import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Fab, Typography } from '@material-ui/core';
import classNames from 'classnames';

import FontAwesomeIcon from '../common/font-awesome-icon';

const styles = theme => ({
    fab: {
        margin: theme.spacing.unit,
        backgroundColor: 'indianred',
        color: theme.palette.common.white
    },
    nameContainer: {
        display: 'flex',
        padding: theme.spacing.unit,
        alignItems: 'center'
    }
});

class AthletesCoachInfo extends Component {
    render() {
        const { classes, xs, currentUserFullName, className } = this.props;
        return <Grid item xs={xs} className={classNames(classes.nameContainer, className)}>
            <Fab className={classes.fab}>
                <FontAwesomeIcon icon="user-circle" variant="regular" size="2x" />
            </Fab>
            <Typography variant="h6">
                {currentUserFullName}
            </Typography>
        </Grid>
    }
}

AthletesCoachInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    xs: PropTypes.number.isRequired,
    currentUserFullName: PropTypes.string.isRequired,
    className: PropTypes.string
}

export default withStyles(styles)(AthletesCoachInfo);


