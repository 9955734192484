export const palette = {
  primary: "#F93C6E",
  secondary: "#0B2B5C",
  darkBlue: "#0B2B5C",
  lightBlue: "#4780AB",
  lightBlueHover: "#F1F6FD",
  white: "#FEFFFF",
  background: "#FAFAFA",
  lightGray: "#EAEAEA",
  lightGrayOpacity: "#f8f8f8",
  light: "#D9D9D9",
  deactivated: "#AFB1B4",
  darkGray: "#6D6F71",
  black: "#12151B",
  darkBlueHover: "#071832",
  boxShadow: "0px 4px 30px 0px #12151B17",
  skeleton: "#D5D6D7",
  opacityGray: "#E1E1E1",
  overlayBlue: "#F1F6FD",
  grayBlue: "#F1F6FE",
  lightPink: "#FFE9F0",
  lightBlueHove: "#E9F1FD",
  lightWhite: "#ffffff00",
};
