import React, { useRef } from "react";
import { Typography, Button } from "@mui/material";
import useCreatePost from "../../../hooks/useCreatePost";
import { PictureIcon } from "../../icons";
import { useTranslation } from "react-i18next";

const CommunityChangePhotoButton = ({
  handleEvent,
  top = "10px",
  left = "10px",
}) => {
  const { handleImageChange } = useCreatePost();
  const { t } = useTranslation("community");

  const hiddenFileInput = useRef(null);

  const handlePhotoClick = () => {
    hiddenFileInput.current.click();
  };
  const handleImageAdded = (event) => {
    handleImageChange(event);
    if (handleEvent) {
      handleEvent();
    }
  };

  return (
    <>
      <Button
        data-testid="change_photo"
        id="change_photo"
        variant="contained"
        disableElevation
        size="small"
        disableRipple
        onClick={handlePhotoClick}
        fontFamily={"karla"}
        startIcon={<PictureIcon />}
        sx={{
          bgcolor: "#FEFFFF",
          position: "absolute",
          top: top,
          left: left,
          "&:hover": {
            backgroundColor: "#FEFFFF",
          },
          "&:active": {
            backgroundColor: "#FEFFFF",
          },
          "&:focus": {
            backgroundColor: "#FEFFFF",
          },
        }}
      >
        <Typography
          component={"span"}
          fontSize={"14px"}
          fontWeight={400}
          fontFamily={"karla"}
          color={"#12151B"}
          textTransform={"none"}
        >
          {t("community.changePhoto")}
        </Typography>
      </Button>
      <input
        ref={hiddenFileInput}
        accept="image/*"
        type="file"
        onChange={handleImageAdded}
        style={{ display: "none" }}
      />
    </>
  );
};

export default CommunityChangePhotoButton;
