import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import {
  OAuthHandler,
  OAuthUrl,
  OAuthLogoutUrl,
} from "../../lib/oauth2-helper";
import SignInButton from "./sign-in-button";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";

const styles = (theme) => ({
  socialLoginButton: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    backgroundColor: theme.palette.common.white,
    boxShadow: "none",
    border: "1px solid gray",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  socialButtonLabel: {
    textTransform: "none",
    fontSize: 18,
    color: "#7a7a7a",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  customColor: {
    background:
      "conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    backgroundClip: "text",
    color: "transparent",
  },
});

export const handlePreSignUpError = (error) => {
  console.log("Social Signup Error", error);
  setTimeout(() => {
    if (!localStorage.getItem("error")) {
      try {
        localStorage.setItem("error", error);
      } catch (e) {
        console.log(e);
      }

      //window.location.reload();
      //setTimeout(() => window.location.replace("/auth"), 500);
    }
  }, 100);
};

OAuthHandler("Google", handlePreSignUpError);

const SignInWithGoogle = ({
  disabled,
  label,
  classes,
  classes: { socialLoginButton, socialButtonLabel },
  withMargin = false,
}) => {
  return (
    <SignInButton
      className={{
        root: socialLoginButton,
        label: socialButtonLabel,
      }}
      withMargin={withMargin}
      disabled={disabled}
      label={label}
      onClick={() => handleGoogleSignUp()}
      icon="google"
      additionalClass={classes.customColor}
    />
  );
};

export const handleGoogleSignUp = async () => {
  const url = OAuthUrl("Google");
  localStorage.removeItem("error");
  if (Capacitor.isNativePlatform()) {
    if (Capacitor.getPlatform() === "ios") {
      Browser.open({
        url: `${
          Capacitor.isNativePlatform()
            ? process.env.REACT_APP_RELEASE_STAGE === "development"
              ? "https://develop.vert.run/"
              : "https://app.vert.run/"
            : window.location.origin
        }googlesigninsignout/${encodeURIComponent(url)}`,
        presentationStyle: "popover",
      });
    } else {
      InAppBrowser.create(url, "_system", {
        clearcache: "yes",
        clearsessioncache: "yes",
        cleardata: "yes",
      });
    }
  } else {
    await window.location.assign(url);
  }
};

SignInWithGoogle.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  withMargin: PropTypes.bool,
};

export default withStyles(styles)(SignInWithGoogle);
