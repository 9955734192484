import React, { useEffect } from "react";
import {
  CardContent,
  Typography,
  Box,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { palette } from "../../../theme/palette";
import dayjs from "dayjs";
import { round, convertUnits } from "../../../lib/units-helper";
import UnitsHook from "../../../hooks/unitsHook";

const headers = [
  "Km",
  "Pace",
  "Elev.Gain",
  "Elev.Loss",
  "Avg.Speed",
  "Vert Speed",
];

const useStyles = makeStyles({
  headerTitle: {
    wordBreak: "break-word !important",
    color: palette.darkGray,
    fontSize: "9px",
  },
  text: {
    color: palette.black,
  },
  textWithIcon: {
    color: palette.black,
  },
});

export const ActivityTable = ({ data = [] }) => {
  const localUnits = UnitsHook();
  const classes = useStyles();

  const calculateMaxSpeed = () => {
    const dataCopy = [...data];
    const speedArray = dataCopy.map((item) => {
      const speed = round(1 / (item.time / 3600), 2);
      return speed;
    });
    const max = Math.max(...speedArray);
    return max;
  };

  const calculateMaxElevation = () => {
    const dataCopy = [...data];
    const elevationArray = dataCopy.map((item) => {
      const elevation = round(
        convertUnits(item.elevation, "kms", localUnits, "elevation"),
        1,
      );
      return elevation;
    });
    const max = Math.max(...elevationArray);
    return max;
  };

  useEffect(() => {
    if (data && data.length) calculateMaxSpeed();
  }, [data]);

  return (
    <Box width={"100%"}>
      <CardContent
        style={{
          padding: "0",
        }}
      >
        <Table
          aria-label="simple table"
          // stickyHeader
          sx={{
            overflowY: "scroll",
            minWidth: "100%",
            minHeight: "100%",
            height: "100%",
          }}
        >
          <TableHead
            sx={{
              borderBottom: `1px solid ${palette.lightGray}`,
            }}
          >
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  style={{ background: "transparent", padding: "12px 0" }}
                  // width={100 / headers.length}
                  width="16.6%"
                  key={header}
                  align="center"
                >
                  <Typography
                    variant="body2"
                    align="left"
                    className={classes.headerTitle}
                  >
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!!data.length &&
              data.map((item, index) => {
                const { pace } = item || {};
                const { minutes, seconds } = pace || {};
                const distance = item.accDistance || item.distance || 0;
                return (
                  <TableRow key={index}>
                    <TableCell
                      style={{ border: "none" }}
                      align="center"
                      width="16.6%"
                    >
                      <Typography
                        variant="body2"
                        align="left"
                        className={classes.text}
                      >
                        {Math.round(distance) > 0
                          ? round(distance, 1)
                          : index + 1}
                      </Typography>
                    </TableCell>

                    <TableCell
                      style={{ border: "none", display: "flex" }}
                      align="center"
                      width="16.6%"
                    >
                      <Typography
                        variant="body2"
                        align="left"
                        className={classes.textWithIcon}
                      >
                        {`${minutes}:${(seconds < 10 ? "0" : "") + seconds}`}{" "}
                      </Typography>
                      {calculateMaxSpeed() ===
                        round(1 / (item.time / 3600), 2) && (
                        <img
                          src={`${process.env.PUBLIC_URL}/run_stat.svg`}
                          alt="max-run-icon"
                          width={15}
                        />
                      )}

                      {calculateMaxElevation() ===
                        round(
                          convertUnits(
                            item.elevation,
                            "kms",
                            localUnits,
                            "elevation",
                          ),
                          1,
                        ) && (
                        <img
                          src={`${process.env.PUBLIC_URL}/elevation_stat.svg`}
                          alt="max-elevation-icon"
                          width={15}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      style={{ border: "none" }}
                      align="center"
                      width="16.6%"
                    >
                      <Typography
                        variant="body2"
                        align="left"
                        className={classes.text}
                      >
                        {round(
                          convertUnits(
                            item.elevation,
                            "kms",
                            localUnits,
                            "elevation",
                          ),
                          1,
                        ) || 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ border: "none" }}
                      align="center"
                      width="16.6%"
                    >
                      <Typography
                        variant="body2"
                        align="left"
                        className={classes.text}
                      >
                        {item.elevation ? 0 && round(item.elevation) : 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ border: "none" }}
                      align="center"
                      width="16.6%"
                    >
                      <Typography
                        variant="body2"
                        align="left"
                        className={classes.text}
                      >
                        {round(1 / (item.time / 3600), 2)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ border: "none" }}
                      align="center"
                      width="16.6%"
                    >
                      <Typography
                        variant="body2"
                        align="left"
                        className={classes.text}
                      >
                        {Math.round(item.elevation / (item.time / 3600))}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CardContent>
    </Box>
  );
};
