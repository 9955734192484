import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, CircularProgress, IconButton, Grid } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment";

import { Routes } from "../lib/routing-middleware";
import history from "../lib/history-helper";
import TrainingFieldsViewer from "./training-viewer/training-fields-viewer";
import {
  athleteTrainingChangeWeek,
  athleteTrainingSelectWorkout,
  athleteTrainingAddWorkout,
  athleteTrainingRemoveWorkout,
  athleteTrainingPatch,
  athleteTrainingSwapWorkouts,
  clearSuccessMessage,
  clearErrorMessage,
  cleanSearch,
  selectAthlete,
  patchSelectedAthleteWorkoutComment,
  athleteTrainingAddWeek,
  athleteTrainingRemoveWeek,
  athleteData,
} from "../actions/athletes-action";
import { notificationChangesClear } from "../actions/notifications-action";
import AthleteTrainingEditor from "./training-viewer/athlete-training-editor";
import TrainingWorkoutSearch from "./training-viewer/training-workout-search";
import AthleteTrainingEditorButtons from "./training-viewer/athlete-training-editor/athlete-training-editor-buttons";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import FlexContainer from "./common/flex-container";
import WorkoutCommentEditor from "./athlete-training-viewer/workout-comment-editor";
import AthleteTrainingPlanInfo from "./athlete-training-viewer/athlete-training-plan-info";
import AthletesCoachInfo from "./athletes/athletes-coach-info";
import { dateFormat } from "../lib/date-helper";

const styles = (theme) => ({
  trainingContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 0.75,
  },
  loading: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    marginTop: 100,
  },
  drawerOpenner: {
    padding: theme.spacing.unit * 0.15,
  },
  coachInfo: {
    paddingLeft: theme.spacing.unit * 4,
  },
  training: {
    padding: theme.spacing.unit * 1.25,
  },
});

class AthleteTrainingViewer extends Component {
  state = {
    openDrawer: false,
    isCommentDialogOpen: false,
    addedWorkouts: [],
  };

  componentDidMount() {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes;
    const { currentTraining } = selectedAthlete || {};
    const hasCurrentTraining = currentTraining !== undefined;

    if (hasCurrentTraining) {
      const today = moment();
      const { startingDate } = currentTraining;
      const startingDateMoment = moment(startingDate, "DD/MM/YYYY").startOf(
        "week",
      );
      const weeks = today.diff(startingDateMoment, "week");
      this.handleRequestWeekChange(weeks);
    }
  }

  componentWillUnmount() {
    let shouldCleanSearchResult = true;

    const { history } = this.props;
    if (history) {
      const { location } = history;
      if (location) {
        const { pathname } = location;
        if (pathname === "/athletes") shouldCleanSearchResult = false;
      }
    }

    if (shouldCleanSearchResult) this.props.dispatch(cleanSearch());
    else {
      const { athletes } = this.props;
      if (athletes) {
        const { selectedAthlete } = athletes;
        if (selectedAthlete) {
          this.props.dispatch(athleteData(selectedAthlete));
        }
      }
    }
    this.props.dispatch(notificationChangesClear());
  }

  handleRequestWeekChange = (weekNumber) => {
    this.props.dispatch(athleteTrainingChangeWeek(weekNumber));
  };

  handleSelectWorkout = (workout) => {
    this.props.dispatch(athleteTrainingSelectWorkout(workout));
  };

  handleWorkoutAdd = (plannedDate) => {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes;
    const { selectedWorkout } = selectedAthlete;
    this.props.dispatch(
      athleteTrainingAddWorkout(
        selectedAthlete,
        moment(plannedDate).format(dateFormat),
        selectedWorkout,
      ),
    );
  };

  handleWorkoutDelete = (workoutId, plannedDate) => {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes;
    this.props.dispatch(
      athleteTrainingRemoveWorkout(
        selectedAthlete,
        moment(plannedDate).format(dateFormat),
        workoutId,
      ),
    );
  };

  handleWorkoutsMoved = (changeReq) => {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes;

    this.props.dispatch(
      athleteTrainingSwapWorkouts(
        {
          selectedAthlete,
          ...changeReq,
        },
        false,
      ),
    );
  };

  handleBackToTrainings = () => {
    history.push(Routes.AthletesPage);
  };

  handleSaveTraining = () => {
    const { athletes, notifications } = this.props;
    const { selectedAthlete } = athletes;
    if (selectedAthlete) {
      const { currentTraining, cognito_user_sub } = selectedAthlete;
      if (currentTraining && cognito_user_sub) {
        const { notificationChangesList } = notifications;
        this.props.dispatch(
          athleteTrainingPatch(
            {
              cognito_user_sub: cognito_user_sub,
              currentTraining: currentTraining,
            },
            notificationChangesList,
          ),
        );
      }
    }
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleWorkoutCommentDialog = (workoutId, plannedDate) => {
    this.setState({
      ...this.state,
      plannedDate: plannedDate,
      workoutId: workoutId,
    });
  };

  handleWorkoutCommentDialogClose = () => {
    this.setState({
      ...this.state,
      plannedDate: undefined,
      workoutId: undefined,
    });
  };

  handleWorkoutCommentDialogSave = (coachComment) => {
    const { plannedDate, workoutId } = this.state;
    if (plannedDate && workoutId) {
      const { athletes } = this.props;
      const { selectedAthlete } = athletes;
      this.props.dispatch(
        patchSelectedAthleteWorkoutComment(
          selectedAthlete,
          moment(plannedDate).format(dateFormat),
          workoutId,
          coachComment,
        ),
      );
    }
  };

  handleToggleDrawer = (open) => {
    this.setState({
      ...this.state,
      openDrawer: open,
    });
  };

  handleAddWeek = (fromPlannedDate) => {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes;
    this.props.dispatch(
      athleteTrainingAddWeek(selectedAthlete, fromPlannedDate),
    );
  };

  handleRemoveWeek = (fromPlannedDate) => {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes;
    this.props.dispatch(
      athleteTrainingRemoveWeek(selectedAthlete, fromPlannedDate),
    );
  };

  render() {
    const { plannedDate, workoutId, openDrawer } = this.state;
    const { classes, athletes, location, auth } = this.props;
    const {
      selectedAthlete,
      loading,
      savingTraining,
      errorMessage,
      successMessage,
      countingAthleteWorkouts,
    } = athletes;
    const { currentTraining, currentWeek = 0 } = selectedAthlete || {};
    const hasCurrentTraining = currentTraining !== undefined;
    const selectedDayForComment =
      typeof plannedDate !== typeof undefined &&
      currentTraining &&
      currentTraining.days.find(
        (day) =>
          day && day[0].plannedDate === moment(plannedDate).format(dateFormat),
      );
    const selectedWorkoutForComment =
      workoutId &&
      selectedDayForComment &&
      selectedDayForComment.find((workout) => workout.id === workoutId);
    const { currentUser = {} } = auth;
    const { attributes = {} } = currentUser;
    const { name, family_name } = attributes;

    return hasCurrentTraining ? (
      <Grid container>
        <AthletesCoachInfo
          xs={12}
          currentUserFullName={`${name} ${family_name || ""}`.trim()}
          className={classes.coachInfo}
        />
        <Grid item xs={12} className={classes.training}>
          <FlexContainer direction="row" wrap={false} padding={0}>
            <IconButton
              onClick={this.handleToggleDrawer.bind(this, true)}
              className={classes.drawerOpenner}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
            <AthleteTrainingPlanInfo
              selectedAthlete={selectedAthlete}
              countingAthleteWorkouts={countingAthleteWorkouts}
              openDrawer={openDrawer}
              onToggleDrawer={this.handleToggleDrawer}
            />
            <TrainingWorkoutSearch onSelectWorkout={this.handleSelectWorkout} />
            <Paper className={classes.trainingContainer}>
              {loading ? (
                <CircularProgress
                  variant="indeterminate"
                  className={classes.loading}
                />
              ) : (
                <Fragment>
                  <FlexContainer direction="row" padding={5}>
                    {currentTraining && (
                      <TrainingFieldsViewer currentTraining={currentTraining} />
                    )}
                    <AthleteTrainingEditorButtons
                      hasCurrentTraining={hasCurrentTraining}
                      saving={savingTraining}
                      onSaveTraining={this.handleSaveTraining}
                      onClearCurrentTraining={this.handleBackToTrainings}
                    />
                  </FlexContainer>
                  <AthleteTrainingEditor
                    saving={savingTraining}
                    currentWeek={currentWeek}
                    currentTraining={currentTraining}
                    onRequestWeekChange={this.handleRequestWeekChange}
                    onWorkoutAdd={this.handleWorkoutAdd}
                    onWorkoutDelete={this.handleWorkoutDelete}
                    onWorkoutsMoved={this.handleWorkoutsMoved}
                    onAddWeek={this.handleAddWeek}
                    onRemoveWeek={this.handleRemoveWeek}
                  />
                </Fragment>
              )}
            </Paper>
            <SnackBarMessageDisplay
              onHideMessage={this.handleHideMessage}
              errorMessage={errorMessage}
              successMessage={successMessage}
            />
            <WorkoutCommentEditor
              onClose={this.handleWorkoutCommentDialogClose}
              onSaveComment={this.handleWorkoutCommentDialogSave}
              isOpen={selectedWorkoutForComment ? true : false}
              workout={selectedWorkoutForComment}
            />
          </FlexContainer>
        </Grid>
      </Grid>
    ) : (
      <Redirect to={{ pathname: "/athletes", state: { from: location } }} />
    );
  }
}

AthleteTrainingViewer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect((store) => {
  return {
    athletes: store.athletes,
    auth: store.auth,
    notifications: store.notifications,
  };
})(withStyles(styles, { withTheme: true })(AthleteTrainingViewer));
