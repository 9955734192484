import React, { Component } from 'react';
import Grow from '@material-ui/core/Grow';

class PlanFieldTransition extends Component {
    componentDidMount() {
        window.scroll(0, 0)
    }
    render() {
        const { children } = this.props;

        return <Grow in={true} timeout={500}>
            {children}
        </Grow>
    }
}

export default PlanFieldTransition;