import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Tooltip,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
} from "@material-ui/core";
import moment from "moment";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import "moment/min/locales";
import {
  getEnergyLevelRate,
  getDoneRate,
  getTrainingLoadRate,
} from "../../lib/rates-helper";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import MenuList from "../common/menu-list";
import { getPlanLevel } from "../../lib/plan-helper";

const styles = (theme) => ({
  container: {
    padding: "24px 54px 0",

    [theme.breakpoints.down("md")]: {
      padding: "24px",
    },
  },
  topBarTitle: {
    fontWeight: 500,
    fontSize: 12,
    color: "#202020",
    marginRight: 14,

    [theme.breakpoints.down("md")]: {
      fontWeight: 500,
      fontSize: 14,
      color: "#000000",
      opacity: 0.6,
    },
  },

  topBar: {
    display: "flex",
  },
  filter: {
    fontSize: 14,
    fontWeight: 400,
    background: "rgba(63, 81, 181, 0.12);",
    borderRadius: 4,
    padding: "6px 8px",
    color: "#212121",
    cursor: "pointer",
    minWidth: 70,
    textAlign: "center",
  },
  filterSelected: {
    fontSize: 14,
    fontWeight: 400,
    background: "#1E629B",
    borderRadius: 4,
    padding: "6px 8px",
    color: "#FFFFFF",
    cursor: "pointer",
    minWidth: 70,
    textAlign: "center",
  },

  doneGrid: {},
  doneContainer: {
    textAlign: "center",
    height: "100%",
    display: "flex",

    [theme.breakpoints.down("md")]: {
      borderRadius: 8,
      margin: "12px 0",
      padding: 12,
    },
  },
  doneMarginContainer: {
    margin: "auto 0",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",

    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  flexContainer: {
    position: "relative",
    alignItems: "center",
    marginBottom: 10,
    textAlign: "left",
  },
  doneTitle: {
    color: "#212121",
    fontSize: 16,
    textAlign: "left",
    fontWeight: 500,
    marginRight: 5,

    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      display: "flex",
    },
  },
  doneChart: {
    width: "50%",
    minWidth: 120,
    height: 60,
    position: "relative",
    margin: 10,
  },
  doneText: {
    top: "50%",
    left: "50%",
    color: "#212121",
    position: "absolute",
    fontSize: 34,
    transform: "translate(-45%, -15%)",
    textAlign: "center",
    fontWeight: 400,
  },
  donePercentage: {
    color: "#212121",
    fontSize: 14,
    textAlign: "center",
    fontWeight: 400,
  },
  doneTip: {
    color: "rgba(158, 158, 158, 0.7)",
    fontSize: 14,
    marginTop: 10,
    textAlign: "left",
    fontWeight: 500,
  },

  energyLevelGrid: {},
  energyLevelContainer: {
    margin: "0 10px",
    padding: "0 10px",
    textAlign: "center",
    height: "100%",
    display: "flex",

    [theme.breakpoints.down("md")]: {
      borderRadius: 8,
      margin: "12px 0",
      padding: 12,
    },
  },
  energyLevelMarginContainer: {
    margin: "auto 0",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
  },
  energyLevelTitle: {
    color: "#212121",
    fontSize: 16,
    textAlign: "left",
    fontWeight: 500,
    marginRight: 5,

    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      display: "flex",
    },
  },
  energyLevelChart: {
    width: "50%",
    minWidth: 120,
    height: 60,
    position: "relative",
    margin: 10,
  },
  energyLevelText: {
    top: "50%",
    left: "50%",
    color: "#212121",
    position: "absolute",
    fontSize: 34,
    transform: "translate(-45%, -15%)",
    textAlign: "center",
    fontWeight: 400,
  },

  energyLevelTip: {
    color: "rgba(158, 158, 158, 0.7)",
    fontSize: 14,
    marginTop: 10,
    textAlign: "left",
    fontWeight: 500,
  },
  energyLevelTipTitle: {
    color: "rgba(158, 158, 158, 0.7)",
    fontSize: 14,
    marginTop: 10,
    textAlign: "left",
    fontWeight: 500,
  },
  energyLevelTipText: {
    color: "rgba(158, 158, 158, 0.7)",
    fontSize: 14,
    marginTop: 10,
    textAlign: "left",
    fontWeight: 500,
  },
  popper: {
    zIndex: 100,
  },
  button: {
    padding: "0",
    fontWeight: "normal",
  },
  minWZero: {
    minWidth: "initial",
  },

  progressTitle: {
    fontWeight: 500,
    fontSize: 20,
    color: "#212121",
    marginRight: 14,
  },

  gridContainer: {
    alignItems: "flex-start",
    marginTop: 12,
  },

  paper: {
    background: "#fff",
  },

  menuItem: {
    padding: "6px 8px",
    fontWeight: "normal",
    alignItems: "center",
  },
  menuItemLabel: {
    textTransform: "initial",
    textAlign: "left",
    justifyContent: "left",
  },
  menuItemLabelSelected: {
    textTransform: "initial",
    textAlign: "left",
    justifyContent: "left",
    color: "#FF6327",
  },

  topBarFilters: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
});

class DashboardDesktopCharts extends Component {
  state = {
    gran: "plan",
    open: false,
  };

  componentDidMount() {}

  handleToggle = () => {
    this.setState({
      ...this.state,
      open: !this.state.open,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      open: false,
    });
  };

  render() {
    const { classes, currentTraining, t, activities, width } = this.props;

    const { gran, open } = this.state;
    const { days } = currentTraining || {};

    let runActivities = [];

    if (activities) {
      runActivities = activities.filter(
        (a) =>
          (a.type && a.type.toLowerCase().includes("run")) ||
          (a.activityType && a.activityType.includes("RUN")),
      );
    }

    let doneRate;
    let energyLevelRate;
    let loadRate;

    energyLevelRate = getEnergyLevelRate(days, moment());
    doneRate = getDoneRate(days, moment());

    const level = getPlanLevel(currentTraining);
    loadRate = getTrainingLoadRate(days, runActivities, moment(), level);

    const createDoughnutChartData = (label, rate, color) => {
      let secondDataPoint;

      if (label === "Points") {
        secondDataPoint = 10 - Math.round(rate[this.state.gran].label);
      } else {
        secondDataPoint = 100 - Math.round(rate[this.state.gran].label);
      }

      return {
        labels: [label, "Empty"],
        datasets: [
          {
            label: `${label} Rate`,
            data: [Math.round(rate[this.state.gran].label), secondDataPoint],
            backgroundColor: color,
            borderWidth: 1,
          },
        ],
      };
    };

    const dataDone = createDoughnutChartData("Done", doneRate, [
      "#1E629B",
      "#C4C4C4",
    ]);
    const dataLoad = createDoughnutChartData("Load", loadRate, [
      "#1E629B",
      "#C4C4C4",
    ]);
    const dataEnergyLevel = createDoughnutChartData(
      "Points",
      energyLevelRate.points,
      ["#1E629B", "#C4C4C4"],
    );

    const doughnutOptions = {
      cutout: "80%",
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      animation: {
        easing: "linear",
        duration: 1000,
      },
      rotation: -90,
      circumference: 180,
    };

    const isInMobileView = width === "sm" || width === "xs";

    return (
      <div className={classes.container}>
        {isInMobileView ? (
          <div className={classes.topBar}>
            <div className={classes.topBarFilters}>
              <Typography
                variant="body1"
                className={
                  gran === "week" ? classes.filterSelected : classes.filter
                }
                onClick={() =>
                  this.setState({
                    ...this.state,
                    gran: "week",
                  })
                }
              >
                {t("Week", { ns: "dashboard" })}
              </Typography>
              <Typography
                variant="body1"
                className={
                  gran === "month" ? classes.filterSelected : classes.filter
                }
                onClick={() =>
                  this.setState({
                    ...this.state,
                    gran: "month",
                  })
                }
              >
                {t("Month", { ns: "dashboard" })}
              </Typography>

              <Typography
                variant="body1"
                className={
                  gran === "plan" ? classes.filterSelected : classes.filter
                }
                onClick={() =>
                  this.setState({
                    ...this.state,
                    gran: "plan",
                  })
                }
              >
                {t("Full plan", { ns: "dashboard" })}
              </Typography>
            </div>
          </div>
        ) : (
          <div className={classes.topBar}>
            <Typography variant="body1" className={classes.progressTitle}>
              {gran === "week"
                ? t("Weekly progress", { ns: "dashboard" })
                : gran === "month"
                ? t("Monthly progress", { ns: "dashboard" })
                : t("Full plan progress", { ns: "dashboard" })}
            </Typography>

            <Button
              className={classes.button}
              buttonRef={(node) => {
                this.anchorEl = node;
              }}
              aria-owns={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={this.handleToggle}
              placement="left-start"
              classes={{ root: classes.minWZero }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/icon-chevron-down.svg`}
                alt=""
              />
            </Button>
            <Popper
              open={open}
              anchorEl={this.anchorEl}
              transition
              disablePortal
              className={classes.popper}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <div className={classes.paper}>
                    <ClickAwayListener onClickAway={() => this.handleClose()}>
                      <MenuList>
                        <Button
                          variant="text"
                          classes={{
                            root: classes.menuItem,
                            label: classes.menuItemLabel,
                          }}
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              gran: "week",
                              open: false,
                            })
                          }
                        >
                          {t("weekly", { ns: "dashboard" })}
                        </Button>

                        <Button
                          variant="text"
                          classes={{
                            root: classes.menuItem,
                            label: classes.menuItemLabel,
                          }}
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              gran: "month",
                              open: false,
                            })
                          }
                        >
                          {t("monthly", { ns: "dashboard" })}
                        </Button>

                        <Button
                          variant="text"
                          classes={{
                            root: classes.menuItem,
                            label: classes.menuItemLabel,
                          }}
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              gran: "plan",
                              open: false,
                            })
                          }
                        >
                          {t("total", { ns: "dashboard" })}
                        </Button>
                      </MenuList>
                    </ClickAwayListener>
                  </div>
                </Grow>
              )}
            </Popper>
          </div>
        )}
        <Grid container className={classes.gridContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className={classes.doneGrid}
          >
            <Tooltip
              title={t("Achieved tooltip", { ns: "dashboard" })}
              disableTouchListener={true}
            >
              <div className={classes.doneContainer}>
                <div className={classes.doneMarginContainer}>
                  <div className={classes.flexContainer}>
                    <Typography variant="body1" className={classes.doneTitle}>
                      {t("Workouts achieved", { ns: "dashboard" })}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.doneTip}
                      style={{
                        color:
                          Math.round(doneRate[this.state.gran]) <= 30
                            ? "#F23827"
                            : Math.round(doneRate[this.state.gran]) > 30 &&
                              Math.round(doneRate[this.state.gran]) <= 50
                            ? "#FF5722"
                            : Math.round(doneRate[this.state.gran]) > 31 &&
                              Math.round(doneRate[this.state.gran]) <= 75
                            ? "#F2E307"
                            : "#04D976",
                      }}
                    >
                      {Math.round(doneRate[this.state.gran]) <= 30
                        ? t("Done 1 Title", { ns: "dashboard" })
                        : Math.round(doneRate[this.state.gran]) > 30 &&
                          Math.round(doneRate[this.state.gran]) <= 50
                        ? t("Done 2 Title", { ns: "dashboard" })
                        : Math.round(doneRate[this.state.gran]) > 51 &&
                          Math.round(doneRate[this.state.gran]) <= 75
                        ? t("Done 3 Title", { ns: "dashboard" })
                        : t("Done 4 Title", { ns: "dashboard" })}
                    </Typography>

                    <Typography variant="body1" className={classes.doneTip}>
                      {Math.round(doneRate[this.state.gran]) <= 30
                        ? t("Done 1", { ns: "dashboard" })
                        : Math.round(doneRate[this.state.gran]) > 30 &&
                          Math.round(doneRate[this.state.gran]) <= 50
                        ? t("Done 2", { ns: "dashboard" })
                        : Math.round(doneRate[this.state.gran]) > 51 &&
                          Math.round(doneRate[this.state.gran]) <= 75
                        ? t("Done 3", { ns: "dashboard" })
                        : t("Done 4", { ns: "dashboard" })}
                    </Typography>
                  </div>

                  <div className={classes.doneChart}>
                    <Doughnut data={dataDone} options={doughnutOptions} />
                    <Typography variant="body1" className={classes.doneText}>
                      {Math.round(doneRate[this.state.gran])}
                      <span className={classes.donePercentage}>%</span>
                    </Typography>
                  </div>
                </div>
              </div>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className={classes.energyLevelGrid}
          >
            <Tooltip
              title={t("Energy level tooltip", { ns: "dashboard" })}
              disableTouchListener={true}
            >
              <div className={classes.energyLevelContainer}>
                <div className={classes.energyLevelMarginContainer}>
                  <div className={classes.flexContainer}>
                    <Typography
                      variant="body1"
                      className={classes.energyLevelTitle}
                    >
                      {t("Energy level T", { ns: "dashboard" })}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.energyLevelTipTitle}
                      style={{
                        color:
                          Math.round(energyLevelRate.points[this.state.gran]) <=
                          3
                            ? "#F23827"
                            : Math.round(
                                energyLevelRate.points[this.state.gran],
                              ) > 3 &&
                              Math.round(
                                energyLevelRate.points[this.state.gran],
                              ) <= 5
                            ? "#F2E307"
                            : "#04D976",
                      }}
                    >
                      {Math.round(energyLevelRate.points[this.state.gran]) <= 3
                        ? t("Energy 1", { ns: "dashboard" })
                        : Math.round(energyLevelRate.points[this.state.gran]) >
                            3 &&
                          Math.round(energyLevelRate.points[this.state.gran]) <=
                            5
                        ? t("Energy 2", { ns: "dashboard" })
                        : t("Energy 3", { ns: "dashboard" })}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.energyLevelTipText}
                    >
                      {Math.round(energyLevelRate.points[this.state.gran]) <= 3
                        ? t("Energy 1 Text", { ns: "dashboard" })
                        : Math.round(energyLevelRate.points[this.state.gran]) >
                            3 &&
                          Math.round(energyLevelRate.points[this.state.gran]) <=
                            5
                        ? t("Energy 2 Text", { ns: "dashboard" })
                        : t("Energy 3 Text", { ns: "dashboard" })}
                    </Typography>
                  </div>
                  <div className={classes.energyLevelChart}>
                    <Doughnut
                      data={dataEnergyLevel}
                      options={doughnutOptions}
                    />
                    <Typography
                      variant="body1"
                      className={classes.energyLevelText}
                    >
                      {Math.round(energyLevelRate.points[this.state.gran])}
                      <span className={classes.donePercentage}>Pts</span>
                    </Typography>
                  </div>
                </div>
              </div>
            </Tooltip>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            className={classes.doneGrid}
          >
            <Tooltip
              title={t("Training load tooltip", { ns: "dashboard" })}
              disableTouchListener={true}
            >
              <div className={classes.doneContainer}>
                <div className={classes.doneMarginContainer}>
                  <div className={classes.flexContainer}>
                    <Typography variant="body1" className={classes.doneTitle}>
                      {t("Training volume", { ns: "dashboard" })}
                    </Typography>

                    <Typography variant="body1" className={classes.doneTip}>
                      {Math.round(loadRate[this.state.gran]) <= 30
                        ? t("Load 1", { ns: "dashboard" })
                        : Math.round(loadRate[this.state.gran]) > 30 &&
                          Math.round(loadRate[this.state.gran]) <= 50
                        ? t("Load 2", { ns: "dashboard" })
                        : Math.round(loadRate[this.state.gran]) > 50 &&
                          Math.round(loadRate[this.state.gran]) <= 75
                        ? t("Load 3", { ns: "dashboard" })
                        : Math.round(loadRate[this.state.gran]) > 75 &&
                          Math.round(loadRate[this.state.gran]) <= 130
                        ? t("Load 4", { ns: "dashboard" })
                        : t("Load 5", { ns: "dashboard" })}
                    </Typography>

                    <Typography variant="body1" className={classes.doneTip}>
                      {Math.round(loadRate[this.state.gran]) <= 30
                        ? t("Load 1 Text", { ns: "dashboard" })
                        : Math.round(loadRate[this.state.gran]) > 30 &&
                          Math.round(loadRate[this.state.gran]) <= 50
                        ? t("Load 2 Text", { ns: "dashboard" })
                        : Math.round(loadRate[this.state.gran]) > 50 &&
                          Math.round(loadRate[this.state.gran]) <= 75
                        ? t("Load 3 Text", { ns: "dashboard" })
                        : Math.round(loadRate[this.state.gran]) > 75 &&
                          Math.round(loadRate[this.state.gran]) <= 130
                        ? t("Load 4 Text", { ns: "dashboard" })
                        : t("Load 5 Text", { ns: "dashboard" })}
                    </Typography>
                  </div>

                  <div className={classes.doneChart}>
                    <Doughnut data={dataLoad} options={doughnutOptions} />
                    <Typography variant="body1" className={classes.doneText}>
                      {Math.round(loadRate[this.state.gran])}
                      <span className={classes.donePercentage}>%</span>
                    </Typography>
                  </div>
                </div>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    );
  }
}

DashboardDesktopCharts.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  currentTraining: PropTypes.object,
  distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  elevation: PropTypes.number,
  t: PropTypes.func.isRequired,
  activities: PropTypes.array,
};

export default withTranslation(["constants", "dashboard"])(
  withWidth()(withStyles(styles, { withTheme: true })(DashboardDesktopCharts)),
);
