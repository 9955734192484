import React, { useEffect, useState } from "react";
import {
  Box,
  withStyles,
  LinearProgress,
  Typography,
  makeStyles,
  Popper,
  Paper,
} from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import { palette } from "../../../../theme/palette";
import { useTranslation } from "react-i18next";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { dateFormat } from "../../../../lib/date-helper";
import moment from "moment";
import { getFixCustomLevel } from "../../../../lib/plan-helper";
import { useSelector, useDispatch } from "react-redux";
import { getUserActivities } from "../../../../actions/user-plans-action";
import EstimatedTimes from "../../../../constants/estimated-times";
import "chart.js/auto";
import { Fade, IconButton } from "@mui/material";
import { TimerIcon } from "../../../icons";
import useCalculatorMI from "../../../../hooks/useCalculatorMI";
import dayjs from "dayjs";

const useStyles = makeStyles({
  statSubValue: {
    color: palette.darkGray,
  },
  mainTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 5,
  },
  subtitle: {
    color: palette.darkGray,
    lineHeight: "24px",
    marginBottom: "24px",
    marginTop: "4px",
    fontFamily: "Karla",
  },
  infoIcon: {
    color: palette.deactivated,
    width: "14px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "600",
    textAlign: "center",
    fontFamily: "Oswald",
  },
  main: {
    marginBottom: "40px",
    width: "100%",
  },
  paperPopper: {
    boxShadow: "0px 1px 15px 0px #12151B0D !important",
    display: "flex",
    padding: "8px 12px 8px 12px",
    /* gap: 12px; */
    borderRadius: "4px !important",
    backgroundColor: `${palette.lightBlueHover} !important`,
    opacity: 1,
    maxWidth: 277,
  },
});

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 4,
  },
  colorSecondary: {
    backgroundColor: palette.lightGray,
  },
  bar: {
    borderRadius: 4,
    backgroundColor: palette.darkBlue,
  },
}))(LinearProgress);

export const ProfilePlanLoadChart = () => {
  const { getPlanDates } = useCalculatorMI();
  const { i18n, t } = useTranslation("profile");
  const { language } = i18n;
  moment.locale(language);
  const { currentPlan, plan } = useSelector((state) => state.userPlans);
  const { currentFilter, currentDate, toDate } = useSelector(
    (state) => state.profile,
  );
  const { activities: activitiesStrava } = useSelector((state) => state.strava);
  const { activities: activitiesSuunto } = useSelector((state) => state.suunto);
  const { activities: activitiesGarmin } = useSelector((state) => state.garmin);
  const { activities: activitiesVert } = useSelector(
    (state) => state.vertActivities,
  );
  const { activities: healthActivities } = useSelector(
    (state) => state.healthKit,
  );
  const { currentTraining, chainedPlans } = currentPlan || {};
  const { durationInWeeks } = currentTraining || {};
  const [labels, setLabels] = useState();
  const dispatch = useDispatch();
  const [time, setTime] = useState(0);

  const fullActivities = [
    ...(activitiesStrava || []),
    ...(activitiesGarmin || []),
    ...(activitiesSuunto || []),
    ...(activitiesVert || []),
    ...(healthActivities || []),
  ];

  /** Using Map to eliminate duplicates via the activity start_date field*/
  const groupedActivities = new Map();
  for (const activity of fullActivities) {
    const { start_date } = activity;
    const startDateTime = new Date(start_date).getTime();
    if (!groupedActivities.has(startDateTime))
      groupedActivities.set(startDateTime, activity);
  }

  const activities = Array.from(groupedActivities.values());
  const runActivities = activities.length
    ? activities.filter(
        (a) =>
          (a.type && a.type.toLowerCase().includes("run")) ||
          (a.activityType && a.activityType.includes("RUN")),
      )
    : [];

  const [planned, setPlanned] = useState([]);
  const [done, setDone] = useState([]);
  const [extra, setExtra] = useState([]);
  const [anchorElTrainingLoad, setAnchorElTrainingLoad] = useState(null);
  const [openTrainingLoad, setOpenTrainingLoad] = useState(false);
  const [placementTrainingLoad, setPlacementTrainingLoad] = useState();
  const classes = useStyles();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#12151B",
          boxWidth: 30,
          boxHeight: 30,
          padding: 15,
          pointStyle: "circle",
          usePointStyle: true,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          labelColor: function(context) {
            const bg =
              context.datasetIndex === 1
                ? palette.darkGray
                : context.datasetIndex === 2
                ? palette.primary
                : palette.darkBlue;
            return {
              borderColor: "rgb(0, 0, 0)",
              backgroundColor: bg,
              borderWidth: 0,
              borderDash: [0, 0],
              borderRadius: 2,
            };
          },
          label: function(context) {
            let title = t("profile.stats.chart.trainingLoad.done");
            if (context.datasetIndex === 1)
              title = t("profile.stats.chart.trainingLoad.planned");
            if (context.datasetIndex === 2)
              title = t("profile.stats.chart.trainingLoad.extra");

            return `${title}: ${EstimatedTimes.convertMinutesToLabel(
              +context.parsed.y,
              true,
            )}`;
          },
          title: function() {
            return null;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: palette.lightGray,
          borderColor: palette.lightGray,
        },
        ticks: {
          minor: {
            fontSize: 12,
            fontColor: "#9E9E9E",
          },
          padding: 10,
        },
        stacked: true,
      },
      y: {
        grid: {
          display: true,
          color: palette.lightGray,
          borderColor: palette.lightGray,
        },
        ticks: {
          beginAtZero: true,
          callback: function(value) {
            return value >= 30
              ? EstimatedTimes.convertMinutesToLabel(value)
              : "";
          },
          fontSize: 12,
          fontColor: "#9E9E9E",
          padding: 5,
        },
        stacked: true,
      },
    },
    animation: {
      easing: "linear",
      duration: 1000,
    },
  };

  useEffect(() => {
    const handleVisibilityChange = () => setOpenTrainingLoad(false);
    const handleClickOutside = () => setOpenTrainingLoad(false);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (Object.values(currentPlan || {}).length) {
      dispatch(getUserActivities(currentPlan));
    }
  }, [currentPlan]);

  useEffect(() => {
    if (
      plan &&
      plan.length &&
      activities &&
      activities.length &&
      currentFilter === 0
    )
      calculateWeekFilter();

    if (
      plan &&
      plan.length &&
      activities &&
      activities.length &&
      currentFilter === 1
    )
      calculateMonthFilter();

    if (
      plan &&
      plan.length &&
      activities &&
      activities.length &&
      currentFilter === 2
    )
      calculateFullPlan();
  }, [
    plan,
    activitiesStrava,
    activitiesGarmin,
    activitiesSuunto,
    activitiesVert,
    healthActivities,
    currentFilter,
    currentDate,
    toDate,
  ]);

  const handlePopperTrainingLoad = (newPlacement) => (event) => {
    setAnchorElTrainingLoad(event.currentTarget);
    setOpenTrainingLoad(
      (prev) => placementTrainingLoad !== newPlacement || !prev,
    );
    setPlacementTrainingLoad(newPlacement);
  };

  const calculateMonthFilter = () => {
    let currentPlannedMonth = [];
    let currentDoneMonth = [];
    let currentOverMonth = [];
    let monthLabels = [];
    let totalWeeks = 0;
    const time = [];

    for (
      let dateIdx = moment(monthStartDate);
      dateIdx.isBefore(monthEndDate, "day");
      dateIdx.add(7, "days")
    ) {
      const labelDate = moment(dateIdx);

      monthLabels.push(
        `${labelDate.startOf("isoWeek").format("MMM")} ${labelDate
          .startOf("isoWeek")
          .date()} - ${labelDate
          .endOf("isoWeek")
          .format("MMM")} ${labelDate.endOf("isoWeek").date()}`,
      );
    }

    for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
      currentPlannedMonth.push(0);
      currentDoneMonth.push(0);
      currentOverMonth.push(0);
    }

    const monthStartDate = moment(currentDate);
    const monthEndDate = moment(toDate);

    for (
      let dateIdx = moment(monthStartDate);
      dateIdx.isBefore(monthEndDate, "day");
      dateIdx.add(7, "days")
    ) {
      const labelDate = moment(dateIdx);

      monthLabels.push(
        `${labelDate.startOf("isoWeek").format("MMM")} ${labelDate
          .startOf("isoWeek")
          .date()} - ${labelDate
          .endOf("isoWeek")
          .format("MMM")} ${labelDate.endOf("isoWeek").date()}`,
      );
    }

    if (plan) {
      plan.forEach((dayOf) => {
        let day = dayOf[0] ? dayOf[0] : dayOf;
        if (day) {
          if (
            moment(day.plannedDate, dateFormat).isSameOrAfter(monthStartDate) &&
            moment(day.plannedDate, dateFormat).isSameOrBefore(monthEndDate)
          ) {
            const plannedDay =
              moment(day.plannedDate, dateFormat).diff(
                monthStartDate.startOf("isoWeek"),
                "days",
              ) + 1;

            const plannedWeek = Math.floor(
              (plannedDay > 1 ? plannedDay - 1 : 0) / 7,
            );

            if (plannedWeek + 1 > totalWeeks) {
              totalWeeks = plannedWeek + 1;
            }

            let foundActivitiesTime = 0;
            if (runActivities) {
              const foundActivities = runActivities.filter(
                (activity) =>
                  activity &&
                  moment(activity.start_date).isSame(
                    moment(day.plannedDate, dateFormat),
                    "day",
                  ),
              );

              if (foundActivities && foundActivities.length > 0) {
                foundActivitiesTime = foundActivities
                  .map((item) => {
                    const movingTime =
                      item.moving_time || item.elapsed_time || 0;
                    return Math.round(movingTime / 60) || 0;
                  })
                  .reduce((a, sum) => a + sum);
              }
              if (foundActivities && foundActivities.length) {
                time.push(
                  foundActivities
                    .map((item) => {
                      const movingTime =
                        item.moving_time || item.elapsed_time || 0;
                      return Math.round(movingTime / 60) || 0;
                    })
                    .reduce((a, sum) => a + sum),
                );
              } else {
                time.push(0);
              }
            }

            if (plannedWeek < durationInWeeks) {
              const dayWorkoutsTime = sumEstimatedTime(day);
              const planned = dayWorkoutsTime;

              const done =
                foundActivitiesTime > dayWorkoutsTime
                  ? dayWorkoutsTime
                  : foundActivitiesTime;

              const over =
                foundActivitiesTime > dayWorkoutsTime
                  ? foundActivitiesTime - dayWorkoutsTime
                  : 0;

              currentPlannedMonth[plannedWeek] += isNaN(+planned)
                ? 0
                : +planned;
              currentDoneMonth[plannedWeek] += isNaN(+done) ? 0 : +done;

              currentOverMonth[plannedWeek] += isNaN(+over) ? 0 : +over;
            }
          }
        }
      });

      for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
        const totalDoneOver =
          currentDoneMonth[weekIndex] + currentOverMonth[weekIndex];
        if (totalDoneOver > currentPlannedMonth[weekIndex]) {
          currentOverMonth[weekIndex] =
            totalDoneOver - currentPlannedMonth[weekIndex];
          currentDoneMonth[weekIndex] = currentPlannedMonth[weekIndex];
        } else {
          currentOverMonth[weekIndex] = 0;
          currentDoneMonth[weekIndex] = totalDoneOver;
        }
      }
    }

    setPlanned(currentPlannedMonth);
    setDone(currentDoneMonth);
    setExtra(currentOverMonth);
    setLabels(monthLabels);
    setTime(time);
  };

  const calculateFullPlan = () => {
    const { startDate: planStartDate } = getPlanDates(plan);
    let currentPlannedTotal = [];
    let currentDoneTotal = [];
    let currentOverTotal = [];
    const time = [];

    const labels = [];
    for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
      labels.push(t("WeekMobile", { week: weekIndex + 1, ns: "dashboard" }));
    }

    for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
      currentPlannedTotal.push(0);
      currentDoneTotal.push(0);
      currentOverTotal.push(0);
    }
    const startingDateMoment = dayjs(planStartDate).startOf("isoWeek");

    if (plan) {
      plan.forEach((dayOf) => {
        let day = dayOf[0] ? dayOf[0] : dayOf;
        if (day) {
          const plannedDay =
            dayjs(day.plannedDate, dateFormat).diff(
              startingDateMoment,
              "days",
            ) + 1;
          const plannedWeek = Math.floor(
            (plannedDay > 1 ? plannedDay - 1 : 0) / 7,
          );

          let foundActivitiesTime = 0;
          if (runActivities) {
            const foundActivities = runActivities.filter(
              (activity) =>
                activity &&
                dayjs(activity.start_date).isSame(
                  dayjs(day.plannedDate, dateFormat),
                  "day",
                ),
            );

            if (foundActivities && foundActivities.length > 0) {
              foundActivitiesTime = foundActivities
                .map((item) => {
                  const movingTime = item.moving_time || item.elapsed_time || 0;
                  return Math.round(movingTime / 60) || 0;
                })
                .reduce((a, sum) => a + sum);
            }

            if (foundActivities && foundActivities.length) {
              time.push(
                foundActivities
                  .map((item) => {
                    const movingTime =
                      item.moving_time || item.elapsed_time || 0;
                    return Math.round(movingTime / 60) || 0;
                  })
                  .reduce((a, sum) => a + sum),
              );
            } else {
              time.push(0);
            }
          }

          if (plannedWeek < durationInWeeks) {
            const toNumberOrZero = (value) => (isNaN(+value) ? 0 : +value);
            const dayWorkoutsTime = sumEstimatedTime(day);
            const planned = toNumberOrZero(dayWorkoutsTime);
            const done = toNumberOrZero(
              foundActivitiesTime > planned ? planned : foundActivitiesTime,
            );

            const over = toNumberOrZero(
              foundActivitiesTime > planned ? foundActivitiesTime - planned : 0,
            );

            currentPlannedTotal[plannedWeek] += planned;
            currentDoneTotal[plannedWeek] += done;
            currentOverTotal[plannedWeek] += over;
          }
        }
      });

      for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
        const totalDoneOver =
          currentDoneTotal[weekIndex] + currentOverTotal[weekIndex];
        if (totalDoneOver > currentPlannedTotal[weekIndex]) {
          currentOverTotal[weekIndex] =
            totalDoneOver - currentPlannedTotal[weekIndex];
          currentDoneTotal[weekIndex] = currentPlannedTotal[weekIndex];
        } else {
          currentOverTotal[weekIndex] = 0;
          currentDoneTotal[weekIndex] = totalDoneOver;
        }
      }
    }

    setPlanned(currentPlannedTotal);
    setDone(currentDoneTotal);
    setExtra(currentOverTotal);
    setLabels(labels);
    setTime(time);
  };

  const calculateWeekFilter = () => {
    const weekStartDate = moment(currentDate).startOf("isoWeek");
    const newDates = [];
    const newPlannedWeek = [];
    const newDoneWeek = [];
    const newOverWeek = [];
    const time = [];
    if (!activities && !activities.length) return;

    for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
      const tempDate = moment(weekStartDate).add(dayIndex, "day");
      newDates[dayIndex] = tempDate;

      let foundActivitiesTime = 0;
      if (runActivities) {
        const foundActivities = runActivities.filter(
          (activity) =>
            activity && moment(activity.start_date).isSame(tempDate, "day"),
        );

        if (foundActivities && foundActivities.length > 0) {
          foundActivitiesTime = foundActivities
            .map((item) => {
              const movingTime = item.moving_time || item.elapsed_time || 0;
              return Math.round(movingTime / 60) || 0;
            })
            .reduce((a, sum) => a + sum);
        }

        if (foundActivities && foundActivities.length) {
          time.push(
            foundActivities
              .map((item) => {
                const movingTime = item.moving_time || item.elapsed_time || 0;
                return Math.round(movingTime / 60) || 0;
              })
              .reduce((a, sum) => a + sum),
          );
        } else {
          time.push(0);
        }
      }

      const foundDay = plan.find((dayOf) => {
        let day = dayOf[0] ? dayOf[0] : dayOf;
        return (
          day && moment(day.plannedDate, dateFormat).isSame(tempDate, "day")
        );
      });

      if (!foundDay) {
        newPlannedWeek[dayIndex] = 0;
        newDoneWeek[dayIndex] = 0;
        newOverWeek[dayIndex] = 0;
      }

      if (foundDay) {
        const dayWorkoutsTime = sumEstimatedTime(foundDay);

        newPlannedWeek[dayIndex] = dayWorkoutsTime;
        newDoneWeek[dayIndex] =
          foundActivitiesTime > dayWorkoutsTime
            ? dayWorkoutsTime
            : foundActivitiesTime;

        newOverWeek[dayIndex] =
          foundActivitiesTime > dayWorkoutsTime
            ? foundActivitiesTime - dayWorkoutsTime
            : 0;
      }
    }

    setPlanned(newPlannedWeek);
    setDone(newDoneWeek);
    setExtra(newOverWeek);
    buildLabels(newDates);
    setTime(time);
  };

  const buildLabels = (dates = []) => {
    const labels = dates.map((date) => `${date.format("ddd")}`.toUpperCase());
    setLabels(labels);
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: t("profile.stats.chart.trainingLoad.done"),
        backgroundColor: palette.darkBlue,
        borderRadius: 4,
        data: done,
        grouped: false,
        stack: "stacked",
      },
      {
        label: t("profile.stats.chart.trainingLoad.planned"),
        backgroundColor: palette.light,
        borderRadius: 4,
        data: planned,
        grouped: false,
        stack: "noStacked",
      },
      {
        label: t("profile.stats.chart.trainingLoad.extra"),
        backgroundColor: palette.primary,
        borderColor: "rgba(0, 0, 0, 0)",
        borderRadius: 4,
        borderWidth: 0,
        data: extra,
        grouped: false,
        stack: "noStacked",
      },
    ],
  };

  const calculateProgressBar = (achievedTime, plannedTime) => {
    const result = (achievedTime && (achievedTime * 100) / plannedTime) || 0;
    if (!isFinite(result)) return 100;
    return result < 100 ? Math.round(result) : 100;
  };

  const sumEstimatedTime = (data) => {
    let cleanData = data[0] ? data[0] : data;
    const fixLevel = getFixCustomLevel(
      cleanData.plannedDate,
      chainedPlans,
      currentTraining,
    );
    if (Array.isArray(data)) {
      return data
        .map((item) =>
          Array.isArray(item.estimatedTime)
            ? Number(item.estimatedTime[fixLevel])
            : Number(item.estimatedTime),
        )
        .reduce((a, sum) => a + sum, 0);
    } else if (
      typeof data === "object" &&
      data.hasOwnProperty("estimatedTime")
    ) {
      return Array.isArray(data.estimatedTime)
        ? Number(data.estimatedTime[fixLevel])
        : Number(data.estimatedTime);
    } else {
      return 0;
    }
  };

  const activitiesTime = time.length > 0 ? time.reduce((a, sum) => a + sum) : 0;

  const plannedTime = planned.length ? planned.reduce((a, sum) => a + sum) : 0;

  return (
    <Box className={classes.main}>
      <Box className={classes.mainTitleContainer}>
        <Typography className={classes.title}>
          {t("profile.stats.chart.trainingLoad.title").toUpperCase()}
        </Typography>
        <Popper
          sx={{ zIndex: 1200, maxWidth: 300 }}
          open={openTrainingLoad}
          anchorEl={anchorElTrainingLoad}
          placement={placementTrainingLoad}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.paperPopper}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: t("profile.stats.chart.trainingLoad.info"),
                  }}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
        <IconButton size="small" onClick={handlePopperTrainingLoad("top")}>
          <InfoIcon className={classes.infoIcon} />
        </IconButton>
      </Box>

      <Box
        sx={{
          height: 300,
          backgroundColor: palette.white,
          borderRadius: "4px",
          padding: "24px 16px",
          boxSizing: "border-box",
          boxShadow: "0px 1px 15px 0px #12151B0D",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Bar data={data} options={options} />
      </Box>

      <Box sx={{ marginTop: "25px" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="6px"
          marginBottom="5px"
        >
          <TimerIcon fill={palette.secondary} />
          <Box height={10} width="100%">
            <BorderLinearProgress
              variant="determinate"
              value={calculateProgressBar(activitiesTime, plannedTime)}
              color="secondary"
              width="100%"
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" className={classes.statSubValue}>
            {t("profile.stats.chart.trainingLoad.achieved")}:{" "}
            <b>
              {EstimatedTimes.convertMinutesToLabel(activitiesTime, true) ||
                "0min"}
            </b>
          </Typography>
          <Typography variant="body1" className={classes.statSubValue}>
            {t("profile.stats.chart.trainingLoad.planned")}:{" "}
            <b>
              {EstimatedTimes.convertMinutesToLabel(plannedTime, true) ||
                "0min"}
            </b>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
