import React from "react";
import RouterLink from "../../common/router-link";
import { makeStyles } from "@material-ui/core/styles";

const LinksSettings = ({ title, link, externalLink, disabled = false }) => {
  const styles = useStyles({ disabled });

  if (externalLink) {
    return (
      <a
        href={!disabled ? link : "#"}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.subscription}
      >
        <div className={styles.garmmin}>{title}</div>
        <img
          className={styles.iconprofile}
          alt=""
          src={`${process.env.PUBLIC_URL}/Icon_short-arrow.png`}
        />
      </a>
    );
  }

  return (
    <RouterLink to={!disabled ? link : "#"} className={styles.subscription}>
      <div className={styles.garmmin}>{title}</div>
      <img
        className={styles.iconprofile}
        alt=""
        src={`${process.env.PUBLIC_URL}/Icon_short-arrow.png`}
      />
    </RouterLink>
  );
};
export default LinksSettings;

const useStyles = makeStyles({
  iconprofile: {
    position: "relative",
    width: "18px",
    height: "18px",
    overflow: "hidden",
    flexShrink: 0,
    objectFit: "cover",
  },
  subscription: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    textDecoration: "none",
    color: "inherit",
    opacity: (props) => (props.disabled ? 0.3 : 1),
    "&:hover": {
      opacity: 0.9,
    },
  },
  garmmin: {
    flex: 1,
    position: "relative",
    lineHeight: "150%",
  },
});
