const TagManagerArgs = () => {
  if (process.env.REACT_APP_SITE_HOSTNAME !== "app.vert.run")
    return {
      gtmId: "GTM-5P4QSQD",
    };
  else
    return {
      gtmId: "GTM-MTSV5X9",
    };
};

export { TagManagerArgs };
