import { useSelector, useDispatch } from "react-redux";
import {
  addAllez,
  addComment,
  getTags,
  getPosts,
  getPost,
  getExplorePosts,
  getExplorePost,
} from "../actions/trail-head-action";
import { useEffect, useState } from "react";
import useUserInfo from "./useUserInfo";
import { updateCommunityReplyId } from "../actions/community-action";
import { logEvent, setEventsMetadata } from "../lib/events-helper";

const usePost = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { postId, replyId } = useSelector((state) => state.community);
  const {
    tags,
    posts,
    currentPost,
    loadingPosts,
    explorePosts,
    loadingExplorePost,
    currentExplorePost,
    loadingExplorePosts,
    currentExplorePostRelated,
    loadingRelatedExplorePosts,
  } = useSelector((state) => state.trailHead);
  const { sub, name, isSubscribed } = useUserInfo();
  const [likes, setLikes] = useState(0);
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    if (tags.length === 0) {
      dispatch(getTags());
    }
  }, [dispatch, tags]);
  const handleAddLikes = async (id, commentId = null, replyId = null) => {
    if (isAuthenticated) {
      try {
        dispatch(
          addAllez(sub, {
            cognito_user_sub: sub,
            cognito_user_name: name,
            id,
            commentId,
            replyId,
          }),
        );
        setLiked(true);
        setLikes((prevLikes) => prevLikes + 1);

        setEventsMetadata({ customer_id: sub });
        logEvent("add_like_trailhead");
      } catch (error) {
        console.error("Error adding like:", error);
      }
    } else {
      console.error("User not autenticated");
    }
  };

  const handleGetLikes = async (postData) => {
    setLikes(postData.allez_count || 0);
    setLiked(postData.allez.some((allez) => allez.cognito_user_sub === sub));
  };

  const handleAddComment = async (text) => {
    const comment = {
      text,
      link: null,
      image: null,
      imageExtension: null,
      cognito_user_sub: sub,
      cognito_user_name: name,
      comment_to: postId,
      reply_to: replyId,
      crown: isSubscribed,
    };
    if (isAuthenticated) {
      try {
        dispatch(addComment(sub, comment));
        handleSetReplyId(null);
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    } else {
      console.error("User not authenticated");
    }
  };

  const handleSetReplyId = (postId, replyId = null) => {
    dispatch(updateCommunityReplyId({ postId: postId, replyId: replyId }));
  };
  const handleGetUserPosts = (
    lastDate,
    searchPhrase,
    tags,
    showReported,
    reload = false,
  ) => {
    dispatch(getPosts(lastDate, searchPhrase, tags, showReported, reload));
  };
  const handleGetExplorePosts = (lastDate, type, reload = false) => {
    dispatch(getExplorePosts(lastDate, type, reload));
  };

  const handleGetExplorePost = (id) => {
    dispatch(getExplorePost(id));
  };
  const handleGetPost = (id) => {
    dispatch(getPost(id));
  };

  return {
    loadingRelatedExplorePosts,
    currentExplorePostRelated,
    handleGetExplorePosts,
    handleGetExplorePost,
    loadingExplorePosts,
    loadingExplorePost,
    currentExplorePost,
    handleGetUserPosts,
    handleSetReplyId,
    handleAddComment,
    handleAddLikes,
    handleGetLikes,
    handleGetPost,
    loadingPosts,
    explorePosts,
    currentPost,
    replyId,
    postId,
    liked,
    posts,
    likes,
    tags,
  };
};

export default usePost;
