import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const RoundBadge = ({ color = "#FFFFFF", number, width, height }) => {
  const classes = useStyles({ color });

  return (
    <Box
      className={classes.badge}
      style={{
        backgroundColor: color,
        width: width,
        height: height,
      }}
    >
      {!isNaN(number) && (
        <>
          <Box className={classes.badgeNumber}>
            {number > 99 ? "99+" : number}
          </Box>
        </>
      )}
    </Box>
  );
};

RoundBadge.propTypes = {
  color: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  avatarSize: PropTypes.number.isRequired,
};

export default RoundBadge;

const useStyles = makeStyles({
  badge: {
    position: "absolute",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    zIndex: 1,
  },
  badgeNumber: {
    position: "absolute",
    zIndex: 3,
    bottom: -3,
    right: -6,
    width: 18,
    display: "flex",
    height: 18,
    borderRadius: "50%",
    background: (props) => props.color,
    justifyContent: "center",
    alignItems: "center",
    fontSize: 10,
    fontFamily: "Karla",
    textWrap: "pretty",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
});
