import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  AccordionDetails,
  Collapse,
  IconButton,
} from "@material-ui/core";
import {
  IconChevronDown,
  IconPointFilled,
  IconTriangleInvertedFilled,
} from "@tabler/icons-react";

const FaqsComponent = () => {
  const [expandedSections, setExpandedSections] = useState([]);
  const { t } = useTranslation("settings");
  const classes = useStyles();

  const handleExpandClick = (sectionIndex) => {
    setExpandedSections((prev) => {
      if (prev.includes(sectionIndex)) {
        return prev.filter((index) => index !== sectionIndex);
      } else {
        return [...prev, sectionIndex];
      }
    });
  };
  const highlightWord = (text, word) => {
    const index = text.lastIndexOf(word);
    if (index === -1) return text;
    return (
      <span>
        {text.slice(0, index)}
        <span className={classes.accordionAnswerHighlight}>{word}</span>
        {text.slice(index + word.length)}
      </span>
    );
  };

  return (
    <Box className={classes.content}>
      <Box className={classes.faqContainer}>
        <Box className={classes.infoContainer}>
          <Typography className={classes.infoTitle}>
            {t("settings.faqs.title")}
          </Typography>
          <Typography className={classes.infoSubtitle}>
            {t("settings.faqs.subtitle")}
          </Typography>
        </Box>
        {faqData.map((section, sectionIndex) => (
          <Box className={classes.categorySection} key={section.topic}>
            <Box className={classes.sectionTitleContainer}>
              <Typography className={classes.sectionTitle}>
                {t(section.topic)}
              </Typography>
              <IconButton onClick={() => handleExpandClick(sectionIndex)}>
                <IconTriangleInvertedFilled
                  size={14}
                  stroke={2}
                  color="#AFB1B4"
                  style={{
                    transform: expandedSections.includes(sectionIndex)
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              </IconButton>
            </Box>
            <Collapse
              in={expandedSections.includes(sectionIndex)}
              timeout="auto"
              unmountOnExit
            >
              {section.questions.map((question, index) => (
                <Accordion
                  key={index}
                  className={classes.accordion}
                  elevation={0}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<IconChevronDown stroke={2} color="#AFB1B4" />}
                  >
                    <Typography className={classes.accordionTitle}>
                      {t(question.title)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Box className={classes.accordionIcon}>
                      <IconPointFilled size={12} stroke={2} color="#12151B" />
                    </Box>
                    <Typography className={classes.accordionAnswer}>
                      {highlightWord(t(question.answer), t(question.highlight))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Collapse>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FaqsComponent;

const useStyles = makeStyles({
  faqIcon: {
    position: "relative",
    width: "24px",
    height: "24px",
    overflow: "hidden",
    flexShrink: 0,
    display: "none",
  },
  mostFrequentQuestions: {
    position: "relative",
    lineHeight: "150%",
    color: "#6d6f71",
  },
  infoContainer: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "2px",
  },
  sectionTitle: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: 16,
    color: "#12151B",
  },
  arrowIcon: {
    position: "relative",
    width: "14px",
    height: "14px",
    float: "right",
    overflow: "hidden",
    flexShrink: 0,
    objectFit: "cover",
  },
  sectionTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    padding: "0px 8px 0px 0px",
  },
  workoutText: {
    position: "relative",
    lineHeight: "150%",
  },
  categorySection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "16px",
  },
  faqContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "24px",
  },
  content: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "24px 16px",
    boxSizing: "border-box",
    textAlign: "left",
    backgroundColor: "#FAFAFA",
  },
  infoTitle: {
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 700,
    color: "#12151B",
  },
  infoSubtitle: {
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
    color: "#6D6F71",
  },
  accordion: {
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  accordionSummary: {
    padding: "8px 16px",
  },
  accordionDetails: {
    gap: "8px",
    padding: "0px 16px 16px",
  },
  accordionTitle: {
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
    color: "#12151B",
  },
  accordionIcon: {
    padding: "4px 0px",
  },
  accordionAnswer: {
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
    color: "#12151B",
  },
  accordionAnswerHighlight: {
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 700,
    color: "#12151B",
  },
});

const faqData = [
  {
    topic: "settings.faqs.topic1",
    questions: [
      {
        title: "settings.faqs.topic1.question1.title",
        answer: "settings.faqs.topic1.question1.answer",
        highlight: "settings.faqs.topic1.question1.highlight",
      },
      {
        title: "settings.faqs.topic1.question2.title",
        answer: "settings.faqs.topic1.question2.answer",
        highlight: "settings.faqs.topic1.question2.highlight",
      },
      {
        title: "settings.faqs.topic1.question3.title",
        answer: "settings.faqs.topic1.question3.answer",
        highlight: "settings.faqs.topic1.question3.highlight",
      },
      {
        title: "settings.faqs.topic1.question4.title",
        answer: "settings.faqs.topic1.question4.answer",
        highlight: "settings.faqs.topic1.question4.highlight",
      },
      {
        title: "settings.faqs.topic1.question5.title",
        answer: "settings.faqs.topic1.question5.answer",
        highlight: "settings.faqs.topic1.question5.highlight",
      },
      {
        title: "settings.faqs.topic1.question6.title",
        answer: "settings.faqs.topic1.question6.answer",
        highlight: "settings.faqs.topic1.question6.highlight",
      },
      {
        title: "settings.faqs.topic1.question7.title",
        answer: "settings.faqs.topic1.question7.answer",
        highlight: "settings.faqs.topic1.question7.highlight",
      },
      {
        title: "settings.faqs.topic1.question8.title",
        answer: "settings.faqs.topic1.question8.answer",
        highlight: "settings.faqs.topic1.question8.highlight",
      },
      {
        title: "settings.faqs.topic1.question9.title",
        answer: "settings.faqs.topic1.question9.answer",
        highlight: "settings.faqs.topic1.question9.highlight",
      },
      {
        title: "settings.faqs.topic1.question10.title",
        answer: "settings.faqs.topic1.question10.answer",
        highlight: "settings.faqs.topic1.question10.highlight",
      },
      {
        title: "settings.faqs.topic1.question11.title",
        answer: "settings.faqs.topic1.question11.answer",
        highlight: "settings.faqs.topic1.question11.highlight",
      },
    ],
  },
  {
    topic: "settings.faqs.topic2",
    questions: [
      {
        title: "settings.faqs.topic2.question1.title",
        answer: "settings.faqs.topic2.question1.answer",
        highlight: "settings.faqs.topic2.question1.highlight",
      },
      {
        title: "settings.faqs.topic2.question2.title",
        answer: "settings.faqs.topic2.question2.answer",
        highlight: "settings.faqs.topic2.question2.highlight",
      },
      {
        title: "settings.faqs.topic2.question3.title",
        answer: "settings.faqs.topic2.question3.answer",
        highlight: "settings.faqs.topic2.question3.highlight",
      },
      {
        title: "settings.faqs.topic2.question4.title",
        answer: "settings.faqs.topic2.question4.answer",
        highlight: "settings.faqs.topic2.question4.highlight",
      },
      {
        title: "settings.faqs.topic2.question5.title",
        answer: "settings.faqs.topic2.question5.answer",
        highlight: "settings.faqs.topic2.question5.highlight",
      },
      {
        title: "settings.faqs.topic2.question6.title",
        answer: "settings.faqs.topic2.question6.answer",
        highlight: "settings.faqs.topic2.question6.highlight",
      },
    ],
  },
  {
    topic: "settings.faqs.topic3",
    questions: [
      {
        title: "settings.faqs.topic3.question1.title",
        answer: "settings.faqs.topic3.question1.answer",
        highlight: "settings.faqs.topic3.question1.highlight",
      },
      {
        title: "settings.faqs.topic3.question2.title",
        answer: "settings.faqs.topic3.question2.answer",
        highlight: "settings.faqs.topic3.question2.highlight",
      },
      {
        title: "settings.faqs.topic3.question3.title",
        answer: "settings.faqs.topic3.question3.answer",
        highlight: "settings.faqs.topic3.question3.highlight",
      },
      {
        title: "settings.faqs.topic3.question4.title",
        answer: "settings.faqs.topic3.question4.answer",
        highlight: "settings.faqs.topic3.question4.highlight",
      },
    ],
  },
  {
    topic: "settings.faqs.topic4",
    questions: [
      {
        title: "settings.faqs.topic4.question1.title",
        answer: "settings.faqs.topic4.question1.answer",
        highlight: "settings.faqs.topic4.question1.highlight",
      },
      {
        title: "settings.faqs.topic4.question2.title",
        answer: "settings.faqs.topic4.question2.answer",
        highlight: "settings.faqs.topic4.question2.highlight",
      },
      {
        title: "settings.faqs.topic4.question3.title",
        answer: "settings.faqs.topic4.question3.answer",
        highlight: "settings.faqs.topic4.question3.highlight",
      },
      {
        title: "settings.faqs.topic4.question4.title",
        answer: "settings.faqs.topic4.question4.answer",
        highlight: "settings.faqs.topic4.question4.highlight",
      },
      {
        title: "settings.faqs.topic4.question5.title",
        answer: "settings.faqs.topic4.question5.answer",
        highlight: "settings.faqs.topic4.question5.highlight",
      },
    ],
  },
  {
    topic: "settings.faqs.topic5",
    questions: [
      {
        title: "settings.faqs.topic5.question1.title",
        answer: "settings.faqs.topic5.question1.answer",
        highlight: "settings.faqs.topic5.question1.highlight",
      },
      {
        title: "settings.faqs.topic5.question2.title",
        answer: "settings.faqs.topic5.question2.answer",
        highlight: "settings.faqs.topic5.question2.highlight",
      },
    ],
  },
  {
    topic: "settings.faqs.topic6",
    questions: [
      {
        title: "settings.faqs.topic6.question1.title",
        answer: "settings.faqs.topic6.question1.answer",
        highlight: "settings.faqs.topic6.question1.highlight",
      },
      {
        title: "settings.faqs.topic6.question2.title",
        answer: "settings.faqs.topic6.question2.answer",
        highlight: "settings.faqs.topic6.question2.highlight",
      },
      {
        title: "settings.faqs.topic6.question3.title",
        answer: "settings.faqs.topic6.question3.answer",
        highlight: "settings.faqs.topic6.question3.highlight",
      },
      {
        title: "settings.faqs.topic6.question4.title",
        answer: "settings.faqs.topic6.question4.answer",
        highlight: "settings.faqs.topic6.question4.highlight",
      },
      {
        title: "settings.faqs.topic6.question5.title",
        answer: "settings.faqs.topic6.question5.answer",
        highlight: "settings.faqs.topic6.question5.highlight",
      },
      {
        title: "settings.faqs.topic6.question6.title",
        answer: "settings.faqs.topic6.question6.answer",
        highlight: "settings.faqs.topic6.question6.highlight",
      },
      {
        title: "settings.faqs.topic6.question7.title",
        answer: "settings.faqs.topic6.question7.answer",
        highlight: "settings.faqs.topic6.question7.highlight",
      },
      {
        title: "settings.faqs.topic6.question8.title",
        answer: "settings.faqs.topic6.question8.answer",
        highlight: "settings.faqs.topic6.question8.highlight",
      },
      {
        title: "settings.faqs.topic6.question9.title",
        answer: "settings.faqs.topic6.question9.answer",
        highlight: "settings.faqs.topic6.question9.highlight",
      },
    ],
  },
];
