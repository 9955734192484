import React from "react";

import Watch from "./connect-your-wach/components/watch";
import NavbarSettings from "./common/navbar-settings";
import { useTranslation } from "react-i18next";

const ConnectYourWatch = () => {
  const { t } = useTranslation("settings");
  return (
    <>
      <NavbarSettings
        closeTo={"/profile/settings"}
        component={<Watch />}
        text={t("settings.connectYouWatch")}
      />
    </>
  );
};
export default ConnectYourWatch;
