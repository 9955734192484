import React, { useEffect, useRef, useState } from "react";
import { Stack, Skeleton, Divider } from "@mui/material";
import {
  Box,
  Card,
  CardMedia,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  List,
  Collapse,
  Typography,
  IconButton,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CloseIcon, CommentIcon, HeartIcon } from "../../icons";
import CommentItem from "./CommentItem";
import { RaceCard } from "../../profile/Races/race-card";
import CommentTextField from "./CommentTextField";
import dayjs from "dayjs";
import { getImage } from "../../../lib/common";
import { gsap } from "gsap";
import * as relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";
import usePost from "../../../hooks/usePost";
import RouterLink from "../router-link";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

dayjs.extend(relativeTime);

async function fetchImageData(image) {
  try {
    const imageUrl = await getImage([image]);
    return imageUrl;
  } catch (error) {
    return null;
  }
}

const useStyles = makeStyles({
  card: {
    maxWidth: "100%",
    borderRadius: "8px",
    margin: "16px",
    boxShadow: 0,
    backgroundColor: "#FEFFFF",
  },
  avatar: {
    width: "50px",
    height: "50px",
  },
  heading: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  subHeading: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#6D6F71",
  },
  subHeadingBold: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#6D6F71",
  },
  postText: {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#12151B",
    marginBottom: "16px",
  },
  boxContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardMedia: {
    borderRadius: "4px",
    objectFit: "cover",
  },
  cardActions: {
    justifyContent: "space-between",
    padding: "0px",
    marginBottom: "16px",
    marginTop: "16px",
  },
  cardActionsButtons: {
    display: "flex",
    flexWrap: "nowrap",
  },
  replyActions: {
    justifyContent: "space-between",
    padding: "0px",
    backgroundColor: "#EAEAEA",
    alignItems: "center",
  },
});

const PostCardComponent = ({ postData = new Object(), isDialog = false }) => {
  const [imageURL, setImageURL] = useState(null);
  const [avatarURL, setAvatarURL] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const classes = useStyles();

  const {
    handleAddLikes,
    handleGetLikes,
    handleSetReplyId,
    postId,
    replyId,
    liked,
    tags,
  } = usePost();
  const { t, i18n } = useTranslation("community");
  const heartRef = useRef(null);
  const cardActionsRef = useRef(null);
  const commentTextFieldRef = useRef(null);
  const cardActions = {
    cardClick: false,
    share: false,
    menu: false,
    edit: false,
    delete: false,
    link: false,
  };

  const handleLike = () => {
    handleAddLikes(postData.id);
    gsap.fromTo(
      heartRef.current,
      { scale: 1 },
      { scale: 1.3, duration: 0.2, yoyo: true, repeat: 1 },
    );
  };

  const setReplyId = () => {
    handleSetReplyId(postId);
  };

  const handleToggleCollapse = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const focusCommentTextField = () => {
    if (commentTextFieldRef.current) {
      commentTextFieldRef.current.focus();
      commentTextFieldRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (postData.image) {
        const url = await fetchImageData(postData.image);
        setImageURL(url);
      }

      if (postData.by && postData.by.image) {
        const avatarUrl = await fetchImageData(postData.by.image);
        setAvatarURL(avatarUrl);
      }
    };
    handleGetLikes(postData);
    fetchData();
  }, [postData.image, postData.by]);

  useEffect(() => {
    if (postData.id == postId && replyId != null) {
      focusCommentTextField();
      gsap.fromTo(
        cardActionsRef.current,
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.5, ease: "power3.out" },
      );
    }
  }, [replyId]);

  return (
    <Card className={classes.card} elevation={isDialog ? 0 : 2}>
      <CardHeader
        avatar={
          <Link
            to={
              postData.by
                ? `/trailhead/profile/${postData.by.cognito_user_sub}`
                : `/trailhead`
            }
          >
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
              src={
                avatarURL
                  ? avatarURL
                  : `${process.env.PUBLIC_URL}/avatar-empty.png`
              }
            />
          </Link>
        }
        title={
          <Typography className={classes.heading}>
            {postData.by != null && postData.by.cognito_user_name}
          </Typography>
        }
        subheader={
          <Typography className={classes.subHeading}>
            {postData.created_at != null &&
              dayjs(postData.created_at).fromNow()}
          </Typography>
        }
      />
      <CardContent>
        {postData.tags && postData.tags.length > 0 && (
          <Stack
            spacing={1}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            style={{
              marginBottom: "16px",
            }}
          >
            {postData &&
              postData.tags &&
              postData.tags.map((tagId) => {
                const tag = tags.find((tag) => tag.id === tagId);
                return (
                  tag &&
                  tag.id && (
                    <Chip
                      key={tag.id}
                      size="medium"
                      variant="filled"
                      label={tag.name[i18n.language] || tag.name.en}
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#F1F6FD",
                        borderWidth: "0px",
                        marginRight: "4px",
                      }}
                    />
                  )
                );
              })}
          </Stack>
        )}
        {postData && postData.id && (
          <RouterLink to={`/trailhead/post/${postData.id}`}>
            <Typography className={classes.postText}>
              {postData.text}
            </Typography>
            <Box className={classes.boxContent}>
              {postData.image != null && (
                <>
                  {!imageLoaded && (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="350px"
                    />
                  )}
                  <CardMedia
                    component="img"
                    image={imageURL}
                    alt="Post"
                    loading="lazy"
                    className={classes.cardMedia}
                    style={{
                      width: imageLoaded ? "100%" : "0",
                      transition: "opacity 0.3s ease-in-out",
                    }}
                    onLoad={handleImageLoaded}
                  />
                </>
              )}

              {postData.goal != null && (
                <RaceCard race={postData.goal} enableActions={cardActions} />
              )}
            </Box>
          </RouterLink>
        )}
        <CardActions disableSpacing className={classes.cardActions}>
          <Typography component={"span"} className={classes.subHeading}>
            {Object.keys(postData.allez).length > 0 && (
              <>
                {t("community.post.likedBy")}{" "}
                {postData.allez.slice(0, 1).map((allez, index) => (
                  <span key={index} style={{ fontWeight: 700 }}>
                    {allez.cognito_user_name}
                  </span>
                ))}
                {Object.keys(postData.allez).length > 1 && (
                  <>
                    {" "}
                    {t("community.post.likedByConnector")}{" "}
                    <span style={{ fontWeight: 700 }}>
                      {Object.keys(postData.allez).length - 1}{" "}
                      {t("community.post.others")}
                    </span>
                  </>
                )}
              </>
            )}
          </Typography>
          <Box className={classes.cardActionsButtons}>
            <IconButton
              data-testid="heart_icon"
              id="heart_icon"
              disableRipple
              disableFocusRipple
              aria-label="Like"
              onClick={handleLike}
              ref={heartRef}
            >
              <HeartIcon state={liked ? "filled" : "outline"} />
            </IconButton>
            <IconButton
              disableRipple
              disableFocusRipple
              aria-label="comment"
              data-testid="comment_icon"
              id="comment_icon"
              onClick={focusCommentTextField}
            >
              <CommentIcon state="outline" />
            </IconButton>
          </Box>
        </CardActions>
        <Divider flexItem />
        <List>
          {postData.comments.map((comment, index) => (
            <Box key={comment.id}>
              {index === 0 ? (
                <CommentItem
                  key={comment.id}
                  comment={comment}
                  postId={postData.id}
                  commentId={comment.id}
                />
              ) : (
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CommentItem
                    key={comment.id}
                    comment={comment}
                    postId={postData.id}
                    commentId={comment.id}
                  />
                </Collapse>
              )}
            </Box>
          ))}
          {Object.keys(postData.comments).length > 1 && (
            <Typography
              component={"span"}
              className={classes.subHeading}
              onClick={handleToggleCollapse}
            >
              {expanded
                ? `${t("community.post.hide")} ${Object.keys(postData.comments)
                    .length - 1} ${t("community.post.comments")}`
                : `${t("community.post.viewAll")} ${Object.keys(
                    postData.comments,
                  ).length - 1} ${t("community.post.comments")}`}
            </Typography>
          )}
        </List>
        {postData.id == postId && replyId != null && (
          <CardActions ref={cardActionsRef} className={classes.replyActions}>
            <Typography
              component={"span"}
              className={classes.subHeading}
              style={{
                marginLeft: "8px",
              }}
            >
              {t("community.post.replyingTo")}{" "}
              {
                <Typography
                  component={"span"}
                  className={classes.subHeadingBold}
                >
                  {
                    postData.comments.find((comment) => comment.id == replyId)
                      .by.cognito_user_name
                  }
                </Typography>
              }
            </Typography>
            <IconButton
              disableRipple
              disableFocusRipple
              aria-label="close"
              onClick={setReplyId}
            >
              <CloseIcon
                data-testid="close_icon"
                id="close_icon"
                width="17px"
                height="17px"
              />
            </IconButton>
          </CardActions>
        )}
        <CommentTextField
          parentId={postData.id}
          reference={commentTextFieldRef}
        />
      </CardContent>
    </Card>
  );
};

export default PostCardComponent;
