import ActionTypes from "../constants/action-types";
import UserPlansService from "../services/user-plans-service";
import ReferralsService from "../services/referrals-service";
import TrailHeadService from "../services/trail-head-service";
import ChallengesService from "../services/challenges-service";
import { subscribe } from "./pusher-action";
import { getNotifications } from "./notifications-action";
import { setShowUpate } from "./auth-action";
import { Capacitor } from "@capacitor/core";
import moment from "moment";
import { dateFormat } from "../lib/date-helper";
import {
  clearActivityGarmin,
  getActivitiesGarmin,
  getActivityGarmin,
  exportWorkoutGarmin,
} from "./garmin-action";
import {
  clearActivitySuunto,
  getActivitiesSuunto,
  getActivitySuunto,
  exportSuuntoWorkout,
} from "./suunto-action";
import { clearActivity, getActivities, getActivity } from "./strava-action";
import {
  clearActivityVert,
  getActivitiesVert,
  getActivityVert,
} from "./vert-activity-action";
import { ACTIVITY_TYPES } from "../lib/activities-helper";
import {
  clearActivityHealthKit,
  getActivityHealthKit,
} from "./healthkit-action";
import { updateAthleteDataLocally } from "./athletes-action";
import {
  getActivitiesCoros,
  getActivityCoros,
  clearActivityCoros,
} from "./coros-action";
import { logEvent, setEventsMetadata } from "../lib/events-helper";

const setCurrentPlan = (plan) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_SET_CURRENT_PLAN, payload: plan });
  };
};

const setLoginCount = (count) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_LOGIN_COUNT, payload: count });
  };
};
const setPlanToSelect = (plan) => {
  return (dispatch) => {
    if (
      (plan.cognito_user_email, plan.cognito_user_sub && plan.id && plan.plan)
    )
      dispatch(
        userPlanMailchimpSubscribe({
          selectedPlan: plan.plan,
          subcategory: plan.id,
          cognito_user_sub: plan.cognito_user_sub,
          cognito_user_email: plan.cognito_user_email,
          onClickPlan: true,
        }),
      );
    dispatch({
      type: ActionTypes.USER_PLANS_SET_PLAN_TO_SELECT,
      payload: plan,
    });
  };
};

const planChangeField = (planTempData) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_CHANGE_FIELD,
      payload: planTempData,
    });
  };
};

const planCleanFields = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_CLEAN_FIELDS });
  };
};

const setErrorMessage = (err) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_SET_ERROR_MESSAGE, payload: err });
  };
};

const clearErrorMessage = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_CLEAR_ERROR_MESSAGE });
  };
};

const setSuccessMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_SET_SUCCESS_MESSAGE,
      payload: message,
    });
  };
};

const clearSuccessMessage = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_CLEAR_SUCCESS_MESSAGE });
  };
};

const savePlan = (
  plan,
  latestSubscription,
  subcategories,
  workoutNotificationsTime = null,
  coachChoose = false,
) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_SAVE_PENDING });
    try {
      let result = null;
      if (coachChoose) {
        result = await UserPlansService.saveCurrentUserPlanCoachChoose(plan);

        const {
          raceCount,
          trainPerWeek,
          desiredStartDate,
          trainHoursPerWeek_raw: trainHoursPerWeek,
          message,
        } = plan;
      } else {
        result = await UserPlansService.saveCurrentUserPlan(
          plan,
          latestSubscription,
          subcategories,
          workoutNotificationsTime,
        );
      }
      dispatch({
        type: ActionTypes.USER_PLANS_SAVE_FULFILLED,
        payload: result,
      });
      dispatch(userPlanMailchimpSubscribe(result));
      dispatch(planCleanFields());
    } catch (exp) {
      dispatch({ type: ActionTypes.USER_PLANS_SAVE_REJECTED, payload: exp });
    }
  };
};

const updatePlan = (
  plan,
  showSuccessMessage = true,
  redirectToLanding = false,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_UPDATE_PENDING,
      payload: showSuccessMessage,
    });
    try {
      const result = await UserPlansService.updateCurrentUserInfo(plan);

      if (plan.coros_auth === null) {
        dispatch({
          type: ActionTypes.COROS_DISCONNECT_FULFILLED,
          payload: result,
          redirect: redirectToLanding,
        });
      }
      dispatch({
        type: ActionTypes.USER_PLANS_UPDATE_FULFILLED,
        payload: result,
        redirect: redirectToLanding,
      });
    } catch (exp) {
      dispatch({ type: ActionTypes.USER_PLANS_UPDATE_REJECTED, payload: exp });
      await dispatch(getCurrentPlan(false));
    }
  };
};

const updatePlanSwap = (
  workoutsToMoveData,
  currentTraining,
  plan,
  showSuccessMessage = true,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_CT_UPDATE_PENDING,
      payload: { showSuccessMessage, blockWeek: true },
    });
    try {
      const result = await UserPlansService.swapWorkouts(
        workoutsToMoveData,
        currentTraining,
        plan,
      );

      if (workoutsToMoveData.cognito_user_sub) {
        setEventsMetadata({ custId: workoutsToMoveData.cognito_user_sub });
      }
      logEvent("move_workout");
      dispatch({
        type: ActionTypes.USER_PLANS_CT_UPDATE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_CT_UPDATE_REJECTED,
        payload: exp,
      });
      await dispatch(getCurrentPlan(false));
    }
  };
};

const updatePlanRedoWeek = (
  data,
  currentTraining,
  plan,
  showSuccessMessage = true,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_CT_UPDATE_PENDING,
      payload: { showSuccessMessage, blockWeek: true },
    });
    try {
      const result = await UserPlansService.redoWeek(
        data,
        currentTraining,
        plan,
      );

      if (data.cognito_user_sub) {
        setEventsMetadata({ custId: data.cognito_user_sub });
      }
      logEvent("redo_this_week");
      dispatch({
        type: ActionTypes.USER_PLANS_CT_UPDATE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_CT_UPDATE_REJECTED,
        payload: exp,
      });
      await dispatch(getCurrentPlan(false));
    }
  };
};

const updatePlanAddEasyWeek = (
  data,
  currentTraining,
  raceCount,
  trainPerWeek,
  plan,
  showSuccessMessage = true,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_CT_UPDATE_PENDING,
      payload: { showSuccessMessage, blockWeek: true },
    });
    try {
      const result = await UserPlansService.addEasyWeek(
        data,
        currentTraining,
        raceCount,
        trainPerWeek,
        plan,
      );
      if (data.cognito_user_sub) {
        setEventsMetadata({ custId: data.cognito_user_sub });
      }
      logEvent("add_easy_week");

      dispatch({
        type: ActionTypes.USER_PLANS_CT_UPDATE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_CT_UPDATE_REJECTED,
        payload: exp,
      });
      await dispatch(getCurrentPlan(false));
    }
  };
};

const autoAssignPlan = (plan, showSuccessMessage = false) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_UPDATE_PENDING,
      payload: { showSuccessMessage, blockWeek: true },
    });
    try {
      const result = await UserPlansService.autoAssignTrainingPlan(plan);
      dispatch({
        type: ActionTypes.USER_PLANS_UPDATE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({ type: ActionTypes.USER_PLANS_UPDATE_REJECTED, payload: exp });
    }
  };
};

const updatePlanAddWorkout = (
  data,
  currentTraining,
  plan,
  showSuccessMessage = true,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_CT_UPDATE_PENDING,
      payload: showSuccessMessage,
    });
    try {
      const result = await UserPlansService.addWorkout(
        data,
        currentTraining,
        plan,
      );
      if (data.cognito_user_sub) {
        setEventsMetadata({ custId: data.cognito_user_sub });
      }
      logEvent("add_workout");
      dispatch({
        type: ActionTypes.USER_PLANS_CT_UPDATE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_CT_UPDATE_REJECTED,
        payload: exp,
      });
      await dispatch(getCurrentPlan(false));
    }
  };
};

const updatePlanImage = (plan) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_UPDATE_IMAGE_PENDING });
    try {
      const result = await UserPlansService.updateCurrentUserInfo(plan);
      dispatch({
        type: ActionTypes.USER_PLANS_UPDATE_IMAGE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_UPDATE_IMAGE_REJECTED,
        payload: exp,
      });
    }
  };
};

const updatePlanAddImage = (imgObj) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_ADD_IMAGE_PENDING });
    try {
      const result = await UserPlansService.addImage(imgObj);
      dispatch({
        type: ActionTypes.USER_PLANS_ADD_IMAGE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_ADD_IMAGE_REJECTED,
        payload: exp,
      });
    }
  };
};

const updatePlanDeleteImage = (imgObj) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_DELETE_IMAGE_PENDING });
    try {
      const result = await UserPlansService.deleteImage(imgObj);
      dispatch({
        type: ActionTypes.USER_PLANS_DELETE_IMAGE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_DELETE_IMAGE_REJECTED,
        payload: exp,
      });
    }
  };
};

const updateLastLogin = (plan) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_UPDATE_LAST_LOGIN_PENDING });
    try {
      const result = await UserPlansService.updateCurrentUserInfo(plan);
      dispatch({
        type: ActionTypes.USER_PLANS_UPDATE_LAST_LOGIN_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_UPDATE_LAST_LOGIN_REJECTED,
        payload: exp,
      });
    }
  };
};

const updateLoad = (plan) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_UPDATE_LOAD_PENDING });
    try {
      const result = await UserPlansService.updateCurrentUserInfo(plan);
      dispatch({
        type: ActionTypes.USER_PLANS_UPDATE_LOAD_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_UPDATE_LOAD_REJECTED,
        payload: exp,
      });
    }
  };
};

const getReferralCode = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_REFERRAL_CODE_PENDING });
    try {
      const result = await ReferralsService.getReferralCode();
      dispatch({
        type: ActionTypes.USER_PLANS_REFERRAL_CODE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_REFERRAL_CODE_REJECTED,
        payload: exp,
      });
    }
  };
};

const useRaffleCode = (code, cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_RAFFLE_CODE_PENDING });
    try {
      const result = await ReferralsService.useRaffleCode(
        cognito_user_sub,
        code,
      );
      dispatch({
        type: ActionTypes.USER_PLANS_RAFFLE_CODE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_RAFFLE_CODE_REJECTED,
        payload: exp,
      });
    }
  };
};

const getNextTrainingPlan = (planData, current_training_order = 1) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_NEXT_PLAN_GET_PENDING });
    try {
      const result = await UserPlansService.getTrainingPlan(
        planData,
        current_training_order + 1,
      );
      dispatch({
        type: ActionTypes.USER_NEXT_PLAN_GET_FULFILLED,
        payload: !!result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_NEXT_PLAN_GET_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getCurrentPlan = (
  showLoading = true,
  userData = null,
  loadNotifications = true,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_LOAD_CURRENT_PLAN_PENDING,
      payload: showLoading,
    });
    try {
      const res = await UserPlansService.getLatestCurrentUserPlan();
      const { result, version } = res || {};
      const { cognito_user_sub } = result || {};
      if (version && localStorage.getItem("version")) {
        if (version !== localStorage.getItem("version")) {
          if (Capacitor.isNativePlatform()) {
            await dispatch(setShowUpate(true));
          } else {
            try {
              console.log(
                "NEEDS RELOAD",
                version,
                localStorage.getItem("version"),
              );
              localStorage.setItem("version", version);

              window.location.reload();
            } catch (e) {
              console.log(e);
            }
          }
        }
      }
      dispatch({
        type: ActionTypes.USER_PLANS_LOAD_CURRENT_PLAN_FULFILLED,
        payload: result,
      });
      if (userData && (!result || !result.mailchimp)) {
        await dispatch(userPlanMailchimpSubscribe(userData, false));
      }
      await dispatch(subscribe(cognito_user_sub));
      await dispatch(getNotifications(false));
      if (
        window.location.pathname &&
        !window.location.pathname.startsWith("/dashboard/chat")
      ) {
        await dispatch(
          currentTrainingGetComments(new Date().getTime(), cognito_user_sub),
        );
      }
      if (cognito_user_sub) {
        await dispatch(
          updatePlan(
            {
              cognito_user_sub: cognito_user_sub,
              lastGetPlan: new Date().getTime(),
              latestDevice: Capacitor.isNativePlatform
                ? Capacitor.getPlatform()
                : "web",
            },
            false,
            false,
          ),
        );
      }
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_LOAD_CURRENT_PLAN_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getTraining = (plan) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_LOAD_TRAINING_PENDING });
    try {
      const result = await UserPlansService.getTrainingPlanFromId(plan);
      dispatch({
        type: ActionTypes.USER_PLANS_LOAD_TRAINING_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_LOAD_TRAINING_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getTrainingPrev = (plan) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_PREV_LOAD_TRAINING_PENDING });
    try {
      const result = await UserPlansService.getTrainingPlanFromId(plan);
      dispatch({
        type: ActionTypes.USER_PLANS_PREV_LOAD_TRAINING_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_PREV_LOAD_TRAINING_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getCurrentTraining = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_LOAD_CURRENT_TRAINING_PENDING });
    try {
      const result = await UserPlansService.getLatestCurrentUserPlan();
      dispatch({
        type: ActionTypes.USER_PLANS_LOAD_CURRENT_TRAINING_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_LOAD_CURRENT_TRAINING_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const planChangeCurrentStep = (currentStep) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_CHANGE_CURRENT_STEP,
      payload: currentStep,
    });
  };
};

const currentTrainingCurrentStartDateChange = (dayCount) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_CURRENT_TRAINING_CURRENT_START_DATE_CHANGED,
      payload: dayCount,
    });
  };
};

const currentTrainingCurrentStartDateSet = (date) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_CURRENT_TRAINING_CURRENT_START_DATE_SET,
      payload: date,
    });
  };
};

const currentTrainingUpdateWorkoutIsDone = (
  workout,
  currentTraining,
  plan,
  showSuccessMessage = true,
  selectTrainingAfterUpdate = false,
) => {
  return async (dispatch) => {
    dispatch({
      type:
        ActionTypes.USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_PENDING,
      payload: {
        workoutId: {
          workoutId: workout.workoutId,
          plannedDate: workout.plannedDate,
        },
        showSuccessMessage: showSuccessMessage,
      },
    });
    try {
      const result = await UserPlansService.currentTrainingUpdateWorkoutIsDone(
        workout,
        currentTraining,
        plan,
      );

      dispatch({
        type:
          ActionTypes.USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_FULFILLED,
        payload: result,
      });
      if (selectTrainingAfterUpdate) {
        dispatch({
          type: ActionTypes.USER_PLANS_CURRENT_TRAINING_SET_SELECTED_TEMP,
          payload: workout,
        });
      }
    } catch (exp) {
      dispatch({
        type:
          ActionTypes.USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_REJECTED,
        payload: exp,
      });
      await dispatch(getCurrentPlan(false));
    }
  };
};

const currentTrainingUpdateWorkoutEnergyLevel = (
  workout,
  currentTraining,
  plan,
  showSuccessMessage = true,
  selectTrainingAfterUpdate = false,
) => {
  return async (dispatch) => {
    dispatch({
      type:
        ActionTypes.USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_PENDING,
      payload: {
        workoutId: workout.workoutId,
        showSuccessMessage: showSuccessMessage,
      },
    });
    try {
      const result = await UserPlansService.currentTrainingUpdateWorkoutEnergyLevel(
        workout,
        currentTraining,
        plan,
      );

      dispatch({
        type:
          ActionTypes.USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_FULFILLED,
        payload: result,
      });
      if (selectTrainingAfterUpdate) {
        dispatch({
          type: ActionTypes.USER_PLANS_CURRENT_TRAINING_SET_SELECTED_TEMP,
          payload: workout,
        });
      }
    } catch (exp) {
      dispatch({
        type:
          ActionTypes.USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_REJECTED,
        payload: exp,
      });
      await dispatch(getCurrentPlan(false));
    }
  };
};

const currentTrainingUpdateWorkoutStatusAndEnergy = (
  workout,
  currentTraining,
  plan,
  showSuccessMessage = true,
  selectTrainingAfterUpdate = false,
) => {
  return async (dispatch) => {
    dispatch({
      type:
        ActionTypes.USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_PENDING,
      payload: {
        workoutId: workout.workoutId,
        showSuccessMessage: showSuccessMessage,
      },
    });
    try {
      const result = await UserPlansService.currentTrainingUpdateWorkoutStatusAndEnergy(
        workout,
        currentTraining,
        plan,
      );

      dispatch({
        type:
          ActionTypes.USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_FULFILLED,
        payload: result,
      });
      if (selectTrainingAfterUpdate) {
        dispatch({
          type: ActionTypes.USER_PLANS_CURRENT_TRAINING_SET_SELECTED_TEMP,
          payload: workout,
        });
      }
    } catch (exp) {
      dispatch({
        type:
          ActionTypes.USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_REJECTED,
        payload: exp,
      });
      await dispatch(getCurrentPlan(false));
    }
  };
};

const currentTrainingGetComments = (lastDate, cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_GET_COMMENTS_PENDING,
    });
    try {
      const result = await UserPlansService.getComments(
        lastDate,
        cognito_user_sub,
      );
      dispatch({
        type: ActionTypes.USER_PLANS_GET_COMMENTS_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_GET_COMMENTS_REJECTED,
        payload: exp,
      });
    }
  };
};

const currentTrainingGetOldComments = (lastDate, cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_GET_OLD_COMMENTS_PENDING,
    });
    try {
      const result = await UserPlansService.getComments(
        lastDate,
        cognito_user_sub,
      );
      dispatch({
        type: ActionTypes.USER_PLANS_GET_OLD_COMMENTS_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_GET_OLD_COMMENTS_REJECTED,
        payload: exp,
      });
    }
  };
};

const currentTrainingAddComment = (comment) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_ADD_COMMENT_PENDING,
    });
    try {
      const result = await UserPlansService.currentTrainingAddComment(comment);
      dispatch({
        type: ActionTypes.USER_PLANS_ADD_COMMENT_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_ADD_COMMENT_REJECTED,
        payload: exp,
      });
    }
  };
};

const currentTrainingAddCommentsRead = (cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_COMMENTS_READ_PENDING,
    });
    try {
      const result = await UserPlansService.currentTrainingAddCommentsRead(
        cognito_user_sub,
      );
      dispatch({
        type: ActionTypes.USER_PLANS_COMMENTS_READ_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_COMMENTS_READ_REJECTED,
      });
    }
  };
};

const addNote = (note, cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_ADD_NOTE_PENDING,
      payload: {
        note,
        cognito_user_sub,
      },
    });
    try {
      const result = await UserPlansService.addNote(note, cognito_user_sub);
      dispatch({
        type: ActionTypes.USER_PLANS_ADD_NOTE_FULFILLED,
        payload: result,
      });
      await dispatch(getCurrentPlan(false));
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_ADD_NOTE_REJECTED,
        payload: exp,
      });
      await dispatch(getCurrentPlan(false));
    }
  };
};

const deleteNote = (note, cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_DELETE_NOTE_PENDING,
      payload: {
        note,
        cognito_user_sub,
      },
    });
    try {
      const result = await UserPlansService.deleteNote(note, cognito_user_sub);
      dispatch({
        type: ActionTypes.USER_PLANS_DELETE_NOTE_FULFILLED,
        payload: result,
      });
      await dispatch(getCurrentPlan(false));
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_DELETE_NOTE_REJECTED,
        payload: exp,
      });
      await dispatch(getCurrentPlan(false));
    }
  };
};

const editNote = (note, cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_EDIT_NOTE_PENDING,
      payload: {
        note,
        cognito_user_sub,
      },
    });
    try {
      const result = await UserPlansService.editNote(note, cognito_user_sub);
      dispatch({
        type: ActionTypes.USER_PLANS_EDIT_NOTE_FULFILLED,
        payload: result,
      });
      await dispatch(getCurrentPlan(false));
    } catch (exp) {
      dispatch({
        type: ActionTypes.USER_PLANS_EDIT_DELETE_REJECTED,
        payload: exp,
      });
      await dispatch(getCurrentPlan(false));
    }
  };
};

const setTrainingDayWorkout = (workout) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_CURRENT_TRAINING_SET_SELECTED_TEMP,
      payload: workout,
    });
  };
};

const userPlanMailchimpSubscribe = (params) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_MAILCHIMP_SUBSCRIBE,
      payload: UserPlansService.userPlanMailchimpSubscribe(params),
    });
  };
};

const deleteAccount = (cognito_user_email) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_DELETE_ACCOUNT,
      payload: UserPlansService.deleteAccount(cognito_user_email),
    });
  };
};

const toggleOpenMenu = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_TOOGLE_OPEN_MENU });
  };
};

const getEpicRaces = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_EPIC_RACES_PENDING });
    try {
      const result = await TrailHeadService.getEpicRaces();
      dispatch({
        type: ActionTypes.GET_EPIC_RACES_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.GET_EPIC_RACES_REJECTED,
        payload: exp,
      });
    }
  };
};

const getUserBadges = (cognito_user_sub, lastDate) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CHALLENGE_GET_USER_BADGES_PENDING });
    try {
      const result = await ChallengesService.getUserBadges(cognito_user_sub);
      dispatch({
        type: ActionTypes.CHALLENGE_GET_USER_BADGES_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.CHALLENGE_GET_USER_BADGES_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const clearTempTraining = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_CURRENT_TEMP_TRAINING,
      payload: null,
    });
  };
};

const getUserActivities = (currentPlan) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_LOAD_ACTIVITIES_PENDING });
    try {
      const {
        currentTraining,
        strava_athlete_id,
        garmin_oauth_token,
        suunto_auth,
        cognito_user_sub,
        coros_auth,
      } = currentPlan || "";

      const servicePromises = [];
      const GET_IN_DAYS_DEFAULT = -90;
      const firstDay =
        currentTraining && currentTraining.days && currentTraining.days.length
          ? currentTraining.days.filter((d) => !!d)[0]
          : null;

      let currentStartDate = firstDay
        ? moment(firstDay[0].plannedDate, dateFormat)
        : moment().add(GET_IN_DAYS_DEFAULT, "days");

      currentStartDate = moment()
        .add(GET_IN_DAYS_DEFAULT, "days")
        .isBefore(currentStartDate, "days")
        ? moment().add(GET_IN_DAYS_DEFAULT, "days")
        : currentStartDate;

      if (strava_athlete_id) {
        servicePromises.push(
          dispatch(
            getActivities({
              start_date: moment(currentStartDate, dateFormat)
                .startOf("isoWeek")
                .format(dateFormat),
              end_date: moment()
                .endOf("isoWeek")
                .format(dateFormat),
              strava_athlete_id: strava_athlete_id,
              cognito_user_sub,
            }),
          ),
        );
      }

      if (garmin_oauth_token) {
        servicePromises.push(
          dispatch(
            getActivitiesGarmin({
              start_date: moment(currentStartDate, dateFormat)
                .startOf("isoWeek")
                .unix(),
              end_date: moment()
                .endOf("isoWeek")
                .unix(),
              oauth_token: garmin_oauth_token,
              cognito_user_sub,
            }),
          ),
        );
      }

      if (suunto_auth) {
        servicePromises.push(
          dispatch(
            getActivitiesSuunto({
              start_date: moment(currentStartDate, dateFormat)
                .startOf("isoWeek")
                .unix(),
              end_date: moment()
                .endOf("isoWeek")
                .unix(),
              access_token: suunto_auth.access_token,
              cognito_user_sub,
              expires_at: suunto_auth.expires_at,
              refresh_token: suunto_auth.refresh_token,
            }),
          ),
        );
      }

      if (coros_auth) {
        servicePromises.push(
          dispatch(
            getActivitiesCoros({
              start_date: moment(currentStartDate, dateFormat)
                .startOf("isoWeek")
                .format(dateFormat),
              end_date: moment()
                .endOf("isoWeek")
                .format(dateFormat),
              cognito_user_sub,
            }),
          ),
        );
      }

      servicePromises.push(
        dispatch(
          getActivitiesVert({
            start_date: moment(currentStartDate)
              .startOf("isoWeek")
              .unix(),
            end_date: moment()
              .endOf("isoWeek")
              .unix(),
            cognito_user_sub,
          }),
        ),
      );

      await Promise.all(servicePromises);

      dispatch({
        type: ActionTypes.ATHLETES_LOAD_ACTIVITIES_FULFILLED,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.ATHLETES_LOAD_ACTIVITIES_REJECTED,
      });
    }
  };
};

const clearCurrentUserActivity = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_CURRENT_ACTIVITY_PENDING });
    try {
      dispatch(clearActivity());
      dispatch(clearActivityGarmin());
      dispatch(clearActivitySuunto());
      dispatch(clearActivityVert());
      dispatch(clearActivityHealthKit());
      dispatch(clearActivityCoros());
      dispatch({
        type: ActionTypes.CLEAR_CURRENT_ACTIVITY_FULLFILLED,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.CLEAR_CURRENT_ACTIVITY_REJECTED,
      });
    }
  };
};

const getUserActivity = (activity, currentPlan) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_CURRENT_ACTIVITY_PENDING });
    try {
      const {
        strava_athlete_id,
        garmin_oauth_token,
        suunto_auth,
        cognito_user_sub,
      } = currentPlan || "";
      const { access_token, expires_at, refresh_token } = suunto_auth || {};
      const { device: type } = activity;

      switch (type) {
        case ACTIVITY_TYPES.VERT_RUN:
          dispatch(
            getActivityVert({
              cognito_user_sub,
              workoutId: activity.created_at,
            }),
          );
          break;
        case ACTIVITY_TYPES.GARMIN:
          dispatch(
            getActivityGarmin({
              oauth_token: garmin_oauth_token,
              summaryId: activity.summaryId,
            }),
          );
          break;
        case ACTIVITY_TYPES.SUUNTO:
          dispatch(
            getActivitySuunto(
              {
                workoutId: activity.workoutKey,
                cognito_user_sub,
                expires_at,
                refresh_token,
                access_token,
              },
              activity,
            ),
          );
          break;
        case ACTIVITY_TYPES.COROS:
          dispatch(
            getActivityCoros(
              {
                customer_id: cognito_user_sub,
                activity_id: activity.ID,
              },
              activity,
            ),
          );
          break;

        case ACTIVITY_TYPES.STRAVA:
          dispatch(
            getActivity({
              id: activity.activityId ? activity.activityId : activity.id,
              strava_athlete_id,
            }),
          );
          break;

        case ACTIVITY_TYPES.HEALTHKIT:
          dispatch(getActivityHealthKit(activity));
          break;

        default:
          throw "Activity type not handled";
      }

      dispatch({
        type: ActionTypes.GET_CURRENT_ACTIVITY_FULLFILLED,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.GET_CURRENT_ACTIVITY_REJECTED,
      });
    }
  };
};

const activeCampaignInvited = (cognito_user_sub, data) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ACTIVE_CAMPAIGN_INVITED_PENDING });
    try {
      const result = await UserPlansService.activeCampaignInvited(
        cognito_user_sub,
        data,
      );
      dispatch({
        type: ActionTypes.ACTIVE_CAMPAIGN_INVITED_FULLFILED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ACTIVE_CAMPAIGN_INVITED_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const exportWorkoutToGarmin = (
  currentPlan,
  selectedTrainingDayWorkoutTemp,
  level,
  useRpe,
) => {
  const { garmin_oauth_token, garmin_oauth_token_secret } = currentPlan || "";
  return async (dispatch) => {
    try {
      const result = await dispatch(
        exportWorkoutGarmin({
          oauth_token: garmin_oauth_token,
          oauth_token_secret: garmin_oauth_token_secret,
          selectedTrainingDayWorkoutTemp,
          level,
          useRpe,
        }),
      );
      dispatch({
        type: ActionTypes.EXPORT_WORKOUT_FULFILLED,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.EXPORT_WORKOUT_UNFULFILLED,
      });
    }
  };
};

const exportWorkoutToSuunto = (
  currentPlan,
  selectedTrainingDayWorkoutTemp,
  level,
  useRpe,
) => {
  const {
    garmin_oauth_token,
    garmin_oauth_token_secret,
    suunto_auth,
    cognito_user_sub,
  } = currentPlan || "";
  return async (dispatch) => {
    try {
      const workout_id = selectedTrainingDayWorkoutTemp.id;
      console.log(
        "selectedTrainingDayWorkoutTemp -> " +
          JSON.stringify(selectedTrainingDayWorkoutTemp),
      );

      const result = await dispatch(
        exportSuuntoWorkout({
          workout_id,
          access_token: suunto_auth,
          level,
          cognito_user_sub,
          date: selectedTrainingDayWorkoutTemp.plannedDate,
        }),
      );
      dispatch({
        type: ActionTypes.EXPORT_WORKOUT_FULFILLED,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.EXPORT_WORKOUT_UNFULFILLED,
      });
    }
  };
};

const editingMode = (cognito_user_sub, status) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.EDITING_PLAN_PENDING,
    });
    try {
      const result = await UserPlansService.editingPlan(
        cognito_user_sub,
        status,
      );

      dispatch(updateAthleteDataLocally(result));

      dispatch({
        type: ActionTypes.EDITING_PLAN_FULLFILED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.EDITING_PLAN_REJECTED,
      });
    }
  };
};
const getPaginatedPlan = (fromDate, toDate, cognito_user_sub) => {
  return async (dispatch) => {
    console.log(
      "paginatedPlan getPaginatedPlan",
      fromDate,
      toDate,
      cognito_user_sub,
    );
    dispatch({ type: ActionTypes.GET_PAGINATED_PLAN_PENDING });
    try {
      dispatch({
        type: ActionTypes.GET_PAGINATED_PLAN_FULFILLED,
        payload: { fromDate, toDate },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_PAGINATED_PLAN_REJECTED,
        payload: error,
        error: true,
      });
    }
  };
};
const setCustomerCoachRate = (cognito_user_sub, rate, message) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CUSTOMER_COACH_RATE_SAVE_PENDING });
    try {
      const result = await UserPlansService.custumerCoachRate(
        cognito_user_sub,
        rate,
        message,
      );
      dispatch({
        type: ActionTypes.CUSTOMER_COACH_RATE_SAVE,
        payload: result,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CUSTOMER_COACH_RATE_SAVE_REJECTED,
        payload: error,
        error: true,
      });
    }
  };
};

const setCustomerPlanRate = (cognito_user_sub, rate, message) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CUSTOMER_PLAN_RATE_SAVE_PENDING });
    try {
      const result = await UserPlansService.custumerPlanRate(
        cognito_user_sub,
        rate,
        message,
      );
      dispatch({
        type: ActionTypes.CUSTOMER_PLAN_RATE_SAVE,
        payload: result,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CUSTOMER_PLAN_RATE_SAVE_REJECTED,
        payload: error,
        error: true,
      });
    }
  };
};
const setrequestCoachChange = (email, message) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.REQUEST_COACH_CHANGE_SAVE_PENDING });
    try {
      const result = await UserPlansService.requestCoachChange(email, message);
      dispatch({
        type: ActionTypes.REQUEST_COACH_CHANGE_SAVE,
        payload: result,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.REQUEST_COACH_CHANGE_SAVE_REJECTED,
        payload: error,
        error: true,
      });
    }
  };
};

const checkUserHasPlan = (cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.USER_PLANS_LOADING_HAS_PLAN });
    try {
      const result = await UserPlansService.hasPlan(cognito_user_sub);
      dispatch({
        type: ActionTypes.USER_PLANS_SET_HAS_PLAN,
        payload: result.hasPlan,
      });
    } catch (error) {
      dispatch({ type: ActionTypes.USER_PLANS_SET_HAS_PLAN_REJECTED });
    } finally {
      dispatch({ type: ActionTypes.USER_PLANS_LOADING_HAS_PLAN_DONE });
    }
  };
};

const setUnreadMessages = (count) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_UNREAD_MESSAGES,
      payload: count,
    });
  };
};
const setNewMessagesByEvent = (message) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.USER_PLANS_NEW_MESSAGES_BY_EVENT,
      payload: message,
    });
  };
};

export {
  setCurrentPlan,
  setPlanToSelect,
  planChangeField,
  planCleanFields,
  setErrorMessage,
  clearErrorMessage,
  setSuccessMessage,
  clearSuccessMessage,
  savePlan,
  updatePlan,
  updatePlanSwap, //
  updatePlanRedoWeek, //
  updatePlanAddEasyWeek, //
  updatePlanAddWorkout, //
  autoAssignPlan,
  updatePlanImage,
  updatePlanAddImage,
  updatePlanDeleteImage,
  updateLastLogin,
  updateLoad,
  planChangeCurrentStep,
  getNextTrainingPlan,
  getCurrentPlan,
  getCurrentTraining,
  currentTrainingCurrentStartDateChange,
  currentTrainingCurrentStartDateSet,
  currentTrainingUpdateWorkoutIsDone, //
  currentTrainingUpdateWorkoutEnergyLevel, //
  currentTrainingAddComment,
  currentTrainingAddCommentsRead,
  currentTrainingGetComments,
  currentTrainingGetOldComments,
  setTrainingDayWorkout,
  userPlanMailchimpSubscribe,
  addNote,
  deleteNote,
  editNote,
  getReferralCode,
  useRaffleCode,
  getTraining,
  getTrainingPrev,
  toggleOpenMenu,
  getEpicRaces,
  getUserBadges,
  clearTempTraining,
  deleteAccount,
  getUserActivities,
  clearCurrentUserActivity,
  getUserActivity,
  activeCampaignInvited,
  exportWorkoutToGarmin,
  editingMode,
  setLoginCount,
  getPaginatedPlan,
  setCustomerCoachRate,
  setCustomerPlanRate,
  setrequestCoachChange,
  exportWorkoutToSuunto,
  checkUserHasPlan,
  setUnreadMessages,
  setNewMessagesByEvent,
  currentTrainingUpdateWorkoutStatusAndEnergy,
};
