import ActionTypes from "../constants/action-types";
import { getActivityCalculations } from "../lib/units-helper";

const initialState = {
  text: "",
  image: null,
  imageExtension: null,
  goal: null,
  badge: null,
  epicRace: null,
  type: null,
  tags: [],
  activity: null,
};

const communityPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.COMMUNITY_SET_CURRENT_TEXT:
      return {
        ...state,
        text: action.payload,
      };
    case ActionTypes.COMMUNITY_SET_CURRENT_IMAGE:
      return {
        ...state,
        image: action.payload.image,
        imageExtension: action.payload.extension,
        goal: null,
      };
    case ActionTypes.COMMUNITY_SET_CURRENT_GOAL:
      return {
        ...state,
        goal: action.payload,
        image: null,
        imageExtension: null,
      };
    case ActionTypes.COMMUNITY_SET_CURRENT_BADGE:
      return {
        ...state,
        badge: action.payload,
      };
    case ActionTypes.COMMUNITY_SET_CURRENT_EPIC_RACE:
      return {
        ...state,
        epicRace: action.payload,
      };
    case ActionTypes.COMMUNITY_SET_POST_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case ActionTypes.COMMUNITY_ADD_POST_TAG:
      return {
        ...state,
        tags: action.payload,
      };
    case ActionTypes.COMMUNITY_ADD_POST_ACTIVITY:
      return {
        ...state,
        activity: action.payload,
      };
    //activites reducers
    case ActionTypes.VERT_GET_ACTIVITY_FULFILLED:
      return {
        ...state,
        activity: getActivityCalculations(
          action.payload,
          localStorage.getItem("units"),
          "vert.run",
        ),
      };
    case ActionTypes.GARMIN_GET_ACTIVITY_FULFILLED:
      return {
        ...state,
        activity: getActivityCalculations(
          action.payload,
          localStorage.getItem("units"),
          "garmin",
        ),
      };
    case ActionTypes.SUUNTO_GET_ACTIVITY_FULFILLED:
      return {
        ...state,
        activity: getActivityCalculations(
          action.payload,
          localStorage.getItem("units"),
          "suunto",
        ),
      };
    case ActionTypes.COROS_GET_ACTIVITY_FULFILLED:
      return {
        ...state,
        activity: getActivityCalculations(
          action.payload,
          localStorage.getItem("units"),
          "coros",
        ),
      };
    case ActionTypes.STRAVA_GET_ACTIVITY_FULFILLED:
      return {
        ...state,
        activity: getActivityCalculations(
          action.payload,
          localStorage.getItem("units"),
          "strava",
        ),
      };
    case ActionTypes.HEALTHKIT_GET_ACTIVITY_FULFILLED:
      return {
        ...state,
        activity: action.payload,
      };

    case ActionTypes.COMMUNITY_RESET_POST_CREATION_STATE:
      return initialState;
    default:
      return state;
  }
};

export default communityPostReducer;
