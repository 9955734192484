import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, ThemeProvider, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import RouterLink from "../common/router-link";
import history from "../../lib/history-helper";
import {
  updatePlan,
  getReferralCode,
  toggleOpenMenu,
} from "../../actions/user-plans-action";
import ActivateTrailheadDialog from "../trail-head/activate-trailhead-dialog";
import { Capacitor } from "@capacitor/core";
import ReferalDialog from "../referal-dialog";
import ProfileMenu from "./profile-menu";
import { clearActivityVert } from "../../actions/vert-activity-action";
import UpgradeProModal from "../generals/upgrade-pro-modal";
import { logEvent } from "../../lib/events-helper";
import { createAnalyticData } from "../../lib/analytic-helper";
import { pushAnalyticData } from "../../actions/analytic-action";
import { theme } from "../../theme";
import { palette } from "../../theme/palette";
import TrainingMenuIcon from "../icons/TrainingMenuIcon";

const styles = (theme) => ({
  drawerRoot: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  menu: {
    width: "70vw",
    height: "100vh",
    borderTopLeftRadius: 18,
    borderBottomLeftRadius: 18,
  },
  menuButton: {
    cursor: "pointer",
    display: "flex",
    bottom: 0,
    left: 0,
    width: "100%",
    alignItems: "center",
    background: "#FFFFFF",
    zIndex: 180,
    padding: "4px 0 0 0",
    justifyContent: "space-around",
    paddingBottom: "env(safe-area-inset-bottom)",
    height: "70px",
    boxShadow: "0px -1px 30px 0px #12151B0A",
  },
  menuButtonTop: {
    display: "none",
  },
  menuBarIcon: {
    textAlign: "center",
  },
  menuBarIconTH: {
    height: 33,
    margin: "-4px auto -4px auto",
  },
  alignCenter: {
    textAlign: "center",
  },
  menuHeader: {
    marginRight: 10,
    width: "100%",
    textAlign: "right",
  },
  menuLabel: {
    marginRight: 27,
    width: "100%",
    textAlign: "right",
  },
  menuItemClose: {
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    marginLeft: "auto",
  },
  logoutContainer: {
    marginTop: 25,
  },
  logo: {
    content: `url("${process.env.PUBLIC_URL}/logo.png")`,
    width: 80,
    marginRight: 27,
    marginTop: 25,
    float: "right",
  },
  fab: {
    alignItems: "center",
  },
  fabSpacer: {},
  addPostButton: {
    background: "#FFFFFF",
    width: 40,
    height: 40,
    marginBottom: 4,
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  addPostLabel: {
    color: "#2E3A59",
  },
  containerRef: {
    display: "flex",
    alignItems: "center",
  },
  menuIconRef: {
    marginLeft: "auto",
    marginRight: 5,
    height: 24,
  },
  menuLabelRef: {
    width: "initial",
    color: "#FF6F00",
    fontWeight: 800,
    marginRight: 43,
    textAlign: "right",
  },
  dot: {
    height: 10,
    minWidth: 10,
  },
  buttonText: {
    fontSize: 12,
    margin: "0",
  },

  proButton: {
    boxShadow: "none",
    borderRadius: 50,
  },

  proButtonLabel: {
    display: "block",
  },

  proButtonDiscount: {
    fontSize: 10,
    lineHeight: "5px",
    paddingTop: 6,
  },
});

class MenuListComposition extends React.Component {
  state = {
    open: false,
    isOpenActivateTH: false,
    referral: false,
    exploreFeatureDialogOpen: false,
  };

  handleCloseUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
  };

  handleUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
    history.push("/subscription/explore");
  };

  handleToggle = () => {
    this.props.dispatch(toggleOpenMenu());
  };

  handleClose = (event) => {
    this.props.dispatch(toggleOpenMenu());
  };

  openTrailhead = () => {
    const { currentPlan } = this.props;
    const { trailhead } = currentPlan || "";

    if (trailhead) {
      history.push(`/trailhead`);
    } else {
      this.handleActivateTrailhead();
    }
  };

  openTracker = () => {
    this.props.dispatch(clearActivityVert());

    const { userPlans, subscriptions, auth } = this.props;
    const { currentUser } = auth || {};
    const { attributes } = currentUser || {};
    const { email } = attributes || {};
    const analyticData = createAnalyticData(
      {
        username: email,
        userPlans,
        subscriptions,
      },
      "open gps tracker",
    );
    this.props.dispatch(pushAnalyticData(analyticData));
    history.push("/tracking/tracker");
  };

  handleOpenActivateTrailhead = () => {
    this.setState({
      ...this.state,
      isOpenActivateTH: true,
    });
  };

  handleCloseActivateTrailhead = () => {
    this.setState({
      ...this.state,
      isOpenActivateTH: false,
    });
  };

  handleActivateTrailhead = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    if (currentPlan) {
      this.props.dispatch(
        updatePlan(
          { cognito_user_sub: currentPlan.cognito_user_sub, trailhead: true },
          false,
          false,
        ),
      );
    }

    history.push(`/trailhead`);
  };

  handleOpenReferral = () => {
    const { userPlans } = this.props;
    const { referralCode } = userPlans;
    if (!referralCode) {
      this.props.dispatch(getReferralCode());
    }
    this.setState({
      ...this.state,
      referral: true,
    });
  };

  handleCloseReferral = () => {
    this.setState({
      ...this.state,
      referral: false,
    });
  };

  render() {
    const {
      classes,
      className,
      t,
      currentPlan,
      isOnTrailhead,
      isOnDashboard,
      isOnSuggesterResults,
      isOnChallenges,
      isOnProfile,
      isOnExplore,
      isOnTracking,
      isOnTraining,
      visible,
    } = this.props;

    const isNative = Capacitor.isNativePlatform();
    const { isOpenActivateTH, referral, exploreFeatureDialogOpen } = this.state;

    const { currentTraining } = currentPlan || "";
    let { selectedPlan } = currentTraining || {};

    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    const showAllButtons =
      (currentPlan && selectedPlan) ||
      !Capacitor.isNativePlatform() ||
      isOnChallenges ||
      isOnProfile ||
      isOnTrailhead ||
      isOnExplore ||
      isOnDashboard ||
      isOnTraining;

    const { subscriptions } = this.props;

    return visible ? (
      <div className={className}>
        <ThemeProvider theme={theme}>
          <div
            className={
              showAllButtons ? classes.menuButton : classes.menuButtonTop
            }
          >
            <RouterLink to="/dashboard" className={classes.menuBarIcon}>
              <div id="home_menu_icon">
                <img
                  src={`${process.env.PUBLIC_URL}/home_icon${
                    isOnDashboard ? "_fill" : ""
                  }.svg`}
                  alt=""
                  style={{ height: 24 }}
                />
                <Typography
                  variant="body2"
                  className={classes.buttonText}
                  style={{
                    color: isOnDashboard ? palette.primary : "initial",
                  }}
                >
                  {t("menu.home")}
                </Typography>
              </div>
            </RouterLink>

            <RouterLink to="/trailhead" className={classes.menuBarIcon}>
              <div
                id="trailhead_menu_icon"
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/community_icon${
                    isOnTrailhead ? "_fill" : ""
                  }.svg`}
                  alt=""
                  style={{ height: 18 }}
                />
                <Typography
                  variant="body2"
                  className={classes.buttonText}
                  style={{
                    color: isOnTrailhead ? palette.primary : "initial",
                  }}
                >
                  {t("menu.community")}
                </Typography>
              </div>
            </RouterLink>

            <RouterLink to="/training" className={classes.menuBarIcon}>
              <div id="training_menu_icon">
                <TrainingMenuIcon activated={isOnTraining} />
                <Typography
                  variant="body2"
                  className={classes.buttonText}
                  style={{
                    color: isOnTraining ? palette.primary : "initial",
                  }}
                >
                  {t("menu.training")}
                </Typography>
              </div>
            </RouterLink>

            <RouterLink to="/profile" className={classes.menuBarIcon}>
              <div id="profile_menu_icon">
                <img
                  src={`${process.env.PUBLIC_URL}/profile_icon${
                    isOnProfile ? "_fill" : ""
                  }.svg`}
                  alt=""
                  style={{
                    height: 24,
                  }}
                />
                <Typography
                  variant="body2"
                  className={classes.buttonText}
                  style={{
                    color: isOnProfile ? palette.primary : "initial",
                  }}
                >
                  {t("menu.profile")}
                </Typography>
              </div>
            </RouterLink>
          </div>
        </ThemeProvider>

        <ProfileMenu
          isOnSuggesterResults={isOnSuggesterResults}
          isOnChallenges={isOnChallenges}
          isOnProfile={isOnProfile}
          isOnTrailhead={isOnTrailhead}
          showAllButtons={showAllButtons}
          onReferral={this.handleOpenReferral}
        ></ProfileMenu>
        <ActivateTrailheadDialog
          open={isOpenActivateTH}
          onCancel={this.handleCloseActivateTrailhead}
          onConfirm={this.handleActivateTrailhead}
        ></ActivateTrailheadDialog>
        <ReferalDialog
          open={referral}
          onCancel={this.handleCloseReferral}
        ></ReferalDialog>

        <UpgradeProModal
          open={exploreFeatureDialogOpen}
          onClose={this.handleCloseUpgrade}
        />
      </div>
    ) : null;
  }
}

MenuListComposition.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  showChangePlanButton: PropTypes.bool,
  currentPlan: PropTypes.object,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
    auth: store.auth,
    subscriptions: store.subscriptions,
  };
})(
  withTranslation("home")(
    withStyles(styles, { withTheme: true })(MenuListComposition),
  ),
);
