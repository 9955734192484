import React from "react";
import { useChatContext } from "../../../context/ChatProvider";
import Chat from "../../../components/common/v2/Chat";

const ChatView = () => {
  const {
    isOpen,
    workoutToShare,
    setWorkoutToShare,
    closeChat,
  } = useChatContext();

  if (!isOpen) return <></>;

  return (
    <>
      <Chat
        isOpen={isOpen}
        workout={workoutToShare}
        setWorkoutToShare={setWorkoutToShare}
        onClose={closeChat}
      />
    </>
  );
};

export default ChatView;
