import React from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Typography, Button } from "@material-ui/core";
import { logEvent } from "../../lib/events-helper";

const styles = (theme) => ({
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  noPadding: {
    padding: "0px !important",
    maxWidth: "700px",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "auto !important",
      width: "auto",
    },
  },
  dialogPaper: {
    borderRadius: "20px",
    maxWidth: "680px",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "90% !important",
      width: "90% !important",
      // maxWidth: "auto",
    },
  },
  imageParent: {
    position: "relative",
    width: "100%",
  },
  closeIcon: {
    position: "absolute",
    top: "5%",
    right: "3%",
    cursor: "pointer",
  },
  content: {
    padding: "12px",
    boxSizing: "border-box",
  },
  title: {
    fontSize: "28px",
    marginBottom: "10px",
    fontWeight: "bold",
    textAlign: "center",
    width: "85%",
    margin: "0 auto",
    lineHeight: "43px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  subtitle: {
    color: "#3A4750",
    fontSize: "18px",
    fontWeight: 400,
    width: "85%",
    margin: "0 auto",
    textAlign: "center",
    marginBottom: "17px",
  },
  description: {
    fontSize: "16px",
    color: "#3A4750",
    // textAlign: "center",
    marginBottom: "10px",
    fontWeight: 100,
    // width: "80%",
    // margin: "0 auto",
    lineHeight: "24px",
  },
  price: {
    color: "#3A4750",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "bold",
  },
  btnParent: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0 6px 0",

    [theme.breakpoints.down("sm")]: {
      width: "80%",
      margin: "20px auto 6px auto",
    },
  },
  btn: {
    width: "453px",
    marginBottom: "16px",
  },
  row: {
    display: "flex",
    alignItems: "flex-start",
    width: "85%",
    margin: "0 auto",
  },
  checkIcon: {
    marginRight: 10,
  },
  plus: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  communityImg: {
    "object-fit": "cover",
    width: "100%",
  },
});

const EndYearModal = ({ classes, t, open, onClose, width }) => {
  const isInMobileViewSm = width === "sm" || width === "xs";

  const goToSub = () => {
    window
      .open(
        "https://vert.chargebeeportal.com/portal/v2/login?forward=portal_main",
        "_blank",
      )
      .focus();
  };

  return (
    <Dialog
      open={true}
      onClose={onClose ? onClose : null}
      aria-labelledby="upgrade-to-pro"
      className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={classes.noPadding}>
        <div className={classes.imageParent}>
          <img
            className={classes.communityImg}
            alt="upgrade to pro modal"
            src={`${process.env.PUBLIC_URL}/year-cover.svg`}
          />
          <img
            src={`${process.env.PUBLIC_URL}/close-white-icon.svg`}
            alt="close-icon"
            className={classes.closeIcon}
            onClick={onClose ? onClose : null}
          />
        </div>

        <div className={classes.content}>
          <Typography className={classes.title}>
            {t("Stay fit and healthy in 2023--all year long.")}
          </Typography>

          <Typography className={classes.subtitle}>
            {t(
              "Switch to annual billing now and commit to a full year of health in the New Year.",
            )}
          </Typography>

          <div className={classes.row}>
            <img
              src={`${process.env.PUBLIC_URL}/check-green-icon.svg`}
              className={classes.checkIcon}
            />
            <Typography
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html: t(
                  "Save $84/year when you switch to annual billing--that's 3 months free!",
                ),
              }}
            ></Typography>
          </div>

          <div className={classes.row}>
            <img
              src={`${process.env.PUBLIC_URL}/check-green-icon.svg`}
              className={classes.checkIcon}
            />
            <Typography
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html: t(
                  "Set long-term goals and work towards them consistentlys with your Vert.run coach.",
                ),
              }}
            ></Typography>
          </div>

          <Typography className={classes.plus}>{t("plus")}</Typography>

          <div className={classes.row}>
            <img
              src={`${process.env.PUBLIC_URL}/check-green-icon.svg`}
              className={classes.checkIcon}
            />
            <Typography
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html: t(
                  "Don't lose what you've built: use our off-season plans to rest correctly while also staying sharp.",
                ),
              }}
            ></Typography>
          </div>

          <div className={classes.btnParent}>
            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              size={!isInMobileViewSm ? "large" : "medium"}
              onClick={goToSub}
            >
              {t("Get 3 months free!")}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation("dashboard")(
  withWidth()(withStyles(styles, { withTheme: true })(EndYearModal)),
);
