import React from "react";

import RouterLink from "../../common/router-link";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  sectionTitle: {
    position: "relative",
    lineHeight: "125%",
    fontFamily: theme.typography.fontFamily,
  },
  instructionText: {
    position: "relative",
    fontSize: theme.typography.h5.fontSize,
    lineHeight: "150%",
    color: "#6d6f71",
  },
  coachInfoContainer: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "0px 16px",
    gap: "2px",
  },
  coachImage: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  coachName: {
    alignSelf: "stretch",
    position: "relative",
    lineHeight: "150%",
  },
  coachDetailsContainer: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  arrowIconContainer: {
    position: "relative",
    width: "14px",
    height: "14px",
    overflow: "hidden",
    flexShrink: "0",
    objectFit: "cover",
  },
  arrowContainer: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    padding: "5px 0px",
  },
  gradientBackground: {
    position: "absolute",
    bottom: 0,
    left: "0px",
    background:
      "linear-gradient(90deg, rgba(18, 21, 27, 0.6) 30.92%, rgba(18, 21, 27, 0.13))",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "4px 8px",
    boxSizing: "border-box",
  },
  coachCard: {
    position: "relative",
    borderRadius: "4px",
    width: "100vw",
    maxWidth: "150px",
    height: "171px",
    overflow: "hidden",
    flexShrink: "0",
  },
  coachDescription: {
    alignSelf: "stretch",
    position: "relative",
    fontSize: theme.typography.h6.fontSize,
    lineHeight: "150%",
  },
  coachesContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "16px",
    boxSizing: "border-box",
    gap: "16px",
    textAlign: "left",
    fontSize: theme.typography.h5.fontSize,
    color: "#feffff",
    fontFamily: theme.typography.fontFamily,
  },
  contentContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "24px 0px",
    boxSizing: "border-box",
    textAlign: "left",
    fontSize: "18px",
    color: "#12151b",
    fontFamily: theme.typography.fontFamily,
  },
});

const OurCoachesComponent = ({ data }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.contentContainer}>
      <div className={styles.coachInfoContainer}>
        <b className={styles.sectionTitle}>{t("Meet the Vert.run coaches")}</b>
        <div className={styles.instructionText}>
          {t("Click on a coach's picture to learn more")}
        </div>
      </div>
      <div className={styles.coachesContainer}>
        {data.length > 0 &&
          data.map(function(item, index) {
            return (
              <RouterLink
                style={{ display: "flex" }}
                key={index}
                to="/profile/settings/our-coaches/info"
              >
                <div className={styles.coachCard}>
                  <img
                    className={styles.coachImage}
                    alt=""
                    src={
                      item.image
                        ? `${item.image}`
                        : `${process.env.PUBLIC_URL}/logo.png`
                    }
                  />
                  <div className={styles.gradientBackground}>
                    <div className={styles.coachDetailsContainer}>
                      <b className={styles.coachName}>
                        {item.label ? item.label : ""}
                      </b>
                      <div className={styles.coachDescription}>
                        {item.description ? item.description : ""}
                      </div>
                    </div>
                    <div className={styles.arrowContainer}>
                      <img
                        className={styles.arrowIconContainer}
                        alt=""
                        src={`${process.env.PUBLIC_URL}/Icon_short-arrow_coach.png`}
                      />
                    </div>
                  </div>
                </div>
              </RouterLink>
            );
          })}
      </div>
    </div>
  );
};

export default OurCoachesComponent;
