import React from "react";
import { palette } from "../../theme/palette";

const BatteryIcon = ({
  width = 10,
  height = 16,
  vWidth = 10,
  vHeight = 16,
  activated = false,
  fill = null,
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vWidth} ${vHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14H2V3.5H8M8.5025 2H7.25V1C7.25 0.723858 7.02614 0.5 6.75 0.5H3.25C2.97386 0.5 2.75 0.723858 2.75 1V2H1.4975C0.95 2 0.5 2.45 0.5 2.9975V14.5025C0.5 15.05 0.95 15.5 1.4975 15.5H8.5025C9.0575 15.5 9.5 15.0575 9.5 14.5025V2.9975C9.5 2.45 9.05 2 8.5025 2ZM7.25 11.5C7.25 11.2239 7.02614 11 6.75 11H3.25C2.97386 11 2.75 11.2239 2.75 11.5V12.75C2.75 13.0261 2.97386 13.25 3.25 13.25H6.75C7.02614 13.25 7.25 13.0261 7.25 12.75V11.5Z"
        fill={fill ? fill : activated ? palette.primary : palette.darkGray}
      />
    </svg>
  </>
);
export default BatteryIcon;
