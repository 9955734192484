import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const styles = {
    container: {
        display: 'flex',
        width: '100%'
    },
    row: {
        flexDirection: 'row'
    },
    column: {
        flexDirection: 'column'
    },
    noWrap: {
        flexWrap: 'noWrap'
    },
    wrap: {
        flexWrap: 'wrap'
    },
    fullHeight: {
        height: '100%'
    }
}

const FlexContainer = (props) => {
    const { className, classes, direction, wrap, padding, flex, children, fullHeight, ...rest } = props;

    return <div className={
        classNames(className, classes.container,
            { [classes.row]: direction && direction === 'row' },
            { [classes.column]: direction && direction === 'column' },
            { [classes.wrap]: direction && direction === 'row' && wrap },
            { [classes.noWrap]: direction && direction === 'row' && !wrap },
            { [classes.fullHeight]: fullHeight })
    }
        style={{ padding: padding, flex: flex }}
        {...rest}>
        {children}
    </div>
}

FlexContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    direction: PropTypes.oneOf(['row', 'column']),
    wrap: PropTypes.bool,
    padding: PropTypes.number,
    fullHeight: PropTypes.bool
}

FlexContainer.defaultProps = {
    direction: 'row',
    wrap: true,
    padding: 10,
    fullHeight: false
}

export default withStyles(styles)(FlexContainer);

