import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Slide, Dialog, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ToolbarReturnBack from "./v2/ToolbarReturnBack";
import history from "../../lib/history-helper";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const NavbarSettings = ({
  component,
  text,
  withClose = false,
  closeTo,
  message,
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(true);
  const { goBack } = useHistory();
  const { t } = useTranslation();

  const handleCloseDialog = () => {
    if (closeTo) {
      setOpenDialog(false);
      history.push(closeTo);
    } else {
      setOpenDialog(false);
      goBack();
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        style={{ display: "flex", flexDirection: "column" }}
        PaperProps={{
          className: classes.paperDialog,
        }}
      >
        <Box
          sx={{
            flex: "0 0 auto",
            bgcolor: "#FAFAFA",
            height: 60,
          }}
        >
          <ToolbarReturnBack
            elevation={0}
            position="fixed"
            goBack={handleCloseDialog}
            title={t(text)}
            withClose={withClose}
            closeTo={closeTo}
            predefinedShadow={true}
          />
        </Box>
        {component}
        {message}
      </Dialog>
    </>
  );
};

export default NavbarSettings;

const useStyles = makeStyles(() => ({
  paperDialog: {
    background: "#fbfbfb",
  },
}));
