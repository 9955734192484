import React from "react";
import RecoveryPassword from "../../../../components/recovery-password";
import StepsRecoveryProvider from "../../../providers/StepsRecoveryProvider";

const RecoveryPasswordView = () => {
  return (
    <StepsRecoveryProvider>
      <RecoveryPassword />
    </StepsRecoveryProvider>
  );
};

export default RecoveryPasswordView;
