const LongestRun = {
  0: "0",
  10: "10k",
  20: "20k",
  30: "30k",
  40: "40k",
  50: "50k+",
};

export default {
  Items: LongestRun,
  itemsArray: Object.keys(LongestRun).map((key) => {
    return {
      text: LongestRun[key],
      value: key,
    };
  }),
  suggesterOptions: [
    {
      text: LongestRun[0],
      value: 0,
    },
    {
      text: LongestRun[10],
      value: 10,
    },
    {
      text: LongestRun[30],
      value: 30,
    },
    {
      text: LongestRun[50],
      value: 50,
    },
  ],
};
