import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getUserActivities } from "../../actions/user-plans-action";
import { getPace } from "../../lib/units-helper";
import {
  postActivityVert,
  editActivityVert,
} from "../../actions/vert-activity-action";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import MomentUtils from "@date-io/moment";
import { dateFormat } from "../../lib/date-helper";
import UnitsHook from "../../hooks/unitsHook";
import { convertUnits } from "../../lib/units-helper";
import { palette } from "../../theme/palette";
import { DistanceIcon, ElevationIcon, TimerIcon, CalendarIcon } from "../icons";
import useActivityDetails from "../../hooks/useActivityDetails";
import { Box } from "@mui/material";

const options = [
  { label: "profile.activities.effort.prettyEasy", value: 20 },
  { label: "profile.activities.effort.easy", value: 40 },
  { label: "profile.activities.effort.medium", value: 60 },
  { label: "profile.activities.effort.hard", value: 80 },
  { label: "profile.activities.effort.veryHard", value: 100 },
];

const SaveActivity = ({ type = "add", activity = {}, onCancel = () => {} }) => {
  const { t } = useTranslation("profile");
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUnit = UnitsHook();
  const {
    loadActivityDetails,
    name,
    description,
    activityType,
    activityEffort,
    activityTypeEffort,
    distance,
    elevation,
    date,
    createdAt,
    time,
    device,
  } = useActivityDetails();

  const { userPlans, vertActivities } = useSelector((state) => state);
  const { loadingActivity, loadingEdit } = vertActivities || {};
  const { currentPlan } = userPlans || {};
  const { cognito_user_sub } = currentPlan || {};
  const [activityDevice, setActivityDevice] = useState("vert.run");
  const timerOptions = Array.from({ length: 60 }, (_, i) => i).map((value) =>
    value < 10 ? `0${value}` : `${value}`,
  );
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    activityType: "",
    typeEffort: "",
    activityEffort: "",
    distance: "",
    elevation: "",
    durationHH: "",
    durationMM: "",
    date: null,
    dateToSend: null,
  });

  const checkForm = () => {
    const formCopy = { ...formValues };
    const { description, ...other } = formCopy;
    const values = Object.values(other);
    const isValid = values.every((field) => field);
    return !isValid;
  };

  const handleInput = (event) => {
    const {
      target: { value, name },
    } = event;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;
    if (value.toString().length <= 10) {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleDateChange = (dateMoment) => {
    setFormValues({
      ...formValues,
      date: dateMoment.format(dateFormat),
      dateToSend: moment(dateMoment, dateFormat).toISOString(),
    });
  };

  const calculateTime = (hours = "00", minutes = "00") => {
    const totalSeconds = hours * 60 * 60 + minutes * 60;
    return totalSeconds;
  };

  const extractTime = (timeString, format = "hh") => {
    if (!timeString) return "00";
    const [hh, mm] = timeString.split(":");

    switch (format) {
      case "hh":
        return hh;
      case "mm":
        return mm;
      default:
        break;
    }
  };

  const saveActivity = async (event, goToShare = false) => {
    event.preventDefault();

    const calculations = {
      name: formValues.name,
      description: formValues.description,
      distance: parseFloat(formValues.distance),
      elevation: parseFloat(formValues.elevation),
      total_elevation_gain: parseFloat(formValues.elevation),
      time: calculateTime(formValues.durationHH, formValues.durationMM),
      moving_time: calculateTime(formValues.durationHH, formValues.durationMM),
      elapsed_time: calculateTime(formValues.durationHH, formValues.durationMM),
      pace: getPace(
        calculateTime(formValues.durationHH, formValues.durationMM),
        formValues.distance * 1000,
      ),
      date: formValues.dateToSend,
      start_date: formValues.dateToSend,
      created_at: createdAt || new Date().getTime(),
      laps: [],
      rawSamples: [],
      device: "vert.run",
      type: formValues.activityType,
      activityType: formValues.activityType,
    };

    const { durationHH, durationMM, date, ...rest } = formValues;
    const values = { ...rest };

    if (!formValues.name) values.name = "Trail Run";
    if (!formValues.activityType) values.activityType = "RUN";
    if (!formValues.typeEffort) values.typeEffort = "Easy";
    if (!formValues.activityEffort) values.activityEffort = 40;

    if (currentUnit == "miles") {
      values.distance = convertUnits(
        values.distance,
        "miles",
        "kms",
        "distance",
      );
      values.elevation = convertUnits(
        values.elevation,
        "miles",
        "kms",
        "elevation",
      );
    }

    if (type == "add") {
      await dispatch(
        postActivityVert({
          ...calculations,
          ...values,
          cognito_user_sub,
          distance: parseFloat(values.distance * 1000),
          elevation: parseFloat(values.elevation),
          total_elevation_gain: parseFloat(values.elevation),
        }),
      );
    } else {
      if (activityDevice == "vert.run") {
        await dispatch(
          editActivityVert({
            ...calculations,
            ...values,
            cognito_user_sub,
            distance: parseFloat(values.distance * 1000),
            elevation: parseFloat(values.elevation),
            total_elevation_gain: parseFloat(values.elevation),
          }),
        );
      }
    }
    getUserActivitiesInComponent();
    onCancel();
  };

  const getUserActivitiesInComponent = async () => {
    const { currentPlan } = userPlans;
    await dispatch(getUserActivities(currentPlan));
  };

  useEffect(() => {
    if (activity && Object.keys(activity).length > 0) {
      loadActivityDetails(activity);
    } else {
      setFormValues({
        name: "",
        description: "",
        activityType: "",
        typeEffort: "",
        activityEffort: "",
        distance: "",
        elevation: "",
        durationHH: "",
        durationMM: "",
        date: null,
        dateToSend: null,
      });
    }
  }, [activity]);

  useEffect(() => {
    setActivityDevice(device);
    setFormValues((prev) => ({
      ...prev,
      ...{
        name,
        description,
        activityType,
        typeEffort: activityTypeEffort,
        activityEffort: activityEffort,
        distance,
        elevation,
        durationHH: extractTime(time, "hh"),
        durationMM: extractTime(time, "mm"),
        date,
        dateToSend: date,
      },
    }));
  }, [
    name,
    description,
    activityType,
    activityEffort,
    activityTypeEffort,
    distance,
    elevation,
    time,
    date,
    device,
    createdAt,
  ]);

  return (
    <Grid
      className={classes.formContainer}
      container
      spacing={2}
      direction="column"
    >
      <FormControl>
        <TextField
          color="secondary"
          fullWidth
          size="small"
          variant="outlined"
          id="name"
          name="name"
          value={formValues.name}
          placeholder={t("profile.activities.form.name")}
          onBlur={(event) => event.preventDefault()}
          onChange={handleInput}
          inputProps={{
            maxLength: 80,
            className: classes.input,
          }}
        />
      </FormControl>

      <FormControl
        variant="outlined"
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Select
          color="secondary"
          name="activityType"
          fullWidth
          readOnly={type == "edit"}
          displayEmpty
          labelId="activity-type-label"
          id="activity-type-select"
          onBlur={(event) => event.preventDefault()}
          value={formValues.activityType}
          onChange={handleInput}
          className={
            type == "add" ? classes.selectStyle : classes.selectStyleReadOnly
          }
          inputProps={{
            className:
              type == "add" ? classes.selectStyle : classes.selectStyleReadOnly,
          }}
          disableUnderline
        >
          <MenuItem value="" disabled>
            {t("profile.activities.form.activityType")}
          </MenuItem>
          <MenuItem value="Run">{t("profile.activities.run")}</MenuItem>
          <MenuItem value="Trail Run">
            {t("profile.activities.trailRun")}
          </MenuItem>
          <MenuItem value="Cycling">{t("profile.activities.cycling")}</MenuItem>
          <MenuItem value="Hiking">{t("profile.activities.hiking")}</MenuItem>
          <MenuItem value="Walking">{t("profile.activities.walking")}</MenuItem>
          <MenuItem value="Other">{t("profile.activities.other")}</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        variant="outlined"
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Select
          color="secondary"
          readOnly={type == "edit"}
          name="typeEffort"
          fullWidth
          displayEmpty
          labelId="type-effort-label"
          id="type-effort-select"
          onBlur={(event) => event.preventDefault()}
          value={formValues.typeEffort}
          onChange={handleInput}
          className={
            type == "add" ? classes.selectStyle : classes.selectStyleReadOnly
          }
          inputProps={{
            className:
              type == "add" ? classes.selectStyle : classes.selectStyleReadOnly,
          }}
          disableUnderline
          size="small"
        >
          <MenuItem value="" disabled>
            {t("profile.activities.form.effortType")}
          </MenuItem>
          <MenuItem value="Easy">
            {t("profile.activities.effortType.easy")}
          </MenuItem>
          <MenuItem value="Workout">
            {t("profile.activities.effortType.workout")}
          </MenuItem>
          <MenuItem value="Race">
            {t("profile.activities.effortType.race")}
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl
        variant="outlined"
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Select
          color="secondary"
          readOnly={type == "edit"}
          name="activityEffort"
          fullWidth
          displayEmpty
          labelId="activity-effort-label"
          id="activity-effort-select"
          onBlur={(event) => event.preventDefault()}
          value={formValues.activityEffort}
          onChange={handleInput}
          className={
            type == "add" ? classes.selectStyle : classes.selectStyleReadOnly
          }
          inputProps={{
            className:
              type == "add" ? classes.selectStyle : classes.selectStyleReadOnly,
          }}
          disableUnderline
          size="small"
        >
          <MenuItem value="" disabled>
            {t("profile.activities.form.activityEffort")}
          </MenuItem>
          {options.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <TextField
          color="secondary"
          fullWidth
          size="small"
          variant="outlined"
          id="description"
          name="description"
          value={formValues.description}
          placeholder={t("profile.activities.form.description")}
          onChange={handleInput}
          onBlur={(event) => event.preventDefault()}
          inputProps={{
            className: classes.input,
            maxLength: 80,
          }}
        />
      </FormControl>

      <FormControl>
        <TextField
          color="secondary"
          fullWidth
          size="small"
          variant="outlined"
          id="distance"
          type="number"
          name="distance"
          onBlur={(event) => event.preventDefault()}
          value={formValues.distance}
          style={{
            backdropFilter: type == "edit" ? "contrast(0.9)" : "none",
            backgroundColor: type == "edit" ? "none" : palette.white,
          }}
          placeholder={t("profile.activities.form.distance", {
            unit: !currentUnit || currentUnit === "kms" ? "km" : "miles",
          })}
          inputProps={{
            maxLength: 10,
            className: classes.input,
          }}
          onChange={handleChange}
          InputProps={{
            readOnly: type == "edit",
            startAdornment: (
              <InputAdornment position="start">
                <DistanceIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>

      <FormControl>
        <TextField
          color="secondary"
          fullWidth
          size="small"
          type="number"
          variant="outlined"
          id="elevation"
          name="elevation"
          value={formValues.elevation}
          style={{
            backdropFilter: type == "edit" ? "contrast(0.9)" : "none",
            backgroundColor: type == "edit" ? "none" : palette.white,
          }}
          placeholder={t("profile.activities.form.elevation", {
            unit: !currentUnit || currentUnit === "kms" ? "m" : "feet",
          })}
          onChange={handleChange}
          inputProps={{
            className: classes.input,
            maxLength: 10,
          }}
          InputProps={{
            readOnly: type == "edit",
            startAdornment: (
              <InputAdornment position="start">
                <ElevationIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              color="secondary"
              disabled={type == "edit"}
              id="date"
              name="date"
              placeholder={t("profile.activities.form.date")}
              value={moment(formValues.date || null, dateFormat).toISOString()}
              format="DD/MM/YYYY"
              disableFuture={true}
              size="small"
              autoOk
              fullWidth
              onChange={handleDateChange}
              inputVariant="outlined"
              style={{
                backdropFilter: type == "edit" ? "contrast(0.9)" : "none",
                backgroundColor: type == "edit" ? "none" : palette.white,
              }}
              inputProps={{
                className: classes.input,
              }}
              InputProps={{
                readOnly: type == "edit",
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
              <TimerIcon />
              <Typography className={classes.label}>
                {t("profile.activities.form.duration")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <FormControl
                variant="outlined"
                style={{
                  width: "100%",
                  minWidth: 75,
                  backgroundColor: "#FFFFFF",
                  color: palette.black,
                }}
              >
                <Select
                  color="secondary"
                  readOnly={type === "edit"}
                  name="durationHH"
                  fullWidth
                  displayEmpty
                  labelId="duration-hh-label"
                  id="duration-hh-select"
                  onBlur={(event) => event.preventDefault()}
                  value={formValues.durationHH}
                  onChange={handleInput}
                  className={
                    type === "add"
                      ? classes.selectStyle
                      : classes.selectStyleReadOnly
                  }
                  inputProps={{
                    className:
                      type === "add"
                        ? classes.selectStyle
                        : classes.selectStyleReadOnly,
                  }}
                  disableUnderline
                  size="small"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 152,
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    {"HH"}
                  </MenuItem>
                  {timerOptions.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography className={classes.label}>{":"}</Typography>
              <FormControl
                variant="outlined"
                style={{
                  width: "100%",
                  minWidth: 75,
                  backgroundColor: "#FFFFFF",
                  color: palette.black,
                }}
              >
                <Select
                  color="secondary"
                  readOnly={type === "edit"}
                  name="durationMM"
                  fullWidth
                  displayEmpty
                  labelId="duration-mm-label"
                  id="duration-mm-select"
                  onBlur={(event) => event.preventDefault()}
                  value={formValues.durationMM}
                  onChange={handleInput}
                  className={
                    type === "add"
                      ? classes.selectStyle
                      : classes.selectStyleReadOnly
                  }
                  inputProps={{
                    className:
                      type === "add"
                        ? classes.selectStyle
                        : classes.selectStyleReadOnly,
                  }}
                  disableUnderline
                  size="small"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 152,
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    {"MM"}
                  </MenuItem>
                  {timerOptions.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <div className={classes.buttonsParent}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6}>
            <Button
              onClick={() => onCancel()}
              fullWidth
              variant="outlined"
              size="large"
              style={{
                borderColor: palette.darkBlue,
                color: palette.darkBlue,
                textTransform: "none",
              }}
            >
              {t("profile.activities.form.buttonCancel")}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color={
                checkForm() || loadingActivity || loadingEdit
                  ? "default"
                  : "secondary"
              }
              disabled={checkForm() || loadingActivity || loadingEdit}
              onClick={(event) => saveActivity(event, false)}
              className={classes.addButton}
            >
              {type == "edit"
                ? t("profile.activities.form.buttonEdit")
                : t("profile.activities.form.buttonAdd")}{" "}
              {(loadingActivity || loadingEdit) && (
                <CircularProgress
                  color="secondary"
                  variant="indeterminate"
                  size={24}
                  className={classes.loading}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default SaveActivity;

const useStyles = makeStyles({
  formContainer: {
    gap: 12,
    paddingBottom: 20,
  },
  label: {
    //styleName: p1-r;
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 400,
  },
  input: {
    padding: "10px 8px",
  },
  date: {
    fontWeight: "bold",
    fontSize: 20,
    fontFamily: "Oswald",
  },
  selectStyle: {
    fontSize: "16px",
    padding: "5px !important",
    borderRadius: "4px",
    border: palette.lightGray,
    fontFamily: "Karla, sans-serif",
    fontWeight: 400,
    backgroundColor: "#FEFFFF",
    color: palette.black,
  },
  selectStyleReadOnly: {
    fontSize: "16px",
    padding: "5px !important",
    borderRadius: "4px",
    border: palette.lightGray,
    fontFamily: "Karla, sans-serif",
    fontWeight: 400,
    backgroundColor: "#ffffff00",
    backdropFilter: "contrast(0.9)",
    color: palette.black,
  },
  addButton: {
    backgroundColor: palette.darkBlue,
    color: palette.white,
  },
  buttonsParent: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    alignItems: "center",
    margin: "0px 0px 0px 0px",
  },
  loading: {
    marginLeft: "10px",
  },
  timePickerInput: {
    "& input::-webkit-calendar-picker-indicator": {
      display: "none",
      "-webkit-appearance": "none",
    },
  },
});
