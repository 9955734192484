import { LocalNotifications } from "@capacitor/local-notifications";
import { logEvent } from "../lib/events-helper";
import history from "../lib/history-helper";
import WorkoutTypes from "../constants/workout-types";
import EstimatedTimes from "../constants/estimated-times";
import { dateFormat } from "../lib/date-helper";
import UserPlansService from "./user-plans-service";
import moment from "moment";
import workoutToText from "../lib/workoutToText";
class LocalNotificationsService {
  static async scheduleNotifications(date, texts, links, isRedo = false) {
    try {
      const dates = [
        new Date(date),
        new Date(date),
        new Date(date),
        new Date(date),
        new Date(date),
        new Date(date),
        new Date(date),
        new Date(date),
        new Date(date),
      ];
      dates[0].setTime(dates[0].getTime() + 60 * 60 * 1000);

      dates[1].setDate(dates[1].getDate() + 1);
      dates[1].setHours(10, 0, 0, 0);

      dates[2].setDate(dates[2].getDate() + 2);
      dates[2].setHours(12, 30, 0, 0);

      dates[3].setDate(dates[3].getDate() + 3);
      dates[3].setHours(10, 0, 0, 0);

      dates[4].setDate(dates[4].getDate() + 4);
      dates[4].setHours(12, 30, 0, 0);

      dates[5].setDate(dates[5].getDate() + 4);
      dates[5].setDate(
        dates[5].getDate() + ((1 + 7 - dates[5].getDay()) % 7 || 7),
      );
      dates[5].setHours(10, 0, 0, 0);

      dates[6].setDate(dates[6].getDate() + 11);
      dates[6].setDate(
        dates[6].getDate() + ((1 + 7 - dates[6].getDay()) % 7 || 7),
      );

      dates[7].setDate(dates[7].getDate() + 18);
      dates[7].setDate(
        dates[7].getDate() + ((1 + 7 - dates[7].getDay()) % 7 || 7),
      );

      dates[8].setDate(dates[8].getDate() + 25);
      dates[8].setDate(
        dates[8].getDate() + ((1 + 7 - dates[8].getDay()) % 7 || 7),
      );

      let notifications = [];

      if (!isRedo) {
        notifications.push({
          title: "",
          body: texts[0],
          largeBody: texts[0],
          id: dates[0].getTime(),
          schedule: { at: dates[0] },
          sound: null,
          attachments: null,
          actionTypeId: links[0],
          extra: null,
        });
      }

      notifications = [
        ...notifications,
        {
          title: "",
          body: texts[1],
          largeBody: texts[1],
          id: dates[1].getTime(),
          schedule: { at: dates[1] },
          sound: null,
          attachments: null,
          actionTypeId: links[1],
          extra: null,
        },
        {
          title: "",
          body: texts[2],
          largeBody: texts[2],
          id: dates[2].getTime(),
          schedule: { at: dates[2] },
          sound: null,
          attachments: null,
          actionTypeId: links[2],
          extra: null,
        },
        {
          title: "",
          body: texts[3],
          largeBody: texts[3],
          id: dates[3].getTime(),
          schedule: { at: dates[3] },
          sound: null,
          attachments: null,
          actionTypeId: links[3],
          extra: null,
        },
        {
          title: "",
          body: texts[4],
          largeBody: texts[4],
          id: dates[4].getTime(),
          schedule: { at: dates[4] },
          sound: null,
          attachments: null,
          actionTypeId: links[4],
          extra: null,
        },
        {
          title: "",
          body: texts[5],
          largeBody: texts[5],
          id: dates[5].getTime(),
          schedule: { at: dates[5] },
          sound: null,
          attachments: null,
          actionTypeId: links[5],
          extra: null,
        },
        {
          title: "",
          body: texts[6],
          largeBody: texts[6],
          id: dates[6].getTime(),
          schedule: { at: dates[6] },
          sound: null,
          attachments: null,
          actionTypeId: links[6],
          extra: null,
        },
        {
          title: "",
          body: texts[7],
          largeBody: texts[7],
          id: dates[7].getTime(),
          schedule: { at: dates[7] },
          sound: null,
          attachments: null,
          actionTypeId: links[7],
          extra: null,
        },
        {
          title: "",
          body: texts[8],
          largeBody: texts[8],
          id: dates[8].getTime(),
          schedule: { at: dates[8] },
          sound: null,
          attachments: null,
          actionTypeId: links[8],
          extra: null,
        },
      ];

      //LocalNotifications.checkPermissions().then((status) => {
      //if (status && status.display && status.display === "granted") {
      await LocalNotifications.schedule({
        notifications,
      });
      //}
      //});
    } catch (error) {
      console.log(error);
    }
  }

  static async scheduleAbandonCartNotifications(date, texts) {
    try {
      const pending = await LocalNotifications.getPending();
      if (pending.notifications && pending.notifications.length) {
        const abandonCartPending = pending.notifications.filter(
          (n) => n.id > 100 && n.id < 105,
        );
        if (abandonCartPending.length > 0) {
          return;
        }
      }

      const dates = [
        new Date(date),
        new Date(date),
        new Date(date),
        new Date(date),
        new Date(date),
      ];
      dates[0].setDate(dates[0].getDate() + 1);
      dates[1].setDate(dates[1].getDate() + 2);
      dates[2].setDate(dates[2].getDate() + 3);
      dates[3].setDate(dates[3].getDate() + 4);
      dates[4].setDate(dates[4].getDate() + 5);
      //LocalNotifications.checkPermissions().then((status) => {
      //if (status && status.display && status.display === "granted") {
      await LocalNotifications.schedule({
        notifications: [
          {
            title: texts[0].title,
            body: texts[0].text,
            largeBody: texts[0].text,
            id: 101,
            schedule: { at: dates[0] },
            sound: null,
            attachments: null,
            actionTypeId: "",
            extra: null,
          },
          {
            title: texts[1].title,
            body: texts[1].text,
            largeBody: texts[1].text,
            id: 102,
            schedule: { at: dates[1] },
            sound: null,
            attachments: null,
            actionTypeId: "",
            extra: null,
          },
          {
            title: texts[2].title,
            body: texts[2].text,
            largeBody: texts[2].text,
            id: 103,
            schedule: { at: dates[2] },
            sound: null,
            attachments: null,
            actionTypeId: "",
            extra: null,
          },
          {
            title: texts[3].title,
            body: texts[3].text,
            largeBody: texts[3].text,
            id: 104,
            schedule: { at: dates[3] },
            sound: null,
            attachments: null,
            actionTypeId: "",
            extra: null,
          },
        ],
      });
      //}
      //});
    } catch (error) {
      console.log(error);
    }
  }

  static async cancelAbandonCartNotifications() {
    try {
      const pending = await LocalNotifications.getPending();
      if (pending.notifications && pending.notifications.length) {
        const abandonCartPending = pending.notifications.filter(
          (n) => n.id > 100 && n.id < 105,
        );
        await LocalNotifications.cancel({ notifications: abandonCartPending });
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async scheduleRaceNotifications(ids, dates, texts) {
    try {
      const notifications = [];
      for (let i = 0; i < ids.length; i++) {
        notifications.push({
          title: "",
          body: texts[i],
          largeBody: texts[i],
          id: ids[i],
          schedule: { at: dates[i] },
          sound: null,
          attachments: null,
          actionTypeId: "",
          extra: null,
        });
      }
      await LocalNotifications.schedule({
        notifications,
      });
    } catch (error) {
      console.log(error);
    }
  }

  static async cancelRaceNotifications(ids) {
    try {
      const pending = await LocalNotifications.getPending();
      if (pending.notifications && pending.notifications.length) {
        const racePending = pending.notifications.filter(
          (n) => n.id === ids[0] || n.id === ids[1],
        );
        LocalNotifications.cancel({ notifications: racePending });
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async scheduleWorkoutNotifications(
    days,
    time,
    cognito_user_sub,
    level,
  ) {
    /*try {
      await UserPlansService.cancelNotifications(cognito_user_sub);

      const notifications = [];

      for (let i = 0; i < days.length; i++) {
        if (days[i] && days[i][0]) {
          const {
            workoutType,
            description,
            estimatedTime,
            userCreatedType,
            plannedDate,
            id,
            parametrizedWorkout,
          } = days[i][0];

          let descEn = description && description.en ? description.en : "";
          let descEs = description && description.es ? description.es : "";

          if (
            parametrizedWorkout &&
            parametrizedWorkout[level] &&
            parametrizedWorkout[level].length
          ) {
            const units = localStorage.getItem("units")
              ? localStorage.getItem("units")
              : null;
            descEn = workoutToText(parametrizedWorkout[level], units, "en");

            descEs = workoutToText(parametrizedWorkout[level], units, "en");
          }

          const divEn = document.createElement("div");
          divEn.innerHTML = descEn;

          const divEs = document.createElement("div");
          divEs.innerHTML = descEs;

          /*const date = new Date().getTime() + i * 10000;
          const sDate = new Date();
          sDate.setTime(date);*/
    /*const momentDate = moment(plannedDate, dateFormat);

    const newEstimatedTime = Array.isArray(estimatedTime)
      ? estimatedTime[level]
      : estimatedTime;

    const wTime = EstimatedTimes.convertMinutesToLabel(
      newEstimatedTime,
      true,
    );

    const timeDate = new Date(time);
    const calcDate = momentDate.toDate();

    calcDate.setHours(timeDate.getHours());
    calcDate.setMinutes(timeDate.getMinutes());
    calcDate.setSeconds(timeDate.getSeconds());

    const plannedDateFormatted = plannedDate
      .replace("/", "-")
      .replace("/", "-");

    const esWTs = {
      Speedwork: "Entrenamiento de intensidad",
      "Long run": "Salida larga",
      "Uphill workout": "Intensidad en subida",
      "Downhill workout": "Entrenamiento de bajadas",
      "Tempo run": "Entrenamiento con tempo",
      "Fartlek workout": "Entrenamiento con Fartleks",
      Recovery: "Recuperación",
      "User created": "Creado por el usuario",
      "Conditioning workout": "Preparación física",
      "Cross training": "Entrenamiento cruzado",
      "Easy workout": "Día fácil",
    };

    if (moment(calcDate).isAfter(moment())) {
      notifications.push({
        app_id: "77b02843-8485-400d-8948-10bd3bb956e9",
        headings: {
          en: `Today's workout: ${
            userCreatedType
              ? userCreatedType
              : `${WorkoutTypes.Items[workoutType]}`
          }${wTime ? " · " + wTime : ""}`,
          es: `El entrenamiento de hoy: ${
            userCreatedType
              ? userCreatedType
              : `${esWTs[WorkoutTypes.Items[workoutType]]}`
          }${wTime ? " · " + wTime : ""}`,
        },
        contents: {
          en: divEn.innerText,
          es: divEs.innerText,
        },
        channel_for_external_user_ids: "push",
        include_external_user_ids: [cognito_user_sub],
        send_after: calcDate.toUTCString(),
        data: { goto: `/dashboard/${id}/${plannedDateFormatted}` },
      });
    }
  }
}
console.log(notifications);
await UserPlansService.scheduleNotifications(
  notifications,
  cognito_user_sub,
);
} catch (error) {
console.log(error);
}*/
  }

  static async listenToNotifications() {
    return LocalNotifications.requestPermissions().then(async (data) => {
      await LocalNotifications.addListener(
        "localNotificationActionPerformed",
        (notificationAction) => {
          logEvent("open_notification");
          if (
            notificationAction &&
            notificationAction.notification &&
            notificationAction.notification.actionTypeId
          ) {
            history.push(notificationAction.notification.actionTypeId);
          }
        },
      );
      return data.display;
    });
  }

  static async stopListenToNotifications() {
    await LocalNotifications.removeAllListeners();
  }
  /*static async stopNotifications() {
    LocalNotifications.unregister();
  }*/
}
export default LocalNotificationsService;
