import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import PlanFieldTransition from "./plan-field-transition";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { dateFormat } from "../../../lib/date-helper";
import moment from "moment";
import SnackBarMessageDisplay from "../../../components/common/snack-bar-message-display";
import classNames from "classnames";
import { t } from "i18next";
import CalendarToday from "@material-ui/icons/CalendarToday";

const styles = (theme) => ({
  gridItem: {
    paddingTop: theme.spacing.unit * 5,
    paddingRight: 16,
    paddingLeft: 16,
  },

  button: {
    marginTop: theme.spacing.unit * 2,
    borderRadius: "10px",
  },
  toRightBtn: {
    display: "block",
    marginLeft: "auto",
  },
  buttonR: {
    marginTop: theme.spacing.unit * 2,
    borderRadius: 50,
    marginLeft: "auto",
  },

  disclaimer: {
    marginTop: theme.spacing.unit * 5,
    fontWeight: 500,
    fontSize: 16,
    color: "#9E9E9E",
  },

  tellMore: {
    marginTop: theme.spacing.unit * 5,
    fontWeight: 500,
    fontSize: 24,
  },

  racePriorityContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
  racePriority: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePrioritySelected: {
    margin: "0 5px",
    background: "#1E629B",
    borderRadius: "100%",
    fontWeight: 900,
    fontSize: 21,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "34px",
    textAlign: "center",
  },
  racePriorityUnselected: {
    margin: "0 5px",
    background: "rgba(30, 98, 155, 0.12)",
    borderRadius: "100%",
    fontWeight: 900,
    fontSize: 21,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "34px",
    textAlign: "center",
  },
  calendarIcon: {
    color: "#9E9E9E",
  },
  label: {
    color: "#9E9E9E",
    fontSize: "16px",
    textAlign: "left",
  },
});

class PlanFieldGoalSelect extends Component {
  state = {
    currentUnits: "kms",
    raceName: null,
    raceDistance: undefined,
    raceElevation: undefined,
    raceDate: null,
    showRaceDetails: false,
    racePriority: null,
    errorMessage: null,
  };

  componentWillUnmount = () => {
    window.removeEventListener("storage", this.storageHandler);
  };

  componentDidMount = () => {
    const currentUnits = localStorage.getItem("units");
    this.setState({
      currentUnits,
    });

    const { userPlans } = this.props;
    const { planTempData } = userPlans || {};
    const goal = planTempData.goal;

    if (goal) {
      this.setState({ ...this.state, showRaceDetails: true });
      for (const key in goal) {
        const booleanValue = Boolean(goal[key]);
        if (booleanValue) this.setState({ [key]: goal[key] });
      }
    }

    window.addEventListener("storage", this.storageHandler);
  };

  storageHandler = () => {
    this.setState({
      ...this.state,
      currentUnits: localStorage.getItem("units"),
    });
  };

  handleButtonClick = (nextGoal) => {
    const { onSelect, id } = this.props;
    const {
      raceDate,
      raceDistance,
      raceName,
      raceElevation,
      currentUnits,
      racePriority,
    } = this.state;

    if (nextGoal) {
      if (!raceDate || !raceName) {
        this.setState({
          ...this.state,
          errorMessage: t("Race name and race date fields are required", {
            ns: "constants",
          }),
        });

        setTimeout(() => {
          this.setState({ ...this.state, errorMessage: null });
        }, 3000);

        return;
      }
    }

    const calculatedDistance =
      currentUnits === "kms"
        ? raceDistance
        : `${Math.round(+raceDistance * 1.60934)}`;
    const calculatedElevation =
      currentUnits === "kms"
        ? raceElevation
        : `${Math.round(+raceElevation * 0.3048)}`;

    if (onSelect) {
      onSelect({
        id,
        value: raceDate
          ? {
              raceDate,
              raceName,
              raceDistance: !isNaN(raceDistance)
                ? calculatedDistance
                : undefined,
              raceElevation: !isNaN(raceElevation)
                ? calculatedElevation
                : undefined,
              racePriority,
            }
          : null,
      });
    }
  };

  isNumber = (number) =>
    /^\d{0,10}$|(?=^.{0,10}$)^\d+\.\d{0,10}$/gm.test(number);

  handlePriorityChange = (priority) => {
    this.setState({
      ...this.state,
      racePriority: priority,
    });
  };

  handleTextChange = (event) => {
    const { target } = event;
    if (target) {
      const { value, name } = target;
      if (
        name === "raceDistance" &&
        !this.isNumber(value) &&
        (!this.state.raceDistance || !this.state.raceDistance.length)
      )
        return this.setState({
          ...this.state,
          ["raceDistance"]: "",
        });

      if (name === "raceDistance" && !this.isNumber(value)) return;

      if (
        name === "raceElevation" &&
        !this.isNumber(value) &&
        (!this.state.raceElevation || !this.state.raceElevation.length)
      )
        return this.setState({
          ...this.state,
          ["raceElevation"]: "",
        });

      if (name === "raceElevation" && !this.isNumber(value)) return;

      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  handleRaceDateChange = (momentObject) => {
    const { onError } = this.props;
    if (momentObject) {
      try {
        this.setState({
          ...this.state,
          raceDate: momentObject.format(dateFormat),
        });
      } catch (exp) {
        if (onError) onError(exp);
      }
    } else {
      this.setState({
        ...this.state,
        raceDate: undefined,
      });
    }
  };

  handleNext = () => {
    this.setState({
      racePriority: "A",
      showRaceDetails: true,
    });
  };

  render() {
    const { classes, xs, sm, md, lg, xl, t } = this.props;
    const {
      raceDate,
      raceDistance,
      raceName,
      raceElevation,
      currentUnits,
      showRaceDetails,
      racePriority,
      errorMessage,
    } = this.state;
    return (
      <>
        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
        />
        <PlanFieldTransition>
          <Grid
            item
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            className={classes.gridItem}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                value={moment(raceDate || null, dateFormat).toISOString()}
                format="DD/MM/YYYY"
                placeholder="DD/MM/AAAA *"
                minDate={moment()}
                mask={(value) =>
                  // handle clearing outside if value can be changed outside of the component
                  value
                    ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
                    : []
                }
                autoOk
                clearable
                keyboard
                fullWidth
                disableOpenOnEnter
                animateYearScrolling={false}
                onChange={this.handleRaceDateChange}
                inputVariant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment disableTypography position="end">
                      <CalendarToday className={classes.calendarIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>

            {showRaceDetails ? (
              <>
                <Typography className={classes.tellMore} variant="body2">
                  {t("Tell us more about your race.", { ns: "layout" })}
                </Typography>

                <TextField
                  variant="outlined"
                  defaultValue={raceName}
                  fullWidth
                  id="raceName"
                  name="raceName"
                  margin="normal"
                  label={`${t("Race Name", { ns: "layout" })}*`}
                  onChange={this.handleTextChange}
                  inputProps={{ maxLength: 80 }}
                  InputLabelProps={{
                    classes: { root: classes.smallerFontSize },
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  value={raceDistance}
                  id="raceDistance"
                  name="raceDistance"
                  margin="normal"
                  label={
                    !currentUnits || currentUnits === "kms"
                      ? t("Race Distance (in Kms)", { ns: "layout" })
                      : t("Race Distance (in miles)", { ns: "layout" })
                  }
                  inputProps={{ maxLength: 10 }}
                  onChange={this.handleTextChange}
                  InputLabelProps={{
                    classes: { root: classes.smallerFontSize },
                  }}
                />

                <TextField
                  defaultValue={raceElevation}
                  value={raceElevation}
                  variant="outlined"
                  fullWidth
                  id="raceElevation"
                  name="raceElevation"
                  inputProps={{ maxLength: 10 }}
                  margin="normal"
                  label={
                    !currentUnits || currentUnits === "kms"
                      ? t("Race Elevation (in meters)", { ns: "layout" })
                      : t("Race Elevation (in feet)", { ns: "layout" })
                  }
                  onChange={this.handleTextChange}
                  InputLabelProps={{
                    classes: { root: classes.smallerFontSize },
                  }}
                />

                <div className={classes.racePriorityContainer}>
                  <Typography variant="h6" className={classes.racePriority}>
                    {t("Goal priority", { ns: "layout" })}:
                  </Typography>

                  <Tooltip
                    title={t("Choose your goal priority", { ns: "layout" })}
                  >
                    <Typography
                      variant="h6"
                      onClick={() => this.handlePriorityChange("A")}
                      className={
                        racePriority === "A"
                          ? classes.racePrioritySelected
                          : classes.racePriorityUnselected
                      }
                    >
                      A
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={t("Choose your goal priority", { ns: "layout" })}
                  >
                    <Typography
                      variant="h6"
                      onClick={() => this.handlePriorityChange("B")}
                      className={
                        racePriority === "B"
                          ? classes.racePrioritySelected
                          : classes.racePriorityUnselected
                      }
                    >
                      B
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={t("Choose your goal priority", { ns: "layout" })}
                  >
                    <Typography
                      variant="h6"
                      onClick={() => this.handlePriorityChange("C")}
                      className={
                        racePriority === "C"
                          ? classes.racePrioritySelected
                          : classes.racePriorityUnselected
                      }
                    >
                      C
                    </Typography>
                  </Tooltip>
                </div>
                <Typography className={classes.label}>
                  {t("We recommend you to had here your A race, or main goal.")}
                </Typography>
              </>
            ) : (
              ""
            )}

            {!showRaceDetails && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                  className={classNames(classes.button, classes.toRightBtn)}
                  disabled={!raceDate}
                >
                  {t("Next")}
                </Button>

                <Typography className={classes.disclaimer} variant="body2">
                  {t("If you don’t have a race or goal planned, that’s ok!", {
                    ns: "layout",
                  })}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleButtonClick(false)}
                  className={classes.button}
                  disabled={raceDate}
                >
                  {t("I don’t have a race or goal planned", { ns: "layout" })}
                </Button>
              </>
            )}

            {showRaceDetails && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleButtonClick(true)}
                className={classNames(classes.button, classes.toRightBtn)}
              >
                {t("Next")}
              </Button>
            )}
          </Grid>
        </PlanFieldTransition>
      </>
    );
  }
}

PlanFieldGoalSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation("constants", "layout")(PlanFieldGoalSelect),
  ),
);
