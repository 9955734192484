import React, { useEffect } from "react";
import { Button, Typography, makeStyles, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import SmallAvatar from "../../generals/small-avatar";
import Notifications from "./Notifications";
import Search from "./Search";
import { useDispatch } from "react-redux";
import { updateNavbar } from "../../../actions/navbar-action";
import Logo from "./Logo";
import { useTranslation } from "react-i18next";
import CustomButton from "./CustomButton";
import { useChatContext } from "../../../context/ChatProvider";
import { IconSearch, IconSettings } from "@tabler/icons-react";
import { IconMessageCircle2Filled } from "@tabler/icons-react";
import { IconMessageCircle2 } from "@tabler/icons-react";
import { usePusher } from "../../../context/PusherProvider";
import useUserInfo from "../../../hooks/useUserInfo";
import { Badge } from "@mui/material";
import useChat from "../../../hooks/useChat";

const AppNav = ({
  visible,
  title,
  isLogo,
  chatButton,
  buttonChildrenProps,
  chatButtonChildrenProps,
  isAvatar,
  isSearch,
  isNoti,
  isSearching,
  isSettings,
  isTrailhead,
  isTraining,
  isDashboard,
  isProfile,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("home");
  const { sub, unreadMessages } = useUserInfo();
  const { openChat } = useChatContext();
  const { subscribeToChannel, unsubscribeFromChannel } = usePusher();
  const { notifyNewMessage, insertNewMessageByEvent } = useChat();

  const searchActived = (e) => {
    e.preventDefault();
    dispatch(updateNavbar({ isSearching: true }));
  };

  const handleUpdateMessages = (data) => {
    notifyNewMessage(true);
    insertNewMessageByEvent(data);
  };

  useEffect(() => {
    if (sub)
      subscribeToChannel(`chat-${sub}`, "message-event", handleUpdateMessages);

    return () => {
      unsubscribeFromChannel(
        `chat-${sub}`,
        "message-event",
        handleUpdateMessages,
      );
    };
  }, [sub]);

  return visible ? (
    <div className={classes.root}>
      {isAvatar && (
        <SmallAvatar
          onTrailHead={false}
          showMountainIndex={true}
          level={false}
        />
      )}

      {buttonChildrenProps && !isTraining && !isSearching && !isTrailhead && (
        <Link
          id="home_go_pro_button"
          className={classes.link}
          to={buttonChildrenProps.to}
        >
          <Button className={classes.buttonAccent}>
            <div className={classes.buttonText}>
              {t(`${buttonChildrenProps.name}`)}
            </div>
          </Button>
        </Link>
      )}

      {isLogo && !isSearching && !buttonChildrenProps && <Logo width={80} />}

      {title && !isSearching && (
        <Typography className={classes.titleText}>{t(title)}</Typography>
      )}

      {isSearch && isSearching && (
        <div style={{ width: "100%" }}>
          <Search />
        </div>
      )}

      <div className={classes.icons}>
        {chatButtonChildrenProps && isTraining && !isSearching && (
          <CustomButton
            onClick={() => openChat()}
            color="default"
            typeStyle="accent"
            variant="contained"
            size="small"
            classes={{
              label: classes.gap,
            }}
            style={{
              fontSize: 16,
              textTransform: "uppercase",
              fontFamily: "Oswald",
              fontWeight: 500,
              letterSpacing: 1,
            }}
          >
            {t(`${chatButtonChildrenProps.name}`)}
            <IconMessageCircle2Filled color="#FEFFFF" stroke={1.5} />
          </CustomButton>
        )}
        {isSearch && !isSearching && (
          <div
            id="search_icon"
            onClick={(e) => searchActived(e)}
            className={classes.iconContainer}
          >
            <IconSearch color="#6D6F71" stroke={1.5} />
          </div>
        )}
        {isNoti && (
          <div className={classes.iconContainer}>
            <Notifications />
          </div>
        )}
        {isSettings && (
          <div className={classes.iconContainer}>
            <Link to="/profile/settings" className={classes.link}>
              <IconButton style={{ padding: 0 }}>
                <IconSettings color="#6D6F71" stroke={1.5} />
              </IconButton>
            </Link>
          </div>
        )}
        {chatButton && (isDashboard || isProfile) && !isSearching && (
          <IconButton style={{ padding: 0 }} onClick={() => openChat()}>
            <Badge
              color="primary"
              invisible={!unreadMessages}
              overlap="circular"
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                "& .MuiBadge-dot": {
                  backgroundColor: "#F93C6E",
                  width: "10px",
                  height: "10px",
                  marginRight: "2px",
                  marginTop: "2px",
                  borderRadius: "50%",
                },
              }}
            >
              <IconMessageCircle2 color="#6D6F71" stroke={1.5} />
            </Badge>
          </IconButton>
        )}
      </div>
    </div>
  ) : null;
};

AppNav.defaultProps = {
  title: null,
  isAvatar: true,
  isLogo: true,
  isNoti: true,
  isSearch: true,
  isMobile: true,
  isSettings: false,
  isTraining: false,
  buttonChildrenProps: null,
};

export default AppNav;

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
  gap: {
    gap: 8,
  },
  root: {
    width: "-webkit-fill-available",
    height: 74,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
    background: "#FEFFFF",
    justifyContent: "space-between",
    alignItems: "center",
    display: "inline-flex",
  },
  titleText: {
    color: "#12151B",
    fontSize: 16,
    fontFamily: "Karla",
    fontWeight: "700",
    lineHeight: 24,
    wordWrap: "break-word",
  },
  profilePhoto: {
    width: 50,
    height: 50,
    position: "relative",
  },
  profile: {
    width: 44.64,
    height: 44.64,
    left: 2.68,
    top: 2.68,
    position: "absolute",
    borderRadius: "50%",
    border: "3px #6BB488 solid",
  },
  ellipse56: {
    width: 18,
    height: 18,
    left: 32,
    top: 32,
    position: "absolute",
    background: "#6BB488",
    borderRadius: "50%",
  },
  countText: {
    width: 18,
    height: 18,
    left: 32,
    top: 32,
    position: "absolute",
    textAlign: "center",
    color: "#FEFFFF",
    fontSize: 10,
    fontFamily: "Karla",
    fontWeight: "700",
    lineHeight: 15,
    wordWrap: "break-word",
  },
  buttonAccent: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    background: "#F93C6E",
    boxShadow: "0px 4px 30px rgba(11, 15, 19, 0.09)",
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: 34,
  },
  buttonText: {
    color: "#FEFFFF",
    fontSize: 14,
    fontFamily: "Oswald",
    fontWeight: "500",
    textTransform: "uppercase",
    letterSpacing: 1,
    wordWrap: "break-word",
  },
  icons: {
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 16,
    display: "flex",
  },
  iconContainer: {
    width: 24,
    height: 24,
    position: "relative",
  },
  vector: {
    width: 18,
    height: 18,
    position: "absolute",
    border: "1.70px #6D6F71 solid",
  },
}));
