import actionTypes from "../constants/action-types";

const initialState = {
  data: [],
  loading: false,
  error: null,
  sampleWeek: null,
  loadingSampleWeek: false,
  errorMessage: null,
  loadingContact: false,
  contactResponse: null,
  loadingReserve: false,
  reserveResponse: null,
};

export default function subcategoriesReducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case actionTypes.SUBCATEGORIES_GET_PENDING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.SUBCATEGORIES_GET_FULFILLED:
        return {
          ...state,
          data: action.payload,
          loading: false,
        };
      case actionTypes.SUBCATEGORIES_GET_REJECTED:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };

      case actionTypes.SUBCATEGORIES_GET_SAMPLE_PENDING:
        return {
          ...state,
          sampleWeek: null,
          loadingSampleWeek: true,
        };
      case actionTypes.SUBCATEGORIES_GET_SAMPLE_FULFILLED:
        return {
          ...state,
          sampleWeek: action.payload,
          loadingSampleWeek: false,
        };
      case actionTypes.SUBCATEGORIES_GET_SAMPLE_REJECTED:
        return {
          ...state,
          error: action.payload,
          loadingSampleWeek: false,
        };

      case actionTypes.SUBCATEGORIES_CLEAR:
        return {
          ...state,
          data: [],
        };

      case actionTypes.POST_CONTACT_FORM_PENDING:
        return { ...state, loadingContact: true };

      case actionTypes.POST_CONTACT_FORM_FULLFILLED:
        return {
          ...state,
          loadingContact: false,
          contactResponse: action.payload,
        };

      case actionTypes.POST_CONTACT_FORM_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingContact: false,
        };

      case actionTypes.CLEAR_CONTACT_FORM:
        return {
          ...state,
          contactResponse: null,
        };

      case actionTypes.POST_RESERVE_SPOT_PENDING:
        return {
          ...state,
          loadingReserve: true,
        };

      case actionTypes.POST_RESERVE_SPOT_FULLFILLED:
        return {
          ...state,
          loadingReserve: false,
          reserveResponse: action.payload,
        };

      case actionTypes.CLEAR_RESERVE_SPOT:
        return {
          ...state,
          reserveResponse: null,
        };

      case actionTypes.POST_RESERVE_SPOT_REJECTED:
        return {
          ...state,
          loadingReserve: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      default:
        return state;
    }
  } else {
    return state;
  }
}
