import React, { useEffect, useState } from "react";
import {
  Box,
  Slide,
  Dialog,
  Typography,
  Button,
  LinearProgress,
} from "@material-ui/core";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import history from "../../../lib/history-helper";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { MobileStepper } from "@mui/material";
import useQuestionnaire from "../../../hooks/useQuestionnaire";
import OnboardingGenerator from "../../../components/common/v2/OnboardingGenerator";
import moment from "moment";
import { dateFormat } from "../../../lib/date-helper";
import useUserInfo from "../../../hooks/useUserInfo";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const generateContinue = (
  stepsConfig,
  currentStep,
  handleNext,
  loadingSave,
) => {
  const currentStepConfig = stepsConfig[currentStep - 1];

  const { t } = useTranslation("onboarding");
  const classes = useStyles();

  const handleContinue = () => {
    handleNext();
  };

  return (
    <>
      {currentStepConfig &&
        currentStepConfig.buttonComponents &&
        currentStepConfig.buttonComponents.map((component, index) => {
          switch (component.type) {
            case "button":
              return loadingSave ? (
                <LinearProgress color="secondary" key={index} />
              ) : (
                <Button
                  key={index}
                  variant="contained"
                  onClick={handleContinue}
                  className={classes.logInButton}
                >
                  {t(component.value)}
                </Button>
              );
            case "textButton":
              return (
                <Button key={index} variant="text" onClick={handleNext}>
                  <Typography className={classes.backButtonText}>
                    {t(component.value)}
                  </Typography>
                </Button>
              );
            default:
              return null;
          }
        })}
    </>
  );
};

const OnboardingSuggester = () => {
  const [openDialog, setOpenDialog] = useState(true);
  const { attributes, nextRaces, isSubscribed } = useUserInfo();
  const { birthdate, locale, gender } = attributes || {};
  const { id } = useParams();
  const {
    values,
    setValues,
    onboardingStorage,
    finishOnboarding,
    loadingSave,
    setPlanValues,
    getSubcategoriesByPlan,
    plansData,
    planToSelectQuestionaire,
    initialStepsConfig,
    activeStep,
    saveActiveStep,
  } = useQuestionnaire();
  const [stepsConfig, setStepsConfig] = useState(initialStepsConfig);

  const steps = stepsConfig.length;
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        await getSubcategoriesByPlan(id);
      }
    };
    fetchData();
  }, [id, getSubcategoriesByPlan]);

  useEffect(() => {
    const setData = async () => {
      if (id && plansData.length) {
        await onboardingStorage.setItem(
          "onboardingPlanType",
          plansData[0].plan,
        );
        await onboardingStorage.setItem("onboardingPlan", plansData[0]);
        await planToSelectQuestionaire(plansData[0]);
      }
    };
    setData();
  }, [id, plansData, onboardingStorage, planToSelectQuestionaire]);

  useEffect(() => {
    const initializeValues = () => {
      const initialValues = {};
      initialStepsConfig.forEach((step) => {
        step.components.forEach((component) => {
          if (
            ["title", "subTitle", "dashedButton", "raceInput"].includes(
              component.type,
            )
          )
            return;
          initialValues[component.name] =
            component.type === "date" && component.name !== "birthdate"
              ? moment().format(dateFormat)
              : component.value;
        });
      });
      setValues(initialValues);
    };

    const removeQuestions = async () => {
      const selectedPlan = await onboardingStorage.getItem("onboardingPlan");
      if (selectedPlan && selectedPlan.questions) {
        setStepsConfig((prevStepsConfig) =>
          prevStepsConfig.filter(
            (step) =>
              !selectedPlan.questions.some(
                (question) =>
                  question.field === step.name &&
                  question.defaultValue !== undefined,
              ),
          ),
        );
      }
    };

    initializeValues();
    setPlanValues();
    removeQuestions();
  }, []);

  useEffect(() => {
    if (nextRaces && nextRaces.length) {
      setStepsConfig((prevStepsConfig) =>
        prevStepsConfig.filter((step) => step.name !== "race_planned"),
      );
    }
  }, [nextRaces]);

  useEffect(() => {
    if (birthdate && gender && locale) {
      setStepsConfig((prevStepsConfig) =>
        prevStepsConfig.filter((step) => step.name !== "welcome"),
      );
    }
  }, [attributes]);

  const handleNext = async () => {
    const newActiveStep = activeStep + 1;
    if (activeStep === steps) {
      await onboardingStorage.setItem("onboardingSuggester", values);
      const onboardingPlanType = await onboardingStorage.getItem(
        "onboardingPlanType",
      );
      if (onboardingPlanType === "explore" || isSubscribed) {
        await finishOnboarding();
        history.push("/");
      } else {
        history.push("/subscription/explore");
      }
    } else {
      saveActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    const newActiveStep = activeStep - 1;
    saveActiveStep(newActiveStep);
  };

  const handleCloseDialog = () => {
    if (activeStep > 1) {
      handleBack();
    } else {
      setStepsConfig(initialStepsConfig);
      setOpenDialog(false);
    }
  };

  const handleTransitionEnd = () => {
    history.goBack();
  };

  const handleChange = (value, name) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionProps={{ onExited: handleTransitionEnd }}
      TransitionComponent={Transition}
      display={"flex"}
      flexDirection="column"
    >
      <ToolbarReturnBack
        withLogo={true}
        logoWidth={107}
        style={{
          backgroundColor: "#FAFAFA",
          alignItems: "flex-start",
          padding: "10px",
        }}
        goBack={handleCloseDialog}
      />

      <Box className={classes.parentBox}>
        <Box className={classes.contentContainer}>
          <Box className={classes.stepperContainer}>
            <MobileStepper
              variant="progress"
              steps={steps}
              position="static"
              activeStep={activeStep - 1}
              className={classes.stepper}
              sx={{ backgroundColor: "#FAFAFA", borderRadius: "10px" }}
              LinearProgressProps={{
                className: classes.linearProgress,
                sx: {
                  backgroundColor: "#EAEAEA",
                  "& .MuiLinearProgress-bar": {
                    borderRadius: "10px",
                    background:
                      "linear-gradient(90deg, #0B2B5C 0%, #F93C6E 100.89%)",
                  },
                },
              }}
            />
            <Typography className={classes.stepperText}>
              {activeStep} of {steps}
            </Typography>
          </Box>
          {stepsConfig.length > 0 &&
            OnboardingGenerator(
              stepsConfig,
              activeStep,
              handleChange,
              handleNext,
              values,
            )}
        </Box>
        {generateContinue(stepsConfig, activeStep, handleNext, loadingSave)}
      </Box>
    </Dialog>
  );
};

export default OnboardingSuggester;

const useStyles = makeStyles({
  parentBox: {
    flex: 1,
    maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    overflow: "hidden",
    padding: "16px",
    gap: "12px",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  backButtonText: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "karla",
    textTransform: "initial",
    color: "#4780AB",
  },
  title: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    color: "#12151B",
    textTransform: "initial",
    lineHeight: "22.5px",
  },

  subtitle: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#12151B",
    lineHeight: "24px",
  },
  contentContainer: {
    flex: 1,
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    marginTop: "40px",
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: "#FAFAFA",
  },
  toggleButtonGroup: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "16px",
  },
  raceContainer: {
    overflowY: "auto",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
  sliderContainer: {
    padding: "16px",
  },
  singleButton: {
    backgroundColor: "#FEFFFF",
    borderRadius: "4px",
    padding: "16px",
    height: "56px",
    justifyContent: "flex-start",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
    "&:hover": {
      border: "none",
      color: "#4780AB",
    },
  },
  singleButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#12151B",
    textTransform: "initial",
  },
  singleButtonTextSelected: {
    color: "#FEFFFF",
  },
  toggleButton: {
    fontFamily: "Oswald",
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "40px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
    width: "45%",
    "&:hover": {
      border: "none",
      color: "#4780AB",
    },
  },
  toggleButtonContained: {
    border: "none",
    color: "#FEFFFF",
    backgroundColor: "#4780AB",
  },
  toggleButtonOutlined: {
    border: "none",
    backgroundColor: "#FEFFFF",
  },
  stepperContainer: {
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
  },
  stepper: {
    flex: 1,
    display: "flex",
    borderRadius: "4px",
  },
  stepperText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "14px",
    color: "#12151B",
    lineHeight: "21px",
    alignSelf: "center",
  },
  linearProgress: {
    flex: 1,
    borderRadius: "4px",
  },
  emailButton: {
    backgroundColor: "#FEFFFF",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  googleButton: {
    backgroundColor: "#FEFFFF",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  facebookButton: {
    backgroundColor: "#3C5A9A",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  appleButton: {
    backgroundColor: "#12151B",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  darkButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#FEFFFF",
  },
  lightButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#12151B",
  },
  emailButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#0B2B5C",
  },
  logInButton: {
    backgroundColor: "#0B2B5C",
    height: "47px",
    color: "#FFFFFF",
    borderRadius: "4px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#0B2B5C",
    },
  },
  dividerText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 700,
  },
  termsText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "21px",
    fontSize: "14px",
    fontWeight: 400,
  },
  signUpText: {
    alignSelf: "center",
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 400,
  },
  termsButton: {
    color: "#4780AB",
    fontWeight: 700,
    cursor: "pointer",
  },
  loginButton: {
    color: "#F93C6E",
    fontWeight: 700,
    cursor: "pointer",
  },
  highlightText: {
    color: "#F93C6E",
  },
  inputForm: {
    backgroundColor: "#FEFFFF",
  },
  sliderTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sliderText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#6D6F71",
  },
  slider: {
    color: "#0B2B5C",
  },
});
