import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core'

import PlanFieldTransition from './plan-field-transition';

class PlanFieldLabel extends Component {
    render() {
        const { className, label, xs, sm, md, lg, xl } = this.props;
        return (
            <PlanFieldTransition>
                <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <Typography variant="caption" className={className}>
                        {label}
                    </Typography>
                </Grid>
            </PlanFieldTransition>
        );
    };
}

PlanFieldLabel.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
}

PlanFieldLabel.defaultProps = {
    tooltipShow: true
}

export default PlanFieldLabel;