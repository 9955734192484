import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
    cell: {
        width: 1
    },
    link: {
        color: theme.palette.text.primary
    }
});

class EnhancedTableCellEdit extends Component {
    render() {
        const { linkTo, classes, onClick, icon } = this.props;
        return <TableCell
            className={classes.cell}
            onClick={(event => event.stopPropagation())}>
            {linkTo ? <Link className={classes.link} to={linkTo}><IconButton>{icon || <EditIcon />}</IconButton></Link> :
                onClick ? <IconButton onClick={onClick}><EditIcon /></IconButton> : null}
        </TableCell>
    }
}

EnhancedTableCellEdit.propTypes = {
    classes: PropTypes.object.isRequired,
    linkTo: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.object
}

export default withStyles(styles)(EnhancedTableCellEdit);