import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateNavbar } from "../actions/navbar-action";
import useRouteInfo from "./useRouteInfo";
import useUserInfo from "./useUserInfo";

const useAppNav = (location = {}) => {
  const dispatch = useDispatch();
  const appNavState = useSelector((state) => state.appNav);
  const { isSearching } = appNavState;
  const auth = useSelector((state) => state.auth);
  const {
    isOnProfile,
    isOnDashboard,
    isOnTrailhead,
    isOnTraining,
    pathname,
    choosePlanVariant,
  } = useRouteInfo(location, auth);
  const { isSubscribed } = useUserInfo();

  useEffect(() => {
    let newAppNavState = {
      visible: true,
      title: null,
      isLogo: true,
      isAvatar: true,
      isNoti: true,
      isSettings: false,
      isSearch: false,
      isTraining: false,
      isDashboard: false,
      isProfile: false,
      isSearching: isSearching,
      chatButton: false,
      chatButtonChildrenProps: { name: "appnav.chat.name" },
      buttonChildrenProps: !isSubscribed
        ? { name: "appnav.getCoach.name", to: "/subscription/explore" }
        : null,
    };

    if (isOnDashboard)
      newAppNavState = {
        ...newAppNavState,
        isSearch: true,
        isDashboard: true,
        isTraining: false,
        isProfile: false,
        isTrailhead: false,
        isLogo: true,
        chatButton: true,
        buttonChildrenProps: !isSubscribed
          ? { name: "appnav.getCoach.name", to: "/subscription/explore" }
          : null,
      };
    if (isOnProfile)
      newAppNavState = {
        ...newAppNavState,
        title: "appnav.profile.title",
        isLogo: true,
        isAvatar: false,
        isSettings: true,
        isDashboard: false,
        isTraining: false,
        isSearching: false,
        isProfile: true,
        isTrailhead: false,
        chatButton: true,
        buttonChildrenProps: !isSubscribed
          ? { name: "appnav.getCoach.name", to: "/subscription/explore" }
          : null,
      };
    if (isOnTrailhead)
      newAppNavState = {
        ...newAppNavState,
        title: "appnav.trailhead.title",
        isLogo: false,
        isAvatar: true,
        isSearch: true,
        isTraining: false,
        isSettings: false,
        isDashboard: false,
        isProfile: false,
        isTrailhead: true,
      };
    if (choosePlanVariant)
      newAppNavState = {
        ...newAppNavState,
        visible: false,
      };
    if (isOnTraining)
      newAppNavState = {
        ...newAppNavState,
        title: "appnav.training.title",
        isLogo: false,
        isNoti: false,
        isTraining: true,
        isTrailhead: false,
        isDashboard: false,
        isProfile: false,
        chatButtonChildrenProps: { name: "appnav.chat.name" },
      };
    dispatch(updateNavbar(newAppNavState));
  }, [pathname, isSubscribed, isSearching]);

  return {
    appNavState,
  };
};

export default useAppNav;
