import React from "react";
import { SvgIcon } from "@material-ui/core";
import { palette } from "../../theme/palette";

const CalendarIcon = ({
  width = 24,
  height = 24,
  activated = false,
  fill = null,
}) => (
  <>
    <SvgIcon>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill={fill ? fill : activated ? palette.primary : "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.1 13.4C12.1 13.1239 12.3239 12.9 12.6 12.9H16.1C16.3761 12.9 16.6 13.1239 16.6 13.4V16.9C16.6 17.1761 16.3761 17.4 16.1 17.4H12.6C12.3239 17.4 12.1 17.1761 12.1 16.9V13.4ZM18.4 4.8H17.5V3.9C17.5 3.40294 17.0971 3 16.6 3C16.1029 3 15.7 3.40294 15.7 3.9V4.8H8.5V3.9C8.5 3.40294 8.09706 3 7.6 3C7.10294 3 6.7 3.40294 6.7 3.9V4.8H5.8C4.81 4.8 4 5.61 4 6.6V19.2C4 20.19 4.81 21 5.8 21H18.4C19.39 21 20.2 20.19 20.2 19.2V6.6C20.2 5.61 19.39 4.8 18.4 4.8ZM18.4 6.6V8.4H5.8V6.6H18.4ZM5.8 19.2V10.2H18.4V19.2H5.8Z"
          fill={fill ? fill : activated ? palette.primary : palette.darkGray}
        />
      </svg>
    </SvgIcon>
  </>
);
export default CalendarIcon;
