import React from "react";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  Button,
  makeStyles,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { palette } from "../../../theme/palette";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StatsIcon from "../../icons/stats-icon";
import unitsHook from "../../../hooks/unitsHook";

const useStyles = makeStyles((theme) => ({
  explanation: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    justifyContent: "center",
    padding: "24px 10px",
  },
  iconText: {
    alignItems: "flex-start",
    alignSelf: "stretch",
    display: "flex",
    position: "relative",
    width: "100%",
  },
  iconInfo: {
    alignItems: "flex-start",
    display: "inline-flex",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    position: "relative",
  },
  formulaExplanation: {
    color: theme.palette.text.primary,
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0,
    lineHeight: theme.typography.pxToRem(24),
    marginTop: -1,
    position: "relative",
  },
  textWrapper: {
    color: theme.palette.grey[900],
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0,
    lineHeight: theme.typography.pxToRem(24),
  },
  span: {
    fontFamily: theme.typography.fontFamilyBold,
    fontWeight: theme.typography.fontWeightBold,
  },
  textWrapper2: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  formulaMI: {
    alignItems: "center",
    alignSelf: "stretch",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    padding: "16px 16px",
    gap: 12,
  },
  formula: {
    alignItems: "center",
    display: "inline-flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    justifyContent: "center",
    position: "relative",
  },
  div: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0,
    lineHeight: theme.typography.pxToRem(24),
    marginTop: -1,
    position: "relative",
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  line: {
    margin: "auto",
    backgroundColor: palette.black,
    width: 140,
    height: 2,
  },
  textWrapper3: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0,
    lineHeight: theme.typography.pxToRem(24),
    position: "relative",
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  textWrapper4: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamilyButtons,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    height: theme.spacing(6),
    letterSpacing: 0,
    alignItems: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  list: {
    alignItems: "center",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    justifyContent: "center",
    position: "relative",
    padding: "0px 0px 0 30px",
    listTypePosition: "outside",
    listStyleType: "disc",
  },
  listItem: {
    display: "list-item",
    padding: "8px 0",
  },
  p: {
    alignSelf: "stretch",
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0,
    lineHeight: theme.typography.pxToRem(24),
    position: "relative",
  },
  textWrapper5: {
    color: theme.palette.grey[900],
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0,
    lineHeight: theme.typography.pxToRem(24),
  },
  btnOutlined: {
    color: palette.darkBlue,
    borderColor: palette.darkBlue,
    width: "100%",
    textTransform: "capitalize",
    borderWidth: "2px",
    height: 47,
  },
  boxButton: {
    padding: "0 20px",
    marginBottom: 24,
  },
  iconMargin: {
    float: "right",
  },
}));

const MIExplanation = () => {
  const classes = useStyles();
  const { t } = useTranslation("mountainIndex");
  const units = unitsHook();

  return (
    <>
      <Box className={classes.explanation}>
        <Box className={classes.iconText}>
          <Box className={classes.iconInfo}>
            <InfoIcon className={classes.iconInformation} />
          </Box>
          <Typography
            className={classes.formulaExplanation}
            dangerouslySetInnerHTML={{ __html: t("mountainIndex.explanation") }}
          />
        </Box>
        <Box className={classes.formulaMI}>
          <Box className={classes.formula}>
            <Typography className={classes.div}>
              {t("mountainIndex.explanation.fraction.numerator", {
                unit: !units || units === "kms" ? "m" : "feet",
              })}
            </Typography>
            <Divider className={classes.line} orientation="vertical" />
            <Typography className={classes.textWrapper3}>
              {t("mountainIndex.explanation.fraction.denominator", {
                unit: !units || units === "kms" ? "km" : "miles",
              })}
            </Typography>
          </Box>
          <Box>=</Box>
          <Box>
            <Typography className={classes.textWrapper4}>
              {t("mountainIndex.explanation.fraction.result").toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Typography className={classes.p}>
              {t("mountainIndex.explanation.item.one")}
            </Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Typography
              className={classes.p}
              dangerouslySetInnerHTML={{
                __html: t("mountainIndex.explanation.item.two"),
              }}
            />
          </ListItem>
        </List>
      </Box>
      <Box className={classes.boxButton}>
        <Link to="/profile">
          <Button
            variant="outlined"
            className={classes.btnOutlined}
            endIcon={
              <>
                <StatsIcon />
              </>
            }
          >
            <b>{t("mountainIndex.explanation.buttonMore")}</b>
          </Button>
        </Link>
      </Box>
    </>
  );
};

export default MIExplanation;
