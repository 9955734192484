import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Avatar,
  Chip,
  TextField,
  Button,
  Link,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { Storage } from "aws-amplify";
import { withTranslation } from "react-i18next";
import moment from "moment";
import LinkPreview from "@ashwamegh/react-link-preview";
import "@ashwamegh/react-link-preview/dist/index.css";
import "moment/min/locales";
import AddComment from "./post/add-comment";
import Comment from "./post/comment";
import RouterLink from "../common/router-link";
import PostActionsMenu from "./post/post-actions-menu";
import AutoCompleteMultiSelect from "../common/auto-complete-multi-select";
import { dateFormat } from "../../lib/date-helper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Allez from "./post/allez";
import { getVideoIframe, getYoutubeId } from "../../lib/youtube-helper";
import linkifyStr from "linkify-string";
import { getLevel } from "../../lib/rates-helper";
import DashboardNextRacePane from "../dashboard/dashboard-training/dashboard-next-race/dashboard-next-race-pane";
import FinishedChallengeH from "../challenges/finished-challenge-h";
import history from "../../lib/history-helper";
import RaceCard from "./social-profile/races/components/race-card";

const styles = (theme) => ({
  container: {
    maxWidth: 620,
    padding: 20,
    background: "#ffffff",
    margin: 10,
    borderRadius: 10,
  },
  postImage: {
    width: "100%",
  },
  topBarContainer: {
    display: "flex",
  },
  topBar: {
    display: "flex",
    alignItems: "center",
  },
  actions: {
    marginLeft: "auto",
  },
  avatar: {
    marginRight: 15,
    position: "relative",
  },
  levelMiniBadge: {
    height: 25,
    bottom: -5,
    right: -10,
    position: "absolute",
  },
  username: {
    fontWeight: "bold",
    fontSize: 14,
  },
  raceName: {
    fontSize: 12,
    color: "#A4A4B2",
  },
  title: {
    title: 16,
    fontWeight: "bold",
  },
  text: {
    fontSize: 14,
    whiteSpace: "pre-wrap",
    marginBottom: 10,
    marginTop: 10,
  },
  bottomBar: {
    marginTop: 10,
  },

  commentsCountContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },

  allezCountContainer: {
    display: "flex",
    alignItems: "center",
  },

  count: {
    fontSize: 12,
  },

  countComments: {
    margin: "0 5px 0px 0",
    fontFamily: "Montserrat",
    fontWeight: "400",
    fontSize: 12,
    color: "#9E9E9E",
  },

  commentsText: {
    fontFamily: "Montserrat",
    fontWeight: "400",
    fontSize: 12,
    color: "#9E9E9E",
  },

  divider: {
    border: "solid 1px #ECEDF4",
  },
  metaBar: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
  metaChip: {
    marginRight: 10,
    marginBottom: 10,
  },
  metaText: {
    color: "#A4A4B2",
    fontSize: 12,
  },
  allezIcon: {
    cursor: "pointer",
  },
  commentsContainer: {},
  commentIcon: {
    marginLeft: 10,
  },
  previousComments: {
    width: "fit-content",
    padding: 5,
    margin: 5,
    background: "#ecedf4",
    borderRadius: 5,
    cursor: "pointer",
  },
  editButtons: {
    display: "flex",
  },
  cancelButton: {
    marginLeft: "auto",
  },
  reportText: {
    color: "red",
  },
  loadingAllez: {
    width: "15px !important",
    height: "15px !important",
    marginLeft: 5,
  },
  loadingContainer: {
    textAlign: "center",
  },
  externalLink: {
    color: "#000",
    textAlign: "center",
  },

  countContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },

  allezContainer: {
    borderTop: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
    margin: "12px -20px",
    padding: "10px 20px 5px 20px",
  },

  question: {
    fontWeight: 900,
    fontSize: 18,
    textTransform: "uppercase",
    textAlign: "center",
    background: "#F9F9FB",
    borderRadius: 10,
    marginTop: 10,
    whiteSpace: "pre-wrap",
    marginBottom: 10,
    padding: 10,
  },

  group: {
    fontWeight: "bold",
    color: "#000000",
    cursor: "pointer",
  },
});

class Post extends Component {
  state = {
    imgUrls: {},
    numCommentsToShow: 3,
    isEditing: false,
    units: "kms",
  };

  async componentDidMount() {
    this.checkImages();

    if (typeof window !== "undefined") {
      this.setState({
        ...this.state,
        units: localStorage.getItem("units"),
      });

      window.addEventListener("storage", () => this.localStorageUpdated());
    }
  }

  localStorageUpdated() {
    this.setState({
      ...this.state,
      units: localStorage.getItem("units"),
    });
  }

  checkImages = () => {
    const { postData } = this.props;
    let images = [];
    if (postData.by && postData.by.image) {
      images.push(postData.by.image);
    }
    if (postData.image) {
      images.push(postData.image);
    }
    this.getUrls(images);
  };

  getUrls = async (images) => {
    if (images) {
      const imgUrls = {};
      for (let i = 0; i < images.length; i++) {
        try {
          const result = await Storage.get(images[i], { level: "public" });
          imgUrls[images[i]] = result;
        } catch (exp) {
          /*onError(
            new Error(
              t(
                "Oops, we could not load your profile picture. Give it another try",
              ),
            ),
          );*/
          throw exp;
        }
      }
      this.setState({
        ...this.state,
        imgUrls,
      });
    }
  };

  showMoreComments = () => {
    this.setState({
      ...this.state,
      numCommentsToShow: this.state.numCommentsToShow + 3,
    });
  };

  handleTitleChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          ...this.state,
          editTitle: value || "",
        });
      }
    }
  };

  handleTextChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          ...this.state,
          editText: value || "",
        });
      }
    }
  };

  handleTagSelect = (event) => {
    this.setState({
      ...this.state,
      editTags: event,
    });
  };

  handleStartEdit = () => {
    const { postData, i18n, tags } = this.props;
    const { tags: postTags, title, text } = postData;
    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const langTags = tags
      ? tags.map((tag) => {
          return {
            label: tag.name[languageDetector]
              ? tag.name[languageDetector]
              : tag.name["en"],
            value: tag.id,
          };
        })
      : [];
    this.setState({
      ...this.state,
      isEditing: true,
      editTitle: title,
      editTags: postTags.map((t) => langTags.find((lT) => lT.value === t)),
      editText: text,
    });
  };

  handleCancelEdit = () => {
    this.setState({
      ...this.state,
      isEditing: false,
    });
  };

  handleSaveEdit = () => {
    const { onEdit, postData } = this.props;
    onEdit({
      id: postData.id,
      text: this.state.editText,
      title: this.state.editTitle,
      tags: this.state.editTags
        ? this.state.editTags.map((tag) => tag.value)
        : "",
    });
    this.setState({
      ...this.state,
      isEditing: false,
    });
  };

  handleSetPinned = (pinned) => {
    const { onEdit, postData, pinnedPosts = [] } = this.props;
    onEdit(
      {
        ...postData,
        pinned: pinned ? "YES" : "",
        pinned_order: pinnedPosts.length + 1,
      },
      true,
    );
  };

  handleMoveDown = () => {
    const { onEdit, postData, pinnedPosts = [] } = this.props;
    onEdit(
      {
        ...postData,
        pinned_order: postData.pinned_order + 1,
      },
      true,
    );

    const postToMoveUp = pinnedPosts.find(
      (p) => p.pinned_order === postData.pinned_order + 1,
    );
    if (postToMoveUp) {
      onEdit(
        {
          ...postToMoveUp,
          pinned_order: postToMoveUp.pinned_order - 1,
        },
        true,
      );
    }
  };

  handleMoveUp = () => {
    const { onEdit, postData, pinnedPosts = [] } = this.props;
    onEdit(
      {
        ...postData,
        pinned_order: postData.pinned_order - 1,
      },
      true,
    );

    const postToMoveDown = pinnedPosts.find(
      (p) => p.pinned_order === postData.pinned_order - 1,
    );
    if (postToMoveDown) {
      onEdit(
        {
          ...postToMoveDown,
          pinned_order: postToMoveDown.pinned_order + 1,
        },
        true,
      );
    }
  };

  goToGroup = (id) => {
    history.push(`/trailhead/group/${id}`);
  };

  render() {
    const {
      classes,
      t,
      postData,
      tags,
      i18n,
      onAddAllez,
      userSub,
      onAddComment,
      onTagClick,
      onDelete,
      onEdit,
      onReport,
      onBlock,
      isAdmin,
      onError,
      addingAllezOn,
      addingCommentOn,
      userImg,
      hasSub,
      isAuthenticated,
      openSignUp,
      urlRelative,
      relativeToParent,
      pinnedPosts = [],
    } = this.props;
    const {
      imgUrls,
      numCommentsToShow,
      isEditing,
      editTags,
      editText,
    } = this.state;
    const {
      id,
      by,
      text,
      allez_count,
      comments_count,
      link,
      image: postImage,
      tags: postTags,
      created_at,
      comments,
      reports,
      allez,
      crown,
      goal,
      type,
      badge,
      epicRace,
      group,
      groupData,
      pinned,
      pinned_order,
    } = postData;
    const {
      cognito_user_sub,
      cognito_user_name,
      nextRace,
      nextRaces,
      image,
      accumulatedDistance = 0,
      accumulatedElevation = 0,
    } = by || {};

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    let allRaces = [];
    if (nextRace) {
      const today = moment();
      const raceDateMoment = moment(nextRace.raceDate, "DD/MM/YYYY");
      const weeksToRace = raceDateMoment.diff(today, "day");
      if (weeksToRace >= 0) {
        allRaces.push(nextRace);
      }
    }
    if (nextRaces && nextRaces.length > 0) {
      nextRaces.forEach((element) => {
        const today = moment();
        const raceDateMoment = moment(element.raceDate, "DD/MM/YYYY");
        const weeksToRace = raceDateMoment.diff(today, "day");
        if (weeksToRace >= 0) {
          allRaces.push(element);
        }
      });
    }

    const commentsToShow = comments.slice(
      Math.max(comments.length - numCommentsToShow, 0),
    );

    const langTags = tags
      ? tags.map((tag) => {
          return {
            label: tag.name[languageDetector]
              ? tag.name[languageDetector]
              : tag.name["en"],
            value: tag.id,
          };
        })
      : [];

    const tag =
      postTags && postTags.length > 0
        ? tags.find((t) => t.id === postTags[0])
        : null;

    const level =
      accumulatedDistance || accumulatedElevation
        ? getLevel(accumulatedDistance, accumulatedElevation)
        : 1;

    return (
      <div className={classes.container}>
        <div className={classes.topBarContainer}>
          <div className={classes.topBar}>
            <RouterLink
              className={classes.avatar}
              to={
                isAuthenticated
                  ? `/trailhead/profile/${cognito_user_sub}`
                  : `/trailhead`
              }
            >
              <Avatar
                src={
                  image
                    ? imgUrls[image]
                    : `${process.env.PUBLIC_URL}/avatar-empty.png`
                }
              />
              {level ? (
                <img
                  src={`${process.env.PUBLIC_URL}/trl${level}-sm.svg`}
                  className={classes.levelMiniBadge}
                />
              ) : (
                ""
              )}
            </RouterLink>

            <div className={classes.usernameContainer}>
              <Typography className={classes.username} variant="body2">
                {cognito_user_name}
              </Typography>

              {allRaces && allRaces.length > 0 && (
                <Typography className={classes.raceName} variant="body2">
                  {allRaces[0].raceName}
                </Typography>
              )}

              <Typography className={classes.metaText} variant="body2">
                {`${moment(created_at)
                  .locale(language)
                  .fromNow()}`}
                {group &&
                groupData &&
                groupData.name &&
                groupData.name[languageDetector] ? (
                  <>
                    {` ${t("on")} `}
                    <span
                      className={classes.group}
                      onClick={() => this.goToGroup(group)}
                    >
                      {groupData.name[languageDetector]
                        ? groupData.name[languageDetector]
                        : groupData.name["en"]}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </Typography>
            </div>
          </div>
          <PostActionsMenu
            className={classes.actions}
            isMine={cognito_user_sub === userSub}
            type={"post"}
            onDelete={() => onDelete("post", postData)}
            onEdit={this.handleStartEdit}
            onReport={(data) => onReport(id, data)}
            onBlock={() => onBlock(cognito_user_sub)}
            onPin={this.handleSetPinned}
            isPinned={pinned === "YES"}
            pinnedOrder={pinned_order}
            isLastPinned={pinnedPosts.length === pinned_order}
            onMoveDown={this.handleMoveDown}
            onMoveUp={this.handleMoveUp}
            isAdmin={isAdmin}
          />
        </div>
        <div className={classes.content}>
          {isEditing ? (
            <TextField
              value={editText ? editText : ""}
              margin="normal"
              variant="outlined"
              placeholder={t("Text placeholder")}
              fullWidth={true}
              multiline={true}
              onChange={this.handleTextChange}
            />
          ) : (
            <Typography
              className={type === "question" ? classes.question : classes.text}
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: linkifyStr(text, {
                  target: {
                    url: "_blank",
                  },
                }),
              }}
            ></Typography>
          )}

          <div style={{ width: "100%" }}>
            {link ? (
              getYoutubeId(link) ? (
                <div className={classes.externalLink}>
                  {getVideoIframe(link, false, relativeToParent)}
                </div>
              ) : (
                <Link
                  href={link}
                  target="_blank"
                  className={classes.externalLink}
                >
                  <LinkPreview url={link} />
                </Link>
              )
            ) : (
              ""
            )}
          </div>

          {postImage && (
            <RouterLink to={`/trailhead/post/${id}`}>
              <img
                src={imgUrls[postImage]}
                alt=""
                className={classes.postImage}
              />
            </RouterLink>
          )}
          {goal && (
            <DashboardNextRacePane
              nextRace={goal}
              userPlans={null}
              editing={false}
              onEdit={null}
              onSave={null}
              onDelete={null}
              isTrailhead={true}
            ></DashboardNextRacePane>
          )}
          {badge && (
            <FinishedChallengeH
              yourEntry={badge}
              onShareChallenge={null}
              units={this.state.units}
            ></FinishedChallengeH>
          )}
          {epicRace && <RaceCard item={epicRace}></RaceCard>}
        </div>
        {isEditing ? (
          <AutoCompleteMultiSelect
            suggestions={langTags}
            placeholder={t("Tags placeholder")}
            isClearable={true}
            value={editTags}
            onChange={(event) => this.handleTagSelect(event)}
            disable={false}
          />
        ) : (
          ""
        )}

        {isEditing && (
          <div className={classes.editButtons}>
            <Button
              className={classes.cancelButton}
              onClick={this.handleCancelEdit}
              color="primary"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={this.handleSaveEdit}
              color="primary"
              disabled={!editText}
            >
              {t("Save")}
            </Button>
          </div>
        )}

        <div className={classes.bottomBar}>
          <div className={classes.countContainer}>
            <div className={classes.allezCountContainer}>
              {allez_count > 0 && id !== addingAllezOn && (
                <Typography
                  className={classes.count}
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html:
                      allez_count === 1
                        ? t("Allez by 1", { name: allez[0].cognito_user_name })
                        : allez_count === 2
                        ? t("Allez by 2", {
                            name: allez[0].cognito_user_name,
                            count: allez_count - 1,
                          })
                        : t("Allez by", {
                            name: allez[0].cognito_user_name,
                            count: allez_count - 1,
                          }),
                  }}
                ></Typography>
              )}
              {id === addingAllezOn && (
                <CircularProgress
                  variant="indeterminate"
                  className={classes.loadingAllez}
                />
              )}
            </div>

            {comments_count > 0 && (
              <div className={classes.commentsCountContainer}>
                <Typography className={classes.countComments} variant="body2">
                  {comments_count}
                </Typography>

                <Typography className={classes.commentsText} variant="body2">
                  {t("Comments")}
                </Typography>
              </div>
            )}
          </div>

          <div className={classes.allezContainer}>
            <Allez
              onAddAllez={() => onAddAllez(id)}
              full={allez.find((a) => a.cognito_user_sub === userSub)}
            />
          </div>

          {!isEditing && tag && tag.name && (
            <Chip
              label={
                tag.name[languageDetector]
                  ? tag.name[languageDetector]
                  : tag.name["en"]
              }
              className={classes.metaChip}
              onClick={() =>
                onTagClick([
                  {
                    value: tag.id,
                    label: tag.name[languageDetector]
                      ? tag.name[languageDetector]
                      : tag.name["en"],
                  },
                ])
              }
            />
          )}
        </div>

        {isAdmin && reports && (
          <div className={classes.reportsContainer}>
            {reports.map((report, i) => (
              <div className={classes.report} key={i}>
                <Typography className={classes.reportText} variant="body2">
                  {`Reported by ${report.cognito_user_name} because it is ${
                    report.reason
                  } on ${moment(report.date).format(dateFormat)}`}
                </Typography>
              </div>
            ))}
          </div>
        )}
        <div className={classes.commentsContainer}>
          {comments.length > commentsToShow.length && (
            <div className={classes.previousComments}>
              <Typography onClick={this.showMoreComments}>
                {t("Previous Comments")}{" "}
                <Chip label={comments.length - commentsToShow.length}></Chip>
              </Typography>
            </div>
          )}
          {commentsToShow.map((comment) => (
            <Comment
              userImg={userImg}
              key={comment.id}
              commentData={comment}
              onAddAllez={onAddAllez}
              userSub={userSub}
              onAddComment={onAddComment}
              onDelete={onDelete}
              onEdit={onEdit}
              onError={onError}
              addingAllezOn={addingAllezOn}
              addingCommentOn={addingCommentOn}
              hasSub={hasSub}
              isAuthenticated={isAuthenticated}
              openSignUp={this.handleOpenSignUp}
              relativeToParent={relativeToParent}
              isAdmin={isAdmin}
            ></Comment>
          ))}
          {id === addingCommentOn && (
            <div className={classes.loadingContainer}>
              <CircularProgress color="primary" />
            </div>
          )}
        </div>
        {isAuthenticated ? (
          <AddComment
            image={userImg}
            userSub={userSub}
            postId={id}
            onAddComment={(comment) => {
              this.setState({
                ...this.state,
                numCommentsToShow: this.state.numCommentsToShow + 1,
              });
              onAddComment(comment);
            }}
            onCancel={() => {}}
            onError={onError}
            hasSub={hasSub}
          ></AddComment>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Post.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string,
  postData: PropTypes.object,
  tags: PropTypes.array,
};

export default withTranslation(["trailhead", "messages"])(
  withWidth()(withStyles(styles, { withTheme: true })(memo(Post))),
);
