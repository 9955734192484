import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import workoutPlans from "../../../constants/workout-plans";
import PlanDetails from "../plan-details";
import {
  setPlanToSelect,
  setSuccessMessage,
} from "../../../actions/user-plans-action";
import WorkoutPlans from "../../../constants/plans";
import history from "../../../lib/history-helper";
import {
  Typography,
  Drawer,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { getSampleWeek } from "../../../actions/subcategory-action";
import DashboardTrainingWeek from "../../dashboard/dashboard-training/dashboard-training-week";
import moment from "moment";
import { dateFormat } from "../../../lib/date-helper";
import MobileShare from "../../common/mobile-share";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing";
import { Capacitor } from "@capacitor/core";
import objectToGetParams from "../../../lib/objectToGetParams";
import { checkSubscription } from "../../../lib/subscription-plan-helper";
import { Clipboard } from "@capacitor/clipboard";
import { useAnalyticsEvent } from "../../../hooks/useAnalyticsEvent";

const styles = (theme) => ({
  drawerRoot: {
    borderRadius: "10px 10px 0px 0px",
    maxHeight: "90%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
      bottom: "0 !important",
      top: "auto",
    },
  },
  buttons: {
    marginTop: "10px",
    paddingBottom: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  rightButtons: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    top: 5,
    right: 5,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
    zIndex: 100,
  },
  detailspicture: {
    position: "relative",
    minHeight: 228,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: 10,
    overflow: "hidden",
    margin: "-16px -16px 10px",
  },
  detailsweeks: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 12,
    color: "#494949",
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },
  detailsTitle: {
    fontFamily: "Avenir",
    fontWeight: "900",
    fontSize: 18,
    color: "#000000",
    textTransform: "uppercase",
    margin: "8px 0 0px",
  },
  detailsDescription: {
    fontFamily: "Montserrat",
    fontWeight: "400",
    fontSize: 14,
    color: "#494949",
    marginBottom: 20,
  },
  detailsAthleteContainer: {
    display: "flex",
    alignItems: "flex-start",
    margin: "12px 0",
  },
  detailsAthleteImage: {
    width: "30%",
    marginTop: 8,
  },
  detailsAthlete: {
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: 18,
    color: "#494949",
    marginLeft: 10,
  },
  detailsAthleteBio: {
    fontFamily: "Montserrat",
    fontWeight: "400",
    fontSize: 14,
    color: "#494949",
  },
  buttonContainer: {
    textAlign: "center",
    padding: 10,
  },
  detailsAdjustable: {
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: 12,
    color: "#9E9E9E",
    marginTop: 20,
  },
  detailsDot: {
    margin: "0 2px",
    width: 20,
  },
  detailsItem: {
    display: "flex",
    alignItems: "center",
    margin: "5px 0",
  },
  detailsItemText: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 18,
    color: "#494949",
    marginRight: 5,
    width: 135,
  },
  features: {
    overflowY: "scroll",
    overflowX: "hidden",
    padding: 15,
  },
  divider: {
    margin: "10px 0 20px 0",
  },
  categoryContainer: {
    position: "relative",
    margin: 10,
    background: "#fff",
    borderRadius: 12,
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  picture: {
    position: "relative",
    minHeight: 228,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: 10,
    borderRadius: "12px 12px 0 0",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      minHeight: 164,
    },
  },
  categoryContent: {
    position: "relative",
    padding: "10px",
    boxSizing: "border-box",
  },
  categoryTitle: {
    fontSize: 20,
    fontWeight: 900,
    color: "#000000",
    marginTop: 10,
    textTransform: "uppercase",
    whiteSpace: "break-spaces",
  },
  athleteTitle: {
    fontSize: 12,
    fontWeight: "normal",
    color: "#000000",
    marginTop: 10,
    textTransform: "uppercase",
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 17,
    lineHeight: "1.5em",
  },
  white: {
    color: theme.palette.common.white,
  },
  free: {
    top: 10,
    left: 10,
    padding: "0px 15px",
    position: "absolute",
    background: "#212121",
    borderRadius: 18,
  },
  freeLabel: {
    fontSize: 16,
    color: "#FFFFFF",
  },
  info: {},
  textContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    overflow: "visible",
    whiteSpace: "nowrap",
  },
  imgIcon: {
    height: 14,
  },
  textInfo: {
    marginLeft: 5,
    fontSize: 14,
    color: "#000000",
  },
  details: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 14,
    textAlign: "right",
    color: "#FF6327",
    marginTop: 10,
    marginLeft: "auto",
  },
  athleteBox: {
    display: "flex",
    alignItems: "center",
  },
  submitButton: {
    borderRadius: 5,
    // marginRight: 14,
    fontSize: 13,
    background: "#FF5721",
    // width: "45%"
  },
  detailsButton: {
    // width: "45%",
    borderRadius: 5,
    fontSize: 13,
  },
  difficulty: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 14,
    background: "rgba(255, 87, 34, 0.24)",
    width: "fit-content",
    padding: "0px 5px",
  },
  hashtagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "14px 0 24px",
  },
  hashtag: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: 10,
    marginRight: 10,
    background: "rgba(63, 81, 181, 0.12)",
    padding: "0 5px",
  },
  seeSample: {
    fontWeight: "normal",
    fontSize: 16,
    textDecoration: "underline",
    cursor: "pointer",
    color: "#FF5722",
    margin: "10px 0",
    alignItems: "center",
    display: "flex",
  },

  progressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    margin: "auto",
  },
  trainingPlanTitle: {
    fontWeight: 500,
    fontSize: 20,
  },

  levelShareContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },

  shareContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },

  share: {
    color: "#FF5722",
    cursor: "pointer",
    fontSize: 14,
  },

  shareIcon: {
    height: 18,
    marginTop: -5,
    marginLeft: 4,
    cursor: "pointer",
  },

  dialogPaper: {
    borderRadius: 10,
  },
  dontWorryParent: {
    margin: "12px 0px 10px 0px",
  },
  dontWorryText: {
    fontSize: "12px",
    fontStyle: "italic",
  },
  noTrainingText: {
    margin: "8px 0px",
    textAlign: "center",
  },
});

const SubCategory = (props) => {
  const {
    classes,
    category,
    i18n,
    t,
    width,
    subscriptions,
    dispatch,
    subcategories,
    landing = false,
    explore = false,
    openDetails,
    currentVariant,
    reserveMySpot,
    auth,
  } = props;

  const { latestExpired, loadingLatest, latest = {} } = subscriptions;
  const { loadingSampleWeek, sampleWeek } = subcategories;
  const { language } = i18n;
  const {
    plan,
    athlete,
    max_distance: distance,
    weeks,
    id,
    intensity,
    strength,
    endurance,
    load,
    launch_date,
  } = category;
  const isInMobileView = width === "sm" || width === "xs";

  const [isHovering, setIsHovering] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [open, setOpen] = useState(false);
  const [sampleOpen, setSampleOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [share, setShare] = useState(false);

  useEffect(() => {
    if (openDetails) {
      setOpen(true);
    }
  }, []);

  const sampleWeekWithDates = [];
  if (sampleWeek) {
    for (let i = 0; i < 7; i++) {
      const currentDate = moment()
        .startOf("isoWeek")
        .add(i, "days");
      if (sampleWeek[i] && sampleWeek[i].length) {
        sampleWeekWithDates.push([
          { ...sampleWeek[i][0], plannedDate: currentDate.format(dateFormat) },
        ]);
      } else {
        sampleWeekWithDates.push(null);
      }
    }
  }

  const handleMouseHover = () => {
    toggleHoverState();
  };

  const toggleHoverState = (state) => {
    setIsHovering(!isHovering);
  };

  const handleSampleWeek = () => {
    setSampleOpen(!sampleOpen);
  };

  const goTo = (fromMain = false, localCategory) => {
    if (auth && auth.isAuthenticated && localCategory && localCategory.tags) {
      if (
        (localCategory.tags.includes("SKYRUNNER World Series") ||
          localCategory.tags.includes("UTMB World Series")) &&
        localCategory.is_unfinished
      )
        return;
    }

    const { userPlans } = props;

    const { currentPlan } = userPlans;
    const { currentTraining = {} } = currentPlan || {};

    if (currentPlan && currentTraining && currentTraining.days) {
      const notNullDays =
        currentTraining && currentTraining.days && currentTraining.days.length
          ? currentTraining.days.filter((d) => !!d)
          : null;

      if (
        notNullDays &&
        notNullDays.length &&
        moment(
          notNullDays[notNullDays.length - 1][0].plannedDate,
          dateFormat,
        ).isSameOrAfter(moment(), "day")
      ) {
        if (fromMain) {
          handleOpen(null);
        } else {
          handleOpenConfirm();
        }
        return;
      }
    }

    goTo1(category);
  };

  const handleCategoryType = (category) => {
    if (category.display_order_first_ultra) return "display_order_first_ultra";
    if (category.display_order_better_trail)
      return "display_order_better_trail";
    if (category.display_order_start_trail) return "display_order_start_trail";
  };

  const goTo1 = (localCategory) => {
    if (auth && auth.isAuthenticated && localCategory && localCategory.tags) {
      if (
        (localCategory.tags.includes("SKYRUNNER World Series") ||
          localCategory.tags.includes("UTMB World Series")) &&
        localCategory.is_unfinished
      )
        return;
    }

    const { auth, onOpenAuth, userPlans } = props;
    const { currentUser, isAuthenticated } = auth;

    const { currentPlan } = userPlans || {};
    const { latestSubscriptionFinalizedTime } = currentPlan || {};

    const isSubscribed = checkSubscription(
      subscriptions,
      latestSubscriptionFinalizedTime,
    );

    if (!isAuthenticated) {
      dispatch(
        setPlanToSelect({
          plan,
          id,
          flag: currentVariant == "B" ? handleCategoryType(category) : null,
        }),
      );
      onOpenAuth();
      return;
    }
    const { attributes } = currentUser || {};
    const { sub, email } = attributes;
    dispatch(
      setPlanToSelect({
        plan,
        id,
        cognito_user_sub: sub,
        cognito_user_email: email,
        flag: currentVariant == "B" ? handleCategoryType(category) : null,
        isPartner:
          localCategory &&
          localCategory.tags &&
          (localCategory.tags.includes("UTMB World Series") ||
            localCategory.tags.includes("SKYRUNNER World Series")),
        redirect: !isSubscribed && plan === "free" ? "free-mobile" : null,
      }),
    );

    setTimeout(() => {
      if (!loadingLatest) {
        const redirectPath =
          plan === WorkoutPlans.Items.FREE
            ? `/chooseplan/${plan}/${id}`
            : !isSubscribed
            ? `/subscription/${plan}`
            : `/chooseplan/${plan}/${id}`;
        history.push(redirectPath);
      }
    }, 100);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    dispatch(getSampleWeek({ id, plan }));
    setOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  const handleOpenConfirm = () => {
    setConfirm(true);
  };

  const createDots = (limit) => {
    let dots = [];

    // Outer loop to create parent
    for (let i = 0; i < 5; i++) {
      if (i < limit) {
        dots.push(
          <img
            src={`${process.env.PUBLIC_URL}/icon-dot-selected.svg`}
            alt=""
            className={classes.detailsDot}
          />,
        );
      } else {
        dots.push(
          <img
            src={`${process.env.PUBLIC_URL}/icon-dot.svg`}
            alt=""
            className={classes.detailsDot}
          />,
        );
      }
    }
    return dots;
  };

  const isMobileOrTablet = () => {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
  };

  const handleShareS1 = async (type) => {
    switch (type) {
      case "instagram":
        props.dispatch(
          setSuccessMessage(
            t(
              "Plan message copied to clipboard. Paste it on Instagram to complete your post",
            ),
          ),
        );

        await Clipboard.write({
          string: t("share text", {
            id: category.id,
          }),
        });

        setTimeout(() => {
          SocialSharing.shareViaInstagram(
            t("share text", {
              id: category.id,
            }),
            category.picture,
          );
        }, 1000);
        setShare(false);
        break;
      case "email":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaEmail(
            t("share text", {
              id: category.id,
            }),
            null,
            null,
            null,
            [category.picture],
          );
        } else {
          window.open(
            "mailto:" +
              objectToGetParams({
                subject: t("share title"),
                body: t("share text", {
                  id: category.id,
                }),
              }),
          );
        }
        setShare(false);
        break;

      case "facebook":
        props.dispatch(
          setSuccessMessage(
            t(
              "Plan message copied to clipboard. Paste it on Facebook to complete your post",
            ),
          ),
        );
        if (!Capacitor.isNativePlatform()) {
          navigator.clipboard.writeText(
            t("share text", {
              id: category.id,
            }),
          );
        } else {
          await Clipboard.write({
            string: t("share text", {
              id: category.id,
            }),
          });
        }
        setTimeout(() => {
          if (Capacitor.isNativePlatform()) {
            SocialSharing.shareViaFacebook(
              t("share text", {
                id: category.id,
              }),
              [category.picture],
            );
          } else {
            window.open(
              "http://www.facebook.com/sharer.php?u=" +
                encodeURIComponent(category.picture),
              "sharer",
              "toolbar=0,status=0,width=626,height=436",
            );
          }
        }, 1000);
        setShare(false);
        break;

      case "whatsapp":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaWhatsApp(
            t("share text", {
              id: category.id,
            }),
            Capacitor.getPlatform() === "ios" ? [] : [category.picture],
          );
        } else {
          window.open(
            "https://" +
              (isMobileOrTablet() ? "api" : "web") +
              ".whatsapp.com/send" +
              objectToGetParams({
                text: t("share text", {
                  id: category.id,
                }),
              }),
          );
        }
        setShare(false);
        break;

      case "twitter":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaTwitter(
            t("share text", {
              id: category.id,
            }),
            [category.picture],
          );
        } else {
          window.open(
            "http://twitter.com/share?" +
              "text=" +
              encodeURIComponent(
                t("share text", {
                  id: category.id,
                }),
              ),
          );
        }
        setShare(false);
        break;

      case "copylink":
        props.dispatch(setSuccessMessage(t("Plan link copied to clipboard")));
        navigator.clipboard.writeText(
          `https://app.vert.run/chooseplan?plan=${category.id}`,
        );
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div
        className={classes.categoryContainer}
        onClick={() => goTo(true, category)}
        onMouseEnter={handleMouseHover}
        onMouseLeave={handleMouseHover}
        style={{
          minWidth: landing
            ? 419
            : explore
            ? isInMobileView
              ? "90%"
              : "30%"
            : "initial",
          minHeight: landing
            ? 447
            : explore
            ? isInMobileView
              ? 374
              : 414
            : "initial",
        }}
      >
        <div
          className={classes.picture}
          style={{
            backgroundImage: `url(${category.picture})`,
          }}
        >
          {plan === workoutPlans.FREE ? (
            <div className={classes.free}>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.freeLabel}
              >
                {t("FreeLabel")}
              </Typography>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={classes.categoryContent}>
          <div className={classes.athleteBox}>
            {athlete ? (
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.athleteTitle}
              >
                {athlete}
              </Typography>
            ) : (
              ""
            )}
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.categoryTitle}
          >
            {category.title[language]
              ? category.title[language]
              : category.title["en"]}
          </Typography>

          <div className={classes.info}>
            <Grid container spacing={32}>
              {weeks ? (
                <Grid
                  item
                  style={{
                    marginRight: 13,
                  }}
                >
                  <div className={classes.textContainer}>
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-timer.svg`}
                      alt=""
                      className={classes.imgIcon}
                    />
                    <Typography variant="body1" className={classes.textInfo}>
                      {t("X weeks", { weeks })}
                    </Typography>
                  </div>
                </Grid>
              ) : (
                ""
              )}
              {distance ? (
                <Grid
                  item
                  style={{
                    marginRight: 13,
                  }}
                >
                  <div className={classes.textContainer}>
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-location.svg`}
                      alt=""
                      className={classes.imgIcon}
                    />
                    <Typography variant="body1" className={classes.textInfo}>
                      {t("up to X", { distance })}
                    </Typography>
                  </div>
                </Grid>
              ) : (
                ""
              )}
              {launch_date ? (
                <Grid item>
                  <div className={classes.textContainer}>
                    <img
                      src={`${process.env.PUBLIC_URL}/launch-date-icon.svg`}
                      alt=""
                      className={classes.imgIcon}
                    />
                    <Typography variant="body1" className={classes.textInfo}>
                      {t("launch")}:{" "}
                      {moment(launch_date, "DD/MM/YYYY")
                        .locale(language)
                        .format("MMMM")}{" "}
                      {language == "en"
                        ? moment(launch_date, "DD/MM/YYYY")
                            .locale(language)
                            .format("Do")
                        : moment(launch_date, "DD/MM/YYYY").format("DD")}
                    </Typography>
                  </div>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </div>

          <div className={classes.buttons}>
            {!explore &&
            (!category ||
              !category.tags ||
              (!category.tags.includes("SKYRUNNER World Series") &&
                !category.tags.includes("UTMB World Series") &&
                !category.tags.includes("X-terra World series"))) ? (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
                className={classes.submitButton}
              >
                {t("Start training")}
              </Button>
            ) : (
              ""
            )}

            {!!category.tags &&
            (category.tags.includes("SKYRUNNER World Series") ||
              category.tags.includes("UTMB World Series") ||
              category.tags.includes("X-terra World series")) &&
            !category.is_unfinished ? (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
                className={classes.submitButton}
              >
                {t("Start training")}
              </Button>
            ) : (
              !!category.tags &&
              (category.tags.includes("SKYRUNNER World Series") ||
                category.tags.includes("UTMB World Series") ||
                category.tags.includes("X-terra World series")) && (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  className={classes.submitButton}
                  onClick={reserveMySpot}
                >
                  {t("Reserve my spot")}
                </Button>
              )
            )}

            <Button
              color="primary"
              size="medium"
              type="submit"
              variant="outlined"
              className={classes.detailsButton}
              onClick={(e) => handleOpen(e)}
            >
              {t("see details")}
            </Button>
          </div>
        </div>
      </div>
      <PlanDetails
        isOpen={selectedPlan ? true : false}
        plan={selectedPlan}
        onClose={() => setSelectedPlan(null)}
      />

      <Drawer
        open={open}
        anchor={isInMobileView ? "bottom" : "left"}
        onClose={handleClose}
        classes={{
          paper: classes.drawerRoot,
        }}
      >
        <div className={classes.rightButtons}>
          <img
            src={`${process.env.PUBLIC_URL}/close.svg`}
            alt=""
            onClick={handleClose}
          />
        </div>

        <div className={classes.features}>
          <div
            className={classes.detailspicture}
            style={{
              backgroundImage: `url(${category.picture})`,
            }}
          ></div>

          <div className={classes.levelShareContainer}>
            {category.runner_level && category.runner_level[language] ? (
              <Typography variant="subtitle2" className={classes.difficulty}>
                {category.runner_level[language]
                  ? category.runner_level[language]
                  : category.runner_level["en"]}
              </Typography>
            ) : (
              ""
            )}

            <div className={classes.shareContainer}>
              <Typography
                variant="subtitle2"
                className={classes.share}
                onClick={() => setShare(true)}
              >
                {t("Share")}
              </Typography>

              <img
                src={`${process.env.PUBLIC_URL}/icon-share-o.svg`}
                alt=""
                className={classes.shareIcon}
                onClick={() => setShare(true)}
              />
            </div>
          </div>

          <div className={classes.dontWorryParent}>
            <Typography
              className={classes.dontWorryText}
              dangerouslySetInnerHTML={{ __html: t("Don't worry") }}
            />
          </div>

          <Typography variant="subtitle2" className={classes.detailsTitle}>
            {category.title[language]
              ? category.title[language]
              : category.title["en"]}
          </Typography>

          <div className={classes.textContainer}>
            <img
              src={`${process.env.PUBLIC_URL}/icon-timer.svg`}
              alt=""
              className={classes.imgIcon}
            />
            <Typography variant="body1" className={classes.textInfo}>
              {t("X weeks", { weeks })}
            </Typography>
          </div>

          <Typography
            variant="subtitle2"
            className={classes.detailsDescription}
          >
            {category.description[language]
              ? category.description[language]
              : category.description["en"]}
          </Typography>

          {intensity ? (
            <div className={classes.detailsItem}>
              <Typography
                variant="subtitle2"
                className={classes.detailsItemText}
              >
                {t("Intensity")}
              </Typography>
              {createDots(intensity)}
            </div>
          ) : (
            ""
          )}

          {strength ? (
            <div className={classes.detailsItem}>
              <Typography
                variant="subtitle2"
                className={classes.detailsItemText}
              >
                {t("Strength")}
              </Typography>
              {createDots(strength)}
            </div>
          ) : (
            ""
          )}

          {endurance ? (
            <div className={classes.detailsItem}>
              <Typography
                variant="subtitle2"
                className={classes.detailsItemText}
              >
                {t("Endurance")}
              </Typography>
              {createDots(endurance)}
            </div>
          ) : (
            ""
          )}

          {load ? (
            <div className={classes.detailsItem}>
              <Typography
                variant="subtitle2"
                className={classes.detailsItemText}
              >
                {t("Training Load")}
              </Typography>
              {createDots(load)}
            </div>
          ) : (
            ""
          )}

          {athlete && category.athlete_bio ? (
            <div className={classes.detailsAthleteContainer}>
              <div>
                <div className={classes.textContainer}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-athlete.svg`}
                    alt=""
                    className={classes.imgIcon}
                  />
                  <Typography
                    variant="subtitle2"
                    className={classes.detailsAthlete}
                  >
                    {athlete}
                  </Typography>
                </div>
                <Typography
                  variant="subtitle2"
                  className={classes.detailsAthleteBio}
                >
                  {category.athlete_bio[language]
                    ? category.athlete_bio[language]
                    : category.athlete_bio["en"]}
                </Typography>
              </div>
              {category.athlete_picture ? (
                <img
                  src={category.athlete_picture}
                  alt=""
                  className={classes.detailsAthleteImage}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {category.average &&
          (category.average.hours || category.average.kilometers) ? (
            <div>
              <div className={classes.textContainer}>
                <img
                  src={`${process.env.PUBLIC_URL}/icon-average.svg`}
                  alt=""
                  className={classes.imgIcon}
                />
                <Typography
                  variant="subtitle2"
                  className={classes.detailsAthlete}
                >
                  {t("Average training per week")}
                </Typography>
              </div>
              <Typography
                variant="subtitle2"
                className={classes.detailsAthleteBio}
              >
                {(category.average.hours
                  ? category.average.hours + " " + t("hours")
                  : "") +
                  (category.average.hours && category.average.kilometers
                    ? " / "
                    : "") +
                  (category.average.kilometers
                    ? category.average.kilometers + " " + t("kilometers")
                    : "")}
              </Typography>
            </div>
          ) : (
            ""
          )}

          {category.hashtags && category.hashtags[language] ? (
            <div className={classes.hashtagsContainer}>
              {category.hashtags[language].map((h, i) => (
                <Typography
                  key={i}
                  variant="subtitle2"
                  className={classes.hashtag}
                >
                  {h}
                </Typography>
              ))}
            </div>
          ) : (
            ""
          )}

          <Typography
            variant="subtitle2"
            className={classes.seeSample}
            onClick={() => handleSampleWeek()}
          >
            {t("See a sample of a training week")}
            <img
              src={`${process.env.PUBLIC_URL}/icon-chevron-${
                sampleOpen ? "up" : "down"
              }-o.svg`}
              alt=""
            />
          </Typography>

          {sampleOpen ? (
            <>
              <Typography
                variant="subtitle2"
                className={classes.trainingPlanTitle}
              >
                {t("Training plan")}
              </Typography>
              {loadingSampleWeek ? (
                <div className={classes.progressContainer}>
                  <CircularProgress
                    variant="indeterminate"
                    color="primary"
                    className={classes.loading}
                    data-testid="LoadingUser"
                  />
                </div>
              ) : sampleWeekWithDates && sampleWeekWithDates.length ? (
                <DashboardTrainingWeek
                  days={sampleWeekWithDates}
                  currentStartDate={moment().startOf("isoWeek")}
                  updatingWorkoutId={false}
                  dayCount={7}
                  isInMobileView={false}
                  onCurrentDaysChangeRequested={() => {}}
                  onWorkoutsMoved={() => {}}
                  userEmail={""}
                  selectedPlan={null}
                  onError={() => {}}
                  hasSubscription={true}
                  isSample={true}
                  level={2}
                />
              ) : (
                <Typography variant="body1" className={classes.noTrainingText}>
                  {t(
                    "The sample week of this training plan will be available once the training plan is live.",
                  )}
                </Typography>
              )}
            </>
          ) : (
            ""
          )}
          <Typography variant="subtitle2" className={classes.detailsAdjustable}>
            {t("adjustable disclaimer")}
          </Typography>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={() => goTo(false, category)}
            color="primary"
            disabled={
              category.tags &&
              (category.tags.includes("SKYRUNNER World Series") ||
                category.tags.includes("UTMB World Series")) &&
              category.is_unfinished
            }
          >
            {t("Start your training journey")}
          </Button>
        </div>
      </Drawer>

      <Dialog
        open={confirm}
        aria-labelledby="subcategory-dialog"
        onClose={() => handleCloseConfirm()}
      >
        <DialogContent>
          <Typography variant="body1">
            {t(
              "You have an active training plan, do you want to replace your active training plan for this new one?",
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => goTo1(category)} color="primary">
            {t("Yes")}
          </Button>
          <Button onClick={() => handleCloseConfirm()} color="primary">
            {t("Not Sure")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={share}
        onClose={() => setShare(false)}
        maxWidth="md"
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogContent id="signupdialog" className={classes.noPadding}>
          <MobileShare
            title={t("Share on")}
            onClose={() => setShare(false)}
            onShare={(type) => handleShareS1(type)}
            copylink={true}
          ></MobileShare>
        </DialogContent>
      </Dialog>
    </>
  );
};

SubCategory.propTypes = {
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }),
  category: PropTypes.object.isRequired,
  width: PropTypes.string,
};

export default connect((store) => {
  return {
    subscriptions: store.subscriptions,
    auth: store.auth,
    subcategories: store.subcategories,
    userPlans: store.userPlans,
  };
})(
  withTranslation("plan")(
    withWidth()(
      withStyles(styles, { withTheme: true })(React.memo(SubCategory)),
    ),
  ),
);
