import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import {
  LinearProgress,
  Paper,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

import {
  changeSearchPhrase,
  generalSearchWorkouts,
  cleanSearch,
  changeSearchFilter,
} from "../../actions/workout-action";
import TrainingWorkout from "./training-workout-search/training-workout";

const styles = (theme) => ({
  container: {
    flex: 0.25,
    textAlign: "center",
    minHeight: 980,
    maxHeight: window.innerHeight - 200 > 980 ? window.innerHeight - 200 : 980,
    overflowY: "scroll",
    marginRight: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    minWidth: 200,
  },
  textField: {
    padding: theme.spacing.unit,
  },
  emptyWorkout: {
    padding: theme.spacing.unit * 2,
    opacity: 0.4,
  },
});

class TrainingWorkoutSearch extends Component {
  componentDidMount() {
    const { isExternalCoach } = this.props;

    if (isExternalCoach) {
      this.props.dispatch(
        changeSearchFilter({ name: "justMine", values: true }),
      );
    } else {
      this.props.dispatch(
        changeSearchFilter({ name: "justMine", values: false }),
      );
    }
  }

  componentWillUnmount() {
    this.props.dispatch(cleanSearch());
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const { workout } = this.props;
      const { currentFilter } = workout;
      const { searchPhrase: searchPhraseBeforeTrim } = currentFilter;
      if (searchPhraseBeforeTrim) {
        let filtersQueryStringReady = {
          searchPhrase: searchPhraseBeforeTrim.trim(),
        };
        this.props.dispatch(generalSearchWorkouts(filtersQueryStringReady));
      }
    }
  };

  handleSearchPhraseChanged = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.props.dispatch(changeSearchPhrase(value));
      }
    }
  };

  handleSelect = ({ name, value }) => {
    const { workout } = this.props;
    const { currentFilter } = workout;

    const values = currentFilter[name];
    if (name === "justMine") {
      this.props.dispatch(changeSearchFilter({ name, values: value }));
      return;
    }
    if (!values || values.indexOf(value) < 0)
      this.props.dispatch(
        changeSearchFilter({ name, values: [...(values || []), value] }),
      );
  };

  render() {
    const {
      classes,
      workout,
      onSelectWorkout,
      isExternalCoach,
      sub,
      disabled,
      level,
    } = this.props;
    const { currentFilter, searchResult, loading, hasBeenSearched } = workout;
    const { searchPhrase, justMine } = currentFilter;
    const { data: dataFull } = searchResult;

    let data = dataFull;
    if (justMine && sub) {
      data = data.filter((w) => w.created_by_cognito_user_sub === sub);
    }

    if (level === "auto") {
      data = data.filter(
        (w) =>
          !w.difficultyLevel ||
          (w.parametrizedWorkout && w.parametrizedWorkout.length),
      );
    } else {
      data = data.filter(
        (w) =>
          !!w.difficultyLevel &&
          (!w.parametrizedWorkout || !w.parametrizedWorkout.length),
      );
    }

    return (
      <Paper className={classes.container}>
        {isExternalCoach ? (
          <FormControlLabel
            control={
              <Switch
                checked={justMine}
                onChange={(event) =>
                  this.handleSelect({
                    name: "justMine",
                    value: !justMine,
                  })
                }
                name="overlay"
                color="primary"
              />
            }
            label={"Just show my workouts"}
            classes={{
              label: classes.switchLabel,
            }}
          />
        ) : (
          ""
        )}

        <div className={classes.textField}>
          <TextField
            name="searchPhrase"
            label="Search Workouts..."
            value={searchPhrase}
            onChange={this.handleSearchPhraseChanged}
            onKeyPress={this.handleKeyPress}
            margin="none"
            fullWidth
            variant="outlined"
            disabled={disabled}
          />
        </div>
        {loading ? (
          <LinearProgress variant="indeterminate" color="primary" />
        ) : hasBeenSearched ? (
          data.length > 0 ? (
            <TrainingWorkout
              workouts={data}
              onSelectWorkout={onSelectWorkout}
            />
          ) : (
            <div className={classes.workoutContainer}>
              No workouts found based on your current search criteria
            </div>
          )
        ) : (
          <div className={classes.emptyWorkout}>
            To search workouts, fill the search area with filters separated by
            space or comma and press Enter on your keyboard
          </div>
        )}
      </Paper>
    );
  }
}

TrainingWorkoutSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectWorkout: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    workout: store.workout,
  };
})(withStyles(styles)(TrainingWorkoutSearch));
