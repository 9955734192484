import React, { Component, memo } from "react";
import { withStyles, Button, Badge } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import WorkoutPlans from "../../constants/plans";
import RouterLink from "../common/router-link";
import ConnectAuth from "../../connectors/connect-auth";
import LanguageMenu from "./language-menu";
import DashboardNotifications from "../dashboard/dashboard-notifications";
import { getReferralCode } from "../../actions/user-plans-action";
import ReferalDialog from "../referal-dialog";
import ProfileMenu from "./profile-menu";
import PaywallModal from "../generals/upgrade-pro-modal";
import history from "../../lib/history-helper";
import { logEvent } from "../../lib/events-helper";
import { checkSubscription } from "../../lib/subscription-plan-helper";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  containerWidth: {
    textAlign: "right",
  },
  marginRight: {
    display: "flex",
    marginRight: 40,
    marginLeft: "auto",
  },
  strava: {
    background: "#ed4834",
    color: "white",
    fontSize: 11,
    [theme.breakpoints.up("md")]: {
      fontSize: 8,
    },
  },
  completeSubscriptionButton: {
    padding: theme.spacing.unit,
  },
  chooseplanButton: {
    fontSize: 11,
    [theme.breakpoints.up("md")]: {
      fontSize: 8,
    },
  },
  topRightCTAButton: {
    textTransform: "initial",
    borderRadius: 26,
    boxShadow: "none",
    width: "max-content",
    fontSize: 16,
  },
  button: {
    fontWeight: "normal",
    textTransform: "none",
    marginRight: 20,
    whiteSpace: "nowrap",
  },
  langDiv: {
    display: "flex",
    alignItems: "center",
  },
  noDisplayInMobile: {
    [theme.breakpoints.down("sm")]: {
      flex: 0,
      display: "none",
    },
  },
  profileIconLink: {
    display: "flex",
  },
  profileIcon: {
    height: 27,
    marginRight: 22,
  },
  iconPresent: {
    cursor: "pointer",
    marginRight: 23,
    height: 24,
  },
  iconChat: {
    cursor: "pointer",
    marginRight: 23,
    height: 24,
  },
  loginButton: {
    minWidth: 130,
  },
  buttons: {
    borderRadius: 50,
    textTransform: "uppercase",
    marginRight: 24,
  },
});

class UserInfo extends Component {
  state = {
    referral: false,
    showpayall: false,
  };

  handleOpenReferral = () => {
    const { userPlans } = this.props;
    const { referralCode } = userPlans;
    if (!referralCode) {
      this.props.dispatch(getReferralCode());
    }
    this.setState({
      ...this.state,
      referral: true,
    });
  };

  handleCloseReferral = () => {
    this.setState({
      ...this.state,
      referral: false,
    });
  };

  render() {
    const {
      auth: { currentUserLoading, isAuthenticated, currentUser },
      classes,
      className,
      userPlans,
      subscriptions,
      t,
      isNotOnChoosingPlanPage,
      isOnDashboard,
      showLanguageMenu,
      showChoosePlanHeader,
      isOnTrailhead,
      isOnExplore,
      isOnTracking,
      subscriptionError,
      handleRetryModal,
    } = this.props;

    const { currentPlan, comments } = userPlans;

    const { currentTraining, latestSubscriptionFinalizedTime } =
      currentPlan || "";

    const { referral } = this.state;

    const showChoosePlanButton =
      isNotOnChoosingPlanPage &&
      currentPlan &&
      currentPlan.selectedPlan !== WorkoutPlans.PREMIUM;
    const showStravaButton =
      isNotOnChoosingPlanPage && currentPlan && !currentPlan.strava_athlete_id;

    const unreadComments = comments
      ? comments.filter((c) => c.type === 0 && !c.isRead)
      : [];

    const handleChatAction = (hasSubscription) => {
      if (hasSubscription) return history.push("/dashboard/chat");
      this.setState({ ...this.state, showpaywall: true });
    };

    const onClosePaywallModal = () => {
      this.setState({ ...this.state, showpaywall: false });
      history.push("/dashboard/chat");
    };

    const isInMobileViewSm =
      this.props.width === "sm" || this.props.width === "xs";

    const {
      latestExpired,
      loadingLatest,
      latest,
      alreadyLoadedOnce,
    } = subscriptions;

    let { selectedPlan } = currentTraining || {};

    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    const isSubscribed = checkSubscription(
      subscriptions,
      latestSubscriptionFinalizedTime,
    );

    if (currentUserLoading) return null;
    else if (isAuthenticated && currentUser) {
      return (
        <div
          className={classNames(
            className,
            classes.container,
            classes.containerWidth,
            {
              [classes.showOneButton]:
                (showChoosePlanButton && !showStravaButton) ||
                (!showChoosePlanButton && showStravaButton),
            },
            {
              [classes.showTwoButtons]:
                showChoosePlanButton && showStravaButton,
            },
          )}
        >
          <div
            className={classNames(classes.langDiv, {
              [classes.noDisplayInMobile]:
                !showLanguageMenu && !showChoosePlanHeader,
            })}
          >
            {isOnDashboard && subscriptionError && !isInMobileViewSm && (
              <InfoIcon
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={handleRetryModal}
              />
            )}

            {isOnTrailhead || isOnDashboard || isOnExplore ? (
              <>
                <DashboardNotifications isOnTrailhead={isOnTrailhead} />
                <div
                  className={classes.iconChat}
                  onClick={() => handleChatAction(!!latest)}
                >
                  <Badge color="error" badgeContent={unreadComments.length}>
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-chat.svg`}
                      alt=""
                    />
                  </Badge>
                </div>
                <PaywallModal
                  open={this.state.showpaywall}
                  onClose={onClosePaywallModal}
                  title="Paywall Title 1"
                  btnText="Try 7 days for free"
                  htmlContent
                  content="Paywall Content 1"
                  price={false}
                />
              </>
            ) : (
              ""
            )}
            <ProfileMenu onReferral={this.handleOpenReferral}></ProfileMenu>
          </div>
          <ReferalDialog
            open={referral}
            onCancel={this.handleCloseReferral}
          ></ReferalDialog>
        </div>
      );
    } else {
      return (
        <div
          className={classNames(
            className,
            classes.marginRight,
            classes.containerWidth,
          )}
        >
          <LanguageMenu />

          <RouterLink to="/auth/signin">
            <Button
              variant="outline"
              color="primary"
              onClick={this.handleSignInRequested}
              className={classes.loginButton}
            >
              {t("Log in")}
            </Button>
          </RouterLink>
          <RouterLink to="/auth/signup">
            <Button
              className={classes.topRightCTAButton}
              variant="contained"
              color="primary"
              onClick={this.handleSignUpRequested}
            >
              {t("Create an account")}
            </Button>
          </RouterLink>
        </div>
      );
    }
  }
}

UserInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  width: PropTypes.string,
  isNotOnChoosingPlanPage: PropTypes.bool,
  isOnDashboard: PropTypes.bool,
  isOnTrailhead: PropTypes.bool,
  isOnExplore: PropTypes.bool,
  isOnTracking: PropTypes.bool,
  className: PropTypes.string,
  userPlans: PropTypes.shape({
    currentStep: PropTypes.number,
    saving: PropTypes.bool,
    loading: PropTypes.bool,
    planTempData: PropTypes.object,
    currentPlan: PropTypes.object,
  }),
  subscriptions: PropTypes.shape({
    latestExpired: PropTypes.bool,
    loadingLatest: PropTypes.bool,
  }),
  dispatch: PropTypes.func,
  setAuthPage: PropTypes.func.isRequired,
  showLanguageMenu: PropTypes.bool,
  showChoosePlanHeader: PropTypes.bool,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
    subscriptions: store.subscriptions,
  };
})(
  ConnectAuth(
    withRouter(
      withTranslation("layout")(
        withWidth()(withStyles(styles, { withTheme: true })(memo(UserInfo))),
      ),
    ),
  ),
);
