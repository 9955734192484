import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import DashboardTrainingWeekday from "./dashboard-training-weekday";
import { dateFormat } from "../../../lib/date-helper";
import Weekdays from "../../../constants/weekdays";
import { ReactSortable } from "react-sortablejs";
import WorkoutTypes from "../../../constants/workout-types";
import history from "../../../lib/history-helper";
import PaywallModal from "../../../components/generals/upgrade-pro-modal";
import { logEvent } from "../../../lib/events-helper";
import { connect } from "react-redux";

const styles = (theme) => ({
  container: {
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  datesContainer: {
    display: "flex",
  },
  date: {
    width: 26,
    textAlign: "center",
    background: "#FFFFFF",
    borderRadius: 18,
    boxShadow: "0 0 12px rgba(33, 81, 209, 0.1)",
    margin: "0 7px",
    padding: 5,
    cursor: "pointer",
  },
  dayText: {
    color: "#9E9E9E",
    fontSize: 12,
  },
  dateSelected: {
    width: 26,
    textAlign: "center",
    background: "#0F4975",
    borderRadius: 18,
    margin: "0 7px",
    padding: 5,
    cursor: "pointer",
  },
  dayTextSelected: {
    color: "#FFFFFF",
    fontSize: 12,
  },
  dialog: {
    margin: 10,
  },
  dialogTitle: {
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 10,
  },
});

class DashboardTrainingWeek extends Component {
  state = {
    currentStartDate: null,
    weekdays: [],
    workoutToMove: null,
    workoutToMove1: null,
    swapOpen: false,
    confirmSwapOpen: false,
    exploreFeatureDialogOpen: false,
  };

  componentDidMount() {
    this.loadDays();
  }

  componentDidUpdate() {
    this.loadDays();
  }

  loadDays() {
    const { currentStartDate, dayCount, days } = this.props;

    let weekdays = [];
    let changed = false;
    for (let counter = 0; counter < dayCount; counter++) {
      const day = moment(currentStartDate)
        .hour(12)
        .minute(0)
        .add(counter, "days");
      const trainingDay = days
        ? days.find(
            (trDay) => trDay && trDay[0].plannedDate === day.format(dateFormat),
          )
        : -1;
      weekdays = [...weekdays, { day, trainingDay }];

      if (
        !changed &&
        trainingDay &&
        trainingDay[0] &&
        this.state.weekdays &&
        this.state.weekdays[counter] &&
        this.state.weekdays[counter].trainingDay &&
        this.state.weekdays[counter].trainingDay[0]
      ) {
        const newTrainingDay = trainingDay[0];
        const oldTrainingDay = this.state.weekdays[counter].trainingDay[0];

        if (
          newTrainingDay.id !== oldTrainingDay.id ||
          newTrainingDay.isDone !== oldTrainingDay.isDone ||
          (newTrainingDay.energyLevel &&
            newTrainingDay.energyLevel !== oldTrainingDay.energyLevel) ||
          newTrainingDay.plannedDate !== oldTrainingDay.plannedDate
        ) {
          changed = true;
        }
      }

      if (
        !changed &&
        trainingDay &&
        trainingDay[0] &&
        this.state.weekdays &&
        this.state.weekdays[counter] &&
        (!this.state.weekdays[counter].trainingDay ||
          !this.state.weekdays[counter].trainingDay[0])
      ) {
        changed = true;
      }

      if (
        !changed &&
        (!trainingDay || !trainingDay[0]) &&
        this.state.weekdays &&
        this.state.weekdays[counter] &&
        this.state.weekdays[counter].trainingDay &&
        this.state.weekdays[counter].trainingDay[0]
      ) {
        changed = true;
      }
    }
    if (
      currentStartDate !== this.state.currentStartDate ||
      changed ||
      !this.state.weekdays
    ) {
      this.setState({
        ...this.state,
        currentStartDate,
        weekdays,
      });
    }
  }

  setDays(ev) {
    if (
      !ev.isMobile &&
      Math.abs(ev.item.offsetTop - ev.originalEvent.srcElement.offsetTop) < 50
    ) {
      return;
    }
    if (
      ev &&
      (ev.oldIndex || ev.oldIndex === 0) &&
      (ev.newIndex || ev.newIndex === 0) &&
      ev.oldIndex !== ev.newIndex
    ) {
      const newState = [...this.state.weekdays];
      newState[ev.newIndex] = this.state.weekdays[ev.oldIndex];
      newState[ev.oldIndex] = this.state.weekdays[ev.newIndex];

      const { currentStartDate, onWorkoutsMoved, onError, t } = this.props;
      for (let counter = 0; counter < 7; counter++) {
        if (newState[counter] && newState[counter].trainingDay) {
          const type = newState[counter].trainingDay[0].workoutType;
          if (
            type === WorkoutTypes.Items.Speedwork ||
            type === WorkoutTypes.Items.ClimbWorkout ||
            type === WorkoutTypes.Items.DescendWorkout
          ) {
            if (counter - 1 > -1) {
              if (newState[counter - 1] && newState[counter - 1].trainingDay) {
                const otherType =
                  newState[counter - 1].trainingDay[0].workoutType;
                if (otherType === type) {
                  onError(
                    new Error(
                      t("Oops, you cant have two on a row", {
                        type: t(`${type}`, { ns: "constants" }),
                      }),
                    ),
                  );
                }
              }
            }
            if (counter + 1 < 7) {
              if (newState[counter + 1] && newState[counter + 1].trainingDay) {
                const otherType =
                  newState[counter + 1].trainingDay[0].workoutType;
                if (otherType === type) {
                  onError(
                    new Error(
                      t("Oops, you cant have two on a row", {
                        type: t(`${type}`, { ns: "constants" }),
                      }),
                    ),
                  );
                }
              }
            }
          }
        }
      }
      let changed = 0;
      for (let counter = 0; counter < 7; counter++) {
        if (newState[counter]) {
          newState[counter].day = moment(currentStartDate)
            .hour(12)
            .minute(0)
            .add(counter, "days")
            .startOf("day");
          if (
            (newState[counter].trainingDay &&
              this.state.weekdays[counter].trainingDay &&
              newState[counter].trainingDay[0].id !==
                this.state.weekdays[counter].trainingDay[0].id) ||
            (newState[counter].trainingDay &&
              !this.state.weekdays[counter].trainingDay) ||
            (!newState[counter].trainingDay &&
              this.state.weekdays[counter].trainingDay)
          ) {
            changed++;
          }
        }
      }
      if (changed === 2) {
        this.setState({ ...this.state, weekdays: newState });
        onWorkoutsMoved({
          plannedDate1: newState[ev.newIndex].trainingDay
            ? newState[ev.newIndex].trainingDay[0].plannedDate
            : null,
          workoutId1: newState[ev.newIndex].trainingDay
            ? newState[ev.newIndex].trainingDay[0].id
            : null,
          plannedDate2: newState[ev.oldIndex].trainingDay
            ? newState[ev.oldIndex].trainingDay[0].plannedDate
            : null,
          workoutId2: newState[ev.oldIndex].trainingDay
            ? newState[ev.oldIndex].trainingDay[0].id
            : null,
          relativePos1: ev.newIndex - ev.oldIndex,
          relativePos2: ev.oldIndex - ev.newIndex,
        });
      }
    }
  }

  handleMobileSwap(event, workoutIndex) {
    event.preventDefault();
    event.stopPropagation();

    const { hasSubscription } = this.props;

    if (hasSubscription) {
      this.setState({
        ...this.state,
        workoutToMove: workoutIndex,
        swapOpen: true,
      });
    } else {
      this.setState({
        ...this.state,
        exploreFeatureDialogOpen: true,
      });
    }
  }

  handleMobileSwapConfirm(index) {
    if (this.state.weekdays[index].trainingDay) {
      this.setState({
        ...this.state,
        workoutToMove1: index,
        swapOpen: false,
        confirmSwapOpen: true,
      });
    } else {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            workoutToMove1: index,
            swapOpen: false,
            confirmSwapOpen: false,
          }),
        100,
      );
      this.setDays({
        oldIndex: this.state.workoutToMove,
        newIndex: index,
        isMobile: true,
      });
    }
  }

  handleDoSwap() {
    this.setDays({
      oldIndex: this.state.workoutToMove,
      newIndex: this.state.workoutToMove1,
      isMobile: true,
    });

    setTimeout(
      () =>
        this.setState({
          ...this.state,
          confirmSwapOpen: false,
        }),
      100,
    );
  }

  handleMobileSwapClose() {
    this.setState({
      ...this.state,
      swapOpen: false,
    });
  }

  handleMobileSwapConfirmClose() {
    this.setState({
      ...this.state,
      confirmSwapOpen: false,
    });
  }

  handleCloseUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
  };

  handleUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
    history.push("/subscription/explore");
  };

  render() {
    const {
      classes,
      currentStartDate,
      desiredStartDate,
      updatingWorkoutId,
      isInMobileView,
      selectedPlan,
      t,
      isSample,
      nextDuration,
      onSendAnalytics,
      level,
      userPlans,
    } = this.props;

    const { currentPlan } = userPlans || {};
    const { currentTraining } = currentPlan || {};
    const { chainedPlans } = currentTraining || {};

    let dates = [];
    for (let counter = 0; counter < 7; counter++) {
      dates = [
        ...dates,
        moment(currentStartDate)
          .startOf("isoWeek")
          .startOf("day")
          .add(counter, "days"),
      ];
    }

    /*const onClick = (index) => {
      const nextCount = dates[index].diff(
        moment(currentStartDate).startOf("day"),
        "days",
      );
      onCurrentDaysChangeRequested(nextCount);
    };*/

    const { swapOpen, confirmSwapOpen, exploreFeatureDialogOpen } = this.state;

    const lastVisibleDate = moment()
      .startOf("isoWeek")
      .add(nextDuration, "weeks");

    return (
      <div className={classes.container}>
        <Dialog
          open={swapOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleMobileSwapClose}
          classes={{
            paper: classes.dialog,
          }}
        >
          <DialogContent>
            <Typography variant="body1" className={classes.dialogTitle}>
              {t("Move this workout to")}
            </Typography>

            <div className={classes.datesContainer}>
              {dates.map((date, index) => {
                if (index !== this.state.workoutToMove) {
                  return (
                    <div
                      className={classes.date}
                      key={index}
                      onClick={() => this.handleMobileSwapConfirm(index)}
                    >
                      <Typography
                        variant={isInMobileView ? "subtitle2" : "h6"}
                        className={classes.dayText}
                      >
                        {`${t(
                          `${Weekdays.itemsArray[date.isoWeekday() - 1].text}`,
                          { ns: "constants" },
                        )
                          .substring(0, 1)
                          .toUpperCase()}`}
                      </Typography>
                      <Typography
                        variant={isInMobileView ? "subtitle2" : "h6"}
                        className={classes.dayText}
                      >
                        {`${date.format("D")}`}
                      </Typography>
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleMobileSwapClose()}
              color="primary"
            >
              {t("Cancel")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmSwapOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleMobileSwapConfirmClose}
        >
          <DialogContent>
            {this.state.weekdays &&
            (this.state.workoutToMove1 || this.state.workoutToMove1 === 0) &&
            this.state.weekdays[this.state.workoutToMove1].trainingDay ? (
              <Typography variant="body1" className={classes.dialogTitle}>
                {t("Confirm Swap Text", {
                  workout: this.state.weekdays[this.state.workoutToMove1]
                    .trainingDay[0].userCreatedType
                    ? this.state.weekdays[this.state.workoutToMove1]
                        .trainingDay[0].userCreatedType
                    : t(
                        `${
                          WorkoutTypes.Items[
                            this.state.weekdays[this.state.workoutToMove1]
                              .trainingDay[0].workoutType
                          ]
                        }`,
                        { ns: "constants" },
                      ),
                  date: `${t(
                    `${
                      Weekdays.itemsArray[
                        this.state.weekdays[
                          this.state.workoutToMove1
                        ].day.isoWeekday() - 1
                      ].text
                    }`,
                    { ns: "constants" },
                  )} ${this.state.weekdays[
                    this.state.workoutToMove1
                  ].day.format("D")}`,
                })}
              </Typography>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleDoSwap()} color="primary">
              {t("Move workout")}
            </Button>
            <Button
              onClick={() => this.handleMobileSwapConfirmClose()}
              color="primary"
            >
              {t("Cancel")}
            </Button>
          </DialogActions>
        </Dialog>

        <PaywallModal
          open={exploreFeatureDialogOpen}
          onClose={this.handleCloseUpgrade}
          list
          title="Paywall Title 2"
          btnText="Try 7 days for free"
        />

        {isInMobileView ||
        (selectedPlan && selectedPlan === "free") ||
        isSample ? (
          this.state.weekdays.map((weekday, index) => {
            return (
              <DashboardTrainingWeekday
                isSample={isSample}
                key={index}
                day={weekday.day}
                trainingDay={weekday.trainingDay}
                desiredStartDate={desiredStartDate}
                updatingWorkoutId={updatingWorkoutId}
                isInMobileView={isInMobileView}
                onMobileSwap={(event) => this.handleMobileSwap(event, index)}
                lastVisibleDate={lastVisibleDate}
                onSendAnalytics={onSendAnalytics}
                level={level}
              />
            );
          })
        ) : (
          <ReactSortable
            list={this.state.weekdays}
            setList={() => {}}
            onUpdate={(ev) => this.setDays(ev)}
            swap
            fallbackOnBody={true}
          >
            {this.state.weekdays.map((weekday, index) => {
              return (
                <DashboardTrainingWeekday
                  isSample={isSample}
                  key={index}
                  day={weekday.day}
                  trainingDay={weekday.trainingDay}
                  desiredStartDate={desiredStartDate}
                  updatingWorkoutId={updatingWorkoutId}
                  isInMobileView={isInMobileView}
                  lastVisibleDate={lastVisibleDate}
                  onSendAnalytics={onSendAnalytics}
                  level={level}
                  chainedPlans={chainedPlans}
                />
              );
            })}
          </ReactSortable>
        )}
      </div>
    );
  }
}

DashboardTrainingWeek.propTypes = {
  classes: PropTypes.object.isRequired,
  days: PropTypes.array,
  currentStartDate: PropTypes.any,
  desiredStartDate: PropTypes.string,
  updatingWorkoutId: PropTypes.string,
  dayCount: PropTypes.number.isRequired,
  isInMobileView: PropTypes.bool,
  onCurrentDaysChangeRequested: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
  selectedPlan: PropTypes.string.isRequired,
  onError: PropTypes.func,
  onWorkoutsMoved: PropTypes.func.isRequired,
};

DashboardTrainingWeek.defaultProps = {
  currentStartDate: moment().startOf("week"),
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withTranslation(["dashboard", "constants"])(
    withStyles(styles, { withTheme: true })(DashboardTrainingWeek),
  ),
);
