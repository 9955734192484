import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import classNames from "classnames";
import { blue, red, green, orange, grey } from "@material-ui/core/colors";
import { Avatar } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";

import { getSubcategoryTitle } from "../../lib/subcategory-helper";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    minHeight: 40,
    padding: 5,
  },
  chip: {
    margin: theme.spacing.unit / 2,
  },
  selectedPlan: {
    backgroundColor: blue[500],
    color: "#fff",
  },
  durationInWeeks: {
    backgroundColor: red[500],
    color: "#fff",
  },
  distance: {
    backgroundColor: green[500],
    color: "#fff",
  },
  surface: {
    backgroundColor: orange[500],
    color: "#fff",
  },
  raceCount: {
    backgroundColor: grey[500],
    color: "#fff",
  },
  capitlize: {
    textTransform: "uppercase",
  },
  labelWrap: {
    whiteSpace: "initial",
  },
});

class TrainingFilterChips extends Component {
  render() {
    const {
      classes,
      filters,
      onDeleteFilter,
      onDeleteSearchPhrase,
      searchPhrase,
      subcategories,
      isOnAthletes,
    } = this.props;
    const { data: subcategoriesData } = subcategories;
    const filterKeys = Object.keys(filters);

    return (
      <div className={classes.container}>
        {searchPhrase ? (
          <Chip
            avatar={
              <Avatar className={classes.capitlize}>
                <SearchIcon />
              </Avatar>
            }
            label={searchPhrase}
            className={classes.chip}
            onDelete={onDeleteSearchPhrase}
          />
        ) : null}
        {filterKeys.map((key, keyIndex) => {
          if (filters[key] && filters[key].length)
            return filters[key].map((data, index) => {
              return (
                <Chip
                  avatar={
                    <Avatar className={classes.capitlize}>{key[0]}</Avatar>
                  }
                  key={`${keyIndex}-${index}`}
                  label={
                    key === "subcategory"
                      ? getSubcategoryTitle(subcategoriesData, data)
                      : data
                  }
                  onDelete={onDeleteFilter.bind(this, key, data)}
                  className={classNames(classes.chip, classes[key])}
                  style={{
                    height: isOnAthletes ? "initial" : 32,
                  }}
                  classes={{
                    label: isOnAthletes ? classes.labelWrap : "",
                  }}
                />
              );
            });
          return null;
        })}
      </div>
    );
  }
}

TrainingFilterChips.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object,
  onDeleteFilter: PropTypes.func.isRequired,
  onDeleteSearchPhrase: PropTypes.func.isRequired,
  searchPhrase: PropTypes.string,
  subcategories: PropTypes.object.isRequired,
};

TrainingFilterChips.defaultProps = {
  filters: [],
};

export default withStyles(styles)(TrainingFilterChips);
