import ActionTypes from "../constants/action-types";
import { getActivityCalculations } from "../lib/units-helper";

const initialState = {
  initializing: false,
  loading: false,
  connected: false,
  activities: [],
  loadingActivity: false,
  currentActivity: null,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.SUUNTO_INIT_PENDING:
        return {
          ...state,
          initializing: true,
          connected: false,
        };
      case ActionTypes.SUUNTO_INIT_FULFILLED:
        return {
          ...state,
          initializing: false,
          connected: true,
          athlete: action.payload,
        };
      case ActionTypes.SUUNTO_INIT_REJECTED:
        return {
          ...state,
          initializing: false,
          connected: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.SUUNTO_LOAD_ACTIVITIES_PENDING:
        return {
          ...state,
          loading: true,
          errorMessage: undefined,
          activities: action.payload ? [] : state.activities,
        };
      case ActionTypes.SUUNTO_LOAD_ACTIVITIES_FULFILLED:
        return {
          ...state,
          loading: false,
          activities: action.payload,
        };
      case ActionTypes.SUUNTO_LOAD_ACTIVITIES_REJECTED:
        return {
          ...state,
          loading: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.SUUNTO_GET_ACTIVITY_PENDING:
        return {
          ...state,
          loadingActivity: true,
          errorMessage: undefined,
        };
      case ActionTypes.SUUNTO_GET_ACTIVITY_FULFILLED:
        return {
          ...state,
          loadingActivity: false,
          currentActivity: getActivityCalculations(
            action.payload,
            localStorage.getItem("units"),
            "suunto",
          ),
        };
      case ActionTypes.SUUNTO_GET_ACTIVITY_REJECTED:
        return {
          ...state,
          loadingActivity: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.SUUNTO_CLEAR_ACTIVITY:
        return {
          ...state,
          loadingActivity: false,
          currentActivity: null,
        };

      case ActionTypes.SUUNTO_CLEAR_ACTIVITIES:
        return { ...initialState };

      default:
        return state;
    }
  } else {
    return state;
  }
}
