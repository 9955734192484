import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

import TrainingWorkoutChips from "./training-workout-chips";

const styles = (theme) => ({
  workout: {
    border: "1px solid lightgray",
    borderRadius: 8,
    margin: theme.spacing.unit,
    padding: theme.spacing.unit / 2,
    cursor: "pointer",
  },
});

class TrainingWorkout extends Component {
  handleDragStart = (workout, event) => {
    const { onSelectWorkout } = this.props;
    if (onSelectWorkout) onSelectWorkout(workout);
  };

  render() {
    const { classes, workouts } = this.props;
    return workouts.map((workout, index) => {
      return (
        <div
          draggable={true}
          key={index}
          className={classes.workout}
          onDragStart={this.handleDragStart.bind(this, workout)}
          title={`${
            workout.description["en"] ? workout.description["en"] : ""
          }\n\n${workout.description["es"] ? workout.description["es"] : ""}`}
        >
          <Typography variant="body1">
            {workout.title &&
              (workout.title["en"] ? workout.title["en"] : workout.title["es"])}
          </Typography>
          <TrainingWorkoutChips workout={workout} level={2} />
        </div>
      );
    });
  }
}

TrainingWorkout.propTypes = {
  classes: PropTypes.object.isRequired,
  workouts: PropTypes.array.isRequired,
  onSelectWorkout: PropTypes.func.isRequired,
};

export default withStyles(styles)(TrainingWorkout);
