import React from "react";

const GarminIcon = ({ width = 18, height = 18, vWidth = 18, vHeight = 18 }) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vWidth} ${vHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.01702 15.2026L8.62841 3.344C8.70482 3.20695 8.90208 3.20725 8.97808 3.34452L15.5429 15.2031C15.6167 15.3364 15.5203 15.5 15.3679 15.5H2.1917C2.03907 15.5 1.94269 15.3359 2.01702 15.2026Z"
        fill="#0075C2"
        stroke="#0075C2"
      />
    </svg>
  </>
);
export default GarminIcon;
