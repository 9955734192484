import React, { useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import SmallAvatar from "../../generals/small-avatar";
import usePost from "../../../hooks/usePost";
import { useTranslation } from "react-i18next";
import { IconSend2 } from "@tabler/icons-react";
import {
  FormControl,
  IconButton,
  makeStyles,
  OutlinedInput,
} from "@material-ui/core";

const CommentTextField = ({ parentId, reference }) => {
  const [commentText, setCommentText] = useState("");
  const { t } = useTranslation("community");
  const classes = useStyles();
  const { handleAddComment, postId, handleSetReplyId } = usePost();

  const handleCommentSubmit = () => {
    if (commentText.trim() !== "") {
      if (parentId != postId) {
        handleSetReplyId(parentId);
      }
      handleAddComment(commentText);
      setCommentText("");
    }
  };

  const setCommentId = () => {
    if (parentId != postId) {
      handleSetReplyId(parentId);
    }
  };

  return (
    <FormControl fullWidth hiddenLabel>
      <OutlinedInput
        inputRef={reference}
        data-testid="comment_field"
        id="comment_field"
        multiline
        minRows={1}
        placeholder={t("community.comment.placeholder")}
        className={classes.inputForm}
        color="secondary"
        value={commentText}
        onFocus={setCommentId}
        onChange={(e) => {
          setCommentText(e.target.value);
        }}
        startAdornment={
          <InputAdornment position="start" className={classes.startAdornment}>
            <SmallAvatar level={false} customSize="32px" onTrailHead={true} />
          </InputAdornment>
        }
        endAdornment={
          <IconButton
            data-testid="send_comment"
            id="send_comment"
            position="start"
            onClick={handleCommentSubmit}
          >
            <IconSend2 stroke={1.5} color="#6D6F71" />
          </IconButton>
        }
      />
    </FormControl>
  );
};

export default CommentTextField;

const useStyles = makeStyles({
  inputForm: {
    fontWeight: 400,
    padding: "0px",
    fontFamily: "Karla",
    fontSize: "16px",
    backgroundColor: "#fff",
    borderWidth: "2px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EAEAEA",
      borderWidth: "2px",
    },
  },
  startAdornment: {
    padding: "12px",
  },
});
