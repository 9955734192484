import React, { memo, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Drawer, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { withTranslation } from "react-i18next";
import SmallAvatar from "../../../components/generals/small-avatar";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  convertUnits,
  round,
  getPace,
  getMilesPace,
} from "../../../lib/units-helper";
import DashboardActivityDetails from "../dashboard-recent-activities/dashboard-activity-details";
import unitsHook from "../../../hooks/unitsHook";
import DashboardActivityShare from "../dashboard-recent-activities/dashboard-activity-share";
import history from "../../../lib/history-helper";
import {
  clearCurrentUserActivity,
  getUserActivity,
} from "../../../actions/user-plans-action";
import Reactions from "../../generals/reactions";
import NewActivityModal from "../../generals/new-activity-modal";
import useActivities from "../../../hooks/useActivities";

const styles = (theme) => ({
  drawerRoot: {
    backgroundColor: "#F9F9FB",
    maxHeight: "100%",
    height: "100%",
    maxWidth: "500px",
    minWidth: "500px",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "100vw",
      minWidth: "100vw",
    },
  },
  header: {
    display: "flex",
    backgroundColor: "#FFF",
    padding: "10px 16px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subtitle: {
    textAlign: "center",
    color: "#E16327",
    marginTop: "8px",
    cursor: "pointer",
  },
  bold: {
    fontWeight: "bold",
  },
  goBack: {
    cursor: "pointer",
  },
  activityContainer: {
    background: "#FFFFFF",
    borderRadius: 12,
    padding: 16,
    margin: "12px",
    verticalAlign: "top",
    whiteSpace: "break-spaces",
    cursor: "pointer",

    "&:last-child": {
      marginRight: 12,
    },
  },

  recentActivity: {
    fontWeight: "normal",
    fontSize: 14,
    marginBottom: 12,
    marginRight: "auto",
  },

  activityName: {
    textTransform: "uppercase",
    color: "#E16327",
    fontWeight: 900,
    fontSize: 18,
  },

  activityDate: {
    color: "#9E9E9E",
    fontWeight: "normal",
    fontSize: 14,
  },

  activityStats: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 24,
  },

  activityStat: {
    textAlign: "center",
  },

  activityStatValue: {
    fontWeight: 500,
    fontSize: 16,
  },

  acitivityStatLabel: {
    fontWeight: "normal",
    fontSize: 14,
  },

  activityMainCont: {
    display: "flex",
    alignItems: "start",
  },

  activityNameCont: {
    marginRight: "auto",
  },

  activityIcon: {
    marginLeft: 12,
    width: 26,
  },

  vDivider: {
    borderLeft: "1px solid #E0E0E0",
    height: 24,
  },

  titleCont: {
    display: "flex",
    alignItems: "center",
  },

  syncTracker: {
    fontWeight: 400,
    fontSize: 14,
    color: "#FF6327",
  },

  addButtonContainer: {
    marginLeft: "10px",
    marginRight: "10px",
    cursor: "pointer",
  },
  titleLabel: {
    margin: "12px 12px 4px 12px",
    textAlign: "right",
    color: "#E16327",
    fontSize: 14,
  },
  reactionMargin: {
    marginTop: 10,
  },
  addTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "16px 0px 10px 0",
    cursor: "pointer",
    "& img": {
      marginRight: "20px",
    },
  },
  manual: {
    color: "#E16327",
    fontWeight: "bold",
  },
});

const AllActivities = ({ classes, t, i18n }) => {
  const { language } = i18n;
  const momentLocale = moment().locale(language);
  const units = unitsHook();
  const { userPlans } = useSelector((state) => state);

  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(null);
  const [currentToShare, setCurrentToShare] = useState(null);

  const [activities] = useActivities();

  const dispatch = useDispatch();

  const handleShowForm = () => setShowForm((showForm) => !showForm);

  const convertDistance = (distance) => {
    const convertedDistance = convertUnits(distance, "kms", units, "distance");
    return convertedDistance;
  };

  const convertElevation = (elevation) => {
    const convertedElevation = round(
      convertUnits(elevation, "kms", units, "elevation"),
      1,
    );
    return convertedElevation;
  };

  const handleDate = (date) => {
    const momentLocale = moment().locale(language);
    return `${momentLocale
      .localeData()
      .weekdays(date)
      .substr(0, 3)}, ${momentLocale
      .localeData()
      .months(date)
      .substr(0, 3)} ${date.format("D")}`;
  };

  const openActivity = (activity) => {
    const { currentPlan } = userPlans || {};
    dispatch(getUserActivity(activity, currentPlan));
    const { device: type } = activity;
    setCurrent(type);
  };

  const handleOpenShare = (type) => setCurrentToShare(type);
  const goBack = () => history.goBack();

  const sortActivities = (activitiesArray) => {
    const sorted = activitiesArray.sort((a, b) => {
      return moment(b.start_date).unix() - moment(a.start_date).unix();
    });

    return sorted || [];
  };

  const checkReactions = (activityDate) => {
    const { currentPlan } = userPlans || {};
    const { currentTraining } = currentPlan || {};
    const { days = [] } = currentTraining || {};

    const result = days.find((item) => {
      if (item) {
        const difference = moment(item[0].plannedDate, "DD/MM/YYYY").diff(
          activityDate,
          "days",
        );

        if (Math.floor(difference) === 0) return item;
      }
    });

    return result && result.length ? result[0].reaction : [];
  };

  useEffect(() => {
    return () => {
      dispatch(clearCurrentUserActivity());
    };
  }, []);

  return (
    <>
      <Drawer
        open={true}
        anchor="right"
        onClose={null}
        classes={{
          paper: classes.drawerRoot,
        }}
      >
        <div className={classes.header}>
          <div style={{ position: "relative" }}>
            <SmallAvatar onTrailHead={false} />
          </div>

          <div>
            <Typography variant="body1" className={classes.bold}>
              {t("Your activities").toUpperCase()}
            </Typography>
          </div>

          <div onClick={goBack} className={classes.goBack}>
            <Close />
          </div>
        </div>

        <div className={classes.addTitle} onClick={handleShowForm}>
          <img src={`${process.env.PUBLIC_URL}/orange_plus.svg`}></img>
          <Typography className={classes.manual} variant="body1">
            {t("Add a manual activity")}
          </Typography>
        </div>

        <div>
          {(sortActivities(activities) || []).map((activity = {}, index) => {
            const name = activity.name
              ? activity.name
              : activity.activityName
              ? activity.activityName
              : "";

            const date = moment(
              activity.start_date_local
                ? activity.start_date_local
                : activity.start_date,
            );

            const time = activity.moving_time
              ? activity.moving_time
              : activity.elapsed_time;

            const pace =
              units == "kms"
                ? getPace(time, activity.distance)
                : getMilesPace(time, activity.distance);

            const h = time ? Math.floor(time / 3600) : 0;
            const m = time ? Math.floor((time % 3600) / 60) : 0;
            const timeStr = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;

            let icon = "timer_default_icon";
            const activityType = activity.type
              ? activity.type
              : activity.activityType;
            if (
              activityType &&
              activityType.toLowerCase().includes("run") &&
              !activityType.toLowerCase().includes("transition")
            ) {
              icon = "icon_activity_run";
            }
            if (
              activityType &&
              (activityType.toLowerCase().includes("ride") ||
                activityType.toLowerCase().includes("bik") ||
                activityType.toLowerCase().includes("cycl")) &&
              !activityType.toLowerCase().includes("transition")
            ) {
              icon = "icon_activity_bike";
            }
            if (
              activityType &&
              activityType.toLowerCase().includes("swim") &&
              !activityType.toLowerCase().includes("transition")
            ) {
              icon = "icon_activity_swim";
            }
            if (
              activityType &&
              (activityType.toLowerCase().includes("walk") ||
                activityType.toLowerCase().includes("hik")) &&
              !activityType.toLowerCase().includes("transition")
            ) {
              icon = "icon_activity_hike";
            }

            if (
              activityType &&
              (activityType.toLowerCase().includes("crossfit") ||
                activityType.toLowerCase().includes("weight") ||
                activityType.toLowerCase().includes("yoga") ||
                activityType.toLowerCase().includes("strength") ||
                activityType.toLowerCase().includes("fitness")) &&
              !activityType.toLowerCase().includes("transition")
            ) {
              icon = "icon_activity_strength";
            }

            return (
              <div
                className={classes.activityContainer}
                key={index}
                onClick={() => openActivity(activity)}
              >
                <div className={classes.titleCont}>
                  <Typography
                    variant="body1"
                    className={classes.recentActivity}
                    style={{
                      marginBottom: 12,
                    }}
                  >
                    {t("Recent Activity")}
                  </Typography>

                  <Typography
                    variant="body1"
                    className={classes.syncTracker}
                    style={{
                      marginBottom: 12,
                    }}
                  >
                    {t("See details", { ns: "dashboard" })}
                  </Typography>
                </div>

                <div className={classes.activityMainCont}>
                  <div className={classes.activityNameCont}>
                    <Typography
                      variant="body1"
                      className={classes.activityName}
                    >
                      {name}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.activityDate}
                    >
                      {date.isValid() ? handleDate(date) : handleDate(moment())}
                    </Typography>
                  </div>

                  <img
                    src={`${process.env.PUBLIC_URL}/${icon}.svg`}
                    alt=""
                    className={classes.activityIcon}
                  />
                </div>

                <div className={classes.activityStats}>
                  <div className={classes.activityStat}>
                    <Typography
                      variant="body1"
                      className={classes.activityStatValue}
                    >
                      {`${pace.minutes}:${(pace.seconds < 10 ? "0" : "") +
                        pace.seconds}`}
                      /{units === "miles" ? `${t("mile")}` : `${t("km")}`}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.activityStatLabel}
                    >
                      {t("avg pace")}
                    </Typography>
                  </div>

                  <div className={classes.vDivider}></div>

                  <div className={classes.activityStat}>
                    <Typography
                      variant="body1"
                      className={classes.activityStatValue}
                    >
                      {t("valueUnit", {
                        value: round(
                          convertDistance(activity.distance) / 1000,
                          1,
                        ),
                        unit: units === "miles" ? ` ${t("miles")}` : "km",
                      })}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.activityStatLabel}
                    >
                      {t("distance")}
                    </Typography>
                  </div>

                  <div className={classes.vDivider}></div>

                  <div className={classes.activityStat}>
                    <Typography
                      variant="body1"
                      className={classes.activityStatValue}
                    >
                      {t("valueUnit", {
                        value: convertElevation(activity.total_elevation_gain),
                        unit: units === "miles" ? ` ${t("feet")}` : "m",
                      })}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.activityStatLabel}
                    >
                      {t("elevation")}
                    </Typography>
                  </div>

                  <div className={classes.vDivider}></div>

                  <div className={classes.activityStat}>
                    <Typography
                      variant="body1"
                      className={classes.activityStatValue}
                    >
                      {timeStr}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.activityStatLabel}
                    >
                      {t("time")}
                    </Typography>
                  </div>
                </div>
                <Reactions
                  reactions={checkReactions(date) || []}
                  className={classes.reactionMargin}
                />
              </div>
            );
          })}
        </div>
      </Drawer>

      <NewActivityModal open={showForm} onClose={handleShowForm} />

      {current && (
        <DashboardActivityDetails
          isOpen={current}
          onClose={() => {
            setCurrent(null);
          }}
          onOpenShare={handleOpenShare}
          activities={activities}
        ></DashboardActivityDetails>
      )}

      {currentToShare && (
        <DashboardActivityShare
          isOpen={currentToShare}
          onClose={(sharing = false) => {
            setCurrentToShare(null);
          }}
        ></DashboardActivityShare>
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("dashboard")(memo(AllActivities)),
);
