export default {
  title: "title",
  titleLower: "title_lower",
  id: "id",
  selectedPlan: "selectedPlan",
  durationInWeeks: "durationInWeeks",
  distance: "distance",
  surface: "surface",
  trainPerWeek: "trainPerWeek",
  raceCount: "raceCount",
  skillToImprove: "skillToImprove",
};
