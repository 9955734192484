import React, { memo, useEffect } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import MobileShare from "../../../../../components/common/mobile-share";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { Capacitor } from "@capacitor/core";
import { setSuccessMessage } from "../../../../../actions/user-plans-action";
import { useDispatch } from "react-redux";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing";
import { convertUnits } from "../../../../../lib/units-helper";
import objectToGetParams from "../../../../../lib/objectToGetParams";
import {
  newPost,
  cleanNewPost,
} from "../../../../../actions/trail-head-action";
import { useSelector } from "react-redux";
import unitsHook from "../../../../../hooks/unitsHook";
import history from "../../../../../lib/history-helper";
import { Clipboard } from "@capacitor/clipboard";

const styles = (theme) => ({
  noPadding: {
    padding: 16,
  },
});

const ShareBadge = ({
  classes,
  t,
  yourEntry = {},
  open,
  onCloseMethod = () => {},
  i18n,
  totals = {},
}) => {
  const dispatch = useDispatch();
  const shareType = "finished";
  const units = unitsHook();

  const { trailHead } = useSelector((state) => state);
  const { selectedAthlete } = trailHead;
  const { cognito_user_name } = selectedAthlete || {};

  useEffect(() => {
    dispatch(cleanNewPost());
  }, []);

  const challengeToShare = yourEntry;
  const isMobileOrTablet = () => {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
  };

  const handleShareFinishedS1 = async (type) => {
    const { language } = i18n;
    const { currentPublicGroup } = trailHead;

    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const done = `${t("valueUnit", {
      value: totals
        ? Math.round(
            convertUnits(totals.distance / 1000, "kms", units, "distance"),
          )
        : 0,
      unit: units === "miles" ? ` ${t("miles")}` : "km",
    })} - ${t("valueUnit", {
      value: totals
        ? new Date(totals.time * 1000).toISOString().substr(11, 5)
        : 0,
      unit: "h",
    })} - ${t("valueUnit", {
      value: totals
        ? Math.round(convertUnits(totals.elevation, "kms", units, "elevation"))
        : 0,
      unit: units === "miles" ? ` ${t("feet")}` : "m",
    })}`;

    switch (type) {
      case "instagram":
        dispatch(
          setSuccessMessage(
            t(
              "Challenge message copied to clipboard. Paste it on Instagram to complete your post",
            ),
          ),
        );
        await Clipboard.write({
          string:
            t("share finished title", {
              name: cognito_user_name,
              title: yourEntry.challengeData.name[languageDetector]
                ? yourEntry.challengeData.name[languageDetector]
                : yourEntry.challengeData.name["en"],
            }) +
            ": " +
            t("share finished text", {
              description: yourEntry.challengeData.description[languageDetector]
                ? yourEntry.challengeData.description[languageDetector]
                : yourEntry.challengeData.description["en"],
              done,
            }) +
            " - " +
            "https://app.vert.run/challenges",
        });
        setTimeout(() => {
          SocialSharing.shareViaInstagram(
            t("share finished title", {
              name: cognito_user_name,
              title: yourEntry.challengeData.name[languageDetector]
                ? yourEntry.challengeData.name[languageDetector]
                : yourEntry.challengeData.name["en"],
            }) +
              ": " +
              t("share finished text", {
                description: yourEntry.challengeData.description[
                  languageDetector
                ]
                  ? yourEntry.challengeData.description[languageDetector]
                  : yourEntry.challengeData.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
            [yourEntry.challengeData.badge],
          );
        }, 1000);
        onCloseMethod();
        break;
      case "email":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaEmail(
            t("share finished text", {
              description: yourEntry.challengeData.description[languageDetector]
                ? yourEntry.challengeData.description[languageDetector]
                : yourEntry.challengeData.description["en"],
              done,
            }),
            t("share finished title", {
              name: cognito_user_name,
              title: yourEntry.challengeData.name[languageDetector]
                ? yourEntry.challengeData.name[languageDetector]
                : yourEntry.challengeData.name["en"],
            }),
            null,
            null,
            null,
            [yourEntry.challengeData.badge],
          );
        } else {
          window.open(
            "mailto:" +
              objectToGetParams({
                subject: t("share finished title", {
                  name: cognito_user_name,
                  title: yourEntry.challengeData.name[languageDetector]
                    ? yourEntry.challengeData.name[languageDetector]
                    : yourEntry.challengeData.name["en"],
                }),
                body:
                  t("share finished text", {
                    description: yourEntry.challengeData.description[
                      languageDetector
                    ]
                      ? yourEntry.challengeData.description[languageDetector]
                      : yourEntry.challengeData.description["en"],
                    done,
                  }) +
                  " - " +
                  "https://app.vert.run/challenges",
              }),
          );
        }
        onCloseMethod();
        break;

      case "facebook":
        dispatch(
          setSuccessMessage(
            t(
              "Challenge message copied to clipboard. Paste it on Facebook to complete your post",
            ),
          ),
        );
        if (!Capacitor.isNativePlatform()) {
          navigator.clipboard.writeText(
            t("share finished title", {
              name: cognito_user_name,
              title: yourEntry.challengeData.name[languageDetector]
                ? yourEntry.challengeData.name[languageDetector]
                : yourEntry.challengeData.name["en"],
            }) +
              ": " +
              t("share finished text", {
                description: yourEntry.challengeData.description[
                  languageDetector
                ]
                  ? yourEntry.challengeData.description[languageDetector]
                  : yourEntry.challengeData.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
          );
        } else {
          await Clipboard.write({
            string:
              t("share finished title", {
                name: cognito_user_name,
                title: yourEntry.challengeData.name[languageDetector]
                  ? yourEntry.challengeData.name[languageDetector]
                  : yourEntry.challengeData.name["en"],
              }) +
              ": " +
              t("share finished text", {
                description: yourEntry.challengeData.description[
                  languageDetector
                ]
                  ? yourEntry.challengeData.description[languageDetector]
                  : yourEntry.challengeData.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
          });
        }
        setTimeout(() => {
          if (Capacitor.isNativePlatform()) {
            SocialSharing.shareViaFacebook(
              t("share finished title", {
                name: cognito_user_name,
                title: yourEntry.challengeData.name[languageDetector]
                  ? yourEntry.challengeData.name[languageDetector]
                  : yourEntry.challengeData.name["en"],
              }) +
                ": " +
                t("share finished text", {
                  description: yourEntry.challengeData.description[
                    languageDetector
                  ]
                    ? yourEntry.challengeData.description[languageDetector]
                    : yourEntry.challengeData.description["en"],
                  done,
                }) +
                " - " +
                "https://app.vert.run/challenges",
              [yourEntry.challengeData.badge],
            );
          } else {
            window.open(
              "http://www.facebook.com/sharer.php?u=" +
                encodeURIComponent(yourEntry.challengeData.badge),
              "sharer",
              "toolbar=0,status=0,width=626,height=436",
            );
          }
        }, 1000);
        onCloseMethod();
        break;

      case "whatsapp":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaWhatsApp(
            t("share finished title", {
              name: cognito_user_name,
              title: yourEntry.challengeData.name[languageDetector]
                ? yourEntry.challengeData.name[languageDetector]
                : yourEntry.challengeData.name["en"],
            }) +
              ": " +
              t("share finished text", {
                description: yourEntry.challengeData.description[
                  languageDetector
                ]
                  ? yourEntry.challengeData.description[languageDetector]
                  : yourEntry.challengeData.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
            Capacitor.getPlatform() === "ios"
              ? []
              : [yourEntry.challengeData.badge],
          );
        } else {
          window.open(
            "https://" +
              (isMobileOrTablet() ? "api" : "web") +
              ".whatsapp.com/send" +
              objectToGetParams({
                text:
                  t("share finished title", {
                    name: cognito_user_name,
                    title: yourEntry.challengeData.name[languageDetector]
                      ? yourEntry.challengeData.name[languageDetector]
                      : yourEntry.challengeData.name["en"],
                  }) +
                  ": " +
                  t("share finished text", {
                    description: yourEntry.challengeData.description[
                      languageDetector
                    ]
                      ? yourEntry.challengeData.description[languageDetector]
                      : yourEntry.challengeData.description["en"],
                    done,
                  }) +
                  " - " +
                  "https://app.vert.run/challenges",
              }),
          );
        }
        onCloseMethod();
        break;

      case "twitter":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaTwitter(
            t("share finished title", {
              name: cognito_user_name,
              title: yourEntry.challengeData.name[languageDetector]
                ? yourEntry.challengeData.name[languageDetector]
                : yourEntry.challengeData.name["en"],
            }) +
              ": " +
              t("share finished text", {
                description: yourEntry.challengeData.description[
                  languageDetector
                ]
                  ? yourEntry.challengeData.description[languageDetector]
                  : yourEntry.challengeData.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
            [yourEntry.challengeData.badge],
          );
        } else {
          window.open(
            "http://twitter.com/share?" +
              "text=" +
              encodeURIComponent(
                t("share finished title", {
                  name: cognito_user_name,
                  title: yourEntry.challengeData.name[languageDetector]
                    ? yourEntry.challengeData.name[languageDetector]
                    : yourEntry.challengeData.name["en"],
                }) +
                  ": " +
                  t("share finished text", {
                    description: yourEntry.challengeData.description[
                      languageDetector
                    ]
                      ? yourEntry.challengeData.description[languageDetector]
                      : yourEntry.challengeData.description["en"],
                    done,
                  }) +
                  " - " +
                  "https://app.vert.run/challenges",
              ),
          );
        }
        onCloseMethod();
        break;

      case "trailhead":
        dispatch(
          newPost(
            "",
            "",
            null,
            null,

            yourEntry,
          ),
        );
        history.push(
          currentPublicGroup
            ? `/trailhead/group/${currentPublicGroup.id}`
            : `/trailhead`,
        );
        onCloseMethod();
        break;

      default:
        break;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseMethod}
      maxWidth="md"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogContent id="signupdialog" className={classes.noPadding}>
        <MobileShare
          title={
            shareType === "invite"
              ? t("Invite with")
              : shareType === "finished"
              ? t("Share your result")
              : t("Share on")
          }
          onClose={onCloseMethod}
          onShare={(type) => handleShareFinishedS1(type)}
          trailhead={trailHead}
          challengeToShare={challengeToShare || {}}
          units={units}
        ></MobileShare>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation("challenges")(
  withWidth()(withStyles(styles, { withTheme: true })(memo(ShareBadge))),
);
