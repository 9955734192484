const getSubscriptionPlanName = (planId) => {
  if (planId.includes("premium")) {
    return "Premium";
  }
  if (
    planId.includes("explore-5") ||
    planId.includes("explore-1") ||
    planId.includes("explore-1012") ||
    planId.includes("explore-102") ||
    planId.includes("explore-103") ||
    planId.includes("explore-106") ||
    planId.includes("explore-12") ||
    planId.includes("explore-3") ||
    planId.includes("explore-6") ||
    planId.includes("explore-o") ||
    planId.includes("explore-14")
  ) {
    return "Full training access + Coaching";
  }
  if (planId.includes("explore-101")) {
    return "Full training access + Coaching";
  }
  return "";
};

const checkSubscription = (sub, latestSubscriptionFinalizedTime) => {
  const { latest, latestExpired, alreadyLoadedOnce } = sub || {};
  if (latest) {
    const status = /active|in_trial|non_renewing/.test(latest.status);
    if (
      !latest &&
      !status &&
      (latestExpired || alreadyLoadedOnce) &&
      !latestSubscriptionFinalizedTime
    ) {
      return false;
    }
    return true;
  }

  return false;
};

export { getSubscriptionPlanName, checkSubscription };
