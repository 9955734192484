import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { palette } from "../../../theme/palette";

const ListItemWithContent = ({ leftContent, rightContent }) => {
  const classes = useStyles();

  return (
    <List className={classes.listRoot}>
      <ListItem
        ContainerProps={{
          className: classes.listItemContainerRoot,
        }}
        className={classes.listItemRoot}
      >
        {leftContent && (
          <ListItemText
            primaryTypographyProps={{
              className: classes.primary,
            }}
            primary={leftContent}
          />
        )}
        {rightContent && (
          <ListItemSecondaryAction className={classes.listSecondaryActionRoot}>
            {typeof rightContent === "string" ? (
              <ListItemText
                secondaryTypographyProps={{
                  className: classes.secondary,
                }}
                secondary={rightContent}
              />
            ) : (
              rightContent
            )}
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </List>
  );
};

export default ListItemWithContent;

const useStyles = makeStyles(() => ({
  listRoot: {
    listStyle: "none",
    padding: 0,
  },
  listItemContainerRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: 0,
  },
  listItemRoot: {
    marginBlock: 1.5,
    padding: 0,
    width: "50%",
  },
  listSecondaryActionRoot: {
    position: "relative",
    display: "flex",
    top: 0,
    right: 0,
    transform: "none",
    textWrap: "nowrap",
    alignItems: "center",
    width: "50%",
    justifyContent: "end",
    paddingRight: 10,
  },
  primary: {
    display: "flex",
    gap: 8,
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: 16,
    color: palette.black,
  },
  secondary: {
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 400,
    color: palette.darkGray,
  },
}));
