import React, { useEffect } from "react";
import PropTypes from "prop-types";

import {
  Box,
  Typography,
  makeStyles,
  Grid,
  Divider,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { palette } from "../../../theme/palette";
import Collapse from "./Collapse";
import DateRange from "./DateRange";
import RadioOptionGroup from "./RadioOptionGroup";
import CustomButton from "./CustomButton";
import { useTranslation } from "react-i18next";
import WorkoutCollapse from "./WorkoutCollapse";
import StrengthCollapse from "./StrengthCollapse";
import useWorkout from "../../../hooks/useWorkout";
import CheckIcon from "@material-ui/icons/Check";
import CardNeedAPlan from "./CardNeedAPlan";
import CardInfo from "./CardInfo";
import { isEmptyHtml } from "../../../lib/common";

const TrainingDay = ({ allow = true, workoutSelected = null }) => {
  const classes = useStyles();
  const { t } = useTranslation("training");
  const {
    videoUrl,
    description,
    fatigue,
    rpe,
    isDone,
    level,
    parametrizedWorkout,
    workoutToday,
    hasNextDay,
    hasPreviousDay,
    setWorkoutToday,
    setIsDone,
    handleChangeFatigue,
    handleChangeRpe,
    workoutIsDoneChange,
    loadingmarksAsDone,
  } = useWorkout();

  const eventButtonMark = async () => {
    const isWorkoutDoneChange = await workoutIsDoneChange(
      workoutToday,
      rpe,
      fatigue,
      !isDone,
    );

    if (isWorkoutDoneChange) {
      setIsDone(!isDone);
    } else {
      setIsDone(isDone);
    }
  };

  // that is in the case with have a workout selected
  useEffect(() => {
    if (workoutSelected) setWorkoutToday(workoutSelected);
  }, [workoutSelected]);

  if (!allow) {
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Box marginTop={"10px"}>
            <DateRange disabled={true} />
          </Box>
        </Grid>

        <Paper elevation={0}>
          <Grid item xs={12}>
            <CardNeedAPlan />

            <Box className={classes.marginY}>
              <WorkoutCollapse emptyState={true} />
            </Box>

            <Box className={classes.marginY}>
              <StrengthCollapse videoUrl={videoUrl} />
            </Box>

            <Box className={classes.marginY}>
              <Collapse title={t("training.description")} disabled={true} />
            </Box>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 36 }}>
            <Box className={classes.marginY}>
              <Typography
                className={classes.sectionTitle}
                style={{ color: palette.deactivated }}
              >
                {t("training.day.fatigue")}
              </Typography>
              <Typography
                className={classes.sectionSubtitle}
                style={{ color: palette.deactivated }}
              >
                {t("training.day.fatigue.question")}
              </Typography>
            </Box>

            <RadioOptionGroup
              disabled={true}
              name="fatigue"
              selectedValue={fatigue.toString()}
              onChange={handleChangeFatigue}
              label={{
                start: t("training.day.fatigue.start"),
                end: t("training.day.fatigue.end"),
              }}
              values={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
                return { value: item.toString(), label: item.toString() };
              })}
            />
          </Grid>

          <Grid item xs={12} style={{ margin: "20px 0" }}>
            <Divider variant="fullWidth" />
          </Grid>

          <Grid item xs={12}>
            <Box className={classes.marginY}>
              <Typography
                className={classes.sectionTitle}
                style={{ color: palette.deactivated }}
              >
                {t("training.day.rpe.title")}
              </Typography>
              <Typography
                className={classes.sectionSubtitle}
                style={{ color: palette.deactivated }}
              >
                {t("training.day.rpe.question")}
              </Typography>
            </Box>
            <RadioOptionGroup
              disabled={true}
              name="rpe"
              selectedValue={rpe.toString()}
              onChange={handleChangeRpe}
              label={{
                start: t("training.day.rpe.start"),
                end: t("training.day.rpe.end"),
              }}
              values={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
                return { value: item.toString(), label: item.toString() };
              })}
            />
          </Grid>

          <Grid item xs={12} style={{ margin: "20px 0" }}>
            <Divider variant="fullWidth" />
          </Grid>

          <Grid item xs={12}>
            <Box py={2}>
              <CustomButton
                color={"default"}
                typeStyle={"disabled"}
                variant="contained"
                size="large"
                fullWidth
                disabled={true}
                style={{ position: "relative", minHeight: 50 }}
              >
                {t("training.day.buttonMarkAsDone")}
              </CustomButton>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.root}>
      {!workoutSelected && (
        <Grid item xs={12}>
          <Box marginTop={"10px"}>
            <DateRange
              disablePrevious={!hasPreviousDay}
              disableNext={!hasNextDay}
            />
          </Box>
        </Grid>
      )}

      <Paper elevation={0}>
        <Grid item xs={12}>
          {!workoutToday ? (
            <CardInfo
              title={t("training.recoveryDay.title")}
              description={t("training.recoveryDay.description")}
            />
          ) : (
            <Box className={classes.marginY}>
              <WorkoutCollapse
                workout={workoutToday}
                level={level}
                parametrizedWorkout={parametrizedWorkout}
              />
            </Box>
          )}

          {videoUrl && videoUrl.length > 0 && (
            <Box className={classes.marginY}>
              <StrengthCollapse videoUrl={videoUrl} />
            </Box>
          )}

          {!isEmptyHtml(description) && (
            <Box className={classes.marginY}>
              <Collapse
                title={t("training.description")}
                disabled={!description}
              >
                <Typography
                  dangerouslySetInnerHTML={description}
                  style={{
                    style: { fontSize: 16 },
                  }}
                />
              </Collapse>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} style={{ marginTop: 36 }}>
          <Box className={classes.marginY}>
            <Typography className={classes.sectionTitle}>
              {t("training.day.fatigue")}
            </Typography>
            <Typography className={classes.sectionSubtitle}>
              {t("training.day.fatigue.question")}
            </Typography>
          </Box>

          <RadioOptionGroup
            disabled={isDone || !workoutToday || loadingmarksAsDone}
            name="fatigue"
            selectedValue={fatigue.toString()}
            onChange={handleChangeFatigue}
            label={{
              start: t("training.day.fatigue.start"),
              end: t("training.day.fatigue.end"),
            }}
            values={[
              { value: "0", label: "0" },
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
              { value: "4", label: "4" },
              { value: "5", label: "5" },
              { value: "6", label: "6" },
              { value: "7", label: "7" },
              { value: "8", label: "8" },
              { value: "9", label: "9" },
              { value: "10", label: "10" },
            ]}
          />
        </Grid>

        <Grid item xs={12} style={{ margin: "20px 0" }}>
          <Divider variant="fullWidth" />
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.marginY}>
            <Typography className={classes.sectionTitle}>
              {t("training.day.rpe")}
            </Typography>
            <Typography className={classes.sectionSubtitle}>
              {t("training.day.rpe.question")}
            </Typography>
          </Box>
          <RadioOptionGroup
            disabled={isDone || !workoutToday || loadingmarksAsDone}
            name="rpe"
            selectedValue={rpe.toString()}
            onChange={handleChangeRpe}
            label={{
              start: t("training.day.rpe.start"),
              end: t("training.day.rpe.end"),
            }}
            values={[
              { value: "0", label: "0" },
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
              { value: "4", label: "4" },
              { value: "5", label: "5" },
              { value: "6", label: "6" },
              { value: "7", label: "7" },
              { value: "8", label: "8" },
              { value: "9", label: "9" },
              { value: "10", label: "10" },
            ]}
          />
        </Grid>

        <Grid item xs={12} style={{ margin: "20px 0" }}>
          <Box py={2}>
            <CustomButton
              disabled={!workoutToday || loadingmarksAsDone}
              color={!isDone ? "secondary" : "default"}
              typeStyle={!isDone ? "primary" : "secondary"}
              variant="contained"
              size="large"
              fullWidth
              style={{ position: "relative", minHeight: 50 }}
              onClick={(e) => {
                e.preventDefault();
                eventButtonMark();
              }}
            >
              {loadingmarksAsDone ? (
                <CircularProgress />
              ) : (
                <>
                  <span
                    id="textIsDoneRef"
                    style={{ position: "absolute", opacity: !isDone ? 1 : 0 }}
                  >
                    {t("training.day.buttonMarkAsDone")}
                  </span>
                  <span
                    id="textUnmarkRef"
                    style={{ position: "absolute", opacity: isDone ? 1 : 0 }}
                  >
                    {t("training.day.buttonUnmarkAsDone")}
                  </span>
                  <span
                    id="checkIconRef"
                    style={{ opacity: 0, transform: "translate(0px, 4px)" }}
                  >
                    <CheckIcon />
                  </span>
                </>
              )}
            </CustomButton>
          </Box>
        </Grid>
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles({
  root: {
    justifyContent: "center",
  },
  sectionTitle: {
    fontFamily: "Oswald",
    textTransform: "uppercase",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 1,
    lineHeight: "22.5px",
    textAlign: "left",
    color: palette.black,
  },
  sectionSubtitle: {
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",
    color: palette.darkGray,
  },
  marginY: {
    marginTop: 15,
    marginBottom: 15,
  },
});

TrainingDay.defaultProps = {
  allow: true,
};

TrainingDay.propTypes = {
  allow: PropTypes.bool,
  workoutSelected: PropTypes.object,
};

export default TrainingDay;
