import React from "react";
import { SvgIcon } from "@material-ui/core";
import { palette } from "../../theme/palette";

const SearchIcon = ({ width = 24, height = 24, activated = false }) => (
  <>
    <SvgIcon>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill={activated ? palette.primary : "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 21L16.657 16.657M16.657 16.657C17.3999 15.9142 17.9892 15.0322 18.3913 14.0616C18.7933 13.091 19.0002 12.0507 19.0002 11.0001C19.0002 9.94952 18.7933 8.90922 18.3913 7.9386C17.9892 6.96798 17.3999 6.08606 16.657 5.34318C15.9142 4.6003 15.0322 4.01102 14.0616 3.60897C13.091 3.20693 12.0507 3 11.0001 3C9.94952 3 8.90922 3.20693 7.9386 3.60897C6.96798 4.01102 6.08606 4.6003 5.34318 5.34318C3.84287 6.84349 3 8.87835 3 11.0001C3 13.1219 3.84287 15.1567 5.34318 16.657C6.84349 18.1574 8.87835 19.0002 11.0001 19.0002C13.1219 19.0002 15.1567 18.1574 16.657 16.657Z"
          stroke={activated ? palette.primary : palette.black}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  </>
);
export default SearchIcon;
