import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { palette } from "../../../theme/palette";

const RadioOptionGroup = ({
  disabled = false,
  name = "option",
  label = { start: "", end: "" },
  values = [{ label: "Option 1", value: 1 }],
  selectedValue,
  onChange,
}) => {
  const classes = useStyles();
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <RadioGroup
          name={name}
          aria-label={name}
          className={classes.radioGroup}
          value={selectedValue.toString()}
          onChange={handleChange}
          row
        >
          {values.map((option) => (
            <FormControlLabel
              disabled={disabled}
              control={
                <Radio
                  disabled={disabled}
                  color="primary"
                  size="small"
                  className={classes.radioSpace}
                />
              }
              classes={{ label: classes.radioLabel }}
              className={classes.radioFormControl}
              key={option.value}
              value={option.value.toString()}
              label={option.label}
              labelPlacement="bottom"
            />
          ))}
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop="8px"
        >
          <Box display="flex" flexDirection="column" alignItems="start">
            <ArrowDropUpIcon />
            <Typography className={classes.radioStart}>
              {label.start}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="end">
            <ArrowDropUpIcon />
            <Typography className={classes.radioEnd}>{label.end}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  radioGroup: {
    justifyContent: "space-between",
  },
  radioFormControl: {
    margin: 0,
  },
  radioLabel: {
    fontFamily: "Oswald",
    fontSize: 18,
    fontWeight: 500,
    color: palette.black,
  },
  radioSpace: {
    padding: 3,
    color: palette.lightGray,
  },
  radioStart: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: 14,
    color: palette.darkGray,
    textAlign: "left",
  },
  radioEnd: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: 14,
    color: palette.darkGray,
    textAlign: "right",
  },
});

RadioOptionGroup.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.object,
  values: PropTypes.arrayOf(PropTypes.object),
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default RadioOptionGroup;
