import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import { withTranslation } from 'react-i18next';

import PlanFieldTransition from './plan-field-transition';

const styles = theme => ({
    chip: {
        margin: theme.spacing.unit * 0.5,
        minWidth: 130,
        maxWidth: 130
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
});

class PlanFieldChipSelect extends Component {
    render() {
        const { classes, selectedValues, id, onSelect, xs, sm, md, lg, xl, items, t } = this.props;
        return (
            <PlanFieldTransition>
                <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <div className={classes.container}>
                        {items.map((item, index) => {
                            const isSelectd = (selectedValues && selectedValues.indexOf(item.value) > -1);
                            return <Chip
                                key={index}
                                label={t(item.text)}
                                clickable
                                className={classes.chip}
                                variant={isSelectd ? "outlined" : "default"}
                                color="primary"
                                deleteIcon={isSelectd ? <DoneIcon /> : <div></div>}
                                onClick={(event) => onSelect(id, item.value)}
                                onDelete={(event) => onSelect(id, item.value)}
                            />
                        })}
                    </div>
                </Grid>
            </PlanFieldTransition>
        );
    };
}

PlanFieldChipSelect.propTypes = {
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    id: PropTypes.string.isRequired,
    selectedValues: PropTypes.array,
    items: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    t: PropTypes.func.isRequired
}

export default withTranslation('constants')(withStyles(styles, { withTheme: true })(PlanFieldChipSelect));