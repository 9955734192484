import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { I18n } from "aws-amplify";

import Languages from "../../constants/languages";
import MenuList from "../common/menu-list";
import MenuListItem from "../common/menu-list-item";

const styles = (theme) => ({
  container: {
    //alignSelf: "flex-end",
  },
  paper: {
    background: theme.palette.background.inputText,
  },
  button: {
    // color: theme.palette.common.black,
    padding: "6px 8px",
    fontWeight: "normal",
    [theme.breakpoints.down("sm")]: {
      // color: theme.palette.common.white
    },
  },
  popper: {
    zIndex: 10001,
  },
});

class LanguageMenu extends Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState({
      ...this.state,
      open: !this.state.open,
    });
  };

  handleClose = async (id, event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    if (id) {
      try {
        const { i18n } = this.props;
        i18n.changeLanguage(id);
        I18n.setLanguage(id);
      } catch (e) {}
    }

    this.setState({
      ...this.state,
      open: false,
    });
  };

  render() {
    const { classes, i18n } = this.props;
    const { open } = this.state;
    const { language } = i18n;

    return (
      <div className={classes.container}>
        <Button
          className={classes.button}
          buttonRef={(node) => {
            this.anchorEl = node;
          }}
          aria-owns={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          {language === "en" || language === "es" || language === "fr"
            ? language
            : "en"}
          {/* {(language && language.indexOf('-') >= 0) ? language.split('-')[0] : language} */}
          <KeyboardArrowDownIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          className={classes.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener
                  onClickAway={this.handleClose.bind(this, null)}
                >
                  <MenuList>
                    <MenuListItem onClick={this.handleClose.bind(this, "en")}>
                      {Languages.Items.en}
                    </MenuListItem>
                    <MenuListItem onClick={this.handleClose.bind(this, "es")}>
                      {Languages.Items.es}
                    </MenuListItem>
                    <MenuListItem onClick={this.handleClose.bind(this, "fr")}>
                      {Languages.Items.fr}
                    </MenuListItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

LanguageMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func,
    language: PropTypes.string,
  }),
};

export default withTranslation("layout")(
  withStyles(styles, { withTheme: true })(LanguageMenu),
);
