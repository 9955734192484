import React from "react";
import { Typography } from "@material-ui/core";

const HighlightText = ({ text, fontSize = 18, color }) => {
  return (
    <Typography component={"span"} style={{ color, fontWeight: 700, fontSize }}>
      {text}
    </Typography>
  );
};

export default HighlightText;
