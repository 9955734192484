import SubcategoriesService from "../services/subcategories-service";
import ActionTypes from "../constants/action-types";

const getSubcategories = (getParams) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SUBCATEGORIES_GET,
    });
    try {
      await dispatch({ type: ActionTypes.SUBCATEGORIES_GET_PENDING });
      const result = await SubcategoriesService.getSubcategories(getParams);
      await dispatch({
        type: ActionTypes.SUBCATEGORIES_GET_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      await dispatch({
        type: ActionTypes.SUBCATEGORIES_GET_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getSampleWeek = (getParams) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SUBCATEGORIES_GET_SAMPLE,
    });
    try {
      await dispatch({ type: ActionTypes.SUBCATEGORIES_GET_SAMPLE_PENDING });
      const result = await SubcategoriesService.getSampleWeek(getParams);
      await dispatch({
        type: ActionTypes.SUBCATEGORIES_GET_SAMPLE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      await dispatch({
        type: ActionTypes.SUBCATEGORIES_GET_SAMPLE_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const PostContactData = (data) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.POST_CONTACT_FORM_PENDING });
    try {
      const result = await SubcategoriesService.postContact(data);
      dispatch({
        type: ActionTypes.POST_CONTACT_FORM_FULLFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.POST_CONTACT_FORM_REJECTED,
        payload: exp,
      });
    }
  };
};

const clearSubcategories = () => {
  return { type: ActionTypes.SUBCATEGORIES_CLEAR };
};

const clearFormData = () => {
  return { type: ActionTypes.CLEAR_CONTACT_FORM };
};

const postReserveSpot = (data) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.POST_RESERVE_SPOT_PENDING });
    try {
      const result = await SubcategoriesService.postReserveSpot({
        ...data,
      });
      dispatch({
        type: ActionTypes.POST_RESERVE_SPOT_FULLFILLED,
        payload: result,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.POST_RESERVE_SPOT_REJECTED,
        payload: error,
      });
    }
  };
};

const clearPost = () => ({ type: ActionTypes.CLEAR_RESERVE_SPOT });

export {
  getSubcategories,
  clearSubcategories,
  getSampleWeek,
  PostContactData,
  clearFormData,
  postReserveSpot,
  clearPost,
};
