import React from "react";
import { SvgIcon } from "@material-ui/core";
import { palette } from "../../theme/palette";

const CommentIcon = ({ width = 24, height = 24, state = "inactive" }) => {
  let fill = "none";
  let stroke = palette.deactivated;

  if (state === "highlighted") {
    stroke = palette.primary;
  } else if (state === "filled") {
    fill = palette.black;
    stroke = palette.black;
  } else if (state === "outline") {
    stroke = palette.black;
  }

  return (
    <SvgIcon width={width} height={height}>
      <svg
        viewBox={`-1 -1 ${width + 2} ${height + 2}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 18.9999C5.03 18.9999 1 14.9702 1 9.99997C1 5.02974 5.03 1 10 1C14.97 1 19 5.02974 19 9.99997C19 11.7707 18.488 13.4222 17.605 14.815L19 18.9999L14.255 17.9323C12.9252 18.6408 11.4714 19.0056 10 18.9999Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};

export default CommentIcon;
