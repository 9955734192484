import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useTrainingContext } from "../../../context/TrainingContext";
import { palette } from "../../../theme/palette";
import { useTranslation } from "react-i18next";
import CustomErrorBoundary from "../error/CustomErrorBoundary";

const WEEKLY_DAYS = 6;
export const DateRangeType = ["today", "week", "month"];
const languageSelected = {
  en: "en-US",
  es: "es-ES",
  fr: "fr-FR",
};
const DateRange = ({
  type = "today",
  disabled = false,
  disablePrevious = false,
  disableNext = false,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("training");
  const { language } = i18n;
  const {
    setWeekRange,
    onChangeDate,
    onSetDirection,
    dateTo,
    dateFrom,
  } = useTrainingContext();

  const handleArrowClick = (increment) => {
    let newStartDate = new Date(dateFrom);
    let newEndDate = new Date(dateTo);
    const isPositive = increment > 0 ? true : false;

    if (type === "today") {
      newStartDate.setDate(newStartDate.getDate() + increment);
      newEndDate.setDate(newEndDate.getDate() + increment);
    } else if (type === "week") {
      newStartDate.setDate(
        newStartDate.getDate() + (WEEKLY_DAYS + 1) * increment,
      );
      newEndDate.setDate(newStartDate.getDate() + WEEKLY_DAYS * increment);
      newStartDate = getStartOfWeek(newStartDate); // re calculate start week
      newEndDate = new Date(newStartDate);
      newEndDate.setDate(newStartDate.getDate() + WEEKLY_DAYS);
    } else {
      newEndDate.setMonth(newEndDate.getMonth() + increment);
    }
    setWeekRange(newStartDate, newEndDate);
    onChangeDate(newEndDate);
    onSetDirection(isPositive);
  };

  const renderDateLabel = () => {
    // default day
    let formattedDate = dateFrom.toLocaleDateString(
      languageSelected[language],
      {
        weekday: "long",
        year: undefined,
        month: "short",
        day: "numeric",
      },
    );
    let formattedEndDate = dateTo.toLocaleDateString(
      languageSelected[language],
      {
        weekday: "long",
        year: undefined,
        month: "short",
        day: "numeric",
      },
    );

    if (type === "week") {
      formattedDate = dateFrom.toLocaleDateString(languageSelected[language], {
        weekday: undefined,
        year: undefined,
        month: "short",
        day: "numeric",
      });
      formattedEndDate = dateTo.toLocaleDateString(languageSelected[language], {
        weekday: undefined,
        year: undefined,
        month: "short",
        day: "numeric",
      });
      return `${t("profile.stats.dateBetween.weekly", {
        ns: "profile",
      })}: ${formattedDate} ${t("profile.stats.dateBetween.to", {
        ns: "profile",
      })} ${formattedEndDate}`;
    } else if (type === "month") {
      return `${formattedDate}`;
    } else {
      return `${formattedDate}`;
    }
  };

  const getStartOfWeek = (date) => {
    const day = date.getDay();
    const difference = day === 0 ? -6 : 1 - day;
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() + difference);
    startOfWeek.setHours(0, 0, 0, 0);
    return startOfWeek;
  };

  useEffect(() => {
    const today = new Date();
    const endOfMonth = new Date(today);
    const startDate = getStartOfWeek(today);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + WEEKLY_DAYS);

    switch (type) {
      case "today":
        setWeekRange(today, today);
        break;
      case "week":
        setWeekRange(startDate, endDate);
        break;
      case "month":
        endOfMonth.setMonth(today.getMonth() + 1);
        endOfMonth.setDate(0);
        setWeekRange(startDate, endOfMonth);
        break;
    }
  }, [type]);

  return (
    <CustomErrorBoundary>
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-around",
          display: "flex",
        }}
      >
        <IconButton
          disabled={disabled || disablePrevious}
          className={classes.iconButton}
          onClick={() => handleArrowClick(-1)}
        >
          <ArrowBackIos style={{ width: 18 }} />
        </IconButton>

        <Box className={classes.spaceBetweenPad}>
          <div className={classes.slideContainer}>
            <Typography
              className={classes.dateText}
              style={{ color: disabled ? palette.deactivated : "initial" }}
            >
              {renderDateLabel()}
            </Typography>
          </div>
        </Box>

        <IconButton
          disabled={disabled || disableNext}
          onClick={() => handleArrowClick(1)}
          className={classes.iconButton}
        >
          <ArrowForwardIos style={{ width: 18 }} />
        </IconButton>
      </Box>
    </CustomErrorBoundary>
  );
};

const useStyles = makeStyles({
  spaceBetweenPad: {
    padding: "0 35px",
  },
  dateText: {},
  iconButton: {
    width: "30px",
    height: "30px",
  },
  slideContainer: {
    width: "100%",
  },
});

DateRange.defaultTypes = {
  type: "today",
  disabled: false,
};

DateRange.propTypes = {
  type: PropTypes.oneOf(["today", "week", "month"]),
  disabled: PropTypes.bool,
};

export default DateRange;
