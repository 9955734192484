import React from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Typography, Button } from "@material-ui/core";
import history from "../../lib/history-helper";

const styles = (theme) => ({
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  noPadding: {
    padding: "0px !important",
    maxWidth: "700px !important",
  },
  dialogPaper: {
    borderRadius: "20px",
    maxWidth: "700px",
  },
  imageParent: {
    position: "relative",
    width: "100%",
  },
  closeIcon: {
    position: "absolute",
    top: "5%",
    right: "3%",
    cursor: "pointer",
  },
  content: {
    padding: "12px",
    boxSizing: "border-box",
  },
  description: {
    fontSize: "21px",
    color: "#3A4750",
    textAlign: "center",
    marginBottom: "26px",
    fontWeight: 100,
    width: "80%",
    margin: "0 auto",
    lineHeight: "25.6px",
  },
  price: {
    color: "#3A4750",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "bold",
  },
  btnParent: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0 6px 0",
  },
  btn: {
    width: "453px",
    marginBottom: "16px",
  },
  card: {
    backgroundColor: "#E16327",
    width: "124px",
    height: "99px",
    borderRadius: "13px",
    padding: "6px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      width: "35%",
    },
  },
  cardText: {
    color: "#FFF",
    textAlign: "center",
    marginTop: "7px",
  },
  cardParent: {
    display: "flex",
    justifyContent: "center",

    "& div:first-child": {
      marginRight: "25px",
    },

    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-evenly",
    },
  },
  title: {
    fontSize: "23px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "13px",
  },
  contentText: {
    fontSize: "16px",
    marginBottom: "20px",
    textAlign: "center",
  },
  subtitle: {
    fontSize: "20px",
    textAlign: "center",
    marginBottom: "12px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "14px",
    },
  },
  communityImg: {
    objectFit: "cover",
    width: "100%",
  },
});

const ReserveModal = ({ classes, t, open, onClose, width, action }) => {
  const isInMobile = width === "sm" || width === "xs";

  const navigateTo = (path) => {
    history.push(path);
    if (action) action();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose ? onClose : null}
      aria-labelledby="upgrade-to-pro"
      className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={classes.noPadding}>
        <div className={classes.imageParent}>
          <img
            className={classes.communityImg}
            alt="upgrade to pro modal"
            src={`${process.env.PUBLIC_URL}/${
              !isInMobile ? "welcome-banner" : "upgrade-pro-banner"
            }.svg`}
          />
          <img
            src={`${process.env.PUBLIC_URL}/close-white-icon.svg`}
            alt="close-icon"
            className={classes.closeIcon}
            onClick={onClose ? onClose : null}
          />
        </div>

        <div className={classes.content}>
          <Typography className={classes.title}>
            {t("Your spot is reserved")}
          </Typography>
          <Typography className={classes.contentText}>
            {t(
              "Once the training plan for your race is live, we'll notify you via email so you can start your training journey",
            )}
          </Typography>
          <Typography className={classes.subtitle}>
            {t("In the meantime you can")}:
          </Typography>

          <div className={classes.cardParent}>
            <div
              className={classes.card}
              onClick={() => navigateTo("/chooseplan")}
            >
              <img
                src={`${process.env.PUBLIC_URL}/explore-plans-icon.svg`}
                alt="explore-icon"
              />
              <Typography variant="body1" className={classes.cardText}>
                {t("Explore other plans")}
              </Typography>
            </div>

            <div className={classes.card} onClick={() => navigateTo("/")}>
              <img
                src={`${process.env.PUBLIC_URL}/goto-profile-icon.svg`}
                alt="explore-icon"
              />
              <Typography variant="body1" className={classes.cardText}>
                {t("Go to your profile")}
              </Typography>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation("plan")(
  withWidth()(withStyles(styles, { withTheme: true })(ReserveModal)),
);
