import React from "react";
import { palette } from "../../theme/palette";

const UnitIcon = ({
  width = 24,
  height = 24,
  activated = false,
  fill = null,
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={activated ? palette.primary : palette.white}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3908 5.13202C14.6228 5.26396 14.8155 5.45534 14.9489 5.68645C15.0824 5.91756 15.1519 6.18007 15.1502 6.44695V11.3637C15.1502 11.9098 14.8512 12.4134 14.3685 12.6787L9.81222 15.561C9.58633 15.685 9.3328 15.75 9.07511 15.75C8.81741 15.75 8.56388 15.685 8.33799 15.561L3.78166 12.6787C3.54552 12.5496 3.34839 12.3595 3.21088 12.1282C3.07337 11.8969 3.00054 11.6328 3 11.3637V6.44627C3 5.90019 3.29903 5.3973 3.78166 5.13202L8.33799 2.44548C8.57056 2.31725 8.83181 2.25 9.09738 2.25C9.36296 2.25 9.6242 2.31725 9.85677 2.44548L14.4131 5.13202H14.3908Z"
        stroke={fill ? fill : activated ? palette.primary : palette.black}
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
export default UnitIcon;
