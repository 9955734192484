import { connect } from "react-redux";

import * as SubCategoriesActions from "../actions/subcategory-action";

const mapState = (state) => ({ subCategories: state.subcategories });
const mapDispatch = {
  ...SubCategoriesActions,
};

const ConnectSubCategories = connect(mapState, mapDispatch);

export default ConnectSubCategories;
