import React from "react";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { CircularProgress } from "@material-ui/core";

class GooogleSigninSignOut extends React.Component {
  async componentWillMount() {
    const { match } = this.props;

    const { params } = match;
    const { authlink } = params;

    try {
      localStorage.setItem("authlink", decodeURIComponent(authlink));
    } catch (e) {
      console.log(e);
    }

    await Auth.signOut();
    window.location.href = decodeURIComponent(authlink);
  }

  render() {
    return (
      <CircularProgress
        style={{
          margin: "auto",
        }}
        variant="indeterminate"
      />
    );
  }
}

export default connect((store) => {
  return {
    auth: store.auth,
  };
})(GooogleSigninSignOut);
