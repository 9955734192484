import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  LinearProgress,
  Avatar,
  Grid,
  Tabs,
  Tab,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Storage } from "aws-amplify";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { convertUnits, round } from "../../lib/units-helper";
import { dateFormat } from "../../lib/date-helper";
import { CircularProgress } from "@material-ui/core";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: 10,
    marginTop: -20,

    [theme.breakpoints.up("md")]: {
      margin: "0 10px",
    },
  },
  imgContainer: {
    position: "relative",
  },
  img: {
    width: "100%",
  },
  badge: {
    height: "90%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  title: {
    fontFamily: "Avenir",
    fontWeight: 900,
    fontSize: 18,
    color: "#202020",
    margin: "12px 0",
    textAlign: "left",
    textTransform: "uppercase",
  },
  description: {
    fontFamily: "Avenir",
    fontWeight: "normal",
    fontSize: 16,
    textAlign: "left",
    color: "#9E9E9E",
  },
  dateContainer: {
    display: "flex",
    alignItems: "center",
  },
  date: {
    fontFamily: "Avenir",
    fontWeight: "normal",
    fontSize: 14,
    color: "#202020",
    marginLeft: 5,
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "6px 0 3px",
  },
  participants: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontSize: 10,
    color: "#202020",
    fontWeight: "normal",
  },
  divider: {
    margin: "0 10px",
  },
  textContainer: {
    padding: "0 16px 16px",
  },
  difficulty: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 14,
    background: "rgba(255, 87, 34, 0.24)",
    width: "fit-content",
    padding: "0px 5px",
    marginTop: 10,
  },
  button: {
    borderRadius: 50,
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "20px",
    textAlign: "center",
    color: "#FFFFFF",
    margin: "auto",
    marginTop: 8,
  },
  shareContainer: {
    display: "flex",
    alignItems: "center",
    height: 48,
  },
  inviteButton: {
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: 600,
    borderRadius: 50,
    margin: "0 10px",
  },
  progressRoot: {
    borderRadius: 5,
    height: 32,
    background: "rgba(63, 81, 181, 0.12)",
  },
  progressBar: {
    borderRadius: 0,
    background: "#1E629B",
  },
  progressLabel: {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    marginLeft: 5,
    fontWeight: "normal",
    fontSize: 16,
    color: "#9E9E9E",
  },
  progressContainer: {
    margin: "15px 0 10px",
    padding: 15,
    border: "1px solid #1E629B",
    borderRadius: 10,
  },
  progressBarContainer: {
    position: "relative",
  },
  progressTitle: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    color: "#202020",
  },
  progressMinMaxContainer: {
    display: "flex",
    margin: "8px 0",
  },
  progressMin: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 24,
    color: "#2E3A59",
  },
  progressMax: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 24,
    color: "#2E3A59",
  },
  progressAchieved: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    textAlign: "center",
    color: "#202020",
    margin: "5px 0",
  },
  progressGoal: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    textAlign: "center",
    color: "#202020",
    margin: "5px 0",
  },
  backIcon: {
    cursor: "pointer",
    marginLeft: 10,
    marginRight: "auto",
  },
  iconShare: {},
  leaderboard: {
    background: "#F9F9FB",

    [theme.breakpoints.up("md")]: {
      background: "#FFFFFF",
      boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
      borderRadius: 10,
    },
  },

  leaderboardTitleContainer: {
    display: "flex",
    padding: "10px 16px",
    background: "#fff",
  },

  iconChallenges: {
    height: 22,
    marginRight: 5,
  },

  leaderboardTitle: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    textAlign: "center",
    color: "#202020",
  },
  leaderboardHeader: {
    display: "flex",
    justifyContent: "space-around",
    margin: 15,
  },
  leaderboardHeaderEntry: {
    textAlign: "center",
  },
  leaderboardHeaderLabel: {
    fontSize: 14,
    fontWeight: 400,
  },
  leaderboardHeaderValue: {
    fontSize: 24,
    fontWeight: 400,
  },
  leaderboardEntry: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    alignItems: "center",
  },
  leaderboardEntrySelected: {
    display: "flex",
    justifyContent: "space-between",
    background: "rgba(255, 111, 0, 0.2)",
    padding: 10,
    alignItems: "center",
  },
  leaderboardEntryName: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    color: "#1D1D28",
  },

  raceName: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSeight: 500,
    fontSize: 10,
    color: "#A4A4B2",
  },
  leaderboardEntryPosition: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    color: "#1D1D28",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginRight: 8,
  },

  leaderboardEntryValue: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    color: "#1D1D28",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginRight: 8,
  },

  user: {
    display: "flex",
    alignItems: "center",
  },
  userTexts: {
    textAlign: "left",
    marginLeft: 5,
  },
  stagesContainer: {
    marginTop: 15,

    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 500,
    },
  },
  stage: {
    padding: "10px 0",
    background: "rgba(63, 81, 181, 0.12)",
    borderRadius: 10,
    margin: "8px 0",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  stageTime: {
    fontFamily: "Avenir",
    fontWeight: 700,
    fontSize: 16,
    margin: "0 8px",
  },
  stageName: {
    fontFamily: "Avenir",
    fontWeight: 400,
    fontSize: 16,
  },
  stageValue: {
    fontFamily: "Avenir",
    fontWeight: 400,
    fontSize: 18,
    marginLeft: "auto",
    marginRight: 8,
  },
  iconDone: {
    marginRight: 8,
  },
  iconInvite: {
    marginRight: 5,
  },

  activitiesTitle: {
    fontFamily: "Avenir",
    fontWeight: "normal",
    fontSize: 18,
    textAlign: "center",
    color: "#202020",
    margin: "18px 0 10px",
  },

  activity: {
    display: "flex",
    background: "rgba(63, 81, 181, 0.12)",
    borderRadius: 10,
    padding: 6,
    margin: 4,
    cursor: "pointer",
  },

  activitySelected: {
    display: "flex",
    background: "#1E629B",
    borderRadius: 10,
    padding: 6,
    margin: 4,
    cursor: "pointer",
  },

  acitivyRunIcon: {
    margin: "0 5px",
  },

  activityTexts: {
    textAlign: "left",
  },

  activityDate: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 12,
    color: "#1D1D28",
  },

  activityDateSelected: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 12,
    color: "#FFFFFF",
  },

  totalsContainer: {
    display: "flex",
  },

  totalsText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 10,
    color: "#A4A4B2",
  },

  totalsTextSpacer: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 10,
    color: "#A4A4B2",
    margin: "0 2px",
  },

  totalsTextSpacerSelected: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 10,
    color: "#FFF",
    margin: "0 2px",
  },

  totalsTextSelected: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 10,
    color: "#FFF",
  },

  acitivySelectedIcon: {
    marginLeft: "auto",
    marginRight: 5,
  },

  noActivitiesContainer: {
    background: "#EFF3F5",
    borderRadius: 8,
    padding: 10,
    margin: 30,
  },

  noActivities: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#7B8BB2",
  },

  topBarLeaderboard: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: 10,
    },
  },
  filter: {
    color: "#000000",
    cursor: "pointer",
    padding: "3px 8px",
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10,
    background: "#E7EAEE",
    borderRadius: 3,
    width: "33%",
    textAlign: "center",
  },
  filterSelected: {
    color: "#FFFFFF",
    cursor: "pointer",
    padding: "3px 8px",
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10,
    background: "#2E3A59",
    borderRadius: 3,
    width: "33%",
    textAlign: "center",
  },

  loadingContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  loading: {
    margin: "auto",
  },

  descOpener: {
    cursor: "pointer",
  },

  buttonContainer: {
    textAlign: "center",
  },

  topBarMobile: {
    background: "#fff",
  },

  iconLeader: {
    marginLeft: -6,
    marginRight: 4,
  },

  buttonJoin: {
    borderRadius: 50,
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "20px",
    textAlign: "center",
    margin: "auto",
    marginTop: 8,
    position: "fixed",
    bottom: 60,
    left: "50%",
    transform: "translate(-50%, 0)",
  },

  favContainer: {
    background: "#FFFFFF",
    borderRadius: 24,
    position: "absolute",
    top: 15,
    right: 15,
    padding: "2px 10px",
    zIndex: 10,
  },
});

class YourChallenge extends Component {
  state = {
    imageUrl: null,
    imageLoading: false,
    badgeUrl: null,
    badgeLoading: false,
    imageUrls: {},
    tab: 0,
    openDescription: false,
  };

  componentDidMount() {
    const { leaders: leaderEntries } = this.props;
    if (
      leaderEntries &&
      leaderEntries.leaders &&
      leaderEntries.leaders.length > 0
    ) {
      const { leaders, maleLeaders, femaleLeaders } = leaderEntries || {};
      leaders.forEach((element) => {
        if (element.user) {
          this.getUserImageUrl(element.user.image);
        }
      });
      maleLeaders.forEach((element) => {
        if (element.user) {
          this.getUserImageUrl(element.user.image);
        }
      });
      femaleLeaders.forEach((element) => {
        if (element.user) {
          this.getUserImageUrl(element.user.image);
        }
      });
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { challenge } = nextProps;
    const { image, badge } = challenge;
    const { imageUrl, badgeUrl } = this.state;
    if (image && !imageUrl) {
      this.getImageUrl(image);
    }
    if (badge && !badgeUrl) {
      this.getBadgeUrl(badge);
    }
  }

  componentDidUpdate(prevProps) {
    const { leaders: leaderEntries } = this.props;

    const { currentPlan } = this.props;

    const { image } = currentPlan || "";

    if ((!prevProps.currentPlan || !prevProps.currentPlan.image) && image) {
      this.getUserImageUrl(image);
    }

    if (
      (!prevProps.leaderEntries ||
        !prevProps.leaderEntries.leaders ||
        prevProps.leaderEntries.leaders.length === 0) &&
      leaderEntries &&
      leaderEntries.leaders &&
      leaderEntries.leaders.length > 0
    ) {
      const { leaders, maleLeaders, femaleLeaders } = leaderEntries || {};
      leaders.forEach((element) => {
        if (element.user) {
          this.getUserImageUrl(element.user.image);
        }
      });
      maleLeaders.forEach((element) => {
        if (element.user) {
          this.getUserImageUrl(element.user.image);
        }
      });
      femaleLeaders.forEach((element) => {
        if (element.user) {
          this.getUserImageUrl(element.user.image);
        }
      });
    }
  }

  getUserImageUrl = async (image) => {
    const { t } = this.props;
    const { imageUrls } = this.state;

    if (!imageUrls[image]) {
      try {
        const result = await Storage.get(image, {
          level: "public",
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });
        imageUrls[image] = result;
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              imageUrls,
            }),
          100,
        );
      } catch (exp) {
        //onError(
        new Error(
          t(
            "Oops, we could not load your profile picture. Give it another try",
            { ns: "messages" },
          ),
          //  ),
        );
        throw exp;
      }
    }
  };

  getImageUrl = async (image) => {
    const { t } = this.props;
    const { imageUrl } = this.state;

    if (image && !imageUrl) {
      try {
        const result = await Storage.get(image, {
          level: "public",
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              imageUrl: result,
              imageLoading: false,
            }),
          100,
        );
      } catch (exp) {
        //onError(
        new Error(
          t(
            "Oops, we could not load your profile picture. Give it another try",
            { ns: "messages" },
          ),
          //  ),
        );
        throw exp;
      }
    } else if (!image) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            imageLoading: false,
          }),
        100,
      );
    }
  };

  getBadgeUrl = async (image) => {
    const { t } = this.props;
    const { badgeUrl } = this.state;

    if (image && !badgeUrl) {
      try {
        const result = await Storage.get(image, {
          level: "public",
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              badgeUrl: result,
              badgeLoading: false,
            }),
          100,
        );
      } catch (exp) {
        // onError(
        new Error(
          t(
            "Oops, we could not load your profile picture. Give it another try",
            { ns: "messages" },
          ),
        );
        //);
        throw exp;
      }
    } else if (!image) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            badgeLoading: false,
          }),
        100,
      );
    }
  };

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    const {
      classes,
      challenge,
      t,
      i18n,
      onShareChallenge,
      onClose,
      currentPlan,
      onInvite,
      units,
      showLeaderboard,
      activities,
      onSelectActivity,
      width,
      isPast,
      loadingYourChallengeEntries,
      notJoined,
      onJoinChallenge,
      leaders: leaderEntries,
      loadingLeaders,

      isFav,
      onToggleFavorite,
    } = this.props;

    const isInMobileViewSm = width === "sm" || width === "xs";

    const {
      image: userImage,
      cognito_user_sub,
      nextRaces,
      cognito_user_name,
      sex,
    } = currentPlan || "";

    const { openDescription } = this.state;

    const allRaces = [];
    if (nextRaces && nextRaces.length > 0) {
      nextRaces.forEach((element) => {
        const today = moment();
        const raceDateMoment = moment(element.raceDate, "DD/MM/YYYY");
        const weeksToRace = raceDateMoment.diff(today, "day");
        if (weeksToRace >= 0) {
          allRaces.push(element);
        }
      });
    }

    const {
      name,
      description,
      start_date,
      end_date,
      difficulty,
      participants,
      image,
      badge,
      yourEntry,
      goal,
      userEntries,
      goal_type,
      stages,
      limit_type,
      limit_value,
      no_activities,
    } = challenge;

    const isFuture = moment(start_date).isAfter(moment());

    const challengeStart = moment(challenge.start_date);
    const challengeEnd = moment(challenge.end_date);
    const challengeWeeks = Math.ceil(
      (challengeEnd.diff(challengeStart, "days") + 1) / 7,
    );

    const {
      leaders,
      position,
      maleLeaders,
      malePosition,
      femaleLeaders,
      femalePosition,
      finishers,
    } = leaderEntries || {};

    const { imageUrls, tab } = this.state;

    const currentLeaders =
      tab === 0 ? leaders : tab === 1 ? maleLeaders : femaleLeaders;

    (currentLeaders ? currentLeaders : []).forEach((l) => {
      const allRaces = [];
      if (l.user.nextRaces && l.user.nextRaces.length > 0) {
        l.user.nextRaces.forEach((element) => {
          const today = moment();
          const raceDateMoment = moment(element.raceDate, "DD/MM/YYYY");
          const weeksToRace = raceDateMoment.diff(today, "day");
          if (weeksToRace >= 0) {
            allRaces.push(element);
          }
        });
      }

      l.user.allRaces = allRaces;
    });

    const currentPosition =
      tab === 0 ? position : tab === 1 ? malePosition : femalePosition;

    let isInLeaders = currentLeaders
      ? currentLeaders.find((l) => l.cognito_user_sub === cognito_user_sub)
      : false;

    if (tab === 1 && sex !== "Male") {
      isInLeaders = true;
    }

    if (tab === 2 && sex !== "Female") {
      isInLeaders = true;
    }

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const momentLocale = moment().locale(language);

    let stagesF = stages;
    if (stages) {
      stagesF = stages.map((stage, i) => {
        let breakAt = 0;
        for (let index = 0; index < i + 1; index++) {
          breakAt += stages[index].value;
        }
        stage.breakAt = breakAt;
        return stage;
      });
    } else {
      stagesF = [];
      if (goal_type === "consistency" && challengeWeeks > 1) {
        for (let index = 0; index < challengeWeeks; index++) {
          stagesF.push({
            name: {
              en: `Week ${index + 1}`,
              es: `Semana ${index + 1}`,
            },
            time: {
              en: "",
              es: "",
            },
            value: goal,
          });
        }
      }
    }

    let possibleActivities = [];
    if (!goal) {
      const challengeStart = moment(start_date);
      const challengeEnd = moment(end_date);

      const activitiesOnTime = activities.filter(
        (a) =>
          moment(
            a.start_date_local ? a.start_date_local : a.start_date,
          ).isBetween(challengeStart, challengeEnd, undefined, "[]") &&
          ((a.type && a.type.toLowerCase().includes("run")) ||
            (a.activityType && a.activityType.includes("RUN"))),
      );

      const limit_types = limit_type ? limit_type.split(",") : [];

      let keys = [];

      for (let l of limit_types) {
        if (l.includes("elevation")) {
          keys.push("total_elevation_gain");
        }
        if (l.includes("distance")) {
          keys.push("distance");
        }
        if (l.includes("time")) {
          keys.push("elapsed_time");
        }
      }

      const limit_values = limit_value ? limit_value.split(",") : [];

      possibleActivities = activitiesOnTime;
      let i = 0;
      for (let key of keys) {
        if (limit_values.length > i) {
          if (key === "distance") {
            possibleActivities = possibleActivities.filter(
              (a) =>
                +a[key] >= +limit_values[i].trim() * 0.95 &&
                +a[key] <= +limit_values[i].trim() * 2,
            );
          }
          if (key === "elapsed_time") {
            possibleActivities = possibleActivities.filter(
              (a) =>
                +a[key] >= +limit_values[i].trim() * 0.8 &&
                +a[key] <= +limit_values[i].trim() * 1.05,
            );
          } else {
            possibleActivities = possibleActivities.filter(
              (a) => +a[key] >= +limit_values[i].trim() * 0.95,
            );
          }
        }
        i++;
      }

      possibleActivities.forEach((a) => {
        a.selected = false;
        if (
          yourEntry &&
          yourEntry.activities &&
          yourEntry.activities.length &&
          yourEntry.activities.find(
            (yA) =>
              (yA.id && yA.id === a.id) ||
              (yA.summaryId && yA.summaryId === a.summaryId),
          )
        ) {
          a.selected = true;
        }
      });

      if (yourEntry && yourEntry.activities && yourEntry.activities.length) {
        yourEntry.activities.forEach((a) => {
          if (
            !possibleActivities.find(
              (yA) =>
                (yA.id && yA.id === a.id) ||
                (yA.summaryId && yA.summaryId === a.summaryId),
            )
          ) {
            a.selected = true;
            possibleActivities.push(a);
          }
        });
      }
    }

    return (
      <div className={classes.container}>
        {showLeaderboard === 0 || showLeaderboard === 1 ? (
          <div className={classes.shareContainer}>
            <div className={classes.backIcon} onClick={() => onClose()}>
              <ArrowBackIosIcon fontSize="small" />
            </div>
            {!notJoined ? (
              <img
                src={`${process.env.PUBLIC_URL}/icon-share.svg`}
                alt=""
                className={classes.iconShare}
                onClick={() => onShareChallenge(challenge)}
                style={{
                  marginRight: isPast ? 10 : 0,
                }}
              />
            ) : (
              ""
            )}
            {!isPast ? (
              <Button
                color="primary"
                variant="outlined"
                className={classes.inviteButton}
                onClick={() => onInvite(challenge)}
              >
                {t("Invite friends")}
              </Button>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {showLeaderboard === 0 || showLeaderboard === 1 ? (
          <div className={classes.imgContainer}>
            <img className={classes.img} alt="challenge" src={image} />
            <img className={classes.badge} alt="badge" src={badge} />

            <div className={classes.favContainer}>
              <img
                src={`${process.env.PUBLIC_URL}/icon-star-${
                  isFav ? "filled" : "outline"
                }.svg`}
                alt=""
                onClick={(ev) =>
                  onToggleFavorite(ev, challenge.id, "Training plan")
                }
                className={classes.fav}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {showLeaderboard === 0 || showLeaderboard === 1 ? (
          <div className={classes.textContainer}>
            <Typography variant={"body1"} className={classes.difficulty}>
              <b>
                {t("Difficulty")}
                {": "}
              </b>
              {+difficulty === 0
                ? t("All levels")
                : +difficulty === 1
                ? t("Easy")
                : +difficulty === 2
                ? t("Medium")
                : +difficulty === 3
                ? t("High")
                : t("All levels")}
            </Typography>

            {name && (
              <Typography variant={"body1"} className={classes.title}>
                {name[languageDetector] ? name[languageDetector] : name["en"]}
              </Typography>
            )}
            {description && (
              <>
                <Typography
                  variant={"body1"}
                  className={classes.description}
                  style={{
                    whiteSpace: openDescription ? "initial" : "nowrap",
                    overflow: openDescription ? "initial" : "hidden",
                    textOverflow: openDescription ? "initial" : "ellipsis",
                  }}
                >
                  {description[languageDetector]
                    ? description[languageDetector]
                    : description["en"]}
                </Typography>

                <img
                  src={`${process.env.PUBLIC_URL}/icon-chevron-${
                    openDescription ? "up" : "down"
                  }.svg`}
                  alt=""
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      openDescription: !openDescription,
                    })
                  }
                  className={classes.descOpener}
                />
              </>
            )}

            <div className={classes.dateContainer}>
              <img
                src={`${process.env.PUBLIC_URL}/icon-challenge-date.svg`}
                alt=""
                className={classes.iconDate}
              />
              <Typography variant={"body1"} className={classes.date}>
                {`${this.capitalize(
                  momentLocale
                    .localeData()
                    .months(moment(start_date))
                    .substr(0, 3),
                )} ${moment(moment(start_date)).format("D")} ${t(
                  "to",
                )} ${this.capitalize(
                  momentLocale
                    .localeData()
                    .months(moment(end_date))
                    .substr(0, 3),
                )} ${moment(moment(end_date)).format("D")}. ${moment(
                  moment(end_date),
                ).format("YYYY")}`}
              </Typography>
            </div>

            <div className={classes.dateContainer}>
              <img
                src={`${process.env.PUBLIC_URL}/icon-participants.svg`}
                alt=""
                className={classes.iconParticipants}
              />
              <Typography variant={"body1"} className={classes.date}>
                {participants ? participants.length : 0} {t("participants")}
              </Typography>
            </div>

            {stagesF ? (
              <Grid container className={classes.stagesContainer}>
                {stagesF.map((stage, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    className={classes.stage}
                    style={{
                      backgroundColor:
                        (goal_type === "consistency" &&
                          yourEntry &&
                          yourEntry.currentDoneWeeks &&
                          yourEntry.currentDoneWeeks[index] &&
                          yourEntry.currentDoneWeeks[index] >= goal) ||
                        (goal_type !== "consistency" &&
                          (yourEntry ? yourEntry.currentDone : 0) >=
                            stage.breakAt)
                          ? "#1E629B"
                          : "rgba(63, 81, 181, 0.12)",
                    }}
                  >
                    <Typography
                      variant={"body1"}
                      className={classes.stageTime}
                      style={{
                        color:
                          (goal_type === "consistency" &&
                            yourEntry &&
                            yourEntry.currentDoneWeeks &&
                            yourEntry.currentDoneWeeks[index] &&
                            yourEntry.currentDoneWeeks[index] >= goal) ||
                          (goal_type !== "consistency" &&
                            (yourEntry ? yourEntry.currentDone : 0) >=
                              stage.breakAt)
                            ? "#fff"
                            : "#000",
                      }}
                    >
                      {stage.time[languageDetector]
                        ? stage.time[languageDetector]
                        : stage.time["en"]}
                    </Typography>

                    <Typography
                      variant={"body1"}
                      className={classes.stageName}
                      style={{
                        color:
                          (goal_type === "consistency" &&
                            yourEntry &&
                            yourEntry.currentDoneWeeks &&
                            yourEntry.currentDoneWeeks[index] &&
                            yourEntry.currentDoneWeeks[index] >= goal) ||
                          (goal_type !== "consistency" &&
                            (yourEntry ? yourEntry.currentDone : 0) >=
                              stage.breakAt)
                            ? "#fff"
                            : "#000",
                      }}
                    >
                      {stage.name[languageDetector]
                        ? stage.name[languageDetector]
                        : stage.name["en"]}
                    </Typography>

                    <Typography
                      variant={"body1"}
                      className={classes.stageValue}
                      style={{
                        color:
                          (goal_type === "consistency" &&
                            yourEntry &&
                            yourEntry.currentDoneWeeks &&
                            yourEntry.currentDoneWeeks[index] &&
                            yourEntry.currentDoneWeeks[index] >= goal) ||
                          (goal_type !== "consistency" &&
                            (yourEntry ? yourEntry.currentDone : 0) >=
                              stage.breakAt)
                            ? "#fff"
                            : "#000",
                      }}
                    >
                      {goal_type === "elevation"
                        ? t("valueUnit", {
                            value: round(
                              convertUnits(
                                stage.value,
                                "kms",
                                units,
                                "elevation",
                              ),
                              1,
                            ),
                            unit: units === "miles" ? ` ${t("feet")}` : "m",
                          })
                        : goal_type === "time"
                        ? t("valueUnit", {
                            value: new Date(stage.value * 1000)
                              .toISOString()
                              .substr(11, 5),
                            unit: "h",
                          })
                        : goal_type === "distance"
                        ? t("valueUnit", {
                            value: round(
                              convertUnits(
                                stage.value / 1000,
                                "kms",
                                units,
                                "distance",
                              ),
                              1,
                            ),
                            unit: units === "miles" ? ` ${t("miles")}` : "km",
                          })
                        : `${
                            yourEntry &&
                            yourEntry.currentDoneWeeks &&
                            yourEntry.currentDoneWeeks[index]
                              ? yourEntry.currentDoneWeeks[index]
                              : 0
                          }/${t("X days", { value: stage.value })}`}
                    </Typography>

                    {(goal_type === "consistency" &&
                      yourEntry &&
                      yourEntry.currentDoneWeeks &&
                      yourEntry.currentDoneWeeks[index] &&
                      yourEntry.currentDoneWeeks[index] >= goal) ||
                    (goal_type !== "consistency" &&
                      (yourEntry ? yourEntry.currentDone : 0) >=
                        stage.breakAt) ? (
                      <img
                        alt="done"
                        className={classes.iconDone}
                        src={`${process.env.PUBLIC_URL}/icon-stage-done.svg`}
                      />
                    ) : (
                      <img
                        alt="done"
                        className={classes.iconDone}
                        src={`${process.env.PUBLIC_URL}/icon-stage-notdone.svg`}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              ""
            )}

            {goal && !notJoined ? (
              <div className={classes.progressContainer}>
                <Typography variant={"body1"} className={classes.progressTitle}>
                  {isPast ? t("You Achieved") : t("Your progress")}
                </Typography>

                <div className={classes.progressMinMaxContainer}>
                  <Typography variant={"body1"} className={classes.progressMin}>
                    {goal_type === "elevation"
                      ? round(
                          convertUnits(
                            yourEntry && yourEntry.currentDone
                              ? yourEntry.currentDone
                              : 0,
                            "kms",
                            units,
                            "elevation",
                          ),
                          1,
                        )
                      : goal_type === "time"
                      ? new Date(
                          yourEntry && yourEntry.currentDone
                            ? yourEntry.currentDone * 1000
                            : 0,
                        )
                          .toISOString()
                          .substr(11, 5)
                      : goal_type === "distance"
                      ? round(
                          convertUnits(
                            (yourEntry && yourEntry.currentDone
                              ? yourEntry.currentDone
                              : 0) / 1000,
                            "kms",
                            units,
                            "distance",
                          ),
                          1,
                        )
                      : yourEntry && yourEntry.currentDone
                      ? yourEntry.currentDone
                      : 0}
                    /
                  </Typography>
                  <Typography variant={"body1"} className={classes.progressMax}>
                    {goal_type === "elevation"
                      ? t("valueUnit", {
                          value: round(
                            convertUnits(goal, "kms", units, "elevation"),
                            1,
                          ),
                          unit: units === "miles" ? ` ${t("feet")}` : "m",
                        })
                      : goal_type === "time"
                      ? t("valueUnit", {
                          value: new Date(goal * 1000)
                            .toISOString()
                            .substr(11, 5),
                          unit: "h",
                        })
                      : goal_type === "distance"
                      ? t("valueUnit", {
                          value: round(
                            convertUnits(goal / 1000, "kms", units, "distance"),
                            1,
                          ),
                          unit: units === "miles" ? ` ${t("miles")}` : "km",
                        })
                      : t(challengeWeeks > 1 ? "X weeks" : "X days", {
                          value: challengeWeeks > 1 ? challengeWeeks : goal,
                        })}
                  </Typography>
                </div>

                <div className={classes.progressBarContainer}>
                  <LinearProgress
                    variant="determinate"
                    value={Math.min(
                      100,
                      yourEntry ? (yourEntry.currentDone * 100) / goal : 0,
                    )}
                    classes={{
                      root: classes.progressRoot,
                      bar: classes.progressBar,
                    }}
                  />
                  <Typography
                    variant="body1"
                    className={classes.progressLabel}
                    style={{
                      left: `${Math.min(
                        84,
                        yourEntry && yourEntry.currentDone
                          ? Math.round((yourEntry.currentDone * 100) / goal)
                          : 0,
                      )}%`,
                    }}
                  >
                    {Math.min(
                      100,
                      yourEntry && yourEntry.currentDone
                        ? Math.round((yourEntry.currentDone * 100) / goal)
                        : 0,
                    )}
                    %
                  </Typography>
                </div>

                {isFuture ? (
                  <Typography
                    variant="body1"
                    className={classes.progressAchieved}
                  >
                    {t(
                      "Your progress will start counting once the challenge starts, get ready",
                    )}
                  </Typography>
                ) : (
                  ""
                )}
              </div>
            ) : !notJoined ? (
              <div>
                <Typography
                  variant={"body1"}
                  className={classes.activitiesTitle}
                >
                  {isPast
                    ? ""
                    : isInMobileViewSm
                    ? t("Choose your activity mobile", {
                        name: name
                          ? name[languageDetector]
                            ? name[languageDetector]
                            : name["en"]
                          : "",
                      })
                    : t("Choose your activity", {
                        name: name
                          ? name[languageDetector]
                            ? name[languageDetector]
                            : name["en"]
                          : "",
                      })}
                </Typography>
                {!isPast &&
                (!possibleActivities || possibleActivities.length === 0) &&
                no_activities ? (
                  <div className={classes.noActivitiesContainer}>
                    <Typography
                      variant={"body1"}
                      className={classes.noActivities}
                    >
                      {no_activities[languageDetector]
                        ? no_activities[languageDetector]
                        : no_activities["en"]}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
                {isPast ? (
                  yourEntry &&
                  yourEntry.activities &&
                  yourEntry.activities.length > 0 ? (
                    <div className={classes.activitySelected}>
                      <img
                        src={`${process.env.PUBLIC_URL}/icon-run-white.svg`}
                        alt=""
                        className={classes.acitivyRunIcon}
                      />
                      <div className={classes.activityTexts}>
                        <Typography
                          variant={"body1"}
                          className={classes.activityDateSelected}
                        >
                          {moment(
                            yourEntry.activities[0].start_date_local,
                          ).format(dateFormat)}
                        </Typography>
                        <div className={classes.totalsContainer}>
                          <Typography
                            variant={"body1"}
                            className={classes.totalsTextSelected}
                          >
                            {t("valueUnit", {
                              value: yourEntry.activities[0].distance
                                ? round(
                                    convertUnits(
                                      yourEntry.activities[0].distance / 1000,
                                      "kms",
                                      units,
                                      "distance",
                                    ),
                                    1,
                                  )
                                : 0,
                              unit: units === "miles" ? ` ${t("miles")}` : "km",
                            })}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            className={classes.totalsTextSpacerSelected}
                          >
                            {" - "}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            className={classes.totalsTextSelected}
                          >
                            {t("valueUnit", {
                              value: yourEntry.activities[0]
                                ? new Date(
                                    yourEntry.activities[0].elapsed_time * 1000,
                                  )
                                    .toISOString()
                                    .substr(11, 5)
                                : 0,
                              unit: "h",
                            })}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            className={classes.totalsTextSpacerSelected}
                          >
                            {" - "}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            className={classes.totalsTextSelected}
                          >
                            {t("valueUnit", {
                              value: yourEntry.activities[0]
                                ? round(
                                    convertUnits(
                                      yourEntry.activities[0]
                                        .total_elevation_gain,
                                      "kms",
                                      units,
                                      "elevation",
                                    ),
                                    1,
                                  )
                                : 0,
                              unit: units === "miles" ? ` ${t("feet")}` : "m",
                            })}
                          </Typography>
                        </div>
                      </div>
                      <img
                        src={`${process.env.PUBLIC_URL}/icon-stage-done.svg`}
                        alt=""
                        className={classes.acitivySelectedIcon}
                      />
                    </div>
                  ) : (
                    <Typography
                      variant={"body1"}
                      className={classes.noActivities}
                    >
                      {t("No activity selected")}
                    </Typography>
                  )
                ) : loadingYourChallengeEntries ? (
                  <div className={classes.loadingContainer}>
                    <CircularProgress
                      variant="indeterminate"
                      color="primary"
                      className={classes.loading}
                      data-testid="LoadingUser"
                    />
                  </div>
                ) : (
                  possibleActivities.map((a) => (
                    <div
                      key={a.id}
                      className={
                        a.selected ? classes.activitySelected : classes.activity
                      }
                      onClick={() => onSelectActivity(a, challenge.id)}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/icon-run-${
                          a.selected ? "white" : "black"
                        }.svg`}
                        alt=""
                        className={classes.acitivyRunIcon}
                      />
                      <div className={classes.activityTexts}>
                        <Typography
                          variant={"body1"}
                          className={
                            a.selected
                              ? classes.activityDateSelected
                              : classes.activityDate
                          }
                        >
                          {moment(
                            a.start_date_local
                              ? a.start_date_local
                              : a.start_date,
                          ).format(dateFormat)}
                        </Typography>
                        <div className={classes.totalsContainer}>
                          <Typography
                            variant={"body1"}
                            className={
                              a.selected
                                ? classes.totalsTextSelected
                                : classes.totalsText
                            }
                          >
                            {t("valueUnit", {
                              value: a.distance
                                ? round(
                                    convertUnits(
                                      a.distance / 1000,
                                      "kms",
                                      units,
                                      "distance",
                                    ),
                                    1,
                                  )
                                : 0,
                              unit: units === "miles" ? ` ${t("miles")}` : "km",
                            })}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            className={
                              a.selected
                                ? classes.totalsTextSpacerSelected
                                : classes.totalsTextSpacer
                            }
                          >
                            {" - "}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            className={
                              a.selected
                                ? classes.totalsTextSelected
                                : classes.totalsText
                            }
                          >
                            {t("valueUnit", {
                              value: a
                                ? new Date(a.elapsed_time * 1000)
                                    .toISOString()
                                    .substr(11, 5)
                                : 0,
                              unit: "h",
                            })}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            className={
                              a.selected
                                ? classes.totalsTextSpacerSelected
                                : classes.totalsTextSpacer
                            }
                          >
                            {" - "}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            className={
                              a.selected
                                ? classes.totalsTextSelected
                                : classes.totalsText
                            }
                          >
                            {t("valueUnit", {
                              value: a
                                ? round(
                                    convertUnits(
                                      a.total_elevation_gain,
                                      "kms",
                                      units,
                                      "elevation",
                                    ),
                                    1,
                                  )
                                : 0,
                              unit: units === "miles" ? ` ${t("feet")}` : "m",
                            })}
                          </Typography>
                        </div>
                      </div>
                      {a.selected ? (
                        <img
                          src={`${process.env.PUBLIC_URL}/icon-stage-done.svg`}
                          alt=""
                          className={classes.acitivySelectedIcon}
                        />
                      ) : (
                        <img
                          src={`${process.env.PUBLIC_URL}/icon-stage-notdone.svg`}
                          alt=""
                          className={classes.acitivySelectedIcon}
                        />
                      )}
                    </div>
                  ))
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {showLeaderboard === 0 || showLeaderboard === 2 ? (
          loadingLeaders ? (
            <div className={classes.loadingContainer}>
              <CircularProgress
                variant="indeterminate"
                color="primary"
                className={classes.loading}
                data-testid="LoadingUser"
              />
            </div>
          ) : (
            <div className={classes.leaderboard}>
              <div className={classes.leaderboardTitleContainer}>
                <img
                  src={`${process.env.PUBLIC_URL}/icon-leaderboard.svg`}
                  alt=""
                  className={classes.iconChallenges}
                />
                <Typography
                  variant={"body1"}
                  className={classes.leaderboardTitle}
                >
                  {t("Leaderboard")}
                </Typography>
              </div>

              {isInMobileViewSm ? (
                <div className={classes.topBarMobile}>
                  <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(ev, index) => this.setState({ tab: index })}
                    variant="fullWidth"
                  >
                    <Tab label={t("Overall")} index={0} />
                    <Tab label={t("Male")} index={1} />
                    <Tab label={t("Female")} index={2} />
                  </Tabs>
                </div>
              ) : (
                <div className={classes.topBarLeaderboard}>
                  <Typography
                    variant="body1"
                    className={
                      tab === 0 ? classes.filterSelected : classes.filter
                    }
                    onClick={() => this.setState({ tab: 0 })}
                  >
                    {t("Overall")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={
                      tab === 1 ? classes.filterSelected : classes.filter
                    }
                    onClick={() => this.setState({ tab: 1 })}
                  >
                    {t("Male")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={
                      tab === 2 ? classes.filterSelected : classes.filter
                    }
                    onClick={() => this.setState({ tab: 2 })}
                  >
                    {t("Female")}
                  </Typography>
                </div>
              )}

              <div className={classes.leaderboardHeader}>
                <div className={classes.leaderboardHeaderEntry}>
                  <Typography
                    variant={"body1"}
                    className={classes.leaderboardHeaderLabel}
                  >
                    {t("Participants")}
                  </Typography>
                  <Typography
                    variant={"body1"}
                    className={classes.leaderboardHeaderValue}
                  >
                    {participants ? participants.length : 0}
                  </Typography>
                </div>

                {!notJoined && goal_type !== "consistency" ? (
                  <div className={classes.leaderboardHeaderEntry}>
                    <Typography
                      variant={"body1"}
                      className={classes.leaderboardHeaderLabel}
                    >
                      {t("Your place")}
                    </Typography>
                    <Typography
                      variant={"body1"}
                      className={classes.leaderboardHeaderValue}
                    >
                      {`${currentPosition}`}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.leaderboardEntries}>
                {currentLeaders &&
                  currentLeaders.map((leader, index) => (
                    <div
                      className={
                        leader.cognito_user_sub === cognito_user_sub
                          ? classes.leaderboardEntrySelected
                          : classes.leaderboardEntry
                      }
                      key={index}
                    >
                      <div className={classes.user}>
                        {goal_type !== "consistency" ? (
                          index === 0 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/icon-leader.svg`}
                              alt=""
                              className={classes.iconLeader}
                            />
                          ) : (
                            <Typography
                              variant={"body1"}
                              className={classes.leaderboardEntryPosition}
                            >
                              {`${index + 1}`}
                            </Typography>
                          )
                        ) : (
                          ""
                        )}

                        <Avatar
                          src={
                            leader.user.image
                              ? imageUrls[leader.user.image]
                              : `${process.env.PUBLIC_URL}/avatar-empty.png`
                          }
                          className={classes.avatar}
                          style={{
                            border: index === 0 ? "1px solid #FF5722" : "0",
                          }}
                        />
                        <div className={classes.userTexts}>
                          <Typography
                            variant={"body1"}
                            className={classes.leaderboardEntryName}
                          >
                            {leader.user.cognito_user_name}
                          </Typography>
                        </div>
                      </div>
                      <Typography
                        variant={"body1"}
                        className={classes.leaderboardEntryValue}
                      >
                        {goal_type === "elevation"
                          ? t("valueUnit", {
                              value: leader
                                ? round(
                                    convertUnits(
                                      leader.currentDone,
                                      "kms",
                                      units,
                                      "elevation",
                                    ),
                                    1,
                                  )
                                : 0,
                              unit: units === "miles" ? ` ${t("feet")}` : "m",
                            })
                          : goal_type === "time"
                          ? t("valueUnit", {
                              value: leader
                                ? new Date(leader.currentDone * 1000)
                                    .toISOString()
                                    .substr(11, 5)
                                : 0,
                              unit: "h",
                            })
                          : goal_type === "distance"
                          ? t("valueUnit", {
                              value: leader
                                ? round(
                                    convertUnits(
                                      leader.currentDone / 1000,
                                      "kms",
                                      units,
                                      "distance",
                                    ),
                                    1,
                                  )
                                : 0,
                              unit: units === "miles" ? ` ${t("miles")}` : "km",
                            })
                          : t(challengeWeeks > 1 ? "X weeks" : "X days", {
                              value: leader.currentDone,
                            })}
                      </Typography>
                    </div>
                  ))}

                {!isInLeaders && !notJoined ? (
                  <div className={classes.leaderboardEntrySelected}>
                    <div className={classes.user}>
                      {goal_type !== "consistency" ? (
                        <Typography
                          variant={"body1"}
                          className={classes.leaderboardEntryPosition}
                        >
                          {`${currentPosition}`}
                        </Typography>
                      ) : (
                        ""
                      )}

                      <Avatar
                        src={
                          userImage
                            ? imageUrls[userImage]
                            : `${process.env.PUBLIC_URL}/avatar-empty.png`
                        }
                        className={classes.avatar}
                      />
                      <div className={classes.userTexts}>
                        <Typography
                          variant={"body1"}
                          className={classes.leaderboardEntryName}
                        >
                          {cognito_user_name}
                        </Typography>
                      </div>
                    </div>
                    <Typography
                      variant={"body1"}
                      className={classes.leaderboardEntryValue}
                    >
                      {goal_type === "elevation"
                        ? t("valueUnit", {
                            value: yourEntry
                              ? round(
                                  convertUnits(
                                    yourEntry.currentDone,
                                    "kms",
                                    units,
                                    "elevation",
                                  ),
                                  1,
                                )
                              : 0,
                            unit: units === "miles" ? ` ${t("feet")}` : "m",
                          })
                        : goal_type === "time"
                        ? t("valueUnit", {
                            value: yourEntry
                              ? new Date(yourEntry.currentDone * 1000)
                                  .toISOString()
                                  .substr(11, 5)
                              : 0,
                            unit: "h",
                          })
                        : goal_type === "distance"
                        ? t("valueUnit", {
                            value: yourEntry
                              ? round(
                                  convertUnits(
                                    yourEntry.currentDone / 1000,
                                    "kms",
                                    units,
                                    "distance",
                                  ),
                                  1,
                                )
                              : 0,
                            unit: units === "miles" ? ` ${t("miles")}` : "km",
                          })
                        : t(challengeWeeks > 1 ? "X weeks" : "X days", {
                            value: yourEntry ? yourEntry.currentDone : 0,
                          })}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )
        ) : (
          ""
        )}

        {notJoined && isInMobileViewSm ? (
          <>
            <div
              style={{
                height: 40,
                background: "#f9f9fb",
              }}
            ></div>
            <Button
              color="primary"
              variant={"contained"}
              className={classes.buttonJoin}
              onClick={() => onJoinChallenge(challenge)}
            >
              {t("Join this Challenge")}
            </Button>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

YourChallenge.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  challenge: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation("challenges")(
  withWidth()(withStyles(styles, { withTheme: true })(YourChallenge)),
);
