import React from "react";
import { Card, CardHeader, CardContent, Skeleton } from "@mui/material";

const PostCardComponentSkeleton = () => {
  return (
    <Card sx={{ maxWidth: "100%", borderRadius: "8px", m: 2, boxShadow: 0 }}>
      <CardHeader
        avatar={<Skeleton variant="circular" width={50} height={50} />}
        title={<Skeleton variant="text" width={200} />}
        subheader={<Skeleton variant="text" width={100} />}
      />
      <CardContent>
        <Skeleton variant="text" height={20} />
        <Skeleton
          variant="rectangular"
          sx={{ marginTop: 1, marginBottom: 1, borderRadius: 4 }}
          height={400}
        />
      </CardContent>
    </Card>
  );
};

export default PostCardComponentSkeleton;
