import { useEffect, useState } from "react";
import { LocalDB } from "../lib/local-db-storage";
const useFavorites = () => {
  const [favorites, setFavorites] = useState([]);
  const favoritesStorage = new LocalDB("favorites");

  useEffect(() => {
    const loadFavorites = async () => {
      const storedFavorites =
        (await favoritesStorage.getItem("favorites")) || [];
      setFavorites(storedFavorites);
    };
    loadFavorites();
  }, []);

  const toggleFavorites = async (type, id) => {
    let updatedFavorites = [...favorites];
    const index = updatedFavorites.findIndex(
      (fav) => fav.type === type && fav.id === id,
    );

    if (index >= 0) {
      updatedFavorites.splice(index, 1);
    } else {
      updatedFavorites.push({ type, id });
    }

    setFavorites(updatedFavorites);
    await favoritesStorage.setItem("favorites", updatedFavorites);
  };

  const isFavorite = (type, id) => {
    return favorites.some((fav) => fav.type === type && fav.id === id);
  };

  return {
    favorites,
    toggleFavorites,
    isFavorite,
  };
};

export default useFavorites;
