import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Typography, Button } from "@material-ui/core";
import history from "../../lib/history-helper";
import useUserInfo from "../../hooks/useUserInfo";
import { setEventsMetadata, logEvent } from "../../lib/events-helper";

const styles = () => ({
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  noPadding: {
    padding: "0px !important",
    maxWidth: "700px !important",
  },
  dialogPaper: {
    borderRadius: "20px",
    maxWidth: "700px",
  },
  imageParent: {
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    top: "5%",
    right: "3%",
    cursor: "pointer",
  },
  content: {
    padding: "12px",
    boxSizing: "border-box",
  },
  title: {
    fontSize: "35px",
    marginBottom: "16px",
    fontWeight: "bold",
    textAlign: "center",
  },
  description: {
    fontSize: "21px",
    color: "#3A4750",
    textAlign: "center",
    marginBottom: "26px",
    fontWeight: 100,
    width: "80%",
    margin: "0 auto",
    lineHeight: "25.6px",
  },
  price: {
    color: "#3A4750",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "bold",
  },
  btnParent: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0 6px 0",
  },
  btn: {
    width: "453px",
    marginBottom: "16px",
  },
});

const WelcomeModal = ({ classes, t, open, onClose }) => {
  const { sub } = useUserInfo();

  const goToSub = () => {
    if (localStorage.getItem("flag")) localStorage.removeItem("flag");
    history.push("/suggester");
  };

  useEffect(() => {
    if (open) {
      setEventsMetadata({ customer_id: sub });
      logEvent("create_new_account");
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose ? onClose : null}
      aria-labelledby="upgrade-to-pro"
      className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={classes.noPadding}>
        <div className={classes.imageParent}>
          <img
            className={classes.communityImg}
            alt="upgrade to pro modal"
            src={`${process.env.PUBLIC_URL}/welcome-banner.svg`}
          />
          <img
            src={`${process.env.PUBLIC_URL}/close-white-icon.svg`}
            alt="close-icon"
            className={classes.closeIcon}
            onClick={onClose ? onClose : null}
          />
        </div>

        <div className={classes.content}>
          <Typography className={classes.title}>
            {t("Welcome to vert!")}
          </Typography>
          <Typography className={classes.description}>
            {t("Welcome Description")}
          </Typography>
          <div className={classes.btnParent}>
            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              size="large"
              onClick={goToSub}
            >
              {t("Get Matched With a Plan")}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation("dashboard")(
  withWidth()(withStyles(styles, { withTheme: true })(WelcomeModal)),
);
