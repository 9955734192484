import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Storage } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Avatar,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { updatePlanImage } from "../../../actions/user-plans-action";
import { clearAthlete, getAthlete } from "../../../actions/trail-head-action";
import { withTranslation } from "react-i18next";
import { setErrorMessage } from "../../../actions/user-plans-action";
import { getBase64, getDisplayErrorMessage } from "../../../lib/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getLevel } from "../../../lib/rates-helper";

const styles = (theme) => ({
  input: {
    display: "none",
  },
  portrait: {
    width: "100%",
    height: 200,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    bacgroundPosition: "center",
    padding: 0,
    position: "absolute",
    backgroundColor: "#bdc3c7",
    top: 0,
  },
  userAvatar: {
    width: "160px",
    height: "160px",
    backgroundColor: "#F9F9FB",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    top: "120px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
    boxSizing: "border-box",

    [theme.breakpoints.down("sm")]: {
      width: "160px",
      height: "160px",
      backgroundColor: "#F9F9FB",
      position: "absolute",
      left: "50%",
      transform: "translate(-50%, 0)",
      borderRadius: "50%",
      backgroundRepeat: "no-repeat",
      top: "120px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "4px",
      boxSizing: "border-box",
    },
  },
  userImage: {
    width: "100%",
    height: "100%",
  },
  actionsContainer: {
    padding: "15px 25px",
    position: "absolute",
    top: "0",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
  },
  iconParent: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transitionDuration: ".6s",
    position: "relative",

    "&:hover": {
      backgroundColor: "#FFF",
      transitionDuration: ".6s",
    },
  },
  actionIcon: {
    cursor: "pointer",
    fontSize: "16px",
    textAlign: "center",
  },
  popperStyle: {
    zIndex: 999,
  },
  textParent: {
    display: "flex",
    alignItems: "center",
  },
  loader: {
    marginLeft: "7px",
  },
  reverseIcon: {
    position: "absolute",
    left: "50%",
    transform: "translate(-55%, 0%) scale(-1, 1)",
  },
  levelBadge: {
    width: 40,
    right: "10px",
    bottom: "5px",
    height: 40,
    position: "absolute",
  },
});

const ProfileHeader = ({ classes, sub = null, t, updateData }) => {
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState("");
  const [localCoverImage, setCoverImage] = useState("");
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const flag = useRef("");

  const {
    auth: {
      currentUser: { attributes },
    },
    userPlans,
    trailHead,
  } = useSelector((state) => state);

  const { updatingImage, currentPlan } = userPlans;
  const { selectedAthlete } = trailHead;
  const { cognito_user_sub: mySub, coverImage, image } = currentPlan || {};

  const {
    coverImage: userCoverImage,
    cognito_user_sub,
    accumulatedDistance = 0,
    accumulatedElevation = 0,
    image: userImage,
  } = selectedAthlete || {};

  const getImage = async (image, type) => {
    try {
      const result = await Storage.get(image, {
        level: "public",
        cacheControl: "no-cache, no-store, must-revalidate",
        expires: 0,
        pragma: "no-cache",
      });
      if (type == "avatar") return setAvatar(result);
      setCoverImage(result);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (mySub === cognito_user_sub) {
      if (image && (flag.current == "" || flag.current == "image"))
        getImage(image, "avatar");
      if (coverImage && (flag.current == "" || flag.current == "coverImage"))
        getImage(coverImage, "cover");
      return;
    }

    if (userImage && (flag.current == "" || flag.current == "image"))
      getImage(userImage, "avatar");
    if (userCoverImage && (flag.current == "" || flag.current == "coverImage"))
      getImage(userCoverImage, "cover");
  }, [image, userImage, coverImage, userCoverImage]);

  const openClose = () => {
    clearAthlete();
    setOpen((open) => !open);
  };

  const handleImageFieldChange = (value, extension, key, currentExtension) => {
    flag.current = key;
    const { currentPlan } = userPlans || {};
    dispatch(
      updatePlanImage({
        cognito_user_sub: currentPlan.cognito_user_sub,
        [key]: value,
        [currentExtension]: extension && extension.toLowerCase(),
      }),
    );
  };

  const handleError = (err) => {
    dispatch(setErrorMessage(getDisplayErrorMessage(err, t)));
  };

  const handleInputChange = async (event, key, currentExtension) => {
    const maxSize = 5120000;
    if (event) {
      const { target } = event;
      if (target) {
        const { files } = target;
        if (files) {
          const selectedFile = files[0];
          try {
            if (selectedFile) {
              const { size } = selectedFile;
              if (size <= maxSize) {
                const { image: imageString, extension } = await getBase64(
                  selectedFile,
                  true,
                  key == "image" ? 150 : 500,
                );
                handleImageFieldChange(
                  imageString,
                  extension,
                  key,
                  currentExtension,
                );
              } else {
                handleError(
                  t("Sorry, your image needs to be smaller than maxSize", {
                    ns: "messages",
                  }),
                );
              }
            } else {
              handleError(t("Invalid File", { ns: "messages" }));
            }
          } catch (exp) {
            handleError(exp);
          }
        }
      }
    }
  };

  const userLevel = getLevel(accumulatedDistance, accumulatedElevation);

  return (
    <div
      className={classes.portrait}
      style={{
        backgroundImage: `url(${localCoverImage || ""})`,
      }}
    >
      <div className={classes.actionsContainer}>
        {sub == attributes.sub && (
          <div
            className={classes.iconParent}
            ref={anchorRef}
            onClick={openClose}
          >
            <MoreHorizIcon className={classes.actionIcon} />
          </div>
        )}
        <Popper
          className={classes.popperStyle}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={openClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    <MenuItem>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={(event) =>
                          handleInputChange(
                            event,
                            "coverImage",
                            "coverImageExtension",
                          )
                        }
                      />
                      <label htmlFor="contained-button-file">
                        <Tooltip
                          title={t("Minimum Photo", { ns: "trailhead" })}
                        >
                          <div className={classes.textParent}>
                            <Typography variant="body2">
                              {" "}
                              {t("Upload Cover", { ns: "trailhead" })}{" "}
                            </Typography>
                            {updatingImage && flag.current == "coverImage" && (
                              <div className={classes.loader}>
                                <CircularProgress
                                  variant="indeterminate"
                                  size={20}
                                />
                              </div>
                            )}
                          </div>
                        </Tooltip>
                      </label>
                    </MenuItem>
                    <MenuItem>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button"
                        type="file"
                        onChange={(event) =>
                          handleInputChange(event, "image", "imageExtension")
                        }
                      />
                      <label htmlFor="contained-button">
                        <div className={classes.textParent}>
                          <Typography variant="body2">
                            {" "}
                            {t("Upload Profile", { ns: "trailhead" })}{" "}
                          </Typography>
                          {updatingImage && flag.current == "image" && (
                            <div className={classes.loader}>
                              <CircularProgress
                                variant="indeterminate"
                                size={20}
                              />
                            </div>
                          )}
                        </div>
                      </label>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <div className={classes.userAvatar}>
        <>
          <Avatar
            src={avatar || `${process.env.PUBLIC_URL}/avatar-empty.png`}
            className={classes.userImage}
          />
          <img
            src={`${process.env.PUBLIC_URL}/trl${userLevel}-sm.svg`}
            className={classes.levelBadge}
          />
        </>
      </div>
    </div>
  );
};

export default withTranslation(
  "dashboard",
  "messages",
  "trailhead",
  "challenges",
)(withStyles(styles, { withTheme: true })(ProfileHeader));
