import React from "react";
import { palette } from "../../theme/palette";

const TrainingMenuIcon = ({
  width = 24,
  height = 24,
  activated = false,
  fill = null,
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 25 24`}
      fill={fill ? fill : activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 12.5C12.75 12.2239 12.9739 12 13.25 12H17.25C17.5261 12 17.75 12.2239 17.75 12.5V16.5C17.75 16.7761 17.5261 17 17.25 17H13.25C12.9739 17 12.75 16.7761 12.75 16.5V12.5ZM19.75 3H18.75V2C18.75 1.44772 18.3023 1 17.75 1C17.1977 1 16.75 1.44772 16.75 2V3H8.75V2C8.75 1.44772 8.30228 1 7.75 1C7.19772 1 6.75 1.44772 6.75 2V3H5.75C4.65 3 3.75 3.9 3.75 5V19C3.75 20.1 4.65 21 5.75 21H19.75C20.85 21 21.75 20.1 21.75 19V5C21.75 3.9 20.85 3 19.75 3ZM19.75 5V7H5.75V5H19.75ZM5.75 19V9H19.75V19H5.75Z"
        fill={fill ? fill : activated ? palette.primary : palette.black}
      />
    </svg>
  </>
);
export default TrainingMenuIcon;
