import React, { useEffect, useState } from "react";
import { CircularProgress, Box, makeStyles } from "@material-ui/core";
import { palette } from "../../../../theme/palette";
import SmallAvatar from "../../../generals/small-avatar";
import moment from "moment";
import {
  calculateTrainingLoad,
  getEnergyLevelRate,
} from "../../../../lib/rates-helper";
import useCalculateWorkoutsDone from "../../../../hooks/useCalculateWorkoutsDone";
import useActivities from "../../../../hooks/useActivities";
import useUserInfo from "../../../../hooks/useUserInfo";
import DateBetween from "../../../common/v2/DateBetween";
import { RunIcon, CheckIcon, BatteryIcon } from "../../../icons";
import CustomErrorBoundary from "../../../common/error/CustomErrorBoundary";

const currentRange = {
  "0": "week",
  "1": "month",
  "2": "plan",
};

export const CircleChart = () => {
  const classes = useStyles();
  const [trainingLoadRate, setTrainingLoadRate] = useState(0);
  const {
    plan,
    currentFilter,
    currentDate,
    toDate,
    currentTraining,
    chainedPlans,
  } = useUserInfo();

  const { runActivities } = useActivities();
  const workoutsDone = useCalculateWorkoutsDone(plan);
  const energyLevelRate = getEnergyLevelRate(plan, moment(currentDate));

  useEffect(() => {
    if (plan && currentTraining)
      setTrainingLoadRate(
        calculateTrainingLoad(
          plan,
          runActivities,
          currentDate,
          toDate,
          chainedPlans,
          currentTraining,
        ),
      );
  }, [currentDate, toDate, runActivities, plan]);

  return (
    <>
      <DateBetween />
      <CustomErrorBoundary>
        <Box display="flex" justifyContent="center" width="100%">
          <Box className={classes.container}>
            <CircularProgress
              variant="determinate"
              value={
                Math.floor(trainingLoadRate) <= 100
                  ? Math.floor(trainingLoadRate)
                  : 100
              }
              color="primary"
              thickness={4}
              size={196}
              classes={{ circle: classes.circle }}
              style={generalStyles(196, 4)}
            />

            <CircularProgress
              variant="determinate"
              value={workoutsDone}
              color="secondary"
              thickness={4.44}
              size={153}
              classes={{ circle: classes.circleDarkBlue }}
              style={generalStyles(153, 5)}
            />
            <CircularProgress
              variant="determinate"
              value={energyLevelRate.full[currentRange[currentFilter]].value}
              color="inherit"
              thickness={6.12}
              size={111}
              classes={{ circle: classes.circleBlue }}
              style={generalStyles(111, 6.12)}
            />
            <Box className={classes.innerCircle}>
              <SmallAvatar onTrailHead={false} level={false} customSize={68} />
            </Box>
            <Box className={classes.innerCircle} style={{ top: 10 }}>
              <RunIcon
                width={14}
                height={14}
                vHeight={20}
                vWidth={20}
                fill={palette.white}
              />
            </Box>
            <Box className={classes.innerCircle} style={{ top: 30 }}>
              <CheckIcon state="none" color={palette.white} />
            </Box>
            <Box className={classes.innerCircle} style={{ top: 51 }}>
              <BatteryIcon width={7} height={13} fill={palette.white} />
            </Box>
          </Box>
        </Box>
      </CustomErrorBoundary>
    </>
  );
};

const useStyles = makeStyles({
  circle: {
    strokeLinecap: "round",
  },
  circleBlue: {
    strokeLinecap: "round",
    stroke: palette.lightBlue,
  },
  circleDarkBlue: {
    strokeLinecap: "round",
    stroke: palette.darkBlue,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: 196,
    width: "100%",
    marginBottom: "20px",
  },
  innerCircle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

const generalStyles = (size, thickness) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) rotate(-90deg)",
  borderRadius: "50%",
  boxShadow: `inset 0 0 0 ${(thickness / 44) * size}px ${palette.lightGray}`,
});
