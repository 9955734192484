import React from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  Box,
  Typography,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import Collapse from "./Collapse";
import ExportWorkoutButton from "./ExportWorkoutButton";
import {
  LightWorkoutColors,
  WorkoutTypesLangKeys,
} from "../../../constants/workout-types";
import { convertMinutesToTimeLabel } from "../../../constants/estimated-times";
import TimerIcon from "../../icons/TimerIcon";
import unitsHook from "../../../hooks/unitsHook";
import workoutToText from "../../../lib/workoutToText";
import { useTranslation } from "react-i18next";
import useUserInfo from "../../../hooks/useUserInfo";

const WorkoutCollapse = ({
  workout = {},
  level = 0,
  parametrizedWorkout = [],
  emptyState = false,
}) => {
  const classes = useStyles();
  const units = unitsHook();
  const { t, i18n } = useTranslation("training");
  const { language } = i18n;
  const { isGarminConnected } = useUserInfo();

  if (emptyState)
    return (
      <Collapse
        title={t("training.workoutType.longRun")}
        Icon={TimerIcon}
        subHeading={"15min"}
        disabled={true}
      />
    );

  if (!workout) return <></>;

  return (
    <Collapse
      title={
        workout.workoutType === "UserCreated"
          ? workout.userCreatedType
          : t(WorkoutTypesLangKeys[workout.workoutType])
      }
      Icon={TimerIcon}
      subHeading={convertMinutesToTimeLabel(
        Array.isArray(workout.estimatedTime) &&
          !isNaN(+workout.estimatedTime[level])
          ? +workout.estimatedTime[level]
          : +workout.estimatedTime,
      )}
      pin={{
        active: true,
        color: LightWorkoutColors[workout.workoutType],
      }}
      disabled={!workout}
      isExpand={Boolean(parametrizedWorkout && parametrizedWorkout.length > 0)}
    >
      {parametrizedWorkout && parametrizedWorkout.length > 0 && (
        <List>
          <ListItem
            className={classes.listItemCore}
            classes={{ gutters: classes.listItemGutters }}
          >
            <ListItemText>
              <Typography
                component={"span"}
                className={classes.p2}
                dangerouslySetInnerHTML={{
                  __html: workoutToText(parametrizedWorkout, units, language),
                }}
              />
            </ListItemText>
          </ListItem>
        </List>
      )}

      {workout && parametrizedWorkout && parametrizedWorkout.length > 0 && (
        <Box py={2}>
          <ExportWorkoutButton
            workout={workout}
            parametrizedWorkout={parametrizedWorkout}
            disabled={!isGarminConnected}
          />
        </Box>
      )}
    </Collapse>
  );
};

const useStyles = makeStyles({
  p2: {
    fontFamily: "Karla",
    fontSize: 14,
  },
  listItemCore: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  listItemGutters: {
    padding: 0,
  },
});

WorkoutCollapse.propTypes = {
  workout: PropTypes.object,
  level: PropTypes.number,
  parametrizedWorkout: PropTypes.array,
  emptyState: PropTypes.bool,
};

export default WorkoutCollapse;
