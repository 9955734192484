import React from "react";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import promise from "redux-promise-middleware";
import { Auth, Hub, Amplify } from "aws-amplify";

import { RoutingMiddleware, Routes } from "./lib/routing-middleware";

import browserHistory from "./lib/history-helper";
import reducer from "./reducers";
import Layout from "./components/layout";
import { Capacitor } from "@capacitor/core";
import AppUrlListener from "./lib/appurllistener";
import awsmobile from "./aws-exports";
import awsmobileprd from "./aws-exports-prd";
import awsmobiledev from "./aws-exports-dev";
import { amplitudeInit, firebaseInit } from "./lib/events-helper";
import { LocalForageStorage } from "./lib/local-forage-storage";
//import FirebaseDynamicLinksService from "./services/firebase-dynamic-links-service";
import { Sortable, Swap } from "sortablejs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

Sortable.mount(new Swap());

const aws = Capacitor.isNativePlatform()
  ? process.env.REACT_APP_RELEASE_STAGE === "development"
    ? awsmobiledev
    : awsmobileprd
  : awsmobile;

//const aws = awsmobileprd;

Amplify.configure({
  ...aws,
  storage: new LocalForageStorage(),
  oauth: {
    domain: aws.oauth.domain,
    scope: aws.oauth.scope,
    redirectSignIn: Capacitor.isNativePlatform()
      ? "run.vert.app://"
      : `${window.location.origin}/`,
    redirectSignOut: Capacitor.isNativePlatform()
      ? "run.vert.app://"
      : `${window.location.origin}/`,
    responseType: "code",
  },
});

Hub.listen("auth", async ({ payload }) => {
  if (Capacitor.isNativePlatform()) {
    console.log("auth Event", payload.event, JSON.stringify(payload.data));
  }
});

Auth.configure({
  storage: new LocalForageStorage(),
  oauth: {
    domain: aws.oauth.domain,
    scope: aws.oauth.scope,
    redirectSignIn: Capacitor.isNativePlatform()
      ? "run.vert.app://"
      : `${window.location.origin}/`,
    redirectSignOut: Capacitor.isNativePlatform()
      ? "run.vert.app://"
      : `${window.location.origin}/`,
    responseType: "code",
  },
});

const middlewares = [promise, thunk];

if (process.env.REACT_APP_RELEASE_STAGE === "development") {
  middlewares.push(createLogger());
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
middlewares.push(RoutingMiddleware);
const middleware = applyMiddleware(...middlewares);
const store = createStore(reducer, composeEnhancers(middleware));

firebaseInit();
amplitudeInit();

const App = () => {
  const {
    i18n: { language },
  } = useTranslation();
  dayjs.locale(language);
  return (
    <Provider store={store}>
      <Router history={browserHistory}>
        <AppUrlListener></AppUrlListener>
        <Switch>
          <Route path={Routes.Landing} component={Layout} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
