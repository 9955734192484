import ActionTypes from "../constants/action-types";

export const setCurrentText = (text) => ({
  type: ActionTypes.COMMUNITY_SET_CURRENT_TEXT,
  payload: text,
});

export const setCurrentImage = (image, extension) => ({
  type: ActionTypes.COMMUNITY_SET_CURRENT_IMAGE,
  payload: { image, extension },
});

export const setCurrentGoal = (goal) => ({
  type: ActionTypes.COMMUNITY_SET_CURRENT_GOAL,
  payload: goal,
});

export const setCurrentBadge = (badge) => ({
  type: ActionTypes.COMMUNITY_SET_CURRENT_BADGE,
  payload: badge,
});

export const setCurrentEpicRace = (epicRace) => ({
  type: ActionTypes.COMMUNITY_SET_CURRENT_EPIC_RACE,
  payload: epicRace,
});

export const setCurrentActivity = (activity) => ({
  type: ActionTypes.COMMUNITY_ADD_POST_ACTIVITY,
  payload: activity,
});

export const setPostType = (type) => ({
  type: ActionTypes.COMMUNITY_SET_POST_TYPE,
  payload: type,
});

export const setPostTag = (tag) => ({
  type: ActionTypes.COMMUNITY_ADD_POST_TAG,
  payload: tag,
});

export const resetPostCreationState = () => ({
  type: ActionTypes.COMMUNITY_RESET_POST_CREATION_STATE,
});
