import React from "react";
import {
  Paper,
  Box,
  MobileStepper,
  Typography,
  Button,
} from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTranslation } from "react-i18next";
import history from "../../../lib/history-helper";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const OnboardingIntroView = () => {
  const { t } = useTranslation("onboarding");
  const theme = useTheme();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleLogin = () => {
    history.push("/auth/log-in");
  };

  const handleSignUp = () => {
    history.push("/auth/sign-up");
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box className={classes.container}>
      <Box>
        <img
          src={`${process.env.PUBLIC_URL}/vertrun-logo-wide.png`}
          alt="logo"
          className={classes.logo}
        />
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          interval={5000}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <Box key={step.label} className={classes.imageContainer}>
              {Math.abs(activeStep - index) <= 2 ? (
                <img
                  className={classes.image}
                  src={step.imgPath}
                  alt={step.title}
                />
              ) : null}
            </Box>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
      <Box className={classes.boxContent}>
        <Paper square elevation={0} className={classes.textContainer}>
          <Typography className={classes.title}>
            {t(images[activeStep].title)}
          </Typography>
          <Typography className={classes.content}>
            {t(images[activeStep].content)}{" "}
            <span className={classes.subContent}>
              {t(images[activeStep].subContent)}
            </span>
          </Typography>
          <Box className={classes.mobileStepperContainer}>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              className={classes.mobileStepper}
            />
            <Box className={classes.buttonContainer}>
              <Button
                variant="outlined"
                onClick={handleLogin}
                className={classes.logInButton}
              >
                {t("onboarding.intro.loginButton")}
              </Button>
              <Button
                variant="contained"
                onClick={handleSignUp}
                className={classes.signUpButton}
              >
                {t("onboarding.intro.signUnButton")}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default OnboardingIntroView;

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    maxHeight: "100vh",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    overflow: "hidden",
  },
  logo: {
    position: "absolute",
    top: "calc(env(safe-area-inset-top) + 20px)",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1,
    width: "80px",
  },
  imageContainer: {
    flex: 1,
    height: "50vh",
    display: "flex",
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "20%",
  },
  boxContent: {
    flex: 1,
    height: "50vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#FAFAFA",
    padding: "0 24px",
    overflowY: "auto",
  },
  textContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    padding: "22px 0",
    backgroundColor: "#FAFAFA",
    justifyContent: "space-between",
  },
  title: {
    fontFamily: "Oswald",
    fontSize: "36px",
    fontWeight: 600,
    color: "#12151B",
    textTransform: "uppercase",
  },
  content: {
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 400,
    gap: "8px",
    color: "#12151B",
  },
  subContent: {
    fontFamily: "Karla",
    fontSize: "16px",
    fontWeight: 700,
    color: "#12151B",
  },
  mobileStepperContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#FAFAFA",
  },
  mobileStepper: {
    alignSelf: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "5px",
  },
  logInButton: {
    color: "#0B2B5C",
    borderColor: "#0B2B5C",
    borderWidth: "2px",
    borderRadius: "4px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    width: "45%",
    textTransform: "initial",
  },
  signUpButton: {
    backgroundColor: "#0B2B5C",
    color: "#FFFFFF",
    borderRadius: "4px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    width: "45%",
    textTransform: "initial",
  },
}));

const images = [
  {
    title: "onboarding.intro.title1",
    content: "onboarding.intro.content1",
    subContent: "onboarding.intro.subContent1",
    imgPath: `${process.env.PUBLIC_URL}/onboarding-img/kirstenOnboarding.webp`,
  },
  {
    title: "onboarding.intro.title2",
    content: "onboarding.intro.content2",
    subContent: "onboarding.intro.subContent2",
    imgPath: `${process.env.PUBLIC_URL}/onboarding-img/alexOnboarding.webp`,
  },
];
