import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Storage } from "aws-amplify";
import { convertUnits, round } from "../../lib/units-helper";

const styles = (theme) => ({
  imgContainer: {
    position: "relative",

    display: "flex",
    alignItems: "center",
  },

  badge: {
    width: 120,
  },

  verifiedImg: {
    position: "absolute",
    top: 20,
    right: 20,
  },

  dateContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  date: {
    fontFamily: "Avenir",
    fontWeight: 800,
    fontSize: 14,
    textAlign: "center",
    color: "#202020",
    [theme.breakpoints.up("md")]: {
      fontSize: 11,
    },
  },

  textContainer: {
    padding: "0 16px 16px",
    width: "100%",
  },

  totalsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },

  totalsTextSelected: {
    fontFamily: "Avenir",
    fontWeight: 800,
    fontSize: 14,
    textAlign: "center",
    color: "#202020",
    margin: "0 2px",

    [theme.breakpoints.up("md")]: {
      fontSize: 11,
    },
  },

  totalsText: {
    fontFamily: "Avenir",
    fontSize: 14,
    textAlign: "center",
    color: "#202020",
    margin: "0 2px",

    [theme.breakpoints.up("md")]: {
      fontSize: 11,
    },
  },

  container: {
    display: "flex",
    overflowX: "auto",
    cursor: "pointer",
  },

  activityStats: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  activityStat: {
    textAlign: "center",
  },

  activityStatValue: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: "Avenir",
  },

  acitivityStatLabel: {
    fontWeight: "normal",
    fontSize: 12,
    fontFamily: "Avenir",
  },

  vDivider: {
    borderLeft: "1px solid #E0E0E0",
    height: 24,
  },

  achieved: {
    fontSize: 14,
    fontFamily: "Avenir",
    background: "rgba(255, 87, 34, 0.24)",
    width: "fit-content",
    padding: "1px 5px",
    marginBottom: 4,
  },

  share: {
    color: "#E16327",
    fontSize: 14,
    textAlign: "right",
    marginBottom: 4,
  },

  name: {
    fontWeight: 900,
    fontSize: 18,
    textTransform: "uppercase",
  },
});

class FinishedChallengeH extends Component {
  state = {
    badgeUrl: null,
    badgeLoading: false,
  };

  async componentWillReceiveProps(nextProps) {
    const { challenge } = nextProps;
    const { badge } = challenge || {};
    const { badgeUrl } = this.state;
    if (badge && !badgeUrl) {
      this.getBadgeUrl(badge);
    }
  }

  getBadgeUrl = async (image) => {
    const { t } = this.props;
    const { badgeUrl } = this.state;

    if (image && !badgeUrl) {
      try {
        const result = await Storage.get(image, {
          level: "public",
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              badgeUrl: result,
              badgeLoading: false,
            }),
          100,
        );
      } catch (exp) {
        // onError(
        new Error(
          t(
            "Oops, we could not load your profile picture. Give it another try",
            { ns: "messages" },
          ),
        );
        //);
        throw exp;
      }
    } else if (!image) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            badgeLoading: false,
          }),
        100,
      );
    }
  };

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    const {
      classes,
      yourEntry,
      t,
      i18n,
      onShareChallenge,
      units,
      width,
    } = this.props;

    const { challengeData } = yourEntry || {};

    const { start_date, end_date, badge, goal_type } = challengeData || {};

    const { language } = i18n;

    const momentLocale = moment().locale(language);

    const isInMobileView = width === "md" || width === "sm" || width === "xs";

    const h =
      yourEntry && yourEntry.totals && yourEntry.totals.time
        ? Math.floor(yourEntry.totals.time / 3600)
        : 0;
    const m =
      yourEntry && yourEntry.totals && yourEntry.totals.time
        ? Math.floor((yourEntry.totals.time % 3600) / 60)
        : 0;
    const timeStr = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;

    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const name =
      yourEntry && yourEntry.challengeData && yourEntry.challengeData.name
        ? yourEntry.challengeData.name[languageDetector]
          ? yourEntry.challengeData.name[languageDetector]
          : yourEntry.challengeData.name["en"]
        : "";

    return (
      <>
        {Object.keys(challengeData || {}).length && (
          <div
            className={classes.container}
            onClick={() =>
              ((yourEntry.challengeData.goal && yourEntry.finished) ||
                (!yourEntry.challengeData.goal &&
                  yourEntry.activities &&
                  yourEntry.activities.length)) &&
              onShareChallenge &&
              onShareChallenge(yourEntry)
            }
          >
            <div className={classes.imgContainer}>
              <img
                className={classes.badge}
                src={badge || ""}
                alt="badge"
                style={{
                  opacity:
                    (yourEntry.challengeData.goal && yourEntry.finished) ||
                    (!yourEntry.challengeData.goal &&
                      yourEntry.activities &&
                      yourEntry.activities.length)
                      ? 1
                      : 0.5,
                }}
              />

              {/*(yourEntry.challengeData.goal && yourEntry.finished) ||
          (!yourEntry.challengeData.goal &&
            yourEntry.activities &&
            yourEntry.activities.length) ? (
            <img
              alt="finished"
              className={classes.verifiedImg}
              src={`${process.env.PUBLIC_URL}/icon-verified.svg`}
            />
          ) : (
            ""
          )*/}
            </div>
            <div
              className={classes.textContainer}
              style={{
                opacity:
                  (yourEntry.challengeData.goal && yourEntry.finished) ||
                  (!yourEntry.challengeData.goal &&
                    yourEntry.activities &&
                    yourEntry.activities.length)
                    ? 1
                    : 0.5,
              }}
            >
              {onShareChallenge && (
                <Typography variant={"body1"} className={classes.share}>
                  {t("Share Badge")}
                </Typography>
              )}

              <Typography variant={"body1"} className={classes.achieved}>
                {t("Achieved")}
              </Typography>

              <Typography variant={"body1"} className={classes.name}>
                {name}
              </Typography>

              {yourEntry && yourEntry.totals ? (
                <div className={classes.activityStats}>
                  <div className={classes.activityStat}>
                    <Typography
                      variant="body1"
                      className={classes.activityStatValue}
                    >
                      {t("valueUnit", {
                        value: round(yourEntry.totals.distance / 1000, 1),
                        unit: units === "miles" ? ` ${t("miles")}` : "km",
                      })}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.activityStatLabel}
                    >
                      {t("distance")}
                    </Typography>
                  </div>

                  <div className={classes.vDivider}></div>

                  <div className={classes.activityStat}>
                    <Typography
                      variant="body1"
                      className={classes.activityStatValue}
                    >
                      {t("valueUnit", {
                        value: yourEntry.totals.elevation,
                        unit: units === "miles" ? ` ${t("feet")}` : "m",
                      })}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.activityStatLabel}
                    >
                      {t("elevation")}
                    </Typography>
                  </div>

                  <div className={classes.vDivider}></div>

                  <div className={classes.activityStat}>
                    <Typography
                      variant="body1"
                      className={classes.activityStatValue}
                    >
                      {timeStr}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.activityStatLabel}
                    >
                      {t("time")}
                    </Typography>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

FinishedChallengeH.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  challenge: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation("challenges")(
  withWidth()(withStyles(styles, { withTheme: true })(FinishedChallengeH)),
);
