import React from "react";
import { palette } from "../../theme/palette";

const NotificationIcon = ({ width = 24, height = 24, activated = false }) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      fill={activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.12 23C13.44 23 14.52 21.9846 14.52 20.7436H9.71997C9.71997 21.9846 10.8 23 12.12 23ZM19.4189 16.3637C19.3547 16.2774 19.32 16.1727 19.32 16.0651V10.5897C19.32 7.12615 17.364 4.22667 13.92 3.45949V2.69231C13.92 1.7559 13.116 1 12.12 1C11.124 1 10.32 1.7559 10.32 2.69231V3.45949C6.88797 4.22667 4.91997 7.11487 4.91997 10.5897V16.0468C4.91997 16.1656 4.87772 16.2804 4.80077 16.3709L3.19663 18.2561C3.0697 18.4053 3 18.5947 3 18.7906C3 19.2461 3.36927 19.6154 3.82479 19.6154H20.1526C20.6206 19.6154 21 19.236 21 18.768C21 18.5857 20.9412 18.4082 20.8323 18.2619L19.4189 16.3637ZM17.5 18H6.5H5.8737C5.71396 18 5.61869 17.822 5.70729 17.6891L6.41603 16.626C6.47078 16.5438 6.5 16.4473 6.5 16.3486V10.5C6.5 7.70205 8.34109 5 12.12 5C15.8988 5 17.5 7.7918 17.5 10.5897V16.3486C17.5 16.4473 17.5292 16.5438 17.584 16.626L18.2927 17.6891C18.3813 17.822 18.286 18 18.1263 18H17.5Z"
        fill={activated ? palette.primary : palette.black}
      />
    </svg>
  </>
);
export default NotificationIcon;
