import React from "react";
import FaqsComponent from "./faqs/components/faqs-component";

import NavbarSettings from "./common/navbar-settings";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  settingsAccount: {
    background: "#f9f9f9",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  pageWrapper: {
    background: "rgba(250, 250, 250, 1)",
    height: "100%",
    width: "100%",
  },
  header2: {
    alignItems: "flex-start",
    background: "rgba(254, 255, 255, 1)",
    boxShadow: " 0px 1px 15px 0px rgba(18, 21, 27, 0.05)",
    display: "inline-flex",
    flex: "0 0 auto",
    width: "100%",
    flexDirection: "column",
    position: "relative",
  },
}));
const Faqs = () => {
  let styles = useStyles();
  const { t } = useTranslation();
  return <NavbarSettings component={<FaqsComponent />} text={t("FAQ's")} />;
};
export default Faqs;
