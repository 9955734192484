import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Typography, Button } from "@material-ui/core";
import history from "../../lib/history-helper";
import { logEvent } from "../../lib/events-helper";

const styles = (theme) => ({
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  noPadding: {
    padding: "0px !important",
    maxWidth: "300px",
  },
  dialogPaper: {
    borderRadius: "12px",
  },
  imageParent: {
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    top: "5%",
    right: "5%",
  },
  content: {
    padding: "12px",
    boxSizing: "border-box",
  },
  title: {
    fontSize: "25px",
    marginBottom: "10px",
    fontWeight: "bold",
    textAlign: "center",
  },
  description: {
    fontSize: "14px",
    color: "#3A4750",
    textAlign: "center",
    marginBottom: "10px",
  },
  listElement: {
    fontSize: "14px",
    color: "#3A4750",
    marginLeft: "5px",
  },
  price: {
    color: "#3A4750",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "bold",
  },
  btnParent: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0 6px 0",
  },
  dot: {
    width: "3px",
    height: "3px",
    borderRadius: "50%",
    backgroundColor: "#3A4750",
  },
  itemParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  list: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  listSubtitle: {
    fontSize: "14px",
    color: "#3A4750",
    textAlign: "center",
  },
});

const UpgradeToProModal = ({
  classes,
  t,
  open,
  onClose,
  title = "Get Coach feedback",
  list = false,
  price = true,
  htmlContent = false,
  btnText = "Upgrade to Pro",
  content = "Paywall Content 1",
}) => {
  const goToSub = () => history.push("/subscription/explore");

  useEffect(() => {}, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose ? onClose : null}
      aria-labelledby="upgrade-to-pro"
      className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={classes.noPadding}>
        <div className={classes.imageParent}>
          <img
            className={classes.communityImg}
            alt="upgrade to pro modal"
            src={`${process.env.PUBLIC_URL}/upgrade-pro-banner.svg`}
          />
          <img
            src={`${process.env.PUBLIC_URL}/close-white-icon.svg`}
            alt="close-icon"
            className={classes.closeIcon}
            onClick={onClose ? onClose : null}
          />
        </div>

        <div className={classes.content}>
          {!list ? (
            <>
              <Typography className={classes.title}>{t(title)}</Typography>

              {!htmlContent ? (
                <Typography className={classes.description}>
                  {t(
                    "And personalize your training plan to your fitness level,  lifestyle, goals and more",
                  )}
                </Typography>
              ) : (
                <Typography
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html: t(content),
                  }}
                />
              )}

              {price && (
                <Typography className={classes.price}>
                  {t("Starts at $3.5 USD/week")}
                </Typography>
              )}
            </>
          ) : (
            <>
              <Typography className={classes.title}>{t(title)}</Typography>

              <Typography
                className={classes.listSubtitle}
                dangerouslySetInnerHTML={{
                  __html: t("Paywall Content 2"),
                }}
              ></Typography>

              <ul className={classes.list}>
                <li>
                  <div className={classes.itemParent}>
                    <div className={classes.dot}></div>
                    <Typography className={classes.listElement}>
                      {t("Paywall Item 1")}
                    </Typography>
                  </div>
                </li>

                <li>
                  <div className={classes.itemParent}>
                    <div className={classes.dot}></div>
                    <Typography className={classes.listElement}>
                      {t("Paywall Item 2")}
                    </Typography>
                  </div>
                </li>

                <li>
                  <div className={classes.itemParent}>
                    <div className={classes.dot}></div>
                    <Typography className={classes.listElement}>
                      {t("Paywall Item 3")}
                    </Typography>
                  </div>
                </li>

                <li>
                  <div className={classes.itemParent}>
                    <div className={classes.dot}></div>
                    <Typography className={classes.listElement}>
                      {t("Paywall Item 4")}
                    </Typography>
                  </div>
                </li>

                <li>
                  <div className={classes.itemParent}>
                    <div className={classes.dot}></div>
                    <Typography className={classes.listElement}>
                      {t("Paywall Item 5")}
                    </Typography>
                  </div>
                </li>
              </ul>
            </>
          )}

          <div className={classes.btnParent}>
            <Button
              color="primary"
              variant="contained"
              size="medium"
              onClick={goToSub}
            >
              {t(btnText)}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation("dashboard")(
  withWidth()(withStyles(styles, { withTheme: true })(UpgradeToProModal)),
);
