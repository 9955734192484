import React, { useEffect, useState, memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Drawer, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import history from "../../../../lib/history-helper";
import { useSelector, useDispatch } from "react-redux";
import GroupsCard from "./components/groups-card";
import { withTranslation } from "react-i18next";
import SmallAvatar from "../../../generals/small-avatar";
import { useParams } from "react-router-dom";
import { getAthlete } from "../../../../actions/trail-head-action";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  drawerRoot: {
    backgroundColor: "#F9F9FB",
    maxHeight: "100%",
    height: "100%",
    maxWidth: "500px",
    minWidth: "500px",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "100vw",
      minWidth: "100vw",
    },
  },
  header: {
    display: "flex",
    backgroundColor: "#FFF",
    padding: "10px 16px 10px 16px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subtitle: {
    textAlign: "center",
    color: "#E16327",
    marginTop: "8px",
  },
  bold: {
    fontWeight: "bold",
  },
  avatar: {
    width: "44px",
    height: "44px",
  },
  content: {
    padding: "16px 0",
  },
  noGroupsParent: {
    display: "flex",
    margin: "16px",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    textAlign: "center",
    marginTop: 10,
  },
  goBack: {
    cursor: "pointer",
  },
});

const AllGroupes = ({ classes, t }) => {
  const { trailHead } = useSelector((state) => state);
  const { publicGroups } = trailHead;
  const { selectedAthlete, loadingAthlete } = trailHead || {};
  const { cognito_user_sub } = selectedAthlete || {};
  const { publicGroups: userPublicGroups = [] } = selectedAthlete || {};
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const params = useParams();

  const goBack = () => history.push(`/trailhead/profile/${cognito_user_sub}`);

  useEffect(() => {
    setOpen(true);
  }, []);

  const joinedGroups = () => {
    const groups = (userPublicGroups || []).map((userGroup) => {
      const match = publicGroups.find((item) => item.id == userGroup);
      return match;
    });
    return groups || [];
  };

  useEffect(() => {
    if (!selectedAthlete && !Object.keys(selectedAthlete || {}).length)
      dispatch(getAthlete(params.id));
  }, []);

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        onClose={goBack}
        classes={{
          paper: classes.drawerRoot,
        }}
      >
        <div className={classes.header}>
          <SmallAvatar />
          <div>
            <Typography variant="body1" className={classes.bold}>
              {t("Groupes").toUpperCase()}
            </Typography>
          </div>
          <div onClick={goBack} className={classes.goBack}>
            <Close />
          </div>
        </div>
        <div className={classes.content}>
          {loadingAthlete ? (
            <div className={classes.loading}>
              <CircularProgress variant="indeterminate" />
            </div>
          ) : userPublicGroups && userPublicGroups.length ? (
            joinedGroups().map((item, index) => (
              <GroupsCard {...item} key={index} />
            ))
          ) : (
            <div className={classes.noGroupsParent}>
              <Typography variant="body1">{t("No Groupes")}</Typography>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("trailhead")(memo(AllGroupes)),
);
