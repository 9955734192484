import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";

import "./i18n";
import CustomErrorBoundary from "./components/common/error/CustomErrorBoundary";

import App from "./app";
import * as serviceWorker from "./serviceWorker";
import { TagManagerArgs } from "./lib/tag-manager-args";
import "@capacitor/core";

const rootElement = document.getElementById("root");
const tagManagerArgs = TagManagerArgs();

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <CustomErrorBoundary>
    <Suspense fallback={<div />}>
      <App />
    </Suspense>
  </CustomErrorBoundary>,
  rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
