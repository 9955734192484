import { useEffect, useState } from "react";
import SubscriptionsService from "../services/subscriptions-service";
import useUserInfo from "./useUserInfo";
import { Capacitor } from "@capacitor/core";
import { useDispatch } from "react-redux";
import history from "../lib/history-helper";
import { Routes } from "../lib/routing-middleware";
import { updatePlan } from "../actions/user-plans-action";
import moment from "moment";
import bugsnagClient from "../lib/bugsnag-client";
import {
  clearErrorMessage,
  clearSuccessMessage,
  setErrorMessage,
  setSuccessMessage,
} from "../actions/subscriptions-action";
import { useTranslation } from "react-i18next";
import useInAppPurchasesService from "./useInAppPurchasesService";

const useCheckout = () => {
  const dispatch = useDispatch();
  const {
    attributes,
    planToSelect,
    currentTraining,
    sub,
    subscriptions,
  } = useUserInfo();
  const { errorMessage, successMessage } = subscriptions;
  const { name = "", family_name = "", email = "" } = attributes || {};
  const { t } = useTranslation();
  const [cbInstance, setCbInstance] = useState(null);
  const [isPurchasing, setIsPurchasing] = useState(false);

  const { purchaseAppSubscription } = useInAppPurchasesService();

  const handleHideMessage = () => {
    dispatch(clearErrorMessage());
    dispatch(clearSuccessMessage());
  };

  const redirectToFinalized = () => {
    if (cbInstance && !Capacitor.isNativePlatform()) {
      cbInstance.closeAll();
    }

    history.push(Routes.Subscription);
    setIsPurchasing(false);

    if (planToSelect || currentTraining) {
      dispatch(
        updatePlan(
          {
            cognito_user_sub: sub,
            latestSubscriptionFinalizedTime: moment().unix(),
            showRaffle: true,
          },
          false,
        ),
      );
    } else {
      bugsnagClient.notify(
        new Error(
          `Someone subscribed without having a current plan. Needs to be checked on Chargebee, ${name},${family_name},${email}`,
        ),
      );
    }
  };

  const handleCheckout = async (product) => {
    setIsPurchasing(true);
    if (Capacitor.isNativePlatform()) {
      await purchaseAppSubscription(product, redirectToFinalized);
    } else {
      handleSelectSubscriptionOption(product);
    }
  };
  const handleSelectSubscriptionOption = async (option) => {
    if (typeof window !== "undefined") {
      cbInstance.openCheckout({
        hostedPage: async () => {
          return await SubscriptionsService.openCheckout(option, {
            first_name: name,
            last_name: family_name,
            email: email,
          });
        },
        success: () => {
          redirectToFinalized();
          dispatch(
            setSuccessMessage(
              t("Your subscription has been created successfully."),
            ),
          );
        },
        close: () => {},
        error: (message) => {
          dispatch(setErrorMessage(message));
        },
      });
    }
  };

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      setCbInstance(
        window.Chargebee.init({
          site: process.env.REACT_APP_CHARGEBEE_SITE,
          enableGATracking: true,
          enableFBQTracking: true,
        }),
      );
    }

    return () => {
      if (cbInstance) {
        cbInstance.closeAll();
      }
    };
  }, [t]);

  return {
    isPurchasing,
    errorMessage,
    successMessage,
    handleHideMessage,
    handleCheckout,
  };
};

export default useCheckout;
