import React from "react";
import { Link } from "react-router-dom";
import { notificationPatch } from "../../../actions/notifications-action";
import {
  Typography,
  Box,
  Divider,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import "moment/min/locales";
import { useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import { palette } from "../../../theme/palette";

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
  notificationContainer: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 12,
    paddingLeft: 12,
    alignItems: "self-start",
    flexDirection: "column",
  },
  notificationContainerRead: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 12,
    paddingLeft: 12,
    borderBottom: "1px solid #C4C4C4",
    background: "rgba(255, 216, 197, 0.3)",
    display: "flex",
    alignItems: "self-start",
    flexDirection: "column",
  },
  unread: {
    marginLeft: "auto",
  },
  notificationText: {
    fontFamily: "Karla",
    color: "#2E3A59",
    fontSize: 13,
    textAlign: "left",
  },
  notificationDate: {
    fontWeight: 700,
    fontSize: 13,
    color: "#FF6327",
    textAlign: "left",
    marginLeft: "4px",
    color: palette.black,
  },
  textRow: {
    display: "flex",
  },
});

const NotificationItem = ({ notification, index }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const notificationTypeText = {
    post: "Your Post",
    comment: "Your Comment",
    reply: "Your Reply",
  };

  const notificationLink =
    notification.notificationType &&
    notification.notificationType === "trailhead"
      ? `/trailhead/post/${notification.notificationContent.post}`
      : "dashboard";

  const handleNotificationClick = (notification) => {
    if (notification) {
      dispatch(notificationPatch({ ...notification, isRead: true }));
    }
  };

  return (
    <>
      <Link className={classes.link} to={notificationLink} key={index}>
        <ListItem
          button
          onClick={() => handleNotificationClick(notification)}
          className={
            notification.isRead
              ? classes.notificationContainer
              : classes.notificationContainerRead
          }
        >
          <Box className={classes.textRow}>
            <Typography
              className={classes.notificationText}
              data-i18n="[html]content.body"
            >
              {t(
                `notificationItem_${notification.notificationType}_${notification.notificationContent.action}_${notification.notificationContent.level}`,
                {
                  name: notification.notificationContent.cognito_user_name,
                },
              )}
            </Typography>
            <Typography variant="h6" className={classes.notificationDate}>
              {notification.notificationContent.postTitle
                ? notification.notificationContent.postTitle
                : t(
                    notificationTypeText[notification.notificationContent.level]
                      ? notificationTypeText[
                          notification.notificationContent.level
                        ]
                      : "",
                  )}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" style={{ color: palette.darkGray }}>
              {`${moment(notification.created_at)
                .locale("en")
                .fromNow()}`}
            </Typography>
          </Box>
        </ListItem>
        <Divider
          style={{
            width: "95%",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </Link>
    </>
  );
};
export default NotificationItem;
