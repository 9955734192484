import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  main: {
    backgroundColor: "#171821",
    borderRadius: "4px",
    padding: "9px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "fit-content",
  },
  dot: {
    background: "#FCB859",
    width: 5,
    height: 5,
    borderRadius: "50%",
    marginRight: "10px",
  },
  text: {
    color: "#FFF",
  },
});

const ComingSoon = () => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Box className={classes.dot} />
      <Typography variant="body2" className={classes.text}>
        Coming Soon
      </Typography>
    </Box>
  );
};

export default ComingSoon;
