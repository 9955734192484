import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button, withWidth } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import RouterLink from "./router-link";

const styles = (theme) => ({
  completeContainer: {
    background: "#fff",
    borderRadius: 12,
    margin: "10px 0 10px 10px",
    width: "calc(100% - 20px)",
  },

  completeContainerLg: {
    background: "#fff",

    display: "flex",
    margin: "10px 0 40px",
    width: "100%",
    borderRadius: 0,
  },

  completeImage: {
    borderRadius: "12px 12px 0 0",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: 124,
  },

  completeImageLg: {
    backgroundSize: "cover",

    width: "200%",
    height: "initial",
    margin: "20px 80px 20px 20px",
    backgroundPosition: "top",
    borderRadius: 12,
    order: 2,
  },

  completeTitle: {
    fontWeight: 500,
    fontSize: 18,
    margin: "16px 16px 0",
  },

  completeText: {
    fontWeight: 400,
    fontSize: 14,
    color: "#9E9E9E",
    padding: "10px 16px 16px",
  },

  completeButtonCont: {
    textAlign: "center",
    paddingBottom: 16,
  },

  completeButton: {
    boxShadow: "none",
    borderRadius: 50,
  },

  textContainerLg: {
    order: 1,
    margin: 80,
  },
});

class CompleteProfile extends Component {
  render() {
    const {
      classes,
      t,
      hasCurrentTraining,
      userType,
      isLg = false,
    } = this.props;

    return (
      <div className={classes.section}>
        <div
          className={
            isLg ? classes.completeContainerLg : classes.completeContainer
          }
        >
          <div
            className={isLg ? classes.completeImageLg : classes.completeImage}
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/complete_background.png)`,
            }}
          ></div>

          <div
            className={isLg ? classes.textContainerLg : classes.textContainer}
          >
            <Typography variant="body1" className={classes.completeTitle}>
              {t("Welcome to Vert!")}
            </Typography>

            <Typography variant="body1" className={classes.completeText}>
              {hasCurrentTraining
                ? t("complete_text")
                : userType === 3
                ? t("complete_text_no_training_3")
                : userType === 1
                ? t("complete_text_no_training_1")
                : t("complete_text_no_training")}
            </Typography>

            <div className={classes.completeButtonCont}>
              {hasCurrentTraining ? (
                <RouterLink to="/complete-profile">
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.completeButton}
                  >
                    {t("Complete your profile")}
                  </Button>
                </RouterLink>
              ) : (
                <RouterLink to="/suggester">
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.completeButton}
                  >
                    {t("Get Matched with a plan")}
                  </Button>
                </RouterLink>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CompleteProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation("trailhead")(
  withWidth()(withStyles(styles, { withTheme: true })(CompleteProfile)),
);
