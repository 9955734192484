import React from "react";
import { SvgIcon } from "@material-ui/core";

const StatsIcon = () => (
  <>
    <SvgIcon>
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icon:stats">
          <path
            id="Vector"
            d="M16.5 16.25H2C1.72386 16.25 1.5 16.0261 1.5 15.75V3.3125C1.5 3.00184 1.75184 2.75 2.0625 2.75C2.37316 2.75 2.625 3.00184 2.625 3.3125V15.125H4.5V8.5C4.5 8.22386 4.72386 8 5 8H7C7.27614 8 7.5 8.22386 7.5 8.5V15.125H9V5.5C9 5.22386 9.22386 5 9.5 5H11.5C11.7761 5 12 5.22386 12 5.5V15.125H13.5V11.5C13.5 11.2239 13.7239 11 14 11H16C16.2761 11 16.5 11.2239 16.5 11.5V16.25Z"
            fill="#0B2B5C"
          />
        </g>
      </svg>
    </SvgIcon>
  </>
);
export default StatsIcon;
