import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid, Button, Avatar } from "@material-ui/core";
import { withTranslation } from "react-i18next";

import PlanFieldTooltip from "./plan-field-tooltip";
import PlanFieldTransition from "./plan-field-transition";

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  avatar: {
    margin: 10,
    width: 80,
    height: 80,
  },
  centerAlign: {
    marginTop: "auto",
    marginBottom: "auto",
  },
});

class PlanFieldPicture extends Component {
  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleInputChange = async (event) => {
    if (event) {
      const { target } = event;
      const { onError, maxSize, onImageChange } = this.props;
      if (target) {
        const { files } = target;
        if (files) {
          const selectedFile = files[0];
          try {
            if (selectedFile) {
              const { size } = selectedFile;
              if (size <= maxSize) {
                const imageString = await this.getBase64(selectedFile);
                if (onImageChange) onImageChange(imageString);
              } else if (onError) {
                onError("Invalid Size");
              }
            } else {
              if (onError) {
                onError("Invalid File");
              }
            }
          } catch (exp) {
            if (onError) onError(exp);
          }
        }
      }
    }
  };
  render() {
    const { classes, xs, sm, md, lg, xl, image, t } = this.props;
    return (
      <PlanFieldTransition>
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <Grid container spacing={0} justify="flex-start">
            <Grid item>
              <Avatar
                src={image || `${process.env.PUBLIC_URL}/empty-avatar.png`}
                className={classes.avatar}
              />
            </Grid>
            <Grid item className={classes.centerAlign}>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={this.handleInputChange}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  component="span"
                  className={classes.button}
                  color="secondary"
                >
                  {t("Upload a picture", { ns: "plan" })}
                </Button>
              </label>
            </Grid>
            <Grid item className={classes.centerAlign}>
              <PlanFieldTooltip
                tooltipText={t("Image must be smaller than maxSize", {
                  ns: "messages",
                })}
                tooltipPlacement="top-end"
              />
            </Grid>
          </Grid>
        </Grid>
      </PlanFieldTransition>
    );
  }
}

PlanFieldPicture.propTypes = {
  classes: PropTypes.object.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  onError: PropTypes.func,
  maxSize: PropTypes.number,
  onImageChange: PropTypes.func,
  image: PropTypes.string,
  t: PropTypes.func.isRequired,
};

PlanFieldPicture.defaultProps = {
  maxSize: 5120000,
};

export default withTranslation(["plan", "messages"])(
  withStyles(styles)(PlanFieldPicture),
);
