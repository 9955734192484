import React, { Component, memo } from "react";
import {
  Dialog,
  DialogContent,
  withMobileDialog,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import WorkoutPlans from "../../constants/plans";
import history from "../../lib/history-helper";
import {
  currentTrainingAddComment,
  currentTrainingAddCommentsRead,
  currentTrainingGetComments,
  currentTrainingGetOldComments,
} from "../../actions/user-plans-action";
import TrainingWeekdayDetailsChat from "../common/training-weekday-chat";
import { grey } from "@material-ui/core/colors";
import { logEvent, setEventsMetadata } from "../../lib/events-helper";
import { createAnalyticData } from "../../lib/analytic-helper";
import { pushAnalyticData } from "../../actions/analytic-action";
import { getPlanLevel } from "../../lib/plan-helper";
import {
  patchSelectedAthleteWorkoutEditComment,
  patchSelectedAthleteWorkoutDeleteComment,
} from "../../actions/athletes-action";
import { checkSubscription } from "../../lib/subscription-plan-helper";
import { useAnalyticsEvent } from "../../hooks/useAnalyticsEvent";

const styles = (theme) => ({
  container: {
    background: "#FFFFFF",
    overflow: "hidden",
    padding: "0 0 5px 0 !important",
    [theme.breakpoints.down("sm")]: {
      overflow: "scroll",
    },
  },
  textContainer: {
    marginTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit,
  },
  newLine: {
    whiteSpace: "pre-line",
  },
  newLineEffort: {
    fontWeight: 500,
    whiteSpace: "pre-line",
  },
  borderedTextContainer: {
    paddingBottom: theme.spacing.unit,
    borderStyle: "groove",
    borderWidth: "thin",
    borderRadius: 5,
    padding: theme.spacing.unit * 2.5,
    minHeight: theme.spacing.unit * 5,
  },
  extraBottomMargin: {
    marginBottom: theme.spacing.unit * 4,
  },
  topBar: {
    marginTop: 32,
  },
  topTitle: {
    color: grey[700],
    marginBottom: theme.spacing.unit,
    fontSize: "0.8rem",
    fontWeight: "normal",
  },
  topText: {
    color: "#000000",
    marginBottom: theme.spacing.unit,
    fontSize: "1rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  effort: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: -10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: -10,
    },
  },
  effortLabel: {
    fontSize: 14,
    textAlign: "center",
    color: "#5F5F5F",
    fontWeight: "normal",
  },
  effortSelect: {
    margin: 10,
    cursor: "pointer",
    width: 28,
    height: 28,
    [theme.breakpoints.down("sm")]: {
      width: 20,
      height: 20,
      margin: 7,
    },
  },
  workoutColumn: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
      marginBottom: 70,
    },
  },
  chatColumn: {
    padding: "0 !important",
    order: 1,
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      order: 2,
      position: "absolute",
      bottom: 0,
      width: "100vw",
    },
  },
  smallerFontSize: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller",
    },
  },
  rightMargin: {
    marginRight: theme.spacing.unit * 2,
  },
  buttons: {
    textTransform: "none",
  },
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    textAlign: "end",
  },
  scrollBody: {
    textAlign: "end",
  },
  paper: {
    width: 400,
    margin: "100px 65px 30px auto",
    height: "calc(100vh - 130px)",
    borderRadius: 18,
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      borderRadius: 0,
    },
  },
  backdrop: {
    background: "transparent",
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    width: "100%",
    right: 0,
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },
  mainTitle: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 12,
    paddingBottom: 0,
    textAlign: "center",
    padding: 10,
  },
});

class DashboardChat extends Component {
  state = {
    savingComment: false,
  };

  componentDidMount() {
    const { userPlans, subscriptions, auth } = this.props;
    const { currentPlan } = userPlans;

    const { cognito_user_sub } = currentPlan;

    this.props.dispatch(
      currentTrainingGetComments(new Date().getTime(), cognito_user_sub),
    );

    setEventsMetadata({ customer_id: cognito_user_sub });
    logEvent("open_chat");

    const { currentUser } = auth || {};
    const { attributes } = currentUser || {};
    const { email } = attributes || {};
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleGetComments = (sub) => {
    this.props.dispatch(currentTrainingGetComments(new Date().getTime(), sub));
  };

  handleClose = () => {
    const { savingComment } = this.state;
    if (!savingComment) {
      this.setAllCommentsAsRead();
      setTimeout(() => {
        history.goBack();
        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          setTimeout(() => window.scrollBy(0, -300), 500);
        }
      }, 500);
    }
  };

  handleTextChange = (event) => {
    const { target } = event;
    if (target) {
      const { value } = target;
      this.setState({
        ...this.state,
        comment: value,
      });
    }
  };

  handleEdit = (comment) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan;

    this.props.dispatch(
      patchSelectedAthleteWorkoutEditComment(
        { cognito_user_sub },
        comment,
        true,
      ),
    );
  };

  handleDelete = async (comment) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan;
    await this.props.dispatch(
      patchSelectedAthleteWorkoutDeleteComment({ cognito_user_sub }, comment),
    );

    await this.handleGetMessages();
  };

  handleGetMessages = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan;
    this.props.dispatch(
      currentTrainingGetComments(new Date().getTime(), cognito_user_sub),
    );
  };

  handleSaveComment = (comment) => {
    const { userPlans, subscriptions, auth } = this.props;
    const { currentPlan, updatingWorkoutId } = userPlans;
    const currentComment = localStorage.getItem("comment");
    if (currentComment) localStorage.removeItem("comment");

    if (!updatingWorkoutId) {
      this.setState({
        ...this.state,
        savingComment: true,
      });

      const {
        lastModified,
        image,
        cognito_user_name,
        cognito_user_sub,
      } = currentPlan;

      this.props.dispatch(
        currentTrainingAddComment(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            comment: {
              ...comment,
              isReadByCoach: false,
              senderImage: image,
              senderName: cognito_user_name,
              cognito_user_sub,
            },
            lastModified,
          },
          false,
          true,
        ),
      );

      setEventsMetadata({ customer_id: cognito_user_sub });
      logEvent("send_chat_message");

      const { currentUser } = auth || {};
      const { attributes } = currentUser || {};
      const { email } = attributes || {};
      const analyticData = createAnalyticData(
        {
          username: email,
          userPlans,
          subscriptions,
        },
        "send chat message",
      );
      this.props.dispatch(pushAnalyticData(analyticData));

      setTimeout(
        () =>
          this.setState({
            ...this.state,
            savingComment: false,
          }),
        2000,
      );
    }
  };

  setAllCommentsAsRead = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    this.props.dispatch(
      currentTrainingAddCommentsRead(currentPlan.cognito_user_sub),
    );
  };

  handleLoadOld = (dateTime) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan;

    this.props.dispatch(
      currentTrainingGetOldComments(dateTime, cognito_user_sub),
    );
  };

  render() {
    const {
      classes,
      fullScreen,
      userPlans,
      t,
      match,
      subscriptions,
    } = this.props;
    const {
      currentPlan,
      comments = [],
      loadingComments,
      loadingOldComments,
      canLoadMoreComments,
    } = userPlans;
    const { currentTraining, cognito_user_name } = currentPlan;

    const level = getPlanLevel(currentTraining);

    let { selectedPlan } = currentPlan;
    const { days } = currentTraining || {};

    const { params } = match || {};
    const { id: idParam, plannedDate: plannedDateParam } = params || {};

    const workout =
      idParam && plannedDateParam
        ? days.find(
            (d) =>
              d &&
              d.length &&
              d[0] &&
              d[0].id === idParam &&
              d[0].plannedDate ===
                plannedDateParam.replace("-", "/").replace("-", "/"),
          )
        : null;

    const { latestExpired, loadingLatest, latest, alreadyLoadedOnce } =
      subscriptions || {};
    const { latestSubscriptionFinalizedTime } = currentPlan || {};

    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    let isSubscribed;
    if (selectedPlan !== WorkoutPlans.Items.FREE)
      isSubscribed = checkSubscription(
        subscriptions,
        latestSubscriptionFinalizedTime,
      );

    const isExplore =
      ((loadingLatest || typeof loadingLatest === "undefined") &&
        !alreadyLoadedOnce) ||
      (!!latest && !isSubscribed);

    return (
      <Dialog
        fullScreen={fullScreen}
        open={true}
        onClose={this.handleClose}
        aria-labelledby="Workout"
        scroll="body"
        maxWidth="sm"
        className={classes.dialog}
        classes={{
          paper: classes.paper,
          scrollBody: classes.scrollBody,
        }}
        BackdropProps={{
          invisible: true,
        }}
      >
        <DialogContent className={classes.container}>
          <div
            className={classes.rightButtons}
            onClick={() => this.handleClose()}
          >
            <img src={`${process.env.PUBLIC_URL}/close.svg`} alt="" />
          </div>
          <Typography variant="h6" className={classes.mainTitle}>
            {t("CHAT WITH YOUR COACH")}
          </Typography>
          <TrainingWeekdayDetailsChat
            comments={comments}
            loading={loadingComments}
            workout={workout}
            handleSaveComment={this.handleSaveComment}
            selectedPlan={selectedPlan}
            type={1}
            showAll={true}
            toggleChat={() => {}}
            userName={cognito_user_name}
            onLoadOld={this.handleLoadOld}
            loadingOldComments={loadingOldComments}
            canLoadMoreComments={canLoadMoreComments}
            isExplore={isExplore}
            level={level}
            handleEditComment={this.handleEdit}
            handleDeleteComment={this.handleDelete}
            getComments={this.handleGetComments}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

DashboardChat.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  fullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  userPlans: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
    subscriptions: store.subscriptions,
    auth: store.auth,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation(["dashboard", "constants"])(
      withWidth()(withMobileDialog()(memo(DashboardChat))),
    ),
  ),
);
