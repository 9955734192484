import React, { memo } from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import { withTranslation } from "react-i18next";
import SnackBarComponent from "./snack-bar-component";

const SnackBarMessageDisplay = ({
  errorMessage,
  infoMessage,
  successMessage,
  warningMessage,
  onHideMessage,
  t,
}) => {
  const getDisplayMessage = (input) => {
    switch (input) {
      case "SERVER ERROR":
        return t(
          "Please re-check the data that you input and make sure it corresponds to what was requested",
        );
      case "INVALID DATA":
        return t(
          "Please check your input data and make sure they all match the requested type",
        );
      case "Network Error":
        return t(
          "There is a problem connecting to the API, please try again later",
        );
      default:
        return t(input) || "";
    }
  };

  return (
    <div>
      <Snackbar
        style={{
          paddingLeft: "env(safe-area-inset-left)",
          paddingRight: "env(safe-area-inset-right)",
          paddingTop: "env(safe-area-inset-top)",
          paddingBottom: "env(safe-area-inset-bottom)",
          zIndex: 100000,
        }}
        data-testid="error"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={errorMessage ? true : false}
        //autoHideDuration={6000}
        onClose={onHideMessage}
      >
        <SnackBarComponent
          onClose={onHideMessage}
          variant="error"
          message={getDisplayMessage(errorMessage)}
        />
      </Snackbar>
      <Snackbar
        style={{
          paddingLeft: "env(safe-area-inset-left)",
          paddingRight: "env(safe-area-inset-right)",
          paddingTop: "env(safe-area-inset-top)",
          paddingBottom: "env(safe-area-inset-bottom)",
          zIndex: 100000,
        }}
        data-testid="info"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={infoMessage ? true : false}
        autoHideDuration={6000}
        onClose={onHideMessage}
      >
        <SnackBarComponent
          onClose={onHideMessage}
          variant="info"
          message={infoMessage || ""}
        />
      </Snackbar>
      <Snackbar
        style={{
          paddingLeft: "env(safe-area-inset-left)",
          paddingRight: "env(safe-area-inset-right)",
          paddingTop: "env(safe-area-inset-top)",
          paddingBottom: "env(safe-area-inset-bottom)",
          zIndex: 100000,
        }}
        data-testid="success"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={successMessage ? true : false}
        autoHideDuration={6000}
        onClose={onHideMessage}
      >
        <SnackBarComponent
          onClose={onHideMessage}
          variant="success"
          message={t(successMessage) || ""}
        />
      </Snackbar>
      <Snackbar
        style={{
          paddingLeft: "env(safe-area-inset-left)",
          paddingRight: "env(safe-area-inset-right)",
          paddingTop: "env(safe-area-inset-top)",
          paddingBottom: "env(safe-area-inset-bottom)",
          zIndex: 100000,
        }}
        data-testid="warning"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={warningMessage ? true : false}
        autoHideDuration={6000}
        onClose={onHideMessage}
      >
        <SnackBarComponent
          onClose={onHideMessage}
          variant="warning"
          message={t(warningMessage) || ""}
        />
      </Snackbar>
    </div>
  );
};

SnackBarMessageDisplay.propTypes = {
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  infoMessage: PropTypes.string,
  warningMessage: PropTypes.string,
  onHideMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation("messages")(memo(SnackBarMessageDisplay));
