import { API } from "aws-amplify";
import bugsnagClient from "../lib/bugsnag-client";

class LabService {
  static API_NAME = "apilabs";
  static LAB_PATH = "/labs/lactate";

  static async sendTrainingZone(data) {
    try {
      const myInit = { body: data };
      const response = await API.post(this.API_NAME, this.LAB_PATH, myInit);
      const { success, error } = response;

      if (error) throw new Error(error);

      if (success) return response;
    } catch (err) {
      bugsnagClient.notify(err);
      throw new Error(err);
    }
  }
}

export default LabService;
