import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Divider, Chip } from '@material-ui/core';

import AthleteUserPlanInfo from './athlete-user-plan-info';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    },
    chip: {
        fontWeight: 'bold',
        margin: 5,
        marginLeft: 0
    },
    chipsContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    divider: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
    }
});

class AthleteUserPlanTop extends Component {
    render() {
        const { classes, selectedAthlete } = this.props;
        const { surface, skillToImprove, trainWeekdays = [], trainPerWeek } = selectedAthlete;
        return <div className={classes.container}>
            <AthleteUserPlanInfo title="Surface" value={surface} />
            <AthleteUserPlanInfo title="Days of training" value={trainPerWeek} />
            <Typography variant="body1">
                Days with more time to train
            </Typography>
            <div className={classes.chipsContainer}>
                {trainWeekdays.map((day, index) => {
                    return <Chip key={index} className={classes.chip} label={day}></Chip>
                })}
            </div>
            <Divider className={classes.divider} />
            <AthleteUserPlanInfo title="Skill to improve" value={skillToImprove} />
        </div>
    }
}

AthleteUserPlanTop.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedAthlete: PropTypes.object.isRequired
}

export default withStyles(styles)(AthleteUserPlanTop);


