import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import useUserInfo from "../../../hooks/useUserInfo";
import TrainingDay from "./TrainingDay";
import Skeleton from "react-loading-skeleton";

const TrainingToday = () => {
  const classes = useStyles();
  const { currentTraining } = useUserInfo();

  if (!currentTraining) {
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.marginY}>
          <Skeleton height={60} width={"100%"} />
        </Grid>
        <Grid item xs={12} className={classes.marginY}>
          <Skeleton height={60} width={"100%"} />
        </Grid>
        <Grid item xs={12} className={classes.marginY}>
          <Skeleton height={60} width={"100%"} />
        </Grid>
        <Grid item xs={12} className={classes.marginY}>
          <Skeleton height={400} width={"100%"} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <TrainingDay
          allow={
            new Boolean(
              currentTraining.constructor === Object &&
                Object.keys(currentTraining).length,
            )
          }
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  root: {
    justifyContent: "center",
  },
  marginY: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export default TrainingToday;
