import React, { Component, memo } from "react";
import {
  Button,
  withStyles,
  Fade,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";

import FilterSelect from "../common/filter-select";
import Plans from "../../constants/plans";
import Distances from "../../constants/distances";
import TrainingDurationInWeeks from "../../constants/training-duration-in-weeks";
import Surfaces from "../../constants/surfaces";
import RaceCounts from "../../constants/race-counts";
import TrainingPerWeek from "../../constants/training-per-week";
import SkillsToImprove from "../../constants/skills-to-improve";
import SubcategoriesFilterSelect from "./subcategories-filter-select";

const styles = (theme) => {
  return {
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    buttons: {
      marginTop: "auto",
      marginBottom: 8,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    textField: {
      marginTop: "auto",
      marginBottom: 8,
      width: 300,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  };
};

class TrainingFilter extends Component {
  handleApplyFilterSubmit = (e) => {
    e.preventDefault();
    const { onApplyFilter, newFilterApplied } = this.props;
    if (onApplyFilter && newFilterApplied) onApplyFilter();
  };

  render() {
    const {
      classes,
      onFilterSelect,
      onSearchPhraseChanged,
      newFilterApplied,
      hasAnyFilters,
      currentFilter,
      subcategories,
      isOnAthletes,
      isExternalCoach,
    } = this.props;
    const { searchPhrase = "", selectedPlan, justMine } = currentFilter;
    const showSkill =
      selectedPlan &&
      selectedPlan.find(
        (item) => item === Plans.Items.EXPLORE || item === Plans.Items.PREMIUM,
      );
    const raceCountsItemsArray = RaceCounts.itemsArray;
    const raceCountItems = [
      {
        text: "Level 1",
        value: `${raceCountsItemsArray[0].value},${raceCountsItemsArray[1].value}`,
      },
      {
        text: "Level 2",
        value: `${raceCountsItemsArray[2].value},${raceCountsItemsArray[3].value}`,
      },
      {
        text: "Level 3",
        value: `${raceCountsItemsArray[4].value},${raceCountsItemsArray[5].value}`,
      },
    ];
    const trainingPerWeekItemsArray = TrainingPerWeek.itemsArray;
    const TrainingPerWeekItems = [
      { text: "3 Days", value: trainingPerWeekItemsArray[0].value },
      {
        text: "5 Days",
        value: `${trainingPerWeekItemsArray[1].value},${trainingPerWeekItemsArray[2].value}`,
      },
      {
        text: "6 Days",
        value: `${trainingPerWeekItemsArray[3].value},${trainingPerWeekItemsArray[4].value}`,
      },
    ];
    const SurfacesItemsArray = Surfaces.itemsArray;
    const surfaceItems = [
      { text: SurfacesItemsArray[0].text, value: SurfacesItemsArray[0].value },
      { text: SurfacesItemsArray[1].text, value: SurfacesItemsArray[1].value },
    ];

    const isExploreOrFreePlanSelected =
      selectedPlan &&
      selectedPlan.find(
        (plan) => plan === Plans.Items.EXPLORE || plan === Plans.Items.FREE,
      );

    return (
      <form
        className={classes.container}
        onSubmit={this.handleApplyFilterSubmit}
      >
        {isExternalCoach ? (
          <FormControlLabel
            control={
              <Switch
                checked={justMine}
                onChange={(event) =>
                  onFilterSelect({
                    name: "justMine",
                    value: !justMine,
                  })
                }
                name="overlay"
                color="primary"
              />
            }
            label={"Just show my trainings"}
            classes={{
              label: classes.switchLabel,
            }}
          />
        ) : (
          ""
        )}
        <TextField
          name="searchPhrase"
          label="Search..."
          value={searchPhrase}
          onChange={onSearchPhraseChanged}
          className={classes.textField}
          margin="none"
          style={{
            marginBottom: isOnAthletes ? 0 : 8,
          }}
        />
        <div>
          <FilterSelect
            name="selectedPlan"
            text="Plan"
            items={Plans.itemsArray}
            onSelect={onFilterSelect}
            noMargin={isOnAthletes}
          />
          <SubcategoriesFilterSelect
            filterSelectName="subcategory"
            filterSelectText="Sub Categories"
            onSelect={onFilterSelect}
            isExploreOrFreePlanSelected={isExploreOrFreePlanSelected}
            subcategories={subcategories}
            isOnAthletes={isOnAthletes}
          />
          <FilterSelect
            name="durationInWeeks"
            text="Duration"
            items={TrainingDurationInWeeks.itemsArray}
            onSelect={onFilterSelect}
            noMargin={isOnAthletes}
          />
          <FilterSelect
            name="distance"
            text="Distance"
            items={Distances.itemsArray}
            onSelect={onFilterSelect}
            noMargin={isOnAthletes}
          />
          <FilterSelect
            name="surface"
            text="Surface"
            items={surfaceItems}
            onSelect={onFilterSelect}
            noMargin={isOnAthletes}
          />
          <FilterSelect
            name="raceCount"
            text="Level"
            items={raceCountItems}
            onSelect={onFilterSelect}
            noMargin={isOnAthletes}
          />
          <FilterSelect
            name="trainPerWeek"
            text="Days"
            items={TrainingPerWeekItems}
            onSelect={onFilterSelect}
            noMargin={isOnAthletes}
          />
          {showSkill && (
            <FilterSelect
              name="skillToImprove"
              text="Skill"
              items={SkillsToImprove.itemsArray}
              onSelect={onFilterSelect}
              noMargin={isOnAthletes}
            />
          )}
        </div>
        <Fade in={newFilterApplied && hasAnyFilters}>
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              color="secondary"
              type="submit"
              disabled={!newFilterApplied}
            >
              Search
              <SearchIcon className={classes.rightIcon} />
            </Button>
          </div>
        </Fade>
      </form>
    );
  }
}

TrainingFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  onSearchPhraseChanged: PropTypes.func.isRequired,
  newFilterApplied: PropTypes.bool,
  onApplyFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.object.isRequired,
  subcategories: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(memo(TrainingFilter));
