import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { Typography, Box } from "@material-ui/core";
import { palette } from "../../theme/palette";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 40,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  text: {
    fontSize: "14px",
    color: palette.darkGray,
  },
  rightAlign: {
    textAlign: "right",
  },
});

const calculateThumbColor = (value) => {
  const colorRanges = [
    { max: 30, color: "#E02758" }, // Alert
    { max: 70, color: "#FFB845" }, // Warning
    { max: 90, color: "#6BB488" }, // Success
    { max: 130, color: "#FFB845" }, // Warning
    { max: Infinity, color: "#E02758" }, // Alert
  ];

  const colorObject = colorRanges.find((range) => value <= range.max);
  return colorObject ? colorObject.color : undefined;
};

const CustomSlider = withStyles({
  rail: {
    backgroundImage:
      "linear-gradient(.15turn, #E02758, #FFB845, #6BB488, #FFB845, #E02758)",
    height: 10,
    opacity: 1,
    borderRadius: 6,
  },
  track: {
    background: "none !important",
    backgroundColor: "none",
    backgroundImage: "none",
  },
  thumb: {
    transition: "left 0.5s ease",
    color: (props) => calculateThumbColor(props.value || 0),
  },
})(Slider);

const MountainIndexBar = ({ value }) => {
  const classes = useStyles();
  const { t } = useTranslation("mountainIndex");

  return (
    <Box className={classes.root}>
      <CustomSlider
        value={Math.round(value)}
        className={classes.customSlider}
        aria-labelledby="continuous-slider"
        valueLabelDisplay="on"
        disableSwap
        max={value <= 150 ? 150 : Math.round(value)}
      />
      <Box className={classes.row}>
        <Box sx={{ width: "40%" }}>
          <img src={`${process.env.PUBLIC_URL}/down-triangle.svg`}></img>
          <Typography className={classes.text}>
            {t("mountainIndex.slider.notElevation")}
          </Typography>
        </Box>

        <Box sx={{ width: "35%" }}>
          <img
            src={`${process.env.PUBLIC_URL}/down-triangle.svg`}
            style={{ display: "block", marginLeft: "auto" }}
          />
          <Typography className={[classes.text, classes.rightAlign]}>
            {t("mountainIndex.slider.tooMuchElevation")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MountainIndexBar;
