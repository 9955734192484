import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress } from "@mui/material";
const VertScreenLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.vertScreenLoader}>
      <CircularProgress />
    </Box>
  );
};

export default VertScreenLoader;

const useStyles = makeStyles({
  vertScreenLoader: {
    flexDirection: "column",
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    zIndex: 9999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.500)",
  },
});
