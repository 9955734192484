import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import XHR from "i18next-xhr-backend";

// const languageDetector = {
//     type: 'languageDetector',
//     async: true, // If this is set to true, your detect function receives a callback function that you should call with your language, useful to retrieve your language stored in AsyncStorage for example
//     init: () => { },
//     detect: (callback) => { // You'll receive a callback if you passed async true
//         /* return detected language */
//         // callback('de'); if you used the async flag
//         return i18n.LanguageCode.indexOf('-') >= 0 ? i18n.LanguageCode.split('-')[0] :
//             i18n.LanguageCode.indexOf('_') >= 0 ? i18n.LanguageCode.split('_')[0] :
//                 i18n.LanguageCode;
//     },
//     cacheUserLanguage: () => { }
// };

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    load: "languageOnly",
    fallbackLng: "en",
    defaultNS: ["dashboard", "layout"],
    debug: true,
    ns: [
      "dashboard",
      "messages",
      "layout",
      "plan",
      "constants",
      "subscription",
      "profile",
    ],
    backend: {
      backends: [XHR, XHR],
      backendOptions: [
        { loadPath: "/locales/v2/{{lng}}/{{ns}}.json" },
        { loadPath: "/locales/{{lng}}/{{ns}}.json" },
      ],
    },
    detection: {
      // order and from where user language should be detected
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],

      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      //cookieMinutes: 10,
      //cookieDomain: 'myDomain',

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,
    },
    react: {
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"], // don't convert to <1></1> if simple react elements
    },
  });
i18n.on("languageChanged", function(lng) {
  const language =
    lng.indexOf("-") >= 0
      ? lng.split("-")[0]
      : lng.indexOf("_") >= 0
      ? lng.split("_")[0]
      : lng;
  if (lng !== language) i18n.changeLanguage(language);
});

export default i18n;
