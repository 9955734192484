import React from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Typography, Button } from "@material-ui/core";
import history from "../../lib/history-helper";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getLatestSubscription } from "../../actions/subscriptions-action";

const styles = (theme) => ({
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  noPadding: {
    padding: "16px !important",
    maxWidth: "300px",
    boxSizing: "border-box",
  },
  dialogPaper: {
    borderRadius: "12px",
    height: 442,
  },
  imageParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: "20px",
  },
  closeIcon: {
    marginLeft: "10px",
  },
  content: {
    textAlign: "center",
    marginBottom: "10px",
  },
  title: {
    marginBottom: "20px",
    textAlign: "center",
  },
  description: {
    fontSize: "14px",
    color: "#3A4750",
    textAlign: "center",
    marginBottom: "10px",
  },
  listElement: {
    fontSize: "14px",
    color: "#3A4750",
    marginLeft: "5px",
  },
  price: {
    color: "#3A4750",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "bold",
  },
  btnParent: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0 0px 0",
  },
  dot: {
    width: "3px",
    height: "3px",
    borderRadius: "50%",
    backgroundColor: "#3A4750",
  },
  itemParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  list: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  listSubtitle: {
    fontSize: "14px",
    color: "#3A4750",
    textAlign: "center",
  },
});

const RetrySubModal = ({ classes, open, onClose, hideRetry }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("dashboard");

  const handleGetSub = async () => {
    await dispatch(getLatestSubscription());
    await hideRetry();
  };

  return (
    <Dialog
      open={true}
      onClose={onClose ? onClose : null}
      aria-labelledby="sub-retry"
      className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={classes.noPadding}>
        <div className={classes.imageParent}>
          <Typography>{t("Close")}</Typography>
          <img
            src={`${process.env.PUBLIC_URL}/workout-detail/close-icon.svg`}
            alt="close-icon"
            className={classes.closeIcon}
            onClick={onClose ? onClose : null}
          />
        </div>

        <div className={classes.content}>
          <Typography
            className={classes.title}
            dangerouslySetInnerHTML={{
              __html: t(
                "If you have an active subscription and is not loading correctly, please refresh the subscription request underneath.",
              ),
            }}
          />
          <Typography className={classes.content}>
            {t(
              "If after refreshing 2 times there are no changes, please reach out directly to our email team@vert.run and we will get back to you asap.",
            )}
          </Typography>
          <div className={classes.btnParent}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={handleGetSub}
            >
              Refresh Subs Request
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation("dashboard")(
  withWidth()(withStyles(styles, { withTheme: true })(RetrySubModal)),
);
