import React, { useState, useEffect } from "react";
import {
  Box,
  Slide,
  Dialog,
  Typography,
  List,
  Link,
  ListItem,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import { Timeline } from "@material-ui/lab";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import CheckoutPriceCard from "../../../components/common/v2/CheckoutPriceCard";
import ListItemWithIcon from "../../../components/common/v2/ListItemWithIcon";
import CheckoutTimelineItem from "../../../components/common/v2/CheckoutTimelineItem";
import history from "../../../lib/history-helper";
import { useTranslation } from "react-i18next";
import useCheckout from "../../../hooks/useCheckout";
import useCheckoutProducts from "../../../hooks/useCheckoutProducts";
import CheckoutPriceCardSkeleton from "../../../components/common/v2/CheckoutPriceCardSkeleton";
import { makeStyles } from "@material-ui/core/styles";
import { round } from "../../../lib/units-helper";
import { setEventsMetadata, logEvent } from "../../../lib/events-helper";
import useUserInfo from "../../../hooks/useUserInfo";
import useQuestionnaire from "../../../hooks/useQuestionnaire";
import {
  IconCalendarEvent,
  IconCheck,
  IconEdit,
  IconInfoCircle,
  IconMedal,
  IconMessageCircle,
} from "@tabler/icons-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CheckoutView = () => {
  const [openDialog, setOpenDialog] = useState(true);
  const { sub } = useUserInfo();
  const {
    onboardingStorage,
    finishOnboarding,
    loadingSave,
  } = useQuestionnaire();
  const { t } = useTranslation("checkout");
  const classes = useStyles();
  const { ownedMobileProducts, handleCheckout, isPurchasing } = useCheckout();
  const { checkoutItems, loading } = useCheckoutProducts();
  const [planType, setPlanType] = useState();

  useEffect(() => {
    const setOnboardingPlanType = async () => {
      const onboardingPlanType = await onboardingStorage.getItem(
        "onboardingPlanType",
      );
      if (onboardingPlanType) {
        setPlanType(onboardingPlanType);
      }
    };

    setOnboardingPlanType();
  }, []);

  useEffect(() => {
    setEventsMetadata({ customer_id: sub });
    logEvent("Visit_Checkout_page");
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const skipCoaching = async () => {
    await finishOnboarding();
    history.push("/");
  };

  const handleTransitionEnd = () => {
    history.goBack();
  };

  if (
    ownedMobileProducts &&
    ownedMobileProducts.length > 0 &&
    ownedMobileProducts.find((o) => o.isActive)
  ) {
    history.replace("/profile");
    return null;
  }
  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
      TransitionProps={{ onExited: handleTransitionEnd }}
      display={"flex"}
      flexDirection="column"
    >
      {isPurchasing === true && (
        <Box className={classes.overlay}>
          <CircularProgress />
        </Box>
      )}
      <Box className={classes.headerBox}>
        <ToolbarReturnBack goBack={handleCloseDialog} withLogo={true} />
      </Box>
      <Box className={classes.parentBox}>
        <List>
          <ListItem>
            <Typography className={classes.title}>
              {t("checkout.title")}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography className={classes.subtitle}>
              {t("checkout.subtitle")}{" "}
              <span className={classes.highlight}>
                {t("checkout.subtitleHighlight")}
              </span>
            </Typography>
          </ListItem>
          <List className={classes.checkList}>
            <ListItemWithIcon icon={<IconCheck size={14} />}>
              <Typography className={classes.checkItem}>
                {t("checkout.list.access")}{" "}
                <span className={classes.highlight}>
                  {t("checkout.list.accessHighlight")}
                </span>{" "}
                {t("checkout.list.accessDescription")}
              </Typography>
            </ListItemWithIcon>
            <ListItemWithIcon icon={<IconCheck size={14} />}>
              <Typography className={classes.checkItem}>
                {t("checkout.list.guidance")}{" "}
                <span className={classes.highlight}>
                  {t("checkout.list.guidanceHighlight")}
                </span>{" "}
                {t("checkout.list.guidanceDescription")}
              </Typography>
            </ListItemWithIcon>
            <ListItemWithIcon icon={<IconCheck size={14} />}>
              <Typography className={classes.checkItem}>
                {t("checkout.list.questions")}
              </Typography>
            </ListItemWithIcon>
            <ListItemWithIcon icon={<IconCheck size={14} />}>
              <Typography className={classes.checkItem}>
                {t("checkout.list.receive")}
                <span className={classes.highlight}>
                  {" "}
                  {t("checkout.list.receiveHighlight")}
                </span>
              </Typography>
            </ListItemWithIcon>
            <ListItemWithIcon icon={<IconCheck size={14} />}>
              <Typography className={classes.checkItem}>
                <span className={classes.highlight}>
                  {t("checkout.list.personalHighlight")}
                </span>{" "}
                {t("checkout.list.personal")}
              </Typography>
            </ListItemWithIcon>
          </List>
          {loading ? (
            <>
              <CheckoutPriceCardSkeleton />
              <CheckoutPriceCardSkeleton />
              <CheckoutPriceCardSkeleton />
            </>
          ) : (
            <>
              <CheckoutPriceCard
                onClick={() =>
                  handleCheckout(
                    checkoutItems[0].name,
                    "Checkout page Android clicks on 1 month",
                  )
                }
                title={t("checkout.item.oneMonth")}
                price={`${checkoutItems[0].currency} ${
                  checkoutItems[0].price
                }/${t("checkout.item.month")}`}
              />
              <CheckoutPriceCard
                onClick={() =>
                  handleCheckout(
                    checkoutItems[1].name,
                    "Checkout page Android clicks on 6 months",
                  )
                }
                title={t("checkout.item.sixMonth")}
                price={`${checkoutItems[1].currency} ${round(
                  checkoutItems[1].price / 5,
                )}/${t("checkout.item.month")}`}
                subtext={t("checkout.item.oneMonthFree")}
              />
              <CheckoutPriceCard
                onClick={() =>
                  handleCheckout(
                    checkoutItems[2].name,
                    "Checkout page Android clicks on 12 months",
                  )
                }
                title={t("checkout.item.twelveMonth")}
                price={`${checkoutItems[2].currency} ${round(
                  checkoutItems[2].price / 12,
                )}/${t("checkout.item.month")}`}
                subtext={t("checkout.item.fourMonthFree")}
              />
            </>
          )}
          <ListItem className={classes.cancelPadding}>
            <IconInfoCircle size={12} color="#6D6F71" stoke={1} />
            <Typography className={classes.cancelText}>
              {t("checkout.cancel")}
            </Typography>
          </ListItem>
          {loadingSave ? (
            <LinearProgress color="secondary" className={classes.progress} />
          ) : (
            <ListItem className={classes.noCoachingItem}>
              {planType && planType !== "explore" && (
                <Link
                  onClick={skipCoaching}
                  underline="always"
                  className={classes.noCoachingText}
                >
                  {t("checkout.noCoach")}
                </Link>
              )}
            </ListItem>
          )}
          <ListItem>
            <Typography className={classes.subtitle}>
              {t("checkout.trial.howItWorks")}
            </Typography>
          </ListItem>
          <ListItem>
            <Timeline className={classes.timeline}>
              <CheckoutTimelineItem
                icon={<IconEdit color="#F93C6E" stoke={1} />}
                timeRange={t("checkout.timeline.today.title")}
                items={[
                  t("checkout.timeline.today.description"),
                  t("checkout.timeline.today.description2"),
                ]}
                mobile
              />
              <CheckoutTimelineItem
                icon={<IconMessageCircle color="#F93C6E" stoke={1} />}
                timeRange={t("checkout.timeline.twoDays")}
                items={[t("checkout.timeline.twoDays.description")]}
                mobile
              />
              <CheckoutTimelineItem
                icon={<IconCalendarEvent color="#F93C6E" stoke={1} />}
                timeRange={t("checkout.timeline.fourDays")}
                items={[t("checkout.timeline.fourDays.description")]}
                mobile
              />
              <CheckoutTimelineItem
                icon={<IconMedal color="#F93C6E" stoke={1} />}
                timeRange={t("checkout.timeline.sevenDays")}
                items={[
                  t("checkout.timeline.sevenDays.description"),
                  t("checkout.timeline.sevenDays.description2"),
                ]}
                mobile
              />
            </Timeline>
          </ListItem>
        </List>
      </Box>
    </Dialog>
  );
};

export default CheckoutView;

const useStyles = makeStyles({
  headerBox: {
    flex: "0 0 auto",
    backgroundColor: "#FAFAFA",
  },
  parentBox: {
    flex: "1 1 auto",
    overflowY: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    backgroundColor: "#FAFAFA",
  },
  title: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  subtitle: {
    fontSize: "22px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  highlight: {
    color: "#F93C6E",
    fontWeight: 700,
  },
  checkList: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  checkItem: {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#12151B",
  },
  cancelPadding: {
    paddingLeft: 20,
  },
  cancelText: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#6D6F71",
    marginLeft: "4px",
  },
  noCoachingItem: {
    display: "flex",
    justifyContent: "center",
    marginTop: 16,
    marginBottom: 16,
    cursor: "pointer",
  },
  noCoachingText: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#4780AB",
  },
  progress: {
    width: "100%",
    height: "4px",
  },
  timeline: {
    padding: 0,
  },
  overlay: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.500)",
    zIndex: 1001,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
