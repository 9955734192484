import { useEffect, useState } from "react";
import useUserInfo from "./useUserInfo";
import SubscriptionsService from "../services/subscriptions-service";
import { useDispatch, useSelector } from "react-redux";
import { Capacitor } from "@capacitor/core";
import subscriptionCurrencies from "../constants/subscription-currencies";
import { setCheckoutItems } from "../actions/checkout-action";
import bugsnagClient from "../lib/bugsnag-client";
import useInAppPurchasesService from "./useInAppPurchasesService";

const useCheckoutProducts = (premium = false) => {
  const { subscriptions, sub } = useUserInfo();
  const [currency, setCurrency] = useState(subscriptionCurrencies.Items.USD);
  const [pricesList, setPricesList] = useState([]);
  const [loading, setLoading] = useState(true);

  const { checkoutItems } = useSelector((state) => state.checkout);
  const dispatch = useDispatch();
  const { mobileProductsExplore, ownedMobileProducts } = subscriptions;
  const { initInAppPurchases } = useInAppPurchasesService();

  const option = premium ? "premium" : "explore";
  const getItemPrice = async () => {
    const exceptionMonthlyList = ["111", "555", "12"];
    const monthlyOptionsList = ["1", "5", "12"];
    const pricesList = [];
    if (option === "premium") {
      const item = await SubscriptionsService.getItem(
        `${option}-0-${currency}`,
      );
      if (item) {
        pricesList.push({
          name: `${option}-0-${currency}`,
          price:
            currency == subscriptionCurrencies.Items.CLP
              ? item.price
              : item.price / 100,
          currency: subscriptionCurrencies.Signs[currency],
        });
      }
    } else {
      if (currency == subscriptionCurrencies.Items.EURO) {
        for (const monthOption of exceptionMonthlyList) {
          const item = await SubscriptionsService.getItem(
            `${option}-${monthOption}-${currency}`,
          );
          if (item) {
            pricesList.push({
              name: item.id,
              price: item.price / 100,
              currency: subscriptionCurrencies.Signs[currency],
            });
          }
        }
      } else {
        for (const monthOption of monthlyOptionsList) {
          const item = await SubscriptionsService.getItem(
            `${option}-${monthOption}-${currency}`,
          );
          if (item) {
            pricesList.push({
              name: item.id,
              price:
                currency == subscriptionCurrencies.Items.CLP
                  ? item.price
                  : item.price / 100,
              currency: subscriptionCurrencies.Signs[currency],
            });
          }
        }
      }
    }
    setPricesList(pricesList);
  };

  const initAppPurchases = async () => {
    setLoading(true);
    if (Capacitor.isNativePlatform()) {
      await initInAppPurchases(sub, dispatch);
    } else {
      getItemPrice();
    }
  };

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      if (mobileProductsExplore && mobileProductsExplore.length > 0) {
        const pricesList = [];
        for (const item of mobileProductsExplore) {
          if (item) {
            try {
              const { product } = item;
              pricesList.push({
                name: item,
                price: product.price,
                currency: product.currencyCode,
              });
            } catch (error) {
              bugsnagClient.notify(error);
            }
          }
        }
        setPricesList(pricesList);
      }
    }
  }, [mobileProductsExplore]);

  useEffect(() => {
    initAppPurchases();
  }, [currency]);

  useEffect(() => {
    if (pricesList && pricesList.length > 0) {
      dispatch(setCheckoutItems(pricesList));
      setLoading(false);
    }
  }, [pricesList]);

  return {
    ownedMobileProducts,
    initAppPurchases,
    getItemPrice,
    pricesList,
    mobileProductsExplore,
    checkoutItems,
    setCurrency,
    loading,
  };
};

export default useCheckoutProducts;
