import React from "react";
import { palette } from "../../theme/palette";

const EmailIcon = ({
  width = 20,
  height = 16,
  activated = false,
  color = "#1D1A05",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={` 0 0 ${width} ${height}`}
    fill={activated ? palette.primary : "none"}
    xmlns="http://www.w3.org/2000/svg"
    color={color}
  >
    <path
      d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM18 14H2V4L9.47 8.66875C9.79427 8.87142 10.2057 8.87142 10.53 8.66875L18 4V14Z"
      fill={activated ? palette.primary : color}
    />
  </svg>
);
export default EmailIcon;
