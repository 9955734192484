const TrainingDifficultyLevels = {
  "1": "Level 1",
  "2": "Level 2",
  "3": "Level 3",
};

export default {
  Items: TrainingDifficultyLevels,
  itemsArray: Object.keys(TrainingDifficultyLevels).map((key) => {
    return {
      text: TrainingDifficultyLevels[key],
      value: key,
    };
  }),
};
