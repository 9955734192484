import React, { useState, useEffect } from "react";
import usePost from "../../../hooks/usePost";
import useCreatePost from "../../../hooks/useCreatePost";
import { Checkbox, Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Chip, TextField, Typography } from "@material-ui/core";

const CommunityTagsDropdown = ({ disabled = false }) => {
  const { tags } = usePost();
  const { t, i18n } = useTranslation("community");
  const { handleTagChange, selectedTags } = useCreatePost();
  const [localSelectedTags, setLocalSelectedTags] = useState([]);

  useEffect(() => {
    setLocalSelectedTags(
      selectedTags
        .map((tagId) => tags.find((tag) => tag.id === tagId))
        .filter(Boolean),
    );
  }, [selectedTags, tags]);

  const handleTagsChange = (event, value) => {
    setLocalSelectedTags(value);

    const tagIds = value.map((tag) => tag.id);
    handleTagChange(tagIds);
  };

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      fullWidth
      size="small"
      options={tags}
      renderTags={(tagValue, getTagsProps) =>
        tagValue.map((tag, index) => (
          <Chip
            {...getTagsProps({ index })}
            style={{
              backgroundColor: "#F1F6FD",
              fontFamily: "Karla",
              fontWeight: 400,
            }}
            size="small"
            key={tag.id}
            label={tag.name[i18n.language] || tag.name.en}
            data-testid={`post_tags_${tag.id}`}
            id={`post_tags_${tag.id}`}
          />
        ))
      }
      disableCloseOnSelect
      value={localSelectedTags}
      onChange={handleTagsChange}
      getOptionLabel={(tag) => tag.name[i18n.language] || tag.name.en}
      renderOption={(props, tag, { selected }) => (
        <li {...props}>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
            data-testid={`post_tags_${tag.id}`}
            id={`post_tags_${tag.id}`}
          />
          <Typography
            style={{ fontSize: 16, fontFamily: "Karla", fontWeight: 400 }}
          >
            {tag.name[i18n.language] || tag.name.en}
          </Typography>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid="post_tags_drop"
          variant="outlined"
          size="small"
          style={{ backgroundColor: "#fff" }}
          color="secondary"
          id="post_tags_drop"
          placeholder={
            localSelectedTags.length > 0
              ? ""
              : t("community.newPost.tagsPlaceholder")
          }
          disableUnderline="true"
          aria-label="naked"
        />
      )}
    />
  );
};

export default CommunityTagsDropdown;
