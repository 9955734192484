const SubscriptionCurrencies = {
  USD: "USD",
  EURO: "EURO",
  CLP: "CLP",
};
const SubscriptionCurrencySigns = {
  USD: "$",
  EURO: "€",
  CLP: "$",
};

export default {
  Items: SubscriptionCurrencies,
  itemsArray: Object.keys(SubscriptionCurrencies).map((key) => {
    return {
      text: SubscriptionCurrencies[key],
      value: key,
    };
  }),
  Signs: SubscriptionCurrencySigns,
};
