import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { IconCalendarEvent, IconRoute } from "@tabler/icons-react";
import React from "react";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { palette } from "../../../theme/palette";
import { useTranslation } from "react-i18next";
import useFavorites from "../../../hooks/useFavorites";

const PopularPlansHomeCard = ({ item, navigate }) => {
  const { t } = useTranslation("home");
  const { language } = useTranslation();
  const { toggleFavorites, isFavorite } = useFavorites();
  const classes = useStyles();
  return (
    <Box className={classes.card} onClick={() => navigate(item)}>
      <Box style={imageStyles(item.cover_picture || item.picture)}>
        <IconButton
          className={classes.favoriteButton}
          size="small"
          aria-label="add to favorites"
          onClick={(e) => {
            e.stopPropagation();
            toggleFavorites("plan", item.id);
          }}
        >
          {isFavorite("plan", item.id) ? (
            <StarIcon color="primary" />
          ) : (
            <StarBorderIcon style={{ color: "white" }} />
          )}
        </IconButton>
      </Box>
      <Box className={classes.descriptionContainer}>
        <Box>
          <Typography variant="subtitle2" className={classes.content}>
            {item.athlete}
          </Typography>
          <Typography variant="h4">
            {item.title[language] || item.title["en"]}
          </Typography>
        </Box>

        <Box className={classes.margin}>
          <Box className={classes.attributes}>
            <Box className={classes.centered}>
              <Box className={classes.verticalCentered}>
                <IconCalendarEvent color="#6D6F71" stroke={1.5} />
                <Typography variant="body2" className={classes.labelOnly}>
                  {item.weeks} {t("home.plan.weeks")}
                </Typography>
              </Box>
            </Box>
            <Divider orientation="vertical" variant="fullWidth" flexItem />
            <Box className={classes.centered}>
              <Box className={classes.verticalCentered}>
                <IconRoute color="#6D6F71" stroke={1.5} />
                <Typography variant="body2" className={classes.label}>
                  {t("home.plan.distance")} {item.max_distance}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PopularPlansHomeCard;

const useStyles = makeStyles(() => ({
  card: {
    background: palette.white,
    borderRadius: "8px",
    marginTop: "12px",
    width: "275px",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
  title: {
    fontFamily: "Oswald",
    marginBottom: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: "4px",
  },
  content: {
    color: palette.darkGray,
    fontWeight: "400",
  },
  button: {
    fontFamily: "Karla",
    marginTop: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "18px",
    textTransform: "lowercase",
  },
  descriptionContainer: {
    padding: "12px",
    height: "165px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  verticalCentered: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  label: {
    color: palette.darkGray,
    marginTop: "4px",
  },
  labelOnly: {
    color: palette.darkGray,
  },
  margin: {
    marginTop: "12px",
  },
  attributes: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    padding: "0 24px",
  },
  favoriteButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "#FFFFFF33",
  },
}));

const imageStyles = (url) => ({
  borderRadius: "8px 8px 0 0",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url(${url})`,
  height: "125px",
  position: "relative",
});
