import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 30,
  },
  logo: {
    width: 100,
    content: `url("${process.env.PUBLIC_URL}/logo-white.png")`,
    marginBottom: 10,
  },
  copyrightText: {
    marginBottom: 10,
    color: theme.palette.common.white,
  },
});

const FooterCopyright = (props) => {
  const { classes } = props;
  const { t } = useTranslation("layout");
  return (
    <div className={classes.container}>
      <img className={classes.logo} alt="logo-footer" />
      <Typography
        className={classes.copyrightText}
        variant="caption"
        color="secondary"
      >
        {t("Vertrun INC 2020 all rights reserved")}
      </Typography>
    </div>
  );
};

FooterCopyright.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FooterCopyright);
