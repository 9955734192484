import { API } from "aws-amplify";
import { ACTIVITY_TYPES } from "../lib/activities-helper";

class GarminService {
  static API_NAME = "apidevice";
  static AUTHORIZATION_INIT_PATH = "/device/garminInit";
  static AUTHORIZATION_SECOND_STEP_PATH = "/device/garminInitSecondStep";
  static AUTHORIZATION_DELETE_PATH = "/device/garminDelete";
  static BACKFILL_PATH = "/device/garminBackfill";
  static GET_ACTIVITIES_PATH = "/device/garminActivities/:oauth_token";
  static GET_ACTIVITY_PATH = "/device/garminActivity/:oauth_token";
  static POST_ACTIVITY_PATH = "/device/exportActivityGarmin";

  static async initAuthorization() {
    const response = await API.post(
      this.API_NAME,
      this.AUTHORIZATION_INIT_PATH,
    );
    const { success, result, error } = response;

    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async secondStepAuthorization(
    oauth_token,
    oauth_verifier,
    oauth_token_secret,
  ) {
    if (oauth_token && oauth_verifier && oauth_token_secret) {
      const myInit = {
        body: {
          oauth_token,
          oauth_verifier,
          oauth_token_secret,
        },
      };
      const response = await API.post(
        this.API_NAME,
        this.AUTHORIZATION_SECOND_STEP_PATH,
        myInit,
      );
      const { success, result, error } = response;

      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async deleteAuthorization(oauth_token, oauth_token_secret) {
    if (oauth_token && oauth_token_secret) {
      const myInit = {
        body: {
          oauth_token,
          oauth_token_secret,
        },
      };
      const response = await API.post(
        this.API_NAME,
        this.AUTHORIZATION_DELETE_PATH,
        myInit,
      );
      const { success, result, error } = response;

      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async backfill(oauth_token, oauth_token_secret) {
    if (oauth_token && oauth_token_secret) {
      const myInit = {
        body: {
          oauth_token,
          oauth_token_secret,
        },
      };
      const response = await API.post(
        this.API_NAME,
        this.BACKFILL_PATH,
        myInit,
      );
      const { success, result, error } = response;

      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async getActivities(params) {
    if (params) {
      const { start_date, end_date, oauth_token, cognito_user_sub } = params;
      if (start_date && end_date && oauth_token && cognito_user_sub) {
        let myInit = {
          queryStringParameters: { start_date, end_date, cognito_user_sub },
        };
        const response = await API.get(
          this.API_NAME,
          this.GET_ACTIVITIES_PATH.replace(":oauth_token", oauth_token),
          myInit,
        );
        const { success, result, error } = response;

        if (success) {
          const data = result.map((activity) => {
            if (!activity.device)
              return { ...activity, device: ACTIVITY_TYPES.GARMIN };
            return activity;
          });
          return data;
        } else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      }
    }
  }

  static async getActivity(params) {
    if (params) {
      const { summaryId, oauth_token } = params;
      if (summaryId && oauth_token) {
        let myInit = {
          queryStringParameters: { summaryId },
        };
        const response = await API.get(
          this.API_NAME,
          this.GET_ACTIVITY_PATH.replace(":oauth_token", oauth_token),
          myInit,
        );
        const { success, result, error } = response;

        if (success) return result;
        else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      }
    }
  }

  static async exportWorkout(params) {
    const {
      oauth_token,
      oauth_token_secret,
      selectedTrainingDayWorkoutTemp,
      level,
      useRpe,
    } = params;
    const myInit = {
      body: {
        oauth_token,
        oauth_token_secret,
        selectedTrainingDayWorkoutTemp,
        level,
        useRpe,
      },
    };
    const response = await API.post(
      this.API_NAME,
      this.POST_ACTIVITY_PATH,
      myInit,
    );
    const { success, result, error } = response;

    if (error) throw error;

    if (success) return result;
  }

  /*static async getStats(strava_athlete_id) {
    if (strava_athlete_id) {
      const response = await API.get(
        this.API_NAME,
        this.GET_STATS_PATH.replace(":strava_athlete_id", strava_athlete_id),
      );
      const { success, result, error } = response;

      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        return null;
      }
    }
  }*/
}

export default GarminService;
