import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { planChangeCurrentStep } from "../../../actions/user-plans-action";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  img: {
    width: "100%",
  },
});

class PlanImage extends Component {
  state = {
    isInvitation: false,
  };

  nextOrSave = async () => {
    const { dispatch, currentStep, savePlan } = this.props;
    if (!savePlan) await dispatch(planChangeCurrentStep(currentStep + 1));
  };

  checkComponent = () => {
    const { savePlan, path } = this.props;

    setTimeout(() => {
      savePlan && path === "invitation"
        ? this.setState({ isInvitation: true })
        : this.setState({ isInvitation: false });
    }, 100);
  };

  componentDidUpdate(prevProps) {
    const { path } = this.props;
    if (path && prevProps.path !== path) this.checkComponent();
  }

  render() {
    const {
      image,
      title,
      subtitle,
      width,
      titleImage,
      skip,
      savePlan,
      t,
      path,
    } = this.props;

    const isInMobileViewSm = width === "sm" || width === "xs";

    return (
      <Grid item xs={12}>
        {isInMobileViewSm ? (
          <div style={{ position: "relative" }}>
            {skip && (
              <button
                type={this.state.isInvitation ? "submit" : "button"}
                style={{
                  background: "rgba(0, 0, 0, 0.6)",
                  width: "73px",
                  height: "26px",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "none",
                }}
                onClick={this.nextOrSave}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    color: "#FFFF",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {t("Skip")}
                </Typography>
              </button>
            )}

            <img
              src={`${process.env.PUBLIC_URL}/${image}`}
              alt=""
              style={{
                width: "100%",
              }}
            />
            {titleImage || title || subtitle ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 12,
                }}
              >
                {titleImage ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/${titleImage}`}
                    alt=""
                    style={{
                      height: 18,
                      marginRight: 5,
                    }}
                  />
                ) : (
                  ""
                )}
                {title ? (
                  <Typography
                    variant="h5"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: 16,
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    {title}
                  </Typography>
                ) : (
                  ""
                )}
                {subtitle ? (
                  <Typography
                    variant="h5"
                    style={{
                      fontFamily: "Avenir",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#000000",

                      textAlign: "center",
                    }}
                  >
                    {subtitle}
                  </Typography>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </Grid>
    );
  }
}

PlanImage.propTypes = {
  classes: PropTypes.object,
  image: PropTypes.string,
};

export default connect((store) => {
  return {
    auth: store.auth,
    userPlans: store.userPlans,
  };
})(
  withTranslation("plan")(
    withWidth()(withStyles(styles, { withTheme: true })(memo(PlanImage))),
  ),
);
