import { dateFormat } from "./date-helper";
import moment from "moment";

const getFullCurrentTraining = (
  currentTraining,
  chainedTrainings,
  previousPlans = [],
) => {
  if (
    !currentTraining ||
    !currentTraining.days ||
    !currentTraining.days.length
  ) {
    return null;
  }
  const fullTraining = {
    ...currentTraining,
  };

  fullTraining.days = [...currentTraining.days];

  chainedTrainings.forEach((element) => {
    if (element && element.days) {
      fullTraining.days = [...fullTraining.days, ...element.days];
    }
  });

  previousPlans.forEach((element) => {
    if (element && element.days) {
      fullTraining.days = [...element.days, ...fullTraining.days];
    }
  });

  fullTraining.days = fullTraining.days.filter((d) => !!d);

  fullTraining.startingDate =
    fullTraining && fullTraining.days && fullTraining.days.length
      ? fullTraining.days[0][0].plannedDate
      : moment().format(dateFormat);

  const endDate =
    fullTraining && fullTraining.days && fullTraining.days.length
      ? fullTraining.days[fullTraining.days.length - 1][0].plannedDate
      : moment().format(dateFormat);

  fullTraining.durationInWeeks =
    moment(endDate, dateFormat)
      .startOf("isoWeek")
      .diff(
        moment(fullTraining.startingDate, dateFormat).startOf("isoWeek"),
        "week",
      ) + 1;

  return fullTraining;
};

const getWeekDurations = (currentTraining, chainedTrainings) => {
  if (!currentTraining) {
    return null;
  }

  const weekDurations = [+currentTraining.durationInWeeks];

  chainedTrainings.forEach((element) => {
    if (element.durationInWeeks) {
      weekDurations.push(+element.durationInWeeks);
    }
  });

  return weekDurations;
};

const getPlan = (plans, date) => {
  for (let i = 0; i < plans.length; i++) {
    if (plans[i] && plans[i].currentTraining && plans[i].currentTraining.days) {
      const days = plans[i].currentTraining.days.filter((d) => d !== null);
      if (
        date.isBetween(
          moment(days[0][0].plannedDate, dateFormat).startOf("isoWeek"),
          moment(days[days.length - 1][0].plannedDate, dateFormat).endOf(
            "isoWeek",
          ),
          "day",
          "[]",
        )
      ) {
        return plans[i];
      }
    }
  }
  return null;
};

export { getFullCurrentTraining, getPlan, getWeekDurations };
