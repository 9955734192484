import React, { useEffect } from "react";
import {
  Box,
  Button,
  LinearProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { updatePlan } from "../../actions/user-plans-action";
import {
  initAuthorizationGarmin,
  secondStepAuthorizationGarmin,
  deleteAuthorizationGarmin,
  clearActivityGarmin,
} from "../../actions/garmin-action";
import { garminAuthorizationUrl } from "../../constants/watches-auth-uri";
import bugsnagClient from "../../lib/bugsnag-client";
import CustomButton from "./v2/CustomButton";
import GarminIcon from "../icons/GarminIcon";
import { palette } from "../../theme/palette";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import history from "../../lib/history-helper";

const GarminConnect = ({ v3 = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("settings");
  const { device } = useParams();
  const location = useLocation();
  const { userPlans, garmin, subscriptions } = useSelector((state) => state);
  const { currentPlan } = userPlans;
  const { initializing, connected } = garmin;
  const { loadingLatest } = subscriptions;
  const { cognito_user_sub, garmin_oauth_token, garmin_oauth_token_secret } =
    currentPlan || "";
  const isGarminConnected =
    (currentPlan &&
      currentPlan.garmin_oauth_token &&
      currentPlan.garmin_oauth_token_secret) ||
    connected;

  const loadQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = value;
    }
    return params;
  };

  const load = () => {
    try {
      const params = loadQueryParams();

      if (isGarminConnected) return false;

      const deviceParam = device
        ? device === "garmin"
        : location.pathname.includes("garmin");

      if (deviceParam) {
        if (Object.keys(params).length <= 0)
          throw new Error("No query params found");

        if (!params.oauth_token && !params.oauth_verifier)
          throw new Error(
            "Error oauth token and oauth_verifier not found: " +
              JSON.stringify(params),
          );

        if (!garmin_oauth_token_secret)
          throw new Error("Error oauth token secret not found");

        dispatch(
          secondStepAuthorizationGarmin(
            params.oauth_token,
            params.oauth_verifier,
            cognito_user_sub,
            garmin_oauth_token_secret,
            t("Syncing Garmin"),
          ),
        );
      }
    } catch (error) {
      bugsnagClient.notify(error);
    }
  };

  const connect = () => {
    history.push("/dashboard");
    dispatch(initAuthorizationGarmin(cognito_user_sub, garminAuthorizationUrl));
  };

  const disconnect = () => {
    if (!garmin_oauth_token && !garmin_oauth_token_secret) return false;

    dispatch(
      deleteAuthorizationGarmin(garmin_oauth_token, garmin_oauth_token_secret),
    );

    dispatch(
      updatePlan({
        cognito_user_sub,
        garmin_oauth_token: null,
        garmin_oauth_token_secret: null,
      }),
    );
    dispatch(clearActivityGarmin());
  };

  useEffect(() => {
    if (garmin_oauth_token_secret) load();
  }, [garmin_oauth_token_secret]);

  if (v3) {
    return (
      <>
        <Box className={classes.boxDevice}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <GarminIcon />
              <Typography variant="body1" className={classes.connectTitleV2}>
                {"Garmin"}
              </Typography>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                gap: 5,
              }}
            >
              {(userPlans.loading && !initializing) || loadingLatest ? (
                <CustomButton
                  typeStyle="disabled"
                  color="default"
                  variant="contained"
                  className={classes.buttonConnect}
                  size="small"
                >
                  <CircularProgress size={14} />
                </CustomButton>
              ) : (
                <CustomButton
                  typeStyle={
                    isGarminConnected && !initializing ? "secondary" : "primary"
                  }
                  color="default"
                  variant="contained"
                  size="small"
                  className={classes.buttonConnect}
                  onClick={
                    isGarminConnected && !initializing ? disconnect : connect
                  }
                >
                  {isGarminConnected && !initializing
                    ? t("settings.watchDisconnect")
                    : t("settings.watchConnect")}
                </CustomButton>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <div className={classes.connectContainer}>
        <div className={classes.connectFlexContainer}>
          <div className={classes.connectTextContainer}>
            <Typography variant="body1" className={classes.connectTitle}>
              {t("Connect with Garmin")}
            </Typography>
            {!userPlans.loading || !loadingLatest ? (
              <div className={classes.connectText}>
                <Typography variant="body1">
                  {t("Status")}
                  {": "}
                  {!initializing ? (
                    isGarminConnected ? (
                      <span style={{ color: "green" }}>{t("Connected")}</span>
                    ) : (
                      t("Not connected")
                    )
                  ) : (
                    t("Connecting")
                  )}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/garmin.png`}
            alt=""
            className={classes.logo}
          />
        </div>
        {(userPlans.loading && !initializing) || loadingLatest ? (
          <LinearProgress color="primary" variant="indeterminate" />
        ) : isGarminConnected && !initializing ? (
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonConnect}
            style={{ backgroundColor: "#c9927d" }}
            onClick={disconnect}
          >
            {t("Disconnect Garmin")}
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonConnect}
            onClick={connect}
          >
            {t("Connect Garmin")}
          </Button>
        )}
      </div>
    </>
  );
};

export default GarminConnect;

const useStyles = makeStyles({
  boxDevice: {
    display: "flex",
    flexDirection: "column",
    padding: "4px 24px",
  },
  sync: {
    //styleName: p2-r;
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
    color: palette.darkGray,
  },
  connectTitleV2: {
    //styleName: p1-b;
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 700,
    color: palette.black,
  },
  strava: {
    background: "#ed4834",
    color: "white",
  },

  text: {
    fontFamily: "Avenir",
    fontSize: 16,
    color: "#202020",
    margin: "10px 0",
  },

  buttonConnect: {
    margin: "10px auto",
    boxShadow: "none",
    borderRadius: 50,
    width: 105,
    height: 30,
    fontSize: 14,
    padding: "4px 24px",
  },

  connectContainer: {
    background: "#FFFFFF",
    borderRadius: 10,
    padding: 25,
    maxWidth: 300,
    marginBottom: 20,
    textAlign: "center",
  },

  connectTitle: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 20,
    color: "#202020",
    textAlign: "left",
  },

  connectText: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#202020",
    textAlign: "left",
  },

  connectFlexContainer: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
  },
});
