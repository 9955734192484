import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { useEffect, useState } from "react";

const useRaces = () => {
  const { userPlans } = useSelector((state) => state);
  const { currentPlan, updating } = userPlans || {};
  const { nextRaces } = currentPlan || {};
  const [races, setRaces] = useState(nextRaces);

  useEffect(() => {
    nextRacesFilter();
  }, [nextRaces]);

  const nextRacesFilter = () => {
    const currentDate = dayjs();
    const midnight = dayjs().endOf("day");

    const filteredRaces = (nextRaces || []).filter((item) => {
      const raceDateDayjs = dayjs(item.raceDate, "DD/MM/YYYY");
      const difference = raceDateDayjs.diff(currentDate, "day");

      if (difference === 0 && raceDateDayjs.isBefore(midnight)) {
        return true;
      }

      if (raceDateDayjs.isAfter(currentDate)) {
        return true;
      }

      return false;
    });

    const allRaces = sortRacesData(filteredRaces, "ascending");
    setRaces(allRaces);
  };

  const sortRacesData = (data = [], orderBy = "descending") => {
    const ordered = data.sort((a, b) => {
      const itemA = dayjs(a.raceDate, "DD/MM/YYYY").valueOf();
      const itemB = dayjs(b.raceDate, "DD/MM/YYYY").valueOf();
      return orderBy === "descending" ? itemB - itemA : itemA - itemB;
    });
    return ordered;
  };

  return {
    updating,
    nextRaces,
    races,
  };
};

export default useRaces;
