const WorkoutHrZones = {
  z1: "Z1: Warm up",
  z2: "Z2: Easy",
  z3: "Z3: Aerobic",
  z4: "Z4: Threshold",
  z5: "Z5: Maximum",
};

export default {
  Items: WorkoutHrZones,
  itemsArray: Object.keys(WorkoutHrZones).map((key) => {
    return {
      text: WorkoutHrZones[key],
      value: key,
    };
  }),
  ItemsShortArray: Object.keys(WorkoutHrZones).map((key) => {
    return {
      text: key,
      value: key,
    };
  }),
};
