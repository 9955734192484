import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import classNames from "classnames";

const styles = (theme) => ({
  dayText: {
    fontWeight: "normal",
    color: "#212121",
    fontSize: 12,
  },
  daySubText: {
    fontWeight: "normal",
    color: "#212121",
    fontSize: 12,
  },
  todayDateContainer: {
    fontWeight: 500,
    color: "#1E629B",
    width: 60,
  },
  dayContainer: {
    marginRight: 12,
    width: 60,
  },
  dayContainerCoach: {
    paddingBottom: "2px",
    borderBottom: "1px solid rgba(0,0,0,0.2)",
  },
  dateCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dateChild: {
    display: "flex",
    flexDirection: "column",
  },
});

class DashboardTrainingWeekdayDay extends Component {
  render() {
    const {
      classes,
      title,
      subtitle,
      className,
      hasTraining,
      isInMobileView,
      isCoachView,
    } = this.props;

    return (
      <div
        className={classNames(
          isCoachView ? classes.dayContainerCoach : classes.dayContainer,
          className,
        )}
      >
        {hasTraining ? (
          <div className={isCoachView ? classes.dateCell : ""}>
            <div className={isCoachView ? classes.dateChild : ""}>
              <Typography
                variant={isInMobileView ? "subtitle2" : "h6"}
                className={classNames(classes.dayText, className)}
              >
                {title}
              </Typography>
              <Typography
                variant={isInMobileView ? "subtitle2" : "h6"}
                className={classNames(classes.dayText, className)}
              >
                {subtitle}
              </Typography>
            </div>
          </div>
        ) : (
          <>
            <Typography
              variant={isInMobileView ? "subtitle2" : "h6"}
              className={classNames(classes.dayText, className)}
            >
              {title}
            </Typography>
            <Typography
              variant={isInMobileView ? "subtitle2" : "h6"}
              className={classNames(classes.dayText, className)}
            >
              {subtitle}
            </Typography>
          </>
        )}
      </div>
    );
  }
}

DashboardTrainingWeekdayDay.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  className: PropTypes.string,
  detailLink: PropTypes.string,
  hasTraining: PropTypes.bool,
  isInMobileView: PropTypes.bool,
  isCoachView: PropTypes.bool,
};
export default withStyles(styles, { withTheme: true })(
  DashboardTrainingWeekdayDay,
);
