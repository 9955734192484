import React, { useState, useEffect, useMemo } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  ListItemIcon,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  LinearProgress,
  Divider,
} from "@material-ui/core";
import Dashboard from "@material-ui/icons/Dashboard";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import {
  getAdminData,
  getAdminCoachesData,
  getAdminCategoriesData,
  clearAdminDashboard,
} from "../../../actions/admin-action";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import { getCoaches } from "../../../actions/athletes-action";
import { getRandomHexColor } from "../../../lib/common";
import { adminChartoptions } from "../../../constants/charts";
import { getSubcategories } from "../../../actions/subcategory-action";
import {
  buildTableData,
  groupByDate,
  removeDuplicatesByKeys,
  getAllWeeks,
  handleDifference,
} from "./utils";
import TimeRange from "./time-range";

const states = [
  { name: "active", color: "#45B765" },
  { name: "in_trial", color: "#FE6221" },
  { name: "non_renewing", color: "#7856FF" },
];

const sidebar = [
  "General Customers count",
  "Customers count by coach",
  "Customers count by Subcategory",
];

const useStyles = makeStyles({
  sidebar: {
    width: 240,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  dialog: {
    backgroundColor: "rgba(0,0,0,0.9)",
  },
  player: {
    marginTop: "5%",
  },
  title: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#475569",
    textAlign: "left",
  },
  itemParent: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    borderRadius: 10,
    cursor: "pointer",
    transition: "all 0.6s",

    "&:hover": {
      backgroundColor: "#FB6222",
      transition: "all 0.6s",
    },

    "&:hover span": {
      color: "#FFF",
      transition: "all 0.6s",
    },

    "&:hover svg": {
      color: "#FFF",
      transition: "all 0.6s",
    },
  },
  selected: {
    backgroundColor: "#FB6222",
    "& span": {
      color: "#FFF",
    },

    "& svg": {
      color: "#FFF",
    },
  },
  drawertTitle: {
    fontWeight: "bold",
    marginLeft: "12px",
  },
  main: {
    marginLeft: 240,
    width: "100%",
    marginTop: 24,
  },
  tableParent: {
    padding: "0px 50px",
    marginTop: "20px",
    marginBottom: "30px",
    overflowX: "auto",
    boxSizing: "border-box",
    width: "calc(100% - 240px)",
  },
});

const AdminDashboard = () => {
  const history = useHistory();
  const { data, coachesData, loading, categoriesData } = useSelector(
    (state) => state.admin,
  );
  const { coachesList } = useSelector((state) => state.athletes);
  const { data: subcategoriesData } = useSelector(
    (state) => state.subcategories,
  );

  const localList = [...coachesList].map((item) => ({
    name: item.label,
    color: getRandomHexColor(),
    value: item.value,
  }));

  const localSubcategories = [...subcategoriesData].map((item) => ({
    name: item.title.en,
    color: getRandomHexColor(),
    value: item.id.toString(),
  }));

  const [accumulation, setAccumulation] = useState([]);
  const [categoriesAccumulation, setCategoriesAccumulation] = useState([]);
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [currentStatus, setCurrentStatus] = useState({});
  const [currentOption, setCurrentOption] = useState(0);
  const [checkItems, setCheckItems] = useState(states);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isEmpty = !Object.keys(currentStatus).length;
  const localstates = isEmpty ? states : [currentStatus];

  const goBack = () => history.push("/");

  const defaultRange = handleDifference(1, "month");
  const [currentRange, setCurrentRange] = useState({
    name: "30D",
    date: defaultRange,
  });

  const handleOption = (option) => setCurrentOption(option);

  const [status, ...tableLabels] = headers;

  const handleStatus = (status) => {
    if (currentStatus.name !== status.name) {
      if (currentOption === 0)
        dispatch(getAdminData(status.name, currentRange.date));
      setCurrentStatus(status);

      if (currentOption === 1)
        dispatch(getAdminCoachesData(status.name, currentRange.date));
      setCurrentStatus(status);

      if (currentOption === 2)
        dispatch(getAdminCategoriesData(status.name, currentRange.date));
      setCurrentStatus(status);
      return;
    }
    if (currentStatus.name === status.name) {
      if (currentOption === 0) getAllStatuses(currentRange.date);
      if (currentOption === 1) getAllAdminCoaches(currentRange.date);
      if (currentOption === 2) getAllAdminCategories(currentRange.date);
      setCurrentStatus({});
    }
  };

  const formatHeader = (item) => {
    const formattedDate = `${dayjs(item).format("MMM")} ${dayjs(item).format(
      "DD",
    )}'${dayjs(item).format("YY")}`;
    return item.includes("-") ? formattedDate : item;
  };

  const formatLabels = () => {
    const formatedLabels = [...tableLabels].map((item) => {
      return formatHeader(item);
    });
    return formatedLabels;
  };

  const distanceData = {
    labels: [...formatLabels()],
    datasets: [...buildTableData(rows, tableLabels)],
  };

  useEffect(() => {
    dispatch(getCoaches());
    dispatch(getSubcategories());

    return () => {
      dispatch(clearAdminDashboard());
    };
  }, []);

  const buildCoachesRows = (coachesData) => {
    const copy = [...JSON.parse(JSON.stringify(coachesData))];
    const result = copy.reduce((acc, current) => {
      const toArray = Object.entries(current.data);

      let currentArray = [];
      toArray.forEach(([date, data]) => {
        const newStructure = (data || []).map((subItem) => ({
          ...subItem,
          current_date: date,
        }));
        currentArray = [...currentArray, ...newStructure];
      });

      current.data = currentArray;
      return [...acc, current];
    }, []);

    [...(result || [])].forEach((item) => {
      const byDate = groupByDate(item.data, currentRange);
      const entries = Object.entries(byDate);

      const lastArray = {};
      entries.forEach(([date, data]) => {
        const grouped = data.reduce((acc, obj) => {
          const key = obj["coach_cognito_user_sub"];
          if (!acc[key]) {
            acc[key] = [];
          }
          obj.date = date;
          acc[key].push(obj);
          return acc;
        }, {});

        const values = Object.values(grouped);
        const lastElement = (values || []).map((elem) => {
          const last = elem.at(-1);
          last.date = date;
          return last;
        });

        lastArray[`${date}`] = lastElement;
      });

      let mergedArrays = [];
      for (const property in lastArray)
        mergedArrays = [...mergedArrays, ...lastArray[property]];

      const all = [...mergedArrays].reduce((accumulator, current) => {
        const filtered = [...mergedArrays].filter(
          (subItem) =>
            subItem["coach_cognito_user_sub"] ===
            current["coach_cognito_user_sub"],
        );
        const newObject = {};
        if (filtered.length) {
          filtered.map((sub) => {
            newObject[`${sub.date}`] = sub.count;
            newObject["coach_cognito_user_sub"] = sub.coach_cognito_user_sub;
          });
        }

        current = newObject;
        return [...accumulator, current];
      }, []);

      const newArray = [...localList]
        .map((coach) => {
          const match = all.find(
            (item) => item.coach_cognito_user_sub == coach.value,
          );

          return {
            ...match,
            status: coach.name,
            color: coach.color,
            hidden: false,
          };
        })
        .filter((coach) => coach["coach_cognito_user_sub"]);

      if (coachesData.length === 3) {
        const localData = [...accumulation, ...newArray];
        const result = calculateTotalAllStatuses(
          localData,
          "coach_cognito_user_sub",
        );
        setRows(result);
        setCheckItems(localList);
        return;
      }

      memoizedAddToArray(newArray);
      setRows(newArray);
      setCheckItems(localList);
    });
  };

  const buildCategoriesRows = (categoriesData) => {
    const copy = [...JSON.parse(JSON.stringify(categoriesData))];
    const result = copy.reduce((acc, current) => {
      const toArray = Object.entries(current.data);

      let currentArray = [];
      toArray.forEach(([date, data]) => {
        const newStructure = (data || []).map((subItem) => ({
          ...subItem,
          current_date: date,
        }));
        currentArray = [...currentArray, ...newStructure];
      });

      current.data = currentArray;
      return [...acc, current];
    }, []);

    [...(result || [])].forEach((item) => {
      const byDate = groupByDate(item.data, currentRange);
      const entries = Object.entries(byDate);

      const lastArray = {};
      entries.forEach(([date, data]) => {
        const grouped = data.reduce((acc, obj) => {
          const key = obj["subcategory"];
          if (!acc[key]) {
            acc[key] = [];
          }
          obj.date = date;
          acc[key].push(obj);
          return acc;
        }, {});

        const values = Object.values(grouped);
        const lastElement = (values || []).map((elem) => {
          const last = elem.at(-1);
          last.date = date;
          return last;
        });

        lastArray[`${date}`] = lastElement;
      });

      let mergedArrays = [];
      for (const property in lastArray)
        mergedArrays = [...mergedArrays, ...lastArray[property]];

      const all = [...mergedArrays].reduce((accumulator, current) => {
        const filtered = [...mergedArrays].filter(
          (subItem) => subItem["subcategory"] === current["subcategory"],
        );
        const newObject = {};
        if (filtered.length) {
          filtered.map((sub) => {
            newObject[`${sub.date}`] = sub.count;
            newObject["subcategory"] = sub.subcategory;
          });
        }

        current = newObject;
        return [...accumulator, current];
      }, []);

      const newArray = [...localSubcategories]
        .map((subcategory) => {
          const match = all.find((item) => {
            return item.subcategory == subcategory.value;
          });

          if (!match) return {};
          return {
            ...match,
            status: subcategory.name,
            color: subcategory.color,
            hidden: false,
          };
        })
        .filter((subcategory) => subcategory["subcategory"]);

      if (categoriesData.length === 3) {
        const localData = [...categoriesAccumulation, ...newArray];
        const result = calculateTotalAllStatuses(localData, "subcategory");
        setRows(result);
        setCheckItems(localSubcategories);
        return;
      }

      memoizedAddArray(newArray);
      setRows(newArray);
      setCheckItems(localSubcategories);
    });
  };

  const addToArray = (newArray) => {
    setAccumulation((prev) => {
      const [first, ...rest] = headers;
      const result = removeDuplicatesByKeys(prev, ["status", rest]);
      return [...result, ...newArray];
    });
  };

  const memoizedAddToArray = useMemo(() => {
    return addToArray;
  }, []);

  const addToCategoriesArray = (newArray) => {
    setCategoriesAccumulation((prev) => {
      const [first, ...rest] = headers;
      const result = removeDuplicatesByKeys(prev, ["status", rest]);
      return [...result, ...newArray];
    });
  };

  const memoizedAddArray = useMemo(() => {
    return addToCategoriesArray;
  }, []);

  const calculateTotal = (data, key) => {
    let total = 0;
    data.forEach((item) => {
      if (item.hasOwnProperty(key)) {
        total += Number(item[key] || "0");
      }
    });
    return total;
  };

  const calculateTotalAllStatuses = (localData, key) => {
    const finalArray = [];
    localData.forEach((item) => {
      const theresult = localData.filter(
        (subItem) => subItem[key] === item[key],
      );

      let newObject = {};
      theresult.forEach((item) => {
        for (const property in item) {
          if (property.includes("-"))
            newObject[property] = calculateTotal(theresult, property);
        }
      });

      if (!finalArray.some((local) => local[key] === item[key]))
        finalArray.push({ ...item, ...newObject });
    });

    return finalArray;
  };

  const buildRows = (localStates) => {
    const rows = [...(data || [])].map((item) => {
      const result = groupByDate(item.data, currentRange);
      const rowData = {};
      const index = states.findIndex(({ name }) => item.status === name);

      Object.entries(result).forEach(([key, value]) => {
        rowData[`${key}`] = value.at(-1).count;
      });

      return {
        status: item.status,
        ...rowData,
        color: states[index].color,
        hidden: false,
      };
    });

    setRows(rows);
    setCheckItems(localStates);
  };

  const getAllStatuses = async (date, coaches = false) => {
    await dispatch(getAdminData("active", date, coaches));
    await dispatch(getAdminData("in_trial", date, coaches));
    await dispatch(getAdminData("non_renewing", date, coaches));
  };

  useEffect(() => {
    if (currentOption === 0) {
      isEmpty
        ? getAllStatuses(currentRange.date)
        : dispatch(getAdminData(currentStatus.name, currentRange.date));
    }
    if (currentOption === 1) {
      isEmpty
        ? getAllAdminCoaches(currentRange.date)
        : dispatch(getAdminCoachesData(currentStatus.name, currentRange.date));
    }

    if (currentOption == 2) {
      isEmpty
        ? getAllAdminCategories(currentRange.date)
        : dispatch(
            getAdminCategoriesData(currentStatus.name, currentRange.date),
          );
    }
  }, [currentOption]);

  useEffect(() => {
    getAllWeeks(currentRange.date, setHeaders);
    if (data && currentOption === 0) buildRows(localstates);
    if (coachesData && currentOption === 1) buildCoachesRows(coachesData);
    if (categoriesData && currentOption === 2)
      buildCategoriesRows(categoriesData);
  }, [coachesData, data, categoriesData]);

  const getAllAdminCoaches = async (date) => {
    await dispatch(getAdminCoachesData("active", date, true));
    await dispatch(getAdminCoachesData("in_trial", date, true));
    await dispatch(getAdminCoachesData("non_renewing", date, true));
  };

  const getAllAdminCategories = async (date) => {
    await dispatch(getAdminCategoriesData("active", date));
    await dispatch(getAdminCategoriesData("in_trial", date));
    await dispatch(getAdminCategoriesData("non_renewing", date));
  };

  const handleAllChecks = (localRow) => {
    if (currentOption === 0) return handleTableCheck(localRow);
    if (currentOption === 1) return handleCheckTable(localRow);
    handleCheckTable(localRow);
  };

  const handleTableCheck = (localRow) => {
    const copyRows = [...rows];
    const copyCheckItems = [...checkItems];

    const index = copyCheckItems.findIndex(
      (item) => item.name == localRow.status,
    );
    const rowIndex = copyRows.findIndex(
      (item) => item.status == localRow.status,
    );

    if (index > -1) {
      copyCheckItems.splice(index, 1);
      copyRows[rowIndex].hidden = true;
    }

    if (index === -1) {
      copyCheckItems.push({ name: localRow.status, color: localRow.color });
      copyRows[rowIndex].hidden = false;
    }

    setCheckItems(copyCheckItems);
    setRows(copyRows);
  };

  const handleCheckTable = (localRow) => {
    const copyRows = [...rows];
    const copyCheckItems = [...checkItems];
    const index = copyCheckItems.findIndex(
      (item) => item.name === localRow.status,
    );

    const rowIndex = copyRows.findIndex(
      (item) => item.status == localRow.status,
    );

    if (index > -1) {
      copyCheckItems.splice(index, 1);
      copyRows[rowIndex].hidden = true;
    }
    if (index === -1) {
      copyCheckItems.push({
        name: localRow.status,
        color: localRow.color,
        status: localRow.label,
      });
      copyRows[rowIndex].hidden = false;
    }

    setCheckItems(copyCheckItems);
    setRows(copyRows);
  };

  const selectAll = () => {
    const copyRows = [...rows];
    const copyCheckItems = [...checkItems];

    if (copyCheckItems.length) {
      const unselected = copyRows.map((item) => {
        return {
          ...item,
          hidden: true,
        };
      });

      copyCheckItems.length = 0;
      setCheckItems(copyCheckItems);
      setRows(unselected);
      return;
    }

    if (currentOption === 0) buildRows(localstates);
    if (currentOption === 1) buildCoachesRows(coachesData);
    if (currentOption === 2) buildCategoriesRows(categoriesData);
  };

  return (
    <Box width="100%">
      {loading && (
        <LinearProgress
          variant="indeterminate"
          color="primary"
          style={{ zIndex: 10099 }}
        />
      )}
      <Box className={classes.main}>
        <Box display="flex" justifyContent="center">
          <Box display="flex" height={35}>
            <TimeRange
              currentRange={currentRange}
              currentStatus={currentStatus}
              isEmpty={isEmpty}
              setCurrentRange={setCurrentRange}
              currentOption={currentOption}
            />
            <Box marginLeft={6}>
              <Select
                style={{ height: 35 }}
                label={"Gender do you identify with"}
                labelId="gender-label"
                id="sex"
                name="sex"
                variant="outlined"
                value={0}
                disableUnderline
              >
                <MenuItem value={0} style={{ height: 35 }}>
                  Weekly
                </MenuItem>
              </Select>
            </Box>
            <Box display="flex" marginLeft={6}>
              {states.map((item) => (
                <FormControlLabel
                  checked={item.name == currentStatus.name}
                  key={item.name}
                  control={<Checkbox style={{ color: item.color }} />}
                  label={item.name}
                  onChange={() => handleStatus(item)}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          height="300px"
          marginTo="30px"
          padding="0px 50px"
          marginTop="20px"
          overflowX="scroll"
          boxSizing="border-box"
          width="calc(100% - 240px)"
        ></Box>

        <div className={classes.tableParent}>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormControlLabel
                    control={<Checkbox style={{ color: "#8F8F91" }} />}
                    label="Unselect all"
                    checked={!checkItems.length}
                    onChange={() => selectAll()}
                  />
                </TableCell>
                {headers.map((item, index) =>
                  index > 0 ? (
                    <TableCell key={index}>{formatHeader(item)}</TableCell>
                  ) : null,
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.status}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ minWidth: 200 }}
                  >
                    <FormControlLabel
                      control={<Checkbox style={{ color: row.color }} />}
                      label=""
                      checked={checkItems.some(
                        (item) => item.name == row.status,
                      )}
                      onChange={() => handleAllChecks(row)}
                    />
                    {row.status}
                  </TableCell>
                  {tableLabels.map((key, index) => {
                    return (
                      <TableCell
                        key={index}
                        align="left"
                        component="th"
                        scope="row"
                      >
                        {row[key] || 0}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>
      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
          },
        }}
        classes={{
          paper: classes.sidebar,
        }}
        variant="permanent"
        anchor="left"
      >
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop={2}
            style={{ cursor: "pointer" }}
            onClick={goBack}
          >
            <img
              src={`${process.env.PUBLIC_URL}/icon-arrow-back-black.svg`}
              alt="arrow-back"
              className={classes.iconBack}
              onClick={null}
              style={{ width: 15 }}
            />
            <Typography variant="body2" className={classes.drawertTitle}>
              Admin Dashboard
            </Typography>
          </Box>
          <List style={{ marginTop: 30 }}>
            {sidebar.map((option, index) => (
              <ListItem
                disablePadding
                key={index}
                onClick={() => handleOption(index)}
              >
                <Box
                  className={classNames(
                    classes.itemParent,
                    index === currentOption ? classes.selected : null,
                  )}
                >
                  <ListItemIcon>
                    <Dashboard color="#FFF" />
                  </ListItemIcon>
                  <ListItemText
                    primary={option}
                    classes={{ primary: classes.title }}
                    style={{ color: "#FFF !important" }}
                  />
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>

        <List>
          <Divider
            style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
          />
          <ListItem disablePadding>
            <Box
              className={classes.itemParent}
              style={{ width: "100%" }}
              onClick={goBack}
            >
              <ListItemIcon>
                <ExitToApp style={{ transform: "scaleX(-1)" }} />
              </ListItemIcon>
              <ListItemText
                primary="Back to profile"
                classes={{ primary: classes.title }}
              />
            </Box>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default AdminDashboard;
