import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import bugsnagClient from "../../../lib/bugsnag-client";
import { ErrorFallbackDefault } from "./ErrorFallbackDefault";

const CustomErrorBoundary = ({ fallbackComponent, children }) => {
  const FallbackComponent = fallbackComponent || ErrorFallbackDefault;

  /**
   * Send event to service
   * @param {*} error
   * @param {*} errorInfo
   */
  const onError = (error, errorInfo) => {
    console.log("ErrorBoundary onError:", { error, errorInfo });

    bugsnagClient.notify(error, (event) => {
      event.addMetadata("errorInfo", errorInfo);
    });
  };

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
      {children}
    </ErrorBoundary>
  );
};

export default CustomErrorBoundary;
