import React from "react";
import { SvgIcon } from "@material-ui/core";
import { palette } from "../../theme/palette";

const ElevationIcon = ({
  width = 24,
  height = 24,
  activated = false,
  color = palette.darkGray,
}) => (
  <>
    <SvgIcon>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill={activated ? palette.primary : "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.66634 4L12.6663 10.3704L17.333 7.33333L21.333 20H3.33301L8.66634 4Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  </>
);
export default ElevationIcon;
