import React, { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import unitsHook from "../../../../hooks/unitsHook";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Box, makeStyles } from "@material-ui/core";
import { palette } from "../../../../theme/palette";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import MountainIndexBar from "../../../generals/mountain-index-slider";
import useUserInfo from "../../../../hooks/useUserInfo";
import useCalculatorMI from "../../../../hooks/useCalculatorMI";
import RaceShortInfo from "../../../common/v2/RaceShortInfo";
import useActivities from "../../../../hooks/useActivities";

const MountainIndexSection = ({ onClose, showNextRace = true }) => {
  const { t } = useTranslation("mountainIndex");
  const classes = useStyles();
  const units = unitsHook();
  const { plan, nextRaces, currentDate, toDate, currentFilter } = useUserInfo();
  const [currentRace, setCurrentRace] = useState(null);
  const [average, setAverage] = useState(0);
  const {
    calculateTotals,
    calculateRaceComparison,
    calculateValue,
    convertDistanceToUnit,
    convertElevationToUnit,
    getMessageForMountainIndex,
  } = useCalculatorMI();
  const { activities } = useActivities();
  const getNextRace = () => {
    if (!nextRaces && !nextRaces.length) return null;

    const futureRaces = Array.from(nextRaces).filter(
      (race) =>
        moment(race.raceDate, "DD/MM/YYYY").isSameOrAfter(moment()) &&
        race.raceDistance &&
        race.raceElevation,
    );

    if (futureRaces.length <= 0) return null;

    const priorityOrder = ["A", "B", "C"];
    for (const priority of priorityOrder) {
      const workout = futureRaces.find(
        (item) => item.racePriority === priority,
      );
      if (workout) {
        setCurrentRace(workout);
        return workout;
      }
    }
  };

  const filterActivities = (index) => {
    if (index == 0) return filterByRange(0); // week filter
    if (index == 1) return filterByRange(4, false); // month filter
    if (index == 2) return fullPlanFilter(plan);
    return activities;
  };

  const fullPlanFilter = (plan) => {
    if (!plan || !plan.length) {
      return [];
    }

    const filteredPlan = plan
      .filter((item) => item)
      .sort((a, b) => {
        const aOf = a[0] ? a[0] : a;
        const bOf = b[0] ? b[0] : b;
        const aDate = moment(aOf.plannedDate, "DD/MM/YYYY").valueOf();
        const bDate = moment(bOf.plannedDate, "DD/MM/YYYY").valueOf();
        return aDate - bDate;
      });

    const firstPlannedDate = filteredPlan[0][0]
      ? filteredPlan[0][0].plannedDate
      : false;
    if (!firstPlannedDate) {
      return [];
    }

    const filteredActivities = (activities || []).filter((activity) => {
      const date = activity.date ? activity.date : activity.start_date;
      return moment(date).isSameOrAfter(moment(firstPlannedDate, "DD/MM/YYYY"));
    });

    return filteredActivities;
  };

  const filterByRange = (range, weekly = true) => {
    return (activities || []).filter((activity) => {
      if (activity) {
        const date = activity.date ? activity.date : activity.start_date;
        const activityDate = dayjs(date);
        const currentDateFormatted = dayjs(currentDate);
        if (weekly && activityDate.isSame(currentDateFormatted, "week"))
          return activity;
        if (!weekly && activityDate.isSame(currentDateFormatted, "month"))
          return activity;
      }
    });
  };

  useEffect(() => {
    if (activities && activities.length) {
      const localActivities = filterActivities(currentFilter);
      const elevation = convertElevationToUnit(
        calculateTotals("total_elevation_gain", localActivities),
        units,
      );
      const distance = convertDistanceToUnit(
        calculateTotals("distance", localActivities),
        units,
      );
      const result = calculateValue(distance, elevation);
      const currentRace = getNextRace();

      if (!currentRace) setAverage(result);
      if (currentRace) {
        const comparation = calculateRaceComparison(currentRace, result, units);
        setAverage(comparation);
      }
    }
  }, [activities, currentRace, currentFilter, currentDate, toDate]);

  return (
    <div className={classes.parent}>
      <div className={classes.card}>
        <Box
          className={
            showNextRace && currentRace
              ? classes.mainRaceContainer
              : classes.mainTitleContainer
          }
        >
          {showNextRace && currentRace ? (
            <RaceShortInfo
              raceName={currentRace.raceName}
              racePriority={currentRace.racePriority}
              raceMI={calculateValue(
                Number(currentRace.raceDistance),
                Number(currentRace.raceElevation),
              )}
            />
          ) : (
            <>
              <Typography className={classes.title}>
                {t("mountainIndex.title").toUpperCase()}
              </Typography>
              <Link id="mountain_index_info" to="/profile/mountain-index">
                <InfoIcon className={classes.infoIcon} />
              </Link>
            </>
          )}
        </Box>

        <Typography variant="body1" className={classes.subtitle}>
          {t(getMessageForMountainIndex(average, currentRace !== null))}
        </Typography>

        <Box sx={{ margin: "0px 15px" }}>
          <MountainIndexBar value={average} />
        </Box>
      </div>
    </div>
  );
};

export default MountainIndexSection;

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "18px",
    fontWeight: "600",
    textAlign: "center",
    fontFamily: "Oswald",
    marginRight: "8px",
  },
  newRow: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 24,
  },
  message: {
    fontSize: "12px",
    marginLeft: "5px",
  },
  footerText: {
    fontSize: "10px",
    fontStyle: "italic",
    marginTop: 30,
    textAlign: "center",
  },
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },

  bottomScrollPaper: {
    alignItems: "flex-end",
  },
  bottomPaperScrollBody: {
    verticalAlign: "bottom",
  },
  container: {
    margin: "32px",
    boxSizing: "border-box",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    borderRadius: "12px",
    backgroundColor: "#FFF",
  },
  boldText: {
    fontWeight: "bold",
    paddingRight: theme.spacing.unit * 0.5,
  },
  white: {
    color: theme.palette.common.white,
  },
  text: {
    color: theme.palette.text.secondaryDark,
    marginTop: "auto",
    fontSize: "small",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      marginTop: 0,
    },
  },
  raceName: {
    fontWeight: 500,
    fontSize: 15,
    color: "#212121",
    margin: "0px 0px 12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "50%",
  },
  raceBottomTitle: {
    color: "#212121",
    fontSize: 12,
    fontWeight: 400,
    margin: "0 12px 0 5px",
  },
  raceBottomText: {
    color: "#212121",
    fontSize: 14,
    fontWeight: 500,
  },
  raceLocation: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePriorityContainer: {
    display: "flex",
    alignItems: "center",
  },
  racePriority: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePrioritySelected: {
    color: "#212121",
    fontSize: 10,
    background: "rgba(255, 87, 34, 0.24)",
    fontWeight: 500,
    width: "fit-content",
    padding: "0px 4px",
  },
  racePriorityUnselected: {
    margin: "0 5px",
    background: "#FFBA9B",
    borderRadius: "100%",
    fontWeight: 900,
    fontSize: 21,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "34px",
    textAlign: "center",
  },
  raceBottomContainer: {
    display: "flex",
    alignItems: "center",
    margin: "2px 0",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      margin: "0 0 12px 0",
    },
  },
  addButtonContainer: {
    marginLeft: "10px",
    cursor: "pointer",
  },
  shareText: {
    color: "#E16327",
    fontSize: 14,
    fontWeight: "bold",
  },
  addRaceParent: {
    display: "flex",
    justifyContent: "center",
    marginTop: "12px",
    cursor: "pointer",
  },
  addRaceChild: {
    display: "flex",
    alignItems: "center",
  },
  addTitle: {
    color: "#E16327",
    fontWeight: "bold",
    marginLeft: "4px",
    fontSize: "14px",
  },
  parent: {
    height: "fit-content",
    width: "100%",
    margin: "40px 0px",
  },
  card: {
    boxSizing: "border-box",
  },
  raceCard: {
    boxSizing: "border-box",
    margin: "45px 28px 68px 28px",
  },
  goBackParent: {
    margin: "16px 28px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    zIndex: 1500,
  },
  mainTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  mainRaceContainer: {
    display: "contents",
  },
  subtitle: {
    color: palette.darkGray,
    lineHeight: "24px",
    marginBottom: "40px",
    marginTop: "4px",
    fontFamily: "Karla",
  },
  infoIcon: {
    color: palette.deactivated,
    width: "14px",
  },
}));
