import React, { Component, memo } from "react";
import {
  Dialog,
  Grid,
  DialogContent,
  withMobileDialog,
  Typography,
  Button,
  CircularProgress,
  DialogActions,
} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import withWidth from "@material-ui/core/withWidth";
import history from "../../../../lib/history-helper";
import {
  setTrainingDayWorkout,
  currentTrainingUpdateWorkoutIsDone,
  currentTrainingUpdateWorkoutEnergyLevel,
  currentTrainingAddComment,
  currentTrainingAddCommentsRead,
  clearTempTraining,
  clearCurrentUserActivity,
  exportWorkoutToGarmin,
  exportWorkoutToSuunto,
} from "../../../../actions/user-plans-action";
import { dateFormat } from "../../../../lib/date-helper";
import { convertToRTFForView } from "../../../../lib/rtf-helper";
import EstimatedTimes from "../../../../constants/estimated-times";
import WorkoutTypes from "../../../../constants/workout-types";
import RouterLink from "../../../common/router-link";
import { logEvent, setEventsMetadata } from "../../../../lib/events-helper";
import { getPlan } from "../../../../lib/current-training-helper";
import { RateApp } from "capacitor-rate-app";
import Card from "../../dashboard-gps/components/card";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { setFormTrackerStatus } from "../../../../actions/workout-action";
import { Capacitor } from "@capacitor/core";
import { clearActivityVert } from "../../../../actions/vert-activity-action";
import { createAnalyticData } from "../../../../lib/analytic-helper";
import { pushAnalyticData } from "../../../../actions/analytic-action";
import workoutToText from "../../../../lib/workoutToText";
import RaceCounts from "../../../../constants/race-counts";
import { getCustomLevel, getPlanLevel } from "../../../../lib/plan-helper";
import DashboardActivityDetails from "../../dashboard-recent-activities/dashboard-activity-details";
import { getUserActivity } from "../../../../actions/user-plans-action";
import DashboardActivityShare from "../../dashboard-recent-activities/dashboard-activity-share";
import ReactPlayer from "react-player";
import ClickNHold from "react-click-n-hold";
import dayjs from "dayjs";
import "dayjs/locale/es";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import * as localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { InfoOutlined } from "@material-ui/icons";
import MobilePlayer from "../../../../components/generals/mobile-player";
import withActivities from "../../../../hoc/withActivities";
import { useAnalyticsEvent } from "../../../../hooks/useAnalyticsEvent";
import CustomErrorBoundary from "../../../common/error/CustomErrorBoundary";

dayjs.extend(localizedFormat);
dayjs.locale("en");
dayjs.extend(localeData);
dayjs.extend(weekday);

const styles = (theme) => ({
  container: {
    padding: "0 !important",
    background: "#F9F9FB",
    overflow: "hidden",
    minWidth: 650,
    [theme.breakpoints.down("sm")]: {
      minWidth: "initial",
      background: "#FFF",
    },
  },
  dialogPaper: {
    borderRadius: 18,

    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },

  expandedDialog: {
    borderRadius: 18,
    maxWidth: "100%",

    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  textContainer: {
    marginTop: theme.spacing.unit * 2,

    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  newLine: {
    whiteSpace: "pre-line",
  },
  newLineEffort: {
    fontWeight: 500,
    whiteSpace: "pre-line",
  },
  borderedTextContainer: {
    paddingBottom: theme.spacing.unit,
    borderStyle: "groove",
    borderWidth: "thin",
    borderRadius: 5,
    padding: theme.spacing.unit * 2.5,
    minHeight: theme.spacing.unit * 5,
  },
  extraBottomMargin: {
    marginBottom: theme.spacing.unit * 4,

    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },
  topBar: {
    marginTop: 32,
  },

  topItem: {
    margin: "22px 0",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "50%",
    },
  },
  topTitle: {
    color: "#212121",
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 400,
    alignItems: "center",
    display: "flex",
  },
  topTitleIcon: {
    marginRight: 10,
  },
  topText: {
    color: "#212121",
    fontSize: 18,
    fontWeight: 500,
  },
  effort: {
    width: "100%",
    display: "flex",
    marginBottom: "16px",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      // alignItems: "flex-end",
    },
  },
  effortLabel: {
    fontSize: 14,
    color: "#212121",
    fontWeight: 400,

    [theme.breakpoints.down("sm")]: {
      maxWidth: 70,
    },
  },
  effortSelect: {
    margin: 10,
    cursor: "pointer",
    width: 28,
    height: 28,
    [theme.breakpoints.down("sm")]: {
      width: 20,
      height: 20,
      margin: 7,
    },
  },
  workoutColumn: {
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "0px 16px",
      width: "100% !important",
      // margin: "0px 16px 30px 16px",
      // backgroundColor: "#F9F9FB",
      borderRadius: "4px",
      margin: 0,
      // maxWidth: "calc(100vw - 30px)",
      overflow: "hidden",
    },
  },
  chatColumn: {
    padding: "0 !important",
    order: 1,
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      order: 2,
      position: "absolute",
      bottom: 0,
      width: "100vw",
    },
  },
  smallerFontSize: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  rightMargin: {
    marginRight: theme.spacing.unit * 2,
  },
  buttons: {
    borderRadius: 50,
    textTransform: "uppercase",
  },
  borderPlain: {
    borderRadius: 5,
  },
  chatBtn: {
    borderRadius: 5,
  },
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  metadataColumn: {
    background: "#fff",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "0px 16px",
    },
  },
  center: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "initial",
    },
  },

  descTitle: {
    color: "#212121",
    fontSize: 20,
    fontWeight: 500,
    whiteSpace: "pre-line",
  },

  topItemFlex: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    padding: "8px",
    boxSizing: "border-box",
    wordWrap: "break-word",
    marginBottom: "5px",
  },
  grid: {
    margin: "0px 0px 8px 0px",
  },
  statsTitle: {
    fontWeight: "bold",
    fontSize: 16,
  },
  topContainer: {
    display: "flex",
    padding: "20px 0px",
    // backgroundColor: "#FFFFFF",
    justifyContent: "space-between",
    alignItems: "center",
    // backgroundColor: "blue"
  },
  labels: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnsParent: {
    display: "flex",
    flexDirection: "row-reverse",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      width: "100%",
      marginBottom: "16px",
    },
  },
  labelParent: {
    display: "flex",
    justifyContent: "flex-end",
  },

  workoutGeneratedTextContainer: {
    boxShadow: "0px 9px 15px rgba(0, 0, 0, 0.12)",
    padding: 15,
    margin: "20px 0",
    backgroundColor: "#FFF",

    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
      backgroundColor: "#F9F9FB",
      padding: "16px",
      boxSizing: "border-box",
      height: "auto",
      marginBottom: "16px",
      borderRadius: "4px",
    },
  },

  workoutGeneratedTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },

  workoutStepsContainer: {
    overflow: "scroll",
    height: 640,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleParent: {
    width: "60%",
    overflowWrap: "break-word",
  },
  closeMargin: {
    margin: "16px 16px 0px 0px",
  },

  activityOpenText: {
    fontSize: 14,
    padding: "10px 0",
    color: "#9E9E9E",
    textAlign: "center",
  },

  activityOpenTextName: {
    fontSize: 14,

    fontStyle: "italic",
    color: "#FF6327",
    cursor: "pointer",
  },

  slidersParent: {
    padding: "16px",
    borderRadius: "4px",
    boxSizing: "border-box",
    backgroundColor: "#F9F9FB",
    marginTop: "16px",
  },

  cardCustom: {
    marginBottom: "0px",
  },

  statsSubtitle: {
    color: "#9E9E9E",
    fontSize: "14px",
  },

  centered: {
    textAlign: "center",
  },

  bigTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  mediaPlayer: {
    "&:not(:first-child)": {
      marginTop: "10px",
    },
  },
  exportContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 12px",
    boxSizing: "border-box",
    borderRadius: "5px",
    border: "solid 1px #212121",
    width: "fit-content",
    marginLeft: "auto",
    cursor: "pointer",
    marginRight: "12px",

    "& img": {
      marginLeft: "8px",
    },
  },
  closeParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",

    "& img": {
      marginLeft: "8px",
    },
  },
  exportParent: {
    display: "flex",
    alignItems: "center",
  },
});

class DashboardTrainingWeekdayDetails extends Component {
  state = {
    energyLevel: 0,
    savingComment: false,
    showChat: false,
    rpe: 0,
    label: 0,
    energyLabel: 0,
    flag: false,
    units: "kms",
    current: null,
    currentActivity: null,
    currentToShare: null,
    videoUrl: "",
    sliderRpe: false,
    sliderEnergy: false,
    exportDialogOpen: false,
    openVideo: false,
  };

  handleCloseExport = () => {
    this.setState({
      ...this.state,
      exportDialogOpen: false,
    });
  };

  handleOpenExport = () => {
    this.setState({
      ...this.state,
      exportDialogOpen: true,
    });
  };

  localStorageUpdated() {
    this.setState({
      ...this.state,
      units: localStorage.getItem("units"),
    });
  }

  toggleChat = () => {
    const { showChat } = this.state;
    this.setState({ showChat: !showChat });
  };

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { id, plannedDate } = params;
    if (id && plannedDate) {
      this.props.dispatch(
        setTrainingDayWorkout({
          workoutId: id,
          plannedDate: plannedDate.replace("-", "/").replace("-", "/"),
        }),
      );
    }
    if (typeof window !== "undefined") {
      this.setState({
        ...this.state,
        units: localStorage.getItem("units"),
      });

      window.addEventListener("storage", () => this.localStorageUpdated());
    }
    const isInMobileView =
      this.props.width === "md" ||
      this.props.width === "sm" ||
      this.props.width === "xs";

    if (!isInMobileView)
      this.setState({ ...this.state, sliderEnergy: true, sliderRpe: true });
  }

  componentDidUpdate(prevProps) {
    const { userPlans, match, athletes, activities, width } = this.props;
    const { currentPlan } = userPlans;
    const { currentTraining } = currentPlan;
    const { selectedTrainingDayWorkoutTemp } = currentTraining;
    const { rpe } = selectedTrainingDayWorkoutTemp || {};
    const { selectedAthlete } = athletes;

    const isInMobileView = width === "md" || width === "sm" || width === "xs";

    if (
      selectedTrainingDayWorkoutTemp &&
      Object.keys(selectedTrainingDayWorkoutTemp).length &&
      activities &&
      !this.state.current &&
      !this.state.currentActivity
    ) {
      const currentActivity = activities.find(
        (item) =>
          moment(item.start_date).isSame(
            moment(selectedTrainingDayWorkoutTemp.plannedDate, dateFormat),
            "day",
          ) &&
          ((item.type && item.type.toLowerCase().includes("run")) ||
            (item.activityType && item.activityType.includes("RUN"))),
      );

      if (currentActivity) {
        this.props.dispatch(getUserActivity(currentActivity, currentPlan));
        const { device: type } = currentActivity;

        this.setState({
          ...this.state,
          current: !isInMobileView ? type : null,
          currentActivity,
        });
      }
    }

    if (
      selectedTrainingDayWorkoutTemp &&
      Object.keys(selectedTrainingDayWorkoutTemp).length &&
      !this.state.flag
    )
      this.setState({
        ...this.state,
        flag: true,
        rpe: selectedTrainingDayWorkoutTemp.visualRpe,
        label: this.normalizeEnergyLabel(
          selectedTrainingDayWorkoutTemp.visualRpe,
        ),
        energyLabel: this.normalizeEnergyLabel(
          selectedTrainingDayWorkoutTemp.visualEnergy,
        ),
        energyLevel: selectedTrainingDayWorkoutTemp.visualEnergy,
      });
  }

  componentWillUnmount() {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { currentTraining } = currentPlan;
    const {
      selectedTrainingDayWorkoutTemp,
      updatingWorkoutId,
    } = currentTraining;

    this.props.dispatch(clearCurrentUserActivity());

    if (selectedTrainingDayWorkoutTemp) {
      if (!updatingWorkoutId) this.props.dispatch(clearTempTraining());
    }
  }

  openActivity = () => {
    const { userPlans, activities } = this.props;
    const { currentPlan } = userPlans;
    const { currentTraining } = currentPlan;
    const { selectedTrainingDayWorkoutTemp } = currentTraining;

    const currentActivity = activities.find(
      (item) =>
        moment(item.start_date).isSame(
          moment(selectedTrainingDayWorkoutTemp.plannedDate, dateFormat),
          "day",
        ) &&
        ((item.type && item.type.toLowerCase().includes("run")) ||
          (item.activityType && item.activityType.includes("RUN"))),
    );

    if (currentActivity) {
      this.props.dispatch(getUserActivity(currentActivity, currentPlan));
      const { device: type } = currentActivity;
      this.setState({
        ...this.state,
        current: type,
      });
    }
  };

  handleNewClose = () => history.push("/");

  handleClose = () => {
    const { userPlans } = this.props;
    const { updatingWorkoutId } = userPlans;

    this.handleWorkoutEnd();

    if (!updatingWorkoutId) {
      setTimeout(() => {
        history.goBack();
        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          setTimeout(() => window.scrollBy(0, -300), 500);
        }
      }, 500);
    }
  };

  handleTextChange = (event) => {
    const { target } = event;
    if (target) {
      const { value } = target;
      this.setState({
        ...this.state,
        comment: value,
      });
    }
  };

  // normalizeLabel(value) {
  //   if (value == 0) return 0;
  //   if (value <= 10) return 2;
  //   if (value > 10 && value <= 20) return 4;
  //   if (value > 20 && value <= 30) return 6;
  //   if (value > 30 && value <= 40) return 8;
  //   if (value > 40 && value <= 50) return 10;
  // }

  normalizeEnergyLabel(value) {
    if (value == 0) return 0;
    if (value <= 10) return 1;
    if (value > 10 && value <= 20) return 2;
    if (value > 20 && value <= 30) return 3;
    if (value > 30 && value <= 40) return 4;
    if (value > 40 && value <= 50) return 5;
    if (value > 50 && value <= 60) return 6;
    if (value > 60 && value <= 70) return 7;
    if (value > 70 && value <= 80) return 8;
    if (value > 80 && value <= 90) return 9;
    if (value > 90 && value <= 100) return 10;
  }

  normalizeEnergyValue(value) {
    if (value == 0) return 100;
    if (value > 0 && value <= 10) return 90;
    if (value > 10 && value <= 20) return 80;
    if (value > 20 && value <= 30) return 70;
    if (value > 30 && value <= 40) return 60;
    if (value > 40 && value <= 50) return 50;
    if (value > 50 && value <= 60) return 40;
    if (value > 60 && value <= 70) return 30;
    if (value > 70 && value <= 80) return 20;
    if (value > 80 && value <= 90) return 10;
    if (value > 90 && value <= 100) return 0;
  }

  normalizeValue(value) {
    if (value == 0) return 0;
    if (value <= 20) return 20;
    if (value > 20 && value <= 40) return 40;
    if (value > 40 && value <= 60) return 60;
    if (value > 60 && value <= 80) return 80;
    if (value > 80 && value <= 100) return 100;
  }

  handleEnergyLevel = (value) => {
    const { userPlans } = this.props;
    const { updatingWorkoutId } = userPlans;

    const newValue = this.normalizeEnergyLabel(value);

    if (!updatingWorkoutId) {
      this.setState({
        ...this.state,
        energyLevel: value,
        energyLabel: newValue,
      });
    }
  };

  handleEnergyLevelChangeRpe = (value) => {
    const { userPlans } = this.props;
    const { updatingWorkoutId } = userPlans;

    if (!updatingWorkoutId) {
      const newValue = this.normalizeEnergyLabel(value);

      this.setState({
        ...this.state,
        rpe: value,
        label: newValue,
      });
    }
  };

  handleWorkoutEnd() {
    const { match, userPlans } = this.props;
    const { currentPlan, updatingWorkoutId } = userPlans;
    const { currentTraining } = currentPlan;
    const { selectedTrainingDayWorkoutTemp = {} } = currentTraining;

    const { params } = match;
    const { id, plannedDate } = params;
    const { rpe, energyLevel } = this.state;

    const rpeRealValue = this.normalizeValue(rpe);
    const realEnergyValue = this.normalizeEnergyValue(energyLevel);

    if (!updatingWorkoutId) {
      this.props.dispatch(
        currentTrainingUpdateWorkoutEnergyLevel(
          {
            ...selectedTrainingDayWorkoutTemp,
            workoutId: id,
            plannedDate: plannedDate.replace("-", "/").replace("-", "/"),
            cognito_user_sub: currentPlan.cognito_user_sub,
            energyLevel: realEnergyValue,
            visualEnergy: energyLevel,
            rpe: rpeRealValue,
            visualRpe: rpe,
          },
          currentTraining,
          0,
          false,
          false,
        ),
      );
    }

    this.setState({ ...this.state, sliderRpe: false });
  }

  handleExportWorkout = (useRpe) => {
    const { match, userPlans } = this.props;
    const { currentPlan } = userPlans;
    const {
      currentTraining,
      numDone,
      garmin_oauth_token,
      suunto_auth,
    } = currentPlan;
    const { selectedTrainingDayWorkoutTemp, chainedPlans } = currentTraining;

    const { parametrizedWorkout, plannedDate } = selectedTrainingDayWorkoutTemp;

    const { params } = match;

    const level = !isNaN(getCustomLevel(plannedDate, chainedPlans))
      ? getCustomLevel(plannedDate, chainedPlans)
      : getPlanLevel(currentTraining);

    if (garmin_oauth_token) {
      this.props.dispatch(
        exportWorkoutToGarmin(
          currentPlan,
          selectedTrainingDayWorkoutTemp,
          level,
          useRpe,
        ),
      );
    } else if (suunto_auth) {
      this.props.dispatch(
        exportWorkoutToSuunto(
          currentPlan,
          selectedTrainingDayWorkoutTemp,
          level,
          useRpe,
        ),
      );
    }
  };

  handleWorkoutIsDoneChange = (markAsDone, position = "") => {
    const { match, userPlans, subscriptions, auth } = this.props;
    const {
      currentPlan,
      updatingWorkoutId,
      chainedPlans,
      previousPlans,
    } = userPlans;
    const { currentTraining, numDone } = currentPlan;
    const { selectedTrainingDayWorkoutTemp } = currentTraining;

    setEventsMetadata({ customer_id: currentPlan.cognito_user_sub });
    logEvent("mark_as_done");

    this.handleWorkoutEnd();

    if (!updatingWorkoutId) {
      const { params } = match;
      const { id, plannedDate } = params;

      this.props.dispatch(
        currentTrainingUpdateWorkoutIsDone(
          {
            ...selectedTrainingDayWorkoutTemp,
            workoutId: id,
            plannedDate: plannedDate.replace("-", "/").replace("-", "/"),
            cognito_user_sub: currentPlan.cognito_user_sub,
            isDone: markAsDone,
          },
          currentTraining,
          0,
        ),
      );
      this.isDone = markAsDone;

      const { currentUser } = auth || {};
      const { attributes } = currentUser || {};
      const { email } = attributes || {};
      const analyticData = createAnalyticData(
        {
          username: email,
          userPlans,
          subscriptions,
        },
        "mark as done",
      );
      this.props.dispatch(pushAnalyticData(analyticData));

      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setTimeout(() => window.scrollBy(0, -300), 500);
      }

      if (numDone && numDone === 2) {
        RateApp.requestReview();
      }
    }
  };

  handleSaveComment = (comment) => {
    const { match, userPlans } = this.props;
    const { currentPlan, updatingWorkoutId } = userPlans;
    if (!updatingWorkoutId) {
      this.setState({
        ...this.state,
        savingComment: true,
      });

      const { currentTraining, lastModified } = currentPlan;
      const {
        selectedTrainingDayWorkoutTemp,
        updatingWorkoutId,
      } = currentTraining;

      const { params } = match;
      const { id, plannedDate } = params;
      if (!updatingWorkoutId) {
        this.props.dispatch(
          currentTrainingAddComment(
            {
              ...selectedTrainingDayWorkoutTemp,
              workoutId: id,
              plannedDate: plannedDate.replace("-", "/").replace("-", "/"),
              cognito_user_sub: currentPlan.cognito_user_sub,
              comment: { ...comment, isReadByCoach: false },
              lastModified,
            },
            false,
            true,
          ),
        );
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              savingComment: false,
            }),
          2000,
        );
      }
    }
  };

  setAllCommentsAsRead = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      currentTrainingAddCommentsRead(
        {
          cognito_user_sub: currentPlan.cognito_user_sub,
        },
        false,
        true,
      ),
    );
  };

  handleOpenShare = (type) => {
    this.setState({
      ...this.state,
      currentToShare: type,
    });
  };

  render() {
    const {
      classes,
      fullScreen,
      userPlans,
      t,
      i18n,
      match,
      width,
      workout,
    } = this.props;

    const {
      current,
      currentActivity,
      currentToShare,
      sliderRpe,
      sliderEnergy,
      exportDialogOpen,
    } = this.state;
    const { currentPlan, updatingWorkoutId } = userPlans;
    const {
      currentTraining,
      garmin_oauth_token,
      suunto_auth,
      cognito_user_sub,
    } = currentPlan;
    const {
      selectedTrainingDayWorkoutTemp = {},
      chainedPlans,
    } = currentTraining;

    const {
      workoutType,
      description,
      estimatedTime,
      isDone = false,
      plannedDate,
      energyLevel,
      rpe,
      userCreatedType,
      title,
      parametrizedWorkout,
      publicTitle,
      reaction,
    } = selectedTrainingDayWorkoutTemp;

    const level = getPlanLevel(currentTraining);
    const newEstimatedTime = Array.isArray(estimatedTime)
      ? estimatedTime[
          !isNaN(getCustomLevel(plannedDate, chainedPlans))
            ? getCustomLevel(plannedDate, chainedPlans)
            : level
        ]
      : estimatedTime;

    const selectedEnergyLevel =
      this.state.energyLevel || this.state.energyLevel === 0
        ? this.state.energyLevel
        : energyLevel || energyLevel === 0
        ? energyLevel
        : 100;

    const { fromTracker } = workout || {};

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";
    const showMarkDoneButton = moment(plannedDate, dateFormat)
      .startOf("day")
      .isBefore(
        moment()
          .add(1, "week")
          .startOf("isoWeek")
          .startOf("day"),
      );

    const isInMobileView = width === "md" || width === "sm" || width === "xs";

    const isInMobileViewSm = width === "sm" || width === "xs";
    const videoWidth = isInMobileViewSm
      ? window.innerWidth - 60
      : Math.min(window.innerWidth, 620);

    const { params } = match;
    const { id: idParam, plannedDate: plannedDateParam } = params;

    const goToChat = () => {
      const {
        workout: { fromTracker },
      } = this.props;

      this.props.dispatch(setFormTrackerStatus(fromTracker));
      history.push(`/dashboard/${idParam}/${plannedDateParam}/chat`);
    };

    const goBack = () => {
      const {
        workout: { fromTracker },
      } = this.props;

      if (fromTracker.fromTracker && fromTracker.id && fromTracker.date) {
        this.props.dispatch(setFormTrackerStatus({}));
        return history.push(
          `/tracking/tracker/${fromTracker.id}/${fromTracker.date}`,
        );
      }

      if (fromTracker.fromTracker && !fromTracker.id && !fromTracker.date) {
        this.props.dispatch(setFormTrackerStatus({}));
        return history.push(`/tracking/tracker`);
      }

      if (!fromTracker.fromTracker) {
        this.handleWorkoutEnd();
        this.props.dispatch(setFormTrackerStatus({}));
        history.push("/dashboard");
      }
    };

    const goToMap = () => {
      this.props.dispatch(clearActivityVert());

      const { userPlans, subscriptions, auth } = this.props;
      const { currentUser } = auth || {};
      const { attributes } = currentUser || {};
      const { email } = attributes || {};
      const analyticData = createAnalyticData(
        {
          username: email,
          userPlans,
          subscriptions,
        },
        "open gps tracker",
      );
      this.props.dispatch(pushAnalyticData(analyticData));

      history.push(`/tracking/tracker`);
    };

    const isNative = Capacitor.isNativePlatform();

    const handleSliderEnd = (key) => {
      if (isInMobileView) this.setState({ ...this.state, [key]: false });
    };

    const convertStringToHTML = (htmlString) => {
      const parser = new DOMParser();
      const html = parser.parseFromString(htmlString, "text/html");
      return html.body;
    };

    const getVideoUrl = (description) => {
      const body = convertStringToHTML(description);
      const child = body.getElementsByTagName("figure");

      if (child && child.length) {
        const url = [...child].map((children) => {
          const htmlString = children && children.innerHTML.split('"');
          return htmlString[1];
        });
        return url;
      }

      return [];
    };

    const deleteVideo = (plain) => {
      const body = convertStringToHTML(plain);
      const child = body.getElementsByTagName("figure");
      if (child && child.length) {
        [...child].forEach((children) => {
          body.removeChild(children);
        });
      }
      const stringBody = body.innerHTML;
      return stringBody;
    };

    const handleOpenVideo = () =>
      this.setState({ ...this.state, openVideo: !this.state.openVideo });

    return selectedTrainingDayWorkoutTemp ? (
      <>
        <CustomErrorBoundary>
          <Dialog
            fullScreen={fullScreen}
            open={true}
            onClose={this.handleClose}
            aria-labelledby="Workout"
            scroll="body"
            maxWidth="md"
            className={classes.dialog}
            classes={{
              paper: !current ? classes.dialogPaper : classes.expandedDialog,
            }}
          >
            <DialogContent className={classes.container}>
              <Grid container spacing={40}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={current ? 2 : 3}
                  xl={current ? 2 : 3}
                  className={classes.metadataColumn}
                >
                  {isInMobileView ? (
                    <div className={classes.topContainer}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ArrowBackIosIcon
                          onClick={goBack}
                          className={classes.backIcon}
                          fontSize="small"
                        />
                        <Typography variant="body1" className={classes.date}>
                          {
                            dayjs()
                              .locale(language)
                              .localeData()
                              .weekdays()[
                              dayjs(plannedDateParam, "DD-MM-YYYY").get("day")
                            ]
                          }{" "}
                          -{" "}
                          {dayjs(plannedDateParam, "DD-MM-YYYY")
                            .locale(language)
                            .format("MMM D")}
                        </Typography>
                      </div>

                      {/* <Button
                      variant="outlined"
                      color="primary"
                      className={classes.buttons}
                      onClick={this.handleWorkoutIsDoneChange.bind(
                        this,
                        !isDone,
                        "up",
                      )}
                    >
                      {!isDone ? t("Mark as done") : t("Unmark as done")}
                    </Button> */}
                      <div
                        className={classes.closeParent}
                        onClick={this.handleNewClose}
                      >
                        <Typography>{t("Close")}</Typography>
                        <img
                          src={`${process.env.PUBLIC_URL}/close.svg`}
                          alt=""
                          onClick={this.handleCloseAppBanner}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {!isInMobileView && (
                    <>
                      <div className={classes.topItemFlex}>
                        <div className={classes.topItem}>
                          <Typography variant="" className={classes.topTitle}>
                            <img
                              src={`${process.env.PUBLIC_URL}/icon-workout-date.svg`}
                              alt=""
                              className={classes.topTitleIcon}
                            />
                            {t("Date")}
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.topText}
                          >
                            {plannedDate}
                          </Typography>
                        </div>
                        <div className={classes.topItem}>
                          <Typography variant="h6" className={classes.topTitle}>
                            <img
                              src={`${process.env.PUBLIC_URL}/icon-workout-type.svg`}
                              alt=""
                              className={classes.topTitleIcon}
                            />
                            {t("Type")}
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.topText}
                          >
                            {userCreatedType
                              ? userCreatedType
                              : t(`${WorkoutTypes.Items[workoutType]}`, {
                                  ns: "constants",
                                })}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.topItemFlex}>
                        {newEstimatedTime > 0 ? (
                          <div className={classes.topItem}>
                            <Typography
                              variant="h6"
                              className={classes.topTitle}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icon-timer.svg`}
                                alt=""
                                className={classes.topTitleIcon}
                              />
                              {t("Time")}
                            </Typography>
                            <Typography
                              variant="body1"
                              className={classes.topText}
                            >
                              {EstimatedTimes.convertMinutesToLabel(
                                newEstimatedTime,
                                true,
                              ) || "0min"}
                            </Typography>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className={classes.center}>
                          <RouterLink
                            to={`/dashboard/${idParam}/${plannedDateParam}/chat`}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.buttons}
                            >
                              {t("Start chat")}
                            </Button>
                          </RouterLink>
                        </div>
                      </div>
                    </>
                  )}

                  {isInMobileView && (
                    <Card className={classes.cardCustom}>
                      <Grid container spacing={2} className={classes.grid}>
                        <Grid item xs={4} md={4}>
                          <Typography
                            variant="body2"
                            className={classes.statsTitle}
                          >
                            {" "}
                            {userCreatedType
                              ? userCreatedType
                              : t(`${WorkoutTypes.Items[workoutType]}`, {
                                  ns: "constants",
                                })}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.statsSubtitle}
                          >
                            {t("Type", { ns: "tracking" })}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.statsTitle,
                              classes.centered,
                            )}
                          >
                            {" "}
                            {EstimatedTimes.convertMinutesToLabel(
                              newEstimatedTime,
                              true,
                            ) || "0min"}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.statsSubtitle,
                              classes.centered,
                            )}
                          >
                            {t("Duration", { ns: "tracking" })}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classNames(classes.chatBtn)}
                            size="small"
                            onClick={goToChat}
                            style={{ fontSize: 10, width: "100%" }}
                          >
                            {t("Coach chat")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={current ? 4 : 9}
                  className={classes.workoutColumn}
                >
                  {!isInMobileView && !current ? (
                    <div className={classes.rightButtons}>
                      <img
                        src={`${process.env.PUBLIC_URL}/close.svg`}
                        alt=""
                        onClick={this.handleClose}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className={classNames(
                      classes.textContainer,
                      classes.extraBottomMargin,
                    )}
                  >
                    {!isInMobileView && (
                      <>
                        {publicTitle ? (
                          <Typography className={classes.bigTitle}>
                            {publicTitle[language]
                              ? publicTitle[language]
                              : publicTitle["en"]}
                          </Typography>
                        ) : (
                          <Typography className={classes.bigTitle}>
                            {t(`${WorkoutTypes.Items[workoutType]}`, {
                              ns: "constants",
                            })}
                          </Typography>
                        )}
                      </>
                    )}

                    {/*!isInMobileView && (
                    <div className={classes.header}>
                      <div className={classes.titleParent}>
                        <Typography
                          variant="subtitle2"
                          className={classes.descTitle}
                        >
                          {title && title[language]}
                        </Typography>
                      </div>

                      {this.state.savingComment || updatingWorkoutId ? (
                        <CircularProgress
                          variant="indeterminate"
                          color="primary"
                          className={classes.loading}
                          data-testid="SavingWorkout"
                        />
                      ) : (
                        <Button
                          color="primary"
                          size={current ? "small" : "medium"}
                          variant="contained"
                          disabled={!showMarkDoneButton}
                          className={classNames(
                            classes.smallerFontSize,
                            isNative ? classes.rightMargin : null,
                            classes.buttons,
                          )}
                          style={{ maxHeight: "2.2813rem" }}
                          onClick={this.handleWorkoutIsDoneChange.bind(
                            this,
                            !isDone,
                          )}
                        >
                          {!isDone ? t("Mark as done") : t("Unmark as done")}
                        </Button>
                      )}
                    </div>
                          )*/}

                    {parametrizedWorkout &&
                    parametrizedWorkout[
                      !isNaN(getCustomLevel(plannedDate, chainedPlans))
                        ? getCustomLevel(plannedDate, chainedPlans)
                        : level
                    ] &&
                    parametrizedWorkout[
                      !isNaN(getCustomLevel(plannedDate, chainedPlans))
                        ? getCustomLevel(plannedDate, chainedPlans)
                        : level
                    ].length ? (
                      <div className={classes.workoutGeneratedTextContainer}>
                        <Typography
                          variant="body1"
                          className={classes.workoutGeneratedTitle}
                        >
                          {t("Workout Detail Preview")}
                        </Typography>

                        <Typography
                          variant="body1"
                          className={classes.workoutGeneratedText}
                          dangerouslySetInnerHTML={{
                            __html: workoutToText(
                              parametrizedWorkout[
                                !isNaN(
                                  getCustomLevel(plannedDate, chainedPlans),
                                )
                                  ? getCustomLevel(plannedDate, chainedPlans)
                                  : level
                              ],
                              this.state.units,
                              languageDetector,
                            ),
                          }}
                        ></Typography>
                        <div></div>

                        {isInMobileView && (garmin_oauth_token || suunto_auth) && (
                          <div className={classes.exportParent}>
                            <div
                              className={classes.exportContainer}
                              onClick={this.handleOpenExport.bind(this)}
                            >
                              <Typography variant="body1">
                                {t("Export to watch")}
                              </Typography>
                              <img
                                src={`${process.env.PUBLIC_URL}/export_icon.svg`}
                                alt="export icon"
                              ></img>
                            </div>
                            <InfoOutlined
                              style={{ fontSize: 32 }}
                              onClick={handleOpenVideo}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    <MobilePlayer
                      open={this.state.openVideo}
                      onClose={handleOpenVideo}
                      url="https://videofilesvertrun.s3.amazonaws.com/Export_workout_video.mp4"
                    />

                    <div className={classes.workoutGeneratedTextContainer}>
                      <Typography
                        variant="subtitle2"
                        className={classes.descTitle}
                      >
                        {t("Description")}
                      </Typography>

                      <Typography
                        variant="body2"
                        className={classes.newLine}
                        dangerouslySetInnerHTML={convertToRTFForView(
                          description &&
                            deleteVideo(
                              description && description[languageDetector]
                                ? description[languageDetector]
                                : description && description["en"],
                            ),
                          videoWidth,
                        )}
                      ></Typography>

                      {[
                        ...getVideoUrl(
                          description && description[languageDetector]
                            ? description[languageDetector]
                            : description && description["en"],
                        ),
                      ].map((url, index) => (
                        <div key={index}>
                          <ReactPlayer
                            config={{
                              youtube: {
                                embedOptions: {
                                  height: !isInMobileView ? 350 : "100%",
                                },
                              },
                            }}
                            url={url}
                            controls={true}
                            width="100%"
                            height={isInMobileView ? videoWidth * 0.56 : "auto"}
                            className={classes.mediaPlayer}
                            light={
                              <img
                                src={`${process.env.PUBLIC_URL}/signup-xl.jpg`}
                                alt="Thumbnail"
                                width="100%"
                                height="100%"
                              />
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className={classes.slidersParent}>
                    {!Object.keys(fromTracker).length &&
                    workoutType !== "Recovery" ? (
                      <>
                        <Typography
                          variant="body2"
                          className={classes.newLineEffort}
                        >
                          {t("How did this activity felt", { ns: "tracking" })}
                        </Typography>

                        <div className={classes.labelParent}>
                          <Typography variant="body2">
                            {this.state.label || 0}
                          </Typography>
                        </div>

                        <ClickNHold
                          onClickNHold={
                            isInMobileView
                              ? () =>
                                  this.setState({
                                    ...this.state,
                                    sliderRpe: true,
                                  })
                              : null
                          }
                          time={isInMobileView ? 0.5 : 0}
                        >
                          <Slider
                            value={this.state.rpe}
                            aria-labelledby="continuous-slider"
                            valueLabelDisplay="off"
                            onChange={
                              sliderRpe
                                ? (event, value) =>
                                    this.handleEnergyLevelChangeRpe(value)
                                : null
                            }
                            onChangeCommitted={() =>
                              handleSliderEnd("sliderRpe")
                            }
                            min={0}
                            max={100}
                            color="primary"
                          />
                        </ClickNHold>

                        <div className={classes.effort}>
                          <Grid container>
                            <Grid item xs={4} sm={4} md={4}>
                              <Typography
                                variant="h6"
                                className={classes.effortLabel}
                                style={{ textAlign: "left" }}
                              >
                                {t("Easy", { ns: "tracking" })}
                              </Typography>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  className={classes.effortLabel}
                                  style={{ textAlign: "right" }}
                                >
                                  {t("Medium", { ns: "tracking" })}
                                </Typography>
                              </div>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  className={classes.effortLabel}
                                  style={{ textAlign: "right" }}
                                >
                                  {t("Max Effort", { ns: "tracking" })}
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    ) : null}

                    {newEstimatedTime > 0 &&
                    !Object.keys(fromTracker).length ? (
                      <>
                        <Typography
                          variant="body2"
                          className={classes.newLineEffort}
                        >
                          {t("How hard was the effort for this workout?")}
                        </Typography>

                        <div className={classes.labelParent}>
                          <Typography variant="body2">
                            {this.state.energyLabel || 0}
                          </Typography>
                        </div>

                        <ClickNHold
                          onClickNHold={
                            isInMobileView
                              ? () =>
                                  this.setState({
                                    ...this.state,
                                    sliderEnergy: true,
                                  })
                              : null
                          }
                          time={isInMobileView ? 0.5 : 0}
                        >
                          <Slider
                            value={this.state.energyLevel}
                            aria-labelledby="continous-slider"
                            valueLabelDisplay="off"
                            onChange={
                              sliderEnergy
                                ? (event, value) =>
                                    this.handleEnergyLevel(value)
                                : null
                            }
                            onChangeCommitted={() =>
                              handleSliderEnd("sliderEnergy")
                            }
                            min={0}
                            max={100}
                            color="primary"
                          />
                        </ClickNHold>

                        <div className={classes.effort}>
                          <Grid container>
                            <Grid item xs={4} sm={4} md={4}>
                              <Typography
                                variant="h6"
                                className={classes.effortLabel}
                                style={{ textAlign: "left" }}
                              >
                                {t("Fresh", { ns: "tracking" })}
                              </Typography>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  className={classes.effortLabel}
                                  style={{ textAlign: "right" }}
                                >
                                  {t("Average", { ns: "tracking" })}
                                </Typography>
                              </div>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  className={classes.effortLabel}
                                  style={{ textAlign: "right" }}
                                >
                                  {t("Very fatigued", { ns: "tracking" })}
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.rightButtons}>
                    {this.state.savingComment || updatingWorkoutId ? (
                      <CircularProgress
                        variant="indeterminate"
                        color="primary"
                        className={classes.loading}
                        data-testid="SavingWorkout"
                      />
                    ) : (
                      <div
                        className={classes.btnsParent}
                        style={{ marginTop: 16 }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={!showMarkDoneButton}
                          className={classNames(
                            classes.smallerFontSize,
                            isNative ? classes.rightMargin : null,
                            classes.buttons,
                            isInMobileView ? classes.borderPlain : null,
                          )}
                          onClick={this.handleWorkoutIsDoneChange.bind(
                            this,
                            !isDone,
                            "down",
                          )}
                        >
                          {!isDone ? t("Mark as done") : t("Unmark as done")}
                        </Button>
                        <div style={{ margin: "0 2px" }}></div>
                        {!isInMobileView &&
                        (garmin_oauth_token || suunto_auth) &&
                        parametrizedWorkout &&
                        parametrizedWorkout[
                          !isNaN(getCustomLevel(plannedDate, chainedPlans))
                            ? getCustomLevel(plannedDate, chainedPlans)
                            : level
                        ] &&
                        parametrizedWorkout[
                          !isNaN(getCustomLevel(plannedDate, chainedPlans))
                            ? getCustomLevel(plannedDate, chainedPlans)
                            : level
                        ].length ? (
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={false}
                            className={classNames(
                              classes.smallerFontSize,
                              isNative ? classes.rightMargin : null,
                              classes.buttons,
                            )}
                            onClick={this.handleOpenExport.bind(this)}
                          >
                            Export Workout
                          </Button>
                        ) : null}

                        <div style={{ margin: "0 2px" }}></div>
                        {workoutType !== "Recovery" && isNative ? (
                          <Button
                            color="primary"
                            variant="outlined"
                            className={classNames(
                              classes.smallerFontSize,
                              classes.buttons,
                              isInMobileView ? classes.borderPlain : null,
                            )}
                            onClick={goToMap}
                          >
                            {t("Go to Tracking", { ns: "tracking" })}
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </div>

                  <Dialog
                    open={exportDialogOpen}
                    aria-labelledby="delete-dialog"
                  >
                    <DialogContent>
                      <Typography variant="body1" className={classes.textInfo}>
                        {t(
                          "Do you want to export the workout based on HR Zone?",
                        )}
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      {garmin_oauth_token && (
                        <Button
                          onClick={() => {
                            this.handleExportWorkout(true);
                            this.handleCloseExport();
                          }}
                          color="primary"
                        >
                          {t("Heart Rate Zones")}
                        </Button>
                      )}

                      <Button
                        onClick={() => {
                          this.handleExportWorkout(false);
                          this.handleCloseExport();
                        }}
                        color="primary"
                      >
                        {t("Only splits")}
                      </Button>
                      <Button
                        onClick={() => {
                          this.handleCloseExport();
                        }}
                        color="primary"
                      >
                        {t("Cancel")}
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {currentActivity && isInMobileView && (
                    <>
                      <Typography
                        variant="body2"
                        className={classes.activityOpenText}
                        onClick={this.openActivity}
                      >
                        {t("We found a activity for this day check it out:")}{" "}
                        <span className={classes.activityOpenTextName}>
                          {currentActivity.name
                            ? currentActivity.name
                            : currentActivity.activityName
                            ? currentActivity.activityName
                            : t("Here")}
                        </span>
                      </Typography>
                      {current && (
                        <DashboardActivityDetails
                          isOpen={current}
                          onClose={() =>
                            this.setState({ ...this.state, current: null })
                          }
                          onOpenShare={this.handleOpenShare}
                        ></DashboardActivityDetails>
                      )}
                    </>
                  )}
                </Grid>

                {current && !isInMobileView && (
                  <Grid md={6} lg={6} xl={6}>
                    <div className={classes.activityColumn}>
                      <div
                        className={classNames(
                          classes.rightButtons,
                          classes.closeMargin,
                        )}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/close.svg`}
                          alt=""
                          onClick={this.handleClose}
                        />
                      </div>
                      <DashboardActivityDetails
                        asComponent={true}
                        isOpen={current}
                        onOpenShare={this.handleOpenShare}
                        tmpWorkout={selectedTrainingDayWorkoutTemp}
                        isCoach={false}
                        customHeight={500}
                        elevationMargin={15}
                      ></DashboardActivityDetails>
                    </div>
                  </Grid>
                )}
              </Grid>
            </DialogContent>

            <DashboardActivityShare
              isOpen={currentToShare}
              onClose={() => {
                this.setState({ ...this.state, currentToShare: null });
              }}
            ></DashboardActivityShare>
          </Dialog>
        </CustomErrorBoundary>
      </>
    ) : null;
  }
}

DashboardTrainingWeekdayDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  fullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  userPlans: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
  workout: PropTypes.object,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
    auth: store.auth,
    subscriptions: store.subscriptions,
    workout: store.workout,
    athletes: store.athletes,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation(["dashboard", "constants", "tracking"])(
      withWidth()(
        withMobileDialog()(
          withActivities(memo(DashboardTrainingWeekdayDetails)),
        ),
      ),
    ),
  ),
);
