import ActionTypes from "../constants/action-types";

const initialState = {
  trainingZoneLoading: false,
  zones: null,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.TRAINING_ZONE_CALCULATOR_PENDING:
        return {
          ...state,
          trainingZoneLoading: true,
        };

      case ActionTypes.TRAINING_ZONE_CALCULATOR_FULLFILED:
        return {
          ...state,
          zones: action.payload,
          trainingZoneLoading: false,
        };

      case ActionTypes.TRAINING_ZONE_CALCULATOR_REJECTED:
        return {
          ...state,
          trainingZoneLoading: false,
        };

      default:
        return state;
    }
  } else {
    return state;
  }
}
