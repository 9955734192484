import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import PlanDrawer from "./plan-drawer.js";
import Fields from "../../../constants/plan-drawer-fields";
import LongestRun from "../../../constants/longest-run";

class PlanSuggesterQuestions extends Component {
  render() {
    const {
      onSelectFieldChange,
      onButtonSelectFieldChange,
      onFieldError,
      onFieldsInit,
      t,
      currentStep,
      onBackRequested,
      userType,
    } = this.props;

    const goalItems = [
      { text: t("Get back in trail shape"), value: 1, icon: "icon-goal-3.svg" },
      { text: t("Perform better in races"), value: 3, icon: "icon-goal-5.svg" },
      { text: t("Run my next Ultra"), value: 4, icon: "icon-goal-4.svg" },
      {
        text: t("Train with a free plan"),
        value: 5,
        icon: "icon-goal-2.svg",
      },
    ];

    const currentlyTrainingItems = [
      { text: t("Not really, Want to start from zero"), value: 0 },
      {
        text:
          userType === 3
            ? t("training less than 3h per week")
            : t("running less than 3h per week"),
        value: 1,
      },
      {
        text:
          userType === 3
            ? t("training about 5h per week")
            : t("running about 5h per week"),
        value: 2,
      },
      { text: t("Heck yeah! Im training 6+ hours per week"), value: 3 },
    ];

    const stepsCount = userType === 3 ? 1 : userType === 1 ? 2 : 3;

    const fields = [
      [
        {
          fieldType: Fields.Items.IMAGE,
          image: "18.jpg",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount: stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("Are you currently training?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.BUTTON_SELECT,
          id: "currentlyTraining",
          xs: 12,
          sm: 12,
          md: 12,
          items: currentlyTrainingItems,
          onError: onFieldError,
          onSelect: onButtonSelectFieldChange,
        },
      ],
    ];

    if (userType !== 3) {
      fields.push([
        {
          fieldType: Fields.Items.IMAGE,
          image: "9.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount: stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t(
            "What has been the longest race/run that you have ever done before?",
          ),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.STEPPER_SELECT,
          id: "longestRun",
          xs: 12,
          sm: 12,
          md: 12,
          onError: onFieldError,
          items: LongestRun.suggesterOptions,
          onSelect: onSelectFieldChange,
        },
      ]);

      if (userType !== 1) {
        fields.unshift([
          {
            fieldType: Fields.Items.IMAGE,
            image: "20.png",
          },
          {
            fieldType: Fields.Items.STEPPER,
            xs: 12,
            sm: 12,
            md: 12,
            currentStep: currentStep,
            stepsCount: stepsCount,
          },
          {
            fieldType: Fields.Items.LABEL,
            xs: 12,
            sm: 12,
            md: 12,
            label: t("What’s your next goal?"),
            subLabel: t("Help us find the right plan for you!"),
            labelNumber: currentStep + 1,
          },
          {
            fieldType: Fields.Items.BUTTON_SELECT,
            id: "currentGoal",
            xs: 12,
            sm: 12,
            md: 12,
            items: goalItems,
            onError: onFieldError,
            onSelect: onButtonSelectFieldChange,
          },
        ]);
      }
    }

    onFieldsInit(fields);
    return (
      <PlanDrawer
        currentStep={currentStep}
        fields={fields[currentStep]}
        onBackRequested={onBackRequested}
      />
    );
  }
}

PlanSuggesterQuestions.propTypes = {
  planTempData: PropTypes.object.isRequired,
  currentData: PropTypes.object.isRequired,
  onButtonSelectFieldChange: PropTypes.func.isRequired,
  onFieldError: PropTypes.func.isRequired,
  onFieldsInit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(["plan", "constants"])(PlanSuggesterQuestions);
