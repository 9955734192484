import React from "react";
import { SvgIcon } from "@material-ui/core";

const SpeedIcon = ({ width = 18, height = 18, state = "outline" }) => {
  let stroke = "none";

  if (state === "highlighted") {
    stroke = "#12151B";
  } else if (state === "outline") {
    stroke = "#12151B";
  }

  return (
    <SvgIcon width={width} height={height}>
      <svg
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.4997 17H3.58578C3.13926 17 2.91666 16.4592 3.23379 16.1449L16.1477 3.3445C16.4633 3.03166 16.9997 3.25522 16.9997 3.69961V16.5C16.9997 16.7761 16.7758 17 16.4997 17Z"
          stroke={stroke}
          strokeWidth="1.5"
        />
        <path
          d="M1 10.5L9.68564 2M1 10.5V6.5M1 10.5H4.65714M9.68564 2H6.13008M9.68564 2V6.11765"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default SpeedIcon;
