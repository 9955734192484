/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobileprd = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:bc09dc9a-b62c-48f2-85eb-72a2e2836d76",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_0FEYsZZXz",
  aws_user_pools_web_client_id: "2iuciil5242kjanvrl5osb11d7",
  oauth: {
    domain: "vertrunappsocialsignin-prd.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "http://localhost/,https://app.vert.run/",
    redirectSignOut: "http://localhost/,https://app.vert.run/",
    responseType: "token",
  },
  federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
  aws_user_files_s3_bucket: "vert-run-app-user-profile-pictures-dev-prd",
  aws_user_files_s3_bucket_region: "us-east-1",
  aws_dynamodb_all_tables_region: "us-east-1",
  aws_dynamodb_table_schemas: [
    {
      tableName: "dynamodbworkouts-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbtrainingplans-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbuserplans-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbstravatokens-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbstravaactivities-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbsubscriptions-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbnotifications-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbsubcategories-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbposts-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbtags-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbreferralcodes-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbchats-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbchallenges-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbuserchallenges-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbgarminactivities-prd",
      region: "us-east-1",
    },
    {
      tableName: "dynamodbsuuntoactivities-dev",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom: [
    {
      name: "apiworkouts",
      endpoint: "https://ujau5c8w54.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
    {
      name: "apitrainingplans",
      endpoint: "https://dik94xm3ed.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
    {
      name: "apiuserplans",
      endpoint: "https://jy4wo46xt9.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
    {
      name: "apiathletes",
      endpoint: "https://bidslzw4wd.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
    {
      name: "apistrava",
      endpoint: "https://p5oexurexc.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
    {
      name: "apisubscriptions",
      endpoint: "https://9oer36suw5.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
    {
      name: "apinotifications",
      endpoint: "https://afeodarqml.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
    {
      name: "apisubcategories",
      endpoint: "https://mzcp49n8dl.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
    {
      name: "apitrailhead",
      endpoint: "https://g7izhnxeb2.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
    {
      name: "apireferrals",
      endpoint: "https://ucrqyn1ez3.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
    {
      name: "apichallenges",
      endpoint: "https://1ud68fmvpj.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
    {
      name: "apidevice",
      endpoint: "https://476fkn24m5.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
    {
      name: "apisearch",
      endpoint: "https://476fkn24m5.execute-api.us-east-1.amazonaws.com/prd",
      region: "us-east-1",
    },
  ],
};

export default awsmobileprd;
