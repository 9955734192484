import React, { useEffect, useState } from "react";
import { makeStyles, Box, Dialog, Slide } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import usePost from "../../../hooks/usePost";
import { useTranslation } from "react-i18next";
import { palette } from "../../../theme/palette";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import VideosCardSkeleton from "../../../components/common/v2/VideosCardSkeleton";
import VideoCard from "../../../components/common/v2/VideoCard";
import InfiniteScroll from "react-infinite-scroll-component";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const VideoView = () => {
  const [openDialog, setOpenDialog] = useState(true);
  const { t } = useTranslation("explore");
  const history = useHistory();
  const { explorePosts, handleGetExplorePosts } = usePost();
  const { data: videos, moreData } = explorePosts["Workouts [ Video ]"] || {};
  const classes = useStyles();

  useEffect(() => {
    if (!videos) {
      handleGetExplorePosts(new Date().getTime(), "Workouts [ Video ]", true);
    }
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const fetchMoreVideos = () => {
    if (videos && videos.length > 0) {
      videos.sort((a, b) => b.created_at - a.created_at);

      const lastCreatedAt = videos[videos.length - 1].created_at;

      if (lastCreatedAt) {
        handleGetExplorePosts(lastCreatedAt, "Workouts [ Video ]", false);
      }
    }
  };

  const handleTransitionEnd = () => {
    history.goBack();
  };

  const navigate = (id) => {
    history.push(`explore/post/${id.id}`);
  };
  let items = [];
  if (videos && Array.isArray(videos)) {
    videos.sort((a, b) => b.created_at - a.created_at);

    items = videos.map((item, index) => (
      <Box key={index} className={classes.videoCardContainer}>
        <VideoCard
          item={item}
          navigate={navigate}
          customStyle={{ marginBottom: "16px" }}
        />
      </Box>
    ));
  }

  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionProps={{ onExited: handleTransitionEnd }}
        TransitionComponent={Transition}
        className={classes.dialog}
        display={"flex"}
        flexDirection="column"
      >
        <ToolbarReturnBack
          withLogo={false}
          title={t("video.title")}
          logoWidth={107}
          style={{
            backgroundColor: "#FEFFFF",
            padding: "10px",
          }}
          goBack={handleCloseDialog}
        />
        <Box id="scroll-view" className={classes.scrollView}>
          <InfiniteScroll
            dataLength={(videos && videos.length) || 0}
            className={classes.videoScrooll}
            next={fetchMoreVideos}
            scrollableTarget="scroll-view"
            hasMore={moreData ? moreData : true}
            loader={
              <Box className={classes.videoCardContainer}>
                <VideosCardSkeleton />{" "}
              </Box>
            }
          >
            {items}
          </InfiniteScroll>
        </Box>
      </Dialog>
    </>
  );
};

export default VideoView;

const useStyles = makeStyles({
  card: {
    background: palette.white,
    borderRadius: "16px",
    width: "100%",
    height: "280px",
  },
  dialog: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#FAFAFA",
    overflow: "hidden",
  },
  descriptionContainer: {
    padding: "12px",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  nextRaceSlider: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  icon: {
    color: palette.darkGray,
    width: "18px",
    margin: 0,
    padding: 0,
  },
  favoriteButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "#FFFFFF33",
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF33",
  },
  videosContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  time: {
    color: "#6D6F71",
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
  },
  workout: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#212121",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "space-between",
    gap: "8px",
  },
  timeContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: "center",
  },
  scrollView: {
    flex: 1,
    overflowY: "auto",
    backgroundColor: "#FAFAFA",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
  videoCardContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 16px 16px 16px",
  },
});
