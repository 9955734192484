import React from "react";
import { Box } from "@material-ui/core";

import StravaConnect from "../../../components/common/stravaConnect";
import GarminConnect from "../../../components/common/garminConnect";
import CorosConnect from "../../../components/common/corosConnect";
import SuuntoConnect from "../../../components/common/suuntoConnect";
import HealthkitConnect from "../../../components/common/healthkitConnect";

const Watch = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <GarminConnect v3={true} />
      <StravaConnect v3={true} />
      <CorosConnect v3={true} />
      <SuuntoConnect v3={true} />
      <HealthkitConnect v3={true} />
    </Box>
  );
};

export default Watch;
