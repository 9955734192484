import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { withTranslation } from "react-i18next";
import "@ashwamegh/react-link-preview/dist/index.css";
import "moment/min/locales";
import CircularProgress from "@material-ui/core/CircularProgress";
import history from "../../lib/history-helper";

const styles = (theme) => ({
  container: {
    maxWidth: 620,
    background: "#ffffff",
    margin: 10,
    borderRadius: 10,
    cursor: "pointer",
  },

  image: {
    width: "100%",
    borderRadius: "10px 10px 0 0",
  },

  textContainer: {
    padding: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    fontWeight: 500,
    fontSize: 18,
  },

  stats: {
    fontWeight: 400,
    fontSize: 12,
    color: "#9E9E9E",
  },

  joinButton: {
    borderRadius: 50,
  },
});

class PublicGroup extends Component {
  state = {};

  async componentDidMount() {}

  goToGroup = (id) => {
    history.push(`/trailhead/group/${id}`);
  };

  render() {
    const {
      classes,
      t,
      i18n,
      publicGroup,
      joiningGroup,
      onJoinGroup,
      userPlans,
      isAuthenticated,
    } = this.props;

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const {
      image,
      name,
      numPosts = 0,
      numMembers = 0,
      id,
      members,
      requests,
      isPrivate,
    } = publicGroup;

    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || {};

    const joined =
      members && members.filter((p) => p === cognito_user_sub).length;

    const requested =
      requests && requests.filter((p) => p === cognito_user_sub).length;

    return (
      <div className={classes.container} onClick={() => this.goToGroup(id)}>
        <img className={classes.image} src={image} alt="" />

        <div className={classes.textContainer}>
          <div>
            <Typography variant="body1" className={classes.title}>
              {name && name[languageDetector] ? name[languageDetector] : ""}
            </Typography>

            <Typography variant="body1" className={classes.stats}>
              {`${numMembers} ${t("members")} • ${numPosts} ${t("posts")}`}
            </Typography>
          </div>

          {!isAuthenticated ? (
            ""
          ) : !joined && joiningGroup ? (
            <CircularProgress
              variant="indeterminate"
              color="primary"
              className={classes.loading}
              data-testid="LoadingUser"
            />
          ) : (!joined && !isPrivate) ||
            (!requested && !joined && isPrivate) ? (
            <Button
              color="primary"
              className={classes.joinButton}
              onClick={(ev) => onJoinGroup(ev, id, isPrivate)}
              variant="outlined"
            >
              {isPrivate ? t("Request to join") : t("Join")}
            </Button>
          ) : requested && !joined && isPrivate ? (
            <Button
              color="primary"
              className={classes.joinButton}
              variant="outlined"
              disabled
            >
              {t("Requested")}
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

PublicGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string,
  publicGroup: PropTypes.object,
};

export default withTranslation(["trailhead", "messages"])(
  withWidth()(withStyles(styles, { withTheme: true })(PublicGroup)),
);
