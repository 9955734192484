import ActionTypes from "../constants/action-types";
import ChallengesService from "../services/challenges-service";

const getChallenges = (cognito_user_sub, loading = true) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CHALLENGES_GET_PENDING, payload: loading });
    try {
      const result = await ChallengesService.getChallenges(cognito_user_sub);
      dispatch({
        type: ActionTypes.CHALLENGES_GET_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.CHALLENGES_GET_REJECTED,
        payload: exp,
      });
    }
  };
};

const getChallengeLeaders = (challengeId, cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CHALLENGES_LEADERS_GET_PENDING });
    try {
      const result = await ChallengesService.getChallengeLeaders(
        challengeId,
        cognito_user_sub,
      );
      dispatch({
        type: ActionTypes.CHALLENGES_LEADERS_GET_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.CHALLENGES_LEADERS_GET_REJECTED,
        payload: exp,
      });
    }
  };
};

const clearChallengeLeaders = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CHALLENGES_LEADERS_CLEAR });
  };
};

const getChallengesOld = (date) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CHALLENGES_GET_OLD_PENDING });
    try {
      const result = await ChallengesService.getChallengesOld(date);
      dispatch({
        type: ActionTypes.CHALLENGES_GET_OLD_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.CHALLENGES_GET_OLD_REJECTED,
        payload: exp,
      });
    }
  };
};

const joinChallenge = (
  cognito_user_sub,
  challengeData,
  lastLogin,
  activities,
) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CHALLENGE_JOIN_PENDING });
    try {
      const result = await ChallengesService.joinChallenge(
        cognito_user_sub,
        challengeData,
      );
      dispatch(
        updateJoinedChallenges({
          cognito_user_sub,
          lastLogin,
          activities,
        }),
      );
      dispatch({
        type: ActionTypes.CHALLENGE_JOIN_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.CHALLENGE_JOIN_REJECTED,
        payload: exp,
      });
    }
  };
};

const updateJoinedChallenges = (joinedChallengesData) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_JOINED_CHALLENGE_PENDING });
    try {
      const result = await ChallengesService.updateJoinedChallenge(
        joinedChallengesData,
      );
      dispatch(getChallenges(joinedChallengesData.cognito_user_sub, false));
      dispatch({
        type: ActionTypes.UPDATE_JOINED_CHALLENGE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.UPDATE_JOINED_CHALLENGE_REJECTED,
        payload: exp,
      });
    }
  };
};

const stopLoadingJoinedChallenges = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_JOINED_CHALLENGE_FULFILLED,
      payload: null,
    });
  };
};

export {
  getChallenges,
  getChallengesOld,
  joinChallenge,
  updateJoinedChallenges,
  stopLoadingJoinedChallenges,
  getChallengeLeaders,
  clearChallengeLeaders,
};
