import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

import AthleteUserPlanTop from "../athletes/athlete-plan/athlete-plan-info/athlete-user-plan-top";
import AthleteUserPlanRight from "../athletes/athlete-plan/athlete-plan-info/athlete-user-plan-right";
import AthletePlanUser from "../athletes/athlete-plan/athlete-plan-user";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing.unit * 2,
  },
});

class AthleteTrainingPlanInfo extends Component {
  render() {
    const {
      classes,
      selectedAthlete,
      countingAthleteWorkouts,
      openDrawer,
      onToggleDrawer,
    } = this.props;

    return (
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={onToggleDrawer.bind(this, false)}
      >
        <div
          className={classes.container}
          onKeyDown={onToggleDrawer.bind(this, false)}
        >
          <AthletePlanUser selectedAthlete={selectedAthlete} />
          <AthleteUserPlanTop selectedAthlete={selectedAthlete} />
          <AthleteUserPlanRight
            selectedAthlete={selectedAthlete}
            countingAthleteWorkouts={countingAthleteWorkouts}
          />
        </div>
      </Drawer>
    );
  }
}

AthleteTrainingPlanInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedAthlete: PropTypes.object.isRequired,
  countingAthleteWorkouts: PropTypes.bool,
  openDrawer: PropTypes.bool,
  onToggleDrawer: PropTypes.func,
};

export default withStyles(styles)(AthleteTrainingPlanInfo);
