const Genders = {
  Male: "Male",
  Female: "Female",
  Other: "Other",
};

export default {
  Items: Genders,
  itemsArray: Object.keys(Genders).map((key) => {
    return {
      text: Genders[key],
      value: key,
    };
  }),
};
