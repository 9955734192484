import React from "react";
import { SvgIcon } from "@material-ui/core";

const SendIcon = ({ width = 24, height = 24, state = "filled" }) => {
  let fill = "none";
  let stroke = "none";

  if (state === "highlighted") {
    stroke = "#6D6F71";
  } else if (state === "filled") {
    fill = "#6D6F71";
  } else if (state === "outline") {
    stroke = "#6D6F71";
  }

  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <svg
        viewBox={`-1 -1 ${width + 2} ${height + 2}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.253139 0.169633C0.359838 0.0791031 0.490778 0.0219061 0.629701 0.00514393C0.768623 -0.0116182 0.90941 0.0127928 1.03458 0.0753462L19.6063 9.36121C19.7248 9.42059 19.8244 9.51177 19.894 9.62455C19.9637 9.73733 20.0005 9.86725 20.0005 9.99979C20.0005 10.1323 19.9637 10.2622 19.894 10.375C19.8244 10.4878 19.7248 10.579 19.6063 10.6384L1.03458 19.9242C0.909495 19.9869 0.768743 20.0115 0.6298 19.995C0.490858 19.9784 0.359844 19.9214 0.253024 19.831C0.146205 19.7406 0.0682861 19.6208 0.0289408 19.4865C-0.0104044 19.3523 -0.00944274 19.2094 0.0317067 19.0756L2.82461 9.99979L0.0317067 0.923931C-0.00920739 0.790325 -0.0100365 0.647653 0.029322 0.51358C0.0686804 0.379508 0.146498 0.259923 0.253139 0.169633ZM4.09891 10.7141L1.88888 17.8999L17.6891 9.99979L1.88888 2.09966L4.09891 9.28549H12.1433C12.3328 9.28549 12.5145 9.36075 12.6484 9.4947C12.7824 9.62866 12.8576 9.81034 12.8576 9.99979C12.8576 10.1892 12.7824 10.3709 12.6484 10.5049C12.5145 10.6388 12.3328 10.7141 12.1433 10.7141H4.09891Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1"
        />
      </svg>
    </SvgIcon>
  );
};

export default SendIcon;
