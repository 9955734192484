import ActionTypes from "../constants/action-types";

const initialState = {
  data: [],
  coachesData: [],
  categoriesData: [],
  loading: false,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.GET_CUSTOMERS_DASHBOARD_PENDING:
        return {
          ...state,
          loading: true,
        };

      case ActionTypes.GET_CUSTOMERS_DASHBOARD_FULLFILLED:
        return {
          ...state,
          data: !state.data.some(
            ({ status }) => status === action.payload.status,
          )
            ? [...state.data, action.payload]
            : [action.payload],
          loading: false,
        };

      case ActionTypes.GET_CUSTOMERS_DASHBOARD_CUSTOMERS_FULFILLED:
        return {
          ...state,
          coachesData: !state.coachesData.some(
            ({ status }) => status === action.payload.status,
          )
            ? [...state.coachesData, action.payload]
            : [action.payload],
          loading: false,
        };

      case ActionTypes.GET_DASHBOARD_SUBCATEGORIES_FULFILLED:
        return {
          ...state,
          categoriesData: !state.categoriesData.some(
            ({ status }) => status === action.payload.status,
          )
            ? [...state.categoriesData, action.payload]
            : [action.payload],
          loading: false,
        };
      case ActionTypes.GET_CUSTOMERS_DASHBOARD_REJECTED:
        return {
          ...state,
          loading: false,
        };
      case ActionTypes.CLEAR_ADMIN_DASHBOARD:
        return initialState;

      default:
        return state;
    }
  } else {
    return state;
  }
}
