import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { withTranslation } from "react-i18next";
import history from "../../../lib/history-helper";
import WorkoutPlans from "../../../constants/workout-plans";
import { dateFormat } from "../../../lib/date-helper";
import UpgradeProModal from "../../generals/upgrade-pro-modal";
import { Button } from "@material-ui/core";
import { logEvent } from "../../../lib/events-helper";

const styles = (theme) => ({
  button: {
    fontWeight: "normal",
    margin: "0 20px",
    [theme.breakpoints.down("md")]: {
      margin: "0 20px 0 auto",
    },
  },
  minWZero: {
    minWidth: "initial",
  },
});

class DashboardTrainingNext extends Component {
  state = {
    stateCurrentStartDate: null,
    disableNextClick: false,
    exploreFeatureDialogOpen: false,
  };

  handleCloseUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
  };

  handleUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
    history.push("/subscription/explore");
  };

  componentDidMount() {
    this.setState({
      stateCurrentStartDate: this.props.currentStartDate,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.disableNextButtonClick();
    }
  }

  disableNextButtonClick = () => {
    const { stateCurrentStartDate } = this.state;
    const { currentStartDate } = this.props;
    if (
      moment(currentStartDate) >= moment(stateCurrentStartDate).add(7, "days")
    ) {
      this.setState({
        disableNextClick: this.getNextButtonStatus(),
      });
    } else {
      this.setState({
        disableNextClick: false,
      });
    }
  };

  getNextButtonStatus = () => {
    const {
      isInMobileView,
      currentStartDate,
      startingDate,
      selectedPlan,
      dayCount,
      duration,
      hasSubscription,
    } = this.props;

    const endOfCurrentWeek = moment()
      .endOf("isoWeek")
      .startOf("day");
    let nextCount = isInMobileView
      ? -moment(currentStartDate)
          .startOf("day")
          .diff(
            moment(currentStartDate)
              .startOf("day")
              .startOf("week"),
            "day",
          ) +
        7 +
        1
      : dayCount;
    if (nextCount === 8) {
      nextCount = 1;
    }

    const limitedView = false;

    let buttonDisable;
    if (limitedView) {
      buttonDisable =
        moment(currentStartDate)
          .startOf("day")
          .isSame(endOfCurrentWeek) ||
        moment(currentStartDate)
          .add(nextCount, "days")
          .startOf("day")
          .isAfter(endOfCurrentWeek);
    } else {
      buttonDisable = moment(startingDate, dateFormat)
        .add(duration, "weeks")
        .startOf("isoWeek")
        .startOf("day")
        .isSameOrBefore(
          moment(currentStartDate)
            .add(nextCount, "days")
            .startOf("day"),
        );
    }

    return buttonDisable;
  };

  render() {
    const {
      isInMobileView,
      currentStartDate,
      onCurrentDaysChangeRequested,
      dayCount,
      classes,
      hasSubscription,
    } = this.props;

    const { exploreFeatureDialogOpen } = this.state;

    let nextCount = isInMobileView
      ? -moment(currentStartDate)
          .startOf("day")
          .diff(
            moment(currentStartDate)
              .startOf("day")
              .startOf("week"),
            "day",
          ) +
        7 +
        1
      : dayCount;
    if (nextCount === 8) {
      nextCount = 1;
    }

    const onClick = () => {
      if (!this.getNextButtonStatus()) {
        onCurrentDaysChangeRequested(nextCount);
        this.disableNextButtonClick();
      } else {
        if (!hasSubscription) {
          this.setState({
            ...this.state,
            exploreFeatureDialogOpen: true,
          });
        }
      }
    };

    return (
      <>
        <Button
          className={classes.button}
          classes={{ root: classes.minWZero }}
          onClick={() => onClick()}
          disabled={this.getNextButtonStatus()}
        >
          <img src={`${process.env.PUBLIC_URL}/arrow-right.svg`} alt="" />
        </Button>

        <UpgradeProModal
          open={exploreFeatureDialogOpen}
          onClose={this.handleCloseUpgrade}
        />
      </>
    );
  }
}

DashboardTrainingNext.propTypes = {
  classes: PropTypes.object.isRequired,
  isInMobileView: PropTypes.bool.isRequired,
  onCurrentDaysChangeRequested: PropTypes.func.isRequired,
  currentStartDate: PropTypes.any,
  startingDate: PropTypes.string.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  dayCount: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation("dashboard")(
  withStyles(styles, { withTheme: true })(DashboardTrainingNext),
);
