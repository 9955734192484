const RaceCounts = {
  "0": "0",
  "1": "1",
  "3": "3",
  "5": "5",
  "6": "6",
  "7": "7+",
};

export default {
  Items: RaceCounts,
  itemsArray: Object.keys(RaceCounts).map((key) => {
    return {
      text: RaceCounts[key],
      value: key,
    };
  }),
};

export const levelScale = {
  "0,1": 0,
  "3,5": 1,
  "6,7": 2,
};
