import Theme from "./layout/theme";

const AuthTheme = {
  container: {
    flex: 0.5,
    flexGrow: 1,
    width: "auto",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Avenir",
  },
  formSection: {
    width: "auto",
    maxWidth: 350,
    padding: 20,
  },
  sectionHeader: {
    color: "gray",
    textAlign: "center",
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "1.2em",
    marginBottom: 24,
  },
  sectionSmallHeader: {
    color: Theme.palette.common.white,
    textAlign: "center",
    fontSize: "x-large",
    marginBottom: 0,
    marginTop: 50,
    fontWeight: 500,
  },
  input: {
    fontSize: 14,
    lineHeight: "1.2em",
    paddingRight: "14",
    background: "none",
    width: "92%",
    padding: "14px 14px",
    border: 0,
    borderBottom: "1px solid gray",
    marginTop: "10px",
    marginBottom: "10px",

    "&:focus": {
      outline: "none",
      border: 0,
      borderBottom: "1px solid gray",
    },
  },
  inputLabel: {
    color: "gray",
    fontFamily: "Avenir",
    fontSize: 14,
  },
  button: {
    borderRadius: 26,
    color: "#fff",
    backgroundColor: "#FF6327",
    padding: "8px 24px",
    fontSize: "0.9375rem",
    width: "100%",
    border: 0,
    fontFamily: "Avenir",
    fontWeight: 500,
    textTransform: "uppercase",
    margin: "10px 0",
  },
  hint: {
    color: "gray",
    fontSize: "0.875rem",
    marginBottom: 24,
  },
  sectionFooter: {
    color: Theme.palette.common.white,
    fontSize: "0.875rem",
    marginBottom: 24,
  },
  sectionFooterSecondaryContent: {
    display: "block",
    textAlign: "center",
  },
  toast: { display: "none" },
  a: {
    color: "rgb(255, 99, 39)",
    cursor: "pointer",
    fontFamily: "Avenir",
  },
};

export default AuthTheme;
