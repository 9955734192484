import React from "react";
import { palette } from "../../theme/palette";

const AvgDownhillSpeedIcon = ({
  width = 24,
  height = 24,
  activated = false,
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 15H13.7037C14.1611 15 14.3784 14.4367 14.0395 14.1295L3.83576 4.88241C3.51439 4.59117 3 4.8192 3 5.2529V14.5C3 14.7761 3.22386 15 3.5 15Z"
        stroke={activated ? palette.primary : palette.darkGray}
        strokeWidth="1.3"
      />
      <path
        d="M9 2L16 9M16 9H12.1111M16 9V4.88235"
        stroke={activated ? palette.primary : palette.darkGray}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
export default AvgDownhillSpeedIcon;
