import React, { useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import RouterLink from "../../common/router-link";
import { makeStyles } from "@material-ui/core/styles";
import LinksSettings from "./links-settings";
import { theme } from "../../../theme";
import { getCoaches } from "../../../actions/athletes-action";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getNotifications } from "../../../actions/notifications-action";
import LocalNotificationsService from "../../../services/local-notifications-service";
import GarminIcon from "../../icons/GarminIcon";
import StravaIcon from "../../icons/StravaIcon";
import CorosIcon from "../../icons/CorosIcon";
import SuuntoIcon from "../../icons/SuuntoIcon";
import {
  AppleIcon,
  LanguageIcon,
  WatchIcon,
  AccountIcon,
  UnitIcon,
  NotificationIcon,
} from "../../icons";
import { palette } from "../../../theme/palette";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { IconTrash } from "@tabler/icons-react";
import ModalCustom from "../../common/v2/ModalCustom";
import { Box } from "@mui/material";
import CustomButton from "../../common/v2/CustomButton";
import { useAuthContext } from "../../../context/AuthProvider";
import browserHistory from "../../../lib/history-helper";
import HighlightText from "../../common/v2/HighlightText";

const GlobalSettings = () => {
  const [checked, setChecked] = React.useState(false);
  const [checkedUnit, setCheckedUnit] = React.useState(false);
  let { userPlans } = useSelector((state) => state);
  const [areYouSureForDelete, setAreYouSureForDelete] = React.useState(false);

  const { hanndleDeleteAccount } = useAuthContext();
  let { currentPlan } = userPlans;
  const { cognito_user_email } = currentPlan || "";
  const [forceUpdateFlag, setForceUpdateFlag] = React.useState(false);

  const { i18n } = useTranslation();
  const { t } = useTranslation("settings");
  const units = localStorage.getItem("units");
  const notifications = localStorage.getItem("notifications");

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCoaches());
    units == "kms" ? setCheckedUnit(true) : setCheckedUnit(false);
    notifications == "true" ? setChecked(true) : setChecked(false);
  }, []);

  const styles = useStyles();

  const deleteAccount = () => {
    setAreYouSureForDelete(false);
    hanndleDeleteAccount(cognito_user_email);
    browserHistory.push("/signout");
  };
  const handleChange = (event) => {
    if (event.target.id === "notification") {
      if (event.target.checked === true) {
        LocalNotificationsService.listenToNotifications().then(
          (notifications) => {
            if (notifications === "denied" || notifications === "prompt") {
              localStorage.setItem("notifications", false);
              setChecked(false);
              dispatch(getNotifications(false));
            } else {
              dispatch(getNotifications(true));
              setChecked(true);
              localStorage.setItem("notifications", true);
            }
          },
        );
      } else {
        dispatch(getNotifications(false));

        setChecked(event.target.checked);
        LocalNotificationsService.stopListenToNotifications();
        //LocalNotificationsService.stopNotifications();
        localStorage.setItem("notifications", event.target.checked);
      }
    } else if (event.target.id === "unit") {
      setCheckedUnit(event.target.checked);

      const id = event.target.checked !== true ? "miles" : "kms";

      try {
        localStorage.setItem("units", id);
      } catch (e) {
        console.log(e);
      }

      window.dispatchEvent(new Event("storage"));
      setForceUpdateFlag((prevFlag) => !prevFlag);
    }
  };
  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: palette.secondary,
      "&:hover": {
        backgroundColor: alpha(
          palette.secondary,
          theme.palette.action.hoverOpacity,
        ),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: palette.deactivated,
    },
  }));
  const changeLanguages = (language) => {
    i18n.changeLanguage(language.target.value);
  };

  const label = { inputProps: { "aria-label": "Color switch demo" } };
  return (
    <div className={styles.options}>
      <div className={styles.account}>
        <div className={styles.category}>
          <AccountIcon width={18} height={18} />
          <b className={styles.garmmin}>{t("settings.yourAccount")}</b>
        </div>

        <div className={styles.subCategories}>
          <RouterLink to="/profile/settings/account">
            <div className={styles.subscription}>
              <div className={styles.garmmin}>{t("settings.account")}</div>
              <img
                className={styles.iconprofile}
                alt=""
                src={`${process.env.PUBLIC_URL}/Icon_short-arrow.png`}
              />
            </div>
          </RouterLink>

          <RouterLink to="/profile/settings/subscription-details">
            <div className={styles.subscription}>
              <div className={styles.garmmin}>
                {t("settings.subscriptionDetails")}
              </div>
              <img
                className={styles.iconprofile}
                alt=""
                src={`${process.env.PUBLIC_URL}/Icon_short-arrow.png`}
              />
            </div>
          </RouterLink>

          <RouterLink to="/profile/settings/change-profile-picture">
            <div className={styles.subscription}>
              <div className={styles.garmmin}>
                {t("settings.changeProfilePircture")}
              </div>
              <img
                className={styles.iconprofile}
                alt=""
                src={`${process.env.PUBLIC_URL}/Icon_short-arrow.png`}
              />
            </div>
          </RouterLink>
        </div>
      </div>
      <div className={styles.optionsChild} />
      <div className={styles.account}>
        <div className={styles.subCategories}>
          <RouterLink to="/profile/settings/watch">
            <div className={styles.subscription} style={{ gap: 8 }}>
              <WatchIcon width={18} height={18} />
              <b className={styles.garmmin}>{t("settings.connectYouWatch")}</b>
              <img
                className={styles.iconprofile}
                alt=""
                src={`${process.env.PUBLIC_URL}/Icon_short-arrow.png`}
              />
            </div>
          </RouterLink>
        </div>

        {currentPlan == undefined ? (
          ""
        ) : currentPlan.garmin_oauth_token != null ? (
          <div className={styles.subscription}>
            <div className={styles.icongarmmin}>
              <GarminIcon />
              <div className={styles.garmin}>Garmin</div>
            </div>
            <div className={styles.connected}>
              {t("settings.watchConnected")}
            </div>
          </div>
        ) : (
          ""
        )}

        {currentPlan == undefined ? (
          ""
        ) : currentPlan.strava_athlete_id != null ? (
          <div className={styles.subscription}>
            <div className={styles.icongarmmin}>
              <StravaIcon />
              <div className={styles.garmin}>Strava</div>
            </div>
            <div className={styles.connected}>
              {t("settings.watchConnected")}
            </div>
          </div>
        ) : (
          ""
        )}

        {currentPlan == undefined ? (
          ""
        ) : currentPlan.coros_auth != null ? (
          <div className={styles.subscription}>
            <div className={styles.icongarmmin}>
              <CorosIcon />
              <div className={styles.garmin}>Coros</div>
            </div>
            <div className={styles.connected}>
              {t("settings.watchConnected")}
            </div>
          </div>
        ) : (
          ""
        )}

        {currentPlan == undefined ? (
          ""
        ) : currentPlan.suunto_auth != null ? (
          <div className={styles.subscription}>
            <div className={styles.icongarmmin}>
              <SuuntoIcon />
              <div className={styles.garmin}>Suunto</div>
            </div>
            <div className={styles.connected}>
              {t("settings.watchConnected")}
            </div>
          </div>
        ) : (
          ""
        )}

        {currentPlan == undefined ? (
          ""
        ) : currentPlan.healthkit != null ? (
          <div className={styles.subscription}>
            <div className={styles.icongarmmin}>
              <AppleIcon activated={true} />
              <div className={styles.garmin}>Apple</div>
            </div>
            <div className={styles.connected}>
              {t("settings.watchConnected")}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={styles.optionsChild} />
      <div className={styles.notifications}>
        <div className={styles.settingOptions}>
          <div className={styles.category}>
            <NotificationIcon width={18} height={18} />
            <b className={styles.garmmin}>{t("settings.notifications")}</b>
          </div>
        </div>
        <div>
          <div className={styles.icongarmmin}>
            <PinkSwitch
              color="secondary"
              checked={checked}
              id="notification"
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
              {...label}
            />
          </div>
        </div>
      </div>
      <div className={styles.optionsChild} />
      <div className={styles.notifications}>
        <div className={styles.settingOptions}>
          <div className={styles.category}>
            <UnitIcon width={18} height={18} />
            <b className={styles.garmmin}>{t("settings.unit")}</b>
          </div>
        </div>
        <div className={styles.booleanUnit}>
          <div className={styles.icongarmmin}>
            <div className={checkedUnit !== true ? styles.on : styles.garmin}>
              {t("settings.unit.miles")}
            </div>
            <PinkSwitch
              checked={checkedUnit}
              id="unit"
              color="secondary"
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
              {...label}
            />
            <div className={checkedUnit === true ? styles.on : styles.garmin}>
              {t("settings.unit.kms")}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.optionsChild} />
      <div className={styles.language}>
        <div className={styles.categoryLenguage}>
          <LanguageIcon width={18} height={18} />
          <b className={styles.garmmin}>{t("settings.language")}</b>
        </div>
        <select
          onChange={changeLanguages}
          value={i18n.language}
          className={styles.garmin2}
        >
          <option value="en">EN</option>
          <option value="fr">FR</option>
          <option value="es">ES</option>
        </select>
      </div>
      <div className={styles.optionsChild} />
      <div className={styles.account}>
        <div className={styles.category}>
          <InfoOutlinedIcon sx={{ width: "18px", height: "18px" }} />
          <b className={styles.garmmin}>{t("settings.infoAndSupport")}</b>
        </div>
        <div className={styles.subCategories}>
          <LinksSettings
            title={t("settings.faqs")}
            link={"/profile/settings/faqs"}
          />
          <LinksSettings
            disabled={true}
            title={t("settings.reportProblem")}
            link={"/profile/settings/report-a-problem"}
          />
          <LinksSettings
            title={t("settings.privacyAndPolicy")}
            link={"https://vert.run/privacy-policy/"}
            externalLink={true}
          />
          <LinksSettings
            title={t("settings.whatIsVert")}
            externalLink={true}
            link={"https://vert.run/about"}
          />
          <LinksSettings
            disabled={true}
            title={t("settings.ourCoaches")}
            link={"/profile/settings/our-coaches"}
          />
          <LinksSettings
            disabled={true}
            title={t("settings.workForVert")}
            link={"/profile/settings/work-with-us"}
          />
        </div>
      </div>
      <div className={styles.optionsChild} />
      <RouterLink to="/signout">
        <div className={styles.settingOptionsChild}>
          <div className={styles.category}>
            <LogoutOutlinedIcon sx={{ height: "18px", width: "18px" }} />
            <b className={styles.garmmin}>{t("settings.logout")} </b>
          </div>
        </div>
      </RouterLink>
      <IconButton
        className={styles.deleteButton}
        onClick={() => setAreYouSureForDelete(true)}
      >
        <IconTrash size="18px" color="#12151B" stroke={1.5} />
        <Typography className={styles.deleteText}>
          {t("settings.deleteAccount")}
        </Typography>
      </IconButton>
      <Typography
        style={{
          fontFamily: "Karla",
          fontSize: "13px",
          fontWeight: 200,
        }}
      >
        v3.3.1
      </Typography>
      <ModalCustom
        fullScreen={false}
        open={areYouSureForDelete}
        onClose={() => setAreYouSureForDelete(false)}
        toolbarJustifyContent="end"
        headerStyles={{
          padding: "8px 5px !important",
        }}
        contentStyles={{
          padding: "8px 20px 10px",
          gap: 8,
        }}
      >
        <Grid container styles={{ gap: "6px 0" }}>
          <Grid item xs={12}>
            <Typography className={styles.modalTitle}>
              {t("settings.deleteAccount.modalTitle.p1")}{" "}
              <HighlightText color={palette.primary} text={t("delete")} />{" "}
              {t("settings.deleteAccount.modalTitle.p2")}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "15px 0" }}>
            <Typography className={styles.modalDescription}>
              {t("settings.deleteAccount.modalDescription.p1")}{" "}
              <span className={styles.modalDescriptionSpan}>
                {t("settings.deleteAccount.modalDescription.highlight")}{" "}
              </span>
              {t("settings.deleteAccount.modalDescription.p2")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box py={2}>
              <CustomButton
                color="secondary"
                typeStyle="primary"
                variant="contained"
                size="large"
                onClick={deleteAccount}
                fullWidth
              >
                <Typography className={styles.modalConfirmButton} noWrap>
                  {t("settings.buttons.deleteAccount")}
                </Typography>
              </CustomButton>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box py={2} pl={1}>
              <CustomButton
                color="default"
                typeStyle="secondary"
                variant="contained"
                size="large"
                onClick={() => setAreYouSureForDelete(false)}
                fullWidth
              >
                <Typography className={styles.modalCancelButton} noWrap>
                  {t("settings.buttons.cancel")}
                </Typography>
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </ModalCustom>
    </div>
  );
};

export default GlobalSettings;

const useStyles = makeStyles({
  iconprofile: {
    position: "relative",
    width: "18px",
    height: "18px",
    overflow: "hidden",
    flexShrink: 0,
    objectFit: "cover",
  },
  category: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
  },
  garmmin: {
    flex: 1,
    position: "relative",
    lineHeight: "150%",
  },
  subscription: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subCategories: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "unset",
    justifyContent: "flex-start",
    gap: "4px",
  },
  account: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "12px",
  },
  optionsChild: {
    position: "relative",
    borderTop: "0.5px solid #eaeaea",
    boxSizing: "border-box",
    width: "354.5px",
    height: "0.5px",
  },
  icongarmminChild: {
    position: "relative",
    width: "18px",
    height: "18px",
    objectFit: "cover",
  },

  icongarmmin: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
  },
  connected: {
    position: "relative",
    lineHeight: "150%",
    color: "#6bb488",
  },
  settingOptions: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },

  on: {
    position: "relative",
    lineHeight: "150%",
    color: "#12151b",
  },
  boolean: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px 26px",
    textAlign: "center",
    color: "#afb1b4",
  },
  notifications: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "12px",
  },
  booleanUnit: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px 12px",
    textAlign: "center",
    color: "#afb1b4",
  },
  categoryLenguage: {
    alignSelf: "stretch",
    width: "265px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
  },
  inputdropdown: {
    borderRadius: "4px",
    backgroundColor: "#feffff",
    boxShadow: "0px 4px 154px rgba(18, 21, 27, 0.05)",
    border: "2px solid #eaeaea",
    boxSizing: "border-box",
    width: "73px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "2px 8px",
    textAlign: "center",
  },
  language: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px 8px 0px 0px",
    gap: "12px",
  },
  privacyPolicy: {
    width: "358px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  settingOptionsChild: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  options: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "24px 16px",
    boxSizing: "border-box",
    gap: "12px",
    textAlign: "left",
    fontSize: theme.typography.h5.fontSize,
    color: "#12151b",
    fontFamily: "Karla",
  },
  garmin2: {
    position: "relative",
    width: "73px",
    height: "28px",
    borderRadius: "4px",
    border: "2px #eaeaea solid",
    marginLeft: "auto",
    lineHeight: "150%",
    padding: "2px 8px",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row",
    padding: "0px",
  },
  deleteText: {
    marginLeft: "8px",
    fontFamily: "Karla",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#12151B",
  },
  modalTitle: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 700,
    textAlign: "left",
    color: "#12151B",
  },
  modalDescription: {
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
    textAlign: "left",
    color: "#12151B",
  },
  modalDescriptionSpan: {
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 700,
    textAlign: "left",
    textDecoration: "underline",
    color: "#12151B",
  },
  modalConfirmButton: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 700,
    color: palette.white,
  },
  modalCancelButton: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 700,
    color: palette.secondary,
  },
});
