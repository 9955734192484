import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import Delete from "@material-ui/icons/DeleteOutline";
import DeleteConfirmationDialog from "../common/delete-confirmation-dialog";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { deleteVertActivity } from "../../actions/vert-activity-action";
import { useSelector } from "react-redux";
import { getUserActivities } from "../../actions/user-plans-action";

const useStyles = makeStyles({
  text: {
    color: "#AAAAAA",
  },
  container: {
    display: "flex",
    cursor: "pointer",
    width: "fit-content",
    padding: 4,
    boxSizing: "border-box",
    borderRadius: 3,
    "&:hover > p, &:hover > svg": {
      color: "#E16328",
    },
  },
  icon: {
    color: "#AAAAAA",
    marginRight: 2,
  },
});

export const DeleteActivity = ({ activity }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const { currentPlan } = useSelector((state) => state.userPlans);
  const { deletedActivity } = useSelector((state) => state.vertActivities);

  const removeActivity = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    await dispatch(
      deleteVertActivity({
        cognito_user_sub: activity.cognito_user_sub,
        created_at: activity.created_at,
      }),
    );
    await showConfirm();
  };

  const showConfirm = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setShow((prev) => !prev);
  };

  const UpdateActivityList = async () => {
    await dispatch(getUserActivities(currentPlan));
  };

  useEffect(() => {
    if (deletedActivity) UpdateActivityList();
  }, [deletedActivity]);

  return (
    <>
      {show && (
        <DeleteConfirmationDialog
          entityName="Epic Race"
          onConfirm={removeActivity}
          onCancel={showConfirm}
          message={t("Are u sure you want to discard this activity")}
        />
      )}
      <Box className={classes.container} onClick={showConfirm}>
        <Delete className={classes.icon} />
        <Typography className={classes.text}>{t("Delete activity")}</Typography>
      </Box>
    </>
  );
};
