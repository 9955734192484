import { createTheme } from "@material-ui/core/styles";
import { palette } from "./palette";
import { componentsOverrides } from "./components";

export const theme = createTheme({
  palette: {
    primary: {
      main: palette.primary,
      dark: palette.dark,
    },
    secondary: {
      main: palette.secondary,
      light: palette.lightBlue,
      darkBlue: palette.darkBlue,
    },
  },
  typography: {
    fontFamily: ["Karla", "Oswald"],
    h1: {
      fontSize: "46px",
      fontWeight: "600",
      letterSpacing: "1px",
    },
    h2: {
      fontSize: "32px",
      fontWeight: "500",
      letterSpacing: "1px",
    },
    h3: {
      fontSize: "22px",
      fontWeight: "600",
      letterSpacing: "1px",
    },
    h4: {
      fontSize: "18px",
      fontWeight: "500",
      letterSpacing: "0px",
    },
    h5: {
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0px",
    },
    h6: {
      fontSize: "14px",
      fontWeight: "400",
      letterSpacing: "0px",
    },
    subtitle1: {
      fontSize: "22px",
      fontWeight: "500",
      letterSpacing: "0px",
    },
    subtitle2: {
      fontSize: "16px",
      fontWeight: "bold",
      letterSpacing: "0px",
    },
    button: {
      fontSize: "14px",
      letterSpacing: "0px",
    },
    body1: {
      fontSize: "16px",
      fontWeight: "400",
      letterSpacing: "0px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: "400",
      letterSpacing: "0px",
    },
  },
  caption: {
    fontSize: "12px",
    fontWeight: "400",
    letterSpacing: "0px",
  },
  overrides: componentsOverrides,
  // spacing: [8, 16, 24, 32, 40, 48, 56],
});
