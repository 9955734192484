import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
const useStyles = makeStyles({
  gettingStarted: {
    flex: 1,
    position: "relative",
    lineHeight: "150%",
    textAlign: "left",
  },
  yesAfterYou: {
    fontFamily: theme.typography.fontFamily,
    lineHeight: "1.4",
  },
  li: {
    marginBottom: "25px",
    textAlign: "justify",
  },
  yesAfterYouSignUpForVer: {
    margin: 0,
    fontSize: "inherit",
    paddingLeft: "19px",
  },
  content: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px 16px",
    boxSizing: "border-box",
    textAlign: "left",
    fontSize: theme.typography.h6.fontSize,
    color: "#12151b",
    fontFamily: theme.typography.fontFamily,
  },
});
export default function BasicAccordion({ titles, content, link }) {
  const styles = useStyles();

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={styles.gettingStarted}>{titles}</div>
        </AccordionSummary>
        <AccordionDetails>
          {link == true ? (
            <ul
              className={styles.yesAfterYouSignUpForVer}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          ) : (
            <ul className={styles.yesAfterYouSignUpForVer}>
              {content.map((item) => (
                <li className={styles.li}>
                  <span className={styles.yesAfterYou}>{item}</span>
                </li>
              ))}
            </ul>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
