import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import "url-search-params-polyfill";
import { Drawer } from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import DashboardNextRaceDetails from "../dashboard/dashboard-training/dashboard-next-race/dashboard-next-race-details";
import moment from "moment";
import withWidth from "@material-ui/core/withWidth";
import { getSubcategories } from "../../actions/subcategory-action";
import {
  getAthlete,
  updateProfile,
  clearAthlete,
  cleanNewPost,
} from "../../actions/trail-head-action";
import {
  updatePlanImage,
  updatePlanAddImage,
  updatePlanDeleteImage,
  getUserBadges,
} from "../../actions/user-plans-action";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "./social-profile/card";
import ProfileHeader from "./social-profile/profile-header";
import PersonalData from "./social-profile/personal-data";
import GroupsCard from "./social-profile/groups-card";
import { getUserPosts } from "../../actions/trail-head-action";
import PostList from "./social-profile/user-posts/post-list";
import history from "../../lib/history-helper";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import classNames from "classnames";
import { Routes } from "../../lib/routing-middleware";
import TrailHeadService from "../../services/trail-head-service";
import { dateFormat } from "../../lib/date-helper";

const styles = (theme) => ({
  main: {
    position: "relative",
  },
  imgRadius: {
    borderRadius: "12px",
  },
  error: {
    color: theme.palette.error.light,
  },
  success: {
    color: theme.palette.success.light,
  },
  strava: {
    background: "#ed4834",
    color: "white",
  },
  avatar: {
    width: 56,
    height: 56,
    alignSelf: "center",
  },
  middleAlign: {
    marginBottom: "auto",
    marginTop: "auto",
  },
  labelContainer: {
    textTransform: "uppercase",
  },
  step: {
    fontSize: 44,
  },
  connectorLine: {
    display: "none",
  },
  title: {
    fontSize: 18,
    fontWeigth: "bold",
    textTransform: "uppercase",
  },
  text: {
    fontSize: 14,
    margin: "25px 0",
    display: "flex",
    textTransform: "uppercase",
  },
  labelText: {
    textTransform: "uppercase",
  },
  valueText: {
    textTransform: "uppercase",
    marginLeft: 5,
  },
  profileContainer: {
    marginTop: 80,
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  button: {
    marginTop: 20,
    boxShadow: "none",
    borderRadius: 28,
    marginBottom: 20,
    marginLeft: 20,
  },
  stepper: {
    padding: "0 24px",
  },
  drawerRoot: {
    backgroundColor: "#F9F9FB",
    maxHeight: "100%",
    height: "100%",
    maxWidth: "500px",
    minWidth: "500px",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "100vw",
      minWidth: "100vw",
    },
  },
  tabsContainer: {
    margin: "0 10px",
  },
  tabsMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  tabSelected: {
    width: 100,
    textAlign: "center",
    padding: 10,
    position: "relative",
    cursor: "pointer",
  },
  tabNotSelected: {
    width: 100,
    textAlign: "center",
    padding: 10,
    position: "relative",
    cursor: "pointer",
  },
  tabContainer: {
    borderRadius: 20,
    background: "#fff",
  },
  editIcon: {
    cursor: "pointer",
    float: "right",
  },
  editActions: {
    display: "inline",
    float: "right",
  },
  editText: {
    color: "3F3F3F",
    fontSize: 10,
    textAlign: "right",
    cursor: "pointer",
    float: "right",
    marginTop: 5,
  },
  loading: {
    textAlign: "center",
    marginTop: 10,
  },
  imgIcon: {
    height: 24,
  },
  stickyIcon: {
    position: "sticky",
    top: 15,
    marginLeft: 25,
    zIndex: 999,
    width: "fit-content",
  },
  iconParent: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transitionDuration: ".6s",
    position: "relative",

    "&:hover": {
      backgroundColor: "#FFF",
      transitionDuration: ".6s",
    },
  },
  actionIcon: {
    cursor: "pointer",
    fontSize: "16px",
    textAlign: "center",
  },
  reverseIcon: {
    position: "absolute",
    left: "50%",
    transform: "translate(-55%, 0%) scale(-1, 1)",
  },
});

class SocialProfile extends Component {
  state = {
    currentTab: 1,
    isEditing: false,
    athleteDescription: null,
    avatarUrl: null,
  };

  async componentDidMount() {
    this.props.dispatch(getSubcategories());

    const {
      garmin_oauth_token,
      strava_athlete_id,
      suunto_auth,
      stravaActivities,
      coros_auth,
    } = selectedAthlete || {};

    const { userPlans, trailHead } = this.props;
    const { currentPlan } = userPlans;
    const { selectedAthlete } = trailHead;
    const { profileQuestions } = selectedAthlete || {};

    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      this.props.dispatch(getUserBadges(id));
      this.props.dispatch(getAthlete(id));
      this.props.dispatch(getUserPosts(id, new Date().getTime()));
      this.props.dispatch(cleanNewPost());
    }

    let newState = {};
    let hasChanged = false;
    // const { trailHead } = this.props;
    if (
      profileQuestions &&
      (profileQuestions[0] ||
        profileQuestions[1] ||
        profileQuestions[2] ||
        profileQuestions[3])
    ) {
      newState.questions = profileQuestions;
      newState.currentTab = 0;
      hasChanged = true;
    }

    if (hasChanged) {
      this.setState({
        ...this.state,
        ...newState,
      });
    }
  }

  async componentDidUpdate(prevPops) {
    const { trailHead, challenges } = this.props;
    const { selectedAthlete } = trailHead;
    const { description, profileQuestions } = selectedAthlete || {};
    const { athleteDescription, questions } = this.state;

    let newState = {};
    let hasChanged = false;
    if (description && athleteDescription === null) {
      newState.athleteDescription = description;
      hasChanged = true;
    }

    if (
      profileQuestions &&
      (profileQuestions[0] ||
        profileQuestions[1] ||
        profileQuestions[2] ||
        profileQuestions[3]) &&
      !questions
    ) {
      newState.questions = profileQuestions;
      newState.currentTab = 0;
      hasChanged = true;
    }

    if (hasChanged) {
      this.setState({
        ...this.state,
        ...newState,
      });
    }
  }

  goBack = () => {
    history.goBack();
    this.props.dispatch(clearAthlete());
  };

  handleChangeTab = (newValue) => {
    this.setState({
      ...this.state,
      currentTab: newValue,
    });
  };

  handleEdit = () => {
    this.setState({
      ...this.state,
      isEditing: true,
    });
  };

  closeEdit = () => {
    const { trailHead } = this.props;
    const { selectedAthlete } = trailHead;
    const { description, profileQuestions } = selectedAthlete || {};
    this.setState({
      ...this.state,
      isEditing: false,
      athleteDescription: description,
      questions: profileQuestions,
    });
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    if (id) {
      this.props.dispatch(getAthlete(id));
    }
  };

  handleSave = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || {};
    const { athleteDescription, questions } = this.state;
    this.props.dispatch(
      updateProfile({
        cognito_user_sub,
        description: athleteDescription,
        profileQuestions: questions,
      }),
    );
    this.setState({
      ...this.state,
      isEditing: false,
    });
  };

  handleDescriptionChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          ...this.state,
          athleteDescription:
            value.replace(/(\r\n|\n|\r)/gm, "").slice(0, 140) || "",
        });
      }
    }
  };

  handleQuestionChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { id, value } = target;
        if (id) {
          const questions = {
            ...this.state.questions,
            [id]: value.replace(/(\r\n|\n|\r)/gm, "").slice(0, 140) || "",
          };
          this.setState({
            ...this.state,
            questions,
          });
        }
      }
    }
  };

  handleImageFieldChange = (value, extension) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      updatePlanImage({
        cognito_user_sub: currentPlan.cognito_user_sub,
        image: value,
        imageExtension: extension && extension.toLowerCase(),
      }),
    );
  };

  handleProfileImageFieldChange = (value, extension) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      updatePlanAddImage({
        cognito_user_sub: currentPlan.cognito_user_sub,
        image: value,
        imageExtension: extension && extension.toLowerCase(),
      }),
    );
  };

  handleProfileImageDelete = (value) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      updatePlanDeleteImage({
        cognito_user_sub: currentPlan.cognito_user_sub,
        image: value,
      }),
    );
  };

  handleClose = () => {
    history.push("/trailhead");
    this.props.dispatch(clearAthlete());
  };

  render() {
    const { classes, trailHead, userPlans } = this.props;
    const { selectedAthlete, currentUserPosts } = trailHead;
    const { currentPlan } = userPlans;

    const { isEditing } = this.state;

    const {
      image,
      sex,
      currentLocation,
      currentGeoLocation,
      cognito_user_name,
      cognito_user_sub,
      stravaStats,
      streakPercentage,
      createdAt,
      description,
      lastLogin,
    } = selectedAthlete || {};

    const { currentUserBadges } = userPlans;
    const { updatingImage, cognito_user_sub: mySub } = currentPlan || {};

    const isMine = cognito_user_sub && mySub && cognito_user_sub === mySub;

    const { nextRace, nextRaces, epicRaces } = selectedAthlete || {};

    let allRaces = [];
    if (nextRace) {
      const today = moment();
      const raceDateMoment = moment(nextRace.raceDate, "DD/MM/YYYY");
      const weeksToRace = raceDateMoment.diff(today, "day");
      if (weeksToRace >= 0) {
        allRaces.push(nextRace);
      }
    }
    if (nextRaces && nextRaces.length > 0) {
      nextRaces.forEach((element) => {
        const today = moment();
        const raceDateMoment = moment(element.raceDate, "DD/MM/YYYY");
        const weeksToRace = raceDateMoment.diff(today, "day");
        if (weeksToRace >= 0) {
          allRaces.push(element);
        }
      });
    }

    allRaces.sort((a, b) => {
      let aMoment;
      if (a && a.raceDate) {
        aMoment = moment(a.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      let bMoment;
      if (b && b.raceDate) {
        bMoment = moment(b.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      return aMoment.diff(bMoment, "days");
    });

    const filteredBadges = (currentUserBadges || []).filter(
      (item) => item.finished === true,
    );

    return (
      <div>
        <Route
          path="/trailhead/profile/:id/nextRace"
          component={DashboardNextRaceDetails}
        />
        <Drawer
          open={true}
          anchor="right"
          onClose={this.handleClose}
          classes={{
            paper: classes.drawerRoot,
          }}
        >
          {!selectedAthlete ? (
            <div className={classes.loading}>
              <CircularProgress variant="indeterminate" />
            </div>
          ) : (
            <div className={classes.main}>
              <div className={classes.stickyIcon} onClick={this.goBack}>
                <div className={classes.iconParent}>
                  <ArrowForwardIosIcon
                    className={classNames(
                      classes.actionIcon,
                      classes.reverseIcon,
                    )}
                  />
                </div>
              </div>
              <ProfileHeader image={image} sub={cognito_user_sub} />
              <PersonalData
                username={cognito_user_name}
                location={currentLocation}
              />
              <Card
                rightText="All Races"
                leftText="Epic Races"
                flag="race"
                data={epicRaces}
              />
              <Card
                rightText="All Badges"
                leftText="Badges"
                data={filteredBadges}
                flag="badge"
              />
              <GroupsCard rightText="All Groupes" leftText="Groupes" />
              <PostList />
            </div>
          )}
        </Drawer>
      </div>
    );
  }
}

export default connect((store) => {
  return {
    strava: store.strava,
    garmin: store.garmin,
    suunto: store.suunto,
    userPlans: store.userPlans,
    auth: store.auth,
    trailHead: store.trailHead,
    subscriptions: store.subscriptions,
    challenges: store.challenges,
    coros: store.coros,
  };
})(
  withTranslation("profile")(
    withWidth()(withStyles(styles, { withTheme: true })(SocialProfile)),
  ),
);
