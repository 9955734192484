import React from "react";
import {
  makeStyles,
  InputAdornment,
  OutlinedInput,
  CircularProgress,
} from "@material-ui/core";
import { palette } from "../../theme/palette";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  input: {
    animation: "$fadein 0.6s linear forwards",
    backgroundColor: palette.lightGray,
    transformOrigin: "right",
    height: "40px",
    padding: "8px 4px",
    boxSizing: "border-box",
    "& > fieldset": {
      border: `solid 1px ${palette.lightGray} !important`,
    },
  },
  "@keyframes fadein": {
    "0%": {
      opacity: 0,
      transform: "scale(0.6)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)",
    },
  },
  inputOut: {
    animation: "$fadeout 0.6s linear forwards",
    backgroundColor: palette.lightGray,
    transformOrigin: "right",
    height: "40px",
    padding: "8px 4px",
    boxSizing: "border-box",
    "& > fieldset": {
      border: `solid 1px ${palette.lightGray} !important`,
    },
  },
  "@keyframes fadeout": {
    "0%": {
      opacity: 1,
      transform: "scale(1)",
    },
    "50%": {
      opacity: 0,
    },
    "100%": {
      opacity: 0,
      transform: "scale(0)",
      display: "none",
    },
  },
  staticInput: {
    display: "none",
  },
  icon: {
    width: "24px",
  },
});

const SearchInput = ({
  show = 0,
  handleInput,
  onChange,
  width = "60vw",
  value,
  loading = false,
}) => {
  const { t } = useTranslation("home");
  const classes = useStyles(width);

  const handleAnimation = (show) => {
    if (show === 0) return classes.staticInput;
    if (show === 1) return classes.input;
    return classes.inputOut;
  };

  return (
    <OutlinedInput
      placeholder={t("Search")}
      className={handleAnimation(show)}
      style={{ width }}
      variant="outlined"
      size="small"
      onChange={onChange}
      value={value}
      id="home_general_search"
      endAdornment={
        <InputAdornment position="end" onClick={handleInput}>
          {loading ? (
            <CircularProgress size="16px" />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/icon_close.svg`}
              alt="search-icon"
              width="20px"
            />
          )}
        </InputAdornment>
      }
    ></OutlinedInput>
  );
};

export default SearchInput;
