import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { palette } from "../../../theme/palette";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles(() => ({
  card: {
    background: palette.white,
    borderRadius: "8px",
    marginTop: "12px",
    width: "275px",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
}));

const CommunityCardSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box
        style={{
          padding: "12px",
          display: "flex",
          gap: "12px",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "12px",
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />
          <Box
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <Skeleton width="80%" />
            <Skeleton width="40%" />
          </Box>
        </Box>
        <Skeleton variant="rounded" width="100%" height="200px" />
        <Box
          style={{
            display: "flex",
            alignItems: "right",
            height: "100%",
            width: "100%",
            justifyContent: "right",
            flexDirection: "row",
            gap: "12px",
          }}
        >
          <Skeleton height="50px" width="40px" />
          <Skeleton height="50px" width="40px" />
        </Box>
      </Box>
    </Box>
  );
};

export default CommunityCardSkeleton;
