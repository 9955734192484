import { API } from "aws-amplify";
import TrainingsService from "./trainings-service";
import moment from "moment";
import bugsnagClient from "../lib/bugsnag-client";
import { Capacitor } from "@capacitor/core";
import { dateFormat } from "../lib/date-helper";
import { Storage } from "aws-amplify";
import {
  addEasyWeek,
  addWorkout,
  currentTrainingUpdateWorkoutEnergyLevel,
  currentTrainingUpdateWorkoutIsDone,
  moveWorkout,
  redoWeek,
} from "../lib/plan-helper";
import SmartPlansService from "./smart-plan-service";
class UserPlansService {
  static API_NAME = "apiuserplans";
  static CURRENT_USER_PLANS_PATH = "/userplans/current";
  static CUSTOMER_COACH_RATE = "/userplans/customerCoachRate";
  static CUSTOMER_PLAN_RATE = "/userplans/customerPlanRate";
  static REQUEST_COACH_CHANGE = "/userplans/requestCoachChange";
  static USER_PLANS_PATH = "/userplans/current/:planid";
  static CURRENT_USER_UPDATE_PLAN_PATH = "/userplans/:cognito_user_sub";
  static CURRENT_USER_ADD_IMAGE_PATH = "/userplans/:cognito_user_sub/addimage";
  static CURRENT_USER_DELETE_IMAGE_PATH =
    "/userplans/:cognito_user_sub/deleteimage";
  static MOVE_WORKOUTS_PATH = "/userplans/:cognito_user_sub/trainingplan/swap";
  static REDO_WEEK_PATH = "/userplans/:cognito_user_sub/trainingplan/redoweek";
  static ADD_EASY_WEEK_PATH =
    "/userplans/:cognito_user_sub/trainingplan/addeasyweek";
  static ADD_WORKOUT_PATH =
    "/userplans/:cognito_user_sub/trainingplan/addworkout";
  static CURRENT_USER_UPDATE_WORKOUT_PATH =
    "/userplans/:cognito_user_sub/trainingplan";
  static IS_DONE = "/isdone";
  static ENERGY_LEVEL = "/energylevel";
  static COMMENT = "/userplans/trainingplan/comment";
  static COMMENTS_READ = "/commentsread";
  static CURRENT_USER_MAILCHIMP_SUBSCRIBE_PATH =
    "/userplans/:cognito_user_sub/mailchimp";
  static ADD_NOTE = "/note/add";
  static DELETE_NOTE = "/note/delete";
  static EDIT_NOTE = "/note/edit";
  static GET_COMMENTS =
    "/userplans/:cognito_user_sub/trainingplan/comments/:lastDate";

  static GET_EVENTS = "/userplans/events/:from/:to";
  static POST_EVENT = "/userplanevent";

  static POST_NOTIFICATIONS = "/userplans/notifications";
  static DELETE_NOTIFICATIONS = "/userplans/notifications";

  static POST_DELETE_ACCOUNT = "/userplans/deleteuser";

  static POST_ACTIVE_CAMPAIGN_INVITE =
    "/userplans/:cognito_user_sub/activecampaigninvite";

  static EDIT_MODE = "/userplans/editmode";
  static HAS_PLAN = "/userplans/hasPlan?cognito_user_sub=:cognito_user_sub";

  static async calculateCurrentTrainingPlanEndDate(trainingPlan) {
    if (trainingPlan) {
      const { startingDate, durationInWeeks } = trainingPlan;
      if (startingDate && durationInWeeks) {
        return moment(startingDate, "DD/MM/YYYY")
          .add(durationInWeeks, "weeks")
          .add(-1, "day")
          .endOf("day")
          .unix();
      }
    }
    return undefined;
  }

  static async getLatestCurrentUserPlan() {
    try {
      const response = await API.get(
        this.API_NAME,
        this.CURRENT_USER_PLANS_PATH,
      );

      const { success, result, error, version } = response;

      let data = null;
      if (result && result.cognito_user_sub && result.currentTraining) {
        try {
          const downloadedData = await Storage.get(`latest`, {
            level: "protected",
            identityId: result.cognito_user_sub,
            download: true,
            bucket: process.env.REACT_APP_PLANS_BUCKET,
            cacheControl: "no-cache, no-store, must-revalidate",
            expires: 0,
            pragma: "no-cache",
          });
          data = await downloadedData.Body.text();
        } catch {}
      }

      const currentTraining = data ? JSON.parse(data) : null;

      let subcategory = null;
      if (
        currentTraining &&
        currentTraining.chainedPlans &&
        currentTraining.chainedPlans.length
      ) {
        for (
          let index = 0;
          index < currentTraining.chainedPlans.length;
          index++
        ) {
          const element = currentTraining.chainedPlans[index];
          if (
            moment().isSameOrAfter(
              moment(element.startingDate, dateFormat),
              "days",
            )
          ) {
            subcategory = element.subcategory;
          }
        }
      }

      if (subcategory && currentTraining) {
        currentTraining.subcategory = subcategory;
      }

      if (success)
        return {
          result: {
            ...result,
            currentTraining,
          },
          version,
        };
      else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getTrainingPlanFromId(planid) {
    try {
      if (planid) {
        const response = await API.get(
          this.API_NAME,
          this.USER_PLANS_PATH.replace(":planid", planid),
        );
        const { success, result, error } = response;
        if (success) {
          return result;
        } else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      } else throw new Error("Invalid Keys");
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async saveCurrentUserPlan(
    userPlanTempData,
    latestSubscription,
    subcategories,
    workoutNotificationsTime = null,
  ) {
    if (userPlanTempData) {
      const { selectedPlan } = userPlanTempData;
      if (selectedPlan) {
        const {
          relatedTrainingPlanInstance: relatedTrainingPlan,
          comment,
        } = await SmartPlansService.getTrainingPlan(
          userPlanTempData,
          subcategories,
        );

        if (relatedTrainingPlan) {
          const currentTrainingEndDate = await this.calculateCurrentTrainingPlanEndDate(
            relatedTrainingPlan,
          );
          userPlanTempData.currentTraining = relatedTrainingPlan;
          if (currentTrainingEndDate)
            userPlanTempData.currentTrainingEndDate = currentTrainingEndDate;
        }

        if (comment) {
          this.currentTrainingAddComment({
            cognito_user_sub: userPlanTempData.cognito_user_sub,
            comment: {
              text: comment.text,
              date: moment().format(dateFormat),
              dateTime: moment().unix(),
              type: 0,
              isReadByCoach: true,
              senderImage: "vertrun-logo-wide.png",
              senderName: "Team Vert.run",
              cognito_user_sub: userPlanTempData.cognito_user_sub,
              isPlanMessage: true,
              payload: {
                ...comment.payload,
              },
            },
            lastModified: moment().unix(),
          });
        }
        await this.saveCurrentTrainingS3(
          relatedTrainingPlan,
          userPlanTempData.cognito_user_sub,
        );
      }

      delete userPlanTempData.currentTraining;

      const myInit = {
        body: userPlanTempData,
      };
      const response = await API.post(
        this.API_NAME,
        this.CURRENT_USER_PLANS_PATH,
        myInit,
      );

      const { success, result, error } = response;

      const downloadedData = await Storage.get(`latest`, {
        level: "protected",
        identityId: result.cognito_user_sub,
        download: true,
        bucket: process.env.REACT_APP_PLANS_BUCKET,
        cacheControl: "no-cache, no-store, must-revalidate",
        expires: 0,
        pragma: "no-cache",
      });

      const data = await downloadedData.Body.text();

      const currentTrainingNew = JSON.parse(data);

      if (success)
        return {
          ...result,
          currentTraining: currentTrainingNew,
        };
      else if (error) throw new Error(error);
    } else throw new Error("INVALID INPUT");
  }

  static async saveCurrentUserPlanCoachChoose(userPlanTempData) {
    if (userPlanTempData) {
      delete userPlanTempData.currentTraining;

      const myInit = {
        body: userPlanTempData,
      };
      const response = await API.post(
        this.API_NAME,
        this.CURRENT_USER_PLANS_PATH,
        myInit,
      );

      const { success, result, error } = response;

      let currentTrainingNew = null;
      try {
        const downloadedData = await Storage.get(`latest`, {
          level: "protected",
          identityId: result.cognito_user_sub,
          download: true,
          bucket: process.env.REACT_APP_PLANS_BUCKET,
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });

        const data = await downloadedData.Body.text();

        currentTrainingNew = JSON.parse(data);
      } catch {}

      if (success)
        return {
          ...result,
          currentTraining: currentTrainingNew,
        };
      else if (error) throw new Error(error);
    } else throw new Error("INVALID INPUT");
  }

  static async autoAssignTrainingPlan(userPlanData) {
    if (userPlanData) {
      const { cognito_user_sub } = userPlanData;
      if (cognito_user_sub) {
        const {
          relatedTrainingPlanInstance: relatedTrainingPlan,
        } = await SmartPlansService.getTrainingPlan(userPlanData);
        if (relatedTrainingPlan) {
          const currentTrainingEndDate = await this.calculateCurrentTrainingPlanEndDate(
            relatedTrainingPlan,
          );

          const myInit = {
            body: {
              modifications: [
                { name: "Restart plan", date: new Date().getTime() },
              ],
            },
          };
          if (currentTrainingEndDate)
            myInit.body.currentTrainingEndDate = currentTrainingEndDate;

          const response = await API.patch(
            this.API_NAME,
            this.CURRENT_USER_UPDATE_PLAN_PATH.replace(
              ":cognito_user_sub",
              cognito_user_sub,
            ),
            myInit,
          );

          await this.saveCurrentTrainingS3(
            relatedTrainingPlan,
            cognito_user_sub,
          );

          const { success, result, error } = response;

          const downloadedData = await Storage.get(`latest`, {
            level: "protected",
            identityId: result.cognito_user_sub,
            download: true,
            bucket: process.env.REACT_APP_PLANS_BUCKET,
            cacheControl: "no-cache, no-store, must-revalidate",
            expires: 0,
            pragma: "no-cache",
          });

          const data = await downloadedData.Body.text();

          if (success)
            return {
              ...result,
              currentTraining: JSON.parse(data),
            };
          else if (error) throw new Error(error);
        }
      } else {
        throw new Error("Invalid Keys");
      }
    } else throw new Error("INVALID INPUT");
  }

  static async updateCurrentUserInfo(userPlanData, download = true) {
    if (userPlanData) {
      const { cognito_user_sub } = userPlanData;
      if (cognito_user_sub) {
        const myInit = {
          body: userPlanData,
        };
        const response = await API.patch(
          this.API_NAME,
          this.CURRENT_USER_UPDATE_PLAN_PATH.replace(
            ":cognito_user_sub",
            cognito_user_sub,
          ),
          myInit,
        );
        const { success, result, error } = response;

        let data = null;
        if (
          result &&
          result.cognito_user_sub &&
          result.currentTraining &&
          download
        ) {
          try {
            const downloadedData = await Storage.get(`latest`, {
              level: "protected",
              identityId: result.cognito_user_sub,
              download: true,
              bucket: process.env.REACT_APP_PLANS_BUCKET,
              cacheControl: "no-cache, no-store, must-revalidate",
              expires: 0,
              pragma: "no-cache",
            });
            data = await downloadedData.Body.text();

            result.currentTraining = data ? JSON.parse(data) : null;
          } catch {}
        }

        if (success) return result;
        else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      } else {
        throw new Error("Invalid Keys");
      }
    } else throw new Error("INVALID INPUT");
  }

  static async addImage(imgObj) {
    if (imgObj) {
      const { cognito_user_sub } = imgObj;
      if (cognito_user_sub) {
        const myInit = {
          body: imgObj,
        };
        const response = await API.patch(
          this.API_NAME,
          this.CURRENT_USER_ADD_IMAGE_PATH.replace(
            ":cognito_user_sub",
            cognito_user_sub,
          ),
          myInit,
        );
        const { success, result, error } = response;
        if (success) return result;
        else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      } else {
        throw new Error("Invalid Keys");
      }
    } else throw new Error("INVALID INPUT");
  }

  static async deleteImage(imgObj) {
    if (imgObj) {
      const { cognito_user_sub } = imgObj;
      if (cognito_user_sub) {
        const myInit = {
          body: imgObj,
        };
        const response = await API.patch(
          this.API_NAME,
          this.CURRENT_USER_DELETE_IMAGE_PATH.replace(
            ":cognito_user_sub",
            cognito_user_sub,
          ),
          myInit,
        );
        const { success, result, error } = response;
        if (success) return result;
        else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      } else {
        throw new Error("Invalid Keys");
      }
    } else throw new Error("INVALID INPUT");
  }

  static async checkIfModifiedByCoach(currentTraining, cognito_user_sub) {
    if (cognito_user_sub) {
      const downloadedData = await Storage.get(`latest`, {
        level: "protected",
        identityId: cognito_user_sub,
        download: true,
        bucket: process.env.REACT_APP_PLANS_BUCKET,
        cacheControl: "no-cache, no-store, must-revalidate",
        expires: 0,
        pragma: "no-cache",
      });

      const data = await downloadedData.Body.text();

      const currentTrainingS3 = JSON.parse(data);

      if (
        currentTrainingS3.lastModified &&
        (currentTrainingS3.lastModified > currentTraining.lastModified ||
          !currentTraining.lastModified)
      ) {
        return currentTrainingS3;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  static async saveCurrentTrainingS3(currentTraining, cognito_user_sub) {
    //delete currentTraining.currentStartDate;
    await Storage.put(`latest`, JSON.stringify(currentTraining), {
      level: "protected",
      identityId: cognito_user_sub,
      acl: "authenticated-read",
      bucket: process.env.REACT_APP_PLANS_BUCKET,
    });
  }

  static async swapWorkouts(workoutsToMoveData, currentTraining, planId = 0) {
    if (workoutsToMoveData) {
      const fromS3 = await this.checkIfModifiedByCoach(
        currentTraining,
        workoutsToMoveData.cognito_user_sub,
      );
      if (fromS3) {
        currentTraining = fromS3;
      }

      const currentTrainingChanges = moveWorkout(
        currentTraining,
        workoutsToMoveData,
      );

      await this.saveCurrentTrainingS3(
        currentTrainingChanges.currentTraining,
        workoutsToMoveData.cognito_user_sub,
      );

      this.updateCurrentUserInfo({
        cognito_user_sub: workoutsToMoveData.cognito_user_sub,
        modifications: [{ name: "Swap workouts", date: new Date().getTime() }],
      });

      return currentTrainingChanges;
    } else throw new Error("INVALID INPUT");
  }

  static async redoWeek(data, currentTraining, planId = 0) {
    if (data) {
      const fromS3 = await this.checkIfModifiedByCoach(
        currentTraining,
        data.cognito_user_sub,
      );
      if (fromS3) {
        currentTraining = fromS3;
      }

      const currentTrainingChanges = redoWeek(currentTraining, data);

      await this.saveCurrentTrainingS3(
        currentTrainingChanges.currentTraining,
        data.cognito_user_sub,
      );

      this.updateCurrentUserInfo({
        currentTrainingEndDate: currentTrainingChanges.currentTrainingEndDate,
        cognito_user_sub: data.cognito_user_sub,
        modifications: [{ name: "Redo week", date: new Date().getTime() }],
      });

      return currentTrainingChanges;
    } else throw new Error("INVALID INPUT");
  }

  static async addEasyWeek(
    data,
    currentTraining,
    raceCount,
    trainPerWeek,
    planId = 0,
  ) {
    if (data && raceCount && trainPerWeek) {
      const { cognito_user_sub } = data;

      const fromS3 = await this.checkIfModifiedByCoach(
        currentTraining,
        cognito_user_sub,
      );
      if (fromS3) {
        currentTraining = fromS3;
      }

      if (cognito_user_sub) {
        const trainingPlanId = `free-12-Flat-${raceCount}-${trainPerWeek}-9999999999`;

        try {
          const searchResult = await TrainingsService.readTraining({
            id: trainingPlanId,
            training_order: 1,
          });
          if (searchResult) {
            const currentTrainingChanges = addEasyWeek(currentTraining, {
              ...data,
              days: searchResult.days,
              plan: planId,
            });

            await this.saveCurrentTrainingS3(
              currentTrainingChanges.currentTraining,
              data.cognito_user_sub,
            );

            this.updateCurrentUserInfo({
              currentTrainingEndDate:
                currentTrainingChanges.currentTrainingEndDate,
              cognito_user_sub: data.cognito_user_sub,
              modifications: [
                { name: "Add easy week", date: new Date().getTime() },
              ],
            });

            return currentTrainingChanges;
          } else {
            throw new Error("Easy Week not found");
          }
        } catch (e) {
          return undefined;
        }
      } else {
        throw new Error("Invalid Keys");
      }
    } else throw new Error("INVALID INPUT");
  }

  static async addWorkout(data, currentTraining, planId = 0) {
    if (data && data.workout) {
      const fromS3 = await this.checkIfModifiedByCoach(
        currentTraining,
        data.cognito_user_sub,
      );
      if (fromS3) {
        currentTraining = fromS3;
      }

      const currentTrainingChanges = addWorkout(currentTraining, data.workout);

      await this.saveCurrentTrainingS3(
        currentTrainingChanges.currentTraining,
        data.cognito_user_sub,
      );

      this.updateCurrentUserInfo({
        cognito_user_sub: data.cognito_user_sub,
        modifications: [{ name: "Add workout", date: new Date().getTime() }],
      });

      return currentTrainingChanges;
    } else throw new Error("INVALID INPUT");
  }

  static async currentTrainingUpdateWorkoutIsDone(
    workoutData,
    currentTraining,
    planId = 0,
  ) {
    if (workoutData) {
      const fromS3 = await this.checkIfModifiedByCoach(
        currentTraining,
        workoutData.cognito_user_sub,
      );
      if (fromS3) {
        currentTraining = fromS3;
      }

      const currentTrainingChanges = currentTrainingUpdateWorkoutIsDone(
        currentTraining,
        workoutData,
      );

      await this.saveCurrentTrainingS3(
        currentTrainingChanges.currentTraining,
        workoutData.cognito_user_sub,
      );

      return currentTrainingChanges;
    } else throw new Error("INVALID INPUT");
  }

  static async currentTrainingUpdateWorkoutEnergyLevel(
    workoutData,
    currentTraining,
    planId = 0,
  ) {
    if (workoutData) {
      const fromS3 = await this.checkIfModifiedByCoach(
        currentTraining,
        workoutData.cognito_user_sub,
      );
      if (fromS3) {
        currentTraining = fromS3;
      }

      const currentTrainingChanges = currentTrainingUpdateWorkoutEnergyLevel(
        currentTraining,
        workoutData,
      );

      await this.saveCurrentTrainingS3(
        currentTrainingChanges.currentTraining,
        workoutData.cognito_user_sub,
      );

      return currentTrainingChanges;
    } else throw new Error("INVALID INPUT");
  }

  static async currentTrainingUpdateWorkoutStatusAndEnergy(
    workoutData,
    currentTraining,
  ) {
    if (workoutData) {
      const fromS3 = await this.checkIfModifiedByCoach(
        currentTraining,
        workoutData.cognito_user_sub,
      );
      if (fromS3) {
        currentTraining = fromS3;
      }

      const currentTrainingChangesDone = currentTrainingUpdateWorkoutIsDone(
        currentTraining,
        workoutData,
      );
      const currentTrainingChangesEnergy = currentTrainingUpdateWorkoutEnergyLevel(
        currentTrainingChangesDone.currentTraining,
        workoutData,
      );

      await this.saveCurrentTrainingS3(
        currentTrainingChangesEnergy.currentTraining,
        workoutData.cognito_user_sub,
      );

      return currentTrainingChangesEnergy;
    } else {
      throw new Error("INVALID INPUT");
    }
  }

  static async getComments(lastDate, cognito_user_sub) {
    try {
      const response = await API.get(
        this.API_NAME,
        this.GET_COMMENTS.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ).replace(":lastDate", lastDate),
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getEvents(from, to) {
    try {
      const response = await API.get(
        this.API_NAME,
        this.GET_EVENTS.replace(":from", from).replace(":to", to),
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async sendEvent(event) {
    if (event) {
      const myInit = {
        body: event,
      };
      const response = await API.post(this.API_NAME, this.POST_EVENT, myInit);
      const { success, result, error } = response;
      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("INVALID INPUT");
  }

  static async scheduleNotifications(notifications, cognito_user_sub) {
    if (notifications && cognito_user_sub) {
      const myInit = {
        body: { notifications, cognito_user_sub },
      };
      const response = await API.post(
        this.API_NAME,
        this.POST_NOTIFICATIONS,
        myInit,
      );
      const { success, result, error } = response;
      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("INVALID INPUT");
  }

  static async cancelNotifications(cognito_user_sub) {
    if (cognito_user_sub) {
      const myInit = {
        body: { cognito_user_sub },
      };
      const response = await API.del(
        this.API_NAME,
        this.DELETE_NOTIFICATIONS,
        myInit,
      );
      const { success, result, error } = response;
      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("INVALID INPUT");
  }

  static async deleteAccount(cognito_user_email) {
    if (cognito_user_email) {
      const myInit = {
        body: { email: cognito_user_email },
      };
      const response = await API.post(
        this.API_NAME,
        this.POST_DELETE_ACCOUNT,
        myInit,
      );
      const { success, result, error } = response;
      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("INVALID INPUT");
  }

  static async currentTrainingAddComment(chatData) {
    if (chatData) {
      const { cognito_user_sub, comment } = chatData;
      if (cognito_user_sub && typeof comment !== undefined) {
        const myInit = {
          body: comment,
        };
        const response = await API.post(this.API_NAME, this.COMMENT, myInit);
        const { success, result, error } = response;
        if (success) return result;
        else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      } else {
        throw new Error("Invalid Keys");
      }
    } else throw new Error("INVALID INPUT");
  }

  static async currentTrainingAddCommentsRead(cognito_user_sub) {
    const response = await API.patch(
      this.API_NAME,
      `${this.CURRENT_USER_UPDATE_WORKOUT_PATH}${this.COMMENTS_READ}`.replace(
        ":cognito_user_sub",
        cognito_user_sub,
      ),
    );
    const { success, result, error } = response;
    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async userPlanMailchimpSubscribe(plan) {
    if (plan) {
      const { cognito_user_sub } = plan;
      if (cognito_user_sub) {
        const myInit = {
          body: { ...plan, device: Capacitor.getPlatform() },
        };
        const response = await API.patch(
          this.API_NAME,
          this.CURRENT_USER_MAILCHIMP_SUBSCRIBE_PATH.replace(
            ":cognito_user_sub",
            cognito_user_sub,
          ),
          myInit,
        );
        const { success, result, error } = response;

        let data = null;
        if (result && cognito_user_sub && result.currentTraining) {
          try {
            const downloadedData = await Storage.get(`latest`, {
              level: "protected",
              identityId: cognito_user_sub,
              download: true,
              bucket: process.env.REACT_APP_PLANS_BUCKET,
              cacheControl: "no-cache, no-store, must-revalidate",
              expires: 0,
              pragma: "no-cache",
            });
            data = await downloadedData.Body.text();

            result.currentTraining = data ? JSON.parse(data) : null;
          } catch {}
        }

        if (success) return result;
        else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      } else {
        throw new Error("Invalid Keys");
      }
    } else throw new Error("INVALID INPUT");
  }

  static async addNote(note, cognito_user_sub) {
    if (note && cognito_user_sub) {
      const myInit = {
        body: { note },
      };
      const response = await API.patch(
        this.API_NAME,
        `${this.CURRENT_USER_UPDATE_PLAN_PATH}${this.ADD_NOTE}`.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
        myInit,
      );
      const { success, result, error } = response;
      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("INVALID INPUT");
  }

  static async deleteNote(note, cognito_user_sub) {
    if (note && cognito_user_sub) {
      const myInit = {
        body: { note },
      };
      const response = await API.patch(
        this.API_NAME,
        `${this.CURRENT_USER_UPDATE_PLAN_PATH}${this.DELETE_NOTE}`.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
        myInit,
      );
      const { success, result, error } = response;
      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("INVALID INPUT");
  }

  static async editNote(note, cognito_user_sub) {
    if (note && cognito_user_sub) {
      const myInit = {
        body: { note },
      };
      const response = await API.patch(
        this.API_NAME,
        `${this.CURRENT_USER_UPDATE_PLAN_PATH}${this.EDIT_NOTE}`.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
        myInit,
      );
      const { success, result, error } = response;
      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("INVALID INPUT");
  }

  static async activeCampaignInvited(cognito_user_sub, data) {
    const myInit = {
      body: data,
    };
    const response = await API.post(
      this.API_NAME,
      `${this.POST_ACTIVE_CAMPAIGN_INVITE}`.replace(
        ":cognito_user_sub",
        cognito_user_sub,
      ),
      myInit,
    );
    const { success, result, error } = response;
    if (success) return result;
    if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async editingPlan(cognito_user_sub, status) {
    const myInit = {
      body: {
        cognito_user_sub,
        edit_mode: status,
      },
    };
    try {
      const response = await API.post(this.API_NAME, this.EDIT_MODE, myInit);
      const { success, result, error } = response;
      if (success) return result;
      if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } catch (error) {
      throw error;
    }
  }

  static async custumerCoachRate(cognito_user_sub, rate, message) {
    const myInit = {
      body: {
        cognito_user_sub,
        rate,
        message,
      },
    };

    try {
      const response = await API.patch(
        this.API_NAME,
        this.CUSTOMER_COACH_RATE,
        myInit,
      );
      const { success, result, error } = response;
      if (success) return result;
      if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } catch (error) {
      throw error;
    }
  }
  static async custumerPlanRate(cognito_user_sub, rate, message) {
    const myInit = {
      body: {
        cognito_user_sub,
        rate,
        message,
      },
    };
    try {
      const response = await API.patch(
        this.API_NAME,
        this.CUSTOMER_PLAN_RATE,
        myInit,
      );
      const { success, result, error } = response;
      if (success) return result;
      if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } catch (error) {
      throw error;
    }
  }
  static async requestCoachChange(email, message) {
    const myInit = {
      body: {
        email,
        message,
      },
    };
    try {
      const response = await API.patch(
        this.API_NAME,
        this.REQUEST_COACH_CHANGE,
        myInit,
      );
      const { success, result, error } = response;
      if (success) return result;
      if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } catch (error) {
      throw error;
    }
  }
  static async hasPlan(cognito_user_sub) {
    try {
      const response = await API.get(
        this.API_NAME,
        `${this.HAS_PLAN}`.replace(":cognito_user_sub", cognito_user_sub),
      );
      if (response) return response;
      throw new Error("HasPlan response error");
    } catch (exp) {
      throw exp;
    }
  }
}

export default UserPlansService;
