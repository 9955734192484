import React, { Component } from "react";
import {
  Dialog,
  Grid,
  DialogContent,
  withMobileDialog,
  Typography,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { Link as RouterLink } from "react-router-dom";

const styles = (theme) => ({
  dialog: {
    padding: "0 !important",
  },
  container: {
    padding: "0 !important",
    background: "#F9F9FB",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflow: "scroll",
    },
  },
  rightButtons: {
    zIndex: 100,
    position: "absolute",
    right: 5,
    top: 5,
    cursor: "pointer",
  },
  coverImg: {
    "object-fit": "cover",
    height: 250,
    width: "100%",
    "-webkit-mask-image":
      "-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
  },
  athleteImgContainer: {
    padding: 10,
    marginTop: "-100px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  athleteImg: {
    height: 215,
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      height: 180,
    },
  },
  topText: {
    fontSize: 16,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  topTextTitle: {
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
    },
  },
  topTextSmall: {
    fontSize: 14,
    textAlign: "center",
  },
  startPlanSection: {
    textAlign: "center",
    width: "100%",
    marginTop: -40,
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
    },
  },
  submitButton: {
    borderRadius: 26,
    color: "#fff",
    padding: "8px 80px",
    fontSize: "0.9375rem",
    border: 0,
    fontFamily: "Avenir",
    fontWeight: "bold",
    textTransform: "uppercase",
    margin: "10px 0",
  },
  link: {
    textDecoration: "none",
  },
  description: {
    fontSize: 16,
    margin: "12px 54px 32px",
    [theme.breakpoints.down("md")]: {
      textAlign: "justify",
      margin: "12px 10px 32px",
    },
  },
  athleteName: {
    fontSize: 21,
    margin: 0,
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      marginLeft: 10,
    },
  },
  athleteAchievement: {
    fontSize: 17,
    margin: "8px 54px 2px",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      margin: "8px 10px 2px",
    },
  },
  athleteBio: {
    fontSize: 15,
    margin: "0 54px 22px",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      margin: "0 10px 22px",
    },
  },
  divider: {
    border: "1px solid #DADADA",
  },
});

class PlanDetails extends Component {
  render() {
    const { classes, fullScreen, t, i18n, isOpen, plan, onClose } = this.props;
    const {
      cover_picture,
      athlete_picture,
      good_for,
      workout_tone,
      weeks,
      max_distance,
      runner_level,
      plan: type,
      id,
      description,
      athlete,
      athlete_achievement,
      athlete_bio,
      training_load,
    } = plan || {};
    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    return (
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={onClose}
        aria-labelledby="Plan"
        scroll="body"
        maxWidth="md"
        classes={{ container: classes.dialog }}
      >
        <DialogContent className={classes.container}>
          <p>sadasdsad</p>
          <div>
            <img className={classes.coverImg} src={cover_picture} alt="" />
          </div>
          <div className={classes.rightButtons}>
            <img
              src={`${process.env.PUBLIC_URL}/close.svg`}
              alt=""
              onClick={onClose}
            />
          </div>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <div className={classes.athleteImgContainer}>
                <img
                  className={classes.athleteImg}
                  src={athlete_picture}
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Typography variant="h6" className={classes.topText}>
                    <b>{t("Good for")}: </b>
                    {good_for && good_for[languageDetector]}
                  </Typography>
                  <Typography variant="h6" className={classes.topText}>
                    <b>{t("Workout tone")}: </b>
                    {workout_tone && workout_tone[languageDetector]}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Grid container justify="center" spacing={40}>
                    <Grid item className={classes.raceBottomItem}>
                      <Typography variant="h6" className={classes.topTextTitle}>
                        {weeks}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.topTextSmall}
                      >
                        {t("Weeks")}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.raceBottomItem}>
                      <Typography variant="h6" className={classes.topTextTitle}>
                        {max_distance}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.topTextSmall}
                      >
                        {t("Up to")}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.raceBottomItem}>
                      <Typography variant="h6" className={classes.topTextTitle}>
                        {runner_level && runner_level[languageDetector]}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.topTextSmall}
                      >
                        {t("Runner level")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.startPlanSection}>
              <RouterLink
                to={`/chooseplan/${type}/${id}`}
                className={classes.link}
              >
                <Button
                  variant="contained"
                  size="large"
                  className={classes.submitButton}
                  color="primary"
                  to={`/chooseplan/${type}/${id}`}
                >
                  {t("START THIS PLAN")}
                </Button>
              </RouterLink>
              <Typography variant="body1" className={classes.description}>
                {description && description[languageDetector]}
              </Typography>
              <hr className={classes.divider}></hr>
              <Typography variant="h6" className={classes.athleteName}>
                {athlete}
              </Typography>
              <Typography variant="h6" className={classes.athleteAchievement}>
                {athlete_achievement && athlete_achievement[languageDetector]}
              </Typography>
              <Typography variant="body1" className={classes.athleteBio}>
                {athlete_bio && athlete_bio[languageDetector]}
              </Typography>
            </div>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

PlanDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  fullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  plan: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation(["plan"])(withWidth()(withMobileDialog()(PlanDetails))),
  ),
);
