import React, { useEffect, useState, memo, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Drawer, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import RaceCard from "./components/race-card";
import history from "../../../../lib/history-helper";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import SmallAvatar from "../../../generals/small-avatar";
import { useParams } from "react-router-dom";
import { getAthlete, newPost } from "../../../../actions/trail-head-action";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddRaceForm from "./components/add-race-form";

const styles = (theme) => ({
  drawerRoot: {
    backgroundColor: "#F9F9FB",
    maxHeight: "100%",
    height: "100%",
    maxWidth: "500px",
    minWidth: "500px",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "100vw",
      minWidth: "100vw",
    },
  },
  header: {
    display: "flex",
    backgroundColor: "#FFF",
    padding: "10px 16px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subtitle: {
    textAlign: "center",
    color: "#E16327",
    marginTop: "8px",
    cursor: "pointer",
  },
  bold: {
    fontWeight: "bold",
  },
  avatar: {
    width: "44px",
    height: "44px",
  },
  content: {
    padding: "16px 0",
  },
  noRacesParent: {
    display: "flex",
    margin: "16px",
    justifyContent: "center",
    alignItems: "center",
  },
  levelBadge: {
    width: 25,
    right: "-10px",
    bottom: "-5px",
    height: "25px",
    position: "absolute",
  },
  loading: {
    textAlign: "center",
    marginTop: 10,
  },
  goBack: {
    cursor: "pointer",
  },
});

const AllRaces = ({ classes, t }) => {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const currentRace = useRef("");
  const currentIndex = useRef(null);

  const {
    auth: { currentUser },
    trailHead,
  } = useSelector((state) => state);

  const { attributes } = currentUser || {};
  const { selectedAthlete, loadingAthlete, currentPublicGroup } = trailHead;
  const { cognito_user_sub, epicRaces } = selectedAthlete || {};

  const { sub } = attributes || {};

  useEffect(() => {
    setOpen(true);
    if (!selectedAthlete && !Object.keys(selectedAthlete || {}).length)
      dispatch(getAthlete(params.id));
  }, []);

  const navigateToAdd = () => {
    const { id } = params;
    history.push(`/trailhead/${id}/add-race`);
  };

  const goBack = () => history.push(`/trailhead/profile/${cognito_user_sub}`);

  const handleShare = (event, epicRace) => {
    dispatch(
      newPost(
        "",
        "",
        null,
        null,

        null,
        epicRace,
      ),
    );
    history.push(
      currentPublicGroup
        ? `/trailhead/group/${currentPublicGroup.id}`
        : `/trailhead`,
    );
  };

  const openOrClose = (data, index) => {
    currentRace.current = data;
    currentIndex.current = index;
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        onClose={goBack}
        classes={{
          paper: classes.drawerRoot,
        }}
      >
        <div className={classes.header}>
          <div style={{ position: "relative" }}>
            <SmallAvatar />
          </div>

          <div>
            <Typography variant="body1" className={classes.bold}>
              {t("Races Done").toUpperCase()}
            </Typography>

            {cognito_user_sub == sub && (
              <Typography
                variant="body2"
                className={classes.subtitle}
                onClick={navigateToAdd}
              >
                {t("Add Race Result")}
              </Typography>
            )}
          </div>
          <div onClick={goBack} className={classes.goBack}>
            <Close />
          </div>
        </div>
        <div className={classes.content}>
          {loadingAthlete ? (
            <div className={classes.loading}>
              <CircularProgress variant="indeterminate" />
            </div>
          ) : epicRaces && epicRaces.length ? (
            epicRaces.map((item, index) => (
              <RaceCard
                item={item}
                title={t("Share Race Result")}
                onShare={(event, epicRace) => handleShare(event, epicRace)}
                openOrClose={() => openOrClose(item, index)}
                key={index}
              />
            ))
          ) : (
            <div className={classes.noRacesParent}>
              <Typography> {t("No Races")} </Typography>
            </div>
          )}
        </div>
      </Drawer>
      {cognito_user_sub == sub && (
        <AddRaceForm
          isOpen={isOpen}
          openOrClose={openOrClose}
          race={currentRace.current}
          edit={true}
          currentIndex={currentIndex.current}
          customWidth
        />
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("trailhead")(memo(AllRaces)),
);
