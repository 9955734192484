import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Avatar, Grid, Typography, Select, MenuItem } from "@material-ui/core";
import moment from "moment";
import { dateFormat } from "../../lib/date-helper";
import { getSubcategoryTitle } from "../../lib/subcategory-helper";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import { Storage } from "aws-amplify";
import DashboardAddEditNextRace from "../dashboard/dashboard-training/dashboard-next-race/dashboard-add-edit-next-race";
import { patchSelectedAthleteRaces } from "../../actions/athletes-action";
import { setErrorMessage } from "../../actions/subscriptions-action";
import { connect } from "react-redux";

const styles = () => ({
  container: {
    background: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
  },
  name: {
    fontSize: 20,
    fontWeight: "bold",
  },
  email: {
    fontSize: 12,
  },
  inlineText: {
    display: "flex",
    marginTop: 7,
  },
  country: {
    color: "#878787",
    fontSize: 12,
  },
  status: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 12,
    marginLeft: 20,
  },
  lastLogin: {
    color: "#878787",
    fontSize: 12,
    marginTop: 7,
  },
  levelText: {
    fontSize: 12,
    marginTop: 7,
  },

  sideContainer: {
    marginTop: 10,
    background: "#F5F9FF",
    borderRadius: 20,
    padding: "1px 11px 11px",
  },

  energyLeveltTitle: {
    fontWeight: "bold",
    fontSize: 16,
    margin: "16px 0",
  },
  graphContainer: {
    height: 80,
  },
  racePriorityContainer: {
    display: "flex",
    alignItems: "center",
  },
  racePrioritySelected: {
    margin: "0 5px",
    background: "#FF6327",
    borderRadius: "100%",
    fontWeight: 900,
    fontSize: 21,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "34px",
    textAlign: "center",
  },
  select: {
    marginTop: "10px",
    fontSize: 14,
    fontWeight: "bold",
  },
  item: {
    fontSize: 14,
  },
  noRacesText: {
    width: "100%",
    marginTop: "10px",
  },
  selectParent: {
    display: "flex",
    width: "100%",
  },
  racesParent: {
    maxHeight: "450px",
    overflowY: "auto",
    width: "100%",
  },
  newRaceContainer: {
    display: "flex",
    padding: "11px 11px 11px",
    background: "#F5F9FF",
    marginTop: 10,
    borderRadius: 20,
    cursor: "pointer",
  },
  newRaceText: {
    fontWeight: 700,
    fontSize: 14,
    color: "#E16327",
    marginLeft: 5,
  },
});

class AthletesProfile extends Component {
  state = {
    avatarUrl: null,
    avatarLoading: false,
    allRaces: [],
    editingRace: null,
    raceToEdit: null,
  };

  async componentDidUpdate(prevProps) {
    const { athlete } = prevProps;
    const { image } = this.props;

    if (athlete !== this.props.athlete) this.buildAllRaces();

    if (image !== prevProps.image || !this.state.avatarUrl) {
      if (image) this.getAvatarUrl(image);
    }
  }

  async componentWillUnmount() {
    const { avatarUrl } = this.state;
    if (avatarUrl) this.setState({ ...this.state, avatarUrl: null });
  }

  orderRaces(races = []) {
    const orderedArray = races.sort((a, b) => {
      let aMoment;
      if (a && a.raceDate) {
        aMoment = moment(a.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      let bMoment;
      if (b && b.raceDate) {
        bMoment = moment(b.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      return aMoment.diff(bMoment, "days");
    });

    return orderedArray;
  }

  buildAllRaces() {
    const { athlete } = this.props;
    const { nextRaces } = athlete || {};

    let allRaces = [];

    if (nextRaces && nextRaces.length > 0) {
      nextRaces.forEach((element) => {
        const raceDateMoment = moment(element.raceDate, "DD/MM/YYYY");
        const current = new Date();
        const currentDate = Date.now();
        const midnight = new Date().setHours(24, 0, 0, 0);
        const difference = raceDateMoment.diff(current, "days");

        if (difference === 0) {
          if (raceDateMoment.valueOf() < midnight)
            return allRaces.push(element);
        }

        if (raceDateMoment.valueOf() > currentDate) allRaces.push(element);
      });
    }

    allRaces = this.sortRacesData(allRaces, "ascending");

    this.setState({ ...this.state, allRaces });
  }

  sortRacesData = (data = [], orderBy = "descending") => {
    const ordered = data.sort((a, b) => {
      const itemA = moment(a.raceDate, "DD/MM/YYYY").valueOf();
      const itemB = moment(b.raceDate, "DD/MM/YYYY").valueOf();
      return orderBy == "descending" ? itemB - itemA : itemA - itemB || [];
    });
    return ordered;
  };

  getAvatarUrl = async (image) => {
    const { avatarUrl } = this.state;

    if (image && !avatarUrl) {
      try {
        const result = await Storage.get(image, {
          level: "public",
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });
        this.setState({
          ...this.state,
          avatarUrl: result,
          avatarLoading: false,
        });
      } catch (exp) {
        console.log(exp);
      }
    } else if (!image) {
      this.setState({
        ...this.state,
        avatarLoading: false,
      });
    }
  };

  handleSetEditingRace = (id) => {
    this.setState({
      ...this.state,
      editingRace: id,
    });
  };

  handleSave = (race) => {
    if (
      race.raceName &&
      race.raceDistance &&
      race.raceElevation &&
      race.raceDate &&
      race.racePriority
    ) {
      const { athlete, t } = this.props;
      const { nextRaces, nextRace, cognito_user_sub } = athlete;

      if (race.raceId === "add") {
        race.raceId = new Date().getTime();
      }

      let newNextRaces = [];
      if (nextRaces) {
        newNextRaces = [
          ...newNextRaces,
          ...nextRaces.filter((r) => r.raceId !== race.raceId),
        ];
      }
      newNextRaces.push(race);
      if (nextRace && race.raceId !== "old") {
        newNextRaces.push({ ...nextRace, raceId: new Date().getTime() });
      }

      this.props.dispatch(
        patchSelectedAthleteRaces({
          cognito_user_sub: cognito_user_sub,
          nextRace: null,
          nextRaces: newNextRaces,
        }),
      );

      this.setState({
        ...this.state,
        editingRace: null,
      });
    } else {
      this.props.dispatch(
        setErrorMessage("Please fill out all your race details"),
      );
    }
  };

  handleDelete = (raceId) => {
    const { athlete } = this.props;
    const { nextRaces, nextRace, cognito_user_sub } = athlete;

    let newNextRaces = [];
    if (nextRaces) {
      newNextRaces = nextRaces.filter((r) => r.raceId !== raceId);
    }

    if (nextRace && raceId !== "old") {
      newNextRaces.push({ ...nextRace, raceId: new Date().getTime() });
    }

    this.props.dispatch(
      patchSelectedAthleteRaces({
        cognito_user_sub: cognito_user_sub,
        nextRace: null,
        nextRaces: newNextRaces,
      }),
    );

    this.setState({
      ...this.state,
      editingRace: null,
    });
  };

  render() {
    const {
      classes,
      athlete,
      isCoach,
      isAdmin,
      data,
      options,
      subcategories,
    } = this.props;

    const {
      cognito_user_name,
      cognito_user_email,
      country,
      subscription,
      lastLogin,
      trainPerWeek,
      trainHoursPerWeek,
      raceCount,
      longestRun,
      nextRace,
      nextRaces,
      currentTraining,
    } = athlete || {};

    const { status, plan_id } = subscription || {};
    const { avatarUrl, allRaces, editingRace } = this.state;

    let { selectedPlan, raceCount: planLevel } = currentTraining || {};

    if (!selectedPlan && athlete && athlete.selectedPlan) {
      selectedPlan = athlete.selectedPlan;
    }

    let planName;
    if (selectedPlan && selectedPlan !== "premium") {
      const subcategory =
        athlete &&
        athlete.currentTraining &&
        athlete.currentTraining.subcategory
          ? athlete.currentTraining.subcategory
          : +athlete.subcategory;
      planName =
        subcategories &&
        subcategories.data &&
        subcategories.data.length &&
        athlete.subcategory
          ? getSubcategoryTitle(subcategories.data, subcategory, "en")
          : selectedPlan === "free"
          ? "Free"
          : "Explore";
    }
    if (selectedPlan === "premium") {
      planName = "Premium";
    }

    const onChangeRaces = (event) => {
      const {
        target: { value },
      } = event;

      const races = (nextRaces || []).filter((item) => {
        const raceDateMoment = moment(item.raceDate, "DD/MM/YYYY");
        const current = new Date();
        const currentDate = Date.now();
        const midnight = new Date().setHours(24, 0, 0, 0);
        const difference = raceDateMoment.diff(current, "days");

        if (
          value == 2 &&
          raceDateMoment.valueOf() < currentDate &&
          difference < 0
        )
          return item;

        if (value == 1) {
          if (difference === 0) {
            if (raceDateMoment.valueOf() < midnight) return item;
          }

          if (raceDateMoment.valueOf() > currentDate) return item;
        }
      });

      const allRaces = this.sortRacesData(
        races,
        value == 2 ? "descending" : "ascending",
      );

      this.setState({ ...this.state, allRaces });
    };

    const raceToEdit = allRaces.find((r) => r.raceId === editingRace);

    return (
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="body1" className={classes.name}>
              {cognito_user_name}
            </Typography>
            <Typography variant="body1" className={classes.email}>
              {cognito_user_email}
            </Typography>
            {!isCoach && !isAdmin ? (
              <>
                <div className={classes.inlineText}>
                  <Typography variant="body1" className={classes.country}>
                    {country}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.status}
                    style={{
                      color: status
                        ? status.toLowerCase() === "active"
                          ? "green"
                          : status.toLowerCase() === "expired" ||
                            status.toLowerCase() === "cancelled"
                          ? "red"
                          : status.toLowerCase() === "non_renewing"
                          ? "blue"
                          : status.toLowerCase() === "active"
                          ? status.toLowerCase() === "in_trial"
                          : "#FFB300"
                        : "",
                    }}
                  >
                    {status}
                  </Typography>
                </div>
                <Typography variant="body1" className={classes.lastLogin}>
                  <b>Current Plan: </b>
                  {planName}
                </Typography>

                <Typography variant="body1" className={classes.lastLogin}>
                  <b>Age: </b>
                  {athlete.birthdate &&
                    Math.floor(
                      moment(new Date(), "DD/MM/YYY").diff(
                        moment(athlete.birthdate, "DD/MM/YYYY"),
                        "years",
                        true,
                      ),
                    )}
                </Typography>

                <Typography variant="body1" className={classes.lastLogin}>
                  <b>Gender: </b>
                  {athlete.sex}
                </Typography>
                {plan_id ? (
                  <Typography variant="body1" className={classes.lastLogin}>
                    <b>Subscription Plan: </b>
                    {plan_id}
                  </Typography>
                ) : (
                  ""
                )}
                {planLevel ? (
                  <Typography variant="body1" className={classes.lastLogin}>
                    <b>Plan level: </b>
                    {planLevel}
                  </Typography>
                ) : (
                  ""
                )}
                <Typography variant="body1" className={classes.lastLogin}>
                  <b>Last login: </b>
                  {lastLogin ? moment(lastLogin).format(dateFormat) : "N/A"}
                </Typography>
              </>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={2}>
            <Avatar
              src={avatarUrl || `${process.env.PUBLIC_URL}/avatar-empty.png`}
              className={classes.avatar}
            />
          </Grid>
          {!isCoach &&
          !isAdmin &&
          (trainPerWeek || raceCount || longestRun || trainHoursPerWeek) ? (
            <Grid item xs={12}>
              <div className={classes.sideContainer}>
                {trainPerWeek ? (
                  <Typography variant="body1" className={classes.levelText}>
                    <b>Days of training: </b>
                    {trainPerWeek}
                  </Typography>
                ) : (
                  ""
                )}

                {raceCount ? (
                  <Typography variant="body1" className={classes.levelText}>
                    <b>Number of races: </b>
                    {raceCount}
                  </Typography>
                ) : (
                  ""
                )}

                {longestRun ? (
                  <Typography variant="body1" className={classes.levelText}>
                    <b>Longest Race: </b>
                    {`${longestRun}km`}
                  </Typography>
                ) : (
                  ""
                )}

                {trainHoursPerWeek ? (
                  <Typography variant="body1" className={classes.levelText}>
                    <b>Avg Running Time: </b>
                    {`${trainHoursPerWeek}h`}
                  </Typography>
                ) : (
                  ""
                )}
              </div>
            </Grid>
          ) : (
            ""
          )}

          {!isCoach && !isAdmin && (
            <div
              className={classes.newRaceContainer}
              onClick={() => this.handleSetEditingRace("add")}
            >
              <img
                src={`${process.env.PUBLIC_URL}/icon-add-nr.svg`}
                alt=""
                className={classes.newRaceIcon}
              />
              <Typography variant="body2" className={classes.newRaceText}>
                Add a race to Athlete’s calendar
              </Typography>
            </div>
          )}

          {!isCoach && !isAdmin && (
            <div className={classes.selectParent}>
              <Select
                disableUnderline
                onChange={onChangeRaces}
                defaultValue={1}
                className={classes.select}
                inputProps={{
                  name: "races",
                  id: "uncontrolled-native",
                }}
              >
                <MenuItem value={1} className={classes.item}>
                  Next races
                </MenuItem>
                <MenuItem value={2} className={classes.item}>
                  Past Races
                </MenuItem>
              </Select>
            </div>
          )}

          {!isCoach && !isAdmin && allRaces && !allRaces.length ? (
            <div className={classes.noRacesText}>
              <Typography variant="body2">No Races</Typography>
            </div>
          ) : null}

          <div className={classes.racesParent}>
            {!isCoach && !isAdmin
              ? (allRaces || []).map((race) => (
                  <Grid
                    item
                    xs={12}
                    key={race.raceId}
                    onClick={() => this.handleSetEditingRace(race.raceId)}
                  >
                    <div className={classes.sideContainer}>
                      {race.raceName ? (
                        <Typography
                          variant="body1"
                          className={classes.levelText}
                        >
                          <b>Race: </b>
                          {race.raceName}
                        </Typography>
                      ) : (
                        ""
                      )}

                      {race.raceDistance ? (
                        <Typography
                          variant="body1"
                          className={classes.levelText}
                        >
                          <b>Distance: </b>
                          {`${race.raceDistance}${
                            !race.units || race.units === "kms"
                              ? "km"
                              : " miles"
                          }`}
                        </Typography>
                      ) : (
                        ""
                      )}

                      {race.raceElevation ? (
                        <Typography
                          variant="body1"
                          className={classes.levelText}
                        >
                          <b>Elevation: </b>
                          {`${race.raceElevation}${
                            !race.units || race.units === "kms" ? " m" : " feet"
                          }`}
                        </Typography>
                      ) : (
                        ""
                      )}

                      {race.raceDate ? (
                        <Typography
                          variant="body1"
                          className={classes.levelText}
                        >
                          <b>Date: </b>
                          {race.raceDate}
                        </Typography>
                      ) : (
                        ""
                      )}

                      {race.raceLocation ? (
                        <Typography
                          variant="body1"
                          className={classes.levelText}
                        >
                          <b>Location: </b>
                          {race.raceLocation}
                        </Typography>
                      ) : (
                        ""
                      )}

                      {race.racePriority ? (
                        <div className={classes.racePriorityContainer}>
                          <Typography
                            variant="h6"
                            className={classes.levelText}
                          >
                            Race priority:
                          </Typography>
                          <Typography
                            variant="h6"
                            className={classes.racePrioritySelected}
                          >
                            {race.racePriority}
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Grid>
                ))
              : ""}
          </div>
          {!isCoach && !isAdmin && currentTraining ? (
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.energyLeveltTitle}>
                All plan energy Level
              </Typography>
              <div className={classes.graphContainer}>
                <Line data={data} options={options} />
              </div>{" "}
            </Grid>
          ) : (
            ""
          )}
        </Grid>

        {editingRace ? (
          <DashboardAddEditNextRace
            isOpen={editingRace}
            onClose={() => this.handleSetEditingRace(null)}
            nextRace={raceToEdit}
            editing={editingRace}
            onEdit={this.handleSetEditingRace}
            onSave={this.handleSave}
            onDelete={this.handleDelete}
          ></DashboardAddEditNextRace>
        ) : (
          ""
        )}
      </div>
    );
  }
}

AthletesProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  athlete: PropTypes.object,
  isCoach: PropTypes.bool,
  isAdmin: PropTypes.bool,
  data: PropTypes.object,
  options: PropTypes.object,
  subcategories: PropTypes.object,
};

export default connect(() => {
  return {};
})(withStyles(styles)(AthletesProfile));
