import ActionTypes from "../constants/action-types";
import TrainingFields from "../constants/training-fields";
import Plans from "../constants/plans";

const initialState = {
  loading: false,
  latestFilterApplied: { searchPhrase: "", justMine: false },
  currentFilter: { searchPhrase: "", justMine: false },
  hasBeenSearched: false,
  searchResult: {
    data: [],
    order: "asc",
    orderBy: TrainingFields.titleLower,
    selected: [],
    rowsPerPage: 10,
    page: 0,
  },
  currentWeek: 0,
  athletesLoading: false,
  athletesList: [],
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.TRAINING_SEARCH_PENDING:
        return {
          ...state,
          loading: true,
          errorMessage: undefined,
          searchResult: {
            ...state.searchResult,
            data: [],
            selected: [],
            page: 0,
          },
        };
      case ActionTypes.TRAINING_SEARCH_REJECTED:
        return {
          ...state,
          loading: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.TRAINING_SEARCH_FULFILLED:
        return {
          ...state,
          loading: false,
          searchResult: {
            ...state.searchResult,
            data: action.payload || [],
          },
          latestFilterApplied: { ...state.currentFilter },
          hasBeenSearched: true,
        };
      case ActionTypes.TRAINING_CLEAN_SEARCH:
        return { ...initialState };
      case ActionTypes.TRAINING_CLEAN_SWAP:
        return {
          ...state,
          searchResult: {
            ...state.searchResult,
            swappable: undefined,
            swapIdRoute: undefined,
          },
        };
      case ActionTypes.TRAINING_SEARCH_PHRASE_CHANGED:
        return {
          ...state,
          currentFilter: {
            ...state.currentFilter,
            searchPhrase: action.payload || "",
          },
        };
      case ActionTypes.TRAINING_SEARCH_FILTER_CHANGED:
        const { name, values } = action.payload;
        let currentFilter = {
          ...state.currentFilter,
          [name]: values,
        };

        if (
          name === "selectedPlan" &&
          (!values ||
            (values && !values.find((value) => value === Plans.Items.EXPLORE)))
        ) {
          if (currentFilter.subcategory) delete currentFilter.subcategory;
        }

        return {
          ...state,
          currentFilter,
        };
      case ActionTypes.TRAINING_SEARCH_PARAM_CHANGED:
        return {
          ...state,
          searchResult: {
            ...state.searchResult,
            ...action.payload,
          },
        };
      case ActionTypes.TRAINING_WEEK_CHANGED:
        return {
          ...state,
          currentWeek: action.payload,
        };
      case ActionTypes.TRAINING_SELECT_WORKOUT:
        return {
          ...state,
          selectedWorkout: action.payload,
        };
      case ActionTypes.TRAINING_INLINE_EDIT_SELECT:
        return {
          ...state,
          inlineEditCellId: action.payload,
        };
      case ActionTypes.TRAINING_ADD_WORKOUT:
        const newDays = [...state.currentTraining.days];
        newDays.splice(action.payload, 1, [
          // ...(state.currentTraining.days[action.payload] || []),
          { ...state.selectedWorkout, trainingTime: "AllDay" },
        ]);
        return {
          ...state,
          currentTraining: {
            ...state.currentTraining,
            days: newDays,
          },
          selectedWorkout: undefined,
        };
      case ActionTypes.TRAINING_READ_PENDING:
        return {
          ...state,
          reading: true,
          errorMessage: undefined,
        };
      case ActionTypes.TRAINING_READ:
        return {
          ...state,
          currentTraining: action.payload,
        };
      case ActionTypes.TRAINING_READ_FULFILLED:
        if (action.payload) {
          const { durationInWeeks } = action.payload;
          let days = [];
          for (let i = 1; i <= durationInWeeks * 7; i++) {
            days.push(undefined);
          }
          return {
            ...state,
            reading: false,
            currentTraining: action.payload
              ? action.payload
              : {
                  ...state.searchFields,
                  days: days,
                },
          };
        }
        return {
          ...state,
          reading: false,
          currentTraining: undefined,
        };
      case ActionTypes.TRAINING_READ_REJECTED:
        return {
          ...state,
          reading: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.TRAINING_REMOVE_WORKOUT:
        const { currentTraining } = state;
        let daysAfterRemoving;
        if (currentTraining) {
          const { days } = currentTraining;
          daysAfterRemoving = [...days];
          const foundIndex = daysAfterRemoving[action.payload.index]
            ? daysAfterRemoving[action.payload.index].findIndex(
                (item) => item.id === action.payload.workoutId,
              )
            : -1;

          if (foundIndex > -1) {
            const newDayTrainings = [
              ...daysAfterRemoving[action.payload.index],
            ];
            newDayTrainings.splice(foundIndex, 1);
            daysAfterRemoving[action.payload.index] = newDayTrainings;
          }

          if (
            daysAfterRemoving[action.payload.index] &&
            Object.keys(daysAfterRemoving[action.payload.index]).length === 0
          )
            daysAfterRemoving[action.payload.index] = undefined;
        }
        return {
          ...state,
          currentTraining: {
            ...state.currentTraining,
            days: daysAfterRemoving,
          },
        };
      case ActionTypes.TRAINING_SAVE_PENDING:
        return {
          ...state,
          saving: true,
          errorMessage: undefined,
          inlineSavingId: action.payload,
        };
      case ActionTypes.TRAINING_SAVE_FULFILLED:
        //For update & create
        const foundItemIndex = state.searchResult.data.findIndex(
          (item) =>
            item.id === action.payload.id &&
            item.training_order === action.payload.training_order,
        );
        const newData =
          foundItemIndex > -1
            ? [...state.searchResult.data]
            : [...state.searchResult.data, action.payload];
        if (foundItemIndex > -1) {
          newData.splice(foundItemIndex, 1, action.payload);
        }
        return {
          ...state,
          saving: false,
          inlineSavingId: undefined,
          currentWeek: 0,
          searchResult: {
            ...state.searchResult,
            data: newData,
          },
          successMessage: "Training was saved successfully",
        };
      case ActionTypes.TRAINING_SAVE_REJECTED:
        const data =
          action.payload &&
          action.payload.response &&
          action.payload.response.data;
        return {
          ...state,
          saving: false,
          inlineSavingId: undefined,
          errorMessage:
            data ||
            (action.payload && action.payload.message) ||
            "SERVER ERROR",
        };
      case ActionTypes.TRAINING_SWAP_PENDING:
        return {
          ...state,
          saving: true,
          errorMessage: undefined,
        };
      case ActionTypes.TRAINING_SWAP_FULFILLED:
        if (action.payload) {
          const { id, training_orders } = action.payload;

          if (
            state.searchResult.data &&
            id &&
            training_orders &&
            training_orders.length === 2
          ) {
            const firstItemIndex = state.searchResult.data.findIndex(
              (item) =>
                item.id === id &&
                item.training_order === parseInt(training_orders[0]),
            );
            if (firstItemIndex >= 0) {
              const secondItemIndex = state.searchResult.data.findIndex(
                (item) =>
                  item.id === id &&
                  item.training_order === parseInt(training_orders[1]),
              );
              if (secondItemIndex >= 0) {
                const {
                  training_order: firstItemTrainingOrder,
                } = state.searchResult.data[firstItemIndex];
                const {
                  training_order: secondItemTrainingOrder,
                } = state.searchResult.data[secondItemIndex];

                const newDataSwap = [...state.searchResult.data];
                newDataSwap.splice(firstItemIndex, 1, {
                  ...newDataSwap[firstItemIndex],
                  training_order: secondItemTrainingOrder,
                });
                newDataSwap.splice(secondItemIndex, 1, {
                  ...newDataSwap[secondItemIndex],
                  training_order: firstItemTrainingOrder,
                });

                return {
                  ...state,
                  saving: false,
                  currentWeek: 0,
                  searchResult: {
                    ...state.searchResult,
                    data: newDataSwap,
                  },
                  successMessage: "Training Plans Swapped Successfully",
                };
              }
            }
          }
        }
        return {
          ...state,
          saving: false,
          currentWeek: 0,
          successMessage: "Training Plans Swapped Successfully",
        };
      case ActionTypes.TRAINING_SWAP_REJECTED:
        const dataSwap =
          action.payload &&
          action.payload.response &&
          action.payload.response.data;
        return {
          ...state,
          saving: false,
          errorMessage:
            dataSwap ||
            (action.payload && action.payload.message) ||
            "SERVER ERROR",
        };
      case ActionTypes.TRAINING_CHANGE_FIELD:
        const { name: fieldName, value } = action.payload;
        let newCurrentTraining = {
          ...state.currentTraining,
          [fieldName]: value,
        };
        if (fieldName === "selectedPlan") {
          if (
            value === Plans.Items.FREE &&
            newCurrentTraining["skillToImprove"]
          )
            delete newCurrentTraining["skillToImprove"];
          else if (
            value === Plans.Items.PREMIUM &&
            newCurrentTraining["distance"]
          )
            delete newCurrentTraining["distance"];
          else if (
            value !== Plans.Items.PREMIUM &&
            newCurrentTraining["athlete_cognito_user_sub"]
          ) {
            delete newCurrentTraining["athlete_cognito_user_sub"];
            delete newCurrentTraining["athlete_cognito_user_full_name"];
          } else if (value !== Plans.Items.EXPLORE) {
            if (newCurrentTraining.subcategory)
              delete newCurrentTraining.subcategory;
          }
        } else if (fieldName === "subcategory" && !value) {
          delete newCurrentTraining.subcategory;
        }
        return {
          ...state,
          currentTraining: newCurrentTraining,
        };
      case ActionTypes.TRAINING_CLEAR_CURRENT:
        return {
          ...state,
          currentTraining: undefined,
          currentWeek: 0,
        };
      case ActionTypes.TRAINING_SET_ERROR_MESSAGE:
        return {
          ...state,
          errorMessage: action.payload,
        };
      case ActionTypes.TRAINING_CLEAR_ERROR_MESSAGE:
        return {
          ...state,
          errorMessage: undefined,
        };
      case ActionTypes.TRAINING_SET_SUCCESS_MESSAGE:
        return {
          ...state,
          successMessage: action.payload,
        };
      case ActionTypes.TRAINING_CLEAR_SUCCESS_MESSAGE:
        return {
          ...state,
          successMessage: undefined,
        };
      case ActionTypes.AUTH_CURRENT_USER_SIGNOUT_FULFILLED:
        return {
          ...initialState,
        };
      case ActionTypes.TRAINING_DELETE_PENDING:
        return {
          ...state,
          deleting: true,
          errorMessage: undefined,
        };
      case ActionTypes.TRAINING_DELETE_FULFILLED:
        const { selected } = state.searchResult;
        const newSearchResultData = [...state.searchResult.data];
        if (selected) {
          selected.forEach((item) => {
            const foundIndex = newSearchResultData.findIndex(
              (data) => `${data.id}/${data.training_order}` === item,
            );
            if (foundIndex > -1) newSearchResultData.splice(foundIndex, 1);
          });
        }
        return {
          ...state,
          deleting: false,
          searchResult: {
            ...state.searchResult,
            selected: [],
            data: newSearchResultData,
          },
          successMessage: "Training(s) Deleted Successfully",
        };
      case ActionTypes.TRAINING_DELETE_REJECTED:
        const deleteData =
          action.payload &&
          action.payload.response &&
          action.payload.response.data;
        return {
          ...state,
          deleting: false,
          errorMessage:
            deleteData ||
            (action.payload && action.payload.message) ||
            "SERVER ERROR",
        };

      case ActionTypes.TRAINING_SEARCH_ATHLETES_PENDING:
        return {
          ...state,
          errorMessage: undefined,
          athletesLoading: true,
        };
      case ActionTypes.TRAINING_SEARCH_ATHLETES_FULFILLED:
        let athletesList = [];
        if (action.payload && action.payload.length > 0)
          action.payload.forEach((athlete) => {
            const {
              cognito_user_sub,
              cognito_user_name,
              cognito_user_family_name,
            } = athlete;
            athletesList = [
              ...athletesList,
              {
                value: cognito_user_sub,
                label: `${cognito_user_name}${
                  cognito_user_family_name ? ` ${cognito_user_family_name}` : ""
                }`.trim(),
              },
            ];
          });
        return {
          ...state,
          athletesLoading: false,
          athletesList: athletesList,
        };
      case ActionTypes.TRAINING_SEARCH_ATHLETES_REJECTED:
        return {
          ...state,
          athletesLoading: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.TRAINING_CLEAR_ATHLETES:
        return {
          ...state,
          athletesList: [],
        };
      default:
        return state;
    }
  } else {
    return state;
  }
}
