import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { palette } from "../../../theme/palette";
import PopularVideosCardSkeleton from "./PopularVideosCardSkeleton";
import usePost from "../../../hooks/usePost";
import PopularVideosHomeCard from "./PopularVideosHomeCard";

const RelatedVideos = () => {
  const { t } = useTranslation("home");
  const history = useHistory();
  const { currentExplorePostRelated, loadingRelatedExplorePosts } = usePost();
  const classes = useStyles();

  const navigate = (id) => {
    history.replace(`${id}`);
  };

  return (
    <>
      <Box>
        <Box className={classes.header}>
          <Typography variant="h3" className={classes.bold}>
            {t("Related Videos")}
          </Typography>
        </Box>
      </Box>
      {loadingRelatedExplorePosts || !currentExplorePostRelated ? (
        <Box className={classes.carousel}>
          {[...Array(3)].map((_, index) => (
            <PopularVideosCardSkeleton key={index} />
          ))}
        </Box>
      ) : (
        <Box className={classes.carousel}>
          {currentExplorePostRelated &&
            currentExplorePostRelated.map((item, index) => (
              <PopularVideosHomeCard
                key={index}
                item={item}
                navigate={navigate}
              />
            ))}
        </Box>
      )}
    </>
  );
};

export default RelatedVideos;

const useStyles = makeStyles(() => ({
  card: {
    background: palette.white,
    borderRadius: "8px",
    width: "275px",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
  descriptionContainer: {
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  carousel: {
    display: "-webkit-inline-box",
    width: "100%",
    overflowY: "hidden",
    overflowX: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(0, 0, 0, 0.2) transparent",
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  seeAll: {
    color: palette.lightBlue,
  },
  bold: {
    fontWeight: "700",
  },
  favoriteButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "#FFFFFF33",
  },
  icon: {
    color: "#6D6F71",
    width: "18px",
    margin: 0,
    padding: 0,
  },
  time: {
    color: "#6D6F71",
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
  },
  workout: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#212121",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "space-between",
    gap: "8px",
  },
  timeContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: "center",
  },
}));
