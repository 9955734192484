import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Grid,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import { useSelector } from "react-redux";

const hexToRgb = (hex = "") => {
  const parsedHex = hex.split("#")[1];
  const bigint = parseInt(parsedHex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r},${g},${b},${0.12})`;
};

const useStyles = makeStyles({
  main: {
    padding: "20px 35px 8px 35px",
    backgroundColor: "#21222D",
    boxSizing: "border-box",
    borderRadius: 10,
  },
  columnText: {
    color: "#87888C",
  },
  divider: {
    height: 1,
    background: "rgba(255, 255, 255, 0.06)",
    margin: "10px 0",
  },
  colorPrimary: {
    background: "#2B2B36",
  },
  barColorPrimary: {
    background: (props) => props.barColor,
  },
  whiteText: {
    color: "#FFF",
  },
  barContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "90%",
    justifyContent: "center",
  },
  customCard: {
    border: `0.5px solid `,
    borderColor: (props) => props.boxColor,
    padding: "5px 7px",
    boxSizing: "border-box",
    backgroundColor: (props) => hexToRgb(props.boxColor),
    borderRadius: "4px",
    width: "fit-content",
    "& p": {
      color: (props) => props.boxColor,
    },
  },
  rowParent: {
    marginTop: "16px",
    "& > *": {
      marginBottom: "12px",
    },
  },
  title: {
    marginBottom: 16,
    color: "#fff",
  },
});

const colors = {
  Z5: "#FC003C",
  Z4: "#FEB002",
  Z3: "#F3EB28",
  Z2: "#A9DFD8",
  Z1: "#20AEF3",
  Z0: "#F2C8ED",
};

const rpeScale = {
  Z5: "8 - 10",
  Z4: "6 - 7",
  Z3: "4 - 5",
  Z2: "3 - 4",
  Z1: "1 - 2",
  Z0: "0 - 1",
};

const names = {
  Z5: "Vo2 Max",
  Z4: "Threshold",
  Z3: "Tempo",
  Z2: "Endurance",
  Z1: "Easy",
  Z0: "Recovery",
};

const TrainingZoneForm = () => {
  const { zones } = useSelector((state) => state.lab);
  const { success, body } = zones || {};
  const classes = useStyles();

  const calculateIntensity = (part, max) => {
    const result = (part / max) * 100;
    return result > 100 ? 100 : Math.floor(result);
  };

  const processTrainingZone = (zones) => {
    if (!zones) return [];
    const newArray = Object.entries(zones);
    const userZones = newArray.map(([key, value]) => {
      return {
        zone: key,
        bpm: Math.max(...value),
        intensity: calculateIntensity(Math.max(...value), body.maxHR),
      };
    });

    return userZones.reverse() || [];
  };

  return (
    <Box className={classes.main}>
      <Typography className={classes.title} variant="h6">
        HR Based Training Zones
      </Typography>
      <Grid container>
        <Grid item md={2}>
          <Typography className={classes.columnText}>Zone</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography className={classes.columnText}>Name</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography className={classes.columnText}>Intensity</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography className={classes.columnText}>Your HR</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography className={classes.columnText}>RPE Scale</Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container className={classes.rowParent}>
        {processTrainingZone(success).map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Grid item md={2}>
                <Typography className={classes.whiteText}>
                  {item.zone}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography className={classes.whiteText}>
                  {names[item.zone]}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Box className={classes.barContainer}>
                  <Grid>
                    <ProgressLine item={item} value={item.intensity} />
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} justifyContent="center" alignItems="center">
                <CustomCard item={item} type="bpm" />
              </Grid>
              <Grid item md={2} justifyContent="center" alignItems="center">
                <CustomCard item={item} type="rpe" />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

const ProgressLine = ({ item, value }) => {
  const classes = useStyles({ barColor: colors[item.zone] });
  return (
    <LinearProgress
      variant="determinate"
      value={value}
      classes={{
        colorPrimary: classes.colorPrimary,
        barColorPrimary: classes.barColorPrimary,
      }}
    />
  );
};

const CustomCard = ({ item, type }) => {
  const classes = useStyles({ boxColor: colors[item.zone] });

  return (
    <Box className={classes.customCard}>
      <Typography>
        {type == "bpm" ? item[type] : rpeScale[item.zone]}{" "}
        {type == "bpm" ? "BPM" : ""}
      </Typography>
    </Box>
  );
};

export default TrainingZoneForm;
