import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import useFavorites from "../../../hooks/useFavorites";
import { useTranslation } from "react-i18next";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { PlayCircleOutlineRounded } from "@material-ui/icons";
import getTimeIcon from "../getTimeIcon";
import { palette } from "../../../theme/palette";

const VideoCard = ({ item, navigate, customStyle = null }) => {
  const { toggleFavorites, isFavorite } = useFavorites();
  const { language } = useTranslation();

  const classes = useStyles();
  return (
    <Box
      className={classes.card}
      style={customStyle}
      onClick={(e) => {
        e.stopPropagation();
        navigate(item);
      }}
    >
      <div style={imageStyles(item.image_url)}>
        <IconButton
          className={classes.favoriteButton}
          size="small"
          aria-label="add to favorites"
          onClick={(e) => {
            e.stopPropagation();
            toggleFavorites("video", item.id);
          }}
        >
          {isFavorite("video", item.id) ? (
            <StarIcon color="primary" />
          ) : (
            <StarBorderIcon style={{ color: "white" }} />
          )}
        </IconButton>
        <IconButton
          className={classes.playButton}
          size="medium"
          aria-label="Play"
          onClick={(e) => {
            e.stopPropagation();
            navigate(item);
          }}
        >
          <PlayCircleOutlineRounded
            style={{ color: "white", width: 40, height: 40 }}
          />
        </IconButton>
      </div>
      <Box className={classes.descriptionContainer}>
        <Box className={classes.titleContainer}>
          <Box className={classes.timeContainer}>
            {getTimeIcon(item.time || item.workout_time)}
            <Typography className={classes.time}>
              {item.time || item.workout_time}
            </Typography>
          </Box>
          <Typography className={classes.workout}>
            {item.workout_type && item.workout_type[language]
              ? item.workout_type[language]
              : item.workout_type && item.workout_type["en"]}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default VideoCard;

const useStyles = makeStyles({
  card: {
    background: palette.white,
    borderRadius: "16px",
    width: "100%",
    height: "280px",
  },
  descriptionContainer: {
    padding: "12px",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  parentBox: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    maxWidth: "100%",
    padding: "20px",
    gap: "24px",
    flex: 1,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    marginBottom: "74px",
  },
  nextRaceSlider: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  icon: {
    color: palette.darkGray,
    width: "18px",
    margin: 0,
    padding: 0,
  },
  favoriteButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "#FFFFFF33",
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF33",
  },
  videosContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  time: {
    color: "#6D6F71",
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
  },
  workout: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#212121",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "space-between",
    gap: "8px",
  },
  timeContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: "center",
  },
});

const imageStyles = (url) => ({
  borderRadius: "8px 8px 0 0",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url(${url})`,
  height: "200px",
  position: "relative",
});
