import React, { Component, memo } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import diff from "deep-diff";
import { Switch, Typography } from "@material-ui/core";
import { Routes } from "../lib/routing-middleware";
import history from "../lib/history-helper";
import WorkoutFilter from "./workouts/workout-filter";
import WorkoutFilterChips from "./workouts/workout-filter-chips";
import WorkoutGrid from "./workouts/workout-grid";
import WorkoutEditor from "./workouts/workout-editor";
import FloatingAddButton from "./common/floating-add-button";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import DeleteConfirmationDialog from "./common/delete-confirmation-dialog";
import { cleanSearch } from "../actions/workout-action";
import { withTranslation } from "react-i18next";

import {
  changeSearchPhrase,
  changeSearchFilter,
  changeSearchParams,
  searchWorkouts,
  clearSuccessMessage,
  clearErrorMessage,
  deleteBulkWorkout,
} from "../actions/workout-action";
import UserGroups from "../constants/user-groups";
import PrivateRoute from "./private-route";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 5,
    width: "100%",
  },
  toggleParent: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    marginRight: "16px",
  },
};

class Workouts extends Component {
  componentDidMount() {
    const { auth } = this.props;
    const { currentUserGroups } = auth;

    const isExternalCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
      ) > -1;

    if (isExternalCoach) {
      this.props.dispatch(
        changeSearchFilter({ name: "justMine", values: true }),
      );
    } else {
      this.props.dispatch(
        changeSearchFilter({ name: "justMine", values: false }),
      );
    }
  }

  handleSearchPhraseChanged = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.props.dispatch(changeSearchPhrase(value));
      }
    }
  };

  handleSearchParamChanged = (param) => {
    this.props.dispatch(changeSearchParams(param));
  };

  handleSelect = ({ name, value }) => {
    const { workout } = this.props;
    const { currentFilter } = workout;

    const values = currentFilter[name];
    if (name === "justMine") {
      this.props.dispatch(changeSearchFilter({ name, values: value }));
      return;
    }
    if (!values || values.indexOf(value) < 0)
      this.props.dispatch(
        changeSearchFilter({ name, values: [...(values || []), value] }),
      );
  };

  handleApplyFilter = () => {
    const { workout } = this.props;
    const { currentFilter } = workout;

    let filtersQueryStringReady = {};
    const { searchPhrase } = currentFilter;
    if (searchPhrase) {
      filtersQueryStringReady.searchPhrase = searchPhrase;
    }
    const filters = Object.keys(currentFilter).filter(
      (key) => key !== "searchPhrase" && key !== "justMine",
    );
    if (filters.length > 0) {
      filters.forEach((filter) => {
        if (currentFilter[filter])
          filtersQueryStringReady[filter] = currentFilter[filter].join(";");
      });
    }
    this.props.dispatch(searchWorkouts(filtersQueryStringReady));
  };

  onDeleteFilter = (key, data) => {
    if (key && data) {
      const { workout } = this.props;
      const { currentFilter } = workout;
      const filters = [...currentFilter[key]];
      if (filters && filters.length > 0) {
        const foundIndex = filters.findIndex((item) => item === data);
        if (foundIndex >= 0) {
          filters.splice(foundIndex, 1);
          this.props.dispatch(
            changeSearchFilter({
              name: key,
              values: filters.length === 0 ? undefined : filters,
            }),
          );
        }
      }
    }
  };

  handleDeleteSearchPhrase = () => {
    this.props.dispatch(changeSearchPhrase());
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleDeleteRequested = () => {
    const { workout } = this.props;
    if (workout) {
      const { searchResult } = workout;
      if (searchResult) {
        const { selected } = searchResult;
        if (selected && selected.length > 0) {
          this.props.dispatch(deleteBulkWorkout(selected));
        }
      }
    }
    history.push(Routes.Workouts);
  };

  handleDeleteCancel = () => {
    history.push(Routes.Workouts);
  };

  componentWillUnmount() {
    //this.props.dispatch(cleanSearch());
  }

  render() {
    const { classes, workout, auth, i18n } = this.props;
    const { language } = i18n;

    const {
      currentFilter,
      latestFilterApplied,
      errorMessage,
      successMessage,
    } = workout;
    const { searchPhrase, justMine } = currentFilter;
    const selectableFilters = { ...currentFilter, searchPhrase: undefined };

    const differents = diff(currentFilter, latestFilterApplied);
    const newFilterApplied = differents && differents.length > 0 ? true : false;
    let hasAnyFilters = false;
    for (let prop in currentFilter) {
      if (currentFilter[prop] && !hasAnyFilters) hasAnyFilters = true;
    }

    const { currentUserGroups, currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;

    const isExternalCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
      ) > -1;

    return (
      <div className={classes.container}>
        <WorkoutFilter
          searchPhrase={searchPhrase}
          onSearchPhraseChanged={this.handleSearchPhraseChanged}
          onFilterSelect={this.handleSelect}
          newFilterApplied={newFilterApplied}
          hasAnyFilters={hasAnyFilters}
          onApplyFilter={this.handleApplyFilter}
          isExternalCoach={isExternalCoach}
          justMine={justMine}
        />
        <WorkoutFilterChips
          filters={selectableFilters}
          onDeleteFilter={this.onDeleteFilter}
          searchPhrase={searchPhrase}
          onDeleteSearchPhrase={this.handleDeleteSearchPhrase}
        />

        <WorkoutGrid
          onSearchParamChanged={this.handleSearchParamChanged}
          sub={sub}
          isExternalCoach={isExternalCoach}
        />
        <FloatingAddButton to={Routes.WorkoutCreate} />

        <Route
          path={Routes.WorkoutDeletePath}
          render={(props) => (
            <DeleteConfirmationDialog
              entityName="Workout(s)"
              onCancel={this.handleDeleteCancel}
              onConfirm={this.handleDeleteRequested}
              {...props}
            />
          )}
        />

        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      </div>
    );
  }
}

Workouts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect((store) => {
  return {
    workout: store.workout,
    auth: store.auth,
  };
})(withTranslation()(withStyles(styles)(memo(Workouts))));
