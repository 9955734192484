import React, { createContext, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSignIn, getSignUpMethod } from "../actions/athletes-action";
import history from "../lib/history-helper";
import { Auth } from "aws-amplify";
import { currentUserLoad, updateAuthAttributes } from "../actions/auth-action";
import { deleteAccount } from "../actions/user-plans-action";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [globalErrorMessage, setGlobalErrorMessage] = useState("");
  const [signInLoading, setSignInLoading] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [confirmSignUpLoading, setConfirmSignUpLoading] = useState(false);
  const [resendConfirmationLoading, setResendConfirmationLoading] = useState(
    false,
  );
  const dispatch = useDispatch();
  const { signInType } = useSelector((state) => state.athletes);

  const getSignUp = () => {
    dispatch(getSignUpMethod(email.toLowerCase()));
  };

  const socialSignIn = (provider) => {
    try {
      Auth.federatedSignIn({ provider });
    } catch (err) {
      setGlobalErrorMessage("onboarding.error.errorOcurred");
      console.log("federated error", err);
    }
  };

  const resetType = () => {
    dispatch(clearSignIn());
  };

  const signUp = async () => {
    setSignUpLoading(true);
    if (!email || !password || !name) {
      setGlobalErrorMessage("onboarding.error.allFieldsRequired");
      setSignUpLoading(false);
      return;
    }

    try {
      const { user } = await Auth.signUp({
        username: email.toLowerCase(),
        password,
        attributes: {
          email: email.toLowerCase(),
          name,
        },
        autoSignIn: {
          enabled: true,
        },
      });
      setSignUpLoading(false);
      if (user) {
        history.push("/auth/sign-up/method/email/confirm");
      }
    } catch (err) {
      setSignUpLoading(false);
      if (err.code === "UserLambdaValidationException") {
        setErrorMessage("onboarding.error.userExist");
      } else if (err.code === "InvalidParameterException") {
        setErrorMessage("onboarding.error.invalidEmail");
      } else {
        setGlobalErrorMessage("onboarding.error.errorOcurred");
      }
    }
  };
  const resetPassword = async () => {
    try {
      await Auth.forgotPassword(email.toLowerCase());
      history.push("/auth/log-in/reset");
    } catch (err) {
      setGlobalErrorMessage("onboarding.error.errorOcurred");
    }
  };

  const resendResetPassword = async () => {
    try {
      await Auth.forgotPassword(email.toLowerCase());
    } catch (err) {
      setGlobalErrorMessage("onboarding.error.errorOcurred");
    }
  };

  const hanndleDeleteAccount = async (mail) => {
    dispatch(deleteAccount(mail));
  };

  const confirmReset = async () => {
    setConfirmSignUpLoading(true);

    if (!confirmationCode) {
      setErrorMessage("onboarding.error.confirmationCodeRequired");
      setConfirmSignUpLoading(false);
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(
        email.toLowerCase(),
        confirmationCode,
        password,
      );
      signIn();
      setConfirmSignUpLoading(false);
    } catch (err) {
      setConfirmSignUpLoading(false);
      if (err.code === "CodeMismatchException") {
        setErrorMessage("onboarding.error.confirmationCodeInvalid");
      } else if (err.code === "ExpiredCodeException") {
        setErrorMessage("onboarding.error.confirmationCodeExpired");
      } else if (err.code === "InvalidPasswordException") {
        setErrorMessage("onboarding.error.confirmationInvalidPassword");
      } else {
        setGlobalErrorMessage("onboarding.error.errorOcurred");
      }
    }
  };

  const confirmSignUp = async () => {
    setConfirmSignUpLoading(true);

    if (!confirmationCode) {
      setErrorMessage("onboarding.error.confirmationCodeRequired");
      setConfirmSignUpLoading(false);
      return;
    }
    try {
      await Auth.confirmSignUp(email.toLowerCase(), confirmationCode);
      signIn();
      setConfirmSignUpLoading(false);
    } catch (err) {
      setConfirmSignUpLoading(false);
      if (err.code === "CodeMismatchException") {
        setErrorMessage("onboarding.error.confirmationCodeInvalid");
      } else if (err.code === "ExpiredCodeException") {
        setErrorMessage("onboarding.error.confirmationCodeExpired");
      } else {
        setGlobalErrorMessage("onboarding.error.errorOcurred");
      }
    }
  };

  const resendConfirmationCode = async () => {
    setResendConfirmationLoading(true);

    try {
      await Auth.resendSignUp(email.toLowerCase());
      setResendConfirmationLoading(false);
    } catch (err) {
      setResendConfirmationLoading(false);
      setErrorMessage("onboarding.error.errorOcurred");
    }
  };
  const signIn = async () => {
    setSignInLoading(true);
    if (!email || !password) {
      setErrorMessage("onboarding.error.passwordRequired");
      setSignInLoading(false);
      return;
    }
    try {
      const { signInUserSession } = await Auth.signIn(
        email.toLowerCase(),
        password,
      );
      setSignInLoading(false);
      if (signInUserSession) {
        dispatch(currentUserLoad(Auth.currentAuthenticatedUser()));
        history.push("/dashboard");
      }
    } catch (err) {
      setSignInLoading(false);
      if (err.code === "UserNotConfirmedException") {
        history.push("/auth/sign-up/method/email/confirm");
      } else if (err.code === "NotAuthorizedException") {
        setErrorMessage("onboarding.error.passwordIncorrect");
      } else if (err.code === "UserNotFoundException") {
        setErrorMessage("onboarding.error.loginFailed");
      } else {
        setErrorMessage("onboarding.error.errorOcurred");
      }
    }
  };

  const finishOnboardingAttribute = async () => {
    let user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:onboardingFinished": "true",
    });

    dispatch(
      updateAuthAttributes({
        "custom:onboardingFinished": "true",
      }),
    );
  };
  const resetErrorMessage = () => {
    setErrorMessage("");
    setGlobalErrorMessage("");
  };

  const finishQuestionaireAttributes = async (birthdate, country, gender) => {
    let user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      birthdate,
      locale: country,
      gender,
    });
    dispatch(
      updateAuthAttributes({
        birthdate,
        locale: country,
        gender,
      }),
    );
  };

  return (
    <AuthContext.Provider
      value={{
        email,
        setEmail,
        name,
        setName,
        password,
        setPassword,
        signInLoading,
        signUpLoading,
        confirmSignUpLoading,
        resendConfirmationLoading,
        getSignUp,
        signInType,
        resetType,
        signIn,
        signUp,
        resetPassword,
        confirmSignUp,
        confirmReset,
        resendResetPassword,
        resendConfirmationCode,
        errorMessage,
        resetErrorMessage,
        globalErrorMessage,
        confirmationCode,
        setConfirmationCode,
        finishOnboardingAttribute,
        finishQuestionaireAttributes,
        socialSignIn,
        hanndleDeleteAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
};

export { AuthContext, AuthProvider, useAuthContext };
