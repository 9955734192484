import React from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Typography, Button } from "@material-ui/core";
import { logEvent } from "../../lib/events-helper";

const styles = (theme) => ({
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  noPadding: {
    padding: "0px !important",
  },
  dialogPaper: {
    borderRadius: "12px",
  },
  imageParent: {
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    top: "5%",
    right: "5%",
    cursor: "pointer",
  },
  content: {
    padding: "12px",
    boxSizing: "border-box",
  },
  title: {
    fontSize: "26px",
    fontWeight: 700,
    textAlign: "center",
  },
  description: {
    fontSize: "18px",
    color: "#3A4750",
    textAlign: "center",
    marginBottom: "20px",
    fontWeight: 400,
  },
  price: {
    color: "#3A4750",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "bold",
  },
  btnParent: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0 6px 0",
  },
  communityImg: {
    width: "100%",
  },
  list: {},
  listItem: {
    display: "flex",
    alignItems: "flex-start",
  },
  listIcon: {
    marginRight: 5,
  },
  listText: {
    color: "#606F7B",
    fontSize: 18,
  },
  plus: {
    margin: 20,
    textAlign: "center",
    fontWeight: 600,
    fontSize: 24,
  },
});

const UpgradeToYearModal = ({ classes, t, open, onClose }) => {
  const goToSub = () => {
    window
      .open(
        "https://vert.chargebeeportal.com/portal/v2/login?forward=portal_main",
        "_blank",
      )
      .focus();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose ? onClose : null}
      aria-labelledby="upgrade-to-pro"
      className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent className={classes.noPadding}>
        <div className={classes.imageParent}>
          <img
            className={classes.communityImg}
            alt="upgrade to pro modal"
            src={`${process.env.PUBLIC_URL}/upgrade-year-banner.jpg`}
          />
          <img
            src={`${process.env.PUBLIC_URL}/close-white-icon.svg`}
            alt="close-icon"
            className={classes.closeIcon}
            onClick={onClose ? onClose : null}
          />
        </div>

        <div className={classes.content}>
          <Typography className={classes.title}>
            {t("Get 3 months free training")}
          </Typography>
          <Typography
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: t(
                "Switch to annual billing today and save $84/year--that's 3 months of free training! Plus, stay accountable and strong all year long",
              ),
            }}
          ></Typography>

          <div className={classes.list}>
            <div className={classes.listItem}>
              <img
                src={`${process.env.PUBLIC_URL}/list-icon.svg`}
                alt="list-icon"
                className={classes.listIcon}
              />
              <Typography
                className={classes.listText}
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Access to everything from your monthly subscription",
                  ),
                }}
              ></Typography>
            </div>

            <Typography className={classes.plus}>{t("PLUS")}</Typography>

            <div className={classes.listItem}>
              <img
                src={`${process.env.PUBLIC_URL}/list-icon.svg`}
                alt="list-icon"
                className={classes.listIcon}
              />
              <Typography
                className={classes.listText}
                dangerouslySetInnerHTML={{
                  __html: t("Pause your plan for up to 60 days -- no fee"),
                }}
              ></Typography>
            </div>

            <div className={classes.listItem}>
              <img
                src={`${process.env.PUBLIC_URL}/list-icon.svg`}
                alt="list-icon"
                className={classes.listIcon}
              />
              <Typography
                className={classes.listText}
                dangerouslySetInnerHTML={{
                  __html: t("See your entire training plan at once"),
                }}
              ></Typography>
            </div>

            <div className={classes.listItem}>
              <img
                src={`${process.env.PUBLIC_URL}/list-icon.svg`}
                alt="list-icon"
                className={classes.listIcon}
              />
              <Typography
                className={classes.listText}
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Stay sharp all year: enjoy our off-season, winter and cross training plans",
                  ),
                }}
              ></Typography>
            </div>
          </div>

          <div className={classes.btnParent}>
            <Button
              color="primary"
              variant="contained"
              size="medium"
              onClick={goToSub}
            >
              {t("GET 3 MONTHS FREE")}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation("dashboard")(
  withWidth()(withStyles(styles, { withTheme: true })(UpgradeToYearModal)),
);
