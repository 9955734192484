import { useEffect, useRef, useState } from "react";
import { useTrainingContext } from "../context/TrainingContext";
import useUserInfo from "./useUserInfo";
import useWorkout, { NOT_WORKOUT } from "./useWorkout";
import { MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arraySwap } from "@dnd-kit/sortable";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import bugsnagClient from "../lib/bugsnag-client";
import { getDay, getWeekday } from "../lib/date-helper";
import { updatePlanSwap } from "../actions/user-plans-action";
import { useTranslation } from "react-i18next";
import { addDays, subDays } from "date-fns";

const useWeeklyWorkout = () => {
  const dispatch = useDispatch();
  const {
    i18n: { language },
  } = useTranslation();
  const { formatDate } = useWorkout();
  const {
    currentTraining,
    currentPlan,
    isSubscribed,
    userPlans,
  } = useUserInfo();
  const { updating = false } = userPlans || {};
  const { durationInWeeks = 0, lastModified = null } = currentTraining || {};
  const { dateFrom, dateTo } = useTrainingContext();
  const [workoutWeekly, setWorkoutWeekly] = useState(null);
  const [workoutSwap, setWorkoutSwap] = useState(null);
  const [weekDates, setWeekDates] = useState([]);
  const [isOpenWorkoutSwap, setIsOpenWorkoutSwap] = useState(false);
  const [isOpenPaywall, setIsOpenPaywall] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasPreviousWeekly, setHasPreviousWeekly] = useState(false);
  const [hasNextWeekly, setHasNextWeekly] = useState(false);
  const containerRef = useRef(null);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 250,
      distance: 5,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  const getWeekDates = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const assignWorkoutWeekly = (days = [], dateTo, dateFrom) => {
    try {
      const week = getWeekDates(dateFrom, dateTo);
      setWeekDates(week);

      if (!week.length && !week.length > 0) {
        setWorkoutWeekly(null);
        return false;
      }

      if (!days.length && !days.length > 0) {
        setWorkoutWeekly(null);
        return false;
      }

      const weekDates = week.map((date) => formatDate(date));
      const trainingDaysNullFiltered = days.filter((day) => day != null);
      const previousWeek = getWeekDates(
        subDays(new Date(dateFrom), 7),
        subDays(new Date(dateTo), 7),
      );
      const nextWeek = getWeekDates(
        addDays(new Date(dateFrom), 7),
        addDays(new Date(dateTo), 7),
      );
      const hasPreviousWeekWorkouts = previousWeek.some((date) =>
        trainingDaysNullFiltered.some(
          (day) => day[0].plannedDate === formatDate(date),
        ),
      );
      const hasNextWeekWorkouts = nextWeek.some((date) =>
        trainingDaysNullFiltered.some(
          (day) => day[0].plannedDate === formatDate(date),
        ),
      );
      const weekDays = weekDates.map((date) => {
        const matchingDay = trainingDaysNullFiltered.find(
          (day) => day[0].plannedDate === date,
        );
        if (matchingDay) {
          return {
            plannedDate: date,
            workout: { sortableId: uuidv4(), ...matchingDay[0] },
          };
        } else {
          return {
            plannedDate: date,
            workout: {
              id: uuidv4(),
              sortableId: uuidv4(),
              workoutType: NOT_WORKOUT,
            },
          };
        }
      });
      setWorkoutWeekly(weekDays.map((week) => week.workout));
      setHasPreviousWeekly(hasPreviousWeekWorkouts);
      setHasNextWeekly(hasNextWeekWorkouts);
    } catch (error) {
      bugsnagClient.notify(error);
      return false;
    }
  };

  const confirmSwapWorkout = () => {
    if (!currentTraining || !workoutSwap) return false;
    dispatch(
      updatePlanSwap(
        {
          cognito_user_sub: currentPlan.cognito_user_sub,
          plannedDate1:
            workoutSwap.destination.workoutType !== NOT_WORKOUT
              ? workoutSwap.destination.plannedDate
              : null,
          workoutId1:
            workoutSwap.destination.workoutType !== NOT_WORKOUT
              ? workoutSwap.destination.id
              : null,
          plannedDate2:
            workoutSwap.source.workoutType !== NOT_WORKOUT
              ? workoutSwap.source.plannedDate
              : null,
          workoutId2:
            workoutSwap.source.workoutType !== NOT_WORKOUT
              ? workoutSwap.source.id
              : null,
          relativePos1: workoutSwap.sourceIndex - workoutSwap.destinationIndex,
          relativePos2: workoutSwap.destinationIndex - workoutSwap.sourceIndex,
        },
        currentTraining,
        0,
        false,
      ),
    );
    setIsOpenWorkoutSwap(false);
  };

  const cancelSwapWorkout = () => {
    setWorkoutSwap(null);
    setIsOpenWorkoutSwap(false);
    assignWorkoutWeekly(currentTraining.days, dateTo, dateFrom);
  };

  const handleDragEnd = ({ ...props }) => {
    // verified is susbscribed
    if (!isSubscribed) return false;

    const { active, over } = props;

    if (active.id !== over.id) {
      const trainingSource = workoutWeekly.find(
        (workout) => workout.sortableId == active.id,
      );
      const trainingDestination = workoutWeekly.find(
        (workout) => workout.sortableId == over.id,
      );
      const trainingSourceIndex = workoutWeekly.findIndex(
        (workout) => workout.sortableId == active.id,
      );
      const trainingDestinationIndex = workoutWeekly.findIndex(
        (workout) => workout.sortableId == over.id,
      );

      const {
        sortableId: sortIdTrainingDestination,
        ...trainingDestinationWithoutSortId
      } = trainingDestination;
      const {
        sortableId: sortIdTraningSource,
        ...trainingSourceWithoutSortId
      } = trainingSource;

      setWorkoutSwap({
        sourceIndex: trainingSourceIndex,
        source: trainingSourceWithoutSortId,
        destinationIndex: trainingDestinationIndex,
        destination: trainingDestinationWithoutSortId,
        sourceWeekday: getWeekday(
          trainingSourceWithoutSortId.plannedDate,
          language,
          true,
        ),
        destinationWeekday: trainingDestinationWithoutSortId
          ? getWeekday(
              trainingDestinationWithoutSortId.plannedDate,
              language,
              true,
            )
          : null,
        destinationDay: trainingDestinationWithoutSortId
          ? getDay(trainingDestinationWithoutSortId.plannedDate, language, true)
          : null,
      });

      setWorkoutWeekly((workouts) => {
        const oldIndex = workouts.findIndex(
          (workout) => workout.sortableId === active.id,
        );
        const newIndex = workouts.findIndex(
          (workout) => workout.sortableId === over.id,
        );
        return arraySwap(workouts, oldIndex, newIndex);
      });

      setIsOpenWorkoutSwap(true);
    }
    setActiveIndex(null);
  };

  const handleDragStart = ({ ...props }) => {
    if (isSubscribed) {
      const { active } = props;
      const index = workoutWeekly.findIndex(
        (item) => item.sortableId === active.id,
      );
      setActiveIndex(index);
    } else {
      setIsOpenPaywall(true);
      return false;
    }
  };

  useEffect(() => {
    if (currentTraining)
      assignWorkoutWeekly(currentTraining.days, dateTo, dateFrom);
  }, [currentTraining, lastModified, durationInWeeks, dateFrom, dateTo]);

  useEffect(() => {
    setLoading(updating);
  }, [updating]);

  return {
    workoutWeekly,
    workoutSwap,
    weekDates,
    loading,
    isOpenPaywall,
    isOpenWorkoutSwap,
    activeIndex,
    containerRef,
    sensors,
    hasNextWeekly,
    hasPreviousWeekly,
    setIsOpenPaywall,
    confirmSwapWorkout,
    cancelSwapWorkout,
    handleDragEnd,
    handleDragStart,
    getWeekDates,
  };
};

export default useWeeklyWorkout;
