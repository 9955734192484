import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  LinearProgress,
  Drawer,
  MenuItem,
  Select,
  Switch,
  Button,
} from "@material-ui/core";
import history from "../lib/history-helper";
import UserGroups from "../constants/user-groups";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import AthleteTrainingWorkoutSearch from "../components/training-viewer/athlete-training-workout-search";
import AthleteTrainingBlockSearch from "../components/training-viewer/athlete-training-block-search";
import { Line, Radar } from "react-chartjs-2";
import {
  athleteTrainingChangeWeek,
  athleteTrainingSelectWorkout,
  athleteTrainingAddWorkout,
  athleteTrainingRemoveWorkout,
  athleteTrainingSwapWorkouts,
  clearSuccessMessage,
  clearErrorMessage,
  patchSelectedAthleteWorkoutComment,
  patchSelectedAthleteWorkoutCommentsRead,
  assignCoachToAthlete,
  assignStrengthCoachToAthlete,
  athleteTrainingAddWeek,
  athleteTrainingRemoveWeek,
  athleteTrainingAddEasyWeek,
  getAthleteComments,
  athleteTrainingAddBlock,
  athleteTrainingRemoveChainedPlan,
  changeCheckedStatus,
  changeNeedsToCheckStatus,
  setErrorMessage,
  athletesSearch,
  clearAthlete,
  patchSelectedAthleteWorkoutViewed,
  changeNeedsHelpStatus,
  saveAthletePlan,
} from "../actions/athletes-action";
import { dateFormat } from "../lib/date-helper";
import moment from "moment";
import AthletesProfile from "./athletes/athletes-profile";
import { getSubcategories } from "../actions/subcategory-action";
import WorkoutCommentEditor from "./athlete-training-viewer/workout-comment-editor";
import { Routes } from "../lib/routing-middleware";
import AthleteTrainingEditor from "./training-viewer/athlete-training-editor";
import { getSubcategoryTitle } from "../lib/subcategory-helper";
import Weekdays from "../constants/weekdays";
import EstimatedTimes from "../constants/estimated-times";
import AthletesNotes from "./athletes/athletes-notes";
import {
  addNote,
  editNote,
  deleteNote,
  editingMode,
} from "../actions/user-plans-action";
import { convertToRTFForView } from "../lib/rtf-helper";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import AthletesNotifications from "./athletes/athletes-notifications";
import AthletePlanAssignCoach from "./athletes/athlete-plan/athlete-plan-assign-coach";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AthletesChat from "./athletes/athletes-chat";
import {
  getEnergyLevelRate,
  getDoneRate,
  getTrainingLoadRate,
  getRpeRate,
} from "../lib/rates-helper";
import {
  getFullCurrentTraining,
  getPlan,
  getWeekDurations,
} from "../lib/current-training-helper";
import CircularProgress from "@material-ui/core/CircularProgress";
import LocalNotificationsService from "../services/local-notifications-service";
import { getPlanLevel } from "../lib/plan-helper";
import { updateAthleteDataLocally } from "../actions/athletes-action";
import { Box } from "@material-ui/core";
import "chart.js/auto";

const styles = (theme) => ({
  container: {
    width: "100%",
    height: "100%",
    padding: theme.spacing.unit * 2,
    background: "#F5F9FF",
  },
  items: {
    textAlign: "center",
    minHeight: 980,
  },
  pane: {
    padding: "0 20px",
    marginBottom: 10,
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    backgroundColor: "white",
    boxSizing: "border-box",
    borderRadius: 20,
    marginBottom: 20,
    justifyContent: "space-between",
  },
  topItem: {
    margin: 10,
  },
  title: {
    fontSize: 45,
    fontWeight: 600,
    margin: 10,
  },

  counterContainer: {
    background: "#FFFFFF",
    borderRadius: 20,
    padding: 30,
  },
  counterTitle: {
    fontSize: 14,
    color: "#808191",
    fontWeight: "bold",
    marginLeft: 5,
  },
  counterCount: {
    fontSize: 40,
  },
  counterTitleContainer: {
    display: "inline-flex",
    alignItems: "center",
  },
  counterTitleContainerSecond: {
    display: "inline-flex",
    alignItems: "center",
    marginTop: 20,
  },
  counterIconBkg: {
    padding: "2px 6px",
    background: "#FF5D6D",
    display: "inline",
    borderRadius: "100%",
  },
  colorPrimary: {
    backgroundColor: "#EDEDED",
  },
  barColorPrimary: {
    backgroundColor: "#1A629B",
  },
  reportContainer: {
    background: "#FFFFFF",
    borderRadius: 20,
    padding: 18,
  },
  reportTitle: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  reportValue: {
    fontSize: 20,
    fontWeight: 900,
    color: "#3F3F3F",
    marginBottom: -5,
  },
  runningBox: {
    display: "flex",
    alignItems: "center",
  },
  barBox: {
    width: "100%",
  },
  barBoxLabel: {
    marginLeft: 5,
    fontSize: 14,
    color: "#5F5F5F",
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 14,
  },
  topBarTitle: {
    fontWeight: 500,
    fontSize: 20,
    color: "#202020",
    marginRight: 14,
  },
  filter: {
    fontSize: 12,
    background: "#E7F3FC",
    borderRadius: 24,
    padding: "6px 8px",
    marginLeft: 10,
    marginRight: 10,
    color: "#0F4975",
    cursor: "pointer",
  },
  filterSelected: {
    fontSize: 12,
    background: "#1A629B",
    borderRadius: 24,
    padding: "6px 8px",
    marginLeft: 10,
    marginRight: 10,
    color: "#FFFFFF",
    cursor: "pointer",
  },
  chartBox: {
    height: 30,
    marginTop: 10,
  },

  notesContainer: {
    background: "#FFFFFF",
    borderRadius: 20,
    padding: 18,
    overflow: "hidden",
    maxHeight: 224,
    marginTop: "12px",
  },
  notesTitleContainer: {
    display: "flex",
  },
  notesTitle: {
    color: "#202020",
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 10,
    marginRight: "auto",
  },
  note: {
    background: "#F5F9FF",
    borderRadius: 8,
    padding: "1px 15px",
    overflow: "scroll",
    maxHeight: 185,
    cursor: "pointer",
  },
  noteText: {
    fontSize: 13,
  },
  remindersTitle: {
    fontSize: 10,
    alignItems: "center",
    display: "flex",
    color: "#5F5F5F",
    cursor: "pointer",
  },
  remindersIcon: {
    fontSize: 16,
  },
  coachContainer: {
    background: "#FFFFFF",
    borderRadius: 20,
    padding: 18,
    maxHeight: 224,
  },
  coachTitleContainer: {
    display: "flex",
  },
  coachTitle: {
    color: "#202020",
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 10,
    marginRight: "auto",
  },
  ratesContainer: {
    background: "#FFFFFF",
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  doneText: {
    fontWeight: 500,
    fontSize: 18,
    textAlign: "center",
    color: "#808191",
  },
  donePercentage: {
    fontWeight: 600,
    fontSize: 20,
    textAlign: "center",
  },
  leftSide: {
    display: "flex",
    alignItems: "center",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginLeft: "20px",
  },

  card: {
    padding: 9,
    boxSizing: "border-box",
    backgroundColor: "#ffcebb",
    borderRadius: 10,
    cursor: "pointer",
    maxHeight: 40,
    minHeight: 40,
    display: "flex",
    alignItems: "center",

    "&:last-child": {
      marginLeft: "8px",
    },

    "&:first-child": {
      marginRight: "8px",
    },
  },

  selectedCard: {
    padding: 9,
    boxSizing: "border-box",
    backgroundColor: "#ff5400",
    borderRadius: 10,
    cursor: "pointer",
    maxHeight: 40,
    minHeight: 40,
    display: "flex",
    alignItems: "center",

    "&:last-child": {
      marginLeft: "8px",
    },

    "&:first-child": {
      marginRight: "8px",
    },
  },
  text: {
    fontWeight: "bold",
  },
  whiteText: {
    fontWeight: "bold",
    color: "#FFF",
  },
  exclamation: {
    color: "red",
    fontSize: 22,
    fontWeight: "bold",
    marginRight: "5px",
  },
  textParent: {
    display: "flex",
    alignItems: "center",
  },
  emoji: {
    width: 20,
    marginRight: "5px",
  },
  selectPlans: {
    fontSize: "12px",
  },
  selectParent: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
  },
  selectItem: {
    fontSize: "12px",
  },
  switchView: {
    display: "flex",
    alignItems: "center",
    padding: "4px 8px",
  },

  workoutContainer: {
    position: "relative",
    cursor: "pointer",
  },
});

class AthletesDetail extends Component {
  state = {
    isCommentDialogOpen: false,
    addedWorkouts: [],
    showWeeklyReport: true,
    notesOpen: false,
    remindersOpen: false,
    ratesGran: "plan",
    searchTab: 0,
    totalEnergy: 0,
    markIsDoneTotal: 0,
    monthDays: [],
    myDataBase: false,
    monthsSelect: [],
  };

  componentDidMount() {
    const { subcategories, athletes, auth, closeDetails } = this.props;
    const { currentUserGroups } = auth || {};
    const { searchResult } = athletes;
    const { data } = subcategories;

    if (!data || data.length === 0) {
      this.props.dispatch(getSubcategories());
    }

    const isExternalCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
      ) > -1;

    if (!isExternalCoach) {
      this.setState({ ...this.state.addedWorkouts, myDataBase: false });
    }

    if (!searchResult || !searchResult.data || searchResult.data.length === 0) {
      closeDetails();
    }

    const { selectedAthlete } = athletes;
    const { currentTraining } = selectedAthlete || {};
    const hasCurrentTraining =
      currentTraining !== undefined && typeof currentTraining === "object";

    if (hasCurrentTraining) {
      const today = moment();
      const { startingDate, durationInWeeks } = currentTraining || {};
      if (startingDate) {
        const startingDateMoment = moment(startingDate, "DD/MM/YYYY").startOf(
          "week",
        );
        let weeks = today.diff(startingDateMoment, "week");
        if (weeks >= +durationInWeeks) {
          weeks = +durationInWeeks - 1;
        }
        setTimeout(() => {
          this.handleRequestWeekChange(weeks);
        }, 1000);
      }
    }

    this.monthlyFilter();
  }

  async componentWillUnmount() {
    const { athletes, dispatch } = this.props;
    const { selectedAthlete } = athletes || {};
    const { cognito_user_sub, editMode } = selectedAthlete || {};
    if (editMode) await dispatch(editingMode(cognito_user_sub, false));
  }

  componentDidUpdate(prevProps, prevState) {
    const { athletes, subcategories } = this.props;
    const { data } = subcategories;
    const { selectedAthlete } = athletes;
    const { currentTraining } = selectedAthlete || {};
    const hasCurrentTraining =
      currentTraining !== null &&
      currentTraining !== undefined &&
      typeof currentTraining === "object";

    if (
      hasCurrentTraining &&
      (!prevProps.athletes ||
        !prevProps.athletes.selectedAthlete ||
        !prevProps.athletes.selectedAthlete.currentTraining ||
        typeof prevProps.athletes.selectedAthlete.currentTraining !== "object")
    ) {
      const today = moment();
      const { startingDate } = currentTraining;
      const startingDateMoment = moment(startingDate, "DD/MM/YYYY").startOf(
        "week",
      );
      const weeks = today.diff(startingDateMoment, "week");
      this.handleRequestWeekChange(weeks);
      this.monthlyFilter();
    }

    if (
      prevProps.athletes.selectedAthlete.currentTraining !== currentTraining ||
      this.state.weekdays !== prevState.weekdays
    ) {
      this.monthlyFilter();
    }

    if (prevProps.subcategories.data.length !== data.length) {
      this.props.dispatch(getSubcategories());
    }
  }

  monthlyFilter = () => {
    const { subcategories, athletes } = this.props;
    const { selectedAthlete } = athletes;
    const { currentTraining } = selectedAthlete || {};

    const thisMonday = moment().startOf("isoWeek");

    if (currentTraining) {
      if (currentTraining.days && currentTraining.days.length) {
        const nonNullDays = currentTraining.days.filter((d) => !!d);

        if (nonNullDays.length) {
          const weeks = [[]];

          let currentWeek = 0;
          let currentWeekMonday = moment(
            nonNullDays[0][0].plannedDate,
            dateFormat,
          ).startOf("isoWeek");

          let todayWeekIdx = 0;
          for (const day of nonNullDays) {
            if (
              moment(day[0].plannedDate, dateFormat)
                .startOf("isoWeek")
                .isSame(currentWeekMonday, "day")
            ) {
              weeks[currentWeek].push(day);
            } else {
              currentWeek++;
              weeks.push([day]);
              currentWeekMonday = moment(
                day[0].plannedDate,
                dateFormat,
              ).startOf("isoWeek");
              if (currentWeekMonday.isSame(thisMonday, "day")) {
                todayWeekIdx = currentWeek;
              }
            }
          }

          const before = [];
          let fourWeeksBefore = [];
          for (let i = todayWeekIdx - 1; i >= 0; i--) {
            fourWeeksBefore.push(weeks[i]);
            if (fourWeeksBefore.length >= 4 || i == 0) {
              before.push(fourWeeksBefore);
              fourWeeksBefore = [];
            }
          }

          const after = [];
          let fourWeeksAfter = [];
          for (let j = todayWeekIdx; j < weeks.length; j++) {
            fourWeeksAfter.push(weeks[j]);
            if (fourWeeksAfter.length >= 4 || j === weeks.length - 1) {
              after.push(fourWeeksAfter);
              fourWeeksAfter = [];
            }
          }

          const byWeek = [...before.reverse(), ...after];
          const months = byWeek.map((month) => {
            return {
              start: moment(month[0][0][0].plannedDate, dateFormat).startOf(
                "isoWeek",
              ),
              end: moment(
                month[month.length - 1][0][0].plannedDate,
                dateFormat,
              ).endOf("isoWeek"),
            };
          });

          this.setState({
            ...this.state,
            monthDays: byWeek,
            monthsLength: months.length,
            monthsSelect: months,
          });
        }
      }
    }
  };

  handleEditingPlan = () => {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes || {};
    const { cognito_user_sub, editMode } = selectedAthlete || {};
    this.props.dispatch(editingMode(cognito_user_sub, !editMode));
  };

  resetWorkoutNotifications() {
    const { athletes } = this.props;

    const { selectedAthlete } = athletes;

    const {
      currentTraining,
      showWorkoutNotifications,
      workoutNotificationsTime,
      cognito_user_sub,
    } = selectedAthlete || {};
    const { days } = currentTraining || {};

    const level = getPlanLevel(currentTraining);

    if (showWorkoutNotifications) {
      if (days) {
        LocalNotificationsService.scheduleWorkoutNotifications(
          days,
          workoutNotificationsTime,
          cognito_user_sub,
          level,
        );
      }
    }
  }

  handleRequestWeekChange = (weekNumber) => {
    this.props.dispatch(athleteTrainingChangeWeek(weekNumber));
  };

  handleSelectWorkout = (workout) => {
    this.props.dispatch(athleteTrainingSelectWorkout(workout));
  };

  handleWorkoutAdd = (plannedDate) => {
    const { athletes } = this.props;
    const { selectedAthlete, selectedAthleteChainedPlans } = athletes;
    const { selectedWorkout } = selectedAthlete;

    const plan = getPlan(
      [
        selectedAthlete,
        ...selectedAthleteChainedPlans.map((c, index) => {
          return {
            updated_at: selectedAthlete.chainedPlans[index],
            currentTraining: c,
          };
        }),
      ],
      plannedDate,
    );

    if (plan) {
      this.props
        .dispatch(
          athleteTrainingAddWorkout(
            selectedAthlete,
            moment(plannedDate).format(dateFormat),
            selectedWorkout,
            plan.updated_at === "latest" ? 0 : plan.updated_at,
          ),
        )
        .then(() => {
          // this.resetWorkoutNotifications();
          this.monthlyFilter();
        });
    }
  };

  handleWorkoutDelete = (workoutId, plannedDate, action = null) => {
    const { athletes } = this.props;
    const { selectedAthlete, selectedAthleteChainedPlans } = athletes;

    const plan = getPlan(
      [
        selectedAthlete,
        ...selectedAthleteChainedPlans.map((c, index) => {
          return {
            updated_at: selectedAthlete.chainedPlans[index],
            currentTraining: c,
          };
        }),
      ],
      plannedDate,
    );

    if (plan) {
      this.props
        .dispatch(
          athleteTrainingRemoveWorkout(
            selectedAthlete,
            moment(plannedDate).format(dateFormat),
            workoutId,
            plan.updated_at === "latest" ? 0 : plan.updated_at,
          ),
        )
        .then(() => {
          // this.resetWorkoutNotifications();
          this.monthlyFilter();
          if (action) action();
        });
    }
  };

  handleSave = async () => {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes;
    const { currentTraining, cognito_user_sub, editMode } =
      selectedAthlete || {};
    await this.props.dispatch(
      saveAthletePlan(currentTraining, cognito_user_sub),
    );
    if (editMode)
      await this.props.dispatch(editingMode(cognito_user_sub, false));
  };

  handleWorkoutsMoved = (changeReq) => {
    const { athletes } = this.props;
    const { selectedAthlete, selectedAthleteChainedPlans } = athletes;

    const plan = getPlan(
      [
        selectedAthlete,
        ...selectedAthleteChainedPlans.map((c, index) => {
          return {
            updated_at: selectedAthlete.chainedPlans[index],
            currentTraining: c,
          };
        }),
      ],
      changeReq.plannedDate1
        ? moment(changeReq.plannedDate1, dateFormat)
        : moment(changeReq.plannedDate2, dateFormat),
    );

    if (plan) {
      this.props
        .dispatch(
          athleteTrainingSwapWorkouts(
            {
              selectedAthlete,
              ...changeReq,
            },
            plan.updated_at === "latest" ? 0 : plan.updated_at,
          ),
        )
        .then(() => {
          // this.resetWorkoutNotifications();
          this.monthlyFilter();
        });
    }
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleWorkoutCommentDialog = (workoutId, plannedDate) => {
    this.setState({
      ...this.state,
      plannedDate: plannedDate,
      workoutId: workoutId,
    });
  };

  handleWorkoutCommentDialogClose = () => {
    const { plannedDate, workoutId } = this.state;
    if (plannedDate && workoutId) {
      const { athletes, auth } = this.props;
      const { selectedAthlete } = athletes;

      const { currentUserGroups } = auth;
      const isAdmin =
        currentUserGroups &&
        currentUserGroups.findIndex(
          (group) => group === UserGroups.USER_GROUP_ADMIN,
        ) > -1;

      if (!isAdmin) {
        this.props.dispatch(
          patchSelectedAthleteWorkoutCommentsRead(
            selectedAthlete,
            moment(plannedDate).format(dateFormat),
            workoutId,
          ),
        );
      }
    }
    this.setState({
      ...this.state,
      plannedDate: undefined,
      workoutId: undefined,
    });
  };

  handleWorkoutCommentDialogSave = (coachComment) => {
    const { plannedDate, workoutId } = this.state;
    if (plannedDate && workoutId) {
      const { athletes } = this.props;
      const { selectedAthlete } = athletes;
      this.props.dispatch(
        patchSelectedAthleteWorkoutComment(
          selectedAthlete,
          moment(plannedDate).format(dateFormat),
          workoutId,
          coachComment,
        ),
      );
    }
  };

  handleSelectWorkout = (workout) => {
    this.props.dispatch(athleteTrainingSelectWorkout(workout));
  };

  handleAddBlock = (plan, planWeek, athleteWeek) => {
    const { athletes } = this.props;
    const { selectedAthlete, selectedAthleteChainedPlans } = athletes;
    const { currentTraining, currentWeek } = selectedAthlete || {};

    const { startingDate } = currentTraining || {};

    const currentStartDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(athleteWeek, "weeks");

    const weekStartDate = moment(currentStartDate).startOf("isoWeek");

    const currentWeekDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek, "weeks");

    const planToChange = getPlan(
      [
        selectedAthlete,
        ...selectedAthleteChainedPlans.map((c, index) => {
          return {
            updated_at: selectedAthlete.chainedPlans[index],
            currentTraining: c,
          };
        }),
      ],
      currentWeekDate,
    );

    if (planToChange) {
      this.props
        .dispatch(
          athleteTrainingAddBlock(
            selectedAthlete,
            moment(weekStartDate).format(dateFormat),
            plan,
            planWeek,
            planToChange.updated_at === "latest" ? 0 : planToChange.updated_at,
          ),
        )
        .then(() => {
          // this.resetWorkoutNotifications();
          this.monthlyFilter();
        });
    }
  };

  openNotes = () => {
    this.setState({
      ...this.state,
      notesOpen: true,
    });
  };

  closeNotes = () => {
    this.setState({
      ...this.state,
      notesOpen: false,
    });
  };

  openReminders = () => {
    this.setState({
      ...this.state,
      remindersOpen: true,
    });
  };

  closeReminders = () => {
    this.setState({
      ...this.state,
      remindersOpen: false,
    });
  };

  handleAddNote = (note) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { cognito_user_sub } = currentPlan;
    this.props.dispatch(addNote(note, cognito_user_sub));
  };

  handleEditNote = (note) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { cognito_user_sub } = currentPlan;
    this.props.dispatch(editNote(note, cognito_user_sub));
  };

  handleDeleteNote = (note) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { cognito_user_sub } = currentPlan;
    this.props.dispatch(deleteNote(note, cognito_user_sub));
  };

  handleAssignCoach = (coach_cognito_user_sub) => {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes;
    if (selectedAthlete) {
      const { cognito_user_sub } = selectedAthlete;
      this.props.dispatch(
        assignCoachToAthlete({ cognito_user_sub, coach_cognito_user_sub }),
      );
    }
  };

  handleAddWeek = () => {
    const { athletes } = this.props;
    const { selectedAthlete, selectedAthleteChainedPlans } = athletes;
    const { currentTraining, currentWeek = 0 } = selectedAthlete || {};

    const { startingDate } = currentTraining || {};

    const currentStartDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek, "weeks");

    const weekStartDate = moment(currentStartDate).startOf("isoWeek");

    const currentWeekDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek, "weeks");

    const plan = getPlan(
      [
        selectedAthlete,
        ...selectedAthleteChainedPlans.map((c, index) => {
          return {
            updated_at: selectedAthlete.chainedPlans[index],
            currentTraining: c,
          };
        }),
      ],
      currentWeekDate,
    );

    if (plan) {
      this.props
        .dispatch(
          athleteTrainingAddWeek(
            selectedAthlete,
            moment(weekStartDate).format(dateFormat),
            plan.updated_at === "latest" ? 0 : plan.updated_at,
          ),
        )
        .then(() => {
          // this.resetWorkoutNotifications();
          this.monthlyFilter();
        });
    }
  };

  handleAddWeekNext = () => {
    const { athletes } = this.props;
    const { selectedAthlete, selectedAthleteChainedPlans } = athletes;
    const { currentTraining, currentWeek = 0 } = selectedAthlete || {};

    const { startingDate } = currentTraining || {};

    const currentStartDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek + 1, "weeks");

    const weekStartDate = moment(currentStartDate).startOf("isoWeek");

    const currentWeekDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek, "weeks");

    const plan = getPlan(
      [
        selectedAthlete,
        ...selectedAthleteChainedPlans.map((c, index) => {
          return {
            updated_at: selectedAthlete.chainedPlans[index],
            currentTraining: c,
          };
        }),
      ],
      currentWeekDate,
    );

    if (plan) {
      this.props
        .dispatch(
          athleteTrainingAddWeek(
            selectedAthlete,
            moment(weekStartDate).format(dateFormat),
            plan.updated_at === "latest" ? 0 : plan.updated_at,
          ),
        )
        .then(() => {
          // this.resetWorkoutNotifications();
          this.monthlyFilter();
        });
    }
  };

  handleAddEasyWeek = () => {
    const { athletes } = this.props;
    const { selectedAthlete, selectedAthleteChainedPlans } = athletes;
    const { currentTraining, currentWeek = 0 } = selectedAthlete || {};

    const { startingDate, raceCount, trainPerWeek } = currentTraining || {};

    const currentStartDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek, "weeks");

    const weekStartDate = moment(currentStartDate).startOf("isoWeek");

    const currentWeekDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek, "weeks");

    const plan = getPlan(
      [
        selectedAthlete,
        ...selectedAthleteChainedPlans.map((c, index) => {
          return {
            updated_at: selectedAthlete.chainedPlans[index],
            currentTraining: c,
          };
        }),
      ],
      currentWeekDate,
    );

    if (plan) {
      this.props
        .dispatch(
          athleteTrainingAddEasyWeek(
            selectedAthlete,
            moment(weekStartDate).format(dateFormat),
            raceCount,
            trainPerWeek,
            plan.updated_at === "latest" ? 0 : plan.updated_at,
          ),
        )
        .then(() => {
          // this.resetWorkoutNotifications();
          this.monthlyFilter();
        });
    }
  };

  handleAssignStrengthCoach = (coach_cognito_user_sub) => {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes;
    if (selectedAthlete) {
      const { cognito_user_sub } = selectedAthlete;
      this.props.dispatch(
        assignStrengthCoachToAthlete({
          cognito_user_sub,
          coach_cognito_user_sub,
        }),
      );
    }
  };

  handleAddEasyWeekNext = () => {
    const { athletes } = this.props;
    const { selectedAthlete, selectedAthleteChainedPlans } = athletes;
    const { currentTraining, currentWeek = 0 } = selectedAthlete || {};

    const { startingDate, raceCount, trainPerWeek } = currentTraining || {};

    const currentStartDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek + 1, "weeks");

    const weekStartDate = moment(currentStartDate).startOf("isoWeek");

    const currentWeekDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek, "weeks");

    const plan = getPlan(
      [
        selectedAthlete,
        ...selectedAthleteChainedPlans.map((c, index) => {
          return {
            updated_at: selectedAthlete.chainedPlans[index],
            currentTraining: c,
          };
        }),
      ],
      currentWeekDate,
    );

    if (plan) {
      this.props
        .dispatch(
          athleteTrainingAddEasyWeek(
            selectedAthlete,
            moment(weekStartDate).format(dateFormat),
            raceCount,
            trainPerWeek,
            plan.updated_at === "latest" ? 0 : plan.updated_at,
          ),
        )
        .then(() => {
          // this.resetWorkoutNotifications();
          this.monthlyFilter();
        });
    }
  };

  handleRemoveWeek = () => {
    const { athletes } = this.props;
    const { selectedAthlete, selectedAthleteChainedPlans } = athletes;
    const { currentTraining, currentWeek = 0 } = selectedAthlete || {};

    const { startingDate } = currentTraining || {};

    const currentStartDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek, "weeks");

    const weekStartDate = moment(currentStartDate).startOf("isoWeek");

    const currentWeekDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek, "weeks");

    const fullChainedPlans = selectedAthleteChainedPlans.map((c, index) => {
      return {
        updated_at: selectedAthlete.chainedPlans[index],
        currentTraining: c,
      };
    });

    const plan = getPlan(
      [selectedAthlete, ...fullChainedPlans],
      currentWeekDate,
    );

    if (
      plan &&
      plan.currentTraining &&
      +plan.currentTraining.durationInWeeks > 1
    ) {
      this.props
        .dispatch(
          athleteTrainingRemoveWeek(
            selectedAthlete,
            moment(weekStartDate).format(dateFormat),
            plan.updated_at === "latest" ? 0 : plan.updated_at,
          ),
        )
        .then(() => {
          // this.resetWorkoutNotifications();
          this.monthlyFilter();
        });
    }
  };

  handleRemoveChainedPlan = (index) => {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes;

    this.props
      .dispatch(athleteTrainingRemoveChainedPlan(selectedAthlete, index))
      .then(() => {
        // this.resetWorkoutNotifications();
        this.monthlyFilter();
      });
  };

  handleOpenChat = (workout = null) => {
    const { athletes } = this.props;

    const { selectedAthlete } = athletes || {};
    this.props.dispatch(
      getAthleteComments(
        selectedAthlete.cognito_user_sub,
        new Date().getTime(),
      ),
    );
    this.setState({
      ...this.state,
      chatOpen: true,
      chatWorkout: workout ? [workout] : null,
    });
  };

  handleCloseChat = () => {
    this.setState({
      ...this.state,
      chatOpen: false,
    });
  };

  onRequestGranChange = (value) => {
    this.setState({
      ...this.state,
      ratesGran: value,
    });
  };

  handlerDbSwitch = () => {
    const { myDataBase } = this.state;
    this.setState({ ...this.state, myDataBase: !myDataBase });
  };

  render() {
    const {
      plannedDate,
      workoutId,
      showWeeklyReport,
      notesOpen,
      remindersOpen,
      chatOpen,
      chatWorkout,
      ratesGran,
      searchTab,
      monthsLength,
    } = this.state;
    const { classes, auth, athletes, subcategories, userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { notes } = currentPlan || {};

    const reminders = notes ? notes.filter((n) => n.type === 1) : [];

    const {
      errorMessage,
      successMessage,
      coachesList,
      strengthCoaches,
      savingTraining,
      selectedAthlete,
      selectedAthleteChainedPlans,
      assigningCoach,
      loadingStrengthAssign,
      loadingChecked,
      loadingNeedsToCheck,
      loadingNeedsHelp,
      saveLoading,
      editingLoading,
    } = athletes || {};
    const {
      currentTraining,
      currentWeek = 0,
      stravaActivities,
      cognito_user_sub,
      coach_cognito_user_sub,
      image,
      checked,
      needsCheck,
      needHelp,
      strength_coach_cognito_user_sub,
      editMode,
    } = selectedAthlete || {};

    const level = getPlanLevel(currentTraining);

    const athleteNotes = notes
      ? notes.filter((n) => n.athlete === cognito_user_sub && n.type === 0)
      : [];
    const latestNote = athleteNotes.length ? athleteNotes[0] : null;

    const fullTraining =
      currentTraining && typeof currentTraining === "object"
        ? getFullCurrentTraining(currentTraining, selectedAthleteChainedPlans)
        : {};

    const { durationInWeeks, startingDate } = fullTraining || {};
    const { currentUserGroups } = auth;
    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;

    const selectedDayForComment =
      typeof plannedDate !== typeof undefined &&
      fullTraining &&
      fullTraining.days.find(
        (day) =>
          day && day[0].plannedDate === moment(plannedDate).format(dateFormat),
      );
    const selectedWorkoutForComment =
      workoutId &&
      selectedDayForComment &&
      selectedDayForComment.find((workout) => workout.id === workoutId);

    let { selectedPlan } = fullTraining || {};

    if (!selectedPlan && selectedAthlete && selectedAthlete.selectedPlan) {
      selectedPlan = selectedAthlete.selectedPlan;
    }

    let planName;

    const currentWeekDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek, "weeks");

    if (selectedPlan && selectedPlan !== "premium") {
      let subcategory =
        selectedAthlete &&
        selectedAthlete.currentTraining &&
        selectedAthlete.currentTraining.subcategory
          ? selectedAthlete.currentTraining.subcategory
          : +selectedAthlete.subcategory;

      if (
        selectedAthlete &&
        selectedAthlete.currentTraining &&
        selectedAthlete.currentTraining.chainedPlans &&
        selectedAthlete.currentTraining.chainedPlans.length
      ) {
        for (
          let index = 0;
          index < selectedAthlete.currentTraining.chainedPlans.length;
          index++
        ) {
          const element = selectedAthlete.currentTraining.chainedPlans[index];
          if (
            moment(currentWeekDate).isSameOrAfter(
              moment(element.startingDate, dateFormat),
              "days",
            )
          ) {
            subcategory = element.subcategory;
          }
        }
      }

      planName =
        subcategories &&
        subcategories.data &&
        subcategories.data.length &&
        selectedAthlete.subcategory
          ? getSubcategoryTitle(subcategories.data, subcategory, "en")
          : selectedPlan === "free"
          ? "Free"
          : "Explore";
    }
    if (selectedPlan === "premium") {
      planName = "Premium";
    }

    let goal = 0;
    let runningStreak = 0;
    if (
      fullTraining &&
      fullTraining.days &&
      fullTraining.days.filter((day) => day).length > 0
    ) {
      //running streak
      goal = fullTraining.days
        .filter((day) => day)
        .map((day) => day.length)
        .reduce((sum, a) => sum + a);
      runningStreak = fullTraining.days
        .filter((day) => day)
        .map((day) => day.filter((workout) => workout.isDone).length)
        .reduce((sum, a) => sum + a);
    }

    const { days } = fullTraining || {};

    const today = moment();
    const startingDateMoment = moment(startingDate, dateFormat);
    const currentDay = today.diff(startingDateMoment, "days") + 1;
    const currentWeekOfPlan = Math.min(
      Math.ceil((currentDay > 0 ? currentDay : 0) / 7),
      durationInWeeks,
    );
    let currentWeekStr = isNaN(currentWeekOfPlan) ? "N/A" : currentWeekOfPlan;
    if (currentWeek > durationInWeeks) {
      currentWeekStr = "Finished";
    }

    // Report
    let currentPlannedWeek = [];
    let currentDoneWeek = [];
    let energyLevelWeek = [];
    const currentStartDate = moment(startingDate, dateFormat)
      .startOf("isoWeek")
      .add(currentWeek, "weeks");

    const weekStartDate = moment(currentStartDate).startOf("isoWeek");
    const dates = [];
    for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
      dates.push(moment(weekStartDate).add(dayIndex, "day"));
      if (days) {
        const tempDate = moment(weekStartDate).add(dayIndex, "day");
        const foundDay = days.find(
          (day) =>
            day &&
            day.length > 0 &&
            moment(day[0].plannedDate, dateFormat).isSame(tempDate, "day"),
        );
        if (foundDay) {
          currentPlannedWeek.push(
            foundDay
              .map((item) =>
                Array.isArray(item.estimatedTime)
                  ? +item.estimatedTime[level]
                  : +item.estimatedTime,
              )
              .reduce((a, sum) => a + sum),
          );
          currentDoneWeek.push(
            foundDay
              .map((item) =>
                item.isDone
                  ? Array.isArray(item.estimatedTime)
                    ? +item.estimatedTime[level]
                    : +item.estimatedTime
                  : 0,
              )
              .reduce((a, sum) => a + sum),
          );
          energyLevelWeek.push(
            foundDay
              .map((item) => (item.energyLevel ? +item.energyLevel : 0))
              .reduce((a, sum) => a + sum),
          );
        } else {
          currentPlannedWeek.push(0);
          currentDoneWeek.push(0);
          energyLevelWeek.push(0);
        }
      } else {
        currentPlannedWeek.push(0);
        currentDoneWeek.push(0);
        energyLevelWeek.push(0);
      }
    }

    let currentPlannedTotal = [];
    let currentDoneTotal = [];
    let energyLevelTotal = [];
    for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
      currentPlannedTotal.push(0);
      currentDoneTotal.push(0);
      energyLevelTotal.push(0);
    }

    if (days) {
      days.forEach((day) => {
        if (day) {
          const plannedDay =
            moment(day[0].plannedDate, dateFormat).diff(
              startingDateMoment,
              "days",
            ) + 1;
          const plannedWeek = Math.floor(
            (plannedDay > 1 ? plannedDay - 1 : 0) / 7,
          );
          const estimated = day
            .map((item) =>
              Array.isArray(item.estimatedTime)
                ? +item.estimatedTime[level]
                : +item.estimatedTime,
            )
            .reduce((a, sum) => a + sum);
          const done = day
            .map((item) =>
              item.isDone
                ? Array.isArray(item.estimatedTime)
                  ? +item.estimatedTime[level]
                  : +item.estimatedTime
                : 0,
            )
            .reduce((a, sum) => a + sum);
          const energyLevel = day
            .map((item) => (item.energyLevel ? +item.energyLevel : 0))
            .reduce((a, sum) => a + sum);
          currentPlannedTotal[plannedWeek] += +estimated;
          currentDoneTotal[plannedWeek] += +done;
          energyLevelTotal[plannedWeek] += +energyLevel;
        }
      });
    }

    energyLevelTotal = energyLevelTotal.map((e) => Math.round(e / 7));

    let time = [];
    let timeCross = [];
    let distance = [];
    let elevation = [];
    let reportLabels = [];

    let runActivities = [];
    let crossTrainingActivities = [];

    if (stravaActivities) {
      runActivities = stravaActivities.filter(
        (a) =>
          (a.type && a.type.toLowerCase().includes("run")) ||
          (a.activityType && a.activityType.includes("RUN")),
      );
      crossTrainingActivities = stravaActivities.filter(
        (a) =>
          (a.type && a.type.toLowerCase() !== "run") ||
          (a.activityType && !a.activityType.includes("RUN")),
      );
    }
    if (showWeeklyReport) {
      const weekStartDate = moment(currentStartDate).startOf("isoWeek");
      const dates = [];
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        dates.push(moment(weekStartDate).add(dayIndex, "day"));
        if (runActivities) {
          const tempDate = moment(weekStartDate).add(dayIndex, "day");
          const foundActivities = runActivities.filter(
            (activity) =>
              activity && moment(activity.start_date).isSame(tempDate, "day"),
          );
          if (foundActivities && foundActivities.length > 0) {
            time.push(
              foundActivities
                .map((item) => Math.round(item.elapsed_time / 60) || 0)
                .reduce((a, sum) => a + sum),
            );
            distance.push(
              foundActivities
                .map((item) => Math.round(item.distance / 1000) || 0)
                .reduce((a, sum) => a + sum),
            );
            elevation.push(
              foundActivities
                .map((item) => Math.round(item.total_elevation_gain) || 0)
                .reduce((a, sum) => a + sum),
            );
          } else {
            time.push(0);
            distance.push(0);
            elevation.push(0);
          }
        } else {
          time.push(0);
          distance.push(0);
          elevation.push(0);
        }

        if (crossTrainingActivities) {
          const tempDate = moment(weekStartDate).add(dayIndex, "day");
          const foundActivities = crossTrainingActivities.filter(
            (activity) =>
              activity && moment(activity.start_date).isSame(tempDate, "day"),
          );
          if (foundActivities && foundActivities.length > 0) {
            timeCross.push(
              foundActivities
                .map((item) => Math.round(item.elapsed_time / 60) || 0)
                .reduce((a, sum) => a + sum),
            );
          } else {
            timeCross.push(0);
          }
        } else {
          timeCross.push(0);
        }
      }

      reportLabels = Weekdays.itemsArray.map((item, i) => [
        item.text.substring(0, 3).toUpperCase(),
        `${dates[i].format("D")} ${dates[i].format("MMM")}`,
      ]);
    } else {
      const startingDateMoment = moment(startingDate, dateFormat);
      reportLabels = [];
      for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
        time.push(0);
        distance.push(0);
        elevation.push(0);
        timeCross.push(0);
        reportLabels.push(`W ${weekIndex + 1}`);
      }
      runActivities.forEach((activity) => {
        if (activity) {
          const plannedDay =
            moment(activity.start_date).diff(startingDateMoment, "days") + 1;
          const plannedWeek = Math.floor((plannedDay > 0 ? plannedDay : 0) / 7);
          time[plannedWeek] += Math.round(activity.elapsed_time / 60) || 0;
          distance[plannedWeek] += Math.round(activity.distance / 1000) || 0;
          elevation[plannedWeek] +=
            Math.round(activity.total_elevation_gain) || 0;
        }
      });
      crossTrainingActivities.forEach((activity) => {
        if (activity) {
          const plannedDay =
            moment(activity.start_date).diff(startingDateMoment, "days") + 1;
          const plannedWeek = Math.floor((plannedDay > 0 ? plannedDay : 0) / 7);
          timeCross[plannedWeek] += Math.round(activity.elapsed_time / 60) || 0;
        }
      });
    }

    let plannedTime = 0;
    if (currentPlannedWeek.length > 0 && currentPlannedTotal.length > 0) {
      plannedTime = (showWeeklyReport
        ? currentPlannedWeek
        : currentPlannedTotal
      ).reduce((a, sum) => a + sum);
    }
    const activitiesTime =
      time.length > 0 ? time.reduce((a, sum) => a + sum) : 0;
    const activitiesDistance =
      distance.length > 0 ? distance.reduce((a, sum) => a + sum) : 0;
    const activitiesElevation =
      elevation.length > 0 ? elevation.reduce((a, sum) => a + sum) : 0;
    const activitiesTimeCross =
      timeCross.length > 0 ? timeCross.reduce((a, sum) => a + sum) : 0;

    const distanceData = {
      labels: reportLabels,
      datasets: [
        {
          backgroundColor: "#E7F3FC",
          borderWidth: 0,
          data: distance,
          borderColor: "#E7F3FC",
        },
      ],
    };

    const elevationData = {
      labels: reportLabels,
      datasets: [
        {
          backgroundColor: "#E7F3FC",
          borderWidth: 0,
          data: elevation,
          borderColor: "#E7F3FC",
        },
      ],
    };

    const crossData = {
      labels: reportLabels,
      datasets: [
        {
          backgroundColor: "#E7F3FC",
          borderWidth: 0,
          data: timeCross,
          borderColor: "#E7F3FC",
        },
      ],
    };

    const energyLevelWeekData = {
      labels: Weekdays.itemsArray.map((item, i) => [
        item.text.substring(0, 3).toUpperCase(),
        `${dates[i].format("D")} ${dates[i].format("MMM")}`,
      ]),
      datasets: [
        {
          backgroundColor: "#E7F3FC",
          borderColor: "#E7F3FC",
          borderWidth: 0,
          data: energyLevelWeek,
        },
      ],
    };

    const planReportLabels = [];
    for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
      planReportLabels.push(`W ${weekIndex + 1}`);
    }

    const energyLevelTotalData = {
      labels: planReportLabels,
      datasets: [
        {
          backgroundColor: "#E7F3FC",
          borderWidth: 0,
          data: energyLevelTotal,
          borderColor: "#E7F3FC",
        },
      ],
    };

    const optionsReports = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      elements: {
        line: {
          fill: true,
          tension: 0.4,
          borderWidth: 0,
        },
        point: {
          radius: 0,
        },
      },
      animation: {
        easing: "linear",
        duration: 1000,
      },
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
            callback: function() {
              return "";
            },
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      animation: {
        easing: "linear",
        duration: 1000,
      },
    };

    const radarOptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        r: {
          angleLines: {
            display: false,
          },
        },
      },
    };
    const optionsTotal = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            color: "#DDDDDD",
            drawBorder: false,
          },
          ticks: {
            minor: {
              fontFamily: "Avenir",
              fontSize: 12,
              fontColor: "#9E9E9E",
            },
            padding: 10,
          },
        },
        y: {
          grid: {
            color: "rgba(0, 0, 0, 0.05)",
            zeroLineColor: "rgba(0, 0, 0, 0.05)",
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: 50,
            callback: function(value) {
              return EstimatedTimes.convertMinutesToLabel(value);
            },
            fontFamily: "Avenir",
            fontSize: 12,
            fontColor: "#9E9E9E",
            padding: 10,
          },
        },
      },
      elements: {
        line: {
          fill: "start",
        },
      },
      animation: {
        easing: "linear",
        duration: 1000,
      },
    };

    let doneRate;
    let energyLevelRate;
    let loadRate;
    let rpeRate;

    energyLevelRate = getEnergyLevelRate(days, moment());
    doneRate = getDoneRate(days, moment());
    loadRate = getTrainingLoadRate(days, runActivities, moment(), level);
    rpeRate = getRpeRate(days, moment());

    const radarData = {
      labels: ["Training Load", "RPE", "Mark as done", "EnergyLevel"],
      datasets: [
        {
          data: [
            loadRate[this.state.ratesGran] / 10,
            rpeRate[this.state.ratesGran],
            doneRate[this.state.ratesGran] / 10,
            energyLevelRate.points[this.state.ratesGran],
          ],
          borderColor: "#FF2F00",
          borderWidth: 2,
          backgroundColor: "rgba(255, 47, 0, 0.2)",
          pointBackgroundColor: "#FF2F00",
          pointBorderColor: "#FFF",
          pointHoverBackgroundColor: "#FFF",
          pointHoverBorderColor: "#FF2F00",
        },
      ],
    };

    const weekDurations = getWeekDurations(
      currentTraining,
      selectedAthleteChainedPlans,
    );

    const handleSearch = () => {
      const { athletes, auth } = this.props;
      const { currentFilter, searchResult } = athletes;
      const { searchPhrase } = currentFilter;
      const filters = Object.keys(currentFilter).filter(
        (key) => key !== "searchPhrase",
      );
      const { currentUserGroups } = auth;
      const { currentUser } = auth;
      const { attributes } = currentUser || {};
      const { sub } = attributes;

      const { data } = searchResult || {};

      const isAdmin =
        currentUserGroups &&
        currentUserGroups.findIndex(
          (group) => group === UserGroups.USER_GROUP_ADMIN,
        ) > -1;
      if (isAdmin && filters.length === 0 && !searchPhrase) {
        this.props.dispatch(
          setErrorMessage("Please fill out filters to search athletes"),
        );
      } else {
        this.props.dispatch(athletesSearch(currentFilter, isAdmin, sub, data));
      }
    };

    const changeStatus = async () => {
      const { athletes } = this.props;
      const { cognito_user_sub, checked } = selectedAthlete || {};
      const status = !checked || checked === 0 ? 1 : 0;
      await this.props.dispatch(changeCheckedStatus(cognito_user_sub, status));

      const selectedAthleteCopy = { ...selectedAthlete };
      selectedAthleteCopy.checked = status;

      await this.props.dispatch(updateAthleteDataLocally(selectedAthleteCopy));
    };

    const handleNeedHelp = async () => {
      const { athletes } = this.props;
      const { needHelp, cognito_user_sub } = selectedAthlete || {};
      const status = !needHelp;
      await this.props.dispatch(
        changeNeedsHelpStatus(cognito_user_sub, status),
      );

      const selectedAthleteCopy = { ...selectedAthlete };
      selectedAthleteCopy.needHelp = status;

      await this.props.dispatch(updateAthleteDataLocally(selectedAthleteCopy));
    };

    const changeToCheckStatus = async () => {
      const { athletes } = this.props;
      const { cognito_user_sub, needsCheck } = selectedAthlete || {};
      const status = !needsCheck || needsCheck === 0 ? 1 : 0;
      await this.props.dispatch(
        changeNeedsToCheckStatus(cognito_user_sub, status),
      );

      const selectedAthleteCopy = { ...selectedAthlete };
      selectedAthleteCopy.needsCheck = status;
      await this.props.dispatch(updateAthleteDataLocally(selectedAthleteCopy));
    };

    const comeBack = async () => {
      const { auth, athletes, dispatch, closeDetails } = this.props;
      const { selectedAthlete } = athletes || {};
      const { cognito_user_sub } = selectedAthlete || {};

      const isAdmin =
        currentUserGroups &&
        currentUserGroups.findIndex(
          (group) => group === UserGroups.USER_GROUP_ADMIN,
        ) > -1;

      if (!isAdmin) {
        await this.props.dispatch(
          patchSelectedAthleteWorkoutViewed(cognito_user_sub),
        );
      }

      if (editMode) await dispatch(editingMode(cognito_user_sub, false));
      await this.props.dispatch(clearAthlete({}));
      closeDetails();
    };

    return (
      <div className={classes.container}>
        {selectedAthlete ? (
          <>
            <div className={classes.topContainer}>
              <div className={classes.leftSide}>
                <>
                  <div onClick={comeBack}>
                    <ArrowBackIosIcon></ArrowBackIosIcon>{" "}
                  </div>

                  <Typography variant="body1" className={classes.title}>
                    Overview
                  </Typography>

                  <div className={classes.actions}>
                    <div
                      className={
                        checked === 1 ? classes.selectedCard : classes.card
                      }
                      onClick={changeStatus}
                    >
                      {!loadingChecked ? (
                        <div className={classes.textParent}>
                          <img
                            src={`${process.env.PUBLIC_URL}/emoji_checked.png`}
                            alt="check-image"
                            className={classes.emoji}
                          />
                          <Typography
                            variant="body2"
                            className={
                              !checked || checked === 0
                                ? classes.text
                                : classes.whiteText
                            }
                          >
                            {checked === 1 ? "Unmark" : "Mark"} training as
                            checked
                          </Typography>
                        </div>
                      ) : (
                        <div className={classes.loading}>
                          <CircularProgress
                            variant="indeterminate"
                            size="20px"
                            color="secondary"
                          />
                        </div>
                      )}
                    </div>

                    <div
                      className={
                        needsCheck == 1 ? classes.selectedCard : classes.card
                      }
                      onClick={changeToCheckStatus}
                    >
                      {!loadingNeedsToCheck ? (
                        <div className={classes.textParent}>
                          <img
                            src={`${process.env.PUBLIC_URL}/emoji_needtocheck.png`}
                            alt="needs-to-check-image"
                            className={classes.emoji}
                          />
                          <Typography
                            variant="body2"
                            className={
                              !needsCheck || needsCheck === 0
                                ? classes.text
                                : classes.whiteText
                            }
                          >
                            {" "}
                            {needsCheck === 1 ? "Unmark" : "Mark"} as need to
                            check
                          </Typography>
                        </div>
                      ) : (
                        <div className={classes.loading}>
                          <CircularProgress
                            variant="indeterminate"
                            size="20px"
                            color="secondary"
                          />
                        </div>
                      )}
                    </div>

                    <div
                      className={needHelp ? classes.selectedCard : classes.card}
                      onClick={handleNeedHelp}
                    >
                      {!loadingNeedsHelp ? (
                        <div className={classes.textParent}>
                          <img
                            src={`${process.env.PUBLIC_URL}/help-plan-icon.svg`}
                            alt="check-image"
                            className={classes.emoji}
                          />
                          <Typography
                            variant="body2"
                            className={
                              !needHelp ? classes.text : classes.whiteText
                            }
                          >
                            Need help choosing a plan
                          </Typography>
                        </div>
                      ) : (
                        <div className={classes.loading}>
                          <CircularProgress
                            variant="indeterminate"
                            size="20px"
                            color="secondary"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              </div>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: editMode ? "#1A629B" : "#FF6228",
                      color: "white",
                    }}
                    onClick={this.handleEditingPlan}
                    disabled={editingLoading}
                  >
                    <Typography variant="button" mr="10px">
                      Edit mode
                    </Typography>

                    {editingLoading && (
                      <Box marginLeft={editingLoading ? "10px" : "0px"}>
                        <CircularProgress
                          variant="indeterminate"
                          size="20px"
                          color="white"
                        />
                      </Box>
                    )}
                  </Button>
                </Box>

                <Box marginLeft="10px" display="flex" alignItems="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSave}
                    disabled={saveLoading}
                  >
                    <Typography variant="button" mr="10px">
                      Save Plan
                    </Typography>
                    <Box marginLeft={saveLoading ? "10px" : "0px"}>
                      {saveLoading && (
                        <CircularProgress
                          variant="indeterminate"
                          size="20px"
                          color="white"
                        />
                      )}
                    </Box>
                  </Button>
                </Box>
              </Box>
              {!isAdmin ? (
                <AthletesNotifications
                  currentSelected={true}
                  onOpenChat={this.handleOpenChat}
                ></AthletesNotifications>
              ) : (
                ""
              )}
            </div>
            <Grid container>
              <Grid item xs={2}>
                <AthletesProfile
                  athlete={selectedAthlete}
                  isCoach={false}
                  isAdmin={false}
                  data={energyLevelTotalData}
                  options={optionsTotal}
                  image={image}
                ></AthletesProfile>

                <div>
                  <AthletesNotes
                    notes={athleteNotes}
                    onAddNote={this.handleAddNote}
                    onEditNote={this.handleEditNote}
                    onDeleteNote={this.handleDeleteNote}
                    athlete={cognito_user_sub}
                    type={0}
                  ></AthletesNotes>
                </div>

                <div className={classes.notesContainer}>
                  <div className={classes.notesTitleContainer}>
                    <Typography variant="body1" className={classes.notesTitle}>
                      Notes
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.remindersTitle}
                      onClick={this.openReminders}
                    >
                      Set Reminder
                      <NotificationsNoneOutlinedIcon
                        className={classes.remindersIcon}
                      />
                    </Typography>
                  </div>
                  {latestNote ? (
                    <div className={classes.note} onClick={this.openNotes}>
                      <Typography
                        variant="body1"
                        className={classes.noteText}
                        dangerouslySetInnerHTML={convertToRTFForView(
                          latestNote.text,
                        )}
                      ></Typography>
                    </div>
                  ) : (
                    <Typography
                      variant="body1"
                      className={classes.noteText}
                      onClick={this.openNotes}
                    >
                      + Click to your first note on this athlete
                    </Typography>
                  )}
                </div>
              </Grid>
              {fullTraining ? (
                <Grid item xs={2}>
                  <div className={classes.switchView}>
                    <Typography variant="body2" style={{ fontSize: "11px" }}>
                      My database
                    </Typography>
                    <Switch
                      checked={!this.state.myDataBase}
                      onChange={this.handlerDbSwitch}
                    />
                    <Typography variant="body2" style={{ fontSize: "11px" }}>
                      Full database
                    </Typography>
                  </div>
                  <div className={classes.topBar}>
                    <Typography
                      variant="body1"
                      className={
                        searchTab === 0
                          ? classes.filterSelected
                          : classes.filter
                      }
                      onClick={() => this.setState({ searchTab: 0 })}
                    >
                      Workouts
                    </Typography>
                    <Typography
                      variant="body1"
                      className={
                        searchTab === 1
                          ? classes.filterSelected
                          : classes.filter
                      }
                      onClick={() => this.setState({ searchTab: 1 })}
                    >
                      Blocks
                    </Typography>
                  </div>

                  {searchTab === 0 ? (
                    <AthleteTrainingWorkoutSearch
                      onSelectWorkout={this.handleSelectWorkout}
                      isExternalCoach={this.state.myDataBase}
                    />
                  ) : (
                    <AthleteTrainingBlockSearch
                      athleteTraining={fullTraining}
                      onAddBlock={this.handleAddBlock}
                    />
                  )}
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={8}>
                <Grid container>
                  {fullTraining ? (
                    <>
                      <Grid item xs={3} className={classes.pane}>
                        <div className={classes.counterContainer}>
                          <div className={classes.counterTitleContainer}>
                            <div className={classes.counterIconBkg}>
                              <img
                                src={`${process.env.PUBLIC_URL}/icon-tool.svg`}
                                alt=""
                                className={classes.counterImgIcon}
                              />
                            </div>
                            <Typography
                              variant="body1"
                              className={classes.counterTitle}
                            >
                              Week of training
                            </Typography>
                          </div>
                          <Typography
                            variant="body1"
                            className={classes.counterCount}
                          >
                            {currentWeekStr === "Finished"
                              ? "Finished"
                              : `${currentWeekStr}/${durationInWeeks}`}
                          </Typography>

                          <LinearProgress
                            classes={{
                              colorPrimary: classes.colorPrimary,
                              barColorPrimary: classes.barColorPrimary,
                            }}
                            variant="determinate"
                            value={
                              currentWeekStr === "Finished"
                                ? 100
                                : (currentWeekStr * 100) / durationInWeeks
                            }
                          />

                          <div className={classes.counterTitleContainerSecond}>
                            <div className={classes.counterIconBkg}>
                              <img
                                src={`${process.env.PUBLIC_URL}/icon-tool.svg`}
                                alt=""
                                className={classes.counterImgIcon}
                              />
                            </div>
                            <Typography
                              variant="body1"
                              className={classes.counterTitle}
                            >
                              Running Streak
                            </Typography>
                          </div>
                          <Typography
                            variant="body1"
                            className={classes.counterCount}
                          >
                            {`${runningStreak}/${goal}`}
                          </Typography>

                          <LinearProgress
                            classes={{
                              colorPrimary: classes.colorPrimary,
                              barColorPrimary: classes.barColorPrimary,
                            }}
                            variant="determinate"
                            value={(runningStreak * 100) / goal}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={6} className={classes.pane}>
                        <div className={classes.reportContainer}>
                          <div className={classes.topBar}>
                            <Typography
                              variant="body1"
                              className={classes.topBarTitle}
                            >
                              Training Reports
                            </Typography>
                            <Typography
                              variant="body1"
                              className={
                                showWeeklyReport
                                  ? classes.filterSelected
                                  : classes.filter
                              }
                              onClick={() =>
                                this.setState({ showWeeklyReport: true })
                              }
                            >
                              weekly
                            </Typography>
                            <Typography
                              variant="body1"
                              className={
                                !showWeeklyReport
                                  ? classes.filterSelected
                                  : classes.filter
                              }
                              onClick={() =>
                                this.setState({ showWeeklyReport: false })
                              }
                            >
                              total
                            </Typography>
                          </div>

                          <Grid container spacing={8}>
                            <Grid item xs={6}>
                              <div className={classes.running}>
                                <Typography
                                  variant="body1"
                                  className={classes.reportTitle}
                                >
                                  Running
                                </Typography>
                                <Typography
                                  className={classes.reportValue}
                                  variant="body2"
                                >
                                  {EstimatedTimes.convertMinutesToLabel(
                                    activitiesTime,
                                    true,
                                  ) || "0min"}
                                </Typography>
                                <div className={classes.runningBox}>
                                  <div className={classes.barBox}>
                                    <LinearProgress
                                      classes={{
                                        colorPrimary: classes.colorPrimary,
                                        barColorPrimary:
                                          classes.barColorPrimary,
                                      }}
                                      variant="determinate"
                                      value={
                                        plannedTime
                                          ? (activitiesTime * 100) / plannedTime
                                          : 0
                                      }
                                    />
                                  </div>
                                  <div>
                                    <Typography
                                      className={classes.barBoxLabel}
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {EstimatedTimes.convertMinutesToLabel(
                                        plannedTime,
                                      ) || "0min"}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className={classes.distance}>
                                <Typography
                                  variant="body1"
                                  className={classes.reportTitle}
                                >
                                  Distance
                                </Typography>
                                <Typography
                                  className={classes.reportValue}
                                  variant="body2"
                                >
                                  {`${activitiesDistance} km`}
                                </Typography>
                                <div className={classes.chartBox}>
                                  <Line
                                    data={distanceData}
                                    options={optionsReports}
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className={classes.elevation}>
                                <Typography
                                  variant="body1"
                                  className={classes.reportTitle}
                                >
                                  Elevation
                                </Typography>
                                <Typography
                                  className={classes.reportValue}
                                  variant="body2"
                                >
                                  {`${activitiesElevation} m`}
                                </Typography>
                                <div className={classes.chartBox}>
                                  <Line
                                    data={elevationData}
                                    options={optionsReports}
                                  />
                                </div>
                              </div>
                            </Grid>

                            <Grid item xs={6}>
                              <div className={classes.elevation}>
                                <Typography
                                  variant="body1"
                                  className={classes.reportTitle}
                                >
                                  Cross training
                                </Typography>
                                <Typography
                                  className={classes.reportValue}
                                  variant="body2"
                                >
                                  {EstimatedTimes.convertMinutesToLabel(
                                    activitiesTimeCross,
                                    true,
                                  ) || "0min"}
                                </Typography>
                                <div className={classes.chartBox}>
                                  <Line
                                    data={crossData}
                                    options={optionsReports}
                                  />
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}

                  <Grid
                    item
                    xs={3}
                    className={classes.pane}
                    style={{
                      padding: 4,
                      backgroundColor: "#FFF",
                      borderRadius: "20px",
                      height: "fit-content",
                    }}
                  >
                    <div className={classes.selectParent}>
                      <Select
                        disableUnderline
                        className={classes.selectPlans}
                        id="gran-filter-select"
                        value={ratesGran || "plan"}
                        onChange={(event) =>
                          this.onRequestGranChange(event.target.value)
                        }
                      >
                        <MenuItem value="plan" className={classes.selectItem}>
                          Full Plan
                        </MenuItem>
                        <MenuItem value="month" className={classes.selectItem}>
                          This Month
                        </MenuItem>
                        <MenuItem value="week" className={classes.selectItem}>
                          This Week
                        </MenuItem>
                      </Select>
                    </div>
                    <div style={{ marginBottom: "12px" }}>
                      <Radar
                        options={radarOptions}
                        data={radarData}
                        width={350}
                        height={200}
                      />
                    </div>

                    {isAdmin && (
                      <div
                        className={classes.coachContainer}
                        style={{ marginTop: "12px" }}
                      >
                        <div className={classes.coachTitleContainer}>
                          <Typography
                            variant="body1"
                            className={classes.coachTitle}
                          >
                            Assign Coach
                          </Typography>
                        </div>
                        <div>
                          <AthletePlanAssignCoach
                            coachesList={coachesList}
                            assigningCoach={assigningCoach}
                            assignedCoachSub={coach_cognito_user_sub}
                            onAssignCoach={this.handleAssignCoach}
                            placeholder="Assign a coach"
                          />
                        </div>

                        <div>
                          <AthletePlanAssignCoach
                            coachesList={strengthCoaches}
                            assigningCoach={loadingStrengthAssign}
                            assignedCoachSub={strength_coach_cognito_user_sub}
                            onAssignCoach={this.handleAssignStrengthCoach}
                            placeholder="Strength coach"
                          />
                        </div>
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12} className={classes.pane}>
                    <AthleteTrainingEditor
                      saving={savingTraining}
                      currentWeek={currentWeek}
                      currentTraining={fullTraining}
                      onRequestWeekChange={this.handleRequestWeekChange}
                      onWorkoutAdd={this.handleWorkoutAdd}
                      onWorkoutDelete={this.handleWorkoutDelete}
                      onWorkoutsMoved={this.handleWorkoutsMoved}
                      planName={planName}
                      data={energyLevelWeekData}
                      options={options}
                      onAddWeek={this.handleAddWeek}
                      onAddWeekNext={this.handleAddWeekNext}
                      onAddEasyWeek={this.handleAddEasyWeek}
                      onAddEasyWeekNext={this.handleAddEasyWeekNext}
                      onRemoveWeek={this.handleRemoveWeek}
                      onOpenChat={this.handleOpenChat}
                      onRemoveChainedPlan={this.handleRemoveChainedPlan}
                      weekDurations={weekDurations}
                      monthlyTraining={this.state.monthDays}
                      monthsSelect={this.state.monthsSelect}
                      monthsLength={monthsLength}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <SnackBarMessageDisplay
              onHideMessage={this.handleHideMessage}
              errorMessage={errorMessage}
              successMessage={successMessage}
            />
            <WorkoutCommentEditor
              onClose={this.handleWorkoutCommentDialogClose}
              onSaveComment={this.handleWorkoutCommentDialogSave}
              isOpen={selectedWorkoutForComment ? true : false}
              workout={selectedWorkoutForComment}
            />
          </>
        ) : (
          <div className={classes.loading}>
            <CircularProgress
              variant="indeterminate"
              size="20px"
              color="secondary"
            />
          </div>
        )}
        <Drawer anchor="right" open={notesOpen} onClose={this.closeNotes}>
          <AthletesNotes
            notes={athleteNotes}
            onAddNote={this.handleAddNote}
            onEditNote={this.handleEditNote}
            onDeleteNote={this.handleDeleteNote}
            athlete={cognito_user_sub}
            type={0}
          ></AthletesNotes>
        </Drawer>

        <Drawer
          anchor="right"
          open={remindersOpen}
          onClose={this.closeReminders}
        >
          <AthletesNotes
            notes={reminders}
            onAddNote={this.handleAddNote}
            onEditNote={this.handleEditNote}
            onDeleteNote={this.handleDeleteNote}
            athlete={cognito_user_sub}
            type={1}
          ></AthletesNotes>
        </Drawer>
        <AthletesChat
          open={chatOpen}
          onClose={this.handleCloseChat}
          workout={chatWorkout}
          level={level}
        ></AthletesChat>
      </div>
    );
  }
}

AthletesDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  closeDetails: PropTypes.func,
};

export default connect((store) => {
  return {
    auth: store.auth,
    athletes: store.athletes,
    subcategories: store.subcategories,
    userPlans: store.userPlans,
  };
})(withStyles(styles)(AthletesDetail));
