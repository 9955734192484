import React from "react";
import { Typography, List, ListItem, Paper } from "@material-ui/core";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  paperContainer: {
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
    padding: "10px",
    marginBottom: "10px",
  },
  timelineOpossiteContent: {
    maxWidth: "1px",
    padding: "2px",
  },
  timelineDot: {
    border: "none",
    background: "none",
    elevation: 0,
  },
  timelineDotMobile: {},
  timelineRange: {
    fontSize: "28px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  timelineRangeMobile: {
    fontSize: "16px",
  },
  listContainer: {
    listStyleType: "disc",
  },
  listItem: {
    display: "list-item",
    listStylePosition: "inside",
    textIndent: "-1.5em",
    paddingLeft: "1em",
    fontSize: "22px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#12151B",
  },
  listItemMobile: {
    fontSize: "14px",
  },
});
const CheckoutTimelineItem = ({ timeRange, items, icon, mobile = false }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paperContainer}>
      <TimelineItem>
        <TimelineOppositeContent className={classes.timelineOpossiteContent} />
        <TimelineSeparator>
          <TimelineDot
            variant="outlined"
            className={[
              classes.timelineDot,
              mobile && classes.timelineDotMobile,
            ]
              .filter(Boolean)
              .join(" ")}
          >
            {icon}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            className={[
              classes.timelineRange,
              mobile && classes.timelineRangeMobile,
            ]
              .filter(Boolean)
              .join(" ")}
          >
            {timeRange}
          </Typography>
          <List className={classes.listContainer}>
            {items.map((item, index) => (
              <ListItem
                key={index}
                component={"Typography"}
                className={[mobile && classes.listItemMobile, classes.listItem]
                  .filter(Boolean)
                  .join(" ")}
              >
                {item}
              </ListItem>
            ))}
          </List>
        </TimelineContent>
      </TimelineItem>
    </Paper>
  );
};

export default CheckoutTimelineItem;
