const getSubcategoryTitle = (subcategories, id, lang = "en") => {
  if (subcategories && subcategories.length > 0) {
    const subcategory = subcategories.find(
      (subcategory) => subcategory.id === id,
    );
    if (subcategory) {
      return subcategory.title[lang]
        ? subcategory.title[lang]
        : subcategory.title["en"];
    } else {
      return "Free";
    }
  } else {
    return "Free";
  }
};

export { getSubcategoryTitle };
