import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  CircularProgress,
  Button,
  Avatar,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { withTranslation } from "react-i18next";
import { getExplorePost } from "../../actions/trail-head-action";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { logEvent } from "../../lib/events-helper";
import { connect } from "react-redux";
import history from "../../lib/history-helper";
import ExplorePost from "./explore-post";
import { getVideoIframe, getYoutubeId } from "../../lib/youtube-helper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  updatePlan,
  setSuccessMessage,
  clearSuccessMessage,
  clearErrorMessage,
} from "../../actions/user-plans-action";
import AppAuthenticator from "../app-authenticator";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import MobileShare from "../common/mobile-share";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing";
import objectToGetParams from "../../lib/objectToGetParams";
import { Capacitor } from "@capacitor/core";
import { newPost } from "../../actions/trail-head-action";
import SnackBarMessageDisplay from "../common/snack-bar-message-display";
import { Clipboard } from "@capacitor/clipboard";

const styles = (theme) => ({
  container: {
    width: "100%",

    overflow: "scroll",

    position: "relative",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100vh",
    },
  },

  postContainer: {
    position: "relative",
    maxWidth: 620,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  postImage: {
    width: "100%",
    marginBottom: 10,
    borderRadius: 12,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },

  postDesciption: {
    padding: "0 10px 10px 10px",
  },

  postMetadataRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },

  postMediaType: {
    background: "rgba(255, 87, 34, 0.24)",
    borderRadius: 4,
    fontWeight: "normal",
    fontSize: 14,
    padding: "2px 4px",
  },

  timeLevelContainer: {
    display: "flex",
    alignItems: "center",
  },

  workoutLevel: {
    background: "rgba(255, 87, 34, 0.24)",
    borderRadius: 4,
    fontWeight: "normal",
    fontSize: 14,
    padding: "2px 4px",
    marginLeft: "auto",
    marginRight: 10,
  },

  postTime: {
    fontWeight: "normal",
    fontSize: 12,
    marginLeft: 10,
  },

  postTitle: {
    fontSize: 24,
    fontWeight: 500,
    margin: "0 10px 10px 10px",
  },

  postAction: {
    boxShadow: "none",
    borderRadius: 50,
    marginLeft: 10,
  },

  backIcon: {
    top: 0,
    left: 5,
    cursor: "pointer",
    position: "absolute",
    background: "rgba(255, 255, 255, 0.7)",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    paddingTop: 3,
    paddingLeft: 7,
    paddingBottom: 3,
    borderRadius: "100%",
    marginTop: 5,
    zIndex: 10,
    [theme.breakpoints.up("md")]: {
      color: "#000000",
      position: "initial",
    },
  },

  loadingContainer: {
    textAlign: "center",
  },

  workoutStats: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: 10,
    padding: "10px 20px",
    background: "#F9F9FB",
  },

  workoutStat: {
    textAlign: "center",
  },

  workoutStatValue: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "Avenir",
  },

  workoutStatLabel: {
    fontWeight: "normal",
    fontSize: 14,
    fontFamily: "Avenir",
  },

  vDivider: {
    borderLeft: "1px solid #E0E0E0",
    height: 24,
  },

  participants: {
    margin: "0 10px 10px 10px",
    padding: 10,
    background: "#F9F9FB",
    borderRadius: 4,
  },

  participantsTitle: {
    fontWeight: "bold",
    fontSize: 18,
  },

  participant: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },

  participantName: {
    fontWeight: 500,
    fontSize: 14,
    marginLeft: 8,
  },

  shareCont: {
    display: "flex",
    alignItems: "center",
    margin: 10,
    padding: 10,
    borderTop: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
  },

  shareIcon: {
    marginLeft: "auto",
    width: 18,
  },

  share: {
    marginLeft: 10,
    fontWeight: "normal",
    fontSize: 16,
  },

  relatedTitle: {
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: 10,
  },

  postsContainer: {
    "&::-webkit-scrollbar ": {
      display: "none",
    },

    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",
  },

  videoContainer: {},
  video: {
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: 10,
  },

  audioContainer: {
    position: "fixed",
    bottom: 0,
    background: "#ffffff",
    width: "calc(100% - 20px)",
    padding: 10,
    zIndex: 10000,
    boxShadow: "0px -1px 3px rgb(50 50 50 / 40%)",
  },

  audioContainerTop: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },

  audioImage: {
    width: 48,
    height: 40,
    borderRadius: 4,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  audioTitle: {
    fontWeight: 500,
    fontSize: 12,
    margin: "0 12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  favContainer: {
    background: "#FFFFFF",
    borderRadius: 24,
    position: "absolute",
    top: 5,
    right: 5,
    padding: "2px 10px",
    zIndex: 100,
  },

  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },

  authBlock: {
    background:
      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },

  postImageContainer: {
    position: "relative",
  },

  videoPlayCont: {
    position: "absolute",
    top: "calc(50% - 10px)",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgb(255 99 39 / 80%)",
    borderRadius: "100%",
    padding: "6px 7px 2px",
  },

  videoPlay: {
    color: "#fff",
    fontSize: 50,
  },

  relatedCont: {
    background: "#F9F9FB",
    paddingTop: 10,
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      paddingTop: 0,
    },
  },

  collab_img: {
    height: 20,
    background: "#fff",
    borderRadius: 24,
    padding: "4px 8px",
  },

  full: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#FF5722",
    marginLeft: 10,
  },
});

class ExploreFullPost extends Component {
  state = {
    openVideo: false,
    openAudio: false,
    audioPlaying: false,
    openSignUp: false,
    signUpTimeout: null,
    openShare: false,
  };

  async componentDidMount() {
    const { match } = this.props;

    const { params } = match;
    const { id } = params;

    this.setState({
      ...this.state,
      openVideo: false,
      openAudio: false,
    });

    if (id) {
      this.props.dispatch(getExplorePost(id));
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.stop();
    }
  }

  handleBack = () => {
    history.goBack();
  };

  openMedia = () => {
    const { auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      const { trailHead } = this.props;
      const { currentExplorePost } = trailHead;

      if (currentExplorePost && currentExplorePost.media) {
        if (currentExplorePost.media_type.toLowerCase() === "video") {
          this.setState({
            ...this.state,
            openVideo: true,
          });
        }
        if (
          currentExplorePost.media_type.toLowerCase() === "audio" &&
          !this.state.audioPlaying
        ) {
          this.setState({
            ...this.state,
            openAudio: true,
            audioPlaying: true,
          });
          setTimeout(() => this.rerender(), 100);
        }
      }
    } else {
      this.handleOpenSignUp();
    }
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      openVideo: false,
      openAudio: false,
    });
  };

  getDuration = () => {
    return this.player ? this.player.duration() : 1;
  };

  getSeek = () => {
    return this.player ? this.player.seek() : 0;
  };

  setSeek = (pos) => {
    this.player.seek(pos);
  };

  playAudio = () => {
    this.setState({
      ...this.state,
      audioPlaying: true,
    });
    setTimeout(() => this.rerender(), 100);
  };

  stopAudio = () => {
    clearTimeout(this.state.audioRerender);
    this.setState({
      ...this.state,
      audioPlaying: false,
      audioRerender: null,
    });
  };

  rerender = () => {
    const timeout = setTimeout(() => this.rerender(), 100);
    this.setState({
      ...this.state,
      audioRerender: timeout,
    });
  };

  toggleFavorite = (event, id, type) => {
    event.preventDefault();
    event.stopPropagation();
    const { auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      const { userPlans } = this.props;
      const { currentPlan } = userPlans;
      const { exploreFavorites, cognito_user_sub } = currentPlan || {};
      if (
        exploreFavorites &&
        exploreFavorites.length &&
        exploreFavorites.find((e) => e.id === id)
      ) {
        const newExploreFavorites = exploreFavorites.filter((f) => f.id !== id);
        this.props.dispatch(
          updatePlan(
            {
              cognito_user_sub: cognito_user_sub,
              exploreFavorites: newExploreFavorites,
            },
            false,
            false,
          ),
        );
      } else {
        this.props.dispatch(
          updatePlan(
            {
              cognito_user_sub: cognito_user_sub,
              exploreFavorites:
                exploreFavorites && exploreFavorites.length
                  ? [{ id, type }, ...exploreFavorites]
                  : [{ id, type }],
            },
            false,
            false,
          ),
        );
      }
    } else {
      this.handleOpenSignUp();
    }
  };

  handleOpenSignUp = () => {
    localStorage.removeItem("error");
    if (this.state.signUpTimeout) {
      clearTimeout(this.state.signUpTimeout);
    }
    this.setState({
      ...this.state,
      openSignUp: true,
      signUpTimeout: null,
    });
  };

  handleCloseSignUp = () => {
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignUp: false,
    });
  };

  handleCloseShare = () => {
    this.setState({
      ...this.state,
      openShare: false,
    });
  };

  handleOpenShare = () => {
    this.setState({
      ...this.state,
      openShare: true,
    });
  };

  isMobileOrTablet = () => {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
  };

  handleShare = async (type, post) => {
    const { i18n, t, trailHead } = this.props;
    const { language } = i18n;

    const { currentPublicGroup } = trailHead;

    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    switch (type) {
      case "instagram":
        await Clipboard.write({
          string:
            t("share title", {
              title: post.title[languageDetector]
                ? post.title[languageDetector]
                : post.title["en"],
            }) +
            ": " +
            `https://app.vert.run/explore/post/${post.id}`,
        });
        this.props.dispatch(
          setSuccessMessage(
            t(
              "Post message copied to clipboard. Paste it on Instagram to complete your post",
            ),
          ),
        );
        setTimeout(() => {
          SocialSharing.shareViaInstagram(
            t("share title", {
              title: post.title[languageDetector]
                ? post.title[languageDetector]
                : post.title["en"],
            }) +
              ": " +
              `https://app.vert.run/explore/post/${post.id}`,
            [post.image_url],
          );
        }, 1000);
        this.handleCloseShare();
        break;
      case "email":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaEmail(
            `https://app.vert.run/explore/post/${post.id}`,
            t("share title", {
              title: post.title[languageDetector]
                ? post.title[languageDetector]
                : post.title["en"],
            }),
            null,
            null,
            null,
            [post.image_url],
          );
        } else {
          window.open(
            "mailto:" +
              objectToGetParams({
                subject: t("share title", {
                  title: post.title[languageDetector]
                    ? post.title[languageDetector]
                    : post.title["en"],
                }),
                body: `https://app.vert.run/explore/post/${post.id}`,
              }),
          );
        }
        this.handleCloseShare();
        break;

      case "facebook":
        this.props.dispatch(
          setSuccessMessage(
            t(
              "Post message copied to clipboard. Paste it on Facebook to complete your post",
            ),
          ),
        );
        if (!Capacitor.isNativePlatform()) {
          navigator.clipboard.writeText(
            t("share title", {
              title: post.title[languageDetector]
                ? post.title[languageDetector]
                : post.title["en"],
            }) +
              ": " +
              `https://app.vert.run/explore/post/${post.id}`,
          );
        } else {
          await Clipboard.write({
            string:
              t("share title", {
                title: post.title[languageDetector]
                  ? post.title[languageDetector]
                  : post.title["en"],
              }) +
              ": " +
              `https://app.vert.run/explore/post/${post.id}`,
          });
        }
        setTimeout(() => {
          if (Capacitor.isNativePlatform()) {
            SocialSharing.shareViaFacebook(
              t("share title", {
                title: post.title[languageDetector]
                  ? post.title[languageDetector]
                  : post.title["en"],
              }) +
                ": " +
                `https://app.vert.run/explore/post/${post.id}`,
              [post.image_url],
            );
          } else {
            window.open(
              "http://www.facebook.com/sharer.php?u=" +
                encodeURIComponent(post.image_url),
              "sharer",
              "toolbar=0,status=0,width=626,height=436",
            );
          }
        }, 1000);
        this.handleCloseShare();
        break;

      case "whatsapp":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaWhatsApp(
            t("share title", {
              title: post.title[languageDetector]
                ? post.title[languageDetector]
                : post.title["en"],
            }) +
              ": " +
              `https://app.vert.run/explore/post/${post.id}`,
            Capacitor.getPlatform() === "ios" ? [] : [post.image_url],
          );
        } else {
          window.open(
            "https://" +
              (this.isMobileOrTablet() ? "api" : "web") +
              ".whatsapp.com/send" +
              objectToGetParams({
                text:
                  t("share title", {
                    title: post.title[languageDetector]
                      ? post.title[languageDetector]
                      : post.title["en"],
                  }) +
                  ": " +
                  `https://app.vert.run/explore/post/${post.id}`,
              }),
          );
        }
        this.handleCloseShare();
        break;

      case "twitter":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaTwitter(
            t("share title", {
              title: post.title[languageDetector]
                ? post.title[languageDetector]
                : post.title["en"],
            }) +
              ": " +
              `https://app.vert.run/explore/post/${post.id}`,
            [post.image_url],
          );
        } else {
          window.open(
            "http://twitter.com/share?" +
              "text=" +
              encodeURIComponent(
                t("share title", {
                  title: post.title[languageDetector]
                    ? post.title[languageDetector]
                    : post.title["en"],
                }) +
                  ": " +
                  `https://app.vert.run/explore/post/${post.id}`,
              ),
          );
        }
        this.handleCloseShare();
        break;

      case "trailhead":
        this.props.dispatch(
          newPost(
            t("share title", {
              title: post.title[languageDetector]
                ? post.title[languageDetector]
                : post.title["en"],
            }),
            `https://app.vert.run/explore/post/${post.id}`,
            post.image_url,
          ),
        );
        history.push(
          currentPublicGroup
            ? `/trailhead/group/${currentPublicGroup.id}`
            : `/trailhead`,
        );
        this.handleCloseShare();
        break;

      case "copylink":
        this.props.dispatch(
          setSuccessMessage(t("Post link copied to clipboard")),
        );
        navigator.clipboard.writeText(
          `https://app.vert.run/explore/post/${post.id}`,
        );
        break;

      default:
        break;
    }
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  render() {
    const { t, classes, trailHead, i18n, userPlans, width, auth } = this.props;
    const {
      loadingExplorePost,
      currentExplorePost,
      currentExplorePostRelated,
    } = trailHead;

    const { currentPlan, successMessage, errorMessage } = userPlans;
    const { exploreFavorites } = currentPlan || {};

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const { openVideo, openAudio, openShare, openSignUp } = this.state;

    const isInMobileViewSm = width === "sm" || width === "xs";
    const videoWidth = isInMobileViewSm
      ? window.innerWidth
      : Math.min(window.innerWidth, 620);

    const { isAuthenticated } = auth;

    const media =
      currentExplorePost &&
      currentExplorePost.video &&
      currentExplorePost.video[language]
        ? currentExplorePost.video[language]
        : currentExplorePost && currentExplorePost.media
        ? currentExplorePost.media
        : null;

    return (
      <div className={classes.container}>
        {isInMobileViewSm ? (
          <ArrowBackIosIcon
            onClick={() => this.handleBack()}
            className={classes.backIcon}
            fontSize="small"
            id="back_video_pannel"
          />
        ) : (
          ""
        )}
        {loadingExplorePost || !currentExplorePost ? (
          <>
            <div className={classes.loadingContainer}>
              <CircularProgress color="primary" />
            </div>
          </>
        ) : (
          <div className={classes.postContainer}>
            {isInMobileViewSm ? (
              <ArrowBackIosIcon
                onClick={() => this.handleBack()}
                className={classes.backIcon}
                fontSize="small"
              />
            ) : (
              ""
            )}
            <div className={classes.favContainer}>
              <img
                src={`${process.env.PUBLIC_URL}/icon-star-${
                  exploreFavorites &&
                  exploreFavorites.length &&
                  exploreFavorites.find((e) => e.id === currentExplorePost.id)
                    ? "filled"
                    : "outline"
                }.svg`}
                alt=""
                onClick={(ev) =>
                  this.toggleFavorite(
                    ev,
                    currentExplorePost.id,
                    currentExplorePost.type,
                  )
                }
                className={classes.fav}
              />
            </div>

            {/*<Helmet>
              <meta property="og:title" content={currentExplorePost.og_title} />
              <meta
                property="og:description"
                content={currentExplorePost.og_description}
              />

              <meta
                property="og:image"
                content={currentExplorePost.image_url}
              />
              <title>{currentExplorePost.og_title}</title>
            </Helmet>*/}

            {openVideo ? (
              <div className={classes.videoContainer}>
                {media ? (
                  getYoutubeId(media) ? (
                    getVideoIframe(media)
                  ) : (
                    <video
                      width={videoWidth}
                      height={videoWidth * 0.56}
                      controls
                      autoPlay
                    >
                      <source src={media} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className={classes.postImageContainer}>
                <img
                  className={classes.postImage}
                  src={currentExplorePost.image_url}
                  alt=""
                />
                {currentExplorePost.media_type.toLowerCase() === "video" ? (
                  <div
                    className={classes.videoPlayCont}
                    onClick={() => this.openMedia()}
                  >
                    <PlayArrowIcon className={classes.videoPlay} />
                  </div>
                ) : (
                  ""
                )}

                {currentExplorePost.collab_url &&
                currentExplorePost.collab_image_url ? (
                  <div
                    style={{
                      textDecoration: "none",
                      position: "absolute",
                      bottom: 15,
                      left: 5,
                    }}
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      window.open(currentExplorePost.collab_url, "_blank");
                    }}
                  >
                    <img
                      src={currentExplorePost.collab_image_url}
                      alt=""
                      className={classes.collab_img}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            <div className={classes.timeLevelContainer}>
              {currentExplorePost.time ? (
                <Typography variant="body1" className={classes.postTime}>
                  {currentExplorePost.time}
                </Typography>
              ) : (
                ""
              )}

              {currentExplorePost.workout_level ? (
                <Typography variant="body1" className={classes.workoutLevel}>
                  {currentExplorePost.workout_level[languageDetector]
                    ? currentExplorePost.workout_level[languageDetector]
                    : currentExplorePost.workout_level["en"]}
                </Typography>
              ) : (
                ""
              )}
            </div>

            <Typography variant="body1" className={classes.postTitle}>
              {currentExplorePost.title &&
              currentExplorePost.title[languageDetector]
                ? currentExplorePost.title[languageDetector]
                : currentExplorePost.title["en"]}
            </Typography>

            {currentExplorePost.media_type &&
            currentExplorePost.media_type.toLowerCase() === "audio" ? (
              <Button
                color="primary"
                variant="contained"
                className={classes.postAction}
                onClick={() => this.openMedia()}
              >
                <PlayArrowIcon fontSize="small" style={{ marginRight: 5 }} />
                {t("Listen")}
              </Button>
            ) : (
              ""
            )}

            {currentExplorePost.workout_calories ||
            currentExplorePost.workout_time ||
            currentExplorePost.workout_type ? (
              <div className={classes.workoutStats}>
                {currentExplorePost.workout_time ? (
                  <div className={classes.workoutStat}>
                    <Typography
                      variant="body1"
                      className={classes.workoutStatLabel}
                    >
                      {t("Duration")}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.workoutStatValue}
                    >
                      {currentExplorePost.workout_time}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}

                {currentExplorePost.workout_time ? (
                  <div className={classes.vDivider}></div>
                ) : (
                  ""
                )}

                {currentExplorePost.workout_type ? (
                  <div className={classes.workoutStat}>
                    <Typography
                      variant="body1"
                      className={classes.workoutStatLabel}
                    >
                      {t("Type")}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.workouttatValue}
                    >
                      {currentExplorePost.workout_type[languageDetector]
                        ? currentExplorePost.workout_type[languageDetector]
                        : currentExplorePost.workout_type["en"]}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}

                {currentExplorePost.workout_type ? (
                  <div className={classes.vDivider}></div>
                ) : (
                  ""
                )}

                {currentExplorePost.workout_calories ? (
                  <div className={classes.workoutStat}>
                    <Typography
                      variant="body1"
                      className={classes.workoutStatLabel}
                    >
                      {t("Calories")}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.workoutStatValue}
                    >
                      {currentExplorePost.workout_calories}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            <div
              style={{
                height: isAuthenticated ? "initial" : 200,
                overflow: isAuthenticated ? "initial" : "hidden",
                position: "relative",
              }}
            >
              <Typography
                className={classes.postDesciption}
                dangerouslySetInnerHTML={{
                  __html:
                    currentExplorePost.text &&
                    currentExplorePost.text[languageDetector]
                      ? currentExplorePost.text[languageDetector]
                      : currentExplorePost.text.en
                      ? currentExplorePost.text.en
                      : "",
                }}
              ></Typography>
              {isAuthenticated ? "" : <div className={classes.authBlock}></div>}
            </div>

            {!isAuthenticated ? (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <Button
                  color="primary"
                  className={classes.readMoreButton}
                  onClick={() => this.handleOpenSignUp()}
                >
                  {t("Read more")}
                </Button>
              </div>
            ) : (
              ""
            )}

            {currentExplorePost.participants &&
            currentExplorePost.participants.length ? (
              <div className={classes.participants}>
                <Typography
                  variant="body1"
                  className={classes.participantsTitle}
                >
                  {t("Participants")}
                </Typography>

                {currentExplorePost.participants.map((participant, i) => {
                  return (
                    <div key={i} className={classes.participant}>
                      <Avatar
                        src={participant.image_url}
                        className={classes.participantImage}
                        style={{
                          width: 32,
                          height: 32,
                        }}
                      />

                      <Typography
                        variant="body1"
                        className={classes.participantName}
                      >
                        {participant.name}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}

            <div
              className={classes.shareCont}
              onClick={() => this.handleOpenShare()}
            >
              {currentExplorePost.collab_url &&
              currentExplorePost.collab_image_url ? (
                <img
                  src={`${process.env.PUBLIC_URL}/icon-full-article.svg`}
                  alt=""
                  className={classes.fullIcon}
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    window.open(currentExplorePost.collab_url, "_blank");
                  }}
                />
              ) : (
                ""
              )}
              {currentExplorePost.collab_url &&
              currentExplorePost.collab_image_url ? (
                <Typography
                  variant="body1"
                  className={classes.full}
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    window.open(currentExplorePost.collab_url, "_blank");
                  }}
                >
                  {t("Check the full article")}
                </Typography>
              ) : (
                ""
              )}

              <img
                src={`${process.env.PUBLIC_URL}/icon-share.svg`}
                alt=""
                className={classes.shareIcon}
              />
              <Typography variant="body1" className={classes.share}>
                {t("Share")}
              </Typography>
            </div>
          </div>
        )}
        {openAudio ? (
          <div className={classes.audioContainer}>
            <div className={classes.audioContainerTop}>
              <div
                className={classes.audioImage}
                style={{
                  backgroundImage: `url(${currentExplorePost.image_url})`,
                }}
              ></div>

              <Typography variant="body1" className={classes.audioTitle}>
                {currentExplorePost.title &&
                currentExplorePost.title[languageDetector]
                  ? currentExplorePost.title[languageDetector]
                  : currentExplorePost.title["en"]}
              </Typography>
            </div>

            <AudioPlayer
              autoPlay
              src={
                currentExplorePost && currentExplorePost.media
                  ? currentExplorePost.media
                  : ""
              }
              onPlay={(e) => console.log("onPlay")}
              // other props here
            />
          </div>
        ) : (
          ""
        )}

        {currentExplorePostRelated ? (
          <div className={classes.relatedCont}>
            <Typography variant="body1" className={classes.relatedTitle}>
              {t("Related stories")}
            </Typography>

            <div className={classes.postsContainer}>
              {currentExplorePostRelated
                ? currentExplorePostRelated.map((post) => {
                    return (
                      <ExplorePost
                        key={post.id}
                        post={post}
                        languageDetector={languageDetector}
                        onToggleFavorite={this.toggleFavorite}
                        exploreFavorites={exploreFavorites}
                      ></ExplorePost>
                    );
                  })
                : ""}
            </div>
          </div>
        ) : (
          ""
        )}

        <Dialog
          open={openSignUp}
          onClose={this.handleCloseSignUp}
          aria-labelledby="signUp"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signupdialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseSignUp}
              />
            </div>
            <AppAuthenticator initialState="signUp" />
          </DialogContent>
        </Dialog>
        <Dialog
          open={openShare}
          onClose={this.handleCloseShare}
          maxWidth="md"
          classes={{
            paper: classes.dialogPaper,
          }}
        >
          <DialogContent id="signupdialog" className={classes.noPadding}>
            <MobileShare
              title={t("Share on")}
              onClose={this.handleCloseShare}
              onShare={(type) => this.handleShare(type, currentExplorePost)}
              trailhead={false}
              challengeToShare={null}
              copylink={true}
            ></MobileShare>
          </DialogContent>
        </Dialog>
        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      </div>
    );
  }
}

ExploreFullPost.propTypes = {
  post: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string,
  t: PropTypes.object.isRequired,
};

export default connect((store) => {
  return {
    auth: store.auth,
    trailHead: store.trailHead,
    userPlans: store.userPlans,
    subscriptions: store.subscriptions,
  };
})(
  withTranslation(["trailhead"])(
    withWidth()(withStyles(styles, { withTheme: true })(ExploreFullPost)),
  ),
);
