import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import RouterLink from "../router-link";
import { useTranslation } from "react-i18next";
import { palette } from "../../../theme/palette";

const CardNeedAPlan = () => {
  const classes = useStyles();
  const { t } = useTranslation("training");

  return (
    <Box className={`${classes.marginY} ${classes.youNeedAplan}`}>
      <Typography className={classes.youNeedAplanTitle}>
        {t("training.needAplan.p1")}
      </Typography>
      <Typography className={classes.youNeedAplanSubtitle}>
        {t("training.needAplan.p2")}{" "}
        <RouterLink to="/chooseplan" className={classes.youNeedAplanLink}>
          {t("training.needAplan.here")}
        </RouterLink>
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  marginY: {
    marginTop: 15,
    marginBottom: 15,
  },
  youNeedAplan: {
    padding: 16,
    boxShadow: palette.boxShadow,
    borderRadius: 4,
    backgroundColor: palette.white,
  },
  youNeedAplanTitle: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 20,
  },
  youNeedAplanLink: {
    color: palette.lightBlue,
    fontWeight: 700,
    textDecoration: "underline",
  },
  youNeedAplanSubtitle: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 400,
  },
});

export default CardNeedAPlan;
