import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { withTranslation } from "react-i18next";
import history from "../../lib/history-helper";
import { setPlanToSelect } from "../../actions/user-plans-action";
import WorkoutPlans from "../../constants/workout-plans";
import { connect } from "react-redux";

const styles = (theme) => ({
  postContainer: {
    background: "#fff",
    borderRadius: 12,
    display: "inline-block",
    width: "90%",
    verticalAlign: "top",
    whiteSpace: "break-spaces",
    minHeight: 395,
    cursor: "pointer",
    position: "relative",
    marginTop: 10,
    marginRight: 0,
    marginBottom: 10,
    marginLeft: 20,

    "&:last-child": {
      marginRight: 60,
    },

    "&:first-child": {
      marginLeft: 60,
    },

    [theme.breakpoints.down("md")]: {
      minHeight: 298,

      margin: "10px 0 10px 20px",

      "&:last-child": {
        marginRight: 10,
      },

      "&:first-child": {
        margin: "10px 0 10px 10px",
      },
    },
  },

  postContainerBlock: {
    background: "#fff",
    borderRadius: 12,
    display: "inline-block",
    width: "90%",
    verticalAlign: "top",
    whiteSpace: "break-spaces",
    minHeight: 395,
    cursor: "pointer",
    position: "relative",
    marginTop: 10,
    marginRight: 0,
    marginBottom: 10,
    marginLeft: 20,

    [theme.breakpoints.down("md")]: {
      minHeight: 298,

      margin: "10px 0 10px 10px",
    },
  },

  postImage: {
    height: 248,
    borderRadius: "12px 12px 0 0",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",

    [theme.breakpoints.down("md")]: {
      height: 124,
    },
  },

  postDesciption: {
    padding: 16,
  },

  postMetadataRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },

  postMediaType: {
    background: "rgba(255, 87, 34, 0.24)",
    borderRadius: 4,
    fontWeight: "normal",
    fontSize: 14,
    padding: "2px 4px",
  },

  postTime: {
    fontWeight: "normal",
    fontSize: 12,
    marginLeft: "auto",
  },

  postTitle: {
    fontWeight: 500,
    fontSize: 18,
  },

  postAction: {
    marginTop: 5,
    fontWeight: 500,
    fontSize: 14,
    color: "#E16327",
  },

  favContainer: {
    background: "#FFFFFF",
    borderRadius: 24,
    position: "absolute",
    top: 5,
    right: 5,
    padding: "2px 10px",
    zIndex: 10,
  },

  desktopFeatured: {
    position: "absolute",
    bottom: 50,
    left: 24,
  },

  collab_img: {
    height: 20,
    background: "#fff",
    borderRadius: 24,
    padding: "4px 8px",
  },
});

class ExplorePost extends Component {
  openPost = (id) => {
    const { post } = this.props;

    if (post.type === "Training plan") {
      this.goToPlan(id);
    } else {
      const loc = window.location.pathname;
      history.push(`/explore/post/${id}`);
      if (loc.startsWith("/explore/post")) {
        history.go();
      }
    }
  };

  goToPlan = (planId) => {
    const { auth, onOpenAuth, subcategories, subscriptions } = this.props;
    const { currentUser, isAuthenticated } = auth;
    const { latestExpired, loadingLatest, latest } = subscriptions;

    const { data } = subcategories;

    const category = data ? data.find((c) => c.id === planId) : null;

    if (category) {
      const { plan, id } = category;

      if (!isAuthenticated) {
        this.props.dispatch(
          setPlanToSelect({
            plan,
            id,
          }),
        );
        onOpenAuth();
        return;
      }
      const { attributes } = currentUser || {};
      const { sub, email } = attributes;
      this.props.dispatch(
        setPlanToSelect({
          plan,
          id,
          cognito_user_sub: sub,
          cognito_user_email: email,
        }),
      );
      setTimeout(() => {
        if (!loadingLatest) {
          const redirectPath =
            plan === WorkoutPlans.FREE
              ? `/chooseplan/${plan}/${id}`
              : latestExpired || !latest
              ? `/subscription/${plan}`
              : `/chooseplan/${plan}/${id}`;
          history.push(redirectPath);
        }
      }, 100);
    }
  };

  render() {
    const {
      t,
      classes,
      post,
      languageDetector,
      isBlock = false,
      onToggleFavorite,
      exploreFavorites,
      width,
      isFeatured = false,
    } = this.props;

    const isInMobileView = width === "sm" || width === "xs";

    return (
      <div
        className={isBlock ? classes.postContainerBlock : classes.postContainer}
        style={{
          width: isBlock
            ? isInMobileView || isFeatured
              ? isInMobileView
                ? "calc(100% - 20px)"
                : "calc(100% - 120px)"
              : "31.5%"
            : isInMobileView
            ? "90%"
            : "30%",
          display: isBlock ? "block" : "inline-block",
          minHeight: isBlock ? "initial" : isInMobileView ? 298 : 395,
        }}
        onClick={() => this.openPost(post.id)}
      >
        <div className={classes.favContainer}>
          <img
            src={`${process.env.PUBLIC_URL}/icon-star-${
              exploreFavorites &&
              exploreFavorites.length &&
              exploreFavorites.find((e) => e.id === post.id)
                ? "filled"
                : "outline"
            }.svg`}
            alt=""
            onClick={(ev) => onToggleFavorite(ev, post.id, post.type)}
            className={classes.fav}
          />
        </div>

        <div
          className={classes.postImage}
          style={{
            backgroundImage: `url(${
              !isInMobileView && isFeatured
                ? post.desktop_banner_url
                : post.image_url
            })`,

            borderRadius: !isFeatured || isInMobileView ? "12px 12px 0 0" : 12,
          }}
        >
          {post.collab_url && post.collab_image_url ? (
            <div
              style={{
                textDecoration: "none",
                position: "absolute",
                bottom: 5,
                left: 5,
              }}
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                window.open(post.collab_url, "_blank");
              }}
            >
              <img
                src={post.collab_image_url}
                alt=""
                className={classes.collab_img}
              />
            </div>
          ) : (
            ""
          )}
        </div>

        {!isFeatured || isInMobileView ? (
          <div className={classes.postDesciption}>
            <div className={classes.postMetadataRow}>
              {post.media_type ? (
                <Typography variant="body1" className={classes.postMediaType}>
                  {post.media_type.toLowerCase() === "video"
                    ? t("Video")
                    : post.media_type.toLowerCase() === "article"
                    ? t("Article")
                    : post.media_type.toLowerCase() === "audio"
                    ? t("Audio")
                    : ""}
                </Typography>
              ) : (
                ""
              )}

              {post.time ? (
                <Typography variant="body1" className={classes.postTime}>
                  {post.time}
                </Typography>
              ) : (
                ""
              )}
            </div>

            <Typography variant="body1" className={classes.postTitle}>
              {post.title && post.title[languageDetector]
                ? post.title[languageDetector]
                  ? post.title[languageDetector]
                  : post.title["en"]
                : ""}
            </Typography>

            {post.media_type ? (
              <Typography variant="body1" className={classes.postAction}>
                {post.media_type.toLowerCase() === "video"
                  ? t("Watch")
                  : post.media_type.toLowerCase() === "article"
                  ? t("Read")
                  : post.media_type.toLowerCase() === "audio"
                  ? t("Listen")
                  : ""}
              </Typography>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className={classes.desktopFeatured}>
            {post.media_type ? (
              <Typography
                variant="body1"
                className={classes.postMediaType}
                style={{
                  backgroundColor: "#FFD7CA",
                  color: "#212121",
                  width: "fit-content",
                }}
              >
                {post.media_type.toLowerCase() === "video"
                  ? t("Video")
                  : post.media_type.toLowerCase() === "article"
                  ? t("Article")
                  : post.media_type.toLowerCase() === "audio"
                  ? t("Audio")
                  : ""}
              </Typography>
            ) : (
              ""
            )}

            <Typography
              variant="body1"
              className={classes.postTitle}
              style={{
                color: "#FFFFFF",
                marginTop: 10,
              }}
            >
              {post.title && post.title[languageDetector]
                ? post.title[languageDetector]
                : ""}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

ExplorePost.propTypes = {
  post: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string,
  t: PropTypes.object.isRequired,
  languageDetector: PropTypes.string,
};

export default connect((store) => {
  return {
    auth: store.auth,
    subscriptions: store.subscriptions,
    subcategories: store.subcategories,
  };
})(
  withTranslation("trailhead")(
    withWidth()(withStyles(styles, { withTheme: true })(ExplorePost)),
  ),
);
