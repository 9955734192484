import React from "react";
import DashboardTrainingWeekdayDetails from "../../../dashboard/dashboard-training/dashboard-training-weekday/dashboard-training-weekday-details";
import NoWorkouts from "../../../dashboard/dashboard-gps/views/no-workouts";
import { Route } from "react-router-dom";

const TrackingRouter = () => (
  <>
    <Route
      path="/tracking/tracker/:id/:plannedDate"
      component={DashboardTrainingWeekdayDetails}
    />
    <Route path="/tracking/tracker/no-workout" component={NoWorkouts} />
  </>
);

export default TrackingRouter;
