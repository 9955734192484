const Surfaces = {
  Flat: "Flat",
  Mountains: "Mountains",
  Stairs: "Stairs",
};

export default {
  Items: Surfaces,
  itemsArray: Object.keys(Surfaces).map((key) => {
    return {
      text: Surfaces[key],
      value: key,
    };
  }),
};
