import { API } from "aws-amplify";
import { ACTIVITY_TYPES } from "../lib/activities-helper";

class StravaService {
  static API_NAME = "apistrava";
  static AUTHORIZATION_INIT_PATH = "/strava/init";
  static GET_ACTIVITIES_PATH = "/strava/:strava_athlete_id/activities";
  static GET_ACTIVITY_PATH = "/strava/:strava_athlete_id/activity";
  static GET_STATS_PATH = "/strava/:strava_athlete_id/stats";

  static async initAuthorization(params) {
    if (params) {
      const { code } = params;
      if (code) {
        let myInit = {
          // OPTIONAL
          body: params,
        };
        const response = await API.put(
          this.API_NAME,
          this.AUTHORIZATION_INIT_PATH,
          myInit,
        );
        const { success, result, error } = response;

        if (success) return result;
        else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      } else throw new Error("Invalid Code");
    } else throw new Error("Invalid Code");
  }

  static async getActivities(params) {
    if (params) {
      const {
        start_date,
        end_date,
        strava_athlete_id,
        cognito_user_sub,
      } = params;
      if (start_date && end_date && strava_athlete_id) {
        let myInit = {
          queryStringParameters: { start_date, end_date, cognito_user_sub },
        };
        const response = await API.get(
          this.API_NAME,
          this.GET_ACTIVITIES_PATH.replace(
            ":strava_athlete_id",
            strava_athlete_id,
          ),
          myInit,
        );
        const { success, result, error } = response;

        if (success) {
          const data = result.map((activity) => {
            if (!activity.device)
              return { ...activity, device: ACTIVITY_TYPES.STRAVA };
            return activity;
          });
          return data;
        } else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      }
    }
  }

  static async getActivity(params) {
    if (params) {
      const { id, strava_athlete_id } = params;
      if (id && strava_athlete_id) {
        let myInit = {
          queryStringParameters: { id },
        };
        const response = await API.get(
          this.API_NAME,
          this.GET_ACTIVITY_PATH.replace(
            ":strava_athlete_id",
            strava_athlete_id,
          ),
          myInit,
        );
        const { success, result, error } = response;

        if (success) return result;
        else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      }
    }
  }

  static async getStats(strava_athlete_id) {
    if (strava_athlete_id) {
      const response = await API.get(
        this.API_NAME,
        this.GET_STATS_PATH.replace(":strava_athlete_id", strava_athlete_id),
      );
      const { success, result, error } = response;

      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        return null;
      }
    }
  }
}

export default StravaService;
