import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CheckIcon from "../../icons/check-icon";
import { types, icons, colors } from "../../../constants/workouts-resources";
import workoutTypes from "../../../constants/workout-types";

const styles = (theme) => ({
  main: {
    display: "flex",
    width: "100%",
    minHeight: "65px",
  },
  cardParent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
  },
  content: {
    width: "70%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px dashed gray",
    padding: "3px",
  },
  contentChild: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
});

const WorkoutCard = ({
  classes,
  workoutType,
  estimatedTime,
  onClick,
  isDone,
  withCheckIcon = true,
  customText = null,
}) => {
  return (
    <div className={classes.main} onClick={onClick && onClick}>
      <div
        className={classes.cardParent}
        style={{ backgroundColor: `${workoutTypes.Colors[workoutType]}` }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/training-icons/${icons[workoutType]}`}
          alt="icon-image"
          width={20}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.contentChild}>
          {!customText ? (
            <>
              <Typography style={{ fontSize: 13 }}>
                {types[workoutType]}
              </Typography>
              <Typography style={{ fontSize: 12 }}>{estimatedTime}</Typography>
            </>
          ) : (
            <Typography style={{ fontSize: 13 }}>{customText}</Typography>
          )}

          <div style={{ alignSelf: "flex-end" }}>
            {withCheckIcon && <CheckIcon isDone={isDone} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("profile")(
  withStyles(styles, { withTheme: true })(WorkoutCard),
);
