import React, { useRef, useState } from "react";
import {
  Box,
  Stack,
  Button,
  Typography,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  ListItem,
} from "@mui/material";
import { GoalIcon, PictureIcon, RunningIcon } from "../../../components/icons";
import CommunityRacesDialog from "./CommunityRacesDialog";
import CommunityActivitiesDialog from "./CommunityActivitiesDialog";
import useCreatePost from "../../../hooks/useCreatePost";
import CommunityPostPreviewDialog from "./CommunityPostPreviewDialog";
import { useTranslation } from "react-i18next";
import { IconChevronRight } from "@tabler/icons-react";

const CommunityPostFooter = ({ handleClose }) => {
  const [activityClicked, setActivityClicked] = useState(false);
  const [previewClicked, setPreviewClicked] = useState(false);
  const [raceClicked, setRaceClicked] = useState(false);
  const { handleImageChange, text, selectedImage, goal } = useCreatePost();
  const { t } = useTranslation("community");

  const hiddenFileInput = useRef(null);

  const handlePhotoClick = () => {
    hiddenFileInput.current.click();
  };

  const handleActivityClick = () => {
    setActivityClicked(true);
  };
  const handlePreviewClick = () => {
    setPreviewClicked(true);
  };
  const handleRaceClick = () => {
    setRaceClicked(true);
  };
  const handleCloseDialog = (selected = false) => {
    if (selected) {
      handlePreviewClick();
    }
    setActivityClicked(false);
    setRaceClicked(false);
  };

  const handleClosePreviewDialog = (posted = false) => {
    if (posted) {
      handleClose();
    }
    setPreviewClicked(false);
  };

  const handleImageAdded = (event) => {
    handleImageChange(event);
    handlePreviewClick();
  };

  return (
    <Box
      width={"100%"}
      sx={{
        m: 0,
        zIndex: 200,
        position: "absolute",
        left: 0,
        bottom: 0,
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          bgcolor: "#FAFAFA",
          p: 2,
        }}
      >
        <Stack sx={{ p: 0 }}>
          <ListItem disablePadding>
            <ListItemButton
              data-testid="post_photo"
              disablePadding
              id="post_photo"
              disableFocusRipple
              disableTouchRipple
              disableRipple
              onClick={handlePhotoClick}
            >
              <input
                ref={hiddenFileInput}
                accept="image/*"
                type="file"
                onChange={handleImageAdded}
                style={{ display: "none" }}
              />
              <ListItemIcon>
                <PictureIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    component={"span"}
                    fontSize={"16px"}
                    fontWeight={400}
                    fontFamily={"karla"}
                    color={"#12151B"}
                  >
                    {t("community.newPost.addPhoto")}
                  </Typography>
                }
              />
              <ListItemIcon sx={{ justifyContent: "end" }}>
                <IconChevronRight size={"18px"} stroke={1} color="#AFB1B4" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              data-testid="post_activity"
              id="post_activity"
              disableFocusRipple
              disableTouchRipple
              disableRipple
              alignItems="center"
              onClick={handleActivityClick}
            >
              <ListItemIcon>
                <RunningIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    component={"span"}
                    fontSize={"16px"}
                    fontWeight={400}
                    fontFamily={"karla"}
                    color={"#12151B"}
                  >
                    {t("community.newPost.addActivity")}
                  </Typography>
                }
              />
              <ListItemIcon sx={{ justifyContent: "end" }}>
                <IconChevronRight size={"18px"} stroke={1} color="#AFB1B4" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              data-testid="post_goal"
              id="post_goal"
              disableFocusRipple
              disableTouchRipple
              disableRipple
              alignItems="center"
              onClick={handleRaceClick}
            >
              <ListItemIcon>
                <GoalIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    component={"span"}
                    fontSize={"16px"}
                    fontWeight={400}
                    fontFamily={"karla"}
                    color={"#12151B"}
                  >
                    {t("community.newPost.addGoal")}
                  </Typography>
                }
              />
              <ListItemIcon sx={{ justifyContent: "end" }}>
                <IconChevronRight size={"18px"} stroke={1} color="#AFB1B4" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </Stack>
        <Button
          sx={{ bgcolor: "#0B2B5C", height: "47px", textTransform: "Initial" }}
          variant="contained"
          disabled={!(text || selectedImage || goal)}
          onClick={handlePreviewClick}
        >
          {t("community.newPost.previewButton")}
        </Button>
      </Box>

      <CommunityRacesDialog
        handleOpenDialog={raceClicked}
        handleCloseDialog={handleCloseDialog}
      />
      <CommunityActivitiesDialog
        handleOpenDialog={activityClicked}
        handleCloseDialog={handleCloseDialog}
      />
      <CommunityPostPreviewDialog
        handleOpenDialog={previewClicked}
        handleCloseDialog={handleClosePreviewDialog}
      />
    </Box>
  );
};

export default CommunityPostFooter;
