import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PlanQuestionnaireButtons from "./plan-questionnaire/plan-questionnaire-buttons";
import SnackBarMessageDisplay from "../common/snack-bar-message-display";
import history from "../../lib/history-helper";
import PlanPreSignupQuestions from "./plan-questionnaire/plan-pre-signup-questions";
import { Routes } from "../../lib/routing-middleware";
import { storageFactory } from "../../lib/storage-factory";
import { Preferences } from "@capacitor/preferences";
import { Capacitor } from "@capacitor/core";

const styles = (theme) => ({
  container: {
    // backgroundImage: `url(${process.env.PUBLIC_URL}/choose-plan-xl.png)`,
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  form: {
    minHeight: 700,
    [theme.breakpoints.down("md")]: {
      minHeight: 600,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 500,
    },
  },
});

class PlanPreSignUp extends Component {
  timeout = null;
  state = {
    fields: [],
  };

  handleFieldsInit = (fields) => {
    if (fields.length !== this.state.fields.length) {
      this.setState({
        ...this.state,
        fields,
      });
    }
  };

  handleButtonSelectFieldChange = async (item) => {
    const { id, value } = item;
    try {
      storageFactory().setItem("currentGoal", value);

      if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "ios") {
        await Preferences.set({
          key: "currentGoal",
          value: value.toString(),
        });
      }
    } catch (e) {
      console.log(e);
    }

    setTimeout(() => history.push("/auth"), 1000);
  };

  handleBackRequested = () => {
    history.push("/auth");
  };

  componentDidMount() {
    window.scroll(0, 0);
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    const { classes } = this.props;
    const { fields } = this.state;

    const formId = "presignupForm";

    const totalSteps = this.state.fields.length - 1;
    return (
      <div className={classes.container}>
        <form id={formId} onSubmit={this.goToResults} className={classes.form}>
          <PlanPreSignupQuestions
            {...this.props}
            currentStep={0}
            onSelectFieldChange={this.handleSelectFieldChange}
            onButtonSelectFieldChange={this.handleButtonSelectFieldChange}
            onFieldError={this.handleFieldError}
            onFieldsInit={this.handleFieldsInit}
            onBackRequested={this.handleBackRequested}
          />
          <PlanQuestionnaireButtons
            form={formId}
            totalSteps={totalSteps}
            currentStep={0}
            saving={false}
            onNextRequested={() => {}}
            onBackRequested={this.handleBackRequested}
            fields={fields}
            isSuggester={true}
          />
        </form>
      </div>
    );
  }
}

PlanPreSignUp.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(
  withTranslation("messages")(
    withStyles(styles, { withTheme: true })(PlanPreSignUp),
  ),
);
