import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Chip, Avatar } from "@material-ui/core";
import { red, green, orange, grey, blue } from "@material-ui/core/colors";
import classNames from "classnames";

import { WorkoutFilterMapper } from "../../../lib/training-workout-filter-mapper";
import EstimatedTimes from "../../../constants/estimated-times";

const styles = (theme) => ({
  chip: {
    margin: theme.spacing.unit / 2,
    height: theme.spacing.unit * 3,
  },
  trainingPeriod: {
    backgroundColor: blue[500],
    color: "#fff",
  },
  difficultyLevel: {
    backgroundColor: grey[500],
    color: "#fff",
  },
  workoutType: {
    backgroundColor: green[500],
    color: "#fff",
  },
  surface: {
    backgroundColor: orange[500],
    color: "#fff",
  },
  estimatedTime: {
    backgroundColor: red[500],
    color: "#fff",
  },
  capitlize: {
    textTransform: "uppercase",
    width: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
  },
});

class AthleteTrainingWorkoutChips extends Component {
  render() {
    const workoutKeys = [
      "difficultyLevel",
      "estimatedTime",
      "surface",
      "trainingPeriod",
      "workoutType",
    ];
    const { classes, workout, level } = this.props;
    return workoutKeys.map((key, index) => {
      return workout[key] ? (
        <Chip
          avatar={
            <Avatar className={classes.capitlize}>
              {WorkoutFilterMapper(key)}
            </Avatar>
          }
          key={index}
          label={
            key === "estimatedTime"
              ? EstimatedTimes.Items[
                  Array.isArray(workout[key])
                    ? workout[key][level]
                    : workout[key]
                ]
              : workout[key]
          }
          className={classNames(classes.chip, classes[key])}
        />
      ) : null;
    });
  }
}

AthleteTrainingWorkoutChips.propTypes = {
  classes: PropTypes.object.isRequired,
  workout: PropTypes.object.isRequired,
};

export default withStyles(styles)(AthleteTrainingWorkoutChips);
