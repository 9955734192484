import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import DashboardCalendar from "./DashboardCalendar";
import useUserInfo from "../../../hooks/useUserInfo";
import WorkoutDate from "./WorkoutDate";
import WorkoutCard from "./WorkoutCard";
import { WorkoutTypesLangKeys } from "../../../constants/workout-types";
import { convertMinutesToTimeLabel } from "../../../constants/estimated-times";
import { useTranslation } from "react-i18next";
import useWorkout from "../../../hooks/useWorkout";
import ModalCustom from "./ModalCustom";
import TrainingDay from "./TrainingDay";
import CardNeedAPlan from "./CardNeedAPlan";
import { useChatContext } from "../../../context/ChatProvider";
import { IconMessageCircle2 } from "@tabler/icons-react";

const TrainingMonth = () => {
  const classes = useStyles();
  const { t } = useTranslation("training");
  const { currentTraining, nextRaces } = useUserInfo();
  const {
    workoutSelected,
    isOpenWorkout,
    openWorkout,
    onCloseOpenWorkout,
    getFixCustomLevel,
  } = useWorkout();
  const [selectedDay, setSelectedDay] = React.useState({
    date: null,
    race: null,
    workout: null,
  });
  const { openChat, setWorkoutToShare } = useChatContext();

  const handleSelectDay = (selectedDay) => {
    setSelectedDay({
      date: selectedDay && selectedDay.date ? selectedDay.date : null,
      workout:
        selectedDay && selectedDay.workout ? selectedDay.workout[0] : null,
      race: selectedDay && selectedDay.race ? selectedDay.race : null,
    });
  };
  return (
    <Grid container className={classes.monthlyContainer}>
      <Grid item xs={12}>
        <DashboardCalendar
          races={nextRaces}
          trainingDays={
            currentTraining && currentTraining.days ? currentTraining.days : []
          }
          onSelectDay={handleSelectDay}
        />
      </Grid>

      {selectedDay.workout && (
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <WorkoutDate
              mb={10}
              date={selectedDay.date ? selectedDay.date : new Date()}
            />
          </Grid>
          <Grid item xs={10}>
            <WorkoutCard
              variant={
                selectedDay.workout
                  ? selectedDay.workout.workoutType
                  : "NoWorkout"
              }
              primaryText={
                selectedDay.workout.workoutType
                  ? selectedDay.workout.workoutType === "UserCreated"
                    ? selectedDay.workout.userCreatedType
                    : t(WorkoutTypesLangKeys[selectedDay.workout.workoutType])
                  : ""
              }
              secondaryText={
                selectedDay.workout.estimatedTime
                  ? convertMinutesToTimeLabel(
                      Array.isArray(selectedDay.workout.estimatedTime) &&
                        !isNaN(
                          +selectedDay.workout.estimatedTime[
                            getFixCustomLevel(selectedDay.workout.plannedDate)
                          ],
                        )
                        ? +selectedDay.workout.estimatedTime[
                            getFixCustomLevel(selectedDay.workout.plannedDate)
                          ]
                        : +selectedDay.workout.estimatedTime,
                    )
                  : ""
              }
              onClick={() => openWorkout(selectedDay.workout)}
              isDone={selectedDay.workout.isDone}
              withCheck
              height="66px"
            />
          </Grid>
        </Grid>
      )}

      {!(
        currentTraining &&
        currentTraining.constructor === Object &&
        Object.keys(currentTraining).length
      ) && (
        <Grid item xs={12} style={{ marginBottom: 25 }}>
          <CardNeedAPlan />
        </Grid>
      )}

      {currentTraining &&
        currentTraining.constructor === Object &&
        Object.keys(currentTraining).length && (
          <ModalCustom
            fullScreen={true}
            open={isOpenWorkout}
            onClose={onCloseOpenWorkout}
            toolbarJustifyContent="space-between"
            paperDialogStyles={{ margin: 0, borderRadius: 0 }}
            callToActions={[
              {
                ActionIconComponent: (
                  <IconMessageCircle2 color="#6D6F71" stroke={2} />
                ),
                onClick: () => {
                  openChat(true);
                  setWorkoutToShare(workoutSelected);
                },
              },
            ]}
          >
            <Grid container>
              <Grid item xs={12}>
                {workoutSelected ? (
                  <TrainingDay allow={true} workoutSelected={workoutSelected} />
                ) : (
                  <TrainingDay allow={false} />
                )}
              </Grid>
            </Grid>
          </ModalCustom>
        )}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  monthlyContainer: {
    gap: 16,
  },
}));
export default TrainingMonth;
