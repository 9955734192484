import ActionTypes from "../constants/action-types";

export const setProfileFilter = (status) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SET_PROFILE_CURRENT_FILTER,
      payload: status,
    });
  };
};

export const setProfileDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SET_PROFILE_CURRENT_DATE,
      payload: date,
    });
  };
};

export const setProfileScreen = (screen) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SET_PROFILE_SCREEN,
      payload: screen,
    });
  };
};
