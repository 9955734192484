import React from "react";
import { palette } from "../../theme/palette";

const AvgUphillSpeedIcon = ({ width = 24, height = 24, activated = false }) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 12H1.2963C0.838906 12 0.62162 11.4367 0.960546 11.1295L11.1642 1.88241C11.4856 1.59117 12 1.8192 12 2.2529V11.5C12 11.7761 11.7761 12 11.5 12Z"
        stroke={activated ? palette.primary : palette.darkGray}
        strokeWidth="1.3"
      />
      <path
        d="M0.75 6L6 0.75M6 0.75H3.08333M6 0.75V3.83824"
        stroke={activated ? palette.primary : palette.darkGray}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
export default AvgUphillSpeedIcon;
