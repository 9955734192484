import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@material-ui/core";
import { palette } from "../../theme/palette";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { WorkoutTypes } from "../../constants/workout-types";
import { getCustomLevel, getPlanLevel } from "../../lib/plan-helper";
import { convertMinutesToTimeLabel } from "../../constants/estimated-times";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useTranslation } from "react-i18next";
import { exportWorkoutToGarmin } from "../../actions/user-plans-action";

import { default as WorkoutCardNew } from "../common/v2/WorkoutCard";
import useWorkout from "../../hooks/useWorkout";
import ModalCustom from "../common/v2/ModalCustom";
import TrainingDay from "../common/v2/TrainingDay";

dayjs.extend(customParseFormat);

const useStyles = makeStyles({
  card: {
    padding: "24px 16px",
    background: palette.white,
    borderRadius: "10px",
    boxShadow: palette.boxShadow,
  },
  title: {
    fontFamily: "Oswald",
    marginBottom: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  content: {
    color: palette.darkGray,
  },
  button: {
    fontFamily: "Karla",
    textTransform: "capitalize",
    padding: "8px 24px",
    boxSizing: "border-box",
    borderRadius: "4px",
    display: "flex",
    height: "65px",
    flex: 1,
    marginRight: "8px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: "14px",
    marginRight: "4px",
  },
  export: {
    border: `solid 2px ${palette.darkBlue}`,
    borderRadius: "4px",
    width: "63px",
    height: "63px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const WorkoutCard = () => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation(["dashboard", "home"]);
  const [todayTraining, setTodayTraining] = useState({});
  const { currentPlan } = useSelector((state) => state.userPlans);
  const { currentTraining } = currentPlan || {};
  const [showExport, setShowExport] = useState();

  const { plan } = useSelector((state) => state.userPlans);
  const level = getPlanLevel(currentTraining);
  const {
    workoutToday,
    workoutSelected,
    isOpenWorkout,
    openWorkout,
    onCloseOpenWorkout,
  } = useWorkout();

  useEffect(() => {
    if (plan && plan.length) setTodayTraining(plan[0]);
  }, [plan]);

  const handleExportWorkout = (useRpe) => {
    const { currentTraining } = currentPlan;
    const { chainedPlans } = currentTraining;
    const { plannedDate } = todayTraining;

    const level = !isNaN(getCustomLevel(plannedDate, chainedPlans))
      ? getCustomLevel(plannedDate, chainedPlans)
      : getPlanLevel(currentTraining);

    dispatch(exportWorkoutToGarmin(currentPlan, todayTraining, level, useRpe));
  };

  const classes = useStyles();

  const dateFormat = ({ plannedDate }) => {
    if (!plannedDate) return;
    const splidDate = plannedDate.split("/");
    const newDate = `${splidDate[1]}-${splidDate[0]}-${splidDate[2]}`;
    const date = dayjs(newDate, { format: "YYYY-MM-DD" });
    const formattedDate = date.locale(language).format("dddd, MMM DD");
    return formattedDate;
  };

  const handleShowExport = () => {
    setShowExport((prev) => !prev);
  };

  return (
    <>
      <Box className={classes.card}>
        <Typography variant="h3" className={classes.title}>
          {t("Todays workout", { ns: "home" })}
        </Typography>
        <Typography variant="subtitle2" className={classes.content}>
          {dateFormat(todayTraining)}
        </Typography>
        <Box display="flex" alignItems="center" marginTop="12px">
          {workoutToday ? (
            <WorkoutCardNew
              paperStylesProp={{ width: "100%" }}
              variant={workoutToday ? workoutToday.workoutType : "NoWorkout"}
              primaryText={
                workoutToday.workoutType
                  ? workoutToday.workoutType === "UserCreated"
                    ? workoutToday.userCreatedType
                    : t(WorkoutTypes[workoutToday.workoutType], {
                        ns: "constants",
                      })
                  : ""
              }
              secondaryText={
                workoutToday.estimatedTime
                  ? convertMinutesToTimeLabel(
                      !isNaN(+workoutToday.estimatedTime[level])
                        ? +workoutToday.estimatedTime[level]
                        : +workoutToday.estimatedTime,
                    )
                  : ""
              }
              onClick={() => openWorkout(workoutToday)}
              margin="0"
              withCheck={false}
            />
          ) : (
            <>{t("Full rest", { ns: "dashboard" })}</>
          )}

          {todayTraining["workoutType"] !== "Recovery" &&
            todayTraining["workoutType"] && (
              <Box
                className={classes.export}
                onClick={handleShowExport}
                id="home_export_workout_icon"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/icon_watch.svg`}
                  width={30}
                ></img>
              </Box>
            )}
        </Box>
      </Box>

      <Dialog open={showExport} aria-labelledby="delete-dialog">
        <DialogContent>
          <Typography variant="body1" className={classes.textInfo}>
            {t("Do you want to export the workout based on HR Zone?")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleExportWorkout(true);
              handleShowExport();
            }}
            color="primary"
          >
            {t("Heart Rate Zones")}
          </Button>
          <Button
            onClick={() => {
              handleExportWorkout(false);
              handleShowExport();
            }}
            color="primary"
          >
            {t("Only splits")}
          </Button>
          <Button
            onClick={() => {
              handleShowExport();
            }}
            color="primary"
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>

      {/** workout detail */}
      {currentTraining &&
        currentTraining.constructor === Object &&
        Object.keys(currentTraining).length && (
          <ModalCustom
            fullScreen={true}
            open={isOpenWorkout}
            onClose={onCloseOpenWorkout}
            toolbarJustifyContent="end"
            paperDialogStyles={{ margin: 0, borderRadius: 0 }}
          >
            <Grid container>
              <Grid item xs={12}>
                {workoutSelected ? (
                  <TrainingDay allow={true} workoutSelected={workoutSelected} />
                ) : (
                  <TrainingDay allow={false} />
                )}
              </Grid>
            </Grid>
          </ModalCustom>
        )}
    </>
  );
};

export default WorkoutCard;
