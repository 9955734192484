import React from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { LinearProgress } from "@material-ui/core";

class PrivateRoute extends React.Component {
  render() {
    const { auth, component: Component, ...rest } = this.props;
    const { currentUserLoading, isAuthenticated } = auth;

    if (currentUserLoading)
      return (
        <LinearProgress
          variant="indeterminate"
          color="primary"
          data-testid="loading"
        />
      );
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/auth",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
}

export default connect((store) => {
  return {
    auth: store.auth,
  };
})(withRouter(PrivateRoute));
