import React from "react";
import CoachInfoComponent from "./coach-info/components/coach-info";
import NavbarSettings from "./common/navbar-settings";
import { useTranslation } from "react-i18next";

const CoachInfo = () => {
  const { t } = useTranslation();
  return (
    <NavbarSettings
      component={<CoachInfoComponent />}
      text={t("Our Coaches")}
    />
  );
};
export default CoachInfo;
