import { PluginType } from "@amplitude/analytics-types";
import { Capacitor } from "@capacitor/core";

export class PlatformModifierPlugin {
  name = "platform-modifier";
  type = PluginType.ENRICHMENT;

  /**
   * setup() is called on plugin installation
   * example: client.add(new LibraryModifierPlugin());
   */
  async setup(config) {
    this.config = config;
    return;
  }

  /**
   * execute() is called on each event instrumented
   * example: client.track('New Event');
   */
  async execute(event) {
    event.plaform = Capacitor.isNativePlatform()
      ? Capacitor.getPlatform()
      : "Web";
    event.os_name = Capacitor.getPlatform();
    return Promise.resolve(event);
  }
}
