import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  container: {
    background: "#FFFFFF",
    borderRadius: 20,
    padding: 30,
  },
  title: {
    fontSize: 14,
    color: "#808191",
    fontWeight: "bold",
    marginLeft: 5,
  },
  count: {
    fontSize: 40,
  },
  titleContainer: {
    display: "inline-flex",
    alignItems: "center",
  },
  iconBkg: {
    padding: "2px 6px",
    background: "#FF5D6D",
    display: "inline",
    borderRadius: "100%",
  },
});

class AthletesCounter extends Component {
  render() {
    const { classes, count, title, icon } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <div className={classes.iconBkg}>
            <img
              src={`${process.env.PUBLIC_URL}/${icon}.svg`}
              alt=""
              className={classes.imgIcon}
            />
          </div>
          <Typography variant="body1" className={classes.title}>
            {title}
          </Typography>
        </div>
        <Typography variant="body1" className={classes.count}>
          {count}
        </Typography>
      </div>
    );
  }
}

AthletesCounter.propTypes = {
  count: PropTypes.number,
  title: PropTypes.string,
  icon: PropTypes.string,
};

export default withStyles(styles)(AthletesCounter);
