export const convertMinutesToTimeLabel = (minutes) => {
  if (typeof minutes !== "number" || minutes < 0) {
    return "Invalid input";
  }

  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  if (hours === 0) {
    return `${mins}min`;
  } else if (mins === 0) {
    return `${hours}h`;
  } else {
    return `${hours}h ${mins}min`;
  }
};

const EstimatedTimes = {
  "0": "0mins",
  "15": "15mins",
  "20": "20mins",
  "30": "30mins",
  "45": "45mins",
  "60": "1h",
  "75": "1h15mins",
  "90": "1h30mins",
  "105": "1h45mins",
  "120": "2h",
  "150": "2h30mins",
  "180": "3h",
  "210": "3h30mins",
  "240": "4h",
  "300": "5h",
  "360": "6h",
  "420": "7h",
  "480": "8h",
  "540": "9h",
  "600": "10h",
  "660": "11h",
  "720": "12h",
  "780": "13h",
  "840": "14h",
  "900": "15h",
  "960": "16h",
  "1020": "17h",
  "1080": "18h",
};

export default {
  Items: EstimatedTimes,
  itemsArray: Object.keys(EstimatedTimes).map((key) => {
    return {
      text: EstimatedTimes[key],
      value: key,
    };
  }),
  convertMinutesToLabel: (minutes, includeMin = false) => {
    const min = Math.floor(minutes);
    return (
      (min >= 60 ? Math.floor(min / 60) + "h" : "") +
      (min % 60 > 0 ? (min % 60) + (includeMin ? "min" : "") : "")
    );
  },
};
