import React, { useState } from "react";
import { palette } from "../../../theme/palette";
import { Box, makeStyles, Slide, Dialog } from "@material-ui/core";
import ProfileSelect from "../../common/v2/ProfileSelect";
import DateBetween from "../../common/v2/DateBetween";
import MountainIndexSection from "../Home/components/mountain-index-section";
import MIExplanation from "../../common/v2/MIExplanation";
import ToolbarReturnBack from "../../common/v2/ToolbarReturnBack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  titleFont: {
    fontFamily: "Karla",
    color: palette.black,
  },
  contentView: {
    flex: "1 1 auto",
    overflowY: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    //position: "fixed",
    backgroundColor: palette.lightGrayOpacity,
    //zIndex: 200,
  },
  box: {
    display: "flex",
    padding: "24px 0px",
    boxSizing: "border-box",
    width: "100%",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MountainIndexView = () => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(true);
  const { goBack } = useHistory();
  const { t } = useTranslation("mountainIndex");

  const handleCloseDialog = () => {
    setOpenDialog(false);
    goBack();
  };

  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        display={"flex"}
        flexDirection="column"
      >
        <Box
          sx={{
            flex: "0 0 auto",
            bgcolor: "#FAFAFA",
          }}
        >
          <ToolbarReturnBack
            goBack={handleCloseDialog}
            withAvatar={true}
            title={t("mountainIndex.title")}
            withBox={false}
          />
        </Box>
        <Box className={classes.contentView}>
          <Box className={classes.contentView}>
            <Box sx={{ padding: "0 18px" }}>
              <ProfileSelect />
              <DateBetween />
              <MountainIndexSection />
            </Box>
            <MIExplanation />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default MountainIndexView;
