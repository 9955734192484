import React from "react";
import GlobalSettings from "./setting-global/components/global-settings";
import NavbarSettings from "./common/navbar-settings";
import { useTranslation } from "react-i18next";

const SettingsAccountGlobal = () => {
  const { t } = useTranslation("settings");
  return (
    <>
      <NavbarSettings
        closeTo={"/profile"}
        component={<GlobalSettings />}
        text={t("settings.title")}
      />
    </>
  );
};
export default SettingsAccountGlobal;
