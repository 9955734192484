import React, { Component, createRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  DialogActions,
  Dialog,
  DialogContent,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PropTypes from "prop-types";
import MenuList from "../../common/menu-list";
import { withTranslation } from "react-i18next";
import "moment/min/locales";

const styles = (theme) => ({
  container: {
    marginLeft: "auto",
    position: "relative",
  },
  paper: {
    background: "#FCFCFC",
    borderRadius: 8,
    boxShadow: "5px 3px 10px rgb(33 81 209 / 10%)",
    padding: "5px 0",
  },
  button: {
    padding: "0",
    fontWeight: "normal",
    minWidth: 24,
  },
  menuItem: {
    padding: "6px 8px",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
  },
  menuItemLabel: {
    textTransform: "initial",
    textAlign: "center",
  },
  popper: {
    zIndex: 1,
    minWidth: 150,
  },
  imgIcon: {
    marginRight: 5,
  },
});

class PostActionsMenu extends Component {
  constructor(props) {
    super(props);
    this.myRef = createRef();
  }

  state = {
    open: false,
    deleteDialogOpen: false,
    reportDialogOpen: false,
    blockDialogOpen: false,
  };

  handleToggle = () => {
    this.setState({
      ...this.state,
      open: !this.state.open,
    });
  };

  handleClose = async (id, data = null) => {
    const { onDelete, onEdit, onReport, onBlock } = this.props;

    if (id) {
      switch (id) {
        case "delete":
          onDelete();
          break;
        case "edit":
          onEdit();
          break;
        case "report":
          onReport(data);
          break;
        case "block":
          onBlock();
          break;
        default:
          break;
      }
    }

    this.setState({
      ...this.state,
      open: false,
    });
  };

  handleOpenDelete = () => {
    this.setState({
      ...this.state,
      deleteDialogOpen: true,
    });
  };

  handleCloseDelete = () => {
    this.setState({
      ...this.state,
      deleteDialogOpen: false,
    });
  };

  handleOpenEasy = () => {
    this.setState({
      ...this.state,
      easyDialogOpen: true,
    });
  };

  handleCloseEasy = () => {
    this.setState({
      ...this.state,
      easyDialogOpen: false,
    });
  };

  handleOpenRestart = () => {
    this.setState({
      ...this.state,
      restartDialogOpen: true,
    });
  };

  handleCloseRestart = () => {
    this.setState({
      ...this.state,
      restartDialogOpen: false,
    });
  };

  handleOpenReport = () => {
    this.setState({
      ...this.state,
      reportDialogOpen: true,
    });
  };

  handleCloseReport = () => {
    this.setState({
      ...this.state,
      reportDialogOpen: false,
    });
  };

  handleOpenBlock = () => {
    this.setState({
      ...this.state,
      blockDialogOpen: true,
    });
  };

  handleCloseBlock = () => {
    this.setState({
      ...this.state,
      blockDialogOpen: false,
    });
  };

  render() {
    const {
      classes,
      t,
      isMine,
      type,
      isAdmin,
      isPinned,
      onPin,
      isLastPinned,
      onMoveDown,
      onMoveUp,
      pinnedOrder,
    } = this.props;
    const {
      open,
      deleteDialogOpen,
      reportDialogOpen,
      blockDialogOpen,
    } = this.state;

    return (
      <div className={classes.container}>
        <Button
          className={classes.button}
          ref={this.myRef}
          aria-owns={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
          placement="left-start"
        >
          <MoreHorizIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={this.myRef.current}
          transition
          disablePortal
          className={classes.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "top" ? "center top" : "center bottom",
              }}
            >
              <div className={classes.paper}>
                <ClickAwayListener
                  onClickAway={this.handleClose.bind(this, null)}
                >
                  {isMine || isAdmin ? (
                    <MenuList>
                      <Tooltip
                        title={
                          type === "reply"
                            ? t("Delete this reply")
                            : type === "comment"
                            ? t("Delete this comment")
                            : t("Delete this post")
                        }
                      >
                        <Button
                          variant="text"
                          classes={{
                            root: classes.menuItem,
                            label: classes.menuItemLabel,
                          }}
                          onClick={this.handleOpenDelete}
                        >
                          {type === "reply"
                            ? t("Delete reply")
                            : type === "comment"
                            ? t("Delete comment")
                            : t("Delete post")}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          type === "reply"
                            ? t("Edit this reply")
                            : type === "comment"
                            ? t("Edit this comment")
                            : t("Edit this post")
                        }
                      >
                        <Button
                          variant="text"
                          classes={{
                            root: classes.menuItem,
                            label: classes.menuItemLabel,
                          }}
                          onClick={() => this.handleClose("edit")}
                        >
                          {type === "reply"
                            ? t("Edit reply")
                            : type === "comment"
                            ? t("Edit comment")
                            : t("Edit post")}
                        </Button>
                      </Tooltip>

                      {isAdmin && type === "post" ? (
                        <>
                          <Tooltip title={isPinned ? "Unpin" : "Pin"}>
                            <Button
                              variant="text"
                              classes={{
                                root: classes.menuItem,
                                label: classes.menuItemLabel,
                              }}
                              onClick={() => onPin(!isPinned)}
                            >
                              {isPinned ? "Unpin" : "Pin"}
                            </Button>
                          </Tooltip>

                          {isPinned && pinnedOrder > 1 ? (
                            <Tooltip title="Move up">
                              <Button
                                variant="text"
                                classes={{
                                  root: classes.menuItem,
                                  label: classes.menuItemLabel,
                                }}
                                onClick={() => onMoveUp()}
                              >
                                Move Up
                              </Button>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {isPinned && !isLastPinned ? (
                            <Tooltip title="Move down">
                              <Button
                                variant="text"
                                classes={{
                                  root: classes.menuItem,
                                  label: classes.menuItemLabel,
                                }}
                                onClick={() => onMoveDown()}
                              >
                                Move Down
                              </Button>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </MenuList>
                  ) : (
                    <MenuList>
                      <Tooltip title={t("Report this post")}>
                        <Button
                          variant="text"
                          classes={{
                            root: classes.menuItem,
                            label: classes.menuItemLabel,
                          }}
                          onClick={this.handleOpenReport}
                        >
                          {t("Report post")}
                        </Button>
                      </Tooltip>
                      <Tooltip title={t("Block this user")}>
                        <Button
                          variant="text"
                          classes={{
                            root: classes.menuItem,
                            label: classes.menuItemLabel,
                          }}
                          onClick={this.handleOpenBlock}
                        >
                          {t("Block user")}
                        </Button>
                      </Tooltip>
                    </MenuList>
                  )}
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </Popper>

        <Dialog
          open={deleteDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseDelete}
        >
          <DialogContent>
            <Typography variant="body1" className={classes.textInfo}>
              {type === "reply"
                ? t("Are you sure you want to delete this reply?")
                : type === "comment"
                ? t("Are you sure you want to delete this comment?")
                : t("Are you sure you want to delete this post?")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose("delete");
                this.handleCloseDelete();
              }}
              color="primary"
            >
              {t("Delete")}
            </Button>
            <Button onClick={this.handleCloseDelete} color="primary">
              {t("Cancel")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={reportDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseReport}
        >
          <DialogContent>
            <Typography variant="body1" className={classes.textInfo}>
              {t("Why are you reporting this post?")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose("report", "offensive");
                this.handleCloseReport();
              }}
              color="primary"
            >
              {t("It's offensive")}
            </Button>
            <Button
              onClick={() => {
                this.handleClose("report", "spam");
                this.handleCloseReport();
              }}
              color="primary"
            >
              {t("It's spam")}
            </Button>
            <Button onClick={this.handleCloseReport} color="primary">
              {t("Cancel")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={blockDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseBlock}
        >
          <DialogContent>
            <Typography variant="body1" className={classes.textInfo}>
              {t("Are you sure you want to block post by this user?")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose("block");
                this.handleCloseBlock();
              }}
              color="primary"
            >
              {t("Block")}
            </Button>
            <Button onClick={this.handleCloseBlock} color="primary">
              {t("Cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

PostActionsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
};

export default withTranslation("trailhead")(
  withStyles(styles, { withTheme: true })(PostActionsMenu),
);
