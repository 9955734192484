import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withTranslation } from "react-i18next";
import WorkoutPlans from "../../../constants/workout-plans";
import PlanDrawer from "./plan-drawer.js";
import Genders from "../../../constants/genders";
import RaceCounts from "../../../constants/race-counts";
import TrainingPerWeek from "../../../constants/training-per-week";
import TrainingHoursPerWeek from "../../../constants/training-hours-per-week";
import Distances from "../../../constants/distances";
import SkillsToImprove from "../../../constants/skills-to-improve";
import Fields from "../../../constants/plan-drawer-fields";
import QuestionFields from "../../../constants/questions";

import { Capacitor } from "@capacitor/core";

class PlanCoachChoose extends PureComponent {
  render() {
    const {
      planTempData,
      currentData,
      onTextFieldChange,
      onDateFieldChange,
      onSelectFieldChange,
      onButtonSelectFieldChange,
      onGoalSelect,
      onTimeSelect,
      onSliderSelect,
      onFieldError,
      onEnterPress,
      onFieldsInit,
      t,
      type,
      onBackRequested,
    } = this.props;

    const skillToImproveItemsArray = SkillsToImprove.itemsArray;
    const skillToImproveItems = [
      {
        ...skillToImproveItemsArray[0],
        caption: `#${t("Light Speed")}`,
        image: `${process.env.PUBLIC_URL}/card-skill-light-speed.png`,
        imageTooltip: t("Speed", { ns: "constants" }),
      },
      {
        ...skillToImproveItemsArray[1],
        caption: `#${t("Climb Strong")}`,
        image: `${process.env.PUBLIC_URL}/card-skill-climb-strong.png`,
        imageTooltip: t("Climbs", { ns: "constants" }),
      },
      {
        ...skillToImproveItemsArray[2],
        caption: `#${t("King of the downhill")}`,
        image: `${process.env.PUBLIC_URL}/card-skill-king-of-downhill.png`,
        imageTooltip: t("Downhills", { ns: "constants" }),
      },
      {
        ...skillToImproveItemsArray[3],
        caption: `#${t("Endurance")}`,
        image: `${process.env.PUBLIC_URL}/card-skill-endurance.png`,
        imageTooltip: t("Endurance", { ns: "constants" }),
      },
    ];
    const distanceItemsArray = Distances.itemsArray;
    const distanceItems = [
      {
        ...distanceItemsArray[0],
        image: `${process.env.PUBLIC_URL}/card-distance-12km.jpg`,
        imageTooltip: t(`${distanceItemsArray[0].text}`, { ns: "constants" }),
      },
      {
        ...distanceItemsArray[1],
        image: `${process.env.PUBLIC_URL}/card-distance-21km.jpg`,
        imageTooltip: t(`${distanceItemsArray[1].text}`, { ns: "constants" }),
      },
      {
        ...distanceItemsArray[2],
        image: `${process.env.PUBLIC_URL}/card-distance-32km.jpg`,
        imageTooltip: t(`${distanceItemsArray[2].text}`, { ns: "constants" }),
      },
      {
        ...distanceItemsArray[3],
        image: `${process.env.PUBLIC_URL}/card-distance-42km.jpg`,
        imageTooltip: t(`${distanceItemsArray[3].text}`, { ns: "constants" }),
      },
    ];

    const {
      country,
      birthdate,
      sex,
      raceCount,
      skillToImprove,
      distance,
      trainPerWeek,
      trainHoursPerWeek,
      currentStep,
      desiredStartDate,
      message,
      workoutNotifications,
    } = planTempData;

    const birthdateMax = moment().subtract(16, "y");
    const desiredStartDateMin = moment();
    const desiredStartDateMax = moment().add(14, "d");

    const questions = [
      {
        field: "trainPerWeek",
      },
      {
        field: "raceCount",
      },
    ];

    let stepsCount = questions.length + 7;

    if (currentData && currentData.birthdate) {
      stepsCount--;
    }
    if (currentData && currentData.country) {
      stepsCount--;
    }
    if (currentData && currentData.sex) {
      stepsCount--;
    }
    if (
      (currentData && currentData.showWorkoutNotifications) ||
      !Capacitor.isNativePlatform()
    ) {
      stepsCount--;
    }

    const requiredQuestions = questions.map((q) => {
      const questionField = QuestionFields.find((v) => v.field === q.field);
      const field = [
        {
          fieldType: Fields.Items.IMAGE,
          image: questionField.image,
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t(questionField.label, { ns: "plan" }),
          labelNumber: currentStep + 1,
        },
      ];
      const lastElement = {
        fieldType: questionField.fieldType,
        id: questionField.field,
        xs: 12,
        sm: 12,
        md: 12,
        onError: onFieldError,
      };
      if (questionField.field === "trainPerWeek") {
        lastElement.selectedValue = trainPerWeek;
        lastElement.items = TrainingPerWeek.itemsArray;
        lastElement.onSelect = onSelectFieldChange;
      } else if (questionField.field === "distance") {
        lastElement.selectedValue = distance;
        lastElement.items = distanceItems;
        lastElement.onSelect = onSelectFieldChange;
      } else if (questionField.field === "skillToImprove") {
        lastElement.selectedValue = skillToImprove;
        lastElement.items = skillToImproveItems;
        lastElement.onSelect = onSelectFieldChange;
      } else if (questionField.field === "raceCount") {
        lastElement.selectedValue = raceCount;
        lastElement.items = RaceCounts.itemsArray;
        lastElement.onSelect = onSelectFieldChange;
      }
      field.push(lastElement);
      return field;
    });

    requiredQuestions.push([
      {
        fieldType: Fields.Items.IMAGE,
        image: "onboarding-img/step3.png",
      },
      {
        fieldType: Fields.Items.STEPPER,
        xs: 12,
        sm: 12,
        md: 12,
        currentStep: currentStep,
        stepsCount,
      },
      {
        fieldType: Fields.Items.LABEL,
        xs: 12,
        sm: 12,
        md: 12,
        label: t("How many hours?", { ns: "plan" }),
        labelNumber: currentStep + 1,
      },
      {
        fieldType: Fields.Items.SLIDER_SELECT,
        id: "trainHoursPerWeek",
        xs: 12,
        sm: 12,
        md: 12,
        min: 0,
        max: 20,
        selectedValue: trainHoursPerWeek,
        onError: onFieldError,
        items: TrainingHoursPerWeek.itemsArray,
        onSelect: onSliderSelect,
      },
    ]);

    if (currentData && !currentData.birthdate) {
      requiredQuestions.push([
        {
          fieldType: Fields.Items.IMAGE,
          image: "onboarding-img/step6.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("What is your date of birth?", { ns: "plan" }),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.DATE,
          id: "birthdate",
          xs: 12,
          sm: 12,
          md: 12,
          value: birthdate,
          onError: onFieldError,
          onChange: onDateFieldChange,
          maxDate: birthdateMax,
          minDateMessage: t("The date must not be before the minimum date", {
            ns: "plan",
          }),
          maxDateMessage: t(
            "You must be at least 16 years old to train with Vert",
            { ns: "plan" },
          ),
        },
      ]);
    }
    if (currentData && !currentData.country) {
      requiredQuestions.push([
        {
          fieldType: Fields.Items.IMAGE,
          image: "onboarding-img/coach-country.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("What is your country?", { ns: "plan" }),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.TEXT_INPUT,
          id: "country",
          xs: 12,
          sm: 12,
          md: 12,
          value: country,
          onKeyPress: onEnterPress,
          onError: onFieldError,
          onChange: onTextFieldChange,
        },
      ]);
    }
    if (currentData && !currentData.sex) {
      requiredQuestions.push([
        {
          fieldType: Fields.Items.IMAGE,
          image: "onboarding-img/coach-gender.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("With which gender do you identify?", { ns: "plan" }),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.BUTTON_SELECT,
          id: "sex",
          xs: 12,
          sm: 12,
          md: 12,
          value: sex,
          items: Genders.itemsArray,
          onError: onFieldError,
          onSelect: onButtonSelectFieldChange,
        },
      ]);
    }

    // if (
    //   currentData &&
    //   !currentData.showWorkoutNotifications &&
    //   Capacitor.isNativePlatform()
    // ) {
    //   requiredQuestions.push([
    //     {
    //       fieldType: Fields.Items.IMAGE,
    //       image: "onboarding-img/step9.png",
    //     },
    //     {
    //       fieldType: Fields.Items.STEPPER,
    //       xs: 12,
    //       sm: 12,
    //       md: 12,
    //       currentStep: currentStep,
    //       stepsCount: stepsCount,
    //     },
    //     {
    //       fieldType: Fields.Items.LABEL,
    //       xs: 12,
    //       sm: 12,
    //       md: 12,
    //       label: t(
    //         "On the days you train, do you want a preview of your workout?",
    //         { ns: "plan" },
    //       ),
    //       subLabel: "",
    //       labelNumber: currentStep + 1,
    //     },
    //     {
    //       fieldType: Fields.Items.TIME_SELECT,
    //       id: "workoutNotifications",
    //       xs: 12,
    //       sm: 12,
    //       md: 12,
    //       value: workoutNotifications,
    //       onSelect: onTimeSelect,
    //     },
    //   ]);
    // }

    requiredQuestions.push([
      {
        fieldType: Fields.Items.IMAGE,
        image: "onboarding-img/step5.png",
      },
      {
        fieldType: Fields.Items.STEPPER,
        xs: 12,
        sm: 12,
        md: 12,
        currentStep: currentStep,
        stepsCount: stepsCount,
      },
      {
        fieldType: Fields.Items.LABEL,
        xs: 12,
        sm: 12,
        md: 12,
        label: t("When would you like to start your plan?", { ns: "plan" }),
        subLabel: t(
          type === WorkoutPlans.FREE ? "If your race free" : "If your race",
          { ns: "plan" },
        ),
        labelNumber: currentStep + 1,
      },
      {
        fieldType: Fields.Items.DATE,
        id: "desiredStartDate",
        xs: 12,
        sm: 12,
        md: 12,
        value: desiredStartDate,
        defaultValue: desiredStartDateMin.format("DD/MM/YYYY"),
        onError: onFieldError,
        onChange: onDateFieldChange,
        minDate: desiredStartDateMin,
        maxDate: desiredStartDateMax,
        minDateMessage: t("You can't start your plan before today", {
          ns: "plan",
        }),
        maxDateMessage: t("You must start your plan within 14 days", {
          ns: "plan",
        }),
      },
    ]);

    requiredQuestions.push([
      {
        fieldType: Fields.Items.IMAGE,
        image: "onboarding-img/coach-share.jpg",
      },
      {
        fieldType: Fields.Items.STEPPER,
        xs: 12,
        sm: 12,
        md: 12,
        currentStep: currentStep,
        stepsCount: stepsCount,
      },
      {
        fieldType: Fields.Items.LABEL,
        xs: 12,
        sm: 12,
        md: 12,
        label: t(
          "Is there anything else you would like to share with your coach?",
          { ns: "plan" },
        ),
        labelNumber: currentStep + 1,
      },
      {
        fieldType: Fields.Items.TEXT_INPUT,
        id: "message",
        xs: 12,
        sm: 12,
        md: 12,
        value: message,
        onKeyPress: () => {},
        onError: onFieldError,
        onChange: onTextFieldChange,
        textFieldPlaceholder: t(
          "Are you recovering from an injury, what’s your strength, your training/racing experience....",
          { ns: "plan" },
        ),
        multiline: true,
        fullWidth: true,
      },
    ]);

    onFieldsInit(requiredQuestions);
    return (
      <PlanDrawer
        currentStep={currentStep}
        fields={requiredQuestions[currentStep]}
        onBackRequested={onBackRequested}
      />
    );
  }
}

PlanCoachChoose.propTypes = {
  match: PropTypes.object.isRequired,
  planTempData: PropTypes.object.isRequired,
  currentData: PropTypes.object.isRequired,
  onTextFieldChange: PropTypes.func.isRequired,
  onYesNoFieldChange: PropTypes.func.isRequired,
  onDateFieldChange: PropTypes.func.isRequired,
  onSelectFieldChange: PropTypes.func.isRequired,
  onButtonSelectFieldChange: PropTypes.func.isRequired,
  onGoalSelect: PropTypes.func,
  onSliderSelect: PropTypes.func,
  onFieldError: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func.isRequired,
  onFieldsInit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(["plan", "constants"])(PlanCoachChoose);
