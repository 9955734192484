import history from "./history-helper";
import ActionTypes from "../constants/action-types";

const Routes = {
  SignIn: `/auth`,
  SwitchSignUp: `/authswitch`,
  AthletesPage: `/athletes`,
  Landing: `/`,
  DashboardPage: `/dashboard`,
  Workouts: `/workouts`,
  WorkoutCreate: `/workouts-editor`,
  WorkoutEditor: (id) => `/workouts-editor/${id || ":id?"}`,
  WorkoutDeletePath: `/workouts/delete`,
  Trainings: `/trainings`,
  TrainingCreate: `/trainings/editor`,
  TrainingEditor: (id, training_order) => `/training/${id}/${training_order}`,
  TrainingDeletePath: `/trainings/delete`,
  Subscription: `/subscription`,
  Profile: `/watch`,
  ProfileInfo: `/profile/info`,
  ProfileStrava: `/profile/strava`,
  ProfileSubscription: `/profile/subscription`,
  ChoosePlan: `/chooseplan`,
  TrailHead: `/trailhead`,
  SuggesterResults: `/suggester/results`,
  Settings: `/account`,
};

const RoutingMiddleware = (store) => (next) => (action) => {
  if (action) {
    const { type, payload, redirect, redirectEditor } = action;
    switch (type) {
      // case ActionTypes.ATHLETES_ASSIGN_TRAINING_FULFILLED:
      case ActionTypes.ATHLETES_TRAINING_SAVE_FULFILLED:
        history.push(Routes.AthletesPage);
        break;
      case ActionTypes.AUTH_CURRENT_USER_FULFILLED:
      case ActionTypes.USER_PLANS_UPDATE_FULFILLED:
      case ActionTypes.USER_PLANS_UPDATE_REJECTED:
        if (redirect) history.push(Routes.Landing);
        break;
      // case ActionTypes.STRAVA_INIT_FULFILLED:
      //   setTimeout(() => history.push(Routes.Landing), 2500);
      //   break;
      case ActionTypes.TRAINING_SAVE_FULFILLED:
        if (redirectEditor)
          history.push(
            Routes.TrainingEditor(payload.id, payload.training_order),
          );
        else if (redirect) history.push(Routes.Trainings);
        break;
      case ActionTypes.TRAINING_SWAP_FULFILLED:
        history.push(Routes.Trainings);
        break;
      case ActionTypes.WORKOUT_SAVE_FULFILLED:
        history.push(Routes.Workouts);
        break;
      default:
        break;
    }
  }
  return next(action);
};

export { RoutingMiddleware, Routes };
