import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";

import { Routes } from "../../lib/routing-middleware";
import history from "../../lib/history-helper";
import {
  saveTrainingHeader,
  trainingChangeField,
  clearCurrentTraining,
  athletesSearch,
  duplicateTraining,
  ClearAthletes,
} from "../../actions/training-action";
import TrainingEditorForm from "./training-editor-form";
import Plans from "../../constants/plans";
import UserGroups from "../../constants/user-groups";
import WorkoutsService from "../../services/workouts-service";

class TrainingEditor extends Component {
  handleClose = () => {
    history.push(Routes.Trainings);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { training } = this.props;
    if (training) {
      const { currentTraining } = training;
      if (currentTraining) {
        const { durationInWeeks, days } = currentTraining;
        let newDays = days ? [...days] : [];

        if (durationInWeeks * 7 > newDays.length)
          for (let i = newDays.length; i < durationInWeeks * 7; i++) {
            newDays.push(undefined);
          }
        else newDays = newDays.slice(0, durationInWeeks * 7);

        newDays = await Promise.all(
          newDays.map(async (d) => {
            if (d && d[0] && !d[0].id) {
              return [await WorkoutsService.saveWorkout(d[0])];
            } else if (d && !d[0]) {
              return [await WorkoutsService.saveWorkout(d)];
            }
            return d;
          }),
        );

        this.props.dispatch(
          saveTrainingHeader({ ...currentTraining, days: newDays }),
        );
      }
    }
  };

  handleSelect = ({ name, value }) => {
    if (name) this.props.dispatch(trainingChangeField({ name, value }));
  };

  handleTextChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { name, value } = target;
        let valueParsed = value;
        if (name === "training_order") {
          const parsedInValue = parseInt(value);
          valueParsed = isNaN(parsedInValue) ? undefined : parsedInValue;
        }
        this.props.dispatch(trainingChangeField({ name, value: valueParsed }));
      }
    }
  };

  handleAthletesSearch = (searchPhrase) => {
    const { auth } = this.props;
    const { currentUserGroups, currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;
    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;
    this.props.dispatch(
      athletesSearch(
        { selectedPlan: Plans.Items.PREMIUM, searchPhrase: searchPhrase },
        isAdmin,
        sub,
      ),
    );
  };

  handleAthleteSelect = (
    athlete_cognito_user_sub,
    athlete_cognito_user_full_name,
  ) => {
    this.props.dispatch(
      trainingChangeField({
        name: "athlete_cognito_user_sub",
        value: athlete_cognito_user_sub,
      }),
    );
    this.props.dispatch(
      trainingChangeField({
        name: "athlete_cognito_user_full_name",
        value: athlete_cognito_user_full_name,
      }),
    );
  };

  componentDidMount() {
    const { location } = this.props;
    const { search } = location;
    if (search) {
      const queryParams = new URLSearchParams(search);
      const id = queryParams.get("id");
      const training_order = queryParams.get("training_order");
      if (id && training_order) {
        this.props.dispatch(duplicateTraining({ id, training_order }));
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearCurrentTraining());
    this.props.dispatch(ClearAthletes());
  }

  render() {
    const formId = "trainingForm";
    const { fullScreen, training, subcategories } = this.props;
    const {
      saving,
      currentTraining = {},
      athletesList,
      athletesLoading,
      reading,
    } = training;
    const {
      selectedPlan,
      durationInWeeks,
      distance,
      surface,
      raceCount,
      trainPerWeek,
      skillToImprove,
      athlete_cognito_user_sub,
    } = currentTraining;
    const isPlanFreeSelected = selectedPlan === Plans.Items.FREE;
    const isPlanPremiumSelected = selectedPlan === Plans.Items.PREMIUM;
    const hasError = !(
      selectedPlan &&
      durationInWeeks &&
      surface &&
      raceCount &&
      trainPerWeek &&
      (isPlanFreeSelected ? true : skillToImprove) &&
      (isPlanPremiumSelected ? true : distance) &&
      (isPlanPremiumSelected ? athlete_cognito_user_sub : true)
    );

    return (
      <Dialog
        fullScreen={fullScreen}
        open={true}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Training Editor
          <LinearProgress
            color="primary"
            variant={saving || reading ? "indeterminate" : "determinate"}
            value={0}
          />
        </DialogTitle>
        <DialogContent>
          <TrainingEditorForm
            formId={formId}
            saving={saving}
            loading={reading}
            training={currentTraining}
            onTextChange={this.handleTextChange}
            onSelect={this.handleSelect}
            onSubmit={this.handleSubmit}
            premiumAthletesList={athletesList}
            athletesLoading={athletesLoading}
            onAthleteSelect={this.handleAthleteSelect}
            onAthletesSearch={this.handleAthletesSearch}
            subcategories={subcategories}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            form={formId}
            disabled={hasError || saving}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

TrainingEditor.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(
  connect((store) => {
    return {
      training: store.training,
      auth: store.auth,
      subcategories: store.subcategories,
    };
  })(TrainingEditor),
);
