import React from "react";
import {
  ClickAwayListener,
  Grow,
  IconButton,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { Emoji } from "emoji-picker-react";
import { palette } from "../../../theme/palette";

const emojiReactions = ["1f44d", "1f604", "2764-fe0f", "1f44c"];

const ChatReactions = ({
  isOpen = false,
  targetMessage = {},
  onReactionSelected,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Grow in={open}>
          <Paper elevation={0} className={classes.emojiReactions}>
            {emojiReactions.map((reaction, index) => (
              <IconButton
                size="small"
                onClick={(event) =>
                  onReactionSelected(event, targetMessage, reaction)
                }
                key={index}
              >
                <Emoji unified={reaction} size={16} />
              </IconButton>
            ))}
          </Paper>
        </Grow>
      </ClickAwayListener>
    </>
  );
};

const useStyles = makeStyles({
  emojiReactions: {
    background: palette.white,
    borderRadius: 6,
    padding: "4px 8px",
    width: "fit-content",
    display: "flex",
    gap: 8,
    alignItems: "center",
    marginTop: "7px",
    position: "absolute",
    bottom: -15,
  },
});

export default ChatReactions;
