import React from "react";
import SubscriptionComponent from "../components/subscription-details/components/subscription";
import NavbarSettings from "./common/navbar-settings";
import { useTranslation } from "react-i18next";

const SubscriptionDetails = () => {
  const { t } = useTranslation("settings");
  return (
    <NavbarSettings
      component={<SubscriptionComponent />}
      text={t("settings.subscriptionDetails")}
    />
  );
};
export default SubscriptionDetails;
