import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Typography,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  Select,
  MenuItem,
} from "@material-ui/core";
import { readTraining } from "../../../actions/training-action";
import { connect } from "react-redux";
import AthleteTrainingEditorWeekday from "../athlete-training-editor/athlete-training-editor-weekday";

const styles = (theme) => ({
  plan: {
    borderRadius: 8,
    margin: theme.spacing.unit,
    padding: 10,
    background: "#4467E0",
    display: "flex",
    alignItems: "baseline",
  },
  title: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#FFFFFF",
    textAlign: "left",
  },
  description: {
    fontFamily: "Avenir",
    fontSize: 14,
    color: "#FFFFFF",
    textAlign: "left",
  },
  plus: {
    cursor: "pointer",
  },
  addText: {
    fontFamily: "Avenir",
    fontSize: 14,
    textAlign: "left",
  },
  weekPreview: {
    marginTop: 10,
  },
});

class AthleteTrainingBlock extends Component {
  state = {
    addBlockOpen: false,
    currentPlan: null,
    currentWeekBlock: null,
    currentWeek: null,
  };

  handleDragStart = (workout, event) => {
    const { onSelectWorkout } = this.props;
    if (onSelectWorkout) onSelectWorkout(workout);
  };

  handleCloseAddBlock = () => {
    this.setState({
      ...this.state,
      addBlockOpen: false,
      currentPlan: null,
    });
  };

  handleOpenAddBlock = (plan) => {
    this.props.dispatch(readTraining(plan));
    this.setState({
      ...this.state,
      addBlockOpen: true,
      currentPlan: plan,
    });
  };

  handleBlockWeekChange = (week) => {
    this.setState({
      ...this.state,
      currentWeekBlock: week,
    });
  };

  handleWeekChange = (week) => {
    this.setState({
      ...this.state,
      currentWeek: week,
    });
  };

  render() {
    const {
      classes,
      plans,
      athleteTraining,
      onAddBlock,
      training,
    } = this.props;
    const {
      addBlockOpen,
      currentPlan,
      currentWeekBlock,
      currentWeek,
    } = this.state;

    const { durationInWeeks } = currentPlan || {};

    const blockItems = [];
    for (let i = 0; i < +durationInWeeks; i++) {
      blockItems.push(
        <MenuItem value={i} key={i}>
          week {i + 1}
        </MenuItem>,
      );
    }

    const { durationInWeeks: durationInWeeksCurrent } = athleteTraining || {};

    const weekItems = [];
    for (let i = 0; i <= +durationInWeeksCurrent; i++) {
      weekItems.push(
        <MenuItem value={i} key={i}>
          week {i + 1}
        </MenuItem>,
      );
    }

    let weekdays = [];

    const { currentTraining: currentTrainingT } = training || {};
    const { days } = currentTrainingT || {};
    weekdays = days
      ? days.slice(currentWeekBlock * 7, currentWeekBlock * 7 + 7)
      : [];

    return (
      <>
        {plans.map((plan, index) => {
          return (
            <div
              className={classes.plan}
              key={index}
              onClick={() => this.handleOpenAddBlock(plan)}
            >
              <div>
                <Typography variant="body1" className={classes.title}>
                  {plan.subcategoryObj &&
                    plan.subcategoryObj.title &&
                    (plan.subcategoryObj.title["en"]
                      ? plan.subcategoryObj.title["en"]
                      : plan.subcategoryObj.title["es"])}
                </Typography>
                <Typography variant="body1" className={classes.description}>
                  {`Level: ${plan.raceCount} - Days: ${plan.trainPerWeek} - ${plan.surface}`}
                </Typography>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/icon-plus-circle.svg`}
                alt=""
                className={classes.plus}
              />
            </div>
          );
        })}

        <Dialog
          open={addBlockOpen}
          onClose={() => this.handleCloseAddBlock()}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
        >
          <DialogContent>
            <Typography variant="body1" className={classes.addText}>
              Add week of selected plan:
            </Typography>

            <Select
              id="block-filter-select"
              value={currentWeekBlock ? currentWeekBlock : 0}
              onChange={(event) =>
                this.handleBlockWeekChange(+event.target.value)
              }
              className={classes.weekTitle}
            >
              {blockItems}
            </Select>

            <div className={classes.weekPreview}>
              {weekdays && weekdays.length
                ? weekdays.map((day, index) => {
                    return (
                      <AthleteTrainingEditorWeekday
                        key={index}
                        onWorkoutAdd={() => {}}
                        trainingDay={day}
                        dayIndex={index}
                        workoutDate={null}
                        onWorkoutDelete={() => {}}
                        onOpenChat={() => {}}
                        isPreview={true}
                        handleModal={() => {}}
                        handleModalLeave={() => {}}
                        parentLeave={() => {}}
                      />
                    );
                  })
                : ""}
            </div>

            <Typography variant="body1" className={classes.addText}>
              Into week of current user planName:
            </Typography>

            <Select
              id="block-filter-select"
              value={currentWeek ? currentWeek : 0}
              onChange={(event) => this.handleWeekChange(+event.target.value)}
              className={classes.weekTitle}
            >
              {weekItems}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleCloseAddBlock()} color="primary">
              Close
            </Button>
            <Button
              onClick={() => {
                onAddBlock(currentPlan, currentWeekBlock, currentWeek);
                this.handleCloseAddBlock();
              }}
              color="primary"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

AthleteTrainingBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  plans: PropTypes.array.isRequired,
  onSelectWorkout: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    training: store.training,
  };
})(withStyles(styles)(AthleteTrainingBlock));
