const SkillsToImprove = {
  Speed: "Speed",
  Climbs: "Climbs",
  Downhills: "Downhills",
  Endurance: "Endurance",
};

export default {
  Items: SkillsToImprove,
  itemsArray: Object.keys(SkillsToImprove).map((key) => {
    return {
      text: SkillsToImprove[key],
      value: key,
    };
  }),
};
