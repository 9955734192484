import React from "react";
import { palette } from "../../theme/palette";

const AvgHeartRateIcon = ({ width = 24, height = 24, activated = false }) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 8.2501H12.795C12.7427 8.243 12.6898 8.243 12.6375 8.2501H12.51C12.4708 8.27246 12.4332 8.29752 12.3975 8.3251C12.3557 8.35267 12.3157 8.38273 12.2775 8.4151C12.2525 8.44576 12.2299 8.47837 12.21 8.5126C12.1756 8.55984 12.1455 8.61004 12.12 8.6626L10.92 11.9701L7.7925 3.4951C7.73995 3.35031 7.64409 3.22521 7.51796 3.13681C7.39182 3.0484 7.24153 3.00098 7.0875 3.00098C6.93347 3.00098 6.78317 3.0484 6.65704 3.13681C6.53091 3.22521 6.43505 3.35031 6.3825 3.4951L4.65 8.2501H2.25C2.05109 8.2501 1.86032 8.32911 1.71967 8.46977C1.57902 8.61042 1.5 8.80118 1.5 9.0001C1.5 9.19901 1.57902 9.38977 1.71967 9.53043C1.86032 9.67108 2.05109 9.7501 2.25 9.7501H5.475C5.51763 9.72957 5.55787 9.70442 5.595 9.6751C5.63676 9.64752 5.67683 9.61747 5.715 9.5851L5.7825 9.4876C5.81825 9.44128 5.84845 9.39094 5.8725 9.3376L7.0875 5.9401L10.2075 14.5051C10.2599 14.65 10.3557 14.7753 10.4818 14.8639C10.608 14.9525 10.7584 15 10.9125 15.0001C11.0666 15 11.217 14.9525 11.3432 14.8639C11.4693 14.7753 11.5651 14.65 11.6175 14.5051L13.3425 9.7501H15.75C15.9489 9.7501 16.1397 9.67108 16.2803 9.53043C16.421 9.38977 16.5 9.19901 16.5 9.0001C16.5 8.80118 16.421 8.61042 16.2803 8.46977C16.1397 8.32911 15.9489 8.2501 15.75 8.2501Z"
        fill={activated ? palette.primary : palette.darkGray}
      />
    </svg>
  </>
);
export default AvgHeartRateIcon;
