import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { Routes } from "../../lib/routing-middleware";
import history from "../../lib/history-helper";
import EnhancedTable from "../common/enhanced-table";
import WorkoutFields from "../../constants/workout-fields";
import { withTranslation } from "react-i18next";

const styles = {
  container: {
    width: "100%",
    color: "gray",
    border: "1px solid lightgray",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 70,
  },
};

class WorkoutGrid extends Component {
  handleUpdateRequestedByDoubleClick = (row) => {
    if (row) {
      const { id } = row;
      history.push(Routes.WorkoutEditor(id));
    }
  };
  render() {
    const {
      classes,
      workout,
      onSearchParamChanged,
      sub,
      isExternalCoach,
      i18n,
    } = this.props;
    const {
      loading,
      hasBeenSearched,
      searchResult,
      deleting,
      currentFilter,
    } = workout;

    const { language } = i18n;

    const { justMine } = currentFilter;
    const { data: dataFull } = searchResult;

    let data = dataFull;
    if (justMine && sub) {
      data = dataFull.filter((w) => w.created_by_cognito_user_sub === sub);
    }

    const headers = [
      {
        id: "title",
        numeric: false,
        disablePadding: false,
        label: "Title",
        orderByOverride: "title_lower",
        bolder: true,
        multiLanguage: true,
        lngToShow: language,
        lngRep: "es",
      },
      {
        id: "estimatedTime",
        numeric: false,
        disablePadding: false,
        label: "Estimated Time",
      },
      {
        id: "description",
        numeric: false,
        disablePadding: false,
        label: "Description",
        orderByOverride: "description_lower",
        multiline: true,
        multiLanguage: true,
        lngToShow: language,
        lngRep: "es",
      },
      // {
      //   id: "trainingPeriod",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Training Period",
      // },
      // {
      //   id: "difficultyLevel",
      //   numeric: true,
      //   disablePadding: false,
      //   label: "Level",
      // },
      {
        id: "workoutType",
        numeric: false,
        disablePadding: false,
        label: "Workout Type",
      },
      // {
      //   id: "surface",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Surface",
      // },
      {
        id: "parametrizedWorkout",
        numeric: false,
        disablePadding: false,
        label: "Workout detail preview",
      },
    ];

    return loading ? (
      <LinearProgress variant="indeterminate" color="primary" />
    ) : hasBeenSearched ? (
      data.length > 0 ? (
        <EnhancedTable
          title="Workouts"
          headers={headers}
          updatable={true}
          updateRoute={Routes.WorkoutCreate}
          onUpdateRequestedByDoubleClick={
            this.handleUpdateRequestedByDoubleClick
          }
          deleting={deleting}
          deleteRoute={Routes.WorkoutDeletePath}
          onChangePage={onSearchParamChanged}
          onChangeRowsPerPage={onSearchParamChanged}
          onRequestSort={onSearchParamChanged}
          onSelectedChanged={onSearchParamChanged}
          {...searchResult}
          data={data}
          keyField={WorkoutFields.id}
          isExternalCoach={isExternalCoach}
          sub={sub}
        />
      ) : (
        <div className={classes.container}>
          No workouts found based on your current search criteria
        </div>
      )
    ) : (
      <div className={classes.container}>
        For viewing workouts, use the filters above to search
      </div>
    );
  }
}

WorkoutGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearchParamChanged: PropTypes.func,
};

export default connect((store) => {
  return {
    workout: store.workout,
  };
})(withTranslation()(withStyles(styles)(WorkoutGrid)));
