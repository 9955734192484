import ActionTypes from "../constants/action-types";

const initialState = {
  initializing: false,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.PUSHER_CHANNEL_SUBSCRIPTION_PENDING:
        return {
          ...state,
          initializing: true,
        };
      case ActionTypes.PUSHER_CHANNEL_SUBSCRIPTION_FULFILLED:
        return {
          ...state,
          initializing: false,
          channel: action.payload,
        };
      case ActionTypes.PUSHER_CHANNEL_SUBSCRIPTION_REJECTED:
        return {
          ...state,
          initializing: false,
          channel: undefined,
        };
      case ActionTypes.PUSHER_CHANNEL_UNSUBSCRIPTION_PENDING:
        return {
          ...state,
          initializing: true,
        };
      case ActionTypes.PUSHER_CHANNEL_UNSUBSCRIPTION_FULFILLED:
        return {
          ...state,
          initializing: false,
          channel: undefined,
        };
      case ActionTypes.PUSHER_CHANNEL_UNSUBSCRIPTION_REJECTED:
        return {
          ...state,
          initializing: false,
        };
      default:
        return state;
    }
  } else {
    return state;
  }
}
