import { useDispatch, useSelector } from "react-redux";
import { checkSubscription } from "../lib/subscription-plan-helper";
import { useEffect, useState } from "react";
import { getPlanLevel } from "../lib/plan-helper";
import { setUnreadMessages } from "../actions/user-plans-action";

const useUserInfo = () => {
  const {
    auth,
    profile,
    userPlans,
    strava,
    garmin,
    suunto,
    vertActivities,
    subscriptions,
  } = useSelector((state) => state);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const dispatch = useDispatch();
  const { currentUser } = auth;
  const {
    hasPlan,
    loadingHasPlan,
    currentPlan,
    plan,
    planToSelect,
    comments: messages,
    loadingComments: loadingMessages,
    unreadMessages,
    comments,
  } = userPlans;
  const { attributes } = currentUser || {};
  const { sub, name, family_name, email } = attributes || {};
  const { latest } = subscriptions || {};
  const {
    nextRaces = [],
    latestSubscriptionFinalizedTime,
    image: profilePictureFilename,
  } = currentPlan || {};
  const { currentFilter, currentDate, toDate } = profile;
  const { currentTraining, garmin_oauth_token, garmin_oauth_token_secret } =
    currentPlan || {};
  const { currentStartDate, durationInWeeks, chainedPlans } =
    currentTraining || {};
  const { activities: activitiesStrava } = strava;
  const { activities: activitiesGarmin, connected } = garmin;
  const { activities: activitiesSuunto } = suunto;
  const { activities: activitiesVert } = vertActivities;
  const activities = [
    ...(activitiesStrava || []),
    ...(activitiesGarmin || []),
    ...(activitiesSuunto || []),
    ...(activitiesVert || []),
  ];
  const level = getPlanLevel(currentTraining);

  const isGarminConnected =
    (currentPlan &&
      currentPlan.garmin_oauth_token &&
      currentPlan.garmin_oauth_token_secret) ||
    connected;

  useEffect(() => {
    setIsSubscribed(
      checkSubscription(subscriptions, latestSubscriptionFinalizedTime),
    );
  }, [subscriptions, latestSubscriptionFinalizedTime]);

  useEffect(() => {
    if (comments && comments.length > 0) {
      dispatch(
        setUnreadMessages(comments.filter((c) => c.isRead !== true).length),
      );
    }
  }, [comments]);

  return {
    userPlans,
    currentUser,
    attributes,
    subscriptions,
    sub,
    name,
    family_name,
    email,
    currentPlan,
    planToSelect,
    plan,
    currentFilter,
    currentDate,
    currentStartDate,
    durationInWeeks,
    toDate,
    currentTraining,
    nextRaces,
    activities,
    latest,
    latestSubscriptionFinalizedTime,
    isSubscribed,
    auth,
    chainedPlans,
    level,
    messages,
    profilePictureFilename,
    loadingMessages,
    isGarminConnected,
    loadingHasPlan,
    hasPlan,
    unreadMessages,
  };
};
export default useUserInfo;
