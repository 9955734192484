import React, { Component, memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { withTranslation } from "react-i18next";
import CalendarToday from "@material-ui/icons/CalendarToday";
import InputAdornment from "@material-ui/core/InputAdornment";
import { dateFormat } from "../../../lib/date-helper";
import PlanFieldTransition from "./plan-field-transition";
import withWidth from "@material-ui/core/withWidth";
import DayjsUtils from "@date-io/dayjs";
import dayjs from "dayjs";

const styles = (theme) => ({
  gridItem: {
    margin: "20px 16px",
    [theme.breakpoints.up("md")]: {
      margin: "20px 0px 12px 0px",
    },
  },
  calendarIcon: {
    color: "#9E9E9E",
  },
});

class PlanFieldDate extends Component {
  handleDateChange = (date) => {
    const { id, onChange, onError } = this.props;
    if (id) {
      if (!date) return onChange({ id: id, value: undefined });
      try {
        if (onChange) onChange({ id: id, value: date.format(dateFormat) });
      } catch (error) {
        if (onError) onError(error);
      }
    }
  };

  render() {
    const {
      classes,
      xs,
      sm,
      md,
      lg,
      xl,
      id,
      value,
      minDate,
      maxDate,
      minDateMessage,
      maxDateMessage,
      defaultValue,
      fullWidth,
      t,
      width,
    } = this.props;
    const patchedValue =
      value || defaultValue
        ? dayjs(value || defaultValue, dateFormat).toISOString()
        : null;

    return (
      <PlanFieldTransition>
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          className={classes.gridItem}
        >
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <div>
              <DatePicker
                id={id}
                value={patchedValue}
                format="DD/MM/YYYY"
                placeholder="DD/MM/AAAA"
                mask={(value) =>
                  value
                    ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
                    : []
                }
                minDate={minDate}
                maxDate={maxDate}
                autoOk
                clearable
                keyboard
                disableOpenOnEnter
                animateYearScrolling={false}
                onChange={this.handleDateChange}
                fullWidth={true}
                maxDateMessage={maxDateMessage}
                inputVariant="outlined"
                minDateMessage={minDateMessage}
                invalidDateMessage={t("Invalid date format")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment disableTypography position="end">
                      <CalendarToday className={classes.calendarIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
        </Grid>
      </PlanFieldTransition>
    );
  }
}

PlanFieldDate.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  minDateMessage: PropTypes.string,
  maxDateMessage: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  fullWidth: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

PlanFieldDate.defaultProps = {
  value: "",
};

export default withTranslation("messages")(
  withWidth()(withStyles(styles, { withTheme: true })(memo(PlanFieldDate))),
);
