import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const styles = (theme) => ({
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  darkPrimary: {
    color: theme.palette.primary.dark,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondaryDark: {
    color: theme.palette.text.secondaryDark,
  },
});

const FontAwesomeIcon = (props) => {
  const { variant, icon, color, classes, className, size } = props;

  return (
    <i
      className={classNames(
        `fa${variant[0]}`,
        `fa-${icon}`,
        { [classes[color]]: color },
        { [`fa-${size}`]: size },
        className,
      )}
    />
  );
};

FontAwesomeIcon.propTypes = {
  variant: PropTypes.oneOf(["solid", "regular", "light", "brands"]).isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "darkPrimary",
    "textPrimary",
    "textSecondaryDark",
  ]),
  classes: PropTypes.object.isRequired,
  size: PropTypes.oneOf(["xs", "sm", "lg", "2x", "3x", "5x", "7x", "10x"]),
};

FontAwesomeIcon.defaultProps = {
  variant: "brands",
};

export default withStyles(styles, { withTheme: true })(FontAwesomeIcon);
