import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import { withTranslation } from "react-i18next";

import PlanFieldTransition from "./plan-field-transition";

const styles = (theme) => ({
  buttonText: {
    background: "rgba(255, 255, 255, 0.3)",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 1.7,
    paddingLeft: theme.spacing.unit * 1.7,
    width: "100%",
    textTransform: "initial",
    border: 0,
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "initial",
    textAlign: "center",
  },

  buttonTextNormal: {
    background: "rgba(255, 255, 255, 0.3)",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 1.7,
    paddingLeft: theme.spacing.unit * 1.7,
    width: "100%",
    textTransform: "initial",
    border: 0,
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 20,
    lineHeight: "initial",
    textAlign: "center",
  },

  buttonContainer: {
    margin: "16px 16px",
    boxShadow: "0px 10px 60px rgba(0, 0, 0, 0.1)",
    borderRadius: 12,
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.dark,
    textAlign: "left",
  },
  button: {
    textAlign: "left",
    border: 0,

    paddingTop: "70%",
    width: "100%",
    position: "relative",
  },
  subtext: {
    fontSize: 12,
    fontWeight: "normal",
    color: "#9E9E9E",
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 14,
    background: "rgba(63, 81, 181, 0.12)",
    padding: "2px 5px",
  },

  buttonLabel: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

class PlanFieldStepperSelect extends Component {
  handleButtonClick = (value) => {
    const { onSelect, id } = this.props;
    if (onSelect) {
      onSelect({ id, value });
    }
  };

  render() {
    const { classes, items, value, t } = this.props;
    return (
      <PlanFieldTransition>
        <Grid container>
          {items.map((item, index) => {
            const { text, value: itemValue, icon, subtext, subtitle } = item;
            return (
              <Grid item key={index} xs={6}>
                <div className={classes.buttonContainer}>
                  <Button
                    onClick={(event) => this.handleButtonClick(itemValue)}
                    className={
                      value === itemValue
                        ? classes.selectedButton
                        : classes.button
                    }
                    variant="outlined"
                    fullWidth
                    classes={{
                      label: classes.buttonLabel,
                    }}
                  >
                    {icon ? (
                      <img
                        src={`${process.env.PUBLIC_URL}/${icon}`}
                        alt=""
                        className={classes.imgIcon}
                        style={{
                          width: 42,
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <div className={classes.buttonTextNormal}>
                      {t(text)}
                      {subtitle ? (
                        <>
                          <br></br>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: subtitle,
                            }}
                            className={classes.subtitle}
                          ></span>
                        </>
                      ) : (
                        ""
                      )}
                      {subtext ? (
                        <>
                          <br></br>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: subtext,
                            }}
                            className={classes.subtext}
                          ></span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Button>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </PlanFieldTransition>
    );
  }
}

PlanFieldStepperSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  items: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation("constants")(
  withStyles(styles, { withTheme: true })(PlanFieldStepperSelect),
);
