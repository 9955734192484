import { dateFormat } from "./date-helper";
import moment from "moment";
import { Capacitor } from "@capacitor/core";

export const createAnalyticData = (analyticData, event) => {
  const { username, userPlans, subscriptions } = analyticData;

  const { currentPlan } = userPlans || {};

  const {
    selectedPlan: selectedPlanCurrent,
    distance,
    sex,
    surface,
    trainPerWeek,
    currentLocation,
    currentTraining,
    lastLogin,
    longestRun,
    raceCount,
    trainHoursPerWeek,
    comments,
  } = currentPlan || {};

  const { selectedPlan, subcategory, id } = currentTraining || {};

  let goal = 0;
  let runningStreak = 0;
  if (
    currentTraining &&
    currentTraining.days &&
    currentTraining.days.filter((day) => day).length > 0
  ) {
    //running streak
    goal = currentTraining.days
      .filter((day) => day)
      .map((day) => day.length)
      .reduce((sum, a) => sum + a);
    runningStreak = currentTraining.days
      .filter((day) => day)
      .map((day) => day.filter((workout) => workout.isDone).length)
      .reduce((sum, a) => sum + a);
  }

  let totalComments = 0;
  if (comments) {
    totalComments = comments.length;
  }

  return {
    type: 1,
    description: event,
    extraData: {
      username,
      selectedPlan: selectedPlan
        ? selectedPlan
        : selectedPlanCurrent
        ? selectedPlanCurrent
        : "Not plan selected",
      distance,
      sex,
      surface,
      trainPerWeek,
      currentLocation,
      subcategory,
      planId: id,
      subscriptionsStatus:
        typeof subscriptions.latest !== "undefined"
          ? subscriptions.latest.status
          : "",
      lastLogin: moment(lastLogin).format(dateFormat),
      longestRun,
      raceCount,
      trainHoursPerWeek,
      runningStreak,
      runningStreakGoal: goal,
      totalComments,
      os: Capacitor.isNativePlatform() ? Capacitor.getPlatform() : "web",
      device: Capacitor.getPlatform(),
    },
  };
};
