import React from "react";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  image: {
    cursor: "pointer",
  },
});

const Allez = ({ onAddAllez, classes, full }) => {
  const animate = (event) => {
    event.target.animate(
      [
        { transform: "scale3d(1, 1, 1)", offset: 0 },
        { transform: "scale3d(1.2, 1.2, 1.2)", offset: 0.5 },
        { transform: "scale3d(1, 1, 1)", offset: 1 },
      ],
      {
        duration: 500,
      },
    );
  };

  return (
    <img
      alt="allez"
      className={classes.image}
      src={`${process.env.PUBLIC_URL}/icon-allez${full ? "-full" : ""}.svg`}
      onClick={(event) => {
        onAddAllez();
        animate(event);
      }}
    />
  );
};

export default withStyles(styles, { withTheme: true })(Allez);
