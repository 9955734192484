import React, { useRef, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import {
  Typography,
  Dialog,
  DialogContent,
  withMobileDialog,
} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CameraAlt from "@material-ui/icons/CameraAlt";
import TimerOutlined from "@material-ui/icons/TimerOutlined";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import BottomBar from "../components/bottom-modal";
import history from "../../../../lib/history-helper";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import DashboardGpsMap from "../dashboard-gps-map";
import { registerPlugin } from "@capacitor/core";
import PauseIcon from "@material-ui/icons/Pause";
import { getActivityCalculations, round } from "../../../../lib/units-helper";
import DeleteConfirmationDialog from "../../../../components/common/delete-confirmation-dialog";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentActivityVert,
  addWatcher,
  clearActivityVert,
  clearWatchers,
} from "../../../../actions/vert-activity-action";
import { setFormTrackerStatus } from "../../../../actions/workout-action";
import Close from "@material-ui/icons/Close";
import TrackingRouter from "../router/tracking-router";
const BackgroundGeolocation = registerPlugin("BackgroundGeolocation");

const headerCommon = {
  backgroundColor: "#FFF",
  borderRadius: "0px 0px 12px 12px",
  padding: "6px 16px 0px 16px",
  boxSizing: "border-box",
  width: "100%",
  display: "flex",
  flexDirection: "column",
};

const styles = (theme) => ({
  header: {
    ...headerCommon,
    height: "auto",
  },

  headerDeployed: {
    ...headerCommon,
    height: "100%",
    marginBottom: "10px",
  },
  statsParent: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    transition: "all .6s",
  },
  statsTitle: {
    fontSize: "34px",
    fontWeight: "bold",
    textAlign: "center",
  },
  statsSubtitle: {
    fontSize: "16px",
    color: "#9E9E9E",
    textAlign: "center",
    marginTop: -5,
  },
  iconParentMargin: {
    display: "flex",
    justifyContent: "center",
    marginTop: 12,
    flex: "1 1 auto",
    alignItems: "flex-end",
  },
  iconParent: {
    display: "flex",
    justifyContent: "center",
  },
  colorPrimary: {
    backgroundColor: "#EDEDED",
  },
  barColorPrimary: {
    backgroundColor: "#1A629B",
  },
  chartParent: {
    height: "200px",
    width: "90%",
    margin: "18px",
  },
  chartTitle: {
    textAlign: "center",
    fontSize: "18px",
    marginTop: "12px",
    fontWeight: "bold",
  },
  dialogPaper: {
    //height: "calc(100% - env(safe-area-inset-bottom) - env(safe-area-inset-top))",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  dialogContentRoot: {
    height: "100%",
  },
  downIcon: {
    fontSize: 35,
    transition: "all .6s",
  },
  upIcon: {
    fontSize: 35,
    transform: "rotate(180deg)",
    transition: "all .6s",
  },
  footer: {
    padding: "20px 0",
    backgroundColor: "#FFF",
    width: "100%",
    boxSizing: "border-box",
  },
  footerContent: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  startBtn: {
    width: 70,
    height: 70,
    borderRadius: "50%",
    backgroundColor: "#FF5722",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnText: {
    color: "#FFF",
    fontWeight: "bold",
  },
  icon: {
    fontSize: 40,
    color: "#9E9E9E",
    zIndex: 10009,
  },
  main: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    position: "relative",
    justifyContent: "center",
  },
  mapSection: {
    height: "100%",
    backgroundColor: "#eaeff4",
    position: "relative",
    overflowY: "hidden",
  },
  mapPlaceholder: {
    background: "#fff",
    borderRadius: 10,
    width: "fit-content",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    padding: 10,
    boxSizing: "border-box",
  },
  mapPlaceholderText: {
    fontSize: 14,
    fontWeight: "bold",
  },
  statsContainer: {
    width: "50%",

    "&:not(:nth-child(1)) + &:not(:nth-child(2))": {
      marginTop: 10,
    },
  },
  pauseIcon: {
    color: "#FFFFFF",
  },
  closeParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: [
      {
        type: "linear",
        grid: {
          display: false,
          color: "#DDDDDD",
          drawBorder: false,
          zeroLineColor: "#DDDDDD",
        },
        ticks: {
          minor: {
            fontFamily: "Avenir",
            fontSize: 12,
            fontColor: "#9E9E9E",
          },
          padding: 10,
          beginAtZero: true,
        },
      },
    ],
    y: [
      {
        grid: {
          drawBorder: false,
          color: "rgba(0, 0, 0, 0.05)",
          zeroLineColor: "rgba(0, 0, 0, 0.05)",
        },
        ticks: {
          fontFamily: "Avenir",
          fontSize: 12,
          fontColor: "#9E9E9E",
        },
      },
    ],
  },
  animation: {
    easing: "linear",
    duration: 1000,
  },
};

const takePicture = async () => {
  const image = await Camera.getPhoto({
    quality: 90,
    allowEditing: true,
    resultType: CameraResultType.DataUrl,
    source: CameraSource.Camera,
    saveToGallery: true,
  });
};

const Tracker = ({ classes, t }) => {
  const { userPlans, vertActivities } = useSelector((state) => state);
  const { currentPlan } = userPlans || {};
  const { currentTraining = {} } = currentPlan || {};
  const { selectedTrainingDayWorkoutTemp } = currentTraining || {};
  const { plannedDate: currentTrainingDate } =
    selectedTrainingDayWorkoutTemp || {};
  const dispatch = useDispatch();

  const [showConfirm, setShowConfirm] = React.useState(false);

  const handleShowConfirm = () => setShowConfirm((showConfirm) => !showConfirm);

  const [showAll, setShowAll] = React.useState(false);
  const [showRate, setShowRate] = React.useState(false);
  const showMore = () => setShowAll((showAll) => !showAll);

  const [showMap, setShowMap] = React.useState(false);
  const [currentLocation, setCurrentLocation] = React.useState(null);

  const [isTracking, setIsTracking] = React.useState(false);
  const isTrackingRef = useRef(isTracking);

  const [trackingData, setTrackingData] = React.useState([]);
  const trackingDataRef = useRef(trackingData);

  const [variance, setVariance] = React.useState(-1);
  const varianceRef = useRef(variance);
  const [lastLat, setLastLat] = React.useState(-1);
  const lastLatRef = useRef(lastLat);
  const [lastLng, setLastLng] = React.useState(-1);
  const lastLngRef = useRef(lastLng);
  const [lastTimestamp, setLastTimestamp] = React.useState(-1);
  const lastTimestampRef = useRef(lastTimestamp);
  const [minAccuracy, setMinAccuracy] = React.useState(1);
  const minAccuracyRef = useRef(minAccuracy);
  const [decay, setDecay] = React.useState(3);
  const decayRef = useRef(decay);

  const [varianceAltitude, setVarianceAltitude] = React.useState(-1);
  const varianceAltitudeRef = useRef(varianceAltitude);
  const [lastAltitude, setLastAltitude] = React.useState(-1);
  const lastAltitudeRef = useRef(lastAltitude);
  const [lastTimestampAltitude, setLastTimestampAltitude] = React.useState(-1);
  const lastTimestampAltitudeRef = useRef(lastTimestampAltitude);
  const [minAccuracyAltitude, setMinAccuracyAltitude] = React.useState(1);
  const minAccuracyAltitudeRef = useRef(minAccuracyAltitude);
  const [decayAltitude, setDecayAltitude] = React.useState(3);
  const decayAltitudeRef = useRef(decayAltitude);

  const [currentTime, setCurrentTime] = React.useState(0);
  const currentTimeRef = useRef(currentTime);

  const [currentTimeTimer, setCurrentTimeTimer] = React.useState(null);
  const currentTimeTimerRef = useRef(currentTimeTimer);

  const [isActive, setIsActive] = React.useState(false);
  const isActiveRef = useRef(isActive);

  const [stats, setStats] = React.useState([
    {
      stat: "0",
      label:
        localStorage.getItem("units") === "miles"
          ? t("Miles")
          : t("Kilometers"),
    },
    { stat: "00:00:00", label: t("Time") },
    {
      stat: "0:00",
      label:
        localStorage.getItem("units") === "miles"
          ? t("Pace /mile")
          : t("Pace /km"),
    },
    {
      stat: "0:00",
      label:
        localStorage.getItem("units") === "miles"
          ? t("Avg Pace /mile")
          : t("Avg Pace /km"),
    },
    {
      stat: "0",
      label:
        localStorage.getItem("units") === "miles"
          ? t("Elevation /feet")
          : t("Elevation /m"),
    },
    {
      stat: "0",
      label:
        localStorage.getItem("units") === "miles"
          ? t("Avg Uphill speed feet/h")
          : t("Avg Uphill speed m/h"),
    },
  ]);

  const statsRef = useRef(stats);

  const [elevationData, setElevationData] = React.useState({
    labels: ["0"],
    datasets: [
      {
        backgroundColor: "#E7F3FC",
        borderWidth: 0,
        data: [0],
        borderColor: "#3F51B5",
        fill: "start",
      },
    ],
  });

  function startTracking() {
    if (showMap) {
      setIsActive(true);
      setIsTracking(true);
      setShowRate(false);
      setCurrentTimeTimer(
        setTimeout(() => {
          addSecond();
        }, 1000),
      );
    }
  }

  function addSecond() {
    const newTime = currentTimeRef.current + 1;

    const h = newTime ? Math.floor(newTime / 3600) : 0;
    const m = newTime ? Math.floor((newTime % 3600) / 60) : 0;

    const s = newTime ? Math.round(newTime - h * 3600 - m * 60) : 0;
    const timeStr = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${
      s < 10 ? `0${s}` : s
    }`;

    const newStats = statsRef.current;
    newStats[1] = {
      stat: timeStr,
      label: t("Time"),
    };

    setStats(newStats);
    setCurrentTime(currentTimeRef.current + 1);

    setCurrentTimeTimer(
      setTimeout(() => {
        addSecond();
      }, 1000),
    );
  }

  function stopTracking() {
    setIsTracking(false);
    setShowRate(true);
    clearTimeout(currentTimeTimerRef.current);
    setCurrentTimeTimer(null);
    setIsActive(false);
  }

  const openBottomModal = () => setShowRate(true);

  const goToTraining = (id, date) =>
    history.replace(`/tracking/tracker/${id}/${date.replace(/[/]/g, "-")}`);

  const goToSumUp = () => {
    const path = window.location.href;
    const splitedPath = path.split("/");
    const date =
      currentTrainingDate ||
      (splitedPath[6] && splitedPath[6].replace(/[-]/g, "/"));

    const currentDate = moment(new Date(), "DD/MM/YYYY");
    const duration = moment.duration(
      currentDate.diff(moment(date, "DD/MM/YYYY")),
    );
    const difference = duration.asDays();

    if (splitedPath[6] && splitedPath[5]) {
      dispatch(
        setFormTrackerStatus({
          fromTracker: true,
          id: splitedPath[5],
          date: splitedPath[6],
        }),
      );
      if (Math.floor(difference) === 0)
        return goToTraining(splitedPath[5], splitedPath[6]);
      if (
        Math.floor(difference) === 1 &&
        currentDate.day() !== moment(date, "DD/MM/YYYY").day()
      )
        return goToTraining(splitedPath[5], splitedPath[6]);
      return history.replace("/tracking/tracker/no-workout");
    }

    const todayDate = parseDate(currentDate);
    const previousDate = currentDate.subtract(1, "days");
    const parsedPrevious = parseDate(previousDate);
    const resultToday = (currentTraining.days || []).find(
      (item) => item && item[0].plannedDate == todayDate,
    );
    const resultPreviousDay = (currentTraining.days || []).find(
      (item) => item && item[0].plannedDate == parsedPrevious,
    );
    const plannedCurrentDate =
      resultToday && resultToday.length
        ? moment(resultToday[0].plannedDate, "DD/MM/YYYY").date()
        : null;

    dispatch(setFormTrackerStatus({ fromTracker: true }));

    if (
      resultToday &&
      resultToday.length &&
      new Date().getDate() == plannedCurrentDate
    )
      return goToTraining(resultToday[0].id, resultToday[0].plannedDate);

    if (resultPreviousDay && resultPreviousDay.length)
      return goToTraining(
        resultPreviousDay[0].id,
        resultPreviousDay[0].plannedDate,
      );

    return history.replace("/tracking/tracker/no-workout");
  };

  const parseDate = (date) => {
    const localDate = new Date(date);
    let day = localDate.getDate();
    let month = localDate.getMonth() + 1;
    if (day < 10) day = `0${day}`;
    if (month < 10) month = `0${month}`;
    const year = localDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const goToDash = (e) => {
    e.preventDefault();
    setIsActive(false);
    setIsTracking(false);
    handleClearTimer();
    dispatch(setFormTrackerStatus({}));
    dispatch(clearActivityVert());
    if (vertActivities.watchers) {
      vertActivities.watchers.forEach((watcher) => {
        BackgroundGeolocation.removeWatcher({
          id: watcher,
        });
      });
      dispatch(clearWatchers());
    }
    history.push("/");
  };

  const handleClearTimer = () => {
    setCurrentTime(0);
    if (currentTimeTimerRef.current) {
      clearTimeout(currentTimeTimerRef.current);
      setCurrentTimeTimer(null);
    }
  };

  const smoothingProcess = (lat, lng, accuracy, timestampInMs) => {
    if (accuracy < minAccuracyRef.current) accuracy = minAccuracyRef.current;

    let finalLat = lat;
    let finalLng = lng;
    let finalVariance = varianceRef.current;

    if (varianceRef.current < 0) {
      setLastTimestamp(timestampInMs);
      setLastLat(lat);
      setLastLng(lng);
      setVariance(accuracy * accuracy);
    } else {
      const timeIncMs = timestampInMs - lastTimestampRef.current;

      if (timeIncMs > 0) {
        finalVariance =
          variance + (timeIncMs * decayRef.current * decayRef.current) / 1000;
        setVariance(finalVariance);
        setLastTimestamp(timestampInMs);
      }

      const _k = finalVariance / (finalVariance + accuracy * accuracy);

      finalLat = lastLatRef.current + _k * (lat - lastLatRef.current);
      finalLng = lastLngRef.current + _k * (lng - lastLngRef.current);

      setLastLat(finalLat);
      setLastLng(finalLng);

      setVariance((1 - _k) * finalVariance);
    }

    return { latitude: finalLat, longitude: finalLng };
  };

  const smoothingProcessAltitude = (altitude, accuracy, timestampInMs) => {
    if (accuracy < minAccuracyAltitudeRef.current)
      accuracy = minAccuracyAltitudeRef.current;

    let finalAltitude = altitude;
    let finalVariance = varianceAltitudeRef.current;

    if (varianceAltitudeRef.current < 0) {
      setLastTimestampAltitude(timestampInMs);
      setLastAltitude(altitude);
      setVarianceAltitude(accuracy * accuracy);
    } else {
      const timeIncMs = timestampInMs - lastTimestampAltitudeRef.current;

      if (timeIncMs > 0) {
        finalVariance =
          varianceAltitude +
          (timeIncMs * decayAltitudeRef.current * decayAltitudeRef.current) /
            1000;
        setVarianceAltitude(finalVariance);
        setLastTimestampAltitude(timestampInMs);
      }

      const _k = finalVariance / (finalVariance + accuracy * accuracy);

      finalAltitude =
        lastAltitudeRef.current + _k * (altitude - lastAltitudeRef.current);

      if (
        finalAltitude - lastAltitudeRef.current < 1 &&
        finalAltitude - lastAltitudeRef.current > -1
      ) {
        setLastAltitude(finalAltitude);
        setVarianceAltitude((1 - _k) * finalVariance);
      } else if (finalAltitude - lastAltitudeRef.current > 1) {
        setLastAltitude(lastAltitudeRef.current + 1);
      } else {
        setLastAltitude(lastAltitudeRef.current - 1);
      }
    }

    return { altitude: finalAltitude };
  };

  const calculateStats = (calculations, location = null) => {
    const units = localStorage.getItem("units") === "miles" ? "miles" : "kms";

    let timeStr = "";
    if (calculations.time > currentTimeRef.current) {
      const h = calculations.time ? Math.floor(calculations.time / 3600) : 0;
      const m = calculations.time
        ? Math.floor((calculations.time % 3600) / 60)
        : 0;

      const s = calculations.time
        ? Math.round(calculations.time - h * 3600 - m * 60)
        : 0;
      timeStr = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${
        s < 10 ? `0${s}` : s
      }`;
      setCurrentTime(calculations.time);
    } else {
      const h = currentTimeRef.current
        ? Math.floor(currentTimeRef.current / 3600)
        : 0;
      const m = currentTimeRef.current
        ? Math.floor((currentTimeRef.current % 3600) / 60)
        : 0;

      const s = currentTimeRef.current
        ? Math.round(currentTimeRef.current - h * 3600 - m * 60)
        : 0;
      timeStr = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${
        s < 10 ? `0${s}` : s
      }`;
    }

    const currentPaceRaw =
      location && location.speed
        ? units === "miles"
          ? (1 / location.speed / 60) * 1609
          : (1 / location.speed / 60) * 1000
        : 0;
    const leftover = currentPaceRaw % 1;
    const minutes = currentPaceRaw - leftover;
    const seconds = Math.round(leftover * 60);

    const currentPace = {
      minutes: minutes && isTrackingRef.current && minutes < 60 ? minutes : 0,
      seconds: seconds && isTrackingRef.current && minutes < 60 ? seconds : 0,
    };

    setStats([
      {
        stat: round(calculations.distance, 2),
        label: units === "miles" ? t("Miles") : t("Kilometers"),
      },
      { stat: timeStr, label: t("Time") },

      {
        stat: `${currentPace.minutes}:${(currentPace.seconds < 10 ? "0" : "") +
          currentPace.seconds}`,
        label: units === "miles" ? t("Pace /mile") : t("Pace /km"),
      },
      {
        stat: `${calculations.pace.minutes}:${(calculations.pace.seconds < 10
          ? "0"
          : "") + calculations.pace.seconds}`,
        label: units === "miles" ? t("Avg Pace /mile") : t("Avg Pace /km"),
      },
      {
        stat: round(calculations.elevation, units === "miles" ? 0 : 2),
        label: units === "miles" ? t("Elevation /feet") : t("Elevation /m"),
      },
      {
        stat: round(calculations.uphillPace, 0),
        label:
          units === "miles"
            ? t("Avg Uphill speed feet/h")
            : t("Avg Uphill speed m/h"),
      },
    ]);

    const elevationSamples = calculations.samples
      ? calculations.samples.map((s) => {
          return {
            x: s.distance,
            y: s.elevation,
          };
        })
      : [];

    const filteredElevationSamples =
      elevationSamples && elevationSamples.length ? [elevationSamples[0]] : [];
    let currentEdge = 0.01;
    for (let index = 0; index < elevationSamples.length; index++) {
      const element = elevationSamples[index];
      if (element.x > currentEdge) {
        filteredElevationSamples.push(element);
        currentEdge += 0.01;
      }
    }

    let labels = [];
    let divider = 1;
    if (calculations.distance) {
      if (calculations.distance / 25 > 1) {
        divider = Math.round(calculations.distance / 25);
      }

      for (let index = 0; index < calculations.distance; index += divider) {
        labels.push(index);
      }
    }

    labels = [...labels, ...filteredElevationSamples.map((e) => e.x)];

    labels.sort((a, b) => a - b);

    setElevationData({
      labels: ["0"],
      datasets: [
        {
          borderColor: "#1A629B",
          borderWidth: 1,
          backgroundColor: "rgba(63, 81, 181, 0.05)",
          pointBorderWidth: 0,
          pointRadius: 0,
          pointHoverRadius: 0,
          pointBackgroundColor: "#1A629B",
          data: filteredElevationSamples,
        },
      ],
    });
  };

  useEffect(() => {
    if (!showMap) {
      BackgroundGeolocation.addWatcher(
        {
          // If the "backgroundMessage" option is defined, the watcher will
          // provide location updates whether the app is in the background or the
          // foreground. If it is not defined, location updates are only
          // guaranteed in the foreground. This is true on both platforms.

          // On Android, a notification must be shown to continue receiving
          // location updates in the background. This option specifies the text of
          // that notification.
          backgroundMessage: t(
            "App is running in background. Click to view location.",
          ),

          // The title of the notification mentioned above. Defaults to "Using
          // your location".
          backgroundTitle: t("GPS Tracking active."),

          // Whether permissions should be requested from the user automatically,
          // if they are not already granted. Defaults to "true".
          requestPermissions: true,

          // If "true", stale locations may be delivered while the device
          // obtains a GPS fix. You are responsible for checking the "time"
          // property. If "false", locations are guaranteed to be up to date.
          // Defaults to "false".
          stale: false,

          // The minimum number of metres between subsequent locations. Defaults
          // to 0.
          distanceFilter: 0,
        },
        (location, error) => {
          if (error) {
            if (error.code === "NOT_AUTHORIZED") {
              if (
                window.confirm(
                  "This app needs your location, " +
                    "but does not have permission.\n\n" +
                    "Open settings now?",
                )
              ) {
                // It can be useful to direct the user to their device's
                // settings when location permissions have been denied. The
                // plugin provides the 'openSettings' method to do exactly
                // this.
                BackgroundGeolocation.openSettings();
              }
            }
            return console.error(error);
          }

          setCurrentLocation(location);

          if (!currentLocation && location) {
            setShowMap(true);
          }

          if (isTrackingRef.current) {
            setTrackingData([
              ...trackingDataRef.current,
              {
                ...location,
                ...smoothingProcess(
                  location.latitude,
                  location.longitude,
                  location.accuracy,
                  location.time,
                ),
                ...smoothingProcessAltitude(
                  location.altitude,
                  location.altitudeAccuracy,
                  location.time,
                ),
              },
            ]);
          } else {
            if (trackingDataRef.current.length > 0) {
              if (
                !trackingDataRef.current[trackingDataRef.current.length - 1]
                  .paused
              ) {
                setTrackingData([
                  ...trackingDataRef.current,
                  {
                    ...location,
                    paused: true,
                    ...smoothingProcess(
                      location.latitude,
                      location.longitude,
                      location.accuracy,
                      location.time,
                    ),
                    ...smoothingProcessAltitude(
                      location.altitude,
                      location.altitudeAccuracy,
                      location.time,
                    ),
                  },
                ]);
              }
            }
          }

          if (trackingDataRef.current.length > 0) {
            const units =
              localStorage.getItem("units") === "miles" ? "miles" : "kms";
            const calculations = getActivityCalculations(
              { rawSamples: trackingDataRef.current },
              units,
              "vert.run",
            );

            if (isActiveRef.current) {
              dispatch(setCurrentActivityVert(calculations));
            }

            calculateStats(calculations, location);
          }
        },
      ).then((watcher_id) => {
        dispatch(addWatcher(watcher_id));
      });
    }

    if (
      vertActivities &&
      vertActivities.currentActivity &&
      (!trackingDataRef.current || !trackingDataRef.current.length)
    ) {
      setTrackingData(vertActivities.currentActivity.rawSamples);
      calculateStats(vertActivities.currentActivity);
    }

    isTrackingRef.current = isTracking;
    trackingDataRef.current = trackingData;
    varianceRef.current = variance;
    lastLatRef.current = lastLat;
    lastLngRef.current = lastLng;
    lastTimestampRef.current = lastTimestamp;
    minAccuracyRef.current = minAccuracy;
    decayRef.current = decay;
    currentTimeRef.current = currentTime;
    currentTimeTimerRef.current = currentTimeTimer;
    statsRef.current = stats;
    isActiveRef.current = isActive;
  }, [
    isTracking,
    trackingData,
    variance,
    lastLat,
    lastLng,
    lastTimestamp,
    minAccuracy,
    decay,
    currentTime,
    currentTimeTimer,
    stats,
    isActive,
  ]);

  return (
    <>
      {window && (
        <Dialog
          fullWidth={true}
          fullScreen={true}
          open={true}
          onClose={null}
          aria-labelledby="tracking"
          scroll="body"
          maxWidth="md"
          className={classes.dialog}
          classes={{
            paper: classes.dialogPaper,
          }}
        >
          <DialogContent
            style={{ height: "100%", padding: 0, background: "#eaeff4" }}
          >
            <div className={classes.main}>
              <div
                className={!showAll ? classes.header : classes.headerDeployed}
              >
                <div className={classes.closeParent}>
                  <div onClick={handleShowConfirm}>
                    <Close />
                  </div>
                </div>
                <div className={classes.statsParent}>
                  {(showAll ? stats : stats.slice(0, 2)).map((item) => (
                    <>
                      <div className={classes.statsContainer}>
                        <Typography
                          variant="body1"
                          className={classes.statsTitle}
                        >
                          {item.stat}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.statsSubtitle}
                        >
                          {item.label}
                        </Typography>
                      </div>
                    </>
                  ))}

                  {showAll && (
                    <div className={classes.chartParent}>
                      <Typography className={classes.chartTitle}>
                        {t("Elevation profile")}
                      </Typography>
                      <Line data={elevationData} options={options}></Line>
                    </div>
                  )}
                </div>

                <div
                  className={
                    !showAll ? classes.iconParent : classes.iconParentMargin
                  }
                >
                  <div onClick={showMore}>
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      className={!showAll ? classes.downIcon : classes.upIcon}
                    />
                  </div>
                </div>
              </div>

              {!showAll && (
                <div className={classes.mapSection}>
                  {showMap ? (
                    <DashboardGpsMap
                      currentLocation={currentLocation}
                      trackingData={trackingData}
                    ></DashboardGpsMap>
                  ) : (
                    <div className={classes.mapPlaceholder}>
                      <Typography
                        variant="body1"
                        className={classes.mapPlaceholderText}
                      >
                        {t("Acquiring GPS...")}
                      </Typography>
                    </div>
                  )}
                </div>
              )}

              <footer className={classes.footer}>
                <div className={classes.footerContent}>
                  <div onClick={goToSumUp}>
                    <TimerOutlined className={classes.icon} />
                  </div>
                  {isTracking ? (
                    <div className={classes.startBtn} onClick={stopTracking}>
                      <PauseIcon
                        fontSize="large"
                        className={classes.pauseIcon}
                      />
                    </div>
                  ) : (
                    <div
                      className={classes.startBtn}
                      onClick={startTracking}
                      style={{
                        opacity: showMap ? 1 : 0.5,
                      }}
                    >
                      <Typography variant="body2" className={classes.btnText}>
                        {t("Start").toUpperCase()}
                      </Typography>
                    </div>
                  )}

                  <div onClick={takePicture}>
                    <CameraAlt className={classes.icon} />
                  </div>
                </div>
              </footer>
            </div>

            <BottomBar
              showRate={showRate}
              showingRate={startTracking}
              openMethod={openBottomModal}
              onClearTimer={handleClearTimer}
            />
          </DialogContent>
        </Dialog>
      )}

      {showConfirm && (
        <DeleteConfirmationDialog
          title={t("Confirm")}
          entityName="Tracker"
          onConfirm={(e) => goToDash(e)}
          onCancel={handleShowConfirm}
          message={t("Are u sure you want to close the tracker")}
        />
      )}

      <TrackingRouter />
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation(["tracking", "dashboard"])(
    withWidth()(withMobileDialog()(Tracker)),
  ),
);
