import React from "react";

import FAQSubscription from "./subscription-faqs/components/faqs-subscription";
import NavbarSettings from "./common/navbar-settings";
import { useTranslation } from "react-i18next";
const SubscriptionFaqs = () => {
  const { t } = useTranslation();
  return (
    <NavbarSettings
      component={<FAQSubscription />}
      text={t("Subscription FAQ's")}
    />
  );
};
export default SubscriptionFaqs;
