import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getAthleteActivities,
  updateAthleteData,
  toLoadAverage,
} from "../../../actions/trail-head-action";
import { dateFormat } from "../../../lib/date-helper";
import moment from "moment";
import { round, convertUnits } from "../../../lib/units-helper";
import unitsHook from "../../../hooks/unitsHook";
import { withTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

const marginBtm = { marginBottom: "7px" };

const styles = (theme) => ({
  personalData: {
    paddingTop: "260px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  userName: {
    ...marginBtm,
  },
  userInfo: {
    color: "#9E9E9E",
    ...marginBtm,
    width: "80%",
    textAlign: "center",
  },
  card: {
    backgroundColor: "rgba(255, 87, 34, 0.24)",
    padding: "4px 8px",
    borderRadius: "4px",
    boxSizing: "border-box",
    marginTop: "7px",
  },
  loading: {
    textAlign: "center",
    marginTop: "7px",
  },
});

const PersonalData = ({ username, location, classes, t }) => {
  const dispatch = useDispatch();
  const { trailHead } = useSelector((state) => state);
  const { selectedAthlete, loadingUpdateAthlete } = trailHead;
  const units = unitsHook();
  const [raceName, setRaceName] = useState(null);

  const {
    garmin_oauth_token,
    strava_athlete_id,
    suunto_auth,
    stravaActivities,
    activities,
    average,
    nextRaces = [],
    cognito_user_sub,
    coros_auth,
  } = selectedAthlete || {};

  useEffect(() => {
    if (!activities) {
      if (
        (strava_athlete_id ||
          garmin_oauth_token ||
          suunto_auth ||
          coros_auth) &&
        !stravaActivities
      ) {
        dispatch(
          getAthleteActivities({
            start_date: garmin_oauth_token
              ? moment()
                  .startOf("isoWeek")
                  .add(-60, "week")
                  .unix()
              : moment()
                  .startOf("isoWeek")
                  .add(-60, "week")
                  .format(dateFormat),
            end_date: garmin_oauth_token
              ? moment()
                  .startOf("isoWeek")
                  .add(-60, "day")
                  .unix()
              : moment()
                  .startOf("isoWeek")
                  .add(-60, "day")
                  .format(dateFormat),
            strava_athlete_id: strava_athlete_id,
            oauth_token: garmin_oauth_token,
            ...suunto_auth,
            cognito_user_sub,
            coros_auth,
          }),
        );
      }
    }

    if (
      !garmin_oauth_token &&
      !strava_athlete_id &&
      !suunto_auth &&
      !coros_auth &&
      !activities
    ) {
      dispatch(updateAthleteData(0));
    }

    return () => {
      dispatch(toLoadAverage());
    };
  }, [
    garmin_oauth_token,
    strava_athlete_id,
    suunto_auth,
    coros_auth,
    activities,
  ]);

  useEffect(() => {
    if (activities && !average) calculateAverage();
  }, [activities, average]);

  const calculateTotal = (array = [], key) => {
    const total = array.reduce((sum, current) => sum + current[key], 0);
    return total;
  };

  const calculateAverage = () => {
    const { activities } = selectedAthlete || {};
    const { result } = activities || {};

    if (result && result.length) {
      const ordered = (result || []).sort(
        (a, b) => new Date(b.start_date) - new Date(a.start_date),
      );

      let fromDate = ordered[ordered.length - 1].start_date;
      let toDate = ordered[0].start_date;

      let from = new Date(fromDate).getTime();
      const to = new Date(toDate).getTime();

      const week = 604800000;
      const day = 86400000;

      const allWeeks = [];
      let weeks = (to - from) / day / 7;
      let i;

      for (i = 0; i < weeks; i++) {
        allWeeks.push(new Date((from += week).toLocaleString()));
      }

      const total = calculateTotal(result, "distance");
      const totalKms = total / allWeeks.length / 1000;

      dispatch(
        updateAthleteData(
          units === "miles"
            ? round(convertUnits(totalKms, "kms", "miles", "distance"), 1) || 0
            : round(totalKms, 1) || 0,
        ),
      );
    }
  };

  useEffect(() => {
    if (nextRaces.length) selectedRace();

    return () => setRaceName(null);
  }, [nextRaces]);

  const selectedRace = () => {
    const formatedRaces = (nextRaces || []).map((item) => {
      const date = item.raceDate.split("/");
      const newDate = `${date[0]}/${date[1]}/${date[2]}`;
      item["raceDate"] = newDate;
      return item;
    });

    const ordered = (formatedRaces || []).sort(
      (a, b) => new Date(b.raceDate) - new Date(a.raceDate),
    );
    return ordered && ordered.length
      ? setRaceName(ordered[ordered.length - 1].raceName)
      : setRaceName(null);
  };

  return (
    <div className={classes.personalData}>
      <Typography variant="h5" className={classes.userName}>
        {username || ""}
      </Typography>

      {raceName && !loadingUpdateAthlete ? (
        <Typography variant="body2" className={classes.userInfo}>
          {raceName || ""}
        </Typography>
      ) : null}

      {loadingUpdateAthlete ? (
        <div className={classes.loading}>
          <CircularProgress variant="indeterminate" size="20px" />
        </div>
      ) : (
        <div className={classes.card}>
          <Typography variant="body2">
            {t("average")} {average || 0}{" "}
            {units === "miles" ? `${t("miles")}` : t("km")} / {t("week")}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default withTranslation("trailhead")(
  withStyles(styles, { withTheme: true })(PersonalData),
);
