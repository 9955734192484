import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  withStyles,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { palette } from "../../../theme/palette";
import { TriangleIcon } from "../../icons";
const NextRaceSlider = () => {
  const { t } = useTranslation("home");
  const { currentPlan } = useSelector((state) => state.userPlans);
  const { nextRaces } = currentPlan || {};
  const [nextRace, setNextRace] = useState({});
  const [progressValue, setProgressValue] = useState(0);
  const today = dayjs();

  const handleComingRace = () => {
    const nextRace = (nextRaces || []).find(({ raceDate }) => {
      return dayjs(raceDate, "DD/MM/YYYY").isAfter(today, "day");
    });
    if (nextRace) setNextRace(nextRace);
  };

  const calculateDifference = (date) => {
    if (!date) return;
    const difference = dayjs(date, "DD/MM/YYYY").diff(today, "days");
    return difference;
  };

  const calculateProgress = () => {
    const startDate = dayjs(nextRace.raceId);
    const endDate = dayjs(nextRace.raceDate, "DD/MM/YYYY");
    const totalDays = endDate.diff(startDate, "days");
    const remainingDays = calculateDifference(nextRace.raceDate);
    const elapsedDays = totalDays - remainingDays;
    const progress = (elapsedDays / totalDays) * 100;
    return progress;
  };

  useEffect(() => {
    if (nextRaces && nextRaces.length) {
      handleComingRace();
    }
  }, [nextRaces]);

  useEffect(() => {
    if (Object.keys(nextRace).length) {
      const progress = calculateProgress();
      setProgressValue(progress);
    }
  }, [nextRace]);

  const classes = useStyles();
  return (
    <Box>
      {!!Object.keys(nextRace).length && (
        <>
          <Box display="flex" alignItems="center" padding={"0 18px"}>
            <Typography variant="h3" className={classes.title}>
              {t("home.race.racingIn")}
            </Typography>
            <Typography
              variant="h3"
              className={[classes.title, classes.violet]}
            >
              {calculateDifference(nextRace.raceDate)} {t("home.race.days")}
            </Typography>
          </Box>

          <Typography className={classes.subTitle}>
            {t("home.race.keepRacing")}
          </Typography>

          <Box marginTop="16px" padding={"0 18px"}>
            <BorderLinearProgress variant="determinate" value={progressValue} />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop="8px"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <TriangleIcon />
              <Typography className={classes.content}>
                {t("home.race.start")}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <TriangleIcon />
              <Typography className={classes.content}>
                {t("home.race.goal")}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default NextRaceSlider;

const useStyles = makeStyles({
  title: {
    fontFamily: "Oswald",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22.5px",
    textTransform: "uppercase",
  },
  subTitle: {
    fontdamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    padding: "0 18px",
    color: "#6D6F71",
  },
  content: {
    fontdamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#6D6F71",
  },
  violet: {
    marginLeft: "4px",
    color: palette.primary,
  },
  progressBar: {
    height: "18px",
    backgroundColor: palette.darkBlue,
    borderRadius: "4px",
    "& .MuiLinearProgress-root > .MuiLinearProgress-determinate	": {
      color: palette.darkBlue,
      height: "18px",
    },
  },
});

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: "18px",
    borderRadius: "4px",
  },
  colorPrimary: {
    backgroundColor: palette.lightGray,
  },
  bar: {
    borderRadius: "4px",
    backgroundColor: palette.darkBlue,
  },
}))(LinearProgress);
