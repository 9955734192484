import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Typography, IconButton, LinearProgress } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";

import TrainingEditorWeekday from "./training-editor/training-editor-weekday";
import FlexContainer from "../common/flex-container";
import { dateFormat } from "../../lib/date-helper";

const styles = (theme) => ({
  container: {},
  iconButton: {
    margin: theme.spacing.unit,
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    textAlign: "center",
  },
  weekTitle: {
    margin: "auto",
  },
});

class TrainingEditor extends Component {
  render() {
    const {
      classes,
      currentWeek,
      currentTraining,
      saving,
      onRequestWeekChange,
      onWorkoutAdd,
      onWorkoutDelete,
      onWorkoutCommentDialog,
      showDates,
      disabled,
      level,
    } = this.props;
    const { durationInWeeks, startingDate, days = [] } = currentTraining || {};
    const startingDateMoment = startingDate && moment(startingDate, dateFormat);
    const patchedStartingDate =
      startingDate && startingDateMoment.startOf("isoWeek");
    //Adding extra days to the beginning of days array (if startingDate is not Monday)
    const startOffset = startingDate
      ? moment(startingDate, dateFormat).diff(patchedStartingDate, "days")
      : 0;
    const startOffsetArray = startOffset > 0 ? new Array(startOffset) : [];
    startOffsetArray.fill(null);

    //Adding extra days to the end of days array (if startingDate is not Monday)
    const endOffset = startOffset > 0 ? 7 - startOffset : 0;
    const endOffsetArray = endOffset > 0 ? new Array(endOffset) : [];
    endOffsetArray.fill(null);

    const additionalDaysWereAdded = startOffset > 0 || endOffset > 0;
    const patchedDays = additionalDaysWereAdded
      ? [...startOffsetArray, ...days, ...endOffsetArray]
      : days;
    const durationNumberParsed = parseInt(durationInWeeks);
    const durationNumber =
      durationNumberParsed + (additionalDaysWereAdded ? 1 : 0);
    let newPatchedDays = [];
    if (days && days.length === durationNumber * 7)
      newPatchedDays = [...patchedDays];
    else
      for (let index = 0; index < durationNumber * 7; index++) {
        const foundIndex = patchedDays.findIndex(
          (day) =>
            day &&
            day[0] &&
            day[0].plannedDate ===
              moment(patchedStartingDate)
                .add("days", index)
                .format(dateFormat),
        );
        if (foundIndex > -1) newPatchedDays.push(patchedDays[foundIndex]);
        else newPatchedDays.push(null);
      }
    return (
      <div className={classes.container}>
        {saving ? (
          <LinearProgress variant="indeterminate" color="primary" />
        ) : null}
        {currentTraining ? (
          <Fragment>
            <div className={classes.titleContainer}>
              <IconButton
                className={classes.iconButton}
                aria-label="Previous Week"
                color="primary"
                disabled={currentWeek === 0}
                onClick={onRequestWeekChange.bind(this, currentWeek - 1)}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography
                variant="h5"
                color="primary"
                className={classes.weekTitle}
              >
                Week {currentWeek + 1}
              </Typography>
              <IconButton
                className={classes.iconButton}
                aria-label="Next Week"
                color="primary"
                disabled={currentWeek === durationNumber - 1}
                onClick={onRequestWeekChange.bind(this, currentWeek + 1)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
            <FlexContainer direction="row" wrap={false} fullHeight padding={0}>
              {newPatchedDays &&
                newPatchedDays.map((day, index) => {
                  if (index >= currentWeek * 7 && index < currentWeek * 7 + 7)
                    return (
                      <TrainingEditorWeekday
                        key={index}
                        onWorkoutAdd={onWorkoutAdd}
                        trainingDay={day}
                        dayIndex={index}
                        offsetDayIndex={startOffset}
                        workoutDate={moment(patchedStartingDate).add(
                          index,
                          "days",
                        )}
                        showDates={showDates}
                        outOfRangeDate={
                          index - startOffset < 0 ||
                          index - startOffset >= durationNumberParsed * 7
                        }
                        onWorkoutDelete={onWorkoutDelete}
                        onWorkoutCommentDialog={onWorkoutCommentDialog}
                        disabled={disabled}
                        level={level}
                      />
                    );
                  else return null;
                })}
            </FlexContainer>
          </Fragment>
        ) : null}
      </div>
    );
  }
}

TrainingEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  currentWeek: PropTypes.number.isRequired,
  currentTraining: PropTypes.object,
  onRequestWeekChange: PropTypes.func.isRequired,
  onWorkoutAdd: PropTypes.func.isRequired,
  onWorkoutDelete: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  onWorkoutCommentDialog: PropTypes.func,
  showDates: PropTypes.bool,
};

TrainingEditor.defaultProps = {
  display: false,
};

export default withStyles(styles)(TrainingEditor);
