import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, MobileStepper } from "@material-ui/core";

const styles = (theme) => ({
  stepper: {
    background: "none",
    [theme.breakpoints.up("md")]: {},
  },
  progress: {
    height: 10,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 6,
  },
  dots: {},
  dot: {
    width: 12,
    borderRadius: 5,
    background: "rgba(63, 81, 181, 0.12)",
    margin: "0 5px",
  },
  dotActive: {
    width: 32,
    backgroundColor: "#3F51B5",
  },
});

class PlanStepper extends Component {
  render() {
    const { xs, sm, md, lg, xl, currentStep, stepsCount, classes } = this.props;

    return (
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <MobileStepper
          variant="dots"
          steps={stepsCount}
          position="static"
          activeStep={currentStep}
          className={classes.stepper}
          LinearProgressProps={{ classes: { root: classes.progress } }}
          nextButton={<div></div>}
          backButton={<div></div>}
          classes={{
            dots: stepsCount > 4 ? classes.dotsLarge : classes.dots,
            dot: classes.dot,
            dotActive: classes.dotActive,
          }}
        />
      </Grid>
    );
  }
}

PlanStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  currentStep: PropTypes.number,
  stepsCount: PropTypes.number,
};

PlanStepper.defaultProps = {
  currentStep: 0,
  stepsCount: 5,
};

export default withStyles(styles, { withTheme: true })(PlanStepper);
