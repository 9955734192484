import React from "react";
import { palette } from "../../theme/palette";

const ActionIcon = ({ width = 24, height = 24, activated = false }) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      fill={activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 12V11.99M12.0001 16V15.99M12.0001 8V7.99"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={activated ? palette.primary : palette.darkGray}
      />
    </svg>
  </>
);
export default ActionIcon;
