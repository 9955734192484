import { API } from "aws-amplify";
import bugsnagClient from "../lib/bugsnag-client";

class SearchService {
  static API_NAME = "apisearch";
  static SEARCH_PATH = "/search";

  static async generalSearch(search) {
    try {
      const response = await API.get(
        this.API_NAME,
        `${this.SEARCH_PATH}/${search}`,
      );
      const { error, body } = response;
      if (body) return body;
      if (error) throw new Error(error);
    } catch (error) {
      bugsnagClient.notify(error);
      throw error;
    }
  }
}

export default SearchService;
