import React, { useState, useEffect } from "react";
import {
  Typography,
  Slider,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { getCustomLevel, getPlanLevel } from "../../../lib/plan-helper";
import workoutToText from "../../../lib/workoutToText";
import { convertToRTFForView } from "../../../lib/rtf-helper";
import { useSelector, useDispatch } from "react-redux";
import EstimatedTimes from "../../../constants/estimated-times";
import { withTranslation } from "react-i18next";
import WorkoutEditorForm from "../../workouts/workout-editor/workout-editor-form";
import {
  athleteTrainingEditWorkout,
  selectAthleteWorkout,
} from "../../../actions/athletes-action";
import { getPlan } from "../../../lib/current-training-helper";
import { dateFormat } from "../../../lib/date-helper";
import moment from "moment";

const styles = (theme) => ({
  modalDetail: {
    position: "absolute",
    backgroundColor: "#FFF",
    border: "1px solid #A2A2A2",
    borderRadius: "5px",
    width: "396px",
    left: "-410px",
    padding: "16px",
    boxSizing: "border-box",
    top: "-95%",
    zIndex: 999,
    maxHeight: "70vh",
    overflowY: "auto",
  },
  parent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconsParent: {
    marginLeft: 6,
    "& img:not(:last-child)": {
      marginRight: 12,
    },
  },
  titleParent: {
    display: "flex",
    marginTop: 20,
  },
  workoutParams: {
    textAlign: "left",
    marginTop: 12,
  },
  descriptionParent: {
    textAlign: "left",
    marginTop: 12,
  },
  plannedParent: {
    width: "80%",
    margin: "0 auto",
    marginTop: 18,
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  line: {
    height: "1px",
    background: "#000",
    width: "40%",
  },
  effortLabel: {
    fontWeight: "bold",
  },
  workoutDescription: {
    marginTop: "10px",
  },
  descriptionTitle: {
    fontWeight: "bold",
  },
  timerParent: {
    display: "flex",
    alignItems: "center",
  },
  actions: {
    display: "flex",
    justifyContent: "end",
  },
});

const AthleteResumeModal = ({
  classes,
  workout,
  close,
  onRemove,
  i18n,
  onMouseLeave,
}) => {
  const {
    title,
    description,
    estimatedTime: workoutEstimatedtime,
    parametrizedWorkout,
    energyLevel = 100,
    rpe,
    isDone,
    workoutType,
  } = workout || {};

  const { selectedAthleteChainedPlans, selectedAthlete } = useSelector(
    (state) => state.athletes,
  );
  const { stravaActivities, currentTraining, selectedWorkout } =
    selectedAthlete || {};

  const { chainedPlans } = currentTraining || {};

  const formId = "workoutForm";
  const dispatch = useDispatch();
  const { language } = i18n;

  const hasError = !(
    title &&
    title["en"] &&
    title["es"] &&
    description &&
    description["en"] &&
    description["es"] &&
    workoutType &&
    workoutEstimatedtime
  );

  const [currentWorkout, setCurrentWorkout] = useState(null);
  const [initialWorkout, setInitialWorkout] = useState(null);
  const [editing, setEditing] = useState(false);
  const [completedTime, setCompletedTime] = useState(0);

  const customLevel = getCustomLevel(
    (currentWorkout || {}).plannedDate,
    chainedPlans,
  );

  const level = getPlanLevel(currentTraining || {});

  const handleSelect = ({ name, value }) => {
    if (name && value) {
      const newCurrentWorkout = { ...currentWorkout };
      if (name === "estimatedTime") {
        const level = getPlanLevel(currentTraining);

        if (Array.isArray(newCurrentWorkout[name])) {
          newCurrentWorkout[name][
            !isNaN(customLevel) ? customLevel : level
          ] = value;
        } else {
          newCurrentWorkout[name] = value;
        }
      } else {
        newCurrentWorkout[name] = value;
      }
      setCurrentWorkout(newCurrentWorkout);
    }
  };

  const normalizeEnergyValue = (value) => {
    if (!value && isNaN(value)) return 0;
    if (value == 0) return 100;
    if (value > 0 && value <= 10) return 90;
    if (value > 10 && value <= 20) return 80;
    if (value > 20 && value <= 30) return 70;
    if (value > 30 && value <= 40) return 60;
    if (value > 40 && value <= 50) return 50;
    if (value > 50 && value <= 60) return 40;
    if (value > 60 && value <= 70) return 30;
    if (value > 70 && value <= 80) return 20;
    if (value > 80 && value <= 90) return 10;
    if (value > 90 && value <= 100) return 0;
  };

  const convertSecondsToMinutes = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    return Math.floor(minutes).toString() || "0";
  };

  useEffect(() => {
    const workoutCopy = { ...selectedWorkout };
    const currentActivity = (stravaActivities || []).find(
      (a) =>
        moment(a.start_date).isSame(
          moment(workoutCopy.plannedDate, dateFormat),
          "day",
        ) &&
        ((a.type && a.type.toLowerCase().includes("run")) ||
          (a.activityType && a.activityType.includes("RUN"))),
    );

    setCompletedTime(
      currentActivity
        ? convertSecondsToMinutes(currentActivity.durationInSeconds)
        : "0min",
    );

    if (selectedWorkout) {
      setInitialWorkout(JSON.parse(JSON.stringify(selectedWorkout)));
      setCurrentWorkout(selectedWorkout);
    }
  }, [selectedWorkout]);

  const handleMultiLangTextChange = ({ name, value }) => {
    if (name && value) {
      const newCurrentWorkout = { ...currentWorkout };
      newCurrentWorkout[name] = value;
      setCurrentWorkout(newCurrentWorkout);
    }
  };

  const handleChangeWorkout = (workoutChanged) => {
    setCurrentWorkout(JSON.parse(JSON.stringify(workoutChanged)));
  };

  const returnStimatedtime = () => {
    const estimatedTime = !Array.isArray(workoutEstimatedtime)
      ? EstimatedTimes.convertMinutesToLabel(workoutEstimatedtime, true)
      : EstimatedTimes.convertMinutesToLabel(
          workoutEstimatedtime[!isNaN(customLevel) ? customLevel : level],
          true,
        );
    return estimatedTime;
  };

  const returnCompletedTime = (time) => {
    return EstimatedTimes.convertMinutesToLabel(parseFloat(time), true);
  };

  const closeEdit = async () => {
    await dispatch(selectAthleteWorkout(initialWorkout));
    await setEditing(false);
  };

  const handleEdit = () => setEditing(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (currentWorkout) {
      const plan = getPlan(
        [
          selectedAthlete,
          ...selectedAthleteChainedPlans.map((c, index) => {
            return {
              updated_at: selectedAthlete.chainedPlans[index],
              currentTraining: c,
            };
          }),
        ],
        moment(currentWorkout.plannedDate, dateFormat),
      );

      dispatch(
        athleteTrainingEditWorkout(
          selectedAthlete,
          {
            ...currentWorkout,
            workoutId: currentWorkout.id,
            ...{
              title: currentWorkout.title,
              title_lower: `${currentWorkout.title[
                "en"
              ].toLowerCase()} ${currentWorkout.title["es"].toLowerCase()}`,
              description: currentWorkout.description,
              description_lower: `${currentWorkout.description[
                "en"
              ].toLowerCase()} ${currentWorkout.description[
                "es"
              ].toLowerCase()}`,
              workoutType: currentWorkout.workoutType,
              estimatedTime: currentWorkout.estimatedTime,
            },
          },
          plan.updated_at === "latest" ? 0 : plan.updated_at,
        ),
      );

      setInitialWorkout(
        JSON.parse(
          JSON.stringify({
            ...currentWorkout,
            ...{
              title: currentWorkout.title,
              title_lower: `${currentWorkout.title[
                "en"
              ].toLowerCase()} ${currentWorkout.title["es"].toLowerCase()}`,
              description: currentWorkout.description,
              description_lower: `${currentWorkout.description[
                "en"
              ].toLowerCase()} ${currentWorkout.description[
                "es"
              ].toLowerCase()}`,
              workoutType: currentWorkout.workoutType,
              estimatedTime: currentWorkout.estimatedTime,
            },
          }),
        ),
      );

      setTimeout(() => closeEdit(), 100);
    }
  };

  return (
    <>
      <div
        className={classes.modalDetail}
        onMouseLeave={!editing && onMouseLeave}
      >
        <div className={classes.parent}>
          <div className={classes.iconsParent}>
            <img
              src={`${process.env.PUBLIC_URL}/workout-detail/edit-icon.svg`}
              alt="edit-icon"
              onClick={handleEdit}
            />
            <img
              src={`${process.env.PUBLIC_URL}/workout-detail/delete-icon.svg`}
              alt="delete-icon"
              onClick={onRemove && onRemove}
            />
          </div>
          <div onClick={onMouseLeave && onMouseLeave}>
            <img
              src={`${process.env.PUBLIC_URL}/workout-detail/close-icon.svg`}
              alt="share-icon"
              width={16}
            />
          </div>
        </div>

        <div className={classes.titleParent}>
          <Typography variant="body1">
            {title[language] ? title[language] : title["en"]}
          </Typography>
          <img
            style={{ marginLeft: 10 }}
            src={`${process.env.PUBLIC_URL}/workout-detail/${
              isDone ? "checked" : "unchecked"
            }-icon.svg`}
            alt="checked-icon"
          />
        </div>

        {parametrizedWorkout &&
          parametrizedWorkout[!isNaN(customLevel) ? customLevel : level] &&
          parametrizedWorkout[!isNaN(customLevel) ? customLevel : level]
            .length && (
            <div className={classes.workoutParams}>
              <Typography variant="body1" className={classes.workoutTitle}>
                Workout:
              </Typography>

              <Typography
                variant="body2"
                className={classes.workoutGeneratedText}
                dangerouslySetInnerHTML={{
                  __html: workoutToText(
                    parametrizedWorkout[
                      !isNaN(customLevel) ? customLevel : level
                    ],
                  ),
                }}
              ></Typography>
            </div>
          )}

        <div className={classes.timerParent}>
          <img
            src={`${process.env.PUBLIC_URL}/workout-detail/timer-icon.svg`}
          />
          <Typography variant="body2" style={{ marginLeft: 10 }}>
            {returnStimatedtime() || "0min"}
          </Typography>
        </div>

        <div className={classes.descriptionParent}>
          <Typography variant="body1" className={classes.descriptionTitle}>
            Description:
          </Typography>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={convertToRTFForView(
              description[language] ? description[language] : description["en"],
            )}
            className={classes.workoutDescription}
          ></Typography>
        </div>

        <div className={classes.plannedParent}>
          <div className={classes.row}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {" "}
              planned{" "}
            </Typography>
            <div className={classes.line}></div>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {" "}
              completed{" "}
            </Typography>
          </div>

          <div className={classes.row}>
            <Typography variant="body2">
              {returnStimatedtime() || "0min"}{" "}
            </Typography>
            <Typography variant="body2">
              {returnCompletedTime(completedTime) || "0min"}
            </Typography>
          </div>

          <div className={classes.row} style={{ marginTop: 20 }}>
            <Typography variant="body2" className={classes.effortLabel}>
              {" "}
              Athlete’s RPE of this workout:
            </Typography>

            <Typography variant="body2">{rpe ? rpe / 10 : 0}</Typography>
          </div>
          <Slider
            value={rpe || 0}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="off"
            step={10}
            marks
            min={0}
            max={100}
            color="primary"
          />
          <div className={classes.row} style={{ marginTop: 20 }}>
            <Typography variant="body2">Easy</Typography>

            <Typography variant="body2">Max Effort</Typography>
          </div>

          <div className={classes.row} style={{ marginTop: 20 }}>
            <Typography variant="body2" className={classes.effortLabel}>
              {" "}
              Athletes’s fatighe:{" "}
            </Typography>

            <Typography variant="body2">
              {!isNaN(energyLevel) ? normalizeEnergyValue(energyLevel) / 10 : 0}
            </Typography>
          </div>
          <Slider
            value={!isNaN(energyLevel) ? normalizeEnergyValue(energyLevel) : 0}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="off"
            step={10}
            marks
            min={0}
            max={100}
            color="primary"
          />
          <div className={classes.row} style={{ marginTop: 20 }}>
            <Typography variant="body2">Fresh</Typography>

            <Typography variant="body2">Very fatigued</Typography>
          </div>
        </div>
      </div>
      <>
        {editing && (
          <>
            <Dialog open={true} maxWidth="xl">
              <DialogContent>
                <WorkoutEditorForm
                  formId={formId}
                  saving={false}
                  workout={currentWorkout}
                  onMultiLangTextChange={handleMultiLangTextChange}
                  onSelect={handleSelect}
                  onSubmit={handleSubmit}
                  isEditingOnPlan={true}
                  level={!isNaN(customLevel) ? customLevel : level}
                  handleChangeWorkout={handleChangeWorkout}
                />

                <div className={classes.actions}>
                  <Button onClick={closeEdit} color="primary">
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    form={formId}
                    disabled={hasError}
                  >
                    {"Update"}
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </>
        )}
      </>
    </>
  );
};

export default withTranslation()(withStyles(styles)(AthleteResumeModal));
