import React, { memo, useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import unitsHook from "../../hooks/unitsHook";
import moment from "moment";
import {
  convertUnits,
  round,
  getPace,
  getActivityCalculations,
  getMilesPace,
} from "../../lib/units-helper";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

import { useDispatch } from "react-redux";
import classNames from "classnames";
import { clearCurrentUserActivity } from "../../actions/user-plans-action";

const styles = (theme) => ({
  main: {
    padding: "24px 56px",
    // marginBottom:
  },
  card: {
    backgroundColor: "#FFF",
    padding: "16px",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  contentParent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  leftSide: {
    width: "100%",
  },
  rightSide: {
    width: "100%",
    boxSizing: "border-box",
  },
  bigStat: {
    fontSize: "44px",
    fontWeight: "bold",
    lineHeight: "100%",
  },
  bigStatsParent: {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0",
  },
  bigStatTitle: {
    color: "#9E9E9E",
    fontSize: "14px",
  },
  grayCard: {
    backgroundColor: "#F9F9FB",
    padding: "16px",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  smallStat: {
    fontSize: "16px",
    fontWeight: 500,
  },
  smallStatTitle: {
    fontSize: "14px",
    color: "#9E9E9E",
  },
  bigStatRow: {
    display: "flex",
    alignItems: "flex-end",
  },
  seeDetailsParent: {
    display: "flex",
  },
  iconParent: {
    marginLeft: 6,
  },
  title: {
    color: "#E16327",
    fontSize: "20px",
    fontWeight: "bold",
  },
  description: {
    color: "#9E9E9E",
    fontSize: "16px",
  },
  date: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 18,
    marginTop: 40,
  },

  progressRoot: {
    height: 16,
    margin: "auto 0",
    background: "transparent",
  },
  progressBar: {
    borderRadius: "0px 4px 4px 0px",
    background: "#1E629B",
  },

  table: {
    borderCollapse: "collapse",
  },

  header: {
    borderBottom: "1px solid #E0E0E0",
  },

  headerTH: {
    fontWeight: "normal",
    fontSize: 12,
    color: "#9E9E9E",
    margin: "6px 0",
    padding: "0 3px",
  },

  th: {
    fontWeight: "normal",
    fontSize: 14,
    margin: "6px 0",
    textAlign: "center",
    padding: "0 3px",
  },

  barTH: {
    width: "100%",
    padding: "0 10px",
  },

  stravaDisclaimer: {
    fontSize: 14,
    textAlign: "center",
    marginTop: 20,
    fontWeight: 500,
  },

  progressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  loading: {
    margin: "auto",
  },
  headParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionText: {
    color: "#9E9E9E",
    fontSize: "14px",
    maxWidth: "80%",
    marginTop: "4px",
  },
  textsParent: {
    maxWidth: "90%",
    overflowWrap: "break-word",
  },
  editIconParent: {
    cursor: "pointer",
  },
  graphContainer: {
    height: "100%",
    padding: "8px 0",
  },
  graphElevationUnit: {
    color: "#9E9E9E",
    fontSize: 14,
    fontWeight: "normal",
    marginBottom: 5,
    marginLeft: 20,
  },
  goBackParent: {
    display: "flex",
    margin: "16px 0",
    cursor: "pointer",
  },
  goBackText: {
    fontSize: "18px",
    marginLeft: "6px",
  },
  elevationTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "8px",
  },
  scrolleable: {
    overflowY: "auto",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 0",
  },
});

const DashboardActivityDetail = ({
  classes,
  activity = {},
  isOpen,
  t,
  clearActivity,
  onDestroy,
}) => {
  const { strava, garmin, suunto, coros, vertActivities } = useSelector(
    (state) => state,
  );

  const leftSide = useRef(null);
  const localUnits = unitsHook();
  const [units, setUnits] = useState(localUnits);
  const [lapPaces, setLapPaces] = useState([]);
  const [labels, setLabels] = useState([]);
  const [elevationSamples, setElevationSamples] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("storage", localStorageUpdated);

    return () => {
      dispatch(clearCurrentUserActivity());
      window.removeEventListener("storage", localStorageUpdated);
      onDestroy && onDestroy();
    };
  }, []);

  const date = moment(
    activity.start_date_local ? activity.start_date_local : activity.start_date,
  );

  const name = activity.name
    ? activity.name
    : activity.activityName
    ? activity.activityName
    : "";

  const localStorageUpdated = () => setUnits(localStorage.getItem("units"));

  const convertDistance = (distance) => {
    const convertedDistance = convertUnits(
      distance,
      "kms",
      localUnits,
      "distance",
    );
    return convertedDistance;
  };

  // const goToSettings = () => history.push("/profile");

  const convertElevation = (elevation) => {
    const convertedElevation = round(
      convertUnits(elevation, "kms", localUnits, "elevation"),
      1,
    );
    return convertedElevation;
  };

  const time = activity.moving_time
    ? activity.moving_time
    : activity.elapsed_time;

  const pace =
    localUnits == "kms"
      ? getPace(time, activity.distance)
      : getMilesPace(time, activity.distance);

  const h = time ? Math.floor(time / 3600) : 0;
  const m = time ? Math.floor((time % 3600) / 60) : 0;
  const timeStr = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;

  const currentDevice =
    isOpen === "vert.run"
      ? vertActivities
      : isOpen === "garmin"
      ? garmin
      : isOpen === "suunto"
      ? suunto
      : isOpen === "coros"
      ? coros
      : isOpen === "strava"
      ? strava
      : {};

  const { currentActivity = {}, loadingActivity } = currentDevice;
  const { currentActivity: vertActivity } = vertActivities || {};

  useEffect(() => {
    if (currentActivity) {
      const elevationSamples =
        currentActivity && currentActivity.samples
          ? currentActivity.samples.map((s) => {
              return {
                x: s.distance,
                y: s.elevation,
              };
            })
          : [];

      let labels = [];
      let divider = 1;
      if (currentActivity && currentActivity.distance) {
        if (currentActivity.distance / 25 > 1) {
          divider = Math.round(currentActivity.distance / 25);
        }

        for (
          let index = 0;
          index < currentActivity.distance;
          index += divider
        ) {
          labels.push(index);
        }
      }

      const lapPaces = [];
      if (currentActivity && currentActivity.laps) {
        if (elevationSamples.length) {
          elevationSamples.push({
            x: 0,
            y: round(elevationSamples[0].y, 0),
          });
        }
        for (let index = 0; index < currentActivity.laps.length; index++) {
          const element = currentActivity.laps[index];
          if (element.pace) {
            lapPaces.push(element.pace.minutes + element.pace.seconds / 60);
          }
        }
      }

      elevationSamples.sort((a, b) => a.x - b.x);
      labels = [...labels, ...elevationSamples.map((e) => e.x)];
      labels.sort((a, b) => a - b);

      setLapPaces(lapPaces);
      setLabels(labels);
      setElevationSamples(elevationSamples);
    }
  }, [currentActivity]);

  const options = {
    scales: {
      responsive: true,
      x: [
        {
          type: "linear",
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            max:
              currentActivity && currentActivity.distance
                ? currentActivity.distance
                : 0,
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            stepSize: 5000,
            callback: function(value, index, values) {
              return Number.isInteger(value)
                ? currentActivity &&
                  currentActivity.distance &&
                  value >= Math.floor(currentActivity.distance)
                  ? `${value} ${
                      localUnits === "miles" ? `${t("miles")}` : `${t("km")}`
                    }`
                  : value
                : "";
            },
          },
          scaleLabel: {
            display: false,
          },
        },
      ],
      y: [
        {
          grid: {
            drawBorder: false,
            color: "rgba(0, 0, 0, 0.05)",
            zeroLineColor: "rgba(0, 0, 0, 0.05)",
          },
          ticks: {
            fontFamily: "Avenir",
            fontSize: 12,
            color: "#9E9E9E",
          },
        },
      ],
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    animation: {
      easing: "linear",
      duration: 1000,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "single",
        callbacks: {
          label: function(tooltipItems) {
            return `${round(+tooltipItems.yLabel, 1)} ${
              localUnits === "miles" ? t("feet") : t("m")
            }`;
          },
          title: function(context) {
            let title = context[0].label;
            return `${round(+title, 1)} ${
              localUnits === "miles" ? t("miles") : t("km")
            }`;
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: t("Elevation"),
        borderColor: "#1A629B",
        borderWidth: 1,
        backgroundColor: "rgba(63, 81, 181, 0.05)",
        pointBorderWidth: 0,
        pointRadius: 0,
        fill: true,
        pointHoverRadius: 0,
        pointBackgroundColor: "#1A629B",
        data: elevationSamples,
      },
    ],
  };

  return (
    <>
      {!currentActivity ? (
        <div className={classes.loader}>
          <CircularProgress variant="indeterminate" size={36} />
        </div>
      ) : (
        <div className={classes.main}>
          <div className={classes.goBackParent} onClick={clearActivity}>
            <img
              src={`${process.env.PUBLIC_URL}/chevron-goback-icon.svg`}
            ></img>
            <Typography className={classes.goBackText}>
              {t("Back to activities")}
            </Typography>
          </div>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <div className={classes.card} ref={leftSide}>
                <div className={classes.contentParent}>
                  <div className={classes.leftSide}>
                    <Typography className={classes.date}>
                      {date.format("DD/MM/YYYY")}
                    </Typography>
                    <Typography className={classes.title}>{name}</Typography>
                    <Typography className={classes.description}>
                      {activity.description}
                    </Typography>

                    <div className={classes.bigStatsParent}>
                      <div>
                        <div className={classes.bigStatRow}>
                          <Typography className={classes.bigStat}>
                            {round(
                              convertDistance(activity.distance) / 1000,
                              1,
                            )}
                          </Typography>

                          <Typography style={{ fontSize: "14px" }}>
                            {" "}
                            {`${
                              localUnits === "miles" ? `${t("miles")}` : "km"
                            }`}{" "}
                          </Typography>
                        </div>
                        <Typography className={classes.bigStatTitle}>
                          {t("distance")}
                        </Typography>
                      </div>

                      <div>
                        <div className={classes.bigStatRow}>
                          <Typography className={classes.bigStat}>
                            {convertElevation(activity.total_elevation_gain)}
                          </Typography>
                          <Typography style={{ fontSize: "14px" }}>{`${
                            localUnits === "miles" ? ` ${t("feet")}` : "m"
                          }`}</Typography>
                        </div>
                        <Typography className={classes.bigStatTitle}>
                          {t("elevation")}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <div className={classes.rightSide}>
                    <div className={classes.grayCard}>
                      <Grid container>
                        <Grid item md={6}>
                          <Typography className={classes.smallStat}>
                            {timeStr}
                          </Typography>
                          <Typography className={classes.smallStatTitle}>
                            {t("time")}
                          </Typography>
                        </Grid>
                        <Grid item md={6} style={{ marginBottom: 10 }}>
                          <Typography className={classes.smallStat}>
                            {`${pace.minutes}:${(pace.seconds < 10 ? "0" : "") +
                              pace.seconds}`}{" "}
                            {localUnits === "miles"
                              ? `${t("miles")}/h`
                              : `${t("km")}/h`}
                          </Typography>
                          <Typography className={classes.smallStatTitle}>
                            {t("avg pace")}
                          </Typography>
                        </Grid>
                        <Grid item md={6} style={{ marginBottom: 10 }}>
                          <Typography className={classes.smallStat}>
                            {round(
                              (currentActivity && currentActivity.uphillPace) ||
                                0,
                              0,
                            ) || 0}{" "}
                            {localUnits === "miles"
                              ? `${t("feet/h")}`
                              : `${t("m/h")}`}
                          </Typography>
                          <Typography className={classes.smallStatTitle}>
                            {t("avg uphill speed")}
                          </Typography>
                        </Grid>
                        <Grid item md={6} style={{ marginBottom: 10 }}>
                          <Typography className={classes.smallStat}>
                            {round(
                              (currentActivity &&
                                currentActivity.downhillPace) ||
                                0,
                              0,
                            ) || 0}{" "}
                            {localUnits === "miles"
                              ? `${t("feet/h")}`
                              : `${t("m/h")}`}
                          </Typography>
                          <Typography className={classes.smallStatTitle}>
                            {t("avg downhill speed")}
                          </Typography>
                        </Grid>
                        <Grid item md={6} style={{ marginBottom: 10 }}>
                          <Typography className={classes.smallStat}>
                            {activity.averageHeartRateInBeatsPerMinute || 0} bpm
                          </Typography>
                          <Typography className={classes.smallStatTitle}>
                            {t("avg heart rate")}
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <Typography className={classes.smallStat}>
                            {activity.activeKilocalories || 0}
                          </Typography>
                          <Typography className={classes.smallStatTitle}>
                            {t("calories")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={6}>
              {currentActivity &&
              currentActivity.laps &&
              currentActivity.laps.length ? (
                <div
                  className={classNames(classes.card, classes.scrolleable)}
                  style={{
                    height: leftSide.current
                      ? leftSide.current.clientHeight
                      : 900,
                  }}
                >
                  {currentActivity &&
                  currentActivity.laps &&
                  currentActivity.laps.length > 0 &&
                  (currentActivity.laps.length > 1 ||
                    currentActivity.laps[0].distance > 0) ? (
                    <>
                      <Typography className={classes.elevationTitle}>
                        {t("Splits")}
                      </Typography>

                      <table className={classes.table}>
                        <tr className={classes.header}>
                          <th>
                            <Typography
                              variant="h3"
                              className={classes.headerTH}
                            >
                              {localUnits === "miles"
                                ? `${t("Mile")}`
                                : `${t("Km")}`}
                            </Typography>
                          </th>
                          <th>
                            <Typography
                              variant="h3"
                              className={classes.headerTH}
                            >
                              {t("Pace")}
                            </Typography>
                          </th>
                          {/*<th>
                <Typography variant="h3" className={classes.headerTH}>
                  {t("Total duration")}
                </Typography>
              </th>*/}
                          <th></th>

                          {/*<th>
                <Typography variant="h3" className={classes.headerTH}>
                  {t("HR")}
                </Typography>
              </th>*/}
                          {isOpen === "strava" ? (
                            <th>
                              <Typography
                                variant="h3"
                                className={classes.headerTH}
                              >
                                {t("Elev")}
                              </Typography>
                            </th>
                          ) : (
                            ""
                          )}
                          {isOpen !== "strava" ? (
                            <th>
                              <Typography
                                variant="h3"
                                className={classes.headerTH}
                              >
                                {t("Elev Gain")}
                              </Typography>
                            </th>
                          ) : (
                            ""
                          )}
                          {isOpen !== "strava" ? (
                            <th>
                              <Typography
                                variant="h3"
                                className={classes.headerTH}
                              >
                                {t("Elev Loss")}
                              </Typography>
                            </th>
                          ) : (
                            ""
                          )}

                          <th>
                            <Typography
                              variant="h3"
                              className={classes.headerTH}
                            >
                              {t("Avg Speed")} (
                              {localUnits === "miles"
                                ? `${t("miles/h")}`
                                : `${t("km/h")}`}
                              )
                            </Typography>
                          </th>
                          <th>
                            <Typography
                              variant="h3"
                              className={classes.headerTH}
                            >
                              {t("Vert Speed")} (
                              {localUnits === "miles"
                                ? `${t("feet/h")}`
                                : `${t("m/h")}`}
                              )
                            </Typography>
                          </th>
                        </tr>
                        {currentActivity
                          ? currentActivity.laps.map((l, i) => {
                              const maxPace = Math.max(...lapPaces);

                              return l.distance >= 0.1 ? (
                                <tr key={l.distance}>
                                  <td>
                                    <Typography
                                      variant="h3"
                                      className={classes.th}
                                    >
                                      {Math.round(l.distance) > 0
                                        ? i + 1
                                        : round(l.distance, 1)}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      variant="h3"
                                      className={classes.th}
                                    >{`${l.pace.minutes}:${(l.pace.seconds < 10
                                      ? "0"
                                      : "") + l.pace.seconds}`}</Typography>
                                  </td>
                                  <td className={classes.barTH}>
                                    <LinearProgress
                                      variant="determinate"
                                      value={Math.min(
                                        100,
                                        lapPaces[i] && maxPace
                                          ? (lapPaces[i] * 100) / maxPace
                                          : 0,
                                      )}
                                      classes={{
                                        root: classes.progressRoot,
                                        bar: classes.progressBar,
                                      }}
                                    />
                                  </td>
                                  {isOpen === "strava" ? (
                                    <th>
                                      <Typography
                                        variant="h3"
                                        className={classes.th}
                                      >
                                        {Math.round(l.elevation)}
                                      </Typography>
                                    </th>
                                  ) : (
                                    ""
                                  )}
                                  {isOpen !== "strava" ? (
                                    <th>
                                      <Typography
                                        variant="h3"
                                        className={classes.th}
                                      >
                                        {Math.round(l.accUp)}
                                      </Typography>
                                    </th>
                                  ) : (
                                    ""
                                  )}
                                  {isOpen !== "strava" ? (
                                    <th>
                                      <Typography
                                        variant="h3"
                                        className={classes.th}
                                      >
                                        {Math.round(l.accDown)}
                                      </Typography>
                                    </th>
                                  ) : (
                                    ""
                                  )}
                                  {isOpen !== "coros" ? (
                                    <td>
                                      <Typography
                                        variant="h3"
                                        className={classes.th}
                                      >
                                        {round(1 / (l.time / 3600), 2)}
                                      </Typography>
                                    </td>
                                  ) : (
                                    <td>
                                      <Typography
                                        variant="h3"
                                        className={classes.th}
                                      >
                                        {round(l.speed, 2)}
                                      </Typography>
                                    </td>
                                  )}

                                  {isOpen !== "coros" ? (
                                    <td>
                                      <Typography
                                        variant="h3"
                                        className={classes.th}
                                      >
                                        {Math.round(
                                          Math.round(l.elevation) /
                                            (l.time / 3600),
                                        )}
                                      </Typography>
                                    </td>
                                  ) : (
                                    <td>
                                      <Typography
                                        variant="h3"
                                        className={classes.th}
                                      >
                                        {Math.round(l.vertSpeed)}
                                      </Typography>
                                    </td>
                                  )}
                                </tr>
                              ) : (
                                ""
                              );
                            })
                          : ""}
                      </table>
                    </>
                  ) : null}
                </div>
              ) : null}
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item md={12} style={{ marginBottom: 10 }}>
              {currentActivity &&
              currentActivity.samples &&
              currentActivity.samples.length ? (
                <div className={classes.card}>
                  <>
                    {currentActivity &&
                    currentActivity.samples &&
                    currentActivity.samples.length > 1 ? (
                      <div className={classes.graphContainer}>
                        <Typography className={classes.elevationTitle}>
                          {t("Elevation profile")}
                        </Typography>
                        <Typography
                          variant="h3"
                          className={classes.graphElevationUnit}
                        >
                          {localUnits === "miles"
                            ? `${t("feet")}`
                            : `${t("m")}`}
                        </Typography>
                        <Line data={data} options={options} />
                      </div>
                    ) : null}
                  </>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("dashboard")(memo(DashboardActivityDetail)),
);
