import React from "react";
import {
  Toolbar,
  AppBar,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SmallAvatar from "../../generals/small-avatar";
import { palette } from "../../../theme/palette";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Logo from "./Logo";

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: palette.white,
  },
  titleFont: {
    fontFamily: "Karla",
    color: palette.black,
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: "0px",
  },
  toolbar: {
    display: "flex",
    backgroundColor: palette.white,
    justifyContent: "space-between",
    minHeight: 60,
    paddingLeft: 0,
  },
}));

const ToolbarReturnBack = ({
  title,
  goBack,
  withAvatar = false,
  customItem = null,
  withLogo = false,
  withClose = false,
  position = "sticky",
  elevation = 0,
  predefinedShadow = false,
  withBox = true,
  logoWidth = 88,
  style = {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar
      className={classes.appBar}
      style={{ boxShadow: predefinedShadow ? palette.boxShadow : "none" }}
      position={position}
      elevation={elevation}
    >
      <Toolbar className={classes.toolbar} style={style}>
        <IconButton id="back_post" onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
        {withLogo ? (
          <Logo widht={logoWidth} />
        ) : (
          <Typography variant="h4" className={classes.titleFont}>
            {t(`${title}`)}
          </Typography>
        )}

        {customItem}
        {withBox && <Box width={"60px"} />}
        {withClose && (
          <IconButton onClick={goBack}>
            <CloseIcon />
          </IconButton>
        )}
        {withAvatar && <SmallAvatar level={false} />}
      </Toolbar>
    </AppBar>
  );
};

ToolbarReturnBack.propTypes = {
  history: PropTypes.object,
  title: PropTypes.string,
};

ToolbarReturnBack.defaultProps = {
  title: "Vert",
};

export default ToolbarReturnBack;
