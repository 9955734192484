import React, { Component } from "react";
import { withMobileDialog, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import history from "../../../../lib/history-helper";
import {
  updatePlan,
  setErrorMessage,
} from "../../../../actions/user-plans-action";
import moment from "moment";
import DashboardNextRacePane from "./dashboard-next-race-pane";
import DashboardAddEditNextRace from "./dashboard-add-edit-next-race";
import { getAthlete } from "../../../../actions/trail-head-action";
import LocalNotificationsService from "../../../../services/local-notifications-service";
import { Capacitor } from "@capacitor/core";
import { Route } from "react-router-dom";
import AllRaces from "../../lists/all-races";

const styles = (theme) => ({
  container: {
    padding: 12,

    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },

  racesContainer: {
    [theme.breakpoints.down("sm")]: {
      "&::-webkit-scrollbar ": {
        display: "none",
      },

      overflowX: "scroll",
      overflowY: "hidden",
      whiteSpace: "nowrap",

      padding: 0,
    },
  },

  title: {
    fontWeight: 500,
    fontSize: 20,
    color: "#212121",

    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: 14,

      marginRight: "auto",
    },
  },
  boldText: {
    fontWeight: "bold",
    paddingRight: theme.spacing.unit * 0.5,
  },
  white: {
    color: theme.palette.common.white,
  },
  smallerFontSize: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller",
    },
  },
  dialogActions: {
    justifyContent: "center",
    marginBottom: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    cursor: "pointer",
    marginRight: 10,
  },
  leftButtons: {
    display: "flex",
    justifyContent: "flex-start",
  },
  rightMargin: {
    marginRight: theme.spacing.unit * 2,
  },
  buttons: {
    textTransform: "none",
  },
  comment: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing.unit,
    },
  },
  raceDateContainer: {
    display: "flex",
    height: "100%",
    paddingTop: theme.spacing.unit,
  },
  text: {
    color: theme.palette.text.secondaryDark,
    marginTop: "auto",
    fontSize: "small",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      marginTop: 0,
    },
  },
  addButtonContainer: {
    marginLeft: "10px",
    cursor: "pointer",
  },
  addButton: {
    marginTop: 10,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",

    [theme.breakpoints.down("sm")]: {
      margin: "0 0 12px 0",
    },
  },
  noPadding: {
    padding: 0,
  },

  extraContainer: {
    [theme.breakpoints.down("sm")]: {
      border: 0,

      background: "#FFFFFF",
      borderRadius: 12,
      padding: 16,
      margin: "0 0 12px 12px",
      minWidth: "calc(100% - 64px)",
      verticalAlign: "top",
      whiteSpace: "break-spaces",

      display: "inline-block",
      "&:last-child": {
        marginRight: 12,
      },
    },
  },
  addRace: {
    fontWeight: 400,
    fontSize: 14,
    color: "#FF6327",
  },
  titleLabel: {
    margin: "12px 12px 4px 12px",
    textAlign: "right",
    color: "#E16327",
    fontSize: 14,
    cursor: "pointer",
  },
});

class DashoardNextRaceDetails extends Component {
  state = {
    editing: null,
  };

  componentDidMount = () => {
    if (typeof window !== "undefined") {
      window.addEventListener("storage", () => this.localStorageUpdated());
    }
  };

  localStorageUpdated() {
    this.setState({
      ...this.state,
      currentUnits: localStorage.getItem("units"),
    });
  }

  handleClose = () => {
    if (
      history &&
      history.location &&
      history.location.pathname &&
      history.location.pathname.toLowerCase().includes("trailhead")
    ) {
      const { userPlans } = this.props;
      const { currentPlan } = userPlans;
      const { cognito_user_sub } = currentPlan;
      this.props.dispatch(getAthlete(cognito_user_sub));
    }
    history.goBack();
  };

  handleSetEditing = (id) => {
    this.setState({
      ...this.state,
      editing: id,
    });
  };

  handleSave = (race) => {
    if (
      race.raceName &&
      race.raceDistance &&
      race.raceElevation &&
      race.raceDate &&
      race.racePriority
    ) {
      const { userPlans, t } = this.props;
      const { currentPlan } = userPlans;
      const { nextRaces, nextRace } = currentPlan;

      if (race.raceId === "add") {
        race.raceId = new Date().getTime();
      }

      let newNextRaces = [];
      if (nextRaces) {
        newNextRaces = [
          ...newNextRaces,
          ...nextRaces.filter((r) => r.raceId !== race.raceId),
        ];
      }

      race.createdAt = new Date().getTime();
      newNextRaces.push(race);
      if (nextRace && race.raceId !== "old") {
        newNextRaces.push({ ...nextRace, raceId: new Date().getTime() });
      }

      this.props.dispatch(
        updatePlan(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            nextRace: null,
            nextRaces: newNextRaces,
          },
          false,
        ),
      );

      if (Capacitor.isNativePlatform()) {
        LocalNotificationsService.cancelRaceNotifications([
          race.raceId.toString() + "0",
          race.raceId.toString() + "1",
        ]);

        const raceDateMoment = moment(race.raceDate, "DD/MM/YYYY");
        raceDateMoment.hours(12);
        raceDateMoment.minutes(0);
        raceDateMoment.seconds(0);
        raceDateMoment.milliseconds(0);

        const raceBefore = moment(raceDateMoment).subtract(1, "days");
        const raceAfter = moment(raceDateMoment).add(1, "days");
        LocalNotificationsService.scheduleRaceNotifications(
          [+(race.raceId.toString() + "0"), +(race.raceId.toString() + "1")],
          [
            new Date(raceBefore.unix() * 1000),
            new Date(raceAfter.unix() * 1000),
          ],
          [t("raceBefore"), t("raceAfter")],
        );
      }

      this.setState({
        ...this.state,
        editing: null,
      });
    } else {
      this.props.dispatch(
        setErrorMessage("Please fill out all your race details"),
      );
    }
  };

  handleDelete = (raceId) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { nextRaces, nextRace } = currentPlan;

    let newNextRaces = [];
    if (nextRaces) {
      newNextRaces = nextRaces.filter((r) => r.raceId !== raceId);
    }

    if (nextRace && raceId !== "old") {
      newNextRaces.push({ ...nextRace, raceId: new Date().getTime() });
    }

    this.props.dispatch(
      updatePlan(
        {
          cognito_user_sub: currentPlan.cognito_user_sub,
          nextRace: null,
          nextRaces: newNextRaces,
        },
        false,
      ),
    );

    if (Capacitor.isNativePlatform()) {
      LocalNotificationsService.cancelRaceNotifications([
        raceId.toString() + "0",
        raceId.toString() + "1",
      ]);
    }

    this.setState({
      ...this.state,
      editing: null,
    });
  };

  goToAll = () => history.push("/dashboard/all-races");

  render() {
    const {
      classes,
      t,
      userPlans,
      onDialog = false,
      onlyRace = null,
      width,
      isTrailhead = false,
      onEdit = null,
    } = this.props;
    const { currentPlan } = userPlans;
    let { nextRace, nextRaces } = currentPlan || {};
    const { editing } = this.state;

    let allRaces = [];
    if (nextRace) {
      const today = moment();
      const raceDateMoment = moment(nextRace.raceDate, "DD/MM/YYYY");
      const weeksToRace = raceDateMoment.diff(today, "day");
      if (weeksToRace >= 0) {
        allRaces.push({ ...nextRace, raceId: "old" });
      }
    }
    if (nextRaces && nextRaces.length > 0) {
      nextRaces.forEach((element) => {
        const today = moment();
        const raceDateMoment = moment(element.raceDate, "DD/MM/YYYY");
        const weeksToRace = raceDateMoment.diff(today, "day");
        if (weeksToRace >= 0) {
          allRaces.push(element);
        }
      });
    }

    allRaces.sort((a, b) => {
      let aMoment;
      if (a && a.raceDate) {
        aMoment = moment(a.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      let bMoment;
      if (b && b.raceDate) {
        bMoment = moment(b.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      return aMoment.diff(bMoment, "days");
    });

    if (onlyRace) {
      allRaces = allRaces.filter((r) => r.raceId === onlyRace.raceId);
    }

    const raceToEdit = allRaces.find((r) => r.raceId === editing);

    const isInMobileView = width === "sm" || width === "xs";

    return (
      <>
        <div className={classes.container}>
          {onDialog ? (
            <div
              className={classes.rightButtons}
              onClick={() => this.handleClose()}
            >
              <img src={`${process.env.PUBLIC_URL}/close.svg`} alt="" />
            </div>
          ) : (
            ""
          )}

          {!onlyRace && !isInMobileView && !isTrailhead ? (
            <div className={classes.titleContainer}>
              <Typography variant="body1" className={classes.title}>
                {t("Your Races")}
              </Typography>

              <div className={classes.addButtonContainer}>
                <img
                  src={`${process.env.PUBLIC_URL}/icon-add-nr.svg`}
                  alt=""
                  onClick={() => this.handleSetEditing("add")}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {allRaces && allRaces.length && isInMobileView && !isTrailhead ? (
            <Typography className={classes.titleLabel} onClick={this.goToAll}>
              {t("See all races")}
            </Typography>
          ) : null}
          <div className={!isTrailhead ? classes.racesContainer : ""}>
            {allRaces.map((race, index) => (
              <div
                className={!isTrailhead ? classes.extraContainer : ""}
                key={JSON.stringify(race)}
                style={{
                  minWidth:
                    allRaces.length === 1
                      ? "calc(100% - 54px)"
                      : "calc(100% - 64px)",
                }}
              >
                {isInMobileView && !isTrailhead ? (
                  <div className={classes.titleContainer}>
                    <Typography variant="body1" className={classes.title}>
                      {t("Your Races")}
                    </Typography>

                    <Typography variant="body1" className={classes.addRace}>
                      {t("Add race or event")}
                    </Typography>

                    <div className={classes.addButtonContainer}>
                      <img
                        src={`${process.env.PUBLIC_URL}/icon-add-nr.svg`}
                        alt=""
                        onClick={() => this.handleSetEditing("add")}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <DashboardNextRacePane
                  nextRace={race}
                  userPlans={userPlans}
                  editing={editing}
                  onEdit={onEdit ? onEdit : this.handleSetEditing}
                  onSave={this.handleSave}
                  onDelete={this.handleDelete}
                  isTrailhead={isTrailhead}
                ></DashboardNextRacePane>
              </div>
            ))}

            {(!allRaces || !allRaces.length) &&
            isInMobileView &&
            !isTrailhead ? (
              <div
                className={classes.extraContainer}
                style={{
                  minWidth: "calc(100% - 54px)",
                }}
              >
                <div className={classes.titleContainer} style={{ margin: 0 }}>
                  <Typography variant="body1" className={classes.title}>
                    {t("Your Races")}
                  </Typography>

                  <Typography
                    variant="body1"
                    className={classes.addRace}
                    onClick={() => this.handleSetEditing("add")}
                  >
                    {t("Add race or event")}
                  </Typography>

                  <div className={classes.addButtonContainer}>
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-add-nr.svg`}
                      alt=""
                      onClick={() => this.handleSetEditing("add")}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          {editing ? (
            <DashboardAddEditNextRace
              isOpen={editing}
              onClose={() => this.handleSetEditing(null)}
              nextRace={raceToEdit}
              userPlans={userPlans}
              editing={editing}
              onEdit={this.handleSetEditing}
              onSave={this.handleSave}
              onDelete={this.handleDelete}
            ></DashboardAddEditNextRace>
          ) : (
            ""
          )}
        </div>
        <Route path="/dashboard/all-races" component={AllRaces} />
      </>
    );
  }
}

DashoardNextRaceDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  fullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  userPlans: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation("dashboard")(
      withWidth()(withMobileDialog()(DashoardNextRaceDetails)),
    ),
  ),
);
