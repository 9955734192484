import React from "react";
import {
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  addMonths,
  subMonths,
  isToday,
  getDay,
} from "date-fns";
import { withTranslation } from "react-i18next";
import "./dashboard-calendar.css";
import Weekdays from "../../../constants/weekdays";
import esLocale from "date-fns/locale/es";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import withWidth from "@material-ui/core/withWidth";
class DashboardCalendar extends React.Component {
  state = {
    currentMonth: new Date(),
    selectedDate: new Date(),
  };

  componentDidMount() {
    const { selectedDate } = this.state;
    this.onDateClick(selectedDate);
  }

  renderHeader() {
    const dateFormat = "MMMM yyyy";
    const { i18n } = this.props;
    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={this.prevMonth}>
            <ChevronLeftIcon></ChevronLeftIcon>
          </div>
        </div>
        <div className="col col-center">
          <span className="currentMonth">
            {format(this.state.currentMonth, dateFormat, {
              locale: languageDetector === "es" ? esLocale : null,
            })}
          </span>
        </div>
        <div className="col col-end">
          <div className="icon" onClick={this.nextMonth}>
            <ChevronRightIcon></ChevronRightIcon>
          </div>
        </div>
      </div>
    );
  }

  renderDays() {
    const { t } = this.props;

    let days = [];
    let startDate = startOfWeek(this.state.currentMonth, 0);

    for (let i = 0; i < 6; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {t(Weekdays.itemsArray[getDay(addDays(startDate, i))].text).substring(
            0,
            1,
          )}
        </div>,
      );
    }

    days = [
      <div className="col col-center" key={6}>
        {t(Weekdays.itemsArray[getDay(addDays(startDate, 6))].text).substring(
          0,
          1,
        )}
      </div>,
      ...days,
    ];

    return <div className="days row">{days}</div>;
  }

  renderCells() {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, 0).setHours(12);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`col cell ${
              !isSameMonth(day, monthStart)
                ? "disabled"
                : isSameDay(day, selectedDate)
                ? "selected"
                : isToday(day)
                ? "today"
                : this.findRaceDay(day)
                ? "race"
                : ""
            }`}
            key={day}
            onClick={() => this.onDateClick(cloneDay)}
          >
            <span className="number">{formattedDate}</span>
            {this.findTrainingDay(day) ? <span className="dot">•</span> : ""}
          </div>,
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>,
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  findTrainingDay = (day) => {
    const { trainingDays } = this.props;
    const found = trainingDays.find((td) => {
      if (td && td[0] && td[0].plannedDate) {
        const parts = td[0].plannedDate.split("/");
        if (isSameDay(day, new Date(+parts[2], +parts[1] - 1, +parts[0], 12))) {
          return true;
        }
      }
      return false;
    });
    return found;
  };

  findRaceDay = (day) => {
    const { races } = this.props;
    const found = races.find((race) => {
      if (race.raceDate) {
        const parts = race.raceDate.split("/");
        if (isSameDay(day, new Date(+parts[2], +parts[1] - 1, +parts[0], 12))) {
          return true;
        }
      }
      return false;
    });
    return found;
  };

  onDateClick = (day) => {
    const { onSelectDay } = this.props;
    this.setState({
      selectedDate: day,
    });
    onSelectDay({
      workout: this.findTrainingDay(day),
      race: this.findRaceDay(day),
    });
  };

  nextMonth = () => {
    this.setState({
      currentMonth: addMonths(this.state.currentMonth, 1),
    });
  };

  prevMonth = () => {
    this.setState({
      currentMonth: subMonths(this.state.currentMonth, 1),
    });
  };

  render() {
    return (
      <div className={"calendarDesktop"}>
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </div>
    );
  }
}

export default withWidth()(
  withTranslation(["constants", "dashboard"])(DashboardCalendar),
);
