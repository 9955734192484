import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Authenticator,
  SignUp,
  Greetings,
  SignIn,
  ForgotPassword,
} from "aws-amplify-react";
import { withStyles } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { I18n } from "aws-amplify";
import { withTranslation } from "react-i18next";

import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import CustomizedSignUp from "./customized-sign-up";
import CustomizedSignIn from "./customized-sign-in";
import AuthTheme from "./auth-theme";
import ConnectAuth from "../connectors/connect-auth";
import CustomizedForgotPassword from "./customized-forgot-password";

const authScreenLabels = {
  es: {
    "Sign in": "Iniciar sesión",
    "Sign In": "Iniciar sesión",
    "Sign in to your account": "Iniciar sesión",
    "Enter your username": "Correo electrónico",
    "Enter your password": "Contraseña",
    Password: "Contraseña",
    "Forgot your password? ": "Olvidaste tu contraseña?  ",
    "Reset password": "Restablecer la contraseña",
    "Enter your code": "Código de confirmación",
    "Lost your code? ": "Perdiste tu código?  ",
    "Resend Code": "Re enviar código",
    "Reset your password": "Ingresa tu nueva contraseña",
    "Enter your email to recover your password":
      "Ingresa tu email para recuperar tu contraseña",
    "An authentication code has been sent to your email":
      "Te hemos enviado un código de autentificación a tu email",
    "Password Recovery": "Recupera tu contraseña",
    "New Password": "Nueva Contraseña",
    Continue: "Continuar",
    Submit: "Enviar",
    "Back to Log In": "Volver a iniciar sesión",
    "Resend code": "Re enviar código",
    "Authentication Code": "Código de autenticación",
    "Confirm Sign Up": "Confirmar tu registro",
    "Don't have an account? ": "No tienes una cuenta? ",
    "Create account": "crea una aquí",
    "Have an account? ": "Ya tienes una cuenta? ",
    "Join us! And start training today.": "Únete y comienza a entrenar hoy!",
    "Online training": "Entrenamiento online",
    "for trail running and": "para trail running y",
    ultras: "ultras",
    Name: "Nombre",
    Username: "Ingresa tu correo electrónico",
    "Confirmation Code": "Ingresa el código que hemos enviado a tu correo",
    "Last name": "Apellido",
    "Password must contain 8 characters and a number":
      "Debe contener 8 carácteres con un número",
    "I accept the": "Acepto los",
    "terms and conditions": "terminos y condiciones",
    "Sign up": "Regístrate",
    "Incorrect username or password":
      "Correo electrónico o contraseña incorrecta",
    "These fields are required: Username, Password":
      "Estos campos son obligatorios: Correo electrónico, Contraseña",
    "Invalid Username or Password": "Usuario o contraseña invalido",
    "It must contain 8 characters with a capital letter, a number and a special character":
      "Debe contener 8 carácteres con una mayúscula, un número y un carácter especial",
    "Invalid verification code provided, please try again.":
      "Código de verficación invalido, por favor intenta de nuevo",
    "Join the 9,000+ trail runners who train with Vert.":
      "Más de 9.000 corredores ya entrenan con vert!",
    "It's great to have you back": "Qué bien tenerte de vuelta!",
    Email: "Email",
    "You already have an account with a different provider":
      "Ya tienes un usuario con un proveedor diferente",
    "Your password": "Tu contraseña",
    "Continue with Facebook": "Continuar con Facebook",
    "Continue with Google": "Continuar con Google",
    "Continue with Apple": "Continuar con Apple",
    "Sign up with Facebook": "Regístrate con Facebook",
    "Sign up with Google": "Regístrate con Google",
    "Sign up with Apple": "Regístrate con Apple",
    "Join us and start training today": "únete y comienza a entrenar hoy!",
    "Join us!": "Únete!",
    "And Start Training Today": "Comienza a entrenar ahora!",
    "Welcome Back!": "Qué bien tenerte de vuelta!",
    "Let's get to your training": "Comencemos a entrenar",
    "Don't train with us yet? Sign up":
      "Aún no entrenas con nosotros? Crea una cuenta",
    "By signing up for Vert.run you agree to the":
      "Al inscribirte en Vert.run aceptas nuestros",
    "View our": "Ver nuestra",
    "privacy policy & EULA": "política de privacidad & EULA",
    "or sign up with your email": "o con tu correo electrónico",
    "or sign in with your email": "o con tu correo electrónico",
    "Forgot your password?": "Olvidaste tu contraseña?",
    "Get a new one here": "Obtén una nueva aquí",
    "Log in": "Iniciar sesión",
    "Log In": "Iniciar sesión",
    "Already a Member?": "Ya tienes una cuenta?",
    "A user with this email address already exist, please sign in with your email & password.":
      "Ya existe un usuario con este correo electrónico, por favor ingrese con su correo electrónico y contraseña.",
    "Welcome to Vert.run": "Bienvenido(a) a Vert.run",
    "The training platform": "La plataforma de entrenamiento",
    "for Trail running + Ultramarathons": "para Trail running + Ultramaratones",
    "Log in to your account": "Inicia tu sesión",
    "PreSignUp failed with error A user with this email address already exist Google.":
      'Una cuenta con este email ya existe, intenta iniciar sesión utilizando "Iniciar sesión con Google".',
    "PreSignUp failed with error A user with this email address already exist Facebook.":
      'Una cuenta con este email ya existe, intenta iniciar sesión utilizando "Iniciar sesión con Facebook".',
    "PreSignUp failed with error A user with this email address already exist Email.":
      'Una cuenta con este email ya existe, intenta iniciar sesión utilizando "Iniciar sesión con correo electrónico".',
    "PreSignUp failed with error A user with this email address already exist Apple.":
      'Una cuenta con este email ya existe, intenta iniciar sesión utilizando "Iniciar sesión con Apple".',

    "PreSignUp failed with error A user with this email address already exist Google. ":
      'Una cuenta con este email ya existe, intenta iniciar sesión utilizando "Iniciar sesión con Google".',
    "PreSignUp failed with error A user with this email address already exist Facebook. ":
      'Una cuenta con este email ya existe, intenta iniciar sesión utilizando "Iniciar sesión con Facebook".',
    "PreSignUp failed with error A user with this email address already exist Email. ":
      'Una cuenta con este email ya existe, intenta iniciar sesión utilizando "Iniciar sesión con correo electrónico".',
    "PreSignUp failed with error A user with this email address already exist Apple. ":
      'Una cuenta con este email ya existe, intenta iniciar sesión utilizando "Iniciar sesión con Apple".',

    "PreSignUp+failed+with+error+A+user+with+this+email+address+already+exist+Google.+":
      'Una cuenta con este email ya existe, intenta iniciar sesión utilizando "Iniciar sesión con Google".',
    "PreSignUp+failed+with+error+A+user+with+this+email+address+already+exist+Facebook.+":
      'Una cuenta con este email ya existe, intenta iniciar sesión utilizando "Iniciar sesión con Facebook".',
    "PreSignUp+failed+with+error+A+user+with+this+email+address+already+exist+Email.+":
      'Una cuenta con este email ya existe, intenta iniciar sesión utilizando "Iniciar sesión con correo electrónico".',
    "PreSignUp+failed+with+error+A+user+with+this+email+address+already+exist+Apple.+":
      'Una cuenta con este email ya existe, intenta iniciar sesión utilizando "Iniciar sesión con Apple".',

    "PreAuthentication+failed+with+error+A+user+with+this+email+address+already+exist+Google.+":
      'Puedes ingresar a esta cuenta utilizando Google. Por favor, intenta iniciar tu sesión utilizando "Iniciar sesión con Google".',
    "PreAuthentication+failed+with+error+A+user+with+this+email+address+already+exist+Facebook.+":
      'Puedes ingresar a esta cuenta utilizando Facebook. Por favor, intenta iniciar tu sesión utilizando "Iniciar sesión con Facebook".',
    "PreAuthentication+failed+with+error+A+user+with+this+email+address+already+exist+Email.+":
      'Puedes ingresar a esta cuenta utilizando tu correo electrónico. Por favor, intenta iniciar tu sesión utilizando "Iniciar sesión con correo electrónico".',
    "PreAuthentication+failed+with+error+A+user+with+this+email+address+already+exist+Apple.+":
      'Puedes ingresar a esta cuenta utilizando Apple. Por favor, intenta iniciar tu sesión utilizando "Iniciar sesión con Apple".',

    testimony:
      "“He hecho dos planes de entrenamiento con Vert.run, solía tener tendencia a lesionarme, porque amo corer y solía correr más de lo adecuado. Gracias a Vert.run he encontrado la estructura que necesitaba. Un plan que me permite ver 2 semanas hacía adelante y que puedo ajustar a mi día mi vida y responsabilidades, con un entrenador que me entrega consejos y apoyo. <b>Si hubiera conocido a Vert.run antes, habría comenzado antes, además el equipo increíble que esta detrás de la pantalla te hace sentir como un PRO!”<b>",
    "Explore plan": "Plan explore",
    "SIGN UP FOR FREE!": "INSCRÍBETE GRATIS!",
    "THE TRAINING PLATFORM": "TU LUGAR DE ENTRENAMIENTO",
    "FOR TRAIL + ULTRA RUNNING": "PARA TRAIL + ULTRA RUNNING",
    "THE HOME FOR TRAIL & ULTRA RUNNERS":
      "LA APP #01 PARA TRAIL & ULTRA RUNNERS",
    "Join more than 26.000 trail runners from all over the world.":
      "Únete a mas de 26.000 trail runners de todo el mundo",
    "Let’s unlock your full potential.": "Encontremos tu máximo potencial",
    "Its simple":
      "Simple: Vert.run es tu <b>entrenador de running -- real y humano --</b> por $25/mes!",

    "Get fitter and faster with our training plans, designed by <b>world class athletes</b> for trail runners of all kinds.":
      "Más de 200+ planes de entrenamiento diseñados para corredores de todos los niveles. Por los mejores atletas del mundo.",
    "Find your personalized training plan":
      "Encuentra tu plan de entrenamiento personalizado",

    "Find your limits and explore your full potential with our <b>Free challenges</b>. For trail runners of all kinds.":
      "Encuentra tus límites y explora tu máximo potencial con nuestros <b>desafíos gratuitos</b> Para trail & ultra runners de todos los niveles.",
    "Elite, personalized training for trail and ultra runners of all kinds.":
      "Elite y personalizado, entrenamiento para trail y Ultra runners de todos los niveles.",
    "Vert.run": "Vert.run",
    "The #1 Trail & Ultra running App": "La App #1 en Trail y Ultra running",
    "Community I Training I Content I Challenges":
      "Comunidad I Entrenamiento I Información I Desafíos",
    "Join our community and discover our challenges.":
      "Únete a nuestra comunidad y descubre nuestros desafíos",
    "Start training today!": "Comienza a entrenar ahora!",
    "“I set a 1.5hr 100K PR on a very technical course… the stats speak for the validity of this platform!”":
      "“Gané 1.5hr en mi mejor tiempo de 100km en una ruta muy técnica... las estadísticas validan totalmente esta plataforma!”",
    "See how it works": "Cómo funciona",
    "You're unique -- so is your plan":
      "Eres única (o) -- también lo es tu plan",
    "Your training plan adapts to your level of experience, current fitness, changing schedule, training conditions and goals.":
      "Tu entrenamiento se adapta a tu nivel de experiencia, estado de forma, horario cambiante, condiciones de entrenamiento y objetivos.",
    "Includes coaching": "Incluye entrenador",
    "Your coach keeps you on track, answers your questions, and leaves you feedback and encouragement on a weekly basis.":
      "Tu entrenador te mantendrá en linea, respondiendo tus preguntas, dejándote comentarios y motivándote semana a semana",
    "Our training plans are designed by professional trail runners--winners of the most competitive and prestigious Ultramarathons in the world.":
      "Nuestros planes están diseñados por trail runners profesionales -- ganadores de las carreras más prestigiosas en el mundo.",
    "FREE & AFFORDABLE TRAINING PLANS":
      "PLANES DE ENTRENAMIENTO GRATUITOS Y ASEQUIBLES",
    "For trail runners of all levels":
      "Para trail runners de todos los niveles",
    Free: "Gratuito",
    "PER MONTH": "AL MES",
    "Only available on App": "Sólo disponible en nuestra app",
    "GET THE APP": "OBTÉN LA APP",
    "Access our platform and trail running training tools":
      "Acceso a la aplicación, comunidad y planes hasta 32 km",
    "Access to our Community Challenges":
      "Acceso a nuestros desafíos y videos workouts",
    "Receive your personal, weekly training plan":
      "Recibe tu plan semanal, gratis en tu perfil",
    "Train for races/distances of up to a Marathon":
      "Entrena para carreras o distancias de hasta 32 km",
    "Designed by world-class athletes": "Diseñado por atletas de nivel mundial",
    "Designed for all levels of experience":
      "Diseñado para todos los niveles de experiencia",
    "TRY IT FREE": "PRUEBALO GRATIS",
    "Includes a personal Vert.run coach":
      "Incluye to entrenador personal Vert.run",
    "Immediate access to 50+ training plans--<b>from 10k to 160k</b>":
      "Accede a más de 50 planes -- <b>desde 10k hasta 100 millas</b>",
    "<b>Unlimited adjustments</b> to your training schedule with our ‘Swap Days’ feature":
      "<b>Ajustes ilimitados</b> a tu entrenamiento y horario con nuestro feature ‘Swap days’",
    "Feeling low in energy? <b>Add a recovery week anytime</b>":
      "Te sientes con baja energía? <b>Agrega una semana de recuperación</b> cuándo lo necesites",
    "Training that works around your life--<b>re-start a week anytime</b>":
      "Entrenamiento que se adapta a tu vida -- <b>recomienza una semana</b> cuando lo necesites",
    "Switch plans anytime": "Cambia de plan cuando quieras",
    "Chat with your coach directly in your training dashboard":
      "Habla con tu entrenador directamente en tu perfil",
    "Everything from Explore +": "Todo lo de Explore +",
    "Train one-on-one with your private trail running coach":
      "Entrena uno a uno con tu entrenador privado",
    "Reach your coach at any time by text or email":
      "Contacta a tu entrenador cuándo lo necesites, vía texto o email",
    "Analyze and improve your results during regular review sessions with your coach":
      "Analiza y mejora tus resultados con sesiones de revisión junto a tu entrenador",
    "Train for all distances": "Entrena para todas las distancias",

    "First week coach onboarding and plan fitting":
      "Primera semana guiada por un entrenador",
    "Training that <b>works around your life</b> (Swap workouts, add easy days, add easy weeks, restart a week...)":
      "Entrenamiento que se adapta a tu vida con nuestra plataforma inteligente",
    "Access to our Trail community in our <b>Trailhead</b> feed":
      "Accede a nuestra comunidad cerrada con atletas de todo el mundo",
    "Train safely and follow your progress with <b>daily and weekly stats</b>":
      "Registra tu progreso con las métricas en tu perfil",
    "<b>Track your activities</b> with Strava, Garmin or Suunto":
      "Sigue tu entrenamiento con Garmin, Suunto y Strava",
    "Receive weekly coach support": "Recibe guia de tu coach cada semana",
    "<b>Chat with your coach</b> from your dashboard":
      "Chat con tu coach desde tu perfil",
    "FIRST 7 DAYS ARE FREE!": "PRIMEROS 7 DÍAS SON GRATIS!",

    "START TRAINING": "COMIENZA A ENTRENAR",
    "Vert.run for Android": "Vert.run para Android",
    "Vert.run for iPhone": "Vert.run para iPhone",
    "Get the App": "Obtén la App",
    "There is a new version available": "Hay una nueva versión disponible",
    Update: "Actualizar",

    "Run my first Ultramarathon": "Correr mi primer Ultramaratón",
    "Start trail running": "Empezar a correr trail",
    "Get back in trail shape": "Recuperar mi estado de forma",
    "Run my next Ultra": "Correr mi próximo Ultra",
    "Train with a free plan": "Entrenar con un plan gratuito",
    "Perform better in races": "Lograr mejores resultados",
    "Not really, want to start from zero": "No, quiero empezar de cero",
    "Kind of. Less than 3h per week": "Corro menos de 3h por semana",
    "Yeah. About 5h per week": "Sí. Corro cerca de 5h por semana",
    "Im training 6+ hours per week": "Entreno más de 6h por semana",
    "TRAIL &": "ENTRENAMIENTO PARA",
    "ULTRA TRAINING": "TRAIL & ULTRA",

    "What's your next goal?": "¿Cuál es tu próximo objetivo?",
    "Are you currently training?": "¿Estás entrenando actualmente?",
    "What has been your longer race ever?":
      "¿Cuál ha sido tu carrera más larga?",

    "FIND YOUR PLAN": "ENCUENTRA TU PLAN",

    "Are you ready for your next race?": "¿Listo(a) para tu siguiente carrera?",
    "Train with us and reach our goals.":
      "Entrena con nosotros y cumple tus objetivos",

    "Vert is designed for trail runners, by trail runners.":
      "Vert es diseñado para trail runners, por trail runners.",
    "What's Vert.Run?": "¿Qué es Vert.Run?",

    "Training plans": "Planes de entrenamiento",
    "Exclusively designed for trail & Ultra runners of all kind by world class athletes.":
      "Diseñado exclusivamente para trail y Ultra runners de todo tipo, por atletas de clase mundial",
    "See all training plans": "Ver todos los planes",
    Challenges: "Desafíos",
    "Find your limits and explore your full potential with our Free challenges. For all levels of experience.":
      "Encuentra tus limites y explora tu potencial con nuestro desafíos Gratis y para todos los niveles de corredores",
    "Discover our challenges": "Descubre nuestros desafíos",
    "Discover training like you've never seen it before":
      "Descubre entrenamientos como nunca antes los has visto",
    "from 5k to 100 miles": "de 5k a 100 millas",
    Community: "Comunidad",
    "Join liked minded trail runners from all over the world. Share ideas, results, adventures and learn from fellow athletes like you":
      "Únete a una comunidad de trail runners y corredores de todo el mundo. Comparte ideas, aventuras, consejos y experiencias con corredores como tú.",
    "From 5k to 100 miles.": "Desde 5k hasta 100 millas o más",
    "About us": "Sobre nosotros",
    "Plan suggester": "Recomendador de planes",
    "Sign up free": "Regístrate gratis",
    "Exclusively designed for trail & ultra runners of all kind by world class athletes.":
      "Exclusivamente diseñados para trail & ultra runners de todo tipo por atletas de clase mundial.",
    "Find your plan": "Encuentra tu plan",
    "What’s your next goal?": "¿Cuál es tu próximo objetivo?",
    Difficulty: "Dificultad",
    Medium: "Media",
    "Run your fastest Vertical Kilometer ever! With Pascal Egli":
      "Corre tu KV más rápido, con Pascal Egli",
    "Run, walk, hike 1000 meters of elevation gain as fast as you possibly can. If you've never run a Vertical Kilometer, or VK, this is the perfect chance to discover just how hard they are (haha.) No matter if you're a novice or VK pro, push your lungs to the limit this week.":
      "Corre, camina 1000 metros de desnivel positivos lo más rápido posible. Si nunca has hecho un Kilómetro Vertical, este es el momento perfecto para descubrir que tán díficiles son (hah). No importa si eres un experto o estas comenzando, esta es la excusa perfecta para empujar tus limites esta semana.",
    "Choose a plan that’s right for you":
      "No importa si entrenas para tus primeros 5km o tus próximas 100 Millas, con tu entrenador todo es posible!",
    "Get Started": "COMENZAR GRATIS",
    "New User?": "¿Nuevo usuario?",
    "Create an account": "Regístrate",
    "Already have an account?": "¿Ya tienes una cuenta?",
    "The most complete App for Trail & Ultra runners":
      "Sólo $25 USD / mes. En serio!",
    "NEW PLAN": "NUEVO PLAN",
    "RUN A 50K LIKE PAU CAPELL": "CORRE UN 50K COMO PAU CAPELL",
    "Vert.run: The #1 Trail & Ultra running App":
      "Vert.run: La App número #1 para Trail & Ultra runners",
    "RUNNING COACHING FOR EVERYONE": "Tu Entrenador de running personal",
    "Create Free Account": "Crea una cuenta gratis",
    "Welcome to the future of Trail Running":
      "Bienvenido (a) al futuro del Trail Running",
    "Get Started App": "Comenzar",
    "Already a member?": "Ya eres miembro?",
    "If you move...": "Tu también eres un atleta!",
    "Free Video Workouts": "Sesiones en Vídeo",
    "From quick 5-min":
      "Desde sesiones rápidas de 5 minutos de fuerza, core... hasta sesiones de fuerza de cuerpo completo. Para prevenir lesiones y volverte más fuerte. Todas son guiadas en video, dentro de la app.",
    "See our video workouts": "Revisa nuestra librería de videos",
    "+ Coaching": "Entrenador",
    "+ 6 Months Coaching": "Coach por 6 meses",
    "Access 200+ training plans for Road, Trails and Ultramarathons":
      "Acceso a 200+ planes para trails, ultras y carreras de asfalto",
    "Receive weekly guidance from your coach":
      "Recibe soporte y guía semanal de tu entrenador",
    "Chat with your coach in your dashboard":
      "Chatea con tu entrenador directamente desde tu perfil",
    "Training that works around your life (Swap workouts, add easy days, add easy weeks, add customs workouts...)":
      "Tu entrenamiento se adapta a tu vida: puedes mover sesiones, agregar días fáciles, agregar semanas de descanso... ",
    "Follow your progress: track your Mountain Index, training load, energy levels and more":
      "Sigue tu progreso: con tu Mountain Index, carga de entrenamiento, niveles de energía y más...",
    "Track your activities with Strava, Garmin,  Suunto or Apple watch":
      "Sincroniza tu Strava, Garmin, Apple watch, Coros y más. ",
    "Export workouts to your Garmin": "Exporta workouts a tu Garmin",
    "GET A COACH": "ELIGE TU ENTRENADOR",
    "Everything from our Monthly Coaching subscription - with special 6-month pricing":
      "Las mismas funcionalidades que nuestro plan de coaching mensual, pero a un precio especial.",
    "/per month": "/al mes",
    "What’s included:": "Que incluye:",
  },
  en: {
    "Lost your code? ": "Didn't recieve your code? ",
    "Back to Sign In": "Back to sign in",
    "Confirm Sign Up": "Confirm sign up",
    "Reset your password": "Password recovery",
    "Enter your username": "Email",
    "Enter your code": "Confirmation Code",
    "A user with this email address already exist, please sign in with your email & password.":
      "A user with this email address already exist, please sign in with your email & password.",
    Username: "Enter your email",
    "Confirmation Code": "Enter the code that we just emailed to you",
    "PreSignUp failed with error A user with this email address already exist Google.":
      'An account with this email already exists, please try to log in using the "Log in with Google" option',
    "PreSignUp failed with error A user with this email address already exist Facebook.":
      'An account with this email already exists, please try to log in using the "Log in with Facebook" option',
    "PreSignUp failed with error A user with this email address already exist Email.":
      'An account with this email already exists, please try to log in using the "Log in with Email" option',
    "PreSignUp failed with error A user with this email address already exist Apple.":
      'An account with this email already exists, please try to log in using the "Log in with Apple" option',

    "PreSignUp failed with error A user with this email address already exist Google. ":
      'An account with this email already exists, please try to log in using the "Log in with Google" option',
    "PreSignUp failed with error A user with this email address already exist Facebook. ":
      'An account with this email already exists, please try to log in using the "Log in with Facebook" option',
    "PreSignUp failed with error A user with this email address already exist Email. ":
      'An account with this email already exists, please try to log in using the "Log in with Email" option',
    "PreSignUp failed with error A user with this email address already exist Apple. ":
      'An account with this email already exists, please try to log in using the "Log in with Apple" option',

    "PreSignUp+failed+with+error+A+user+with+this+email+address+already+exist+Google.+":
      'An account with this email already exists, please try to log in using the "Log in with Google" option',
    "PreSignUp+failed+with+error+A+user+with+this+email+address+already+exist+Facebook.+":
      'An account with this email already exists, please try to log in using the "Log in with Facebook" optionv',
    "PreSignUp+failed+with+error+A+user+with+this+email+address+already+exist+Email.+":
      'An account with this email already exists, please try to log in using the "Log in with Email" option',
    "PreSignUp+failed+with+error+A+user+with+this+email+address+already+exist+Apple.+":
      'An account with this email already exists, please try to log in using the "Log in with Apple" option',

    "PreAuthentication+failed+with+error+A+user+with+this+email+address+already+exist+Google.+":
      'This account can only be logged into with Google, please try to log in using the "Log in with Google" option',
    "PreAuthentication+failed+with+error+A+user+with+this+email+address+already+exist+Facebook.+":
      'This account can only be logged into with Facebook, please try to log in using the "Log in with Facebook" option',
    "PreAuthentication+failed+with+error+A+user+with+this+email+address+already+exist+Email.+":
      'This account can only be logged into with Email, please try to log in using the "Log in with Email" option',
    "PreAuthentication+failed+with+error+A+user+with+this+email+address+already+exist+Apple.+":
      'This account can only be logged into with Apple, please try to log in using the "Log in with Apple" option',

    testimony:
      "“I have already done 2 training plans with Vert.run. I had a tendency of getting injured because I love running, and me overdoing it was not helping. Thanks to Vert.run I have a structure, a 2-week plan ahead that I can check to fit in my day-to-day life, and a coach that gives advice and support. <b>If I had known Vert.run earlier I would started then, plus the great great team behind the screen makes you feel like a PRO!”</b>",
    "Get Started App": "Get Started",

    "Its simple":
      "It’s simple: Vert.run is running <b>coaching--from a real, human coach--</b>for $25 USD/month. Yes, seriously!",
    "If you move...": "If you move, you’re an athlete",
    "Get fitter and faster with our training plans, designed by <b>world class athletes</b> for trail runners of all kinds.":
      "Over 200+ training plans designed for runners of ALL levels--by the best athletes in the world.",
    "Free Video Workouts": "Free Video Workouts",
    "From quick 5-min":
      "From quick 5-min sessions--to full-body core, strength, stability, ankle strength, mobility routines and more. Build fitness and prevent injury with our free video workouts.",
    "See our video workouts": "See our video workouts",
    "Join liked minded trail runners from all over the world. Share ideas, results, adventures and learn from fellow athletes like you":
      "Connect with like-minded trail runners from all over the world. Share ideas, adventures and learn from fellow runners like you, in our in-app community: “The Trailhead.”",
    "Choose a plan that’s right for you":
      "No matter if you’re training for your first 5k or your next 100 Miler, your Vert.run coach will get you there.",
    "The most complete App for Trail & Ultra runners":
      "Just $25 USD / month. Seriously.",
    "Train for races/distances of up to a Marathon":
      "Train for races/distances of up to 20 miles",
    "Get Started": "START FOR FREE",
  },
  fr: {
    "Sign in": "Se connecter",
    "Sign In": "Se connecter",
    "Sign in to your account": "Se connecter",
    "Enter your username": "Adresse e-mail",
    "Enter your password": "Mot de passe",
    Password: "Mot de passe",
    "Forgot your password?": "Mot de passe oublié ?",
    "Reset password": "Réinitialiser le mot de passe",
    "Enter your code": "Code de confirmation",
    "Lost your code?": "Vous avez perdu votre code?",
    "Resend Code": "Renvoyer le code",
    "Reset your password": "Entrez votre nouveau mot de passe",
    "Enter your email to recover your password":
      "Entrez votre e-mail pour récupérer votre mot de passe",

    "An authentication code has been sent to your email":
      "Nous avons envoyé un code d'authentification à votre adresse mail",

    "Password Recovery": "Récupérez votre mot de passe",
    "New Password": "Nouveau mot de passe",
    Continue: "Continuer",
    Submit: "Envoyer",
    "Back to Log In": "Revenir à la page de connexion",
    "Resend code": "Renvoyer le code",
    "Authentication Code": "Code d'authentification",
    "Confirm Sign Up": "Confirmer votre inscription",
    "Don't have an account?": "Pas encore inscrit ?",
    "Create account": "Créer un compte",
    "Have an account?": "Vous avez déjà un compte?",
    "Join us! And start training today.":
      "Rejoignez-nous et commencez à vous entraîner dès maintenant !",
    "Online training": "Entraînement en ligne",
    "for trail running and": "pour le trail running et",
    ultras: "les ultras",
    Name: "Prénom",
    Username: "Entrez votre adresse e-mail",
    "Confirmation Code": "Entrez le code que vous avez reçu par mail",
    "Last name": "Nom de famille",
    "Password must contain 8 characters and a number":
      "Doit contenir 8 caractères et un chiffre",

    "I accept the": "J'accepte les",
    "terms and conditions": "conditions d'utilisation",
    "Sign up": "Inscrivez-vous",
    "Incorrect username or password":
      "Adresse e-mail ou mot de passe incorrect",

    "These fields are required": "Ces champs sont obligatoires",
    "Username, Password": "Adresse e-mail Mot de passe",
    "Invalid Username or Password":
      "Nom d'utilisateur ou mot de passe invalide",
    "It must contain 8 characters with a capital letter, a number and a special character":
      "Doit contenir 8 caractères dont une majuscule un chiffre et un caractère spécial",

    "Invalid verification code provided, please try again.":
      "Code de vérification invalide veuillez réessayer",

    "Join the 9,000+ trail runners who train with Vert.":
      "Plus de 9 000 coureurs s'entraînent déjà avec Vert.",

    "It's great to have you back": "Ravi de te revoir!",
    Email: "Email",
    "You already have an account with a different provider":
      "Vous vous êtes déjà connecté via une autre plateforme",

    "Your password": "Votre mot de passe",
    "Continue with Facebook": "Continuer avec Facebook",
    "Continue with Google": "Continuer avec Google",
    "Continue with Apple": "Continuer avec Apple",
    "Sign up with Facebook": "S'inscrire avec Facebook",
    "Sign up with Google": "S'inscrire avec Google",
    "Sign up with Apple": "S'inscrire avec Apple",
    "Join us and start training today":
      "Rejoignez-nous et commencez à vous entraîner dès maintenant!",
    "Join us!": "Rejoignez-nous!",
    "And Start Training Today": "Commencez à vous entraîner maintenant!",
    "Welcome Back!": "Ravi de vous revoir!",
    "Let's get to your training": "Commençons votre entraînement",
    "Don't train with us yet? Sign up":
      "Vous ne vous entraînez pas encore avec nous? Créez un compte",

    "By signing up for Vert.run you agree to the":
      "En vous inscrivant à Vert.run vous acceptez nos",

    "View our": "Consulter notre",
    "privacy policy & EULA": "politique de confidentialité & EULA",
    "or sign up with your email": "ou avec votre e-mail",
    "or sign in with your email": "ou avec votre e-mail",
    "Forgot your password?": "Mot de passe oublié ?",
    "Get a new one here": "Réinitialiser votre mot de passe",
    "Log in": "Se connecter",
    "Log In": "Se connecter",
    "Already a Member?": "Vous avez déjà un compte?",
    "A user with this email address already exist, please sign in with your email & password.":
      "Un utilisateur existe déjà avec cette adresse e-mail connectez vous avec votre e-mail et votre mot de passe.",

    "Welcome to Vert.run": "Bienvenue sur Vert.run",
    "The training platform": "La plateforme d'entraînement",
    "for Trail running + Ultramarathons":
      "pour le trail running et l'utlra-trail",
    "Log in to your account": "Connectez-vous",
    "PreSignUp failed with error A user with this email address already exist Google.":
      "Un compte associé à cet e-mail existe déjà essayez de vous connecter en utilisant Se connecter avec Google.'",

    "PreSignUp failed with error A user with this email address already exist Facebook.":
      "Un compte associé à cet e-mail existe déjà essayez de vous connecter en utilisant Se connecter avec Facebook.'",

    "PreSignUp failed with error A user with this email address already exist Email.":
      "Un compte associé à cet e-mail existe déjà essayez de vous connecter en utilisant Se connecter avec e-mail.'",

    "PreSignUp failed with error A user with this email address already exist Apple.":
      "Un compte associé à cet e-mail existe déjà essayez de vous connecter en utilisant Se connecter avec Apple.",

    "PreSignUp failed with error A user with this email address already exist Google.":
      "Un compte associé à cet e-mail existe déjà essayez de vous connecter en utilisant Se connecter avec Google.'",

    "PreSignUp failed with error A user with this email address already exist Facebook.":
      "Un compte associé à cet e-mail existe déjà essayez de vous connecter en utilisant Se connecter avec Facebook.'",

    "PreSignUp failed with error A user with this email address already exist Email.":
      "Un compte associé à cet e-mail existe déjà essayez de vous connecter en utilisant Se connecter avec e-mail.'",

    "PreSignUp failed with error A user with this email address already exist Apple.":
      "Un compte associé à cet e-mail existe déjà essayez de vous connecter en utilisant Se connecter avec Apple.'",

    "PreSignUp+failed+with+error+A+user+with+this+email+address+already+exist+Google.+":
      "Un compte associé à cet e-mail existe déjà essayez de vous connecter en utilisant Se connecter avec Google.'",

    "PreSignUp+failed+with+error+A+user+with+this+email+address+already+exist+Facebook.+":
      "Un compte associé à cet e-mail existe déjà essayez de vous connecter en utilisant Se connecter avec Facebook.'",

    "PreSignUp+failed+with+error+A+user+with+this+email+address+already+exist+Email.+":
      "Un compte associé à cet e-mail existe déjà essayez de vous connecter en utilisant Se connecter avec e-mail.'",

    "PreSignUp+failed+with+error+A+user+with+this+email+address+already+exist+Apple.+":
      "Un compte associé à cet e-mail existe déjà essayez de vous connecter en utilisant Se connecter avec Apple.'",

    "PreAuthentication+failed+with+error+A+user+with+this+email+address+already+exist+Google.+":
      "Vous pouvez accéder à ce compte via Google. Essayez de vous connecter en utilisant Se connecter avec Google.'",

    "PreAuthentication+failed+with+error+A+user+with+this+email+address+already+exist+Facebook.+":
      "Vous pouvez accéder à ce compte via Facebook. Essayez de vous connecter en utilisant Se connecter avec Facebook.'",

    "PreAuthentication+failed+with+error+A+user+with+this+email+address+already+exist+Email.+":
      "Vous pouvez accéder à ce compte via votre adresse e-mail. Essayez de vous connecter en utilisant Se connecter avec e-mail.'",

    "PreAuthentication+failed+with+error+A+user+with+this+email+address+already+exist+Apple.+":
      "Vous pouvez accéder à ce compte via Apple. Essayez de vous connecter en utilisant Se connecter avec Apple.'",

    testimony:
      "J'ai fait deux plans d'entraînement avec Vert.run j'avais tendance à me blesser parce que j'aime courir et que je courais plus que nécessaire. Grâce à Vert.run j'ai trouvé la structure dont j'avais besoin. Ce plan me permet de prévoir mes entraînements 2 semaines à l'avance et de les adapter à ma vie quotidienne et à mes responsabilités. Mon coach me donne des conseils et me soutient. <b>Si j'avais connu Vert.run plus tôt j'aurais commencé à m'entraîner bien avant en plus la formidable équipe qui est derrière l'écran te fait te sentir comme un PRO !<b>",

    "Explore plan": "Ta souscription Vert.run",
    "SIGN UP FOR FREE!": "INSCRIs-TOI GRATUITEMENT!",
    "THE TRAINING PLATFORM": "TON LIEU D'ENTRAÎNEMENT",
    "FOR TRAIL + ULTRA RUNNING": "POUR LE TRAIL + L'ULTRA TRAIL",
    "THE HOME FOR TRAIL & ULTRA RUNNERS":
      "L'APP #01 POUR LES TRAILEURS & ULTRA TRAILEURS",

    "Join more than 26.000 trail runners from all over the world.":
      "Rejoins plus de 26 000 traileurs du monde entier",

    "Let’s unlock your full potential.": "Libérons tout ton potentiel",
    "Its simple":
      "Simple: Vert.run est ton <b>coach de running - réel et humain -</b> pour 25 $ par mois !",

    "Get fitter and faster with our training plans, designed by <b>world class athletes</b> for trail runners of all kinds.":
      "Plus de 200 plans d'entraînement conçus pour les coureurs de TOUS niveaux par les meilleurs athlètes du monde.",

    "Find your personalized training plan":
      "Trouve ton plan d'entraînement personnalisé",

    "Find your limits and explore your full potential with our <b>Free challenges</b>. For trail runners of all kinds.":
      "Teste tes limites et explore tout ton potentiel avec nos <b>défis gratuits</b> pour les traileurs & ultra traileurs de tous niveaux.",

    "Elite, personalized training for trail and ultra runners of all kinds.":
      "Un entraînement personnalisé et créé par des athlètes élites pour les traileurs et ultra-traileurs de tous niveaux.",

    "Vert.run": "Vert.run",
    "The #1 Trail & Ultra running App": "L'App #1 en Trail et Ultra trail",
    "Community I Training I Content I Challenges":
      "Communauté I Entraînement I Information I Défis",

    "Join our community and discover our challenges.":
      "Rejoins notre communauté et découvre nos défis",

    "Start training today!": "Commence à t'entraîner dès maintenant !",
    "“I set a 1.5hr 100K PR on a very technical course… the stats speak for the validity of this platform!”":
      "J'ai gagné 1h30 sur mon meilleur temps au 100 km sur un parcours très technique... les chiffres parlent d'eux même quant à la légitimité de cette plateforme !",

    "See how it works": "Comment ça marche",
    "You're unique -- so is your plan":
      "Tu es unique - et ton plan l'est aussi",

    "Your training plan adapts to your level of experience, current fitness, changing schedule, training conditions and goals.":
      "Ton entraînement s'adapte à ton expérience ta forme tes horaires tes conditions d'entraînement et tes objectifs.",

    "Includes coaching": "Inclus un coach",
    "Your coach keeps you on track, answers your questions, and leaves you feedback and encouragement on a weekly basis.":
      "Ton coach te garde sur la bonne voie répond à tes questions te fait des retours et t'encourage chaque semaine",

    "Our training plans are designed by professional trail runners--winners of the most competitive and prestigious Ultramarathons in the world.":
      "Nos plans sont conçus par des traileurs professionnels - vainqueurs des courses les plus prestigieuses au monde.",

    "FREE & AFFORDABLE TRAINING PLANS":
      "PLANS D'ENTRAÎNEMENT GRATUITS ET ABORDABLES",

    "For trail runners of all levels":
      "Pour les coureurs de trail de tous niveaux",

    Free: "Gratuit",
    "PER MONTH": "PAR MOIS",
    "Only available on App": "Disponible uniquement sur notre application",
    "GET THE APP": "OBTIENS L'APP",
    "Access our platform and trail running training tools":
      "Accès à notre plateforme et à nos outils d'entraînement en trail",

    "Access to our Community Challenges": "Participation à nos défis gratuites",

    "Receive your personal, weekly training plan":
      "Recevez votre plan d'entraînement personnel hebdomadaire",

    "Train for races/distances of up to a Marathon":
      "Entraînement pour des courses/distances allant jusqu'à 30 km",

    "Designed by world-class athletes":
      "Conçu par des athlètes de niveau mondial",
    "Designed for all levels of experience":
      "Conçu pour tous les niveaux d'expérience",

    "TRY IT FREE": "ESSAIE-LE GRATUITEMENT",
    "Includes a personal Vert.run coach": "Inclus ton coach personnel Vert.run",

    "Immediate access to 50+ training plans--<b>from 10k to 160k</b>":
      "Accède à plus de 50 plans - <b>de 10 à 160 km</b>",

    "<b>Unlimited adjustments</b> to your training schedule with our ‘Swap Days’ feature":
      "<b>Ajustements illimités</b> à ton planning d'entraînement et tes horaires avec notre fonctionnalité Swap days",

    "Feeling low in energy? <b>Add a recovery week anytime</b>":
      "Tu te sens fatigué ? <b>Ajoute une semaine de récupération</b> quand tu en as besoin",

    "Training that works around your life--<b>re-start a week anytime</b>":
      "Un entraînement qui s'adapte à ta vie - <b>redémarre une semaine</b> quand tu en as besoin",

    "Switch plans anytime": "Change de plan quand tu veux",
    "Chat with your coach directly in your training dashboard":
      "Parle avec ton coach directement sur ton profil",

    "Everything from Explore +": "Tout ce que comprend ta souscription",
    "Train one-on-one with your private trail running coach":
      "Entraîne-toi en tête à tête avec ton coach personnel",

    "Reach your coach at any time by text or email":
      "Contacte ton coach quand tu en as besoin par texto ou par e-mail",

    "Analyze and improve your results during regular review sessions with your coach":
      "Analyse et améliore tes résultats grâce à des séances bilan régulières avec ton coach",

    "Train for all distances": "Entraîne-toi pour toutes les distances",

    "First week coach onboarding and plan fitting":
      "Première semaine accueil du coach et ajustement du plan",

    "Training that <b>works around your life</b> (Swap workouts, add easy days, add easy weeks, restart a week...)":
      "Un entraînement qui <b>s'adapte à ta vie>/b> avec notre plateforme intelligente",

    "Access to our Trail community in our <b>Trailhead</b> feed":
      "Rejoins notre communauté de traileurs avec des athlètes du monde entier",

    "Train safely and follow your progress with <b>daily and weekly stats</b>":
      "Enregistre tes progrès avec des <b>statistiques journalières et hebdomadaires</b>",

    "<b>Track your activities</b> with Strava, Garmin or Suunto":
      "Suis ton entraînement avec Garmin Suunto et Strava",

    "Receive weekly coach support":
      "Reçois des conseils de ton coach chaque semaine",
    "<b>Chat with your coach</b> from your dashboard":
      "Chat avec ton coach depuis ton profil",

    "FIRST 7 DAYS ARE FREE!": "LES 7 PREMIERS JOURS SONT GRATUITS!",

    "START TRAINING": "COMMENCE À T'ENTRAÎNER",
    "Vert.run for Android": "Vert.run pour Android",
    "Vert.run for iPhone": "Vert.run pour iPhone",
    "Get the App": "Obtiens l'App",
    "There is a new version available": "Une nouvelle version est disponible",
    Update: "Mettre à jour",

    "Run my first Ultramarathon": "Courir mon premier Ultra-marathon",
    "Start trail running": "Commencer à courir en trail",
    "Get back in trail shape": "Retrouver ma forme physique",
    "Run my next Ultra": "Courir mon prochain Ultra",
    "Train with a free plan": "M'entraîner avec un plan gratuit",
    "Perform better in races": "Obtenir de meilleurs résultats",
    "Not really, want to start from zero": "Non je veux partir de zéro",
    "Kind of. Less than 3h per week":
      "Un peu. Je cours moins de 3h par semaine",
    "Yeah. About 5h per week": "Oui. Je cours environ 5h par semaine",
    "Im training 6+ hours per week": "Je m'entraîne plus de 6h par semaine",
    "TRAIL &": "ENTRAÎNEMENT POUR",
    "ULTRA TRAINING": "TRAIL & ULTRA TRAIL",

    "What's your next goal?": "Quel est ton prochain objectif ?",
    "Are you currently training?": "T'entraînes-tu actuellement ?",
    "What has been your longer race ever?":
      "Quelle a été ta course la plus longue ?",

    "FIND YOUR PLAN": "TROUVE TON PLAN",

    "Are you ready for your next race?": "Prêt(e) pour ta prochaine course ?",
    "Train with us and reach our goals.":
      "Entraîne-toi avec nous et atteins tes objectifs",

    "Vert is designed for trail runners, by trail runners.":
      "Vert est conçu pour les traileurs par des traileurs.",

    "What's Vert.Run?": "Qu'est-ce que Vert.Run ?",

    "Training plans": "Plans d'entraînement",
    "Exclusively designed for trail & Ultra runners of all kind by world class athletes.":
      "Conçu exclusivement pour les traileurs et ultra-traileurs de tous niveaux par des athlètes de renommée mondiale",

    "See all training plans": "Voir tous les plans",
    Challenges: "Défis",
    "Find your limits and explore your full potential with our Free challenges. For all levels of experience.":
      "Teste tes limites et explore tout ton potentiel avec nos défis gratuits de tous niveaux.",

    "Discover our challenges": "Découvre nos défis",
    "Discover training like you've never seen it before":
      "Découvre des entraînements jamais vus auparavant",

    "from 5k to 100 miles": "de 5 à 160 km",
    Community: "Communauté",
    "Join liked minded trail runners from all over the world. Share ideas, results, adventures and learn from fellow athletes like you":
      "Rejoins des coureurs de trail partageant les mêmes intérêts du monde entier. Partagez des idées, des résultats, des aventures et apprenez des coureurs comme vous dans notre Trailhead.",

    "From 5k to 100 miles.": "De 5 à 160 km voire plus",
    "About us": "À propos de nous",
    "Plan suggester": "Suggesteur de plans",
    "Sign up free": "Inscris-toi gratuitement",
    "Exclusively designed for trail & ultra runners of all kind by world class athletes.":
      "Exclusivement conçus pour les traileurs & ultra-traileurs de tous niveaux par des athlètes de renommée mondiale.",

    "Find your plan": "Trouve ton plan",
    "What’s your next goal?": "Quel est ton prochain objectif ?",
    Difficulty: "Difficulté",
    Medium: "Moyenne",
    "Run your fastest Vertical Kilometer ever! With Pascal Egli":
      "Fais ton Kilomètre Vertical le plus rapide ! Avec Pascal Egli",

    "Run, walk, hike 1000 meters of elevation gain as fast as you possibly can. If you've never run a Vertical Kilometer, or VK, this is the perfect chance to discover just how hard they are (haha.) No matter if you're a novice or VK pro, push your lungs to the limit this week.":
      "Cours ou marche 1000 mètres de dénivelé positif le plus rapidement possible. Si tu n'as jamais fait un Kilomètre Vertical c'est le moment idéal pour découvrir à quel point ils sont difficiles (haha). Peu importe si tu es un expert du KV ou si tu débutes c'est l'excuse parfaite pour repousser tes limites cette semaine.",

    "Choose a plan that’s right for you":
      "Peu importe que tu t'entraînes pour ton premier 5 km ou pour ton prochain 100 miles, avec ton coach Vert.run, tu réussiras à le faire.",

    "Get Started": "Commencez gratuitement",
    "New User?": "Nouvel utilisateur ?",
    "Create an account": "Inscris-toi",
    "Already have an account?": "Tu as déjà un compte ?",
    "The most complete App for Trail & Ultra runners":
      "Seulement 25 $ par mois. Sérieusement!",

    "NEW PLAN": "NOUVEAU PLAN",
    "RUN A 50K LIKE PAU CAPELL": "COURS UN 50K COMME PAU CAPELL",
    "Vert.run": "Vert.run",
    "The #1 Trail & Ultra running App":
      "L'application n°1 pour les coureurs de Trail & Ultra-trail",
    "RUNNING COACHING FOR EVERYONE": "Ton coach personnel en course à pied",
    "Create Free Account": "Crée un compte gratuit",
    "Welcome to the future of Trail Running":
      "Bienvenue dans le futur du Trail Running",

    "Get Started App": "Commencer",
    "Already a member?": "Tu es déjà membre ?",
    "If you move...": "Tu es aussi un athlète!",
    "Free Video Workouts": "Entraînements en vidéo",
    "From quick 5-min":
      "Des sessions de 5 minutes rapides aux routines complètes pour renforcer le corps, la stabilité, la force, les chevilles... Développe ta condition physique et préviens les blessures grâce à nos vidéos d'entraînement à suivre.",
    "See our video workouts": "Voir nos vidéos d'entraînement",
    "What’s included:": "Ce qui est inclus:",
    "+ Coaching": "Coach",
    "+ 6 Months Coaching": "Coach por 6 meses",
    "Access 200+ training plans for Road, Trails and Ultramarathons":
      "Accès à plus de 200 plans d'entraînement pour la route, le trail et les ultras",
    "Receive weekly guidance from your coach":
      "Recevez des conseils hebdomadaires de votre coach",
    "Chat with your coach in your dashboard":
      "Discutez avec votre coach sur l'application",
    "Training that works around your life (Swap workouts, add easy days, add easy weeks, add customs workouts...)":
      "Un entraînement qui s'adapte à ta vie (échangez des séances, ajoutez des jours et de semaines faciles. etc.)",
    "Follow your progress: track your Mountain Index, training load, energy levels and more":
      "Suivez votre progression : avec votre Mountain Index,  charge d'entraînement, niveau d'énergie...",
    "Track your activities with Strava, Garmin,  Suunto or Apple watch":
      "Suivez vos activités avec Strava, Garmin, Suunto ou Apple Watch",
    "Export workouts to your Garmin": "Exporta workouts a tu Garmin",
    "GET A COACH": "JE M’INCRIS",
    "Everything from our Monthly Coaching subscription - with special 6-month pricing":
      "Les mêmes fonctionnalités que notre plan d'entraînement mensuel, avec un tarif spécial",
    "/per month": "/ par mois",
  },
};

//I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  leftColumn: {
    flex: 0.5,
    flexGrow: 1,
    paddingLeft: 52,
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.down("sm")]: {
      flex: 0,
      display: "none",
    },
  },
  white: {
    color: theme.palette.common.white,
  },
});

class AppAuthenticator extends Component {
  componentDidMount() {
    const { i18n } = this.props;
    if (i18n) {
      const { language } = i18n;
      if (language) I18n.setLanguage(language);
    }
  }

  errorMessageMap = (message) => {
    let inputMessage = message;
    if (/incorrect.*username.*password/i.test(message)) {
      inputMessage = I18n.get("Incorrect username or password");
    } else if (
      /property.*username.*undefined/i.test(message) ||
      /undefined.*object.*username/i.test(message)
    ) {
      inputMessage = I18n.get("These fields are required: Username, Password");
    } else if (
      /property.*password.*undefined/i.test(message) ||
      /undefined.*object.*password/i.test(message)
    ) {
      inputMessage = I18n.get("These fields are required: Username, Password");
    } else if (/user.*not.*exist/i.test(message)) {
      inputMessage = I18n.get("Invalid Username or Password");
    } else if (/password.*satisfy.*constraint/i.test(message)) {
      inputMessage = I18n.get(
        "It must contain 8 characters with a capital letter, a number and a special character",
      );
    } else if (/presignUp.*/i.test(message)) {
      inputMessage = I18n.get(message);
    } else if (
      /validation error detected.*/i.test(message) ||
      /Invalid verification code provided.*/i.test(message)
    ) {
      inputMessage = I18n.get(
        "Invalid verification code provided, please try again.",
      );
    }

    this.props.setGeneralError(inputMessage);

    return inputMessage;
  };

  handleHideMessage = () => {
    this.props.clearGeneralError();
  };

  handleStateChanged = (authState) => {
    if (authState === "signedIn") {
      const { auth } = this.props;
      const { currentUser } = auth || {};
      if (!currentUser)
        this.props.currentUserLoad(Auth.currentAuthenticatedUser(), true);
    }
  };

  render() {
    const {
      auth: { generalErrorMessage },
      classes,
      initialState,
    } = this.props;

    return (
      <div className={classes.container}>
        <Authenticator
          authState={initialState || "signUp"}
          onStateChange={this.handleStateChanged}
          errorMessage={this.errorMessageMap}
          hide={[SignUp, Greetings, SignIn, ForgotPassword]}
          theme={AuthTheme}
        >
          <CustomizedSignUp override={"SignUp"} />
          <CustomizedSignIn override={"SignIn"} />
          <CustomizedForgotPassword override={"ForgotPassword"} />
        </Authenticator>
        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={generalErrorMessage}
        />
      </div>
    );
  }
}

AppAuthenticator.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  initialState: PropTypes.string,
  t: PropTypes.func.isRequired,
  currentUserLoad: PropTypes.func.isRequired,
  setGeneralError: PropTypes.func.isRequired,
  clearGeneralError: PropTypes.func.isRequired,
};

export default ConnectAuth(
  withTranslation("layout")(
    withStyles(styles, { withTheme: true })(AppAuthenticator),
  ),
);
