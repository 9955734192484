import React, { memo, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import {
  Typography,
  Grid,
  CircularProgress,
  Button,
  Box,
} from "@material-ui/core";
import {
  convertUnits,
  round,
  getPace,
  getActivityCalculations,
  getMilesPace,
} from "../../lib/units-helper";
import unitsHook from "../../hooks/unitsHook";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DashboardActivityDetail from "./dashboard-activity-detail";
import history from "../../lib/history-helper";
import { getUserActivity } from "../../actions/user-plans-action";
import Reactions from "../generals/reactions";
import { DeleteActivity } from "../generals/delete-activity";

const styles = (theme) => ({
  main: {
    padding: "24px 56px",
  },
  bigCard: {
    border: "1px solid #E0E0E0",
    borderRadius: "5px",
    padding: "12px",
    boxSizing: "border-box",
    width: "85%",
    marginBottom: "28px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  date: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  detailsText: {
    color: "#E16327",
    fontSize: "16px",
    cursor: "pointer",
  },
  title: {
    color: "#E16327",
    fontSize: "20px",
    fontWeight: "bold",
  },
  description: {
    color: "#9E9E9E",
    fontSize: "16px",
  },
  contentParent: {
    display: "flex",
    marginTop: "19px",
  },
  leftSide: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: "1",
  },
  rightSide: {
    width: "50%",
    padding: "0px 0px 0px 20px",
    boxSizing: "border-box",
  },
  bigStat: {
    fontSize: "44px",
    fontWeight: "bold",
    lineHeight: "100%",
  },
  bigStatsParent: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  },
  bigStatTitle: {
    color: "#9E9E9E",
    fontSize: "14px",
  },
  grayCard: {
    backgroundColor: "#F9F9FB",
    padding: "16px",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  smallStat: {
    fontSize: "16px",
    fontWeight: 500,
  },
  smallStatTitle: {
    fontSize: "14px",
    color: "#9E9E9E",
  },
  bigStatRow: {
    display: "flex",
    alignItems: "flex-end",
  },
  seeDetailsParent: {
    display: "flex",
  },
  iconParent: {
    marginLeft: 6,
  },

  subtitle: {
    fontWeight: 500,
    fontSize: 18,
    marginTop: 40,
  },
  row: {
    marginBottom: "12px",

    "&:nth-last-child(1), &:nth-last-child(2)": {
      marginBottom: "0px",
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 0",
  },
  noActivitiesText: {
    marginBottom: "16px",
  },
  goToSettingsBtn: {
    borderRadius: 50,
  },
  deleteContainer: {
    marginTop: 12,
  },
});

const DashboardActivityList = ({
  classes,
  activities = [],
  isLoading = false,
  t,
}) => {
  const localUnits = unitsHook();
  const [current, setCurrent] = useState("");
  const [actualActivity, setActualActivity] = useState({});
  const dispatch = useDispatch();

  const { userPlans } = useSelector((state) => state);

  const sortActivities = (activitiesArray) => {
    const sorted = activitiesArray.sort((a, b) => {
      return moment(b.start_date).unix() - moment(a.start_date).unix();
    });

    return sorted || [];
  };

  const convertDistance = (distance) => {
    const convertedDistance = convertUnits(
      distance,
      "kms",
      localUnits,
      "distance",
    );
    return convertedDistance;
  };

  const goToSettings = () => history.push("/profile");

  const convertElevation = (elevation) => {
    const convertedElevation = round(
      convertUnits(elevation, "kms", localUnits, "elevation"),
      1,
    );
    return convertedElevation;
  };

  const clearCurrentActivity = () => {
    setCurrent("");
    setActualActivity(null);
  };

  const openActivity = (activity) => {
    const { currentPlan } = userPlans || {};

    setActualActivity(activity);
    dispatch(getUserActivity(activity, currentPlan));
    const { device: type } = activity;
    setCurrent(type);
  };

  const checkReactions = (activityDate) => {
    const { currentPlan } = userPlans || {};
    const { currentTraining } = currentPlan || {};
    const { days = [] } = currentTraining || {};

    const result = days.find((item) => {
      if (item) {
        const difference = moment(item[0].plannedDate, "DD/MM/YYYY").diff(
          activityDate,
          "days",
        );
        if (Math.floor(difference) === 0) return item;
      }
    });

    return result && result.length ? result[0].reaction : [];
  };

  return (
    <>
      {isLoading && (
        <div className={classes.loader}>
          <CircularProgress variant="indeterminate" size={36} />
        </div>
      )}

      {!current && (
        <div className={classes.main}>
          {activities && activities.length
            ? (sortActivities(activities) || []).map((activity, index) => {
                const name = activity.name
                  ? activity.name
                  : activity.activityName
                  ? activity.activityName
                  : "";
                const time = activity.moving_time
                  ? activity.moving_time
                  : activity.elapsed_time;

                const pace =
                  localUnits == "kms"
                    ? getPace(time, activity.distance)
                    : getMilesPace(time, activity.distance);

                const h = time ? Math.floor(time / 3600) : 0;
                const m = time ? Math.floor((time % 3600) / 60) : 0;
                const timeStr = `${h < 10 ? `0${h}` : h}:${
                  m < 10 ? `0${m}` : m
                }`;

                const date = moment(
                  activity.start_date_local
                    ? activity.start_date_local
                    : activity.start_date,
                );

                const calculations = activity.rawSamples
                  ? getActivityCalculations(
                      { rawSamples: activity.rawSamples },
                      localUnits,
                      activity.device,
                    )
                  : 0;

                let icon = null;
                const activityType = activity.type
                  ? activity.type
                  : activity.activityType;
                if (
                  activityType &&
                  activityType.toLowerCase().includes("run") &&
                  !activityType.toLowerCase().includes("transition")
                ) {
                  icon = "icon_activity_run";
                }
                if (
                  activityType &&
                  (activityType.toLowerCase().includes("ride") ||
                    activityType.toLowerCase().includes("bik") ||
                    activityType.toLowerCase().includes("cycl")) &&
                  !activityType.toLowerCase().includes("transition")
                ) {
                  icon = "icon_activity_bike";
                }
                if (
                  activityType &&
                  activityType.toLowerCase().includes("swim") &&
                  !activityType.toLowerCase().includes("transition")
                ) {
                  icon = "icon_activity_swim";
                }
                if (
                  activityType &&
                  (activityType.toLowerCase().includes("walk") ||
                    activityType.toLowerCase().includes("hik")) &&
                  !activityType.toLowerCase().includes("transition")
                ) {
                  icon = "icon_activity_hike";
                }

                if (
                  activityType &&
                  (activityType.toLowerCase().includes("crossfit") ||
                    activityType.toLowerCase().includes("weight") ||
                    activityType.toLowerCase().includes("yoga") ||
                    activityType.toLowerCase().includes("strength") ||
                    activityType.toLowerCase().includes("fitness")) &&
                  !activityType.toLowerCase().includes("transition")
                ) {
                  icon = "icon_activity_strength";
                }

                return (
                  <div key={index}>
                    <div className={classes.bigCard}>
                      <div className={classes.header}>
                        <Typography className={classes.date}>
                          {date.format("DD/MM/YYYY")}
                        </Typography>
                        <div
                          className={classes.seeDetailsParent}
                          onClick={() => openActivity(activity, current)}
                        >
                          <Typography className={classes.detailsText}>
                            {t("See details")}
                          </Typography>
                          <div className={classes.iconParent}>
                            <img
                              src={`${process.env.PUBLIC_URL}/${icon}.svg`}
                              alt=""
                              className={classes.activityIcon}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={classes.contentParent}>
                        <div className={classes.leftSide}>
                          <div>
                            <Typography className={classes.title}>
                              {name}
                            </Typography>
                            <Typography className={classes.description}>
                              {activity.description}
                            </Typography>
                          </div>

                          <div className={classes.bigStatsParent}>
                            <div>
                              <div className={classes.bigStatRow}>
                                <Typography className={classes.bigStat}>
                                  {round(
                                    convertDistance(activity.distance) / 1000,
                                    1,
                                  )}
                                </Typography>

                                <Typography style={{ fontSize: "14px" }}>
                                  {" "}
                                  {`${
                                    localUnits === "miles"
                                      ? `${t("miles")}`
                                      : "km"
                                  }`}{" "}
                                </Typography>
                              </div>
                              <Typography className={classes.bigStatTitle}>
                                {t("distance")}
                              </Typography>
                            </div>

                            <div>
                              <div className={classes.bigStatRow}>
                                <Typography className={classes.bigStat}>
                                  {convertElevation(
                                    activity.total_elevation_gain,
                                  )}
                                </Typography>
                                <Typography style={{ fontSize: "14px" }}>{`${
                                  localUnits === "miles" ? ` ${t("feet")}` : "m"
                                }`}</Typography>
                              </div>
                              <Typography className={classes.bigStatTitle}>
                                {t("elevation")}
                              </Typography>
                            </div>
                          </div>
                        </div>

                        <div className={classes.rightSide}>
                          <div className={classes.grayCard}>
                            <Grid container>
                              <Grid item md={6} className={classes.row}>
                                <Typography className={classes.smallStat}>
                                  {timeStr}
                                </Typography>
                                <Typography className={classes.smallStatTitle}>
                                  {t("time")}
                                </Typography>
                              </Grid>
                              <Grid item md={6} className={classes.row}>
                                <Typography className={classes.smallStat}>
                                  {`${pace.minutes}:${(pace.seconds < 10
                                    ? "0"
                                    : "") + pace.seconds}`}{" "}
                                  {localUnits === "miles"
                                    ? `${t("miles")}/h`
                                    : `${t("km")}/h`}
                                </Typography>
                                <Typography className={classes.smallStatTitle}>
                                  {t("avg pace")}
                                </Typography>
                              </Grid>
                              <Grid item md={6} className={classes.row}>
                                <Typography className={classes.smallStat}>
                                  {activity.averageHeartRateInBeatsPerMinute ||
                                    0}{" "}
                                  bpm
                                </Typography>
                                <Typography className={classes.smallStatTitle}>
                                  {t("avg heart rate")}
                                </Typography>
                              </Grid>
                              <Grid item md={6} className={classes.row}>
                                <Typography className={classes.smallStat}>
                                  {activity.activeKilocalories || 0}
                                </Typography>
                                <Typography className={classes.smallStatTitle}>
                                  {t("calories")}
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>

                      {activity.device === "vert.run" && (
                        <Box className={classes.deleteContainer}>
                          <DeleteActivity activity={activity} />
                        </Box>
                      )}
                      <Reactions reactions={checkReactions(date)} />
                    </div>
                  </div>
                );
              })
            : !isLoading && (
                <div>
                  <Typography className={classes.noActivitiesText}>
                    {t("No activities")}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={goToSettings}
                    className={classes.goToSettingsBtn}
                  >
                    {t("Go to settings")}
                  </Button>
                </div>
              )}
        </div>
      )}
      {current && (
        <DashboardActivityDetail
          activity={actualActivity}
          isOpen={current}
          clearActivity={clearCurrentActivity}
        />
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("dashboard")(memo(DashboardActivityList)),
);
