import React, { Component, memo } from "react";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";
import ClassNames from "classnames";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  rootForm: {
    backgroundColor: "#FFF",
  },
  formControl: {
    margin: theme.spacing.unit,
    backgroundColor: "#FFF",
  },
  xsmallWidth: {
    minWidth: 60,
  },
  smallWidth: {
    minWidth: 120,
  },
  largeWidth: {
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  select: {
    backgroundColor: "#FFF",
    border: "solid 1px #C4C4C4",
    borderRadius: "5px",
    padding: "16px",

    "&:focus": {
      borderRadius: "5px",
      backgroundColor: "#FFF",
    },
  },

  noPaddingselect: {
    backgroundColor: "#FFF",
    border: "solid 1px #C4C4C4",
    borderRadius: "5px",

    "&:focus": {
      borderRadius: "5px",
      backgroundColor: "#FFF",
    },
  },
});

class FilterSelect extends Component {
  handleChange = (event) => {
    const { onSelect } = this.props;

    if (onSelect && event) {
      const { target } = event;
      if (target) {
        const { name, value } = target;
        if (name) {
          onSelect({ name, value });
        }
      }
    }
  };

  render() {
    const {
      classes,
      name,
      text,
      items,
      value,
      required,
      disabled,
      large,
      small,
      xsmall,
      autoFocus,
      noMargin,
      width,
      customStyles = null,
      customClass,
    } = this.props;

    return (
      <FormControl
        className={ClassNames(
          customStyles ? classes.formControl : null,
          { [classes.xsmallWidth]: xsmall },
          { [classes.smallWidth]: small },
          { [classes.largeWidth]: large },
          customStyles && { root: customStyles ? classes.rootForm : null },
        )}
        variant={customStyles ? "filled" : ""}
        required={required || false}
        disabled={disabled}
        style={{ minWidth: width || 140, margin: !noMargin && 8 }}
      >
        <InputLabel htmlFor={name}>{text}</InputLabel>
        <Select
          autoFocus={autoFocus}
          required={required || false}
          value={value || ""}
          onChange={this.handleChange}
          disableUnderline={customStyles ? true : false}
          inputProps={{
            name: name,
            id: name,
          }}
          MenuProps={{ disablePortal: true }}
          classes={
            customStyles && { root: ClassNames(classes.select, customClass) }
          }
        >
          {items.map((item, index) => {
            const { value, text } = item;
            return (
              <MenuItem key={index} value={value}>
                {text}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}

FilterSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  xsmall: PropTypes.bool,
  customStyles: PropTypes.bool,
};

FilterSelect.defaultProps = {
  small: false,
  xsmall: false,
  autoFocus: false,
};

export default withStyles(styles)(memo(FilterSelect));
