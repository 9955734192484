import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import SubCategory from "./subcategories/subcategory";
import ConnectSubcategories from "../../connectors/connect-subcategories";
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
import subcategory from "./subcategories/subcategory";
import { Copyright } from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { logEvent } from "@amplitude/analytics-browser";
import { toggleOpenMenu } from "../../actions/user-plans-action";

const styles = (theme) => ({
  item: {
    paddingBottom: "0 !important",
    position: "relative",
  },
  container: {
    "&::-webkit-scrollbar ": {
      display: "none",
    },
  },

  favContainer: {
    background: "#FFFFFF",
    borderRadius: 24,
    position: "absolute",
    top: 15,
    right: 15,
    padding: "2px 10px",
    zIndex: 10,
  },
});
const SubCategories = (props) => {
  const {
    subCategories,
    getSubcategories,
    filter,
    classes,
    subgroup,
    onOpenAuth,
    max = null,
    landing = false,
    explore = false,
    onToggleFavorite,
    exploreFavorites,
    favorites = false,
    hideFav = false,
    width,
    userType = 0,
    currentDetails,
    filterLoading,
    t,
    userPlans,
    morePlans,
    noShowPlansFilter,
    currentVariant,
    filtersApplied,
    reserveMySpot,
  } = props;

  const { currentPlan } = userPlans || {};

  const { userType: typeUser, currentTraining } = currentPlan || {};

  useEffect(() => {
    if (getSubcategories) getSubcategories({ readOnlyAvailables: true });
  }, []);

  const { loading, data } = subCategories;

  let filteredData = [...data];
  const backupData = [...data];

  if (filter) {
    if (!filter.tags.includes("All")) {
      filteredData = backupData.filter((subcategory) => {
        if (subcategory.tags && subcategory["tags"].includes(filter["tags"]))
          return subcategory;
      });
    } else {
      filteredData = backupData;
    }

    if (filter.training_surfaces.length) {
      filteredData = filteredData.filter((subcategory) => {
        const intersection = filter.training_surfaces.filter(
          (element) =>
            subcategory.training_surfaces &&
            subcategory["training_surfaces"].includes(element),
        );
        if (intersection.length) return subcategory;
      });
    }

    if (filter.distance_range.min > 0 || filter.distance_range.max > 0) {
      filteredData = filteredData.filter((subcategory) => {
        if (subcategory["distance_range"]) {
          const { min, max } = subcategory["distance_range"];
          if (
            !(
              filter["distance_range"].min < min &&
              filter["distance_range"].max < min
            ) &&
            !(
              filter["distance_range"].min > max &&
              filter["distance_range"].max > max
            )
          )
            return subcategory;
        }
      });
    }
  }

  filteredData.sort(function(a, b) {
    return (
      (userType === 1 && a.display_order_bur
        ? a.display_order_bur
        : userType === 3 && a.display_order_btr
        ? a.display_order_btr
        : a.display_order) -
      (userType === 1 && b.display_order_bur
        ? b.display_order_bur
        : userType === 3 && b.display_order_btr
        ? b.display_order_btr
        : b.display_order)
    );
  });

  if (subgroup) {
    filteredData = subgroup.map((s) => data.find((p) => p.id === +s));
  }
  filteredData = filteredData.filter((s) => s !== undefined);

  if (max) {
    filteredData = filteredData.slice(0, max);
  }

  if (!landing && favorites) {
    filteredData =
      exploreFavorites && exploreFavorites.length
        ? filteredData.filter((p) =>
            exploreFavorites.find((e) => e.id === p.id),
          )
        : [];
  }

  const preSignUpFilter = {
    "1": "display_order_first_ultra",
    "2": "display_order_better_trail",
    "3": "display_order_start_trail",
  };

  const isInMobileViewSm = width === "sm" || width === "xs";
  const hasPlan =
    typeof currentTraining !== "string" &&
    Object.keys(currentTraining || {}).length;

  if (
    data &&
    !!preSignUpFilter[typeUser] &&
    !morePlans &&
    isInMobileViewSm &&
    !noShowPlansFilter &&
    currentVariant == "B" &&
    !filtersApplied &&
    !hasPlan
  )
    filteredData = data.filter(
      (item) => item && item[preSignUpFilter[typeUser]],
    );

  return (
    <Grid
      container
      spacing={40}
      justify="center"
      id="subcategories"
      style={{
        display: landing || explore ? "block" : "flex",
        overflowX: landing || explore ? "scroll" : "initial",
        overflowY: landing || explore ? "hidden" : "initial",
        whiteSpace: landing || explore ? "nowrap" : "initial",

        margin: (landing || explore) && (loading || !data) ? "auto" : "initial",
        textAlign:
          (landing || explore) && (loading || !data) ? "center" : "initial",
      }}
      className={classes.container}
    >
      {loading || !data ? (
        <CircularProgress
          variant="indeterminate"
          color="primary"
          data-testid="LoadingUser"
        />
      ) : data.length === 0 ? (
        <Typography variant="subtitle2" className={classes.fullPlans}>
          {"No plans matched your selection"}
        </Typography>
      ) : (
        filteredData.map((category, index) => {
          return (
            <Grid
              item
              key={category.id}
              xs={12}
              sm={6}
              md={subgroup ? 12 : 6}
              lg={subgroup ? 12 : 4}
              className={classes.item}
              style={{
                display: landing || explore ? "inline-block" : "block",
                minWidth: landing
                  ? 435
                  : explore
                  ? isInMobileViewSm
                    ? "90%"
                    : "30%"
                  : "initial",
                maxWidth: explore
                  ? isInMobileViewSm
                    ? "90%"
                    : "30%"
                  : "initial",
                verticalAlign: landing || explore ? "top" : "initial",
                marginLeft:
                  explore && !isInMobileViewSm && index === 0 ? 50 : 0,
              }}
            >
              <SubCategory
                category={category}
                onOpenAuth={onOpenAuth}
                landing={landing}
                explore={explore}
                openDetails={category.id === currentDetails}
                currentVariant={currentVariant}
                reserveMySpot={(event) => reserveMySpot(event, category)}
              />
              {!hideFav ? (
                <div className={classes.favContainer}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-star-${
                      exploreFavorites &&
                      exploreFavorites.length &&
                      exploreFavorites.find((e) => e.id === category.id)
                        ? "filled"
                        : "outline"
                    }.svg`}
                    alt=""
                    onClick={(ev) =>
                      onToggleFavorite(ev, category.id, "Training plan")
                    }
                    className={classes.fav}
                  />
                </div>
              ) : (
                ""
              )}
            </Grid>
          );
        })
      )}

      {!filteredData.length && !loading && !filterLoading && (
        <Typography style={{ marginTop: 20, fontSize: 20, color: "#7E7E7E" }}>
          {t("No training plans found")}
        </Typography>
      )}
    </Grid>
  );
};

SubCategories.propTypes = {
  getSubcategories: PropTypes.func.isRequired,
  clearSubcategories: PropTypes.func.isRequired,
  subCategories: PropTypes.shape({
    data: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
  filter: PropTypes.string,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withWidth()(
    withTranslation("plan")(
      withStyles(styles, { withTheme: true })(
        ConnectSubcategories(React.memo(SubCategories)),
      ),
    ),
  ),
);
