import React from "react";

const Logo = ({ width = 88, style = {} }) => {
  return (
    <img
      style={style}
      src={`${process.env.PUBLIC_URL}/vertrun-logo-wide.png`}
      alt="logo"
      width={width}
    />
  );
};

export default Logo;
