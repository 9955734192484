import React from "react";
import { ListItem } from "@material-ui/core";
import { Skeleton } from "@mui/material";

const CheckoutPriceCardButtonSkeleton = () => {
  return (
    <ListItem style={{ width: "100%" }}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          minHeight: "280px",
          margin: "0px",
          padding: "0px",
        }}
      />
    </ListItem>
  );
};

export default CheckoutPriceCardButtonSkeleton;
