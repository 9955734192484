import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PlanQuestionnaireButtons from "./plan-questionnaire/plan-questionnaire-buttons";
import SnackBarMessageDisplay from "../common/snack-bar-message-display";
import history from "../../lib/history-helper";
import ConnectAuth from "../../connectors/connect-auth";
import ConnectUserPlans from "../../connectors/connect-user-plans";
import ConnectSubscriptions from "../../connectors/connect-subscriptions";
import ConnectSubcategories from "../../connectors/connect-subcategories";
import PlanSuggesterQuestions from "./plan-questionnaire/plan-suggester-questions";
import { Routes } from "../../lib/routing-middleware";
import { logEvent } from "../../lib/events-helper";

const styles = (theme) => ({
  container: {
    // backgroundImage: `url(${process.env.PUBLIC_URL}/choose-plan-xl.png)`,
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  form: {
    minHeight: 700,
    [theme.breakpoints.down("md")]: {
      minHeight: 600,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 500,
    },
  },
});

class PlanSuggester extends Component {
  timeout = null;
  state = {
    fields: [],
  };

  handleFieldsInit = (fields) => {
    if (fields.length !== this.state.fields.length) {
      this.setState({
        ...this.state,
        fields,
      });
    }
  };

  handleSelectFieldChange = (item) => {
    const { id, value } = item;
    if (id) {
      this.props.planChangeField({ name: id, value: value });
      this.timeout = setTimeout(() => {
        this.handleNextRequested();
      }, 1000);
    }
  };

  handleButtonSelectFieldChange = (item) => {
    const { id, value } = item;
    if (id) {
      if (id === "currentGoal" && value === 5) {
        history.push("/chooseplan?filter=free");
      } else if (id === "init" && value === 1) {
        try {
          localStorage.setItem("challengesUser", true);
        } catch (e) {
          console.log(e);
        }

        history.push("/challenges");
      } else {
        this.props.planChangeField({ name: id, value: value });
        this.timeout = setTimeout(() => {
          this.handleNextRequested();
        }, 1000);
      }
    }
  };

  getDisplayErrorMessage = (err) => {
    let errorMessage = "";
    const { t } = this.props;

    if (typeof err === typeof string) {
      errorMessage = err;
    } else errorMessage = err.message || err.inputMessage || err.toString();

    switch (errorMessage) {
      case "Invalid Size":
        return t("Sorry, your image needs to be smaller than maxSize");
      case "Invalid File":
        return t("Invalid file, please try again");
      default:
        return errorMessage;
    }
  };

  handleFieldError = (err) => {
    this.props.setErrorMessage(this.getDisplayErrorMessage(err)); //GOES TO SNACK BAR
  };

  handleHideMessage = () => {
    this.props.clearErrorMessage();
  };

  goToResults = (event) => {
    history.push(Routes.SuggesterResults);
  };

  handleBackRequested = () => {
    const { userPlans } = this.props;
    if (userPlans) {
      const { currentStep } = userPlans;
      if (currentStep > 0) {
        this.props.planChangeCurrentStep(currentStep - 1);
      } else {
        history.goBack();
      }
    }
  };

  handleNextRequested = () => {
    if (this.timeout) clearTimeout(this.timeout);
    const { userPlans } = this.props;

    if (userPlans) {
      const { currentStep, currentPlan } = userPlans;
      const { userType } = currentPlan || {};
      if (
        currentStep === 2 ||
        (currentStep === 1 && userType === 1) ||
        (currentStep === 0 && userType === 3)
      ) {
        this.goToResults();
      } else {
        this.props.planChangeCurrentStep(currentStep + 1);
      }
    }
  };

  componentDidMount() {
    window.scroll(0, 0);
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);

    this.props.planChangeCurrentStep(0);
  }

  render() {
    const { classes, userPlans } = this.props;
    const { fields } = this.state;
    const { currentStep, errorMessage, saving, currentPlan } = userPlans;
    const { userType } = currentPlan || {};

    const formId = "suggesterForm";

    const totalSteps = this.state.fields.length - 1;
    return (
      <div className={classes.container}>
        {saving && <LinearProgress variant="indeterminate" color="primary" />}
        <form id={formId} onSubmit={this.goToResults} className={classes.form}>
          <PlanSuggesterQuestions
            {...this.props}
            currentStep={currentStep}
            onSelectFieldChange={this.handleSelectFieldChange}
            onButtonSelectFieldChange={this.handleButtonSelectFieldChange}
            onFieldError={this.handleFieldError}
            onFieldsInit={this.handleFieldsInit}
            onBackRequested={this.handleBackRequested}
            userType={userType}
          />
          <PlanQuestionnaireButtons
            form={formId}
            totalSteps={totalSteps}
            currentStep={currentStep}
            saving={saving}
            onNextRequested={this.handleNextRequested}
            onBackRequested={this.handleBackRequested}
            fields={fields}
            isSuggester={true}
          />
        </form>
        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
        />
      </div>
    );
  }
}

PlanSuggester.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  planChangeField: PropTypes.func.isRequired,
  clearErrorMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  planChangeCurrentStep: PropTypes.func.isRequired,
  userPlans: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  subscriptions: PropTypes.object.isRequired,
  subCategories: PropTypes.object.isRequired,
};

export default withRouter(
  ConnectAuth(
    ConnectSubscriptions(
      ConnectSubcategories(
        ConnectUserPlans(
          withTranslation("messages")(
            withStyles(styles, { withTheme: true })(PlanSuggester),
          ),
        ),
      ),
    ),
  ),
);
