import gsap from "gsap";
import {
  Box,
  Card,
  CardActions,
  CardHeader,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Browser } from "@capacitor/browser";
import React from "react";
import ToggleOptions from "./ToggleOptions";
import { palette } from "../../../theme/palette";
import { Emoji } from "emoji-picker-react";
import { TypeMessage } from "./Chat";
import WorkoutCard from "./WorkoutCard";
import { convertMinutesToTimeLabel } from "../../../constants/estimated-times";
import useUserInfo from "../../../hooks/useUserInfo";
import { WorkoutTypesLangKeys } from "../../../constants/workout-types";
import { useTranslation } from "react-i18next";
import useChat from "../../../hooks/useChat";
import { useHistory } from "react-router-dom";
import Linkify from "linkify-react";
import { Capacitor } from "@capacitor/core";

const ChatMessageContent = ({
  propStyles = {},
  onClick,
  customClass = null,
  options = [],
  text = "",
  date = null,
  reactions = [],
  replyTo = null,
  workout = null,
}) => {
  const classes = useStyles();
  const { level } = useUserInfo();
  const { t } = useTranslation("chat");
  const { getPrettyTextMessage } = useChat();
  const history = useHistory();
  const workoutClean = workout
    ? workout.length > 0
      ? workout[0]
      : workout
    : null;

  const scrollToMessage = (messageId) => {
    const element = document.getElementById(`message-${messageId}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      gsap.fromTo(
        element,
        { opacity: 0, backgroundColor: palette.overlayBlue, x: -10 },
        {
          opacity: 1,
          backgroundColor: palette.overlayBlue,
          x: 0,
          duration: 1,
          clearProps: "all",
        },
      );
    }
  };

  const handleLinkClick = (event, href) => {
    event.preventDefault();
    const internalOrigins = [
      "app.vert.run",
      "develop.vert.run",
      window.location.origin,
    ];

    const isInternalLink = internalOrigins.some(
      (origin) =>
        href.startsWith(`http://${origin}`) ||
        href.startsWith(`https://${origin}`) ||
        href.startsWith(origin),
    );

    if (isInternalLink) {
      const relativePath = href.replace(/^https?:\/\/[^\/]+/, "");
      history.push(relativePath);
    } else {
      if (Capacitor.isNativePlatform()) {
        const platform = Capacitor.getPlatform();
        if (platform === "ios") {
          Browser.open({ url: href, presentationStyle: "popover" });
        } else if (platform === "android") {
          Browser.open({ url: href, toolbarColor: "#000000" });
        }
      } else {
        window.open(href, "_blank");
      }
    }
  };

  const linkifyOptions = {
    formatHref: (href, type) => {
      if (type === "url") {
        const internalOrigins = [
          "app.vert.run",
          "develop.vert.run",
          window.location.origin,
        ];
        const isInternalLink = internalOrigins.some(
          (origin) =>
            href.startsWith(`http://${origin}`) ||
            href.startsWith(`https://${origin}`) ||
            href.startsWith(origin),
        );
        if (isInternalLink) {
          const formattedHref = href.replace(
            /^https?:\/\/(app.vert.run|develop.vert.run)/,
            window.location.origin,
          );
          return formattedHref;
        }
      }
      return href;
    },
    render: ({ attributes, content }) => {
      return (
        <Typography
          component="a"
          {...attributes}
          onClick={(event) => handleLinkClick(event, attributes.href)}
          style={{ color: palette.primary, wordBreak: "break-word" }}
        >
          {content}
        </Typography>
      );
    },
  };

  return (
    <Card
      className={`${classes.message} ${customClass}`}
      style={propStyles}
      onClick={onClick}
    >
      {replyTo && (
        <Card
          onClick={() => scrollToMessage(replyTo.id)}
          className={`${classes.message} ${
            TypeMessage[replyTo.type] === "COACH"
              ? classes.received
              : classes.sender
          }`}
        >
          <CardHeader
            classes={{
              root: classes.messageCardHeader,
              action: classes.messageCardHeaderAction,
              title: classes.messageCardHeaderTitle,
            }}
            title={getPrettyTextMessage(replyTo)}
          />
        </Card>
      )}
      <CardHeader
        classes={{
          root: classes.messageCardHeader,
          action: classes.messageCardHeaderAction,
          title: classes.messageCardHeaderTitle,
        }}
        action={
          <>
            {options && options.length > 0 && (
              <ToggleOptions
                iconWidth={24}
                iconHeight={24}
                iconButtonSize="small"
                actions={options}
              />
            )}
          </>
        }
        title={
          <Typography component="div" style={{ wordBreak: "break-word" }}>
            <Linkify options={linkifyOptions}>{text}</Linkify>
          </Typography>
        }
      />
      {workoutClean && (
        <WorkoutCard
          variant={workoutClean ? workoutClean.workoutType : "NoWorkout"}
          primaryText={
            workoutClean.workoutType
              ? workoutClean.workoutType === "UserCreated"
                ? workoutClean.userCreatedType
                : t(WorkoutTypesLangKeys[workoutClean.workoutType], {
                    ns: "training",
                  })
              : ""
          }
          secondaryText={
            workoutClean.estimatedTime
              ? convertMinutesToTimeLabel(
                  !isNaN(+workoutClean.estimatedTime[level])
                    ? +workoutClean.estimatedTime[level]
                    : +workoutClean.estimatedTime,
                )
              : ""
          }
          isDone={workoutClean.isDone}
          withCheck
          margin="0"
          paperStylesProp={{ width: 205 }}
        />
      )}
      {date && (
        <CardActions style={{ padding: 0 }}>
          <Typography className={classes.messageDate}>{date}</Typography>
        </CardActions>
      )}
      {(reactions && reactions.length) > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "flex-row",
            gap: 5,
            position: "absolute",
            bottom: -15,
          }}
        >
          {reactions.map((reaction, index) => (
            <Paper
              elevation={0}
              key={index}
              style={{
                background: palette.white,
                borderRadius: 8,
                padding: "4px 8px",
                width: "fit-content",
                display: "flex",
              }}
            >
              <Emoji unified={reaction} size={16} />
            </Paper>
          ))}
        </Box>
      )}
    </Card>
  );
};

const useStyles = makeStyles({
  contentView: {
    flex: "1 1 auto",
    overflowY: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    //position: "fixed",
    backgroundColor: palette.lightGrayOpacity,
    //zIndex: 200,
  },
  contentSender: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: 10,
    gap: 5,
  },
  contentReceived: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    gap: 5,
    position: "relative",
  },
  messageCardHeader: {
    padding: 0,
  },
  messageCardHeaderAction: {
    margin: 0,
  },
  messageCardHeaderTitle: {
    fontWeight: 400,
  },
  message: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    border: "2px 0px 0px 0px",
    maxWidth: 260,
    padding: "8px 12px",
    color: palette.black,
    boxShadow: "none",
    wordBreak: "break-word",
  },
  messageDate: {
    fontWeight: 400,
    fontSize: 14,
    color: palette.deactivated,
  },
  received: {
    backgroundColor: palette.lightPink,
    borderColor: palette.primary,
    borderRadius: "0px 8px 8px 8px",
  },
  sender: {
    backgroundColor: palette.lightBlueHove,
    borderColor: palette.lightBlueHover,
    borderRadius: "8px 0px 8px 8px",
  },
  box: {
    display: "flex",
    padding: "24px 0px",
    boxSizing: "border-box",
    width: "100%",
  },
});

export default ChatMessageContent;
