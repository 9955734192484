import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";

class TrainingGridSwapConfirm extends Component {
  handleConfirm = () => {
    const { onConfirm, match } = this.props;
    const { params = {} } = match;
    const { id, training_order1, training_order2 } = params;
    if (onConfirm) onConfirm(id, training_order1, training_order2);
  };

  render() {
    const { onCancel, saving } = this.props;
    return (
      <div>
        <Dialog
          open={true}
          onClose={onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Swap Confirmation"}
          </DialogTitle>
          <LinearProgress
            color="primary"
            variant={saving ? "indeterminate" : "determinate"}
            value={0}
          />
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to swap selected trainings?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleConfirm} color="primary">
              Swap
            </Button>
            <Button onClick={onCancel} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default TrainingGridSwapConfirm;
