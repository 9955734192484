import React from "react";
import { SvgIcon } from "@material-ui/core";
import { palette } from "../../theme/palette";

const DeleteIcon = ({ width = 24, height = 24, activated = false }) => (
  <>
    <SvgIcon>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill={activated ? palette.primary : "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.25 3C5.25 2.60218 5.40804 2.22064 5.68934 1.93934C5.97064 1.65804 6.35218 1.5 6.75 1.5H11.25C11.6478 1.5 12.0294 1.65804 12.3107 1.93934C12.592 2.22064 12.75 2.60218 12.75 3V4.5H15.75C15.9489 4.5 16.1397 4.57902 16.2803 4.71967C16.421 4.86032 16.5 5.05109 16.5 5.25C16.5 5.44891 16.421 5.63968 16.2803 5.78033C16.1397 5.92098 15.9489 6 15.75 6H14.9482L14.298 15.1065C14.2711 15.4849 14.1017 15.8391 13.8241 16.0977C13.5465 16.3563 13.1811 16.5 12.8018 16.5H5.1975C4.8181 16.5 4.4528 16.3563 4.17516 16.0977C3.89753 15.8391 3.72819 15.4849 3.70125 15.1065L3.0525 6H2.25C2.05109 6 1.86032 5.92098 1.71967 5.78033C1.57902 5.63968 1.5 5.44891 1.5 5.25C1.5 5.05109 1.57902 4.86032 1.71967 4.71967C1.86032 4.57902 2.05109 4.5 2.25 4.5H5.25V3ZM6.75 4.5H11.25V3H6.75V4.5ZM4.5555 6L5.19825 15H12.8025L13.4452 6H4.5555ZM7.5 7.5C7.69891 7.5 7.88968 7.57902 8.03033 7.71967C8.17098 7.86032 8.25 8.05109 8.25 8.25V12.75C8.25 12.9489 8.17098 13.1397 8.03033 13.2803C7.88968 13.421 7.69891 13.5 7.5 13.5C7.30109 13.5 7.11032 13.421 6.96967 13.2803C6.82902 13.1397 6.75 12.9489 6.75 12.75V8.25C6.75 8.05109 6.82902 7.86032 6.96967 7.71967C7.11032 7.57902 7.30109 7.5 7.5 7.5ZM10.5 7.5C10.6989 7.5 10.8897 7.57902 11.0303 7.71967C11.171 7.86032 11.25 8.05109 11.25 8.25V12.75C11.25 12.9489 11.171 13.1397 11.0303 13.2803C10.8897 13.421 10.6989 13.5 10.5 13.5C10.3011 13.5 10.1103 13.421 9.96967 13.2803C9.82902 13.1397 9.75 12.9489 9.75 12.75V8.25C9.75 8.05109 9.82902 7.86032 9.96967 7.71967C10.1103 7.57902 10.3011 7.5 10.5 7.5Z"
          fill={activated ? palette.primary : palette.darkGray}
        />
      </svg>
    </SvgIcon>
  </>
);
export default DeleteIcon;
