import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { setPlanToSelect } from "../actions/user-plans-action";
import SubCategories from "./choose-plan/subcategories";
import { connect } from "react-redux";
import SuggesterMatrix from "../constants/suggester-matrix";
import { getSubcategories } from "../actions/subcategory-action";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import RouterLink from "./common/router-link";
import withWidth from "@material-ui/core/withWidth";
import { storageFactory } from "../lib/storage-factory";

const styles = (theme) => ({
  backIcon: {
    cursor: "pointer",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: 750,

    [theme.breakpoints.down("md")]: {
      minHeight: 600,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minHeight: 500,
    },
  },

  subContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 600,
      margin: "auto",
    },
  },
  titleContainer: {
    width: "60%",
    paddingBottom: 20,
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  title: {
    fontWeight: 500,
  },
  subTitle: {
    fontWeight: "normal",
    fontSize: 16,
    margin: "5px 10px",
    color: "#9E9E9E",
    lineHeight: "25px",
    textAlign: "center",
    fontFamily: "Montserrat",
  },
  color: {
    color: "#FF6327",
    fontWeight: "bold",
  },
  name: {
    fontSize: 24,
    fontWeight: 700,
    margin: "0 10px",
  },
  description: {
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 2,
    color: "#9E9E9E",
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing.unit * 8,
    paddingRight: theme.spacing.unit * 8,
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 5,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
  },
  contentTitleContainer: {},
  white: {
    color: theme.palette.common.white,
  },
  premiumLink: {
    marginTop: 10,
    width: "100%",
    color: "#000000",
    fontSize: "0.875rem",
    [theme.breakpoints.up("md")]: {
      top: 5,
      left: "50%",
      transform: "translate(-50%, 0)",
      position: "absolute",
      width: "initial",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  freeButtonRoot: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    fontSize: "0.975rem",
    fontWeight: 600,
    paddingTop: theme.spacing.unit * 1.2,
    paddingBottom: theme.spacing.unit * 1.2,
  },
  freeButtonlabel: {
    textAlign: "center",
  },
  filter: {
    fontSize: 28,
    color: "#000000",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  filterSelected: {
    fontSize: 28,
    color: "#FF6327",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  filterItem: {
    paddingBottom: "0 !important",
  },
  fullPlans: {
    textAlign: "center",
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#FF6327",
    textDecoration: "underline",
  },

  pickContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  pickTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 16,
    textTransform: "uppercase",

    color: "#FF5722",
  },

  backIconDesktop: {
    top: 10,
    left: 5,
    position: "absolute",
    cursor: "pointer",
    zIndex: 999,
  },

  buttonContainer: {
    width: "100%",
    textAlign: "center",
  },

  button: {
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    margin: "30px auto 0",
  },

  buttonIcon: {
    marginLeft: 5,
    marginTop: -2,
  },
});

class SuggesterResults extends PureComponent {
  componentDidMount() {
    const { subcategories } = this.props;
    const { data } = subcategories || {};
    if (!data || data.length === 0) this.props.dispatch(getSubcategories());
  }

  handleChooseFilter = (filter) => {
    this.setState({
      ...this.state,
      currentFilter: filter,
    });
  };

  handleChoosePlan = (event) => {
    if (event) {
      const { currentTarget } = event;
      if (currentTarget) {
        const { id } = currentTarget;
        if (id) {
          const { subscriptions } = this.props;
          const { latestExpired, latest } = subscriptions;
          this.props.dispatch(setPlanToSelect({ plan: id }));
          let nextPath = `/subscription/${id}`;
          if (latestExpired === false && latest) {
            const { plan_id } = latest;
            if (plan_id && plan_id.includes("premium")) {
              nextPath = `/chooseplan/${id}`;
            }
          }
          setTimeout(() => {
            this.props.history.push(nextPath);
          });
        }
      }
    }
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  goToDashboard = () => {
    storageFactory().removeItem("currentGoal");
    this.props.history.push("/dashboard");
  };

  render() {
    const {
      classes,
      t,
      userPlans,
      auth,
      onOpenAuth,
      isAuthenticated = true,
      max,
      width,
    } = this.props;
    const { planTempData, currentPlan } = userPlans || "";
    const { currentGoal, currentlyTraining, longestRun } = planTempData || "";
    const { userType } = currentPlan || {};
    let subgroup =
      userType === 3
        ? SuggesterMatrix.itemsArray.find((i) => i.key === `btr`)
        : SuggesterMatrix.itemsArray.find(
            (i) =>
              i.key === `${currentGoal}-${currentlyTraining}-${longestRun}`,
          );
    if (!subgroup) {
      subgroup = SuggesterMatrix.itemsArray[0];
    }

    const { currentUser } = auth || {};
    const { attributes } = currentUser || {};
    const { name } = attributes || {};

    const isInMobileViewSm = width === "sm" || width === "xs";

    return (
      <div className={classes.container}>
        <div className={classes.content}>
          {isAuthenticated ? (
            isInMobileViewSm ? (
              <ArrowBackIosIcon
                onClick={() => this.handleBack()}
                className={classes.backIcon}
                fontSize="small"
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/icon-arrow-back-black.svg`}
                alt=""
                className={classes.backIconDesktop}
                onClick={() => this.handleBack()}
              />
            )
          ) : (
            ""
          )}
          <div className={classes.contentTitleContainer}>
            {name ? (
              <Typography variant="h3" align="center" className={classes.name}>
                {name},
              </Typography>
            ) : (
              <>
                <Typography
                  variant="h3"
                  align="center"
                  className={classes.name}
                >
                  {t("Awesome")},
                </Typography>
                <br></br>
              </>
            )}

            <Typography
              variant="h3"
              align="center"
              className={classes.subTitle}
            >
              {name
                ? t("Suggester title") + " "
                : t("Suggester title no name") + " "}
              <span className={classes.color}>
                {t(
                  userType === 3
                    ? "become a trail runner"
                    : userType === 1
                    ? "become an ultra runner"
                    : currentGoal === 0
                    ? "run your first ultramarathon"
                    : currentGoal === 2
                    ? "start trail running"
                    : currentGoal === 1
                    ? "get back in trail shape"
                    : currentGoal === 3
                    ? "perform better in races"
                    : currentGoal === 4
                    ? "run your next ultra"
                    : "run your next ultra",
                )}
              </span>
            </Typography>
          </div>
          <div className={classes.pickContainer}>
            <Typography
              variant="h3"
              align="center"
              className={classes.pickTitle}
            >
              {t("Pick the plan to get started")}
            </Typography>
            <img src={`${process.env.PUBLIC_URL}/icon-arrow-bold.svg`} alt="" />
          </div>
          <div className={classes.subContainer}>
            <SubCategories
              subgroup={subgroup.subCategories}
              onOpenAuth={onOpenAuth}
              max={max}
              hideFav={true}
            />
          </div>

          <RouterLink to="/chooseplan">
            <Typography variant="subtitle2" className={classes.fullPlans}>
              {t("See full training plans menu")}
            </Typography>
          </RouterLink>

          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={() => this.goToDashboard()}
            >
              {t("Take me to my profile")}
              <img src={`${process.env.PUBLIC_URL}/icon-person.svg`} alt="" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

SuggesterResults.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default connect((store) => {
  return {
    subscriptions: store.subscriptions,
    userPlans: store.userPlans,
    auth: store.auth,
  };
})(
  withTranslation("plan")(
    withWidth()(withStyles(styles, { withTheme: true })(SuggesterResults)),
  ),
);
