import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ReorderIcon from "@material-ui/icons/Reorder";
import FilterSelect from "../../common/filter-select";
import WorkoutActionTypes from "../../../constants/workout-action-types";
import WorkoutActionVariables from "../../../constants/workout-action-variables";
import { IconButton, TextField, Tooltip } from "@material-ui/core";
import TimeMaskedInputSeconds from "../../common/time-masked-input-seconds";
import WorkoutTargets from "../../../constants/workout-targets";
import PaceMaskedInput from "../../common/pace-masked-input";
import WorkoutHrZones from "../../../constants/workout-hr-zones";
import DeleteIcon from "@material-ui/icons/Delete";
import FontAwesomeIcon from "../../common/font-awesome-icon";

const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },

  handle: {
    cursor: "grab",
  },

  smallMargin: {
    margin: "0 10px",
  },

  smallMarginAndLeft: {
    margin: "0 10px 0 auto",
  },

  input: {
    padding: "16px",
    margin: 0,
  },

  inputField: {
    backgroundColor: "#FFF",
  },
});

const WorkoutStep = ({
  classes,
  step,
  onChangeStepSelect,
  repeatId = null,
  handleClass = "handle",
  level,
  onDeleteStep,
  onDuplicateStep,
  showActions = false,
}) => {
  const { action, variable, value, id, targetType, targetValue } = step || {};

  const handleChangeTargetValue = (data) => {
    const { name, value } = data;

    let cleanedValue = value;

    if (targetType === WorkoutTargets.Items.RPE) {
      if (cleanedValue > 10) cleanedValue = 10;
      if (cleanedValue < 0) cleanedValue = 0;
    }

    if (
      targetType === WorkoutTargets.Items["Heart Rate"] ||
      targetType === WorkoutTargets.Items["Downhill Speed"] ||
      targetType === WorkoutTargets.Items["Uphill Speed"]
    ) {
      if (cleanedValue < 0) cleanedValue = 0;
    }

    onChangeStepSelect({ name, value: cleanedValue }, id, repeatId);
  };

  const handleChangeValue = (data) => {
    const { name, value } = data;

    let cleanedValue = value;

    if (
      variable === WorkoutActionVariables.Items.Distance ||
      variable === WorkoutActionVariables.Items.Elevation
    ) {
      if (cleanedValue < 0) cleanedValue = 0;
    }

    onChangeStepSelect({ name, value: cleanedValue }, id, repeatId);
  };

  return (
    <div className={classes.container}>
      <div className={handleClass}>
        <ReorderIcon />
      </div>

      <FilterSelect
        autoFocus
        name="action"
        value={action}
        large={false}
        items={WorkoutActionTypes.itemsArray}
        onSelect={(event) => onChangeStepSelect(event, id, repeatId)}
        disabled={level !== 2 && !showActions}
        customStyles
      />

      <FilterSelect
        autoFocus
        name="variable"
        value={variable}
        large={false}
        items={WorkoutActionVariables.itemsArray}
        onSelect={(event) => onChangeStepSelect(event, id, repeatId)}
        disabled={level !== 2 && !showActions}
        customStyles
      />

      {!variable ? (
        ""
      ) : variable === WorkoutActionVariables.Items.Time ? (
        <TextField
          id={id}
          value={value}
          margin="normal"
          variant="outlined"
          name="value"
          className={classes.input}
          InputProps={{
            inputComponent: TimeMaskedInputSeconds,
            className: classes.inputField,
          }}
          onChange={(event) => onChangeStepSelect(event.target, id, repeatId)}
        />
      ) : (
        <TextField
          id={id}
          value={value}
          className={classes.input}
          name="value"
          margin="normal"
          variant="outlined"
          type="number"
          InputProps={{
            endAdornment: <span>m</span>,
            className: classes.inputField,
          }}
          onChange={(event) => handleChangeValue(event.target)}
        />
      )}

      <FilterSelect
        autoFocus
        name="targetType"
        value={targetType}
        large={false}
        items={WorkoutTargets.itemsArray}
        onSelect={(event) => onChangeStepSelect(event, id, repeatId)}
        disabled={level !== 2 && !showActions}
        customStyles
      />

      {!targetType || targetType === WorkoutTargets.Items["No Target"] ? (
        ""
      ) : targetType === WorkoutTargets.Items.Pace ? (
        <TextField
          id={id}
          value={targetValue}
          margin="normal"
          className={classes.input}
          variant="outlined"
          name="targetValue"
          InputProps={{
            inputComponent: PaceMaskedInput,
            className: classes.inputField,
          }}
          onChange={(event) => onChangeStepSelect(event.target, id, repeatId)}
        />
      ) : targetType === WorkoutTargets.Items["Heart Rate Zone"] ? (
        <FilterSelect
          autoFocus
          name="targetValue"
          value={targetValue}
          large={false}
          items={WorkoutHrZones.itemsArray}
          onSelect={(event) => onChangeStepSelect(event, id, repeatId)}
          customStyles
        />
      ) : (
        <TextField
          id={id}
          value={targetValue}
          margin="normal"
          variant="outlined"
          className={classes.input}
          name="targetValue"
          type="number"
          InputProps={{
            className: classes.inputField,
            endAdornment: (
              <span>
                {targetType === WorkoutTargets.Items["Heart Rate"]
                  ? "bpm"
                  : targetType === WorkoutTargets.Items["Downhill Speed"] ||
                    targetType === WorkoutTargets.Items["Uphill Speed"]
                  ? "m/h"
                  : targetType === WorkoutTargets.Items.RPE
                  ? "/10"
                  : ""}
              </span>
            ),
          }}
          onChange={(event) => handleChangeTargetValue(event.target)}
        />
      )}

      {level === 2 || showActions ? (
        <Tooltip title="Delete">
          <IconButton
            aria-label="Delete"
            className={classes.smallMarginAndLeft}
            onClick={() => onDeleteStep(id, repeatId)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}

      {level === 2 || showActions ? (
        <Tooltip title="Duplicate">
          <IconButton
            aria-label="Duplicate"
            className={classes.smallMargin}
            onClick={() => onDuplicateStep(step, repeatId)}
          >
            <FontAwesomeIcon variant="regular" icon="copy" />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
    </div>
  );
};

WorkoutStep.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WorkoutStep);
