import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Input,
  InputAdornment,
  Drawer,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router-dom";
import { Routes } from "../lib/routing-middleware";
import UserGroups from "../constants/user-groups";
import {
  clearErrorMessage,
  clearSuccessMessage,
  getCoaches,
  athletesSearch,
  selectAthlete,
  setErrorMessage,
  getAthleteImageUrl,
  patchSelectedAthleteWorkoutViewed,
  changeSearchPhrase,
  changeSearchFilter,
  getAthleteComments,
  getAthleteChainedPlan,
  getStrengthCoaches,
  athleteData,
  changeSort,
  getCoachAthletesCount,
  cleanSearch,
} from "../actions/athletes-action";
import { dateFormat } from "../lib/date-helper";
import moment from "moment";
import AthletesList from "./athletes/athletes-list";
import AthletesProfile from "./athletes/athletes-profile";
import AthletesCounter from "./athletes/athletes-counter";
import { getSubcategories } from "../actions/subcategory-action";
import AthletesNotifications from "./athletes/athletes-notifications";
import AthletesComments from "./athletes/athletes-comments";
import AutoCompleteSelect from "./common/auto-complete-select";
import AthletesChat from "./athletes/athletes-chat";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import AthletesDetail from "./athletes-detail";

const styles = (theme) => ({
  container: {
    width: "100%",
    height: "100%",
    padding: theme.spacing.unit * 2,
    background: "#FCFCFC",
  },
  items: {
    textAlign: "center",
    minHeight: 980,
  },
  pane: {
    padding: "0 20px",
  },
  paneComments: {
    padding: "0 20px",
    cursor: "pointer",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },
  topItem: {
    margin: 10,
  },
  title: {
    fontSize: 45,
    fontWeight: 600,
    margin: 10,
  },
  loadingContainer: {
    textAlign: "center",
  },
  athletesContainer: {
    textAlign: "center",
  },
  coachesList: {
    minWidth: 250,
  },

  emojiContainer: {
    padding: 5,
    background: "#FFFFFF",
    borderRadius: 20,
  },

  emojiLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },

  emojiText: {
    fontWeight: 500,
    fontSize: 16,
    marginLeft: 5,
  },
});

class Athletes extends Component {
  state = {
    filteredData: null,
    week: 0,
    subscription: "",
    type: "all",
    commentsToRead: 0,
    searchText: "",
    commentsOpen: false,
    coachSub: null,
    chatOpen: false,
    newF: "all",
    openDetails: false,
  };

  componentDidMount() {
    const { subcategories } = this.props;
    const { data } = subcategories;
    if (!data || data.length === 0) this.props.dispatch(getSubcategories());
    const { athletes } = this.props;
    const { searchResult } = athletes || {};
    const { data: athletesData } = searchResult || {};
    this.props.dispatch(getCoaches());
    this.props.dispatch(getStrengthCoaches());

    const { auth } = this.props;
    const { currentUserGroups } = auth;
    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;
    if (!isAdmin) this.handleSearch();
    if (!isAdmin) this.props.dispatch(getCoachAthletesCount());

    const { currentFilter } = athletes;

    this.setState({
      ...this.state,
      currentFilter,
    });

    this.handleFilterChange(currentFilter);
  }
  componentWillUnmount() {
    this.props.dispatch(cleanSearch());
  }

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleSearch = () => {
    const { athletes, auth } = this.props;
    const { currentFilter, searchResult } = athletes;
    const { searchPhrase } = currentFilter;
    const filters = Object.keys(currentFilter).filter(
      (key) => key !== "searchPhrase",
    );
    const { currentUserGroups } = auth;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;

    const { data } = searchResult || {};

    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;
    if (isAdmin && filters.length === 0 && !searchPhrase) {
      this.props.dispatch(
        setErrorMessage("Please fill out filters to search athletes"),
      );
    } else {
      this.props.dispatch(athletesSearch(currentFilter, isAdmin, sub, data));
    }
  };

  handleFilterChange = (filterChange) => {
    const { athletes, auth } = this.props;
    const { searchResult } = athletes || {};
    const { data } = searchResult;

    const { currentUserGroups } = auth;
    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;

    if (!isAdmin) {
      const weekFilter =
        filterChange && (filterChange.week || filterChange.week === 0)
          ? filterChange.week
          : this.state.week;

      const subscriptionFilter =
        filterChange &&
        (filterChange.subscription || filterChange.subscription === "")
          ? filterChange.subscription
          : this.state.subscription;

      const newFilter =
        filterChange && filterChange.newF ? filterChange.newF : this.state.newF;

      const typeFilter =
        filterChange && filterChange.type ? filterChange.type : this.state.type;

      const textFilter =
        filterChange && typeof filterChange.searchText === "string"
          ? filterChange.searchText.trim()
          : this.state.searchText.trim();

      let dataAfterWeekFilter = [];
      if (weekFilter !== 0) {
        const today = moment();

        data.forEach((item) => {
          const {
            currentTraining,
            chainedPlans,
            currentWeek: currentWeekValue,
          } = item;
          const { durationInWeeks, startingDate } = currentTraining || {};
          if (currentWeekValue) {
            if (currentWeekValue === weekFilter) {
              dataAfterWeekFilter.push(item);
            }
            return;
          }
          const startingDateMoment = moment(startingDate, dateFormat);
          const currentDay = today.diff(startingDateMoment, "days") + 1;
          const currentWeek = Math.min(
            Math.ceil((currentDay > 0 ? currentDay : 0) / 7),
            durationInWeeks,
          );
          if (!isNaN(currentWeek)) {
            if (weekFilter > 0 && currentWeek === weekFilter) {
              const { days } = currentTraining;

              let endMoment = moment();
              if (days && days.length) {
                const workouts = days.filter((d) => d !== null);

                if (
                  workouts &&
                  workouts.length &&
                  workouts[workouts.length - 1] &&
                  workouts[workouts.length - 1][0] &&
                  workouts[workouts.length - 1][0].plannedDate
                ) {
                  endMoment = moment(
                    workouts[workouts.length - 1][0].plannedDate,
                    "DD/MM/YYYY",
                  );

                  if (endMoment.isSameOrAfter(moment())) {
                    dataAfterWeekFilter.push(item);
                  }
                }
              }
            }
            if (
              weekFilter < 0 &&
              (!chainedPlans || chainedPlans.length === 0)
            ) {
              const { days } = currentTraining;

              let endMoment = moment();
              if (days) {
                const workouts = days.filter((d) => d !== null);

                if (
                  workouts &&
                  workouts.length &&
                  workouts[workouts.length - 1] &&
                  workouts[workouts.length - 1][0] &&
                  workouts[workouts.length - 1][0].plannedDate
                ) {
                  endMoment = moment(
                    workouts[workouts.length - 1][0].plannedDate,
                    "DD/MM/YYYY",
                  );

                  if (endMoment.isBefore(moment())) {
                    dataAfterWeekFilter.push(item);
                  }
                }
              }
            }
          }
        });
      }
      if (weekFilter === 0) {
        dataAfterWeekFilter = data;
      }

      let dataAfterSubscriptionFilter = [];
      if (subscriptionFilter !== "" && subscriptionFilter !== "all") {
        dataAfterWeekFilter.forEach((item) => {
          const { subscription } = item;
          if (
            subscription &&
            subscription.status &&
            subscription.status === subscriptionFilter
          ) {
            dataAfterSubscriptionFilter.push(item);
          }
        });
      } else {
        dataAfterSubscriptionFilter = dataAfterWeekFilter;
      }

      let dataAfterNewFilter = [];
      if (newFilter === "new") {
        dataAfterSubscriptionFilter.forEach((item) => {
          const { subscription } = item;

          if (subscription && subscription.created_at) {
            const dateA = moment.unix(subscription.created_at);
            const dateB = moment().valueOf();
            const difference = dateA.diff(dateB, "days");

            if (difference >= -30) dataAfterNewFilter.push(item);
          }
        });
      } else {
        dataAfterNewFilter = dataAfterSubscriptionFilter;
      }

      let dataAfterTypeFilter = [];
      if (typeFilter !== "all") {
        dataAfterNewFilter.forEach((item) => {
          const { currentTraining } = item;
          let { selectedPlan } = currentTraining || {};
          if (!selectedPlan && item && item.selectedPlan) {
            selectedPlan = item.selectedPlan;
          }
          if (selectedPlan === typeFilter) {
            dataAfterTypeFilter.push(item);
          }
        });
      } else {
        dataAfterTypeFilter = dataAfterNewFilter;
      }

      let dataAfterTextFilter = [];
      if (textFilter !== "") {
        dataAfterTypeFilter.forEach((item) => {
          const { cognito_user_email, cognito_user_name } = item;
          if (
            (cognito_user_email &&
              cognito_user_email
                .toLowerCase()
                .includes(textFilter.toLowerCase())) ||
            (cognito_user_name &&
              cognito_user_name
                .toLowerCase()
                .includes(textFilter.toLowerCase()))
          ) {
            dataAfterTextFilter.push(item);
          }
        });
      } else {
        dataAfterTextFilter = dataAfterTypeFilter;
      }

      let filteredData = dataAfterTextFilter;
      if (
        weekFilter === 0 &&
        typeFilter === "all" &&
        textFilter === "" &&
        subscriptionFilter === "" &&
        newFilter === "all"
      ) {
        filteredData = null;
      }

      this.setState({
        ...this.state,
        filteredData,
        ...filterChange,
      });

      this.props.dispatch(changeSearchFilter(filterChange));
    } else {
      if (
        filterChange &&
        (filterChange.searchText || filterChange.searchText === "")
      ) {
        this.props.dispatch(
          changeSearchPhrase(filterChange.searchText.trim().toLowerCase()),
        );
        this.setState({
          ...this.state,
          ...filterChange,
        });
      }
    }
  };

  handleCommentsToRead = (commentsToRead) => {
    if (this.state.commentsToRead !== commentsToRead) {
      this.setState({
        ...this.state,
        commentsToRead,
      });
    }
  };

  handleAthleteSelect = (athlete) => {
    const { cognito_user_sub, image, imageUrl, chainedPlans } = athlete || {};

    if (cognito_user_sub) {
      this.props.dispatch(athleteData(athlete));

      const { auth } = this.props;
      const { currentUserGroups } = auth;
      const isAdmin =
        currentUserGroups &&
        currentUserGroups.findIndex(
          (group) => group === UserGroups.USER_GROUP_ADMIN,
        ) > -1;
      if (!isAdmin) {
        this.props.dispatch(
          patchSelectedAthleteWorkoutViewed(cognito_user_sub),
        );
      }
      //if (!statistic)
      //this.props.dispatch(getOverallWorkoutsCount(cognito_user_sub));

      if (image && !imageUrl) {
        this.props.dispatch(getAthleteImageUrl({ image, cognito_user_sub }));
      }
      if (chainedPlans) {
        chainedPlans.forEach((c) => {
          this.props.dispatch(
            getAthleteChainedPlan(cognito_user_sub, c.subcategory),
          );
        });
      }
      setTimeout(this.openDetails(), 100);
    }
  };

  handleOpenChat = (athlete) => {
    const { cognito_user_sub, image, imageUrl } = athlete;
    if (cognito_user_sub) {
      this.props.dispatch(
        getAthleteComments(cognito_user_sub, new Date().getTime()),
      );
      this.props.dispatch(athleteData(athlete));
      if (image && !imageUrl) {
        this.props.dispatch(getAthleteImageUrl({ image, cognito_user_sub }));
      }
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            chatOpen: true,
          }),
        100,
      );
    }
  };

  handleCloseChat = () => {
    this.setState({
      ...this.state,
      chatOpen: false,
    });
  };

  handleKeyPress = (event) => {
    const { auth } = this.props;
    const { currentUserGroups } = auth;
    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;
    if (event.key === "Enter" && isAdmin) {
      this.handleSearch();
    }
  };

  handleCoachFilterChange = (coachSub) => {
    this.setState({
      ...this.state,
      coachSub,
    });
    this.props.dispatch(changeSearchFilter({ coachSub }));
    if (coachSub) {
      setTimeout(() => this.handleSearch());
    }
  };

  openComments = () => {
    this.setState({
      ...this.state,
      commentsOpen: true,
    });
  };

  closeComments = () => {
    this.setState({
      ...this.state,
      commentsOpen: false,
    });
  };
  openDetails = () => {
    this.setState({
      ...this.state,
      openDetails: true,
    });
  };

  closeDetails = () => {
    this.setState({
      ...this.state,
      openDetails: false,
    });
  };

  render() {
    const { classes, auth, athletes, subcategories, userPlans } = this.props;
    const {
      searchResult,
      loading,
      hasBeenSearched,
      coachesList,
      assigningCoach,
      errorMessage,
      successMessage,
      athletesCount,
      totalUnreadMessages,
    } = athletes || {};
    const { data } = searchResult;
    const {
      filteredData,
      week,
      subscription,
      commentsToRead,
      type,
      searchText,
      commentsOpen,
      coachSub,
      chatOpen,
      newF,
      openDetails,
    } = this.state;

    const { currentUserGroups } = auth;
    const { currentPlan } = userPlans || {};
    const { image } = currentPlan || {};

    const isCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_COACH,
      ) > -1;

    const isStrengthCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_STRENGTH_COACH,
      ) > -1;

    const isExternalCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
      ) > -1;

    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;

    let newComments = [];

    const valueObject = coachSub
      ? coachesList.find((coach) => coach.value === coachSub)
      : null;

    data.forEach((element) => {
      if (element.comments) {
        newComments = element.comments.filter(
          (c) => c.type === 1 && c.isReadByCoach === false,
        );
      }
    });

    return openDetails ? (
      <AthletesDetail closeDetails={this.closeDetails} />
    ) : (
      <div className={classes.container}>
        <div className={classes.topContainer}>
          <Typography variant="body1" className={classes.title}>
            Overview
          </Typography>
          {!isAdmin ? (
            <Select
              id="type-filter-select"
              value={type ? type : "all"}
              onChange={(event) =>
                this.handleFilterChange({ type: event.target.value })
              }
              className={classes.topItem}
            >
              <MenuItem value={"all"}>All my athletes</MenuItem>
              <MenuItem value={"free"}>Free athletes</MenuItem>
              <MenuItem value={"explore"}>Explore athletes</MenuItem>
              <MenuItem value={"premium"}>Premium athletes</MenuItem>
            </Select>
          ) : (
            <div className={classes.coachesList}>
              <AutoCompleteSelect
                suggestions={coachesList}
                placeholder="Filter by coach"
                isClearable={true}
                value={valueObject}
                onChange={this.handleCoachFilterChange}
                disable={false}
              />
            </div>
          )}
          <FormControl fullWidth className={classes.topItem}>
            <Input
              id="search"
              placeholder={`Search by email${isAdmin ? "" : " or name"}`}
              value={searchText}
              onChange={(event) =>
                this.handleFilterChange({ searchText: event.target.value })
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              }
              onKeyPress={this.handleKeyPress}
            />
          </FormControl>
          {!isAdmin ? (
            <AthletesNotifications
              onOpenChat={this.handleOpenChat}
              totalUnreadMessages={totalUnreadMessages}
            ></AthletesNotifications>
          ) : (
            ""
          )}
        </div>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2}>
                <AthletesProfile
                  athlete={currentPlan}
                  isCoach={isCoach || isExternalCoach || isStrengthCoach}
                  isAdmin={isAdmin}
                  subcategories={subcategories}
                  image={image}
                ></AthletesProfile>
              </Grid>

              {!isAdmin ? (
                <Grid item xs={3} className={classes.pane}>
                  <AthletesCounter
                    title={"Active athletes"}
                    count={athletesCount ? athletesCount : data.length}
                    icon={"icon-tool"}
                  ></AthletesCounter>
                </Grid>
              ) : (
                ""
              )}
              {!isAdmin ? (
                <Grid
                  item
                  xs={3}
                  className={classes.paneComments}
                  onClick={() => this.openComments()}
                >
                  <AthletesCounter
                    title={"Comments to read"}
                    count={commentsToRead}
                    icon={"icon-tool"}
                  ></AthletesCounter>
                </Grid>
              ) : (
                ""
              )}

              <Grid item className={classes.emojiContainer}>
                <div className={classes.emojiLabel}>
                  <img
                    src={`${process.env.PUBLIC_URL}/emoji_lastweek.png`}
                    alt=""
                  />
                  <Typography variant="body1" className={classes.emojiText}>
                    Plan ends this week
                  </Typography>
                </div>
                <div className={classes.emojiLabel}>
                  <img
                    src={`${process.env.PUBLIC_URL}/emoji_racelastweek.png`}
                    alt=""
                  />
                  <Typography variant="body1" className={classes.emojiText}>
                    Raced last week
                  </Typography>
                </div>
                <div className={classes.emojiLabel}>
                  <img
                    src={`${process.env.PUBLIC_URL}/emoji_racethisweek.png`}
                    alt=""
                  />
                  <Typography variant="body1" className={classes.emojiText}>
                    Racing this week
                  </Typography>
                </div>
                <div className={classes.emojiLabel}>
                  <img
                    src={`${process.env.PUBLIC_URL}/emoji_checked.png`}
                    alt=""
                  />
                  <Typography variant="body1" className={classes.emojiText}>
                    Training checked
                  </Typography>
                </div>
                <div className={classes.emojiLabel}>
                  <img
                    src={`${process.env.PUBLIC_URL}/emoji_needtocheck.png`}
                    alt=""
                  />
                  <Typography variant="body1" className={classes.emojiText}>
                    Need to check training plan
                  </Typography>
                </div>
                <div className={classes.emojiLabel}>
                  <img
                    src={`${process.env.PUBLIC_URL}/help-plan-icon.svg`}
                    alt=""
                  />
                  <Typography variant="body1" className={classes.emojiText}>
                    Need help choosing a plan
                  </Typography>
                </div>
              </Grid>
            </Grid>
            {(loading && (!data || data.length === 0) && !filteredData) ||
            (loading && isAdmin) ? (
              <div className={classes.loadingContainer}>
                <CircularProgress color="primary" />
              </div>
            ) : hasBeenSearched || !isAdmin ? (
              data.length > 0 || (filteredData && filteredData.length) > 0 ? (
                <>
                  {loading && (
                    <div className={classes.loadingContainer}>
                      <CircularProgress color="primary" />
                    </div>
                  )}
                  <AthletesList
                    athletesPlans={filteredData ? filteredData : data}
                    coachesList={coachesList}
                    onAthleteSelect={this.handleAthleteSelect}
                    subcategories={subcategories}
                    onFilterChange={this.handleFilterChange}
                    weekFilter={week}
                    subscriptionFilter={subscription}
                    newFilter={newF}
                    onCommentsToReadCalculated={this.handleCommentsToRead}
                    isAdmin={isAdmin}
                    assigningCoach={assigningCoach}
                    onOpenChat={this.handleOpenChat}
                    athletesCount={athletesCount}
                  />
                </>
              ) : (
                <Typography
                  variant="body1"
                  className={classes.athletesContainer}
                >
                  No athletes found based on your current search criteria
                </Typography>
              )
            ) : (
              <Typography variant="body1" className={classes.athletesContainer}>
                Use the search bar to search for the athletes
              </Typography>
            )}
          </Grid>
        </Grid>

        <AthletesChat
          open={chatOpen}
          onClose={this.handleCloseChat}
          action={this.handleSearch}
        ></AthletesChat>
        {
          <Drawer
            anchor="right"
            open={commentsOpen}
            onClose={this.closeComments}
          >
            <AthletesComments newComments={newComments}></AthletesComments>
          </Drawer>
        }

        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      </div>
    );
  }
}

Athletes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(
  connect((store) => {
    return {
      auth: store.auth,
      athletes: store.athletes,
      subcategories: store.subcategories,
      userPlans: store.userPlans,
    };
  })(withStyles(styles)(Athletes)),
);
