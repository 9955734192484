import React, { Component, memo } from "react";
import { withMobileDialog, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { Routes } from "../../../../lib/routing-middleware";
import history from "../../../../lib/history-helper";
import { dateFormat } from "../../../../lib/date-helper";
import { updatePlan } from "../../../../actions/user-plans-action";
import WorkoutStat from "../../../generals/workout-stat";

const styles = (theme) => ({
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing.unit * 2,
  },
  container: {
    borderRadius: 8,

    border: "1px solid #1E629B",
    padding: 14,
    margin: "15px 0",

    cursor: "pointer",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      border: 0,
      padding: 0,
      margin: 0,
    },
  },

  containerTH: {
    borderRadius: 8,

    border: 0,
    padding: 14,
    margin: "15px 0",

    cursor: "pointer",

    position: "relative",
  },

  title: {
    fontWeight: 500,
  },
  boldText: {
    fontWeight: "bold",
    paddingRight: theme.spacing.unit * 0.5,
  },
  white: {
    color: theme.palette.common.white,
  },
  smallerFontSize: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller",
    },
  },
  dialogActions: {
    justifyContent: "center",
    marginBottom: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    cursor: "pointer",
  },
  leftButtons: {
    display: "flex",
    justifyContent: "flex-start",
  },
  rightMargin: {
    marginRight: theme.spacing.unit * 2,
  },
  buttons: {
    textTransform: "none",
  },
  comment: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing.unit,
    },
  },
  raceDateContainer: {
    display: "flex",
    height: "100%",
    paddingTop: theme.spacing.unit,
    marginTop: 24,
  },
  text: {
    color: theme.palette.text.secondaryDark,
    marginTop: "auto",
    fontSize: "small",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      marginTop: 0,
    },
  },
  raceName: {
    fontWeight: 500,
    fontSize: 14,
    color: "#212121",
    margin: "0px 0px 12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  raceBottomTitle: {
    color: "#212121",
    fontSize: 12,
    fontWeight: 400,
    margin: "0 12px 0 5px",
  },
  raceBottomText: {
    color: "#212121",
    fontSize: 14,
    fontWeight: 500,
  },
  raceLocation: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePriorityContainer: {
    display: "flex",
    alignItems: "center",
  },
  racePriority: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePrioritySelected: {
    color: "#212121",
    fontSize: 10,
    background: "rgba(255, 87, 34, 0.24)",
    fontWeight: 500,
    width: "fit-content",
    padding: "0px 4px",
  },
  racePriorityUnselected: {
    margin: "0 5px",
    background: "#FFBA9B",
    borderRadius: "100%",
    fontWeight: 900,
    fontSize: 21,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "34px",
    textAlign: "center",
  },

  raceBottomContainer: {
    display: "flex",
    alignItems: "center",
    margin: "2px 0",
  },

  shareText: {
    color: "#FF6327",
    fontSize: 14,
    fontWeight: 400,
    position: "absolute",
    top: 5,
    right: 5,
  },
  statParent: {
    position: "absolute",
    top: "16px",
    right: "3%",
    [theme.breakpoints.down("sm")]: {
      right: "0%",
      top: "0%",
    },
  },
});

class DashoardNextRacePane extends Component {
  state = {
    currentUnits: "kms",
    raceName: null,
    raceDistance: null,
    raceElevation: null,
    raceDate: null,
    raceLocation: null,
    racePriority: "A",
    raceId: null,
  };

  calculateDistance = (currentRace, unit) => {
    if (
      (currentRace.units && unit !== currentRace.units) ||
      (!currentRace.units && unit === "miles")
    ) {
      if (unit === "kms") return Math.round(currentRace.raceDistance * 1.60934);
      return Math.round(currentRace.raceDistance * 0.621371);
    }

    return Math.round(parseFloat(currentRace.raceDistance));
  };

  calculateElevation = (currentRace, unit) => {
    if (
      (currentRace.units && unit !== currentRace.units) ||
      (!currentRace.units && unit === "miles")
    ) {
      if (unit === "kms") return Math.round(currentRace.raceElevation * 0.3048);
      return Math.round(currentRace.raceElevation * 3.28084);
    }

    return Math.round(parseFloat(currentRace.raceElevation));
  };

  componentDidMount = () => {
    const { nextRace } = this.props;
    const currentUnits = localStorage.getItem("units");
    if (nextRace) {
      let calculatedDistance = nextRace.raceDistance;
      let calculatedElevation = nextRace.raceElevation;

      if (
        (nextRace.units && currentUnits !== nextRace.units) ||
        (!nextRace.units && currentUnits === "miles")
      ) {
        if (currentUnits === "kms") {
          calculatedDistance = Math.round(calculatedDistance * 1.60934);
          calculatedElevation = Math.round(calculatedElevation * 0.3048);
        } else {
          calculatedDistance = Math.round(calculatedDistance * 0.621371);
          calculatedElevation = Math.round(calculatedElevation * 3.28084);
        }
      }

      this.setState({
        ...nextRace,
        raceDistance: calculatedDistance,
        raceElevation: calculatedElevation,
        units: currentUnits,
        raceId: nextRace.raceId ? nextRace.raceId : "old",
        currentUnits,
      });
    } else {
      this.setState({
        units: localStorage.getItem("units"),
        raceName: null,
        raceDistance: null,
        raceElevation: null,
        raceDate: null,
        raceLocation: null,
        raceId: "add",
        racePriority: "A",
        currentUnits,
      });
    }

    if (typeof window !== "undefined") {
      window.addEventListener("storage", () => this.localStorageUpdated());
    }
  };

  localStorageUpdated() {
    this.setState({
      ...this.state,
      currentUnits: localStorage.getItem("units"),
    });
  }

  handleTextChange = (event) => {
    const { target } = event;
    if (target) {
      const { value, name } = target;
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  handleRaceDateChange = (momentObject) => {
    const { onError } = this.props;
    if (momentObject) {
      try {
        this.setState({
          ...this.state,
          raceDate: momentObject.format(dateFormat),
        });
      } catch (exp) {
        if (onError) onError(exp);
      }
    } else {
      this.setState({
        ...this.state,
        raceDate: undefined,
      });
    }
  };

  handlePriorityChange = (priority) => {
    this.setState({
      ...this.state,
      racePriority: priority,
    });
  };

  handleDelete = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    this.props.dispatch(
      updatePlan(
        {
          cognito_user_sub: currentPlan.cognito_user_sub,
          nextRace: null,
        },
        false,
      ),
    );
    history.push(Routes.DashboardPage);
  };

  render() {
    const {
      currentUnits,
      raceDistance,
      raceElevation,
      raceName,
      raceDate,
      racePriority,
      units: raceUnits,
      raceId,
    } = this.state;
    const { classes, t, onEdit, isTrailhead = false, nextRace } = this.props;

    const isInMobileViewSm =
      this.props.width === "sm" || this.props.width === "xs";

    return (
      <div
        className={isTrailhead ? classes.containerTH : classes.container}
        onClick={() => (isTrailhead ? onEdit(nextRace) : onEdit(raceId))}
      >
        {isTrailhead && onEdit ? (
          <Typography variant="body1" className={classes.shareText}>
            {t("Share Goal")}
          </Typography>
        ) : (
          ""
        )}

        <div
          className={classes.statParent}
          style={isTrailhead && onEdit ? { top: "26px", right: "0%" } : null}
        >
          <WorkoutStat
            distance={this.calculateDistance(nextRace, currentUnits)}
            elevation={this.calculateElevation(nextRace, currentUnits)}
          />
        </div>

        <Typography variant="h6" className={classes.racePrioritySelected}>
          {t("Priority")} {racePriority}
        </Typography>
        <Typography
          variant="h6"
          className={classes.raceName}
          style={{
            maxWidth: !isTrailhead && isInMobileViewSm ? "50vw" : "50%",
          }}
        >
          {raceName}
        </Typography>

        <div>
          <div className={classes.raceBottomContainer}>
            <img src={`${process.env.PUBLIC_URL}/icon-distance.svg`} alt="" />
            <Typography variant="h6" className={classes.raceBottomTitle}>
              {t("Distance")}
            </Typography>
            <Typography variant="body1" className={classes.raceBottomText}>
              {this.calculateDistance(nextRace, currentUnits)
                ? !currentUnits || currentUnits === "kms"
                  ? t("X Kms", {
                      value: this.calculateDistance(nextRace, currentUnits),
                    })
                  : t("X miles", {
                      value: this.calculateDistance(nextRace, currentUnits),
                    })
                : t("Empty")}
            </Typography>
          </div>
          <div className={classes.raceBottomContainer}>
            <img src={`${process.env.PUBLIC_URL}/icon-elevation.svg`} alt="" />

            <Typography variant="h6" className={classes.raceBottomTitle}>
              {t("Elevation")}
            </Typography>

            <Typography variant="body1" className={classes.raceBottomText}>
              {raceElevation
                ? !currentUnits || currentUnits === "kms"
                  ? t("X ms", {
                      value: this.calculateElevation(nextRace, currentUnits),
                    })
                  : t("X feet", {
                      value: this.calculateElevation(nextRace, currentUnits),
                    })
                : t("Empty")}
            </Typography>
          </div>
          <div className={classes.raceBottomContainer}>
            <img src={`${process.env.PUBLIC_URL}/icon-timer.svg`} alt="" />
            <Typography variant="h6" className={classes.raceBottomTitle}>
              {t("Date")}
            </Typography>
            <Typography variant="body1" className={classes.raceBottomText}>
              {raceDate}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

DashoardNextRacePane.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  fullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  userPlans: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation("dashboard")(
      withWidth()(withMobileDialog()(memo(DashoardNextRacePane))),
    ),
  ),
);
