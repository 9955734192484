import React, { useEffect, useState } from "react";
import {
  Box,
  Slide,
  Dialog,
  Typography,
  Divider,
  Avatar,
  Chip,
  Button,
  Grid,
  Card,
  IconButton,
  Collapse,
  CardContent,
  CardActions,
} from "@material-ui/core";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import { makeStyles, styled } from "@material-ui/core/styles";
import { CheckIcon, DotIcon, RunningIcon } from "../../icons";
import { Rating, Skeleton, Stack } from "@mui/material";
import WorkoutDate from "./WorkoutDate";
import useQuestionnaire from "../../../hooks/useQuestionnaire";
import dayjs from "dayjs";
import WorkoutCard from "./WorkoutCard";
import { WorkoutTypes } from "../../../constants/workout-types";
import { convertMinutesToTimeLabel } from "../../../constants/estimated-times";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import useFavorites from "../../../hooks/useFavorites";
import {
  IconCalendarEvent,
  IconChevronDown,
  IconRoute,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PlanDetailsDialog = ({
  openDialog,
  setOpenDialog,
  plan,
  onStartTraining = (plan) => console.info("plan", plan),
}) => {
  const classes = useStyles();
  const { getSample, loadingSampleWeek, sampleWeek } = useQuestionnaire();
  const { toggleFavorites, isFavorite } = useFavorites();
  const [sampleWeeksWithDates, setSampleWeeksWithDates] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const {
    t,
    i18n: { language },
  } = useTranslation("plan");

  useEffect(() => {
    const week = [];
    if (sampleWeek) {
      for (let i = 0; i < sampleWeek.length; i++) {
        const currendDay = dayjs()
          .startOf("isoWeek")
          .add(i, "day");
        week.push({
          ...sampleWeek[i][0],
          plannedDate: new Date(currendDay),
        });
      }
    }
    setSampleWeeksWithDates(week);
  }, [sampleWeek]);

  useEffect(() => {
    if (expanded) {
      getSample({ id: plan.id, plan: plan.plan });
    }
  }, [expanded]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setExpanded(false);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (!plan) return null;

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
      display={"flex"}
      flexDirection="column"
      scroll="paper"
    >
      <Box className={classes.headerBox}>
        <ToolbarReturnBack
          goBack={handleCloseDialog}
          title={t("plan.dialog.details")}
          withBox={false}
          customItem={
            plan ? (
              <IconButton
                className={classes.favoriteButton}
                size="small"
                aria-label="add to favorites"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFavorites("plan", plan.id);
                }}
              >
                {isFavorite("plan", plan.id) ? (
                  <StarIcon color="primary" />
                ) : (
                  <StarBorderIcon style={{ color: "black" }} />
                )}
              </IconButton>
            ) : (
              <Box width={"30px"} />
            )
          }
        />
      </Box>
      <Box className={classes.parentBox}>
        <Box className={classes.contentContainer}>
          <Box className={classes.headerContainer}>
            {plan.picture && (
              <img
                className={classes.image}
                src={plan.picture}
                alt={plan.athlete}
              />
            )}
            <Box>
              {plan.athlete && (
                <Typography className={classes.name}>{plan.athlete}</Typography>
              )}
              <Typography className={classes.title}>
                {plan.title[language] ? plan.title[language] : plan.title.en}
                {plan.runner_level && (
                  <Box className={classes.levelContainer}>
                    <Typography className={classes.levelText}>
                      {plan.runner_level[language]
                        ? plan.runner_level[language]
                        : plan.runner_level.en
                        ? plan.runner_level.en
                        : ""}
                    </Typography>
                  </Box>
                )}
              </Typography>
            </Box>
            <Box className={classes.iconContainer}>
              <Box className={classes.icon}>
                <IconCalendarEvent color="#6D6F71" stroke={1.5} />
                <Typography className={classes.dividerIconText}>
                  {plan.weeks} {t("plan.dialog.weeks")}
                </Typography>
              </Box>
              <Divider orientation="vertical" variant="fullWidth" flexItem />
              <Box className={classes.icon}>
                <IconRoute color="#6D6F71" stroke={1.5} />
                <Typography className={classes.dividerIconText}>
                  {t("plan.dialog.maxDistance")} {plan.max_distance}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.warningContainer}>
              <CheckIcon state="filled" color="#000000" />
              <Typography className={classes.warningText}>
                <span className={classes.warningTextSpan}>
                  {t("plan.dialog.warning")}
                </span>{" "}
                {t("plan.dialog.warningDescription")}
              </Typography>
            </Box>
          </Box>
          <Typography className={classes.descriptionText}>
            {plan.description[language]
              ? plan.description[language]
              : plan.description.en}
          </Typography>
          <Box className={classes.athleteDetailsContainer}>
            <Box className={classes.profileContainer}>
              {plan.athlete_picture && (
                <Avatar className={classes.avatar} src={plan.athlete_picture} />
              )}
              {plan.athlete && (
                <Typography className={classes.avatarName}>
                  {plan.athlete}
                </Typography>
              )}
            </Box>
            {plan.athlete_bio && (
              <Typography className={classes.descriptionText}>
                {plan.athlete_bio[language]
                  ? plan.athlete_bio[language]
                  : plan.athlete_bio.en}
              </Typography>
            )}
          </Box>
          <Box className={classes.ratingContainer}>
            <Box className={classes.ratingBox}>
              <Typography className={classes.ratingText}>
                {t("plan.dialog.intensity")}
              </Typography>
              <Rating
                className={classes.rating}
                readOnly
                max={5}
                size="large"
                defaultValue={Number(plan.intensity)}
                icon={<DotIcon color="#F93C6E" width={14} height={14} />}
                emptyIcon={
                  <DotIcon
                    state="filled"
                    color="#d9d9d9"
                    width={14}
                    height={14}
                  />
                }
              />
            </Box>
            <Box className={classes.ratingBox}>
              <Typography className={classes.ratingText}>
                {t("plan.dialog.strength")}
              </Typography>
              <Rating
                className={classes.rating}
                readOnly
                max={5}
                size="large"
                defaultValue={Number(plan.strength)}
                icon={<DotIcon color="#F93C6E" width={14} height={14} />}
                emptyIcon={
                  <DotIcon
                    state="filled"
                    color="#d9d9d9"
                    width={14}
                    height={14}
                  />
                }
              />
            </Box>
            <Box className={classes.ratingBox}>
              <Typography className={classes.ratingText}>
                {t("plan.dialog.endurance")}
              </Typography>
              <Rating
                className={classes.rating}
                readOnly
                max={5}
                size="large"
                defaultValue={Number(plan.endurance)}
                icon={<DotIcon color="#F93C6E" width={14} height={14} />}
                emptyIcon={
                  <DotIcon
                    state="filled"
                    color="#d9d9d9"
                    width={14}
                    height={14}
                  />
                }
              />
            </Box>
            <Box className={classes.ratingBox}>
              <Typography className={classes.ratingText}>
                {t("plan.dialog.trainingLoad")}
              </Typography>
              <Rating
                className={classes.rating}
                readOnly
                max={5}
                size="large"
                defaultValue={Number(plan.load)}
                icon={<DotIcon color="#F93C6E" width={14} height={14} />}
                emptyIcon={
                  <DotIcon
                    state="filled"
                    color="#d9d9d9"
                    width={14}
                    height={14}
                  />
                }
              />
            </Box>
          </Box>
          {plan.average && (
            <Box className={classes.avgTrainingContainer}>
              <Box className={classes.warningContainer}>
                <RunningIcon />
                <Typography className={classes.avgTrainingText}>
                  {t("plan.dialog.averageTraining")}
                </Typography>
              </Box>
              <Typography className={classes.avgTrainingValue}>
                {t(
                  `Up to ${plan.average.hours} h and ${plan.average.kilometers} km`,
                )}
              </Typography>
            </Box>
          )}
          {plan.tags && plan.tags.length > 0 && (
            <Box>
              <Stack
                spacing={1}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                style={{
                  marginBottom: "16px",
                }}
              >
                {plan.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    size="medium"
                    variant="default"
                    label={tag}
                    className={classes.tag}
                  />
                ))}
              </Stack>
            </Box>
          )}
          <Card className={classes.sampleWeekCard}>
            <CardActions disableSpacing>
              <Typography className={classes.avgTrainingText}>
                {t("plan.dialog.sample")}
              </Typography>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                className={classes.expandIcon}
              >
                <IconChevronDown size={18} color="#AFB1B4" />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                {loadingSampleWeek ? (
                  <Box className={classes.progressContainer}>
                    <Grid container>
                      <Grid item xs={2}>
                        {Array.from({ length: 7 }).map((_, i) => (
                          <Skeleton
                            key={i}
                            variant="rounded"
                            width="90%"
                            height={65}
                            style={{ marginBottom: "8px" }}
                          />
                        ))}
                      </Grid>
                      <Grid item xs={10}>
                        {Array.from({ length: 7 }).map((_, i) => (
                          <Skeleton
                            key={i}
                            variant="rounded"
                            width="100%"
                            height={65}
                            style={{ marginBottom: "8px" }}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  sampleWeeksWithDates &&
                  sampleWeeksWithDates.length > 0 && (
                    <Grid container>
                      <Grid item xs={2}>
                        {sampleWeeksWithDates.map((workout, i) => (
                          <WorkoutDate
                            key={i}
                            date={workout.plannedDate}
                            ignoreToday
                          />
                        ))}
                      </Grid>
                      <Grid item xs={10} className={classes.workoutContainer}>
                        {sampleWeeksWithDates.map((workout, i) => (
                          <WorkoutCard
                            margin="0"
                            key={i}
                            variant={
                              workout ? workout.workoutType : "NoWorkout"
                            }
                            primaryText={
                              workout.workoutType
                                ? t(WorkoutTypes[workout.workoutType])
                                : ""
                            }
                            secondaryText={
                              workout.estimatedTime
                                ? convertMinutesToTimeLabel(
                                    +workout.estimatedTime[0],
                                  )
                                : ""
                            }
                            withCheck
                          />
                        ))}
                      </Grid>
                    </Grid>
                  )
                )}
              </CardContent>
            </Collapse>
          </Card>
          <Button
            variant="contained"
            onClick={() => onStartTraining(plan)}
            className={classes.startButton}
          >
            {t("plan.dialog.startPlan")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PlanDetailsDialog;

const useStyles = makeStyles({
  dialog: {
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  headerBox: {
    flex: "0 0 auto",
    backgroundColor: "#FEFFFF",
  },
  parentBox: {
    flex: "1 1 auto",
    overflowY: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    backgroundColor: "#FAFAFA",
  },
  image: {
    width: "100%",
    height: "250px",
    objectFit: "cover",
    objectPosition: "center -20px",
    borderRadius: "8px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 16px",
    gap: "32px",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  name: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#6D6F71",
    textTransform: "capitalize",
    lineHeight: "24px",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    color: "#12151B",
    textTransform: "initial",
    lineHeight: "22.5px",
  },
  iconContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dividerIconText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6D6F71",
    lineHeight: "21px",
  },
  levelContainer: {
    backgroundColor: "#6BB488",
    color: "#FEFFFF",
    padding: "4px 8px",
    borderRadius: "4px",
    marginLeft: "8px",
  },
  level: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "21px",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  warningText: {
    color: "#12151B",
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
  },
  warningTextSpan: {
    color: "#12151B",
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "18px",
  },
  descriptionText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "14px",
    color: "#12151B",
    lineHeight: "21px",
  },
  athleteDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  profileContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
  },
  avatar: {
    width: "50px",
    height: "50px",
  },
  avatarName: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    color: "#212121",
    lineHeight: "22.5px",
  },
  ratingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  ratingText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#12151B",
    lineHeight: "24px",
  },
  rating: {
    display: "flex",
    gap: "16px",
  },
  ratingBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avgTrainingContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avgTrainingText: {
    padding: "0px",
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "16px",
    color: "#12151B",
    lineHeight: "24px",
  },
  avgTrainingValue: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#12151B",
    lineHeight: "24px",
  },
  tag: {
    borderRadius: "4px",
    backgroundColor: "#F1F6FD",
    padding: "4px 8px",
    marginRight: "4px",
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#12151b",
  },
  startButton: {
    flex: 2,
    backgroundColor: "#0B2B5C",
    color: "#FFFFFF",
    borderRadius: "4px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
  },
  workoutContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "5px",
    justifyContent: "space-around",
  },
  progressContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },
  sampleWeekCard: {
    backgroundColor: "#FEFFFF",
    padding: "0px 12px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  expandIcon: {
    padding: "0px",
  },
});
