import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import { palette } from "../../../theme/palette";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Collapse = ({
  children,
  pin = { active: false, color: palette.darkBlue },
  title = "Collapse",
  subHeading,
  disabled = false,
  isExpand = true,
  Icon,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          disabled={disabled}
          expandIcon={disabled || isExpand ? <ExpandMoreIcon /> : null}
          aria-controls="panel1a-content"
          id={`panel1a-header`}
          className={classes.summary}
          classes={{ content: classes.between }}
        >
          <div className={`${classes.flex}`}>
            <Box
              style={{
                width: 24,
                height: 24,
                backgroundColor: pin.color,
                borderRadius: "50%",
                marginRight: "10px",
                display: pin.active ? "flex" : "none",
              }}
            />
            <Typography className={classes.heading}>{title}</Typography>
          </div>
          <div className={classes.column}>
            {Icon && <Icon width={16} height={16} />}
            <Typography className={classes.subHeading}>{subHeading}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.details }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  flex: {
    display: "flex",
  },
  between: {
    justifyContent: "space-between",
  },
  accordion: {
    backgroundColor: palette.white,
    boxShadow: palette.boxShadow,
    padding: "12px 14px",
    borderRadius: 4,
  },
  summary: {
    padding: 0,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: "0 6px 0 0",
  },
  column: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  heading: {
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 700,
    color: palette.black,
  },
  subHeading: {
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
    color: palette.black,
  },
}));

Collapse.propTypes = {
  children: PropTypes.element,
  pin: PropTypes.object,
  title: PropTypes.string,
  subHeading: PropTypes.string,
  disabled: PropTypes.bool,
  Icon: PropTypes.element,
};

export default Collapse;
