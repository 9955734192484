const TrainingDistances = {
  "12": "10 Km",
  "21": "21 Km",
  "32": "32 Km",
  "42": "50 Km",
};

const TrainingNames = {
  "12": "First Trail Experience",
  "21": "Train for a Trail Half Marathon",
  "32": "Train to run 20 mountain miles",
  "42": "Family - friendly 5k",
};

export default {
  Items: TrainingDistances,
  Names: TrainingNames,
  itemsArray: Object.keys(TrainingDistances).map((key) => {
    return {
      text: TrainingDistances[key],
      value: key,
    };
  }),
};
