import moment from "moment";

const getNextMonday = () => {
  if (moment().isoWeekday() === 1 || moment().isoWeekday() === 2)
    //Monday = 1, Tuesday=2
    return moment()
      .startOf("isoWeek")
      .startOf("day");
  else
    return moment()
      .add(1, "week")
      .startOf("isoWeek")
      .startOf("day");
};

const getPreviousMonday = (date = null) => {
  if (date) {
    return date.startOf("isoWeek").startOf("day");
  }
  return moment()
    .startOf("isoWeek")
    .startOf("day");
};

const getWeekday = (date = null, language = "en", fixFormat = false) => {
  if (!date) return false;

  let dateConvert = null;
  if (fixFormat) {
    const dateSplit = date.split("/");
    const dateFixFormat = `${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`;
    dateConvert = new Date(dateFixFormat);
  } else {
    dateConvert = new Date(date);
  }

  return dateConvert.toLocaleDateString(locale[language], { weekday: "long" });
};

const getDay = (date = null, language = "en", fixFormat = false) => {
  if (!date) return false;

  let dateConvert = null;
  if (fixFormat) {
    const dateSplit = date.split("/");
    const dateFixFormat = `${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`;
    dateConvert = new Date(dateFixFormat);
  } else {
    dateConvert = new Date(date);
  }

  return dateConvert.toLocaleDateString(locale[language], {
    weekday: "long",
    year: undefined,
    month: "short",
    day: "numeric",
  });
};

const locale = {
  es: "es-ES",
  en: "en-US",
  fr: "fr-FR",
};
const dateFormat = "DD/MM/YYYY";
const dateFormatShort = "DD/MM";
const formatDate = "MM/DD/YYYY";

export {
  getPreviousMonday,
  getNextMonday,
  getWeekday,
  getDay,
  dateFormat,
  dateFormatShort,
  formatDate,
};
