import ActionTypes from "../constants/action-types";
import { getActivityCalculations } from "../lib/units-helper";

const initialState = {
  initializing: false,
  loading: false,
  activities: [],
  loadingActivity: false,
  currentActivity: null,
  watchers: [],
  loadingEdit: false,
  deletedActivity: false,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.VERT_LOAD_ACTIVITIES_PENDING:
        return {
          ...state,
          loading: true,
          errorMessage: undefined,
          activities: action.payload ? [] : state.activities,
        };
      case ActionTypes.VERT_LOAD_ACTIVITIES_FULFILLED:
        return {
          ...state,
          loading: false,
          activities: action.payload,
        };
      case ActionTypes.VERT_LOAD_ACTIVITIES_REJECTED:
        return {
          ...state,
          loading: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.VERT_GET_ACTIVITY_PENDING:
      case ActionTypes.VERT_ADD_ACTIVITY_PENDING:
        return {
          ...state,
          loadingActivity: true,
          errorMessage: undefined,
        };
      case ActionTypes.VERT_GET_ACTIVITY_FULFILLED:
      case ActionTypes.VERT_ADD_ACTIVITY_FULFILLED:
        return {
          ...state,
          loadingActivity: false,
          currentActivity: getActivityCalculations(
            action.payload,
            localStorage.getItem("units"),
            "vert.run",
          ),
        };
      case ActionTypes.VERT_GET_ACTIVITY_REJECTED:
      case ActionTypes.VERT_ADD_ACTIVITY_REJECTED:
        return {
          ...state,
          loadingActivity: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.VERT_SET_ACTIVITY:
        return {
          ...state,
          loadingActivity: false,
          currentActivity: action.payload,
        };
      case ActionTypes.VERT_CLEAR_ACTIVITY:
        return {
          ...state,
          loadingActivity: false,
          currentActivity: null,
          actvities: [],
        };

      case ActionTypes.VERT_SET_WATCHER:
        return {
          ...state,
          watchers: [...state.watchers, action.payload],
        };
      case ActionTypes.VERT_CLEAR_WATCHERS:
        return {
          ...state,
          watchers: [],
        };

      case ActionTypes.UPDATE_VERT_ACTIVITY_PENDING:
        return {
          ...state,
          loadingEdit: true,
        };

      case ActionTypes.UPDATE_VERT_ACTIVITY_FULLFILLED:
        return {
          ...state,
          loadingEdit: false,
        };

      case ActionTypes.UPDATE_VERT_ACTIVITY_REJECTED:
        return {
          ...state,
          loadingEdit: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.VERT_CLEAR_ACTIVITIES:
        return { ...initialState };

      case ActionTypes.DELETE_VERT_ACTIVITY_FULFILLED:
        return { ...state, deletedActivity: action.payload };

      default:
        return state;
    }
  } else {
    return state;
  }
}
