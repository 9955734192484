import React, { Component, createRef, memo } from "react";
import {
  withMobileDialog,
  Typography,
  Dialog,
  DialogContent,
  LinearProgress,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import moment from "moment";
import { round, getPace } from "../../../lib/units-helper";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import EditForm from "./edit-form";
import ActivityMountainIndex from "../../generals/activity-mountain-index";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import { dateFormat } from "../../../lib/date-helper";
import { getPlan } from "../../../lib/current-training-helper";
import {
  athleteTrainingEditWorkout,
  selectAthleteWorkout,
} from "../../../actions/athletes-action";
import Reactions from "../../generals/reactions";
import SnackBarMessageDisplay from "../../common/snack-bar-message-display";

const styles = (theme) => ({
  container: {
    backgroundColor: "#F9F9FB",

    [theme.breakpoints.down("md")]: {
      backgroundColor: "#ffffff",
    },
  },

  topContainer: {
    display: "flex",
    marginBottom: 40,
    alignItems: "center",
  },

  topContainerComponent: {
    display: "flex",
    alignItems: "center",
  },

  iconShare: {
    marginLeft: "auto",
  },

  date: {
    fontWeight: 500,
    fontSize: 18,
    marginLeft: 20,
  },

  grayDate: {
    color: "#9F9F9F",
    fontSize: 16,
    marginBottom: 20,
  },

  name: {
    fontWeight: 500,
    fontSize: 18,
    color: "#E16327",
    maxWidth: "80%",
  },

  mainStatsContainer: {
    display: "flex",
    // margin: "20px 0",
    // backgroundColor: "green"
  },

  mainStat: {
    width: "50%",
  },

  mainStatValue: {
    display: "flex",
    alignItems: "flex-end",
    wordWrap: "break-word",
  },

  mainStatValueNumber: {
    fontWeight: "bold",
    fontSize: 38,
  },

  mainStatValueUnit: {
    fontWeight: "normal",
    fontSize: 14.4,
    marginBottom: 12,
    marginLeft: 3,
  },

  mainStatLabel: {
    fontWeight: "normal",
    fontSize: 14,
    color: "#9E9E9E",
  },

  stats: {
    background: "#F9F9FB",
    padding: "16px 16px 0",
    borderRadius: "4px",
    boxSizing: "border-box",
  },

  statsRow: {
    display: "flex",
    paddingBottom: 16,
    boxSizing: "border-box",
  },

  stat: {
    width: "50%",
  },

  statValue: {
    display: "flex",
    alignItems: "flex-end",
  },

  statValueNumber: {
    fontWeight: 500,
    fontSize: 16,
  },

  statValueUnit: {
    fontWeight: "normal",
    fontSize: 12,
    marginBottom: 1,
    marginLeft: 3,
  },

  statLabel: {
    fontWeight: "normal",
    fontSize: 14,
    color: "#9E9E9E",
    marginTop: 4,
  },

  graphContainer: {
    height: 170,
    padding: "12px 0",
    background: "#FFFFFF",
    boxSizing: "border-box",
  },

  graphElevationUnit: {
    color: "#9E9E9E",
    fontSize: 14,
    fontWeight: "normal",
    marginBottom: 5,
    marginLeft: 20,
  },

  subtitle: {
    fontWeight: 500,
    fontSize: 18,
    // marginTop: 40,
  },

  progressRoot: {
    height: 16,
    margin: "auto 0",
    background: "transparent",
  },
  progressBar: {
    borderRadius: "0px 4px 4px 0px",
    background: "#1E629B",
  },

  table: {
    borderCollapse: "collapse",
  },

  header: {
    borderBottom: "1px solid #E0E0E0",
  },

  headerTH: {
    fontWeight: "normal",
    fontSize: 12,
    color: "#9E9E9E",
    margin: "6px 0",
    padding: "0 3px",
    boxSizing: "border-box",
  },

  th: {
    fontWeight: "normal",
    fontSize: 14,
    margin: "6px 0",
    textAlign: "center",
    padding: "0 3px",
    boxSizing: "border-box",
  },

  barTH: {
    width: "100%",
    padding: "0 10px",
  },

  stravaDisclaimer: {
    fontSize: 14,
    textAlign: "center",
    marginTop: 20,
    fontWeight: 500,
  },

  progressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  loading: {
    margin: "auto",
  },
  headParent: {
    // display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionText: {
    color: "#9E9E9E",
    fontSize: "14px",
    maxWidth: "80%",
    marginTop: "4px",
  },
  textsParent: {
    maxWidth: "100%",
    overflowWrap: "break-word",
  },
  editIconParent: {
    cursor: "pointer",
  },

  leftParent: {
    background: "white",
    padding: "10px",
    boxSizing: "border-box",
    border: "solid 1px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("md")]: {
      border: "none",
    },
  },
  chartParent: {
    marginTop: 40,
    border: "solid 1px rgba(0, 0, 0, 0.2)",
    padding: "8px",
    boxSizing: "border-box",
    backgroundColor: "#FFF",
    [theme.breakpoints.down("md")]: {
      border: "none",
    },
  },
  mountainParent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  emojiSuggestion: {
    background: "#EFEFEF",
    borderRadius: "62px",
    padding: "4px 8px",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    marginTop: "7px",
  },
  emojiChild: {
    marginRight: "8px",
    cursor: "pointer",
  },
  emojisContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: "8px",
    position: "relative",
  },
  picker: {
    position: "absolute",
    top: "50%",
  },
  reactionMargin: {
    marginBottom: "10px",

    [theme.breakpoints.down("md")]: {
      marginBottom: "0px",
    },
  },
  titleParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const suggestedEmojies = [
  "1f44d",
  "1f604",
  "2764-fe0f",
  "1f44c",
  "1f64f",
  "1f600",
];

class DashboardActivityDetails extends Component {
  constructor(props) {
    super(props);
    this.divRef = createRef(null);
  }

  state = {
    showEdit: false,
    height: 0,
    showPicker: false,
    emojiError: false,
  };

  handleOpen = () => {
    this.setState({ ...this.setState, showPicker: true });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.height == 0) {
      if (this.divRef.current)
        this.setState({
          ...this.state,
          height: this.divRef.current.clientHeight,
        });
    }
  };

  handleEmoji = (emoji) => {
    this.setState({ ...this.setState, showPicker: false });
  };

  handleEmojiDelete = async (emoji) => {
    const {
      selectedAthlete,
      selectedAthleteChainedPlans,
    } = this.props.athletes;
    const { dispatch } = this.props;
    const { selectedWorkout } = selectedAthlete || {};

    const currentWorkout = { ...selectedWorkout };

    const plan = getPlan(
      [
        selectedAthlete,
        ...selectedAthleteChainedPlans.map((c, index) => {
          return {
            updated_at: selectedAthlete.chainedPlans[index],
            currentTraining: c,
          };
        }),
      ],
      moment(currentWorkout.plannedDate, dateFormat),
    );

    const previousReactions = currentWorkout.reaction
      ? [...currentWorkout.reaction]
      : [];

    const emojiList = previousReactions.filter((item) => item !== emoji);

    await dispatch(
      athleteTrainingEditWorkout(
        selectedAthlete,
        {
          ...currentWorkout,
          workoutId: currentWorkout.id,
          reaction: emojiList,
        },
        plan.updated_at === "latest" ? 0 : plan.updated_at,
      ),
    );

    await dispatch(
      selectAthleteWorkout({ ...currentWorkout, reaction: emojiList }),
    );

    this.setState({ ...this.state, showPicker: false });
  };

  handleError = () => {
    this.setState({ ...this.state, emojiError: true });

    setTimeout(() => {
      this.setState({ ...this.state, emojiError: false });
    }, 2000);
  };

  handleEdit = async (emoji) => {
    const {
      selectedAthlete,
      selectedAthleteChainedPlans,
    } = this.props.athletes;
    const { dispatch } = this.props;
    const { selectedWorkout } = selectedAthlete || {};

    const currentWorkout = { ...selectedWorkout };

    const plan = getPlan(
      [
        selectedAthlete,
        ...selectedAthleteChainedPlans.map((c, index) => {
          return {
            updated_at: selectedAthlete.chainedPlans[index],
            currentTraining: c,
          };
        }),
      ],
      moment(currentWorkout.plannedDate, dateFormat),
    );

    const previousReactions = currentWorkout.reaction
      ? [...currentWorkout.reaction]
      : [];

    if (previousReactions.length >= 2) {
      this.setState({ ...this.state, showPicker: false });
      this.handleError();
      return;
    }

    await dispatch(
      athleteTrainingEditWorkout(
        selectedAthlete,
        {
          ...currentWorkout,
          workoutId: currentWorkout.id,
          reaction: [...previousReactions, emoji],
        },
        plan.updated_at === "latest" ? 0 : plan.updated_at,
      ),
    );

    await dispatch(
      selectAthleteWorkout({
        ...currentWorkout,
        reaction: [...previousReactions, emoji],
      }),
    );
    this.setState({ ...this.state, showPicker: false });
  };

  checkReactions = (activityDate) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { currentTraining } = currentPlan || {};
    const { days = [] } = currentTraining || {};

    const result = days.find((item) => {
      if (item) {
        const difference = moment(item[0].plannedDate, "DD/MM/YYYY").diff(
          activityDate,
          "days",
        );
        if (difference === 0) return item;
      }
    });

    return result && result.length ? result[0].reaction : [];
  };

  render() {
    const {
      classes,
      t,
      isOpen,
      onClose,
      strava,
      garmin,
      suunto,
      vertActivities,
      onOpenShare,
      asComponent,
      width,
      activities,
      customHeight,
      elevationMargin,
      athletes,
      tmpWorkout = {},
      isCoach,
      healthKit,
      coros,
    } = this.props;

    const { selectedAthlete } = athletes || {};
    const { selectedWorkout = {} } = selectedAthlete || {};

    const { showPicker } = this.state;

    const currentDevice =
      isOpen === "vert.run"
        ? vertActivities
        : isOpen === "garmin"
        ? garmin
        : isOpen === "suunto"
        ? suunto
        : isOpen === "coros"
        ? coros
        : isOpen === "strava"
        ? strava
        : isOpen === "healthKit"
        ? healthKit
        : {};

    const { currentActivity = {}, loadingActivity } = currentDevice || {};

    const pace = getPace(
      (currentActivity || {}).time,
      (currentActivity || {}).distance,
    );

    const matchedActivity = (activities || []).find((item = {}) => {
      const startDate = moment(item.start_date);
      const currentActivityDate = moment(
        (currentActivity || {}).date,
        "DD/MM/YYYY",
      );
      const sameDay = startDate.isSame(currentActivityDate, "day");
      const sameTitle =
        (item.activityName || item.name) === (currentActivity || {}).name;

      const sameDuration = item.durationInSeconds
        ? item.durationInSeconds === (currentActivity || {}).time
        : item.moving_time
        ? item.moving_time === (currentActivity || {}).time
        : true;

      return currentActivity && sameDay && sameTitle && sameDuration;
    });

    const date = matchedActivity
      ? matchedActivity.start_date_local
        ? moment(matchedActivity.start_date_local)
        : moment(matchedActivity.start_date)
      : moment(
          (currentActivity || {}).date || (currentActivity || {}).start_date,
        );

    const activityType =
      matchedActivity &&
      (matchedActivity.type
        ? matchedActivity.type
        : matchedActivity.activityType);
    const isCorrectType =
      activityType &&
      (activityType.toLowerCase().includes("run") ||
        activityType.toLowerCase().includes("walk") ||
        activityType.toLowerCase().includes("hik") ||
        activityType.toLowerCase().includes("hik"));

    const showEditForm = () => {
      const { showEdit } = this.state;
      this.setState({ showEdit: !showEdit });
    };

    const units = localStorage.getItem("units");

    let labels = [];
    let divider = 1;
    if (currentActivity && currentActivity.distance) {
      if (currentActivity.distance / 25 > 1) {
        divider = Math.round(currentActivity.distance / 25);
      }

      for (let index = 0; index < currentActivity.distance; index += divider) {
        labels.push(index);
      }
    }

    const elevationSamples =
      currentActivity && currentActivity.samples
        ? currentActivity.samples.map((s) => {
            return {
              x: s.distance,
              y: s.elevation,
            };
          })
        : [];

    const lapPaces = [];
    if (currentActivity && currentActivity.laps) {
      if (elevationSamples.length) {
        elevationSamples.push({
          x: 0,
          y: round(elevationSamples[0].y, 0),
        });
      }
      for (let index = 0; index < currentActivity.laps.length; index++) {
        const element = currentActivity.laps[index];
        lapPaces.push(element.pace.minutes + element.pace.seconds / 60);
      }
    }

    labels = [...labels, ...elevationSamples.map((e) => e.x)];

    labels.sort((a, b) => a - b);

    elevationSamples.sort((a, b) => a.x - b.x);

    const data = {
      labels,
      datasets: [
        {
          label: t("Elevation"),
          borderColor: "#1A629B",
          borderWidth: 1,
          backgroundColor: "rgba(63, 81, 181, 0.05)",
          pointBorderColor: "rgba(63, 81, 181, 0)",
          pointBackgroundColor: "#1A629B",
          data: elevationSamples,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          mode: "single",
          callbacks: {
            label: function(tooltipItem) {
              return `${round(+tooltipItem.formattedValue, 1)} ${
                units === "miles" ? t("feet") : t("m")
              }`;
            },
            title: function(context) {
              let title = context[0].formattedValue;
              return `${round(+title, 1)} ${
                units === "miles" ? t("miles") : t("km")
              }`;
            },
          },
        },
      },
      scales: {
        x: [
          {
            type: "linear",
            grid: {
              display: false,
              color: "#DDDDDD",
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              max:
                currentActivity && currentActivity.distance
                  ? currentActivity.distance
                  : 0,
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              callback: function(value, index, values) {
                return Number.isInteger(value)
                  ? currentActivity &&
                    currentActivity.distance &&
                    value >= Math.floor(currentActivity.distance)
                    ? `${value} ${
                        units === "miles" ? `${t("miles")}` : `${t("km")}`
                      }`
                    : value
                  : "";
              },
            },
          },
        ],
        y: [
          {
            grid: {
              drawBorder: false,
              color: "rgba(0, 0, 0, 0.05)",
              zeroLineColor: "rgba(0, 0, 0, 0.05)",
            },
            ticks: {
              fontFamily: "Avenir",
              fontSize: 12,
              color: "#9E9E9E",
            },
          },
        ],
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      animation: {
        easing: "linear",
        duration: 1000,
      },
    };

    const h =
      currentActivity && currentActivity.time
        ? Math.floor(currentActivity.time / 3600)
        : 0;
    const m =
      currentActivity && currentActivity.time
        ? Math.floor((currentActivity.time % 3600) / 60)
        : 0;
    const timeStr = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;

    const analysis =
      currentActivity &&
      currentActivity.samples &&
      currentActivity.samples.length > 1 ? (
        <div
          className={classes.chartParent}
          style={elevationMargin ? { marginTop: elevationMargin } : null}
        >
          <Typography variant="h3" className={classes.subtitle}>
            {t("Elevation profile")}
          </Typography>

          <div className={classes.graphContainer}>
            <Typography variant="h3" className={classes.graphElevationUnit}>
              {units === "miles" ? `${t("feet")}` : `${t("m")}`}
            </Typography>
            <Line data={data} options={options} />
          </div>
        </div>
      ) : (
        <></>
      );

    const isInMobileView = width === "sm" || width === "xs";

    const content = loadingActivity ? (
      <div className={classes.container}>
        {!asComponent && (
          <div
            className={
              asComponent ? classes.topContainerComponent : classes.topContainer
            }
          >
            <ArrowBackIosIcon
              onClick={() => onClose()}
              className={classes.backIcon}
              fontSize="small"
            />
          </div>
        )}

        <div className={classes.progressContainer}>
          <CircularProgress
            variant="indeterminate"
            color="primary"
            className={classes.loading}
            data-testid="LoadingActivity"
          />
        </div>
      </div>
    ) : currentActivity ? (
      <div
        className={classes.container}
        style={{
          padding: asComponent ? "11px 20px" : 0,
          boxSizing: "border-box",
        }}
      >
        <div
          className={
            asComponent ? classes.topContainerComponent : classes.topContainer
          }
        >
          {!asComponent ? (
            <ArrowBackIosIcon
              onClick={() => onClose()}
              className={classes.backIcon}
              fontSize="small"
            />
          ) : (
            ""
          )}

          {isInMobileView && isCorrectType && (
            <div className={classes.mountainParent}>
              <ActivityMountainIndex activity={currentActivity} />
            </div>
          )}

          {/* 
          {currentActivity.date && (
            <Typography
              variant="h3"
              className={classes.date}
              style={{
                marginLeft: asComponent ? 0 : 20,
              }}
            >
              {moment(currentActivity.date).format("DD/MM/YYYY - HH:mm")}
            </Typography>
          )} */}
          {this.state.emojiError && (
            <SnackBarMessageDisplay
              errorMessage="Only two reactions are allowed"
              variant="error"
              open={true}
            />
          )}

          {!asComponent ? (
            <img
              src={`${process.env.PUBLIC_URL}/icon-share-o.svg`}
              alt=""
              className={classes.iconShare}
              onClick={() => onOpenShare(isOpen)}
            />
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            display: asComponent ? "flex" : "block",
            maxHeight: asComponent ? customHeight || 300 : "initial",
          }}
        >
          <div
            style={{
              minWidth: asComponent ? 300 : "initial",
            }}
          >
            <div className={classes.leftParent} ref={this.divRef}>
              {currentActivity.name && (
                <div className={classes.headParent}>
                  {isCoach && (
                    <div className={classes.emojisContainer}>
                      <div className={classes.emojiSuggestion}>
                        {suggestedEmojies.map((item, index) => (
                          <div
                            className={classes.emojiChild}
                            onClick={() => this.handleEdit(item)}
                            key={index}
                          >
                            <Emoji unified={item} size="18" />
                          </div>
                        ))}

                        <div
                          className={classes.emojiChild}
                          onClick={this.handleOpen}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/add-emoji-icon.svg`}
                            alt="add-emoji-icon"
                          />
                        </div>
                      </div>

                      {showPicker && (
                        <div className={classes.picker}>
                          <EmojiPicker
                            width="fit-content"
                            previewConfig={{ showPreview: false }}
                            height="400px"
                            onEmojiClick={(emoji) =>
                              this.handleEdit(emoji.unified)
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}

                  <div className={classes.textsParent}>
                    <div className={classes.titleParent}>
                      <Typography variant="h3" className={classes.name}>
                        {currentActivity.name}
                      </Typography>
                      <div>
                        <Reactions
                          reactions={
                            (selectedWorkout.reaction &&
                              selectedWorkout.reaction.length &&
                              selectedWorkout.reaction) ||
                            tmpWorkout.reaction ||
                            this.checkReactions(date)
                          }
                          className={classes.reactionMargin}
                          handleDelete={
                            isCoach ? this.handleEmojiDelete : () => {}
                          }
                        />
                      </div>
                    </div>

                    <Typography className={classes.grayDate}>
                      {date.format("DD/MM/YYYY")} - {date.hour()}:
                      {date.minutes() <= 9
                        ? `0${date.minutes()}`
                        : date.minutes()}
                    </Typography>

                    {currentActivity.description && (
                      <Typography
                        variant="body2"
                        className={classes.descriptionText}
                      >
                        {currentActivity.description}
                      </Typography>
                    )}
                  </div>
                  {currentActivity && currentActivity.device == "vert.run" && (
                    <div
                      onClick={showEditForm}
                      className={classes.editIconParent}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/edit-icon.svg`}
                        alt="edit-icon"
                      />
                    </div>
                  )}
                </div>
              )}

              {this.state.showEdit && <EditForm onClose={showEditForm} />}

              <div className={classes.mainStatsContainer}>
                <div className={classes.mainStat}>
                  <div className={classes.mainStatValue}>
                    <Typography
                      variant="h3"
                      className={classes.mainStatValueNumber}
                    >
                      {isOpen === "coros"
                        ? round(currentActivity.distance / 1000, 1)
                        : round(currentActivity.distance, 1)}
                    </Typography>
                    <Typography
                      variant="h3"
                      className={classes.mainStatValueNumber}
                    ></Typography>
                    <Typography
                      variant="h3"
                      className={classes.mainStatValueUnit}
                    >
                      {units === "miles" ? `${t("miles")}` : `${t("km")}`}
                    </Typography>
                  </div>
                  <Typography variant="h3" className={classes.mainStatLabel}>
                    {t("distance")}
                  </Typography>
                </div>

                <div className={classes.mainStat}>
                  <div className={classes.mainStatValue}>
                    <Typography
                      variant="h3"
                      className={classes.mainStatValueNumber}
                    >
                      {round(currentActivity.elevation, 1)}
                    </Typography>
                    <Typography
                      variant="h3"
                      className={classes.mainStatValueUnit}
                    >
                      {units === "miles" ? `${t("feet")}` : `${t("m")}`}
                    </Typography>
                  </div>
                  <Typography variant="h3" className={classes.mainStatLabel}>
                    {t("elevation")}
                  </Typography>
                </div>
              </div>

              <div className={classes.stats}>
                <div className={classes.statsRow}>
                  <div className={classes.stat}>
                    <div className={classes.statValue}>
                      <Typography
                        variant="h3"
                        className={classes.statValueNumber}
                      >
                        {timeStr}
                      </Typography>
                    </div>
                    <Typography variant="h3" className={classes.statLabel}>
                      {t("time")}
                    </Typography>
                  </div>

                  <div className={classes.stat}>
                    <div className={classes.statValue}>
                      <Typography
                        variant="h3"
                        className={classes.statValueNumber}
                      >
                        {isOpen !== "coros"
                          ? currentActivity.pace
                            ? `${
                                currentActivity.pace.minutes
                              }:${(currentActivity.pace.seconds < 10
                                ? "0"
                                : "") + currentActivity.pace.seconds}`
                            : "00:00"
                          : `${pace.minutes}:${(pace.seconds < 10 ? "0" : "") +
                              pace.seconds}`}
                      </Typography>
                      <Typography
                        variant="h3"
                        className={classes.statValueUnit}
                      >
                        /{units === "miles" ? `${t("mile")}` : `${t("km")}`}
                      </Typography>
                    </div>
                    <Typography variant="h3" className={classes.statLabel}>
                      {t("avg pace")}
                    </Typography>
                  </div>
                </div>
                <div className={classes.statsRow}>
                  <div className={classes.stat}>
                    <div className={classes.statValue}>
                      <Typography
                        variant="h3"
                        className={classes.statValueNumber}
                      >
                        {round(currentActivity.uphillPace || 0, 0)}
                      </Typography>
                      <Typography
                        variant="h3"
                        className={classes.statValueUnit}
                      >
                        {units === "miles" ? `${t("feet/h")}` : `${t("m/h")}`}
                      </Typography>
                    </div>
                    <Typography variant="h3" className={classes.statLabel}>
                      {t("avg uphill speed")}
                    </Typography>
                  </div>

                  <div className={classes.stat}>
                    <div className={classes.statValue}>
                      <Typography
                        variant="h3"
                        className={classes.statValueNumber}
                      >
                        {round(currentActivity.downhillPace || 0, 0)}
                      </Typography>
                      <Typography
                        variant="h3"
                        className={classes.statValueUnit}
                      >
                        {units === "miles" ? `${t("feet/h")}` : `${t("m/h")}`}
                      </Typography>
                    </div>
                    <Typography variant="h3" className={classes.statLabel}>
                      {t("avg downhill speed")}
                    </Typography>
                  </div>
                </div>
                <div className={classes.statsRow}>
                  <div className={classes.stat}>
                    <div className={classes.statValue}>
                      <Typography
                        variant="h3"
                        className={classes.statValueNumber}
                      >
                        {currentActivity.hr && currentActivity.hr > 0
                          ? currentActivity.hr
                          : "-"}
                      </Typography>
                      {currentActivity.hr && currentActivity.hr > 0 ? (
                        <Typography
                          variant="h3"
                          className={classes.statValueUnit}
                        >
                          bmp
                        </Typography>
                      ) : (
                        ""
                      )}
                    </div>
                    <Typography variant="h3" className={classes.statLabel}>
                      {t("avg heart rate")}
                    </Typography>
                  </div>

                  <div className={classes.stat}>
                    <div className={classes.statValue}>
                      <Typography
                        variant="h3"
                        className={classes.statValueNumber}
                      >
                        {currentActivity.calories &&
                        currentActivity.calories > 0
                          ? currentActivity.calories
                          : "-"}
                      </Typography>
                    </div>
                    <Typography variant="h3" className={classes.statLabel}>
                      {t("calories")}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            {!asComponent ? analysis : ""}
          </div>

          {currentActivity &&
          currentActivity.laps &&
          currentActivity.laps.length > 0 &&
          (currentActivity.laps.length > 1 ||
            currentActivity.laps[0].distance > 0) ? (
            <div
              style={{
                overflow: asComponent ? "scroll" : "initial",
                border: !isInMobileView
                  ? "solid 1px rgba(0, 0, 0, 0.2)"
                  : "none  ",
                marginLeft: isInMobileView ? 0 : 10,
                marginTop: isInMobileView ? 40 : 0,
                padding: "10px",
                boxSizing: "border-box",
                height: isInMobileView
                  ? "initial"
                  : `${this.state.height}px` || 0,
                background: "#FFF",
              }}
            >
              {currentActivity &&
              currentActivity.laps &&
              currentActivity.laps.length > 0 &&
              (currentActivity.laps.length > 1 ||
                currentActivity.laps[0].distance > 0) ? (
                <Typography variant="h3" className={classes.subtitle}>
                  {t("Splits")}
                </Typography>
              ) : (
                ""
              )}

              {currentActivity &&
              currentActivity.laps &&
              currentActivity.laps.length > 0 &&
              (currentActivity.laps.length > 1 ||
                currentActivity.laps[0].distance > 0) ? (
                <table className={classes.table}>
                  <tr className={classes.header}>
                    <th>
                      <Typography variant="h3" className={classes.headerTH}>
                        {units === "miles" ? `${t("Mile")}` : `${t("Km")}`}
                      </Typography>
                    </th>
                    <th>
                      <Typography variant="h3" className={classes.headerTH}>
                        {t("Pace")}
                      </Typography>
                    </th>
                    {/*<th>
                <Typography variant="h3" className={classes.headerTH}>
                  {t("Total duration")}
                </Typography>
              </th>*/}
                    <th></th>

                    {/*<th>
                <Typography variant="h3" className={classes.headerTH}>
                  {t("HR")}
                </Typography>
              </th>*/}
                    {isOpen === "strava" ? (
                      <th>
                        <Typography variant="h3" className={classes.headerTH}>
                          {t("Elev")}
                        </Typography>
                      </th>
                    ) : (
                      ""
                    )}
                    {isOpen !== "strava" ? (
                      <th>
                        <Typography variant="h3" className={classes.headerTH}>
                          {t("Elev Gain")}
                        </Typography>
                      </th>
                    ) : (
                      ""
                    )}
                    {isOpen !== "strava" ? (
                      <th>
                        <Typography variant="h3" className={classes.headerTH}>
                          {t("Elev Loss")}
                        </Typography>
                      </th>
                    ) : (
                      ""
                    )}

                    <th>
                      <Typography variant="h3" className={classes.headerTH}>
                        {t("Avg Speed")} (
                        {units === "miles" ? `${t("miles/h")}` : `${t("km/h")}`}
                        )
                      </Typography>
                    </th>
                    <th>
                      <Typography variant="h3" className={classes.headerTH}>
                        {t("Vert Speed")} (
                        {units === "miles" ? `${t("feet/h")}` : `${t("m/h")}`})
                      </Typography>
                    </th>
                  </tr>
                  {currentActivity
                    ? currentActivity.laps.map((l, i) => {
                        const maxPace = Math.max(...lapPaces);

                        return l.distance >= 0.1 ? (
                          <tr key={l.distance}>
                            <td>
                              <Typography variant="h3" className={classes.th}>
                                {Math.round(l.distance) > 0
                                  ? i + 1
                                  : round(l.distance, 1)}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                variant="h3"
                                className={classes.th}
                              >{`${l.pace.minutes}:${(l.pace.seconds < 10
                                ? "0"
                                : "") + l.pace.seconds}`}</Typography>
                            </td>
                            <td className={classes.barTH}>
                              <LinearProgress
                                variant="determinate"
                                value={Math.min(
                                  100,
                                  lapPaces[i] && maxPace
                                    ? (lapPaces[i] * 100) / maxPace
                                    : 0,
                                )}
                                classes={{
                                  root: classes.progressRoot,
                                  bar: classes.progressBar,
                                }}
                              />
                            </td>
                            {/*<td>
                        <Typography variant="h3" className={classes.th}>
                          {new Date(l.accTime * 1000)
                            .toISOString()
                            .substr(11, 8)}
                        </Typography>
                      </td>*/}
                            {/*<td>
                        <Typography variant="h3" className={classes.th}>
                          {l.hr ? Math.round(l.hr) : "-"}
                        </Typography>
                      </td>*/}

                            {isOpen === "strava" ? (
                              <th>
                                <Typography variant="h3" className={classes.th}>
                                  {Math.round(l.elevation)}
                                </Typography>
                              </th>
                            ) : (
                              ""
                            )}
                            {isOpen !== "strava" ? (
                              <th>
                                <Typography variant="h3" className={classes.th}>
                                  {Math.round(l.accUp)}
                                </Typography>
                              </th>
                            ) : (
                              ""
                            )}
                            {isOpen !== "strava" ? (
                              <th>
                                <Typography variant="h3" className={classes.th}>
                                  {Math.round(l.accDown)}
                                </Typography>
                              </th>
                            ) : (
                              ""
                            )}

                            {isOpen !== "coros" ? (
                              <td>
                                <Typography variant="h3" className={classes.th}>
                                  {round(1 / (l.time / 3600), 2)}
                                </Typography>
                              </td>
                            ) : (
                              <td>
                                <Typography variant="h3" className={classes.th}>
                                  {round(l.speed, 2)}
                                </Typography>
                              </td>
                            )}

                            {isOpen !== "coros" ? (
                              <td>
                                <Typography variant="h3" className={classes.th}>
                                  {Math.round(
                                    Math.round(l.elevation) / (l.time / 3600),
                                  )}
                                </Typography>
                              </td>
                            ) : (
                              <td>
                                <Typography variant="h3" className={classes.th}>
                                  {Math.round(l.vertSpeed)}
                                </Typography>
                              </td>
                            )}
                          </tr>
                        ) : (
                          ""
                        );
                      })
                    : ""}
                </table>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        {asComponent ? analysis : ""}
      </div>
    ) : (
      <div className={classes.container}>
        {!asComponent && (
          <div
            className={
              asComponent ? classes.topContainerComponent : classes.topContainer
            }
          >
            <ArrowBackIosIcon
              onClick={() => onClose()}
              className={classes.backIcon}
              fontSize="small"
            />
          </div>
        )}

        <div className={classes.progressContainer}>
          <Typography variant="h3" className={classes.headerTH}>
            {t("Error loading activity details")}
          </Typography>
        </div>
      </div>
    );

    return asComponent ? (
      <>{content}</>
    ) : (
      <Dialog
        open={!!isOpen}
        onClose={onClose}
        maxWidth={"xl"}
        fullScreen={true}
        style={{
          paddingLeft: "env(safe-area-inset-left)",
          paddingRight: "env(safe-area-inset-right)",
          paddingTop: "env(safe-area-inset-top)",
          paddingBottom: "env(safe-area-inset-bottom)",
        }}
      >
        <DialogContent className={classes.noPadding}>{content}</DialogContent>
      </Dialog>
    );
  }
}

DashboardActivityDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  fullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  userPlans: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect((store) => {
  return {
    strava: store.strava,
    garmin: store.garmin,
    suunto: store.suunto,
    vertActivities: store.vertActivities,
    userPlans: store.userPlans,
    athletes: store.athletes,
    healthKit: store.healthKit,
    coros: store.coros,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation("dashboard")(
      withWidth()(withMobileDialog()(memo(DashboardActivityDetails))),
    ),
  ),
);
