import ActionTypes from "../constants/action-types";

const initialState = {
  searchData: [],
  loading: false,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.GENERAL_SEARCH_PENDING:
        return {
          ...state,
          loading: true,
        };

      case ActionTypes.GENERAL_SEARCH_FULLFILED:
        return {
          ...state,
          loading: false,
          searchData: action.payload,
        };

      case ActionTypes.GENERAL_SEARCH_REJECTED:
        return {
          ...state,
          loading: false,
          searchData: [],
        };

      default:
        return state;
    }
  }
}
