import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Avatar, makeStyles } from "@material-ui/core";
import { getLevel } from "../../lib/rates-helper";
import { getImage } from "../../lib/common";
import { useCapacitorStorage } from "../../hooks/useCapacitorStorage";
import RoundBadge from "../common/v2/RoundBadge";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useCalculatorMI from "../../hooks/useCalculatorMI";
import CustomErrorBoundary from "../common/error/CustomErrorBoundary";

const SmallAvatar = ({
  level = true,
  onTrailHead = true,
  customSize = null,
  showMountainIndex = false,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [currentPath, setCurrentPath] = useState(null);
  const capacitorStorage = useCapacitorStorage();
  const [avatar, setAvatar] = useState(null);
  const { average, getColorByAverageValue } = useCalculatorMI();
  const { selectedAthlete } = useSelector((state) => state.trailHead);
  const { image, accumulatedDistance = 0, accumulatedElevation = 0 } =
    selectedAthlete || {};
  const { currentPlan } = useSelector((state) => state.userPlans);
  const {
    image: profileImage,
    accumulatedDistance: currentUserDist,
    accumulatedElevation: currentUserElevation,
  } = currentPlan || {};

  const userLevel = getLevel(accumulatedDistance, accumulatedElevation);
  const currentUserLevel = getLevel(currentUserDist, currentUserElevation);
  capacitorStorage.getItem("profile_image").then((image) => setAvatar(image));

  const setImage = async (image) => {
    try {
      const result = await getImage(image);
      setAvatar(image);
      await capacitorStorage.setItem("profile_image", result);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (image && onTrailHead) setImage(image);
    if (profileImage && !onTrailHead) setImage(profileImage);
  }, [image, profileImage]);

  useEffect(() => {
    if (pathname) setCurrentPath(pathname.split("/")[1]);
  }, [pathname]);

  return (
    <CustomErrorBoundary>
      <div className={classes.parent}>
        {showMountainIndex && (
          <Link
            id="profile_menu"
            to={`/${currentPath}/mountain-index`}
            className={classes.parent}
          >
            <Avatar
              className={classes.avatar}
              style={customSize && { width: customSize, height: customSize }}
              src={avatar || `${process.env.PUBLIC_URL}/avatar-empty.png`}
            />
            <RoundBadge
              color={getColorByAverageValue(average)}
              width={customSize ? customSize + 5 : 45}
              height={customSize ? customSize + 5 : 45}
              number={average}
            />
          </Link>
        )}
        {level && (
          <>
            <Avatar
              className={classes.avatar}
              style={customSize && { width: customSize, height: customSize }}
              src={avatar || `${process.env.PUBLIC_URL}/avatar-empty.png`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/trl${
                selectedAthlete ? userLevel : currentUserLevel
              }-sm.svg`}
              className={classes.levelBadge}
            />
          </>
        )}
        {!level && !showMountainIndex && (
          <Avatar
            className={classes.avatar}
            style={customSize && { width: customSize, height: customSize }}
            src={avatar || `${process.env.PUBLIC_URL}/avatar-empty.png`}
          />
        )}
      </div>
    </CustomErrorBoundary>
  );
};

export default SmallAvatar;

const useStyles = makeStyles({
  avatar: {
    position: "relative",
    width: "38px",
    height: "38px",
    zIndex: 2,
  },
  levelBadge: {
    width: 25,
    right: "-10px",
    bottom: "-5px",
    height: "25px",
    position: "absolute",
  },
  parent: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
