import moment from "moment";

import ActionTypes from "../constants/action-types";
import { dateFormat } from "../lib/date-helper";

const initialState = {
  loading: false,
  latestFilterApplied: { searchPhrase: "" },
  currentFilter: { searchPhrase: "" },
  hasBeenSearched: false,
  searchResult: {
    data: [],
    order: "asc",
    orderBy: "name",
    selected: [],
    rowsPerPage: 10,
    page: 0,
  },
  athletesCount: 0,
  totalUnreadMessages: 0,
  coachesLoading: false,
  coachesList: [],
  comments: [],
  loadingComments: false,
  loadingOldComments: null,
  canLoadMoreComments: true,
  selectedAthleteChainedPlans: [],
  recentData: [],
  loadingRecentData: false,
  data: [],
  loadingData: false,
  events: [],
  loadingEvents: false,
  loadingChecked: false,
  loadingNeedsToCheck: false,
  sendingBulkMessage: false,
  loadingNeedsHelp: false,
  signInType: null,
  signInLoading: false,
  strengthCoaches: [],
  loadingStrengthAssign: false,
  saveLoading: false,
  editingLoading: false,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.ATHLETES_SEARCH_PHRASE_CHANGED:
        return {
          ...state,
          currentFilter: {
            ...state.currentFilter,
            searchPhrase: action.payload || "",
            exclusiveStartKey: null,
          },
          searchResult: {
            ...state.searchResult,
            data: [],
            page: 0,
            visitedPages: 0,
          },
        };
      case ActionTypes.ATHLETES_SEARCH_FILTER_CHANGED:
        const newFilter = {
          ...state.currentFilter,
          ...action.payload,
          exclusiveStartKey: action.payload.coachSub
            ? null
            : state.currentFilter.exclusiveStartKey,
        };

        const newData = action.payload.coachSub ? [] : state.searchResult.data;

        return {
          ...state,
          currentFilter: newFilter,
          searchResult: {
            ...state.searchResult,
            data: newData,
            page: 0,
            visitedPages: 0,
          },
        };
      case ActionTypes.ATHLETES_ADD_VISITED_PAGE:
        return {
          ...state,
          searchResult: {
            ...state.searchResult,
            visitedPages: action.payload,
          },
        };

      case ActionTypes.ATHLETES_SORT_CHANGED:
        return {
          ...state,
          searchResult: {
            ...state.searchResult,
            ...action.payload,
          },
        };

      case ActionTypes.ATHLETES_SEARCH_PENDING:
        return {
          ...state,
          loading: true,
          errorMessage: undefined,
        };
      case ActionTypes.ATHLETES_SEARCH_FULFILLED:
        const existingData = state.searchResult.data;
        const newAthleteData = action.payload.users || [];

        const uniqueData = newAthleteData.filter((newUser) => {
          return !existingData.some(
            (existingUser) =>
              existingUser.cognito_user_sub === newUser.cognito_user_sub,
          );
        });

        return {
          ...state,
          loading: false,
          currentFilter: {
            ...state.currentFilter,
            exclusiveStartKey: action.payload.nextExclusiveStartKey || null,
          },
          searchResult: {
            ...state.searchResult,
            data: existingData.concat(uniqueData),
            rowsPerPage: action.payload.itemsPerPage
              ? parseInt(action.payload.itemsPerPage)
              : 10,
          },
          selectedAthlete: {
            ...state.selectedAthlete,
          },
          latestFilterApplied: { ...state.currentFilter },
          hasBeenSearched: true,
        };

      case ActionTypes.ATHLETES_SEARCH_REJECTED:
        return {
          ...state,
          loading: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.ATHLETES_CLEAN_SEARCH:
        return { ...initialState, coachesList: state.coachesList };
      case ActionTypes.ATHLETES_GET_COUNT_PENDING:
        return {
          ...state,
          errorMessage: undefined,
          counting: true,
        };
      case ActionTypes.ATHLETES_GET_COUNT_FULFILLED:
        return {
          ...state,
          counting: false,
          athletesCount: action.payload.result,
          totalUnreadMessages: action.payload.totalUnreadMessages || 0,
        };
      case ActionTypes.ATHLETES_GET_COUNT_REJECTED:
        return {
          ...state,
          counting: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.ATHLETES_SELECT_FULFILLED:
        const dataCopy = [...state.searchResult.data];
        for (let i = 0; i < dataCopy.length; i++) {
          if (
            dataCopy[i].cognito_user_sub ===
            action.payload.result.cognito_user_sub
          ) {
            dataCopy[i] = { ...dataCopy[i], ...action.payload.result };
          }
        }
        return {
          ...state,
          selectedAthlete: action.payload.result,
          searchResult: {
            ...state.searchResult,
            data: dataCopy,
          },
          selectedAthleteChainedPlans: [],
        };
      case ActionTypes.ATHLETES_SELECT_WORKOUT:
        return {
          ...state,
          selectedAthlete: {
            ...state.selectedAthlete,
            selectedWorkout: JSON.parse(JSON.stringify(action.payload)),
          },
        };
      case ActionTypes.ATHLETES_LOAD_COACHES_PENDING:
        return {
          ...state,
          errorMessage: undefined,
          coachesLoading: true,
        };
      case ActionTypes.ATHLETES_LOAD_COACHES_FULFILLED:
        let coachesList = [];
        action.payload.forEach((coach) => {
          const { Attributes = {} } = coach;
          const email = Attributes.find((item) => item.Name === "email").Value;
          const sub = Attributes.find((item) => item.Name === "sub").Value;
          const name = Attributes.find((item) => item.Name === "name").Value;
          const family_name = Attributes.find(
            (item) => item.Name === "family_name",
          );
          coachesList = [
            ...coachesList,
            {
              value: sub,
              label:
                `${name}${family_name ? ` ${family_name.Value}` : ""}` || email,
            },
          ];
        });
        return {
          ...state,
          coachesLoading: false,
          coachesList: coachesList,
        };
      case ActionTypes.ATHLETES_LOAD_COACHES_REJECTED:
        return {
          ...state,
          coachesLoading: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.ATHLETES_ASSIGN_COACH_PENDING:
        return {
          ...state,
          errorMessage: undefined,
          assigningCoach: true,
        };
      case ActionTypes.ATHLETES_ASSIGN_COACH_FULFILLED:
        let assignedAthlete = action.payload;
        const { selectedAthlete } = state;
        const { statistic, stravaActivities, imageUrl } = selectedAthlete || {};
        if (statistic) {
          assignedAthlete = {
            ...assignedAthlete,
            statistic: statistic,
          };
        }
        if (stravaActivities) {
          assignedAthlete = {
            ...assignedAthlete,
            stravaActivities: stravaActivities,
          };
        }
        if (imageUrl) {
          assignedAthlete = {
            ...assignedAthlete,
            imageUrl: imageUrl,
          };
        }
        const { cognito_user_sub } = assignedAthlete;
        let newUserPlans = [...state.searchResult.data];
        if (cognito_user_sub) {
          const foundIndex = state.searchResult.data.findIndex(
            (userPlan) => userPlan.cognito_user_sub === cognito_user_sub,
          );
          if (foundIndex > -1) {
            newUserPlans.splice(foundIndex, 1, assignedAthlete);
          }
        }

        return {
          ...state,
          assigningCoach: false,
          selectedAthlete: {
            ...assignedAthlete,
            currentTraining: state.selectedAthlete.currentTraining,
          },
          searchResult: {
            ...state.searchResult,
            data: newUserPlans,
          },
        };
      case ActionTypes.ATHLETES_ASSIGN_COACH_REJECTED:
        return {
          ...state,
          assigningCoach: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.ATHLETES_GET_OVERALL_WORKOUT_COUNT_PENDING:
        return {
          ...state,
          countingAthleteWorkouts: true,
          errorMessage: undefined,
        };
      case ActionTypes.ATHLETES_GET_OVERALL_WORKOUT_COUNT_FULFILLED:
        let updatedUserPlans = [...state.searchResult.data];
        const foundSelectedIndex = updatedUserPlans.findIndex(
          (userPlan) =>
            userPlan.cognito_user_sub === action.payload.cognito_user_sub,
        );
        if (foundSelectedIndex > -1) {
          updatedUserPlans.splice(foundSelectedIndex, 1, {
            ...updatedUserPlans[foundSelectedIndex],
            statistic: {
              overallNotDoneWorkoutsCount:
                action.payload.statistic.totalMissedWorkout,
              overallWorkoutsCount: action.payload.statistic.totalWorkout,
            },
          });
        }
        let newSelectedAthlete = state.selectedAthlete;
        if (
          newSelectedAthlete &&
          newSelectedAthlete.cognito_user_sub ===
            action.payload.cognito_user_sub
        ) {
          newSelectedAthlete = {
            ...newSelectedAthlete,
            statistic: {
              overallNotDoneWorkoutsCount:
                action.payload.statistic.totalMissedWorkout,
              overallWorkoutsCount: action.payload.statistic.totalWorkout,
            },
          };
        }
        return {
          ...state,
          countingAthleteWorkouts: false,
          searchResult: {
            ...state.searchResult,
            data: updatedUserPlans,
          },
          selectedAthlete: newSelectedAthlete,
        };
      case ActionTypes.ATHLETES_GET_OVERALL_WORKOUT_COUNT_REJECTED:
        return {
          ...state,
          countingAthleteWorkouts: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.ATHLETES_ASSIGN_TRAINING_PENDING:
      case ActionTypes.ATHLETES_CHAIN_TRAINING_PENDING:
        return {
          ...state,
          errorMessage: undefined,
          assigningTraining: true,
        };
      case ActionTypes.ATHLETES_ASSIGN_TRAINING_FULFILLED:
      case ActionTypes.ATHLETES_CHAIN_TRAINING_FULFILLED:
        let returnedAthlete = action.payload;
        const { cognito_user_sub: assignedAthleteUserSub } = returnedAthlete;
        let newUserPlansAfterAssign = [...state.searchResult.data];
        if (assignedAthleteUserSub) {
          const foundIndex = state.searchResult.data.findIndex(
            (userPlan) => userPlan.cognito_user_sub === assignedAthleteUserSub,
          );
          if (foundIndex > -1) {
            newUserPlansAfterAssign.splice(foundIndex, 1, {
              ...newUserPlansAfterAssign[foundIndex],
              ...returnedAthlete,
            });
          }
        }
        return {
          ...state,
          assigningTraining: false,
          selectedAthlete: returnedAthlete,
          selectedAthleteChainedPlans: [],
          searchResult: {
            ...state.searchResult,
            data: newUserPlansAfterAssign,
          },
          successMessage: "Training was assigned successfully",
        };
      case ActionTypes.ATHLETES_ASSIGN_TRAINING_REJECTED:
      case ActionTypes.ATHLETES_CHAIN_TRAINING_REJECTED:
        return {
          ...state,
          assigningTraining: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.ATHLETES_TRAINING_WEEK_CHANGED:
        return {
          ...state,
          selectedAthlete: {
            ...state.selectedAthlete,
            currentWeek: action.payload,
          },
        };
      case ActionTypes.ATHLETES_TRAINING_SELECT_WORKOUT:
        return {
          ...state,
          selectedAthlete: {
            ...state.selectedAthlete,
            selectedWorkout: action.payload,
          },
        };
      case ActionTypes.ATHLETES_TRAINING_ADD_WORKOUT:
        const newDays = [...state.selectedAthlete.currentTraining.days];
        let foundIndex = -1;
        if (
          newDays[0] &&
          moment(newDays[0][0].plannedDate, dateFormat).isSameOrAfter(
            moment(action.payload),
          )
        )
          foundIndex = 0;
        else {
          if (
            newDays.length ===
            parseInt(state.selectedAthlete.currentTraining.durationInWeeks) * 7
          )
            newDays.map((day, index) => {
              if (
                moment(
                  state.selectedAthlete.currentTraining.startingDate,
                  dateFormat,
                )
                  .add("days", index)
                  .isSame(moment(action.payload))
              )
                foundIndex = index;
              return index;
            });
          else {
            const foundDayIndex = newDays.findIndex(
              (day) =>
                day &&
                moment(day[0].plannedDate, dateFormat).isSameOrAfter(
                  moment(action.payload),
                ),
            );
            if (foundDayIndex > -1) foundIndex = foundDayIndex;
            else foundIndex = newDays.length;
          }
        }
        const tempWorkout = {
          ...state.selectedAthlete.selectedWorkout,
          plannedDate: moment(action.payload).format(dateFormat),
          trainingTime: "AllDay",
        };
        if (
          newDays.length ===
            parseInt(state.selectedAthlete.currentTraining.durationInWeeks) *
              7 &&
          (typeof newDays[foundIndex] === typeof null ||
            typeof newDays[foundIndex] === typeof undefined)
        )
          newDays.splice(foundIndex, 1, [tempWorkout]);
        else newDays.splice(foundIndex, 0, [tempWorkout]);
        return {
          ...state,
          selectedAthlete: {
            ...state.selectedAthlete,
            currentTraining: {
              ...state.selectedAthlete.currentTraining,
              days: newDays,
            },
            selectedWorkout: undefined,
          },
        };

      case ActionTypes.ATHLETES_TRAINING_SAVE_PENDING:
      case ActionTypes.ATHLETES_TRAINING_WORKOUT_EDIT_PENDING:
      case ActionTypes.ATHLETES_TRAINING_WORKOUT_ADD_PENDING:
      case ActionTypes.ATHLETES_TRAINING_WORKOUT_DELETE_PENDING:
      case ActionTypes.ATHLETES_TRAINING_SWAP_PENDING:
      case ActionTypes.ATHLETES_TRAINING_VIEWED_SAVE_PENDING:
      case ActionTypes.ATHLETES_TRAINING_WEEK_ADD_PENDING:
      case ActionTypes.ATHLETES_TRAINING_WEEK_REMOVE_PENDING:
      case ActionTypes.ATHLETES_TRAINING_CHAINED_REMOVE_PENDING:
      case ActionTypes.ATHLETES_TRAINING_WEEK_EASY_ADD_PENDING:
      case ActionTypes.ATHLETES_TRAINING_BLOCK_ADD_PENDING:
      case ActionTypes.ATHLETES_TRAINING_RACES_SAVE_PENDING:
        return {
          ...state,
          errorMessage: undefined,
          savingTraining: true,
        };
      case ActionTypes.ATHLETES_TRAINING_SAVE_FULFILLED:
      case ActionTypes.ATHLETES_TRAINING_WORKOUT_EDIT_FULFILLED:
      case ActionTypes.ATHLETES_TRAINING_WORKOUT_ADD_FULFILLED:
      case ActionTypes.ATHLETES_TRAINING_WORKOUT_DELETE_FULFILLED:
      case ActionTypes.ATHLETES_TRAINING_SWAP_FULFILLED:
      case ActionTypes.ATHLETES_TRAINING_WEEK_ADD_FULFILLED:
      case ActionTypes.ATHLETES_TRAINING_WEEK_REMOVE_FULFILLED:
      case ActionTypes.ATHLETES_TRAINING_CHAINED_REMOVE_FULFILLED:
      case ActionTypes.ATHLETES_TRAINING_WEEK_EASY_ADD_FULFILLED:
      case ActionTypes.ATHLETES_TRAINING_BLOCK_ADD_FULFILLED:
        return {
          ...state,
          savingTraining: false,
          selectedAthlete: {
            ...state.selectedAthlete,
            ...action.payload,
          },
        };

      case ActionTypes.ATHLETES_TRAINING_VIEWED_SAVE_FULFILLED:
      case ActionTypes.ATHLETES_TRAINING_RACES_SAVE_FULFILLED:
        let returnedAthleteToSave = action.payload;
        const {
          cognito_user_sub: returnedAthleteUserSub,
          updated_at,
        } = returnedAthleteToSave;

        let newUserPlansAfterSave = state.searchResult.data.map((userPlan) => {
          if (userPlan.cognito_user_sub === returnedAthleteUserSub) {
            return { ...userPlan, ...returnedAthleteToSave };
          } else {
            return userPlan;
          }
        });

        if (state.selectedAthlete && state.selectedAthlete.selectedWorkout) {
          returnedAthleteToSave.selectedWorkout =
            state.selectedAthlete.selectedWorkout;
        }
        if (state.selectedAthlete && state.selectedAthlete.currentWeek) {
          returnedAthleteToSave.currentWeek = state.selectedAthlete.currentWeek;
        }
        if (state.selectedAthlete && state.selectedAthlete.subscription) {
          returnedAthleteToSave.subscription =
            state.selectedAthlete.subscription;
        }
        if (state.selectedAthlete && state.selectedAthlete.stravaActivities) {
          returnedAthleteToSave.stravaActivities =
            state.selectedAthlete.stravaActivities;
        }

        if (state.selectedAthlete) {
          returnedAthleteToSave.currentTraining =
            state.selectedAthlete.currentTraining;
        }

        return {
          ...state,
          savingTraining: false,
          selectedAthlete:
            updated_at === "latest"
              ? returnedAthleteToSave
              : state.selectedAthlete,
          searchResult: {
            ...state.searchResult,
            data:
              updated_at === "latest"
                ? newUserPlansAfterSave
                : state.searchResult.data,
            chainedPlans:
              action.type ===
              ActionTypes.ATHLETES_TRAINING_CHAINED_REMOVE_FULFILLED
                ? []
                : state.chainedPlans,
          },
        };

      case ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENTS_READ_SAVE_FULFILLED:
        let newUserPlansAfterSaveRead = [...state.searchResult.data];
        let newTotalUnreadMessages = state.totalUnreadMessages;

        if (
          action.payload &&
          action.payload.Items &&
          action.payload.Items.length
        ) {
          const foundIndex = state.searchResult.data.findIndex(
            (userPlan) =>
              userPlan.cognito_user_sub ===
              action.payload.Items[0].cognito_user_sub,
          );
          if (foundIndex > -1) {
            newTotalUnreadMessages =
              newTotalUnreadMessages > 0
                ? newTotalUnreadMessages -
                  newUserPlansAfterSaveRead[foundIndex].unread
                : 0;
            newUserPlansAfterSaveRead[foundIndex].unread = 0;
          }
        }

        return {
          ...state,
          totalUnreadMessages: newTotalUnreadMessages,
          searchResult: {
            ...state.searchResult,
            data: newUserPlansAfterSaveRead,
          },
          comments:
            action.payload && action.payload.Items ? action.payload.Items : [],
        };

      case ActionTypes.ATHLETES_TRAINING_SAVE_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_WORKOUT_EDIT_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_WORKOUT_ADD_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_WORKOUT_DELETE_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_SWAP_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_VIEWED_SAVE_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_WEEK_ADD_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_WEEK_REMOVE_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_CHAINED_REMOVE_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_WEEK_EASY_ADD_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_BLOCK_ADD_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_RACES_SAVE_REJECTED:
        return {
          ...state,
          savingTraining: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_SAVE_PENDING:
      case ActionTypes.ATHLETES_TRAINING_COMMENTS_PENDING:
        return {
          ...state,
          loadingComments: true,
          comments: action.payload.init ? [] : state.comments,
        };

      case ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_SAVE_FULFILLED:
      case ActionTypes.ATHLETES_TRAINING_COMMENTS_FULFILLED:
        return {
          ...state,
          loadingComments: false,
          comments:
            action.payload && action.payload.Items ? action.payload.Items : [],
        };

      case ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_SAVE_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_DELETE_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_EDIT_REJECTED:
      case ActionTypes.ATHLETES_TRAINING_COMMENTS_REJECTED:
        return {
          ...state,
          loadingComments: false,
          errorMessage: "Error loading comments",
        };

      case ActionTypes.ATHLETES_TRAINING_GET_OLD_COMMENTS_PENDING:
        return {
          ...state,
          loadingOldComments: true,
        };

      case ActionTypes.ATHLETES_TRAINING_GET_OLD_COMMENTS_FULFILLED:
        return {
          ...state,
          canLoadMoreComments:
            action.payload.Items && action.payload.Items.length > 0,
          comments:
            action.payload && action.payload.Items
              ? [...action.payload.Items, ...state.comments]
              : state.comments,
          loadingOldComments: false,
        };

      case ActionTypes.ATHLETES_TRAINING_GET_OLD_COMMENTS_REJECTED:
        return {
          ...state,
          loadingOldComments: false,
          errorMessage: "Error loading comments",
        };

      case ActionTypes.ATHLETES_TRAINING_BULK_MESSAGE_SAVE_PENDING:
        return {
          ...state,
          sendingBulkMessage: true,
          successMessage: "Successfully sent bulk message",
        };

      case ActionTypes.ATHLETES_TRAINING_BULK_MESSAGE_SAVE_FULFILLED:
        return {
          ...state,
          sendingBulkMessage: false,
          successMessage: "Successfully sent bulk message",
        };

      case ActionTypes.ATHLETES_TRAINING_BULK_MESSAGE_SAVE_REJECTED:
        return {
          ...state,
          sendingBulkMessage: false,
          errorMessage: "Error loading comments",
        };

      case ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_DELETE_FULFILLED:
        return {
          ...state,
          loadingComments: false,
          comments: state.comments.filter((c) => c.id !== action.payload.id),
        };

      case ActionTypes.ATHLETES_SET_ERROR_MESSAGE:
        return {
          ...state,
          errorMessage: action.payload,
        };
      case ActionTypes.ATHLETES_CLEAR_ERROR_MESSAGE:
        return {
          ...state,
          errorMessage: undefined,
        };
      case ActionTypes.ATHLETES_SET_SUCCESS_MESSAGE:
        return {
          ...state,
          successMessage: action.payload,
        };
      case ActionTypes.ATHLETES_CLEAR_SUCCESS_MESSAGE:
        return {
          ...state,
          successMessage: undefined,
        };
      case ActionTypes.ATHLETES_LOAD_ACTIVITIES_PENDING:
        return {
          ...state,
          loadingActivities: true,
          errorMessage: undefined,
        };

      case ActionTypes.ATHLETES_LOAD_ACTIVITIES_FULFILLED:
        let updatedUserPlansForActivities = [...state.searchResult.data];
        let newSelectedAthleteForActivities = state.selectedAthlete;
        if (action.payload.result && action.payload.result.length > 0) {
          const foundSelectedIndexForActivities = updatedUserPlansForActivities.findIndex(
            (userPlan) =>
              (userPlan.strava_athlete_id &&
                action.payload.strava_athlete_id &&
                userPlan.strava_athlete_id ===
                  action.payload.strava_athlete_id) ||
              (userPlan.garmin_oauth_token &&
                action.payload.garmin_oauth_token &&
                userPlan.garmin_oauth_token ===
                  action.payload.garmin_oauth_token) ||
              (userPlan.suunto_auth &&
                userPlan.suunto_auth.access_token &&
                action.payload.access_token &&
                userPlan.suunto_auth.access_token ===
                  action.payload.access_token) ||
              (userPlan.suunto_auth &&
                userPlan.suunto_auth.access_token &&
                action.payload.access_token &&
                userPlan.suunto_auth.access_token ===
                  action.payload.access_token),
          );
          if (foundSelectedIndexForActivities > -1) {
            updatedUserPlansForActivities.splice(
              foundSelectedIndexForActivities,
              1,
              {
                ...updatedUserPlansForActivities[
                  foundSelectedIndexForActivities
                ],
                stravaActivities: action.payload.result,
              },
            );
          }
          if (
            (newSelectedAthleteForActivities &&
              newSelectedAthleteForActivities.strava_athlete_id &&
              action.payload.strava_athlete_id &&
              newSelectedAthleteForActivities.strava_athlete_id ===
                action.payload.strava_athlete_id) ||
            (newSelectedAthleteForActivities &&
              newSelectedAthleteForActivities.garmin_oauth_token &&
              action.payload.garmin_oauth_token &&
              newSelectedAthleteForActivities.garmin_oauth_token ===
                action.payload.garmin_oauth_token) ||
            (newSelectedAthleteForActivities.suunto_auth &&
              newSelectedAthleteForActivities.suunto_auth.access_token &&
              action.payload.access_token &&
              newSelectedAthleteForActivities.suunto_auth.access_token ===
                action.payload.access_token)
          ) {
            newSelectedAthleteForActivities = {
              ...newSelectedAthleteForActivities,
              stravaActivities: action.payload.result,
            };
          }
        }
        return {
          ...state,
          loadingActivities: false,
          searchResult: {
            ...state.searchResult,
            data: updatedUserPlansForActivities,
          },
          selectedAthlete: newSelectedAthleteForActivities,
        };
      case ActionTypes.ATHLETES_LOAD_ACTIVITIES_REJECTED:
        return {
          ...state,
          loadingActivities: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.ATHLETES_TRAINING_PLAN_PENDING:
        return {
          ...state,
          savingTraining: true,
        };

      case ActionTypes.ATHLETES_TRAINING_PLAN_FULFILLED:
        let newSelectedAthleteForChainedPlan = state.selectedAthlete;
        let newChainedPlans = state.selectedAthleteChainedPlans;
        if (newSelectedAthleteForChainedPlan.chainedPlans) {
          if (
            state.selectedAthleteChainedPlans.length !==
            newSelectedAthleteForChainedPlan.chainedPlans.length
          ) {
            newChainedPlans = [
              ...newSelectedAthleteForChainedPlan.chainedPlans,
            ];
          }
          const slotIndex = newSelectedAthleteForChainedPlan.chainedPlans.findIndex(
            (c) => c === action.payload.updated_at,
          );
          if (slotIndex > -1) {
            newChainedPlans[slotIndex] = action.payload.currentTraining;
          }
        }
        return {
          ...state,
          selectedAthleteChainedPlans: newChainedPlans,
          savingTraining: false,
        };

      case ActionTypes.ATHLETES_TRAINING_PLAN_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          savingTraining: false,
        };

      case ActionTypes.ATHLETES_LOAD_IMAGE_URL_PENDING:
        return {
          ...state,
          loadingAthleteImageUrl: true,
          errorMessage: undefined,
        };
      case ActionTypes.ATHLETES_LOAD_IMAGE_URL_FULFILLED:
        let updatedUserPlansForImageUrl = [...state.searchResult.data];
        const foundSelectedIndexForImageUrl = updatedUserPlansForImageUrl.findIndex(
          (userPlan) =>
            userPlan.cognito_user_sub === action.payload.cognito_user_sub,
        );
        if (foundSelectedIndexForImageUrl > -1) {
          updatedUserPlansForImageUrl.splice(foundSelectedIndexForImageUrl, 1, {
            ...updatedUserPlansForImageUrl[foundSelectedIndexForImageUrl],
            imageUrl: action.payload.imageUrl,
          });
        }
        let newSelectedAthleteForImageUrl = state.selectedAthlete;
        if (
          newSelectedAthleteForImageUrl &&
          newSelectedAthleteForImageUrl.cognito_user_sub ===
            action.payload.cognito_user_sub
        ) {
          newSelectedAthleteForImageUrl = {
            ...newSelectedAthleteForImageUrl,
            imageUrl: action.payload.imageUrl,
          };
        }
        return {
          ...state,
          loadingAthleteImageUrl: false,
          searchResult: {
            ...state.searchResult,
            data: updatedUserPlansForImageUrl,
          },
          selectedAthlete: newSelectedAthleteForImageUrl,
        };
      case ActionTypes.ATHLETES_LOAD_IMAGE_URL_REJECTED:
        return {
          ...state,
          loadingAthleteImageUrl: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.ATHLETES_GET_RECENT_DATA_PENDING:
        return {
          ...state,
          loadingRecentData: true,
        };

      case ActionTypes.ATHLETES_GET_RECENT_DATA_FULFILLED:
        return {
          ...state,
          recentData: action.payload.data,
          loadingRecentData: false,
        };

      case ActionTypes.ATHLETES_GET_RECENT_DATA_REJECTED:
        return {
          ...state,
          loadingRecentData: false,
          errorMessage: "Error loading recent data",
        };

      case ActionTypes.ATHLETES_GET_DATA_PENDING:
        return {
          ...state,
          loadingData: true,
        };

      case ActionTypes.ATHLETES_GET_DATA_FULFILLED:
        return {
          ...state,
          data: action.payload.data,
          loadingData: false,
        };

      case ActionTypes.ATHLETES_GET_DATA_REJECTED:
        return {
          ...state,
          loadingData: false,
          errorMessage: "Error loading recent data",
        };

      case ActionTypes.ATHLETES_GET_EVENTS_PENDING:
        return {
          ...state,
          loadingEvents: true,
        };

      case ActionTypes.ATHLETES_GET_EVENTS_FULFILLED:
        return {
          ...state,
          events: action.payload.data,
          loadingEvents: false,
        };

      case ActionTypes.ATHLETES_GET_EVENTS_REJECTED:
        return {
          ...state,
          loadingEvents: false,
          errorMessage: "Error loading events",
        };

      case ActionTypes.ATHLETES_CHANGE_TRAINING_CHECK_PENDING:
        return {
          ...state,
          loadingChecked: true,
        };

      case ActionTypes.ATHLETES_CHANGE_TRAINING_CHECK_FULFILLED:
        return {
          ...state,
          loadingChecked: false,
          selectedAthlete: {
            ...state.selectedAthlete,
            checked: action.payload.checked,
          },
        };

      case ActionTypes.ATHLETES_CHANGE_TRAINING_CHECK_REJECTED:
        return {
          ...state,
          loadingChecked: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.ATHLETES_CHANGE_TRAINING_NEEDSTOCHECK_PENDING:
        return {
          ...state,
          loadingNeedsToCheck: true,
        };

      case ActionTypes.ATHLETES_CHANGE_TRAINING_NEEDSTOCHECK_FULFILLED:
        return {
          ...state,
          loadingNeedsToCheck: false,
          selectedAthlete: {
            ...state.selectedAthlete,
            needsCheck: action.payload.needsCheck,
          },
        };

      case ActionTypes.ATHLETES_CHANGE_TRAINING_NEEDSTOCHECK_REJECTED:
        return {
          ...state,
          loadingChecked: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.ATHLETES_CLEAR_ATHLETE:
        return {
          ...state,
          selectedAthlete: action.payload,
        };

      case ActionTypes.ATHLETES_CHANGE_TRAINING_NEEDSHELP_PENDING:
        return {
          ...state,
          loadingNeedsHelp: true,
        };

      case ActionTypes.ATHLETES_CHANGE_TRAINING_NEEDSHELP_FULFILLED:
        return {
          ...state,
          loadingNeedsHelp: false,
          selectedAthlete: {
            ...state.selectedAthlete,
            needHelp: action.payload.needHelp,
          },
        };

      case ActionTypes.ATHLETES_CHANGE_TRAINING_NEEDSHELP_REJECTED:
        return {
          ...state,
          loadingNeedsHelp: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.ATHLETES_UPDATE_LAST_VIEWED:
        return {
          ...state,
          searchResult: {
            ...state.searchResult,
            data: action.payload,
          },
        };

      case ActionTypes.ATHLETES_GET_SIGNUP_PENDING:
        return {
          ...state,
          signInLoading: true,
        };

      case ActionTypes.ATHLETES_GET_SIGNUP_FULLFILLED:
        return {
          ...state,
          signInLoading: false,
          signInType: action.payload,
        };

      case ActionTypes.ATHLETES_GET_SIGNUP_REJECTED:
        return {
          ...state,
          signInLoading: false,
          signInType: { data: "error" },
          signInError: action.payload,
        };

      case ActionTypes.ATHLETES_UPDATE_LOCALLY:
        const {
          searchResult: { data },
        } = state;

        const reducerCopy = [...JSON.parse(JSON.stringify(data))];
        const sub = action.payload.cognito_user_sub;

        const index = reducerCopy.findIndex(
          (item) => item.cognito_user_sub === sub,
        );

        reducerCopy[index] = {
          ...reducerCopy[index],
          ...action.payload,
          currentTraining: reducerCopy[index].currentTraining,
        };
        return {
          ...state,
          searchResult: {
            ...state.searchResult,
            data: reducerCopy,
          },
        };

      case ActionTypes.ATHLETES_LOAD_STRENGTH_COACHES:
        return {
          ...state,
          strengthCoaches: action.payload,
          loadingStrengthAssign: false,
        };

      case ActionTypes.ATHLETES_ASSIGN_STRENGTH_COACH_PENDING:
        return {
          ...state,
          loadingStrengthAssign: true,
        };

      case ActionTypes.ATHLETES_ASSIGN_STRENGTH_COACH_REJECTED:
        return {
          ...state,
          loadingStrengthAssign: false,
        };

      case ActionTypes.ATHLETES_ASSIGN_STRENGTH_COACH_FULLFILED:
        let athlete = { ...action.payload };
        const { cognito_user_sub: user_sub } = athlete;

        if (statistic) {
          athlete = {
            ...athlete,
            statistic: statistic,
          };
        }
        if (stravaActivities) {
          athlete = {
            ...athlete,
            stravaActivities: stravaActivities,
          };
        }
        if (imageUrl) {
          athlete = {
            ...athlete,
            imageUrl: imageUrl,
          };
        }
        let newPlan = [...state.searchResult.data];
        if (user_sub) {
          const foundIndex = state.searchResult.data.findIndex(
            (userPlan) => userPlan.cognito_user_sub === user_sub,
          );
          if (foundIndex > -1) {
            newPlan.splice(foundIndex, 1, athlete);
          }
        }
        return {
          ...state,
          selectedAthlete: athlete,
          loadingStrengthAssign: false,
          searchResult: {
            ...state.searchResult,
            data: newPlan,
          },
        };

      case ActionTypes.ATHLETES_SAVE_PLAN_PENDING:
        return {
          ...state,
          saveLoading: true,
        };

      case ActionTypes.ATHLETES_SAVE_PLAN_FULLFILED:
        return {
          ...state,
          saveLoading: false,
          successMessage: "Plan saved successfully",
        };

      case ActionTypes.ATHLETES_SAVE_PLAN_REJECTED:
        return {
          ...state,
          saveLoading: false,
        };

      case ActionTypes.EDITING_PLAN_PENDING:
        return {
          ...state,
          editingLoading: true,
        };

      case ActionTypes.EDITING_PLAN_FULLFILED:
        return {
          ...state,
          editingLoading: false,
          successMessage: "Editing status changed successfully",
          selectedAthlete: {
            ...state.selectedAthlete,
            editMode: action.payload.editMode,
          },
        };

      case ActionTypes.EDITING_PLAN_REJECTED:
        return {
          ...state,
          editingLoading: false,
        };

      default:
        return state;
    }
  } else {
    return state;
  }
}
