import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import RouterLink from './router-link';

const styles = theme => ({
    fab: {
        position: 'absolute',
        top: 120,
        right: 15,
        [theme.breakpoints.down('md')]: {
            top: 180,
        },
        [theme.breakpoints.down('sm')]: {
            top: 280,
        },
        [theme.breakpoints.down('xs')]: {
            top: 350,
        }
    }
});

function FloatingAddButton(props) {
    const { classes, to } = props;
    return (
        <RouterLink to={to} className={classes.fab}>
            <Fab color="primary" aria-label="Add">
                <AddIcon />
            </Fab>
        </RouterLink>
    );
}

FloatingAddButton.propTypes = {
    classes: PropTypes.object.isRequired,
    to: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(FloatingAddButton);