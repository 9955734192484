import React, { useRef, useEffect, memo } from "react";
import { withTranslation } from "react-i18next";
import { withStyles, withWidth } from "@material-ui/core";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import { App } from "@capacitor/app";

const styles = (theme) => ({
  map: {
    height: "100%",
  },

  centerButton: {
    position: "absolute",
    bottom: "25px",
    right: "10px",
    backgroundColor: "#fff",
    border: "2px solid rgba(0,0,0,0.2)",
    borderRadius: 2,
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
    padding: 5,
  },
});

const DashboardGpsMap = ({ classes, t, currentLocation, trackingData }) => {
  const canvasRef = useRef(null);

  const [map, setMap] = React.useState(null);
  const mapRef = useRef(map);

  const [userMarker, setUserMarker] = React.useState(null);
  const [path, setPath] = React.useState(null);
  const [centerTimeout, setCenterTimeout] = React.useState(null);
  const centerTimeoutRef = useRef(centerTimeout);
  const trackingDataRef = useRef(trackingData);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevProps = usePrevious({
    currentLocation: currentLocation,
    /*image: props.image,
    type: props.type,
    overlay: props.overlay,
    video: props.video,
    muted: props.muted,
    sharingVideo: props.sharingVideo,*/
  });

  const centerMap = () => {
    if (trackingDataRef.current && trackingDataRef.current.length) {
      mapRef.current.panTo(
        new window.L.LatLng(
          trackingDataRef.current[trackingDataRef.current.length - 1].latitude,
          trackingDataRef.current[trackingDataRef.current.length - 1].longitude,
        ),
      );
    } else {
      mapRef.current.panTo(
        new window.L.LatLng(
          currentLocation.latitude,
          currentLocation.longitude,
        ),
      );
    }
  };

  useEffect(() => {
    if (!prevProps || (!prevProps.currentLocation && currentLocation)) {
      if (map) {
        map.off();
        map.remove();
      }

      const mapInstance = window.L.map("map").setView(
        new window.L.LatLng(
          currentLocation.latitude,
          currentLocation.longitude,
        ),
        16,
      );

      setMap(mapInstance);
      window.L.tileLayer(
        "https://api.maptiler.com/maps/outdoor/{z}/{x}/{y}.png?key=HYQqXwUGhRkIp3nBx2ra",
      ).addTo(mapInstance);

      const marker = window.L.userMarker(
        new window.L.LatLng(
          currentLocation.latitude,
          currentLocation.longitude,
        ),
        {
          pulsing: false,
          accuracy: 100,
          smallIcon: true,
        },
      );
      marker.addTo(mapInstance);

      setUserMarker(marker);

      mapInstance.on("movestart", (e) => {
        if (centerTimeoutRef.current) {
          clearTimeout(centerTimeoutRef.current);
        }
      });

      mapInstance.on("moveend", (e) => {
        setCenterTimeout(
          setTimeout(() => {
            centerMap();
          }, 10000),
        );
      });

      App.addListener("appStateChange", ({ isActive }) => {
        centerMap();
      });
    } else {
      if (trackingData && trackingData.length) {
        if (!path) {
          const polyline = window.L.polyline(
            [
              trackingData
                .filter((p) => !p.paused)
                .map((point) => [point.latitude, point.longitude]),
            ],
            {
              color: "blue",
              weight: 2,
              opacity: 0.5,
              smoothFactor: 1,
            },
          );
          polyline.addTo(map);
          setPath(polyline);
        } else {
          path.setLatLngs([
            trackingData
              .filter((p) => !p.paused)
              .map((point) => [point.latitude, point.longitude]),
          ]);
        }

        userMarker.setLatLng(
          new window.L.LatLng(
            trackingData[trackingData.length - 1].latitude,
            trackingData[trackingData.length - 1].longitude,
          ),
        );
        userMarker.setAccuracy(trackingData[trackingData.length - 1].accuracy);
      } else {
        userMarker.setLatLng(
          new window.L.LatLng(
            currentLocation.latitude,
            currentLocation.longitude,
          ),
        );
        userMarker.setAccuracy(currentLocation.accuracy);
      }
    }
    mapRef.current = map;
    centerTimeoutRef.current = centerTimeout;
    trackingDataRef.current = trackingData;
  }, [currentLocation, map, centerTimeout, trackingData]);

  return (
    <div id="map" className={classes.map}>
      <MyLocationIcon
        onClick={() => centerMap()}
        className={classes.centerButton}
        fontSize="small"
      />
    </div>
  );
};

export default withTranslation("dashboard")(
  withWidth()(withStyles(styles, { withTheme: true })(memo(DashboardGpsMap))),
);
