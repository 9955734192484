const TrainingDurationInWeeks = {
  "1": "1 Week",
  "2": "2 Weeks",
  "3": "3 Weeks",
  "4": "4 Weeks",
  "5": "5 Weeks",
  "6": "6 Weeks",
  "7": "7 Weeks",
  "8": "8 Weeks",
  "9": "9 Weeks",
  "10": "10 Weeks",
  "11": "11 Weeks",
  "12": "12 Weeks",
  "13": "13 Weeks",
  "14": "14 Weeks",
  "15": "15 Weeks",
  "16": "16 Weeks",
};

export default {
  Items: TrainingDurationInWeeks,
  itemsArray: Object.keys(TrainingDurationInWeeks).map((key) => {
    return {
      text: TrainingDurationInWeeks[key],
      value: key,
    };
  }),
};
