import React, { Component } from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

class TimeMaskedInputSeconds extends Component {
  render() {
    const { inputRef, ...other } = this.props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          /\d/,
          /\d/,
          "h",
          ":",
          /[0-5]/,
          /\d/,
          "m",
          ":",
          /[0-5]/,
          /\d/,
          "s",
        ]}
        placeholderChar={"0"}
        showMask="false"
      />
    );
  }
}

TimeMaskedInputSeconds.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default TimeMaskedInputSeconds;
