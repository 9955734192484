import ActionTypes from "../constants/action-types";

const initialState = {
  title: null,
  isLogo: true,
  isAvatar: true,
  isNoti: true,
  isSearch: true,
  isMobile: true,
  isSettings: false,
  isSearching: false,
  buttonChildrenProps: null,
};

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_NAVBAR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default navbarReducer;
