import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { palette } from "../../../theme/palette";
import useSubcategories from "../../../hooks/useSubcategories";
import PopularPlansCardSkeleton from "./PopularPlansCardSkeleton";
import PopularPlansHomeCard from "./PopularPlansHomeCard";

const PopularPlansHome = () => {
  const { t } = useTranslation("home");
  const history = useHistory();
  const { subCategoriesData, loading } = useSubcategories();
  const classes = useStyles();

  const plans = subCategoriesData || [];
  const filteredPlans = plans.filter(({ tags = [] }) =>
    tags.includes("Popular"),
  );

  const navigate = (category) => {
    history.push("chooseplan?plan=" + category.id);
  };

  const navigateToAll = () => {
    history.push("chooseplan");
  };

  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.header}>
          <Typography variant="h3" className={classes.bold}>
            {t("home.plan.popular")}
          </Typography>
          <Typography
            variant="body2"
            className={classes.seeAll}
            id="home_see_all_plans"
            onClick={navigateToAll}
          >
            {t("home.plan.seeAll")}
          </Typography>
        </Box>
      </Box>

      {loading ? (
        <Box className={classes.carousel}>
          {[...Array(3)].map((_, index) => (
            <PopularPlansCardSkeleton key={index} className={classes.card} />
          ))}
        </Box>
      ) : (
        <Box className={classes.carousel}>
          {filteredPlans.slice(0, 5).map((item) => (
            <PopularPlansHomeCard
              key={item.id}
              item={item}
              navigate={navigate}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PopularPlansHome;

const useStyles = makeStyles(() => ({
  card: {
    background: palette.white,
    borderRadius: "8px",
    marginTop: "12px",
    width: "275px",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
  title: {
    fontFamily: "Oswald",
    marginBottom: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: "4px",
  },
  content: {
    color: palette.darkGray,
    fontWeight: "400",
  },
  button: {
    fontFamily: "Karla",
    marginTop: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "18px",
    textTransform: "lowercase",
  },
  descriptionContainer: {
    padding: "12px",
    height: "165px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  verticalCentered: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  label: {
    color: palette.darkGray,
    marginTop: "4px",
  },
  labelOnly: {
    color: palette.darkGray,
  },
  margin: {
    marginTop: "12px",
  },
  carousel: {
    display: "-webkit-inline-box",
    width: "100%",
    overflowY: "hidden",
    overflowX: "scroll",
    scrollbarWidth: "none",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  seeAll: {
    color: palette.lightBlue,
  },
  bold: {
    fontFamily: "Karla",
    fontSize: "22px",
    lineHeight: "33px",
    letterSpacing: "0px",
    fontWeight: "700",
  },
  attributes: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    padding: "0 24px",
  },
  favoriteButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "#FFFFFF33",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
}));
