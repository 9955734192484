import React, { useState, memo } from "react";
import { withTranslation } from "react-i18next";
import {
  withStyles,
  TextField,
  InputAdornment,
  IconButton,
  Link,
  Typography,
  Checkbox,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SignInWithGoogle from "../auth-components/sign-in-with-google";
import SignInWithFacebook from "../auth-components/sign-in-with-facebook";
import SignInWithApple from "../auth-components/sign-in-with-apple";
import { Capacitor } from "@capacitor/core";
import NoAccount from "./no-account";

const styles = (theme) => ({
  card: {
    background: "#FFFFFF",
    border: "1px solid rgba(158, 158, 158, 0.32)",
    boxShadow: "0px 0px 24px rgba(158, 158, 158, 0.5)",
    borderRadius: "5px",
    padding: "33px 12px",
  },
  forgotContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 12,
  },
  passwordReset: {
    marginLeft: "10px",
  },
  rememberMe: {
    display: "flex",
    alignItems: "center",
  },
  grid: {
    flexWrap: "no-wrap !important",
  },
  rememberContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "30px",
  },
});

const AuthOption = ({
  classes,
  loading,
  I18n,
  authType,
  customHandleInput,
  handleKeyPress,
  handleForgotPassword,
  handleSignUp,
  user,
  t,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleCheckbox = ({ target: { checked } }) => {
    if (checked) {
      localStorage.setItem("remember", true);
      localStorage.setItem("email", user);
      return;
    }

    if (localStorage.getItem("remember") && localStorage.getItem("email")) {
      localStorage.removeItem("remember");
      localStorage.removeItem("email");
    }
  };

  return (
    <div className={classes.card}>
      {authType == "google" && (
        <SignInWithGoogle
          disabled={loading}
          label={I18n.get("Continue with Google")}
        />
      )}
      {authType == "facebook" && (
        <SignInWithFacebook
          disabled={loading}
          label={I18n.get("Continue with Facebook")}
        />
      )}

      {authType == "signinwithapple" && (
        <SignInWithApple
          disabled={loading}
          label={I18n.get("Continue with Apple")}
        />
      )}

      {authType == "email" && (
        <>
          <TextField
            id="password"
            key="password"
            name="password"
            autoComplete="off"
            variant="outlined"
            onChange={customHandleInput}
            label={I18n.get("Your password")}
            type={showPassword ? "text" : "password"}
            fullWidth
            className={classes.TextField}
            onKeyPress={handleKeyPress}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className={classes.forgotContainer}>
            <Typography variant="body2">{t("Forgot password?")}</Typography>
            <Link
              className={classes.passwordReset}
              component="button"
              tabIndex="-1"
              variant="body2"
              onClick={() => {
                if (!loading) handleForgotPassword();
              }}
            >
              {t("Get a new on here.")}
            </Link>
          </div>

          <Box className={classes.rememberContainer}>
            <Box className={classes.rememberMe}>
              <Checkbox
                onChange={handleCheckbox}
                style={{ color: "#FF6228" }}
                defaultChecked={!!localStorage.getItem("remember") || false}
              />
              <Typography>{t("Remember me")}</Typography>
            </Box>
            <Box>
              <Button
                id="login-pass-id"
                variant="contained"
                color="primary"
                autoFocus={!Capacitor.isNativePlatform()}
                size="large"
                type="submit"
                disabled={false}
              >
                {!loading ? (
                  I18n.get("Log in")
                ) : (
                  <CircularProgress size={26} color="inherit" />
                )}
              </Button>
            </Box>
          </Box>
        </>
      )}

      {authType == "error" && <NoAccount handleSignUp={handleSignUp} />}
    </div>
  );
};

export default withTranslation("auth")(
  withStyles(styles, { withTheme: true })(memo(AuthOption)),
);
