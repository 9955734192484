import React, { useState } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Auth, I18n } from "aws-amplify";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { palette } from "../../../theme/palette";
import bugsnagClient from "../../../lib/bugsnag-client";
import {
  STEPS_RECOVERY,
  useStepsRecovery,
} from "../../../app/providers/StepsRecoveryProvider";

const useStyles = makeStyles((theme) => ({
  iconarrowBack: {
    position: "relative",
    width: "24px",
    height: "24px",
    overflow: "hidden",
    flexShrink: 0,
    objectFit: "cover",
  },
  vertrunLogoIcon: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    left: "0%",
    maxWidth: "100%",
    overflow: "hidden",
    maxHeight: "100%",
    objectFit: "cover",
  },
  iconvertrunLogo: {
    position: "relative",
    width: "151px",
    height: "70px",
  },
  logoback: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0px 16px",
  },
  passwordRecoveryText: {
    fontSize: 18,
    color: palette.black,
    position: "relative",
    letterSpacing: "1px",
    lineHeight: "125%",
    textTransform: "uppercase",
    fontWeight: "500",
    fontFamily: "Oswald",
  },
  anAuthenticationCode: {
    color: palette.black,
    alignSelf: "stretch",
    position: "relative",
    fontSize: 16,
    lineHeight: "150%",
    fontFamily: "Karla",
  },
  passwordRecovery: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "12px",
    fontSize: "18px",
    color: palette.black,
    fontFamily: "Karla",
  },
  enterTheCode: {
    position: "relative",
    lineHeight: "150%",
    display: "inline-block",
    width: "358px",
  },
  placeholder: {
    position: "relative",
    lineHeight: "150%",
  },
  iconplaceholder: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  email: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  inputstext: {
    borderRadius: "4px",
    border: "2px solid #eaeaea",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    padding: "12px 13px",
  },
  emailInput: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontSize: 16,
    color: "#6d6f71",
    fontFamily: "Karla",
  },
  input: {
    width: "100%",
    paddingLeft: "10px",
    height: "100%",
    border: "none",
    outline: "none",
  },
  passwordInput: {
    alignSelf: "stretch",
    backgroundColor: "#feffff",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  span: {
    color: "#12151b",
  },
  resendCode: {
    color: "#4780ab",
    textTransform: "normal",
  },
  codeChild: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "8px",
    color: "#6d6f71",
  },
  code: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "12px",
  },
  authenticationCodeInput: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },

  password: {
    alignSelf: "stretch",
    backgroundColor: "#feffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    color: "#6d6f71",
  },
  passwordRecoveryForm: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "32px",
  },
  text: {
    position: "relative",
    lineHeight: "125%",
  },
  primaryButton: {
    alignSelf: "stretch",
    borderRadius: "4px",
    backgroundColor: palette.darkBlue,
    boxShadow: "0px 4px 30px rgba(18, 21, 27, 0.05)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 0px",
  },
  backToLog: {
    alignSelf: "stretch",
    position: "relative",
    lineHeight: "125%",
    color: "#4780ab",
  },
  buttons: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "16px",
    textAlign: "center",
    fontSize: "18px",
    color: palette.white,
    marginTop: 10,
  },
  content: {
    alignSelf: "stretch",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 16px",
  },
  page: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "24px 0px",
    boxSizing: "border-box",
    gap: "24px",
    textAlign: "left",
    color: palette.black,
    fontFamily: "Karla",
  },
}));

const AuthPasswordComponent = () => {
  const { t } = useTranslation("settings");
  const { onSetStep, onSetErrorMessage } = useStepsRecovery();
  const styles = useStyles();
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [resendTime, setResendTime] = useState();
  const [visibilityPassword, setVisibilityPassword] = useState(false);
  const [password, setPassword] = useState("");

  const passwordSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    Auth.forgotPasswordSubmit(email, code, password)
      .then((data) => {
        console.log("Auth forgotPasswordSubmit response > ", { data });
        onSetStep(STEPS_RECOVERY.SUCCESS);
      })
      .catch((err) => {
        onSetErrorMessage(err.message);
        bugsnagClient.notify(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendCode = (event) => {
    setLoading(true);
    event.preventDefault();

    Auth.forgotPassword(email)
      .then((data) => {
        console.log("Auth forgotPassword response > ", { data });
      })
      .catch((err) => {
        onSetErrorMessage(err.message);
        bugsnagClient.notify(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.page}>
      <div className={styles.logoback}>
        <div className={styles.iconvertrunLogo}>
          <img
            className={styles.vertrunLogoIcon}
            alt=""
            src={process.env.PUBLIC_URL + "/vertrun-logo-wide.png"}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.passwordRecoveryForm}>
          <div className={styles.passwordRecovery}>
            <div className={styles.passwordRecoveryText}>
              {t("settings.enterYourEmail")}
            </div>
            <div className={styles.anAuthenticationCode}>
              {t("settings.sentCodeTitle")}
            </div>
          </div>
          <div className={styles.authenticationCodeInput}>
            <div className={styles.code}>
              <div className={styles.enterTheCode}>
                {t("settings.enterYourCode")}
              </div>
              <div className={styles.codeChild}>
                <div className={styles.emailInput}>
                  <div className={styles.email}>
                    <div className={styles.inputstext}>
                      <div className={styles.iconplaceholder}>
                        <input
                          type="text"
                          className={styles.input}
                          placeholder={t("settings.authCode")}
                          value={code}
                          onChange={(event) => setCode(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.placeholder}>
                  <span>
                    <span>{t("settings.didntReceiveCode")}</span>
                    <span className={styles.span}>{` `}</span>
                  </span>
                  <Button
                    disabled={loading}
                    variant="text"
                    color={loading ? "default" : "secondary"}
                    onClick={sendCode}
                    className={styles.resendCode}
                  >
                    {t("settings.resendCode")}
                  </Button>
                  <span className={styles.span}>{` `}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.code}>
            <div className={styles.enterTheCode}>
              {t("settings.createPassword")}
            </div>
            <div className={styles.emailInput}>
              <div className={styles.email}>
                <div className={styles.inputstext}>
                  <div className={styles.iconplaceholder}>
                    <input
                      type={visibilityPassword ? "text" : "password"}
                      className={styles.input}
                      placeholder={t("settings.newPassword")}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <Button
                      variant="text"
                      onClick={() => setVisibilityPassword((prev) => !prev)}
                      className={styles.iconmail}
                    >
                      {visibilityPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <img src={`${process.env.PUBLIC_URL}/Icon_eye.png`} />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            variant="contained"
            color={
              loading || code === "" || password === ""
                ? "default"
                : "secondary"
            }
            disabled={loading || code === "" || password === ""}
            className={styles.primaryButton}
            onClick={passwordSubmit}
          >
            {loading ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              <b className={styles.text}>{t("settings.buttons.confirm")}</b>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AuthPasswordComponent;
