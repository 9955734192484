import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

class TimeMaskedInput extends Component {
    render() {
        const { inputRef, ...other } = this.props;
        return (
            <MaskedInput
                {...other}
                ref={ref => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={[/\d/, /\d/, ':', /[0-5]/, /\d/]}
                placeholderChar={'-'}
                showMask
            />
        );
    }
}

TimeMaskedInput.propTypes = {
    inputRef: PropTypes.func.isRequired
};

export default TimeMaskedInput;