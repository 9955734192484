import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography, makeStyles } from "@material-ui/core";
import { palette } from "../../theme/palette";
import { useSelector, useDispatch } from "react-redux";
import { getPosts } from "../../actions/trail-head-action";
import { getImage } from "../../lib/common";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { RowListSkeleton } from "./row-list-skeleton";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  card: {
    background: palette.white,
    borderRadius: "8px",
    marginTop: "12px",
    width: "220px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
  title: {
    fontFamily: "Oswald",
    marginBottom: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: "4px",
  },
  content: {
    color: palette.darkGray,
    fontWeight: "400",
  },
  button: {
    fontFamily: "Karla",
    marginTop: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "18px",
    textTransform: "lowercase",
  },
  descriptionContainer: {
    padding: "12px",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  verticalCentered: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  divider: {
    background: palette.lightGray,
    width: "2px",
    height: "40px",
  },
  label: {
    color: palette.darkGray,
    marginTop: "4px",
  },
  labelOnly: {
    color: palette.darkGray,
  },
  margin: {
    marginTop: "12px",
  },
  carousel: {
    display: "-webkit-inline-box",
    width: "100%",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "40px",
  },
  seeAll: {
    color: palette.lightBlue,
  },
  blur: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: "50%",
    width: 40,
    height: 40,
    padding: "4px",
  },
  blurFavorite: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: "50%",
    width: 24,
    height: 24,
    padding: "4px",
    boxSizing: "border-box",
    position: "absolute",
    top: "8px",
    right: "8px",
  },
  bold: {
    fontWeight: "700",
  },
  row: {
    display: "flex",
    alignItems: "center",
    padding: "12px",
  },
  titles: {
    marginLeft: "8px",
  },
  caption: {
    padding: "0px 12px 12px 12px",
  },
  picture: {
    height: "153px",
    background: "gray",
    width: "100%",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "12px 0px",
    padding: "0px 12px",
    boxSizing: "border-box",
  },
  iconsContainer: {
    "& img:first-child": {
      marginRight: "16px",
    },
  },
  icon: {
    marginLeft: "16px",
  },
});

const TrailHeadPreview = () => {
  const {
    t,
    i18n: { language = "en" },
  } = useTranslation("home");
  const history = useHistory();
  const dispatch = useDispatch();
  const [profileImages, setProfileImages] = useState([]);
  const [images, setImages] = useState([]);
  const { posts, loadingPosts } = useSelector((state) => state.trailHead);
  const { data = [] } = posts || {};
  const classes = useStyles();

  useEffect(() => {
    const { data } = posts || {};
    if (!data || (!data.length && !loadingPosts))
      dispatch(getPosts(new Date().getTime(), "", "", false, true));
  }, [posts, loadingPosts]);

  useEffect(() => {
    if (Array.isArray(data) && data.length) {
      getPostImage();
      getProfileImage();
    }
  }, [data]);

  const getProfileImage = async () => {
    await (data || []).forEach(async ({ by }) => {
      const image = await getImage(by.image);
      await setProfileImages((prev) => [...prev, image]);
    });
  };

  const getPostImage = async () => {
    await (data || []).forEach(async ({ image }) => {
      const currentImage = image ? await getImage(image) : null;
      await setImages((prev) => [...prev, currentImage]);
    });
  };

  const goToTrailhead = () => history.push("trailhead");
  const goToPost = ({ id }) => history.push(`trailhead/post/${id}`);

  const truncate = (text, limit) => {
    if (text.length <= limit) return text;
    return `${text.slice(0, limit)}...`;
  };

  return (
    <>
      <Box sx={{ marginTop: "40px" }}>
        {!loadingPosts && (
          <Box className={classes.header}>
            <Typography variant="h3" className={classes.bold}>
              {t("New in our community")}
            </Typography>
            <Typography
              variant="body2"
              className={classes.seeAll}
              onClick={goToTrailhead}
              id="home_see_all_community"
            >
              {t("See all")}
            </Typography>
          </Box>
        )}
      </Box>

      {loadingPosts && (
        <Box sx={{ marginTop: "12px" }}>
          <RowListSkeleton width={265} height={235} />
        </Box>
      )}
      <Box className={classes.carousel}>
        {(data || []).map((item, index) => {
          return (
            <Box
              className={classes.card}
              key={index}
              onClick={() => goToPost(item)}
              id={`home_community_pos1${index + 1}`}
            >
              <Box className={classes.row}>
                <Avatar
                  src={
                    profileImages[index] ||
                    `${process.env.PUBLIC_URL}/avatar-empty.png`
                  }
                />
                <Box className={classes.titles}>
                  <Typography variant="h4">
                    {item.by.cognito_user_name}
                  </Typography>
                  <Typography
                    className={classes.content}
                    variant="subtitle2"
                  >{`${moment(item.created_at)
                    .locale(language)
                    .fromNow()}`}</Typography>
                </Box>
              </Box>
              <Typography variant="body1" className={classes.caption}>
                {!images[index]
                  ? truncate(item.text, 140)
                  : truncate(item.text, 25)}
              </Typography>
              {images[index] && (
                <img src={images[index]} alt="post image" width="100%" />
              )}
              <Box className={classes.actions}>
                <Box className={classes.iconsContainer}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon_heart.svg`}
                    alt="icon-heart"
                    id="home_community_heart_icon"
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/icon_comment.svg`}
                    alt="icon-comment"
                    id="trailhead_menu_icon"
                  />
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default TrailHeadPreview;
