import React from "react";
import useUserInfo from "../../../hooks/useUserInfo";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import GeneralCard from "../../generals/generalCard";
import useWorkout from "../../../hooks/useWorkout";
import WorkoutCard from "./WorkoutCard";
import { palette } from "../../../theme/palette";
import ModalCustom from "./ModalCustom";
import TrainingDay from "./TrainingDay";
import { WorkoutTypes } from "../../../constants/workout-types";
import { convertMinutesToTimeLabel } from "../../../constants/estimated-times";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";
import dayjs from "dayjs";
import { IconMessageCircle2 } from "@tabler/icons-react";
import { useChatContext } from "../../../context/ChatProvider";
import ExportWorkoutButton from "./ExportWorkoutButton";

const HomeInfoCard = () => {
  const classes = useStyles();
  const { t } = useTranslation("home");
  const { currentTraining, isGarminConnected } = useUserInfo();
  const {
    loading,
    openWorkout,
    workoutToday,
    isOpenWorkout,
    onCloseOpenWorkout,
    getFixCustomLevel,
    parametrizedWorkout,
  } = useWorkout();
  const { openChat, setWorkoutToShare } = useChatContext();

  return (
    <>
      <Box>
        {typeof currentTraining === "string" ? (
          <GeneralCard
            title="home.workout.today"
            content="home.workout.choosePlan"
          />
        ) : (
          <Box className={classes.card}>
            {loading ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "8px",
                }}
              >
                <Skeleton variant="rounded" width="40%" />
                <Skeleton variant="rounded" width="30%" />
                <Box
                  style={{ display: "flex", flexDirection: "row", gap: "8px" }}
                >
                  <Skeleton variant="rounded" height="54px" width="100%" />
                  <Skeleton variant="rounded" height="54px" width="54px" />
                </Box>
              </Box>
            ) : workoutToday ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  gap: "8px",
                }}
              >
                <Box>
                  <Typography className={classes.title}>
                    {t("home.workout.today")}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {dayjs().format("dddd, MMMM D")}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: 1,
                    gap: "8px",
                  }}
                >
                  <WorkoutCard
                    height="58px"
                    paperStylesProp={{ width: "100%" }}
                    variant={
                      workoutToday ? workoutToday.workoutType : "NoWorkout"
                    }
                    primaryText={
                      workoutToday.workoutType
                        ? workoutToday.workoutType === "UserCreated"
                          ? workoutToday.userCreatedType
                          : t(WorkoutTypes[workoutToday.workoutType], {
                              ns: "constants",
                            })
                        : ""
                    }
                    secondaryText={
                      workoutToday.estimatedTime
                        ? convertMinutesToTimeLabel(
                            Array.isArray(workoutToday.estimatedTime) &&
                              !isNaN(
                                +workoutToday.estimatedTime[
                                  getFixCustomLevel(workoutToday.plannedDate)
                                ],
                              )
                              ? +workoutToday.estimatedTime[
                                  getFixCustomLevel(workoutToday.plannedDate)
                                ]
                              : +workoutToday.estimatedTime,
                          )
                        : ""
                    }
                    onClick={() => openWorkout(workoutToday)}
                    margin="0"
                    isDone={workoutToday.isDone}
                    withCheck={workoutToday.isDone}
                  />
                  {workoutToday &&
                    parametrizedWorkout &&
                    parametrizedWorkout.length > 0 && (
                      <ExportWorkoutButton
                        workout={workoutToday}
                        parametrizedWorkout={parametrizedWorkout || []}
                        small
                        disabled={!isGarminConnected}
                      />
                    )}
                </Box>
              </Box>
            ) : (
              <WorkoutCard
                height="58px"
                paperStylesProp={{ width: "100%" }}
                variant="Recovery"
                primaryText={t("home.workout.fullRest")}
                margin="0"
              />
            )}
          </Box>
        )}
      </Box>
      {currentTraining &&
        currentTraining.constructor === Object &&
        Object.keys(currentTraining).length && (
          <ModalCustom
            fullScreen={true}
            open={isOpenWorkout}
            onClose={onCloseOpenWorkout}
            toolbarJustifyContent="space-between"
            paperDialogStyles={{ margin: 0, borderRadius: 0 }}
            callToActions={[
              {
                ActionIconComponent: (
                  <IconMessageCircle2 color="#6D6F71" stroke={2} />
                ),
                onClick: () => {
                  openChat();
                  setWorkoutToShare(workoutToday);
                },
              },
            ]}
          >
            <Grid container>
              <Grid item xs={12}>
                {workoutToday ? (
                  <TrainingDay allow={true} workoutSelected={workoutToday} />
                ) : (
                  <TrainingDay allow={false} />
                )}
              </Grid>
            </Grid>
          </ModalCustom>
        )}
    </>
  );
};

export default HomeInfoCard;

const useStyles = makeStyles({
  card: {
    padding: "16px",
    background: palette.white,
    borderRadius: "10px",
    boxShadow: palette.boxShadow,
    display: "flex",
  },
  title: {
    color: "#12151B",
    textTransform: "uppercase",
    fontFamily: "Oswald",
    fontSize: "18px",
    fontWeight: 500,
  },
  subTitle: {
    fontFamily: "Karla",
    marginBottom: "12px",
    color: "#6D6F71",
    fontWeight: "400",
    fontSize: "16px",
  },
});
