const WorkoutActionVariables = {
  Time: "Time",
  Distance: "Distance",
  Elevation: "Elevation",
};

export default {
  Items: WorkoutActionVariables,
  itemsArray: Object.keys(WorkoutActionVariables).map((key) => {
    return {
      text: WorkoutActionVariables[key],
      value: key,
    };
  }),
  ItemsShortArray: Object.keys(WorkoutActionVariables).map((key) => {
    return {
      text: key,
      value: key,
    };
  }),
};
