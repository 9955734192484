import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import PlanFieldTransition from "./plan-field-transition";

const styles = (theme) => ({
  container: {
    padding: "10px 16px 0",
    textAlign: "center",
  },
  icon: {
    verticalAlign: "middle",
    paddingRight: theme.spacing.unit,
  },
  text: {
    paddingBottom: theme.spacing.unit * 3,
  },
  subLabel: {
    display: "block",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    color: "#9E9E9E",
  },
  subLabelContainer: {},
  label: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: 24,
    textAlign: "center",
    margin: "10px 0px",
  },
});

class PlanFieldLabel extends Component {
  render() {
    const { classes, xs, sm, md, lg, xl, label, subLabel } = this.props;
    return (
      <PlanFieldTransition>
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <div className={classes.container}>
            {/*<Typography variant="h5" inline color="primary">
              {labelNumber}
            </Typography>
            <ArrowForwardIcon
              color="primary"
              fontSize="small"
              className={classes.icon}
    />*/}
            <Typography variant="h5" inline className={classes.label}>
              {label}
            </Typography>
            {subLabel ? (
              <div className={classes.subLabelContainer}>
                <Typography
                  variant="h5"
                  inline
                  className={classes.subLabel}
                  dangerouslySetInnerHTML={{
                    __html: subLabel,
                  }}
                ></Typography>
              </div>
            ) : (
              ""
            )}
          </div>
        </Grid>
      </PlanFieldTransition>
    );
  }
}

PlanFieldLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  label: PropTypes.string.isRequired,
  labelNumber: PropTypes.number.isRequired,
};

PlanFieldLabel.defaultProps = {
  labelNumber: 1,
};

export default withStyles(styles, { withTheme: true })(PlanFieldLabel);
