import React, { memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { Grid, Typography } from "@material-ui/core";
import {
  FacebookIcon,
  EmailIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";
import { Capacitor } from "@capacitor/core";
import { withTranslation } from "react-i18next";
import { convertUnits, round } from "../../lib/units-helper";
import LinkIcon from "@material-ui/icons/Link";

const styles = (theme) => ({
  main: {
    zIndex: 10000,
    background: "#fff",
    width: "100%",
    position: "initial",
    padding: 0,
  },
  container: {
    background: "#fff",
    justifyContent: "space-evenly",
    padding: 0,
  },
  topContainer: {
    padding: 0,
    marginBottom: 10,
    textAlign: "center",
  },
  logoImgTH: {
    cursor: "pointer",
    height: 36,
  },
  logoImg: {
    maxHeight: 40,
    cursor: "pointer",
  },
  icon: {
    cursor: "pointer",
  },
  title: {
    fontWeight: 500,

    fontFamily: "Montserrat",
    color: "#2E3A59",
    fontSize: 13,
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    marginLeft: "auto",
  },

  totalsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    zIndex: 10000,
    margin: "15px 0",
  },

  totalsTextSelected: {
    textAlign: "center",
    margin: "0 2px",

    fontWeight: 700,
    fontSize: 13,
    color: "#FF6327",
  },

  totalsText: {
    textAlign: "center",
    margin: "0 2px",

    fontWeight: 700,
    fontSize: 13,
    color: "#FF6327",
  },

  cont: {
    textAlign: "center",
  },

  iconCopy: {
    height: 40,
    background: "#7f7f7f",
    color: "#fff",
    borderRadius: "100%",
    padding: "0px 8px",
    cursor: "pointer",
  },
});

const MobileShare = ({
  classes,
  title,
  onClose,
  onShare,
  trailhead,
  t,
  challengeToShare,
  units,
  copylink,
}) => {
  return (
    <div className={classes.main}>
      <img
        src={`${process.env.PUBLIC_URL}/close.svg`}
        alt=""
        onClick={onClose}
        className={classes.rightButtons}
      />

      <div className={classes.topContainer}>
        <Typography variant="body1" className={classes.title}>
          {title}
        </Typography>
      </div>

      {challengeToShare ? (
        <div className={classes.totalsContainer}>
          <Typography
            variant={"body1"}
            className={
              challengeToShare.goal_type === "distance"
                ? classes.totalsTextSelected
                : classes.totalsText
            }
          >
            {t("valueUnit", {
              value:
                challengeToShare.yourEntry && challengeToShare.yourEntry.totals
                  ? round(
                      convertUnits(
                        challengeToShare.yourEntry.totals.distance / 1000,
                        "kms",
                        units,
                        "distance",
                      ),
                      1,
                    )
                  : 0,
              unit: units === "miles" ? ` ${t("miles")}` : "km",
            })}
          </Typography>
          <Typography variant={"body1"} className={classes.totalsText}>
            {" - "}
          </Typography>
          <Typography
            variant={"body1"}
            className={
              challengeToShare.goal_type === "time"
                ? classes.totalsTextSelected
                : classes.totalsText
            }
          >
            {t("valueUnit", {
              value:
                challengeToShare.yourEntry && challengeToShare.yourEntry.totals
                  ? new Date(challengeToShare.yourEntry.totals.time * 1000)
                      .toISOString()
                      .substr(11, 5)
                  : 0,
              unit: "h",
            })}
          </Typography>
          <Typography variant={"body1"} className={classes.totalsText}>
            {" - "}
          </Typography>
          <Typography
            variant={"body1"}
            className={
              challengeToShare.goal_type === "elevation"
                ? classes.totalsTextSelected
                : classes.totalsText
            }
          >
            {t("valueUnit", {
              value:
                challengeToShare.yourEntry && challengeToShare.yourEntry.totals
                  ? round(
                      convertUnits(
                        challengeToShare.yourEntry.totals.elevation,
                        "kms",
                        units,
                        "elevation",
                      ),
                      1,
                    )
                  : 0,
              unit: units === "miles" ? ` ${t("feet")}` : "m",
            })}
          </Typography>
        </div>
      ) : (
        ""
      )}

      <Grid container spacing={2} className={classes.container}>
        <Grid item xs="4" className={classes.cont}>
          <EmailIcon
            size={40}
            round
            className={classes.icon}
            onClick={() => onShare("email")}
          />
        </Grid>

        <Grid item xs="4" className={classes.cont}>
          <FacebookIcon
            size={40}
            round
            className={classes.icon}
            onClick={() => onShare("facebook")}
          />
        </Grid>

        {Capacitor.isNativePlatform() ? (
          <Grid item xs="4" className={classes.cont}>
            <img
              src={`${process.env.PUBLIC_URL}/instagram-logo.svg`}
              alt=""
              className={classes.logoImg}
              onClick={() => onShare("instagram")}
            />
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs="4" className={classes.cont}>
          <WhatsappIcon
            size={40}
            round
            className={classes.icon}
            onClick={() => onShare("whatsapp")}
          />
        </Grid>
        <Grid item xs="4" className={classes.cont}>
          <TwitterIcon
            size={40}
            round
            className={classes.icon}
            onClick={() => onShare("twitter")}
          />
        </Grid>

        {trailhead ? (
          <Grid item xs="4">
            <div onClick={() => onShare("trailhead")} className={classes.cont}>
              <img
                src={`${process.env.PUBLIC_URL}/trailhead-menu-icon.svg`}
                alt=""
                className={classes.logoImgTH}
              />
            </div>
          </Grid>
        ) : (
          ""
        )}

        {copylink ? (
          <Grid item xs="4">
            <div onClick={() => onShare("copylink")} className={classes.cont}>
              <LinkIcon className={classes.iconCopy}></LinkIcon>
            </div>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
};

MobileShare.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation(["challenges"])(
  withStyles(styles, { withTheme: true })(memo(MobileShare)),
);
