const useRouteInfo = (path = {}) => {
  const { pathname = "/" } = path;
  const isOnChoosingPlan = pathname && pathname.startsWith("/chooseplan");
  const isOnSubscription = pathname && pathname.startsWith("/subscription");
  const isOnDashboard = pathname && pathname.startsWith("/dashboard");
  const isOnProfile = pathname && pathname.startsWith("/profile");
  const isOnExplore = pathname && pathname.startsWith("/explore");
  const isOnAuth = pathname && pathname.startsWith("/auth");
  const isOnTracking = pathname && pathname.startsWith("/tracking");
  const isOnUtmb = pathname && pathname.startsWith("/chooseplan-utmb");
  const isOnSkyrunner = pathname && pathname.startsWith("/chooseplan-sws");
  const isOnXterra = pathname && pathname.startsWith("/chooseplan-xterra");
  const showChoosePlanHeader =
    pathname &&
    (pathname.includes("/chooseplan") || pathname.startsWith("/subscription"));
  const isOnTrailhead =
    (pathname && pathname.startsWith("/trailhead")) ||
    pathname.startsWith("/community");
  const isOnChallenges = pathname && pathname.startsWith("/challenges");
  const isOnSuggesterResults =
    pathname && pathname.startsWith("/suggester/results");
  const isOnSuggester = pathname && pathname.startsWith("/suggester");
  const isOnPreSignUp = pathname && pathname.startsWith("/presignup");
  const choosePlanVariant =
    pathname.includes("chooseplan/coach-choose") ||
    pathname.includes("chooseplan/explore") ||
    pathname.includes("subscription/explore") ||
    pathname.includes("chooseplan/free") ||
    pathname.includes("subscription/premium") ||
    pathname.includes("/auth") ||
    pathname.includes("/welcome") ||
    pathname.includes("/subscription");

  const isOnTraining = pathname && pathname.startsWith("/training");
  const activeSpacingContent =
    isOnDashboard ||
    isOnProfile ||
    isOnTrailhead ||
    isOnTraining ||
    isOnChoosingPlan ||
    isOnExplore;
  return {
    pathname,
    isOnChoosingPlan,
    isOnSubscription,
    isOnDashboard,
    isOnProfile,
    isOnExplore,
    isOnAuth,
    isOnTracking,
    isOnUtmb,
    isOnSkyrunner,
    isOnXterra,
    showChoosePlanHeader,
    isOnTrailhead,
    isOnChallenges,
    isOnSuggesterResults,
    isOnSuggester,
    isOnPreSignUp,
    choosePlanVariant,
    isOnTraining,
    activeSpacingContent,
  };
};

export default useRouteInfo;
