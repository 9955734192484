import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import PlanDrawer from "./plan-drawer.js";
import Fields from "../../../constants/plan-drawer-fields";
import withWidth from "@material-ui/core/withWidth";
import { emitter } from "@marvelapp/react-ab-test";

const PlanPreSignupQuestions = ({
  onButtonSelectFieldChange,
  onFieldError,
  onFieldsInit,
  t,
  currentStep,
  onBackRequested,
  width,
}) => {
  const [goalItemState, setGoalItemState] = useState([]);

  const isInMobileViewSm = width === "sm" || width === "xs";

  useEffect(() => {
    if (isInMobileViewSm) {
      const mobileVariant = emitter.getActiveVariant("landing-mobile-option");
      if (!mobileVariant) return setGoalItemState(newGoalItems);
      return mobileVariant == "B"
        ? setGoalItemState(newGoalItems)
        : setGoalItemState(goalItems);
    }

    if (!isInMobileViewSm) {
      const webVariant = emitter.getActiveVariant("landing-option");
      if (!webVariant) return setGoalItemState(newGoalItems);
      return webVariant == "B"
        ? setGoalItemState(newGoalItems)
        : setGoalItemState(goalItems);
    }
  }, []);

  const goalItems = [
    {
      text: t("Training for my first Ultramarathon"),
      value: 1,
      icon: "icon-goal-1.svg",
    },
    {
      text: t("Training to start trail running"),
      value: 3,
      icon: "icon-goal-2.svg",
    },
    {
      text: t("Training to improve my trail performances"),
      value: 2,
      icon: "icon-goal-3.svg",
    },
    {
      text: t("Trail & Ultra’s content and challenges"),
      value: 4,
      icon: "icon-goal-5.svg",
    },
    {
      text: t("A great community of trail  runners"),
      value: 5,
      icon: "icon-goal-4.svg",
    },
  ];

  const newGoalItems = [
    {
      text: t("Start Trail running"),
      value: 3,
      icon: "pre_signup/new_start_icon.svg",
    },
    {
      text: t("Run my first Ultramarathon"),
      value: 1,
      icon: "pre_signup/new_ultra_icon.svg",
    },
    {
      text: t("Become a better trail runner"),
      value: 2,
      icon: "pre_signup/new_better_trail_icon.svg",
    },
  ];

  const stepsCount = 1;

  const fields = [
    [
      {
        fieldType: Fields.Items.IMAGE,
        image: "pre-signup-cover.jpeg",
      },
      {
        fieldType: Fields.Items.STEPPER,
        xs: 12,
        sm: 12,
        md: 12,
        currentStep: currentStep - 1,
        stepsCount: stepsCount - 1,
      },
      {
        fieldType: Fields.Items.LABEL,
        xs: 12,
        sm: 12,
        md: 12,
        label: t("What brings yout to Vert?"),
        subLabel: t("This will help us personalize recomendations for you"),
        labelNumber: currentStep + 1,
      },
      {
        fieldType: Fields.Items.BUTTON_SELECT,
        id: "currentGoal",
        xs: 12,
        sm: 12,
        md: 12,
        items: goalItemState,
        onError: onFieldError,
        onSelect: onButtonSelectFieldChange,
      },
    ],
  ];

  onFieldsInit(fields);
  return (
    <PlanDrawer
      currentStep={currentStep}
      fields={fields[currentStep]}
      onBackRequested={onBackRequested}
    />
  );
};

PlanPreSignupQuestions.propTypes = {
  planTempData: PropTypes.object.isRequired,
  currentData: PropTypes.object.isRequired,
  onButtonSelectFieldChange: PropTypes.func.isRequired,
  onFieldError: PropTypes.func.isRequired,
  onFieldsInit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(["plan", "constants"])(
  withWidth()(memo(PlanPreSignupQuestions)),
);
