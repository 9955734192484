import trailHead from "../../../components/trail-head";
import CheckoutView from "../views/CheckoutView";
import PremiumCheckoutView from "../views/PremiumCheckoutView";

const routes = [
  {
    path: "/subscription/explore",
    component: CheckoutView,
    private: true,
  },
  {
    path: "/subscription/premium",
    component: PremiumCheckoutView,
    private: true,
  },
  {
    path: "/trailhead",
    component: trailHead,
    private: true,
  },
];

export default routes;
