import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Fab, Avatar, Chip } from "@material-ui/core";

import FontAwesomeIcon from "../../common/font-awesome-icon";

const styles = (theme) => ({
  container: {
    display: "flex",
    padding: theme.spacing.unit,
    position: "relative",
  },
  fab: {
    margin: theme.spacing.unit,
    backgroundColor: "indianred",
    color: theme.palette.common.white,
  },
  avatar: {
    width: 56,
    height: 56,
    alignSelf: "center",
  },
  nameContainer: {
    textAlign: "left",
    alignSelf: "center",
  },
  title: {
    lineHeight: 1,
  },
  badge: {
    position: "absolute",
    right: 5,
    color: "#ffffff",
  },
});

class AthletePlanUser extends Component {
  render() {
    const {
      classes,
      selectedAthlete,
      selectedAthleteSubscription,
    } = this.props;
    const {
      cognito_user_name,
      cognito_user_family_name,
      cognito_user_email,
      imageUrl,
    } = selectedAthlete;

    return (
      <div className={classes.container}>
        <Fab className={classes.fab}>
          {imageUrl ? (
            <Avatar src={imageUrl} className={classes.avatar} />
          ) : (
            <FontAwesomeIcon icon="user-circle" variant="regular" size="2x" />
          )}
        </Fab>
        <div className={classes.nameContainer}>
          <Typography variant="h6" className={classes.title}>
            {cognito_user_name} {cognito_user_family_name}
          </Typography>
          <Typography variant="subtitle2" className={classes.title}>
            {cognito_user_email}
          </Typography>
        </div>
        {selectedAthleteSubscription && selectedAthleteSubscription.status ? (
          <Chip
            style={{
              backgroundColor:
                selectedAthleteSubscription.status.toLowerCase() === "active"
                  ? "green"
                  : selectedAthleteSubscription.status.toLowerCase() ===
                      "expired" ||
                    selectedAthleteSubscription.status.toLowerCase() ===
                      "cancelled"
                  ? "red"
                  : selectedAthleteSubscription.status.toLowerCase() ===
                    "non_renewing"
                  ? "blue"
                  : selectedAthleteSubscription.status.toLowerCase() ===
                    "active"
                  ? selectedAthleteSubscription.status.toLowerCase() ===
                    "in_trial"
                  : "#FFB300",
            }}
            className={classes.badge}
            label={selectedAthleteSubscription.status}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

AthletePlanUser.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedAthlete: PropTypes.object.isRequired,
  selectedAthleteSubscription: PropTypes.object,
};

export default withStyles(styles)(AthletePlanUser);
