import axios from "axios";

class Geocode {
  static apikey = "AIzaSyDvsPHCc4-zK3lSvWG3lvLP_bcK0jg2n-s";

  static fromLatLng = async (lat, lng) => {
    if (lat !== null && !isNaN(lat) && lng !== null && !isNaN(lng)) {
      try {
        const { data } = await axios({
          baseURL: "https://maps.googleapis.com",
          url: "/maps/api/geocode/json",
          params: {
            latlng: `${lat},${lng}`,
            key: this.apikey,
            language: "en",
          },
        });
        return data;
      } catch (error) {
        const { response } = error;
        if (response && response.data) {
          const { data, status } = response;
          const { error = {} } = data;

          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (typeof error === "string")
            throw new Error(error || `Request failed with status ${status}`);
          else throw error;
        } else {
          // Something happened in setting up the request that triggered an Error
          throw error;
        }
      }
    } else throw new Error("Invalid LAT/LNG provided to Geocode");
  };
}

export default Geocode;
