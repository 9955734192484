import ActionTypes from "../constants/action-types";
import VertActivityService from "../services/vert-activity-service";
import { Storage } from "aws-amplify";

const getActivitiesVert = (param, reload = false) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.VERT_LOAD_ACTIVITIES_PENDING,
      payload: reload,
    });
    try {
      const result = await VertActivityService.getActivities(param);
      dispatch({
        type: ActionTypes.VERT_LOAD_ACTIVITIES_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.VERT_LOAD_ACTIVITIES_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getActivityVert = (param) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.VERT_GET_ACTIVITY_PENDING });
    try {
      const result = await VertActivityService.getActivity(param);
      dispatch({
        type: ActionTypes.VERT_GET_ACTIVITY_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.VERT_GET_ACTIVITY_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const postActivityVert = (activity) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.VERT_ADD_ACTIVITY_PENDING });
    try {
      await VertActivityService.postActivity(activity);
      dispatch({
        type: ActionTypes.VERT_ADD_ACTIVITY_FULFILLED,
        payload: activity,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.VERT_ADD_ACTIVITY_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const setCurrentActivityVert = (activity) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VERT_SET_ACTIVITY,
      payload: activity,
    });
  };
};

const clearActivityVert = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.VERT_CLEAR_ACTIVITY });
  };
};

const addWatcher = (activity) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.VERT_SET_WATCHER,
      payload: activity,
    });
  };
};

const clearWatchers = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.VERT_CLEAR_WATCHERS });
  };
};

const editActivityVert = (data) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_VERT_ACTIVITY_PENDING });
    try {
      const result = await VertActivityService.editActivity(data);
      dispatch({
        type: ActionTypes.UPDATE_VERT_ACTIVITY_FULLFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.UPDATE_VERT_ACTIVITY_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const deleteVertActivity = (data) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_VERT_ACTIVITY_PENDING });
    try {
      const result = await VertActivityService.deleteActivity(data);
      await Storage.remove(
        `vertactivities/${data.cognito_user_sub}${data.created_at.toString()}`,
      );
      dispatch({
        type: ActionTypes.DELETE_VERT_ACTIVITY_FULFILLED,
        payload: result,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.DELETE_VERT_ACTIVITY_REJECTED,
        payload: error,
        error: true,
      });
    }
  };
};

const clearVertActivities = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.VERT_CLEAR_ACTIVITIES });
  };
};

export {
  setCurrentActivityVert,
  getActivitiesVert,
  getActivityVert,
  clearActivityVert,
  postActivityVert,
  addWatcher,
  clearWatchers,
  editActivityVert,
  clearVertActivities,
  deleteVertActivity,
};
