import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const styles = (theme) => ({
  main: {
    padding: "16px",
    boxSizing: "border-box",
    backgroundColor: "#F9F9FB",
    borderRadius: "4px",
    margin: "0 0px 16px 0px",
  },
});

const Card = ({ children, classes, className }) => (
  <div className={classNames(classes.main, className)}>{children}</div>
);

export default withStyles(styles, { withTheme: true })(Card);
