import React, { useEffect, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { palette } from "../../../theme/palette";
import usePost from "../../../hooks/usePost";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CommunityCardSkeleton from "./CommunityCardSkeleton";
import PostCardHomeComponent from "./PostCardHomeComponent";

const CommunityHomePreview = () => {
  const classes = useStyles();
  const { t } = useTranslation("home");
  const history = useHistory();

  const { posts, loadingPosts, handleGetUserPosts } = usePost();
  const [items, setItems] = useState([]);

  const goToTrailhead = () => history.push("trailhead");

  useEffect(() => {
    if (posts === null || posts.data === null || posts.data.length === 0) {
      handleGetUserPosts(new Date().getTime(), "", "", false, true);
    }
  }, []);

  useEffect(() => {
    if (posts && posts.data && Array.isArray(posts.data)) {
      const sortedItems = posts.data
        .sort((a, b) => b.created_at - a.created_at)
        .slice(0, 3);
      setItems(sortedItems);
    }
  }, [posts]);

  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.header}>
          <Typography variant="h3" className={classes.bold}>
            {t("home.community.new")}
          </Typography>
          <Typography
            variant="body2"
            className={classes.seeAll}
            id="home_see_all_community"
            onClick={goToTrailhead}
          >
            {t("home.community.seeAll")}
          </Typography>
        </Box>
      </Box>
      {loadingPosts ? (
        <Box className={classes.carousel}>
          {[...Array(3)].map((_, index) => (
            <CommunityCardSkeleton key={index} className={classes.card} />
          ))}
        </Box>
      ) : (
        <Box className={classes.carousel}>
          {items.map((item) => (
            <PostCardHomeComponent key={item.id} postData={item} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CommunityHomePreview;

const useStyles = makeStyles({
  carousel: {
    display: "-webkit-inline-box",
    width: "100%",
    overflowY: "hidden",
    overflowX: "scroll",
    scrollbarWidth: "none",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  seeAll: {
    color: palette.lightBlue,
  },
  bold: {
    fontFamily: "Karla",
    fontSize: "22px",
    lineHeight: "33px",
    letterSpacing: "0px",
    fontWeight: "700",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
});
