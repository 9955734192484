import ActionTypes from "../constants/action-types";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { formatDate } from "../lib/date-helper";
dayjs.extend(isoWeek);

const initialState = {
  currentFilter: 2,
  currentDate: dayjs()
    .startOf("week")
    .add(1, "day")
    .format(formatDate),
  toDate: dayjs()
    .startOf("week")
    .add(1, "week")
    .format(formatDate),
  screen: 0,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.SET_PROFILE_CURRENT_FILTER:
        return {
          ...state,
          currentFilter: action.payload,
        };

      case ActionTypes.SET_PROFILE_CURRENT_DATE:
        return {
          ...state,
          ...action.payload,
        };

      case ActionTypes.SET_PROFILE_SCREEN:
        return {
          ...state,
          screen: action.payload,
        };

      default:
        return state;
    }
  } else {
    return state;
  }
}
