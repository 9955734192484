import React, { useState } from "react";
import {
  Box,
  Link,
  Slide,
  Dialog,
  Typography,
  Button,
  FormControl,
  OutlinedInput,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import history from "../../../lib/history-helper";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthContext } from "../../../context/AuthProvider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const OnboardingSignUpEmailConfirm = () => {
  const [openDialog, setOpenDialog] = useState(true);
  const {
    email,
    setConfirmationCode,
    confirmationCode,
    globalErrorMessage,
    errorMessage,
    resetErrorMessage,
    confirmSignUp,
    confirmSignUpLoading,
    resendConfirmationCode,
  } = useAuthContext();

  const { t } = useTranslation("onboarding");
  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleTransitionEnd = () => {
    resetErrorMessage();
    history.goBack();
  };

  const handleConfirm = () => {
    resetErrorMessage();
    confirmSignUp();
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionProps={{ onExited: handleTransitionEnd }}
      TransitionComponent={Transition}
      display={"flex"}
      flexDirection="column"
    >
      <ToolbarReturnBack
        withLogo={true}
        logoWidth={107}
        style={{
          backgroundColor: "#FAFAFA",
          alignItems: "flex-start",
          padding: "10px",
        }}
        goBack={handleCloseDialog}
      />

      <Box className={classes.parentBox}>
        <Box className={classes.contentContainer}>
          <Box className={classes.titleBox}>
            <Typography className={classes.title}>
              {t("onboarding.signupConfirm.title")}
            </Typography>
          </Box>
          <Typography className={classes.title}>{email}</Typography>

          <Typography className={classes.subTitle}>
            {t("onboarding.signupConfirm.enterCode")}
          </Typography>
          <FormControl fullWidth error={errorMessage} hiddenLabel size="small">
            <OutlinedInput
              first
              value={confirmationCode}
              onChange={(e) => {
                setConfirmationCode(e.target.value);
              }}
              placeholder={t("onboarding.signupConfirm.codePlaceholder")}
              color="secondary"
              aria-label="naked"
              className={classes.inputForm}
              type="text"
            />
            <FormHelperText className={classes.errorCardText}>
              {t(errorMessage)}
            </FormHelperText>
          </FormControl>
          <Typography className={classes.notReceivedText}>
            {t("onboarding.signupConfirm.notReceived")}{" "}
            <Link
              onClick={resendConfirmationCode}
              underline="none"
              className={classes.notReceivedButton}
            >
              {t("onboarding.signupConfirm.resend")}
            </Link>
          </Typography>
        </Box>
        {globalErrorMessage != "" && (
          <Typography variant="caption" color="error">
            {t(globalErrorMessage)}
          </Typography>
        )}
        <Button
          disabled={confirmSignUpLoading}
          variant="contained"
          onClick={handleConfirm}
          className={classes.logInButton}
        >
          {confirmSignUpLoading ? (
            <CircularProgress />
          ) : (
            t("onboarding.signupConfirm.confirmButton")
          )}
        </Button>
      </Box>
    </Dialog>
  );
};

export default OnboardingSignUpEmailConfirm;

const useStyles = makeStyles({
  parentBox: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    padding: "16px",
    gap: "12px",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  title: {
    fontFamily: "Oswald",
    fontWeight: 700,
    fontSize: "22px",
    color: "#12151B",
    textTransform: "uppercase",
    lineHeight: "27.5px",
  },

  subtitle: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#12151B",
    lineHeight: "24px",
  },
  contentContainer: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginTop: "40px",
  },
  emailButton: {
    backgroundColor: "#FEFFFF",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  googleButton: {
    backgroundColor: "#FEFFFF",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  facebookButton: {
    backgroundColor: "#3C5A9A",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  appleButton: {
    backgroundColor: "#12151B",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  darkButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#FEFFFF",
  },
  lightButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#12151B",
  },
  emailButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#0B2B5C",
  },
  logInButton: {
    backgroundColor: "#0B2B5C",
    height: "47px",
    color: "#FFFFFF",
    borderRadius: "4px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  dividerText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 700,
  },
  notReceivedText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "24px",
    fontSize: "16px",
    fontWeight: 400,
  },
  signUpText: {
    alignSelf: "center",
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 400,
  },
  notReceivedButton: {
    color: "#4780AB",
    fontWeight: 700,
    cursor: "pointer",
  },
  loginButton: {
    color: "#F93C6E",
    fontWeight: 700,
    cursor: "pointer",
  },
  errorCardText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
});
