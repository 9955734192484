import React, { memo, useEffect, useState } from "react";
import Subcategories from "./choose-plan/subcategories";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Fab,
  Avatar,
  Dialog,
  DialogContent,
  LinearProgress,
  Grid,
  Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import LanguageMenu from "./layout/language-menu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { getLevel } from "../lib/rates-helper";
import history from "../lib/history-helper";
import { getImage } from "../lib/common";
import { storageFactory } from "../lib/storage-factory";
import { toggleOpenMenu } from "../actions/user-plans-action";
import AppAuthenticator from "./app-authenticator";
import ReserveModal from "./generals/reserve-modal";
import { postReserveSpot, clearPost } from "../actions/subcategory-action";

import moment from "moment";

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing.unit * 8,
    paddingRight: theme.spacing.unit * 8,
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 5,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    },
  },
  topContainer: {
    position: "sticky",
    top: 0,
    padding: "15px 10px 1px",
    zIndex: 999,

    [theme.breakpoints.down("sm")]: {
      padding: "15px 10px 1px",
      borderRadius: "0 0 12px 12px",
    },
  },
  contentTitleContainer: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 1.6,
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: 22,
    marginBottom: 5,
    color: "#000",
    lineHeight: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      textAlign: "left",
    },
  },
  containerLang: {
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
    },
  },
  logoImg: {
    height: 50,
    margin: "auto",
  },
  signInButton: {
    fontFamily: "Montserrat",
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    justifyContent: "space-between",
    zIndex: 999,
    width: "100%",
    marginTop: 8,
  },
  levelMiniBadge: {
    height: 25,
    bottom: -5,
    right: -15,
    position: "absolute",
  },
  title: {
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      fontSize: 18,
      marginTop: 2,
    },
  },
  favs: {
    marginLeft: "auto",
    marginRight: 15,
  },
  titleHeader: {
    width: "50%",
    fontSize: "16px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      width: "90%",
    },
  },
  titleParent: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      margin: "16px 0px 12px 0px",
    },
  },
  tag: {
    backgroundColor: "rgba(27, 96, 168, 0.12)",
    padding: "4px 16px",
    borderRadius: "4px",
    width: "content-fit",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 16px",
    },
    border: "solid 1px #000D44",
  },
  bold: {
    fontWeight: "bold",
  },
  tagParent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
  },
  icon: {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    cursor: "pointer",
  },
  linearProgress: {
    zIndex: 10001,
    margin: "0px -74px",
    marginBottom: "10px",
  },
  main: {
    width: "100%",
  },
  topBar: {
    display: "flex",
    marginBottom: 10,
    justifyContent: "space-between",
  },
  imgParent: {
    display: "flex",
    alignItems: "center",
    marginRight: "auto",
  },
  mobileSubtitle: {
    textAlign: "center",
    color: "#000",
    marginBottom: 16,
  },
  mainTitle: {
    marginTop: 25,
    marginBottom: 20,
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bigCard: {
    marginTop: 50,
    background: "#F4F4F5",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    padding: "20px",
    boxSizing: "border-box",

    [theme.breakpoints.down("sm")]: {
      margin: "25px 12px 12px 12px",
    },
  },
  cardText: {
    fontSize: 16,
    marginLeft: 16,
    width: "80%",
  },
  listItem: {
    fontSize: 18,
    cursor: "pointer",
    fontWeight: "500",
    "&:first-child": {
      marginBottom: "10px",
    },
  },
  filtersCardParent: {
    marginTop: "10px",
    position: "sticky",
    height: "fit-content",
    top: 65,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  filtersCard: {
    background: "#F4F4F5",
    width: "100%",
    borderRadius: "6px",
    padding: "20px",
    boxSizing: "border-box",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  mobileCardTitle: {
    fontWeight: "500",
    textAlign: "center",
  },
  mobileCardText: {
    marginTop: "8px",
    textAlign: "center",
  },
});

const ChoosePlanXTerra = ({ classes, t, width }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, currentUser } = useSelector((state) => state.auth);
  const { attributes = {} } = currentUser || {};
  const { currentPlan } = useSelector((state) => state.userPlans);
  const { loadingReserve, reserveResponse } = useSelector(
    (state) => state.subcategories,
  );

  const [avatarUrl, setAvatarUrl] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { accumulatedDistance, accumulatedElevation, image } =
    currentPlan || {};
  const isInMobileViewSm = width === "sm" || width == "xs";

  const level = getLevel(accumulatedDistance, accumulatedElevation) || 0;

  const setImage = async (image) => {
    try {
      const result = await getImage(image);
      setAvatarUrl(result);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (image) setImage(image);
  }, [image]);

  const handleOpenSignUp = () => setOpenSignUp((signUp) => !signUp);
  const handleOpenSignIn = () => setOpenSignIn((signIn) => !signIn);

  const xterraFilter = {
    tags: "X-terra World series",
    training_surfaces: [],
    distance_range: { min: 0, max: 0 },
  };

  const handleBack = () => history.push("/chooseplan");

  const toogleMenu = () => {
    if (isAuthenticated) {
      storageFactory().removeItem("currentGoal");
      dispatch(toggleOpenMenu());
    } else {
      handleOpenSignUp();
    }
  };

  const reserveMySpot = async (event, localCategory) => {
    event.stopPropagation();

    const { name, email } = attributes || {};

    const body = {
      name,
      email,
      race: localCategory.title["en"],
      date: moment().format("DD/M/YYYY"),
      sheet: "Xterra",
    };

    if (isAuthenticated) return await dispatch(postReserveSpot(body));
    handleOpenSignUp();
  };

  useEffect(() => {
    if (reserveResponse && reserveResponse.success) setShowModal(true);

    return () => {
      dispatch(clearPost());
    };
  }, [reserveResponse]);

  const handleClose = () => {
    dispatch(clearPost());
    setShowModal(false);
  };

  return (
    <div className={classes.main}>
      {isInMobileViewSm && (
        <div className={classes.searchBar}>
          <div className={classes.row} onClick={handleBack}>
            <ArrowBackIosIcon fontSize="small" style={{ marginLeft: "12px" }} />
            <Typography variant="body2">{t("Back to Menu")}</Typography>
            <div />
          </div>

          <div className={classes.row}>
            <img
              src={`${process.env.PUBLIC_URL}/vertrun-logo-wide.png`}
              alt="utmb-logo"
              style={{ width: 55 }}
            />
            <img
              src={`${process.env.PUBLIC_URL}/xterra-icon.svg`}
              alt="utmb-logo"
              style={{ marginLeft: 5, width: 55 }}
            />
          </div>

          <LanguageMenu />
        </div>
      )}

      {loadingReserve && (
        <LinearProgress
          className={classes.linearProgress}
          variant="indeterminate"
          color="primary"
        />
      )}

      <div className={classes.content}>
        <div className={classes.bigCard}>
          {!isInMobileViewSm && (
            <img
              alt="xterra-logo"
              src={`${process.env.PUBLIC_URL}/xterra-icon.svg`}
            />
          )}

          {!isInMobileViewSm && (
            <Typography
              className={classes.cardText}
              dangerouslySetInnerHTML={{
                __html: t("Achieve xterra"),
              }}
            />
          )}

          {isInMobileViewSm && (
            <div>
              <Typography className={classes.mobileCardTitle}>
                {t("Choose your Xterra® Training Plan")}
              </Typography>

              <Typography className={classes.mobileCardText}>
                {t(
                  "Access personal coaching support and a specific training plan designed for your Xterra® World Series race.",
                )}
              </Typography>
            </div>
          )}
        </div>
        <Typography variant="h2" className={classes.mainTitle}>
          {t("X-terra® World Series")}
        </Typography>

        <Grid container spacing={2}>
          <Grid item md={3} className={classes.filtersCardParent}>
            <Box className={classes.filtersCard}>
              <Typography
                className={classes.listItem}
                variant="body2"
                style={{ color: "#E16327" }}
              >
                {t("X-terra® World Series")}
              </Typography>
              <Typography
                className={classes.listItem}
                variant="body2"
                onClick={handleBack}
              >
                {t("Go back to main menu")}
              </Typography>
            </Box>
          </Grid>

          <Grid item md={9}>
            <Subcategories
              filter={xterraFilter}
              filtersApplied={true}
              hideFav
              reserveMySpot={reserveMySpot}
              onOpenAuth={handleOpenSignUp}
            />
          </Grid>
        </Grid>

        <Dialog
          open={openSignIn}
          onClose={handleOpenSignIn}
          aria-labelledby="signIn"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signindialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={handleOpenSignIn}
              />
            </div>
            <AppAuthenticator initialState="signIn" />
          </DialogContent>
        </Dialog>

        <Dialog
          open={openSignUp}
          onClose={handleOpenSignUp}
          aria-labelledby="signUp"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signupdialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={handleOpenSignUp}
              />
            </div>
            <AppAuthenticator initialState="signUp" />
          </DialogContent>
        </Dialog>

        <ReserveModal open={showModal} onClose={handleClose} />
      </div>
    </div>
  );
};

export default withTranslation("plan")(
  withWidth()(withStyles(styles, { withTheme: true })(memo(ChoosePlanXTerra))),
);
