import React from "react";
import { CircleChart } from "./components/circle-chart";
import { StatsSection } from "./components/stats-section";
import { ProfilePlanLoadChart } from "./components/profile-plan-load";
import { ProfileActivityChart } from "./components/profile-activity-chart";
import ProfileSelect from "../../common/v2/ProfileSelect";
import MountainIndexSection from "./components/mountain-index-section";

export const HomeProfile = () => {
  return (
    <>
      <ProfileSelect />
      <CircleChart />
      <StatsSection />
      <MountainIndexSection showNextRace={false} />
      <ProfilePlanLoadChart />
      <ProfileActivityChart />
    </>
  );
};
