import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
} from "@material-ui/core";

import AthleteTrainingWorkoutChips from "./athlete-training-workout-chips";
import { convertToRTFForView } from "../../../lib/rtf-helper";

const styles = (theme) => ({
  workout: {
    border: "1px solid lightgray",
    borderRadius: 8,
    margin: theme.spacing.unit,
    padding: theme.spacing.unit / 2,
    cursor: "pointer",
  },
});

class AthleteTrainingWorkout extends Component {
  state = {
    descriptionOpen: false,
    currentWorkout: null,
  };

  handleDragStart = (workout, event) => {
    const { onSelectWorkout } = this.props;
    if (onSelectWorkout) onSelectWorkout(workout);
  };

  handleCloseDescription = () => {
    this.setState({
      ...this.state,
      descriptionOpen: false,
      currentWorkout: null,
    });
  };

  handleOpenDescription = (workout) => {
    this.setState({
      ...this.state,
      descriptionOpen: true,
      currentWorkout: workout,
    });
  };

  render() {
    const { classes, workouts, level } = this.props;
    const { descriptionOpen, currentWorkout } = this.state;

    return (
      <>
        {workouts.map((workout, index) => {
          return (
            <div
              draggable={true}
              className={classes.workout}
              onDragStart={this.handleDragStart.bind(this, workout)}
              key={index}
              onClick={() => this.handleOpenDescription(workout)}
            >
              <Typography variant="body1">
                {workout.title &&
                  (workout.title["en"]
                    ? workout.title["en"]
                    : workout.title["es"])}
              </Typography>
              <AthleteTrainingWorkoutChips workout={workout} level={level} />
            </div>
          );
        })}

        <Dialog
          open={descriptionOpen}
          onClose={() => this.handleCloseDescription()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle>
            {currentWorkout &&
              currentWorkout.title &&
              (currentWorkout.title["en"] || currentWorkout.title["es"])}
          </DialogTitle>
          <DialogContent
            id="form-dialog-title"
            dangerouslySetInnerHTML={convertToRTFForView(
              `${
                currentWorkout &&
                currentWorkout.description &&
                currentWorkout.description["en"]
                  ? currentWorkout.description["en"]
                  : ""
              }<hr>${
                currentWorkout &&
                currentWorkout.description &&
                currentWorkout.description["es"]
                  ? currentWorkout.description["es"]
                  : ""
              }`,
            )}
          ></DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleCloseDescription()}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

AthleteTrainingWorkout.propTypes = {
  classes: PropTypes.object.isRequired,
  workouts: PropTypes.array.isRequired,
  onSelectWorkout: PropTypes.func.isRequired,
};

export default withStyles(styles)(AthleteTrainingWorkout);
