import React, { PureComponent, memo } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import AppAuthenticator from "./app-authenticator";
import LanguageMenu from "./layout/language-menu";
import { Link, Modal, DialogContent } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { I18n } from "aws-amplify";
import { withTranslation } from "react-i18next";
import { animateScroll, scroller } from "react-scroll";
import { Capacitor } from "@capacitor/core";
import externalLinksEn from "../constants/external-links-en";
import ConnectAuth from "../connectors/connect-auth";
import { Redirect } from "react-router-dom";
import SubCategories from "./choose-plan/subcategories";
import RouterLink from "./common/router-link";
import SuggesterResults from "./suggester-results";
import ConnectUserPlans from "../connectors/connect-user-plans";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import { storageFactory } from "../lib/storage-factory";
import ContactModal from "./generals/contact-modal";
import { emitter } from "@marvelapp/react-ab-test";
import { logEvent } from "../lib/events-helper";

const styles = (theme) => ({
  container: {
    width: "100%",
  },

  containerLang: {
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
    },
  },
  buttonLang: {
    color: "#000",

    [theme.breakpoints.up("md")]: {
      fontWeight: "normal",
      fontSize: 18,
      color: "#fff",
    },
  },

  topGrid: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/landing-hero-image.jpg)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundPosition: "center",
    height: "100vh",
    width: "100%",
    backgroundAttachment: "fixed",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 1274,
      margin: "auto",
      width: "100%",
    },
  },

  topGridOld: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/landing_background.png)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundPosition: "center",
    height: "100vh",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 1274,
      margin: "auto",
      width: "100%",
    },
  },

  secondGrid: {
    maxWidth: 1274,
    margin: "auto",
    marginBottom: 30,
  },
  thirdGrid: {
    padding: "70px 0",
  },
  topGridLeft: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${process.env.PUBLIC_URL}/landing-mobile-hero-image.jpg)`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      padding: 0,
    },
  },

  topGridLeftOld: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${process.env.PUBLIC_URL}/mobile-landing-page.jpg)`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      padding: 0,
    },
  },

  appLading: {
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${process.env.PUBLIC_URL}/landing-mobile-hero-image.jpg)`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      padding: 1,
      height: "100vh",
      backgroundPosition: "center",
    },
  },

  appLadingOld: {
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${process.env.PUBLIC_URL}/landing-mobile-hero-image.png)`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      padding: 1,
      height: "100vh",
    },
  },

  appLandingTop: {
    textAlign: "center",
    padding: "30px 20px",
  },

  appTitle: {
    fontFamily: "Avenir",
    fontWeight: 800,
    fontSize: 35,
    textAlign: "center",
    color: "#FFFFFF",
    margin: "0 20px",
  },
  appTitle2: {
    color: "#FFFFFF",
    fontSize: 30,
    textAlign: "left",
    fontFamily: "Avenir",
    fontWeight: 800,
    textShadow:
      "0px 3px 3px rgb(0 0 0 / 14%), 0px 3px 4px rgb(0 0 0 / 12%), 0px 1px 8px rgb(0 0 0 / 20%)",
    marginTop: 30,
    textTransform: "uppercase",
  },

  appTitle2Old: {
    fontFamily: "Avenir",
    fontWeight: 800,
    fontSize: 22,
    textAlign: "center",
    color: "#FFFFFF",
    margin: "0 10px",
    textTransform: "uppercase",
  },

  appSubTitle: {
    fontFamily: "Avenir",
    fontSize: 17,
    textAlign: "center",
    color: "#FFFFFF",
    margin: "0 10px",
    fontWeight: 400,
  },

  logoImg: {
    height: 70,
    margin: "auto",
  },
  secondGridLeft: {
    padding: "0 30px 0 30px",
  },
  topImg: {
    width: "100%",
  },
  topBar: {
    display: "flex",
  },
  title: {
    color: "#FFF",
    fontSize: 55,
    fontWeight: 600,
    lineHeight: "1.2em",
    fontFamily: "Montserrat",

    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
      color: "#ffffff",
      marginLeft: 20,
      marginTop: 100,
      marginRight: 40,
    },
  },

  titleFlex: {
    display: "flex",
    alignItems: "center",
    marginBottom: 200,
  },

  titleL1: {
    color: "#FFF",
    fontSize: 80,
    fontWeight: 300,
    lineHeight: "80px",
    fontFamily: "Montserrat",
    marginBottom: 10,

    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginTop: 200,
      marginRight: 40,
      fontSize: 30,
      lineHeight: "30px",
    },
  },

  titleL2: {
    color: "#FFF",
    fontSize: 80,
    fontWeight: 900,
    lineHeight: "90px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      fontSize: 30,
      lineHeight: "35px",
    },
  },

  subtitle: {
    fontSize: 35,
    margin: "10px 0",
    fontFamily: "Montserrat",
    color: "#FFF",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: 21,
      margin: "30px 0",
      fontFamily: "Montserrat",
      textAlign: "left",
    },
  },
  quote: {
    fontSize: 19,
    fontWeight: 200,
    margin: "30px 0",
    marginRight: 130,
    fontFamily: "Montserrat",
    marginTop: 5,
    textTransform: "uppercase",

    [theme.breakpoints.down("sm")]: {
      color: "#ffffff",
      fontSize: 16,
      marginLeft: 20,
      fontWeight: 400,
      marginTop: 10,
      marginBottom: 0,
    },
  },
  topAction: {
    textAlign: "center",
    marginRight: 130,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  signUpButtonApp: {
    fontSize: 20,
    fontWeight: 600,
    borderRadius: 10,
    padding: "12px 30px",
    marginTop: 20,
    fontFamily: "Montserrat",

    [theme.breakpoints.down("sm")]: {
      padding: "3px 30px",
      fontSize: 25,
      margin: "20px 30px 5px 30px",
      height: 40,
      textTransform: "none",
    },
  },

  signUpButtonAppOld: {
    fontSize: 20,
    fontWeight: 600,
    borderRadius: 10,
    padding: "12px 30px",
    marginTop: 20,
    fontFamily: "Montserrat",

    [theme.breakpoints.down("sm")]: {
      padding: "3px 30px",
      fontSize: 16,
      margin: "20px 30px 5px 30px",
      height: 40,
    },
  },

  signUpButton: {
    fontSize: 20,
    fontWeight: 600,
    borderRadius: 10,
    padding: "12px 30px",
    marginTop: 20,
    fontFamily: "Montserrat",

    [theme.breakpoints.down("sm")]: {
      padding: "3px 30px",
      marginTop: 60,
      marginBottom: 90,
    },
  },
  appBox: {
    position: "absolute",
    bottom: 0,
    textAlign: "center",
    width: "100%",
  },

  signInButton: {
    fontFamily: "Montserrat",

    [theme.breakpoints.down("sm")]: {
      color: "#000",
    },
  },
  videolink: {
    cursor: "pointer",
    fontFamily: "Montserrat",
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      color: "#fff",
      fontWeight: "normal",
      marginBottom: 50,
      fontSize: 16,
    },
  },
  descContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 50,
    },
  },
  descEntry: {
    display: "flex",
    marginRight: 95,
    marginBottom: 60,

    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginRight: 0,
      textAlign: "center",
    },
  },
  descIcon: {
    fontSize: 50,
    marginRight: 20,

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: 30,
    },
  },
  descTitle: {
    fontSize: 25,
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginBottom: 10,
  },
  descText: {
    color: "#636363",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "1.5em",
  },
  testimony: {
    backgroundColor: "#F6F6F6",
    padding: 20,
    borderRadius: 15,
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#FFFFFF",
      margin: "0 10px",
    },
  },
  testimonyText: {
    color: "#4c4c4c",
    fontSize: 17,
    fontWeight: 100,
    fontStyle: "italic",
    lineHeight: "1.9em",
  },
  testimonyAuthor: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    marginTop: 20,
  },
  testimonyPlan: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: 13,
    lineHeight: "1.3em",
  },
  trustPilotImg: {
    width: 150,
    display: "block",
    margin: "auto",
  },
  pricesTitleContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: 70,
    marginBottom: 50,
  },
  pricesTitle: {
    fontFamily: "Montserrat",
    fontSize: 52,
    fontWeight: 600,

    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
      fontWeight: 500,
    },
  },
  pricesSubtitle: {
    fontFamily: "Montserrat",
    color: "#616161",
    fontSize: 30,
    fontWeight: "normal",

    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      fontWeight: 300,
    },
  },

  plans: {
    margin: "80px 0 80px 30px",
    display: "flex",
    position: "relative",
  },

  plansTitleContainer: {
    width: "100%",
    padding: 15,
  },
  plansTitle: {
    fontFamily: "Montserrat",
    fontSize: 42,
    fontWeight: 600,
  },
  plansSubtitle: {
    fontSize: 30,
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: "#616161",
    marginTop: 30,
  },

  plansAction: {
    color: "#FF5722",
    fontSize: 30,
    marginTop: 20,
    fontFamily: "Montserrat",
    fontWeight: 600,
    cursor: "pointer",
  },

  plansArrows: {
    position: "absolute",
    bottom: 0,
    right: 20,
    width: 50,
    display: "flex",
    justifyContent: "space-between",
  },

  challenges: {
    margin: "80px 30px",
    display: "flex",
  },

  challengesTitleContainer: {
    width: "100%",
    padding: "15px 40px",
  },
  challengesTitle: {
    fontFamily: "Montserrat",
    fontSize: 52,
    fontWeight: 600,
  },
  challengesSubtitle: {
    fontSize: 30,
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: "#616161",
    marginTop: 30,
  },

  challengesAction: {
    color: "#FF5722",
    fontSize: 30,
    marginTop: 20,
    fontFamily: "Montserrat",
    fontWeight: 600,
  },

  column1Item: {
    order: 1,
  },
  column2Item: {
    order: 2,
  },
  column3Item: {
    order: 3,
  },
  testimonyItem: {
    [theme.breakpoints.down("sm")]: {
      order: 4,
    },
  },

  column1: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 20px 0px rgb(114 114 114 / 50%)",
    transition:
      "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s",
    textAlign: "center",
    margin: "20px 20px 20px 20px",
    padding: "40px 50px",
    borderRadius: "10px 10px 10px 10px",
    width: 300,
    minHeight: 945,
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
      padding: "20px 50px",
    },
  },

  column2: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 20px 0px rgb(114 114 114 / 50%)",
    transition:
      "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s",
    textAlign: "center",
    margin: "20px 20px 20px 20px",
    padding: "40px 50px",
    borderRadius: "10px 10px 10px 10px",
    width: 300,
    minHeight: 945,
  },

  column3: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 20px 0px rgb(114 114 114 / 50%)",
    transition:
      "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s",
    textAlign: "center",
    margin: "20px 20px 20px 20px",
    padding: "40px 50px",
    borderRadius: "10px 10px 10px 10px",
    width: 300,
    minHeight: 945,

    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
      padding: "20px 50px",
    },
  },

  column1Top: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: "5.9px",
  },
  column1TopPrice: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: 65,
    fontWeight: 600,
  },
  column1TopType: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: 17,
    fontweight: 300,
    letterSpacing: "5.9px",
  },

  column1OnlyOnApp: {
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 500,
    marginTop: 16,
  },

  column2First7Days: {
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 500,
    marginTop: 16,
  },

  columnIncluded: {
    textAlign: "left",
    margin: "30px 0 20px",
    fontWeight: 600,
    fontSize: 16,
  },

  divider1: {
    margin: "40px 0",
    width: "100%",
    display: "block",
  },
  dividerLine1: {
    borderTop: "3px solid #000",
    width: "20%",
    margin: "0 auto",
    display: "block",
  },
  column1Entry: {
    display: "flex",
    alignItems: "baseline",
  },
  column1EntryIcon: {
    fontSize: 16,
    marginRight: 20,
  },
  column1EntryText: {
    textAlign: "left",
    fontSize: 15,
    fontWeight: 300,
    fontFamily: "Montserrat",
    lineHeight: "36px",
    marginRight: 10,
  },
  column1SignUpButton: {
    padding: "8px 40px",
  },

  column2Top: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: "5.9px",
  },
  column2TopPrice: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: 65,
    fontWeight: 600,
  },
  column2TopPerMonth: {
    color: "#000000",
  },
  column2TopType: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: 17,
    fontweight: 300,
    letterSpacing: "5.9px",
  },
  divider2: {
    margin: "40px 0",
    width: "100%",
    display: "block",
  },
  dividerLine2: {
    borderTop: "3px solid #000000",
    width: "20%",
    margin: "0 auto",
    display: "block",
  },
  column2Entry: {
    display: "flex",
    alignItems: "baseline",
  },
  column2EntryIcon: {
    fontSize: 16,
    marginRight: 20,
    color: "#000000",
  },
  column2EntryText: {
    color: "#000000",
    textAlign: "left",
    fontSize: 15,
    fontWeight: 300,
    fontFamily: "Montserrat",
    lineHeight: "36px",
  },
  column2SignUpButton: {
    padding: "8px 40px",
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },
  videoContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 560,
    height: 315,

    [theme.breakpoints.down("md")]: {
      width: 355,
      height: 200,
    },
  },
  video: {
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: 10,
  },
  noPadding: {
    padding: "0 !important",
  },
  error: {
    fontSize: 12,
    color: "red",
    fontWeight: 400,
    lineHeight: "18px",
    margin: "10px 0",
  },
  appBanner: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
  },
  appIcon: {
    height: 64,
    padding: "0 10px",
  },
  appBannerText: {
    fontSize: 14,
  },
  install: {
    marginLeft: "auto",
    color: "#ff6327",
    textDecoration: "none",
  },
  appPopupTitle: {
    color: "#282828",
    fontSize: 35,
    fontFamily: "Montserrat",
    fontWeight: 600,
    textAlign: "center",
  },
  appButton: {
    display: "block",
    margin: 35,
    width: "calc(100% - 70px)",
  },

  suggesterSelect: {
    background: "#fff",
    padding: 5,
    borderRadius: 5,
    border: "solid 1px",
    width: 300,
  },

  suggesterQ: {
    fontFamily: "Montserrat",
    fontSize: 16,
    color: "#fff",
    margin: "10px 5px 5px",
  },

  suggesterRadioRoot: {
    color: "#fff",
    "&checked": {
      color: "#fff !important",
    },
  },

  suggesterRadioLabel: {
    color: "#fff",
  },

  suggesterRadios: {
    width: 300,
  },

  whatis: {
    padding: "40px 0",
  },

  whatisTitleContainer: {
    width: "100%",
    textAlign: "center",
    marginBottom: 30,
  },

  whatisTitle: {
    fontFamily: "Montserrat",
    fontSize: 52,
    fontWeight: 600,
  },

  whatisSubtitle: {
    color: "#616161",
    fontFamily: "Montserrat",
    fontSize: 30,
    fontWeight: 400,
  },

  whatisGridContainer: {
    width: "calc(100% - 80px)",
    margin: 40,
  },

  whatisGridIcon: {
    margin: "-35px -35px 0",
  },

  whatisGridTitle: {
    color: "#fff",
    margin: "0 24px",
    fontSize: 30,
    fontFamily: "Montserrat",
    fontWeight: 600,
  },

  whatisGridSubtitle: {
    color: "#3d4459",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 300,
    margin: "0 0 20px",
  },

  whatisDiv: {
    margin: "auto",
    boxShadow: "0px 0px 20px 0px rgb(0 0 0 / 5%)",
    height: 440,
    borderRadius: 10,
    width: 352,
  },

  whatisGridLink: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: 15,
    fill: "#FF6327",
    color: "#FF6327",
    backgroundColor: "rgba(255,255,255,0)",
    padding: "0px 0px 0px 0px",
  },

  whatisGridLinkTxt: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: 15,
    fill: "#FF6327",
    color: "#FF6327",
    backgroundColor: "rgba(255,255,255,0)",
    padding: "0px 0px 0px 0px",
    marginRight: 10,
    fontWeight: 200,
  },

  nextIcon: {
    position: "absolute",
    bottom: "90px",
    left: 80,
    cursor: "pointer",
  },

  arrowRight: {
    marginLeft: 24,
  },

  suggesterContainer: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/landing_suggester.png)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    padding: "100px 0",
  },

  suggesterTitle: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 30,
    textAlign: "center",
    color: "#616161",
  },

  suggesterSubtitle: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 52,
    textAlign: "center",
    color: "#212121",
  },

  suggesterRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  suggesterButton: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 20,
    background: "#FFFFFF",
    boxShadow: "0px 10px 60px rgb(0 0 0 / 10%)",
    borderRadius: 12,
    padding: 30,
    minWidth: 350,
    textAlign: "center",
    margin: 15,
    cursor: "pointer",
    position: "relative",
    overflow: "hidden",
  },

  suggesterSelected: {
    position: "absolute",
    top: -1,
    right: -1,
  },

  suggesterBottom: {
    display: "flex",
    width: "calc(100% - 80px)",
    marginTop: 80,
  },

  suggesterCurrent: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 60,
    color: "#212121",
    cursor: "pointer",
  },

  suggesterBottomDivider: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 60,
    color: "#212121",
    opacity: 0.2,
    margin: "0px 20px",
  },

  imgContainer: {
    position: "relative",
  },
  img: {
    width: "100%",
    borderRadius: "10px 10px 0 0",
  },
  badge: {
    height: "90%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  titleCh: {
    fontFamily: "Avenir",
    fontWeight: 900,
    fontSize: 18,
    color: "#202020",
    margin: "12px 0",
    textAlign: "left",
    textTransform: "uppercase",
  },
  description: {
    fontFamily: "Avenir",
    fontWeight: "normal",
    fontSize: 16,
    textAlign: "left",
    color: "#9E9E9E",
  },
  difficulty: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 14,
    background: "rgba(255, 87, 34, 0.24)",
    width: "fit-content",
    padding: "0px 5px",
    marginTop: 10,
  },

  textContainer: {
    padding: "0 16px 16px",
  },

  challenge: {
    boxShadow: "0px 17.551px 105.306px rgb(0 0 0 / 10%)",
    borderRadius: 20,
    maxWidth: 600,
  },

  community: {
    padding: "40px 0",
  },

  communityTitle: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 52,
    textAlign: "center",
  },

  communitySubtitle: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 30,
    textAlign: "center",
    margin: "30px 15%",
    color: "#616161",
  },

  communityRow: {
    width: "max-content",
    marginBottom: 24,
  },

  communityImg: {
    display: "inline-block",
    borderRadius: 12,
    margin: "0 12px",
  },

  navBar: {
    margin: "20px 20px 70px",
    display: "flex",
    alignItems: "center",
  },

  logo: {
    height: 44,
    marginRight: 50,
  },

  menuItem: {
    fontWeight: "normal",
    fontSize: 18,
    color: "#FFFFFF",
    marginRight: 30,
    cursor: "pointer",
  },

  signInButtonNav: {
    fontFamily: "Montserrat",

    color: "#fff",
    marginLeft: "auto",

    fontWeight: "normal",
    fontSize: 18,

    textTransform: "initial",

    marginRight: 20,
  },

  signUpButtonNav: {
    fontFamily: "Montserrat",

    color: "#fff",

    fontWeight: "normal",
    fontSize: 18,

    textTransform: "initial",
  },

  titleCont: {
    marginBottom: 120,
    marginLeft: 80,
    marginRight: 200,
    maxWidth: 700,
  },

  signUpButtonTitle: {
    fontFamily: "Montserrat",

    color: "#fff",

    fontWeight: 600,
    fontSize: 24,

    textTransform: "initial",
    padding: "50px 15px",
    width: "325px",

    marginLeft: "auto",
  },

  appIcons: {
    display: "flex",
    alignItems: "center",
  },

  newTitle: {
    fontSize: 66,
    [theme.breakpoints.down("sm")]: {
      // marginLeft: 20,
      // marginRight: 150,
      fontSize: 30,
      // lineHeight: "35px",
    },
  },

  newTitleCont: {
    maxWidth: 750,
  },
  extraMargin: {
    marginTop: 150,
  },
});

class AuthLanding extends PureComponent {
  state = {
    openSignIn: false,
    openSignUp: false,
    openVideo: false,
    openApp: false,
    openAppBanner:
      !Capacitor.isNativePlatform() &&
      (this.detectIOS() || this.detectAndroid()),
    openSuggester: false,
    goal: null,
    training: null,
    distance: null,
    currentQuestion: 0,
    inactiveUser: false,
  };

  interval = setInterval(
    this.setState({ ...this.state, inactiveUser: true }),
    60000 * 2,
  );

  detectIOS() {
    const toMatch = [/iPhone/i, /iPad/i, /iPod/i];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  detectAndroid() {
    const toMatch = [/Android/i];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  componentWillMount() {
    if (localStorage.getItem("authlink")) {
      const authlink = localStorage.getItem("authlink");
      localStorage.removeItem("authlink");
      window.location.href = authlink;
    }
  }

  componentDidMount() {
    localStorage.removeItem("challengesUser");
    window.addEventListener("mousemove", this.startTimer);
    this.startTimer();

    const isInMobileViewSm =
      this.props.width === "sm" || this.props.width === "xs";

    const currentVariant = emitter.getActiveVariant("landing-option");
    const currentMobileVariant = emitter.getActiveVariant(
      "landing-mobile-option",
    );

    if (localStorage.getItem("error")) {
      storageFactory().removeItem("currentGoal");
      this.props.setGeneralError(I18n.get(localStorage.getItem("error")));
    }

    if (storageFactory().getItem("currentGoal")) {
      this.handleOpenSignUp();
    } else {
      storageFactory().removeItem("currentGoal");
    }

    const { i18n, match } = this.props;

    if (i18n) {
      const { language } = i18n;
      if (language) I18n.setLanguage(language);
    }

    const { params } = match;
    const { action } = params;
    if (action) {
      if (action === "signup") {
        this.handleOpenSignUp();
      }
      if (action === "signin") {
        this.handleOpenSignIn();
      }
    }
  }

  startTimer = () => {
    clearInterval(this.interval);
    this.interval = setInterval(this.userStatus, 60000 * 2);
  };

  userStatus = () => {
    this.setState({ ...this.state, inactiveUser: true });
  };

  handleOpenSignUp = (isUnderTitle = false) => {
    localStorage.removeItem("error");

    const isInMobileViewSm =
      this.props.width === "sm" || this.props.width === "xs";

    const currentVariant = emitter.getActiveVariant("landing-option");
    const currentMobileVariant = emitter.getActiveVariant(
      "landing-mobile-option",
    );

    const { history } = this.props;
    if (!storageFactory().getItem("currentGoal")) {
      history.push("/presignup");
      // history.push({
      //   pathname: "/presignup",
      //   state: isInMobileViewSm ? currentMobileVariant : currentVariant,
      // });
    } else {
      this.setState({
        ...this.state,
        openSignUp: true,
        openSuggester: false,
      });
    }
  };

  handleOpenSignIn = () => {
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignIn: true,
      openSuggester: false,
    });
  };

  handleCloseSignUp = () => {
    const {
      location: { pathname },
      history,
    } = this.props;
    localStorage.removeItem("error");
    storageFactory().removeItem("currentGoal");
    this.setState({
      ...this.state,
      openSignUp: false,
    });
    if (pathname === "/auth/signup") {
      history.push("/auth");
    }
  };

  handleCloseSignIn = () => {
    const {
      location: { pathname },
      history,
    } = this.props;
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignIn: false,
    });
    if (pathname === "/auth/signin") {
      history.push("/auth");
    }
  };

  handleOpen = () => {
    this.setState({
      ...this.state,
      openVideo: true,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      openVideo: false,
    });
  };

  handleCloseAppBanner = () => {
    this.setState({
      ...this.state,
      openAppBanner: false,
    });
  };

  handleOpenApp = () => {
    this.setState({
      ...this.state,
      openApp: true,
    });
  };

  handleCloseApp = () => {
    this.setState({
      ...this.state,
      openApp: false,
    });
  };

  handleOpenSuggester = () => {
    this.setState({
      ...this.state,
      openSuggester: true,
    });
  };

  handleCloseSuggester = () => {
    this.setState({
      ...this.state,
      openSuggester: false,
    });
  };

  onGoalSelectChange = (value) => {
    this.setState({
      ...this.state,
      goal: value,
    });
    this.props.planChangeField({ name: "currentGoal", value: value });
    setTimeout(
      () =>
        this.setState({
          ...this.state,
          currentQuestion: 1,
        }),
      500,
    );
  };

  onTrainingSelectChange = (value) => {
    this.setState({
      ...this.state,
      training: value,
    });
    this.props.planChangeField({ name: "currentlyTraining", value: value });
    setTimeout(
      () =>
        this.setState({
          ...this.state,
          currentQuestion: 2,
        }),
      500,
    );
  };

  onDistanceSelectChange = (value) => {
    this.setState({
      ...this.state,
      distance: +value,
    });
    this.props.planChangeField({ name: "longestRun", value: value });
    setTimeout(() => this.handleOpenSuggester(), 500);
  };

  scrollLeft = () => {
    const subcategoriesElement = document.getElementById("subcategories");
    if (subcategoriesElement) {
      subcategoriesElement.scrollTo({
        left: subcategoriesElement.scrollLeft - 100,
        behavior: "smooth",
      });

      this.setState({
        ...this.state,
        scrollTimeout: setTimeout(() => {
          this.scrollLeft();
        }, 100),
      });
    }
  };

  scrollRight = () => {
    const subcategoriesElement = document.getElementById("subcategories");
    if (subcategoriesElement) {
      subcategoriesElement.scrollTo({
        left: subcategoriesElement.scrollLeft + 100,
        behavior: "smooth",
      });
      this.setState({
        ...this.state,
        scrollTimeout: setTimeout(() => {
          this.scrollRight();
        }, 100),
      });
    }
  };

  handleHideMessage = () => {
    this.props.clearGeneralError();
  };

  closeModal = () => {
    this.setState({ ...this.state, inactiveUser: false });
  };

  render() {
    const { classes, width, i18n, auth, location = {}, t } = this.props;
    const {
      openSignIn,
      openSignUp,
      openVideo,
      openAppBanner,
      openApp,
      goal,
      training,
      distance,
      openSuggester,
      currentQuestion,
    } = this.state;
    const isInMobileViewSm = width === "sm" || width === "xs";
    const { language } = i18n || {};
    const { isAuthenticated, generalErrorMessage } = auth;

    const trustPilotImg = (
      <Link
        className={classes.externalLink}
        href="https://www.trustpilot.com/review/vert.run"
        target="_blank"
      >
        <img
          src={`${process.env.PUBLIC_URL}/trustpilot-logo.png`}
          alt=""
          className={classes.trustPilotImg}
        />
      </Link>
    );

    const testimony = (
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={classes.testimonyItem}
      >
        <div className={classes.testimony}>
          <Typography
            variant="body1"
            className={classes.testimonyText}
            dangerouslySetInnerHTML={{ __html: I18n.get("testimony") }}
          ></Typography>
          <Typography variant="body1" className={classes.testimonyAuthor}>
            EMMA A.
          </Typography>
          <Typography variant="body1" className={classes.testimonyPlan}>
            {I18n.get("Explore plan")}
          </Typography>
          {isInMobileViewSm ? trustPilotImg : ""}
        </div>
        <div>
          {!isInMobileViewSm ? trustPilotImg : ""}
          <Button
            color="primary"
            variant="contained"
            className={classes.signUpButton}
            onClick={this.handleOpenSignUp}
            size="large"
            style={{
              display: "block",
              margin: !isInMobileViewSm ? "0 auto" : "30px auto 0 auto",
            }}
          >
            {I18n.get("SIGN UP FOR FREE!")}
          </Button>
        </div>
      </Grid>
    );

    return isAuthenticated ? (
      <Redirect
        to={{
          pathname: "/dashboard",
          state: { from: location },
        }}
      />
    ) : (
      <div className={classes.container}>
        {!isInMobileViewSm && this.state.inactiveUser && (
          <ContactModal
            open={this.state.inactiveUser}
            onClose={this.closeModal}
          />
        )}

        {openAppBanner && (
          <div className={classes.appBanner}>
            <img
              src={`${process.env.PUBLIC_URL}/close.svg`}
              alt=""
              onClick={this.handleCloseAppBanner}
            />

            <img
              src={`${process.env.PUBLIC_URL}/appicon.png`}
              alt=""
              className={classes.appIcon}
            />

            <Typography variant="body1" className={classes.appBannerText}>
              {I18n.get(
                this.detectAndroid()
                  ? "Vert.run for Android"
                  : "Vert.run for iPhone",
              )}
            </Typography>

            <Link
              className={classes.install}
              href={
                this.detectAndroid()
                  ? externalLinksEn.AndroidApp
                  : externalLinksEn.iOSApp
              }
              target="_blank"
            >
              <Typography variant="body1" className={classes.install}>
                {I18n.get("Get the App")}
              </Typography>
            </Link>
          </div>
        )}
        <Modal
          open={openVideo}
          onClose={this.handleClose}
          onBackdropClick={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.videoContainer}>
            <iframe
              title="Intro Video"
              className={classes.video}
              src="https://www.youtube.com/embed/kMIABQ-vWD4?autoplay=1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            ></iframe>
          </div>
        </Modal>
        {Capacitor.isNativePlatform() ? (
          <div className={classes.appLading}>
            <div className={classes.appLandingTop}>
              <img
                src={`${process.env.PUBLIC_URL}/vertrun-logo-white.png`}
                alt=""
                className={classes.logoImg}
              />
              <Typography
                variant="body1"
                className={classNames(
                  classes.appTitle2,
                  Capacitor.isNativePlatform() &&
                    Capacitor.getPlatform() === "android"
                    ? classes.extraMargin
                    : null,
                )}
                dangerouslySetInnerHTML={{
                  __html: t("Trail running training for everyone"),
                }}
              ></Typography>

              <div className={classes.appIcons}>
                <img
                  src={`${process.env.PUBLIC_URL}/app-icon-1.png`}
                  alt=""
                  className={classes.appIconTitle}
                  style={{
                    height: 30,
                  }}
                />

                <img
                  src={`${process.env.PUBLIC_URL}/app-icon-2.png`}
                  alt=""
                  className={classes.appIconTitle}
                  style={{
                    marginLeft: 8,
                    height: 30,
                  }}
                />

                <img
                  src={`${process.env.PUBLIC_URL}/app-icon-3.png`}
                  alt=""
                  className={classes.appIconTitle}
                  style={{
                    height: 38,
                  }}
                />

                <img
                  src={`${process.env.PUBLIC_URL}/app-icon-4.png`}
                  alt=""
                  className={classes.appIconTitle}
                  style={{
                    height: 36,
                  }}
                />
              </div>
            </div>
            <div className={classes.appBox}>
              <Button
                color="primary"
                variant="contained"
                className={classes.signUpButtonApp}
                onClick={() => {
                  this.handleOpenSignUp();
                  const userAgent =
                    navigator.userAgent || navigator.vendor || window.opera;
                  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    setTimeout(() => {
                      animateScroll.scrollToTop({
                        containerId: "signupdialog",
                        duration: 0,
                      });
                    }, 250);
                  }
                }}
                size="large"
              >
                {t("Sign up Free Mobile")}
              </Button>

              <Typography
                variant="body1"
                className={classes.videolink}
                onClick={() => {
                  this.handleOpenSignIn();
                  const userAgent =
                    navigator.userAgent || navigator.vendor || window.opera;
                  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    setTimeout(() => {
                      animateScroll.scrollToTop({
                        containerId: "signindialog",
                        duration: 0,
                      });
                    }, 250);
                  }
                }}
              >
                {t("Already a member?")}{" "}
                <span style={{ color: "#FF6327" }}>{t("Log in")}</span>
              </Typography>
            </div>
          </div>
        ) : (
          <>
            <Grid container className={classes.topGrid}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.topGridLeft}
              >
                {isInMobileViewSm ? (
                  <>
                    <div className={classes.topBar}>
                      <LanguageMenu
                        classes={{
                          container: classes.containerLang,
                          button: classes.buttonLang,
                        }}
                      />

                      <img
                        src={`${process.env.PUBLIC_URL}/vertrun-logo-wide.png`}
                        alt=""
                        className={classes.logoImg}
                      />

                      <Button
                        color="primary"
                        className={classes.signInButton}
                        onClick={this.handleOpenSignIn}
                      >
                        {I18n.get("Log in")}
                      </Button>
                    </div>

                    <Typography variant="h6" className={classes.titleL1}>
                      {"VERT.RUN"}
                      {": "}
                    </Typography>

                    <Typography variant="h6" className={classes.titleL2}>
                      {I18n.get("RUNNING COACHING FOR EVERYONE")}
                    </Typography>

                    <div className={classes.topAction}>
                      {localStorage.getItem("error") ? (
                        <Typography variant="h2" className={classes.error}>
                          {I18n.get(localStorage.getItem("error"))}
                        </Typography>
                      ) : (
                        ""
                      )}

                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.signUpButton}
                        onClick={() => {
                          this.handleOpenSignUp();
                          const userAgent =
                            navigator.userAgent ||
                            navigator.vendor ||
                            window.opera;
                          if (
                            /iPad|iPhone|iPod/.test(userAgent) &&
                            !window.MSStream
                          ) {
                            setTimeout(() => {
                              animateScroll.scrollToTop({
                                containerId: "signupdialog",
                                duration: 0,
                              });
                            }, 250);
                          }
                        }}
                        size="large"
                      >
                        {I18n.get("Create Free Account")}
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.navBar}>
                      <img
                        src={`${process.env.PUBLIC_URL}/logo-white.png`}
                        alt=""
                        className={classes.logo}
                      />

                      <Typography
                        variant="h6"
                        className={classes.menuItem}
                        onClick={() => {
                          scroller.scrollTo("whatis", {
                            duration: 1000,
                            smooth: true,
                          });
                        }}
                      >
                        {I18n.get("About us")}
                      </Typography>

                      <Typography
                        variant="h6"
                        className={classes.menuItem}
                        onClick={() => {
                          scroller.scrollTo("plans", {
                            duration: 1000,
                            smooth: true,
                          });
                        }}
                      >
                        {I18n.get("Training plans")}
                      </Typography>

                      <Typography
                        variant="h6"
                        className={classes.menuItem}
                        onClick={() => {
                          scroller.scrollTo("suggester", {
                            duration: 1000,
                            smooth: true,
                          });
                        }}
                      >
                        {I18n.get("Plan suggester")}
                      </Typography>

                      <Typography
                        variant="h6"
                        className={classes.menuItem}
                        onClick={() => {
                          scroller.scrollTo("community", {
                            duration: 1000,
                            smooth: true,
                          });
                        }}
                      >
                        {I18n.get("Community")}
                      </Typography>

                      <Typography
                        variant="h6"
                        className={classes.menuItem}
                        onClick={() => {
                          scroller.scrollTo("challenges", {
                            duration: 1000,
                            smooth: true,
                          });
                        }}
                      >
                        {I18n.get("Challenges")}
                      </Typography>

                      <LanguageMenu
                        classes={{
                          container: classes.containerLang,
                          button: classes.buttonLang,
                        }}
                      />

                      <Button
                        color="primary"
                        className={classes.signInButtonNav}
                        onClick={this.handleOpenSignIn}
                      >
                        {I18n.get("Log in")}
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.signUpButtonNav}
                        onClick={this.handleOpenSignUp}
                      >
                        {I18n.get("Sign Up")}
                      </Button>
                    </div>

                    <div className={classes.titleFlex}>
                      <div
                        className={classNames(
                          classes.titleCont,
                          language == "es" ? classes.newTitleCont : null,
                        )}
                      >
                        <Typography variant="h6" className={classes.titleL1}>
                          {"VERT.RUN"}
                          {": "}
                        </Typography>

                        <Typography variant="h6" className={classes.titleL2}>
                          {I18n.get("RUNNING COACHING FOR EVERYONE")}
                        </Typography>

                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          className={classes.signUpButtonNav}
                          onClick={() => this.handleOpenSignUp(true)}
                        >
                          {t("Sign Up Free")}
                        </Button>
                      </div>

                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.signUpButtonTitle}
                        onClick={this.handleOpenSignUp}
                      >
                        {I18n.get("Create Free Account")}
                        <img
                          src={`${process.env.PUBLIC_URL}/arrow-landing-sign.svg`}
                          alt=""
                        />
                      </Button>
                    </div>

                    <img
                      src={`${process.env.PUBLIC_URL}/arrow-landing.svg`}
                      alt=""
                      onClick={() => {
                        scroller.scrollTo("whatis", {
                          duration: 1000,
                          smooth: true,
                        });
                      }}
                      className={classes.nextIcon}
                    />
                  </>
                )}
              </Grid>
            </Grid>

            {isInMobileViewSm ? (
              <Grid container className={classes.secondGrid}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classes.secondGridLeft}
                >
                  <div className={classes.descContainer}>
                    <div className={classes.descEntry}>
                      <i
                        aria-hidden="true"
                        className={classNames(
                          "fas",
                          "fa-universal-access",
                          classes.descIcon,
                        )}
                      ></i>
                      <div>
                        <Typography variant="h6" className={classes.descTitle}>
                          {I18n.get("You're unique -- so is your plan")}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.descText}
                        >
                          {I18n.get(
                            "Your training plan adapts to your level of experience, current fitness, changing schedule, training conditions and goals.",
                          )}
                        </Typography>
                      </div>
                    </div>

                    <div className={classes.descEntry}>
                      <i
                        aria-hidden="true"
                        className={classNames(
                          "far",
                          "fa-calendar-check",
                          classes.descIcon,
                        )}
                      ></i>
                      <div>
                        <Typography variant="h6" className={classes.descTitle}>
                          {I18n.get("Includes coaching")}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.descText}
                        >
                          {I18n.get(
                            "Your coach keeps you on track, answers your questions, and leaves you feedback and encouragement on a weekly basis.",
                          )}
                        </Typography>
                      </div>
                    </div>

                    <div className={classes.descEntry}>
                      <i
                        aria-hidden="true"
                        className={classNames(
                          "fa",
                          "fa-globe-americas",
                          classes.descIcon,
                        )}
                      ></i>
                      <div>
                        <Typography variant="h6" className={classes.descTitle}>
                          {I18n.get("Designed by world-class athletes")}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.descText}
                        >
                          {I18n.get(
                            "Our training plans are designed by professional trail runners--winners of the most competitive and prestigious Ultramarathons in the world.",
                          )}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <>
                <div className={classes.whatis} id="whatis">
                  <div className={classes.whatisTitleContainer}>
                    <Typography variant="h6" className={classes.whatisTitle}>
                      {I18n.get("What's Vert.Run?")}
                    </Typography>

                    <Typography
                      variant="h6"
                      className={classes.whatisSubtitle}
                      dangerouslySetInnerHTML={{
                        __html: I18n.get("Its simple"),
                      }}
                    ></Typography>
                  </div>

                  <Grid container className={classes.whatisGridContainer}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      className={classes.whatisGrid}
                    >
                      <RouterLink
                        to="/chooseplan"
                        className={classes.whatisGridLink}
                      >
                        <div
                          className={classes.whatisDiv}
                          style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${process.env.PUBLIC_URL}/francesco.png)`,
                            marginTop: 80,
                          }}
                        >
                          <Typography
                            variant="h6"
                            className={classes.whatisGridTitle}
                          >
                            Coach
                          </Typography>
                          <img
                            src={`${process.env.PUBLIC_URL}/landing-arrow-right.svg`}
                            alt=""
                            className={classes.arrowRight}
                          />
                        </div>
                      </RouterLink>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      className={classes.whatisGrid}
                    >
                      <RouterLink
                        to="/chooseplan"
                        className={classes.whatisGridLink}
                      >
                        <div
                          className={classes.whatisDiv}
                          style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${process.env.PUBLIC_URL}/nici.png)`,
                          }}
                        >
                          <Typography
                            variant="h6"
                            className={classes.whatisGridTitle}
                          >
                            Coach
                          </Typography>
                          <img
                            src={`${process.env.PUBLIC_URL}/landing-arrow-right.svg`}
                            alt=""
                            className={classes.arrowRight}
                          />
                        </div>
                      </RouterLink>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      className={classes.whatisGrid}
                    >
                      <RouterLink
                        to="/chooseplan"
                        className={classes.whatisGridLink}
                      >
                        <div
                          className={classes.whatisDiv}
                          style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${process.env.PUBLIC_URL}/steve.png)`,
                            marginTop: 80,
                          }}
                        >
                          <Typography
                            variant="h6"
                            className={classes.whatisGridTitle}
                          >
                            Coach
                          </Typography>
                          <img
                            src={`${process.env.PUBLIC_URL}/landing-arrow-right.svg`}
                            alt=""
                            className={classes.arrowRight}
                          />
                        </div>
                      </RouterLink>
                    </Grid>
                  </Grid>
                </div>

                <div className={classes.plans} id="plans">
                  <div className={classes.plansTitleContainer}>
                    <Typography variant="h6" className={classes.plansTitle}>
                      {I18n.get("Training plans")}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={classes.plansSubtitle}
                      dangerouslySetInnerHTML={{
                        __html: I18n.get(
                          "Get fitter and faster with our training plans, designed by <b>world class athletes</b> for trail runners of all kinds.",
                        ),
                      }}
                    ></Typography>

                    <RouterLink
                      to="/chooseplan"
                      className={classes.whatisGridLink}
                    >
                      <Typography variant="h6" className={classes.plansAction}>
                        {I18n.get("Find your plan")}
                      </Typography>
                    </RouterLink>

                    <img
                      src={`${process.env.PUBLIC_URL}/landing-arrow-right-o.svg`}
                      alt=""
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        scroller.scrollTo("suggester", {
                          duration: 1000,
                          smooth: true,
                        });
                      }}
                    />
                  </div>
                  <SubCategories
                    filter={this.state.currentFilter}
                    onOpenAuth={this.handleOpenSignUp}
                    landing={true}
                    hideFav={true}
                  />

                  <div className={classes.plansArrows}>
                    <img
                      src={`${process.env.PUBLIC_URL}/arrow-left.svg`}
                      alt=""
                      onMouseDown={() => {
                        this.scrollLeft();
                      }}
                      onMouseUp={() => {
                        clearTimeout(this.state.scrollTimeout);
                      }}
                      style={{ cursor: "pointer" }}
                    />

                    <img
                      src={`${process.env.PUBLIC_URL}/arrow-right.svg`}
                      alt=""
                      onMouseDown={() => {
                        this.scrollRight();
                      }}
                      onMouseUp={() => {
                        clearTimeout(this.state.scrollTimeout);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>

                <div className={classes.suggesterContainer} id="suggester">
                  <Typography variant="h6" className={classes.suggesterTitle}>
                    {I18n.get("Find your personalized training plan")}
                  </Typography>

                  {currentQuestion === 0 ? (
                    <>
                      <Typography
                        variant="h6"
                        className={classes.suggesterSubtitle}
                      >
                        {I18n.get("What’s your next goal?")}
                      </Typography>

                      <div className={classes.suggesterRow}>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onGoalSelectChange(0)}
                        >
                          {I18n.get("Run my first Ultramarathon")}

                          {goal === 0 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onGoalSelectChange(1)}
                        >
                          {I18n.get("Get back in trail shape")}
                          {goal === 1 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onGoalSelectChange(2)}
                        >
                          {I18n.get("Start trail running")}
                          {goal === 2 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className={classes.suggesterRow}>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onGoalSelectChange(3)}
                        >
                          {I18n.get("Perform better in races")}
                          {goal === 3 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onGoalSelectChange(4)}
                        >
                          {I18n.get("Run my next Ultra")}
                          {goal === 4 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onGoalSelectChange(5)}
                        >
                          {I18n.get("Train with a free plan")}
                          {goal === 5 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : currentQuestion === 1 ? (
                    <>
                      <Typography
                        variant="h6"
                        className={classes.suggesterSubtitle}
                      >
                        {I18n.get("Are you currently training?")}
                      </Typography>

                      <div className={classes.suggesterRow}>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onTrainingSelectChange(0)}
                        >
                          {I18n.get("Not really, want to start from zero")}
                          {training === 0 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onTrainingSelectChange(1)}
                        >
                          {I18n.get("Kind of. Less than 3h per week")}
                          {training === 1 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className={classes.suggesterRow}>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onTrainingSelectChange(2)}
                        >
                          {I18n.get("Yeah. About 5h per week")}
                          {training === 2 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onTrainingSelectChange(3)}
                        >
                          {I18n.get("Im training 6+ hours per week")}
                          {training === 3 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : currentQuestion === 2 ? (
                    <>
                      <Typography
                        variant="h6"
                        className={classes.suggesterSubtitle}
                      >
                        {I18n.get("What has been your longer race ever?")}
                      </Typography>

                      <div className={classes.suggesterRow}>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onDistanceSelectChange(0)}
                        >
                          {I18n.get("0k")}
                          {distance === 0 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onDistanceSelectChange(10)}
                        >
                          {I18n.get("<20k")}
                          {distance === 10 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className={classes.suggesterRow}>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onDistanceSelectChange(20)}
                        >
                          {I18n.get("<42K")}
                          {distance === 20 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className={classes.suggesterButton}
                          onClick={() => this.onDistanceSelectChange(50)}
                        >
                          {I18n.get("50k+")}
                          {distance === 50 ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/landing-selected.svg`}
                              alt=""
                              className={classes.suggesterSelected}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className={classes.suggesterBottom}>
                    <div
                      className={classes.suggesterCurrent}
                      style={{
                        marginLeft: "auto",
                        opacity: currentQuestion === 0 ? 1 : 0.2,
                      }}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          currentQuestion: 0,
                        })
                      }
                    >
                      01
                    </div>
                    <div className={classes.suggesterBottomDivider}>|</div>
                    <div
                      className={classes.suggesterCurrent}
                      style={{
                        opacity: currentQuestion === 1 ? 1 : 0.2,
                      }}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          currentQuestion: 1,
                        })
                      }
                    >
                      02
                    </div>
                    <div className={classes.suggesterBottomDivider}>|</div>
                    <div
                      className={classes.suggesterCurrent}
                      style={{
                        opacity: currentQuestion === 2 ? 1 : 0.2,
                      }}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          currentQuestion: 2,
                        })
                      }
                    >
                      03
                    </div>
                  </div>
                </div>

                <div className={classes.challenges} id="challenges">
                  <div className={classes.challengesTitleContainer}>
                    <Typography
                      variant="h6"
                      className={classes.challengesTitle}
                    >
                      {I18n.get("Free Video Workouts")}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={classes.challengesSubtitle}
                      dangerouslySetInnerHTML={{
                        __html: I18n.get("From quick 5-min"),
                      }}
                    ></Typography>
                    <RouterLink
                      to="/explore"
                      className={classes.whatisGridLink}
                    >
                      <Typography variant="h6" className={classes.plansAction}>
                        {I18n.get("See our video workouts")}
                      </Typography>
                    </RouterLink>
                    <RouterLink
                      to="/explore"
                      className={classes.whatisGridLink}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/landing-arrow-right-o.svg`}
                        alt=""
                      />
                    </RouterLink>
                  </div>

                  <div className={classes.imgContainer}>
                    <RouterLink
                      to="/explore"
                      className={classes.whatisGridLink}
                    >
                      <img
                        className={classes.img}
                        alt="workout"
                        src={`${process.env.PUBLIC_URL}/workout_card.png`}
                      />
                    </RouterLink>
                  </div>
                </div>

                <div className={classes.community} id="community">
                  <Typography variant="h6" className={classes.communityTitle}>
                    {I18n.get("Community")}
                  </Typography>
                  <Typography
                    variant="h6"
                    className={classes.communitySubtitle}
                  >
                    {I18n.get(
                      "Join liked minded trail runners from all over the world. Share ideas, results, adventures and learn from fellow athletes like you",
                    )}
                  </Typography>

                  <div
                    className={classes.communityRow}
                    style={{
                      marginLeft: -100,
                    }}
                  >
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l1.png`}
                    />
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l2.png`}
                    />
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l3.png`}
                    />
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l4.png`}
                    />
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l5.png`}
                    />
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l6.png`}
                    />
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l3.png`}
                    />
                  </div>
                  <div
                    className={classes.communityRow}
                    style={{
                      marginLeft: -50,
                    }}
                  >
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l5.png`}
                    />
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l6.png`}
                    />
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l7.png`}
                    />
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l8.png`}
                    />
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l1.png`}
                    />
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l2.png`}
                    />
                    <img
                      className={classes.communityImg}
                      src={`${process.env.PUBLIC_URL}/l7.png`}
                    />
                  </div>
                </div>
              </>
            )}

            <Grid
              container
              className={classes.thirdGrid}
              justify="center"
              alignItems="center"
            >
              <div className={classes.pricesTitleContainer}>
                <Typography variant="h6" className={classes.pricesTitle}>
                  {I18n.get("Choose a plan that’s right for you")}
                </Typography>
                <Typography variant="h6" className={classes.pricesSubtitle}>
                  {I18n.get("The most complete App for Trail & Ultra runners")}
                </Typography>
              </div>
              <Grid item className={classes.column1Item}>
                <div className={classes.column1}>
                  <div className={classes.column1Top}>
                    <Typography
                      variant="body1"
                      className={classes.column1TopType}
                    >
                      {I18n.get("Free")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.column1TopPrice}
                    >
                      $0
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.column1TopPerMonth}
                    >
                      {I18n.get("/per month")}
                    </Typography>
                  </div>

                  <Typography
                    variant="body1"
                    className={classes.columnIncluded}
                  >
                    {I18n.get("What’s included:")}
                  </Typography>
                  <div className={classes.column1Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column1EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column1EntryText}
                      >
                        {I18n.get(
                          "Access our platform and trail running training tools",
                        )}
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.column1Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column1EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column1EntryText}
                      >
                        {I18n.get("Access to our Community Challenges")}
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.column1Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column1EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column1EntryText}
                      >
                        {I18n.get(
                          "Receive your personal, weekly training plan",
                        )}
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.column1Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column1EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column1EntryText}
                      >
                        {I18n.get(
                          "Train for races/distances of up to a Marathon",
                        )}
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.column1Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column1EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column1EntryText}
                      >
                        {I18n.get("Designed for all levels of experience")}
                      </Typography>
                    </div>
                  </div>

                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.column1SignUpButton}
                    onClick={this.handleOpenSignUp}
                    size="large"
                    style={{
                      display: "block",
                      margin: "35px auto 0 auto",
                    }}
                  >
                    {I18n.get("Get Started")}
                  </Button>
                </div>
              </Grid>

              <Grid item className={classes.column2Item}>
                <div className={classes.column2}>
                  <div className={classes.column1Top}>
                    <Typography
                      variant="body1"
                      className={classes.column1TopType}
                    >
                      {I18n.get("+ Coaching")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.column1TopPrice}
                    >
                      $25
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.column1TopPerMonth}
                    >
                      {I18n.get("/per month")}
                    </Typography>
                  </div>

                  <Typography
                    variant="body1"
                    className={classes.columnIncluded}
                  >
                    {I18n.get("What’s included:")}
                  </Typography>
                  <div className={classes.column1Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column1EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column1EntryText}
                      >
                        <b>
                          {I18n.get(
                            "Access 200+ training plans for Road, Trails and Ultramarathons",
                          )}
                        </b>
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.column1Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column1EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column1EntryText}
                      >
                        <b>
                          {I18n.get("Receive weekly guidance from your coach")}
                        </b>
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.column1Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column1EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column1EntryText}
                      >
                        <b>
                          {I18n.get("Chat with your coach in your dashboard")}
                        </b>
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.column1Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column1EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column1EntryText}
                      >
                        {I18n.get(
                          "Training that works around your life (Swap workouts, add easy days, add easy weeks, add customs workouts...)",
                        )}
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.column1Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column1EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column1EntryText}
                      >
                        {I18n.get(
                          "Follow your progress: track your Mountain Index, training load, energy levels and more",
                        )}
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.column1Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column1EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column1EntryText}
                      >
                        {I18n.get(
                          "Track your activities with Strava, Garmin,  Suunto or Apple watch",
                        )}
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.column1Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column1EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column1EntryText}
                      >
                        <b>{I18n.get("Export workouts to your Garmin")}</b>
                      </Typography>
                    </div>
                  </div>

                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.column1SignUpButton}
                    onClick={this.handleOpenApp}
                    size="large"
                    style={{
                      display: "block",
                      margin: "35px auto 0 auto",
                    }}
                  >
                    {I18n.get("GET A COACH")}
                  </Button>
                  <Typography
                    variant="body1"
                    className={classes.column1OnlyOnApp}
                  >
                    {I18n.get("FIRST 7 DAYS ARE FREE!")}
                  </Typography>
                </div>
              </Grid>

              <Grid item className={classes.column3Item}>
                <div className={classes.column3}>
                  <div className={classes.column2Top}>
                    <Typography
                      variant="body1"
                      className={classes.column2TopType}
                    >
                      {I18n.get("+ 6 Months Coaching")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.column2TopPrice}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      $126
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.column2TopPerMonth}
                    >
                      $21{I18n.get("/per month")}
                    </Typography>
                  </div>

                  <Typography
                    variant="body1"
                    className={classes.columnIncluded}
                  >
                    {I18n.get("What’s included:")}
                  </Typography>
                  <div className={classes.column2Entry}>
                    <i
                      aria-hidden="true"
                      className={classNames(
                        "fas",
                        "fa-check",
                        classes.column2EntryIcon,
                      )}
                    ></i>
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.column2EntryText}
                      >
                        <b>
                          {I18n.get(
                            "Everything from our Monthly Coaching subscription - with special 6-month pricing",
                          )}
                        </b>
                      </Typography>
                    </div>
                  </div>

                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.column2SignUpButton}
                    onClick={this.handleOpenSignUp}
                    size="large"
                    style={{
                      display: "block",
                      margin: "35px auto 0 auto",
                    }}
                  >
                    {I18n.get("GET A COACH")}
                  </Button>

                  <Typography
                    variant="body1"
                    className={classes.column2First7Days}
                  >
                    {I18n.get("FIRST 7 DAYS ARE FREE!")}
                  </Typography>
                </div>
              </Grid>
              {isInMobileViewSm ? testimony : ""}
            </Grid>
          </>
        )}

        <Dialog
          open={openSignIn}
          onClose={this.handleCloseSignIn}
          aria-labelledby="signIn"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signindialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseSignIn}
              />
            </div>
            <AppAuthenticator initialState="signIn" />
          </DialogContent>
        </Dialog>
        <Dialog
          open={openSignUp}
          onClose={this.handleCloseSignUp}
          aria-labelledby="signUp"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signupdialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseSignUp}
              />
            </div>
            <AppAuthenticator initialState="signUp" />
          </DialogContent>
        </Dialog>

        <Dialog
          open={openApp}
          onClose={this.handleCloseApp}
          aria-labelledby="app"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="appdialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseApp}
              />
            </div>

            <Typography variant="h6" className={classes.appPopupTitle}>
              {I18n.get("Get the App")}
            </Typography>

            <Link
              className={classes.install}
              href={externalLinksEn.iOSApp}
              target="_blank"
            >
              <Button
                color="primary"
                variant="contained"
                className={classes.appButton}
                size="large"
              >
                {I18n.get("Vert.run for iPhone")}
              </Button>
            </Link>

            <Link
              className={classes.install}
              href={externalLinksEn.AndroidApp}
              target="_blank"
            >
              <Button
                color="primary"
                variant="contained"
                className={classes.appButton}
                size="large"
              >
                {I18n.get("Vert.run for Android")}
              </Button>
            </Link>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openSuggester}
          onClose={this.handleCloseSuggester}
          aria-labelledby="suggester"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="suggester" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseSuggester}
              />
            </div>
            <SuggesterResults
              onOpenAuth={this.handleOpenSignUp}
              isAuthenticated={false}
              max={2}
            />
          </DialogContent>
        </Dialog>

        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={generalErrorMessage}
        />
      </div>
    );
  }
}

AuthLanding.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default ConnectAuth(
  ConnectUserPlans(
    withTranslation("layout")(
      withWidth()(withStyles(styles, { withTheme: true })(memo(AuthLanding))),
    ),
  ),
);
