const stravaAuthorizationUrl = `${process.env.REACT_APP_STREVA_AUTHORIZATION_URL}?client_id=${process.env.REACT_APP_STREVA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_STREVA_REDIRECT_URL}&response_type=code&scope=activity:read_all&state=init`;
const garminAuthorizationUrl = `${process.env.REACT_APP_GARMIN_AUTHORIZATION_URL}?oauth_callback=${process.env.REACT_APP_GARMIN_REDIRECT_URL}&`;
const suuntoAuthorizationUrl = `https://cloudapi-oauth.suunto.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_SUUNTO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SUUNTO_REDIRECT_URL}`;
const corosAuthorizationUrl = `${process.env.REACT_APP_COROS_AUTH_URL}?client_id=${process.env.REACT_APP_COROS_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_COROS_REDIRECT_URL}&state=123&response_type=code`;
export {
  stravaAuthorizationUrl,
  garminAuthorizationUrl,
  suuntoAuthorizationUrl,
  corosAuthorizationUrl,
};
