import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getExplorePosts,
  getExploreFeaturedPost,
  toggleFavorites,
} from "../actions/trail-head-action";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography, Fab, Avatar } from "@material-ui/core";
import {
  clearSuccessMessage,
  clearErrorMessage,
  toggleOpenMenu,
  updatePlan,
} from "../actions/user-plans-action";
import { getChallenges } from "../actions/challenges-action";
import AppAuthenticator from "./app-authenticator";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import { setErrorMessage } from "../actions/user-plans-action";
import { logEvent } from "../lib/events-helper";
import { Storage } from "aws-amplify";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import withWidth from "@material-ui/core/withWidth";
import SubCategories from "./choose-plan/subcategories";
import ExplorePost from "./explore/explore-post";
import RouterLink from "./common/router-link";
import history from "../lib/history-helper";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CompleteProfile from "./common/complete-profile";
import { getLevel } from "../lib/rates-helper";
import moment from "moment";

const styles = (theme) => ({
  container: {
    width: "100%",
    background: "#E5E5E5",

    overflow: "scroll",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100vh",
    },
  },
  posts: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  loading: {
    textAlign: "center",
  },
  top: {
    padding: 10,
    margin: "auto",

    background: "#e5e5e5",

    position: "sticky",
    top: 0,
    zIndex: 100,
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)",
      background: "#ffffff",
      borderRadius: "0 0 12px 12px",
      textAlign: "initial",
    },
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  termSearch: {
    marginTop: 0,
    marginBottom: 4,
    background: "rgb(255 255 255 / 10%)",
  },
  logoImg: {
    width: 72,
    marginRight: "auto",
    cursor: "pointer",
  },
  iconSearch: {
    marginLeft: 10,
    cursor: "pointer",
  },
  onlyReported: {
    color: "#ffffff",
  },
  button: {
    boxShadow: "none",
    borderRadius: 50,
    marginBottom: 0,
    marginLeft: 20,
  },

  titleCont: {
    display: "flex",
    alignItems: "center",
    margin: "10px 10px 0 10px",
  },

  titleContChallenges: {
    display: "initial",
    margin: "20px 20px 0 20px",
    width: "40%",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      margin: "10px 10px 0 10px",
      width: "initial",
    },
  },

  textChallenges: {
    fontSize: 16,
    margin: "20px 0 0 60px",
  },

  title: {
    fontWeight: 500,
    fontSize: 20,
    marginRight: "auto",
    marginLeft: 60,

    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      fontSize: 18,
    },
  },

  seeAll: {
    fontWeight: 500,
    fontSize: 14,
    color: "#E16327",
  },

  filter: {
    fontSize: 14,
    fontWeight: 400,
    background: "rgba(63, 81, 181, 0.12);",
    borderRadius: 4,
    padding: "6px 8px",
    color: "#212121",
    cursor: "pointer",
    minWidth: 50,
    textAlign: "center",
  },
  filterSelected: {
    fontSize: 14,
    fontWeight: 400,
    background: "#1E629B",
    borderRadius: 4,
    padding: "6px 8px",
    color: "#FFFFFF",
    cursor: "pointer",
    minWidth: 50,
    textAlign: "center",
  },

  round: {
    borderRadius: 50,
  },

  closeIcon: {
    cursor: "pointer",
  },

  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },

  noPadding: {
    padding: "0 !important",
  },

  section: {
    marginBottom: 35,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },

  sectionChallenges: {
    display: "flex",
    background: "#fff",
    padding: "15px 0",
    margin: "40px 0",
    [theme.breakpoints.down("sm")]: {
      display: "initial",
      background: "initial",
      padding: "initial",
      margin: 0,
    },
  },

  postsContainer: {
    "&::-webkit-scrollbar ": {
      display: "none",
    },

    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",
  },

  challengeContainer: {
    borderRadius: 12,
    padding: 16,
    display: "inline-flex",
    flexDirection: "column",
    margin: "10px 0 10px 10px",
    minWidth: 164,
    maxWidth: 164,
    verticalAlign: "top",
    whiteSpace: "break-spaces",
    minHeight: 224,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",

    "&:last-child": {
      marginRight: 10,
    },
  },

  challengeName: {
    fontSize: 16,
    color: "#fff",
    fontWeight: 500,
    marginTop: "auto",
  },

  badge: {
    width: 120,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -65%)",
  },

  postContainer: {
    background: "#fff",
    borderRadius: 12,
    margin: "10px 0 10px 60px",
    display: "inline-block",
    width: "90%",
    verticalAlign: "top",
    whiteSpace: "break-spaces",
    minHeight: 298,
    cursor: "pointer",

    "&:last-child": {
      marginRight: 60,
    },

    [theme.breakpoints.down("sm")]: {
      margin: "10px 0 10px 10px",

      "&:last-child": {
        marginRight: 10,
      },
    },
  },

  postImage: {
    height: 124,
    borderRadius: "12px 12px 0 0",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  postDesciption: {
    padding: 16,
  },

  postMetadataRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },

  postMediaType: {
    background: "rgba(255, 87, 34, 0.24)",
    borderRadius: 4,
    fontWeight: "normal",
    fontSize: 14,
    padding: "2px 4px",
  },

  postTime: {
    fontWeight: "normal",
    fontSize: 12,
    marginLeft: "auto",
  },

  postTitle: {
    fontWeight: 500,
    fontSize: 18,
  },

  postAction: {
    marginTop: 5,
    fontWeight: 500,
    fontSize: 14,
    color: "#E16327",
  },

  filterGrid: {
    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",

    "&::-webkit-scrollbar ": {
      display: "none",
    },
  },

  filterItem: {
    paddingBottom: "0 !important",
    margin: 5,
    display: "inline-block",
  },

  favs: {
    marginLeft: "auto",
    marginRight: 15,
  },

  favContainer: {
    background: "#FFFFFF",
    borderRadius: 24,
    position: "absolute",
    top: 5,
    right: 5,
    padding: "2px 10px",
    zIndex: 10,
  },

  favoritesTitle: {
    fontWeight: 500,
    fontSize: 18,
    marginRight: "auto",
    marginLeft: 25,
    marginTop: 2,
  },

  favsDesktop: {
    height: 15,
  },

  seeAllCont: {
    display: "flex",
  },

  seeAllContChallenges: {
    display: "flex",
    marginLeft: 60,
    marginTop: 20,
  },

  seeAllArrow: {
    height: 20,
    marginLeft: 10,
    marginRight: 50,
  },

  levelMiniBadge: {
    height: 25,
    bottom: -5,
    right: -10,
    position: "absolute",
  },
});

class Explore extends PureComponent {
  state = {
    avatarLoading: true,
    avatarUrl: null,
    openSignUp: false,
    signUpTimeout: null,
    types: [
      "Become a trail runner",
      "Become an ultra runner",
      "Workouts [ Video ]",
      "Recovery [ Video ]",
      "Tips [ Video ]",
      "Podcast",
    ],
  };

  async componentDidMount() {
    this.getAvatarUrl();

    this.props.dispatch(getChallenges(""));

    this.props.dispatch(getExploreFeaturedPost());

    for (let index = 0; index < this.state.types.length; index++) {
      const type = this.state.types[index];
      this.props.dispatch(getExplorePosts(new Date().getTime(), type, true));
    }

    /*const { auth } = this.props;
    const { isAuthenticated } = auth;
    if (!isAuthenticated && !this.state.signUpTimeout) {
      this.setState({
        ...this.state,
        signUpTimeout: setTimeout(() => {
          this.handleOpenSignUp();
        }, 6000),
      });
    }*/
  }

  async componentDidUpdate(prevProps) {
    const { userPlans } = this.props;
    const { currentPlan, updatingImage } = userPlans;
    const { image } = currentPlan || {};
    if (
      (currentPlan &&
        currentPlan.image &&
        (!prevProps.userPlans ||
          !prevProps.userPlans.currentPlan ||
          !prevProps.userPlans.currentPlan.image ||
          prevProps.userPlans.currentPlan.image !== currentPlan.image)) ||
      (prevProps.userPlans.updatingImage && !updatingImage)
    ) {
      if (image) {
        this.setState({
          ...this.state,
          avatarLoading: true,
        });
        this.getAvatarUrl();
      }
    }
  }

  getAvatarUrl = async () => {
    const { userPlans, t } = this.props;
    const { currentPlan } = userPlans;
    const { image } = currentPlan || {};

    if (image) {
      try {
        const result = await Storage.get(image, { level: "public" });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              avatarUrl: result,
              avatarLoading: false,
            }),
          100,
        );
      } catch (exp) {
        this.handleError(
          new Error(
            t(
              "Oops, we could not load your profile picture. Give it another try",
              { ns: "messages" },
            ),
          ),
        );
        throw exp;
      }
    } else if (!image) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            avatarLoading: false,
          }),
        100,
      );
    }
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleError = (err) => {
    this.props.dispatch(setErrorMessage(err)); //GOES TO SNACK BAR
  };

  /*handleLoadNextPage = (lastDate) => {
    this.props.dispatch(
      getPosts(
        lastDate,
        this.state.searchPhrase,
        this.state.tags ? this.state.tags.map((tag) => tag.value) : "",
        false,
      ),
    );
  };*/

  handleOpenSignUp = () => {
    localStorage.removeItem("error");
    if (this.state.signUpTimeout) {
      clearTimeout(this.state.signUpTimeout);
    }
    this.setState({
      ...this.state,
      openSignUp: true,
      signUpTimeout: null,
    });
  };

  handleCloseSignUp = () => {
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignUp: false,
    });
  };

  toogleMenu = () => {
    const { auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      this.props.dispatch(toggleOpenMenu());
    } else {
      this.handleOpenSignUp();
    }
  };

  handleChooseType = (type) => {
    const { trailHead } = this.props;
    const { favorites } = trailHead;

    if (favorites) {
      this.props.dispatch(toggleFavorites());
    }

    if (type === "Challenges") {
      history.push(`/challenges`);
    } else if (type === "Training plans") {
      history.push(`/chooseplan`);
    } else {
      history.push(`/explore/tag/${type}`);
    }
  };

  handleOpenFavorites = () => {
    const { auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      this.props.dispatch(toggleFavorites());
    } else {
      this.handleOpenSignUp();
    }
  };

  toggleFavorite = (event, id, type) => {
    event.preventDefault();
    event.stopPropagation();
    const { auth } = this.props;
    const { isAuthenticated, currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;
    if (isAuthenticated) {
      const { userPlans } = this.props;
      const { currentPlan } = userPlans;
      const { exploreFavorites } = currentPlan || {};
      if (
        exploreFavorites &&
        exploreFavorites.length &&
        exploreFavorites.find((e) => e.id === id)
      ) {
        const newExploreFavorites = exploreFavorites.filter((f) => f.id !== id);
        this.props.dispatch(
          updatePlan(
            {
              cognito_user_sub: sub,
              exploreFavorites: newExploreFavorites,
            },
            false,
            false,
          ),
        );
      } else {
        this.props.dispatch(
          updatePlan(
            {
              cognito_user_sub: sub,
              exploreFavorites:
                exploreFavorites && exploreFavorites.length
                  ? [{ id, type }, ...exploreFavorites]
                  : [{ id, type }],
            },
            false,
            false,
          ),
        );
      }
    } else {
      this.handleOpenSignUp();
    }
  };

  render() {
    const { classes, t, auth, trailHead, i18n, width, challenges } = this.props;
    const { isAuthenticated, currentUser } = auth;

    const { loadingChallenges, currentChallenges } = challenges;
    const {
      loadingExplorePosts,
      explorePosts,
      exploreFeaturedPost,
      favorites,
    } = trailHead;

    //data.sort((a, b) => b.created_at - a.created_at);

    const { userPlans } = this.props;
    const { currentPlan, successMessage, errorMessage, loading } = userPlans;
    const {
      exploreFavorites,
      accumulatedDistance = 0,
      accumulatedElevation = 0,
      userType = 0,
      createdAt,
    } = currentPlan || {};

    const level = loading
      ? 0
      : getLevel(accumulatedDistance, accumulatedElevation);
    const { attributes } = currentUser || {};

    const { currentTraining } = currentPlan || {};
    const { birthdate, gender, locale } = attributes || {};

    let { selectedPlan } = currentPlan || {};
    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    const { avatarUrl, openSignUp, types } = this.state;

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const isInMobileViewSm = width === "sm" || width === "xs";

    const hasCurrentTraining =
      currentTraining && typeof currentTraining === "object";

    const hasProfileCompleted = birthdate && locale && gender;

    return (
      <div
        className={classes.container}
        id="thContainer"
        onScroll={() => {
          /*if (
            document.getElementById("thContainer").scrollTop === 0 ||
            isSearchingWithPhrase
          ) {
            this.setState({
              isSearchingWithFilters: true,
            });
          } else {
            this.setState({
              isSearchingWithFilters: false,
            });
          }*/
        }}
      >
        <div className={classes.top}>
          {isInMobileViewSm && (
            <div className={classes.searchBar}>
              {favorites && (
                <ArrowBackIosIcon
                  onClick={() => this.handleOpenFavorites()}
                  className={classes.backIcon}
                  fontSize="small"
                />
              )}

              {favorites && (
                <Typography variant="body1" className={classes.favoritesTitle}>
                  {t("Favorites")}
                </Typography>
              )}

              {!favorites && (
                <div
                  className={classes.pictureContainer}
                  style={{
                    width: 44,
                    height: 44,
                    marginRight: "auto",
                    position: "relative",
                  }}
                >
                  <Fab
                    component="span"
                    className={classes.fab}
                    title="Cambia tu foto"
                    style={{
                      width: 44,
                      height: 44,
                    }}
                    onClick={() => this.toogleMenu()}
                  >
                    <Avatar
                      src={
                        avatarUrl ||
                        `${process.env.PUBLIC_URL}/avatar-empty.png`
                      }
                      className={classes.avatar}
                      style={{
                        width: 44,
                        height: 44,
                      }}
                    />
                  </Fab>

                  {level ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/trl${level}-sm.svg`}
                      className={classes.levelMiniBadge}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}

              <img
                src={`${process.env.PUBLIC_URL}/icon-star-${
                  favorites ? "filled" : "outline"
                }.svg`}
                alt="favorite"
                onClick={this.handleOpenFavorites}
                className={classes.favs}
              />
            </div>
          )}

          {(!favorites || !isInMobileViewSm) && (
            <div className={classes.filterGrid}>
              {!isInMobileViewSm && (
                <div className={classes.filterItem}>
                  <Typography
                    variant="body1"
                    onClick={() => this.handleOpenFavorites()}
                    className={
                      favorites ? classes.filterSelected : classes.filter
                    }
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-star-filled.svg`}
                      alt=""
                      className={classes.favsDesktop}
                    />
                  </Typography>
                </div>
              )}
              {["Latest", "Challenges", "Training plans", ...types].map(
                (type, index) => {
                  return (type !== "Become a trail runner" || userType === 3) &&
                    (type !== "Become an ultra runner" || userType === 1) ? (
                    <div key={index} className={classes.filterItem}>
                      <Typography
                        variant="body1"
                        onClick={() =>
                          index === 0 && favorites
                            ? this.handleOpenFavorites()
                            : this.handleChooseType(type)
                        }
                        className={
                          index === 0 && !favorites
                            ? classes.filterSelected
                            : classes.filter
                        }
                      >
                        {t(type)}
                      </Typography>
                    </div>
                  ) : (
                    ""
                  );
                },
              )}
            </div>
          )}
        </div>

        <div className={classes.posts} id="posts">
          {(!hasProfileCompleted || !hasCurrentTraining) &&
          !loading &&
          isAuthenticated &&
          moment(createdAt).isBefore(moment().add(14, "days")) ? (
            <CompleteProfile
              hasCurrentTraining={hasCurrentTraining}
              isLg={width === "lg"}
              userType={userType}
            ></CompleteProfile>
          ) : (
            ""
          )}

          {exploreFeaturedPost && !favorites ? (
            <div
              className={classes.section}
              style={{
                marginLeft: isInMobileViewSm ? 0 : 60,
              }}
            >
              <div
                className={classes.titleCont}
                style={{
                  marginLeft: isInMobileViewSm ? 10 : 0,
                }}
              >
                <Typography variant="body1" className={classes.title}>
                  {t("Featured")}
                </Typography>
              </div>

              <ExplorePost
                post={exploreFeaturedPost}
                languageDetector={languageDetector}
                isBlock={true}
                onToggleFavorite={this.toggleFavorite}
                exploreFavorites={exploreFavorites}
                isFeatured={true}
                onOpenAuth={this.handleOpenSignUp}
              ></ExplorePost>
            </div>
          ) : (
            ""
          )}

          {!favorites ||
          (favorites &&
            exploreFavorites &&
            exploreFavorites.length &&
            exploreFavorites.find((e) => e.type === "Challenge")) ? (
            <div className={classes.sectionChallenges}>
              <div className={classes.titleContChallenges}>
                <Typography variant="body1" className={classes.title}>
                  {t("Challenges & Adventures")}
                </Typography>

                {!isInMobileViewSm ? (
                  <Typography
                    variant="body1"
                    className={classes.textChallenges}
                  >
                    {t("Challenges & Adventures description")}
                  </Typography>
                ) : (
                  ""
                )}

                <RouterLink
                  to="/challenges"
                  className={classes.seeAllContChallenges}
                >
                  <Typography variant="body1" className={classes.seeAll}>
                    {t("See All")}
                  </Typography>

                  {!isInMobileViewSm ? (
                    <img
                      className={classes.seeAllArrow}
                      alt="badge"
                      src={`${process.env.PUBLIC_URL}/landing-arrow-right-o.svg`}
                    />
                  ) : (
                    ""
                  )}
                </RouterLink>
              </div>

              {loadingChallenges && (
                <div className={classes.loading}>
                  <CircularProgress variant="indeterminate" />
                </div>
              )}

              <div className={classes.postsContainer}>
                {(favorites
                  ? exploreFavorites && exploreFavorites.length
                    ? currentChallenges.filter((c) =>
                        exploreFavorites.find((e) => e.id === c.id),
                      )
                    : []
                  : currentChallenges
                ).map((challenge) => {
                  return (
                    <RouterLink to="/challenges" key={challenge.id}>
                      <div
                        className={classes.challengeContainer}
                        style={{
                          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%), url(${challenge.image})`,
                        }}
                      >
                        <div className={classes.favContainer}>
                          <img
                            src={`${process.env.PUBLIC_URL}/icon-star-${
                              exploreFavorites &&
                              exploreFavorites.length &&
                              exploreFavorites.find(
                                (e) => e.id === challenge.id,
                              )
                                ? "filled"
                                : "outline"
                            }.svg`}
                            alt=""
                            onClick={(ev) =>
                              this.toggleFavorite(ev, challenge.id, "Challenge")
                            }
                            className={classes.fav}
                          />
                        </div>

                        {challenge.badge ? (
                          <img
                            className={classes.badge}
                            alt="badge"
                            src={challenge.badge}
                          />
                        ) : (
                          ""
                        )}
                        <Typography
                          variant="body1"
                          className={classes.challengeName}
                        >
                          {challenge.name
                            ? challenge.name[languageDetector]
                              ? challenge.name[languageDetector]
                              : challenge.name["en"]
                            : ""}
                        </Typography>
                      </div>
                    </RouterLink>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}

          {!favorites ||
          (favorites &&
            exploreFavorites &&
            exploreFavorites.length &&
            exploreFavorites.find((e) => e.type === "Training plan")) ? (
            <div className={classes.section}>
              <div className={classes.titleCont}>
                <Typography variant="body1" className={classes.title}>
                  {t("Training plans")}
                </Typography>

                <RouterLink to="/chooseplan" className={classes.seeAllCont}>
                  <Typography variant="body1" className={classes.seeAll}>
                    {t("See All")}
                  </Typography>

                  {!isInMobileViewSm ? (
                    <img
                      className={classes.seeAllArrow}
                      alt="badge"
                      src={`${process.env.PUBLIC_URL}/landing-arrow-right-o.svg`}
                    />
                  ) : (
                    ""
                  )}
                </RouterLink>
              </div>

              <SubCategories
                filter={this.state.currentFilter}
                onOpenAuth={this.handleOpenSignUp}
                explore={true}
                onToggleFavorite={this.toggleFavorite}
                exploreFavorites={exploreFavorites}
                favorites={favorites}
                userType={userType}
              />
            </div>
          ) : (
            ""
          )}
        </div>

        {types.map((type) => {
          let posts =
            explorePosts &&
            explorePosts[type] &&
            explorePosts[type].data &&
            explorePosts[type].data.length
              ? explorePosts[type].data
              : [];

          if (favorites) {
            posts =
              exploreFavorites && exploreFavorites.length
                ? posts.filter((p) =>
                    exploreFavorites.find((e) => e.id === p.id),
                  )
                : [];
          }

          return posts &&
            posts.length &&
            (type !== "Become a trail runner" || userType === 3) &&
            (type !== "Become an ultra runner" || userType === 1) ? (
            <div className={classes.section} key={type}>
              <div className={classes.titleCont}>
                <Typography variant="body1" className={classes.title}>
                  {t(type)}
                </Typography>

                <RouterLink
                  to={`/explore/tag/${type}`}
                  className={classes.seeAllCont}
                >
                  <Typography variant="body1" className={classes.seeAll}>
                    {t("See All")}
                  </Typography>

                  {!isInMobileViewSm ? (
                    <img
                      className={classes.seeAllArrow}
                      alt="badge"
                      src={`${process.env.PUBLIC_URL}/landing-arrow-right-o.svg`}
                    />
                  ) : (
                    ""
                  )}
                </RouterLink>
              </div>

              {loadingExplorePosts && loadingExplorePosts[type] && (
                <div className={classes.loading}>
                  <CircularProgress variant="indeterminate" />
                </div>
              )}

              <div className={classes.postsContainer}>
                {posts
                  ? posts.map((post) => {
                      return (
                        <ExplorePost
                          key={post.id}
                          post={post}
                          languageDetector={languageDetector}
                          onToggleFavorite={this.toggleFavorite}
                          exploreFavorites={exploreFavorites}
                        ></ExplorePost>
                      );
                    })
                  : ""}
              </div>
            </div>
          ) : (
            ""
          );
        })}
        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />

        <Dialog
          open={openSignUp}
          onClose={this.handleCloseSignUp}
          aria-labelledby="signUp"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signupdialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseSignUp}
              />
            </div>
            <AppAuthenticator initialState="signUp" />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

Explore.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default connect((store) => {
  return {
    auth: store.auth,
    trailHead: store.trailHead,
    userPlans: store.userPlans,
    subscriptions: store.subscriptions,
    challenges: store.challenges,
  };
})(
  withTranslation("trailhead")(
    withWidth()(withStyles(styles, { withTheme: true })(Explore)),
  ),
);
