import React, { useEffect, useState } from "react";
import PodcastRow from "./podcast-row";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { ThemeProvider, Typography } from "@material-ui/core";
import { Box, makeStyles } from "@material-ui/core";
import { theme } from "../../theme";
import { palette } from "../../theme/palette";
import { useSelector, useDispatch } from "react-redux";
import { getExplorePosts } from "../../actions/trail-head-action";
import { Filters } from "./filters";
import { useHistory } from "react-router-dom";
import SearchInput from "./search-input";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const filters = ["Favorites"];

const useStyles = makeStyles({
  header: {
    backgroundColor: palette.white,
    padding: "12px 24px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "& > p": {
      fontWeight: "700",
    },
  },
  iconSearch: {
    color: palette.darkGray,
    width: "24px",
  },
  arrow: {
    width: "24px",
  },
  main: {
    backgroundColor: palette.background,
    width: "100%",
    marginBottom: "16px",
  },
  content: {
    padding: "0px 16px",
    boxSizing: "border-box",
  },
  filtersContainer: {
    margin: "16px 0px 8px 0px",
  },
  inputParent: {
    position: "absolute",
    right: "0px",
    top: "-5px",
  },
  container: {
    position: "relative",
  },
  notFound: {
    color: "#6D6F71",
    marginTop: "16px",
  },
});

const skeletonStyles = {
  marginBottom: "16px",
};

const PodcatsList = () => {
  const {
    t,
    i18n: { language = "en" },
  } = useTranslation("home");
  const [localPodcasts, setLocalPodcasts] = useState([]);
  const [inputText, setInputText] = useState("");
  const history = useHistory();
  const [search, setSearch] = useState(0);
  const [favorites, setFilterFavorites] = useState(false);
  const { explorePosts, loadingExplorePosts } = useSelector(
    (state) => state.trailHead,
  );
  const { data: podcast } = explorePosts["Podcast"] || {};
  const dispatch = useDispatch();
  useEffect(() => {
    setLocalPodcasts(podcast);
  }, [podcast]);

  useEffect(() => {
    dispatch(getExplorePosts(new Date().getTime(), "Podcast", true));
  }, []);

  const handleFavorites = () => {
    setFilterFavorites((prev) => !prev);
  };

  const handleSearch = () => {
    if (search === 0) return setSearch(1);
    if (search === 1) {
      setInputText("");
      setLocalPodcasts(podcast);
      setSearch(2);
      return;
    }
    if (search === 2) return setSearch(1);
  };

  const handleChange = ({ target: { value } }) => {
    setInputText(value);
    const filtered = podcast.filter(({ title }) =>
      (title[language] ? title[language] : title["en"])
        .toLowerCase()
        .includes(value.toLowerCase()),
    );
    setLocalPodcasts(filtered);
  };

  const goBack = () => history.push("/");

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.main}>
        <Box className={classes.header}>
          <ArrowBack
            className={classes.arrow}
            id="back_see_all_podcast"
            onClick={goBack}
          />
          <Typography variant="h4">{t("Podcast")}</Typography>
          <Box className={classes.container}>
            <Box className={classes.inputParent}>
              <SearchInput
                show={search}
                handleInput={handleSearch}
                onChange={handleChange}
                width="80vw"
                value={inputText}
              />
            </Box>

            <img
              src={`${process.env.PUBLIC_URL}/icon_search.svg`}
              alt="search-icon"
              width="24px"
              id="podcasts_search_icon"
              onClick={handleSearch}
            />
          </Box>
        </Box>

        <Box className={classes.content}>
          <Box className={classes.filtersContainer}>
            <Filters filters={filters} onClick={handleFavorites} />
          </Box>

          {loadingExplorePosts["Podcast"] && (
            <Skeleton
              count={6}
              borderRadius={4}
              height={62}
              baseColor={palette.skeleton}
              style={skeletonStyles}
            />
          )}

          {localPodcasts && localPodcasts.length ? (
            <Box id="podcast_list">
              <PodcastRow
                podcast={localPodcasts || []}
                onlyFavorites={favorites}
                language={language}
              />
            </Box>
          ) : (
            !loadingExplorePosts["Podcast"] && (
              <Typography className={classes.notFound} variant="body2">
                {t(`We couldn't find any results for`)}{" "}
                <b>{`"${inputText}"`}</b>
              </Typography>
            )
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PodcatsList;
