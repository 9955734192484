import React, { memo } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  Link,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";

import FontAwesomeIcon from "../common/font-awesome-icon";
import EnhancedTableHead from "./enhanced-table/enhanced-table-head";
import EnhancedTableToolbar from "./enhanced-table/enhanced-table-toolbar";
import EnhancedTableCellEdit from "./enhanced-table/enhanced-table-cell-edit";
import EnhancedTableCellEditor from "./enhanced-table/enhanced-table-cell-editor";
import { convertToRTFForView } from "../../lib/rtf-helper";
import { withTranslation } from "react-i18next";
import WorkoutTypes from "../../constants/workout-types";
import workoutToText from "../../lib/workoutToText";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {},
  tableWrapper: {
    overflowX: "auto",
  },
  bolderCell: {
    fontWeight: 600,
  },
  multiline: {
    whiteSpace: "pre-line",
  },
});

class EnhancedTable extends React.Component {
  desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  getSorting = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => this.desc(a, b, orderBy)
      : (a, b) => -this.desc(a, b, orderBy);
  };

  handleRequestSort = (event, property) => {
    const {
      order: lastOrder,
      orderBy: lastOrderBy,
      onRequestSort,
      headers,
    } = this.props;
    if (onRequestSort) {
      const relatedHeader = headers.find((header) => header.id === property);

      const orderBy = relatedHeader.orderByOverride || property;
      let order = "asc";

      if (lastOrderBy === orderBy && lastOrder === "asc") {
        order = "desc";
      }
      onRequestSort({ order, orderBy });
    }
  };

  handleSelectAllClick = (event) => {
    const { data, onSelectedChanged } = this.props;
    if (onSelectedChanged) {
      if (event.target.checked)
        onSelectedChanged({ selected: data.map((n) => this.getKey(n)) });
      else onSelectedChanged({ selected: [] });
    }
  };

  handleClick = (event, row) => {
    const { selected, onSelectedChanged, selectable } = this.props;
    if (selectable && onSelectedChanged) {
      const selectedIndex = selected.indexOf(this.getKey(row));
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, this.getKey(row));
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      onSelectedChanged({ selected: newSelected });
    }
  };

  handleChangePage = (event, page) => {
    const { onChangePage } = this.props;
    if (onChangePage) onChangePage({ page: page });
  };

  handleChangeRowsPerPage = (event) => {
    const { onChangeRowsPerPage } = this.props;
    if (onChangeRowsPerPage) {
      const { target } = event;
      if (target) {
        const { value } = target;
        if (value) onChangeRowsPerPage({ rowsPerPage: value });
      }
    }
  };

  handleDoubleClick = (event, row) => {
    const { onDoubleClick } = this.props;
    if (onDoubleClick) {
      event.preventDefault();
      event.stopPropagation();
      onDoubleClick(row);
    }
  };

  isSelected = (row) => {
    const { selected = [] } = this.props;
    return selected.indexOf(this.getKey(row)) !== -1;
  };

  getKey = (row, joinBy = "/", includeKey) => {
    const { keyFields, keyField } = this.props;
    if (row) {
      if (keyField) {
        return row[keyField];
      } else if (keyFields) {
        const arr = [];
        keyFields.forEach((key) => {
          arr.push(includeKey ? `${key}=${row[key]}` : row[key]);
        });
        return arr.join(joinBy);
      }
    }
    return "";
  };

  dataToShow = (row, key, t) => {
    if (key == "parametrizedWorkout" && row[key] && row[key].length)
      return (
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: workoutToText(row[key][2]),
          }}
        />
      );
    if (key == "workoutType") return t(`${WorkoutTypes.Items[row[key]]}`);
    return row[key];
  };

  render() {
    const {
      classes,
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      title,
      headers,
      updatable,
      updateRoute,
      duplicateRoute,
      onUpdateRequested,
      deleting,
      deleteRoute,
      selectable,
      extraActions,
      inlineSavingId,
      inlineEditCellId,
      onSelectInlineEditCell,
      onCellEditSaveRequested,
      isExternalCoach,
      sub,
      t,
    } = this.props;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          deleteRoute={deleteRoute}
          deleting={deleting}
          extraActions={extraActions}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            padding="none"
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              headers={headers}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              updatable={updatable}
              selectable={selectable}
            />
            <TableBody>
              {this.stableSort(data, this.getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isSelected = this.isSelected(row);
                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        !isExternalCoach ||
                        sub === row.created_by_cognito_user_sub
                          ? this.handleClick(event, row)
                          : ""
                      }
                      onDoubleClick={(event) =>
                        !isExternalCoach ||
                        sub === row.created_by_cognito_user_sub
                          ? this.handleDoubleClick(event, row)
                          : ""
                      }
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isSelected}
                    >
                      {selectable && (
                        <TableCell padding="checkbox">
                          <Checkbox checked={isSelected} />
                        </TableCell>
                      )}
                      {headers.map((header, index) => {
                        return header.multiLanguage ? (
                          <TableCell
                            padding={
                              header.disablePadding ? "dense" : "default"
                            }
                            key={index}
                            align={header.numeric ? "right" : "left"}
                            className={classNames({
                              [classes.bolderCell]: header.bolder,
                              [classes.multiline]: header.multiline,
                            })}
                          >
                            <div
                              dangerouslySetInnerHTML={
                                convertToRTFForView(
                                  row[header.id][header.lngToShow],
                                ) ||
                                convertToRTFForView(
                                  row[header.id][header.lngRep],
                                )
                              }
                            ></div>
                          </TableCell>
                        ) : (
                          <TableCell
                            padding={
                              header.disablePadding ? "dense" : "default"
                            }
                            key={index}
                            align={header.numeric ? "right" : "left"}
                            className={classNames({
                              [classes.bolderCell]: header.bolder,
                              [classes.multiline]: header.multiline,
                            })}
                          >
                            {updatable && header.inlineEditor ? (
                              <EnhancedTableCellEditor
                                name={header.id}
                                value={row[header.id]}
                                inlineEditId={inlineEditCellId}
                                id={this.getKey(row)}
                                onEditRequested={onSelectInlineEditCell}
                                onCancel={onSelectInlineEditCell}
                                onSave={onCellEditSaveRequested}
                                savingId={inlineSavingId}
                              />
                            ) : !updatable && header.clickable ? (
                              <Link
                                component="button"
                                color="inherit"
                                onClick={(event) =>
                                  this.handleDoubleClick(event, row)
                                }
                              >
                                {row[header.id]}
                              </Link>
                            ) : (
                              this.dataToShow(row, header.id, t)
                            )}
                          </TableCell>
                        );
                      })}
                      {updatable &&
                        (updateRoute ? (
                          <EnhancedTableCellEdit
                            linkTo={`${updateRoute}/${this.getKey(row, "/")}`}
                          />
                        ) : onUpdateRequested ? (
                          <EnhancedTableCellEdit
                            onClick={(event) => onUpdateRequested(row)}
                          />
                        ) : null)}
                      {duplicateRoute && (
                        <EnhancedTableCellEdit
                          icon={
                            <FontAwesomeIcon variant="regular" icon="copy" />
                          }
                          linkTo={`${duplicateRoute}?${this.getKey(
                            row,
                            "&",
                            true,
                          )}`}
                        />
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  data: PropTypes.array,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  selected: PropTypes.array,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  keyField: PropTypes.string,
  keyFields: PropTypes.array,
  title: PropTypes.string,
  headers: PropTypes.array,
  updatable: PropTypes.bool,
  updateRoute: PropTypes.string,
  deleting: PropTypes.bool,
  deleteRoute: PropTypes.string,
  selectable: PropTypes.bool,
  onUpdateRequested: PropTypes.func,
  onDoubleClick: PropTypes.func,
  extraActions: PropTypes.array,
  onCellEditSaveRequested: PropTypes.func,
  inlineSavingId: PropTypes.string || PropTypes.number,
};

EnhancedTable.defaultProps = {
  data: [],
  selected: [],
  rowsPerPage: 30,
  page: 1,
  updatable: true,
  selectable: true,
};

export default withTranslation("constants")(
  withStyles(styles)(memo(EnhancedTable)),
);
