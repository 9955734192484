import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  AppBar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { CloseIcon } from "../../../components/icons";
import usePost from "../../../hooks/usePost";
import PostCardComponent from "../../../components/common/v2/PostCardComponent";
import PostCardComponentSkeleton from "../../../components/common/v2/PostCardComponentSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { gsap } from "gsap";
import SmallAvatar from "../../../components/generals/small-avatar";
import useUserInfo from "../../../hooks/useUserInfo";
import CommunityTagsTab from "../../../components/common/v2/CommunityTagsTab";
import CommunityPostFooter from "../../../components/common/v2/CommunityPostFooter";
import useCreatePost from "../../../hooks/useCreatePost";
import CommunityTagsDropdown from "../../../components/common/v2/CommunityTagsDropdown";
import { useTranslation } from "react-i18next";
import { logEvent, setEventsMetadata } from "../../../lib/events-helper";
import { IconEdit } from "@tabler/icons-react";

const CommunityView = () => {
  const { tags, posts, handleGetUserPosts } = usePost();
  const { handleTextChange, handleResetPost } = useCreatePost();
  const classes = useStyles();
  const { name, sub } = useUserInfo();
  const { t } = useTranslation("community");
  const [inputClicked, setInputClicked] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [postText, setPostText] = useState("");

  const handleTagSelect = (tag) => {
    let updatedTags = [];
    if (selectedTags.some((t) => t.id === tag.id)) {
      updatedTags = selectedTags.filter((t) => t.id !== tag.id);
    } else {
      updatedTags = [...selectedTags, tag];
    }

    setSelectedTags(updatedTags);

    const tagValues = updatedTags.map((t) => t.id);

    handleGetUserPosts(new Date().getTime(), "", tagValues, false, true);
  };

  const fetchMorePosts = () => {
    if (posts && posts.data && posts.data.length > 0) {
      posts.data.sort((a, b) => b.created_at - a.created_at);

      const lastCreatedAt = posts.data[posts.data.length - 1].created_at;

      if (lastCreatedAt) {
        handleGetUserPosts(lastCreatedAt, "", "", false, false);
      }
    }
  };

  const handleClosePost = () => {
    handleResetPost();
    setPostText("");
    handleInputClick(false);
  };

  const handleClose = () => {
    handleResetPost();
    setPostText("");
    handleInputClick(false);
  };

  let items = [];
  if (posts && posts.data && Array.isArray(posts.data)) {
    posts.data.sort((a, b) => b.created_at - a.created_at);

    items = posts.data.map((post) => (
      <PostCardComponent key={post.id} postData={post} />
    ));
  }

  useEffect(() => {
    setEventsMetadata({ customer_id: sub });
    logEvent("visit_trailhead");

    handleGetUserPosts(new Date().getTime(), "", "", false, true);
  }, []);

  const handleInputClick = (clicked) => {
    if (clicked && !inputClicked) {
      setInputClicked(true);
      gsap.to("#comment_field", {
        height: "200px",
        minHeight: "200px",
        maxHeight: "200px",
        duration: 0.5,
        resize: "none",
      });
      gsap.to("#comment_parent", {
        duration: 0.5,
        backgroundColor: "#FAFAFA",
      });
      gsap.to("#edit-icon", { opacity: 0, width: "0px", duration: 0.5 });
      gsap.to("#comment_avatar", {
        opacity: 1,
        height: "32px",
        duration: 0.5,
        margin: "16px",
      });
    } else if (!clicked && inputClicked) {
      setInputClicked(false);
      gsap.to("#comment_avatar", {
        opacity: 0,
        height: "0px",
        duration: 0.5,
        margin: "0px",
      });
      gsap.to("#edit-icon", { opacity: 1, width: "auto", duration: 0.5 });
      gsap.to("#comment_field", {
        height: "23px",
        duration: 0.5,
        minHeight: "23px",
        maxHeight: "23px",
      });
      gsap.to("#comment_parent", {
        duration: 0.5,
        backgroundColor: "#FEFFFF",
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Box
        data-testid="comment_parent"
        id="comment_parent"
        className={classes.postParent}
      >
        <Stack
          data-testid="comment_avatar"
          id="comment_avatar"
          direction="row"
          spacing={2}
          className={classes.postAvatar}
        >
          <SmallAvatar level={false} customSize="32px" onTrailHead={true} />
          <Typography component={"span"} className={classes.heading}>
            {name}
          </Typography>
        </Stack>
        <FormControl fullWidth hiddenLabel data-testid="new_post" id="new_post">
          <OutlinedInput
            data-testid="post_text"
            id="post_text"
            onClick={() => handleInputClick(true)}
            multiline
            first
            onBlur={() => handleTextChange(postText)}
            value={postText}
            onChange={(e) => {
              setPostText(e.target.value);
              if (e.target.value.length === 1) {
                handleTextChange(e.target.value);
              }
              if (e.target.value.length === 0) {
                handleTextChange(e.target.value);
              }
            }}
            placeholder={t("community.newPost.placeholder")}
            color="secondary"
            aria-label="naked"
            className={classes.inputForm}
            startAdornment={
              <InputAdornment
                position="start"
                data-testid="edit-icon"
                id="edit-icon"
              >
                <IconEdit stroke={1} color="#AFB1B4" size={"24px"} />
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      {inputClicked ? (
        <Box>
          <AppBar
            data-testid="comment_appbar"
            id="comment_appbar"
            position="fixed"
            className={classes.appbar}
          >
            <Box className={classes.appbarBox} />
            <Typography className={classes.title}>
              {t("community.newPost.title")}
            </Typography>
            <IconButton
              data-testid="close_post"
              id="close_post"
              disableRipple
              disableFocusRipple
              aria-label="close"
              onClick={handleClosePost}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </AppBar>
          <Box sx={{ m: 2 }}>
            <CommunityTagsDropdown />
            <CommunityPostFooter handleClose={handleClose} />
          </Box>
        </Box>
      ) : (
        <>
          <CommunityTagsTab
            tags={tags}
            onClick={handleTagSelect}
            selectedTags={selectedTags}
          />
          <div id="scroll-view" className={classes.scrollView}>
            <InfiniteScroll
              dataLength={(posts && posts.data && posts.data.length) || 0}
              next={fetchMorePosts}
              hasMore={true}
              scrollableTarget="scroll-view"
              loader={
                <Box className={classes.center}>
                  <PostCardComponentSkeleton />
                </Box>
              }
            >
              {items}
            </InfiniteScroll>
          </div>
        </>
      )}
    </Box>
  );
};

export default CommunityView;

const useStyles = makeStyles({
  heading: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  title: {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#FAFAFA",
    overflow: "hidden",
  },
  postParent: {
    position: "sticky",
    top: 0,
    zIndex: 200,
    backgroundColor: "#FEFFFF",
  },
  postAvatar: {
    margin: 0,
    opacity: 0,
    height: "0px",
  },
  inputForm: {
    marginBottom: "16px",
    height: "48px",
    marginLeft: "16px",
    marginRight: "16px",
    backgroundColor: "#fff",
    borderWidth: "2px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EAEAEA",
      borderWidth: "2px",
    },
  },
  scrollView: {
    flex: 1,
    overflowY: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
  center: {
    textAlign: "center",
  },
  appbar: {
    padding: "16px",
    height: "74px",
    boxShadow: "none",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
    backgroundColor: "#FEFFFF",
  },
  appbarBox: {
    width: "40px",
    height: "40px",
  },
  closeIcon: {
    width: "17px",
    height: "17px",
  },
});
