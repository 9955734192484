import ActionTypes from "../constants/action-types";
import CorosService from "../services/coros-service";
import { logEvent, setEventsMetadata } from "../lib/events-helper";
import { standarizeActivities } from "../lib/activities-helper";
import { setSuccessMessage } from "./user-plans-action";
import bugsnagClient from "../lib/bugsnag-client";
import UserPlansService from "../services/user-plans-service";

const initAuthorizationCoros = (cognito_user_sub, code) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.COROS_INIT_PENDING });
    try {
      const result = await CorosService.initAuthorization(
        code,
        cognito_user_sub,
      );

      dispatch({ type: ActionTypes.COROS_INIT_FULFILLED, payload: result });

      if (result) {
        const resultUpdate = await UserPlansService.updateCurrentUserInfo({
          cognito_user_sub: cognito_user_sub,
          coros_auth: result,
        });

        if (!resultUpdate.coros_auth) throw new Error("Coros auth not updated");

        dispatch(setSuccessMessage("Sync Coros account"));
        setEventsMetadata({ customer_id: cognito_user_sub });
        logEvent("connect_GPS_provider");
      }
    } catch (exp) {
      bugsnagClient.notify(exp);
      dispatch({
        type: ActionTypes.COROS_INIT_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getActivitiesCoros = (param, reload = false) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.COROS_LOAD_ACTIVITIES_PENDING,
      payload: reload,
    });
    try {
      let result = await CorosService.getActivities(param);
      result = standarizeActivities(result, "coros");

      dispatch({
        type: ActionTypes.COROS_LOAD_ACTIVITIES_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.COROS_LOAD_ACTIVITIES_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getActivityCoros = (params, activity) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.COROS_GET_ACTIVITY_PENDING });
    try {
      const result = await CorosService.getActivity(params);
      dispatch({
        type: ActionTypes.COROS_GET_ACTIVITY_FULFILLED,
        payload: { result },
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.COROS_GET_ACTIVITY_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const clearActivityCoros = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.COROS_CLEAR_ACTIVITY });
  };
};

const clearCorosActivities = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.COROS_CLEAR_ACTIVITIES });
  };
};
export {
  initAuthorizationCoros,
  getActivitiesCoros,
  getActivityCoros,
  clearActivityCoros,
  clearCorosActivities,
};
