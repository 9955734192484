import React from "react";
import { palette } from "../../theme/palette";

const GoogleIcon = ({ width = 24, height = 24, activated = false }) => (
  <svg
    width={width}
    height={height}
    viewBox={` 0 0 ${width} ${height}`}
    fill={activated ? palette.primary : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5654 12.2527C22.5654 11.505 22.5048 10.7532 22.3755 10.0176H12V14.2534H17.9415C17.695 15.6196 16.9028 16.8281 15.7428 17.596V20.3445H19.2875C21.369 18.4287 22.5654 15.5994 22.5654 12.2527Z"
      fill={activated ? palette.primary : "#4285F4"}
    />
    <path
      d="M11.9997 22.9995C14.9664 22.9995 17.4683 22.0254 19.2912 20.344L15.7465 17.5955C14.7603 18.2665 13.4871 18.6464 12.0037 18.6464C9.13403 18.6464 6.70083 16.7104 5.8278 14.1074H2.16992V16.9408C4.03726 20.6552 7.84064 22.9995 11.9997 22.9995Z"
      fill={activated ? palette.primary : "#34A853"}
    />
    <path
      d="M5.82395 14.1074C5.36318 12.7413 5.36318 11.262 5.82395 9.89584V7.0625H2.17012C0.609962 10.1707 0.609962 13.8326 2.17012 16.9408L5.82395 14.1074Z"
      fill={activated ? palette.primary : "#FBBC04"}
    />
    <path
      d="M11.9997 5.35381C13.5679 5.32956 15.0836 5.91967 16.2194 7.00289L19.3599 3.86237C17.3713 1.99504 14.732 0.968406 11.9997 1.00074C7.84064 1.00074 4.03726 3.34501 2.16992 7.06352L5.82375 9.89685C6.69275 7.28986 9.12999 5.35381 11.9997 5.35381Z"
      fill={activated ? palette.primary : "#EA4335"}
    />
  </svg>
);
export default GoogleIcon;
