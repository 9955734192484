import React, { Component, Fragment, memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import moment from "moment";
import withWidth from "@material-ui/core/withWidth";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import "moment/min/locales";
import DashboardTrainingWeek from "./dashboard-training/dashboard-training-week";
import DashboardTrainingActionsMenu from "./dashboard-training/dashboard-training-actions-menu";
import { dateFormat } from "../../lib/date-helper";
import DashboardTrainingPrevious from "./dashboard-training/dashboard-training-previous";
import DashboardTrainingNext from "./dashboard-training/dashboard-training-next";
import RouterLink from "../common/router-link";
import { getPlanLevel } from "../../lib/plan-helper";
import { currentTrainingCurrentStartDateSet } from "../../actions/user-plans-action";
import { connect } from "react-redux";

const styles = (theme) => ({
  container: {
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
      marginTop: theme.spacing.unit,
    },
  },
  workoutContainer: {
    position: "relative",
    /*background: "#FCFCFC",
    boxShadow: "0 0 12px rgba(33, 81, 209, 0.1)",
    borderRadius: 18,
    margin: "0 15px 20px 0",
    padding: "30px 15px",
    
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
      margin: 5,
      padding: 5,
      background: "#EFF3F5",
    },*/
  },
  emptyContainer: {
    margin: 20,
    display: "flex",
    background: "#FCFCFC",
    boxShadow: "0 0 12px rgba(33, 81, 209, 0.1)",
    borderRadius: 18,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginBottom: 22,
    },
  },
  topBarTitle: {
    fontWeight: 500,
    fontSize: 12,
    color: "#202020",
    marginRight: 16,
  },

  loading: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1,
    background: "#fff",
    opacity: 0.5,
    display: "flex",
  },
  progress: {
    margin: "auto",
  },
  activate: {
    background: "#FFD8C5",
    borderRadius: 8,
    padding: 15,
    margin: 40,
  },
  activateText: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#FF6327",
  },
  weeksText: {
    color: "#212121",
    fontSize: 18,
    fontFamily: "Avenir",
    fontWeight: "normal",
    marginLeft: 12,
  },
  emptyContainerText: {
    fontSize: 18,
    margin: 20,
    fontWeight: "normal",
  },
  topBarMobile: {
    display: "flex",
    marginBottom: 10,
    flexDirection: "column-reverse",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginBottom: 22,
    },
  },
  barContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "75%",
    margin: "0 auto",
  },
  superBarParent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column-reverse",
  },
  superBarParentHidden: {
    display: "flex",
    flexDirection: "column-reverse",
  },
});

class DashboardTraining extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleCurrentWeek = () => {
    const weekNumber = moment().week();
    const date = moment()
      .clone()
      .week(weekNumber)
      .day("Monday")
      .format("YYYY-MM-DD hh:mm:ss");

    this.props.dispatch(currentTrainingCurrentStartDateSet(date));
  };

  componentDidMount() {
    this.handleCurrentWeek();
  }

  componentDidUpdate() {
    const { updating } = this.props;

    if (updating) {
      if (!this.state.loading) {
        this.setState({
          ...this.state,
          loading: true,
        });
      }
    } else {
      if (this.state.loading) {
        setTimeout(() => {
          this.setState({
            ...this.state,
            loading: false,
          });
        }, 1000);
      }
    }
  }

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    const {
      classes,
      width,
      xs,
      sm,
      md,
      lg,
      xl,
      className,
      currentTraining,
      onCurrentDaysChangeRequested,
      onWorkoutsMoved,
      updatingWorkoutId,
      selectedPlan,
      userEmail,
      onError,
      t,
      i18n,
      subscriptionEnd,
      status,
      onRedoWeek,
      onAddEasyWeek,
      onRestartPlan,
      onOpenAdd,
      hasSubscription,
      loading,
      onSendAnalytics,
    } = this.props;

    const { language } = i18n;
    const momentLocale = moment().locale(language);

    const isInMobileView = width === "md" || width === "sm" || width === "xs";
    const {
      currentStartDate = moment().startOf("isoWeek"),
      startingDate,
      days,
      durationInWeeks,
      raceCount,
      trainPerWeek,
    } = currentTraining || {};
    const dayCount = 7;

    const level = getPlanLevel(currentTraining);

    const isBeforePlan = moment(currentStartDate).isBefore(
      moment(startingDate, dateFormat).startOf("isoWeek"),
    );

    const startingDateMoment = moment(startingDate, dateFormat);
    const currentDay =
      moment(currentStartDate)
        .startOf("isoWeek")
        .diff(startingDateMoment.startOf("isoWeek"), "days") + 1;

    const currentWeek = Math.ceil((currentDay > 0 ? currentDay : 0) / 7);

    let nextDuration = durationInWeeks ? parseInt(durationInWeeks) : 1;
    if (subscriptionEnd) {
      const subsEndMoment = moment.unix(subscriptionEnd).startOf("isoWeek");
      let diffWithSubsEnd = subsEndMoment.diff(
        moment(startingDate, dateFormat).startOf("isoWeek"),
        "weeks",
      );
      diffWithSubsEnd = diffWithSubsEnd <= 0 ? 1 : diffWithSubsEnd;
      if (status === "active" || status === "non_renewing") {
        diffWithSubsEnd += 2;
      }
      if (status === "in_trial") {
        diffWithSubsEnd += 1;
      }
      if (diffWithSubsEnd < nextDuration) {
        nextDuration = diffWithSubsEnd;
      }
    }

    return (
      <Grid
        item
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        className={classNames(classes.container, className)}
      >
        {!hasSubscription && selectedPlan !== "free" ? (
          /*<div className={classes.activate}>
            <Typography variant="body1" className={classes.activateText}>
              {t("Activate full text")}
              <RouterLink to="/chooseplan?filter=free">
                <u>{t("Activate full text 1")}</u>
              </RouterLink>
            </Typography>
          </div>*/ ""
        ) : currentTraining &&
          currentTraining.days &&
          currentTraining.days.length ? (
          <Fragment>
            <div
              className={
                !isInMobileView ? classes.topBar : classes.topBarMobile
              }
            >
              <div
                className={
                  !isInMobileView
                    ? classes.superBarParent
                    : classes.superBarParentHidden
                }
              >
                <div className={classes.barContent}>
                  <DashboardTrainingPrevious
                    isInMobileView={isInMobileView}
                    currentStartDate={currentStartDate}
                    startingDate={startingDate}
                    selectedPlan={selectedPlan}
                    onCurrentDaysChangeRequested={onCurrentDaysChangeRequested}
                    dayCount={dayCount}
                    hasSubscription={hasSubscription}
                  />

                  <Typography
                    variant={isInMobileView ? "caption" : "body1"}
                    className={classes.weeksText}
                  >
                    {isInMobileView
                      ? `${t("Week")} ${currentWeek}, `
                      : t("Week of", { currentWeek, durationInWeeks })}
                    {isInMobileView ? (
                      <b>
                        {`${momentLocale
                          .localeData()
                          .months(moment(currentStartDate))
                          .substr(0, 3)} ${moment(currentStartDate)
                          .startOf("isoWeek")
                          .format("D")} - ${moment(currentStartDate)
                          .endOf("isoWeek")
                          .format("D")}`}
                      </b>
                    ) : (
                      ""
                    )}
                  </Typography>

                  <DashboardTrainingNext
                    isInMobileView={isInMobileView}
                    currentStartDate={currentStartDate}
                    startingDate={startingDate}
                    selectedPlan={selectedPlan}
                    onCurrentDaysChangeRequested={onCurrentDaysChangeRequested}
                    dayCount={dayCount}
                    duration={durationInWeeks}
                    hasSubscription={hasSubscription}
                  />
                </div>

                <DashboardTrainingActionsMenu
                  onRedoWeek={(to) => onRedoWeek(currentStartDate, to)}
                  onAddEasyWeek={() =>
                    onAddEasyWeek(currentStartDate, raceCount, trainPerWeek)
                  }
                  onRestartPlan={onRestartPlan}
                  onOpenAdd={onOpenAdd}
                  currentStartDate={currentStartDate}
                  hasSubscription={hasSubscription}
                ></DashboardTrainingActionsMenu>
              </div>
            </div>

            {currentWeek > durationInWeeks ? (
              <div className={classes.emptyContainer}>
                <RouterLink to="/chooseplan">
                  <Typography
                    variant="h6"
                    dangerouslySetInnerHTML={{
                      __html: t("You currently dont have a training plan"),
                    }}
                    className={classes.emptyContainerText}
                  ></Typography>
                </RouterLink>
              </div>
            ) : (
              <div className={classes.workoutContainer}>
                {isBeforePlan ? (
                  <div className={classes.emptyContainer}>
                    <Typography
                      variant="h6"
                      className={classes.emptyContainerText}
                    >
                      {t("Before plan", {
                        day: `${moment(startingDate, dateFormat).format("D")}`,
                        month: `${momentLocale
                          .localeData()
                          .months(moment(startingDate, dateFormat))}`,
                        ns: "dashboard",
                      })}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
                {this.state.loading ? (
                  <div className={classes.loading}>
                    <CircularProgress
                      variant="indeterminate"
                      color="primary"
                      size={50}
                      className={classes.progress}
                    />
                  </div>
                ) : (
                  ""
                )}
                {!isBeforePlan ? (
                  <DashboardTrainingWeek
                    days={days}
                    currentStartDate={currentStartDate}
                    updatingWorkoutId={updatingWorkoutId}
                    dayCount={dayCount}
                    isInMobileView={isInMobileView}
                    onCurrentDaysChangeRequested={onCurrentDaysChangeRequested}
                    onWorkoutsMoved={onWorkoutsMoved}
                    userEmail={userEmail}
                    selectedPlan={selectedPlan}
                    onError={onError}
                    hasSubscription={hasSubscription}
                    nextDuration={nextDuration}
                    onSendAnalytics={onSendAnalytics}
                    level={level}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </Fragment>
        ) : !loading ? (
          <div className={classes.emptyContainer}>
            <Typography variant="h6" className={classes.emptyContainerText}>
              {t("Your coach is working on your training plan")}
            </Typography>
          </div>
        ) : (
          ""
        )}
      </Grid>
    );
  }
}

DashboardTraining.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  className: PropTypes.string,
  currentTraining: PropTypes.object,
  onCurrentDaysChangeRequested: PropTypes.func.isRequired,
  onWorkoutsMoved: PropTypes.func.isRequired,
  onRedoWeek: PropTypes.func.isRequired,
  onAddEasyWeek: PropTypes.func.isRequired,
  onRestartPlan: PropTypes.func,
  onOpenAdd: PropTypes.func.isRequired,
  updatingWorkoutId: PropTypes.string,
  updating: PropTypes.bool,
  selectedPlan: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
  subscriptionEnd: PropTypes.number,
  onError: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withTranslation("dashboard")(
    withWidth()(withStyles(styles)(memo(DashboardTraining))),
  ),
);
