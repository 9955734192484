import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { palette } from "../../../theme/palette";
import { useTranslation } from "react-i18next";
import useFavorites from "../../../hooks/useFavorites";
import getTimeIcon from "../getTimeIcon";

function PopularVideosHomeCard({ item, navigate }) {
  const { language } = useTranslation();
  const { toggleFavorites, isFavorite } = useFavorites();
  const classes = useStyles();

  return (
    <Box className={classes.card} onClick={() => navigate(item.id)}>
      <div style={imageStyles(item.image_url)}>
        <IconButton
          className={classes.favoriteButton}
          size="small"
          aria-label="add to favorites"
          onClick={(e) => {
            e.stopPropagation();
            toggleFavorites("video", item.id);
          }}
        >
          {isFavorite("video", item.id) ? (
            <StarIcon color="primary" />
          ) : (
            <StarBorderIcon style={{ color: "white" }} />
          )}
        </IconButton>
      </div>
      <Box className={classes.descriptionContainer}>
        <Box className={classes.titleContainer}>
          <Box className={classes.timeContainer}>
            {getTimeIcon(item.time || item.workout_time)}
            <Typography className={classes.time}>
              {item.time || item.workout_time}
            </Typography>
          </Box>
          <Typography className={classes.workout}>
            {item.workout_type && item.workout_type[language]
              ? item.workout_type[language]
              : item.workout_type && item.workout_type["en"]}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default PopularVideosHomeCard;

const useStyles = makeStyles(() => ({
  card: {
    background: palette.white,
    borderRadius: "8px",
    width: "275px",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
  descriptionContainer: {
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  carousel: {
    display: "-webkit-inline-box",
    width: "100%",
    overflowY: "hidden",
    overflowX: "scroll",
    scrollbarWidth: "none",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  seeAll: {
    color: palette.lightBlue,
  },
  bold: {
    fontFamily: "Karla",
    fontSize: "22px",
    lineHeight: "33px",
    letterSpacing: "0px",
    fontWeight: "700",
  },
  favoriteButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "#FFFFFF33",
  },
  icon: {
    color: "#6D6F71",
    width: "18px",
    margin: 0,
    padding: 0,
  },
  time: {
    color: "#6D6F71",
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
  },
  workout: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#212121",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "space-between",
    gap: "8px",
  },
  timeContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
}));

const imageStyles = (url) => ({
  borderRadius: "8px 8px 0 0",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url(${url})`,
  height: "125px",
  position: "relative",
});
