import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import { updatePlan } from "../../actions/user-plans-action";
import { dateFormat } from "../../lib/date-helper";
import { getChallenges } from "../../actions/challenges-action";
import { logEvent } from "../../lib/events-helper";
import DashboardTraining from "./dashboard-training";
import DashoardNextRaceDetails from "./dashboard-training/dashboard-next-race/dashboard-next-race-details";
import DashboardCalendar from "./dashboard-calendar/dashboard-calendar";
import DashboardTrainingWeekday from "./dashboard-training/dashboard-training-weekday";
import DashboardDesktopCharts from "./dashboard-desktop-charts";
import DashboardWeeklyReportDiagram from "./dashboard-weekly-report-diagram";
import DashboardWeeklyReportDiagramMobile from "./dashboard-weekly-report-diagram-mobile";
import DashboardTrainingDaily from "./dashboard-training-daily";
import DashboardRecentActivities from "./dashboard-recent-activities";
import CompleteProfile from "../common/complete-profile";
import TrailLevelPopup from "../common/trail-level-popup";
import { createAnalyticData } from "../../lib/analytic-helper";
import { pushAnalyticData } from "../../actions/analytic-action";
import { getPlanLevel } from "../../lib/plan-helper";
import { checkSubscription } from "../../lib/subscription-plan-helper";

const styles = (theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,

    height: "100vh",
    boxShadow: "4px 0px 12px rgba(33, 81, 209, 0.04)",

    [theme.breakpoints.down("md")]: {
      height: "100%",
      borderRadius: "0 0 18px 18px",
      backgroundColor: "#eaeff4",
    },
  },

  title: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 20,
    color: "#212121",
    margin: "24px 12px",
  },

  topBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    justifyContent: "space-around",
    padding: "4px 8px",
  },

  filterDesktop: {
    fontSize: 14,
    fontWeight: 400,
    background: "rgba(63, 81, 181, 0.12);",
    borderRadius: 4,
    padding: "6px 8px",
    marginLeft: 5,
    marginRight: 5,
    color: "#212121",
    cursor: "pointer",
    width: "47%",
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      minWidth: "15%",
    },
  },
  filterSelectedDesktop: {
    fontSize: 14,
    fontWeight: 400,
    background: "#1E629B",
    borderRadius: 4,
    padding: "6px 8px",
    marginLeft: 5,
    marginRight: 5,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "47%",
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      minWidth: "15%",
    },
  },

  scroll: {
    overflowY: "scroll",
    overflowX: "hidden",
  },

  calendarContainer: {
    padding: 12,
  },

  calendarRace: {
    margin: -12,
  },

  topBarMobile: {
    alignItems: "center",
    marginBottom: 20,
    justifyContent: "center",
    top: 0,
    zIndex: 100,
    background: "#fff",
  },

  progressContainer: {
    textAlign: "center",
    width: "100%",
  },

  loading: {
    display: "block",
    margin: "auto",
  },

  loadingText: {
    color: "#FF6327",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Avenir",
    marginTop: 10,
  },
});

class DashboardTrainingColumn extends Component {
  state = {
    tab: 0,
    notificationsScheduled: false,
    units: "kms",
    calendarSelectedWorkout: null,
    calendarSelectedRace: null,
    currentDateMobile: moment(),
  };

  async componentDidUpdate(prevPops) {
    this.setSocial();

    const { challenges, auth, userPlans } = this.props;

    if (
      prevPops.challenges.loadingChallenges &&
      !challenges.loadingChallenges
    ) {
      const { currentUser } = auth;
      const { attributes } = currentUser || {};
      const { sub } = attributes || {};
      if (sub) {
        let yourChallenges = challenges.currentChallenges
          ? challenges.currentChallenges.filter(
              (c) => c.participants && c.participants.includes(sub),
            )
          : [];
        this.props.dispatch(
          updatePlan(
            {
              cognito_user_sub: sub,
              currentChallenges: yourChallenges
                .map((c) => (c && c.id ? c.id : null))
                .filter((cs) => cs)
                .join(","),
            },
            false,
            false,
          ),
        );
      }
    }

    if (
      prevPops.challenges.loadingYourChallengeEntries &&
      !challenges.loadingYourChallengeEntries
    ) {
      const { currentUser } = auth;
      const { attributes } = currentUser || {};
      const { sub } = attributes || {};

      const { currentPlan } = userPlans;
      let { pastChallenges = "" } = currentPlan || "";

      if (sub) {
        const { yourChallengeEntries } = challenges || {};
        const yourDoneChallenges = yourChallengeEntries
          ? yourChallengeEntries.filter(
              (e) => e.challengeData.end_date < new Date().getTime(),
            )
          : [];

        const pastChallengesSrt = yourDoneChallenges
          .map((c) =>
            c && c.challengeData && c.challengeData.id
              ? (c.challengeData.goal && c.finished) ||
                (!c.challengeData.goal && c.activities && c.activities.length)
                ? c.challengeData.id + "_Completed"
                : c.challengeData.id + "_NotCompleted"
              : null,
          )
          .filter((cs) => cs)
          .join(",");

        this.props.dispatch(
          updatePlan(
            {
              cognito_user_sub: sub,
              pastChallenges: pastChallengesSrt,
            },
            false,
            false,
          ),
        );
      }
    }
  }

  sendAnalytics() {
    const { auth, userPlans, subscriptions } = this.props;
    const { currentUser } = auth || {};
    const { attributes } = currentUser || {};
    const { email } = attributes || {};
    const analyticData = createAnalyticData(
      {
        username: email,
        userPlans,
        subscriptions,
      },
      "open workout",
    );
    this.props.dispatch(pushAnalyticData(analyticData));
  }

  setSocial() {
    const { userPlans } = this.props;
    const { athleteDescription, questions } = this.state;
    const { currentPlan } = userPlans;
    const { description, profileQuestions } = currentPlan || {};

    let newState = {};
    let hasChanged = false;
    if (description && athleteDescription === null) {
      newState.athleteDescription = description;
      hasChanged = true;
    }

    if (profileQuestions && !questions) {
      newState.questions = profileQuestions;
      hasChanged = true;
    }

    if (hasChanged) {
      this.setState({
        ...this.state,
        ...newState,
      });
    }
  }

  async componentDidMount() {
    this.setSocial();

    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes || {};

    if (sub) {
      this.props.dispatch(getChallenges(sub));
    }

    if (typeof window !== "undefined") {
      this.setState({
        ...this.state,
        units: localStorage.getItem("units"),
      });

      window.addEventListener("storage", () => this.localStorageUpdated());
    }

    if (
      window.location.pathname &&
      window.location.pathname.startsWith("/dashboard/races")
    ) {
      setTimeout(() => {
        this.setState({
          ...this.state,
          tab: 2,
        });
      }, 100);
    }
  }

  localStorageUpdated() {
    this.setState({
      ...this.state,
      units: localStorage.getItem("units"),
    });
  }

  async componentWillReceiveProps(nextProps) {
    const { image } = nextProps;
    const { avatarUrl } = this.state;
    if (image && !avatarUrl) {
      this.setState({
        ...this.state,
        avatarLoading: true,
      });
      this.getAvatarUrl();
    }
  }

  handleChangeTab = (newValue) => {
    this.setState({
      ...this.state,
      currentTab: newValue,
    });
  };

  handleCalendarSelected = (calendarSelected) => {
    this.setState({
      ...this.state,
      calendarSelectedWorkout:
        calendarSelected && calendarSelected.workout
          ? calendarSelected.workout
          : null,
      calendarSelectedRace:
        calendarSelected && calendarSelected.race
          ? calendarSelected.race
          : null,
    });
  };

  handleDaysChangeMobile = (date) => {
    this.setState({ ...this.state, currentDateMobile: date });
  };

  render() {
    const {
      classes,
      userPlans,
      subscriptions,
      width,

      t,
      xs,
      sm,
      md,
      lg,
      xl,
      onCurrentDaysChangeRequested,
      onWorkoutsMoved,
      onError,
      onRedoWeek,
      onAddEasyWeek,
      onRestartPlan,
      onOpenAdd,
      strava,
      garmin,
      suunto,
      vertActivities,
      openLevel,
      onCloseLevel,
      healthKit,
      activities,
      coros,
    } = this.props;
    const { currentPlan, updatingWorkoutId, updating, loading } = userPlans;

    const {
      currentTraining,
      desiredStartDate,
      cognito_user_email,
      strava_athlete_id,
      garmin_oauth_token,
      suunto_auth,
      createdAt,
      userType,
      coros_auth,
    } = currentPlan || "";

    const { chainedPlans } = currentTraining || {};
    const level = getPlanLevel(currentTraining);

    const {
      tab,
      calendarSelectedWorkout,
      calendarSelectedRace,
      units,
      currentDateMobile,
    } = this.state;

    const fullTraining = currentTraining;

    const { loading: loadingStrava } = strava;
    const { loading: loadingGarmin } = garmin;
    const { loading: loadingSuunto } = suunto;
    const { loading: loadingCoros } = coros;
    const { activities: activitiesVert, loading: loadingVert } = vertActivities;
    const {
      activities: healthActivities,
      loading: loadingHealthkit,
    } = healthKit;
    const loadingActivities =
      loadingGarmin ||
      loadingSuunto ||
      loadingCoros ||
      loadingStrava ||
      loadingVert ||
      loadingHealthkit;

    const noTracker =
      !loading &&
      !strava_athlete_id &&
      !garmin_oauth_token &&
      !suunto_auth &&
      !coros_auth &&
      (!healthActivities || !healthActivities.length) &&
      (!activitiesVert || !activitiesVert.length);

    const { nextRace, nextRaces } = currentPlan || {};
    let allRaces = [];
    if (nextRace) {
      const today = moment();
      const raceDateMoment = moment(nextRace.raceDate, "DD/MM/YYYY");
      const weeksToRace = raceDateMoment.diff(today, "day");
      if (weeksToRace >= 0) {
        allRaces.push(nextRace);
      }
    }
    if (nextRaces && nextRaces.length > 0) {
      nextRaces.forEach((element) => {
        const today = moment();
        const raceDateMoment = moment(element.raceDate, "DD/MM/YYYY");
        const weeksToRace = raceDateMoment.diff(today, "day");
        if (weeksToRace >= 0) {
          allRaces.push(element);
        }
      });
    }

    allRaces.sort((a, b) => {
      let aMoment;
      if (a && a.raceDate) {
        aMoment = moment(a.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      let bMoment;
      if (b && b.raceDate) {
        bMoment = moment(b.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      return aMoment.diff(bMoment, "days");
    });

    const { loadingLatest, latest, alreadyLoadedOnce } = subscriptions;
    const { trial_end, current_term_end, status } = latest || {};
    const { latestSubscriptionFinalizedTime } = currentPlan || {};
    let showCompleteSubscriptionButton = false;

    const { durationInWeeks, startingDate } = fullTraining || {};

    let { selectedPlan } = fullTraining || {};

    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    const isSubscribed = checkSubscription(
      subscriptions,
      latestSubscriptionFinalizedTime,
    );

    const subscriptionEnd = current_term_end ? current_term_end : trial_end;
    let nextDuration = durationInWeeks ? parseInt(durationInWeeks) : 1;
    if (subscriptionEnd) {
      const subsEndMoment = moment.unix(subscriptionEnd).startOf("isoWeek");
      let diffWithSubsEnd = subsEndMoment.diff(
        moment(startingDate, dateFormat).startOf("isoWeek"),
        "weeks",
      );
      diffWithSubsEnd = diffWithSubsEnd <= 0 ? 1 : diffWithSubsEnd;
      if (status === "active" || status === "non_renewing") {
        diffWithSubsEnd += 2;
      }
      if (status === "in_trial") {
        diffWithSubsEnd += 1;
      }
      if (diffWithSubsEnd < nextDuration) {
        nextDuration = diffWithSubsEnd;
      }
    }
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { birthdate, gender, locale } = attributes || {};

    const lastVisibleDate = moment()
      .startOf("isoWeek")
      .add(nextDuration, "weeks");

    const isInMobileView = width === "md" || width === "sm" || width === "xs";

    let elevation = 0;
    let distance = 0;
    if (activities && activities.length > 0) {
      const elevationDecimal = activities
        .map((activity) => activity.total_elevation_gain || 0)
        .reduce((sum, a) => sum + a);
      elevation = parseInt(elevationDecimal || 0);
      const distanceMeters = activities
        .map((activity) => activity.distance || 0)
        .reduce((sum, a) => sum + a);
      distance = parseFloat(
        parseFloat(distanceMeters / 1000).toFixed(1),
      ).toLocaleString();
    }

    const hasCurrentTraining =
      currentTraining && typeof currentTraining === "object";

    const hasProfileCompleted = birthdate && locale && gender;

    return (
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        {isInMobileView && fullTraining ? (
          <div className={classes.topBarMobile}>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(ev, index) =>
                this.setState({
                  tab: index,
                })
              }
              variant="fullWidth"
            >
              <Tab label={t("Daily", { ns: "dashboard" })} index={0} />
              <Tab label={t("Weekly", { ns: "dashboard" })} index={1} />
              <Tab label={t("Achieved", { ns: "dashboard" })} index={2} />
            </Tabs>
          </div>
        ) : (
          ""
        )}
        <div className={classes.container}>
          {!isInMobileView ? (
            <Typography variant="body" className={classes.title}>
              {t("Training plan", { ns: "dashboard" })}
            </Typography>
          ) : (
            ""
          )}

          {!isInMobileView && hasCurrentTraining ? (
            <div className={classes.topBar}>
              <Typography
                variant="body1"
                className={
                  tab === 0
                    ? classes.filterSelectedDesktop
                    : classes.filterDesktop
                }
                onClick={() => this.setState({ tab: 0 })}
              >
                {t("Weekly view", { ns: "dashboard" })}
              </Typography>
              <Typography
                variant="body1"
                className={
                  tab === 1
                    ? classes.filterSelectedDesktop
                    : classes.filterDesktop
                }
                onClick={() => {
                  this.setState({ tab: 1 });
                }}
              >
                {t("Monthly view", { ns: "dashboard" })}
              </Typography>
            </div>
          ) : (
            ""
          )}

          <div className={classes.scroll}>
            {tab === 0 && isInMobileView && (
              <>
                {openLevel ? (
                  <TrailLevelPopup
                    isOpen={openLevel}
                    onClose={() => onCloseLevel()}
                  ></TrailLevelPopup>
                ) : (
                  ""
                )}

                {fullTraining ? (
                  <DashboardTrainingDaily
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.training}
                    desiredStartDate={desiredStartDate}
                    currentTraining={fullTraining}
                    updatingWorkoutId={updatingWorkoutId}
                    updating={updating}
                    onCurrentDaysChangeRequested={onCurrentDaysChangeRequested}
                    onWorkoutsMoved={onWorkoutsMoved}
                    onError={onError}
                    onRedoWeek={onRedoWeek}
                    onAddEasyWeek={onAddEasyWeek}
                    onRestartPlan={onRestartPlan}
                    onOpenAdd={onOpenAdd}
                    selectedPlan={selectedPlan}
                    userEmail={cognito_user_email}
                    subscriptionEnd={
                      current_term_end ? current_term_end : trial_end
                    }
                    status={status}
                    hasSubscription={isSubscribed}
                    lastVisibleDate={lastVisibleDate}
                    loading={loading || loadingLatest || !alreadyLoadedOnce}
                    onDaysChangeMobile={this.handleDaysChangeMobile}
                  />
                ) : (
                  ""
                )}

                {(!hasProfileCompleted || !hasCurrentTraining) &&
                !loading &&
                moment(createdAt).isBefore(moment().add(14, "days")) ? (
                  <CompleteProfile
                    hasCurrentTraining={hasCurrentTraining}
                    userType={userType}
                  ></CompleteProfile>
                ) : (
                  ""
                )}

                {loadingActivities && (!activities || !activities.length) ? (
                  <div className={classes.progressContainer}>
                    <CircularProgress
                      variant="indeterminate"
                      color="primary"
                      className={classes.loading}
                      data-testid="LoadingUser"
                    />
                    <Typography variant="body1" className={classes.loadingText}>
                      {t("Loading activities", { ns: "dashboard" })}
                    </Typography>
                  </div>
                ) : (
                  <DashboardRecentActivities
                    activities={activities}
                    units={units}
                    noTracker={noTracker}
                  ></DashboardRecentActivities>
                )}

                <DashoardNextRaceDetails></DashoardNextRaceDetails>

                <DashboardWeeklyReportDiagramMobile
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  currentTraining={fullTraining}
                  distance={distance}
                  elevation={elevation}
                  activities={activities}
                  noTracker={noTracker}
                  loading={loading}
                  currentDate={currentDateMobile}
                />
              </>
            )}

            {((tab === 0 && !isInMobileView) ||
              (tab === 1 && isInMobileView)) && (
              <DashboardTraining
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.training}
                desiredStartDate={desiredStartDate}
                currentTraining={fullTraining}
                updatingWorkoutId={updatingWorkoutId}
                updating={updating}
                onCurrentDaysChangeRequested={onCurrentDaysChangeRequested}
                onWorkoutsMoved={onWorkoutsMoved}
                onError={onError}
                onRedoWeek={onRedoWeek}
                onAddEasyWeek={onAddEasyWeek}
                onRestartPlan={onRestartPlan}
                onOpenAdd={onOpenAdd}
                selectedPlan={selectedPlan}
                userEmail={cognito_user_email}
                subscriptionEnd={
                  current_term_end ? current_term_end : trial_end
                }
                status={status}
                hasSubscription={isSubscribed}
                loading={loading || loadingLatest || !alreadyLoadedOnce}
              />
            )}

            {tab === 0 &&
              !isInMobileView &&
              (!hasProfileCompleted || !hasCurrentTraining) &&
              !loading &&
              moment(createdAt).isBefore(moment().add(14, "days")) && (
                <CompleteProfile
                  hasCurrentTraining={hasCurrentTraining}
                  userType={userType}
                ></CompleteProfile>
              )}

            {tab === 1 && !isInMobileView && (
              <div className={classes.calendarContainer}>
                {((loadingLatest || typeof loadingLatest === "undefined") &&
                  !alreadyLoadedOnce) ||
                (!!latest && !showCompleteSubscriptionButton) ||
                selectedPlan === "free" ? (
                  <div>
                    <DashboardCalendar
                      trainingDays={
                        fullTraining && fullTraining.days
                          ? fullTraining.days
                          : []
                      }
                      races={allRaces}
                      onSelectDay={this.handleCalendarSelected}
                    ></DashboardCalendar>
                    {calendarSelectedWorkout ? (
                      <div className={classes.calendarWorkout}>
                        <DashboardTrainingWeekday
                          day={moment(
                            calendarSelectedWorkout[0].plannedDate,
                            dateFormat,
                          )}
                          trainingDay={calendarSelectedWorkout}
                          desiredStartDate={null}
                          updatingWorkoutId={null}
                          isInMobileView={isInMobileView}
                          isCalendar={true}
                          lastVisibleDate={lastVisibleDate}
                          onSendAnalytics={() => this.sendAnalytics()}
                          level={level}
                          chainedPlans={chainedPlans}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {calendarSelectedRace ? (
                      <div className={classes.calendarRace}>
                        <DashoardNextRaceDetails
                          onlyRace={calendarSelectedRace}
                        ></DashoardNextRaceDetails>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <DashboardTraining
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.training}
                    desiredStartDate={desiredStartDate}
                    currentTraining={fullTraining}
                    updatingWorkoutId={updatingWorkoutId}
                    updating={updating}
                    onCurrentDaysChangeRequested={onCurrentDaysChangeRequested}
                    onWorkoutsMoved={this.handleWorkoutsMoved}
                    onError={this.handleError}
                    selectedPlan={selectedPlan}
                    userEmail={cognito_user_email}
                    subscriptionEnd={
                      current_term_end ? current_term_end : trial_end
                    }
                    status={status}
                    onRedoWeek={onRedoWeek}
                    onAddEasyWeek={onAddEasyWeek}
                    onOpenAdd={onOpenAdd}
                    onRestartPlan={onRestartPlan}
                    hasSubscription={isSubscribed}
                    loading={loading || loadingLatest || !alreadyLoadedOnce}
                    onSendAnalytics={() => this.sendAnalytics()}
                  />
                )}
              </div>
            )}

            {tab !== 3 && !isInMobileView ? (
              <DashoardNextRaceDetails></DashoardNextRaceDetails>
            ) : (
              ""
            )}

            {(tab === 3 && !isInMobileView) || (tab === 2 && isInMobileView) ? (
              <>
                <DashboardDesktopCharts
                  currentTraining={fullTraining}
                  activities={activities}
                ></DashboardDesktopCharts>

                {!isInMobileView ? (
                  <DashboardWeeklyReportDiagram
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    currentTraining={fullTraining}
                    distance={distance}
                    elevation={elevation}
                    activities={activities}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </Grid>
    );
  }
}

DashboardTrainingColumn.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  planName: PropTypes.string,
};

DashboardTrainingColumn.defaultProps = {
  maxSize: 5120000,
};

export default connect((store) => {
  return {
    strava: store.strava,
    userPlans: store.userPlans,
    auth: store.auth,
    trailHead: store.trailHead,
    subscriptions: store.subscriptions,
    challenges: store.challenges,
    garmin: store.garmin,
    suunto: store.suunto,
    vertActivities: store.vertActivities,
    healthKit: store.healthKit,
    coros: store.coros,
  };
})(
  withTranslation(
    "dashboard",
    "messages",
    "trailhead",
    "challenges",
  )(
    withWidth()(
      withStyles(styles, { withTheme: true })(memo(DashboardTrainingColumn)),
    ),
  ),
);
