import { useEffect, useState } from "react";

const useCalculateWorkoutsDone = (plan = []) => {
  const [workoutsDone, setWorkoutsDone] = useState(0);

  const calculatePercent = (part, total) => {
    return Math.round((part / total) * 100);
  };

  const calculatedWorkoutsDone = (plan) => {
    if (!Array.isArray(plan)) return 0;
    const markAsDone = plan.filter((item) => {
      let itemCheck = item[0] ? item[0] : item;
      return itemCheck.isDone;
    });
    const result = calculatePercent(markAsDone.length, plan.length);
    return !isNaN(Math.floor(result)) ? Math.floor(result) : 0;
  };

  useEffect(() => {
    setWorkoutsDone(calculatedWorkoutsDone(plan));
  }, [plan]);

  return workoutsDone;
};

export default useCalculateWorkoutsDone;
