const WorkoutTargets = {
  "No Target": "No Target",
  "Heart Rate": "Heart Rate",
  "Heart Rate Zone": "Heart Rate Zone",
  Pace: "Pace",
  RPE: "RPE",
  "Uphill Speed": "Uphill Speed",
  "Downhill Speed": "Downhill Speed",
};

export default {
  Items: WorkoutTargets,
  itemsArray: Object.keys(WorkoutTargets).map((key) => {
    return {
      text: WorkoutTargets[key],
      value: key,
    };
  }),
  ItemsShortArray: Object.keys(WorkoutTargets).map((key) => {
    return {
      text: key,
      value: key,
    };
  }),
};
