import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        const { onRequestSort } = this.props;
        if (onRequestSort)
            onRequestSort(event, property);
    };

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, headers, updatable, selectable } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {selectable &&
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={numSelected === rowCount}
                                onChange={onSelectAllClick}
                            />
                        </TableCell>}
                    {headers.map((header, index) => {
                        const isActiveSort = orderBy === header.id || (header.orderByOverride && orderBy === header.orderByOverride)
                        return (
                            <TableCell
                                key={index}
                                align={header.numeric ? 'right' : 'left'}
                                padding={header.disablePadding ? 'dense' : 'default'}
                                sortDirection={isActiveSort ? order : false} >
                                <Tooltip
                                    title="Sort"
                                    placement={header.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300} >
                                    <TableSortLabel
                                        active={isActiveSort}
                                        direction={order}
                                        onClick={this.createSortHandler(header.id)} >
                                        {header.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        )
                    },
                        this,
                    )}
                    {updatable &&
                        <TableCell padding="checkbox">&nbsp;</TableCell>}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    headers: PropTypes.array.isRequired,
    updatable: PropTypes.bool,
    selectable: PropTypes.bool
}

export default EnhancedTableHead;