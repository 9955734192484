import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";
import SubscriptionOptionsInApp from "../constants/subscription-options-in-app";
import { useDispatch } from "react-redux";
import {
  setMobileProducts,
  addOwnedMobileProduct,
} from "../actions/subscriptions-action";
import { Capacitor } from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

const useInAppPurchasesService = () => {
  const dispatch = useDispatch();

  const initInAppPurchases = async (sub) => {
    const instanceID = await FirebaseAnalytics.getAppInstanceId();
    await Purchases.setFirebaseAppInstanceID(instanceID);
    await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
    console.log("Pre Purchases");
    Purchases.configure({
      apiKey:
        Capacitor.getPlatform() === "ios"
          ? process.env.REACT_APP_APPLE_RC_KEY
          : process.env.REACT_APP_GOOGLE_RC_KEY,
      appUserID: sub,
    });

    try {
      const offerings = await Purchases.getOfferings();

      if (
        offerings.current !== null &&
        offerings.current.availablePackages.length !== 0
      ) {
        const mobileProductsExplore = [
          offerings.current.availablePackages.find(
            (p) => p.identifier === SubscriptionOptionsInApp.EXPLORE_MONTHLY,
          ),
          offerings.current.availablePackages.find(
            (p) => p.identifier === SubscriptionOptionsInApp.EXPLORE_SIX_MONTHS,
          ),
          offerings.current.availablePackages.find(
            (p) => p.identifier === SubscriptionOptionsInApp.EXPLORE_YEARLY,
          ),
        ];
        const mobileProductsPremium = [
          offerings.current.availablePackages.find(
            (p) => p.identifier === SubscriptionOptionsInApp.PREMIUM_MONTHLY,
          ),
        ];
        dispatch(
          setMobileProducts({ mobileProductsExplore, mobileProductsPremium }),
        );
      }
    } catch (e) {
      console.error("Error loading in-app offerings", e);
    }

    try {
      const { customerInfo } = await Purchases.getCustomerInfo();
      if (
        customerInfo &&
        customerInfo.entitlements &&
        customerInfo.entitlements.active
      ) {
        const activeEntitlements = Object.entries(
          customerInfo.entitlements.active,
        );
        if (activeEntitlements.length) {
          console.log("customerInfo login", customerInfo);
          activeEntitlements.forEach((entitlement) => {
            dispatch(addOwnedMobileProduct(entitlement));
          });
        }
      } else {
        console.log("No active entitlements found");
      }
    } catch (e) {
      console.error("Error fetching in-app customer info", e);
    }
  };

  const purchaseAppSubscription = async (
    product,
    finalizeFunction = () => console.log("finalizeFunction"),
  ) => {
    console.log("Purchase", product);

    console.log("customerInfo purchase start");
    const { customerInfo, error } = await Purchases.purchasePackage({
      aPackage: product,
    });

    if (
      customerInfo &&
      customerInfo.entitlements &&
      customerInfo.entitlements.active
    ) {
      const activeEntitlements = Object.entries(
        customerInfo.entitlements.active,
      );
      if (activeEntitlements.length) {
        console.log("customerInfo purchase customerInfo", customerInfo);
        activeEntitlements.forEach((entitlement) => {
          console.log("purchase entitlement", entitlement);
          dispatch(addOwnedMobileProduct(entitlement));
        });
      }
      finalizeFunction();
    } else {
      console.log("No active entitlements found during purchase");
    }
    if (error) {
      console.error("Error during purchase", error);
    }
  };

  const syncTransactions = async () => {
    const { customerInfo, error } = await Purchases.getCustomerInfo();
    if (
      !(
        customerInfo &&
        customerInfo.entitlements &&
        customerInfo.entitlements.active &&
        Object.entries(customerInfo.entitlements.active).length
      )
    ) {
      Purchases.syncPurchases();
    }
    if (error) {
      console.error("Error fetching in-app customer info", error);
    }
  };

  const refreshFull = async () => {
    const { customerInfo, error } = await Purchases.restoreTransactions();
    if (
      customerInfo &&
      customerInfo.entitlements &&
      customerInfo.entitlements.active
    ) {
      const activeEntitlements = Object.entries(
        customerInfo.entitlements.active,
      );
      if (activeEntitlements.length) {
        console.log("customerInfo restore", customerInfo);
        activeEntitlements.forEach((entitlement) => {
          dispatch(addOwnedMobileProduct(entitlement));
        });
      }
    } else {
      console.log("No active entitlements found during restore");
    }
    if (error) {
      console.error("Error during restore", error);
    }
  };

  return {
    initInAppPurchases,
    purchaseAppSubscription,
    syncTransactions,
    refreshFull,
  };
};

export default useInAppPurchasesService;
