import React, { createContext, useState, useContext } from "react";
import PaywallModal from "../components/common/v2/PaywallModal";
import useUserInfo from "../hooks/useUserInfo";
import ChatView from "../app/mobile/views/ChatView";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [workoutToShare, setWorkoutToShare] = useState(null);
  const [isOpenPaywall, setIsOpenPaywall] = useState(false);
  const { isSubscribed } = useUserInfo();

  const openChat = () => {
    if (isSubscribed) {
      setIsOpenPaywall(false);
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setIsOpenPaywall(true);
    }
  };

  const closeChat = () => {
    setIsOpen(false);
    setWorkoutToShare(null);
  };

  return (
    <ChatContext.Provider
      value={{ isOpen, openChat, closeChat, workoutToShare, setWorkoutToShare }}
    >
      {children}
      {isOpen && <ChatView />}
      {isOpenPaywall && (
        <PaywallModal
          isOpen={isOpenPaywall}
          onClose={() => {
            setIsOpenPaywall(false);
          }}
        />
      )}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  return useContext(ChatContext);
};
