import React, { useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { Auth, I18n } from "aws-amplify";
import { theme } from "../../../theme";
import { useTranslation } from "react-i18next";
import useUserInfo from "../../../hooks/useUserInfo";
import bugsnagClient from "../../../lib/bugsnag-client";
import {
  STEPS_RECOVERY,
  useStepsRecovery,
} from "../../../app/providers/StepsRecoveryProvider";

const useStyles = makeStyles({
  iconarrowBack: {
    position: "relative",
    width: "24px",
    height: "24px",
    overflow: "hidden",
    flexShrink: 0,
    objectFit: "cover",
  },
  vertrunLogoIcon: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    left: "0%",
    maxWidth: "100%",
    overflow: "hidden",
    maxHeight: "100%",
    objectFit: "cover",
  },
  iconvertrunLogo: {
    position: "relative",
    width: "151px",
    height: "70px",
  },
  logoback: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0px 16px",
  },
  passwordRecoveryChild: {
    position: "relative",
    letterSpacing: "1px",
    lineHeight: "125%",
    textTransform: "uppercase",
    fontWeight: "500",
  },
  enterYourEmail: {
    alignSelf: "stretch",
    position: "relative",
    fontSize: theme.typography.h5.fontSize,
    lineHeight: "150%",
    fontFamily: theme.typography.fontFamily,
  },
  passwordRecovery: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "12px",
  },
  iconmail: {
    position: "relative",
    width: "18px",
    height: "18px",
    overflow: "hidden",
    flexShrink: 0,
    objectFit: "cover",
  },
  input: {
    width: "100%",
    paddingLeft: "10px",
    height: "100%",
    border: "none",
    outline: "none",
  },
  iconplaceholder: {
    width: "328px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
  },
  inputstext: {
    alignSelf: "stretch",
    borderRadius: "4px",
    border: "2px solid #eaeaea",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "12px 16px",
  },
  email: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  emailInput: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontSize: theme.typography.h5.fontSize,
    color: "#6d6f71",
    fontFamily: theme.typography.fontFamily,
  },
  login: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "16px",
  },
  text: {
    position: "relative",
    lineHeight: "125%",
  },
  primaryButton: {
    alignSelf: "stretch",
    borderRadius: "4px",
    backgroundColor: theme.palette.secondary.darkBlue,
    boxShadow: "0px 4px 30px rgba(18, 21, 27, 0.05)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 0px",
  },
  backToLog: {
    alignSelf: "stretch",
    position: "relative",
    lineHeight: "125%",
    color: "#4780ab",
  },
  buttons: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "16px",
    textAlign: "center",
    color: "#feffff",
    fontFamily: theme.typography.fontFamily,
  },
  content: {
    alignSelf: "stretch",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "0px 16px",
    boxSizing: "border-box",
  },
  page: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "24px 0px",
    boxSizing: "border-box",
    gap: "56px",
    textAlign: "left",
    fontSize: "18px",
    color: "#12151b",
    fontFamily: "Oswald",
  },
});

const Email = () => {
  const { t } = useTranslation("settings");
  const { attributes } = useUserInfo();
  const { onSetStep, onSetErrorMessage } = useStepsRecovery();
  const styles = useStyles();
  const [inputEmail, setInputEmail] = useState(
    attributes.email ? attributes.email : "",
  );
  const [loading, setLoading] = useState(false);

  const sendCode = (event) => {
    setLoading(true);
    event.preventDefault();

    localStorage.setItem("email", inputEmail);
    Auth.forgotPassword(inputEmail)
      .then((data) => {
        onSetStep(STEPS_RECOVERY.CHANGE_PASSWORD);
      })
      .catch((err) => {
        onSetErrorMessage(err.message);
        bugsnagClient.notify(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.page}>
      <div className={styles.logoback}>
        <div className={styles.iconvertrunLogo}>
          <img
            className={styles.vertrunLogoIcon}
            alt=""
            src={process.env.PUBLIC_URL + "/vertrun-logo-wide.png"}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.login}>
          <div className={styles.passwordRecovery}>
            <div className={styles.passwordRecoveryChild}>
              {t("settings.recoveryPassword")}
            </div>
            <div className={styles.enterYourEmail}>
              {t("settings.enterYourEmail")}
            </div>
          </div>
          <div className={styles.emailInput}>
            <div className={styles.email}>
              <div
                className={styles.inputstext}
                style={{
                  backgroundColor: attributes.email ? "#eaeaea" : "transparent",
                }}
              >
                <div className={styles.iconplaceholder}>
                  <div className={styles.iconmail}>
                    <MailOutlineIcon fontSize="small" />
                  </div>
                  <input
                    disabled={attributes.email}
                    type="text"
                    className={styles.input}
                    value={inputEmail}
                    onChange={(event) => setInputEmail(event.target.value)}
                    placeholder="Email"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            disabled={inputEmail === "" || loading}
            variant="contained"
            color={inputEmail === "" || loading ? "default" : "secondary"}
            className={styles.primaryButton}
            onClick={sendCode}
          >
            {loading ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              <b className={styles.text}>{t("settings.buttons.continue")}</b>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Email;
