import React from "react";

const getVideoIframe = (url, fullW = false, relativeToParent = false) => {
  const videoId = getYoutubeId(url);
  const width = fullW
    ? window.innerWidth
    : Math.min(window.innerWidth, 620) - 100;
  const iframeMarkup = (
    <iframe
      width={!relativeToParent ? width : "100%"}
      height={!relativeToParent ? width * 0.56 : "100%"}
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allowFullScreen
    ></iframe>
  );
  return iframeMarkup;
};

const getYoutubeId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

export { getYoutubeId, getVideoIframe };
