import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { StatCard } from "./stat-card";
import { palette } from "../../../../theme/palette";
import moment from "moment";
import {
  calculateTrainingLoad,
  getEnergyLevelRate,
} from "../../../../lib/rates-helper";
import { useSelector } from "react-redux";
import { getFixCustomLevel } from "../../../../lib/plan-helper";
import EstimatedTimes from "../../../../constants/estimated-times";
import useCalculateWorkoutsDone from "../../../../hooks/useCalculateWorkoutsDone";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { useTranslation } from "react-i18next";
import { Fade, Paper, Popper } from "@mui/material";
import { round } from "../../../../lib/units-helper";
import CustomErrorBoundary from "../../../common/error/CustomErrorBoundary";
dayjs.extend(isoWeek);

const currentRange = {
  "0": "week",
  "1": "month",
  "2": "plan",
};

export const StatsSection = () => {
  const { t } = useTranslation("profile");
  const classes = useStyles();
  const [planTime, setPlanTime] = useState([]);
  const [plannedTime, setPlannedTime] = useState([]);

  const [anchorElTrainingLoad, setAnchorElTrainingLoad] = useState(null);
  const [openTrainingLoad, setOpenTrainingLoad] = useState(false);
  const [placementTrainingLoad, setPlacementTrainingLoad] = useState();

  const [anchorElWorkoutPopper, setAnchorElWorkoutPopper] = useState(null);
  const [openWorkoutPopper, setOpenWorkoutPopper] = useState(false);
  const [placementWorkoutPopper, setPlacementWorkoutPopper] = useState();

  const [anchorElEnergyLevelPopper, setAnchorElEnergyLevelPopper] = useState(
    null,
  );
  const [openEnergyLevelPopper, setOpenEnergyLevelPopper] = useState(false);
  const [
    placementEnergyLevelPopper,
    setPlacementEnergyLevelPopper,
  ] = useState();

  const handlePopperEnergyLevel = (newPlacement) => (event) => {
    setAnchorElEnergyLevelPopper(event.currentTarget);
    setOpenEnergyLevelPopper(
      (prev) => placementEnergyLevelPopper !== newPlacement || !prev,
    );
    setPlacementEnergyLevelPopper(newPlacement);
  };
  const handlePopperWorkout = (newPlacement) => (event) => {
    setAnchorElWorkoutPopper(event.currentTarget);
    setOpenWorkoutPopper(
      (prev) => placementWorkoutPopper !== newPlacement || !prev,
    );
    setPlacementWorkoutPopper(newPlacement);
  };
  const handlePopperTrainingLoad = (newPlacement) => (event) => {
    setAnchorElTrainingLoad(event.currentTarget);
    setOpenTrainingLoad(
      (prev) => placementTrainingLoad !== newPlacement || !prev,
    );
    setPlacementTrainingLoad(newPlacement);
  };

  const { currentPlan, plan } = useSelector((state) => state.userPlans);
  const { currentFilter, currentDate, toDate } = useSelector(
    (state) => state.profile,
  );
  const { currentTraining } = currentPlan || {};
  const { chainedPlans } = currentTraining || {};
  const energyLevelRate = getEnergyLevelRate(plan, moment(currentDate));
  const { activities: activitiesStrava } = useSelector((state) => state.strava);
  const { activities: activitiesSuunto } = useSelector((state) => state.suunto);
  const { activities: activitiesGarmin } = useSelector((state) => state.garmin);
  const { activities: activitiesVert } = useSelector(
    (state) => state.vertActivities,
  );
  const { activities: healthActivities } = useSelector(
    (state) => state.healthKit,
  );

  const fullActivities = [
    ...(activitiesStrava || []),
    ...(activitiesGarmin || []),
    ...(activitiesSuunto || []),
    ...(activitiesVert || []),
    ...(healthActivities || []),
  ];

  /** Using Map to eliminate duplicates via the activity start_date field*/
  const groupedActivities = new Map();
  for (const activity of fullActivities) {
    const { start_date } = activity;
    const startDateTime = new Date(start_date).getTime();
    if (!groupedActivities.has(startDateTime))
      groupedActivities.set(startDateTime, activity);
  }
  const activities = Array.from(groupedActivities.values());

  const runActivities = activities.length
    ? activities.filter(
        (a) =>
          (a.type && a.type.toLowerCase().includes("run")) ||
          (a.activityType && a.activityType.includes("RUN")),
      )
    : [];

  const loadRate = calculateTrainingLoad(
    plan,
    runActivities,
    currentDate,
    toDate,
    chainedPlans,
    currentTraining,
  );

  useEffect(() => {
    if ((activities && activities.length) || (plan && plan.length))
      calculateTime(plan);
  }, [
    activitiesStrava,
    activitiesGarmin,
    activitiesSuunto,
    activitiesVert,
    healthActivities,
    currentFilter,
    currentDate,
    toDate,
    plan,
  ]);

  const calculateTime = (plan) => {
    const time = [];
    if (runActivities) {
      const foundActivities = runActivities.filter(
        (activity) =>
          activity &&
          moment(activity.start_date).isBetween(
            currentDate,
            toDate,
            "day",
            "[]",
          ),
      );
      if (foundActivities && foundActivities.length) {
        time.push(
          foundActivities
            .map((item) => {
              const movingTime = item.moving_time || item.elapsed_time || 0;
              return Math.round(movingTime / 60) || 0;
            })
            .reduce((a, sum) => a + sum),
        );
      } else {
        time.push(0);
      }
    }

    let plannedTime = [];
    try {
      if (plan && plan.length) {
        const accumulated = [...plan].map((dayOf) => {
          let day = dayOf[0] ? dayOf[0] : dayOf;
          return Array.isArray(day.estimatedTime)
            ? +day.estimatedTime[
                getFixCustomLevel(
                  day.plannedDate,
                  chainedPlans,
                  currentTraining,
                )
              ]
            : +day.estimatedTime;
        });

        plannedTime = accumulated;
      }
      setPlanTime(time);
      setPlannedTime(plannedTime);
    } catch (error) {
      setPlanTime(time);
      setPlannedTime(plannedTime);
    }
  };

  const activitiesTime =
    planTime.length > 0 ? planTime.reduce((a, sum) => a + sum) : 0;
  const allPlanTime =
    plannedTime.length > 0 ? plannedTime.reduce((a, sum) => a + sum) : 0;

  const workoutsDone = useCalculateWorkoutsDone(plan);

  const manageText = (trainingLoad) => {
    if (trainingLoad < 50) return "profile.stats.totalTraining.minorfifty";
    if (trainingLoad > 51 && trainingLoad < 75)
      return "profile.stats.totalTraining.betweenFiftyOneAndSeventyFive";
    if (trainingLoad > 76 && trainingLoad < 130)
      return "profile.stats.totalTraining.betweenSeventySixAndHundredThirty";
    if (trainingLoad > 131)
      return "profile.stats.totalTraining.higherHundredThirtyOne";
  };

  const manageWorkoutDoneText = (done) => {
    if (done < 30) return "profile.stats.workoutDone.minorThirty";
    if (done > 31 && done < 50)
      return "profile.stats.workoutDone.betweenThirtyOneAndFifty";
    if (done > 51 && done < 75)
      return "profile.stats.workoutDone.betweenFiftyOneAndSeventyFive";
    if (done > 75 && done <= 100)
      return "profile.stats.workoutDone.betweenSeventyFiveAndHundred";
  };

  const manageEnergyLevelText = (energyLevel) => {
    if (energyLevel < 4) return "profile.stats.energyLevel.minorFour";
    if (energyLevel < 4 && energyLevel <= 5)
      return "profile.stats.energyLevel.betweenFourAndFive";
    if (energyLevel > 5 && energyLevel <= 7)
      return "profile.stats.energyLevel.betweenFiveAndSeven";
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setOpenTrainingLoad(false);
        setOpenWorkoutPopper(false);
        setOpenEnergyLevelPopper(false);
      }
    };

    const handleClickOutside = () => {
      setOpenTrainingLoad(false);
      setOpenWorkoutPopper(false);
      setOpenEnergyLevelPopper(false);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <CustomErrorBoundary>
      <Box
        sx={{
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Box style={{ margin: 0 }}>
          <Grid
            container
            spacing={1}
            alignItems="normal"
            justifyContent="center"
          >
            <Grid
              item
              className={classes.displayEnd}
              xs={6}
              sm={6}
              sx={6}
              md={6}
              onClick={handlePopperTrainingLoad("top")}
            >
              <Popper
                sx={{ zIndex: 1200, maxWidth: 300 }}
                open={openTrainingLoad}
                anchorEl={anchorElTrainingLoad}
                placement={placementTrainingLoad}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper className={classes.paperPopper}>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: t(manageText(loadRate)),
                        }}
                      />
                    </Paper>
                  </Fade>
                )}
              </Popper>
              <StatCard
                title={t("profile.stats.trainingLoad")}
                stat={`${Math.floor(loadRate)}/100%`}
                color={palette.primary}
                icon="run"
              />
            </Grid>

            <Grid
              item
              className={classes.displayStart}
              xs={6}
              sm={6}
              sx={6}
              md={6}
              onClick={handlePopperWorkout("top")}
            >
              <Popper
                sx={{ zIndex: 1200, maxWidth: 300 }}
                open={openWorkoutPopper}
                anchorEl={anchorElWorkoutPopper}
                placement={placementWorkoutPopper}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper className={classes.paperPopper}>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: t(manageWorkoutDoneText(workoutsDone)),
                        }}
                      />
                    </Paper>
                  </Fade>
                )}
              </Popper>
              <StatCard
                title={t("profile.stats.workoutDone")}
                stat={`${workoutsDone}/100%`}
                color={palette.darkBlue}
                icon="check"
              />
            </Grid>
          </Grid>

          <Box mt="5px" width="100%">
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="normal"
            >
              <Grid
                item
                className={classes.displayEnd}
                xs={6}
                sm={6}
                sx={6}
                md={6}
                onClick={handlePopperEnergyLevel("bottom")}
              >
                <Popper
                  sx={{ zIndex: 1200, maxWidth: 300 }}
                  open={openEnergyLevelPopper}
                  anchorEl={anchorElEnergyLevelPopper}
                  placement={placementEnergyLevelPopper}
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper className={classes.paperPopper}>
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: t(
                              manageEnergyLevelText(
                                energyLevelRate.full[
                                  currentRange[currentFilter]
                                ].label,
                              ),
                            ),
                          }}
                        />
                      </Paper>
                    </Fade>
                  )}
                </Popper>
                <StatCard
                  title={t("profile.stats.energyLevel")}
                  stat={`${round(
                    energyLevelRate.full[currentRange[currentFilter]].label,
                  )}/10`}
                  color={palette.lightBlue}
                  icon="battery"
                />
              </Grid>

              <Grid
                item
                className={classes.displayStart}
                xs={6}
                sm={6}
                sx={6}
                md={6}
              >
                <StatCard
                  title={t("profile.stats.totalTraining")}
                  stat={`${EstimatedTimes.convertMinutesToLabel(
                    activitiesTime,
                    true,
                  ) || "0min"}/${EstimatedTimes.convertMinutesToLabel(
                    allPlanTime,
                    true,
                  ) || "0min"}`}
                  color={palette.darkGray}
                  icon="timer"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </CustomErrorBoundary>
  );
};

const useStyles = makeStyles({
  displayStart: {
    display: "flex",
    justifyContent: "start",
  },
  displayEnd: {
    display: "flex",
    justifyContent: "end",
  },
  paperPopper: {
    boxShadow: "0px 1px 15px 0px #12151B0D !important",
    display: "flex",
    padding: "8px 12px 8px 12px",
    /* gap: 12px; */
    borderRadius: "4px !important",
    backgroundColor: `${palette.lightBlueHover} !important`,
    opacity: 1,
    maxWidth: 277,
  },
});
