import React, { memo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import { NoSsr } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";

import NoOptionsMessage from "./auto-complete-select/no-options-message";
import Control from "./auto-complete-select/control";
import Option from "./auto-complete-select/option";
import Placeholder from "./auto-complete-select/placeholder";
import SingleValue from "./auto-complete-select/single-value";
import ValueContainer from "./auto-complete-select/value-container";
import Menu from "./auto-complete-select/menu";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    padding: 0,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
});

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const AutoCompleteSelect = ({
  classes,
  suggestions,
  placeholder,
  value,
  isClearable,
  disable,
  onKeyDown,
  onInputChange,
  loading,
  onChange,
}) => {
  const handleChange = (event) => {
    if (event !== null) {
      const { value, label } = event;
      if (onChange) onChange(value, label);
    } else {
      if (onChange) onChange(null, "");
    }
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          options={suggestions}
          components={components}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable
          isDisabled={disable}
          onInputChange={onInputChange}
          onKeyDown={onKeyDown}
        />
        {loading && <LinearProgress variant="indeterminate" color="primary" />}
      </NoSsr>
    </div>
  );
};

AutoCompleteSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  isClearable: PropTypes.bool,
  disable: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onInputChange: PropTypes.func,
  loading: PropTypes.bool,
};

AutoCompleteSelect.defaultProps = {
  placeholder: "Select...",
  isClearable: true,
};

export default withStyles(styles, { withTheme: true })(
  memo(AutoCompleteSelect),
);
