import ActionTypes from "../constants/action-types";
import { logEvent, setEventsMetadata } from "../lib/events-helper";
import GarminService from "../services/garmin-service";
import StravaService from "../services/strava-service";
import UserPlansService from "../services/user-plans-service";

import { setSuccessMessage, updatePlan } from "./user-plans-action";

const initAuthorizationGarmin = (cognito_user_sub, garminUrl) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GARMIN_INIT });
    try {
      const result = await GarminService.initAuthorization();
      const queryParams = new URLSearchParams(result);
      if (queryParams.get("oauth_token_secret")) {
        const resultUpdate = await UserPlansService.updateCurrentUserInfo({
          cognito_user_sub: cognito_user_sub,
          garmin_oauth_token_secret: queryParams.get("oauth_token_secret"),
        });

        if (!resultUpdate.garmin_oauth_token_secret)
          throw new Error("Garmin oauth token not updated");
        window.location.href = garminUrl + result;
      } else {
        throw new Error("Garmin oauth token not found");
      }
    } catch (exp) {
      dispatch({
        type: ActionTypes.GARMIN_INIT_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const secondStepAuthorizationGarmin = (
  oauth_token,
  oauth_verifier,
  cognito_user_sub,
  oauth_token_secret,
  successMessage,
) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GARMIN_INIT_PENDING });
    try {
      const result = await GarminService.secondStepAuthorization(
        oauth_token,
        oauth_verifier,
        oauth_token_secret,
      );
      dispatch({ type: ActionTypes.GARMIN_INIT_FULFILLED, payload: result });
      setEventsMetadata({ customer_id: cognito_user_sub });
      logEvent("connect_GPS_provider");

      const queryParams = new URLSearchParams(result);
      if (
        queryParams.get("oauth_token") &&
        queryParams.get("oauth_token_secret")
      ) {
        dispatch(setSuccessMessage(successMessage));

        dispatch(
          updatePlan(
            {
              cognito_user_sub: cognito_user_sub,
              garmin_oauth_token: queryParams.get("oauth_token"),
              garmin_oauth_token_secret: queryParams.get("oauth_token_secret"),
            },
            false,
            false,
          ),
        );

        dispatch(
          backfillGarmin(
            queryParams.get("oauth_token"),
            queryParams.get("oauth_token_secret"),
          ),
        );
      }
    } catch (exp) {
      dispatch({
        type: ActionTypes.GARMIN_INIT_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const deleteAuthorizationGarmin = (oauth_token, oauth_token_secret) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GARMIN_DELETE });
    try {
      await GarminService.deleteAuthorization(oauth_token, oauth_token_secret);
    } catch (exp) {
      dispatch({
        type: ActionTypes.GARMIN_INIT_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const backfillGarmin = (oauth_token, oauth_token_secret) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GARMIN_BACKFILL });
    try {
      await GarminService.backfill(oauth_token, oauth_token_secret);
    } catch (exp) {
      dispatch({
        type: ActionTypes.GARMIN_INIT_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getActivityGarmin = (params) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GARMIN_GET_ACTIVITY_PENDING });
    try {
      const result = await GarminService.getActivity(params);
      dispatch({
        type: ActionTypes.GARMIN_GET_ACTIVITY_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.GARMIN_GET_ACTIVITY_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getActivitiesGarmin = (param, reload = false) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.GARMIN_LOAD_ACTIVITIES_PENDING,
      payload: reload,
    });
    try {
      const result = await GarminService.getActivities(param);
      dispatch({
        type: ActionTypes.GARMIN_LOAD_ACTIVITIES_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.GARMIN_LOAD_ACTIVITIES_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const fetchAndFilterStravaActivities = async (result, param) => {
  param.start_date = param.strava_start_date;
  param.end_date = param.strava_end_date;
  let stravaResult = await StravaService.getActivities(param);
  stravaResult = modifyStartDateAttribute(stravaResult);
  const differentStartDateEntries = filterDifferentStartDateEntries(
    result,
    stravaResult,
  );
  return differentStartDateEntries;
};

const modifyStartDateAttribute = (entries) => {
  return entries.map((entry) => {
    const modifiedStartDate = entry.start_date.replace("Z", ".000Z");
    return { ...entry, start_date: modifiedStartDate };
  });
};

const filterDifferentStartDateEntries = (result, stravaResult) => {
  const allStartDates = result.map((entry) => entry.start_date);
  return stravaResult.filter(
    (entry) => !allStartDates.includes(entry.start_date),
  );
};

const clearActivityGarmin = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GARMIN_CLEAR_ACTIVITY });
  };
};

const exportWorkoutGarmin = (params) => {
  return async (dispatch) => {
    try {
      const result = await GarminService.exportWorkout(params);
      return result;
    } catch (exp) {
      dispatch({
        type: ActionTypes.EXPORT_WORKOUT_UNFULFILLED,
      });
    }
  };
};

const clearGarminActivities = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GARMIN_CLEAR_ACTIVITIES });
  };
};

export {
  initAuthorizationGarmin,
  secondStepAuthorizationGarmin,
  deleteAuthorizationGarmin,
  backfillGarmin,
  getActivitiesGarmin,
  getActivityGarmin,
  clearActivityGarmin,
  exportWorkoutGarmin,
  clearGarminActivities,
};
