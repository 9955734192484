import ActionTypes from "../constants/action-types";
import SubscriptionsService from "../services/subscriptions-service";

const getLatestSubscription = () => {
  return async (dispatch) => {
    await dispatch({
      type: ActionTypes.SUBSCRIPTIONS_LATEST_PENDING,
      payload: true,
    });

    const result = await SubscriptionsService.getLatestSubscription();
    const { error } = result || {};

    if (error) {
      await dispatch({
        type: ActionTypes.SUBSCRIPTIONS_LATEST_REJECTED,
        payload: { message: "Something went wrong loading the subscription" },
      });
      return;
    }

    await dispatch({
      type: ActionTypes.SUBSCRIPTIONS_LATEST,
      payload: SubscriptionsService.getLatestSubscription(),
    });
  };
};

const setMobileProducts = (products) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.MOBILE_PRODUCTS,
      payload: products,
    });
  };
};

const addOwnedMobileProduct = (product) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.OWNED_MOBILE_PRODUCT,
      payload: product,
    });
  };
};

const setErrorMessage = (err) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SUBSCRIPTIONS_SET_ERROR_MESSAGE,
      payload: err,
    });
  };
};

const clearErrorMessage = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SUBSCRIPTIONS_CLEAR_ERROR_MESSAGE });
  };
};

const setSuccessMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SUBSCRIPTIONS_SET_SUCCESS_MESSAGE,
      payload: message,
    });
  };
};

const clearSuccessMessage = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SUBSCRIPTIONS_CLEAR_SUCCESS_MESSAGE });
  };
};

export {
  getLatestSubscription,
  setErrorMessage,
  clearErrorMessage,
  setSuccessMessage,
  clearSuccessMessage,
  setMobileProducts,
  addOwnedMobileProduct,
};
