import { API } from "aws-amplify";
import bugsnagClient from "../lib/bugsnag-client";

class ReferralsService {
  static API_NAME = "apireferrals";
  static GET_CODE_PATH = "/referrals/code";
  static USE_CODE_PATH = "/referrals/codeuse";

  static async getReferralCode() {
    try {
      const response = await API.get(this.API_NAME, this.GET_CODE_PATH);
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async useRaffleCode(cognito_user_sub, code) {
    try {
      const myInit = {
        body: { code },
      };
      const response = await API.post(
        this.API_NAME,
        this.USE_CODE_PATH.replace(":cognito_user_sub", cognito_user_sub),
        myInit,
      );
      const { success, error } = response;
      if (success) {
        return true;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }
}

export default ReferralsService;
