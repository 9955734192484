import React, { useEffect, useState } from "react";
import YourCoaches from "./our-coaches/components/our-coaches-component";
import NavbarSettings from "./common/navbar-settings";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { faker } from "@faker-js/faker";

const Coaches = () => {
  const { t } = useTranslation();
  const { coachesList } = useSelector((state) => state.athletes);
  const [coaches, setCoaches] = useState([]);

  const generateFakeCoach = () => {
    const sex = faker.person.sexType();
    const firstName = faker.person.firstName(sex);
    const lastName = faker.person.lastName();
    const email = faker.helpers.unique(faker.internet.email, [
      firstName,
      lastName,
    ]);

    const fakeCoach = {
      name: `${firstName} ${lastName}`,
      email,
      description: faker.lorem.sentence(3),
      username_ig: faker.internet.userName(),
      link_ig: `https://www.instagram.com/${faker.internet.userName()}/`,
      username_strava: faker.internet.userName(),
      link_strava: `https://www.strava.com/athletes/${faker.number.int()}`,
      image: faker.image.avatar(),
      videoUrl: faker.internet.url(),
      information: [
        {
          title: faker.lorem.sentence(3),
          description: faker.lorem.paragraphs(),
        },
        {
          title: faker.lorem.sentence(4),
          description: faker.lorem.paragraphs(),
        },
        {
          title: faker.lorem.sentence(3),
          description: faker.lorem.paragraphs(),
        },
      ],
    };

    return fakeCoach;
  };

  const coachesListMockup = Array.from({ length: 10 }, generateFakeCoach);

  useEffect(() => {
    if (coaches) setCoaches(coachesListMockup);
  }, [coachesList]);

  return (
    <>
      <NavbarSettings
        component={<YourCoaches data={coaches} />}
        text={t("Our Coaches")}
      />
    </>
  );
};
export default Coaches;
