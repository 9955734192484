const ExternalLinksEs = {
  Blog: "https://vert.run/vert-blog/",
  AboutVert: "https://vert.run/nosotros/",
  Plans: "https://vert.run/planes/",
  PrivacyPolicy: "https://vert.run/politica-de-privacidad",
  CoreRoutine: "https://vert.run/core-para-trail-running/",
  StrengthRoutine:
    "https://vert.run/entrenamiento-de-fuerza-para-trail-running/",
  JumpsRoutine: "https://vert.run/saltos-para-trail-running/",
  ConditioningTraining:
    "https://vert.run/preparacion-fisica-para-trail-running-y-ultra-maratones/",
  Coaches: "https://vert.run/entrenadores/",
};

export default ExternalLinksEs;
