import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  withMobileDialog,
} from "@material-ui/core";
import PropTypes from "prop-types";
import TrainingWeekdayDetailsChat from "../common/training-weekday-chat";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  container: {
    width: 500,
    height: 500,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "auto",
    },
  },
});

class WorkoutCommentEditor extends Component {
  state = {
    addedComments: [],
  };

  onSaveComment = (comment) => {
    const { onSaveComment } = this.props;

    if (onSaveComment) {
      onSaveComment(comment);

      this.setState({
        addedComments: [...this.state.addedComments, comment],
      });
    }
  };

  render() {
    const { fullScreen, onClose, workout, isOpen, classes } = this.props;
    const { coachComment, comment, coachFeedback, comments = [] } = workout;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={onClose}
        aria-labelledby="workout-commend-editor"
      >
        <DialogContent className={classes.container}>
          <TrainingWeekdayDetailsChat
            comments={comments}
            comment={comment}
            coachComment={coachComment}
            coachFeedback={coachFeedback}
            handleSaveComment={this.onSaveComment}
            type={0}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

WorkoutCommentEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveComment: PropTypes.func.isRequired,
  workout: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
};

WorkoutCommentEditor.defaultProps = {
  workout: {},
};

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(WorkoutCommentEditor),
);
