import React, { useState } from "react";
import {
  Box,
  Link,
  Slide,
  Dialog,
  Typography,
  Button,
} from "@material-ui/core";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import history from "../../../lib/history-helper";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { AppleIcon, EmailIcon, GoogleIcon } from "../../../components/icons";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useAuthContext } from "../../../context/AuthProvider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const OnboardingSignUp = () => {
  const [openDialog, setOpenDialog] = useState(true);
  const { socialSignIn } = useAuthContext();
  const { t } = useTranslation("onboarding");
  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleTransitionEnd = () => {
    history.goBack();
  };

  const handleButtonClick = (method) => {
    switch (method) {
      case "email":
        history.push(`/auth/sign-up/method/${method}`);
        break;
      case "google":
        socialSignIn(CognitoHostedUIIdentityProvider.Google);
        break;
      case "facebook":
        socialSignIn(CognitoHostedUIIdentityProvider.Facebook);
        break;
      case "apple":
        socialSignIn(CognitoHostedUIIdentityProvider.Apple);
        break;
      default:
        break;
    }
  };

  const handleLogIn = () => {
    history.push("/auth/log-in");
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionProps={{ onExited: handleTransitionEnd }}
      TransitionComponent={Transition}
      display={"flex"}
      flexDirection="column"
    >
      <ToolbarReturnBack
        withLogo={true}
        logoWidth={107}
        style={{
          backgroundColor: "#FAFAFA",
          alignItems: "flex-start",
          padding: "10px",
        }}
        goBack={handleCloseDialog}
      />

      <Box className={classes.parentBox}>
        <Box className={classes.contentContainer}>
          <Box className={classes.titleBox}>
            <Typography className={classes.title}>
              {t("onboarding.signup.title")}
            </Typography>
          </Box>
          <Button
            className={classes.emailButton}
            onClick={() => handleButtonClick("email")}
            size="large"
            variant="contained"
            startIcon={<EmailIcon color="#0B2B5C" />}
          >
            <Typography className={classes.emailButtonText}>
              {t("onboarding.signup.continueWith")}{" "}
              <span style={{ fontWeight: 700 }}>
                {t("onboarding.signup.email")}
              </span>
            </Typography>
          </Button>
          <Button
            className={classes.googleButton}
            onClick={() => handleButtonClick("google")}
            size="large"
            variant="contained"
            startIcon={<GoogleIcon />}
          >
            <Typography className={classes.lightButtonText}>
              {t("onboarding.signup.continueWith")}{" "}
              <span style={{ fontWeight: 700 }}>
                {t("onboarding.signup.google")}
              </span>
            </Typography>
          </Button>
          <Button
            className={classes.appleButton}
            onClick={() => handleButtonClick("apple")}
            size="large"
            variant="contained"
            startIcon={<AppleIcon />}
          >
            <Typography className={classes.darkButtonText}>
              {t("onboarding.signup.continueWith")}{" "}
              <span style={{ fontWeight: 700 }}>
                {t("onboarding.signup.apple")}
              </span>
            </Typography>
          </Button>
        </Box>
        <Typography className={classes.signUpText}>
          {t("onboarding.signup.haveAccount")}{" "}
          <Link
            onClick={handleLogIn}
            underline="none"
            className={classes.loginButton}
          >
            {t("onboarding.signup.logIn")}
          </Link>
        </Typography>
      </Box>
    </Dialog>
  );
};

export default OnboardingSignUp;

const useStyles = makeStyles({
  parentBox: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    padding: "16px",
    gap: "12px",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  title: {
    fontFamily: "Oswald",
    fontWeight: 700,
    fontSize: "22px",
    color: "#12151B",
    textTransform: "uppercase",
    lineHeight: "27.5px",
  },

  subtitle: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#12151B",
    lineHeight: "24px",
  },
  contentContainer: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "40px",
  },
  emailButton: {
    backgroundColor: "#FEFFFF",
    justifyContent: "center",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  googleButton: {
    backgroundColor: "#FEFFFF",
    justifyContent: "center",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  facebookButton: {
    backgroundColor: "#3C5A9A",
    justifyContent: "center",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  appleButton: {
    backgroundColor: "#12151B",
    justifyContent: "center",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  darkButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#FEFFFF",
  },
  lightButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#12151B",
  },
  emailButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#0B2B5C",
  },
  logInButton: {
    backgroundColor: "#0B2B5C",
    height: "47px",
    color: "#FFFFFF",
    borderRadius: "4px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  dividerText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 700,
  },
  signUpText: {
    alignSelf: "center",
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 400,
  },
  loginButton: {
    color: "#F93C6E",
    fontWeight: 700,
    cursor: "pointer",
  },
});
