import React from "react";
import { SvgIcon } from "@material-ui/core";
import { palette } from "../../theme/palette";

const GoalIcon = ({ width = 24, height = 24, state = "filled" }) => {
  let fill = "none";
  let stroke = "none";

  if (state === "highlighted") {
    stroke = "#12151B";
  } else if (state === "filled") {
    fill = "#12151B";
  } else if (state === "outline") {
    stroke = "#12151B";
  }

  return (
    <SvgIcon width={width} height={height}>
      <svg
        viewBox={`-1 -1 ${width + 2} ${height + 2}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.16091 1C5.60753 1 5.23132 1.56177 5.44203 2.07346L8.318 9.05778C6.33 10.2929 5 12.4909 5 15C5 18.8593 8.13989 22 12 22C15.8601 22 19 18.8593 19 15C19 12.4917 17.67 10.2937 15.682 9.05778L18.558 2.0735C18.7687 1.56179 18.3924 1 17.8391 1C17.524 1 17.2401 1.1901 17.1202 1.48138L14.2758 8.38733C13.7575 8.20685 13.2197 8.08858 12.6736 8.035L15.126 2.07352C15.3366 1.56172 14.9603 1 14.4069 1C14.0918 1 13.8078 1.19015 13.6878 1.48151L10.9679 8.08555C10.5446 8.14752 10.1281 8.24855 9.72344 8.38733L6.87979 1.48143C6.75984 1.19012 6.47595 1 6.16091 1ZM9.59288 1C9.03926 1 8.66284 1.56197 8.87355 2.07393L10.4079 5.802L11.2494 3.75878L10.3122 1.48179C10.1922 1.19027 9.90813 1 9.59288 1ZM12 9.55556C15.0014 9.55556 17.4444 11.9978 17.4444 15C17.4444 18.0022 15.0014 20.4444 12 20.4444C8.99856 20.4444 6.55556 18.0022 6.55556 15C6.55556 11.9978 8.99856 9.55556 12 9.55556ZM11.0916 14.0527L9.33361 14.2451C9.16175 14.2639 9.09311 14.4772 9.22173 14.5927L10.5378 15.7747L10.1667 17.5213C10.1307 17.6906 10.3123 17.8225 10.4622 17.7361L12 16.8496L13.5378 17.7373C13.6877 17.8239 13.8693 17.692 13.8334 17.5226L13.463 15.7762L14.779 14.5919C14.9075 14.4763 14.8388 14.2632 14.667 14.2444L12.9084 14.0519L12.1844 12.328C12.1158 12.1646 11.8842 12.1646 11.8156 12.328L11.0916 14.0527Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1"
        />
      </svg>
    </SvgIcon>
  );
};

export default GoalIcon;
