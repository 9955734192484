import React, { useEffect, useRef, useState } from "react";
import { Stack } from "@mui/material";
import {
  Avatar,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Collapse,
  IconButton,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HeartIcon } from "../../icons";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import { getImage } from "../../../lib/common";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";
import usePost from "../../../hooks/usePost";

dayjs.extend(relativeTime);

async function fetchImageData(image) {
  try {
    const imageUrl = await getImage([image]);
    return imageUrl;
  } catch (error) {
    return null;
  }
}

const useStyles = makeStyles({
  heading: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  body: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#6D6F71",
  },
  body2: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#12151B",
  },
  bodyBold: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#6D6F71",
  },
  container: {
    justifyContent: "space-between",
    padding: "0px",
    marginBottom: "16px",
  },
  avatar: {
    width: "32px",
    height: "32px",
  },
  commentBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
  },
  list: {
    marginLeft: "32px",
  },
});

const CommentItem = ({
  comment,
  postId,
  commentId,
  replyId = null,
  isReply = false,
}) => {
  const { t } = useTranslation("community");
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const {
    handleAddLikes,
    liked,
    likes,
    handleGetLikes,
    handleSetReplyId,
  } = usePost();

  const setReplyId = () => {
    handleSetReplyId(postId, comment.id);
  };
  const [avatarURL, setAvatarURL] = useState(null);
  const heartRef = useRef(null);

  const handleToggleCollapse = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleLike = () => {
    handleAddLikes(postId, commentId, replyId);
    gsap.fromTo(
      heartRef.current,
      { scale: 1 },
      { scale: 1.3, duration: 0.2, yoyo: true, repeat: 1 },
    );
  };

  useEffect(() => {
    if (comment.by && comment.by.image) {
      fetchImageData(comment.by.image).then((avatarUrl) => {
        setAvatarURL(avatarUrl);
      });
    }

    handleGetLikes(comment);
  }, [comment.by.image]);

  return (
    <>
      <ListItem
        alignItems="flex-start"
        className={classes.container}
        style={{
          marginTop: isReply ? "8px" : "16px",
        }}
      >
        <ListItemAvatar style={{ marginRight: "16px", minWidth: "32px" }}>
          <Link
            style={{ display: "inline-block" }}
            to={
              comment.by
                ? `/trailhead/profile/${comment.by.cognito_user_sub}`
                : `/trailhead`
            }
          >
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
              src={
                avatarURL
                  ? avatarURL
                  : `${process.env.PUBLIC_URL}/avatar-empty.png`
              }
            />
          </Link>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography component={"span"} className={classes.heading}>
              {comment.by != null && comment.by.cognito_user_name}
            </Typography>
          }
          secondary={
            <>
              <Typography
                component={"span"}
                className={classes.body2}
                style={{ display: "inline-block", marginRight: "8px" }}
              >
                {comment.text}
              </Typography>
              <Box component={"span"} className={classes.commentBox}>
                <Typography
                  component={"span"}
                  className={classes.body}
                  style={{ marginRight: "8px" }}
                >
                  {comment.created_at != null &&
                    dayjs(comment.created_at).fromNow()}
                </Typography>
                {!isReply && (
                  <Typography
                    onClick={setReplyId}
                    className={classes.bodyBold}
                    style={{ cursor: "pointer" }}
                  >
                    {t("community.post.reply")}
                  </Typography>
                )}
              </Box>
              {!isReply && Object.keys(comment.comments).length > 0 && (
                <Typography
                  className={classes.body}
                  onClick={handleToggleCollapse}
                >
                  {expanded
                    ? t("community.post.hideReplies")
                    : `${t("community.post.view")} ${
                        Object.keys(comment.comments).length
                      } ${t("community.post.replies")}`}
                </Typography>
              )}
            </>
          }
        />
        <Stack
          direction="column"
          alignSelf={"center"}
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <IconButton
            disableRipple
            disableFocusRipple
            aria-label="Like"
            style={{ padding: "0px" }}
            onClick={handleLike}
            ref={heartRef}
          >
            <HeartIcon state={liked ? "filled" : "outline"} />
          </IconButton>
          <Typography
            component={"span"}
            className={classes.body}
            style={{ margin: "0px" }}
          >
            {likes}
          </Typography>
        </Stack>
      </ListItem>
      {!isReply && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List className={classes.list}>
            {comment.comments.map((comment, index) => (
              <Box key={comment.id}>
                {index === 0 ? (
                  <CommentItem
                    comment={comment}
                    postId={postId}
                    commentId={commentId}
                    replyId={comment.id}
                    isReply={true}
                  />
                ) : (
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CommentItem
                      comment={comment}
                      postId={postId}
                      commentId={commentId}
                      replyId={comment.id}
                      isReply={true}
                    />
                  </Collapse>
                )}
              </Box>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default CommentItem;
