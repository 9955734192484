import React, { Component } from "react";
import {
  withMobileDialog,
  Typography,
  TextField,
  Button,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  InputAdornment,
  Grid,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { dateFormat } from "../../../../lib/date-helper";
import moment from "moment";
import { palette } from "../../../../theme/palette";

const styles = (theme) => ({
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing.unit * 2,
  },
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: 18,
  },
  title: {
    fontWeight: 500,
  },
  boldText: {
    fontWeight: "bold",
    paddingRight: theme.spacing.unit * 0.5,
  },
  white: {
    color: theme.palette.common.white,
  },
  smallerFontSize: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller",
    },
  },
  dialogActions: {
    justifyContent: "center",
    marginBottom: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    cursor: "pointer",
  },
  leftButtons: {
    display: "flex",
    justifyContent: "flex-start",
  },
  rightMargin: {
    marginRight: theme.spacing.unit * 2,
  },
  comment: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing.unit,
    },
  },
  raceDateContainer: {
    display: "flex",
    height: "100%",
    paddingTop: theme.spacing.unit,
    marginTop: 8,
  },
  text: {
    color: theme.palette.text.secondaryDark,
    marginTop: "auto",
    fontSize: "small",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      marginTop: 0,
    },
  },
  raceName: {
    fontWeight: 600,
    fontSize: 14,
    color: palette.black,
    margin: "3px 0px",
    fontFamily: "Oswald",
  },
  raceBottomTitle: {
    fontWeight: 500,
    fontSize: 14,
    color: "#5F5F5F",
  },
  raceBottomText: {
    fontWeight: 200,
    fontSize: 16,
    color: "#3F3F3F",
  },
  raceLocation: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePriorityContainer: {
    display: "flex",
    alignItems: "flex-start",
    margin: "10px 0",
    flexDirection: "column",
  },
  racePriority: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePrioritySelected: {
    margin: "0 5px",
    background: "#1E629B",
    borderRadius: "100%",
    fontWeight: 900,
    fontSize: 21,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "34px",
    textAlign: "center",
  },
  racePriorityUnselected: {
    margin: "0 5px",
    background: "rgba(30, 98, 155, 0.12)",
    borderRadius: "100%",
    fontWeight: 900,
    fontSize: 21,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "34px",
    textAlign: "center",
  },
  buttons: {
    borderRadius: 50,
    textTransform: "uppercase",
    marginLeft: "auto",
    display: "block",
  },
  buttonsCont: {
    display: "flex",
  },
  outlined: {
    "& .MuiInputBase-input": {
      padding: "1px",
      backgroundColor: "red",
    },
  },
  priorityA: {
    backgroundColor: "#F93C6E",
  },
  priorityB: {
    backgroundColor: "#4780AB",
  },
  priorityC: {
    backgroundColor: "#6BB488",
  },
});

class DashboardAddEditNextRace extends Component {
  state = {
    currentUnits: "kms",
    raceName: null,
    raceDistance: null,
    raceElevation: null,
    raceDate: null,
    raceLocation: null,
    racePriority: "A",
    raceId: null,
    link: null,
  };

  componentDidMount = () => {
    const { nextRace } = this.props;
    const currentUnits = localStorage.getItem("units");
    if (nextRace) {
      let calculatedDistance = nextRace.raceDistance;
      let calculatedElevation = nextRace.raceElevation;

      if (
        (nextRace.units && currentUnits !== nextRace.units) ||
        (!nextRace.units && currentUnits === "miles")
      ) {
        if (currentUnits === "kms") {
          calculatedDistance = Math.round(calculatedDistance * 1.60934);
          calculatedElevation = Math.round(calculatedElevation * 0.3048);
        } else {
          calculatedDistance = Math.round(calculatedDistance * 0.621371);
          calculatedElevation = Math.round(calculatedElevation * 3.28084);
        }
      }
      this.setState({
        ...nextRace,
        raceDistance: calculatedDistance,
        raceElevation: calculatedElevation,
        units: currentUnits,
        raceId: nextRace.raceId ? nextRace.raceId : "old",
        currentUnits,
      });
    } else {
      this.setState({
        units: localStorage.getItem("units"),
        raceName: null,
        raceDistance: null,
        raceElevation: null,
        raceDate: null,
        raceLocation: null,
        raceId: "add",
        racePriority: "A",
        currentUnits,
      });
    }

    if (typeof window !== "undefined") {
      window.addEventListener("storage", () => this.localStorageUpdated());
    }
  };

  localStorageUpdated() {
    this.setState({
      ...this.state,
      currentUnits: localStorage.getItem("units"),
    });
  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      editing: null,
    });

    if (this.props.parentMethod) this.props.parentMethod();
  }

  handleTextChange = (event) => {
    const { target } = event;
    if (target) {
      const { value, name } = target;
      if (
        name === "raceDistance" &&
        !this.isNumber(value) &&
        (!this.state.raceDistance || !this.state.raceDistance.length)
      )
        return this.setState({
          ...this.state,
          ["raceDistance"]: "",
        });

      if (name === "raceDistance" && !this.isNumber(value)) return;

      if (
        name === "raceElevation" &&
        !this.isNumber(value) &&
        (!this.state.raceElevation || !this.state.raceElevation.length)
      )
        return this.setState({
          ...this.state,
          ["raceElevation"]: "",
        });

      if (name === "raceElevation" && !this.isNumber(value)) return;

      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  isNumber = (number) =>
    /^\d{0,10}$|(?=^.{0,10}$)^\d+\.\d{0,10}$/gm.test(number);

  handleRaceDateChange = (momentObject) => {
    const { onError } = this.props;
    if (momentObject) {
      try {
        this.setState({
          ...this.state,
          raceDate: momentObject.format(dateFormat),
        });
      } catch (exp) {
        if (onError) onError(exp);
      }
    } else {
      this.setState({
        ...this.state,
        raceDate: undefined,
      });
    }
  };

  handlePriorityChange = (priority) => {
    this.setState({
      ...this.state,
      racePriority: priority,
    });
  };

  render() {
    const {
      currentUnits,
      raceDistance,
      raceElevation,
      raceName,
      racePriority,
      raceId,
      link,
    } = this.state;
    const {
      classes,
      t,
      editing,
      onSave,
      onDelete,
      isOpen,
      onClose,
    } = this.props;

    return (
      <Dialog open={isOpen} onClose={onClose} maxWidth="md">
        <DialogContent id="addeditdialog" className={classes.noPadding}>
          <div>
            <div className={classes.container}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {editing && raceId && editing !== "add" ? (
                  <Typography variant="h6" className={classes.raceName}>
                    {t("Edit Race")}
                  </Typography>
                ) : (
                  <Typography variant="h6" className={classes.raceName}>
                    {t("Add a Race").toUpperCase()}
                  </Typography>
                )}

                <img
                  src={`${process.env.PUBLIC_URL}/icon_close.svg`}
                  onClick={onClose}
                />
              </Box>

              {editing && editing === raceId ? (
                <TextField
                  defaultValue={this.state.raceName}
                  fullWidth
                  id="raceName"
                  name="raceName"
                  margin="normal"
                  label={t("Name")}
                  onChange={this.handleTextChange}
                  variant="outlined"
                  inputProps={{ maxLength: 80 }}
                  size="small"
                  InputLabelProps={{
                    classes: { root: classes.smallerFontSize },
                  }}
                />
              ) : (
                <Typography variant="h6" className={classes.raceName}>
                  {raceName}
                </Typography>
              )}

              <div>
                <div className={classes.raceBottomItem}>
                  {editing && editing === raceId ? (
                    <TextField
                      fullWidth
                      value={raceDistance}
                      id="raceDistance"
                      name="raceDistance"
                      margin="normal"
                      placeholder={
                        !currentUnits || currentUnits === "kms"
                          ? t("Distance (km)")
                          : t("Distance (miles)")
                      }
                      inputProps={{ maxLength: 10 }}
                      onChange={this.handleTextChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={`${process.env.PUBLIC_URL}/icon_distance_gray.svg`}
                            />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        classes: { root: classes.smallerFontSize },
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className={classes.raceBottomItem}>
                  {editing && editing === raceId ? (
                    <TextField
                      defaultValue={raceElevation}
                      value={raceElevation}
                      fullWidth
                      id="raceElevation"
                      name="raceElevation"
                      inputProps={{ maxLength: 10 }}
                      margin="normal"
                      variant="outlined"
                      placeholder={
                        !currentUnits || currentUnits === "kms"
                          ? t("Elevation (m)")
                          : t("Elevation (feet)")
                      }
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={`${process.env.PUBLIC_URL}/icon_elevation_gray.svg`}
                            />
                          </InputAdornment>
                        ),
                      }}
                      onChange={this.handleTextChange}
                      InputLabelProps={{
                        classes: { root: classes.smallerFontSize },
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>

                <div className={classes.raceBottomItem}>
                  {editing && editing === raceId ? (
                    <TextField
                      defaultValue={link}
                      value={link}
                      fullWidth
                      id="link"
                      name="link"
                      inputProps={{ maxLength: 150 }}
                      margin="normal"
                      variant="outlined"
                      size="small"
                      placeholder={t("Race link (optional)")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={`${process.env.PUBLIC_URL}/icon_link_gray.svg`}
                            />
                          </InputAdornment>
                        ),
                      }}
                      onChange={this.handleTextChange}
                      InputLabelProps={{
                        classes: { root: classes.smallerFontSize },
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.raceBottomItem}>
                      {editing && editing === raceId ? (
                        <div className={classes.raceDateContainer}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              value={moment(
                                this.state.raceDate || null,
                                dateFormat,
                              ).toISOString()}
                              format="DD/MM/YYYY"
                              placeholder="Date"
                              mask={(value) =>
                                // handle clearing outside if value can be changed outside of the component
                                value
                                  ? [
                                      /\d/,
                                      /\d/,
                                      "/",
                                      /\d/,
                                      /\d/,
                                      "/",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]
                                  : []
                              }
                              autoOk
                              size="small"
                              clearable
                              inputVariant="outlined"
                              keyboard
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={`${process.env.PUBLIC_URL}/icon_date_gray.svg`}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                              onChange={this.handleRaceDateChange}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.racePriorityContainer}>
                      <Typography variant="h6" className={classes.racePriority}>
                        {t("Priority")}:
                      </Typography>
                      {editing && editing === raceId ? (
                        <Box display="flex">
                          <Tooltip title={t("Choose your goal priority")}>
                            <Typography
                              variant="h6"
                              onClick={() => this.handlePriorityChange("A")}
                              className={
                                racePriority === "A"
                                  ? `${classes.racePrioritySelected} ${classes.priorityA}`
                                  : classes.racePriorityUnselected
                              }
                            >
                              A
                            </Typography>
                          </Tooltip>
                          <Tooltip title={t("Choose your goal priority")}>
                            <Typography
                              variant="h6"
                              onClick={() => this.handlePriorityChange("B")}
                              className={
                                racePriority === "B"
                                  ? `${classes.racePrioritySelected} ${classes.priorityB}`
                                  : classes.racePriorityUnselected
                              }
                            >
                              B
                            </Typography>
                          </Tooltip>
                          <Tooltip title={t("Choose your goal priority")}>
                            <Typography
                              variant="h6"
                              onClick={() => this.handlePriorityChange("C")}
                              className={
                                racePriority === "C"
                                  ? `${classes.racePrioritySelected} ${classes.priorityC}`
                                  : classes.racePriorityUnselected
                              }
                            >
                              C
                            </Typography>
                          </Tooltip>
                        </Box>
                      ) : (
                        ""
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          {editing && raceId && editing !== "add" ? (
            <Button color="primary" onClick={() => onDelete(raceId)}>
              {t("Delete")}
            </Button>
          ) : (
            ""
          )}
          <Grid
            container
            spacing={2}
            style={{
              padding: "0px 16px",
              marginTop: "4px",
              marginBottom: "16px",
            }}
          >
            <Grid item xs={6} sm={6} md={6}>
              <Button
                onClick={() => onClose()}
                fullWidth
                variant="outlined"
                size="medium"
                style={{
                  borderColor: palette.darkBlue,
                  color: palette.darkBlue,
                }}
              >
                {t("Cancel")}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Button
                onClick={() => onSave(this.state)}
                fullWidth
                variant="contained"
                size="medium"
                style={{
                  backgroundColor: palette.darkBlue,
                  color: palette.white,
                }}
              >
                {t("Add")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

DashboardAddEditNextRace.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  fullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  userPlans: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("dashboard")(
    withWidth()(withMobileDialog()(DashboardAddEditNextRace)),
  ),
);
