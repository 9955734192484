import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withTranslation } from "react-i18next";

import PlanDrawer from "./plan-drawer.js";
import Weekdays from "../../../constants/weekdays";
import Genders from "../../../constants/genders";
import RaceCounts from "../../../constants/race-counts";
import TrainingPerWeek from "../../../constants/training-per-week";
import SkillsToImprove from "../../../constants/skills-to-improve";
import Fields from "../../../constants/plan-drawer-fields";
import LongestRun from "../../../constants/longest-run";
import { Capacitor } from "@capacitor/core";

class PlanPremium extends Component {
  render() {
    const {
      planTempData,
      currentData,
      onTextFieldChange,
      onYesNoFieldChange,
      onDateFieldChange,
      onSelectFieldChange,
      onButtonSelectFieldChange,
      onArrayFieldChange,
      onFieldError,
      onEnterPress,
      onFieldsInit,
      t,
      onBackRequested,
      onTimeSelect,
    } = this.props;

    const trainHoursPerWeekItems = [
      { text: "0", value: 0 },
      { text: "2", value: 2 },
      { text: "4", value: 4 },
      { text: "6", value: 6 },
      { text: "8", value: 8 },
      { text: "10+", value: 10 },
    ];
    const skillToImproveItemsArray = SkillsToImprove.itemsArray;
    const skillToImproveItems = [
      {
        ...skillToImproveItemsArray[0],
        caption: `#${t("Light Speed")}`,
        image: `${process.env.PUBLIC_URL}/card-skill-light-speed.png`,
        imageTooltip: t("Speed", { ns: "constants" }),
      },
      {
        ...skillToImproveItemsArray[1],
        caption: `#${t("Climb Strong")}`,
        image: `${process.env.PUBLIC_URL}/card-skill-climb-strong.png`,
        imageTooltip: t("Climbs", { ns: "constants" }),
      },
      {
        ...skillToImproveItemsArray[2],
        caption: `#${t("King of the downhill")}`,
        image: `${process.env.PUBLIC_URL}/card-skill-king-of-downhill.png`,
        imageTooltip: t("Downhills", { ns: "constants" }),
      },
      {
        ...skillToImproveItemsArray[3],
        caption: `#${t("Endurance")}`,
        image: `${process.env.PUBLIC_URL}/card-skill-endurance.png`,
        imageTooltip: t("Endurance", { ns: "constants" }),
      },
    ];

    const {
      country,
      birthdate,
      sex,
      raceCount,
      skillToImprove,
      trainPerWeek,
      trainWeekdays,
      trainHoursPerWeek,
      currentStep,
      desiredStartDate,
      longestRun,
      workoutNotifications,
    } = planTempData;

    const birthdateMax = moment().subtract(16, "y");
    const desiredStartDateMin = moment();
    const desiredStartDateMax = moment().add(14, "d");

    let stepsCount = 12;
    if (currentData && currentData.birthdate) {
      stepsCount--;
    }
    if (currentData && currentData.country) {
      stepsCount--;
    }
    if (currentData && currentData.sex) {
      stepsCount--;
    }
    if (
      (currentData && currentData.showWorkoutNotifications) ||
      !Capacitor.isNativePlatform()
    ) {
      stepsCount--;
    }

    const fields = [
      [
        {
          fieldType: Fields.Items.IMAGE,
          image: "6.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("Do you have hills or mountains nearby?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.YES_NO,
          id: "surface",
          xs: 12,
          sm: 12,
          md: 12,
          onError: onFieldError,
          onClick: onYesNoFieldChange,
        },
      ],
      [
        {
          fieldType: Fields.Items.IMAGE,
          image: "4.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("How many days do you usually train per week?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.STEPPER_SELECT,
          id: "trainPerWeek",
          xs: 12,
          sm: 12,
          md: 12,
          selectedValue: trainPerWeek,
          onError: onFieldError,
          items: TrainingPerWeek.itemsArray,
          onSelect: onSelectFieldChange,
        },
      ],
      [
        {
          fieldType: Fields.Items.IMAGE,
          image: "3.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("Which days do you have the most time to train each week?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.CHIPS_SELECT,
          id: "trainWeekdays",
          xs: 12,
          sm: 12,
          md: 12,
          selectedValues: trainWeekdays,
          onError: onFieldError,
          items: Weekdays.itemsArray,
          onSelect: onArrayFieldChange,
        },
      ],
      [
        {
          fieldType: Fields.Items.IMAGE,
          image: "10.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("Which skill would you like to start working on?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.CARD_SELECT,
          id: "skillToImprove",
          xs: 12,
          sm: 12,
          md: 12,
          selectedValue: skillToImprove,
          onError: onFieldError,
          items: skillToImproveItems,
          onSelect: onSelectFieldChange,
        },
      ],
      [
        {
          fieldType: Fields.Items.IMAGE,
          image: "4.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("How many hours do you usually train each week?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.STEPPER_SELECT,
          id: "trainHoursPerWeek",
          xs: 12,
          sm: 12,
          md: 12,
          selectedValue: trainHoursPerWeek,
          onError: onFieldError,
          items: trainHoursPerWeekItems,
          onSelect: onSelectFieldChange,
        },
      ],
      [
        {
          fieldType: Fields.Items.IMAGE,
          image: "7.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("How many trail races have you run before?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.STEPPER_SELECT,
          id: "raceCount",
          xs: 12,
          sm: 12,
          md: 12,
          selectedValue: raceCount,
          onError: onFieldError,
          items: RaceCounts.itemsArray,
          onSelect: onSelectFieldChange,
        },
      ],
      [
        {
          fieldType: Fields.Items.IMAGE,
          image: "9.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t(
            "What has been the longest race/run that you have ever done before?",
          ),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.STEPPER_SELECT,
          id: "longestRun",
          xs: 12,
          sm: 12,
          md: 12,
          selectedValue: longestRun,
          onError: onFieldError,
          items: LongestRun.itemsArray,
          onSelect: onSelectFieldChange,
        },
      ],
    ];

    if (currentData && !currentData.birthdate) {
      fields.push([
        {
          fieldType: Fields.Items.IMAGE,
          image: "5.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("What is your date of birth?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.DATE,
          id: "birthdate",
          xs: 12,
          sm: 12,
          md: 12,
          value: birthdate,
          onError: onFieldError,
          onChange: onDateFieldChange,
          maxDate: birthdateMax,
          minDateMessage: t("The date must not be before the minimum date"),
          maxDateMessage: t(
            "You must be at least 16 years old to train with Vert",
          ),
        },
      ]);
    }

    if (currentData && !currentData.country) {
      fields.push([
        {
          fieldType: Fields.Items.IMAGE,
          image: "8.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("What is your country?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.TEXT_INPUT,
          id: "country",
          xs: 12,
          sm: 12,
          md: 12,
          value: country,
          onKeyPress: onEnterPress,
          onError: onFieldError,
          onChange: onTextFieldChange,
        },
      ]);
    }

    if (currentData && !currentData.sex) {
      fields.push([
        {
          fieldType: Fields.Items.IMAGE,
          image: "1.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("With which gender do you identify?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.BUTTON_SELECT,
          id: "sex",
          xs: 12,
          sm: 12,
          md: 12,
          value: sex,
          items: Genders.itemsArray,
          onError: onFieldError,
          onSelect: onButtonSelectFieldChange,
        },
      ]);
    }

    // if (
    //   currentData &&
    //   !currentData.showWorkoutNotifications &&
    //   Capacitor.isNativePlatform()
    // ) {
    //   fields.push([
    //     {
    //       fieldType: Fields.Items.IMAGE,
    //       image: "12.png",
    //     },
    //     {
    //       fieldType: Fields.Items.STEPPER,
    //       xs: 12,
    //       sm: 12,
    //       md: 12,
    //       currentStep: currentStep,
    //       stepsCount: stepsCount,
    //     },
    //     {
    //       fieldType: Fields.Items.LABEL,
    //       xs: 12,
    //       sm: 12,
    //       md: 12,
    //       label: t(
    //         "On the days you train, do you want a preview of your workout?",
    //       ),
    //       subLabel: "",
    //       labelNumber: currentStep + 1,
    //     },
    //     {
    //       fieldType: Fields.Items.TIME_SELECT,
    //       id: "workoutNotifications",
    //       xs: 12,
    //       sm: 12,
    //       md: 12,
    //       value: workoutNotifications,
    //       onSelect: onTimeSelect,
    //     },
    //   ]);
    // }

    fields.push([
      {
        fieldType: Fields.Items.IMAGE,
        image: "12.png",
      },
      {
        fieldType: Fields.Items.STEPPER,
        xs: 12,
        sm: 12,
        md: 12,
        currentStep: currentStep,
        stepsCount,
      },
      {
        fieldType: Fields.Items.LABEL,
        xs: 12,
        sm: 12,
        md: 12,
        label: t("When would you like to start your plan?"),
        subLabel: t("If your race"),
        labelNumber: currentStep + 1,
      },
      {
        fieldType: Fields.Items.DATE,
        id: "desiredStartDate",
        xs: 12,
        sm: 12,
        md: 12,
        value: desiredStartDate,
        defaultValue: desiredStartDateMin.format("DD/MM/YYYY"),
        onError: onFieldError,
        onChange: onDateFieldChange,
        minDate: desiredStartDateMin,
        maxDate: desiredStartDateMax,
        minDateMessage: t("You can't start your plan before today"),
        maxDateMessage: t("You must start your plan within 14 days"),
      },
    ]);

    fields.push([
      {
        fieldType: Fields.Items.IMAGE,
        image: "invitationcover.svg",
      },
      {
        fieldType: Fields.Items.STEPPER,
        xs: 12,
        sm: 12,
        md: 12,
        currentStep: currentStep,
        stepsCount: stepsCount,
      },
      {
        fieldType: Fields.Items.INVITATION,
        id: "invitation",
      },
    ]);

    onFieldsInit(fields);
    return (
      <PlanDrawer
        currentStep={currentStep}
        fields={fields[currentStep]}
        onBackRequested={onBackRequested}
      />
    );
  }
}

PlanPremium.propTypes = {
  planTempData: PropTypes.object.isRequired,
  currentData: PropTypes.object.isRequired,
  onTextFieldChange: PropTypes.func.isRequired,
  onDateFieldChange: PropTypes.func.isRequired,
  onArrayFieldChange: PropTypes.func.isRequired,
  onYesNoFieldChange: PropTypes.func.isRequired,
  onSelectFieldChange: PropTypes.func.isRequired,
  onButtonSelectFieldChange: PropTypes.func.isRequired,
  onFieldError: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func.isRequired,
  onFieldsInit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(["plan", "constants"])(PlanPremium);
