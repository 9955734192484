import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import moment from "moment";
import PlanDrawer from "./plan-drawer.js";
import Fields from "../../../constants/plan-drawer-fields";
import Genders from "../../../constants/genders";

class PlanSuggesterQuestions extends Component {
  render() {
    const {
      country,
      birthdate,
      sex,
      onButtonSelectFieldChange,
      onFieldError,
      onFieldsInit,
      t,
      currentStep,
      onBackRequested,
      onDateFieldChange,
      onTextFieldChange,
      onEnterPress,
    } = this.props;

    const birthdateMax = moment().subtract(16, "y");
    const stepsCount = 3;

    const fields = [
      [
        {
          fieldType: Fields.Items.IMAGE,
          image: "5.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("What is your date of birth?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.DATE,
          id: "birthdate",
          xs: 12,
          sm: 12,
          md: 12,
          value: birthdate,
          onError: onFieldError,
          onChange: onDateFieldChange,
          maxDate: birthdateMax,
          minDateMessage: t("The date must not be before the minimum date"),
          maxDateMessage: t(
            "You must be at least 16 years old to train with Vert",
          ),
        },
      ],

      [
        {
          fieldType: Fields.Items.IMAGE,
          image: "9.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("What is your country?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.TEXT_INPUT,
          id: "country",
          xs: 12,
          sm: 12,
          md: 12,
          value: country,
          onKeyPress: onEnterPress,
          onError: onFieldError,
          onChange: onTextFieldChange,
        },
      ],

      [
        {
          fieldType: Fields.Items.IMAGE,
          image: "1.png",
        },
        {
          fieldType: Fields.Items.STEPPER,
          xs: 12,
          sm: 12,
          md: 12,
          currentStep: currentStep,
          stepsCount,
        },
        {
          fieldType: Fields.Items.LABEL,
          xs: 12,
          sm: 12,
          md: 12,
          label: t("With which gender do you identify?"),
          labelNumber: currentStep + 1,
        },
        {
          fieldType: Fields.Items.BUTTON_SELECT,
          id: "sex",
          xs: 12,
          sm: 12,
          md: 12,
          value: sex,
          items: Genders.itemsArray,
          onError: onFieldError,
          onSelect: onButtonSelectFieldChange,
        },
      ],
    ];

    onFieldsInit(fields);

    return (
      <PlanDrawer
        currentStep={currentStep}
        fields={fields[currentStep]}
        onBackRequested={onBackRequested}
      />
    );
  }
}

PlanSuggesterQuestions.propTypes = {
  planTempData: PropTypes.object.isRequired,
  currentData: PropTypes.object.isRequired,
  onButtonSelectFieldChange: PropTypes.func.isRequired,
  onFieldError: PropTypes.func.isRequired,
  onFieldsInit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(["plan", "constants"])(PlanSuggesterQuestions);
