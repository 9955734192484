import React, { useState, useEffect } from "react";
import { Typography, Box } from "@material-ui/core";
import { palette } from "../../../theme/palette";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import CardTemplate from "../../common/v2/CardTemplate";
import { useTranslation } from "react-i18next";
import unitsHook from "../../../hooks/unitsHook";
import { round } from "../../../lib/units-helper";
dayjs.extend(weekOfYear);

export const ActivityChart = ({
  activitiesData = [],
  currentActivity = {},
}) => {
  const { t } = useTranslation();
  const localUnits = unitsHook();
  const [lapPaces, setLapPaces] = useState([]);
  const [labels, setLabels] = useState([]);
  const [elevation, setElevation] = useState([]);

  useEffect(() => {
    if (currentActivity) {
      const elevationSamples =
        currentActivity && currentActivity.samples
          ? currentActivity.samples.map((s) => {
              return {
                x: s.distance,
                y: s.elevation,
              };
            })
          : [];

      let labels = [];
      let divider = 1;
      if (currentActivity && currentActivity.distance) {
        if (currentActivity.distance / 25 > 1) {
          divider = Math.round(currentActivity.distance / 25);
        }

        for (
          let index = 0;
          index < currentActivity.distance;
          index += divider
        ) {
          labels.push(index);
        }
      }

      const lapPaces = [];
      if (currentActivity && currentActivity.laps) {
        if (elevationSamples.length) {
          elevationSamples.push({
            x: 0,
            y: round(elevationSamples[0].y, 0),
          });
        }
        for (let index = 0; index < currentActivity.laps.length; index++) {
          const element = currentActivity.laps[index];
          if (element.pace) {
            lapPaces.push(element.pace.minutes + element.pace.seconds / 60);
          }
        }
      }

      elevationSamples.sort((a, b) => a.x - b.x);
      labels = elevationSamples.map((e) => e.x);
      labels.sort((a, b) => a - b);

      setLapPaces(lapPaces);
      setLabels(labels);
      setElevation(elevationSamples.map((e) => e.y));
    }
  }, [currentActivity]);

  const optionsLinear = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${t("Elevation")}:${round(+context.parsed.y, 1)} ${
              localUnits === "miles" ? t("feet") : t("m")
            } - ${round(+context.label, 2)} ${
              localUnits === "miles" ? `${t("miles")}` : `${t("km")}`
            }`;
          },
          title: () => null,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: true,
          color: palette.lightGray,
          drawBorder: true,
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
          max:
            currentActivity && currentActivity.distance
              ? currentActivity.distance
              : 0,
          display: true,
          padding: 10,
          callback: function(value, index, ticks) {
            return "";
          },
        },
      },
      y: {
        display: true,
        grid: {
          display: false,
          color: palette.lightGray,
          drawBorder: true,
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
          display: true,
          padding: 10,
          callback: function(value, index, values) {
            return `${value} ${
              localUnits === "miles" ? `${t("feet")}` : `${t("m")}`
            }`;
          },
        },
      },
    },
    animation: {
      easing: "linear",
      duration: 1000,
    },
  };

  const data = {
    labels,
    datasets: [
      {
        backgroundColor: "rgba(0,0,0,0)",
        borderWidth: 4,
        borderColor: "#0B2B5C",
        label: "Elevation",
        data: elevation,
        borderCapStyle: "round",
        borderJoinStyle: "round",
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
      },
    ],
  };

  return (
    <CardTemplate>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="16px"
      >
        <Typography variant="h4" style={{ fontSize: 18, fontWeight: 700 }}>
          {t("Elevation Gain")}
        </Typography>
      </Box>
      <Line data={data} options={optionsLinear} />
    </CardTemplate>
  );
};
