import React from "react";
import {
  Button,
  Typography,
  Card,
  CardContent,
  ListItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  cardContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#FEFFFF",
    elevation: 3,
    minHeight: "280px",
  },
  cardContent: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: 400,
    fontFamily: "oswald",
    color: "#12151B",
    textTransform: "uppercase",
  },
  priceContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  priceText: {
    fontSize: "40px",
    fontWeight: 600,
    fontFamily: "oswald",
    color: "#0B2B5C",
  },
  priceHighlight: {
    fontWeight: 600,
    fontSize: "48px",
  },
  subtextContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  noSubtext: {
    display: "block",
    height: "27px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  buttonText: {
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#FEFFFF",
  },
  button: {
    backgroundColor: "#0B2B5C",
    height: "62px",
    width: "100%",
    margin: "24px",
  },
});
const CheckoutPriceCardButton = ({
  title,
  price,
  subtext = null,
  glow = false,
  premium = false,
  onClick = () => {},
}) => {
  const { t } = useTranslation("webcheckout");
  const classes = useStyles();

  return (
    <ListItem className={classes.container}>
      <Card
        className={classes.cardContainer}
        style={{
          ...(glow && { boxShadow: "0 0 20px #4780AB7D" }),
        }}
      >
        <CardContent className={classes.cardContent}>
          <div>
            <Typography className={classes.cardTitle}>{title}</Typography>
          </div>
          <div className={classes.priceContainer}>
            <Typography className={classes.priceText}>
              {price}
              {!premium && (
                <span className={classes.priceHighlight}>
                  /{t("checkout.item.month")}
                </span>
              )}
            </Typography>
            {subtext ? (
              <div className={classes.subtextContainer}>{subtext}</div>
            ) : (
              <span className={classes.noSubtext}></span>
            )}
            <div className={classes.buttonContainer}>
              <Button
                onClick={onClick}
                variant="contained"
                className={classes.button}
              >
                <Typography className={classes.buttonText}>
                  {t("checkout.item.subscribe")}
                </Typography>
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </ListItem>
  );
};

export default CheckoutPriceCardButton;
