import React from "react";

const SuuntoIcon = ({ width = 18, height = 18, vWidth = 18, vHeight = 18 }) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vWidth} ${vHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 3L0 16H18L9 3Z" fill="#CE1F44" />
    </svg>
  </>
);
export default SuuntoIcon;
