import { connect } from "react-redux";

import * as pusherActions from "../actions/pusher-action";

const mapState = (state) => ({
  pusher: state.pusher,
});

const mapDispatch = {
  ...pusherActions,
};

const ConnectPusher = connect(mapState, mapDispatch);

export default ConnectPusher;
