/* eslint-disable no-console */
import { Analytics, AWSKinesisFirehoseProvider } from "aws-amplify";
import moment from "moment";
import * as amplitude from "@amplitude/analytics-browser";

Analytics.addPluggable(new AWSKinesisFirehoseProvider());
Analytics.configure({
  AWSKinesisFirehose: {
    region: process.env.REACT_APP_FIREHOSE_REGION,
    bufferSize: 1000,
    flushSize: 100,
    flushInterval: 5000,
    resendLimit: 5,
  },
});

// Usage instruction:
//  AnalyticsService.addAnalyticsData({
//   type: 1,
//   description: 'Page X Viewed',
//   extraData: {
//   Platform: 'PC'
//  }
// });

class AnalyticsService {
  static async addAnalyticsData(analyticsData) {
    if (analyticsData) {
      const { type, description = "", extraData = {} } = analyticsData;
      amplitude.track(description, extraData);
      const isTypeInteger =
        !isNaN(type) &&
        parseInt(Number(type)) === type &&
        !isNaN(parseInt(type, 10));
      if (isTypeInteger) {
        const obj = {
          data: {
            type: analyticsData.type,
            eventDate: moment(),
            typeDescription: description,
            AppendedData: extraData,
          },
          streamName: process.env.REACT_APP_FIREHOSE_NAME,
        };
        try {
          return await Analytics.record(obj, "AWSKinesisFirehose").promise();
        } catch (e) {
          return { error: e };
        }
      } else {
        return { error: "Event type is not valid" };
      }
    } else {
      return { error: "Analytic data is not valid" };
    }
  }
}

export default AnalyticsService;
