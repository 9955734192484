import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import "url-search-params-polyfill";
import {
  Drawer,
  Typography,
  Button,
  TextField,
  InputAdornment,
  LinearProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import DashboardNextRaceDetails from "../dashboard/dashboard-training/dashboard-next-race/dashboard-next-race-details";
import withWidth from "@material-ui/core/withWidth";
import history from "../../lib/history-helper";
import {
  joinGroup,
  acceptJoinGroup,
  leaveGroup,
  clearGroup,
  getTags,
  addAllez,
  addComment,
  deletePost,
  deleteComment,
  editPost,
  addReport,
  newPost,
  cleanNewPost,
  addPost,
  getGroupPosts,
} from "../../actions/trail-head-action";
import PublicGroupRequests from "./public-group/public-group-requests";
import { getGroup } from "../../actions/trail-head-action";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  updatePlan,
  clearSuccessMessage,
  clearErrorMessage,
  toggleOpenMenu,
  getUserActivities,
  clearCurrentUserActivity,
} from "../../actions/user-plans-action";
import { setErrorMessage } from "../../actions/user-plans-action";
import DashboardRecentActivities from "../dashboard/dashboard-recent-activities";
import { reduceFileSize } from "../../lib/image-helper";
import jimp from "jimp";
import DashboardNextRacePane from "../dashboard/dashboard-training/dashboard-next-race/dashboard-next-race-pane";
import FinishedChallenges from "../challenges/finished-challenges";
import FinishedChallengeH from "../challenges/finished-challenge-h";
import AutoCompleteMultiSelect from "../common/auto-complete-multi-select";
import Post from "./post";

import InfiniteScroll from "react-infinite-scroll-component";
import { convertUnits, round } from "../../lib/units-helper";
import RaceCard from "./social-profile/races/components/race-card";
import { logEvent } from "../../lib/events-helper";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import classNames from "classnames";
import UserGroups from "../../constants/user-groups";
import { checkSubscription } from "../../lib/subscription-plan-helper";
import PostCardComponent from "../common/v2/PostCardComponent";

const styles = (theme) => ({
  drawerRoot: {
    minHeight: "100vh",
    maxWidth: "500px",
    minWidth: "500px",
    background: "#F9F9FB",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "initial",
      minWidth: "initial",
    },
  },

  container: {},

  loadingContainer: {
    textAlign: "center",
  },

  image: {
    width: "100%",
  },

  infoContainer: {
    padding: "16px",
    background: "#FFFFFF",
    borderRadius: "0px 0px 12px 12px",
  },

  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    fontWeight: 500,
    fontSize: 18,
  },

  stats: {
    fontWeight: 400,
    fontSize: 12,
    color: "#9E9E9E",
  },

  joinButton: {
    borderRadius: 50,
  },

  description: {
    fontSize: 14,
    margin: "16px 0",
  },

  backIcon: {
    top: 0,
    left: 5,
    cursor: "pointer",
    position: "sticky",
    background: "rgba(255, 255, 255, 0.7)",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    paddingTop: 3,
    paddingLeft: 7,
    paddingBottom: 3,
    borderRadius: "100%",
    marginTop: 5,
    zIndex: 10,
    marginBottom: -31,
  },

  postOptionsRow: {
    display: "flex",
    justifyContent: "space-evenly",
  },

  postOption: {
    textAlign: "center",
    background: "#F9F9FB",
    borderRadius: 4,
    padding: "10px 20px",
    minWidth: 65,
    cursor: "pointer",
  },

  postOptionText: {
    fontSize: 12,
    cursor: "pointer",
  },

  postImageContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  postImage: {
    maxWidth: "100%",
    maxHeight: 300,
    marginTop: 15,
  },

  publishButtonContainer: {
    width: "100%",
    marginTop: 10,
    textAlign: "center",
    marginBottom: 40,
  },

  publishButton: {
    margin: "auto",
    textTransform: "initial",
    borderRadius: 10,
    width: 75,
    fontFamily: "Montserrat",
    fontSize: 16,
    height: 29,
    boxShadow: "none",
  },

  input: {
    display: "none",
    cursor: "pointer",
  },

  searchBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    marginTop: 8,
  },

  tabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
  },

  tab: {
    fontSize: 16,
    opacity: 0.7,
    textTransform: "uppercase",
    cursor: "pointer",
  },

  tabSelected: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    cursor: "pointer",
    marginLeft: 26,
  },

  iconSearch: {
    marginLeft: 10,
    cursor: "pointer",
  },

  posts: {
    width: "100%",
  },
  loading: {
    textAlign: "center",
  },

  topContainer: {
    //position: "sticky",
    zIndex: 100,
    //top: 0,
    background: "#ffffff",
    borderRadius: "0px 0px 12px 12px",
    marginBottom: 20,
  },

  progressRoot: {
    borderRadius: 5,
    height: 16,
    background: "#ffffff",
  },
  progressBar: {
    borderRadius: 0,
    background: "#1E629B",
  },

  goalContainer: {
    background: "#F9F9FB",
    borderRadius: 4,
    padding: 12,
    marginTop: 16,
  },

  goalTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
  },

  goalText: {
    fontWeight: 600,
    fontSize: 12,
  },

  goalAdded: {
    fontWeight: 400,
    fontSize: 12,
    marginTop: 8,
  },

  progressBarContainer: {
    position: "relative",
  },

  progressLabel: {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    marginLeft: 5,
    fontWeight: "normal",
    fontSize: 12,
    color: "#9E9E9E",
  },

  stickyIcon: {
    position: "sticky",
    top: 15,
    marginLeft: 25,
    zIndex: 999,
    width: "fit-content",
  },

  iconParent: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transitionDuration: ".6s",
    position: "relative",

    "&:hover": {
      backgroundColor: "#FFF",
      transitionDuration: ".6s",
    },
  },
  actionIcon: {
    cursor: "pointer",
    fontSize: "16px",
    textAlign: "center",
  },
  reverseIcon: {
    position: "absolute",
    left: "50%",
    transform: "translate(-55%, 0%) scale(-1, 1)",
  },
});

class PublicGroupMain extends Component {
  state = {
    avatarLoading: true,
    avatarUrl: null,
    openSignUp: false,
    signUpTimeout: null,
    tab: 0,
    isPosting: null,
    units: "kms",

    currentImage: null,
    currentImageExtension: null,
    currentGoal: null,
    currentBadge: null,
    currentEpicRace: null,
    type: null,
    postTags: [],
  };

  async componentDidMount() {
    const { match, auth } = this.props;

    const { currentUserGroups } = auth;

    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;

    const { params } = match;
    const { id } = params;

    if (id) {
      this.props.dispatch(getGroup(id, isAdmin));
      this.props.dispatch(getGroupPosts(new Date().getTime(), id, true));
    }

    this.props.dispatch(getTags());
    //this.props.dispatch(getPosts(new Date().getTime(), "", "", false, true));
    //this.props.dispatch(getPublicGroups());
    //this.getAvatarUrl();

    /*this.setState({
      ...this.state,
      signUpTimeout: setTimeout(() => {
        const { auth } = this.props;
        const { isAuthenticated } = auth;
        if (!isAuthenticated && !this.state.signUpTimeout) {
          this.handleOpenSignUp();
        }
      }, 6000),
    });*/

    const { userPlans } = this.props;
    if (userPlans.loadingPlan === false) {
      this.getActivities();
    }

    if (typeof window !== "undefined") {
      this.setState({
        ...this.state,
        units: localStorage.getItem("units"),
      });

      window.addEventListener("storage", () => this.localStorageUpdated());
    }

    const { trailHead } = this.props;
    const { text, image, imageBase64, badge, epicRace } = trailHead;
    if (text && image) {
      this.convertImgToDataURLviaCanvas(image, (base64_data) => {
        this.setState({
          ...this.state,
          currentImage: base64_data,
          currentImageExtension: "png",
          isPosting: "final",
        });
      });
    }
    if (imageBase64) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            currentImage: imageBase64,
            currentImageExtension: "png",
            isPosting: "final",
          }),
        100,
      );
    }
    if (badge) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            currentBadge: badge,
            isPosting: "final",
          }),
        100,
      );
    }
    if (epicRace) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            currentEpicRace: epicRace,
            isPosting: "final",
          }),
        100,
      );
    }
  }

  localStorageUpdated() {
    this.setState({
      ...this.state,
      units: localStorage.getItem("units"),
    });
  }

  async componentDidUpdate(prevProps) {
    const { userPlans } = this.props;
    const { currentPlan, updatingImage } = userPlans;
    const { image } = currentPlan || {};
    /*if (
      (currentPlan &&
        currentPlan.image &&
        (!prevProps.userPlans ||
          !prevProps.userPlans.currentPlan ||
          !prevProps.userPlans.currentPlan.image ||
          prevProps.userPlans.currentPlan.image !== currentPlan.image)) ||
      (prevProps.userPlans.updatingImage && !updatingImage)
    ) {
      const { avatarUrl } = this.state;
      if (image && !avatarUrl) {
        this.setState({
          ...this.state,
          avatarLoading: true,
        });
        this.getAvatarUrl();
      }
    }*/

    if (
      (!prevProps.userPlans.currentPlan ||
        !prevProps.userPlans.currentPlan.strava_athlete_id) &&
      userPlans.currentPlan &&
      userPlans.currentPlan.strava_athlete_id
    ) {
      this.getActivities();
    }

    if (
      (!prevProps.userPlans.currentPlan ||
        !prevProps.userPlans.currentPlan.garmin_oauth_token) &&
      userPlans.currentPlan &&
      userPlans.currentPlan.garmin_oauth_token
    ) {
      this.getActivities();
    }

    if (
      (!prevProps.userPlans.currentPlan ||
        !prevProps.userPlans.currentPlan.suunto_auth) &&
      userPlans.currentPlan &&
      userPlans.currentPlan.suunto_auth
    ) {
      this.getActivities();
    }

    if (
      (!prevProps.userPlans.currentPlan ||
        !prevProps.userPlans.currentPlan.coros_auth) &&
      userPlans.currentPlan &&
      userPlans.currentPlan.coros_auth
    ) {
      this.getActivities();
    }

    const { trailHead } = this.props;
    const { text, image: imageTH, imageBase64, badge, epicRace } = trailHead;

    if (
      (!prevProps.trailHead.text || !prevProps.trailHead.image) &&
      text &&
      imageTH
    ) {
      this.convertImgToDataURLviaCanvas(imageTH, (base64_data) => {
        this.setState({
          ...this.state,
          currentImage: base64_data,
          currentImageExtension: "png",
          isPosting: "final",
        });
      });
    }
    if (!prevProps.trailHead.imageBase64 && imageBase64) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            currentImage: imageBase64,
            currentImageExtension: "png",
            isPosting: "final",
          }),
        100,
      );
    }

    if (!prevProps.trailHead.badge && badge) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            currentBadge: badge,
            isPosting: "final",
          }),
        100,
      );
    }

    if (!prevProps.trailHead.epicRace && epicRace) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            currentEpicRace: epicRace,
            isPosting: "final",
          }),
        100,
      );
    }
  }

  convertImgToDataURLviaCanvas = function(url, callback) {
    var img = new Image();

    img.crossOrigin = "Anonymous";

    img.onload = function() {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      canvas.height = this.height;
      canvas.width = this.width;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      callback(dataURL);
      canvas = null;
    };

    img.src = url;
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleError = (err) => {
    this.props.dispatch(setErrorMessage(err)); //GOES TO SNACK BAR
  };

  handleLoadNextPage = (lastDate) => {
    const { match } = this.props;

    const { params } = match;
    const { id } = params;
    if (id) {
      this.props.dispatch(getGroupPosts(lastDate, id));
    }
  };

  handleAddAllez = (id, commentId = null, replyId = null) => {
    const { auth } = this.props;
    const { currentUser, isAuthenticated } = auth;
    const { attributes } = currentUser || {};
    const { sub, name } = attributes || {};
    if (isAuthenticated) {
      this.props.dispatch(
        addAllez(sub, {
          cognito_user_sub: sub,
          cognito_user_name: name,
          id,
          commentId,
          replyId,
        }),
      );
    } else {
      this.handleOpenSignUp();
    }
  };

  handleReport = (id, reason) => {
    const { auth } = this.props;
    const { currentUser, isAuthenticated } = auth;
    const { attributes } = currentUser || {};
    const { sub, name } = attributes || {};
    if (isAuthenticated) {
      this.props.dispatch(
        addReport(sub, {
          cognito_user_sub: sub,
          cognito_user_name: name,
          id,
          reason,
        }),
      );
    } else {
      this.handleOpenSignUp();
    }
  };

  handleAddComment = (comment) => {
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub, name } = attributes;
    this.props.dispatch(
      addComment(sub, { ...comment, cognito_user_name: name }),
    );
  };

  handleDelete = (type, entry) => {
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;
    switch (type) {
      case "post":
        this.props.dispatch(deletePost(sub, entry));
        break;
      case "comment":
      case "reply":
        this.props.dispatch(deleteComment(sub, entry));
        break;
      default:
        break;
    }
  };

  handleEdit = (editData) => {
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;
    this.props.dispatch(editPost(sub, editData));
  };

  handleBlock = (user) => {
    const { userPlans, auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      const { currentPlan } = userPlans;
      const { cognito_user_sub, blockedUsers } = currentPlan || {};
      this.props.dispatch(
        updatePlan(
          {
            cognito_user_sub,
            blockedUsers: blockedUsers ? [...blockedUsers, user] : [user],
          },
          false,
          false,
        ),
      );
    } else {
      this.handleOpenSignUp();
    }
  };

  toogleMenu = () => {
    this.props.dispatch(toggleOpenMenu());
    this.setState({
      ...this.state,
      isPosting: null,
    });
  };

  handleTextChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.props.dispatch(newPost("", value || "", null));
      }
    }
  };

  getActivities = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    this.props.dispatch(getUserActivities(currentPlan));
  };

  handleCloseAddPost = () => {
    this.setState({
      ...this.state,
      isPosting: this.state.isPosting === "main" ? null : "main",
      currentImage: null,
      currentImageExtension: null,
      currentGoal: null,
      currentBadge: null,
      currentEpicRace: null,
      type: null,
      postTags: [],
    });
    if (this.state.isPosting === "main") {
      this.props.dispatch(cleanNewPost());
      this.props.dispatch(clearCurrentUserActivity());
    }
    if (this.state.isPosting === "final") {
      this.props.dispatch(clearCurrentUserActivity());
    }
  };

  handlePostTagSelect = (event) => {
    this.setState({
      ...this.state,
      postTags: event,
    });
  };

  handleSave = () => {
    const { userPlans, subscriptions, trailHead } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || {};

    const { latestExpired, loadingLatest, latest } = subscriptions || {};
    const { latestSubscriptionFinalizedTime } = currentPlan || {};

    let notSubscribed = false;

    let { selectedPlan } = currentPlan;
    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    const isSubscribed = checkSubscription(
      subscriptions,
      latestSubscriptionFinalizedTime,
    );
    const { text, currentPublicGroup } = trailHead;

    const {
      currentImage,
      currentImageExtension,
      currentGoal,
      currentBadge,
      currentEpicRace,
      type,
      postTags,
    } = this.state;

    const post = {
      title: "",
      text,
      tags: postTags.map((tag) => tag.value),
      link: "",
      image: currentImage,
      imageExtension: currentImageExtension,
      cognito_user_sub,
      crown: isSubscribed,
      goal: currentGoal,
      badge: currentBadge,
      epicRace: currentEpicRace,
      type,
      group: currentPublicGroup.id,
    };

    this.props.dispatch(addPost(cognito_user_sub, post));

    this.setState({
      ...this.state,
      isPosting: null,
      currentImage: null,
      currentImageExtension: null,
      currentGoal: null,
      currentBadge: null,
      currentEpicRace: null,
      type: null,
      postTags: [],
    });
    this.props.dispatch(cleanNewPost());
    this.props.dispatch(clearCurrentUserActivity());
  };

  handleInputChange = async (event) => {
    if (event) {
      const { target } = event;
      const { maxSize, t } = this.props;
      if (target) {
        const { files } = target;
        if (files) {
          const selectedFile = files[0];
          try {
            if (selectedFile) {
              const { size } = selectedFile;
              if (size <= maxSize) {
                if (size > 1024000) {
                  reduceFileSize(
                    selectedFile,
                    1024000,
                    1000,
                    Infinity,
                    0.9,
                    async (fixedFile) => {
                      fixedFile.name = selectedFile.name;
                      const {
                        image: imageString,
                        extension,
                      } = await this.getBase64(fixedFile);

                      this.setState({
                        ...this.state,
                        currentImage: imageString,
                        currentImageExtension: extension,
                        isPosting: "final",
                      });
                    },
                  );
                } else {
                  const {
                    image: imageString,
                    extension,
                  } = await this.getBase64(selectedFile);

                  this.setState({
                    ...this.state,
                    currentImage: imageString,
                    currentImageExtension: extension,
                    isPosting: "final",
                  });
                }
              } else {
                this.handleError(
                  t("Sorry, your image needs to be smaller than maxSize10", {
                    ns: "messages",
                  }),
                );
              }
            } else {
              //if (onError) {
              this.handleError(t("Invalid File", { ns: "messages" }));
              //}
            }
          } catch (exp) {
            this.handleError(exp);
          }
        }
      }
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const image = await jimp.read(reader.result);
          //await image.resize(150, jimp.AUTO);
          const fileNameArray = file.name ? file.name.split(".") : undefined;
          const extension = fileNameArray
            ? fileNameArray[fileNameArray.length - 1]
            : undefined;
          const data = await image.getBase64Async(
            extension === "png" ? jimp.MIME_PNG : jimp.MIME_JPEG,
          );
          resolve({
            image: data,
            extension,
          });
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleGoalPost = (goal) => {
    this.setState({
      ...this.state,
      currentGoal: goal,
      isPosting: "final",
    });
  };

  handleBadgePost = (badge) => {
    this.setState({
      ...this.state,
      currentBadge: badge,
      isPosting: "final",
    });
  };

  handleClose = () => {
    this.props.dispatch(clearGroup());

    if (history.length < 3) {
      history.replace("/trailhead");
    } else {
      history.goBack();
    }
  };

  handleJoin = (ev, id) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || {};
    this.props.dispatch(joinGroup(cognito_user_sub, id));
  };

  handleAcceptJoinGroup = (cognito_user_sub, id) => {
    this.props.dispatch(acceptJoinGroup(cognito_user_sub, id));
  };

  handleLeave = (ev, id) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || {};
    this.props.dispatch(leaveGroup(cognito_user_sub, id));
  };

  render() {
    const {
      classes,
      trailHead,
      userPlans,
      t,
      i18n,
      auth,
      width,

      strava,
      garmin,
      suunto,
      vertActivities,
      coros,
    } = this.props;

    const { currentUser, currentUserGroups, isAuthenticated } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes || {};

    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;

    const {
      currentPublicGroup,
      joiningPublicGroup,
      loadingPublicGroup,

      tags: tagOptions,
      addingAllezOn,
      addingCommentOn,

      text,
      image,

      currentGroupPosts,
      loadingGroupPosts,
    } = trailHead;

    const { data } = currentGroupPosts;

    data.sort((a, b) => b.created_at - a.created_at);

    const {
      image: groupImage,
      name,
      description,
      numPosts = 0,
      numMembers = 0,
      id,
      members,
      goal,
      goal_type,
      month,
      isPrivate,
      requests,
      requestsFull,
    } = currentPublicGroup || {};

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const { currentPlan } = userPlans;
    const {
      cognito_user_sub,
      loading,
      successMessage,
      errorMessage,
      blockedUsers,
    } = currentPlan || {};

    const joined =
      members && members.filter((p) => p === cognito_user_sub).length;

    const requested =
      requests && requests.filter((p) => p === cognito_user_sub).length;

    const {
      searchPhrase,
      tags,
      showReported,
      isSearchingWithPhrase,
      isSearchingWithFilters,
      avatarUrl,
      openSignUp,
      tab,
      isPosting,
      units,
      currentImage,
      currentGoal,
      currentBadge,
      currentEpicRace,
      postTags,
    } = this.state;

    const langTags = tagOptions
      ? tagOptions.map((tag) => {
          return {
            label: tag.name[languageDetector]
              ? tag.name[languageDetector]
              : tag.name["en"],
            value: tag.id,
          };
        })
      : [];

    const { strava_athlete_id, garmin_oauth_token, suunto_auth, coros_auth } =
      currentPlan || "";

    const { activities: activitiesStrava, loading: loadingStrava } = strava;
    const { activities: activitiesGarmin, loading: loadingGarmin } = garmin;
    const { activities: activitiesSuunto, loading: loadingSuunto } = suunto;
    const { activities: activitiesCoros, loading: loadingCoros } = coros;
    const { activities: activitiesVert, loading: loadingVert } = vertActivities;

    const activities =
      activitiesGarmin && activitiesGarmin.length
        ? activitiesGarmin
        : activitiesSuunto && activitiesSuunto.length
        ? activitiesSuunto
        : activitiesCoros && activitiesCoros.length
        ? activitiesCoros
        : activitiesStrava && activitiesStrava.length
        ? activitiesStrava
        : activitiesVert && activitiesVert.length
        ? activitiesVert
        : [];

    const loadingActivities =
      loadingStrava ||
      loadingGarmin ||
      loadingSuunto ||
      loadingVert ||
      loadingCoros;

    const noTracker =
      !loading &&
      !strava_athlete_id &&
      !garmin_oauth_token &&
      !suunto_auth &&
      !coros_auth &&
      (!activitiesVert || !activitiesVert.length);

    const from = "kms";

    const h = goal ? Math.floor(goal / 3600) : 0;
    const m = goal ? Math.floor((goal % 3600) / 60) : 0;
    const timeStr = `${h < 10 ? `0${h}` : h} ${t("hours")}`;

    let totalDone = 0;
    let yourDone = 0;
    let yourDoneTimeStr = 0;

    if (goal_type === "distance") {
      totalDone =
        month && month.entries && month.entries.length
          ? month.entries.reduce(
              (acc, curr) => acc + curr.accumulatedDistance,
              0,
            )
          : 0;

      yourDone =
        month && month.entries && month.entries.length
          ? month.entries
              .filter((e) => e.cognito_user_sub === cognito_user_sub)
              .reduce((acc, curr) => acc + curr.accumulatedDistance, 0)
          : 0;
    }

    if (goal_type === "elevation") {
      totalDone =
        month && month.entries && month.entries.length
          ? month.entries.reduce(
              (acc, curr) => acc + curr.accumulatedElevation,
              0,
            )
          : 0;

      yourDone =
        month && month.entries && month.entries.length
          ? month.entries
              .filter((e) => e.cognito_user_sub === cognito_user_sub)
              .reduce((acc, curr) => acc + curr.accumulatedElevation, 0)
          : 0;
    }

    if (goal_type === "time") {
      totalDone =
        month && month.entries && month.entries.length
          ? month.entries.reduce((acc, curr) => acc + curr.accumulatedTime, 0)
          : 0;

      yourDone =
        month && month.entries && month.entries.length
          ? month.entries
              .filter((e) => e.cognito_user_sub === cognito_user_sub)
              .reduce((acc, curr) => acc + curr.accumulatedTime, 0)
          : 0;

      const h = yourDone ? Math.floor(yourDone / 3600) : 0;
      const m = yourDone ? Math.floor((yourDone % 3600) / 60) : 0;
      yourDoneTimeStr = `${h < 10 ? `0${h}` : h}h${
        m < 10 ? `0${m}` : m
      }min${yourDone - h * 3600 - m * 60}${t("sec")}`;
    }

    const items = data.map((post) => {
      if (
        !blockedUsers ||
        !blockedUsers.find((b) => b === post.cognito_user_sub)
      ) {
        return (
          <Post
            userImg={avatarUrl}
            key={post.id}
            postData={post}
            tags={tagOptions}
            onAddAllez={this.handleAddAllez}
            userSub={sub}
            onAddComment={this.handleAddComment}
            onTagClick={this.handleTagSelect}
            onDelete={this.handleDelete}
            onEdit={this.handleEdit}
            onReport={this.handleReport}
            onBlock={this.handleBlock}
            isAdmin={false}
            onError={this.handleError}
            addingAllezOn={addingAllezOn}
            addingCommentOn={addingCommentOn}
            hasSub={false}
            isAuthenticated={isAuthenticated}
            openSignUp={this.handleOpenSignUp}
          ></Post>
        );
      }
      return <span key={post.id}></span>;
    });

    return (
      <div>
        <Drawer
          open={true}
          anchor="right"
          onClose={this.handleClose}
          classes={{
            paper: classes.drawerRoot,
          }}
        >
          <div className={classes.stickyIcon} onClick={this.handleClose}>
            <div className={classes.iconParent}>
              <ArrowForwardIosIcon
                className={classNames(classes.actionIcon, classes.reverseIcon)}
              />
            </div>
          </div>
          {loadingPublicGroup ? (
            <div className={classes.loadingContainer}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <InfiniteScroll
              dataLength={items.length} //This is important field to render the next data
              next={() => {
                data &&
                  data.length > 0 &&
                  this.handleLoadNextPage(data[data.length - 1].created_at);
              }}
              hasMore={true}
              scrollableTarget="thContainer"
              height={window.innerHeight}
              style={{
                marginTop: -36,
              }}
            >
              <div className={classes.container}>
                <div className={classes.topContainer}>
                  <div className={classes.imageContainer}>
                    <img className={classes.image} src={groupImage} alt="" />
                  </div>
                  <div className={classes.infoContainer}>
                    <div className={classes.flexContainer}>
                      <Typography variant="h6" className={classes.title}>
                        {name && name[languageDetector]
                          ? name[languageDetector]
                            ? name[languageDetector]
                            : name["en"]
                          : ""}
                      </Typography>

                      {!isAuthenticated ? (
                        ""
                      ) : joiningPublicGroup ? (
                        <CircularProgress
                          variant="indeterminate"
                          color="primary"
                          className={classes.loading}
                          data-testid="LoadingUser"
                        />
                      ) : (!joined && !isPrivate) ||
                        (!requested && !joined && isPrivate) ? (
                        <Button
                          color="primary"
                          className={classes.joinButton}
                          onClick={(ev) => this.handleJoin(ev, id, isPrivate)}
                          variant="outlined"
                        >
                          {isPrivate ? t("Request to join") : t("Join")}
                        </Button>
                      ) : requested && !joined && isPrivate ? (
                        <Button
                          color="primary"
                          className={classes.joinButton}
                          variant="outlined"
                          disabled
                        >
                          {t("Requested")}
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          className={classes.joinButton}
                          onClick={(ev) => this.handleLeave(ev, id)}
                          variant="outlined"
                        >
                          {t("Leave")}
                        </Button>
                      )}
                    </div>
                    <Typography variant="body1" className={classes.description}>
                      {description && description[languageDetector]
                        ? description[languageDetector]
                          ? description[languageDetector]
                          : description["en"]
                        : ""}
                    </Typography>
                    <Typography variant="body1" className={classes.stats}>
                      {`${numMembers} ${t("members")} • ${numPosts} ${t(
                        "posts",
                      )}`}
                    </Typography>

                    {goal && goal_type ? (
                      <div className={classes.goalContainer}>
                        <div className={classes.goalTextContainer}>
                          <Typography variant="h6" className={classes.goalText}>
                            {t("Goal of the month")}
                          </Typography>

                          <Typography variant="h6" className={classes.goalText}>
                            {goal_type === "distance"
                              ? `${round(
                                  convertUnits(
                                    goal / 1000,
                                    from,
                                    units,
                                    "distance",
                                  ),
                                  0,
                                )} ${units === "miles" ? t("miles") : t("km")}`
                              : goal_type === "elevation"
                              ? `${round(
                                  convertUnits(goal, from, units, "distance"),
                                  0,
                                )} ${units === "miles" ? t("feet") : t("m")}`
                              : timeStr}
                          </Typography>
                        </div>

                        <div className={classes.progressBarContainer}>
                          <LinearProgress
                            variant="determinate"
                            value={Math.min(
                              100,
                              totalDone ? (totalDone * 100) / goal : 0,
                            )}
                            classes={{
                              root: classes.progressRoot,
                              bar: classes.progressBar,
                            }}
                          />
                          <Typography
                            variant="body1"
                            className={classes.progressLabel}
                            style={{
                              left: `${Math.min(
                                91,
                                totalDone
                                  ? Math.round((totalDone * 100) / goal)
                                  : 0,
                              )}%`,
                            }}
                          >
                            {Math.min(
                              100,
                              totalDone
                                ? Math.round((totalDone * 100) / goal)
                                : 0,
                            )}
                            %
                          </Typography>
                        </div>

                        {joined ? (
                          <Typography
                            variant="h6"
                            className={classes.goalAdded}
                            dangerouslySetInnerHTML={{
                              __html: t(
                                "You have added {{value}}{{unit}} ({{percentage}}%)",
                                {
                                  value:
                                    goal_type === "distance"
                                      ? round(
                                          convertUnits(
                                            yourDone / 1000,
                                            "kms",
                                            units,
                                            "distance",
                                          ),
                                          1,
                                        )
                                      : goal_type === "elevation"
                                      ? round(
                                          convertUnits(
                                            yourDone,
                                            "kms",
                                            units,
                                            "elevation",
                                          ),
                                          1,
                                        )
                                      : yourDoneTimeStr,
                                  unit:
                                    goal_type === "distance"
                                      ? units === "miles"
                                        ? t("miles")
                                        : t("km")
                                      : goal_type === "elevation"
                                      ? units === "miles"
                                        ? t("feet")
                                        : t("m")
                                      : "",
                                  percentage: Math.min(
                                    100,
                                    yourDone
                                      ? round((yourDone * 100) / goal, 1)
                                      : 0,
                                  ),
                                },
                              ),
                            }}
                          ></Typography>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {isPosting && (
                      <div className={classes.searchBar}>
                        {isPosting && (
                          <div className={classes.tabs}>
                            <Typography
                              variant="body1"
                              className={classes.tabSelected}
                              style={{ cursor: "initial" }}
                            >
                              {isPosting === "activities"
                                ? t("ACTIVITIES TO SHARE")
                                : isPosting === "goal"
                                ? t("GOALS TO SHARE")
                                : isPosting === "badge"
                                ? t("BADGES TO SHARE")
                                : t("COMPOSING")}
                            </Typography>
                          </div>
                        )}

                        {isPosting && (
                          <img
                            src={`${process.env.PUBLIC_URL}/close.svg`}
                            alt=""
                            className={classes.iconSearch}
                            onClick={() => this.handleCloseAddPost()}
                          />
                        )}
                      </div>
                    )}

                    {joined &&
                    isPosting !== "activities" &&
                    isPosting !== "goal" &&
                    isPosting !== "badge" &&
                    tab === 0 ? (
                      <TextField
                        value={text ? text : ""}
                        margin="normal"
                        variant="outlined"
                        placeholder={t("What are you up to?")}
                        onChange={this.handleTextChange}
                        className={classes.postText}
                        fullWidth
                        inputProps={{ maxLength: 2200 }}
                        onFocus={() =>
                          this.setState({
                            ...this.state,
                            isPosting: isPosting ? isPosting : "main",
                          })
                        }
                        multiline={true}
                        InputProps={{
                          classes: {
                            root: classes.round,
                            input: classes.postText,
                            underline: classes.outline,
                          },
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icon-new-post.svg`}
                                alt=""
                                className={classes.closeIcon}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      ""
                    )}

                    {isPosting === "main" && (
                      <div className={classes.postOptions}>
                        <div className={classes.postOptionsRow}>
                          <div
                            className={classes.postOption}
                            onClick={() => {
                              this.props.dispatch(clearCurrentUserActivity());
                              this.setState({
                                ...this.state,
                                isPosting: "activities",
                              });
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icon-post-activity.svg`}
                              alt=""
                              className={classes.postOptionIcon}
                            />

                            <Typography
                              variant="body1"
                              className={this.postOptionText}
                            >
                              {t("Activity")}
                            </Typography>
                          </div>

                          <div
                            className={classes.postOption}
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                isPosting: "goal",
                              })
                            }
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icon-post-goal.svg`}
                              alt=""
                              className={classes.postOptionIcon}
                            />

                            <Typography
                              variant="body1"
                              className={this.postOptionText}
                            >
                              {t("Goal")}
                            </Typography>
                          </div>

                          <div
                            className={classes.postOption}
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                isPosting: "final",
                                type: "question",
                              })
                            }
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icon-post-question.svg`}
                              alt=""
                              className={classes.postOptionIcon}
                            />

                            <Typography
                              variant="body1"
                              className={this.postOptionText}
                            >
                              {t("Question")}
                            </Typography>
                          </div>
                        </div>

                        <div
                          className={classes.postOptionsRow}
                          style={{ marginTop: 10 }}
                        >
                          <div
                            className={classes.postOption}
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                isPosting: "final",
                              })
                            }
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icon-post-text.svg`}
                              alt=""
                              className={classes.postOptionIcon}
                            />

                            <Typography
                              variant="body1"
                              className={this.postOptionText}
                            >
                              {t("Text")}
                            </Typography>
                          </div>

                          <div className={classes.postOption}>
                            <input
                              accept="image/*"
                              className={classes.input}
                              id="contained-button-file-2"
                              type="file"
                              onChange={this.handleInputChange}
                            />
                            <label
                              htmlFor="contained-button-file-2"
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icon-post-image.svg`}
                                alt=""
                                className={classes.postOptionIcon}
                              />

                              <Typography
                                variant="body1"
                                className={this.postOptionText}
                              >
                                {t("Image")}
                              </Typography>
                            </label>
                          </div>

                          <div
                            className={classes.postOption}
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                isPosting: "badge",
                              })
                            }
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icon-post-badge.svg`}
                              alt=""
                              className={classes.postOptionIcon}
                            />

                            <Typography
                              variant="body1"
                              className={this.postOptionText}
                            >
                              {t("Badge")}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}

                    {isPosting === "activities" &&
                      (loadingActivities ? (
                        <div className={classes.loading}>
                          <CircularProgress variant="indeterminate" />
                        </div>
                      ) : (
                        <DashboardRecentActivities
                          activities={activities}
                          units={units}
                          noTracker={noTracker}
                          isColumn={true}
                        ></DashboardRecentActivities>
                      ))}

                    {isPosting === "goal" && (
                      <DashboardNextRaceDetails
                        isTrailhead={true}
                        onEdit={this.handleGoalPost}
                      ></DashboardNextRaceDetails>
                    )}

                    {isPosting === "badge" && (
                      <FinishedChallenges
                        onShareChallenge={this.handleBadgePost}
                      ></FinishedChallenges>
                    )}

                    {isPosting === "final" ? (
                      <>
                        {currentImage ? (
                          <div className={classes.postImageContainer}>
                            <img
                              src={currentImage}
                              alt=""
                              className={classes.postImage}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {currentGoal ? (
                          <DashboardNextRacePane
                            nextRace={currentGoal}
                            userPlans={userPlans}
                            editing={false}
                            onEdit={null}
                            onSave={null}
                            onDelete={null}
                            isTrailhead={true}
                          ></DashboardNextRacePane>
                        ) : (
                          ""
                        )}

                        {currentBadge ? (
                          <FinishedChallengeH
                            yourEntry={currentBadge}
                            onShareChallenge={null}
                            units={this.state.units}
                          ></FinishedChallengeH>
                        ) : (
                          ""
                        )}

                        {currentEpicRace ? (
                          <RaceCard item={currentEpicRace}></RaceCard>
                        ) : (
                          ""
                        )}

                        <AutoCompleteMultiSelect
                          suggestions={langTags}
                          placeholder={t("Tags placeholder")}
                          isClearable={true}
                          value={postTags}
                          onChange={(event) => this.handlePostTagSelect(event)}
                          disable={false}
                        />

                        <div className={classes.publishButtonContainer}>
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.publishButton}
                            onClick={this.handleSave}
                            disabled={!text}
                          >
                            {t("Publish")}
                          </Button>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {isAdmin && requestsFull && requestsFull.length ? (
                  <PublicGroupRequests
                    requests={requestsFull}
                    onAccept={(cognito_user_sub) =>
                      this.handleAcceptJoinGroup(cognito_user_sub, id)
                    }
                  />
                ) : (
                  ""
                )}

                {!isPosting && tab === 0 && (!isPrivate || joined) ? (
                  <div className={classes.posts} id="posts">
                    {items}

                    {loadingGroupPosts && (
                      <div className={classes.loading}>
                        <CircularProgress variant="indeterminate" />
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </InfiniteScroll>
          )}
        </Drawer>
      </div>
    );
  }
}

PublicGroupMain.defaultProps = {
  maxSize: 10240000,
};

export default connect((store) => {
  return {
    auth: store.auth,
    trailHead: store.trailHead,
    userPlans: store.userPlans,
    subscriptions: store.subscriptions,

    strava: store.strava,
    garmin: store.garmin,
    suunto: store.suunto,
    vertActivities: store.vertActivities,
    coros: store.coros,
  };
})(
  withTranslation("trailhead")(
    withWidth()(withStyles(styles, { withTheme: true })(PublicGroupMain)),
  ),
);
