import React from "react";
import { useTrainingContext } from "../context/TrainingContext";
import useUserInfo from "./useUserInfo";
import bugsnagClient from "../lib/bugsnag-client";
import { getCustomLevel, getPlanLevel } from "../lib/plan-helper";
import { convertToRTFForView, convertStringToHTML } from "../lib/rtf-helper";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RateApp } from "capacitor-rate-app";
import { logEvent } from "../lib/events-helper";
import { createAnalyticData } from "../lib/analytic-helper";
import { pushAnalyticData } from "../actions/analytic-action";
import { currentTrainingUpdateWorkoutStatusAndEnergy } from "../actions/user-plans-action";
export const NOT_WORKOUT = "NoWorkout";

const useWorkout = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { language } = i18n;
  const { userPlans, subscriptions, auth, currentTraining } = useUserInfo();
  const { loadingmarksAsDone } = userPlans;
  const { date, isChangeDate, dateFrom, dateTo } = useTrainingContext();
  const { days = [], chainedPlans } = currentTraining || {};
  const [workoutToday, setWorkoutToday] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [level, setLevel] = React.useState(0);
  const [parametrizedWorkout, setParametrizedWorkout] = React.useState(null);
  const [rpe, setRpe] = React.useState(0);
  const [fatigue, setFatigue] = React.useState(0);
  const [openVideo, setOpenVideo] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState(null);
  const [isDone, setIsDone] = React.useState(false);
  const [workoutSelected, setWorkoutSelected] = React.useState(null);
  const [isOpenWorkout, setIsOpenWorkout] = React.useState(false);
  const [hasNextDay, setHasNextDay] = React.useState(false);
  const [hasPreviousDay, setHasPreviousDay] = React.useState(false);

  const handleChangeFatigue = (value) => {
    setFatigue(Number(value));
  };
  const handleChangeRpe = (value) => {
    setRpe(Number(value));
  };
  const handleOpenVideo = () => {
    setOpenVideo((prev) => !prev);
  };

  const getVideoUrl = (description) => {
    const body = convertStringToHTML(description);
    const child = body.getElementsByTagName("figure");

    if (child && child.length) {
      const url = [...child].map((children) => {
        const htmlString = children && children.innerHTML.split('"');
        return htmlString[1];
      });
      return url;
    }

    return [];
  };

  const formatDate = (dateSelected) => {
    try {
      const day = dateSelected
        .getDate()
        .toString()
        .padStart(2, "0");
      const month = (dateSelected.getMonth() + 1).toString().padStart(2, "0"); // add one more because in js month is between 0 to 11
      const year = dateSelected.getFullYear();
      return `${day}/${month}/${year}`;
    } catch (error) {
      bugsnagClient.notify(error);
    }
  };

  const getFixCustomLevel = (plannedDate) => {
    if (!plannedDate) return "";

    return !isNaN(getCustomLevel(plannedDate, chainedPlans))
      ? getCustomLevel(plannedDate, chainedPlans)
      : getPlanLevel(currentTraining);
  };

  const assignWorkoutToday = (days = [], selectedDate) => {
    try {
      if (!selectedDate) {
        setWorkoutToday(null);
        return false;
      }

      if (!days.length && !days.length > 0) {
        setWorkoutToday(null);
        return false;
      }
      const trainingDaysNullFiltered = days.filter((day) => day != null);
      const selectedDateFormatted = formatDate(selectedDate);
      const findWorkoutDay = trainingDaysNullFiltered.find((day) => {
        const [d] = day;
        return d.plannedDate == selectedDateFormatted;
      });

      if (findWorkoutDay && findWorkoutDay.length) {
        const [findWorkoutToday] = findWorkoutDay;
        setWorkoutToday(findWorkoutToday);
      } else {
        setWorkoutToday(null);
      }

      // Verify next days
      const hasNextDayWorkout = trainingDaysNullFiltered.some((day) => {
        const [d] = day;
        const dayDate = new Date(d.plannedDate);
        return dayDate > new Date(selectedDate);
      });

      // Verify past days
      const hasPreviousDayWorkout = trainingDaysNullFiltered.some((day) => {
        const [d] = day;
        const dayDate = new Date(d.plannedDate);
        return dayDate < new Date(selectedDate);
      });

      setHasPreviousDay(hasPreviousDayWorkout);
      setHasNextDay(hasNextDayWorkout);
    } catch (error) {
      bugsnagClient.notify(error);
    }
    setLoading(false);
  };

  const makeWorkoutDetails = () => {
    if (!workoutToday) return false;

    const {
      plannedDate,
      parametrizedWorkout,
      description,
      visualEnergy = 0,
      visualRpe = 0,
      isDone = false,
    } = workoutToday;

    // set custom level
    setLevel(getFixCustomLevel(plannedDate));

    if (parametrizedWorkout) {
      setParametrizedWorkout(
        parametrizedWorkout[getFixCustomLevel(plannedDate)],
      );
    }

    // set workout description without video
    setDescription(
      convertToRTFForView(
        description &&
          deleteVideo(
            description && description[language]
              ? description[language]
              : description && description["en"],
          ),
        0,
      ),
      setLoading(false),
    );

    // set workout videoUrl without text
    const video = getVideoUrl(
      description && description[language]
        ? description[language]
        : description && description["en"],
    );
    setVideoUrl(video);

    // set Fatigue
    setFatigue(visualEnergy);

    // set RPE
    setRpe(visualRpe);

    // set is Done
    setIsDone(isDone);
  };

  const deleteVideo = (plain) => {
    const body = convertStringToHTML(plain);
    const child = body.getElementsByTagName("figure");
    if (child && child.length) {
      [...child].forEach((children) => {
        body.removeChild(children);
      });
    }
    const stringBody = body.innerHTML;
    return stringBody;
  };

  const setWorkoutStatusAndEnergy = async (
    workout,
    markAsDone,
    rpe = 0,
    fatigue = 0,
  ) => {
    try {
      const { currentPlan } = userPlans;
      const { currentTraining } = currentPlan;
      const { id } = workout;
      const rpeRealValue = normalizeValue(rpe);
      const realEnergyValue = normalizeEnergyValue(fatigue);

      let savedWorkout;
      if (markAsDone) {
        savedWorkout = {
          ...workout,
          workoutId: id,
          cognito_user_sub: currentPlan.cognito_user_sub,
          energyLevel: realEnergyValue,
          visualEnergy: fatigue,
          rpe: rpeRealValue,
          visualRpe: rpe,
          isDone: markAsDone,
        };
      } else {
        const {
          visualEnergy = 0,
          visualRpe = 0,
          ...workoutWithoutQuestionnaire
        } = workout;

        setFatigue(visualEnergy);
        setRpe(visualRpe);
        savedWorkout = {
          ...workoutWithoutQuestionnaire,
          workoutId: id,
          cognito_user_sub: currentPlan.cognito_user_sub,
          isDone: markAsDone,
        };
      }

      dispatch(
        currentTrainingUpdateWorkoutStatusAndEnergy(
          savedWorkout,
          currentTraining,
          0,
          false,
          false,
        ),
      );

      return true;
    } catch (error) {
      bugsnagClient.notify(error);
      return false;
    }
  };

  const workoutIsDoneChange = async (
    workout,
    rpe = 0,
    fatigue = 0,
    markAsDone,
  ) => {
    const { updatingWorkoutId, currentPlan } = userPlans;
    const { currentUser } = auth || {};
    const { attributes } = currentUser || {};
    const { email } = attributes || {};
    const { numDone } = currentPlan;

    if (!workout) {
      const workoutParam = new Error("Error to get workout for update");
      bugsnagClient.notify(workoutParam);
      return false;
    }

    if (updatingWorkoutId) {
      const updating = new Error("Error to update workout");
      bugsnagClient.notify(updating);
      return false;
    }

    const resolveWorkoutDone = await setWorkoutStatusAndEnergy(
      workout,
      markAsDone,
      rpe,
      fatigue,
    );

    if (resolveWorkoutDone) {
      logEvent("mark_as_done");

      const analyticData = createAnalyticData(
        {
          username: email,
          userPlans,
          subscriptions,
        },
        "mark as done",
      );

      dispatch(pushAnalyticData(analyticData));
      if (numDone && numDone === 2) {
        RateApp.requestReview();
      }
    }

    return resolveWorkoutDone;
  };

  const openWorkout = (workout) => {
    if (workout.workoutType === NOT_WORKOUT) return false;
    setWorkoutSelected(workout);
    setIsOpenWorkout(true);
  };
  const onCloseOpenWorkout = () => {
    setIsOpenWorkout(false);
  };

  const normalizeEnergyValue = (value) => {
    if (value == 0) return 100;
    if (value > 0 && value <= 1) return 90;
    if (value > 1 && value <= 2) return 80;
    if (value > 2 && value <= 3) return 70;
    if (value > 3 && value <= 4) return 60;
    if (value > 4 && value <= 5) return 50;
    if (value > 5 && value <= 6) return 40;
    if (value > 6 && value <= 7) return 30;
    if (value > 7 && value <= 8) return 20;
    if (value > 8 && value <= 9) return 10;
    if (value > 9 && value <= 10) return 0;
  };

  const normalizeValue = (value) => {
    if (value == 0) return 0;
    if (value <= 2) return 2;
    if (value > 2 && value <= 4) return 40;
    if (value > 4 && value <= 6) return 60;
    if (value > 6 && value <= 8) return 80;
    if (value > 8 && value <= 10) return 100;
  };

  const resetAllWorkoutData = () => {
    setDescription(null);
    setLevel(0);
    setParametrizedWorkout(null);
    setRpe(0);
    setFatigue(0);
    setVideoUrl(null);
    setIsDone(false);
  };

  React.useEffect(() => {
    setLoading(true);
    if (days) {
      resetAllWorkoutData();
      assignWorkoutToday(days, dateFrom);
    }
  }, [days, dateFrom, dateTo]);

  React.useEffect(() => {
    if (workoutToday) makeWorkoutDetails();
  }, [workoutToday]);

  return {
    description,
    date,
    isChangeDate,
    isDone,
    days,
    chainedPlans,
    workoutToday,
    level,
    parametrizedWorkout,
    rpe,
    fatigue,
    openVideo,
    videoUrl,
    workoutSelected,
    isOpenWorkout,
    hasNextDay,
    hasPreviousDay,
    openWorkout,
    onCloseOpenWorkout,
    setWorkoutToday,
    setIsDone,
    handleChangeFatigue,
    handleChangeRpe,
    handleOpenVideo,
    getVideoUrl,
    workoutIsDoneChange,
    formatDate,
    loading,
    getFixCustomLevel,
    loadingmarksAsDone,
  };
};

export default useWorkout;
