import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Capacitor } from "@capacitor/core";
import { updatePlan } from "../actions/user-plans-action";
import LocalNotificationsService from "../services/local-notifications-service";
import { setErrorMessage } from "../actions/subscriptions-action";
import { useTranslation } from "react-i18next";
import useUserInfo from "./useUserInfo";
import moment from "moment";
import useCreatePost from "./useCreatePost";
import { logEvent, setEventsMetadata } from "../lib/events-helper";

const useRaceList = () => {
  const formTypeLabel = {
    add: "profile.races.form.add",
    edit: "profile.races.form.edit",
  };

  const { t } = useTranslation("profile");
  const dispatch = useDispatch();
  const { handleSetGoal } = useCreatePost();
  const { userPlans, currentPlan, nextRaces } = useUserInfo();
  const [loading, setLoading] = useState(true);
  const [races, setRaces] = useState(null);
  const [selectValue, setSelectValue] = useState(1);
  const [raceToEdit, setRaceToEdit] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [formType, setFormType] = useState("add");
  const [titleForm, setTitleForm] = useState("profile.races.form.add");
  const [goalPostPreview, setGoalPostPreview] = useState(false);
  const handleCloseGoalPreview = () => setGoalPostPreview(false);

  const handleFilter = (event) => {
    const {
      target: { value },
    } = event;

    setSelectValue(value);

    if (value === 0) return setRaces(nextRaces);
    const races = (nextRaces || []).filter((item) => {
      const raceDateMoment = moment(item.raceDate, "DD/MM/YYYY");
      const current = new Date();
      const currentDate = Date.now();
      const midnight = new Date().setHours(24, 0, 0, 0);
      const difference = raceDateMoment.diff(current, "days");

      if (
        value == 2 &&
        raceDateMoment.valueOf() < currentDate &&
        difference < 0
      )
        return item;

      if (value == 1) {
        if (difference === 0) {
          if (raceDateMoment.valueOf() < midnight) return item;
        }

        if (raceDateMoment.valueOf() > currentDate) return item;
      }
    });

    const allRaces = sortRacesData(
      races,
      value == 2 ? "descending" : "ascending",
    );

    setRaces(allRaces);
  };

  const nextRacesFilter = () => {
    setRaces([]);
    setLoading(true);
    const races = (nextRaces || []).filter((item) => {
      const raceDateMoment = moment(item.raceDate, "DD/MM/YYYY");
      const current = new Date();
      const currentDate = Date.now();
      const midnight = new Date().setHours(24, 0, 0, 0);
      const difference = raceDateMoment.diff(current, "days");

      if (difference === 0) {
        if (raceDateMoment.valueOf() < midnight) return item;
      }

      if (raceDateMoment.valueOf() > currentDate) return item;
    });

    const allRaces = sortRacesData(races, "ascending");
    setRaces(allRaces);
    setLoading(false);
  };

  const sortRacesData = useMemo(() => (data = [], orderBy = "descending") => {
    const ordered = data.sort((a, b) => {
      const itemA = moment(a.raceDate, "DD/MM/YYYY").valueOf();
      const itemB = moment(b.raceDate, "DD/MM/YYYY").valueOf();
      return orderBy == "descending" ? itemB - itemA : itemA - itemB || [];
    });
    return ordered;
  });

  const handleSave = (race) => {
    if (
      race.raceName &&
      race.raceDistance &&
      race.raceElevation &&
      race.raceDate &&
      race.racePriority
    ) {
      const { currentPlan } = userPlans;
      const { nextRaces, nextRace } = currentPlan;
      const newRaceId =
        race.raceId !== null ? race.raceId : new Date().getTime();
      const newNextRaces = nextRaces
        ? nextRaces.filter((r) => r.raceId !== newRaceId)
        : [];
      newNextRaces.push({ ...race, raceId: newRaceId });

      if (nextRace && formType === "add") {
        newNextRaces.push({ ...nextRace, raceId: new Date().getTime() });
      }

      setRaces(newNextRaces);

      dispatch(
        updatePlan(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            nextRace: null,
            nextRaces: newNextRaces,
          },
          true, //show success
          false, //don't redirect
        ),
      );

      setOpenForm(false);

      if (Capacitor.isNativePlatform()) {
        LocalNotificationsService.cancelRaceNotifications([
          race.raceId.toString() + "0",
          race.raceId.toString() + "1",
        ]);

        const raceDateMoment = moment(race.raceDate, "DD/MM/YYYY");
        raceDateMoment.hours(12);
        raceDateMoment.minutes(0);
        raceDateMoment.seconds(0);
        raceDateMoment.milliseconds(0);

        const raceBefore = moment(raceDateMoment).subtract(1, "days");
        const raceAfter = moment(raceDateMoment).add(1, "days");
        LocalNotificationsService.scheduleRaceNotifications(
          [+(race.raceId.toString() + "0"), +(race.raceId.toString() + "1")],
          [
            new Date(raceBefore.unix() * 1000),
            new Date(raceAfter.unix() * 1000),
          ],
          [t("raceBefore"), t("raceAfter")],
        );
      }

      setFormType(null);
      setTitleForm(null);
      setRaceToEdit({});

      setEventsMetadata({ customer_id: currentPlan.cognito_user_sub });
      logEvent("add_race");
    } else {
      dispatch(setErrorMessage("Please fill out all your race details"));
    }
  };

  const handleDelete = (raceId) => {
    const { nextRaces, nextRace } = currentPlan;

    let newNextRaces = [];
    if (nextRaces) {
      newNextRaces = nextRaces.filter((r) => r.raceId !== raceId);
    }

    if (nextRace && raceId !== "old") {
      newNextRaces.push({ ...nextRace, raceId: new Date().getTime() });
    }

    setRaces(newNextRaces);

    dispatch(
      updatePlan(
        {
          cognito_user_sub: currentPlan.cognito_user_sub,
          nextRace: null,
          nextRaces: newNextRaces,
        },
        false,
      ),
    );

    if (Capacitor.isNativePlatform()) {
      LocalNotificationsService.cancelRaceNotifications([
        raceId.toString() + "0",
        raceId.toString() + "1",
      ]);
    }
  };

  const shareRace = (race) => {
    handleSetGoal(race);
    setGoalPostPreview(true);
  };

  const handleOpenForm = (type = "add") => {
    setOpenForm(true);
    setFormType(type);
    setTitleForm(formTypeLabel[type] ? formTypeLabel[type] : "");
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormType(null);
    setTitleForm(null);
    setRaceToEdit({});
  };

  const editRace = (race) => {
    handleOpenForm("edit");
    setRaceToEdit(race);
  };

  useEffect(() => {
    setLoading(true);
    if (nextRaces && nextRaces.length) nextRacesFilter();
    setLoading(false);
  }, [nextRaces]);

  return {
    loading,
    races,
    raceToEdit,
    formType,
    titleForm,
    openForm,
    selectValue,
    goalPostPreview,
    editRace,
    shareRace,
    handleSave,
    handleFilter,
    handleOpenForm,
    handleCloseForm,
    handleDelete,
    handleCloseGoalPreview,
  };
};

export default useRaceList;
