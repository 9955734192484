import React from "react";
import { CircularProgress } from "@material-ui/core";
import FilterSelect from "../common/filter-select";
import PropTypes from "prop-types";

export default function SubcategoriesFilterSelect(props) {
  const {
    filterSelectText,
    filterSelectName,
    onSelect,
    subcategories,
    hasGeneral,
    isExploreOrFreePlanSelected,
    isOnAthletes,
    ...restProps
  } = props;
  const { loading, data } = subcategories;

  const getSubcategoriesSelectOptions = () => {
    if (!loading) {
      let initialSubcategories = [];
      if (hasGeneral) {
        initialSubcategories = [
          {
            text: "General",
            value: "",
          },
        ];
      }
      return data.reduce((total, item) => {
        total.push({
          text: item.title.en,
          value: item.id,
        });
        return total;
      }, initialSubcategories);
    } else return [];
  };

  const subcategoriesTitles = getSubcategoriesSelectOptions();
  return (
    <>
      {isExploreOrFreePlanSelected ? (
        loading ? (
          <CircularProgress
            variant="indeterminate"
            color="primary"
            data-testid="subcategoriesLoading"
          />
        ) : (
          <FilterSelect
            name={filterSelectName}
            text={filterSelectText}
            items={subcategoriesTitles}
            onSelect={onSelect}
            large={!isOnAthletes}
            {...restProps}
            noMargin={isOnAthletes}
          />
        )
      ) : (
        <></>
      )}
    </>
  );
}

SubcategoriesFilterSelect.propTypes = {
  filterSelectText: PropTypes.string.isRequired,
  filterSelectName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  hasGeneral: PropTypes.bool,
  subcategories: PropTypes.object.isRequired,
};
