import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Fade, Fab, Stepper, Step, StepButton } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";

const styles = (theme) => ({
  buttonContainer: {
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto",
    paddingLeft: theme.spacing.unit * 2,
  },
  buttons: {
    marginRight: theme.spacing.unit,
  },
  stepper: {
    marginLeft: 20,
    width: 300,
  },
});

class TrainingEditorButtons extends Component {
  render() {
    const {
      classes,
      hasCurrentTraining,
      saving,
      onSaveTraining,
      onClearCurrentTraining,
      disabled,
      level = 3,
      showLevelStepper = false,
      onHandleStep,
    } = this.props;

    const levelSteps = [0, 1, 2];

    return (
      <Fade in={hasCurrentTraining}>
        <div className={classes.buttonContainer}>
          {!disabled && (
            <Fab
              size="small"
              variant="extended"
              color="primary"
              disabled={!hasCurrentTraining || saving}
              className={classes.buttons}
              onClick={onSaveTraining}
            >
              <SaveIcon fontSize="small" />
              &nbsp;Save
            </Fab>
          )}
          <Fab
            size="small"
            variant="extended"
            color="secondary"
            disabled={!hasCurrentTraining || saving}
            className={classes.buttons}
            onClick={onClearCurrentTraining}
          >
            <UndoIcon fontSize="small" />
            &nbsp;Cancel
          </Fab>

          {showLevelStepper && (
            <Stepper
              nonLinear
              activeStep={level}
              alternativeLabel
              className={classes.stepper}
            >
              {levelSteps.map((step, index) => (
                <Step key={index} completed={false}>
                  <StepButton
                    color="inherit"
                    onClick={() => onHandleStep(index)}
                  >
                    {`Level ${index + 1}`}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          )}
        </div>
      </Fade>
    );
  }
}

TrainingEditorButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  hasCurrentTraining: PropTypes.bool,
  saving: PropTypes.bool,
  onSaveTraining: PropTypes.func.isRequired,
  onClearCurrentTraining: PropTypes.func.isRequired,
};

export default withStyles(styles)(TrainingEditorButtons);
