import React from "react";
import { Typography, ListItem, List, makeStyles } from "@material-ui/core";
import { palette } from "../../../theme/palette";
import { useTranslation, withTranslation } from "react-i18next";
import NotificationItem from "./NotificationItem";

const useStyles = makeStyles({
  container: {
    background: "#ffffff",
    borderRadius: 10,
    overflow: "hidden",
    minWidth: 250,
  },
  notificationsContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    overflowY: "scroll",
    maxHeight: 145,
    "&::-webkit-scrollbar": {
      width: 20,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: palette.lightGray,
      borderRadius: 20,
      border: `6px solid transparent`,
      backgroundClip: "content-box",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: palette.lightGray,
    },
  },
});

const NotificationList = ({ notificationList, unreadNotifications }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <List className={classes.notificationsContainer}>
        {!notificationList || notificationList.length <= 0 ? (
          <ListItem
            sx={{
              padding: "12px 16px",
              boxSizing: "border-box",
              boxShadow: palette.boxShadow,
            }}
          >
            <Typography style={{ color: palette.darkGray }}>
              {t("Currently, you don't have any new notifications")}
            </Typography>
          </ListItem>
        ) : (
          notificationList.map((notification, index) => (
            <div key={index}>
              <NotificationItem notification={notification} index={index} />
            </div>
          ))
        )}
      </List>
    </div>
  );
};
export default NotificationList;
