import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import FooterCopyright from "./footer-copyright";
import FooterMenus from "./footer-menus";
import FooterSocial from "./footer-social";
import FooterSignIn from "./footer-signin";
import withWidth from "@material-ui/core/withWidth";

const styles = (theme) => ({
  container: {
    backgroundColor: "#181C20",
    color: "#fff",

    [theme.breakpoints.down("sm")]: {
      height: 50,
      backgroundColor: "#fff",
    },
  },

  flexContainer: {
    flexShrink: 0,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
});

class Footer extends Component {
  render() {
    const { classes, width, isAuthenticated } = this.props;
    const isInMobileView = width === "sm" || width === "xs";

    const { pathname } = window.location || {};
    const isOnAuth = pathname && pathname.startsWith("/auth");
    const isOnChallenges = pathname && pathname.startsWith("/challenges");
    return (
      <footer
        className={classes.container}
        style={{
          height:
            isInMobileView && (isOnAuth || (isOnChallenges && !isAuthenticated))
              ? 0
              : isInMobileView
              ? 50
              : "initial",
        }}
      >
        <div className={classes.flexContainer}>
          {!isInMobileView && <FooterCopyright />}
          {!isInMobileView && <FooterMenus />}

          {!isInMobileView && <FooterSignIn />}
        </div>
        {!isInMobileView && <FooterSocial />}
      </footer>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(Footer));
