const SuggesterMatrix = {
  "0-0-0": ["1589548664", "1605202097", "1576151491", "1596186574"],
  "0-0-10": ["1589548664", "1605202097", "1576151491", "1596186574"],
  "0-0-30": ["1589548664", "1605202097", "1576151491", "1596186574"],
  "0-0-50": ["1589548664", "1605202097", "1576151491", "1596186574"],
  "0-1-0": ["1589548664", "1605202097", "1576151491", "1596186574"],
  "0-1-10": ["1589548664", "1605202097", "1576151491", "1596186574"],
  "0-1-30": ["1589548664", "1605202097", "1576151491", "1596186574"],
  "0-1-50": ["1589548664", "1605202097", "1576151491", "1596186574"],
  "0-2-0": ["1605111279", "1605202097", "1576151491", "1596186574"],
  "0-2-10": ["1605111279", "1605202097", "1576151491", "1596186574"],
  "0-2-30": ["1605111279", "1605202097", "1576151491", "1596186574"],
  "0-2-50": ["1605111279", "1605202097", "1576151491", "1596186574"],
  "0-3-0": ["1589548664", "1605202097", "1576151491", "1596186574"],
  "0-3-10": ["1589548664", "1605202097", "1576151491", "1596186574"],
  "0-3-30": ["1605202097", "1576151491", "1610729320", "1596186574"],
  "0-3-50": ["1605202097", "1576151491", "1610729320", "1596186574"],

  "1-0-0": ["1614349326", "1589548664", "1599135514", "1605202097"],
  "1-0-10": ["1614349326", "1589548664", "1599135514", "1605202097"],
  "1-0-30": ["1614349326", "1589548664", "1599135514", "1576151491"],
  "1-0-50": ["1614349326", "1589548664", "1599135514", "1610729320"],
  "1-1-0": ["1614349326", "1589548664", "1599135514", "1605202097"],
  "1-1-10": ["1614349326", "1589548664", "1599135514", "1605202097"],
  "1-1-30": ["1614349326", "1589548664", "1599135514", "1576151491"],
  "1-1-50": ["1614349326", "1589548664", "1599135514", "1605202097"],
  "1-2-0": ["1589548664", "1599135514", "1593171535", "1605202097"],
  "1-2-10": ["1589548664", "1599135514", "1593171535", "1605202097"],
  "1-2-30": ["1589548664", "1599135514", "1593171535", "1576151491"],
  "1-2-50": ["1589548664", "1599135514", "1593171535", "1610729320"],
  "1-3-0": ["1605111279", "1599135514", "1593171535", "1605202097"],
  "1-3-10": ["1605111279", "1599135514", "1593171535", "1605202097"],
  "1-3-30": ["1605111279", "1599135514", "1593171535", "1576151491"],
  "1-3-50": ["1605111279", "1599135514", "1593171535", "1610729320"],

  "2-0-0": ["1614349326", "1589548664", "1599135514", "1605202097"],
  "2-0-10": ["1614349326", "1589548664", "1599135514", "1605202097"],
  "2-0-30": ["1614349326", "1589548664", "1599135514", "1576151491"],
  "2-0-50": ["1614349326", "1589548664", "1599135514", "1610729320"],
  "2-1-0": ["1614349326", "1589548664", "1599135514", "1605202097"],
  "2-1-10": ["1614349326", "1589548664", "1599135514", "1605202097"],
  "2-1-30": ["1614349326", "1589548664", "1599135514", "1576151491"],
  "2-1-50": ["1614349326", "1589548664", "1599135514", "1610729320"],
  "2-2-0": ["1589548664", "1605111279", "1599135514", "1605202097"],
  "2-2-10": ["1589548664", "1605111279", "1599135514", "1605202097"],
  "2-2-30": ["1589548664", "1605111279", "1599135514", "1576151491"],
  "2-2-50": ["1589548664", "1605111279", "1599135514", "1610729320"],
  "2-3-0": ["1605111279", "1599135514", "1576151491", "1605202097"],
  "2-3-10": ["1605111279", "1599135514", "1576151491", "1605202097"],
  "2-3-30": ["1605111279", "1599135514", "1576151491", "1605202097"],
  "2-3-50": ["1605111279", "1599135514", "1576151491", "1610729320"],

  "3-0-0": ["1589548664", "1599135514", "1593171535", "1576151491"],
  "3-0-10": ["1589548664", "1599135514", "1593171535", "1576151491"],
  "3-0-30": ["1589548664", "1599135514", "1605202097", "1576151491"],
  "3-0-50": ["1589548664", "1599135514", "1576151491", "1610729320"],
  "3-1-0": ["1589548664", "1599135514", "1593171535", "1576151491"],
  "3-1-10": ["1589548664", "1599135514", "1593171535", "1576151491"],
  "3-1-30": ["1589548664", "1599135514", "1605202097", "1576151491"],
  "3-1-50": ["1589548664", "1599135514", "1576151491", "1610729320"],
  "3-2-0": ["1576151683", "1593171535", "1593171535", "1576151491"],
  "3-2-10": ["1576151683", "1593171535", "1587558323", "1576151491"],
  "3-2-30": ["1576151683", "1593171535", "1610729320", "1576151491"],
  "3-2-50": ["1576151683", "1610729320", "1576151491", "1594125487588"],
  "3-3-0": ["1576151683", "1593171535", "1605202097", "1576151491"],
  "3-3-10": ["1576151683", "1593171535", "1605202097", "1576151491"],
  "3-3-30": ["1576151683", "1593171535", "1587558323", "1576151491"],
  "3-3-50": ["1576151683", "1610729320", "1587558323", "1594125487588"],

  "4-0-0": ["1589548664", "1576151491", "1594125487588", "1587558323"],
  "4-0-10": ["1589548664", "1576151491", "1594125487588", "1587558323"],
  "4-0-30": ["1589548664", "1576151491", "1594125487588", "1587558323"],
  "4-0-50": ["1589548664", "1576151491", "1594125487588", "1587558323"],
  "4-1-0": ["1589548664", "1576151491", "1594125487588", "1587558323"],
  "4-1-10": ["1589548664", "1576151491", "1594125487588", "1587558323"],
  "4-1-30": ["1589548664", "1610729320", "1594125487588", "1587558323"],
  "4-1-50": ["1589548664", "1610729320", "1594125487588", "1587558323"],
  "4-2-0": ["1576151491", "1596186574", "1594125487588", "1587558323"],
  "4-2-10": ["1576151491", "1596186574", "1594125487588", "1587558323"],
  "4-2-30": ["1576151491", "1596186574", "1594125487588", "1587558323"],
  "4-2-50": ["1576151491", "1596186574", "1594125487588", "1587558323"],
  "4-3-0": ["1576151491", "1594125487588", "1587558323", "1596186574"],
  "4-3-10": ["1576151491", "1594125487588", "1587558323", "1596186574"],
  "4-3-30": ["1576151491", "1594125487588", "1587558323", "1596186574"],
  "4-3-50": ["1594125487588", "1596186574", "1587558323", "1596186574"],

  btr: ["1640210885", "1643821710", "1603103238", "1589548664"],
};

export default {
  Items: SuggesterMatrix,
  itemsArray: Object.keys(SuggesterMatrix).map((key) => {
    return {
      subCategories: SuggesterMatrix[key],
      key,
    };
  }),
};
