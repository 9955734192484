import React, { Component } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import {
  Grid,
  Typography,
  LinearProgress,
  ClickAwayListener,
  Grow,
  Popper,
  Button,
} from "@material-ui/core";
import moment from "moment";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import Weekdays from "../../constants/weekdays";
import EstimatedTimes from "../../constants/estimated-times";
import "moment/min/locales";
import { dateFormat } from "../../lib/date-helper";
import MenuList from "../common/menu-list";
import { getPlanLevel } from "../../lib/plan-helper";

const styles = (theme) => ({
  container: {
    padding: "0 54px 12px",

    [theme.breakpoints.down("md")]: {
      padding: "0 24px 12px 24px",
    },
  },
  graphContainer: {
    height: 250,
    [theme.breakpoints.down("md")]: {
      height: 200,
      padding: "24px 12px 12px",
      background: "#FFFFFF",
      borderRadius: 8,
    },
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 14,
  },
  topBarFilters: {
    display: "flex",
    alignItems: "center",
    marginTop: 14,
  },
  topBarTitle: {
    fontWeight: 500,
    fontSize: 20,
    color: "#212121",
  },

  topBarTitleReports: {
    fontWeight: 400,
    fontSize: 18,
    color: "#212121",
  },

  filter: {
    fontSize: 14,
    fontWeight: 400,
    background: "rgba(63, 81, 181, 0.12);",
    borderRadius: 4,
    padding: "6px 8px",
    marginRight: 20,
    color: "#212121",
    cursor: "pointer",
    minWidth: 92,
    textAlign: "center",
  },
  filterSelected: {
    fontSize: 14,
    fontWeight: 400,
    background: "#1E629B",
    borderRadius: 4,
    padding: "6px 8px",
    marginRight: 20,
    color: "#FFFFFF",
    cursor: "pointer",
    minWidth: 92,
    textAlign: "center",
  },
  overview: {},
  reports: {},
  reportContainer: {
    padding: "12px 54px",
    [theme.breakpoints.down("md")]: {
      padding: 24,
    },
  },
  reportTitle: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  reportValue: {
    fontSize: 20,
    fontWeight: 900,
    color: "#3F3F3F",
    marginBottom: -5,
  },
  runningBox: {
    display: "flex",
    alignItems: "center",
    marginTop: 16,
  },
  barBox: {
    width: "100%",
  },
  barBoxLabel: {
    marginLeft: 5,
    fontSize: 14,
    color: "#5F5F5F",
  },
  colorPrimary: {
    backgroundColor: "#EDEDED",
  },
  barColorPrimary: {
    backgroundColor: "#1A629B",
  },
  chartBox: {
    height: 30,
    marginTop: 10,
  },
  running: {
    marginBottom: 10,
    padding: 12,
    background: "#FFFFFF",
    borderRadius: 8,
  },
  distance: {
    marginBottom: 10,
    padding: 12,
    background: "#FFFFFF",
    borderRadius: 8,
  },
  doneContainer: {
    background: "#FFFFFF",
    borderRadius: 10,
    margin: 10,
    padding: 10,
    textAlign: "center",
    height: "calc(100% - 40px)",
    display: "flex",
  },
  doneMarginContainer: {
    margin: "auto 0",
    width: "100%",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginBottom: 10,
    justifyContent: "center",
  },
  doneTitle: {
    fontWeight: 500,
    fontWize: 16,
    textAlign: "center",
    color: "#000000",
    opacity: 0.6,
    marginRight: 5,
  },
  doneChart: {
    position: "relative",
  },
  doneText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontWeight: 500,
    fontSize: 30,
    textAlign: "center",
    color: "#000000",
  },
  donePercentage: {
    fontWeight: 900,
    fontSize: 14,
    textAlign: "center",
    color: "#000000",
    opacity: 0.2,
  },
  doneTip: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: "center",
    color: "#000000",
    marginTop: 10,
  },

  energyLevelContainer: {
    background: "#FFFFFF",
    borderRadius: 10,
    margin: "0 10px",
    padding: 10,
    textAlign: "center",
    height: "calc(100% - 40px)",
    display: "flex",
  },
  energyLevelMarginContainer: {
    margin: "auto 0",
    width: "100%",
  },
  energyLevelTitle: {
    fontWeight: 500,
    fontWize: 16,
    textAlign: "center",
    color: "#000000",
    opacity: 0.6,
    marginRight: 5,
  },
  energyLevelChart: {
    position: "relative",
  },
  energyLevelText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontWeight: 500,
    fontSize: 30,
    textAlign: "center",
    color: "#000000",
  },
  energyLevelPercentage: {
    fontWeight: 900,
    fontSize: 14,
    textAlign: "center",
    color: "#000000",
    opacity: 0.2,
  },
  energyLevelTip: {
    fontWeight: 500,
    fontSize: 19,
    textAlign: "center",
    color: "#000000",
    marginTop: 10,
  },
  energyLevelTipTitle: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: "center",
    color: "#1D2125",
  },
  energyLevelTipText: {
    fontWeight: 500,
    fontSize: 12,
    textAlign: "center",
    color: "#000000",
    opacity: 0.3,
  },
  loadTip: {
    display: "flex",
    marginBottom: 14,

    [theme.breakpoints.up("md")]: {
      maxWidth: 500,
      marginLeft: "auto",
      background: "#FFFFFF",
      boxShadow: "0px 2px 4px rgb(0 0 0 / 25%)",
      borderRadius: 10,
      padding: "2px 10px",
    },
  },
  loadText: {
    fontWeight: 500,
    fontSize: 21,
    textAlign: "center",
    color: "#000000",
    marginRight: 10,
  },
  loadPercentage: {
    fontWeight: 900,
    fontSize: 14,
    textAlign: "center",
    color: "#000000",
    opacity: 0.2,
  },
  loadTipTitle: {
    fontWeight: 500,
    fontSize: 14,
    alignItems: "center",
  },
  loadTipText: {
    fonWeight: 500,
    fontSize: 12,
    color: "#000000",
    opacity: 0.3,
  },

  containerMenu: {
    width: "fit-content",
  },
  paper: {
    background: "#FCFCFC",
    borderRadius: 8,
    boxShadow: "5px 3px 10px rgb(33 81 209 / 10%)",
    padding: "5px 0",
  },
  buttonMenu: {
    padding: "0",
    fontWeight: "normal",
    minWidth: 0,
  },
  menuItem: {
    padding: "6px 8px",
    fontWeight: "normal",
    alignItems: "center",
  },
  menuItemLabel: {
    textTransform: "initial",
    textAlign: "left",
    justifyContent: "left",
  },
  menuItemLabelSelected: {
    textTransform: "initial",
    textAlign: "left",
    justifyContent: "left",
    color: "#FF6327",
  },

  arrow: {
    width: 20,
  },

  popper: {
    zIndex: 100,
  },
  button: {
    padding: "0",
    fontWeight: "normal",
  },
  minWZero: {
    minWidth: "initial",
  },
});

class DashboardWeeklyReportDiagram extends Component {
  state = {
    showWeeklyOverview: true,
    units: "kms",
    openDone: false,
    doneGran: "plan",

    openEnergy: false,
    energyGran: "plan",
    open: false,
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setState({
        ...this.state,
        units: localStorage.getItem("units"),
      });

      window.addEventListener("storage", () => this.localStorageUpdated());
    }
  }

  localStorageUpdated() {
    this.setState({
      ...this.state,
      units: localStorage.getItem("units"),
    });
  }

  handleOpenDone() {
    this.setState({
      ...this.state,
      openDone: true,
    });
  }

  handleCloseDone(doneGran = null) {
    this.setState({
      ...this.state,
      openDone: false,
      doneGran: doneGran ? doneGran : this.state.doneGran,
    });
  }

  handleOpenEnergy() {
    this.setState({
      ...this.state,
      openEnergy: true,
    });
  }

  handleCloseEnergy(energyGran = null) {
    this.setState({
      ...this.state,
      openEnergy: false,
      energyGran: energyGran ? energyGran : this.state.energyGran,
    });
  }

  handleToggle = () => {
    this.setState({
      ...this.state,
      open: !this.state.open,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      open: false,
    });
  };

  render() {
    const {
      classes,
      xs,
      sm,
      md,
      lg,
      xl,
      className,
      currentTraining,
      t,
      i18n,
      activities,
      width,
    } = this.props;

    const isInMobileView = width === "md" || width === "sm" || width === "xs";

    const { showWeeklyOverview, units, open } = this.state;
    const { days, currentStartDate, startingDate, durationInWeeks } =
      currentTraining || {};

    const { language } = i18n;
    const momentLocale = moment().locale(language);

    const level = getPlanLevel(currentTraining);

    // Overview
    let currentPlannedWeek = [];
    let currentDoneWeek = [];
    let labels = [];
    const weekStartDate = moment(currentStartDate).startOf("isoWeek");
    const dates = [];
    for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
      dates.push(moment(weekStartDate).add(dayIndex, "day"));
      if (days) {
        const tempDate = moment(weekStartDate).add(dayIndex, "day");
        const foundDay = days.find(
          (day) =>
            day &&
            day.length > 0 &&
            moment(day[0].plannedDate, dateFormat).isSame(tempDate, "day"),
        );
        if (foundDay) {
          currentPlannedWeek.push(
            foundDay
              .map((item) =>
                Array.isArray(item.estimatedTime)
                  ? +item.estimatedTime[level]
                  : +item.estimatedTime,
              )
              .reduce((a, sum) => a + sum),
          );
          currentDoneWeek.push(
            foundDay
              .map((item) =>
                item.isDone
                  ? Array.isArray(item.estimatedTime)
                    ? +item.estimatedTime[level]
                    : +item.estimatedTime
                  : 0,
              )
              .reduce((a, sum) => a + sum),
          );
        } else {
          currentPlannedWeek.push(0);
          currentDoneWeek.push(0);
        }
      } else {
        currentPlannedWeek.push(0);
        currentDoneWeek.push(0);
      }
    }

    let currentPlannedTotal = [];
    let currentDoneTotal = [];
    for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
      currentPlannedTotal.push(0);
      currentDoneTotal.push(0);
    }
    const startingDateMoment = moment(startingDate, dateFormat);
    if (days) {
      days.forEach((day) => {
        if (day) {
          const plannedDay =
            moment(day[0].plannedDate, dateFormat).diff(
              startingDateMoment,
              "days",
            ) + 1;
          const plannedWeek = Math.floor(
            (plannedDay > 1 ? plannedDay - 1 : 0) / 7,
          );
          if (plannedWeek < durationInWeeks) {
            const estimated = day
              .map((item) =>
                Array.isArray(item.estimatedTime)
                  ? +item.estimatedTime[level]
                  : +item.estimatedTime,
              )
              .reduce((a, sum) => a + sum);
            const done = day
              .map((item) =>
                item.isDone
                  ? Array.isArray(item.estimatedTime)
                    ? +item.estimatedTime[level]
                    : +item.estimatedTime
                  : 0,
              )
              .reduce((a, sum) => a + sum);
            currentPlannedTotal[plannedWeek] += isNaN(+estimated)
              ? 0
              : +estimated;
            currentDoneTotal[plannedWeek] += isNaN(+done) ? 0 : +done;
          }
        }
      });
    }

    const currentPlanned = showWeeklyOverview
      ? currentPlannedWeek
      : currentPlannedTotal;
    const currentDone = showWeeklyOverview ? currentDoneWeek : currentDoneTotal;
    if (showWeeklyOverview) {
      labels = Weekdays.itemsArray.map((item, i) =>
        isInMobileView
          ? [
              `${dates[i].format("D")} ${momentLocale
                .localeData()
                .months(dates[i])
                .substring(0, 3)}`,
            ]
          : [
              t(item.text)
                .substring(0, 3)
                .toUpperCase(),
              `${dates[i].format("D")} ${momentLocale
                .localeData()
                .months(dates[i])
                .substring(0, 3)}`,
            ],
      );
    } else {
      labels = [];
      for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
        labels.push(t("WeekMobile", { week: weekIndex + 1, ns: "dashboard" }));
      }
    }
    const data = {
      labels,
      datasets: [
        {
          label: t("Planned", { ns: "dashboard" }),
          borderColor: "#1A629B",
          borderWidth: 1,
          backgroundColor: "transparent",
          pointBorderWidth: 0.01,
          pointRadius: 2,
          pointHoverRadius: 2,
          pointBackgroundColor: "#1A629B",
          data: currentPlanned,
        },
        {
          fill: true,
          label: t("Done", { ns: "dashboard" }),
          backgroundColor: "#E7F3FC",
          borderWidth: 0,
          data: currentDone,
        },
      ],
    };

    const options = {
      tension: 0.4,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            color: "#DDDDDD",
            borderColor: "#DDDDDD",
          },
          ticks: {
            minor: {
              fontFamily: "Avenir",
              fontSize: 12,
              color: "#9E9E9E",
            },
            padding: 10,
          },
        },
        y: {
          grid: {
            drawBorder: false,
            color: "rgba(0, 0, 0, 0.05)",
            borderColor: "rgba(0, 0, 0, 0.05)",
          },
          ticks: {
            beginAtZero: true,
            stepSize: showWeeklyOverview ? 60 : 180,
            callback: function(value) {
              return EstimatedTimes.convertMinutesToLabel(value);
            },
            fontFamily: "Avenir",
            fontSize: 12,
            color: "#9E9E9E",
            padding: 10,
          },
        },
      },
      animation: {
        easing: "linear",
        duration: 1000,
      },
    };

    // Report
    let time = [];
    let timeCross = [];
    let distance = [];
    let elevation = [];
    let reportLabels = [];

    let runActivities = [];
    let crossTrainingActivities = [];

    if (activities) {
      runActivities = activities.filter(
        (a) =>
          (a.type && a.type.toLowerCase().includes("run")) ||
          (a.activityType && a.activityType.includes("RUN")),
      );
      crossTrainingActivities = activities.filter(
        (a) =>
          (a.type && a.type.toLowerCase() !== "run") ||
          (a.activityType && !a.activityType.includes("RUN")),
      );
    }
    if (showWeeklyOverview) {
      const weekStartDate = moment(currentStartDate).startOf("isoWeek");
      const dates = [];
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        dates.push(moment(weekStartDate).add(dayIndex, "day"));
        if (runActivities) {
          const tempDate = moment(weekStartDate).add(dayIndex, "day");
          const foundActivities = runActivities.filter(
            (activity) =>
              activity && moment(activity.start_date).isSame(tempDate, "day"),
          );
          if (foundActivities && foundActivities.length > 0) {
            time.push(
              foundActivities
                .map((item) => Math.round(item.elapsed_time / 60) || 0)
                .reduce((a, sum) => a + sum),
            );
            distance.push(
              foundActivities
                .map((item) => Math.round(item.distance / 1000) || 0)
                .reduce((a, sum) => a + sum),
            );
            elevation.push(
              foundActivities
                .map((item) => Math.round(item.total_elevation_gain) || 0)
                .reduce((a, sum) => a + sum),
            );
          } else {
            time.push(0);
            distance.push(0);
            elevation.push(0);
          }
        } else {
          time.push(0);
          distance.push(0);
          elevation.push(0);
        }

        if (crossTrainingActivities) {
          const tempDate = moment(weekStartDate).add(dayIndex, "day");
          const foundActivities = crossTrainingActivities.filter(
            (activity) =>
              activity && moment(activity.start_date).isSame(tempDate, "day"),
          );
          if (foundActivities && foundActivities.length > 0) {
            timeCross.push(
              foundActivities
                .map((item) => Math.round(item.elapsed_time / 60) || 0)
                .reduce((a, sum) => a + sum),
            );
          } else {
            timeCross.push(0);
          }
        } else {
          timeCross.push(0);
        }
      }

      reportLabels = Weekdays.itemsArray.map((item, i) => [
        isInMobileView
          ? ""
          : t(item.text)
              .substring(0, 3)
              .toUpperCase(),
        `${dates[i].format("D")} ${momentLocale
          .localeData()
          .months(dates[i])
          .substring(0, 3)}`,
      ]);
    } else {
      const startingDateMoment = moment(startingDate, dateFormat);
      reportLabels = [];
      for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
        time.push(0);
        distance.push(0);
        elevation.push(0);
        timeCross.push(0);
        reportLabels.push(
          t("WeekMobile", { week: weekIndex + 1, ns: "dashboard" }),
        );
      }
      runActivities.forEach((activity) => {
        if (activity) {
          const plannedDay =
            moment(activity.start_date).diff(startingDateMoment, "days") + 1;
          const plannedWeek = Math.floor((plannedDay > 0 ? plannedDay : 0) / 7);
          if (plannedWeek < durationInWeeks) {
            time[plannedWeek] += Math.round(activity.elapsed_time / 60) || 0;
            distance[plannedWeek] += Math.round(activity.distance / 1000) || 0;
            elevation[plannedWeek] +=
              Math.round(activity.total_elevation_gain) || 0;
          }
        }
      });
      crossTrainingActivities.forEach((activity) => {
        if (activity) {
          const plannedDay =
            moment(activity.start_date).diff(startingDateMoment, "days") + 1;
          const plannedWeek = Math.floor((plannedDay > 0 ? plannedDay : 0) / 7);
          timeCross[plannedWeek] += Math.round(activity.elapsed_time / 60) || 0;
        }
      });
    }

    let plannedTime = 0;
    if (currentPlannedWeek.length > 0 && currentPlannedTotal.length > 0) {
      plannedTime = (showWeeklyOverview
        ? currentPlannedWeek
        : currentPlannedTotal
      ).reduce((a, sum) => a + sum);
    }
    const activitiesTime =
      time.length > 0 ? time.reduce((a, sum) => a + sum) : 0;
    const activitiesDistance =
      distance.length > 0 ? distance.reduce((a, sum) => a + sum) : 0;
    const activitiesElevation =
      elevation.length > 0 ? elevation.reduce((a, sum) => a + sum) : 0;
    const activitiesTimeCross =
      timeCross.length > 0 ? timeCross.reduce((a, sum) => a + sum) : 0;

    const distanceData = {
      labels: reportLabels,
      datasets: [
        {
          fill: true,
          backgroundColor: "#E7F3FC",
          borderColor: "#E7F3FC",
          data: distance,
        },
      ],
    };

    const elevationData = {
      labels: reportLabels,
      datasets: [
        {
          fill: true,
          backgroundColor: "#E7F3FC",
          borderColor: "#E7F3FC",
          data: elevation,
        },
      ],
    };

    const crossData = {
      labels: reportLabels,
      datasets: [
        {
          fill: true,
          backgroundColor: "#E7F3FC",
          borderColor: "#E7F3FC",
          data: timeCross,
        },
      ],
    };

    const optionsReports = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      elements: {
        line: {
          fill: true,
          tension: 0.4,
          borderWidth: 0,
        },
        point: {
          radius: 0,
        },
      },
      animation: {
        easing: "linear",
        duration: 1000,
      },
    };

    return (
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={className}>
        <div>
          <div className={classes.overview}>
            <div className={classes.container}>
              <div className={classes.topBar}>
                {isInMobileView ? (
                  <>
                    <Typography variant="body1" className={classes.topBarTitle}>
                      {showWeeklyOverview
                        ? t("Weekly running load", {
                            ns: "dashboard",
                          })
                        : t("Full plan running load", {
                            ns: "dashboard",
                          })}
                    </Typography>

                    <Button
                      className={classes.button}
                      buttonRef={(node) => {
                        this.anchorEl = node;
                      }}
                      aria-owns={open ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={this.handleToggle}
                      placement="left-start"
                      classes={{ root: classes.minWZero }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/icon-chevron-down.svg`}
                        alt=""
                      />
                    </Button>
                    <Popper
                      open={open}
                      anchorEl={this.anchorEl}
                      transition
                      disablePortal
                      className={classes.popper}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <div className={classes.paper}>
                            <ClickAwayListener
                              onClickAway={() => this.handleClose()}
                            >
                              <MenuList>
                                <Button
                                  variant="text"
                                  classes={{
                                    root: classes.menuItem,
                                    label: classes.menuItemLabel,
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      ...this.state,
                                      showWeeklyOverview: true,
                                      open: false,
                                    })
                                  }
                                >
                                  {t("weekly", { ns: "dashboard" })}
                                </Button>

                                <Button
                                  variant="text"
                                  classes={{
                                    root: classes.menuItem,
                                    label: classes.menuItemLabel,
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      ...this.state,
                                      showWeeklyOverview: false,
                                      open: false,
                                    })
                                  }
                                >
                                  {t("total", { ns: "dashboard" })}
                                </Button>
                              </MenuList>
                            </ClickAwayListener>
                          </div>
                        </Grow>
                      )}
                    </Popper>
                  </>
                ) : (
                  <div>
                    <Typography variant="body1" className={classes.topBarTitle}>
                      {isInMobileView
                        ? t("TRAINING LOAD", { ns: "dashboard" })
                        : t("OVERVIEW", { ns: "dashboard" })}
                    </Typography>

                    <div className={classes.topBarFilters}>
                      <Typography
                        variant="body1"
                        className={
                          showWeeklyOverview
                            ? classes.filterSelected
                            : classes.filter
                        }
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            showWeeklyOverview: true,
                          })
                        }
                      >
                        {t("weekly", { ns: "dashboard" })}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={
                          !showWeeklyOverview
                            ? classes.filterSelected
                            : classes.filter
                        }
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            showWeeklyOverview: false,
                          })
                        }
                      >
                        {t("total", { ns: "dashboard" })}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.graphContainer}>
                <Line data={data} options={options} />
              </div>
            </div>
          </div>
          <div className={classes.reports}>
            <div className={classes.reportContainer}>
              <div className={classes.topBar}>
                <div>
                  <Typography
                    variant="body1"
                    className={classes.topBarTitleReports}
                  >
                    {t("REPORTS", { ns: "dashboard" })}
                  </Typography>
                </div>
              </div>

              <Grid container spacing={32}>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <div className={classes.running}>
                    <Typography variant="body1" className={classes.reportTitle}>
                      {t("Running", { ns: "dashboard" })}
                    </Typography>
                    <Typography className={classes.reportValue} variant="body2">
                      {EstimatedTimes.convertMinutesToLabel(
                        activitiesTime,
                        true,
                      ) || "0min"}
                    </Typography>
                    <div className={classes.runningBox}>
                      <div className={classes.barBox}>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.colorPrimary,
                            barColorPrimary: classes.barColorPrimary,
                          }}
                          variant="determinate"
                          value={
                            plannedTime
                              ? (activitiesTime * 100) / plannedTime
                              : 0
                          }
                        />
                      </div>
                      <div>
                        <Typography
                          className={classes.barBoxLabel}
                          variant="body2"
                          color="textSecondary"
                        >
                          {EstimatedTimes.convertMinutesToLabel(plannedTime) ||
                            "0min"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <div className={classes.distance}>
                    <Typography variant="body1" className={classes.reportTitle}>
                      {t("Distance", { ns: "dashboard" })}
                    </Typography>
                    <Typography className={classes.reportValue} variant="body2">
                      {!units || units === "kms"
                        ? `${activitiesDistance} km`
                        : `${Math.round(activitiesDistance * 0.621371)} ${t(
                            "miles",
                          )}`}
                    </Typography>
                    <div className={classes.chartBox}>
                      <Line data={distanceData} options={optionsReports} />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <div className={classes.distance}>
                    <Typography variant="body1" className={classes.reportTitle}>
                      {t("Elevation", { ns: "dashboard" })}
                    </Typography>
                    <Typography className={classes.reportValue} variant="body2">
                      {!units || units === "kms"
                        ? `${activitiesElevation} m`
                        : `${Math.round(activitiesElevation * 3.28084)} ${t(
                            "feet",
                          )}`}
                    </Typography>
                    <div className={classes.chartBox}>
                      <Line data={elevationData} options={optionsReports} />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <div className={classes.distance}>
                    <Typography variant="body1" className={classes.reportTitle}>
                      {t("Cross training", { ns: "dashboard" })}
                    </Typography>
                    <Typography className={classes.reportValue} variant="body2">
                      {EstimatedTimes.convertMinutesToLabel(
                        activitiesTimeCross,
                        true,
                      ) || "0min"}
                    </Typography>
                    <div className={classes.chartBox}>
                      <Line data={crossData} options={optionsReports} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Grid>
    );
  }
}

DashboardWeeklyReportDiagram.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  currentTraining: PropTypes.object,
  distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  elevation: PropTypes.number,
  t: PropTypes.func.isRequired,
  activities: PropTypes.array,
};

export default withTranslation(["constants", "dashboard"])(
  withWidth()(
    withStyles(styles, { withTheme: true })(DashboardWeeklyReportDiagram),
  ),
);
