import React from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  Button,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { useTranslation } from "react-i18next";
import useFavorites from "../../../hooks/useFavorites";
import { IconCalendarEvent, IconRoute } from "@tabler/icons-react";

const OnboardingPlanCard = ({
  plan,
  onDetails = (plan) => console.info("plan", plan),
  onStartTraining = (plan) => console.info("plan", plan),
}) => {
  const classes = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation("onboarding");
  const { toggleFavorites, isFavorite } = useFavorites();

  return (
    <Card className={classes.card}>
      <CardMedia
        component="img"
        image={plan.picture || plan.cover_picture}
        alt={plan.athlete}
        className={classes.cardImage}
      />
      {plan.plan === "free" && (
        <Box className={classes.overlay}>
          <Typography className={classes.overlayText}>Free</Typography>
        </Box>
      )}
      <IconButton
        className={classes.favoriteButton}
        size="small"
        aria-label="add to favorites"
        onClick={(e) => {
          e.stopPropagation();
          toggleFavorites("plan", plan.id);
        }}
      >
        {isFavorite("plan", plan.id) ? (
          <StarIcon color="primary" />
        ) : (
          <StarBorderIcon style={{ color: "white" }} />
        )}
      </IconButton>
      <CardContent className={classes.cardContent}>
        <Box>
          <Typography className={classes.cardName}>{plan.athlete}</Typography>
          <Typography className={classes.cardTitle}>
            {plan.title[language] ? plan.title[language] : plan.title.en}
          </Typography>
        </Box>
        <Box className={classes.cardIconContainer}>
          <Box className={classes.cardIcon}>
            <IconCalendarEvent color="#6D6F71" stroke={1.5} />
            <Typography className={classes.cardIconText}>
              {plan.weeks} {t("onboarding.plan.weeks")}
            </Typography>
          </Box>
          <Divider orientation="vertical" variant="fullWidth" flexItem />
          <Box className={classes.cardIcon}>
            <IconRoute color="#6D6F71" stroke={1.5} />
            <Typography className={classes.cardIconText}>
              {t("onboarding.plan.distance")} {plan.max_distance}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          onClick={() => onDetails(plan)}
          className={classes.detailsButton}
        >
          {t("onboarding.plan.details")}
        </Button>
        <Button
          variant="contained"
          onClick={() => onStartTraining(plan)}
          className={classes.startButton}
        >
          {t("onboarding.plan.start")}
        </Button>
      </CardActions>
    </Card>
  );
};

export default OnboardingPlanCard;

const useStyles = makeStyles({
  card: {
    borderRadius: "8px",
    height: "480px",
    marginBottom: "16px",
    position: "relative",
  },
  cardImage: {
    width: "100%",
    height: "250px",
    objectPosition: "center -20px",
    objectFit: "cover",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    gap: "16px",
  },
  cardName: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#6D6F71",
    textTransform: "capitalize",
    lineHeight: "24px",
  },
  cardTitle: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    color: "#12151B",
    textTransform: "initial",
    lineHeight: "22.5px",
  },
  detailsButton: {
    flex: 1,
    color: "#0B2B5C",
    borderColor: "#0B2B5C",
    borderWidth: "2px",
    borderRadius: "4px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "initial",
  },
  startButton: {
    flex: 2,
    backgroundColor: "#0B2B5C",
    color: "#FFFFFF",
    borderRadius: "4px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "initial",
  },
  cardIconContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  cardIcon: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cardIconText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6D6F71",
    lineHeight: "21px",
  },
  overlay: {
    position: "absolute",
    top: "14px",
    left: "12px",
    backgroundColor: "#F93C6E",
    color: "#FEFFFF",
    padding: "4px 12px",
    borderRadius: "4px",
  },
  overlayText: {
    fontFamily: "Oswald",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "21px",
    textTransform: "uppercase",
  },
  favoriteButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "#FFFFFF33",
  },
});
