import React from "react";
import { withRouter, Redirect } from "react-router-dom";

const RedirectToAuth = (props) => {
  const { match, location } = props;
  if (match) {
    const { params } = match;
    if (params) {
      const { authState } = params;
      if (authState === "signIn")
        return (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: location },
            }}
          />
        );
      else if (authState === "signUp")
        return (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: location },
            }}
          />
        );
    }
  }

  return (
    <Redirect
      to={{
        pathname: "/",
        state: { from: location },
      }}
    />
  );
};

export default withRouter(RedirectToAuth);
