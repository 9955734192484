import ActionTypes from "../constants/action-types";
import ServerErrors from "../constants/server-errors";

const initialState = {
  currentChallenges: [],
  loadingChallenges: false,
  oldChallenges: [],
  loadingOldChallenges: false,
  yourChallengeEntries: null,
  loadingYourChallengeEntries: true,
  leaders: null,
  loadingLeaders: false,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.CHALLENGES_GET_PENDING:
        return {
          ...state,
          loadingChallenges: action.payload,
        };
      case ActionTypes.CHALLENGES_GET_FULFILLED:
        return {
          ...state,
          loadingChallenges: false,
          currentChallenges: action.payload,
        };
      case ActionTypes.CHALLENGES_GET_REJECTED:
        return {
          ...state,
          loadingChallenges: false,
          errorMessage: ServerErrors.ServerError,
        };

      case ActionTypes.CHALLENGES_LEADERS_GET_PENDING:
        return {
          ...state,
          loadingLeaders: true,
          leaders: null,
        };
      case ActionTypes.CHALLENGES_LEADERS_GET_FULFILLED:
        return {
          ...state,
          loadingLeaders: false,
          leaders: action.payload,
        };
      case ActionTypes.CHALLENGES_LEADERS_GET_REJECTED:
        return {
          ...state,
          loadingLeaders: false,
          errorMessage: ServerErrors.ServerError,
        };

      case ActionTypes.CHALLENGES_LEADERS_CLEAR:
        return {
          ...state,
          loadingLeaders: false,
          leaders: null,
        };

      case ActionTypes.CHALLENGES_GET_OLD_PENDING:
        return {
          ...state,
          loadingOldChallenges: true,
        };
      case ActionTypes.CHALLENGES_GET_OLD_FULFILLED:
        return {
          ...state,
          loadingOldChallenges: false,
          oldChallenges: action.payload,
        };
      case ActionTypes.CHALLENGES_GET_OLD_REJECTED:
        return {
          ...state,
          loadingOldChallenges: false,
          errorMessage: ServerErrors.ServerError,
        };

      case ActionTypes.UPDATE_JOINED_CHALLENGE_PENDING:
        return {
          ...state,
          loadingYourChallengeEntries: true,
        };
      case ActionTypes.UPDATE_JOINED_CHALLENGE_FULFILLED:
        const newChallengesJ = state.currentChallenges.map((cc) => {
          cc.yourEntry = action.payload
            ? action.payload.find((yE) => yE.challenge === cc.id)
            : cc.yourEntry;
          return cc;
        });
        return {
          ...state,
          loadingYourChallengeEntries: false,
          yourChallengeEntries: action.payload
            ? action.payload
            : state.yourChallengeEntries,
          currentChallenges: newChallengesJ,
        };
      case ActionTypes.UPDATE_JOINED_CHALLENGE_REJECTED:
        return {
          ...state,
          loadingYourChallengeEntries: false,
          errorMessage: ServerErrors.ServerError,
        };

      case ActionTypes.CHALLENGE_JOIN_PENDING:
        return {
          ...state,
        };
      case ActionTypes.CHALLENGE_JOIN_FULFILLED:
        const newChallenges = [
          ...state.currentChallenges.filter((c) => c.id !== action.payload.id),
          action.payload,
        ].sort((a, b) => {
          return a.start_date - b.start_date;
        });
        return {
          ...state,
          currentChallenges: newChallenges,
        };
      case ActionTypes.CHALLENGE_JOIN_REJECTED:
        return {
          ...state,
          errorMessage: ServerErrors.ServerError,
        };

      default:
        return state;
    }
  } else {
    return state;
  }
}
