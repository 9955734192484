import React, { Component, createRef, memo } from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Step,
  StepButton,
  StepIcon,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";

import { Routes } from "../../lib/routing-middleware";
import history from "../../lib/history-helper";
import {
  saveWorkout,
  readWorkout,
  cleanReadWorkoutFields,
  workoutChangeField,
  setWorkout,
  deleteBulkWorkout,
  changeSearchParams,
} from "../../actions/workout-action";
import UserGroups from "../../constants/user-groups";
import FilterSelect from "../common/filter-select";
import TrainingPeriods from "../../constants/training-periods";
import WorkoutTypes from "../../constants/workout-types";
import Surfaces from "../../constants/surfaces";
import EstimatedTimes from "../../constants/estimated-times";
import Languages from "../../constants/languages";
import { convertToRTFForCDK } from "../../lib/rtf-helper";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import WorkoutTargets from "../../constants/workout-targets";
import DeleteIcon from "@material-ui/icons/Delete";
import FontAwesomeIcon from "../common/font-awesome-icon";
import WorkoutStep from "./workout-editor/workout-step";
import WorkoutRepeat from "./workout-editor/workout-repeat";
import { ReactSortable } from "react-sortablejs";
import WorkoutActionTypes from "../../constants/workout-action-types";
import WorkoutActionVariables from "../../constants/workout-action-variables";
import DifficultyLevels from "../../constants/difficulty-levels";
import workoutToText from "../../lib/workoutToText";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 5,
    width: "100%",
    backgroundColor: "#F5F9FF",
  },
  flexContainer: {
    display: "flex",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },

  mainDataContainer: {
    maxWidth: 500,
    padding: 10,
  },

  workoutPane: {
    width: "100%",
    padding: 10,
  },

  topBar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "10px",
  },

  smallMargin: {
    margin: "0 10px",
    backgroundColor: "#FFF",
  },

  stepper: {
    width: "400px",
    padding: "2px",
  },

  workoutGeneratedTextContainer: {
    boxShadow: "0px 0px 10px 1px rgb(0 0 0 / 25%)",
    padding: 15,
    margin: "20px 0",
    maxHeight: 250,
    overflow: "scroll",
    backgroundColor: "#FFF",
  },

  workoutGeneratedTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },

  workoutStepsContainer: {
    overflow: "scroll",
    height: 640,
  },

  "@global": {
    ".ck-editor__editable": {
      maxHeight: 200,
    },
  },
  custom: {
    margingTop: "0px !important",
    fontSize: "12px",
  },
  labelParent: {
    marginTop: "-13px !important",
  },
  noPadding: {
    padding: "26px 16px 16px 16px",
  },
  richTextEditor: {
    "& div .ck .ck-content": {
      height: 300,
    },
  },
};

class WorkoutEditor extends Component {
  state = {
    language: "en",
    level: 2,
    showCKEditor: false,
    rendered: false,
  };

  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  componentWillUnmount() {
    this.props.dispatch(cleanReadWorkoutFields());
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    if (id) {
      this.props.dispatch(readWorkout(id));
    } else {
      const { workout } = this.props;
      const { workoutForSave } = workout;
      if (!workoutForSave || Object.keys(workoutForSave).length === 0)
        this.handleAddStep(null);
    }
    setTimeout(() => {
      this.setState({ showCKEditor: true });
    }, 100);
  }

  async componentDidUpdate() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    const { rendered } = this.state;
    if (id && !rendered) {
      await this.setState({ ...this.state, rendered: true });
      await this.props.dispatch(readWorkout(id));
    }
  }

  handleClose = () => {
    history.push(Routes.Workouts);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { workout } = this.props;
    if (workout) {
      const { workoutForSave } = workout;
      if (workoutForSave) this.props.dispatch(saveWorkout(workoutForSave));
    }
  };

  handleSelect = ({ name, value }) => {
    if (name && value) this.props.dispatch(workoutChangeField({ name, value }));
  };

  handleLevelEstimatedTimeSelect = ({ name, value }) => {
    const { workout } = this.props;
    const { workoutForSave = {} } = workout;
    const { estimatedTime } = workoutForSave;

    let newEstimatedTime = [];
    if (Array.isArray(estimatedTime)) {
      newEstimatedTime = [...estimatedTime];
    }
    newEstimatedTime[this.state.level] = value;
    if (name && value)
      this.props.dispatch(
        workoutChangeField({ name, value: newEstimatedTime }),
      );
  };

  handleMultiLangTextChange = ({ name, value }) => {
    this.props.dispatch(workoutChangeField({ name, value }));
  };

  handleLanguageSelect = ({ name, value }) => {
    this.setState({
      ...this.state,
      language: value,
    });
  };

  handleChange = (event) => {
    const { target } = event;
    const { language } = this.state;

    if (target) {
      const { name, value } = target;
      if (name) {
        const { workout } = this.props;
        const { workoutForSave = {} } = workout;
        const { title, description } = workoutForSave;

        const valueObject = name === "title" ? title : description;
        this.handleMultiLangTextChange({
          name: name,
          value: {
            ...valueObject,
            [language]: value,
          },
        });
      }
    }
  };

  handlePublicTitle = (event) => {
    const { target } = event;
    const { language } = this.state;

    if (target) {
      const { name, value } = target;
      if (name) {
        const { workout } = this.props;
        const { workoutForSave = {} } = workout;
        const { publicTitle, description } = workoutForSave;
        const valueObject = name === "publicTitle" ? publicTitle : description;

        this.handleMultiLangTextChange({
          name,
          value: {
            ...valueObject,
            [language]: value,
          },
        });
      }
    }
  };

  handleChangeRTEditor = (event, editor) => {
    const value = editor.getData();

    const { language } = this.state;

    const { workout } = this.props;
    const { workoutForSave = {} } = workout;
    const { description } = workoutForSave;

    const valueObject = description;
    this.handleMultiLangTextChange({
      name: "description",
      value: {
        ...valueObject,
        [language]: value,
      },
    });
  };

  handleStep = (step) => {
    this.setState({
      ...this.state,
      level: step,
    });
  };

  handleAddStep = (repeatId = null) => {
    const newStep = {
      type: "step",
      action: WorkoutActionTypes.Items["Warm up"],
      variable: WorkoutActionVariables.Items.Time,
      value: null,
      id: new Date().getTime(),
      targetType: WorkoutTargets.Items["No Target"],
      targetValue: null,
      isDeleteOpen: false,
    };

    const { workout } = this.props;
    const { workoutForSave = {} } = workout;
    const { parametrizedWorkout = [] } = workoutForSave;

    const newParametrizedWorkout = [...parametrizedWorkout];

    if (repeatId) {
      const repeatStep = newParametrizedWorkout[2].find(
        (step) => step.id === repeatId,
      );
      if (repeatStep) {
        repeatStep.steps = [...repeatStep.steps, newStep];
      }
    } else {
      newParametrizedWorkout[2] = [
        ...(newParametrizedWorkout[2] ? newParametrizedWorkout[2] : []),
        newStep,
      ];
    }

    newParametrizedWorkout[0] = JSON.parse(
      JSON.stringify(newParametrizedWorkout[2]),
    );
    newParametrizedWorkout[1] = JSON.parse(
      JSON.stringify(newParametrizedWorkout[2]),
    );
    this.props.dispatch(
      workoutChangeField({
        name: "parametrizedWorkout",
        value: newParametrizedWorkout,
      }),
    );
  };

  handleChangeStepSelect = ({ name, value }, stepId, repeatId = null) => {
    if (name) {
      const { workout } = this.props;
      const { workoutForSave = {} } = workout;
      const { parametrizedWorkout = [] } = workoutForSave;

      const { level } = this.state;
      const newParametrizedWorkout = JSON.parse(
        JSON.stringify([...parametrizedWorkout]),
      );

      if (repeatId) {
        const repeatStep = newParametrizedWorkout[level].find(
          (step) => step.id === repeatId,
        );
        if (repeatStep) {
          const stepToChange = repeatStep.steps.find((s) => s.id === stepId);
          stepToChange[name] = value;
          if (name === "variable") {
            stepToChange.value = "";
          }
          if (name === "targetType") {
            stepToChange.targetValue = "";
          }
        }

        if (level === 2 && (name !== "value" || name !== "targetValue")) {
          newParametrizedWorkout[0] = JSON.parse(
            JSON.stringify(newParametrizedWorkout[2]),
          );
          newParametrizedWorkout[1] = JSON.parse(
            JSON.stringify(newParametrizedWorkout[2]),
          );
        }

        this.props.dispatch(
          workoutChangeField({
            name: "parametrizedWorkout",
            value: newParametrizedWorkout,
          }),
        );
        return;
      }

      const stepToChange = newParametrizedWorkout[level].find(
        (s) => s.id === stepId,
      );

      if (stepToChange) {
        stepToChange[name] = value;
        if (name === "variable") {
          stepToChange.value = "";
        }
        if (name === "targetType") {
          stepToChange.targetValue = "";
        }

        if (level === 2 && (name !== "value" || name !== "targetValue")) {
          newParametrizedWorkout[0] = JSON.parse(
            JSON.stringify(newParametrizedWorkout[2]),
          );
          newParametrizedWorkout[1] = JSON.parse(
            JSON.stringify(newParametrizedWorkout[2]),
          );
        }
        this.props.dispatch(
          workoutChangeField({
            name: "parametrizedWorkout",
            value: newParametrizedWorkout,
          }),
        );
      }
    }
  };

  handleAddRepeat = () => {
    const newStep = {
      type: "repeat",
      times: 2,
      steps: [],
      id: new Date().getTime(),
    };

    const { workout } = this.props;
    const { workoutForSave = {} } = workout;
    const { parametrizedWorkout = [] } = workoutForSave;

    const newParametrizedWorkout = [...parametrizedWorkout];
    newParametrizedWorkout[2] = [
      ...(newParametrizedWorkout[2] ? newParametrizedWorkout[2] : []),
      newStep,
    ];

    newParametrizedWorkout[0] = JSON.parse(
      JSON.stringify(newParametrizedWorkout[2]),
    );
    newParametrizedWorkout[1] = JSON.parse(
      JSON.stringify(newParametrizedWorkout[2]),
    );
    this.props.dispatch(
      workoutChangeField({
        name: "parametrizedWorkout",
        value: newParametrizedWorkout,
      }),
    );
  };

  handleChangeRepeatTimes = (repeatId, value, level) => {
    if (value > 1) {
      const { workout } = this.props;
      const { workoutForSave = {} } = workout;
      const { parametrizedWorkout = [] } = workoutForSave;

      const newParametrizedWorkout = [...parametrizedWorkout];

      const repeatStep = newParametrizedWorkout[level].find(
        (step) => step.id === repeatId,
      );

      if (repeatStep) {
        repeatStep.times = value;
      }

      if (level === 2) {
        newParametrizedWorkout[0] = JSON.parse(
          JSON.stringify(newParametrizedWorkout[2]),
        );
        newParametrizedWorkout[1] = JSON.parse(
          JSON.stringify(newParametrizedWorkout[2]),
        );
      }

      this.props.dispatch(
        workoutChangeField({
          name: "parametrizedWorkout",
          value: newParametrizedWorkout,
        }),
      );
    }
  };

  handleSetParametrizedWorkout = (listChange, repeatId = null) => {
    const { workout } = this.props;
    const { workoutForSave = {} } = workout;
    const { parametrizedWorkout = [] } = workoutForSave;

    const newParametrizedWorkout = [...parametrizedWorkout];

    if (repeatId) {
      const repeatStep = newParametrizedWorkout[2].find(
        (step) => step.id === repeatId,
      );
      if (repeatStep) {
        repeatStep.steps = listChange;
      }

      const repeatStep1 = newParametrizedWorkout[0].find(
        (step) => step.id === repeatId,
      );
      if (repeatStep1) {
        repeatStep1.steps = listChange;
      }

      const repeatStep2 = newParametrizedWorkout[0].find(
        (step) => step.id === repeatId,
      );
      if (repeatStep2) {
        repeatStep2.steps = listChange;
      }

      this.props.dispatch(
        workoutChangeField({
          name: "parametrizedWorkout",
          value: newParametrizedWorkout,
        }),
      );
      return;
    }

    newParametrizedWorkout[2] = listChange;
    newParametrizedWorkout[0] = JSON.parse(
      JSON.stringify(newParametrizedWorkout[2]),
    );
    newParametrizedWorkout[1] = JSON.parse(
      JSON.stringify(newParametrizedWorkout[2]),
    );
    this.props.dispatch(
      workoutChangeField({
        name: "parametrizedWorkout",
        value: newParametrizedWorkout,
      }),
    );
  };

  handleDeleteStep = (id, repeatId = null) => {
    const { workout } = this.props;
    const { workoutForSave = {} } = workout;
    const { parametrizedWorkout = [] } = workoutForSave;

    const newParametrizedWorkout = [...parametrizedWorkout];

    if (repeatId) {
      const repeatStep = newParametrizedWorkout[2].find(
        (step) => step.id === repeatId,
      );
      if (repeatStep) {
        repeatStep.steps = repeatStep.steps.filter((step) => step.id !== id);
      }
    } else {
      newParametrizedWorkout[2] = newParametrizedWorkout[2].filter(
        (step) => step.id !== id,
      );
    }

    newParametrizedWorkout[0] = JSON.parse(
      JSON.stringify(newParametrizedWorkout[2]),
    );
    newParametrizedWorkout[1] = JSON.parse(
      JSON.stringify(newParametrizedWorkout[2]),
    );

    this.props.dispatch(
      workoutChangeField({
        name: "parametrizedWorkout",
        value: newParametrizedWorkout,
      }),
    );
  };

  handleDuplicateStep = (step, repeatId = null) => {
    const { workout } = this.props;
    const { workoutForSave = {} } = workout;
    const { parametrizedWorkout = [] } = workoutForSave;

    const newParametrizedWorkout = [...parametrizedWorkout];

    if (repeatId) {
      const repeatStep = newParametrizedWorkout[2].find(
        (rStep) => rStep.id === repeatId,
      );
      if (repeatStep) {
        const duplicatedIndex = repeatStep.steps.findIndex(
          (dStep) => dStep.id === step.id,
        );
        repeatStep.steps.splice(duplicatedIndex + 1, 0, {
          ...step,
          id: new Date().getTime(),
        });
      }
    } else {
      const duplicatedIndex = newParametrizedWorkout[2].findIndex(
        (dStep) => dStep.id === step.id,
      );
      newParametrizedWorkout[2].splice(duplicatedIndex + 1, 0, {
        ...step,
        id: new Date().getTime(),
      });
    }

    newParametrizedWorkout[0] = JSON.parse(
      JSON.stringify(newParametrizedWorkout[2]),
    );
    newParametrizedWorkout[1] = JSON.parse(
      JSON.stringify(newParametrizedWorkout[2]),
    );

    this.props.dispatch(
      workoutChangeField({
        name: "parametrizedWorkout",
        value: newParametrizedWorkout,
      }),
    );
  };

  isValidParametrizedWorkout = () => {
    const { workout } = this.props;
    const { workoutForSave = {} } = workout;
    const { parametrizedWorkout = [] } = workoutForSave;

    if (parametrizedWorkout.length === 0) return false;

    for (const level of parametrizedWorkout) {
      for (const step of level) {
        if (step.type === "step") {
          if (!this.isValidStep(step)) {
            return false;
          }
        } else {
          if (!step.steps || step.steps.length === 0) return false;

          for (const rStep of step.steps) {
            if (!this.isValidStep(rStep)) {
              return false;
            }
          }
        }
      }
    }

    return true;
  };

  isValidStep = (step) => {
    return (
      step.value &&
      !(
        step.variable === WorkoutActionVariables.Items.Time &&
        step.value === "00h:00m:00s"
      ) &&
      (step.targetType === WorkoutTargets.Items["No Target"] ||
        step.targetValue)
    );
  };

  handleDuplicateWorkout = () => {
    const { workout } = this.props;
    const { workoutForSave = {} } = workout;
    this.props.dispatch(
      setWorkout({
        ...workoutForSave,
        id: null,
        title: {
          en: workoutForSave.title.en + " (Copy)",
          es: workoutForSave.title.es + " (Copia)",
        },
        publicTitle: {
          en:
            workoutForSave.publicTitle && workoutForSave.publicTitle.en
              ? workoutForSave.publicTitle.en + " (Copy)"
              : "",
          es:
            workoutForSave.publicTitle && workoutForSave.publicTitle.es
              ? workoutForSave.publicTitle.es + " (Copia)"
              : "",
        },
      }),
    );

    history.replace(Routes.WorkoutCreate);
  };

  handleDeleteWorkout = () => {
    this.setState({
      isDeleteOpen: true,
    });
  };

  handleDeleteRequested = () => {
    const { workout } = this.props;
    const { workoutForSave } = workout;
    if (workout && workoutForSave) {
      this.props.dispatch(
        changeSearchParams({ selected: [workoutForSave.id] }),
      );
      this.props.dispatch(deleteBulkWorkout([workoutForSave.id]));
    }
    history.push(Routes.Workouts);
  };

  handleDeleteCancel = () => {
    this.setState({
      isDeleteOpen: false,
    });
  };

  render() {
    const formId = "workoutForm";
    const { classes, workout, auth } = this.props;
    const { saving, workoutForSave = {} } = workout;
    const {
      id,
      title,
      description,
      trainingPeriod,
      difficultyLevel,
      workoutType,
      surface,
      estimatedTime,
      created_by_cognito_user_sub,
      parametrizedWorkout = [[], [], []],
      publicTitle,
    } = workoutForSave;

    const isParametrized =
      !difficultyLevel ||
      (parametrizedWorkout &&
        parametrizedWorkout.length &&
        parametrizedWorkout[2].length);

    const hasError = !(
      title &&
      title["en"] &&
      title["es"] &&
      description &&
      description["en"] &&
      description["es"] &&
      trainingPeriod &&
      workoutType &&
      surface &&
      ((!isParametrized && estimatedTime) ||
        (isParametrized &&
          Array.isArray(estimatedTime) &&
          estimatedTime.length === 3 &&
          estimatedTime[0] &&
          estimatedTime[1] &&
          estimatedTime[2])) &&
      ((!isParametrized && parametrizedWorkout[2].length === 0) ||
        this.isValidParametrizedWorkout())
    );

    const isNew = id ? false : true;

    const { currentUserGroups, currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;

    const isExternalCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
      ) > -1;

    const disabled =
      isExternalCoach &&
      created_by_cognito_user_sub &&
      created_by_cognito_user_sub !== sub;

    const { language, level, showCKEditor, isDeleteOpen } = this.state;

    const levelSteps = [0, 1, 2];

    const ckEditorValue =
      description && description[language]
        ? convertToRTFForCDK(description[language])
        : "";

    let newEstimatedTime = [null, null, null];

    if (!isParametrized || (estimatedTime && Array.isArray(estimatedTime))) {
      newEstimatedTime = estimatedTime;
    }

    return (
      <div className={classes.container}>
        <div className={classes.flexContainer}>
          <div className={classes.mainDataContainer}>
            <form id={formId} onSubmit={this.handleSubmit} disabled={saving}>
              <div className={classes.titleContainer}>
                <div ref={this.ref}>
                  <FilterSelect
                    autoFocus
                    name="language"
                    text="lng"
                    value={language}
                    large={false}
                    xsmall
                    items={Languages.ItemsShortArray}
                    onSelect={this.handleLanguageSelect}
                    width={40}
                  />
                </div>

                <TextField
                  className={classes.title}
                  margin="dense"
                  name="title"
                  label={`Title`}
                  value={title && title[language] ? title[language] : ""}
                  onChange={this.handleChange}
                  fullWidth
                  required
                  disabled={disabled}
                />
              </div>

              <div
                className={classes.titleContainer}
                style={{
                  marginLeft: this.ref.current
                    ? this.ref.current.clientWidth
                    : 0,
                }}
              >
                <TextField
                  className={classes.title}
                  margin="dense"
                  name="publicTitle"
                  label="Public title"
                  value={
                    publicTitle && publicTitle[language]
                      ? publicTitle[language]
                      : ""
                  }
                  onChange={this.handlePublicTitle}
                  fullWidth
                  disabled={disabled}
                  inputProps={{ maxLength: 45 }}
                />
              </div>

              <div className={classes.workoutGeneratedTextContainer}>
                <Typography
                  variant="body1"
                  className={classes.workoutGeneratedTitle}
                >
                  Workout detail Preview
                </Typography>

                <Typography
                  variant="body1"
                  className={classes.workoutGeneratedText}
                  dangerouslySetInnerHTML={{
                    __html: workoutToText(parametrizedWorkout[level]),
                  }}
                ></Typography>
                <div></div>
              </div>

              {showCKEditor ? (
                <div className={classes.richTextEditor}>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      height: 800,
                      link: {
                        decorators: {
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      mediaEmbed: {
                        previewsInData: true,
                        providers: [
                          {
                            name: "videofilesvertrun",
                            // A URL regexp or an array of URL regexps:
                            url: /^videofilesvertrun\.s3\.amazonaws\.com\/(.+)/,

                            // To be defined only if the media are previewable:
                            html: (match) => {
                              const url = match[0];
                              return (
                                '<div style="text-align: center; width: 100%">' +
                                '<video width="100%" height="auto" controls>' +
                                `<source src="https://${url}" type="video/mp4" />` +
                                "Your browser does not support the video tag." +
                                "</video>" +
                                "</div>"
                              );
                            },
                          },
                        ],
                      },
                      readOnly: disabled,
                    }}
                    data={ckEditorValue}
                    onReady={(editor) => {
                      editor.ui.view.editable.element.style.minHeight = "500px";
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "500px",
                          editor.editing.view.document.getRoot(),
                        );
                      });
                    }}
                    onChange={this.handleChangeRTEditor}
                    required
                    disabled={disabled}
                  />
                </div>
              ) : (
                ""
              )}

              <div style={{ marginTop: 15 }}>
                <FilterSelect
                  required
                  value={trainingPeriod}
                  name="trainingPeriod"
                  text="Training Period"
                  items={TrainingPeriods.itemsArray}
                  onSelect={this.handleSelect}
                  disabled={disabled}
                  width="44%"
                  customStyles
                  customClass={classes.noPadding}
                />

                <FilterSelect
                  required
                  value={workoutType}
                  name="workoutType"
                  text="Workout Type"
                  items={WorkoutTypes.itemsArray}
                  onSelect={this.handleSelect}
                  disabled={disabled}
                  width="44%"
                  customStyles
                  customClass={classes.noPadding}
                />

                <FilterSelect
                  required
                  value={surface}
                  name="surface"
                  text="Surface"
                  items={Surfaces.itemsArray}
                  onSelect={this.handleSelect}
                  disabled={disabled}
                  width="44%"
                  customStyles
                  customClass={classes.noPadding}
                />

                <FilterSelect
                  required
                  value={
                    isParametrized ? newEstimatedTime[level] : newEstimatedTime
                  }
                  name="estimatedTime"
                  text={
                    !isParametrized
                      ? "Estimated Time"
                      : `Level ${level + 1} Estimated Time`
                  }
                  items={EstimatedTimes.itemsArray}
                  onSelect={
                    isParametrized
                      ? this.handleLevelEstimatedTimeSelect
                      : this.handleSelect
                  }
                  disabled={disabled}
                  width="44%"
                  customStyles
                  customClass={classes.noPadding}
                />

                {!isParametrized ? (
                  <FilterSelect
                    required
                    value={difficultyLevel}
                    name="difficultyLevel"
                    text="Level"
                    items={DifficultyLevels.itemsArray}
                    onSelect={this.handleSelect}
                    disabled={disabled}
                    width="44%"
                    customStyles
                  />
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>

          <div className={classes.workoutPane}>
            <div className={classes.topBar}>
              <div>
                <Button
                  variant="outlined"
                  className={classes.smallMargin}
                  onClick={() => this.handleAddStep()}
                  disabled={level !== 2}
                >
                  + Add Step
                </Button>
                <Button
                  variant="outlined"
                  className={classes.smallMargin}
                  onClick={this.handleAddRepeat}
                  disabled={level !== 2}
                >
                  + Add Repeat
                </Button>
              </div>

              <div>
                <Stepper
                  nonLinear
                  activeStep={level}
                  alternativeLabel
                  className={classes.stepper}
                >
                  {levelSteps.map((step, index) => (
                    <Step key={index} completed={false}>
                      <StepButton
                        color="inherit"
                        onClick={() => this.handleStep(index)}
                        classes={{ root: classes.customButton }}
                      >
                        <StepLabel
                          classes={{
                            label: classes.custom,
                            labelContainer: classes.labelParent,
                          }}
                        >
                          {`Level ${index + 1}`}
                        </StepLabel>
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </div>

              <div>
                {!isNew && (
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="Delete"
                      className={classes.smallMargin}
                      onClick={this.handleDeleteWorkout}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {!isNew && (
                  <Tooltip title="Duplicate">
                    <IconButton
                      aria-label="Duplicate"
                      className={classes.smallMargin}
                      onClick={this.handleDuplicateWorkout}
                    >
                      <FontAwesomeIcon variant="regular" icon="copy" />
                    </IconButton>
                  </Tooltip>
                )}

                <Button
                  onClick={this.handleClose}
                  color="primary"
                  disabled={saving}
                >
                  Cancel
                </Button>
                {!disabled && (
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    form={formId}
                    disabled={hasError || saving}
                  >
                    {isNew ? "Add" : "Update"}
                  </Button>
                )}
              </div>
            </div>

            <div className={classes.workoutStepsContainer}>
              {parametrizedWorkout[level] ? (
                <ReactSortable
                  list={parametrizedWorkout[level]}
                  setList={(newState) =>
                    this.handleSetParametrizedWorkout(newState)
                  }
                  handle=".handle"
                >
                  {parametrizedWorkout[level].map((paramStep) =>
                    paramStep.type === "step" ? (
                      <WorkoutStep
                        key={paramStep.id}
                        step={paramStep}
                        onChangeStepSelect={this.handleChangeStepSelect}
                        level={level}
                        onDeleteStep={this.handleDeleteStep}
                        onDuplicateStep={this.handleDuplicateStep}
                      />
                    ) : (
                      <WorkoutRepeat
                        key={paramStep.id}
                        repeat={paramStep}
                        onSetParametrizedWorkout={
                          this.handleSetParametrizedWorkout
                        }
                        onChangeRepeatTimes={this.handleChangeRepeatTimes}
                        onAddStep={this.handleAddStep}
                        onChangeStepSelect={this.handleChangeStepSelect}
                        level={level}
                        onDeleteStep={this.handleDeleteStep}
                        onDuplicateStep={this.handleDuplicateStep}
                      />
                    ),
                  )}
                </ReactSortable>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <Dialog
          open={isDeleteOpen}
          onClose={this.handleDeleteRequested}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this workout?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteRequested} color="primary">
              Accept
            </Button>
            <Button onClick={this.handleDeleteCancel}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

WorkoutEditor.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default connect((store) => {
  return {
    workout: store.workout,
    auth: store.auth,
  };
})(withStyles(styles)(memo(WorkoutEditor)));
