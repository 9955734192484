import React from "react";
import { ListItem, Box } from "@material-ui/core";

const ListItemWithIcon = ({ icon, children }) => {
  return (
    <ListItem
      style={{
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <Box
        style={{ display: "flex", alignItems: "center", marginRight: "8px" }}
      >
        <Box style={{ width: "14px", height: "14px", marginTop: "5px" }}>
          {icon}
        </Box>
      </Box>
      <Box style={{ flex: 1 }}>{children}</Box>
    </ListItem>
  );
};

export default ListItemWithIcon;
