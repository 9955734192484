import { useEffect, useState } from "react";
import useUserInfo from "./useUserInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  getSampleWeek,
  getSubcategories,
  PostContactData,
} from "../actions/subcategory-action";
import { LocalDB } from "../lib/local-db-storage";
import { useAuthContext } from "../context/AuthProvider";
import {
  activeCampaignInvited,
  currentTrainingAddComment,
  planChangeField,
  savePlan,
  setPlanToSelect,
  userPlanMailchimpSubscribe,
} from "../actions/user-plans-action";
import moment from "moment";
import bugsnagClient from "../lib/bugsnag-client";
import {
  ElevationIcon,
  EmailIcon,
  FlatIcon,
  HillIcon,
} from "../components/icons";
import {
  IconBarbell,
  IconBike,
  IconMapPin,
  IconSearch,
  IconSnowboarding,
  IconSwimming,
  IconTrekking,
  IconUserCircle,
  IconX,
} from "@tabler/icons-react";

const useQuestionnaire = () => {
  const subcategoriesState = useSelector((state) => state.subcategories);
  const {
    data: subCategoriesData,
    loadingSampleWeek,
    sampleWeek,
    loading: loadingSubCategories,
  } = useSelector((state) => state.subcategories);
  const { finishOnboardingAttribute } = useAuthContext();
  const { sub, name, family_name, email, latest, planToSelect } = useUserInfo();
  const dispatch = useDispatch();
  const { userPlans, isSubscribed, attributes } = useUserInfo();
  const { birthdate, gender, locale } = attributes || {};
  const [values, setValues] = useState({});
  const [plansData, setPlansData] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const onboardingStorage = new LocalDB("onboarding");
  const { finishQuestionaireAttributes } = useAuthContext();
  const [activeStep, setActiveStep] = useState(1);

  const setPlanValues = async () => {
    const selectedPlan = await onboardingStorage.getItem("onboardingPlan");
    if (selectedPlan && selectedPlan.questions) {
      const newValues = selectedPlan.questions.reduce((prev, q) => {
        return {
          ...prev,
          [q.field]: q.defaultValue ? q.defaultValue : undefined,
        };
      }, {});
      setValues((prev) => ({ ...prev, ...newValues }));
    }
  };

  const finishOnboarding = async () => {
    try {
      setLoadingSave(true);
      await finishOnboardingAttribute();

      const selectedPlan = await onboardingStorage.getItem("onboardingPlan");
      const selectedPlanType = await onboardingStorage.getItem(
        "onboardingPlanType",
      );
      const onboardingitems = await onboardingStorage.getItem(
        "onboardingSuggester",
      );

      await dispatch(planChangeField(onboardingitems));

      await dispatch(
        setPlanToSelect({
          ...planToSelect,
          redirect: selectedPlanType === "free" ? "free-mobile" : null,
        }),
      );

      if (selectedPlanType !== "free" && isSubscribed === false) {
        throw new Error("Subscription required");
      }
      if (
        onboardingitems.birthdate &&
        onboardingitems.country &&
        onboardingitems.gender
      ) {
        await finishQuestionaireAttributes(
          onboardingitems.birthdate,
          onboardingitems.country,
          onboardingitems.gender,
        );
      }

      const saveItem = {
        ...onboardingitems,
        other_sport: [onboardingitems.other_sport],
        trainHoursPerWeek_raw: onboardingitems.trainHoursPerWeek || undefined,
        subcategory:
          selectedPlan && selectedPlan.id
            ? selectedPlan.id.toString()
            : undefined,
        selectedPlan:
          selectedPlanType === "coach" || selectedPlanType === "explore"
            ? "explore"
            : "free",
        cognito_user_name: name
          ? name.toLowerCase()
          : email.split("@")[0].toLowerCase(),
        cognito_user_family_name: family_name && family_name.toLowerCase(),
        cognito_user_email: email,
        cognito_user_sub: sub,
        sex: gender
          ? gender
          : onboardingitems.gender
          ? onboardingitems.gender
          : undefined,
        country: locale
          ? locale
          : onboardingitems.country
          ? onboardingitems.country
          : undefined,
        birthdate: birthdate
          ? birthdate
          : onboardingitems.birthdate
          ? onboardingitems.birthdate
          : undefined,
        distance: onboardingitems.distance || undefined,
        needHelp: selectedPlanType === "coach" ? true : false,
      };

      await dispatch(
        savePlan(
          saveItem,
          latest,
          subcategoriesState,
          null,
          selectedPlanType === "coach" ? true : false,
        ),
      );

      if (onboardingitems && onboardingitems.invitedList) {
        const emailsArray = Object.values(onboardingitems.invitedList);
        const invitedList = {};
        emailsArray.forEach((value, index) => {
          invitedList[`friend${index + 1}`] = value;
        });
        await dispatch(
          PostContactData({
            Subject: "Invite friends",
            name,
            email,
            message: `Invitation emails: ${emailsArray.toString()}`,
          }),
        );
        if (Object.keys(invitedList).length) {
          await userPlanMailchimpSubscribe({
            cognito_user_sub: sub,
            cognito_user_email: email,
            ...invitedList,
          });
        }
        for (const property in invitedList) {
          await dispatch(
            activeCampaignInvited(sub, {
              invitedUserEmail: invitedList[property],
              invitedByEmail: email,
              invitedByName: name,
            }),
          );
        }
      }

      if (selectedPlanType === "coach") {
        await dispatch(
          userPlanMailchimpSubscribe({
            cognito_user_sub: sub,
            cognito_user_email: email,
            helpChoosingPlan: "help-choosing-plan",
          }),
        );
      }

      if (
        onboardingitems &&
        onboardingitems.message &&
        onboardingitems.message !== ""
      ) {
        const comment = {
          text: onboardingitems.message || "",
          dateTime: new Date().getTime(),
          date: moment().format("DD/MM/YYYY"),
          type: 1,
        };

        if (onboardingitems.message && onboardingitems.message.length) {
          dispatch(
            currentTrainingAddComment(
              {
                cognito_user_sub: sub,
                comment: {
                  ...comment,
                  isReadByCoach: false,
                  senderName: name,
                  cognito_user_sub: sub,
                },
                lastModified: new Date().getTime(),
              },
              false,
              true,
            ),
          );
        }
      }
      setLoadingSave(false);
      await onboardingStorage.clear();
    } catch (e) {
      setLoadingSave(false);
      bugsnagClient.notify(e);
    }
  };

  const planToSelectQuestionaire = async (plan) => {
    dispatch(
      setPlanToSelect({
        plan: plan.plan,
        id: plan.id,
        cognito_user_sub: sub,
        cognito_user_email: email,
      }),
    );
  };

  useEffect(() => {
    dispatch(getSubcategories({ readOnlyAvailables: true }));

    const loadActiveStep = async () => {
      const savedStep = await onboardingStorage.getItem("activeStep");
      if (savedStep) {
        setActiveStep(savedStep);
      }
    };
    loadActiveStep();
  }, []);

  const getSample = (getParams) => {
    dispatch(getSampleWeek(getParams));
  };
  const getSubcategoriesByPlan = (plan) => {
    const data = subCategoriesData
      ? subCategoriesData
          .filter((subcategory) => plan.includes(subcategory.id))
          .map((subcategory) => ({ ...subcategory }))
      : [];
    setPlansData(data);
  };

  const saveActiveStep = async (step) => {
    setActiveStep(step);
    await onboardingStorage.setItem("activeStep", step);
  };

  return {
    values,
    setValues,
    userPlans,
    subCategoriesData,
    onboardingStorage,
    getSubcategoriesByPlan,
    plansData,
    getSample,
    loadingSampleWeek,
    sampleWeek,
    loadingSubCategories,
    finishOnboarding,
    loadingSave,
    setPlanValues,
    planToSelect,
    initialStepsConfig,
    planToSelectQuestionaire,
    activeStep,
    saveActiveStep,
  };
};

export default useQuestionnaire;

const initialStepsConfig = [
  {
    name: "welcome",
    components: [
      {
        type: "title",
        value: "onboarding.questions.welcome.title",
      },
      {
        type: "subTitle",
        value: "onboarding.questions.welcome.subtitle",
      },
      {
        type: "date",
        name: "birthdate",
        placeholder: "onboarding.questions.welcome.birthdatePlaceholder",
      },
      {
        type: "dropdown",
        placeholder: "onboarding.questions.welcome.genderPlaceholder",
        options: [
          {
            label: "onboarding.questions.welcome.genderWoman",
            value: "Female",
          },
          { label: "onboarding.questions.welcome.genderMan", value: "Male" },
          {
            label: "onboarding.questions.welcome.genderNonBinary",
            value: "Other",
          },
          { label: "onboarding.questions.welcome.genderNone", value: "Other" },
        ],
        value: "",
        name: "gender",
        icon: { component: IconUserCircle, color: "#6D6F71" },
      },
      {
        type: "textInput",
        placeholder: "onboarding.questions.welcome.countryPlaceholder",
        value: "",
        name: "country",
        icon: { component: IconMapPin, color: "#6D6F71" },
      },
    ],
    buttonComponents: [
      {
        type: "button",
        value: "onboarding.questions.welcome.continueButton",
      },
    ],
  },
  {
    name: "trainPerWeek",
    components: [
      {
        type: "title",
        value: "onboarding.questions.trainPerWeek.title",
        highlight: "days",
      },
      {
        type: "toggleButtonGroup",
        options: [
          { label: "onboarding.questions.trainPerWeek.3", value: "3" },
          { label: "onboarding.questions.trainPerWeek.4", value: "4" },
          { label: "onboarding.questions.trainPerWeek.5", value: "5" },
          { label: "onboarding.questions.trainPerWeek.6", value: "6" },
          { label: "onboarding.questions.trainPerWeek.7", value: "7" },
        ],
        value: "3",
        name: "trainPerWeek",
      },
    ],
    buttonComponents: [
      {
        type: "button",
        value: "onboarding.questions.trainPerWeek.continueButton",
      },
    ],
  },
  {
    name: "trainHoursPerWeek",
    components: [
      {
        type: "title",
        value: "onboarding.questions.trainHoursPerWeek.title",
        highlight: "onboarding.questions.trainHoursPerWeek.titleHighlight",
      },
      {
        type: "subTitle",
        value: "onboarding.questions.trainHoursPerWeek.subtitle",
      },
      {
        type: "slider",
        value: 0,
        min: 0,
        step: 1,
        max: 20,
        text: "onboarding.questions.trainHoursPerWeek.sliderText",
        unit: "onboarding.questions.trainHoursPerWeek.sliderUnit",
        name: "trainHoursPerWeek",
      },
    ],
    buttonComponents: [
      {
        type: "button",
        value: "onboarding.questions.trainHoursPerWeek.continueButton",
      },
    ],
  },
  {
    name: "raceCount",
    components: [
      {
        type: "title",
        value: "onboarding.questions.raceCount.title",
        highlight: "onboarding.questions.raceCount.titleHighlight",
      },
      {
        type: "toggleButtonGroup",
        options: [
          { label: "onboarding.questions.raceCount.0", value: "0" },
          { label: "onboarding.questions.raceCount.1", value: "1" },
          { label: "onboarding.questions.raceCount.3", value: "3" },
          { label: "onboarding.questions.raceCount.5", value: "5" },
          { label: "onboarding.questions.raceCount.6", value: "6" },
          { label: "onboarding.questions.raceCount.7", value: "7+" },
        ],
        value: "0",
        name: "raceCount",
      },
    ],
    buttonComponents: [
      {
        type: "button",
        value: "onboarding.questions.raceCount.continueButton",
      },
    ],
  },
  {
    name: "longestRun",
    components: [
      {
        type: "title",
        value: "onboarding.questions.longestRun.title",
      },
      {
        type: "slider",
        value: 0,
        min: 0,
        max: 50,
        step: 10,
        text: "onboarding.questions.longestRun.sliderText",
        unit: "onboarding.questions.longestRun.sliderUnit",
        name: "longestRun",
      },
    ],
    buttonComponents: [
      {
        type: "button",
        value: "onboarding.questions.longestRun.continueButton",
      },
    ],
  },
  {
    name: "condition",
    components: [
      {
        type: "title",
        value: "onboarding.questions.condition.title",
      },
      {
        type: "buttonGroup",
        options: [
          {
            label: "onboarding.questions.condition.flat",
            value: "flat",
            icon: FlatIcon,
            color: "#6D6F71",
          },
          {
            label: "onboarding.questions.condition.hillyRunning",
            value: "hilly_running",
            icon: HillIcon,
            color: "#6D6F71",
          },
          {
            label: "onboarding.questions.condition.climbRunning",
            value: "climb_running",
            icon: ElevationIcon,
            color: "#6D6F71",
          },
          {
            label: "onboarding.questions.condition.weekends",
            value: "weekends",
            icon: ElevationIcon,
            color: "#6D6F71",
          },
        ],
        value: "flat",
        name: "condition",
      },
    ],
    buttonComponents: [
      {
        type: "button",
        value: "onboarding.questions.condition.continueButton",
      },
    ],
  },
  {
    name: "other_sports",
    components: [
      {
        type: "title",
        value: "onboarding.questions.otherSports.title",
      },
      {
        type: "buttonGroup",
        options: [
          {
            label: "onboarding.questions.otherSports.noOtherSports",
            value: "no_other_sports",
            icon: IconX,
            color: "#6D6F71",
          },
          {
            label: "onboarding.questions.otherSports.roadCycling",
            value: "road_cycling",
            icon: IconBike,
            color: "#6D6F71",
          },
          {
            label: "onboarding.questions.otherSports.swimming",
            value: "swimming",
            icon: IconSwimming,
            color: "#6D6F71",
          },
          {
            label: "onboarding.questions.otherSports.weightLifting",
            value: "weight_lifting",
            icon: IconBarbell,
            color: "#6D6F71",
          },
          {
            label: "onboarding.questions.otherSports.alpineSkiing",
            value: "alpine_skiing",
            icon: IconSnowboarding,
            color: "#6D6F71",
          },
          {
            label: "onboarding.questions.otherSports.hiking",
            value: "hiking",
            icon: IconTrekking,
            color: "#6D6F71",
          },
          {
            label: "onboarding.questions.otherSports.otherSports",
            value: "other_sports",
            icon: IconSearch,
            color: "#6D6F71",
          },
        ],
        value: "no_other_sports",
        name: "other_sport",
      },
    ],
    buttonComponents: [
      {
        type: "button",
        value: "onboarding.questions.otherSports.continueButton",
      },
    ],
  },
  {
    name: "race_planned",
    components: [
      {
        type: "title",
        value: "onboarding.questions.racePlanned.title",
      },
      {
        type: "subTitle",
        value: "onboarding.questions.racePlanned.subtitle",
      },
      {
        type: "dashedButton",
        value: "onboarding.questions.racePlanned.placeHolder",
        function: "openForm",
      },
      {
        type: "raceInput",
      },
    ],
    buttonComponents: [
      {
        type: "button",
        value: "onboarding.questions.racePlanned.continueButton",
      },
    ],
  },
  {
    name: "desiredStartDate",
    components: [
      {
        type: "title",
        value: "onboarding.questions.desiredStartDate.title",
      },
      {
        type: "subTitle",
        value: "onboarding.questions.desiredStartDate.subtitle",
      },
      {
        type: "date",
        name: "desiredStartDate",
        placeholder: "onboarding.questions.desiredStartDate.placeholder",
      },
    ],
    buttonComponents: [
      {
        type: "button",
        value: "onboarding.questions.desiredStartDate.continueButton",
      },
    ],
  },
  {
    name: "chat",
    components: [
      {
        type: "title",
        value: "onboarding.questions.chat.title",
      },
      {
        type: "subTitle",
        value: "onboarding.questions.chat.subtitle",
      },
      {
        type: "chat",
        chatPlaceholder: "onboarding.questions.chat.chatMessage",
        placeholder: "onboarding.questions.chat.placeholder",
        name: "message",
        value: "",
      },
    ],
    buttonComponents: [
      {
        type: "textButton",
        value: "onboarding.questions.chat.skipButton",
      },
    ],
  },
  {
    name: "invitedList",
    components: [
      {
        type: "title",
        value: "onboarding.questions.invitedList.title",
      },
      {
        type: "subTitle",
        value: "onboarding.questions.invitedList.subtitle",
      },
      {
        type: "emailInput",
        value: {},
        placeholder: "onboarding.questions.invitedList.placeholder",
        buttonValue: "onboarding.questions.invitedList.buttonPlaceholder",
        name: "invitedList",
        icon: { component: EmailIcon, color: "#6D6F71" },
      },
    ],
    buttonComponents: [
      {
        type: "button",
        value: "onboarding.questions.invitedList.continueButton",
      },
    ],
  },
];
