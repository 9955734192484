import ActionTypes from "../constants/action-types";
import WorkoutsService from "../services/workouts-service";

const searchWorkouts = (searchParams) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.WORKOUT_SEARCH,
      payload: WorkoutsService.searchWorkouts(searchParams),
    });
  };
};

const generalSearchWorkouts = (searchParams) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.WORKOUT_SEARCH,
      payload: WorkoutsService.generalSearchWorkouts(searchParams),
    });
  };
};

const cleanSearch = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.WORKOUT_CLEAN_SEARCH });
  };
};

const saveWorkout = (workout) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.WORKOUT_SAVE_PENDING });
    try {
      const result = await WorkoutsService.saveWorkout(workout);
      dispatch({ type: ActionTypes.WORKOUT_SAVE_FULFILLED, payload: result });
    } catch (err) {
      dispatch({
        type: ActionTypes.WORKOUT_SAVE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const changeSearchPhrase = (searchPhrase) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.WORKOUT_SEARCH_PHRASE_CHANGED,
      payload: searchPhrase,
    });
  };
};

const changeSearchFilter = (searchFilter) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.WORKOUT_SEARCH_FILTER_CHANGED,
      payload: searchFilter,
    });
  };
};

const changeSearchParams = (searchParams) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.WORKOUT_SEARCH_PARAM_CHANGED,
      payload: searchParams,
    });
  };
};

const readWorkout = (id) => {
  return function(dispatch) {
    dispatch({ type: ActionTypes.WORKOUT_READ, payload: id });
  };
};

const setWorkout = (workout) => {
  return function(dispatch) {
    dispatch({ type: ActionTypes.WORKOUT_SET, payload: workout });
  };
};

const cleanReadWorkoutFields = () => {
  return function(dispatch) {
    dispatch({ type: ActionTypes.WORKOUT_CLEAN_FIELDS });
  };
};

const workoutChangeField = (workoutToSave) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.WORKOUT_CHANGE_FIELD,
      payload: workoutToSave,
    });
  };
};

const setErrorMessage = (err) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.WORKOUT_SET_ERROR_MESSAGE, payload: err });
  };
};

const clearErrorMessage = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.WORKOUT_CLEAR_ERROR_MESSAGE });
  };
};

const setSuccessMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.WORKOUT_SET_SUCCESS_MESSAGE,
      payload: message,
    });
  };
};

const clearSuccessMessage = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.WORKOUT_CLEAR_SUCCESS_MESSAGE });
  };
};

const deleteBulkWorkout = (ids) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.WORKOUT_DELETE,
      payload: WorkoutsService.deleteBulkWorkout(ids),
    });
  };
};

const setFormTrackerStatus = (status) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.FROM_TRACKER_STATUS,
      payload: status,
    });
  };
};

export {
  searchWorkouts,
  generalSearchWorkouts,
  cleanSearch,
  saveWorkout,
  changeSearchParams,
  changeSearchPhrase,
  changeSearchFilter,
  readWorkout,
  cleanReadWorkoutFields,
  workoutChangeField,
  setErrorMessage,
  clearErrorMessage,
  setSuccessMessage,
  clearSuccessMessage,
  deleteBulkWorkout,
  setFormTrackerStatus,
  setWorkout,
};
