import React, { useState, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { Box, makeStyles } from "@material-ui/core";
import { CustomTabs } from "./custom-tabs";
import { RaceList } from "./Races/race-list";
import { ActivityList } from "./Activity/activity-list";
import { HomeProfile } from "./Home";
import { palette } from "../../theme/palette";
import { useDispatch } from "react-redux";
import { getUserActivities } from "../../actions/user-plans-action";
import SwipeableViews from "react-swipeable-views";

const ProfileView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [tab, setTab] = useState(0);

  const handleSwipeChangeIndex = (index) => {
    setTab(index);
  };

  const handleTabChange = (_, tabIndex) => {
    setTab(tabIndex);
  };

  useEffect(() => {
    dispatch(getUserActivities());
  }, [dispatch]);

  return (
    <MuiThemeProvider theme={theme}>
      <Box className={classes.fixedTabs}>
        <CustomTabs onChange={handleTabChange} tabIndex={tab} />
      </Box>
      <Box className={classes.contentWrapper}>
        <SwipeableViews
          containerStyle={{ height: "100%" }}
          style={{ height: "100%" }}
          index={tab}
          onChangeIndex={handleSwipeChangeIndex}
        >
          <div
            className={classes.tabContent}
            style={{ display: tab === 0 ? "flex" : "none" }}
          >
            <HomeProfile />
          </div>
          <div
            className={classes.tabContent}
            style={{ display: tab === 1 ? "flex" : "none" }}
          >
            <RaceList />
          </div>
          <div
            className={classes.tabContent}
            style={{ display: tab === 2 ? "flex" : "none" }}
          >
            <ActivityList />
          </div>
        </SwipeableViews>
      </Box>
    </MuiThemeProvider>
  );
};

export default ProfileView;

const useStyles = makeStyles({
  tabContent: {
    flex: 1,
    backgroundColor: palette.lightGrayOpacity,
    flexDirection: "column",
    padding: "0 20px",
    paddingBottom: "20px",
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
    height: "100%",
  },
  contentWrapper: {
    flex: 1,
    overflowY: "auto",
    width: "100%",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  fixedTabs: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 1000,
    backgroundColor: "#fff",
  },
});
