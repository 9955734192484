import React, { useEffect, useRef, useState } from "react";
import { Skeleton } from "@mui/material";
import {
  Box,
  Card,
  CardMedia,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HeartIcon, CommentIcon } from "../../icons";
import dayjs from "dayjs";
import { getImage } from "../../../lib/common";
import { gsap } from "gsap";
import * as relativeTime from "dayjs/plugin/relativeTime";
import usePost from "../../../hooks/usePost";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { RaceCard } from "../../profile/Races/race-card";
import history from "../../../lib/history-helper";

dayjs.extend(relativeTime);

const PostCardHomeComponent = ({ postData = new Object() }) => {
  const [imageURL, setImageURL] = useState(null);
  const [avatarURL, setAvatarURL] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const classes = useStyles();
  const { handleAddLikes, liked, handleGetLikes } = usePost();
  const heartRef = useRef(null);
  const cardActions = {
    cardClick: false,
    share: false,
    menu: false,
    edit: false,
    delete: false,
    link: false,
  };

  const handleLike = () => {
    handleAddLikes(postData.id);
    gsap.fromTo(
      heartRef.current,
      { scale: 1 },
      { scale: 1.3, duration: 0.2, yoyo: true, repeat: 1 },
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (postData.image) {
        const url = await fetchImageData(postData.image);
        setImageURL(url);
      }

      if (postData.by && postData.by.image) {
        const avatarUrl = await fetchImageData(postData.by.image);
        setAvatarURL(avatarUrl);
      }
    };
    handleGetLikes(postData);
    fetchData();
  }, [postData.image, postData.by]);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const handleDetailsClick = () => {
    history.push(`/trailhead/post/${postData.id}`);
  };

  return (
    <Card className={classes.card} elevation={0}>
      <CardHeader
        avatar={
          <Link
            to={
              postData.by
                ? `/trailhead/profile/${postData.by.cognito_user_sub}`
                : `/trailhead`
            }
          >
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
              src={
                avatarURL
                  ? avatarURL
                  : `${process.env.PUBLIC_URL}/avatar-empty.png`
              }
            />
          </Link>
        }
        title={
          <Typography className={classes.heading}>
            {postData.by != null && postData.by.cognito_user_name}
          </Typography>
        }
        subheader={
          <Typography className={classes.subHeading}>
            {postData.created_at != null &&
              dayjs(postData.created_at).fromNow()}
          </Typography>
        }
      />
      <CardContent className={classes.content} onClick={handleDetailsClick}>
        {postData.text && (
          <Typography className={classes.postText}>{postData.text}</Typography>
        )}
        {postData.image ? (
          <Box className={classes.boxContent}>
            {!imageLoaded && (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            )}
            <CardMedia
              component="img"
              image={imageURL}
              alt="Post"
              loading="lazy"
              className={classes.cardMedia}
              style={{
                opacity: imageLoaded ? 1 : 0,
                transition: "opacity 0.3s ease-in-out",
              }}
              onLoad={handleImageLoaded}
            />
          </Box>
        ) : (
          <Box className={classes.boxContent}>
            {postData.goal != null ? (
              <Box style={{ scale: 0.3 }}>
                <RaceCard race={postData.goal} enableActions={cardActions} />
              </Box>
            ) : (
              <Box style={{ height: "100%" }} />
            )}
          </Box>
        )}
      </CardContent>
      <CardActions disableSpacing className={classes.cardActions}>
        <IconButton
          data-testid="heart_icon"
          id="heart_icon"
          disableRipple
          disableFocusRipple
          aria-label="Like"
          onClick={handleLike}
          ref={heartRef}
        >
          <HeartIcon state={liked ? "filled" : "outline"} />
        </IconButton>
        <IconButton
          disableRipple
          disableFocusRipple
          aria-label="comment"
          data-testid="comment_icon"
          onClick={handleDetailsClick}
          id="comment_icon"
        >
          <CommentIcon state="outline" />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default PostCardHomeComponent;

async function fetchImageData(image) {
  try {
    const imageUrl = await getImage([image]);
    return imageUrl;
  } catch (error) {
    return null;
  }
}

const useStyles = makeStyles({
  card: {
    width: "275px",
    maxWidth: "275px",
    height: "350px",
    maxHeight: "350px",
    borderRadius: "8px",
    boxShadow: 0,
    backgroundColor: "#FEFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
  heading: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  subHeading: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#6D6F71",
  },
  postText: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#12151B",
    padding: "0px 8px",
    marginBottom: "16px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    padding: "0px",
    overflow: "hidden",
  },
  boxContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    overflow: "hidden",
    height: "150px",
  },
  cardMedia: {
    borderRadius: "4px",
    objectFit: "cover",
    maxHeight: "100%",
  },
  cardActions: {
    justifyContent: "end",
    padding: "8px",
  },
});
