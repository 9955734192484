import React from "react";
import { Route } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "../../theme";
import routes from "./routes";
import PrivateRoute from "../../components/private-route";
import { AuthProvider } from "../../context/AuthProvider";

const DesktopLayout = () => {
  return (
    <AuthProvider>
      <MuiThemeProvider theme={theme}>
        {routes.map((route) => {
          if (route.private)
            return (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
              />
            );
          else
            return (
              <PrivateRoute
                key={route.path}
                path={route.path}
                component={route.component}
              />
            );
        })}
      </MuiThemeProvider>
    </AuthProvider>
  );
};

export default DesktopLayout;
