import ActionTypes from "../constants/action-types";
import bugsnagClient from "../lib/bugsnag-client";

const initialState = {
  isAuthenticated: false,
  currentUser: undefined,
  currentUserLoading: true,
  page: "sign-up",
  showUpdate: false,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.AUTH_CURRENT_USER_PENDING:
        return {
          ...state,
          currentUserLoading: true,
          isAuthenticated: false,
          currentUser: undefined,
        };
      case ActionTypes.AUTH_UPDATE_ATTRIBUTES:
        return {
          ...state,
          currentUser: {
            ...state.currentUser,
            attributes: {
              ...state.currentUser.attributes,
              ...action.payload,
            },
          },
        };
      case ActionTypes.AUTH_CURRENT_USER_FULFILLED:
        let userGroups = undefined;
        const currentUser = action.payload;
        const { signInUserSession } = currentUser;
        if (signInUserSession) {
          const { idToken } = signInUserSession;
          if (idToken) {
            const { payload } = idToken;
            if (payload) {
              const groups = payload["cognito:groups"];
              userGroups = groups && groups.length > 0 ? groups : undefined;
            }
          }
        }
        if (bugsnagClient && currentUser && currentUser.attributes)
          bugsnagClient.user = {
            ...(bugsnagClient.user || {}),
            appUser: currentUser.attributes,
            appUserSub: currentUser.attributes.sub,
          };
        return {
          ...state,
          currentUserLoading: false,
          isAuthenticated: true,
          currentUser: currentUser,
          currentUserGroups: userGroups,
        };
      case ActionTypes.AUTH_CURRENT_USER_SESSION_CHANGED:
        let newUserGroups = undefined;
        const currentUserWithNewSession = {
          ...state.currentUser,
          signInUserSession: action.payload,
        };
        if (action.payload) {
          const { idToken } = action.payload;
          if (idToken) {
            const { payload } = idToken;
            if (payload) {
              const groups = payload["cognito:groups"];
              newUserGroups = groups && groups.length > 0 ? groups : undefined;
            }
          }
        }
        return {
          ...state,
          currentUser: currentUserWithNewSession,
          currentUserGroups: newUserGroups,
        };
      case ActionTypes.AUTH_CURRENT_USER_REJECTED:
        return {
          ...state,
          currentUserLoading: false,
          isAuthenticated: false,
          currentUser: undefined,
        };
      case ActionTypes.AUTH_CURRENT_USER_SIGNOUT_PENDING:
        return {
          ...state,
          currentUserSigningOut: true,
        };

      case ActionTypes.AUTH_SHOW_UPDATE_FULFILLED:
        return {
          ...state,
          showUpdate: action.payload,
        };
      case ActionTypes.AUTH_CURRENT_USER_SIGNOUT_FULFILLED:
        return {
          ...state,
          currentUserSigningOut: false,
          isAuthenticated: false,
          currentUser: undefined,
        };
      case ActionTypes.AUTH_CURRENT_USER_SIGNOUT_REJECTED:
        return {
          ...state,
          currentUserSigningOut: false,
        };
      case ActionTypes.AUTH_USER_SET_ERROR:
        return {
          ...state,
          generalErrorMessage:
            action.payload ||
            "Something went wrong, please check all your fields and try again",
        };
      case ActionTypes.AUTH_USER_CLEAR_ERROR:
        return {
          ...state,
          generalErrorMessage: undefined,
        };
      case ActionTypes.AUTH_USER_SET_PAGE:
        return {
          ...state,
          page: action.payload || state.page,
        };
      default:
        return state;
    }
  } else {
    return state;
  }
}
