import Pusher from "pusher-js";

const pusherClient =
  process.env.NODE_ENV === "test"
    ? {
        connection: { state: "connected", connect: () => {} },
        disconnect: () => {},
      }
    : new Pusher(process.env.REACT_APP_PUSHER_APIKEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true,
      });

export default pusherClient;
