import React, { Component } from "react";
import {
  withMobileDialog,
  Typography,
  Dialog,
  DialogContent,
  CircularProgress,
  Drawer,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { getLevel } from "../../lib/rates-helper";
import { convertUnits, round } from "../../lib/units-helper";

const styles = (theme) => ({
  container: {
    display: "flex",
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      margin: 10,
    },
  },

  noPadding: {
    padding: "0 !important",
    background: "#E5E5E5",
  },

  topContainer: {
    display: "flex",
    padding: 20,
    backgroundColor: "#FFFFFF",
  },

  progressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  loading: {
    margin: "auto",
  },

  title: {
    fontWeight: "normal",
    fontSize: 18,
    color: "#FF5722",
  },

  subtitle: {
    fontWeight: "normal",
    fontSize: 18,
  },

  summary: {
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "0px 0px 12px 12px",
    paddingBottom: 20,
  },

  badge: {
    height: 100,
  },

  nameText: {
    fontWeight: 500,
    fontSize: 18,
    margin: "12px 0",
  },

  summaryStats: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },

  summaryStatDistance: {
    color: "#FF5722",
  },

  summaryStatElevation: {
    color: "#3F51B5",
  },

  summaryValue: {
    fontWeight: "500",
    fontSize: 16,
  },

  summaryLabel: {
    fontWeight: "normal",
    fontSize: 14,
  },

  vDivider: {
    borderLeft: "1px solid #E0E0E0",
    height: 24,
  },

  levelsContainer: {
    padding: 20,
  },

  disclaimer: {
    fontWeight: "normal",
    fontSize: 12,
    textAlign: "center",
    color: "#9E9E9E",
    marginBottom: 20,
  },

  levelContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },

  levelBadge: {
    height: 70,
    marginRight: 20,
  },

  levelName: {
    fontWeight: 500,
    fontSize: 18,
    marginBottom: 6,
  },

  levelMinMax: {
    display: "flex",
    marginTop: 4,
  },

  levelMinMaxValues: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#9E9E9E",
    marginLeft: 5,
  },

  drawerRoot: {
    minHeight: "100vh",
    maxWidth: "500px",
    minWidth: "500px",
    background: "#EFF3F5",
    overflowX: "hidden",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "100vw",
      minWidth: "100vw",
    },
  },

  backIcon: {
    cursor: "pointer",
    position: "absolute",
    top: 5,
    right: 5,
    [theme.breakpoints.down("sm")]: {
      top: 15,
      right: 15,
    },
  },

  imgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 18,
    position: "relative",
  },

  lvlup: {
    position: "absolute",
    right: -4,
    top: "50%",
    transform: "translate(0, -60px)",
  },

  dPos: {
    position: "absolute",
    right: 0,
    top: 60,
  },
});

class TrailLevelPopup extends Component {
  componentDidMount = () => {};

  render() {
    const { classes, t, isOpen, onClose, userPlans, auth, width } = this.props;

    const { currentUser } = auth;
    let currentUserFullName = "";
    if (currentUser) {
      const { attributes } = currentUser;
      if (attributes) {
        const { name, family_name, email } = attributes;
        currentUserFullName =
          name || family_name ? `${name} ${family_name || ""}`.trim() : email;
      }
    }

    const { currentPlan, loading } = userPlans;

    const { accumulatedDistance = 0, accumulatedElevation = 0 } =
      currentPlan || {};

    const level = getLevel(accumulatedDistance, accumulatedElevation);

    const from = "kms";

    const units = localStorage.getItem("units") === "miles" ? "miles" : "kms";

    const levels = [
      {
        level: 1,
        distanceMin: 0,
        distanceMax: 79,
        elevationMin: 0,
        elevationMax: 4999,
      },
      {
        level: 2,
        distanceMin: 80,
        distanceMax: 199,
        elevationMin: 5000,
        elevationMax: 7999,
      },
      {
        level: 3,
        distanceMin: 200,
        distanceMax: 499,
        elevationMin: 8000,
        elevationMax: 11999,
      },
      {
        level: 4,
        distanceMin: 500,
        distanceMax: 999,
        elevationMin: 12000,
        elevationMax: 19999,
      },
      {
        level: 5,
        distanceMin: 1000,
        distanceMax: 2499,
        elevationMin: 20000,
        elevationMax: 34999,
      },
      {
        level: 6,
        distanceMin: 2500,
        distanceMax: 4999,
        elevationMin: 35000,
        elevationMax: 49999,
      },
      {
        level: 7,
        distanceMin: 5000,
        distanceMax: 14999,
        elevationMin: 50000,
        elevationMax: 99999,
      },
      {
        level: 8,
        distanceMin: 15000,
        distanceMax: -1,
        elevationMin: 100000,
        elevationMax: -1,
      },
    ];

    const isInMobileView = width === "sm" || width === "xs";

    const levelData = levels[level - 1];

    const l = level < levels.length ? levels[level] : null;

    const content = l ? (
      <div className={classes.container}>
        <img
          src={`${process.env.PUBLIC_URL}/close.svg`}
          alt=""
          onClick={() => onClose()}
          className={classes.backIcon}
        />

        <div className={classes.imgContainer}>
          <img
            src={`${process.env.PUBLIC_URL}/trl${level}.svg`}
            className={classes.badge}
          />
          <img
            src={`${process.env.PUBLIC_URL}/icon-levelup.svg`}
            className={classes.lvlup}
          />
        </div>
        <div>
          <div className={classes.levelsContainer}>
            <div key={l.level} className={classes.levelContainer}>
              <div>
                <Typography variant="h3" className={classes.title}>
                  {t("Congratulations")},
                </Typography>

                <Typography variant="body1" className={classes.subtitle}>
                  {t("you have reached a")}{" "}
                  <b>{t("X Trail Runner Level", { level: levelData.level })}</b>
                </Typography>

                <Typography
                  variant="h3"
                  className={classes.levelMinMaxValues}
                  style={{
                    marginLeft: 0,
                    marginTop: 16,
                  }}
                >
                  {t("Your next objectives are:")}
                </Typography>

                <div className={classes.levelMinMax}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-level-distance.svg`}
                    className={classes.levelMinMaxIcon}
                  />
                  <Typography
                    variant="h3"
                    className={classes.levelMinMaxValues}
                  >
                    {round(
                      convertUnits(l.distanceMin, from, units, "distance"),
                      0,
                    )}
                    {l.distanceMax >= 0 ? ` - ` : ""}
                    {l.distanceMax >= 0
                      ? round(
                          convertUnits(l.distanceMax, from, units, "distance"),
                          0,
                        )
                      : ""}{" "}
                    {units === "miles" ? `${t("miles")}` : `${t("km")}`}
                    {l.distanceMax >= 0 ? "" : t(" and more")}
                    {"; "}
                  </Typography>

                  {!isInMobileView ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-level-elevation.svg`}
                      className={classes.levelMinMaxIcon}
                      style={{
                        marginLeft: 8,
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {!isInMobileView ? (
                    <Typography
                      variant="h3"
                      className={classes.levelMinMaxValues}
                    >
                      {round(
                        convertUnits(l.elevationMin, from, units, "elevation"),
                        0,
                      )}
                      {l.elevationMax >= 0 ? ` - ` : ""}
                      {l.elevationMax >= 0
                        ? round(
                            convertUnits(
                              l.elevationMax,
                              from,
                              units,
                              "elevation",
                            ),
                            0,
                          )
                        : ""}{" "}
                      {units === "miles" ? `${t("feet")}` : `${t("m")}`}
                      {l.elevationMax >= 0 ? "" : t(" and more")}
                    </Typography>
                  ) : (
                    ""
                  )}
                </div>

                {isInMobileView ? (
                  <div className={classes.levelMinMax}>
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-level-elevation.svg`}
                      className={classes.levelMinMaxIcon}
                    />

                    <Typography
                      variant="h3"
                      className={classes.levelMinMaxValues}
                    >
                      {round(
                        convertUnits(l.elevationMin, from, units, "elevation"),
                        0,
                      )}
                      {l.elevationMax >= 0 ? ` - ` : ""}
                      {l.elevationMax >= 0
                        ? round(
                            convertUnits(
                              l.elevationMax,
                              from,
                              units,
                              "elevation",
                            ),
                            0,
                          )
                        : ""}{" "}
                      {units === "miles" ? `${t("feet")}` : `${t("m")}`}
                      {l.elevationMax >= 0 ? "" : t(" and more")}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );

    return !isInMobileView ? (
      <Dialog
        open={isOpen}
        onClose={onClose}
        classes={{
          paper: classes.dPos,
        }}
        hideBackdrop={true}
      >
        <DialogContent className={classes.noPadding}>{content}</DialogContent>
      </Dialog>
    ) : (
      <div>{content}</div>
    );
  }
}

TrailLevelPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  fullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  userPlans: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
    auth: store.auth,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation("dashboard")(
      withWidth()(withMobileDialog()(TrailLevelPopup)),
    ),
  ),
);
