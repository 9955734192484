import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, TextField } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import PlanFieldTransition from './plan-field-transition';

const styles = {
    textField: {
        marginTop: 0
    }
}
class PlanFieldDropdown extends Component {
    render() {
        const { classes, className, xs, sm, md, lg, xl, id, value, onChange, items, required, t } = this.props;
        return (
            <PlanFieldTransition>
                <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <TextField
                        className={classNames(classes.textField, className)}
                        id={id}
                        select
                        value={value}
                        onChange={onChange}
                        SelectProps={{
                            native: true
                        }}
                        margin="normal"
                        required={required}
                        variant="outlined">
                        <option key="empty" value="">{t("Select")}</option>
                        {items.map(item => (
                            <option key={item.value} value={item.value}>
                                {item.text}
                            </option>
                        ))}
                    </TextField>
                </Grid>
            </PlanFieldTransition>
        );
    };
}

PlanFieldDropdown.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    items: PropTypes.array,
    required: PropTypes.bool,
    t: PropTypes.func.isRequired
}

PlanFieldDropdown.defaultProps = {
    required: false
}

export default withTranslation('plan')(withStyles(styles)(PlanFieldDropdown));