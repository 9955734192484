import React from "react";
import { palette } from "../../theme/palette";

const DotIcon = ({
  width = 12,
  height = 12,
  activated = false,
  color = "#000000",
}) => (
  <svg
    width={12}
    height={12}
    viewBox={` 0 0 ${width} ${height}`}
    fill={activated ? palette.primary : "none"}
    xmlns="http://www.w3.org/2000/svg"
    color={color}
  >
    <circle cx="6" cy="6" r="6" fill={activated ? palette.primary : color} />
  </svg>
);
export default DotIcon;
