import React, { useEffect } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Link, Typography, Button } from "@material-ui/core";
import { theme } from "../../../theme";
import { getLatestSubscription } from "../../../actions/subscriptions-action";
import RouterLink from "../../common/router-link";
import { useTranslation } from "react-i18next";
import { palette } from "../../../theme/palette";
import { useHistory } from "react-router-dom";
import useManageSubscription from "../../../hooks/useManageSubscription";

const useStyles = makeStyles({
  textUnderline: {
    color: palette.lightBlue,
    fontFamily: "Karla",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "400",
    textDecoration: "underline",
    width: "fit-content",
  },
  description: {
    color: palette.darkGray,
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: 16,
  },
  primaryButton: {
    alignSelf: "stretch",
    borderRadius: "4px",
    backgroundColor: palette.darkBlue,
    boxShadow: "0px 4px 30px rgba(18, 21, 27, 0.05)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 0px",
  },
  iconInformation: {
    position: "relative",
    width: "14px",
    height: "14px",
    overflow: "hidden",
    flexShrink: 0,
    objectFit: "cover",
  },
  subsName: {
    position: "relative",
    lineHeight: "150%",
  },
  subsType: {
    flexShrink: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "4px",
  },
  subscription: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  settingsAccount: {
    options: {
      alignItems: "flex-end",
      display: "flex",
      flexDirection: "column",
      gridGap: "24px",
      gap: "24px",
      padding: "24px 0px",
      position: "relative",
      width: "100%",
    },
  },
  active: {
    position: "relative",
    lineHeight: "150%",
    color: "#6bb488",
  },
  in_trial: {
    position: "relative",
    lineHeight: "150%",
    color: "rgb(255, 179, 0)",
  },
  paused: {
    position: "relative",
    lineHeight: "150%",
    color: "#E02758",
  },
  icon: {
    flexShrink: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "4px 8px",
  },
  youCanPauseContainer: {
    flex: 1,
    position: "relative",
    lineHeight: "150%",
  },
  statusInfo: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    fontSize: "14px",
    color: "#6d6f71",
  },
  status: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    gap: "8px",
  },
  options: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    padding: "24px 0px",
    gap: "24px",
  },
  text: {
    position: "relative",
    lineHeight: "125%",
  },
  secondaryButton: {
    alignSelf: "stretch",
    borderRadius: "4px",
    border: "2px solid #0b2b5c",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px",
    textAlign: "center",
    fontSize: "18px",
    color: "#0b2b5c",
  },
  content: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px 16px",
    boxSizing: "border-box",
    textAlign: "left",
    fontSize: theme.typography.h5.fontSize,
    color: "#12151b",
    fontFamily: "Karla",
  },
  button: {
    width: "100%",
  },
});

const SubscriptionComponent = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("settings");
  const history = useHistory();
  const { link: manageLink } = useManageSubscription();

  const subscriptions = useSelector((state) => state.subscriptions);
  const activiteSub =
    subscriptions.latest == null || subscriptions.latest == undefined
      ? ""
      : subscriptions.latest.status.toLowerCase();
  const subcriptionMonth =
    subscriptions.latest == null || subscriptions.latest == undefined
      ? ""
      : subscriptions.latest.billing_period;
  const subscriptionChannel =
    subscriptions.latest == null || subscriptions.latest == undefined
      ? ""
      : subscriptions.latest.channel;

  const startSubscriptionClick = (event) => {
    event.preventDefault();
    return history.push("/subscription/explore");
  };

  const manageSubscriptionClick = (event) => {
    event.preventDefault();
    return window.open(manageLink, "_blank");
  };

  return (
    <div className={styles.content}>
      <div className={styles.options}>
        <div className={styles.subscription}>
          <b className={styles.subsName}>{t(`settings.subscription`)}</b>
          <div className={styles.subsType}>
            <div className={styles.subsName}>
              {subcriptionMonth ? (
                <>
                  <InfoOutlinedIcon
                    fontSize="small"
                    sx={{ with: "14px", height: "14px" }}
                    className={styles.iconInformation}
                  />
                  {subcriptionMonth} {t("settings.subscriptionMonthProgam")}
                </>
              ) : (
                <RouterLink to="/subscription/explore">
                  <Typography className={styles.textUnderline}>
                    {t("settings.startYourSubscription")}
                  </Typography>
                </RouterLink>
              )}
            </div>
          </div>
        </div>
        <div className={styles.status}>
          <div className={styles.subscription}>
            <b className={styles.subsName}>
              {t("settings.subscriptionStatus")}
            </b>

            {activiteSub && (
              <div
                className={
                  activiteSub == "in_trial"
                    ? styles.in_trial
                    : activiteSub == "active"
                    ? styles.active
                    : styles.paused
                }
              >
                {activiteSub}
              </div>
            )}
          </div>
          <div className={styles.statusInfo}>
            {activiteSub ? (
              <>
                <div className={styles.icon}>
                  <InfoOutlinedIcon
                    fontSize="small"
                    sx={{ with: "14px", height: "14px" }}
                    className={styles.iconInformation}
                  />
                </div>
                <div className={styles.youCanPauseContainer}>
                  <span>
                    {t(`settings.subscriptionPause.p1`)} {` `}
                  </span>
                  <b>
                    {t(`settings.subscriptionPause.p2`)} {` `}
                  </b>
                  <span>
                    {t(`settings.subscriptionPause.p3`)} {` `}
                  </span>
                  <b>{t(`settings.subscriptionPause.p4`)}</b>
                </div>
              </>
            ) : (
              <Typography className={styles.description}>
                {t("settings.withoutSubscription")}
              </Typography>
            )}
          </div>
        </div>
        <div className={styles.subscription}>
          <b className={styles.subsName}>{t("settings.subscriptionSource")}</b>
          <div className={styles.subsName}>{subscriptionChannel}</div>
        </div>
        {!subscriptionChannel && (
          <Typography className={styles.description}>
            {t("settings.withoutSubscription")}
          </Typography>
        )}
      </div>

      {activiteSub ? (
        <Button
          onClick={manageSubscriptionClick}
          variant="contained"
          className={styles.secondaryButton}
        >
          <b className={styles.text}>{t("settings.manageSubscription")}</b>
        </Button>
      ) : (
        <Button
          onClick={startSubscriptionClick}
          color="secondary"
          variant="contained"
          className={styles.primaryButton}
        >
          <b className={styles.text}>{t("settings.startYourSubscription")}</b>
        </Button>
      )}
    </div>
  );
};

export default SubscriptionComponent;
