import moment from "moment";
import { dateFormat } from "./date-helper";
import WorkoutTypes from "../constants/workout-types";
import bugsnagClient from "./bugsnag-client";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { getFixCustomLevel } from "./plan-helper";
dayjs.extend(isBetween);

const responseErrorHelper = {
  plan: 0,
  month: 0,
  week: 0,
};

const responseEnergyLevelErrorHelper = {
  points: {
    plan: 0,
    month: 0,
    week: 0,
  },
  full: {
    plan: {
      label: 0,
      value: 0,
    },
    month: {
      label: 0,
      value: 0,
    },
    week: {
      label: 0,
      value: 0,
    },
  },
};

const getEnergyLevelRate = (days, currentStartDate) => {
  let energyLevelPlan = 0;
  let energyLevelPlanPoints = 0;

  let energyLevelMonth = 0;
  let energyLevelMonthPoints = 0;

  let energyLevelWeek = 0;
  let energyLevelWeekPoints = 0;

  try {
    if (days) {
      days.forEach((dayOf) => {
        const day = dayOf[0] ? dayOf[0] : dayOf;
        if (day && day.energyLevel !== undefined && !isNaN(day.energyLevel)) {
          const { plannedDate, energyLevel } = day;

          // Convert energy level to a value between 0 and 10
          let currentEnergyLevel = Math.floor(energyLevel / 10);

          // Total energy level for the plan
          energyLevelPlan += currentEnergyLevel;
          energyLevelPlanPoints++;

          const date = moment(plannedDate, dateFormat);

          // Check if the date is within the current month
          if (
            date.isBetween(
              moment(currentStartDate).startOf("month"),
              moment(currentStartDate).endOf("month"),
              undefined,
              "[]",
            )
          ) {
            energyLevelMonth += currentEnergyLevel;
            energyLevelMonthPoints++;
          }

          // Check if the date is within the current week
          if (
            date.isBetween(
              moment(currentStartDate).startOf("isoWeek"),
              moment(currentStartDate).endOf("isoWeek"),
              undefined,
              "[]",
            )
          ) {
            energyLevelWeek += currentEnergyLevel;
            energyLevelWeekPoints++;
          }
        }
      });
    }

    // Calculate percentages
    const planPercentage = energyLevelPlanPoints
      ? energyLevelPlan / energyLevelPlanPoints
      : 0;
    const monthPercentage = energyLevelMonthPoints
      ? energyLevelMonth / energyLevelMonthPoints
      : 0;
    const weekPercentage = energyLevelWeekPoints
      ? energyLevelWeek / energyLevelWeekPoints
      : 0;

    return {
      points: {
        plan: planPercentage,
        month: monthPercentage,
        week: weekPercentage,
      },
      full: {
        plan: {
          label: planPercentage,
          value: planPercentage * 10,
        },
        month: {
          label: monthPercentage,
          value: monthPercentage * 10,
        },
        week: {
          label: weekPercentage,
          value: weekPercentage * 10,
        },
      },
    };
  } catch (error) {
    bugsnagClient.notify(error);
    return responseEnergyLevelErrorHelper;
  }
};

const calculateTrainingLoad = (
  days,
  activities,
  startDate,
  endDate,
  chainedPlans = [],
  currentTraining = null,
) => {
  let planTotal = 0;
  let doneTotal = 0;

  const startDateToDayJs = dayjs(startDate);
  const endDateToDayJs = dayjs(endDate);
  try {
    if (!days) throw new Error("Plan no exists");
    if (!activities) throw new Error("Activities no exists");

    planTotal = days
      .map((dayOf) => {
        let day = dayOf[0] ? dayOf[0] : dayOf;
        return Array.isArray(day.estimatedTime)
          ? +day.estimatedTime[
              getFixCustomLevel(day.plannedDate, chainedPlans, currentTraining)
            ]
          : +day.estimatedTime;
      })
      .reduce((time, sum) => time + sum);

    const foundActivities = activities.filter(
      (activity) =>
        activity &&
        dayjs(activity.start_date).isBetween(
          startDateToDayJs,
          endDateToDayJs,
          "day",
          "[]",
        ),
    );

    if (foundActivities && foundActivities.length) {
      doneTotal = foundActivities
        .map((item) => {
          const movingTime = item.moving_time || item.elapsed_time || 0;
          return Math.round(movingTime / 60) || 0;
        })
        .reduce((a, sum) => a + sum);
    } else {
      doneTotal = 0;
    }
    return planTotal ? (doneTotal / planTotal) * 100 : 0;
  } catch (error) {
    bugsnagClient.notify(error);
    return 0;
  }
};

const getRpeRate = (days, currentStartDate) => {
  let rpeLevelPlan = 0;
  let rpePlanPoints = 0;

  let rpeLevelMonth = 0;
  let rpeLevelMonthPoints = 0;

  let rpeLevelWeek = 0;
  let rpeLevelWeekPoints = 0;

  try {
    if (days) {
      days.forEach((dayOf) => {
        let day = dayOf[0] ? dayOf[0] : dayOf;
        if (day) {
          let currentRpeLevel = 0;
          if (day.rpe > 0 && day.rpe <= 20) currentRpeLevel = 2;
          if (day.rpe > 20 && day.rpe <= 40) currentRpeLevel = 4;
          if (day.rpe > 40 && day.rpe <= 60) currentRpeLevel = 6;
          if (day.rpe > 60 && day.rpe <= 80) currentRpeLevel = 8;
          if (day.rpe > 80 && day.rpe <= 100) currentRpeLevel = 10;

          rpeLevelPlan += currentRpeLevel;
          if (currentRpeLevel > 0) {
            rpePlanPoints++;
          }

          const date = moment(day.plannedDate, dateFormat);

          if (
            date.isBetween(
              moment(currentStartDate).startOf("month"),
              moment(currentStartDate).endOf("month"),
              undefined,
              "[]",
            )
          ) {
            rpeLevelMonth += currentRpeLevel;
            if (currentRpeLevel > 0) {
              rpeLevelMonthPoints++;
            }
          }

          if (
            date.isBetween(
              moment(currentStartDate).startOf("isoWeek"),
              moment(currentStartDate).endOf("isoWeek"),
              undefined,
              "[]",
            )
          ) {
            rpeLevelWeek += currentRpeLevel;
            if (currentRpeLevel > 0) {
              rpeLevelWeekPoints++;
            }
          }
        }
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    return responseErrorHelper;
  }

  return {
    plan: rpeLevelPlan ? rpeLevelPlan / rpePlanPoints : 0,
    month: rpeLevelMonthPoints ? rpeLevelMonth / rpeLevelMonthPoints : 0,
    week: rpeLevelWeekPoints ? rpeLevelWeek / rpeLevelWeekPoints : 0,
  };
};

const getDoneRate = (days, currentStartDate) => {
  let donePlan = 0;
  let totalWosPlan = 0;

  let doneMonth = 0;
  let totalWosMonth = 0;

  let doneWeek = 0;
  let totalWosWeek = 0;

  try {
    if (days) {
      days.forEach((dayOf) => {
        let day = dayOf[0] ? dayOf[0] : dayOf;
        if (day) {
          const date = moment(day.plannedDate, dateFormat);
          if (date.isBefore(moment())) {
            totalWosPlan++;
            if (day.isDone) {
              donePlan++;
            }
          }

          if (
            date.isBetween(
              moment(currentStartDate).startOf("month"),
              moment(currentStartDate).endOf("month"),
              undefined,
              "[]",
            )
          ) {
            if (date.isBefore(moment())) {
              totalWosMonth++;
              if (day.isDone) {
                doneMonth++;
              }
            }
          }

          if (
            date.isBetween(
              moment(currentStartDate).startOf("isoWeek"),
              moment(currentStartDate).endOf("isoWeek"),
              undefined,
              "[]",
            )
          ) {
            if (date.isBefore(moment())) {
              totalWosWeek++;
              if (day.isDone) {
                doneWeek++;
              }
            }
          }
        }
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    return responseErrorHelper;
  }

  return {
    plan: totalWosPlan ? (donePlan / totalWosPlan) * 100 : 0,
    month: totalWosMonth ? (doneMonth / totalWosMonth) * 100 : 0,
    week: totalWosWeek ? (doneWeek / totalWosWeek) * 100 : 0,
  };
};

const getTrainingLoadRate = (days, activities, currentStartDate, level = 2) => {
  let loadPlan = 0;
  let donePlan = 0;

  let loadMonth = 0;
  let doneMonth = 0;

  let loadWeek = 0;
  let doneWeek = 0;

  let startDate = null;

  try {
    if (days) {
      days.forEach((dayOf) => {
        let day = dayOf[0] ? dayOf[0] : dayOf;
        if (day) {
          const date = moment(day.plannedDate, dateFormat);
          if (!startDate) {
            startDate = date;
          }
          if (date.isBefore(moment())) {
            if (
              day.estimatedTime &&
              day.workoutType !== WorkoutTypes.Items.Strength &&
              day.workoutType !== WorkoutTypes.Items.Corework &&
              day.workoutType !== WorkoutTypes.Items.UserCreated
            ) {
              loadPlan += Array.isArray(day.estimatedTime)
                ? +day.estimatedTime[level]
                : +day.estimatedTime;
            }
          }

          if (
            date.isBetween(
              moment(currentStartDate).startOf("month"),
              moment(currentStartDate).endOf("month"),
              undefined,
              "[]",
            )
          ) {
            if (date.isBefore(moment())) {
              if (
                day.estimatedTime &&
                day.workoutType !== WorkoutTypes.Items.Strength &&
                day.workoutType !== WorkoutTypes.Items.Corework &&
                day.workoutType !== WorkoutTypes.Items.UserCreated
              ) {
                loadMonth += Array.isArray(day.estimatedTime)
                  ? +day.estimatedTime[level]
                  : +day.estimatedTime;
              }
            }
          }

          if (
            date.isBetween(
              moment(currentStartDate).startOf("isoWeek"),
              moment(currentStartDate).endOf("isoWeek"),
              undefined,
              "[]",
            )
          ) {
            if (date.isBefore(moment())) {
              if (
                day.estimatedTime &&
                day.workoutType !== WorkoutTypes.Items.Strength &&
                day.workoutType !== WorkoutTypes.Items.Corework &&
                day.workoutType !== WorkoutTypes.Items.UserCreated
              ) {
                loadWeek += Array.isArray(day.estimatedTime)
                  ? +day.estimatedTime[level]
                  : +day.estimatedTime;
              }
            }
          }
        }
      });
    }

    if (activities && startDate) {
      activities.forEach((activity) => {
        if (activity) {
          const activityDate = moment(activity.start_date);

          if (
            activityDate.isBefore(moment()) &&
            activityDate.isSameOrAfter(startDate)
          ) {
            if (activity.elapsed_time) {
              donePlan += activity.elapsed_time / 60;
            }
          }

          if (
            activityDate.isBetween(
              moment(currentStartDate).startOf("month"),
              moment(currentStartDate).endOf("month"),
              undefined,
              "[]",
            ) &&
            activityDate.isSameOrAfter(startDate)
          ) {
            if (activityDate.isBefore(moment())) {
              if (activity.elapsed_time) {
                doneMonth += activity.elapsed_time / 60;
              }
            }
          }

          if (
            activityDate.isBetween(
              moment(currentStartDate).startOf("isoWeek"),
              moment(currentStartDate).endOf("isoWeek"),
              undefined,
              "[]",
            ) &&
            activityDate.isSameOrAfter(startDate)
          ) {
            if (activityDate.isBefore(moment())) {
              if (activity.elapsed_time) {
                doneWeek += activity.elapsed_time / 60;
              }
            }
          }
        }
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    return responseErrorHelper;
  }

  return {
    plan: loadPlan ? (donePlan / loadPlan) * 100 : 0,
    month: loadMonth ? (doneMonth / loadMonth) * 100 : 0,
    week: loadWeek ? (doneWeek / loadWeek) * 100 : 0,
  };
};

const getAccumulatedDistance = (activities, startDate) => {
  const accumulatedDistance = activities
    .filter((a) => moment(a.start_date).unix() * 1000 > startDate)
    .reduce((sum, ac) => {
      return sum + ac.distance;
    }, 0);
  return accumulatedDistance;
};

const getAccumulatedElevation = (activities, startDate) => {
  const accumulatedElevation = activities
    .filter((a) => moment(a.start_date).unix() * 1000 > startDate)
    .reduce((sum, ac) => {
      return sum + ac.total_elevation_gain;
    }, 0);
  return accumulatedElevation;
};

const getLevel = (accumulatedDistance, accumulatedElevation) => {
  let level = 1;

  if (accumulatedDistance >= 80000 || accumulatedElevation >= 5000) {
    level = 2;
  }
  if (accumulatedDistance >= 200000 || accumulatedElevation >= 8000) {
    level = 3;
  }
  if (accumulatedDistance >= 500000 || accumulatedElevation >= 12000) {
    level = 4;
  }
  if (accumulatedDistance >= 1000000 || accumulatedElevation >= 20000) {
    level = 5;
  }
  if (accumulatedDistance >= 2500000 || accumulatedElevation >= 35000) {
    level = 6;
  }
  if (accumulatedDistance >= 5000000 || accumulatedElevation >= 50000) {
    level = 7;
  }
  if (accumulatedDistance >= 15000000 || accumulatedElevation >= 100000) {
    level = 8;
  }

  return level;
};

export {
  getEnergyLevelRate,
  getDoneRate,
  getTrainingLoadRate,
  getAccumulatedDistance,
  getAccumulatedElevation,
  getLevel,
  getRpeRate,
  calculateTrainingLoad,
};
