import React, { memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import history from "../../../../../lib/history-helper";

const styles = (theme) => ({
  main: {
    backgroundColor: "white",
    borderRadius: "12px",
    margin: "0px 16px 16px 16px",
    cursor: "pointer",
  },
  description: {
    padding: "16px",
    boxSizing: "border-box",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  subtitle: {
    fontSize: "12px",
    colord: "#9E9E9E",
  },
});

const GroupsCard = ({
  classes,
  image,
  name,
  numPosts,
  numMembers,
  i18n,
  id,
  t,
}) => {
  const { language = "en" } = i18n;

  const gotToGroupDetail = () => {
    history.push(`/trailhead/group/${id}`);
  };

  return (
    <div className={classes.main} onClick={gotToGroupDetail}>
      <img src={image} width="100%" alt="group-image" />

      <div className={classes.description}>
        <Typography className={classes.title}>
          {name[language] ? name[language] : name["en"]}
        </Typography>
        <Typography className={classes.subtitle}>
          {numMembers || ""} {t("members")} • {numPosts || ""} {t("posts")}
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("trailhead")(memo(GroupsCard)),
);
