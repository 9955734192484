import React, { useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { palette } from "../../theme/palette";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  categoryList: {
    display: "-webkit-inline-box",
    width: "100%",
    overflowX: "scroll",
    boxSizing: "border-box",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    "& div:not(:last-child)": {
      marginRight: "12px",
    },
  },
  categoryCard: {
    borderRadius: "4px",
    padding: "8px 12px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.lightBlueHover,
  },
  categoryCardSelected: {
    borderRadius: "4px",
    padding: "8px 12px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.lightBlue,
    color: palette.white,
  },
});

export const Filters = ({ filters = [], onClick = () => {} }) => {
  const { t } = useTranslation("home");
  const [current, setCurrent] = useState("");
  const classes = useStyles();

  const handleClick = (tag) => {
    onClick();
    if (current !== tag) return setCurrent(tag);
    if (current === tag) return setCurrent("");
  };

  return (
    <Box className={classes.categoryList}>
      {filters.map((item) => (
        <Box
          key={item.label}
          className={classes.parentCardCategory}
          onClick={() => handleClick(item)}
        >
          <Box
            className={
              current === item
                ? classes.categoryCardSelected
                : classes.categoryCard
            }
          >
            <Typography key={item} variant="body2">
              {t(item)}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
