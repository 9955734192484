import React, { Component } from "react";
import {
  Grid,
  DialogContent,
  withMobileDialog,
  Typography,
  Button,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import withWidth from "@material-ui/core/withWidth";

import { dateFormat } from "../../../../lib/date-helper";
import EstimatedTimes from "../../../../constants/estimated-times";
import { grey } from "@material-ui/core/colors";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const styles = (theme) => ({
  container: {
    paddingTop: theme.spacing.unit * 3,
    background: "#F9F9FB",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflow: "scroll",
    },
  },
  textContainer: {
    marginTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit,
  },
  newLine: {
    whiteSpace: "pre-line",
  },
  extraBottomMargin: {
    marginBottom: theme.spacing.unit * 4,
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    cursor: "pointer",
  },
  topBar: {
    marginTop: 10,
  },
  topTitle: {
    color: grey[700],
    marginBottom: theme.spacing.unit,
    fontSize: "0.8rem",
    fontWeight: "normal",
  },
  workoutColumn: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
      marginBottom: 70,
    },
  },
  smallerFontSize: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller",
    },
  },
  rightMargin: {
    marginRight: theme.spacing.unit * 2,
  },
  buttons: {
    textTransform: "none",
  },
  descriptionLabel: {
    fontWeight: "800",
    fontSize: 14,
    marginBottom: 0,
    marginTop: 24,
  },
  mainTitle: {
    fontWeight: "900",
    fontSize: 20,
  },
});

class DashboardTrainingWeekdayAdd extends Component {
  state = {
    workoutDate: "",
    workoutDescription: "",
    workoutDuration: "",
    workoutType: "",
  };

  handleDateChange = (momentObject) => {
    if (momentObject) {
      try {
        this.setState({
          ...this.state,
          workoutDate: momentObject.format(dateFormat),
        });
      } catch (exp) {}
    } else {
      this.setState({
        ...this.state,
        workoutDate: undefined,
      });
    }
  };

  handleTimeChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          workoutDuration: value || "",
        });
      }
    }
  };

  handleDescriptionChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          workoutDescription: value || "",
        });
      }
    }
  };

  handleTypeChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          workoutType: value || "",
        });
      }
    }
  };

  render() {
    const { classes, t, onClose, onAdd } = this.props;

    const {
      workoutDate,
      workoutDescription,
      workoutDuration,
      workoutType,
    } = this.state;

    return (
      <DialogContent className={classes.container}>
        <Grid container spacing={40}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.workoutColumn}
          >
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={onClose}
              />
            </div>
            <Typography variant="h6" className={classes.mainTitle}>
              {t("Write what you did")}
            </Typography>
            <Grid container spacing={40} className={classes.topBar}>
              <Grid
                item
                className={classes.topItem}
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
              >
                <Typography variant="h6" className={classes.topTitle}>
                  {t("Date")}
                </Typography>
                <div className={classes.dateContainer}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      value={moment(
                        workoutDate || null,
                        dateFormat,
                      ).toISOString()}
                      format="DD/MM/YYYY"
                      placeholder="DD/MM/AAAA"
                      mask={(value) =>
                        // handle clearing outside if value can be changed outside of the component
                        value
                          ? [
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]
                          : []
                      }
                      autoOk
                      clearable
                      keyboard
                      fullWidth
                      disableOpenOnEnter
                      animateYearScrolling={false}
                      onChange={this.handleDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>
              <Grid
                item
                className={classes.topItem}
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
              >
                <Typography variant="h6" className={classes.topTitle}>
                  {t("Type")}
                </Typography>

                <TextField
                  value={workoutType}
                  placeholder={t("Type placeholder")}
                  fullWidth={true}
                  onChange={this.handleTypeChange}
                />
              </Grid>
              <Grid
                item
                className={classes.topItem}
                xs={12}
                sm={12}
                md={2}
                lg={2}
                xl={2}
              >
                <Typography variant="h6" className={classes.topTitle}>
                  {t("Time")}
                </Typography>

                <NativeSelect
                  value={workoutDuration}
                  onChange={this.handleTimeChange}
                >
                  <option aria-label="None" value="" />
                  {EstimatedTimes.itemsArray.slice(0, 12).map((w, index) => (
                    <option value={w.value} key={index}>
                      {w.text}
                    </option>
                  ))}
                </NativeSelect>
              </Grid>
            </Grid>
            <div
              className={classNames(
                classes.textContainer,
                classes.extraBottomMargin,
              )}
            >
              <Typography
                variant="subtitle2"
                className={classes.descriptionLabel}
              >
                {t("Add here your workout description")}
              </Typography>

              <TextField
                value={workoutDescription}
                margin="normal"
                variant="outlined"
                placeholder={t("Description placeholder")}
                fullWidth={true}
                multiline={true}
                rows={4}
                onChange={this.handleDescriptionChange}
              />
            </div>

            <div className={classes.rightButtons}>
              <Button
                color="primary"
                variant="contained"
                className={classNames(
                  classes.smallerFontSize,
                  classes.rightMargin,
                  classes.buttons,
                )}
                disabled={
                  !workoutDate ||
                  !workoutDescription ||
                  !workoutDuration ||
                  !workoutType
                }
                onClick={() => onAdd(this.state)}
              >
                {t("Save")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    );
  }
}

DashboardTrainingWeekdayAdd.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  fullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  userPlans: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation(["dashboard", "constants"])(
      withWidth()(withMobileDialog()(DashboardTrainingWeekdayAdd)),
    ),
  ),
);
