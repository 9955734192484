import localforage from "localforage";

export class LocalDB {
  constructor(name = "") {
    this.name = name;
    this.storage = localforage.createInstance({
      name: this.name,
    });
  }

  async setItem(key, value) {
    await this.storage.setItem(key, value);
    return value;
  }

  async getItem(key) {
    return await this.storage.getItem(key);
  }

  async removeItem(key) {
    await this.storage.removeItem(key);
  }

  async clear() {
    await this.storage.clear();
  }

  sync() {
    if (!this.syncPromise) {
      this.syncPromise = this.storage.iterate((val, key) => {
        this.memoryStore[key] = val;
      });
    }
    return this.syncPromise;
  }
}
