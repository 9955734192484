import React from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  extraButton: {
    marginLeft: "auto",
    display: "block",
    borderRadius: "10px",
  },
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 20,
    marginBottom: 20,
    padding: "0px 16px",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      margin: "30px auto 0",
    },
  },
  extraMargin: {
    marginBottom: 46,
  },
});

const PlanQuestionnaireButtons = (props) => {
  const {
    currentStep,
    form,
    onNextRequested,
    classes,
    saving,
    disableSubmit,
    totalSteps,
    fields,
    isSuggester = false,
    selectedPlan,
  } = props;
  const { t } = useTranslation("plan");

  return (
    <div
      className={classNames(classes.container, {
        [classes.extraMargin]: currentStep === 1,
      })}
    >
      {(currentStep > 0 || form === "completeForm") &&
        currentStep < totalSteps &&
        (fields[currentStep][3].id === "birthdate" ||
          fields[currentStep][3].id === "country" ||
          fields[currentStep][3].id === "trainWeekdays" ||
          fields[currentStep][3].id === "desiredStartDate" ||
          (selectedPlan === "coach-choose" &&
            fields[currentStep][3].id === "desiredStartDate")) && (
          <Button
            disabled={saving}
            variant="contained"
            color="primary"
            onClick={onNextRequested}
            className={classes.extraButton}
          >
            {t("Next")}
          </Button>
        )}
      {(currentStep >= totalSteps &&
        !isSuggester &&
        selectedPlan === "coach-choose") ||
      (fields && fields.length && fields[currentStep][3].id === "message") ? (
        <Button
          disabled={saving || disableSubmit}
          variant="contained"
          color="primary"
          type="submit"
          form={form}
          className={classes.extraButton}
        >
          {t("Submit")}
        </Button>
      ) : null}
    </div>
  );
};
PlanQuestionnaireButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  selectedPlan: PropTypes.string,
  form: PropTypes.string.isRequired,
  onBackRequested: PropTypes.func,
  onNextRequested: PropTypes.func,
  saving: PropTypes.bool,
  fields: PropTypes.array,
  disableSubmit: PropTypes.bool,
};

PlanQuestionnaireButtons.defaultProps = {
  saving: false,
};

export default withStyles(styles)(PlanQuestionnaireButtons);
