import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Slider,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CommentIcon from "@material-ui/icons/Comment";
import moment from "moment";
import classNames from "classnames";
import Weekdays from "../../../constants/weekdays";
import DashboardTrainingWeekdayDay from "../../dashboard/dashboard-training/dashboard-training-weekday/dashboard-training-weekday-day";
import "moment/min/locales";
import WorkoutTypes from "../../../constants/workout-types";
import EstimatedTimes from "../../../constants/estimated-times";
import { connect } from "react-redux";
import {
  selectAthleteWorkout,
  patchSelectedAthleteWorkoutCommentsRead,
} from "../../../actions/athletes-action";
import AthleteTrainingWorkoutViewer from "../../athletes/athlete-training-comments/athlete-training-workout-viewer";
import { dateFormat } from "../../../lib/date-helper";
import WorkoutCard from "../components/workout-card";
import UserGroups from "../../../constants/user-groups";
import { getCustomLevel, getPlanLevel } from "../../../lib/plan-helper";
import workoutToText from "../../../lib/workoutToText";
import AthleteResumeModal from "../../athletes/athlete-training-comments/athlete-resume-modal";

const styles = (theme) => ({
  day: {
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    height: "100%",
    minHeight: "280px",
  },
  workoutContainer: {
    position: "relative",
    cursor: "pointer",
  },
  weekdayContainer: {
    display: "inline-flex",
    flexDirection: "column",
    width: "14%",
    flex: 1 / 7,
    height: "90%",
    textAlign: "center",
    border: "solid 1px rgba(0, 0, 0, 0.2)",
    borderWidth: "1px 0px 1px 1px",

    "&:last-child": {
      borderWidth: "1px 1px 1px 1px",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },
  title: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  delete: {
    textAlign: "right",
    height: 45,
  },
  dayTitle: {
    border: "1px solid lightgrey",
    textAlign: "center",
    borderRadius: 4,
  },
  workout: {
    paddingBottom: 10,
  },
  commentIcon: {
    display: "block",
    marginLeft: "auto",
    marginRight: 0,
  },
  icon: {
    color: "dodgerblue",
  },
  newLine: {
    whiteSpace: "pre-line",
  },
  todayDateContainer: {
    fontWeight: 500,
    color: "#1E629B",
  },
  descriptionContainer: {
    borderRadius: 8,
    height: 155,
    padding: 10,
    textAlign: "left",
    position: "relative",
  },
  chatContainer: {
    borderRadius: 8,
    height: 155,
    padding: 10,
    textAlign: "left",
    marginTop: 15,
    position: "relative",
    overflow: "scroll",
    cursor: "pointer",
  },
  description: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: 500,
  },
  descriptionTime: {
    color: "#FFFFFF",
    fontSize: 14,
    fontWeight: 400,
  },
  chatMessage: {
    fontSize: 14,
    fontWeight: 400,
  },
});

class AthleteTrainingEditorWeekday extends Component {
  state = {
    deleteDialogOpen: false,
    workout: null,
    workoutDate: null,
    isOpenWorkoutViewer: false,
  };
  handleOnDrop = (dayIndexOrPlannedDate, event) => {
    event.preventDefault();
    const { onWorkoutAdd } = this.props;
    if (onWorkoutAdd) onWorkoutAdd(dayIndexOrPlannedDate);
  };

  handleOnDragOver = (event) => {
    event.preventDefault();
  };

  openDeleteDialog = (workout, workoutDate) => {
    this.setState({
      ...this.state,
      deleteDialogOpen: true,
      workout: workout,
      workoutDate: workoutDate,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      deleteDialogOpen: false,
      workout: null,
      workoutDate: null,
    });
  };

  openModal = (workout) => {
    this.setState({ ...this.state, modalId: workout.id });
  };

  handleOpenWV = (workout) => {
    this.props.dispatch(selectAthleteWorkout(workout));

    setTimeout(
      () =>
        this.setState({
          ...this.state,
          isOpenWorkoutViewer: true,
        }),
      100,
    );
  };

  handleCloseWV = () => {
    this.setState({
      ...this.state,
      isOpenWorkoutViewer: false,
    });

    const { athletes, auth } = this.props;
    const { selectedAthlete } = athletes;
    const { selectedWorkout } = selectedAthlete;

    const { currentUserGroups } = auth;
    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;

    if (!isAdmin) {
      this.props.dispatch(
        patchSelectedAthleteWorkoutCommentsRead(
          selectedAthlete,
          selectedWorkout.plannedDate,
          selectedWorkout.id,
        ),
      );
    }
  };

  render() {
    const {
      classes,
      dayIndex,
      trainingDay,
      onWorkoutDelete,
      workoutDate,
      onOpenChat,
      isPreview = false,
      athletes,
      handleModal,
      currentSelected,
      handleModalLeave,
      parentLeave,
    } = this.props;

    const { selectedAthlete } = athletes;
    const { stravaActivities, currentTraining } = selectedAthlete;
    const { chainedPlans } = currentTraining || {};

    const level = getPlanLevel(currentTraining || {});

    let currentActivity = null;

    if (stravaActivities) {
      currentActivity = stravaActivities.find((a) =>
        moment(a.start_date).isSame(moment(workoutDate), "day"),
      );
    }

    const {
      deleteDialogOpen,
      workout: workoutToDelete,
      workoutDate: workoutDateToDelete,
      isOpenWorkoutViewer,
    } = this.state;

    const today = moment()
      .startOf("day")
      .toISOString();

    return (
      <div className={classes.weekdayContainer}>
        <DashboardTrainingWeekdayDay
          title={`${Weekdays.itemsArray[dayIndex % 7].text}`
            .substring(0, 3)
            .toUpperCase()}
          subtitle={
            isPreview
              ? ""
              : `${moment(workoutDate).format("D")} ${moment(workoutDate)
                  .format("MMMM")
                  .substring(0, 3)}`
          }
          hasTraining={true}
          className={classNames({
            [classes.todayDateContainer]:
              moment(workoutDate)
                .startOf("day")
                .toISOString() === today,
          })}
          isInMobileView={false}
          isCoachView={true}
        />
        <div
          className={classes.day}
          onDragOver={this.handleOnDragOver}
          onDrop={this.handleOnDrop.bind(this, workoutDate)}
        >
          <Fragment>
            {trainingDay &&
              trainingDay.map((workout, index) => {
                const {
                  title,
                  workoutType,
                  estimatedTime,
                  userCreatedType,
                  isDone,
                  plannedDate,
                } = workout;

                const newEstimatedTime = Array.isArray(estimatedTime)
                  ? estimatedTime[
                      !isNaN(getCustomLevel(workout.plannedDate, chainedPlans))
                        ? getCustomLevel(workout.plannedDate, chainedPlans)
                        : level
                    ]
                  : estimatedTime;

                const displayedTitle = userCreatedType
                  ? userCreatedType
                  : title && (title["en"] || title["es"]);

                return (
                  <div
                    key={index}
                    className={classes.workoutContainer}
                    onMouseEnter={() => handleModal(workout)}
                    onMouseLeave={() => parentLeave()}
                  >
                    {currentSelected &&
                    workout &&
                    workout.id === currentSelected.id &&
                    workout.plannedDate === currentSelected.plannedDate ? (
                      <AthleteResumeModal
                        workout={workout}
                        close={() => handleModal(null)}
                        onRemove={() =>
                          this.openDeleteDialog(workout.id, workoutDate)
                        }
                        onMouseLeave={() => handleModalLeave()}
                      />
                    ) : (
                      ""
                    )}

                    {isPreview ? (
                      ""
                    ) : (
                      <div className={classes.delete}>
                        <IconButton
                          color="primary"
                          aria-label="Remove"
                          onClick={() =>
                            this.openDeleteDialog(workout.id, workoutDate)
                          }
                        >
                          {/* <CancelIcon /> */}
                        </IconButton>
                      </div>
                    )}
                    <WorkoutCard
                      workoutType={workoutType}
                      isDone={isDone}
                      estimatedTime={EstimatedTimes.convertMinutesToLabel(
                        newEstimatedTime,
                        true,
                      )}
                      onClick={() => this.handleOpenWV(workout)}
                    />

                    {isPreview ? (
                      ""
                    ) : (
                      <IconButton
                        color="default"
                        className={classes.commentIcon}
                        aria-label="Comment"
                        onClick={() => onOpenChat(workout)}
                      >
                        <CommentIcon />
                      </IconButton>
                    )}
                  </div>
                );
              })}

            {!trainingDay && currentActivity ? (
              <div style={{ marginTop: "45px" }}>
                <WorkoutCard
                  workoutType="NoWorkout"
                  isDone={false}
                  onClick={null}
                  withCheckIcon={false}
                  customText="The user didn't have a workout but has an activity this day"
                />
              </div>
            ) : (
              ""
            )}

            <Dialog
              open={deleteDialogOpen}
              aria-labelledby="delete-dialog"
              onClose={this.handleClose}
            >
              <DialogTitle>
                {"Are you sure you want to delete this workout?"}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    onWorkoutDelete(workoutToDelete, workoutDateToDelete);
                    this.handleClose();
                  }}
                  color="primary"
                >
                  Yes
                </Button>
                <Button onClick={this.handleClose} color="primary">
                  No
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={isOpenWorkoutViewer}
              onClose={() => this.handleCloseWV()}
              aria-labelledby="form-dialog-title"
              maxWidth="xl"
              disableEnforceFocus
            >
              <AthleteTrainingWorkoutViewer
                onClose={() => this.handleCloseWV()}
                onOpenChat={(ev) => onOpenChat(ev)}
                onWorkoutDelete={onWorkoutDelete}
                handleClose={this.handleCloseWV}
              ></AthleteTrainingWorkoutViewer>
            </Dialog>
          </Fragment>
        </div>
      </div>
    );
  }
}

AthleteTrainingEditorWeekday.propTypes = {
  classes: PropTypes.object.isRequired,
  dayIndex: PropTypes.number.isRequired,
  onWorkoutAdd: PropTypes.func.isRequired,
  trainingDay: PropTypes.array,
  onWorkoutDelete: PropTypes.func.isRequired,
  workoutDate: PropTypes.object, //Athletes: date to show on athletes tp viewer
};

AthleteTrainingEditorWeekday.defaultProps = {};

export default connect((store) => {
  return {
    athletes: store.athletes,
    auth: store.auth,
  };
})(withStyles(styles)(AthleteTrainingEditorWeekday));
