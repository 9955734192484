import React from "react";
import { palette } from "../../theme/palette";

const HillIcon = ({
  width = 24,
  height = 24,
  activated = false,
  color = "black",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={` 0 0 20 12`}
    fill={activated ? palette.primary : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 11L1 11C1 11 1.47739 6.53215 3.29846 3.11828C4.52589 0.817298 7.52613 0.475711 9.53811 2.13494C11.9785 4.14752 12.6163 6.05484 15.9441 6.05484C18.6781 6.05484 19 11 19 11Z"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default HillIcon;
