import React, { memo } from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import unitsHook from "../../../../../hooks/unitsHook";
import { convertUnits, round } from "../../../../../lib/units-helper";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  commonCard: {
    background: "white",
    margin: "0px 16px 16px 16px",
    borderRadius: "12px",
    padding: "10px",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  parent: {
    display: "inline-flex",
    width: "100%",
  },
  leftSection: {
    width: "40%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightSection: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
  },
  stats: {
    display: "flex",
    width: "90%",
    justifyContent: "space-between",
  },
  smallText: {
    fontSize: "12px",
  },
  orangeText: {
    textAlign: "right",
    color: "#E16327",
    cursor: "pointer",
  },
  title: {
    width: "90%",
    fontSize: 16,
    margin: "6px 0",
    lineHeight: 1.2,
    fontWeight: "bold",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
  },
  leftMargin: {
    marginLeft: "8px",
  },
  footerItem: {
    display: "flex",
  },
  icon: {
    marginRight: 4,
  },
  centerText: {
    textAlign: "center",
  },
  subParent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  image: {
    width: 120,
    height: "object-fit",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
});

const RaceCard = ({
  item = {},
  classes,
  openOrClose = null,
  title,
  t,
  onShare = null,
}) => {
  const { badge, elevation, name, location, distance, time, date } = item;
  const currentUnit = unitsHook();

  const roundNumber = (number) => {
    return Math.floor(number) || ``;
  };

  return (
    <div
      className={classes.commonCard}
      onClick={openOrClose ? () => openOrClose(item) : null}
    >
      <div className={classes.parent}>
        <div className={classes.leftSection}>
          <img
            src={badge || `${process.env.PUBLIC_URL}/user-profile/race.png`}
            // width="%"
            className={classes.image}
          ></img>
        </div>
        <div className={classes.rightSection}>
          <Typography
            variant="body2"
            className={classes.orangeText}
            onClick={(event) => onShare && onShare(event, item)}
          >
            {title}
          </Typography>
          <div className={classes.subParent}>
            <Typography variant="body1" className={classes.title}>
              {name || ""}
            </Typography>
            <div className={classes.stats}>
              <div>
                <Typography variant="body2" className={classes.centerText}>
                  {distance
                    ? currentUnit == "kms"
                      ? round(distance, 1)
                      : round(
                          convertUnits(distance, "kms", "miles", "distance", 1),
                        )
                    : `__ ${
                        currentUnit == "miles"
                          ? t("miles", { ns: "challenges" })
                          : "kms"
                      }`}
                </Typography>
                <Typography variant="body2" className={classes.smallText}>
                  {t("Distance")}
                </Typography>
              </div>
              <div>
                <Typography variant="body2" className={classes.centerText}>
                  {elevation
                    ? currentUnit == "kms"
                      ? round(elevation)
                      : round(
                          convertUnits(elevation, "kms", "miles", "elevation"),
                        )
                    : `__ ${
                        currentUnit == "miles"
                          ? t("feet", { ns: "challenges" })
                          : "m"
                      }`}
                </Typography>
                <Typography variant="body2" className={classes.smallText}>
                  {t("Elevation")}
                </Typography>
              </div>
              <div>
                <Typography variant="body2" className={classes.centerText}>
                  {time || "__:__"}
                </Typography>
                <Typography variant="body2" className={classes.smallText}>
                  {t("Time")}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.footerItem}>
          <img
            src={`${process.env.PUBLIC_URL}/date-icon.svg`}
            alt="date-icon"
            className={classes.icon}
          />
          <Typography variant="body2" className={classes.smallText}>
            {date || t("Add Date")}
          </Typography>
        </div>
        <div className={classNames(classes.footerItem, classes.leftMargin)}>
          <img
            src={`${process.env.PUBLIC_URL}/people-icons.svg`}
            alt="people-icon"
            className={classes.icon}
          />
          <Typography variant="body2" className={classes.smallText}>
            {location || ""}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation(["trailhead", "challenges"])(memo(RaceCard)),
);
