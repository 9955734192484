export default {
  title: "title",
  titleLower: "title_lower",
  description: "description",
  descriptionLower: "description_lower",
  id: "id",
  difficultyLevel: "difficultyLevel",
  estimatedTime: "estimatedTime",
  surface: "surface",
  trainingPeriod: "trainingPeriod",
  workoutType: "workoutType",
};
