import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Fab,
  Avatar,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Drawer,
  Link,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Storage } from "aws-amplify";
import withWidth from "@material-ui/core/withWidth";
import { CircularProgress } from "@material-ui/core";
import FontAwesomeIcon from "../common/font-awesome-icon";
import { connect } from "react-redux";
import {
  setErrorMessage,
  toggleOpenMenu,
} from "../../actions/user-plans-action";
import {
  checkSubscription,
  getSubscriptionPlanName,
} from "../../lib/subscription-plan-helper";
import RouterLink from "../common/router-link";
import { getSubcategoryTitle } from "../../lib/subcategory-helper";
import WorkoutPlans from "../../constants/plans";
import Languages from "../../constants/languages";
import { updatePlan, updatePlanImage } from "../../actions/user-plans-action";
import jimp from "jimp";
import { getLevel } from "../../lib/rates-helper";
import TrailLevelDetails from "../common/trail-level-details";
import { Capacitor } from "@capacitor/core";
import Person from "@material-ui/icons/Person";
import { Image } from "@material-ui/icons";
import { storageFactory } from "../../lib/storage-factory";
import history from "../../lib/history-helper";
import externalLinksEn from "../../constants/external-links-en";
import externalLinksEs from "../../constants/external-links-es";

const styles = (theme) => ({
  container: {
    //alignSelf: "flex-end",
    marginRight: 24,
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  paper: {
    background: theme.palette.background.inputText,
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
  },
  button: {
    // color: theme.palette.common.black,
    padding: "0",
    fontWeight: "normal",
    [theme.breakpoints.down("sm")]: {
      // color: theme.palette.common.white
    },
  },
  popper: {
    zIndex: 1,
    right: "0 !important",
    transform: "translate3d(0, 62px, 0px) !important",
    background: "#ffffff !important",
    width: "300px !important",
    left: "initial !important",

    [theme.breakpoints.down("sm")]: {
      bottom: 0,
      top: "initial !important",
      transform: "initial !important",
    },
  },
  fab: {
    width: 40,
    height: 40,
  },

  progressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: 109,
  },

  pictureContainer: {
    position: "relative",
    marginTop: theme.spacing.unit * 1.2,
    marginBottom: theme.spacing.unit * 1.2,
    marginRight: 8,
    marginLeft: "auto",
    top: 0,
    left: 0,
    "& svg": {
      position: "absolute",
      top: -14,
      left: -14,
      "& circle": {
        strokeLinecap: "butt !important",
        strokeDasharray: "20, 5",
        stroke: "rgb(255,216,197,0.3) !important",
      },
      "& path": {
        strokeLinecap: "butt !important",
        strokeDasharray: "20, 5",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: 35,
    },
  },

  pictureIcon: {
    position: "absolute",
    bottom: 5,
    right: 5,
    cursor: "pointer",
  },

  input: {
    display: "none",
  },

  flexContainer: {
    display: "flex",
    alignItems: "center",
  },

  containerMenu: {
    padding: 12,

    [theme.breakpoints.down("sm")]: {
      maxHeight: "100vh",
      overflow: "scroll",
    },
  },

  userTexts: {
    textAlign: "left",
  },

  nameText: {
    fontWeight: "normal",
    fontSize: 14,
    color: "#000000",
  },

  subscriptionText: {
    fontWeight: 400,
    fontSize: 12,
    color: "#9E9E9E",
  },

  currentPlanTitle: {
    color: "#212121",
    fontSize: 12,
    fontWeight: "normal",
    textAlign: "left",
    marginTop: 10,
  },

  currentPlanText: {
    color: "#212121",
    fontSize: 14,
    fontWeight: 500,
    textAlign: "left",
    marginBottom: 8,
  },

  bioTitle: {
    color: "#212121",
    fontSize: 14,
    fontWeight: "normal",
    textAlign: "left",
  },

  bioText: {
    color: "#212121",
    fontSize: 12,
    fontWeight: 400,
    textAlign: "left",
    marginBottom: 8,
    cursor: "pointer",
  },

  link: {
    color: "#212121",
    fontSize: 14,
    fontWeight: "normal",
    textAlign: "left",
    margin: "18px 0",
    textDecoration: "none",
  },

  linkB: {
    color: "#212121",
    fontSize: 14,
    fontWeight: "normal",
    textAlign: "left",
    margin: "9px 0",
  },

  linkReffer: {
    color: "#FF6F00",
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "left",
    margin: "18px 0",
  },

  unlockTitle: {
    color: "#9E9E9E",
    fontSize: 12,
    fontWeight: "normal",
    margin: "18px 0 0 0",
    textAlign: "left",
  },

  linkUnlock: {
    color: "#FF6F00",
    fontSize: 12,
    fontWeight: "normal",
    textAlign: "left",
    margin: "0 0 18px 0",
    textDecoration: "underline",
  },

  option: {
    marginLeft: "auto",
    cursor: "pointer",
    color: "#9E9E9E",
    fontWeight: "normal",
    fontSize: 14,
  },

  buttonText: {
    fontSize: 11,
    margin: "0",
  },

  rightButtons: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    position: "absolute",
    right: 0,
    zIndex: 100,
  },

  drawerRoot: {
    minHeight: "100vh",
    maxWidth: "500px",
    minWidth: "500px",
    background: "#FFFFFF",
    overflowX: "hidden",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      maxWidth: "80vw",
      minWidth: "80vw",
    },
  },

  levelContainer: {
    background: "#F9F9FB",
    display: "flex",
    margin: "5px -12px",
    padding: "8px 12px",
    alignItems: "center",
    cursor: "pointer",
  },

  levelBadge: {
    width: 32,
  },

  level: {
    marginLeft: 14,
    marginRight: "auto",
    fontWeight: "normal",
    fontSize: 14,
  },

  levelMiniBadge: {
    height: 25,
    bottom: -5,
    right: -10,
    position: "absolute",
  },
});

class ProfileMenu extends Component {
  state = {
    open: false,
    avatarLoading: true,
    avatarUrl: null,
    bio: null,
    bioDialogOpen: false,
    openLevel: false,
  };
  handleToggle = () => {
    storageFactory().removeItem("currentGoal");
    this.props.dispatch(toggleOpenMenu());
  };

  handleClose = async (event) => {
    if (
      (this.anchorEl && event && this.anchorEl.contains(event.target)) ||
      this.state.bioDialogOpen
    ) {
      return;
    }

    this.props.dispatch(toggleOpenMenu());
  };

  getAvatarUrl = async () => {
    const { userPlans, t } = this.props;
    const { currentPlan } = userPlans;
    const { image } = currentPlan || {};

    if (image) {
      try {
        const result = await Storage.get(image, { level: "public" });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              avatarUrl: result,
              avatarLoading: false,
            }),
          100,
        );
      } catch (exp) {
        this.handleError(
          new Error(
            t(
              "Oops, we could not load your profile picture. Give it another try",
              { ns: "messages" },
            ),
          ),
        );
        throw exp;
      }
    } else if (!image) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            avatarLoading: false,
          }),
        100,
      );
    }
  };

  setInitialUnit() {
    const units = localStorage.getItem("units");
    try {
      if (!units || units == "null") localStorage.setItem("units", "kms");
      if (units) localStorage.setItem("units", units);
    } catch (e) {
      console.log(e);
    }

    window.dispatchEvent(new Event("storage"));
    this.forceUpdate();
  }

  async componentDidMount() {
    this.getAvatarUrl();
    this.setInitialUnit();
  }

  handleError = (err) => {
    this.props.dispatch(setErrorMessage(this.getDisplayErrorMessage(err))); //GOES TO SNACK BAR
  };

  getDisplayErrorMessage = (err) => {
    let errorMessage = "";
    const { t } = this.props;

    if (typeof err === typeof string) {
      errorMessage = err;
    } else errorMessage = err.message || err.inputMessage || err.toString();

    switch (errorMessage) {
      case "Invalid Size":
        return t("Sorry, your image needs to be smaller than maxSize");
      case "Invalid File":
        return t("Invalid file, please try again");
      default:
        return errorMessage;
    }
  };

  handleChangeLanguage = () => {
    const { i18n } = this.props;
    const { language } = i18n;
    const id = language === "en" ? "es" : language === "es" ? "fr" : "en";
    i18n.changeLanguage(id);
  };

  handleChangeUnits = () => {
    const units = localStorage.getItem("units");
    const id = units === "kms" ? "miles" : "kms";
    try {
      localStorage.setItem("units", id);
    } catch (e) {
      console.log(e);
    }

    window.dispatchEvent(new Event("storage"));
    this.forceUpdate();
  };

  async componentDidUpdate(prevProps) {
    const { userPlans } = this.props;
    const { currentPlan, updatingImage } = userPlans;
    const { image } = currentPlan || {};
    if (
      (currentPlan &&
        currentPlan.image &&
        (!prevProps.userPlans ||
          !prevProps.userPlans.currentPlan ||
          !prevProps.userPlans.currentPlan.image ||
          prevProps.userPlans.currentPlan.image !== currentPlan.image)) ||
      (prevProps.userPlans.updatingImage && !updatingImage)
    ) {
      if (Image) {
        this.setState({
          ...this.state,
          avatarLoading: true,
        });
        this.getAvatarUrl();
      }
    }
  }

  handleOpenBio = (bio) => {
    this.setState({
      ...this.state,
      bio,
      bioDialogOpen: true,
    });
  };

  handleCloseBio = () => {
    setTimeout(
      () =>
        this.setState({
          ...this.state,
          bioDialogOpen: false,
        }),
      10,
    );
  };

  handleOpenLevel = () => {
    this.setState({
      ...this.state,
      openLevel: true,
    });
  };

  handleCloseLevel = () => {
    this.setState({
      ...this.state,
      openLevel: false,
    });
  };

  handleBioChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          ...this.state,
          bio: value.replace(/(\r\n|\n|\r)/gm, "").slice(0, 140) || "",
        });
      }
    }
  };

  handleSaveBio = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || {};
    const { bio } = this.state;
    if (bio) {
      this.props.dispatch(
        updatePlan(
          {
            cognito_user_sub,
            description: bio,
          },
          false,
          false,
        ),
      );
      this.setState({
        ...this.state,
        isEditing: false,
      });
    }
  };

  checkReload = () => {
    if (
      window.location.pathname &&
      window.location.pathname.startsWith("/dashboard")
    ) {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const image = await jimp.read(reader.result);
          await image.resize(150, jimp.AUTO);
          const data = await image.getBase64Async(jimp.MIME_JPEG);
          const fileNameArray = file.name ? file.name.split(".") : undefined;
          resolve({
            image: data,
            extension: fileNameArray[fileNameArray.length - 1],
          });
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleImageFieldChange = (value, extension) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      updatePlanImage({
        cognito_user_sub: currentPlan.cognito_user_sub,
        image: value,
        imageExtension: extension && extension.toLowerCase(),
      }),
    );
  };

  handleInputChange = async (event) => {
    if (event) {
      const { target } = event;
      const { maxSize, t } = this.props;
      if (target) {
        const { files } = target;
        if (files) {
          const selectedFile = files[0];
          try {
            if (selectedFile) {
              const { size } = selectedFile;
              if (size <= maxSize) {
                const { image: imageString, extension } = await this.getBase64(
                  selectedFile,
                );

                this.handleImageFieldChange(imageString, extension);
                this.setState({
                  ...this.state,
                  avatarUrl: undefined,
                });
              } else {
                this.handleError(
                  t("Sorry, your image needs to be smaller than maxSize", {
                    ns: "messages",
                  }),
                );
              }
            } else {
              this.handleError(t("Invalid File", { ns: "messages" }));
            }
          } catch (exp) {
            this.handleError(exp);
          }
        }
      }
    }
  };

  render() {
    const {
      classes,
      userPlans,
      auth,
      subscriptions,
      t,
      subcategories,
      i18n,
      onReferral,
      width,
    } = this.props;
    const { isAuthenticated } = auth;
    const { updatingImage, currentPlan, openMenu, loading } = userPlans;
    const { cognito_user_sub } = currentPlan || {};
    const {
      avatarUrl,
      avatarLoading,
      bio,
      bioDialogOpen,
      openLevel,
    } = this.state;

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const languageTitle =
      language === "en"
        ? Languages.Items.en
        : language === "fr"
        ? Languages.Items.fr
        : Languages.Items.es;

    const unitsTitle = localStorage.getItem("units");

    const { currentUser } = auth;
    let currentUserFullName = "";
    if (currentUser) {
      const { attributes } = currentUser;
      if (attributes) {
        const { name, family_name, email } = attributes;
        currentUserFullName =
          name || family_name ? `${name} ${family_name || ""}`.trim() : email;
      }
    }

    let isSubscribed = false;
    const {
      currentTraining,
      description,
      latestSubscriptionFinalizedTime,
      accumulatedDistance = 0,
      accumulatedElevation = 0,
    } = currentPlan || {};

    isSubscribed = checkSubscription(
      subscriptions,
      latestSubscriptionFinalizedTime,
    );
    const { latestExpired, loadingLatest, latest } = subscriptions;
    let { selectedPlan } = currentTraining || {};

    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    let planName;
    if (selectedPlan && selectedPlan !== "premium") {
      const subcategory =
        currentPlan &&
        currentPlan.currentTraining &&
        currentPlan.currentTraining.subcategory
          ? currentPlan.currentTraining.subcategory
          : +currentPlan.subcategory;
      planName =
        subcategories &&
        subcategories.data &&
        subcategories.data.length &&
        currentPlan.subcategory
          ? getSubcategoryTitle(
              subcategories.data,
              subcategory,
              languageDetector,
            )
          : selectedPlan === "free"
          ? "Free"
          : "Explore";
    }
    if (selectedPlan === "premium") {
      planName = "Premium";
    }

    const isInMobileView = width === "sm" || width === "xs";

    const level = loading
      ? 0
      : getLevel(accumulatedDistance, accumulatedElevation);

    const navigateTo = () => {
      !isAuthenticated
        ? history.push("/trailhead")
        : history.push(`/trailhead/profile/${cognito_user_sub}`);
      this.props.dispatch(toggleOpenMenu());
    };

    const paper = (
      <Paper className={classes.paper}>
        <ClickAwayListener onClickAway={this.handleClose.bind(this)}>
          <div className={classes.containerMenu}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleClose.bind(this)}
              />
            </div>
            <div className={classes.flexContainer}>
              <div>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  type="file"
                  onChange={this.handleInputChange}
                />
                <label htmlFor="contained-button-file">
                  {updatingImage || avatarLoading ? (
                    <div className={classes.progressContainer}>
                      <CircularProgress
                        variant="indeterminate"
                        color="primary"
                        data-testid="LoadingImage"
                      />
                    </div>
                  ) : (
                    <div className={classes.pictureContainer}>
                      <Fab
                        component="span"
                        className={classes.fab}
                        title="Change your picture"
                      >
                        <Avatar
                          src={
                            avatarUrl ||
                            `${process.env.PUBLIC_URL}/avatar-empty.png`
                          }
                          className={classes.avatar}
                        />
                      </Fab>

                      {!avatarUrl && (
                        <FontAwesomeIcon
                          icon="edit"
                          size="sm"
                          variant="regular"
                          className={classes.pictureIcon}
                        />
                      )}
                    </div>
                  )}
                </label>
              </div>

              <div className={classes.userTexts}>
                <Typography variant="body1" className={classes.nameText}>
                  {currentUserFullName}
                </Typography>

                <Typography
                  variant="body1"
                  className={classes.subscriptionText}
                >
                  {getSubscriptionPlanName(
                    latest && latest.plan_id ? latest.plan_id : "",
                  )}
                </Typography>
              </div>
            </div>

            <div
              className={classes.levelContainer}
              onClick={() => this.handleOpenLevel()}
            >
              <img
                src={`${process.env.PUBLIC_URL}/trl${level}-sm.svg`}
                className={classes.levelBadge}
                alt="level"
              />

              <Typography variant="body1" className={classes.level}>
                {t("Level", { ns: "layout" })} {level}
              </Typography>

              <img
                src={`${process.env.PUBLIC_URL}/icon-chevron-right.svg`}
                alt=""
              />
            </div>

            {/* <RouterLink
              to={
                isAuthenticated
                  ? `/trailhead/profile/${cognito_user_sub}`
                  : `/trailhead`
              }
            > */}
            <div className={classes.levelContainer} onClick={navigateTo}>
              <Person style={{ fontSize: 28 }} />

              <Typography variant="body1" className={classes.level}>
                {t("Public Profile", { ns: "layout" })}
              </Typography>

              <img
                src={`${process.env.PUBLIC_URL}/icon-chevron-right.svg`}
                alt=""
              />
            </div>
            {/* </RouterLink> */}

            {planName ? (
              <Typography variant="body1" className={classes.currentPlanTitle}>
                {t("My Current Training plan", { ns: "layout" })}
              </Typography>
            ) : (
              ""
            )}

            {planName ? (
              <Typography variant="body1" className={classes.currentPlanText}>
                {planName}
              </Typography>
            ) : (
              ""
            )}

            <Typography variant="body1" className={classes.bioTitle}>
              {t("My bio", { ns: "layout" })}
            </Typography>

            <Typography
              variant="body1"
              className={classes.bioText}
              style={{
                color: description ? "#212121" : "#FF6F00",
              }}
              onClick={() => this.handleOpenBio(description)}
            >
              {description ? description : t("Add your bio", { ns: "layout" })}
            </Typography>

            <RouterLink to="/">
              <Typography
                variant="body1"
                className={classes.link}
                onClick={() => this.handleClose()}
              >
                {t("Profile", { ns: "layout" })}
              </Typography>
            </RouterLink>

            <RouterLink to="/explore">
              <Typography
                variant="body1"
                className={classes.link}
                onClick={() => this.handleClose()}
              >
                {t("Explore", { ns: "layout" })}
              </Typography>
            </RouterLink>

            <RouterLink to="/trailhead">
              <Typography
                variant="body1"
                className={classes.link}
                onClick={() => this.handleClose()}
              >
                {t("Community", { ns: "layout" })}
              </Typography>
            </RouterLink>

            <RouterLink to="/challenges">
              <Typography
                variant="body1"
                className={classes.link}
                onClick={() => this.handleClose()}
              >
                {t("My Challenges", { ns: "layout" })}
              </Typography>
            </RouterLink>

            <RouterLink to="/explore/tag/Workouts [ Video ]">
              <Typography
                variant="body1"
                className={classes.link}
                onClick={() => this.handleClose()}
              >
                {t("Conditioning training", { ns: "layout" })}
              </Typography>
            </RouterLink>

            {/* {Capacitor.isNativePlatform() &&
            Capacitor.getPlatform() === "ios" ? (
              ""
            ) : (
              <RouterLink>
                <Typography
                  variant="body1"
                  className={classes.linkReffer}
                  onClick={() => onReferral()}
                >
                  {t("Reffer a friend", { ns: "layout" })}
                </Typography>
              </RouterLink>
            )} */}

            {!isSubscribed ? (
              <div>
                <Typography variant="body1" className={classes.unlockTitle}>
                  {t("Unlock all features to achieve your goals", {
                    ns: "layout",
                  })}
                </Typography>
                <RouterLink to="/subscription/explore">
                  <Typography variant="body1" className={classes.linkUnlock}>
                    {t("Upgrade Plan", { ns: "layout" })}
                  </Typography>
                </RouterLink>
              </div>
            ) : (
              ""
            )}

            <RouterLink to="/chooseplan">
              <Typography
                variant="body1"
                className={classes.link}
                onClick={() => this.handleClose()}
              >
                {t("Training plans menu", { ns: "layout" })}
              </Typography>
            </RouterLink>

            <Divider className={classes.divider} />

            <RouterLink to="/profile">
              <Typography
                variant="body1"
                className={classes.link}
                onClick={() => this.handleClose()}
              >
                {t("Settings", { ns: "layout" })}
              </Typography>
            </RouterLink>

            <div className={classes.flexContainer}>
              <Typography variant="body1" className={classes.linkB}>
                {t("Language", { ns: "layout" })}
              </Typography>

              <Typography
                variant="body1"
                className={classes.option}
                onClick={() => this.handleChangeLanguage()}
              >
                {languageTitle}
              </Typography>

              <img
                src={`${process.env.PUBLIC_URL}/icon-chevron-right.svg`}
                alt=""
              />
            </div>

            <div className={classes.flexContainer}>
              <Typography variant="body1" className={classes.linkB}>
                {t("Unit", { ns: "layout" })}
              </Typography>

              <Typography
                variant="body1"
                className={classes.option}
                onClick={() => this.handleChangeUnits()}
              >
                {unitsTitle === "miles"
                  ? t("Miles", { ns: "layout" })
                  : t("Kms", { ns: "layout" })}
              </Typography>

              <img
                src={`${process.env.PUBLIC_URL}/icon-chevron-right.svg`}
                alt=""
              />
            </div>

            {Capacitor.isNativePlatform() && (
              <Link
                href={
                  language === "en"
                    ? externalLinksEn.PrivacyPolicy
                    : externalLinksEs.PrivacyPolicy
                }
                target="_blank"
              >
                <Typography variant="body1" className={classes.link}>
                  {t("Privacy Policy")}
                </Typography>
              </Link>
            )}

            <RouterLink to="/signout">
              <Typography variant="body1" className={classes.link}>
                {t("Logout", { ns: "layout" })}
              </Typography>
            </RouterLink>
          </div>
        </ClickAwayListener>
      </Paper>
    );

    return (
      <div className={classes.container}>
        {isInMobileView ? (
          ""
        ) : (
          <div
            style={{
              position: "relative",
            }}
          >
            <Fab
              component="span"
              className={classes.fab}
              buttonRef={(node) => {
                this.anchorEl = node;
              }}
              aria-owns={openMenu ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={this.handleToggle}
            >
              <Avatar
                src={avatarUrl || `${process.env.PUBLIC_URL}/avatar-empty.png`}
                className={classes.avatar}
              />
            </Fab>
            {level ? (
              <img
                src={`${process.env.PUBLIC_URL}/trl${level}-sm.svg`}
                className={classes.levelMiniBadge}
                alt="level"
              />
            ) : (
              ""
            )}
          </div>
        )}
        {isInMobileView ? (
          <Drawer
            open={openMenu}
            anchor="right"
            onClose={this.handleClose}
            classes={{
              paper: classes.drawerRoot,
            }}
          >
            {paper}
          </Drawer>
        ) : (
          <Popper
            open={openMenu}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={classes.popper}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                {paper}
              </Grow>
            )}
          </Popper>
        )}

        <Dialog
          open={bioDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseBio}
          maxWidth="md"
        >
          <DialogContent>
            <Typography variant="body1" className={classes.bioTitle}>
              {t("My bio", { ns: "layout" })}
            </Typography>
            <TextField
              value={bio}
              margin="normal"
              variant="outlined"
              placeholder={t("Description placeholder", { ns: "trailhead" })}
              fullWidth={true}
              multiline={true}
              rowsMax={2}
              rows={2}
              onChange={this.handleBioChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleSaveBio();
                this.handleCloseBio();
              }}
              color="primary"
            >
              {t("Save", { ns: "layout" })}
            </Button>
            <Button onClick={this.handleCloseBio} color="primary">
              {t("Cancel", { ns: "layout" })}
            </Button>
          </DialogActions>
        </Dialog>
        <TrailLevelDetails
          isOpen={openLevel}
          onClose={() => this.handleCloseLevel()}
        ></TrailLevelDetails>
      </div>
    );
  }
}

ProfileMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func,
    language: PropTypes.string,
  }),
};

ProfileMenu.defaultProps = {
  maxSize: 5120000,
};

export default connect((store) => {
  return {
    strava: store.strava,
    userPlans: store.userPlans,
    auth: store.auth,
    trailHead: store.trailHead,
    subscriptions: store.subscriptions,
    challenges: store.challenges,
    subcategories: store.subcategories,
  };
})(
  withTranslation(
    "dashboard",
    "messages",
    "trailhead",
    "challenges",
  )(withWidth()(withStyles(styles, { withTheme: true })(ProfileMenu))),
);
