export const WorkoutTypes = {
  Speedwork: "Speedwork",
  LongRun: "Long run",
  ClimbWorkout: "Uphill workout",
  DescendWorkout: "Downhill workout",
  Tempo: "Tempo run",
  Fartlek: "Fartlek workout",
  Recovery: "Recovery",
  Strength: "Cross training",
  Corework: "Conditioning workout",
  EasyRun: "Easy workout",
  UserCreated: "User created",
};

const WorkoutColors = {
  Speedwork: "#FF6327",
  LongRun: "#1A629B",
  ClimbWorkout: "#FF6327",
  DescendWorkout: "#FF6327",
  Tempo: "#FF6327",
  Fartlek: "#FF6327",
  Recovery: "#CFD8DC",
  Strength: "#FFD600",
  Corework: "#578fCC",
  EasyRun: "#00AD96",
  UserCreated: "#AE887A",
  NoWorkout: "#FFBA9B",
};

export const LightWorkoutColors = {
  Speedwork: "#EF698C",
  LongRun: "#4780AB",
  ClimbWorkout: "#EF698C",
  DescendWorkout: "#EF698C",
  Tempo: "#E07668",
  Fartlek: "#EF698C",
  Recovery: "#EAEAEA",
  Strength: "#E7B66D",
  Corework: "#2BA5A2",
  EasyRun: "#6BB488",
  NoWorkout: "#ffffff00",
  UserCreated: "#AE887A",
};

export const WorkoutTypesLangKeys = {
  Speedwork: "training.workoutType.speedwork",
  LongRun: "training.workoutType.longRun",
  ClimbWorkout: "training.workoutType.climbWorkout",
  DescendWorkout: "training.workoutType.descendWorkout",
  Tempo: "training.workoutType.tempo",
  Fartlek: "training.workoutType.fartlek",
  Recovery: "training.workoutType.recovery",
  Strength: "training.workoutType.strength",
  Corework: "training.workoutType.corework",
  EasyRun: "training.workoutType.easyRun",
  UserCreated: "training.workoutType.userCreated",
};

export default {
  Items: WorkoutTypes,
  Colors: WorkoutColors,
  itemsArray: Object.keys(WorkoutTypes).map((key) => {
    return {
      text: WorkoutTypes[key],
      value: key,
    };
  }),
};
