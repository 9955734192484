import React from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { RaceCard } from "./race-card";
import { palette } from "../../../theme/palette";
import ModalCustom from "../../common/v2/ModalCustom";
import FormRace from "../../common/v2/FormRace";
import ButtonDashed from "../../common/v2/ButtonDashed";
import useRaceList from "../../../hooks/useRaceList";
import { useTranslation } from "react-i18next";
import RaceCardSkeleton from "../../common/v2/RaceCardSkeleton";
import CommunityPostPreviewDialog from "../../common/v2/CommunityPostPreviewDialog";

const options = [
  {
    label: "profile.races.filter.upcomingRaces",
    value: 1,
  },
  {
    label: "profile.races.filter.pastRaces",
    value: 2,
  },
  {
    label: "profile.races.filter.allRaces",
    value: 0,
  },
];

const useStyles = makeStyles({
  box: {
    display: "flex",
    padding: "24px 0px",
    boxSizing: "border-box",
    width: "100%",
  },
  select: {
    fontSize: "16px",
    padding: "1px !important",
    borderRadius: "4px",
    border: palette.lightGray,
    fontFamily: "Karla, sans-serif",
    fontWeight: 400,
    backgroundColor: "#FEFFFF",
  },
  root: {
    padding: "10px 12px",
  },
});

export const RaceList = () => {
  const { t } = useTranslation("profile");
  const classes = useStyles();
  const {
    loading,
    races,
    raceToEdit,
    formType,
    titleForm,
    openForm,
    selectValue,
    goalPostPreview,
    editRace,
    shareRace,
    handleSave,
    handleFilter,
    handleOpenForm,
    handleCloseForm,
    handleDelete,
    handleCloseGoalPreview,
  } = useRaceList();

  return (
    <Box sx={{ width: "100%" }}>
      <FormControl className={classes.box}>
        <Select
          color="secondary"
          value={selectValue}
          className={classes.select}
          fullWidth
          variant="outlined"
          disableUnderline
          classes={{ root: classes.root }}
          onChange={handleFilter}
          data-testid="profile_drop_race"
        >
          {options.map((item, index) => (
            <MenuItem
              value={item.value}
              data-testid={`profile_drop_race_${index + 1}`}
              key={index}
            >
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ButtonDashed
        id="profile_add_race"
        onClick={() => handleOpenForm("add")}
        label={t("profile.races.add")}
        withAddIcon={true}
      />

      <ModalCustom
        fullScreen={false}
        open={openForm}
        onClose={handleCloseForm}
        title={t(titleForm)}
      >
        <FormRace
          type={formType}
          race={raceToEdit}
          onCancel={handleCloseForm}
          onSave={handleSave}
        />
      </ModalCustom>

      <CommunityPostPreviewDialog
        handleOpenDialog={goalPostPreview}
        handleCloseDialog={handleCloseGoalPreview}
        isFromShare={true}
      />

      {loading ? (
        <>
          <RaceCardSkeleton />
          <RaceCardSkeleton />
          <RaceCardSkeleton />
        </>
      ) : races && races.length > 0 ? (
        races.map((race, index) => (
          <RaceCard
            race={race}
            key={index}
            handleEdit={editRace}
            handleDelete={handleDelete}
            handleShare={shareRace}
          />
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "auto",
            justifyContent: "center",
            alignItems: "center",
            padding: 30,
          }}
        >
          <Typography variant="h4">{t("profile.races.emptyList")}</Typography>
        </Box>
      )}
    </Box>
  );
};
