import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import PlanFieldTransition from './plan-field-transition';

class PlanFieldCheckboxGroup extends Component {
    render() {
        const { id, selected, onChange, xs, sm, md, lg, xl, items } = this.props;
        return (
            <PlanFieldTransition>
                <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <FormControl component="fieldset">
                        {items ?
                            <FormGroup id={id} row>
                                {items.map((item, index) => {
                                    return <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                icon={<RadioButtonUncheckedIcon />}
                                                checkedIcon={<RadioButtonCheckedIcon />}
                                                checked={selected && selected.indexOf(item.value) > -1}
                                                onChange={onChange}
                                                value={item.value}
                                                color="primary" />
                                        }
                                        label={item.name}
                                        labelPlacement="end"
                                    />
                                })}
                            </FormGroup>
                            : null}
                    </FormControl>
                </Grid>
            </PlanFieldTransition>
        );
    };
}

PlanFieldCheckboxGroup.propTypes = {
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    id: PropTypes.string.isRequired,
    selected: PropTypes.array,
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func
}

export default PlanFieldCheckboxGroup;