import React, { memo } from "react";
import { Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import history from "../../../lib/history-helper";
import { useParams } from "react-router-dom";

const styles = (theme) => ({
  commonCard: {
    background: "white",
    margin: "16px 16px 0px 16px",
    borderRadius: "12px",
    padding: "16px",
    boxSizing: "border-box",
    height: "auto",
    cursor: "pointer",
  },
  cardTitleParent: {
    display: "flex",
    justifyContent: "space-between",
    algnItems: "flex-start",
    marginBottom: "16px",
  },
  cardSubtitle: {
    marginTop: 9,
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "center",
  },
  orangeText: {
    color: "#FF5722",
  },
  grayText: {
    color: "#EEEEEE",
  },
  emptyGroup: {
    height: 110,
    width: "100%",
    backgroundColor: "#EEEEEE",
    borderRadius: "12px",
  },

  emptyText: {
    marginTop: 9,
    fontSize: "12px",
    textAlign: "center",
  },
});

const GroupsCard = ({ leftText, rightText, data, classes, t, i18n }) => {
  const { language = "en" } = i18n;
  const params = useParams();

  const {
    trailHead: { publicGroups, selectedAthlete },
  } = useSelector((state) => state);
  const { publicGroups: userPublicGroups = [] } = selectedAthlete || {};

  const joinedGroups = () => {
    const groups = (userPublicGroups || []).map((userGroup) => {
      const match = publicGroups.find((item) => item.id == userGroup);
      return match;
    });
    return groups || [];
  };

  const navigateTo = () => {
    const { id } = params;
    history.push(`/trailhead/${id}/all-groupes`);
  };

  return (
    <div className={classes.commonCard} onClick={navigateTo}>
      <div className={classes.cardTitleParent}>
        <Typography variant="body2">{t(leftText)}</Typography>
        <Typography variant="body2" className={classes.orangeText}>
          {t(rightText)}
        </Typography>
      </div>
      <Grid container spacing={2}>
        {userPublicGroups.length ? (
          joinedGroups()
            .slice(0, 2)
            .map((item = {}) => (
              <Grid item xs={6} md={6} key={item.id}>
                <div>
                  <img
                    src={
                      item.image ||
                      `${process.env.PUBLIC_URL}/user-profile/group.png`
                    }
                    width="100%"
                    height="100%"
                    style={{ borderRadius: "12px" }}
                  ></img>
                </div>
                {item.name && (
                  <Typography className={classes.cardSubtitle} variant="body2">
                    {item.name[language || "en"]}
                  </Typography>
                )}
              </Grid>
            ))
        ) : (
          <Grid item xs={6} md={6}>
            <div className={classes.emptyGroup}></div>
            <Typography className={classes.emptyText} variant="body2">
              {t("No Groupes")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("trailhead")(memo(GroupsCard)),
);
