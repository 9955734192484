import React, { memo } from "react";
import { withTranslation } from "react-i18next";
import { Typography, withStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { clearSignIn } from "../../actions/athletes-action";
import history from "../../lib/history-helper";
import { Routes } from "../../lib/routing-middleware";

const styles = (theme) => ({
  button: {
    borderRadius: "4px",
    width: "100%",
    "&:first-child": {
      marginBottom: "16px",
    },
  },
  title: {
    fontWeight: "bold",
    marginBottom: 20,
  },
});

const NoAccount = ({ classes, handleSignUp, t }) => {
  const dispatch = useDispatch();
  const tryAgain = () => dispatch(clearSignIn());
  const createNewAccount = () => handleSignUp();

  return (
    <>
      <Button
        variant="outlined"
        className={classes.button}
        size="large"
        onClick={tryAgain}
        id="try-again-button"
      >
        {t("Try again with another email")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={createNewAccount}
        size="large"
        id="new-account-button"
      >
        {t("Create a new account")}
      </Button>
    </>
  );
};

export default withTranslation("auth")(
  withStyles(styles, { withTheme: true })(memo(NoAccount)),
);
