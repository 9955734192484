import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../../theme";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomerCoachRate,
  setCustomerPlanRate,
  setrequestCoachChange,
} from "../../../../actions/user-plans-action";
import { useTranslation } from "react-i18next";
import { palette } from "../../../../theme/palette";
const useStyles = makeStyles({
  thankYouForYourFeedback: {
    width: "250px",
    margin: "0",
  },
  wouldYouLikeToAddAnything: {
    margin: "0",
    fontSize: theme.typography.h6.fontSize,
    color: "#6d6f71",
  },
  thankYouForContainer: {
    width: "266px",
    flex: "1",
    position: "absolute",
    lineHeight: "130%",
  },
  backgroundAlert: {
    width: "100%",
    backgroundColor: "#24232348",
    height: "100%",
    position: "absolute",
    zIndex: 9999,
  },

  titleclose: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  placeholder: {
    position: "relative",
    lineHeight: "150%",
  },
  inputPlaceholder: {
    color: palette.darkGray,
    fontFamily: "Karla",
    alignSelf: "stretch",
    flex: "1",
    border: "0px solid #eaeaea",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "8px 12px",
  },
  textInput: {
    alignSelf: "stretch",
    borderRadius: "4px",
    border: "2px solid #eaeaea",
    boxSizing: "border-box",
    height: "204px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    color: "#afb1b4",
  },
  text: {
    position: "relative",
    lineHeight: "125%",
  },
  secondaryButton: {
    alignSelf: "stretch",
    flex: "1",
    borderRadius: "4px",
    border: "2px solid #0b2b5c",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px",
  },
  primaryButton: {
    alignSelf: "stretch",
    flex: "1",
    borderRadius: "4px",
    backgroundColor: theme.palette.secondary.darkBlue,
    boxShadow: "0px 4px 30px rgba(18, 21, 27, 0.05)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "137px",
    padding: "12px",
    color: "#feffff",
  },
  buttons: {
    alignSelf: "stretch",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "12px",
    textAlign: "center",
    fontSize: "18px",
    color: theme.palette.secondary.darkBlue,
  },
  popupcoachFeedback: {
    position: "absolute",
    borderRadius: "8px",
    backgroundColor: "#feffff",
    boxShadow: "0px 4px 30px rgba(18, 21, 27, 0.05)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    padding: "16px",
    boxSizing: "border-box",
    gap: "16px",
    textAlign: "left",
    fontSize: theme.typography.h5.fontSize,
    color: "#12151b",
    fontFamily: "Karla",
  },
});
const Message = ({
  title,
  activate,
  setActivate,
  marginTextArea,
  secondText,
  textArea,
  rateState,
  type,
}) => {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  let styles = useStyles();
  let disable = () => {
    setActivate(false);
  };
  let [text, setText] = useState("");
  let currentPlan = useSelector((state) => state.userPlans);
  let saved = () => {
    if (type === "coach") {
      dispatch(
        setCustomerCoachRate(
          currentPlan.currentPlan.cognito_user_sub,
          rateState,
          text,
        ),
      );
    } else if (type === "plan") {
      dispatch(
        setCustomerPlanRate(
          currentPlan.currentPlan.cognito_user_sub,
          rateState,
          text,
        ),
      );
    } else if (type === "requestCoachChange") {
      dispatch(
        setrequestCoachChange(currentPlan.currentPlan.cognito_user_email, text),
      );
    }
    setActivate(false);
  };
  return (
    <>
      {activate === false ? (
        ""
      ) : (
        <div className={styles.backgroundAlert}>
          <div className={styles.popupcoachFeedback}>
            <div className={styles.titleclose}>
              <div className={styles.thankYouForContainer}>
                <p className={styles.thankYouForYourFeedback}>
                  <span>
                    <b>{t(title)}</b>
                  </span>
                </p>
                <p className={styles.wouldYouLikeToAddAnything}>
                  <span>
                    <span>{t(secondText)}</span>
                  </span>
                </p>
              </div>
            </div>
            <CloseIcon
              onClick={disable}
              color="disabled"
              sx={{ position: "relative", top: "-20px", marginLeft: "auto" }}
            />

            <div
              className={styles.textInput}
              style={{ marginTop: marginTextArea === 0 ? "0px" : "40px" }}
            >
              <textArea
                className={styles.inputPlaceholder}
                value={text}
                onChange={(event) => setText(event.target.value)}
                placeholder={textArea}
              ></textArea>
            </div>
            <div className={styles.buttons}>
              <div onClick={disable} className={styles.secondaryButton}>
                <b className={styles.text}>{t("Cancel")}</b>
              </div>
              <div onClick={saved} className={styles.primaryButton}>
                <b className={styles.text}>{t("Send")}</b>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Message;
