import { API } from "aws-amplify";
import moment from "moment";

import TrainingsService from "./trainings-service";
import StravaService from "./strava-service";
import NotificationsService from "./notifications-service";
import { getNextMonday, dateFormat } from "../lib/date-helper";
import { Storage } from "aws-amplify";
import GarminService from "./garmin-service";
import SuuntoService from "./suunto-service";
import CorosService from "./coros-service";
import VertActivityService from "./vert-activity-service";
import { standarizeActivities } from "../lib/activities-helper";
import bugsnagClient from "../lib/bugsnag-client";

import {
  addEasyWeek,
  addWeek,
  addWorkout,
  chainTraining,
  deleteWorkout,
  editWorkout,
  moveWorkout,
  removeChained,
  removeWeek,
  addBlockWeek,
} from "../lib/plan-helper";

class AthletesService {
  static API_NAME = "apiathletes";
  static ATHLETES_SEARCH_COACH_PATH = "/athletes/coach";
  static ATHLETES_SEARCH_ADMIN_PATH = "/athletes";
  static ATHLETES_GET_ATHLETE_DETAILS =
    "/athletes/athletedata/:cognito_user_sub";

  static ATHLETES_GET_COUNT_PATH = "/athletes/coach/total";
  static ATHLETES_GET_COACHES_PATH = "/athletes/coach/list";
  static ATHLETES_GET_STRENGTH_COACHES_PATH =
    "/athletes/coach/liststrengthcoaches";
  static ATHLETES_ASSIGN_COACHE_PATH =
    "/athletes/:cognito_user_sub/coach/:coach_cognito_user_sub";

  static ATHLETES_ASSIGN_STRENGTH_COACH_PATH =
    "/athletes/:cognito_user_sub/strengthcoach/:coach_cognito_user_sub";

  static ATHLETES_GET_OVERALL_WORKOUT_COUNT_PATH =
    "/athletes/statistic/:cognito_user_sub";
  static ATHLETES_ASSIGN_TRAINING_PATH = "/athletes/:cognito_user_sub/training";
  static ATHLETES_CHAIN_TRAINING_PATH =
    "/athletes/:cognito_user_sub/chaintraining";
  static ATHLETES_PATCH_TRAINING_PATH = "/athletes/:cognito_user_sub/training";

  static ATHLETES_UPDATE_CHAIN_TRAINING_PATH =
    "/athletes/:cognito_user_sub/updatechainedplans";

  static ATHLETES_PATCH_RACES_PATH = "/athletes/:cognito_user_sub/races";

  static ATHLETES_PATCH_VIEWED_TRAINING_PATH =
    "/athletes/:cognito_user_sub/viewed";

  static ATHLETES_PATCH_ENDDATE_TRAINING_PATH =
    "/athletes/:cognito_user_sub/endDate";

  static ATHLETES_PATCH_TRAINING_COMMENT_PATH =
    "/athletes/:cognito_user_sub/comment";

  static ATHLETES_PATCH_TRAINING_BULK_COMMENT_PATH = "/athletes/bulkComment";

  static ATHLETES_GET_TRAINING_COMMENTS_PATH =
    "/athletes/:cognito_user_sub/comments/:lastDate";

  static ATHLETES_GET_TRAINING_PLAN_PATH =
    "/athletes/:cognito_user_sub/training/:planid";

  static ATHLETES_PATCH_TRAINING_COMMENT_DELETE_PATH =
    "/athletes/:cognito_user_sub/commentdelete";
  static ATHLETES_PATCH_TRAINING_COMMENT_EDIT_PATH =
    "/athletes/:cognito_user_sub/commentedit";
  static ATHLETES_PATCH_TRAINING_COMMENTS_READ_PATH =
    "/athletes/:cognito_user_sub/commentsread";
  static ATHLETES_PATCH_EDIT_WORKOUT_PATH =
    "/athletes/:cognito_user_sub/editworkout";
  static ATHLETES_PATCH_ADD_WORKOUT_PATH =
    "/athletes/:cognito_user_sub/addworkout";
  static ATHLETES_PATCH_DELETE_WORKOUT_PATH =
    "/athletes/:cognito_user_sub/deleteworkout";
  static ATHLETES_PATCH_MOVE_WORKOUTS_PATH =
    "/athletes/:cognito_user_sub/swapworkouts";
  static ATHLETES_PATCH_ADD_WEEK_PATH = "/athletes/:cognito_user_sub/addweek";
  static ATHLETES_PATCH_ADD_EASY_WEEK_PATH =
    "/athletes/:cognito_user_sub/addeasyweek";
  static ATHLETES_PATCH_REMOVE_WEEK_PATH =
    "/athletes/:cognito_user_sub/removeweek";
  static ATHLETES_PATCH_REMOVE_CHAIN_PATH =
    "/athletes/:cognito_user_sub/removechained";
  static ATHLETES_GET_SIGNUP_METHOD_PATH = "/athletes/signupmethod/:user_email";

  static ATHLETES_GET_DATA_PATH = "/athletes/data/:from/:to";
  static ATHLETES_PAGINATED_PLAN = "/athletes/getS3Plan/:cognito_user_sub";

  static async athletesSearch(
    searchParams,
    isAdmin,
    cognito_user_sub,
    athletes = [],
  ) {
    const { searchPhrase, selectedPlan, coachSub } = searchParams;
    if (
      (isAdmin && !searchPhrase && !coachSub && !selectedPlan) ||
      !cognito_user_sub
    ) {
      throw new Error("Invalid Keys");
    } else {
      let myInit = {
        // OPTIONAL
        queryStringParameters: searchParams,
      };
      const response = await API.get(
        this.API_NAME,
        isAdmin
          ? this.ATHLETES_SEARCH_ADMIN_PATH
          : this.ATHLETES_SEARCH_COACH_PATH,
        myInit,
      );

      const {
        success,
        result,
        error,
        nextExclusiveStartKey,
        itemsPerPage,
        totalUnreadMessages,
      } = response;

      if (success) {
        const users = result;

        if (users) {
          for (let u = 0; u < users.length; u++) {
            let found = false;
            if (athletes) {
              const athlete = athletes.find(
                (a) => a.cognito_user_sub === users[u].cognito_user_sub,
              );
              if (athlete) {
                found = true;
                users[u].currentTraining = athlete.currentTraining;
              }
            }
          }
        }
        return {
          users: users ? users : [],
          nextExclusiveStartKey,
          itemsPerPage,
          totalUnreadMessages,
        };
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    }
  }

  static async getCurrentTraining(athlete) {
    try {
      const downloadedData = await Storage.get(`latest`, {
        level: "protected",
        identityId: athlete.cognito_user_sub,
        download: true,
        bucket: process.env.REACT_APP_PLANS_BUCKET,
        cacheControl: "no-cache, no-store, must-revalidate",
        expires: 0,
        pragma: "no-cache",
      });
      const data = await downloadedData.Body.text();
      console.log("athleteData getcurrentTraining ", JSON.parse(data));

      return {
        ...athlete,
        currentTraining: JSON.parse(data),
      };
    } catch {
      return {
        ...athlete,
        currentTraining: undefined,
      };
    }
  }

  static async getCoachAthletesCount() {
    const response = await API.get(this.API_NAME, this.ATHLETES_GET_COUNT_PATH);
    const { success, error } = response;

    if (success) return response;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async getCoaches() {
    const response = await API.get(
      this.API_NAME,
      this.ATHLETES_GET_COACHES_PATH,
    );
    const { success, result, error } = response;

    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static handleFormat(data) {
    return [...data].map(({ Attributes = {} }) => {
      const { Value: value } = Attributes.find((subItem) => {
        return subItem.Name === "sub";
      });

      const { Value: label } = Attributes.find((subItem) => {
        return subItem.Name === "name";
      });

      return { label, value };
    });
  }

  static async getStrengthCoaches() {
    const response = await API.get(
      this.API_NAME,
      this.ATHLETES_GET_STRENGTH_COACHES_PATH,
    );

    const { success, result, error } = response;

    const data = this.handleFormat(result);

    if (success) return data;
    if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async getData(from, to) {
    if (from && to) {
      const response = await API.get(
        this.API_NAME,
        this.ATHLETES_GET_DATA_PATH.replace(":from", from).replace(":to", to),
      );
      const { success, result, error } = response;

      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async assignCoachToAthlete(assignParams) {
    const { cognito_user_sub, coach_cognito_user_sub } = assignParams;
    if (cognito_user_sub && coach_cognito_user_sub) {
      const response = await API.patch(
        this.API_NAME,
        this.ATHLETES_ASSIGN_COACHE_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ).replace(":coach_cognito_user_sub", coach_cognito_user_sub),
      );
      const { success, result, error } = response;

      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async assignStrengthCoachToAthlete({
    cognito_user_sub,
    coach_cognito_user_sub,
  }) {
    const response = await API.patch(
      this.API_NAME,
      this.ATHLETES_ASSIGN_STRENGTH_COACH_PATH.replace(
        ":cognito_user_sub",
        cognito_user_sub,
      ).replace(":coach_cognito_user_sub", coach_cognito_user_sub),
    );
    const { success, result, error } = response;

    if (success) return result;
    if (typeof error === typeof "") throw new Error(error);
    else throw error;
  }

  static async getOverallWorkoutsCount(cognito_user_sub) {
    if (cognito_user_sub) {
      const response = await API.get(
        this.API_NAME,
        this.ATHLETES_GET_OVERALL_WORKOUT_COUNT_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
      );
      const { success, result, error } = response;

      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async getTrainingPlan({ id, training_order } = {}, startingDate) {
    if (id && training_order) {
      const result = await TrainingsService.readTraining({
        id,
        training_order,
      });
      if (result) {
        const startingDateFinal = startingDate ? startingDate : getNextMonday();
        const trainingPlan = result;
        const trainingPlanInstance = {
          ...trainingPlan,
          startingDate: moment(startingDateFinal).format(dateFormat),
          days: trainingPlan.days.map(
            (day, index) =>
              day && [
                ...day.map((training) => {
                  return {
                    ...training,
                    plannedDate: moment(startingDateFinal)
                      .add(index, "days")
                      .format(dateFormat),
                  };
                }),
              ],
          ),
        };
        return trainingPlanInstance;
      }
    }
    return undefined;
  }

  static async calculateCurrentTrainingPlanEndDate(trainingPlan) {
    if (trainingPlan) {
      const { startingDate, durationInWeeks } = trainingPlan;
      if (startingDate && durationInWeeks) {
        return moment(startingDate, "DD/MM/YYYY")
          .add(durationInWeeks, "weeks")
          .add(-1, "day")
          .endOf("day")
          .unix();
      }
    }
    return undefined;
  }

  static async assignTrainingToAthlete(assignParams) {
    const { cognito_user_sub, trainingPlan, startingDate } = assignParams;
    if (cognito_user_sub && trainingPlan) {
      const readTrainingPlan = await this.getTrainingPlan(
        trainingPlan,
        startingDate,
      );
      if (readTrainingPlan) {
        let newTrainingPlan = readTrainingPlan;
        if (newTrainingPlan.raceCount === "auto") {
          newTrainingPlan = {
            ...newTrainingPlan,
            raceCount: trainingPlan.raceCount,
          };
        }

        let myInit = {
          // OPTIONAL
          body: newTrainingPlan,
        };
        const response = await API.put(
          this.API_NAME,
          this.ATHLETES_ASSIGN_TRAINING_PATH.replace(
            ":cognito_user_sub",
            cognito_user_sub,
          ),
          myInit,
        );
        const { success, result, error } = response;

        const downloadedData = await Storage.get(`latest`, {
          level: "protected",
          identityId: result.cognito_user_sub,
          download: true,
          bucket: process.env.REACT_APP_PLANS_BUCKET,
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });

        const data = await downloadedData.Body.text();

        if (success) {
          return {
            ...result,
            currentTraining: JSON.parse(data),
          };
        } else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      } else {
        throw new Error("Invalid Training Plan");
      }
    } else throw new Error("Invalid Keys");
  }

  static async chainTrainingToAthleteAfterCurrent(assignParams) {
    const {
      selectedAthlete,
      trainingPlan,
      startingDate,
      position,
      level,
    } = assignParams;

    if (selectedAthlete && trainingPlan) {
      const readTrainingPlan = await this.getTrainingPlan(
        trainingPlan,
        startingDate,
      );
      if (readTrainingPlan) {
        let { cognito_user_sub, currentTraining } = selectedAthlete;

        if (cognito_user_sub) {
          const currentTrainingChanges = chainTraining(
            currentTraining,
            readTrainingPlan,
            position,
            level,
          );

          const current = currentTrainingChanges.currentTraining;

          let myInit = {
            body: {
              trainingPlan: current,
            },
          };

          const response = await API.put(
            this.API_NAME,
            this.ATHLETES_UPDATE_CHAIN_TRAINING_PATH.replace(
              ":cognito_user_sub",
              cognito_user_sub,
            ),
            myInit,
          );

          return { ...selectedAthlete, ...currentTrainingChanges };
        } else throw new Error("INVALID INPUT");
      } else {
        throw new Error("Invalid Training Plan");
      }
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingPatch(
    selectedAthlete,
    notificationChangesList = [],
  ) {
    const { cognito_user_sub, currentTraining } = selectedAthlete;
    if (cognito_user_sub && currentTraining) {
      const myInit = {
        body: { ...currentTraining },
      };
      const response = await API.patch(
        this.API_NAME,
        this.ATHLETES_PATCH_TRAINING_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        if (notificationChangesList && notificationChangesList.length > 0)
          await NotificationsService.notificationsCreate(
            notificationChangesList,
          );
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingPatchRaces(selectedAthlete) {
    const { cognito_user_sub } = selectedAthlete;
    if (cognito_user_sub) {
      const myInit = {
        body: { ...selectedAthlete },
      };
      const response = await API.patch(
        this.API_NAME,
        this.ATHLETES_PATCH_RACES_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingViewedPatch(cognito_user_sub) {
    if (cognito_user_sub) {
      const response = await API.patch(
        this.API_NAME,
        this.ATHLETES_PATCH_VIEWED_TRAINING_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
      );
      const { success, result, error } = response;
      if (success) {
        delete result.currentTraining;
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingEndDatePatch(cognito_user_sub, endDate) {
    if (cognito_user_sub) {
      const response = await API.patch(
        this.API_NAME,
        this.ATHLETES_PATCH_ENDDATE_TRAINING_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
        { body: { currentTrainingEndDate: endDate } },
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async swapWorkouts(workoutsToMoveData, planId = 0) {
    if (workoutsToMoveData) {
      const { selectedAthlete } = workoutsToMoveData;
      let { cognito_user_sub, currentTraining } = selectedAthlete;

      if (cognito_user_sub) {
        const currentTrainingChanges = moveWorkout(
          currentTraining,
          workoutsToMoveData,
        );
        return currentTrainingChanges;
      } else throw new Error("INVALID INPUT");
    } else throw new Error("INVALID INPUT");
  }

  static async athleteTrainingPlan(cognito_user_sub, planid) {
    if (cognito_user_sub && planid) {
      const response = await API.get(
        this.API_NAME,
        this.ATHLETES_GET_TRAINING_PLAN_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ).replace(":planid", planid),
      );
      const { success, result, error } = response;
      const { cognito_user_sub: sub } = result || {};

      const downloadedData = await Storage.get(`latest`, {
        level: "protected",
        identityId: result ? sub : cognito_user_sub,
        download: true,
        bucket: process.env.REACT_APP_PLANS_BUCKET,
        cacheControl: "no-cache, no-store, must-revalidate",
        expires: 0,
        pragma: "no-cache",
      });

      const data = await downloadedData.Body.text();

      if (success) {
        return {
          result: {
            ...result,
            currentTraining: JSON.parse(data),
          },
        };
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingComments(cognito_user_sub, lastDate) {
    if (cognito_user_sub) {
      const response = await API.get(
        this.API_NAME,
        this.ATHLETES_GET_TRAINING_COMMENTS_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ).replace(":lastDate", lastDate),
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingPatchComment(selectedAthlete, coachComment) {
    const { cognito_user_sub } = selectedAthlete;
    if (cognito_user_sub && coachComment) {
      const myInit = {
        body: { coachComment },
      };
      const response = await API.post(
        this.API_NAME,
        this.ATHLETES_PATCH_TRAINING_COMMENT_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingSendBulkMessage({ message, recipients }) {
    if (message && recipients) {
      const myInit = {
        body: { coachComment: message, cognito_user_subs: recipients },
      };
      const response = await API.post(
        this.API_NAME,
        this.ATHLETES_PATCH_TRAINING_BULK_COMMENT_PATH,
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingPatchDeleteComment(
    selectedAthlete,
    coachComment,
  ) {
    const { cognito_user_sub } = selectedAthlete;
    if (cognito_user_sub && coachComment) {
      const myInit = {
        body: { coachComment },
      };
      const response = await API.patch(
        this.API_NAME,
        this.ATHLETES_PATCH_TRAINING_COMMENT_DELETE_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingPatchEditComment(selectedAthlete, coachComment) {
    const { cognito_user_sub } = selectedAthlete;
    if (cognito_user_sub && coachComment) {
      const myInit = {
        body: { coachComment },
      };
      const response = await API.patch(
        this.API_NAME,
        this.ATHLETES_PATCH_TRAINING_COMMENT_EDIT_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingPatchCommentsRead(selectedAthlete) {
    const { cognito_user_sub } = selectedAthlete;
    if (cognito_user_sub) {
      const response = await API.patch(
        this.API_NAME,
        this.ATHLETES_PATCH_TRAINING_COMMENTS_READ_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingPatchEditWorkout(
    selectedAthlete,
    data,
    planId = 0,
  ) {
    let { cognito_user_sub, currentTraining } = selectedAthlete;

    if (cognito_user_sub && data) {
      const currentTrainingChanges = editWorkout(currentTraining, data);

      return currentTrainingChanges;
    } else throw new Error("INVALID INPUT");
  }

  static async athleteTrainingPatchAddWorkout(
    selectedAthlete,
    plannedDate,
    workout,
    planId = 0,
  ) {
    let { cognito_user_sub, currentTraining } = selectedAthlete;

    if (cognito_user_sub && workout) {
      const currentTrainingChanges = addWorkout(currentTraining, {
        ...workout,
        plannedDate,
      });

      return currentTrainingChanges;
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingPatchDeleteWorkout(
    selectedAthlete,
    plannedDate,
    workoutId,
    planId = 0,
  ) {
    let { cognito_user_sub, currentTraining } = selectedAthlete;

    if (cognito_user_sub && workoutId && plannedDate) {
      const currentTrainingChanges = deleteWorkout(
        currentTraining,
        workoutId,
        plannedDate,
      );
      return currentTrainingChanges;
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingPatchAddWeek(
    selectedAthlete,
    fromPlannedDate,
    planId,
  ) {
    let { cognito_user_sub, currentTraining } = selectedAthlete;

    if (cognito_user_sub && fromPlannedDate) {
      const currentTrainingChanges = addWeek(currentTraining, fromPlannedDate);

      this.athleteTrainingEndDatePatch(
        cognito_user_sub,
        currentTrainingChanges.currentTrainingEndDate,
      );

      return currentTrainingChanges;
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingPatchAddEasyWeek(
    selectedAthlete,
    fromPlannedDate,
    raceCount,
    trainPerWeek,
    planId = 0,
  ) {
    let { cognito_user_sub, currentTraining } = selectedAthlete;
    if (fromPlannedDate && raceCount && trainPerWeek) {
      if (cognito_user_sub) {
        const trainingPlanId = `free-12-Flat-${raceCount}-${trainPerWeek}-9999999999`;

        try {
          const searchResult = await TrainingsService.readTraining({
            id: trainingPlanId,
            training_order: 1,
          });
          if (searchResult) {
            const currentTrainingChanges = addEasyWeek(currentTraining, {
              startDate: fromPlannedDate,
              days: searchResult.days,
            });

            this.athleteTrainingEndDatePatch(
              cognito_user_sub,
              currentTrainingChanges.currentTrainingEndDate,
            );

            return currentTrainingChanges;
          } else {
            throw new Error("Easy Week not found");
          }
        } catch (e) {
          return undefined;
        }
      } else {
        throw new Error("Invalid Keys");
      }
    } else throw new Error("INVALID INPUT");
  }

  static async athleteTrainingPatchAddBlock(
    selectedAthlete,
    fromPlannedDate,
    plan,
    planWeek = 0,
    planId = 0,
  ) {
    let { cognito_user_sub, currentTraining } = selectedAthlete;
    if (cognito_user_sub && fromPlannedDate && plan) {
      const searchResult = await TrainingsService.readTraining({
        id: plan.id,
        training_order: plan.training_order,
      });

      if (searchResult) {
        const currentTrainingChanges = addBlockWeek(currentTraining, {
          startDate: fromPlannedDate,
          days: searchResult.days.slice(planWeek * 7, planWeek * 7 + 7),
          plan: searchResult,
        });

        this.athleteTrainingEndDatePatch(
          cognito_user_sub,
          currentTrainingChanges.currentTrainingEndDate,
        );

        return currentTrainingChanges;
      } else {
        throw new Error("Easy Week not found");
      }
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingPatchRemoveWeek(
    selectedAthlete,
    fromPlannedDate,
    planId = 0,
  ) {
    let { cognito_user_sub, currentTraining } = selectedAthlete;

    if (cognito_user_sub && fromPlannedDate) {
      const currentTrainingChanges = removeWeek(
        currentTraining,
        fromPlannedDate,
      );

      this.athleteTrainingEndDatePatch(
        cognito_user_sub,
        currentTrainingChanges.currentTrainingEndDate,
      );

      return currentTrainingChanges;
    } else throw new Error("Invalid Keys");
  }

  static async athleteTrainingPatchRemoveChainedPlan(
    selectedAthlete,
    planIndex = 0,
  ) {
    let { cognito_user_sub, currentTraining } = selectedAthlete;

    if (cognito_user_sub) {
      const currentTrainingChanges = removeChained(currentTraining, planIndex);
      this.athleteTrainingEndDatePatch(
        cognito_user_sub,
        currentTrainingChanges.currentTrainingEndDate,
      );

      const current = currentTrainingChanges.currentTraining;

      let myInit = {
        body: {
          trainingPlan: current,
        },
      };

      const response = await API.put(
        this.API_NAME,
        this.ATHLETES_UPDATE_CHAIN_TRAINING_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
        myInit,
      );

      return currentTrainingChanges;
    } else throw new Error("Invalid Keys");
  }

  static async getAthleteActivities(params) {
    if (params && params.oauth_token) {
      const result = await GarminService.getActivities(params);
      if (result && result.length > 0) return result;
      else return undefined;
    } else if (params && params.access_token) {
      const result = await SuuntoService.getActivities(params);
      if (result && result.length > 0)
        return standarizeActivities(result, "suunto");
      else return undefined;
    } else if (params && params.coros_auth) {
      const result = await CorosService.getActivities(params);
      if (result && result.length > 0)
        return standarizeActivities(result, "coros");
      else return undefined;
    } else if (params && params.strava_athlete_id) {
      const result = await StravaService.getActivities(params);
      if (result && result.length > 0) return result;
      else return undefined;
    } else if (params) {
      const result = await VertActivityService.getActivities(params);
      if (result && result.length > 0) return result;
      else return undefined;
    }
  }

  static async changeCheckStatus(sub, status) {
    try {
      const response = await API.patch(
        this.API_NAME,
        `${this.ATHLETES_SEARCH_ADMIN_PATH}/${sub}/checked`,
        { body: { value: status } },
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async changeNeedToCheckStatus(sub, status) {
    try {
      const response = await API.patch(
        this.API_NAME,
        `${this.ATHLETES_SEARCH_ADMIN_PATH}/${sub}/needscheck`,
        { body: { value: status } },
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async changeNeedsHelpStatus(sub, status) {
    try {
      const response = await API.patch(
        this.API_NAME,
        `${this.ATHLETES_SEARCH_ADMIN_PATH}/${sub}/needshelp`,
        { body: { value: status } },
      );
      const { result, error } = response;

      if (error) throw new Error(error);
      return result;
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async saveCurrentTrainingS3(currentTraining, cognito_user_sub) {
    await Storage.put(`latest`, JSON.stringify(currentTraining), {
      level: "protected",
      identityId: cognito_user_sub,
      acl: "authenticated-read",
      bucket: process.env.REACT_APP_PLANS_BUCKET,
    });
  }

  static async checkIfModified(currentTraining, cognito_user_sub) {
    if (cognito_user_sub) {
      try {
        const downloadedData = await Storage.get(`latest`, {
          level: "protected",
          identityId: cognito_user_sub,
          download: true,
          bucket: process.env.REACT_APP_PLANS_BUCKET,
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });

        const data = await downloadedData.Body.text();

        const currentTrainingS3 = JSON.parse(data);

        if (
          currentTrainingS3.lastModified &&
          (currentTrainingS3.lastModified > currentTraining.lastModified ||
            !currentTraining.lastModified)
        ) {
          return currentTrainingS3;
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }

  static async getSignUpMethod(user_email) {
    if (user_email) {
      const response = await API.get(
        this.API_NAME,
        this.ATHLETES_GET_SIGNUP_METHOD_PATH.replace(":user_email", user_email),
      );
      const { success, result, error } = response;
      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async getAthleteData(cognito_user_sub) {
    const response = await API.get(
      this.API_NAME,
      this.ATHLETES_GET_ATHLETE_DETAILS.replace(
        ":cognito_user_sub",
        cognito_user_sub,
      ),
    );
    const { success, result, error } = response;
    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }
  static async getPaginatedPlan(fromDate, toDate, cognito_user_sub) {
    const response = await API.get(
      this.API_NAME,
      `${this.ATHLETES_PAGINATED_PLAN}/${fromDate}/${toDate}`.replace(
        ":cognito_user_sub",
        cognito_user_sub,
      ),
    );
    const { success, result, error } = response;
    if (success) return result;
    if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }
}

export default AthletesService;
