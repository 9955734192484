import React, { Component } from "react";
import { TextField, withStyles, Button, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

import Plans from "../../constants/plans";
import Distances from "../../constants/distances";
import TrainingDurationInWeeks from "../../constants/training-duration-in-weeks";
import Surfaces from "../../constants/surfaces";
import RaceCounts from "../../constants/race-counts";
import TrainingPerWeek from "../../constants/training-per-week";
import SkillsToImprove from "../../constants/skills-to-improve";
import FilterSelect from "../common/filter-select";
import AutoCompleteSelect from "../common/auto-complete-select";
import SubcategoriesFilterSelect from "./subcategories-filter-select";
import { csvToJson } from "../../lib/csvtojson-helper";
import Papa from "papaparse";

const styles = (theme) => ({
  athletes: {
    minHeight: 100,
    paddingTop: theme.spacing.unit * 2,
    margin: theme.spacing.unit,
  },
  fieldset: {
    border: 0,
  },
  leftMargin: {
    marginLeft: theme.spacing.unit,
  },
  import: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
  importButton: {
    marginRight: 10,
  },
});

class TrainingEditorForm extends Component {
  state = {
    days: [],
    daysLoaded: false,
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      //Enter
      event.preventDefault();
      const { onAthletesSearch } = this.props;
      const { searchPhrase } = this.state;
      if (onAthletesSearch) onAthletesSearch(searchPhrase);
    }
  };

  handleSearchPhraseChange = (searchPhrase) => {
    this.setState({
      ...this.state,
      searchPhrase: searchPhrase,
    });
  };

  handleInputChange = async (event) => {
    this.setState({
      ...this.state,
      daysLoaded: false,
    });

    if (event) {
      const { target } = event;

      if (target) {
        const { files } = target;

        if (files) {
          const selectedFile = files[0];

          if (selectedFile) {
            const reader = new FileReader();

            reader.addEventListener("load", (e) => {
              const csvdata = e.target.result;

              const parsed = Papa.parse(csvdata, { header: true });
              const json = parsed.data;

              const { onSelect } = this.props;
              onSelect({ name: "days", value: json });

              this.setState({
                ...this.state,
                days: json,
                daysLoaded: true,
              });
            });

            reader.readAsText(selectedFile);
          }
        }
      }
    }
  };

  render() {
    const {
      classes,
      formId,
      saving,
      loading,
      training,
      onTextChange,
      onSelect,
      onSubmit,
      premiumAthletesList,
      athletesLoading,
      onAthleteSelect,
      subcategories,
    } = this.props;

    const {
      title = "",
      training_order = "",
      selectedPlan,
      durationInWeeks,
      distance,
      surface,
      raceCount,
      trainPerWeek,
      skillToImprove,
      athlete_cognito_user_sub,
      subcategory,
    } = training;
    const premiumAthleteValue = athlete_cognito_user_sub
      ? premiumAthletesList.find(
          (athlete) => athlete.value === athlete_cognito_user_sub,
        )
      : null;

    const isPlanFreeSelected = selectedPlan === Plans.Items.FREE;
    const isPlanPremiumSelected = selectedPlan === Plans.Items.PREMIUM;

    const raceCountsItemsArray = RaceCounts.itemsArray;
    const raceCountItems = [
      {
        text: "Level auto adjust - with parametrized workouts",
        value: `auto`,
      },
      {
        text: "Level 1",
        value: `${raceCountsItemsArray[0].value},${raceCountsItemsArray[1].value}`,
      },
      {
        text: "Level 2",
        value: `${raceCountsItemsArray[2].value},${raceCountsItemsArray[3].value}`,
      },
      {
        text: "Level 3",
        value: `${raceCountsItemsArray[4].value},${raceCountsItemsArray[5].value}`,
      },
    ];
    const trainingPerWeekItemsArray = TrainingPerWeek.itemsArray;
    const TrainingPerWeekItems = [
      { text: "3 Days", value: trainingPerWeekItemsArray[0].value },
      {
        text: "5 Days",
        value: `${trainingPerWeekItemsArray[1].value},${trainingPerWeekItemsArray[2].value}`,
      },
      {
        text: "6 Days",
        value: `${trainingPerWeekItemsArray[3].value},${trainingPerWeekItemsArray[4].value}`,
      },
    ];
    const SurfacesItemsArray = Surfaces.itemsArray;
    const surfaceItems = [
      { text: SurfacesItemsArray[0].text, value: SurfacesItemsArray[0].value },
      { text: SurfacesItemsArray[1].text, value: SurfacesItemsArray[1].value },
    ];

    const isExploreOrFreePlanSelected =
      selectedPlan === Plans.Items.EXPLORE || selectedPlan === Plans.Items.FREE;

    return (
      <form id={formId} onSubmit={onSubmit}>
        <fieldset disabled={saving || loading} className={classes.fieldset}>
          <TextField
            className={classes.leftMargin}
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            value={title}
            onChange={onTextChange}
            fullWidth
            required
          />
          <TextField
            className={classes.leftMargin}
            margin="dense"
            name="training_order"
            label="Priority"
            placeholder="AUTO"
            value={training_order}
            onChange={onTextChange}
          />
          <FilterSelect
            value={selectedPlan}
            name="selectedPlan"
            text="Plan"
            items={Plans.itemsArray}
            onSelect={onSelect}
            required
            small
          />
          <SubcategoriesFilterSelect
            filterSelectName="subcategory"
            filterSelectText="Sub Category"
            onSelect={onSelect}
            isExploreOrFreePlanSelected={isExploreOrFreePlanSelected}
            subcategories={subcategories}
            value={subcategory}
            hasGeneral
            required
            small
          />
          <FilterSelect
            value={durationInWeeks}
            name="durationInWeeks"
            text="Duration"
            items={TrainingDurationInWeeks.itemsArray}
            onSelect={onSelect}
            required
            small
          />
          {selectedPlan && !isPlanPremiumSelected && (
            <FilterSelect
              value={distance}
              name="distance"
              text="Distance"
              items={Distances.itemsArray}
              onSelect={onSelect}
              required
              small
            />
          )}
          <FilterSelect
            value={surface}
            name="surface"
            text="Surface"
            items={surfaceItems}
            onSelect={onSelect}
            required
            small
          />
          <FilterSelect
            value={raceCount}
            name="raceCount"
            text="Level"
            items={raceCountItems}
            onSelect={onSelect}
            required
            small
          />
          <FilterSelect
            value={trainPerWeek}
            name="trainPerWeek"
            text="Days"
            items={TrainingPerWeekItems}
            onSelect={onSelect}
            required
            small
          />
          {!selectedPlan || isPlanFreeSelected || (
            <FilterSelect
              value={skillToImprove}
              name="skillToImprove"
              text="Skill"
              items={SkillsToImprove.itemsArray}
              onSelect={onSelect}
              required
              small
            />
          )}
          {isPlanPremiumSelected && (
            <div className={classes.athletes}>
              <AutoCompleteSelect
                suggestions={premiumAthletesList}
                placeholder="Premium athletes"
                isClearable={true}
                value={premiumAthleteValue}
                disable={athletesLoading}
                loading={athletesLoading}
                onChange={onAthleteSelect}
                onInputChange={this.handleSearchPhraseChange}
                onKeyDown={this.handleKeyDown}
              />
            </div>
          )}

          <div className={classes.import}>
            <Button
              variant="contained"
              component="label"
              className={classes.importButton}
            >
              Import workouts from CSV
              <input
                type="file"
                accept=".csv"
                hidden
                onChange={this.handleInputChange}
              />
            </Button>

            {this.state.daysLoaded ? (
              <Typography variant="body1">
                {`Loaded ${this.state.days.filter((d) => !!d).length} workouts`}
              </Typography>
            ) : (
              ""
            )}
          </div>
        </fieldset>
      </form>
    );
  }
}

TrainingEditorForm.propTypes = {
  classes: PropTypes.object.isRequired,
  formId: PropTypes.string,
  saving: PropTypes.bool,
  training: PropTypes.object,
  onTextChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  premiumAthletesList: PropTypes.array,
  athletesLoading: PropTypes.bool,
  onAthleteSelect: PropTypes.func,
  AthletesSearch: PropTypes.func,
  subcategories: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TrainingEditorForm);
