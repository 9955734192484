import React from "react";
import { SvgIcon } from "@material-ui/core";
import { palette } from "../../theme/palette";
const LinkIcon = ({ width = 24, height = 24, activated = false }) => (
  <>
    <SvgIcon>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill={activated ? palette.primary : "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.172 14.829L14.829 9.17196M7.05 11.293L5.636 12.707C5.26456 13.0784 4.96991 13.5194 4.76889 14.0047C4.56786 14.49 4.4644 15.0102 4.4644 15.5355C4.4644 16.0608 4.56786 16.5809 4.76889 17.0662C4.96991 17.5515 5.26456 17.9925 5.636 18.364C6.00744 18.7354 6.44841 19.03 6.93373 19.2311C7.41904 19.4321 7.9392 19.5356 8.4645 19.5356C8.9898 19.5356 9.50996 19.4321 9.99527 19.2311C10.4806 19.03 10.9216 18.7354 11.293 18.364L12.705 16.95M11.292 7.04996L12.706 5.63596C13.0774 5.26451 13.5184 4.96987 14.0037 4.76885C14.489 4.56782 15.0092 4.46436 15.5345 4.46436C16.0598 4.46436 16.58 4.56782 17.0653 4.76885C17.5506 4.96987 17.9916 5.26451 18.363 5.63596C18.7344 6.0074 19.0291 6.44837 19.2301 6.93369C19.4311 7.419 19.5346 7.93916 19.5346 8.46446C19.5346 8.98976 19.4311 9.50992 19.2301 9.99523C19.0291 10.4805 18.7344 10.9215 18.363 11.293L16.949 12.707"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={activated ? palette.primary : palette.darkGray}
        />
      </svg>
    </SvgIcon>
  </>
);
export default LinkIcon;
