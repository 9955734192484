import React, { createContext, useContext, useState } from "react";
const TrainingContext = createContext();

const TrainingProvider = ({ children }) => {
  const [isChangeDate, setIsChangeDate] = useState(true);
  const [direction, setDirection] = useState("left");
  const [date, setDate] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  const onSetDirection = (direction) => {
    return direction ? setDirection("right") : setDirection("left");
  };

  const onChangeDate = (newDate) => {
    setIsChangeDate(false);
    setDate(newDate);
    setTimeout(() => {
      setIsChangeDate(true);
    }, 300);
  };

  const setWeekRange = (from, to) => {
    setDateFrom(from);
    setDateTo(to);
  };

  return (
    <TrainingContext.Provider
      value={{
        direction,
        isChangeDate,
        date,
        dateFrom,
        dateTo,
        setWeekRange,
        onChangeDate,
        onSetDirection,
      }}
    >
      {children}
    </TrainingContext.Provider>
  );
};

const useTrainingContext = () => {
  const context = useContext(TrainingContext);
  if (!context) {
    throw new Error(
      "useTrainingContext must be used within a TrainingProvider",
    );
  }
  return context;
};

export { TrainingContext, TrainingProvider, useTrainingContext };
