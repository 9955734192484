import localforage from "localforage";

export class LocalForageStorage {
  syncPromise = null;
  memoryStore = {};
  asyncStore = localforage.createInstance({
    name: "amplifyStore",
  });

  setItem(key, value) {
    this.asyncStore.setItem(key, value);
    this.memoryStore[key] = value;
    return this.memoryStore[key];
  }

  getItem(key) {
    return Object.prototype.hasOwnProperty.call(this.memoryStore, key)
      ? this.memoryStore[key]
      : undefined;
  }

  removeItem(key) {
    this.asyncStore.removeItem(key);
    return delete this.memoryStore[key];
  }

  clear() {
    this.asyncStore.clear();
    return {};
  }

  sync() {
    if (!this.syncPromise) {
      this.syncPromise = this.asyncStore.iterate((val, key) => {
        this.memoryStore[key] = val;
      });
    }
    return this.syncPromise;
  }
}
