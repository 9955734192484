import { API } from "aws-amplify";

import workoutPlans from "../constants/workout-plans";

class SubcategoryService {
  static API_NAME = "apisubcategories";
  static GET_SUBCATEGORY_PATH = (plan) => `/subcategories/${plan}`;
  static GET_SUBCATEGORY_SAMPLE_PATH = `/subcategoriessampleweek`;
  static POST_CONTACT = "/subcategories/email";
  static POST_RESERVE_SPOT = "/subcategories/reservespot";

  static async getSubcategories(getParams) {
    const responseFree = await API.get(
      this.API_NAME,
      this.GET_SUBCATEGORY_PATH(workoutPlans.FREE),
      {
        queryStringParameters: getParams,
      },
    );

    const responseExplore = await API.get(
      this.API_NAME,
      this.GET_SUBCATEGORY_PATH(workoutPlans.EXPLORE),
      {
        queryStringParameters: getParams,
      },
    );

    const {
      success: successFree,
      result: resultFree,
      error: errorFree,
    } = responseFree;
    const {
      success: successExplore,
      result: resultExplore,
      error: errorExplore,
    } = responseExplore;
    if (successFree && successExplore) {
      return [...resultFree, ...resultExplore];
    } else {
      throw new Error(errorFree || errorExplore || "Server Error");
    }
  }

  static async getSampleWeek(getParams) {
    const response = await API.get(
      this.API_NAME,
      this.GET_SUBCATEGORY_SAMPLE_PATH,
      {
        queryStringParameters: getParams,
      },
    );

    const { success: success, result: result, error: error } = response;

    if (success) {
      return result;
    } else {
      throw new Error(error || "Server Error");
    }
  }

  static async postContact(contactData) {
    const data = {
      body: contactData,
    };

    const response = await API.post(this.API_NAME, this.POST_CONTACT, data);

    const { success, error } = response;

    if (success) return response;
    if (error) {
      if (typeof error === "string") throw new Error(error);
      throw error;
    }
  }

  static async postReserveSpot(reserveData) {
    const data = {
      body: reserveData,
    };

    const response = await API.post(
      this.API_NAME,
      this.POST_RESERVE_SPOT,
      data,
    );
    const { success, error } = response;

    if (success) return response;
    if (error) {
      if (typeof error === "string") throw new Error(error);
      throw error;
    }
  }
}

export default SubcategoryService;
