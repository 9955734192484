import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SwapVertIcon from '@material-ui/icons/SwapVert';

class EnhancedDeleteIcon extends Component {
    render() {
        const { saving, swapIdRoute } = this.props;
        return (
            saving ? <Tooltip title="Swap">
                <IconButton aria-label="Swap">
                    <CircularProgress color="primary" size={25} />
                </IconButton>
            </Tooltip>
                : <Link to={`/trainings/swap${swapIdRoute}`}>
                    <Tooltip title="Swap">
                        <IconButton aria-label="Swap">
                            <SwapVertIcon />
                        </IconButton>
                    </Tooltip>
                </Link>
        );
    }
}

EnhancedDeleteIcon.propTypes = {
    deleting: PropTypes.bool,
    linkTo: PropTypes.string
}

export default EnhancedDeleteIcon;