import ActionTypes from "../constants/action-types";
import {
  getCurrentPlan,
  updateLastLogin,
  updatePlan,
} from "./user-plans-action";
import { unsubscribe } from "./pusher-action";
import AppNotificationsService from "../services/app-notifications-service";
import { Capacitor } from "@capacitor/core";
import InAppPurchasesService from "../services/in-app-purchases-service";
import LocalNotificationsService from "../services/local-notifications-service";
import history from "../lib/history-helper";
import UserPlansService from "../services/user-plans-service";
import { storageFactory } from "../lib/storage-factory";

const currentUserLoad = (authenticationPromise, redirect = false) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.AUTH_CURRENT_USER_PENDING });
    try {
      const user = await authenticationPromise;
      await dispatch({
        type: ActionTypes.AUTH_CURRENT_USER_FULFILLED,
        payload: user,
        redirect,
      });

      await dispatch(
        getCurrentPlan(true, {
          cognito_user_sub: user.attributes.sub,
          cognito_user_email: user.attributes.email,
          cognito_user_name: user.attributes.name,
        }),
      );

      if (user && user.attributes && user.attributes.sub) {
        let change = {
          cognito_user_sub: user.attributes.sub,
          lastLogin: new Date().getTime(),
          cognito_user_email: user.attributes.email,
          cognito_user_name: user.attributes.name,
          challengesUser: localStorage.getItem("challengesUser"),
        };
        if (storageFactory().getItem("currentGoal")) {
          change = {
            ...change,
            userType: +storageFactory().getItem("currentGoal"),
            exploreUser: +storageFactory().getItem("currentGoal") === 4,
            trailheadUser: +storageFactory().getItem("currentGoal") === 5,
          };
          storageFactory().removeItem("currentGoal");
        }
        await dispatch(updatePlan(change, false, false));
        if (Capacitor.isNativePlatform()) {
          AppNotificationsService.initAppNotifications(user.attributes.sub);
          await InAppPurchasesService.initInAppPurchases(
            user.attributes.sub,
            dispatch,
          );
          LocalNotificationsService.listenToNotifications();
        }
      }
    } catch (exp) {
      await dispatch({
        type: ActionTypes.AUTH_CURRENT_USER_REJECTED,
        payload: exp,
        error: true,
      });

      if (exp === "The user is not authenticated") {
        const now = new Date();
        UserPlansService.sendEvent({
          id: now.getTime() + "unauth",
          date: now.getTime(),
          cognito_user_sub: "unauth",
          event: "unauth_visit",
          data: {
            device: Capacitor.isNativePlatform
              ? Capacitor.getPlatform()
              : "web",
          },
        });
      }
    }
  };
};

const currentUserSilentLoad = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.AUTH_CURRENT_USER_SILENT_PENDING });
    try {
      if (Capacitor.isNativePlatform()) {
        InAppPurchasesService.refresh();
      }
      await dispatch(getCurrentPlan(false));
    } catch (exp) {
      dispatch({
        type: ActionTypes.AUTH_CURRENT_USER_SILENT_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const updateAuthAttributes = (attributes) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.AUTH_UPDATE_ATTRIBUTES, payload: attributes });
  };
};

const setCurrentUser = (user) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.AUTH_CURRENT_USER_FULFILLED, payload: user });
  };
};

const setShowUpate = (user) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.AUTH_SHOW_UPDATE_FULFILLED, payload: user });
  };
};

const setCurrentUserSession = (signInSession) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.AUTH_CURRENT_USER_SESSION_CHANGED,
      payload: signInSession,
    });
  };
};

const currentUserSignOut = (signOutPromise, user_sub) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.AUTH_CURRENT_USER_SIGNOUT_PENDING });
    try {
      const result = await signOutPromise;
      dispatch({
        type: ActionTypes.AUTH_CURRENT_USER_SIGNOUT_FULFILLED,
        payload: result,
      });
      dispatch(unsubscribe(user_sub));
      history.push("/auth");
    } catch (exp) {
      dispatch({
        type: ActionTypes.AUTH_CURRENT_USER_SIGNOUT_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const setGeneralError = (err) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.AUTH_USER_SET_ERROR, payload: err });
  };
};

const clearGeneralError = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.AUTH_USER_CLEAR_ERROR });
  };
};

const setAuthPage = (page) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.AUTH_USER_SET_PAGE, payload: page });
  };
};

export {
  currentUserLoad,
  currentUserSilentLoad,
  currentUserSignOut,
  setCurrentUser,
  setCurrentUserSession,
  setGeneralError,
  clearGeneralError,
  setAuthPage,
  setShowUpate,
  updateAuthAttributes,
};
