import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FlexContainer from './flex-container';

const styles = theme => ({
    container: {
        width:'100%',
        opacity: 0.8
    }
})

const MenuList = (props) => {
    const { classes, className, ...rest } = props;
    return <FlexContainer padding={0} direction="column" className={classNames(classes.container, className)} {...rest}>
        {props.children}
    </FlexContainer>
};

MenuList.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(MenuList);