import { API } from "aws-amplify";
import AthletesService from "./athletes-service";

class TrainingsService {
  static API_NAME = "apitrainingplans";
  static TRAINING_READ_PATH = "/trainingplans/:id/:training_order";
  static TRAINING_SAVE_PATH = "/trainingplans";
  static TRAINING_SWAP_PATH =
    "/trainingplans/:id/:training_order1/:training_order2";
  static TRAINING_PATCH_PATH = "/trainingplans/:id";
  static TRAINING_SEARCH_PATH = "/trainingplans";
  static TRAINING_DELETE_PATH = "/trainingplans/:ids/:training_orders";

  static async searchTrainings(searchParams, includeDays) {
    let myInit = {
      // OPTIONAL
      queryStringParameters: includeDays
        ? { ...searchParams, includeDays: true }
        : searchParams,
    };
    const response = await API.get(
      this.API_NAME,
      this.TRAINING_SEARCH_PATH,
      myInit,
    );
    const { success, result, error } = response;

    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async readTraining(keys) {
    const { id, training_order } = keys;
    if (id && training_order) {
      const response = await API.get(
        this.API_NAME,
        this.TRAINING_READ_PATH.replace(":id", id).replace(
          ":training_order",
          training_order,
        ),
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async saveTraining(training) {
    let myInit = {
      // OPTIONAL
      body: training,
    };
    const response = await API.put(
      this.API_NAME,
      this.TRAINING_SAVE_PATH,
      myInit,
    );
    const { success, result, error } = response;
    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async swapTraining(id, training_order1, training_order2) {
    const response = await API.patch(
      this.API_NAME,
      this.TRAINING_SWAP_PATH.replace(":id", id)
        .replace(":training_order1", training_order1)
        .replace(":training_order2", training_order2),
    );
    const { success, result, error } = response;
    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async patchTraining(id, keyValues) {
    const myInit = {
      body: { ...keyValues },
    };
    const response = await API.patch(
      this.API_NAME,
      this.TRAINING_PATCH_PATH.replace(":id", id),
      myInit,
    );
    const { success, result, error } = response;
    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async createTraining(training) {
    let myInit = {
      // OPTIONAL
      body: training,
    };

    const response = await API.post(
      this.API_NAME,
      this.TRAINING_SAVE_PATH,
      myInit,
    );
    const { success, result, error } = response;
    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async deleteBulkTraining(keys) {
    if (keys) {
      const response = await API.del(
        this.API_NAME,
        this.TRAINING_DELETE_PATH.replace(
          ":ids",
          keys.map((key) => key.id).join(";"),
        ).replace(
          ":training_orders",
          keys.map((key) => key.training_order).join(";"),
        ),
      );
      const { success, result, error } = response;

      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("INVALID INPUT");
  }

  static async athletesSearch(searchParams, isAdmin, sub) {
    const { selectedPlan, searchPhrase } = searchParams;
    if (selectedPlan && searchPhrase) {
      const result = await AthletesService.athletesSearch(
        searchParams,
        isAdmin,
        sub,
      );
      if (result && result.length > 0) return result;
    }
    return undefined;
  }

  static async patchAnExistingTrainingAsDuplicate(params) {
    const result = await this.readTraining(params);
    return {
      ...result,
      title: result.title && `${result.title} - duplicate`,
      title_lower: undefined,
      id: undefined,
      training_order: undefined,
    };
  }
}

export default TrainingsService;
