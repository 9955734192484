import React from "react";
import moment from "moment";
import {
  Box,
  ButtonBase,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import ModalCustom from "./ModalCustom";
import CustomButton from "./CustomButton";
import { palette } from "../../../theme/palette";
import { useTranslation } from "react-i18next";
import { dateFormat, getDay } from "../../../lib/date-helper";
import HighlightText from "./HighlightText";
import { getPlan } from "../../../lib/current-training-helper";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { updatePlanRedoWeek } from "../../../actions/user-plans-action";
import PaywallModal from "./PaywallModal";

const RedoWeek = ({
  currentPlan = {},
  currentTraining = {},
  chainedPlans = [],
  isSubscribed = false,
  fromDate,
  toDate,
  disabled = false,
}) => {
  const classes = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation("training");
  const dispatch = useDispatch();
  const [isOpenPaywall, setIsOpenPaywall] = React.useState(false);
  const [isRedo, setIsRedo] = React.useState(false);
  const [redoValue, setRedoValue] = React.useState(fromDate.toString());

  const onCloseRedo = () => setIsRedo(false);

  const openRedo = () => {
    if (isSubscribed) {
      setIsRedo(true);
    } else {
      setIsOpenPaywall(true);
    }
  };

  const onChangeRedoValue = (event) => {
    setRedoValue(event.target.value);
  };

  const onRedoWeek = () => {
    if (!fromDate) return false;

    const plan = getPlan(
      [
        currentPlan,
        ...chainedPlans.map((c, index) => {
          return {
            updated_at: currentPlan.chainedPlans[index],
            currentTraining: c,
          };
        }),
      ],
      moment(fromDate),
    );

    if (!plan) return false;

    dispatch(
      updatePlanRedoWeek(
        {
          cognito_user_sub: currentPlan.cognito_user_sub,
          startDate: dayjs(fromDate).format(dateFormat),
          toDate: dayjs(redoValue)
            .add(1, "day")
            .format(dateFormat),
          lastModified: plan.lastModified,
        },
        currentTraining,
        plan.updated_at === "latest" ? 0 : plan.updated_at,
        false,
      ),
    );

    onCloseRedo();
  };

  return (
    <>
      <PaywallModal
        isOpen={isOpenPaywall}
        onClose={() => setIsOpenPaywall(false)}
      />

      <ButtonBase
        className={classes.cardButton}
        onClick={openRedo}
        disabled={disabled}
      >
        <SvgIcon style={{ fontSize: 16 }} viewBox="0 0 18 18">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3574 0.314488C9.58737 -0.166107 7.71279 -0.0944791 5.98462 0.51978C4.25645 1.13404 2.75718 2.26161 1.68757 3.75151V1.96831C1.68757 1.74452 1.59868 1.5299 1.44043 1.37166C1.28219 1.21342 1.06757 1.12452 0.843787 1.12452C0.620001 1.12452 0.40538 1.21342 0.247139 1.37166C0.0888987 1.5299 0 1.74452 0 1.96831L0 6.74977H4.78146C5.00525 6.74977 5.21987 6.66087 5.37811 6.50263C5.53635 6.34439 5.62525 6.12977 5.62525 5.90598C5.62525 5.6822 5.53635 5.46758 5.37811 5.30934C5.21987 5.15109 5.00525 5.0622 4.78146 5.0622H2.8385C3.71979 3.68344 5.03804 2.64009 6.58237 2.09908C8.1267 1.55806 9.80786 1.55064 11.3569 2.078C12.9059 2.60536 14.2334 3.63702 15.1268 5.00794C16.0202 6.37887 16.428 8.00984 16.285 9.63992C16.1419 11.27 15.4563 12.805 14.3377 13.9994C13.2192 15.1938 11.7324 15.9785 10.1152 16.228C8.49796 16.4775 6.84379 16.1774 5.41729 15.3756C3.99079 14.5739 2.87441 13.3169 2.24672 11.8057C2.1576 11.6041 1.99308 11.4453 1.78838 11.3634C1.58367 11.2815 1.35504 11.283 1.15141 11.3675C0.947776 11.4521 0.785324 11.6129 0.698816 11.8157C0.612307 12.0185 0.608616 12.2472 0.68853 12.4526C1.2749 13.8641 2.21102 15.1029 3.40867 16.0524C4.60632 17.0019 6.02603 17.6308 7.53398 17.8798C9.04194 18.1287 10.5885 17.9896 12.0278 17.4755C13.4671 16.9614 14.7517 16.0892 15.7607 14.9412C16.7696 13.7932 17.4696 12.4072 17.7947 10.9138C18.1197 9.42035 18.0591 7.86877 17.6186 6.40527C17.178 4.94177 16.372 3.61456 15.2766 2.54875C14.1812 1.48294 12.8324 0.714766 11.3574 0.314488Z"
            fill="#12151B"
          />
        </SvgIcon>
        <Typography className={classes.cardTextButton}>
          {t("training.redoWeek")}
        </Typography>
      </ButtonBase>

      {/** Redo modal  */}
      <ModalCustom
        fullScreen={false}
        open={isRedo}
        onClose={onCloseRedo}
        toolbarJustifyContent="end"
        headerStyles={{
          padding: "8px 5px !important",
        }}
        contentStyles={{
          padding: "8px 20px 10px",
          gap: 8,
        }}
      >
        <Grid container style={{ gap: "6px 0" }}>
          <Grid item xs={12}>
            <Typography className={classes.modalTitle}>
              {t("training.redoWeek.modalTitle.p1")}{" "}
              <HighlightText
                color={palette.primary}
                text={t("training.redoWeek.modalTitle.redo")}
              />{" "}
              {t("training.redoWeek.modalTitle.p2")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.modalDescription}>
              {t("training.redoWeek.modalDescription.p1")}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 5 }}>
            <Typography className={classes.modalTitle}>
              {t("training.redoWeek.startOn")}
            </Typography>
            <FormControl style={{ width: "100%" }} component="fieldset">
              <RadioGroup
                style={{ justifyContent: "space-between" }}
                row
                aria-label="redo"
                name="redo"
                value={redoValue.toString()}
                onChange={onChangeRedoValue}
                defaultValue={fromDate.toString()}
              >
                <FormControlLabel
                  value={fromDate.toString()}
                  control={<Radio color="primary" />}
                  label={getDay(fromDate, language)}
                />
                <FormControlLabel
                  value={dayjs(toDate)
                    .add(1, "day")
                    .toString()}
                  control={<Radio color="primary" />}
                  label={getDay(
                    new Date(dayjs(toDate).add(1, "day")),
                    language,
                  )}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Box py={2}>
              <CustomButton
                color={"default"}
                typeStyle={"secondary"}
                variant="contained"
                size="large"
                onClick={onCloseRedo}
                fullWidth
              >
                {t("training.modalButtons.cancel")}
              </CustomButton>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box py={2} pl={1}>
              <CustomButton
                color={"secondary"}
                typeStyle={"primary"}
                variant="contained"
                size="large"
                onClick={onRedoWeek}
                fullWidth
              >
                {t("training.modalButtons.redo")}
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </ModalCustom>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 700,
    textAlign: "left",
    color: palette.black,
  },
  modalDescription: {
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",
    color: palette.black,
  },
  weekContainer: {
    padding: theme.spacing(2),
  },
  dayColumn: {
    width: "100%",
    marginBottom: 5,
    fontFamily: "Karla",
    fontWeight: 300,
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
    padding: "0 !important",
  },
  cardButton: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "120px",
    padding: "16px 12px",
    gap: 12,
    borderRadius: 4,
    boxShadow: palette.boxShadow,
    "&:hover": {
      backgroundColor: "#F1F6FD",
    },
  },
  cardTextButton: {
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
  },
  marginY: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

export default RedoWeek;
