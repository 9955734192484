import React from "react";
import { Stack, Chip, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

const CommunityTagsTab = ({ tags, onClick, selectedTags }) => {
  const { t, i18n } = useTranslation();
  const handleTagClick = (tag) => {
    onClick(tag);
  };

  if (tags.length === 0) {
    return (
      <Stack
        spacing={1}
        direction="row"
        useFlexGap
        flexWrap="wrap"
        sx={{
          m: 1,
          overflowX: "auto",
          display: "flex",
          maxWidth: "100%",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={60}
          height={32}
          borderRadius={"4px"}
        />
        <Skeleton
          variant="rectangular"
          width={60}
          height={32}
          borderRadius={"4px"}
        />
        <Skeleton
          variant="rectangular"
          width={60}
          height={32}
          borderRadius={"4px"}
        />
      </Stack>
    );
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      sx={{
        m: 1,
        overflowX: "auto",
        display: "flex",
        maxWidth: "100%",
      }}
    >
      {tags.map((tag) => (
        <Chip
          key={tag.id}
          size="medium"
          variant="filled"
          style={{
            fontFamily: "Karla",
            fontWeight: 400,
          }}
          label={t(tag.name[i18n.language] || tag.name.en)}
          onClick={() => handleTagClick(tag)}
          sx={{
            fontWeight: 400,
            borderRadius: "4px",
            bgcolor: selectedTags.some(
              (selectedTag) => selectedTag.id === tag.id,
            )
              ? "#4780AB"
              : "#F1F6FD",
            color: selectedTags.some((selectedTag) => selectedTag.id === tag.id)
              ? "white"
              : "black",
            marginRight: "4px",
            cursor: "pointer",
            "&:hover": {
              bgcolor: selectedTags.some(
                (selectedTag) => selectedTag.id === tag.id,
              )
                ? "#4780AB"
                : "#F1F6FD",
              color: selectedTags.some(
                (selectedTag) => selectedTag.id === tag.id,
              )
                ? "white"
                : "black",
            },
          }}
        />
      ))}
    </Stack>
  );
};

export default CommunityTagsTab;
