import React, { useEffect, useState, memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Drawer, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import history from "../../../../lib/history-helper";
import { useSelector, useDispatch } from "react-redux";
import BadgeCard from "./components/badge-card";
import { withTranslation } from "react-i18next";
import SmallAvatar from "../../../generals/small-avatar";
import { useParams } from "react-router-dom";
import { getAthlete } from "../../../../actions/trail-head-action";
import { getUserBadges } from "../../../../actions/user-plans-action";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  drawerRoot: {
    backgroundColor: "#F9F9FB",
    maxHeight: "100%",
    height: "100%",
    maxWidth: "500px",
    minWidth: "500px",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "100vw",
      minWidth: "100vw",
    },
  },
  header: {
    display: "flex",
    backgroundColor: "#FFF",
    padding: "10px 16px 10px 16px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subtitle: {
    textAlign: "center",
    color: "#E16327",
    marginTop: "8px",
  },
  bold: {
    fontWeight: "bold",
  },
  avatar: {
    width: "44px",
    height: "44px",
  },
  content: {
    padding: "16px 0",
  },
  noBadgesParent: {
    display: "flex",
    margin: "16px",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    textAlign: "center",
    marginTop: 10,
  },
  goBack: {
    cursor: "pointer",
  },
});

const AllBadges = ({ classes, t }) => {
  const { userPlans, trailHead } = useSelector((state) => state);
  const { currentUserBadges } = userPlans;
  const { selectedAthlete, loadingAthlete } = trailHead || {};
  const { cognito_user_sub } = selectedAthlete || {};
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const goBack = () => history.push(`/trailhead/profile/${cognito_user_sub}`);

  const filteredBadges = (currentUserBadges || []).filter(
    (item) => item.finished === true,
  );

  useEffect(() => {
    const { id } = params;

    setOpen(true);
    if (!selectedAthlete && !Object.keys(selectedAthlete || {}).length) {
      dispatch(getAthlete(id));
      dispatch(getUserBadges(id));
    }
  }, []);

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        onClose={goBack}
        classes={{
          paper: classes.drawerRoot,
        }}
      >
        <div className={classes.header}>
          <SmallAvatar />
          <div>
            <Typography variant="body1" className={classes.bold}>
              {t("All Badges").toUpperCase()}
            </Typography>
          </div>
          <div onClick={goBack} className={classes.goBack}>
            <Close />
          </div>
        </div>
        <div className={classes.content}>
          {loadingAthlete ? (
            <div className={classes.loading}>
              <CircularProgress variant="indeterminate" />
            </div>
          ) : filteredBadges.length ? (
            (filteredBadges || []).map((item, index) => (
              <BadgeCard {...{ entry: item }} key={index} />
            ))
          ) : (
            <div className={classes.noBadgesParent}>
              <Typography>{t("No Badges")}</Typography>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("trailhead")(memo(AllBadges)),
);
