import React, { useEffect, useState } from "react";
import { Box, Slide, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ToolbarReturnBack from "./ToolbarReturnBack";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import usePost from "../../../hooks/usePost";
import PostCardComponent from "./PostCardComponent";
import PostCardComponentSkeleton from "./PostCardComponentSkeleton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  dialog: {
    display: "flex",
    flexDirection: "column",
  },
  headerBox: {
    flex: "0 0 auto",
  },
  contentBox: {
    flex: "auto",
    overflowY: "auto",
  },
});

const CommunityPostDialog = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(true);
  const { currentPost, handleGetPost } = usePost();
  const { goBack } = useHistory();

  useEffect(() => {
    handleGetPost(id);
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    goBack();
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <Box className={classes.headerBox}>
        <ToolbarReturnBack goBack={handleCloseDialog} />
      </Box>
      <Box className={classes.contentBox}>
        {currentPost == null ? (
          <PostCardComponentSkeleton />
        ) : (
          <PostCardComponent postData={currentPost} isDialog={true} />
        )}
      </Box>
    </Dialog>
  );
};

export default CommunityPostDialog;
