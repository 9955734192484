import { convertUnits } from "../lib/units-helper";
import useActivities from "./useActivities";
import { useEffect, useState } from "react";
import unitsHook from "./unitsHook";
import {
  getPaginatedPlan,
  getUserActivities,
} from "../actions/user-plans-action";
import { useDispatch } from "react-redux";
import useUserInfo from "./useUserInfo";
import moment from "moment";
import dayjs from "dayjs";
import { dateFormat, formatDate } from "../lib/date-helper";
import { setProfileDate, setProfileFilter } from "../actions/profile-action";
import bugsnagClient from "../lib/bugsnag-client";

const useCalculatorMI = () => {
  const units = unitsHook();
  const dispatch = useDispatch();
  const { activities } = useActivities();
  const {
    currentPlan,
    nextRaces,
    currentDate,
    toDate,
    currentTraining,
    sub,
  } = useUserInfo();
  const [average, setAverage] = useState(0);
  const calculateValue = (distance = 0, elevation = 0) => {
    if (isNaN(distance) || isNaN(elevation) || distance <= 0 || elevation <= 0)
      return 0;
    return Math.round(elevation / distance);
  };

  const calculateTotals = (key, localActivities = []) => {
    return localActivities.reduce(
      (accumulator, value) => accumulator + value[key],
      0,
    );
  };

  const convertElevationToUnit = (localElevation, units) => {
    return isNaN(localElevation) || localElevation <= 0
      ? 0
      : units === "kms"
      ? localElevation
      : localElevation * 3.2808;
  };

  const convertDistanceToUnit = (localDistance, units) => {
    return isNaN(localDistance) || localDistance <= 0
      ? 0
      : units === "kms"
      ? localDistance / 1000
      : localDistance * 0.00062137;
  };

  const calculateRaceComparison = (race, currentResult, units) => {
    const resultRace = calculateValue(
      units === "kms"
        ? parseFloat(race.raceDistance)
        : convertUnits(
            parseFloat(race.raceDistance),
            "kms",
            "miles",
            "distance",
          ),
      units === "kms"
        ? parseFloat(race.raceElevation)
        : convertUnits(
            parseFloat(race.raceElevation),
            "kms",
            "miles",
            "elevation",
          ),
    );

    const comparation = calculatePercent(
      Number(resultRace),
      Number(currentResult),
    );
    return comparation;
  };

  const calculatePercent = (total, activitiesMountain) => {
    if (
      !isNaN(total) &&
      total > 0 &&
      !isNaN(activitiesMountain) &&
      activitiesMountain > 0
    ) {
      const result = (activitiesMountain * 100) / total;
      return Math.round(result);
    }

    return 0;
  };

  const getMessageForMountainIndex = (percentage, hasRace) => {
    const lowerBound = 70;
    const upperBound = 130;

    if (!hasRace && percentage <= 0)
      return "mountainIndex.tip.hasntRaceAndPercentageMinorToCero";

    if (percentage >= lowerBound && percentage <= upperBound)
      return "mountainIndex.tip.betweenLowerBoundAndUpperBound";
    if (percentage > upperBound) return "mountainIndex.tip.higherToUpperBound";
    if (percentage < lowerBound) return "mountainIndex.tip.minorToLowerBound";

    return "";
  };

  const getNextRace = () => {
    if (!nextRaces && !nextRaces.length) return null;

    const futureRaces = Array.from(nextRaces).filter(
      (race) =>
        moment(race.raceDate, "DD/MM/YYYY").isSameOrAfter(moment()) &&
        race.raceDistance &&
        race.raceElevation,
    );

    if (futureRaces.length <= 0) return null;

    const priorityOrder = ["A", "B", "C"];
    for (const priority of priorityOrder) {
      const workout = futureRaces.find(
        (item) => item.racePriority === priority,
      );
      if (workout) return workout;
    }
  };

  const filterActivities = () => {
    return (activities || []).filter((activity) => {
      if (activity) {
        const date = activity.date ? activity.date : activity.start_date;
        const activityDate = dayjs(date);
        const currentDateFormatted = dayjs(currentDate);
        const toDateFormatted = dayjs(toDate);
        if (
          (activityDate.isSame(currentDateFormatted) ||
            activityDate.isAfter(currentDateFormatted)) &&
          (activityDate.isSame(toDateFormatted) ||
            activityDate.isBefore(toDateFormatted))
        )
          return activity;
      }
    });
  };

  const getColorByAverageValue = (value) => {
    const colorRanges = [
      { max: 30, color: "#E02758" }, // Alert
      { max: 70, color: "#FFB845" }, // Warning
      { max: 90, color: "#6BB488" }, // Success
      { max: 130, color: "#FFB845" }, // Warning
      { max: Infinity, color: "#E02758" }, // Alert
    ];

    const colorObject = colorRanges.find((range) => value <= range.max);
    return colorObject ? colorObject.color : undefined;
  };

  const getPlanDates = (plan) => {
    if (!plan || (Array.isArray(plan) && plan.length === 0))
      return {
        startDate: dayjs().format(formatDate),
        endDate: dayjs().format(formatDate),
      };

    const dates = plan.flat().map((workout) => workout.plannedDate);
    dates.sort(
      (a, b) =>
        dayjs(a, "DD/MM/YYYY").toDate() - dayjs(b, "DD/MM/YYYY").toDate(),
    );

    const today = dayjs();
    const getPlanDayFromToday = plan.flat().reduce((closest, workout) => {
      const plannedDate = dayjs(workout.plannedDate, dateFormat);
      return !closest ||
        Math.abs(today.diff(plannedDate)) <
          Math.abs(today.diff(dayjs(closest.plannedDate, dateFormat)))
        ? workout
        : closest;
    }, null);

    const startDate = dates[0];
    const endDate = getPlanDayFromToday.plannedDate;
    return {
      startDate: dayjs(startDate, dateFormat).format(formatDate),
      endDate: dayjs(endDate, dateFormat).format(formatDate),
    };
  };

  const manageDates = (date, toDate) => {
    const parsedDate = manageDate(date).replaceAll("/", "-");
    const parsedToDate = manageDate(toDate).replaceAll("/", "-");
    return { parsedDate, parsedToDate };
  };

  const manageDate = (date) => {
    const newDate = date.split("/");
    const parsedDate = `${newDate[1]}/${newDate[0]}/${newDate[2]}`;
    return parsedDate;
  };

  useEffect(() => {
    if (activities && activities.length) {
      const localActivities = filterActivities(activities);
      const elevation = convertElevationToUnit(
        calculateTotals("total_elevation_gain", localActivities),
        units,
      );
      const distance = convertDistanceToUnit(
        calculateTotals("distance", localActivities),
        units,
      );

      const result = calculateValue(distance, elevation);
      const currentRace = getNextRace();
      if (currentRace) {
        const comparation = calculateRaceComparison(currentRace, result, units);
        setAverage(comparation);
      } else {
        setAverage(result);
      }
    }
  }, [activities, currentDate, toDate]);

  useEffect(() => {
    if (currentPlan && !(activities && activities.length))
      dispatch(getUserActivities(currentPlan));
  }, [currentPlan]);

  useEffect(() => {
    if (
      typeof currentTraining != "string" &&
      typeof currentTraining != "undefined"
    ) {
      try {
        const { startDate, endDate } = getPlanDates(currentTraining.days);
        const { parsedDate, parsedToDate } = manageDates(startDate, endDate);
        dispatch(setProfileDate({ currentDate: startDate, toDate: endDate }));
        dispatch(getPaginatedPlan(parsedDate, parsedToDate, sub));
        dispatch(setProfileFilter(2)); // full plan
      } catch (error) {
        bugsnagClient.notify(error);
      }
    }
  }, [currentTraining]);

  return {
    average,
    calculatePercent,
    calculateRaceComparison,
    calculateValue,
    calculateTotals,
    convertDistanceToUnit,
    convertElevationToUnit,
    getMessageForMountainIndex,
    getColorByAverageValue,
    getPlanDates,
  };
};

export default useCalculatorMI;
