import React from "react";
import { Box, makeStyles, Divider } from "@material-ui/core";
import { palette } from "../../../theme/palette";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles(() => ({
  card: {
    background: palette.white,
    borderRadius: "8px",
    marginTop: "12px",
    width: "275px",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
}));

const PopularPlansCardSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Skeleton variant="rounded" width="100%" height="125px" />
      <Box
        style={{
          padding: "12px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Skeleton width="60%" />
          <Skeleton width="80%" />
        </Box>
        <Box>
          <Box
            style={{
              display: "flex",
              padding: "12px",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                height: "100%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Skeleton height="40px" width="30%" />
              <Skeleton width="50%" />
            </Box>
            <Divider orientation="vertical" variant="fullWidth" flexItem />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                height: "100%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Skeleton height="40px" width="30%" />
              <Skeleton width="50%" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PopularPlansCardSkeleton;
