import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { palette } from "../../theme/palette";
import TimerOutlined from "@material-ui/icons/TimerOutlined";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  card: {
    background: palette.white,
    borderRadius: "8px",
    marginTop: "12px",
    width: "100%",
    marginBottom: "16px",
  },
  title: {
    fontFamily: "Oswald",
    marginBottom: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: "4px",
  },
  content: {
    color: palette.darkGray,
    fontWeight: "400",
    marginBottom: "8px",
  },
  button: {
    fontFamily: "Karla",
    marginTop: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "18px",
    textTransform: "lowercase",
  },
  descriptionContainer: {
    padding: "16px",
    boxSizing: "border-box",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  verticalCentered: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  divider: {
    background: palette.lightGray,
    width: "2px",
    height: "40px",
  },
  label: {
    color: palette.darkGray,
    marginTop: "4px",
  },
  labelOnly: {
    color: palette.darkGray,
  },
  margin: {
    marginTop: "12px",
  },
  carousel: {
    width: "100%",
  },
  blur: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: "50%",
    width: 56,
    height: 56,
    padding: "4px",
  },
  blurFavorite: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: "50%",
    width: 32,
    height: 32,
    padding: "4px",
    boxSizing: "border-box",
    position: "absolute",
    top: "8px",
    right: "8px",
  },
  bold: {
    fontWeight: "700",
  },
});

const imageStyles = (url) => ({
  borderRadius: "8px 8px 0 0",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url(${url})`,
  height: "150px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
});

const iconStyle = {
  color: palette.darkGray,
  width: "18px",
  margin: 0,
  padding: 0,
  marginRight: "4px",
};

const VideosRow = ({ data, onlyFavorites, language }) => {
  const { currentPlan } = useSelector((state) => state.userPlans);
  const { exploreFavorites } = currentPlan || {};
  const classes = useStyles();
  const history = useHistory();

  const navigate = ({ id }) => {
    history.push(`explore/post/${id}`);
  };

  return (
    <>
      <Box className={classes.carousel}>
        {(data || []).map((item, index) => {
          const isFavorite = (exploreFavorites || []).find(
            ({ id }) => id === item.id,
          );

          if (onlyFavorites && !isFavorite) return;
          return (
            <Box
              onClick={() => navigate(item)}
              className={classes.card}
              key={item.id}
              id={`Video ${index + 1}`}
            >
              <Box alt="training-cover" sx={imageStyles(item.image_url)}>
                <Box className={classes.blur}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-play-white.svg`}
                    width="100%"
                  />
                </Box>

                <Box className={classes.blurFavorite}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-star-outline-${
                      isFavorite ? "filled" : "white"
                    }.svg`}
                    width="100%"
                  />
                </Box>
              </Box>
              <Box></Box>
              <Box className={classes.descriptionContainer}>
                <Box display="flex">
                  <TimerOutlined style={iconStyle} />
                  <Typography variant="subtitle2" className={classes.content}>
                    {item.time || item.workout_time}
                  </Typography>
                </Box>
                <Typography variant="h4">
                  {item.workout_type[language]
                    ? item.workout_type[language]
                    : item.workout_type["en"]}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default VideosRow;
