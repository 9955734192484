import { Avatar, makeStyles } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import React from "react";
import { TypeMessage } from "./Chat";
import { palette } from "../../../theme/palette";
import { Storage } from "aws-amplify";

const ChatAvatar = ({ message, onAppbar = false }) => {
  const classes = useStyles();
  const [avatarFound, setAvatarFound] = React.useState(null);

  const getAvatar = async (imageName) => {
    setAvatarFound(
      await Storage.get(imageName, {
        level: "public",
      }),
    );
  };

  React.useEffect(() => {
    if (message && message.senderImage) getAvatar(message.senderImage);
  }, [message]);

  if (!message) return <Skeleton variant="circular" width={36} height={36} />;

  return (
    <>
      {avatarFound ? (
        <Avatar
          className={onAppbar ? classes.avatar : classes.avatarMessage}
          src={avatarFound}
        />
      ) : (
        <Avatar
          className={`${onAppbar ? classes.avatar : classes.avatarMessage} ${
            TypeMessage[message.type] === "COACH"
              ? classes.avatarReceived
              : classes.avatarSender
          }`}
        >
          {message.senderName ? message.senderName.charAt(0) : "V"}
        </Avatar>
      )}
    </>
  );
};

const useStyles = makeStyles({
  avatar: {
    width: 40,
    height: 40,
  },
  avatarMessage: {
    width: 32,
    height: 32,
    backgroundColor: palette.white,
  },
  avatarReceived: {
    background: palette.lightPink,
  },
  avatarSender: {
    background: palette.secondary,
  },
});

export default ChatAvatar;
