import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Drawer, Badge } from "@material-ui/core";
import moment from "moment";
import { addNote, editNote, deleteNote } from "../../actions/user-plans-action";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import AthletesNotes from "./athletes-notes";
import AthletesComments from "./athletes-comments";

const styles = (theme) => ({
  iconsContainer: {
    display: "flex",
  },
  iconFirst: {
    margin: "0 50px",
    cursor: "pointer",
  },
  iconSecond: {
    marginRight: 25,
    cursor: "pointer",
  },
});

class AthletesNotifications extends Component {
  state = {
    notesOpen: false,
    commentsOpen: false,
  };

  componentWillMount() {}

  openNotes = () => {
    this.setState({
      ...this.state,
      notesOpen: true,
    });
  };

  closeNotes = () => {
    this.setState({
      ...this.state,
      notesOpen: false,
    });
  };

  openComments = () => {
    this.setState({
      ...this.state,
      commentsOpen: true,
    });
  };

  closeComments = () => {
    this.setState({
      ...this.state,
      commentsOpen: false,
    });
  };

  handleAddNote = (note) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { cognito_user_sub } = currentPlan;
    this.props.dispatch(addNote(note, cognito_user_sub));
  };

  handleEditNote = (note) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { cognito_user_sub } = currentPlan;
    this.props.dispatch(editNote(note, cognito_user_sub));
  };

  handleDeleteNote = (note) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { cognito_user_sub } = currentPlan;
    this.props.dispatch(deleteNote(note, cognito_user_sub));
  };

  render() {
    const {
      classes,
      athletes,
      userPlans,
      onOpenChat,
      currentSelected,
      totalUnreadMessages,
    } = this.props;
    const { searchResult, comments } = athletes || {};
    const { data } = searchResult;

    const { notesOpen, commentsOpen } = this.state;

    const { currentPlan } = userPlans || {};
    const { notes } = currentPlan || {};

    const reminders = notes ? notes.filter((n) => n.type === 1) : [];

    const today = moment();
    const todayReminders = reminders.filter((r) =>
      moment(r.date).isSame(today, "day"),
    );

    let newComments = [];

    let newCommentsNum = 0;

    if (currentSelected && !totalUnreadMessages) {
      if (comments) {
        const newAthleteComments = comments.filter(
          (c) => c.type === 1 && c.isReadByCoach === false,
        );
        if (newAthleteComments.length) {
          newComments.push(newAthleteComments[newAthleteComments.length - 1]);
        }
        newCommentsNum = newComments.length;
      }
    } else {
      data.forEach((element) => {
        if (element && element.unread) {
          newComments.push(element.lastChat);
          newCommentsNum += element.unread;
        }
      });
    }
    if (totalUnreadMessages) {
      newCommentsNum = totalUnreadMessages;
    }

    return (
      <div className={classes.iconsContainer}>
        <Badge
          color="error"
          className={classes.iconFirst}
          badgeContent={newCommentsNum}
        >
          <ChatBubbleOutlineOutlinedIcon
            onClick={() =>
              currentSelected ? onOpenChat() : this.openComments()
            }
          />
        </Badge>
        <Badge
          color="secondary"
          className={classes.iconSecond}
          badgeContent={todayReminders.length}
        >
          <NotificationsNoneOutlinedIcon onClick={() => this.openNotes()} />
        </Badge>
        <Drawer anchor="right" open={notesOpen} onClose={this.closeNotes}>
          <AthletesNotes
            notes={reminders}
            onAddNote={this.handleAddNote}
            onEditNote={this.handleEditNote}
            onDeleteNote={this.handleDeleteNote}
            athlete={null}
            type={1}
          ></AthletesNotes>
        </Drawer>

        <Drawer anchor="right" open={commentsOpen} onClose={this.closeComments}>
          <AthletesComments
            newComments={newComments}
            onOpenChat={onOpenChat}
          ></AthletesComments>
        </Drawer>
      </div>
    );
  }
}

AthletesNotifications.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  xs: PropTypes.number,
  athletes: PropTypes.object,
  userPlans: PropTypes.object,
  dispatch: PropTypes.func,
  totalUnreadMessages: PropTypes.number,
};

export default connect((store) => {
  return {
    athletes: store.athletes,
    userPlans: store.userPlans,
  };
})(withStyles(styles)(AthletesNotifications));
