import { useEffect, useState } from "react";

const unitsHook = () => {
  const [currentUnit, setCurrentUnit] = useState("kms");

  useEffect(() => {
    window.addEventListener("storage", localStorageUpdated);

    const unit = localStorage.getItem("units");
    setCurrentUnit(unit);

    return () => window.removeEventListener("storage", localStorageUpdated);
  }, []);

  const localStorageUpdated = () =>
    setCurrentUnit(localStorage.getItem("units"));
  return currentUnit;
};

export default unitsHook;
