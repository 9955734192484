import { useEffect } from "react";
import { App } from "@capacitor/app";

const AppUrlListener = () => {
  useEffect(() => {
    App.addListener("appUrlOpen", (data) => {
      let slug = null;
      if (data.url.includes("run.vert.app://")) {
        slug = data.url.split("run.vert.app://").pop();
      } else {
        slug = data.url.split(".run/").pop();
      }

      if (slug !== null || slug !== undefined) {
        window.location.href = "/" + slug;
      } else {
        window.location.href = "/";
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;
