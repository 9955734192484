import ActionTypes from "../constants/action-types";
import { logEvent, setEventsMetadata } from "../lib/events-helper";
import HealthKitService from "../services/healthkit-service";
import UserPlansService from "../services/user-plans-service";
import {
  standarizeHealtkit,
  healthKitStandardDetail,
} from "../lib/activities-helper";

const initAuthorizationHealthKit = (cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.HEALTHKIT_INIT_PENDING });
    try {
      await HealthKitService.initAuthorization();
      dispatch({ type: ActionTypes.HEALTHKIT_INIT_FULFILLED, payload: true });
      setEventsMetadata({ customer_id: cognito_user_sub });
      logEvent("connect_GPS_provider");

      const result = await UserPlansService.updateCurrentUserInfo({
        cognito_user_sub,
        healthKit: true,
      });

      dispatch({
        type: ActionTypes.USER_PLANS_SET_CURRENT_PLAN,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.HEALTHKIT_INIT_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getActivitiesHealthKit = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.HEALTHKIT_LOAD_ACTIVITIES_PENDING });
    try {
      const result = await HealthKitService.getActivities();
      dispatch({
        type: ActionTypes.HEALTHKIT_LOAD_ACTIVITIES_FULFILLED,
        payload: standarizeHealtkit(result),
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.HEALTHKIT_LOAD_ACTIVITIES_REJECTED,
        payload: error,
        error: true,
      });
    }
  };
};

const getActivityHealthKit = (activity) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.HEALTHKIT_GET_ACTIVITY_PENDING });
    const result = healthKitStandardDetail(activity);
    try {
      dispatch({
        type: ActionTypes.HEALTHKIT_GET_ACTIVITY_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.HEALTHKIT_GET_ACTIVITY_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const clearActivityHealthKit = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.HEALTHKIT_CLEAR_ACTIVITY });
  };
};

const disconnectHealthkit = (cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.HEALTHKIT_INIT_PENDING });
    try {
      dispatch({ type: ActionTypes.HEALTHKIT_INIT_FULFILLED, payload: false });
      dispatch({
        type: ActionTypes.HEALTHKIT_LOAD_ACTIVITIES_FULFILLED,
        payload: [],
      });

      const result = await UserPlansService.updateCurrentUserInfo({
        cognito_user_sub,
        healthKit: false,
      });

      dispatch({
        type: ActionTypes.USER_PLANS_SET_CURRENT_PLAN,
        payload: result,
      });
    } catch (error) {
      throw new Error(error);
    }
  };
};

const clearHealthkitActivities = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.HEALTHKIT_CLEAR_ACTIVITIES });
  };
};

export {
  initAuthorizationHealthKit,
  getActivitiesHealthKit,
  getActivityHealthKit,
  clearActivityHealthKit,
  disconnectHealthkit,
  clearHealthkitActivities,
};
