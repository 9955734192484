import TrainingsService from "./trainings-service";
import WorkoutPlans from "../constants/workout-plans";
import {
  RaceCountPlanToTraining,
  TrainingPerWeekPlanToTraining,
} from "../lib/training-field-mapper";
import moment from "moment";
import { getPreviousMonday, dateFormat } from "../lib/date-helper";
import RaceCounts from "../constants/race-counts";
import { chainTraining } from "../lib/plan-helper";

class SmartPlansService {
  static getWeeksDifference(goal, trainingPlan) {
    const { raceDate } = goal;
    const goalDate = getPreviousMonday(moment(raceDate, "DD/MM/YYYY"));

    const { startingDate, durationInWeeks } = trainingPlan;
    const trainingPlanEndDate = moment(startingDate, "DD/MM/YYYY").add(
      durationInWeeks,
      "weeks",
    );

    return goalDate.diff(trainingPlanEndDate, "weeks") + 1;
  }

  static getFitEnough(trainHoursPerWeek, trainingPlan, raceCount) {
    const { startingDate, days } = trainingPlan;
    const thirdWeekStart = moment(startingDate, "DD/MM/YYYY").add(2, "weeks");

    const thirdWeekEnd = moment(startingDate, "DD/MM/YYYY").add(3, "weeks");

    let level = 2;
    if (raceCount) {
      const raceCountsItemsArray = RaceCounts.itemsArray;
      switch (raceCount) {
        case `${raceCountsItemsArray[0].value},${raceCountsItemsArray[1].value}`:
          level = 0;
          break;
        case `${raceCountsItemsArray[2].value},${raceCountsItemsArray[3].value}`:
          level = 1;
          break;
        default:
          level = 2;
      }
    }

    const thirdWeekMins = days.reduce((a, day) => {
      if (day && day[0]) {
        if (
          moment(day[0].plannedDate, "DD/MM/YYYY").isBetween(
            thirdWeekStart,
            thirdWeekEnd,
            "[)",
          )
        ) {
          return (
            a +
            (Array.isArray(day[0].estimatedTime)
              ? day[0].estimatedTime[level]
              : +day[0].estimatedTime)
          );
        }
        return a;
      }
      return a;
    }, 0);

    return trainHoursPerWeek >= thirdWeekMins / 60;
  }

  static async buildTrainingPlan(
    {
      desiredStartDate,
      searchResult,
      goalWeeksDiff = null,
      fitEnough = null,
      needsTailor = false,
      userPlanTempData,
      relatedChainTraining,
    },
    subCategories = [],
  ) {
    const desiredStartDateMoment = moment(desiredStartDate, "DD/MM/YYYY");
    const previousMondayMoment = getPreviousMonday(
      desiredStartDateMoment.clone(),
    );
    const relatedTrainingPlan = searchResult;

    let relatedTrainingPlanInstance = {
      ...relatedTrainingPlan,
      startingDate: moment(previousMondayMoment).format(dateFormat),
      days: relatedTrainingPlan.days.map((day, index) => {
        const currentPlannedDate = moment(previousMondayMoment).add(
          index,
          "days",
        );
        if (day) {
          return [
            ...day.map((training) => {
              return {
                ...training,
                plannedDate: currentPlannedDate.format(dateFormat),
              };
            }),
          ];
        }
      }),
    };

    if (relatedChainTraining) {
      let currentTraining = relatedTrainingPlanInstance;
      let selectedTrainingPlan = relatedChainTraining;
      let position = 1;
      const currentTrainingChanges = chainTraining(
        currentTraining,
        selectedTrainingPlan,
        position,
      );

      relatedTrainingPlanInstance = currentTrainingChanges.currentTraining;
    }

    // No changes, return normal TP
    if (!needsTailor)
      return {
        relatedTrainingPlanInstance: removeDaysBeforeDate(
          relatedTrainingPlanInstance,
          desiredStartDateMoment,
        ),
        comment: "",
      };

    const goalDate = getPreviousMonday(
      moment(userPlanTempData.goal.raceDate, "DD/MM/YYYY"),
    );
    const choosenPlan = subCategories.data.find(
      (s) => s.id === searchResult.subcategory,
    );

    const commentPayload = {
      raceName:
        userPlanTempData.goal && userPlanTempData.goal.raceName
          ? userPlanTempData.goal.raceName
          : "",
      weeksToRace: goalDate.diff(moment().startOf("week"), "weeks") + 1,
      plan: choosenPlan ? choosenPlan.title : null,
      xWeeks: 0,
      yWeeks: choosenPlan ? +choosenPlan.durationInWeeks : 0,
    };

    return {
      relatedTrainingPlanInstance: removeDaysBeforeDate(
        relatedTrainingPlanInstance,
        desiredStartDateMoment,
      ),
      comment: "",
    };
  }

  static removeWeeks(amount, relatedTrainingPlanInstance) {
    const newDays = relatedTrainingPlanInstance.days
      .map((day) => {
        if (day) {
          return day.map((training) => {
            return {
              ...training,
              plannedDate: moment(training.plannedDate, dateFormat)
                .add(amount, "weeks")
                .format(dateFormat),
            };
          });
        }
      })
      .filter(
        (day) =>
          !day ||
          (day &&
            day[0] &&
            moment(day[0].plannedDate, dateFormat).isSameOrAfter(
              moment(relatedTrainingPlanInstance.startingDate, dateFormat),
            )),
      );

    relatedTrainingPlanInstance.days = newDays;
    return relatedTrainingPlanInstance;
  }

  static addWeeks(
    amount,
    relatedTrainingPlanInstance,
    fromPlan,
    desiredStartDateMoment,
  ) {
    const newDays = relatedTrainingPlanInstance.days.map((day) => {
      if (day) {
        return day.map((training) => {
          return {
            ...training,
            plannedDate: moment(training.plannedDate, dateFormat)
              .add(amount, "weeks")
              .format(dateFormat),
          };
        });
      }
    });

    let firstWorkoutIndex = fromPlan.days.findIndex((day) => !!day);

    const fromPlanDays = fromPlan.days.map((day, index) => {
      const currentPlannedDate = moment(
        relatedTrainingPlanInstance.startingDate,
        dateFormat,
      ).add(index, "days");
      if (
        day &&
        (desiredStartDateMoment < currentPlannedDate ||
          index === firstWorkoutIndex)
      ) {
        return [
          ...day.map((training) => {
            if (index === firstWorkoutIndex) {
              return {
                ...training,
                plannedDate: desiredStartDateMoment.format(dateFormat),
              };
            }
            return {
              ...training,
              plannedDate: currentPlannedDate.format(dateFormat),
            };
          }),
        ];
      }
    });

    relatedTrainingPlanInstance.days = [
      ...fromPlanDays.filter(
        (day) =>
          !day ||
          (day &&
            day[0] &&
            moment(relatedTrainingPlanInstance.startingDate, dateFormat)
              .add(amount, "weeks")
              .isAfter(moment(day[0].plannedDate, dateFormat))),
      ),
      ...newDays,
    ];
    return relatedTrainingPlanInstance;
  }

  static async getRawTrainingPlan(
    userPlanTempData,
    subcategory,
    subCategories,
    training_order = 1,
  ) {
    let {
      surface,
      raceCount,
      trainPerWeek,
      distance,
      selectedPlan,
      skillToImprove,
    } = userPlanTempData;

    const { data = [] } = subCategories || {};
    const currentSub = data.find((s) => s.id === +subcategory);
    const { questions = [] } = currentSub;

    const qSurface = questions.find(
      (q) => q.field === "surface" && q.defaultValue,
    );
    if (qSurface) {
      surface = qSurface.defaultValue;
    }

    const qRaceCount = questions.find(
      (q) => q.field === "raceCount" && q.defaultValue,
    );
    if (qRaceCount) {
      raceCount = qRaceCount.defaultValue;
    }

    const qTrainPerWeek = questions.find(
      (q) => q.field === "trainPerWeek" && q.defaultValue,
    );
    if (qTrainPerWeek) {
      trainPerWeek = qTrainPerWeek.defaultValue;
    }

    const qDistance = questions.find(
      (q) => q.field === "distance" && q.defaultValue,
    );
    if (qDistance) {
      distance = qDistance.defaultValue;
    }

    const qSkillToImprove = questions.find(
      (q) => q.field === "skillToImprove" && q.defaultValue,
    );
    if (qSkillToImprove) {
      skillToImprove = qSkillToImprove.defaultValue;
    }

    let trainingPlanId = `${selectedPlan}${
      skillToImprove ? `-${skillToImprove}` : ""
    }${distance ? `-${distance}` : ""}-${surface}-${RaceCountPlanToTraining(
      raceCount,
    )}-${TrainingPerWeekPlanToTraining(trainPerWeek)}${
      subcategory ? `-${subcategory}` : ""
    }`;

    let trainingPlanIdAuto = `${selectedPlan}${
      skillToImprove ? `-${skillToImprove}` : ""
    }${
      distance ? `-${distance}` : ""
    }-${surface}-auto-${TrainingPerWeekPlanToTraining(trainPerWeek)}${
      subcategory ? `-${subcategory}` : ""
    }`;

    if (
      subcategory === "1643821710" ||
      subcategory === "1640210885" ||
      subcategory === "1614349326"
    ) {
      trainingPlanId = `free${
        distance ? `-${distance}` : ""
      }-${surface}-${RaceCountPlanToTraining(
        raceCount,
      )}-${TrainingPerWeekPlanToTraining(trainPerWeek)}${
        subcategory ? `-${subcategory}` : ""
      }`;

      trainingPlanIdAuto = `free${
        distance ? `-${distance}` : ""
      }-${surface}-auto-${TrainingPerWeekPlanToTraining(trainPerWeek)}${
        subcategory ? `-${subcategory}` : ""
      }`;
    }

    try {
      let rawTrainingPlan = await TrainingsService.readTraining({
        id: trainingPlanIdAuto,
        training_order,
      });

      if (!rawTrainingPlan) {
        rawTrainingPlan = await TrainingsService.readTraining({
          id: trainingPlanId,
          training_order,
        });
      }

      return rawTrainingPlan;
    } catch (error) {
      return undefined;
    }
  }

  static async getTrainingPlan(
    userPlanTempData,
    subCategories = [],
    training_order = 1,
  ) {
    const {
      surface,
      raceCount,
      trainPerWeek,
      distance,
      selectedPlan,
      skillToImprove,
      subcategory,
      desiredStartDate,
      goal,
      trainHoursPerWeek_raw: trainHoursPerWeek,
    } = userPlanTempData;

    if (selectedPlan !== WorkoutPlans.PREMIUM) {
      const trainingPlanId = `${selectedPlan}${
        skillToImprove ? `-${skillToImprove}` : ""
      }${distance ? `-${distance}` : ""}-${surface}-${RaceCountPlanToTraining(
        raceCount,
      )}-${TrainingPerWeekPlanToTraining(trainPerWeek)}${
        subcategory ? `-${subcategory}` : ""
      }`;

      const trainingPlanIdAuto = `${selectedPlan}${
        skillToImprove ? `-${skillToImprove}` : ""
      }${
        distance ? `-${distance}` : ""
      }-${surface}-auto-${TrainingPerWeekPlanToTraining(trainPerWeek)}${
        subcategory ? `-${subcategory}` : ""
      }`;

      try {
        let searchResult = await TrainingsService.readTraining({
          id: trainingPlanIdAuto,
          training_order,
        });
        const relatedChainOrder = training_order + 1;
        let relatedChainTraining = await TrainingsService.readTraining({
          id: trainingPlanIdAuto,
          training_order: relatedChainOrder,
        });

        if (!searchResult) {
          searchResult = await TrainingsService.readTraining({
            id: trainingPlanId,
            training_order,
          });
          relatedChainTraining = await TrainingsService.readTraining({
            id: trainingPlanId,
            training_order: relatedChainOrder,
          });
        }
        if (searchResult) {
          const { relatedTrainingPlanInstance } = await this.buildTrainingPlan({
            desiredStartDate,
            searchResult,
            userPlanTempData,
            relatedChainTraining,
          });

          if (goal) {
            const weekDiff = this.getWeeksDifference(
              goal,
              relatedTrainingPlanInstance,
            );

            const fitEnough = this.getFitEnough(
              trainHoursPerWeek,
              relatedTrainingPlanInstance,
              raceCount,
            );

            if (weekDiff) {
              return await this.buildTrainingPlan(
                {
                  desiredStartDate,
                  searchResult,
                  userPlanTempData,
                  goalWeeksDiff: weekDiff,
                  needsTailor: true,
                  fitEnough,
                  relatedChainTraining,
                },
                subCategories,
              );
            }
          }
          return {
            relatedTrainingPlanInstance,
            comment: null,
          };
        }
      } catch (e) {
        return {
          relatedTrainingPlanInstance: undefined,
          comment: null,
        };
      }
    }
    return {
      relatedTrainingPlan: undefined,
      comment: null,
    };
  }
}

const getWeekDurations = (fullTraining) => {
  const startingDate =
    fullTraining && fullTraining.days && fullTraining.days.length
      ? fullTraining.days[0][0].plannedDate
      : moment().format(dateFormat);

  const endDate =
    fullTraining && fullTraining.days && fullTraining.days.length
      ? fullTraining.days[fullTraining.days.length - 1][0].plannedDate
      : moment().format(dateFormat);

  return (
    moment(endDate, dateFormat)
      .startOf("isoWeek")
      .diff(moment(startingDate, dateFormat).startOf("isoWeek"), "week") + 1
  );
};

const removeDaysBeforeDate = (fullTraining, desiredStartDateMoment) => {
  const previousMondayMoment = getPreviousMonday(
    desiredStartDateMoment.clone(),
  );
  let firstWorkoutIndex = fullTraining.days.findIndex((day) => !!day);
  const relatedTrainingPlanInstance = {
    ...fullTraining,
    startingDate: moment(previousMondayMoment).format(dateFormat),
    days: fullTraining.days.map((day, index) => {
      if (day && index === firstWorkoutIndex) {
        return [
          ...day.map((training) => {
            if (index === firstWorkoutIndex) {
              return {
                ...training,
                plannedDate: desiredStartDateMoment.format(dateFormat),
              };
            }
          }),
        ];
      } else if (
        day &&
        day[0] &&
        moment(day[0].plannedDate, dateFormat).isAfter(desiredStartDateMoment)
      ) {
        return day;
      } else {
        return null;
      }
    }),
  };
  relatedTrainingPlanInstance.days = relatedTrainingPlanInstance.days.filter(
    (trDay) => trDay !== undefined,
  );
  return relatedTrainingPlanInstance;
};

export default SmartPlansService;
