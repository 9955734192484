import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/min/locales";
import { useDispatch, useSelector } from "react-redux";
import { notificationPatch } from "../../../actions/notifications-action";
import { Box } from "@material-ui/core";
import NotificationList from "./NotificationList";
import NotificationPanel from "./NotificationPanel";

const Notifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const [notificationList, setNotificationList] = useState([]);

  const handleNotificationsClear = (e, list) => {
    e.preventDefault();
    if (Array.isArray(list) && list) {
      list.forEach((notification) =>
        dispatch(notificationPatch({ ...notification, isRead: true })),
      );
    }
  };

  const getNotificationDate = (notification) => {
    return notification.created_at;
  };

  useEffect(() => {
    let notificationListNow = notifications
      ? notifications.notificationList
      : [];

    let newNotificationList = [
      ...notificationListNow.filter((n) => n.notificationType === "trailhead"),
    ];

    newNotificationList = newNotificationList.sort((a, b) => {
      const aDate = getNotificationDate(a);
      const bDate = getNotificationDate(b);

      return bDate - aDate;
    });

    newNotificationList = newNotificationList.splice(0, 20);
    setNotificationList(newNotificationList);
  }, [notifications]);

  return (
    <>
      <Box>
        <NotificationPanel>
          <NotificationList notificationList={notificationList} />
        </NotificationPanel>
      </Box>
    </>
  );
};

Notifications.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  userPlans: PropTypes.object,
};

export default Notifications;
