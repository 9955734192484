import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import "url-search-params-polyfill";
import { Drawer } from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { Routes } from "../../lib/routing-middleware";
import history from "../../lib/history-helper";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import {
  addAllez,
  addComment,
  deletePost,
  deleteComment,
  editPost,
  getPost,
  addReport,
} from "../../actions/trail-head-action";
import Post from "./post";
import CircularProgress from "@material-ui/core/CircularProgress";
import { updatePlan } from "../../actions/user-plans-action";
import AppAuthenticator from "../app-authenticator";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import withWidth from "@material-ui/core/withWidth";
import { logEvent } from "../../lib/events-helper";

const styles = (theme) => ({
  backIcon: {
    cursor: "pointer",
  },
  drawerRoot: {
    minHeight: "100vh",
    maxWidth: "500px",
    minWidth: "500px",
    background: "#FFFFFF",
    overflowX: "hidden",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "100vw",
      minWidth: "100vw",
    },
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    margin: 10,
    position: "sticky",
    top: 0,
  },
  container: {
    margin: 10,
  },
  progressContainer: {
    textAlign: "center",
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },

  noPadding: {
    padding: "0 !important",
  },
});

class PostDrawer extends Component {
  state = {
    openSignUp: false,
  };

  componentDidMount() {
    const { match } = this.props;

    const { params } = match;
    const { id } = params;
    if (id) {
      this.props.dispatch(getPost(id));
    }
  }

  loadPost = () => {};

  handleClose = () => {
    history.push(Routes.TrailHead);
  };

  handleAddAllez = (id, commentId = null, replyId = null) => {
    const { auth } = this.props;
    const { currentUser, isAuthenticated } = auth;
    const { attributes } = currentUser || {};
    const { sub, name } = attributes || {};
    if (isAuthenticated) {
      this.props.dispatch(
        addAllez(sub, {
          cognito_user_sub: sub,
          cognito_user_name: name,
          id,
          commentId,
          replyId,
        }),
      );
    } else {
      this.handleOpenSignUp();
    }
  };

  handleAddComment = (comment) => {
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub, name } = attributes;
    this.props.dispatch(
      addComment(sub, { ...comment, cognito_user_name: name }),
    );
  };

  handleEdit = (editData) => {
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;
    this.props.dispatch(editPost(sub, editData));
  };

  handleDelete = (type, entry) => {
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;
    switch (type) {
      case "post":
        this.props.dispatch(deletePost(sub, entry));
        break;
      case "comment":
      case "reply":
        this.props.dispatch(deleteComment(sub, entry));
        break;
      default:
        break;
    }
  };

  handleReport = (id, reason) => {
    const { auth } = this.props;
    const { currentUser, isAuthenticated } = auth;
    const { attributes } = currentUser || {};
    const { sub, name } = attributes || {};
    if (isAuthenticated) {
      this.props.dispatch(
        addReport(sub, {
          cognito_user_sub: sub,
          cognito_user_name: name,
          id,
          reason,
        }),
      );
    } else {
      this.handleOpenSignUp();
    }
  };

  handleBlock = (user) => {
    const { userPlans, auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      const { currentPlan } = userPlans;
      const { cognito_user_sub, blockedUsers } = currentPlan || {};
      this.props.dispatch(
        updatePlan(
          {
            cognito_user_sub,
            blockedUsers: blockedUsers ? [...blockedUsers, user] : [user],
          },
          false,
          false,
        ),
      );
    } else {
      this.handleOpenSignUp();
    }
  };

  handleOpenSignUp = () => {
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignUp: true,
    });
  };

  handleCloseSignUp = () => {
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignUp: false,
    });
  };

  render() {
    const { trailHead, auth, classes, width } = this.props;
    const { currentPost, tags: tagOptions, loadingPost } = trailHead;
    const { currentUser, isAuthenticated } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes || {};

    const { openSignUp } = this.state;

    const isInMobileViewSm = width === "sm" || width === "xs";

    return (
      <div>
        <Drawer
          open={true}
          anchor="right"
          onClose={this.handleClose}
          classes={{
            paper: classes.drawerRoot,
          }}
        >
          <div className={classes.topBar}>
            <ArrowBackIosIcon
              onClick={() => this.handleClose()}
              className={classes.backIcon}
              fontSize="small"
              id="back_post_community"
            />
          </div>

          <div className={classes.container}>
            {loadingPost ? (
              <div className={classes.progressContainer}>
                <CircularProgress
                  variant="indeterminate"
                  color="primary"
                  data-testid="LoadingImage"
                />
              </div>
            ) : (
              <Post
                postData={currentPost}
                tags={tagOptions}
                onAddAllez={this.handleAddAllez}
                userSub={sub}
                onAddComment={this.handleAddComment}
                onTagClick={() => {}}
                onDelete={this.handleDelete}
                onEdit={this.handleEdit}
                onReport={this.handleReport}
                onBlock={this.handleBlock}
                isAuthenticated={isAuthenticated}
                openSignUp={this.handleOpenSignUp}
              ></Post>
            )}
          </div>
        </Drawer>

        <Dialog
          open={openSignUp}
          onClose={this.handleCloseSignUp}
          aria-labelledby="signUp"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signupdialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseSignUp}
              />
            </div>
            <AppAuthenticator initialState="signUp" />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect((store) => {
  return {
    auth: store.auth,
    trailHead: store.trailHead,
    userPlans: store.userPlans,
  };
})(
  withTranslation("trailhead")(
    withWidth()(withStyles(styles, { withTheme: true })(PostDrawer)),
  ),
);
