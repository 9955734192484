import React from "react";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";
import { palette } from "../../../theme/palette";

const CustomButton = ({ typeStyle, className, ...props }) => {
  const classes = useStyles();

  let buttonClassName = classes.primaryButton;

  if (typeStyle === "accent") {
    buttonClassName = classes.accentButton;
  }
  if (typeStyle === "secondary") {
    buttonClassName = classes.secondaryButton;
  }
  if (typeStyle === "disabled") {
    buttonClassName = classes.disabledButton;
  }
  if (typeStyle === "icon") {
    buttonClassName = classes.iconButton;
  }
  if (props.disabled) {
    buttonClassName = classes.disabledButton;
  }

  return (
    <Button
      {...props}
      className={`${classes.base} ${buttonClassName} ${className}`}
    />
  );
};

const useStyles = makeStyles(() => ({
  base: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 700,
    alignSelf: "center",
    border: `2px solid ${palette.darkBlue}`,
    padding: "5px 10px",
    "&:hover": {
      border: "2px solid ${palette.darkBlue}",
    },
  },
  accentButton: {
    backgroundColor: palette.primary,
    color: palette.white,
    border: "none",
    "&:hover": {
      backgroundColor: palette.primary,
      border: "none",
      color: palette.white,
    },
  },
  primaryButton: {
    backgroundColor: palette.darkBlue,
    color: palette.white,
    "&:hover": {
      backgroundColor: palette.darkBlue,
      color: palette.white,
    },
  },
  secondaryButton: {
    backgroundColor: palette.white,
    color: palette.darkBlue,
    "&:hover": {
      backgroundColor: palette.white,
      color: palette.darkBlue,
    },
  },
  disabledButton: {
    backgroundColor: `${palette.deactivated} !important`,
    color: `${palette.white} !important`,
    border: `2px solid ${palette.deactivated} !important`,
    "&:hover": {
      backgroundColor: `${palette.deactivated} !important`,
      color: `${palette.white} !important`,
      border: `2px solid ${palette.deactivated} !important`,
    },
  },
  disabled: {
    "&.Mui-disabled": {
      backgroundColor: `${palette.deactivated} !important`,
      color: `${palette.white} !important`,
      border: `2px solid ${palette.deactivated} !important`,
    },
    "&:hover": {
      backgroundColor: `${palette.deactivated} !important`,
      color: `${palette.white} !important`,
      border: `2px solid ${palette.deactivated} !important`,
    },
  },
}));

CustomButton.defaultProps = {
  typeStyle: "primary",
};

CustomButton.propTypes = {
  typeStyle: PropTypes.oneOf(["accent", "primary", "secondary", "disabled"]),
};

export default CustomButton;
