import dayjs from "dayjs";
import { round } from "../lib/units-helper";
import { useTranslation } from "react-i18next";

const useRaceCard = (race = {}) => {
  const { t } = useTranslation("profile");
  const today = dayjs();
  const {
    raceName,
    raceDistance,
    raceElevation,
    racePriority,
    raceId,
    link,
    raceDate,
  } = race;

  const createdAt = (raceDate) => {
    if (!raceDate) return 0;
    const splidDate = raceDate.split("/");
    const newDate = `${splidDate[1]}-${splidDate[0]}-${splidDate[2]}`;
    const total = dayjs(newDate).diff(dayjs(raceId), "days");
    const diff = calculateDifference(raceDate);
    const part = total - diff;
    const result = (part / total) * 100;
    return !isNaN(result) ? Number(result) : 100;
  };

  const calculateDifference = (date) => {
    if (!date) return;
    const splidDate = date.split("/");
    const newDate = `${splidDate[1]}-${splidDate[0]}-${splidDate[2]}`;
    const difference = dayjs(newDate).diff(today, "days");
    return difference;
  };

  const calculateDifferenceWeeks = (date) => {
    if (!date) return {};
    const splidDate = date.split("/");
    const newDate = `${splidDate[1]}-${splidDate[0]}-${splidDate[2]}`;
    const difference = dayjs(newDate).diff(today, "weeks");

    if (difference === 0) {
      const dayDifference = calculateDifference(date);
      return {
        diff: t("profile.races.card.remainingTime", {
          count: dayDifference,
          unit: "days",
        }),
        diffNumber: dayDifference,
      };
    }
    return {
      diff: t("profile.races.card.remainingTime", {
        count: difference,
        unit: "weeks",
      }),
      diffNumber: difference,
    };
  };

  const calculateValue = (distance = 0, elevation = 0) => {
    const isValid =
      !isNaN(distance) && distance > 0 && !isNaN(elevation) && elevation > 0;
    const result = elevation / distance;
    return isValid ? `${round(result)}`.substring(0, 4) : 0;
  };

  const handleDateFormat = (date) => {
    if (!date) return;
    return dayjs(date, "DD-MM-YYYY").format("MMM D, YYYY");
  };

  return {
    raceDate,
    raceDistance,
    raceElevation,
    raceId,
    raceName,
    racePriority,
    link,
    createdAt,
    calculateDifferenceWeeks,
    calculateValue,
    handleDateFormat,
  };
};

export default useRaceCard;
