import React from "react";
import { palette } from "../../theme/palette";

const FlatIcon = ({
  width = 24,
  height = 24,
  activated = false,
  color = "black",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={` 0 0 25 24`}
    fill={activated ? palette.primary : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.3335 12L21.3335 12"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default FlatIcon;
