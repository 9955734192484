import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import { Emoji } from "emoji-picker-react";
import classNames from "classnames";
import ClickNHold from "react-click-n-hold";

const styles = () => ({
  wrapperEmoji: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  emojiBtn: {
    background: "#EFEFEF",
    borderRadius: "62px",
    width: "35px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "all .6s",
  },
  reactionsCount: {
    fontSize: "11px",
    marginLeft: "3px",
  },
  hold: {
    "&:not(:last-child)": {
      marginRight: "5px",
    },
  },
});

const processReactions = (reactions) => {
  const reactionsList = [];
  const count = {};

  reactions.forEach((element) => {
    count[element] = (count[element] || 0) + 1;
  });

  for (const prop in count) {
    const index = reactions.findIndex((elem) => elem == prop);
    reactionsList[index] = {
      emoji: `${reactions[index]}`,
      count: `${count[prop]}`,
    };
  }

  return reactionsList || [];
};

const Reactions = ({ reactions = [], classes, className, handleDelete }) => (
  <div className={classNames(classes.wrapperEmoji, className)}>
    {processReactions(reactions || []).map((reaction, index) => (
      <div className={classes.hold} key={index}>
        <ClickNHold
          onClickNHold={() => handleDelete(reaction.emoji)}
          time={0.5}
        >
          <div className={classes.emojiBtn}>
            <Emoji unified={reaction.emoji} size="18" />
            <Typography className={classes.reactionsCount}>
              {reaction.count}
            </Typography>
          </div>
        </ClickNHold>
      </div>
    ))}
  </div>
);

export default withStyles(styles, { withTheme: true })(Reactions);
