import ActionTypes from "../constants/action-types";
import moment from "moment";

const initialState = {
  loadingLatest: false,
  mobileProductsExplore: [],
  mobileProductsPremium: [],
  loadingMobileProducts: undefined,
  errorMobileProducts: undefined,
  ownedMobileProducts: [],
  latestExpired: undefined,
  errorMessage: null,
  alreadyLoadedOnce: false,
  loadingContact: false,
  data: undefined,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.SUBSCRIPTIONS_LATEST_PENDING:
        return {
          ...state,
          loadingLatest: true,
        };
      case ActionTypes.SUBSCRIPTIONS_LATEST_FULFILLED:
        return {
          ...state,
          loadingLatest: false,
          latest: action.payload,
          alreadyLoadedOnce: true,
          latestExpired: action.payload
            ? moment().unix() >=
              (action.payload.current_term_end || action.payload.trial_end || 0)
            : undefined,
        };
      case ActionTypes.SUBSCRIPTIONS_LATEST_REJECTED:
        return {
          ...state,
          loadingLatest: false,
          latestExpired: undefined,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.SUBSCRIPTIONS_SET_ERROR_MESSAGE:
        return {
          ...state,
          errorMessage: action.payload,
        };
      case ActionTypes.SUBSCRIPTIONS_CLEAR_ERROR_MESSAGE:
        return {
          ...state,
          errorMessage: null,
        };
      case ActionTypes.SUBSCRIPTIONS_SET_SUCCESS_MESSAGE:
        return {
          ...state,
          successMessage: action.payload,
        };
      case ActionTypes.SUBSCRIPTIONS_CLEAR_SUCCESS_MESSAGE:
        return {
          ...state,
          successMessage: undefined,
        };
      case ActionTypes.AUTH_CURRENT_USER_SIGNOUT_FULFILLED:
        return {
          ...initialState,
        };

      case ActionTypes.MOBILE_PRODUCTS:
        return {
          ...state,
          mobileProductsExplore: action.payload.mobileProductsExplore,
          mobileProductsPremium: action.payload.mobileProductsPremium,
        };

      case ActionTypes.OWNED_MOBILE_PRODUCT:
        let newOwned = state.ownedMobileProducts;
        const already = newOwned.find((o) => o.id === action.payload.id);
        if (already) {
          newOwned = [
            ...newOwned.filter((o) => o.id !== action.payload.id),
            action.payload,
          ];
        } else {
          newOwned.push(action.payload);
        }
        return {
          ...state,
          ownedMobileProducts: newOwned,
        };

      case ActionTypes.POST_CONTACT_FORM_PENDING:
        return { ...state, loadingContact: true };

      case ActionTypes.POST_CONTACT_FORM_FULLFILLED:
        return { ...state, loadingContact: false, data: action.payload };

      case ActionTypes.rejected:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingContact: "false",
        };

      default:
        return state;
    }
  } else {
    return state;
  }
}
