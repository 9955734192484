import { API } from "aws-amplify";
import bugsnagClient from "../lib/bugsnag-client";
import StravaService from "./strava-service";
import GarminService from "./garmin-service";
import SuuntoService from "./suunto-service";
import VertActivityService from "./vert-activity-service";
import { standarizeActivities } from "../lib/activities-helper";
import CorosService from "./coros-service";

class TrailHeadService {
  static API_NAME = "apitrailhead";
  static USER_PROFILE_PATH = "/trailhead/:cognito_user_sub/profile";
  static TAGS_PATH = "/trailhead/tags";
  static PUBLIC_GROUPS_PATH = "/trailhead/publicgroups";
  static GET_GROUP_PATH = "/trailhead/publicgroup/:id";
  static JOIN_GROUP_PATH = "/trailhead/:cognito_user_sub/joingroup";
  static ACCEPT_JOIN_GROUP_PATH =
    "/trailhead/:cognito_user_sub/acceptjoingroup";
  static LEAVE_GROUP_PATH = "/trailhead/:cognito_user_sub/leavegroup";
  static ADD_GROUP_ACTIVITIES_PATH =
    "/trailhead/:cognito_user_sub/addgroupactivities";
  static POST_PATH = "/trailhead/:cognito_user_sub/post";
  static GET_POST_PATH = "/trailhead/post/:id";
  static POSTS_PATH = "/trailhead/posts/:lastDate";
  static GROUP_POSTS_PATH = "/trailhead/groupposts/:group/:lastDate";
  static PINNED_POSTS_PATH = "/trailhead/pinnedposts";

  static GET_EXPLORE_POST_PATH = "/trailhead/explorepost/:id";
  static EXPLORE_POSTS_PATH = "/trailhead/exploreposts/:lastDate";
  static GET_EXPLORE_FEATURED_POST_PATH = "/trailhead/explorefeaturedpost";

  static ADD_ALLEZ_PATH = "/trailhead/:cognito_user_sub/post/addallez";
  static ADD_REPORT_PATH = "/trailhead/:cognito_user_sub/post/addreport";
  static ADD_COMMENT_PATH = "/trailhead/:cognito_user_sub/post/addcomment";
  static DELETE_COMMENT_PATH =
    "/trailhead/:cognito_user_sub/post/deletecomment";

  static CONVERT_VIDEO_PATH = "/trailhead/mp4video/:id";
  static GET_EPIC_RACES = "/trailhead/epicRaces";
  static GET_USER_POSTS = "/trailhead/postsbyuser";

  static async getAthleteProfile(cognito_user_sub) {
    try {
      const response = await API.get(
        this.API_NAME,
        this.USER_PROFILE_PATH.replace(":cognito_user_sub", cognito_user_sub),
      );
      const { success, result, error } = response;
      if (success) {
        const { strava_athlete_id } = result;
        if (strava_athlete_id) {
          result.stravaStats = await this.getAthleteStats(strava_athlete_id);
        }
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getTags() {
    try {
      const response = await API.get(this.API_NAME, this.TAGS_PATH);
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getPublicGroups() {
    try {
      const response = await API.get(this.API_NAME, this.PUBLIC_GROUPS_PATH);
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async convertVideo(id) {
    try {
      const response = await API.get(
        this.API_NAME,
        this.CONVERT_VIDEO_PATH.replace(":id", id),
      );
      const { success, result, error } = response;
      if (success) {
        return success;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async addPost(cognito_user_sub, post) {
    try {
      const myInit = {
        body: post,
      };
      const response = await API.post(
        this.API_NAME,
        this.POST_PATH.replace(":cognito_user_sub", cognito_user_sub),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async joinGroup(cognito_user_sub, group) {
    try {
      const myInit = {
        body: { group },
      };
      const response = await API.post(
        this.API_NAME,
        this.JOIN_GROUP_PATH.replace(":cognito_user_sub", cognito_user_sub),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async acceptJoinGroup(cognito_user_sub, group) {
    try {
      const myInit = {
        body: { group },
      };
      const response = await API.post(
        this.API_NAME,
        this.ACCEPT_JOIN_GROUP_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async leaveGroup(cognito_user_sub, group) {
    try {
      const myInit = {
        body: { group },
      };
      const response = await API.post(
        this.API_NAME,
        this.LEAVE_GROUP_PATH.replace(":cognito_user_sub", cognito_user_sub),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async addGroupActivities(cognito_user_sub, group, activities) {
    try {
      const myInit = {
        body: { group, activities },
      };
      const response = await API.post(
        this.API_NAME,
        this.ADD_GROUP_ACTIVITIES_PATH.replace(
          ":cognito_user_sub",
          cognito_user_sub,
        ),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getGroup(group, isAdmin = false) {
    try {
      const response = await API.get(
        this.API_NAME,
        this.GET_GROUP_PATH.replace(":id", group),
        {
          queryStringParameters: {
            isAdmin: isAdmin ? 1 : 0,
          },
        },
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async deletePost(cognito_user_sub, post) {
    try {
      const myInit = {
        body: post,
      };
      const response = await API.del(
        this.API_NAME,
        this.POST_PATH.replace(":cognito_user_sub", cognito_user_sub),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getPosts(lastDate, searchPhrase, tags, showReported) {
    try {
      const response = await API.get(
        this.API_NAME,
        this.POSTS_PATH.replace(":lastDate", lastDate),
        {
          queryStringParameters: {
            searchPhrase,
            tags: tags && tags.length ? JSON.stringify(tags) : null,
            showReported,
          },
        },
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getGroupPosts(lastDate, group) {
    try {
      const response = await API.get(
        this.API_NAME,
        this.GROUP_POSTS_PATH.replace(":lastDate", lastDate).replace(
          ":group",
          group,
        ),
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getPinnedPosts() {
    try {
      const response = await API.get(this.API_NAME, this.PINNED_POSTS_PATH);
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getPost(id) {
    try {
      const response = await API.get(
        this.API_NAME,
        this.GET_POST_PATH.replace(":id", id),
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getExplorePosts(lastDate, type) {
    try {
      const response = await API.get(
        this.API_NAME,
        this.EXPLORE_POSTS_PATH.replace(":lastDate", lastDate),
        {
          queryStringParameters: {
            type,
          },
        },
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getExplorePost(id) {
    try {
      const response = await API.get(
        this.API_NAME,
        this.GET_EXPLORE_POST_PATH.replace(":id", id),
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getExploreFeaturedPost() {
    try {
      const response = await API.get(
        this.API_NAME,
        this.GET_EXPLORE_FEATURED_POST_PATH,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getAthleteStats(strava_athlete_id) {
    const result = await StravaService.getStats(strava_athlete_id);
    if (result) return result;
    else return undefined;
  }

  static async addAllez(cognito_user_sub, postData) {
    try {
      const myInit = {
        body: postData,
      };
      const response = await API.patch(
        this.API_NAME,
        this.ADD_ALLEZ_PATH.replace(":cognito_user_sub", cognito_user_sub),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async addReport(cognito_user_sub, postData) {
    try {
      const myInit = {
        body: postData,
      };
      const response = await API.patch(
        this.API_NAME,
        this.ADD_REPORT_PATH.replace(":cognito_user_sub", cognito_user_sub),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async addComment(cognito_user_sub, comment) {
    try {
      const myInit = {
        body: comment,
      };
      const response = await API.patch(
        this.API_NAME,
        this.ADD_COMMENT_PATH.replace(":cognito_user_sub", cognito_user_sub),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async deleteComment(cognito_user_sub, comment) {
    try {
      const myInit = {
        body: comment,
      };
      const response = await API.del(
        this.API_NAME,
        this.DELETE_COMMENT_PATH.replace(":cognito_user_sub", cognito_user_sub),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async editPost(cognito_user_sub, editData) {
    try {
      const myInit = {
        body: editData,
      };
      const response = await API.patch(
        this.API_NAME,
        this.POST_PATH.replace(":cognito_user_sub", cognito_user_sub),
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getEpicRaces() {
    try {
      const response = await API.get(this.API_NAME, this.GET_EPIC_RACES);
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getUserPosts(cognito_user_sub, lastDate) {
    try {
      const response = await API.get(
        this.API_NAME,
        `${this.GET_USER_POSTS}/${cognito_user_sub}/${lastDate}`,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getAthleteActivities(params) {
    if (params && params.oauth_token) {
      const result = await GarminService.getActivities(params);
      if (result && result.length > 0) return result;
      else return undefined;
    } else if (params && params.access_token) {
      const result = await SuuntoService.getActivities(params);
      if (result && result.length > 0)
        return standarizeActivities(result, "suunto");
      else return undefined;
    } else if (params && params.coros_auth) {
      const result = await CorosService.getActivities(params);
      if (result && result.length > 0)
        return standarizeActivities(result, "coros");
      else return undefined;
    } else if (params && params.strava_athlete_id) {
      const result = await StravaService.getActivities(params);
      if (result && result.length > 0) return result;
      else return undefined;
    } else if (params) {
      const result = await VertActivityService.getActivities(params);
      if (result && result.length > 0) return result;
      else return undefined;
    }
  }
}

export default TrailHeadService;
