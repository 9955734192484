import ActionTypes from "../constants/action-types";

const initialState = {
  postId: null,
  replyId: null,
};

const communityReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.COMMUNITY_SET_REPLY_ID:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default communityReducer;
