import React, { memo } from "react";
import { ButtonGroup, Button } from "@material-ui/core";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  getAdminData,
  getAdminCategoriesData,
  getAdminCoachesData,
} from "../../../actions/admin-action";
import { handleDifference } from "./utils";

const filters = ["Today", "Yesterday", "7D", "30D", "3M", "6M", "12M"];

const TimeRange = ({
  currentRange,
  currentStatus,
  isEmpty,
  setCurrentRange,
  currentOption,
}) => {
  const dispatch = useDispatch();

  const getAllStatuses = async (date, coaches = false) => {
    await dispatch(getAdminData("active", date, coaches));
    await dispatch(getAdminData("in_trial", date, coaches));
    await dispatch(getAdminData("non_renewing", date, coaches));
  };

  const getAllAdminCoaches = async (date) => {
    await dispatch(getAdminCoachesData("active", date, true));
    await dispatch(getAdminCoachesData("in_trial", date, true));
    await dispatch(getAdminCoachesData("non_renewing", date, true));
  };

  const getAllAdminCategories = async (date) => {
    await dispatch(getAdminCategoriesData("active", date));
    await dispatch(getAdminCategoriesData("in_trial", date));
    await dispatch(getAdminCategoriesData("non_renewing", date));
  };

  const handleDateRange = (value) => {
    if (value === currentRange.name) return;
    if (value === "Today") {
      const today = dayjs().format("YYYY-MM-DD");
      isEmpty
        ? getAllStatuses(today)
        : dispatch(getAdminData(currentStatus.name, today));
      if (currentOption === 1)
        isEmpty
          ? getAllAdminCoaches(today)
          : dispatch(getAdminCoachesData(currentStatus.name, today));

      if (currentOption === 2)
        isEmpty
          ? getAllAdminCategories(today)
          : dispatch(getAdminCategoriesData(currentStatus.name, today));

      setCurrentRange({ name: value, date: today });
    }

    if (value === "Yesterday") {
      const yesterday = handleDifference(1, "days");
      isEmpty
        ? getAllStatuses(yesterday)
        : dispatch(getAdminData(currentStatus.name, yesterday));
      if (currentOption === 1)
        isEmpty
          ? getAllAdminCoaches(yesterday)
          : dispatch(getAdminCoachesData(currentStatus.name, yesterday));

      if (currentOption === 2)
        isEmpty
          ? getAllAdminCategories(yesterday)
          : dispatch(getAdminCategoriesData(currentStatus.name, yesterday));

      setCurrentRange({ name: value, date: yesterday });
    }

    if (value === "7D") {
      const difference = handleDifference(7, "days");
      isEmpty
        ? getAllStatuses(difference)
        : dispatch(getAdminData(currentStatus.name, difference));
      if (currentOption === 1)
        isEmpty
          ? getAllAdminCoaches(difference)
          : dispatch(getAdminCoachesData(currentStatus.name, difference));

      if (currentOption === 2)
        isEmpty
          ? getAllAdminCategories(difference)
          : dispatch(getAdminCategoriesData(currentStatus.name, difference));

      setCurrentRange({ name: value, date: difference });
    }

    if (value === "30D") {
      const difference = handleDifference(1, "month");
      isEmpty
        ? getAllStatuses(difference)
        : dispatch(getAdminData(currentStatus.name, difference));
      if (currentOption === 1)
        isEmpty
          ? getAllAdminCoaches(difference)
          : dispatch(getAdminCoachesData(currentStatus.name, difference));

      if (currentOption === 2)
        isEmpty
          ? getAllAdminCategories(difference)
          : dispatch(getAdminCategoriesData(currentStatus.name, difference));

      setCurrentRange({ name: value, date: difference });
    }

    if (value === "3M") {
      const difference = handleDifference(3, "month");
      isEmpty
        ? getAllStatuses(difference)
        : dispatch(getAdminData(currentStatus.name, difference));
      setCurrentRange({ name: value, date: difference });
      if (currentOption === 1)
        isEmpty
          ? getAllAdminCoaches(difference)
          : dispatch(getAdminCoachesData(currentStatus.name, difference));

      if (currentOption === 2)
        isEmpty
          ? getAllAdminCategories(difference)
          : dispatch(getAdminCategoriesData(currentStatus.name, difference));
      setCurrentRange({ name: value, date: difference });
    }

    if (value === "6M") {
      const difference = handleDifference(6, "month");
      isEmpty
        ? getAllStatuses(difference)
        : dispatch(getAdminData(currentStatus.name, difference));
      if (currentOption === 1)
        isEmpty
          ? getAllAdminCoaches(difference)
          : dispatch(getAdminCoachesData(currentStatus.name, difference));

      if (currentOption === 2)
        isEmpty
          ? getAllAdminCategories(difference)
          : dispatch(getAdminCategoriesData(currentStatus.name, difference));
      setCurrentRange({ name: value, date: difference });
    }

    if (value === "12M") {
      const difference = handleDifference(12, "month");
      isEmpty
        ? getAllStatuses(difference)
        : dispatch(getAdminData(currentStatus.name, difference));
      if (currentOption === 1)
        isEmpty
          ? getAllAdminCoaches(difference)
          : dispatch(getAdminCoachesData(currentStatus.name, difference));

      if (currentOption === 2)
        isEmpty
          ? getAllAdminCategories(difference)
          : dispatch(getAdminCategoriesData(currentStatus.name, difference));
      setCurrentRange({ name: value, date: difference });
    }
  };

  return (
    <ButtonGroup aria-label="outlined primary button group">
      {filters.map((item) => (
        <Button
          key={item}
          onClick={() => handleDateRange(item)}
          style={{
            backgroundColor:
              item === currentRange.name ? "rgba(0, 0, 0, 0.05)" : "",
          }}
        >
          {item}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default memo(TimeRange);
