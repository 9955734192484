import React, { memo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";

const DeleteConfirmationDialog = ({
  entityName,
  onCancel,
  onConfirm,
  t,
  message = null,
  title = null,
}) => {
  return (
    <div>
      <Dialog
        open={true}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title || t("Delete Confirmation")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message || `${t("Are u sure")} ${t(entityName)}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirm} color="primary">
            {t("Yes")}
          </Button>
          <Button onClick={onCancel}>{t("No")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withTranslation("dashboard")(memo(DeleteConfirmationDialog));
