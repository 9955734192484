import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';

class EnhancedDeleteIcon extends Component {
    render() {
        const { deleting, linkTo } = this.props;
        return (
            deleting ? <Tooltip title="Delete">
                <IconButton aria-label="Delete">
                    <CircularProgress color="primary" size={25} />
                </IconButton>
            </Tooltip>
                : linkTo ? <Link to={linkTo}>
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Link> : null
        );
    }
}

EnhancedDeleteIcon.propTypes = {
    deleting: PropTypes.bool,
    linkTo: PropTypes.string
}

export default EnhancedDeleteIcon;