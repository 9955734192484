import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { palette } from "../../../theme/palette";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles(() => ({
  card: {
    background: palette.white,
    borderRadius: "8px",
    width: "275px",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
}));

const PopularVideosCardSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Skeleton variant="rounded" width="100%" height="125px" />
      <Box
        style={{
          padding: "12px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Skeleton width="60%" />
          <Skeleton width="80%" />
        </Box>
      </Box>
    </Box>
  );
};

export default PopularVideosCardSkeleton;
