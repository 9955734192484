import React from "react";
import { SvgIcon } from "@material-ui/core";

const DataIcon = ({ width = 18, height = 18, state = "filled" }) => {
  let fill = "none";
  let stroke = "none";

  if (state === "highlighted") {
    stroke = "#12151B";
  } else if (state === "filled") {
    fill = "#12151B";
  } else if (state === "outline") {
    stroke = "#12151B";
  }

  return (
    <SvgIcon width={width} height={height}>
      <svg
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 15.75H2C1.72386 15.75 1.5 15.5261 1.5 15.25V2.8125C1.5 2.50184 1.75184 2.25 2.0625 2.25C2.37316 2.25 2.625 2.50184 2.625 2.8125V14.625H4.5V8C4.5 7.72386 4.72386 7.5 5 7.5H7C7.27614 7.5 7.5 7.72386 7.5 8V14.625H9V5C9 4.72386 9.22386 4.5 9.5 4.5H11.5C11.7761 4.5 12 4.72386 12 5V14.625H13.5V11C13.5 10.7239 13.7239 10.5 14 10.5H16C16.2761 10.5 16.5 10.7239 16.5 11V15.75Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1"
        />
      </svg>
    </SvgIcon>
  );
};

export default DataIcon;
