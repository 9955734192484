import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import PropTypes from "prop-types";
import MenuList from "../../common/menu-list";
import MenuListItem from "../../common/menu-list-item";

const styles = (theme) => ({
  container: {
    alignSelf: "flex-end",
  },
  paper: {
    background: theme.palette.background.inputText,
  },
  button: {
    // color: theme.palette.common.black,
    padding: "6px 8px",
    fontWeight: "normal",
    [theme.breakpoints.down("sm")]: {
      // color: theme.palette.common.white
    },
  },
  popper: {
    zIndex: 1,
  },
});

class ActionsMenu extends Component {
  state = {
    open: false,
    addDialogOpen: false,
    addNextDialogOpen: false,
    removeDialogOpen: false,
    addEasyDialogOpen: false,
    addEasyNextDialogOpen: false,
    removeChainedPlanDialogOpen: false,
    chainedToDelete: null,
  };

  handleToggle = () => {
    this.setState({
      ...this.state,
      open: !this.state.open,
    });
  };

  handleClose = async (id, index = -1) => {
    const {
      onAddWeek,
      onRemoveWeek,
      onAddWeekNext,
      onAddEasyWeek,
      onAddEasyWeekNext,
      onRemoveChainedPlan,
    } = this.props;

    if (id) {
      switch (id) {
        case "addeasyweek":
          onAddEasyWeek();
          break;
        case "addeasyweeknext":
          onAddEasyWeekNext();
          break;
        case "addweek":
          onAddWeek();
          break;
        case "addweeknext":
          onAddWeekNext();
          break;
        case "removeweek":
          onRemoveWeek();
          break;
        case "removechainedplan":
          onRemoveChainedPlan(index);
          break;
        default:
          break;
      }
    }

    this.setState({
      ...this.state,
      open: false,
    });
  };

  handleOpenAdd = () => {
    this.setState({
      ...this.state,
      addDialogOpen: true,
    });
  };

  handleCloseAdd = () => {
    this.setState({
      ...this.state,
      addDialogOpen: false,
    });
  };

  handleOpenAddNext = () => {
    this.setState({
      ...this.state,
      addNextDialogOpen: true,
    });
  };

  handleCloseAddNext = () => {
    this.setState({
      ...this.state,
      addNextDialogOpen: false,
    });
  };

  handleOpenAddEasy = () => {
    this.setState({
      ...this.state,
      addEasyDialogOpen: true,
    });
  };

  handleCloseAddEasy = () => {
    this.setState({
      ...this.state,
      addEasyDialogOpen: false,
    });
  };

  handleOpenAddEasyNext = () => {
    this.setState({
      ...this.state,
      addEasyNextDialogOpen: true,
    });
  };

  handleCloseAddEasyNext = () => {
    this.setState({
      ...this.state,
      addEasyNextDialogOpen: false,
    });
  };

  handleOpenRemove = () => {
    this.setState({
      ...this.state,
      removeDialogOpen: true,
    });
  };

  handleCloseRemove = () => {
    this.setState({
      ...this.state,
      removeDialogOpen: false,
    });
  };

  handleOpenRemoveChainedPlan = (index) => {
    this.setState({
      ...this.state,
      removeChainedPlanDialogOpen: true,
      chainedToDelete: index,
    });
  };

  handleCloseRemoveChainedPlan = () => {
    this.setState({
      ...this.state,
      removeChainedPlanDialogOpen: false,
      chainedToDelete: null,
    });
  };

  render() {
    const { classes, chainedPlans } = this.props;
    const {
      open,
      addDialogOpen,
      removeDialogOpen,
      addNextDialogOpen,
      addEasyDialogOpen,
      addEasyNextDialogOpen,
      removeChainedPlanDialogOpen,
      chainedToDelete,
    } = this.state;

    return (
      <div className={classes.container}>
        <Button
          className={classes.button}
          buttonRef={(node) => {
            this.anchorEl = node;
          }}
          aria-owns={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          <TuneIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          className={classes.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener
                  onClickAway={this.handleClose.bind(this, null)}
                >
                  <MenuList>
                    <MenuListItem onClick={this.handleOpenAddEasy}>
                      {"Add Easy Week Here"}
                    </MenuListItem>
                    <MenuListItem onClick={this.handleOpenAddEasyNext}>
                      {"Add Easy Week Next Week"}
                    </MenuListItem>
                    <MenuListItem onClick={this.handleOpenAdd}>
                      {"Add Empty Week Here"}
                    </MenuListItem>
                    <MenuListItem onClick={this.handleOpenAddNext}>
                      {"Add Empty Week Next Week"}
                    </MenuListItem>
                    <MenuListItem onClick={this.handleOpenRemove}>
                      {"Remove This Week"}
                    </MenuListItem>
                    {chainedPlans && chainedPlans.length
                      ? chainedPlans.map((w, i) => {
                          return (
                            <MenuListItem
                              onClick={() =>
                                this.handleOpenRemoveChainedPlan(i)
                              }
                              key={i}
                            >
                              {`Remove Chained Plan starting from ${w.startingDate}`}
                            </MenuListItem>
                          );
                        })
                      : ""}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <Dialog
          open={addDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseAdd}
        >
          <DialogTitle>
            {
              "Are you sure you want to add a week? An empty week will be added and the current week and all the next ones will be moved one week to the future"
            }
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose("addweek");
                this.handleCloseAdd();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={this.handleCloseAdd} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={addNextDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseAddNext}
        >
          <DialogTitle>
            {
              "Are you sure you want to add a week? An empty week will be added after the current week and all the next ones will be moved one week to the future"
            }
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose("addweeknext");
                this.handleCloseAddNext();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={this.handleCloseAddNext} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={addEasyDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseAddEasy}
        >
          <DialogTitle>
            {
              "Are you sure you want to add a easy week? A easy week will be added and the current week and all the next ones will be moved one week to the future"
            }
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose("addeasyweek");
                this.handleCloseAddEasy();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={this.handleCloseAddEasy} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={addEasyNextDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseAddEasyNext}
        >
          <DialogTitle>
            {
              "Are you sure you want to add a easy week? A easy week will be added after the current week and all the next ones will be moved one week to the future"
            }
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose("addeasyweeknext");
                this.handleCloseAddEasyNext();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={this.handleCloseAddEasyNext} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={removeDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseRemove}
        >
          <DialogTitle>
            {
              "Are you sure you want to remove this week? The current week and all its information (energy level, is done) will be deleted and all the next weeks will be moved one week to the past"
            }
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose("removeweek");
                this.handleCloseRemove();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={this.handleCloseRemove} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={removeChainedPlanDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseRemoveChainedPlan}
        >
          <DialogTitle>
            {
              "Are you sure you want to remove this chained plan? The chained plan and all its information (energy level, is done) will be deleted"
            }
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose("removechainedplan", chainedToDelete);
                this.handleCloseRemoveChainedPlan();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={this.handleCloseRemoveChainedPlan} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ActionsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onAddWeek: PropTypes.func,
  onAddWeekNext: PropTypes.func,
  onRemoveWeek: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(ActionsMenu);
