import { connect } from "react-redux";

import * as userPlansActions from "../actions/user-plans-action";

const mapState = (state) => ({
  userPlans: state.userPlans,
});

const mapDispatch = {
  ...userPlansActions,
};

const ConnectUserPlans = connect(mapState, mapDispatch);

export default ConnectUserPlans;
