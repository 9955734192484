import jimp from "jimp";
import { Storage } from "aws-amplify";

export const getBase64 = (file, resize = true, size = 150) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      try {
        const image = await jimp.read(reader.result);
        if (resize) await image.resize(size, jimp.AUTO);
        const data = await image.getBase64Async(jimp.MIME_JPEG);
        const fileNameArray = file.name ? file.name.split(".") : undefined;

        resolve({
          image: data,
          extension: fileNameArray[fileNameArray.length - 1],
        });
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const getDisplayErrorMessage = (err, t) => {
  let errorMessage = "";

  if (typeof err === typeof string) {
    errorMessage = err;
  } else errorMessage = err.message || err.inputMessage || err.toString();

  switch (errorMessage) {
    case "Invalid Size":
      return t("Sorry, your image needs to be smaller than maxSize");
    case "Invalid File":
      return t("Invalid file, please try again");
    default:
      return errorMessage;
  }
};

export const getImage = async (image) => {
  try {
    const result = await Storage.get(image, {
      level: "public",
      cacheControl: "no-cache",
    });
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

export const getRandomHexColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215);
  const hexColor = randomColor.toString(16).padStart(6, "0");
  return `#${hexColor}`;
};

export const isEmptyHtml = (object) => {
  if (!object) return false;
  return object.hasOwnProperty("__html") && object.__html === "";
};
