import { Preferences } from "@capacitor/preferences";

export const useCapacitorStorage = () => {
  /**
   *
   * @param {string} key
   * @param {string} value
   */
  const setItem = async (key, value) => {
    await Preferences.set({
      key,
      value,
    });
  };

  /**
   *
   * @param {string} key
   * @returns value from storage
   */
  const getItem = async (key) => {
    const { value } = await Preferences.get({ key });
    return value;
  };

  /**
   *
   * @param {string} key
   */
  const removeItem = async (key) => {
    await Preferences.remove({ key });
  };

  return { setItem, getItem, removeItem };
};
