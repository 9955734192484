import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TablePagination,
  Select,
  MenuItem,
  TableSortLabel,
  Badge,
  Grow,
  Popper,
  Button,
  ClickAwayListener,
  Switch,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { dateFormat } from "../../lib/date-helper";
import { getSubcategoryTitle } from "../../lib/subcategory-helper";
import {
  addVisitedPage,
  assignCoachToAthlete,
  assignStrengthCoachToAthlete,
  athletesSearch,
  changeSort,
  sendBulkMessage,
  setErrorMessage,
} from "../../actions/athletes-action";
import AthletePlanAssignCoach from "./athlete-plan/athlete-plan-assign-coach";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import { connect } from "react-redux";
import { getEnergyLevelRate, getDoneRate } from "../../lib/rates-helper";
import MenuList from "../common/menu-list";
import { levelScale } from "../../constants/race-counts";
import userGroups from "../../constants/user-groups";

const styles = (theme) => ({
  container: {
    display: "block",
    padding: 20,
    overflow: "scroll",
    width: "calc(100vw - 50px)",
  },
  tableContainer: {
    background: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
  },
  card: {
    textAlign: "left",
    marginBottom: theme.spacing.unit,
  },
  cardActionArea: {
    padding: theme.spacing.unit,
  },
  chipsContainer: {
    padding: 0,
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    fontWeight: "bold",
    margin: 5,
    marginLeft: 0,
  },
  smallChip: {
    fontSize: "x-small",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  containerMenu: {
    width: "fit-content",
  },
  paper: {
    background: "#FCFCFC",
    borderRadius: 8,
    boxShadow: "5px 3px 10px rgb(33 81 209 / 10%)",
    padding: "5px 0",
  },
  buttonMenu: {
    padding: "0",
    fontWeight: "normal",
    minWidth: 0,
  },
  menuItem: {
    padding: "6px 8px",
    fontWeight: "normal",
    alignItems: "center",
  },
  menuItemLabel: {
    textTransform: "initial",
    textAlign: "left",
    justifyContent: "left",
  },
  menuItemLabelSelected: {
    textTransform: "initial",
    textAlign: "left",
    justifyContent: "left",
    color: "#FF6327",
  },
  popper: {
    zIndex: 1,
    top: "40px !important",
    left: "initial !important",
    right: "0 !important",
    transform: "translate(0, 0) !important",
  },

  flexContainer: {
    display: "flex",
    alignItems: "center",
  },

  align: {
    display: "flex",
    alignItems: "center",
  },

  emojiInTable: {
    marginRight: 5,
  },

  sendBulkMessageButtonContainer: {
    marginLeft: "auto",
  },
  exclamation: {
    color: "red",
    fontSize: 22,
    fontWeight: "bold",
    marginRight: "5px",
  },
  emoji: {
    width: 18,
    margin: "0 3px",
  },

  filter: {
    marginLeft: 10,
  },
});

class AthletesList extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    orderBy: "athlete",
    order: "desc",
    openDone: false,
    doneGran: "plan",

    openEnergy: false,
    energyGran: "plan",

    openLoad: false,
    loadGran: "plan",

    isOpenSendBulkMessage: false,

    selectedAthletes: [],
    text: null,
  };

  handleChangePage = (event, newPage) => {
    const { athletes, auth } = this.props;
    const { currentFilter, searchResult } = athletes;
    const { searchPhrase } = currentFilter;
    const filters = Object.keys(currentFilter).filter(
      (key) => key !== "searchPhrase",
    );
    const { currentUserGroups } = auth;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;

    const { data, visitedPages } = searchResult || {};

    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === userGroups.USER_GROUP_ADMIN,
      ) > -1;
    if (isAdmin && filters.length === 0 && !searchPhrase) {
      this.props.dispatch(
        setErrorMessage("Please fill out filters to search athletes"),
      );
    } else {
      if (newPage > visitedPages) {
        this.props.dispatch(athletesSearch(currentFilter, isAdmin, sub, data));
        this.props.dispatch(addVisitedPage(newPage));
      }
      this.props.dispatch(changeSort({ page: newPage }));
    }
  };

  handleChangeRowsPerPage = (event) => {
    this.props.dispatch(
      changeSort({ page: 0, rowsPerPage: +event.target.value }),
    );
  };

  handleRequestSort = (property) => {
    const { athletes } = this.props;
    const { searchResult } = athletes;
    const { orderBy, order } = searchResult || {};
    const isAsc = orderBy === property && order === "asc";
    this.props.dispatch(
      changeSort({ order: isAsc ? "desc" : "asc", orderBy: property }),
    );
  };

  verifyNextWeekRace = (date) => {
    if (date == "N/A") return;

    const today = moment();
    const raceDateMoment = moment(date, "DD/MM/YYYY");
    const result = moment.duration(raceDateMoment.diff(today)).asDays();
    const diference = Math.floor(result);

    if (diference <= 6) {
      return true;
    }
    return false;
  };

  formatData = (data) => {
    const { subcategories } = this.props;

    let commentsToRead = 0;
    const formatedData = data.map((value) => {
      const {
        cognito_user_sub,
        cognito_user_name,
        cognito_user_email,
        lastLogin,
        lastViewed,
        currentTraining,
        nextRace,
        nextRaces,
        subscription,
        coach_cognito_user_sub,
        unread,
        latestLoadRate,
        lastChat,
        energyLevelRate,
        doneRate,
        modifications,
        planName: planNameValue,
        currentWeek: currentweekValue,
        durationInWeeks: durationInWeeksValue,
        strength_coach_cognito_user_sub,
      } = value;
      const { durationInWeeks, startingDate, days } = currentTraining || {};

      let racedLastWeek = false;
      let racingNextWeek = false;
      let allRaces = [];

      if (nextRace) {
        const today = moment();
        const raceDateMoment = moment(nextRace.raceDate, "DD/MM/YYYY");
        const daysToRace = raceDateMoment.diff(today, "day");
        if (daysToRace >= 0) {
          allRaces.push(nextRace);
        }

        if (
          raceDateMoment.isAfter(moment(today).add(-6, "days")) &&
          raceDateMoment.isBefore(moment(today))
        ) {
          racedLastWeek = true;
        }

        if (
          raceDateMoment.isAfter(moment(today)) &&
          raceDateMoment.isBefore(moment(today).add(6, "days"))
        ) {
          racingNextWeek = true;
        }
      }

      if (nextRaces && nextRaces.length > 0) {
        nextRaces.forEach((element) => {
          const today = moment();
          const raceDateMoment = moment(element.raceDate, "DD/MM/YYYY");
          const daysToRace = raceDateMoment.diff(today, "day");
          if (daysToRace >= 0) {
            allRaces.push(element);
          }

          if (
            raceDateMoment.isAfter(moment(today).add(-6, "days")) &&
            raceDateMoment.isBefore(moment(today))
          ) {
            racedLastWeek = true;
          }

          if (
            raceDateMoment.isAfter(moment(today).startOf("isoWeek")) &&
            raceDateMoment.isBefore(moment(today))
          ) {
            racingNextWeek = true;
          }
        });
      }

      allRaces = allRaces.sort((a, b) => {
        return moment(a.raceDate, "DD/MM/YYYY").diff(
          moment(b.raceDate, "DD/MM/YYYY"),
        );
      });

      const { status, plan_id } = subscription || {};

      let { selectedPlan } = currentTraining || {};

      if (!selectedPlan && value && value.selectedPlan) {
        selectedPlan = value.selectedPlan;
      }

      const today = moment();
      const startingDateMoment = moment(startingDate, dateFormat);
      const currentDay = today.diff(startingDateMoment, "days") + 1;
      let currentWeek = "";
      let durationWeeks = 0;

      if (durationInWeeksValue) {
        durationWeeks = durationInWeeksValue;
      }

      if (durationInWeeks) {
        durationWeeks = durationInWeeks;
      }

      const currentWeekN = Math.min(
        Math.ceil((currentDay > 0 ? currentDay : 0) / 7),
        durationWeeks,
      );

      let planName;

      const currentWeekDate = moment(startingDate, dateFormat)
        .startOf("isoWeek")
        .add(currentWeekN, "weeks");

      if (selectedPlan && selectedPlan !== "premium") {
        let subcategory =
          value && value.currentTraining && value.currentTraining.subcategory
            ? value.currentTraining.subcategory
            : +value.subcategory;

        if (
          value &&
          value.currentTraining &&
          value.currentTraining.chainedPlans &&
          value.currentTraining.chainedPlans.length
        ) {
          for (
            let index = 0;
            index < value.currentTraining.chainedPlans.length;
            index++
          ) {
            const element = value.currentTraining.chainedPlans[index];
            if (
              moment(currentWeekDate)
                .subtract(1, "week")
                .isSameOrAfter(moment(element.startingDate, dateFormat), "days")
            ) {
              subcategory = element.subcategory;
            }
          }
        }

        planName =
          subcategories &&
          subcategories.data &&
          subcategories.data.length &&
          subcategory
            ? getSubcategoryTitle(subcategories.data, subcategory, "en")
            : selectedPlan === "free"
            ? "Free"
            : "Explore";
      }

      if (selectedPlan === "premium") {
        planName = "Premium";
      }

      if (planNameValue) {
        planName = planNameValue.en;
      }

      currentWeek = isNaN(currentWeekN) ? -1 : currentWeekN.toString();

      let endMoment = moment();
      if (days) {
        const workouts = days.filter((d) => d !== null);

        if (
          workouts &&
          workouts.length &&
          workouts[workouts.length - 1] &&
          workouts[workouts.length - 1][0] &&
          workouts[workouts.length - 1][0].plannedDate
        ) {
          endMoment = moment(
            workouts[workouts.length - 1][0].plannedDate,
            "DD/MM/YYYY",
          );

          if (endMoment.isBefore(moment())) {
            currentWeek = 999;
          }
        }
      }

      if (currentweekValue) {
        currentWeek = currentweekValue;
      }

      const d = new Date();
      d.setDate(d.getDate() - 14);

      const newModifications =
        modifications && modifications.length
          ? modifications.filter((m) => m.date > d.getTime())
          : null;

      return {
        code: cognito_user_sub,
        athlete: cognito_user_name,
        email: cognito_user_email,
        lastLogin: parseFloat(lastLogin),
        comments: unread,
        lastViewed: lastViewed ? moment(lastViewed).fromNow() : "N/A",
        lastChat: lastChat ? moment(lastChat.dateTime).fromNow() : "N/A",
        plan: planName,
        subscriptionPlan: plan_id,
        week: Number(currentWeek),
        streak: doneRate ? Math.round(doneRate[this.state.doneGran]) : "N/A",
        energy: energyLevelRate
          ? Math.round(energyLevelRate[this.state.energyGran])
          : "N/A",
        load: !latestLoadRate
          ? "N/A"
          : Math.round(latestLoadRate[this.state.loadGran]),
        nextGoal: allRaces && allRaces[0] ? allRaces[0].raceDate : "N/A",
        status,
        userPlan: value,
        startingDate,
        coach_cognito_user_sub,
        strength_coach_cognito_user_sub,
        cognito_user_sub,
        isLastWeek: durationWeeks
          ? currentWeek === parseFloat(durationWeeks) || currentWeek === 999
          : true,
        racedLastWeek,
        racingNextWeek,
        modification:
          newModifications && newModifications.length
            ? newModifications[0].name
            : "",
        numModifications:
          newModifications && newModifications.length
            ? newModifications.length
            : 0,
      };
    });
    return formatedData;
  };

  handleAssignCoach = (coach_cognito_user_sub, cognito_user_sub) => {
    if (cognito_user_sub) {
      this.props.dispatch(
        assignCoachToAthlete({ cognito_user_sub, coach_cognito_user_sub }),
      );
    }
  };

  handleAssignStrengthCoach = (coach_cognito_user_sub, cognito_user_sub) => {
    if (cognito_user_sub) {
      this.props.dispatch(
        assignStrengthCoachToAthlete({
          cognito_user_sub,
          coach_cognito_user_sub,
        }),
      );
    }
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };

  stableSort = (array, comparator, orderBy = "", order) => {
    const withNextGoal = array.filter((item) => item.nextGoal !== "N/A");
    const withouthNextGoal = array.filter((item) => item.nextGoal === "N/A");

    if (orderBy && orderBy == "nextGoal") {
      const result = withNextGoal.sort((a, b) => {
        const aDate = moment(a.nextGoal, "DD/MM/YYYY").valueOf();
        const bDate = moment(b.nextGoal, "DD/MM/YYYY").valueOf();
        return order == "asc" ? aDate - bDate : bDate - aDate;
      });
      return [...result, ...withouthNextGoal];
    }

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  handleOpenDone(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      ...this.state,
      openDone: true,
    });
  }

  handleCloseDone(event, doneGran = null) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      ...this.state,
      openDone: false,
      doneGran: doneGran ? doneGran : this.state.doneGran,
    });
  }

  handleOpenEnergy(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      ...this.state,
      openEnergy: true,
    });
  }

  handleCloseEnergy(event, energyGran = null) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      ...this.state,
      openEnergy: false,
      energyGran: energyGran ? energyGran : this.state.energyGran,
    });
  }

  handleOpenLoad(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      ...this.state,
      openLoad: true,
    });
  }

  handleCloseLoad(event, loadGran = null) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      ...this.state,
      openLoad: false,
      loadGran: loadGran ? loadGran : this.state.loadGran,
    });
  }

  handleSelectedChange(event, athlete) {
    const selected = event.target.checked;

    if (selected) {
      this.setState({
        ...this.state,
        selectedAthletes: [...this.state.selectedAthletes, athlete],
      });
    } else {
      this.setState({
        ...this.state,
        selectedAthletes: this.state.selectedAthletes.filter(
          (athlete) => athlete.cognito_user_sub !== selected.cognito_user_sub,
        ),
      });
    }
  }

  handleWeekValue = (value) => {
    if (value === 999) return "finished";
    if (value === -1) return "N/A";
    return value;
  };

  handleOpenSendBulkMessage() {
    this.setState({
      ...this.state,
      isOpenSendBulkMessage: true,
    });
  }

  handleCloseSendBulkMessage() {
    this.setState({
      ...this.state,
      isOpenSendBulkMessage: false,
    });
  }

  handleSendBulkMessage() {
    const { userPlans, athletes } = this.props;
    const { currentPlan } = userPlans;
    const { image, cognito_user_name } = currentPlan;
    const { sendingBulkMessage } = athletes;

    if (!sendingBulkMessage) {
      const message = {
        text: this.state.text,
        date: moment().format(dateFormat),
        dateTime: moment().unix(),
        type: 0,
        senderImage: image,
        senderName: cognito_user_name,
      };

      this.setState({
        ...this.state,
        isOpenSendBulkMessage: false,
        text: "",
      });

      this.props.dispatch(
        sendBulkMessage({
          message: message,
          recipients: this.state.selectedAthletes.map(
            (athlete) => athlete.cognito_user_sub,
          ),
        }),
      );
    }
  }

  handleTextChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          ...this.state,
          text: value || "",
        });
      }
    }
  };

  render() {
    const {
      classes,
      athletesPlans,
      coachesList = [],
      onAthleteSelect,
      onFilterChange,
      weekFilter,
      subscriptionFilter,
      newFilter,
      isAdmin,
      assigningCoach,
      onOpenChat,
      athletes,
      sendingBulkMessage,
    } = this.props;

    const {
      searchResult,
      strengthCoaches = [],
      loadingStrengthAssign,
      currentFilter,
      loading,
    } = athletes;
    const { page, rowsPerPage, orderBy, order } = searchResult || {};
    const { exclusiveStartKey } = currentFilter || {};

    const columns = [
      { id: "athlete", label: "Athlete" },
      {
        id: "plan",
        label: "Training Plan",
      },
      {
        id: "week",
        label: "Week",
      },
      {
        id: "lastLogin",
        label: "Last Login",
      },

      {
        id: "lastViewed",
        label: "Last Checked",
      },

      {
        id: "lastChat",
        label: "Last Message",
      },

      {
        id: "streak",
        label: "Mark as done rate",
      },
      {
        id: "load",
        label: "Training load rate",
      },
      {
        id: "energy",
        label: "Energy level rate",
      },

      {
        id: "nextGoal",
        label: "Next Goal",
      },

      {
        id: "subscriptionPlan",
        label: "Subscription Plan",
      },

      {
        id: "modification",
        label: "Modifications",
      },

      {
        id: "comments",
        label: "",
      },
    ];

    const {
      openDone,
      openEnergy,
      openLoad,
      selectedAthletes,
      isOpenSendBulkMessage,
      text,
    } = this.state;

    const rows = this.formatData(athletesPlans);

    const weekOptions = [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
    ];

    const subscriptionOptions = [
      "active",
      "in_trial",
      "expired",
      "cancelled",
      "non_renewing",
    ];

    rows.forEach((r) => {
      if (r.week > weekOptions[weekOptions.length - 1]) {
        for (let i = weekOptions[weekOptions.length - 1]; i <= r.week; i++) {
          weekOptions.push(i);
        }
      }
    });

    return (
      <div className={classes.container}>
        <div className={classes.flexContainer}>
          {!isAdmin ? (
            <div className={classes.flexContainer}>
              <Typography variant="body1" className={classes.filterText}>
                New Customers
              </Typography>
              <Switch
                color="default"
                checked={newFilter === "all"}
                onChange={(event) =>
                  onFilterChange({ newF: event.target.checked ? "all" : "new" })
                }
              />
              <Typography variant="body1" className={classes.filterText}>
                Everybody
              </Typography>
            </div>
          ) : (
            ""
          )}

          {!isAdmin ? (
            <Select
              id="week-filter-select"
              value={weekFilter ? weekFilter : 0}
              onChange={(event) =>
                onFilterChange({ week: +event.target.value })
              }
              className={classes.filter}
            >
              <MenuItem value={0}>any week</MenuItem>
              {weekOptions.map((w) => (
                <MenuItem value={w} key={w}>
                  week {w}
                </MenuItem>
              ))}
              <MenuItem value={-1}>finished plan</MenuItem>
            </Select>
          ) : (
            ""
          )}

          {!isAdmin ? (
            <Select
              id="subscription-filter-select"
              value={subscriptionFilter ? subscriptionFilter : "all"}
              onChange={(event) =>
                onFilterChange({ subscription: event.target.value })
              }
              className={classes.filter}
            >
              <MenuItem value={"all"}>any subscription status</MenuItem>
              {subscriptionOptions.map((w) => (
                <MenuItem value={w} key={w}>
                  {w}
                </MenuItem>
              ))}
            </Select>
          ) : (
            ""
          )}

          <div className={classes.sendBulkMessageButtonContainer}>
            <Button
              onClick={() => this.handleOpenSendBulkMessage()}
              color="primary"
              variant="contained"
              disabled={
                !selectedAthletes ||
                selectedAthletes.length === 0 ||
                sendingBulkMessage
              }
            >
              Send Bulk Message
            </Button>
          </div>
        </div>
        <div className={classes.tableContainer}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell key="select"></TableCell>
                {columns.map((column) =>
                  !isAdmin || column.id !== "comments" ? (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        textAlign: "center",
                        padding: 10,
                        maxWidth:
                          column.id === "streak" ||
                          column.id === "load" ||
                          column.id === "energy"
                            ? 75
                            : "initial",
                      }}
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => this.handleRequestSort(column.id)}
                        hideSortIcon={true}
                      >
                        {column.label}
                        {orderBy === column.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}

                        {column.id === "streak" ? (
                          <div className={classes.containerMenu}>
                            <Button
                              className={classes.buttonMenu}
                              buttonRef={(node) => {
                                this.anchorEl = node;
                              }}
                              aria-owns={
                                openDone ? "menu-list-grow" : undefined
                              }
                              aria-haspopup="true"
                              onClick={(e) => this.handleOpenDone(e)}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/chevron_down.svg`}
                                alt=""
                              />
                            </Button>

                            <Popper
                              open={openDone}
                              anchorEl={this.anchorEl}
                              transition
                              disablePortal
                              className={classes.popper}
                              placement="right-start"
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow {...TransitionProps} id="menu-list-grow">
                                  <div className={classes.paper}>
                                    <ClickAwayListener
                                      onClickAway={(e) =>
                                        this.handleCloseDone(e)
                                      }
                                    >
                                      <MenuList>
                                        <Button
                                          variant="text"
                                          classes={{
                                            root: classes.menuItem,
                                            label:
                                              this.state.doneGran === "week"
                                                ? classes.menuItemLabelSelected
                                                : classes.menuItemLabel,
                                          }}
                                          onClick={(e) =>
                                            this.handleCloseDone(e, "week")
                                          }
                                        >
                                          This week
                                        </Button>

                                        <Button
                                          variant="text"
                                          classes={{
                                            root: classes.menuItem,
                                            label:
                                              this.state.doneGran === "month"
                                                ? classes.menuItemLabelSelected
                                                : classes.menuItemLabel,
                                          }}
                                          onClick={(e) =>
                                            this.handleCloseDone(e, "month")
                                          }
                                        >
                                          This month
                                        </Button>

                                        <Button
                                          variant="text"
                                          classes={{
                                            root: classes.menuItem,
                                            label:
                                              this.state.doneGran === "plan"
                                                ? classes.menuItemLabelSelected
                                                : classes.menuItemLabel,
                                          }}
                                          onClick={(e) =>
                                            this.handleCloseDone(e, "plan")
                                          }
                                        >
                                          Full plan
                                        </Button>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </div>
                                </Grow>
                              )}
                            </Popper>
                          </div>
                        ) : (
                          ""
                        )}

                        {column.id === "energy" ? (
                          <div className={classes.containerMenu}>
                            <Button
                              className={classes.buttonMenu}
                              buttonRef={(node) => {
                                this.anchorElE = node;
                              }}
                              aria-owns={
                                openEnergy ? "menu-list-grow" : undefined
                              }
                              aria-haspopup="true"
                              onClick={(e) => this.handleOpenEnergy(e)}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/chevron_down.svg`}
                                alt=""
                              />
                            </Button>

                            <Popper
                              open={openEnergy}
                              anchorEl={this.anchorElE}
                              transition
                              disablePortal
                              className={classes.popper}
                              placement="right-start"
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow {...TransitionProps} id="menu-list-grow">
                                  <div className={classes.paper}>
                                    <ClickAwayListener
                                      onClickAway={(e) =>
                                        this.handleCloseEnergy(e)
                                      }
                                    >
                                      <MenuList>
                                        <Button
                                          variant="text"
                                          classes={{
                                            root: classes.menuItem,
                                            label:
                                              this.state.energyGran === "week"
                                                ? classes.menuItemLabelSelected
                                                : classes.menuItemLabel,
                                          }}
                                          onClick={(e) =>
                                            this.handleCloseEnergy(e, "week")
                                          }
                                        >
                                          This week
                                        </Button>

                                        <Button
                                          variant="text"
                                          classes={{
                                            root: classes.menuItem,
                                            label:
                                              this.state.energyGran === "month"
                                                ? classes.menuItemLabelSelected
                                                : classes.menuItemLabel,
                                          }}
                                          onClick={(e) =>
                                            this.handleCloseEnergy(e, "month")
                                          }
                                        >
                                          This month
                                        </Button>

                                        <Button
                                          variant="text"
                                          classes={{
                                            root: classes.menuItem,
                                            label:
                                              this.state.energyGran === "plan"
                                                ? classes.menuItemLabelSelected
                                                : classes.menuItemLabel,
                                          }}
                                          onClick={(e) =>
                                            this.handleCloseDone(e, "plan")
                                          }
                                        >
                                          Full plan
                                        </Button>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </div>
                                </Grow>
                              )}
                            </Popper>
                          </div>
                        ) : (
                          ""
                        )}

                        {column.id === "load" ? (
                          <div className={classes.containerMenu}>
                            <Button
                              className={classes.buttonMenu}
                              buttonRef={(node) => {
                                this.anchorElL = node;
                              }}
                              aria-owns={
                                openLoad ? "menu-list-grow" : undefined
                              }
                              aria-haspopup="true"
                              onClick={(e) => this.handleOpenLoad(e)}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/chevron_down.svg`}
                                alt=""
                              />
                            </Button>

                            <Popper
                              open={openLoad}
                              anchorEl={this.anchorElL}
                              transition
                              disablePortal
                              className={classes.popper}
                              placement="right-start"
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow {...TransitionProps} id="menu-list-grow">
                                  <div className={classes.paper}>
                                    <ClickAwayListener
                                      onClickAway={(e) =>
                                        this.handleCloseLoad(e)
                                      }
                                    >
                                      <MenuList>
                                        <Button
                                          variant="text"
                                          classes={{
                                            root: classes.menuItem,
                                            label:
                                              this.state.loadGran === "week"
                                                ? classes.menuItemLabelSelected
                                                : classes.menuItemLabel,
                                          }}
                                          onClick={(e) =>
                                            this.handleCloseLoad(e, "week")
                                          }
                                        >
                                          This week
                                        </Button>

                                        <Button
                                          variant="text"
                                          classes={{
                                            root: classes.menuItem,
                                            label:
                                              this.state.loadGran === "month"
                                                ? classes.menuItemLabelSelected
                                                : classes.menuItemLabel,
                                          }}
                                          onClick={(e) =>
                                            this.handleCloseLoad(e, "month")
                                          }
                                        >
                                          This month
                                        </Button>

                                        <Button
                                          variant="text"
                                          classes={{
                                            root: classes.menuItem,
                                            label:
                                              this.state.loadGran === "plan"
                                                ? classes.menuItemLabelSelected
                                                : classes.menuItemLabel,
                                          }}
                                          onClick={(e) =>
                                            this.handleCloseLoad(e, "plan")
                                          }
                                        >
                                          Full plan
                                        </Button>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </div>
                                </Grow>
                              )}
                            </Popper>
                          </div>
                        ) : (
                          ""
                        )}
                      </TableSortLabel>
                    </TableCell>
                  ) : (
                    ""
                  ),
                )}
                {isAdmin ? (
                  <>
                    <TableCell
                      key="assign"
                      style={{ textAlign: "center", padding: 10 }}
                    >
                      Assign Coach
                    </TableCell>

                    <TableCell
                      key="assign"
                      style={{ textAlign: "center", padding: 10 }}
                    >
                      Assign a strength coach
                    </TableCell>
                  </>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.stableSort(
                rows,
                this.getComparator(order, orderBy),
                orderBy,
                order,
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell key="select">
                        <Checkbox
                          onChange={(ev) =>
                            this.handleSelectedChange(ev, row.userPlan)
                          }
                        />
                      </TableCell>
                      {columns.map((column) => {
                        let value = row[column.id];

                        const {
                          cognito_user_sub,
                          statistic,
                          strava_athlete_id,
                          stravaActivities,
                          image,
                          imageUrl,
                          chainedPlans,
                          garmin_oauth_token,
                          suunto_auth,
                        } = row.userPlan;

                        return !isAdmin || column.id !== "comments" ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              textAlign: "center",
                              padding: 1,
                              color:
                                column.id === "athlete" && row.status
                                  ? row.status.toLowerCase() === "active"
                                    ? "green"
                                    : row.status.toLowerCase() === "expired" ||
                                      row.status.toLowerCase() === "cancelled"
                                    ? "red"
                                    : row.status.toLowerCase() ===
                                      "non_renewing"
                                    ? "blue"
                                    : row.status.toLowerCase() === "active"
                                    ? row.status.toLowerCase() === "in_trial"
                                    : row.status.toLowerCase() ===
                                      "non_renewing"
                                    ? "#FF81D0"
                                    : row.status.toLowerCase() === "paused"
                                    ? "#7A577A"
                                    : "#FFB300"
                                  : column.id === "streak" &&
                                    value >= 0 &&
                                    column.id === "streak" &&
                                    value <= 30
                                  ? "#F23827"
                                  : column.id === "streak" &&
                                    value > 30 &&
                                    column.id === "streak" &&
                                    value <= 50
                                  ? "#F29F05"
                                  : column.id === "streak" &&
                                    value > 51 &&
                                    column.id === "streak" &&
                                    value <= 75
                                  ? "#F2E307"
                                  : column.id === "streak" && value > 75
                                  ? "#04D976"
                                  : column.id === "energy" &&
                                    value >= 0 &&
                                    column.id === "energy" &&
                                    value <= 3
                                  ? "#F23827"
                                  : column.id === "energy" &&
                                    value > 3 &&
                                    column.id === "energy" &&
                                    value <= 5
                                  ? "#F2E307"
                                  : column.id === "energy" && value > 5
                                  ? "#04D976"
                                  : column.id === "load" &&
                                    value >= 0 &&
                                    column.id === "load" &&
                                    value <= 30
                                  ? "#F23827"
                                  : column.id === "load" &&
                                    value > 30 &&
                                    column.id === "load" &&
                                    value <= 50
                                  ? "#F29F05"
                                  : column.id === "load" &&
                                    value > 51 &&
                                    column.id === "load" &&
                                    value <= 75
                                  ? "#F2E307"
                                  : column.id === "load" &&
                                    value > 75 &&
                                    column.id === "load" &&
                                    value <= 130
                                  ? "#04D976"
                                  : column.id === "load" && value > 130
                                  ? "#F23827"
                                  : "",
                              fontWeight:
                                column.id === "athlete" ||
                                column.id === "streak" ||
                                column.id === "energy" ||
                                column.id === "load"
                                  ? "bold"
                                  : "",
                              cursor: "pointer",
                            }}
                            onClick={onAthleteSelect.bind(this, row.userPlan)}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {column.id === "athlete" && row.isLastWeek ? (
                                <img
                                  src={`${process.env.PUBLIC_URL}/emoji_lastweek.png`}
                                  alt=""
                                  className={classes.emojiInTable}
                                />
                              ) : (
                                ""
                              )}

                              {column.id === "lastViewed" &&
                                row.userPlan.checked === 1 && (
                                  <img
                                    src={`${process.env.PUBLIC_URL}/emoji_checked.png`}
                                    alt="check-image"
                                    className={classes.emoji}
                                  />
                                )}

                              {column.id === "lastViewed" &&
                                row.userPlan.needsCheck === 1 && (
                                  <img
                                    src={`${process.env.PUBLIC_URL}/emoji_needtocheck.png`}
                                    alt="needs-to-check-image"
                                    className={classes.emoji}
                                  />
                                )}

                              {column.id === "athlete" && row.racedLastWeek ? (
                                <img
                                  src={`${process.env.PUBLIC_URL}/emoji_racelastweek.png`}
                                  alt=""
                                  className={classes.emojiInTable}
                                />
                              ) : (
                                ""
                              )}

                              {column.id === "athlete" &&
                              row.userPlan.needHelp ? (
                                <img
                                  src={`${process.env.PUBLIC_URL}/help-plan-icon.svg`}
                                  alt=""
                                  className={classes.emojiInTable}
                                />
                              ) : (
                                ""
                              )}

                              {column.id === "athlete" &&
                              this.verifyNextWeekRace(row.nextGoal) ? (
                                <img
                                  src={`${process.env.PUBLIC_URL}/emoji_racethisweek.png`}
                                  alt=""
                                  className={classes.emojiInTable}
                                />
                              ) : (
                                ""
                              )}

                              {column.id === "comments" ? (
                                <Badge
                                  color="error"
                                  className={classes.iconFirst}
                                  badgeContent={value}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    onOpenChat(row.userPlan);
                                  }}
                                >
                                  <ChatBubbleOutlineOutlinedIcon />
                                </Badge>
                              ) : column.id === "modification" ? (
                                row.numModifications > 1 ? (
                                  <Badge
                                    color="error"
                                    className={classes.iconFirst}
                                    badgeContent={row.numModifications}
                                  >
                                    {value}
                                  </Badge>
                                ) : (
                                  value
                                )
                              ) : column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : column.id === "streak" ||
                                (column.id === "load" && value !== "N/A") ? (
                                value + "%"
                              ) : column.id === "lastLogin" ? (
                                value ? (
                                  moment.unix(value / 1000).fromNow()
                                ) : (
                                  "N/A"
                                )
                              ) : column.id === "week" ? (
                                this.handleWeekValue(value)
                              ) : (
                                value
                              )}
                            </div>
                          </TableCell>
                        ) : (
                          ""
                        );
                      })}

                      {isAdmin && (
                        <>
                          <TableCell key="assign" width="190px">
                            <AthletePlanAssignCoach
                              coachesList={coachesList}
                              assigningCoach={assigningCoach}
                              assignedCoachSub={
                                row.coach_cognito_user_sub
                                  ? row.coach_cognito_user_sub
                                  : null
                              }
                              onAssignCoach={(value) =>
                                this.handleAssignCoach(
                                  value,
                                  row.cognito_user_sub,
                                )
                              }
                              placeholder="Assign a coach"
                            />
                          </TableCell>
                          <TableCell key="assign-strength" width="190px">
                            <AthletePlanAssignCoach
                              coachesList={strengthCoaches}
                              assigningCoach={loadingStrengthAssign}
                              assignedCoachSub={
                                row.strength_coach_cognito_user_sub
                                  ? row.strength_coach_cognito_user_sub
                                  : null
                              }
                              onAssignCoach={(value) =>
                                this.handleAssignStrengthCoach(
                                  value,
                                  row.cognito_user_sub,
                                )
                              }
                              placeholder="Strength coach"
                            />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={exclusiveStartKey ? -1 : rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            onPageChange={this.handleChangePage}
            nextIconButtonProps={{
              disabled:
                loading ||
                (!exclusiveStartKey &&
                  rows.length > 0 &&
                  page >= Math.ceil(rows.length / rowsPerPage) - 1),
            }}
          />
        </div>

        <Dialog
          open={isOpenSendBulkMessage}
          aria-labelledby="delete-dialog"
          onClose={() => this.handleCloseSendBulkMessage()}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogContent>
            <TextField
              value={text ? text : ""}
              margin="normal"
              variant="outlined"
              placeholder={"Type a message"}
              fullWidth={true}
              multiline={true}
              rows={3}
              label={"Type a message"}
              onChange={this.handleTextChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleSendBulkMessage()}
              color="primary"
            >
              Send
            </Button>
            <Button
              onClick={() => this.handleCloseSendBulkMessage()}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AthletesList.propTypes = {
  assigningCoach: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  athletesPlans: PropTypes.array.isRequired,
  coachesList: PropTypes.array,
  onAthleteSelect: PropTypes.func.isRequired,
  subcategories: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
  weekFilter: PropTypes.number,
  onCommentsToReadCalculated: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

export default connect((store) => {
  return {
    athletes: store.athletes,
    userPlans: store.userPlans,
    auth: store.auth,
  };
})(withStyles(styles)(AthletesList));
