import React from "react";
import {
  Box,
  Button,
  LinearProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import {
  initAuthorizationHealthKit,
  disconnectHealthkit,
  clearActivityHealthKit,
} from "../../actions/healthkit-action";
import { palette } from "../../theme/palette";
import { AppleIcon } from "../icons";
import CustomButton from "./v2/CustomButton";
import { CircularProgress } from "@mui/material";

const HealthKitConnect = ({ v3 = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("settings");
  const { userPlans, healthKit } = useSelector((state) => state);
  const { currentPlan } = userPlans;
  const { initializing } = healthKit;
  const { healthKit: activeHealthKit, cognito_user_sub } = currentPlan || {};

  const requestAuthorization = () => {
    if (activeHealthKit) {
      dispatch(disconnectHealthkit(cognito_user_sub));
      dispatch(clearActivityHealthKit());
    } else {
      dispatch(initAuthorizationHealthKit(cognito_user_sub));
    }
  };

  if (v3 && Capacitor.getPlatform() === "ios") {
    return (
      <>
        <Box className={classes.boxDevice}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <AppleIcon activated={true} />
              <Typography variant="body1" className={classes.connectTitleV2}>
                {"Apple"}
              </Typography>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                gap: 5,
              }}
            >
              {userPlans.loading && !initializing ? (
                <CustomButton
                  typeStyle="disabled"
                  color="default"
                  className={classes.buttonConnect}
                  variant="contained"
                  size="small"
                >
                  <CircularProgress size={14} />
                </CustomButton>
              ) : (
                <CustomButton
                  typeStyle={
                    activeHealthKit && !initializing ? "disabled" : "primary"
                  }
                  color="default"
                  variant="contained"
                  size="small"
                  className={classes.buttonConnect}
                  onClick={requestAuthorization}
                >
                  {activeHealthKit
                    ? t("settings.watchDisconnect")
                    : t("settings.watchConnect")}
                </CustomButton>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
  }
  return (
    <>
      {Capacitor.getPlatform() === "ios" && (
        <div className={classes.connectContainer}>
          <div className={classes.connectFlexContainer}>
            <div className={classes.connectTextContainer}>
              <Typography variant="body1" className={classes.connectTitle}>
                {t("Connect with HealthKit")}
              </Typography>
              {!initializing ? (
                <div className={classes.connectText}>
                  <Typography variant="body1">
                    {t("Status")}
                    {": "}
                    {activeHealthKit ? (
                      <span style={{ color: "green" }}>{t("Connected")}</span>
                    ) : (
                      t("Not connected")
                    )}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/healthkit.png`}
              alt=""
              className={classes.logo}
            />
          </div>
          {!initializing ? (
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonConnect}
              style={{
                backgroundColor: activeHealthKit ? "#c9927d" : "#E16327",
              }}
              onClick={requestAuthorization}
            >
              {activeHealthKit
                ? t("Disconnect HealthKit")
                : t("Connect HealthKit")}
            </Button>
          ) : (
            <LinearProgress color="primary" variant="indeterminate" />
          )}
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles({
  boxDevice: {
    display: "flex",
    flexDirection: "column",
    padding: "4px 24px",
  },
  sync: {
    //styleName: p2-r;
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
    color: palette.darkGray,
  },
  connectTitleV2: {
    //styleName: p1-b;
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 700,
    color: palette.black,
  },
  strava: {
    background: "#ed4834",
    color: "white",
  },

  text: {
    fontFamily: "Avenir",
    fontSize: 16,
    color: "#202020",
    margin: "10px 0",
  },

  buttonConnect: {
    margin: "10px auto",
    boxShadow: "none",
    borderRadius: 50,
    width: 105,
    height: 30,
    fontSize: 14,
    padding: "4px 24px",
  },
  connectContainer: {
    background: "#FFFFFF",
    borderRadius: 10,
    padding: 25,
    maxWidth: 300,
    marginBottom: 20,
    textAlign: "center",
  },
  connectFlexContainer: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
  },
  connectTextContainer: {
    textAlign: "left",
  },
  connectTitle: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 20,
    color: "#202020",
    textAlign: "left",
  },
  connectText: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#202020",
    textAlign: "left",
  },
  logo: {
    width: 60,
  },
});
export default HealthKitConnect;
