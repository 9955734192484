import React from "react";
import { SvgIcon } from "@material-ui/core";
import { palette } from "../../theme/palette";

const RunningIcon = ({ width = 24, height = 24, state = "filled" }) => {
  let fill = "none";
  let stroke = "none";

  if (state === "highlighted") {
    stroke = "#12151B";
  } else if (state === "filled") {
    fill = "#12151B";
  } else if (state === "outline") {
    stroke = "#12151B";
  }

  return (
    <SvgIcon width={width} height={height}>
      <svg
        viewBox={`-1 -1 ${width + 2} ${height + 2}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4896 5.47998C14.5896 5.47998 15.4896 4.57998 15.4896 3.47998C15.4896 2.37998 14.5896 1.47998 13.4896 1.47998C12.3896 1.47998 11.4896 2.37998 11.4896 3.47998C11.4896 4.57998 12.3896 5.47998 13.4896 5.47998ZM10.3196 17.48L10.8896 14.98L12.9896 16.98V21.98C12.9896 22.53 13.4396 22.98 13.9896 22.98C14.5396 22.98 14.9896 22.53 14.9896 21.98V16.34C14.9896 15.79 14.7696 15.27 14.3696 14.89L12.8896 13.48L13.4896 10.48C14.6165 11.7683 16.1592 12.621 17.8496 12.89C18.4496 12.98 18.9896 12.5 18.9896 11.89C18.9896 11.4 18.6296 10.99 18.1396 10.91C16.6196 10.66 15.3596 9.75998 14.6896 8.57998L13.6896 6.97998C13.2896 6.37998 12.6896 5.97998 11.9896 5.97998C11.6896 5.97998 11.4896 6.07998 11.1896 6.07998L7.20955 7.75998C6.84726 7.91483 6.53848 8.17275 6.32157 8.50166C6.10466 8.83058 5.98921 9.21598 5.98955 9.60998V11.98C5.98955 12.53 6.43955 12.98 6.98955 12.98C7.53955 12.98 7.98955 12.53 7.98955 11.98V9.57998L9.78955 8.87998L8.18955 16.98L4.26955 16.18C3.72955 16.07 3.19955 16.42 3.08955 16.96V17C2.97955 17.54 3.32955 18.07 3.86955 18.18L7.97955 19C8.49075 19.1014 9.02136 18.9994 9.45842 18.7155C9.89547 18.4316 10.2044 17.9883 10.3196 17.48Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1"
        />
      </svg>
    </SvgIcon>
  );
};

export default RunningIcon;
