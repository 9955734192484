import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Grid,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import AccountCircleOutlined from "@material-ui/icons/AccountCircleOutlined";
import ExploreOutlined from "@material-ui/icons/ExploreOutlined";
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import classNames from "classnames";
import Group from "@material-ui/icons/Group";
import { VertLabIcon } from "./vert-lab-icon";
import TrainingZoneForm from "./training-zone-form";
import TrainingZoneStats from "./training-zone-stats";
import RacetimePredictor from "./race-time-predictor";
import TrainingLoaDistribution from "./training-load-distribution";
import LactateChart from "./lactate-chart";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LocalNotificationsService from "../../../services/local-notifications-service";
import { storageFactory } from "../../../lib/storage-factory";
import { currentUserSignOut } from "../../../actions/auth-action";
import AppAuthenticator from "../../app-authenticator";
import { Auth } from "aws-amplify";

const styles = (theme) => ({
  main: {
    display: "flex",
    flex: 1,
    minHeight: "100vh",
  },
  sidebar: {
    width: "200px",
    backgroundColor: "#171821",
    padding: "25px 0px 0px 0px",
    boxSizing: "border-box",
    borderRight: "1px solid #2C2D33",
  },
  item: {
    color: "#87888C",
    cursor: "pointer",
    padding: "12px",
    transition: "all .6s",

    "&:hover": {
      backgroundColor: "#A9DFD8",
      borderRadius: "6px",
      transition: "all .6s",
      color: "#000",
    },
  },
  itemContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  icon: {
    marginRight: "10px",
  },
  title: {
    fontSize: 14,
    fontFamily: "Montserrat",
    fontWeight: "500",
  },
  element: {
    "&:last-child": {
      marginTop: "120px",
    },
  },
  right: {
    display: "block",
    width: "calc(100% - 200px)",
    backgroundColor: "#171821",
    marginLeft: "auto",
    padding: 40,
    boxSizing: "border-box",
  },
  background: {
    backgroundColor: "#A9DFD8",
    borderRadius: "6px",
    color: "#000",
  },
  modalRoot: {
    padding: "0px !important",
    margin: "0px !important",
  },
  dialogPaper: {
    padding: "0px !important",
  },
  content: {
    padding: "0px !important",
  },
  rightButtons: {
    paddingLeft: "10px",
    paddingTop: "10px",
    cursor: "pointer",
  },
});

const menuOptions = [
  {
    title: "Vert - Labs",
    icon: <VertLabIcon />,
  },
  {
    title: "Dashboard",
    icon: <AccountCircleOutlined />,
    route: "/",
  },
  {
    title: "Trailhead",
    icon: <Group />,
    route: "/trailhead",
  },
  {
    title: "Explore",
    icon: <ExploreOutlined />,
    route: "/explore",
  },
  {
    title: "Settings",
    icon: <SettingsOutlined />,
    route: "/profile",
  },
  {
    title: "Signout",
    icon: <ExitToAppIcon />,
    route: "signout",
  },
];

const Layout = ({ classes, width }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser, isAuthenticated } = useSelector((state) => state.auth);
  const { attributes } = currentUser || {};
  const { sub } = attributes || {};
  const { zones } = useSelector((state) => state.lab);
  const { success } = zones || {};

  const [openSignUp, setOpenSignUp] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);

  const handleOpenSignUp = () => setOpenSignUp((signUp) => !signUp);
  const handleOpenSignIn = () => setOpenSignIn((signIn) => !signIn);

  const isInMobileViewSm = width === "sm" || width === "xs";

  const runIfAuthenticated = (callback) => {
    if (isAuthenticated) return callback();
    handleOpenSignIn();
  };

  const handleRoute = (route) => {
    if (route == "signout") return signOut();
    history.push(route);
  };

  const signOut = () => {
    LocalNotificationsService.stopListenToNotifications();
    storageFactory().removeItem("currentGoal");
    dispatch(currentUserSignOut(Auth.signOut(), sub));
    if (localStorage.getItem("counter"))
      localStorage.removeItem("counter", "1");
  };

  return (
    <Box className={classes.main}>
      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
          },
        }}
        classes={{
          paper: classes.sidebar,
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          {menuOptions.map((item, index) => (
            <ListItem key={index} disablePadding className={classes.element}>
              <Box
                onClick={() =>
                  runIfAuthenticated(() => handleRoute(item.route))
                }
                className={classNames(
                  classes.itemContainer,
                  classes.item,
                  index == 0 ? classes.background : null,
                )}
              >
                <Box className={classes.icon}>{item.icon}</Box>
                <ListItemText
                  primary={item.title}
                  classes={{ primary: classes.title }}
                />
              </Box>
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Box className={classes.right}>
        <Grid container spacing={3}>
          <Grid item md={4}>
            {success ? (
              <LactateChart />
            ) : (
              <TrainingZoneForm
                runIfAuthenticated={runIfAuthenticated}
                handleOpenSignIn={handleOpenSignIn}
                handleOpenSignUp={handleOpenSignUp}
              />
            )}
            <TrainingLoaDistribution />
          </Grid>
          <Grid item md={8}>
            <TrainingZoneStats />
            <RacetimePredictor />
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={openSignIn}
        onClose={handleOpenSignIn}
        aria-labelledby="signIn"
        maxWidth={!isInMobileViewSm ? "sm" : false}
        fullScreen={isInMobileViewSm}
        classes={{
          paper: classes.dialogPaper,
        }}
        style={{
          paddingLeft: "env(safe-area-inset-left)",
          paddingRight: "env(safe-area-inset-right)",
          paddingTop: "env(safe-area-inset-top)",
          paddingBottom: "env(safe-area-inset-bottom)",
        }}
      >
        <DialogContent id="signindialog" classes={{ root: classes.content }}>
          <div className={classes.rightButtons}>
            <img
              src={`${process.env.PUBLIC_URL}/close.svg`}
              alt=""
              onClick={handleOpenSignIn}
            />
          </div>
          <AppAuthenticator initialState="signIn" />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSignUp}
        onClose={handleOpenSignUp}
        aria-labelledby="signUp"
        maxWidth={!isInMobileViewSm ? "sm" : false}
        fullScreen={isInMobileViewSm}
        style={{
          paddingLeft: "env(safe-area-inset-left)",
          paddingRight: "env(safe-area-inset-right)",
          paddingTop: "env(safe-area-inset-top)",
          paddingBottom: "env(safe-area-inset-bottom)",
        }}
      >
        <DialogContent id="signupdialog">
          <div className={classes.rightButtons}>
            <img
              src={`${process.env.PUBLIC_URL}/close.svg`}
              alt=""
              onClick={handleOpenSignUp}
            />
          </div>
          <AppAuthenticator initialState="signUp" />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default withTranslation()(
  withWidth()(withStyles(styles, { withTheme: true })(Layout)),
);
