import React, { useEffect } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { palette } from "../../../theme/palette";
import PopularVideosCardSkeleton from "./PopularVideosCardSkeleton";
import usePost from "../../../hooks/usePost";
import PopularVideosHomeCard from "./PopularVideosHomeCard";

const PopularVideosHome = () => {
  const { t } = useTranslation("home");
  const history = useHistory();
  const {
    explorePosts,
    loadingExplorePosts,
    handleGetExplorePosts,
  } = usePost();
  const { data: videos } = explorePosts["Workouts [ Video ]"] || {};
  const classes = useStyles();

  useEffect(() => {
    if (!videos) {
      handleGetExplorePosts(new Date().getTime(), "Workouts [ Video ]", true);
    }
  }, []);

  const navigate = (id) => {
    history.push(`explore/post/${id}`);
  };

  const navigateToAll = () => {
    history.push("/all-videos");
  };

  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.header}>
          <Typography variant="h3" className={classes.bold}>
            {t("home.video.popular")}
          </Typography>
          <Typography
            variant="body2"
            className={classes.seeAll}
            id="home_see_all_plans"
            onClick={navigateToAll}
          >
            {t("home.video.seeAll")}
          </Typography>
        </Box>
      </Box>
      {loadingExplorePosts["Workouts [ Video ]"] ? (
        <Box className={classes.carousel}>
          {[...Array(3)].map((_, index) => (
            <PopularVideosCardSkeleton key={index} />
          ))}
        </Box>
      ) : (
        <Box className={classes.carousel}>
          {videos &&
            videos.map((item, index) => (
              <PopularVideosHomeCard
                key={index}
                item={item}
                navigate={navigate}
              />
            ))}
        </Box>
      )}
    </Box>
  );
};

export default PopularVideosHome;

const useStyles = makeStyles(() => ({
  card: {
    background: palette.white,
    borderRadius: "8px",
    width: "275px",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
  descriptionContainer: {
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  carousel: {
    display: "-webkit-inline-box",
    width: "100%",
    overflowY: "hidden",
    overflowX: "scroll",
    scrollbarWidth: "none",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  seeAll: {
    color: palette.lightBlue,
  },
  bold: {
    fontFamily: "Karla",
    fontSize: "22px",
    lineHeight: "33px",
    letterSpacing: "0px",
    fontWeight: "700",
  },
  favoriteButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "#FFFFFF33",
  },
  icon: {
    color: "#6D6F71",
    width: "18px",
    margin: 0,
    padding: 0,
  },
  time: {
    color: "#6D6F71",
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
  },
  workout: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#212121",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "space-between",
    gap: "8px",
  },
  timeContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
}));
