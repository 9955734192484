import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getPosts,
  addAllez,
  addComment,
  deletePost,
  deleteComment,
  editPost,
  addReport,
  newPost,
  cleanNewPost,
  addPost,
  joinGroup,
} from "../../../../actions/trail-head-action";
import Post from "../../../trail-head/post";
import PublicGroup from "../../../trail-head/public-group";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Typography, Dialog, DialogContent } from "@material-ui/core";
import {
  updatePlan,
  clearSuccessMessage,
  clearErrorMessage,
  toggleOpenMenu,
  getUserActivities,
} from "../../../../actions/user-plans-action";
import UserGroups from "../../../../constants/user-groups";
import SnackBarMessageDisplay from "../../../common/snack-bar-message-display";
import { setErrorMessage } from "../../../../actions/user-plans-action";
import { Storage } from "aws-amplify";
import withWidth from "@material-ui/core/withWidth";
import { getLevel } from "../../../../lib/rates-helper";
import DashboardRecentActivities from "../../../dashboard/dashboard-recent-activities";
import { reduceFileSize } from "../../../../lib/image-helper";
import jimp from "jimp";
import DashboardNextRaceDetails from "../../../dashboard/dashboard-training/dashboard-next-race/dashboard-next-race-details";
import DashboardNextRacePane from "../../../dashboard/dashboard-training/dashboard-next-race/dashboard-next-race-pane";
import AutoCompleteMultiSelect from "../../../common/auto-complete-multi-select";
import { getUserPosts } from "../../../../actions/trail-head-action";
import { logEvent } from "../../../../lib/events-helper";
import { checkSubscription } from "../../../../lib/subscription-plan-helper";
import PostCardComponent from "../../../common/v2/PostCardComponent";

const styles = (theme) => ({
  container: {
    width: "100%",
    background: "#F9F9FB",
    // height: "auto",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  posts: {
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  loading: {
    textAlign: "center",
  },
  postText: {
    marginTop: 0,
    background: "rgb(255 255 255 / 10%)",
  },
  logoImg: {
    width: 72,
    marginRight: "auto",
    cursor: "pointer",
  },
  iconSearch: {
    marginLeft: 10,
    cursor: "pointer",
  },
  onlyReported: {
    color: "#ffffff",
  },
  button: {
    boxShadow: "none",
    borderRadius: 50,
    marginBottom: 0,
    marginLeft: 20,
  },

  title: {
    fontWeight: 500,
    fontSize: 18,
    marginRight: "auto",
  },
  round: {
    borderRadius: 4,
    marginTop: 0,
  },

  closeIcon: {
    cursor: "pointer",
  },

  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },

  noPadding: {
    padding: "0 !important",
  },

  levelMiniBadge: {
    height: 25,
    bottom: -5,
    right: -10,
    position: "absolute",
  },

  tabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
  },

  tab: {
    fontSize: 16,
    opacity: 0.7,
    textTransform: "uppercase",
    cursor: "pointer",
  },

  tabSelected: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    cursor: "pointer",
  },

  postOptionsRow: {
    display: "flex",
    justifyContent: "space-evenly",
  },

  postOption: {
    textAlign: "center",
    background: "#F9F9FB",
    borderRadius: 4,
    padding: "10px 20px",
    minWidth: 65,
    cursor: "pointer",
  },

  postOptionText: {
    fontSize: 12,
  },

  postImageContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  postImage: {
    maxWidth: "100%",
    maxHeight: 300,
    marginTop: 15,
  },

  publishButtonContainer: {
    width: "100%",
    marginTop: 10,
    textAlign: "center",
  },

  publishButton: {
    margin: "auto",
    textTransform: "initial",
    borderRadius: 10,
    width: 75,
    fontFamily: "Montserrat",
    fontSize: 16,
    height: 29,
    boxShadow: "none",
  },

  input: {
    display: "none",
    cursor: "pointer",
  },
});

class PostList extends React.Component {
  state = {
    searchPhrase: null,
    tags: [],
    showReported: false,
    isSearchingWithPhrase: false,
    isSearchingWithFilters: true,
    avatarLoading: true,
    avatarUrl: null,
    openSignUp: false,
    signUpTimeout: null,
    tab: 0,
    isPosting: null,
    units: "kms",

    currentImage: null,
    currentImageExtension: null,
    currentGoal: null,
    currentBadge: null,
    type: null,
    postTags: [],
  };

  async componentDidMount() {
    this.getAvatarUrl();

    this.setState({
      ...this.state,
      signUpTimeout: setTimeout(() => {
        const { auth } = this.props;
        const { isAuthenticated } = auth;
        if (!isAuthenticated && !this.state.signUpTimeout) {
          this.handleOpenSignUp();
        }
      }, 6000),
    });

    const { userPlans } = this.props;
    if (userPlans.loadingPlan === false) {
      this.getActivities();
    }

    if (typeof window !== "undefined") {
      this.setState({
        ...this.state,
        units: localStorage.getItem("units"),
      });

      window.addEventListener("storage", () => this.localStorageUpdated());
    }

    const { trailHead } = this.props;
    const { text, image, imageBase64, badge } = trailHead;
    if (text && image) {
      this.convertImgToDataURLviaCanvas(image, (base64_data) => {
        this.setState({
          ...this.state,
          currentImage: base64_data,
          currentImageExtension: "png",
          isPosting: "final",
        });
      });
    }
    if (imageBase64) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            currentImage: imageBase64,
            currentImageExtension: "png",
            isPosting: "final",
          }),
        100,
      );
    }
    if (badge) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            currentBadge: badge,
            isPosting: "final",
          }),
        100,
      );
    }
  }

  localStorageUpdated() {
    this.setState({
      ...this.state,
      units: localStorage.getItem("units"),
    });
  }

  async componentDidUpdate(prevProps) {
    const { userPlans } = this.props;
    const { currentPlan, updatingImage } = userPlans;
    const { image } = currentPlan || {};

    if (
      (currentPlan &&
        currentPlan.image &&
        (!prevProps.userPlans ||
          !prevProps.userPlans.currentPlan ||
          !prevProps.userPlans.currentPlan.image ||
          prevProps.userPlans.currentPlan.image !== currentPlan.image)) ||
      (prevProps.userPlans.updatingImage && !updatingImage)
    ) {
      if (image) {
        this.setState({
          ...this.state,
          avatarLoading: true,
        });
        this.getAvatarUrl();
      }
    }

    if (
      (!prevProps.userPlans.currentPlan ||
        !prevProps.userPlans.currentPlan.strava_athlete_id) &&
      userPlans.currentPlan &&
      userPlans.currentPlan.strava_athlete_id
    ) {
      this.getActivities();
    }

    if (
      (!prevProps.userPlans.currentPlan ||
        !prevProps.userPlans.currentPlan.garmin_oauth_token) &&
      userPlans.currentPlan &&
      userPlans.currentPlan.garmin_oauth_token
    ) {
      this.getActivities();
    }

    if (
      (!prevProps.userPlans.currentPlan ||
        !prevProps.userPlans.currentPlan.suunto_auth) &&
      userPlans.currentPlan &&
      userPlans.currentPlan.suunto_auth
    ) {
      this.getActivities();
    }

    if (
      (!prevProps.userPlans.currentPlan ||
        !prevProps.userPlans.currentPlan.coros_auth) &&
      userPlans.currentPlan &&
      userPlans.currentPlan.coros_auth
    ) {
      this.getActivities();
    }

    const { trailHead } = this.props;
    const { text, image: imageTH, imageBase64 } = trailHead;

    if (
      (!prevProps.trailHead.text || !prevProps.trailHead.image) &&
      text &&
      imageTH
    ) {
      this.convertImgToDataURLviaCanvas(imageTH, (base64_data) => {
        this.setState({
          ...this.state,
          currentImage: base64_data,
          currentImageExtension: "png",
          isPosting: "final",
        });
      });
    }
    if (!prevProps.trailHead.imageBase64 && imageBase64) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            currentImage: imageBase64,
            currentImageExtension: "png",
            isPosting: "final",
          }),
        100,
      );
    }
  }

  convertImgToDataURLviaCanvas = function(url, callback) {
    var img = new Image();

    img.crossOrigin = "Anonymous";

    img.onload = function() {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      canvas.height = this.height;
      canvas.width = this.width;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      callback(dataURL);
      canvas = null;
    };

    img.src = url;
  };

  getAvatarUrl = async () => {
    const { userPlans, t } = this.props;
    const { currentPlan } = userPlans;
    const { image } = currentPlan || {};

    if (image) {
      try {
        const result = await Storage.get(image, {
          level: "public",
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              avatarUrl: result,
              avatarLoading: false,
            }),
          100,
        );
      } catch (exp) {
        this.handleError(
          new Error(
            t(
              "Oops, we could not load your profile picture. Give it another try",
              { ns: "messages" },
            ),
          ),
        );
        throw exp;
      }
    } else if (!image) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            avatarLoading: false,
          }),
        100,
      );
    }
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleError = (err) => {
    this.props.dispatch(setErrorMessage(err)); //GOES TO SNACK BAR
  };

  handleLoadNextPage = (lastDate) => {
    this.props.dispatch(
      getPosts(
        lastDate,
        this.state.searchPhrase,
        this.state.tags ? this.state.tags.map((tag) => tag.value) : "",
        false,
      ),
    );
  };

  handleAddAllez = async (id, commentId = null, replyId = null) => {
    const { auth } = this.props;
    const { currentUser, isAuthenticated } = auth;
    const { attributes } = currentUser || {};
    const { sub, name } = attributes || {};
    if (isAuthenticated) {
      await this.props.dispatch(
        addAllez(sub, {
          cognito_user_sub: sub,
          cognito_user_name: name,
          id,
          commentId,
          replyId,
        }),
      );

      const {
        trailHead: { selectedAthlete },
      } = this.props;
      const { cognito_user_sub } = selectedAthlete || {};
      await this.props.dispatch(
        getUserPosts(cognito_user_sub, new Date().getTime()),
      );
    } else {
      this.handleOpenSignUp();
    }
  };

  handleReport = (id, reason) => {
    const { auth } = this.props;
    const { currentUser, isAuthenticated } = auth;
    const { attributes } = currentUser || {};
    const { sub, name } = attributes || {};
    if (isAuthenticated) {
      this.props.dispatch(
        addReport(sub, {
          cognito_user_sub: sub,
          cognito_user_name: name,
          id,
          reason,
        }),
      );
    } else {
      this.handleOpenSignUp();
    }
  };

  handleAddComment = async (comment) => {
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub, name } = attributes;
    await this.props.dispatch(
      addComment(sub, { ...comment, cognito_user_name: name }),
    );

    const {
      trailHead: { selectedAthlete },
    } = this.props;
    const { cognito_user_sub } = selectedAthlete || {};
    await this.props.dispatch(
      getUserPosts(cognito_user_sub, new Date().getTime()),
    );
  };

  handleStartPhraseSearch = () => {
    this.setState({
      ...this.state,
      isSearchingWithPhrase: true,
      tags: [],
      isPosting: null,
    });
  };

  handleStopSearch = () => {
    this.setState({
      ...this.state,
      isSearchingWithPhrase: false,
      tags: [],
      searchPhrase: null,
    });
    if (
      (this.state.tags && this.state.tags.length) ||
      this.state.searchPhrase
    ) {
      this.props.dispatch(
        getPosts(new Date().getTime(), null, [], false, true),
      );
    }
  };

  handleSearchChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;

        if (this.state.searchTimeout) {
          clearTimeout(this.state.searchTimeout);
        }

        this.setState({
          ...this.state,
          searchPhrase: value || "",
          searchTimeout: setTimeout(() => {
            this.props.dispatch(
              getPosts(
                new Date().getTime(),
                value,
                this.state.tags ? this.state.tags.map((tag) => tag.value) : "",
                this.state.showReported,
                true,
              ),
            );
          }, 1000),
        });
      }
    }
  };

  handleTabSelect = (tab) => {
    this.setState({
      ...this.state,
      tab,
      isPosting: null,
    });
  };

  handleTagSelect = async (tag) => {
    const {
      trailHead: { selectedAthlete },
    } = this.props;
    const { cognito_user_sub } = selectedAthlete || {};

    if (this.state.tags.find((t) => t.value === tag.value)) {
      await this.props.dispatch(
        getPosts(
          new Date().getTime(),
          this.state.searchPhrase,
          [],
          this.state.showReported,
          true,
        ),
      );

      await this.props.dispatch(
        getUserPosts(cognito_user_sub, new Date().getTime()),
      );

      this.setState({
        ...this.state,
        tags: [],
      });
    } else {
      this.props.dispatch(
        getPosts(
          new Date().getTime(),
          this.state.searchPhrase,
          [tag.value],
          this.state.showReported,
          true,
        ),
      );

      await this.props.dispatch(
        getUserPosts(cognito_user_sub, new Date().getTime()),
      );
      this.setState({
        ...this.state,
        tags: [tag],
      });
    }
  };

  handleChangeShowReported = (event) => {
    this.props.dispatch(
      getPosts(
        new Date().getTime(),
        this.state.searchPhrase,
        this.state.tags ? this.state.tags.map((tag) => tag.value) : "",
        event.target.checked,
        true,
      ),
    );
    this.setState({ ...this.state, showReported: event.target.checked });
  };

  handleDelete = async (type, entry) => {
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;
    switch (type) {
      case "post":
        await this.props.dispatch(deletePost(sub, entry));
        break;
      case "comment":
      case "reply":
        await this.props.dispatch(deleteComment(sub, entry));
        break;
      default:
        break;
    }

    const {
      trailHead: { selectedAthlete },
    } = this.props;
    const { cognito_user_sub } = selectedAthlete || {};
    await this.props.dispatch(
      getUserPosts(cognito_user_sub, new Date().getTime()),
    );
  };

  handleEdit = async (editData) => {
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;
    await this.props.dispatch(editPost(sub, editData));

    const {
      trailHead: { selectedAthlete },
    } = this.props;
    const { cognito_user_sub } = selectedAthlete || {};
    await this.props.dispatch(
      getUserPosts(cognito_user_sub, new Date().getTime()),
    );
  };

  handleBlock = async (user) => {
    const { userPlans, auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      const { currentPlan } = userPlans;
      const { cognito_user_sub, blockedUsers } = currentPlan || {};
      await this.props.dispatch(
        updatePlan(
          {
            cognito_user_sub,
            blockedUsers: blockedUsers ? [...blockedUsers, user] : [user],
          },
          false,
          false,
        ),
      );

      const {
        trailHead: { selectedAthlete },
      } = this.props;
      const { cognito_user_sub: userCognito } = selectedAthlete || {};
      await this.props.dispatch(
        getUserPosts(userCognito, new Date().getTime()),
      );
    } else {
      this.handleOpenSignUp();
    }
  };

  handleOpenSignUp = () => {
    localStorage.removeItem("error");
    if (this.state.signUpTimeout) {
      clearTimeout(this.state.signUpTimeout);
    }

    const { auth } = this.props;
    const { isAuthenticated } = auth;

    if (!isAuthenticated) {
      this.setState({
        ...this.state,
        openSignUp: true,
        signUpTimeout: null,
      });
    }
  };

  handleCloseSignUp = () => {
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignUp: false,
    });
  };

  toogleMenu = () => {
    this.props.dispatch(toggleOpenMenu());
    this.setState({
      ...this.state,
      isPosting: null,
    });
  };

  handleTextChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.props.dispatch(newPost("", value || "", null));
      }
    }
  };

  getActivities = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    this.props.dispatch(getUserActivities(currentPlan));
  };

  handleCloseAddPost = () => {
    this.setState({
      ...this.state,
      isPosting: this.state.isPosting === "main" ? null : "main",
      currentImage: null,
      currentImageExtension: null,
      currentGoal: null,
      currentBadge: null,
      type: null,
    });
    if (this.state.isPosting === "main") {
      this.props.dispatch(cleanNewPost());
    }
  };

  handlePostTagSelect = (event) => {
    this.setState({
      ...this.state,
      postTags: event,
    });
  };

  handleSave = () => {
    const { userPlans, subscriptions, trailHead } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || {};
    const { latestSubscriptionFinalizedTime } = currentPlan || {};

    let notSubscribed = false;

    let { selectedPlan } = currentPlan;
    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    const isSubscribed = checkSubscription(
      subscriptions,
      latestSubscriptionFinalizedTime,
    );

    const { text } = trailHead;

    const {
      currentImage,
      currentImageExtension,
      currentGoal,
      currentBadge,
      type,
      postTags,
    } = this.state;

    const post = {
      title: "",
      text,
      tags: postTags.map((tag) => tag.value),
      link: "",
      image: currentImage,
      imageExtension: currentImageExtension,
      cognito_user_sub,
      crown: isSubscribed,
      goal: currentGoal,
      badge: currentBadge,
      type,
    };

    this.props.dispatch(addPost(cognito_user_sub, post));

    this.setState({
      ...this.state,
      isPosting: null,
      currentImage: null,
      currentImageExtension: null,
      currentGoal: null,
      currentBadge: null,
      type: null,
    });
    this.props.dispatch(cleanNewPost());
  };

  handleInputChange = async (event) => {
    if (event) {
      const { target } = event;
      const { maxSize, t } = this.props;
      if (target) {
        const { files } = target;
        if (files) {
          const selectedFile = files[0];
          try {
            if (selectedFile) {
              const { size } = selectedFile;
              if (size <= maxSize) {
                if (size > 1024000) {
                  reduceFileSize(
                    selectedFile,
                    1024000,
                    1000,
                    Infinity,
                    0.9,
                    async (fixedFile) => {
                      fixedFile.name = selectedFile.name;
                      const {
                        image: imageString,
                        extension,
                      } = await this.getBase64(fixedFile);

                      this.setState({
                        ...this.state,
                        currentImage: imageString,
                        currentImageExtension: extension,
                        isPosting: "final",
                      });
                    },
                  );
                } else {
                  const {
                    image: imageString,
                    extension,
                  } = await this.getBase64(selectedFile);

                  this.setState({
                    ...this.state,
                    currentImage: imageString,
                    currentImageExtension: extension,
                    isPosting: "final",
                  });
                }
              } else {
                this.handleError(
                  t("Sorry, your image needs to be smaller than maxSize10", {
                    ns: "messages",
                  }),
                );
              }
            } else {
              //if (onError) {
              this.handleError(t("Invalid File", { ns: "messages" }));
              //}
            }
          } catch (exp) {
            this.handleError(exp);
          }
        }
      }
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const image = await jimp.read(reader.result);
          //await image.resize(150, jimp.AUTO);
          const fileNameArray = file.name ? file.name.split(".") : undefined;
          const extension = fileNameArray
            ? fileNameArray[fileNameArray.length - 1]
            : undefined;
          const data = await image.getBase64Async(
            extension === "png" ? jimp.MIME_PNG : jimp.MIME_JPEG,
          );
          resolve({
            image: data,
            extension,
          });
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleGoalPost = (goal) => {
    this.setState({
      ...this.state,
      currentGoal: goal,
      isPosting: "final",
    });
  };

  handleBadgePost = (badge) => {
    this.setState({
      ...this.state,
      currentBadge: badge,
      isPosting: "final",
    });
  };

  handleJoinPublicGroup = (id) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || {};
    this.props.dispatch(joinGroup(cognito_user_sub, id));
  };

  render() {
    const {
      classes,
      t,
      auth,
      trailHead,
      i18n,
      location = {},
      subscriptions,
      width,

      strava,
      garmin,
      suunto,
      vertActivities,

      userPlans,
      coros,
    } = this.props;
    const { currentUser, currentUserGroups, isAuthenticated } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes || {};

    const {
      posts,
      tags: tagOptions,
      loadingPosts,
      addingAllezOn,
      addingCommentOn,

      text,
      image,

      loadingPublicGroups,
      publicGroups,
      currentUserPosts,
      joiningPublicGroup,
    } = trailHead;
    const { data } = posts;
    const { Items } =
      currentUserPosts ||
      {}(Items || []).sort((a, b) => b.created_at - a.created_at);

    const { currentPlan, loading, successMessage, errorMessage } = userPlans;
    const {
      blockedUsers,
      trailhead,
      accumulatedDistance = 0,
      accumulatedElevation = 0,
    } = currentPlan || {};

    const level = loading
      ? 0
      : getLevel(accumulatedDistance, accumulatedElevation);

    const isCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_COACH,
      ) > -1;

    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;

    const isMaster =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_MASTER,
      ) > -1;

    const { latestExpired, loadingLatest, latest } = subscriptions || {};
    const { latestSubscriptionFinalizedTime } = currentPlan || {};

    let notSubscribed = false;

    let { selectedPlan } = currentPlan || {};
    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    //if (selectedPlan !== WorkoutPlans.Items.FREE) {
    if (!loadingLatest) {
      if (
        !latestSubscriptionFinalizedTime &&
        (!latest ||
          (latest.status !== "active" &&
            latest.status !== "in_trial" &&
            latest.status !== "non_renewing"))
      )
        notSubscribed = true;
      else if (latestExpired) notSubscribed = true;
    }
    //} else {
    //  notSubscribed = true;
    //}

    const {
      searchPhrase,
      tags,
      showReported,
      isSearchingWithPhrase,
      isSearchingWithFilters,
      avatarUrl,
      openSignUp,
      tab,
      isPosting,
      units,
      currentImage,
      currentGoal,
      currentBadge,
      postTags,
    } = this.state;

    const { strava_athlete_id, garmin_oauth_token, suunto_auth, coros_auth } =
      currentPlan || "";

    const { activities: activitiesStrava, loading: loadingStrava } = strava;
    const { activities: activitiesGarmin, loading: loadingGarmin } = garmin;
    const { activities: activitiesSuunto, loading: loadingSuunto } = suunto;
    const { activities: activitiesCoros, loading: loadingCoros } = coros;
    const { activities: activitiesVert, loading: loadingVert } = vertActivities;

    const activities =
      activitiesGarmin && activitiesGarmin.length
        ? activitiesGarmin
        : activitiesSuunto && activitiesSuunto.length
        ? activitiesSuunto
        : activitiesCoros && activitiesCoros.length
        ? activitiesCoros
        : activitiesStrava && activitiesStrava.length
        ? activitiesStrava
        : activitiesVert && activitiesVert.length
        ? activitiesVert
        : [];

    const loadingActivities =
      loadingStrava ||
      loadingGarmin ||
      loadingSuunto ||
      loadingVert ||
      loadingCoros;

    const noTracker =
      !loading &&
      !strava_athlete_id &&
      !garmin_oauth_token &&
      !suunto_auth &&
      !coros_auth &&
      (!activitiesVert || !activitiesVert.length);

    const items = (Items || []).map((post) => {
      if (
        !blockedUsers ||
        !blockedUsers.find((b) => b === post.cognito_user_sub)
      ) {
        return (
          // <Post
          //   userImg={avatarUrl}
          //   key={post.id}
          //   postData={post}
          //   tags={tagOptions}
          //   onAddAllez={this.handleAddAllez}
          //   userSub={sub}
          //   onAddComment={this.handleAddComment}
          //   onTagClick={this.handleTagSelect}
          //   onDelete={this.handleDelete}
          //   onEdit={this.handleEdit}
          //   onReport={this.handleReport}
          //   onBlock={this.handleBlock}
          //   isAdmin={isAdmin}
          //   onError={this.handleError}
          //   addingAllezOn={addingAllezOn}
          //   addingCommentOn={addingCommentOn}
          //   hasSub={!notSubscribed}
          //   isAuthenticated={isAuthenticated}
          //   openSignUp={this.handleOpenSignUp}
          //   relativeToParent={true}
          // ></Post>
          <PostCardComponent
            key={post.id}
            postData={post}
            postTags={tagOptions}
          />
        );
      }
      return <span key={post.id}></span>;
    });

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const langTags = tagOptions
      ? tagOptions.map((tag) => {
          return {
            label: tag.name[languageDetector]
              ? tag.name[languageDetector]
              : tag.name["en"],
            value: tag.id,
          };
        })
      : [];

    const isInMobileViewSm = width === "sm" || width === "xs";
    return (
      <div
        className={classes.container}
        id="thContainer"
        onScroll={() => {
          if (
            document.getElementById("thContainer").scrollTop === 0 ||
            isSearchingWithPhrase
          ) {
            this.setState({
              isSearchingWithFilters: true,
            });
          } else {
            this.setState({
              isSearchingWithFilters: false,
            });
          }
        }}
      >
        <div className={classes.top}>
          {/* {isAdmin && (
            <FormControlLabel
              classes={{ label: classes.onlyReported }}
              control={
                <Switch
                  checked={showReported}
                  onChange={this.handleChangeShowReported}
                  name="showReported"
                />
              }
              label="Show only reported posts"
            />
          )} */}

          <div></div>

          {isPosting === "main" && (
            <div className={classes.postOptions}>
              <div className={classes.postOptionsRow}>
                <div
                  className={classes.postOption}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      isPosting: "activities",
                    })
                  }
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-post-activity.svg`}
                    alt=""
                    className={classes.postOptionIcon}
                  />

                  <Typography variant="body1" className={this.postOptionText}>
                    {t("Activity")}
                  </Typography>
                </div>

                <div
                  className={classes.postOption}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      isPosting: "goal",
                    })
                  }
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-post-goal.svg`}
                    alt=""
                    className={classes.postOptionIcon}
                  />

                  <Typography variant="body1" className={this.postOptionText}>
                    {t("Goal")}
                  </Typography>
                </div>

                <div
                  className={classes.postOption}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      isPosting: "final",
                      type: "question",
                    })
                  }
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-post-question.svg`}
                    alt=""
                    className={classes.postOptionIcon}
                  />

                  <Typography variant="body1" className={this.postOptionText}>
                    {t("Question")}
                  </Typography>
                </div>
              </div>

              <div className={classes.postOptionsRow} style={{ marginTop: 10 }}>
                <div
                  className={classes.postOption}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      isPosting: "final",
                    })
                  }
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-post-text.svg`}
                    alt=""
                    className={classes.postOptionIcon}
                  />

                  <Typography variant="body1" className={this.postOptionText}>
                    {t("Text")}
                  </Typography>
                </div>

                <div className={classes.postOption}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file-2"
                    type="file"
                    onChange={this.handleInputChange}
                  />
                  <label
                    htmlFor="contained-button-file-2"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-post-image.svg`}
                      alt=""
                      className={classes.postOptionIcon}
                    />

                    <Typography variant="body1" className={this.postOptionText}>
                      {t("Image")}
                    </Typography>
                  </label>
                </div>

                <div
                  className={classes.postOption}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      isPosting: "badge",
                    })
                  }
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-post-badge.svg`}
                    alt=""
                    className={classes.postOptionIcon}
                  />

                  <Typography variant="body1" className={this.postOptionText}>
                    {t("Badge")}
                  </Typography>
                </div>
              </div>
            </div>
          )}

          {isPosting === "activities" &&
            (loadingActivities ? (
              <div className={classes.loading}>
                <CircularProgress variant="indeterminate" />
              </div>
            ) : (
              <DashboardRecentActivities
                activities={activities}
                units={units}
                noTracker={noTracker}
                isColumn={true}
              ></DashboardRecentActivities>
            ))}

          {isPosting === "goal" && (
            <DashboardNextRaceDetails
              isTrailhead={true}
              onEdit={this.handleGoalPost}
            ></DashboardNextRaceDetails>
          )}
          {/* 
          {isPosting === "badge" && (
            <FinishedChallenges
              onShareChallenge={this.handleBadgePost}
            ></FinishedChallenges>
          )} */}

          {isPosting === "final" ? (
            <>
              {currentImage ? (
                <div className={classes.postImageContainer}>
                  <img
                    src={currentImage}
                    alt=""
                    className={classes.postImage}
                  />
                </div>
              ) : (
                ""
              )}

              {currentGoal ? (
                <DashboardNextRacePane
                  nextRace={currentGoal}
                  userPlans={userPlans}
                  editing={false}
                  onEdit={null}
                  onSave={null}
                  onDelete={null}
                  isTrailhead={true}
                ></DashboardNextRacePane>
              ) : (
                ""
              )}
              {/* 
              {currentBadge ? (
                <FinishedChallengeH
                  yourEntry={currentBadge}
                  onShareChallenge={null}
                  units={this.state.units}
                ></FinishedChallengeH>
              ) : (
                ""
              )} */}

              <AutoCompleteMultiSelect
                suggestions={langTags}
                placeholder={t("Tags placeholder")}
                isClearable={true}
                value={postTags}
                onChange={(event) => this.handlePostTagSelect(event)}
                disable={false}
              />

              <div className={classes.publishButtonContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.publishButton}
                  onClick={this.handleSave}
                  disabled={!text}
                >
                  {t("Publish")}
                </Button>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        {!isPosting && tab === 0 && (
          <div className={classes.posts} id="posts">
            <InfiniteScroll
              dataLength={items.length} //This is important field to render the next data
              next={() => {
                data &&
                  data.length > 0 &&
                  this.handleLoadNextPage(data[data.length - 1].created_at);
              }}
              hasMore={true}
              scrollableTarget="thContainer"
            >
              {items}
            </InfiniteScroll>
            {loadingPosts && (
              <div className={classes.loading}>
                <CircularProgress variant="indeterminate" />
              </div>
            )}
          </div>
        )}

        {tab === 1 && (
          <div className={classes.posts} id="posts">
            {loadingPublicGroups && !publicGroups ? (
              <div className={classes.loading}>
                <CircularProgress variant="indeterminate" />
              </div>
            ) : (
              publicGroups.map((p) => (
                <PublicGroup
                  publicGroup={p}
                  key={p.id}
                  joiningGroup={joiningPublicGroup}
                  onJoinGroup={this.handleJoinPublicGroup}
                  userPlans={userPlans}
                ></PublicGroup>
              ))
            )}
          </div>
        )}
        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />

        <Dialog
          open={openSignUp}
          onClose={this.handleCloseSignUp}
          aria-labelledby="signUp"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signupdialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseSignUp}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

// TrailHead.propTypes = {
//   classes: PropTypes.object.isRequired,
//   t: PropTypes.func.isRequired,
//   history: PropTypes.shape({
//     push: PropTypes.func,
//   }),
// };

// TrailHead.defaultProps = {
//   maxSize: 10240000,
// };

export default connect((store) => {
  return {
    auth: store.auth,
    trailHead: store.trailHead,
    userPlans: store.userPlans,
    subscriptions: store.subscriptions,

    strava: store.strava,
    garmin: store.garmin,
    suunto: store.suunto,
    coros: store.coros,
    vertActivities: store.vertActivities,
  };
})(
  withTranslation("trailhead")(
    withWidth()(withStyles(styles, { withTheme: true })(PostList)),
  ),
);
