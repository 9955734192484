export const externalLinks = [
  { label: "All", value: "All" },
  { label: "Most Popular", value: "Popular" },
  { label: "Just added", value: "Just added" },
  { label: "Designed by Pro athletes", value: "Designed by Pro athletes" },
  { label: "UTMB World Series", value: "UTMB World Series" },
  { label: "SKYRUNNER World Series", value: "SKYRUNNER World Series" },
  { label: "X-terra World series", value: "X-terra World series" },
  { label: "Run The Alps", value: "Run The Alps" },
  { label: "City training", value: "City training" },
  { label: "Speed", value: "Speed" },
  { label: "Ultramarathon (50k+)", value: "Ultramarathon (50k+)" },
  { label: "Mid Distance (15k - 41k)", value: "Mid Distance (15k - 41k)" },
  { label: "Beginner (5k - 10k)", value: "Beginner (5k - 10k)" },
  { label: "Pre-Season", value: "Pre-Season" },
  { label: "Injury & Recovery", value: "Injury & Recovery" },
  { label: "Stage Races", value: "Stage Races" },
];
