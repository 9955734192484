import { useDispatch, useSelector } from "react-redux";
import { getUserActivities } from "../actions/user-plans-action";
import useUserInfo from "./useUserInfo";
import moment from "moment";

const useActivities = () => {
  const dispatch = useDispatch();
  const { currentPlan } = useUserInfo();

  const { activities: activitiesStrava, loading: stravaLoading } = useSelector(
    (state) => state.strava,
  );
  const { activities: activitiesGarmin, loading: garminLoading } = useSelector(
    (state) => state.garmin,
  );
  const { activities: activitiesSuunto, loading: suuntoLoading } = useSelector(
    (state) => state.suunto,
  );
  const { activities: activitiesCoros, loading: corosLoading } = useSelector(
    (state) => state.coros,
  );
  const { activities: activitiesVert, loading: vertLoading } = useSelector(
    (state) => state.vertActivities,
  );
  const {
    activities: healthActivities,
    loading: healthKitLoading,
  } = useSelector((state) => state.healthKit);

  const fullActivities = [
    ...(activitiesGarmin || []),
    ...(activitiesSuunto || []),
    ...(activitiesCoros || []),
    ...(activitiesStrava || []),
    ...(activitiesVert || []),
    ...(healthActivities || []),
  ];

  /** Using Map to eliminate duplicates via the activity start_date field*/
  const groupedActivities = new Map();
  for (const activity of fullActivities) {
    const { start_date } = activity;
    const startDateTime = new Date(start_date).getTime();
    if (!groupedActivities.has(startDateTime))
      groupedActivities.set(startDateTime, activity);
  }

  const activities = Array.from(groupedActivities.values());

  const runActivities = activities.length
    ? activities.filter(
        (a) =>
          (a.type && a.type.toLowerCase().includes("run")) ||
          (a.activityType && a.activityType.includes("RUN")),
      )
    : [];

  const sortedActivities = activities.length
    ? activities.sort((a, b) => {
        return moment(b.start_date).unix() - moment(a.start_date).unix();
      })
    : [];

  const handleGetActivities = () => {
    dispatch(getUserActivities(currentPlan));
  };

  const loading =
    stravaLoading ||
    garminLoading ||
    suuntoLoading ||
    vertLoading ||
    healthKitLoading ||
    corosLoading;

  return {
    handleGetActivities,
    activities,
    loading,
    sortedActivities,
    runActivities,
    activitiesGarmin,
    activitiesSuunto,
    activitiesVert,
    activitiesStrava,
    activitiesCoros,
    healthActivities,
    groupedActivities,
  };
};
export default useActivities;
