import React, { useState, memo } from "react";
import { withTranslation } from "react-i18next";
import { withStyles, Button } from "@material-ui/core";
import Card from "./components/card";
import { useDispatch } from "react-redux";
import {
  planChangeField,
  planChangeCurrentStep,
  getCurrentPlan,
} from "../../../actions/user-plans-action";
import { useSelector } from "react-redux";

const styles = () => ({
  main: {
    padding: "16px",
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    borderRadius: "10px",
    marginLeft: "auto",
    marginTop: "30px",
    display: "block",
  },
  selectedOption: {
    backgroundColor: "rgba(63, 81, 181, 0.2)",
  },
});

const options = [
  { icon: "hiking", text: "Hiking", value: "hiking" },
  {
    icon: "road-running",
    text: "Road Running",
    value: "road_running",
  },
  { icon: "road-cycling", text: "Road Cycling", value: "road_cycling" },
  {
    icon: "road-cycling",
    text: "Mountain or Gravel biking",
    value: "mountain_cycling",
  },
  {
    icon: "cross-training",
    text: "Cross country skiing",
    value: "cross_skiing",
  },
  { icon: "cross-training", text: "Alpine skiing", value: "alpine_skiing" },
  { icon: "swim", text: "Other Sports", value: "other_sports" },
  {
    icon: "swim",
    text: "I don't practice other sports",
    value: "no_other_sports",
  },
];

const PlanOtherSports = ({ classes, currentStep, t }) => {
  const { planTempData, currentPlan } = useSelector((state) => state.userPlans);
  const dispatch = useDispatch();
  const [currentOptions, setCurrenOptions] = useState(
    planTempData.other_sport || [],
  );

  const selectOption = (option) => {
    const copy = [...currentOptions];

    if (
      currentOptions[0] == "no_other_sports" &&
      option.value != "no_other_sports"
    ) {
      const index = currentOptions.findIndex((item) => item == option.value);
      copy.splice(index, 1);
      copy.push(option.value);
      setCurrenOptions(copy);
      return;
    }

    if (
      option.value == "no_other_sports" ||
      currentOptions.includes("no_other_sports")
    )
      return setCurrenOptions(["no_other_sports"]);

    const index = currentOptions.findIndex((item) => item == option.value);

    if (index === -1) {
      copy.push(option.value);
      setCurrenOptions(copy);
      return;
    }

    copy.splice(index, 1);
    setCurrenOptions(copy);
  };

  const nextAction = async () => {
    await dispatch(
      planChangeField({ name: "other_sport", value: currentOptions }),
    );
    await dispatch(planChangeCurrentStep(currentStep + 1));
  };

  const matchValues = (option) =>
    currentOptions.find((item) => item == option.value);

  return (
    <div className={classes.main}>
      {options.map((option, index) => (
        <Card
          key={index}
          icon={option.icon}
          text={t(option.text)}
          onClick={() => selectOption(option)}
          className={matchValues(option) ? classes.selectedOption : null}
        />
      ))}
      <Button
        variant="contained"
        color="primary"
        className={classes.btn}
        onClick={nextAction}
      >
        {t("Next")}
      </Button>
    </div>
  );
};

export default withTranslation("plan")(
  withStyles(styles, { withTheme: true })(memo(PlanOtherSports)),
);
