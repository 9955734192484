import { CardContent, Box, Typography } from "@material-ui/core";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { palette } from "../../../theme/palette";

const RaceCardSkeleton = () => {
  return (
    <CardContent
      style={{
        backgroundColor: palette.white,
        borderRadius: 8,
        marginBottom: 16,
        boxShadow: palette.boxShadow,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Skeleton circle width={40} height={40} />
          <Skeleton width={120} height={24} style={{ marginLeft: 10 }} />
        </Box>

        <Box display="flex" alignItems="center">
          <Skeleton width={30} height={30} style={{ marginRight: 10 }} />
          <Skeleton width={30} height={30} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Box display="flex" alignItems="center" marginBottom="8px">
          <Skeleton width={20} height={20} style={{ marginRight: 10 }} />
          <Skeleton width={60} height={20} />
        </Box>

        <Skeleton width={60} height={20} />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <Skeleton width={20} height={20} style={{ marginRight: 10 }} />
          <Skeleton width={60} height={20} />
        </Box>

        <Skeleton width={60} height={20} />
      </Box>

      <Typography
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          marginTop: "20px",
        }}
        variant="h4"
      >
        <Skeleton width={120} height={20} />
      </Typography>
    </CardContent>
  );
};

export default RaceCardSkeleton;
