import { Capacitor } from "@capacitor/core";
import { Facebook } from "@awesome-cordova-plugins/facebook";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import * as amplitude from "@amplitude/analytics-browser";
import { PlatformModifierPlugin } from "./enrichment-plugin";
import { Analytics } from "aws-amplify";

let subscription = null;
let subcategory = null;
let racecount = null;
let trainHoursPerWeek = null;
let customer_id = null;
let plan_id = null;
let device = null;

const facebookLogEvent = async (event) => {
  if (Capacitor.isNativePlatform() && process.env.NODE_ENV !== "development") {
    const eventName = event
      .split(" ")
      .join("_")
      .replace(/[^a-z0-9-_]/gi, "");
    Facebook.logEvent(eventName, {
      subscription,
      subcategory,
      racecount,
      trainHoursPerWeek,
    })
      .then((r) => console.log("logEvent ~~>" + r.res))
      .catch((err) => console.log("logEvent err ~~>" + err));
  }
};

const firebaseInit = async () => {
  if (!Capacitor.isNativePlatform()) {
    await FirebaseAnalytics.initializeFirebase({
      apiKey: "AIzaSyCweVffjqJJ7TdN6Ghbtsi2vyRjYyVKc-M",
      authDomain: "vertrun---ga4.firebaseapp.com",
      projectId: "vertrun---ga4",
      storageBucket: "vertrun---ga4.appspot.com",
      messagingSenderId: "1035340367709",
      appId: "1:1035340367709:web:a92cfd819ce3601f2c8ab4",
      measurementId: "G-TKHLQZ07VY",
    });
  }
  await FirebaseAnalytics.setCollectionEnabled({
    enabled:
      Capacitor.isNativePlatform() || process.env.NODE_ENV !== "development",
  });
};

const firebaseLogEvent = (event) => {
  const eventName = event
    .split(" ")
    .join("_")
    .replace(/[^a-z0-9-_]/gi, "");

  const params = {};
  if (subscription !== null) params.subscription = subscription;
  if (subcategory !== null) params.subcategory = subcategory;
  if (racecount !== null) params.racecount = racecount;
  if (trainHoursPerWeek !== null) params.trainHoursPerWeek = trainHoursPerWeek;
  if (device !== null) params.device = device;

  FirebaseAnalytics.logEvent({
    name: eventName,
    params: params,
  }).then((r) => console.log("logEvent ~~>" + JSON.stringify(r)));
};

const amplitudeInit = () => {
  amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY);

  /*try {
    amplitude.add(PlatformModifierPlugin);
  } catch {
    console.log("error attaching amplitude platform modifier plugin");
  }*/
};

const amplitudeLogEvent = (event) => {
  const eventName = event
    .split(" ")
    .join("_")
    .replace(/[^a-z0-9-_]/gi, "");

  amplitude.track(eventName, {
    os: Capacitor.isNativePlatform() ? Capacitor.getPlatform() : "web",
    device: Capacitor.getPlatform(),
  });
};

const logEvent = (event) => {
  facebookLogEvent(event);
  firebaseLogEvent(event);
  // amplitudeLogEvent(event);
  if (customer_id) {
    amplifyAnalyticsEvent(event);
  }
};

const setEventsMetadata = ({ sub, cat, races, train, custId, planId }) => {
  if (sub !== undefined) subscription = sub;
  if (cat !== undefined) subcategory = cat;
  if (races !== undefined) racecount = races;
  if (train !== undefined) trainHoursPerWeek = train;
  if (custId !== undefined) customer_id = custId;
  if (planId !== undefined) plan_id = planId;
};

const amplifyAnalyticsEvent = (event) => {
  const attributes = {};
  if (customer_id !== null && customer_id !== undefined) {
    attributes.customer_id = customer_id;
  }
  if (plan_id !== null && plan_id !== undefined) {
    attributes.plan_id = plan_id;
  }

  Analytics.record({
    name: event,
    attributes: attributes,
  })
    .then(() => {
      console.log(`Analytics event recorded: ${event}`, attributes);
    })
    .catch((error) => {
      console.error(`Failed to record analytics event: ${event}`, error);
    });
};

export { amplitudeInit, firebaseInit, logEvent, setEventsMetadata };
