import { palette } from "./palette";

export const componentsOverrides = {
  MuiButton: {
    contained: {
      textTransform: "none",
      borderRadius: "4px !important",
      "&:disabled": {
        background: palette.deactivated,
      },
    },
    outlined: {
      borderRadius: "4px !important",
    },
  },
};
