import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

import Geocode from "../../lib/geocode";

const styles = (theme) => ({
  textContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 14,
    paddingTop: 6,
    paddingBottom: 6,
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
    },
  },
  text: {
    color: "#202020",
    marginTop: "auto",
    paddingLeft: 9,
    paddingRight: theme.spacing.unit,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  imgIcon: {
    height: 20,
    [theme.breakpoints.down("md")]: {
      height: 16,
    },
  },
});

class DashboardUserCurrentLocation extends Component {
  async componentDidMount() {
    const {
      currentLocation,
      onError,
      onChanges,
      t,
      currentGeoLocation,
    } = this.props;
    if (!currentLocation || !currentGeoLocation)
      if (navigator.geolocation) {
        // Try HTML5 geolocation.
        navigator.geolocation.getCurrentPosition(async (position) => {
          try {
            const response = await Geocode.fromLatLng(
              position.coords.latitude,
              position.coords.longitude,
            );
            const { results } = response;
            if (results && results.length > 0) {
              const { address_components } = results[0];
              if (address_components) {
                const address = address_components
                  .filter((comp) => comp.types.indexOf("political") >= 0)
                  .map((comp) => comp.long_name)
                  .join(", ");
                if (onChanges)
                  onChanges({
                    currentLocation: address,
                    currentGeoLocation: {
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude,
                    },
                  });
              }
            }
          } catch (exp) {
            if (onError) {
              onError(
                t("Unable to update your current location", {
                  ns: "messages",
                }),
              );
            } else throw exp;
          }
        });
      } else if (onError)
        onError(
          new Error(
            t("Your browser does not support location service", {
              ns: "messages",
            }),
          ),
        );
  }

  render() {
    const { currentLocation, classes, t } = this.props;
    return (
      <div className={classNames(classes.textContainer)}>
        <img
          src={`${process.env.PUBLIC_URL}/icon-place.svg`}
          alt=""
          className={classes.imgIcon}
        />
        <Typography variant={"body1"} className={classes.text}>
          {currentLocation || t("The Mountains", { ns: "dashboard" })}
        </Typography>
      </div>
    );
  }
}

DashboardUserCurrentLocation.propTypes = {
  classes: PropTypes.object.isRequired,
  currentLocation: PropTypes.string,
  currentGeoLocation: PropTypes.object,
  isInMobileView: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default withTranslation(["dashboard", "messages"])(
  withStyles(styles)(DashboardUserCurrentLocation),
);
