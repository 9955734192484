import React from "react";
import useRaces from "../../../hooks/useRaces";
import { Typography, Box, Dialog, Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RaceCard } from "../../profile/Races/race-card";
import ToolbarReturnBack from "./ToolbarReturnBack";
import useCreatePost from "../../../hooks/useCreatePost";
import ModalCustom from "./ModalCustom";
import FormRace from "./FormRace";
import ButtonDashed from "./ButtonDashed";
import RaceCardSkeleton from "./RaceCardSkeleton";
import useRaceList from "../../../hooks/useRaceList";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  dialog: {
    display: "flex",
    flexDirection: "column",
    color: "#12151B0D",
  },

  headerBox: {
    flex: "0 0 auto",
    color: "#FEFFFF",
  },
  noContentBox: {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "16px",
    flex: "1 1 auto",
    overflowY: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    backgroundColor: "#FAFAFA",
  },
  heading: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  body: {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#12151B",
  },
});

const CommunityRacesDialog = ({ handleOpenDialog, handleCloseDialog }) => {
  const classes = useStyles();
  const { races, updating } = useRaces();
  const {
    openForm,
    handleSave,
    handleOpenForm,
    handleCloseForm,
  } = useRaceList();
  const { handleSetGoal } = useCreatePost();
  const { t } = useTranslation("community");
  const cardActions = {
    cardClick: true,
    mountainIndex: false,
    share: false,
    menu: false,
    edit: false,
    delete: false,
    link: false,
  };

  const handleCardClick = (race) => {
    handleSetGoal(race);
    handleCloseDialog(true);
  };

  return (
    <Dialog
      fullScreen
      open={handleOpenDialog}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <Box className={classes.headerBox}>
        <ToolbarReturnBack
          goBack={() => handleCloseDialog(false)}
          title={t("community.races.title")}
        />
      </Box>
      <Box className={classes.contentBox}>
        <Typography className={classes.heading}>
          {t("community.races.yourGoals")}
        </Typography>
        {updating ? (
          <>
            <RaceCardSkeleton />
            <RaceCardSkeleton />
            <RaceCardSkeleton />
          </>
        ) : races && races.length !== 0 ? (
          <>
            {races.map((race, index) => (
              <RaceCard
                key={index}
                data-testid={`select_goal${index + 1}`}
                id={`select_goal${index + 1}`}
                race={race}
                enableActions={cardActions}
                handleCardClick={handleCardClick}
              />
            ))}
            <ButtonDashed
              onClick={handleOpenForm}
              label={t("community.races.addRace")}
              withAddIcon={true}
            />
          </>
        ) : (
          <Box className={classes.noContentBox}>
            <Typography className={classes.body}>
              {t("community.races.noRaces")}
            </Typography>
            <ButtonDashed
              onClick={handleOpenForm}
              label={t("community.races.addRace")}
              withAddIcon={true}
            />
          </Box>
        )}
      </Box>
      <ModalCustom
        fullScreen={false}
        open={openForm}
        onClose={handleCloseForm}
        title={t("community.races.addRaceModal.title")}
      >
        <FormRace
          type={"add"}
          race={null}
          onCancel={handleCloseForm}
          onSave={handleSave}
        />
      </ModalCustom>
    </Dialog>
  );
};

export default CommunityRacesDialog;
