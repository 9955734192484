import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ModalCustom from "./ModalCustom";
import { palette } from "../../../theme/palette";
import HighlightText from "./HighlightText";
import { useTranslation } from "react-i18next";
import CustomButton from "./CustomButton";
import CheckIcon from "../../icons/CheckIcon";
import history from "../../../lib/history-helper";

const PaywallModal = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const benefits = [
    "Get weekly real-human coaching",
    "Move workouts around",
    "Add custom workouts",
    "Redo a week",
    "Add an easy week",
    "Restart your plan",
  ];

  return (
    <ModalCustom
      fullScreen={false}
      open={isOpen}
      onClose={onClose}
      toolbarJustifyContent="end"
      contentStyles={{
        padding: "0px 20px 16px",
      }}
    >
      <Grid container styles={{ gap: "6px 0" }}>
        <Grid item xs={12}>
          <Typography className={classes.modalTitle}>
            Want to{" "}
            <HighlightText color={palette.primary} text={"personalize"} /> your
            plan?
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ padding: "15px 0" }}>
          <Typography className={classes.modalDescription}>
            {t("Upgrade to personalize your training:")}
          </Typography>
          <List>
            {benefits.map((item, key) => (
              <ListItem
                key={key}
                classes={{
                  gutters: classes.listItemGutters,
                }}
              >
                <ListItemIcon
                  classes={{
                    root: classes.listItemIconRoot,
                  }}
                >
                  <CheckIcon state="fill" color={"#12151B"} />
                </ListItemIcon>
                <ListItemText
                  className={classes.modalDescription}
                  primary={t(item)}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Box pb={2}>
            <CustomButton
              color={"primary"}
              typeStyle={"accent"}
              variant="contained"
              size="large"
              onClick={() => {
                history.push("/subscription/explore");
              }}
              fullWidth
            >
              {t("Start Your 7 Day FREE Trial")}
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </ModalCustom>
  );
};

const useStyles = makeStyles(() => ({
  modalTitle: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 700,
    textAlign: "left",
    color: palette.black,
  },
  modalDescription: {
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",
    color: palette.black,
  },
  marginY: {
    marginTop: 15,
    marginBottom: 15,
  },
  listItemIconRoot: {
    minWidth: 22,
  },
  listItemGutters: {
    padding: "2px 8px",
  },
}));

PaywallModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default PaywallModal;
