import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { palette } from "../../../theme/palette";

const CardInfo = ({ title, description }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.marginY} ${classes.cardInfo}`}>
      <Typography className={classes.cardInfoTitle}>{title}</Typography>
      <Typography className={classes.cardInfoSubtitle}>
        {description}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  marginY: {
    marginTop: 15,
    marginBottom: 15,
  },
  cardInfo: {
    padding: 16,
    boxShadow: palette.boxShadow,
    borderRadius: 4,
    backgroundColor: palette.white,
  },
  cardInfoTitle: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 20,
  },
  cardInfoLink: {
    color: palette.lightBlue,
    fontWeight: 700,
    textDecoration: "underline",
  },
  cardInfoSubtitle: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 400,
  },
});

CardInfo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default CardInfo;
