import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Pusher from "pusher-js";

const PusherContext = createContext();

export const usePusher = () => useContext(PusherContext);

const pusher = new Pusher(process.env.REACT_APP_PUSHER_APIKEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  encrypted: true,
});

export const PusherProvider = ({ children }) => {
  const [channels, setChannels] = useState({});
  const channelsRef = useRef(channels);

  const subscribeToChannel = (channelName, eventName, callback) => {
    let channel = channelsRef.current[channelName];
    if (!channel) {
      channel = pusher.subscribe(channelName);
      console.info("Subscribed to channel", channelName, eventName);
      setChannels((prev) => {
        const newChannels = { ...prev, [channelName]: channel };
        channelsRef.current = newChannels;
        return newChannels;
      });
    }
    channel.bind(eventName, callback);
  };

  const unsubscribeFromChannel = (channelName, eventName, callback) => {
    const channel = channelsRef.current[channelName];
    if (channel) {
      channel.unbind(eventName, callback);
      pusher.unsubscribe(channelName);
      console.info("Unsubscribed to channel", channelName, eventName);
      setChannels((prev) => {
        const newChannels = { ...prev };
        delete newChannels[channelName];
        channelsRef.current = newChannels;
        return newChannels;
      });
    }
  };

  useEffect(() => {
    return () => {
      Object.keys(channelsRef.current).forEach((channelName) => {
        pusher.unsubscribe(channelName);
      });
    };
  }, []);

  return (
    <PusherContext.Provider
      value={{ subscribeToChannel, unsubscribeFromChannel }}
    >
      {children}
    </PusherContext.Provider>
  );
};
