import React from "react";
import { palette } from "../../theme/palette";

const LanguageIcon = ({
  width = 24,
  height = 24,
  activated = false,
  fill = null,
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7661 1.5H8.18548C7.6332 1.5 7.18548 1.94772 7.18548 2.5V4.20619H8.25806V2.58247H15.7661C16.0879 2.58247 16.3024 2.79897 16.3024 3.12371V9.07732C16.3024 9.40206 16.0879 9.61856 15.7661 9.61856H9.29435C9.01821 9.61856 8.79435 9.84241 8.79435 10.1186V13.4072H5.95202L3.96774 14.9768V13.4072H2.35887C2.0371 13.4072 1.82258 13.1907 1.82258 12.866V6.91237C1.82258 6.58763 2.0371 6.37113 2.35887 6.37113H9.33064V5.28866H2.35887C1.44718 5.28866 0.75 5.99227 0.75 6.91237V12.866C0.75 13.7861 1.44718 14.4897 2.35887 14.4897H2.89516V16.1959C2.89516 16.6175 3.38498 16.8498 3.7115 16.5831L6.27379 14.4897H8.86694C9.41922 14.4897 9.86694 14.042 9.86694 13.4897V10.701H15.7661C16.6778 10.701 17.375 9.99742 17.375 9.07732V3.12371C17.375 2.20361 16.6778 1.5 15.7661 1.5Z"
        fill={fill ? fill : activated ? palette.primary : palette.black}
      />
      <path
        d="M3.75 12H4.73901L4.98626 11.3258H6.26374L6.51099 12H7.5L6.0989 8.25H5.10989L3.75 12ZM5.6044 9.30337L6.01648 10.6096H5.19231L5.6044 9.30337Z"
        fill={fill ? fill : activated ? palette.primary : palette.black}
      />
      <path
        d="M11.25 8.25C11.6625 8.25 12.225 8.12727 12.75 7.84091C13.275 8.12727 13.875 8.25 14.25 8.25V7.43182C14.25 7.43182 13.875 7.43182 13.4625 7.26818C13.9125 6.77727 14.25 6.04091 14.25 4.97727V4.56818H13.125V3.75H12.375V4.56818H11.25V5.38636H13.4625C13.3875 6.12273 13.0875 6.57273 12.75 6.85909C12.525 6.65455 12.3 6.36818 12.15 6H11.3625C11.5125 6.53182 11.7375 6.94091 12.0375 7.26818C11.6625 7.43182 11.325 7.43182 11.25 7.43182V8.25Z"
        fill={fill ? fill : activated ? palette.primary : palette.black}
      />
    </svg>
  </>
);
export default LanguageIcon;
