import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { withStyles, Button } from "@material-ui/core";
import Card from "./components/card";
import { useDispatch } from "react-redux";
import {
  planChangeField,
  planChangeCurrentStep,
} from "../../../actions/user-plans-action";
import { useSelector } from "react-redux";

const styles = () => ({
  main: {
    padding: "16px",
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    borderRadius: "10px",
    marginLeft: "auto",
    marginTop: "30px",
    display: "block",
  },
  selectedOption: {
    backgroundColor: "rgba(63, 81, 181, 0.2)",
  },
});

const options = [
  {
    icon: "flat",
    text: "Only flat running, not access to elevation gain.",
    value: "flat",
  },
  {
    icon: "mountain",
    text: "Hilly running, not big mountains",
    value: "flat",
    value: "hilly_running",
  },
  {
    icon: "mountain",
    text: "Mountain running, access to long climbs",
    value: "climb_running",
  },
];

const PlanCondition = ({ classes, currentStep, t }) => {
  const { planTempData } = useSelector((state) => state.userPlans);
  const dispatch = useDispatch();
  const [currentOption, setCurrentOption] = useState(
    planTempData.condition || null,
  );

  const selectOption = (option) => setCurrentOption(option.value);

  const nextAction = async () => {
    await dispatch(
      planChangeField({ name: "condition", value: currentOption }),
    );
    await dispatch(planChangeCurrentStep(currentStep + 1));
  };

  return (
    <div className={classes.main}>
      {options.map((option, index) => (
        <Card
          key={index}
          icon={option.icon}
          text={t(option.text)}
          onClick={() => selectOption(option)}
          className={
            currentOption == option.value ? classes.selectedOption : null
          }
        />
      ))}

      <Button
        variant="contained"
        color="primary"
        className={classes.btn}
        disabled={!currentOption}
        onClick={nextAction}
      >
        {t("Next")}
      </Button>
    </div>
  );
};

export default withTranslation("plan")(
  withStyles(styles, { withTheme: true })(PlanCondition),
);
