import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useUserInfo from "./useUserInfo";
import { dateFormat } from "../lib/date-helper";
import bugsnagClient from "../lib/bugsnag-client";
import { patchSelectedAthleteWorkoutDeleteComment } from "../actions/athletes-action";
import {
  currentTrainingAddComment,
  currentTrainingAddCommentsRead,
  setNewMessagesByEvent,
  setUnreadMessages,
} from "../actions/user-plans-action";
import { useChatContext } from "../context/ChatProvider";
import { useTranslation } from "react-i18next";
import { logEvent } from "../lib/events-helper";

const useChat = () => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language: languageDetector },
  } = useTranslation("chat");
  const {
    isSubscribed: isExplore,
    name: userName,
    sub,
    name,
    profilePictureFilename,
    messages: allMessages,
    loadingMessages,
    currentPlan,
  } = useUserInfo();
  const { workoutToShare, setWorkoutToShare } = useChatContext();
  const { goBack } = useHistory();
  const contentViewRef = useRef(null);
  const newMessageRef = useRef(null);
  const inputTextRef = useRef(null);
  const replyToRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openReaction, setOpenReaction] = useState(false);
  const [messageSelected, setMessageSelected] = useState(null);
  const [messageSelectedIndex, setMessageSelectedIndex] = useState(null);
  const [messages, setMessages] = useState(null);
  const [inputText, setInputText] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [replyTo, setReplyTo] = useState(null);
  const [areYouSureForDelete, setAreYouSureForDelete] = useState(false);
  const [sharedWorkout, setSharedWorkout] = useState(null);
  const [currentCoachByLastMessage, setCurrentCoachByLastMessage] = useState(
    null,
  );

  const handleSendMessage = () => {
    logEvent("send_chat_message");
    if (inputText.trim() === "") return;

    const newMessage = {
      text: inputText,
      senderName: name,
      date: dayjs().format(dateFormat),
      dateTime: new Date().getTime(),
      replyTo: replyTo,
      workout: workoutToShare ? [workoutToShare] : null,
      type: 1,
      senderImage: profilePictureFilename ? profilePictureFilename : null,
    };
    setMessages((prevMessages) => [newMessage, ...prevMessages]);

    try {
      const { lastModified } = currentPlan;
      dispatch(
        currentTrainingAddComment(
          {
            cognito_user_sub: sub,
            comment: {
              ...newMessage,
              isReadByCoach: false,
              cognito_user_sub: sub,
              replyTo: replyTo,
            },
            lastModified,
          },
          false,
          true,
        ),
      );
      setInputText("");
      setReplyTo(null);
      setWorkoutToShare(null);
    } catch (error) {
      bugsnagClient.notify(error);
    }
  };

  const handleDeleteMessage = (messageId, sure = false) => {
    const messageToRemove = getMessageById(messageId);
    if (sure) {
      setAreYouSureForDelete(false);

      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== messageToRemove.id),
      );

      if (messageToRemove)
        dispatch(
          patchSelectedAthleteWorkoutDeleteComment(
            { cognito_user_sub: sub },
            messageToRemove,
          ),
        );
      setMessageSelected(null);
    } else {
      setAreYouSureForDelete(true);
      setMessageSelected(messageToRemove);
    }
  };

  const handleReply = (messageId) => {
    logEvent("send_chat_message");
    const messageToReply = getMessageById(messageId);
    setReplyTo(messageToReply);
  };

  const handleReactionSelected = (event, message, reaction) => {
    event.preventDefault();
    const newMessages = messages.map((msg) =>
      msg.id === message.id
        ? {
            ...msg,
            reaction: msg.reaction ? [...msg.reaction, reaction] : [reaction],
          }
        : msg,
    );
    setMessages(newMessages);
    setOpenReaction(false);
    setMessageSelectedIndex(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEmojiSelect = (emoji) => {
    setInputText((prevText) => prevText + emoji.emoji);
    setShowEmojiPicker(false);
  };

  const getLastCoachByMessage = (messages) => {
    setCurrentCoachByLastMessage(
      messages.find((message) => message.type === 0),
    );
  };

  const getMessageById = (messageId) => {
    if (!messageId) return null;
    return messages.find((message) => message.id === messageId);
  };

  const getPrettyTextMessage = (message) => {
    if (!message) return "";
    return message.isPlanMessage
      ? t(message.text, {
          ...message.payload,
          fillName:
            message.payload && message.payload.leadUpPlan
              ? message.payload.leadUpPlan[languageDetector]
                ? message.payload.leadUpPlan[languageDetector]
                : message.payload.leadUpPlan["en"]
              : "",
          fillName1:
            message.payload && message.payload.leadUpPlan1
              ? message.payload.leadUpPlan1[languageDetector]
                ? message.payload.leadUpPlan1[languageDetector]
                : message.payload.leadUpPlan1["en"]
              : "",
          fillName2:
            message.payload && message.payload.leadUpPlan2
              ? message.payload.leadUpPlan2[languageDetector]
                ? message.payload.leadUpPlan2[languageDetector]
                : message.payload.leadUpPlan2["en"]
              : "",
          planName:
            message.payload && message.payload.plan
              ? message.payload.plan[languageDetector]
                ? message.payload.plan[languageDetector]
                : message.payload.plan["en"]
              : "",
          name: userName,
        })
      : message.isWelcomeMessage
      ? isExplore
        ? t("chat.welcomeMessage", { name: userName })
        : t("chat.welcomeMessageFree", {
            name: userName,
          })
      : message.isWelcomeMessage
      ? isExplore
        ? t("chat.welcomeMessage", { name: userName })
        : t("chat.welcomeMessageFree", {
            name: userName,
          })
      : message.isCancellationMessage
      ? t("chat.cancellationMessage", {
          name: userName,
        })
      : message.text;
  };

  const notifyNewMessage = (isNotify) => {
    dispatch(setUnreadMessages(isNotify));
  };

  const setAllAsRead = () => {
    if (sub) dispatch(currentTrainingAddCommentsRead(sub));
    notifyNewMessage(false);
  };

  const insertNewMessageByEvent = (message) => {
    dispatch(setNewMessagesByEvent(message));
  };

  return {
    openDialog,
    openReaction,
    messages,
    messageSelectedIndex,
    showEmojiPicker,
    currentCoachByLastMessage,
    contentViewRef,
    newMessageRef,
    inputText,
    goBack,
    areYouSureForDelete,
    replyTo,
    loadingMessages,
    inputTextRef,
    replyToRef,
    messageSelected,
    sharedWorkout,
    allMessages,
    setOpenDialog,
    setOpenReaction,
    setMessages,
    setMessageSelected,
    setReplyTo,
    setMessageSelectedIndex,
    setShowEmojiPicker,
    setInputText,
    handleCloseDialog,
    handleSendMessage,
    handleEmojiSelect,
    handleReactionSelected,
    handleDeleteMessage,
    handleReply,
    setAreYouSureForDelete,
    setSharedWorkout,
    getPrettyTextMessage,
    setAllAsRead,
    getLastCoachByMessage,
    insertNewMessageByEvent,
    notifyNewMessage,
  };
};

export default useChat;
