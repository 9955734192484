import React, { Component } from "react";
import {
  withMobileDialog,
  Typography,
  Dialog,
  DialogContent,
  CircularProgress,
  Drawer,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { getLevel } from "../../lib/rates-helper";
import { convertUnits, round } from "../../lib/units-helper";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

const styles = (theme) => ({
  container: {},

  noPadding: {
    padding: "0 !important",
    background: "#E5E5E5",
  },

  topContainer: {
    display: "flex",
    padding: 20,
    backgroundColor: "#FFFFFF",
  },

  progressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  loading: {
    margin: "auto",
  },

  title: {
    fontWeight: "500",
    fontSize: 18,
  },

  summary: {
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "0px 0px 12px 12px",
    paddingBottom: 20,
  },

  summaryBadge: {
    height: 100,
    position: "absolute",

    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  nameText: {
    fontWeight: 500,
    fontSize: 18,
    margin: "12px 0",
  },

  summaryStats: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },

  summaryStatDistance: {
    color: "#FF5722",
  },

  summaryStatElevation: {
    color: "#3F51B5",
  },

  summaryValue: {
    fontWeight: "500",
    fontSize: 16,
  },

  summaryLabel: {
    fontWeight: "normal",
    fontSize: 14,
  },

  vDivider: {
    borderLeft: "1px solid #E0E0E0",
    height: 24,
  },

  levelsContainer: {
    padding: 20,
  },

  disclaimer: {
    fontWeight: "normal",
    fontSize: 12,
    textAlign: "center",
    color: "#9E9E9E",
    marginBottom: 20,
  },

  levelContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },

  levelBadge: {
    height: 70,
    marginRight: 20,
  },

  levelName: {
    fontWeight: 500,
    fontSize: 18,
    marginBottom: 6,
  },

  levelMinMax: {
    display: "flex",
    marginTop: 4,
  },

  levelMinMaxValues: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#9E9E9E",
    marginLeft: 5,
  },

  drawerRoot: {
    minHeight: "100vh",
    maxWidth: "500px",
    minWidth: "500px",
    background: "#EFF3F5",
    overflowX: "hidden",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "100vw",
      minWidth: "100vw",
    },
  },

  backIcon: {
    cursor: "pointer",
  },

  doneChart: {
    width: "100%",
    height: 200,
    position: "relative",
  },

  chart: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },

  chart2: {
    position: "absolute",
    height: "85%",
    width: "85%",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },
});

class TrailLevelDetails extends Component {
  componentDidMount = () => {};

  render() {
    const { classes, t, isOpen, onClose, userPlans, auth, width } = this.props;

    const { currentUser } = auth;
    let currentUserFullName = "";
    if (currentUser) {
      const { attributes } = currentUser;
      if (attributes) {
        const { name, family_name, email } = attributes;
        currentUserFullName =
          name || family_name ? `${name} ${family_name || ""}`.trim() : email;
      }
    }

    const { currentPlan, loading } = userPlans;

    const { accumulatedDistance = 0, accumulatedElevation = 0 } =
      currentPlan || {};

    const level = getLevel(accumulatedDistance, accumulatedElevation);

    const from = "kms";

    const units = localStorage.getItem("units") === "miles" ? "miles" : "kms";

    const levels = [
      {
        level: 1,
        distanceMin: 0,
        distanceMax: 79,
        elevationMin: 0,
        elevationMax: 4999,
      },
      {
        level: 2,
        distanceMin: 80,
        distanceMax: 199,
        elevationMin: 5000,
        elevationMax: 7999,
      },
      {
        level: 3,
        distanceMin: 200,
        distanceMax: 499,
        elevationMin: 8000,
        elevationMax: 11999,
      },
      {
        level: 4,
        distanceMin: 500,
        distanceMax: 999,
        elevationMin: 12000,
        elevationMax: 19999,
      },
      {
        level: 5,
        distanceMin: 1000,
        distanceMax: 2499,
        elevationMin: 20000,
        elevationMax: 34999,
      },
      {
        level: 6,
        distanceMin: 2500,
        distanceMax: 4999,
        elevationMin: 35000,
        elevationMax: 49999,
      },
      {
        level: 7,
        distanceMin: 5000,
        distanceMax: 14999,
        elevationMin: 50000,
        elevationMax: 99999,
      },
      {
        level: 8,
        distanceMin: 15000,
        distanceMax: -1,
        elevationMin: 100000,
        elevationMax: -1,
      },
    ];

    const isInMobileView = width === "sm" || width === "xs";

    const levelData = levels[level - 1];

    const distancePercentage =
      level === 8
        ? 100
        : ((accumulatedDistance / 1000) * 100) / (levelData.distanceMax + 1);

    const elevationPercentage =
      level === 8
        ? 100
        : (accumulatedElevation * 100) / (levelData.elevationMax + 1);

    const distanceLoad = {
      labels: ["Done", "Remaining"],
      datasets: [
        {
          label: "Distance",
          data: [distancePercentage, 100 - distancePercentage],
          backgroundColor: ["#FF5722", "#F9F9FB"],
          borderWidth: 1,
        },
      ],
    };

    const elevationLoad = {
      labels: ["Done", "Remaining"],
      datasets: [
        {
          label: "Elevation",
          data: [elevationPercentage, 100 - elevationPercentage],
          backgroundColor: ["#3F51B5", "#F9F9FB"],
          borderWidth: 1,
        },
      ],
    };

    const optionsDistance = {
      cutout: "80%",
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      animation: {
        easing: "linear",
        duration: 1000,
      },
    };

    const optionsElevation = {
      cutout: "93%",
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      animation: {
        easing: "linear",
        duration: 1000,
      },
    };

    const content = loading ? (
      <div className={classes.container}>
        <div className={classes.topContainer}>
          <ArrowBackIosIcon
            onClick={() => onClose()}
            className={classes.backIcon}
            fontSize="small"
          />
        </div>

        <div className={classes.progressContainer}>
          <CircularProgress
            variant="indeterminate"
            color="primary"
            className={classes.loading}
            data-testid="LoadingActivity"
          />
        </div>
      </div>
    ) : (
      <div className={classes.container}>
        <div className={classes.topContainer}>
          <ArrowBackIosIcon
            onClick={() => onClose()}
            className={classes.backIcon}
            fontSize="small"
          />

          <Typography variant="h3" className={classes.title}>
            {t("Trail level")}
          </Typography>
        </div>
        <div className={classes.summary}>
          <div className={classes.doneChart}>
            <div className={classes.chart}>
              <Doughnut data={distanceLoad} options={optionsDistance} />
            </div>
            <div className={classes.chart2}>
              <Doughnut data={elevationLoad} options={optionsElevation} />
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/trl${level}.svg`}
              className={classes.summaryBadge}
            />
          </div>

          <Typography variant="body1" className={classes.nameText}>
            {currentUserFullName}
          </Typography>

          <div className={classes.summaryStats}>
            <div className={classes.summaryStatDistance}>
              <Typography variant="h3" className={classes.summaryValue}>
                {round(
                  convertUnits(accumulatedDistance, from, units, "distance") /
                    1000,
                  0,
                )}{" "}
                {units === "miles" ? `${t("miles")}` : `${t("km")}`}
              </Typography>
              <Typography variant="h3" className={classes.summaryLabel}>
                {t("distance")}
              </Typography>
            </div>

            <div className={classes.vDivider}></div>

            <div className={classes.summaryStatElevation}>
              <Typography variant="h3" className={classes.summaryValue}>
                {round(
                  convertUnits(accumulatedElevation, from, units, "elevation"),
                  0,
                )}{" "}
                {units === "miles" ? `${t("feet")}` : `${t("m")}`}
              </Typography>
              <Typography variant="h3" className={classes.summaryLabel}>
                {t("elevation")}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.levelsContainer}>
          <Typography variant="h3" className={classes.disclaimer}>
            {t(
              "You will level up as soon as you reach any value distance or elevation",
            )}
          </Typography>
          {levels.map((l) => (
            <div key={l.level} className={classes.levelContainer}>
              <img
                src={`${process.env.PUBLIC_URL}/trl${l.level}.svg`}
                className={classes.levelBadge}
              />

              <div>
                <Typography variant="h3" className={classes.levelName}>
                  {t("Level")} {l.level}
                </Typography>

                <div className={classes.levelMinMax}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-level-distance.svg`}
                    className={classes.levelMinMaxIcon}
                  />

                  <Typography
                    variant="h3"
                    className={classes.levelMinMaxValues}
                  >
                    {round(
                      convertUnits(l.distanceMin, from, units, "distance"),
                      0,
                    )}
                    {l.distanceMax >= 0 ? ` - ` : ""}
                    {l.distanceMax >= 0
                      ? round(
                          convertUnits(l.distanceMax, from, units, "distance"),
                          0,
                        )
                      : ""}{" "}
                    {units === "miles" ? `${t("miles")}` : `${t("km")}`}
                    {l.distanceMax >= 0 ? "" : t(" and more")}
                  </Typography>
                </div>

                <div className={classes.levelMinMax}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-level-elevation.svg`}
                    className={classes.levelMinMaxIcon}
                  />

                  <Typography
                    variant="h3"
                    className={classes.levelMinMaxValues}
                  >
                    {round(
                      convertUnits(l.elevationMin, from, units, "elevation"),
                      0,
                    )}
                    {l.elevationMax >= 0 ? ` - ` : ""}
                    {l.elevationMax >= 0
                      ? round(
                          convertUnits(
                            l.elevationMax,
                            from,
                            units,
                            "elevation",
                          ),
                          0,
                        )
                      : ""}{" "}
                    {units === "miles" ? `${t("feet")}` : `${t("m")}`}
                    {l.elevationMax >= 0 ? "" : t(" and more")}
                  </Typography>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );

    return isInMobileView ? (
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth={true}
        fullScreen={true}
        style={{
          paddingLeft: "env(safe-area-inset-left)",
          paddingRight: "env(safe-area-inset-right)",
          paddingTop: "env(safe-area-inset-top)",
          paddingBottom: "env(safe-area-inset-bottom)",
        }}
      >
        <DialogContent className={classes.noPadding}>{content}</DialogContent>
      </Dialog>
    ) : (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        classes={{
          paper: classes.drawerRoot,
        }}
      >
        {content}
      </Drawer>
    );
  }
}

TrailLevelDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  fullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  userPlans: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
    auth: store.auth,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation("dashboard")(
      withWidth()(withMobileDialog()(TrailLevelDetails)),
    ),
  ),
);
