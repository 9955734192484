import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogActions, Button, Typography } from "@material-ui/core";

import {
  selectAthlete,
  patchSelectedAthleteWorkoutCommentsRead,
  athleteData,
} from "../../actions/athletes-action";
import { connect } from "react-redux";

import AthleteTrainingWorkoutViewer from "../athletes/athlete-training-comments/athlete-training-workout-viewer";
import UserGroups from "../../constants/user-groups";

const styles = (theme) => ({
  container: {
    background: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
  },
  commentsTitleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  commentsTitle: {
    color: "#202020",
    fontSize: 20,
    fontWeight: 500,
    marginRight: 50,
  },
  comment: {
    background: "#F5F9FF",
    borderRadius: 8,
    padding: "15px 15px",
    width: 200,
    marginBottom: 10,
    cursor: "pointer",
  },
  commentText: {
    fontSize: 13,
  },
  commentFooter: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  commentDateText: {
    marginRight: "auto",
    fontSize: 10,
    paddingRight: 10,
  },
});

class AthletesComments extends Component {
  state = {
    isOpenWorkoutViewer: false,
  };

  handleAthleteSelect = (athlete) => {
    const { cognito_user_sub } = athlete;
    if (cognito_user_sub) {
      this.props.dispatch(athleteData(athlete));
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            isOpenWorkoutViewer: true,
          }),
        100,
      );
    }
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      isOpenWorkoutViewer: false,
    });

    const { athletes, auth } = this.props;
    const { selectedAthlete } = athletes;
    const { selectedWorkout } = selectedAthlete;

    const { currentUserGroups } = auth;
    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;

    if (!isAdmin) {
      this.props.dispatch(
        patchSelectedAthleteWorkoutCommentsRead(
          selectedAthlete,
          selectedWorkout.plannedDate,
          selectedWorkout.id,
        ),
      );
    }
  };

  async componentDidMount() {}

  render() {
    const { classes, newComments, onOpenChat } = this.props;

    const { isOpenWorkoutViewer } = this.state;

    const { athletes } = this.props;
    const { searchResult } = athletes || {};
    const { data } = searchResult;

    return (
      <div className={classes.container}>
        <div className={classes.commentsTitleContainer}>
          <Typography variant="body1" className={classes.commentsTitle}>
            User comment log
          </Typography>
        </div>

        {newComments.map((c, index) => {
          const athlete = data.find(
            (a) => a.cognito_user_sub === c.cognito_user_sub,
          );

          const { cognito_user_sub, image, imageUrl } = athlete || {};

          return (
            <div
              key={index}
              className={classes.comment}
              onClick={() => onOpenChat(athlete)}
            >
              <Typography variant="body1" className={classes.commentText}>
                {c.text}
              </Typography>
              <div className={classes.commentFooter}>
                <Typography variant="body1" className={classes.commentDateText}>
                  {c.date}
                </Typography>
                <Typography variant="body1" className={classes.commentDateText}>
                  {c.senderName}
                </Typography>
              </div>
            </div>
          );
        })}

        <Dialog
          open={isOpenWorkoutViewer}
          onClose={() => this.handleClose()}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="lg"
          disableEnforceFocus
        >
          <AthleteTrainingWorkoutViewer
            onClose={() => this.handleClose()}
          ></AthleteTrainingWorkoutViewer>
        </Dialog>
      </div>
    );
  }
}

AthletesComments.propTypes = {
  classes: PropTypes.object.isRequired,
  newComments: PropTypes.array,
  dispatch: PropTypes.func,
};

export default connect((store) => {
  return {
    athletes: store.athletes,
    auth: store.auth,
  };
})(withStyles(styles)(AthletesComments));
