const Fields = {
  TEXT_INPUT: "textInput",
  CHECKBOX_GROUP: "checkboxGroup",
  DATE: "date",
  DROPDOWN: "dropdown",
  LABEL: "label",
  PICTURE: "picture",
  TIME: "time",
  TOOLTIP: "tooltip",
  GRID_LABEL: "gridLabel",
  YES_NO: "yesNo",
  BUTTON_SELECT: "buttonSelect",
  GOAL_SELECT: "goalSelect",
  TIME_SELECT: "timeSelect",
  STEPPER_SELECT: "stepperSelect",
  SLIDER_SELECT: "sliderSelect",
  CARD_SELECT: "cardSelect",
  CHIPS_SELECT: "chipSelect",
  STEPPER: "stepper",
  IMAGE: "image",
  INVITATION: "invitation",
  PERSONAL_DATA: "personalData",
  CONDITION: "condition",
  OTHER_SPORT: "otherSport",
};

export default {
  Items: Fields,
  itemsArray: Object.keys(Fields).map((key) => {
    return {
      key: key,
      value: Fields[key],
    };
  }),
};
