import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CheckIcon = ({
  width = 14,
  height = 14,
  state = "outline",
  marginTop = "0",
  color = "#6D6F71",
}) => {
  let fill = color;

  if (state === "highlighted" || state === "outline") {
    fill = "#aac5f9";
  }

  return (
    <SvgIcon style={{ width: width, height: height, marginTop: marginTop }}>
      <svg viewBox="0 0 14 14" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.0193 3.73361C12.167 3.88307 12.25 4.08575 12.25 4.29709C12.25 4.50842 12.167 4.7111 12.0193 4.86056L6.11351 10.8343C6.03547 10.9132 5.94281 10.9759 5.84083 11.0186C5.73885 11.0613 5.62954 11.0833 5.51915 11.0833C5.40877 11.0833 5.29946 11.0613 5.19748 11.0186C5.0955 10.9759 5.00284 10.9132 4.92479 10.8343L1.99055 7.86681C1.91529 7.79329 1.85526 7.70534 1.81397 7.60811C1.77267 7.51087 1.75094 7.40629 1.75003 7.30047C1.74912 7.19464 1.76906 7.08969 1.80867 6.99175C1.84829 6.8938 1.9068 6.80482 1.98078 6.72998C2.05476 6.65515 2.14273 6.59597 2.23957 6.5559C2.3364 6.51583 2.44015 6.49566 2.54478 6.49658C2.6494 6.4975 2.75279 6.51949 2.84892 6.56126C2.94505 6.60302 3.03199 6.66374 3.10468 6.73986L5.51889 9.18184L10.9046 3.73361C10.9778 3.65955 11.0647 3.6008 11.1603 3.56072C11.256 3.52063 11.3585 3.5 11.462 3.5C11.5655 3.5 11.668 3.52063 11.7636 3.56072C11.8593 3.6008 11.9461 3.65955 12.0193 3.73361Z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  );
};

export default CheckIcon;
