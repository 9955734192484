import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  Button,
  Link,
  Typography,
  LinearProgress,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import ConnectAuth from "../connectors/connect-auth";
import { Auth, I18n } from "aws-amplify";
import { ForgotPassword } from "aws-amplify-react";
import classNames from "classnames";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Capacitor } from "@capacitor/core";
import { clearSignIn } from "../actions/athletes-action";
import { connect } from "react-redux";

const styles = (theme) => ({
  container: {
    marginBottom: 40,
    textAlign: "center",
  },
  logoImg: {
    width: 112,
  },
  topTitle: {
    fontWeight: "bold",
    textAlign: "left",
    lineHeight: "30px",
    marginTop: 16,
    fontSize: 24,
  },
  topSubTitle: {
    fontWeight: 500,
    fontSize: 14,
    maxWidth: "auto",
    lineHeight: "20px",
    textAlign: "left",
    color: "gray",
  },
  paper: {
    ...(theme.formSection || {}),
    padding: "10px 26px 0 26px",
    width: 320,
    minWidth: 240,
    margin: "auto",
    textAlign: "center",
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  title: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  button: {
    marginTop: 20,
    textTransform: "none",
  },
  checkboxLabel: {
    color: theme.palette.text.primaryText,
  },
  TextField: {
    margin: "10px 0",
  },
  loginText: {
    marginTop: 10,
  },
  forgetPasswordLinkBlock: {
    marginTop: 20,
    marginBottom: 0,
    color: "gray",
  },
  signUpLinkBlock: {
    marginTop: 10,
  },
  hidden: {
    opacity: 0,
  },
  white: {
    color: theme.palette.common.white,
  },
  section: {
    marginTop: 8,
    marginBottom: 20,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: 10,
    color: "gray",
  },
  visibilityIcon: {
    [theme.breakpoints.up("sm")]: {
      color: "white",
    },
  },
  linearProgress: {
    marginTop: 15,
    marginBottom: 15,
  },
  submitButton: {
    marginTop: 20,
    boxShadow: "none",
    borderRadius: 26,
  },
  passwordReset: {
    marginLeft: 2,
  },
  resendButton: {
    fontSize: 14,
    color: "gray",
    textTransform: "none",
    fontWeight: "normal",
  },
});

class CustomForgotPassword extends ForgotPassword {
  state = {
    email: "",
    code: "",
    loading: false,
    step: 0,
    showPassword: false,
  };

  handleInputChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { id, value } = target;
        if (id) {
          this.setState({
            [id]: value || "",
          });
        }
      }
    }
  };

  handleForgotPassword = (event) => {
    event.preventDefault();
    const { email, code, password, step } = this.state;
    this.setState({
      ...this.state,
      loading: true,
    });

    if (step === 0) {
      this.sendCode(email);
    }

    if (step === 1) {
      Auth.forgotPasswordSubmit(email, code, password)
        .then((data) => {
          this.setState({
            ...this.state,
            loading: false,
            step: 2,
          });
        })
        .catch((err) => {
          this.setState({
            ...this.state,
            loading: false,
          });
          this.error(err);
        });
    }

    if (step === 2) {
      this.handleSignIn();
    }
  };

  sendCode = (email) => {
    Auth.forgotPassword(email)
      .then((data) => {
        this.setState({
          ...this.state,
          loading: false,
          step: 1,
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          loading: false,
        });
        this.error(err);
      });
  };

  handleSignIn = () => {
    this.props.dispatch(clearSignIn());
    const { onStateChange, setAuthPage } = this.props;
    if (onStateChange) {
      this.setState({
        ...this.state,
        loading: false,
        step: 0,
      });
      onStateChange("signIn");
      setAuthPage("sign-in");
    }
  };

  showComponent(theme) {
    const { classes } = this.props;
    const { loading, showPassword, step, password, code, email } = this.state;
    return (
      <form className={classes.container} onSubmit={this.handleForgotPassword}>
        <Paper className={classes.paper} elevation={0}>
          <Typography variant="h1" className={classes.topTitle}>
            {step === 0
              ? I18n.get("Password Recovery")
              : step === 1
              ? I18n.get("Reset your password")
              : I18n.get("Password Recovery Complete")}
          </Typography>
          {step < 2 ? (
            <Typography variant="h2" className={classes.topSubTitle}>
              {step === 0
                ? I18n.get("Enter your email to recover your password")
                : I18n.get(
                    "An authentication code has been sent to your email",
                  )}
            </Typography>
          ) : (
            ""
          )}
          {step === 0 ? (
            <TextField
              id="email"
              value={email}
              type="email"
              fullWidth
              required
              className={classes.TextField}
              onChange={this.handleInputChange}
              label={I18n.get("Email")}
              variant="outlined"
            />
          ) : step === 1 ? (
            <>
              <TextField
                id="code"
                value={code}
                label={I18n.get("Authentication Code")}
                type="text"
                fullWidth
                required
                className={classes.TextField}
                onChange={this.handleInputChange}
              />
              <TextField
                id="password"
                value={password}
                label={I18n.get("New Password")}
                type={showPassword ? "text" : "password"}
                fullWidth
                required
                className={classes.TextField}
                onChange={this.handleInputChange}
                color="primary"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={classes.inputAdornment}
                    >
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() =>
                          this.setState({ showPassword: !showPassword })
                        }
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />{" "}
            </>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            color="primary"
            autoFocus={!Capacitor.isNativePlatform()}
            fullWidth
            size="large"
            type="submit"
            disabled={loading}
            className={classes.submitButton}
          >
            {step === 0
              ? I18n.get("Continue")
              : step === 1
              ? I18n.get("Submit")
              : I18n.get("Back to Log In")}
          </Button>

          {step === 1 ? (
            <Button
              fullWidth
              size="large"
              disabled={loading}
              onClick={() => this.sendCode(email)}
              className={classes.resendButton}
            >
              {I18n.get("Resend code")}
            </Button>
          ) : (
            ""
          )}

          {step < 2 ? (
            <Typography
              variant="body1"
              className={classNames(classes.loginText)}
            >
              <Link
                className={classes.loginLink}
                onClick={() => {
                  this.handleSignIn();
                }}
              >
                {I18n.get("Back to Log In")}
              </Link>
            </Typography>
          ) : (
            ""
          )}
          <LinearProgress
            color="primary"
            className={classNames(classes.linearProgress, {
              [classes.hidden]: !loading,
            })}
            variant="indeterminate"
          />
        </Paper>
      </form>
    );
  }
}

CustomForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  setAuthPage: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    auth: store.auth,
  };
})(ConnectAuth(withStyles(styles, { withTheme: true })(CustomForgotPassword)));
