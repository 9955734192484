import React from "react";

import ComponentTextArea from "./common/info-supp";
import NavbarSettings from "./common/navbar-settings";
import { useTranslation } from "react-i18next";
const ReportAProblem = () => {
  const { t } = useTranslation();
  return (
    <NavbarSettings
      component={
        <ComponentTextArea
          title={t("We'll get back to you as soon as possible.")}
          descriptionng={t(
            "If you're experiencing a bug or other issue, let us know here.",
          )}
          description={t(
            "Your feedback is important to us so that we can keep improving Vert.run. Thank you!",
          )}
          ng={true}
          textArea={t("Tell us about your issue")}
        />
      }
      text={t("Report a problem")}
    />
  );
};
export default ReportAProblem;
