import React, { useEffect } from "react";
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  makeStyles,
  OutlinedInput,
  Slide,
  Toolbar,
  Typography,
  Grow,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import EmojiPicker from "emoji-picker-react";
import { useTranslation } from "react-i18next";
import { palette } from "../../../theme/palette";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FormControl } from "@mui/material";
import { CloseIcon, SendIcon } from "../../icons";
import { EmojiEmotionsOutlined as EmojiEmotionsIcon } from "@material-ui/icons";
import ChatAvatar from "./ChatAvatar";
import ChatMessage from "./ChatMessage";
import useChat from "../../../hooks/useChat";
import gsap from "gsap";
import ChatMessageContent from "./ChatMessageContent";
import ModalCustom from "./ModalCustom";
import CustomButton from "./CustomButton";
import WorkoutCard from "./WorkoutCard";
import useUserInfo from "../../../hooks/useUserInfo";
import { convertMinutesToTimeLabel } from "../../../constants/estimated-times";
import { WorkoutTypesLangKeys } from "../../../constants/workout-types";
import { getFixCustomLevel } from "../../../lib/plan-helper";
import { logEvent } from "@amplitude/analytics-browser";

export const TypeMessage = {
  0: "COACH",
  1: "ATHLETE",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Chat = ({ isOpen, onClose, workout, setWorkoutToShare }) => {
  const classes = useStyles();
  const { t } = useTranslation("chat");
  const { chainedPlans, currentTraining } = useUserInfo();

  const {
    allMessages,
    areYouSureForDelete,
    openReaction,
    messages,
    messageSelectedIndex,
    showEmojiPicker,
    currentCoachByLastMessage,
    contentViewRef,
    inputTextRef,
    replyToRef,
    inputText,
    messageSelected,
    loadingMessages,
    replyTo,
    setAreYouSureForDelete,
    setReplyTo,
    setOpenReaction,
    setMessageSelectedIndex,
    setShowEmojiPicker,
    setInputText,
    handleSendMessage,
    handleEmojiSelect,
    handleReactionSelected,
    handleDeleteMessage,
    handleReply,
    getPrettyTextMessage,
    setMessages,
    setAllAsRead,
    getLastCoachByMessage,
    notifyNewMessage,
  } = useChat();

  const focusInputTextField = () => {
    if (inputTextRef.current) {
      inputTextRef.current.focus();
      inputTextRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    if (allMessages && allMessages.length > 0) {
      const sortedMessages = allMessages.sort(
        (a, b) => b.dateTime - a.dateTime,
      );
      const cloneSortedMessage = [...sortedMessages];
      setMessages(sortedMessages);
      getLastCoachByMessage(cloneSortedMessage);
    }
  }, [allMessages]);

  useEffect(() => {
    if (isOpen) {
      notifyNewMessage(false);
      logEvent("open_chat");
      if (contentViewRef.current) {
        // Alternative way to ensure scroll
        requestAnimationFrame(() => {
          contentViewRef.current.scrollTop =
            contentViewRef.current.scrollHeight;
        });
      }
    }
  }, [isOpen, messages]);

  useEffect(() => {
    if (replyTo != null) {
      focusInputTextField();
      gsap.fromTo(
        replyToRef.current,
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.5, ease: "power3.out" },
      );
    }
  }, [replyTo]);

  useEffect(() => {
    focusInputTextField();
    setAllAsRead();
  }, []);

  return (
    <>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={() => onClose()}
        TransitionComponent={Transition}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Box
          sx={{
            flex: "0 0 auto",
            bgcolor: "#FAFAFA",
          }}
        >
          <AppBar position="sticky" elevation={0}>
            <Toolbar className={classes.toolbar}>
              <IconButton id="back_post" onClick={() => onClose()}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h4" className={classes.titleFont}>
                {t(`chat.coach`)}{" "}
                {currentCoachByLastMessage && (
                  <>{currentCoachByLastMessage.senderName}</>
                )}
              </Typography>
              <ChatAvatar message={currentCoachByLastMessage} onAppbar />
            </Toolbar>
          </AppBar>
        </Box>

        <Box
          id="contentView"
          className={classes.contentView}
          ref={contentViewRef}
        >
          {loadingMessages && <LinearProgress color="secondary" />}
          {messages &&
            messages.map((message, index) => (
              <>
                {TypeMessage[message.type] === "COACH" ? (
                  <ChatMessage
                    key={index}
                    index={index}
                    message={message}
                    isLastMessageOfSender={index === 0}
                    messageSelectedIndex={messageSelectedIndex}
                    openReaction={openReaction}
                    handleReactionSelected={handleReactionSelected}
                    handleReply={handleReply}
                    onClickMessage={() => {
                      setOpenReaction(true);
                      setMessageSelectedIndex(index);
                    }}
                  />
                ) : (
                  <ChatMessage
                    key={index}
                    index={index}
                    sender={true}
                    message={message}
                    isLastMessageOfSender={index === 0}
                    messageSelectedIndex={messageSelectedIndex}
                    handleDeleteMessage={handleDeleteMessage}
                  />
                )}
              </>
            ))}
        </Box>

        <Box className={classes.contentOutlineInput}>
          <Grow in={showEmojiPicker}>
            <Box
              sx={{
                position: "absolute",
                bottom: "60px",
                left: "10px",
                zIndex: 1,
              }}
            >
              <EmojiPicker onEmojiClick={handleEmojiSelect} />
            </Box>
          </Grow>
          <Grid container spacing={1} alignItems="flex-end">
            {(replyTo || workout) && (
              <Grid
                item
                xs={12}
                ref={replyToRef}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                {replyTo && (
                  <ChatMessageContent
                    propStyles={{ width: "100%", maxWidth: "100%" }}
                    customClass={
                      TypeMessage[replyTo.type] === "COACH"
                        ? classes.received
                        : classes.sender
                    }
                    text={getPrettyTextMessage(replyTo)}
                    date={replyTo.date}
                  />
                )}

                {workout && (
                  <WorkoutCard
                    variant={workout ? workout.workoutType : "NoWorkout"}
                    primaryText={
                      workout.workoutType
                        ? workout.workoutType === "UserCreated"
                          ? workout.userCreatedType
                          : t(WorkoutTypesLangKeys[workout.workoutType], {
                              ns: "training",
                            })
                        : ""
                    }
                    secondaryText={
                      workout.estimatedTime
                        ? convertMinutesToTimeLabel(
                            !isNaN(
                              +workout.estimatedTime[
                                getFixCustomLevel(
                                  workout.plannedDate,
                                  chainedPlans,
                                  currentTraining,
                                )
                              ],
                            )
                              ? +workout.estimatedTime[
                                  getFixCustomLevel(
                                    workout.plannedDate,
                                    chainedPlans,
                                    currentTraining,
                                  )
                                ]
                              : +workout.estimatedTime,
                          )
                        : ""
                    }
                    isDone={workout.isDone}
                    withCheck
                    margin="0"
                    paperStylesProp={{ width: "100%" }}
                  />
                )}

                <IconButton
                  size="small"
                  disableRipple
                  disableFocusRipple
                  aria-label="close"
                  onClick={() => {
                    setReplyTo(null);
                    setWorkoutToShare(null);
                  }}
                >
                  <CloseIcon
                    data-testid="close_icon"
                    id="close_icon"
                    width="14px"
                    height="14px"
                  />
                </IconButton>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  ref={inputTextRef}
                  className={classes.inputMessage}
                  size="small"
                  multiline
                  disableUnderline="true"
                  placeholder={t("chat.inputMessagePlaceholder")}
                  color="secondary"
                  value={inputText}
                  classes={{
                    input: classes.overflowInput,
                  }}
                  onChange={(e) => setInputText(e.target.value)}
                  startAdornment={
                    <IconButton
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    >
                      <EmojiEmotionsIcon />
                    </IconButton>
                  }
                  endAdornment={
                    <IconButton
                      data-testid="send_comment"
                      id="send_comment"
                      position="end"
                      onClick={handleSendMessage}
                    >
                      <SendIcon width={24} height={24} />
                    </IconButton>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <ModalCustom
          fullScreen={false}
          open={areYouSureForDelete}
          onClose={() => setAreYouSureForDelete(false)}
          toolbarJustifyContent="end"
          headerStyles={{
            padding: "8px 5px !important",
          }}
          contentStyles={{
            padding: "8px 20px 10px",
            gap: 8,
          }}
        >
          <Grid container styles={{ gap: "6px 0" }}>
            <Grid item xs={12}>
              <Typography className={classes.modalTitle}>
                {t("chat.modalDelete.title")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box py={2}>
                <CustomButton
                  color="default"
                  typeStyle="secondary"
                  variant="contained"
                  size="large"
                  onClick={() => setAreYouSureForDelete(false)}
                  fullWidth
                >
                  {t("chat.buttons.cancel")}
                </CustomButton>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box py={2} pl={1}>
                <CustomButton
                  color="secondary"
                  typeStyle="primary"
                  variant="contained"
                  size="large"
                  onClick={() => handleDeleteMessage(messageSelected.id, true)}
                  fullWidth
                >
                  {t("chat.buttons.delete")}
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
        </ModalCustom>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles({
  overflowInput: {
    maxHeight: 72,
    overflowY: "auto !important",
    scrollbarWidth: "none",
  },
  titleFont: {
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 700,
    color: palette.black,
  },
  toolbar: {
    display: "flex",
    backgroundColor: palette.white,
    justifyContent: "space-between",
    minHeight: 60,
  },

  inputMessage: {
    maxHeight: 72,
    height: "100%",
    minHeight: "100%",
    padding: 0,
  },
  contentOutlineInput: {
    margin: 20,
  },
  contentView: {
    display: "flex",
    flex: "1 1 auto",
    overflowY: "auto",
    scrollbarWidth: "none",
    maxWidth: "100vh",
    flexDirection: "column-reverse",
    msOverflowStyle: "none",
    //position: "fixed",
    backgroundColor: palette.lightGrayOpacity,
    //zIndex: 200,
  },
  modalTitle: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 700,
    textAlign: "left",
    color: palette.black,
  },
  received: {
    backgroundColor: palette.lightPink,
    borderColor: palette.primary,
    borderRadius: "0px 8px 8px 8px",
  },
  sender: {
    backgroundColor: palette.lightBlueHove,
    borderColor: palette.lightBlueHover,
    borderRadius: "8px 0px 8px 8px",
  },
  box: {
    display: "flex",
    padding: "24px 0px",
    boxSizing: "border-box",
    width: "100%",
  },
});

export default Chat;
