import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { useSelector } from "react-redux";
import { Dialog, DialogContent, TextField, Button } from "@material-ui/core";
import classNames from "classnames";
import SnackBarMessageDisplay from "../../common/snack-bar-message-display";
import { useDispatch } from "react-redux";
import {
  editActivityVert,
  getActivityVert,
} from "../../../actions/vert-activity-action";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getUserActivities } from "../../../actions/user-plans-action";

const styles = (theme) => ({
  dialogPaper: {
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  inputStyle: {
    marginBottom: "16px !important",
    marginTop: "0px !important",
  },
  smallerFontSize: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller",
    },
  },
  input: {
    backgroundColor: "#F9F9FB",
  },
  radiusButton: {
    borderRadius: 50,
  },
  title: {
    marginBottom: "16px",
    fontWeight: "bold",
  },
  buttonParent: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "8px",
  },
  form: {
    marginTop: "8px",
  },
});

const EditForm = ({ classes, t, onClose }) => {
  const dispatch = useDispatch();
  const { userPlans } = useSelector((state) => state);
  const {
    currentUser: { attributes },
  } = useSelector((state) => state.auth);
  const { sub } = attributes || {};
  const { currentActivity, loadingEdit } = useSelector(
    (state) => state.vertActivities,
  );
  const { name, description, created_at } = currentActivity || {};

  const [showError, setShowError] = useState(false);
  const [formValues, setFormValues] = useState({
    name: name ? name : "",
    description: description ? description : "",
  });

  const handleInput = (event) => {
    const {
      target: { value, name },
    } = event;
    setFormValues({ ...formValues, [name]: value });
  };

  const editActivity = async () => {
    const isNotValid = Object.values(formValues).every((item) => item.length);
    if (!isNotValid) return handleError();

    const data = {
      cognito_user_sub: sub,
      created_at,
      name: formValues.name,
      description: formValues.description,
    };

    await dispatch(editActivityVert(data));
    await dispatch(
      getActivityVert({ cognito_user_sub: sub, workoutId: created_at }),
    );
    await getAllActivities();
    onClose();
  };

  const handleError = () => {
    setShowError(true);
    setTimeout(() => setShowError(false), 3000);
  };

  const getAllActivities = () => {
    const { currentPlan } = userPlans || {};
    dispatch(getUserActivities(currentPlan));
  };

  return (
    <>
      {showError && (
        <SnackBarMessageDisplay
          variant="error"
          open={showError}
          errorMessage="All fields are required"
        />
      )}

      <Dialog
        open={true}
        onClose={onClose}
        aria-labelledby="tracking"
        scroll="body"
        maxWidth="md"
        className={classes.dialog}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogContent className={classes.dialogContent}>
          <form className={classes.form}>
            <TextField
              fullWidth
              variant="filled"
              id="name"
              name="name"
              margin="normal"
              value={formValues.name}
              readOnly
              label={t("Name")}
              onChange={handleInput}
              className={classes.inputStyle}
              inputProps={{ maxLength: 100 }}
              InputLabelProps={{
                classes: { root: classes.smallerFontSize },
              }}
              InputProps={{
                disableUnderline: true,
                classes: { root: classes.input },
              }}
            />

            <TextField
              fullWidth
              variant="filled"
              id="description"
              name="description"
              margin="normal"
              value={formValues.description}
              inputProps={{ maxLength: 250 }}
              multiline
              readOnly
              label={t("Description")}
              onChange={handleInput}
              className={classes.inputStyle}
              InputLabelProps={{
                classes: { root: classes.smallerFontSize },
              }}
              InputProps={{
                disableUnderline: true,
                classes: { root: classes.input },
              }}
            />

            <div className={classes.buttonParent}>
              {!loadingEdit ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={editActivity}
                  className={classNames(
                    classes.radiusButton,
                    classes.smallerFontSize,
                  )}
                >
                  {t("Edit").toUpperCase()}
                </Button>
              ) : (
                <div>
                  <CircularProgress variant="indeterminate" size="25px" />
                </div>
              )}
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withTranslation(
  "dashboard",
  "messages",
)(withWidth()(withStyles(styles)(EditForm)));
