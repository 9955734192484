import React, { useEffect, useState, memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Close from "@material-ui/icons/Close";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { Typography, Slider, Button } from "@material-ui/core";
import classNames from "classnames";
import history from "../../../../lib/history-helper";
import { useDispatch, useSelector } from "react-redux";
import {
  currentTrainingUpdateWorkoutEnergyLevel,
  setTrainingDayWorkout,
} from "../../../../actions/user-plans-action";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { dateFormat } from "../../../../lib/date-helper";

const labels = [2, 4, 6, 8, 10];

const optionsLoad = {
  cutout: "80%",
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  animation: {
    easing: "linear",
    duration: 1000,
  },
};

const rateCommon = {
  backgroundColor: "#FFF",
  position: "fixed",
  left: 0,
  width: "100%",
  transition: "all .5s",
  padding: "16px",
  boxSizing: "border-box",
  borderRadius: "16px 16px 0px 0px",
  zIndex: 1012,
};

const styles = (theme) => ({
  showRate: {
    ...rateCommon,
    bottom: "0%",
  },
  rate: {
    ...rateCommon,
    bottom: "-100%",
  },
  closeParent: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closeIcon: {
    cursor: "pointer",
  },
  circle: {
    width: 50,
    height: 50,
    position: "relative",
  },
  circleParent: {
    display: "flex",
    justifyContent: "center",
    margin: "8px 0",
  },
  title: {
    fontWeight: "bold",
    marginBottom: 12,
  },
  overlay: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 1011,
  },
  notShow: {
    display: "none",
  },
  effort: {
    width: "100%",
    display: "flex",
    margin: "10px 0 5px",

    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-end",
    },
  },
  effortLabel: {
    fontSize: 14,
    textAlign: "center",
    color: "#212121",
    fontWeight: 400,

    [theme.breakpoints.down("sm")]: {
      maxWidth: 70,
    },
  },
  effortParent: {
    margin: "18px 16px",
  },
  buttons: {
    borderRadius: 10,
  },
  radiusButton: {
    borderRadius: 50,
  },
  smallerFontSize: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller",
    },
  },
  buttonsParent: {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0",
    alignItems: "center",
  },
  back: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#FF5722",
    cursor: "pointer",
  },
  number: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "50%",
    top: "50%",
    fontWeight: "bold",
  },
  statsSubtitle: {
    color: "#9E9E9E",
    fontSize: 14,
  },
  topContainer: {
    display: "flex",
    padding: 20,
    backgroundColor: "#FFFFFF",
  },
  labels: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const BottomModal = ({
  classes,
  showRate,
  showingRate,
  openMethod,
  t,
  onClearTimer,
}) => {
  const myHistory = useHistory();
  const [params, setParams] = useState({});

  useEffect(() => {
    if (myHistory.location.state && myHistory.location.state.from) {
      if (myHistory.location.state.from.includes("save")) {
        openMethod();
        setEffortLevel(myHistory.location.state.rpe / 2);
        setLabel(myHistory.location.state.rpe / 10);
      }
    }
  }, []);

  const { userPlans } = useSelector((state) => state);
  const { currentPlan, updatingWorkoutId } = userPlans || {};
  const { currentTraining = {} } = currentPlan || {};
  const { selectedTrainingDayWorkoutTemp } = currentTraining || {};
  const [effortLevel, setEffortLevel] = useState(0);
  const [label, setLabel] = useState(0);

  const dispatch = useDispatch();

  const data = {
    labels: ["Load", "Empty"],
    datasets: [
      {
        data: [
          effortLevel,
          effortLevel > 0 && effortLevel < 50 ? 50 - effortLevel : 0,
        ],
        backgroundColor: ["#FF5722", "#FFCCBC"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const path = window.location.href;
    const splitedPath = path.split("/");

    setParams({ id: splitedPath[5], plannedDate: splitedPath[6] });

    const selectedTrainingDayWorkout =
      currentTraining && currentTraining.days && currentTraining.days.length
        ? currentTraining.days.find(
            (trDay) =>
              trDay &&
              trDay[0].plannedDate ===
                (splitedPath[6] ? splitedPath[6] : moment().format(dateFormat)),
          )
        : null;

    if (
      selectedTrainingDayWorkout &&
      selectedTrainingDayWorkout.length &&
      selectedTrainingDayWorkout[0].rpe
    ) {
      setEffortLevel(selectedTrainingDayWorkout[0].rpe / 2);
      setLabel(selectedTrainingDayWorkout[0].rpe / 10);
    }

    if (selectedTrainingDayWorkout && selectedTrainingDayWorkout.length) {
      dispatch(
        setTrainingDayWorkout({
          workoutId: selectedTrainingDayWorkout[0].id,
          plannedDate: selectedTrainingDayWorkout[0].plannedDate,
        }),
      );
    }
  }, [showRate]);

  const normalizeLabel = (value) => {
    if (value == 0) return 0;
    if (value <= 10) return 2;
    if (value > 10 && value <= 20) return 4;
    if (value > 20 && value <= 30) return 6;
    if (value > 30 && value <= 40) return 8;
    if (value > 40 && value <= 50) return 10;
  };

  const handleEnergyLevelChangeRpe = (value) => {
    const newValue = normalizeLabel(value);
    setEffortLevel(value);
    setLabel(newValue);
  };

  const normalizeValue = (value) => {
    if (value <= 20) return 20;
    if (value > 20 && value <= 40) return 40;
    if (value > 40 && value <= 60) return 60;
    if (value > 60 && value <= 80) return 80;
    if (value > 80 && value <= 100) return 100;
  };

  const handleWorkoutEnd = () => {
    const realValue = normalizeValue(effortLevel);

    if (selectedTrainingDayWorkoutTemp) {
      dispatch(
        currentTrainingUpdateWorkoutEnergyLevel(
          {
            ...selectedTrainingDayWorkoutTemp,
            workoutId: selectedTrainingDayWorkoutTemp.id,
            plannedDate: selectedTrainingDayWorkoutTemp.plannedDate,
            cognito_user_sub: currentPlan.cognito_user_sub,
            rpe: realValue,
            visualRpe: effortLevel,
          },
          currentTraining,
          0,
          false,
          false,
        ),
      );
    }
  };

  const goToSave = () => {
    onClearTimer();
    const path = `/tracking/save/${label}`;
    const currentPath = history.location.pathname;
    history.push(path, { from: currentPath });
  };

  return (
    <>
      <div className={!showRate ? classes.notShow : classes.overlay}></div>
      <div className={!showRate ? classes.rate : classes.showRate}>
        <div className={classes.closeParent}>
          <div onClick={showingRate}>
            <Close />
          </div>
        </div>

        <div className={classes.circleParent}>
          <div className={classes.circle}>
            <Doughnut data={data} options={optionsLoad} />
            <Typography className={classes.number}>{label}</Typography>
          </div>
        </div>

        <Typography variant="body2" className={classes.title}>
          {t("How did this activity felt")}
        </Typography>

        {/* <div className={classes.effort}>
          <Typography variant="h6" className={classes.effortLabel}>
            {t("Easy")}
          </Typography>

          <Typography
            variant="h6"
            className={classes.effortLabel}
            style={{
              marginLeft: "auto",
            }}
          >
            {t("Max Effort")}
          </Typography>
        </div>

        <div className={classes.labels}>
          {labels.map((item) => (
            <Typography variant="h6" className={classes.effortLabel} key={item}>
              {item}
            </Typography>
          ))}
        </div> */}

        {/* <p>{label}</p> */}

        <Slider
          value={effortLevel}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="off"
          onChange={(event, value) => handleEnergyLevelChangeRpe(value)}
          onChangeCommitted={handleWorkoutEnd}
          min={0}
          max={50}
          color="primary"
        />

        <div className={classes.buttonsParent}>
          <Typography
            variant="body1"
            className={classes.back}
            onClick={showingRate}
          >
            {t("Back to Tracker")}
          </Typography>

          <Button
            onClick={goToSave}
            variant="contained"
            color="primary"
            className={classNames(
              classes.radiusButton,
              classes.smallerFontSize,
            )}
          >
            {t("Next")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("tracking")(memo(BottomModal)),
);
