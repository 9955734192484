import React, { useEffect, useState } from "react";
import Message from "./settings/components/message/message";
import BasicRating from "./settings/components/rating/rating";
import { useSelector } from "react-redux";
import RouterLink from "../components/common/router-link";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getCoaches } from "../actions/athletes-action";
import NavbarSettings from "./common/navbar-settings";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../theme";
import { useTranslation } from "react-i18next";
import useUserInfo from "../hooks/useUserInfo";
import { palette } from "../theme/palette";

const SettingsAccount = () => {
  const styles = useStyles();
  const { t } = useTranslation("settings");
  const [activate, setActivate] = useState(false);
  const [title, setTitle] = useState("");
  const [secondTitle, setSecondTitle] = useState("");
  const [rateCoachState, setRateCoachState] = useState(0);
  const [ratePlanState, setRatePlanState] = useState(0);
  const [textArea, setTextArea] = useState("");
  const [type, setType] = useState("");

  const [marginTextArea, setMaringTextArea] = useState(0);
  const auth = useSelector((state) => state.auth);
  const { userPlans, currentPlan } = useUserInfo();
  const { customerCoachRate, customerPlanRate } = userPlans;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCoaches());
  }, []);
  const alertRateYourCoach = () => {
    setActivate(true);
    setType("coach");
    setTitle("settings.alertRateYourCoach.title");
    setSecondTitle("settings.alertRateYourCoach.secondTitle");
    setTextArea("settings.alertRateYourCoach.textArea");
    setMaringTextArea(0);
  };
  const alertRateYourPlan = () => {
    setActivate(true);
    setType("plan");
    setTitle("settings.alertRateYourPlan.title");
    setSecondTitle("settings.alertRateYourPlan.secondTitle");
    setTextArea("settings.alertRateYourPlan.textArea");
    setMaringTextArea(0);
  };
  const alertCoachChange = () => {
    setType("requestCoachChange");
    setActivate(true);
    setTitle("settings.alertCoachChange.title");
    setSecondTitle("settings.alertCoachChange.secondTitle");
    setTextArea("settings.alertCoachChange.textArea");
    setMaringTextArea(40);
  };

  useEffect(() => {
    if (customerCoachRate.rate) setRateCoachState(customerCoachRate.rate);
  }, [customerCoachRate]);

  useEffect(() => {
    if (customerPlanRate.rate) setRatePlanState(customerPlanRate.rate);
  }, [customerPlanRate]);

  return (
    <NavbarSettings
      component={
        <div className={styles.optionsContainer}>
          <div className={styles.container}>
            <div className={styles.textBold}>{t("settings.account.name")}</div>
            <div className={styles.textRegular}>
              {auth.currentUser.attributes.name}
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.textBold}>{t("settings.account.email")}</div>
            <div className={styles.textRegular}>
              {auth.currentUser.attributes.email}
            </div>
          </div>

          {/**
          {auth.currentUserGroups !== undefined ? (
            ""
          ) : (
          **/}
          <div className={styles.container}>
            <div className={styles.textBold}>
              {t("settings.account.password")}
            </div>
            <RouterLink to="/profile/settings/account/recovery-password">
              <Typography className={styles.textUnderline}>
                {t("settings.account.changePassword")}
              </Typography>
            </RouterLink>
          </div>
          {/** )} */}
          <div className={styles.columnContainer}>
            <div className={styles.container} style={{ justifyContent: "end" }}>
              <RouterLink to="/chooseplan">
                <div className={styles.textUnderlineClickable}>
                  {t("settings.account.changePlan")}
                </div>
              </RouterLink>
            </div>
          </div>
        </div>
      }
      text={t("settings.account")}
      message={
        <Message
          title={t(title)}
          activate={activate}
          setActivate={setActivate}
          marginTextArea={marginTextArea}
          secondText={t(secondTitle)}
          textArea={t(textArea)}
          rateState={type === "coach" ? rateCoachState : ratePlanState}
          type={type}
        />
      }
    />
  );
};

export default SettingsAccount;

const useStyles = makeStyles({
  container: {
    alignItems: "flex-start",
    alignSelf: "stretch",
    display: "flex",
    flex: "0 0 auto",
    justifyContent: "space-between",
    position: "relative",
    width: "100%",
  },
  optionsContainer: {
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "24px 0px",
    position: "relative",
    width: "100%",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  textBold: {
    color: "rgba(18, 21, 27, 1)",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5,
    fontStyle: "normal",
    fontWeight: "700",
    letterSpacing: "0px",
    lineHeight: "150%",
    marginTop: "-1px",
    position: "relative",
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  textRegular: {
    color: "rgba(18, 21, 27, 1)",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "0px",
    lineHeight: "150%",
    marginTop: "-1px",
    position: "relative",
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  textUnderline: {
    color: "rgba(71, 128, 171, 1)",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "0px",
    lineHeight: "150%",
    marginTop: "-1px",
    position: "relative",
    textDecoration: "underline",
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  columnContainer: {
    alignItems: "flex-start",
    alignSelf: "stretch",
    display: "flex",
    flex: "0 0 auto",
    flexDirection: "column",
    gap: "12px",
    position: "relative",
    width: "100%",
  },
  textUnderlineClickable: {
    color: "rgba(71, 128, 171, 1)",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "0px",
    lineHeight: "150%",
    position: "relative",
    textDecoration: "underline",
    whiteSpace: "nowrap",
    width: "fit-content",
    cursor: "pointer",
  },
  planContainer: {
    alignItems: "flex-start",
    alignSelf: "stretch",
    justifyContent: "space-between",
    display: "flex",
    flex: "0 0 auto",
    gap: "24px",
    position: "relative",
    width: "100%",
  },
  planName: {
    alignSelf: "stretch",
    color: "rgba(18, 21, 27, 1)",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: "400",
    letterSpacing: "0",
    lineHeight: "24px",
    marginTop: "-1px",
    position: "relative",
    textAlign: "right",
    width: "238px",
  },
  starsContainer: {
    flex: "0 0 auto",
    position: "relative",
  },
  name: {
    color: "rgba(18, 21, 27, 1)",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: "400",
    letterSpacing: "0",
    lineHeight: "24px",
    marginTop: "-1px",
    position: "relative",
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  iconTitleContainer: {
    alignItems: "center",
    display: "inline-flex",
    flex: "0 0 auto",
    gap: "8px",
    position: "relative",
  },
});
