import TrainingDifficultyLevels from "../constants/training-difficulty-levels";
import RaceCounts from "../constants/race-counts";
import TrainingPerWeek from "../constants/training-per-week";

const TrainingDifficultyLevelToRaceCount = (trainingDifficultyLevel) => {
  const raceCountsItemsArray = RaceCounts.itemsArray;
  const trainingDifficultyLevelsItemsArray =
    TrainingDifficultyLevels.itemsArray;
  switch (trainingDifficultyLevel) {
    case trainingDifficultyLevelsItemsArray[0].value:
      return `${raceCountsItemsArray[0].value},${raceCountsItemsArray[1].value}`;
    case trainingDifficultyLevelsItemsArray[1].value:
      return `${raceCountsItemsArray[2].value},${raceCountsItemsArray[3].value}`;
    case trainingDifficultyLevelsItemsArray[2].value:
      return `${raceCountsItemsArray[4].value},${raceCountsItemsArray[5].value}`;
    default:
      return `${raceCountsItemsArray[0].value},${raceCountsItemsArray[1].value}`;
  }
};

const RaceCountPlanToTraining = (raceCount) => {
  const raceCountsItemsArray = RaceCounts.itemsArray;
  switch (raceCount) {
    case raceCountsItemsArray[0].value:
      return `${raceCountsItemsArray[0].value},${raceCountsItemsArray[1].value}`;
    case raceCountsItemsArray[1].value:
      return `${raceCountsItemsArray[0].value},${raceCountsItemsArray[1].value}`;
    case raceCountsItemsArray[2].value:
      return `${raceCountsItemsArray[2].value},${raceCountsItemsArray[3].value}`;
    case raceCountsItemsArray[3].value:
      return `${raceCountsItemsArray[2].value},${raceCountsItemsArray[3].value}`;
    case raceCountsItemsArray[4].value:
      return `${raceCountsItemsArray[4].value},${raceCountsItemsArray[5].value}`;
    case raceCountsItemsArray[5].value:
      return `${raceCountsItemsArray[4].value},${raceCountsItemsArray[5].value}`;
    default:
      return `${raceCountsItemsArray[0].value},${raceCountsItemsArray[1].value}`;
  }
};

const TrainingPerWeekPlanToTraining = (trainingPerWeek) => {
  const trainingPerWeekItemsArray = TrainingPerWeek.itemsArray;
  switch (trainingPerWeek) {
    case trainingPerWeekItemsArray[0].value:
      return `${trainingPerWeekItemsArray[0].value}`;
    case trainingPerWeekItemsArray[1].value:
      return `${trainingPerWeekItemsArray[1].value},${trainingPerWeekItemsArray[2].value}`;
    case trainingPerWeekItemsArray[2].value:
      return `${trainingPerWeekItemsArray[1].value},${trainingPerWeekItemsArray[2].value}`;
    case trainingPerWeekItemsArray[3].value:
      return `${trainingPerWeekItemsArray[3].value},${trainingPerWeekItemsArray[4].value}`;
    case trainingPerWeekItemsArray[4].value:
      return `${trainingPerWeekItemsArray[3].value},${trainingPerWeekItemsArray[4].value}`;
    default:
      return `${trainingPerWeekItemsArray[0].value}`;
  }
};

export {
  TrainingDifficultyLevelToRaceCount,
  RaceCountPlanToTraining,
  TrainingPerWeekPlanToTraining,
};
