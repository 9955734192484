import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation, withTranslation } from "react-i18next";

const useStyles = makeStyles({
  a: {
    backgroundColor: "#F93C6E",
  },
  b: {
    backgroundColor: "#4780AB",
  },
  c: {
    backgroundColor: "#6BB488",
  },
  circle: {
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginRight: "8px",
  },
  circleText: {
    color: "white",
    fontWeight: "bold",
  },
  texta: {
    color: "#F93C6E",
  },
  textb: {
    color: "#4780AB",
  },
  textc: {
    color: "#6BB488",
  },
  leftText: {
    marginLeft: "8px",
  },
  rightText: {
    color: "darkgray",
  },
  mountainIndex: {
    fontFamily: "Karla",
    textAlign: "center",
    fontWeight: 700,
  },
  raceName: {
    wordBreak: "break-all",
    fontWeight: "700",
    maxWidth: "80%",
  },
});

const RaceShortInfo = ({ raceName, racePriority, raceMI }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      marginBottom="15px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          className={`${classes.circle} ${classes[racePriority.toLowerCase()]}`}
        >
          <Typography className={classes.circleText} variant="h3">
            {racePriority}
          </Typography>
        </Box>
        <Typography variant="h4" className={classes.raceName}>
          {raceName}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography className={classes.mountainIndex} variant="h4">
          {t("M. INDEX")} = {raceMI}
        </Typography>
      </Box>
    </Box>
  );
};
export default RaceShortInfo;
