import React, { useState, useRef, useEffect } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import SnackBarMessageDisplay from "../../components/common/snack-bar-message-display";
import {
  Dialog,
  DialogContent,
  Typography,
  TextField,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import history from "../../lib/history-helper";
import { useDispatch, useSelector } from "react-redux";
import {
  PostContactData,
  clearFormData,
} from "../../actions/subcategory-action";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    position: "relative",
  },
  noPadding: {
    padding: "0px !important",
    maxWidth: "700px !important",
    minWidth: "580px",
    position: "relative",
  },
  dialogPaper: {
    borderRadius: "20px 20px 0 0",
    maxWidth: "580px",
    position: "relative",
  },
  closeIconParent: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px 16px 0px 16px",
    cursor: "pointer",
  },
  title: {
    fontSize: "35px",
    fontWeight: "bold",
    marginBottom: "30px",
    width: "80%",
    lineHeight: "43px",
  },
  contentParent: {
    padding: "16px 75px",
  },
  subtitle: {
    fontSize: "21px",
    width: "70%",
    lineHeight: "26px",
    fontWeight: "500",
    "& a": {
      color: "#E16327",
    },
  },
  input: {
    marginTop: "25px",
    fontSize: "14px",
  },

  button: {
    width: "100%",
    height: "74px",
    backgroundColor: "#EF401F",
    border: "none",
    marginTop: "60px",
    display: "flex",
    justifyContent: "center",
    alighItems: "center",
    cursor: "pointer",

    "& p": {
      color: "white",
      fontSize: "32px",
      fontWeight: "bold",
    },

    "&:hover div > div:last-child": {
      marginLeft: "20px",
      transition: "all ease-in-out .6s",
    },
  },

  buttonChild: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& div:last-child": {
      marginLeft: "10px",
      transition: "all ease-in-out .6s",
    },
  },
  textArea: {
    minHeight: 500,
  },
  root: {
    fontSize: "15px",
    "&::placeholder": {
      fontSize: "14px",
    },
  },
});

const ContactModal = ({ classes, t, open, onClose, i18n }) => {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const { language } = i18n;

  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { errorMessage, contactResponse, loadingContact } = useSelector(
    (state) => state.subcategories,
  );

  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccesMsg] = useState(null);

  useEffect(() => {
    if (errorMessage) {
      setErrorMsg(errorMessage);
      setTimeout(() => {
        setErrorMsg(null);
        dispatch(clearFormData());
      }, 2000);

      return;
    }

    if (contactResponse) {
      setSuccesMsg(t("Thanks for write us we'll write you back ASAP"));
      setTimeout(() => {
        setSuccesMsg(null);
        dispatch(clearFormData());
      }, 2000);
      return;
    }

    return () => dispatch(clearFormData());
  }, [contactResponse, errorMessage]);

  const handleInput = (event) => {
    const {
      target: { value, name },
    } = event;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async () => {
    const regex = RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

    if (Object.values(values).some((item) => !item.length)) {
      setErrorMsg(t("All fields are required"));
      setTimeout(() => setErrorMsg(null), 3000);
      return;
    }

    if (!regex.test(values["email"])) {
      setErrorMsg(t("Invalid email format"));
      setTimeout(() => setErrorMsg(null), 3000);
      return;
    }
    await dispatch(PostContactData(values));
    await clearForm();
  };

  const handleHideMessage = () => {
    setErrorMsg(null);
    setSuccesMsg(null);
  };

  const clearForm = () => {
    for (const key in values) {
      setValues({ [key]: "" });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose ? onClose : null}
        aria-labelledby="upgrade-to-pro"
        className={classes.dialog}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent className={classes.noPadding} innerRef={modalRef}>
          {modalRef.current && (
            <img
              style={{
                position: "fixed",
                left: modalRef.current
                  ? `${modalRef.current.getBoundingClientRect().left - 30}px`
                  : "0%",
                bottom: modalRef.current
                  ? `${modalRef.current.getBoundingClientRect().bottom -
                      (modalRef.current.getBoundingClientRect().height + 25)}px`
                  : "0%",
                zIndex: -1,
              }}
              src={`${process.env.PUBLIC_URL}/contact-form-lines.svg`}
              alt="close-icon"
            />
          )}

          <div className={classes.closeIconParent} onClick={onClose}>
            <Close />
          </div>

          <div className={classes.contentParent}>
            <Typography variant="h1" className={classes.title}>
              {t("modalTitle")}
            </Typography>
            <Typography
              variant="body2"
              className={classes.subtitle}
              dangerouslySetInnerHTML={{
                __html: t("modalSubtitle"),
              }}
            ></Typography>
            <div className={classes.line}></div>

            <form>
              <TextField
                name="name"
                label={t("modalNameInput")}
                inputProps={{ maxLength: 63 }}
                value={values.name}
                className={classes.input}
                InputProps={{
                  classes: {
                    root: classes.root,
                  },
                }}
                onChange={handleInput}
                margin="none"
                fullWidth
              />
              <TextField
                name="email"
                label={t("modalEmailInput")}
                type="email"
                inputProps={{ maxLength: 63 }}
                InputProps={{
                  classes: {
                    root: classes.root,
                  },
                }}
                value={values.email}
                className={classes.input}
                onChange={handleInput}
                margin="none"
                fullWidth
              />
              <TextField
                name="message"
                label={t("modalMessageInput")}
                InputProps={{
                  classes: {
                    root: classes.root,
                  },
                }}
                inputProps={{ maxLength: 2200 }}
                value={values.message}
                multiline={true}
                rows={language == "en" ? 3 : 4}
                className={classes.input}
                onChange={handleInput}
                margin="none"
                fullWidth
                placeholder={t("modalMessagePlaceholder")}
              />
            </form>
          </div>

          <div className={classes.button} onClick={handleSubmit}>
            {!loadingContact ? (
              <div className={classes.buttonChild}>
                <Typography>{t("modalBtnText")}</Typography>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/button-arrow-icon.svg`}
                    alt="close-icon"
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  variant="indeterminate"
                  size={32}
                  style={{ color: "white" }}
                />
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <SnackBarMessageDisplay
        onHideMessage={handleHideMessage}
        errorMessage={errorMsg}
        successMessage={successMsg}
      />
    </div>
  );
};

export default withTranslation("plan")(
  withWidth()(withStyles(styles, { withTheme: true })(ContactModal)),
);
