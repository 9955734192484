import React, { Component, memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Typography,
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import Weekdays from "../../../constants/weekdays";
import DashboardTrainingWeekdayDay from "./dashboard-training-weekday/dashboard-training-weekday-day";
import "moment/min/locales";
import EstimatedTimes from "../../../constants/estimated-times";
import WorkoutTypes from "../../../constants/workout-types";
import history from "../../../lib/history-helper";
import { logEvent } from "../../../lib/events-helper";
import { convertToRTFForView } from "../../../lib/rtf-helper";
import { dateFormat } from "../../../lib/date-helper";
import { connect } from "react-redux";
import { updatePlanSwap } from "../../../actions/user-plans-action";
import UpgradeToPro from "../../generals/upgrade-pro-modal";
import { withWidth } from "@material-ui/core";
import { CollectionsOutlined } from "@material-ui/icons";
import { getCustomLevel } from "../../../lib/plan-helper";
import { useAnalyticsEvent } from "../../../hooks/useAnalyticsEvent";

const styles = (theme) => ({
  container: {
    display: "flex",
    margin: 12,
    width: "calc(100% - 24px)",
    cursor: "pointer",
  },
  containerCalendar: {
    display: "flex",
    width: "100%",
    margin: 0,
    cursor: "pointer",
  },
  dontDisplay: {
    display: "none",
  },
  todayMobileContainer: {
    width: "100%",
  },

  workoutContainer: {
    borderRadius: 8,
    textAlign: "left",
    position: "relative",
    width: "100%",

    padding: 14,
  },

  workoutContainerRecovery: {
    borderRadius: 8,
    textAlign: "left",
    position: "relative",
    width: "100%",

    background: "#F5F5F5",
    padding: 14,
  },

  topContainer: {
    display: "flex",
    alignItems: "center",
  },

  description: {
    fontSize: 14,
    fontWeight: "normal",

    color: "#ffffff",
    maxWidth: "calc(100vw - 60px)",
  },
  descriptionTime: {
    fontWeight: 400,

    fontSize: 12,
    color: "#212121",
    margin: "0 12px 0 6px",
  },
  todayWorkout: {
    fontWeight: 800,
  },
  notDonePassedWorkout: {
    // backgroundColor: amber[100]
  },
  progress: {
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  iconContainer: {
    textAlign: "right",
    position: "absolute",
    bottom: 5,
    right: 5,
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      display: "flex",
      marginLeft: "auto",
      position: "relative",
      bottom: "initial",
      right: "initial",
    },
  },
  white: {
    color: theme.palette.common.white,
  },
  secondaryDark: {
    color: theme.palette.secondary.dark,
  },
  descriptionLink: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  descriptionContainerBreak: {
    height: "100%",
    width: "100%",
  },
  todayDateContainer: {
    fontWeight: 500,
    color: "#1E629B",
  },
  iconSwap: {
    marginRight: 10,
    width: 24,
  },

  title: {
    fontWeight: 900,
    fontSize: 18,
    marginBottom: 8,
  },

  timeContainer: {
    display: "flex",
    alignItems: "center",
  },

  iconTimer: {
    marginTop: -3,
  },

  descOpener: {
    cursor: "pointer",
    width: 24,
    display: "block",
  },

  buttonCont: {
    textAlign: "center",
  },

  button: {
    marginTop: 20,
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: 600,
    borderRadius: 50,
    padding: "10px 8px",
    background: "#fff",
    boxShadow: "none",
    fontSize: "9px",
  },
  buttonDisabled: {
    marginTop: 20,
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: 600,
    borderRadius: 50,
    padding: "10px 8px",
    boxShadow: "none",
    fontSize: "9px",
    backgroundColor: "#c9ddf2",
  },
  swapIcon: {
    marginRight: "4px",
  },
  moveText: {
    fontSize: "9px",
    fontWeight: 600,
  },
  datesContainer: {
    display: "flex",
    margin: "8px 0",
  },
  date: {
    width: 26,
    textAlign: "center",
    background: "#FFFFFF",
    borderRadius: 18,
    boxShadow: "0 0 12px rgba(33, 81, 209, 0.1)",
    margin: "0 7px",
    padding: 5,
    cursor: "pointer",
  },
  dayText: {
    color: "#9E9E9E",
    fontSize: 12,
  },
  dateSelected: {
    width: 26,
    textAlign: "center",
    background: "#0F4975",
    borderRadius: 18,
    margin: "0 7px",
    padding: 5,
    cursor: "pointer",
  },
  dayTextSelected: {
    color: "#FFFFFF",
    fontSize: 12,
  },
  contentBtnParent: {
    display: "flex",
    alignItems: "center",
  },
});

class DashboardTrainingOneWeekday extends Component {
  state = {
    openDescription: false,
    workoutToMove: null,
    swapOpen: false,
    weekdays: [],
    currentStartDate: moment(),
    confirmSwapOpen: false,
    workoutToMove1: null,
    exploreFeatureDialogOpen: false,
  };

  openLink(id, canOpen, plannedDateFormatted) {
    const { onSendAnalytics, userPlans } = this.props;
    const { currentPlan } = userPlans;
    if (id && canOpen) {
      history.push(`/dashboard/${id}/${plannedDateFormatted}`);
    }
  }

  componentDidMount() {
    this.loadDays();
  }

  handleMobileSwap(event, training) {
    const index = this.state.weekdays.findIndex(
      (item) => item.trainingDay && item.trainingDay[0].id == training.id,
    );
    const { hasSubscription } = this.props;

    event.preventDefault();
    event.stopPropagation();

    if (hasSubscription) {
      this.loadDays();
      setTimeout(() => {
        this.setState({
          ...this.state,
          workoutToMove: index,
          swapOpen: true,
        });
      }, 500);

      return;
    }
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: true,
    });
  }

  loadDays() {
    const { currentPlan } = this.props.userPlans || {};
    const { currentTraining } = currentPlan || {};

    const { currentStartDate = moment().startOf("isoWeek"), days } =
      currentTraining || {};

    const dayCount = 7;

    let weekdays = [];
    let changed = false;
    for (let counter = 0; counter < dayCount; counter++) {
      const day = moment(currentStartDate)
        .hour(12)
        .minute(0)
        .add(counter, "days");
      const trainingDay = days
        ? days.find(
            (trDay) => trDay && trDay[0].plannedDate === day.format(dateFormat),
          )
        : -1;

      weekdays = [...weekdays, { day, trainingDay }];

      if (
        !changed &&
        trainingDay &&
        trainingDay[0] &&
        this.state.weekdays &&
        this.state.weekdays[counter] &&
        this.state.weekdays[counter].trainingDay &&
        this.state.weekdays[counter].trainingDay[0]
      ) {
        const newTrainingDay = trainingDay[0];
        const oldTrainingDay = this.state.weekdays[counter].trainingDay[0];

        if (
          newTrainingDay.id !== oldTrainingDay.id ||
          newTrainingDay.isDone !== oldTrainingDay.isDone ||
          (newTrainingDay.energyLevel &&
            newTrainingDay.energyLevel !== oldTrainingDay.energyLevel) ||
          newTrainingDay.plannedDate !== oldTrainingDay.plannedDate
        ) {
          changed = true;
        }
      }

      if (
        !changed &&
        trainingDay &&
        trainingDay[0] &&
        this.state.weekdays &&
        this.state.weekdays[counter] &&
        (!this.state.weekdays[counter].trainingDay ||
          !this.state.weekdays[counter].trainingDay[0])
      ) {
        changed = true;
      }

      if (
        !changed &&
        (!trainingDay || !trainingDay[0]) &&
        this.state.weekdays &&
        this.state.weekdays[counter] &&
        this.state.weekdays[counter].trainingDay &&
        this.state.weekdays[counter].trainingDay[0]
      ) {
        changed = true;
      }
    }
    if (
      currentStartDate !== this.state.currentStartDate ||
      changed ||
      !this.state.weekdays
    ) {
      this.setState({
        ...this.state,
        currentStartDate,
        weekdays,
      });
    }
  }

  handleWorkoutsMoved = (changeReq) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { currentTraining } = currentPlan || {};

    if (currentTraining) {
      this.props.dispatch(
        updatePlanSwap(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            ...changeReq,
          },
          currentTraining,
          0,
          false,
        ),
      );
    }
  };

  setDays(ev) {
    if (
      !ev.isMobile &&
      Math.abs(ev.item.offsetTop - ev.originalEvent.srcElement.offsetTop) < 50
    ) {
      return;
    }
    if (
      ev &&
      (ev.oldIndex || ev.oldIndex === 0) &&
      (ev.newIndex || ev.newIndex === 0) &&
      ev.oldIndex !== ev.newIndex
    ) {
      const newState = [...this.state.weekdays];
      newState[ev.newIndex] = this.state.weekdays[ev.oldIndex];
      newState[ev.oldIndex] = this.state.weekdays[ev.newIndex];

      const currentStartDate = moment().startOf("isoWeek");

      const { onError, t } = this.props;

      for (let counter = 0; counter < 7; counter++) {
        if (newState[counter] && newState[counter].trainingDay) {
          const type = newState[counter].trainingDay[0].workoutType;
          if (
            type === WorkoutTypes.Items.Speedwork ||
            type === WorkoutTypes.Items.ClimbWorkout ||
            type === WorkoutTypes.Items.DescendWorkout
          ) {
            if (counter - 1 > -1) {
              if (newState[counter - 1] && newState[counter - 1].trainingDay) {
                const otherType =
                  newState[counter - 1].trainingDay[0].workoutType;
                if (otherType === type) {
                  onError(
                    new Error(
                      t("Oops, you cant have two on a row", {
                        type: t(`${type}`, { ns: "constants" }),
                      }),
                    ),
                  );
                }
              }
            }
            if (counter + 1 < 7) {
              if (newState[counter + 1] && newState[counter + 1].trainingDay) {
                const otherType =
                  newState[counter + 1].trainingDay[0].workoutType;
                if (otherType === type) {
                  onError(
                    new Error(
                      t("Oops, you cant have two on a row", {
                        type: t(`${type}`, { ns: "constants" }),
                      }),
                    ),
                  );
                }
              }
            }
          }
        }
      }
      let changed = 0;
      for (let counter = 0; counter < 7; counter++) {
        if (newState[counter]) {
          newState[counter].day = moment(currentStartDate)
            .hour(12)
            .minute(0)
            .add(counter, "days")
            .startOf("day");
          if (
            (newState[counter].trainingDay &&
              this.state.weekdays[counter].trainingDay &&
              newState[counter].trainingDay[0].id !==
                this.state.weekdays[counter].trainingDay[0].id) ||
            (newState[counter].trainingDay &&
              !this.state.weekdays[counter].trainingDay) ||
            (!newState[counter].trainingDay &&
              this.state.weekdays[counter].trainingDay)
          ) {
            changed++;
          }
        }
      }
      if (changed === 2) {
        this.setState({ ...this.state, weekdays: newState });
        this.handleWorkoutsMoved({
          plannedDate1: newState[ev.newIndex].trainingDay
            ? newState[ev.newIndex].trainingDay[0].plannedDate
            : null,
          workoutId1: newState[ev.newIndex].trainingDay
            ? newState[ev.newIndex].trainingDay[0].id
            : null,
          plannedDate2: newState[ev.oldIndex].trainingDay
            ? newState[ev.oldIndex].trainingDay[0].plannedDate
            : null,
          workoutId2: newState[ev.oldIndex].trainingDay
            ? newState[ev.oldIndex].trainingDay[0].id
            : null,
          relativePos1: ev.newIndex - ev.oldIndex,
          relativePos2: ev.oldIndex - ev.newIndex,
        });
      }
    }
  }

  handleDoSwap() {
    this.setDays({
      oldIndex: this.state.workoutToMove,
      newIndex: this.state.workoutToMove1,
      isMobile: true,
    });

    setTimeout(
      () =>
        this.setState({
          ...this.state,
          confirmSwapOpen: false,
        }),
      100,
    );
  }

  handleMobileSwapConfirm(index) {
    if (this.state.weekdays[index].trainingDay) {
      this.setState({
        ...this.state,
        workoutToMove1: index,
        swapOpen: false,
        confirmSwapOpen: true,
      });
    } else {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            workoutToMove1: index,
            swapOpen: false,
            confirmSwapOpen: false,
          }),
        100,
      );
      this.setDays({
        oldIndex: this.state.workoutToMove,
        newIndex: index,
        isMobile: true,
      });
    }
  }

  handleMobileSwapClose = () => {
    this.setState({
      ...this.state,
      swapOpen: false,
    });
  };

  handleMobileSwapConfirmClose = () => {
    this.setState({
      ...this.state,
      confirmSwapOpen: false,
    });
  };

  handleCloseUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
  };

  render() {
    const {
      classes,
      trainingDay,
      day,
      isInMobileView,
      t,
      i18n,
      isCalendar = false,
      lastVisibleDate,
      isSample,
      hasSubscription,
      updatingWorkoutId,
      width,
      level,
      userPlans,
    } = this.props;

    const { currentPlan } = userPlans || {};
    const { editMode, currentTraining } = currentPlan || {};
    const { chainedPlans } = currentTraining || {};

    const {
      days,
      currentStartDate,
      swapOpen,
      confirmSwapOpen,

      exploreFeatureDialogOpen,
    } = this.state;

    const training =
      trainingDay && trainingDay.length > 0 ? trainingDay[0] : undefined;
    const { language } = i18n;
    const {
      workoutType,
      isDone = false,
      id,
      comment,
      plannedDate = "",
      estimatedTime,
      userCreatedType,
      description,
    } = training || {};

    const customLevel = getCustomLevel(plannedDate, chainedPlans);

    const newEstimatedTime = Array.isArray(estimatedTime)
      ? estimatedTime[!isNaN(customLevel) ? customLevel : level]
      : estimatedTime;

    const plannedDateFormatted = plannedDate
      .replace("/", "-")
      .replace("/", "-");
    const today = moment()
      .startOf("day")
      .toISOString();
    const dayMoment = moment(day)
      .startOf("day")
      .toISOString();
    const hasComment = typeof comment !== undefined && comment;
    const momentLocale = moment().locale(language);
    const weekdayMoment = moment(plannedDateFormatted, dateFormat);

    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const canOpen = lastVisibleDate
      ? weekdayMoment.isSameOrBefore(lastVisibleDate)
      : true;

    const weekday = days
      ? days.find(
          (d) =>
            d &&
            d.length > 0 &&
            moment(d[0].plannedDate, dateFormat).isSame(
              moment(currentStartDate),
              "day",
            ),
        )
      : null;

    let dates = [];
    for (let counter = 0; counter < 7; counter++) {
      dates = [
        ...dates,
        moment(currentStartDate)
          .startOf("isoWeek")
          .startOf("day")
          .add(counter, "days"),
      ];
    }

    const isInMobileViewSm = width === "sm" || width === "xs";
    const videoWidth = isInMobileViewSm
      ? window.innerWidth - 60
      : Math.min(window.innerWidth, 620);

    return (
      <>
        <div
          className={classNames(
            isInMobileView || !isCalendar
              ? classes.container
              : classes.containerCalendar,
            {
              [classes.dontDisplay]: isInMobileView && !training,
            },
          )}
          onClick={() =>
            this.openLink(id, canOpen && !isSample, plannedDateFormatted)
          }
        >
          <div className={classes.descriptionLink}>
            {training ? (
              <div
                className={classes.workoutContainer}
                style={{
                  backgroundColor: WorkoutTypes.Colors[workoutType],
                }}
              >
                <div className={classes.topContainer}>
                  <div className={classes.titleContainer}>
                    <Typography
                      variant="body2"
                      className={classes.title}
                      style={{
                        color: "#fff",
                      }}
                    >
                      {userCreatedType
                        ? userCreatedType
                        : t(`${WorkoutTypes.Items[workoutType]}`, {
                            ns: "constants",
                          })}
                    </Typography>

                    <div className={classes.timeContainer}>
                      {workoutType !== WorkoutTypes.Items.Recovery ? (
                        <img
                          src={`${process.env.PUBLIC_URL}/icon-timer-w.svg`}
                          alt=""
                          className={classes.iconTimer}
                        />
                      ) : (
                        ""
                      )}

                      {workoutType !== WorkoutTypes.Items.Recovery ? (
                        <Typography
                          variant="body2"
                          className={classes.descriptionTime}
                          style={{
                            color: "#fff",
                          }}
                        >
                          {EstimatedTimes.convertMinutesToLabel(
                            newEstimatedTime,
                            true,
                          )}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className={classes.iconContainer}>
                    {/*isInMobileView && !isCalendar && !isSample ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-swap.svg`}
                    alt=""
                    className={classes.iconSwap}
                    onClick={(event) => onMobileSwap(event)}
                  />
                ) : (
                  ""
                )*/}
                    <Tooltip
                      title={
                        !isDone && dayMoment <= today
                          ? t("Mark as done")
                          : hasComment
                          ? t("This training already has a note")
                          : t("Write a note about your training")
                      }
                    >
                      {updatingWorkoutId &&
                      updatingWorkoutId.workoutId === id &&
                      updatingWorkoutId.plannedDate === plannedDate ? (
                        <CircularProgress variant="indeterminate" size="32px" />
                      ) : isDone ? (
                        <img
                          src={`${process.env.PUBLIC_URL}/icon-done.svg`}
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${process.env.PUBLIC_URL}/icon-notdone.svg`}
                          alt=""
                          style={{
                            opacity: 0.15,
                          }}
                        />
                      )}
                    </Tooltip>
                  </div>
                </div>

                <Typography
                  variant="body2"
                  className={classes.description}
                  dangerouslySetInnerHTML={convertToRTFForView(
                    description && description[languageDetector],
                    videoWidth,
                  )}
                  style={{
                    maxHeight: !this.state.openDescription ? 75 : "initial",
                    overflow: "hidden",
                  }}
                ></Typography>
                <img
                  src={`${process.env.PUBLIC_URL}/icon-chevron-${
                    this.state.openDescription ? "up" : "down"
                  }-w.svg`}
                  alt=""
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.setState({
                      ...this.state,
                      openDescription: !this.state.openDescription,
                    });
                  }}
                  className={classes.descOpener}
                />

                <div
                  style={{
                    display: "flex",
                    aligItems: "center",
                    justifyContent: `${
                      isInMobileView ? "space-between" : "center"
                    }`,
                  }}
                >
                  {isInMobileView && (
                    <Tooltip
                      title={
                        typeof editMode === "boolean" && editMode
                          ? t("Sorry, your coach is editing this plan")
                          : ""
                      }
                    >
                      <div className={classes.buttonCont}>
                        <Button
                          id="move-workout"
                          size="small"
                          variant={"contained"}
                          className={
                            hasSubscription
                              ? classes.button
                              : classes.buttonDisabled
                          }
                          onClick={(event) => {
                            if (typeof editMode == "boolean") {
                              event.preventDefault();
                              event.stopPropagation();
                            }
                            if (!editMode)
                              this.handleMobileSwap(event, training);
                          }}
                        >
                          <div className={classes.contentBtnParent}>
                            <img
                              src={`${process.env.PUBLIC_URL}/big-swap-icon.svg`}
                              alt=""
                              className={classes.swapIcon}
                            />
                            <Typography className={classes.moveText}>
                              {t("Move workout")}
                            </Typography>
                          </div>
                        </Button>
                      </div>
                    </Tooltip>
                  )}

                  <div className={classes.buttonCont}>
                    <Button
                      variant={"contained"}
                      size="small"
                      className={classes.button}
                      onClick={() =>
                        this.openLink(
                          id,
                          canOpen && !isSample,
                          plannedDateFormatted,
                        )
                      }
                    >
                      {t("Open workout", { ns: "dashboard" })}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              !isInMobileView && (
                <div
                  className={classes.workoutContainer}
                  style={{
                    border: 0,
                  }}
                ></div>
              )
            )}
          </div>
        </div>
        <Dialog
          open={swapOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleMobileSwapClose}
          classes={{
            paper: classes.dialog,
          }}
        >
          <DialogContent>
            <Typography variant="body1" className={classes.dialogTitle}>
              {t("Move this workout to")}
            </Typography>

            <div className={classes.datesContainer}>
              {(dates || []).map((date, index) => {
                if (index !== this.state.workoutToMove) {
                  return (
                    <div
                      className={classes.date}
                      key={index}
                      onClick={() => this.handleMobileSwapConfirm(index)}
                    >
                      <Typography
                        variant={isInMobileView ? "subtitle2" : "h6"}
                        className={classes.dayText}
                      >
                        {`${t(
                          `${Weekdays.itemsArray[date.isoWeekday() - 1].text}`,
                          { ns: "constants" },
                        )
                          .substring(0, 1)
                          .toUpperCase()}`}
                      </Typography>
                      <Typography
                        variant={isInMobileView ? "subtitle2" : "h6"}
                        className={classes.dayText}
                      >
                        {`${date.format("D")}`}
                      </Typography>
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleMobileSwapClose()}
              color="primary"
            >
              {t("Cancel")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmSwapOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleMobileSwapConfirmClose}
        >
          <DialogContent>
            {this.state.weekdays &&
            (this.state.workoutToMove1 || this.state.workoutToMove1 === 0) &&
            this.state.weekdays[this.state.workoutToMove1].trainingDay ? (
              <Typography variant="body1" className={classes.dialogTitle}>
                {t("Confirm Swap Text", {
                  workout: this.state.weekdays[this.state.workoutToMove1]
                    .trainingDay[0].userCreatedType
                    ? this.state.weekdays[this.state.workoutToMove1]
                        .trainingDay[0].userCreatedType
                    : t(
                        `${
                          WorkoutTypes.Items[
                            this.state.weekdays[this.state.workoutToMove1]
                              .trainingDay[0].workoutType
                          ]
                        }`,
                        { ns: "constants" },
                      ),
                  date: `${t(
                    `${
                      Weekdays.itemsArray[
                        this.state.weekdays[
                          this.state.workoutToMove1
                        ].day.isoWeekday() - 1
                      ].text
                    }`,
                    { ns: "constants" },
                  )} ${this.state.weekdays[
                    this.state.workoutToMove1
                  ].day.format("D")}`,
                })}
              </Typography>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleDoSwap()} color="primary">
              {t("Move workout")}
            </Button>
            <Button
              onClick={() => this.handleMobileSwapConfirmClose()}
              color="primary"
            >
              {t("Cancel")}
            </Button>
          </DialogActions>
        </Dialog>

        <UpgradeToPro
          open={exploreFeatureDialogOpen}
          onClose={this.handleCloseUpgrade}
          list
          title="Paywall Title 2"
          btnText="Try 7 days for free"
        />
      </>
    );
  }
}

DashboardTrainingOneWeekday.propTypes = {
  classes: PropTypes.object.isRequired,
  trainingDay: PropTypes.array,
  day: PropTypes.object.isRequired,
  plannedDate: PropTypes.string,
  updatingWorkoutId: PropTypes.string,
  isInMobileView: PropTypes.bool,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
};
export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withTranslation(["dashboard", "constants"])(
    withWidth()(
      withStyles(styles, { withTheme: true })(
        memo(DashboardTrainingOneWeekday),
      ),
    ),
  ),
);
