import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Tooltip,
} from "@material-ui/core";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

import PlanFieldTransition from "./plan-field-transition";

const styles = (theme) => ({
  card: {
    maxWidth: 300,
    marginRight: 20,
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing.unit * 3,
    },
  },
  media: {
    minWidth: 200,
    height: 200,
    color: "white",
    textAlign: "left",
    paddingLeft: 10,
    paddingTop: 5,
    backgroundPosition: "center",
  },
  selectedCard: {
    backgroundColor: theme.palette.primary.dark,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  white: {
    color: theme.palette.common.white,
  },
  titleText: {
    fontSize: "0.9rem",
  },
});

class PlanFieldCardSelect extends Component {
  handleClick = (value) => {
    const { onSelect, id } = this.props;
    if (onSelect) {
      onSelect({ id, value });
    }
  };

  render() {
    const { classes, xs, sm, md, lg, xl, items, selectedValue, t } = this.props;

    return (
      <PlanFieldTransition>
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <div className={classes.container}>
            {items.map((item, index) => {
              const {
                text,
                caption,
                value,
                image,
                imageTooltip,
                tooltip,
                disabled,
              } = item;
              return tooltip ? (
                <Tooltip title={tooltip} key={index}>
                  <Card
                    key={index}
                    className={classNames(classes.card, {
                      [classes.selectedCard]: selectedValue === value,
                    })}
                    onClick={
                      disabled ? null : (event) => this.handleClick(value)
                    }
                  >
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image={image}
                        title={imageTooltip}
                      >
                        <Typography
                          gutterBottom
                          variant="overline"
                          className={classes.white}
                        >
                          {caption}
                        </Typography>
                      </CardMedia>
                      <CardContent>
                        <Typography
                          gutterBottom
                          color="primary"
                          className={classes.titleText}
                        >
                          {t(text)}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Tooltip>
              ) : (
                <Card
                  key={index}
                  className={classNames(classes.card, {
                    [classes.selectedCard]: selectedValue === value,
                  })}
                  onClick={(event) => this.handleClick(value)}
                >
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={image}
                      title={imageTooltip}
                    >
                      <Typography
                        gutterBottom
                        variant="overline"
                        className={classes.white}
                      >
                        {caption}
                      </Typography>
                    </CardMedia>
                    <CardContent>
                      <Typography
                        gutterBottom
                        color="primary"
                        className={classes.titleText}
                      >
                        {t(text)}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
          </div>
        </Grid>
      </PlanFieldTransition>
    );
  }
}

PlanFieldCardSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  selectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  items: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation("constants")(
  withStyles(styles, { withTheme: true })(PlanFieldCardSelect),
);
