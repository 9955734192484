import { Auth } from "aws-amplify";
import { CognitoAuth } from "amazon-cognito-auth-js";
import awsmobile from "../aws-exports";
import { Capacitor } from "@capacitor/core";
import awsmobileprd from "../aws-exports-prd";
import awsmobiledev from "../aws-exports-dev";

const aws = Capacitor.isNativePlatform()
  ? process.env.REACT_APP_RELEASE_STAGE === "development"
    ? awsmobiledev
    : awsmobileprd
  : awsmobile;

// const aws = awsmobileprd;

const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
const COGNITO_APP_CLIENT_ID = aws.aws_user_pools_web_client_id;
const COGNITO_USER_POOL_ID = aws.aws_user_pools_id;
const APP_DOMAIN = Capacitor.isNativePlatform()
  ? "run.vert.app://callback"
  : window.location.href.replace(window.location.pathname, "/");
const RESPONSE_TYPE = `code`;
const REDIRECT_URI = `${APP_DOMAIN}`;

const OAuthParams = (identityProvider) => ({
  AppWebDomain: APP_DOMAIN,
  ClientId: COGNITO_APP_CLIENT_ID,
  IdentityProvider: identityProvider,
  RedirectUriSignIn: `${APP_DOMAIN}callback`,
  RedirectUriSignOut: `${APP_DOMAIN}callback`,
  ResponseType: "code",
  TokenScopesArray: [
    "profile",
    "email",
    "openid",
    "aws.cognito.signin.user.admin",
    "phone",
  ],
  UserPoolId: COGNITO_USER_POOL_ID,
});

const userHandler = (cognitoAuthClient, callback) => {
  cognitoAuthClient.userhandler = {
    onFailure: (err) => {
      callback(err);
    },
    onSuccess: (result) => {
      Auth.currentSession().then(async (session) => {
        callback(null, { session, result });
      });
    },
  };
};

export const OAuthUrl = (identityProvider) =>
  `${COGNITO_DOMAIN}/oauth2/authorize?response_type=${RESPONSE_TYPE}&client_id=${COGNITO_APP_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=STATE&identity_provider=${identityProvider}`;

export const OAuthLogoutUrl = `${COGNITO_DOMAIN}/logout?client_id=${COGNITO_APP_CLIENT_ID}&logout_uri=${REDIRECT_URI}`;

export const OAuthHandler = (identityProvider, handleError) => {
  const cognitoAuthClient = new CognitoAuth(OAuthParams(identityProvider));
  const url = cognitoAuthClient.getAppWebDomain();
  if (url) {
    const urlSplit = url.split("?");
    if (urlSplit.length > 1) {
      const parsedAppWebDomain = parseQueryString(urlSplit[1]);
      if (parsedAppWebDomain.error_description) {
        if (handleError) handleError(parsedAppWebDomain.error_description);
        return;
      }
    }
  }
  userHandler(cognitoAuthClient, (err) => {
    if (err && handleError) handleError(err);
  });
};

function parseQueryString(query) {
  const vars = query.split("&");
  const query_string = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof query_string[key] === "undefined") {
      query_string[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      const arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}
