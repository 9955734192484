import { API } from "aws-amplify";
import { ACTIVITY_TYPES } from "../lib/activities-helper";

class CorosService {
  static API_NAME = "apidevice";
  static AUTHORIZATION_INIT_PATH = "/device/corosInit";
  static GET_ACTIVITIES_PATH = "/device/getCorosActivities";
  static GET_ACTIVITY_PATH = "/device/corosActivity";

  static async initAuthorization(code, cognito_user_sub) {
    if (code) {
      const myInit = {
        body: {
          code,
          cognito_user_sub,
        },
      };
      const response = await API.post(
        this.API_NAME,
        this.AUTHORIZATION_INIT_PATH,
        myInit,
      );
      const { success, result, error } = response;

      if (success) {
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async getActivities(params) {
    if (params) {
      const { start_date, end_date, cognito_user_sub } = params;
      if (start_date && end_date && cognito_user_sub) {
        let myInit = {
          queryStringParameters: { cognito_user_sub, start_date, end_date },
        };
        const response = await API.get(
          this.API_NAME,
          this.GET_ACTIVITIES_PATH,
          myInit,
        );
        const { success, result, error } = response;

        if (success) {
          const data = result.map((activity) => {
            if (!activity.device)
              return { ...activity, device: ACTIVITY_TYPES.COROS };
            return activity;
          });
          return data;
        } else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      }
    }
  }

  static async getActivity(params) {
    if (params) {
      const { customer_id, activity_id } = params;
      if (customer_id && activity_id) {
        let myInit = {
          queryStringParameters: { customer_id, activity_id },
        };
        const response = await API.get(
          this.API_NAME,
          this.GET_ACTIVITY_PATH,
          myInit,
        );
        const { success, result, error } = response;

        if (success) return result;
        else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      }
    }
  }
}
export default CorosService;
