import React, { useState } from "react";
import { Popover, Box, makeStyles } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { IconBell } from "@tabler/icons-react";

const useStyles = makeStyles({
  alignCenter: {
    textAlign: "center",
  },
  paper: {
    borderRadius: 10,
    borderRadius: 4,
    width: "100%",
    marginTop: "30px",
  },
});

const NotificationPanel = ({ children }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  return (
    <>
      <div className={classes.alignCenter}>
        <Box id="notification_icon" onClick={(e) => handleClick(e)}>
          <IconBell color={anchorEl ? "#F93C6E" : "#6D6F71"} stroke={1.5} />
        </Box>
      </div>

      <Box onClick={() => handleClose()}>
        <Popover
          id="notification-menu"
          open={anchorEl ? true : false}
          anchorEl={anchorEl}
          onClose={() => handleClose()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{
            paper: classes.paper,
          }}
        >
          {children}
        </Popover>
      </Box>
    </>
  );
};

export default NotificationPanel;
