import React, { useEffect } from "react";
import { Select, MenuItem, makeStyles, FormControl } from "@material-ui/core";
import { palette } from "../../../theme/palette";
import { useTranslation } from "react-i18next";
import useUserInfo from "../../../hooks/useUserInfo";
import useProfile from "../../../hooks/useProfile";
import { logEvent } from "../../../lib/events-helper";

const useStyles = makeStyles({
  box: {
    display: "flex",
    padding: "24px 0px",
    boxSizing: "border-box",
    width: "100%",
  },
  select: {
    fontSize: "16px",
    padding: "1px !important",
    borderRadius: "4px",
    border: palette.lightGray,
    fontFamily: "Karla, sans-serif",
    fontWeight: 400,
    backgroundColor: "#FEFFFF",
  },
  root: {
    padding: "10px 12px",
  },
});

const ProfileSelect = () => {
  const { currentFilter } = useUserInfo();
  const { handlePlanProgressionFilter } = useProfile();
  const classes = useStyles();
  const { t } = useTranslation("profile");

  useEffect(() => {
    logEvent("visit_profile");
    handlePlanProgressionFilter({ target: { value: 2 } });
    return () => {
      handlePlanProgressionFilter({ target: { value: 2 } });
    };
  }, []);

  return (
    <FormControl className={classes.box}>
      <Select
        color="secondary"
        value={currentFilter}
        className={classes.select}
        fullWidth
        variant="outlined"
        disableUnderline
        classes={{ root: classes.root }}
        onChange={handlePlanProgressionFilter}
        id="profile_drop_menu"
      >
        <MenuItem value={0} id="profile_drop_menu_1">
          {t("profile.stats.filter.week")}
        </MenuItem>
        <MenuItem value={1} id="profile_drop_menu_2">
          {t("profile.stats.filter.month")}
        </MenuItem>
        <MenuItem value={2} id="profile_drop_menu_3">
          {t("profile.stats.filter.fullplan")}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default ProfileSelect;
