import React from "react";
import { Box, Divider, makeStyles } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import { palette } from "../../../theme/palette";

const OnboardingPlanCardSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Skeleton variant="rounded" width="100%" height="250px" />
      <Box
        style={{
          padding: "12px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Skeleton width="60%" />
          <Skeleton width="80%" />
        </Box>
        <Box>
          <Box
            style={{
              display: "flex",
              padding: "12px",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                height: "100%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Skeleton height="40px" width="15%" />
              <Skeleton width="30%" />
            </Box>
            <Divider orientation="vertical" variant="fullWidth" flexItem />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                height: "100%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Skeleton height="40px" width="15%" />
              <Skeleton width="30%" />
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton height="80px" width="30%" />
            <Skeleton height="80px" width="70%" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OnboardingPlanCardSkeleton;

const useStyles = makeStyles(() => ({
  card: {
    background: palette.white,
    borderRadius: "8px",
    marginTop: "12px",
    width: "100%",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
}));
