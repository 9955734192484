import React from "react";
import {
  makeStyles,
  Avatar,
  CircularProgress,
  Button,
  Box,
  Fab,
} from "@material-ui/core";
import useChangeProfilePicture from "../../../hooks/useChangeProfilePicture";
import { useTranslation } from "react-i18next";

const ChangeProfilePicture = () => {
  const classes = useStyles();
  const { t } = useTranslation("settings");
  const {
    avatarUrl,
    avatarLoading,
    selectedImage,
    updatingImage,
    loading,
    buttonDisabled,
    handleInputChange,
    handleSave,
  } = useChangeProfilePicture();

  return (
    <>
      <div className={classes.container}>
        <Box style={{ width: "100%", height: 5 }}></Box>
        <div className={classes.pictureContain}>
          <label
            htmlFor="contained-button-file"
            className={classes.fabContainer}
          >
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={(event) => handleInputChange(event)}
            />
            {updatingImage || avatarLoading || loading ? (
              <CircularProgress
                variant="indeterminate"
                color="primary"
                data-testid="LoadingImage"
              />
            ) : (
              <>
                <Fab
                  component="span"
                  className={classes.fab}
                  title={t("settings.changeYourPircture")}
                >
                  <Avatar
                    src={
                      avatarUrl || `${process.env.PUBLIC_URL}/avatar-empty.png`
                    }
                    className={classes.avatar}
                  />
                </Fab>
              </>
            )}
          </label>
        </div>
        <div className={classes.buttonContain}>
          <Button
            disabled={buttonDisabled()}
            className={classes.button}
            variant="contained"
            color={selectedImage !== null ? "secondary" : "default"}
            onClick={(event) => handleSave(event)}
          >
            {t("settings.buttons.save")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ChangeProfilePicture;

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    height: "100vh",
  },
  pictureContain: {
    display: "flex",
  },
  avatar: {
    width: "270px !important",
    height: "270px !important",
    aspactRatio: 1,
  },
  buttonContain: {
    display: "flex",
    width: "100vw",
    marginBottom: 10,
  },
  button: {
    width: "100vw",
    marginInline: 20,
    height: "47px",
    padding: "12px 24px",
    borderRadius: 4,
    gap: 8,
  },
  input: {
    display: "none",
  },
  fabContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: 270,
    height: 270,
  },
  fab: {
    boxShadow: "none",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
  },
});
