import React from "react";
import { palette } from "../../theme/palette";

const TimerIcon = ({
  width = 24,
  height = 24,
  activated = false,
  fill = null,
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 30 30`}
      fill={fill ? fill : activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 25C12.6794 25 10.4538 24.0781 8.81282 22.4372C7.17187 20.7962 6.25 18.5706 6.25 16.25C6.25 13.9294 7.17187 11.7038 8.81282 10.0628C10.4538 8.42187 12.6794 7.5 15 7.5C17.3206 7.5 19.5462 8.42187 21.1872 10.0628C22.8281 11.7038 23.75 13.9294 23.75 16.25C23.75 18.5706 22.8281 20.7962 21.1872 22.4372C19.5462 24.0781 17.3206 25 15 25ZM23.7875 9.2375L25.5625 7.4625C25 6.825 24.4375 6.25 23.8 5.7L22.025 7.5C20.0875 5.925 17.65 5 15 5C12.0163 5 9.15483 6.18526 7.04505 8.29505C4.93526 10.4048 3.75 13.2663 3.75 16.25C3.75 19.2337 4.93526 22.0952 7.04505 24.205C9.15483 26.3147 12.0163 27.5 15 27.5C21.25 27.5 26.25 22.4625 26.25 16.25C26.25 13.6 25.325 11.1625 23.7875 9.2375ZM13.75 17.5H16.25V10H13.75V17.5ZM18.75 1.25H11.25V3.75H18.75V1.25Z"
        fill={fill ? fill : activated ? palette.primary : palette.darkGray}
      />
    </svg>
  </>
);
export default TimerIcon;
