import React, { useRef, useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { theme } from "../../theme";
const useStyles = makeStyles({
  settings: {
    width: "100%",
    lineHeight: "150%",
  },
  textarea: {
    outline: "none",
    width: "100%",
    height: "230px",
    background: "#dedede00",
    border: "none",
  },
  letUsKnowContainer: {
    alignSelf: "stretch",
    lineHeight: "150%",
  },
  yourFeedbackIs: {
    alignSelf: "stretch",
    fontSize: theme.typography.h6.fontSize,
    lineHeight: "150%",
    color: "#6d6f71",
  },
  infoText: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "8px",
  },
  inputstext: {
    alignSelf: "stretch",
    borderRadius: "4px",
    border: "2px solid #eaeaea",
    display: "none",
  },
  inputdropdown: {
    alignSelf: "stretch",
    borderRadius: "4px",
    boxShadow: "0px 4px 154px rgba(18, 21, 27, 0.05)",
    border: "2px solid #eaeaea",
    display: "none",
  },
  iconcalendar: {
    position: "relative",
    width: "24px",
    height: "24px",
    overflow: "hidden",
    flexShrink: "0",
    display: "none",
  },
  iconimage: {
    position: "relative",
    width: "18px",
    height: "18px",
    overflow: "hidden",
    flexShrink: "0",
    objectFit: "cover",
  },
  add: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "4px",
  },
  iconplaceholder: {
    alignSelf: "stretch",
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  inputstextArea: {
    alignSelf: "stretch",
    borderRadius: "4px",
    border: "2px solid #eaeaea",
    boxSizing: "border-box",
    height: "280px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "12px 16px",
  },
  formvertical: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "16px",
  },
  placeholder: {
    position: "relative",
    textDecoration: "underline",
    lineHeight: "150%",
  },
  doc: {
    borderRadius: "4px",
    backgroundColor: "#feffff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "4px",
    gap: "4px",
  },
  uploadesDocs: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "12px",
    color: "#4780ab",
  },
  inputsform: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    gap: "16px",
    color: "#6d6f71",
  },
  content: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "24px 16px",
    gap: "24px",
    textAlign: "left",
    fontSize: theme.typography.h5.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
  page: {
    position: "absolute",
    top: "0px",
    left: "0px",
    marginTop: "54px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  text: {
    position: "relative",
    lineHeight: "125%",
  },
  primaryButton: {
    borderRadius: "4px",
    backgroundColor: theme.palette.secondary.darkBlue,
    boxShadow: "0px 4px 30px rgba(18, 21, 27, 0.05)",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 0px",
    marginBottom: "50px",
    boxSizing: "border-box",
    fontSize: "18px",
    color: "#feffff",
    fontFamily: theme.typography.fontFamily,
  },
  reportProbleml: {
    backgroundColor: theme.palette.backgroundColor,
    width: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
    textAlign: "center",
    fontSize: "15px",
    color: "#12151b",
    fontFamily: "Roboto",
  },
});

const ComponentTextArea = ({
  title,
  description,
  descriptionng,
  ng,
  textArea,
}) => {
  const styles = useStyles();

  const fileInputRef = useRef(null);
  const fileInputRefDoc = useRef(null);

  const [imgFile, setImgFile] = useState([]);
  const [docFile, setDocFile] = useState([]);

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageClickFile = () => {
    if (fileInputRefDoc.current) {
      fileInputRefDoc.current.click();
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const imageFiles = Array.from(selectedFiles).filter((file) =>
      file.type.startsWith("image/"),
    );
    setImgFile(imageFiles);
  };

  const handleFileChangeFile = (event) => {
    const selectedFiles = event.target.files;
    const documentFiles = Array.from(selectedFiles).filter((file) =>
      isDocument(file),
    );
    setDocFile(documentFiles);
  };

  const isDocument = (file) => {
    const allowedDocumentTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ];

    return allowedDocumentTypes.includes(file.type);
  };

  return (
    <div className={styles.reportProbleml}>
      <div className={styles.page}>
        <div className={styles.content}>
          <div className={styles.infoText}>
            <div className={styles.letUsKnowContainer}>
              {ng === false ? (
                <span>{descriptionng}</span>
              ) : (
                <b>{descriptionng}</b>
              )}
              <span>{title}</span>
            </div>
            <div className={styles.yourFeedbackIs}>{description}</div>
          </div>
          <div className={styles.inputsform}>
            <div className={styles.formvertical}>
              <div className={styles.inputstextArea}>
                <div className={styles.iconplaceholder}>
                  <div className={styles.iconcalendar} />
                  <div className={styles.settings}>
                    <textarea
                      className={styles.textarea}
                      placeholder={textArea}
                    />
                    <div className={styles.add}>
                      <img
                        className={styles.iconimage}
                        onClick={handleImageClick}
                        alt=""
                        src={`${process.env.PUBLIC_URL}/Icon_image.png`}
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept="image/*"
                        multiple
                      />
                      <img
                        className={styles.iconimage}
                        onClick={handleImageClickFile}
                        alt=""
                        src={`${process.env.PUBLIC_URL}/majesticons_attachment-line.png`}
                      />
                      <input
                        type="file"
                        ref={fileInputRefDoc}
                        style={{ display: "none" }}
                        onChange={handleFileChangeFile}
                        accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf" // Permitir archivos de documento
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.uploadesDocs}>
              {imgFile.map((img, index) => (
                <div key={index} className={styles.doc}>
                  <img
                    className={styles.iconimage}
                    alt=""
                    src={`${process.env.PUBLIC_URL}/Icon_image.png`}
                  />
                  <div className={styles.placeholder}>{img.name}</div>
                </div>
              ))}
              {docFile.map((doc, index) => (
                <div key={index} className={styles.doc}>
                  <img
                    className={styles.iconimage}
                    alt=""
                    src={`${process.env.PUBLIC_URL}/majesticons_attachment-line.png`}
                  />
                  <div className={styles.placeholder}>{doc.name}</div>
                </div>
              ))}
            </div>
            <Button
              variant="contained"
              color="secondary"
              className={styles.primaryButton}
            >
              <b className={styles.text}>Send</b>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentTextArea;
