import React from 'react';
import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { getSubcategoryTitle } from '../../lib/subcategory-helper';

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        fontWeight: 'bold',
        margin: 5
    }
}

const TrainingFieldsViewer = (props) => {
    const { classes, currentTraining, subcatgories } = props;
    const { selectedPlan, durationInWeeks, distance, surface, raceCount, trainPerWeek, skillToImprove, athlete_cognito_user_full_name, subcategory } = currentTraining;
    return (
        <div className={classes.container}>
            <Chip className={classes.chip} label={`Plan Type: ${selectedPlan}`} />
            <Chip className={classes.chip} label={`Duration: ${durationInWeeks} weeks`} />
            {distance && <Chip className={classes.chip} label={`Distance: ${distance} kms`} />}
            <Chip className={classes.chip} label={`Surface: ${surface}`} />
            <Chip className={classes.chip} label={`Level: ${raceCount}`} />
            <Chip className={classes.chip} label={`Plan Days: ${trainPerWeek}`} />
            {skillToImprove && <Chip className={classes.chip} label={`Skill: ${skillToImprove}`} />}
            {athlete_cognito_user_full_name && <Chip className={classes.chip} label={`Athlete: ${athlete_cognito_user_full_name}`} />}
            {subcategory && <Chip className={classes.chip} label={`Subcategory: ${getSubcategoryTitle(subcatgories, subcategory)}`} />}
        </div>
    );
}

TrainingFieldsViewer.propTypes = {
    classes: PropTypes.object.isRequired,
    currentTraining: PropTypes.object.isRequired,
}

export default withStyles(styles)(TrainingFieldsViewer);
