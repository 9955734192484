import { useState } from "react";
import moment from "moment";
import { getPace, getMilesPace } from "../lib/units-helper";
import UnitsHook from "./unitsHook";
import { secondsToTimeStr } from "../lib/time-helper";

const useActivityDetails = () => {
  const localUnits = UnitsHook();
  const [name, setName] = useState("");
  const [time, setTime] = useState("");
  const [pace, setPace] = useState("");
  const [date, setDate] = useState(null);
  const [icon, setIcon] = useState(null);
  const [description, setDescription] = useState("");
  const [elevation, setElevation] = useState("");
  const [distance, setDistance] = useState("");
  const [device, setDevice] = useState(null);
  const [activityType, setActivityType] = useState("");
  const [activityEffort, setActivityEffort] = useState("");
  const [activityTypeEffort, setActivityTypeEffort] = useState("");
  const [createdAt, setCreatedAt] = useState(null);

  const loadNameState = (activity) => {
    const activityName = activity.name || activity.activityName || "";
    setName(activityName);
  };

  const loadTimeState = (activity) => {
    const movingTime = activity.moving_time || activity.elapsed_time || 0;
    const timeStr = secondsToTimeStr(movingTime);
    setTime(timeStr);
  };

  const loadPaceState = (activity) => {
    const movingTime = activity.moving_time || activity.elapsed_time || 0;
    const distance = activity.distance || 0;
    const paceValue =
      localUnits === "kms"
        ? getPace(movingTime, distance)
        : getMilesPace(movingTime, distance);
    setPace(paceValue);
  };

  const loadStartDateState = (activity) => {
    const startDate = moment(
      activity.start_date_local
        ? activity.start_date_local
        : activity.start_date,
    );
    setDate(startDate.isValid() ? startDate : null);
  };

  const loadCreatedAt = (activity) => {
    const activityCreatedAt = activity.created_at || new Date().getTime();
    setCreatedAt(activityCreatedAt);
  };

  const loadDescriptionState = (activity) => {
    const activityDescription = activity.description || "";
    setDescription(activityDescription);
  };

  const loadElevationState = (activity) => {
    const activityElevation = activity.total_elevation_gain || 0;
    setElevation(activityElevation);
  };

  const loadDistanceState = (activity) => {
    const activityDistance = activity.distance / 1000 || 0;
    setDistance(activityDistance);
  };

  const loadDeviceState = (activity) => {
    const activityDevice = activity.device || "";
    setDevice(activityDevice);
  };

  const loadActivityTypeState = (activity) => {
    const activityTypeValue = activity.type || activity.activityType || "";
    setActivityType(activityTypeValue);
  };

  const loadActivityTypeEffortState = (activity) => {
    const activityTypeEffortValue = activity.typeEffort || "";
    setActivityTypeEffort(activityTypeEffortValue);
  };

  const loadActivityEffortState = (activity) => {
    const activityEffortValue = activity.activityEffort || "";
    setActivityEffort(activityEffortValue);
  };

  const loadIconState = (activity) => {
    let activityIcon = null;
    const activityType = activity.type || activity.activityType || "";
    if (
      activityType.toLowerCase().includes("run") &&
      !activityType.toLowerCase().includes("transition")
    ) {
      activityIcon = "icon_activity_run";
    } else if (
      (activityType.toLowerCase().includes("ride") ||
        activityType.toLowerCase().includes("bik") ||
        activityType.toLowerCase().includes("cycl")) &&
      !activityType.toLowerCase().includes("transition")
    ) {
      activityIcon = "icon_activity_bike";
    } else if (
      activityType.toLowerCase().includes("swim") &&
      !activityType.toLowerCase().includes("transition")
    ) {
      activityIcon = "icon_activity_swim";
    } else if (
      (activityType.toLowerCase().includes("walk") ||
        activityType.toLowerCase().includes("hik")) &&
      !activityType.toLowerCase().includes("transition")
    ) {
      activityIcon = "icon_activity_hike";
    } else if (
      (activityType.toLowerCase().includes("crossfit") ||
        activityType.toLowerCase().includes("weight") ||
        activityType.toLowerCase().includes("yoga") ||
        activityType.toLowerCase().includes("strength") ||
        activityType.toLowerCase().includes("fitness")) &&
      !activityType.toLowerCase().includes("transition")
    ) {
      activityIcon = "icon_activity_strength";
    }
    setIcon(activityIcon);
  };

  const loadActivityDetails = (activity) => {
    if (activity) {
      loadNameState(activity);
      loadTimeState(activity);
      loadPaceState(activity);
      loadIconState(activity);
      loadStartDateState(activity);
      loadDescriptionState(activity);
      loadElevationState(activity);
      loadDistanceState(activity);
      loadDeviceState(activity);
      loadActivityTypeState(activity);
      loadActivityEffortState(activity);
      loadActivityTypeEffortState(activity);
      loadCreatedAt(activity);
    }
  };

  return {
    name,
    time,
    pace,
    date,
    icon,
    device,
    elevation,
    distance,
    description,
    activityType,
    activityEffort,
    activityTypeEffort,
    createdAt,
    loadActivityDetails,
  };
};

export default useActivityDetails;
