import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography, Button } from "@material-ui/core";
import { Dialog, DialogContent, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import { useRaffleCode } from "../actions/user-plans-action";

const styles = (theme) => ({
  gearTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
    color: "#FF6327",
    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
  },
  gearText1: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 12,
    textAlign: "center",
    color: "#2E3A59",
    margin: "10px 0",
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
  },
  gearText2: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 10,
    textAlign: "center",
    color: "#2E3A59",
    [theme.breakpoints.up("sm")]: {
      fontSize: 12,
      marginBottom: 10,
    },
  },
  codeContainer: {
    borderRadius: 12,
    boxShadow: "0 0 12px rgba(33, 81, 209, 0.1)",
    textAlign: "center",
    margin: "30px 0",
    padding: 20,
  },
  codeContainerWeb: {
    borderRadius: 12,
    boxShadow: "0 0 12px rgba(33, 81, 209, 0.1)",
    textAlign: "center",
    margin: 5,
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 5,
    },
  },
  code: {
    background: "#EFF3F5",
    border: "1px solid #9E9E9E",
    borderRadius: 8,
    margin: "0 10px 10px 10px",
  },
  codeText: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 12,
    textAlign: "center",
    color: "#2E3A59",
    padding: "10px",
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
  },
  shareButton: {
    borderRadius: 48,
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "20px",
    textAlign: "center",
    color: "#FFFFFF",
    margin: "auto",
    marginTop: 8,
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },
  discountTitle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 16,
    textAlign: "center",
    color: "#2E3A59",
    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
      marginTop: 10,
    },
  },
  discountText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 10,
    textAlign: "center",
    color: "#2E3A59",
    margin: "10px 0",
    [theme.breakpoints.up("sm")]: {
      fontSize: 12,
    },
  },
  dialogContent: {
    padding: 0,
    paddingTop: "0 !important",
  },
  content: {
    textAlign: "center",
  },
  mainContainer: {
    padding: "0 24px",
  },
  hatsImg: {
    width: "100%",
    marginBottom: "-4px",
  },
  dialogPaper: {
    borderRadius: 20,
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },
  button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
  },
  icon: {
    marginBottom: 2,
  },

  how: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 12,
    color: "#2E3A59",
    margin: 8,
    cursor: "pointer",
  },

  input: {
    background: "#EFF3F5",
    border: "1px solid #9E9E9E",
    borderRadius: 8,
    textAlign: "center",
  },

  lotteryText: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 12,
    color: "#2E3A59",
  },

  lotteryTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 16,
    color: "#FF6327",
  },

  howContainer: {
    marginTop: 10,
    padding: "1px 24px 24px",
    background: "#F9F9FB",
    boxShadow: "0px -4px 7px rgb(0 0 0 / 25%)",
    borderRadius: "20px",
  },
});

class RaffleDialog extends Component {
  state = {
    code: null,
    showHow: false,
  };

  componentDidUpdate(prevProps) {
    const { userPlans, onCancel } = this.props;
    const { loadingRaffleCode, raffleCodeError } = userPlans;

    const { userPlans: prevUserPlans } = prevProps;
    const { loadingRaffleCode: prevLoadingRaffleCode } = prevUserPlans;

    if (prevLoadingRaffleCode && !loadingRaffleCode && !raffleCodeError) {
      onCancel();
    }
  }

  handleCodeChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          ...this.state,
          code: value || "",
        });
      }
    }
  };

  useRaffleCode = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan;
    this.props.dispatch(useRaffleCode(this.state.code, cognito_user_sub));
  };

  render() {
    const { classes, t, open, onCancel } = this.props;
    const { code } = this.state;

    return (
      <div>
        <Dialog
          open={open}
          onClose={onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.dialogPaper }}
          maxWidth="xs"
        >
          <DialogContent className={classes.dialogContent}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={onCancel}
              />
            </div>

            <div className={classes.content}>
              <Typography className={classes.lotteryTitle}>
                {t("Did a friend sent you?")}
              </Typography>

              {this.state.showHow ? (
                <div className={classes.howContainer}>
                  <div className={classes.rightButtons}>
                    <img
                      src={`${process.env.PUBLIC_URL}/close.svg`}
                      alt=""
                      onClick={() =>
                        this.setState({ ...this.state, showHow: false })
                      }
                    />
                  </div>
                  <Typography
                    variant="body1"
                    className={classes.lotteryText}
                    dangerouslySetInnerHTML={{
                      __html: t("how it works text 1"),
                    }}
                  ></Typography>
                  <Typography
                    variant="body1"
                    className={classes.lotteryText}
                    dangerouslySetInnerHTML={{
                      __html: t("how it works text 2"),
                    }}
                  ></Typography>
                </div>
              ) : (
                <div className={classes.mainContainer}>
                  <Typography
                    variant="body1"
                    className={classes.lotteryText}
                    dangerouslySetInnerHTML={{
                      __html: t("lottery message"),
                    }}
                  ></Typography>

                  <TextField
                    value={code ? code : ""}
                    margin="normal"
                    variant="outlined"
                    placeholder={t("Add your code here")}
                    fullWidth={true}
                    onChange={this.handleCodeChange}
                    autoFocus
                    className={classes.input}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.shareButton}
                    onClick={this.useRaffleCode}
                    disabled={!code}
                  >
                    {t("Join the giveaway")}
                  </Button>

                  <Typography
                    className={classes.how}
                    onClick={() =>
                      this.setState({ ...this.state, showHow: true })
                    }
                  >
                    {t("How it works?")}
                  </Typography>
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

RaffleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withTranslation("dashboard")(
    withWidth()(withStyles(styles, { withTheme: true })(RaffleDialog)),
  ),
);
