import React from "react";
import Content from "./common/info-supp";
import NavbarSettings from "./common/navbar-settings";
import { useTranslation } from "react-i18next";
const WorkWithUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <NavbarSettings
        component={
          <Content
            title={""}
            descriptionng={t(
              "We'll get back to you as soon as possible. Want to be part of the Vert.run team? Send us an email!",
            )}
            description={t(
              "Let us know a little about yourself and why you're interested in working with Vert.run",
            )}
            ng={true}
            textArea={t("Tell us about yourself!")}
          />
        }
        text={t("Work with us!")}
      />
    </>
  );
};
export default WorkWithUs;
