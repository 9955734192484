import React, { useEffect } from "react";
import {
  Paper,
  Tab,
  Tabs,
  AppBar,
  makeStyles,
  Box,
  Slide,
} from "@material-ui/core";
import { TrainingProvider } from "../../../context/TrainingContext";
import TrainingToday from "../../../components/common/v2/TrainingToday";
import TrainingWeek from "../../../components/common/v2/TrainingWeek";
import TrainingMonth from "../../../components/common/v2/TrainingMonth";
import { palette } from "../../../theme/palette";
import TabPanel from "../../../components/common/v2/TabPanel";
import useUserInfo from "../../../hooks/useUserInfo";
import { logEvent, setEventsMetadata } from "../../../lib/events-helper";
import { useTranslation } from "react-i18next";

const TrainingView = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const { sub } = useUserInfo();
  const { t } = useTranslation("training");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setEventsMetadata({ customer_id: sub });
    logEvent("visit_training");
  }, []);

  return (
    <TrainingProvider>
      <Paper elevation={0} className={classes.root}>
        <AppBar position="static" variant="outlined" className={classes.appTab}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="training tabs"
            className={classes.tabs}
            classes={{ indicator: classes.indicator }}
          >
            <Tab className={classes.tab} label={t("training.today")} />
            <Tab className={classes.tab} label={t("training.week")} />
            <Tab className={classes.tab} label={t("training.month")} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <Slide direction="left" in={value === 0}>
            <Box
              sx={{
                marginTop: 42,
              }}
            >
              <TrainingToday />
            </Box>
          </Slide>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Slide direction="left" in={value === 1}>
            <Box
              sx={{
                marginTop: 42,
              }}
            >
              <TrainingWeek />
            </Box>
          </Slide>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Slide direction="left" in={value === 2}>
            <Box
              sx={{
                marginTop: 42,
              }}
            >
              <TrainingMonth />
            </Box>
          </Slide>
        </TabPanel>
      </Paper>
    </TrainingProvider>
  );
};

export default TrainingView;

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  indicator: {
    backgroundColor: palette.black,
    color: palette.black,
  },
  appTab: {
    position: "fixed",
    display: "flex",
    backgroundColor: palette.white,
    boxShadow: "0px 8px 16px 0px #12151B17",
    border: 0,
  },
  tabs: {
    width: "100%",
    backgroundColor: palette.background,
    alignSelf: "center",
  },
  tab: {
    display: "flex",
    flex: 1,
    backgroundColor: palette.white,
    fontFamily: "Oswald",
    fontSize: 18,
    fontWeight: 400,
    letterSpacing: 1,
    textAlign: "left",
    "&:not(.Mui-selected)": {
      color: palette.deactivated,
    },
    "&.Mui-selected": {
      color: palette.black,
      fontWeight: 600,
    },
  },
});
