import React, { Component, memo, createRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Typography,
  IconButton,
  LinearProgress,
  Fab,
  Select,
  MenuItem,
  Switch,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";
import AthleteTrainingEditorWeekday from "./athlete-training-editor/athlete-training-editor-weekday";
import { ReactSortable } from "react-sortablejs";
import { dateFormat } from "../../lib/date-helper";
import RouterLink from "../common/router-link";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import ActionsMenu from "./athlete-training-editor/actions-menu";
import WorkoutCard from "./components/workout-card";
import EstimatedTimes from "../../constants/estimated-times";
import { selectAthleteWorkout } from "../../actions/athletes-action";
import AthleteTrainingWorkoutViewer from "../athletes/athlete-training-comments/athlete-training-workout-viewer";
import { ThumbDownSharp } from "@material-ui/icons";
import { connect } from "react-redux";
import { getPlanLevel, getCustomLevel } from "../../lib/plan-helper";
import AthleteResumeModal from "../athletes/athlete-training-comments/athlete-resume-modal";
import AthleteAssignTraining from "../athletes/athlete-plan/athlete-assign-training";

const styles = (theme) => ({
  container: {
    padding: 5,
    background: "#FFFFFF",
    // borderRadius: 25,
  },
  iconButton: {
    label: {
      root: {
        fontSize: 20,
      },
    },
  },
  titleContainer: {
    display: "flex",
    // justifyContent: "space-between",
    flexDirection: "row",
    textAlign: "center",
    alignItems: "center",
    // paddingBottom: 10,
  },
  weekTitle: {
    // marginLeft: "auto",
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
  },
  planName: {
    fontSize: 16,
    color: "#878787",
    margin: "0 16px",
  },
  fab: {
    textTransform: "none",
    width: "100%",
    height: 30,
  },
  energyLeveltTitle: {
    fontWeight: "bold",
    fontSize: 16,
    margin: "16px 0",
  },
  graphContainer: {
    height: 80,
  },
  actionsBar: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  planContainer: {
    height: "100%",
  },
  switchView: {
    display: "flex",
    alignItems: "center",
  },
  leftSection: {
    display: "flex",
    alignItems: "center",
  },

  //MOTNHLY VIEW STYLES
  parent: {
    display: "flex",
    flexDirection: "column",
  },
  workoutContainer: {
    position: "relative",
    cursor: "pointer",
  },

  weekdayContainer: {
    display: "inline-flex",
    flexDirection: "column",
    height: "90%",
    textAlign: "center",
    border: "solid 1px rgba(0, 0, 0, 0.2)",
    borderWidth: "0.5px 0.5px 0.5px 0.5px",
  },

  day: {
    height: "100%",
  },

  weekHeader: {
    padding: "8px 0px",
    boxSizing: "border-box",
    borderBottom: "solid 1px rgba(0, 0, 0, 0.2)",
  },
  workoutParent: {
    marginBottom: 10,
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    position: "relative",
  },
  workoutCell: {
    border: "0.5px solid rgba(0, 0, 0, 0.2)",
    padding: "8px 0px",
  },
  cellHeader: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 5px",
  },

  addIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "solid 1px rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
    boxSizing: "border-box",
    width: "15px",
    height: "15px",

    "& p": {
      color: "rgba(0, 0, 0, 0.2)",
    },
  },

  monthLabel: {
    textAlign: "center",
  },
});

class AthleteTrainingEditor extends Component {
  constructor(props) {
    super(props);
    this.timerRef = createRef();
  }

  state = {
    alldays: [],
    weekdays: [],
    currentWorkout: null,
    weeklyView: true,
    currentMonth: 0,
    isOpenWorkoutViewer: false,
    isOnCard: false,
    deleteDialogOpen: false,
    workout: null,
    workoutDate: null,
    openAssignDialog: false,
  };

  sortWorkouts = (list) => {
    const result = list.sort((a, b) => {
      const aDate = moment(a[0].plannedDate).format(dateFormat);
      const bDate = moment(b[0].plannedDate).format(dateFormat);
      return bDate - aDate;
    });
    return result;
  };

  buildWeek = (days) => {
    if (days && days.length) {
      const orderedByDate = this.sortWorkouts(days);
      const date = moment(orderedByDate[0][0].plannedDate, dateFormat);
      const firstDay = date.clone().startOf("isoWeek");

      const weekDays = [];

      for (let day = 0; day <= 6; day++) {
        moment(firstDay)
          .add(day, "days")
          .format(dateFormat);

        weekDays.push({
          day: moment(firstDay).add(day, "days"),
        });
      }

      for (const item of days) {
        let idx = 0;
        for (const weekDay of weekDays) {
          if (
            moment(item[0].plannedDate, dateFormat).isSame(weekDay.day, "day")
          ) {
            weekDays[idx] = { ...weekDays[idx], trainingDay: item };
          }
          idx++;
        }
      }

      return weekDays;
    }
  };

  handleOpenWV = (workout) => {
    this.props.dispatch(selectAthleteWorkout(workout));

    setTimeout(
      () =>
        this.setState({
          ...this.state,
          isOpenWorkoutViewer: true,
        }),
      100,
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      currentTraining,
      currentWeek,
      monthlyTraining,
      monthsSelect,
      saving,
    } = this.props;
    const { durationInWeeks, startingDate, days = [] } = currentTraining || {};

    if (
      prevProps.monthlyTraining !== monthlyTraining ||
      prevState.currentMonth !== this.state.currentMonth
    ) {
      this.loadMonths(monthlyTraining);
    }
    if (
      !isNaN(this.props.monthsLength) &&
      prevProps.monthsLength !== this.props.monthsLength
    ) {
      let result = monthsSelect.findIndex((month) => {
        return moment().isBetween(month.start, month.end, []);
      });

      if (result < 0) {
        result = monthsSelect.length - 1;
      }

      this.setState({ ...this.state, currentMonth: result });
    }

    if (days && days.length) {
      const startingDateMoment =
        startingDate && moment(startingDate, dateFormat);
      const patchedStartingDate =
        startingDate &&
        startingDateMoment
          .startOf("isoWeek")
          .hour(12)
          .minute(0);

      const durationNumberParsed = parseInt(durationInWeeks);

      const startOfCurrentWeek = moment(patchedStartingDate).add(
        currentWeek * 7,
        "day",
      );
      const endOfCurrentWeek = moment(patchedStartingDate).add(
        currentWeek * 7 + 6,
        "day",
      );

      let newPatchedDays = [];
      let newPatchedWeekDays = [];
      for (let index = 0; index < durationNumberParsed * 7; index++) {
        const date = moment(patchedStartingDate).add(index, "day");
        const foundIndex = days.findIndex(
          (day) =>
            day && day[0] && day[0].plannedDate === date.format(dateFormat),
        );
        if (foundIndex > -1)
          newPatchedDays.push({ day: date, trainingDay: days[foundIndex] });
        else newPatchedDays.push({ day: date });

        if (
          (date.isBefore(endOfCurrentWeek) &&
            date.isAfter(startOfCurrentWeek)) ||
          date.isSame(startOfCurrentWeek) ||
          date.isSame(endOfCurrentWeek)
        ) {
          newPatchedWeekDays.push(newPatchedDays[index]);
        }
      }

      let changed =
        currentWeek !== prevProps.currentWeek ||
        this.state.weekdays.length !== newPatchedWeekDays.length;
      for (let counter = 0; counter < 7 && !changed; counter++) {
        if (
          !changed &&
          this.state.weekdays &&
          this.state.weekdays[counter] &&
          this.state.weekdays[counter].trainingDay &&
          this.state.weekdays[counter].trainingDay[0] &&
          newPatchedWeekDays &&
          newPatchedWeekDays[counter] &&
          newPatchedWeekDays[counter].trainingDay &&
          newPatchedWeekDays[counter].trainingDay[0]
        ) {
          const newTrainingDay = this.state.weekdays[counter].trainingDay[0];
          const oldTrainingDay = newPatchedWeekDays[counter].trainingDay[0];

          if (
            newTrainingDay.id !== oldTrainingDay.id ||
            newTrainingDay.isDone !== oldTrainingDay.isDone ||
            (newTrainingDay.energyLevel &&
              newTrainingDay.energyLevel !== oldTrainingDay.energyLevel) ||
            newTrainingDay.plannedDate !== oldTrainingDay.plannedDate
          ) {
            changed = true;
          }
        }

        if (
          !changed &&
          this.state.weekdays &&
          this.state.weekdays[counter] &&
          this.state.weekdays[counter].trainingDay &&
          this.state.weekdays[counter].trainingDay[0] &&
          newPatchedWeekDays &&
          newPatchedWeekDays[counter] &&
          (!newPatchedWeekDays[counter].trainingDay ||
            !newPatchedWeekDays[counter].trainingDay[0])
        ) {
          changed = true;
        }

        if (
          !changed &&
          (!this.state.weekdays ||
            !this.state.weekdays[counter] ||
            !this.state.weekdays[counter].trainingDay ||
            !this.state.weekdays[counter].trainingDay[0]) &&
          newPatchedWeekDays &&
          newPatchedWeekDays[counter] &&
          newPatchedWeekDays[counter].trainingDay &&
          newPatchedWeekDays[counter].trainingDay[0]
        ) {
          changed = true;
        }

        if (
          !changed &&
          this.state.weekdays &&
          this.state.weekdays[counter] &&
          this.state.weekdays[counter].trainingDay &&
          this.state.weekdays[counter].trainingDay[0] &&
          newPatchedWeekDays &&
          newPatchedWeekDays[counter] &&
          (!newPatchedWeekDays[counter].trainingDay ||
            !newPatchedWeekDays[counter].trainingDay[0])
        ) {
          changed = true;
        }
      }

      if (changed || (!saving && prevProps.saving)) {
        this.setState({
          alldays: newPatchedDays,
          weekdays: newPatchedWeekDays,
        });
      }
    }
  }

  loadMonths = async (monthlyTraining) => {
    const stateCopy = { ...this.state };

    if (monthlyTraining && monthlyTraining.length) {
      for (const key in stateCopy) {
        if (key.includes("week")) {
          const element = key.split("week");
          if (!isNaN(Number(element[1]))) {
            delete stateCopy[key];
          }
        }
      }
      await this.setState(stateCopy);
      const { currentMonth } = this.state;

      if (monthlyTraining[currentMonth]) {
        const currentMonthLength = monthlyTraining[currentMonth].length;
        this.setState({ currentMonthLength });
        monthlyTraining[currentMonth].map(async (item, index) => {
          const week = this.buildWeek(item);
          await this.setState({ [`week${index}`]: week });
        });
      }
    }
  };

  handleSwitch = () => {
    const { monthlyTraining } = this.props;

    this.loadMonths(monthlyTraining);

    setTimeout(() => {
      const { weeklyView } = this.state;
      this.setState({ ...this.state, weeklyView: !weeklyView });
    }, 100);
  };

  handleCloseWV = () => {
    this.setState({
      ...this.state,
      isOpenWorkoutViewer: false,
    });
  };

  setWeeks(ev, item) {
    if (
      Math.abs(ev.item.offsetLeft - ev.originalEvent.srcElement.offsetLeft) < 50
    ) {
      return;
    }

    const { onWorkoutsMoved } = this.props;

    const workoutsOfWeek = this.state[`week${item}`].filter((d) => !!d);

    const startingDateMoment = workoutsOfWeek[0].day;
    const startOfCurrentWeek = startingDateMoment
      .startOf("isoWeek")
      .hour(12)
      .minute(0);

    if (
      ev &&
      (ev.oldIndex || ev.oldIndex === 0) &&
      (ev.newIndex || ev.newIndex === 0) &&
      ev.oldIndex !== ev.newIndex
    ) {
      const newState = [...this.state[`week${item}`]];
      newState[ev.newIndex] = this.state[`week${item}`][ev.oldIndex];
      newState[ev.oldIndex] = this.state[`week${item}`][ev.newIndex];

      let changed = 0;
      for (let counter = 0; counter < 7; counter++) {
        if (newState[counter]) {
          newState[counter].day = moment(startOfCurrentWeek)
            .add(counter, "days")
            .startOf("day");
          if (
            (newState[counter].trainingDay &&
              this.state[`week${item}`][counter].trainingDay &&
              newState[counter].trainingDay[0].id !==
                this.state[`week${item}`][counter].trainingDay[0].id) ||
            (newState[counter].trainingDay &&
              !this.state[`week${item}`][counter].trainingDay) ||
            (!newState[counter].trainingDay &&
              this.state[`week${item}`][counter].trainingDay)
          ) {
            changed++;
          }
        }
      }
      if (changed === 2) {
        this.setState({ ...this.state, [`week${item}`]: newState });
        onWorkoutsMoved({
          plannedDate1: newState[ev.newIndex].trainingDay
            ? newState[ev.newIndex].trainingDay[0].plannedDate
            : null,
          workoutId1: newState[ev.newIndex].trainingDay
            ? newState[ev.newIndex].trainingDay[0].id
            : null,
          plannedDate2: newState[ev.oldIndex].trainingDay
            ? newState[ev.oldIndex].trainingDay[0].plannedDate
            : null,
          workoutId2: newState[ev.oldIndex].trainingDay
            ? newState[ev.oldIndex].trainingDay[0].id
            : null,
          relativePos1: ev.newIndex - ev.oldIndex,
          relativePos2: ev.oldIndex - ev.newIndex,
        });
      }
    }
  }

  checkIfExistActivity = (workout) => {
    const { selectedAthlete } = this.props.athletes || {};
    const { stravaActivities } = selectedAthlete || {};

    if (stravaActivities) {
      const result = stravaActivities.find((strava) =>
        moment(strava.start_date).isSame(moment(workout.day), "day"),
      );

      if (result) return true;
    }
    return false;
  };

  setDays(ev) {
    if (
      Math.abs(ev.item.offsetLeft - ev.originalEvent.srcElement.offsetLeft) < 50
    ) {
      return;
    }

    const { onWorkoutsMoved, currentWeek, currentTraining } = this.props;
    const { startingDate } = currentTraining || {};

    const startingDateMoment = startingDate && moment(startingDate, dateFormat);
    const patchedStartingDate =
      startingDate &&
      startingDateMoment
        .startOf("isoWeek")
        .hour(12)
        .minute(0);

    const startOfCurrentWeek = moment(patchedStartingDate).add(
      currentWeek * 7,
      "day",
    );

    if (
      ev &&
      (ev.oldIndex || ev.oldIndex === 0) &&
      (ev.newIndex || ev.newIndex === 0) &&
      ev.oldIndex !== ev.newIndex
    ) {
      const newState = [...this.state.weekdays];
      newState[ev.newIndex] = this.state.weekdays[ev.oldIndex];
      newState[ev.oldIndex] = this.state.weekdays[ev.newIndex];

      let changed = 0;
      for (let counter = 0; counter < 7; counter++) {
        if (newState[counter]) {
          newState[counter].day = moment(startOfCurrentWeek)
            .add(counter, "days")
            .startOf("day");
          if (
            (newState[counter].trainingDay &&
              this.state.weekdays[counter].trainingDay &&
              newState[counter].trainingDay[0].id !==
                this.state.weekdays[counter].trainingDay[0].id) ||
            (newState[counter].trainingDay &&
              !this.state.weekdays[counter].trainingDay) ||
            (!newState[counter].trainingDay &&
              this.state.weekdays[counter].trainingDay)
          ) {
            changed++;
          }
        }
      }
      if (changed === 2) {
        this.setState({ ...this.state, weekdays: newState });
        onWorkoutsMoved({
          plannedDate1: newState[ev.newIndex].trainingDay
            ? newState[ev.newIndex].trainingDay[0].plannedDate
            : null,
          workoutId1: newState[ev.newIndex].trainingDay
            ? newState[ev.newIndex].trainingDay[0].id
            : null,
          plannedDate2: newState[ev.oldIndex].trainingDay
            ? newState[ev.oldIndex].trainingDay[0].plannedDate
            : null,
          workoutId2: newState[ev.oldIndex].trainingDay
            ? newState[ev.oldIndex].trainingDay[0].id
            : null,
          relativePos1: ev.newIndex - ev.oldIndex,
          relativePos2: ev.oldIndex - ev.newIndex,
        });
      }
    }
  }
  openAssign = () => {
    this.setState({
      ...this.state,
      openAssignDialog: true,
    });
  };

  closeAssign = () => {
    this.setState({
      ...this.state,
      openAssignDialog: false,
    });
  };

  render() {
    const {
      classes,
      currentWeek,
      currentTraining,
      saving,
      onRequestWeekChange,
      onWorkoutAdd,
      onWorkoutDelete,
      planName,
      data,
      options,
      onAddWeek,
      onRemoveWeek,
      onAddWeekNext,
      onOpenChat,
      onAddEasyWeek,
      onAddEasyWeekNext,
      onRemoveChainedPlan,
      monthlyTraining,
      monthsLength,
      monthsSelect = [],
      athletes,
    } = this.props;

    const { weeklyView, currentMonth, openAssignDialog } = this.state;
    const { selectedAthlete } = athletes || {};
    const { selectedWorkout = {} } = selectedAthlete || {};

    const { durationInWeeks, chainedPlans, startingDate } =
      currentTraining || {};
    const durationNumber = parseInt(durationInWeeks);

    const level = getPlanLevel(currentTraining || {});

    const handleModal = (workout) => {
      this.timerRef = setTimeout(() => {
        if (workout) this.props.dispatch(selectAthleteWorkout(workout));

        this.setState({
          ...this.state,
          currentWorkout: workout ? workout : null,
        });
      }, 2000);
    };

    const openDeleteDialog = (workout, workoutDate) => {
      this.setState({
        ...this.state,
        deleteDialogOpen: true,
        workout: workout,
        workoutDate: workoutDate,
      });
    };

    const handleModalLeave = () => {
      clearTimeout(this.timerRef);
      this.timerRef = null;
      this.setState({ ...this.state, currentWorkout: null });
    };

    const parentLeave = () => {
      clearTimeout(this.timerRef);
      this.timerRef = null;
    };

    const handleClose = () => {
      this.setState({
        ...this.state,
        deleteDialogOpen: false,
        workout: null,
        workoutDate: null,
      });
    };

    const menuItems = [];

    for (let i = 0; i < durationInWeeks; i++) {
      menuItems.push(
        <MenuItem value={i} key={i}>
          week {i + 1}
        </MenuItem>,
      );
    }

    const days = ["M", "T", "W", "T", "F", "S", "S"];

    const handleNextMonth = () => {
      const { currentMonth } = this.state;
      if (currentMonth < monthsLength - 1)
        this.setState({ ...this.state, currentMonth: currentMonth + 1 });
    };

    const handlePreviousMonth = () => {
      const { currentMonth } = this.state;
      if (currentMonth >= 1)
        this.setState({ ...this.state, currentMonth: currentMonth - 1 });
    };

    return (
      <>
        <div>
          <div className={classes.actionsBar}>
            <div className={classes.leftSection}>
              {currentTraining ? (
                <>
                  {!weeklyView && (
                    <>
                      <IconButton
                        className={classes.iconButton}
                        aria-label="Previous Month"
                        disabled={currentMonth == 0}
                        onClick={handlePreviousMonth}
                        size="small"
                      >
                        <ArrowBackIosIcon />
                      </IconButton>

                      {monthsSelect[this.state.currentMonth] && (
                        <>
                          <Typography
                            variant="body2"
                            className={classes.monthLabel}
                          >
                            {moment(monthsSelect[this.state.currentMonth].start)
                              .format("MMM DD")
                              .toUpperCase()}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.monthLabel}
                          >
                            -
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.monthLabel}
                          >
                            {moment(monthsSelect[this.state.currentMonth].end)
                              .format("MMM DD")
                              .toUpperCase()}
                          </Typography>
                        </>
                      )}

                      <IconButton
                        className={classes.iconButton}
                        aria-label="Next Month"
                        disabled={currentMonth == monthsLength - 1}
                        onClick={handleNextMonth}
                        size="small"
                      >
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </>
                  )}

                  {weeklyView && (
                    <>
                      <Select
                        id="week-filter-select"
                        value={
                          weeklyView ? currentWeek || 0 : currentMonth || 0
                        }
                        onChange={
                          weeklyView
                            ? (event) =>
                                onRequestWeekChange(+event.target.value)
                            : (event) =>
                                this.setState({
                                  ...this.state,
                                  currentMonth: event.target.value,
                                })
                        }
                        className={classes.weekTitle}
                      >
                        {menuItems}
                      </Select>

                      <IconButton
                        className={classes.iconButton}
                        aria-label="Previous Week"
                        disabled={currentWeek === 0}
                        onClick={onRequestWeekChange.bind(
                          this,
                          currentWeek - 1,
                        )}
                        size="small"
                      >
                        <ArrowBackIosIcon />
                      </IconButton>

                      <IconButton
                        className={classes.iconButton}
                        aria-label="Next Week"
                        disabled={currentWeek === durationNumber - 1}
                        onClick={onRequestWeekChange.bind(
                          this,
                          currentWeek + 1,
                        )}
                        size="small"
                      >
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </>
                  )}
                </>
              ) : null}

              <Typography variant="h5" className={classes.planName}>
                {planName}
              </Typography>

              <div className={classes.planContainer}>
                <div className={classes.titleContainer}>
                  {currentTraining && weeklyView ? (
                    <ActionsMenu
                      onAddWeek={onAddWeek.bind()}
                      onRemoveWeek={onRemoveWeek.bind()}
                      onAddWeekNext={onAddWeekNext.bind()}
                      onAddEasyWeek={onAddEasyWeek.bind()}
                      onAddEasyWeekNext={onAddEasyWeekNext.bind()}
                      onRemoveChainedPlan={onRemoveChainedPlan.bind()}
                      chainedPlans={chainedPlans}
                    ></ActionsMenu>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {openAssignDialog ? (
                <AthleteAssignTraining closeAssign={this.closeAssign} />
              ) : null}

              <Fab
                onClick={this.openAssign}
                variant="extended"
                aria-label="Assign training plan"
                className={classes.fab}
                color="primary"
              >
                Assign training plan
              </Fab>
            </div>

            <div className={classes.switchView}>
              <Typography variant="body2">Weekly View</Typography>
              <Switch
                onChange={this.handleSwitch}
                checked={!weeklyView}
                disabled={saving}
              />
              <Typography variant="body2">Monthly View</Typography>
            </div>
          </div>

          <div className={classes.container}>
            {saving ? (
              <LinearProgress variant="indeterminate" color="primary" />
            ) : null}

            {currentTraining && weeklyView ? (
              <ReactSortable
                list={this.state.weekdays}
                setList={() => {}}
                onUpdate={(ev) => this.setDays(ev)}
                forceFallback={true}
                swap
              >
                {this.state.weekdays &&
                  this.state.weekdays.map((day, index) => {
                    return (
                      <AthleteTrainingEditorWeekday
                        key={index}
                        onWorkoutAdd={onWorkoutAdd}
                        trainingDay={day.trainingDay}
                        dayIndex={index}
                        workoutDate={day.day}
                        onWorkoutDelete={onWorkoutDelete}
                        onOpenChat={onOpenChat}
                        handleModal={handleModal}
                        currentSelected={this.state.currentWorkout}
                        handleModalLeave={handleModalLeave}
                        parentLeave={parentLeave}
                      />
                    );
                  })}
              </ReactSortable>
            ) : null}

            {currentTraining && !weeklyView && !!this.state.weekdays.length && (
              <div className={classes.parent}>
                <div style={{ display: "flex" }}>
                  {days.map((day, index) => (
                    <div
                      key={index}
                      className={classes.workoutCell}
                      style={{ width: `${100 / 7}%` }}
                    >
                      <Typography
                        variant="body2"
                        style={{ textAlign: "center" }}
                      >
                        {day}
                      </Typography>
                    </div>
                  ))}
                </div>
                {Boolean(this.state.currentMonthLength) &&
                monthlyTraining[this.state.currentMonth]
                  ? monthlyTraining[this.state.currentMonth].map(
                      (item, index) => (
                        <div className={classes.weekdayContainer} key={index}>
                          <div className={classes.day}>
                            <div className={classes.workoutContainer}>
                              <>
                                <ReactSortable
                                  list={this.state[`week${index}`] || []}
                                  setList={() => {}}
                                  onUpdate={(ev) => this.setWeeks(ev, index)}
                                  forceFallback={true}
                                  swap
                                  style={{ display: "flex" }}
                                >
                                  {(this.state[`week${index}`] || []).map(
                                    (workout, index) => {
                                      if (workout) {
                                        if (
                                          workout.trainingDay &&
                                          workout.trainingDay.length
                                        ) {
                                          const newEstimatedTime = Array.isArray(
                                            workout.trainingDay[0]
                                              .estimatedTime,
                                          )
                                            ? workout.trainingDay[0]
                                                .estimatedTime[
                                                !isNaN(
                                                  getCustomLevel(
                                                    workout.trainingDay[0]
                                                      .plannedDate,
                                                    chainedPlans,
                                                  ),
                                                )
                                                  ? getCustomLevel(
                                                      workout.trainingDay[0]
                                                        .plannedDate,
                                                      chainedPlans,
                                                    )
                                                  : level
                                              ]
                                            : workout.trainingDay[0]
                                                .estimatedTime;

                                          return (
                                            <div
                                              className={classes.workoutCell}
                                              style={{ width: `${100 / 7}%` }}
                                              key={index}
                                            >
                                              <div
                                                className={classes.cellHeader}
                                              >
                                                <div
                                                  className={classes.addIcon}
                                                >
                                                  <Typography>+</Typography>
                                                </div>
                                                <Typography variant="body2">
                                                  {moment(
                                                    workout.trainingDay[0]
                                                      .plannedDate,
                                                    "DD/MM",
                                                  ).format("DD")}
                                                </Typography>
                                              </div>
                                              <div
                                                className={
                                                  classes.workoutParent
                                                }
                                                onMouseEnter={() =>
                                                  handleModal(
                                                    workout.trainingDay[0],
                                                  )
                                                }
                                                onMouseLeave={parentLeave}
                                              >
                                                {this.state.currentWorkout &&
                                                  workout.trainingDay[0] &&
                                                  workout.trainingDay[0].id ===
                                                    this.state.currentWorkout
                                                      .id &&
                                                  workout.trainingDay[0]
                                                    .plannedDate ===
                                                    this.state.currentWorkout
                                                      .plannedDate && (
                                                    <AthleteResumeModal
                                                      workout={
                                                        workout.trainingDay[0]
                                                      }
                                                      close={handleModal}
                                                      onRemove={() =>
                                                        openDeleteDialog(
                                                          workout.trainingDay[0]
                                                            .id,
                                                          workout.day,
                                                        )
                                                      }
                                                      onMouseLeave={
                                                        handleModalLeave
                                                      }
                                                    />
                                                  )}

                                                <WorkoutCard
                                                  workoutType={
                                                    workout.trainingDay[0]
                                                      .workoutType
                                                  }
                                                  isDone={
                                                    workout.trainingDay[0]
                                                      .isDone
                                                  }
                                                  estimatedTime={EstimatedTimes.convertMinutesToLabel(
                                                    newEstimatedTime,
                                                    true,
                                                  )}
                                                  onClick={() =>
                                                    this.handleOpenWV(
                                                      workout.trainingDay[0],
                                                    )
                                                  }
                                                ></WorkoutCard>
                                              </div>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              className={classes.workoutCell}
                                              style={{ width: `${100 / 7}%` }}
                                              key={index}
                                            >
                                              <div
                                                className={classes.cellHeader}
                                              >
                                                <div
                                                  className={classes.addIcon}
                                                >
                                                  <Typography>+</Typography>
                                                </div>
                                                <Typography variant="body2">
                                                  {moment(
                                                    workout.day,
                                                    "DD/MM",
                                                  ).format("DD")}
                                                </Typography>
                                              </div>
                                              {this.checkIfExistActivity(
                                                workout,
                                              ) && (
                                                <div
                                                  style={{ padding: "0px 8px" }}
                                                >
                                                  <WorkoutCard
                                                    workoutType="NoWorkout"
                                                    isDone={false}
                                                    onClick={null}
                                                    withCheckIcon={false}
                                                    customText="The user didn't have a workout but has an activity this day"
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          );
                                        }
                                      }
                                      return "";
                                    },
                                  )}
                                </ReactSortable>
                              </>
                            </div>
                          </div>
                        </div>
                      ),
                    )
                  : ""}
              </div>
            )}

            {currentTraining && weeklyView ? (
              <>
                <Typography variant="h5" className={classes.energyLeveltTitle}>
                  Energy Level
                </Typography>
                <div className={classes.graphContainer}>
                  <Line data={data} options={options} />
                </div>
              </>
            ) : null}
          </div>
        </div>

        <Dialog
          disableEnforceFocus
          open={this.state.deleteDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={handleClose}
        >
          <DialogTitle>
            {"Are you sure you want to delete this workout?"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                onWorkoutDelete(this.state.workout, this.state.workoutDate);
                handleClose();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.isOpenWorkoutViewer}
          onClose={() => this.handleCloseWV()}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          disableEnforceFocus
        >
          <AthleteTrainingWorkoutViewer
            onClose={() => this.handleCloseWV()}
            onOpenChat={(ev) => onOpenChat(ev)}
          ></AthleteTrainingWorkoutViewer>
        </Dialog>
      </>
    );
  }
}

AthleteTrainingEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  currentWeek: PropTypes.number.isRequired,
  currentTraining: PropTypes.object,
  onRequestWeekChange: PropTypes.func.isRequired,
  onWorkoutAdd: PropTypes.func.isRequired,
  onWorkoutDelete: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  onWorkoutsMoved: PropTypes.func.isRequired,
  planName: PropTypes.string,
  data: PropTypes.object,
  options: PropTypes.object,
  onAddWeek: PropTypes.func.isRequired,
  onRemoveWeek: PropTypes.func.isRequired,
  onAddWeekNext: PropTypes.func.isRequired,
};

AthleteTrainingEditor.defaultProps = {
  display: false,
};

export default connect((store) => {
  return {
    athletes: store.athletes,
  };
})(withStyles(styles)(memo(AthleteTrainingEditor)));
