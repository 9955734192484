import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import history from "../../lib/history-helper";
import FontAwesomeIcon from "../common/font-awesome-icon";
import RouterLink from "../common/router-link";
import UserGroups from "../../constants/user-groups";
import ActivateTrailheadDialog from "../trail-head/activate-trailhead-dialog";
import { updatePlan } from "../../actions/user-plans-action";
import UpgradeProModal from "../generals/upgrade-pro-modal";

const styles = (theme) => ({
  typography: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 5,
    paddingLeft: theme.spacing.unit,
    "&:hover": {
      background: "lightgray",
    },
  },
  icon: {
    minWidth: 40,
  },
  button: {
    fontWeight: "normal",
    textTransform: "none",
    marginLeft: 20,
    marginRight: 20,
  },
  iconButton: {
    height: 16,
    marginRight: 5,
  },
  iconButtonP: {
    height: 20,
    marginRight: 5,
  },
  pro: {
    color: "#FF5722",
    fontSize: 10,
    fontWeight: 500,
    marginLeft: 4,
    alignSelf: "baseline",
  },
});

class AppBarMenu extends Component {
  state = {
    anchorEl: null,
    isOpenActivateTH: false,
    exploreFeatureDialogOpen: false,
  };

  handleCloseUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
  };

  handleUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
    history.push("/subscription/explore");
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  openTrailhead = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { trailhead } = currentPlan || "";

    if (trailhead) {
      history.push(`/trailhead`);
    } else {
      this.handleActivateTrailhead();
    }
  };

  handleOpenActivateTrailhead = () => {
    this.setState({
      ...this.state,
      isOpenActivateTH: true,
    });
  };

  handleCloseActivateTrailhead = () => {
    this.setState({
      ...this.state,
      isOpenActivateTH: false,
    });
  };

  handleActivateTrailhead = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    if (currentPlan) {
      this.props.dispatch(
        updatePlan(
          { cognito_user_sub: currentPlan.cognito_user_sub, trailhead: true },
          false,
          false,
        ),
      );
    }

    history.push(`/trailhead`);
  };

  render() {
    const { auth, classes, t } = this.props;

    const { anchorEl, isOpenActivateTH, exploreFeatureDialogOpen } = this.state;
    const { currentUserLoading, isAuthenticated, currentUserGroups } = auth;

    const isStrengthCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_STRENGTH_COACH,
      ) > -1;

    const isCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_COACH,
      ) > -1;

    const isExternalCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
      ) > -1;

    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;

    return (
      <Fragment>
        <RouterLink to="/">
          <Button variant="text" className={classes.button}>
            <img
              src={`${process.env.PUBLIC_URL}/user-menu-icon.svg`}
              alt=""
              className={classes.iconButtonP}
            />
            {t("Profile")}
          </Button>
        </RouterLink>

        <Button
          variant="text"
          className={classes.button}
          onClick={this.openTrailhead}
        >
          <img
            src={`${process.env.PUBLIC_URL}/trailhead-menu-icon.svg`}
            alt=""
            className={classes.iconButton}
            style={{
              height: 23,
            }}
          />
          Trailhead
        </Button>

        <RouterLink to="/explore">
          <Button variant="text" className={classes.button}>
            <img
              src={`${process.env.PUBLIC_URL}/icon-explore.svg`}
              alt=""
              className={classes.iconButton}
            />
            Explore
          </Button>
        </RouterLink>

        {!currentUserLoading &&
          isAuthenticated &&
          (isCoach || isAdmin || isExternalCoach || isStrengthCoach) && (
            <Fragment>
              <Button
                variant="text"
                className={classes.button}
                aria-owns={anchorEl ? "coach-menu" : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                {t("Coach Menu")} &nbsp;&nbsp;
                <FontAwesomeIcon icon="caret-down" variant="solid" />
              </Button>
              <Popover
                id="coach-menu"
                open={anchorEl ? true : false}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {(isCoach || isExternalCoach || isStrengthCoach) && (
                  <RouterLink to="/workouts">
                    <Typography className={classes.typography}>
                      <FontAwesomeIcon
                        className={classes.icon}
                        variant="solid"
                        icon="dumbbell"
                        size="lg"
                      />{" "}
                      {t("Workouts")}
                    </Typography>
                  </RouterLink>
                )}
                {(isCoach || isExternalCoach || isStrengthCoach) && (
                  <RouterLink to="/trainings">
                    <Typography className={classes.typography}>
                      <FontAwesomeIcon
                        className={classes.icon}
                        variant="solid"
                        icon="columns"
                        size="lg"
                      />{" "}
                      {t("Trainings")}
                    </Typography>
                  </RouterLink>
                )}
                {(isAdmin || isCoach || isExternalCoach || isStrengthCoach) && (
                  <RouterLink to="/athletes">
                    <Typography className={classes.typography}>
                      <FontAwesomeIcon
                        className={classes.icon}
                        variant="solid"
                        icon="running"
                        size="lg"
                      />{" "}
                      {t("Athletes")}
                    </Typography>
                  </RouterLink>
                )}
                {isAdmin && (
                  <RouterLink to="/admin-dashboard">
                    <Typography className={classes.typography}>
                      <FontAwesomeIcon
                        className={classes.icon}
                        variant="solid"
                        icon="running"
                        size="lg"
                      />{" "}
                      {t("Data Dashboard")}
                    </Typography>
                  </RouterLink>
                )}
              </Popover>
            </Fragment>
          )}
        <ActivateTrailheadDialog
          open={isOpenActivateTH}
          onCancel={this.handleCloseActivateTrailhead}
          onConfirm={this.handleActivateTrailhead}
        ></ActivateTrailheadDialog>

        <UpgradeProModal
          open={exploreFeatureDialogOpen}
          onClose={this.handleCloseUpgrade}
        />
      </Fragment>
    );
  }
}

AppBarMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isNotOnChoosingPlanPage: PropTypes.bool,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
    auth: store.auth,
    subscriptions: store.subscriptions,
  };
})(
  withTranslation("layout")(
    withStyles(styles, { withTheme: true })(AppBarMenu),
  ),
);
