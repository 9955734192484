import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withMobileDialog,
  LinearProgress,
  Typography,
  Select,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { Routes } from "../../../lib/routing-middleware";
import history from "../../../lib/history-helper";
import Trainings from "../../trainings";
import {
  assignTrainingToAthlete,
  chainTrainingToAthleteAfterCurrent,
} from "../../../actions/athletes-action";
import LocalNotificationsService from "../../../services/local-notifications-service";
import RaceCounts from "../../../constants/race-counts";
import FilterSelect from "../../common/filter-select";
import { getPlanLevel } from "../../../lib/plan-helper";

const styles = (theme) => ({
  paper: {
    minHeight: 530,
  },
  actionContainer: {
    justifyContent: "space-between",
    height: 60,
    alignItems: "end",
  },
  dateContainer: {
    display: "flex",
    height: "100%",
    paddingLeft: theme.spacing.unit * 3,
  },
  text: {
    paddingRight: theme.spacing.unit * 3,
  },
});
class AthleteAssignTraining extends Component {
  state = {
    startingDate: undefined,
    type: 0,
    isLevelDialogOpen: false,
    currentRow: null,
    raceCount: null,
  };

  handleAssignTraining = (row) => {
    if (row.raceCount === "auto") {
      this.setState({
        isLevelDialogOpen: true,
        currentRow: row,
      });
      return;
    }
    if (row) {
      const { athletes } = this.props;
      if (athletes) {
        const { selectedAthlete, selectedAthleteChainedPlans } = athletes;
        if (selectedAthlete) {
          const { cognito_user_sub } = selectedAthlete;

          const { startingDate, type } = this.state;

          if (type === 0) {
            this.props.dispatch(
              assignTrainingToAthlete({
                trainingPlan: row,
                cognito_user_sub: cognito_user_sub,
                startingDate: startingDate,
              }),
            );
          }
          if (type === 1 || type === 2) {
            let currentPlan = selectedAthlete.currentTraining;

            if (
              type === 2 &&
              selectedAthleteChainedPlans &&
              selectedAthleteChainedPlans.length
            ) {
              const newPlan =
                selectedAthleteChainedPlans[
                  selectedAthleteChainedPlans.length - 1
                ];
              if (newPlan && newPlan.days) currentPlan = newPlan;
            }

            const workouts = currentPlan.days.filter((d) => d !== null);

            let startMoment = moment(
              workouts[workouts.length - 1][0].plannedDate,
              "DD/MM/YYYY",
            );

            startMoment = startMoment.startOf("isoWeek");
            startMoment = startMoment.add(1, "week");

            const { raceCount, ...trainingPart } = row;

            this.props.dispatch(
              chainTrainingToAthleteAfterCurrent({
                trainingPlan: trainingPart,
                selectedAthlete,
                startingDate: startMoment,
                position: type === 1 ? 0 : 1,
                level: row.raceCount,
              }),
            );
          }

          // setTimeout(() => this.resetWorkoutNotifications(), 1000);

          this.handleClose();
        }
      }
    }
  };

  resetWorkoutNotifications() {
    const { athletes } = this.props;

    const { selectedAthlete } = athletes;

    const {
      currentTraining,
      showWorkoutNotifications,
      workoutNotificationsTime,
      cognito_user_sub,
    } = selectedAthlete || {};
    const { days } = currentTraining || {};

    const level = getPlanLevel(currentTraining);
    if (showWorkoutNotifications) {
      if (days) {
        LocalNotificationsService.scheduleWorkoutNotifications(
          days,
          workoutNotificationsTime,
          cognito_user_sub,
          level,
        );
      }
    }
  }

  handleClose = () => {
    const { athletes, closeAssign } = this.props;
    if (athletes) {
      const { selectedAthlete } = athletes;
      if (selectedAthlete) {
        closeAssign();
      } else {
        history.push(`${Routes.AthletesPage}`);
      }
    } else {
      history.push(`${Routes.AthletesPage}`);
    }
  };

  handleDateChange = (momentObject) => {
    const { onError } = this.props;
    if (momentObject) {
      try {
        this.setState({
          ...this.state,
          startingDate: momentObject.toISOString(),
        });
      } catch (exp) {
        if (onError) onError(exp);
      }
    } else {
      this.setState({
        ...this.state,
        startingDate: undefined,
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      ...this.state,
      type: +event.target.value,
    });
  };

  handleSelectLevel = ({ name, value }) => {
    this.setState({
      ...this.state,
      raceCount: value,
    });
  };

  handleConfirmSelectLevel = () => {
    const { currentRow, raceCount } = this.state;
    this.handleAssignTraining({ ...currentRow, raceCount });
  };

  handleCloseLevelDialog = () => {
    this.setState({
      ...this.state,
      raceCount: null,
      isLevelDialogOpen: false,
    });
  };

  render() {
    const { classes, fullScreen, training, athletes, closeAssign } = this.props;
    const { loading } = training;
    const { assigningTraining } = athletes;
    const { startingDate, type, isLevelDialogOpen, raceCount } = this.state;

    const raceCountsItemsArray = RaceCounts.itemsArray;
    const raceCountItems = [
      {
        text: "Level 1",
        value: `${raceCountsItemsArray[0].value},${raceCountsItemsArray[1].value}`,
      },
      {
        text: "Level 2",
        value: `${raceCountsItemsArray[2].value},${raceCountsItemsArray[3].value}`,
      },
      {
        text: "Level 3",
        value: `${raceCountsItemsArray[4].value},${raceCountsItemsArray[5].value}`,
      },
    ];

    return (
      <Dialog
        maxWidth="md"
        fullScreen={fullScreen}
        open={true}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="form-dialog-title">
          Search trainings and assign one to athlete
          <LinearProgress
            color="primary"
            variant={
              loading || assigningTraining ? "indeterminate" : "determinate"
            }
            value={0}
          />
        </DialogTitle>
        <DialogContent>
          <Trainings
            addable={false}
            updatable={false}
            selectable={false}
            onSelectByDoubleClick={this.handleAssignTraining}
          />
        </DialogContent>
        <DialogActions className={classes.actionContainer}>
          <Select
            native
            value={type}
            onChange={(e) => this.handleChange(e)}
            inputProps={{
              name: "type",
              id: "type-native-simple",
            }}
          >
            <option aria-label="None" value="" />
            <option value={0}>Select starting date</option>
            <option value={1}>Start at end of current plan</option>
            <option value={2}>Start at end of training</option>
          </Select>

          {type === 0 ? (
            <div className={classes.dateContainer}>
              <Typography variant="h6" color="primary" className={classes.text}>
                Starting Date
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  value={startingDate || null}
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/AAAA"
                  mask={(value) =>
                    // handle clearing outside if value can be changed outside of the component
                    value
                      ? [
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]
                      : []
                  }
                  autoOk
                  clearable
                  keyboard
                  disableOpenOnEnter
                  animateYearScrolling={false}
                  onChange={this.handleDateChange}
                />
              </MuiPickersUtilsProvider>
            </div>
          ) : (
            ""
          )}

          <Button onClick={this.handleClose} color="primary" disabled={loading}>
            Close
          </Button>
        </DialogActions>

        <Dialog open={isLevelDialogOpen} onClose={this.handleCloseLevelDialog}>
          <DialogTitle>Choose level</DialogTitle>
          <DialogContent>
            <FilterSelect
              value={raceCount}
              name="raceCount"
              text="Level"
              items={raceCountItems}
              onSelect={this.handleSelectLevel}
              required
              small
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseLevelDialog}
              color="primary"
              disabled={loading}
            >
              Close
            </Button>

            <Button
              onClick={this.handleConfirmSelectLevel}
              color="primary"
              disabled={loading}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  }
}

AthleteAssignTraining.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  closeAssign: PropTypes.func.isRequired,
};

export default withMobileDialog()(
  withStyles(styles)(
    connect((store) => {
      return {
        training: store.training,
        athletes: store.athletes,
      };
    })(AthleteAssignTraining),
  ),
);
