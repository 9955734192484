import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Typography, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CommentIcon from "@material-ui/icons/Comment";
import moment from "moment";

import TrainingWorkoutChips from "../training-workout-search/training-workout-chips";
import Weekdays from "../../../constants/weekdays";
import { dateFormatShort } from "../../../lib/date-helper";
import { convertToRTFForView } from "../../../lib/rtf-helper";
import workoutToText from "../../../lib/workoutToText";

const styles = (theme) => ({
  day: {
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    border: "1px solid silver",
    minHeight: 300,
    borderRadius: 4,
  },
  weekdayContainer: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    flex: 1 / 7,
    height: "90%",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  dayTitle: {
    border: "1px solid lightgrey",
    textAlign: "center",
    borderRadius: 4,
  },
  workout: {
    paddingBottom: 10,
  },
  commentIcon: {
    display: "block",
    marginLeft: "auto",
    marginRight: 0,
  },
  icon: {
    color: "dodgerblue",
  },
  newLine: {
    whiteSpace: "pre-line",
  },
  workoutGeneratedTextContainer: {
    boxShadow: "0px 0px 10px 1px rgb(0 0 0 / 25%)",
    padding: 15,
    margin: "20px 0",
    maxHeight: 250,
    overflow: "scroll",
  },

  workoutGeneratedTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },

  workoutStepsContainer: {
    overflow: "scroll",
    height: 640,
  },
});

class TrainingEditorWeekday extends Component {
  handleOnDrop = (dayIndexOrPlannedDate, event) => {
    const { onWorkoutAdd } = this.props;
    if (onWorkoutAdd) onWorkoutAdd(dayIndexOrPlannedDate);
  };

  handleOnDragOver = (event) => {
    event.preventDefault();
  };

  render() {
    const {
      classes,
      dayIndex,
      trainingDay,
      onWorkoutDelete,
      onWorkoutCommentDialog,
      workoutDate,
      showDates,
      offsetDayIndex,
      outOfRangeDate,
      disabled: disabledExt,
      level = 2,
    } = this.props;
    const disabled = outOfRangeDate || disabledExt;
    return (
      <div className={classes.weekdayContainer}>
        <Typography variant="h6" gutterBottom className={classes.dayTitle}>
          {Weekdays.itemsArray[dayIndex % 7].text}
        </Typography>
        {showDates && (
          <Typography variant="h6" gutterBottom className={classes.dayTitle}>
            {moment(workoutDate).format(dateFormatShort)}
          </Typography>
        )}
        <div
          className={classes.day}
          onDragOver={!disabled ? this.handleOnDragOver : null}
          onDrop={
            !disabled
              ? showDates
                ? this.handleOnDrop.bind(this, workoutDate)
                : this.handleOnDrop.bind(this, dayIndex - offsetDayIndex)
              : null
          }
        >
          <Fragment>
            {trainingDay &&
              trainingDay.map((workout, index) => {
                const {
                  title,
                  description,
                  userCreatedType,
                  parametrizedWorkout,
                } = workout;
                const displayedTitle = userCreatedType
                  ? userCreatedType
                  : title && (title["en"] || title["es"]);
                const displayedDescription =
                  description && (description["en"] || description["es"]);

                return (
                  <div className={classes.workout} key={index}>
                    <div className={classes.titleContainer}>
                      <Typography variant="h6" className={classes.title}>
                        {displayedTitle}
                      </Typography>
                      <div>
                        <IconButton
                          color="primary"
                          aria-label="Remove"
                          onClick={
                            showDates
                              ? onWorkoutDelete.bind(
                                  this,
                                  workout.id,
                                  workoutDate,
                                )
                              : onWorkoutDelete.bind(
                                  this,
                                  workout.id,
                                  dayIndex - offsetDayIndex,
                                )
                          }
                          disabled={disabled}
                        >
                          <CancelIcon />
                        </IconButton>
                      </div>
                    </div>

                    {parametrizedWorkout &&
                    parametrizedWorkout[level] &&
                    parametrizedWorkout[level].length ? (
                      <div className={classes.workoutGeneratedTextContainer}>
                        <Typography
                          variant="body1"
                          className={classes.workoutGeneratedTitle}
                        >
                          Workout detail Preview
                        </Typography>

                        <Typography
                          variant="body1"
                          className={classes.workoutGeneratedText}
                          dangerouslySetInnerHTML={{
                            __html: workoutToText(parametrizedWorkout[level]),
                          }}
                        ></Typography>
                        <div></div>
                      </div>
                    ) : (
                      ""
                    )}

                    <Typography
                      variant="body2"
                      title={displayedDescription}
                      className={classes.newLine}
                      dangerouslySetInnerHTML={convertToRTFForView(
                        displayedDescription &&
                          displayedDescription.length > 250
                          ? displayedDescription
                              .substring(0, 250)
                              .concat("....")
                          : displayedDescription,
                      )}
                    ></Typography>
                    <TrainingWorkoutChips workout={workout} level={level} />
                    {onWorkoutCommentDialog && (
                      <IconButton
                        color="default"
                        className={classes.commentIcon}
                        aria-label="Comment"
                        onClick={onWorkoutCommentDialog.bind(
                          this,
                          workout.id,
                          workoutDate,
                        )}
                      >
                        <CommentIcon />
                      </IconButton>
                    )}
                  </div>
                );
              })}
          </Fragment>
        </div>
      </div>
    );
  }
}

TrainingEditorWeekday.propTypes = {
  classes: PropTypes.object.isRequired,
  dayIndex: PropTypes.number.isRequired,
  onWorkoutAdd: PropTypes.func.isRequired,
  trainingDay: PropTypes.array,
  onWorkoutDelete: PropTypes.func.isRequired,
  onWorkoutCommentDialog: PropTypes.func,
  workoutDate: PropTypes.object, //Athletes: date to show on athletes tp viewer
  showDates: PropTypes.bool, //Athletes: to show dates for athletes tp viewer
  offsetDayIndex: PropTypes.number, //Athletes: extra days count that were added to the beginning of days array
  outOfRangeDate: PropTypes.bool, //Athletes: for disabling extra added days
};

TrainingEditorWeekday.defaultProps = {
  showDates: false,
  outOfRangeDate: false,
  offsetDayIndex: 0,
};

export default withStyles(styles)(TrainingEditorWeekday);
