import React from "react";
import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { palette } from "../../../theme/palette";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import useDateBetween from "../../../hooks/useDateBetween";

const useStyles = makeStyles({
  spaceBetweenPad: {
    padding: "0 35px",
  },
  dateText: {
    color: palette.black,
  },
  iconButton: {
    width: "30px",
    height: "30px",
  },
});

const DateBetween = () => {
  const classes = useStyles();

  const {
    currentFilter,
    handleNextArrow,
    handlePreviousArrow,
    datePrefix,
    dateFrom,
    dateTo,
  } = useDateBetween();

  return (
    <>
      <Box
        sx={{
          margin: "0 0 24px 0",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <IconButton
          className={classes.iconButton}
          onClick={() => handlePreviousArrow()}
          disabled={currentFilter === 2}
          id="profile_left_arrow"
        >
          <ArrowBackIos style={{ width: 18 }} />
        </IconButton>

        <Box className={classes.spaceBetweenPad}>
          <Typography className={classes.dateText}>
            <b>{datePrefix()} </b>
            {dateFrom()} {dateTo()}
          </Typography>
        </Box>

        <IconButton
          onClick={() => handleNextArrow()}
          className={classes.iconButton}
          disabled={currentFilter === 2}
          id="profile_right_arrow"
        >
          <ArrowForwardIos style={{ width: 18 }} />
        </IconButton>
      </Box>
    </>
  );
};

export default DateBetween;
