import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography, Avatar, TextField, Button, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { Storage } from "aws-amplify";
import { withTranslation } from "react-i18next";
import moment from "moment";
import LinkPreview from "@ashwamegh/react-link-preview";
import "@ashwamegh/react-link-preview/dist/index.css";
import "moment/min/locales";
import RouterLink from "../../common/router-link";
import PostActionsMenu from "./post-actions-menu";
import CircularProgress from "@material-ui/core/CircularProgress";
import Allez from "./allez";
import { getVideoIframe, getYoutubeId } from "../../../lib/youtube-helper";
import linkifyStr from "linkify-string";
import { getLevel } from "../../../lib/rates-helper";

const styles = (theme) => ({
  container: {
    maxWidth: 620,
    padding: "10px 20px 0",
  },
  postImage: {
    width: "100%",
  },
  topBarContainer: {
    display: "flex",
  },
  topBar: {
    display: "flex",
    alignItems: "center",
  },
  actions: {
    marginLeft: "auto",
  },
  avatar: {
    marginRight: 10,
    position: "relative",
  },
  levelMiniBadge: {
    height: 25,
    bottom: -5,
    right: -10,
    position: "absolute",
  },
  username: {
    fontWeight: "bold",
    fontSize: 16,
  },
  raceName: {
    fontSize: 14,
    color: "#A4A4B2",
  },
  title: {
    title: 16,
    fontWeight: "bold",
  },
  text: {
    fontSize: 14,
    whiteSpace: "pre-wrap",
    marginBottom: 10,
  },
  bottomBar: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
  },
  count: {
    margin: "0 0 0 5px",
    fontSize: 12,
  },
  divider: {
    border: "solid 1px #ECEDF4",
  },
  metaBar: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
  metaChip: {
    marginRight: 10,
  },
  metaText: {
    color: "#A4A4B2",
    fontSize: 14,
  },
  allezIcon: {
    cursor: "pointer",
  },

  commentsContainer: {},
  commentIcon: {
    marginLeft: 10,
  },

  reply: {
    marginLeft: 10,
    cursor: "pointer",
    color: "#A4A4B2",
    fontSize: 12,
  },
  editButtons: {
    display: "flex",
  },
  cancelButton: {
    marginLeft: "auto",
  },
  loadingAllez: {
    width: "15px !important",
    height: "15px !important",
    marginLeft: 5,
  },
  externalLink: {
    color: "#000",
    textAlign: "center",
  },
});

class Reply extends Component {
  state = {
    imgUrls: {},
    isEditing: false,
  };

  async componentDidMount() {
    this.checkImages();
  }

  checkImages = () => {
    const { commentData } = this.props;
    let images = [];
    if (commentData.by && commentData.by.image) {
      images.push(commentData.by.image);
    }
    if (commentData.image) {
      images.push(commentData.image);
    }
    this.getUrls(images);
  };

  getUrls = async (images) => {
    if (images) {
      const imgUrls = {};
      for (let i = 0; i < images.length; i++) {
        try {
          const result = await Storage.get(images[i], {
            level: "public",
            cacheControl: "no-cache, no-store, must-revalidate",
            expires: 0,
            pragma: "no-cache",
          });
          imgUrls[images[i]] = result;
        } catch (exp) {
          /*onError(
            new Error(
              t(
                "Oops, we could not load your profile picture. Give it another try",
              ),
            ),
          );*/
          throw exp;
        }
      }
      this.setState({
        ...this.state,
        imgUrls,
      });
    }
  };

  handleTextChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          ...this.state,
          editText: value || "",
        });
      }
    }
  };

  handleStartEdit = () => {
    const { commentData } = this.props;
    const { text } = commentData;

    this.setState({
      ...this.state,
      isEditing: true,
      editText: text,
    });
  };

  handleCancelEdit = () => {
    this.setState({
      ...this.state,
      isEditing: false,
    });
  };

  handleSaveEdit = () => {
    const { onEdit, commentData } = this.props;
    onEdit({
      id: commentData.comment_to,
      commentId: commentData.reply_to,
      replyId: commentData.id,
      text: this.state.editText,
    });
    this.setState({
      ...this.state,
      isEditing: false,
    });
  };

  render() {
    const {
      classes,
      t,
      commentData,
      i18n,
      onAddAllez,
      userSub,
      onReply,
      onDelete,
      isAdmin,
      addingAllezOn,
      isAuthenticated,
    } = this.props;
    const { imgUrls, isEditing, editText } = this.state;
    const {
      id,
      by,
      title,
      text,
      allez_count,
      comments_count,
      link,
      image: postImage,
      created_at,
      comment_to,
      reply_to,
      allez,
      crown,
    } = commentData;
    const {
      cognito_user_sub,
      cognito_user_name,
      nextRace,
      nextRaces,
      image,
      accumulatedDistance = 0,
      accumulatedElevation = 0,
    } = by || {};

    const { language } = i18n;

    let allRaces = [];
    if (nextRace) {
      const today = moment();
      const raceDateMoment = moment(nextRace.raceDate, "DD/MM/YYYY");
      const weeksToRace = raceDateMoment.diff(today, "day");
      if (weeksToRace >= 0) {
        allRaces.push(nextRace);
      }
    }
    if (nextRaces && nextRaces.length > 0) {
      nextRaces.forEach((element) => {
        const today = moment();
        const raceDateMoment = moment(element.raceDate, "DD/MM/YYYY");
        const weeksToRace = raceDateMoment.diff(today, "day");
        if (weeksToRace >= 0) {
          allRaces.push(element);
        }
      });
    }

    const level =
      accumulatedDistance || accumulatedElevation
        ? getLevel(accumulatedDistance, accumulatedElevation)
        : 1;

    return (
      <div className={classes.container}>
        <div className={classes.topBarContainer}>
          <RouterLink
            to={
              isAuthenticated
                ? `/trailhead/profile/${cognito_user_sub}`
                : `/trailhead`
            }
            className={classes.topBar}
          >
            <div className={classes.avatar}>
              <Avatar
                src={
                  image
                    ? imgUrls[image]
                    : `${process.env.PUBLIC_URL}/avatar-empty.png`
                }
              />
              {level ? (
                <img
                  src={`${process.env.PUBLIC_URL}/trl${level}-sm.svg`}
                  className={classes.levelMiniBadge}
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.usernameContainer}>
              <Typography className={classes.username} variant="body2">
                {cognito_user_name}
              </Typography>

              {allRaces.length > 0 && (
                <Typography className={classes.raceName} variant="body2">
                  {allRaces[0].raceName}
                </Typography>
              )}
            </div>
          </RouterLink>
          {(cognito_user_sub === userSub || isAdmin) && (
            <PostActionsMenu
              className={classes.actions}
              isMine={cognito_user_sub === userSub}
              type={"reply"}
              onDelete={() => onDelete("reply", commentData)}
              onEdit={this.handleStartEdit}
              isAdmin={isAdmin}
            />
          )}
        </div>
        <div className={classes.content}>
          <Typography className={classes.title} variant="body2">
            {title}
          </Typography>

          {isEditing ? (
            <TextField
              value={editText ? editText : ""}
              margin="normal"
              variant="outlined"
              placeholder={t("Comment placeholder")}
              fullWidth={true}
              multiline={true}
              inputProps={{ maxLength: 2200 }}
              onChange={this.handleTextChange}
            />
          ) : (
            <Typography
              className={classes.text}
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: linkifyStr(text, {
                  target: {
                    url: "_blank",
                  },
                }),
              }}
            ></Typography>
          )}

          {link ? (
            getYoutubeId(link) ? (
              <div className={classes.externalLink}>{getVideoIframe(link)}</div>
            ) : (
              <Link
                href={link}
                target="_blank"
                className={classes.externalLink}
              >
                <LinkPreview url={link} />
              </Link>
            )
          ) : (
            ""
          )}
          {postImage && (
            <img
              src={imgUrls[postImage]}
              alt=""
              className={classes.postImage}
            />
          )}
        </div>
        <div className={classes.metaBar}>
          <Typography className={classes.metaText} variant="body2">
            {`${moment(created_at)
              .locale(language)
              .fromNow()}`}
          </Typography>
        </div>

        {isEditing && (
          <div className={classes.editButtons}>
            <Button
              className={classes.cancelButton}
              onClick={this.handleCancelEdit}
              color="primary"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={this.handleSaveEdit}
              color="primary"
              disabled={!editText}
            >
              {t("Save")}
            </Button>
          </div>
        )}

        <div className={classes.bottomBar}>
          <Allez
            onAddAllez={() => onAddAllez(comment_to, reply_to, id)}
            full={allez.find((a) => a.cognito_user_sub === userSub)}
          />
          {allez_count > 0 && id !== addingAllezOn && (
            <Typography
              className={classes.count}
              variant="body2"
              dangerouslySetInnerHTML={{
                __html:
                  allez_count === 1
                    ? t("Allez by 1", { name: allez[0].cognito_user_name })
                    : allez_count === 2
                    ? t("Allez by 2", {
                        name: allez[0].cognito_user_name,
                        count: allez_count - 1,
                      })
                    : t("Allez by", {
                        name: allez[0].cognito_user_name,
                        count: allez_count - 1,
                      }),
              }}
            ></Typography>
          )}
          {id === addingAllezOn && (
            <CircularProgress
              variant="indeterminate"
              className={classes.loadingAllez}
            />
          )}
          {comments_count > 0 && (
            <img
              src={`${process.env.PUBLIC_URL}/icon-comment.svg`}
              alt=""
              className={classes.commentIcon}
            />
          )}
          <Typography
            className={classes.reply}
            variant="body2"
            onClick={onReply}
          >
            {t("Reply")}
          </Typography>
        </div>
      </div>
    );
  }
}

Reply.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string,
  commentData: PropTypes.object,
  tags: PropTypes.array,
};

export default withTranslation(["trailhead", "messages"])(
  withWidth()(withStyles(styles, { withTheme: true })(Reply)),
);
