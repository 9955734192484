import React, { useState, useEffect } from "react";
import { Typography, Box } from "@material-ui/core";
import { palette } from "../../../../theme/palette";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import { dateFormat } from "../../../../lib/date-helper";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import unitsHook from "../../../../hooks/unitsHook";
import { round } from "../../../../lib/units-helper";
dayjs.extend(weekOfYear);

export const ProfileActivityChart = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("profile");
  const [labels, setLabels] = useState([]);
  const [elevation, setElevation] = useState([]);
  const localUnits = unitsHook();
  moment.locale(language);
  const { currentPlan, plan } = useSelector((state) => state.userPlans);
  const { currentFilter, currentDate, toDate } = useSelector(
    (state) => state.profile,
  );
  const { activities: activitiesStrava } = useSelector((state) => state.strava);
  const { activities: activitiesSuunto } = useSelector((state) => state.suunto);
  const { activities: activitiesGarmin } = useSelector((state) => state.garmin);
  const { activities: activitiesVert } = useSelector(
    (state) => state.vertActivities,
  );
  const { activities: healthActivities } = useSelector(
    (state) => state.healthKit,
  );
  const { currentTraining } = currentPlan || {};
  const { durationInWeeks } = currentTraining || {};

  const activities = [
    ...(activitiesStrava || []),
    ...(activitiesGarmin || []),
    ...(activitiesSuunto || []),
    ...(activitiesVert || []),
    ...(healthActivities || []),
  ];

  const optionsLinear = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0)",
        displayColors: false,
        bodyColor: "#6D6F71",
        xAlign: "left",
        callbacks: {
          labelColor: function(context) {
            return {
              borderColor: "rgb(0, 0, 0)",
              backgroundColor: palette.darkBlue,
              borderWidth: 0,
              borderDash: [0, 0],
              borderRadius: 2,
            };
          },
          label: function(context) {
            return `${t(
              "profile.stats.chart.activityStats.elevation.tooltip",
            )}:${round(+context.parsed.y, 1)} ${
              localUnits === "miles"
                ? t("profile.unit.feet")
                : t("profile.unit.m")
            }`;
          },
          title: () => null,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          padding: 40,
          display: true,
          color: palette.lightGray,
          drawBorder: true,
          zeroLineColor: palette.lightGray,
        },
        ticks: {
          display: true,
          padding: 10,
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
          color: palette.lightGray,
          drawBorder: true,
          zeroLineColor: palette.lightGray,
        },
        ticks: {
          beginAtZero: true,
          display: true,
          padding: 10,
          callback: function(value, index, values) {
            return `${value} ${
              localUnits === "miles"
                ? `${t("profile.unit.feet")}`
                : `${t("profile.unit.m")}`
            }`;
          },
        },
      },
    },
    animation: {
      easing: "linear",
      duration: 1000,
    },
  };

  const data = {
    labels,
    datasets: [
      {
        backgroundColor: "#0B2B5C",
        borderColor: "#0B2B5C",
        hoverBorderColor: "#F93C6E",
        hoverBackgroundColor: "#F93C6E",
        borderWidth: 4,
        label: t("profile.stats.chart.activityStats.elevation.tooltip"),
        data: elevation,
        tension: 0.4,
      },
    ],
  };

  const calculateMonthFilter = () => {
    let monthLabels = [];
    let totalWeeks = 0;
    const elevations = [];

    const monthStartDate = moment(currentDate);
    const monthEndDate = moment(toDate);

    for (
      let dateIdx = moment(monthStartDate);
      dateIdx.isBefore(monthEndDate, "day");
      dateIdx.add(7, "days")
    ) {
      const labelDate = moment(dateIdx);

      monthLabels.push(
        `${labelDate.startOf("isoWeek").format("MMM")} ${labelDate
          .startOf("isoWeek")
          .date()} - ${labelDate
          .endOf("isoWeek")
          .format("MMM")} ${labelDate.endOf("isoWeek").date()}`,
      );
    }

    for (
      let dateIdx = moment(monthStartDate);
      dateIdx.isBefore(monthEndDate, "day");
      dateIdx.add(7, "days")
    ) {
      const labelDate = moment(dateIdx);

      monthLabels.push(
        `${labelDate.startOf("isoWeek").format("MMM")} ${labelDate
          .startOf("isoWeek")
          .date()} - ${labelDate
          .endOf("isoWeek")
          .format("MMM")} ${labelDate.endOf("isoWeek").date()}`,
      );
    }

    if (plan) {
      plan.forEach((dayOf) => {
        let day = dayOf[0] ? dayOf[0] : dayOf;
        if (day) {
          if (
            moment(day.plannedDate, dateFormat).isBetween(
              monthStartDate,
              monthEndDate,
              "[]",
            )
          ) {
            const plannedDay =
              moment(day.plannedDate, dateFormat).diff(
                monthStartDate.startOf("isoWeek"),
                "days",
              ) + 1;

            const plannedWeek = Math.floor(
              (plannedDay > 1 ? plannedDay - 1 : 0) / 7,
            );

            if (plannedWeek + 1 > totalWeeks) totalWeeks = plannedWeek + 1;

            if (activities) {
              const dayActivity = activities.find(
                (activity) =>
                  activity &&
                  moment(activity.start_date).isSame(day.plannedDate, "day"),
              );
              if (dayActivity)
                elevations.push({
                  elevation: dayActivity.total_elevation_gain,
                  date: dayActivity.start_date,
                });
            }
          }
        }
      });
    }

    const grouped = groupByWeek(elevations);

    const groupedArray = Object.entries(grouped);
    const accumulatedElevation = groupedArray.map(([key, value]) => {
      return value.reduce((acc, current) => acc + current.elevation, 0);
    });

    setLabels(monthLabels);
    setElevation(accumulatedElevation);
  };

  useEffect(() => {
    if (
      plan &&
      plan.length &&
      activities &&
      activities.length &&
      currentFilter === 0
    )
      calculateWeekFilter();

    if (
      plan &&
      plan.length &&
      activities &&
      activities.length &&
      currentFilter === 1
    )
      calculateMonthFilter();

    if (
      plan &&
      plan.length &&
      activities &&
      activities.length &&
      currentFilter === 2
    )
      calculateFullPlan();
  }, [
    plan,
    activitiesStrava,
    activitiesGarmin,
    activitiesSuunto,
    activitiesVert,
    healthActivities,
    currentFilter,
    currentDate,
    toDate,
  ]);

  const calculateWeekFilter = () => {
    const weekStartDate = moment(currentDate).startOf("isoWeek");
    const newDates = [];
    const elevationArray = [];
    if (!activities && !activities.length) return;

    for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
      const tempDate = moment(weekStartDate).add(dayIndex, "day");
      newDates[dayIndex] = tempDate;

      const dayActivity = activities.find(
        (activity) =>
          activity && moment(activity.start_date).isSame(tempDate, "day"),
      );

      dayActivity
        ? (elevationArray[dayIndex] = dayActivity.total_elevation_gain)
        : (elevationArray[dayIndex] = 0);
    }

    setElevation(elevationArray);
    buildLabels(newDates);
  };

  const buildLabels = (dates = []) => {
    const labels = dates.map((date) => `${date.format("ddd")}`.toUpperCase());
    setLabels(labels);
  };

  const groupByWeek = (array) => {
    return array.reduce((acc, obj) => {
      const week = dayjs(obj.date)
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      if (!acc[week]) {
        acc[week] = [];
      }
      acc[week].push(obj);
      return acc;
    }, {});
  };

  const calculateFullPlan = () => {
    let currentPlannedTotal = [];
    let currentDoneTotal = [];
    let currentOverTotal = [];
    const elevations = [];
    const labels = [];
    for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
      labels.push(t("WeekMobile", { week: weekIndex + 1, ns: "dashboard" }));
    }

    for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
      currentPlannedTotal.push(0);
      currentDoneTotal.push(0);
      currentOverTotal.push(0);
    }

    if (plan) {
      plan.forEach((dayOf) => {
        let day = dayOf[0] ? dayOf[0] : dayOf;
        if (day) {
          if (activities) {
            const dayActivity = activities.find(
              (activity) =>
                activity &&
                moment(activity.start_date).isSame(day.plannedDate, "day"),
            );
            if (dayActivity)
              elevations.push({
                elevation: dayActivity.total_elevation_gain,
                date: dayActivity.start_date,
              });
          }
        }
      });
    }

    const grouped = groupByWeek(elevations);

    const groupedArray = Object.entries(grouped);
    const accumulatedElevation = groupedArray.map(([key, value]) => {
      return value.reduce((acc, current) => acc + current.elevation, 0);
    });

    setLabels(labels);
    setElevation(accumulatedElevation);
  };

  return (
    <>
      <Typography
        variant="h4"
        style={{
          fontFamily: "Oswald",
          marginBottom: "8px",
          textTransform: "uppercase",
        }}
      >
        {t("profile.stats.chart.activityStats.title")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          backgroundColor: palette.white,
          borderRadius: "4px",
          padding: "24px 16px",
          boxSizing: "border-box",
          boxShadow: "0px 1px 15px 0px #12151B0D",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom="16px"
        >
          <Typography variant="h4">
            {t("profile.stats.chart.activityStats.elevation.title")}
          </Typography>
        </Box>
        <Line data={data} options={optionsLinear} />
      </Box>
    </>
  );
};
