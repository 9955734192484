import ActionTypes from "../constants/action-types";
import NotificationsService from "../services/notifications-service";

const notificationChangesUpdate = (notification) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.NOTIFICATION_UPDATE_CHANGES,
      payload: notification,
    });
  };
};

const notificationChangesClear = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.NOTIFICATION_CLEAR_CHANGES });
  };
};

const getNotifications = (loadUnreadOnly = true) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.NOTIFICATION_LOAD_PENDING });
    try {
      const result = await NotificationsService.getNotifications(
        loadUnreadOnly,
      );
      dispatch({
        type: ActionTypes.NOTIFICATION_LOAD_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.NOTIFICATION_LOAD_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const notificationPatch = (notification) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.NOTIFICATION_UPDATE_PENDING });
    try {
      const result = await NotificationsService.notificationPatch(notification);
      dispatch({
        type: ActionTypes.NOTIFICATION_UPDATE_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.NOTIFICATION_UPDATE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

export {
  notificationChangesUpdate,
  notificationChangesClear,
  getNotifications,
  notificationPatch,
};
