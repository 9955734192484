import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import AutoCompleteSelect from "../../common/auto-complete-select";

const styles = (theme) => ({
  container: {
    display: "flex",
    padding: theme.spacing.unit,
  },
  fab: {
    margin: theme.spacing.unit,
    backgroundColor: "indianred",
    color: theme.palette.common.white,
  },
  coachesContainer: {
    textAlign: "left",
    alignSelf: "center",
    flexGrow: 1,
  },
  assigning: {
    alignSelf: "center",
  },
});

class AthletePlanAssignCoach extends Component {
  render() {
    const {
      classes,
      coachesList = [],
      assignedCoachSub,
      onAssignCoach,
      assigningCoach,
      placeholder,
    } = this.props;
    const valueObject = assignedCoachSub
      ? coachesList.find((coach) => coach.value === assignedCoachSub)
      : null;

    return (
      <div className={classes.container}>
        {assigningCoach ? (
          <CircularProgress
            variant="indeterminate"
            color="secondary"
            size={30}
            className={classes.assigning}
          />
        ) : (
          ""
        )}
        <div className={classes.coachesContainer}>
          <AutoCompleteSelect
            suggestions={coachesList}
            placeholder={placeholder}
            isClearable={false}
            value={valueObject}
            onChange={onAssignCoach}
            disable={assigningCoach}
          />
        </div>
      </div>
    );
  }
}

AthletePlanAssignCoach.propTypes = {
  classes: PropTypes.object.isRequired,
  coachesList: PropTypes.array,
  assignedCoachSub: PropTypes.string,
  onAssignCoach: PropTypes.func.isRequired,
  assigningCoach: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default withStyles(styles)(AthletePlanAssignCoach);
