import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import { NoSsr } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";

import NoOptionsMessage from "./auto-complete-select/no-options-message";
import Control from "./auto-complete-select/control";
import Option from "./auto-complete-select/option";
import Placeholder from "./auto-complete-select/placeholder";
import SingleValue from "./auto-complete-select/single-value";
import ValueContainer from "./auto-complete-select/value-container";
import Menu from "./auto-complete-select/menu";
import { Chip, Input } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    background: "rgb(255 255 255 / 10%)",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 300,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  select: {
    "&:before": {
      borderBottom: "1px solid #fff !important",
    },
    "&:after": {
      borderBottom: `1px solid #fff !important"`,
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottom: `1px solid #fff !important"`,
    },
  },
  underline: {
    "&:before": {
      borderBottom: "1px solid #fff !important",
    },
    "&:after": {
      borderBottom: `1px solid #fff !important"`,
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottom: `1px solid #fff !important"`,
    },
  },
});

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class AutoCompleteMultiSelect extends Component {
  render() {
    const {
      classes,
      suggestions,
      placeholder,
      value,
      isClearable,
      disable,
      onKeyDown,
      onInputChange,
      loading,
      onChange,
    } = this.props;

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            classes={classes}
            options={suggestions}
            components={components}
            value={value}
            onChange={(ev) => onChange(ev)}
            placeholder={placeholder}
            isClearable={isClearable}
            isSearchable
            isDisabled={disable}
            onInputChange={onInputChange}
            onKeyDown={onKeyDown}
            isMulti={true}
            multiple={true}
            input={<Input id="select-multiple-chip" classes={classes} />}
            renderValue={(selected) => {
              return (
                <div className={classes.chips}>
                  {selected.map((element) => (
                    <Chip
                      key={element.value}
                      label={element.label}
                      className={classes.chip}
                    />
                  ))}
                </div>
              );
            }}
          />
          {loading && (
            <LinearProgress variant="indeterminate" color="primary" />
          )}
        </NoSsr>
      </div>
    );
  }
}

AutoCompleteMultiSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  isClearable: PropTypes.bool,
  disable: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onInputChange: PropTypes.func,
  loading: PropTypes.bool,
};

AutoCompleteMultiSelect.defaultProps = {
  placeholder: "Select...",
  isClearable: true,
};

export default withStyles(styles, { withTheme: true })(AutoCompleteMultiSelect);
