import ActionTypes from "../constants/action-types";
import SearchService from "../services/search-service";

const standarizeResponse = (data) => {
  const entries = Object.entries(data);

  let newArray = [];
  entries.forEach(([key, values]) => {
    values.forEach((item) => {
      newArray.push({
        ...item,
        type: key,
      });
    });
  });

  return newArray;
};

export const generalSearch = (search) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GENERAL_SEARCH_PENDING });
    try {
      const result = await SearchService.generalSearch(search);
      const data = standarizeResponse(result);
      dispatch({ type: ActionTypes.GENERAL_SEARCH_FULLFILED, payload: data });
    } catch (err) {
      dispatch({
        type: ActionTypes.GENERAL_SEARCH_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};
