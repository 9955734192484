import React from "react";
import ChangeProfilePicture from "../../../../components/common/v2/ChangeProfilePicture";
import NavbarSettings from "../../../../components/common/navbar-settings";
import { useTranslation } from "react-i18next";

const ProfilePictureView = () => {
  const { t } = useTranslation("settings");
  return (
    <NavbarSettings
      component={<ChangeProfilePicture />}
      text={t("settings.changeProfilePircture")}
    />
  );
};
export default ProfilePictureView;
