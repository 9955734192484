const ExternalLinksEn = {
  Blog: "https://vert.run/en/journal/",
  AboutVert: "https://vert.run/about/",
  Plans: "https://vert.run/trainining/",
  PrivacyPolicy: "https://vert.run/eula/",
  CoreRoutine: "https://vert.run/en/core-work-for-trail-running",
  StrengthRoutine: "https://vert.run/en/strength-for-trail-running",
  JumpsRoutine: "https://vert.run/en/jumps-for-trail-running/",
  ConditioningTraining:
    "https://vert.run/en/cross-training-for-trail-and-ultras/",
  Coaches: "https://vert.run/coaches/",
  iOSApp:
    "https://apps.apple.com/us/app/vert-trail-ultra-training/id1560376613",
  AndroidApp: "https://play.google.com/store/apps/details?id=run.vert.app",
};

export default ExternalLinksEn;
