import { API } from "aws-amplify";
import bugsnagClient from "../lib/bugsnag-client";

class ChallengesService {
  static API_NAME = "apichallenges";
  static GET_PATH = "/challenges";
  static GET_LEADERS_PATH = "/challenges/leaders";
  static JOIN_CHALLENGE_PATH = "/challenges/join";
  static UPDATE_YOUR_CHALLENGE_PATH = "/challenges/updateyourchallenges";
  static GET_USER_BADGES_PATH = "/challenges/byuser";

  static async getChallenges(cognito_user_sub) {
    try {
      const response = await API.get(
        this.API_NAME,
        `${this.GET_PATH}/${cognito_user_sub}`,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getChallengeLeaders(challengeId, cognito_user_sub) {
    try {
      const response = await API.get(
        this.API_NAME,
        `${this.GET_LEADERS_PATH}/${challengeId}/${cognito_user_sub}`,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getChallengesOld(date) {
    try {
      const response = await API.get(this.API_NAME, `${this.GET_PATH}/${date}`);
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async joinChallenge(cognito_user_sub, challengeData) {
    try {
      const myInit = {
        body: { cognito_user_sub, ...challengeData },
      };
      const response = await API.patch(
        this.API_NAME,
        this.JOIN_CHALLENGE_PATH,
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async updateJoinedChallenge(joinedChallengesData) {
    try {
      const myInit = {
        body: joinedChallengesData,
      };
      const response = await API.post(
        this.API_NAME,
        this.UPDATE_YOUR_CHALLENGE_PATH,
        myInit,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }

  static async getUserBadges(cognito_user_sub) {
    try {
      const response = await API.get(
        this.API_NAME,
        `${this.GET_USER_BADGES_PATH}/${cognito_user_sub}`,
      );
      const { success, result, error } = response;
      if (success) {
        return result;
      } else if (error) throw new Error(error);
    } catch (exp) {
      bugsnagClient.notify(exp);
      throw exp;
    }
  }
}

export default ChallengesService;
