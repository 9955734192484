import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useRaceList from "../../../hooks/useRaceList";
import { makeStyles } from "@material-ui/core";
import {
  Box,
  Typography,
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  Slider,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Radio,
  Avatar,
  Card,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ButtonDashed from "./ButtonDashed";
import ModalCustom from "./ModalCustom";
import FormRace from "./FormRace";
import { RaceCard } from "../../profile/Races/race-card";
import { dateFormat } from "../../../lib/date-helper";
import moment from "moment";
import { IconCalendarEvent, IconSend2 } from "@tabler/icons-react";

const useStyles = makeStyles({
  parentBox: {
    flex: 1,
    maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    overflow: "hidden",
    padding: "16px",
    gap: "12px",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  backButtonText: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#4780AB",
  },
  title: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    color: "#12151B",
    textTransform: "initial",
    lineHeight: "22.5px",
  },
  titleIntro: {
    fontFamily: "Oswald",
    fontWeight: 700,
    fontSize: "22px",
    color: "#12151B",
    textTransform: "uppercase",
    lineheight: "27.5px",
  },
  subtitle: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#12151B",
    lineHeight: "24px",
  },
  subtitleIntro: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    color: "#12151B",
    lineheight: "22.5px",
  },
  contentContainer: {
    flex: 1,
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    marginTop: "40px",
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: "#FAFAFA",
  },
  toggleButtonGroup: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    padding: "16px",
    justifyContent: "space-between",
    gap: "16px",
  },
  raceContainer: {
    overflowY: "auto",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
  sliderContainer: {
    padding: "16px",
  },
  singleButton: {
    backgroundColor: "#FEFFFF",
    borderRadius: "4px",
    padding: "16px",
    height: "56px",
    justifyContent: "flex-start",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
    "&:hover": {
      border: "none",
      color: "#4780AB",
    },
  },
  singleButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#12151B",
    textTransform: "initial",
  },
  singleButtonTextSelected: {
    color: "#FEFFFF",
  },
  toggleButton: {
    fontFamily: "Oswald",
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "40px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
    width: "45%",
    "&:hover": {
      backgroundColor: "inherit",
      border: "none",
      color: "#4780AB",
      boxShadow: "none",
    },
  },
  toggleButtonContained: {
    border: "none",
    color: "#FEFFFF",
    backgroundColor: "#4780AB",
    "&:hover": {
      backgroundColor: "#4780AB",
      border: "none",
      color: "#FEFFFF",
    },
  },
  toggleButtonOutlined: {
    border: "none",
    backgroundColor: "#FEFFFF",
    "&:hover": {
      backgroundColor: "#FEFFFF",
      border: "none",
    },
  },
  stepperContainer: {
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
  },
  stepper: {
    flex: 1,
    display: "flex",
    borderRadius: "4px",
  },
  stepperText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "14px",
    color: "#12151B",
    lineHeight: "21px",
    alignSelf: "center",
  },
  linearProgress: {
    flex: 1,
    borderRadius: "4px",
  },
  emailButton: {
    backgroundColor: "#FEFFFF",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  googleButton: {
    backgroundColor: "#FEFFFF",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  facebookButton: {
    backgroundColor: "#3C5A9A",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  appleButton: {
    backgroundColor: "#12151B",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  darkButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#FEFFFF",
  },
  lightButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#12151B",
  },
  emailButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#0B2B5C",
  },
  logInButton: {
    backgroundColor: "#0B2B5C",
    height: "47px",
    color: "#FFFFFF",
    borderRadius: "4px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  dividerText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 700,
  },
  termsText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "21px",
    fontSize: "14px",
    fontWeight: 400,
  },
  signUpText: {
    alignSelf: "center",
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 400,
  },
  termsButton: {
    color: "#4780AB",
    fontWeight: 700,
    cursor: "pointer",
  },
  loginButton: {
    color: "#F93C6E",
    fontWeight: 700,
    cursor: "pointer",
  },
  highlightText: {
    color: "#F93C6E",
  },
  inputForm: {
    backgroundColor: "#FEFFFF",
  },
  sliderTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sliderText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#6D6F71",
  },
  slider: {
    color: "#0B2B5C",
  },
});

const OnboardingGenerator = (
  stepsConfig,
  currentStep,
  handleChange,
  handleNext,
  values = {},
) => {
  const currentStepConfig = stepsConfig[currentStep - 1];
  const [emailCount, setEmailCount] = useState([1]);
  const [emails, setEmails] = useState({});

  const { t } = useTranslation("onboarding");
  const {
    races,
    openForm,
    handleSave,
    handleOpenForm,
    handleCloseForm,
  } = useRaceList();
  const classes = useStyles();

  const setEmailValue = (value, name) => {
    setEmails({ ...emails, [name]: value });
    handleChange(emails, "invitedList");
  };

  const handleInsertForm = () => {
    setEmailCount((prev) => [...prev, 1]);
  };

  const handleSaveRace = (race) => {
    handleNext();
    handleSave(race);
  };

  const executeFunction = (functionName) => {
    switch (functionName) {
      case "openForm":
        handleOpenForm();
        break;
      default:
        break;
    }
  };

  const highlightWord = (text, word) => {
    const index = text.lastIndexOf(word);
    if (index === -1) return text;
    return (
      <span>
        {text.slice(0, index)}
        <span className={classes.highlightText}>{word}</span>
        {text.slice(index + word.length)}
      </span>
    );
  };

  return (
    <>
      {currentStepConfig &&
        currentStepConfig.components &&
        currentStepConfig.components.map((component, index) => {
          switch (component.type) {
            case "textInput":
              return (
                <FormControl key={index} fullWidth hiddenLabel size="small">
                  <OutlinedInput
                    placeholder={t(component.placeholder)}
                    value={values[component.name]}
                    onChange={(e) =>
                      handleChange(e.target.value, component.name)
                    }
                    color="secondary"
                    className={classes.inputForm}
                    aria-label="naked"
                    startAdornment={
                      <InputAdornment position="start">
                        {component.icon && (
                          <component.icon.component
                            color={component.icon.color}
                          />
                        )}
                      </InputAdornment>
                    }
                  />
                </FormControl>
              );
            case "emailInput":
              return (
                <>
                  {emailCount.map((_, i) => (
                    <FormControl key={i} fullWidth hiddenLabel size="small">
                      <OutlinedInput
                        placeholder={t(component.placeholder)}
                        value={emails[`friend${i + 1}`]}
                        onChange={(e) =>
                          setEmailValue(e.target.value, `friend${i + 1}`)
                        }
                        color="secondary"
                        className={classes.inputForm}
                        aria-label="naked"
                        startAdornment={
                          <InputAdornment position="start">
                            {component.icon && (
                              <component.icon.component
                                color={component.icon.color}
                              />
                            )}
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  ))}
                  {emailCount.length < 5 && (
                    <ButtonDashed
                      onClick={handleInsertForm}
                      label={t(component.buttonValue)}
                      withAddIcon={true}
                    />
                  )}
                </>
              );
            case "dropdown":
              return (
                <FormControl key={index} fullWidth hiddenLabel size="small">
                  <Select
                    value={values[component.name]}
                    onChange={(e) =>
                      handleChange(e.target.value, component.name)
                    }
                    displayEmpty
                    input={
                      <OutlinedInput
                        className={classes.inputForm}
                        color="secondary"
                        style={{
                          color:
                            values[component.name] === "" ? "#b3b3b3" : "black",
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            {component.icon && (
                              <component.icon.component
                                color={component.icon.color}
                              />
                            )}
                          </InputAdornment>
                        }
                      />
                    }
                  >
                    <MenuItem disabled value="">
                      {t(component.placeholder)}
                    </MenuItem>
                    {component.options.map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {t(option.label)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            case "toggleButtonGroup":
              return (
                <Box className={classes.toggleButtonGroup}>
                  {component.options.map((option, i) => (
                    <Button
                      key={i}
                      variant={
                        values[component.name] === option.value
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => handleChange(option.value, component.name)}
                      className={[
                        classes.toggleButton,
                        values[component.name] === option.value
                          ? classes.toggleButtonContained
                          : classes.toggleButtonOutlined,
                      ]}
                    >
                      {t(option.label)}
                    </Button>
                  ))}
                </Box>
              );
            case "buttonGroup":
              return (
                <>
                  {component.options.map((option, i) => (
                    <Button
                      key={i}
                      startIcon={
                        option.icon && (
                          <option.icon
                            color={
                              values[component.name] === option.value
                                ? "#FEFFFF"
                                : option.color
                            }
                            stoke={1}
                          />
                        )
                      }
                      variant={
                        values[component.name] === option.value
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => handleChange(option.value, component.name)}
                      className={[
                        classes.singleButton,
                        values[component.name] === option.value
                          ? classes.toggleButtonContained
                          : classes.toggleButtonOutlined,
                      ]}
                    >
                      <Typography
                        className={[
                          classes.singleButtonText,
                          values[component.name] === option.value &&
                            classes.singleButtonTextSelected,
                        ]}
                      >
                        {t(option.label)}
                      </Typography>
                    </Button>
                  ))}
                </>
              );
            case "slider":
              return (
                <>
                  <Box className={classes.sliderTextContainer}>
                    <Typography className={classes.sliderText}>
                      {t(component.text)}
                    </Typography>
                    <Typography className={classes.sliderText}>
                      {values[component.name]}
                      {t(component.unit)}
                    </Typography>
                  </Box>
                  <Box className={classes.sliderContainer}>
                    <Slider
                      key={index}
                      min={component.min}
                      step={component.step}
                      max={component.max}
                      className={classes.slider}
                      value={values[component.name]}
                      onChange={(e, value) =>
                        handleChange(value, component.name)
                      }
                      valueLabelDisplay="auto"
                      aria-labelledby="continuous-slider"
                    />
                  </Box>
                </>
              );
            case "title":
              return (
                <Typography className={classes.title}>
                  {highlightWord(t(component.value), t(component.highlight))}
                </Typography>
              );
            case "titleIntro":
              return (
                <Typography className={classes.titleIntro}>
                  {highlightWord(t(component.value), t(component.highlight))}
                </Typography>
              );
            case "toggle":
              return (
                <FormControl>
                  <RadioGroup
                    value={values[component.name]}
                    onChange={(e) =>
                      handleChange(e.target.value, component.name)
                    }
                    name={component.name}
                  >
                    {component.options.map((option, i) => (
                      <FormControlLabel
                        key={i}
                        value={option.value}
                        control={
                          <Radio
                            style={{
                              color: "#D81B60",
                              "&.Mui-checked": {
                                color: "#D81B60",
                              },
                            }}
                          />
                        }
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
            case "subTitle":
              return (
                <Typography className={classes.subTitle}>
                  {highlightWord(t(component.value), t(component.highlight))}
                </Typography>
              );
            case "subTitleIntro":
              return (
                <Typography className={classes.subtitleIntro}>
                  {highlightWord(t(component.value), t(component.highlight))}
                </Typography>
              );
            case "date":
              return (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    fullWidth
                    name={component.name}
                    placeholder={t(component.placeholder)}
                    variant="outlined"
                    format="DD/MM/YYYY"
                    color="secondary"
                    className={classes.inputForm}
                    value={
                      values[component.name]
                        ? moment(values[component.name], dateFormat)
                        : null
                    }
                    autoOk
                    size="small"
                    inputVariant="outlined"
                    keyboard
                    onChange={(date) =>
                      handleChange(
                        date ? moment(date).format(dateFormat) : null,
                        component.name,
                      )
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconCalendarEvent color="#6D6F71" stoke={1} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              );
            case "dashedButton":
              return (
                <ButtonDashed
                  onClick={() => executeFunction(component.function)}
                  label={t(component.value)}
                  withAddIcon={true}
                />
              );
            case "raceInput":
              return (
                <>
                  <ModalCustom
                    fullScreen={false}
                    open={openForm}
                    onClose={handleCloseForm}
                    title={t("onboarding.signupQuestion.addRace")}
                  >
                    <FormRace
                      type={"add"}
                      race={null}
                      onCancel={handleCloseForm}
                      onSave={handleSaveRace}
                    />
                  </ModalCustom>
                  <Box className={classes.raceContainer}>
                    {races &&
                      races.length !== 0 &&
                      races.map((race, index) => (
                        <RaceCard
                          key={index}
                          enableActions={{
                            cardClick: false,
                            mountainIndex: false,
                            share: false,
                            menu: false,
                            edit: false,
                            delete: false,
                            link: false,
                          }}
                          race={race}
                        />
                      ))}
                  </Box>
                </>
              );
            case "chat":
              return (
                <Box
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflow: "hidden",
                  }}
                >
                  <Box style={{ flexDirection: "row", display: "flex" }}>
                    <Avatar
                      src={`${process.env.PUBLIC_URL}/onboarding-img/steveChat.webp`}
                    />
                    <Card
                      elevation={0}
                      style={{
                        backgroundColor: "#FFF2F6",
                        borderColor: "#FFE9F0",
                        borderRadius: "8px",
                        borderWidth: "2px",
                        margin: "10px",
                        padding: "10px",
                      }}
                    >
                      <Typography>{t(component.chatPlaceholder)}</Typography>
                    </Card>
                  </Box>
                  <FormControl fullWidth hiddenLabel size="small">
                    <OutlinedInput
                      placeholder={t(component.placeholder)}
                      value={values[component.name]}
                      onChange={(e) =>
                        handleChange(e.target.value, component.name)
                      }
                      color="secondary"
                      className={classes.inputForm}
                      aria-label="naked"
                      multiline
                      maxRows={10}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleNext}>
                            <IconSend2 color="#6D6F71" stroke={1} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              );
            default:
              return null;
          }
        })}
    </>
  );
};

export default OnboardingGenerator;
