import React from "react";
import {
  IconClockHour1,
  IconClockHour10,
  IconClockHour11,
  IconClockHour12,
  IconClockHour2,
  IconClockHour3,
  IconClockHour4,
  IconClockHour5,
  IconClockHour6,
  IconClockHour7,
  IconClockHour8,
  IconClockHour9,
} from "@tabler/icons-react";
const getTimeIcon = (time, size = "24px", color = "#6D6F71", stroke = 1.5) => {
  switch (time) {
    case "5min" || "2min":
      return <IconClockHour1 color={color} stroke={stroke} size={size} />;
    case "8min" || "10min":
      return <IconClockHour2 color={color} stroke={stroke} size={size} />;
    case "15min":
      return <IconClockHour3 color={color} stroke={stroke} size={size} />;
    case "20min":
      return <IconClockHour4 color={color} stroke={stroke} size={size} />;
    case "25min":
      return <IconClockHour5 color={color} stroke={stroke} size={size} />;
    case "30min":
      return <IconClockHour6 color={color} stroke={stroke} size={size} />;
    case "35min":
      return <IconClockHour7 color={color} stroke={stroke} size={size} />;
    case "40min":
      return <IconClockHour8 color={color} stroke={stroke} size={size} />;
    case "45min":
      return <IconClockHour9 color={color} stroke={stroke} size={size} />;
    case "50min":
      return <IconClockHour10 color={color} stroke={stroke} size={size} />;
    case "55min":
      return <IconClockHour11 color={color} stroke={stroke} size={size} />;
    default:
      return <IconClockHour12 color={color} stroke={stroke} size={size} />;
  }
};

export default getTimeIcon;
