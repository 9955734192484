import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button,
    LinearProgress
} from '@material-ui/core';

import FlexContainer from '../../common/flex-container';

const styles = theme => {
    return {
        container: {
            justifyContent: 'space-between'
        },
        editIcon: {
            cursor: 'pointer'
        },
        disabled: {
            opacity: 0.6
        },
        checkIcon: {
            cursor: 'pointer',
            color: theme.palette.success.main
        },
        cancelIcon: {
            cursor: 'pointer',
            color: theme.palette.warning.main
        },
        textEditor: {
            minWidth: 250
        }
    }
}

class EnhancedTableCellEditor extends Component {
    state = {
        editorValue: undefined,
        showEditIcon: false
    }

    handleOnChange = (e) => {
        if (e) {
            const { target } = e;
            if (target) {
                const { value } = target;
                this.setState({
                    ...this.state,
                    editorValue: value
                });
            }
        }
    }

    handleMouseOver = () => {
        const { id, inlineEditId } = this.props;
        const isInEdit = id && inlineEditId && id === inlineEditId;
        this.setState({
            ...this.state,
            showEditIcon: !isInEdit
        })
    }

    handleMouseLeave = () => {
        this.setState({
            ...this.state,
            showEditIcon: false
        })
    }

    handleClick = (e) => {
        const { onEditRequested, id } = this.props;
        if (onEditRequested) {
            this.preventPropagation(e);
            onEditRequested(id);
            this.setState({
                ...this.state,
                showEditIcon: false
            })
        }
    }

    handleCancel = (e) => {
        const { onCancel, inlineEditId, savingId, id } = this.props;
        const isSaving = id && savingId && inlineEditId && id === inlineEditId && inlineEditId === savingId;
        if (onCancel) {
            this.preventPropagation(e);
            if (!isSaving) {
                this.setState({
                    ...this.state,
                    editorValue: undefined
                });
                onCancel();
            }
        }
    }

    handleSave = (e) => {
        const { name, onSave, inlineEditId } = this.props;
        const { editorValue } = this.state;
        if (inlineEditId && onSave && typeof editorValue !== undefined) {
            this.preventPropagation(e);
            onSave(inlineEditId, name, editorValue);
        }
    }

    preventPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        const { editorValue, showEditIcon } = this.state;
        const { value, classes, id, inlineEditId, savingId } = this.props;

        const isInEdit = (id && inlineEditId && id === inlineEditId) ? true : false;
        const hasChanged = typeof editorValue !== typeof undefined;
        const isSaving = id && savingId && inlineEditId && id === inlineEditId && inlineEditId === savingId;

        return <FlexContainer
            className={classes.container}
            onMouseOver={this.handleMouseOver}
            onMouseLeave={this.handleMouseLeave}>
            <Fragment>
                <Dialog
                    onClick={this.preventPropagation}
                    onDoubleClick={this.preventPropagation}
                    open={isInEdit}
                    onClose={this.handleCancel}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Training Plan Title</DialogTitle>
                    <LinearProgress color="primary" variant={isSaving ? "indeterminate" : "determinate"} value={0} />
                    <DialogContent>
                        <DialogContentText>
                            Enter new title:
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="title"
                            name="title"
                            label="Title"
                            type="text"
                            fullWidth
                            className={classes.textEditor}
                            disabled={isSaving}
                            value={editorValue || value || ''}
                            onChange={this.handleOnChange} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancel} color="primary" disabled={isSaving}>
                            Cancel
                            </Button>
                        <Button onClick={this.handleSave} color="primary" disabled={!hasChanged || isSaving}>
                            Save
                            </Button>
                    </DialogActions>
                </Dialog>
                {value}
                <EditIcon
                    fontSize="small"
                    visibility={showEditIcon ? "" : "hidden"}
                    className={classes.editIcon}
                    onClick={this.handleClick} />
            </Fragment>
        </FlexContainer>
    }
}

EnhancedTableCellEditor.propTypes = {
    value: PropTypes.string || PropTypes.number
}

export default withStyles(styles, { withTheme: true })(EnhancedTableCellEditor);