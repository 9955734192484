import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import RouterLink from "../common/router-link";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 30,
  },
  menu: {
    color: theme.palette.common.white,
    marginTop: 10,
    marginBottom: 10,
  },
  white: {
    color: theme.palette.common.white,
    opacity: 0.6,
    textTransform: "uppercase",
  },

  signUpButton: {
    fontSize: 10,
    borderRadius: 10,
    padding: "5px 10px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Montserrat",
  },
});

const FooterSignIn = (props) => {
  const { classes } = props;
  const { t } = useTranslation("layout");

  return (
    <div className={classes.container}>
      <Typography variant="subtitle2" className={classes.white}>
        {t("GET STARTED")}
      </Typography>

      <RouterLink to="/auth/signup">
        <Button
          color="primary"
          variant="contained"
          className={classes.signUpButton}
          size="large"
        >
          {t("GET STARTED")}
        </Button>
      </RouterLink>

      <RouterLink to="/auth/signin">
        <Typography variant="caption" className={classes.menu}>
          {t("Log in")}
        </Typography>
      </RouterLink>
    </div>
  );
};

FooterSignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FooterSignIn);
