import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ActivityCard } from "../Activity/activity-card";
import { useSelector } from "react-redux";
import { palette } from "../../../theme/palette";
import { useDispatch } from "react-redux";
import { clearCurrentUserActivity } from "../../../actions/user-plans-action";
import { ActivityDetailModal } from "./activity-detail";
import SaveActivity from "../../../components/generals/new-activity-modal";
import dayjs from "dayjs";
import useUserInfo from "../../../hooks/useUserInfo";
import ButtonDashed from "../../common/v2/ButtonDashed";
import { useTranslation } from "react-i18next";
import ModalCustom from "../../common/v2/ModalCustom";
import RaceCardSkeleton from "../../common/v2/RaceCardSkeleton";
import useCreatePost from "../../../hooks/useCreatePost";
import CommunityActivityShareDialog from "../../common/v2/CommunityActivityShareDialog";
import CommunityPostPreviewDialog from "../../common/v2/CommunityPostPreviewDialog";
import useActivities from "../../../hooks/useActivities";
import { deleteVertActivity } from "../../../actions/vert-activity-action";

const options = [
  {
    label: "profile.activities.filter.mostRecent",
    value: 0,
  },
  {
    label: "profile.activities.filter.week",
    value: 1,
  },
  {
    label: "profile.activities.filter.month",
    value: 2,
  },
  {
    label: "profile.activities.filter.all",
    value: 3,
  },
];

export const ActivityList = () => {
  const formTypeLabel = {
    add: "profile.activities.form.add",
    edit: "profile.activities.form.edit",
  };
  const { t } = useTranslation("profile");
  const dispatch = useDispatch();
  const classes = useStyles();
  const { sub } = useUserInfo();
  const { handleActivityChange } = useCreatePost();
  const [activityClicked, setActivityClicked] = useState(false);
  const [postPreview, setPostPreview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentType, setCurrentType] = useState(null);
  const [selectValue, setSelectValue] = useState(0);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [formType, setFormType] = useState("add");
  const [titleForm, setTitleForm] = useState(formTypeLabel.add);
  const [activityToEdit, setActivityToEdit] = useState(null);
  const [openActiviyDetail, setOpenActivityDetail] = useState(false);
  const {
    activitiesVert,
    activitiesGarmin,
    activitiesCoros,
    activitiesStrava,
    healthActivities,
    activitiesSuunto,
    sortedActivities,
    handleGetActivities,
  } = useActivities();
  const [localActivities, setLocalActivities] = useState([]);
  const { deletedActivity } = useSelector((state) => state.vertActivities);

  const rangeFilter = (range) => {
    const localActivities = (sortedActivities || []).filter((activity) => {
      const date = activity.start_date_local
        ? activity.start_date_local
        : activity.start_date;

      const same = dayjs().isSame(date, range);
      if (same) return activity;
    });
    setLocalActivities(localActivities);
  };

  const handleFilter = (event) => {
    const {
      target: { value },
    } = event;

    setSelectValue(value);

    if (value === 0) return nextActivitiesFilter();
    if (value === 1) return rangeFilter("week");
    if (value === 2) return rangeFilter("month");
    setLocalActivities(sortedActivities);
  };

  const nextActivitiesFilter = () => {
    const recents = sortedActivities.slice(0, 5);
    setLocalActivities(recents);
    setLoading(false);
  };

  const handleDetailActivity = (event, activity) => {
    const { device: type } = activity;
    setOpenActivityDetail(true);
    setCurrentActivity(activity);
    setCurrentType(type);
  };

  const onCloseActivityDetail = () => {
    setOpenActivityDetail(false);
    setCurrentType(null);
    setCurrentActivity(null);
    dispatch(clearCurrentUserActivity());
  };

  const handleOpenForm = (type = "add") => {
    setOpenForm(true);
    setFormType(type);
    setTitleForm(formTypeLabel[type] ? formTypeLabel[type] : "");
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormType(null);
    setTitleForm(null);
    setActivityToEdit({});
  };

  const editActivity = (activity) => {
    handleOpenForm("edit");
    setActivityToEdit(activity);
  };

  const handleSelectActivity = (activity) => {
    handleActivityChange(activity);
    setActivityClicked(true);
  };

  const handleContinuePost = () => {
    setPostPreview(true);
  };

  const handleCloseActivity = () => {
    setActivityClicked(false);
  };

  const handleClosePreview = () => {
    setPostPreview(false);
    setActivityClicked(false);
  };

  const deleteActivity = (activity) => {
    if (!activity) return false;
    setLocalActivities(
      localActivities.filter(
        (localActivity) => localActivity.created_at !== activity.created_at,
      ),
    );
    dispatch(
      deleteVertActivity({
        cognito_user_sub: sub,
        created_at: activity.created_at,
      }),
    );
  };

  useEffect(() => {
    if (deletedActivity) handleGetActivities();
  }, [deletedActivity]);

  useEffect(() => {
    nextActivitiesFilter();
  }, [
    activitiesVert,
    activitiesCoros,
    activitiesGarmin,
    activitiesSuunto,
    activitiesStrava,
    healthActivities,
  ]);

  return (
    <Box sx={{ width: "100%" }}>
      <FormControl className={classes.box}>
        <Select
          color="secondary"
          value={selectValue}
          className={classes.select}
          fullWidth
          variant="outlined"
          disableUnderline
          classes={{ root: classes.root }}
          onChange={handleFilter}
          id="profile_drop_menu"
        >
          {options.map((item, index) => (
            <MenuItem
              value={item.value}
              id={`profile_drop_menu_${index + 1}`}
              key={index}
            >
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ButtonDashed
        onClick={() => handleOpenForm("add")}
        label={t("profile.activities.form.add")}
        withAddIcon={true}
      />

      <ModalCustom
        fullScreen={false}
        open={openForm}
        onClose={handleCloseForm}
        title={t(titleForm)}
      >
        <SaveActivity
          type={formType}
          activity={activityToEdit}
          onCancel={handleCloseForm}
        />
      </ModalCustom>

      {currentActivity && (
        <ActivityDetailModal
          type={currentType}
          open={openActiviyDetail}
          activity={currentActivity}
          onClose={onCloseActivityDetail}
          shareActivity={handleSelectActivity}
        />
      )}

      <CommunityActivityShareDialog
        handleOpenDialog={activityClicked}
        handleCloseDialog={handleCloseActivity}
        handleContinueDialog={handleContinuePost}
        isFromShare={true}
      />

      <CommunityPostPreviewDialog
        handleOpenDialog={postPreview}
        handleCloseDialog={handleClosePreview}
        isFromShare={true}
      />

      {loading || sortedActivities === null ? (
        <>
          <RaceCardSkeleton />
          <RaceCardSkeleton />
          <RaceCardSkeleton />
        </>
      ) : localActivities && localActivities.length > 0 ? (
        (localActivities || []).map((activity, index) => (
          <ActivityCard
            key={index}
            activity={activity}
            selectActivity={handleSelectActivity}
            handleDetailActivity={handleDetailActivity}
            handleEdit={editActivity}
            handleDelete={deleteActivity}
          />
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "auto",
            justifyContent: "center",
            alignItems: "center",
            padding: 30,
          }}
        >
          <Typography variant="h4">
            {t("profile.activities.emptyList")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  box: {
    display: "flex",
    padding: "24px 0px",
    boxSizing: "border-box",
    width: "100%",
  },
  select: {
    fontSize: "16px",
    padding: "1px !important",
    borderRadius: "4px",
    border: palette.lightGray,
    fontFamily: "Karla, sans-serif",
    fontWeight: 400,
    backgroundColor: "#FEFFFF",
  },
  root: {
    padding: "10px 12px",
  },
});
