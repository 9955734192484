import React, { Component, memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import "url-search-params-polyfill";
import {
  Typography,
  Button,
  Grid,
  Link,
  Dialog,
  DialogTitle,
  DialogActions,
  ThemeProvider,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import RouterLink from "./common/router-link";

import moment from "moment";
import withWidth from "@material-ui/core/withWidth";
import DashboardUser from "./dashboard/dashboard-user";
import AppBar from "./layout/app-bar";
import { dateFormat } from "../lib/date-helper";
import { getSubcategories } from "../actions/subcategory-action";
import { getSubcategoryTitle } from "../lib/subcategory-helper";
import WorkoutPlans from "../constants/plans";
import LocalNotificationsService from "../services/local-notifications-service";
import {
  updatePlanImage,
  updatePlan,
  deleteAccount,
  clearSuccessMessage,
  clearErrorMessage,
} from "../actions/user-plans-action";
import history from "../lib/history-helper";
import InAppPurchasesService from "../services/in-app-purchases-service";
import { Capacitor } from "@capacitor/core";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import { getPlanLevel } from "../lib/plan-helper";
import { checkSubscription } from "../lib/subscription-plan-helper";
import StravaConnect from "./common/stravaConnect";
import GarminConnect from "./common/garminConnect";
import SuuntoConnect from "./common/suuntoConnect";
import CorosConnect from "./common/corosConnect";
import HealthKitConnect from "./common/healthkitConnect";
import { theme } from "../theme";

const styles = (theme) => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FCFCFC",
    [theme.breakpoints.down("md")]: {
      minHeight: "calc(100vh - 50px)",
      backgroundColor: "#FFFFFF",
    },
  },
  error: {
    color: theme.palette.error.light,
  },
  success: {
    color: theme.palette.success.light,
  },
  strava: {
    background: "#ed4834",
    color: "white",
  },
  avatar: {
    width: 56,
    height: 56,
    alignSelf: "center",
  },
  middleAlign: {
    marginBottom: "auto",
    marginTop: "auto",
  },
  labelContainer: {
    textTransform: "uppercase",
  },
  step: {
    fontSize: 44,
  },
  connectorLine: {
    display: "none",
  },
  title: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 20,
    color: "#202020",
    margin: "10px 0",
  },
  text: {
    fontFamily: "Avenir",
    fontSize: 16,
    color: "#202020",
    margin: "10px 0",
  },
  labelText: {},
  valueText: {
    marginLeft: 5,
  },
  profileContainer: {
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  button: {
    marginTop: 20,
    boxShadow: "none",
    borderRadius: 50,
    marginBottom: 20,
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginLeft: 0,
    },
  },

  buttonConnect: {
    margin: "10px auto",
    boxShadow: "none",
    borderRadius: 50,
  },
  stepper: {
    padding: "0 24px",
  },
  subscriptionContainer: {
    background: "#FFFFFF",
    borderRadius: 10,
    padding: 25,
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginTop: 20,
    },
  },
  subscriptionContainerMobile: {
    background: "#FFFFFF",
    borderRadius: 10,
    padding: 16,
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginTop: 20,
    },
  },
  subsText: {
    fontFamily: "Avenir",
    fontWeight: "normal",
    fontSize: 12,
    textAlign: "center",
    color: "#3F3F3F",
    margin: "10px 0",
  },
  subsContainer: {
    margin: "25px",
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
  },

  connectContainer: {
    background: "#FFFFFF",
    borderRadius: 10,
    padding: 25,
    maxWidth: 300,
    marginBottom: 20,
    textAlign: "center",
  },

  connectTitle: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 20,
    color: "#202020",
    textAlign: "left",
  },

  connectText: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#202020",
    textAlign: "left",
  },

  connectFlexContainer: {
    display: "flex",
    alignItems: "start",
  },

  logo: {
    marginLeft: 40,
  },

  corosLogo: {
    marginLeft: 40,
    marginBottom: 5,
    width: "70px",
    height: "auto",
  },

  subsColumn: {
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },

  appsColumn: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  subText: {
    marginLeft: "5px",
  },
  howToCard: {
    borderRadius: "5px",
    padding: "12px",
    boxSizing: "border-box",
    border: "solid 1px #5F5F5F",
    background: "#FFF",
  },
  refreshBtnParent: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "12px",
  },
});

class UserProfile extends Component {
  state = {
    isOpenDeleteModal: false,
    isOpenDeletedModal: false,
    manageNotiModal: false,
    disableNotificationsButton: false,
    tab: 0,
  };

  handleOpenDeleteModal = () => {
    this.setState({
      isOpenDeleteModal: true,
    });
  };

  handleCloseDeleteModal = (deleted) => {
    if (deleted) {
      const { userPlans } = this.props;
      const { currentPlan } = userPlans;
      const { cognito_user_email } = currentPlan || "";
      this.props.dispatch(deleteAccount(cognito_user_email));
    }
    this.setState({
      isOpenDeleteModal: false,
      isOpenDeletedModal: deleted,
    });
  };

  handleCloseDeletedModal = () => {
    this.setState({
      isOpenDeletedModal: false,
    });
  };

  async componentDidMount() {
    const { currentPlan } = this.props.userPlans || {};

    if (currentPlan) {
      this.props.dispatch(
        updatePlan(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            noShowPlansFilter: true,
          },
          false,
        ),
      );
    }
    this.props.dispatch(getSubcategories());
  }

  handleImageFieldChange = (value, extension) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      updatePlanImage({
        cognito_user_sub: currentPlan.cognito_user_sub,
        image: value,
        imageExtension: extension && extension.toLowerCase(),
      }),
    );
  };

  handleActivateTrailhead = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    if (currentPlan) {
      this.props.dispatch(
        updatePlan(
          { cognito_user_sub: currentPlan.cognito_user_sub, trailhead: true },
          false,
          false,
        ),
      );
    }

    history.push(`/trailhead/profile/${currentPlan.cognito_user_sub}`);
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleOpenNotificationsModal = () => {
    this.setState({
      ...this.state,
      manageNotiModal: !this.state.manageNotiModal,
    });
  };

  async resetWorkoutNotifications(newWorkoutNotificationsTime = null) {
    const { userPlans, t, i18n } = this.props;
    const { currentPlan } = userPlans;

    const {
      currentTraining,
      showWorkoutNotifications,
      workoutNotificationsTime,
      cognito_user_sub,
    } = currentPlan || {};
    const { days } = currentTraining || {};

    const level = getPlanLevel(currentTraining);
    if (newWorkoutNotificationsTime || showWorkoutNotifications) {
      if (days) {
        await LocalNotificationsService.scheduleWorkoutNotifications(
          days,
          newWorkoutNotificationsTime
            ? newWorkoutNotificationsTime
            : workoutNotificationsTime,
          cognito_user_sub,
          level,
        );
      }
    }
  }

  capitalize = (word) => {
    if (!word) return "";
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  handleTimeSelect = async (item) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.setState({
      manageNotiModal: !this.state.manageNotiModal,
      disableNotificationsButton: true,
    });
    if (item && item.value) {
      const { value } = item;
      const { showWorkoutNotifications, workoutNotificationsTime } = value;
      this.props.dispatch(
        updatePlan(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            showWorkoutNotifications: showWorkoutNotifications,
            workoutNotificationsTime,
          },
          false,
          false,
        ),
      );

      await this.resetWorkoutNotifications(workoutNotificationsTime);
    } else {
      this.props.dispatch(
        updatePlan(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            showWorkoutNotifications: false,
          },
          false,
          false,
        ),
      );
    }
    this.setState({
      disableNotificationsButton: false,
    });
  };

  handleRefreshFull = async () => {
    await InAppPurchasesService.refreshFull(this.props.dispatch);
  };

  render() {
    const {
      classes,
      strava,
      userPlans,
      t,
      auth,
      subcategories,
      subscriptions,
      i18n,
      width,
    } = this.props;

    const {
      updating: userPlanIsUpdaing,
      currentPlan,
      loading: userPlanIsLoading,
      successMessage,
    } = userPlans;

    const { manageNotiModal, disableNotificationsButton } = this.state;
    const { initializing, errorMessage } = strava;

    const isNative = Capacitor.isNativePlatform();

    const {
      image,
      sex,
      currentLocation,
      currentGeoLocation,
      currentTraining,
      updatingImage,
      trailhead,
    } = currentPlan || "";
    const { nextRace, nextRaces } = currentPlan || {};
    const { latestExpired, loadingLatest, latest } = subscriptions;
    const { latestSubscriptionFinalizedTime } = currentPlan || {};

    let allRaces = [];
    if (nextRace) {
      const today = moment();
      const raceDateMoment = moment(nextRace.raceDate, "DD/MM/YYYY");
      const weeksToRace = raceDateMoment.diff(today, "day");
      if (weeksToRace >= 0) {
        allRaces.push(nextRace);
      }
    }
    if (nextRaces && nextRaces.length > 0) {
      nextRaces.forEach((element) => {
        const today = moment();
        const raceDateMoment = moment(element.raceDate, "DD/MM/YYYY");
        const weeksToRace = raceDateMoment.diff(today, "day");
        if (weeksToRace >= 0) {
          allRaces.push(element);
        }
      });
    }

    allRaces.sort((a, b) => {
      let aMoment;
      if (a && a.raceDate) {
        aMoment = moment(a.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      let bMoment;
      if (b && b.raceDate) {
        bMoment = moment(b.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      return aMoment.diff(bMoment, "days");
    });

    let goal = 0;
    let runningStreak = 0;
    const { durationInWeeks, startingDate } = currentTraining || {};

    let { selectedPlan } = currentTraining || {};

    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    let showCompleteSubscriptionButton = false;

    if (selectedPlan !== WorkoutPlans.Items.FREE) {
      showCompleteSubscriptionButton = checkSubscription(
        subscriptions,
        latestSubscriptionFinalizedTime,
      );
    }

    const today = moment();
    const startingDateMoment = moment(startingDate, dateFormat);
    const currentDay = today.diff(startingDateMoment, "days") + 1;
    const currentWeek = Math.min(
      Math.ceil((currentDay > 0 ? currentDay : 0) / 7),
      durationInWeeks,
    );

    let trialDaysLeft = 0;

    let isMobileSubs = null;
    if (latest) {
      const { trial_end, plan_id } = latest;
      const endDate = moment.unix(trial_end);
      trialDaysLeft = endDate.diff(today, "days") + 1;

      if (plan_id.includes("apple")) {
        isMobileSubs = "apple";
      }
      if (plan_id.includes("google")) {
        isMobileSubs = "google";
      }
    }

    if (
      currentTraining &&
      currentTraining.days &&
      currentTraining.days.filter((day) => day).length > 0
    ) {
      //running streak
      goal = currentTraining.days
        .filter((day) => day)
        .map((day) => day.length)
        .reduce((sum, a) => sum + a);
      runningStreak = currentTraining.days
        .filter((day) => day)
        .map((day) => day.filter((workout) => workout.isDone).length)
        .reduce((sum, a) => sum + a);
    }

    const { currentUser } = auth;
    let currentUserFullName = "";
    if (currentUser) {
      const { attributes } = currentUser;
      if (attributes) {
        const { name, family_name, email } = attributes;
        currentUserFullName =
          name || family_name ? `${name} ${family_name || ""}`.trim() : email;
      }
    }

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    let planName;
    if (selectedPlan && selectedPlan !== "premium") {
      const subcategory =
        currentPlan &&
        currentPlan.currentTraining &&
        currentPlan.currentTraining.subcategory
          ? currentPlan.currentTraining.subcategory
          : +currentPlan.subcategory;
      planName =
        subcategories &&
        subcategories.data &&
        subcategories.data.length &&
        currentPlan.subcategory
          ? getSubcategoryTitle(
              subcategories.data,
              subcategory,
              languageDetector,
            )
          : selectedPlan === "free"
          ? "Free"
          : "Explore";
    }
    if (selectedPlan === "premium") {
      planName = "Premium";
    }

    const showStravaButton = currentPlan && !currentPlan.strava_athlete_id;

    const isInMobileViewSm = width === "sm" || width === "xs";

    const { isOpenDeleteModal, isOpenDeletedModal } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.container}>
          {/* <WorkoutNotificationDialog
          open={manageNotiModal}
          onCancel={this.handleOpenNotificationsModal}
          onTimeSelect={this.handleTimeSelect}
          disableButton
        /> */}
          <AppBar
            isOnDashboard={true}
            isNotOnChoosingPlanPage={true}
            showLanguageMenu={false}
            showChoosePlanHeader={false}
            whiteLogoOnMobileView={false}
            isOnSubscriptionPage={false}
            isOnProfile={true}
          />
          <Grid container spacing={16}>
            <DashboardUser
              xs={12}
              sm={12}
              md={4}
              lg={3}
              image={image}
              userFullName={currentUserFullName}
              planName={planName}
              currentLocation={currentLocation}
              currentGeoLocation={currentGeoLocation}
              sex={sex}
              updatingImage={updatingImage}
              trialDaysLeft={trialDaysLeft}
              durationInWeeks={+durationInWeeks}
              currentWeek={currentWeek}
              onError={this.handleError}
              onImageChange={this.handleImageFieldChange}
              onFieldChanges={this.handleFieldChanges}
              maxSize={2048000}
              runningStreak={runningStreak}
              goal={goal}
              hasSubscription={!!latest}
              showStravaButton={showStravaButton}
              showCompleteSubscriptionButton={showCompleteSubscriptionButton}
              selectedPlan={selectedPlan}
              currentTraining={currentTraining}
              nextRace={allRaces[0]}
              onActivateTrailhead={this.handleActivateTrailhead}
              trailheadActive={trailhead}
              isProfile={true}
            />
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={9}
              className={classes.trainingPane}
            >
              {!isInMobileViewSm ? (
                <RouterLink to="/dashboard">
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                  >
                    {t("Back to Dashboard")}
                  </Button>
                </RouterLink>
              ) : (
                ""
              )}

              <Grid container className={classes.profileContainer}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classes.subsColumn}
                >
                  {/* {isNative && (
                  <div className={classes.connectContainer}>
                    <div className={classes.connectFlexContainer}>
                      <Typography variant="h5" className={classes.title}>
                        {t("Notifications")}
                      </Typography>
                    </div>

                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.buttonConnect}
                      onClick={this.handleOpenNotificationsModal}
                      disabled={disableNotificationsButton}
                    >
                      {t("Manage Notifications")}
                    </Button>
                  </div>
                )} */}

                  {!Capacitor.isNativePlatform() && (
                    <div className={classes.subscriptionContainer}>
                      <Typography variant="h5" className={classes.title}>
                        {t("Subscription Details")}
                      </Typography>
                      {latest && latest.status ? (
                        <div className={classes.text}>
                          <Typography
                            variant="body1"
                            className={classes.labelText}
                          >
                            <b>{t("Status")}:</b>
                            <span
                              style={{
                                color: latest.status
                                  ? latest.status.toLowerCase() === "active"
                                    ? "green"
                                    : latest.status.toLowerCase() ===
                                        "expired" ||
                                      latest.status.toLowerCase() ===
                                        "cancelled"
                                    ? "red"
                                    : latest.status.toLowerCase() ===
                                      "non_renewing"
                                    ? "blue"
                                    : latest.status.toLowerCase() === "active"
                                    ? latest.status.toLowerCase() === "in_trial"
                                    : "#FFB300"
                                  : "",
                              }}
                            >{` ${latest.status}`}</span>
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                      {latest && latest.trial_end && trialDaysLeft > 0 ? (
                        <div className={classes.text}>
                          <Typography
                            variant="body1"
                            className={classes.labelText}
                          >
                            <b>{t("Current Trial End Date")}:</b>
                            {` ${moment
                              .unix(latest.trial_end)
                              .format(dateFormat)}`}
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                      {latest && latest.next_billing_at ? (
                        <div className={classes.text}>
                          <Typography
                            variant="body1"
                            className={classes.labelText}
                          >
                            <b>{t("Next Billing Date")}:</b>
                            {` ${moment
                              .unix(latest.next_billing_at)
                              .format(dateFormat)}`}
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                      {latest &&
                      latest.currency_code &&
                      latest.plan_unit_price ? (
                        <div className={classes.text}>
                          <Typography
                            variant="body1"
                            className={classes.labelText}
                          >
                            <b>{t("Next Billing Amount")}:</b>
                            {` ${
                              latest.currency_code
                            } ${latest.plan_unit_price / 100}`}
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                      {latest &&
                      latest.event &&
                      latest.event.event_type &&
                      latest.event.occurred_at ? (
                        <div className={classes.text}>
                          <Typography
                            variant="body1"
                            className={classes.labelText}
                          >
                            <b>{t("Latest Event")}:</b>
                            {` ${latest.event.event_type} ${t(
                              "at",
                            )} ${moment
                              .unix(latest.event.occurred_at)
                              .format(dateFormat)}`}
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                      {planName ? (
                        <div className={classes.text}>
                          <Typography
                            variant="body1"
                            className={classes.labelText}
                          >
                            <b>{t("Current Training Plan")}:</b>
                            {` ${planName}`}
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  {Capacitor.isNativePlatform() && (
                    <div className={classes.subscriptionContainerMobile}>
                      <Typography variant="h5" className={classes.title}>
                        {t("Subscription Details")}
                      </Typography>
                      {latest && (
                        <ul>
                          <li>
                            {latest && latest.status && (
                              <div className={classes.text}>
                                <Typography
                                  variant="body1"
                                  className={classes.labelText}
                                >
                                  <b>{t("Status")}:</b>
                                  <span
                                    style={{
                                      color: latest.status
                                        ? latest.status.toLowerCase() ===
                                          "active"
                                          ? "green"
                                          : latest.status.toLowerCase() ===
                                              "expired" ||
                                            latest.status.toLowerCase() ===
                                              "cancelled"
                                          ? "red"
                                          : latest.status.toLowerCase() ===
                                            "non_renewing"
                                          ? "blue"
                                          : latest.status.toLowerCase() ===
                                            "active"
                                          ? latest.status.toLowerCase() ===
                                            "in_trial"
                                          : "#FFB300"
                                        : "",
                                    }}
                                  >{` ${this.capitalize(latest.status)}`}</span>
                                </Typography>
                              </div>
                            )}
                          </li>

                          <li>
                            <div className={classes.text}>
                              <Typography
                                variant="body1"
                                className={classes.labelText}
                              >
                                <b>{t("Subscription Source")}:</b>
                                <span className={classes.subText}>
                                  {latest && latest.store
                                    ? latest.store
                                    : latest && latest.channel
                                    ? latest.channel
                                    : ""}
                                </span>
                              </Typography>
                            </div>
                          </li>

                          <li>
                            {planName && (
                              <div className={classes.text}>
                                <Typography
                                  variant="body1"
                                  className={classes.labelText}
                                >
                                  <b>{t("Current Training Plan")}:</b>
                                  {` ${planName}`}
                                </Typography>
                              </div>
                            )}
                          </li>
                        </ul>
                      )}
                    </div>
                  )}

                  {latest && latest.channel && latest.channel === "web" && (
                    <div className={classes.subsContainer}>
                      <Link
                        href="https://vert.chargebeeportal.com/portal/v2/login?forward=portal_main"
                        className={classes.link}
                      >
                        <Button variant="outlined">
                          {t("Manage your Subscription")}
                        </Button>
                      </Link>

                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        className={classes.subsText}
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "You can Pause your subscription for up to 60 days without charges",
                          ),
                        }}
                      ></Typography>

                      <Typography variant="body1" className={classes.subsText}>
                        {t("Cancel or reactivate your subscription")}
                      </Typography>
                    </div>
                  )}

                  {Capacitor.getPlatform() === "ios" &&
                    latest &&
                    latest.store &&
                    latest.store === "APP_STORE" && (
                      <div className={classes.refreshBtnParent}>
                        <Button
                          variant="outlined"
                          onClick={this.handleRefreshFull}
                        >
                          {t("Refresh Purchases")}
                        </Button>
                      </div>
                    )}

                  {isNative && isMobileSubs && (
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      className={classes.subsText}
                      dangerouslySetInnerHTML={{
                        __html: t(
                          isMobileSubs === "apple"
                            ? "You find your subscription details on the App Store"
                            : "You find your subscription details on the Play Store",
                        ),
                      }}
                    ></Typography>
                  )}

                  {!latest && (
                    <div className={classes.subsContainer}>
                      <RouterLink
                        to="/subscription/explore"
                        className={classes.link}
                      >
                        <Button variant="outlined">
                          {t("Upgrade your Subscription")}
                        </Button>
                      </RouterLink>
                    </div>
                  )}

                  {Capacitor.getPlatform() === "ios" &&
                    latest &&
                    latest.store &&
                    latest.store === "APP_STORE" && (
                      <div className={classes.subsContainer}>
                        <Link
                          href="https://support.apple.com/en-us/HT202039"
                          className={classes.link}
                        >
                          <Button
                            variant="outlined"
                            style={{ textTransform: "none" }}
                          >
                            {t(
                              "Click here to learn how to manage your Apple App Store Subscription",
                            )}
                          </Button>
                        </Link>
                      </div>
                    )}

                  {Capacitor.getPlatform() === "android" &&
                    latest &&
                    latest.store &&
                    latest.store === "PLAY_STORE" && (
                      <div className={classes.subsContainer}>
                        <Link
                          href="https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid"
                          className={classes.link}
                        >
                          <Button
                            variant="outlined"
                            style={{ textTransform: "none" }}
                          >
                            {t(
                              "Click here to learn how to manage your Android Play Store Subscription",
                            )}
                          </Button>
                        </Link>
                      </div>
                    )}

                  {latest && isInMobileViewSm && (
                    <div className={classes.howToCard}>
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "To request an account deletion, please send us an email to team@vert.run with this information:",
                          ),
                        }}
                      ></Typography>
                      <ul>
                        <li>
                          <Typography variant="body2">
                            {t("Your email address.")}
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body2">
                            {t("Reason for account deletion.")}
                          </Typography>
                        </li>
                      </ul>
                      <Typography variant="body2">
                        {t(
                          "And our team will take care of deleting your account as fast as possible.",
                        )}
                      </Typography>
                    </div>
                  )}

                  {Capacitor.getPlatform() === "ios" ? (
                    <div className={classes.subsContainer}>
                      <Button
                        variant="outlined"
                        onClick={this.handleOpenDeleteModal}
                      >
                        {t("Delete My Account")}
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classes.appsColumn}
                >
                  <StravaConnect />
                  <GarminConnect />
                  <SuuntoConnect />
                  <CorosConnect />
                  <HealthKitConnect />
                </Grid>
              </Grid>

              {isInMobileViewSm ? (
                <div style={{ textAlign: "center" }}>
                  <RouterLink to="/dashboard">
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.button}
                    >
                      {t("Back to Dashboard")}
                    </Button>
                  </RouterLink>
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <SnackBarMessageDisplay
            onHideMessage={this.handleHideMessage}
            errorMessage={errorMessage}
            successMessage={successMessage}
          />

          <Dialog
            open={isOpenDeleteModal}
            onClose={() => this.handleCloseDeleteModal(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {t("Are you sure, you want to delete your account?")}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => this.handleCloseDeleteModal(false)}
                color="primary"
              >
                {t("No")}
              </Button>
              <Button
                onClick={() => this.handleCloseDeleteModal(true)}
                color="primary"
              >
                {t("Yes")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={isOpenDeletedModal}
            onClose={() => this.handleCloseDeletedModal()}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {t("Your account will be deleted in the next 48 hours")}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => this.handleCloseDeletedModal()}
                color="primary"
              >
                {t("Close")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </ThemeProvider>
    );
  }
}

export default connect((store) => {
  return {
    strava: store.strava,
    userPlans: store.userPlans,
    auth: store.auth,
    subscriptions: store.subscriptions,
    subcategories: store.subcategories,
  };
})(
  withTranslation("profile")(
    withWidth()(withStyles(styles, { withTheme: true })(memo(UserProfile))),
  ),
);
