import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";

import { Routes } from "../../lib/routing-middleware";
import history from "../../lib/history-helper";
import EnhancedTable from "../common/enhanced-table";
import TrainingFields from "../../constants/training-fields";
import TrainingGridSwapIcon from "./training-grid/training-grid-swap-icon";

const styles = {
  container: {
    width: "100%",
    color: "gray",
    border: "1px solid lightgray",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 70,
  },
};

class TrainingGrid extends Component {
  handleUpdateRequested = (row) => {
    if (row) {
      const { id, training_order } = row;
      history.push(Routes.TrainingEditor(id, training_order));
    }
  };
  handleDoubleClick = (row) => {
    const { onSelectByDoubleClick, updatable } = this.props;
    if (row) {
      if (updatable) {
        const { id, training_order } = row;
        history.push(Routes.TrainingEditor(id, training_order));
      } else if (onSelectByDoubleClick) {
        onSelectByDoubleClick(row);
      }
    }
  };

  render() {
    const {
      classes,
      training,
      onSearchParamChanged,
      onSelectedChanged,
      onCellEditSaveRequested,
      onSelectInlineEditCell,
      updatable,
      selectable,
      sub,
      isExternalCoach,
    } = this.props;
    const {
      loading,
      hasBeenSearched,
      searchResult,
      deleting,
      saving,
      inlineSavingId,
      inlineEditCellId,
      currentFilter,
    } = training;
    const { data: dataFull, swappable, swapIdRoute } = searchResult;
    const headers = [
      {
        id: "title",
        numeric: false,
        disablePadding: true,
        label: "Title",
        orderByOverride: "title_lower",
        bolder: true,
        inlineEditor: true,
        clickable: true,
      },
      {
        id: "training_order",
        numeric: true,
        disablePadding: true,
        label: "Priority",
      },
      {
        id: "selectedPlan",
        numeric: false,
        disablePadding: true,
        label: "Plan Type",
      },
      {
        id: "durationInWeeks",
        numeric: true,
        disablePadding: true,
        label: "Duration",
      },
      {
        id: "distance",
        numeric: true,
        disablePadding: true,
        label: "Distance",
      },
      { id: "surface", numeric: false, disablePadding: true, label: "Surface" },
      {
        id: "trainPerWeek",
        numeric: false,
        disablePadding: true,
        label: "Days",
      },
      {
        id: "raceCount",
        numeric: false,
        disablePadding: true,
        label: "Levels",
      },
      {
        id: "skillToImprove",
        numeric: false,
        disablePadding: true,
        label: "Skill",
      },
      {
        id: "athlete_cognito_user_full_name",
        numeric: false,
        disablePadding: true,
        label: "Athlete",
      },
    ];

    const { justMine } = currentFilter;
    let data = dataFull;
    if (justMine && sub) {
      data = dataFull.filter((w) => w.created_by_cognito_user_sub === sub);
    }

    return loading ? (
      <LinearProgress variant="indeterminate" color="primary" />
    ) : hasBeenSearched ? (
      data.length > 0 ? (
        <EnhancedTable
          title="Trainings"
          headers={headers}
          updatable={updatable}
          onUpdateRequested={this.handleUpdateRequested}
          onDoubleClick={this.handleDoubleClick}
          deleting={deleting}
          deleteRoute={Routes.TrainingDeletePath}
          selectable={selectable}
          onChangePage={onSearchParamChanged}
          onChangeRowsPerPage={onSearchParamChanged}
          onRequestSort={onSearchParamChanged}
          onSelectedChanged={onSelectedChanged}
          extraActions={
            swappable
              ? [
                  <TrainingGridSwapIcon
                    key={1}
                    saving={saving}
                    swapIdRoute={swapIdRoute}
                  />,
                ]
              : undefined
          }
          {...searchResult}
          data={data}
          keyFields={[TrainingFields.id, "training_order"]}
          onCellEditSaveRequested={onCellEditSaveRequested}
          inlineSavingId={inlineSavingId}
          inlineEditCellId={inlineEditCellId}
          onSelectInlineEditCell={onSelectInlineEditCell}
          duplicateRoute={Routes.TrainingCreate}
          isExternalCoach={isExternalCoach}
          sub={sub}
        />
      ) : (
        <div className={classes.container}>
          No trainings found based on your current search criteria
        </div>
      )
    ) : (
      <div className={classes.container}>
        For viewing trainings, use the filters above to search
      </div>
    );
  }
}

TrainingGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearchParamChanged: PropTypes.func,
  onSelectedChanged: PropTypes.func,
  updatable: PropTypes.bool,
  selectable: PropTypes.bool,
  onSelectByDoubleClick: PropTypes.func,
};

TrainingGrid.defaultProps = {
  updatable: true,
  selectable: true,
};

export default withStyles(styles)(TrainingGrid);
