import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import RouterLink from "../common/router-link";
import externalLinksEn from "../../constants/external-links-en";
import externalLinksEs from "../../constants/external-links-es";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 30,
  },
  menu: {
    color: theme.palette.common.white,
  },
  externalLink: {
    textDecoration: "none !important",
    color: "inherit",
    marginTop: 5,
    marginBottom: 5,
    "&:hover": {
      opacity: 0.9,
    },
  },
  white: {
    color: theme.palette.common.white,
    opacity: 0.6,
    textTransform: "uppercase",
  },
});

const FooterMenus = (props) => {
  const { classes } = props;
  const { t, i18n } = useTranslation("layout");
  const { language } = i18n;
  const isEnglishLng = language === "en";

  return (
    <div className={classes.container}>
      <Typography variant="subtitle2" className={classes.white}>
        {t("Menu")}
      </Typography>
      <Link
        className={classes.externalLink}
        href={isEnglishLng ? externalLinksEn.Blog : externalLinksEs.Blog}
        target="_blank"
      >
        <Typography variant="caption" className={classes.menu}>
          {t("Blog")}
        </Typography>
      </Link>
      <Link
        className={classes.externalLink}
        href={
          isEnglishLng ? externalLinksEn.AboutVert : externalLinksEs.AboutVert
        }
        target="_blank"
      >
        <Typography variant="caption" className={classes.menu}>
          {t("What is Vert")}?
        </Typography>
      </Link>
      <RouterLink to="/chooseplan" className={classes.externalLink}>
        <Typography variant="caption" className={classes.menu}>
          {t("Training plans")}
        </Typography>
      </RouterLink>
      <RouterLink to="/explore/tag/Workouts" className={classes.externalLink}>
        <Typography variant="caption" className={classes.menu}>
          {t("Conditioning training")}
        </Typography>
      </RouterLink>
      <Link
        className={classes.externalLink}
        href={
          isEnglishLng
            ? externalLinksEn.PrivacyPolicy
            : externalLinksEs.PrivacyPolicy
        }
        target="_blank"
      >
        <Typography variant="caption" className={classes.menu}>
          {t("Privacy Policy")}
        </Typography>
      </Link>
    </div>
  );
};

FooterMenus.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FooterMenus);
