import { useState } from "react";

const useRaceFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validate = (state) => {
    let isValid = true;
    const newErrors = {};

    if (!state.raceName.trim()) {
      newErrors.raceName = "profile.races.form.valid.nameIsRequired";
      isValid = false;
    } else if (state.raceName.length > 255) {
      newErrors.raceName = "profile.races.form.valid.nameLength";
      isValid = false;
    }

    if (!state.raceDistance.trim() || isNaN(state.raceDistance)) {
      newErrors.raceDistance = "profile.races.form.valid.distanceIsNumber";
      isValid = false;
    }

    if (!state.raceElevation.trim() || isNaN(state.raceElevation)) {
      newErrors.raceElevation = "profile.races.form.valid.elevationIsNumber";
      isValid = false;
    }

    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!state.raceDate || !dateRegex.test(state.raceDate)) {
      newErrors.raceDate = "profile.races.form.valid.dateFormat";
      isValid = false;
    }

    if (state.link.trim() && !isValidUrl(state.link.trim())) {
      newErrors.link = "profile.races.form.valid.linkFormat";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const isValidUrl = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  return { errors, validate };
};

export default useRaceFormValidation;
