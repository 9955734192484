import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LinearProgress, FormControlLabel, Switch } from "@material-ui/core";

import {
  generalSearchWorkouts,
  cleanSearch,
} from "../../actions/workout-action";
import AthleteTrainingBlock from "./athlete-training-block-search/athlete-training-block";
import TrainingFilter from "../trainings/training-filter";
import TrainingFilterChips from "../trainings/training-filter-chips";
import {
  changeSearchPhrase,
  changeSearchFilter,
  searchTrainings,
} from "../../actions/training-action";
import diff from "deep-diff";
import UserGroups from "../../constants/user-groups";

const styles = (theme) => ({
  mainContainer: {
    paddingLeft: 16,
  },
  container: {
    flex: 0.25,
    textAlign: "center",
    minHeight: 585,
    maxHeight: 585,
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    minWidth: 200,
    background: "#FFFFFF",
    borderRadius: 20,
  },
  textField: {
    padding: theme.spacing.unit,
  },
  emptyWorkout: {
    padding: theme.spacing.unit * 2,
    opacity: 0.4,
  },
  filterContainer: {
    padding: "0 10px 16px",
    background: "#FFFFFF",
    borderRadius: 20,
    marginBottom: 10,
  },
  searchButtonContainer: {
    textAlign: "right",
    width: "100%",
  },
  searchButton: {
    marginTop: 10,
  },
});

class AthleteTrainingBlockSearch extends Component {
  componentDidMount() {
    const { auth } = this.props;
    const { currentUserGroups } = auth;

    const isExternalCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
      ) > -1;

    if (isExternalCoach) {
      this.props.dispatch(
        changeSearchFilter({ name: "justMine", values: true }),
      );
    } else {
      this.props.dispatch(
        changeSearchFilter({ name: "justMine", values: false }),
      );
    }
  }

  componentWillUnmount() {
    this.props.dispatch(cleanSearch());
  }

  handleSearchPress = () => {
    const { workout } = this.props;
    const { currentFilter } = workout;
    this.props.dispatch(generalSearchWorkouts(currentFilter));
  };

  handleSearchPhraseChanged = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.props.dispatch(changeSearchPhrase(value));
      }
    }
  };

  handleSelect = ({ name, value }) => {
    const { training } = this.props;
    const { currentFilter } = training;

    const values = currentFilter[name];
    if (name === "justMine") {
      this.props.dispatch(changeSearchFilter({ name, values: value }));
      return;
    }
    if (!values || values.indexOf(value) < 0)
      this.props.dispatch(
        changeSearchFilter({ name, values: [...(values || []), value] }),
      );
  };

  handleApplyFilter = () => {
    const { training } = this.props;
    const { currentFilter } = training;

    let filtersQueryStringReady = {};
    const { searchPhrase } = currentFilter;
    if (searchPhrase) {
      filtersQueryStringReady.searchPhrase = searchPhrase;
    }
    const filters = Object.keys(currentFilter).filter(
      (key) => key !== "searchPhrase",
    );
    let currentFilters = {
      ...currentFilter,
    };
    if (currentFilter.subcategories) {
      currentFilters = {
        ...currentFilters,
        subcategories: currentFilter.subcategories.filter(
          (subcategory) => subcategory !== "General",
        ),
      };
    }
    if (filters.length > 0) {
      filters.forEach((filter) => {
        if (currentFilters[filter] && currentFilter[filter].length)
          filtersQueryStringReady[filter] = currentFilters[filter].join(";");
      });
    }
    this.props.dispatch(searchTrainings(filtersQueryStringReady));
  };

  onDeleteFilter = (key, data) => {
    if (key && data) {
      const { training } = this.props;
      const { currentFilter } = training;
      const filters = [...currentFilter[key]];
      if (filters && filters.length > 0) {
        const foundIndex = filters.findIndex((item) => item === data);
        if (foundIndex >= 0) {
          filters.splice(foundIndex, 1);
          this.props.dispatch(
            changeSearchFilter({
              name: key,
              values: filters.length === 0 ? undefined : filters,
            }),
          );
        }
      }
    }
  };

  handleDeleteSearchPhrase = () => {
    this.props.dispatch(changeSearchPhrase());
  };

  render() {
    const {
      classes,
      training,
      subcategories,
      onAddBlock,
      athleteTraining,
      auth,
    } = this.props;
    const {
      currentFilter,
      latestFilterApplied,
      loading,
      searchResult,
      hasBeenSearched,
    } = training;
    const { searchPhrase, justMine } = currentFilter;

    const { data: dataFull } = searchResult;

    const { currentUserGroups, currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;

    const isExternalCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
      ) > -1;

    let data = dataFull;
    if (justMine) {
      data = dataFull.filter((w) => w.created_by_cognito_user_sub === sub);
    }

    const selectableFilters = { ...currentFilter, searchPhrase: undefined };

    const differents = diff(currentFilter, latestFilterApplied);
    const newFilterApplied = differents && differents.length > 0;
    let hasAnyFilters = false;
    for (let prop in currentFilter) {
      if (currentFilter[prop] && !hasAnyFilters) hasAnyFilters = true;
    }

    const { data: subcategoriesData } = subcategories;
    const plans = data.map((plan) => {
      return {
        ...plan,
        subcategoryObj: subcategoriesData
          ? subcategoriesData.find((s) => s.id === plan.subcategory)
          : null,
      };
    });

    return (
      <div className={classes.mainContainer}>
        <div className={classes.filterContainer}>
          {isExternalCoach ? (
            <FormControlLabel
              control={
                <Switch
                  checked={justMine}
                  onChange={(event) =>
                    this.handleSearchFilterChanged({
                      name: "justMine",
                      values: !justMine,
                    })
                  }
                  name="overlay"
                  color="primary"
                />
              }
              label={"Just show my trainings"}
              classes={{
                label: classes.switchLabel,
              }}
            />
          ) : (
            ""
          )}

          <TrainingFilter
            onSearchPhraseChanged={this.handleSearchPhraseChanged}
            onFilterSelect={this.handleSelect}
            newFilterApplied={newFilterApplied}
            hasAnyFilters={hasAnyFilters}
            onApplyFilter={this.handleApplyFilter}
            currentFilter={currentFilter}
            subcategories={subcategories}
            isOnAthletes={true}
          />
          <TrainingFilterChips
            filters={selectableFilters}
            onDeleteFilter={this.onDeleteFilter}
            searchPhrase={searchPhrase}
            onDeleteSearchPhrase={this.handleDeleteSearchPhrase}
            subcategories={subcategories}
            isOnAthletes={true}
          />
        </div>
        <div className={classes.container}>
          {loading ? (
            <LinearProgress variant="indeterminate" color="primary" />
          ) : hasBeenSearched ? (
            plans.length > 0 ? (
              <AthleteTrainingBlock
                plans={plans}
                onAddBlock={onAddBlock}
                athleteTraining={athleteTraining}
              />
            ) : (
              <div className={classes.workoutContainer}>
                No plans found based on your current search criteria
              </div>
            )
          ) : (
            <div className={classes.emptyWorkout}>
              To search plans, fill the search area with filters and press the
              search button
            </div>
          )}
        </div>
      </div>
    );
  }
}

AthleteTrainingBlockSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  onAddBlock: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    training: store.training,
    subcategories: store.subcategories,
    auth: store.auth,
  };
})(withStyles(styles)(AthleteTrainingBlockSearch));
