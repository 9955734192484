import React from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  main: {
    padding: "35px",
    backgroundColor: "#21222D",
    boxSizing: "border-box",
    borderRadius: 10,
    marginBottom: "24px",
  },
  title: {
    color: "#FFF",
  },
  titleParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  grayText: {
    color: "#87888C",
    margin: "8px 0",
  },
  bpmTitle: {
    fontSize: 24,
    color: "#A9DFD8",
    fontWeight: 700,
  },
  doneChart: {
    width: "100%",
    position: "relative",
    marginTop: 25,
    height: 120,
  },
  doneText: {
    top: "50%",
    left: "50%",
    color: "#FFF",
    position: "absolute",
    fontSize: 34,
    transform: "translate(-45%, -15%)",
    textAlign: "center",
    fontWeight: 400,
  },
});

const LactateChart = () => {
  const classes = useStyles();
  const { zones } = useSelector((state) => state.lab);
  const { success, body = {} } = zones || {};

  const calculateAverage = (data, number = 1) => {
    if (!data || !success) return;
    const numberData = Object.values(data);
    const newArray = numberData.map((item) => item[number]);
    const total = newArray.reduce((total, item) => total + item);
    const average = total / newArray.length;
    return Math.floor(average);
  };

  const getLactate = () => {
    if (!success) return {};
    const values = success["Z4"];
    return { min: values[0], max: values[1] };
  };

  const calculatePercent = (part, max) => {
    const result = (part / max) * 100;
    return Math.floor(result);
  };

  const optionsLoad = {
    cutout: 80,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    animation: {
      easing: "linear",
      duration: 1000,
    },
    rotation: 1 * Math.PI,
    circumference: 1 * Math.PI,
  };

  const dataLoad = {
    labels: ["Load", "Empty"],
    datasets: [
      {
        label: "Training load",
        data: [
          calculatePercent(calculateAverage(success), body.maxHR),
          100 - calculatePercent(calculateAverage(success), body.maxHR),
        ],
        backgroundColor: ["#A9DFD8", "#2B2B36"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className={classes.main}>
      <Typography className={classes.title} variant="h6">
        Your Lactate Threshold is:
      </Typography>

      <Typography className={classes.grayText} variant="body2">
        Based on your activity information and your personal details
      </Typography>

      <Typography className={classes.bpmTitle}>
        {getLactate().min} - {getLactate().max} BPM
      </Typography>

      <Typography className={classes.grayText} variant="body2">
        This is equivalent to a {calculatePercent(getLactate().min, body.maxHR)}
        % to {calculatePercent(getLactate().max, body.maxHR)}% of your Max Heart
        Rate.
      </Typography>

      <Box className={classes.doneChart}>
        <Doughnut data={dataLoad} options={optionsLoad} />
        <Typography variant="body1" className={classes.doneText}>
          {calculatePercent(calculateAverage(success), body.maxHR)}
          <span className={classes.donePercentage}>%</span>
        </Typography>
      </Box>
    </div>
  );
};

export default LactateChart;
