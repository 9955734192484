import ActionTypes from "../constants/action-types";
import TrainingsService from "../services/trainings-service";

const searchTrainings = (searchParams) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_SEARCH_PENDING });
    try {
      const result = await TrainingsService.searchTrainings(searchParams);
      dispatch({
        type: ActionTypes.TRAINING_SEARCH_FULFILLED,
        payload: result,
      });
      dispatch({ type: ActionTypes.TRAINING_CLEAN_SWAP });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAINING_SEARCH_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const cleanSearch = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_CLEAN_SEARCH });
  };
};

const cleanSwap = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_CLEAN_SWAP });
  };
};

const saveTrainingHeader = (training) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_SAVE_PENDING });
    try {
      const result = await TrainingsService.createTraining(training);
      dispatch({
        type: ActionTypes.TRAINING_SAVE_FULFILLED,
        payload: result,
        redirectEditor: true,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAINING_SAVE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const changeSearchPhrase = (searchPhrase) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAINING_SEARCH_PHRASE_CHANGED,
      payload: searchPhrase,
    });
  };
};

const changeSearchFilter = (searchFilter) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAINING_SEARCH_FILTER_CHANGED,
      payload: searchFilter,
    });
  };
};

const changeSearchParams = (searchParams) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAINING_SEARCH_PARAM_CHANGED,
      payload: searchParams,
    });
  };
};

const changeWeek = (weekNumber) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_WEEK_CHANGED, payload: weekNumber });
  };
};

const selectWorkout = (training) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_SELECT_WORKOUT, payload: training });
  };
};

const selectInlineEditTraining = (routeId) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAINING_INLINE_EDIT_SELECT,
      payload: routeId,
    });
  };
};

const readTraining = ({ id, training_order }) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAINING_READ,
      payload: await TrainingsService.readTraining({ id, training_order }),
    });
  };
};

const duplicateTraining = ({ id, training_order }) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_READ_PENDING });
    TrainingsService.patchAnExistingTrainingAsDuplicate({ id, training_order })
      .then((result) => {
        dispatch({
          type: ActionTypes.TRAINING_READ_FULFILLED,
          payload: result,
        });
        if (
          result &&
          result.athlete_cognito_user_full_name &&
          result.athlete_cognito_user_sub
        ) {
          dispatch({
            type: ActionTypes.TRAINING_SEARCH_ATHLETES_FULFILLED,
            payload: [
              {
                cognito_user_sub: result.athlete_cognito_user_sub,
                cognito_user_name: "",
                cognito_user_family_name: result.athlete_cognito_user_full_name,
              },
            ],
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.TRAINING_READ_REJECTED,
          payload: err,
          error: true,
        });
      });
  };
};

const addWorkout = (dayIndex) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_ADD_WORKOUT, payload: dayIndex });
  };
};

const removeWorkout = (dayTraining) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAINING_REMOVE_WORKOUT,
      payload: dayTraining,
    });
  };
};

const clearCurrentTraining = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_CLEAR_CURRENT });
  };
};

const saveTraining = (currentTraining) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_SAVE_PENDING });
    try {
      const result = await TrainingsService.saveTraining(currentTraining);
      dispatch({
        type: ActionTypes.TRAINING_SAVE_FULFILLED,
        payload: result,
        redirect: true,
      });
      dispatch(clearCurrentTraining());
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAINING_SAVE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const patchTraining = (idRoute, change) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_SAVE_PENDING, payload: idRoute });
    try {
      const result = await TrainingsService.patchTraining(idRoute, change);
      dispatch({ type: ActionTypes.TRAINING_SAVE_FULFILLED, payload: result });
      dispatch(selectInlineEditTraining());
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAINING_SAVE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const swapTraining = (id, training_order1, training_order2) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_SWAP_PENDING });
    try {
      const result = await TrainingsService.swapTraining(
        id,
        training_order1,
        training_order2,
      );
      dispatch({ type: ActionTypes.TRAINING_SWAP_FULFILLED, payload: result });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAINING_SWAP_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const athletesSearch = (searchParams, isAdmin, sub) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TRAINING_SEARCH_ATHLETES,
      payload: TrainingsService.athletesSearch(searchParams, isAdmin, sub),
    });
  };
};

const ClearAthletes = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_CLEAR_ATHLETES });
  };
};

const setErrorMessage = (err) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_SET_ERROR_MESSAGE, payload: err });
  };
};

const clearErrorMessage = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_CLEAR_ERROR_MESSAGE });
  };
};

const setSuccessMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TRAINING_SET_SUCCESS_MESSAGE,
      payload: message,
    });
  };
};

const clearSuccessMessage = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_CLEAR_SUCCESS_MESSAGE });
  };
};

const trainingChangeField = (trainingToSave) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TRAINING_CHANGE_FIELD,
      payload: trainingToSave,
    });
  };
};

const deleteBulkTraining = (keys) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_DELETE_PENDING });
    try {
      const result = await TrainingsService.deleteBulkTraining(keys);
      dispatch({
        type: ActionTypes.TRAINING_DELETE_FULFILLED,
        payload: result,
      });
      dispatch({ type: ActionTypes.TRAINING_CLEAN_SWAP });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAINING_DELETE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

export {
  searchTrainings,
  cleanSearch,
  saveTrainingHeader,
  changeSearchFilter,
  changeSearchParams,
  changeSearchPhrase,
  changeWeek,
  selectWorkout,
  readTraining,
  addWorkout,
  clearCurrentTraining,
  removeWorkout,
  saveTraining,
  setErrorMessage,
  clearErrorMessage,
  setSuccessMessage,
  clearSuccessMessage,
  trainingChangeField,
  deleteBulkTraining,
  swapTraining,
  patchTraining,
  selectInlineEditTraining,
  cleanSwap,
  athletesSearch,
  ClearAthletes,
  duplicateTraining,
};
