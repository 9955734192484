import dayjs from "dayjs";
import ActionTypes from "../constants/action-types";
import ServerErrors from "../constants/server-errors";
import moment from "moment";
import { dateFormat } from "../lib/date-helper";

const initialState = {
  currentStep: 0,
  planTempData: {},
  nextPlanExists: undefined,
  saving: false,
  loading: true,
  loadingPlan: true,
  planToSelect: null,
  referralCode: null,
  loadingReferralCode: null,
  referralCodeError: null,
  loadingRaffleCode: null,
  comments: [],
  loadingComments: null,
  loadingOldComments: null,
  canLoadMoreComments: true,
  chainedPlans: [],
  previousPlans: [],
  openMenu: false,
  loadingEpicRaces: true,
  epicRaces: [],
  loadingUserBadges: false,
  currentUserBadges: [],
  editingLoading: false,
  editingMode: false,
  loginCount: 0,
  planLoading: false,
  plan: [],
  customerCoachRate: {},
  customerPlanRate: {},
  loadingHasPlan: true,
  hasPlan: null,
  unreadMessages: false,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.USER_PLANS_SET_PLAN_TO_SELECT:
        return {
          ...state,
          planToSelect: action.payload,
          saving: false,
          successMessage: undefined,
        };
      case ActionTypes.USER_PLANS_SET_CURRENT_PLAN:
        return {
          ...state,
          currentPlan: action.payload,
        };
      case ActionTypes.USER_PLANS_CHANGE_FIELD:
        const { name, value } = action.payload;
        return {
          ...state,
          planTempData: {
            ...state.planTempData,
            [name]: value,
          },
        };
      case ActionTypes.USER_PLANS_CLEAN_FIELDS:
        return {
          ...state,
          planTempData: {},
          errorMessage: undefined,
        };
      case ActionTypes.USER_PLANS_SET_ERROR_MESSAGE:
        return {
          ...state,
          errorMessage: action.payload,
        };
      case ActionTypes.USER_PLANS_CLEAR_ERROR_MESSAGE:
        return {
          ...state,
          errorMessage: undefined,
        };
      case ActionTypes.USER_PLANS_SET_SUCCESS_MESSAGE:
        return {
          ...state,
          successMessage: action.payload,
        };
      case ActionTypes.USER_PLANS_CLEAR_SUCCESS_MESSAGE:
        return {
          ...state,
          successMessage: undefined,
        };
      case ActionTypes.USER_PLANS_SAVE_PENDING:
        return {
          ...state,
          saving: true,
        };
      case ActionTypes.USER_PLANS_SAVE_FULFILLED:
        return {
          ...state,
          saving: false,
          successMessage: "Your plan changes have been saved successfully",
          currentPlan: action.payload,
        };
      case ActionTypes.USER_PLANS_SAVE_REJECTED:
        return {
          ...state,
          saving: false,
          errorMessage: undefined,
        };
      case ActionTypes.USER_PLANS_UPDATE_PENDING:
        return {
          ...state,
          updating: action.payload,
          showSuccessMessage: action.payload,
        };
      case ActionTypes.USER_PLANS_UPDATE_FULFILLED:
      case ActionTypes.USER_PLANS_UPDATE_LOAD_FULFILLED:
        let updatedCurrentPlan = action.payload;
        if (!updatedCurrentPlan) {
          updatedCurrentPlan = state.currentPlan;
        }

        if (
          (!updatedCurrentPlan || !updatedCurrentPlan.currentTraining) &&
          state.currentPlan &&
          state.currentPlan.currentTraining
        ) {
          updatedCurrentPlan.currentTraining =
            state.currentPlan.currentTraining;
        }

        if (
          updatedCurrentPlan.currentTraining &&
          state.currentPlan &&
          state.currentPlan.currentTraining &&
          state.currentPlan.currentTraining.currentStartDate
        ) {
          updatedCurrentPlan.currentTraining.currentStartDate =
            state.currentPlan.currentTraining.currentStartDate;
        }

        if (
          updatedCurrentPlan &&
          updatedCurrentPlan.currentTraining &&
          state.currentPlan &&
          state.currentPlan.currentTraining &&
          state.currentPlan.currentTraining.selectedTrainingDayWorkoutTemp
        ) {
          updatedCurrentPlan.currentTraining.selectedTrainingDayWorkoutTemp =
            state.currentPlan.currentTraining.selectedTrainingDayWorkoutTemp;
        }

        return {
          ...state,
          updating: false,
          successMessage: state.showSuccessMessage
            ? "Your changes have been saved successfully"
            : undefined,
          currentPlan: updatedCurrentPlan,
        };
      case ActionTypes.USER_PLANS_UPDATE_REJECTED:
        return {
          ...state,
          updating: false,
          errorMessage: undefined,
          currentPlan: {},
        };

      case ActionTypes.USER_PLANS_CT_UPDATE_PENDING:
        return {
          ...state,
          updating: true,
          showSuccessMessage: action.payload,
        };
      case ActionTypes.USER_PLANS_CT_UPDATE_FULFILLED:
        let currentPlanUpdates = action.payload;
        let updatedCurrentPlanCT = {
          ...state.currentPlan,
          ...currentPlanUpdates,
        };

        return {
          ...state,
          updating: false,
          successMessage: state.showSuccessMessage
            ? "Your changes have been saved successfully"
            : undefined,
          currentPlan: updatedCurrentPlanCT,
        };

      case ActionTypes.USER_PLANS_CT_UPDATE_REJECTED:
        return {
          ...state,
          updating: false,
          errorMessage: undefined,
        };

      case ActionTypes.USER_PLANS_UPDATE_IMAGE_PENDING:
        return {
          ...state,
          updatingImage: true,
          currentPlan: {
            ...state.currentPlan,
            image: null,
          },
        };
      case ActionTypes.USER_PLANS_UPDATE_IMAGE_FULFILLED:
        return {
          ...state,
          updatingImage: false,
          successMessage: "Your photo has been saved successfully",
          currentPlan: action.payload,
        };
      case ActionTypes.USER_PLANS_UPDATE_IMAGE_REJECTED:
        return {
          ...state,
          updatingImage: false,
          errorMessage:
            (action.payload && action.payload.message) ||
            ServerErrors.ServerError,
        };

      case ActionTypes.USER_PLANS_ADD_IMAGE_PENDING:
        return {
          ...state,
          addingImage: true,
        };
      case ActionTypes.USER_PLANS_ADD_IMAGE_FULFILLED:
        return {
          ...state,
          addingImage: false,
          successMessage: "Your photo has been saved successfully",
          currentPlan: action.payload,
        };
      case ActionTypes.USER_PLANS_ADD_IMAGE_REJECTED:
        return {
          ...state,
          addingImage: false,
          errorMessage:
            (action.payload && action.payload.message) ||
            ServerErrors.ServerError,
        };

      case ActionTypes.USER_PLANS_DELETE_IMAGE_PENDING:
        return {
          ...state,
          addingImage: true,
        };
      case ActionTypes.USER_PLANS_DELETE_IMAGE_FULFILLED:
        return {
          ...state,
          addingImage: false,
          successMessage: "Your photo has been deleted successfully",
          currentPlan: action.payload,
        };
      case ActionTypes.USER_PLANS_DELETE_IMAGE_REJECTED:
        return {
          ...state,
          addingImage: false,
          errorMessage:
            (action.payload && action.payload.message) ||
            ServerErrors.ServerError,
        };

      case ActionTypes.USER_NEXT_PLAN_GET_PENDING:
        return {
          ...state,
        };
      case ActionTypes.USER_NEXT_PLAN_GET_FULFILLED:
        return {
          ...state,
          nextPlanExists: action.payload,
        };
      case ActionTypes.USER_NEXT_PLAN_GET_REJECTED:
        return {
          ...state,
          errorMessage: undefined,
        };

      case ActionTypes.USER_PLANS_REFERRAL_CODE_PENDING:
        return {
          ...state,
          loadingReferralCode: true,
          referralCodeError: false,
        };
      case ActionTypes.USER_PLANS_REFERRAL_CODE_FULFILLED:
        if (!action.payload) {
          return {
            ...state,
            loadingReferralCode: false,
            referralCodeError: true,
            errorMessage: "No referral codes available. Please try again later",
          };
        }
        return {
          ...state,
          referralCode: action.payload,
          loadingReferralCode: false,
          referralCodeError: false,
        };
      case ActionTypes.USER_PLANS_REFERRAL_CODE_REJECTED:
        return {
          ...state,
          loadingReferralCode: false,
          referralCodeError: true,
          errorMessage: "No referral codes available. Please try again later",
        };

      case ActionTypes.USER_PLANS_RAFFLE_CODE_PENDING:
        return {
          ...state,
          loadingRaffleCode: true,
          raffleCodeError: false,
        };
      case ActionTypes.USER_PLANS_RAFFLE_CODE_FULFILLED:
        if (!action.payload) {
          return {
            ...state,
            loadingRaffleCode: false,
            raffleCodeError: true,
            errorMessage: "Promo code not found",
          };
        }
        return {
          ...state,
          referralCode: action.payload,
          loadingRaffleCode: false,
          raffleCodeError: false,
          successMessage: "Use of promo code saved",
        };
      case ActionTypes.USER_PLANS_RAFFLE_CODE_REJECTED:
        return {
          ...state,
          loadingRaffleCode: false,
          raffleCodeError: true,
          errorMessage: "Promo code not found",
        };
      case ActionTypes.USER_PLANS_GET_COMMENTS_PENDING:
      case ActionTypes.USER_PLANS_ADD_COMMENT_PENDING:
      case ActionTypes.USER_PLANS_COMMENTS_READ_PENDING:
        return {
          ...state,
          loadingComments: true,
        };

      case ActionTypes.USER_PLANS_GET_COMMENTS_FULFILLED:
      case ActionTypes.USER_PLANS_ADD_COMMENT_FULFILLED:
      case ActionTypes.USER_PLANS_COMMENTS_READ_FULFILLED:
        return {
          ...state,
          comments:
            action.payload && action.payload.Items ? action.payload.Items : [],
          loadingComments: false,
        };

      case ActionTypes.USER_PLANS_GET_COMMENTS_REJECTED:
      case ActionTypes.USER_PLANS_ADD_COMMENT_REJECTED:
      case ActionTypes.USER_PLANS_COMMENTS_READ_REJECTED:
        return {
          ...state,
          loadingComments: false,
          //errorMessage: "Error loading comments",
        };

      case ActionTypes.USER_PLANS_GET_OLD_COMMENTS_PENDING:
        return {
          ...state,
          loadingOldComments: true,
        };

      case ActionTypes.USER_PLANS_GET_OLD_COMMENTS_FULFILLED:
        return {
          ...state,
          canLoadMoreComments:
            action.payload.Items && action.payload.Items.length > 0,
          comments:
            action.payload && action.payload.Items
              ? [...action.payload.Items, ...state.comments]
              : state.comments,
          loadingOldComments: false,
        };

      case ActionTypes.USER_PLANS_GET_OLD_COMMENTS_REJECTED:
        return {
          ...state,
          loadingOldComments: false,
          //errorMessage: "Error loading comments",
        };

      case ActionTypes.USER_PLANS_LOAD_CURRENT_PLAN_PENDING:
        return {
          ...state,
          loading: action.payload,
          loadingPlan: true,
        };
      case ActionTypes.USER_PLANS_LOAD_CURRENT_PLAN_FULFILLED:
        let loadedCurrentPlan = action.payload;
        if (!loadedCurrentPlan) {
          loadedCurrentPlan = state.currentPlan;
        }

        if (
          (!loadedCurrentPlan || !loadedCurrentPlan.currentTraining) &&
          state.currentPlan &&
          state.currentPlan.currentTraining
        ) {
          loadedCurrentPlan.currentTraining = state.currentPlan.currentTraining;
        }

        if (
          loadedCurrentPlan &&
          loadedCurrentPlan.currentTraining &&
          state.currentPlan &&
          state.currentPlan.currentTraining &&
          state.currentPlan.currentTraining.currentStartDate
        ) {
          loadedCurrentPlan.currentTraining.currentStartDate =
            state.currentPlan.currentTraining.currentStartDate;
        }

        if (
          loadedCurrentPlan &&
          loadedCurrentPlan.currentTraining &&
          state.currentPlan &&
          state.currentPlan.currentTraining &&
          state.currentPlan.currentTraining.selectedTrainingDayWorkoutTemp
        ) {
          loadedCurrentPlan.currentTraining.selectedTrainingDayWorkoutTemp =
            state.currentPlan.currentTraining.selectedTrainingDayWorkoutTemp;
        }

        return {
          ...state,
          loading: false,
          loadingPlan: false,
          currentPlan: loadedCurrentPlan,
          chainedPlans:
            !loadedCurrentPlan ||
            !loadedCurrentPlan.chainedPlans ||
            loadedCurrentPlan.chainedPlans.length === 0
              ? []
              : state.chainedPlans,
          previousPlans:
            !loadedCurrentPlan ||
            !loadedCurrentPlan.previousPlans ||
            loadedCurrentPlan.previousPlans.length === 0
              ? []
              : state.previousPlans,
        };
      case ActionTypes.USER_PLANS_LOAD_CURRENT_PLAN_REJECTED:
        return {
          ...state,
          loading: false,
          loadingPlan: false,
          errorMessage:
            state.currentPlan && state.currentPlan.currentTraining
              ? undefined
              : (action.payload && action.payload.message) ||
                ServerErrors.ServerError,
        };
      case ActionTypes.USER_PLANS_LOAD_CURRENT_TRAINING_PENDING:
        return {
          ...state,
          loadingCurrentTraining: true,
        };
      case ActionTypes.USER_PLANS_LOAD_CURRENT_TRAINING_FULFILLED:
        const { currentTraining: currentTrainingToReload } = action.payload;
        return {
          ...state,
          loadingCurrentTraining: false,
          currentPlan: {
            ...state.currentPlan,
            currentTraining: currentTrainingToReload,
          },
        };
      case ActionTypes.USER_PLANS_LOAD_CURRENT_TRAINING_REJECTED:
        return {
          ...state,
          loadingCurrentTraining: false,
          errorMessage:
            state.currentPlan && state.currentPlan.currentTraining
              ? undefined
              : (action.payload && action.payload.message) ||
                ServerErrors.ServerError,
        };

      case ActionTypes.USER_PLANS_LOAD_TRAINING_PENDING:
        return {
          ...state,
          loadingPlan: true,
        };

      case ActionTypes.USER_PLANS_LOAD_TRAINING_FULFILLED:
        let newCurrentTrainingForChainedPlan = state.currentPlan;
        let newChainedPlans = state.chainedPlans;
        if (
          newCurrentTrainingForChainedPlan.chainedPlans &&
          action.payload &&
          action.payload.updated_at &&
          action.payload.currentTraining
        ) {
          if (
            state.chainedPlans.length !==
            newCurrentTrainingForChainedPlan.chainedPlans.length
          ) {
            newChainedPlans = [
              ...newCurrentTrainingForChainedPlan.chainedPlans,
            ];
          }
          const slotIndex = newCurrentTrainingForChainedPlan.chainedPlans.findIndex(
            (c) => c === action.payload.updated_at,
          );
          if (slotIndex > -1) {
            newChainedPlans[slotIndex] = action.payload.currentTraining;
          }
        }
        return {
          ...state,
          chainedPlans: newChainedPlans,
          loadingPlan: false,
        };

      case ActionTypes.USER_PLANS_PREV_LOAD_TRAINING_PENDING:
        return {
          ...state,
          loadingPlan: true,
        };

      case ActionTypes.USER_PLANS_PREV_LOAD_TRAINING_FULFILLED:
        let newCurrentTrainingForPrevPlan = state.currentPlan;
        let newPreviousPlans = state.previousPlans;
        if (newCurrentTrainingForPrevPlan.previousPlans) {
          if (
            state.previousPlans.length !==
            newCurrentTrainingForPrevPlan.previousPlans.length
          ) {
            newChainedPlans = [...newCurrentTrainingForPrevPlan.previousPlans];
          }
          const slotIndex = newCurrentTrainingForPrevPlan.previousPlans.findIndex(
            (c) => c === action.payload.updated_at,
          );
          if (slotIndex > -1) {
            newPreviousPlans[slotIndex] = action.payload.currentTraining;
          }
        }
        return {
          ...state,
          previousPlans: newPreviousPlans,
          loadingPlan: false,
        };

      case ActionTypes.USER_PLANS_CHANGE_CURRENT_STEP:
        return {
          ...state,
          currentStep: action.payload,
        };
      case ActionTypes.USER_PLANS_CURRENT_TRAINING_CURRENT_START_DATE_CHANGED:
        if (!state.currentPlan || !state.currentPlan.currentTraining) {
          return {
            ...state,
          };
        }
        return {
          ...state,
          currentPlan: {
            ...state.currentPlan,
            currentTraining: {
              ...state.currentPlan.currentTraining,
              currentStartDate: (state.currentPlan.currentTraining
                .currentStartDate
                ? moment(state.currentPlan.currentTraining.currentStartDate)
                : action.payload !== 7 && action.payload !== -7
                ? moment().startOf("day")
                : moment().startOf("isoWeek")
              ).add(action.payload, "days"),
            },
          },
        };

      case ActionTypes.USER_PLANS_CURRENT_TRAINING_CURRENT_START_DATE_SET:
        if (!state.currentPlan || !state.currentPlan.currentTraining) {
          return {
            ...state,
          };
        }
        return {
          ...state,
          currentPlan: {
            ...state.currentPlan,
            currentTraining: {
              ...state.currentPlan.currentTraining,
              currentStartDate: action.payload,
            },
          },
        };

      case ActionTypes.AUTH_CURRENT_USER_SIGNOUT_FULFILLED:
        return {
          ...initialState,
        };
      case ActionTypes.USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_PENDING:
        return {
          ...state,
          updatingWorkoutId: action.payload.workoutId,
          showSuccessMessage: action.payload.showSuccessMessage,
          loadingmarksAsDone: true,
        };
      case ActionTypes.USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_FULFILLED:
        let newCurrentPlan = action.payload;
        const { currentPlan } = state;
        if (currentPlan) {
          const { currentTraining } = currentPlan;
          if (currentTraining) {
            newCurrentPlan = {
              ...currentPlan,
              currentTraining: {
                ...newCurrentPlan.currentTraining,
                currentStartDate: currentTraining.currentStartDate,
                selectedTrainingDayWorkoutTemp:
                  currentTraining.selectedTrainingDayWorkoutTemp,
              },
            };
          }
        }
        return {
          ...state,
          updatingWorkoutId: undefined,
          successMessage: state.showSuccessMessage
            ? "Changes to the training plan were saved successfully"
            : undefined,
          currentPlan: newCurrentPlan,
          loadingmarksAsDone: false,
        };
      case ActionTypes.USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_REJECTED:
        return {
          ...state,
          updatingWorkoutId: undefined,
          loadingmarksAsDone: false,
          errorMessage:
            (action.payload && action.payload.message) ||
            ServerErrors.ServerError,
        };
      case ActionTypes.USER_PLANS_CURRENT_TRAINING_SET_SELECTED_TEMP:
        const fullTraining =
          state.currentPlan && state.currentPlan.currentTraining
            ? state.currentPlan.currentTraining
            : null;

        const selectedWorkout =
          action.payload && fullTraining && fullTraining.days
            ? fullTraining.days.find(
                (training) =>
                  training &&
                  training[0].plannedDate === action.payload.plannedDate &&
                  training[0].id === action.payload.workoutId,
              )
            : undefined;
        return {
          ...state,
          currentPlan: {
            ...state.currentPlan,
            currentTraining: {
              ...state.currentPlan.currentTraining,
              selectedTrainingDayWorkoutTemp:
                selectedWorkout && selectedWorkout.length > 0
                  ? selectedWorkout[0]
                  : undefined,
            },
          },
        };
      case ActionTypes.USER_PLANS_MAILCHIMP_SUBSCRIBE_PENDING:
        return {
          ...state,
          subscribing: true,
        };
      case ActionTypes.USER_PLANS_MAILCHIMP_SUBSCRIBE_FULFILLED:
        let loadedCurrentPlanM = action.payload;
        if (!loadedCurrentPlanM) {
          loadedCurrentPlanM = state.currentPlan;
        }

        if (
          (!loadedCurrentPlanM || !loadedCurrentPlanM.currentTraining) &&
          state.currentPlan &&
          state.currentPlan.currentTraining
        ) {
          loadedCurrentPlanM.currentTraining =
            state.currentPlan.currentTraining;
        }

        if (
          loadedCurrentPlanM &&
          loadedCurrentPlanM.currentTraining &&
          state.currentPlan &&
          state.currentPlan.currentTraining &&
          state.currentPlan.currentTraining.currentStartDate
        ) {
          loadedCurrentPlanM.currentTraining.currentStartDate =
            state.currentPlan.currentTraining.currentStartDate;
        }

        if (
          loadedCurrentPlanM &&
          loadedCurrentPlanM.currentTraining &&
          state.currentPlan &&
          state.currentPlan.currentTraining &&
          state.currentPlan.currentTraining.selectedTrainingDayWorkoutTemp
        ) {
          loadedCurrentPlanM.currentTraining.selectedTrainingDayWorkoutTemp =
            state.currentPlan.currentTraining.selectedTrainingDayWorkoutTemp;
        }

        return {
          ...state,
          subscribing: false,
          currentPlan: loadedCurrentPlanM,
        };
      case ActionTypes.USER_PLANS_MAILCHIMP_SUBSCRIBE_REJECTED:
        if (action.payload) {
          console.error(action.payload.message || action.payload);
        }
        return {
          ...state,
          subscribing: false,
          //errorMessage: 'Your subscription to our Newsletter has been failed, please check your profile to resubscribe'
        };

      case ActionTypes.USER_PLANS_TOOGLE_OPEN_MENU:
        return {
          ...state,
          openMenu: !state.openMenu,
        };

      case ActionTypes.GET_EPIC_RACES_PENDING:
        return {
          ...state,
          loadingEpicRaces: true,
        };

      case ActionTypes.GET_EPIC_RACES_FULFILLED:
        return {
          ...state,
          loadingEpicRaces: false,
          epicRaces: action.payload,
        };

      case ActionTypes.GET_EPIC_RACES_REJECTED:
        return {
          ...state,
          loadingEpicRaces: false,
          errorMessage:
            (action.payload && action.payload.message) ||
            ServerErrors.ServerError,
        };

      case ActionTypes.CHALLENGE_GET_USER_BADGES_PENDING:
        return {
          ...state,
          loadingUserBadges: true,
        };

      case ActionTypes.CHALLENGE_GET_USER_BADGES_FULFILLED:
        return {
          ...state,
          loadingUserBadges: false,
          currentUserBadges: action.payload,
        };

      case ActionTypes.CHALLENGE_GET_USER_BADGES_REJECTED:
        return {
          ...state,
          loadingEpicRaces: false,
          errorMessage:
            (action.payload && action.payload.message) ||
            ServerErrors.ServerError,
        };

      case ActionTypes.CLEAR_CURRENT_TEMP_TRAINING:
        return {
          ...state,
          currentPlan: {
            ...state.currentPlan,
            currentTraining: {
              ...state.currentPlan.currentTraining,
              selectedTrainingDayWorkoutTemp: {},
            },
          },
        };

      case ActionTypes.EXPORT_WORKOUT_FULFILLED:
        return {
          ...state,
          successMessage: "Workout exported successfully.",
        };

      case ActionTypes.EXPORT_WORKOUT_UNFULFILLED:
        return {
          ...state,
          errorMessage: "There was an error trying to export the workout.",
        };

      case ActionTypes.SET_LOGIN_COUNT:
        return {
          ...state,
          loginCount: action.payload,
        };

      case ActionTypes.GET_PAGINATED_PLAN_PENDING:
        return {
          ...state,
          planLoading: true,
        };

      case ActionTypes.GET_PAGINATED_PLAN_FULFILLED:
        const localCurrentPlan = state.currentPlan.currentTraining.days || [];
        const paginatedPlan = localCurrentPlan.filter((day) => {
          if (day) {
            const { fromDate, toDate } = action.payload;
            if (
              (dayjs(day[0].plannedDate, dateFormat).isSame(
                dayjs(fromDate, dateFormat),
              ) ||
                dayjs(day[0].plannedDate, dateFormat).isAfter(
                  dayjs(fromDate, dateFormat),
                )) &&
              (dayjs(day[0].plannedDate, dateFormat).isSame(
                dayjs(toDate, dateFormat),
              ) ||
                dayjs(day[0].plannedDate, dateFormat).isBefore(
                  dayjs(toDate, dateFormat),
                ))
            ) {
              return day;
            }
          }
        });

        return {
          ...state,
          plan: paginatedPlan,
        };

      case ActionTypes.GET_PAGINATED_PLAN_REJECTED:
        return {
          ...state,
          planLoading: false,
        };
      case ActionTypes.CUSTOMER_COACH_RATE_SAVE:
        return {
          ...state,
          customerCoachRate: action.payload,
        };

      case ActionTypes.CUSTOMER_COACH_RATE_SAVE_PENDING:
        return {
          ...state,
          customerCoachRate: true,
        };

      case ActionTypes.CUSTOMER_COACH_RATE_SAVE_REJECTED:
        return {
          ...state,
          customerCoachRate: action.payload,
        };
      case ActionTypes.CUSTOMER_PLAN_RATE_SAVE:
        return {
          ...state,
          customerPlanRate: action.payload,
        };

      case ActionTypes.CUSTOMER_COACH_PLAN_SAVE_PENDING:
        return {
          ...state,
          customerPlanRate: true,
        };

      case ActionTypes.CUSTOMER_PLAN_RATE_SAVE_REJECTED:
        return {
          ...state,
          customerPlanRate: action.payload,
        };

      case ActionTypes.REQUEST_COACH_CHANGE_SAVE:
        return {
          ...state,
          requestCoachChange: action.payload,
        };

      case ActionTypes.REQUEST_COACH_CHANGE_SAVE_PENDING:
        return {
          ...state,
          requestCoachChange: true,
        };

      case ActionTypes.REQUEST_COACH_CHANGE_SAVE_REJECTED:
        return {
          ...state,
          requestCoachChange: action.payload,
        };
      case ActionTypes.USER_PLANS_LOADING_HAS_PLAN:
        return {
          ...state,
          loadingHasPlan: true,
        };
      case ActionTypes.USER_PLANS_LOADING_HAS_PLAN_DONE:
        return {
          ...state,
          loadingHasPlan: false,
        };
      case ActionTypes.USER_PLANS_SET_HAS_PLAN:
        return {
          ...state,
          hasPlan: action.payload,
        };
      case ActionTypes.USER_PLANS_SET_HAS_PLAN_REJECTED:
        return {
          ...state,
          hasPlan: false,
          loadingHasPlan: false,
        };
      case ActionTypes.USER_PLANS_UNREAD_MESSAGES:
        return {
          ...state,
          unreadMessages: action.payload,
        };
      case ActionTypes.USER_PLANS_NEW_MESSAGES_BY_EVENT:
        return {
          ...state,
          comments: action.payload
            ? [...state.comments, action.payload]
            : state.comments,
        };

      default:
        return state;
    }
  } else {
    return state;
  }
}
