import React from "react";
import { SvgIcon } from "@material-ui/core";
import { palette } from "../../theme/palette";

const LightningIcon = ({ width = 24, height = 24, activated = false }) => (
  <>
    <SvgIcon>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill={activated ? palette.primary : "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7435 8.88542C14.7246 8.80526 14.6861 8.73101 14.6316 8.66928C14.577 8.60754 14.5081 8.56023 14.4309 8.53155L10.8291 7.18048L11.7456 2.59582C11.7664 2.4893 11.7519 2.37891 11.7044 2.28133C11.657 2.18374 11.579 2.10425 11.4824 2.05484C11.3858 2.00543 11.2757 1.98879 11.1688 2.00743C11.0619 2.02607 10.9639 2.07898 10.8897 2.15817L3.88737 9.66068C3.8305 9.72061 3.78936 9.79369 3.76762 9.87339C3.74588 9.9531 3.74423 10.037 3.76281 10.1175C3.78139 10.198 3.81962 10.2726 3.87408 10.3347C3.92855 10.3968 3.99755 10.4445 4.07493 10.4735L7.67801 11.8245L6.76396 16.4042C6.7432 16.5107 6.75766 16.6211 6.80514 16.7187C6.85262 16.8163 6.93056 16.8958 7.02719 16.9452C7.12381 16.9946 7.23389 17.0112 7.3408 16.9926C7.44771 16.9739 7.54566 16.921 7.61987 16.8418L14.6222 9.33932C14.6781 9.27938 14.7183 9.20664 14.7395 9.1275C14.7606 9.04835 14.762 8.96523 14.7435 8.88542ZM8.09002 14.8768L8.74462 11.602C8.76805 11.4858 8.74948 11.3651 8.69223 11.2614C8.63498 11.1576 8.54277 11.0776 8.43201 11.0355L5.12841 9.79447L10.4189 4.12633L9.76496 7.40117C9.74153 7.51732 9.76009 7.63801 9.81734 7.74174C9.87459 7.84548 9.96681 7.92552 10.0776 7.96761L13.3787 9.20553L8.09002 14.8768Z"
          fill={activated ? palette.primary : palette.darkGray}
        />
      </svg>
    </SvgIcon>
  </>
);
export default LightningIcon;
