import React from "react";
import { TextField } from "@material-ui/core";

import inputComponent from "./input-component";

export default function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        classes: {
          underline: props.selectProps.classes.underline
            ? props.selectProps.classes.underline
            : {},
        },
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}
