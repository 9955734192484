import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import FilterSelect from "../../common/filter-select";
import TrainingPeriods from "../../../constants/training-periods";
import DifficultyLevels from "../../../constants/difficulty-levels";
import WorkoutTypes from "../../../constants/workout-types";
import Surfaces from "../../../constants/surfaces";
import EstimatedTimes from "../../../constants/estimated-times";
import WorkoutEditorMultiLangFields from "./workout-editor-multi-lang-fields";
import {
  Grid,
  Button,
  Tooltip,
  Stepper,
  Step,
  StepButton,
  IconButton,
  StepLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ReactSortable } from "react-sortablejs";
import WorkoutStep from "./workout-step";
import WorkoutRepeat from "./workout-repeat";
import DeleteIcon from "@material-ui/icons/Delete";
import FontAwesomeIcon from "../../common/font-awesome-icon";
import { connect } from "react-redux";
import { workoutChangeField } from "../../../actions/workout-action";
import WorkoutActionTypes from "../../../constants/workout-action-types";
import WorkoutActionVariables from "../../../constants/workout-action-variables";
import WorkoutTargets from "../../../constants/workout-targets";
import { readWorkout } from "../../../actions/workout-action";
import { athleteTrainingRemoveWorkout } from "../../../actions/athletes-action";
import moment from "moment";
import { getPlan } from "../../../lib/current-training-helper";
import classNames from "classnames";

const styles = (theme) => ({
  container: {
    padding: "14px",
    boxSizing: "border-box",
  },
  workoutPane: {
    // width: "100%",
    padding: 10,
    marginLeft: "16px",
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  smallMargin: {
    margin: "0 10px",
    backgroundColor: "#FFF",
  },
  stepper: {
    width: "250px",
    padding: "2px",
  },
  workoutStepsContainer: {
    overflow: "scroll",
    height: "auto",
    width: "100%",
  },
  custom: {
    margingTop: "0px !important",
    fontSize: "12px",
  },
  levelCustom: {
    padding: "7px 15px",
    margin: "0 15px",
    backgroundColor: "#FFF",
    border: "1px solid rgb(203,203,203)",
    borderRadius: "4px",

    "& p": {
      fontWeight: "bold",
    },
  },
});

const isNew = false;

class WorkoutEditorForm extends Component {
  state = {
    level: 1,
    showDialog: false,
  };

  componentDidMount() {
    const { athletes, level } = this.props;
    const { selectedAthlete } = athletes || {};
    const { selectedWorkout } = selectedAthlete;
    this.props.handleChangeWorkout({
      ...JSON.parse(JSON.stringify(selectedWorkout)),
    });
    if (!isNaN(level)) this.setState({ ...this.state, level });
  }

  handleOpenDelete = () => {
    this.setState({ ...this.state, showDialog: !this.state.showDialog });
  };

  handleSetParametrizedWorkout = (listChange, repeatId = null) => {
    const { workout } = this.props;
    const { parametrizedWorkout = [] } = workout;
    const { level } = this.state;

    const newParametrizedWorkout = [...parametrizedWorkout];

    if (repeatId) {
      const repeatStep = newParametrizedWorkout[level].find(
        (step) => step.id === repeatId,
      );
      if (repeatStep) {
        repeatStep.steps = listChange;
      }

      this.props.handleChangeWorkout({
        ...workout,
        parametrizedWorkout: [
          ...JSON.parse(JSON.stringify(newParametrizedWorkout)),
        ],
      });
      return;
    }

    newParametrizedWorkout[level] = listChange;
    newParametrizedWorkout[level] = JSON.parse(
      JSON.stringify(newParametrizedWorkout[level]),
    );

    this.props.handleChangeWorkout({
      ...JSON.parse(JSON.stringify(workout)),
      parametrizedWorkout: [
        ...JSON.parse(JSON.stringify(newParametrizedWorkout)),
      ],
    });
  };

  handleAddStep = (repeatId = null) => {
    const newStep = {
      type: "step",
      action: WorkoutActionTypes.Items["Warm up"],
      variable: WorkoutActionVariables.Items.Time,
      value: null,
      id: new Date().getTime(),
      targetType: WorkoutTargets.Items["No Target"],
      targetValue: null,
      isDeleteOpen: false,
    };

    const { workout } = this.props;
    const { parametrizedWorkout = [] } = workout;
    const { level } = this.state;
    const newParametrizedWorkout = [...parametrizedWorkout];

    if (repeatId) {
      const repeatStep = newParametrizedWorkout[level].find(
        (step) => step.id === repeatId,
      );
      if (repeatStep) {
        repeatStep.steps = [...repeatStep.steps, newStep];
      }
    } else {
      newParametrizedWorkout[level] = [
        ...(newParametrizedWorkout[level] ? newParametrizedWorkout[level] : []),
        newStep,
      ];
    }

    this.props.handleChangeWorkout({
      ...workout,
      parametrizedWorkout: JSON.parse(JSON.stringify(newParametrizedWorkout)),
    });
  };

  handleDuplicateStep = (step, repeatId = null) => {
    const { workout } = this.props;
    const { parametrizedWorkout = [] } = workout;
    const { level } = this.state;
    const newParametrizedWorkout = [...parametrizedWorkout];

    if (repeatId) {
      const repeatStep = newParametrizedWorkout[level].find(
        (rStep) => rStep.id === repeatId,
      );
      if (repeatStep) {
        const duplicatedIndex = repeatStep.steps.findIndex(
          (dStep) => dStep.id === step.id,
        );
        repeatStep.steps.splice(duplicatedIndex + 1, 0, {
          ...step,
          id: new Date().getTime(),
        });
      }
    } else {
      const duplicatedIndex = newParametrizedWorkout[level].findIndex(
        (dStep) => dStep.id === step.id,
      );
      newParametrizedWorkout[level].splice(duplicatedIndex + 1, 0, {
        ...step,
        id: new Date().getTime(),
      });
    }

    this.props.handleChangeWorkout({
      ...workout,
      parametrizedWorkout: JSON.parse(JSON.stringify(newParametrizedWorkout)),
    });
  };

  handleChangeStepSelect = ({ name, value }, stepId, repeatId = null) => {
    if (name) {
      const { workout } = this.props;
      const { parametrizedWorkout = [] } = workout;

      const { level } = this.state;
      const newParametrizedWorkout = JSON.parse(
        JSON.stringify([...parametrizedWorkout]),
      );

      if (repeatId) {
        const repeatStep = newParametrizedWorkout[level].find(
          (step) => step.id === repeatId,
        );
        if (repeatStep) {
          const stepToChange = repeatStep.steps.find((s) => s.id === stepId);
          stepToChange[name] = value;
          if (name === "variable") {
            stepToChange.value = "";
          }
          if (name === "targetType") {
            stepToChange.targetValue = "";
          }
        }

        if (name !== "value" || name !== "targetValue") {
          newParametrizedWorkout[level] = JSON.parse(
            JSON.stringify(newParametrizedWorkout[level]),
          );
        }

        this.props.handleChangeWorkout({
          ...workout,
          ...{
            parametrizedWorkout: JSON.parse(
              JSON.stringify(newParametrizedWorkout),
            ),
          },
        });
        return;
      }

      const stepToChange = newParametrizedWorkout[level].find(
        (s) => s.id === stepId,
      );

      if (stepToChange) {
        stepToChange[name] = value;
        if (name === "variable") {
          stepToChange.value = "";
        }
        if (name === "targetType") {
          stepToChange.targetValue = "";
        }

        if (name !== "value" || name !== "targetValue") {
          newParametrizedWorkout[level] = JSON.parse(
            JSON.stringify(newParametrizedWorkout[level]),
          );
        }

        this.props.handleChangeWorkout({
          ...workout,
          parametrizedWorkout: JSON.parse(
            JSON.stringify(newParametrizedWorkout),
          ),
        });
      }
    }
  };

  handleAfterDelete = () => {
    this.props.handleClose();
    this.handleOpenDelete();
  };

  handleAddRepeat = () => {
    const newStep = {
      type: "repeat",
      times: 2,
      steps: [],
      id: new Date().getTime(),
    };

    const { workout } = this.props;
    const { level } = this.state;
    const { parametrizedWorkout = [] } = workout;

    const newParametrizedWorkout = [...parametrizedWorkout];
    newParametrizedWorkout[level] = [
      ...(newParametrizedWorkout[level] ? newParametrizedWorkout[level] : []),
      newStep,
    ];

    this.props.handleChangeWorkout({
      ...workout,
      parametrizedWorkout: JSON.parse(JSON.stringify(newParametrizedWorkout)),
    });
  };

  handleChangeRepeatTimes = (repeatId, value) => {
    if (value > 1) {
      const { workout } = this.props;
      const { parametrizedWorkout = [] } = workout;

      const newParametrizedWorkout = [...parametrizedWorkout];

      const { level } = this.state;

      const repeatStep = newParametrizedWorkout[level].find(
        (step) => step.id === repeatId,
      );

      if (repeatStep) repeatStep.times = value;

      newParametrizedWorkout[level] = JSON.parse(
        JSON.stringify(newParametrizedWorkout[level]),
      );

      this.props.handleChangeWorkout({
        ...workout,
        parametrizedWorkout: JSON.parse(JSON.stringify(newParametrizedWorkout)),
      });
    }
  };

  handleDeleteStep = (id, repeatId = null) => {
    const { workout } = this.props;
    const { parametrizedWorkout = [] } = workout;
    const { level } = this.state;
    const newParametrizedWorkout = [...parametrizedWorkout];

    if (repeatId) {
      const repeatStep = newParametrizedWorkout[level].find(
        (step) => step.id === repeatId,
      );
      if (repeatStep) {
        repeatStep.steps = repeatStep.steps.filter((step) => step.id !== id);
      }
    } else {
      newParametrizedWorkout[level] = newParametrizedWorkout[level].filter(
        (step) => step.id !== id,
      );
    }

    this.props.handleChangeWorkout({
      ...workout,
      parametrizedWorkout: JSON.parse(JSON.stringify(newParametrizedWorkout)),
    });
  };

  handleStep = (step) => {
    this.setState({
      ...this.state,
      level: step,
    });
  };

  render() {
    const {
      formId,
      saving,
      workout,
      onSelect,
      onSubmit,
      onMultiLangTextChange,
      isEditingOnPlan,
      disabled,
      classes,
      isSmart = false,
      onWorkoutDelete,
    } = this.props;
    const {
      title = {},
      description = {},
      trainingPeriod,
      difficultyLevel,
      workoutType,
      surface,
      estimatedTime,
      parametrizedWorkout = [],
      id,
      plannedDate,
    } = workout || {};
    const isParametrized =
      !difficultyLevel ||
      (parametrizedWorkout &&
        parametrizedWorkout.length &&
        parametrizedWorkout[this.state.level].length);

    return (
      <div className={classes.container}>
        <form id={formId} onSubmit={onSubmit} disabled={saving}>
          <Grid container>
            <Grid item md={isParametrized ? 4 : 12}>
              <WorkoutEditorMultiLangFields
                title={title}
                description={description}
                onChange={onMultiLangTextChange}
                disabled={disabled}
              />

              {!isEditingOnPlan ? (
                <div>
                  <FilterSelect
                    required
                    value={trainingPeriod}
                    name="trainingPeriod"
                    text="Training Period"
                    items={TrainingPeriods.itemsArray}
                    onSelect={
                      !isParametrized
                        ? onSelect
                        : (params) =>
                            onSelect({ ...params, level: this.state.level })
                    }
                    disabled={disabled}
                    width="45%"
                    customStyles
                    customClass={classes.noPadding}
                  />
                </div>
              ) : (
                ""
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {!isEditingOnPlan && difficultyLevel ? (
                  <div>
                    <FilterSelect
                      required
                      value={difficultyLevel}
                      name="difficultyLevel"
                      text="Level"
                      items={DifficultyLevels.itemsArray}
                      onSelect={
                        !isParametrized
                          ? onSelect
                          : (params) =>
                              onSelect({ ...params, level: this.state.level })
                      }
                      disabled={disabled}
                      width="45%"
                      customStyles
                      customClass={classes.noPadding}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div style={{ width: "50%" }}>
                  <FilterSelect
                    required
                    value={workoutType}
                    name="workoutType"
                    text="Workout Type"
                    items={WorkoutTypes.itemsArray}
                    onSelect={
                      !isParametrized
                        ? onSelect
                        : (params) =>
                            onSelect({ ...params, level: this.state.level })
                    }
                    disabled={disabled}
                    width="90%"
                    customStyles
                    customClass={classes.noPadding}
                  />
                </div>

                {!isEditingOnPlan ? (
                  <div>
                    <FilterSelect
                      required
                      value={surface}
                      name="surface"
                      text="Surface"
                      items={Surfaces.itemsArray}
                      onSelect={
                        !isParametrized
                          ? onSelect
                          : (params) =>
                              onSelect({ ...params, level: this.state.level })
                      }
                      disabled={disabled}
                      width="45%"
                      customStyles
                      customClass={classes.noPadding}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div style={{ width: "50%" }}>
                  <FilterSelect
                    required
                    value={
                      Array.isArray(estimatedTime)
                        ? estimatedTime[this.state.level]
                        : estimatedTime
                    }
                    name="estimatedTime"
                    text="Estimated Time"
                    items={EstimatedTimes.itemsArray}
                    onSelect={
                      !isParametrized
                        ? onSelect
                        : (params) =>
                            onSelect({ ...params, level: this.state.level })
                    }
                    disabled={disabled}
                    width="90%"
                    customStyles
                    customClass={classes.noPadding}
                  />
                </div>

                <div style={{ width: "50%" }}>
                  <FilterSelect
                    required
                    value={trainingPeriod}
                    name="trainingPeriod"
                    text="Training Period"
                    items={TrainingPeriods.itemsArray}
                    disabled={disabled}
                    width="90%"
                    customStyles
                    customClass={classes.noPadding}
                    onSelect={
                      !isParametrized
                        ? onSelect
                        : (params) =>
                            onSelect({ ...params, level: this.state.level })
                    }
                  />
                </div>

                <div style={{ width: "50%" }}>
                  <FilterSelect
                    required
                    value={surface}
                    name="surface"
                    text="Surface"
                    items={Surfaces.itemsArray}
                    disabled={disabled}
                    width="90%"
                    customStyles
                    customClass={classes.noPadding}
                    onSelect={
                      !isParametrized
                        ? onSelect
                        : (params) =>
                            onSelect({ ...params, level: this.state.level })
                    }
                  />
                </div>
              </div>
            </Grid>

            {Boolean(isParametrized) && (
              <Grid item md={8}>
                <div className={classes.workoutPane}>
                  <div className={classes.topBar}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        variant="outlined"
                        className={classes.smallMargin}
                        onClick={() => this.handleAddStep()}
                      >
                        + Add Step
                      </Button>
                      <Button
                        variant="outlined"
                        className={classes.smallMargin}
                        onClick={this.handleAddRepeat}
                      >
                        + Add Repeat
                      </Button>

                      <div
                        className={classNames(
                          classes.smallMargin,
                          classes.levelCustom,
                        )}
                      >
                        <Typography variant="body2">
                          WORKOUT LEVEL: LVL {this.state.level + 1}
                        </Typography>
                      </div>
                    </div>

                    <div>
                      {!isNew && (
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="Delete"
                            className={classes.smallMargin}
                            onClick={this.handleOpenDelete}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  <div className={classes.workoutStepsContainer}>
                    {parametrizedWorkout[this.state.level] ? (
                      <ReactSortable
                        list={parametrizedWorkout[this.state.level]}
                        setList={(newState) =>
                          this.handleSetParametrizedWorkout(newState)
                        }
                        handle=".handle"
                      >
                        {parametrizedWorkout[
                          this.state.level
                        ].map((paramStep) =>
                          paramStep.type === "step" ? (
                            <WorkoutStep
                              key={paramStep.id}
                              step={paramStep}
                              onChangeStepSelect={this.handleChangeStepSelect}
                              level={this.state.level}
                              onDeleteStep={this.handleDeleteStep}
                              onDuplicateStep={this.handleDuplicateStep}
                              showActions={true}
                            />
                          ) : (
                            <WorkoutRepeat
                              key={paramStep.id}
                              repeat={paramStep}
                              onSetParametrizedWorkout={
                                this.handleSetParametrizedWorkout
                              }
                              onChangeRepeatTimes={this.handleChangeRepeatTimes}
                              onAddStep={this.handleAddStep}
                              onChangeStepSelect={this.handleChangeStepSelect}
                              level={this.state.level}
                              onDeleteStep={this.handleDeleteStep}
                              onDuplicateStep={this.handleDuplicateStep}
                              showActions={true}
                            />
                          ),
                        )}
                      </ReactSortable>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </form>

        <Dialog
          open={this.state.showDialog}
          onClose={this.handleOpenDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this workout?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                onWorkoutDelete(
                  id,
                  moment(plannedDate, "DD/MM/YYYY"),
                  this.handleAfterDelete,
                )
              }
              color="primary"
            >
              Accept
            </Button>
            <Button onClick={this.handleOpenDelete}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

WorkoutEditorForm.propTypes = {
  formId: PropTypes.string,
  saving: PropTypes.bool,
  workout: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onMultiLangTextChange: PropTypes.func.isRequired,
  isEditingOnPlan: PropTypes.bool,
};

export default connect((store) => {
  return {
    auth: store.auth,
    athletes: store.athletes,
  };
})(withStyles(styles)(memo(WorkoutEditorForm)));
