import React, { useState } from "react";
import BasicAccordion from "../../common/accordion";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VideoPlayer from "./video";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  settings: {
    position: "relative",
    lineHeight: "150%",
  },

  videoLinkIcon: {
    position: "relative",
    width: "149px",
    height: "188px",
    objectFit: "cover",
  },
  namedescriptionChild: {
    position: "relative",
    letterSpacing: "1px",
    lineHeight: "125%",
    textTransform: "uppercase",
    fontWeight: theme.typography.h2.fontWeight,
  },
  professionalTrailRunner: {
    alignSelf: "stretch",
    position: "relative",
    fontSize: theme.typography.h6.fontSize,
    lineHeight: "150%",
    fontFamily: theme.typography.fontFamily,
    color: "#6d6f71",
  },
  namedescription: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "8px",
  },
  instagram: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "9px",
  },
  iconstrava: {
    position: "relative",
    width: "18px",
    height: "18px",
    overflow: "hidden",
    flexShrink: "0",
    objectFit: "cover",
  },
  strava: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "9px",
  },
  socialMedia: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "8px",
    gap: "8px",
    fontSize: theme.typography.h6.fontSize,
    fontFamily: theme.typography.fontFamily,
  },

  details: {
    alignSelf: "stretch",
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "12px 0px",
    gap: "24px",
  },
  coachInfo: {
    width: "390px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: "32px",
    justifyContent: "flex-start",
    padding: "0px 16px",
    boxSizing: "border-box",
    gap: "12px",
    textAlign: "left",
    fontSize: "18px",
    fontFamily: "Oswald",
  },
  coachStory: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "16px",
    fontSize: theme.typography.h5.fontSize,
    fontFamily: theme.typography.fontFamily,
    padding: "16px",
  },
  container: {
    position: "relative",
    height: "200px",
  },

  overlayIcon: {
    position: "absolute",
    background: "#ffffff6a",
    color: "#feffff",
    borderRadius: "100%",
    top: "70%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  page: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "32px",
    textAlign: "center",
    fontSize: "15px",
    color: "#12151b",
    fontFamily: "Roboto",
  },
});

const CoachInfoComponent = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  let [video, setVideo] = useState(false);
  return (
    <div className={styles.page}>
      <div className={styles.coachInfo}>
        {video == false ? (
          <div className={styles.container}>
            <img
              className={styles.videoLinkIcon}
              alt=""
              src={`${process.env.PUBLIC_URL}/rectangulo1.jpeg`}
            />
            <PlayCircleOutlineIcon
              onClick={() => setVideo(true)}
              className={styles.overlayIcon}
              sx={{ fontSize: 45 }}
            />
          </div>
        ) : (
          <VideoPlayer
            videoSource={
              " https://videofilesvertrun.s3.amazonaws.com/Couch+to+trail+-+Francesco-Puppi+-+1.MOV"
            }
          />
        )}

        <div className={styles.details}>
          <div className={styles.namedescription}>
            <div className={styles.namedescriptionChild}>Francesco Puppi</div>
            <div className={styles.professionalTrailRunner}>
              Professional Trail Runner - Nike
            </div>
          </div>
          <div className={styles.socialMedia}>
            <div className={styles.instagram}>
              <img
                className={styles.iconstrava}
                alt=""
                src={`${process.env.PUBLIC_URL}/Icon_insta.png`}
              />
              <div className={styles.settings}>@Francesco.puppi</div>
            </div>
            <div className={styles.strava}>
              <img
                className={styles.iconstrava}
                alt=""
                src={`${process.env.PUBLIC_URL}/Icon_Strava.png`}
              />
              <div className={styles.settings}>Francesco Puppi</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.coachStory}>
        <BasicAccordion
          titles={t("How did he start running?")}
          content={[
            "Lorem ipsum dolor sit amet consectetur. Sem consectetur diam sit fringilla aliquam adipiscing. Eget tristique urna lectus fermentum auctor adipiscing ultrices morbi condimentum. Sit sollicitudin duis arcu curabitur vehicula lacus purus sagittis arcu. Felis elementum potenti adipiscing ac nibh aenean est egestas. Leo volutpat in tellus viverra integer turpis dictumst amet convallis. Dolor mattis nunc orci sed cursus suspendisse semper in. Dignissim eget proin sit nibh massa id. Sed mauris id velit odio adipiscing pellentesque ante mauris.",
          ]}
          link={false}
        />
        <BasicAccordion
          titles={t("How did he start running?")}
          content={[
            "Lorem ipsum dolor sit amet consectetur. Sem consectetur diam sit fringilla aliquam adipiscing. Eget tristique urna lectus fermentum auctor adipiscing ultrices morbi condimentum. Sit sollicitudin duis arcu curabitur vehicula lacus purus sagittis arcu. Felis elementum potenti adipiscing ac nibh aenean est egestas. Leo volutpat in tellus viverra integer turpis dictumst amet convallis. Dolor mattis nunc orci sed cursus suspendisse semper in. Dignissim eget proin sit nibh massa id. Sed mauris id velit odio adipiscing pellentesque ante mauris.",
          ]}
          link={false}
        />
        <BasicAccordion
          titles={t("How did he start running?")}
          content={[
            "Lorem ipsum dolor sit amet consectetur. Sem consectetur diam sit fringilla aliquam adipiscing. Eget tristique urna lectus fermentum auctor adipiscing ultrices morbi condimentum. Sit sollicitudin duis arcu curabitur vehicula lacus purus sagittis arcu. Felis elementum potenti adipiscing ac nibh aenean est egestas. Leo volutpat in tellus viverra integer turpis dictumst amet convallis. Dolor mattis nunc orci sed cursus suspendisse semper in. Dignissim eget proin sit nibh massa id. Sed mauris id velit odio adipiscing pellentesque ante mauris.",
          ]}
          link={false}
        />
      </div>
    </div>
  );
};

export default CoachInfoComponent;
