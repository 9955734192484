import { createTheme } from "@material-ui/core/styles";
import {
  green,
  grey,
  deepOrange,
  red,
  lightBlue,
} from "@material-ui/core/colors";

export default createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      "Avenir",
      "Montserrat",
      "Raleway",
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#FF6327",
      contrastText: "#fff",
      gradient: `linear-gradient(to left, ${deepOrange[900]}, ${deepOrange[400]})`,
    },
    secondary: {
      main: lightBlue[500],
      dark: "#007ac1",
      darkOpacity: function(opacity) {
        return `rgba(0, 122, 193, ${opacity / 100})`;
      },
      contrastText: "#fff",
      gradient: `linear-gradient(to right, #007ac1, #03a9f4)`,
    },
    white: {
      main: "#ffffff",
    },
    success: {
      ...green,
      main: green[500],
      light: green[300],
      dark: green[700],
      contrastText: grey[900],
    },
    error: {
      main: red[800],
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#fff",
    },
    warning: {
      main: red[800],
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#fff",
    },
    background: {
      default: "rgba(224,247,250,0.25)",
      inputText: "rgba(255, 255, 255, 0.8)",
      diagramMobile: "#E3F1FD",
      weeklyProgressMobile: "linear-gradient(to left, #F4541D, #FFAF01)",
      paperAuth: "rgba(0, 0, 0, .68)",
      stepperSelect: "#fafafa",
    },
    text: {
      secondary: grey[400],
      secondaryDark: grey[500],
    },
  },
  overrides: {
    MuiButton: {
      // Name of the component ⚛️ / style sheet
      contained: {
        "&:disabled": {
          opacity: 0.7,
          background: deepOrange[600],
        },
      },
      // label: { // Name of the rule
      //     color: 'white', // Some CSS,

      // }
    },
    MuiInputBase: {
      input: {
        color: "black",
      },
    },
    MuiModal: {
      root: {
        zIndex: 200,
      },
    },
  },
});
