import { API } from "aws-amplify";
import { Storage } from "aws-amplify";
import { ACTIVITY_TYPES } from "../lib/activities-helper";
class SuuntoService {
  static API_NAME = "apidevice";
  static GET_ACTIVITIES_PATH = "/device/vertActivities/:cognito_user_sub";
  static GET_ACTIVITY_PATH = "/device/vertActivity/:cognito_user_sub";
  static POST_ACTIVITY_PATH = "/device/addvertactivity";
  static PATCH_ACTIVITY_PATH = "/device/editvertactivity";
  static DEL_ACTIVITY_PATH = "/device/deleteVertActivity";

  static async getActivities(params) {
    if (params) {
      const { start_date, end_date, cognito_user_sub } = params;
      if (start_date && end_date && cognito_user_sub) {
        let myInit = {
          queryStringParameters: {
            start_date,
            end_date,
          },
        };
        const response = await API.get(
          this.API_NAME,
          this.GET_ACTIVITIES_PATH.replace(
            ":cognito_user_sub",
            cognito_user_sub,
          ),
          myInit,
        );
        const { success, result, error } = response;

        if (success) {
          const data = result.map((activity) => {
            if (!activity.device)
              return { ...activity, device: ACTIVITY_TYPES.VERT_RUN };
            return activity;
          });
          return data;
        } else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      }
    }
  }

  static async getActivity({ workoutId, cognito_user_sub }) {
    if (workoutId && cognito_user_sub) {
      let dataSamples = null;
      try {
        const downloadedDataActivity = await Storage.get(
          `vertactivities/${`${cognito_user_sub}${workoutId.toString()}`}`,
          {
            download: true,
            cacheControl: "no-cache, no-store, must-revalidate",
            expires: 0,
            pragma: "no-cache",
          },
        );

        const dataActivity = await downloadedDataActivity.Body.text();
        dataSamples = JSON.parse(dataActivity);
      } catch (error) {
        throw new Error(error);
      }

      const myInit = {
        queryStringParameters: {
          workoutId,
        },
      };

      const response = await API.get(
        this.API_NAME,
        this.GET_ACTIVITY_PATH.replace(":cognito_user_sub", cognito_user_sub),
        myInit,
      );
      const { success, result, error } = response;

      if (success) return { ...result, ...dataSamples };
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    }
  }

  static async postActivity(activity) {
    await Storage.put(
      `vertactivities/${activity.cognito_user_sub +
        activity.created_at.toString()}`,
      JSON.stringify({
        samples: activity.samples,
        rawSamples: activity.rawSamples,
      }),
    );

    let myInit = {
      body: { activity: { ...activity, samples: [], rawSamples: [] } },
    };

    const response = await API.post(
      this.API_NAME,
      this.POST_ACTIVITY_PATH,
      myInit,
    );
    const { success, result, error } = response;
    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async editActivity(data) {
    const activityData = {
      body: { activity: data },
    };

    const response = await API.patch(
      this.API_NAME,
      this.PATCH_ACTIVITY_PATH,
      activityData,
    );

    const { success, result, error } = response;
    if (success) return result;
    if (error) {
      if (typeof error === typeof "") throw new Error(error);
      throw error;
    }
  }

  static async deleteActivity(data) {
    const activityData = { body: { activity: data } };

    const response = await API.del(
      this.API_NAME,
      this.DEL_ACTIVITY_PATH,
      activityData,
    );
    const { success, error } = response;

    if (success) return response;

    if (error) {
      if (typeof error === typeof "") throw new Error(error);
      throw error;
    }
  }
}

export default SuuntoService;
