import ActionTypes from "../constants/action-types";
import { logEvent, setEventsMetadata } from "../lib/events-helper";
import StravaService from "../services/strava-service";

import { setSuccessMessage, updatePlan } from "./user-plans-action";

const initAuthorization = (param) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.STRAVA_INIT_PENDING });
    try {
      const result = await StravaService.initAuthorization(param);
      dispatch({ type: ActionTypes.STRAVA_INIT_FULFILLED, payload: result });
      setEventsMetadata({ customer_id: result.cognito_user_sub });
      logEvent("connect_GPS_provider");
      dispatch(setSuccessMessage("Sync Strava account"));
      dispatch(
        updatePlan(
          {
            strava_athlete_id: result.id,
            cognito_user_sub: result.cognito_user_sub,
          },
          false,
          false,
        ),
      );
    } catch (exp) {
      dispatch({
        type: ActionTypes.STRAVA_INIT_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getActivities = (param, reload = false) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.STRAVA_LOAD_ACTIVITIES_PENDING,
      payload: reload,
    });
    try {
      const result = await StravaService.getActivities(param);
      dispatch({
        type: ActionTypes.STRAVA_LOAD_ACTIVITIES_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.STRAVA_LOAD_ACTIVITIES_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getActivity = (param) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.STRAVA_GET_ACTIVITY_PENDING });
    try {
      const result = await StravaService.getActivity(param);
      dispatch({
        type: ActionTypes.STRAVA_GET_ACTIVITY_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.STRAVA_GET_ACTIVITY_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const clearActivity = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.STRAVA_CLEAR_ACTIVITY });
  };
};

const clearStravaActivities = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.STRAVA_CLEAR_ACTIVITIES });
  };
};

export {
  initAuthorization,
  getActivities,
  getActivity,
  clearActivity,
  clearStravaActivities,
};
