import React, { useState, useEffect } from "react";
import {
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  addMonths,
  subMonths,
  isToday,
  getDay,
} from "date-fns";
import { useTranslation } from "react-i18next";
import esLocale from "date-fns/locale/es";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Weekdays, { WeekdaysLangKeys } from "../../../constants/weekdays";
import { palette } from "../../../theme/palette";
import { makeStyles, Paper, Box, Typography } from "@material-ui/core";

const DashboardCalendar = ({ trainingDays, races, onSelectDay }) => {
  const { t, i18n } = useTranslation("training");
  const classes = useStyles();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    onSelectDay({
      date: selectedDate,
      workout: findTrainingDay(selectedDate),
      race: findRaceDay(selectedDate),
    });
  }, [selectedDate]);

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";
    const language = i18n.language;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    return (
      <Paper elevation={0} className={`${classes.header} ${classes.row}`}>
        <Box className={`${classes.col} ${classes.colStart}`}>
          <Box className={classes.icon} onClick={prevMonth}>
            <ChevronLeftIcon />
          </Box>
        </Box>
        <Box className={`${classes.col} ${classes.colCenter}`}>
          <Box className={classes.currentMonth}>
            {format(currentMonth, dateFormat, {
              locale: languageDetector === "es" ? esLocale : null,
            })}
          </Box>
        </Box>
        <Box className={`${classes.col} ${classes.colEnd}`}>
          <Box className={classes.icon} onClick={nextMonth}>
            <ChevronRightIcon />
          </Box>
        </Box>
      </Paper>
    );
  };

  const renderDays = () => {
    let days = [];
    let startDate = startOfWeek(currentMonth, 0);

    for (let i = 0; i < 6; i++) {
      days.push(
        <Box className={`${classes.col} ${classes.colCenter}`} key={i}>
          {t(
            WeekdaysLangKeys[
              Weekdays.itemsArray[getDay(addDays(startDate, i))].text
            ],
          ).substring(0, 3)}
        </Box>,
      );
    }

    days = [
      <Box className={`${classes.col} ${classes.colCenter}`} key={6}>
        {t(
          WeekdaysLangKeys[
            Weekdays.itemsArray[getDay(addDays(startDate, 6))].text
          ],
        ).substring(0, 3)}
      </Box>,
      ...days,
    ];

    return <Box className={`${classes.days} ${classes.row}`}>{days}</Box>;
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, 0).setHours(12);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <Box
            className={`${classes.col} ${classes.cell} ${
              !isSameMonth(day, monthStart)
                ? "disabled"
                : isSameDay(day, selectedDate)
                ? "selected"
                : isToday(day)
                ? "today"
                : ""
            }`}
            key={day}
            onClick={() => onDateClick(cloneDay)}
          >
            <Typography className={`number`}>{formattedDate}</Typography>
            {findTrainingDay(day) && !isToday(day) ? (
              <Box
                className={`${assignDotType(day)} ${
                  isSameDay(day, selectedDate) ? "dot-focus" : null
                }`}
              >
                •
              </Box>
            ) : (
              ""
            )}
          </Box>,
        );
        day = addDays(day, 1);
      }
      rows.push(
        <Box className={`${classes.row}`} key={day}>
          {days}
        </Box>,
      );
      days = [];
    }
    return <Box className={`${classes.body}`}>{rows}</Box>;
  };

  const assignDotType = (day) => {
    const foundTraining = trainingDays.find((td) => {
      if (td && td[0] && td[0].plannedDate) {
        const parts = td[0].plannedDate.split("/");
        return isSameDay(
          day,
          new Date(+parts[2], +parts[1] - 1, +parts[0], 12),
        );
      }
      return false;
    });
    if (!foundTraining && !foundTraining.length) return classes.dotUnmarked;

    return foundTraining[0].isDone === true
      ? classes.dotUnmarked
      : classes.dotMarked;
  };

  const findTrainingDay = (day) => {
    const found = trainingDays.find((td) => {
      if (td && td[0] && td[0].plannedDate) {
        const parts = td[0].plannedDate.split("/");
        return isSameDay(
          day,
          new Date(+parts[2], +parts[1] - 1, +parts[0], 12),
        );
      }
      return false;
    });
    return found;
  };

  const findRaceDay = (day) => {
    const found = races.find((race) => {
      if (race.raceDate) {
        const parts = race.raceDate.split("/");
        return isSameDay(
          day,
          new Date(+parts[2], +parts[1] - 1, +parts[0], 12),
        );
      }
      return false;
    });
    return found;
  };

  const onDateClick = (day) => {
    setSelectedDate(day);
    onSelectDay({
      workout: findTrainingDay(day),
      race: findRaceDay(day),
    });
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  return (
    <Paper elevation={0} className={classes.calendarDesktop}>
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </Paper>
  );
};

export default DashboardCalendar;

const useStyles = makeStyles({
  icon: {
    fontFamily: '"Material Icons", serif',
    fontStyle: "normal",
    display: "inline-block",
    verticalAlign: "middle",
    lineHeight: 1,
    textTransform: "none",
    letterSpacing: "normal",
    wordWrap: "normal",
    whiteSpace: "nowrap",
    direction: "ltr",
    WebkitFontSmoothing: "antialiased",
    textRendering: "optimizeLegibility",
    MozOsxFontSmoothing: "grayscale",
    fontFeatureSettings: '"liga"',
  },
  row: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: 40,
    justifyContent: "space-between",
  },
  rowMiddle: {
    alignItems: "center",
  },
  col: {
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: "100%",
  },
  colStart: {
    justifyContent: "flex-start",
    textAlign: "left",
  },
  colCenter: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
  colEnd: {
    justifyContent: "flex-end",
    textAlign: "right",
  },
  calendar: {
    display: "block",
    position: "relative",
    width: "calc(100% - 40px)",
    background: "transparent",
    padding: 6,
    margin: 15,
    borderRadius: 20,
  },
  calendarDesktop: {
    display: "block",
    position: "relative",
    background: "transparent",
    margin: "0 auto 12px",
    borderRadius: 20,
    padding: 6,
  },
  header: {
    fontWeight: 400,
    padding: "0.5em 0",
    fontFamily: "Karla",
    fontSize: 16,
    textAlign: "center",
    marginBottom: 10,
  },
  days: {
    textTransform: "uppercase",
    color: palette.black,
    padding: "0.75em 0",
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
  },
  cell: {
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    background: "transparent",
    transition: "0.25s ease-out",
    textAlign: "center",
    maxHeight: 40,
    maxWidth: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .selected": {},
  },
  body: {
    display: "grid",
    gap: 12,
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 400,
    "& .cell": {
      height: "calc((100vw - 40px) / 7)",
    },
    "& .cell:hover": {
      boxShadow: palette.boxShadow,
      background: "#f9f9f9",
      transition: "0.5s ease-out",
    },

    "& .selected .number, & .today .number, & .race .number": {
      fontWeight: 700,
      padding: "10px 12px",
    },
    "& .selected": {
      boxShadow: palette.boxShadow,
      background: palette.lightBlueHover,
      color: palette.darkBlue,
    },
    "& .today": {
      boxShadow: palette.boxShadow,
      color: palette.primary,
      background: palette.white,
    },
    "& .race .number": {
      color: palette.primary,
      background: palette.white,
    },
    "& .row": {
      borderBottom: "none",
      minHeight: 40,
    },
    "& .row:last-child": {
      borderBottom: "none",
    },
    "& .cell:last-child": {
      borderRight: "none",
    },
    "& .number": {},
    "& .disabled": {
      color: palette.deactivated,
      pointerEvents: "none",
    },
    "& .bg": {
      boxShadow: palette.boxShadow,
      fontWeight: 700,
      lineHeight: 1,
      color: "#1a8fff",
      opacity: 0,
      fontSize: "8em",
      position: "absolute",
      top: "-0.2em",
      right: "-0.05em",
      transition: "0.25s ease-out",
      letterSpacing: "-0.07em",
    },
    "& .cell:hover .bg, & .selected .bg": {
      opacity: 0.05,
      transition: "0.5s ease-in",
    },
    "& .col": {
      flexGrow: 0,
      flexBasis: "calc(100% / 7)",
      width: "calc(100% / 7)",
    },
  },
  currentMonth: {
    textTransform: "capitalize",
    fontStyle: "normal",
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: 16,
  },
  dotMarked: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    color: palette.darkBlue,
    transform: "translate(-50%)",
  },
  dotUnmarked: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    color: palette.deactivated,
    transform: "translate(-50%)",
  },
});
