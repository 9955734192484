const TrainingHoursPerWeek = {
  0: "0",
  2: "2",
  4: "4",
  6: "6",
  8: "8",
  10: "10+",
};

export default {
  Items: TrainingHoursPerWeek,
  itemsArray: Object.keys(TrainingHoursPerWeek).map((key) => {
    return {
      text: TrainingHoursPerWeek[key],
      value: key,
    };
  }),
};
