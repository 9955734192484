import React, { Component, memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  DialogActions,
  Dialog,
  DialogContent,
  Tooltip,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { dateFormat } from "../../../lib/date-helper";
import moment from "moment";
import "moment/min/locales";
import history from "../../../lib/history-helper";
import withWidth from "@material-ui/core/withWidth";
import UpgradeToPro from "../../../components/generals/upgrade-pro-modal";
import classnames from "classnames";
import { logEvent } from "../../../lib/events-helper";
import { connect } from "react-redux";

const styles = (theme) => ({
  container: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      // marginRight: 20,
    },
  },
  paper: {
    background: "#FCFCFC",
    borderRadius: 8,
    boxShadow: "5px 3px 10px rgb(33 81 209 / 10%)",
    padding: "5px 0",
  },
  button: {
    padding: "0",
    fontWeight: "normal",
  },
  menuItem: {
    padding: "6px 8px",
    fontWeight: "normal",
    alignItems: "center",
  },
  menuItemLabel: {
    textTransform: "initial",
    textAlign: "left",
    justifyContent: "left",
  },
  popper: {
    zIndex: 1,
    position: "absolute !important",
    top: "20px !important",
    right: "0 !important",
    width: "200px !important",
    left: "initial !important",
  },
  imgIcon: {
    marginRight: 5,
  },
  textInfoRedo: {
    marginRight: "auto",
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 12,
    },
  },
  redoActions: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  rightButtons: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },
  minWZero: {
    minWidth: "initial",
  },
  card: {
    backgroundColor: "rgba(60, 90, 154, 0.05)",
    borderRadius: "12px",
    padding: "12px",
    boxSizing: "border-box",
    alignSelf: "center",
    margin: "0px 12px 16px 12px",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#FFF",
    },
  },
  cardTitle: {
    textAlign: "center",
    color: "#E16327",
    fontSize: "14px",
    marginBottom: "8px",
  },
  segment: {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  option: {
    fontSize: "10px",
    textAlign: "center",
    alignSelf: "center",
    lineHeight: "14px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
    },
  },
  separator: {
    width: "1px",
    backgroundColor: "#E0E0E0",
    height: "24px",
    margin: "auto 0",
  },
  segmentContent: {
    width: "70%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    alignSelf: "center",
  },
  iconStyle: {
    width: 16,
    height: 16,
    alignSelf: "center",
    marginBottom: "3px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  content: {
    display: "flex",
  },
  proText: {
    position: "absolute",
    top: "5px",
    right: "8px",
    color: "#E16327",
    cursor: "pointer",
  },
});

class DashboardTrainingActionsMenu extends Component {
  state = {
    open: false,
    redoDialogOpen: false,
    addNextDialogOpen: false,
    easyDialogOpen: false,
    restartDialogOpen: false,
    exploreFeatureDialogOpen: false,
  };

  handleToggle = () => {
    this.setState({
      ...this.state,
      open: !this.state.open,
    });
  };

  handleClose = async (id) => {
    const { onRedoWeek, onAddEasyWeek, onRestartPlan } = this.props;

    if (id) {
      switch (id) {
        case "redoweeknow":
          onRedoWeek("now");
          break;
        case "redoweeknext":
          onRedoWeek("next");
          break;
        case "easyweek":
          onAddEasyWeek();
          break;
        case "restart":
          onRestartPlan(this.state.desiredStartDate);
          break;
        default:
          break;
      }
    }

    this.setState({
      ...this.state,
      open: false,
    });
  };

  isInEditMode = (editMode, lastModified) => {
    if (typeof editMode === "boolean" && editMode) {
      const lastModifiedTime = new Date(lastModified);
      const currentTime = new Date();
      const timeDifference = (currentTime - lastModifiedTime) / (1000 * 60);

      return timeDifference < 15;
    }
    return false;
  };

  handleOpenRedo = () => {
    const { hasSubscription, userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { editMode, lastModified } = currentPlan || {};

    if (hasSubscription) {
      if (this.isInEditMode(editMode, lastModified)) {
        return;
      }

      this.setState({
        ...this.state,
        redoDialogOpen: true,
      });
    } else {
      this.setState({
        ...this.state,
        open: false,
        exploreFeatureDialogOpen: true,
      });
    }
  };

  handleCloseRedo = () => {
    this.setState({
      ...this.state,
      redoDialogOpen: false,
    });
  };

  handleOpenEasy = () => {
    const { hasSubscription, userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { editMode, lastModified } = currentPlan || {};

    if (hasSubscription) {
      if (this.isInEditMode(editMode, lastModified)) {
        return;
      }

      this.setState({
        ...this.state,
        easyDialogOpen: true,
      });
    } else {
      this.setState({
        ...this.state,
        open: false,
        exploreFeatureDialogOpen: true,
      });
    }
  };

  handleCloseEasy = () => {
    this.setState({
      ...this.state,
      easyDialogOpen: false,
    });
  };

  handleOpenRestart = () => {
    const { hasSubscription, userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { editMode, lastModified } = currentPlan || {};

    if (hasSubscription) {
      if (typeof editMode === "boolean" && editMode)
        if (this.isInEditMode(editMode, lastModified)) {
          return;
        }

      this.setState({
        ...this.state,
        restartDialogOpen: true,
      });
    } else {
      this.setState({
        ...this.state,
        open: false,
        exploreFeatureDialogOpen: true,
      });
    }
  };

  handleCloseRestart = () => {
    this.setState({
      ...this.state,
      restartDialogOpen: false,
    });
  };

  handleDateChange = (momentObject) => {
    if (momentObject) {
      try {
        this.setState({
          ...this.state,
          desiredStartDate: momentObject.format(dateFormat),
        });
      } catch (exp) {}
    } else {
      this.setState({
        ...this.state,
        desiredStartDate: undefined,
      });
    }
  };

  handleCloseUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
  };

  handleUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
    history.push("/subscription/explore");
  };

  handleCloseX = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      ...this.state,
      open: false,
    });
  };

  openProModal = () => {
    this.setState({
      ...this.state,
      open: false,
      exploreFeatureDialogOpen: true,
    });
  };

  render() {
    const {
      classes,
      t,
      onOpenAdd,
      currentStartDate,
      i18n,
      hasSubscription,
      width,
      userPlans,
    } = this.props;
    const {
      open,
      redoDialogOpen,
      easyDialogOpen,
      restartDialogOpen,
      exploreFeatureDialogOpen,
    } = this.state;

    const { currentPlan } = userPlans || {};
    const { editMode, lastModified } = currentPlan || {};
    const { language } = i18n;
    const momentLocale = moment().locale(language);
    const isInMobileView = width === "md" || width === "sm" || width === "xs";

    const thisMonday = moment().startOf("isoWeek");
    const nextMonday = moment()
      .startOf("isoWeek")
      .add(7, "days");

    const checkWeek = () => {
      const result = currentStartDate
        ? moment(currentStartDate).isAfter(moment().endOf("isoWeek"))
        : false;

      return !result ? true : false;
    };

    return (
      <div className={classes.container}>
        <div className={classes.card}>
          <div>
            {!hasSubscription && !isInMobileView ? (
              <Typography
                className={classnames(classes.cardTitle, classes.proText)}
                onClick={this.openProModal}
              >
                {t("PRO")}
              </Typography>
            ) : null}
          </div>
          <Typography className={classes.cardTitle}>
            {t("Personalize your training")}
          </Typography>

          <div className={classes.content}>
            <Tooltip
              title={t(
                !this.isInEditMode(editMode, lastModified)
                  ? "Add a new workout to your plan"
                  : "Sorry, your coach is editing this plan",
              )}
            >
              <div
                className={classes.segment}
                onClick={() => {
                  if (hasSubscription) {
                    if (!this.isInEditMode(editMode, lastModified)) onOpenAdd();
                  } else {
                    this.setState({
                      ...this.state,
                      open: false,
                      exploreFeatureDialogOpen: true,
                    });
                  }
                }}
              >
                <div className={classes.segmentContent}>
                  <img
                    src={`${process.env.PUBLIC_URL}/add-workout-icon.svg`}
                    alt="add-workout-icon"
                    className={classes.iconStyle}
                  />
                  <Typography className={classes.option}>
                    {t("Add a Workout")}
                  </Typography>
                </div>
                <div className={classes.separator}></div>
              </div>
            </Tooltip>
            <Tooltip
              title={t(
                !this.isInEditMode(editMode, lastModified)
                  ? "Redo this week of training"
                  : "Sorry, your coach is editing this plan",
              )}
            >
              <div
                className={classes.segment}
                onClick={checkWeek() ? this.handleOpenRedo : null}
              >
                <div className={classes.segmentContent}>
                  <img
                    src={`${process.env.PUBLIC_URL}/redo-icon.svg`}
                    alt="redo-icon"
                    className={classes.iconStyle}
                  />
                  <Typography className={classes.option}>
                    {t("Redo this week")}
                  </Typography>
                </div>
                <div className={classes.separator}></div>
              </div>
            </Tooltip>
            <Tooltip
              title={t(
                !this.isInEditMode(editMode, lastModified)
                  ? "Tired this week? add an easy recovery week"
                  : "Sorry, your coach is editing this plan",
              )}
            >
              <div className={classes.segment} onClick={this.handleOpenEasy}>
                <div className={classes.segmentContent}>
                  <img
                    src={`${process.env.PUBLIC_URL}/battery-icon.svg`}
                    alt="battery-icon"
                    className={classes.iconStyle}
                  />
                  <Typography className={classes.option}>
                    {t("Add easy week")}
                  </Typography>
                </div>
                <div className={classes.separator}></div>
              </div>
            </Tooltip>
            <Tooltip
              title={t(
                !this.isInEditMode(editMode, lastModified)
                  ? "Restart your training plan"
                  : "Sorry, your coach is editing this plan",
              )}
            >
              <div className={classes.segment} onClick={this.handleOpenRestart}>
                <div className={classes.segmentContent}>
                  <img
                    src={`${process.env.PUBLIC_URL}/restart-icon.svg`}
                    alt="restart-icon"
                    className={classes.iconStyle}
                  />
                  <Typography className={classes.option}>
                    {t("Restart plan")}
                  </Typography>
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
        <Dialog
          open={redoDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseRedo}
        >
          <DialogContent>
            <Typography variant="body1" className={classes.textInfo}>
              {t("Are you sure you want to redo this week?")}
            </Typography>
          </DialogContent>
          <DialogActions
            classes={{
              root: classes.redoActions,
            }}
          >
            <Typography variant="body1" className={classes.textInfoRedo}>
              {t("Redo this week starting")}:
            </Typography>
            <Button
              onClick={() => {
                this.handleClose("redoweeknow");
                this.handleCloseRedo();
              }}
              color="primary"
            >
              {`${momentLocale
                .localeData()
                .weekdays(thisMonday)
                .substring(0, 3)}
                ${thisMonday.format("D")} ${momentLocale
                .localeData()
                .months(thisMonday)
                .substring(0, 3)}`}
            </Button>
            <Button
              onClick={() => {
                this.handleClose("redoweeknext");
                this.handleCloseRedo();
              }}
              color="primary"
            >
              {`${momentLocale
                .localeData()
                .weekdays(nextMonday)
                .substring(0, 3)}
                ${nextMonday.format("D")} ${momentLocale
                .localeData()
                .months(nextMonday)
                .substring(0, 3)}`}
            </Button>
            <Button onClick={this.handleCloseRedo} color="primary">
              {t("Cancel")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={easyDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseEasy}
        >
          <DialogContent>
            <Typography variant="body1" className={classes.textInfo}>
              {t("Are you sure you want to add an easy week?")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose("easyweek");
                this.handleCloseEasy();
              }}
              color="primary"
            >
              {t("Yes")}
            </Button>
            <Button onClick={this.handleCloseEasy} color="primary">
              {t("No")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={restartDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={this.handleCloseRestart}
        >
          <DialogContent>
            <Typography variant="body1" className={classes.textInfo}>
              {t("When would you like to start your plan?")}
            </Typography>
            <div className={classes.dateContainer}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  value={moment(
                    this.state.desiredStartDate || null,
                    dateFormat,
                  ).toISOString()}
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/AAAA"
                  mask={(value) =>
                    // handle clearing outside if value can be changed outside of the component
                    value
                      ? [
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]
                      : []
                  }
                  minDate={moment()
                    .startOf("day")
                    .startOf("isoWeek")
                    .subtract(4, "weeks")}
                  maxDate={moment()
                    .startOf("day")
                    .startOf("isoWeek")
                    .add(4, "weeks")}
                  autoOk
                  clearable
                  keyboard
                  fullWidth
                  disableOpenOnEnter
                  animateYearScrolling={false}
                  onChange={this.handleDateChange}
                />
              </MuiPickersUtilsProvider>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose("restart");
                this.handleCloseRestart();
              }}
              color="primary"
            >
              {t("Yes")}
            </Button>
            <Button onClick={this.handleCloseRestart} color="primary">
              {t("No")}
            </Button>
          </DialogActions>
        </Dialog>

        <UpgradeToPro
          open={exploreFeatureDialogOpen}
          onClose={this.handleCloseUpgrade}
          list
          title="Paywall Title 2"
          btnText="Try 7 days for free"
        />
      </div>
    );
  }
}

DashboardTrainingActionsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onRedoWeek: PropTypes.func,
  onAddEasyWeek: PropTypes.func,
  onRestartPlan: PropTypes.func,
  onOpenAdd: PropTypes.func,
  currentStartDate: PropTypes.object,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withTranslation("dashboard")(
    withWidth()(
      withStyles(styles, { withTheme: true })(
        memo(DashboardTrainingActionsMenu),
      ),
    ),
  ),
);
