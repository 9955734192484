import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Fab,
  DialogActions,
  Button,
  Typography,
  FormControl,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import moment from "moment";
import { dateFormat } from "../../lib/date-helper";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { connect } from "react-redux";
import { convertToRTFForView } from "../../lib/rtf-helper";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  selectAthlete,
  getAthleteImageUrl,
  patchSelectedAthleteWorkoutViewed,
  athleteData,
} from "../../actions/athletes-action";
import { Routes } from "../../lib/routing-middleware";
import history from "../../lib/history-helper";

const styles = (theme) => ({
  container: {
    background: "#FFFFFF",
    borderRadius: 20,
    padding: 10,
    boxSizing: "borcer-box",
    marginTop: "12px",
  },
  addButtonContainer: {
    textAlign: "right",
    width: "100%",
  },
  addButton: {
    marginTop: 10,
  },
  notesTitleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  notesTitle: {
    color: "#202020",
    fontSize: 20,
    fontWeight: 500,
    marginRight: 50,
  },
  note: {
    background: "#F5F9FF",
    borderRadius: 8,
    padding: "1px 15px",
    width: "auto",
    marginBottom: 10,
    cursor: "pointer",
  },
  noteToday: {
    background: "rgb(213, 255, 159)",
    borderRadius: 8,
    padding: "1px 15px",
    width: "auto",
    marginBottom: 10,
    cursor: "pointer",
  },
  noteText: {
    fontSize: 13,
  },
  noteFooter: {
    display: "flex",
    alignItems: "center",
  },
  noteDateText: {
    marginRight: "auto",
    fontSize: 10,
    paddingRight: 10,
  },
  noteActionIcon: {
    cursor: "pointer",
  },
  datePicker: {
    marginTop: 10,
  },
});

class AthletesNotes extends Component {
  state = {
    isOpenNoteModal: false,
    currentNote: null,
    currentNoteInitial: null,
    isEdit: false,
    isOpenDeleteNoteModal: false,
  };

  handleChangeRTEditor = (event, editor) => {
    const value = editor.getData();
    const { currentNote } = this.state;

    currentNote.text = value;

    this.setState({
      ...this.state,
      currentNote,
    });
  };

  handleCloseNoteModal = (confirm = false) => {
    this.setState({
      ...this.state,
      isOpenNoteModal: false,
    });
    if (confirm) {
      if (this.state.isEdit) {
        const { onEditNote } = this.props;
        onEditNote(this.state.currentNote);
      } else {
        const { onAddNote, type } = this.props;
        const now = new Date().getTime();
        onAddNote({
          ...this.state.currentNote,
          id: now,
          date: type === 0 ? now : this.state.currentNote.date,
        });
      }
    } else {
      if (this.state.currentNoteInitial) {
        this.state.currentNote.text = this.state.currentNoteInitial.text;
        this.state.currentNote.type = this.state.currentNoteInitial.type;
        this.state.currentNote.athlete = this.state.currentNoteInitial.athlete;
        this.state.currentNote.date = this.state.currentNoteInitial.date;
      }
    }
  };

  handleCloseDeleteNoteModal = (confirm = false) => {
    this.setState({
      ...this.state,
      isOpenDeleteNoteModal: false,
    });
    if (confirm) {
      const { onDeleteNote } = this.props;
      onDeleteNote(this.state.currentNote);
    }
  };

  handleAddNote = () => {
    const { athlete, type } = this.props;
    this.setState({
      ...this.state,
      isOpenNoteModal: true,
      isEdit: false,
      currentNote: {
        text: "",
        type: type,
        athlete,
        date: null,
      },
    });
  };

  handleEditNote = (note, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({
      ...this.state,
      isOpenNoteModal: true,
      isEdit: true,
      currentNote: note,
      currentNoteInitial: JSON.parse(JSON.stringify(note)),
    });
  };

  handleDeleteNote = (note, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({
      ...this.state,
      isOpenDeleteNoteModal: true,
      currentNote: note,
    });
  };

  handleDateChange = (momentObject) => {
    const { currentNote } = this.state;

    currentNote.date = momentObject.valueOf();

    this.setState({
      ...this.state,
      currentNote,
    });
  };

  handleAthleteChange = (cognito_user_sub) => {
    const { currentNote } = this.state;

    currentNote.athlete = cognito_user_sub;

    this.setState({
      ...this.state,
      currentNote,
    });
  };

  handleAthleteSelect = (athlete) => {
    const { cognito_user_sub, image, imageUrl } = athlete;
    if (cognito_user_sub) {
      this.props.dispatch(athleteData(athlete));
      this.props.dispatch(patchSelectedAthleteWorkoutViewed(cognito_user_sub));
      if (image && !imageUrl) {
        this.props.dispatch(getAthleteImageUrl({ image, cognito_user_sub }));
      }
      setTimeout(() => history.push(`${Routes.AthletesPage}/`), 100);
    }
  };

  async componentDidMount() {}

  render() {
    const { classes, notes, type, athletes } = this.props;
    const {
      currentNote,
      isOpenNoteModal,
      isEdit,
      isOpenDeleteNoteModal,
    } = this.state;

    const { date, athlete } = currentNote || {};
    const today = moment();

    const { searchResult } = athletes || {};
    const { data } = searchResult;

    return (
      <div className={classes.container}>
        <div className={classes.notesTitleContainer}>
          <Typography variant="body1" className={classes.notesTitle}>
            {type === 0 ? "Notes" : "Reminders"}
          </Typography>

          <div className={classes.addButtonContainer}>
            <Fab
              color="primary"
              aria-label="search"
              size="small"
              onClick={this.handleAddNote}
              className={classes.addButton}
            >
              <AddIcon fontSize="small" />
            </Fab>
          </div>
        </div>

        {notes.map((note) => {
          const athlete = data.find((a) => a.cognito_user_sub === note.athlete);
          const { currentTraining } = athlete || {};
          const { startingDate } = currentTraining || {};

          return (
            <div
              className={
                type === 1 && moment(note.date).isSame(today, "day")
                  ? classes.noteToday
                  : classes.note
              }
              key={note.id}
              onClick={() => {
                if (type === 1 && note.athlete && note.athlete !== "reminder") {
                  this.handleAthleteSelect({ ...athlete, startingDate });
                }
              }}
            >
              <Typography
                variant="body1"
                className={classes.noteText}
                dangerouslySetInnerHTML={convertToRTFForView(note.text)}
              ></Typography>
              <div className={classes.noteFooter}>
                <Typography variant="body1" className={classes.noteDateText}>
                  {moment(note.date).format(dateFormat)}
                </Typography>
                {type === 1 && note.athlete && note.athlete !== "reminder" ? (
                  <Typography variant="body1" className={classes.noteDateText}>
                    {athlete && athlete.cognito_user_name
                      ? athlete.cognito_user_name
                      : ""}
                  </Typography>
                ) : (
                  ""
                )}
                <div className={classes.noteActions}>
                  <EditIcon
                    onClick={(e) => this.handleEditNote(note, e)}
                    className={classes.noteActionIcon}
                    fontSize="small"
                  />{" "}
                  <DeleteIcon
                    onClick={(e) => this.handleDeleteNote(note, e)}
                    className={classes.noteActionIcon}
                    fontSize="small"
                  />
                </div>
              </div>
            </div>
          );
        })}

        <Dialog
          open={isOpenNoteModal}
          onClose={() => this.handleCloseNoteModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {isEdit ? "Edit" : "Add"} {type === 0 ? "Note" : "Reminder"}
          </DialogTitle>
          <DialogContent>
            <CKEditor
              editor={ClassicEditor}
              config={{
                link: {
                  decorators: {
                    openInNewTab: {
                      mode: "manual",
                      label: "Open in a new tab",
                      attributes: {
                        target: "_blank",
                        rel: "noopener noreferrer",
                      },
                    },
                  },
                },
              }}
              data={currentNote ? currentNote.text : ""}
              onChange={this.handleChangeRTEditor}
              required
            />

            {type === 1 ? (
              <>
                <FormControl fullWidth className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      className={classes.datePicker}
                      value={moment(date || null).toISOString()}
                      format="DD/MM/YYYY"
                      placeholder="DD/MM/AAAA"
                      mask={(value) =>
                        // handle clearing outside if value can be changed outside of the component
                        value
                          ? [
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]
                          : []
                      }
                      autoOk
                      clearable
                      keyboard
                      fullWidth
                      disableOpenOnEnter
                      animateYearScrolling={false}
                      onChange={this.handleDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>On Athlete</InputLabel>
                  <NativeSelect
                    value={athlete}
                    onChange={(event) =>
                      this.handleAthleteChange(event.target.value)
                    }
                  >
                    <option aria-label="None" value="" />
                    {data.map((a, index) => (
                      <option value={a.cognito_user_sub} key={index}>
                        {a.cognito_user_name}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleCloseNoteModal(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.handleCloseNoteModal(true)}
              color="primary"
            >
              {isEdit ? "Edit" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isOpenDeleteNoteModal}
          onClose={() => this.handleCloseDeleteNoteModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Delete {type === 0 ? "Note" : "Reminder"}?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => this.handleCloseDeleteNoteModal(false)}
              color="primary"
            >
              No
            </Button>
            <Button
              onClick={() => this.handleCloseDeleteNoteModal(true)}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AthletesNotes.propTypes = {
  classes: PropTypes.object.isRequired,
  notes: PropTypes.array,
  onAddNote: PropTypes.func,
  onEditNote: PropTypes.func,
  onDeleteNote: PropTypes.func,
  athlete: PropTypes.string,
  type: PropTypes.number,
};

export default connect((store) => {
  return {
    athletes: store.athletes,
    userPlans: store.userPlans,
  };
})(withStyles(styles)(AthletesNotes));
