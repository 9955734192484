import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Slider, Typography, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import classNames from "classnames";

import PlanFieldTransition from "./plan-field-transition";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    borderRadius: 6,
    cursor: "pointer",
    height: "100%",
    margin: "auto",
    marginBottom: theme.spacing.unit * 5,
  },
  gridItem: {
    paddingTop: theme.spacing.unit * 5,
    paddingRight: 16,
    paddingLeft: 16,
  },
  widthSmall: {
    width: 200,
  },
  widthMedium: {
    width: 310,
  },
  labelParent: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 5,
  },
  buttonR: {
    marginTop: theme.spacing.unit * 2,
    borderRadius: "10px",
    marginLeft: "auto",
    display: "block",
  },
});

class PlanFieldSliderSelect extends Component {
  // This Slider is very specific to Train Hours Per Week, needs to be more standarized if used for other questions

  state = {
    value: 0,
  };

  handleClick = () => {
    const { id, onSelect } = this.props;
    if (onSelect)
      onSelect([
        {
          id,
          value:
            this.state.value < 3 ? "3" : this.state.value < 5 ? "4,5" : "6,7",
        },
        { id: `${id}_raw`, value: this.state.value },
      ]);
  };

  handleSetValue = (value) => {
    this.setState({
      value,
    });
  };

  render() {
    const {
      xs,
      sm,
      md,
      lg,
      xl,
      id,
      classes,
      min,
      max,
      selectedValue,
      t,
    } = this.props;

    return (
      <PlanFieldTransition>
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          className={classes.gridItem}
        >
          <div className={classes.labelParent}>
            <Typography variant="body2">
              {t("Weekly hours of training", { ns: "layout" })}
            </Typography>
            <Typography variant="body2">
              {this.state.value || 0}h{this.state.value === max ? "+" : ""}
            </Typography>
          </div>

          <div id={id} className={classes.container}>
            <Slider
              value={this.state.value}
              aria-labelledby="continous-slider"
              valueLabelDisplay="off"
              onChange={(event, value) => this.handleSetValue(value)}
              min={min}
              max={max}
              color="primary"
            />
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={this.handleClick}
            className={classes.buttonR}
          >
            {t("Next")}
          </Button>
        </Grid>
      </PlanFieldTransition>
    );
  }
}

PlanFieldSliderSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  theme: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  selectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
PlanFieldSliderSelect.defaultProps = {
  items: [],
};

export default withTranslation(
  "constants",
  "layout",
)(withStyles(styles, { withTheme: true })(PlanFieldSliderSelect));
