import React, { useEffect } from "react";
import {
  Box,
  Button,
  LinearProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { updatePlan } from "../../actions/user-plans-action";
import {
  clearActivityCoros,
  initAuthorizationCoros,
} from "../../actions/coros-action";
import { corosAuthorizationUrl } from "../../constants/watches-auth-uri";
import bugsnagClient from "../../lib/bugsnag-client";
import { palette } from "../../theme/palette";
import CorosIcon from "../icons/CorosIcon";
import CustomButton from "./v2/CustomButton";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import history from "../../lib/history-helper";

const CorosConnect = ({ v3 = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("settings");
  const { device } = useParams();
  const location = useLocation();
  const { userPlans, coros, subscriptions } = useSelector((state) => state);
  const { currentPlan } = userPlans;
  const { initializing, connected } = coros;
  const { loadingLatest } = subscriptions;
  const { cognito_user_sub, coros_auth } = currentPlan || "";
  const isCorosConnected = (currentPlan && currentPlan.coros_auth) || connected;

  const loadQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = value;
    }
    return params;
  };

  const load = (cognito_user_sub) => {
    try {
      const params = loadQueryParams();

      if (isCorosConnected) return false;

      const deviceParam = device
        ? device === "coros"
        : location.pathname.includes("coros");

      if (deviceParam) {
        if (Object.keys(params).length <= 0) {
          throw new Error("No query params found");
        }
        if (params.error)
          throw new Error(`Error parameter found ${JSON.stringify(params)}`);

        if (!params.code)
          throw new Error(`Code parameter not found ${JSON.stringify(params)}`);

        dispatch(initAuthorizationCoros(cognito_user_sub, params.code));
      }
    } catch (error) {
      bugsnagClient.notify(error);
    }
  };

  const connect = () => {
    history.push("/dashboard");
    window.open(corosAuthorizationUrl, "_self");
  };

  const disconnect = () => {
    if (!coros_auth) return false;

    dispatch(
      updatePlan({
        cognito_user_sub,
        coros_auth: null,
      }),
    );
    dispatch(clearActivityCoros());
  };

  useEffect(() => {
    if (cognito_user_sub) load(cognito_user_sub);
  }, [cognito_user_sub]);

  if (v3) {
    return (
      <>
        <Box className={classes.boxDevice}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <CorosIcon />
              <Typography variant="body1" className={classes.connectTitleV2}>
                {"Coros"}
              </Typography>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                gap: 5,
              }}
            >
              {(userPlans.loading && !initializing) || loadingLatest ? (
                <CustomButton
                  typeStyle="disabled"
                  color="default"
                  variant="contained"
                  size="small"
                  className={classes.buttonConnect}
                >
                  <CircularProgress size={14} />
                </CustomButton>
              ) : (
                <CustomButton
                  typeStyle={
                    isCorosConnected && !initializing ? "secondary" : "primary"
                  }
                  color="default"
                  variant="contained"
                  size="small"
                  className={classes.buttonConnect}
                  onClick={
                    isCorosConnected && !initializing ? disconnect : connect
                  }
                >
                  {isCorosConnected && !initializing
                    ? t("settings.watchDisconnect")
                    : t("settings.watchConnect")}
                </CustomButton>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <div className={classes.connectContainer}>
      <div className={classes.connectFlexContainer}>
        <div className={classes.connectTextContainer}>
          <Typography variant="body1" className={classes.connectTitle}>
            {t("Connect with Coros")}
          </Typography>
          {!userPlans.loading || !loadingLatest ? (
            <div className={classes.connectText}>
              <Typography variant="body1">
                {t("Status")}
                {": "}
                {!initializing ? (
                  isCorosConnected ? (
                    <span style={{ color: "green" }}>{t("Connected")}</span>
                  ) : (
                    t("Not connected")
                  )
                ) : (
                  t("Connecting")
                )}
              </Typography>
            </div>
          ) : (
            ""
          )}
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/coros.png`}
          alt=""
          className={classes.corosLogo}
        />
      </div>
      {(userPlans.loading && !initializing) || loadingLatest ? (
        <LinearProgress color="primary" variant="indeterminate" />
      ) : isCorosConnected && !initializing ? (
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonConnect}
          style={{ backgroundColor: "#c9927d" }}
          onClick={disconnect}
        >
          {t("Disconnect Coros")}
        </Button>
      ) : (
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonConnect}
          onClick={connect}
        >
          {t("Connect Coros")}
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  boxDevice: {
    display: "flex",
    flexDirection: "column",
    padding: "4px 24px",
  },
  sync: {
    //styleName: p2-r;
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
    color: palette.darkGray,
  },
  connectTitleV2: {
    //styleName: p1-b;
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 700,
    color: palette.black,
  },
  corosLogo: {
    width: 56,
  },

  connectContainer: {
    background: "#FFFFFF",
    borderRadius: 10,
    padding: 25,
    maxWidth: 300,
    marginBottom: 20,
    textAlign: "center",
  },

  connectTitle: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 20,
    color: "#202020",
    textAlign: "left",
  },

  connectText: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#202020",
    textAlign: "left",
  },

  connectFlexContainer: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
  },

  buttonConnect: {
    margin: "10px auto",
    boxShadow: "none",
    borderRadius: 50,
    width: 105,
    height: 30,
    fontSize: 14,
    padding: "4px 24px",
  },
});

export default CorosConnect;
