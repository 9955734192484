import React from "react";
import { Box, Typography } from "@mui/material";
import Logo from "../v2/Logo";
import CustomButton from "../v2/CustomButton";

export const ErrorFallbackDefault = ({ error }) => (
  <Box style={contentError}>
    <Box marginY={2}>
      <Logo width={80} />
    </Box>

    <Box
      marginY={2}
      display={"flex"}
      flexDirection={"column"}
      gap={"10px"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography style={textWrong} fontFamily={"Karla"}>
        ¡Ups! Sorry.. <br /> Something it's wrong
      </Typography>
      <Typography fontWeight={"200"} fontFamily={"Karla"} component={"span"}>
        {error.message}
      </Typography>
      <Typography
        fontWeight={"200"}
        fontSize={"13px"}
        fontFamily={"Karla"}
        component={"span"}
      >
        v3.3.1
      </Typography>
    </Box>

    <Box marginY={4}>
      <CustomButton
        typeStyle="primary"
        onClick={() => (window.location.href = "/")}
        style={{ textTransform: "capitalize" }}
      >
        Try again
      </CustomButton>
    </Box>
  </Box>
);

const contentError = {
  display: "block",
  width: "-webkit-fill-available",
  minHeight: "auto",
  padding: "20px",
  textAlign: "center",
  fontFamily: "system-ui",
};
const textWrong = {
  fontWeight: 700,
  textAlign: "center",
};
