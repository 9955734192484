export const icons = {
  Speedwork: "speedwork-icon.svg",
  Corework: "conditioning-icon.svg",
  Recovery: "day-off-icon.svg",
  EasyRun: "run-icon.svg",
  LongRun: "run-icon.svg",
  RecoveryRun: "run-icon.svg",
  UserCreated: "user-created-icon.svg",
  Strength: "cross-icon.svg",
  ClimbWorkout: "climb-icon.svg",
  DescendWorkout: "climb-icon.svg",
  Tempo: "run-icon.svg",
  Fartlek: "run-icon.svg",
  NoWorkout: "noworkout-icon.svg",
};

export const colors = {
  Speedwork: "rgba(255, 99, 39, 0.5)",
  Corework: "rgba(87, 143, 204, 0.5)",
  Recovery: "rgba(158, 158, 158, 0.5)",
  EasyRun: "rgba(0, 173, 150, 0.5)",
  LongRun: "rgba(26, 98, 155, 0.5)",
  RecoveryRun: "rgba(0, 173, 150, 0.5)",
  UserCreated: "rgba(155, 81, 224, 0.5)",
  Strength: "rgba(255, 214, 0, 0.5)",
  NoWorkout: "rgba(155, 81, 224, 0.5)",
};

export const types = {
  Speedwork: "Speedwork",
  LongRun: "Long run",
  ClimbWorkout: "Uphill workout",
  DescendWorkout: "Downhill workout",
  Tempo: "Tempo run",
  Fartlek: "Fartlek workout",
  Recovery: "Recovery",
  Strength: "Cross training",
  Corework: "Conditioning workout",
  EasyRun: "Easy workout",
  UserCreated: "User created",
};
