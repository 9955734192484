import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Typography, Box } from "@material-ui/core";
import moment from "moment";
import withWidth from "@material-ui/core/withWidth";
import { withTranslation } from "react-i18next";
import "moment/min/locales";
import { convertUnits, round, getPace } from "../../lib/units-helper";
import history from "../../lib/history-helper";
import { connect } from "react-redux";
import DashboardActivityDetails from "./dashboard-recent-activities/dashboard-activity-details";
import DashboardActivityShare from "./dashboard-recent-activities/dashboard-activity-share";
import { logEvent, setEventsMetadata } from "../../lib/events-helper";
import { Route } from "react-router-dom";
import AllActivities from "./lists/all-activities";
import {
  clearCurrentUserActivity,
  getUserActivity,
} from "../../actions/user-plans-action";
import Reactions from "../generals/reactions";
import NewActivityModal from "../generals/new-activity-modal";
import { DeleteActivity } from "../generals/delete-activity";

const styles = (theme) => ({
  container: {
    "&::-webkit-scrollbar ": {
      display: "none",
    },

    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",
  },

  containerColumn: {
    overflowY: "scroll",
    height: "100vh",
  },

  activityContainer: {
    background: "#FFFFFF",
    borderRadius: 12,
    padding: 16,
    display: "inline-block",
    margin: "0px 0 12px 12px",
    minWidth: "calc(100% - 64px)",
    maxWidth: "calc(100% - 64px)",
    verticalAlign: "top",
    whiteSpace: "break-spaces",
    minHeight: 180,
    cursor: "pointer",

    "&:last-child": {
      marginRight: 12,
    },
  },

  recentActivity: {
    fontWeight: "normal",
    fontSize: 14,
    fontFamily: "Avenir",
    marginBottom: 12,
    marginRight: "auto",
  },

  activityName: {
    textTransform: "uppercase",
    color: "#E16327",
    fontFamily: "Avenir",
    fontWeight: 900,
    fontSize: 18,
  },

  activityDate: {
    color: "#9E9E9E",
    fontWeight: "normal",
    fontSize: 14,
    fontFamily: "Avenir",
  },

  activityStats: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 24,
  },

  activityStat: {
    textAlign: "center",
  },

  activityStatValue: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "Avenir",
  },

  acitivityStatLabel: {
    fontWeight: "normal",
    fontSize: 14,
    fontFamily: "Avenir",
  },

  activityMainCont: {
    display: "flex",
    alignItems: "start",
  },

  activityNameCont: {
    marginRight: "auto",
  },

  activityIcon: {
    marginLeft: 12,
    width: 26,
  },

  vDivider: {
    borderLeft: "1px solid #E0E0E0",
    height: 24,
  },

  titleCont: {
    display: "flex",
    alignItems: "center",
  },

  syncTracker: {
    fontWeight: 400,
    fontSize: 14,
    color: "#FF6327",
  },

  addButtonContainer: {
    marginLeft: "10px",
    marginRight: "10px",
    cursor: "pointer",
  },
  titleLabel: {
    margin: "12px 12px 4px 12px",
    textAlign: "right",
    color: "#E16327",
    fontSize: 14,
    cursor: "pointer",
  },
  reactionMargin: {
    marginTop: "13px",
  },
  addTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 0px 16px 0",
    cursor: "pointer",
    "& img": {
      marginRight: "12px",
    },
  },
  manual: {
    color: "#E16327",
    fontWeight: "bold",
    fontSize: 14,
  },
});

class DashboardRecentActivities extends Component {
  state = {
    current: null,
    currentToShare: null,
    showForm: false,
  };

  componentDidMount() {
    const {
      strava,
      garmin,
      vertActivities,
      suunto,
      isColumn = false,
      healthKit,
      coros,
    } = this.props;

    const currentDevice =
      vertActivities && vertActivities.currentActivity
        ? "vert.run"
        : garmin && garmin.currentActivity
        ? "garmin"
        : suunto && suunto.currentActivity
        ? "suunto"
        : coros && coros.currentActivity
        ? "coros"
        : strava && strava.currentActivity
        ? "strava"
        : healthKit && healthKit.currentActivity
        ? "healthKit"
        : null;

    if (!isColumn) {
      this.setState({
        ...this.state,
        current: currentDevice,
      });

      setTimeout(() => {
        const racElement = document.getElementById("recentActivitiesContainer");

        if (racElement) {
          setTimeout(() => {
            const { activities } = this.props;
            racElement.scrollTo({
              left: racElement.offsetWidth * activities.length,
            });
          }, 10);
        }
      }, 100);
    }
  }

  componentDidUpdate(prevProps) {
    const { activities: prevActivities } = prevProps;
    const { activities } = this.props;
    if (activities.length && !prevActivities.length) {
      setTimeout(() => {
        const racElement = document.getElementById("recentActivitiesContainer");
        if (racElement) {
          racElement.scrollTo({
            left: racElement.offsetWidth * activities.length,
          });
        }
      }, 100);
    }
  }

  openSettings = () => {
    history.push("/profile");
  };

  openActivity = (activity) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    setEventsMetadata({ customer_id: currentPlan.cognito_user_sub });
    logEvent("open_activity");

    this.props.dispatch(getUserActivity(activity, currentPlan));
    const { device: type } = activity;

    this.setState({
      ...this.state,
      current: type,
    });
  };

  openShareDirect = (activity) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(getUserActivity(activity, currentPlan));
    const { device: type } = activity;
    this.setState({
      ...this.state,
      currentToShare: type,
    });
  };

  handleOpenShare = (type) => {
    this.setState({
      ...this.state,
      currentToShare: type,
    });
  };

  goToAll() {
    history.push("/dashboard/all-activities");
  }

  handleDate(date) {
    const { language } = this.props.i18n;
    const momentLocale = moment().locale(language);
    return `${momentLocale
      .localeData()
      .weekdays(date)
      .substr(0, 3)}, ${momentLocale
      .localeData()
      .months(date)
      .substr(0, 3)} ${date.format("D")}`;
  }

  handleShowForm = () => {
    this.setState({ ...this.state, showForm: !this.state.showForm });
  };

  checkReactions = (activityDate) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { currentTraining } = currentPlan || {};
    const { days = [] } = currentTraining || {};

    const result = days.find((item) => {
      if (item) {
        const difference = moment(item[0].plannedDate, "DD/MM/YYYY").diff(
          activityDate,
          "days",
        );
        if (Math.floor(difference === 0)) return item;
      }
    });

    return result && result.length ? result[0].reaction : [];
  };

  render() {
    const {
      classes,
      t,
      i18n,
      units,
      noTracker,
      isColumn = false,
      userPlans,
      width,
      activities,
    } = this.props;

    const { currentPlan } = userPlans;

    const { current, currentToShare } = this.state;

    const { language } = i18n;
    const momentLocale = moment().locale(language);
    const isInMobileView = width === "sm" || width === "xs";

    activities.sort((a, b) => {
      return isColumn
        ? moment(a.start_date).unix() - moment(b.start_date).unix()
        : moment(a.start_date).unix() - moment(b.start_date).unix();
    });

    return (
      <>
        {activities && activities.length && isInMobileView && !isColumn ? (
          <Typography className={classes.titleLabel} onClick={this.goToAll}>
            {t("See all activities")}
          </Typography>
        ) : null}
        <div
          className={isColumn ? classes.containerColumn : classes.container}
          id="recentActivitiesContainer"
        >
          {(noTracker
            ? [
                {
                  name: "",
                  start_date_local: 0,
                  distance: 0,
                  total_elevation_gain: 0,
                  type: "",
                  moving_time: 0,
                  id: null,
                },
              ]
            : activities
          ).map((activity) => {
            const name = activity.name
              ? activity.name
              : activity.activityName
              ? activity.activityName
              : "";

            const date = moment(
              activity.start_date_local
                ? activity.start_date_local
                : activity.start_date,
            );

            const distance = convertUnits(
              activity.distance,
              "kms",
              units,
              "distance",
            );

            const elevation = round(
              convertUnits(
                activity.total_elevation_gain,
                "kms",
                units,
                "elevation",
              ),
              1,
            );

            let icon = "timer_default_icon";
            const activityType = activity.type
              ? activity.type
              : activity.activityType;
            if (
              activityType &&
              activityType.toLowerCase().includes("run") &&
              !activityType.toLowerCase().includes("transition")
            ) {
              icon = "icon_activity_run";
            }
            if (
              activityType &&
              (activityType.toLowerCase().includes("ride") ||
                activityType.toLowerCase().includes("bik") ||
                activityType.toLowerCase().includes("cycl")) &&
              !activityType.toLowerCase().includes("transition")
            ) {
              icon = "icon_activity_bike";
            }
            if (
              activityType &&
              activityType.toLowerCase().includes("swim") &&
              !activityType.toLowerCase().includes("transition")
            ) {
              icon = "icon_activity_swim";
            }
            if (
              activityType &&
              (activityType.toLowerCase().includes("walk") ||
                activityType.toLowerCase().includes("hik")) &&
              !activityType.toLowerCase().includes("transition")
            ) {
              icon = "icon_activity_hike";
            }

            if (
              activityType &&
              (activityType.toLowerCase().includes("crossfit") ||
                activityType.toLowerCase().includes("weight") ||
                activityType.toLowerCase().includes("yoga") ||
                activityType.toLowerCase().includes("strength") ||
                activityType.toLowerCase().includes("fitness")) &&
              !activityType.toLowerCase().includes("transition")
            ) {
              icon = "icon_activity_strength";
            }

            const time = activity.moving_time
              ? activity.moving_time
              : activity.elapsed_time;

            const pace = getPace(time, distance);

            const h = time ? Math.floor(time / 3600) : 0;
            const m = time ? Math.floor((time % 3600) / 60) : 0;
            const timeStr = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;

            return (
              <>
                <div
                  className={classes.activityContainer}
                  key={
                    activity.workoutKey
                      ? activity.workoutKey
                      : activity.activityId
                      ? activity.activityId
                      : activity.id
                  }
                  style={{
                    minHeight: noTracker ? "initial" : 180,

                    minWidth:
                      activities.length === 1 || noTracker
                        ? "calc(100% - 54px)"
                        : "calc(100% - 64px)",

                    maxWidth:
                      activities.length === 1 || noTracker
                        ? "calc(100% - 54px)"
                        : "calc(100% - 64px)",
                  }}
                  onClick={() =>
                    isColumn
                      ? this.openShareDirect(activity)
                      : this.openActivity(activity)
                  }
                >
                  <div className={classes.titleCont}>
                    <Typography
                      variant="body1"
                      className={classes.recentActivity}
                      style={{
                        marginBottom: noTracker ? "initial" : 12,
                      }}
                    >
                      {t("Recent Activity")}
                    </Typography>

                    {noTracker ? (
                      <Typography
                        variant="body1"
                        className={classes.syncTracker}
                        onClick={() => this.openSettings()}
                      >
                        {t("Sync your activity tracker", {
                          ns: "dashboard",
                        })}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body1"
                        className={classes.syncTracker}
                        style={{
                          marginBottom: 12,
                        }}
                      >
                        {t(isColumn ? "Share activity" : "See details", {
                          ns: "dashboard",
                        })}
                      </Typography>
                    )}

                    {noTracker ? (
                      <div className={classes.addButtonContainer}>
                        <img
                          src={`${process.env.PUBLIC_URL}/icon-add-nr.svg`}
                          alt=""
                          onClick={() => this.openSettings()}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {!noTracker ? (
                    <div className={classes.activityMainCont}>
                      <div className={classes.activityNameCont}>
                        <Typography
                          variant="body1"
                          className={classes.activityName}
                        >
                          {name}
                        </Typography>

                        <Typography
                          variant="body1"
                          className={classes.activityDate}
                        >
                          {date.isValid()
                            ? this.handleDate(date)
                            : this.handleDate(moment())}
                        </Typography>
                      </div>

                      <img
                        src={`${process.env.PUBLIC_URL}/${icon}.svg`}
                        alt=""
                        className={classes.activityIcon}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {!noTracker ? (
                    <>
                      <div className={classes.activityStats}>
                        <div className={classes.activityStat}>
                          <Typography
                            variant="body1"
                            className={classes.activityStatValue}
                          >
                            {`${pace.minutes}:${(pace.seconds < 10 ? "0" : "") +
                              pace.seconds}`}
                            /{units === "miles" ? `${t("mile")}` : `${t("km")}`}
                          </Typography>

                          <Typography
                            variant="body1"
                            className={classes.activityStatLabel}
                          >
                            {t("avg pace")}
                          </Typography>
                        </div>

                        <div className={classes.vDivider}></div>

                        <div className={classes.activityStat}>
                          <Typography
                            variant="body1"
                            className={classes.activityStatValue}
                          >
                            {t("valueUnit", {
                              value: round(distance / 1000, 1),
                              unit: units === "miles" ? ` ${t("miles")}` : "km",
                            })}
                          </Typography>

                          <Typography
                            variant="body1"
                            className={classes.activityStatLabel}
                          >
                            {t("distance")}
                          </Typography>
                        </div>

                        <div className={classes.vDivider}></div>

                        <div className={classes.activityStat}>
                          <Typography
                            variant="body1"
                            className={classes.activityStatValue}
                          >
                            {t("valueUnit", {
                              value: elevation,
                              unit: units === "miles" ? ` ${t("feet")}` : "m",
                            })}
                          </Typography>

                          <Typography
                            variant="body1"
                            className={classes.activityStatLabel}
                          >
                            {t("elevation")}
                          </Typography>
                        </div>

                        <div className={classes.vDivider}></div>

                        <div className={classes.activityStat}>
                          <Typography
                            variant="body1"
                            className={classes.activityStatValue}
                          >
                            {timeStr}
                          </Typography>

                          <Typography
                            variant="body1"
                            className={classes.activityStatLabel}
                          >
                            {t("time")}
                          </Typography>
                        </div>
                      </div>
                      <Reactions
                        reactions={this.checkReactions(date) || []}
                        className={classes.reactionMargin}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  {activity.device === "vert.run" && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        zIndex: 9999,
                      }}
                    >
                      <DeleteActivity activity={activity} />
                    </Box>
                  )}
                </div>
              </>
            );
          })}

          {current && (
            <DashboardActivityDetails
              isOpen={current}
              onClose={() => {
                this.setState({
                  ...this.state,
                  current: null,
                });
                this.props.dispatch(clearCurrentUserActivity());
              }}
              onOpenShare={this.handleOpenShare}
              activities={activities}
            ></DashboardActivityDetails>
          )}

          {currentToShare && (
            <DashboardActivityShare
              isOpen={currentToShare}
              onClose={(sharing = false) => {
                this.setState({
                  ...this.state,
                  currentToShare: null,
                });
                if (isColumn && !sharing) {
                  this.props.dispatch(clearCurrentUserActivity());
                }
              }}
              isColumn={isColumn}
            ></DashboardActivityShare>
          )}
        </div>
        {!activities.length && (
          <div className={classes.addTitle} onClick={this.handleShowForm}>
            <img src={`${process.env.PUBLIC_URL}/orange_plus.svg`}></img>
            <Typography className={classes.manual} variant="body1">
              {t("Add a manual activity")}
            </Typography>
          </div>
        )}

        <NewActivityModal
          open={this.state.showForm}
          onClose={this.handleShowForm}
        />
        <Route path="/dashboard/all-activities" component={AllActivities} />
      </>
    );
  }
}

DashboardRecentActivities.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    strava: store.strava,
    garmin: store.garmin,
    suunto: store.suunto,
    vertActivities: store.vertActivities,
    userPlans: store.userPlans,
    healthKit: store.healthKit,
    coros: store.coros,
  };
})(
  withTranslation("dashboard")(
    withWidth()(withStyles(styles)(DashboardRecentActivities)),
  ),
);
