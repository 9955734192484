import ActionTypes from "../constants/action-types";
import { logEvent, setEventsMetadata } from "../lib/events-helper";
import SuuntoService from "../services/suunto-service";
import UserPlansService from "../services/user-plans-service";
import { standarizeActivities } from "../lib/activities-helper";
import StravaService from "../services/strava-service";
import { setSuccessMessage } from "./user-plans-action";
import bugsnagClient from "../lib/bugsnag-client";

const initAuthorizationSuunto = (cognito_user_sub, code) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SUUNTO_INIT_PENDING });
    try {
      const result = await SuuntoService.initAuthorization(code);

      dispatch({ type: ActionTypes.SUUNTO_INIT_FULFILLED, payload: result });

      if (result && result.user && result.access_token) {
        const resultUpdate = await UserPlansService.updateCurrentUserInfo({
          cognito_user_sub: cognito_user_sub,
          suunto_auth: result,
        });

        if (!resultUpdate.suunto_auth)
          throw new Error("Suunto auth not updated");

        dispatch(setSuccessMessage("Sync Suunto account"));
        setEventsMetadata({ customer_id: cognito_user_sub });
        logEvent("connect_GPS_provider");
      }
    } catch (exp) {
      bugsnagClient.notify(exp);
      dispatch({
        type: ActionTypes.SUUNTO_INIT_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getActivitiesSuunto = (param, reload = false) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SUUNTO_LOAD_ACTIVITIES_PENDING,
      payload: reload,
    });
    try {
      let result = await SuuntoService.getActivities(param);
      result = standarizeActivities(result, "suunto");

      dispatch({
        type: ActionTypes.SUUNTO_LOAD_ACTIVITIES_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.SUUNTO_LOAD_ACTIVITIES_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getActivitySuunto = (params, activity) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SUUNTO_GET_ACTIVITY_PENDING });
    try {
      const result = await SuuntoService.getActivity(params);
      dispatch({
        type: ActionTypes.SUUNTO_GET_ACTIVITY_FULFILLED,
        payload: { ...activity, ...result },
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.SUUNTO_GET_ACTIVITY_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const exportSuuntoWorkout = (params, activity) => {
  return async (dispatch) => {
    try {
      const result = await SuuntoService.exportWorkout(params);
      dispatch({
        type: ActionTypes.EXPORT_WORKOUT_FULFILLED,
        payload: { ...result },
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.EXPORT_WORKOUT_UNFULFILLED,
        payload: exp,
        error: true,
      });
    }
  };
};

const fetchAndFilterStravaActivities = async (result, param) => {
  param.start_date = param.strava_start_date;
  param.end_date = param.strava_end_date;
  let stravaResult = await StravaService.getActivities(param);
  stravaResult = modifyStartDateAttribute(stravaResult);

  const differentStartDateEntries = filterDifferentStartDateEntries(
    result,
    stravaResult,
  );
  return differentStartDateEntries;
};

const modifyStartDateAttribute = (entries) => {
  return entries.map((entry) => {
    let modifiedStartDate = new Date(entry.start_date).getTime() / 1000;
    return { ...entry, start_date: modifiedStartDate };
  });
};

const filterDifferentStartDateEntries = (result, stravaResult) => {
  const allStartDates = result.map((entry) =>
    Math.floor(entry.startTime / 1000),
  );
  return stravaResult.filter(
    (entry) => !allStartDates.includes(entry.start_date),
  );
};

const clearActivitySuunto = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SUUNTO_CLEAR_ACTIVITY });
  };
};

const clearSuuntoActivities = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SUUNTO_CLEAR_ACTIVITIES });
  };
};

export {
  initAuthorizationSuunto,
  getActivitiesSuunto,
  getActivitySuunto,
  clearActivitySuunto,
  clearSuuntoActivities,
  exportSuuntoWorkout,
};
