import Fields from "./plan-drawer-fields";

const QuestionFields = [
  {
    field: "surface",
    fieldType: Fields.Items.YES_NO,
    label: "Do you have hills or mountains nearby?",
    image: "6.png",
  },
  {
    field: "trainPerWeek",
    fieldType: Fields.Items.STEPPER_SELECT,
    label: "How many days do you usually train per week?",
    image: "onboarding-img/step1.png",
  },
  {
    field: "distance",
    fieldType: Fields.Items.CARD_SELECT,
    label: "Which distance do you want to start training for?",
    image: "2.png",
  },
  {
    field: "skillToImprove",
    fieldType: Fields.Items.CARD_SELECT,
    label: "Which skill would you like to start working on?",
    image: "4.png",
  },
  {
    field: "raceCount",
    fieldType: Fields.Items.STEPPER_SELECT,
    label: "How many trail races have you run before?",
    image: "onboarding-img/step2.png",
  },
  {
    field: "country",
    fieldType: Fields.Items.DROPDOWN,
    label: "What is your country",
    image: "8.png",
  },
  {
    field: "birthdate",
    fieldType: Fields.Items.DATE,
    label: "What is your date of birth?",
    image: "5.png",
  },
  {
    field: "sex",
    fieldType: Fields.Items.BUTTON_SELECT,
    label: "With which gender do you identify?",
    image: "1.png",
  },
];

export default QuestionFields;
