import React from "react";
import history from "../../../lib/history-helper";
import CoachInfo from "../../../components/coach-info";
import ConnectYourWatch from "../../../components/connect-your-watch";
import Faqs from "../../../components/faqs";
import ProfileView from "../../../components/profile";
import MountainIndexView from "../../../components/profile/MountainIndex";
import ReportAProblem from "../../../components/report-a-problem";
import SettingsAccount from "../../../components/settings";
import SettingsAccountGlobal from "../../../components/settings-global";
import SubscriptionDetails from "../../../components/subscription-details";
import SubscriptionFaqs from "../../../components/subscription-faqs";
import WorkWithUs from "../../../components/work-with-us";
import Coaches from "../../../components/your-coaches";
import ProfilePictureView from "../views/Settings/ProfilePictureView";
import CommunityPostDialog from "../../../components/common/v2/CommunityPostDialog";
import publicGroupMain from "../../../components/trail-head/public-group-main";
import socialProfile from "../../../components/trail-head/social-profile";
import AllRaces from "../../../components/trail-head/social-profile/races/all-races";
import AddResult from "../../../components/trail-head/social-profile/races/add-result";
import AllBadges from "../../../components/trail-head/social-profile/badges";
import AllGroups from "../../../components/trail-head/social-profile/groups";
import CommunityView from "../views/CommunityView";
import RecoveryPasswordView from "../views/Settings/RecoveryPasswordView";
import TrainingView from "../views/TrainingView";
import CheckoutView from "../views/CheckoutView";
import OnboardingIntroView from "../views/OnboardingIntroView";
import OnboardingSignUpQuestions from "../views/OnboardingSignUpQuestions";
import OnboardingLogin from "../views/OnboardingLogin";
import OnboardingLogInConfirm from "../views/OnboardingLogInConfirm";
import OnboardingSignUp from "../views/OnboardingSignUp";
import OnboardingSignUpEmail from "../views/OnboardingSignUpEmail";
import OnboardingSignUpEmailConfirm from "../views/OnboardingSignUpEmailConfirm";
import OnboardingWelcome from "../views/OnboardingWelcome";
import OnboardingChoosePlan from "../views/OnboardingChoosePlan";
import CheckoutProcess from "../views/CheckoutProcess";
import OnboardingSuggester from "../views/OnboardingSuggester";
import OnboardingResetPassword from "../views/OnboardingResetPassword";
import HomeView from "../views/HomeView";
import VideoView from "../views/VideoView";
import ChoosePlanView from "../views/ChoosePlanView";
import ExploreDetails from "../views/ExploreDetails";
import Chat from "../../../components/common/v2/Chat";

const routes = [
  {
    path: "/profile",
    component: ProfileView,
    private: true,
    exact: true,
  },
  {
    path: "/profile/:device",
    component: ConnectYourWatch,
    private: true,
  },
  {
    path: "/profile/mountain-index",
    component: MountainIndexView,
    private: true,
  },
  {
    path: "/profile/settings",
    component: SettingsAccountGlobal,
    private: true,
  },
  {
    path: "/profile/settings/account",
    component: SettingsAccount,
    private: true,
  },
  {
    path: "/profile/settings/watch",
    component: ConnectYourWatch,
    private: true,
  },
  {
    path: "/profile/settings/subscription-details",
    component: SubscriptionDetails,
    private: true,
  },
  {
    path: "/profile/settings/change-profile-picture",
    component: ProfilePictureView,
    private: true,
  },
  {
    path: "/profile/settings/report-a-problem",
    component: ReportAProblem,
    private: true,
  },
  {
    path: "/profile/settings/work-with-us",
    component: WorkWithUs,
    private: true,
  },
  {
    path: "/profile/settings/faqs",
    component: Faqs,
    private: true,
  },
  {
    path: "/profile/settings/subscription-faqs",
    component: SubscriptionFaqs,
    private: true,
  },
  {
    path: "/profile/settings/our-coaches",
    component: Coaches,
    private: true,
  },
  {
    path: "/profile/settings/our-coaches/info",
    component: CoachInfo,
    private: true,
  },
  {
    path: "/profile/settings/account/recovery-password",
    component: RecoveryPasswordView,
    private: true,
  },
  {
    path: "/trailhead",
    component: CommunityView,
    exact: true,
    private: true,
  },
  {
    path: "/trailhead/post/:id",
    component: CommunityPostDialog,
    private: true,
  },
  {
    path: "/trailhead/profile/:id",
    component: socialProfile,
    private: true,
  },
  {
    path: "/trailhead/group/:id",
    component: publicGroupMain,
    private: true,
  },
  {
    path: "/trailhead/:id/add-race",
    component: AddResult,
    private: true,
  },
  {
    path: "/trailhead/:id/races",
    component: AllRaces,
    private: true,
  },
  {
    path: "/trailhead/:id/all-badges",
    component: AllBadges,
    private: true,
  },
  {
    path: "/trailhead/:id/all-groupes",
    component: AllGroups,
    private: true,
  },
  {
    path: "/training",
    component: TrainingView,
    private: true,
  },
  {
    path: "/subscription/explore",
    component: CheckoutView,
    private: true,
  },
  {
    path: "/auth",
    component: OnboardingIntroView,
    private: false,
  },
  {
    path: "/auth/sign-up",
    component: OnboardingSignUpQuestions,
    private: false,
  },
  {
    path: "/auth/sign-up/method",
    component: OnboardingSignUp,
    private: false,
  },
  {
    path: "/auth/sign-up/method/email",
    component: OnboardingSignUpEmail,
    private: false,
  },
  {
    path: "/auth/sign-up/method/email/confirm",
    component: OnboardingSignUpEmailConfirm,
    private: false,
  },
  {
    path: "/welcome",
    component: OnboardingWelcome,
    private: false,
  },
  {
    path: "/welcome/choose-plan",
    component: OnboardingChoosePlan,
    private: false,
  },
  {
    path: "/auth/log-in",
    component: OnboardingLogin,
    private: false,
  },
  {
    path: "/auth/log-in/reset",
    component: OnboardingResetPassword,
    private: false,
  },
  {
    path: "/auth/log-in/confirm",
    component: OnboardingLogInConfirm,
    private: false,
  },
  {
    path: "/suggester/",
    component: OnboardingSuggester,
    exact: true,
    private: false,
  },
  {
    path: "/chooseplan/:selectedPlan/:id?",
    exact: true,
    component: OnboardingSuggester,
    private: false,
  },
  {
    path: "/subscription",
    exact: true,
    component: CheckoutProcess,
    private: true,
  },
  {
    path: "/dashboard/mountain-index",
    component: MountainIndexView,
    private: true,
  },
  {
    path: "/trailhead/mountain-index",
    component: MountainIndexView,
    private: true,
  },
  {
    path: "/training/mountain-index",
    component: MountainIndexView,
    private: true,
  },
  {
    path: "/dashboard",
    component: HomeView,
    private: true,
  },
  {
    path: "/all-videos",
    component: VideoView,
    exact: true,
    private: true,
  },
  {
    path: "/explore/post/:id",
    component: ExploreDetails,
    private: true,
  },
  {
    path: "/chooseplan",
    component: ChoosePlanView,
    private: true,
  },
  {
    path: "/dashboard/chat",
    component: (props) =>
      React.createElement(Chat, {
        ...props,
        isOpen: true,
        onClose: () => history.push("/dashboard"),
      }),
    private: true,
    exact: true,
  },
];

export default routes;
