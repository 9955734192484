import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  InputLabel,
  ButtonGroup,
  Box,
  FormControl,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { palette } from "../../../theme/palette";
import useRaceFormValidation from "../../../hooks/useRaceFormValidation";
import { dateFormat } from "../../../lib/date-helper";
import {
  DistanceIcon,
  ElevationIcon,
  LinkIcon,
  CalendarIcon,
} from "../../icons";
import unitsHook from "../../../hooks/unitsHook";

const FormRace = ({
  race = {},
  type = "add",
  onCancel = () => {},
  onSave = () => {},
}) => {
  const { t } = useTranslation("profile");
  const currentUnit = unitsHook();
  const { errors, validate } = useRaceFormValidation();
  const classes = useStyles();
  const [state, setState] = useState({
    raceId: null,
    raceName: "",
    raceDistance: "",
    raceElevation: "",
    raceDate: null,
    raceLocation: "",
    racePriority: "A",
    link: "",
  });

  const handleTextChange = (event) => {
    const { target } = event;
    if (target) {
      const { value, name } = target;
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleChangeLimit = (event, limit) => {
    const {
      target: { value, name },
    } = event;
    if (value.toString().length <= limit) {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handlePriorityChange = (priority) => {
    setState((prevState) => ({
      ...prevState,
      racePriority: priority,
    }));
  };

  const handleDateChange = (dateMoment) => {
    setState((prevState) => ({
      ...prevState,
      raceDate: dateMoment.format(dateFormat),
    }));
  };

  const handleSave = () => {
    const isValid = validate(state);
    if (isValid) {
      onSave(state);
    }
  };

  useEffect(() => {
    if (race && Object.keys(race).length > 0) {
      setState((prev) => ({ ...prev, ...race }));
    } else {
      setState({
        raceId: null,
        raceName: "",
        raceDistance: "",
        raceElevation: "",
        raceDate: null,
        raceLocation: "",
        racePriority: "A",
        link: "",
      });
    }
  }, [race]);

  return (
    <Grid
      className={classes.formContainer}
      container
      spacing={2}
      direction="column"
    >
      <FormControl error={Boolean(errors.raceName)}>
        <TextField
          color="secondary"
          fullWidth
          size="small"
          id="profile_add_race_name_field"
          data-testid="profile_add_race_name_field"
          aria-describedby="raceName-error-text"
          name="raceName"
          placeholder={t("profile.races.form.name")}
          variant="outlined"
          inputProps={{
            className: classes.input,
          }}
          onChange={handleTextChange}
          value={state.raceName}
        />
        <FormHelperText id="raceName-error-text">
          {t(errors.raceName)}
        </FormHelperText>
      </FormControl>

      <FormControl error={Boolean(errors.raceDistance)}>
        <TextField
          color="secondary"
          fullWidth
          size="small"
          id="profile_add_race_distance_field"
          data-testid="profile_add_race_distance_field"
          name="raceDistance"
          placeholder={t("profile.races.form.distance", {
            unit: !currentUnit || currentUnit === "kms" ? "km" : "miles",
          })}
          variant="outlined"
          onChange={(event) => handleChangeLimit(event, 10)}
          value={state.raceDistance}
          type="number"
          inputProps={{
            className: classes.input,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DistanceIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText>{t(errors.raceDistance)}</FormHelperText>
      </FormControl>

      <FormControl error={Boolean(errors.raceElevation)}>
        <TextField
          color="secondary"
          fullWidth
          size="small"
          id="profile_add_race_elevation_field"
          data-testid="profile_add_race_elevation_field"
          name="raceElevation"
          type="number"
          placeholder={t("profile.races.form.elevation", {
            unit: !currentUnit || currentUnit === "kms" ? "m" : "feet",
          })}
          variant="outlined"
          onChange={(event) => handleChangeLimit(event, 10)}
          value={state.raceElevation}
          inputProps={{
            className: classes.input,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ElevationIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText>{t(errors.raceElevation)}</FormHelperText>
      </FormControl>

      <FormControl error={Boolean(errors.link)}>
        <TextField
          fullWidth
          color="secondary"
          size="small"
          id="profile_add_race_link_field"
          data-testid="profile_add_race_link_field"
          name="link"
          type="url"
          placeholder={t("profile.races.form.raceLink")}
          variant="outlined"
          onChange={handleTextChange}
          value={state.link}
          inputProps={{
            className: classes.input,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText>{t(errors.link)}</FormHelperText>
      </FormControl>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10vw",
          alignItems: "center",
        }}
      >
        <Box>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              color="secondary"
              fullWidth
              id="profile_add_race_date_field"
              data-testid="profile_add_race_date_field"
              name="raceDate"
              placeholder={t("profile.races.form.date")}
              variant="outlined"
              format="DD/MM/YYYY"
              value={moment(state.raceDate || null, dateFormat).toISOString()}
              autoOk
              size="small"
              inputVariant="outlined"
              keyboard
              onChange={handleDateChange}
              inputProps={{
                className: classes.input,
                maxLength: 150,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            margin: "5px 0",
          }}
        >
          <InputLabel>{t("profile.races.form.priority")}</InputLabel>
          <ButtonGroup fullWidth classes={{ root: classes.buttonGroupRoot }}>
            <Button
              id="profile_add_race_priority_1"
              data-testid="profile_add_race_priority_1"
              className={
                state.racePriority === "A"
                  ? `${classes.racePrioritySelected} ${classes.priorityA}`
                  : classes.racePriorityUnselected
              }
              onClick={() => handlePriorityChange("A")}
            >
              A
            </Button>
            <Button
              id="profile_add_race_priority_2"
              data-testid="profile_add_race_priority_2"
              className={
                state.racePriority === "B"
                  ? `${classes.racePrioritySelected} ${classes.priorityB}`
                  : classes.racePriorityUnselected
              }
              onClick={() => handlePriorityChange("B")}
            >
              B
            </Button>
            <Button
              id="profile_add_race_priority_3"
              data-testid="profile_add_race_priority_3"
              className={
                state.racePriority === "C"
                  ? `${classes.racePrioritySelected} ${classes.priorityC}`
                  : classes.racePriorityUnselected
              }
              onClick={() => handlePriorityChange("C")}
            >
              C
            </Button>
          </ButtonGroup>
        </Box>
      </Box>

      <Grid sx={{ marginTop: "10px" }} container spacing={2}>
        <Grid item xs={6} sm={6} md={6}>
          <Button
            id="profile_add_race_cancel"
            data-testid="profile_add_race_cancel"
            onClick={(e) => onCancel(e)}
            fullWidth
            variant="outlined"
            size="medium"
            className={classes.cancelButton}
          >
            {t("profile.races.form.buttonCancel")}
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Button
            id="profile_add_race"
            data-testid="profile_add_race"
            onClick={(e) => handleSave(e)}
            fullWidth
            variant="contained"
            size="medium"
            className={classes.saveButton}
          >
            {type == "edit"
              ? t("profile.races.form.buttonEdit")
              : t("profile.races.form.buttonAdd")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormRace;

const useStyles = makeStyles({
  buttonGroupRoot: {
    gap: 5,
  },
  formContainer: {
    gap: 12,
    paddingBottom: 20,
  },
  input: {
    padding: "14px 8px",
  },
  racePrioritySelected: {
    border: "none",
    padding: "0 !important",
    borderRadius: "100% !important",
    fontWeight: 900,
    fontSize: 18,
    color: "#FFFFFF",
    cursor: "pointer",
    width: 28,
    height: 28,
    minWidth: 28,
    textAlign: "center",
  },
  racePriorityUnselected: {
    border: "none",
    padding: "0 !important",
    background: "rgba(30, 98, 155, 0.12)",
    borderRadius: "100% !important",
    fontWeight: 900,
    fontSize: 18,
    color: "#FFFFFF",
    cursor: "pointer",
    width: 28,
    height: 28,
    minWidth: 28,
    textAlign: "center",
  },
  cancelButton: {
    color: palette.darkBlue,
    borderColor: palette.darkBlue,
    borderWidth: 2,
    textTransform: "capitalize",
    minHeight: 48,
    fontSize: 18,
    fontWeight: 700,
  },
  saveButton: {
    backgroundColor: palette.darkBlue,
    color: palette.white,
    minHeight: 48,
    fontSize: 18,
  },
  priorityA: {
    backgroundColor: "#F93C6E !important",
  },
  priorityB: {
    backgroundColor: "#4780AB !important",
  },
  priorityC: {
    backgroundColor: "#6BB488 !important",
  },
});
