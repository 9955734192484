import React, { Component, memo } from "react";
import {
  DialogContent,
  withMobileDialog,
  Typography,
  Button,
  Slider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  patchSelectedAthleteWorkoutComment,
  patchSelectedAthleteWorkoutDeleteComment,
  patchSelectedAthleteWorkoutEditComment,
  athleteTrainingEditWorkout,
  selectAthleteWorkout,
} from "../../../actions/athletes-action";
import { convertToRTFForView } from "../../../lib/rtf-helper";
import WorkoutEditorForm from "../../workouts/workout-editor/workout-editor-form";
import EditIcon from "@material-ui/icons/Edit";
import { getPlan } from "../../../lib/current-training-helper";
import { dateFormat } from "../../../lib/date-helper";
import moment from "moment";
import WorkoutTypes from "../../../constants/workout-types";
import EstimatedTimes from "../../../constants/estimated-times";
import DashboardActivityDetails from "../../dashboard/dashboard-recent-activities/dashboard-activity-details";
import { getUserActivity } from "../../../actions/user-plans-action";
import workoutToText from "../../../lib/workoutToText";
import RaceCounts from "../../../constants/race-counts";
import { getCustomLevel, getPlanLevel } from "../../../lib/plan-helper";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    background: "#F9F9FB",
  },
  workoutContainer: {
    textAlign: "left",
  },

  workout: {
    display: "flex",
  },

  metadataColumn: {
    background: "#fff",
    padding: 24,
    width: "50%",
  },

  topItemFlex: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
  },

  topItem: {
    margin: "22px 0",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "50%",
    },
  },

  topTitle: {
    color: "#212121",
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 400,
    alignItems: "center",
    display: "flex",
  },
  topTitleIcon: {
    marginRight: 10,
  },
  topText: {
    color: "#212121",
    fontSize: 18,
    fontWeight: 500,
  },

  commentContainer: {
    textAlign: "left",
    padding: theme.spacing.unit,
    width: "40%",
    minHeight: 400,
    minWidth: 300,
    position: "relative",
  },
  borderContainer: {
    borderStyle: "groove",
    borderWidth: "thin",
    minHeight: 150,
    padding: 10,
    borderRadius: 5,
  },
  workoutDescription: {
    margin: "10px 0 32px",
  },
  actionIcon: {
    cursor: "pointer",
  },
  actions: {
    textAlign: "end",
    margin: "12px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginTop: 16,
    justifyContent: "space-between",
  },

  content: {
    padding: "0 !important",
  },

  workoutColumn: {
    padding: 24,
  },

  rightButtons: {
    zIndex: 100,
    cursor: "pointer",
    position: "absolute",
    right: 5,
    top: 5,
  },

  newLineEffort: {
    fontWeight: 500,
    whiteSpace: "pre-line",
  },

  effort: {
    width: "100%",
    display: "flex",
    margin: "10px 0 5px",

    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-end",
    },
  },
  effortLabel: {
    fontSize: 14,
    textAlign: "center",
    color: "#212121",
    fontWeight: 400,

    [theme.breakpoints.down("sm")]: {
      maxWidth: 70,
    },
  },

  workoutGeneratedTextContainer: {
    boxShadow: "0px 0px 10px 1px rgb(0 0 0 / 25%)",
    padding: 15,
    margin: "20px 0",
    maxHeight: 250,
    overflow: "scroll",
    backgroundColor: "#FFF",
  },

  workoutGeneratedTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },

  workoutStepsContainer: {
    overflow: "scroll",
    height: 640,
  },

  editButton: {
    borderRadius: 50,
  },

  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  progressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "right",
    alignItems: "center",
  },
});

class AthleteTrainingWorkoutViewer extends Component {
  state = {
    addedComments: [],
    deletedComments: [],
    editedComments: [],
    currentWorkout: null,
    initialWorkout: null,
    isEditing: false,
    current: false,
  };

  componentDidMount() {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes;
    const { selectedWorkout } = selectedAthlete || {};
    if (selectedWorkout && !this.state.currentWorkout) {
      this.setState({
        ...this.state,
        currentWorkout: JSON.parse(JSON.stringify(selectedWorkout)),
        initialWorkout: JSON.parse(JSON.stringify(selectedWorkout)),
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { athletes } = this.props;
    const { selectedAthlete } = athletes;
    const { selectedWorkout, stravaActivities } = selectedAthlete || {};

    if (selectedWorkout && !this.state.currentWorkout) {
      this.setState({
        ...this.state,
        currentWorkout: JSON.parse(JSON.stringify(selectedWorkout)),
        initialWorkout: JSON.parse(JSON.stringify(selectedWorkout)),
      });
    }

    if (!this.state.current && stravaActivities && selectedWorkout) {
      const { plannedDate } = selectedWorkout || {};

      let currentActivity = null;

      currentActivity = stravaActivities.find(
        (a) =>
          moment(a.start_date).isSame(moment(plannedDate, dateFormat), "day") &&
          ((a.type && a.type.toLowerCase().includes("run")) ||
            (a.activityType && a.activityType.includes("RUN"))),
      );

      if (currentActivity) {
        this.props.dispatch(getUserActivity(currentActivity, selectedAthlete));
        const { device: type } = currentActivity;
        this.setState({
          ...this.state,
          current: type,
        });
      }
    }
  }

  handleSave = async (comment) => {
    const { athletes } = this.props;
    const { selectedAthlete = {} } = athletes;
    const { selectedWorkout } = selectedAthlete;
    const { plannedDate, id } = selectedWorkout;

    this.props.dispatch(
      patchSelectedAthleteWorkoutComment(
        selectedAthlete,
        plannedDate,
        id,
        comment,
      ),
    );
    this.setState({
      ...this.state,
      addedComments: [...this.state.addedComments, comment],
    });
  };

  handleDelete = async (comment) => {
    const { athletes } = this.props;
    const { selectedAthlete = {} } = athletes;
    const { selectedWorkout } = selectedAthlete;
    const { plannedDate, id } = selectedWorkout;

    this.props.dispatch(
      patchSelectedAthleteWorkoutDeleteComment(
        selectedAthlete,
        plannedDate,
        id,
        comment,
      ),
    );
    this.setState({
      ...this.state,
      deletedComments: [...this.state.deletedComments, comment],
    });
  };

  handleEdit = async (comment) => {
    const { athletes } = this.props;
    const { selectedAthlete = {} } = athletes;
    const { selectedWorkout } = selectedAthlete;
    const { plannedDate, id } = selectedWorkout;

    this.props.dispatch(
      patchSelectedAthleteWorkoutEditComment(
        selectedAthlete,
        plannedDate,
        id,
        comment,
      ),
    );
    this.setState({
      ...this.state,
      editedComments: [...this.state.editedComments, comment],
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { athletes } = this.props;
    const { selectedAthlete = {}, selectedAthleteChainedPlans } = athletes;
    const { currentWorkout, current } = this.state;
    if (currentWorkout) {
      const plan = getPlan(
        [
          selectedAthlete,
          ...selectedAthleteChainedPlans.map((c, index) => {
            return {
              updated_at: selectedAthlete.chainedPlans[index],
              currentTraining: c,
            };
          }),
        ],
        moment(currentWorkout.plannedDate, dateFormat),
      );

      this.props
        .dispatch(
          athleteTrainingEditWorkout(
            selectedAthlete,
            {
              ...currentWorkout,
              workoutId: currentWorkout.id,
              ...{
                title: currentWorkout.title,
                title_lower: `${currentWorkout.title[
                  "en"
                ].toLowerCase()} ${currentWorkout.title["es"].toLowerCase()}`,
                description: currentWorkout.description,
                description_lower: `${currentWorkout.description[
                  "en"
                ].toLowerCase()} ${currentWorkout.description[
                  "es"
                ].toLowerCase()}`,
                workoutType: currentWorkout.workoutType,
                estimatedTime: currentWorkout.estimatedTime,
              },
            },
            plan.updated_at === "latest" ? 0 : plan.updated_at,
          ),
        )
        .then(() => this.closeEdit());
      this.setState({
        ...this.state,
        initialWorkout: JSON.parse(
          JSON.stringify({
            ...currentWorkout,
            ...{
              title: currentWorkout.title,
              title_lower: `${currentWorkout.title[
                "en"
              ].toLowerCase()} ${currentWorkout.title["es"].toLowerCase()}`,
              description: currentWorkout.description,
              description_lower: `${currentWorkout.description[
                "en"
              ].toLowerCase()} ${currentWorkout.description[
                "es"
              ].toLowerCase()}`,
              workoutType: currentWorkout.workoutType,
              estimatedTime: currentWorkout.estimatedTime,
            },
          }),
        ),
      });
    }
  };

  handleChangeWorkout = (workoutChanged) => {
    this.setState({
      ...this.state,
      currentWorkout: JSON.parse(JSON.stringify(workoutChanged)),
    });
  };

  handleSelect = ({ name, value, level: levelParam }) => {
    if (name && value) {
      let { currentWorkout } = this.state;
      const copy = { ...JSON.parse(JSON.stringify(currentWorkout)) };
      if (name === "estimatedTime") {
        const { athletes } = this.props;
        const { selectedAthlete } = athletes;
        const { currentTraining } = selectedAthlete || {};

        const level = getPlanLevel(currentTraining);

        if (Array.isArray(copy[name])) {
          copy[name][!isNaN(levelParam) ? levelParam : level] = value;
        } else {
          copy[name] = value;
        }
      } else {
        copy[name] = value;
      }

      this.setState({
        ...this.state,
        currentWorkout: JSON.parse(JSON.stringify(copy)),
      });
    }
  };

  handleMultiLangTextChange = ({ name, value }) => {
    if (name && value) {
      let { currentWorkout } = this.state;
      currentWorkout[name] = value;
      this.setState({
        ...this.state,
        currentWorkout,
      });
    }
  };

  openEdit = () => {
    this.setState({
      ...this.state,
      isEditing: true,
    });
  };

  closeEdit = () => {
    this.setState({
      ...this.state,
      isEditing: false,
      currentWorkout: JSON.parse(JSON.stringify(this.state.initialWorkout)),
    });
    this.props.dispatch(
      selectAthleteWorkout(
        JSON.parse(JSON.stringify(this.state.initialWorkout)),
      ),
    );
  };

  render() {
    const formId = "workoutForm";
    const {
      classes,
      athletes,
      onClose,
      onOpenChat,
      onWorkoutDelete,
    } = this.props;
    const { selectedAthlete, savingTraining } = athletes;
    const { selectedWorkout = {}, currentTraining } = selectedAthlete || {};
    const { chainedPlans } = currentTraining || {};
    const { description, energyLevel = 100, title, userCreatedType, rpe } =
      selectedWorkout || {};

    const { isEditing, currentWorkout, current } = this.state;

    const level = getPlanLevel(currentTraining);
    const customLevel = getCustomLevel(
      selectedWorkout.plannedDate,
      chainedPlans,
    );

    const normalizeEnergyValue = (value) => {
      if (!value && isNaN(value)) return 0;
      if (value == 0) return 100;
      if (value > 0 && value <= 10) return 90;
      if (value > 10 && value <= 20) return 80;
      if (value > 20 && value <= 30) return 70;
      if (value > 30 && value <= 40) return 60;
      if (value > 40 && value <= 50) return 50;
      if (value > 50 && value <= 60) return 40;
      if (value > 60 && value <= 70) return 30;
      if (value > 70 && value <= 80) return 20;
      if (value > 80 && value <= 90) return 10;
      if (value > 90 && value <= 100) return 0;
    };

    const {
      title: editedTitle,
      description: editedDescription,
      workoutType,
      estimatedTime,
      plannedDate,
      parametrizedWorkout,
    } = currentWorkout || {};

    let newEstimatedTime = estimatedTime;
    if (Array.isArray(estimatedTime)) {
      newEstimatedTime =
        estimatedTime[!isNaN(customLevel) ? customLevel : level];
    }

    const hasError = !(
      editedTitle &&
      editedTitle["en"] &&
      editedTitle["es"] &&
      editedDescription &&
      editedDescription["en"] &&
      editedDescription["es"] &&
      workoutType &&
      newEstimatedTime
    );

    return (
      <>
        {selectedWorkout ? (
          <DialogContent
            classes={{
              root: classes.content,
            }}
          >
            <div className={classes.container}>
              <div className={classes.workoutContainer}>
                <div className={classes.rightButtons}>
                  <img
                    src={`${process.env.PUBLIC_URL}/close.svg`}
                    alt=""
                    onClick={() => onClose()}
                  />
                </div>
                {isEditing ? (
                  <>
                    <WorkoutEditorForm
                      formId={formId}
                      saving={false}
                      workout={this.state.currentWorkout}
                      onMultiLangTextChange={this.handleMultiLangTextChange}
                      onSelect={this.handleSelect}
                      onSubmit={this.handleSubmit}
                      isEditingOnPlan={true}
                      level={!isNaN(customLevel) ? customLevel : level}
                      handleChangeWorkout={this.handleChangeWorkout}
                      onWorkoutDelete={onWorkoutDelete}
                      handleClose={this.props.handleClose}
                    />

                    {savingTraining ? (
                      <div className={classes.progressContainer}>
                        <CircularProgress
                          variant="indeterminate"
                          color="primary"
                          className={classes.loading}
                        />
                      </div>
                    ) : (
                      <div className={classes.actions}>
                        <Button
                          onClick={() => this.closeEdit()}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          form={formId}
                          disabled={hasError}
                        >
                          {"Update"}
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className={classes.workout}>
                    <div className={classes.metadataColumn}>
                      <div className={classes.topItemFlex}>
                        <div className={classes.topItem}>
                          <Typography variant="h6" className={classes.topTitle}>
                            <img
                              src={`${process.env.PUBLIC_URL}/icon-workout-date.svg`}
                              alt=""
                              className={classes.topTitleIcon}
                            />
                            {"Date"}
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.topText}
                          >
                            {plannedDate}
                          </Typography>
                        </div>
                        {userCreatedType || workoutType ? (
                          <div className={classes.topItem}>
                            <Typography
                              variant="h6"
                              className={classes.topTitle}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icon-workout-type.svg`}
                                alt=""
                                className={classes.topTitleIcon}
                              />
                              {"Type"}
                            </Typography>
                            <Typography
                              variant="body1"
                              className={classes.topText}
                            >
                              {userCreatedType
                                ? userCreatedType
                                : WorkoutTypes.Items[workoutType]}
                            </Typography>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className={classes.topItemFlex}>
                        {newEstimatedTime > 0 ? (
                          <div className={classes.topItem}>
                            <Typography
                              variant="h6"
                              className={classes.topTitle}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icon-timer.svg`}
                                alt=""
                                className={classes.topTitleIcon}
                              />
                              {"Time"}
                            </Typography>
                            <Typography
                              variant="body1"
                              className={classes.topText}
                            >
                              {EstimatedTimes.convertMinutesToLabel(
                                newEstimatedTime,
                                true,
                              ) || "0min"}
                            </Typography>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className={classes.center}>
                          {/*<RouterLink
                    to={`/dashboard/${idParam}/${plannedDateParam}/chat`}
                  >*/}
                          <Button
                            variant="outlined"
                            color="primary"
                            className={classes.buttons}
                            onClick={() =>
                              onOpenChat(title ? currentWorkout : "")
                            }
                          >
                            {"Start chat"}
                          </Button>
                          {/*</RouterLink>*/}
                        </div>
                      </div>
                    </div>
                    {(userCreatedType || title) && description ? (
                      <div className={classes.workoutColumn}>
                        <div className={classes.title}>
                          <Typography variant="h6">
                            {userCreatedType
                              ? userCreatedType
                              : title["en"] || title["es"]}
                          </Typography>

                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            className={classes.editButton}
                            onClick={() => this.openEdit()}
                          >
                            Edit Workout
                          </Button>
                        </div>

                        {parametrizedWorkout &&
                        parametrizedWorkout[
                          !isNaN(customLevel) ? customLevel : level
                        ] &&
                        parametrizedWorkout[
                          !isNaN(customLevel) ? customLevel : level
                        ].length ? (
                          <div
                            className={classes.workoutGeneratedTextContainer}
                          >
                            <Typography
                              variant="body1"
                              className={classes.workoutGeneratedTitle}
                            >
                              Workout detail Preview
                            </Typography>

                            <Typography
                              variant="body1"
                              className={classes.workoutGeneratedText}
                              dangerouslySetInnerHTML={{
                                __html: workoutToText(
                                  parametrizedWorkout[
                                    !isNaN(customLevel) ? customLevel : level
                                  ],
                                ),
                              }}
                            ></Typography>
                            <div></div>
                          </div>
                        ) : (
                          ""
                        )}

                        <Typography
                          variant="body2"
                          dangerouslySetInnerHTML={convertToRTFForView(
                            description["en"] || description["es"],
                          )}
                          className={classes.workoutDescription}
                        ></Typography>

                        <div>
                          <div className={classes.row}>
                            <Typography
                              variant="body2"
                              className={classes.newLineEffort}
                            >
                              {"How did this activity felt? (RPE)"}
                            </Typography>

                            <Typography variant="body2">
                              {rpe ? rpe / 10 : 0}
                            </Typography>
                          </div>

                          <div
                            className={classes.row}
                            style={{ marginTop: "10px" }}
                          >
                            <Typography
                              variant="h6"
                              className={classes.effortLabel}
                            >
                              {"Easy"}
                            </Typography>

                            <Typography
                              variant="h6"
                              className={classes.effortLabel}
                            >
                              {"Medium"}
                            </Typography>

                            <Typography
                              variant="h6"
                              className={classes.effortLabel}
                            >
                              {"Max effort"}
                            </Typography>
                          </div>
                          <Slider
                            value={rpe || 0}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="off"
                            min={0}
                            max={100}
                            color="primary"
                          />
                        </div>

                        <div>
                          <div className={classes.row}>
                            <Typography
                              variant="body2"
                              className={classes.newLineEffort}
                            >
                              {"How fatigued are you today?"}
                            </Typography>

                            <Typography variant="body2">
                              {!isNaN(energyLevel)
                                ? normalizeEnergyValue(energyLevel) / 10
                                : 0}
                            </Typography>
                          </div>

                          <div className={classes.effort}>
                            <Typography
                              variant="h6"
                              className={classes.effortLabel}
                            >
                              {"Fresh"}
                            </Typography>

                            <Typography
                              variant="h6"
                              className={classes.effortLabel}
                              style={{
                                marginLeft: "auto",
                              }}
                            >
                              {"Average"}
                            </Typography>

                            <Typography
                              variant="h6"
                              className={classes.effortLabel}
                              style={{
                                marginLeft: "auto",
                              }}
                            >
                              {"Very fatigued"}
                            </Typography>
                          </div>
                          <Slider
                            value={
                              !isNaN(energyLevel)
                                ? normalizeEnergyValue(energyLevel)
                                : 0
                            }
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="off"
                            min={0}
                            max={100}
                            color="primary"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {current ? (
                      <div className={classes.activityColumn}>
                        <DashboardActivityDetails
                          asComponent={true}
                          isOpen={current}
                          customHeight={1000}
                          elevationMargin={12}
                          isCoach={true}
                        ></DashboardActivityDetails>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
              {/*<div className={classes.commentContainer}>
                <TrainingWeekdayDetailsChat
                  comments={allComments}
                  comment={comment}
                  coachComment={coachComment}
                  coachFeedback={coachFeedback}
                  handleSaveComment={this.handleSave}
                  handleDeleteComment={this.handleDelete}
                  handleEditComment={this.handleEdit}
                  isCoach={true}
                  type={0}
                />
                      </div>*/}
            </div>
          </DialogContent>
        ) : (
          ""
        )}
      </>
    );
  }
}

AthleteTrainingWorkoutViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(
  withStyles(styles)(
    connect((store) => {
      return {
        athletes: store.athletes,
        notifications: store.notifications,
        strava: store.strava,
        garmin: store.garmin,
        suunto: store.suunto,
        coros: store.coros,
      };
    })(memo(AthleteTrainingWorkoutViewer)),
  ),
);
