import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Storage } from "aws-amplify";
import { animateScroll } from "react-scroll";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: 10,
    margin: "15px 34px 15px 34px",
  },
  imgContainer: {
    position: "relative",
  },
  img: {
    width: "100%",
    borderRadius: "10px 10px 0 0",
  },
  badge: {
    height: "90%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  title: {
    fontFamily: "Avenir",
    fontWeight: 900,
    fontSize: 18,
    color: "#202020",
    margin: "12px 0",
    textAlign: "left",
    textTransform: "uppercase",
  },
  description: {
    fontFamily: "Avenir",
    fontWeight: "normal",
    fontSize: 16,
    textAlign: "left",
    color: "#9E9E9E",
  },
  dateContainer: {
    display: "flex",
    alignItems: "center",
  },
  date: {
    fontFamily: "Avenir",
    fontWeight: "normal",
    fontSize: 14,
    color: "#202020",
    marginLeft: 5,
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "6px 0 3px",
  },
  participants: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontSize: 10,
    color: "#202020",
    fontWeight: "normal",
  },
  divider: {
    margin: "0 10px",
  },
  textContainer: {
    padding: "0 16px 16px",
  },
  difficulty: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 14,
    background: "rgba(255, 87, 34, 0.24)",
    width: "fit-content",
    padding: "0px 5px",
    marginTop: 10,
  },
  button: {
    borderRadius: 50,
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "20px",
    textAlign: "center",
    margin: "auto",
    marginTop: 8,
  },

  descOpener: {
    cursor: "pointer",
  },

  buttonContainer: {
    textAlign: "center",
    display: "flex",
  },

  favContainer: {
    background: "#FFFFFF",
    borderRadius: 24,
    position: "absolute",
    top: 15,
    right: 15,
    padding: "2px 10px",
    zIndex: 10,
  },
});

class Challenge extends Component {
  state = {
    imageUrl: null,
    imageLoading: false,
    badgeUrl: null,
    badgeLoading: false,
    openDescription: false,
  };

  async componentWillReceiveProps(nextProps) {
    const { challenge } = nextProps;
    const { image, badge } = challenge;
    const { imageUrl, badgeUrl } = this.state;
    if (image && !imageUrl) {
      this.getImageUrl(image);
    }
    if (badge && !badgeUrl) {
      this.getBadgeUrl(badge);
    }
  }

  getImageUrl = async (image) => {
    const { t } = this.props;
    const { imageUrl } = this.state;

    if (image && !imageUrl) {
      try {
        const result = await Storage.get(image, {
          level: "public",
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              imageUrl: result,
              imageLoading: false,
            }),
          100,
        );
      } catch (exp) {
        //onError(
        new Error(
          t(
            "Oops, we could not load your profile picture. Give it another try",
            { ns: "messages" },
          ),
          //  ),
        );
        throw exp;
      }
    } else if (!image) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            imageLoading: false,
          }),
        100,
      );
    }
  };

  getBadgeUrl = async (image) => {
    const { t } = this.props;
    const { badgeUrl } = this.state;

    if (image && !badgeUrl) {
      try {
        const result = await Storage.get(image, {
          level: "public",
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              badgeUrl: result,
              badgeLoading: false,
            }),
          100,
        );
      } catch (exp) {
        // onError(
        new Error(
          t(
            "Oops, we could not load your profile picture. Give it another try",
            { ns: "messages" },
          ),
        );
        //);
        throw exp;
      }
    } else if (!image) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            badgeLoading: false,
          }),
        100,
      );
    }
  };

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    const {
      classes,
      challenge,
      t,
      i18n,
      onJoinChallenge,
      isJoined,
      onOpenChallenge,
      isPast,
      onToggleFavorite,
      isFav,
    } = this.props;

    const { openDescription } = this.state;

    const {
      name,
      description,
      start_date,
      end_date,
      difficulty,
      participants,
      image,
      badge,
    } = challenge;

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const momentLocale = moment().locale(language);

    return (
      <div className={classes.container}>
        <div className={classes.imgContainer}>
          <img className={classes.img} alt="challenge" src={image} />
          <img className={classes.badge} alt="badge" src={badge} />

          <div className={classes.favContainer}>
            <img
              src={`${process.env.PUBLIC_URL}/icon-star-${
                isFav ? "filled" : "outline"
              }.svg`}
              alt=""
              onClick={(ev) =>
                onToggleFavorite(ev, challenge.id, "Training plan")
              }
              className={classes.fav}
            />
          </div>
        </div>
        <div className={classes.textContainer}>
          <Typography variant={"body1"} className={classes.difficulty}>
            <b>
              {t("Difficulty")}
              {": "}
            </b>
            {+difficulty === 0
              ? t("All levels")
              : +difficulty === 1
              ? t("Easy")
              : +difficulty === 2
              ? t("Medium")
              : +difficulty === 3
              ? t("High")
              : t("All levels")}
          </Typography>

          <Typography variant={"body1"} className={classes.title}>
            {name[languageDetector] ? name[languageDetector] : name["en"]}
          </Typography>

          <Typography
            variant={"body1"}
            className={classes.description}
            style={{
              whiteSpace: openDescription ? "initial" : "nowrap",
              overflow: openDescription ? "initial" : "hidden",
              textOverflow: openDescription ? "initial" : "ellipsis",
            }}
          >
            {description[languageDetector]
              ? description[languageDetector]
              : description["en"]}
          </Typography>

          <img
            src={`${process.env.PUBLIC_URL}/icon-chevron-${
              openDescription ? "up" : "down"
            }.svg`}
            alt=""
            onClick={() =>
              this.setState({
                ...this.state,
                openDescription: !openDescription,
              })
            }
            className={classes.descOpener}
          />

          <div className={classes.dateContainer}>
            <img
              src={`${process.env.PUBLIC_URL}/icon-challenge-date.svg`}
              alt=""
              className={classes.iconDate}
            />
            <Typography variant={"body1"} className={classes.date}>
              {`${this.capitalize(
                momentLocale
                  .localeData()
                  .months(moment(start_date))
                  .substr(0, 3),
              )} ${moment(moment(start_date)).format("D")} ${t(
                "to",
              )} ${this.capitalize(
                momentLocale
                  .localeData()
                  .months(moment(end_date))
                  .substr(0, 3),
              )} ${moment(moment(end_date)).format("D")}. ${moment(
                moment(end_date),
              ).format("YYYY")}`}
            </Typography>
          </div>

          <div className={classes.dateContainer}>
            <img
              src={`${process.env.PUBLIC_URL}/icon-participants.svg`}
              alt=""
              className={classes.iconParticipants}
            />
            <Typography variant={"body1"} className={classes.date}>
              {participants ? participants.length : 0} {t("participants")}
            </Typography>
          </div>

          <div className={classes.buttonContainer}>
            {!isJoined ? (
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => {
                  onJoinChallenge(challenge);
                  const userAgent =
                    navigator.userAgent || navigator.vendor || window.opera;
                  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    setTimeout(() => {
                      animateScroll.scrollToTop({
                        containerId: "signupdialog",
                        duration: 0,
                      });
                    }, 250);
                  }
                }}
              >
                {t("Join this Challenge")}
              </Button>
            ) : (
              ""
            )}

            <Button
              color="primary"
              variant={isJoined ? "contained" : "outlined"}
              className={classes.button}
              onClick={() => onOpenChallenge(challenge)}
            >
              {!isJoined
                ? t("Leaders")
                : isPast
                ? t("See Results")
                : t("Check your progress")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Challenge.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  challenge: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation("challenges")(
  withWidth()(withStyles(styles, { withTheme: true })(Challenge)),
);
