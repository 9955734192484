import React from "react";
import { palette } from "../../theme/palette";

const TriangleIcon = ({
  width = 12,
  height = 12,
  activated = false,
  color = "#6D6F71",
}) => (
  <svg
    width={12}
    height={12}
    viewBox={` 0 0 ${width} ${height}`}
    fill={activated ? palette.primary : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.49998 9.49999H10.5C10.5911 9.49971 10.6804 9.47457 10.7583 9.4273C10.8362 9.38003 10.8998 9.31241 10.9421 9.23172C10.9844 9.15102 11.0039 9.06031 10.9985 8.96935C10.9931 8.87839 10.963 8.79062 10.9115 8.71549L6.41148 2.21549C6.22498 1.94599 5.77598 1.94599 5.58898 2.21549L1.08898 8.71549C1.03689 8.79046 1.00635 8.87828 1.00066 8.96939C0.99498 9.0605 1.01437 9.15143 1.05674 9.2323C1.0991 9.31316 1.16282 9.38087 1.24096 9.42806C1.31911 9.47526 1.40869 9.50014 1.49998 9.49999Z"
      fill={color}
    />
  </svg>
);
export default TriangleIcon;
