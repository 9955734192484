import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  iconarrowBack: {
    position: "relative",
    width: "24px",
    height: "24px",
    overflow: "hidden",
    flexShrink: 0,
    objectFit: "cover",
    opacity: 0,
  },
  vertrunLogoIcon: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    left: "0%",
    maxWidth: "100%",
    overflow: "hidden",
    maxHeight: "100%",
    objectFit: "cover",
  },
  iconvertrunLogo: {
    position: "relative",
    width: "151px",
    height: "70px",
  },
  iconclose: {
    position: "relative",
    width: "24px",
    height: "24px",
    overflow: "hidden",
    flexShrink: 0,
    objectFit: "cover",
  },
  logoback: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0px 16px",
  },
  yourPasswordHas: {
    alignSelf: "stretch",
    position: "relative",
    lineHeight: "125%",
    fontFamily: "Karla",
  },
  text: {
    position: "relative",
    lineHeight: "125%",
  },
  primaryButton: {
    alignSelf: "stretch",
    borderRadius: "4px",
    backgroundColor: "#0b2b5c",
    boxShadow: "0px 4px 30px rgba(18, 21, 27, 0.05)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 0px",
    textAlign: "center",
    fontSize: "18px",
    color: "#feffff",
  },
  content: {
    alignSelf: "stretch",
    textAlign: "center",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "0px 16px",
    boxSizing: "border-box",
  },
  page: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "24px 0px",
    boxSizing: "border-box",
    gap: "56px",
    textAlign: "left",
    fontSize: "22px",
    color: "#12151b",
    fontFamily: "Karla",
  },
}));

const EmailSend = () => {
  const classes = useStyles();
  const { t } = useTranslation("settings");
  localStorage.removeItem("email");
  return (
    <div className={classes.page}>
      <div className={classes.logoback}>
        <div className={classes.iconvertrunLogo}>
          <img
            className={classes.vertrunLogoIcon}
            alt=""
            src={process.env.PUBLIC_URL + "/vertrun-logo-wide.png"}
          />
        </div>
      </div>
      <div className={classes.content}>
        <b className={classes.yourPasswordHas}>
          {t("settings.passwordChangedComplete")}
        </b>
      </div>
    </div>
  );
};

export default EmailSend;
