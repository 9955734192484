import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getExplorePosts,
  toggleFavorites,
} from "../../actions/trail-head-action";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import {
  clearSuccessMessage,
  clearErrorMessage,
  toggleOpenMenu,
  updatePlan,
} from "../../actions/user-plans-action";
import AppAuthenticator from "../app-authenticator";
import SnackBarMessageDisplay from "../common/snack-bar-message-display";
import { setErrorMessage } from "../../actions/user-plans-action";
import { logEvent } from "../../lib/events-helper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import withWidth from "@material-ui/core/withWidth";
import history from "../../lib/history-helper";
import ExplorePost from "./explore-post";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const styles = (theme) => ({
  container: {
    width: "100%",
    background: "#E5E5E5",

    overflow: "scroll",
    height: "100vh",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  posts: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  loading: {
    textAlign: "center",
  },

  top: {
    padding: 10,
    margin: "auto",

    background: "#e5e5e5",

    position: "sticky",
    top: 0,
    zIndex: 100,
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)",
      background: "#ffffff",
      borderRadius: "0 0 12px 12px",
      textAlign: "initial",
    },
  },

  searchBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  termSearch: {
    marginTop: 0,
    marginBottom: 4,
    background: "rgb(255 255 255 / 10%)",
  },
  logoImg: {
    width: 72,
    marginRight: "auto",
    cursor: "pointer",
  },
  iconSearch: {
    marginLeft: 10,
    cursor: "pointer",
  },
  onlyReported: {
    color: "#ffffff",
  },
  button: {
    boxShadow: "none",
    borderRadius: 50,
    marginBottom: 0,
    marginLeft: 20,
  },

  title: {
    fontWeight: 500,
    fontSize: 18,
    marginRight: "auto",
    marginLeft: 25,
    marginTop: 2,
  },

  filterItem: {
    paddingBottom: "0 !important",
    margin: 5,
    display: "inline-block",
  },

  filter: {
    fontSize: 14,
    fontWeight: 400,
    background: "rgba(63, 81, 181, 0.12);",
    borderRadius: 4,
    padding: "6px 8px",
    color: "#212121",
    cursor: "pointer",
    minWidth: 50,
    textAlign: "center",
  },
  filterSelected: {
    fontSize: 14,
    fontWeight: 400,
    background: "#1E629B",
    borderRadius: 4,
    padding: "6px 8px",
    color: "#FFFFFF",
    cursor: "pointer",
    minWidth: 50,
    textAlign: "center",
  },

  round: {
    borderRadius: 50,
  },

  closeIcon: {
    cursor: "pointer",
  },

  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },

  noPadding: {
    padding: "0 !important",
  },

  favs: {
    marginLeft: "auto",
    marginRight: 15,
  },

  filterGrid: {
    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",

    "&::-webkit-scrollbar ": {
      display: "none",
    },
  },

  favsDesktop: {
    height: 15,
  },
});

class ExploreTag extends PureComponent {
  state = {
    avatarLoading: true,
    avatarUrl: null,
    openSignUp: false,
    signUpTimeout: null,
    type: null,
    types: [
      "Become a trail runner",
      "Become an ultra runner",
      "Workouts [ Video ]",
      "Recovery [ Video ]",
      "Tips [ Video ]",
      "Podcast",
    ],
  };

  async componentDidMount() {
    const { match } = this.props;

    const { params } = match;
    const { id } = params;
    if (id) {
      this.setState({
        ...this.state,
        type: id,
      });
      this.props.dispatch(getExplorePosts(new Date().getTime(), id, true));
    }
  }

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleError = (err) => {
    this.props.dispatch(setErrorMessage(err)); //GOES TO SNACK BAR
  };

  handleLoadNextPage = (lastDate) => {
    if (this.state.type) {
      this.props.dispatch(getExplorePosts(lastDate, this.state.type, false));
    }
  };

  handleOpenSignUp = () => {
    localStorage.removeItem("error");
    if (this.state.signUpTimeout) {
      clearTimeout(this.state.signUpTimeout);
    }
    this.setState({
      ...this.state,
      openSignUp: true,
      signUpTimeout: null,
    });
  };

  handleCloseSignUp = () => {
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignUp: false,
    });
  };

  toogleMenu = () => {
    this.props.dispatch(toggleOpenMenu());
  };

  handleBack = () => {
    history.push(`/explore`);
  };

  toggleFavorite = (event, id, type) => {
    event.preventDefault();
    event.stopPropagation();
    const { auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      const { userPlans } = this.props;
      const { currentPlan } = userPlans;
      const { exploreFavorites, cognito_user_sub } = currentPlan || {};
      if (
        exploreFavorites &&
        exploreFavorites.length &&
        exploreFavorites.find((e) => e.id === id)
      ) {
        const newExploreFavorites = exploreFavorites.filter((f) => f.id !== id);
        this.props.dispatch(
          updatePlan(
            {
              cognito_user_sub: cognito_user_sub,
              exploreFavorites: newExploreFavorites,
            },
            false,
            false,
          ),
        );
      } else {
        this.props.dispatch(
          updatePlan(
            {
              cognito_user_sub: cognito_user_sub,
              exploreFavorites:
                exploreFavorites && exploreFavorites.length
                  ? [{ id, type }, ...exploreFavorites]
                  : [{ id, type }],
            },
            false,
            false,
          ),
        );
      }
    } else {
      this.handleOpenSignUp();
    }
  };

  handleOpenFavorites = () => {
    const { auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      this.props.dispatch(toggleFavorites());
    } else {
      this.handleOpenSignUp();
    }
  };

  handleChooseType = (type) => {
    const { trailHead } = this.props;
    const { favorites } = trailHead;

    if (favorites) {
      this.props.dispatch(toggleFavorites());
    }

    if (type === "Challenges") {
      history.push(`/challenges`);
    } else if (type === "Training plans") {
      history.push(`/chooseplan`);
    } else if (type === "Latest") {
      history.push(`/explore`);
    } else {
      history.push(`/explore/tag/${type}`);
      history.go();
    }
  };

  render() {
    const { classes, t, trailHead, i18n, subscriptions, width } = this.props;

    const { openSignUp, type, types } = this.state;

    const { loadingExplorePosts, explorePosts, favorites } = trailHead;

    const { loadingPosts } =
      type && loadingExplorePosts && loadingExplorePosts[type]
        ? loadingExplorePosts[type]
        : {};
    const { data } =
      type && explorePosts && explorePosts[type] ? explorePosts[type] : {};

    if (data && data.length) {
      data.sort((a, b) => b.created_at - a.created_at);
    }

    const { userPlans } = this.props;
    const { currentPlan, successMessage, errorMessage } = userPlans;
    const { exploreFavorites, userType } = currentPlan || {};

    let { selectedPlan } = currentPlan || {};
    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    let filteredData = data;
    if (favorites) {
      filteredData =
        exploreFavorites &&
        exploreFavorites.length &&
        filteredData &&
        filteredData.length
          ? filteredData.filter((c) =>
              exploreFavorites.find((e) => e.id === c.id),
            )
          : [];
    }

    const items =
      filteredData && filteredData.length
        ? filteredData.map((post) => {
            return (
              <ExplorePost
                key={post.id}
                post={post}
                languageDetector={languageDetector}
                isBlock={true}
                onToggleFavorite={this.toggleFavorite}
                exploreFavorites={exploreFavorites}
              ></ExplorePost>
            );
          })
        : [];

    const isInMobileViewSm = width === "sm" || width === "xs";
    return (
      <div className={classes.container} id="thContainer">
        <div className={classes.top}>
          {isInMobileViewSm && (
            <div className={classes.searchBar}>
              <ArrowBackIosIcon
                onClick={() => this.handleBack()}
                className={classes.backIcon}
                fontSize="small"
              />

              <Typography variant="body1" className={classes.title}>
                {t(type)}
              </Typography>

              <img
                src={`${process.env.PUBLIC_URL}/icon-star-${
                  favorites ? "filled" : "outline"
                }.svg`}
                alt=""
                onClick={this.handleOpenFavorites}
                className={classes.favs}
              />
            </div>
          )}

          {!isInMobileViewSm && (
            <div className={classes.filterGrid}>
              {!isInMobileViewSm && (
                <div className={classes.filterItem}>
                  <Typography
                    variant="body1"
                    onClick={() => this.handleOpenFavorites()}
                    className={
                      favorites ? classes.filterSelected : classes.filter
                    }
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-star-filled.svg`}
                      alt=""
                      className={classes.favsDesktop}
                    />
                  </Typography>
                </div>
              )}

              {["Latest", "Challenges", "Training plans", ...types].map(
                (fType, index) => {
                  return (fType !== "Become a trail runner" ||
                    userType === 3) &&
                    (fType !== "Become an ultra runner" || userType === 1) ? (
                    <div key={index} className={classes.filterItem}>
                      <Typography
                        variant="body1"
                        onClick={() =>
                          index === 0 && favorites
                            ? this.handleBack()
                            : this.handleChooseType(fType)
                        }
                        className={
                          fType === type
                            ? classes.filterSelected
                            : classes.filter
                        }
                      >
                        {t(fType)}
                      </Typography>
                    </div>
                  ) : (
                    ""
                  );
                },
              )}
            </div>
          )}
        </div>
        <div className={classes.posts} id="posts">
          <InfiniteScroll
            dataLength={items.length} //This is important field to render the next data
            next={() => {
              data &&
                data.length > 0 &&
                this.handleLoadNextPage(data[data.length - 1].created_at);
            }}
            hasMore={true}
            scrollableTarget="thContainer"
            style={{
              display: isInMobileViewSm ? "initial" : "flex",
              flexWrap: isInMobileViewSm ? "initial" : "wrap",
            }}
          >
            {items}
          </InfiniteScroll>
          {loadingPosts && (
            <div className={classes.loading}>
              <CircularProgress variant="indeterminate" />
            </div>
          )}
        </div>
        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />

        <Dialog
          open={openSignUp}
          onClose={this.handleCloseSignUp}
          aria-labelledby="signUp"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signupdialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseSignUp}
              />
            </div>
            <AppAuthenticator initialState="signUp" />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

ExploreTag.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default connect((store) => {
  return {
    auth: store.auth,
    trailHead: store.trailHead,
    userPlans: store.userPlans,
    subscriptions: store.subscriptions,
  };
})(
  withTranslation("trailhead")(
    withWidth()(withStyles(styles, { withTheme: true })(ExploreTag)),
  ),
);
