const convertToRTFForView = (plain, videoWidth = 0) => {
  plain =
    plain && videoWidth
      ? plain.replace(
          'width="100%" height="auto" controls',
          `width="${videoWidth}" height="${videoWidth * 0.56}" controls`,
        )
      : plain;
  return { __html: plain };
};

const convertToRTFForCDK = (plain) => {
  if (!plain) {
    return "";
  }
  plain = plain
    .replace(/\n/g, "</p><p>")
    .replace(/<\/p><p><\/p><p>/g, "</p><p>");

  if (!plain.startsWith("<p>")) {
    plain = `<p>${plain}</p>`;
  }
  return plain;
};

const convertStringToHTML = (htmlString) => {
  const parser = new DOMParser();
  const html = parser.parseFromString(htmlString, "text/html");
  return html.body;
};

export { convertToRTFForView, convertToRTFForCDK, convertStringToHTML };
