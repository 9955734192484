import React from "react";
import { InfoIcon } from "../../icons";
import { Box, Typography } from "@mui/material";
import { palette } from "../../../theme/palette";

const InfoNote = ({ text = "" }) => {
  if (!text) return <></>;

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box px={"10px"} paddingTop={"2px"}>
          <InfoIcon />
        </Box>
        <Box>
          <Typography
            fontFamily="Karla"
            fontWeight={400}
            fontSize={12}
            color={palette.darkGray}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default InfoNote;
