const WorkoutActionTypes = {
  "Warm up": "Warm up",
  Run: "Run",
  Recover: "Recover",
  Rest: "Rest",
  Other: "Other",
  "Cool down": "Cool down",
  "Run Uphill": "Run Uphill",
  "Run Downhill": "Run Downhill",
  "Run Tempo": "Run Tempo",
  "Cross Training": "Cross Training",
  Stride: "Stride",
  "Conditioning training": "Conditioning training",
};

export default {
  Items: WorkoutActionTypes,
  itemsArray: Object.keys(WorkoutActionTypes).map((key) => {
    return {
      text: WorkoutActionTypes[key],
      value: key,
    };
  }),
  ItemsShortArray: Object.keys(WorkoutActionTypes).map((key) => {
    return {
      text: key,
      value: key,
    };
  }),
};
