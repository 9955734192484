import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import PlanFieldDate from "../plan-fields/plan-field-date";
import PlanFieldTime from "../plan-fields/plan-field-time";
import PlanFieldDropdown from "../plan-fields/plan-field-dropdown";
import PlanFieldPicture from "../plan-fields/plan-field-picture";
import PlanFieldTextInput from "../plan-fields/plan-field-text-input";
import PlanFieldCheckboxGroup from "../plan-fields/plan-field-checkbox-group";
import PlanFieldLabel from "../plan-fields/plan-field-label";
import PlanFieldGridLabel from "../plan-fields/plan-field-grid-label";
import PlanFieldTooltip from "../plan-fields/plan-field-tooltip";
import PlanFieldYesNo from "../plan-fields/plan-field-yes-no";
import PlanFieldButtonSelect from "../plan-fields/plan-field-button-select";
import PlanFieldGoalSelect from "../plan-fields/plan-field-goal-select";
import PlanFieldCardSelect from "../plan-fields/plan-field-card-select";
import PlanFieldChipSelect from "../plan-fields/plan-field-chip-select";
import PlanFieldStepperSelect from "../plan-fields/plan-field-stepper-select";
import PlanFieldSliderSelect from "../plan-fields/plan-field-slider";
import Invitation from "../plan-fields/invitation";
import PlanStepper from "../plan-fields/plan-stepper";
import PlanImage from "../plan-fields/plan-image";
import history from "../../../lib/history-helper";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Fields from "../../../constants/plan-drawer-fields";
import PlanFieldTimeSelect from "../plan-fields/plan-field-time-select";
import PersonalData from "../plan-fields/plan-personal-data";
import PlanCondition from "../plan-fields/plan-condition";
import OtherSports from "../plan-fields/plan-other-sports";

const styles = (theme) => ({
  grid: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      margin: "50px auto 0",
    },
  },
  backIcon: {
    // top: 15,
    top: "env(safe-area-inset-top)",
    left: 5,
    marginTop: 5,
    cursor: "pointer",
    position: "absolute",
    background: "rgba(255, 255, 255, 0.7)",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    paddingTop: 3,
    paddingLeft: 7,
    paddingBottom: 3,
    borderRadius: "100%",
    zIndex: 10,
    [theme.breakpoints.up("md")]: {
      color: "#000000",
      top: 20,
    },
  },

  backIconDesktop: {
    top: 10,
    left: 5,
    position: "absolute",
    cursor: "pointer",
    zIndex: 999,
  },
});

class PlanDrawer extends Component {
  handleBack = () => {
    history.goBack();
  };

  render() {
    const { classes, fields, onBackRequested, width } = this.props;
    const isInMobileViewSm = width === "sm" || width === "xs";
    return (
      <Grid
        container
        alignContent="center"
        alignItems="center"
        className={classes.grid}
      >
        {isInMobileViewSm ? (
          <ArrowBackIosIcon
            onClick={() =>
              onBackRequested ? onBackRequested() : this.handleBack()
            }
            className={classes.backIcon}
            fontSize="small"
          />
        ) : (
          <img
            src={`${process.env.PUBLIC_URL}/icon-arrow-back-black.svg`}
            alt=""
            className={classes.backIconDesktop}
            onClick={() =>
              onBackRequested ? onBackRequested() : this.handleBack()
            }
          />
        )}

        {fields &&
          fields.map((field, index) => {
            const { fieldType, ...rest } = field;
            switch (fieldType) {
              case Fields.Items.TEXT_INPUT:
                return <PlanFieldTextInput {...rest} key={index} />;
              case Fields.Items.CHECKBOX_GROUP:
                return <PlanFieldCheckboxGroup {...rest} key={index} />;
              case Fields.Items.DATE:
                return <PlanFieldDate {...rest} key={index} />;
              case Fields.Items.DROPDOWN:
                return <PlanFieldDropdown {...rest} key={index} />;
              case Fields.Items.LABEL:
                return <PlanFieldLabel {...rest} key={index} />;
              case Fields.Items.PICTURE:
                return <PlanFieldPicture {...rest} key={index} />;
              case Fields.Items.TIME:
                return <PlanFieldTime {...rest} key={index} />;
              case Fields.Items.TOOLTIP:
                return <PlanFieldTooltip {...rest} key={index} />;
              case Fields.Items.GRID_LABEL:
                return <PlanFieldGridLabel {...rest} key={index} />;
              case Fields.Items.YES_NO:
                return <PlanFieldYesNo {...rest} key={index} />;
              case Fields.Items.BUTTON_SELECT:
                return <PlanFieldButtonSelect {...rest} key={index} />;
              case Fields.Items.STEPPER_SELECT:
                return <PlanFieldStepperSelect {...rest} key={index} />;
              case Fields.Items.SLIDER_SELECT:
                return <PlanFieldSliderSelect {...rest} key={index} />;
              case Fields.Items.CARD_SELECT:
                return <PlanFieldCardSelect {...rest} key={index} />;
              case Fields.Items.CHIPS_SELECT:
                return <PlanFieldChipSelect {...rest} key={index} />;
              case Fields.Items.STEPPER:
                return <PlanStepper {...rest} key={index} />;
              case Fields.Items.IMAGE:
                return <PlanImage {...rest} key={index} />;
              case Fields.Items.GOAL_SELECT:
                return <PlanFieldGoalSelect {...rest} key={index} />;
              case Fields.Items.TIME_SELECT:
                return <PlanFieldTimeSelect {...rest} key={index} />;
              case Fields.Items.INVITATION:
                return <Invitation {...rest} key={index} />;
              case Fields.Items.PERSONAL_DATA:
                return <PersonalData {...rest} key={index} />;
              case Fields.Items.CONDITION:
                return <PlanCondition {...rest} key={index} />;
              case Fields.Items.OTHER_SPORT:
                return <OtherSports {...rest} key={index} />;
              default:
                return null;
            }
          })}
      </Grid>
    );
  }
}

PlanDrawer.propTypes = {
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  currentStep: PropTypes.number,
};

export default withWidth()(withStyles(styles)(memo(PlanDrawer)));
