import React, { useState } from "react";
import {
  CardContent,
  Typography,
  Box,
  withStyles,
  LinearProgress,
  makeStyles,
  Popover,
} from "@material-ui/core";
import {
  DistanceIcon,
  ElevationIcon,
  LinkIcon,
  CalendarIcon,
  DeleteIcon,
  EditIcon,
  ActionIcon,
  ShareIcon,
} from "../../icons";
import { palette } from "../../../theme/palette";
import { round } from "../../../lib/units-helper";
import { convertUnits } from "../../../lib/units-helper";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useRaceCard from "../../../hooks/useRaceCard";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import unitsHook from "../../../hooks/unitsHook";

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: "6px",
    borderRadius: "4px",
    backgroundColor: palette.lightGray,
  },
  colorPrimary: {
    backgroundColor: palette.lightGray,
  },
  bar: {
    borderRadius: "4px",
    height: "6px",
  },
}))(LinearProgress);

const useStyles = makeStyles({
  a: {
    backgroundColor: "#F93C6E",
  },
  b: {
    backgroundColor: "#4780AB",
  },
  c: {
    backgroundColor: "#6BB488",
  },
  circle: {
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginRight: "8px",
  },
  circleText: {
    color: palette.white,
    fontWeight: "bold",
  },
  texta: {
    color: "#F93C6E",
  },
  textb: {
    color: "#4780AB",
  },
  textc: {
    color: "#6BB488",
  },
  leftText: {
    marginLeft: "8px",
  },
  rightText: {
    color: palette.darkGray,
  },
  mountainIndex: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Karla",
    textAlign: "center",
    marginTop: "20px",
  },
  popover: {
    padding: "12px 16px",
    boxSizing: "border-box",
  },
  popoverParent: {
    backgroundColor: "green",
  },
  link: {
    color: palette.black,
    textDecoration: "none",
  },
  raceName: {
    wordBreak: "break-all",
    fontWeight: "700",
    maxWidth: "80%",
  },
  cardNormal: {
    backgroundColor: palette.white,
    borderRadius: 8,
    marginBottom: 16,
    boxShadow: palette.boxShadow,
  },
  cardClick: {
    backgroundColor: palette.white,
    borderRadius: 8,
    marginBottom: 16,
    boxShadow: palette.boxShadow,
    "&:hover, &:focus, &:active": {
      border: `1px solid ${palette.secondary}`,
      backgroundColor: `${palette.overlayBlue} !important`,
    },
  },
  infoIcon: {
    color: palette.deactivated,
    width: "14px",
    marginLeft: 8,
  },
});

export const RaceCard = ({
  race = {
    raceDate: "27/02/2021",
    raceDistance: "87",
    raceElevation: "4600",
    raceId: 1,
    raceName: "Run",
    racePriority: "A",
    units: "kms",
    link: null,
  },
  enableActions = {
    cardClick: false,
    mountainIndex: true,
    share: true,
    menu: true,
    edit: true,
    delete: true,
    link: true,
  },
  handleEdit,
  handleDelete,
  handleShare,
  handleCardClick = (race) => {},
}) => {
  const { t } = useTranslation("profile");
  const {
    raceDate,
    raceDistance,
    raceElevation,
    raceId,
    raceName,
    racePriority,
    link,
    createdAt,
    handleDateFormat,
    calculateValue,
    calculateDifferenceWeeks,
  } = useRaceCard(race);

  const units = unitsHook();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        flex: "auto",
      }}
    >
      <CardContent
        className={
          enableActions.cardClick ? classes.cardClick : classes.cardNormal
        }
        onClick={() => (enableActions.cardClick ? handleCardClick(race) : null)}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              className={`${classes.circle} ${
                classes[racePriority.toLowerCase()]
              }`}
            >
              <Typography className={classes.circleText} variant="h3">
                {racePriority}
              </Typography>
            </Box>
            <Typography variant="h4" className={classes.raceName}>
              {raceName}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            {enableActions.share && (
              <Box
                id="profile_share_race"
                data-testid="profile_share_race"
                onClick={() => handleShare(race)}
              >
                <ShareIcon />
              </Box>
            )}
            {enableActions.menu && (
              <Box
                sx={{ marginLeft: "8px" }}
                id="profile_option_race"
                data-testid="profile_option_race"
                onClick={handleClick}
              >
                <ActionIcon width={40} height={40} />
              </Box>
            )}
          </Box>

          {enableActions.menu && (
            <Popover
              id="menu-popover"
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box className={classes.popover}>
                {enableActions.edit && (
                  <Box
                    id="profile_option_race_#1"
                    data-testid="profile_option_race_#1"
                    display="flex"
                    alignItems="center"
                    marginBottom="8px"
                    onClick={(event) => {
                      event.preventDefault();
                      handleEdit(race);
                      handleClose();
                    }}
                  >
                    <EditIcon />
                    <Typography style={{ marginLeft: "8px" }}>
                      {t("profile.card.options.edit")}
                    </Typography>
                  </Box>
                )}

                {enableActions.delete && (
                  <Box
                    id="profile_option_race_#2"
                    data-testid="profile_option_race_#2"
                    display="flex"
                    alignItems="center"
                    marginBottom="8px"
                    onClick={(event) => {
                      event.preventDefault();
                      handleDelete(raceId);
                      handleClose();
                    }}
                  >
                    <DeleteIcon />
                    <Typography style={{ marginLeft: "8px" }}>
                      {t("profile.card.options.delete")}
                    </Typography>
                  </Box>
                )}

                {enableActions.link && link && (
                  <a
                    id="profile_option_race_#3"
                    data-testid="profile_option_race_#3"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    <Box display="flex" alignItems="center">
                      <LinkIcon />
                      <Typography style={{ marginLeft: "8px" }}>
                        {t("profile.card.options.visitLink")}
                      </Typography>
                    </Box>
                  </a>
                )}
              </Box>
            </Popover>
          )}
        </Box>

        {calculateDifferenceWeeks(raceDate).diffNumber >= 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px 0px",
            }}
          >
            <Typography variant="h5">
              {t("profile.races.card.youAreRacingIn")}{" "}
              <Box
                component="span"
                className={classes[`text${racePriority.toLowerCase()}`]}
              >
                {calculateDifferenceWeeks(raceDate).diff}
              </Box>
            </Typography>
          </Box>
        )}

        {calculateDifferenceWeeks(raceDate).diffNumber >= 0 && (
          <BorderLinearProgress
            variant="determinate"
            value={createdAt(raceDate)}
            classes={{ bar: classes[racePriority.toLowerCase()] }}
          />
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Box display="flex" alignItems="center" marginBottom="8px">
            <CalendarIcon />
            <Typography color="text.secondary" className={classes.leftText}>
              {t("profile.card.date")}
            </Typography>
          </Box>

          <Typography sx={{ mb: 1.5 }} className={classes.rightText}>
            {handleDateFormat(raceDate)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center" marginBottom="8px">
            <ElevationIcon />
            <Typography sx={{ mb: 1.5 }} className={classes.leftText}>
              {t("profile.card.elevation")}
            </Typography>
          </Box>
          <Typography
            sx={{ mb: 1.5 }}
            color="text.primary"
            className={classes.rightText}
          >
            {round(convertUnits(raceElevation, "kms", units, "elevation"), 1)}{" "}
            {units === "kms" ? t("profile.unit.m") : t("profile.unit.feet")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            <DistanceIcon />
            <Typography sx={{ mb: 1.5 }} className={classes.leftText}>
              {t("profile.card.distance")}
            </Typography>
          </Box>
          <Typography sx={{ mb: 1.5 }} className={classes.rightText}>
            {round(convertUnits(raceDistance, "kms", units, "distance"), 1)}{" "}
            {units === "kms" ? t("profile.unit.km") : t("profile.unit.miles")}
          </Typography>
        </Box>

        <Typography className={classes.mountainIndex} variant="h4">
          {t("mountainIndex.title", { ns: "mountainIndex" })} =
          <span style={{ marginLeft: 6 }}>
            {calculateValue(raceDistance, raceElevation)}
          </span>
          {enableActions.mountainIndex && (
            <Link
              id="mountain_index_info"
              data-testid="mountain_index_info"
              to="/profile/mountain-index"
            >
              <InfoIcon className={classes.infoIcon} />
            </Link>
          )}
        </Typography>
      </CardContent>
    </Box>
  );
};
