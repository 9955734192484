import React from "react";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  SvgIcon,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import { LightWorkoutColors } from "../../../constants/workout-types";
import { palette } from "../../../theme/palette";

const WorkoutCard = ({
  primaryText,
  secondaryText,
  withCheck = false,
  isDone = false,
  variant = "NoWorkout",
  margin = "auto",
  disabled = false,
  height = "74px",
  paperStylesProp = {},
  onClick = () => {},
}) => {
  const classes = useStyles({
    variant,
    height,
    margin,
    withCheck,
    background: LightWorkoutColors[variant],
    filter: disabled ? "grayscale(0.9)" : "grayscale(0)",
  });

  return (
    <Paper style={paperStylesProp} className={classes.paper} onClick={onClick}>
      <ListItem
        className={classes.listItemCore}
        classes={{ gutters: classes.listItemGutters }}
      >
        <ListItemText>
          <Typography className={classes.secondaryText}>
            <SvgIcon style={{ fontSize: 14 }} viewBox="0 0 24 24">
              <path
                d="M8.99988 19.0002C7.14336 19.0002 5.36288 18.2627 4.05013 16.95C2.73738 15.6372 1.99988 13.8568 1.99988 12.0002C1.99988 10.1437 2.73738 8.36325 4.05013 7.0505C5.36288 5.73774 7.14336 5.00024 8.99988 5.00024C10.8564 5.00024 12.6369 5.73774 13.9496 7.0505C15.2624 8.36325 15.9999 10.1437 15.9999 12.0002C15.9999 13.8568 15.2624 15.6372 13.9496 16.95C12.6369 18.2627 10.8564 19.0002 8.99988 19.0002ZM16.0299 6.39024L16.7856 5.63454C17.159 5.26114 17.1811 4.65829 16.8103 4.28231C16.782 4.25367 16.7537 4.22517 16.7252 4.19679C16.3524 3.8251 15.7526 3.85153 15.383 4.2264L14.6199 5.00024C13.0699 3.74024 11.1199 3.00024 8.99988 3.00024C6.61293 3.00024 4.32374 3.94846 2.63592 5.63628C0.948089 7.32411 -0.00012207 9.6133 -0.00012207 12.0002C-0.00012207 14.3872 0.948089 16.6764 2.63592 18.3642C4.32374 20.052 6.61293 21.0002 8.99988 21.0002C13.9999 21.0002 17.9999 16.9702 17.9999 12.0002C17.9999 9.88024 17.2599 7.93024 16.0299 6.39024ZM7.99988 12.0002C7.99988 12.5525 8.44759 13.0002 8.99988 13.0002C9.55216 13.0002 9.99988 12.5525 9.99988 12.0002V8.00024C9.99988 7.44796 9.55216 7.00024 8.99988 7.00024C8.44759 7.00024 7.99988 7.44796 7.99988 8.00024V12.0002ZM11.9999 1.00024C11.9999 0.447959 11.5522 0.000244141 10.9999 0.000244141H6.99988C6.44759 0.000244141 5.99988 0.447959 5.99988 1.00024C5.99988 1.55253 6.44759 2.00024 6.99988 2.00024H10.9999C11.5522 2.00024 11.9999 1.55253 11.9999 1.00024Z"
                fill={variant == "Recovery" ? palette.black : palette.white}
              />
            </SvgIcon>
            {secondaryText}
          </Typography>
          <Typography className={classes.primaryText}>{primaryText}</Typography>
        </ListItemText>
        {withCheck && (
          <ListItemIcon classes={{ root: classes.lisItemIcon }}>
            <CheckIcon
              style={{
                color: isDone
                  ? variant == "Recovery"
                    ? palette.black
                    : palette.white
                  : palette.lightWhite,
              }}
            />
          </ListItemIcon>
        )}
      </ListItem>
    </Paper>
  );
};

const useStyles = makeStyles({
  listItemCore: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 0,
  },
  listItemGutters: {
    padding: 0,
  },
  lisItemIcon: {
    justifyContent: "end",
  },
  primaryText: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Karla",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textWrap: "nowrap",
    textAlign: "left",
    justifyContent: (props) => (props.withCheck ? "start" : "center"),
    color: (props) => (props.variant === "Recovery" ? "#000" : "#fff"),
  },
  secondaryText: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Karla",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    textAlign: "left",
    color: (props) => (props.variant === "Recovery" ? "#000" : "#fff"),
    justifyContent: (props) => (props.withCheck ? "start" : "center"),
  },
  paper: {
    backgroundColor: (props) => props.background,
    display: "flex",
    padding: "8px 24px",
    borderRadius: 4,
    boxShadow: "none",
    height: (props) => props.height,
    margin: (props) => props.margin,
    filter: (props) => props.filter,
  },
});

export default WorkoutCard;
