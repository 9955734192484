import moment from "moment";
import RaceCounts, { levelScale } from "../constants/race-counts";
import { dateFormat } from "./date-helper";

const getPlanLevel = (currentTraining) => {
  let level = 2;
  if (currentTraining && currentTraining.raceCount) {
    const raceCountsItemsArray = RaceCounts.itemsArray;
    switch (currentTraining.raceCount) {
      case `${raceCountsItemsArray[0].value},${raceCountsItemsArray[1].value}`:
        level = 0;
        break;
      case `${raceCountsItemsArray[2].value},${raceCountsItemsArray[3].value}`:
        level = 1;
        break;
      default:
        level = 2;
    }
  }
  return level;
};

const getCustomLevel = (currentWorkoutDate, chainedPlans = []) => {
  let level;
  const currentWeekDate = moment(currentWorkoutDate, dateFormat);

  if (chainedPlans && chainedPlans.length) {
    for (let index = 0; index < chainedPlans.length; index++) {
      const element = chainedPlans[index];
      if (
        moment(currentWeekDate).isSameOrAfter(
          moment(element.startingDate, dateFormat),
        )
      ) {
        level = element.level;
      }
    }
  }

  return levelScale[level];
};

const moveWorkout = (currentTraining, workoutsToMoveData) => {
  const {
    plannedDate1,
    workoutId1,
    relativePos1,
    plannedDate2,
    workoutId2,
    relativePos2,
  } = workoutsToMoveData;

  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  if (!plannedDate1 && !workoutId1 && !plannedDate2 && !workoutId2) {
    return { currentTraining };
  }

  if (plannedDate1 && workoutId1) {
    const trainingDay1 = currentTraining.days.find(
      (trDay) =>
        trDay &&
        trDay[0].plannedDate === plannedDate1 &&
        trDay[0].id === workoutId1,
    );
    const newDate = moment(plannedDate1, "DD/MM/YYYY")
      .startOf("day")
      .add(relativePos1, "days");
    if (trainingDay1 && trainingDay1[0]) {
      trainingDay1[0].plannedDate = newDate.format("DD/MM/YYYY");
    }
  }

  if (plannedDate2 && workoutId2) {
    const trainingDay2 = currentTraining.days.find(
      (trDay) =>
        trDay &&
        trDay[0].plannedDate === plannedDate2 &&
        trDay[0].id === workoutId2,
    );
    const newDate = moment(plannedDate2, "DD/MM/YYYY")
      .startOf("day")
      .add(relativePos2, "days");

    if (trainingDay2 && trainingDay2[0]) {
      trainingDay2[0].plannedDate = newDate.format("DD/MM/YYYY");
    }
  }

  currentTraining.days = currentTraining.days.filter((trDay) => trDay !== null);

  currentTraining.days.sort((a, b) => {
    let aMoment;
    if (a && a[0] && a[0].plannedDate) {
      aMoment = moment(a[0].plannedDate, "DD/MM/YYYY").startOf("day");
    } else {
      return -1;
    }

    let bMoment;
    if (b && b[0] && b[0].plannedDate) {
      bMoment = moment(b[0].plannedDate, "DD/MM/YYYY").startOf("day");
    } else {
      return -1;
    }

    return aMoment.diff(bMoment, "days");
  });

  currentTraining.lastModified = new Date().getTime();

  return { currentTraining };
};

const redoWeek = (currentTraining, data) => {
  const { startDate, toDate } = data;

  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  if (!startDate) {
    return { currentTraining };
  }

  const from = moment(startDate, "DD/MM/YYYY").startOf("isoWeek");
  const to = moment(startDate, "DD/MM/YYYY")
    .startOf("isoWeek")
    .add(6, "days");
  const today = moment(toDate, "DD/MM/YYYY").startOf("isoWeek");
  const weekDiff = today.diff(from, "weeks");
  let newDays = [];

  currentTraining.days.forEach((d) => {
    if (d && d[0]) {
      const wDate = moment(d[0].plannedDate, "DD/MM/YYYY");
      if (wDate.isBetween(from, to, "days", "[]")) {
        const copiedDay = JSON.parse(JSON.stringify(d[0]));
        delete copiedDay.comments;
        delete copiedDay.isDone;
        delete copiedDay.energyLevel;
        copiedDay.plannedDate = moment(wDate)
          .add(weekDiff, "weeks")
          .format("DD/MM/YYYY");
        newDays.push([copiedDay]);
      }
      if (
        wDate.isSameOrAfter(
          moment(toDate, "DD/MM/YYYY").startOf("isoWeek"),
          "days",
        )
      ) {
        d[0].plannedDate = wDate.add(7, "days").format("DD/MM/YYYY");
      }
    }
    newDays.push(d);
  });

  newDays = newDays.filter((trDay) => trDay !== null);

  newDays.sort((a, b) => {
    let aMoment;
    if (a && a[0] && a[0].plannedDate) {
      aMoment = moment(a[0].plannedDate, "DD/MM/YYYY").startOf("day");
    } else {
      return -1;
    }

    let bMoment;
    if (b && b[0] && b[0].plannedDate) {
      bMoment = moment(b[0].plannedDate, "DD/MM/YYYY").startOf("day");
    } else {
      return -1;
    }

    return aMoment.diff(bMoment, "days");
  });

  currentTraining.durationInWeeks = (
    +currentTraining.durationInWeeks + 1
  ).toString();

  if (currentTraining.chainedPlans && currentTraining.chainedPlans.length) {
    currentTraining.chainedPlans = rearrangeChainedPlansBreaks(
      currentTraining.chainedPlans,
      from,
      1,
    );
  }

  currentTraining.lastModified = new Date().getTime();

  return {
    currentTraining: { ...currentTraining, days: newDays },
    currentTrainingEndDate: calculateCurrentTrainingPlanEndDate(
      currentTraining,
    ),
  };
};

const addEasyWeek = (currentTraining, data) => {
  const { startDate, days } = data;

  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  if (!startDate) {
    return { currentTraining };
  }

  const from = moment(startDate, "DD/MM/YYYY").startOf("isoWeek");

  let newDays = [];
  currentTraining.days.forEach((d) => {
    if (d && d[0]) {
      const wDate = moment(d[0].plannedDate, "DD/MM/YYYY");
      if (wDate.isSameOrAfter(moment(from).startOf("isoWeek"), "days")) {
        d[0].plannedDate = wDate.add(7, "days").format("DD/MM/YYYY");
      }
    }
    newDays.push(d);
  });

  for (let currentIndex = 0; currentIndex < 7; currentIndex++) {
    if (days[currentIndex] && days[currentIndex][0]) {
      const newDay = days[currentIndex][0];
      newDay.plannedDate = moment(from)
        .add(currentIndex, "days")
        .format("DD/MM/YYYY");
      newDays.push([newDay]);
    }
  }

  newDays = newDays.filter((trDay) => trDay !== null);

  newDays.sort((a, b) => {
    let aMoment;
    if (a && a[0] && a[0].plannedDate) {
      aMoment = moment(a[0].plannedDate, "DD/MM/YYYY").startOf("day");
    } else {
      return -1;
    }

    let bMoment;
    if (b && b[0] && b[0].plannedDate) {
      bMoment = moment(b[0].plannedDate, "DD/MM/YYYY").startOf("day");
    } else {
      return -1;
    }

    return aMoment.diff(bMoment, "days");
  });

  currentTraining.durationInWeeks = (
    +currentTraining.durationInWeeks + 1
  ).toString();

  if (currentTraining.chainedPlans && currentTraining.chainedPlans.length) {
    currentTraining.chainedPlans = rearrangeChainedPlansBreaks(
      currentTraining.chainedPlans,
      from,
      1,
    );
  }

  currentTraining.lastModified = new Date().getTime();

  return {
    currentTraining: { ...currentTraining, days: newDays },
    currentTrainingEndDate: calculateCurrentTrainingPlanEndDate(
      currentTraining,
    ),
  };
};

const addBlockWeek = (currentTraining, data) => {
  const { startDate, days, plan } = data;

  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  if (!startDate) {
    return { currentTraining };
  }

  const from = moment(startDate, "DD/MM/YYYY").startOf("isoWeek");

  let newDays = [];
  currentTraining.days.forEach((d) => {
    if (d && d[0]) {
      const wDate = moment(d[0].plannedDate, "DD/MM/YYYY");
      if (wDate.isSameOrAfter(moment(from).startOf("isoWeek"), "days")) {
        d[0].plannedDate = wDate.add(7, "days").format("DD/MM/YYYY");
      }
    }
    newDays.push(d);
  });

  for (let currentIndex = 0; currentIndex < 7; currentIndex++) {
    if (days[currentIndex] && days[currentIndex][0]) {
      const newDay = days[currentIndex][0];
      newDay.plannedDate = moment(from)
        .add(currentIndex, "days")
        .format("DD/MM/YYYY");
      newDays.push([newDay]);
    }
  }

  newDays = newDays.filter((trDay) => trDay !== null);

  newDays.sort((a, b) => {
    let aMoment;
    if (a && a[0] && a[0].plannedDate) {
      aMoment = moment(a[0].plannedDate, "DD/MM/YYYY").startOf("day");
    } else {
      return -1;
    }

    let bMoment;
    if (b && b[0] && b[0].plannedDate) {
      bMoment = moment(b[0].plannedDate, "DD/MM/YYYY").startOf("day");
    } else {
      return -1;
    }

    return aMoment.diff(bMoment, "days");
  });

  currentTraining.durationInWeeks = (
    +currentTraining.durationInWeeks + 1
  ).toString();

  const currentTrainingEndDate = calculateCurrentTrainingPlanEndDate(
    currentTraining,
  );

  if (plan && plan.subcategory) {
    if (currentTraining.chainedPlans && currentTraining.chainedPlans.length) {
      let newChained = [...currentTraining.chainedPlans];
      const brokenInto = [];
      for (let index = 0; index < newChained.length; index++) {
        const element = newChained[index];

        if (
          moment(element.startingDate, dateFormat).isSameOrAfter(from, "days")
        ) {
          element.startingDate = moment(
            newChained[index].startingDate,
            "DD/MM/YYYY",
          )
            .add(7, "days")
            .format("DD/MM/YYYY");
        } else {
          if (
            (index === newChained.length - 1 &&
              moment.unix(currentTrainingEndDate).isAfter(from)) ||
            (index < newChained.length - 1 &&
              moment(newChained[index + 1].startingDate, "DD/MM/YYYY").isAfter(
                from,
                "days",
              ))
          ) {
            brokenInto.push({
              subcategory: element.subcategory,
              startingDate: moment(from, "DD/MM/YYYY")
                .add(7, "days")
                .format("DD/MM/YYYY"),
            });
          }
        }
      }

      newChained = [
        ...newChained,
        ...brokenInto,
        {
          subcategory: plan.subcategory,
          startingDate: from.format("DD/MM/YYYY"),
        },
      ];

      newChained.sort((a, b) => {
        let aMoment;
        if (a.startingDate) {
          aMoment = moment(a.startingDate, "DD/MM/YYYY").startOf("day");
        } else {
          return -1;
        }

        let bMoment;
        if (b.startingDate) {
          bMoment = moment(b.startingDate, "DD/MM/YYYY").startOf("day");
        } else {
          return -1;
        }

        return aMoment.diff(bMoment, "days");
      });

      currentTraining.chainedPlans = newChained;
    } else {
      currentTraining.chainedPlans = [
        {
          subcategory: currentTraining.subcategory,
          startingDate: currentTraining.startingDate,
        },
        {
          subcategory: plan.subcategory,
          startingDate: from.format("DD/MM/YYYY"),
        },
      ];

      if (moment.unix(currentTrainingEndDate).isAfter(from)) {
        currentTraining.chainedPlans.push({
          subcategory: currentTraining.subcategory,
          startingDate: moment(from)
            .add(7, "days")
            .format("DD/MM/YYYY"),
        });
      }
    }
  }

  currentTraining.lastModified = new Date().getTime();

  return {
    currentTraining: { ...currentTraining, days: newDays },
    currentTrainingEndDate,
  };
};

const addWorkout = (currentTraining, workout) => {
  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  if (!workout) {
    return { currentTraining };
  }

  currentTraining.days = currentTraining.days.filter(
    (trDay) =>
      trDay && trDay[0] && trDay[0].plannedDate !== workout.plannedDate,
  );

  currentTraining.days.push([workout]);

  currentTraining.days = currentTraining.days.filter((trDay) => trDay !== null);

  currentTraining.days.sort((a, b) => {
    let aMoment;
    if (a && a[0] && a[0].plannedDate) {
      aMoment = moment(a[0].plannedDate, "DD/MM/YYYY").startOf("day");
    } else {
      return -1;
    }

    let bMoment;
    if (b && b[0] && b[0].plannedDate) {
      bMoment = moment(b[0].plannedDate, "DD/MM/YYYY").startOf("day");
    } else {
      return -1;
    }

    return aMoment.diff(bMoment, "days");
  });

  currentTraining.lastModified = new Date().getTime();

  return {
    currentTraining,
  };
};

const currentTrainingUpdateWorkoutIsDone = (currentTraining, data) => {
  const { plannedDate, workoutId, isDone } = data;

  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  if (!plannedDate && !workoutId && isDone) {
    return { currentTraining };
  }

  const newTraining = {
    ...currentTraining,
  };

  const trainingDay = newTraining.days.find(
    (trDay) =>
      trDay &&
      trDay[0].plannedDate === plannedDate &&
      trDay[0].id === workoutId,
  );
  const foundWorkout =
    trainingDay && trainingDay.length > 0 ? trainingDay[0] : undefined;
  if (foundWorkout) {
    if (typeof isDone !== undefined) foundWorkout.isDone = isDone;

    let numDone = newTraining.numDone ? newTraining.numDone : 0;
    if (isDone) {
      numDone++;
    } else {
      numDone--;
      if (numDone < 0) {
        numDone = 0;
      }
    }

    newTraining.numDone = numDone;
    newTraining.lastModified = new Date().getTime();

    return { currentTraining: newTraining };
  } else {
    return { currentTraining };
  }
};

const currentTrainingUpdateWorkoutEnergyLevel = (currentTraining, data) => {
  const {
    plannedDate,
    workoutId,
    energyLevel,
    rpe,
    visualRpe,
    visualEnergy,
  } = data;

  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  if (!plannedDate && !workoutId && energyLevel) {
    return { currentTraining };
  }

  const newTraining = {
    ...currentTraining,
  };

  const trainingDay = newTraining.days.find(
    (trDay) =>
      trDay &&
      trDay[0].plannedDate === plannedDate &&
      trDay[0].id === workoutId,
  );
  const foundWorkout =
    trainingDay && trainingDay.length > 0 ? trainingDay[0] : undefined;
  if (foundWorkout) {
    if (typeof energyLevel !== undefined) {
      foundWorkout.energyLevel = energyLevel;
      foundWorkout.visualEnergy = visualEnergy;
    }

    if (typeof rpe !== undefined) {
      foundWorkout.rpe = rpe;
      foundWorkout.visualRpe = visualRpe;
    }

    newTraining.lastModified = new Date().getTime();
    return { currentTraining: { ...newTraining } };
  } else {
    return { currentTraining };
  }
};

const editWorkout = (currentTraining, data) => {
  const { plannedDate, workoutId } = data;

  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  if (!plannedDate || !workoutId) {
    return { currentTraining };
  }

  const newTraining = {
    ...JSON.parse(JSON.stringify(currentTraining)),
  };

  const trainingDayIndex = newTraining.days.findIndex(
    (trDay) =>
      trDay &&
      trDay[0].plannedDate === plannedDate &&
      trDay[0].id === workoutId,
  );

  if (trainingDayIndex > -1) {
    let foundWorkout =
      newTraining.days[trainingDayIndex] &&
      newTraining.days[trainingDayIndex].length > 0
        ? newTraining.days[trainingDayIndex][0]
        : undefined;
    if (foundWorkout) {
      if (typeof data !== undefined) {
        foundWorkout = {
          ...JSON.parse(JSON.stringify(foundWorkout)),
          ...JSON.parse(JSON.stringify(data)),
        };
        newTraining.days[trainingDayIndex][0] = foundWorkout;
      } else {
        return { currentTraining };
      }
    } else {
      return { currentTraining };
    }
  } else {
    return { currentTraining };
  }

  newTraining.lastModified = new Date().getTime();
  return { currentTraining: newTraining };
};

const deleteWorkout = (currentTraining, workoutId, plannedDate) => {
  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  if (!workoutId || !plannedDate) {
    return { currentTraining };
  }

  const newTraining = {
    ...currentTraining,
  };

  newTraining.days = newTraining.days.filter((trDay) => trDay !== null);

  newTraining.days = newTraining.days.filter(
    (trDay) =>
      !(
        trDay &&
        trDay[0].plannedDate === plannedDate &&
        trDay[0].id === workoutId
      ),
  );

  newTraining.lastModified = new Date().getTime();
  return { currentTraining: newTraining };
};

const addWeek = (currentTraining, fromPlannedDate) => {
  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  if (!fromPlannedDate) {
    return { currentTraining };
  }

  const newTraining = {
    ...currentTraining,
  };

  const from = moment(fromPlannedDate, "DD/MM/YYYY");
  newTraining.days = currentTraining.days.map((d) => {
    if (d && d[0]) {
      const wDate = moment(d[0].plannedDate, "DD/MM/YYYY");
      if (!wDate.isBefore(from, "days")) {
        d[0].plannedDate = wDate.add(7, "days").format("DD/MM/YYYY");
      }
    }
    return d;
  });
  newTraining.durationInWeeks = (+newTraining.durationInWeeks + 1).toString();

  if (currentTraining.chainedPlans && currentTraining.chainedPlans.length) {
    currentTraining.chainedPlans = rearrangeChainedPlansBreaks(
      currentTraining.chainedPlans,
      from,
      1,
    );
  }

  newTraining.lastModified = new Date().getTime();
  return {
    currentTraining: newTraining,
    currentTrainingEndDate: calculateCurrentTrainingPlanEndDate(
      currentTraining,
    ),
  };
};

const removeWeek = (currentTraining, fromPlannedDate) => {
  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  if (!fromPlannedDate) {
    return { currentTraining };
  }

  const newTraining = {
    ...currentTraining,
  };

  const from = moment(fromPlannedDate, "DD/MM/YYYY");
  const to = moment(fromPlannedDate, "DD/MM/YYYY").add(6, "days");
  newTraining.days = currentTraining.days
    .map((d) => {
      if (d && d[0]) {
        const wDate = moment(d[0].plannedDate, "DD/MM/YYYY");
        if (wDate.isBetween(from, to, "days", "[]")) {
          return null;
        }
        if (!wDate.isBefore(from, "days")) {
          d[0].plannedDate = wDate.add(-7, "days").format("DD/MM/YYYY");
        }
      }
      return d;
    })
    .filter((dd) => dd !== null);
  newTraining.durationInWeeks = (+newTraining.durationInWeeks - 1).toString();

  if (currentTraining.chainedPlans && currentTraining.chainedPlans.length) {
    currentTraining.chainedPlans = rearrangeChainedPlansBreaks(
      currentTraining.chainedPlans,
      from,
      -1,
    );
  }

  newTraining.lastModified = new Date().getTime();
  return {
    currentTraining: newTraining,
    currentTrainingEndDate: calculateCurrentTrainingPlanEndDate(
      currentTraining,
    ),
  };
};

const chainTraining = (
  currentTraining,
  selectedTrainingPlan,
  position,
  level,
) => {
  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  if (!selectedTrainingPlan && !position) {
    return { currentTraining };
  }

  const chainedPlans = currentTraining.chainedPlans
    ? currentTraining.chainedPlans
    : [];

  const from =
    position === 1 || (position === 0 && chainedPlans.length === 0)
      ? moment(currentTraining.startingDate, "DD/MM/YYYY")
          .add(+currentTraining.durationInWeeks, "weeks")
          .startOf("isoWeek")
      : moment(chainedPlans[0].startingDate, "DD/MM/YYYY").startOf("isoWeek");

  position === 0
    ? chainedPlans.unshift({
        subcategory: selectedTrainingPlan.subcategory,
        startingDate: from.format("DD/MM/YYYY"),
        level,
      })
    : chainedPlans.push({
        subcategory: selectedTrainingPlan.subcategory,
        startingDate: from.format("DD/MM/YYYY"),
        level,
      });

  if (position === 0 && chainedPlans.length > 1) {
    for (
      let currentIndex = 1;
      currentIndex < chainedPlans.length;
      currentIndex++
    ) {
      const wDate = moment(
        chainedPlans[currentIndex].startingDate,
        "DD/MM/YYYY",
      ).add(selectedTrainingPlan.days.length, "days");
      chainedPlans[currentIndex].startingDate = wDate.format("DD/MM/YYYY");
    }
  }

  let newDays = [];
  currentTraining.days.forEach((d) => {
    if (d && d[0]) {
      const wDate = moment(d[0].plannedDate, "DD/MM/YYYY");
      if (wDate.isSameOrAfter(moment(from).startOf("isoWeek"), "days")) {
        d[0].plannedDate = wDate
          .add(selectedTrainingPlan.days.length, "days")
          .format("DD/MM/YYYY");
      }
    }
    newDays.push(d);
  });

  for (
    let currentIndex = 0;
    currentIndex < selectedTrainingPlan.days.length;
    currentIndex++
  ) {
    if (
      selectedTrainingPlan.days[currentIndex] &&
      selectedTrainingPlan.days[currentIndex][0]
    ) {
      const newDay = selectedTrainingPlan.days[currentIndex][0];
      newDay.plannedDate = moment(from)
        .add(currentIndex, "days")
        .format("DD/MM/YYYY");
      newDays.push([newDay]);
    }
  }

  newDays = newDays.filter((trDay) => trDay !== null && trDay !== undefined);

  newDays.sort((a, b) => {
    let aMoment;
    if (a && a[0] && a[0].plannedDate) {
      aMoment = moment(a[0].plannedDate, "DD/MM/YYYY").startOf("day");
    } else {
      return -1;
    }

    let bMoment;
    if (b && b[0] && b[0].plannedDate) {
      bMoment = moment(b[0].plannedDate, "DD/MM/YYYY").startOf("day");
    } else {
      return -1;
    }

    return aMoment.diff(bMoment, "days");
  });

  currentTraining.durationInWeeks = getWeekDurations({
    ...currentTraining,
    days: newDays,
  }).toString();

  currentTraining.lastModified = new Date().getTime();
  return {
    currentTraining: { ...currentTraining, days: newDays, chainedPlans },
    currentTrainingEndDate: calculateCurrentTrainingPlanEndDate({
      ...currentTraining,
      days: newDays,
      chainedPlans,
    }),
  };
};

const removeChained = (currentTraining, planIndex) => {
  if (!currentTraining || !currentTraining.days) {
    return { currentTraining };
  }

  const chainedPlans = currentTraining.chainedPlans
    ? currentTraining.chainedPlans
    : [];

  if (planIndex + 1 > chainedPlans.length) {
    return { currentTraining };
  }

  const newTraining = {
    ...currentTraining,
  };

  const from = moment(chainedPlans[planIndex].startingDate, "DD/MM/YYYY");
  const to =
    chainedPlans.length > planIndex + 1
      ? moment(chainedPlans[planIndex + 1].startingDate, "DD/MM/YYYY")
      : moment(currentTraining.startingDate, "DD/MM/YYYY").add(
          +currentTraining.durationInWeeks,
          "weeks",
        );

  const diff = to.diff(from, "days");

  newTraining.days = currentTraining.days
    .map((d) => {
      if (d && d[0]) {
        const wDate = moment(d[0].plannedDate, "DD/MM/YYYY");
        if (wDate.isBetween(from, to, "days", "[]")) {
          return null;
        }
        if (!wDate.isBefore(from, "days")) {
          d[0].plannedDate = wDate.add(diff, "days").format("DD/MM/YYYY");
        }
      }
      return d;
    })
    .filter((dd) => dd !== null);

  newTraining.durationInWeeks = getWeekDurations(newTraining).toString();

  for (let index = planIndex + 1; index < chainedPlans.length; index++) {
    const element = chainedPlans[index];
    element.startingDate = moment(
      chainedPlans[index].startingDate,
      "DD/MM/YYYY",
    )
      .add(diff * -1, "days")
      .format("DD/MM/YYYY");
  }

  newTraining.chainedPlans.splice(planIndex, 1);

  newTraining.lastModified = new Date().getTime();
  return {
    currentTraining: newTraining,
    currentTrainingEndDate: calculateCurrentTrainingPlanEndDate(
      currentTraining,
    ),
  };
};

const calculateCurrentTrainingPlanEndDate = (trainingToPatch) => {
  if (trainingToPatch) {
    const { days } = trainingToPatch;

    let endMoment = moment();
    if (days) {
      const workouts = days.filter((d) => d !== null);

      endMoment = moment(
        workouts[workouts.length - 1][0].plannedDate,
        "DD/MM/YYYY",
      )
        .add(1, "week")
        .startOf("isoWeek");
    }

    if (endMoment) {
      return endMoment
        .endOf("isoWeek")
        .endOf("day")
        .unix();
    }
  }
  return undefined;
};

const rearrangeChainedPlansBreaks = (chainedPlans, date, sign) => {
  const newChained = [...chainedPlans];
  for (let index = 0; index < newChained.length; index++) {
    const element = newChained[index];

    if (moment(element.startingDate, dateFormat).isSameOrAfter(date, "days")) {
      element.startingDate = moment(
        newChained[index].startingDate,
        "DD/MM/YYYY",
      )
        .add(7 * sign, "days")
        .format("DD/MM/YYYY");
    }
  }

  return newChained;
};

const getWeekDurations = (fullTraining) => {
  const startingDate =
    fullTraining && fullTraining.days && fullTraining.days.length
      ? fullTraining.days[0][0].plannedDate
      : moment().format(dateFormat);

  const endDate =
    fullTraining && fullTraining.days && fullTraining.days.length
      ? fullTraining.days[fullTraining.days.length - 1][0].plannedDate
      : moment().format(dateFormat);

  return (
    moment(endDate, dateFormat)
      .startOf("isoWeek")
      .diff(moment(startingDate, dateFormat).startOf("isoWeek"), "week") + 1
  );
};

const getFixCustomLevel = (plannedDate, chainedPlans, currentTraining) => {
  return !isNaN(getCustomLevel(plannedDate, chainedPlans))
    ? getCustomLevel(plannedDate, chainedPlans)
    : getPlanLevel(currentTraining);
};

export {
  moveWorkout,
  redoWeek,
  addEasyWeek,
  addWorkout,
  currentTrainingUpdateWorkoutIsDone,
  currentTrainingUpdateWorkoutEnergyLevel,
  editWorkout,
  deleteWorkout,
  addWeek,
  removeWeek,
  chainTraining,
  removeChained,
  getPlanLevel,
  addBlockWeek,
  getCustomLevel,
  getFixCustomLevel,
};
