export default {
  EXPLORE_MONTHLY: "$rc_monthly",
  EXPLORE_SIX_MONTHS: "$rc_six_month",
  EXPLORE_YEARLY: "$rc_annual",

  EXPLORE_FULL_ACCESS: "explore101",
  EXPLORE_FULL_COACHING: "explore102",
  EXPLORE_FULL_COACHING_SIX_MONTHS: "explore106",
  PREMIUM_MONTHLY: "premium0",
};
