import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import SocialProfile from "./trail-head/social-profile";
import PostDrawer from "./trail-head/post-drawer";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  getPosts,
  getPublicGroups,
  getTags,
  addAllez,
  addComment,
  deletePost,
  deleteComment,
  editPost,
  addReport,
  newPost,
  cleanNewPost,
  addPost,
  joinGroup,
  addGroupActivities,
  getPinnedPosts,
} from "../actions/trail-head-action";
import Post from "./trail-head/post";
import PublicGroup from "./trail-head/public-group";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  Grid,
  InputAdornment,
  Fab,
  Avatar,
} from "@material-ui/core";
import {
  updatePlan,
  clearSuccessMessage,
  clearErrorMessage,
  toggleOpenMenu,
  getUserActivities,
  clearCurrentUserActivity,
} from "../actions/user-plans-action";
import UserGroups from "../constants/user-groups";
import AppAuthenticator from "./app-authenticator";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import { setErrorMessage } from "../actions/user-plans-action";
import {
  firebaseInit,
  logEvent,
  setEventsMetadata,
} from "../lib/events-helper";
import { Storage } from "aws-amplify";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import withWidth from "@material-ui/core/withWidth";
import history from "../lib/history-helper";
import { getLevel } from "../lib/rates-helper";
import AllRaces from "../components/trail-head/social-profile/races/all-races";
import AddResult from "../components/trail-head/social-profile/races/add-result";
import DashboardRecentActivities from "./dashboard/dashboard-recent-activities";
import { reduceFileSize } from "../lib/image-helper";
import jimp from "jimp";
import DashboardNextRaceDetails from "./dashboard/dashboard-training/dashboard-next-race/dashboard-next-race-details";
import DashboardNextRacePane from "./dashboard/dashboard-training/dashboard-next-race/dashboard-next-race-pane";
import FinishedChallenges from "./challenges/finished-challenges";
import FinishedChallengeH from "./challenges/finished-challenge-h";
import AutoCompleteMultiSelect from "./common/auto-complete-multi-select";
import AllBadges from "../components/trail-head/social-profile/badges";
import AllGroups from "../components/trail-head/social-profile/groups";
import PublicGroupMain from "./trail-head/public-group-main";
import RaceCard from "./trail-head/social-profile/races/components/race-card";
import { createAnalyticData } from "../lib/analytic-helper";
import { pushAnalyticData } from "../actions/analytic-action";
import { checkSubscription } from "../lib/subscription-plan-helper";
import withActivities from "../hoc/withActivities";
import { useAnalyticsEvent } from "../hooks/useAnalyticsEvent";

const styles = (theme) => ({
  container: {
    width: "100%",
    background: "#E5E5E5",

    overflow: "scroll",
    height: "100vh",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  posts: {
    width: 620,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  loading: {
    textAlign: "center",
  },
  top: {
    padding: 10,
    width: 580,
    margin: "auto",
    borderRadius: "0 0 12px 12px",
    background: "#ffffff",

    position: "sticky",
    top: 0,
    zIndex: 100,

    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)",
    },
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 28,
  },
  termSearch: {
    marginTop: 0,
    marginBottom: 4,
    background: "rgb(255 255 255 / 10%)",
  },

  postText: {
    marginTop: 0,
    background: "rgb(255 255 255 / 10%)",
  },

  logoImg: {
    width: 72,
    marginRight: "auto",
    cursor: "pointer",
  },
  iconSearch: {
    marginLeft: 10,
    cursor: "pointer",
  },
  onlyReported: {},
  button: {
    boxShadow: "none",
    borderRadius: 50,
    marginBottom: 0,
    marginLeft: 20,
  },

  title: {
    fontWeight: 500,
    fontSize: 18,
    marginRight: "auto",
  },

  filter: {
    fontSize: 14,
    fontWeight: 400,
    background: "rgba(63, 81, 181, 0.12);",
    borderRadius: 4,
    padding: "6px 8px",
    color: "#212121",
    cursor: "pointer",
    minWidth: 50,
    textAlign: "center",
  },
  filterSelected: {
    fontSize: 14,
    fontWeight: 400,
    background: "#1E629B",
    borderRadius: 4,
    padding: "6px 8px",
    color: "#FFFFFF",
    cursor: "pointer",
    minWidth: 50,
    textAlign: "center",
  },

  round: {
    borderRadius: 4,
    marginTop: 0,
  },

  closeIcon: {
    cursor: "pointer",
  },

  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },

  noPadding: {
    padding: "0 !important",
  },

  levelMiniBadge: {
    height: 25,
    bottom: -5,
    right: -10,
    position: "absolute",
  },

  tabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
  },

  tab: {
    fontSize: 16,
    opacity: 0.7,
    textTransform: "uppercase",
    cursor: "pointer",
  },

  tabSelected: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    cursor: "pointer",
  },

  postOptionsRow: {
    display: "flex",
    justifyContent: "space-evenly",
  },

  postOption: {
    textAlign: "center",
    background: "#F9F9FB",
    borderRadius: 4,
    padding: "10px 20px",
    minWidth: 65,
    cursor: "pointer",
  },

  postOptionText: {
    fontSize: 12,
    cursor: "pointer",
  },

  postOptionIcon: {
    cursor: "pointer",
  },

  postImageContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  postImage: {
    maxWidth: "100%",
    maxHeight: 300,
    marginTop: 15,
  },

  publishButtonContainer: {
    width: "100%",
    marginTop: 10,
    textAlign: "center",
  },

  publishButton: {
    margin: "auto",
    textTransform: "initial",
    borderRadius: 10,
    width: 75,
    fontFamily: "Montserrat",
    fontSize: 16,
    height: 29,
    boxShadow: "none",
  },

  input: {
    display: "none",
    cursor: "pointer",
  },

  noPublicGroups: {
    textAlign: "center",
    margin: 10,
    fontSize: 14,
  },
});

class TrailHead extends PureComponent {
  state = {
    searchPhrase: null,
    tags: [],
    showReported: false,
    isSearchingWithPhrase: false,
    isSearchingWithFilters: true,
    avatarLoading: true,
    avatarUrl: null,
    openSignUp: false,
    signUpTimeout: null,
    tab: 0,
    isPosting: null,
    units: "kms",

    currentImage: null,
    currentImageExtension: null,
    currentGoal: null,
    currentBadge: null,
    currentEpicRace: null,
    type: null,
    postTags: [],
  };

  async componentDidMount() {
    this.props.dispatch(getTags());
    this.props.dispatch(getPosts(new Date().getTime(), "", "", false, true));
    this.props.dispatch(getPublicGroups());
    this.getAvatarUrl();

    this.setState({
      ...this.state,
      signUpTimeout: setTimeout(() => {
        const { auth } = this.props;
        const { isAuthenticated } = auth;
        if (!isAuthenticated && !this.state.signUpTimeout) {
          this.handleOpenSignUp();
        }
      }, 6000),
    });

    const { userPlans, subscriptions, auth } = this.props;
    if (userPlans.loadingPlan === false) {
      this.getActivities();
    }

    const { currentUser } = auth || {};
    const { attributes } = currentUser || {};
    const { email, sub } = attributes || {};

    setEventsMetadata({ custId: sub });
    logEvent("visit_trailhead");

    if (typeof window !== "undefined") {
      this.setState({
        ...this.state,
        units: localStorage.getItem("units"),
      });

      window.addEventListener("storage", () => this.localStorageUpdated());
    }

    const { trailHead } = this.props;
    const {
      text,
      image,
      imageBase64,
      badge,
      epicRace,
      currentPublicGroup,
      loadingPublicGroup,
    } = trailHead;
    if (!loadingPublicGroup && !currentPublicGroup) {
      if (text && image) {
        this.convertImgToDataURLviaCanvas(image, (base64_data) => {
          this.setState({
            ...this.state,
            currentImage: base64_data,
            currentImageExtension: "png",
            isPosting: "final",
          });
        });
      }
      if (imageBase64) {
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              currentImage: imageBase64,
              currentImageExtension: "png",
              isPosting: "final",
            }),
          100,
        );
      }
      if (badge) {
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              currentBadge: badge,
              isPosting: "final",
            }),
          100,
        );
      }
      if (epicRace) {
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              currentEpicRace: epicRace,
              isPosting: "final",
            }),
          100,
        );
      }
    }
  }

  localStorageUpdated() {
    this.setState({
      ...this.state,
      units: localStorage.getItem("units"),
    });
  }

  async componentDidUpdate(prevProps) {
    const { userPlans, loadingActivities, activities } = this.props;
    const { currentPlan, updatingImage } = userPlans;
    const { image } = currentPlan || {};

    if (
      (currentPlan &&
        currentPlan.image &&
        (!prevProps.userPlans ||
          !prevProps.userPlans.currentPlan ||
          !prevProps.userPlans.currentPlan.image ||
          prevProps.userPlans.currentPlan.image !== currentPlan.image)) ||
      (prevProps.userPlans.updatingImage && !updatingImage)
    ) {
      if (image) {
        this.setState({
          ...this.state,
          avatarLoading: true,
        });
        this.getAvatarUrl();
      }
    }

    if (
      (!prevProps.userPlans.currentPlan ||
        !prevProps.userPlans.currentPlan.strava_athlete_id) &&
      userPlans.currentPlan &&
      userPlans.currentPlan.strava_athlete_id
    ) {
      this.getActivities();
    }

    if (
      (!prevProps.userPlans.currentPlan ||
        !prevProps.userPlans.currentPlan.garmin_oauth_token) &&
      userPlans.currentPlan &&
      userPlans.currentPlan.garmin_oauth_token
    ) {
      this.getActivities();
    }

    if (
      (!prevProps.userPlans.currentPlan ||
        !prevProps.userPlans.currentPlan.suunto_auth) &&
      userPlans.currentPlan &&
      userPlans.currentPlan.suunto_auth
    ) {
      this.getActivities();
    }

    if (
      (!prevProps.userPlans.currentPlan ||
        !prevProps.userPlans.currentPlan.coros_auth) &&
      userPlans.currentPlan &&
      userPlans.currentPlan.coros_auth
    ) {
      this.getActivities();
    }

    const { trailHead } = this.props;
    const {
      text,
      image: imageTH,
      imageBase64,
      loadingPublicGroup,
      currentPublicGroup,
      badge,
      epicRace,
    } = trailHead;

    if (!loadingPublicGroup && !currentPublicGroup) {
      if (
        (!prevProps.trailHead.text || !prevProps.trailHead.image) &&
        text &&
        imageTH
      ) {
        this.convertImgToDataURLviaCanvas(imageTH, (base64_data) => {
          this.setState({
            ...this.state,
            currentImage: base64_data,
            currentImageExtension: "png",
            isPosting: "final",
            tab: 0,
          });
        });
      }
      if (!prevProps.trailHead.imageBase64 && imageBase64) {
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              currentImage: imageBase64,
              currentImageExtension: "png",
              isPosting: "final",
              tab: 0,
            }),
          100,
        );
      }

      if (!prevProps.trailHead.badge && badge) {
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              currentBadge: badge,
              isPosting: "final",
              tab: 0,
            }),
          100,
        );
      }

      if (!prevProps.trailHead.epicRace && epicRace) {
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              currentEpicRace: epicRace,
              isPosting: "final",
              tab: 0,
            }),
          100,
        );
      }
    }

    if (
      activities &&
      activities.length &&
      (!prevProps.activities || prevProps.activities.length === 0)
    ) {
      const { currentPlan } = userPlans;
      const {
        currentTraining,
        lastAccumulatedCheck = 0,
        accumulatedDistance = 0,
        accumulatedElevation = 0,
        publicGroups = [],
      } = currentPlan || "";
      const { days } = currentTraining || {};

      const runActivities = activities.filter(
        (a) =>
          (a.type && a.type.toLowerCase().includes("run")) ||
          (a.activityType && a.activityType.includes("RUN")),
      );

      for (let i = 0; i < publicGroups.length; i++) {
        this.props.dispatch(
          addGroupActivities(
            currentPlan.cognito_user_sub,
            publicGroups[i],
            runActivities,
          ),
        );
      }
    }
  }

  convertImgToDataURLviaCanvas = function(url, callback) {
    var img = new Image();

    img.crossOrigin = "Anonymous";

    img.onload = function() {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      canvas.height = this.height;
      canvas.width = this.width;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      callback(dataURL);
      canvas = null;
    };

    img.src = url;
  };

  getAvatarUrl = async () => {
    const { userPlans, t } = this.props;
    const { currentPlan } = userPlans;
    const { image } = currentPlan || {};

    if (image) {
      try {
        const result = await Storage.get(image, {
          level: "public",
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              avatarUrl: result,
              avatarLoading: false,
            }),
          100,
        );
      } catch (exp) {
        this.handleError(
          new Error(
            t(
              "Oops, we could not load your profile picture. Give it another try",
              { ns: "messages" },
            ),
          ),
        );
        throw exp;
      }
    } else if (!image) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            avatarLoading: false,
          }),
        100,
      );
    }
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleError = (err) => {
    this.props.dispatch(setErrorMessage(err)); //GOES TO SNACK BAR
  };

  handleLoadNextPage = (lastDate) => {
    this.props.dispatch(
      getPosts(
        lastDate,
        this.state.searchPhrase,
        this.state.tags ? this.state.tags.map((tag) => tag.value) : "",
        false,
      ),
    );
  };

  handleAddAllez = (id, commentId = null, replyId = null) => {
    const { auth, userPlans, subscriptions } = this.props;
    const { currentUser, isAuthenticated } = auth;
    const { attributes } = currentUser || {};
    const { sub, name } = attributes || {};
    if (isAuthenticated) {
      this.props.dispatch(
        addAllez(sub, {
          cognito_user_sub: sub,
          cognito_user_name: name,
          id,
          commentId,
          replyId,
        }),
      );
      const { currentUser } = auth || {};
      const { attributes } = currentUser || {};
      const { email } = attributes || {};
      const analyticData = createAnalyticData(
        {
          username: email,
          userPlans,
          subscriptions,
        },
        "add allez",
      );
      this.props.dispatch(pushAnalyticData(analyticData));
    } else {
      this.handleOpenSignUp();
    }
  };

  handleReport = (id, reason) => {
    const { auth } = this.props;
    const { currentUser, isAuthenticated } = auth;
    const { attributes } = currentUser || {};
    const { sub, name } = attributes || {};
    if (isAuthenticated) {
      this.props.dispatch(
        addReport(sub, {
          cognito_user_sub: sub,
          cognito_user_name: name,
          id,
          reason,
        }),
      );
    } else {
      this.handleOpenSignUp();
    }
  };

  handleAddComment = (comment) => {
    const { auth, userPlans, subscriptions } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub, name } = attributes;
    this.props.dispatch(
      addComment(sub, { ...comment, cognito_user_name: name }),
    );

    const { email } = attributes || {};
    const analyticData = createAnalyticData(
      {
        username: email,
        userPlans,
        subscriptions,
      },
      "add comment",
    );
    this.props.dispatch(pushAnalyticData(analyticData));
  };

  handleStartPhraseSearch = () => {
    this.setState({
      ...this.state,
      isSearchingWithPhrase: true,
      tags: [],
      isPosting: null,
    });
  };

  handleStopSearch = () => {
    this.setState({
      ...this.state,
      isSearchingWithPhrase: false,
      tags: [],
      searchPhrase: null,
    });
    if (
      (this.state.tags && this.state.tags.length) ||
      this.state.searchPhrase
    ) {
      this.props.dispatch(
        getPosts(new Date().getTime(), null, [], false, true),
      );
    }
  };

  handleSearchChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;

        if (this.state.searchTimeout) {
          clearTimeout(this.state.searchTimeout);
        }

        this.setState({
          ...this.state,
          searchPhrase: value || "",
          searchTimeout:
            this.state.tab === 0
              ? setTimeout(() => {
                  this.props.dispatch(
                    getPosts(
                      new Date().getTime(),
                      value,
                      this.state.tags
                        ? this.state.tags.map((tag) => tag.value)
                        : "",
                      this.state.showReported,
                      true,
                    ),
                  );
                }, 1000)
              : null,
        });
      }
    }
  };

  handleTabSelect = (tab) => {
    this.setState({
      ...this.state,
      tab,
      isPosting: null,
      searchPhrase: null,
    });
  };

  handleTagSelect = (tag) => {
    if (this.state.tags.find((t) => t.value === tag.value)) {
      this.props.dispatch(
        getPosts(
          new Date().getTime(),
          this.state.searchPhrase,
          [],
          this.state.showReported,
          true,
        ),
      );
      this.setState({
        ...this.state,
        tags: [],
      });
    } else {
      this.props.dispatch(
        getPosts(
          new Date().getTime(),
          this.state.searchPhrase,
          [tag.value],
          this.state.showReported,
          true,
        ),
      );
      this.setState({
        ...this.state,
        tags: [tag],
      });
    }
  };

  handleChangeShowReported = (event) => {
    this.props.dispatch(
      getPosts(
        new Date().getTime(),
        this.state.searchPhrase,
        this.state.tags ? this.state.tags.map((tag) => tag.value) : "",
        event.target.checked,
        true,
      ),
    );
    this.setState({ ...this.state, showReported: event.target.checked });
  };

  handleDelete = (type, entry) => {
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;
    switch (type) {
      case "post":
        this.props.dispatch(deletePost(sub, entry));
        break;
      case "comment":
      case "reply":
        this.props.dispatch(deleteComment(sub, entry));
        break;
      default:
        break;
    }
  };

  handleEdit = (editData, reloadPinned) => {
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;
    this.props.dispatch(editPost(sub, editData)).then(() => {
      if (reloadPinned) {
        this.props.dispatch(
          getPosts(
            new Date().getTime(),
            this.state.searchPhrase,
            this.state.tags ? this.state.tags.map((tag) => tag.value) : "",
            this.state.showReported,
            true,
          ),
        );
      }
    });
  };

  handleBlock = (user) => {
    const { userPlans, auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      const { currentPlan } = userPlans;
      const { cognito_user_sub, blockedUsers } = currentPlan || {};
      this.props.dispatch(
        updatePlan(
          {
            cognito_user_sub,
            blockedUsers: blockedUsers ? [...blockedUsers, user] : [user],
          },
          false,
          false,
        ),
      );
    } else {
      this.handleOpenSignUp();
    }
  };

  handleOpenSignUp = () => {
    localStorage.removeItem("error");
    if (this.state.signUpTimeout) {
      clearTimeout(this.state.signUpTimeout);
    }

    const { auth } = this.props;
    const { isAuthenticated } = auth;

    if (!isAuthenticated) {
      this.setState({
        ...this.state,
        openSignUp: true,
        signUpTimeout: null,
      });
    }
  };

  handleCloseSignUp = () => {
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignUp: false,
    });
  };

  toogleMenu = () => {
    this.props.dispatch(toggleOpenMenu());
    this.setState({
      ...this.state,
      isPosting: null,
    });
  };

  handleTextChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.props.dispatch(newPost("", value || "", null));
      }
    }
  };

  componentWillUnmount() {
    clearTimeout(this.state.garminTimeout);

    this.setState({
      ...this.state,
      garminTimeout: null,
    });
  }

  getActivities = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    this.props.dispatch(getUserActivities(currentPlan));
  };

  handleCloseAddPost = () => {
    this.setState({
      ...this.state,
      isPosting: this.state.isPosting === "main" ? null : "main",
      currentImage: null,
      currentImageExtension: null,
      currentGoal: null,
      currentBadge: null,
      currentEpicRace: null,
      type: null,
      postTags: [],
    });
    if (this.state.isPosting === "main") {
      this.props.dispatch(cleanNewPost());
      this.props.dispatch(clearCurrentUserActivity());
    }
    if (this.state.isPosting === "final") {
      this.props.dispatch(clearCurrentUserActivity());
    }
  };

  handlePostTagSelect = (event) => {
    this.setState({
      ...this.state,
      postTags: event,
    });
  };

  handleSave = () => {
    const { userPlans, subscriptions, trailHead, auth } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || {};
    const { latestSubscriptionFinalizedTime } = currentPlan || {};

    let notSubscribed = false;

    let { selectedPlan } = currentPlan;
    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    const isSubscribed = checkSubscription(
      subscriptions,
      latestSubscriptionFinalizedTime,
    );

    const { text } = trailHead;

    const {
      currentImage,
      currentImageExtension,
      currentGoal,
      currentBadge,
      currentEpicRace,
      type,
      postTags,
    } = this.state;

    const post = {
      title: "",
      text,
      tags: postTags.map((tag) => tag.value),
      link: "",
      image: currentImage,
      imageExtension: currentImageExtension,
      cognito_user_sub,
      crown: isSubscribed,
      goal: currentGoal,
      badge: currentBadge,
      epicRace: currentEpicRace,
      type,
    };

    this.props.dispatch(addPost(cognito_user_sub, post));

    const { currentUser } = auth || {};
    const { attributes } = currentUser || {};
    const { email } = attributes || {};
    const analyticData = createAnalyticData(
      {
        username: email,
        userPlans,
        subscriptions,
      },
      "add post",
    );
    this.props.dispatch(pushAnalyticData(analyticData));

    this.setState({
      ...this.state,
      isPosting: null,
      currentImage: null,
      currentImageExtension: null,
      currentGoal: null,
      currentBadge: null,
      currentEpicRace: null,
      postTags: [],
      type: null,
    });
    this.props.dispatch(cleanNewPost());
    this.props.dispatch(clearCurrentUserActivity());
  };

  handleInputChange = async (event) => {
    if (event) {
      const { target } = event;
      const { maxSize, t } = this.props;
      if (target) {
        const { files } = target;
        if (files) {
          const selectedFile = files[0];
          try {
            if (selectedFile) {
              const { size } = selectedFile;
              if (size <= maxSize) {
                if (size > 1024000) {
                  reduceFileSize(
                    selectedFile,
                    1024000,
                    1000,
                    Infinity,
                    0.9,
                    async (fixedFile) => {
                      fixedFile.name = selectedFile.name;
                      const {
                        image: imageString,
                        extension,
                      } = await this.getBase64(fixedFile);

                      this.setState({
                        ...this.state,
                        currentImage: imageString,
                        currentImageExtension: extension,
                        isPosting: "final",
                      });
                    },
                  );
                } else {
                  const {
                    image: imageString,
                    extension,
                  } = await this.getBase64(selectedFile);

                  this.setState({
                    ...this.state,
                    currentImage: imageString,
                    currentImageExtension: extension,
                    isPosting: "final",
                  });
                }
              } else {
                this.handleError(
                  t("Sorry, your image needs to be smaller than maxSize10", {
                    ns: "messages",
                  }),
                );
              }
            } else {
              //if (onError) {
              this.handleError(t("Invalid File", { ns: "messages" }));
              //}
            }
          } catch (exp) {
            this.handleError(exp);
          }
        }
      }
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const image = await jimp.read(reader.result);
          //await image.resize(150, jimp.AUTO);
          const fileNameArray = file.name ? file.name.split(".") : undefined;
          const extension = fileNameArray
            ? fileNameArray[fileNameArray.length - 1]
            : undefined;
          const data = await image.getBase64Async(
            extension === "png" ? jimp.MIME_PNG : jimp.MIME_JPEG,
          );
          resolve({
            image: data,
            extension,
          });
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleGoalPost = (goal) => {
    this.setState({
      ...this.state,
      currentGoal: goal,
      isPosting: "final",
    });
  };

  handleBadgePost = (badge) => {
    this.setState({
      ...this.state,
      currentBadge: badge,
      isPosting: "final",
    });
  };

  handleJoinPublicGroup = (event, id, isPrivate = 0) => {
    event.preventDefault();
    event.stopPropagation();
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || {};
    this.props.dispatch(joinGroup(cognito_user_sub, id));

    if (!isPrivate) {
      history.push(`/trailhead/group/${id}`);
    }
  };

  render() {
    const {
      classes,
      t,
      auth,
      trailHead,
      i18n,
      location = {},
      subscriptions,
      width,
      loadingActivities,
      activities,
      userPlans,
      vertActivities,
    } = this.props;
    const { currentUser, currentUserGroups, isAuthenticated } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes || {};

    const {
      posts,
      tags: tagOptions,
      loadingPosts,
      addingAllezOn,
      addingCommentOn,

      text,
      image,

      loadingPublicGroups,
      publicGroups,

      joiningPublicGroup,

      currentPublicGroup,
      loadingPublicGroup,

      currentPinnedPosts,
      loadingPinnedPosts,
    } = trailHead;
    const { data } = posts;
    const { data: pinnedData } = currentPinnedPosts;

    data.sort((a, b) => b.created_at - a.created_at);
    pinnedData.sort((a, b) => a.pinned_order - b.pinned_order);

    const { currentPlan, loading, successMessage, errorMessage } = userPlans;
    const {
      blockedUsers,
      trailhead,
      accumulatedDistance = 0,
      accumulatedElevation = 0,
    } = currentPlan || {};

    const level = loading
      ? 0
      : getLevel(accumulatedDistance, accumulatedElevation);

    const isCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_COACH,
      ) > -1;

    const isAdmin =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_ADMIN,
      ) > -1;

    const isMaster =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_MASTER,
      ) > -1;

    const { latestSubscriptionFinalizedTime } = currentPlan || {};

    let notSubscribed = false;

    let { selectedPlan } = currentPlan || {};
    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    const isSubscribed = checkSubscription(
      subscriptions,
      latestSubscriptionFinalizedTime,
    );

    const {
      searchPhrase,
      tags,
      showReported,
      isSearchingWithPhrase,
      isSearchingWithFilters,
      avatarUrl,
      openSignUp,
      tab,
      isPosting,
      units,
      currentImage,
      currentGoal,
      currentBadge,
      currentEpicRace,
      postTags,
    } = this.state;

    const { strava_athlete_id, garmin_oauth_token, suunto_auth, coros_auth } =
      currentPlan || "";

    const { activities: activitiesVert } = vertActivities;

    const noTracker =
      !loading &&
      !strava_athlete_id &&
      !garmin_oauth_token &&
      !suunto_auth &&
      !coros_auth &&
      (!activitiesVert || !activitiesVert.length);

    const pinnedItems = pinnedData.map((post) => {
      if (
        !blockedUsers ||
        !blockedUsers.find((b) => b === post.cognito_user_sub)
      ) {
        return (
          <Post
            userImg={avatarUrl}
            key={post.id}
            postData={post}
            tags={tagOptions}
            onAddAllez={this.handleAddAllez}
            userSub={sub}
            onAddComment={this.handleAddComment}
            onTagClick={this.handleTagSelect}
            onDelete={this.handleDelete}
            onEdit={this.handleEdit}
            onReport={this.handleReport}
            onBlock={this.handleBlock}
            isAdmin={isAdmin}
            onError={this.handleError}
            addingAllezOn={addingAllezOn}
            addingCommentOn={addingCommentOn}
            hasSub={isSubscribed}
            isAuthenticated={isAuthenticated}
            openSignUp={this.handleOpenSignUp}
            pinnedPosts={pinnedData}
          ></Post>
        );
      }
      return <span key={post.id}></span>;
    });

    const items = data.map((post) => {
      if (
        !blockedUsers ||
        !blockedUsers.find((b) => b === post.cognito_user_sub)
      ) {
        return (
          <Post
            userImg={avatarUrl}
            key={post.id}
            postData={post}
            tags={tagOptions}
            onAddAllez={this.handleAddAllez}
            userSub={sub}
            onAddComment={this.handleAddComment}
            onTagClick={this.handleTagSelect}
            onDelete={this.handleDelete}
            onEdit={this.handleEdit}
            onReport={this.handleReport}
            onBlock={this.handleBlock}
            isAdmin={isAdmin}
            onError={this.handleError}
            addingAllezOn={addingAllezOn}
            addingCommentOn={addingCommentOn}
            hasSub={!notSubscribed}
            isAuthenticated={isAuthenticated}
            openSignUp={this.handleOpenSignUp}
            pinnedPosts={pinnedData}
          ></Post>
        );
      }
      return <span key={post.id}></span>;
    });

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const langTags = tagOptions
      ? tagOptions.map((tag) => {
          return {
            label: tag.name[languageDetector]
              ? tag.name[languageDetector]
              : tag.name["en"],
            value: tag.id,
          };
        })
      : [];

    const isInMobileViewSm = width === "sm" || width === "xs";

    const publicGroupsFiltered = searchPhrase
      ? publicGroups.filter(
          (p) =>
            p &&
            p.name &&
            p.name[languageDetector] &&
            p.name[languageDetector]
              .toLowerCase()
              .includes(searchPhrase.toLowerCase()),
        )
      : publicGroups;

    return (
      <div
        className={classes.container}
        id="thContainer"
        onScroll={() => {
          if (
            document.getElementById("thContainer").scrollTop === 0 ||
            isSearchingWithPhrase
          ) {
            this.setState({
              isSearchingWithFilters: true,
            });
          } else {
            this.setState({
              isSearchingWithFilters: false,
            });
          }
        }}
      >
        <Route path="/trailhead/profile/:id" component={SocialProfile} />
        <Route path="/trailhead/group/:id" component={PublicGroupMain} />
        <Route path="/trailhead/post/:id" component={PostDrawer} />
        <Route path="/trailhead/:id/add-race" component={AddResult} />
        <Route path="/trailhead/:id/races" component={AllRaces} />
        <Route path="/trailhead/:id/all-badges" component={AllBadges} />
        <Route path="/trailhead/:id/all-groupes" component={AllGroups} />

        <div className={classes.top}>
          {isAdmin && (
            <FormControlLabel
              classes={{ label: classes.onlyReported }}
              control={
                <Switch
                  checked={showReported}
                  onChange={this.handleChangeShowReported}
                  name="showReported"
                />
              }
              label="Show only reported posts"
            />
          )}

          <div>
            <div className={classes.searchBar}>
              {!isSearchingWithPhrase && (
                <div
                  className={classes.pictureContainer}
                  style={{
                    width: 44,
                    height: 44,
                    position: "relative",
                  }}
                >
                  <Fab
                    component="span"
                    className={classes.fab}
                    title="Cambia tu foto"
                    style={{
                      width: 44,
                      height: 44,
                    }}
                    onClick={() => this.toogleMenu()}
                  >
                    <Avatar
                      src={
                        avatarUrl ||
                        `${process.env.PUBLIC_URL}/avatar-empty.png`
                      }
                      className={classes.avatar}
                      style={{
                        width: 44,
                        height: 44,
                      }}
                    />
                  </Fab>

                  {level ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/trl${level}-sm.svg`}
                      className={classes.levelMiniBadge}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}

              {isSearchingWithPhrase && (
                <TextField
                  value={searchPhrase ? searchPhrase : ""}
                  margin="normal"
                  variant="outlined"
                  placeholder={t("Search term placeholder")}
                  onChange={this.handleSearchChange}
                  autoFocus
                  className={classes.termSearch}
                  fullWidth
                  InputProps={{
                    classes: {
                      root: classes.round,
                      input: classes.textSearch,
                      underline: classes.outline,
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/close.svg`}
                          alt=""
                          onClick={() => this.handleStopSearch()}
                          className={classes.closeIcon}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              {!isSearchingWithPhrase && !isPosting && (
                <div className={classes.tabs}>
                  <Typography
                    variant="body1"
                    onClick={() => this.handleTabSelect(0)}
                    className={
                      this.state.tab === 0 ? classes.tabSelected : classes.tab
                    }
                  >
                    {t("Feed")}
                  </Typography>

                  <Typography
                    variant="body1"
                    onClick={() => this.handleTabSelect(1)}
                    className={
                      this.state.tab === 1 ? classes.tabSelected : classes.tab
                    }
                  >
                    {t("Groups")}
                  </Typography>
                </div>
              )}

              {isPosting && (
                <div className={classes.tabs}>
                  <Typography
                    variant="body1"
                    className={classes.tabSelected}
                    style={{ cursor: "initial" }}
                  >
                    {isPosting === "activities"
                      ? t("ACTIVITIES TO SHARE")
                      : isPosting === "goal"
                      ? t("GOALS TO SHARE")
                      : isPosting === "badge"
                      ? t("BADGES TO SHARE")
                      : t("COMPOSING")}
                  </Typography>
                </div>
              )}

              {!isSearchingWithPhrase && !isPosting && (
                <img
                  src={`${process.env.PUBLIC_URL}/icon-searchTH.svg`}
                  alt=""
                  className={classes.iconSearch}
                  onClick={() => {
                    this.handleStartPhraseSearch();
                  }}
                />
              )}

              {isPosting && (
                <img
                  src={`${process.env.PUBLIC_URL}/close.svg`}
                  alt=""
                  className={classes.iconSearch}
                  onClick={() => this.handleCloseAddPost()}
                />
              )}

              {/*!isSearchingWithPhrase && (
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => this.openAddPost()}
              >
                {t("Add Post placeholder")}
              </Button>
            )*/}
            </div>

            {!isSearchingWithPhrase &&
            isPosting !== "activities" &&
            isPosting !== "goal" &&
            isPosting !== "badge" &&
            tab === 0 ? (
              <TextField
                value={
                  text && !(currentPublicGroup || loadingPublicGroup)
                    ? text
                    : ""
                }
                margin="normal"
                variant="outlined"
                placeholder={t("What are you up to?")}
                inputProps={{ maxLength: 2200 }}
                onChange={this.handleTextChange}
                className={classes.postText}
                fullWidth
                onFocus={() =>
                  this.setState({
                    ...this.state,
                    isPosting: isPosting ? isPosting : "main",
                  })
                }
                multiline={true}
                InputProps={{
                  classes: {
                    root: classes.round,
                    input: classes.postText,
                    underline: classes.outline,
                  },
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/icon-new-post.svg`}
                        alt=""
                        className={classes.closeIcon}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              ""
            )}
          </div>

          {isSearchingWithFilters && !isPosting && tab === 0 ? (
            <Grid container spacing={1} className={classes.filterGrid}>
              {langTags
                ? langTags.map((filter, index) => (
                    <Grid item key={index} className={classes.filterItem}>
                      <Typography
                        variant="body1"
                        onClick={() => this.handleTagSelect(filter)}
                        className={
                          this.state.tags.find((t) => t.value === filter.value)
                            ? classes.filterSelected
                            : classes.filter
                        }
                      >
                        {filter.label}
                      </Typography>
                    </Grid>
                  ))
                : ""}
            </Grid>
          ) : (
            ""
          )}

          {isPosting === "main" && (
            <div className={classes.postOptions}>
              <div className={classes.postOptionsRow}>
                <div
                  className={classes.postOption}
                  onClick={() => {
                    this.props.dispatch(clearCurrentUserActivity());
                    this.setState({
                      ...this.state,
                      isPosting: "activities",
                    });
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-post-activity.svg`}
                    alt=""
                    className={classes.postOptionIcon}
                  />

                  <Typography variant="body1" className={this.postOptionText}>
                    {t("Activity")}
                  </Typography>
                </div>

                <div
                  className={classes.postOption}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      isPosting: "goal",
                    })
                  }
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-post-goal.svg`}
                    alt=""
                    className={classes.postOptionIcon}
                  />

                  <Typography variant="body1" className={this.postOptionText}>
                    {t("Goal")}
                  </Typography>
                </div>

                <div
                  className={classes.postOption}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      isPosting: "final",
                      type: "question",
                    })
                  }
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-post-question.svg`}
                    alt=""
                    className={classes.postOptionIcon}
                  />

                  <Typography variant="body1" className={this.postOptionText}>
                    {t("Question")}
                  </Typography>
                </div>
              </div>

              <div className={classes.postOptionsRow} style={{ marginTop: 10 }}>
                <div
                  className={classes.postOption}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      isPosting: "final",
                    })
                  }
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-post-text.svg`}
                    alt=""
                    className={classes.postOptionIcon}
                  />

                  <Typography variant="body1" className={this.postOptionText}>
                    {t("Text")}
                  </Typography>
                </div>

                <div className={classes.postOption}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file-2"
                    type="file"
                    onChange={this.handleInputChange}
                  />
                  <label
                    htmlFor="contained-button-file-2"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-post-image.svg`}
                      alt=""
                      className={classes.postOptionIcon}
                    />

                    <Typography variant="body1" className={this.postOptionText}>
                      {t("Image")}
                    </Typography>
                  </label>
                </div>

                <div
                  className={classes.postOption}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      isPosting: "badge",
                    })
                  }
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-post-badge.svg`}
                    alt=""
                    className={classes.postOptionIcon}
                  />

                  <Typography variant="body1" className={this.postOptionText}>
                    {t("Badge")}
                  </Typography>
                </div>
              </div>
            </div>
          )}

          {isPosting === "activities" &&
            (loadingActivities ? (
              <div className={classes.loading}>
                <CircularProgress variant="indeterminate" />
              </div>
            ) : (
              <DashboardRecentActivities
                activities={activities}
                units={units}
                noTracker={noTracker}
                isColumn={true}
              ></DashboardRecentActivities>
            ))}

          {isPosting === "goal" && (
            <DashboardNextRaceDetails
              isTrailhead={true}
              onEdit={this.handleGoalPost}
            ></DashboardNextRaceDetails>
          )}

          {isPosting === "badge" && (
            <FinishedChallenges
              onShareChallenge={this.handleBadgePost}
            ></FinishedChallenges>
          )}

          {isPosting === "final" ? (
            <>
              {currentImage ? (
                <div className={classes.postImageContainer}>
                  <img
                    src={currentImage}
                    alt=""
                    className={classes.postImage}
                  />
                </div>
              ) : (
                ""
              )}

              {currentGoal ? (
                <DashboardNextRacePane
                  nextRace={currentGoal}
                  userPlans={userPlans}
                  editing={false}
                  onEdit={null}
                  onSave={null}
                  onDelete={null}
                  isTrailhead={true}
                ></DashboardNextRacePane>
              ) : (
                ""
              )}

              {currentBadge ? (
                <FinishedChallengeH
                  yourEntry={currentBadge || {}}
                  onShareChallenge={null}
                  units={this.state.units}
                ></FinishedChallengeH>
              ) : (
                ""
              )}

              {currentEpicRace ? (
                <RaceCard item={currentEpicRace}></RaceCard>
              ) : (
                ""
              )}

              <AutoCompleteMultiSelect
                suggestions={langTags}
                placeholder={t("Tags placeholder")}
                isClearable={true}
                value={postTags}
                onChange={(event) => this.handlePostTagSelect(event)}
                disable={false}
              />

              <div className={classes.publishButtonContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.publishButton}
                  onClick={this.handleSave}
                  disabled={!text}
                >
                  {t("Publish")}
                </Button>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        {!isPosting && tab === 0 && (
          <div className={classes.posts} id="posts">
            <InfiniteScroll
              dataLength={items.length} //This is important field to render the next data
              next={() => {
                data &&
                  data.length > 0 &&
                  this.handleLoadNextPage(data[data.length - 1].created_at);
              }}
              hasMore={true}
              scrollableTarget="thContainer"
            >
              {!this.state.tags || !this.state.tags.length ? pinnedItems : ""}
              {items}
            </InfiniteScroll>
            {loadingPosts ||
              (loadingPinnedPosts && (
                <div className={classes.loading}>
                  <CircularProgress variant="indeterminate" />
                </div>
              ))}
          </div>
        )}

        {tab === 1 && (
          <div className={classes.posts} id="posts">
            {loadingPublicGroups && (!publicGroups || !publicGroups.length) ? (
              <div className={classes.loading}>
                <CircularProgress variant="indeterminate" />
              </div>
            ) : publicGroupsFiltered && publicGroupsFiltered.length ? (
              publicGroupsFiltered.map((p) => (
                <PublicGroup
                  publicGroup={p}
                  key={p.id}
                  joiningGroup={joiningPublicGroup}
                  onJoinGroup={this.handleJoinPublicGroup}
                  userPlans={userPlans}
                  isAuthenticated={isAuthenticated}
                ></PublicGroup>
              ))
            ) : (
              <Typography variant="body1" className={classes.noPublicGroups}>
                {t("No public groups match your search phrase")}
              </Typography>
            )}
          </div>
        )}
        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
          successMessage={t(successMessage)}
        />

        <Dialog
          open={openSignUp}
          onClose={this.handleCloseSignUp}
          aria-labelledby="signUp"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signupdialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseSignUp}
              />
            </div>
            <AppAuthenticator initialState="signUp" />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

TrailHead.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

TrailHead.defaultProps = {
  maxSize: 10240000,
};

export default connect((store) => {
  return {
    auth: store.auth,
    trailHead: store.trailHead,
    userPlans: store.userPlans,
    subscriptions: store.subscriptions,
    vertActivities: store.vertActivities,
  };
})(
  withTranslation("trailhead")(
    withWidth()(
      withStyles(styles, { withTheme: true })(withActivities(TrailHead)),
    ),
  ),
);
