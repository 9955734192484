import React from "react";
import { palette } from "../../theme/palette";

const FacebookIcon = ({ width = 24, height = 24, activated = false }) => (
  <svg
    width={width}
    height={height}
    viewBox={` 0 0 ${width} ${height}`}
    fill={activated ? palette.primary : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.789 23.9999V13.0523H17.3269L17.8566 8.78588H13.789V6.06189C13.789 4.82668 14.1192 3.98482 15.8248 3.98482L18 3.98377V0.167932C17.6236 0.116127 16.3325 0 14.8304 0C11.6942 0 9.54712 1.98818 9.54712 5.63957V8.786H6V13.0524H9.54701V24L13.789 23.9999Z"
      fill={activated ? palette.primary : "#FEFFFF"}
    />
  </svg>
);
export default FacebookIcon;
