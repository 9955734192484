import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import FontAwesomeIcon from '../../common/font-awesome-icon';
import PlanFieldTransition from './plan-field-transition';

const styles = theme => ({
    buttonText: {
        background: 'rgba(255, 255, 255, 0.3)',
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingRight: theme.spacing.unit * 1.7,
        paddingLeft: theme.spacing.unit * 1.7,
        marginRight: theme.spacing.unit * 2,
        width: 20
    },
    buttonContainer: {
        paddingBottom: theme.spacing.unit * 5
    },
    white: {
        color: theme.palette.common.white
    }
});

class PlanFieldYesNo extends Component {
    render() {
        const { classes, xs, sm, md, lg, xl, onClick, id, t } = this.props;
        return (
            <PlanFieldTransition>
                <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <div className={classes.buttonContainer}>
                        <Button variant="contained" onClick={(event) => onClick(id, 1)} color="primary">
                            <div className={classes.buttonText}>{t("S")}</div>
                            <FontAwesomeIcon variant="regular" icon="thumbs-up" className={classes.white} size="lg" />
                        </Button>
                    </div>
                    <div>
                        <Button variant="contained" onClick={(event) => onClick(id, 0)} color="primary">
                            <div className={classes.buttonText}>{t("N")}</div>
                            <FontAwesomeIcon variant="regular" icon="thumbs-down" className={classes.white} size="lg" />
                        </Button>
                    </div>
                </Grid>
            </PlanFieldTransition>
        );
    };
}

PlanFieldYesNo.propTypes = {
    classes: PropTypes.object.isRequired,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    onClick: PropTypes.func,
    t: PropTypes.func.isRequired
}

export default withTranslation('plan')(withStyles(styles, { withTheme: true })(PlanFieldYesNo));