import React from "react";
import { Box, Typography, makeStyles, Button } from "@material-ui/core";
import { palette } from "../../theme/palette";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "24px 16px",
    background: palette.white,
    borderRadius: "10px",
    boxShadow: palette.boxShadow,
  },
  title: {
    fontFamily: "Oswald",
    marginBottom: "4px",
    fontWeight: "500",
    fontSize: "18px",
    textTransform: "uppercase",
  },
  content: {
    fontFamily: "Karla",
    marginBottom: "12px",
    color: "#6D6F71",
    fontWeight: "400",
    fontSize: "16px",
  },
  button: {
    fontFamily: theme.typography.fontFamily,
    marginTop: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "18px",
  },
}));

const GeneralCard = ({ title, content }) => {
  const { t } = useTranslation("home");
  const classes = useStyles();
  const history = useHistory();

  const navigate = () => {
    history.push("/chooseplan");
  };
  return (
    <Box className={classes.card}>
      <Typography variant="h3" className={classes.title}>
        {t(title)}
      </Typography>
      <Typography variant="subtitle2" className={classes.content}>
        {t(content)}
      </Typography>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        fullWidth
        id="home_choose_plan_button"
        onClick={navigate}
      >
        {t("home.generalCard.button")}
      </Button>
    </Box>
  );
};

export default GeneralCard;
