import React, { Component, memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";
import { Route } from "react-router-dom";
import { withRouter, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import moment from "moment";

import PlanExploreFree from "./plan-questionnaire/plan-explore-free";
import PlanPremium from "./plan-questionnaire/plan-premium";
import PlanQuestionnaireButtons from "./plan-questionnaire/plan-questionnaire-buttons";
import WorkoutPlans from "../../constants/workout-plans";
import SnackBarMessageDisplay from "../common/snack-bar-message-display";
import Surfaces from "../../constants/surfaces";

import ConnectAuth from "../../connectors/connect-auth";
import ConnectUserPlans from "../../connectors/connect-user-plans";
import ConnectSubscriptions from "../../connectors/connect-subscriptions";
import ConnectSubcategories from "../../connectors/connect-subcategories";
import history from "../../lib/history-helper";
import { logEvent } from "../../lib/events-helper";
import LocalNotificationsService from "../../services/local-notifications-service";
import { Capacitor } from "@capacitor/core";
import { storageFactory } from "../../lib/storage-factory";
import PlanCoachChoose from "./plan-questionnaire/plan-coach-choose";
import {
  currentTrainingAddComment,
  planChangeField,
} from "../../actions/user-plans-action";
import { userPlanMailchimpSubscribe } from "../../actions/user-plans-action";
import { connect } from "react-redux";
import { setPlanToSelect } from "../../actions/user-plans-action";
import { checkSubscription } from "../../lib/subscription-plan-helper";
import { getLatestSubscription } from "../../actions/subscriptions-action";

const styles = (theme) => ({
  container: {
    // backgroundImage: `url(${process.env.PUBLIC_URL}/choose-plan-xl.png)`,
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  form: {
    minHeight: 700,
    [theme.breakpoints.down("md")]: {
      minHeight: 600,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 500,
    },
  },
});

class PlanQuestionnaire extends Component {
  timeout = null;
  state = {
    fields: [],
  };

  handleFieldsInit = (fields) => {
    if (fields.length !== this.state.fields.length) {
      this.setState({
        ...this.state,
        fields,
      });
    }
  };

  selectedPlan = () => {
    const { match } = this.props;
    const { params = {} } = match;
    const { selectedPlan = WorkoutPlans.FREE } = params;
    return selectedPlan;
  };

  handleYesNoFieldChange = (id, value) => {
    if (id) {
      let correctValue = value;
      if (id === "surface") {
        correctValue =
          value === 1 ? Surfaces.Items.Mountains : Surfaces.Items.Flat;
      }
      this.props.planChangeField({ name: id, value: correctValue });
      this.handleNextRequested();
    }
  };

  handleEnterPress = (e) => {
    if (e.keyCode === 13) {
      this.handleNextRequested();
    }
  };

  handleTextFieldChange = (e) => {
    const { value, id } = e.target;

    if (id) {
      this.props.planChangeField({ name: id, value });
    }
  };

  handleSelectFieldChange = (item) => {
    const { id, value } = item;
    if (id) {
      this.props.planChangeField({ name: id, value: value });
      this.timeout = setTimeout(() => {
        this.handleNextRequested();
      }, 1000);
    }
  };

  handleSliderSelectFieldChange = (items) => {
    items.forEach((item) => {
      const { id, value } = item;
      if (id) {
        this.props.planChangeField({ name: id, value: value });
      }
    });

    this.timeout = setTimeout(() => {
      this.handleNextRequested();
    }, 1000);
  };

  handleButtonSelectFieldChange = (item) => {
    const { id, value } = item;
    if (id) {
      this.props.planChangeField({ name: id, value: value });
      this.timeout = setTimeout(() => {
        this.handleNextRequested();
      }, 1000);
    }
  };

  handleGoalSelect = (item) => {
    const { id, value } = item;
    if (id) {
      this.props.planChangeField({ name: id, value: value });
      this.timeout = setTimeout(() => {
        this.handleNextRequested();
      }, 1000);
    }
  };

  handleTimeSelect = (item) => {
    if (item && item.value) {
      const { value } = item;
      const { showWorkoutNotifications, workoutNotificationsTime } = value;
      if (showWorkoutNotifications) {
        this.props.planChangeField({
          name: "showWorkoutNotifications",
          value: showWorkoutNotifications,
        });
      }
      if (workoutNotificationsTime) {
        this.props.planChangeField({
          name: "workoutNotificationsTime",
          value: workoutNotificationsTime,
        });
      }
    } else {
      this.props.planChangeField({
        name: "showWorkoutNotifications",
        value: false,
      });
    }
    this.timeout = setTimeout(() => {
      this.handleNextRequested();
    }, 1000);
  };

  handleDateFieldChange = (event) => {
    const { value, id } = event;
    if (id && value) {
      this.props.planChangeField({ name: id, value: value });
      if (id !== "desiredStartDate") {
        this.timeout = setTimeout(() => {
          this.handleNextRequested();
        }, 1000);
      }
    }
  };

  handleArrayFieldChange = (id, value) => {
    if (id) {
      const { userPlans } = this.props;
      const { planTempData } = userPlans;
      const { [id]: arrayField = [] } = planTempData;
      const foundIndex = arrayField && arrayField.indexOf(value);
      let newarrayField = arrayField;
      if (foundIndex > -1) {
        newarrayField.splice(foundIndex, 1);
      } else newarrayField = [...arrayField, value];
      this.props.planChangeField({ name: id, value: newarrayField });
    }
  };

  getDisplayErrorMessage = (err) => {
    let errorMessage = "";
    const { t } = this.props;

    if (typeof err === typeof string) {
      errorMessage = err;
    } else errorMessage = err.message || err.inputMessage || err.toString();

    switch (errorMessage) {
      case "Invalid Size":
        return t("Sorry, your image needs to be smaller than maxSize");
      case "Invalid File":
        return t("Invalid file, please try again");
      default:
        return errorMessage;
    }
  };

  handleFieldError = (err) => {
    this.props.setErrorMessage(this.getDisplayErrorMessage(err)); //GOES TO SNACK BAR
  };

  handleHideMessage = () => {
    this.props.clearErrorMessage();
  };

  savePlan = async (event) => {
    if (event) event.preventDefault();

    await this.props.dispatch(getLatestSubscription());
    const { userPlans, auth, subscriptions, match, t } = this.props;
    const { latest } = subscriptions;
    const { currentUser } = auth;
    if (userPlans && currentUser) {
      const { attributes } = currentUser;
      const { planTempData, currentPlan } = userPlans;
      const {
        birthdate,
        country,
        sex,
        welcomeNotifications,
        cognito_user_sub,
        workoutNotificationsTime,
        condition,
        other_sport,
        image,
        lastModified,
      } = currentPlan || {};
      if (planTempData && attributes) {
        const { name, family_name, email } = attributes;

        const { nextRaces, nextRace } = currentPlan;
        let newNextRaces = nextRaces;
        let newNextRace = nextRace;

        if (
          planTempData.goal &&
          planTempData.goal.raceName &&
          planTempData.goal.raceDate &&
          planTempData.goal.racePriority
        ) {
          planTempData.goal.raceId = new Date().getTime();

          newNextRaces = [];
          if (nextRaces) {
            newNextRaces = [
              ...newNextRaces,
              ...nextRaces.filter((r) => r.raceId !== planTempData.goal.raceId),
            ];
          }

          newNextRaces.push(planTempData.goal);
          if (nextRace && planTempData.goal.raceId !== "old") {
            newNextRaces.push({ ...nextRace, raceId: new Date().getTime() });
          }

          newNextRace = null;

          if (Capacitor.isNativePlatform()) {
            LocalNotificationsService.cancelRaceNotifications([
              planTempData.goal.raceId.toString() + "0",
              planTempData.goal.raceId.toString() + "1",
            ]);

            const raceDateMoment = moment(
              planTempData.goal.raceDate,
              "DD/MM/YYYY",
            );
            raceDateMoment.hours(12);
            raceDateMoment.minutes(0);
            raceDateMoment.seconds(0);
            raceDateMoment.milliseconds(0);

            const raceBefore = moment(raceDateMoment).subtract(1, "days");
            const raceAfter = moment(raceDateMoment).add(1, "days");
            LocalNotificationsService.scheduleRaceNotifications(
              [
                +(planTempData.goal.raceId.toString() + "0"),
                +(planTempData.goal.raceId.toString() + "1"),
              ],
              [
                new Date(raceBefore.unix() * 1000),
                new Date(raceAfter.unix() * 1000),
              ],
              [t("raceBefore"), t("raceAfter")],
            );
          }
        }

        let selectedPlan = this.selectedPlan();
        if (selectedPlan === "coach-choose") selectedPlan = "explore";

        const newWelcomeNotifications = this.setNotifications();
        storageFactory().removeItem("currentGoal");
        const readyToBeSavedItem = {
          ...planTempData,
          desiredStartDate:
            planTempData.desiredStartDate || moment().format("DD/MM/YYYY"),
          subcategory: match.params.id,
          selectedPlan,
          cognito_user_name: name
            ? name.toLowerCase()
            : email.split("@")[0].toLowerCase(),
          cognito_user_family_name: family_name && family_name.toLowerCase(),
          cognito_user_email: email,
          cognito_user_sub,
          birthdate: birthdate ? birthdate : planTempData.birthdate,
          condition: condition ? condition : planTempData.condition,
          country: country ? country : planTempData.country,
          other_sport: other_sport ? other_sport : planTempData.other_sport,
          sex: sex ? sex : planTempData.sex,
          welcomeNotifications: welcomeNotifications
            ? welcomeNotifications
            : newWelcomeNotifications,
          nextRace: newNextRace,
          nextRaces: newNextRaces,
          needHelp: this.selectedPlan() === "coach-choose" ? true : undefined,
        };

        const comment = {
          text: planTempData.message,
          dateTime: new Date().getTime(),
          date: moment().format("DD/MM/YYYY"),
          type: 1,
        };

        if (this.selectedPlan() === "coach-choose") {
          this.props.dispatch(
            userPlanMailchimpSubscribe({
              cognito_user_sub: currentPlan.cognito_user_sub,
              cognito_user_email: currentPlan.cognito_user_email,
              helpChoosingPlan: "help-choosing-plan",
            }),
          );
        }

        if (
          this.selectedPlan() === "coach-choose" &&
          planTempData.message &&
          planTempData.message.length
        ) {
          this.props.dispatch(
            currentTrainingAddComment(
              {
                cognito_user_sub: currentPlan.cognito_user_sub,
                comment: {
                  ...comment,
                  isReadByCoach: false,
                  senderImage: image,
                  senderName: name,
                  cognito_user_sub,
                },
                lastModified,
              },
              false,
              true,
            ),
          );
        }

        if (this.selectedPlan() === "coach-choose") {
          console.log("savePlan setPlanToSelect", true);
          this.props.dispatch(
            setPlanToSelect({
              plan: "explore",
            }),
          );
        }

        const { subCategories } = this.props;
        this.props.savePlan(
          readyToBeSavedItem,
          latest,
          subCategories,
          workoutNotificationsTime
            ? workoutNotificationsTime
            : planTempData.workoutNotificationsTime
            ? moment.utc(planTempData.workoutNotificationsTime).format()
            : null,
          this.selectedPlan() === "coach-choose",
        );
      }
    }
  };

  setNotifications() {
    const { userPlans, t, subscriptions } = this.props;
    const { currentPlan } = userPlans;
    const { welcomeNotifications, createdAt } = currentPlan || "";
    const { latest } = subscriptions;
    const { plan_id } = latest || {};

    if (
      (!createdAt || createdAt > 1635026400000) &&
      (!welcomeNotifications || (welcomeNotifications === "free" && latest)) &&
      Capacitor.isNativePlatform() &&
      Capacitor.getPlatform() === "ios" &&
      (!plan_id ||
        (!plan_id.includes("apple:explore101") &&
          !plan_id.includes("google:explore101")))
    ) {
      setTimeout(() => {
        LocalNotificationsService.scheduleNotifications(
          new Date(),
          [
            latest ? t("N1 Text") : t("N1 Text Free"),
            latest ? t("N2 Text") : t("N2 Text Free"),
            latest ? t("N3 Text") : t("N3 Text Free"),
            latest ? t("N4 Text") : t("N4 Text Free"),
            latest ? t("N5 Text") : t("N5 Text Free"),
            latest ? t("N6 Text") : t("N6 Text Free"),
            latest ? t("N7 Text") : t("N7 Text Free"),
            latest ? t("N8 Text") : t("N8 Text Free"),
            latest ? t("N9 Text") : t("N9 Text Free"),
          ],
          [
            "/profile",
            latest ? "/dashboard/chat" : "/dashboard",
            "/trailhead",
            "/dashboard",
            "/challenges",
            "/dashboard",
            "/dashboard",
            "/dashboard",
            "/dashboard",
          ],
          welcomeNotifications === "free" && latest,
        );
      }, 2000);
      return latest ? "explore" : "free";
    }
    return "";
  }

  handleBackRequested = () => {
    const { userPlans } = this.props;
    if (userPlans) {
      const { currentStep } = userPlans;
      if (currentStep === 0) {
        history.goBack();
      } else {
        this.props.planChangeCurrentStep(currentStep - 1);
      }
    }
  };

  handleNextRequested = () => {
    if (this.timeout) clearTimeout(this.timeout);
    const { userPlans, setErrorMessage, planChangeCurrentStep } = this.props;
    if (userPlans) {
      const { currentStep, planTempData } = userPlans;
      const {
        birthdate,
        country,
        trainWeekdays,
        desiredStartDate,
      } = planTempData;
      const selectedPlan = this.selectedPlan();
      if (this.state.fields[currentStep][2].id === "birthdate" && !birthdate)
        setErrorMessage("Please fill out your birthday");
      else if (
        this.state.fields[currentStep][2].id === "desiredStartDate" &&
        !desiredStartDate
      )
        setErrorMessage("Please fill out your start date");
      else if (
        this.state.fields[currentStep][2].id === "trainWeekdays" &&
        (!trainWeekdays || trainWeekdays.length === 0)
      )
        setErrorMessage("Please select week days");
      else if (
        selectedPlan === WorkoutPlans.PREMIUM &&
        this.state.fields[currentStep][2].id === "country" &&
        !country
      )
        setErrorMessage("Please fill out your country");
      else planChangeCurrentStep(currentStep + 1);
    }
  };

  componentDidMount() {
    window.scroll(0, 0);
  }

  componentWillUnmount() {
    const { planToSelect } = this.props.userPlans || {};
    const selectedPlan = planToSelect || {};
    if (this.timeout) clearTimeout(this.timeout);
    this.props.dispatch(
      setPlanToSelect({
        redirect:
          selectedPlan.redirect === "free-mobile"
            ? selectedPlan.redirect
            : null,
        plan: selectedPlan.plan,
      }),
    );
    this.props.planChangeCurrentStep(0);
  }

  render() {
    const { classes, auth, userPlans, location } = this.props;
    const { fields } = this.state;
    const {
      planTempData,
      currentStep,
      errorMessage,
      saving,
      successMessage,
      currentPlan,
      planToSelect,
    } = userPlans;

    const {
      image: oldImage,
      birthdate,
      country,
      sex,
      showWorkoutNotifications,
      condition,
      other_sport,
    } = currentPlan || {};
    const { currentUser } = auth;
    let currentUserFullName = "";
    if (currentUser) {
      const { attributes } = currentUser;
      if (attributes) {
        const { name, family_name } = attributes;
        currentUserFullName = `${name} ${family_name || ""}`.trim();
      }
    }

    const { subscriptions } = this.props;
    const { isAuthenticated } = auth;
    const { latestSubscriptionFinalizedTime } = currentPlan || {};

    const isSubscribed = checkSubscription(
      subscriptions,
      latestSubscriptionFinalizedTime,
    );
    const formId = "planForm";

    const totalSteps = this.state.fields.length - 1;
    return !saving && successMessage ? (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location },
        }}
      />
    ) : (
      <div className={classes.container}>
        {saving && <LinearProgress variant="indeterminate" color="primary" />}
        <form id={formId} onSubmit={this.savePlan} className={classes.form}>
          <Route
            exact
            path={`/chooseplan/coach-choose`}
            render={(props) => {
              return (
                <PlanCoachChoose
                  {...props}
                  planTempData={{
                    ...planTempData,
                    currentStep,
                    userFullName: currentUserFullName,
                    image: oldImage,
                  }}
                  currentData={{
                    birthdate,
                    country,
                    sex,
                    showWorkoutNotifications,
                  }}
                  onTextFieldChange={this.handleTextFieldChange}
                  onDateFieldChange={this.handleDateFieldChange}
                  onYesNoFieldChange={this.handleYesNoFieldChange}
                  onSelectFieldChange={this.handleSelectFieldChange}
                  onButtonSelectFieldChange={this.handleButtonSelectFieldChange}
                  onSliderSelect={this.handleSliderSelectFieldChange}
                  onTimeSelect={this.handleTimeSelect}
                  onFieldError={this.handleFieldError}
                  onEnterPress={this.handleEnterPress}
                  onFieldsInit={this.handleFieldsInit}
                  onBackRequested={this.handleBackRequested}
                  type={WorkoutPlans.FREE}
                />
              );
            }}
          />
          <Route
            exact
            path={`/chooseplan/${WorkoutPlans.FREE}/:id`}
            render={(props) => {
              return (
                <PlanExploreFree
                  {...props}
                  planTempData={{
                    ...planTempData,
                    currentStep,
                    userFullName: currentUserFullName,
                    image: oldImage,
                  }}
                  currentData={{
                    birthdate,
                    country,
                    sex,
                    showWorkoutNotifications,
                    condition,
                    other_sport,
                  }}
                  onTextFieldChange={this.handleTextFieldChange}
                  onDateFieldChange={this.handleDateFieldChange}
                  onYesNoFieldChange={this.handleYesNoFieldChange}
                  onSelectFieldChange={this.handleSelectFieldChange}
                  onButtonSelectFieldChange={this.handleButtonSelectFieldChange}
                  onSliderSelect={this.handleSliderSelectFieldChange}
                  onTimeSelect={this.handleTimeSelect}
                  onFieldError={this.handleFieldError}
                  onEnterPress={this.handleEnterPress}
                  onFieldsInit={this.handleFieldsInit}
                  onBackRequested={this.handleBackRequested}
                  type={WorkoutPlans.FREE}
                  onGoalSelect={this.handleGoalSelect}
                />
              );
            }}
          />
          <Route
            exact
            path={`/chooseplan/${WorkoutPlans.EXPLORE}/:id`}
            render={(props) => {
              return (
                <PlanExploreFree
                  {...props}
                  planTempData={{
                    ...planTempData,
                    currentStep,
                    userFullName: currentUserFullName,
                    image: oldImage,
                  }}
                  currentData={{
                    birthdate,
                    country,
                    sex,
                    showWorkoutNotifications,
                    condition,
                    other_sport,
                  }}
                  onTextFieldChange={this.handleTextFieldChange}
                  onDateFieldChange={this.handleDateFieldChange}
                  onYesNoFieldChange={this.handleYesNoFieldChange}
                  onSelectFieldChange={this.handleSelectFieldChange}
                  onButtonSelectFieldChange={this.handleButtonSelectFieldChange}
                  onGoalSelect={this.handleGoalSelect}
                  onTimeSelect={this.handleTimeSelect}
                  onSliderSelect={this.handleSliderSelectFieldChange}
                  onFieldError={this.handleFieldError}
                  onEnterPress={this.handleEnterPress}
                  onFieldsInit={this.handleFieldsInit}
                  onBackRequested={this.handleBackRequested}
                  type={WorkoutPlans.EXPLORE}
                />
              );
            }}
          />
          <Route
            exact
            path={`/chooseplan/${WorkoutPlans.PREMIUM}`}
            render={(props) => {
              return (
                <PlanPremium
                  {...props}
                  planTempData={{
                    ...planTempData,
                    currentStep,
                    userFullName: currentUserFullName,
                    image: oldImage,
                  }}
                  currentData={{
                    birthdate,
                    country,
                    sex,
                    showWorkoutNotifications,
                  }}
                  onTextFieldChange={this.handleTextFieldChange}
                  onDateFieldChange={this.handleDateFieldChange}
                  onYesNoFieldChange={this.handleYesNoFieldChange}
                  onSelectFieldChange={this.handleSelectFieldChange}
                  onButtonSelectFieldChange={this.handleButtonSelectFieldChange}
                  onFieldError={this.handleFieldError}
                  onArrayFieldChange={this.handleArrayFieldChange}
                  onEnterPress={this.handleEnterPress}
                  onFieldsInit={this.handleFieldsInit}
                  onBackRequested={this.handleBackRequested}
                  onTimeSelect={this.handleTimeSelect}
                />
              );
            }}
          />
          <PlanQuestionnaireButtons
            form={formId}
            totalSteps={totalSteps}
            currentStep={currentStep}
            saving={saving}
            selectedPlan={this.selectedPlan()}
            onNextRequested={this.handleNextRequested}
            onBackRequested={this.handleBackRequested}
            fields={fields}
          />
        </form>
        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
        />
      </div>
    );
  }
}

PlanQuestionnaire.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  planChangeField: PropTypes.func.isRequired,
  clearErrorMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  savePlan: PropTypes.func.isRequired,
  updatePlan: PropTypes.func.isRequired,
  planChangeCurrentStep: PropTypes.func.isRequired,
  userPlans: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  subscriptions: PropTypes.object.isRequired,
  subCategories: PropTypes.object.isRequired,
};

export default connect((store) => {
  return {
    auth: store.auth,
    userPlans: store.userPlans,
    subscriptions: store.subscriptions,
  };
})(
  withRouter(
    ConnectAuth(
      ConnectSubscriptions(
        ConnectSubcategories(
          ConnectUserPlans(
            withTranslation(
              "messages",
              "dashboard",
            )(withStyles(styles, { withTheme: true })(memo(PlanQuestionnaire))),
          ),
        ),
      ),
    ),
  ),
);
