import React, { useRef, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { round } from "../../lib/units-helper";

const Canvas = (props) => {
  const canvasRef = useRef(null);
  const { t } = useTranslation("dashboard");
  let videoContainer; // object to hold video and associated info

  const [startX, setStartX] = React.useState(0);
  const startXRef = useRef(startX);
  const [startY, setStartY] = React.useState(0);
  const startYRef = useRef(startY);

  const [endX, setEndX] = React.useState(0);
  const endXRef = useRef(endX);
  const [endY, setEndY] = React.useState(0);
  const endYRef = useRef(endY);

  const [imgX, setImgX] = React.useState(0);
  const imgXRef = useRef(imgX);
  const [imgY, setImgY] = React.useState(0);
  const imgYRef = useRef(imgY);

  const [imgBkg, setImgBkg] = React.useState(null);

  const [isDragging, setisDragging] = React.useState(false);
  const isDraggingRef = useRef(isDragging);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevProps = usePrevious({
    image: props.image,
    type: props.type,
    overlay: props.overlay,
    video: props.video,
    muted: props.muted,
    sharingVideo: props.sharingVideo,
    alignment: props.alignment,
  });

  const draw = (ctx) => {
    const canvas = ctx.canvas;
    const { devicePixelRatio = 1 } = window;

    if (props.image && props.currentActivity) {
      const image = new Image();
      image.onload = function() {
        setImgBkg(image);
        if (props.alignment === "h") {
          drawImageScaled(image, ctx, true);
        } else {
          drawImageScaledV(image, ctx, null, true);
        }

        const bkg = new Image();
        bkg.onload = function() {
          if (props.alignment === "h") {
            drawImageScaled(bkg, ctx);
          } else {
            if (props.overlay) {
              ctx.fillStyle = "black"; // darken display
              ctx.globalAlpha = 0.3;
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              ctx.globalAlpha = 1;
            }

            drawImageScaledV(bkg, ctx, "right");
          }

          drawStats(ctx, canvas);

          setTimeout(() => {
            props.onFinishDraw(canvas.toDataURL("image/jpeg"));
          }, 500);
        };

        bkg.src =
          props.overlay && props.alignment === "h"
            ? `${process.env.PUBLIC_URL}/share-bkg-o.png`
            : `${process.env.PUBLIC_URL}/share-bkg.png`;
      };
      image.src = props.image;
    } else if (props.video && props.currentActivity) {
      const video = document.getElementById("share-video"); // create a video element
      video.muted = true;
      video.src = props.video;
      video.playsInline = true;
      // the video will now begin to load.
      // As some additional info is needed we will place the video in a
      // containing object for convenience
      video.autoPlay = true; // ensure that the video does not auto play
      video.loop = true; // set the video to loop.

      videoContainer = {
        // we will add properties as needed
        video: video,
        ready: false,
      };

      const bkg = new Image();

      // can be found below
      const readyToPlayVideo = (event) => {
        videoContainer.video.play();
        // this is a referance to the video
        // the video may not match the canvas size so find a scale to fit

        resizeCanvas(canvas, video.videoWidth, video.videoHeight);

        videoContainer.scaleX = canvas.width / video.videoWidth;
        videoContainer.scaleY = canvas.height / video.videoHeight;

        videoContainer.ready = true;
        // the video can be played so hand it off to the display function
        updateCanvas();
      };

      const updateCanvas = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // only draw if loaded and ready
        if (videoContainer !== undefined && videoContainer.ready) {
          // find the top left of the video on the canvas

          const { devicePixelRatio = 1 } = window;
          var scaleX = videoContainer.scaleX;
          var scaleY = videoContainer.scaleY;
          var vidH = videoContainer.video.videoHeight;
          var vidW = videoContainer.video.videoWidth;
          var top =
            (canvas.height / 2 - (vidH / 2) * scaleX) / devicePixelRatio;
          var left =
            (canvas.width / 2 - (vidW / 2) * scaleY) / devicePixelRatio;
          // now just draw the video the correct size

          if (!props.sharingVideo) {
            ctx.drawImage(
              videoContainer.video,
              left,
              top,
              (vidW * scaleX) / devicePixelRatio,
              (vidH * scaleY) / devicePixelRatio,
            );
          }

          if (props.overlay) {
            ctx.fillStyle = "black"; // darken display
            ctx.globalAlpha = 0.5;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }

          ctx.globalAlpha = 1;
          ctx.drawImage(
            bkg,
            left,
            top,
            (vidW * scaleX) / devicePixelRatio,
            (vidW * scaleY) / devicePixelRatio,
          );
          drawStats(ctx, canvas);

          if (!props.sharingVideo) {
            requestAnimationFrame(updateCanvas);
          } else {
            props.onFinishDraw(
              canvas.toDataURL("image/png"),
              videoContainer.video.videoWidth,
              videoContainer.video.videoHeight,
            );
          }
        }
        // all done for display
      };

      bkg.onload = function() {
        setTimeout(() => readyToPlayVideo(), 500);
      };

      video.oncanplay = () => {
        props.onFinishLoadingVideo();
        bkg.src = `${process.env.PUBLIC_URL}/share-bkg.png`;
      };
    }
  };

  const handleMouseDown = (e) => {
    const startX = parseInt(
      e.clientX
        ? e.clientX
        : e.touches && e.touches.length
        ? e.touches[0].pageX
        : startXRef.current,
    );
    const startY = parseInt(
      e.clientY
        ? e.clientY
        : e.touches && e.touches.length
        ? e.touches[0].pageY
        : startYRef.current,
    );
    const isDragging = true;

    setStartX(startX);
    setStartY(startY);
    setisDragging(isDragging);
  };

  const handleMouseUp = (e) => {
    const endX = parseInt(
      e.clientX
        ? e.clientX
        : e.touches && e.touches.length
        ? e.touches[0].pageX
        : endXRef.current,
    );
    const endY = parseInt(
      e.clientY
        ? e.clientY
        : e.touches && e.touches.length
        ? e.touches[0].pageY
        : endYRef.current,
    );
    const isDragging = false;

    setEndX(endX);
    setEndY(endY);

    setImgX(imgXRef.current + endX - startXRef.current);
    setImgY(imgYRef.current + endY - startYRef.current);

    setisDragging(isDragging);
  };

  const handleMouseOut = (e, img) => {
    setisDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDraggingRef.current) {
      e.preventDefault();
      const endX = parseInt(
        e.clientX
          ? e.clientX
          : e.touches && e.touches.length
          ? e.touches[0].pageX
          : endYRef.current,
      );
      const endY = parseInt(
        e.clientY
          ? e.clientY
          : e.touches && e.touches.length
          ? e.touches[0].pageY
          : endYRef.current,
      );
      if (
        Math.abs(endX - startXRef.current) > 10 ||
        Math.abs(endY - startYRef.current) > 10
      ) {
        setEndX(endX);
        setEndY(endY);

        setImgX(imgXRef.current + endX - startXRef.current);
        setImgY(imgYRef.current + endY - startYRef.current);

        setStartX(endX);
        setStartY(endY);
      }
    }
  };

  const resizeCanvas = (canvas, w, h) => {
    let { width, height } = canvas.getBoundingClientRect();

    if (w && h) {
      height = (width / w) * h;
    }

    const context = canvas.getContext("2d");

    if (canvas.width !== width || canvas.height !== height) {
      const { devicePixelRatio: ratio = 1 } = window;
      canvas.width = width * ratio;
      canvas.height = height * ratio;
      context.scale(ratio, ratio);
      return true;
    }
  };

  const drawImageScaled = (img, ctx, withDrag = false) => {
    const canvas = ctx.canvas;
    const { devicePixelRatio = 1 } = window;
    const hRatio = canvas.width / img.width;
    const vRatio = canvas.height / img.height;

    const ratio = img.width > img.height ? vRatio : hRatio;
    const centerShift_x =
      (canvas.width - img.width * ratio) / 2 / devicePixelRatio;
    const centerShift_y =
      (canvas.height - img.height * ratio) / 2 / devicePixelRatio;
    //ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
      img,
      0,
      0,
      img.width * devicePixelRatio,
      img.height * devicePixelRatio,
      centerShift_x + (withDrag ? imgXRef.current / devicePixelRatio : 0),
      centerShift_y + (withDrag ? imgYRef.current / devicePixelRatio : 0),
      img.width * ratio,
      img.height * ratio,
    );
  };

  const drawImageScaledV = (img, ctx, align = null, withDrag = false) => {
    const canvas = ctx.canvas;
    const { devicePixelRatio = 1 } = window;
    const hRatio = canvas.width / img.width;
    const vRatio = canvas.height / img.height;

    const ratio = align === "right" ? hRatio : vRatio;
    const centerShift_x =
      align === "right"
        ? (canvas.width - img.width * ratio) / devicePixelRatio
        : (canvas.width - img.width * ratio) / 2 / devicePixelRatio;
    const centerShift_y =
      align === "right"
        ? 0
        : (canvas.height - img.height * ratio) / 2 / devicePixelRatio;
    //ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
      img,
      0,
      0,
      img.width * devicePixelRatio,
      img.height * devicePixelRatio,
      centerShift_x + (withDrag ? imgXRef.current / devicePixelRatio : 0),
      centerShift_y + (withDrag ? imgYRef.current / devicePixelRatio : 0),
      img.width * ratio,
      img.height * ratio,
    );
  };

  const drawStats = (ctx, canvas) => {
    //Main Stats

    //Distance
    const distance = props.currentActivity.distance.toFixed(
      props.currentActivity.distance > 99 ? 0 : 2,
    );
    ctx.font = `700 ${(canvas.width * 0.075) / devicePixelRatio}px "Avenir"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      distance,
      (canvas.width * 0.08) / devicePixelRatio,
      (canvas.height - canvas.width * 0.11) / devicePixelRatio,
    );

    const distanceMeasure = ctx.measureText(distance);

    ctx.font = `400 ${(canvas.width * 0.037) / devicePixelRatio}px "Avenir"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      props.units === "miles" ? `${t("miles")}` : `${t("km")}`,
      (canvas.width * 0.09) / devicePixelRatio + distanceMeasure.width,
      (canvas.height - canvas.width * 0.12) / devicePixelRatio,
    );

    ctx.font = `400 ${(canvas.width * 0.032) / devicePixelRatio}px "Avenir"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      t("distance"),
      (canvas.width * 0.08) / devicePixelRatio,
      (canvas.height - canvas.width * 0.064) / devicePixelRatio,
    );

    //Elevation

    const elevation = round(
      props.currentActivity.elevation,
      props.units === "miles" || props.currentActivity.elevation > 9999 ? 0 : 2,
    );

    ctx.font = `400 ${(canvas.width * 0.037) / devicePixelRatio}px "Avenir"`;

    const elevationUnitsMeasure = ctx.measureText(
      props.units === "miles" ? `${t("miles")}` : `${t("km")}`,
    );

    ctx.font = `700 ${(canvas.width * 0.075) / devicePixelRatio}px "Avenir"`;
    ctx.fillStyle = "white";

    const elevationMeasure = ctx.measureText(elevation);

    ctx.fillText(
      elevation,
      canvas.width / 2 / devicePixelRatio -
        //elevationUnitsMeasure.width / 2 -
        elevationMeasure.width / 2,
      (canvas.height - canvas.width * 0.11) / devicePixelRatio,
    );

    ctx.font = `400 ${(canvas.width * 0.037) / devicePixelRatio}px "Avenir"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      props.units === "miles" ? `${t("feet")}` : `${t("m")}`,
      canvas.width / 2 / devicePixelRatio -
        //elevationUnitsMeasure.width / 2 -
        elevationMeasure.width / 2 +
        elevationMeasure.width +
        (canvas.width * 0.01) / devicePixelRatio,
      (canvas.height - canvas.width * 0.12) / devicePixelRatio,
    );

    ctx.font = `400 ${(canvas.width * 0.032) / devicePixelRatio}px "Avenir"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      t("elevation"),
      canvas.width / 2 / devicePixelRatio -
        //elevationUnitsMeasure.width / 2 -
        elevationMeasure.width / 2,
      (canvas.height - canvas.width * 0.064) / devicePixelRatio,
    );

    //Time
    var h = Math.floor(props.currentActivity.time / 3600);
    var m = Math.floor((props.currentActivity.time % 3600) / 60);
    const time = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;

    ctx.font = `700 ${(canvas.width * 0.075) / devicePixelRatio}px "Avenir"`;

    const timeMeasure = ctx.measureText(time);

    ctx.fillStyle = "white";
    ctx.fillText(
      time,
      canvas.width / devicePixelRatio -
        timeMeasure.width -
        (canvas.width * 0.08) / devicePixelRatio,
      (canvas.height - canvas.width * 0.11) / devicePixelRatio,
    );

    ctx.font = `400 ${(canvas.width * 0.032) / devicePixelRatio}px "Avenir"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      t("time"),
      canvas.width / devicePixelRatio -
        timeMeasure.width -
        (canvas.width * 0.08) / devicePixelRatio,
      (canvas.height - canvas.width * 0.064) / devicePixelRatio,
    );

    //Curve

    if (props.type === 1 || props.type === 3) {
      const justY = props.elevationSamples.map((e) => e.y);

      const minY = Math.min(...justY);
      const maxY = Math.max(...justY);

      ctx.strokeStyle = "#FFFFFF";
      ctx.beginPath();
      ctx.moveTo(
        -10,
        (canvas.height - canvas.width * 0.186) / devicePixelRatio,
      );

      const resampled = props.elevationSamples.map((e) => {
        const value = {
          x:
            (e.x * (canvas.width / devicePixelRatio)) /
            props.elevationSamples[props.elevationSamples.length - 1].x,
          y:
            (canvas.height - canvas.width * 0.186) / devicePixelRatio -
            ((e.y - minY) * ((canvas.width * 0.2) / devicePixelRatio)) /
              (maxY - minY),
        };

        ctx.lineTo(value.x, value.y);

        return value;
      });

      ctx.lineTo(
        canvas.width / devicePixelRatio + 10,
        (canvas.height - canvas.width * 0.186) / devicePixelRatio,
      );

      ctx.lineTo(
        canvas.width / devicePixelRatio + 10,
        canvas.height / devicePixelRatio + 10,
      );
      ctx.lineTo(-10, canvas.height / devicePixelRatio + 10);
      ctx.closePath();
      ctx.stroke();
    }

    // Vert speed

    if (props.type === 2 || props.type === 3) {
      //const uphill = new Image();
      //uphill.onload = function() {
      /*ctx.drawImage(
                uphill,
                (canvas.width * 0.07) / devicePixelRatio,
                (canvas.width * 0.08) / devicePixelRatio,
                (canvas.width * 0.11) / devicePixelRatio,
                (canvas.width * 0.11) / devicePixelRatio,
              );*/

      const uphillPace = round(props.currentActivity.uphillPace || 0, 0);
      ctx.font = `500 ${(canvas.width * 0.04) / devicePixelRatio}px "Avenir"`;
      ctx.fillStyle = "white";
      ctx.fillText(
        uphillPace,
        (canvas.width * 0.08) / devicePixelRatio,
        (canvas.width * 0.155) / devicePixelRatio,
      );

      const uphillPaceMeasure = ctx.measureText(uphillPace);

      ctx.font = `400 ${(canvas.width * 0.032) / devicePixelRatio}px "Avenir"`;
      ctx.fillStyle = "white";
      ctx.fillText(
        props.units === "miles" ? `${t("feet/h")}` : `${t("m/h")}`,
        (canvas.width * 0.09) / devicePixelRatio + uphillPaceMeasure.width,
        (canvas.width * 0.155) / devicePixelRatio,
      );

      ctx.font = `400 ${(canvas.width * 0.032) / devicePixelRatio}px "Avenir"`;
      ctx.fillStyle = "white";
      ctx.fillText(
        t("avg uphill speed"),
        (canvas.width * 0.08) / devicePixelRatio,
        (canvas.width * 0.205) / devicePixelRatio,
      );

      //const downhill = new Image();
      //downhill.onload = function() {
      /*ctx.drawImage(
                  downhill,
                  (canvas.width * 0.07) / devicePixelRatio,
                  (canvas.width * 0.32) / devicePixelRatio,
                  (canvas.width * 0.11) / devicePixelRatio,
                  (canvas.width * 0.11) / devicePixelRatio,
                );*/

      const downhillPace = round(props.currentActivity.downhillPace || 0, 0);
      ctx.font = `500 ${(canvas.width * 0.04) / devicePixelRatio}px "Avenir"`;
      ctx.fillStyle = "white";
      ctx.fillText(
        downhillPace,
        (canvas.width * 0.08) / devicePixelRatio,
        (canvas.width * 0.322) / devicePixelRatio,
      );

      const downhillPaceMeasure = ctx.measureText(downhillPace);

      ctx.font = `400 ${(canvas.width * 0.032) / devicePixelRatio}px "Avenir"`;
      ctx.fillStyle = "white";
      ctx.fillText(
        props.units === "miles" ? `${t("feet/h")}` : `${t("m/h")}`,
        (canvas.width * 0.09) / devicePixelRatio + downhillPaceMeasure.width,
        (canvas.width * 0.322) / devicePixelRatio,
      );

      ctx.font = `400 ${(canvas.width * 0.032) / devicePixelRatio}px "Avenir"`;
      ctx.fillStyle = "white";
      ctx.fillText(
        t("avg downhill speed"),
        (canvas.width * 0.08) / devicePixelRatio,
        (canvas.width * 0.372) / devicePixelRatio,
      );
    }
  };

  /*useEffect(() => {
    const canvas = canvasRef.current;
    canvas.addEventListener("mousedown", (e) => handleMouseDown(e), false);
    canvas.addEventListener("mousemove", (e) => handleMouseMove(e), false);
    canvas.addEventListener("mouseup", (e) => handleMouseUp(e), false);
    canvas.addEventListener("mouseout", (e) => handleMouseOut(e), false);

    canvas.addEventListener("touchstart", (e) => handleMouseDown(e), false);
    canvas.addEventListener("touchmove", (e) => handleMouseMove(e), false);
    canvas.addEventListener("touchend", (e) => handleMouseUp(e), false);
    canvas.addEventListener("touchout", (e) => handleMouseOut(e), false);
    return () => {
      canvas.removeEventListener("mousedown", (e) => handleMouseDown(e));
      canvas.removeEventListener("mousemove", (e) => handleMouseMove(e));
      canvas.removeEventListener("mouseup", (e) => handleMouseUp(e));
      canvas.removeEventListener("mouseout", (e) => handleMouseOut(e));

      canvas.removeEventListener("touchstart", (e) => handleMouseDown(e));
      canvas.removeEventListener("touchmove", (e) => handleMouseMove(e));
      canvas.removeEventListener("touchend", (e) => handleMouseUp(e));
      canvas.removeEventListener("touchout", (e) => handleMouseOut(e));
    };
  }, []);*/

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (
      !prevProps ||
      prevProps.image !== props.image ||
      prevProps.video !== props.video ||
      prevProps.type !== props.type ||
      prevProps.overlay !== props.overlay ||
      prevProps.muted !== props.muted ||
      prevProps.sharingVideo !== props.sharingVideo ||
      prevProps.alignment !== props.alignment
    ) {
      resizeCanvas(canvas);
      draw(context);
    }
  }, [resizeCanvas, draw]);

  useEffect(() => {
    startXRef.current = startX;
    startYRef.current = startY;
    endXRef.current = endX;
    endYRef.current = endY;
    imgXRef.current = imgX;
    imgYRef.current = imgY;
    isDraggingRef.current = isDragging;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    resizeCanvas(canvas);

    if (isDragging) {
      if (props.alignment === "h") {
        context.clearRect(0, 0, canvas.width, canvas.height);
        drawImageScaled(imgBkg, context, true);
      } else {
        drawImageScaledV(imgBkg, context, null, true);
      }
    } else {
      resizeCanvas(canvas);
      draw(context);
    }
  }, [startX, startY, endX, endY, imgX, imgY, isDragging]);

  return (
    <>
      <canvas id="share-canvas" ref={canvasRef} {...props} />
      <video
        id="share-video"
        style={{ display: "none" }}
        autoPlay
        muted
        playsInline
        loop
      ></video>
    </>
  );
};

export default memo(Canvas);
