import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

const styles = () => ({
  link: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      opacity: 0.9,
    },
  },
});

class RouterLink extends Component {
  render() {
    const { classes, className, to } = this.props;

    return (
      <Link className={classNames(classes.link, className)} to={to}>
        {this.props.children}
      </Link>
    );
  }
}

RouterLink.propTypes = {
  classes: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(RouterLink);
