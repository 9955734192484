/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "7845987cce0a4c4eb3b187b559650047",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "7845987cce0a4c4eb3b187b559650047",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "apiathletes",
            "endpoint": "https://bidslzw4wd.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apibedrock",
            "endpoint": "https://4qtmsrwu6l.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apichallenges",
            "endpoint": "https://1ud68fmvpj.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apidatasage",
            "endpoint": "https://2kg412j7nk.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apidevice",
            "endpoint": "https://476fkn24m5.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apilabs",
            "endpoint": "https://r3qi2cq9z9.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apinotifications",
            "endpoint": "https://afeodarqml.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apireferrals",
            "endpoint": "https://ucrqyn1ez3.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apistrava",
            "endpoint": "https://p5oexurexc.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apisubcategories",
            "endpoint": "https://mzcp49n8dl.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apisubscriptions",
            "endpoint": "https://9oer36suw5.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apitrailhead",
            "endpoint": "https://g7izhnxeb2.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apitrainingplans",
            "endpoint": "https://dik94xm3ed.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apiuserplans",
            "endpoint": "https://jy4wo46xt9.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        },
        {
            "name": "apiworkouts",
            "endpoint": "https://ujau5c8w54.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:bc09dc9a-b62c-48f2-85eb-72a2e2836d76",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0FEYsZZXz",
    "aws_user_pools_web_client_id": "2iuciil5242kjanvrl5osb11d7",
    "oauth": {
        "domain": "vertrunappsocialsignin-prd.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://develop.vert.run/,http://localhost:3000/,https://localhost:3000/,https://app.vert.run/",
        "redirectSignOut": "https://develop.vert.run/,http://localhost:3000/,https://localhost:3000/,https://app.vert.run/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "dynamodbchallenges-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbchats-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbdata-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbeevents-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbepicraces-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbexploreposts-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbgarminactivities-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbnotifications-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbposts-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbpublicgroupchallenges-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbpublicgroups-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbreferralcodes-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbstravaactivities-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbstravatokens-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbsubcategories-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbsubscriptions-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbsuuntoactivities-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbtags-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbtrainingplans-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbuserchallenges-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbuserplans-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbuserplanslatest-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbvertactivities-prd",
            "region": "us-east-1"
        },
        {
            "tableName": "dynamodbworkouts-prd",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "vert-run-app-user-profile-pictures-dev-prd",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
