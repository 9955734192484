import React, { Component, memo } from "react";
import {
  Dialog,
  DialogContent,
  withMobileDialog,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";

import {
  patchSelectedAthleteWorkoutCommentsRead,
  patchSelectedAthleteWorkoutComment,
  patchSelectedAthleteWorkoutDeleteComment,
  patchSelectedAthleteWorkoutEditComment,
  getAthleteOldComments,
  updateAthleteDataLocally,
} from "../../actions/athletes-action";
import TrainingWeekdayDetailsChat from "../common/training-weekday-chat";
import { grey } from "@material-ui/core/colors";
import UserGroups from "../../constants/user-groups";

const styles = (theme) => ({
  container: {
    background: "#FFFFFF",
    overflow: "hidden",
    padding: "0 0 5px 0 !important",
    [theme.breakpoints.down("sm")]: {
      overflow: "scroll",
    },
  },
  textContainer: {
    marginTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit,
  },
  newLine: {
    whiteSpace: "pre-line",
  },
  newLineEffort: {
    fontWeight: 500,
    whiteSpace: "pre-line",
  },
  borderedTextContainer: {
    paddingBottom: theme.spacing.unit,
    borderStyle: "groove",
    borderWidth: "thin",
    borderRadius: 5,
    padding: theme.spacing.unit * 2.5,
    minHeight: theme.spacing.unit * 5,
  },
  extraBottomMargin: {
    marginBottom: theme.spacing.unit * 4,
  },
  topBar: {
    marginTop: 32,
  },
  topTitle: {
    color: grey[700],
    marginBottom: theme.spacing.unit,
    fontSize: "0.8rem",
    fontWeight: "normal",
  },
  topText: {
    color: "#000000",
    marginBottom: theme.spacing.unit,
    fontSize: "1rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  effort: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: -10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: -10,
    },
  },
  effortLabel: {
    fontSize: 14,
    textAlign: "center",
    color: "#5F5F5F",
    fontWeight: "normal",
  },
  effortSelect: {
    margin: 10,
    cursor: "pointer",
    width: 28,
    height: 28,
    [theme.breakpoints.down("sm")]: {
      width: 20,
      height: 20,
      margin: 7,
    },
  },
  workoutColumn: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
      marginBottom: 70,
    },
  },
  chatColumn: {
    padding: "0 !important",
    order: 1,
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      order: 2,
      position: "absolute",
      bottom: 0,
      width: "100vw",
    },
  },
  smallerFontSize: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller",
    },
  },
  rightMargin: {
    marginRight: theme.spacing.unit * 2,
  },
  buttons: {
    textTransform: "none",
  },
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  paper: {
    width: 400,
    margin: "100px 65px 30px auto",
    height: "calc(100vh - 130px)",
    borderRadius: 18,
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  backdrop: {
    background: "transparent",
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    width: "100%",
    right: 0,
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },
  mainTitle: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 12,
    paddingBottom: 0,
    textAlign: "center",
    padding: 10,
    textTransform: "uppercase",
  },

  scrollBody: {
    textAlign: "end",
  },
});

class AthletesChat extends Component {
  state = {
    savingComment: false,
  };

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleClose = () => {
    const { savingComment } = this.state;
    if (!savingComment) {
      const { athletes, onClose, auth } = this.props;
      const { selectedAthlete = {} } = athletes;

      const { currentUserGroups } = auth;
      const isAdmin =
        currentUserGroups &&
        currentUserGroups.findIndex(
          (group) => group === UserGroups.USER_GROUP_ADMIN,
        ) > -1;

      if (!isAdmin) {
        this.props.dispatch(
          patchSelectedAthleteWorkoutCommentsRead(selectedAthlete),
        );
      }

      setTimeout(() => {
        onClose();
      }, 500);
    }
  };

  handleTextChange = (event) => {
    const { target } = event;
    if (target) {
      const { value } = target;
      this.setState({
        ...this.state,
        comment: value,
      });
    }
  };

  handleSave = async (comment) => {
    const { athletes, userPlans, action } = this.props;
    const { selectedAthlete = {} } = athletes;
    const { currentPlan } = userPlans;
    const { image, cognito_user_name } = currentPlan;
    const { cognito_user_sub } = selectedAthlete || {};

    const currentComment = localStorage.getItem("comment");
    if (currentComment) localStorage.removeItem("comment");

    const message = {
      ...comment,
      senderImage: image,
      senderName: cognito_user_name,
      cognito_user_sub,
    };

    await this.props.dispatch(
      patchSelectedAthleteWorkoutComment(selectedAthlete, message),
    );

    const value = {
      ...message,
      created_at: new Date().getTime(),
      dateTime: new Date().getTime(),
    };

    const selectedAthleteCopy = { ...selectedAthlete };
    selectedAthleteCopy.lastChat = value;
    await this.props.dispatch(updateAthleteDataLocally(selectedAthleteCopy));
  };

  handleDelete = async (comment) => {
    const { athletes } = this.props;
    const { selectedAthlete = {} } = athletes;
    this.props.dispatch(
      patchSelectedAthleteWorkoutDeleteComment(selectedAthlete, comment),
    );
  };

  handleEdit = (comment) => {
    const { athletes, auth } = this.props;
    const { selectedAthlete = {} } = athletes;
    const { currentUserGroups } = auth || {};

    const isCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_COACH,
      ) > -1;

    const isStrengthCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_STRENGTH_COACH,
      ) > -1;

    this.props.dispatch(
      patchSelectedAthleteWorkoutEditComment(
        selectedAthlete,
        comment,
        isCoach || isStrengthCoach ? false : true,
        isCoach || isStrengthCoach,
      ),
    );
  };

  handleLoadOld = (dateTime) => {
    const { athletes } = this.props;
    const { selectedAthlete = {} } = athletes;
    const { cognito_user_sub } = selectedAthlete || {};

    this.props.dispatch(getAthleteOldComments(dateTime, cognito_user_sub));
  };

  render() {
    const {
      classes,
      fullScreen,
      athletes,
      t,
      open,
      workout,
      level,
    } = this.props;
    const {
      selectedAthlete = {},
      comments = [],
      loadingComments,
      loadingOldComments,
      canLoadMoreComments,
    } = athletes;
    const { selectedPlan, cognito_user_name } = selectedAthlete || {};

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={this.handleClose}
        aria-labelledby="Workout"
        scroll="body"
        maxWidth="sm"
        className={classes.dialog}
        classes={{
          paper: classes.paper,
          scrollBody: classes.scrollBody,
        }}
        BackdropProps={{
          invisible: true,
        }}
      >
        <DialogContent className={classes.container}>
          <div className={classes.rightButtons}>
            <img
              src={`${process.env.PUBLIC_URL}/close.svg`}
              alt=""
              onClick={this.handleClose}
            />
          </div>
          <Typography variant="h6" className={classes.mainTitle}>
            {t("CHAT WITH", { name: cognito_user_name })}
          </Typography>
          <TrainingWeekdayDetailsChat
            loading={loadingComments}
            comments={comments}
            workout={workout}
            handleSaveComment={this.handleSave}
            handleDeleteComment={this.handleDelete}
            handleEditComment={this.handleEdit}
            selectedPlan={selectedPlan}
            type={0}
            showAll={true}
            toggleChat={() => {}}
            userName={cognito_user_name}
            isCoach={true}
            onLoadOld={this.handleLoadOld}
            loadingOldComments={loadingOldComments}
            canLoadMoreComments={canLoadMoreComments}
            level={level}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

AthletesChat.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  fullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  athletes: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect((store) => {
  return {
    athletes: store.athletes,
    userPlans: store.userPlans,
    auth: store.auth,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation(["dashboard", "constants"])(
      withWidth()(withMobileDialog()(memo(AthletesChat))),
    ),
  ),
);
