import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  videoContainer: {
    position: "relative",
    width: "149px",
  },
  video: {
    width: "149px",
    height: "188px",
  },
}));

const VideoPlayer = ({ videoSource }) => {
  const classes = useStyles();
  const videoRef = useRef(null);

  return (
    <div className={classes.videoContainer}>
      <video ref={videoRef} className={classes.video} autoPlay controls>
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video element.ç
      </video>
    </div>
  );
};

export default VideoPlayer;

/*{openVideo ? (
  <div className={classes.videoContainer}>
    {media ? (
      getYoutubeId(media) ? (
        getVideoIframe(media)
      ) : (
        <video
          width={videoWidth}
          height={videoWidth * 0.56}
          controls
          autoPlay
        >
          <source src={media} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    ) : (
      ""
    )}
  </div>
) : (
  <div className={classes.postImageContainer}>
    <img
      className={classes.postImage}
      src={currentExplorePost.image_url}
      alt=""
    />
    {currentExplorePost.media_type.toLowerCase() === "video" ? (
      <div
        className={classes.videoPlayCont}
        onClick={() => this.openMedia()}
      >
        <PlayArrowIcon className={classes.videoPlay} />
      </div>
    ) : (
      ""
    )}

    {currentExplorePost.collab_url &&
      currentExplorePost.collab_image_url ? (
      <div
        style={{
          textDecoration: "none",
          position: "absolute",
          bottom: 15,
          left: 5,
        }}
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          window.open(currentExplorePost.collab_url, "_blank");
        }}
      >
        <img
          src={currentExplorePost.collab_image_url}
          alt=""
          className={classes.collab_img}
        />
      </div>
    ) : (
      ""
    )}
  </div>
)}*/
