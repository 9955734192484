const TrainingPerWeek = {
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
};

export default {
  Items: TrainingPerWeek,
  itemsArray: Object.keys(TrainingPerWeek).map((key) => {
    return {
      text: TrainingPerWeek[key],
      value: key,
    };
  }),
};
