import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import ComingSoon from "./coming-soon";

const useStyles = makeStyles({
  main: {
    padding: "20px 35px 8px 35px",
    backgroundColor: "#21222D",
    boxSizing: "border-box",
    borderRadius: 10,
    marginTop: "24px",
  },
  title: {
    color: "#FFF",
    marginBottom: "12px",
  },
  comingParent: {
    marginTop: "12px",
    marginBottom: "20px",
  },
});

const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        color: "#DDDDDD",
        zeroLineColor: "#DDDDDD",
      },
      ticks: {
        minor: {
          fontFamily: "Avenir",
          fontSize: 12,
          fontColor: "#9E9E9E",
        },
        padding: 10,
      },
      stacked: true,
    },
    y: {
      grid: {
        drawBorder: false,
        color: "rgba(0, 0, 0, 0.05)",
        zeroLineColor: "rgba(0, 0, 0, 0.05)",
      },
      ticks: {
        beginAtZero: true,
        fontFamily: "Avenir",
        fontSize: 12,
        fontColor: "#9E9E9E",
        padding: 10,
      },
      stacked: true,
    },
  },
  animation: {
    easing: "linear",
    duration: 1000,
  },
  tooltips: {
    enabled: true,
    mode: "single",
  },
};

const data = {
  labels: ["1", "2", "3", "4", "5", "6", "7"],
  datasets: [
    {
      label: false,
      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: "#A9DFD8",
      borderColor: "#A9DFD8",
      borderWidth: 1,
    },
  ],
};

const TrainingLoaDistribution = () => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Typography variant="h6" className={classes.title}>
        {" "}
        Training Load distribution{" "}
      </Typography>
      <Box className={classes.comingParent}>
        <ComingSoon />
      </Box>
      <Box height={280}>
        <Bar data={data} options={options} />
      </Box>
    </Box>
  );
};

export default TrainingLoaDistribution;
