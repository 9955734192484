import React, { memo, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Drawer, Typography, Tabs, Tab } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { withTranslation } from "react-i18next";
import SmallAvatar from "../../../components/generals/small-avatar";
import unitsHook from "../../../hooks/unitsHook";
import { useSelector, useDispatch } from "react-redux";
import history from "../../../lib/history-helper";
import DashboardAddEditNextRace from "../dashboard-training/dashboard-next-race/dashboard-add-edit-next-race";
import LocalNotificationsService from "../../../services/local-notifications-service";
import {
  updatePlan,
  setErrorMessage,
} from "../../../actions/user-plans-action";
import { Capacitor } from "@capacitor/core";
import moment from "moment";
import WorkoutStat from "../../generals/workout-stat";

const styles = (theme) => ({
  drawerRoot: {
    backgroundColor: "#F9F9FB",
    maxHeight: "100%",
    height: "100%",
    maxWidth: "500px",
    minWidth: "500px",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "100vw",
      minWidth: "100vw",
    },
  },
  header: {
    display: "flex",
    backgroundColor: "#FFF",
    padding: "10px 16px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subtitle: {
    textAlign: "center",
    color: "#E16327",
    marginTop: "8px",
    cursor: "pointer",
  },
  bold: {
    fontWeight: "bold",
  },
  content: {
    padding: "16px 0",
  },
  noRacesParent: {
    display: "flex",
    margin: "16px",
    justifyContent: "center",
    alignItems: "center",
  },
  goBack: {
    cursor: "pointer",
  },
  container: {
    borderRadius: 8,
    backgroundColor: "#FFF",
    padding: 14,
    margin: "15px 16px",
    boxSizing: "border-box",
    cursor: "pointer",
    position: "relative",
  },
  title: {
    fontWeight: 500,
  },
  boldText: {
    fontWeight: "bold",
    paddingRight: theme.spacing.unit * 0.5,
  },
  white: {
    color: theme.palette.common.white,
  },
  text: {
    color: theme.palette.text.secondaryDark,
    marginTop: "auto",
    fontSize: "small",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      marginTop: 0,
    },
  },
  raceName: {
    fontWeight: 500,
    fontSize: 15,
    color: "#212121",
    margin: "0px 0px 12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "50%",
  },
  raceBottomTitle: {
    color: "#212121",
    fontSize: 12,
    fontWeight: 400,
    margin: "0 12px 0 5px",
  },
  raceBottomText: {
    color: "#212121",
    fontSize: 14,
    fontWeight: 500,
  },
  raceLocation: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePriorityContainer: {
    display: "flex",
    alignItems: "center",
  },
  racePriority: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePrioritySelected: {
    color: "#212121",
    fontSize: 10,
    background: "rgba(255, 87, 34, 0.24)",
    fontWeight: 500,
    width: "fit-content",
    padding: "0px 4px",
  },
  racePriorityUnselected: {
    margin: "0 5px",
    background: "#FFBA9B",
    borderRadius: "100%",
    fontWeight: 900,
    fontSize: 21,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "34px",
    textAlign: "center",
  },
  raceBottomContainer: {
    display: "flex",
    alignItems: "center",
    margin: "2px 0",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      margin: "0 0 12px 0",
    },
  },
  addButtonContainer: {
    marginLeft: "10px",
    cursor: "pointer",
  },
  shareText: {
    color: "#E16327",
    fontSize: 14,
    fontWeight: "bold",
  },
  addRaceParent: {
    display: "flex",
    justifyContent: "center",
    marginTop: "12px",
    cursor: "pointer",
  },
  addRaceChild: {
    display: "flex",
    alignItems: "center",
  },
  addTitle: {
    color: "#E16327",
    fontWeight: "bold",
    marginLeft: "4px",
    fontSize: "14px",
  },
  statParent: {
    position: "absolute",
    // transform: "translate(0%, -50%)",
    top: "16px",
    right: "5%",
    [theme.breakpoints.down("sm")]: {
      right: "5%",
    },
  },
});

const AllRaces = ({ classes, t }) => {
  const [editing, setEditing] = useState(null);
  const dispatch = useDispatch();
  const currentUnits = unitsHook();
  const { userPlans } = useSelector((state) => state);
  const { currentPlan } = userPlans || {};
  const { nextRaces: races } = currentPlan || [];

  const [tab, setTab] = useState(0);
  const [filteredRaces, setFilteredRaces] = useState([]);
  const [raceToEdit, setRaceToEdit] = useState(null);

  useEffect(() => {
    if (tab == 0 && races) sortRaces(races);
    if (tab == 1 && races) sortRaces(races, true);
  }, [tab, races]);

  const calculateDistance = (currentRace, unit) => {
    if (
      (currentRace.units && unit !== currentRace.units) ||
      (!currentRace.units && unit === "miles")
    ) {
      if (unit === "kms") return Math.round(currentRace.raceDistance * 1.60934);
      return Math.round(currentRace.raceDistance * 0.621371);
    }

    return Math.round(parseFloat(currentRace.raceDistance));
  };

  const calculateElevation = (currentRace, unit) => {
    if (
      (currentRace.units && unit !== currentRace.units) ||
      (!currentRace.units && unit === "miles")
    ) {
      if (unit === "kms") return Math.round(currentRace.raceElevation * 0.3048);
      return Math.round(currentRace.raceElevation * 3.28084);
    }

    return Math.round(parseFloat(currentRace.raceElevation));
  };

  const goBack = () => history.goBack();

  const handleEditing = () => {
    setEditing("add");
  };

  const handleClose = () => {
    setEditing(null);
    setRaceToEdit(null);
  };

  const handleSave = (race) => {
    if (
      race.raceName &&
      race.raceDistance &&
      race.raceElevation &&
      race.raceDate &&
      race.racePriority
    ) {
      const { currentPlan } = userPlans;
      const { nextRaces, nextRace } = currentPlan;

      if (race.raceId === "add") race.raceId = new Date().getTime();

      let newNextRaces = [];
      if (nextRaces) {
        newNextRaces = [
          ...newNextRaces,
          ...nextRaces.filter((r) => r.raceId !== race.raceId),
        ];
      }
      newNextRaces.push(race);
      if (nextRace && race.raceId !== "old")
        newNextRaces.push({ ...nextRace, raceId: new Date().getTime() });

      dispatch(
        updatePlan(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            nextRace: null,
            nextRaces: newNextRaces,
          },
          false,
        ),
      );

      if (Capacitor.isNativePlatform()) {
        LocalNotificationsService.cancelRaceNotifications([
          race.raceId.toString() + "0",
          race.raceId.toString() + "1",
        ]);

        const raceDateMoment = moment(race.raceDate, "DD/MM/YYYY");
        raceDateMoment.hours(12);
        raceDateMoment.minutes(0);
        raceDateMoment.seconds(0);
        raceDateMoment.milliseconds(0);

        const raceBefore = moment(raceDateMoment).subtract(1, "days");
        const raceAfter = moment(raceDateMoment).add(1, "days");
        LocalNotificationsService.scheduleRaceNotifications(
          [+(race.raceId.toString() + "0"), +(race.raceId.toString() + "1")],
          [
            new Date(raceBefore.unix() * 1000),
            new Date(raceAfter.unix() * 1000),
          ],
          [t("raceBefore"), t("raceAfter")],
        );
      }

      setEditing(null);
    } else {
      dispatch(setErrorMessage("Please fill out all your race details"));
    }
  };

  const sortRaces = (racesArray, past = false) => {
    const filtered = racesArray.filter((race) => {
      const raceTime = moment(race.raceDate, "DD/MM/YYYY");
      const difference = raceTime.diff(moment(), "days");
      if (past && difference < 0) return race;
      if (!past && difference >= 0) return race;
    });

    const sorted = filtered.sort((a, b) => {
      const itemA = moment(a.raceDate, "DD/MM/YYYY").valueOf();
      const itemB = moment(b.raceDate, "DD/MM/YYYY").valueOf();

      if (past) return itemB - itemA;
      return itemA - itemB;
    });

    setFilteredRaces(sorted || []);
  };

  const handleDelete = (raceId) => {
    const { nextRaces, nextRace } = currentPlan;

    let newNextRaces = [];
    if (nextRaces) {
      newNextRaces = nextRaces.filter((r) => r.raceId !== raceId);
    }

    if (nextRace && raceId !== "old") {
      newNextRaces.push({ ...nextRace, raceId: new Date().getTime() });
    }

    dispatch(
      updatePlan(
        {
          cognito_user_sub: currentPlan.cognito_user_sub,
          nextRace: null,
          nextRaces: newNextRaces,
        },
        false,
      ),
    );

    if (Capacitor.isNativePlatform()) {
      LocalNotificationsService.cancelRaceNotifications([
        raceId.toString() + "0",
        raceId.toString() + "1",
      ]);
    }

    setEditing(null);
  };

  const changeTab = (value) => setTab(value);

  const editRace = (race) => {
    setEditing(race.raceId);
    setRaceToEdit(race);
  };

  return (
    <>
      <Drawer
        open={true}
        anchor="right"
        onClose={goBack}
        classes={{
          paper: classes.drawerRoot,
        }}
      >
        <div className={classes.header}>
          <div style={{ position: "relative" }}>
            <SmallAvatar onTrailHead={false} />
          </div>

          <div>
            <Typography variant="body1" className={classes.bold}>
              {t("Your Races").toUpperCase()}
            </Typography>
          </div>

          <div onClick={goBack} className={classes.goBack}>
            <Close />
          </div>
        </div>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          style={{ backgroundColor: "#FFF" }}
          variant="fullWidth"
        >
          <Tab label={t("Next Races")} index={0} onClick={() => changeTab(0)} />
          <Tab label={t("Past Races")} index={1} onClick={() => changeTab(1)} />
        </Tabs>

        <div>
          <div className={classes.addRaceParent}>
            <div className={classes.addRaceChild} onClick={handleEditing}>
              <img
                src={`${process.env.PUBLIC_URL}/icon-add-nr.svg`}
                alt="add-icon"
              />
              <Typography variant="body1" className={classes.addTitle}>
                {t("Add A Race To Your Calendar")}
              </Typography>
            </div>
          </div>
          {(filteredRaces || []).map((item, index) => (
            <div
              className={classes.container}
              key={index}
              onClick={() => editRace(item)}
            >
              <div className={classes.statParent}>
                <WorkoutStat
                  distance={calculateDistance(item, currentUnits)}
                  elevation={calculateElevation(item, currentUnits)}
                />
              </div>
              <div className={classes.titleContainer}>
                <Typography variant="body2" className={classes.title}>
                  {t("Your Races")}
                </Typography>

                {/* <div className={classes.addButtonContainer}>
                  <Typography className={classes.shareText}>
                    {t("Share goal")}
                  </Typography>
                </div> */}
              </div>

              <Typography variant="h6" className={classes.racePrioritySelected}>
                {t("Priority")} {item.racePriority}
              </Typography>
              <Typography variant="h6" className={classes.raceName}>
                {item.raceName}
              </Typography>

              <div>
                <div className={classes.raceBottomContainer}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-distance.svg`}
                    alt=""
                  />
                  <Typography variant="h6" className={classes.raceBottomTitle}>
                    {t("Distance")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.raceBottomText}
                  >
                    {item.raceDistance
                      ? !currentUnits || currentUnits === "kms"
                        ? t("X Kms", {
                            value: calculateDistance(item, currentUnits) || 0,
                          })
                        : t("X miles", {
                            value: calculateDistance(item, currentUnits) || 0,
                          })
                      : t("Empty")}
                  </Typography>
                </div>
                <div className={classes.raceBottomContainer}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-elevation.svg`}
                    alt=""
                  />

                  <Typography variant="h6" className={classes.raceBottomTitle}>
                    {t("Elevation")}
                  </Typography>

                  <Typography
                    variant="body1"
                    className={classes.raceBottomText}
                  >
                    {item.raceElevation
                      ? !currentUnits || currentUnits === "kms"
                        ? t("X ms", {
                            value: calculateElevation(item, currentUnits) || 0,
                          })
                        : t("X feet", {
                            value: calculateElevation(item, currentUnits) || 0,
                          })
                      : t("Empty")}
                  </Typography>
                </div>
                <div className={classes.raceBottomContainer}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-timer.svg`}
                    alt=""
                  />
                  <Typography variant="h6" className={classes.raceBottomTitle}>
                    {t("Date")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.raceBottomText}
                  >
                    {item.raceDate}
                  </Typography>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Drawer>
      {editing && (
        <DashboardAddEditNextRace
          isOpen={editing}
          onClose={handleClose}
          userPlans={userPlans}
          editing={editing}
          onEdit={editRace}
          onSave={handleSave}
          onDelete={handleDelete}
          nextRace={raceToEdit}
          parentMethod={handleClose}
        ></DashboardAddEditNextRace>
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("dashboard")(memo(AllRaces)),
);
