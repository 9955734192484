import ActionTypes from "../constants/action-types";
import AthletesService from "../services/athletes-service";
import { Storage } from "aws-amplify";
import UserPlansService from "../services/user-plans-service";
import { dateFormat } from "../lib/date-helper";
import moment from "moment";
import { currentTrainingGetComments } from "./user-plans-action";

const changeSearchPhrase = (searchPhrase) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_SEARCH_PHRASE_CHANGED,
      payload: searchPhrase,
    });
  };
};

const changeSearchFilter = (searchFilter) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_SEARCH_FILTER_CHANGED,
      payload: searchFilter,
    });
  };
};
const addVisitedPage = (newPage) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_ADD_VISITED_PAGE,
      payload: newPage,
    });
  };
};

const changeSort = (sort) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_SORT_CHANGED,
      payload: sort,
    });
  };
};

const athletesSearch = (searchParams, isAdmin, cognito_user_sub, data = []) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_SEARCH,
      payload: AthletesService.athletesSearch(
        searchParams,
        isAdmin,
        cognito_user_sub,
        data,
      ),
    });
  };
};

const getCoachAthletesCount = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_GET_COUNT,
      payload: AthletesService.getCoachAthletesCount(),
    });
  };
};

const cleanSearch = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_CLEAN_SEARCH });
  };
};

const athleteData = (athlete) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_DATA_FETCHING });
    try {
      const result = await AthletesService.getAthleteData(
        athlete.cognito_user_sub,
      );
      dispatch({
        type: ActionTypes.ATHLETES_DATA_FULLFILLED,
        payload: { result },
      });
      const { subscription, chats, plan, s3Plan } = result || {};

      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_COMMENTS_FULFILLED,
        payload: chats,
      });

      const selectAthleteDatta = {
        ...plan,
        subscription,
        currentTraining: s3Plan,
      };

      dispatch({
        type: ActionTypes.ATHLETES_SELECT_FULFILLED,
        payload: {
          result: selectAthleteDatta,
          cognito_user_sub: athlete.cognito_user_sub,
        },
      });

      const {
        cognito_user_sub,
        strava_athlete_id,
        stravaActivities,
        garmin_oauth_token,
        suunto_auth,
        currentTraining,
        coros_auth,
      } = selectAthleteDatta || {};

      const firstDay =
        currentTraining && currentTraining.days && currentTraining.days.length
          ? currentTraining.days.filter((d) => !!d)[0]
          : null;

      const startingDate = firstDay
        ? moment(firstDay[0].plannedDate, dateFormat)
        : moment().add(-60, "days");

      if (
        (strava_athlete_id ||
          garmin_oauth_token ||
          suunto_auth ||
          coros_auth) &&
        !stravaActivities &&
        startingDate.isBefore(moment())
      ) {
        dispatch(
          getAthleteActivities({
            start_date:
              garmin_oauth_token || suunto_auth
                ? startingDate.unix()
                : startingDate.format(dateFormat),
            end_date:
              garmin_oauth_token || suunto_auth
                ? moment().unix()
                : moment().format(dateFormat),
            strava_athlete_id: strava_athlete_id,
            oauth_token: garmin_oauth_token,
            ...suunto_auth,
            cognito_user_sub,
            coros_auth,
          }),
        );
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_DATA_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};
const selectAthlete = (athlete) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_SELECT_PENDING });
    try {
      const result = await AthletesService.getCurrentTraining(athlete);
      dispatch({
        type: ActionTypes.ATHLETES_SELECT_FULFILLED,
        payload: { result, cognito_user_sub: athlete.cognito_user_sub },
      });

      const {
        cognito_user_sub,
        strava_athlete_id,
        stravaActivities,
        garmin_oauth_token,
        suunto_auth,
        currentTraining,
        coros_auth,
      } = result || {};

      const firstDay =
        currentTraining && currentTraining.days && currentTraining.days.length
          ? currentTraining.days.filter((d) => !!d)[0]
          : null;

      const startingDate = firstDay
        ? moment(firstDay[0].plannedDate, dateFormat)
        : moment().add(-60, "days");

      if (
        (strava_athlete_id ||
          garmin_oauth_token ||
          suunto_auth ||
          coros_auth) &&
        !stravaActivities &&
        startingDate.isBefore(moment())
      ) {
        dispatch(
          getAthleteActivities({
            start_date:
              garmin_oauth_token || suunto_auth
                ? startingDate.unix()
                : startingDate.format(dateFormat),
            end_date:
              garmin_oauth_token || suunto_auth
                ? moment().unix()
                : moment().format(dateFormat),
            strava_athlete_id: strava_athlete_id,
            oauth_token: garmin_oauth_token,
            ...suunto_auth,
            cognito_user_sub,
            coros_auth,
          }),
        );
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_SELECT_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const selectAthleteWorkout = (workout) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_SELECT_WORKOUT, payload: workout });
  };
};

const getCoaches = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_LOAD_COACHES,
      payload: AthletesService.getCoaches(),
    });
  };
};

const getStrengthCoaches = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_LOAD_STRENGTH_COACHES,
      payload: await AthletesService.getStrengthCoaches(),
    });
  };
};

const assignCoachToAthlete = (assignParams) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_ASSIGN_COACH,
      payload: AthletesService.assignCoachToAthlete(assignParams),
    });
  };
};

const assignStrengthCoachToAthlete = (assignParams) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_ASSIGN_STRENGTH_COACH_PENDING,
    });
    try {
      dispatch({
        type: ActionTypes.ATHLETES_ASSIGN_STRENGTH_COACH_FULLFILED,
        payload: await AthletesService.assignStrengthCoachToAthlete(
          assignParams,
        ),
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.ATHLETES_ASSIGN_STRENGTH_COACH_REJECTED,
      });
    }
  };
};

const getOverallWorkoutsCount = (cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_GET_OVERALL_WORKOUT_COUNT_PENDING });
    try {
      const result = await AthletesService.getOverallWorkoutsCount(
        cognito_user_sub,
      );
      dispatch({
        type: ActionTypes.ATHLETES_GET_OVERALL_WORKOUT_COUNT_FULFILLED,
        payload: { statistic: result, cognito_user_sub: cognito_user_sub },
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_GET_OVERALL_WORKOUT_COUNT_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const assignTrainingToAthlete = (assignParams) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_ASSIGN_TRAINING_PENDING });
    try {
      const result = await AthletesService.assignTrainingToAthlete(
        assignParams,
      );
      dispatch({
        type: ActionTypes.ATHLETES_ASSIGN_TRAINING_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_ASSIGN_TRAINING_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const chainTrainingToAthleteAfterCurrent = (assignParams) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_CHAIN_TRAINING_PENDING });
    try {
      const result = await AthletesService.chainTrainingToAthleteAfterCurrent(
        assignParams,
      );
      dispatch({
        type: ActionTypes.ATHLETES_CHAIN_TRAINING_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_CHAIN_TRAINING_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const athleteTrainingChangeWeek = (weekNumber) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_WEEK_CHANGED,
      payload: weekNumber,
    });
  };
};

const athleteTrainingSelectWorkout = (workout) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_SELECT_WORKOUT,
      payload: workout,
    });
  };
};

const athleteTrainingPatch = (
  selectedAthlete,
  notificationChangesList,
  reloadOverallWorkoutCount = true,
) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_TRAINING_SAVE_PENDING });
    try {
      const result = await AthletesService.athleteTrainingPatch(
        selectedAthlete,
        notificationChangesList,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_SAVE_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_SAVE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const athleteTrainingSwapWorkouts = (workoutsToMoveData, planId = 0) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_SWAP_PENDING,
    });
    try {
      const result = await AthletesService.swapWorkouts(
        workoutsToMoveData,
        planId,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_SWAP_FULFILLED,
        payload: result,
      });
      if (
        workoutsToMoveData.selectedAthlete &&
        workoutsToMoveData.selectedAthlete.chainedPlans &&
        workoutsToMoveData.selectedAthlete &&
        workoutsToMoveData.selectedAthlete.cognito_user_sub &&
        planId !== 0
      ) {
        workoutsToMoveData.selectedAthlete.chainedPlans.forEach((c) => {
          dispatch(
            getAthleteChainedPlan(
              workoutsToMoveData.selectedAthlete.cognito_user_sub,
              c,
            ),
          );
        });
      }
    } catch (exp) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_SWAP_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const setErrorMessage = (err) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_SET_ERROR_MESSAGE, payload: err });
  };
};

const clearErrorMessage = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_CLEAR_ERROR_MESSAGE });
  };
};

const setSuccessMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_SET_SUCCESS_MESSAGE,
      payload: message,
    });
  };
};

const clearSuccessMessage = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_CLEAR_SUCCESS_MESSAGE });
  };
};

const patchSelectedAthleteWorkoutViewed = (cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_VIEWED_SAVE_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingViewedPatch(
        cognito_user_sub,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_VIEWED_SAVE_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_VIEWED_SAVE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const patchSelectedAthleteRaces = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_RACES_SAVE_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingPatchRaces(payload);
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_RACES_SAVE_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_RACES_SAVE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getAthleteChainedPlan = (cognito_user_sub, planId) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_PLAN_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingPlan(
        cognito_user_sub,
        planId,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_PLAN_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_PLAN_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getAthleteComments = (cognito_user_sub, lastDate, init = true) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_COMMENTS_PENDING,
      payload: { init },
    });
    try {
      const result = await AthletesService.athleteTrainingComments(
        cognito_user_sub,
        lastDate,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_COMMENTS_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_COMMENTS_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getAthleteOldComments = (lastDate, cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_GET_OLD_COMMENTS_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingComments(
        cognito_user_sub,
        lastDate,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_GET_OLD_COMMENTS_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_GET_OLD_COMMENTS_REJECTED,
        payload: exp,
      });
    }
  };
};

const patchSelectedAthleteWorkoutComment = (selectedAthlete, coachComment) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_SAVE_PENDING,
      payload: { init: false },
    });
    try {
      const result = await AthletesService.athleteTrainingPatchComment(
        selectedAthlete,
        coachComment,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_SAVE_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_SAVE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const sendBulkMessage = ({ message, recipients }) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_BULK_MESSAGE_SAVE_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingSendBulkMessage({
        message,
        recipients,
      });
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_BULK_MESSAGE_SAVE_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_BULK_MESSAGE_SAVE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const patchSelectedAthleteWorkoutDeleteComment = (
  selectedAthlete,
  coachComment,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_DELETE_PENDING,
      payload: { init: false },
    });
    try {
      const result = await AthletesService.athleteTrainingPatchDeleteComment(
        selectedAthlete,
        coachComment,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_DELETE_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_DELETE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const patchSelectedAthleteWorkoutEditComment = (
  selectedAthlete,
  coachComment,
  refresh = false,
  isCoach = false,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_EDIT_PENDING,
      payload: { init: false },
    });
    try {
      const result = await AthletesService.athleteTrainingPatchEditComment(
        selectedAthlete,
        coachComment,
      );

      const { cognito_user_sub } = selectedAthlete;

      if (isCoach)
        dispatch(patchSelectedAthleteWorkoutCommentsRead(selectedAthlete));
      if (refresh)
        dispatch(
          currentTrainingGetComments(new Date().getTime(), cognito_user_sub),
        );

      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_EDIT_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENT_EDIT_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const patchSelectedAthleteWorkoutCommentsRead = (selectedAthlete) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENTS_READ_SAVE_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingPatchCommentsRead(
        selectedAthlete,
      );
      dispatch({
        type:
          ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENTS_READ_SAVE_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_COMMENTS_READ_SAVE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const athleteTrainingEditWorkout = (selectedAthlete, data, planId = 0) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_WORKOUT_EDIT_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingPatchEditWorkout(
        selectedAthlete,
        data,
        planId,
      );

      if (
        selectedAthlete &&
        selectedAthlete.chainedPlans &&
        selectedAthlete &&
        selectedAthlete.cognito_user_sub &&
        planId !== 0
      ) {
        selectedAthlete.chainedPlans.forEach((c) => {
          dispatch(
            getAthleteChainedPlan(
              selectedAthlete.cognito_user_sub,
              c.subcategory,
            ),
          );
        });
      }
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_EDIT_FULFILLED,
        payload: JSON.parse(JSON.stringify(result)),
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_EDIT_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const athleteTrainingAddWorkout = (
  selectedAthlete,
  plannedDate,
  workout,
  planId = 0,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_WORKOUT_ADD_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingPatchAddWorkout(
        selectedAthlete,
        plannedDate,
        workout,
        planId,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_ADD_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_ADD_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const athleteTrainingRemoveWorkout = (
  selectedAthlete,
  plannedDate,
  workoutId,
  planId = 0,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_WORKOUT_DELETE_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingPatchDeleteWorkout(
        selectedAthlete,
        plannedDate,
        workoutId,
        planId,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_DELETE_FULFILLED,
        payload: result,
      });
      if (
        selectedAthlete &&
        selectedAthlete.chainedPlans &&
        selectedAthlete &&
        selectedAthlete.cognito_user_sub &&
        planId !== 0
      ) {
        selectedAthlete.chainedPlans.forEach((c) => {
          dispatch(
            getAthleteChainedPlan(
              selectedAthlete.cognito_user_sub,
              c.subcategory,
            ),
          );
        });
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WORKOUT_DELETE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const athleteTrainingAddWeek = (
  selectedAthlete,
  fromPlannedDate,
  planId = 0,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_WEEK_ADD_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingPatchAddWeek(
        selectedAthlete,
        fromPlannedDate,
        planId,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WEEK_ADD_FULFILLED,
        payload: result,
      });
      if (
        result &&
        result.chainedPlans &&
        selectedAthlete &&
        selectedAthlete.cognito_user_sub
      ) {
        result.chainedPlans.forEach((c) => {
          dispatch(
            getAthleteChainedPlan(
              selectedAthlete.cognito_user_sub,
              c.subcategory,
            ),
          );
        });
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WEEK_ADD_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const athleteTrainingAddEasyWeek = (
  selectedAthlete,
  fromPlannedDate,
  raceCount,
  trainPerWeek,
  planId = 0,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_WEEK_EASY_ADD_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingPatchAddEasyWeek(
        selectedAthlete,
        fromPlannedDate,
        raceCount,
        trainPerWeek,
        planId,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WEEK_EASY_ADD_FULFILLED,
        payload: result,
      });
      if (
        result &&
        result.chainedPlans &&
        selectedAthlete &&
        selectedAthlete.cognito_user_sub
      ) {
        result.chainedPlans.forEach((c) => {
          dispatch(
            getAthleteChainedPlan(
              selectedAthlete.cognito_user_sub,
              c.subcategory,
            ),
          );
        });
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WEEK_EASY_ADD_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const athleteTrainingAddBlock = (
  selectedAthlete,
  fromPlannedDate,
  plan,
  planWeek,
  planId = 0,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_BLOCK_ADD_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingPatchAddBlock(
        selectedAthlete,
        fromPlannedDate,
        plan,
        planWeek,
        planId,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_BLOCK_ADD_FULFILLED,
        payload: result,
      });
      if (
        result &&
        result.chainedPlans &&
        selectedAthlete &&
        selectedAthlete.cognito_user_sub
      ) {
        result.chainedPlans.forEach((c) => {
          dispatch(
            getAthleteChainedPlan(
              selectedAthlete.cognito_user_sub,
              c.subcategory,
            ),
          );
        });
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_BLOCK_ADD_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const athleteTrainingRemoveWeek = (
  selectedAthlete,
  fromPlannedDate,
  planId = 0,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_WEEK_REMOVE_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingPatchRemoveWeek(
        selectedAthlete,
        fromPlannedDate,
        planId,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WEEK_REMOVE_FULFILLED,
        payload: result,
      });
      if (
        result &&
        result.chainedPlans &&
        selectedAthlete &&
        selectedAthlete.cognito_user_sub
      ) {
        result.chainedPlans.forEach((c) => {
          dispatch(
            getAthleteChainedPlan(
              selectedAthlete.cognito_user_sub,
              c.subcategory,
            ),
          );
        });
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_WEEK_REMOVE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const athleteTrainingRemoveChainedPlan = (selectedAthlete, planIndex = 0) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_CHAINED_REMOVE_PENDING,
    });
    try {
      const result = await AthletesService.athleteTrainingPatchRemoveChainedPlan(
        selectedAthlete,
        planIndex,
      );
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_CHAINED_REMOVE_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_TRAINING_CHAINED_REMOVE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const patchSelectedAthleteWorkoutFeedback = (coachFeedback) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_TRAINING_WORKOUT_FEEDBACK_SAVE,
      payload: coachFeedback,
    });
  };
};

const getAthleteActivities = (param) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_LOAD_ACTIVITIES_PENDING });
    try {
      const result = await AthletesService.getAthleteActivities(param);
      dispatch({
        type: ActionTypes.ATHLETES_LOAD_ACTIVITIES_FULFILLED,
        payload: {
          result,
          strava_athlete_id: param.strava_athlete_id,
          garmin_oauth_token: param.oauth_token,
          access_token: param.access_token,
        },
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.ATHLETES_LOAD_ACTIVITIES_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getAthleteImageUrl = ({ image, cognito_user_sub }) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_LOAD_IMAGE_URL_PENDING });
    try {
      const result = await Storage.get(image, {
        level: "public",
        cacheControl: "no-cache, no-store, must-revalidate",
        expires: 0,
      });
      dispatch({
        type: ActionTypes.ATHLETES_LOAD_IMAGE_URL_FULFILLED,
        payload: { imageUrl: result, cognito_user_sub: cognito_user_sub },
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.ATHLETES_LOAD_IMAGE_URL_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

const getRecentData = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_GET_RECENT_DATA_PENDING });
    try {
      let today = new Date();
      const from = new Date(today.getFullYear(), today.getMonth(), 1);
      from.setMonth(from.getMonth() - 2);
      const result = await AthletesService.getData(
        from.getTime(),
        new Date().getTime(),
      );
      dispatch({
        type: ActionTypes.ATHLETES_GET_RECENT_DATA_FULFILLED,
        payload: { data: result },
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_GET_RECENT_DATA_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getData = (from, to) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_GET_DATA_PENDING });
    try {
      const result = await AthletesService.getData(
        from.getTime(),
        to.getTime(),
      );
      dispatch({
        type: ActionTypes.ATHLETES_GET_DATA_FULFILLED,
        payload: { data: result },
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_GET_DATA_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getEvents = (from, to) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_GET_EVENTS_PENDING });
    try {
      const result = await UserPlansService.getEvents(
        from.getTime(),
        to.getTime(),
      );
      dispatch({
        type: ActionTypes.ATHLETES_GET_EVENTS_FULFILLED,
        payload: { data: result },
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_GET_EVENTS_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const changeCheckedStatus = (cognito_user_sub, status) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_CHANGE_TRAINING_CHECK_PENDING });
    try {
      const result = await AthletesService.changeCheckStatus(
        cognito_user_sub,
        status,
      );
      dispatch({
        type: ActionTypes.ATHLETES_CHANGE_TRAINING_CHECK_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_CHANGE_TRAINING_CHECK_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const changeNeedsToCheckStatus = (cognito_user_sub, status) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_CHANGE_TRAINING_NEEDSTOCHECK_PENDING,
    });
    try {
      const result = await AthletesService.changeNeedToCheckStatus(
        cognito_user_sub,
        status,
      );
      dispatch({
        type: ActionTypes.ATHLETES_CHANGE_TRAINING_NEEDSTOCHECK_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_CHANGE_TRAINING_NEEDSTOCHECK_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const changeNeedsHelpStatus = (cognito_user_sub, status) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_CHANGE_TRAINING_NEEDSHELP_PENDING,
    });
    try {
      const result = await AthletesService.changeNeedsHelpStatus(
        cognito_user_sub,
        status,
      );
      dispatch({
        type: ActionTypes.ATHLETES_CHANGE_TRAINING_NEEDSHELP_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_CHANGE_TRAINING_NEEDSHELP_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const clearAthlete = (param) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_CLEAR_ATHLETE,
      payload: param,
    });
  };
};

const updateLastViewed = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_UPDATE_LAST_VIEWED,
      payload,
    });
  };
};

const getSignUpMethod = (user_email) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_GET_SIGNUP_PENDING });
    try {
      const result = await AthletesService.getSignUpMethod(user_email);
      dispatch({
        type: ActionTypes.ATHLETES_GET_SIGNUP_FULLFILLED,
        payload: { data: result },
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_GET_SIGNUP_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const clearSignIn = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_GET_SIGNUP_FULLFILLED,
      payload: null,
    });
  };
};

const updateAthleteDataLocally = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETES_UPDATE_LOCALLY,
      payload,
    });
  };
};

const updateAthletePlanLocally = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ATHLETE_UPDATE_TRAINING,
      payload,
    });
  };
};

const saveAthletePlan = (currentTraining, cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.ATHLETES_SAVE_PLAN_PENDING });
    try {
      const result = await AthletesService.saveCurrentTrainingS3(
        currentTraining,
        cognito_user_sub,
      );
      dispatch({
        type: ActionTypes.ATHLETES_SAVE_PLAN_FULLFILED,
        payload: { data: result },
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.ATHLETES_SAVE_PLAN_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

export {
  changeSearchPhrase,
  changeSearchFilter,
  athletesSearch,
  getCoachAthletesCount,
  selectAthleteWorkout,
  cleanSearch,
  selectAthlete,
  getCoaches,
  assignCoachToAthlete,
  getOverallWorkoutsCount,
  assignTrainingToAthlete,
  chainTrainingToAthleteAfterCurrent,
  athleteTrainingChangeWeek,
  athleteTrainingSelectWorkout,
  athleteTrainingEditWorkout,
  athleteTrainingAddWorkout,
  athleteTrainingRemoveWorkout,
  athleteTrainingPatch,
  athleteTrainingSwapWorkouts,
  athleteTrainingAddWeek,
  athleteTrainingAddEasyWeek,
  athleteTrainingRemoveWeek,
  athleteTrainingAddBlock,
  athleteTrainingRemoveChainedPlan,
  setErrorMessage,
  clearErrorMessage,
  setSuccessMessage,
  clearSuccessMessage,
  patchSelectedAthleteWorkoutViewed,
  patchSelectedAthleteWorkoutComment,
  patchSelectedAthleteWorkoutCommentsRead,
  patchSelectedAthleteWorkoutFeedback,
  patchSelectedAthleteWorkoutDeleteComment,
  patchSelectedAthleteWorkoutEditComment,
  getAthleteActivities,
  getAthleteImageUrl,
  getAthleteComments,
  getAthleteOldComments,
  changeSort,
  getAthleteChainedPlan,
  getRecentData,
  getData,
  getEvents,
  sendBulkMessage,
  changeCheckedStatus,
  changeNeedsToCheckStatus,
  clearAthlete,
  updateLastViewed,
  patchSelectedAthleteRaces,
  changeNeedsHelpStatus,
  getSignUpMethod,
  clearSignIn,
  updateAthleteDataLocally,
  getStrengthCoaches,
  assignStrengthCoachToAthlete,
  updateAthletePlanLocally,
  saveAthletePlan,
  athleteData,
  addVisitedPage,
};
