import React, { memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import PlanFieldTransition from "./plan-field-transition";
import withWidth from "@material-ui/core/withWidth";
import { emitter } from "@marvelapp/react-ab-test";

const styles = (theme) => ({
  buttonText: {
    background: "rgba(255, 255, 255, 0.3)",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 1.7,
    paddingLeft: theme.spacing.unit * 1.7,
    width: "100%",
    textTransform: "initial",
    border: 0,
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "initial",
  },

  buttonTextNormal: {
    background: "rgba(255, 255, 255, 0.3)",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 1.7,
    paddingLeft: theme.spacing.unit * 1.7,
    width: "100%",
    textTransform: "initial",
    border: 0,
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: "initial",

    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },

  buttonContainer: {
    margin: "16px 16px",
    boxShadow: "0px 10px 60px rgba(0, 0, 0, 0.1)",
    borderRadius: 12,

    [theme.breakpoints.up("md")]: {
      margin: "16px auto",
      maxWidth: 570,
      padding: "6px 0",
    },
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.dark,
    textAlign: "left",
  },
  button: {
    textAlign: "left",
    border: 0,
  },
  subtext: {
    fontSize: 12,
    fontWeight: "normal",
    color: "#9E9E9E",
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 14,
    background: "rgba(63, 81, 181, 0.12)",
    padding: "2px 5px",
  },
});

const PlanFieldButtonSelect = ({
  onSelect,
  id,
  classes,
  xs,
  sm,
  md,
  lg,
  xl,
  items,
  value,
  t,
  width,
}) => {
  const isInMobileViewSm = width === "sm" || width === "xs";

  const handleButtonClick = (value) => {
    if (isInMobileViewSm) {
      const mobileVariant = emitter.getActiveVariant("landing-mobile-option");
      if (mobileVariant && mobileVariant == "B") {
        const eventName = transformName(value);
      }
    }

    if (!isInMobileViewSm) {
      const webVariant = emitter.getActiveVariant("landing-option");
      if (webVariant && webVariant == "B") {
        const eventName = transformName(value);
      }
    }

    if (onSelect) onSelect({ id, value });
  };

  const transformName = (value) => {
    if (value == 2) return "Existing_Trailrunner_pre_Sign_up_B";
    if (value == 3) return "Become_Trailrunner_pre_Sign_up_B";
    if (value == 1) return "Become_Ultrarunner_pre_Sign_up_B";
    return "";
  };

  return (
    <PlanFieldTransition>
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        {items.map((item, index) => {
          const {
            text,
            value: itemValue,
            icon,
            subtext,
            subtitle,
            type,
          } = item;
          return (
            <div key={index} className={classes.buttonContainer}>
              <Button
                onClick={() => handleButtonClick(itemValue)}
                className={
                  value === itemValue ? classes.selectedButton : classes.button
                }
                variant="outlined"
                fullWidth
              >
                {icon ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/${icon}`}
                    alt=""
                    className={classes.imgIcon}
                    style={{
                      width: 42,
                    }}
                  />
                ) : (
                  ""
                )}
                <div
                  className={
                    type === "title"
                      ? classes.buttonText
                      : classes.buttonTextNormal
                  }
                >
                  {t(text)}
                  {subtitle ? (
                    <>
                      <br></br>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: subtitle,
                        }}
                        className={classes.subtitle}
                      ></span>
                    </>
                  ) : (
                    ""
                  )}
                  {subtext ? (
                    <>
                      <br></br>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: subtext,
                        }}
                        className={classes.subtext}
                      ></span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Button>
            </div>
          );
        })}
      </Grid>
    </PlanFieldTransition>
  );
};

PlanFieldButtonSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  items: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation("constants")(
  withWidth()(
    withStyles(styles, { withTheme: true })(memo(PlanFieldButtonSelect)),
  ),
);
