import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Routes } from "../../lib/routing-middleware";
import history from "../../lib/history-helper";
import { setPlanToSelect } from "../../actions/user-plans-action";
import { getLatestSubscription } from "../../actions/subscriptions-action";
import StarIcon from "@material-ui/icons/Star";
import { transform } from "babel-core";

const styles = (theme) => ({
  container: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    // paddingTop: theme.spacing.unit * 8,
    // paddingBottom: theme.spacing.unit * 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      // paddingBottom: theme.spacing.unit * 2,
    },
  },
  textContainer: {
    flex: 0.5,
    display: "flex",
    justifyContent: "center",
    // width: '50%',
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 36,
    },
  },
  verticalMiddleAligned: {
    padding: "0  24px",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    alignItems: "center",
    gap: 12,
  },
  picContainer: {
    flex: 0.5,
    display: "flex",
    position: "relative",
    // display: 'flex',
    // justifyContent: 'center',
    // width: '50%',
    // [theme.breakpoints.down('sm')]: {
    //     width: '100%'
    // }
  },
  chatPic: {
    margin: 0,
    position: "absolute",
    top: "50%",
    left: 0,
    transform: "translate(32px, -50%)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 175,
      transform: "translate(25px, -50%)",
    },
  },
  pic: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  button: {
    textTransform: "none",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  item: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",
    marginBottom: 0,
  },
  description2: {
    marginBottom: theme.spacing.unit * 10,
    paddingRight: theme.spacing.unit * 10,
    paddingLeft: theme.spacing.unit * 10,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing.unit * 5,
      paddingRight: theme.spacing.unit * 3,
      paddingLeft: theme.spacing.unit * 3,
    },
  },
  description1: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 10,
    paddingLeft: theme.spacing.unit * 10,
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing.unit * 3,
      paddingLeft: theme.spacing.unit * 3,
    },
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: "0.05em",
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "0.05em",
  },
  startIcon: {
    justifyContent: "start",
    minWidth: 35,
  },
  emoji: {
    fontSize: 16,
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "16px 0",
  },
  infoContent: {
    padding: "0 4px",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "0.05em",
    textAlign: "center",
    color: "#E02758",
    width: "100%",
    textTransform: "uppercase",
    margin: 0,
    textWrap: "nowrap",
  },
  border: {
    borderBottom: "2px solid #E02758",
    width: "100%",
  },
  containerDetails: {
    marginTop: "5vh",
  },
  containerButton: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  listItemGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

class SubscriptionFinalized extends Component {
  handleGoTo = async () => {
    const { userPlans, auth } = this.props;
    const { planToSelect } = userPlans;
    const { plan, id, redirect } = planToSelect || {};

    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub, email } = attributes;
    await this.props.dispatch(
      setPlanToSelect({
        plan,
        id,
        cognito_user_sub: sub,
        cognito_user_email: email,
        redirect,
      }),
    );
    setTimeout(() => {
      if (redirect === null && plan && plan === "explore") {
        history.push(Routes.DashboardPage);
        return;
      }

      if (plan && id && plan !== "free") {
        history.push(`/chooseplan/${plan}/${id}`);
      } else if (plan && plan !== "free") {
        history.push(`/chooseplan/${plan}`);
      } else if (redirect === "coach-choose") {
        history.push(`/chooseplan/coach-choose`);
      } else {
        history.push(Routes.DashboardPage);
      }
    }, 100);
  };

  componentWillUnmount() {
    this.props.dispatch(getLatestSubscription());
  }

  render() {
    const { classes, t } = this.props;
    return (
      <Box className={classes.container}>
        <Box className={classes.textContainer}>
          <Box className={classes.verticalMiddleAligned}>
            <Box className={classes.containerDetails}>
              <Typography variant="h4" gutterBottom className={classes.title}>
                {t("subscription finalize header desktop")}
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.subtitle}
              >
                {t("subscription finalize description 1")}
              </Typography>

              <Box className={classes.infoContainer}>
                <Box className={classes.border} />
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.infoContent}
                >
                  <span style={{ fontSize: 18 }}>🚨</span>
                  {t("subscription finalize alert")}
                </Typography>
                <Box className={classes.border} />
              </Box>

              <List>
                <ListItem classes={{ gutters: classes.listItemGutters }}>
                  <ListItemIcon className={classes.startIcon}>
                    <span className={classes.emoji}>🙋‍♂️</span>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={classes.item}
                      dangerouslySetInnerHTML={{
                        __html: t("subscription finalize item 1"),
                      }}
                    ></Typography>
                  </ListItemText>
                </ListItem>
                <ListItem classes={{ gutters: classes.listItemGutters }}>
                  <ListItemIcon className={classes.startIcon}>
                    <span className={classes.emoji}>🏁</span>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={classes.item}
                      dangerouslySetInnerHTML={{
                        __html: t("subscription finalize item 2"),
                      }}
                    ></Typography>
                  </ListItemText>
                </ListItem>
                <ListItem classes={{ gutters: classes.listItemGutters }}>
                  <ListItemIcon className={classes.startIcon}>
                    <span className={classes.emoji}>📅</span>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={classes.item}
                      dangerouslySetInnerHTML={{
                        __html: t("subscription finalize item 3"),
                      }}
                    ></Typography>
                  </ListItemText>
                </ListItem>
                <ListItem classes={{ gutters: classes.listItemGutters }}>
                  <ListItemIcon className={classes.startIcon}>
                    <span className={classes.emoji}>📢</span>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={classes.item}
                      dangerouslySetInnerHTML={{
                        __html: t("subscription finalize item 4"),
                      }}
                    ></Typography>
                  </ListItemText>
                </ListItem>
                <ListItem classes={{ gutters: classes.listItemGutters }}>
                  <ListItemIcon className={classes.startIcon}>
                    <span className={classes.emoji}>💬</span>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={classes.item}
                      dangerouslySetInnerHTML={{
                        __html: t("subscription finalize item 5"),
                      }}
                    ></Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Box>
            <Box className={classes.containerButton}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleGoTo}
              >
                {t("Continue")}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={classes.picContainer}>
          <Box className={classes.chatPic}>
            <img
              src={`${process.env.PUBLIC_URL}/welcome-chat.webp`}
              alt=""
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Box>
          <img
            src={`${process.env.PUBLIC_URL}/welcome-vert.webp`}
            alt=""
            className={classes.pic}
          />
        </Box>
      </Box>
    );
  }
}

SubscriptionFinalized.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
    auth: store.auth,
  };
})(
  withTranslation("subscription")(
    withStyles(styles, { withTheme: true })(SubscriptionFinalized),
  ),
);
