import dayjs from "dayjs";

export const buildTableData = (rows, tableLabels) => {
  const result = rows.reduce((acc, current) => {
    const { status, ...others } = current;
    const data = tableLabels.map((item) => {
      const match = others[item];
      if (!isNaN(match)) return match;
      return 0;
    });

    current = {
      label: status,
      fill: false,
      borderWidth: 0,
      data,
      borderColor: current.color,
      hidden: current.hidden,
    };

    return [...acc, current];
  }, []);

  return result;
};

export const groupByDate = (data, currentRange) => {
  const byDate = data.reduce((grouped, item) => {
    const date = dayjs(item.current_date);
    const dayDifference =
      currentRange.name === "Yesterday" || currentRange.name === "Today";
    const weekStart = !dayDifference
      ? date.startOf("week").format("YYYY-MM-DD")
      : date.format("YYYY-MM-DD");

    if (!grouped[weekStart]) grouped[weekStart] = [];

    grouped[weekStart].push(item);
    return grouped;
  }, {});
  return byDate;
};

export const removeDuplicatesByKeys = (arr, keys) => {
  const seen = new Set();
  return arr.filter((obj) => {
    const keyValues = keys.map((key) => obj[key]).join("|");
    if (!seen.has(keyValues)) {
      seen.add(keyValues);
      return true;
    }
    return false;
  });
};

const handleDaysDifference = (localStartDate, endDate, setHeaders) => {
  const daysBetween = [];
  const daysDifference = endDate.diff(localStartDate, "days");

  if (daysDifference === 1) {
    const yesterday = localStartDate.format("YYYY-MM-DD");
    setHeaders(["", yesterday]);
    return;
  }

  for (let i = 0; i <= daysDifference; i++) {
    const currentDay = localStartDate.add(i, "days");
    daysBetween.push(currentDay.format("YYYY-MM-DD"));
  }

  setHeaders(["", ...daysBetween]);
  return;
};

export const getAllWeeks = (startDate, setHeaders) => {
  const localStartDate = dayjs(startDate);
  const endDate = dayjs();
  const weeksBetween = [];
  const weeksDiff = endDate.diff(localStartDate, "week");

  if (weeksDiff === 0)
    return handleDaysDifference(localStartDate, endDate, setHeaders);

  for (let i = 0; i <= weeksDiff; i++) {
    const currentWeekStart = localStartDate.add(i, "week").startOf("week");
    weeksBetween.push(currentWeekStart.format("YYYY-MM-DD"));
  }

  setHeaders(["", ...weeksBetween]);
};

export const handleDifference = (quantity, unit) => {
  const date = dayjs()
    .subtract(quantity, unit)
    .format("YYYY-MM-DD");
  return date;
};
