import React, { useState } from "react";
import {
  Box,
  Link,
  Slide,
  Dialog,
  Typography,
  Button,
  FormControl,
  InputAdornment,
  FormHelperText,
  OutlinedInput,
} from "@material-ui/core";
import Divider from "@mui/material/Divider";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import history from "../../../lib/history-helper";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { AppleIcon } from "../../../components/icons";
import { useAuthContext } from "../../../context/AuthProvider";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { IconMail } from "@tabler/icons-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const OnboardingLogin = () => {
  const [openDialog, setOpenDialog] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { email, setEmail, socialSignIn } = useAuthContext();

  const { t } = useTranslation("onboarding");
  const classes = useStyles();

  const handleCloseDialog = () => {
    setErrorMessage("");
    setOpenDialog(false);
  };

  const handleTransitionEnd = () => {
    history.goBack();
  };

  const handleSignUp = () => {
    setErrorMessage("");
    history.push("/auth/sign-up");
  };
  const handleLogIn = () => {
    if (email === "") {
      setErrorMessage(t("onboarding.error.enterEmailError"));
      return;
    }
    setErrorMessage("");
    history.push("/auth/log-in/confirm");
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionProps={{ onExited: handleTransitionEnd }}
      TransitionComponent={Transition}
      display={"flex"}
      flexDirection="column"
    >
      <ToolbarReturnBack
        withLogo={true}
        logoWidth={107}
        style={{
          backgroundColor: "#FAFAFA",
          alignItems: "flex-start",
          padding: "10px",
        }}
        goBack={handleCloseDialog}
      />
      <Box className={classes.parentBox}>
        <Box className={classes.contentContainer}>
          <Box className={classes.titleBox}>
            <Typography className={classes.title}>
              {t("onboarding.login.title")}
            </Typography>
            <Typography className={classes.subtitle}>
              {t("onboarding.login.subtitle")}
            </Typography>
          </Box>
          <Box className={classes.formBox}>
            <FormControl
              fullWidth
              error={errorMessage}
              hiddenLabel
              size="small"
            >
              <OutlinedInput
                first
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={t("onboarding.login.emailPlaceholder")}
                color="secondary"
                aria-label="naked"
                className={classes.inputForm}
                startAdornment={
                  <InputAdornment
                    position="start"
                    data-testid="edit-icon"
                    id="edit-icon"
                  >
                    <IconMail color="#6D6F71" stoke={1} />
                  </InputAdornment>
                }
              />
              <FormHelperText className={classes.errorCardText}>
                {t(errorMessage)}
              </FormHelperText>
            </FormControl>
            <Button
              onClick={handleLogIn}
              variant="contained"
              className={classes.logInButton}
            >
              {t("onboarding.login.loginButton")}
            </Button>
          </Box>
          <Divider>
            <Typography className={classes.dividerText}>
              {t("onboarding.login.connector")}
            </Typography>
          </Divider>
          <Box className={classes.signUpBox}>
            <Typography className={classes.subtitle}>
              {t("onboarding.login.ifUsed")}{" "}
              <span style={{ fontWeight: 700 }}>
                {t("onboarding.login.ifUsedApple")}
              </span>
              {t("onboarding.login.ifUsedAppleDescription")}
            </Typography>
            <Button
              className={classes.button}
              size="large"
              variant="contained"
              startIcon={<AppleIcon />}
              onClick={() =>
                socialSignIn(CognitoHostedUIIdentityProvider.Apple)
              }
            >
              <Typography className={classes.buttonText}>
                {t("onboarding.login.continueWith")}{" "}
                <span style={{ fontWeight: 700 }}>
                  {t("onboarding.login.apple")}
                </span>
              </Typography>
            </Button>
          </Box>
        </Box>
        <Typography className={classes.signUpText}>
          {t("onboarding.login.new")}{" "}
          <Link
            onClick={handleSignUp}
            underline="none"
            className={classes.signUpButton}
          >
            {t("onboarding.login.signUp")}
          </Link>
        </Typography>
      </Box>
    </Dialog>
  );
};

export default OnboardingLogin;

const useStyles = makeStyles({
  parentBox: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    padding: "16px",
    gap: "12px",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  title: {
    fontFamily: "Oswald",
    fontWeight: 700,
    fontSize: "22px",
    color: "#12151B",
    textTransform: "uppercase",
    lineHeight: "27.5px",
  },

  subtitle: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#12151B",
    lineHeight: "24px",
  },
  contentContainer: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    marginTop: "24px",
  },
  button: {
    backgroundColor: "#12151B",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  buttonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#FEFFFF",
  },
  logInButton: {
    backgroundColor: "#0B2B5C",
    height: "47px",
    color: "#FFFFFF",
    borderRadius: "4px",
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  dividerText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 700,
  },
  signUpText: {
    alignSelf: "center",
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 400,
  },
  formBox: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  signUpBox: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  signUpButton: {
    color: "#F93C6E",
    fontWeight: 700,
    cursor: "pointer",
  },
  errorCardText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
});
