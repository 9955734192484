import React from "react";
import useActivities from "../hooks/useActivities";

function withActivities(Component) {
  return function WithActivities(props) {
    const { activities, loading } = useActivities();
    return (
      <Component
        {...props}
        activities={activities}
        loadingActivities={loading}
      />
    );
  };
}

export default withActivities;
