import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Fade, Fab } from "@material-ui/core";
import UndoIcon from "@material-ui/icons/Undo";

const styles = (theme) => ({
  buttonContainer: {
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto",
    paddingLeft: theme.spacing.unit * 2,
  },
  buttons: {
    marginRight: theme.spacing.unit,
  },
});

class AthleteTrainingEditorButtons extends Component {
  render() {
    const {
      classes,
      hasCurrentTraining,
      saving,
      onClearCurrentTraining,
    } = this.props;

    return (
      <Fade in={hasCurrentTraining}>
        <div className={classes.buttonContainer}>
          <Fab
            size="small"
            variant="extended"
            color="secondary"
            disabled={!hasCurrentTraining || saving}
            className={classes.buttons}
            onClick={onClearCurrentTraining}
          >
            <UndoIcon fontSize="small" />
            &nbsp;Close
          </Fab>
        </div>
      </Fade>
    );
  }
}

AthleteTrainingEditorButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  hasCurrentTraining: PropTypes.bool,
  saving: PropTypes.bool,
  onSaveTraining: PropTypes.func.isRequired,
  onClearCurrentTraining: PropTypes.func.isRequired,
};

export default withStyles(styles)(AthleteTrainingEditorButtons);
