import { useEffect, useState } from "react";
import useUserInfo from "./useUserInfo";
import { Capacitor } from "@capacitor/core";

const PLAY_STORE_SUBSCRIPTIONS =
  "https://play.google.com/store/account/subscriptions";
const IOS_SUBSCRIPTIONS = "https://apps.apple.com/account/subscriptions";
const CHARGEBEE_SUBSCRIPTIONS =
  "https://vert.chargebeeportal.com/portal/v2/login?forward=portal_main";

const useManageSubscription = () => {
  const { subscriptions } = useUserInfo();
  const [link, setLink] = useState(null);

  const setManageSubscriptionLink = () => {
    console.log("setManageSubscriptionLink", { subscriptions });
    if (subscriptions.latest == undefined) {
      setLink(null);
      return;
    }

    if (
      Capacitor.getPlatform() === "web" &&
      subscriptions.latest.channel &&
      subscriptions.latest.channel === "web"
    ) {
      setLink(CHARGEBEE_SUBSCRIPTIONS);
      return;
    }
    if (
      Capacitor.getPlatform() === "ios" &&
      subscriptions.latest.store &&
      subscriptions.latest.store === "APP_STORE"
    ) {
      setLink(IOS_SUBSCRIPTIONS);
      return;
    }
    if (
      Capacitor.getPlatform() === "android" &&
      subscriptions.latest.store &&
      subscriptions.latest.store === "PLAY_STORE"
    ) {
      setLink(PLAY_STORE_SUBSCRIPTIONS);
      return;
    }
  };

  useEffect(() => {
    if (subscriptions) setManageSubscriptionLink();
  }, [subscriptions]);

  return { link };
};

export default useManageSubscription;
