import React from "react";
import PropTypes from "prop-types";
import CustomButton from "./CustomButton";
import { WatchOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import ModalCustom from "./ModalCustom";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { palette } from "../../../theme/palette";
import useUserInfo from "../../../hooks/useUserInfo";
import { getCustomLevel } from "../../../lib/plan-helper";
import { getPlanLevel } from "../../../lib/plan-helper";
import { exportWorkoutToGarmin } from "../../../actions/user-plans-action";
import { useDispatch } from "react-redux";
import PaywallModal from "./PaywallModal";
import { logEvent } from "../../../lib/events-helper";

const ExportWorkoutButton = ({
  workout = {},
  parametrizedWorkout = [],
  small = false,
  disabled = false,
}) => {
  const { t } = useTranslation("training");
  const dispatch = useDispatch();
  const { currentPlan, currentTraining, isSubscribed } = useUserInfo();
  const classes = useStyles();
  const [exportOpen, setExportOpen] = React.useState(false);
  const [isOpenPaywall, setIsOpenPaywall] = React.useState(false);

  const handleCloseExport = () => {
    setExportOpen(false);
  };

  const handleOpenExport = () => {
    if (isSubscribed) {
      setExportOpen(true);
    } else {
      setIsOpenPaywall(true);
    }
  };

  const handleExportWorkout = (useRpe) => {
    logEvent("export_workout");
    const { chainedPlans } = currentTraining;

    if (isSubscribed) {
      const level = !isNaN(getCustomLevel(workout.plannedDate, chainedPlans))
        ? getCustomLevel(workout.plannedDate, chainedPlans)
        : getPlanLevel(currentTraining);

      dispatch(exportWorkoutToGarmin(currentPlan, workout, level, useRpe));
    } else {
      setIsOpenPaywall(true);
    }
  };

  if (!Object.keys(workout).length && !parametrizedWorkout.length) {
    return <></>;
  }

  return (
    <>
      <PaywallModal
        isOpen={isOpenPaywall}
        onClose={() => setIsOpenPaywall(false)}
      />
      {small ? (
        <CustomButton
          variant="secondary"
          style={{ width: "58px", height: "58px" }}
          typeStyle="icon"
          onClick={() => handleOpenExport()}
          disabled={disabled}
        >
          <WatchOutlined />
        </CustomButton>
      ) : (
        <CustomButton
          variant="contained"
          typeStyle="secondary"
          size="large"
          onClick={() => handleOpenExport()}
          fullWidth
          endIcon={<WatchOutlined />}
          disabled={disabled}
        >
          {t("training.exportWorkout.buttonText")}
        </CustomButton>
      )}
      <ModalCustom
        fullScreen={false}
        open={exportOpen}
        onClose={handleCloseExport}
        toolbarJustifyContent="end"
        headerStyles={{
          padding: "8px 5px !important",
        }}
        contentStyles={{
          padding: "8px 20px 10px",
          gap: 8,
        }}
      >
        <Grid container styles={{ gap: "6px 0" }}>
          <Grid item xs={12} style={{ padding: "10px 0" }}>
            <Typography className={classes.modalDescription}>
              {t("training.exportWorkout.modalTitle")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box px={0} py={1}>
              <CustomButton
                color={"secondary"}
                typeStyle={"primary"}
                variant="contained"
                size="small"
                onClick={() => {
                  handleExportWorkout(true);
                  handleCloseExport();
                }}
                fullWidth
              >
                {t("training.exportWorkout.modalOption.heartRateZones")}
              </CustomButton>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box py={1} px={0}>
              <CustomButton
                color={"secondary"}
                typeStyle={"primary"}
                variant="contained"
                size="small"
                onClick={() => {
                  handleExportWorkout(false);
                  handleCloseExport();
                }}
                fullWidth
              >
                {t("training.exportWorkout.modalOption.onlySplits")}
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </ModalCustom>
    </>
  );
};

const useStyles = makeStyles(() => ({
  modalDescription: {
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",
    color: palette.black,
  },
}));

ExportWorkoutButton.propTypes = {
  workout: PropTypes.object,
  parametrizedWorkout: PropTypes.array,
};

export default ExportWorkoutButton;
