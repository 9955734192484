import React, { useEffect } from "react";
import bugsnagClient from "../../lib/bugsnag-client";
import {
  Box,
  Button,
  LinearProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { updatePlan } from "../../actions/user-plans-action";
import {
  clearActivity,
  initAuthorization as initAuthorizationStrava,
} from "../../actions/strava-action";
import { stravaAuthorizationUrl } from "../../constants/watches-auth-uri";
import { useTranslation } from "react-i18next";
import StravaIcon from "../icons/StravaIcon";
import { palette } from "../../theme/palette";
import CustomButton from "./v2/CustomButton";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import history from "../../lib/history-helper";

const StravaConnect = ({ v3 = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation("settings");
  const { device } = useParams();
  const { subscriptions, userPlans, strava } = useSelector((state) => state);
  const { currentPlan } = userPlans;
  const { initializing } = strava;
  const { loadingLatest } = subscriptions;
  const isStravaConnected = currentPlan && currentPlan.strava_athlete_id;

  const loadQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = value;
    }
    return params;
  };

  const load = () => {
    try {
      const queryParams = loadQueryParams();

      if (isStravaConnected) return false;

      const deviceParam = device
        ? device === "strava"
        : location.pathname.includes("strava");

      if (deviceParam) {
        if (Object.keys(queryParams).length <= 0)
          throw new Error("No query params found");

        if (queryParams.type === "strava" && queryParams.error)
          throw new Error("Error connecting to strava: " + queryParams.error);

        const { code, state } = queryParams;
        if (state !== "init" && !code)
          throw new Error("Error finding code and state of query parameters");

        dispatch(initAuthorizationStrava({ code }));
      }
    } catch (error) {
      bugsnagClient.notify(error);
    }
  };

  const connect = () => {
    history.push("/dashboard");
    return window.open(stravaAuthorizationUrl, "_self");
  };

  const disconnect = () => {
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || {};
    dispatch(updatePlan({ cognito_user_sub, strava_athlete_id: null }));
    dispatch(clearActivity());
  };

  useEffect(() => {
    load();
  }, []);

  if (v3) {
    return (
      <>
        <Box className={classes.boxDevice}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <StravaIcon />
              <Typography variant="body1" className={classes.connectTitleV2}>
                {"Strava"}
              </Typography>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                gap: 5,
              }}
            >
              {(userPlans.loading && !initializing) || loadingLatest ? (
                <CustomButton
                  typeStyle="disabled"
                  color="default"
                  className={classes.buttonConnect}
                  variant="contained"
                  size="small"
                >
                  <CircularProgress size={14} />
                </CustomButton>
              ) : (
                <CustomButton
                  typeStyle={
                    isStravaConnected && !initializing ? "secondary" : "primary"
                  }
                  color="default"
                  variant="contained"
                  size="small"
                  className={classes.buttonConnect}
                  onClick={
                    isStravaConnected && !initializing ? disconnect : connect
                  }
                >
                  {isStravaConnected && !initializing
                    ? t("settings.watchDisconnect")
                    : t("settings.watchConnect")}
                </CustomButton>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <div className={classes.connectContainer}>
        <div className={classes.connectFlexContainer}>
          <div className={classes.connectTextContainer}>
            <Typography variant="body1" className={classes.connectTitle}>
              {t("Connect with Strava")}
            </Typography>
            {!userPlans.loading || !loadingLatest ? (
              <div className={classes.connectText}>
                <Typography variant="body1">
                  {t("Status")}:{" "}
                  {!initializing || !userPlans.loading ? (
                    isStravaConnected ? (
                      <span style={{ color: "green" }}>{t("Connected")}</span>
                    ) : (
                      t("Not connected")
                    )
                  ) : (
                    t("Connecting")
                  )}
                </Typography>
              </div>
            ) : null}
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/strava.png`}
            alt=""
            className={classes.logo}
          />
        </div>
        {(userPlans.loading && !initializing) || loadingLatest ? (
          <LinearProgress color="primary" variant="indeterminate" />
        ) : isStravaConnected && !initializing ? (
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonConnect}
            style={{ backgroundColor: "#c9927d" }}
            onClick={disconnect}
          >
            {t("Disconnect Strava")}
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonConnect}
            onClick={connect}
          >
            {t("Connect Strava")}
          </Button>
        )}
      </div>
    </>
  );
};

export default StravaConnect;

const useStyles = makeStyles({
  boxDevice: {
    display: "flex",
    flexDirection: "column",
    padding: "4px 24px",
  },
  sync: {
    //styleName: p2-r;
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
    color: palette.darkGray,
  },
  connectTitleV2: {
    //styleName: p1-b;
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 700,
    color: palette.black,
  },
  strava: {
    background: "#ed4834",
    color: "white",
  },

  text: {
    fontFamily: "Avenir",
    fontSize: 16,
    color: "#202020",
    margin: "10px 0",
  },

  buttonConnect: {
    margin: "10px auto",
    boxShadow: "none",
    borderRadius: 50,
    width: 105,
    height: 30,
    fontSize: 14,
    padding: "4px 24px",
  },

  connectContainer: {
    background: "#FFFFFF",
    borderRadius: 10,
    padding: 25,
    maxWidth: 300,
    marginBottom: 20,
    textAlign: "center",
  },

  connectTitle: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 20,
    color: "#202020",
    textAlign: "left",
  },

  connectText: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#202020",
    textAlign: "left",
  },

  connectFlexContainer: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
  },
});
