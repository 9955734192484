import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { grey } from "@material-ui/core/colors";
import RouterLink from "../common/router-link";
import moment from "moment";
import "moment/min/locales";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import { notificationPatch } from "../../actions/notifications-action";
import {
  Badge,
  Typography,
  Popover,
  Button,
  Box,
  Divider,
} from "@material-ui/core";
import { palette } from "../../theme/palette";

const styles = (theme) => ({
  container: {
    background: "#ffffff",
    borderRadius: 10,
    overflow: "hidden",
    minWidth: 250,
  },
  textContainer: {
    background: theme.palette.primary.gradient,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    textAlign: "center",
    marginBottom: theme.spacing.unit * 1.5,
  },
  notificationsTop: {
    display: "flex",
    position: "relative",
  },
  title: {
    color: grey[700],
    marginBottom: theme.spacing.unit * 2,
    fontSize: "0.8rem",
    textTransform: "uppercase",
    textAlign: "left",
  },
  clearContainer: {
    padding: 10,
    textAlign: "center",
  },
  clear: {
    borderRadius: 5,
    textTransform: "initial",
  },
  notificationsContainer: {
    overflow: "scroll",
    maxHeight: 370,
  },
  notificationContainer: {
    padding: 15,
    // display: "flex",
    alignItems: "center",
  },
  notificationContainerPlan: {
    padding: 15,
    borderBottom: "1px solid #C4C4C4",
    background: "rgba(255, 216, 197, 0.3)",
    display: "flex",
    alignItems: "center",
  },
  unread: {
    marginLeft: "auto",
  },
  notificationText: {
    fontFamily: "Montserrat",
    color: "#2E3A59",
    fontSize: 13,
    textAlign: "left",
  },
  notificationDate: {
    fontWeight: 700,
    fontSize: 13,
    color: "#FF6327",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "4px",
      color: palette.black,
    },
  },
  noNotificationsText: {
    fontFamily: "Montserrat",
    color: "red",
    fontSize: 13,
    zIndex: 999,
  },
  marginMobile: {
    cursor: "pointer",
  },
  marginMobileTH: {
    cursor: "pointer",
  },
  thMargin: {
    cursor: "pointer",
    marginRight: 23,
  },
  buttonText: {
    fontSize: 11,
    margin: "0",
  },
  alignCenter: {
    textAlign: "center",
  },
  paper: {
    borderRadius: 10,

    [theme.breakpoints.down("sm")]: {
      borderRadius: 4,
      boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
      width: "100%",
      marginTop: "30px",
    },
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },
  overlay: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    background: "rgba(0,0,0,0.6)",
    left: 0,
    top: 0,
    zIndex: 998,
  },
  textRow: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
});

class DashboardNotifications extends Component {
  state = {
    anchorEl: null,
  };
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNotificationClick = (notification) => {
    this.props.dispatch(notificationPatch({ ...notification, isRead: true }));
    this.handleClose();
  };

  handleNotificationsClear = (notificationList) => {
    notificationList.forEach((notification) => {
      this.props.dispatch(notificationPatch({ ...notification, isRead: true }));
    });
  };

  render() {
    const {
      classes,
      className,
      t,
      width,
      notifications,
      isOnTrailhead,
      isOnDashboard,
      isOnProfile,
    } = this.props;
    const { notificationList: trailheadNotifications } = notifications;
    const { anchorEl } = this.state;
    const isInMobileViewSm = width === "sm" || width === "xs";

    let notificationList = [];

    notificationList = [
      ...notificationList,
      ...trailheadNotifications.filter(
        (n) => n.notificationType === "trailhead",
      ),
    ];

    notificationList = notificationList.sort((a, b) => {
      let aDate = null;
      if (a.notificationType && a.notificationType === "trailhead") {
        aDate = a.created_at;
      } else {
        aDate = a.dateTime ? a.dateTime * 1000 : moment(a.date).unix() * 1000;
      }

      let bDate = null;
      if (b.notificationType && b.notificationType === "trailhead") {
        bDate = b.created_at;
      } else {
        bDate = b.dateTime ? b.dateTime * 1000 : moment(b.date).unix() * 1000;
      }

      return bDate - aDate;
    });

    notificationList = notificationList.splice(0, 20);

    const unreadNotifications = notificationList.filter((n) => !n.isRead);

    return (
      <Box>
        <div className={classes.alignCenter}>
          <Badge
            color="error"
            badgeContent={unreadNotifications.length}
            className={
              !isInMobileViewSm
                ? classes.thMargin
                : isOnTrailhead && isInMobileViewSm
                ? classes.marginMobileTH
                : classes.marginMobile
            }
          >
            <img
              onClick={(e) => this.handleClick(e)}
              src={`${process.env.PUBLIC_URL}/${
                !isOnDashboard && !isOnProfile
                  ? "notification-menu-icon"
                  : "notification_icon"
              }.svg`}
              alt="notification_icon"
              className={classes.menuBarIcon}
              id="notification_icon"
            />
          </Badge>
          {isInMobileViewSm && isOnTrailhead ? (
            <Typography
              variant="body2"
              className={classes.buttonText}
              style={{ color: "#000000" }}
            >
              {t("Notifications")}
            </Typography>
          ) : (
            ""
          )}
        </div>

        {this.state.anchorEl && isInMobileViewSm && (
          <Box className={classes.overlay} onClick={() => this.handleClose()}>
            {!notificationList.length && (
              <Popover
                id="notification-menu"
                open={anchorEl ? true : false}
                anchorEl={anchorEl}
                onClose={() => this.handleClose()}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                classes={{
                  paper: classes.paper,
                }}
              >
                <Box
                  sx={{
                    padding: "12px 16px",
                    boxSizing: "border-box",
                    boxShadow: palette.boxShadow,
                  }}
                >
                  <Typography style={{ color: palette.darkGray }}>
                    {t("Currently, you don't have any new notifications")}
                  </Typography>
                </Box>
              </Popover>
            )}
          </Box>
        )}

        {notificationList && notificationList.length > 0 && (
          <Popover
            id="notification-menu"
            open={anchorEl ? true : false}
            anchorEl={anchorEl}
            onClose={() => this.handleClose()}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            classes={{
              paper: classes.paper,
            }}
          >
            <div className={classNames(classes.container, className)}>
              {!isInMobileViewSm && (
                <div className={classes.rightButtons}>
                  <img
                    src={`${process.env.PUBLIC_URL}/close.svg`}
                    alt=""
                    onClick={() => this.handleClose()}
                  />
                </div>
              )}

              {(!notificationList || notificationList.length === 0) && (
                <Typography
                  variant="caption"
                  className={classes.noNotificationsText}
                >
                  {t("No new notifications")}
                </Typography>
              )}

              <div className={classes.notificationsContainer}>
                {notificationList.map((notification, index) => {
                  return notification.notificationType &&
                    notification.notificationType === "trailhead" ? (
                    <RouterLink
                      to={`/trailhead/post/${notification.notificationContent.post}`}
                      key={index}
                    >
                      <div
                        className={classes.notificationContainer}
                        onClick={this.handleNotificationClick.bind(
                          this,
                          notification,
                        )}
                      >
                        <Box className={classes.textRow}>
                          <Typography
                            className={classes.notificationText}
                            key={index}
                            data-i18n="[html]content.body"
                          >
                            {t(
                              `notificationItem_${notification.notificationType}_${notification.notificationContent.action}_${notification.notificationContent.level}`,
                              {
                                name:
                                  notification.notificationContent
                                    .cognito_user_name,
                              },
                            )}
                          </Typography>
                          <Typography
                            variant="h6"
                            className={classes.notificationDate}
                          >
                            {notification.notificationContent.postTitle
                              ? notification.notificationContent.postTitle
                              : notification.notificationContent.level ===
                                "post"
                              ? t("Your Post")
                              : notification.notificationContent.level ===
                                "comment"
                              ? t("Your Comment")
                              : t("Your Reply")}
                          </Typography>
                        </Box>

                        {isInMobileViewSm && (
                          <Box>
                            <Typography
                              variant="body2"
                              style={{ color: palette.darkGray }}
                            >{`${moment(notification.created_at)
                              .locale("en")
                              .fromNow()}`}</Typography>
                          </Box>
                        )}

                        {!notification.isRead && (
                          <span className={classes.unread}>&#8226;</span>
                        )}
                      </div>
                      {isInMobileViewSm && (
                        <Divider
                          style={{
                            width: "90%",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                      )}
                    </RouterLink>
                  ) : (
                    <RouterLink to={"dashboard"} key={index}>
                      <div
                        className={classes.notificationContainerPlan}
                        onClick={this.handleNotificationClick.bind(
                          this,
                          notification,
                        )}
                      >
                        <div>
                          <Typography
                            variant="caption"
                            className={classes.notificationText}
                          >
                            {t(`notificationItem_comment`)}
                          </Typography>
                          <Typography
                            variant="h6"
                            className={classes.notificationDate}
                          >
                            {t("notification workout")}
                          </Typography>
                        </div>
                        {!notification.isRead && (
                          <span className={classes.unread}>&#8226;</span>
                        )}
                      </div>
                    </RouterLink>
                  );
                })}
              </div>
              {notificationList && notificationList.length > 0 ? (
                <div className={classes.clearContainer}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.clear}
                    onClick={() =>
                      this.handleNotificationsClear(notificationList)
                    }
                    id="clear_notifications"
                  >
                    {t("Clear notifications")}
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </Popover>
        )}
      </Box>
    );
  }
}

DashboardNotifications.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  t: PropTypes.func.isRequired,
  notifications: PropTypes.object.isRequired,
  userPlans: PropTypes.object,
};

export default connect((store) => {
  return {
    notifications: store.notifications,
    userPlans: store.userPlans,
  };
})(
  withTranslation("dashboard")(
    withWidth()(withStyles(styles)(memo(DashboardNotifications))),
  ),
);
