import dayjs from "dayjs";
import { setProfileDate, setProfileFilter } from "../actions/profile-action";
import { getPaginatedPlan } from "../actions/user-plans-action";
import { formatDate } from "../lib/date-helper";
import useUserInfo from "./useUserInfo";
import useCalculatorMI from "./useCalculatorMI";
import { useDispatch } from "react-redux";
import bugsnagClient from "../lib/bugsnag-client";

const useProfile = () => {
  const dispatch = useDispatch();
  const { currentTraining, sub } = useUserInfo();
  const { getPlanDates } = useCalculatorMI();

  const handlePlanProgressionFilter = ({ target: { value } }) => {
    if (value === 0) {
      // week
      const fromDate = dayjs()
        .startOf("isoWeek")
        .format(formatDate);
      const toDate = dayjs(fromDate)
        .endOf("isoWeek")
        .format(formatDate);
      const { parsedDate, parsedToDate } = manageDates(fromDate, toDate);
      dispatch(setProfileDate({ currentDate: fromDate, toDate }));
      dispatch(getPaginatedPlan(parsedDate, parsedToDate, sub));
    }

    if (value === 1) {
      // month
      const fromDate = dayjs()
        .startOf("month")
        .format(formatDate);
      const toDate = dayjs()
        .endOf("month")
        .format(formatDate);
      const { parsedDate, parsedToDate } = manageDates(fromDate, toDate);
      dispatch(setProfileDate({ currentDate: fromDate, toDate }));
      dispatch(getPaginatedPlan(parsedDate, parsedToDate, sub));
    }

    if (value === 2) {
      // full plan
      try {
        const { startDate, endDate } = getPlanDates(currentTraining.days);
        const { parsedDate, parsedToDate } = manageDates(startDate, endDate);
        dispatch(setProfileDate({ currentDate: startDate, toDate: endDate }));
        dispatch(getPaginatedPlan(parsedDate, parsedToDate, sub));
      } catch (error) {
        bugsnagClient.notify(error);
      }
    }
    dispatch(setProfileFilter(value));
  };

  const manageDates = (date, toDate) => {
    const parsedDate = manageDate(date).replaceAll("/", "-");
    const parsedToDate = manageDate(toDate).replaceAll("/", "-");
    return { parsedDate, parsedToDate };
  };

  const manageDate = (date) => {
    const newDate = date.split("/");
    const parsedDate = `${newDate[1]}/${newDate[0]}/${newDate[2]}`;
    return parsedDate;
  };
  return {
    handlePlanProgressionFilter,
  };
};

export default useProfile;
