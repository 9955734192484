import React from "react";
import { withTranslation } from "react-i18next";
import {
  withStyles,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";

const styles = () => ({
  card: {
    boxShadow: "0px 10px 60px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    padding: "16px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
  },
  image: {
    marginRight: "12px",
  },
  text: {
    fontSize: "16px",
    textAlign: "left",
  },
  cardParent: {
    borderRadius: "12px",
    marginTop: "16px",
  },
});

const Card = ({ classes, icon, text, onClick = null, className }) => {
  return (
    <CardActionArea
      className={classNames(classes.cardParent, className)}
      onClick={onClick}
    >
      <CardContent className={classes.card}>
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/onboarding-img/icon/${icon}-icon.svg`}
            className={classes.image}
          />
        </div>
        <div>
          <Typography variant="body2" className={classes.text}>
            {text}
          </Typography>
        </div>
      </CardContent>
    </CardActionArea>
  );
};

export default withTranslation("constants")(
  withStyles(styles, { withTheme: true })(Card),
);
