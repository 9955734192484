import React, { useEffect, useState } from "react";
import history from "../../../lib/history-helper";
import { useTranslation } from "react-i18next";
import { palette } from "../../../theme/palette";
import { useSelector, useDispatch } from "react-redux";
import { updateNavbar } from "../../../actions/navbar-action";
import { Capacitor } from "@capacitor/core";
import { generalSearch } from "../../../actions/search-action";
import SearchInput from "../../generals/search-input";
import { Box, Typography, Divider } from "@material-ui/core";

const Search = () => {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const search = useSelector((state) => state.search);
  const { loading: searchLoading, searchData } = search;

  const [timer, setTimer] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [showSearch, setShowSearch] = useState(0);

  const types = {
    subcategories: "chooseplan",
    posts: "explore/post",
  };

  const inputSearch = async ({ target: { value } }) => {
    await setSearchValue(value);
    await clearTimeout(timer);
    const newTimer = setTimeout(() => {
      dispatch(generalSearch(value));
    }, 800);

    await setTimer(newTimer);
  };

  const handleSearch = () => {
    if (showSearch === 0) setShowSearch(1);
    dispatch(updateNavbar({ isSearching: true }));
    if (showSearch === 1) setShowSearch(2);
    setSearchValue("");
    dispatch(updateNavbar({ isSearching: false }));

    if (showSearch === 2) setShowSearch(1);
  };

  const selectOption = (item) => {
    const { type, id } = item;
    const section = types[type];
    if (type === "subcategories")
      return history.push("chooseplan", { id: item.id });
    history.push(`${section}/${id}`);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "static",
            display: "flex",
            justifyContent: "center",
            marginTop: 0,
          }}
        >
          <SearchInput
            show={showSearch}
            handleInput={handleSearch}
            onChange={inputSearch}
            loading={searchLoading}
            value={searchValue}
          />
        </Box>
      </Box>

      {searchValue && (
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            background: palette.background,
            position: "absolute",
            top: "70px",
            left: "0",
            width: "100%",
            zIndex: 999,
            marginTop:
              Capacitor.getPlatform() === "ios"
                ? "env(safe-area-inset-top)"
                : "0px",
            paddingBottom: "20px",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              padding: "16px",
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box sx={{ padding: "4px 0px", color: palette.darkGray }}>
              <Typography sx={{ textAlign: "left" }} variant="body2">
                {searchData.length > 0
                  ? t("Showing results for")
                  : t("We couldn't find any results for")}
                <b>{` "${searchValue}"`}</b>
              </Typography>
            </Box>
            <Box
              sx={{
                height: "2px",
                width: "100%",
                padding: "8px 0px",
              }}
            >
              <Divider sx={{ marginTop: "8px" }} />
            </Box>
            {!searchLoading
              ? searchData.map((item, index) => (
                  <Box
                    sx={{
                      marginTop: "16px",
                      color: palette.black,
                      "& p": {
                        textAlign: "left",
                      },
                    }}
                    variant="h6"
                    key={item.id}
                    onClick={() => selectOption(item)}
                    id={`home_search_result${index}`}
                  >
                    <Typography>
                      {item && item.title
                        ? item.title[language]
                          ? item.title[language]
                          : item.title["en"]
                        : ""}
                    </Typography>
                  </Box>
                ))
              : null}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Search;
