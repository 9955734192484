import ActionTypes from "../constants/action-types";
import WorkoutFields from "../constants/workout-fields";

const initialState = {
  loading: false,
  latestFilterApplied: {
    searchPhrase: "",
    type: "",
    duration: "",
    level: "",
    surface: "",
    period: "",
    justMine: false,
  },
  currentFilter: {
    searchPhrase: "",
    type: "",
    duration: "",
    level: "",
    surface: "",
    period: "",
    justMine: false,
  },
  hasBeenSearched: false,
  searchResult: {
    data: [],
    order: "asc",
    orderBy: WorkoutFields.titleLower,
    selected: [],
    rowsPerPage: 10,
    page: 0,
  },
  fromTracker: {},
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.WORKOUT_SEARCH_PENDING:
        return {
          ...state,
          loading: true,
          errorMessage: undefined,
          searchResult: {
            ...state.searchResult,
            data: [],
            selected: [],
            page: 0,
          },
        };
      case ActionTypes.WORKOUT_SEARCH_REJECTED:
        return {
          ...state,
          loading: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.WORKOUT_SEARCH_FULFILLED:
        return {
          ...state,
          loading: false,
          searchResult: {
            ...state.searchResult,
            data: action.payload || [],
          },
          latestFilterApplied: { ...state.currentFilter },
          hasBeenSearched: true,
        };
      case ActionTypes.WORKOUT_CLEAN_SEARCH:
        return { ...initialState };
      case ActionTypes.WORKOUT_SEARCH_PHRASE_CHANGED:
        return {
          ...state,
          currentFilter: {
            ...state.currentFilter,
            searchPhrase: action.payload || "",
          },
        };
      case ActionTypes.WORKOUT_SEARCH_FILTER_CHANGED:
        const { name, values } = action.payload;
        return {
          ...state,
          currentFilter: {
            ...state.currentFilter,
            [name]: values,
          },
        };
      case ActionTypes.WORKOUT_SEARCH_PARAM_CHANGED:
        return {
          ...state,
          searchResult: {
            ...state.searchResult,
            ...action.payload,
          },
        };
      case ActionTypes.WORKOUT_SAVE_PENDING:
        return {
          ...state,
          saving: true,
          errorMessage: undefined,
        };
      case ActionTypes.WORKOUT_SAVE_FULFILLED:
        //For update
        const foundItemIndex = state.searchResult.data.findIndex(
          (item) => item.id === action.payload.id,
        );
        const newData =
          foundItemIndex > -1
            ? [...state.searchResult.data]
            : [...state.searchResult.data, action.payload];
        if (foundItemIndex > -1) {
          newData.splice(foundItemIndex, 1, action.payload);
        }
        return {
          ...state,
          saving: false,
          hasBeenSearched: true,
          searchResult: {
            ...state.searchResult,
            data: newData,
          },
          successMessage: "Workout was saved Successfully",
        };
      case ActionTypes.WORKOUT_SAVE_REJECTED:
        return {
          ...state,
          saving: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.WORKOUT_DELETE_PENDING:
        return {
          ...state,
          deleting: true,
          errorMessage: undefined,
        };
      case ActionTypes.WORKOUT_DELETE_FULFILLED:
        const { selected } = state.searchResult;
        const newSearchResultData = [...state.searchResult.data];
        if (selected && selected.length > 0)
          selected.map((item) => {
            const foundIndex = newSearchResultData.findIndex(
              (data) => data.id === item,
            );
            if (foundIndex > -1) newSearchResultData.splice(foundIndex, 1);
            return newSearchResultData;
          });
        return {
          ...state,
          deleting: false,
          searchResult: {
            ...state.searchResult,
            selected: [],
            data: newSearchResultData,
          },
          successMessage: "Workout(s) Deleted Successfully",
        };
      case ActionTypes.WORKOUT_DELETE_REJECTED:
        return {
          ...state,
          deleting: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.WORKOUT_READ:
        let foundWorkout;
        if (state.searchResult) {
          const { data } = state.searchResult;
          if (data) {
            const result = data.find((item) => item.id === action.payload);
            foundWorkout = JSON.parse(JSON.stringify({ ...result }));
          }
        }

        return {
          ...state,
          workoutForSave: foundWorkout,
        };

      case ActionTypes.WORKOUT_SET:
        return {
          ...state,
          workoutForSave: action.payload,
        };

      case ActionTypes.WORKOUT_CLEAN_FIELDS:
        return {
          ...state,
          workoutForSave: {},
        };
      case ActionTypes.WORKOUT_CHANGE_FIELD:
        const { name: workoutName, value } = action.payload;
        return {
          ...state,
          workoutForSave: {
            ...state.workoutForSave,
            [workoutName]: value,
          },
        };
      case ActionTypes.WORKOUT_SET_ERROR_MESSAGE:
        return {
          ...state,
          errorMessage: action.payload,
        };
      case ActionTypes.WORKOUT_CLEAR_ERROR_MESSAGE:
        return {
          ...state,
          errorMessage: undefined,
        };
      case ActionTypes.WORKOUT_SET_SUCCESS_MESSAGE:
        return {
          ...state,
          successMessage: action.payload,
        };
      case ActionTypes.WORKOUT_CLEAR_SUCCESS_MESSAGE:
        return {
          ...state,
          successMessage: undefined,
        };
      case ActionTypes.AUTH_CURRENT_USER_SIGNOUT_FULFILLED:
        return {
          ...initialState,
        };

      case ActionTypes.FROM_TRACKER_STATUS:
        return {
          ...state,
          fromTracker: action.payload,
        };

      default:
        return state;
    }
  } else {
    return state;
  }
}
