import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import ComingSoon from "./coming-soon";

const useStyles = makeStyles({
  main: {
    padding: "20px 35px 8px 35px",
    backgroundColor: "#21222D",
    boxSizing: "border-box",
    borderRadius: 10,
    marginTop: "24px",
  },
  title: {
    color: "#FFF",
  },
  titleParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
});

const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        color: "#DDDDDD",
        zeroLineColor: "#DDDDDD",
      },
      ticks: {
        minor: {
          fontFamily: "Avenir",
          fontSize: 12,
          fontColor: "#9E9E9E",
        },
        padding: 10,
      },
    },
    y: {
      grid: {
        color: "rgba(0, 0, 0, 0.05)",
        zeroLineColor: "#A9DFD8",
      },
      ticks: {
        beginAtZero: true,
        fontFamily: "Avenir",
        fontSize: 12,
        fontColor: "#9E9E9E",
        padding: 10,
      },
    },
  },
  animation: {
    easing: "linear",
    duration: 1000,
  },
};

const distanceData = {
  labels: ["1km", "2km", "3km", "4km", "5km", "6km", "7km", "8km", "9km"],
  datasets: [
    {
      borderWidth: 1,
      data: [1, 9, 4, 6, 2, 5, 8, 3, 10],
      borderColor: "#A9DFD8",
      tension: 0.4,
    },
  ],
};

const RacetimePredictor = () => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Box className={classes.titleParent}>
        <Typography variant="h6" className={classes.title}>
          Race time predictor
        </Typography>
        <ComingSoon />
      </Box>
      <Line options={options} data={distanceData} />
    </Box>
  );
};

export default RacetimePredictor;
