import React from "react";
import BasicAccordion from "../../common/accordion";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  faqIcon: {
    position: "relative",
    width: "24px",
    height: "24px",
    overflow: "hidden",
    flexShrink: 0,
    display: "none",
  },
  infoContainer: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "2px",
  },
  sectionTitle: {
    position: "relative",
    fontSize: theme.typography.h5.fontSize,
    lineHeight: "150%",
    display: "inline-block",
    width: "350px",
    height: "24px",
    flexShrink: 0,
  },
  workoutText: {
    position: "relative",
    lineHeight: "150%",
  },
  questionText: {
    alignSelf: "stretch",
    position: "relative",
    fontSize: "14px",
    lineHeight: "150%",
    color: "#6d6f71",
  },
  subscriptionCategory: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    gap: "16px",
    fontSize: "14px",
  },
  seeOtherFaqsLink: {
    alignSelf: "stretch",
    position: "relative",
    fontSize: "18px",
    textDecoration: "underline",
    lineHeight: "125%",
    color: theme.palette.secondary.darkBlue,
    textAlign: "center",
  },
  faqContainer: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    padding: "24px 0px",
    gap: "24px",
  },
  contentContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px 16px",
    boxSizing: "border-box",
    textAlign: "left",
    fontSize: theme.typography.h5,
    color: "#12151b",
    fontFamily: theme.typography.fontFamily,
  },
});

const FAQSubscription = () => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <div className={styles.contentContainer}>
      <div className={styles.faqContainer}>
        <div className={styles.infoContainer}>
          <div className={styles.faqIcon} />
          <b className={styles.workoutText}>
            {t("Frequently Asked Questions")}
          </b>
          <div className={styles.questionText}>
            {t(
              `Have a question about our training subscriptions? Here's the answer!`,
            )}
          </div>
        </div>
        <div className={styles.subscriptionCategory}>
          <b className={styles.sectionTitle}>{t("Subscription")}</b>
          <BasicAccordion
            titles={"Question 1"}
            content={["Answer 1", "Answer 2"]}
            link={false}
          />
          <BasicAccordion
            titles={"Question 1"}
            content={["Answer 1", "Answer 2"]}
            link={false}
          />
          <BasicAccordion
            titles={"Question 1"}
            content={["Answer 1", "Answer 2"]}
            link={false}
          />
          <BasicAccordion
            titles={"Question 1"}
            content={["Answer 1", "Answer 2"]}
            link={false}
          />
        </div>
        <b className={styles.seeOtherFaqsLink}>{t("See other FAQ's")}</b>
      </div>
    </div>
  );
};

export default FAQSubscription;
