import React from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import unitsHook from "../../hooks/unitsHook";
import { round } from "../../lib/units-helper";

const styles = (theme) => ({
  main: {
    backgroundColor: "#FFF",
    borderRadius: "5px",
    boxShadow: "-2px 2px 4px rgba(0, 0, 0, 0.25)",
    padding: "4px 12px",
  },
  title: {
    fontWeight: "bold",
    fontSize: "14px",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  stat: {
    color: "#E16327",
    fontSize: "20px",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  statUnit: {
    color: "#E16327",
    fontSize: "12px",
    marginLeft: "5px",
  },
});

const ActivityMountainIndex = ({ classes, activity = {}, t }) => {
  const units = unitsHook();

  const calculateValue = (distance = 0, elevation = 0) => {
    const isValid =
      !isNaN(distance) && distance > 0 && !isNaN(elevation) && elevation > 0;
    const result = elevation / distance;
    return isValid ? `${round(result)}`.substring(0, 4) : 0;
  };

  return (
    <div className={classes.main}>
      <div className={classes.row}>
        <Typography className={classes.title}>{t("Mountain index")}</Typography>
        <Typography className={classes.stat}>
          {calculateValue(activity.distance, activity.elevation)}
        </Typography>
        <Typography className={classes.statUnit}>
          {" "}
          {units == "kms" ? "m+ / km" : `${t("feet")}+ / ${t("miles")}`}
        </Typography>
      </div>
    </div>
  );
};

export default withTranslation("challenges")(
  withWidth()(withStyles(styles, { withTheme: true })(ActivityMountainIndex)),
);
