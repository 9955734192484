import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, TextField, InputAdornment } from '@material-ui/core';

import TimeMaskedInput from '../../common/time-masked-input';
import PlanFieldTransition from './plan-field-transition';

const styles = {
    textField: {
        marginTop: 0
    }
}
class PlanFieldTime extends Component {
    render() {
        const { classes, className, xs, sm, md, lg, xl, id, value, defaultValue, onChange, fullWidth, endAdornmentLabel, required } = this.props;
        return (
            <PlanFieldTransition>
                <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <TextField
                        className={classNames(classes.textField, className)}
                        id={id}
                        value={value}
                        defaultValue={defaultValue}
                        margin="normal"
                        variant="outlined"
                        fullWidth={fullWidth}
                        required={required}
                        InputProps={{
                            endAdornment: endAdornmentLabel ? <InputAdornment position="end">{endAdornmentLabel}</InputAdornment> : null,
                            inputComponent: TimeMaskedInput
                        }}
                        onChange={onChange} />
                </Grid>
            </PlanFieldTransition>
        );
    };
}

PlanFieldTime.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    endAdornmentLabel: PropTypes.string,
    fullWidth: PropTypes.bool,
    required: PropTypes.bool
}

PlanFieldTime.defaultProps = {
    required: false
}

export default withStyles(styles)(PlanFieldTime);