import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class ActivateTrailheadDialog extends Component {
  render() {
    const { open, onCancel, onConfirm, t } = this.props;

    return (
      <div>
        <Dialog
          open={open}
          onClose={onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("Activate Trailhead")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("Do you want to activate your Trailhead?")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onConfirm} color="primary">
              {t("Activate")}
            </Button>
            <Button onClick={onCancel} color="secondary">
              {t("Cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect((store) => {
  return {
    auth: store.auth,
  };
})(withTranslation(["trailhead"])(withWidth()(ActivateTrailheadDialog)));
