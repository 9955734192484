import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Slide,
  Dialog,
  Typography,
  Button,
  Drawer,
  OutlinedInput,
  FormControl,
  InputAdornment,
  IconButton,
  Chip,
  Slider,
} from "@material-ui/core";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import history from "../../../lib/history-helper";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import useQuestionnaire from "../../../hooks/useQuestionnaire";
import TuneIcon from "@material-ui/icons/Tune";
import OnboardingPlanCard from "../../../components/common/v2/OnboardingPlanCard";
import PlanDetailsDialog from "../../../components/common/v2/PlanDetailsDialog";
import OnboardingPlanCardSkeleton from "../../../components/common/v2/OnboardingPlanCardSkeleton";
import useUserInfo from "../../../hooks/useUserInfo";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  CloseIcon,
  ElevationIcon,
  FlatIcon,
  HillIcon,
  SearchIcon,
} from "../../../components/icons";
import { Stack } from "@mui/material";
import { palette } from "../../../theme/palette";
import { logEvent } from "@amplitude/analytics-browser";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ChoosePlanView = () => {
  const [openDialog, setOpenDialog] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const plan = queryParams.get("plan");
  const { isSubscribed } = useUserInfo();
  const [detailsDialog, setDetailsDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  const [distanceSliderValue, setDistanceSliderValue] = useState([0, 180]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedToggle, setSelectedToggle] = useState([]);
  const {
    onboardingStorage,
    loadingSubCategories,
    planToSelectQuestionaire,
    subCategoriesData,
  } = useQuestionnaire();

  const {
    t,
    i18n: { language },
  } = useTranslation("plan");

  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleTransitionEnd = () => {
    history.goBack();
  };

  const handleConfirm = async () => {
    logEvent("have_coach_choose_plan");
    await onboardingStorage.setItem("onboardingPlanType", "coach");
    await onboardingStorage.setItem("onboardingPlan", {});
    history.push("/suggester");
  };

  const handleDetails = (plan) => {
    setSelectedPlan(plan);
    setDetailsDialog(true);
  };

  const handleStartTraining = async (plan) => {
    await onboardingStorage.setItem("onboardingPlanType", plan.plan);
    await onboardingStorage.setItem("onboardingPlan", plan);
    planToSelectQuestionaire(plan);

    if (plan.plan === "explore" && isSubscribed === false) {
      history.push("/subscription/explore");
    }

    if (plan.plan === "free" || isSubscribed === true) {
      history.push("/suggester");
    }
  };

  const handleClearFilters = () => {
    setSelectedTags([]);
    setSelectedToggle([]);
    setDistanceSliderValue([0, 180]);
  };

  const handleTagSelect = (tag) => {
    let updatedTags = [];
    if (selectedTags.some((t) => t === tag)) {
      updatedTags = selectedTags.filter((t) => t !== tag);
    } else {
      updatedTags = [...selectedTags, tag];
    }
    setSelectedTags(updatedTags);
  };

  const handleToggleSelect = (value) => {
    let updatedToggle = [];
    if (selectedToggle.some((t) => t === value)) {
      updatedToggle = selectedToggle.filter((t) => t !== value);
    } else {
      updatedToggle = [...selectedToggle, value];
    }
    setSelectedToggle(updatedToggle);
  };

  const handleSliderChange = (event, newValue) => {
    setDistanceSliderValue(newValue);
  };

  useEffect(() => {
    if (subCategoriesData) {
      const sortedSubCategories = [...subCategoriesData].sort(
        (a, b) => a.display_order - b.display_order,
      );

      if (plan) {
        const selectedPlan = sortedSubCategories.find(
          (p) => p.id === Number(plan),
        );
        if (selectedPlan) {
          handleDetails(selectedPlan);
        }
      }

      setItems(sortedSubCategories);
      setFilteredItems(sortedSubCategories);
    }
  }, [subCategoriesData, plan]);

  useEffect(() => {
    let newFilteredItems = items;

    if (searchText) {
      newFilteredItems = newFilteredItems.filter(
        (item) =>
          item.title &&
          item.title[language].toLowerCase().includes(searchText.toLowerCase()),
      );
    }

    if (selectedTags.length > 0) {
      newFilteredItems = newFilteredItems.filter((item) =>
        selectedTags.every((tag) => item.tags && item.tags.includes(tag)),
      );
    }

    if (selectedToggle.length > 0) {
      newFilteredItems = newFilteredItems.filter((item) =>
        selectedToggle.every(
          (toggle) =>
            item.training_surfaces && item.training_surfaces.includes(toggle),
        ),
      );
    }

    newFilteredItems = newFilteredItems.filter(
      (item) =>
        item.distance_range &&
        item.distance_range.min >= distanceSliderValue[0] &&
        item.distance_range.max <= distanceSliderValue[1],
    );

    setFilteredItems(newFilteredItems);
  }, [
    searchText,
    selectedTags,
    selectedToggle,
    distanceSliderValue,
    items,
    language,
  ]);

  const Row = useCallback(
    ({ index, style }) => {
      const plan = filteredItems[index];
      return (
        <div style={style}>
          <OnboardingPlanCard
            key={plan.id}
            plan={plan}
            onDetails={handleDetails}
            onStartTraining={handleStartTraining}
          />
        </div>
      );
    },
    [filteredItems],
  );

  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionProps={{ onExited: handleTransitionEnd }}
        TransitionComponent={Transition}
        display={"flex"}
        flexDirection="column"
      >
        <ToolbarReturnBack
          withLogo={true}
          logoWidth={107}
          style={{
            backgroundColor: "#FEFFFF",
            padding: "10px",
          }}
          goBack={handleCloseDialog}
        />
        <Box className={classes.parentBox}>
          <Button
            variant="contained"
            onClick={handleConfirm}
            className={classes.coachButton}
          >
            {t("plan.coachChoose")}
          </Button>
          <Box className={classes.searchBox}>
            <FormControl
              fullWidth
              hiddenLabel
              data-testid="new_post"
              id="new_post"
            >
              <OutlinedInput
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={t("plan.search")}
                color="secondary"
                aria-label="naked"
                className={classes.searchInput}
                startAdornment={
                  <InputAdornment
                    position="start"
                    data-testid="edit-icon"
                    id="edit-icon"
                  >
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchText && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear"
                        edge="end"
                        onClick={() => setSearchText("")}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
            <IconButton aria-label="filter" onClick={() => setOpenDrawer(true)}>
              <TuneIcon
                style={{
                  color:
                    selectedTags.length > 0 ||
                    selectedToggle.length > 0 ||
                    distanceSliderValue[0] !== 0 ||
                    distanceSliderValue[1] !== 180
                      ? palette.lightBlue
                      : "black",
                }}
              />
            </IconButton>
          </Box>
          <Box className={classes.listContainer}>
            <Typography className={classes.title}>{t("plan.title")}</Typography>
            {loadingSubCategories ? (
              Array(3)
                .fill()
                .map((_, i) => <OnboardingPlanCardSkeleton key={i} />)
            ) : (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    itemCount={filteredItems.length}
                    itemSize={496}
                    width={width}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            )}
          </Box>
        </Box>
      </Dialog>
      <PlanDetailsDialog
        openDialog={detailsDialog}
        setOpenDialog={setDetailsDialog}
        plan={selectedPlan}
        onStartTraining={handleStartTraining}
      />
      <Drawer anchor="bottom" open={openDrawer} onClose={handleCloseDrawer}>
        <Box className={classes.drawerBox}>
          <Box className={classes.drawerContent}>
            <Box>
              <IconButton
                aria-label="close"
                onClick={handleCloseDrawer}
                className={classes.closeButton}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Stack
              className={classes.tagsContainer}
              direction="row"
              spacing={"4px"}
              gap={"8px"}
              flexWrap="wrap"
              justifyContent="flex-start"
            >
              {filterOptions.map((tag) => (
                <Chip
                  key={tag}
                  size="medium"
                  variant="filled"
                  label={t(tag)}
                  onClick={() => handleTagSelect(tag)}
                  style={{
                    padding: "8px 12px",
                    margin: "0px",
                    fontFamily: "Karla",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    borderRadius: "4px",
                    borderWidth: 0,
                    color: selectedTags.includes(tag) ? "#FEFFFF" : "#12151B",
                    backgroundColor: selectedTags.includes(tag)
                      ? "#4780AB"
                      : "#F1F6FD",
                    cursor: "pointer",
                  }}
                />
              ))}
            </Stack>
            <Box className={classes.trainingSurfaceContainer}>
              <Box className={classes.trainingSurfaceTitleContainer}>
                <Typography className={classes.title}>
                  {t("plan.filter.trainingSurface")}
                </Typography>
                <Typography className={classes.subtitle}>
                  {t("plan.filter.trainingSurface.description")}
                </Typography>
              </Box>
              <Box className={classes.toggleContainer}>
                {toggleOptions.map((option, index) => (
                  <Button
                    key={index}
                    variant={
                      selectedToggle.includes(option.value)
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleToggleSelect(option.value)}
                    className={[
                      classes.toggleButton,
                      selectedToggle.includes(option.value)
                        ? classes.toggleButtonContained
                        : classes.toggleButtonOutlined,
                    ].join(" ")}
                    classes={{ label: classes.buttonLabel }}
                  >
                    <Box className={classes.iconWrapper}>
                      {
                        <option.icon
                          color={
                            selectedToggle.includes(option.value)
                              ? "#FEFFFF"
                              : "#12151B"
                          }
                        />
                      }
                    </Box>
                    <Typography
                      style={{
                        color: selectedToggle.includes(option.value)
                          ? "#FEFFFF"
                          : "#12151B",
                      }}
                      className={classes.textWrapper}
                    >
                      {option.label}
                    </Typography>
                  </Button>
                ))}
              </Box>
            </Box>
            <Box className={classes.sliderContainer}>
              <Typography className={classes.title}>
                {t("plan.filter.trainingDistance")}
              </Typography>
              <Box className={classes.sliderTextContainer}>
                <Typography className={classes.subtitle}>
                  {t("plan.filter.distance")}
                </Typography>
                <Typography className={classes.subtitle}>
                  {distanceSliderValue[0]} km - {distanceSliderValue[1]} km
                </Typography>
              </Box>
              <Box>
                <Slider
                  min={0}
                  step={1}
                  max={180}
                  className={classes.slider}
                  value={distanceSliderValue}
                  onChange={handleSliderChange}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                />
              </Box>
            </Box>
            <Button
              variant="outlined"
              onClick={handleClearFilters}
              className={classes.detailsButton}
            >
              {t("plan.filter.clear")}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default ChoosePlanView;

const useStyles = makeStyles({
  parentBox: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    padding: "16px",
    gap: "16px",
    height: "100vh",
    overflow: "hidden",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  title: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    color: "#12151B",
    textTransform: "initial",
    lineHeight: "22.5px",
  },
  subtitle: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#6D6F71 ",
    lineHeight: "21px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  coachButton: {
    backgroundColor: "#F93C6E",
    height: "47px",
    color: "#FFFFFF",
    borderRadius: "4px",
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
    width: "100%",
  },
  dividerText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 700,
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    overflowY: "auto",
    flex: 1,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "16px",
    height: "100%",
  },
  searchInput: {
    backgroundColor: "#fff",
    borderWidth: "0px",
    borderColor: "#EAEAEA",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EAEAEA",
      borderWidth: "0px",
    },
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "8px",
  },
  drawerBox: {
    padding: "32px",
    flex: 1,
    display: "flex",
  },
  drawerContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "40px",
  },
  tagsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    overflowX: "auto",
    maxWidth: "100%",
    justifyContent: "center",
  },
  slider: {
    color: palette.lightBlue,
  },
  sliderContainer: {
    display: "flex",
    gap: "16px",
    flexDirection: "column",
  },
  sliderTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  toggleContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  toggleButton: {
    flex: 1,
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
    borderRadius: "20px",
    padding: "12px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&:hover": {
      border: "none",
    },
  },
  toggleButtonContained: {
    border: "none",
    backgroundColor: "#4780AB",
    color: "#FEFFFF",
  },
  toggleButtonOutlined: {
    border: "none",
    backgroundColor: "#FEFFFF",
  },
  buttonLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconWrapper: {
    marginBottom: "8px",
  },
  textWrapper: {
    fontFamily: "Karla",
    fontWeight: 400,
    textTransform: "Initial",
    color: "#12151B",
    fontSize: "14px",
    lineHeight: "21px",
  },
  detailsButton: {
    flex: 1,
    textTransform: "initial",
    color: "#0B2B5C",
    borderColor: "#0B2B5C",
    borderWidth: "2px",
    borderRadius: "4px",
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
  },
  closeButton: {
    position: "absolute",
    top: "12px",
    right: "12px",
    height: "24px",
    width: "24px",
  },
  trainingSurfaceContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  trainingSurfaceTitleContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
});

const filterOptions = [
  "Beginner",
  "Free",
  "Start from zero",
  "Ultras",
  "Performance",
];

const toggleOptions = [
  { label: "Totally Flat", value: "Flat", icon: FlatIcon },
  { label: "Hills", value: "Hills", icon: HillIcon },
  {
    label: "Mountains",
    value: "Mountains",
    icon: ElevationIcon,
  },
];
