import React, { Component, memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import diff from "deep-diff";

import { Routes } from "../lib/routing-middleware";
import history from "../lib/history-helper";
import TrainingFilter from "./trainings/training-filter";
import TrainingFilterChips from "./trainings/training-filter-chips";
import TrainingGrid from "./trainings/training-grid";
import TrainingEditor from "./trainings/training-editor";
import FloatingAddButton from "./common/floating-add-button";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import DeleteConfirmationDialog from "./common/delete-confirmation-dialog";
import { cleanSearch } from "../actions/training-action";
import TrainingGridSwapConfirm from "./trainings/training-grid/training-grid-swap-confirm";
import {
  changeSearchPhrase,
  changeSearchFilter,
  changeSearchParams,
  searchTrainings,
  clearSuccessMessage,
  clearErrorMessage,
  deleteBulkTraining,
  swapTraining,
  patchTraining,
  selectInlineEditTraining,
} from "../actions/training-action";
import {
  getSubcategories,
  clearSubcategories,
} from "../actions/subcategory-action";
import UserGroups from "../constants/user-groups";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 5,
    width: "100%",
  },
};

class Trainings extends Component {
  componentDidMount() {
    const { auth } = this.props;
    const { currentUserGroups } = auth;

    const isExternalCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
      ) > -1;

    if (isExternalCoach) {
      this.props.dispatch(
        changeSearchFilter({ name: "justMine", values: true }),
      );
    } else {
      this.props.dispatch(
        changeSearchFilter({ name: "justMine", values: false }),
      );
    }
    const { subcategories } = this.props;
    const { data } = subcategories;
    if (!data || data.length === 0) {
      this.props.dispatch(getSubcategories());
    }
  }

  handleSearchPhraseChanged = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.props.dispatch(changeSearchPhrase(value));
      }
    }
  };

  handleSearchParamChanged = (param) => {
    this.props.dispatch(changeSearchParams(param));
  };

  handleSelectedChanged = ({ selected }) => {
    const swappable =
      selected &&
      selected.length === 2 &&
      [...new Set(selected.map((item) => item.split("/")[0]))].length === 1;
    const swapIdRoute = swappable
      ? `/${selected[0].split("/")[0]}/${selected[0].split("/")[1]}/${
          selected[1].split("/")[1]
        }`
      : undefined;
    this.props.dispatch(
      changeSearchParams({
        selected: selected,
        swappable,
        swapIdRoute,
      }),
    );
  };

  handleSelect = ({ name, value }) => {
    const { training } = this.props;
    const { currentFilter } = training;

    const values = currentFilter[name];
    if (name === "justMine") {
      this.props.dispatch(changeSearchFilter({ name, values: value }));
      return;
    }
    if (!values || values.indexOf(value) < 0)
      this.props.dispatch(
        changeSearchFilter({ name, values: [...(values || []), value] }),
      );
  };

  handleApplyFilter = () => {
    const { training } = this.props;
    const { currentFilter } = training;

    let filtersQueryStringReady = {};
    const { searchPhrase } = currentFilter;
    if (searchPhrase) {
      filtersQueryStringReady.searchPhrase = searchPhrase;
    }
    const filters = Object.keys(currentFilter).filter(
      (key) => key !== "searchPhrase",
    );
    let currentFilters = {
      ...currentFilter,
    };
    if (currentFilter.subcategories) {
      currentFilters = {
        ...currentFilters,
        subcategories: currentFilter.subcategories.filter(
          (subcategory) => subcategory !== "General",
        ),
      };
    }
    if (filters.length > 0) {
      filters.forEach((filter) => {
        if (currentFilters[filter] && currentFilter[filter].length)
          filtersQueryStringReady[filter] = currentFilters[filter].join(";");
      });
    }
    this.props.dispatch(searchTrainings(filtersQueryStringReady));
  };

  onDeleteFilter = (key, data) => {
    if (key && data) {
      const { training } = this.props;
      const { currentFilter } = training;
      const filters = [...currentFilter[key]];
      if (filters && filters.length > 0) {
        const foundIndex = filters.findIndex((item) => item === data);
        if (foundIndex >= 0) {
          filters.splice(foundIndex, 1);
          this.props.dispatch(
            changeSearchFilter({
              name: key,
              values: filters.length === 0 ? undefined : filters,
            }),
          );
        }
      }
    }
  };

  handleDeleteSearchPhrase = () => {
    this.props.dispatch(changeSearchPhrase());
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleDeleteRequested = () => {
    const { training } = this.props;
    if (training) {
      const { searchResult } = training;
      if (searchResult) {
        const { selected } = searchResult;
        if (selected && selected.length > 0) {
          const selectedIds = selected.map((id) => {
            const splittedId = id && id.split("/");
            return {
              id: splittedId && splittedId[0],
              training_order: splittedId && splittedId[1],
            };
          });
          this.props.dispatch(deleteBulkTraining(selectedIds));
        }
      }
    }
    history.push(Routes.Trainings);
  };

  handleDeleteCancel = () => {
    history.push(Routes.Trainings);
  };

  handleSwapConfirm = (...params) => {
    this.props.dispatch(swapTraining(...params));
  };

  handleSwapCancel = () => {
    history.push(Routes.Trainings);
  };

  handleCellEditSaveRequested = (inlineEditCellId, name, value) => {
    this.props.dispatch(patchTraining(inlineEditCellId, { [name]: value }));
  };

  handleSelectInlineEditCell = (id) => {
    this.props.dispatch(selectInlineEditTraining(id));
  };

  componentWillUnmount() {
    let shouldCleanSearchResult = true;

    const { history } = this.props;
    if (history) {
      const { location } = history;
      if (location) {
        const { pathname } = location;
        if (pathname.startsWith("/training/")) shouldCleanSearchResult = false;
      }
    }

    if (shouldCleanSearchResult) {
      this.props.dispatch(cleanSearch());
      this.props.dispatch(clearSubcategories());
    }
  }

  render() {
    const {
      classes,
      training,
      addable,
      updatable,
      selectable,
      onSelectByDoubleClick,
      subcategories,
      auth,
    } = this.props;
    const {
      currentFilter,
      latestFilterApplied,
      errorMessage,
      successMessage,
      saving,
    } = training;
    const { searchPhrase } = currentFilter;
    const selectableFilters = { ...currentFilter, searchPhrase: undefined };

    const differents = diff(currentFilter, latestFilterApplied);
    const newFilterApplied = differents && differents.length > 0;
    let hasAnyFilters = false;
    for (let prop in currentFilter) {
      if (currentFilter[prop] && !hasAnyFilters) hasAnyFilters = true;
    }

    const { currentUserGroups, currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;

    const isExternalCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
      ) > -1;

    return (
      <div className={classes.container}>
        <TrainingFilter
          onSearchPhraseChanged={this.handleSearchPhraseChanged}
          onFilterSelect={this.handleSelect}
          newFilterApplied={newFilterApplied}
          hasAnyFilters={hasAnyFilters}
          onApplyFilter={this.handleApplyFilter}
          currentFilter={currentFilter}
          subcategories={subcategories}
          isExternalCoach={isExternalCoach}
        />
        <TrainingFilterChips
          filters={selectableFilters}
          onDeleteFilter={this.onDeleteFilter}
          searchPhrase={searchPhrase}
          onDeleteSearchPhrase={this.handleDeleteSearchPhrase}
          subcategories={subcategories}
        />
        <TrainingGrid
          training={training}
          updatable={updatable}
          selectable={selectable}
          onSearchParamChanged={this.handleSearchParamChanged}
          onSelectedChanged={this.handleSelectedChanged}
          onCellEditSaveRequested={this.handleCellEditSaveRequested}
          onSelectInlineEditCell={this.handleSelectInlineEditCell}
          onSelectByDoubleClick={onSelectByDoubleClick}
          sub={sub}
          isExternalCoach={isExternalCoach}
        />
        {addable && <FloatingAddButton to={Routes.TrainingCreate} />}
        {/* <Route exact path={Routes.TrainingCreate} component={TrainingEditor} /> */}
        <Route
          exact
          path={Routes.TrainingDeletePath}
          render={(props) => (
            <DeleteConfirmationDialog
              entityName="Training(s)"
              onCancel={this.handleDeleteCancel}
              onConfirm={this.handleDeleteRequested}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/trainings/swap/:id/:training_order1/:training_order2"
          render={(props) => {
            return (
              <TrainingGridSwapConfirm
                {...props}
                saving={saving}
                onCancel={this.handleSwapCancel}
                onConfirm={this.handleSwapConfirm}
              />
            );
          }}
        />
        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      </div>
    );
  }
}

Trainings.propTypes = {
  classes: PropTypes.object.isRequired,
  addable: PropTypes.bool,
  updatable: PropTypes.bool,
  selectable: PropTypes.bool,
  onSelectByDoubleClick: PropTypes.func,
};

Trainings.defaultProps = {
  addable: true,
  updatable: true,
  selectable: true,
};

export default connect((store) => {
  return {
    training: store.training,
    subcategories: store.subcategories,
    auth: store.auth,
  };
})(withStyles(styles)(memo(Trainings)));
