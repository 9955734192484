import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import classNames from "classnames";
import {
  Dialog,
  DialogContent,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
} from "@material-ui/core";
// import history from "../../../../lib/history-helper";
import { useParams } from "react-router-dom";
import DeleteConfirmationDialog from "../../../../components/common/delete-confirmation-dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  currentTrainingUpdateWorkoutEnergyLevel,
  getUserActivities,
} from "../../../../actions/user-plans-action";
import DashboardGPSMapView from "../dashboard-gps-map-view";
import { getActivityCalculations } from "../../../../lib/units-helper";
import {
  clearWatchers,
  postActivityVert,
  clearActivityVert,
} from "../../../../actions/vert-activity-action";
import DashboardActivityShare from "../../dashboard-recent-activities/dashboard-activity-share";
import { registerPlugin } from "@capacitor/core";
import { useHistory } from "react-router-dom";
import { logEvent } from "../../../../lib/events-helper";
const BackgroundGeolocation = registerPlugin("BackgroundGeolocation");

const ovalCommon = {
  backgroundColor: "#FFF",
  width: 56,
  height: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  borderRadius: "24px",
};

const styles = (theme) => ({
  dialogPaper: {
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFF",
    padding: "16px",
    boxSizing: "border-box",
  },
  date: {
    fontWeight: 500,
    fontSize: 18,
    marginLeft: 20,
  },
  leftSide: {
    display: "flex",
    alignItems: "center",
  },
  discard: {
    color: "#FF5722",
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  smallerFontSize: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller",
    },
  },
  inputStyle: {
    marginBottom: "16px !important",
    marginTop: "0px !important",
  },
  input: {
    backgroundColor: "#FFF",
  },
  selectStyle: {
    backgroundColor: "#FFF",
  },
  form: {
    padding: "24px 16px",
    boxSizing: "border-box",
  },
  imageParent: {
    position: "relative",
    margin: "0px 0px 16px 0px",
    height: "200px",
  },
  ovalLeft: {
    bottom: "8%",
    left: "4%",
    ...ovalCommon,
  },
  ovalRight: {
    bottom: "8%",
    right: "4%",
    ...ovalCommon,
  },
  buttonsParent: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    alignItems: "center",
    margin: "25px 0px 10px 0px",
  },
  back: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#FF5722",
    cursor: "pointer",
  },
  radiusButton: {
    borderRadius: 50,
  },
  dialogContent: {
    padding: 0,
    minHeight: "100%",
    backgroundColor: "#F9F9FB",

    "&:first-child": {
      paddingTop: "0px !important",
    },
  },
  dialogDiscard: {
    zIndex: 99999999,
  },
});

const options = [
  { label: "Pretty Easy", value: 20 },
  { label: "Easy", value: 40 },
  { label: "Medium", value: 60 },
  { label: "Hard", value: 80 },
  { label: "Very hard", value: 100 },
];

const SaveActivity = ({ classes, t }) => {
  const history = useHistory();
  const params = useParams();
  const { effort } = params;

  const [image, setImage] = useState("");
  const [confirmDiscard, setConfirmDiscard] = useState(false);
  const [currentToShare, setCurrentToShare] = useState(null);

  const { userPlans, vertActivities } = useSelector((state) => state);
  const { currentPlan, updatingWorkoutId } = userPlans || {};
  const { currentTraining, cognito_user_sub } = currentPlan || {};
  const { selectedTrainingDayWorkoutTemp } = currentTraining || {};
  const { id, plannedDate } = selectedTrainingDayWorkoutTemp || {};

  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    type: "",
    typeEffort: "",
    activityEffort: "",
  });

  const handleInput = (event) => {
    const {
      target: { value, name },
    } = event;
    setFormValues({ ...formValues, [name]: value });
  };

  const showConfirm = () =>
    setConfirmDiscard((confirmDiscard) => !confirmDiscard);

  const goBack = () => {
    const { activityEffort } = formValues;
    const path = history.location.state.from;
    const currentPath = history.location.pathname;
    if (path !== currentPath && history.location.state && path) {
      return history.push(path, {
        from: currentPath,
        rpe: activityEffort || Number(effort) * 10,
      });
    }
    history.goBack();
  };

  const onConfirm = () => {
    if (vertActivities.watchers) {
      vertActivities.watchers.forEach((watcher) => {
        BackgroundGeolocation.removeWatcher({
          id: watcher,
        });
      });
      dispatch(clearWatchers());
    }
    dispatch(clearActivityVert());

    history.push("/dashboard");
  };

  const handleEnergyLevelChange = (event) => {
    handleInput(event);
    const {
      target: { value },
    } = event;

    if (selectedTrainingDayWorkoutTemp && !updatingWorkoutId) {
      dispatch(
        currentTrainingUpdateWorkoutEnergyLevel(
          {
            ...selectedTrainingDayWorkoutTemp,
            workoutId: selectedTrainingDayWorkoutTemp.id,
            plannedDate: selectedTrainingDayWorkoutTemp.plannedDate,
            cognito_user_sub: currentPlan.cognito_user_sub,
            rpe: value,
          },
          currentTraining,
          0,
          false,
          false,
        ),
      );
    }
  };

  const saveActivity = (goToShare = false) => {
    const calculations = getActivityCalculations(
      { rawSamples: vertActivities.currentActivity.rawSamples },
      "kms",
      "vert.run",
    );

    const values = { ...formValues };

    if (!formValues.name) {
      values.name = "Trail Run";
    }

    if (!formValues.type) {
      values.type = "Trail Run";
    }

    if (!formValues.typeEffort) {
      values.typeEffort = "Easy";
    }

    if (!formValues.activityEffort) {
      values.activityEffort = Number(effort) * 10;
    }

    dispatch(
      postActivityVert({
        ...calculations,
        ...values,
        cognito_user_sub,
        distance: calculations.distance * 1000,
      }),
    );

    if (vertActivities.watchers) {
      vertActivities.watchers.forEach((watcher) => {
        BackgroundGeolocation.removeWatcher({
          id: watcher,
        });
      });
      dispatch(clearWatchers());
    }

    getUserActivitiesInComponent();
    if (goToShare) {
      setCurrentToShare("vert.run");
    } else {
      dispatch(clearActivityVert());
      history.push("/dashboard");
    }
  };

  const getUserActivitiesInComponent = () => {
    const { currentPlan } = userPlans;
    dispatch(getUserActivities(currentPlan));
  };

  return (
    <>
      {confirmDiscard && (
        <DeleteConfirmationDialog
          entityName="Epic Race"
          onConfirm={onConfirm}
          onCancel={showConfirm}
          message={t("Are u sure you want to discard this activity")}
        />
      )}

      <Dialog
        fullWidth={true}
        fullScreen={true}
        open={true}
        onClose={null}
        aria-labelledby="tracking"
        scroll="body"
        maxWidth="md"
        className={classes.dialog}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogContent className={classes.dialogContent}>
          <div className={classes.topContainer}>
            <div className={classes.leftSide}>
              <ArrowBackIosIcon
                onClick={goBack}
                className={classes.backIcon}
                fontSize="small"
              />

              <Typography variant="body2" className={classes.date}>
                {t("Save Activity")}
              </Typography>
            </div>

            <Typography className={classes.discard} onClick={showConfirm}>
              {t("Discard")}
            </Typography>
          </div>
          <form className={classes.form}>
            <TextField
              fullWidth
              variant="filled"
              id="name"
              name="name"
              margin="normal"
              value={formValues.name}
              readOnly
              label={t("Trace name")}
              onChange={handleInput}
              className={classes.inputStyle}
              InputLabelProps={{
                classes: { root: classes.smallerFontSize },
              }}
              InputProps={{
                disableUnderline: true,
                classes: { root: classes.input },
              }}
            />

            <TextField
              fullWidth
              variant="filled"
              id="description"
              name="description"
              margin="normal"
              multiline
              value={formValues.description}
              readOnly
              label={t("Description Optional")}
              className={classes.inputStyle}
              onChange={handleInput}
              InputLabelProps={{
                classes: { root: classes.smallerFontSize },
              }}
              InputProps={{
                disableUnderline: true,
                classes: { root: classes.input },
              }}
            />

            <FormControl
              variant="filled"
              style={{
                width: "100%",
                backgroundColor: "#FFFFFF",
                marginBottom: "16px",
              }}
            >
              <InputLabel
                id="type-activity-label"
                className={classes.smallerFontSize}
              >
                {t("Type of Activity")}
              </InputLabel>
              <Select
                name="type"
                fullWidth
                labelId="type-activity-label"
                id="type-activity-select"
                label="Type of Activity"
                value={formValues.type}
                onChange={handleInput}
                className={classes.selectStyle}
                disableUnderline
              >
                <MenuItem value="Trail Run">{t("Trail Run")}</MenuItem>
                <MenuItem value="Run">{t("Run")}</MenuItem>
                <MenuItem value="Ride">{t("Ride")}</MenuItem>
                <MenuItem value="Walk">{t("Walk")}</MenuItem>
                <MenuItem value="Hike">{t("Hike")}</MenuItem>
              </Select>
            </FormControl>

            <div className={classes.imageParent}>
              {vertActivities &&
              vertActivities.currentActivity &&
              vertActivities.currentActivity.rawSamples ? (
                <DashboardGPSMapView
                  trackingData={vertActivities.currentActivity.rawSamples}
                  mapId={`map${new Date().getTime()}`}
                ></DashboardGPSMapView>
              ) : null}
            </div>

            <FormControl
              variant="filled"
              style={{
                width: "100%",
                backgroundColor: "#FFFFFF",
                marginBottom: "16px",
              }}
            >
              <InputLabel
                id="type-effort-label"
                className={classes.smallerFontSize}
              >
                {t("Type of effort")}
              </InputLabel>
              <Select
                name="typeEffort"
                fullWidth
                labelId="type-effort-label"
                id="type-effort-select"
                label="Type of effort"
                value={formValues.typeEffort}
                onChange={handleInput}
                className={classes.selectStyle}
                disableUnderline
              >
                <MenuItem value="Easy">{t("Easy")}</MenuItem>
                <MenuItem value="Workout">{t("Workout")}</MenuItem>
                <MenuItem value="Race">{t("Race")}</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              variant="filled"
              style={{
                width: "100%",
                backgroundColor: "#FFFFFF",
                marginBottom: "16px",
              }}
            >
              <InputLabel
                id="activity-effort-label"
                className={classes.smallerFontSize}
              >
                {t("Activity effort")}
              </InputLabel>
              <Select
                name="activityEffort"
                fullWidth
                defaultValue={20}
                labelId="type-effort-label"
                id="type-effort-select"
                label="Type of effort"
                value={formValues.activityEffort || Number(effort) * 10}
                onChange={(event) => handleEnergyLevelChange(event)}
                className={classes.selectStyle}
                disableUnderline
              >
                {options.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {t(item.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className={classes.buttonsParent}>
              <Button
                variant="contained"
                color="primary"
                className={classNames(
                  classes.radiusButton,
                  classes.smallerFontSize,
                )}
                onClick={() => saveActivity(false)}
              >
                {t("Save Activity")}
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => saveActivity(true)}
                className={classNames(
                  classes.radiusButton,
                  classes.smallerFontSize,
                )}
              >
                {t("Save & Share").toUpperCase()}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <DashboardActivityShare
        isOpen={currentToShare}
        onClose={(sharing = false) => {
          setCurrentToShare(null);
          dispatch(clearActivityVert());
          history.push("/dashboard");
        }}
        isColumn={false}
      ></DashboardActivityShare>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("tracking")(withWidth()(SaveActivity)),
);
