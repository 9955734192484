import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const styles = {
    toolTipIcon: {
        padding: 2,
        margin: 2
    }
}

class PlanFieldTooltip extends Component {
    render() {
        const { classes, tooltipText, tooltipPlacement } = this.props;
        return (
            tooltipText ?
                <Tooltip title={tooltipText} placement={tooltipPlacement}>
                    <IconButton className={classes.toolTipIcon}>
                        <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                : null
        );
    };
}

PlanFieldTooltip.propTypes = {
    classes: PropTypes.object.isRequired,
    tooltipText: PropTypes.string,
    tooltipPlacement: PropTypes.string
}

PlanFieldTooltip.defaultProps = {
    tooltipPlacement: "top"
}

export default withStyles(styles)(PlanFieldTooltip);