import { useEffect } from "react";
import dayjs from "dayjs";
import { formatDate } from "../lib/date-helper";
import { useDispatch } from "react-redux";
import { setProfileDate } from "../actions/profile-action";
import { getPaginatedPlan } from "../actions/user-plans-action";
import useUserInfo from "./useUserInfo";
import { useTranslation } from "react-i18next";
import "dayjs/locale/es";
import "dayjs/locale/fr";

const useDateBetween = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("profile");
  const currentLocale = i18n.language;
  dayjs.locale(currentLocale);

  const {
    sub,
    currentFilter,
    currentDate,
    currentStartDate,
    durationInWeeks,
    toDate,
  } = useUserInfo();

  const handleArrow = (direction) => {
    const dateMapping = {
      0: "isoWeek",
      1: "month",
      2: "year",
    };

    const dateType = dateMapping[currentFilter];
    if (!dateType) return;

    let date, toDate;

    if (dateType === "month") {
      date = dayjs(currentDate)
        .add(direction, "month")
        .startOf(dateType)
        .format(formatDate);
      toDate = dayjs(date)
        .endOf(dateType)
        .format(formatDate);
    } else {
      date = dayjs(currentDate)
        .add(direction, "week")
        .format(formatDate);
      toDate = dayjs(date)
        .endOf(dateType)
        .format(formatDate);
    }

    const { parsedDate, parsedToDate } = manageDates(date, toDate);
    dispatch(setProfileDate({ currentDate: date, toDate }));
    dispatch(getPaginatedPlan(parsedDate, parsedToDate, sub));
  };

  const handleNextArrow = () => handleArrow(1);
  const handlePreviousArrow = () => handleArrow(-1);

  const manageDate = (date) => {
    if (!date) return;
    const newDate = date.split("/");
    const parsedDate = `${newDate[1]}/${newDate[0]}/${newDate[2]}`;
    return parsedDate;
  };

  const manageDates = (date, toDate) => {
    const parsedDate = manageDate(date).replaceAll("/", "-");
    const parsedToDate = manageDate(toDate).replaceAll("/", "-");
    return { parsedDate, parsedToDate };
  };

  const dateFrom = () => {
    let from;
    if (currentFilter == 0) {
      // week
      from = `${dayjs(currentDate)
        .startOf("isoWeek")
        .format("MMM")} ${dayjs(currentDate)
        .startOf("isoWeek")
        .date()}`;
    } else if (currentFilter == 1) {
      // month
      from = `${dayjs(currentDate)
        .startOf("month")
        .format("MMMM YYYY")}`;
    } else {
      // all plan
      from = `${dayjs(currentDate).format("MMM YYYY")}`;
    }
    return from;
  };

  const dateTo = () => {
    let to;
    if (currentFilter == 0) {
      // week
      to = `${t("profile.stats.dateBetween.to")} ${dayjs(toDate).format(
        "MMM",
      )} ${dayjs(toDate).date()}`;
    } else if (currentFilter == 1) {
      // month
      to = ``;
    } else {
      // all plan
      to = `${t("profile.stats.dateBetween.to")} ${dayjs(toDate).format(
        "MMM YYYY",
      )}`;
    }
    return to;
  };

  const datePrefix = () => {
    if (currentFilter === 0)
      return `${t(`profile.stats.dateBetween.weekly`)} ${dayjs(
        currentDate,
      ).isoWeek()}`;
    return ``;
  };

  useEffect(() => {
    const parseDate = manageDate(currentDate).replaceAll("/", "-");
    const parsedToDate = manageDate(toDate).replaceAll("/", "-");
    dispatch(getPaginatedPlan(parseDate, parsedToDate, sub));
  }, []);

  return {
    currentFilter,
    manageDate,
    manageDates,
    dateFrom,
    dateTo,
    datePrefix,
    handleNextArrow,
    handlePreviousArrow,
  };
};

export default useDateBetween;
