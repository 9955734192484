import {
  ActivityData,
  CapacitorHealthkit,
  OtherData,
  QueryOutput,
  SampleNames,
  SleepData,
} from "@perfood/capacitor-healthkit";
import moment from "moment";

const READ_PERMISSIONS = [
  "calories",
  "stairs",
  "activity",
  "steps",
  "distance",
  "duration",
  "weight",
];

class HealthKitService {
  static async initAuthorization() {
    try {
      const result = await CapacitorHealthkit.requestAuthorization({
        all: [""],
        read: READ_PERMISSIONS,
        write: [""],
      });
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async getActivities() {
    const endDate = moment.utc().endOf("day");
    const startDate = moment
      .utc(endDate)
      .subtract(60, "days")
      .startOf("day");

    try {
      const queryOptions = {
        sampleName: SampleNames.WORKOUT_TYPE,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        limit: 10000,
      };
      const result = await CapacitorHealthkit.queryHKitSampleType(queryOptions);
      const data = result.map((activity) => {
        if (!activity.device) return { ...activity, device: "healthKit" };
        return activity;
      });
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default HealthKitService;
