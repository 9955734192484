import React from "react";
import { palette } from "../../theme/palette";

const RunIcon = ({
  width = 24,
  height = 24,
  vWidth = 24,
  vHeight = 24,
  activated = false,
  fill = null,
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vWidth} ${vHeight}`}
      fill={activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 8.3125C2.26794 8.3125 2.04538 8.22031 1.88128 8.05622C1.71719 7.89212 1.625 7.66956 1.625 7.4375C1.625 7.20544 1.71719 6.98288 1.88128 6.81878C2.04538 6.65469 2.26794 6.5625 2.5 6.5625H5.125V8.3125H2.5ZM3.375 4.8125C3.14294 4.8125 2.92038 4.72031 2.75628 4.55622C2.59219 4.39212 2.5 4.16956 2.5 3.9375C2.5 3.70544 2.59219 3.48288 2.75628 3.31878C2.92038 3.15469 3.14294 3.0625 3.375 3.0625H7.75V4.8125H3.375ZM1.625 11.8125C1.39294 11.8125 1.17038 11.7203 1.00628 11.5562C0.842187 11.3921 0.75 11.1696 0.75 10.9375C0.75 10.7054 0.842187 10.4829 1.00628 10.3188C1.17038 10.1547 1.39294 10.0625 1.625 10.0625H5.125C5.125 10.0625 5.125 10.0625 5.125 10.9375C5.125 11.8125 5.125 11.8125 5.125 11.8125H1.625Z"
        fill={fill ? fill : activated ? palette.primary : palette.darkGray}
      />
      <path
        d="M13.3677 4.375C14.3302 4.375 15.1177 3.5875 15.1177 2.625C15.1177 1.6625 14.3302 0.875 13.3677 0.875C12.4052 0.875 11.6177 1.6625 11.6177 2.625C11.6177 3.5875 12.4052 4.375 13.3677 4.375ZM10.5939 14.875L11.0927 12.6875L12.9302 14.4375V18.8125C12.9302 19.2938 13.3239 19.6875 13.8052 19.6875C14.2864 19.6875 14.6802 19.2938 14.6802 18.8125V13.8775C14.6802 13.3963 14.4877 12.9412 14.1377 12.6087L12.8427 11.375L13.3677 8.75C14.3537 9.87727 15.7036 10.6234 17.1827 10.8587C17.7077 10.9375 18.1802 10.5175 18.1802 9.98375C18.1802 9.555 17.8652 9.19625 17.4364 9.12625C16.1064 8.9075 15.0039 8.12 14.4177 7.0875L13.5427 5.6875C13.1927 5.1625 12.6677 4.8125 12.0552 4.8125C11.7927 4.8125 11.6177 4.9 11.3552 4.9L7.87269 6.37C7.55569 6.5055 7.2855 6.73117 7.0957 7.01897C6.90591 7.30677 6.80489 7.644 6.80519 7.98875V10.0625C6.80519 10.5437 7.19894 10.9375 7.68019 10.9375C8.16144 10.9375 8.55519 10.5437 8.55519 10.0625V7.9625L10.1302 7.35L8.73019 14.4375L5.30019 13.7375C4.82769 13.6413 4.36394 13.9475 4.26769 14.42V14.455C4.17144 14.9275 4.47769 15.3913 4.95019 15.4875L8.54644 16.205C8.99374 16.2938 9.45802 16.2045 9.84044 15.9561C10.2229 15.7076 10.4932 15.3198 10.5939 14.875Z"
        fill={fill ? fill : activated ? palette.primary : palette.darkGray}
      />
    </svg>
  </>
);
export default RunIcon;
