import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography, Button, Grid } from "@material-ui/core";
import { Dialog, DialogContent, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  EmailIcon,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import withWidth from "@material-ui/core/withWidth";
import { Share } from "@capacitor/share";
import { Capacitor } from "@capacitor/core";
import { connect } from "react-redux";

const styles = (theme) => ({
  gearTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 17,
    textAlign: "center",
    color: "#FF6327",
    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
  },
  gearText1: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 12,
    textAlign: "center",
    color: "#2E3A59",
    margin: "10px 0",
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
  },
  gearText2: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 15,
    textAlign: "center",
    color: "#2E3A59",
    margin: "10px 0",
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },
  codeContainer: {
    borderRadius: 12,
    boxShadow: "0 0 12px rgba(33, 81, 209, 0.1)",
    textAlign: "center",
    margin: "30px 0",
    padding: 20,
  },
  codeContainerWeb: {
    borderRadius: 12,
    boxShadow: "0 0 12px rgba(33, 81, 209, 0.1)",
    textAlign: "center",
    margin: 5,
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 5,
    },
  },
  code: {
    background: "#EFF3F5",
    border: "1px solid #9E9E9E",
    borderRadius: 8,
    margin: "0 10px 10px 10px",
  },
  codeText: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 12,
    textAlign: "center",
    color: "#2E3A59",
    padding: "10px",
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
  },
  shareButton: {
    borderRadius: 48,
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 13,
    lineHeight: "13px",
    textAlign: "center",
    color: "#FFFFFF",
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
      fontSize: 13,
    },
  },
  discountTitle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 16,
    textAlign: "center",
    color: "#2E3A59",
    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
      marginTop: 10,
    },
  },
  discountText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 12,
    textAlign: "center",
    color: "#2E3A59",
    margin: "10px 0",
    [theme.breakpoints.up("sm")]: {
      fontSize: 12,
    },
  },
  dialogContent: {
    padding: 0,
    paddingTop: "0 !important",
  },
  content: {
    padding: "0 24px",
  },
  hatsImg: {
    width: "100%",
    marginBottom: "-4px",
  },
  dialogPaper: {
    borderRadius: 20,
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },
  button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
  },
  icon: {
    marginBottom: 2,
  },
  numContainer: {
    display: "flex",
    textAlign: "center",
    width: "100%",
    justifyContent: "space-evenly",
  },
  num: {
    color: "#ff6327",
    background: "#ffd2c3",
    margin: "auto",
    fontSize: 24,
    fontFamily: "Montserrat",
    borderRadius: "100%",
    width: 35,
    height: 35,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  numTitle: {
    color: "#FF6327",
    fontSize: 17,
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  numText: {
    color: "#2E3A59",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  numBox: {
    width: "33%",
  },
});

class ReferralDialog extends Component {
  render() {
    const { classes, t, open, onCancel, userPlans } = this.props;
    const { referralCode, loadingReferralCode, referralCodeError } = userPlans;
    const shareUrl = "https://app.vert.run";

    const detectMob = () => {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    };

    return (
      <div>
        <Dialog
          open={open && !referralCodeError}
          onClose={onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.dialogPaper }}
          maxWidth="sm"
        >
          <DialogContent className={classes.dialogContent}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={onCancel}
              />
            </div>
            <div className={classes.content}>
              <Typography variant="body1" className={classes.gearTitle}>
                {t("Invite friends and enter to win")}
              </Typography>

              <Typography variant="body1" className={classes.gearText2}>
                {t("Give $10 and get $10")}
              </Typography>

              {(navigator.share && detectMob()) ||
              Capacitor.isNativePlatform() ? (
                <div className={classes.codeContainer}>
                  <div className={classes.code}>
                    {loadingReferralCode ? (
                      <CircularProgress
                        variant="indeterminate"
                        className={classes.loading}
                      />
                    ) : (
                      <Typography variant="body1" className={classes.codeText}>
                        {referralCode}
                      </Typography>
                    )}
                  </div>

                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.shareButton}
                    onClick={async () => {
                      await Share.share({
                        title: t("share title"),
                        text: t("share message no name", {
                          code: referralCode,
                        }),
                        url: shareUrl,
                        dialogTitle: t("Invite a friend"),
                      });
                    }}
                    disabled={loadingReferralCode}
                  >
                    {t("Share Vert with friends!")}
                  </Button>
                </div>
              ) : (
                <Grid container>
                  <Grid item xs={6} className={classes.pane}>
                    <div className={classes.codeContainerWeb}>
                      <FacebookShareButton
                        url={shareUrl}
                        quote={t("share message no name", {
                          code: referralCode,
                        })}
                        disabled={loadingReferralCode}
                        className={classes.button}
                      >
                        <FacebookIcon
                          size={32}
                          round
                          className={classes.icon}
                        />

                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.shareButton}
                          onClick={() => {}}
                        >
                          {t("Invite with Facebook")}
                        </Button>
                      </FacebookShareButton>
                    </div>
                  </Grid>

                  <Grid item xs={6} className={classes.pane}>
                    <div className={classes.codeContainerWeb}>
                      <EmailShareButton
                        url={shareUrl}
                        subject={t("share title")}
                        body={t("share message", { code: referralCode })}
                        disabled={loadingReferralCode}
                        className={classes.button}
                      >
                        <EmailIcon size={32} round className={classes.icon} />

                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.shareButton}
                          onClick={() => {}}
                        >
                          {t("Invite with Email")}
                        </Button>
                      </EmailShareButton>
                    </div>
                  </Grid>

                  <Grid item xs={6} className={classes.pane}>
                    <div className={classes.codeContainerWeb}>
                      <WhatsappShareButton
                        url={shareUrl}
                        title={t("share message", { code: referralCode })}
                        separator=" "
                        disabled={loadingReferralCode}
                        className={classes.button}
                      >
                        <WhatsappIcon
                          size={32}
                          round
                          className={classes.icon}
                        />

                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.shareButton}
                          onClick={() => {}}
                        >
                          {t("Invite with Whatsapp")}
                        </Button>
                      </WhatsappShareButton>
                    </div>
                  </Grid>

                  <Grid item xs={6} className={classes.pane}>
                    <div className={classes.codeContainerWeb}>
                      <TwitterShareButton
                        url={shareUrl}
                        title={t("share message no name", {
                          code: referralCode,
                        })}
                        disabled={loadingReferralCode}
                        className={classes.button}
                      >
                        <TwitterIcon size={32} round className={classes.icon} />

                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.shareButton}
                          onClick={() => {}}
                        >
                          {t("Invite with Twitter")}
                        </Button>
                      </TwitterShareButton>
                    </div>
                  </Grid>

                  <Grid item xs={12} className={classes.pane}>
                    <div className={classes.codeContainerWeb}>
                      <div className={classes.code}>
                        {loadingReferralCode ? (
                          <CircularProgress
                            variant="indeterminate"
                            className={classes.loading}
                          />
                        ) : (
                          <Typography
                            variant="body1"
                            className={classes.codeText}
                          >
                            {referralCode}
                          </Typography>
                        )}
                      </div>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.shareButton}
                        onClick={() => {
                          navigator.clipboard.writeText(referralCode);
                        }}
                        disabled={loadingReferralCode}
                      >
                        {t("Copy your code")}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}

              <Typography variant="body1" className={classes.discountText}>
                {t("Share your code with friends")}
              </Typography>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

ReferralDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withTranslation("dashboard")(
    withWidth()(withStyles(styles, { withTheme: true })(ReferralDialog)),
  ),
);
