import { distance } from "jimp";
import moment from "moment";
import { getPace, convertUnits } from "./units-helper";

const ACTIVITY_TYPES = {
  VERT_RUN: "vert.run",
  GARMIN: "garmin",
  SUUNTO: "suunto",
  STRAVA: "strava",
  HEALTHKIT: "healthKit",
  COROS: "coros",
};

const standarizeActivities = (activities, device) => {
  let activitiesStandard = [];
  if (device === "suunto") {
    activitiesStandard = activities.map((activity) => {
      if (activity.device === "vert.run") return activity;
      return {
        ...activity,
        total_elevation_gain: activity.totalAscent,
        distance: activity.totalDistance,
        elapsed_time: activity.totalTime,
        start_date: new Date(activity.startTime).toISOString(),
        id: activity.workoutId,
        type:
          activity.activityId === 1 ||
          activity.activityId === 22 ||
          activity.activityId === 59
            ? "run"
            : "other",
      };
    });
  } else if (device === "coros") {
    activitiesStandard = activities.map((activity) => {
      if (activity.device === "vert.run") return activity;
      return {
        ...activity,
        elapsed_time: activity.duration,
        start_date: new Date(activity.startTime).toISOString(),
        id: activity.labelId,
        type:
          activity.activityType === "RUNNING" ||
          activity.activityType === "TRAIL RUNNING"
            ? "run"
            : "other",
        averageHeartRateInBeatsPerMinute: activity.avgHeartRate,
        activeKilocalories: activity.calorie,
      };
    });
  }
  return activitiesStandard;
};

const calculateDatesDifference = (dateA, dateB) => {
  const startDate = moment(dateA);
  const endDate = moment(dateB);
  const difference = endDate.diff(startDate);
  const result = Math.ceil(difference / 1000);
  return !isNaN(result) ? result : 0;
};

const standarizeHealtkit = (activities = []) => {
  if (!activities && !activities.length) return [];
  const result = [...activities].map((activity) => {
    const time = calculateDatesDifference(activity.startDate, activity.endDate);
    return {
      ...activity,
      distance: activity.totalDistance,
      start_date: new Date(activity.startDate).toISOString(),
      end_date: new Date(activity.endDate).toISOString(),
      date: new Date(activity.startDate).toISOString(),
      type: activity.workoutActivityName,
      sport_type: activity.workoutActivityName,
      name: `Apple - ${activity.workoutActivityName}`,
      device: "healthKit",
      elapsed_time: calculateDatesDifference(
        activity.startDate,
        activity.endDate,
      ),
      elevation: activity.elevation || 0,
      pace: getPace(time, activity.totalDistance),
      calories: activity.totalEnergyBurned || 0,
      time: calculateDatesDifference(activity.startDate, activity.endDate),
    };
  });
  return result;
};

const healthKitStandardDetail = (activity) => {
  const unit = localStorage.getItem("units");
  const activityCopy = { ...activity };
  activityCopy.distance = convertUnits(
    activityCopy.distance / 1000,
    "kms",
    unit,
    "distance",
  );
  return activityCopy;
};

export {
  ACTIVITY_TYPES,
  standarizeActivities,
  standarizeHealtkit,
  healthKitStandardDetail,
};
