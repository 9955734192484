import ActionTypes from "../constants/action-types";
import AdminService from "../services/admin-service";

export const getAdminData = (status = "", start_date = "2023-06-26") => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_CUSTOMERS_DASHBOARD_PENDING });
    try {
      const result = await AdminService.getAdminData(status, start_date);
      dispatch({
        type: ActionTypes.GET_CUSTOMERS_DASHBOARD_FULLFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.GET_CUSTOMERS_DASHBOARD_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

export const getAdminCoachesData = (
  status = "",
  start_date = "2023-06-26",
  coachesQuery = true,
) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_CUSTOMERS_DASHBOARD_PENDING });
    try {
      const result = await AdminService.getAdminData(
        status,
        start_date,
        coachesQuery,
      );
      dispatch({
        type: ActionTypes.GET_CUSTOMERS_DASHBOARD_CUSTOMERS_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.GET_CUSTOMERS_DASHBOARD_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

export const getAdminCategoriesData = (
  status = "",
  start_date = "2023-06-26",
  coachesQuery = false,
  subcategoriesQuery = true,
) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_CUSTOMERS_DASHBOARD_PENDING });
    try {
      const result = await AdminService.getAdminData(
        status,
        start_date,
        coachesQuery,
        subcategoriesQuery,
      );
      dispatch({
        type: ActionTypes.GET_DASHBOARD_SUBCATEGORIES_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.GET_CUSTOMERS_DASHBOARD_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

export const clearAdminDashboard = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_ADMIN_DASHBOARD,
      payload: null,
    });
  };
};
