import React from "react";
import { Typography, Box, makeStyles, IconButton } from "@material-ui/core";
import {
  CalendarIcon,
  DistanceIcon,
  ElevationIcon,
  TimerIcon,
  LightningIcon,
  EditIcon,
  DeleteIcon,
  ShareIcon,
} from "../../icons";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { palette } from "../../../theme/palette";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import UnitsHook from "../../../hooks/unitsHook";
import { convertUnits, round } from "../../../lib/units-helper";
import CardTemplate from "../../common/v2/CardTemplate";
import ToggleOptions from "../../common/v2/ToggleOptions";
import ListItemWithContent from "../../common/v2/ListItemWithContent";
import { Link } from "react-router-dom";
import useCalculatorMI from "../../../hooks/useCalculatorMI";
import { getPace, getMilesPace } from "../../../lib/units-helper";
import CustomErrorBoundary from "../../../components/common/error/CustomErrorBoundary";

const useStyles = makeStyles({
  a: {
    backgroundColor: "#F93C6E",
  },
  b: {
    backgroundColor: "#4780AB",
  },
  c: {
    backgroundColor: "#6BB488",
  },
  circle: {
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginRight: "8px",
  },
  circleText: {
    color: palette.white,
    fontWeight: "bold",
  },
  texta: {
    color: "#F93C6E",
  },
  textb: {
    color: "#4780AB",
  },
  textc: {
    color: "#6BB488",
  },
  leftText: {
    marginLeft: "8px",
  },
  rightText: {
    color: palette.deactivated,
    textAlign: "right",
  },
  mountainIndex: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    marginTop: "20px",
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "23px",
    letterSpacing: "0em",
  },
  popover: {
    padding: "12px 16px",
    boxSizing: "border-box",
  },
  popoverParent: {
    backgroundColor: "green",
  },
  link: {
    color: palette.black,
    textDecoration: "nonse",
  },
  raceName: {
    wordBreak: "break-all",
    fontWeight: "700",
    maxWidth: "80%",
  },
  infoIcon: {
    color: palette.deactivated,
    width: "14px",
    marginLeft: 8,
  },
});

export const ActivityCard = ({
  activity,
  selectActivity,
  handleDetailActivity,
  handleEdit,
  handleDelete,
  enableActions = {
    share: true,
    menu: true,
    mountainIndex: true,
  },
}) => {
  const { t } = useTranslation("profile");
  const units = UnitsHook();
  const classes = useStyles();
  const { calculateValue } = useCalculatorMI();

  const loadIconByType = () => {
    let activityIcon = "icon_activity_run";
    const activityType = activity.activityType || activity.type || "";
    if (
      activityType.toLowerCase().includes("run") &&
      !activityType.toLowerCase().includes("transition")
    ) {
      activityIcon = "icon_activity_run";
    } else if (
      (activityType.toLowerCase().includes("ride") ||
        activityType.toLowerCase().includes("bik") ||
        activityType.toLowerCase().includes("cycl")) &&
      !activityType.toLowerCase().includes("transition")
    ) {
      activityIcon = "icon_activity_bike";
    } else if (
      activityType.toLowerCase().includes("swim") &&
      !activityType.toLowerCase().includes("transition")
    ) {
      activityIcon = "icon_activity_swim";
    } else if (
      (activityType.toLowerCase().includes("walk") ||
        activityType.toLowerCase().includes("hik")) &&
      !activityType.toLowerCase().includes("transition")
    ) {
      activityIcon = "icon_activity_hike";
    } else if (
      (activityType.toLowerCase().includes("crossfit") ||
        activityType.toLowerCase().includes("weight") ||
        activityType.toLowerCase().includes("yoga") ||
        activityType.toLowerCase().includes("strength") ||
        activityType.toLowerCase().includes("fitness")) &&
      !activityType.toLowerCase().includes("transition")
    ) {
      activityIcon = "icon_activity_strength";
    }
    return activityIcon;
  };

  const loadPace = () => {
    const movingTime = activity.moving_time || activity.elapsed_time || 0;
    const distance = activity.distance || 0;
    const paceValue =
      units === "kms"
        ? getPace(movingTime, distance)
        : getMilesPace(movingTime, distance);
    return paceValue;
  };

  const loadTime = () => {
    const movingTime = activity.moving_time || activity.elapsed_time || 0;
    const h = movingTime ? Math.floor(movingTime / 3600) : 0;
    const m = movingTime ? Math.floor((movingTime % 3600) / 60) : 0;
    const timeStr = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;
    return timeStr;
  };

  const handleDateFormat = (date) => {
    if (!date) return;
    return dayjs(date).format("MMM D, YYYY");
  };

  const handleShare = (activity) => {
    selectActivity(activity);
  };

  const actionsGrantedByDevice = (activity) => {
    if (activity.device && activity.device !== "vert.run") {
      return [
        {
          title: t("profile.card.options.share"),
          icon: <ShareIcon />,
          onClick: () => handleShare(activity),
        },
      ];
    }

    return [
      {
        title: t("profile.card.options.edit"),
        icon: <EditIcon />,
        onClick: () => handleEdit(activity),
      },
      {
        title: t("profile.card.options.delete"),
        icon: <DeleteIcon />,
        onClick: () => handleDelete(activity),
      },
      {
        title: t("profile.card.options.share"),
        icon: <ShareIcon />,
        onClick: () => handleShare(activity),
      },
    ];
  };

  return (
    <CustomErrorBoundary>
      <CardTemplate
        onClick={(event) => handleDetailActivity(event, activity)}
        title={
          activity.name || activity.activityName || activity.deviceName || ""
        }
        avatar={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/${loadIconByType()}.svg`}
              alt={loadIconByType()}
              className={classes.activityIcon}
            />
          </Box>
        }
        actions={
          <>
            {enableActions.share && (
              <IconButton
                aria-label="share"
                onClick={() => handleShare(activity)}
              >
                <ShareIcon />
              </IconButton>
            )}
            {enableActions.menu && (
              <ToggleOptions actions={actionsGrantedByDevice(activity)} />
            )}
          </>
        }
        FooterComponent={
          <Typography className={classes.mountainIndex}>
            {t("mountainIndex.title", { ns: "mountainIndex" })} =
            <span style={{ marginLeft: 6 }}>
              {calculateValue(
                round(
                  convertUnits(activity.distance, "kms", units, "distance") /
                    1000,
                  1,
                ),
                round(
                  convertUnits(
                    activity.total_elevation_gain || 0,
                    "kms",
                    units,
                    "elevation",
                  ),
                  1,
                ),
              )}
            </span>
            {enableActions.mountainIndex && (
              <Link id="mountain_index_info" to="/profile/mountain-index">
                <InfoIcon className={classes.infoIcon} />
              </Link>
            )}
          </Typography>
        }
      >
        <ListItemWithContent
          leftContent={
            <>
              <CalendarIcon />
              <Typography color="text.secondary">
                {t("profile.card.date")}
              </Typography>
            </>
          }
          rightContent={
            <>
              <Typography className={classes.rightText}>
                {handleDateFormat(
                  activity.start_date_local
                    ? activity.start_date_local
                    : activity.start_date,
                )}
              </Typography>
            </>
          }
        />
        <ListItemWithContent
          leftContent={
            <>
              <TimerIcon />
              <Typography color="text.secondary">
                {t("profile.card.duration")}
              </Typography>
            </>
          }
          rightContent={
            <>
              <Typography className={classes.rightText}>
                {loadTime()}
              </Typography>
            </>
          }
        />
        <ListItemWithContent
          leftContent={
            <>
              <DistanceIcon />
              <Typography color="text.secondary">
                {t("profile.card.distance")}
              </Typography>
            </>
          }
          rightContent={
            <>
              <Typography className={classes.rightText}>
                {round(
                  convertUnits(activity.distance, "kms", units, "distance") /
                    1000,
                  1,
                )}{" "}
                {units === "kms" ? "km" : "miles"}
              </Typography>
            </>
          }
        />
        <ListItemWithContent
          leftContent={
            <>
              <ElevationIcon />
              <Typography color="text.secondary">
                {t("profile.card.elevation")}
              </Typography>
            </>
          }
          rightContent={
            <>
              <Typography className={classes.rightText}>
                {round(
                  convertUnits(
                    activity.total_elevation_gain,
                    "kms",
                    units,
                    "elevation",
                  ),
                  1,
                )}{" "}
                {units === "kms" ? "m" : "feet"}
              </Typography>
            </>
          }
        />
        <ListItemWithContent
          leftContent={
            <>
              <LightningIcon />
              <Typography color="text.secondary">
                {t("profile.card.avgPace")}
              </Typography>
            </>
          }
          rightContent={
            <>
              <Typography className={classes.rightText}>
                {`${loadPace().minutes}:${(loadPace().seconds < 10 ? "0" : "") +
                  loadPace().seconds}`}{" "}
                {units === "miles" ? `${"miles"}/h` : `${"km"}/h`}
              </Typography>
            </>
          }
        />
      </CardTemplate>
    </CustomErrorBoundary>
  );
};
