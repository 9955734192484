import React, { Component } from "react";
import PropTypes from "prop-types";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import classNames from "classnames";
import { Redirect } from "react-router-dom";
import theme from "./layout/theme";
import Dashboard from "./dashboard";
import ChoosePlan from "./choose-plan";
import SignOut from "./sign-out";
import Footer from "./layout/footer";
import AppBar from "./layout/app-bar";
import PrivateRoute from "./private-route";
import RedirectToAuth from "./common/redirect-to-auth";
import PlanQuestionnaire from "./choose-plan/plan-questionnaire";
import Workouts from "./workouts";
import TrainingViewer from "./training-viewer";
import Trainings from "./trainings";
import Athletes from "./athletes";
import AthletesDetail from "./athletes-detail";
import AthleteTrainingViewer from "./athlete-training-viewer";
import SubscriptionFinalized from "./subscription/subscription-finalized";
import ConnectAuth from "../connectors/connect-auth";
import ConnectPusher from "../connectors/connect-pusher";
import UserProfile from "./user-profile";
import AthleteAssignTraining from "./athletes/athlete-plan/athlete-assign-training";
import AuthLanding from "./auth-landing";
import TrailHead from "./trail-head";
import Explore from "./explore";
import ExploreTag from "./explore/explore-tag";
import ExploreFullPost from "./explore/explore-full-post";
import Challenges from "./challenges";
import "@ashwamegh/react-link-preview/dist/index.css";
import PlanSuggester from "./choose-plan/plan-suggester";
import PlanComplete from "./choose-plan/plan-complete";
import SuggesterResults from "./suggester-results";
import { Capacitor } from "@capacitor/core";
import { Link, Typography } from "@material-ui/core";
import { I18n } from "aws-amplify";
import externalLinksEn from "../constants/external-links-en";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import GooogleSingInSignOut from "./gooogle-sing-in-sign-out";
import PlanPreSignup from "./choose-plan/plan-pre-signup";
import { logEvent, setEventsMetadata } from "../lib/events-helper";
import ConnectUserPlans from "../connectors/connect-user-plans";
import { storageFactory } from "../lib/storage-factory";
import { Preferences } from "@capacitor/preferences";
import Tracker from "./dashboard/dashboard-gps/views/tracker";
import SaveActivity from "./dashboard/dashboard-gps/views/save-activity";
import { Routes } from "../lib/routing-middleware";
import WorkoutEditor from "./workouts/workout-editor";
import ChoosePlanSkyRuning from "./choose-plan-skyrunning";
import ChoosePlanUtmb from "./choose-plan-utmb";
import ChoosePlanXterra from "./choose-plan-xterra";
import LabDashboard from "./lab-dashboard";
import { connect } from "react-redux";
import { getLatestSubscription } from "../actions/subscriptions-action";
import AdminDashboard from "./admin-dashboard/components/layout";
import RunTheAlps from "./run-the-alps";
import PodcastList from "./generals/podcast-list";
import VideosList from "./generals/all-videos-list";
import MobileLayout from "../app/mobile";
import DesktopLayout from "../app/desktop";
import trainingEditor from "./trainings/training-editor";

const styles = {
  containerNoOverflow: {
    width: "100%",
    minHeight: "100%",
    display: "flex !important",
    flexDirection: "column",
  },
  container: {
    width: "100%",
    minHeight: "100%",
    display: "flex !important",
    flexDirection: "column",
    overflow: "hidden",
  },
  main: {
    display: "flex",
    flexGrow: 1,
    flexShrink: 0,
    flexBasic: "auto",
  },
  background: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  signUp: {
    backgroundImage: "none",
  },
  signIn: {
    backgroundImage: "none",
  },
  iosHeader: {
    zIndex: 100000,
    position: "fixed",
    top: 0,
    background: "#fff",
  },
  appBanner: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
  },
  appIcon: {
    height: 64,
    padding: "0 10px",
  },
  appBannerText: {
    fontSize: 14,
    marginLeft: 10,
  },
  install: {
    marginLeft: "auto",
    color: "#ff6327",
    textDecoration: "none",
  },
};

class Layout extends Component {
  state = {
    time: null,
    showUpdateBlocked: false,
  };

  async componentDidUpdate(prevProps) {
    const { isAuthenticated } = this.props.auth;
    if (prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated) {
      if (isAuthenticated) this.props.dispatch(getLatestSubscription());
    }
  }

  async componentDidMount() {
    if (Capacitor.isNativePlatform()) {
      // "version": "1.3.25"
      try {
        localStorage.setItem("version", "1.3.25");
        localStorage.setItem("showUpdate", "0");
      } catch (e) {
        console.log(e);
      }

      if (Capacitor.getPlatform() === "ios") {
        const { value } = await Preferences.get({ key: "currentGoal" });
        if (value) {
          storageFactory().setItem("currentGoal", +value);
        }

        await Preferences.remove({ key: "currentGoal" });
        setEventsMetadata({ device: "ios" });
        logEvent("open_the_app");
      } else if (Capacitor.getPlatform() === "android") {
        setEventsMetadata({ device: "android" });
        logEvent("open_the_app");
      }
    } else {
      setEventsMetadata({ device: "web" });
      logEvent("open_the_app");
    }

    this.props.currentUserLoad(Auth.currentAuthenticatedUser());

    window.onfocus = () => {
      // this.resetTimer();
      const isOnDashboard =
        window.location.pathname &&
        window.location.pathname.startsWith("/dashboard");
      if (isOnDashboard) {
        if (navigator.onLine) {
          const { userPlans } = this.props;
          const { loading, saving, updating, loadingPlan } = userPlans || {};

          const { auth } = this.props;
          const { isAuthenticated, currentUserSigningOut } = auth;

          if (isAuthenticated && !currentUserSigningOut) {
            if (!loading && !saving && !updating && !loadingPlan) {
              this.props.currentUserSilentLoad();
            }
          }
        }
      }
    };

    // document.onmousemove = this.resetTimer;
    // document.onkeypress = this.resetTimer;
  }

  // resetTimer = () => {
  //   if (this.state.time) {
  //     clearTimeout(this.state.time);
  //   }
  //   const time = setTimeout(() => {
  //     const isOnDashboard =
  //       window.location.pathname &&
  //       window.location.pathname.startsWith("/dashboard");

  //     const { auth } = this.props;
  //     const { isAuthenticated, currentUserSigningOut } = auth;

  //     if (isAuthenticated && !currentUserSigningOut) {
  //       if (isOnDashboard) {
  //         if (navigator.onLine) {
  //           const { userPlans } = this.props;
  //           const { loading, saving, updating, loadingPlan } = userPlans || {};

  //           if (!loading && !saving && !updating && !loadingPlan) {
  //             this.props.currentUserSilentLoad();
  //           }
  //         }
  //         this.resetTimer();
  //       }
  //     }
  //   }, 15 * 60 * 1000);
  //   this.setState({
  //     ...this.state,
  //     time,
  //   });
  // };

  async componentWillUnmount() {
    this.props.unsubscribe();
  }

  handleCloseAppBanner = () => {
    this.props.setShowUpate(false);
    this.setState({
      ...this.state,
      showUpdateBlocked: true,
    });
    setTimeout(() => {
      this.setState({
        ...this.state,
        showUpdateBlocked: false,
      });
    }, 15 * 60 * 1000);
  };

  render() {
    const {
      classes,
      auth: { isAuthenticated, page, showUpdate },
      location: { pathname } = {},
      width,
    } = this.props;

    const isInMobileViewSm = width === "sm" || width === "xs";

    const isOnChoosingPlan = pathname && pathname.startsWith("/chooseplan");
    const isOnSubscription = pathname && pathname.startsWith("/subscription");
    const isOnDashboard = pathname && pathname.startsWith("/dashboard");
    const isOnProfile = pathname && pathname.startsWith("/profile");
    const isOnExplore = pathname && pathname.startsWith("/explore");
    const isOnAuth = pathname && pathname.startsWith("/auth");
    const isOnTracking = pathname && pathname.startsWith("/tracking");
    const isOnUtmb = pathname && pathname.startsWith("/chooseplan-utmb");
    const isOnSkyrunner = pathname && pathname.startsWith("/chooseplan-sws");
    const isOnXterra = pathname && pathname.startsWith("/chooseplan-xterra");
    const showChoosePlanHeader =
      pathname &&
      (pathname.includes("/chooseplan") ||
        pathname.startsWith("/subscription"));
    const isOnTrailhead = pathname && pathname.startsWith("/trailhead");
    const isOnChallenges = pathname && pathname.startsWith("/challenges");
    const isOnSuggesterResults =
      pathname && pathname.startsWith("/suggester/results");

    const isOnSuggester = pathname && pathname.startsWith("/suggester");
    const isOnPreSignUp = pathname && pathname.startsWith("/presignup");
    const logoShouldTurnWhiteOnMobileView = !isAuthenticated;
    const choosePlanVariant =
      pathname.includes("chooseplan/coach-choose") ||
      pathname.includes("chooseplan/explore") ||
      pathname.includes("subscription/explore") ||
      pathname.includes("chooseplan/free") ||
      pathname.includes("subscription/premium");

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classNames(classes.iosHeader)}></div>
        <div
          className={classNames(
            !pathname.startsWith("/chooseplan")
              ? classes.container
              : classes.containerNoOverflow,
          )}
          data-testid="authenticator"
        >
          {showUpdate && !this.state.showUpdateBlocked && (
            <div className={classes.appBanner}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseAppBanner}
              />

              <img
                src={`${process.env.PUBLIC_URL}/appicon.png`}
                alt=""
                className={classes.appIcon}
              />

              <Typography variant="body1" className={classes.appBannerText}>
                {I18n.get(
                  Capacitor.getPlatform() === "android"
                    ? "There is a new version available"
                    : "There is a new version available",
                )}
              </Typography>

              <Link
                className={classes.install}
                href={
                  Capacitor.getPlatform() === "android"
                    ? externalLinksEn.AndroidApp
                    : externalLinksEn.iOSApp
                }
                target="_blank"
              >
                <Typography variant="body1" className={classes.install}>
                  {I18n.get("Update")}
                </Typography>
              </Link>
            </div>
          )}
          {!isOnDashboard &&
          !isOnProfile &&
          !isOnChallenges &&
          !isOnSuggester &&
          !isOnSuggesterResults &&
          !isOnPreSignUp &&
          (!isOnAuth || isInMobileViewSm) &&
          !pathname.includes("/google") &&
          !(
            pathname.includes("/subscription") && Capacitor.isNativePlatform()
          ) &&
          !pathname.includes("/lab") &&
          !pathname.includes("/admin-dashboard") ? (
            <AppBar
              isNotOnChoosingPlanPage={!isOnChoosingPlan}
              isOnDashboard={isOnDashboard}
              isOnSubscription={pathname.includes("/subscription/")}
              showLanguageMenu={!isAuthenticated}
              showChoosePlanHeader={showChoosePlanHeader}
              whiteLogoOnMobileView={logoShouldTurnWhiteOnMobileView}
              isOnSubscriptionPage={isOnSubscription}
              isOnTrailhead={isOnTrailhead}
              isOnSuggesterResults={isOnSuggesterResults}
              isOnExplore={isOnExplore}
              isOnTracking={isOnTracking}
              isOnUtmb={isOnUtmb}
              isOnSkyrunner={isOnSkyrunner}
              isOnChoosingPlan={isOnChoosingPlan}
              choosePlanVariant={choosePlanVariant}
              isOnXterra={isOnXterra}
            />
          ) : (
            ""
          )}

          {/* new version routes */
          isInMobileViewSm || Capacitor.isNativePlatform() ? (
            <>
              <MobileLayout />
            </>
          ) : (
            <>
              <PrivateRoute exact path="/trainings" component={Trainings} />
              <PrivateRoute
                exact
                path="/trainings/editor"
                component={trainingEditor}
              />
              <PrivateRoute
                path="/training/:id?/:training_order?"
                component={TrainingViewer}
              />
              <DesktopLayout />
              <Route exact path="/auth" component={AuthLanding} />
              <Route exact path="/auth/:action" component={AuthLanding} />
              <PrivateRoute
                exact
                path="/chooseplan/:selectedPlan/:id?"
                component={PlanQuestionnaire}
              />
              <Route exact path="/presignup" component={PlanPreSignup} />
              <Route exact path="/suggester" component={PlanSuggester} />
              <PrivateRoute
                exact
                path="/suggester/results"
                component={SuggesterResults}
              />
              <PrivateRoute
                exact
                path="/subscription"
                component={SubscriptionFinalized}
              />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <Route exact path="/chooseplan" component={ChoosePlan} />
              <Route path="/chooseplan-sws" component={ChoosePlanSkyRuning} />
              <Route path="/chooseplan-utmb" component={ChoosePlanUtmb} />
              <Route path="/chooseplan-xterra" component={ChoosePlanXterra} />
              <Route path="/all-videos" component={VideosList} />
              <Route exact path="/explore" component={Explore} />
              <Route exact path="/explore/tag/:id" component={ExploreTag} />
              <Route
                exact
                path="/explore/post/:id"
                component={ExploreFullPost}
              />
            </>
          )}

          <main>
            {/* old version routes */
            !(isInMobileViewSm || Capacitor.isNativePlatform()) && (
              <>
                <PrivateRoute path="/profile" component={UserProfile} />
              </>
            )}

            <Route
              exact
              path="/"
              render={(props) => (
                <Redirect
                  to={{
                    pathname: "/dashboard",

                    state: { from: props.location },
                  }}
                />
              )}
            />
            <Route
              exact
              path="/authswitch/:authState"
              component={RedirectToAuth}
            />
            <Route path="/challenges" component={Challenges} />
            <Route path="/tracking/tracker" component={Tracker} />
            <Route path="/tracking/save/:effort" component={SaveActivity} />

            <Route path="/all-podcast" component={PodcastList} />
            <Route path="/run-the-alps" component={RunTheAlps} />

            <PrivateRoute
              exact
              path="/complete-profile"
              component={PlanComplete}
            />

            <PrivateRoute path="/workouts" component={Workouts} />
            <PrivateRoute
              path={Routes.WorkoutEditor()}
              component={WorkoutEditor}
            />
            <PrivateRoute exact path="/athletes" component={Athletes} />
            <PrivateRoute
              exact
              path="/athletes/:id"
              component={AthletesDetail}
            />
            <Route
              exact
              path="/athletes/assign"
              component={AthleteAssignTraining}
            />
            <PrivateRoute
              path="/athlete/training/edit"
              component={AthleteTrainingViewer}
            />
            <PrivateRoute path="/signout" component={SignOut} />
            <Route
              path="/googlesigninsignout/:authlink"
              component={GooogleSingInSignOut}
            />
            <PrivateRoute path="/admin-dashboard" component={AdminDashboard} />
            <Route path="/lab" component={LabDashboard} />
          </main>
          {!(Capacitor.isNativePlatform() || isInMobileViewSm) &&
          !pathname.includes("/lab") &&
          !pathname.includes("/subscription/") &&
          !pathname.includes("/admin-dashboard") ? (
            <Footer isAuthenticated={isAuthenticated} />
          ) : null}
        </div>
      </MuiThemeProvider>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  location: PropTypes.object,
  currentUserLoad: PropTypes.func.isRequired,
  currentUserSilentLoad: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    auth: store.auth,
  };
})(
  ConnectUserPlans(
    ConnectAuth(
      ConnectPusher(
        withTranslation("layout")(withWidth()(withStyles(styles)(Layout))),
      ),
    ),
  ),
);
