import React, { memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { Button, Typography } from "@material-ui/core";

const styles = (theme) => ({
  main: {
    backgroundColor: "white",
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    margin: 10,
  },
  email: {
    fontSize: "14px",
  },
  button: {
    marginLeft: "auto",
  },
});

const PublicGroupRequests = ({ classes, requests, onAccept }) => {
  return (
    <div className={classes.main}>
      <Typography className={classes.title}>Requests to Join</Typography>
      {requests.map((r) => (
        <div className={classes.item} key={r.cognito_user_sub}>
          <Typography className={classes.email}>
            {r.cognito_user_email}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => onAccept(r.cognito_user_sub)}
          >
            Accept
          </Button>
        </div>
      ))}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("trailhead")(memo(PublicGroupRequests)),
);
