import React from "react";
import {
  Paper,
  Grid,
  Typography,
  makeStyles,
  Box,
  LinearProgress,
} from "@material-ui/core";

import { closestCenter, DndContext, DragOverlay } from "@dnd-kit/core";
import { SortableContext, rectSwappingStrategy } from "@dnd-kit/sortable";

import DateRange from "./DateRange";
import { palette } from "../../../theme/palette";
import WorkoutCard from "./WorkoutCard";
import ModalCustom from "./ModalCustom";
import CustomButton from "./CustomButton";
import Skeleton from "react-loading-skeleton";
import TrainingDay from "./TrainingDay";
import WorkoutDate from "./WorkoutDate";
import {
  LightWorkoutColors,
  WorkoutTypesLangKeys,
} from "../../../constants/workout-types";
import { useTranslation } from "react-i18next";
import { convertMinutesToTimeLabel } from "../../../constants/estimated-times";
import { GridItem, SortableGridItem } from "./WorkoutSortable";
import HighlightText from "./HighlightText";
import PaywallModal from "./PaywallModal";
import AddEasyWeek from "./AddEasyWeek";
import RedoWeek from "./RedoWeek";
import AddWorkout from "./AddWorkout";
import { useTrainingContext } from "../../../context/TrainingContext";
import useUserInfo from "../../../hooks/useUserInfo";
import useWeeklyWorkout from "../../../hooks/useWeeklyWorkout";
import dayjs from "dayjs";
import CardNeedAPlan from "./CardNeedAPlan";
import useWorkout from "../../../hooks/useWorkout";
import { NOT_WORKOUT } from "../../../hooks/useWorkout";
import { useChatContext } from "../../../context/ChatProvider";
import InfoNote from "./InfoNote";
import { IconMessageCircle2 } from "@tabler/icons-react";

const TrainingWeek = () => {
  const classes = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation("training");
  const { dateFrom, dateTo, setWeekRange } = useTrainingContext();
  const { openChat, setWorkoutToShare } = useChatContext();

  const {
    currentTraining,
    chainedPlans,
    currentPlan,
    isSubscribed,
  } = useUserInfo();
  const {
    workoutSelected,
    isOpenWorkout,
    openWorkout,
    onCloseOpenWorkout,
    getFixCustomLevel,
  } = useWorkout();
  const {
    workoutWeekly,
    workoutSwap,
    weekDates,
    loading,
    isOpenPaywall,
    isOpenWorkoutSwap,
    activeIndex,
    containerRef,
    sensors,
    hasNextWeekly,
    hasPreviousWeekly,
    setIsOpenPaywall,
    confirmSwapWorkout,
    cancelSwapWorkout,
    handleDragEnd,
    handleDragStart,
    getWeekDates,
  } = useWeeklyWorkout();

  if (!currentTraining) {
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.marginY}>
          <Skeleton height={40} width={"100%"} />
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            {Array.from({ length: 7 }).map((_, i) => (
              <Skeleton
                key={i}
                variant="rounded"
                width="90%"
                height={65}
                style={{ marginBottom: "8px" }}
              />
            ))}
          </Grid>
          <Grid item xs={10}>
            {Array.from({ length: 7 }).map((_, i) => (
              <Skeleton
                key={i}
                variant="rounded"
                width="100%"
                height={65}
                style={{ marginBottom: "8px" }}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (
    !(
      currentTraining.constructor === Object &&
      Object.keys(currentTraining).length
    )
  ) {
    const weekDatesLocal = getWeekDates(
      new Date(),
      new Date(dayjs().add(6, "day")),
    );
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: 15 }}>
            <DateRange type="week" disabled={true} />
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.marginY}>
          <CardNeedAPlan />
        </Grid>

        <Paper
          elevation={0}
          className={`${classes.marginB} ${classes.disabled}`}
        >
          <Grid container>
            {weekDatesLocal.length && (
              <Grid item xs={2}>
                {weekDatesLocal.map((date, index) => (
                  <WorkoutDate date={date} key={index} locale={language} />
                ))}
              </Grid>
            )}
            <Grid item xs={10}>
              <Grid container>
                {Array.from({ length: 7 }).map((item) => {
                  const LightColorsKeys = Object.keys(LightWorkoutColors);
                  const WorkoutTypesKeys = Object.keys(WorkoutTypesLangKeys);
                  const workout = {
                    variant:
                      LightColorsKeys[
                        Math.floor(LightColorsKeys.length * Math.random())
                      ],
                    primaryText: t(
                      WorkoutTypesLangKeys[
                        WorkoutTypesKeys[
                          Math.floor(WorkoutTypesKeys.length * Math.random())
                        ]
                      ],
                    ),
                    secondaryText: Array.from(["1h", "1h 30min", "2h"])[
                      Math.floor(
                        Math.random() *
                          Array.from(["1h", "1h 30min", "2h"]).length,
                      )
                    ],
                  };
                  return (
                    <div className={classes.dayColumn} key={item}>
                      <WorkoutCard
                        disabled={true}
                        variant={workout.variant}
                        primaryText={workout.primaryText}
                        secondaryText={workout.secondaryText}
                        isDone={true}
                        withCheck
                        margin={0}
                        height="66px"
                      />
                    </div>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Grid item xs={12} className={classes.marginB}>
          <InfoNote text={t("training.dnd.pressAndHold")} />
        </Grid>

        <Grid item xs={12} className={`${classes.marginY} ${classes.disabled}`}>
          <Grid container>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <AddEasyWeek disabled={true} />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <RedoWeek fromDate={dateFrom} toDate={dateTo} disabled={true} />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <AddWorkout disabled={true} />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Grid container>
      <PaywallModal
        isOpen={isOpenPaywall}
        onClose={() => setIsOpenPaywall(false)}
      />

      {loading && (
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <LinearProgress color="secondary" />
        </Grid>
      )}

      <Grid
        item
        xs={12}
        className={`${loading ? classes.disabled : null}`}
        style={{ marginTop: 15 }}
      >
        <DateRange
          type="week"
          onWeekChange={setWeekRange}
          disabled={loading}
          disablePrevious={!hasPreviousWeekly}
          disableNext={!hasNextWeekly}
        />
      </Grid>

      <Paper
        elevation={0}
        className={`${classes.marginB} ${loading ? classes.disabled : null}`}
        style={{ width: "100%" }}
      >
        <Grid container>
          {weekDates.length && (
            <Grid item xs={2}>
              {weekDates.map((date, index) => (
                <WorkoutDate date={date} key={index} locale={language} />
              ))}
            </Grid>
          )}
          <Grid item xs={10} ref={containerRef}>
            {workoutWeekly ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                <Grid container>
                  <SortableContext
                    items={workoutWeekly.map((workout) => workout.sortableId)}
                    strategy={rectSwappingStrategy}
                  >
                    {workoutWeekly.map((workout, index) => (
                      <SortableGridItem
                        key={workout.sortableId}
                        item={workout}
                        styles={activeIndex === index ? { opacity: 0 } : {}}
                        className={classes.dayColumn}
                      >
                        <WorkoutCard
                          variant={
                            workout && workout.workoutType
                              ? workout.workoutType
                              : "NoWorkout"
                          }
                          primaryText={
                            workout && workout.workoutType
                              ? workout.workoutType === "UserCreated"
                                ? workout.userCreatedType
                                : t(WorkoutTypesLangKeys[workout.workoutType])
                              : ""
                          }
                          secondaryText={
                            workout && workout.estimatedTime
                              ? convertMinutesToTimeLabel(
                                  Array.isArray(workout.estimatedTime) &&
                                    !isNaN(
                                      +workout.estimatedTime[
                                        getFixCustomLevel(workout.plannedDate)
                                      ],
                                    )
                                    ? +workout.estimatedTime[
                                        getFixCustomLevel(workout.plannedDate)
                                      ]
                                    : +workout.estimatedTime,
                                )
                              : ""
                          }
                          onClick={() => openWorkout(workout)}
                          isDone={workout ? workout.isDone : false}
                          height="66px"
                          withCheck
                        />
                      </SortableGridItem>
                    ))}
                  </SortableContext>
                  <DragOverlay>
                    {activeIndex != null ? (
                      <GridItem>
                        <WorkoutCard
                          variant={
                            workoutWeekly[activeIndex] &&
                            workoutWeekly[activeIndex].workoutType
                              ? workoutWeekly[activeIndex].workoutType
                              : "NoWorkout"
                          }
                          primaryText={
                            workoutWeekly[activeIndex] &&
                            workoutWeekly[activeIndex].workoutType
                              ? workoutWeekly[activeIndex].workoutType ===
                                "UserCreated"
                                ? workoutWeekly[activeIndex].userCreatedType
                                : t(
                                    WorkoutTypesLangKeys[
                                      workoutWeekly[activeIndex].workoutType
                                    ],
                                  )
                              : ""
                          }
                          secondaryText={
                            workoutWeekly[activeIndex] &&
                            workoutWeekly[activeIndex].estimatedTime
                              ? convertMinutesToTimeLabel(
                                  Array.isArray(
                                    workoutWeekly[activeIndex].estimatedTime,
                                  ) &&
                                    !isNaN(
                                      +workoutWeekly[activeIndex].estimatedTime[
                                        getFixCustomLevel(
                                          workoutWeekly[activeIndex]
                                            .plannedDate,
                                        )
                                      ],
                                    )
                                    ? +workoutWeekly[activeIndex].estimatedTime[
                                        getFixCustomLevel(
                                          workoutWeekly[activeIndex]
                                            .plannedDate,
                                        )
                                      ]
                                    : +workoutWeekly[activeIndex].estimatedTime,
                                )
                              : ""
                          }
                          onClick={() =>
                            openWorkout(workoutWeekly[activeIndex])
                          }
                          isDone={
                            workoutWeekly[activeIndex]
                              ? workoutWeekly[activeIndex].isDone
                              : false
                          }
                          withCheck
                          height="66px"
                        />
                      </GridItem>
                    ) : null}
                  </DragOverlay>
                </Grid>
              </DndContext>
            ) : (
              <Grid container>
                {Array.from({ length: 7 }).map((item, index) => (
                  <Grid item xs={12} className={classes.dayColumn} key={index}>
                    <Skeleton width={"100%"} height={72} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Grid item xs={12} className={classes.marginB}>
        <InfoNote text={t("training.dnd.pressAndHold")} />
      </Grid>

      <Grid
        item
        xs={12}
        className={`${classes.marginY} ${loading ? classes.disabled : null}`}
      >
        <Grid container>
          <Grid
            item
            xs={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <AddEasyWeek
              currentPlan={currentPlan}
              currentTraining={currentTraining}
              isSubscribed={isSubscribed}
              disabled={loading}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <RedoWeek
              currentTraining={currentTraining}
              currentPlan={currentPlan}
              chainedPlans={chainedPlans}
              fromDate={dateFrom}
              isSubscribed={isSubscribed}
              toDate={dateTo}
              disabled={loading}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <AddWorkout isSubscribed={isSubscribed} disabled={loading} />
          </Grid>
        </Grid>
      </Grid>

      {/**Swap workout modal */}
      {workoutSwap && (
        <ModalCustom
          fullScreen={false}
          open={isOpenWorkoutSwap}
          onClose={cancelSwapWorkout}
          toolbarJustifyContent="end"
          paperDialogStyles={{ margin: 10, borderRadius: 8 }}
          contentStyles={{ padding: "0px 16px 12px" }}
        >
          <Grid container styles={{ gap: "6px 0" }}>
            <Grid item xs={12}>
              <Typography className={classes.modalTitle}>
                {t("training.swapWorkout.modalTitle.p1")}{" "}
                <HighlightText
                  color={palette.primary}
                  text={t("training.swapWorkout.modalTitle.move")}
                />{" "}
                {t("training.swapWorkout.source", {
                  source: workoutSwap.sourceWeekday,
                })}{" "}
                {workoutSwap.destination.workoutType !== NOT_WORKOUT ? (
                  <>
                    {t("training.swapWorkout.destination", {
                      destination: workoutSwap.destinationWeekday,
                    })}
                  </>
                ) : (
                  <></>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: "15px 0" }}>
              <Typography className={classes.modalDescription}>
                {workoutSwap.destination.workoutType !== NOT_WORKOUT && (
                  <>
                    {t("training.swapWorkout.modalDescription", {
                      workoutType: t(
                        WorkoutTypesLangKeys[
                          workoutSwap.destination.workoutType
                        ],
                      ),
                      workoutDestinationDay: workoutSwap.destinationDay,
                    })}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box py={2}>
                <CustomButton
                  color={"default"}
                  typeStyle={"secondary"}
                  variant="contained"
                  size="large"
                  onClick={cancelSwapWorkout}
                  fullWidth
                >
                  {t("training.modalButtons.cancel")}
                </CustomButton>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box py={2} pl={1}>
                <CustomButton
                  color={"secondary"}
                  typeStyle={"primary"}
                  variant="contained"
                  size="large"
                  onClick={confirmSwapWorkout}
                  fullWidth
                >
                  {t("training.modalButtons.moveWorkout")}
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
        </ModalCustom>
      )}

      {/**Workout modal */}
      <ModalCustom
        fullScreen={true}
        open={isOpenWorkout}
        onClose={onCloseOpenWorkout}
        toolbarJustifyContent="space-between"
        paperDialogStyles={{ margin: 0, borderRadius: 0 }}
        callToActions={[
          {
            ActionIconComponent: (
              <IconMessageCircle2 color="#6D6F71" stroke={2} />
            ),
            onClick: () => {
              openChat();
              setWorkoutToShare(workoutSelected);
            },
          },
        ]}
      >
        <Grid container>
          <Grid item xs={12}>
            {workoutSelected ? (
              <TrainingDay allow={true} workoutSelected={workoutSelected} />
            ) : (
              <TrainingDay allow={false} />
            )}
          </Grid>
        </Grid>
      </ModalCustom>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 700,
    textAlign: "left",
    color: palette.black,
  },
  modalDescription: {
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",
    color: palette.black,
  },
  weekContainer: {
    padding: theme.spacing(2),
  },
  disabled: {
    zIndex: "0 !important",
    opacity: "0.5 !important",
  },
  dayColumn: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
    fontFamily: "Karla",
    fontWeight: 300,
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
    padding: "0 !important",
  },
  cardButton: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 75,
    maxWidth: "120px",
    padding: "16px 12px",
    gap: 4,
    borderRadius: 4,
    boxShadow: palette.boxShadow,
    "&:hover": {
      backgroundColor: "#F1F6FD",
    },
  },
  cardTextButton: {
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
  },
  marginY: {
    marginTop: 15,
    marginBottom: 15,
  },
  marginB: {
    marginBottom: 15,
  },
}));

export default TrainingWeek;
