import React, { useEffect, useRef } from "react";
import { makeStyles, Box } from "@material-ui/core";
import useUserInfo from "../../../hooks/useUserInfo";
import HomeInfoCard from "../../../components/common/v2/HomeInfoCard";
import { TrainingProvider } from "../../../context/TrainingContext";
import NextRaceSlider from "../../../components/common/v2/NextRaceSlider";
import PopularPlansHome from "../../../components/common/v2/PopularPlansHome";
import { gsap } from "gsap";
import CommunityHomePreview from "../../../components/common/v2/CommunityHomePreview";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PopularVideosHome from "../../../components/common/v2/PopularVideosHome";
import { checkUserHasPlan } from "../../../actions/user-plans-action";
import { useDispatch } from "react-redux";
import VertScreenLoader from "../../../components/common/v2/VertScreenLoader";
import { logEvent } from "../../../lib/events-helper";

const HomeView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    nextRaces,
    currentTraining,
    attributes,
    hasPlan,
    loadingHasPlan,
    sub: cognitoUserSub,
  } = useUserInfo();
  const nextRaceSliderRef = useRef(null);
  const history = useHistory();
  const animationTriggered = useRef(false);

  useEffect(() => {
    // check user has a plan
    if (hasPlan === null || typeof hasPlan == "undefined")
      dispatch(checkUserHasPlan(cognitoUserSub));
  }, []);

  useEffect(() => {
    logEvent("visit_homepage");
  }, []);

  useEffect(() => {
    if (
      attributes &&
      attributes["custom:onboardingFinished"] &&
      attributes["custom:onboardingFinished"] !== "true" &&
      !loadingHasPlan &&
      !hasPlan
    ) {
      history.push("/welcome");
    }
  }, [attributes, hasPlan, loadingHasPlan]);

  useEffect(() => {
    if (!animationTriggered.current && nextRaces && nextRaces.length > 0) {
      gsap.fromTo(
        nextRaceSliderRef.current,
        { y: -20, opacity: 0 },
        { y: 0, opacity: 1, duration: 1 },
      );
      animationTriggered.current = true;
    }
  }, [nextRaces]);

  return (
    <>
      {loadingHasPlan && <VertScreenLoader />}

      <Box className={classes.root}>
        <TrainingProvider>
          <HomeInfoCard />
        </TrainingProvider>
        {nextRaces && nextRaces.length > 0 && (
          <Box ref={nextRaceSliderRef}>
            <NextRaceSlider />
          </Box>
        )}
        {typeof currentTraining === "string" ? <PopularPlansHome /> : null}
        <CommunityHomePreview />
        <PopularVideosHome />
      </Box>
    </>
  );
};

export default HomeView;

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    maxWidth: "100%",
    padding: "24px",
    gap: "40px",
    flex: 1,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
});
