import React, { PureComponent, memo } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { LinearProgress } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import {
  setPlanToSelect,
  updatePlan,
  toggleOpenMenu,
  clearSuccessMessage,
  clearErrorMessage,
} from "../actions/user-plans-action";
import SubCategories from "./choose-plan/subcategories";
import workoutPlans from "../constants/workout-plans";
import { connect } from "react-redux";
import { Capacitor } from "@capacitor/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import withWidth from "@material-ui/core/withWidth";
import AppAuthenticator from "./app-authenticator";
import LanguageMenu from "./layout/language-menu";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import { Storage } from "aws-amplify";
import { getLevel } from "../lib/rates-helper";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import { storageFactory } from "../lib/storage-factory";
import Slider from "@material-ui/core/Slider";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { convertUnits, round } from "../lib/units-helper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Close from "@material-ui/icons/Close";
import WelcomeModal from "./generals/welcome-modal";
import { logEvent } from "../lib/events-helper";
import ContactModal from "./generals/contact-modal";
import { Experiment, Variant, emitter } from "@marvelapp/react-ab-test";
import { userPlanMailchimpSubscribe } from "../actions/user-plans-action";
import moment from "moment";
import { postReserveSpot, clearPost } from "../actions/subcategory-action";
import ReserveModal from "./generals/reserve-modal";
import { checkSubscription } from "../lib/subscription-plan-helper";
import { externalLinks } from "../constants/choose-plan";
import { useAnalyticsEvent } from "../hooks/useAnalyticsEvent";

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "3F51B5",
      },
      track: {
        color: "3F51B5",
      },
      rail: {
        color: "rgba(63, 81, 181, 0.5)",
      },
    },
  },
});

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: 750,
    backgroundColor: "#F9F9FB",
    [theme.breakpoints.down("md")]: {
      minHeight: 600,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minHeight: 500,
      background: "#FFF",
    },
  },
  topContainer: {
    background: "#FFFFFF",
    position: "fixed",
    marginTop:
      Capacitor.getPlatform() === "ios" ? "env(safe-area-inset-top)" : 0,
    left: 0,
    width: "100%",
    top: 60,
    padding: "15px 10px 1px",
    zIndex: 999,

    boxShadow: "18px 20px 71px -20px rgba(0,0,0,0.44)",
    "-webkit-box-shadow": "18px 20px 71px -20px rgba(0,0,0,0.44)",
    "-moz-box-shadow": "18px 20px 71px -20px rgba(0,0,0,0.44)",

    [theme.breakpoints.down("sm")]: {
      padding: "15px 10px 1px",
      borderRadius: "0 0 12px 12px",
    },
  },

  titleContainer: {
    width: "60%",
    paddingBottom: 20,
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  favs: {
    marginLeft: "auto",
    marginRight: 15,
  },

  searchBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,

    [theme.breakpoints.down("sm")]: {
      background: "#FFF",
      width: "100%",
      position: "fixed",
      marginTop:
        Capacitor.getPlatform() === "ios" ? "env(safe-area-inset-top)" : 0,
      top: 0,
      left: 0,
      zIndex: 999,
      justifyContent: "space-between",
      height: 60,
    },
  },
  title: {
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      fontSize: 18,
      marginRight: "auto",
      marginLeft: 25,
      marginTop: 2,
    },
  },
  subTitle: {
    fontWeight: "normal",
    fontSize: 22,
    marginBottom: 5,
    color: "#7E7E7E",
    lineHeight: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      textAlign: "left",
    },
  },
  description: {
    fontSize: 18,
    fontWeight: 300,
    letterSpacing: 2,
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
      lineHeight: "1.5em",
    },
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing.unit * 8,
    paddingRight: theme.spacing.unit * 8,
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 5,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    },
  },
  contentTitleContainer: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 1.6,
  },
  freeButtonRoot: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    fontSize: "0.975rem",
    fontWeight: 600,
    paddingTop: theme.spacing.unit * 1.2,
    paddingBottom: theme.spacing.unit * 1.2,
  },
  freeButtonlabel: {
    textAlign: "center",
  },

  filter: {
    fontSize: 14,
    fontWeight: 400,
    background: "rgba(63, 81, 181, 0.12);",
    borderRadius: 4,
    padding: "6px 8px",
    color: "#212121",
    cursor: "pointer",
    minWidth: 50,
    textAlign: "center",
  },
  filterSelected: {
    fontSize: 14,
    fontWeight: 400,
    background: "#1E629B",
    borderRadius: 4,
    padding: "6px 8px",
    color: "#FFFFFF",
    cursor: "pointer",
    minWidth: 50,
    textAlign: "center",
  },

  filterGrid: {
    justifyContent: "center",
    margin: "0 auto 20px auto",
    width: "80%",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "left",
      marginBottom: 13,
      width: "auto",

      // overflowX: "scroll",
      // overflowY: "hidden",
      // whiteSpace: "nowrap",

      display: "block",

      "&::-webkit-scrollbar ": {
        display: "none",
      },
    },
  },

  filterItem: {
    paddingBottom: "0 !important",
    margin: 5,
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
  },

  backIcon: {
    left: 5,
    cursor: "pointer",
    position: "fixed",
    top: 30,
    zIndex: 1049,
    [theme.breakpoints.up("md")]: {
      color: "#000000",
      top: 10,
      left: 35,
    },
  },

  topBar: {
    display: "flex",
    marginBottom: 10,
  },

  containerLang: {
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
    },
  },

  logoImg: {
    height: 50,
    position: "fixed",
    marginTop:
      Capacitor.getPlatform() === "ios" ? "env(safe-area-inset-top)" : 0,
    transform: "translate(-50%, -50%)",
    top: 30,
    left: "50%",
    zIndex: 999,
  },

  signInButton: {
    fontFamily: "Montserrat",
  },

  levelMiniBadge: {
    height: 25,
    bottom: -5,
    right: -15,
    position: "absolute",
  },
  titleParent: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      margin: "16px 0px 12px 0px",
    },
  },
  titleHeader: {
    width: "50%",
    fontSize: "16px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      width: "90%",
    },
  },
  separator: {
    height: "1px",
    backgroundColor: "#9E9E9E",
    margin: "3px auto 16px auto",
    width: "80%",

    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      width: "100%",
      margin: "3px 0",
    },
  },

  separatorDown: {
    height: "1px",
    backgroundColor: "#9E9E9E",
    margin: "16px auto",
    width: "80%",

    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      width: "100%",
      margin: "3px 0",
    },
  },
  card: {
    border: "solid 1px gray",
    padding: "6px 8px",
    boxSizing: "border-box",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    cursor: "pointer",

    "&:not(:first-child)": {
      marginLeft: "10px",
    },
  },

  cardSelected: {
    border: "solid 1px gray",
    padding: "6px 8px",
    boxSizing: "border-box",
    borderRadius: "8px",
    display: "flex",
    width: "fit-content",
    cursor: "pointer",
    background: "rgba(63, 81, 181, 0.12)",
    justifyContent: "center",
    alignItems: "center",

    "&:not(:first-child)": {
      marginLeft: "10px",
    },
  },

  cardsParent: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  cardsSection: {
    padding: "16px 10px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  surfaceTitle: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  surfaceSubtitle: {
    fontSize: "12px",
    color: "#898A8D",
  },
  titlesParent: {
    marginBottom: "10px",
    textAlign: "left",

    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  sliderSection: {
    padding: "16px 10px",
    boxSizing: "border-box",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  applyFiltersParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
    },
  },
  applyFilters: {
    margin: "10px 10px",
    borderRadius: 50,
  },
  advSearchBtn: {
    display: "flex",
    backgroundColor: "rgba(63, 81, 181, 0.12)",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px",
    boxSizing: "border-box",
    cursor: "pointer",
    borderRadius: "4px",
    border: "solid 1px #000000",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      minHeight: 60,
    },
  },
  searchBtnContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "10px 0",

    [theme.breakpoints.down("sm")]: {
      width: "49%",
    },
  },

  searchBtnContainerSolo: {
    display: "flex",
    justifyContent: "center",
    margin: "10px 0",
  },

  upIcon: {
    fontSize: 35,
    transform: "rotate(180deg)",
  },
  iconParent: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  distLabelsParent: {
    display: "flex",
    justifyContent: "space-between",
    "& *": {
      fontSize: 10,
      color: "#898A8D",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
  cardText: {
    fontSize: "8px",
    textAlign: "center",
  },
  surfaceSection: {
    display: "flex",
    width: "50%",

    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  sliderParent: {
    width: "80%",

    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  sectionsParent: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "80%",
    margin: "0 auto",

    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: "0px",
      width: "auto",
    },
  },
  closeIconParent: {
    position: "absolute",
    right: "0px",
    cursor: "pointer",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row-reverse",
  },
  btnCustom: {
    ...styles.buttonsContainer,
    width: "49%",
  },
  seeMoreParent: {
    display: "flex",
    justifyContent: "center",
  },
  linearProgress: {
    zIndex: 10001,
  },
  search: {
    position: "fixed",
    transform: "translate(-50%, -50%)",
    marginTop:
      Capacitor.getPlatform() === "ios" ? "env(safe-area-inset-top)" : 0,
    top: 30,
    left: "50%",
    zIndex: 1034,
    [theme.breakpoints.down("sm")]: {
      left: "86%",
    },
  },
  newSearchBtnParent: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginLeft: "10px",
    },
    cursor: "pointer",
  },
  filtersCard: {
    background: "#F4F4F5",
    width: "100%",
    borderRadius: "6px",
    padding: "20px",
    boxSizing: "border-box",
  },
  listItem: {
    fontWeight: "400",
    fontSize: 18,
    marginBottom: "10px",
    cursor: "pointer",
  },
  mainTitle: {
    marginTop: 65,
    marginBottom: 20,
    fontWeight: "500",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  filtersCardParent: {
    marginTop: "10px",
    position: "sticky",
    height: "fit-content",
    top: 65,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  parentCardCategory: {
    "&:not(:last-child)": {
      marginRight: "12px",
    },
  },
  categoryCard: {
    borderRadius: "5px",
    padding: "3px 20px",
    boxSizing: "border-box",
    border: "solid 1.5px #000",
    minWidth: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  categoryList: {
    display: "-webkit-inline-box",
    width: "100%",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    [theme.breakpoints.down("sm")]: {
      margin: "30px 12px 20px 12px",
    },
  },
  bigCard: {
    marginTop: 70,
    marginBottom: 20,
    background: "trasparent",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "5px",
    padding: "20px",
    boxSizing: "border-box",
    margin: "25px 12px 12px 12px",

    "& button": {
      marginTop: 16,
    },
  },
  extraMarginMobile: {
    marginTop: "0px",
  },
});
class ChoosePlan extends PureComponent {
  state = {
    filterOptions: [
      "All",
      "Beginner",
      "Free",
      "Start from zero",
      "Ultras",
      "Performance",
    ],
    currentFilter: {
      tags: "All",
      training_surfaces: [],
      distance_range: { min: 0, max: 0 },
    },
    appliedFilter: {
      tags: "All",
      training_surfaces: [],
      distance_range: { min: 0, max: 0 },
    },
    visual_range: [0, 0],
    openSignUp: false,
    favorites: false,
    avatarLoading: true,
    avatarUrl: null,
    currentDetails: null,
    showFilters: false,
    units: "kms",
    isLoading: false,
    showWelcomeModal: false,
    inactiveUser: false,
    firstTime: false,
    flag: false,
    morePlans: false,
    currentVariant: null,
    filtersApplied: false,
    showReserve: false,
    currentTag: "All",
    visualTag: "All",
  };

  currentUnit = localStorage.getItem("units");

  interval = null;

  componentDidMount = () => {
    const { width } = this.props;

    this.props.dispatch(
      setPlanToSelect({
        redirect: null,
      }),
    );

    if (this.props.location.state && this.props.location.state.id) {
      this.setState({
        ...this.state,
        currentDetails: this.props.location.state.id,
      });

      this.props.history.replace({
        ...this.props.history.location,
        state: null,
      });
    }

    window.addEventListener("storage", () => this.localStorageUpdated(width));
    window.addEventListener("mousemove", this.mouseEvent);

    this.interval = setInterval(this.startTimer, 60000 * 2);

    if (
      this.props.location.search &&
      this.props.location.search.includes("free")
    ) {
      this.setState({
        ...this.state,
        currentFilter: { ...this.state.currentFilter, tags: "Free" },
      });
    }
    if (
      this.props.location.search &&
      this.props.location.search.includes("plan=")
    ) {
      this.setState({
        ...this.state,
        currentDetails: this.props.location.search.split("?plan=")[1],
      });
    }
    this.getAvatarUrl();
  };

  componentWillUnmount = () => {
    this.props.dispatch(clearPost());
  };

  mouseEvent = (event) => {
    const counter = localStorage.getItem("counter");
    const screenWidth = window.innerWidth;

    const yAxie = event && event.clientY;
    const xAxie = event && event.clientX;
    if (
      (xAxie >= screenWidth - screenWidth / 5 && yAxie < 70) ||
      (xAxie >= 20 && xAxie <= screenWidth / 5 && yAxie < 70)
    ) {
      if (
        !this.state.firstTime &&
        !this.state.inactiveUser
        // && Number(counter) < 2
      ) {
        this.setState({ ...this.state, inactiveUser: true, firstTime: true });
      }
    }
  };

  startTimer = () => {
    const counter = localStorage.getItem("counter");
    if (!counter || Number(counter) < 2)
      this.setState({ ...this.state, inactiveUser: true });
  };

  userStatus = () => {
    this.setState({ ...this.state, inactiveUser: true });
  };

  modalCloseHandler = () => {
    this.setState({
      ...this.state,
      showWelcomeModal: false,
    });

    if (localStorage.getItem("flag")) localStorage.removeItem("flag");
  };

  async componentDidUpdate(prevProps, prevState) {
    const { userPlans } = this.props;
    const { currentPlan, updatingImage } = userPlans;
    const { image } = currentPlan || {};

    if (
      localStorage.getItem("flag") &&
      prevState.flag !== localStorage.getItem("flag")
    ) {
      this.setState({ ...this.state, showWelcomeModal: true });
    }

    if (
      (currentPlan &&
        currentPlan.image &&
        (!prevProps.userPlans ||
          !prevProps.userPlans.currentPlan ||
          !prevProps.userPlans.currentPlan.image ||
          prevProps.userPlans.currentPlan.image !== currentPlan.image)) ||
      (prevProps.userPlans.updatingImage && !updatingImage)
    ) {
      if (image) {
        this.setState({
          ...this.state,
          avatarLoading: true,
        });
        this.getAvatarUrl();
      }
    }
  }

  morePlans = () => {
    this.setState({ ...this.state, morePlans: true });
  };

  getAvatarUrl = async () => {
    const { userPlans, t } = this.props;
    const { currentPlan } = userPlans;
    const { image } = currentPlan || {};

    if (image) {
      try {
        const result = await Storage.get(image, { level: "public" });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              avatarUrl: result,
              avatarLoading: false,
            }),
          100,
        );
      } catch (exp) {
        throw exp;
      }
    } else if (!image) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            avatarLoading: false,
          }),
        100,
      );
    }
  };

  handleChooseFilter = (key, filter) => {
    const { history } = this.props;

    if (filter === "SKYRUNNER World Series")
      return history.push("/chooseplan-sws");
    if (filter === "UTMB World Series") return history.push("/chooseplan-utmb");
    if (filter === "X-terra World series")
      return history.push("/chooseplan-xterra");

    if (filter === "Run The Alps") return history.push("/run-the-alps");

    this.setState({
      ...this.state,
      currentFilter: { ...this.state.currentFilter, [key]: filter },
      currentTag: filter,
    });
  };

  localStorageUpdated(width) {
    this.setState({
      ...this.state,
      units: localStorage.getItem("units"),
    });
  }

  handleChoosePlan = (event, coachChoose = false) => {
    const { auth, subscriptions, userPlans } = this.props;
    const { isAuthenticated } = auth;

    if (isAuthenticated) {
      if (coachChoose) {
        const { currentPlan } = userPlans || {};
        const { latestSubscriptionFinalizedTime } = currentPlan || {};

        const isSubscribed = checkSubscription(
          subscriptions,
          latestSubscriptionFinalizedTime,
        );

        this.props.dispatch(
          setPlanToSelect({
            redirect:
              !isSubscribed && Capacitor.isNativePlatform()
                ? "coach-choose"
                : null,
          }),
        );
        setTimeout(() => {
          this.props.history.push("/chooseplan/coach-choose");
        });
        return;
      }

      if (event) {
        const { currentTarget } = event;
        if (currentTarget) {
          const { id } = currentTarget;
          if (id) {
            const { subscriptions } = this.props;
            const { latestExpired, latest } = subscriptions;
            this.props.dispatch(setPlanToSelect({ plan: id }));
            let nextPath = `/subscription/${id}`;
            if (latestExpired === false && latest) {
              const { plan_id } = latest;
              if (plan_id && plan_id.includes("premium")) {
                nextPath = `/chooseplan/${id}`;
              }
            }
            setTimeout(() => {
              this.props.history.push(nextPath);
            });
          }
        }
      }
    } else {
      if (coachChoose) {
        this.props.dispatch(
          setPlanToSelect({
            redirect: "coach-choose",
          }),
        );
      }
      this.handleOpenSignUp();
    }
  };

  handleBack = () => {
    this.props.history.push("/explore");
  };

  closeModalHandler = () => {
    const counter = localStorage.getItem("counter");
    const { inactiveUser } = this.state;

    if (inactiveUser) {
      this.setState({ ...this.state, inactiveUser: false });
    }

    if (counter) {
      const result = Number(localStorage.getItem("counter"));
      const count = result + 1;
      if (Number(counter < 2)) {
        localStorage.setItem("counter", count.toString());
      }

      if (count == 2) {
        this.interval = null;
        clearInterval(this.interval);
      }
    }
  };

  handleOpenSignUp = () => {
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignUp: true,
    });
  };

  handleCloseSignUp = () => {
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignUp: false,
    });
  };

  handleOpenSignIn = () => {
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignIn: true,
    });
  };

  handleCloseSignIn = () => {
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignIn: false,
    });
  };

  toggleFavorite = (event, id, type) => {
    event.preventDefault();
    event.stopPropagation();
    const { auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      const { userPlans } = this.props;
      const { currentPlan } = userPlans;
      const { exploreFavorites, cognito_user_sub } = currentPlan || {};
      if (
        exploreFavorites &&
        exploreFavorites.length &&
        exploreFavorites.find((e) => e.id === id)
      ) {
        const newExploreFavorites = exploreFavorites.filter((f) => f.id !== id);
        this.props.dispatch(
          updatePlan(
            {
              cognito_user_sub: cognito_user_sub,
              exploreFavorites: newExploreFavorites,
            },
            false,
            false,
          ),
        );
      } else {
        this.props.dispatch(
          updatePlan(
            {
              cognito_user_sub: cognito_user_sub,
              exploreFavorites:
                exploreFavorites && exploreFavorites.length
                  ? [{ id, type }, ...exploreFavorites]
                  : [{ id, type }],
            },
            false,
            false,
          ),
        );
      }
    } else {
      this.handleOpenSignUp();
    }
  };

  handleOpenFavorites = () => {
    const { auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      this.setState({
        ...this.state,
        favorites: !this.state.favorites,
      });
    } else {
      this.handleOpenSignUp();
    }
  };

  toogleMenu = () => {
    const { auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      storageFactory().removeItem("currentGoal");
      this.props.dispatch(toggleOpenMenu());
    } else {
      this.handleOpenSignUp();
    }
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  convertMilesToKms(localValue) {
    const firstValue = convertUnits(localValue[0], "miles", "kms", "distance");
    const secondValue = convertUnits(localValue[1], "miles", "kms", "distance");
    return [round(firstValue), round(secondValue)];
  }

  handleChange = (event, value) => {
    let localValue = [...value];
    let realValue = [0, 0];

    this.state.units === "miles"
      ? (realValue = this.convertMilesToKms(localValue))
      : (realValue = value);

    this.setState({
      ...this.state,
      visual_range: value,
      currentFilter: {
        ...this.state.currentFilter,
        distance_range: { min: realValue[0], max: realValue[1] },
      },
    });
  };

  handleFilters = (show) => {
    if (show) {
      const currentVariantCoachChoose = emitter.getActiveVariant(
        "coach_choose_plan",
      );
    }
    this.setState({
      ...this.state,
      showFilters: show,
    });
  };

  handleSurface = (value) => {
    const { currentFilter } = this.state;
    const currentIndex = currentFilter.training_surfaces.indexOf(value);
    const localSurfaces = [...currentFilter.training_surfaces];

    if (currentIndex === -1) localSurfaces.push(value);
    if (currentIndex !== -1) localSurfaces.splice(currentIndex, 1);

    this.setState({
      ...this.state,
      currentFilter: {
        ...this.state.currentFilter,
        training_surfaces: [...localSurfaces],
      },
    });
  };

  closePanel = () => {
    this.setState({
      ...this.state,
      showFilters: false,
    });
  };

  handleExternalFilter = async (item) => {
    await this.handleChooseFilter("tags", item.value);
    await this.setState({
      ...this.state,
      currentTag: item.value,
      visualTag: item.label,
    });
    await this.applyFilters();
  };

  applyFilters = () => {
    this.setState({
      ...this.state,
      appliedFilter: { ...this.state.currentFilter },
      isLoading: true,
    });

    setTimeout(() => {
      this.setState({
        ...this.state,
        isLoading: false,
        showFilters: false,
        filtersApplied: true,
      });
    }, 1000);
  };

  goToSkyrunning = () => {
    const { history } = this.props;
    history.push("/chooseplan-sws");
  };

  goToUtmb = () => {
    const { history } = this.props;
    history.push("/chooseplan-utmb");
  };

  render() {
    const { classes, t, auth, width, userPlans, subcategories } = this.props;
    const { loadingReserve } = subcategories;
    const { isAuthenticated, attributes } = auth;
    const isInMobileViewSm = width === "sm" || width === "xs";
    const {
      openSignUp,
      openSignIn,
      filterOptions,
      favorites,
      avatarUrl,
      currentDetails,
      currentFilter,
      showFilters,
      isLoading,
      units,
      morePlans,
      showReserve,
    } = this.state;

    const { pathname } = window.location;

    const { currentPlan, loading, successMessage, errorMessage } = userPlans;
    const {
      exploreFavorites,
      accumulatedDistance = 0,
      accumulatedElevation = 0,
      noShowPlansFilter,
    } = currentPlan || {};

    const level = loading
      ? 0
      : getLevel(accumulatedDistance, accumulatedElevation);

    const filters = filterOptions;

    const handleCloseReserve = () =>
      this.setState({ ...this.state, showReserve: false });

    const reserveMySpot = async (event, localCategory) => {
      event.stopPropagation();
      const { name, email } = attributes || {};

      const body = {
        name,
        email,
        race: localCategory.title["en"],
        date: moment().format("DD/M/YYYY"),
        sheet: localCategory.tags.includes("UTMB World Series")
          ? "UTMB"
          : "Skyrunner",
      };

      if (isAuthenticated) {
        await this.props.dispatch(postReserveSpot(body));
        await this.props
          .dispatch(
            userPlanMailchimpSubscribe({
              cognito_user_sub: currentPlan.cognito_user_sub,
              cognito_user_email: currentPlan.cognito_user_email,
              planNameReservedSpot: body,
            }),
          )
          .then(() => {
            this.setState({ ...this.state, showReserve: true });
            this.props.dispatch(clearPost());
          })
          .catch((err) => {
            throw new Error(err);
          });
        return;
      }
      this.handleOpenSignUp();
    };

    return (
      <div className={classes.container}>
        {isInMobileViewSm ? (
          <div className={classes.searchBar}>
            <ArrowBackIosIcon
              onClick={() => this.handleBack()}
              fontSize="small"
              style={{ marginLeft: "12px" }}
            />

            <div
              className={classes.pictureContainer}
              style={{
                width: 44,
                height: 44,
                marginRight: "auto",
                position: "relative",
              }}
            >
              <Fab
                component="span"
                className={classes.fab}
                title="Cambia tu foto"
                style={{
                  width: 44,
                  height: 44,
                }}
                onClick={() => this.toogleMenu()}
              >
                <Avatar
                  src={
                    avatarUrl || `${process.env.PUBLIC_URL}/avatar-empty.png`
                  }
                  className={classes.avatar}
                  style={{
                    width: 44,
                    height: 44,
                  }}
                />
              </Fab>
            </div>
          </div>
        ) : (
          ""
        )}

        <Box className={classes.search}>
          <Box
            className={classes.newSearchBtnParent}
            onClick={() => this.handleFilters(!showFilters)}
          >
            {isInMobileViewSm ? (
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {t("Search")}
              </Typography>
            ) : (
              <Typography>{t("Advanced Search")}</Typography>
            )}
            <img src={`${process.env.PUBLIC_URL}/search-icon.svg`}></img>
          </Box>
        </Box>
        {!isInMobileViewSm &&
          this.state.showWelcomeModal &&
          pathname.startsWith("/chooseplan") && (
            <WelcomeModal
              open={this.state.showWelcomeModal}
              onClose={this.modalCloseHandler}
            />
          )}

        {!isInMobileViewSm && this.state.inactiveUser && !isAuthenticated && (
          <ContactModal
            open={this.state.inactiveUser}
            onClose={this.closeModalHandler}
          />
        )}

        {loadingReserve && (
          <LinearProgress
            className={classes.linearProgress}
            variant="indeterminate"
            color="primary"
          />
        )}

        {isInMobileViewSm && (
          <img
            src={`${process.env.PUBLIC_URL}/vertrun-logo-wide.png`}
            alt=""
            className={classes.logoImg}
          />
        )}

        <div className={classes.content}>
          {showFilters && (
            <div className={classes.topContainer}>
              {!isInMobileViewSm ? (
                <div className={classes.contentTitleContainer}>
                  <Typography
                    variant="h3"
                    align="center"
                    className={classes.subTitle}
                  >
                    {t("Choose your training plan")}
                  </Typography>
                </div>
              ) : (
                ""
              )}

              {!isInMobileViewSm && (
                <div className={classes.titleParent}>
                  <Typography
                    variant="body1"
                    className={classes.titleHeader}
                    dangerouslySetInnerHTML={{ __html: t("Choose yor plan") }}
                  />
                </div>
              )}

              {!isInMobileViewSm && showFilters ? (
                <div className={classes.separator} />
              ) : null}

              {showFilters && (
                <div>
                  <Grid container spacing={16} className={classes.filterGrid}>
                    {filters.map((filter, index) => (
                      <Grid item key={index} className={classes.filterItem}>
                        <Typography
                          variant="body1"
                          onClick={() =>
                            this.handleChooseFilter("tags", filter)
                          }
                          className={
                            filter === currentFilter.tags
                              ? classes.filterSelected
                              : classes.filter
                          }
                        >
                          {t(filter)}
                        </Typography>
                      </Grid>
                    ))}

                    {!isInMobileViewSm && (
                      <div
                        className={classes.closeIconParent}
                        onClick={this.closePanel}
                      >
                        <Close />
                      </div>
                    )}
                  </Grid>

                  {isInMobileViewSm && <div className={classes.separator} />}
                  <div className={classes.sectionsParent}>
                    <div className={classes.surfaceSection}>
                      <div className={classes.cardsSection}>
                        <div className={classes.titlesParent}>
                          <Typography
                            variant="body1"
                            className={classes.surfaceTitle}
                          >
                            {" "}
                            {t("Training surface")}{" "}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.surfaceSubtitle}
                          >
                            {" "}
                            {t(
                              "If you can access hills/mountains on the weekends, choose hills or mountains",
                            )}{" "}
                          </Typography>
                        </div>

                        <div className={classes.cardsParent}>
                          <div
                            className={
                              currentFilter.training_surfaces.includes("Flat")
                                ? classes.cardSelected
                                : classes.card
                            }
                            onClick={() => this.handleSurface("Flat")}
                          >
                            <div className={classes.cardContent}>
                              <img
                                src={`${process.env.PUBLIC_URL}/flat-surface-icon.svg`}
                                alt="flat-surface-icon"
                              />
                              <Typography className={classes.cardText}>
                                {t("Totally flat")}
                              </Typography>
                            </div>
                          </div>

                          <div
                            className={
                              currentFilter.training_surfaces.includes("Hills")
                                ? classes.cardSelected
                                : classes.card
                            }
                            onClick={() => this.handleSurface("Hills")}
                          >
                            <div className={classes.cardContent}>
                              <img
                                src={`${process.env.PUBLIC_URL}/hills-surface-icon.svg`}
                                alt="hills-surface-icon"
                              />
                              <Typography className={classes.cardText}>
                                {t("Hills")}
                              </Typography>
                            </div>
                          </div>

                          <div
                            className={
                              currentFilter.training_surfaces.includes(
                                "Mountains",
                              )
                                ? classes.cardSelected
                                : classes.card
                            }
                            onClick={() => this.handleSurface("Mountains")}
                          >
                            <div className={classes.cardContent}>
                              <img
                                src={`${process.env.PUBLIC_URL}/mountains-surface-icon.svg`}
                                alt="mountains-surface-icon"
                              />
                              <Typography className={classes.cardText}>
                                {t("Mountains")}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {isInMobileViewSm && <div className={classes.separator} />}

                    <div className={classes.sliderSection}>
                      <div className={classes.titlesParent}>
                        <Typography
                          variant="body1"
                          className={classes.surfaceTitle}
                        >
                          {" "}
                          {t("Which distance are you training for")}{" "}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.surfaceSubtitle}
                        >
                          {" "}
                          {this.state.visual_range[0]}{" "}
                          {units === "kms" ? "km" : t("Miles")} -{" "}
                          {this.state.visual_range[1]}{" "}
                          {units === "kms" ? "km" : t("Miles")}
                        </Typography>
                      </div>

                      <ThemeProvider theme={muiTheme}>
                        <div className={classes.sliderParent}>
                          <Slider
                            value={this.state.visual_range}
                            onChange={this.handleChange}
                            aria-labelledby="range-slider"
                            color="blue"
                            min={0}
                            max={units === "kms" ? 160 : 99}
                          />
                          <div className={classes.distLabelsParent}>
                            <Typography>
                              0 {units === "kms" ? "km" : t("Miles")}
                            </Typography>
                            <Typography>
                              {units === "miles"
                                ? `${round(
                                    convertUnits(
                                      160,
                                      "kms",
                                      "miles",
                                      "distance",
                                    ),
                                  )} ${t("Miles")}`
                                : "160 km"}
                              &nbsp;
                              {t("or more")}
                            </Typography>
                          </div>
                        </div>
                      </ThemeProvider>
                    </div>
                  </div>
                  <div className={classes.applyFiltersParent}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.applyFilters}
                      onClick={this.applyFilters}
                    >
                      {t("Apply filters")}
                    </Button>
                    {isLoading && (
                      <CircularProgress
                        variant="indeterminate"
                        color="primary"
                        size={20}
                      />
                    )}
                  </div>

                  {isInMobileViewSm && (
                    <div className={classes.iconParent}>
                      <KeyboardArrowDownIcon
                        fontSize="small"
                        className={classes.upIcon}
                        onClick={() => this.handleFilters(false)}
                      />
                    </div>
                  )}

                  {!isInMobileViewSm && showFilters && (
                    <div className={classes.separatorDown} />
                  )}
                </div>
              )}
            </div>
          )}

          {isInMobileViewSm && isAuthenticated && (
            <Box
              style={{
                marginTop: 90,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                size="medium"
                style={{ borderRadius: 5 }}
                onClick={() => this.handleChoosePlan(null, true)}
              >
                {t("Have a coach to choose my plan")}
                <Box
                  style={{
                    display: "block",
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: 20,
                    marginLeft: 2,
                  }}
                >
                  <img
                    src="/check-white-icon.svg"
                    alt="check-white-icon"
                    width={28}
                  />
                </Box>
              </Button>
            </Box>
          )}

          {!isAuthenticated && isInMobileViewSm && (
            <div className={classes.bigCard}>
              <Typography
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Your coach will personalize any training plan for you, your goals and race schedule.",
                  ),
                }}
              ></Typography>
              <Button
                color="primary"
                variant="contained"
                size="medium"
                style={{ borderRadius: 5 }}
                onClick={() => this.handleChoosePlan(null, true)}
              >
                {t("Have a coach to choose my plan")}
                <Box
                  style={{
                    display: "block",
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: 20,
                    marginLeft: 2,
                  }}
                >
                  <img
                    src="/check-white-icon.svg"
                    alt="check-white-icon"
                    width={28}
                  />
                </Box>
              </Button>
            </div>
          )}

          <Box
            className={classNames(
              classes.categoryList,
              !isAuthenticated && isInMobileViewSm
                ? classes.extraMarginMobile
                : null,
            )}
          >
            {isInMobileViewSm &&
              externalLinks.map((item) => (
                <Box key={item.label} className={classes.parentCardCategory}>
                  <Box
                    className={classes.categoryCard}
                    style={{
                      borderColor:
                        this.state.currentTag == item.value ? "#E16327" : "",
                    }}
                  >
                    <Typography
                      style={{
                        color:
                          this.state.currentTag == item.value ? "#E16327" : "",
                      }}
                      key={item}
                      variant="body2"
                      onClick={() => this.handleExternalFilter(item)}
                    >
                      {t(item.label).toUpperCase()}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>

          <ReserveModal
            open={showReserve}
            onClose={handleCloseReserve}
            action={handleCloseReserve}
          />

          <Typography variant="h2" className={classes.mainTitle}>
            {t(this.state.visualTag)}
          </Typography>

          <Grid container spacing={2}>
            <Grid item md={3} className={classes.filtersCardParent}>
              <Box className={classes.filtersCard}>
                <Box
                  className={classes.row}
                  onClick={() => this.handleChoosePlan(null, true)}
                >
                  <Typography
                    className={classes.listItem}
                    style={{ fontWeight: "500" }}
                  >
                    {t("Ask a coach for a plan")}
                  </Typography>
                  <img
                    src={`${process.env.PUBLIC_URL}/coach-plan-icon.svg`}
                    alt="icon"
                  ></img>
                </Box>
                {externalLinks.map((item) => (
                  <Typography
                    className={classes.listItem}
                    style={{
                      color:
                        this.state.currentTag == item.value ? "#E16327" : "",
                      fontWeight: "500",
                    }}
                    key={item.value}
                    onClick={() => this.handleExternalFilter(item)}
                  >
                    {t(item.label)}
                  </Typography>
                ))}
              </Box>
            </Grid>
            <Grid item md={9}>
              <Experiment name="chooseplan">
                <Variant name="A">
                  <SubCategories
                    filter={this.state.appliedFilter}
                    filtersApplied={this.state.filtersApplied}
                    onOpenAuth={this.handleOpenSignUp}
                    onToggleFavorite={this.toggleFavorite}
                    exploreFavorites={exploreFavorites}
                    favorites={favorites}
                    currentDetails={+currentDetails}
                    filterloadind={isLoading}
                    morePlans={morePlans}
                    noShowPlansFilter={noShowPlansFilter}
                    currentVariant={this.state.currentVariant}
                    reserveMySpot={reserveMySpot}
                  />
                </Variant>

                <Variant name="B">
                  <SubCategories
                    filter={this.state.appliedFilter}
                    filtersApplied={this.state.filtersApplied}
                    onOpenAuth={this.handleOpenSignUp}
                    onToggleFavorite={this.toggleFavorite}
                    exploreFavorites={exploreFavorites}
                    favorites={favorites}
                    currentDetails={+currentDetails}
                    filterloadind={isLoading}
                    morePlans={morePlans}
                    noShowPlansFilter={noShowPlansFilter}
                    currentVariant={this.state.currentVariant}
                    reserveMySpot={reserveMySpot}
                  />
                </Variant>
              </Experiment>
            </Grid>
          </Grid>

          {isInMobileViewSm &&
            !morePlans &&
            !noShowPlansFilter &&
            this.state.currentVariant == "B" && (
              <div className={classes.seeMoreParent}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  className={classes.button}
                  onClick={this.morePlans}
                >
                  {t("See more plans")}
                </Button>
              </div>
            )}

          {isAuthenticated && !isInMobileViewSm ? (
            <img
              src={`${process.env.PUBLIC_URL}/icon-arrow-back-black.svg`}
              alt=""
              className={classes.backIcon}
              onClick={() => this.handleBack()}
            />
          ) : (
            ""
          )}
        </div>
        <Dialog
          open={openSignUp}
          onClose={this.handleCloseSignUp}
          aria-labelledby="signUp"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signupdialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseSignUp}
              />
            </div>
            <AppAuthenticator initialState="signUp" />
          </DialogContent>
        </Dialog>
        <Dialog
          open={openSignIn}
          onClose={this.handleCloseSignIn}
          aria-labelledby="signIn"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signindialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseSignIn}
              />
            </div>
            <AppAuthenticator initialState="signIn" />
          </DialogContent>
        </Dialog>
        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      </div>
    );
  }
}

ChoosePlan.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default connect((store) => {
  return {
    subscriptions: store.subscriptions,
    auth: store.auth,
    userPlans: store.userPlans,
    subcategories: store.subcategories,
  };
})(
  withTranslation("plan")(
    withWidth()(withStyles(styles, { withTheme: true })(memo(ChoosePlan))),
  ),
);
