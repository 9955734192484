import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ActionIcon } from "../../icons";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  listItem: {
    minHeight: 36,
  },
});

const ToggleOptions = ({
  actions,
  iconButtonSize = "medium",
  iconWidth = 32,
  iconHeight = 32,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="actions"
        onClick={handleMenuClick}
        size={iconButtonSize}
      >
        <ActionIcon width={iconWidth} height={iconHeight} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {actions.map((action, index) => (
          <MenuItem
            className={classes.listItem}
            key={index}
            onClick={(event) => {
              event.preventDefault();
              action.onClick();
              handleMenuClose();
            }}
          >
            {action.icon && action.icon} {action.title && action.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

ToggleOptions.propTypes = {
  actions: PropTypes.array,
};

ToggleOptions.defaultProps = {
  actions: [
    {
      icon: null,
      title: "Option",
      onClick: () => console.info("Option"),
    },
  ],
};

export default ToggleOptions;
