import React, { memo, useState } from "react";
import {
  Typography,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { withStyles, TextField, Select } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import CalendarToday from "@material-ui/icons/CalendarToday";
import withWidth from "@material-ui/core/withWidth";
import { dateFormat } from "../../../lib/date-helper";
import moment from "moment";
import {
  planChangeField,
  planChangeCurrentStep,
} from "../../../actions/user-plans-action";
import { useDispatch, useSelector } from "react-redux";

const styles = {
  main: {
    padding: "16px",
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  title: {
    margin: "0px 0px 16px 0px",
    fontSize: "24px",
    fontWeight: "bold",
  },
  calendarIcon: {
    color: "#9E9E9E",
  },
  field: {
    marginTop: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  btn: {
    borderRadius: "10px !important",
    marginLeft: "auto",
    marginTop: "30px",
  },
};

const PersonalData = ({
  classes,
  minDate,
  maxDate,
  minDateMessage,
  maxDateMessage,
  id,
  width,
  t,
  genders,
  currentStep,
}) => {
  const dispatch = useDispatch();
  const { planTempData } = useSelector((state) => state.userPlans);
  const isInMobileViewSm = width == "xs" || width == "sm";

  const [userData, setUserData] = useState({
    birthdate: planTempData.birthdate || null,
    country: planTempData.country || null,
    sex: planTempData.sex || "",
  });

  const handleDate = (momentDate) => {
    const birthdate = momentDate ? momentDate.format(dateFormat) : null;
    setUserData({ ...userData, birthdate });
  };

  const handleField = (event) => {
    const {
      target: { value, name },
    } = event;
    setUserData({ ...userData, [name]: value });
  };

  const handleNext = async () => {
    const dataToObject = Object.entries(userData).map(([name, value]) => ({
      name,
      value,
    }));

    const birthdate = dataToObject[0];
    const country = dataToObject[1];
    const gender = dataToObject[2];

    await dispatch(planChangeField(birthdate));
    await dispatch(planChangeField(country));
    await dispatch(planChangeField(gender));
    await dispatch(planChangeCurrentStep(currentStep + 1));
  };

  const isValid = () => {
    const data = Object.values(userData);
    const valid = data.every((item) => item);
    return valid;
  };

  return (
    <div className={classes.main}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div>
          <DatePicker
            id={id}
            value={
              userData.birthdate
                ? moment(userData.birthdate, dateFormat).toISOString()
                : null
            }
            format="DD/MM/YYYY"
            placeholder={t("What’s your date of birth?")}
            mask={(value) =>
              // handle clearing outside if value can be changed outside of the component
              value
                ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
                : []
            }
            minDate={minDate}
            maxDate={maxDate}
            autoOk
            clearable
            keyboard
            disableOpenOnEnter
            animateYearScrolling={false}
            onChange={handleDate}
            fullWidth={true}
            maxDateMessage={maxDateMessage}
            inputVariant="outlined"
            minDateMessage={minDateMessage}
            invalidDateMessage={t("Invalid date format")}
            InputProps={{
              endAdornment: (
                <InputAdornment disableTypography position="end">
                  <CalendarToday className={classes.calendarIcon} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </MuiPickersUtilsProvider>

      <TextField
        id="country"
        name="country"
        autoComplete="off"
        label={t("Current country of residence")}
        type="country"
        fullWidth={isInMobileViewSm}
        className={classes.field}
        onKeyPress={null}
        variant="outlined"
        value={userData.country}
        onChange={handleField}
      />

      <div style={{ width: "100%" }}>
        <FormControl variant="outlined" className={classes.field}>
          <InputLabel htmlFor="gender-label">
            {t("Gender do you identify with")}
          </InputLabel>
          <Select
            label={t("Gender do you identify with")}
            labelId="gender-label"
            id="sex"
            name="sex"
            value={userData.sex}
            onChange={handleField}
            disableUnderline
            // inputProps={{
            //   name: "sex",
            //   id: "outlined-age-native-simple",
            // }}
          >
            {genders.map((gender, index) => (
              <MenuItem value={gender.value} key={index}>
                {t(gender.text)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <Button
        variant="contained"
        className={classes.btn}
        color="primary"
        onClick={handleNext}
        disabled={!isValid()}
      >
        {t("Next")}
      </Button>
    </div>
  );
};

export default withTranslation("plan")(
  withWidth()(withStyles(styles, { withTheme: true })(memo(PersonalData))),
);
