import React, { useEffect, useState, useRef, memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Drawer, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import RaceCard from "./components/race-card";
import { useDispatch, useSelector } from "react-redux";
import { getEpicRaces } from "../../../../actions/user-plans-action";
import AddRaceForm from "./components/add-race-form";
import history from "../../../../lib/history-helper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const styles = (theme) => ({
  drawerRoot: {
    backgroundColor: "#F9F9FB",
    maxHeight: "100%",
    height: "100%",
    maxWidth: "500px",
    minWidth: "500px",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "100vw",
      minWidth: "100vw",
    },
  },
  header: {
    display: "flex",
    backgroundColor: "#FFF",
    padding: "16px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subtitle: {
    textAlign: "center",
    color: "#E16327",
    marginTop: "8px",
  },
  bold: {
    fontWeight: "bold",
  },
  avatar: {
    width: "44px",
    height: "44px",
  },
  content: {
    padding: "16px 0",
  },
  loading: {
    textAlign: "center",
    marginTop: 10,
  },
  goBack: {
    cursor: "pointer",
  },
});

const AddResult = ({ classes, t }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const params = useParams();

  const currentRace = useRef("");
  const { epicRaces, loadingEpicRaces } = useSelector(
    (state) => state.userPlans,
  );
  const races = epicRaces || [];

  useEffect(() => {
    dispatch(getEpicRaces());
    setOpen(true);
  }, []);

  const openOrClose = (data) => {
    currentRace.current = data;
    setIsOpen((isOpen) => !isOpen);
  };

  const goBack = () => {
    const { id } = params;
    history.push(`/trailhead/${id}/races`);
  };

  const orderBy = (racesArray = []) => {
    const ordered = racesArray.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
    });

    return ordered || [];
  };

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        onClose={goBack}
        classes={{
          paper: classes.drawerRoot,
        }}
      >
        <div className={classes.header}>
          <div></div>
          <div>
            <Typography variant="body1" className={classes.subtitle}>
              {t("Add Race Result")}
            </Typography>
          </div>
          <div onClick={goBack} className={classes.goBack}>
            <Close />
          </div>
        </div>
        <div className={classes.content}>
          {loadingEpicRaces ? (
            <div className={classes.loading}>
              <CircularProgress variant="indeterminate" />
            </div>
          ) : (
            orderBy(races).map((item, index) => (
              <RaceCard
                item={item}
                key={index}
                openOrClose={openOrClose}
                title={t("Add Race Result")}
              />
            ))
          )}
        </div>
      </Drawer>
      <AddRaceForm
        isOpen={isOpen}
        openOrClose={openOrClose}
        race={currentRace.current}
        customWidth
      />
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("trailhead")(memo(AddResult)),
);
