import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubcategories } from "../actions/subcategory-action";

const useSubcategories = () => {
  const { data: subCategoriesData, loading } = useSelector(
    (state) => state.subcategories,
  );

  const dispatch = useDispatch();
  const [plansData, setPlansData] = useState([]);

  useEffect(() => {
    dispatch(getSubcategories({ readOnlyAvailables: true }));
  }, []);

  const getSubcategoriesByPlan = (plan) => {
    const data = subCategoriesData
      ? subCategoriesData
          .filter((subcategory) => plan.includes(subcategory.id))
          .map((subcategory) => ({ ...subcategory }))
      : [];
    setPlansData(data);
  };

  return {
    subCategoriesData,
    getSubcategoriesByPlan,
    plansData,
    loading,
  };
};

export default useSubcategories;
