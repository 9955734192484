import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, FormControlLabel, Switch } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

import FilterSelect from "../common/filter-select";
import trainingPeriods from "../../constants/training-periods";
import DifficultyLevels from "../../constants/difficulty-levels";
import WorkoutTypes from "../../constants/workout-types";
import Surfaces from "../../constants/surfaces";
import EstimatedTimes from "../../constants/estimated-times";

const styles = (theme) => {
  return {
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    buttons: {
      marginTop: "auto",
      marginBottom: 8,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    textField: {
      marginTop: "auto",
      marginBottom: 8,
      width: 300,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  };
};

class WorkoutFilter extends Component {
  handleApplyFilterSubmit = (e) => {
    e.preventDefault();
    const { onApplyFilter, newFilterApplied } = this.props;
    if (onApplyFilter && newFilterApplied) onApplyFilter();
  };

  render() {
    const {
      classes,
      onFilterSelect,
      onSearchPhraseChanged,
      newFilterApplied,
      hasAnyFilters,
      searchPhrase,
      isExternalCoach,
      justMine,
    } = this.props;

    return (
      <form
        className={classes.container}
        onSubmit={this.handleApplyFilterSubmit}
      >
        {isExternalCoach ? (
          <FormControlLabel
            control={
              <Switch
                checked={justMine}
                onChange={(event) =>
                  onFilterSelect({
                    name: "justMine",
                    value: !justMine,
                  })
                }
                name="overlay"
                color="primary"
              />
            }
            label={"Just show my workouts"}
            classes={{
              label: classes.switchLabel,
            }}
          />
        ) : (
          ""
        )}
        <TextField
          name="searchPhrase"
          label="Search..."
          value={searchPhrase}
          onChange={onSearchPhraseChanged}
          className={classes.textField}
          margin="none"
        />
        <div>
          <FilterSelect
            name="trainingPeriod"
            text="Training Period"
            items={trainingPeriods.itemsArray}
            onSelect={onFilterSelect}
          />
          <FilterSelect
            name="difficultyLevel"
            text="Level"
            items={DifficultyLevels.itemsArray}
            onSelect={onFilterSelect}
          />
          <FilterSelect
            name="workoutType"
            text="Workout Type"
            items={WorkoutTypes.itemsArray}
            onSelect={onFilterSelect}
          />
          <FilterSelect
            name="surface"
            text="Surface"
            items={Surfaces.itemsArray}
            onSelect={onFilterSelect}
          />
          <FilterSelect
            name="estimatedTime"
            text="Estimated Time"
            items={EstimatedTimes.itemsArray}
            onSelect={onFilterSelect}
          />
        </div>
        <Fade in={newFilterApplied && hasAnyFilters}>
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              color="secondary"
              type="submit"
              disabled={!newFilterApplied}
            >
              Search
              <SearchIcon className={classes.rightIcon} />
            </Button>
          </div>
        </Fade>
      </form>
    );
  }
}

WorkoutFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  onSearchPhraseChanged: PropTypes.func.isRequired,
  newFilterApplied: PropTypes.bool.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
  searchPhrase: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(WorkoutFilter);
