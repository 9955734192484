import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { AddIcon } from "../../icons";
import { palette } from "../../../theme/palette";

const useStyles = makeStyles({
  addRaceParent: {
    padding: "12px 16px",
    boxSizing: "border-box",
    width: "100%",
    border: `dashed 2px ${palette.lightGray}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    marginBottom: "16px",
    cursor: "pointer",
  },
  addRaceText: {
    color: palette.darkGray,
  },
});

const ButtonDashed = ({
  id = "",
  style = {},
  label = "",
  withAddIcon = true,
  onClick = (e) => console.log(e),
}) => {
  const classes = useStyles();

  return (
    <Box
      data-testid={id}
      id={id}
      style={style}
      className={classes.addRaceParent}
      onClick={onClick}
    >
      <Typography className={classes.addRaceText}>{label}</Typography>
      {withAddIcon && <AddIcon />}
    </Box>
  );
};

export default ButtonDashed;
