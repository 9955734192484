import React, { useEffect, useState } from "react";
import {
  Box,
  Link,
  Slide,
  Dialog,
  Typography,
  Button,
  FormControl,
  OutlinedInput,
  IconButton,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import history from "../../../lib/history-helper";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { AppleIcon, FacebookIcon, GoogleIcon } from "../../../components/icons";
import { useAuthContext } from "../../../context/AuthProvider";
import { Skeleton } from "@mui/material";
import { IconEye, IconEyeClosed } from "@tabler/icons-react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const OnboardingLogInConfirm = () => {
  const [openDialog, setOpenDialog] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const {
    email,
    signInType,
    getSignUp,
    resetType,
    password,
    setPassword,
    signIn,
    errorMessage,
    signInLoading,
    resetErrorMessage,
    resetPassword,
    socialSignIn,
  } = useAuthContext();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    getSignUp();
    return () => {
      resetType();
    };
  }, []);

  const { t } = useTranslation("onboarding");
  const classes = useStyles();

  const handleCloseDialog = () => {
    resetErrorMessage();
    setOpenDialog(false);
  };

  const handleResetPassword = () => {
    setPassword("");
    resetPassword();
  };

  const handleTransitionEnd = () => {
    resetErrorMessage();
    setPassword("");
    history.goBack();
  };

  const handleSignUp = () => {
    resetErrorMessage();
    history.push("/auth/sign-up");
  };

  const handleSignIn = () => {
    resetErrorMessage();
    signIn();
  };
  const renderSignInMethod = () => {
    if (!signInType) {
      return (
        <Box className={classes.parentBox}>
          <Box className={classes.contentContainer}>
            <Box className={classes.title}>
              <Skeleton
                animation="wave"
                variant="text"
                width={"60%"}
                height={30}
              />
            </Box>
            <Box className={classes.titleBox}>
              <Skeleton
                animation="wave"
                variant="text"
                width={"50%"}
                height={50}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width={"30%"}
                height={20}
              />
            </Box>
            <Skeleton
              animation="wave"
              variant="text"
              width={"80%"}
              height={20}
            />
          </Box>
          <Skeleton
            animation="wave"
            className={classes.googleButton}
            size="large"
            variant="contained"
          />
        </Box>
      );
    }
    switch (signInType.data) {
      case "Google":
        return renderSignInBox(
          email,
          t("onboarding.loginConfirm.google"),
          t("onboarding.loginConfirmDesciption.google"),
          <GoogleIcon />,
          classes.googleButton,
          classes.lightButtonText,
          () => socialSignIn(CognitoHostedUIIdentityProvider.Google),
        );
      case "Facebook":
        return renderSignInBox(
          email,
          t("onboarding.loginConfirm.facebook"),
          t("onboarding.loginConfirmDesciption.facebook"),
          <FacebookIcon />,
          classes.facebookButton,
          classes.darkButtonText,
          () => socialSignIn(CognitoHostedUIIdentityProvider.Facebook),
        );
      case "SignInWithApple":
        return renderSignInBox(
          email,
          t("onboarding.loginConfirm.apple"),
          t("onboarding.loginConfirmDesciption.apple"),
          <AppleIcon />,
          classes.appleButton,
          classes.darkButtonText,
          () => socialSignIn(CognitoHostedUIIdentityProvider.Apple),
        );
      case "email":
        return (
          <Box className={classes.parentBox}>
            <Box className={classes.contentContainer}>
              <Box className={classes.title}>
                <Typography className={classes.title}>
                  {t("onboarding.loginConfirmGreetings.email")}
                </Typography>
              </Box>
              <Box className={classes.titleBox}>
                <Typography className={classes.emailText}>{email}</Typography>
                <Link
                  onClick={handleCloseDialog}
                  className={classes.notMeButton}
                >
                  {t("onboarding.loginConfirm.NotMe")}
                </Link>
              </Box>

              <Typography className={classes.subTitle}>
                {t("onboarding.loginConfirm.enterPassword")}
              </Typography>
              <FormControl
                error={errorMessage}
                fullWidth
                hiddenLabel
                size="small"
              >
                <OutlinedInput
                  first
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder={t("onboarding.loginConfirm.passwordPlaceholder")}
                  color="secondary"
                  aria-label="naked"
                  className={classes.inputForm}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <IconEye color="#6D6F71" stroke={1} />
                      ) : (
                        <IconEyeClosed color="#6D6F71" stroke={1} />
                      )}
                    </IconButton>
                  }
                />
                <FormHelperText className={classes.errorCardText}>
                  {t(errorMessage)}
                </FormHelperText>
              </FormControl>
              <Typography className={classes.forgotPassword}>
                {t("onboarding.loginConfirm.forgotPassword")}{" "}
                <Link
                  onClick={handleResetPassword}
                  className={classes.newPassword}
                >
                  {t("onboarding.loginConfirm.getPassword")}
                </Link>
              </Typography>
            </Box>
            <Button
              className={classes.logInButton}
              disabled={signInLoading}
              size="large"
              variant="contained"
              onClick={handleSignIn}
            >
              {signInLoading ? (
                <CircularProgress color="secondary" />
              ) : (
                t("onboarding.loginConfirm.logInButton")
              )}
            </Button>
          </Box>
        );
      case "error":
        return (
          <Box className={classes.parentBox}>
            <Box className={classes.contentContainer}>
              <Box className={classes.title}>
                <Typography className={classes.title}>
                  {t("onboarding.loginConfirm.noAccount")}
                </Typography>
              </Box>
              <Box className={classes.titleBox}>
                <Typography className={classes.emailText}>{email}</Typography>
              </Box>
              <Typography className={classes.subTitle}>
                {t("onboarding.loginConfirm.optionDescription")}
              </Typography>
            </Box>
            <Box className={classes.buttonBox}>
              <Button
                className={classes.logInButton}
                size="large"
                variant="contained"
                onClick={handleCloseDialog}
              >
                {t("onboarding.loginConfirm.tryAgain")}
              </Button>
              <Button
                className={classes.signUpButton}
                size="large"
                variant="outlined"
                onClick={handleSignUp}
              >
                {t("onboarding.loginConfirm.createAccount")}
              </Button>
            </Box>
          </Box>
        );
      default:
        return (
          <Box className={classes.parentBox}>
            <Box className={classes.contentContainer}>
              <Box className={classes.title}>
                <Typography className={classes.title}>
                  {t("onboarding.loginConfirm.noAccount")}
                </Typography>
              </Box>
              <Box className={classes.titleBox}>
                <Typography className={classes.emailText}>{email}</Typography>
              </Box>
              <Typography className={classes.subTitle}>
                {t("onboarding.loginConfirm.optionDescription")}
              </Typography>
            </Box>
            <Box className={classes.buttonBox}>
              <Button
                className={classes.logInButton}
                size="large"
                variant="contained"
                onClick={handleCloseDialog}
              >
                {t("onboarding.loginConfirm.tryAgain")}
              </Button>
              <Button
                className={classes.signUpButton}
                size="large"
                variant="outlined"
                onClick={handleSignUp}
              >
                {t("onboarding.loginConfirm.createAccount")}
              </Button>
            </Box>
          </Box>
        );
    }
  };

  const renderSignInBox = (
    email,
    provider,
    subTitle,
    icon,
    buttonClass,
    buttonTextClass,
    onClick,
  ) => (
    <Box className={classes.parentBox}>
      <Box className={classes.contentContainer}>
        <Typography className={classes.title}>
          {t("onboarding.loginConfirmGreetings.email")}
        </Typography>
        <Typography className={classes.emailText}>{email}</Typography>
        <Link onClick={handleCloseDialog} className={classes.notMeButton}>
          {t("onboarding.loginConfirm.NotMe")}
        </Link>
        <Typography className={classes.subTitle}>{subTitle}</Typography>
      </Box>
      <Button
        className={buttonClass}
        size="large"
        variant="contained"
        startIcon={icon}
        onClick={onClick}
      >
        <Typography className={buttonTextClass}>
          {t("onboarding.login.continueWith")}{" "}
          <span style={{ fontWeight: 700 }}>{provider}</span>
        </Typography>
      </Button>
    </Box>
  );

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionProps={{ onExited: handleTransitionEnd }}
      TransitionComponent={Transition}
      display="flex"
      flexDirection="column"
    >
      <ToolbarReturnBack
        withLogo={true}
        logoWidth={107}
        style={{
          backgroundColor: "#FAFAFA",
          alignItems: "flex-start",
          padding: "10px",
        }}
        goBack={handleCloseDialog}
      />
      <Box className={classes.parentBox}>{renderSignInMethod}</Box>
    </Dialog>
  );
};

export default OnboardingLogInConfirm;

const useStyles = makeStyles({
  parentBox: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    padding: "16px",
    gap: "24px",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  title: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    color: "#12151B",
    textTransform: "capitalize",
    lineHeight: "22.5px",
  },
  emailText: {
    fontFamily: "Oswald",
    fontWeight: 500,
    fontSize: "18px",
    color: "#12151B",
    textTransform: "uppercase",
    lineHeight: "22.5px",
  },

  subtitle: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#12151B",
    lineHeight: "24px",
  },
  contentContainer: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "40px",
  },
  emailButton: {
    backgroundColor: "#FEFFFF",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  googleButton: {
    backgroundColor: "#FEFFFF",
    justifyContent: "center",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  facebookButton: {
    backgroundColor: "#3C5A9A",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  appleButton: {
    backgroundColor: "#12151B",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  darkButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#FEFFFF",
  },
  lightButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#12151B",
  },
  emailButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#0B2B5C",
  },
  logInButton: {
    backgroundColor: "#0B2B5C",
    height: "47px",
    color: "#FFFFFF",
    borderRadius: "4px",
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  dividerText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 700,
  },
  notReceivedText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "24px",
    fontSize: "16px",
    fontWeight: 400,
  },
  signUpText: {
    alignSelf: "center",
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 400,
  },
  notReceivedButton: {
    color: "#4780AB",
    fontWeight: 700,
    cursor: "pointer",
  },
  notMeButton: {
    color: "#4780AB",
    fontFamily: "Karla",
    fontWeight: 700,
    cursor: "pointer",
    fontSize: "14px",
    lineHeight: "21px",
  },
  newPassword: {
    color: "#4780AB",
    fontFamily: "Karla",
    fontWeight: 600,
    cursor: "pointer",
    fontSize: "14px",
    lineHeight: "21px",
  },
  signUpButton: {
    color: "#0B2B5C",
    borderColor: "#0B2B5C",
    borderWidth: "2px",
    borderRadius: "4px",
    height: "47px",
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "initial",
  },
  errorCardText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  forgotPassword: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6D6F71",
    lineHeight: "21px",
  },
  buttonBox: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
});
