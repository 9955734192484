import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import FontAwesomeIcon from "../common/font-awesome-icon";

const styles = {
  socialIcon: {
    marginRight: 14,
  },
  flatButton: {
    boxShadow: "none",
  },
};

const SignInButton = (props) => {
  const {
    classes,
    className,
    disabled,
    label,
    onClick,
    icon,
    additionalClass,
    withMargin,
  } = props;
  return (
    <Button
      variant="contained"
      color="secondary"
      fullWidth
      size="large"
      disabled={disabled}
      classes={className}
      onClick={onClick}
      style={{ marginBottom: withMargin ? 20 : 0 }}
    >
      <FontAwesomeIcon
        icon={icon}
        className={classNames(classes.socialIcon, additionalClass)}
      />
      {label}
    </Button>
  );
};

SignInButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default withStyles(styles)(SignInButton);
