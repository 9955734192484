import { API } from "aws-amplify";

class WorkoutsService {
  static API_NAME = "apiworkouts";
  static WORKOUT_SEARCH_PATH = "/workouts";
  static WORKOUT_GENERAL_SEARCH_PATH = "/workouts/search";
  static WORKOUT_SAVE_PATH = "/workouts";
  static WORKOUT_DELETE_PATH = "/workouts/:ids";

  static async searchWorkouts(searchParams) {
    let myInit = {
      // OPTIONAL
      queryStringParameters: searchParams,
    };
    const response = await API.get(
      this.API_NAME,
      this.WORKOUT_SEARCH_PATH,
      myInit,
    );
    const { success, result, error } = response;
    if (success) return result;
    else if (error) {
      if (typeof error === "string") throw new Error(error);
      else throw error;
    }
  }

  static async generalSearchWorkouts(searchParams) {
    let myInit = {
      // OPTIONAL
      queryStringParameters: searchParams,
    };
    const response = await API.get(
      this.API_NAME,
      this.WORKOUT_GENERAL_SEARCH_PATH,
      myInit,
    );
    const { success, result, error } = response;
    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async saveWorkout(workout) {
    let myInit = {
      // OPTIONAL
      body: workout,
    };
    const response = await API.put(
      this.API_NAME,
      this.WORKOUT_SAVE_PATH,
      myInit,
    );
    const { success, result, error } = response;
    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async deleteBulkWorkout(ids) {
    if (ids) {
      const response = await API.del(
        this.API_NAME,
        this.WORKOUT_DELETE_PATH.replace(":ids", ids.join(",")),
      );
      const { success, result, error } = response;

      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("INVALID INPUT");
  }
}

export default WorkoutsService;
