import React, { Component } from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

class PaceMaskedInput extends Component {
  render() {
    const { inputRef, ...other } = this.props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/[0-5]/, /\d/, ":", /[0-5]/, /\d/, "min/km"]}
        placeholderChar={"0"}
        showMask="false"
      />
    );
  }
}

PaceMaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default PaceMaskedInput;
