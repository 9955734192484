import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import { Dialog, DialogContent, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  closeIcon: {
    zIndex: 1500,
    cursor: "pointer",
    width: 32,
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  dialog: {
    backgroundColor: "rgba(0,0,0,0.9)",
  },
  player: {
    marginTop: "5%",
  },
});

const MobilePlayer = ({ onClose, open, url, autoplay = false }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose && onClose}
      aria-labelledby="signIn"
      style={{
        paddingLeft: "env(safe-area-inset-left)",
        paddingRight: "env(safe-area-inset-right)",
        paddingTop: "env(safe-area-inset-top)",
        paddingBottom: "env(safe-area-inset-bottom)",
      }}
      fullScreen
    >
      <DialogContent id="signindialog" className={classes.dialog}>
        <div className={classes.closeIcon} onClick={onClose}>
          <img
            src={`${process.env.PUBLIC_URL}/close-white-icon.svg`}
            alt="close-icon"
          />
        </div>
        <ReactPlayer
          playing={autoplay}
          url={url}
          controls={true}
          width="100%"
          height="95%"
          className={classes.player}
        />
      </DialogContent>
    </Dialog>
  );
};
MobilePlayer.propTypes = {
  open: PropTypes.bool,
  url: PropTypes.string,
  onClose: PropTypes.func,
  autoplay: PropTypes.bool,
};

export default MobilePlayer;
