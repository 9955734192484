import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  container: {
    display: "flex",
    padding: "30px 0",
    margin: "0 100px",
    alignItems: "center",
    borderTop: "1px solid #FFFFFF",
  },
  link: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 12,
  },
  white: {
    color: theme.palette.common.white,
  },
});

const FooterSocial = (props) => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <Link
        className={classes.link}
        href="https://www.instagram.com/vert.run/"
        target="_blank"
      >
        <img src={`${process.env.PUBLIC_URL}/icon-instagram.svg`} alt="" />
      </Link>

      <Link
        className={classes.link}
        href="https://www.facebook.com/profile.php?id=1326905294120693&ref=br_rs"
        target="_blank"
      >
        <img src={`${process.env.PUBLIC_URL}/icon-facebook.svg`} alt="" />
      </Link>

      <Link
        className={classes.link}
        href="https://apps.apple.com/us/app/vert-trail-ultra-training/id1560376613"
        target="_blank"
        style={{
          marginLeft: "auto",
        }}
      >
        <img src={`${process.env.PUBLIC_URL}/AS.png`} alt="" />
      </Link>

      <Link
        className={classes.link}
        href="https://play.google.com/store/apps/details?id=run.vert.app"
        target="_blank"
      >
        <img src={`${process.env.PUBLIC_URL}/GP.png`} alt="" />
      </Link>
    </div>
  );
};

FooterSocial.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FooterSocial);
