import React from "react";
import { palette } from "../../theme/palette";

const AccountIcon = ({
  width = 24,
  height = 24,
  activated = false,
  fill = null,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill={activated ? palette.primary : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.45475 5.15914C9.45475 5.81012 9.19614 6.43444 8.73583 6.89476C8.27551 7.35508 7.65119 7.61368 7.0002 7.61368C6.34921 7.61368 5.72489 7.35508 5.26457 6.89476C4.80426 6.43444 4.54565 5.81012 4.54565 5.15914C4.54565 4.50815 4.80426 3.88383 5.26457 3.42351C5.72489 2.96319 6.34921 2.70459 7.0002 2.70459C7.65119 2.70459 8.27551 2.96319 8.73583 3.42351C9.19614 3.88383 9.45475 4.50815 9.45475 5.15914ZM8.22747 5.15914C8.22747 5.48463 8.09817 5.79679 7.86801 6.02695C7.63785 6.25711 7.32569 6.38641 7.0002 6.38641C6.67471 6.38641 6.36255 6.25711 6.13239 6.02695C5.90223 5.79679 5.77293 5.48463 5.77293 5.15914C5.77293 4.83364 5.90223 4.52148 6.13239 4.29132C6.36255 4.06116 6.67471 3.93186 7.0002 3.93186C7.32569 3.93186 7.63785 4.06116 7.86801 4.29132C8.09817 4.52148 8.22747 4.83364 8.22747 5.15914Z"
      fill={fill ? fill : activated ? palette.primary : palette.black}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0.25C3.27216 0.25 0.25 3.27216 0.25 7C0.25 10.7278 3.27216 13.75 7 13.75C10.7278 13.75 13.75 10.7278 13.75 7C13.75 3.27216 10.7278 0.25 7 0.25ZM1.47727 7C1.47727 8.2825 1.9148 9.46314 2.64809 10.4008C3.16307 9.72448 3.82744 9.17641 4.58929 8.79937C5.35114 8.42233 6.18984 8.22654 7.03989 8.22727C7.87893 8.22648 8.70708 8.41721 9.46125 8.78493C10.2154 9.15266 10.8757 9.68768 11.3918 10.3492C11.9235 9.65189 12.2815 8.83797 12.4361 7.97482C12.5908 7.11166 12.5377 6.22408 12.2813 5.38551C12.0248 4.54695 11.5723 3.78151 10.9613 3.15252C10.3503 2.52354 9.59829 2.0491 8.76751 1.76845C7.93672 1.4878 7.05105 1.40902 6.18378 1.53862C5.3165 1.66822 4.49255 2.00248 3.78011 2.51374C3.06766 3.025 2.4872 3.69856 2.08675 4.47869C1.68631 5.25882 1.47738 6.12309 1.47727 7ZM7 12.5227C5.7322 12.5246 4.50266 12.0885 3.51945 11.2881C3.91521 10.7215 4.44196 10.259 5.05489 9.93974C5.66782 9.62051 6.3488 9.45405 7.03989 9.45455C7.72235 9.454 8.39509 9.61628 9.00225 9.92792C9.60941 10.2396 10.1335 10.6915 10.5309 11.2464C9.54002 12.0727 8.29022 12.5245 7 12.5227Z"
      fill={fill ? fill : activated ? palette.primary : palette.black}
    />
  </svg>
);
export default AccountIcon;
