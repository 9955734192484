import React, { useState } from "react";
import {
  STEPS_RECOVERY,
  useStepsRecovery,
} from "../app/providers/StepsRecoveryProvider";
import {
  Dialog,
  Slide,
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import Email from "./password-change/components/email";
import AuthPasswordComponent from "./password-change/components/auth-password";
import EmailSend from "./password-change/components/email-send";
import { useHistory } from "react-router-dom";
import { palette } from "../theme/palette";
import { CloseIcon } from "./icons";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";

const useStyles = makeStyles((theme) => ({
  paperDialog: {
    background: "#fbfbfb",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const RecoveryPassword = () => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(true);
  const { step, errorMessage, onSetErrorMessage } = useStepsRecovery();
  const { goBack } = useHistory();
  const handleCloseDialog = () => {
    goBack();
    setOpenDialog(false);
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
      style={{ display: "flex", flexDirection: "column" }}
      PaperProps={{
        className: classes.paperDialog,
      }}
    >
      <AppBar
        position="sticky"
        elevation={0}
        style={{ backgroundColor: palette.white }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <CloseIcon width={24} height={24} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <SnackBarMessageDisplay
        errorMessage={errorMessage}
        onHideMessage={() => onSetErrorMessage(null)}
      />
      {step === STEPS_RECOVERY.SEND_CODE && <Email />}
      {step === STEPS_RECOVERY.CHANGE_PASSWORD && <AuthPasswordComponent />}
      {step === STEPS_RECOVERY.SUCCESS && <EmailSend />}
    </Dialog>
  );
};
export default RecoveryPassword;
