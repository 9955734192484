import React from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import { palette } from "../../theme/palette";
import EventIcon from "@material-ui/icons/Event";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { RowListSkeleton } from "./row-list-skeleton";

const useStyles = makeStyles(() => ({
  card: {
    background: palette.white,
    borderRadius: "8px",
    marginTop: "12px",
    width: "275px",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
  title: {
    fontFamily: "Oswald",
    marginBottom: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: "4px",
  },
  content: {
    color: palette.darkGray,
    fontWeight: "400",
  },
  button: {
    fontFamily: "Karla",
    marginTop: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "18px",
    textTransform: "lowercase",
  },
  descriptionContainer: {
    padding: "12px",
    height: "135px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  verticalCentered: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  divider: {
    background: palette.lightGray,
    width: "2px",
    height: "40px",
    margin: "0px 12px",
  },
  label: {
    color: palette.darkGray,
    marginTop: "4px",
  },
  labelOnly: {
    color: palette.darkGray,
  },
  margin: {
    marginTop: "12px",
  },
  carousel: {
    display: "-webkit-inline-box",
    width: "100%",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  seeAll: {
    color: palette.lightBlue,
  },
  bold: {
    fontWeight: "700",
  },
  attributes: {
    display: "flex",
    justifyContent: "center",
  },
}));

const imageStyles = (url) => ({
  borderRadius: "8px 8px 0 0",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url(${url})`,
  height: "125px",
});

const iconStyle = {
  color: palette.darkGray,
  width: "18px",
  margin: 0,
  padding: 0,
};

const PopularPlans = () => {
  const { t } = useTranslation("home");
  const history = useHistory();
  const { language } = useTranslation();
  const { data, loading } = useSelector((state) => state.subcategories);
  const classes = useStyles();
  const plans = data || [];
  const filteredPlans = plans.filter(({ tags = [] }) =>
    (tags || []).includes("Popular"),
  );

  const navigate = (category) => {
    history.push("chooseplan", { id: category.id });
  };

  const navigateToAll = () => {
    history.push("chooseplan");
  };

  return (
    <>
      <Box sx={{ marginTop: "40px" }}>
        {!loading && (
          <Box className={classes.header}>
            <Typography variant="h3" className={classes.bold}>
              {t("Most popular plans")}
            </Typography>
            <Typography
              variant="body2"
              className={classes.seeAll}
              id="home_see_all_plans"
              onClick={navigateToAll}
            >
              {t("See all")}
            </Typography>
          </Box>
        )}
      </Box>

      {loading && (
        <Box sx={{ marginTop: "12px" }}>
          <RowListSkeleton width={265} height={235} />
        </Box>
      )}
      <Box className={classes.carousel}>
        {(filteredPlans || []).slice(0, 5).map((item = {}, index) => {
          return (
            <Box
              className={classes.card}
              key={item.id}
              id={`home_plans_pos${index + 1}`}
              onClick={() => navigate(item)}
            >
              <div
                alt="training-cover"
                style={imageStyles(item.cover_picture || item.picture)}
              />
              <Box className={classes.descriptionContainer}>
                <Box>
                  <Typography variant="subtitle2" className={classes.content}>
                    {item.athlete}
                  </Typography>
                  <Typography variant="h4">
                    {item.title[language]
                      ? item.title[language]
                      : item.title["en"]}
                  </Typography>
                </Box>

                <Box className={classes.margin}>
                  <Box className={classes.attributes}>
                    <>
                      <Box className={classes.centered}>
                        <Box className={classes.verticalCentered}>
                          <EventIcon style={iconStyle} />
                          <Typography
                            variant="body2"
                            className={classes.labelOnly}
                          >
                            {item.weeks} {t("weeks")}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className={classes.centered}>
                        <Box className={classes.divider} />
                      </Box>

                      <Box className={classes.centered}>
                        <Box className={classes.verticalCentered}>
                          <img
                            src={`${process.env.PUBLIC_URL}/icon_distance.svg`}
                            width={20}
                          ></img>
                          <Typography variant="body2" className={classes.label}>
                            {t("up to")} {item.max_distance}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default PopularPlans;
