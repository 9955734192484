import ActionTypes from "../constants/action-types";
import LabService from "../services/lab-service";

export const sendTrainingZones = (data) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAINING_ZONE_CALCULATOR_PENDING });
    try {
      const result = await LabService.sendTrainingZone(data);
      dispatch({
        type: ActionTypes.TRAINING_ZONE_CALCULATOR_FULLFILED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAINING_ZONE_CALCULATOR_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};
