import { useSelector, useDispatch } from "react-redux";
import { reduceFileSize } from "../lib/image-helper";
import { getUserActivity, setErrorMessage } from "../actions/user-plans-action";
import useUserInfo from "./useUserInfo";
import {
  resetPostCreationState,
  setCurrentActivity,
  setCurrentGoal,
  setCurrentImage,
  setCurrentText,
  setPostTag,
} from "../actions/communityPost-action";

import { getBase64 } from "../lib/common";
import { addPost } from "../actions/trail-head-action";
import { logEvent, setEventsMetadata } from "../lib/events-helper";

const useCreatePost = () => {
  const dispatch = useDispatch();
  const { currentPlan, sub, isSubscribed } = useUserInfo();
  const {
    badge,
    tags,
    text,
    image,
    imageExtension,
    activity,
    goal,
    epicRace,
  } = useSelector((state) => state.communityPost);

  const handleCreatePost = () => {
    const post = {
      title: "",
      text,
      tags,
      link: "",
      image,
      imageExtension,
      cognito_user_sub: sub,
      crown: isSubscribed,
      goal,
      badge: badge,
      epicRace,
    };
    dispatch(addPost(sub, post));
    dispatch(resetPostCreationState());
    setEventsMetadata({ customer_id: sub });
    logEvent("add_post_trailhead");
  };

  const handleImageChange = async (event) => {
    try {
      if (!event) return;

      const { target } = event;
      if (!target) return;

      const { files } = target;
      if (!files || files.length === 0) return;

      const selectedFile = files[0];
      if (!selectedFile) {
        handleError("Invalid File");
        return;
      }

      const { size } = selectedFile;
      const maxSize = 10240000;

      if (size < maxSize) {
        const { image: imageString, extension } = await getBase64(
          selectedFile,
          false,
        );
        dispatch(setCurrentImage(imageString, extension));
        return;
      }

      const shouldReduceFileSize = size > 1024000;

      const reducedFile = shouldReduceFileSize
        ? await reduceFileSize(selectedFile, maxSize, 1000, Infinity, 0.9)
        : selectedFile;

      const { image: imageString, extension } = await getBase64(
        reducedFile,
        false,
      );

      dispatch(setCurrentImage(imageString, extension));
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (errorMessage) => {
    dispatch(setErrorMessage(errorMessage));
  };

  const handleTextChange = (text) => {
    dispatch(setCurrentText(text));
  };

  const handleActivityChange = (activity) => {
    dispatch(getUserActivity(activity, currentPlan));
  };

  const handleSetImage = (image) => {
    dispatch(setCurrentImage(image, ".png"));
  };
  const handleResetImage = () => {
    dispatch(setCurrentImage(null, null));
  };

  const handleTagChange = (tags) => {
    dispatch(setPostTag(tags));
  };

  const handleResetActivity = () => {
    dispatch(setCurrentActivity(""));
  };
  const handleSetGoal = (goal) => {
    dispatch(setCurrentGoal(goal));
  };
  const handleResetPost = () => {
    dispatch(resetPostCreationState());
  };

  return {
    selectedImage: image,
    selectedImageExtension: imageExtension,
    selectedTags: tags,
    text,
    goal,
    activity,
    handleActivityChange,
    handleSetImage,
    handleImageChange,
    handleTagChange,
    handleTextChange,
    handleResetImage,
    handleResetActivity,
    handleSetGoal,
    handleCreatePost,
    handleResetPost,
  };
};

export default useCreatePost;
