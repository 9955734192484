import React, { memo, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, Tabs, Tab } from "@material-ui/core";
import MountainIndexBar from "./mountain-index-slider";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useSelector } from "react-redux";
import { convertUnits, round } from "../../lib/units-helper";
import moment from "moment";
import WorkoutStat from "./workout-stat";
import unitsHook from "../../hooks/unitsHook";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import MobilePlayer from "./mobile-player";
import useActivities from "../../hooks/useActivities";

const styles = (theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 12,
  },
  arrow: {
    width: 18,
  },
  dates: {
    color: "9E9E9E",
    fontSize: "14px",
  },
  centeredDate: {
    color: "9E9E9E",
    fontSize: "14px",
    textAlign: "center",
    marginTop: 12,
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: "10px",
  },
  newRow: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 24,
  },
  message: {
    fontSize: "12px",
    marginLeft: "5px",
  },
  footerText: {
    fontSize: "10px",
    fontStyle: "italic",
    marginTop: 30,
    textAlign: "center",
  },
  tab: {
    fontSize: "12px",
  },
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },

  bottomScrollPaper: {
    alignItems: "flex-end",
  },
  bottomPaperScrollBody: {
    verticalAlign: "bottom",
  },
  container: {
    margin: "32px",
    boxSizing: "border-box",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    borderRadius: "12px",
    backgroundColor: "#FFF",
  },
  boldText: {
    fontWeight: "bold",
    paddingRight: theme.spacing.unit * 0.5,
  },
  white: {
    color: theme.palette.common.white,
  },
  text: {
    color: theme.palette.text.secondaryDark,
    marginTop: "auto",
    fontSize: "small",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      marginTop: 0,
    },
  },
  raceName: {
    fontWeight: 500,
    fontSize: 15,
    color: "#212121",
    margin: "0px 0px 12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "50%",
  },
  raceBottomTitle: {
    color: "#212121",
    fontSize: 12,
    fontWeight: 400,
    margin: "0 12px 0 5px",
  },
  raceBottomText: {
    color: "#212121",
    fontSize: 14,
    fontWeight: 500,
  },
  raceLocation: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePriorityContainer: {
    display: "flex",
    alignItems: "center",
  },
  racePriority: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePrioritySelected: {
    color: "#212121",
    fontSize: 10,
    background: "rgba(255, 87, 34, 0.24)",
    fontWeight: 500,
    width: "fit-content",
    padding: "0px 4px",
  },
  racePriorityUnselected: {
    margin: "0 5px",
    background: "#FFBA9B",
    borderRadius: "100%",
    fontWeight: 900,
    fontSize: 21,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "34px",
    textAlign: "center",
  },
  raceBottomContainer: {
    display: "flex",
    alignItems: "center",
    margin: "2px 0",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      margin: "0 0 12px 0",
    },
  },
  addButtonContainer: {
    marginLeft: "10px",
    cursor: "pointer",
  },
  shareText: {
    color: "#E16327",
    fontSize: 14,
    fontWeight: "bold",
  },
  addRaceParent: {
    display: "flex",
    justifyContent: "center",
    marginTop: "12px",
    cursor: "pointer",
  },
  addRaceChild: {
    display: "flex",
    alignItems: "center",
  },
  addTitle: {
    color: "#E16327",
    fontWeight: "bold",
    marginLeft: "4px",
    fontSize: "14px",
  },
  contentDialog: {
    padding: "0px !important",
    borderRadius: "12px !important",
  },
  parent: {
    height: "100%",
    position: "fixed",
    zIndex: 1100,
    width: "100%",
    left: 0,
    top: 0,
    background: "rgba(25, 25, 25, 0.25)",
    backdropFilter: "blur(5px)",
    overflowY: "auto",
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  closeIcon: {
    zIndex: 1500,
    cursor: "pointer",
    width: 22,
  },
  rootsito: {
    overflowY: "scroll !important",
  },
  card: {
    borderRadius: "5px",
    padding: "20px",
    boxSizing: "border-box",
    margin: "32px 28px",
    background: "#FFF",
    zIndex: 1200,
  },
  raceCard: {
    ...styles.card,
    margin: "45px 28px 68px 28px",
  },
  goBackParent: {
    margin: "16px 28px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    zIndex: 1500,
  },
  mainTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const MountainIndexModal = ({ width, classes, t, onClose }) => {
  const units = unitsHook();

  const [currentRace, setCurrentRace] = useState(null);
  const [currentDate, setCurrentDate] = useState(moment());
  const [previousDate, setPreviousDate] = useState(
    moment()
      .clone()
      .subtract(0.9, "week"),
  );
  const [option, setOption] = useState(0);
  const [average, setAverage] = useState(0);
  const { userPlans } = useSelector((state) => state);

  const [openVideo, setOpenVideo] = useState(false);

  const [activities] = useActivities();

  const { currentPlan } = userPlans || {};
  const { currentTraining, nextRaces = [] } = currentPlan || {};
  const { days } = currentTraining || {};

  const calculateValue = (distance = 0, elevation = 0) => {
    const isValid =
      !isNaN(distance) && distance > 0 && !isNaN(elevation) && elevation > 0;
    const result = elevation / distance;

    return isValid ? `${round(result)}`.substring(0, 4) : 0;
  };

  const calculateTotals = (key, localActivities = []) => {
    const result = localActivities.reduce(
      (accumulator, value) => accumulator + value[key],
      0,
    );
    return result;
  };

  const convertElevationToUnit = (localElevation, units) => {
    if (isNaN(localElevation) || localElevation <= 0) return 0;
    if (units == "kms") return localElevation;
    return localElevation * 3.2808;
  };

  const comvertDistanceToUnit = (localDistance, units) => {
    if (isNaN(localDistance) || localDistance <= 0) return 0;
    if (units == "kms") return localDistance / 1000;
    return localDistance * 0.00062137;
  };

  useEffect(() => {
    if (activities && activities.length) {
      const localActivities = filterActivities(option);
      const elevation = convertElevationToUnit(
        calculateTotals("total_elevation_gain", localActivities),
        units,
      );
      const distance = comvertDistanceToUnit(
        calculateTotals("distance", localActivities),
        units,
      );
      const result = calculateValue(distance, elevation);

      const currentRace = getRace();

      if (!currentRace) setAverage(result);

      if (currentRace) {
        const resultRace = calculateValue(
          units == "kms"
            ? parseFloat(currentRace.raceDistance)
            : convertUnits(
                parseFloat(currentRace.raceDistance),
                "kms",
                "miles",
                "distance",
              ),
          units == "kms"
            ? parseFloat(currentRace.raceElevation)
            : convertUnits(
                parseFloat(currentRace.raceElevation),
                "kms",
                "miles",
                "elevation",
              ),
        );

        const comparation = calculatePercent(
          Number(resultRace),
          Number(result),
        );
        setAverage(comparation);
      }
    }
  }, [option, activities]);

  const checkRace = () => {
    const copy = [...nextRaces];
    const result = copy.filter((race) => {
      const futureRace = moment(race.raceDate, "DD/MM/YYYY").isSameOrAfter(
        moment(),
      );
      if (futureRace) return race;
    });

    return result && result.length ? true : false;
  };

  const getRace = () => {
    if (nextRaces && nextRaces.length) {
      const copy = [...nextRaces];
      const result = copy.filter((race) => {
        const futureRace = moment(race.raceDate, "DD/MM/YYYY").isSameOrAfter(
          moment(),
        );
        if (futureRace && race.raceDistance && race.raceElevation) return race;
      });

      if (result) {
        if (result.some((item) => item.racePriority === "A")) {
          const workoutA = result.find((item) => item.racePriority == "A");
          setCurrentRace(workoutA);
          return workoutA;
        }

        if (result.some((item) => item.racePriority === "B")) {
          const workoutB = result.find((item) => item.racePriority == "B");
          setCurrentRace(workoutB);
          return workoutB;
        }

        if (result.some((item) => item.racePriority === "C")) {
          const workoutC = result.find((item) => item.racePriority == "C");
          setCurrentRace(workoutC);
          return workoutC;
        }
      }

      return false;
    }
  };

  const calculatePercent = (total, activitiesMountain) => {
    if (
      !isNaN(total) &&
      total > 0 &&
      !isNaN(activitiesMountain) &&
      activitiesMountain > 0
    ) {
      const result = (activitiesMountain * 100) / total;
      return Math.round(result);
    }

    return 0;
  };

  const filterActivities = (index) => {
    if (index == 0) return filterByRange(0);
    if (index == 1) return filterByRange(3, false);
    if (index == 2) return fullPlanFilter(days);
    return activities;
  };

  const fullPlanFilter = (days) => {
    if (days) {
      const preFilter = days.filter((item) => item);
      const result = (preFilter || []).sort((a, b) => {
        if (a && b) {
          const aDate = moment(a[0].plannedDate, "DD/MM/YYYY").valueOf();
          const bDate = moment(b[0].plannedDate, "DD/MM/YYYY").valueOf();
          return aDate - bDate;
        }
      });
      const filtered = (activities || []).filter((activity) => {
        if (activity) {
          const date = activity.date ? activity.date : activity.start_date;
          if (
            moment(date).isSameOrAfter(
              moment(result[0][0].plannedDate, "DD/MM/YYYY"),
            )
          )
            return activity;
        }
      });

      setPreviousDate(moment(result[0][0].plannedDate, "DD/MM/YYYY"));
      setCurrentDate(moment());
      return filtered;
    }

    return [];
  };

  const filterByRange = (range, weekly = true) => {
    const filtered = (activities || []).filter((activity) => {
      if (activity) {
        const date = activity.date ? activity.date : activity.start_date;
        const difference = moment(currentDate).diff(moment(date), "weeks");
        if (difference == range && weekly) return activity;
        if (difference >= 0 && difference <= range) return activity;
      }
    });
    return filtered;
  };

  const handleText = (average) => {
    const withRaces = checkRace();

    if (!withRaces) return t("no race");
    if (average < 70) return t("litte elevation");
    if (average >= 70 && average <= 130) return t("medium elevation");
    if (average > 130) return t("high elevation");
  };

  const handlerChange = (event, index) => {
    const quantity = numberOfweeks[index];
    setOption(index);
    setCurrentDate(moment());
    setPreviousDate(
      moment()
        .clone()
        .subtract(quantity, "week"),
    );
  };

  const numberOfweeks = {
    "0": 0.9,
    "1": 3.9,
  };

  const handlerBack = (index) => {
    const quantity = numberOfweeks[index];
    const copy = moment(previousDate)
      .clone()
      .subtract(quantity, "week");
    setCurrentDate(moment(previousDate));
    setPreviousDate(moment(copy));
  };

  const handlerNext = (index) => {
    const quantity = numberOfweeks[index];
    const copy = moment(currentDate)
      .clone()
      .add(quantity, "week");
    setCurrentDate(moment(copy));
    setPreviousDate(moment(currentDate));
  };

  const calculateDistance = (distance, unit) => {
    if (
      (currentRace.units && unit !== currentRace.units) ||
      (!currentRace.units && unit === "miles")
    ) {
      if (units === "kms") return Math.round(distance * 1.60934);
      return Math.round(distance * 0.621371);
    }

    return Math.round(parseFloat(distance));
  };

  const calculateElevation = (elevation, unit) => {
    if (
      (currentRace.units && unit !== currentRace.units) ||
      (!currentRace.units && unit === "miles")
    ) {
      if (units === "kms") return Math.round(elevation * 0.3048);
      return Math.round(elevation * 3.28084);
    }

    return Math.round(parseFloat(elevation));
  };

  const handleOpen = () => setOpenVideo((openVideo) => !openVideo);

  return (
    <>
      <div className={classes.parent}>
        <div className={classes.goBackParent}>
          <div className={classes.closeIcon} onClick={onClose}>
            <img
              src={`${process.env.PUBLIC_URL}/close-white-icon.svg`}
              alt="close-icon"
            />
          </div>
        </div>

        <div className={classes.card}>
          <Box className={classes.mainTitleContainer}>
            <InfoIcon onClick={() => handleOpen()} />
            <Typography className={classes.title}>
              {t("Mountain Index").toUpperCase()}
            </Typography>
          </Box>

          <Box>
            <Tabs
              value={option}
              onChange={(event, index) => handlerChange(event, index)}
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab className={classes.tab} label={t("1 week")} index={0} />
              <Tab className={classes.tab} label={t("4 weeks avg")} index={1} />
              <Tab className={classes.tab} label={t("Full plan")} index={2} />
            </Tabs>
          </Box>

          <div className={option <= 1 ? classes.row : classes.centeredRow}>
            {option <= 1 && (
              <ArrowBackIosIcon
                className={classes.arrow}
                onClick={() => handlerBack(option)}
              />
            )}

            <Typography
              className={option <= 1 ? classes.dates : classes.centeredDate}
            >
              {previousDate.format("DD")} {previousDate.format("MMM")} -{" "}
              {currentDate.format("DD")} {currentDate.format("MMM")}{" "}
            </Typography>

            {option <= 1 && (
              <ArrowForwardIosIcon
                className={classes.arrow}
                onClick={() => handlerNext(option)}
              />
            )}
          </div>

          <MountainIndexBar value={average} />
          <div className={classes.newRow}>
            <div>
              <img src={`${process.env.PUBLIC_URL}/mountain-icon.svg`}></img>
            </div>
            <div>
              <Typography
                className={classes.message}
                dangerouslySetInnerHTML={{ __html: handleText(average) }}
              />
            </div>
          </div>

          <Typography className={classes.footerText}>
            {t("footer metric")}
          </Typography>
        </div>

        {currentRace && (
          <div className={classes.container}>
            <div>
              <div className={classes.titleContainer}></div>

              <Typography variant="h6" className={classes.racePrioritySelected}>
                {t("Priority")} {currentRace.racePriority}
              </Typography>

              <Typography variant="body2" className={classes.title}>
                {currentRace.name}
              </Typography>
              <Typography variant="h6" className={classes.raceName}>
                {currentRace.raceName}
              </Typography>

              <div>
                <div className={classes.raceBottomContainer}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-distance.svg`}
                    alt=""
                  />
                  <Typography variant="h6" className={classes.raceBottomTitle}>
                    {t("Distance")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.raceBottomText}
                  >
                    {currentRace.raceDistance
                      ? !units || units === "kms"
                        ? t("X Kms", {
                            value:
                              calculateDistance(
                                currentRace.raceDistance,
                                units,
                              ) || 0,
                          })
                        : t("X miles", {
                            value:
                              calculateDistance(
                                currentRace.raceDistance,
                                units,
                              ) || 0,
                          })
                      : t("Empty")}
                  </Typography>
                </div>
                <div className={classes.raceBottomContainer}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-elevation.svg`}
                    alt=""
                  />

                  <Typography variant="h6" className={classes.raceBottomTitle}>
                    {t("Elevation")}
                  </Typography>

                  <Typography
                    variant="body1"
                    className={classes.raceBottomText}
                  >
                    {currentRace.raceElevation
                      ? !units || units === "kms"
                        ? t("X ms", {
                            value:
                              calculateElevation(
                                currentRace.raceElevation,
                                units,
                              ) || 0,
                          })
                        : t("X feet", {
                            value:
                              calculateElevation(
                                currentRace.raceElevation,
                                units,
                              ) || 0,
                          })
                      : t("Empty")}
                  </Typography>
                </div>
                <div className={classes.raceBottomContainer}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-timer.svg`}
                    alt=""
                  />
                  <Typography variant="h6" className={classes.raceBottomTitle}>
                    {t("Date")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.raceBottomText}
                  >
                    {currentRace.raceDate}
                  </Typography>
                </div>
              </div>
            </div>

            <div>
              <div className={classes.statParent}>
                <WorkoutStat
                  distance={calculateDistance(currentRace.raceDistance, units)}
                  elevation={calculateElevation(
                    currentRace.raceElevation,
                    units,
                  )}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <MobilePlayer
        open={openVideo}
        onClose={handleOpen}
        url="https://videofilesvertrun.s3.amazonaws.com/Mountain_Index_Explanatory_Video.mp4"
      />
    </>
  );
};

export default withTranslation("dashboard")(
  withWidth()(
    withStyles(styles, { withTheme: true })(memo(MountainIndexModal)),
  ),
);
