import React from "react";
import { palette } from "../../theme/palette";

const CaloriesIcon = ({ width = 24, height = 24, activated = false }) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17C12.0881 17 14.625 14.5527 14.625 11.4117C14.625 10.6407 14.5856 9.81425 14.1581 8.5295C13.7306 7.24475 13.6447 7.079 13.1929 6.2855C12.9997 7.90438 11.9666 8.57938 11.7041 8.78113C11.7041 8.57113 11.0794 6.251 10.1317 4.86238C9.20137 3.5 7.93612 2.60637 7.19437 2C7.19437 3.15125 6.87075 4.86275 6.40687 5.735C5.94337 6.60688 5.85637 6.63875 5.277 7.2875C4.698 7.93625 4.43213 8.13688 3.94763 8.92438C3.4635 9.71188 3.375 10.7607 3.375 11.5317C3.375 14.6727 5.91188 17 9 17Z"
        stroke={activated ? palette.primary : palette.darkGray}
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
export default CaloriesIcon;
