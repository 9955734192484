import React from "react";
import {
  Typography,
  Card,
  CardContent,
  ListItem,
  CardActionArea,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  cardContainer: {
    width: "100%",
    backgroundColor: "#FEFFFF",
    minHeight: "80px",
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "oswald",
    color: "#12151B",
    textTransform: "uppercase",
  },
  priceContainer: {
    textAlign: "right",
  },
  priceText: {
    fontSize: "22px",
    fontWeight: 700,
    fontFamily: "oswald",
    color: "#0B2B5C",
  },
  subtext: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#6D6F71",
  },
});
const CheckoutPriceCard = ({
  title,
  price,
  subtext = null,
  onClick = () => {},
}) => {
  const classes = useStyles();

  return (
    <ListItem style={{ width: "100%", padding: "6px 16px" }}>
      <Card className={classes.cardContainer}>
        <CardActionArea onClick={onClick} style={{ height: "80px" }}>
          <CardContent className={classes.cardContent}>
            <div>
              <Typography className={classes.title}>{title}</Typography>
            </div>
            <div className={classes.priceContainer}>
              <Typography className={classes.priceText}>{price}</Typography>
              {subtext && (
                <Typography className={classes.subtext}>{subtext}</Typography>
              )}
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </ListItem>
  );
};

export default CheckoutPriceCard;
