import { combineReducers } from "redux";
import authReducer from "./auth-reducer";
import userPlansReducer from "./user-plans-reducer";
import workoutReducer from "./workout-reducer";
import trainingReducer from "./training-reducer";
import athletesReducer from "./athletes-reducer";
import stravaReducer from "./strava-reducer";
import subscriptionsReducer from "./subscriptions-reducer";
import pusherReducer from "./pusher-reducer";
import notificationsReducer from "./notifications-reducer";
import subcategoriesReducer from "./subcategories-reducer";
import trailHeadReducer from "./trail-head-reducer";
import challengesReducer from "./challenges-reducer";
import garminReducer from "./garmin-reducer";
import suuntoReducer from "./suunto-reducer";
import vertActivityReducer from "./vert-activity-reducer";
import labReducer from "./lab-reducer";
import healthKitReducer from "./healthkit-reducer";
import adminReducer from "./admin-dashboard-reducer";
import searchReducer from "./search-reducer";
import profileReducer from "./profile-reducer";
import corosReducer from "./coros-reducer";
import navbarReducer from "./navbar-reducer";
import communityReducer from "./community-reducer";
import communityPostReducer from "./communitypost-reducer";
import checkoutReducer from "./checkout-reducer";

export default combineReducers({
  auth: authReducer,
  userPlans: userPlansReducer,
  workout: workoutReducer,
  training: trainingReducer,
  athletes: athletesReducer,
  strava: stravaReducer,
  subscriptions: subscriptionsReducer,
  pusher: pusherReducer,
  notifications: notificationsReducer,
  subcategories: subcategoriesReducer,
  trailHead: trailHeadReducer,
  challenges: challengesReducer,
  garmin: garminReducer,
  suunto: suuntoReducer,
  coros: corosReducer,
  vertActivities: vertActivityReducer,
  lab: labReducer,
  healthKit: healthKitReducer,
  admin: adminReducer,
  search: searchReducer,
  profile: profileReducer,
  appNav: navbarReducer,
  community: communityReducer,
  communityPost: communityPostReducer,
  checkout: checkoutReducer,
});
