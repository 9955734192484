import React from "react";
import { Box, Typography, Button, CircularProgress } from "@material-ui/core";
import history from "../../../lib/history-helper";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../../components/common/v2/Logo";
import useQuestionnaire from "../../../hooks/useQuestionnaire";

const CheckoutProcess = () => {
  const { t } = useTranslation("checkout");
  const classes = useStyles();
  const {
    onboardingStorage,
    finishOnboarding,
    loadingSave,
  } = useQuestionnaire();

  const handleConfirm = async () => {
    const onboardingPlanType = await onboardingStorage.getItem(
      "onboardingPlanType",
    );
    if (onboardingPlanType === "explore") {
      history.push("/suggester");
    } else {
      await finishOnboarding();
      history.push("/");
    }
  };

  return (
    <Box className={classes.parentBox}>
      <Logo width={108} style={{ margin: "20px" }} />
      <Box className={classes.imageContainer}>
        <img
          className={classes.image}
          src={`${process.env.PUBLIC_URL}/subscription-finalized.webp`}
          alt="checkout"
        />
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.titleBox}>
          <Typography className={classes.title}>
            {t("checkout.welcome.title")}
          </Typography>
        </Box>
        <Typography className={classes.subTitle}>
          {t("checkout.welcome.processing")}
        </Typography>
        <Typography className={classes.text}>
          {t("checkout.welcome.description")}
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={handleConfirm}
        className={classes.startButton}
      >
        {loadingSave ? <CircularProgress /> : t("checkout.welcome.startButton")}
      </Button>
    </Box>
  );
};

export default CheckoutProcess;

const useStyles = makeStyles({
  parentBox: {
    flex: 1,
    gap: "24px",
    display: "flex",
    maxHeight: "100vh",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    alignItems: "center",
    padding: "16px",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  title: {
    fontFamily: "Oswald",
    fontWeight: 700,
    fontSize: "22px",
    color: "#12151B",
    textTransform: "uppercase",
    lineHeight: "27.5px",
  },
  imageContainer: {
    flex: 1,
    flexShrink: 1,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#D9D9D9",
    objectFit: "cover",
    position: "relative",
  },
  image: {
    position: "absolute",
    display: "block",
    maxWidth: "100%",
    height: "100%",
    width: "100%",
    aspectRatio: "1 / 1",
    objectFit: "cover",
    objectPosition: "center",
  },
  subTitle: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    color: "#12151B",
    lineHeight: "22.5px",
  },
  text: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#12151B",
    lineHeight: "24px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  startButton: {
    backgroundColor: "#F93C6E",
    height: "47px",
    color: "#FFFFFF",
    borderRadius: "4px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
    width: "100%",
  },
});
