const TrainingPeriods = {
  Basic: "Basic",
  Specific: "Specific",
  Competition: "Competition",
  Recovering: "Recovering",
  Tapering: "Tapering",
  Maintenance: "Maintenance",
};

export default {
  Items: TrainingPeriods,
  itemsArray: Object.keys(TrainingPeriods).map((key) => {
    return {
      text: TrainingPeriods[key],
      value: key,
    };
  }),
};
