import moment from "moment";

const convertUnits = (value, from, to, type) => {
  if (isNaN(value)) {
    return 0;
  }
  if (from === to || !to) {
    return value;
  }
  if (type === "distance") {
    if (from === "kms" && to === "miles") {
      return value * 0.621371;
    }
    if (from === "miles" && to === "kms") {
      return value * 1.60934;
    }
  }
  if (type === "elevation") {
    if (from === "kms" && to === "miles") {
      return value * 3.28084;
    }
    if (from === "miles" && to === "kms") {
      return value * 0.3048;
    }
  }
};

const round = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

const getPace = (timeInSeconds, distanceInMetres) => {
  if (distanceInMetres === 0) {
    return { minutes: 0, seconds: 0 };
  }

  const pace = (timeInSeconds / distanceInMetres / 60) * 1000;
  const leftover = pace % 1;
  const minutes = pace - leftover;
  const seconds = Math.round(leftover * 60);

  return {
    minutes: minutes > 60 ? 0 : minutes,
    seconds: minutes > 60 ? 0 : seconds,
  };
};

export const getMilesPace = (time, distance) => {
  if (distance === 0 || isNaN(distance)) return { minutes: 0, seconds: 0 };

  const milesDistance = distance / 1609;
  const minutes = time / 60;

  const pace = minutes / milesDistance;
  const paceMinutes = Math.floor(pace);
  const paceSeconds = Math.round((pace - paceMinutes) * 60);

  return {
    minutes: paceMinutes,
    seconds: paceSeconds,
  };
};

const getActivityCalculations = (activity = {}, units, provider) => {
  switch (provider) {
    case "strava":
      return getActivityCalculationsStrava(activity, units);
    case "garmin":
      return getActivityCalculationsGarmin(activity, units);
    case "suunto":
      return getActivityCalculationsSuunto(activity, units);
    case "vert.run":
      return getActivityCalculationsVert(activity, units);
    case "healthKit":
      return getActivityCalculationsHealthKit(activity, units);
    case "coros":
      return getActivityCalculationsCoros(activity, units);
    default:
      return null;
  }
};

const getActivityCalculationsStrava = (activity, units) => {
  const from = "kms";

  const records =
    units === "miles" ? activity.splits_standard : activity.splits_metric;

  const laps = [];

  let distanceUp = 0;
  let distanceDown = 0;
  let timeUp = 0;
  let timeDown = 0;

  if (records && records.length) {
    for (let i = 0; i < records.length; i++) {
      if (records[i]) {
        if (records[i].elevation_difference > 0) {
          distanceUp += records[i].elevation_difference;
          timeUp += records[i].moving_time;
        }
        if (records[i].elevation_difference < 0) {
          distanceDown += records[i].elevation_difference;
          timeDown += records[i].moving_time;
        }

        const lap = {
          distance: convertUnits(
            records[i].distance / 1000,
            from,
            units,
            "distance",
          ),
          time: records[i].moving_time,
          elevation: convertUnits(
            records[i].elevation_difference,
            from,
            units,
            "elevation",
          ),
          hr: records[i].average_heartrate,
          pace: getPace(
            records[i].moving_time,
            convertUnits(records[i].distance, from, units, "distance"),
          ),
        };

        laps.push(lap);
      }
    }
  }

  const samples = [];

  if (
    activity.streams &&
    activity.streams.time &&
    activity.streams.time.data &&
    activity.streams.distance &&
    activity.streams.distance.data &&
    activity.streams.altitude &&
    activity.streams.altitude.data &&
    activity.streams.latlng &&
    activity.streams.latlng.data
  ) {
    for (let i = 0; i < activity.streams.time.data.length; i++) {
      const sample = {
        distance: 0,
        elevation: 0,
        time: 0,
        hr: -1,
        latlng: [],
      };

      if (activity.streams.distance.data[i]) {
        const accDistance = convertUnits(
          activity.streams.distance.data[i] / 1000,
          from,
          units,
          "distance",
        );
        sample.distance = accDistance;
      }

      if (activity.streams.altitude.data[i]) {
        const accElevation = convertUnits(
          activity.streams.altitude.data[i],
          from,
          units,
          "elevation",
        );
        sample.elevation = accElevation;
      }

      if (activity.streams.time.data[i]) {
        sample.time = activity.streams.time.data[i];
      }

      if (
        activity.streams.heartrate &&
        activity.streams.heartrate.data &&
        activity.streams.heartrate.data[i]
      ) {
        sample.hr = activity.streams.heartrate.data[i];
      }

      if (activity.streams.latlng.data[i]) {
        sample.latlng = activity.streams.latlng.data[i];
      }

      samples.push(sample);
    }
  }

  console.log("LAPS" + JSON.stringify(laps));
  console.log("SAMPLES" + JSON.stringify(samples));
  return {
    samples,
    laps,
    name: activity.name ? activity.name : "",
    pace:
      activity.moving_time && activity.distance
        ? getPace(
            activity.moving_time,
            convertUnits(activity.distance, from, units, "distance"),
          )
        : { minutes: 0, seconds: 0 },

    uphillPace:
      distanceUp && timeUp
        ? convertUnits(distanceUp, from, units, "elevation") / (timeUp / 3600)
        : 0,
    downhillPace:
      distanceDown && timeDown
        ? convertUnits(distanceDown, from, units, "elevation") /
          (timeDown / 3600)
        : 0,

    hr: activity.average_heartrate ? activity.average_heartrate : -1,
    distance: activity.distance
      ? convertUnits(activity.distance / 1000, from, units, "distance")
      : 0,
    elevation: activity.total_elevation_gain
      ? convertUnits(activity.total_elevation_gain, from, units, "elevation")
      : 0,
    time: activity.moving_time ? activity.moving_time : activity.moving_time,
    date: moment(activity.start_date_local),
    calories: activity.calories ? activity.calories : -1,
  };
};

const getActivityCalculationsGarmin = (activity, units) => {
  const from = "kms";

  const limit = units === "miles" ? 1609.34 : 1000;

  const samples = [];
  const laps = [];

  let distanceUp = 0;
  let distanceDown = 0;
  let timeUp = 0;
  let timeDown = 0;

  if (activity && activity.samples && activity.samples.length) {
    let currentLap = 0;
    let samplesPerLap = 0;
    let accDistance = 0;
    let accElevation = 0;
    let accTime = 0;
    let accHR = 0;

    let accUp = 0;
    let accDown = 0;

    let firstElevation = -1;

    for (let i = 0; i < activity.samples.length; i++) {
      if (activity.samples[i]) {
        samplesPerLap++;

        const sample = {
          distance: 0,
          elevation: 0,
          time: 0,
          hr: -1,
          latlng: [],
        };

        if (activity.samples[i].totalDistanceInMeters) {
          accDistance = convertUnits(
            activity.samples[i].totalDistanceInMeters / 1000,
            from,
            units,
            "distance",
          );
          sample.distance = accDistance;
        }

        if (activity.samples[i].elevationInMeters) {
          accElevation = convertUnits(
            activity.samples[i].elevationInMeters,
            from,
            units,
            "elevation",
          );
          sample.elevation = accElevation;
          if (firstElevation < 0) {
            firstElevation = accElevation;
          }
        }

        if (activity.samples[i].timerDurationInSeconds) {
          accTime = activity.samples[i].timerDurationInSeconds;
          sample.time = activity.samples[i].timerDurationInSeconds;
        }

        if (activity.samples[i].heartRate) {
          accHR += activity.samples[i].heartRate;
          sample.hr = activity.samples[i].heartRate;
        }

        if (
          activity.samples[i].latitudeInDegree &&
          activity.samples[i].longitudeInDegree
        ) {
          sample.latlng = [
            activity.samples[i].latitudeInDegree,
            activity.samples[i].longitudeInDegree,
          ];
        }

        if (i > 0) {
          if (
            activity.samples[i].elevationInMeters &&
            activity.samples[i - 1].elevationInMeters
          ) {
            if (
              activity.samples[i].elevationInMeters >
              activity.samples[i - 1].elevationInMeters
            ) {
              distanceUp +=
                activity.samples[i].elevationInMeters -
                activity.samples[i - 1].elevationInMeters;
              timeUp +=
                activity.samples[i].timerDurationInSeconds -
                activity.samples[i - 1].timerDurationInSeconds;

              accUp += convertUnits(
                activity.samples[i].elevationInMeters -
                  activity.samples[i - 1].elevationInMeters,
                from,
                units,
                "elevation",
              );
            }
            if (
              activity.samples[i].elevationInMeters <
              activity.samples[i - 1].elevationInMeters
            ) {
              distanceDown +=
                activity.samples[i - 1].elevationInMeters -
                activity.samples[i].elevationInMeters;
              timeDown +=
                activity.samples[i].timerDurationInSeconds -
                activity.samples[i - 1].timerDurationInSeconds;

              accDown += convertUnits(
                activity.samples[i - 1].elevationInMeters -
                  activity.samples[i].elevationInMeters,
                from,
                units,
                "elevation",
              );
            }
          }
        }

        samples.push(sample);

        if (
          (activity.samples[i].totalDistanceInMeters &&
            activity.samples[i].totalDistanceInMeters >=
              (currentLap + 1) * limit) ||
          i === activity.samples.length - 1
        ) {
          const lap = {
            accDistance: accDistance,
            accTime: accTime,
            accElevation: accElevation,
            distance:
              accDistance -
              (laps.length ? laps[laps.length - 1].accDistance : 0),
            time: accTime - (laps.length ? laps[laps.length - 1].accTime : 0),
            elevation:
              accElevation -
              (laps.length
                ? laps[laps.length - 1].accElevation
                : firstElevation),
            hr: samplesPerLap ? accHR / samplesPerLap : -1,
            pace: getPace(
              accTime - (laps.length ? laps[laps.length - 1].accTime : 0),
              (accDistance -
                (laps.length ? laps[laps.length - 1].accDistance : 0)) *
                1000,
            ),
            accDown: accDown,
            accUp: accUp,
          };

          laps.push(lap);
          currentLap++;
          samplesPerLap = 0;
          accDistance = 0;
          accElevation = 0;
          accTime = 0;
          accHR = 0;
          accUp = 0;
          accDown = 0;
        }
      }
    }
  }

  return {
    samples,
    laps,
    name: activity.activityName ? activity.activityName : "",
    pace:
      activity.elapsed_time && activity.distance
        ? getPace(
            activity.elapsed_time,
            convertUnits(activity.distance, from, units, "distance"),
          )
        : { minutes: 0, seconds: 0 },
    uphillPace:
      distanceUp && timeUp
        ? convertUnits(distanceUp, from, units, "elevation") / (timeUp / 3600)
        : 0,
    downhillPace:
      distanceDown && timeDown
        ? convertUnits(distanceDown, from, units, "elevation") /
          (timeDown / 3600)
        : 0,
    hr: activity.averageHeartRateInBeatsPerMinute
      ? activity.averageHeartRateInBeatsPerMinute
      : -1,
    distance: activity.distance
      ? convertUnits(activity.distance / 1000, from, units, "distance")
      : 0,
    elevation: activity.totalElevationGainInMeters
      ? convertUnits(
          activity.totalElevationGainInMeters,
          from,
          units,
          "elevation",
        )
      : 0,
    time: activity.elapsed_time ? activity.elapsed_time : activity.elapsed_time,
    date: moment(activity.start_date),
    calories: activity.activeKilocalories ? activity.activeKilocalories : -1,
  };
};

const getActivityCalculationsSuunto = (activity, units) => {
  const from = "kms";

  const limit = units === "miles" ? 1609.34 : 1000;

  const samples = [];
  const laps = [];

  let distanceUp = 0;
  let distanceDown = 0;
  let timeUp = 0;
  let timeDown = 0;

  if (activity.records && activity.records.length) {
    let currentLap = 0;
    let samplesPerLap = 0;
    let accDistance = 0;
    let accElevation = 0;
    let accTime = 0;
    let accHR = 0;
    let accUp = 0;
    let accDown = 0;

    let firstElevation = -1;
    let firstTime = null;

    const records = activity.records.filter((r) => r.type === "record");

    for (let i = 0; i < records.length; i++) {
      if (records[i] && records[i].data) {
        samplesPerLap++;

        const sample = {
          distance: 0,
          elevation: 0,
          time: 0,
          hr: -1,
          latlng: [],
        };

        if (records[i].data.distance) {
          accDistance = convertUnits(
            records[i].data.distance / 1000,
            from,
            units,
            "distance",
          );
          sample.distance = accDistance;
        }

        if (records[i].data.altitude) {
          accElevation = convertUnits(
            records[i].data.altitude,
            from,
            units,
            "elevation",
          );
          sample.elevation = accElevation;
          if (firstElevation < 0) {
            firstElevation = accElevation;
          }
        }

        if (records[i].data.timestamp) {
          if (i === 0) {
            firstTime = moment(records[i].data.timestamp);
            accTime = 0;
            sample.time = 0;
          } else {
            const diffInSeconds = Math.abs(
              firstTime.diff(moment(records[i].data.timestamp), "seconds"),
            );
            accTime = diffInSeconds;
            sample.time = diffInSeconds;
          }
        }

        if (records[i].data.heart_rate) {
          accHR += records[i].data.heart_rate;
          sample.hr = records[i].data.heart_rate;
        }

        if (records[i].data.position_lat && records[i].data.position_long) {
          sample.latlng = [
            records[i].data.position_lat,
            records[i].data.position_long,
          ];
        }

        if (i > 0) {
          if (records[i].data.altitude && records[i - 1].data.altitude) {
            if (records[i].data.altitude > records[i - 1].data.altitude) {
              distanceUp +=
                records[i].data.altitude - records[i - 1].data.altitude;

              const diffInSeconds = Math.abs(
                moment(records[i - 1].data.timestamp).diff(
                  moment(records[i].data.timestamp),
                  "seconds",
                ),
              );
              timeUp += diffInSeconds;

              accUp += convertUnits(
                records[i].data.altitude - records[i - 1].data.altitude,
                from,
                units,
                "elevation",
              );
            }
            if (records[i].data.altitude < records[i - 1].data.altitude) {
              distanceDown +=
                records[i - 1].data.altitude - records[i].data.altitude;
              const diffInSeconds = Math.abs(
                moment(records[i - 1].data.timestamp).diff(
                  moment(records[i].data.timestamp),
                  "seconds",
                ),
              );
              timeDown += diffInSeconds;

              accDown += convertUnits(
                records[i - 1].data.altitude - records[i].data.altitude,
                from,
                units,
                "elevation",
              );
            }
          }
        }

        samples.push(sample);

        if (
          (records[i].data.distance &&
            records[i].data.distance >= (currentLap + 1) * limit) ||
          i === records.length - 1
        ) {
          const lap = {
            accDistance: accDistance,
            accTime: accTime,
            accElevation: accElevation,
            distance:
              accDistance -
              (laps.length ? laps[laps.length - 1].accDistance : 0),
            time: accTime - (laps.length ? laps[laps.length - 1].accTime : 0),
            elevation:
              accElevation -
              (laps.length
                ? laps[laps.length - 1].accElevation
                : firstElevation),
            hr: samplesPerLap ? accHR / samplesPerLap : -1,
            pace: getPace(
              accTime - (laps.length ? laps[laps.length - 1].accTime : 0),
              (accDistance -
                (laps.length ? laps[laps.length - 1].accDistance : 0)) *
                1000,
            ),
            accDown: accDown,
            accUp: accUp,
          };

          laps.push(lap);
          currentLap++;
          samplesPerLap = 0;
          accDistance = 0;
          accElevation = 0;
          accTime = 0;
          accHR = 0;

          accUp = 0;
          accDown = 0;
        }
      }
    }
  }

  return {
    samples,
    laps,
    name: activity.workoutName ? activity.workoutName : "",
    pace:
      activity.elapsed_time && activity.distance
        ? getPace(
            activity.elapsed_time,
            convertUnits(activity.distance, from, units, "distance"),
          )
        : { minutes: 0, seconds: 0 },

    uphillPace:
      distanceUp && timeUp
        ? convertUnits(distanceUp, from, units, "elevation") / (timeUp / 3600)
        : 0,
    downhillPace:
      distanceDown && timeDown
        ? convertUnits(distanceDown, from, units, "elevation") /
          (timeDown / 3600)
        : 0,
    hr:
      activity.hrdata && activity.hrdata.workoutAvgHR
        ? activity.hrdata.workoutAvgHR
        : -1,
    distance: activity.distance
      ? convertUnits(activity.distance / 1000, from, units, "distance")
      : 0,
    elevation: activity.totalAscent
      ? convertUnits(activity.totalAscent, from, units, "elevation")
      : 0,
    time: activity.elapsed_time ? activity.elapsed_time : activity.elapsed_time,
    date: moment(activity.start_date),
    calories: activity.energyConsumption ? activity.energyConsumption : -1,
  };
};

const getActivityCalculationsCoros = (activity, units) => {
  const from = "kms"; // Change this based on the original unit of the data

  const limit = units === "miles" ? 1609.34 : 1000;

  const samples = [];
  const laps = [];

  let distanceUp = 0;
  let distanceDown = 0;
  let timeUp = 0;
  let timeDown = 0;

  if (
    activity &&
    activity.result &&
    activity.result.sessions &&
    activity.result.sessions.length
  ) {
    let currentLap = 0;
    let samplesPerLap = 0;
    let accDistance = 0;
    let accElevation = 0;
    let accTime = 0;
    let accHR = 0;

    let accUp = 0;
    let accDown = 0;

    let firstElevation = -1;
    let firstTime = null;

    for (let i = 0; i < activity.result.sessions[0].laps.length; i++) {
      if (activity.result.sessions[0].laps[i]) {
        const records = activity.result.sessions[0].laps[i].records;

        for (const record of records) {
          const sample = {
            distance: convertUnits(record.distance, from, units, "distance"),
            elevation: record.altitude
              ? convertUnits(record.altitude, from, units, "elevation")
              : null,
            time: record.elapsed_time,
            hr: record.heart_rate,
            latlng: [record.position_lat, record.position_long],
          };

          samples.push(sample);
          samplesPerLap++;
        }

        accDistance = convertUnits(
          activity.result.sessions[0].laps[i].total_distance,
          from,
          units,
          "distance",
        );
        accElevation = convertUnits(
          activity.result.sessions[0].laps[i].total_ascent,
          from,
          units,
          "elevation",
        );
        let pace = getPace(
          activity.result.sessions[0].laps[i].total_timer_time,
          activity.result.sessions[0].laps[i].total_distance,
        );

        if (activity.result.sessions[0].laps[i].total_timer_time) {
          accTime = activity.result.sessions[0].laps[i].total_elapsed_time;
        }

        if (activity.result.sessions[0].laps[i].avg_heart_rate) {
          accHR += activity.result.sessions[0].laps[i].avg_heart_rate;
        }

        distanceUp += activity.result.sessions[0].laps[i].total_ascent;
        timeUp += activity.result.sessions[0].laps[i].total_timer_time;

        accUp += convertUnits(
          activity.result.sessions[0].laps[i].total_ascent,
          from,
          units,
          "elevation",
        );

        accDown += convertUnits(
          activity.result.sessions[0].laps[i].total_descent,
          from,
          units,
          "elevation",
        );

        let elevation =
          accElevation -
          (laps.length ? laps[laps.length - 1].accElevation : firstElevation);

        let lapTime = Math.round(accDistance / (accTime / 3600));
        let vertSpeed = elevation / (accTime / 3600);

        const lap = {
          accDistance: accDistance,
          distance: accDistance,
          accTime: accTime,
          accElevation: accElevation,
          time: lapTime,
          elevation: elevation,
          hr: samplesPerLap ? accHR / samplesPerLap : -1,
          pace: pace,
          accDown: accDown,
          accUp: accUp,
          speed: activity.result.sessions[0].laps[i].avg_speed,
          vertSpeed: vertSpeed > 0 ? vertSpeed : 0,
        };

        laps.push(lap);
        currentLap++;
        samplesPerLap = 0;
        accDistance = 0;
        accElevation = 0;
        accTime = 0;
        accHR = 0;
        accUp = 0;
        accDown = 0;
      }
    }
  }

  return {
    samples,
    laps,
    name: activity.result.sessions[0].sport
      ? activity.result.sessions[0].sport
      : "",
    uphillPace:
      distanceUp && timeUp
        ? convertUnits(distanceUp, from, units, "elevation") / (timeUp / 3600)
        : 0,
    downhillPace: 0,
    hr: activity.result.sessions[0].avg_heart_rate
      ? activity.result.sessions[0].avg_heart_rate
      : -1,
    distance: activity.result.sessions[0].total_distance
      ? convertUnits(
          activity.result.sessions[0].total_distance,
          from,
          units,
          "distance",
        )
      : 0,
    elevation: activity.result.sessions[0].total_ascent
      ? convertUnits(
          activity.result.sessions[0].total_ascent,
          from,
          units,
          "elevation",
        )
      : 0,
    time: activity.result.sessions[0].total_elapsed_time
      ? activity.result.sessions[0].total_elapsed_time
      : 0,
    date: moment(activity.result.timestamp),
    calories: activity.result.sessions[0].total_calories
      ? activity.result.sessions[0].total_calories
      : -1,
  };
};

const getActivityCalculationsVert = (activity, units) => {
  const from = "kms";
  const limit = units === "miles" ? 1.60934 : 1;

  const samples = [];
  const laps = [];

  let distanceUp = 0;
  let distanceDown = 0;
  let timeUp = 0;
  let timeDown = 0;

  let distance = 0;
  let elevationInMeters = 0;
  let elapsed_time = 0;
  let moving_time = 0;

  if (
    (!activity.rawSamples || !activity.rawSamples.length) &&
    (activity.rawSamples || activity.rawSamples.length)
  ) {
    return {
      ...activity,
      distance: activity.distance
        ? convertUnits(activity.distance / 1000, from, units, "distance")
        : 0,
      elevation: activity.elevation
        ? convertUnits(activity.elevation, from, units, "elevation")
        : 0,
    };
  }

  if (activity && activity.rawSamples && activity.rawSamples.length) {
    let currentLap = 0;
    let samplesPerLap = 0;
    let accDistance = 0;
    let accElevation = 0;
    let accTime = 0;

    let accUp = 0;
    let accDown = 0;

    let firstElevation = -1;

    let lastElevationChange = 0;

    for (let i = 0; i < activity.rawSamples.length; i++) {
      if (activity.rawSamples[i]) {
        samplesPerLap++;

        const sample = {
          distance: 0,
          elevation: 0,
          time: 0,
          hr: -1,
          latlng: [],
        };

        const sampleDistance =
          i == 0
            ? 0
            : getDistanceFromLatLonInKM(
                activity.rawSamples[i - 1].latitude,
                activity.rawSamples[i - 1].longitude,
                activity.rawSamples[i].latitude,
                activity.rawSamples[i].longitude,
              );

        sample.latlng = [
          activity.rawSamples[i].latitude,
          activity.rawSamples[i].longitude,
        ];

        const convertedSampleDistance = convertUnits(
          sampleDistance,
          from,
          units,
          "distance",
        );
        accDistance += convertedSampleDistance;

        distance += convertedSampleDistance;
        sample.distance = distance;

        const sampleElevationChange =
          i == 0
            ? 0
            : activity.rawSamples[i - 1].altitude -
              activity.rawSamples[i].altitude;

        accElevation += convertUnits(
          sampleElevationChange,
          from,
          units,
          "elevation",
        );

        sample.elevation = convertUnits(
          activity.rawSamples[i].altitude,
          from,
          units,
          "elevation",
        );

        if (firstElevation < 0) {
          firstElevation = accElevation;
        }

        const sampleTime =
          i == 0
            ? 0
            : activity.rawSamples[i].time - activity.rawSamples[0].time;
        accTime = sampleTime / 1000;
        sample.time = sampleTime / 1000;
        elapsed_time = accTime;
        if (i > 0 && !activity.rawSamples[i - 1].paused) {
          moving_time +=
            (activity.rawSamples[i].time - activity.rawSamples[i - 1].time) /
            1000;
        }

        if (i > 0) {
          if (
            activity.rawSamples[i].altitude &&
            activity.rawSamples[lastElevationChange].altitude
          ) {
            if (
              Math.abs(
                activity.rawSamples[i].altitude -
                  activity.rawSamples[lastElevationChange].altitude,
              ) > 5
            ) {
              if (
                activity.rawSamples[i].altitude >
                activity.rawSamples[lastElevationChange].altitude
              ) {
                distanceUp +=
                  activity.rawSamples[i].altitude -
                  activity.rawSamples[lastElevationChange].altitude;
                timeUp +=
                  (activity.rawSamples[i].time -
                    activity.rawSamples[lastElevationChange].time) /
                  1000;

                const metersUp = convertUnits(
                  activity.rawSamples[i].altitude -
                    activity.rawSamples[lastElevationChange].altitude,
                  from,
                  units,
                  "elevation",
                );

                accUp += metersUp;

                elevationInMeters += metersUp;
              }
              if (
                activity.rawSamples[i].altitude <
                activity.rawSamples[lastElevationChange].altitude
              ) {
                distanceDown +=
                  activity.rawSamples[lastElevationChange].altitude -
                  activity.rawSamples[i].altitude;
                timeDown +=
                  (activity.rawSamples[i].time -
                    activity.rawSamples[lastElevationChange].time) /
                  1000;

                accDown += convertUnits(
                  activity.rawSamples[lastElevationChange].altitude -
                    activity.rawSamples[i].altitude,
                  from,
                  units,
                  "elevation",
                );
              }

              lastElevationChange = i;
            }
          }
        }

        samples.push(sample);

        if (accDistance >= 1 || i === activity.rawSamples.length - 1) {
          const lap = {
            accDistance: distance,
            accTime: accTime,
            accElevation,
            distance: accDistance,
            time: accTime - (laps.length ? laps[laps.length - 1].accTime : 0),
            elevation: accElevation,
            pace: getPace(
              accTime - (laps.length ? laps[laps.length - 1].accTime : 0),
              accDistance * 1000,
            ),
            accDown: accDown,
            accUp: accUp,
          };

          laps.push(lap);
          currentLap++;
          samplesPerLap = 0;
          accDistance = 0;
          accElevation = 0;
          accTime = 0;
          accUp = 0;
          accDown = 0;
        }
      }
    }
  }

  return {
    samples,
    rawSamples: activity.rawSamples ? activity.rawSamples : [],
    laps,
    name: activity.name
      ? activity.name
      : activity.activityName
      ? activity.activityName
      : "",
    description: activity.description,
    pace:
      moving_time && distance
        ? getPace(moving_time, distance * 1000)
        : { minutes: 0, seconds: 0 },
    uphillPace:
      distanceUp && distanceUp > 0 && timeUp
        ? convertUnits(distanceUp, from, units, "elevation") / (timeUp / 3600)
        : 0,
    downhillPace:
      distanceDown && distanceDown > 0 && timeDown
        ? convertUnits(distanceDown, from, units, "elevation") /
          (timeDown / 3600)
        : 0,
    distance: distance ? distance : 0,
    elevation: elevationInMeters ? elevationInMeters : 0,
    total_elevation_gain: elevationInMeters ? elevationInMeters : 0,
    time: moving_time,
    date:
      activity.rawSamples && activity.rawSamples.length
        ? moment(activity.rawSamples[0].time)
        : moment(),
    start_date:
      activity.rawSamples && activity.rawSamples.length
        ? new Date(activity.rawSamples[0].time).toISOString()
        : new Date().toISOString(),
    created_at:
      activity.rawSamples && activity.rawSamples.length
        ? activity.rawSamples[0].time
        : new Date().getTime(),
    elapsed_time,
    moving_time,
    device: "vert.run",
  };
};

const getActivityCalculationsHealthKit = (activity, units) => {
  const from = "kms";

  if (activity && !activity.rawSamples) {
    return {
      ...activity,
      rawSamples: [],
      uphillPace: 0,
      downhillPace: 0,
      samples: [],
      laps: [],
      distance: convertUnits(activity.distance / 1000, from, units, "distance"),
      elevation: convertUnits(activity.elevation, from, units, "elevation"),
      date: moment(activity.date),
    };
  }
};

const getDistanceFromLatLonInKM = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

export { convertUnits, round, getPace, getActivityCalculations };
