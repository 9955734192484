import ActionTypes from "../constants/action-types";
import TrailHeadService from "../services/trail-head-service";
import UserPlansService from "../services/user-plans-service";
import AthletesService from "../services/athletes-service";
import { getNotifications } from "./notifications-action";
import { getCurrentPlan } from "./user-plans-action";
import { logEvent, setEventsMetadata } from "../lib/events-helper";

const getAthlete = (cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_GET_ATHLETE_PENDING });
    try {
      const result = await TrailHeadService.getAthleteProfile(cognito_user_sub);
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_ATHLETE_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_ATHLETE_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const updateProfile = (plan) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_UPDATE_PROFILE_PENDING });
    try {
      const result = await UserPlansService.updateCurrentUserInfo(plan);
      dispatch({
        type: ActionTypes.TRAIL_HEAD_UPDATE_PROFILE_FULFILLED,
        payload: result,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_UPDATE_PROFILE_REJECTED,
        payload: exp,
      });
    }
  };
};

const getTags = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_GET_TAGS_PENDING });
    try {
      const result = await TrailHeadService.getTags();
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_TAGS_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_TAGS_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getPublicGroups = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_GET_PUBLIC_GROUPS_PENDING });
    try {
      const result = await TrailHeadService.getPublicGroups();
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_PUBLIC_GROUPS_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_PUBLIC_GROUPS_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const newPost = (
  title,
  text,
  image,
  imageBase64 = null,
  badge = null,
  epicRace = null,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_NEW_POST,
      payload: { title, text, image, imageBase64, badge, epicRace },
    });
  };
};

const cleanNewPost = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_CLEAN_NEW_POST,
    });
  };
};

const addPost = (cognito_user_sub, post) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_ADD_POST_PENDING });
    try {
      const result = await TrailHeadService.addPost(cognito_user_sub, post);
      dispatch({
        type: ActionTypes.TRAIL_HEAD_ADD_POST_FULFILLED,
        payload: result,
      });
      dispatch(getPosts(new Date().getTime() + 10000, null, null, false, true));
      if (post.group) {
        dispatch(getGroupPosts(new Date().getTime() + 10000, post.group, true));
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_ADD_POST_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const deletePost = (cognito_user_sub, post) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_DELETE_POST_PENDING });
    try {
      const result = await TrailHeadService.deletePost(cognito_user_sub, post);
      dispatch({
        type: ActionTypes.TRAIL_HEAD_DELETE_POST_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_DELETE_POST_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getPosts = (
  lastDate,
  searchPhrase,
  tags,
  showReported,
  reload = false,
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_GET_POSTS_PENDING,
      payload: { reload },
    });
    try {
      await dispatch(getPinnedPosts());
      const result = await TrailHeadService.getPosts(
        lastDate,
        searchPhrase,
        tags,
        showReported,
      );
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_POSTS_FULFILLED,
        payload: { result, reload },
      });
      await dispatch(getNotifications(false));
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_POSTS_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getGroupPosts = (lastDate, group, reload = false) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_GET_GROUP_POSTS_PENDING,
      payload: { reload },
    });
    try {
      const result = await TrailHeadService.getGroupPosts(lastDate, group);
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_GROUP_POSTS_FULFILLED,
        payload: { result },
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_GROUP_POSTS_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getPinnedPosts = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_GET_PINNED_POSTS_PENDING,
    });
    try {
      const result = await TrailHeadService.getPinnedPosts();
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_PINNED_POSTS_FULFILLED,
        payload: { result, reload: true },
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_PINNED_POSTS_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getPost = (id) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_GET_POST_PENDING,
    });
    try {
      const result = await TrailHeadService.getPost(id);
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_POST_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_POST_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getExplorePosts = (lastDate, type, reload = false) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_GET_EXPLORE_POSTS_PENDING,
      payload: { type, reload },
    });
    try {
      const result = await TrailHeadService.getExplorePosts(lastDate, type);
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_EXPLORE_POSTS_FULFILLED,
        payload: { result, type, reload },
      });
      await dispatch(getNotifications(false));
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_EXPLORE_POSTS_REJECTED,
        payload: { err, type },
        error: true,
      });
    }
  };
};

const getExplorePost = (id) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_GET_EXPLORE_POST_PENDING,
    });
    try {
      const result = await TrailHeadService.getExplorePost(id);
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_EXPLORE_POST_FULFILLED,
        payload: result,
      });
      if (result && result.related) {
        dispatch(getRelatedExplorePosts(result.related));
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_EXPLORE_POST_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getExploreFeaturedPost = (id) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_GET_EXPLORE_FEATURED_POST_PENDING,
    });
    try {
      const result = await TrailHeadService.getExploreFeaturedPost();
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_EXPLORE_FEATURED_POST_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_EXPLORE_FEATURED_POST_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getRelatedExplorePosts = (related) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_GET_RELATED_EXPLORE_POST_PENDING,
    });
    try {
      const result = await Promise.all(
        related.map((id) => {
          return TrailHeadService.getExplorePost(id);
        }),
      );
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_RELATED_EXPLORE_POST_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_RELATED_EXPLORE_POST_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const addAllez = (cognito_user_sub, postData) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_ADD_ALLEZ_PENDING,
      payload: postData,
    });
    try {
      const result = await TrailHeadService.addAllez(
        cognito_user_sub,
        postData,
      );
      setEventsMetadata({ customer_id: cognito_user_sub });
      logEvent("add_like_trailhead");
      dispatch({
        type: ActionTypes.TRAIL_HEAD_ADD_ALLEZ_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_ADD_ALLEZ_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const addReport = (cognito_user_sub, postData) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_ADD_REPORT_PENDING });
    try {
      const result = await TrailHeadService.addReport(
        cognito_user_sub,
        postData,
      );
      dispatch({
        type: ActionTypes.TRAIL_HEAD_ADD_REPORT_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_ADD_REPORT_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const addComment = (cognito_user_sub, comment) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_ADD_COMMENT_PENDING,
      payload: comment,
    });
    try {
      const result = await TrailHeadService.addComment(
        cognito_user_sub,
        comment,
      );
      dispatch({
        type: ActionTypes.TRAIL_HEAD_ADD_COMMENT_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_ADD_COMMENT_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const deleteComment = (cognito_user_sub, comment) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_DELETE_COMMENT_PENDING });
    try {
      const result = await TrailHeadService.deleteComment(
        cognito_user_sub,
        comment,
      );
      dispatch({
        type: ActionTypes.TRAIL_HEAD_DELETE_COMMENT_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_DELETE_COMMENT_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const editPost = (cognito_user_sub, editData) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_EDIT_POST_PENDING });
    try {
      const result = await TrailHeadService.editPost(
        cognito_user_sub,
        editData,
      );
      dispatch({
        type: ActionTypes.TRAIL_HEAD_EDIT_POST_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_EDIT_POST_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getUserPosts = (cognito_user_sub, lastDate) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_GET_USER_POSTS_PENDING });
    try {
      const result = await TrailHeadService.getUserPosts(
        cognito_user_sub,
        lastDate,
      );
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_USER_POSTS_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRIAL_HEAD_GET_USER_POSTS_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const toggleFavorites = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_TOGGLE_FAVORITES,
    });
  };
};

const clearAthlete = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_GET_ATHLETE_FULFILLED,
      payload: null,
    });
    dispatch({
      type: ActionTypes.UPDATE_SELECTED_ATHLETE_PENDING,
      payload: true,
    });
  };
};

const updateAthleteData = (body) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SELECTED_ATHLETE_PENDING,
      payload: false,
    });
    try {
      dispatch({
        type: ActionTypes.UPDATE_SELECTED_ATHLETE_FULLFILLED,
        payload: body,
      });
    } catch (err) {
      return err;
    }
  };
};

const toLoadAverage = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SELECTED_ATHLETE_PENDING,
      payload: true,
    });
  };
};

/*const getAthleteImageUrl = ({ image, cognito_user_sub }) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_LOAD_IMAGE_URL_PENDING });
    try {
      const result = await Storage.get(image, { level: "public" });
      dispatch({
        type: ActionTypes.TRAIL_HEAD_LOAD_IMAGE_URL_FULFILLED,
        payload: { imageUrl: result, cognito_user_sub: cognito_user_sub },
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_LOAD_IMAGE_URL_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};*/

const clearGroup = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRAIL_HEAD_CLEAR_GROUP,
    });
  };
};

const joinGroup = (cognito_user_sub, group) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_JOIN_GROUP_PENDING });
    try {
      const result = await TrailHeadService.joinGroup(cognito_user_sub, group);
      dispatch({
        type: ActionTypes.TRAIL_HEAD_JOIN_GROUP_FULFILLED,
        payload: result,
      });
      dispatch(getCurrentPlan(false, null, false));
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_JOIN_GROUP_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const acceptJoinGroup = (cognito_user_sub, group) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_JOIN_GROUP_PENDING });
    try {
      const result = await TrailHeadService.acceptJoinGroup(
        cognito_user_sub,
        group,
      );
      dispatch({
        type: ActionTypes.TRAIL_HEAD_JOIN_GROUP_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_JOIN_GROUP_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const addGroupActivities = (cognito_user_sub, group, activities) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_ADD_GROUP_ACTIVITIES_PENDING });
    try {
      const result = await TrailHeadService.addGroupActivities(
        cognito_user_sub,
        group,
        activities,
      );
      dispatch({
        type: ActionTypes.TRAIL_HEAD_ADD_GROUP_ACTIVITIES_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_ADD_GROUP_ACTIVITIES_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const leaveGroup = (cognito_user_sub, group) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_LEAVE_GROUP_PENDING });
    try {
      const result = await TrailHeadService.leaveGroup(cognito_user_sub, group);
      dispatch({
        type: ActionTypes.TRAIL_HEAD_LEAVE_GROUP_FULFILLED,
        payload: result,
      });
      dispatch(getCurrentPlan(false, null, false));
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_LEAVE_GROUP_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getGroup = (group, isAdmin = false) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_GET_GROUP_PENDING });
    try {
      const result = await TrailHeadService.getGroup(group, isAdmin);
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_GROUP_FULFILLED,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_GROUP_REJECTED,
        payload: err,
        error: true,
      });
    }
  };
};

const getAthleteActivities = (param) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.TRAIL_HEAD_GET_USER_ACTIVITY_PENDING });
    try {
      const result = await AthletesService.getAthleteActivities(param);
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_USER_ACTIVITY_FULFILLED,
        payload: {
          result,
          strava_athlete_id: param.strava_athlete_id,
          garmin_oauth_token: param.oauth_token,
          access_token: param.access_token,
        },
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.TRAIL_HEAD_GET_USER_ACTIVITY_REJECTED,
        payload: exp,
        error: true,
      });
    }
  };
};

export {
  getAthlete,
  updateProfile,
  getTags,
  addPost,
  deletePost,
  getPost,
  getPosts,
  addAllez,
  addComment,
  deleteComment,
  editPost,
  addReport,
  newPost,
  cleanNewPost,
  getExplorePosts,
  getExplorePost,
  getRelatedExplorePosts,
  getExploreFeaturedPost,
  toggleFavorites,
  getUserPosts,
  getPublicGroups,
  joinGroup,
  acceptJoinGroup,
  clearAthlete,
  getGroup,
  leaveGroup,
  clearGroup,
  getGroupPosts,
  addGroupActivities,
  getAthleteActivities,
  updateAthleteData,
  toLoadAverage,
  getPinnedPosts,
};
