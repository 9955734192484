import React, { useEffect, useState } from "react";
import {
  Box,
  Slide,
  Dialog,
  Typography,
  Button,
  Link,
} from "@material-ui/core";
import Divider from "@mui/material/Divider";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import history from "../../../lib/history-helper";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import useQuestionnaire from "../../../hooks/useQuestionnaire";
import OnboardingPlanCard from "../../../components/common/v2/OnboardingPlanCard";
import PlanDetailsDialog from "../../../components/common/v2/PlanDetailsDialog";
import OnboardingPlanCardSkeleton from "../../../components/common/v2/OnboardingPlanCardSkeleton";
import useUserInfo from "../../../hooks/useUserInfo";
import { useAuthContext } from "../../../context/AuthProvider";
import { logEvent } from "../../../lib/events-helper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const OnboardingChoosePlan = () => {
  const [openDialog, setOpenDialog] = useState(true);
  const { isSubscribed } = useUserInfo();
  const [detailsDialog, setDetailsDialog] = useState(false);
  const { finishOnboardingAttribute } = useAuthContext();

  const [selectedPlan, setSelectedPlan] = useState();
  const {
    onboardingStorage,
    plansData,
    getSubcategoriesByPlan,
    subCategoriesData,
    loadingSubCategories,
    planToSelectQuestionaire,
  } = useQuestionnaire();

  const { t } = useTranslation("onboarding");
  const classes = useStyles();
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleTransitionEnd = () => {
    history.goBack();
  };

  const handleConfirm = async () => {
    logEvent("have_coach_choose_plan");
    await onboardingStorage.setItem("onboardingPlanType", "coach");
    await onboardingStorage.setItem("onboardingPlan", {});
    history.push("/suggester");
  };

  const handleDetails = (plan) => {
    setSelectedPlan(plan);
    setDetailsDialog(true);
  };

  const handleSkip = async () => {
    await finishOnboardingAttribute();
    history.replace("/");
  };

  const handleStartTraining = async (plan) => {
    await onboardingStorage.setItem("onboardingPlanType", plan.plan);
    await onboardingStorage.setItem("onboardingPlan", plan);
    planToSelectQuestionaire(plan);

    if (plan.plan === "explore" && isSubscribed === false) {
      history.push("/subscription/explore");
    }

    if (plan.plan === "free" || isSubscribed === true) {
      history.push("/suggester");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const item = await onboardingStorage.getItem("onboardingGoal");
      getSubcategoriesByPlan(plans[item ? item : "run5kto10k"]);
    };

    fetchData();
  }, [subCategoriesData]);

  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionProps={{ onExited: handleTransitionEnd }}
        TransitionComponent={Transition}
        display={"flex"}
        flexDirection="column"
      >
        <ToolbarReturnBack
          withLogo={true}
          logoWidth={107}
          style={{
            backgroundColor: "#FEFFFF",
            padding: "10px",
          }}
          goBack={handleCloseDialog}
          withBox={false}
          customItem={
            <Typography className={classes.skip} onClick={handleSkip}>
              {t("onboarding.plan.skip")}
            </Typography>
          }
        />
        <Box className={classes.parentBox}>
          <Box className={classes.contentContainer}>
            <Box className={classes.titleBox}>
              <Typography className={classes.title}>
                {t("onboarding.plan.header.title")}
              </Typography>
            </Box>
            <Typography className={classes.subTitle}>
              {t("onboarding.plan.header.description")}
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={handleConfirm}
            className={classes.coachButton}
          >
            {t("onboarding.plan.header.button")}
          </Button>
          <Divider>
            <Typography className={classes.dividerText}>
              {t("onboarding.plan.header.divider")}
            </Typography>
          </Divider>
          <Box className={classes.cardsContainer}>
            <Typography className={classes.title}>
              {t("onboarding.plan.title")}
            </Typography>
            {loadingSubCategories
              ? Array(3)
                  .fill()
                  .map((_, i) => <OnboardingPlanCardSkeleton key={i} />)
              : plansData &&
                plansData.map((plan) => (
                  <OnboardingPlanCard
                    key={plan.id}
                    plan={plan}
                    onDetails={handleDetails}
                    onStartTraining={handleStartTraining}
                  />
                ))}
            <Link
              href="/chooseplan"
              className={classes.seeMoreLink}
              underline="none"
            >
              <Typography className={classes.seeMore}>
                {t("onboarding.plan.seeAll")}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Dialog>
      <PlanDetailsDialog
        openDialog={detailsDialog}
        setOpenDialog={setDetailsDialog}
        plan={selectedPlan}
        onStartTraining={handleStartTraining}
      />
    </>
  );
};

export default OnboardingChoosePlan;

const useStyles = makeStyles({
  parentBox: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    padding: "16px",
    gap: "16px",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  title: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    color: "#12151B",
    textTransform: "initial",
    lineHeight: "22.5px",
  },
  subtitle: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6D6F71 ",
    lineHeight: "21px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  coachButton: {
    backgroundColor: "#F93C6E",
    height: "47px",
    color: "#FFFFFF",
    borderRadius: "4px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
    width: "100%",
  },
  dividerText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 700,
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    gap: "16px",
    overflowY: "hidden",
  },
  seeMoreLink: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    textDecoration: "none",
  },
  seeMore: {
    fontFamily: "Karla",
    color: "#4780AB",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 700,
  },
  skip: {
    fontFamily: "Karla",
    color: "#4780AB",
    lineHeight: "27px",
    fontSize: "18px",
    fontWeight: 700,
    textDecoration: "underline",
  },
});

const plans = {
  run5kto10k: [1700085224, 1696620771, 1603103238],
  run5kto42k: [1700085224, 1704489031, 1593171535],
  half_marathon: [1576151743, 1651845303, 1576151727],
  marathon: [1696620522, 1593171535, 1599135514],
  run50k: [1605202097, 1610729320, 1638540940],
  run50kplus: [1596186574, 1587558323, 1623857242],
  run80kto170kplus: [1587558323, 1637239547, 1594125487588],
  none: [1589548664, 1651845303, 1682003930],
};
