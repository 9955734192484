import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  List,
  Link,
  ListItem,
  LinearProgress,
} from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import { Timeline } from "@material-ui/lab";
import CheckoutPriceCardButton from "../../../components/common/v2/CheckoutPriceCardButton";
import ListItemWithIcon from "../../../components/common/v2/ListItemWithIcon";
import CheckoutTimelineItem from "../../../components/common/v2/CheckoutTimelineItem";
import { useTranslation } from "react-i18next";
import history from "../../../lib/history-helper";
import useCheckout from "../../../hooks/useCheckout";
import SnackBarMessageDisplay from "../../../components/common/snack-bar-message-display";
import { ArrowBack } from "@material-ui/icons";
import useCheckoutProducts from "../../../hooks/useCheckoutProducts";
import CheckoutPriceCardButtonSkeleton from "../../../components/common/v2/CheckoutPriceCardButtonSkeleton";
import { makeStyles } from "@material-ui/core/styles";
import useQuestionnaire from "../../../hooks/useQuestionnaire";
import {
  IconCalendarEvent,
  IconCheck,
  IconEdit,
  IconMedal,
  IconMessageCircle,
} from "@tabler/icons-react";

const useStyles = makeStyles({
  gridContainer: {
    padding: "64px",
  },
  backButton: {
    position: "absolute",
    top: "64px",
    left: "64px",
    color: "#4780AB",
  },
  backButtonText: {
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#4780AB",
  },
  title: {
    fontSize: "102px",
    fontWeight: 500,
    fontFamily: "Oswald",
    color: "#12151B",
    textTransform: "uppercase",
  },
  subtitle: {
    fontSize: "47px",
    fontWeight: 500,
    fontFamily: "Oswald",
    color: "#12151B",
  },
  highlight: {
    color: "#F93C6E",
    fontWeight: 700,
  },
  listContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  checkItem: {
    marginLeft: "11px",
    fontSize: "22px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  imageContainer: {
    position: "relative",
    borderRadius: "4px",
    overflow: "hidden",
    width: "100%",
    height: "130%",
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    objectFit: "cover",
  },
  imageText: {
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#FEFFFF",
    position: "absolute",
    top: 20,
    right: 0,
    writingMode: "vertical-lr",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
  },
  imageGradient: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage:
      "radial-gradient(338.27% 146.6% at 38.04% 109.6%, #FAFAFA 19.68%, rgba(250, 250, 250, 0) 40.61%)",
    borderRadius: "4px",
    zIndex: 0,
  },
  pricesContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  priceText: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#6D6F71",
  },
  priceHighlight: {
    fontWeight: 700,
  },
  noCoachingItem: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#4780AB",
    justifyContent: "center",
  },
  timelineTextContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  timelineText: {
    justifyContent: "center",
    fontSize: "40px",
    fontWeight: 500,
    fontFamily: "oswald",
    color: "#12151B",
  },
  timelineHightlight: {
    color: "#F93C6E",
  },
  timelineButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  timelineButton: {
    backgroundColor: "#0B2B5C",
    height: "62px",
    width: "80%",
    margin: "24px",
  },
  timelineButtonText: {
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#FEFFFF",
  },
  noCoachingText: {
    cursor: "pointer",
  },
  progress: {
    width: "100%",
    height: "4px",
  },
});
const CheckoutView = () => {
  const { t } = useTranslation("webcheckout");
  const {
    onboardingStorage,
    finishOnboarding,
    loadingSave,
  } = useQuestionnaire();
  const classes = useStyles();
  const {
    handleCheckout,
    errorMessage,
    successMessage,
    handleHideMessage,
  } = useCheckout();
  const [planType, setPlanType] = useState();

  useEffect(() => {
    const setOnboardingPlanType = async () => {
      const onboardingPlanType = await onboardingStorage.getItem(
        "onboardingPlanType",
      );
      if (onboardingPlanType) {
        setPlanType(onboardingPlanType);
      }
    };

    setOnboardingPlanType();
  }, []);

  const { checkoutItems, loading } = useCheckoutProducts();

  const skipCoaching = async () => {
    await finishOnboarding();
    history.push("/");
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <Grid className={classes.gridContainer} container spacing={2}>
        <Button
          variant="text"
          onClick={handleGoBack}
          startIcon={<ArrowBack />}
          className={classes.backButton}
        >
          <Typography className={classes.backButtonText}>
            {t("checkout.back")}
          </Typography>
        </Button>
        <Grid item xs={7}>
          <Typography className={classes.title}>
            {t("checkout.title")}
          </Typography>
          <Typography className={classes.subtitle}>
            {t("checkout.subtitle")}{" "}
            <span className={classes.highlight}>
              {t("checkout.subtitleHighlight")}
            </span>
          </Typography>
          <List className={classes.listContainer}>
            <ListItemWithIcon icon={<IconCheck size={24} />}>
              <Typography className={classes.checkItem}>
                {t("checkout.list.access")}{" "}
                <span className={classes.highlight}>
                  {t("checkout.list.accessHighlight")}
                </span>{" "}
                {t("checkout.list.accessDescription")}
              </Typography>
            </ListItemWithIcon>
            <ListItemWithIcon icon={<IconCheck size={24} />}>
              <Typography className={classes.checkItem}>
                {t("checkout.list.guidance")}{" "}
                <span className={classes.highlight}>
                  {t("checkout.list.guidanceHighlight")}
                </span>{" "}
                {t("checkout.list.guidanceDescription")}
              </Typography>
            </ListItemWithIcon>
            <ListItemWithIcon icon={<IconCheck size={24} />}>
              <Typography className={classes.checkItem}>
                {t("checkout.list.questions")}
              </Typography>
            </ListItemWithIcon>
            <ListItemWithIcon icon={<IconCheck size={24} />}>
              <Typography className={classes.checkItem}>
                <span className={classes.highlight}>
                  {t("checkout.list.personalHighlight")}
                </span>{" "}
                {t("checkout.list.personal")}
              </Typography>
            </ListItemWithIcon>
            <ListItemWithIcon icon={<IconCheck size={24} />}>
              <Typography className={classes.checkItem}>
                {t("checkout.list.sync")}
              </Typography>
            </ListItemWithIcon>
          </List>
        </Grid>
        <Grid item xs={5}>
          <div className={classes.imageContainer}>
            <img
              src={`${process.env.PUBLIC_URL}/onboarding-img/fpuppi.webp`}
              alt="Francesco"
              loading="lazy"
              className={classes.image}
            />
            <Typography className={classes.imageText}>
              {t("checkout.coach.description")}
            </Typography>
            <div className={classes.imageGradient} />
          </div>
        </Grid>
        <Box className={classes.pricesContainer}>
          {loading ? (
            <>
              <CheckoutPriceCardButtonSkeleton />
              <CheckoutPriceCardButtonSkeleton />
              <CheckoutPriceCardButtonSkeleton />
            </>
          ) : (
            <>
              <CheckoutPriceCardButton
                onClick={() => handleCheckout(checkoutItems[0].name, false)}
                title={t("checkout.item.oneMonth")}
                price={`${checkoutItems[0].currency} ${checkoutItems[0].price}`}
              />
              <CheckoutPriceCardButton
                onClick={() => handleCheckout(checkoutItems[1].name, false)}
                title={t("checkout.item.sixMonth")}
                price={`${checkoutItems[1].currency} ${checkoutItems[1].price /
                  5}`}
                glow={true}
                subtext={
                  <Typography className={classes.priceText}>
                    {t("checkout.item.onePayment")}{" "}
                    <span className={classes.priceHighlight}>
                      {checkoutItems[1].currency} {checkoutItems[1].price}
                    </span>
                  </Typography>
                }
              />
              <CheckoutPriceCardButton
                onClick={() => handleCheckout(checkoutItems[2].name, false)}
                title={t("checkout.item.twelveMonth")}
                price={`${checkoutItems[2].currency} ${checkoutItems[2].price /
                  12}`}
                subtext={
                  <Typography className={classes.priceText}>
                    {t("checkout.item.onePayment")}{" "}
                    <span className={classes.priceHighlight}>
                      {checkoutItems[2].currency} {checkoutItems[2].price}
                    </span>
                  </Typography>
                }
              />
            </>
          )}
        </Box>
        {loadingSave ? (
          <LinearProgress color="secondary" className={classes.progress} />
        ) : (
          <ListItem className={classes.noCoachingItem}>
            {planType && planType === "free" && (
              <Link
                onClick={skipCoaching}
                underline="always"
                className={classes.noCoachingText}
              >
                {t("checkout.noCoach")}
              </Link>
            )}
          </ListItem>
        )}
        <Grid item xs={6} className={classes.timelineTextContainer}>
          <Typography className={classes.timelineText}>
            {t("checkout.trial.howItWorks")}{" "}
            <span className={classes.timelineHightlight}>
              {t("checkout.trial.howItWorksHighlight")}
            </span>{" "}
            {t("checkout.trial.howItWorksDescription")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <ListItem>
            <Timeline>
              <CheckoutTimelineItem
                icon={<IconEdit IconMedal color="#F93C6E" stoke={1} />}
                timeRange={t("checkout.timeline.today.title")}
                items={[
                  t("checkout.timeline.today.description"),
                  t("checkout.timeline.today.description2"),
                ]}
              />
              <CheckoutTimelineItem
                icon={<IconMessageCircle IconMedal color="#F93C6E" stoke={1} />}
                timeRange={t("checkout.timeline.twoDays")}
                items={[t("checkout.timeline.twoDays.description")]}
              />
              <CheckoutTimelineItem
                icon={<IconCalendarEvent IconMedal color="#F93C6E" stoke={1} />}
                timeRange={t("checkout.timeline.fourDays")}
                items={[t("checkout.timeline.fourDays.description")]}
              />
              <CheckoutTimelineItem
                icon={<IconMedal IconMedal color="#F93C6E" stoke={1} />}
                timeRange={t("checkout.timeline.sevenDays")}
                items={[
                  t("checkout.timeline.sevenDays.description"),
                  t("checkout.timeline.sevenDays.description2"),
                ]}
              />
            </Timeline>
          </ListItem>
          <ListItem className={classes.timelineButtonContainer}>
            {loading ? (
              <Skeleton variant="rectangular" height={62} width="80%" />
            ) : (
              <Button
                onClick={() => handleCheckout(checkoutItems[0].name, false)}
                variant="contained"
                className={classes.timelineButton}
              >
                <Typography className={classes.timelineButtonText}>
                  {t("checkout.freeTrial")}
                </Typography>
              </Button>
            )}
          </ListItem>
        </Grid>
      </Grid>
      <SnackBarMessageDisplay
        onHideMessage={handleHideMessage}
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
    </>
  );
};

export default CheckoutView;
