import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Capacitor } from "@capacitor/core";
import SignInButton from "./sign-in-button";
import { OAuthUrl, OAuthHandler } from "../../lib/oauth2-helper";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";

const styles = (theme) => ({
  socialLoginButton: {
    backgroundColor: "#3B5998",
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    boxShadow: "none",
    border: 0,
    "&:hover": {
      backgroundColor: "#3B5998",
    },
  },
  socialButtonLabel: {
    textTransform: "none",
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  socialIcon: {
    marginRight: 14,
  },
});

export const handlePreSignUpError = (error) => {
  setTimeout(() => {
    if (!localStorage.getItem("error")) {
      try {
        localStorage.setItem("error", error);
      } catch (e) {
        console.log(e);
      }

      //window.location.reload();
      //setTimeout(() => window.location.replace("/auth"), 500);
    }
  }, 100);
};

OAuthHandler("Facebook", handlePreSignUpError);

const SignInWithFacebook = ({
  disabled,
  label,
  classes: { socialLoginButton, socialButtonLabel },
  withMargin = false,
}) => {
  return (
    <SignInButton
      className={{
        root: socialLoginButton,
        label: socialButtonLabel,
      }}
      withMargin={withMargin}
      disabled={disabled}
      label={label}
      onClick={handleFacebookSignUp}
      icon="facebook-f"
    />
  );
};

export const handleFacebookSignUp = async () => {
  const url = OAuthUrl("Facebook");
  localStorage.removeItem("error");

  if (Capacitor.isNativePlatform()) {
    if (Capacitor.getPlatform() === "ios") {
      const ref1 = InAppBrowser.create(url, "_blank", {
        clearcache: "yes",
        clearsessioncache: "yes",
        cleardata: "yes",
        // beforeload: "yes",
      });
      ref1.on("loadstart").subscribe((event) => {
        if (
          event &&
          event.url &&
          event.url.includes("code=") &&
          event.url.includes("state=") &&
          event.url.includes("run.vert")
        ) {
          const redirectUri = "capacitor://localhost";
          const params = new URLSearchParams(event.url.split("?")[1]);

          if (params.has("local")) {
            // flag added to avoid a loop
            return;
          }

          if (params.has("code") && params.has("state")) {
            const url = `${redirectUri}?code=${params.get(
              "code",
            )}&state=${params.get("state")}&local=${true}`;

            document.location.href = url;
            ref1.close();
          }
        } else if (
          event &&
          event.url &&
          event.url.includes("error_description=")
        ) {
          try {
            localStorage.setItem(
              "error",
              "You already have an account with a different provider",
            );
          } catch (e) {
            console.log(e);
          }

          const redirectUri = "capacitor://localhost";
          const params = new URLSearchParams(event.url.split("?")[1]);

          if (params.has("local")) {
            // flag added to avoid a loop
            return;
          }

          if (params.has("error_description")) {
            try {
              localStorage.setItem("error", params.get("error_description"));
            } catch (e) {
              console.log(e);
            }

            const url = `${redirectUri}?error_description=${params.get(
              "error_description",
            )}}`;

            document.location.href = url;
            ref1.close();
          }
        }
      });
    } else {
      InAppBrowser.create(url, "_system", {
        clearcache: "yes",
        clearsessioncache: "yes",
        cleardata: "yes",
      });
    }
  } else {
    await window.location.assign(url);
  }
};

SignInWithFacebook.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  withMargin: PropTypes.bool,
};

export default withStyles(styles)(SignInWithFacebook);
