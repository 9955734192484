import WorkoutActionVariables from "../constants/workout-action-variables";
import WorkoutTargets from "../constants/workout-targets";
import { convertUnits, getPace, round } from "./units-helper";

const translations = {
  "Warm up": { en: "Warm up", es: "Calentamiento" },
  Run: { en: "Run", es: "Correr" },
  Recover: { en: "Recover", es: "Recuperar" },
  Rest: { en: "Rest", es: "Descansar" },
  Other: { en: "Other", es: "Otro" },
  "Cool down": { en: "Cool down", es: "Enfriamiento" },
  "Run Uphill": { en: "Run Uphill", es: "Correr en subida" },
  "Run Downhill": { en: "Run Downhill", es: "Correr en bajada" },
  "Run Tempo": { en: "Run Tempo", es: "Correr en Tempo" },
  "Cross Training": { en: "Cross Training", es: "Entrenamiento cruzado" },
  Stride: { en: "Stride", es: "Stride" },
  "Conditioning training": {
    en: "Conditioning training",
    es: "Preparación física",
  },
  "Heart Rate": { en: "HR", es: "RC" },
  "Heart Rate Zone": { en: "Heart Rate Zone", es: "Zona de Ritmo Cardíaco" },
  Pace: { en: "Pace", es: "Ritmo" },
  RPE: { en: "RPE", es: "RPE" },
  "Uphill Speed": { en: "Uphill Speed of", es: "Velocidad en Subida de" },
  "Downhill Speed": { en: "Downhill Speed of", es: "Velocidad en Bajada de" },
  for: { en: "for", es: "por" },
  bpm: { en: "bpm", es: "lpm" },
  miles: { en: "miles", es: "millas" },
  km: { en: "km", es: "km" },
  m: { en: "m", es: "m" },
  feet: { en: "feet", es: "pies" },
  withTarget: { en: "with a target of", es: "con objetivo de" },
  Repeat: { en: "Repeat", es: "Repetir" },
  times: { en: "times", es: "veces" },
  elevGain: { en: "of elevation gain", es: "de desnivel positivo" },
  strides: { en: "Stride", es: "Stride" },
  Recovery: { en: "Recover", es: "Recuperar" },
};

export default function workoutToText(
  parametrizedWorkout,
  units = "kms",
  lang = "en",
) {
  if (parametrizedWorkout.length === 0) return "";

  let fullText = "";

  for (const step of parametrizedWorkout) {
    if (step.type === "step") {
      fullText += stepToText(step, units, lang);
    } else {
      fullText += `<p>${
        translations["Repeat"][lang]
          ? translations["Repeat"][lang]
          : translations["Repeat"]["en"]
      } ${step.times} ${
        translations["times"][lang]
          ? translations["times"][lang]
          : translations["times"]["en"]
      } [ `;

      if (step.steps && typeof step.steps[Symbol.iterator] === "function") {
        for (const rStep of step.steps) {
          fullText += stepToText(rStep, units, lang, false);
          fullText += " - ";
        }
      }

      fullText =
        step.steps && step.steps.length > 0
          ? fullText.substring(0, fullText.length - 3)
          : fullText;
      fullText += " ]</p>";
    }
  }

  return fullText;
}

function stepToText(step, units, lang, withP = true) {
  if (translations[step.action]) {
    return `${withP ? `<p>` : ""}${
      translations[step.action][lang]
        ? translations[step.action][lang]
        : translations[step.action]["en"]
    } ${valueToText(step.variable, step.value, units, lang)} ${targetToText(
      step.targetType,
      step.targetValue,
      units,
      lang,
    )}${withP ? `</p>` : ""}`;
  }
}

function valueToText(variable, value, units, lang) {
  let text = "";

  switch (variable) {
    case WorkoutActionVariables.Items.Distance:
      text =
        units === "miles"
          ? `${round(
              convertUnits(value / 1000, "kms", "miles", "distance"),
              1,
            )} ${
              translations["miles"][lang]
                ? translations["miles"][lang]
                : translations["miles"]["en"]
            }`
          : `${round(value / 1000, 1)} ${
              translations["km"][lang]
                ? translations["km"][lang]
                : translations["km"]["en"]
            }`;
      break;
    case WorkoutActionVariables.Items.Elevation:
      text = `${
        units === "miles"
          ? `${round(convertUnits(value, "kms", "miles", "elevation"), 0)} ${
              translations["feet"][lang]
                ? translations["feet"][lang]
                : translations["feet"]["en"]
            }`
          : `${value} ${
              translations["m"][lang]
                ? translations["m"][lang]
                : translations["m"]["en"]
            }`
      } ${
        translations["elevGain"][lang]
          ? translations["elevGain"][lang]
          : translations["elevGain"]["en"]
      }`;
      break;
    case WorkoutActionVariables.Items.Time:
      const hours = value ? value.substring(0, 2) : "00";
      const minutes = value ? value.substring(4, 6) : "00";
      const seconds = value ? value.substring(8, 10) : "00";
      text = `${hours && hours !== "00" ? `${+hours}h` : ""}
        ${minutes && minutes !== "00" ? `${+minutes}min` : ""}
        ${seconds && seconds !== "00" ? `${+seconds}s` : ""}
        `;
      break;
    default:
      return "";
  }

  return `${
    translations.for[lang] ? translations.for[lang] : translations.for["en"]
  } ${text}`;
}

function targetToText(targetType, targetValue, units, lang) {
  let text = "";

  switch (targetType) {
    case WorkoutTargets.Items["Heart Rate"]:
      text = `${targetValue}${
        translations["bpm"][lang]
          ? translations["bpm"][lang]
          : translations["bpm"]["en"]
      }`;
      break;
    case WorkoutTargets.Items["Heart Rate Zone"]:
      text = `${targetValue.toUpperCase()}`;
      break;
    case WorkoutTargets.Items.RPE:
      text = `${targetValue} ${
        translations["RPE"][lang]
          ? translations["RPE"][lang]
          : translations["RPE"]["en"]
      }`;
      break;
    case WorkoutTargets.Items.Pace:
      const minutes = +targetValue.substring(0, 2);
      const seconds = +targetValue.substring(3, 5);
      const pace = getPace(
        minutes * 60 + seconds,
        units === "miles" ? 1600 : 1000,
      );
      text = `${pace.minutes}:${(pace.seconds < 10 ? "0" : "") +
        pace.seconds} ${
        units === "miles"
          ? `min/${
              translations["miles"][lang]
                ? translations["miles"][lang]
                : translations["miles"]["en"]
            }`
          : `min/${
              translations["km"][lang]
                ? translations["km"][lang]
                : translations["km"]["en"]
            }`
      }`;
      break;
    case WorkoutTargets.Items["Uphill Speed"]:
      text = `${
        translations["Uphill Speed"][lang]
          ? translations["Uphill Speed"][lang]
          : translations["Uphill Speed"]["en"]
      } ${
        units === "miles"
          ? `${round(
              convertUnits(targetValue, "kms", "miles", "elevation"),
              0,
            )} ${
              translations["feet"][lang]
                ? translations["feet"][lang]
                : translations["feet"]["en"]
            }/h`
          : `${targetValue} ${translations["m"][lang]}/h`
      }`;
      break;
    case WorkoutTargets.Items["Downhill Speed"]:
      text = `${
        translations["Downhill Speed"][lang]
          ? translations["Downhill Speed"][lang]
          : translations["Downhill Speed"]["en"]
      } ${
        units === "miles"
          ? `${round(
              convertUnits(targetValue, "kms", "miles", "elevation"),
              0,
            )} ${
              translations["feet"][lang]
                ? translations["feet"][lang]
                : translations["feet"]["en"]
            }/h`
          : `${targetValue} ${
              translations["m"][lang]
                ? translations["m"][lang]
                : translations["m"]["en"]
            }/h`
      }`;
      break;
    default:
      return "";
  }

  return `${
    translations.withTarget[lang]
      ? translations.withTarget[lang]
      : translations.withTarget["en"]
  } ${text}`;
}
