import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { palette } from "../../../theme/palette";

const WorkoutDate = ({
  date = new Date(),
  locale = "en",
  mb = 20,
  ignoreToday = false,
}) => {
  const classes = useStyles();
  const now = new Date();
  const dateLocale = date.toLocaleDateString();
  const nowLocale = now.toLocaleDateString();
  const localeDate = {
    en: "en-US",
    es: "es-ES",
    fr: "fr-FR",
  };
  const formatDate = (date) => {
    if (!date) return "";
    const day = date.getDate();
    const month = date
      .toLocaleDateString(localeDate[locale], { month: "short" })
      .toUpperCase();
    return `${day}-${month}`;
  };

  return (
    <Grid
      container
      className={classes.dateColumn}
      style={{
        borderRadius:
          dateLocale === nowLocale && !ignoreToday ? "10px 0 0 10px" : "0",
        boxShadow:
          dateLocale === nowLocale && !ignoreToday ? palette.boxShadow : "none",
        marginBottom: mb,
      }}
    >
      <Typography
        className={classes.dateColumnText}
        style={{
          fontWeight:
            dateLocale === nowLocale && !ignoreToday ? "700" : "initial",
        }}
      >
        {date
          ? date.toLocaleDateString(localeDate[locale], { weekday: "short" })
          : ""}
      </Typography>
      <Typography
        style={{
          fontWeight:
            dateLocale === nowLocale && !ignoreToday ? "700" : "initial",
        }}
        className={classes.dateColumnText}
      >
        {formatDate(date)}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles({
  dateColumn: {
    marginTop: 10,
    height: 66,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  dateColumnText: {
    fontFamily: "Karla",
    textTransform: "uppercase",
    fontWeight: "300",
    fontSize: 14,
    color: palette.darkGray,
  },
});

export default WorkoutDate;
