const Weekdays = {
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
};

export const WeekdaysLangKeys = {
  Monday: "training.weekdays.monday",
  Tuesday: "training.weekdays.tuesday",
  Wednesday: "training.weekdays.wednesday",
  Thursday: "training.weekdays.thursday",
  Friday: "training.weekdays.friday",
  Saturday: "training.weekdays.saturday",
  Sunday: "training.weekdays.sunday",
};

export default {
  Items: Weekdays,
  itemsArray: Object.keys(Weekdays).map((key) => {
    return {
      text: Weekdays[key],
      value: key,
    };
  }),
};
