import React, { Fragment } from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";

import { Routes } from "../../lib/routing-middleware";
import history from "../../lib/history-helper";
import UserProfileMenu from "./user-profile-menu";
import WorkoutPlans from "../../constants/plans";
import ConnectAuth from "../../connectors/connect-auth";
import LanguageMenu from "./language-menu";
import { Capacitor } from "@capacitor/core";

const styles = (theme) => ({
  iconLink: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline",
    },
  },
  signedInLink: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      right: 10,
      bottom: 14,
      position: "absolute",
    },
  },
  leftButton: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline",
      left: 18,
      position: "absolute",
      top: 21,
    },
  },
  choosePlanButton: {
    fontSize: 8,
  },
  white: {
    color: theme.palette.common.white,
  },
  topRightCTAButton: {
    textTransform: "initial",
    borderRadius: 26,
    boxShadow: "none",
    padding: "5px 15px",
  },
});

const AppBarMobileSignIn = (props) => {
  const {
    classes,
    auth: { isAuthenticated, page },
    userPlans,
    isNotOnChoosingPlanPage,
    subscriptions,
    location: { pathname },
    setAuthPage,
    isOnTrailhead,
    isOnDashboard,
    isOnSuggesterResults,
    isOnChallenges,
    isOnProfile,
    isOnExplore,
    isOnTracking,
  } = props;
  const { currentPlan, comments } = userPlans;
  const { currentTraining } = currentPlan || "";
  let { selectedPlan } = currentTraining || {};

  if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
    selectedPlan = currentPlan.selectedPlan;
  }

  const { latestSubscriptionFinalizedTime } = currentPlan || {};
  const { latestExpired, loadingLatest, latest } = subscriptions;
  let showCompleteSubscriptionButton = false;

  if (selectedPlan !== WorkoutPlans.FREE)
    if (!loadingLatest) {
      if (
        !latestSubscriptionFinalizedTime &&
        (!latest ||
          (latest.status !== "active" &&
            latest.status !== "in_trial" &&
            latest.status !== "non_renewing"))
      )
        showCompleteSubscriptionButton = isNotOnChoosingPlanPage && true;
      else if (latestExpired)
        showCompleteSubscriptionButton = isNotOnChoosingPlanPage && true;
    }
  const { t } = useTranslation("layout");

  return isAuthenticated ? (
    <Fragment>
      <UserProfileMenu
        showChangePlanButton={
          !showCompleteSubscriptionButton &&
          isNotOnChoosingPlanPage &&
          currentPlan &&
          currentPlan.selectedPlan !== WorkoutPlans.PREMIUM
        }
        className={classes.iconLink}
        currentPlan={currentPlan}
        isOnTrailhead={isOnTrailhead}
        isOnDashboard={isOnDashboard}
        isOnSuggesterResults={isOnSuggesterResults}
        isOnChallenges={isOnChallenges}
        comments={comments}
        isOnProfile={isOnProfile}
        isOnExplore={isOnExplore}
        isOnTracking={isOnTracking}
      />
    </Fragment>
  ) : (page === "sign-in" || pathname === Routes.SignIn) &&
    !Capacitor.isNativePlatform() ? (
    <div className={classes.signedInLink}>
      <LanguageMenu />
      <Button
        className={classes.topRightCTAButton}
        variant="contained"
        color="primary"
        onClick={() => {
          setAuthPage("sign-up");
          history.push(Routes.SwitchSignUp);
        }}
      >
        {t("Create an account")}
      </Button>
    </div>
  ) : !Capacitor.isNativePlatform() ? (
    <div className={classes.signedInLink}>
      <LanguageMenu />
      <Button
        className={classes.topRightCTAButton}
        variant="contained"
        color="primary"
        onClick={() => {
          setAuthPage("sign-in");
          history.push(Routes.SignIn);
        }}
      >
        {t("Log in")}
      </Button>
    </div>
  ) : (
    ""
  );
};

AppBarMobileSignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  userPlans: PropTypes.object.isRequired,
  subscriptions: PropTypes.object.isRequired,
  width: PropTypes.string,
  isNotOnChoosingPlanPage: PropTypes.bool,
  setAuthPage: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
    subscriptions: store.subscriptions,
  };
})(
  ConnectAuth(
    withRouter(
      withWidth()(withStyles(styles, { withTheme: true })(AppBarMobileSignIn)),
    ),
  ),
);
