import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  Button,
  Link,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { I18n } from "aws-amplify";
import { SignIn } from "aws-amplify-react";
import classNames from "classnames";
import SignInWithApple from "./auth-components/sign-in-with-apple";
import { Capacitor } from "@capacitor/core";
import ConnectAuth from "../connectors/connect-auth";
import { logEvent } from "../lib/events-helper";
import { connect } from "react-redux";
import { getSignUpMethod, clearSignIn } from "../actions/athletes-action";
import AuthOption from "./sign-in/auth-option";
import { withTranslation } from "react-i18next";
import { setLoginCount } from "../actions/user-plans-action";

const styles = (theme) => ({
  container: {
    marginBottom: 30,
    padding: "0 20px",
    boxSizing: "border-box",
    minWidth: "600px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 10px",
      minWidth: "auto",
    },
  },
  topTitle: {
    color: "#282828",
    fontFamily: "Montserrat",
    fontSize: 30,
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
  topSubTitle: {
    color: "#282828",
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: 400,
    marginBottom: 20,
    margin: 20,
  },
  logoImg: {
    width: 150,
    marginBottom: 25,
    marginTop: 10,
  },
  paper: {
    ...(theme.formSection || {}),
    padding: "10px 25px",
    minWidth: 240,
    margin: "auto",
    borderRadius: 5,
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      padding: "10px 15px",
    },
  },
  title: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  button: {
    marginTop: 20,
    textTransform: "none",
  },
  checkboxLabel: {
    color: theme.palette.text.primaryText,
  },
  TextField: {
    margin: "10px 0",
  },
  footerLink: {
    color: "var(--grey)",
    marginTop: 10,
  },
  forgetPasswordLinkBlock: {
    marginTop: 20,
    marginBottom: 0,
    color: "gray",
  },
  signUpLinkBlock: {
    marginTop: 10,
  },
  hidden: {
    opacity: 0,
  },
  white: {
    color: theme.palette.common.white,
  },
  section: {
    marginTop: 8,
    marginBottom: 20,
  },
  subTitle: {
    fontSize: 19,
    color: "gray",
    width: "90%",
    textAlign: "center",
    marginTop: 4,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
    },
  },
  visibilityIcon: {
    [theme.breakpoints.up("sm")]: {
      color: "white",
    },
  },
  linearProgress: {
    marginTop: 15,
    marginBottom: 15,
  },
  submitButton: {
    marginTop: 12,
    boxShadow: "none",
    borderRadius: 4,
    width: "100%",
    padding: "12px 0px",
  },
  passwordReset: {
    color: "#616161",
  },
  error: {
    fontSize: 12,
    color: "red",
    fontWeight: 400,
    lineHeight: "18px",
    margin: "10px 0",
  },

  createAccountText: {
    marginTop: 20,
    marginBottom: 0,
    color: "gray",
    textAlign: "center",
    fontSize: 15,
  },
  createAccountLink: {
    marginLeft: 10,
    cursor: "pointer",
  },

  orCont: {
    display: "flex",
    alignItems: "center",
  },

  hrL: {
    flexGrow: 1,
    marginRight: 20,
    border: 0,
    borderTop: "1px solid #949494",
  },

  hrR: {
    flexGrow: 1,
    marginLeft: 20,
    border: 0,
    borderTop: "1px solid #949494",
  },
  imageParent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titlesContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    wordBreak: "break-all",
    width: "100%",
  },
  fullWidth: {
    width: "90%",
    marginBottom: 4,
  },
  orangeText: {
    color: "#E16327",
    fontWeight: "bold",
    cursor: "pointer",
  },
  subtitleParent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 26,
  },
  errorTitle: {
    color: "#282828",
    fontFamily: "Montserrat",
    fontSize: 22,
    textAlign: "center",
    width: "100%",
    marginTop: 32,
    marginBottom: 20,
  },
  appleText: {
    color: "#282828",
    fontFamily: "Montserrat",
    fontSize: 15,
    fontWeight: 400,
    margin: "35px 0px 15px 0px",
    textAlign: "center",
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "48px 0px 15px 0px",
      fontSize: 13,
    },
  },
  appleSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
});

const handleText = (data, user, t) => {
  if (data === "error") return t("not found email", { email: user });
  if (!data) return t("welcome back");
  if (data) return t("It's great to see you", { email: user });
};

class CustomSignIn extends SignIn {
  state = {
    showPassword: false,
    user: "",
  };

  componentDidMount() {
    if (!this.inputs["username"]) this.setRememberedUser();
  }

  componentDidUpdate() {
    if (!this.inputs["username"]) this.setRememberedUser();
  }

  setRememberedUser = () => {
    try {
      this.inputs = this.inputs || {};
      const email = localStorage.getItem("email");
      if (email) this.inputs["username"] = email;
    } catch (error) {
      return error;
    }
  };

  localStorageUpdated() {
    const email = localStorage.getItem("email");
    this.inputs["username"] = email;
    this.setState({ ...this.state, user: email });
  }

  customHandleInput = (evt) => {
    try {
      this.inputs = this.inputs || {};
      const { name, value } = evt.target;
      this.inputs[name] = name === "username" ? value.toLowerCase() : value;
    } catch {
      this.handleInputChange(evt);
    }
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.customSignIn(event);
    }
  };

  handleForgotPassword = () => {
    super.changeState("forgotPassword");
  };

  customSignIn = (event) => {
    event.preventDefault();
    const { signInType } = this.props.athletes;
    const { data } = signInType || {};
    const { loading } = this.state;

    const localLoginCount = localStorage.getItem("loginCount") || "0";
    const { username, password } = this.inputs;
    this.setState({ ...this.state, user: username });
    if (!username) return this.props.setGeneralError("Username Required");
    if (!password && data == "email")
      return this.props.setGeneralError("Password Required");
    if (username && password) {
      if (!loading) {
        if (Number(localLoginCount) <= 3) {
          this.props.dispatch(setLoginCount(Number(localLoginCount) + 1));
          localStorage.setItem("loginCount", `${Number(localLoginCount) + 1}`);
        }
        super.signIn();
      }

      localStorage.removeItem("error");
      return;
    }

    if (!loading) {
      this.props.dispatch(getSignUpMethod(username));
    }
    localStorage.removeItem("error");
  };

  handleSignUp = () => {
    const { onStateChange, setAuthPage } = this.props;
    if (onStateChange) {
      onStateChange("signUp");
      setAuthPage("sign-up");
    }
  };

  componentWillUnmount() {
    this.props.dispatch(clearSignIn());
  }

  handleNotMe = () => this.props.dispatch(clearSignIn());

  showComponent(theme) {
    const { classes, t } = this.props;
    const { user, loading } = this.state;
    const { signInType, signInLoading } = this.props.athletes;
    const { data } = signInType || {};

    return (
      <form className={classes.container} onSubmit={this.customSignIn}>
        <div className={classes.imageParent}>
          <img
            src={`${process.env.PUBLIC_URL}/vertrun-logo-wide.png`}
            alt=""
            className={classes.logoImg}
          />
        </div>

        <div className={classes.titlesContainer}>
          <Typography
            variant="h1"
            className={classNames(
              classes.topTitle,
              data ? classes.fullWidth : null,
            )}
            dangerouslySetInnerHTML={{
              __html: handleText(data, user, t),
            }}
          ></Typography>
        </div>

        <div className={classes.subtitleParent}>
          {data ? (
            <Typography
              variant="body1"
              className={classes.orangeText}
              onClick={this.handleNotMe}
            >
              {t("This is not me")}
            </Typography>
          ) : (
            <Typography variant="body1" className={classes.subTitle}>
              {t("login into")}
            </Typography>
          )}
        </div>

        {data === "error" && (
          <Typography variant="body1" className={classes.errorTitle}>
            {t("Would you like to")}
          </Typography>
        )}

        <Paper className={classes.paper} elevation={0}>
          {data && (
            <AuthOption
              I18n={I18n}
              loading={loading}
              authType={data.toLowerCase()}
              customHandleInput={this.customHandleInput}
              handleKeyPress={this.handleKeyPress}
              handleForgotPassword={this.handleForgotPassword}
              handleSignUp={this.handleSignUp}
              user={user}
            />
          )}

          {!data && (
            <>
              <TextField
                id="username"
                key="username"
                name="username"
                autoComplete="off"
                onChange={this.customHandleInput}
                label={t("Enter your email address")}
                type="email"
                fullWidth
                required
                className={classes.TextField}
                onKeyPress={this.handleKeyPress}
                variant="outlined"
                defaultValue={localStorage.getItem("email") || ""}
              />

              <div
                style={{
                  display: "flex",
                }}
              >
                <Button
                  id="login-id"
                  variant="contained"
                  color="primary"
                  autoFocus={!Capacitor.isNativePlatform()}
                  size="large"
                  type="submit"
                  disabled={loading}
                  className={classes.submitButton}
                >
                  {!signInLoading ? (
                    I18n.get("Log in")
                  ) : (
                    <CircularProgress size={26} color="inherit" />
                  )}
                </Button>
              </div>
            </>
          )}

          {data !== "error" && (
            <Typography
              variant="body1"
              className={classNames(classes.createAccountText)}
            >
              {t("New to Vert?")}
              <Link
                className={classes.createAccountLink}
                onClick={() => {
                  this.handleSignUp();
                }}
              >
                {t("Create an account")}
              </Link>
            </Typography>
          )}

          {!data && (
            <div className={classes.appleSection}>
              <Typography
                variant="body1"
                className={classes.appleText}
                dangerouslySetInnerHTML={{
                  __html: t(
                    "If you used Apple Hide My Email, you can log in with your apple id here",
                  ),
                }}
              ></Typography>

              <SignInWithApple
                disabled={loading}
                label={I18n.get("Continue with Apple")}
              />
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

CustomSignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  setAuthPage: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    athletes: store.athletes,
    auth: store.auth,
    userPlans: store.userPlans,
  };
})(
  ConnectAuth(
    withTranslation("auth")(
      withStyles(styles, { withTheme: true })(CustomSignIn),
    ),
  ),
);
