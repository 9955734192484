import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import AthleteUserPlanInfo from "./athlete-user-plan-info";
import { dateFormatShort, dateFormat } from "../../../../lib/date-helper";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
});

class AthleteUserPlanRight extends Component {
  render() {
    const {
      classes,
      selectedAthlete,
      countingAthleteWorkouts,
      loadingActivities,
    } = this.props;
    const {
      currentTraining,
      statistic,
      stravaActivities,
      desiredStartDate,
      nextRace,
    } = selectedAthlete;

    const { overallNotDoneWorkoutsCount = 0, overallWorkoutsCount = 0 } =
      statistic || {};
    const {
      lastWeekElapsedTime = 0,
      lastWeekDistance = 0,
      lastWeekElevatin = 0,
    } = stravaActivities || {};
    let goal = 0;
    let runningStreak = 0;
    let currentWeek = 1;

    if (currentTraining) {
      const { days } = currentTraining;
      if (days) {
        const filledDays = days.filter((day) => day).map((day) => day.length);
        if (filledDays.length > 0)
          goal = filledDays.reduce((sum, a) => sum + a);
        const doneDays = days
          .filter((day) => day)
          .map((day) => day.filter((workout) => workout.isDone).length);
        if (doneDays.length > 0)
          runningStreak = doneDays.reduce((sum, a) => sum + a);
      }
      const { startingDate, durationInWeeks } = currentTraining;
      const currentWeekStartDayFormatted = moment()
        .startOf("isoWeek")
        .format(dateFormatShort);
      if (
        moment(startingDate, dateFormat)
          .startOf("isoWeek")
          .format(dateFormatShort) !== currentWeekStartDayFormatted
      )
        for (let counter = 1; counter <= durationInWeeks; counter++) {
          if (
            moment(startingDate, dateFormat)
              .add(counter, "week")
              .startOf("isoWeek")
              .format(dateFormatShort) === currentWeekStartDayFormatted
          )
            currentWeek = counter + 1;
        }
    }

    return (
      <div className={classes.container}>
        <AthleteUserPlanInfo
          title="Desired Start Date"
          value={desiredStartDate}
        />
        <AthleteUserPlanInfo
          title="Current week of training"
          value={currentWeek}
        />
        <AthleteUserPlanInfo
          title="Running streak"
          value={`${runningStreak} of ${goal}`}
        />
        <AthleteUserPlanInfo
          title="Overall workouts not mark as done"
          loading={countingAthleteWorkouts}
          value={`${overallNotDoneWorkoutsCount} of ${overallWorkoutsCount}`}
        />
        <AthleteUserPlanInfo
          title="Last week hours of training"
          loading={loadingActivities}
          value={lastWeekElapsedTime.toLocaleString()}
        />
        <AthleteUserPlanInfo
          title="Last week kilometers"
          loading={loadingActivities}
          value={`${lastWeekDistance.toLocaleString()} km`}
        />
        <AthleteUserPlanInfo
          title="Last week elevation gain"
          loading={loadingActivities}
          value={`${lastWeekElevatin.toLocaleString()} m`}
        />
        {nextRace && nextRace.raceName ? (
          <AthleteUserPlanInfo
            title="Next Race Name"
            value={nextRace.raceName}
          />
        ) : (
          ""
        )}
        {nextRace && nextRace.raceDate ? (
          <AthleteUserPlanInfo
            title="Next Race Date"
            value={nextRace.raceDate}
          />
        ) : (
          ""
        )}
        {nextRace && nextRace.raceDistance ? (
          <AthleteUserPlanInfo
            title="Next Race Distance"
            value={`${nextRace.raceDistance} ${
              !nextRace.units || nextRace.units === "kms" ? "kms" : "miles"
            }`}
          />
        ) : (
          ""
        )}
        {nextRace && nextRace.raceElevation ? (
          <AthleteUserPlanInfo
            title="Next Race Elevation"
            value={`${nextRace.raceElevation} ${
              !nextRace.units || nextRace.units === "kms" ? "m" : "feet"
            }`}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

AthleteUserPlanRight.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedAthlete: PropTypes.object.isRequired,
  countingAthleteWorkouts: PropTypes.bool,
  loadingActivities: PropTypes.bool,
};

export default withStyles(styles)(AthleteUserPlanRight);
