import React, { Component, memo } from "react";
import {
  CircularProgress,
  DialogContent,
  ThemeProvider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Grid,
  Dialog,
  Typography,
  DialogActions,
  Button,
  Badge,
  Box,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import moment from "moment";
import withWidth from "@material-ui/core/withWidth";
import {
  clearSuccessMessage,
  clearErrorMessage,
  setErrorMessage,
  updatePlan,
  updatePlanSwap,
  updatePlanRedoWeek,
  updatePlanAddEasyWeek,
  updatePlanImage,
  userPlanMailchimpSubscribe,
  currentTrainingCurrentStartDateChange,
  autoAssignPlan,
  updatePlanAddWorkout,
  updateLoad,
  clearTempTraining,
  getUserActivities,
  clearCurrentUserActivity,
  currentTrainingCurrentStartDateSet,
  getPaginatedPlan,
} from "../actions/user-plans-action";

import { clearErrorMessage as clearSubErrorMessage } from "../actions/subscriptions-action";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import DashboardTrainingColumn from "./dashboard/dashboard-training-column";
import DashboardUser from "./dashboard/dashboard-user";
import DashboardWeeklyReportDiagram from "./dashboard/dashboard-weekly-report-diagram";
import AppBar from "./layout/app-bar";
import { dateFormat } from "../lib/date-helper";
import DashboardNextRaceDetails from "./dashboard/dashboard-training/dashboard-next-race/dashboard-next-race-details";
import DashboardTrainingWeekdayAdd from "./dashboard/dashboard-training/dashboard-training-weekday/dashboard-training-weekday-add";
import { createAnalyticData } from "../lib/analytic-helper";
import { pushAnalyticData } from "../actions/analytic-action";
import { getSubcategories } from "../actions/subcategory-action";
import { Route } from "react-router-dom";
import DashboardTrainingWeekdayDetails from "./dashboard/dashboard-training/dashboard-training-weekday/dashboard-training-weekday-details";
import DashboardChat from "./dashboard/dashboard-chat";
import {
  getEnergyLevelRate,
  getDoneRate,
  getTrainingLoadRate,
  getAccumulatedDistance,
  getAccumulatedElevation,
  getLevel,
} from "../lib/rates-helper";
import { getPlan } from "../lib/current-training-helper";
import DashboardDesktopCharts from "./dashboard/dashboard-desktop-charts";
import { logEvent, setEventsMetadata } from "../lib/events-helper";
import TrailLevelPopup from "./common/trail-level-popup";
import { addGroupActivities } from "../actions/trail-head-action";
import { storageFactory } from "../lib/storage-factory";
import { Capacitor } from "@capacitor/core";
import LocalNotificationsService from "../services/local-notifications-service";
import DashboardActivityList from "./dashboard/dashboard-activity-list";
import WelcomeModal from "./generals/welcome-modal";
import UpgradeYearModal from "./generals/upgrade-year-modal";
import { getPlanLevel } from "../lib/plan-helper";
import PaywallModal from "../components/generals/upgrade-pro-modal";
import history from "../lib/history-helper";
import EndYearModal from "./generals/end-year-modal";
import { getActivitiesHealthKit } from "../actions/healthkit-action";
import RetrySubModal from "./generals/subscription-modal";
import { theme } from "../theme";
import GeneralCard from "./generals/generalCard";
import WorkoutCard from "./generals/workoutCard";
import PopularPlans from "./generals/popular-plans";
import PopularVideos from "./generals/popular-videos";
import PopularPodcast from "./generals/podcast";
import TrailHeadPreview from "./generals/trail-head-preview";
import { checkSubscription } from "../lib/subscription-plan-helper";
import dayjs from "dayjs";
import { TrainingProvider } from "../context/TrainingContext";
import NextRaceSlider from "./common/v2/NextRaceSlider";

const styles = (theme) => ({
  progressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FCFCFC",
    [theme.breakpoints.down("md")]: {
      minHeight: "calc(100vh - 50px)",
      backgroundColor: "#EFF3F5",
    },
  },
  loading: {
    margin: "auto",
  },
  weeklyReport: {
    order: 1,
    [theme.breakpoints.down("md")]: {
      order: 2,
    },
  },
  notDisplayedInMobile: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  training: {
    order: 2,
    [theme.breakpoints.down("md")]: {
      order: 1,
    },
  },
  gridItemWithMargin: {
    marginTop: theme.spacing.unit * 6,
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      width: "100%",
      padding: "0 !important",
    },
  },
  completeSubscription: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      order: 3,
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  completeSubscriptionButton: {
    width: "95%",
  },
  trainingPane: {
    height: "100vh",
    overflow: "scroll",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },

  topBarMobile: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    justifyContent: "center",
  },
  filter: {
    color: "#000000",
    cursor: "pointer",
    padding: "3px 8px",
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10,
    background: "#E7EAEE",
    borderRadius: 3,
    width: "33%",
    textAlign: "center",
  },
  filterSelected: {
    color: "#FFFFFF",
    cursor: "pointer",
    padding: "3px 8px",
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10,
    background: "#2E3A59",
    borderRadius: 3,
    width: "33%",
    textAlign: "center",
  },
  runningStreakLabelContainer: {
    display: "flex",
    marginTop: 5,
    marginBottom: 5,
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      marginLeft: 10,
    },
  },
  runningStreakIcon: {
    height: 11.5,
  },
  runningStreakLabel: {
    fontSize: 14,
    fontWeight: 400,
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      marginRight: 3,
      textAlign: "left",
    },
  },
  runningStreakValue: {
    fontSize: 20,
    fontWeight: 900,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      fontWeight: "normal",
      marginLeft: 10,
      marginBottom: 20,
    },
  },
  calendarWorkout: {
    padding: "0 20px 20px 20px",
    minWidth: 300,
    [theme.breakpoints.down("md")]: {
      minWidth: "initial",
    },
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  topBarTitle: {
    fontWeight: 500,
    fontSize: 12,
    color: "#202020",
    marginRight: 14,
  },
  filterDesktop: {
    fontSize: 12,
    background: "#E7F3FC",
    borderRadius: 24,
    padding: "6px 8px",
    marginLeft: 10,
    marginRight: 10,
    color: "#0F4975",
    cursor: "pointer",
  },
  filterSelectedDesktop: {
    fontSize: 12,
    background: "#1A629B",
    borderRadius: 24,
    padding: "6px 8px",
    marginLeft: 10,
    marginRight: 10,
    color: "#FFFFFF",
    cursor: "pointer",
  },
  calendarViewContainer: {
    display: "flex",
    alignItems: "center",
    background: "#FCFCFC",
    boxShadow: "0 0 12px rgba(33, 81, 209, 0.1)",
    borderRadius: 18,
    margin: "0 15px 20px 0",
    padding: "30px 15px",
    position: "relative",
  },
  commentIcon: {
    right: 10,
    bottom: 10,
    padding: 10,
    position: "fixed",
    cursor: "pointer",
  },
  filterBtn: {
    fontSize: 14,
    fontWeight: 400,
    background: "rgba(63, 81, 181, 0.12);",
    borderRadius: 4,
    padding: "6px 8px",
    marginLeft: 5,
    marginRight: 5,
    color: "#212121",
    cursor: "pointer",
    minWidth: "20%",
    maxWidth: 92,
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      minWidth: "15%",
    },
  },

  filterBtnSelected: {
    fontSize: 14,
    fontWeight: 400,
    background: "#1E629B",
    borderRadius: 4,
    padding: "6px 8px",
    marginLeft: 5,
    marginRight: 5,
    color: "#FFF",
    cursor: "pointer",
    minWidth: "20%",
    maxWidth: 92,
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      minWidth: "15%",
    },
  },
  filtersParent: {
    marginTop: "20px",
    display: "flex",
    padding: "0 54px",
  },
});

class Dashboard extends Component {
  state = {
    isOpenAddWorkout: false,
    isOpenAddWorkoutConfirm: false,
    workoutToAdd: null,
    tab: 0,
    calendarSelectedWorkout: null,
    openLevel: false,
    currentFilter: 0,
    currentActivity: null,
    showWelcomeModal: false,
    pathnameRedirect: "/chooseplan",
    upgradeYearDialogOpen: false,
    isOpenWorkoutNotificationDialog: false,
    showpaywall: false,
    showYearModal: false,
    showsubModal: false,
    showSub: false,
    openActivityModal: false,
  };

  constructor(props) {
    super(props);
    this.analyticData = [];
    this.isAnalyticDataPush = false;
  }

  updateState = (event, myKey, value) => {
    this.setState({
      ...this.state,
      myKey: value,
    });
  };

  async componentDidMount() {
    const { userPlans, auth, width } = this.props;
    const isInMobileView = width === "md" || width === "sm" || width === "xs";
    const { isAuthenticated, currentUserSigningOut, currentUser } = auth;
    const {
      attributes: { sub },
    } = currentUser;
    const { currentPlan } = userPlans || {};
    const { currentTraining, currentTrainingEndDate } = currentPlan || {};

    const date = dayjs().format("MM-DD-YYYY");
    if (isInMobileView) this.props.dispatch(getPaginatedPlan(date, date, sub));

    const { subcategories } = this.props;
    const { data } = subcategories;
    if (!data || !data.length) this.props.dispatch(getSubcategories());

    const day = moment("23/12/2022", "DD/MM/YYYY").isoWeekday();
    if (moment().isSameOrBefore(moment("15/02/2023", "DD/MM/YYYY"))) {
      if (day == 1 || day == 3 || day == 5) {
        if (day.toString() !== localStorage.getItem("show_year_modal")) {
          this.setState({ ...this.state, showYearModal: true });
          localStorage.setItem("show_year_modal", day);
        }
      }
    }

    if (isAuthenticated && !currentUserSigningOut) {
      await this.props.dispatch(clearCurrentUserActivity());

      if (!isInMobileView && userPlans.loadingPlan) this.getActivities();

      const { days } = currentTraining || {};
      const today = moment();
      let trainingEndDate = moment.unix(currentTrainingEndDate);
      if (days && days[days.length - 1]) {
        trainingEndDate = moment(
          days[days.length - 1][0].plannedDate,
          dateFormat,
        );
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      auth,
      userPlans,
      subscriptions,
      dispatch,
      strava,
      garmin,
      suunto,
      vertActivities,
      width,
      coros,
    } = this.props;

    const { currentUser } = auth || {};
    const { attributes } = currentUser || {};
    const { email } = attributes || {};

    const isInMobileView = width === "md" || width === "sm" || width === "xs";

    const { currentPlan } = userPlans || {};
    const { currentTraining } = currentPlan || {};
    const { selectedTrainingDayWorkoutTemp } = currentTraining || {};
    const { errorMessage: subErrorMessage } = subscriptions;

    if (
      subErrorMessage !== prevProps.subscriptions.errorMessage &&
      subErrorMessage
    ) {
      this.setState({ ...this.state, showSub: true });
    }

    if (!prevProps.userPlans.currentPlan && userPlans.currentPlan) {
      if (!isInMobileView) this.getActivities();
      if (Object.values(selectedTrainingDayWorkoutTemp || {}).length)
        this.props.dispatch(clearTempTraining());

      const { currentPlan } = userPlans;
      const { gpsDate, cognito_user_sub } = currentPlan || {};
      if (!gpsDate) {
        dispatch(
          updatePlan(
            { cognito_user_sub, gpsDate: moment().unix() },
            false,
            false,
          ),
        );
      }

      if (
        !userPlans.currentPlan.showWorkoutNotifications &&
        userPlans.currentPlan.showWorkoutNotifications === undefined &&
        userPlans.currentPlan.currentTraining &&
        Capacitor.isNativePlatform()
      ) {
        setTimeout(
          this.setState({ isOpenWorkoutNotificationDialog: true }),
          100,
        );
      }
    }

    if (
      prevProps.userPlans.loading === true &&
      userPlans.loadingPlan === false
    ) {
      this.analyticData = [...this.analyticData, "userPlans"];

      const { currentPlan } = userPlans;
      const subcategory =
        currentPlan &&
        currentPlan.currentTraining &&
        currentPlan.currentTraining.subcategory
          ? currentPlan.currentTraining.subcategory
          : currentPlan &&
            currentPlan.currentTraining &&
            currentPlan.currentTraining.subcategory
          ? +currentPlan.subcategory
          : 0;
      setEventsMetadata({
        sub:
          subscriptions && subscriptions.latest
            ? subscriptions.latest.plan_id
            : null,
        cat: subcategory,
        racecount:
          currentPlan && currentPlan.raceCount ? currentPlan.raceCount : null,
        train:
          currentPlan && currentPlan.trainPerWeek
            ? currentPlan.trainPerWeek
            : null,
      });
    }

    if (
      prevProps.subscriptions.loadingLatest === true &&
      subscriptions.loadingLatest === false
    ) {
      this.analyticData = [...this.analyticData, "subscriptions"];

      const { currentPlan } = userPlans;
      const subcategory =
        currentPlan &&
        currentPlan.currentTraining &&
        currentPlan.currentTraining.subcategory
          ? currentPlan.currentTraining.subcategory
          : currentPlan &&
            currentPlan.currentTraining &&
            currentPlan.currentTraining.subcategory
          ? +currentPlan.subcategory
          : 0;
      setEventsMetadata({
        sub:
          subscriptions && subscriptions.latest
            ? subscriptions.latest.plan_id
            : null,
        cat: subcategory,
      });
    }

    if (
      userPlans.loading !== prevProps.userPlans.loading ||
      vertActivities.loading !== prevProps.vertActivities.loading ||
      strava.loading !== prevProps.strava.loading ||
      garmin.loading !== prevProps.garmin.loading ||
      suunto.loading !== prevProps.suunto.loading ||
      coros.loading !== prevProps.coros.loading
    ) {
      const { activities: activitiesStrava } = strava;
      const { activities: activitiesGarmin } = garmin;
      const { activities: activitiesSuunto } = suunto;
      const { activities: activitiesVert } = vertActivities;
      const { activities: activitiesCoros } = coros;

      const activities =
        activitiesGarmin && activitiesGarmin.length
          ? activitiesGarmin
          : activitiesSuunto && activitiesSuunto.length
          ? activitiesSuunto
          : activitiesCoros && activitiesCoros.length
          ? activitiesCoros
          : activitiesStrava && activitiesStrava.length
          ? activitiesStrava
          : activitiesVert && activitiesVert.length
          ? activitiesVert
          : [];

      if (
        (activitiesStrava &&
          activitiesStrava.length &&
          prevProps.strava.activities.length === 0) ||
        (activitiesGarmin &&
          activitiesGarmin.length &&
          (!prevProps.garmin.activities ||
            prevProps.garmin.activities.length === 0)) ||
        (activitiesSuunto &&
          activitiesSuunto.length &&
          (!prevProps.suunto.activities ||
            prevProps.suunto.activities.length === 0)) ||
        (activitiesCoros &&
          activitiesCoros.length &&
          (!prevProps.coros.activities ||
            prevProps.coros.activities.length === 0)) ||
        (activitiesVert &&
          activitiesVert.length &&
          (!prevProps.vertActivities.activities ||
            prevProps.vertActivities.activities.length === 0)) ||
        (activitiesVert &&
          activitiesVert.length &&
          !prevProps.vertActivities.activities !== activitiesVert.length)
      ) {
        const { currentPlan } = userPlans;
        const {
          currentTraining,
          lastAccumulatedCheck = 0,
          accumulatedDistance = 0,
          accumulatedElevation = 0,
        } = currentPlan || "";
        const { days } = currentTraining || {};

        const runActivities = activities.filter(
          (a) =>
            (a.type && a.type.toLowerCase().includes("run")) ||
            (a.activityType && a.activityType.includes("RUN")),
        );

        const energyLevelRate = getEnergyLevelRate(days, moment());
        const doneRate = getDoneRate(days, moment());

        const planLevel = getPlanLevel(currentTraining);
        const loadRate = getTrainingLoadRate(
          days,
          runActivities,
          moment(),
          planLevel,
        );

        const newAccumulatedDistance =
          accumulatedDistance +
          getAccumulatedDistance(runActivities, lastAccumulatedCheck);
        const newAccumulatedElevation =
          accumulatedElevation +
          getAccumulatedElevation(runActivities, lastAccumulatedCheck);

        const oldLevel = getLevel(accumulatedDistance, accumulatedElevation);
        const newLevel = getLevel(
          newAccumulatedDistance,
          newAccumulatedElevation,
        );

        if (oldLevel < newLevel) {
          setTimeout(() => {
            this.setState({
              ...this.state,
              openLevel: true,
            });
          }, 500);
        }

        this.props.dispatch(
          updateLoad(
            {
              cognito_user_sub: currentPlan.cognito_user_sub,
              latestEnergyLevelRate: energyLevelRate.points,
              latestDoneRate: doneRate,
              latestLoadRate: loadRate,
              accumulatedDistance: newAccumulatedDistance,
              accumulatedElevation: newAccumulatedElevation,
            },
            false,
            false,
          ),
        );
      }
    }

    if (
      this.analyticData.length === 2 &&
      !this.isAnalyticDataPush &&
      userPlans.loadingPlan === false
    ) {
      const analyticData = createAnalyticData(
        {
          username: email,
          userPlans,
          subscriptions,
        },
        "dashboard visit",
      );
      dispatch(pushAnalyticData(analyticData));
      this.isAnalyticDataPush = true;

      const { currentPlan } = userPlans;
      if (
        currentPlan &&
        currentPlan.cognito_user_sub &&
        currentPlan.cognito_user_email
      ) {
        this.props.dispatch(
          userPlanMailchimpSubscribe(
            {
              cognito_user_sub: currentPlan.cognito_user_sub,
              cognito_user_email: currentPlan.cognito_user_email,
              subscriptionStatus:
                subscriptions && typeof subscriptions.latest !== "undefined"
                  ? subscriptions.latest.status
                  : "",
              subscriptionPlan:
                subscriptions && typeof subscriptions.latest !== "undefined"
                  ? subscriptions.latest.plan_id
                  : "",
              lastLogin: currentPlan.lastLogin ? currentPlan.lastLogin : null,
            },
            false,
          ),
        );
      }
    }

    const { activities: activitiesStrava } = strava;
    const { activities: activitiesGarmin } = garmin;
    const { activities: activitiesSuunto } = suunto;
    const { activities: activitiesCoros } = coros;
    const { activities: activitiesVert } = vertActivities;

    const activities =
      activitiesGarmin && activitiesGarmin.length
        ? activitiesGarmin
        : activitiesSuunto && activitiesSuunto.length
        ? activitiesSuunto
        : activitiesCoros && activitiesCoros.length
        ? activitiesCoros
        : activitiesStrava && activitiesStrava.length
        ? activitiesStrava
        : activitiesVert && activitiesVert.length
        ? activitiesVert
        : [];

    if (
      (activitiesStrava &&
        activitiesStrava.length &&
        (!prevProps.strava.activities ||
          prevProps.strava.activities.length === 0)) ||
      (activitiesGarmin &&
        activitiesGarmin.length &&
        (!prevProps.garmin.activities ||
          prevProps.garmin.activities.length === 0)) ||
      (activitiesSuunto &&
        activitiesSuunto.length &&
        (!prevProps.suunto.activities ||
          prevProps.suunto.activities.length === 0)) ||
      (activitiesCoros &&
        activitiesCoros.length &&
        (!prevProps.coros.activities ||
          prevProps.coros.activities.length === 0)) ||
      (activitiesVert &&
        activitiesVert.length &&
        (!prevProps.vertActivities.activities ||
          prevProps.vertActivities.activities.length === 0))
    ) {
      const { currentPlan } = userPlans;
      const { publicGroups = [] } = currentPlan || "";

      const runActivities = activities.filter(
        (a) =>
          (a.type && a.type.toLowerCase().includes("run")) ||
          (a.activityType && a.activityType.includes("RUN")),
      );

      for (let i = 0; i < publicGroups.length; i++) {
        this.props.dispatch(
          addGroupActivities(
            currentPlan.cognito_user_sub,
            publicGroups[i],
            runActivities,
          ),
        );
      }
    }
  }

  resetWorkoutNotifications(newWorkoutNotificationsTime = null) {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const {
      currentTraining,
      showWorkoutNotifications,
      workoutNotificationsTime,
      cognito_user_sub,
    } = currentPlan || {};
    const { days } = currentTraining || {};

    const level = getPlanLevel(currentTraining);
    if (newWorkoutNotificationsTime || showWorkoutNotifications) {
      if (days) {
        LocalNotificationsService.scheduleWorkoutNotifications(
          days,
          newWorkoutNotificationsTime
            ? newWorkoutNotificationsTime
            : workoutNotificationsTime,
          cognito_user_sub,
          level,
        );
      }
    }
  }

  handleCloseUpgradeYear = () => {
    this.setState({
      ...this.state,
      upgradeYearDialogOpen: false,
    });
  };

  handleCloseLevel = () => {
    this.setState({
      ...this.state,
      openLevel: false,
    });
  };

  componentWillUnmount() {
    clearTimeout(this.state.garminTimeout);

    this.setState({
      ...this.state,
      garminTimeout: null,
    });
  }

  getDisplayErrorMessage = (err) => {
    let errorMessage = "";
    const { t } = this.props;

    if (typeof err === typeof string) {
      errorMessage = err;
    } else errorMessage = err.message || err.inputMessage || err.toString();

    switch (errorMessage) {
      case "Invalid Size":
        return t("Sorry, your image needs to be smaller than maxSize");
      case "Invalid File":
        return t("Invalid file, please try again");
      default:
        return errorMessage;
    }
  };

  handleError = (err) => {
    this.props.dispatch(setErrorMessage(this.getDisplayErrorMessage(err)));
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
    this.props.dispatch(clearSubErrorMessage());
  };

  handleFieldChanges = (changes, showMessage) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      updatePlan(
        { cognito_user_sub: currentPlan.cognito_user_sub, ...changes },
        showMessage,
      ),
    );
    if (changes.currentLocation && changes.currentGeoLocation) {
      this.props.dispatch(
        userPlanMailchimpSubscribe(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            cognito_user_email: currentPlan.cognito_user_email,
            ...changes,
          },
          showMessage,
        ),
      );
    }
  };

  handleImageFieldChange = (value, extension) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      updatePlanImage({
        cognito_user_sub: currentPlan.cognito_user_sub,
        image: value,
        imageExtension: extension && extension.toLowerCase(),
      }),
    );
  };

  capitalize = (text) => {
    return !text ? "" : text.charAt(0).toUpperCase() + text.slice(1);
  };

  handleCurrentDaysChange = (dayCount) => {
    this.props.dispatch(currentTrainingCurrentStartDateChange(dayCount));
  };

  handleCurrentDaysSet = (date) => {
    const startDate = moment()
      .startOf("isoWeek")
      .date(date);
    this.props.dispatch(currentTrainingCurrentStartDateSet(startDate));
  };

  handleWorkoutsMoved = (changeReq) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { currentTraining } = currentPlan || "";

    if (currentTraining) {
      this.props.dispatch(
        updatePlanSwap(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            ...changeReq,
          },
          currentTraining,
          0,
          false,
        ),
      );

      // setTimeout(() => this.resetWorkoutNotifications(), 1000);
    }
  };

  handleRedoWeek = (currentStartDate, to) => {
    const { userPlans } = this.props;
    const { currentPlan, chainedPlans } = userPlans;
    const { currentTraining } = currentPlan || "";

    if (currentStartDate) {
      const plan = getPlan(
        [
          currentPlan,
          ...chainedPlans.map((c, index) => {
            return {
              updated_at: currentPlan.chainedPlans[index],
              currentTraining: c,
            };
          }),
        ],
        moment(currentStartDate),
      );

      if (plan) {
        this.props.dispatch(
          updatePlanRedoWeek(
            {
              cognito_user_sub: currentPlan.cognito_user_sub,
              startDate: moment(currentStartDate)
                .startOf("isoWeek")
                .format(dateFormat),
              toDate:
                to === "now"
                  ? moment()
                      .startOf("isoWeek")
                      .format(dateFormat)
                  : moment()
                      .startOf("isoWeek")
                      .add(7, "days")
                      .format(dateFormat),
              lastModified: plan.lastModified,
            },
            currentTraining,
            plan.updated_at === "latest" ? 0 : plan.updated_at,
            false,
          ),
        );

        // setTimeout(() => this.resetWorkoutNotifications(), 1000);
      }
    }
  };

  handleAddEasyWeek = (currentStartDate, raceCount, trainPerWeek) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { currentTraining } = currentPlan || "";

    if (currentStartDate) {
      this.props.dispatch(
        updatePlanAddEasyWeek(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            startDate: moment(currentStartDate)
              .startOf("isoWeek")
              .format(dateFormat),
          },
          currentTraining,
          raceCount,
          trainPerWeek,
          0,
          false,
        ),
      );

      // setTimeout(() => this.resetWorkoutNotifications(), 1000);
    }
  };

  handleRestartPlan = async (desiredStartDate) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { currentTraining } = currentPlan || {};

    await this.handleCurrentDaysSet(desiredStartDate);

    if (desiredStartDate && currentTraining) {
      await this.props.dispatch(
        autoAssignPlan(
          {
            ...currentTraining,
            desiredStartDate,
            cognito_user_sub: currentPlan.cognito_user_sub,
          },
          false,
        ),
      );
      // setTimeout(() => this.resetWorkoutNotifications(), 1000);
    }
  };

  handleOpenAdd = () => {
    this.setState({
      ...this.state,
      isOpenAddWorkout: true,
    });
  };

  handleCloseAddWorkout = () => {
    this.setState({
      ...this.state,
      isOpenAddWorkout: false,
    });
  };

  handleCloseAddWorkoutConfirm = () => {
    this.setState({
      ...this.state,
      isOpenAddWorkoutConfirm: false,
    });
  };

  handleActivity = (data) => {
    this.setState({
      ...this.state,
      currentActivity: data,
    });
  };

  handleAddWorkout = (workoutData) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { currentTraining } = currentPlan || {};
    const workout = {
      created_by_cognito_user_sub: currentPlan.cognito_user_sub,
      description: {
        en: workoutData.workoutDescription,
        es: workoutData.workoutDescription,
      },
      description_lower: `${workoutData.workoutDescription.toLowerCase()} ${workoutData.workoutDescription.toLowerCase()}`,
      difficultyLevel: "1",
      estimatedTime: workoutData.workoutDuration,
      id: new Date().getTime().toString(),
      plannedDate: workoutData.workoutDate,
      surface: "Flat",
      title: {
        en: "User created workout",
        es: "Entrenamiento creado por el usuario",
      },
      title_lower: "user created workout entrenamiento creado por el usuario",
      trainingPeriod: "Basic",
      trainingTime: "AllDay",
      updated_at: new Date().getTime(),
      workoutType: "UserCreated",
      userCreatedType: workoutData.workoutType,
    };
    if (currentTraining) {
      if (currentTraining.days) {
        const existingWorkout = currentTraining.days.find(
          (d) => d && d[0] && d[0].plannedDate === workoutData.workoutDate,
        );
        if (existingWorkout) {
          this.setState({
            ...this.state,
            isOpenAddWorkoutConfirm: true,
            workoutToAdd: workout,
          });
        } else {
          this.setState({
            ...this.state,
            workoutToAdd: workout,
          });
          setTimeout(() => this.doAdd(), 100);
        }
      }
    }
  };

  doAdd = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { currentTraining } = currentPlan || {};

    if (this.state.workoutToAdd) {
      this.props.dispatch(
        updatePlanAddWorkout(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            workout: this.state.workoutToAdd,
          },
          currentTraining,
          0,
          false,
        ),
      );

      // setTimeout(() => this.resetWorkoutNotifications(), 1000);
    }
    this.handleCloseAddWorkout();
  };

  findWorkout = (day, days) => {
    return days.find((w) => w && w[0].plannedDate === day);
  };

  getActivities = async () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    const { healthKit: activeHealthkit } = currentPlan || {};
    await this.props.dispatch(getUserActivities(currentPlan));
    if (activeHealthkit) await this.props.dispatch(getActivitiesHealthKit());
  };

  handleActivateTrailhead = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    if (currentPlan) {
      this.props.dispatch(
        updatePlan(
          { cognito_user_sub: currentPlan.cognito_user_sub, trailhead: true },
          false,
          false,
        ),
      );
    }
  };

  handleOnClose = () => {
    this.setState({
      ...this.state,
      showWelcomeModal: false,
    });
  };

  showModal = () => {
    this.setState({
      ...this.state,
      showWelcomeModal: true,
    });

    this.showModal = null;
  };

  handleCalendarWorkout = (workout) => {
    this.setState({
      ...this.state,
      calendarSelectedWorkout: workout,
    });
  };

  handleFilters = (btnValue) => {
    this.setState({
      ...this.state,
      currentFilter: btnValue,
    });
  };

  handleTimeSelect = (item) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    if (item && item.value) {
      const { value } = item;
      const { showWorkoutNotifications, workoutNotificationsTime } = value;
      this.props.dispatch(
        updatePlan(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            showWorkoutNotifications: showWorkoutNotifications,
            workoutNotificationsTime,
          },
          false,
          false,
        ),
      );

      // this.resetWorkoutNotifications(workoutNotificationsTime);
    } else {
      this.props.dispatch(
        updatePlan(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            showWorkoutNotifications: false,
          },
          false,
          false,
        ),
      );
    }
    this.setState({
      ...this.state,
      isOpenWorkoutNotificationDialog: false,
    });
  };

  render() {
    const {
      classes,
      userPlans,
      auth,
      strava,
      garmin,
      suunto,
      location = {},
      width,
      t,
      subscriptions,
      vertActivities,
      subscriptions: { latest, loadingLatest, errorMessage: subErrorMessage },
      healthKit,
      coros,
    } = this.props;
    const {
      loading,
      loadingPlan,
      currentPlan,
      successMessage,
      errorMessage,
      planToSelect,
      comments,
      loginCount,
    } = userPlans;
    const {
      image,
      currentTraining,
      challengesUser,
      latestSubscriptionFinalizedTime,
    } = currentPlan || "";

    const fullTraining = currentTraining;

    const { activities: activitiesStrava, loading: stravaLoading } = strava;
    const { activities: activitiesGarmin, loading: garminLoading } = garmin;
    const { activities: activitiesSuunto, loading: suuntoloading } = suunto;
    const { activities: activitiesCoros, loading: corosloading } = coros;
    const { activities: activitiesVert, loading: vertLoading } = vertActivities;
    const {
      activities: healthActivities,
      loading: loadingHealthkit,
    } = healthKit;

    const isLoading =
      stravaLoading ||
      garminLoading ||
      suuntoloading ||
      vertLoading ||
      loadingHealthkit ||
      corosloading;

    const activities =
      activitiesGarmin && activitiesGarmin.length
        ? activitiesGarmin
        : activitiesSuunto && activitiesSuunto.length
        ? activitiesSuunto
        : activitiesCoros && activitiesCoros.length
        ? activitiesCoros
        : activitiesStrava && activitiesStrava.length
        ? activitiesStrava
        : activitiesVert && activitiesVert.length
        ? activitiesVert
        : [];

    const {
      isOpenAddWorkout,
      isOpenAddWorkoutConfirm,
      upgradeYearDialogOpen,
      showYearModal,
    } = this.state;

    const allActivities = [
      ...(activitiesStrava || []),
      ...(activitiesGarmin || []),
      ...(activitiesSuunto || []),
      ...(activitiesVert || []),
      ...(healthActivities || []),
      ...(activitiesCoros || []),
    ];

    const handleYearModal = () => {
      this.setState({ ...this.state, showYearModal: false });
    };

    const hideRetry = () => this.setState({ ...this.state, showSub: false });
    const handleRetryModal = () =>
      this.setState({ ...this.state, showsubModal: !this.state.showsubModal });

    const handleChatAction = (hasSubscription) => {
      if (hasSubscription) return history.push("/dashboard/chat");
      this.setState({ ...this.state, showpaywall: true });
    };

    const onClosePaywallModal = () => {
      this.setState({ ...this.state, showpaywall: false });
      history.push("/dashboard/chat");
    };

    const { nextRace, nextRaces } = currentPlan || {};
    let allRaces = [];
    if (nextRace) {
      const today = moment();
      const raceDateMoment = moment(nextRace.raceDate, "DD/MM/YYYY");
      const weeksToRace = raceDateMoment.diff(today, "day");
      if (weeksToRace >= 0) {
        allRaces.push(nextRace);
      }
    }
    if (nextRaces && nextRaces.length > 0) {
      nextRaces.forEach((element) => {
        const today = moment();
        const raceDateMoment = moment(element.raceDate, "DD/MM/YYYY");
        const weeksToRace = raceDateMoment.diff(today, "day");
        if (weeksToRace >= 0) {
          allRaces.push(element);
        }
      });
    }

    allRaces.sort((a, b) => {
      let aMoment;
      if (a && a.raceDate) {
        aMoment = moment(a.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      let bMoment;
      if (b && b.raceDate) {
        bMoment = moment(b.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      return aMoment.diff(bMoment, "days");
    });

    let elevation = 0;
    let distance = 0;

    let { selectedPlan } = fullTraining || {};

    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    if (activities && activities.length > 0) {
      const elevationDecimal = activities
        .map((activity) => activity.total_elevation_gain || 0)
        .reduce((sum, a) => sum + a);
      elevation = parseInt(elevationDecimal || 0);
      const distanceMeters = activities
        .map((activity) => activity.distance || 0)
        .reduce((sum, a) => sum + a);
      distance = parseFloat(
        parseFloat(distanceMeters / 1000).toFixed(1),
      ).toLocaleString();
    }

    let pathnameRedirect = "/chooseplan";
    let needToRedirect = false;

    if (localStorage.getItem("challengesUser") || challengesUser) {
      pathnameRedirect = "challenges";
    }

    if (planToSelectType && planToSelectId) {
      pathnameRedirect = `/subscription/${planToSelectType}`;
    }

    if (+storageFactory().getItem("currentGoal") === 5) {
      pathnameRedirect = "/trailhead";
      needToRedirect = true;
    } else if (+storageFactory().getItem("currentGoal") === 4) {
      pathnameRedirect = "/explore";
      needToRedirect = true;
    }

    if (/1|2|3/.test(+storageFactory().getItem("currentGoal"))) {
      pathnameRedirect = "/chooseplan";
      needToRedirect = true;
      localStorage.setItem("flag", true);
    }

    const isSubscribed = checkSubscription(
      subscriptions,
      latestSubscriptionFinalizedTime,
    );

    const { currentUserLoading } = auth;

    const isInMobileView = width === "md" || width === "sm" || width === "xs";

    const { plan: planToSelectType, id: planToSelectId } = planToSelect || {};

    const unreadComments = comments
      ? comments.filter((c) => c.type === 0 && !c.isRead)
      : [];

    return needToRedirect ? (
      <Redirect
        to={{
          pathname: pathnameRedirect,
          state: { from: location },
        }}
      />
    ) : loading ||
      currentUserLoading ||
      (!currentPlan && loadingPlan) ||
      loadingLatest ? (
      <div className={classes.progressContainer}>
        <CircularProgress
          variant="indeterminate"
          color="primary"
          className={classes.loading}
          data-testid="LoadingUser"
        />
      </div>
    ) : (
      <ThemeProvider theme={theme}>
        <div className={classes.container}>
          <WelcomeModal
            open={this.state.showWelcomeModal}
            onClose={this.handleOnClose}
          />

          {showYearModal && !isInMobileView && (
            <EndYearModal open={showYearModal} onClose={handleYearModal} />
          )}

          {this.state.showsubModal && (
            <RetrySubModal onClose={handleRetryModal} hideRetry={hideRetry} />
          )}

          <PaywallModal
            open={this.state.showpaywall}
            onClose={onClosePaywallModal}
            title="Paywall Title 1"
            btnText="Try 7 days for free"
            htmlContent
            content="Paywall Content 1"
            price={false}
          />

          <AppBar
            isOnDashboard={true}
            isNotOnChoosingPlanPage={true}
            showLanguageMenu={false}
            showChoosePlanHeader={false}
            whiteLogoOnMobileView={false}
            isOnSubscriptionPage={false}
            subscriptionError={this.state.showSub}
            handleRetryModal={handleRetryModal}
          />

          <Grid container spacing={16}>
            {!isInMobileView && (
              <DashboardTrainingColumn
                xs={12}
                sm={12}
                md={4}
                lg={3}
                onCurrentDaysChangeRequested={this.handleCurrentDaysChange}
                onWorkoutsMoved={this.handleWorkoutsMoved}
                onError={this.handleError}
                onRedoWeek={this.handleRedoWeek}
                onAddEasyWeek={this.handleAddEasyWeek}
                onRestartPlan={this.handleRestartPlan}
                onOpenAdd={this.handleOpenAdd}
                openLevel={this.state.openLevel}
                onCloseLevel={this.handleCloseLevel}
                activities={allActivities}
              />
            )}
            {/* NEW HOME */}
            {isInMobileView && (
              <TrainingProvider>
                <Box
                  sx={{
                    padding: "1rem",
                    boxSizing: "border-box",
                    width: "100%",
                    marginBottom: "16px",
                  }}
                >
                  {typeof currentTraining === "string" && (
                    <>
                      {loginCount <= 3 ? (
                        <GeneralCard
                          title="Welcome to vert!"
                          content="We are thrilled to have you here! Lets get you started with a plan
  to help you reach your goals."
                        />
                      ) : (
                        <GeneralCard
                          title="Todays workout"
                          content="Choose a plan to start your training adventure."
                        />
                      )}
                    </>
                  )}

                  <Box style={{ marginTop: "1rem" }}>
                    {typeof currentTraining === "object" &&
                      !!Object.values(currentTraining || {}).length && (
                        <WorkoutCard />
                      )}
                  </Box>

                  <Box style={{ marginTop: "1rem" }}>
                    {nextRaces && nextRaces.length > 0 && <NextRaceSlider />}
                  </Box>
                  <PopularPlans />
                  <TrailHeadPreview />
                  <PopularVideos />
                  <PopularPodcast />
                </Box>
              </TrainingProvider>
            )}
            {!isInMobileView ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={9}
                className={classes.trainingPane}
              >
                <>
                  <div className={classes.filtersParent}>
                    <Typography
                      className={
                        this.state.currentFilter == 0
                          ? classes.filterBtnSelected
                          : classes.filterBtn
                      }
                      onClick={() => this.handleFilters(0)}
                    >
                      {t("Your achivements", { ns: "dashboard" })}
                    </Typography>
                    <Typography
                      className={
                        this.state.currentFilter == 1
                          ? classes.filterBtnSelected
                          : classes.filterBtn
                      }
                      onClick={() => this.handleFilters(1)}
                    >
                      {t("Your activities", { ns: "dashboard" })}
                    </Typography>
                  </div>
                  {this.state.currentFilter === 0 && (
                    <>
                      <DashboardDesktopCharts
                        currentTraining={fullTraining}
                        activities={activities}
                      ></DashboardDesktopCharts>
                      <DashboardWeeklyReportDiagram
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.weeklyReport}
                        currentTraining={fullTraining}
                        distance={distance}
                        elevation={elevation}
                        activities={activities}
                      />
                    </>
                  )}

                  {this.state.currentFilter === 1 && (
                    <DashboardActivityList
                      activities={allActivities}
                      selectActivity={this.handleActivity}
                      isLoading={isLoading}
                    />
                  )}
                </>
              </Grid>
            ) : (
              ""
            )}
            <SnackBarMessageDisplay
              onHideMessage={this.handleHideMessage}
              errorMessage={errorMessage || subErrorMessage}
              successMessage={successMessage}
            />
          </Grid>
          <Route
            path="/dashboard/nextRace"
            component={DashboardNextRaceDetails}
          />
          <Route
            path="/dashboard/:id/:plannedDate"
            component={DashboardTrainingWeekdayDetails}
          />
          <Route path="/dashboard/chat" component={DashboardChat} />
          <Route
            path="/dashboard/:id/:plannedDate/chat"
            component={DashboardChat}
          />

          {!isInMobileView ? (
            <div
              className={classes.commentIcon}
              onClick={() => handleChatAction(!!latest)}
            >
              <Badge color="error" badgeContent={unreadComments.length}>
                <img src={`${process.env.PUBLIC_URL}/comment.svg`} alt="" />
              </Badge>
            </div>
          ) : (
            ""
          )}

          <Dialog
            open={isOpenAddWorkout}
            onClose={this.handleCloseAddWorkout}
            aria-labelledby="Workout"
            scroll="body"
            maxWidth="md"
            className={classes.dialog}
          >
            <DashboardTrainingWeekdayAdd
              onClose={this.handleCloseAddWorkout}
              onAdd={this.handleAddWorkout}
            ></DashboardTrainingWeekdayAdd>
          </Dialog>

          <Dialog
            open={isOpenAddWorkoutConfirm}
            aria-labelledby="delete-dialog"
            onClose={this.handleCloseAddWorkoutConfirm}
          >
            <DialogContent>
              <Typography variant="body1" className={classes.textInfo}>
                {t("Are you sure you want to add workout?")}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.doAdd();
                  setTimeout(() => this.handleCloseAddWorkoutConfirm(), 100);
                }}
                color="primary"
              >
                {t("Yes")}
              </Button>
              <Button
                onClick={this.handleCloseAddWorkoutConfirm}
                color="primary"
              >
                {t("No")}
              </Button>
            </DialogActions>
          </Dialog>

          {!isInMobileView ? (
            <TrailLevelPopup
              isOpen={this.state.openLevel}
              onClose={() => this.handleCloseLevel()}
            ></TrailLevelPopup>
          ) : (
            ""
          )}

          <UpgradeYearModal
            open={upgradeYearDialogOpen}
            onClose={this.handleCloseUpgradeYear}
          />
        </div>
      </ThemeProvider>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  subcategories: PropTypes.object.isRequired,
  userPlans: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  strava: PropTypes.object.isRequired,
  garmin: PropTypes.object.isRequired,
  suunto: PropTypes.object.isRequired,
  width: PropTypes.any,
  subscriptions: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
  clearSuccessMessage: PropTypes.func,
  clearErrorMessage: PropTypes.func,
  setErrorMessage: PropTypes.func,
  updatePlan: PropTypes.func,
  updatePlanImage: PropTypes.func,
  userPlanMailchimpSubscribe: PropTypes.func,
  currentTrainingCurrentStartDateChange: PropTypes.func,
  currentTrainingCurrentStartDateSet: PropTypes.func,
  coros: PropTypes.object.isRequired,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
    subcategories: store.subcategories,
    auth: store.auth,
    strava: store.strava,
    garmin: store.garmin,
    suunto: store.suunto,
    vertActivities: store.vertActivities,
    subscriptions: store.subscriptions,
    challenges: store.challenges,
    healthKit: store.healthKit,
    coros: store.coros,
  };
})(
  withTranslation(["messages", "constants", "dashboard"])(
    withWidth()(withStyles(styles, { withTheme: true })(memo(Dashboard))),
  ),
);
