import React from "react";
import { SvgIcon } from "@material-ui/core";
import { palette } from "../../theme/palette";

const EditIcon = ({ width = 24, height = 24, activated = false }) => (
  <SvgIcon style={{ width, height }}>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 12.258C5.25 12.5348 5.47489 12.759 5.7517 12.758L8.15033 12.7499C8.41271 12.749 8.66422 12.645 8.85064 12.4604L15.7838 5.59349C16.0673 5.30999 16.2233 4.93349 16.2233 4.53299C16.2233 4.13249 16.0673 3.75599 15.7838 3.47249L14.5943 2.28299C14.0273 1.71599 13.038 1.71899 12.4755 2.28074L5.54629 9.14377C5.35668 9.33158 5.25 9.58739 5.25 9.85426V12.258ZM13.5338 3.34349L14.7255 4.53074L13.5278 5.71724L12.3383 4.52849L13.5338 3.34349ZM6.75 10.0627L11.2725 5.58299L12.462 6.77249L7.94025 11.2507H6.75V10.0627Z"
        fill={activated ? palette.primary : palette.darkGray}
      />
      <path
        d="M3.75 15.75H14.25C15.0773 15.75 15.75 15.0773 15.75 14.25V7.749L14.25 9.249V14.25H6.1185C6.099 14.25 6.07875 14.2575 6.05925 14.2575C6.0345 14.2575 6.00975 14.2507 5.98425 14.25H3.75V3.75H8.88525L10.3853 2.25H3.75C2.92275 2.25 2.25 2.92275 2.25 3.75V14.25C2.25 15.0773 2.92275 15.75 3.75 15.75Z"
        fill={activated ? palette.primary : palette.darkGray}
      />
    </svg>
  </SvgIcon>
);
export default EditIcon;
