import React, { useEffect } from "react";
import { Box, Typography, makeStyles, Divider } from "@material-ui/core";
import { palette } from "../../theme/palette";
import { useSelector, useDispatch } from "react-redux";
import MicIcon from "@material-ui/icons/MicNoneOutlined";
import { getExplorePosts } from "../../actions/trail-head-action";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  card: {
    background: palette.white,
    borderRadius: "8px",
    marginTop: "12px",
    width: "275px",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
  button: {
    fontFamily: "Karla",
    marginTop: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "18px",
    textTransform: "lowercase",
  },
  descriptionContainer: {
    padding: "12px",
  },
  divider: {
    background: palette.opacityGray,
    width: "2px",
    height: "40px",
  },
  label: {
    color: palette.darkGray,
    marginTop: "4px",
  },
  labelOnly: {
    color: palette.darkGray,
  },
  margin: {
    marginTop: "12px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "40px",
  },
  seeAll: {
    color: palette.lightBlue,
  },
  content: {
    color: palette.darkGray,
    fontWeight: "400",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 0px 16px 0px",
    boxSizing: "border-box",
  },
  rowLeft: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontWeight: "700",
  },
  bold: {
    fontWeight: "700",
  },
});

const iconStyle = {
  color: palette.darkGray,
  width: "18px",
  margin: 0,
  padding: 0,
  marginRight: "4px",
};

const skeletonStyles = {
  marginBottom: "16px",
};

const PopularPodcast = () => {
  const {
    t,
    i18n: { language = "en" },
  } = useTranslation("home");
  const history = useHistory();
  const { explorePosts, loadingExplorePosts } = useSelector(
    (state) => state.trailHead,
  );
  const { data: podcast } = explorePosts["Podcast"] || {};
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (!podcast || !podcast.length)
      dispatch(getExplorePosts(new Date().getTime(), "Podcast", true));
  }, [podcast]);

  const navigate = () => history.push("/all-podcast");

  const navigatePodtcast = ({ id }) => {
    history.push(`explore/post/${id}`);
  };

  return (
    <>
      <Box marginTop="40px">
        {!loadingExplorePosts["Podcast"] && (
          <Box className={classes.header}>
            <Typography variant="h3" className={classes.bold}>
              {t("Podcast episodes")}
            </Typography>
            <Typography
              variant="body2"
              className={classes.seeAll}
              onClick={navigate}
              id="home_see_all_podcast"
            >
              {t("See all")}
            </Typography>
          </Box>
        )}
      </Box>

      {loadingExplorePosts["Podcast"] && (
        <>
          <Skeleton
            count={1}
            borderRadius={4}
            height={15}
            baseColor={palette.skeleton}
            style={skeletonStyles}
          />
          <Skeleton
            count={6}
            borderRadius={4}
            height={62}
            baseColor={palette.skeleton}
            style={skeletonStyles}
          />
        </>
      )}
      {(podcast || []).slice(0, 5).map((item, index) => (
        <Box key={index}>
          <Box
            className={classes.row}
            key={index}
            id={`home_podcast_pos${index + 1}`}
            onClick={() => navigatePodtcast(item)}
          >
            <Box>
              <Typography variant="h5" className={classes.title}>
                {item.title[language] ? item.title[language] : item.title["en"]}
              </Typography>
              <Box className={classes.rowLeft}>
                <MicIcon style={iconStyle} />
                <Typography variant="subtitle2" className={classes.content}>
                  {item.time}
                </Typography>
              </Box>
            </Box>
            <Box style={{ padding: "10px" }}>
              <img
                src={`${process.env.PUBLIC_URL}/icon-play-black.svg`}
                width={35}
              ></img>
            </Box>
          </Box>
          <Divider style={{ background: palette.opacityGray }} />
        </Box>
      ))}
    </>
  );
};

export default PopularPodcast;
