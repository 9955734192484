import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "../../theme";
import useAppNav from "../../hooks/useAppNav";
import routes from "./routes";
import PrivateRoute from "../../components/private-route";
import UserProfileMenu from "../../components/layout/user-profile-menu";
import AppNav from "../../components/common/v2/AppNav";
import { useLocation } from "react-router-dom";
import useRouteInfo from "../../hooks/useRouteInfo";
import { AuthProvider } from "../../context/AuthProvider";
import { Box } from "@material-ui/core";
import { ChatProvider } from "../../context/ChatProvider";
import { SafeArea } from "capacitor-plugin-safe-area";
import { PusherProvider } from "../../context/PusherProvider";

const MobileLayout = () => {
  const location = useLocation();
  const { appNavState } = useAppNav(location);
  const {
    isOnProfile,
    isOnDashboard,
    isOnTraining,
    isOnTrailhead,
    activeSpacingContent,
  } = useRouteInfo(location);

  useEffect(() => {
    const safeAreaChanged = async () => {
      await SafeArea.removeAllListeners();
      await SafeArea.addListener("safeAreaChanged", (data) => {
        const { insets } = data;
        for (const [key, value] of Object.entries(insets)) {
          document.documentElement.style.setProperty(
            `--safe-area-inset-${key}`,
            `${value}px`,
          );
        }
      });
    };

    safeAreaChanged();
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <AuthProvider>
        <PusherProvider>
          <ChatProvider>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  top: "env(safe-area-inset-top)",
                  zIndex: 1000,
                  position: "fixed",
                  width: "100%",
                  paddingTop: "env(safe-area-inset-top)",
                }}
              >
                <AppNav {...appNavState} />
              </Box>
              <Box
                sx={{
                  marginTop: `calc(env(safe-area-inset-top) + ${
                    activeSpacingContent ? "74px" : "0"
                  })`,
                  marginBottom: `calc(env(safe-area-inset-bottom) + ${
                    activeSpacingContent ? "74px" : "0"
                  })`,
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                }}
              >
                {routes.map((route) => {
                  if (!route.private)
                    return (
                      <Route
                        key={route.path}
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                      />
                    );
                  else
                    return (
                      <PrivateRoute
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    );
                })}
              </Box>
              <Box
                sx={{
                  bottom: "env(safe-area-inset-bottom)",
                  zIndex: 10,
                  position: "fixed",
                  width: "100%",
                  paddingBottom: "env(safe-area-inset-bottom)",
                }}
              >
                <UserProfileMenu
                  isOnProfile={isOnProfile}
                  isOnDashboard={isOnDashboard}
                  isOnTraining={isOnTraining}
                  isOnTrailhead={isOnTrailhead}
                  visible={appNavState.visible}
                />
              </Box>
            </Box>
          </ChatProvider>
        </PusherProvider>
      </AuthProvider>
    </MuiThemeProvider>
  );
};

export default MobileLayout;
