import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { round } from "../../lib/units-helper";
import unitsHook from "../../hooks/unitsHook";
import { convertUnits } from "../../lib/units-helper";

const styles = (theme) => ({
  parent: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentParent: {
    position: "absolute",
  },
  number: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "18px",
  },
  stats: {
    textAlign: "center",
    color: "#8B8B8B",
    fontSize: "9px",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "2px",
    textAlign: "center",
  },
});

const WorkoutStat = ({ classes, t, distance, elevation }) => {
  const baseUrl = process.env.PUBLIC_URL;
  const units = unitsHook();

  const calculateValue = (distance = 0, elevation = 0) => {
    const isValid =
      !isNaN(distance) && distance > 0 && !isNaN(elevation) && elevation > 0;
    const result = elevation / distance;

    return isValid ? `${round(result)}`.substring(0, 4) : 0;
  };

  return (
    <>
      <Typography variant="body2" className={classes.title}>
        {t("Mountain index")}
      </Typography>
      <div className={classes.parent}>
        <img
          src={`${baseUrl}/frame-chart.svg`}
          alt="workout-frame"
          width={100}
        />
        <div className={classes.contentParent}>
          <Typography variant="body1" className={classes.number}>
            {calculateValue(distance, elevation)}
          </Typography>
          <Typography variant="body2" className={classes.stats}>
            {units == "kms" ? "m+ / km" : `${t("feet")}+ / ${t("miles")}`}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default withTranslation("challenges")(
  withStyles(styles, { withTheme: true })(WorkoutStat),
);
