import React from "react";
import {
  makeStyles,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  AppBar,
  Slide,
  Toolbar,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { palette } from "../../../theme/palette";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalCustom = ({
  children,
  title = null,
  fullScreen = true,
  open = false,
  onClose,
  paperDialogStyles = { borderRadius: 8, margin: 20 },
  headerStyles = {},
  contentStyles = {},
  toolbarJustifyContent = "start",
  callToActions = null,
}) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: paperDialogStyles,
        }}
      >
        <AppBar
          className={classes.appBar}
          elevation={0}
          style={{ ...headerStyles }}
        >
          <Toolbar
            className={classes.toolbar}
            style={{
              justifyContent: toolbarJustifyContent
                ? toolbarJustifyContent
                : "start",
            }}
          >
            {callToActions && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 8 }}>
                {callToActions.map(
                  ({ onClick, ActionIconComponent }, index) => (
                    <IconButton onClick={() => onClick()} key={index}>
                      {ActionIconComponent}
                    </IconButton>
                  ),
                )}
              </Box>
            )}
            {title && (
              <Typography variant="h4" className={classes.title}>
                {title.toUpperCase()}
              </Typography>
            )}
            <IconButton
              className={classes.close}
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent
          style={{
            padding: "8px 30px 30px",
            ...contentStyles,
          }}
        >
          <div>{children}</div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(() => ({
  appBar: {
    position: "relative",
    backgroundColor: palette.white,
    padding: "10px 20px 5px 20px !important",
  },
  toolbar: {
    padding: 0,
  },
  title: {
    fontFamily: "Oswald",
    fontSize: 18,
    fontWeight: 500,
    flex: 1,
    color: palette.black,
  },
  close: {
    color: palette.darkGray,
  },
}));
export default ModalCustom;
