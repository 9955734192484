import React, { Component, memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Typography, Tooltip, CircularProgress } from "@material-ui/core";
import moment from "moment";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Weekdays from "../../../constants/weekdays";
import DashboardTrainingWeekdayDay from "./dashboard-training-weekday/dashboard-training-weekday-day";
import "moment/min/locales";
import EstimatedTimes from "../../../constants/estimated-times";
import WorkoutTypes from "../../../constants/workout-types";
import history from "../../../lib/history-helper";
import { logEvent } from "../../../lib/events-helper";
import { getCustomLevel } from "../../../lib/plan-helper";
import { useAnalyticsEvent } from "../../../hooks/useAnalyticsEvent";

const styles = (theme) => ({
  container: {
    display: "flex",
    margin: 12,
    width: "calc(100% - 24px)",
    cursor: "pointer",
  },
  containerCalendar: {
    display: "flex",
    width: "100%",
    margin: 0,
    cursor: "pointer",
  },
  dontDisplay: {
    display: "none",
  },
  todayMobileContainer: {
    width: "100%",
  },

  descriptionContainer: {
    borderRadius: 8,
    textAlign: "left",
    position: "relative",
    width: "100%",

    display: "flex",
    alignItems: "center",

    padding: 14,

    [theme.breakpoints.down("md")]: {
      padding: 6,
    },
  },

  descriptionContainerRecovery: {
    borderRadius: 8,
    textAlign: "left",
    position: "relative",
    width: "100%",

    display: "flex",
    alignItems: "center",

    background: "#F5F5F5",
    padding: 14,

    [theme.breakpoints.down("md")]: {
      padding: 6,
    },
  },

  description: {
    fontSize: 14,
    fontWeight: "normal",

    color: "#212121",
  },
  descriptionTime: {
    fontWeight: 400,

    fontSize: 12,
    color: "#212121",
    margin: "0 12px 0 6px",
  },
  todayWorkout: {
    fontWeight: 800,
  },
  notDonePassedWorkout: {
    // backgroundColor: amber[100]
  },
  progress: {
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  iconContainer: {
    textAlign: "right",
    position: "absolute",
    bottom: 5,
    right: 5,
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      display: "flex",
      marginLeft: "auto",
      position: "relative",
      bottom: "initial",
      right: "initial",
    },
  },
  white: {
    color: theme.palette.common.white,
  },
  secondaryDark: {
    color: theme.palette.secondary.dark,
  },
  descriptionLink: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  descriptionContainerBreak: {
    height: "100%",
    width: "100%",
  },
  todayDateContainer: {
    fontWeight: 500,
    color: "#1E629B",
  },
  iconSwap: {
    marginRight: 10,
    width: 24,
  },
});

class DashboardTrainingWeekday extends Component {
  openLink(id, canOpen, plannedDateFormatted) {
    const { onSendAnalytics, userPlans } = this.props;
    const { currentPlan } = userPlans;
    if (id && canOpen) {
      history.push(`/dashboard/${id}/${plannedDateFormatted}`);
    }
  }
  render() {
    const {
      classes,
      trainingDay,
      day,
      isInMobileView,
      t,
      i18n,
      onMobileSwap,
      isCalendar = false,
      lastVisibleDate,
      isSample,
      updatingWorkoutId,
      onSendAnalytics,
      level,
      userPlans,
    } = this.props;
    const training =
      trainingDay && trainingDay.length > 0 ? trainingDay[0] : undefined;
    const { language } = i18n;
    const {
      workoutType,
      isDone = false,
      id,
      comment,
      plannedDate = "",
      estimatedTime,
      userCreatedType,
      publicTitle,
    } = training || {};

    const { currentPlan } = userPlans || {};
    const { currentTraining } = currentPlan || {};
    const { chainedPlans } = currentTraining || {};

    const customLevel = getCustomLevel(plannedDate, chainedPlans);

    const newEstimatedTime = Array.isArray(estimatedTime)
      ? estimatedTime[!isNaN(customLevel) ? customLevel : level]
      : estimatedTime;

    const plannedDateFormatted = plannedDate
      .replace("/", "-")
      .replace("/", "-");
    const today = moment()
      .startOf("day")
      .toISOString();
    const dayMoment = moment(day)
      .startOf("day")
      .toISOString();
    const hasComment = typeof comment !== undefined && comment;
    const momentLocale = moment().locale(language);
    const weekdayMoment = moment(day);

    const canOpen = lastVisibleDate
      ? weekdayMoment.isSameOrBefore(lastVisibleDate)
      : true;
    return (
      <div
        className={classNames(
          isInMobileView || !isCalendar
            ? classes.container
            : classes.containerCalendar,
          {
            [classes.dontDisplay]: isInMobileView && !training,
          },
        )}
      >
        <div
          onClick={() =>
            this.openLink(id, canOpen && !isSample, plannedDateFormatted)
          }
          className={classes.descriptionLink}
        >
          <DashboardTrainingWeekdayDay
            onSendAnalytics={onSendAnalytics}
            title={`${t(
              `${Weekdays.itemsArray[moment(day).isoWeekday() - 1].text}`,
              { ns: "constants" },
            )
              .substring(0, 3)
              .toUpperCase()}`}
            subtitle={`${weekdayMoment.format("D")}-${momentLocale
              .localeData()
              .months(weekdayMoment)
              .substring(0, 3)
              .toUpperCase()}`}
            detailLink={!id ? "" : `/dashboard/${id}/${plannedDateFormatted}`}
            hasTraining={training !== undefined}
            className={classNames({
              [classes.todayDateContainer]: dayMoment === today,
            })}
            isInMobileView={isInMobileView}
          />
          {training ? (
            <div
              className={classes.descriptionContainer}
              style={{
                backgroundColor: WorkoutTypes.Colors[workoutType],
              }}
            >
              {workoutType !== WorkoutTypes.Items.Recovery ? (
                <img
                  src={`${process.env.PUBLIC_URL}/icon-timer-w.svg`}
                  alt=""
                />
              ) : (
                ""
              )}

              {workoutType !== WorkoutTypes.Items.Recovery ? (
                <Typography
                  variant="body2"
                  className={classes.descriptionTime}
                  style={{
                    color: "#fff",
                  }}
                >
                  {EstimatedTimes.convertMinutesToLabel(newEstimatedTime, true)}
                </Typography>
              ) : (
                ""
              )}

              <Typography
                variant="body2"
                className={classes.description}
                style={{
                  color: "#fff",
                }}
              >
                {/* {isCalendar
                  ? `${plannedDate}: `
                  : isInMobileView
                    ? `${t(
                      `${Weekdays.itemsArray[moment(day).isoWeekday() - 1].text
                      }`,
                      { ns: "constants" },
                    )}: `
                    : ""} */}

                {userCreatedType
                  ? userCreatedType
                  : !publicTitle || !publicTitle[language]
                  ? t(`${WorkoutTypes.Items[workoutType]}`, {
                      ns: "constants",
                    })
                  : publicTitle[language]}
              </Typography>
              <div className={classes.iconContainer}>
                {isInMobileView && !isCalendar && !isSample ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-swap.svg`}
                    alt=""
                    className={classes.iconSwap}
                    onClick={(event) => onMobileSwap(event)}
                  />
                ) : (
                  ""
                )}
                <Tooltip
                  title={
                    !isDone && dayMoment <= today
                      ? t("Mark as done")
                      : hasComment
                      ? t("This training already has a note")
                      : t("Write a note about your training")
                  }
                >
                  {updatingWorkoutId &&
                  updatingWorkoutId.workoutId === id &&
                  updatingWorkoutId.plannedDate === plannedDate ? (
                    <CircularProgress variant="indeterminate" size="32px" />
                  ) : isDone ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-done.svg`}
                      alt=""
                    />
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-notdone.svg`}
                      alt=""
                      style={{
                        opacity: 0.15,
                      }}
                    />
                  )}
                </Tooltip>
              </div>
            </div>
          ) : (
            !isInMobileView && (
              <div
                className={classes.descriptionContainer}
                style={{
                  border: 0,
                }}
              ></div>
            )
          )}
        </div>
      </div>
    );
  }
}

DashboardTrainingWeekday.propTypes = {
  classes: PropTypes.object.isRequired,
  trainingDay: PropTypes.array,
  day: PropTypes.object.isRequired,
  plannedDate: PropTypes.string,
  updatingWorkoutId: PropTypes.string,
  isInMobileView: PropTypes.bool,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
};
export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withTranslation(["dashboard", "constants"])(
    withStyles(styles, { withTheme: true })(memo(DashboardTrainingWeekday)),
  ),
);
