import ActionTypes from "../constants/action-types";

const initialState = {
  loading: false,
  notificationList: [],
  notificationChangesList: [],
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.NOTIFICATION_UPDATE_CHANGES:
        const {
          cognito_user_sub,
          notificationType,
          notificationContent,
          changedContent,
        } = action.payload;
        let notificationChanges = [...state.notificationChangesList];
        const foundIndex = notificationChanges.findIndex(
          (change) =>
            change.cognito_user_sub === cognito_user_sub &&
            change.notificationType === notificationType &&
            JSON.stringify(change.notificationContent) ===
              JSON.stringify(notificationContent),
        );
        if (changedContent) {
          if (foundIndex === -1)
            notificationChanges = [
              ...notificationChanges,
              {
                cognito_user_sub,
                notificationType,
                notificationContent,
              },
            ];
        } else {
          if (foundIndex > -1) notificationChanges.splice(foundIndex, 1);
        }
        return {
          ...state,
          notificationChangesList: notificationChanges,
        };
      case ActionTypes.NOTIFICATION_CLEAR_CHANGES:
        return {
          ...state,
          notificationChangesList: [],
        };
      case ActionTypes.NOTIFICATION_LOAD_PENDING:
        return {
          ...state,
          loading: true,
          errorMessage: undefined,
        };
      case ActionTypes.NOTIFICATION_LOAD_FULFILLED:
        return {
          ...state,
          loading: false,
          notificationList: action.payload,
        };
      case ActionTypes.NOTIFICATION_LOAD_REJECTED:
        return {
          ...state,
          loading: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      case ActionTypes.NOTIFICATION_UPDATE_PENDING:
        return {
          ...state,
          saving: true,
          errorMessage: undefined,
        };
      case ActionTypes.NOTIFICATION_UPDATE_FULFILLED:
        const returnedNotification = action.payload;
        const { isRead, created_at } = returnedNotification;
        let newNotificationList = [...state.notificationList];
        if (isRead) {
          const foundIndex = newNotificationList.findIndex(
            (notification) =>
              notification.notificationType ===
                returnedNotification.notificationType &&
              notification.created_at === created_at,
          );
          newNotificationList[foundIndex].isRead = true;
        }
        return {
          ...state,
          saving: false,
          notificationList: newNotificationList,
        };
      case ActionTypes.NOTIFICATION_UPDATE_REJECTED:
        return {
          ...state,
          saving: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };
      default:
        return state;
    }
  } else {
    return state;
  }
}
