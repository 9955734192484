import bugsnag from "@bugsnag/js";

const bugsnagClient =
  process.env.NODE_ENV !== "test"
    ? bugsnag({
        apiKey: "0e54b3d424be4563ebabd389f1e0e64f",
        appVersion: "3.0.0",
        notifyReleaseStages: ["production"],
      })
    : {
        notify: () => {},
      };

export default bugsnagClient;
