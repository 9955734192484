import moment from "moment";
import { useEffect, useState } from "react";
import { dateFormat } from "../lib/date-helper";
import useUserInfo from "./useUserInfo";
import { useDispatch } from "react-redux";
import { updatePlanAddWorkout } from "../actions/user-plans-action";

const useAddWorkout = () => {
  const { userPlans } = useUserInfo();
  const dispatch = useDispatch();
  const [enableToSend, setDisabledToSend] = useState(true);
  const [workoutValues, setWorkoutValues] = useState({
    date: null,
    time: "",
    workoutType: "",
    description: "",
    durationHH: "",
    durationMM: "",
  });

  const handleInput = (event) => {
    const {
      target: { value, name },
    } = event;
    setWorkoutValues({ ...workoutValues, [name]: value });
  };

  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;
    setWorkoutValues({ ...workoutValues, [name]: value });
  };

  const handleDateChange = (dateMoment) => {
    setWorkoutValues({
      ...workoutValues,
      date: dateMoment.format(dateFormat),
      dateToSend: moment(dateMoment, dateFormat).toISOString(),
    });
  };

  const validate = (state) => {
    setDisabledToSend(false);

    if (!state.workoutType.trim()) {
      setDisabledToSend(true);
    }

    if (!state.durationHH || !state.durationMM) {
      setDisabledToSend(true);
    }

    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!state.date || !dateRegex.test(state.date)) {
      setDisabledToSend(true);
    }
  };

  const timeToSeconds = (timeStr) => {
    const [hours, minutes] = timeStr.split(":").map(Number);
    const totalSeconds = ((hours || 0) * 3600 + (minutes || 0) * 60) / 60;
    return totalSeconds.toString();
  };

  const handleAddWorkout = (workoutData) => {
    const { currentPlan } = userPlans;
    const { currentTraining } = currentPlan || {};

    if (!currentTraining) return false;

    if (!currentTraining.days) return false;

    const workout = {
      created_by_cognito_user_sub: currentPlan.cognito_user_sub,
      description: {
        en: workoutData.description,
        es: workoutData.description,
      },
      description_lower: `${workoutData.description.toLowerCase()} ${workoutData.description.toLowerCase()}`,
      difficultyLevel: "1",
      estimatedTime: timeToSeconds(
        `${workoutData.durationHH}:${workoutData.durationMM}`,
      ),
      id: new Date().getTime().toString(),
      plannedDate: workoutData.date,
      surface: "Flat",
      title: {
        en: "User created workout",
        es: "Entrenamiento creado por el usuario",
      },
      title_lower: "user created workout entrenamiento creado por el usuario",
      trainingPeriod: "Basic",
      trainingTime: "AllDay",
      updated_at: new Date().getTime(),
      workoutType: "UserCreated",
      userCreatedType: workoutData.workoutType,
    };

    dispatch(
      updatePlanAddWorkout(
        {
          cognito_user_sub: currentPlan.cognito_user_sub,
          workout,
        },
        currentTraining,
        0,
        false,
      ),
    );

    setWorkoutValues({
      date: null,
      time: "",
      workoutType: "",
      description: "",
      durationHH: "",
      durationMM: "",
    });
  };

  useEffect(() => {
    validate(workoutValues);
  }, [workoutValues]);

  return {
    workoutValues,
    enableToSend,
    setWorkoutValues,
    handleChange,
    handleDateChange,
    handleInput,
    handleAddWorkout,
  };
};

export default useAddWorkout;
