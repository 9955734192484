import React from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
} from "@material-ui/core";
import CheckoutPriceCardButton from "../../../components/common/v2/CheckoutPriceCardButton";
import history from "../../../lib/history-helper";
import ListItemWithIcon from "../../../components/common/v2/ListItemWithIcon";
import { useTranslation } from "react-i18next";
import useCheckout from "../../../hooks/useCheckout";
import SnackBarMessageDisplay from "../../../components/common/snack-bar-message-display";
import { ArrowBack } from "@material-ui/icons";
import useCheckoutProducts from "../../../hooks/useCheckoutProducts";
import CheckoutPriceCardButtonSkeleton from "../../../components/common/v2/CheckoutPriceCardButtonSkeleton";
import { makeStyles } from "@material-ui/core/styles";
import { IconCheck } from "@tabler/icons-react";

const useStyles = makeStyles({
  gridContainer: {
    padding: "64px",
  },
  backButton: {
    position: "absolute",
    top: "64px",
    left: "64px",
    color: "#4780AB",
  },
  backButtonText: {
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#4780AB",
  },
  title: {
    fontSize: "102px",
    fontWeight: 500,
    fontFamily: "Oswald",
    color: "#12151B",
    textTransform: "uppercase",
  },
  subtitle: {
    fontSize: "47px",
    fontWeight: 500,
    fontFamily: "Oswald",
    color: "#12151B",
    textTransform: "uppercase",
  },
  highlight: {
    color: "#F93C6E",
    fontWeight: 700,
  },
  listContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  checkItem: {
    marginLeft: "11px",
    fontSize: "22px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  imageContainer: {
    position: "relative",
    borderRadius: "4px",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    objectFit: "cover",
  },
  imageText: {
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#FEFFFF",
    position: "absolute",
    top: 20,
    right: 0,
    writingMode: "vertical-lr",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
  },
  pricesContainer: {
    flex: 1,
    maxWidth: "800px",
    display: "flex",
    flexDirection: "row",
  },
});
const PremiumCheckoutView = () => {
  const { t } = useTranslation("webcheckout");
  const classes = useStyles();
  const {
    handleCheckout,
    errorMessage,
    successMessage,
    handleHideMessage,
  } = useCheckout();
  const { checkoutItems, loading } = useCheckoutProducts(true);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <Grid className={classes.gridContainer} container spacing={2}>
        <Button
          variant="text"
          onClick={handleGoBack}
          startIcon={<ArrowBack />}
          className={classes.backButton}
        >
          <Typography className={classes.backButtonText}>
            {t("checkout.back")}
          </Typography>
        </Button>
        <Grid item xs={7}>
          <Typography className={classes.title}>
            {t("checkout.Premium.title")}
          </Typography>
          <Typography className={classes.subtitle}>
            {t("checkout.Premium.subtitle")}{" "}
            <span className={classes.highlight}>
              {t("checkout.Premium.subtitleHighlight")}
            </span>{" "}
            {t("checkout.Premium.subtitleDescription")}
          </Typography>
          <List className={classes.listContainer}>
            <ListItemWithIcon icon={<IconCheck size={24} />}>
              <Typography className={classes.checkItem}>
                {t("checkout.premium.list.access")}{" "}
                <span className={classes.highlight}>
                  {t("checkout.premium.list.accessHighlight")}
                </span>{" "}
                {t("checkout.premium.list.accessDescription")}
              </Typography>
            </ListItemWithIcon>
            <ListItemWithIcon icon={<IconCheck size={24} />}>
              <Typography className={classes.checkItem}>
                {t("checkout.premium.list.guidance")}{" "}
                <span className={classes.highlight}>
                  {t("checkout.premium.list.guidanceHighlight")}
                </span>{" "}
                {t("checkout.premium.list.guidanceDescription")}
              </Typography>
            </ListItemWithIcon>
            <ListItemWithIcon icon={<IconCheck size={24} />}>
              <Typography className={classes.checkItem}>
                {t("checkout.premium.list.questions")}
              </Typography>
            </ListItemWithIcon>
            <ListItemWithIcon icon={<IconCheck size={24} />}>
              <Typography className={classes.checkItem}>
                <span className={classes.highlight}>
                  {t("checkout.premium.list.personalHighlight")}
                </span>{" "}
                {t("checkout.premium.list.personal")}
              </Typography>
            </ListItemWithIcon>
            <ListItemWithIcon icon={<IconCheck size={24} />}>
              <Typography className={classes.checkItem}>
                {t("checkout.premium.list.sync")}
              </Typography>
            </ListItemWithIcon>
            <ListItem>
              <div className={classes.pricesContainer}>
                {loading ? (
                  <>
                    <CheckoutPriceCardButtonSkeleton />
                  </>
                ) : (
                  <CheckoutPriceCardButton
                    onClick={() => handleCheckout(checkoutItems[0].name, false)}
                    title={t("checkout.premium.item.monthlySubscription")}
                    price={`${checkoutItems[0].currency} ${checkoutItems[0].price}`}
                    premium
                  />
                )}
              </div>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={5}>
          <div className={classes.imageContainer}>
            <img
              src={`${process.env.PUBLIC_URL}/onboarding-img/fpuppi.webp`}
              alt="Francesco"
              loading="lazy"
              className={classes.image}
            />
            <Typography className={classes.imageText}>
              {t("checkout.premium.coach.description")}
            </Typography>
          </div>
        </Grid>
        <Box className={classes.pricesContainer} />
      </Grid>
      <SnackBarMessageDisplay
        onHideMessage={handleHideMessage}
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
    </>
  );
};

export default PremiumCheckoutView;
