import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import FinishedChallengeH from "./finished-challenge-h";
import {
  getChallenges,
  updateJoinedChallenges,
} from "../../actions/challenges-action";
import { getUserActivities } from "../../actions/user-plans-action";
import withActivities from "../../hoc/withActivities";

const styles = (theme) => ({
  progressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    margin: "auto",
  },
});

class FinishedChallenges extends Component {
  state = {
    units: "kms",
  };

  componentDidMount() {
    const { userPlans, auth, activities } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub, email } = attributes || {};

    this.props.dispatch(getChallenges(sub ? sub : ""));
    if (userPlans.loadingPlan === false) {
      this.getActivities();
    }

    if (activities.length > 0) {
      this.refreshJoinedChallenges();
    }

    if (typeof window !== "undefined") {
      this.setState({
        ...this.state,
        units: localStorage.getItem("units"),
      });

      window.addEventListener("storage", () => this.localStorageUpdated());
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { userPlans, activities, challenges } = this.props;

    if (!prevProps.userPlans.currentPlan && userPlans.currentPlan) {
      this.getActivities();
    }

    if (
      prevProps.challenges.loadingChallenges &&
      !challenges.loadingChallenges
    ) {
      this.refreshJoinedChallenges();
    }

    if (
      activities.length > 0 &&
      (!prevProps.activities || prevProps.activities.length === 0)
    ) {
      if (!challenges.loadingChallenges) {
        this.refreshJoinedChallenges();
      }
    }
  }

  localStorageUpdated() {
    this.setState({
      ...this.state,
      units: localStorage.getItem("units"),
    });
  }

  refreshJoinedChallenges = () => {
    const { userPlans, activities } = this.props;
    const { currentPlan } = userPlans;
    const { lastLogin } = currentPlan || {};

    if (currentPlan && currentPlan.cognito_user_sub) {
      this.props.dispatch(
        updateJoinedChallenges({
          cognito_user_sub: currentPlan.cognito_user_sub,
          lastLogin,
          activities,
        }),
      );
    }
  };

  componentWillUnmount() {
    clearTimeout(this.state.garminTimeout);

    this.setState({
      ...this.state,
      garminTimeout: null,
    });
  }

  getActivities = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    this.props.dispatch(getUserActivities(currentPlan));
  };

  render() {
    const { t, challenges, classes, onShareChallenge } = this.props;
    const {
      yourChallengeEntries,
      loadingChallenges,
      loadingYourChallengeEntries,
    } = challenges || {};

    const yourDoneChallenges = yourChallengeEntries
      ? yourChallengeEntries.filter(
          (e) => e.challengeData.end_date < new Date().getTime() && e.finished,
        )
      : null;

    return (
      <div>
        <div>
          {yourDoneChallenges && yourDoneChallenges.length ? (
            yourDoneChallenges.map((c, index) => (
              <div item xs={6} key={index}>
                <FinishedChallengeH
                  yourEntry={c}
                  onShareChallenge={onShareChallenge}
                  units={this.state.units}
                ></FinishedChallengeH>
              </div>
            ))
          ) : loadingChallenges || loadingYourChallengeEntries ? (
            <div className={classes.progressContainer}>
              <CircularProgress
                variant="indeterminate"
                color="primary"
                className={classes.loading}
                data-testid="LoadingUser"
              />
            </div>
          ) : (
            <div className={classes.noFinishedContainer}>
              <Typography variant={"body1"} className={classes.noActive}>
                {t("no finished challenges", { ns: "challenges" })}
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  }
}

FinishedChallenges.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  challenge: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
    challenges: store.challenges,
    auth: store.auth,
  };
})(
  withTranslation("challenges")(
    withWidth()(
      withStyles(styles, { withTheme: true })(
        withActivities(FinishedChallenges),
      ),
    ),
  ),
);
