import React from "react";
import { palette } from "../../theme/palette";

const WatchIcon = ({
  width = 24,
  height = 24,
  activated = false,
  fill = null,
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.45778 1.93748C5.48929 1.68749 5.70189 1.5 5.95386 1.5H11.5348C11.7867 1.5 11.9993 1.68749 12.0308 1.93748L12.3086 4.14141C12.7869 4.20294 13.2262 4.43502 13.5445 4.7943C13.8629 5.15358 14.0384 5.61549 14.0384 6.09375V7.40625H14.2002C14.4763 7.40625 14.7002 7.63011 14.7002 7.90625V10.8438C14.7002 11.1199 14.4763 11.3438 14.2002 11.3438H14.0384V12.6562C14.0384 13.1345 13.8629 13.5964 13.5445 13.9557C13.2262 14.315 12.7869 14.5471 12.3086 14.6086L12.0308 16.8125C11.9993 17.0625 11.7867 17.25 11.5348 17.25H5.95386C5.70189 17.25 5.48929 17.0625 5.45778 16.8125L5.18005 14.6086C4.70177 14.5471 4.26244 14.315 3.94411 13.9557C3.62577 13.5964 3.45021 13.1345 3.4502 12.6562V6.09375C3.45021 5.61549 3.62577 5.15358 3.94411 4.7943C4.26244 4.43502 4.70177 4.20294 5.18005 4.14141L5.45778 1.93748ZM6.51615 4.125H10.9725L10.8622 3.24997C10.8307 2.99998 10.6181 2.8125 10.3661 2.8125H7.12252C6.87056 2.8125 6.65796 2.99998 6.62645 3.24997L6.51615 4.125ZM5.43549 13.3125H12.0531C12.2286 13.3125 12.397 13.2434 12.5211 13.1203C12.6452 12.9972 12.7149 12.8303 12.7149 12.6562V6.09375C12.7149 5.9197 12.6452 5.75278 12.5211 5.62971C12.397 5.50664 12.2286 5.4375 12.0531 5.4375H5.43549C5.25998 5.4375 5.09166 5.50664 4.96755 5.62971C4.84345 5.75278 4.77372 5.9197 4.77372 6.09375V12.6562C4.77372 12.8303 4.84345 12.9972 4.96755 13.1203C5.09166 13.2434 5.25998 13.3125 5.43549 13.3125ZM6.51615 14.625L6.62645 15.5C6.65796 15.75 6.87056 15.9375 7.12252 15.9375H10.3661C10.6181 15.9375 10.8307 15.75 10.8622 15.5L10.9725 14.625H6.51615Z"
        fill={fill ? fill : activated ? palette.primary : palette.black}
      />
    </svg>
  </>
);
export default WatchIcon;
