import React from "react";

export const VertLabIcon = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.35417 10.1667C1.91667 10.1667 1.53993 10.0087 1.22396 9.69272C0.907986 9.37675 0.75 9.00001 0.75 8.56251C0.75 8.12501 0.907986 7.74827 1.22396 7.4323C1.53993 7.11633 1.91667 6.95834 2.35417 6.95834C2.79167 6.95834 3.1684 7.11633 3.48438 7.4323C3.80035 7.74827 3.95833 8.12501 3.95833 8.56251C3.95833 9.00001 3.80035 9.37675 3.48438 9.69272C3.1684 10.0087 2.79167 10.1667 2.35417 10.1667ZM9.64583 10.1667C9.20833 10.1667 8.8316 10.0087 8.51562 9.69272C8.19965 9.37675 8.04167 9.00001 8.04167 8.56251C8.04167 8.12501 8.19965 7.74827 8.51562 7.4323C8.8316 7.11633 9.20833 6.95834 9.64583 6.95834C10.0931 6.95834 10.4722 7.11633 10.7833 7.4323C11.0944 7.74827 11.25 8.12501 11.25 8.56251C11.25 9.00001 11.0944 9.37675 10.7833 9.69272C10.4722 10.0087 10.0931 10.1667 9.64583 10.1667ZM6 4.04168C5.5625 4.04168 5.18576 3.88369 4.86979 3.56772C4.55382 3.25175 4.39583 2.87501 4.39583 2.43751C4.39583 2.00001 4.55382 1.62327 4.86979 1.3073C5.18576 0.99133 5.5625 0.833344 6 0.833344C6.4375 0.833344 6.81424 0.99133 7.13021 1.3073C7.44618 1.62327 7.60417 2.00001 7.60417 2.43751C7.60417 2.87501 7.44618 3.25175 7.13021 3.56772C6.81424 3.88369 6.4375 4.04168 6 4.04168Z"
      fill="black"
    />
  </svg>
);
