import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Slide,
  List,
  Switch,
  ListItem,
  ListItemIcon,
  Collapse,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import ToolbarReturnBack from "./ToolbarReturnBack";
import useCreatePost from "../../../hooks/useCreatePost";
import {
  DataIcon,
  ElevationIcon,
  PictureIcon,
  RunningIcon,
  SpeedIcon,
  OverlayIcon,
} from "../../icons";
import { makeStyles } from "@material-ui/core/styles";
import PostCanvas from "./PostCanvas";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  dialog: {
    display: "flex",
    flexDirection: "column",
  },
  headerBox: {
    flex: "0 0 auto",
  },
  footerBox: {
    flex: "0 0 auto",
    padding: "16px",
  },
  contentBox: {
    padding: "16px",
    flex: "1 1 auto",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
  list: {
    width: "100%",
  },
  listitem: {
    paddingLeft: "32px",
  },
  items: {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  subItems: {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#12151B",
  },
  checkbox: {
    color: "#0B2B5C",
    "&.Mui-checked": {
      color: "#0B2B5C",
    },
  },
  button: {
    fontFamily: "karla",
    fontWeight: 700,
    fontSize: "18px",
    backgroundColor: "#0B2B5C",
    color: "#FEFFFF",
    height: "48px",
    width: "100%",
  },
});

const CommunityActivityShareDialog = ({
  handleOpenDialog,
  handleCloseDialog,
  handleContinueDialog,
  isFromShare = false,
}) => {
  const classes = useStyles();
  const { handleResetImage, handleSetImage } = useCreatePost();
  const { t } = useTranslation("community");
  const [checked, setChecked] = useState([]);
  const [check, setCheck] = useState([1]);
  const [generatedImage, setGeneratedImage] = useState(null);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleContinueButton = () => {
    if (generatedImage) {
      handleSetImage(generatedImage);
    }

    if (isFromShare) {
      handleContinueDialog();
    } else {
      handleCloseDialog(true);
    }
  };

  const handleCheckBox = (value) => () => {
    const currentIndex = check.indexOf(value);
    const newChecked = [...check];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheck(newChecked);
  };

  const handleGoBack = () => {
    handleResetImage();
    handleCloseDialog(false);
  };

  return (
    <Dialog
      fullScreen
      open={handleOpenDialog}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
      className={classes.dialog}
      disableRestoreFocus
    >
      <Box className={classes.headerBox}>
        <ToolbarReturnBack
          goBack={handleGoBack}
          title={t("community.activityGen.title")}
        />
      </Box>
      <Box className={classes.contentBox}>
        <PostCanvas
          overlay={checked.indexOf("overlay") !== -1}
          squareFormat={checked.indexOf("format") !== -1}
          data={checked.indexOf("data") !== -1}
          activityData={check.indexOf("activity") !== -1}
          elevationData={check.indexOf("elevation") !== -1}
          speedData={check.indexOf("speed") !== -1}
          setGeneratedImage={setGeneratedImage}
        />
        <List className={classes.list} component="nav">
          <ListItem>
            <ListItemIcon>
              <OverlayIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography className={classes.items}>
                  {t("community.activityGen.overlay")}
                </Typography>
              }
            />
            <Switch
              data-testid="toggle1"
              id="toggle1"
              disabled={generatedImage == null}
              edge="end"
              onChange={handleToggle("overlay")}
              checked={checked.indexOf("overlay") !== -1}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DataIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography className={classes.items}>
                  {t("community.activityGen.stats")}
                </Typography>
              }
            />
            <Switch
              data-testid="toggle2"
              id="toggle2"
              disabled={generatedImage == null}
              edge="end"
              onChange={handleToggle("data")}
              checked={checked.indexOf("data") !== -1}
            />
          </ListItem>
          <Collapse
            in={checked.indexOf("data") !== -1}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem className={classes.listitem}>
                <ListItemIcon>
                  <RunningIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={classes.subItems}>
                      {t("community.activityGen.activity")}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    data-testid="check1"
                    id="check1"
                    edge="end"
                    onChange={handleCheckBox("activity")}
                    checked={check.indexOf("activity") !== -1}
                    className={classes.checkbox}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem className={classes.listitem}>
                <ListItemIcon>
                  <ElevationIcon color="black" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={classes.subItems}>
                      {t("community.activityGen.elevation")}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    data-testid="check2"
                    id="check2"
                    edge="end"
                    onChange={handleCheckBox("elevation")}
                    checked={check.indexOf("elevation") !== -1}
                    className={classes.checkbox}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem className={classes.listitem}>
                <ListItemIcon>
                  <SpeedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={classes.subItems}>
                      {t("community.activityGen.speed")}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    data-testid="check3"
                    id="check3"
                    edge="end"
                    onChange={handleCheckBox("speed")}
                    checked={check.indexOf("speed") !== -1}
                    className={classes.checkbox}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Collapse>
          <ListItem>
            <ListItemIcon>
              <PictureIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography component={"span"} className={classes.items}>
                  {t("community.activityGen.imageFormat")}
                </Typography>
              }
            />
            <Switch
              data-testid="toggle3"
              id="toggle3"
              disabled={generatedImage == null}
              edge="end"
              onChange={handleToggle("format")}
              checked={checked.indexOf("format") !== -1}
            />
          </ListItem>
        </List>
      </Box>
      <Box className={classes.footerBox}>
        <Button
          data-testid="continue_button"
          id="continue_button"
          disabled={generatedImage == null}
          className={classes.button}
          variant="contained"
          onClick={handleContinueButton}
        >
          {t("community.activityGen.continueButton")}
        </Button>
      </Box>
    </Dialog>
  );
};

export default CommunityActivityShareDialog;
