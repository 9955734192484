import React, { Component, memo } from "react";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  withMobileDialog,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  ClickAwayListener,
  Grow,
  Popper,
  CircularProgress,
  Avatar,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import moment from "moment";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import { dateFormat } from "../../lib/date-helper";
import { animateScroll } from "react-scroll";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Capacitor } from "@capacitor/core";
import Weekdays from "../../constants/weekdays";
import "moment/min/locales";
import EstimatedTimes from "../../constants/estimated-times";
import WorkoutTypes from "../../constants/workout-types";
import MenuList from "../common/menu-list";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Storage } from "aws-amplify";
import RouterLink from "../common/router-link";
import linkifyStr from "linkify-string";
import classNames from "classnames";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import { connect } from "react-redux";
import ClickNHold from "react-click-n-hold";
import { getCustomLevel } from "../../lib/plan-helper";
import { useAnalyticsEvent } from "../../hooks/useAnalyticsEvent";
import { logEvent, setEventsMetadata } from "../../lib/events-helper";

const styles = (theme) => ({
  container: {
    background: "#FFFFFF",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      borderTopLeftRadius: 18,
      borderTopRightRadius: 18,
      position: "initial",
    },
  },
  textFieldContainer: {
    padding: 10,
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "calc(100% - 20px)",
  },
  textField: {
    fontFamily: "Avenir",
    background: "#FFFFFF",
  },
  coachMessageContainer: {
    marginBottom: 24,
    maxWidth: 310,
    width: "100%",
  },
  yourMessageContainer: {
    marginLeft: "auto",
    marginBottom: 24,
    maxWidth: 310,
    width: "100%",
  },
  coachMessage: {
    background: "#FFECB3",
    padding: 15,
    borderTopLeftRadius: "0px !important",
    borderTopRightRadius: "10px !important",
    borderBottomRightRadius: "10px !important",
    borderBottomLeftRadius: "10px !important",
    position: "relative",
  },
  yourMessage: {
    background: "#E7F3FC",
    padding: 15,
    borderRadius: 5,
    textAlign: "right",
    position: "relative",
    borderTopLeftRadius: "10px !important",
    borderTopRightRadius: "0px !important",
    borderBottomRightRadius: "10px !important",
    borderBottomLeftRadius: "10px !important",
  },
  sender: {
    fontWeight: 500,
    fontSize: 14,
    color: "#202020",
  },
  chatTitle: {
    fontWeight: 500,
    fontSize: 12,
    color: "#202020",
    textAlign: "center",
  },
  text: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#3F3F3F",
    wordWrap: "break-word",

    "& a": {
      wordWrap: "break-word",
    },
  },
  textLink: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#3F3F3F",
    textDecoration: "underline",
  },
  date: {
    fontWeight: "normal",
    fontSize: 12,
    color: "#9E9E9E",
    textAlign: "left",
    marginTop: "10px",
  },
  messages: {
    left: 10,
    width: "calc(100% - 23px)",
    height: "calc(100% - 115px)",
    bottom: 110,
    overflowY: "scroll",
    overflowX: "hidden",
    position: "absolute",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 150px)",
      bottom: 106,
    },
  },
  closeChatContainer: {
    position: "absolute",
    left: 0,
    bottom: 9,
    width: "100%",
    textAlign: "center",
    color: "#FF6327",
  },
  closeChat: {
    color: "#FF6327",
  },
  notext: {
    color: "#3F3F3F",
    fontSize: 12,
    fontWeight: "normal",
    textAlign: "center",
    marginTop: 10,
    fontStyle: "italic",
  },
  badge: {
    margin: "-4px 10px 0",
  },
  actionIcon: {
    cursor: "pointer",
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    cursor: "pointer",
  },
  workoutContainer: {
    display: "flex",
    padding: "10px",
    justifyContent: "space-between",
    borderLeft: "#FF6327 8px solid",
  },
  workoutContainerMessage: {
    display: "flex",
    padding: "10px",
    justifyContent: "space-between",
    borderRadius: "0 0 5px 5px",
    minWidth: 200,
  },
  workoutTitle: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: "16px",
    color: "#EFF3F5",
  },
  workoutSubText: {
    display: "flex",
    alignItems: "center",
  },
  workoutTime: {
    fontFamily: "Avenir",
    fontWeight: "normal",
    fontSize: 14,
    color: "#EFF3F5",
    marginRight: 10,
  },
  workoutDate: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 14,
    color: "#EFF3F5",
  },

  containerMenu: {
    marginTop: -15,
    marginRight: -30,
    width: "fit-content",
    marginLeft: "auto",
  },
  yourContainerMenu: {
    marginTop: -15,
    width: "fit-content",
    marginLeft: -30,
  },
  paper: {
    background: "#FCFCFC",
    borderRadius: 8,
    boxShadow: "5px 3px 10px rgb(33 81 209 / 10%)",
    padding: "5px 0",
    width: "fit-content !important",
  },
  buttonMenu: {
    padding: "3px",
    fontWeight: "normal",
    boxSizing: "border-box",
  },
  menuItem: {
    padding: "6px 8px",
    fontWeight: "normal",
    alignItems: "center",
  },
  menuItemLabel: {
    textTransform: "initial",
    textAlign: "left",
    justifyContent: "left",
  },
  popper: {
    zIndex: 1,
    top: "20px !important",
    left: "initial !important",
    right: 0,
    transform: "translate(0, 0) !important",
  },
  popperLeft: {
    zIndex: 1,
    left: "0px !important",
    transform: "translate(0, 0) !important",
    top: "20px !important",
  },
  replyToContainer: {
    borderLeft: "8px solid",
    padding: 15,
  },
  replyToContainerMessage: {
    borderLeft: "8px solid",
    padding: 15,
    opacity: "0.5",
  },
  loading: {
    textAlign: "center",
    marginBottom: 24,
  },
  flexContainer: {
    display: "flex",
    padding: "0 20px",
  },
  avatarCoach: {
    marginRight: 5,
  },
  avatarAthlete: {
    marginLeft: 5,
  },
  scrollToBottom: {
    position: "absolute",
    right: 0,
    top: "40%",
    zIndex: 100,
    background: "#FFFFFF",
    border: "1px solid #EFF3F5",
    borderRadius: "5px 0px 0px 5px",
    padding: "5px 5px 1px",
    cursor: "pointer",
  },
  workoutNoRadius: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  workoutRadius: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  replyNoRadius: {
    borderTopLeftRadius: "0px !important",
    borderTopRightRadius: "0px !important",
  },
  replyRadius: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  optionIcon: {
    width: 16,
    marginRight: 6,
  },
  iconParent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
  },
  emojiBtn: {
    background: "#EFEFEF",
    borderRadius: "62px",
    width: "35px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginTop: "7px",
    transition: "all .6s",

    "&:not(:last-child)": {
      marginRight: "5px",
    },

    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
      transition: "all .6s",
    },
  },
  emojiSuggestion: {
    background: "#EFEFEF",
    borderRadius: "62px",
    padding: "4px 8px",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    marginTop: "7px",
  },
  emojiChild: {
    marginRight: "8px",
    cursor: "pointer",
  },
  chatMsgParent: {
    position: "relative",
  },
  picker: {
    position: "absolute",
    top: "-55px",
    right: "0px",
    zIndex: 10405,
  },
  reactionsCount: {
    fontSize: "11px",
    marginLeft: "3px",
  },
  wrapperEmoji: {
    marginRight: "5px",
  },
});

const suggestedEmojies = [
  "1f44d",
  "1f604",
  "2764-fe0f",
  "1f44c",
  "1f64f",
  "1f600",
];

class TrainingWeekdayDetailsChat extends Component {
  state = {
    currentComment: null,
    intialCommentToEdit: null,
    deleteDialogOpen: false,
    commentToDelete: null,
    commentToEdit: null,
    addWorkout: true,
    open: null,
    imgUrls: {},
    showGoBottom: false,
    currentIndex: -1,
    emoji: null,
    reaction: false,
    suggestion: false,
  };

  handleOpenMenu = (id) => {
    if (this.state.open) return this.setState({ ...this.state, open: false });
    this.setState({
      ...this.state,
      open: id,
    });
  };

  handleCloseOptions = () => {
    this.setState({
      ...this.state,
      open: false,
    });
  };

  handleCloseMenu = (message) => {
    if (message) {
      this.setState({
        ...this.state,
        replyTo: {
          ...message,
          text:
            message.text.length > 200
              ? `${message.text.substring(0, 200)}...`
              : message.text,
        },
        addWorkout: false,
        open: false,
      });
    } else {
      this.setState({
        ...this.state,
        open: false,
      });
    }
  };

  handleRemoveReplyTo = () => {
    this.setState({
      ...this.state,
      replyTo: null,
    });
  };

  componentDidMount() {
    const comment = localStorage.getItem("comment");
    if (comment)
      this.setState({
        ...this.state,
        currentComment: { ...JSON.parse(comment) },
      });

    animateScroll.scrollToBottom({
      containerId: "chat",
      duration: 0,
    });
    this.checkImages();
  }

  handleTextChange = (event) => {
    const { type } = this.props;
    const { target } = event;
    if (target) {
      const { value } = target;

      const comment = {
        text: value,
        date: moment().format(dateFormat),
        dateTime: moment().unix(),
        type,
      };

      localStorage.setItem("comment", JSON.stringify(comment));

      this.setState({
        ...this.state,
        currentComment: {
          ...comment,
        },
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { comments } = this.props;
    if (
      prevProps.comments &&
      comments &&
      comments.length > prevProps.comments.length
    ) {
      const comment = localStorage.getItem("comment");
      if (comment)
        this.setState({
          ...this.state,
          currentComment: { ...JSON.parse(comment) },
        });

      animateScroll.scrollToBottom({
        containerId: "chat",
        duration: 0,
      });
      this.checkImages();
    }
    if (this.props.showAll && !prevProps.showAll) {
      animateScroll.scrollToBottom({
        containerId: "chat",
        duration: 0,
      });
    }
  }

  checkImages = () => {
    const { comments } = this.props;
    let images = [];
    comments.forEach((element) => {
      if (element.senderImage) {
        images.push(element.senderImage);
      }
    });
    this.getUrls(images);
  };

  getUrls = async (images) => {
    if (images) {
      const imgUrls = {};
      for (let i = 0; i < images.length; i++) {
        if (!imgUrls[images[i]]) {
          try {
            const result = await Storage.get(images[i], { level: "public" });
            imgUrls[images[i]] = result;
          } catch (exp) {
            throw exp;
          }
        }
      }
      this.setState({
        ...this.state,
        imgUrls,
      });
    }
  };

  handleSend = () => {
    if (this.state.currentComment) {
      let comment = this.state.currentComment;
      if (this.state.addWorkout) {
        const { workout } = this.props;
        comment = { ...comment, workout };
      }
      if (this.state.replyTo) {
        comment = { ...comment, replyTo: this.state.replyTo };
      }
      this.props.handleSaveComment(comment);

      setEventsMetadata({
        custId: this.props.userPlans.currentPlan.cognito_user_sub,
      });
      logEvent("send_chat_message");

      this.setState({
        ...this.state,
        currentComment: null,
        addWorkout: false,
        replyTo: false,
      });

      setTimeout(
        () =>
          animateScroll.scrollToBottom({
            containerId: "chat",
            duration: 0,
          }),
        100,
      );
    }
  };

  handleEditComment = async (comment) => {
    const text = comment.text.trim();
    if (text && text !== "") {
      this.props.handleEditComment(comment);
      this.setState({
        ...this.state,
        commentToEdit: null,
        currentIndex: -1,
      });
      return;
    }

    await this.handleDeleteComment(comment);
    this.setState({
      ...this.state,
      commentToEdit: null,
      currentIndex: -1,
    });
  };

  handleDeleteComment = (comment) => {
    this.props.handleDeleteComment(comment);
  };

  openDeleteDialog = (comment) => {
    this.setState({
      ...this.state,
      deleteDialogOpen: true,
      commentToDelete: comment,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      deleteDialogOpen: false,
      commentToDelete: null,
    });
  };

  openEdit = (comment, index) => {
    this.setState({
      ...this.state,
      commentToEdit: comment,
      intialCommentToEdit: JSON.parse(JSON.stringify(comment)),
      currentIndex: index,
      open: false,
      suggestion: false,
      reaction: false,
    });
  };

  clickNHold = (emoji, comment) => {
    const copy = [...comment.reaction];
    const filtered = copy.filter((reaction) => reaction !== emoji);

    this.props.handleEditComment({
      ...comment,
      reaction: filtered,
    });
  };

  closeEdit = (refresh = false) => {
    if (refresh) {
      this.state.commentToEdit.text = this.state.intialCommentToEdit.text;
    }
    this.setState({
      ...this.state,
      commentToEdit: null,
      intialCommentToEdit: null,
      currentIndex: -1,
    });
  };

  handleEmoji = (emoji, comment) => {
    const { unified } = emoji;
    const previousReactions = comment.reaction ? [...comment.reaction] : [];
    this.props.handleEditComment({
      ...comment,
      reaction: [...previousReactions, unified],
    });

    this.setState({ ...this.state, currentIndex: -1, reaction: false });
  };

  processReactions = (reactions) => {
    const reactionsList = [];
    const count = {};

    reactions.forEach((element) => {
      count[element] = (count[element] || 0) + 1;
    });

    for (const prop in count) {
      const index = reactions.findIndex((elem) => elem == prop);
      reactionsList[index] = {
        emoji: `${reactions[index]}`,
        count: `${count[prop]}`,
      };
    }

    return reactionsList || [];
  };

  sumEmoji = (emoji, comment) => {
    const previousReactions = comment.reaction ? [...comment.reaction] : [];
    this.props.handleEditComment({
      ...comment,
      reaction: [...previousReactions, emoji],
    });

    if (this.state.suggestion)
      this.setState({ ...this.state, suggestion: false, currentIndex: -1 });
  };

  handleEditTextChange = (event) => {
    const { target } = event;
    if (target) {
      const { value } = target;
      const commentToEdit = this.state.commentToEdit;
      commentToEdit.text = value;
      this.setState({
        ...this.state,
        commentToEdit,
      });
    }
  };

  addReaction = (index) => {
    this.setState({ ...this.state, currentIndex: index, suggestion: true });
  };

  handleCloseEmoji = () => {
    const { reaction, suggestion } = this.state;
    if (suggestion)
      this.setState({ ...this.state, suggestion: false, currentIndex: -1 });
    if (reaction)
      this.setState({ ...this.state, reaction: false, currentIndex: -1 });
  };

  handleRemoveWorkout = () => {
    this.setState({
      ...this.state,
      addWorkout: false,
    });
  };

  handleOpenEmoji = (index) => {
    this.setState({
      ...this.state,
      suggestion: false,
      reaction: true,
      currentIndex: index,
    });
  };

  render() {
    const {
      classes,
      t,
      width,
      comments,
      comment,
      coachComment,
      coachFeedback,
      type,
      isCoach,
      showAll,
      workout,
      userName,
      loading,
      loadingOldComments,
      canLoadMoreComments,
      onLoadOld,
      isExplore,
      i18n,
      level,
      athletes,
      userPlans,
    } = this.props;

    const {
      currentComment,
      commentToDelete,
      deleteDialogOpen,
      commentToEdit,
      addWorkout,
      open,
      replyTo,
      imgUrls,
      showGoBottom,
      currentIndex,
      reaction,
      suggestion,
    } = this.state;
    const isInMobileView = width === "xs" || width === "sm";

    let customLevel;
    const { selectedAthlete = {} } = athletes || {};
    if (Object.keys(selectedAthlete).length > 0) {
      const { selectedWorkout = {}, currentTraining } = selectedAthlete || {};
      const { chainedPlans } = currentTraining || {};
      customLevel = getCustomLevel(selectedWorkout.plannedDate, chainedPlans);
    } else {
      const { currentPlan } = userPlans || {};
      const { currentTraining } = currentPlan || {};
      const { chainedPlans } = currentTraining || {};
      if (workout)
        customLevel = getCustomLevel(workout[0].plannedDate, chainedPlans);
    }

    let margin = 115;
    let height = `calc(100% - ${margin}px)`;
    if (isInMobileView) {
      margin = workout && addWorkout ? 130 : replyTo ? 140 : 55;
      height = `calc(100% - ${
        workout && addWorkout ? 165 : replyTo ? 175 : 90
      }px)`;
    } else {
      margin = workout && addWorkout ? 130 : replyTo ? 160 : 65;
      height = `calc(100% - ${margin + 35}px)`;
    }

    const sortedComments = comments.sort((a, b) => a.dateTime - b.dateTime);

    const { language } = i18n;

    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    return (
      <div
        className={classes.container}
        style={{
          height:
            isInMobileView && showAll
              ? Capacitor.isNativePlatform() &&
                Capacitor.getPlatform() === "ios"
                ? "calc(80vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))"
                : "80vh"
              : isInMobileView && !showAll
              ? "0px"
              : "100%",
          display:
            isInMobileView && showAll
              ? "block"
              : isInMobileView && !showAll
              ? "none"
              : "block",
        }}
      >
        {showGoBottom ? (
          <div
            className={classes.scrollToBottom}
            onClick={() =>
              animateScroll.scrollToBottom({
                containerId: "chat",
                duration: 0,
              })
            }
          >
            <img src={`${process.env.PUBLIC_URL}/icon-scroll.svg`} alt="" />
          </div>
        ) : (
          ""
        )}
        {(isInMobileView && showAll) || type === 0 || !isInMobileView ? (
          <>
            <div
              className={classes.messages}
              id="chat"
              style={{
                height: height,
                bottom: margin,
              }}
              onScroll={() => {
                if (
                  !showGoBottom &&
                  document.getElementById("chat").scrollTop <
                    document.getElementById("chat").scrollHeight -
                      document.getElementById("chat").offsetHeight -
                      100
                ) {
                  this.setState({ ...this.state, showGoBottom: true });
                } else if (
                  document.getElementById("chat").scrollTop >=
                  document.getElementById("chat").scrollHeight -
                    document.getElementById("chat").offsetHeight -
                    100
                ) {
                  this.setState({ ...this.state, showGoBottom: false });
                }
                if (
                  document.getElementById("chat").scrollTop < 10 &&
                  !loadingOldComments &&
                  canLoadMoreComments &&
                  sortedComments &&
                  sortedComments.length &&
                  onLoadOld
                ) {
                  onLoadOld(sortedComments[0].dateTime);
                }
              }}
            >
              {loadingOldComments ? (
                <div className={classes.loading}>
                  <CircularProgress variant="indeterminate" />
                </div>
              ) : (
                ""
              )}

              {coachComment ? (
                <div className={classes.coachMessage}>
                  <Typography variant="h6" className={classes.sender}>
                    {t("Coach")}
                  </Typography>
                  <Typography variant="body1" className={classes.text}>
                    {coachComment}
                  </Typography>
                </div>
              ) : (
                ""
              )}

              {comment ? (
                <div className={classes.yourMessage}>
                  <Typography variant="body1" className={classes.text}>
                    <pre style={{ fontFamily: "inherit" }}>{comment}</pre>
                  </Typography>
                </div>
              ) : (
                ""
              )}

              {coachFeedback ? (
                <div className={classes.coachMessage}>
                  <Typography variant="h6" className={classes.sender}>
                    {t("Coach")}
                  </Typography>
                  <Typography variant="body1" className={classes.text}>
                    {coachFeedback}
                  </Typography>
                </div>
              ) : (
                ""
              )}

              {sortedComments.map((message, i) => (
                <div className={classes.flexContainer} key={i}>
                  {message.type === 0 ? (
                    <Avatar
                      src={
                        message.senderImage
                          ? imgUrls[message.senderImage]
                          : `${process.env.PUBLIC_URL}/avatar-empty.png`
                      }
                      className={classes.avatarCoach}
                    />
                  ) : (
                    ""
                  )}
                  <div
                    className={
                      message.type === 0
                        ? classes.coachMessageContainer
                        : classes.yourMessageContainer
                    }
                  >
                    {message.replyTo ? (
                      <div>
                        <div
                          style={{
                            backgroundColor:
                              message.replyTo.type === 0
                                ? "#FFECB3"
                                : "#E7F3FC",
                            borderColor:
                              message.replyTo.type === 0
                                ? "#FFD600"
                                : "rgb(28 99 155)",
                          }}
                          className={classes.replyToContainerMessage}
                        >
                          {message.replyTo.type === 0 ? (
                            <Typography variant="h6" className={classes.sender}>
                              {message.replyTo.senderName
                                ? message.replyTo.senderName
                                : t("Coach")}
                            </Typography>
                          ) : (
                            <Typography variant="h6" className={classes.sender}>
                              {userName}
                            </Typography>
                          )}

                          <Typography variant="body1" className={classes.text}>
                            {message.replyTo.text}
                          </Typography>

                          <Typography variant="body1" className={classes.date}>
                            {message.replyTo.date}
                          </Typography>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className={classes.chatMsgParent}>
                      {currentIndex === i && reaction && (
                        <ClickAwayListener onClickAway={this.handleCloseEmoji}>
                          <div className={classes.picker}>
                            <EmojiPicker
                              width="fit-content"
                              previewConfig={{ showPreview: false }}
                              height="400px"
                              onEmojiClick={(emoji) =>
                                this.handleEmoji(emoji, message)
                              }
                            />
                          </div>
                        </ClickAwayListener>
                      )}

                      <div
                        className={classNames(
                          message.type === 0
                            ? classes.coachMessage
                            : classes.yourMessage,
                          message.workout
                            ? classes.workoutNoRadius
                            : classes.workoutRadius,
                          message.replyTo
                            ? classes.replyNoRadius
                            : classes.replyRadius,
                        )}
                      >
                        <div
                          className={
                            message.type === 0
                              ? classes.containerMenu
                              : classes.yourContainerMenu
                          }
                        >
                          {!message.isWelcomeMessage ? (
                            <Button
                              className={classes.buttonMenu}
                              buttonRef={(node) => {
                                this[message.dateTime] = node;
                              }}
                              aria-owns={
                                open === message.dateTime
                                  ? "menu-list-grow"
                                  : undefined
                              }
                              aria-haspopup="true"
                              onClick={() =>
                                this.handleOpenMenu(message.dateTime)
                              }
                            >
                              <MoreHorizIcon />
                            </Button>
                          ) : (
                            ""
                          )}
                          <Popper
                            open={open === message.dateTime}
                            anchorEl={this[message.dateTime]}
                            transition
                            disablePortal
                            className={
                              message.type === 0
                                ? classes.popper
                                : classes.popperLeft
                            }
                            placement={
                              message.type === 0 ? "left-start" : "right-start"
                            }
                          >
                            {({ TransitionProps }) => (
                              <Grow {...TransitionProps} id="menu-list-grow">
                                <ClickAwayListener
                                  onClickAway={this.handleCloseMenu.bind(
                                    this,
                                    null,
                                  )}
                                >
                                  <div className={classes.paper}>
                                    <MenuList>
                                      <Button
                                        variant="text"
                                        classes={{
                                          root: classes.menuItem,
                                          label: classes.menuItemLabel,
                                        }}
                                        onClick={() =>
                                          this.handleCloseMenu(message)
                                        }
                                      >
                                        <img
                                          src={`${process.env.PUBLIC_URL}/reply-icon.svg`}
                                          className={classes.optionIcon}
                                        />
                                        {t("Reply to this message")}
                                      </Button>

                                      {message.type != 0 && !isCoach && (
                                        <Button
                                          variant="text"
                                          classes={{
                                            root: classes.menuItem,
                                            label: classes.menuItemLabel,
                                          }}
                                          onClick={() =>
                                            this.openEdit(message, i)
                                          }
                                        >
                                          <EditIcon
                                            className={classes.optionIcon}
                                          />
                                          {t("Edit this message")}
                                        </Button>
                                      )}
                                    </MenuList>
                                  </div>
                                </ClickAwayListener>
                              </Grow>
                            )}
                          </Popper>
                        </div>
                        {message.type === 0 ? (
                          <Typography variant="h6" className={classes.sender}>
                            {message.senderName
                              ? message.senderName
                              : t("Coach")}
                          </Typography>
                        ) : (
                          <Typography variant="h6" className={classes.sender}>
                            {userName}
                          </Typography>
                        )}
                        {commentToEdit &&
                        commentToEdit.dateTime === message.dateTime ? (
                          <TextField
                            defaultValue={this.state.commentToEdit.text}
                            fullWidth
                            id="comment"
                            name="comment"
                            margin="normal"
                            variant="outlined"
                            label="Comment"
                            multiline
                            inputProps={{ maxLength: 1000 }}
                            onChange={this.handleEditTextChange}
                            InputLabelProps={{
                              classes: { root: classes.smallerFontSize },
                            }}
                          />
                        ) : (
                          <>
                            <Typography
                              variant="body1"
                              className={classes.text}
                              dangerouslySetInnerHTML={{
                                __html: message.isPlanMessage
                                  ? t(message.text, {
                                      ...message.payload,
                                      fillName:
                                        message.payload &&
                                        message.payload.leadUpPlan
                                          ? message.payload.leadUpPlan[
                                              languageDetector
                                            ]
                                            ? message.payload.leadUpPlan[
                                                languageDetector
                                              ]
                                            : message.payload.leadUpPlan["en"]
                                          : "",
                                      fillName1:
                                        message.payload &&
                                        message.payload.leadUpPlan1
                                          ? message.payload.leadUpPlan1[
                                              languageDetector
                                            ]
                                            ? message.payload.leadUpPlan1[
                                                languageDetector
                                              ]
                                            : message.payload.leadUpPlan1["en"]
                                          : "",
                                      fillName2:
                                        message.payload &&
                                        message.payload.leadUpPlan2
                                          ? message.payload.leadUpPlan2[
                                              languageDetector
                                            ]
                                            ? message.payload.leadUpPlan2[
                                                languageDetector
                                              ]
                                            : message.payload.leadUpPlan2["en"]
                                          : "",
                                      planName:
                                        message.payload && message.payload.plan
                                          ? message.payload.plan[
                                              languageDetector
                                            ]
                                            ? message.payload.plan[
                                                languageDetector
                                              ]
                                            : message.payload.plan["en"]
                                          : "",
                                      name: userName,
                                    })
                                  : message.isWelcomeMessage
                                  ? isExplore
                                    ? t("welcome message", { name: userName })
                                    : t("welcome message free", {
                                        name: userName,
                                      })
                                  : message.isWelcomeMessage
                                  ? isExplore
                                    ? t("welcome message", { name: userName })
                                    : t("welcome message free", {
                                        name: userName,
                                      })
                                  : message.isCancellationMessage
                                  ? t("cancellation message", {
                                      name: userName,
                                    })
                                  : linkifyStr(message.text, {
                                      target: {
                                        url: "_blank",
                                      },
                                    }),
                              }}
                            ></Typography>

                            {message.isWelcomeMessage && !isExplore ? (
                              <RouterLink to="/subscription/explore">
                                <Typography
                                  variant="body1"
                                  className={classes.textLink}
                                >
                                  {t("welcome message free action")}
                                </Typography>
                              </RouterLink>
                            ) : (
                              ""
                            )}
                          </>
                        )}

                        <Typography variant="body1" className={classes.date}>
                          {message.date}
                        </Typography>

                        {isCoach && message.type === 0 ? (
                          !commentToEdit ? (
                            <div className={classes.actions}>
                              <EditIcon
                                onClick={() => this.openEdit(message)}
                                className={classes.actionIcon}
                                fontSize="small"
                              />{" "}
                              <DeleteIcon
                                onClick={() => this.openDeleteDialog(message)}
                                className={classes.actionIcon}
                                fontSize="small"
                              />
                            </div>
                          ) : (
                            <div className={classes.actions}>
                              <CheckIcon
                                onClick={() =>
                                  this.handleEditComment(commentToEdit)
                                }
                                className={classes.actionIcon}
                                fontSize="small"
                              />{" "}
                              <CloseIcon
                                onClick={() => this.closeEdit(true)}
                                className={classes.actionIcon}
                                fontSize="small"
                              />
                            </div>
                          )
                        ) : (
                          ""
                        )}

                        {!isCoach &&
                          currentIndex == i &&
                          !suggestion &&
                          !reaction && (
                            <div className={classes.actions}>
                              <CheckIcon
                                onClick={() =>
                                  this.handleEditComment(commentToEdit)
                                }
                                className={classes.actionIcon}
                                fontSize="small"
                              />{" "}
                              <CloseIcon
                                onClick={() => this.closeEdit(true)}
                                className={classes.actionIcon}
                                fontSize="small"
                              />
                            </div>
                          )}
                      </div>

                      {currentIndex !== i && (
                        <div className={classes.iconParent}>
                          {Array.isArray(message.reaction)
                            ? this.processReactions(message.reaction).map(
                                (elem, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={classes.wrapperEmoji}
                                    >
                                      <ClickNHold
                                        onClickNHold={() =>
                                          this.clickNHold(elem.emoji, message)
                                        }
                                        time={0.5}
                                      >
                                        <div
                                          className={classes.emojiBtn}
                                          onClick={() =>
                                            this.sumEmoji(elem.emoji, message)
                                          }
                                        >
                                          <Emoji
                                            unified={elem.emoji}
                                            size="18"
                                          />
                                          <Typography
                                            className={classes.reactionsCount}
                                          >
                                            {elem.count}
                                          </Typography>
                                        </div>
                                      </ClickNHold>
                                    </div>
                                  );
                                },
                              )
                            : null}

                          <div
                            className={classes.emojiBtn}
                            onClick={() => this.addReaction(i)}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/emoji-icon.svg`}
                              alt="add-emoji-icon"
                            />
                          </div>
                        </div>
                      )}

                      {suggestion && currentIndex === i && (
                        <ClickAwayListener onClickAway={this.handleCloseEmoji}>
                          <div className={classes.emojiSuggestion}>
                            {suggestedEmojies.map((item, index) => (
                              <div
                                className={classes.emojiChild}
                                onClick={() => this.sumEmoji(item, message)}
                                key={index}
                              >
                                <Emoji unified={item} size="18" />
                              </div>
                            ))}

                            <div
                              className={classes.emojiChild}
                              onClick={() => this.handleOpenEmoji(i)}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/add-emoji-icon.svg`}
                                alt="add-emoji-icon"
                              />
                            </div>
                          </div>
                        </ClickAwayListener>
                      )}
                    </div>

                    {message.workout ? (
                      <Tooltip
                        title={isCoach ? message.workout[0].description.en : ""}
                      >
                        <div
                          style={{
                            backgroundColor:
                              WorkoutTypes.Colors[
                                message.workout[0].workoutType
                              ],
                          }}
                          className={classes.workoutContainerMessage}
                        >
                          <div>
                            <Typography
                              variant="body1"
                              className={classes.workoutTitle}
                            >
                              {`${t(
                                `${
                                  Weekdays.itemsArray[
                                    moment(
                                      message.workout[0].plannedDate,
                                      dateFormat,
                                    ).isoWeekday() - 1
                                  ].text
                                }`,
                                { ns: "constants" },
                              )}: `}
                              {message.workout[0].userCreatedType
                                ? message.workout[0].userCreatedType
                                : t(
                                    `${
                                      WorkoutTypes.Items[
                                        message.workout[0].workoutType
                                      ]
                                    }`,
                                    { ns: "constants" },
                                  )}
                            </Typography>
                            <div className={classes.workoutSubText}>
                              <Typography
                                variant="body1"
                                className={classes.workoutTime}
                              >
                                {EstimatedTimes.convertMinutesToLabel(
                                  Array.isArray(
                                    message.workout[0].estimatedTime,
                                  )
                                    ? message.workout[0].estimatedTime[
                                        !isNaN(customLevel)
                                          ? customLevel
                                          : level
                                      ]
                                    : message.workout[0].estimatedTime,
                                  true,
                                )}
                              </Typography>
                              <Typography
                                variant="body1"
                                className={classes.workoutDate}
                              >
                                {message.workout[0].plannedDate}
                              </Typography>
                            </div>
                          </div>
                          {message.workout[0].isDone ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/icon-done.svg`}
                              alt=""
                            />
                          ) : (
                            <img
                              src={`${process.env.PUBLIC_URL}/icon-notdone.svg`}
                              alt=""
                            />
                          )}
                        </div>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </div>
                  {message.type === 1 ? (
                    <Avatar
                      src={
                        message.senderImage
                          ? imgUrls[message.senderImage]
                          : `${process.env.PUBLIC_URL}/avatar-empty.png`
                      }
                      className={classes.avatarAthlete}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ))}

              {loading ? (
                <div className={classes.loading}>
                  <CircularProgress variant="indeterminate" />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className={classes.textFieldContainer}>
              {workout && addWorkout ? (
                <div>
                  <div className={classes.rightButtons}>
                    <img
                      src={`${process.env.PUBLIC_URL}/close.svg`}
                      alt=""
                      onClick={() => this.handleRemoveWorkout()}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor:
                        WorkoutTypes.Colors[workout[0].workoutType],
                    }}
                    className={classes.workoutContainer}
                  >
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.workoutTitle}
                      >
                        {`${t(
                          `${
                            Weekdays.itemsArray[
                              moment(
                                workout[0].plannedDate,
                                dateFormat,
                              ).isoWeekday() - 1
                            ].text
                          }`,
                          { ns: "constants" },
                        )}: `}
                        {workout[0].userCreatedType
                          ? workout[0].userCreatedType
                          : t(`${WorkoutTypes.Items[workout[0].workoutType]}`, {
                              ns: "constants",
                            })}
                      </Typography>
                      <div className={classes.workoutSubText}>
                        <Typography
                          variant="body1"
                          className={classes.workoutTime}
                        >
                          {EstimatedTimes.convertMinutesToLabel(
                            Array.isArray(workout[0].estimatedTime)
                              ? workout[0].estimatedTime[
                                  !isNaN(customLevel) ? customLevel : level
                                ]
                              : workout[0].estimatedTime,
                            true,
                          )}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.workoutDate}
                        >
                          {workout[0].plannedDate}
                        </Typography>
                      </div>
                    </div>
                    {workout[0].isDone ? (
                      <img
                        src={`${process.env.PUBLIC_URL}/icon-done.svg`}
                        alt=""
                      />
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/icon-notdone.svg`}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              {replyTo ? (
                <div>
                  <div className={classes.rightButtons}>
                    <img
                      src={`${process.env.PUBLIC_URL}/close.svg`}
                      alt=""
                      onClick={() => this.handleRemoveReplyTo()}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor:
                        replyTo.type === 0 ? "#FFECB3" : "#E7F3FC",
                      borderColor:
                        replyTo.type === 0 ? "#FFD600" : "rgb(28 99 155)",
                    }}
                    className={classes.replyToContainer}
                  >
                    {replyTo.type === 0 ? (
                      <Typography variant="h6" className={classes.sender}>
                        {replyTo.senderName ? replyTo.senderName : t("Coach")}
                      </Typography>
                    ) : (
                      <Typography variant="h6" className={classes.sender}>
                        {userName}
                      </Typography>
                    )}

                    <Typography variant="body1" className={classes.text}>
                      {replyTo.text}
                    </Typography>

                    <Typography variant="body1" className={classes.date}>
                      {replyTo.date}
                    </Typography>
                  </div>
                </div>
              ) : (
                ""
              )}

              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                value={
                  currentComment && currentComment.text
                    ? currentComment.text
                    : ""
                }
                onChange={(ev) => this.handleTextChange(ev)}
                placeholder={t("Type a message")}
                labelWidth={0}
                autoComplete="off"
                multiline
                inputProps={{ maxLength: 1000 }}
                // onKeyPress={(ev) => {
                //   if (ev.key === "Enter") {
                //     this.handleSend();
                //     ev.preventDefault();
                //   }
                // }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="send message"
                      onClick={() => this.handleSend()}
                      edge="end"
                      color="primary"
                    >
                      <SendOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                }
                className={classes.textField}
                fullWidth
              />
            </div>
          </>
        ) : (
          ""
        )}
        <Dialog
          open={deleteDialogOpen}
          aria-labelledby="delete-dialog"
          onClose={() => this.handleClose()}
        >
          <DialogTitle>
            {"Are you sure you want to delete this comment?"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleDeleteComment(commentToDelete);
                this.handleClose();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={() => this.handleClose()} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

TrainingWeekdayDetailsChat.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  comments: PropTypes.array,
  comment: PropTypes.string,
  coachComment: PropTypes.string,
  coachFeedback: PropTypes.string,
  type: PropTypes.number,
  handleSaveComment: PropTypes.func,
  handleDeleteComment: PropTypes.func,
  handleEditComment: PropTypes.func,
  selectedPlan: PropTypes.string,
  isCoach: PropTypes.bool,
  match: PropTypes.object,
};

export default connect((store) => {
  return {
    athletes: store.athletes,
    userPlans: store.userPlans,
    subscriptions: store.subscriptions,
    auth: store.auth,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation(["dashboard", "constants"])(
      withWidth()(withMobileDialog()(memo(TrainingWeekdayDetailsChat))),
    ),
  ),
);
