import pusherClient from "../lib/pusher-client";
import ActionTypes from "../constants/action-types";

import { getLatestSubscription } from "./subscriptions-action";
import { getCurrentPlan } from "./user-plans-action";

let reloadingPlan = false;

const subscribe = (cognito_user_sub) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.PUSHER_CHANNEL_SUBSCRIPTION_PENDING });
    try {
      if (pusherClient.connection.state === "disconnected")
        pusherClient.connection.connect();
      const channel = await pusherClient.subscribe(cognito_user_sub);
      channel
        .bind("pusher:subscription_succeeded", () => {})
        .bind("system", (event) => {
          const { message } = event;
          if (message === "payment_refresh_required") {
            dispatch(getLatestSubscription());
          }
        })
        .bind("notification", (event) => {
          const { message } = event;
          if (message === "coaching_changes_refresh_required") {
            if (!reloadingPlan) {
              dispatch(getCurrentPlan(false)); //it will dispatch getNotifications
              reloadingPlan = true;
              setTimeout(() => (reloadingPlan = false), 1000);
            }
          }
        });
      dispatch({
        type: ActionTypes.PUSHER_CHANNEL_SUBSCRIPTION_FULFILLED,
        payload: channel,
      });
    } catch (exp) {
      dispatch({
        type: ActionTypes.PUSHER_CHANNEL_SUBSCRIPTION_REJECTED,
        error: true,
        payload: exp && exp.message ? exp.message : exp,
      });
    }
  };
};

const unsubscribe = (user_sub) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.PUSHER_CHANNEL_UNSUBSCRIPTION_PENDING });
    try {
      pusherClient.unbind_all("system");
      pusherClient.unbind_all("notification");
      if (user_sub) {
        await pusherClient.unsubscribe(user_sub);
        await pusherClient.disconnect();
      } else await pusherClient.disconnect();

      dispatch({ type: ActionTypes.PUSHER_CHANNEL_UNSUBSCRIPTION_FULFILLED });
    } catch (exp) {
      dispatch({
        type: ActionTypes.PUSHER_CHANNEL_UNSUBSCRIPTION_REJECTED,
        error: true,
        payload: exp && exp.message ? exp.message : exp,
      });
    }
  };
};

export { subscribe, unsubscribe };
