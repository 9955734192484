import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { withTranslation } from "react-i18next";
import history from "../../../lib/history-helper";
import WorkoutPlans from "../../../constants/workout-plans";
import { dateFormat } from "../../../lib/date-helper";
import { Button } from "@material-ui/core";
import UpgradeProModal from "../../generals/upgrade-pro-modal";
import { logEvent } from "../../../lib/events-helper";

const styles = (theme) => ({
  button: {
    fontWeight: "normal",

    [theme.breakpoints.down("md")]: {
      margin: "0 auto 0 10px",
    },
  },
  minWZero: {
    minWidth: "initial",
  },
});

class DashboardTrainingPrevious extends Component {
  state = {
    exploreFeatureDialogOpen: false,
  };

  handleCloseUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
  };

  handleUpgrade = () => {
    this.setState({
      ...this.state,
      exploreFeatureDialogOpen: false,
    });
    history.push("/subscription/explore");
  };

  render() {
    const {
      isInMobileView,
      currentStartDate,
      startingDate,
      selectedPlan,
      onCurrentDaysChangeRequested,
      dayCount,
      classes,
      hasSubscription,
    } = this.props;

    const { exploreFeatureDialogOpen } = this.state;

    const previousCount = isInMobileView
      ? moment(currentStartDate)
          .startOf("day")
          .diff(
            moment(currentStartDate)
              .startOf("day")
              .startOf("week"),
            "day",
          ) +
        7 -
        1
      : dayCount;
    const startingDateMoment = moment(startingDate, dateFormat);
    const limitedView = selectedPlan === WorkoutPlans.FREE && !hasSubscription;

    const isDisabled = () => {
      return limitedView
        ? moment(currentStartDate)
            .startOf("day")
            .isSameOrBefore(moment(startingDateMoment).startOf("day")) ||
            moment(currentStartDate)
              .startOf("day")
              .isSameOrBefore(
                moment()
                  .subtract(1, "week")
                  .startOf("isoWeek")
                  .startOf("day"),
              )
        : moment(currentStartDate)
            .startOf("day")
            .isSameOrBefore(moment(startingDateMoment).startOf("day"));
    };

    const onClick = () => {
      if (!isDisabled()) {
        onCurrentDaysChangeRequested(-previousCount);
      } else {
        if (!hasSubscription) {
          this.setState({
            ...this.state,
            exploreFeatureDialogOpen: true,
          });
        }
      }
    };

    return (
      <>
        <Button
          className={classes.button}
          classes={{ root: classes.minWZero }}
          onClick={() => onClick()}
          disabled={isDisabled()}
        >
          <img src={`${process.env.PUBLIC_URL}/arrow-left.svg`} alt="" />
        </Button>

        <UpgradeProModal
          open={exploreFeatureDialogOpen}
          onClose={this.handleCloseUpgrade}
        />
      </>
    );
  }
}

DashboardTrainingPrevious.propTypes = {
  classes: PropTypes.object.isRequired,
  isInMobileView: PropTypes.bool.isRequired,
  onCurrentDaysChangeRequested: PropTypes.func.isRequired,
  currentStartDate: PropTypes.any,
  startingDate: PropTypes.string.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  dayCount: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation("dashboard")(
  withStyles(styles, { withTheme: true })(DashboardTrainingPrevious),
);
