class AppNotificationsService {
  static initAppNotifications(userSub) {
    // Uncomment to set OneSignal device logging to VERBOSE
    // window.plugins.OneSignal.Debug.setLogLevel(6);

    window.plugins.OneSignal.initialize("77b02843-8485-400d-8948-10bd3bb956e9");
    window.plugins.OneSignal.login(userSub);

    const listener = (event) => {
      const notificationPayload = JSON.stringify(event);
      console.log(notificationPayload);
    };
    window.plugins.OneSignal.Notifications.addEventListener("click", listener);

    window.plugins.OneSignal.Notifications.requestPermission(true).then(
      (accepted) => {
        console.log("User accepted notifications: " + accepted);
      },
    );
  }
}
export default AppNotificationsService;
