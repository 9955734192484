import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Storage } from "aws-amplify";
import { convertUnits, round } from "../../lib/units-helper";

const styles = (theme) => ({
  imgContainer: {
    position: "relative",
  },
  badge: {
    width: "100%",
  },

  verifiedImg: {
    position: "absolute",
    top: 20,
    right: 20,
  },

  dateContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  date: {
    fontFamily: "Avenir",
    fontWeight: 800,
    fontSize: 14,
    textAlign: "center",
    color: "#202020",
    [theme.breakpoints.up("md")]: {
      fontSize: 11,
    },
  },

  textContainer: {
    padding: "0 16px 16px",
  },

  totalsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },

  totalsTextSelected: {
    fontFamily: "Avenir",
    fontWeight: 800,
    fontSize: 14,
    textAlign: "center",
    color: "#202020",
    margin: "0 2px",

    [theme.breakpoints.up("md")]: {
      fontSize: 11,
    },
  },

  totalsText: {
    fontFamily: "Avenir",
    fontSize: 14,
    textAlign: "center",
    color: "#202020",
    margin: "0 2px",

    [theme.breakpoints.up("md")]: {
      fontSize: 11,
    },
  },
});

class FinishedChallenge extends Component {
  state = {
    badgeUrl: null,
    badgeLoading: false,
  };

  async componentWillReceiveProps(nextProps) {
    const { challenge } = nextProps;
    const { badge } = challenge || {};
    const { badgeUrl } = this.state;
    if (badge && !badgeUrl) {
      this.getBadgeUrl(badge);
    }
  }

  getBadgeUrl = async (image) => {
    const { t } = this.props;
    const { badgeUrl } = this.state;

    if (image && !badgeUrl) {
      try {
        const result = await Storage.get(image, {
          level: "public",
          cacheControl: "no-cache, no-store, must-revalidate",
          expires: 0,
          pragma: "no-cache",
        });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              badgeUrl: result,
              badgeLoading: false,
            }),
          100,
        );
      } catch (exp) {
        // onError(
        new Error(
          t(
            "Oops, we could not load your profile picture. Give it another try",
            { ns: "messages" },
          ),
        );
        //);
        throw exp;
      }
    } else if (!image) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            badgeLoading: false,
          }),
        100,
      );
    }
  };

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    const {
      classes,
      yourEntry,
      t,
      i18n,
      onShareChallenge,
      units,
      width,
    } = this.props;

    const { challengeData } = yourEntry;

    const { start_date, end_date, badge, goal_type } = challengeData;

    const { language } = i18n;

    const momentLocale = moment().locale(language);

    const isInMobileView = width === "md" || width === "sm" || width === "xs";

    const h =
      yourEntry && yourEntry.totals && yourEntry.totals.time
        ? Math.floor(yourEntry.totals.time / 3600)
        : 0;
    const m =
      yourEntry && yourEntry.totals && yourEntry.totals.time
        ? Math.floor((yourEntry.totals.time % 3600) / 60)
        : 0;
    const timeStr = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;

    return (
      <div
        className={classes.container}
        onClick={() =>
          ((yourEntry.challengeData.goal && yourEntry.finished) ||
            (!yourEntry.challengeData.goal &&
              yourEntry.activities &&
              yourEntry.activities.length)) &&
          onShareChallenge({ ...challengeData, yourEntry })
        }
      >
        <div className={classes.imgContainer}>
          <img
            className={classes.badge}
            src={badge}
            alt="badge"
            style={{
              opacity:
                (yourEntry.challengeData.goal && yourEntry.finished) ||
                (!yourEntry.challengeData.goal &&
                  yourEntry.activities &&
                  yourEntry.activities.length)
                  ? 1
                  : 0.5,
            }}
          />

          {/*(yourEntry.challengeData.goal && yourEntry.finished) ||
          (!yourEntry.challengeData.goal &&
            yourEntry.activities &&
            yourEntry.activities.length) ? (
            <img
              alt="finished"
              className={classes.verifiedImg}
              src={`${process.env.PUBLIC_URL}/icon-verified.svg`}
            />
          ) : (
            ""
          )*/}
        </div>
        <div
          className={classes.textContainer}
          style={{
            opacity:
              (yourEntry.challengeData.goal && yourEntry.finished) ||
              (!yourEntry.challengeData.goal &&
                yourEntry.activities &&
                yourEntry.activities.length)
                ? 1
                : 0.5,
          }}
        >
          {!isInMobileView ? (
            <div className={classes.dateContainer}>
              <img
                src={`${process.env.PUBLIC_URL}/icon-challenge-date.svg`}
                alt=""
                className={classes.iconDate}
              />
              <Typography variant={"body1"} className={classes.date}>
                {`${this.capitalize(
                  momentLocale
                    .localeData()
                    .months(moment(start_date))
                    .substr(0, 3),
                )} ${moment(moment(start_date)).format("D")} ${t(
                  "to",
                )} ${this.capitalize(
                  momentLocale
                    .localeData()
                    .months(moment(end_date))
                    .substr(0, 3),
                )} ${moment(moment(end_date)).format("D")}. ${moment(
                  moment(end_date),
                ).format("YYYY")}`}
              </Typography>
            </div>
          ) : (
            ""
          )}

          {!isInMobileView ? (
            <div className={classes.totalsContainer}>
              <Typography
                variant={"body1"}
                className={
                  goal_type === "distance"
                    ? classes.totalsTextSelected
                    : classes.totalsText
                }
              >
                {t("valueUnit", {
                  value:
                    yourEntry && yourEntry.totals
                      ? round(
                          convertUnits(
                            yourEntry.totals.distance / 1000,
                            "kms",
                            units,
                            "distance",
                          ),
                          1,
                        )
                      : 0,
                  unit: units === "miles" ? ` ${t("miles")}` : "km",
                })}
              </Typography>
              <Typography variant={"body1"} className={classes.totalsText}>
                {" - "}
              </Typography>
              <Typography
                variant={"body1"}
                className={
                  goal_type === "time"
                    ? classes.totalsTextSelected
                    : classes.totalsText
                }
              >
                {t("valueUnit", {
                  value: yourEntry && yourEntry.totals ? timeStr : 0,
                  unit: "h",
                })}
              </Typography>
              <Typography variant={"body1"} className={classes.totalsText}>
                {" - "}
              </Typography>
              <Typography
                variant={"body1"}
                className={
                  goal_type === "elevation"
                    ? classes.totalsTextSelected
                    : classes.totalsText
                }
              >
                {t("valueUnit", {
                  value:
                    yourEntry && yourEntry.totals
                      ? round(
                          convertUnits(
                            yourEntry.totals.elevation,
                            "kms",
                            units,
                            "elevation",
                          ),
                          1,
                        )
                      : 0,
                  unit: units === "miles" ? ` ${t("feet")}` : "m",
                })}
              </Typography>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

FinishedChallenge.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  challenge: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation("challenges")(
  withWidth()(withStyles(styles, { withTheme: true })(FinishedChallenge)),
);
