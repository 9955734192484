import React, { useState } from "react";
import { Box, Slide, Dialog } from "@material-ui/core";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import history from "../../../lib/history-helper";
import { makeStyles } from "@material-ui/core/styles";
import OnboardingGenerator from "../../../components/common/v2/OnboardingGenerator";
import useQuestionnaire from "../../../hooks/useQuestionnaire";
import { IconMedal, IconRun, IconSearch, IconWalk } from "@tabler/icons-react";
import { ElevationIcon, FireIcon } from "../../../components/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const OnboardingSignUpQuestions = () => {
  const [openDialog, setOpenDialog] = useState(true);
  const [activeStep, setActiveStep] = useState(1);
  const { onboardingStorage } = useQuestionnaire();

  const classes = useStyles();

  const handleCloseDialog = () => {
    if (activeStep > 1) {
      handleBack();
    } else {
      setOpenDialog(false);
    }
  };

  const handleTransitionEnd = () => {
    history.goBack();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(stepsConfig.findIndex((step) => step.name === "welcome") + 1);
  };

  const handleChange = async (value, name) => {
    if (name === "welcomeItem") {
      setActiveStep(stepsConfig.findIndex((step) => step.name === value) + 1);
    } else if (name === "onboardingGoal") {
      await onboardingStorage.setItem(name, value);
      history.push("/auth/sign-up/method");
    }
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionProps={{ onExited: handleTransitionEnd }}
      TransitionComponent={Transition}
      display={"flex"}
      flexDirection="column"
    >
      <ToolbarReturnBack
        withLogo={true}
        logoWidth={107}
        style={{
          backgroundColor: "#FAFAFA",
          alignItems: "flex-start",
          padding: "10px",
        }}
        goBack={handleCloseDialog}
      />
      <Box className={classes.parentBox}>
        <Box className={classes.contentBox}>
          {OnboardingGenerator(
            stepsConfig,
            activeStep,
            handleChange,
            handleNext,
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default OnboardingSignUpQuestions;

const stepsConfig = [
  {
    name: "welcome",
    components: [
      {
        type: "titleIntro",
        value: "onboarding.signupQuestion.titleIntro",
      },
      {
        type: "subTitleIntro",
        value: "onboarding.signupQuestion.subTitleIntro",
      },
      {
        type: "buttonGroup",
        options: [
          {
            label: "onboarding.signupQuestion.road_running",
            value: "road_running",
            icon: IconWalk,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.trail_running",
            value: "trail_running",
            icon: ElevationIcon,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.both",
            value: "both",
            icon: FireIcon,
            color: "#12151B",
          },
        ],
        value: "",
        name: "welcomeItem",
      },
    ],
  },
  {
    name: "both",
    components: [
      {
        type: "subTitleIntro",
        value: "onboarding.signupQuestion.subTitleIntro2",
      },
      {
        type: "subTitle",
        value: "onboarding.signupQuestion.subtitle2",
      },
      {
        type: "buttonGroup",
        options: [
          {
            label: "onboarding.signupQuestion.run5kto10k",
            value: "run5kto10k",
            icon: IconWalk,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.halfMarathon",
            value: "half_marathon",
            icon: ElevationIcon,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.marathon",
            value: "marathon",
            icon: IconMedal,
            color: "#020202",
          },
          {
            label: "onboarding.signupQuestion.run50k",
            value: "run50k",
            icon: IconRun,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.run80kto170kplus",
            value: "run80kto170kplus",
            icon: IconMedal,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.none",
            value: "none",
            icon: IconSearch,
            color: "#12151B",
          },
        ],
        value: "",
        name: "onboardingGoal",
      },
    ],
  },
  {
    name: "road_running",
    components: [
      {
        type: "subTitleIntro",
        value: "onboarding.signupQuestion.subTitleIntro2",
      },
      {
        type: "subTitle",
        value: "onboarding.signupQuestion.subtitle2",
      },
      {
        type: "buttonGroup",
        options: [
          {
            label: "onboarding.signupQuestion.run5kto10k",
            value: "run5kto10k",
            icon: IconWalk,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.halfMarathon",
            value: "half_marathon",
            icon: ElevationIcon,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.marathon",
            value: "marathon",
            icon: IconMedal,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.run50kplus",
            value: "run50kplus",
            icon: IconRun,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.none",
            value: "none",
            icon: IconSearch,
            color: "#12151B",
          },
        ],
        value: "",
        name: "onboardingGoal",
      },
    ],
  },
  {
    name: "trail_running",
    components: [
      {
        type: "subTitleIntro",
        value: "onboarding.signupQuestion.subTitleIntro2",
      },
      {
        type: "subTitle",
        value: "onboarding.signupQuestion.subtitle2",
      },
      {
        type: "buttonGroup",
        options: [
          {
            label: "onboarding.signupQuestion.run5kto42k",
            value: "run5kto42k",
            icon: IconWalk,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.run50k",
            value: "run50k",
            icon: IconRun,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.run80kto170kplus",
            value: "run80kto170kplus",
            icon: IconMedal,
            color: "#12151B",
          },
          {
            label: "onboarding.signupQuestion.none",
            value: "none",
            icon: IconSearch,
            color: "#12151B",
          },
        ],
        value: "",
        name: "onboardingGoal",
      },
    ],
  },
];

const useStyles = makeStyles({
  parentBox: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    padding: "16px",
    gap: "12px",
  },
  contentBox: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  title: {
    fontFamily: "Oswald",
    fontWeight: 700,
    fontSize: "22px",
    color: "#F93C6E",
    textTransform: "uppercase",
    lineheight: "27.5px",
  },

  subtitle: {
    fontFamily: "Karla",
    fontWeight: 700,
    fontSize: "18px",
    color: "#12151B",
    lineheight: "22.5px",
  },
  buttonsContainer: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignContent: "center",
    marginTop: "20px",
  },
  button: {
    backgroundColor: "#FEFFFF",
    borderRadius: "4px",
    padding: "16px",
    height: "56px",
    justifyContent: "flex-start",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  buttonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineheight: "20px",
    color: "#12151B",
  },
});
