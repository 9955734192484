import React, { memo } from "react";
import { Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import history from "../../../lib/history-helper";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { useParams } from "react-router-dom";

const styles = (theme) => ({
  commonCard: {
    background: "white",
    margin: "16px 16px 0px 16px",
    borderRadius: "12px",
    padding: "16px",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  cardTitleParent: {
    display: "flex",
    justifyContent: "space-between",
    algnItems: "flex-start",
    marginBottom: "16px",
  },
  cardSubtitle: {
    textAlign: "center",
    fontSize: "12px",
  },
  orangeText: {
    color: "#FF5722",
  },
});

const Card = ({
  leftText,
  rightText,
  data = [],
  classes,
  flag,
  t,
  i18n,
  match,
}) => {
  const { language } = i18n;
  const momentLocale = moment().locale(language);
  const params = useParams();

  const navigateTo = () => {
    const { id } = params;
    if (flag == "race") return history.push(`/trailhead/${id}/races`);
    history.push(`/trailhead/${id}/all-badges`);
  };

  const capitalize = (text) => {
    if (typeof text !== "string") return "";
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  return (
    <div className={classes.commonCard} onClick={navigateTo}>
      <div className={classes.cardTitleParent}>
        <Typography variant="body2">{t(leftText)}</Typography>
        <Typography variant="body2" className={classes.orangeText}>
          {t(rightText)}
        </Typography>
      </div>
      <Grid container spacing={2}>
        {data && data.length ? (
          (data.slice(0, 3) || []).map((item = {}, index) => (
            <Grid item xs={4} md={4} key={index}>
              <img
                src={
                  flag == "race"
                    ? item.badge
                    : item.challengeData.badge ||
                      `${process.env.PUBLIC_URL}/user-profile/race.png`
                }
                width="100%"
              ></img>
              {flag == "race" ? (
                <Typography className={classes.cardSubtitle}>
                  {item.name}
                </Typography>
              ) : (
                <div>
                  <Typography variant="body2" className={classes.cardSubtitle}>
                    {" "}
                    {`${capitalize(
                      momentLocale
                        .localeData()
                        .months(moment(item.challengeData.start_date))
                        .substr(0, 3),
                    )} ${moment(moment(item.challengeData.start_date)).format(
                      "D",
                    )} ${t("to")} ${capitalize(
                      momentLocale
                        .localeData()
                        .months(moment(item.challengeData.end_date))
                        .substr(0, 3),
                    )} ${moment(moment(item.challengeData.end_date)).format(
                      "D",
                    )}. ${moment(moment(item.challengeData.end_date)).format(
                      "YYYY",
                    )}`}
                  </Typography>
                </div>
              )}
            </Grid>
          ))
        ) : (
          <Grid item xs={4} md={4}>
            <img
              src={`${process.env.PUBLIC_URL}/user-profile/race-empty.png`}
              width="100%"
            ></img>
            <Typography className={classes.cardSubtitle}>
              {flag == "race" ? t("No Races") : t("No Badges")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation(["trailhead", "challenges"])(memo(Card)),
);
