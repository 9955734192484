import React from "react";
import { Box, Typography, makeStyles, Divider } from "@material-ui/core";
import { palette } from "../../theme/palette";
import MicIcon from "@material-ui/icons/MicNoneOutlined";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  card: {
    background: palette.white,
    borderRadius: "8px",
    marginTop: "12px",
    width: "275px",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
  button: {
    fontFamily: theme.typography.fontFamily,
    marginTop: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "18px",
    textTransform: "lowercase",
  },
  descriptionContainer: {
    padding: "12px",
  },
  divider: {
    background: palette.lightGray,
    width: "2px",
    height: "40px",
  },
  label: {
    color: palette.darkGray,
    marginTop: "4px",
  },
  labelOnly: {
    color: palette.darkGray,
  },
  margin: {
    marginTop: "12px",
  },
  content: {
    color: palette.darkGray,
    fontWeight: "400",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 0px 16px 0px",
    boxSizing: "border-box",
  },
  rowLeft: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontWeight: "700",
  },
}));

const iconStyle = {
  color: palette.darkGray,
  width: "18px",
  margin: 0,
  padding: 0,
  marginRight: "4px",
};

const PodcastRow = ({ podcast = [], onlyFavorites, language }) => {
  const history = useHistory();
  const classes = useStyles();
  const { currentPlan } = useSelector((state) => state.userPlans);
  const { exploreFavorites } = currentPlan || {};

  const navigatePodcast = ({ id }) => {
    history.push(`explore/post/${id}`);
  };

  return (
    <>
      {(podcast || []).map((item, index) => {
        const isFavorite = (exploreFavorites || []).find(
          ({ id }) => id === item.id,
        );

        if (onlyFavorites && !isFavorite) return;
        return (
          <Box key={index}>
            <Box
              className={classes.row}
              key={index}
              id={index}
              onClick={() => navigatePodcast(item)}
            >
              <Box>
                <Typography variant="h5" className={classes.title}>
                  {item.title[language]
                    ? item.title[language]
                    : item.title["en"]}
                </Typography>
                <Box className={classes.rowLeft}>
                  <MicIcon style={iconStyle} />
                  <Typography variant="subtitle2" className={classes.content}>
                    {item.time}
                  </Typography>
                </Box>
              </Box>
              <Box style={{ padding: "10px" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/icon-play-black.svg`}
                  width={35}
                ></img>
              </Box>
            </Box>
            <Divider style={{ background: palette.opacityGray }} />
          </Box>
        );
      })}
    </>
  );
};

export default PodcastRow;
