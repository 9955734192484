import React from "react";
import {
  Box,
  ButtonBase,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  FormControl,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import ModalCustom from "./ModalCustom";
import { useTranslation } from "react-i18next";
import { AddIcon, CalendarIcon, TimerIcon } from "../../icons";
import { palette } from "../../../theme/palette";
import CustomButton from "./CustomButton";
import PaywallModal from "./PaywallModal";
import MomentUtils from "@date-io/moment";
import { dateFormat } from "../../../lib/date-helper";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import useAddWorkout from "../../../hooks/useAddWorkout";

const AddWorkout = ({ isSubscribed = false, disabled = false }) => {
  const classes = useStyles();
  const { t } = useTranslation("training");
  const [isAddWorkout, setIsAddWorkout] = React.useState(false);
  const [isOpenPaywall, setIsOpenPaywall] = React.useState(false);
  const {
    workoutValues,
    enableToSend,
    handleChange,
    handleDateChange,
    handleInput,
    handleAddWorkout,
  } = useAddWorkout();

  const timerOptions = Array.from({ length: 60 }, (_, i) => i).map((value) =>
    value < 10 ? `0${value}` : `${value}`,
  );

  const openAddWorkout = () => {
    if (isSubscribed) {
      setIsAddWorkout(true);
    } else {
      setIsOpenPaywall(true);
    }
  };

  const onCloseAddWorkout = () => setIsAddWorkout(false);

  const onAddWorkout = () => {
    handleAddWorkout(workoutValues);
    onCloseAddWorkout();
  };

  return (
    <>
      <PaywallModal
        isOpen={isOpenPaywall}
        onClose={() => setIsOpenPaywall(false)}
      />

      <ButtonBase
        className={classes.cardButton}
        onClick={openAddWorkout}
        disabled={disabled}
      >
        <AddIcon fill={palette.black} />
        <Typography className={classes.cardTextButton}>
          {t("training.addWorkout")}
        </Typography>
      </ButtonBase>
      {/** Add workout modal  */}
      <ModalCustom
        fullScreen={false}
        open={isAddWorkout}
        onClose={onCloseAddWorkout}
        title={t("training.addWorkout")}
        toolbarJustifyContent="end"
        headerStyles={{
          padding: "8px 5px !important",
        }}
        contentStyles={{
          padding: "8px 20px 10px",
          gap: 8,
        }}
      >
        <Grid container style={{ gap: "15px 0" }}>
          <Grid item xs={12} style={{ paddingRight: "2.5px" }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                color="secondary"
                fullWidth
                name="date"
                placeholder={t("training.addWorkout.inputDate")}
                variant="outlined"
                format="DD/MM/YYYY"
                value={moment(
                  workoutValues.date || null,
                  dateFormat,
                ).toISOString()}
                autoOk
                size="small"
                inputVariant="outlined"
                keyboard
                onChange={handleDateChange}
                inputProps={{
                  className: classes.input,
                  maxLength: 150,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarIcon fill={palette.deactivated} />
                    </InputAdornment>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              color="secondary"
              size="small"
              variant="outlined"
              id="workoutType"
              name="workoutType"
              type="text"
              value={workoutValues.workoutType}
              placeholder={t("training.addWorkout.inputWorkoutType")}
              onChange={handleChange}
              inputProps={{
                className: classes.input,
                maxLength: 20,
              }}
              fullWidth
              InputProps={{
                classes: {
                  root: classes.root,
                },
                startAdornment: (
                  <>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.1172 4.10986C10.9422 4.10986 11.6172 3.43486 11.6172 2.60986C11.6172 1.78486 10.9422 1.10986 10.1172 1.10986C9.29216 1.10986 8.61716 1.78486 8.61716 2.60986C8.61716 3.43486 9.29216 4.10986 10.1172 4.10986ZM7.73966 13.1099L8.16716 11.2349L9.74216 12.7349V16.4849C9.74216 16.8974 10.0797 17.2349 10.4922 17.2349C10.9047 17.2349 11.2422 16.8974 11.2422 16.4849V12.2549C11.2422 11.8424 11.0772 11.4524 10.7772 11.1674L9.66716 10.1099L10.1172 7.85986C10.9624 8.8261 12.1194 9.46565 13.3872 9.66736C13.8372 9.73486 14.2422 9.37486 14.2422 8.91736C14.2422 8.54986 13.9722 8.24236 13.6047 8.18236C12.4647 7.99486 11.5197 7.31986 11.0172 6.43486L10.2672 5.23486C9.96716 4.78486 9.51716 4.48486 8.99216 4.48486C8.76716 4.48486 8.61716 4.55986 8.39216 4.55986L5.40716 5.81986C5.13545 5.936 4.90386 6.12944 4.74118 6.37612C4.5785 6.62281 4.49191 6.91187 4.49216 7.20736V8.98486C4.49216 9.39736 4.82966 9.73486 5.24216 9.73486C5.65466 9.73486 5.99216 9.39736 5.99216 8.98486V7.18486L7.34216 6.65986L6.14216 12.7349L3.20216 12.1349C2.79716 12.0524 2.39966 12.3149 2.31716 12.7199V12.7499C2.23466 13.1549 2.49716 13.5524 2.90216 13.6349L5.98466 14.2499C6.36806 14.326 6.76602 14.2494 7.09381 14.0365C7.4216 13.8236 7.65333 13.4911 7.73966 13.1099Z"
                        fill="#AFB1B4"
                      />
                    </svg>
                  </>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ paddingLeft: "2.5px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                <TimerIcon />
                <Typography className={classes.label}>
                  {t("training.addWorkout.inputDuration")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <FormControl
                  variant="outlined"
                  style={{
                    width: "100%",
                    minWidth: 75,
                    backgroundColor: "#FFFFFF",
                    color: palette.black,
                  }}
                >
                  <Select
                    color="secondary"
                    name="durationHH"
                    fullWidth
                    displayEmpty
                    labelId="duration-hh-label"
                    id="duration-hh-select"
                    onBlur={(event) => event.preventDefault()}
                    value={workoutValues.durationHH}
                    onChange={handleInput}
                    className={classes.selectStyle}
                    inputProps={{
                      className: classes.selectStyle,
                    }}
                    disableUnderline
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 152,
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      {"HH"}
                    </MenuItem>
                    {timerOptions.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography className={classes.label}>{":"}</Typography>
                <FormControl
                  variant="outlined"
                  style={{
                    width: "100%",
                    minWidth: 75,
                    backgroundColor: "#FFFFFF",
                    color: palette.black,
                  }}
                >
                  <Select
                    color="secondary"
                    name="durationMM"
                    fullWidth
                    displayEmpty
                    labelId="duration-mm-label"
                    id="duration-mm-select"
                    onBlur={(event) => event.preventDefault()}
                    value={workoutValues.durationMM}
                    onChange={handleInput}
                    className={classes.selectStyle}
                    inputProps={{
                      className: classes.selectStyle,
                    }}
                    disableUnderline
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 152,
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      {"MM"}
                    </MenuItem>
                    {timerOptions.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              color="secondary"
              fullWidth
              id="description"
              type="text"
              multiline={true}
              minRows={6}
              maxRows={6}
              variant="outlined"
              name="description"
              value={workoutValues.description}
              placeholder={t("training.addWorkout.inputDescription")}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Box py={2} pr={"2.5px"}>
              <CustomButton
                color={"default"}
                typeStyle={"secondary"}
                variant="contained"
                size="large"
                onClick={onCloseAddWorkout}
                fullWidth
              >
                {t("training.modalButtons.cancel")}
              </CustomButton>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box py={2} pl={"2.5px"}>
              <CustomButton
                disabled={enableToSend}
                color={"secondary"}
                typeStyle={"primary"}
                variant="contained"
                size="large"
                onClick={onAddWorkout}
                fullWidth
              >
                {t("training.modalButtons.add")}
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </ModalCustom>
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  input: {
    padding: "14px 8px",
  },
  modalTitle: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 700,
    textAlign: "left",
    color: palette.black,
  },
  modalDescription: {
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",
    color: palette.black,
  },
  cardButton: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "120px",
    padding: "16px 12px",
    gap: 4,
    borderRadius: 4,
    boxShadow: palette.boxShadow,
    "&:hover": {
      backgroundColor: "#F1F6FD",
    },
  },
  cardTextButton: {
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
  },
  marginY: {
    marginTop: 15,
    marginBottom: 15,
  },
  label: {
    //styleName: p1-r;
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 400,
  },
  selectStyle: {
    fontSize: "16px",
    padding: "5px !important",
    borderRadius: "4px",
    border: palette.lightGray,
    fontFamily: "Karla, sans-serif",
    fontWeight: 400,
    backgroundColor: "#FEFFFF",
    color: palette.black,
  },
}));

export default AddWorkout;
