import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Routes } from "../lib/routing-middleware";
import history from "../lib/history-helper";
import TrainingFieldsViewer from "./training-viewer/training-fields-viewer";
import {
  changeWeek,
  selectWorkout,
  readTraining,
  addWorkout,
  removeWorkout,
  cleanSearch,
  clearCurrentTraining,
  saveTraining,
  clearSuccessMessage,
  clearErrorMessage,
} from "../actions/training-action";
import TrainingEditor from "./training-viewer/training-editor";
import TrainingWorkoutSearch from "./training-viewer/training-workout-search";
import TrainingEditorButtons from "./training-viewer/training-editor/training-editor-buttons";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
// import Plans from '../constants/plans';
import FlexContainer from "./common/flex-container";
import UserGroups from "../constants/user-groups";

const styles = (theme) => ({
  trainingContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 0.75,
    padding: 10,
  },
  loading: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    marginTop: 100,
  },
  containerScroll: {
    width: "100%",
    display: "flex",
    overflow: "scroll",
  },
});

class TrainingViewer extends Component {
  state = {
    level: 2,
  };

  handleRequestWeekChange = (weekNumber) => {
    this.props.dispatch(changeWeek(weekNumber));
  };

  handleSelectWorkout = (workout) => {
    this.props.dispatch(selectWorkout(workout));
  };

  handleWorkoutAdd = (dayIndex) => {
    this.props.dispatch(addWorkout(dayIndex));
  };

  handleWorkoutDelete = (workoutId, index) => {
    this.props.dispatch(removeWorkout({ workoutId: workoutId, index: index }));
  };

  handleBackToTrainings = () => {
    history.push(Routes.Trainings);
  };

  handleSaveTraining = () => {
    const { training } = this.props;
    if (training) {
      const { currentTraining } = training;
      if (currentTraining) this.props.dispatch(saveTraining(currentTraining));
    }
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  hasRequiredParams = () => {
    const { match } = this.props;
    const { params = {} } = match;
    const { id, training_order } = params;
    if (id && training_order) return { id, training_order };
  };

  componentWillUnmount() {
    let shouldCleanSearchResult = true;

    const { history } = this.props;
    if (history) {
      const { location } = history;
      if (location) {
        const { pathname } = location;
        if (pathname === "/trainings") shouldCleanSearchResult = false;
      }
    }

    if (shouldCleanSearchResult) this.props.dispatch(cleanSearch());
    else this.props.dispatch(clearCurrentTraining());
  }

  async componentDidMount() {
    const keys = this.hasRequiredParams();
    if (keys) this.props.dispatch(readTraining(keys));
  }

  handleStep = (step) => {
    this.setState({
      ...this.state,
      level: step,
    });
  };

  render() {
    const { classes, training, subcategories, auth } = this.props;
    const { data } = subcategories;
    const {
      currentTraining,
      currentWeek,
      reading,
      saving,
      errorMessage,
      successMessage,
    } = training;
    const hasCurrentTraining = typeof currentTraining !== undefined;

    const { created_by_cognito_user_sub } = currentTraining || {};

    const { currentUserGroups, currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;

    const isExternalCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
      ) > -1;

    const disabled =
      isExternalCoach &&
      created_by_cognito_user_sub &&
      created_by_cognito_user_sub !== sub;

    const keys = this.hasRequiredParams();

    const { level } = this.state;

    return keys ? (
      <FlexContainer
        direction="row"
        wrap={false}
        padding={10}
        classes={{
          container: classes.containerScroll,
        }}
      >
        <TrainingWorkoutSearch
          onSelectWorkout={this.handleSelectWorkout}
          isExternalCoach={isExternalCoach}
          sub={sub}
          disabled={disabled}
          level={
            currentTraining && currentTraining.raceCount
              ? currentTraining.raceCount
              : null
          }
        />
        <Paper className={classes.trainingContainer}>
          {reading ? (
            <CircularProgress
              variant="indeterminate"
              className={classes.loading}
            />
          ) : (
            <>
              <FlexContainer direction="row" padding={5}>
                {currentTraining && (
                  <TrainingFieldsViewer
                    currentTraining={currentTraining}
                    subcatgories={data}
                  />
                )}
                <TrainingEditorButtons
                  hasCurrentTraining={hasCurrentTraining}
                  saving={saving}
                  onSaveTraining={this.handleSaveTraining}
                  onClearCurrentTraining={this.handleBackToTrainings}
                  disabled={disabled}
                  level={level}
                  showLevelStepper={
                    currentTraining &&
                    currentTraining.raceCount &&
                    currentTraining.raceCount === "auto"
                  }
                  onHandleStep={this.handleStep}
                />
              </FlexContainer>
              <TrainingEditor
                saving={saving}
                currentWeek={currentWeek}
                currentTraining={currentTraining}
                onRequestWeekChange={this.handleRequestWeekChange}
                onWorkoutAdd={this.handleWorkoutAdd}
                onWorkoutDelete={this.handleWorkoutDelete}
                disabled={disabled}
                level={level}
              />
            </>
          )}
        </Paper>
        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      </FlexContainer>
    ) : (
      <Redirect to="/trainings" />
    );
  }
}

TrainingViewer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect((store) => {
  return {
    training: store.training,
    subcategories: store.subcategories,
    auth: store.auth,
  };
})(withStyles(styles, { withTheme: true })(TrainingViewer));
