import React from "react";
import { SvgIcon } from "@material-ui/core";
import { palette } from "../../theme/palette";

const HeartIcon = ({ width = 24, height = 24, state = "inactive" }) => {
  let fill = "none";
  let stroke = palette.deactivated;

  if (state === "highlighted") {
    stroke = palette.primary;
  } else if (state === "filled") {
    fill = palette.primary;
    stroke = palette.primary;
  } else if (state === "outline") {
    stroke = palette.black;
  }

  return (
    <SvgIcon width={width} height={height}>
      <svg
        viewBox={`-1 -1 ${width + 2} ${height + 2}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.9107 0C13.8826 0 12.1069 0.925218 11 2.48913C9.89312 0.925218 8.11741 0 6.08929 0C4.47487 0.00193039 2.92709 0.683141 1.78552 1.89418C0.643952 3.10522 0.00181965 4.74719 0 6.45986C0 13.7532 10.1937 19.6567 10.6278 19.9005C10.7422 19.9658 10.8701 20 11 20C11.1299 20 11.2578 19.9658 11.3722 19.9005C11.8063 19.6567 22 13.7532 22 6.45986C21.9982 4.74719 21.3561 3.10522 20.2145 1.89418C19.0729 0.683141 17.5251 0.00193039 15.9107 0Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};

export default HeartIcon;
