import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  Button,
  Link,
  Typography,
  InputAdornment,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import { Auth, I18n } from "aws-amplify";
import { SignUp } from "aws-amplify-react";
import classNames from "classnames";

import ConnectAuth from "../connectors/connect-auth";
import SignInWithFacebook from "./auth-components/sign-in-with-facebook";
import SignInWithGoogle from "./auth-components/sign-in-with-google";
import SignInWithApple from "./auth-components/sign-in-with-apple";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Capacitor } from "@capacitor/core";
import { clearSignIn } from "../actions/athletes-action";
import { connect } from "react-redux";

const styles = (theme) => ({
  container: {
    padding: "0 20px",
  },
  logoImg: {
    width: 112,
  },
  topTitle: {
    color: "#282828",
    fontFamily: "Montserrat",
    fontSize: 35,
    fontWeight: 600,
  },
  topSubTitle: {
    color: "#282828",
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: 400,
    marginBottom: 20,
    margin: 20,
  },
  paper: {
    ...(theme.formSection || {}),
    padding: "10px 0 0 0",
    width: 320,
    minWidth: 240,
    margin: "auto",
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  title: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imgTitle: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    borderRadius: 21,
    margin: 16,
    width: "calc(100% - 32px);",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },
  black: {
    color: theme.palette.common.black,
  },
  section: {
    marginTop: 8,
    marginBottom: 20,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "gray",
  },
  TextField: {
    margin: "10px 0",
  },
  visibilityIcon: {
    [theme.breakpoints.up("sm")]: {
      color: "black",
    },
  },
  fieldsContainer: {
    padding: "0 40px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 25px 15px",
    },
  },
  button: {
    marginTop: 20,
    textTransform: "none",
  },
  checkboxLabel: {
    color: theme.palette.text.primary,
  },
  notchedOutline: {},
  focused: {},
  input: {
    textAlign: "center",
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "1.2em",
    paddingRight: 14,
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.common.black,
    },
  },
  footerLink: {
    marginTop: 10,
  },
  footer: {
    marginTop: 10,
    textAlign: "center",
    paddingRight: theme.spacing.unit * 2.5,
    paddingLeft: theme.spacing.unit * 2.5,
  },
  submitButton: {
    marginTop: 10,
    boxShadow: "none",
    borderRadius: 12,
    float: "right",
    marginBottom: 10,
  },
  termsText: {
    fontSize: 10,
    marginTop: 10,
  },
  termsLink: {
    marginLeft: 5,
    fontSize: 10,
  },
  bottomText: {
    marginTop: 0,
  },

  dashboardPreview: {
    position: "absolute",
    top: "50%",
    left: "15%",
    transform: "translate(-40%, -10%)",
    width: "30%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  playButtonContanier: {
    position: "intial",
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
  },
  playButton: {
    position: "absolute",
    top: "52%",
    left: "14%",
    width: "7%",
    [theme.breakpoints.down("md")]: {
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "50%",
      width: "50px",
      position: "absolute",
    },
  },
  linearProgress: {
    marginTop: 15,
    marginBottom: 15,
  },
  hidden: {
    opacity: 0,
  },
  videoContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 560,
    height: 315,

    [theme.breakpoints.down("md")]: {
      width: 355,
      height: 200,
    },
  },
  video: {
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: 10,
  },
  error: {
    fontSize: 12,
    color: "red",
    fontWeight: 400,
    lineHeight: "18px",
    margin: "10px 0",
  },

  loginText: {
    marginTop: 20,
    marginBottom: 0,
    color: "gray",
  },
  loginLink: {
    marginLeft: 10,
    cursor: "pointer",
  },

  orCont: {
    display: "flex",
    alignItems: "center",
  },

  hrL: {
    flexGrow: 1,
    marginRight: 20,
    border: 0,
    borderTop: "1px solid #949494",
  },

  hrR: {
    flexGrow: 1,
    marginLeft: 20,
    border: 0,
    borderTop: "1px solid #949494",
  },
});

class CustomizedSignUp extends SignUp {
  constructor(props) {
    super(props);
    this.state = {
      isAccepted: true,
      firstName: "",
      email: "",
      password: "",
      showPassword: false,
      loading: false,
      openVideo: false,
    };
    this._validAuthStates = ["signUp"];
  }

  handleSignUp = (event) => {
    event.preventDefault();
    const { firstName, email, password } = this.state;
    this.setState({
      ...this.state,
      loading: true,
    });
    const signup_info = {
      username: email && email.toLowerCase(),
      password: password,
      attributes: {
        email: email && email.toLowerCase(),
        name: firstName,
      },
    };

    Auth.signUp(signup_info)
      .then((data) => {
        this.setState({
          ...this.state,
          loading: false,
        });
        const { onStateChange } = this.props;
        if (onStateChange) {
          const { user } = data;
          if (user) {
            const { username } = user;
            if (username) {
              const userAgent =
                navigator.userAgent || navigator.vendor || window.opera;
              if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                setTimeout(() => window.scroll(0, 0), 500);
              }
              onStateChange("confirmSignUp", username);
            }
          }
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          loading: false,
        });
        this.error(err);
      });
  };

  handleInputChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { id, value } = target;
        if (id) {
          this.setState({
            [id]: value || "",
          });
        }
      }
    }
  };

  handleSignIn = () => {
    this.props.dispatch(clearSignIn());
    const { onStateChange, setAuthPage } = this.props;
    if (onStateChange) {
      onStateChange("signIn");
      setAuthPage("sign-in");
    }
  };

  showComponent() {
    const { classes } = this.props;
    const { loading, showPassword } = this.state;

    const { isAccepted, firstName, email, password } = this.state;
    return (
      <form
        className={classes.container}
        onSubmit={isAccepted ? this.handleSignUp : null}
      >
        <Typography variant="h1" className={classes.topTitle}>
          {I18n.get("Sign up")}
        </Typography>

        <Typography variant="body1" className={classNames(classes.loginText)}>
          {I18n.get("Already have an account?")}
          <Link
            className={classes.loginLink}
            onClick={() => {
              this.handleSignIn();
            }}
          >
            {I18n.get("Log in")}
          </Link>
        </Typography>

        <Paper className={classes.paper} elevation={0}>
          <div className={classes.innerWrapper}>
            <SignInWithGoogle
              disabled={loading}
              label={I18n.get("Continue with Google")}
              withMargin
            />

            <SignInWithFacebook
              disabled={loading}
              label={I18n.get("Continue with Facebook")}
              withMargin
            />

            <SignInWithApple
              disabled={loading}
              label={I18n.get("Continue with Apple")}
              withMargin
            />
            <div className={classes.section}>
              <div className={classes.orCont}>
                <hr className={classes.hrL}></hr>
                <Typography variant="h2" className={classes.subTitle}>
                  {I18n.get("Or")}
                </Typography>
                <hr className={classes.hrR}></hr>
              </div>
            </div>
            <TextField
              id="firstName"
              value={firstName}
              label={I18n.get("Name")}
              type="text"
              fullWidth
              required
              className={classes.TextField}
              onChange={this.handleInputChange}
              variant="outlined"
            />
            <TextField
              id="email"
              value={email}
              type="email"
              fullWidth
              required
              className={classes.TextField}
              onChange={this.handleInputChange}
              label={I18n.get("Email")}
              variant="outlined"
            />
            <TextField
              id="password"
              value={password}
              label={I18n.get("Password")}
              type={showPassword ? "text" : "password"}
              fullWidth
              required
              className={classes.TextField}
              onChange={this.handleInputChange}
              color="primary"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() =>
                        this.setState({ showPassword: !showPassword })
                      }
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                autoFocus={!Capacitor.isNativePlatform()}
                size="large"
                type="submit"
                className={classes.submitButton}
                disabled={!isAccepted || loading}
              >
                {I18n.get("Sign up")}
              </Button>
            </div>
          </div>
          <Typography variant="body1" className={classNames(classes.termsText)}>
            {I18n.get("By signing up for Vert.run you agree to the")}
            <Link
              href="https://vert.run/terms-of-use/"
              target="_blank"
              className={classes.termsLink}
            >
              {I18n.get("terms and conditions")}
            </Link>
          </Typography>
          <Typography
            variant="body1"
            className={classNames(classes.termsText, classes.bottomText)}
          >
            {I18n.get("View our")}
            <Link
              href="https://vert.run/eula/"
              target="_blank"
              className={classes.termsLink}
            >
              {I18n.get("privacy policy & EULA")}
            </Link>
          </Typography>

          <LinearProgress
            color="primary"
            className={classNames(classes.linearProgress, {
              [classes.hidden]: !loading,
            })}
            variant="indeterminate"
          />
        </Paper>
      </form>
    );
  }
}

CustomizedSignUp.propTypes = {
  classes: PropTypes.object.isRequired,
  setAuthPage: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    auth: store.auth,
  };
})(ConnectAuth(withStyles(styles, { withTheme: true })(CustomizedSignUp)));
