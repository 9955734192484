import { connect } from "react-redux";

import {
  getLatestSubscription,
  setErrorMessage as setSubscriptionErrorMessage,
  clearErrorMessage as clearSubscriptionErrorMessage,
  setSuccessMessage as setSubscriptionSuccessMessage,
  clearSuccessMessage as clearSubscriptionSuccessMessage,
} from "../actions/subscriptions-action";

const mapState = (state) => ({
  subscriptions: state.subscriptions,
});

const mapDispatch = {
  getLatestSubscription,
  setSubscriptionErrorMessage,
  clearSubscriptionErrorMessage,
  setSubscriptionSuccessMessage,
  clearSubscriptionSuccessMessage,
};

const ConnectSubscriptions = connect(mapState, mapDispatch);

export default ConnectSubscriptions;
