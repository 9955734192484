const DifficultyLevels = {
  "1": "01",
  "2": "02",
  "3": "03",
};

export default {
  Items: DifficultyLevels,
  itemsArray: Object.keys(DifficultyLevels).map((key) => {
    return {
      text: DifficultyLevels[key],
      value: key,
    };
  }),
};
