import { API } from "aws-amplify";
import bugsnagClient from "../lib/bugsnag-client";

class NotificationsService {
  static API_NAME = "apinotifications";
  static NOTIFICATIONS_LIST_PATH = "/notifications";
  static NOTIFICATIONS_PATCH_PATH = "/notifications/:cognito_user_sub";
  static NOTIFICATIONS_CREATE_PATH = "/notifications";

  static async getNotifications(loadUnreadOnly = false) {
    let myInit = {
      // OPTIONAL
      queryStringParameters: { loadUnreadOnly },
    };
    const response = await API.get(
      this.API_NAME,
      this.NOTIFICATIONS_LIST_PATH,
      myInit,
    );
    const { success, result, error } = response;

    if (success) return result;
    else if (error) {
      if (typeof error === typeof "") throw new Error(error);
      else throw error;
    }
  }

  static async notificationPatch(notification) {
    if (notification) {
      const { cognito_user_sub, created_at } = notification;
      if (cognito_user_sub && created_at) {
        const myInit = {
          body: { ...notification },
        };
        const response = await API.patch(
          this.API_NAME,
          this.NOTIFICATIONS_PATCH_PATH.replace(
            ":cognito_user_sub",
            cognito_user_sub,
          ),
          myInit,
        );
        const { success, result, error } = response;
        if (success) return result;
        else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      } else throw new Error("Invalid Keys");
    } else throw new Error("Invalid Parameter");
  }

  static async notificationsCreate(notifications) {
    if (notifications && notifications.length > 0) {
      const myInit = {
        body: notifications,
      };
      try {
        const response = await API.post(
          this.API_NAME,
          this.NOTIFICATIONS_CREATE_PATH,
          myInit,
        );
        const { success, result, error } = response;
        if (success) return result;
        else if (error) {
          if (typeof error === typeof "")
            bugsnagClient.notify(new Error(error));
          else bugsnagClient.notify(error);
        }
      } catch (exp) {
        bugsnagClient.notify(exp);
      }
    } else throw new Error("Invalid Parameter");
  }
}
export default NotificationsService;
