import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ReorderIcon from "@material-ui/icons/Reorder";
import FilterSelect from "../../common/filter-select";
import WorkoutActionTypes from "../../../constants/workout-action-types";
import WorkoutActionVariables from "../../../constants/workout-action-variables";
import {
  Button,
  ButtonGroup,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import WorkoutStep from "./workout-step";
import { ReactSortable } from "react-sortablejs";
import DeleteIcon from "@material-ui/icons/Delete";
import FontAwesomeIcon from "../../common/font-awesome-icon";

const styles = (theme) => ({
  container: {
    border: "1px solid #c4c4c4",
    borderRadius: 5,
    background: "#fafafa",
    marginTop: "10px",
  },

  stepContainer: {
    display: "flex",
    alignItems: "center",
    background: "#fff",
    padding: 10,
  },

  stepsContainer: {
    padding: 10,
  },

  timesInput: {
    width: 50,
    height: 37,
    borderRadius: 0,
  },

  handle: {
    cursor: "grab",
  },

  smallMargin: {
    margin: "0 10px",
  },

  smallMarginAndLeft: {
    margin: "0 10px 0 auto",
  },
});

const WorkoutRepeat = ({
  classes,
  onChangeRepeatTimes,
  repeat,
  onAddStep,
  onChangeStepSelect,
  onSetParametrizedWorkout,
  level,
  onDeleteStep,
  onDuplicateStep,
  showActions = false,
}) => {
  const { times, id, steps } = repeat || {};

  const handleMinus = () => {
    onChangeRepeatTimes(id, times - 1, level);
  };

  const handlePlus = () => {
    onChangeRepeatTimes(id, times + 1, level);
  };

  return (
    <div className={classes.container}>
      <div className={classes.stepContainer}>
        <div className="handle">
          <ReorderIcon />
        </div>

        <ButtonGroup
          disableElevation
          variant="contained"
          aria-label="Disabled elevation buttons"
        >
          <Button variant="outlined" onClick={handleMinus}>
            -
          </Button>
          <TextField
            value={times}
            margin="none"
            variant="outlined"
            onChange={onChangeRepeatTimes}
            InputProps={{
              classes: { root: classes.timesInput },
            }}
            disabled
          />
          <Button variant="outlined" onClick={handlePlus}>
            +
          </Button>
        </ButtonGroup>

        {level === 2 || showActions ? (
          <Tooltip title="Delete">
            <IconButton
              aria-label="Delete"
              className={classes.smallMarginAndLeft}
              onClick={() => onDeleteStep(id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}

        {level === 2 || showActions ? (
          <Tooltip title="Duplicate">
            <IconButton
              aria-label="Duplicate"
              className={classes.smallMargin}
              onClick={() => onDuplicateStep(repeat)}
            >
              <FontAwesomeIcon variant="regular" icon="copy" />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}

        {level === 2 || showActions ? (
          <Button
            variant="outlined"
            className={classes.smallMargin}
            onClick={() => onAddStep(id)}
          >
            + Add Step
          </Button>
        ) : (
          ""
        )}
      </div>

      {steps && steps.length ? (
        <div className={classes.stepsContainer}>
          <ReactSortable
            list={steps}
            setList={(newState) => onSetParametrizedWorkout(newState, id)}
            handle={`.handle-${id}`}
          >
            {steps.map((paramStep) => (
              <WorkoutStep
                key={paramStep.id}
                handleClass={`handle-${id}`}
                step={paramStep}
                repeatId={id}
                onChangeStepSelect={onChangeStepSelect}
                level={level}
                onDeleteStep={onDeleteStep}
                onDuplicateStep={onDuplicateStep}
                showActions={showActions}
              />
            ))}
          </ReactSortable>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

WorkoutRepeat.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WorkoutRepeat);
