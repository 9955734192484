import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Dialog,
  Slide,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import usePost from "../../../hooks/usePost";
import { useTranslation } from "react-i18next";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import "react-loading-skeleton/dist/skeleton.css";
import { Skeleton } from "@mui/material";
import ReactPlayer from "react-player/lazy";
import useFavorites from "../../../hooks/useFavorites";
import RelatedVideos from "../../../components/common/v2/RelatedVideos";
import getTimeIcon from "../../../components/common/getTimeIcon";
import { IconBarbell, IconBook, IconFlame } from "@tabler/icons-react";
import post from "../../../components/trail-head/post";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  dialog: {},
  parentBox: {
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    maxWidth: "100%",
    padding: "20px",
    gap: "24px",
    flex: 1,
  },
  skeletonContainer: {
    flex: 1,
    gap: "24px",
    display: "flex",
    flexDirection: "column",
  },
  skeletonRow: {
    display: "flex",
    gap: "12px",
    flexDirection: "row",
  },
  skeletonColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  videoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  titleContainer: {
    display: "flex",
    gap: "12px",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  articletitleContainer: {
    display: "flex",
    gap: "8px",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  title: {
    fontFamily: "Karla",
    color: "#12151B",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "30px",
  },
  description: {
    fontFamily: "Karla",
    color: "#12151B",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
  },
  workout_level: {
    color: "white",
    fontFamily: "Karla",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "21px",
    height: "28px",
    alignContent: "center",
    padding: "4px 8px",
    borderRadius: "4px",
    whiteSpace: "nowrap",
  },
  detailsTitle: {
    fontFamily: "Oswald",
    fontWeight: "500",
    fontSize: "18px",
    letterSpacing: "1px",
    lineHeight: "22.5px",
    color: "#0B2B5C",
  },
  iconDetails: {
    display: "flex",
    gap: "4px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  readiconDetails: {
    display: "flex",
    gap: "4px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  details: {
    color: "#6D6F71",
    fontFamily: "Karla",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
  },
  infoContainer: {
    display: "flex",
    gap: "12px",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  infoItem: {
    gap: "4px",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
  },
  image: {
    width: "100%",
    height: "250px",
    objectFit: "cover",
    objectPosition: "center",
    borderRadius: "8px",
  },
});

const ExploreDetails = () => {
  const { t, i18n } = useTranslation("explore");
  const { language } = i18n;
  const history = useHistory();
  const { toggleFavorites, isFavorite } = useFavorites();
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(true);
  const {
    handleGetExplorePost,
    loadingExplorePost,
    currentExplorePost,
  } = usePost();
  const classes = useStyles();

  useEffect(() => {
    if (id) {
      handleGetExplorePost(id);
    }
  }, [id]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleTransitionEnd = () => {
    history.goBack();
  };

  const getVideoUrl = () => {
    if (!currentExplorePost) return null;
    if (currentExplorePost.video && currentExplorePost.video[language]) {
      return currentExplorePost.video[language];
    } else if (currentExplorePost.video && currentExplorePost.video["en"]) {
      return currentExplorePost.video["en"];
    } else {
      return currentExplorePost.media || null;
    }
  };
  const getWorkoutLevelColor = (level) => {
    switch (level.toLowerCase()) {
      case "Beginner":
        return "#6BB488";
      case "Intermediate":
        return "#FFB845";
      case "Advanced":
        return "#E02758";
      case "All levels":
        return "#6BB488";
      default:
        return "#6BB488";
    }
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionProps={{ onExited: handleTransitionEnd }}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <ToolbarReturnBack
        goBack={handleCloseDialog}
        title={t(
          currentExplorePost &&
            currentExplorePost.media_type.toLowerCase() === "video"
            ? "explore.video.title"
            : currentExplorePost &&
              currentExplorePost.media_type.toLowerCase() === "article"
            ? "explore.article.title"
            : "explore.details.title",
        )}
        withBox={false}
        customItem={
          currentExplorePost ? (
            <IconButton
              className={classes.favoriteButton}
              size="small"
              aria-label="add to favorites"
              onClick={(e) => {
                e.stopPropagation();
                toggleFavorites("video", currentExplorePost.id);
              }}
            >
              {isFavorite("video", currentExplorePost.id) ? (
                <StarIcon color="primary" />
              ) : (
                <StarBorderIcon style={{ color: "black" }} />
              )}
            </IconButton>
          ) : (
            <Box width={"30px"} />
          )
        }
      />
      {currentExplorePost &&
        currentExplorePost.media_type.toLowerCase() === "video" && (
          <Box className={classes.parentBox}>
            {loadingExplorePost || !currentExplorePost ? (
              <Box className={classes.skeletonContainer}>
                <Skeleton variant="rounded" height={300} />
                <Box className={classes.skeletonRow}>
                  <Skeleton
                    variant="rectangular"
                    height={30}
                    style={{ flex: 2 }}
                  />
                  <Skeleton variant="rounded" height={30} style={{ flex: 1 }} />
                </Box>
                <Box className={classes.skeletonRow}>
                  <Skeleton
                    variant="rectangular"
                    height={60}
                    style={{ flex: 1 }}
                  />
                  <Skeleton variant="rounded" height={60} style={{ flex: 1 }} />
                  <Skeleton variant="rounded" height={60} style={{ flex: 1 }} />
                </Box>
                <Box className={classes.skeletonColumn}>
                  <Skeleton variant="rounded" height={15} />
                  <Skeleton variant="rounded" height={15} />
                  <Skeleton variant="rounded" height={15} />
                  <Skeleton variant="rounded" height={15} />
                  <Skeleton variant="rounded" height={15} width="40%" />
                </Box>
                <Skeleton variant="rounded" height={30} width="60%" />
              </Box>
            ) : (
              <Box className={classes.videoContainer}>
                <ReactPlayer
                  playing={false}
                  url={getVideoUrl()}
                  light={
                    <img
                      src={currentExplorePost.image_url}
                      className={classes.image}
                    />
                  }
                  controls={true}
                  width={"100%"}
                  height={"100%"}
                  className={classes.player}
                />
                <Box className={classes.titleContainer}>
                  <Typography className={classes.title}>
                    {currentExplorePost.title[language] ||
                      currentExplorePost.title["en"]}
                  </Typography>
                  {currentExplorePost.workout_level && (
                    <Typography
                      className={classes.workout_level}
                      style={{
                        backgroundColor: getWorkoutLevelColor(
                          currentExplorePost.workout_level[language] ||
                            currentExplorePost.workout_level["en"],
                        ),
                      }}
                    >
                      {currentExplorePost.workout_level[language] ||
                        currentExplorePost.workout_level["en"]}
                    </Typography>
                  )}
                </Box>
                <Box className={classes.infoContainer}>
                  <Box className={classes.infoItem}>
                    <Typography className={classes.detailsTitle}>
                      {currentExplorePost.time}
                    </Typography>
                    <Box className={classes.iconDetails}>
                      {getTimeIcon(
                        currentExplorePost.time ||
                          currentExplorePost.workout_time,
                        "18px",
                      )}
                      <Typography className={classes.details}>
                        {t("video.details.duration")}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider
                    orientation="vertical"
                    variant="fullWidth"
                    flexItem
                  />
                  <Box className={classes.infoItem}>
                    <Typography className={classes.detailsTitle}>
                      {currentExplorePost.workout_type[language] ||
                        currentExplorePost.workout_type["en"]}
                    </Typography>
                    <Box className={classes.iconDetails}>
                      <IconBarbell color="#6D6F71" size={"18px"} stroke={1.5} />
                      <Typography className={classes.details}>
                        {t("video.details.type")}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider
                    orientation="vertical"
                    variant="fullWidth"
                    flexItem
                  />
                  <Box className={classes.infoItem}>
                    <Typography className={classes.detailsTitle}>
                      {currentExplorePost.workout_calories}
                    </Typography>
                    <Box className={classes.iconDetails}>
                      <IconFlame color="#6D6F71" size={"18px"} stroke={1.5} />
                      <Typography className={classes.details}>
                        {t("video.details.calories")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html:
                      currentExplorePost.text[language] ||
                      currentExplorePost.text["en"],
                  }}
                />
                <RelatedVideos />
              </Box>
            )}
          </Box>
        )}
      {currentExplorePost &&
        currentExplorePost.media_type.toLowerCase() === "article" && (
          <Box className={classes.parentBox}>
            {loadingExplorePost || !currentExplorePost ? (
              <Box className={classes.skeletonContainer}>
                <Skeleton variant="rounded" height={300} />
                <Box className={classes.skeletonRow}>
                  <Skeleton
                    variant="rectangular"
                    height={30}
                    style={{ flex: 2 }}
                  />
                  <Skeleton variant="rounded" height={30} style={{ flex: 1 }} />
                </Box>
                <Box className={classes.skeletonRow}>
                  <Skeleton
                    variant="rectangular"
                    height={60}
                    style={{ flex: 1 }}
                  />
                  <Skeleton variant="rounded" height={60} style={{ flex: 1 }} />
                  <Skeleton variant="rounded" height={60} style={{ flex: 1 }} />
                </Box>
                <Box className={classes.skeletonColumn}>
                  <Skeleton variant="rounded" height={15} />
                  <Skeleton variant="rounded" height={15} />
                  <Skeleton variant="rounded" height={15} />
                  <Skeleton variant="rounded" height={15} />
                  <Skeleton variant="rounded" height={15} width="40%" />
                </Box>
                <Skeleton variant="rounded" height={30} width="60%" />
              </Box>
            ) : (
              <Box className={classes.videoContainer}>
                <img
                  src={currentExplorePost.image_url}
                  className={classes.image}
                />
                <Box className={classes.articletitleContainer}>
                  <Box className={classes.readiconDetails}>
                    <IconBook color="#6D6F71" size={"18px"} stroke={2} />
                    <Typography className={classes.details}>
                      {t("explore.details.duration", {
                        time: currentExplorePost.time,
                      })}
                    </Typography>
                  </Box>
                  <Typography className={classes.title}>
                    {currentExplorePost.title[language] ||
                      currentExplorePost.title["en"]}
                  </Typography>
                  {currentExplorePost.workout_level && (
                    <Typography
                      className={classes.workout_level}
                      style={{
                        backgroundColor: getWorkoutLevelColor(
                          currentExplorePost.workout_level[language] ||
                            currentExplorePost.workout_level["en"],
                        ),
                      }}
                    >
                      {currentExplorePost.workout_level[language] ||
                        currentExplorePost.workout_level["en"]}
                    </Typography>
                  )}
                </Box>
                <Typography
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html:
                      currentExplorePost.text[language] ||
                      currentExplorePost.text["en"],
                  }}
                />
              </Box>
            )}
          </Box>
        )}
    </Dialog>
  );
};

export default ExploreDetails;
