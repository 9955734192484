import React, { useEffect, useState, forwardRef } from "react";
import {
  Typography,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  withMobileDialog,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { dateFormat } from "../../../../../lib/date-helper";
import { withStyles } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import SnackBarMessageDisplay from "../../../../common/snack-bar-message-display";
import { getAthlete } from "../../../../../actions/trail-head-action";
import { updatePlan } from "../../../../../actions/user-plans-action";
import { useDispatch, useSelector } from "react-redux";
import unitsHook from "../../../../../hooks/unitsHook";
import { convertUnits, round } from "../../../../../lib/units-helper";
import { IMaskInput } from "react-imask";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteConfirmationDialog from "../../../../../components/common/delete-confirmation-dialog";
import classNames from "classnames";
import { logEvent, setEventsMetadata } from "../../../../../lib/events-helper";

const styles = (theme) => ({
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing.unit * 2,
  },
  container: {
    backgroundColor: "#FFFFFF",
    padding: "20px",
    borderRadius: 18,
    boxSizing: "border-box",
  },
  title: {
    fontWeight: 500,
  },
  boldText: {
    fontWeight: "bold",
    paddingRight: theme.spacing.unit * 0.5,
  },
  white: {
    color: theme.palette.common.white,
  },
  smallerFontSize: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller",
    },
  },
  dialogActions: {
    justifyContent: "center",
    marginBottom: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    cursor: "pointer",
  },
  leftButtons: {
    display: "flex",
    justifyContent: "flex-start",
  },
  rightMargin: {
    marginRight: theme.spacing.unit * 2,
  },
  comment: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing.unit,
    },
  },
  raceDateContainer: {
    display: "flex",
    height: "100%",
    paddingTop: theme.spacing.unit,
    marginTop: 24,
  },
  text: {
    color: theme.palette.text.secondaryDark,
    marginTop: "auto",
    fontSize: "small",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      marginTop: 0,
    },
  },
  raceName: {
    fontWeight: 600,
    fontSize: 14,
    color: "#5F5F5F",
    margin: "3px 0px",
  },
  raceBottomTitle: {
    fontWeight: 500,
    fontSize: 14,
    color: "#5F5F5F",
  },
  raceBottomText: {
    fontWeight: 200,
    fontSize: 16,
    color: "#3F3F3F",
  },
  raceLocation: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePriorityContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
  racePriority: {
    fontSize: 14,
    color: "#5F5F5F",
  },
  racePrioritySelected: {
    margin: "0 5px",
    background: "#1E629B",
    borderRadius: "100%",
    fontWeight: 900,
    fontSize: 21,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "34px",
    textAlign: "center",
  },
  racePriorityUnselected: {
    margin: "0 5px",
    background: "rgba(30, 98, 155, 0.12)",
    borderRadius: "100%",
    fontWeight: 900,
    fontSize: 21,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "34px",
    textAlign: "center",
  },
  buttons: {
    borderRadius: 50,
    textTransform: "uppercase",
    marginLeft: "auto",
    display: "block",
  },
  buttonsCont: {
    display: "flex",
  },

  snackBar: {
    zIndex: 9999,
    top: "5%",
    right: "5%",
  },

  customWidth: {
    minWidth: 280,
    [theme.breakpoints.down("md")]: {
      minWidth: "auto",
      width: "auto",
    },
  },

  dialogPaper: {
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
});

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00:00"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const AddRaceForm = ({
  t,
  classes,
  isOpen,
  openOrClose,
  race = {},
  edit = false,
  currentIndex,
  customWidth = null,
}) => {
  const dispatch = useDispatch();
  const {
    userPlans: { currentPlan, updating },
    trailHead,
  } = useSelector((state) => state);

  const { selectedAthlete, loadingAthlete } = trailHead;
  const { cognito_user_sub } = selectedAthlete || {};

  const [dontAction, setDontAction] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const currentUnit = unitsHook();

  const initialValues = {
    distance: "",
    elevation: "",
    time: "",
    date: "",
  };

  const [values, setValues] = useState(initialValues);
  const [errorDialog, setErrorDialog] = useState({
    open: false,
    message: "",
  });

  const [successDialog, setSuccessDialog] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    const { id, ...others } = race;
    if (Object.keys(race).length && edit) setValues({ ...values, ...others });
  }, [race]);

  const showError = () => {
    setErrorDialog({
      open: true,
      message: "All fields are required",
    });

    setTimeout(() => {
      setErrorDialog({
        ...errorDialog,
        open: false,
      });
    }, 3000);
  };

  const showSucces = (message) => {
    setSuccessDialog({
      open: true,
      message,
    });

    setTimeout(() => {
      setSuccessDialog({
        ...successDialog,
        open: false,
      });
    }, 3000);
  };

  const saveUserEpicRaces = async () => {
    const allData = { ...values, ...race };

    if (currentUnit == "miles") {
      allData["distance"] = convertUnits(
        allData["distance"],
        "miles",
        "kms",
        "distance",
      );

      allData["elevation"] = convertUnits(
        allData["elevation"],
        "miles",
        "kms",
        "elevation",
      );
    }

    const localEpicRaces = currentPlan.epicRaces
      ? [...currentPlan.epicRaces]
      : [];

    localEpicRaces.push(allData);

    await dispatch(
      updatePlan(
        {
          cognito_user_sub: currentPlan.cognito_user_sub,
          epicRaces: localEpicRaces,
        },
        false,
      ),
    );

    await dispatch(getAthlete(cognito_user_sub));

    clearForm();
    showSucces(t("Successfully created", { ns: "messages" }));
    setDontAction(false);
  };

  const onSave = () => {
    setEventsMetadata({ customer_id: currentPlan.cognito_user_sub });
    logEvent("add_race");
    setDontAction(true);
    const fieldsArray = Object.values(values);
    const isValid = fieldsArray.every((item) => item.length);
    if (!isValid) {
      setDontAction(false);
      showError();
      return;
    }

    !edit ? saveUserEpicRaces() : onEdit();
  };

  const onEdit = async () => {
    if (currentUnit == "miles") {
      values["distance"] = convertUnits(
        values["distance"],
        "miles",
        "kms",
        "distance",
      );

      values["elevation"] = convertUnits(
        values["elevation"],
        "miles",
        "kms",
        "elevation",
      );
    }

    const localEpicRaces = currentPlan.epicRaces;
    localEpicRaces[currentIndex] = values;

    await dispatch(
      updatePlan(
        {
          cognito_user_sub: currentPlan.cognito_user_sub,
          epicRaces: localEpicRaces,
        },
        false,
      ),
    );

    await dispatch(getAthlete(cognito_user_sub));
    showSucces(t("Successfully edited", { ns: "messages" }));
    setDontAction(false);
    clearForm();
  };

  const handleRaceDateChange = (momentObject) => {
    if (momentObject) {
      setValues({
        ...values,
        date: momentObject.format(dateFormat),
      });
    }
  };

  const handleTextChange = (event) => {
    const { name, value } = event.target;

    if (
      name === "distance" &&
      !isNumber(value) &&
      (!values.distance || !values.distance.length)
    )
      return setValues({
        ...values,
        ["distance"]: "",
      });

    if (name === "distance" && !isNumber(value)) return;

    if (
      name === "elevation" &&
      !isNumber(value) &&
      (!values.elevation || !values.elevation.length)
    )
      return setValues({
        ...values,
        ["elevation"]: "",
      });

    if (name === "elevation" && !isNumber(value)) return;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const isNumber = (number) =>
    /^\d{0,10}$|(?=^.{0,10}$)^\d+\.\d{0,10}$/gm.test(number);

  const clearForm = () => {
    openOrClose();
    setValues({
      ...values,
      ["date"]: "",
      ["time"]: "",
      ["distance"]: "",
      ["elevation"]: "",
    });
  };

  const deleteRace = async () => {
    setDontAction(true);
    const localEpicRaces = currentPlan.epicRaces;
    localEpicRaces.splice(currentIndex, 1);

    await dispatch(
      updatePlan(
        {
          cognito_user_sub: currentPlan.cognito_user_sub,
          epicRaces: localEpicRaces,
        },
        false,
      ),
    );

    await dispatch(getAthlete(cognito_user_sub));
    showSucces(t("Successfully deleted", { ns: "messages" }));
    setShowConfirm(false);
    clearForm();
    setDontAction(false);
  };

  const openDialog = () => setShowConfirm(true);

  return (
    <>
      <SnackBarMessageDisplay
        variant="error"
        open={errorDialog.open}
        errorMessage={errorDialog.message}
      />
      <SnackBarMessageDisplay
        variant="success"
        open={successDialog.open}
        successMessage={successDialog.message}
      />
      <Dialog
        open={isOpen}
        maxWidth="md"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent id="addeditdialog" className={classes.noPadding}>
          <div>
            <div
              className={classNames(
                classes.container,
                customWidth ? classes.customWidth : null,
              )}
            >
              <Typography variant="h6" className={classes.raceName}>
                {t("Add Race")}
              </Typography>

              <TextField
                fullWidth
                id="name"
                name="name"
                margin="normal"
                value={race.name}
                readOnly
                label={t("Race Name")}
                inputProps={{ maxLength: 80 }}
                InputLabelProps={{
                  classes: { root: classes.smallerFontSize },
                }}
              />

              <div>
                <div className={classes.raceBottomItem}>
                  <TextField
                    fullWidth
                    id="distance"
                    name="distance"
                    margin="normal"
                    value={
                      values.distance
                        ? edit
                          ? round(values.distance, 1)
                          : values.distance
                        : ""
                    }
                    label={
                      !currentUnit || currentUnit === "kms"
                        ? t("Race Distance (in Kms)")
                        : t("Race Distance (in miles)")
                    }
                    inputProps={{ maxLength: 10 }}
                    onChange={handleTextChange}
                    InputLabelProps={{
                      classes: { root: classes.smallerFontSize },
                    }}
                  />
                </div>
                <div className={classes.raceBottomItem}>
                  <TextField
                    fullWidth
                    id="elevation"
                    name="elevation"
                    margin="normal"
                    inputProps={{ maxLength: 10 }}
                    value={
                      values.elevation
                        ? edit
                          ? round(values.elevation, 1)
                          : values.elevation
                        : ""
                    }
                    label={
                      !currentUnit || currentUnit === "kms"
                        ? t("Race Elevation (in meters)")
                        : t("Race Elevation (in feet)")
                    }
                    onChange={handleTextChange}
                    InputLabelProps={{
                      classes: { root: classes.smallerFontSize },
                    }}
                  />
                </div>
                <div className={classes.raceBottomItem}>
                  <TextField
                    fullWidth
                    id="time"
                    name="time"
                    margin="normal"
                    label={!edit && t("Race time")}
                    value={values.time}
                    placeholder={"00:00"}
                    onChange={handleTextChange}
                    InputLabelProps={{
                      classes: { root: classes.smallerFontSize },
                    }}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                  />
                </div>
                <div className={classes.raceBottomItem}>
                  <div className={classes.raceDateContainer}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        value={moment(
                          values.date || "",
                          dateFormat,
                        ).toISOString()}
                        format="DD/MM/YYYY"
                        placeholder="DD.MM.AAAA"
                        readOnly={edit ? true : false}
                        mask={(value) =>
                          // handle clearing outside if value can be changed outside of the component
                          value
                            ? [
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]
                            : []
                        }
                        autoOk
                        clearable
                        keyboard
                        fullWidth
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        onChange={handleRaceDateChange}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          {updating || loadingAthlete ? (
            <div>
              <CircularProgress variant="indeterminate" size="25px" />
            </div>
          ) : (
            <Button onClick={!dontAction ? onSave : null} color="primary">
              {!edit ? t("Save") : t("Edit")}
            </Button>
          )}

          <Button onClick={clearForm} color="primary">
            {t("Cancel")}
          </Button>

          {edit && (
            <Button onClick={openDialog} color="primary">
              {t("Delete")}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {showConfirm && (
        <DeleteConfirmationDialog
          entityName="Epic Race"
          onConfirm={!dontAction ? deleteRace : null}
          onCancel={() => setShowConfirm(false)}
        />
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation(["dashboard", "messages"])(
    withWidth()(withMobileDialog()(AddRaceForm)),
  ),
);
