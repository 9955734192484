import React, { useEffect, useState } from "react";
import { Typography, Box, Dialog, Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useActivities from "../../../hooks/useActivities";
import ToolbarReturnBack from "./ToolbarReturnBack";
import { ActivityCard } from "../../profile/Activity/activity-card";
import useCreatePost from "../../../hooks/useCreatePost";
import CommunityActivityShareDialog from "./CommunityActivityShareDialog";
import RaceCardSkeleton from "./RaceCardSkeleton";
import ModalCustom from "./ModalCustom";
import SaveActivity from "../../generals/new-activity-modal";
import ButtonDashed from "./ButtonDashed";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  dialog: {
    display: "flex",
    flexDirection: "column",
    color: "#12151B0D",
  },
  headerBox: {
    flex: "0 0 auto",
    color: "#FEFFFF",
  },
  noContentBox: {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "16px",
    flex: "1 1 auto",
    overflowY: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    backgroundColor: "#FAFAFA",
  },
  heading: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "karla",
    color: "#12151B",
  },
  body: {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "karla",
    color: "#12151B",
  },
});

const CommunityActivitiesDialog = ({ handleOpenDialog, handleCloseDialog }) => {
  const classes = useStyles();
  const { loading, sortedActivities, handleGetActivities } = useActivities();
  const { handleActivityChange } = useCreatePost();
  const { t } = useTranslation("community");
  const [openForm, setOpenForm] = useState(false);
  const [activityClicked, setActivityClicked] = useState(false);

  const cardActions = {
    share: false,
    menu: false,
    mountainIndex: false,
  };

  const handlOpenForm = (open) => {
    setOpenForm(open);
  };

  const handleActivityClick = (activity) => {
    handleActivityChange(activity);
    setActivityClicked(true);
  };

  const handleCloseActivity = (selected) => {
    if (selected) {
      handleCloseDialog(true);
    }
    setActivityClicked(false);
  };

  useEffect(() => {
    handleGetActivities();
  }, []);

  return (
    <Dialog
      fullScreen
      open={handleOpenDialog}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <Box className={classes.headerBox}>
        <ToolbarReturnBack
          goBack={() => handleCloseDialog(false)}
          title={t("community.activity.title")}
        />
      </Box>
      <Box className={classes.contentBox}>
        <Typography className={classes.heading}>
          {t("community.activity.recentActivity")}
        </Typography>
        {loading ? (
          <>
            <RaceCardSkeleton />
            <RaceCardSkeleton />
            <RaceCardSkeleton />
          </>
        ) : sortedActivities && sortedActivities.length !== 0 ? (
          <>
            {sortedActivities.map((activity, index) => (
              <ActivityCard
                key={index}
                data-testid={`select_activity${index + 1}`}
                id={`select_activity${index + 1}`}
                name={activity.name}
                activity={activity}
                handleDetailActivity={() => handleActivityClick(activity)}
                enableActions={cardActions}
              />
            ))}
            <ButtonDashed
              onClick={() => handlOpenForm(true)}
              label={t("community.activity.addActivity")}
              withAddIcon={true}
            />
          </>
        ) : (
          <Box className={classes.noContentBox}>
            <Typography className={classes.body}>
              {t("community.activity.noActivity")}
            </Typography>
            <ButtonDashed
              onClick={() => handlOpenForm(true)}
              label={t("community.activity.addActivity")}
              withAddIcon={true}
            />
          </Box>
        )}
      </Box>
      <CommunityActivityShareDialog
        handleOpenDialog={activityClicked}
        handleCloseDialog={handleCloseActivity}
      />
      <ModalCustom
        fullScreen={false}
        open={openForm}
        onClose={() => handlOpenForm(false)}
        title={t("community.activity.addActivityModal.title")}
      >
        <SaveActivity
          type={"add"}
          activity={null}
          onCancel={() => handlOpenForm(false)}
        />
      </ModalCustom>
    </Dialog>
  );
};

export default CommunityActivitiesDialog;
