import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import classNames from "classnames";
import { blue, red, green, orange, grey } from "@material-ui/core/colors";
import { Avatar } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";

import { WorkoutFilterMapper } from "../../lib/training-workout-filter-mapper";
import EstimatedTimes from "../../constants/estimated-times";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    minHeight: 40,
    padding: 5,
  },
  chip: {
    margin: theme.spacing.unit / 2,
  },
  trainingPeriod: {
    backgroundColor: blue[500],
    color: "#fff",
  },
  difficultyLevel: {
    backgroundColor: grey[500],
    color: "#fff",
  },
  workoutType: {
    backgroundColor: green[500],
    color: "#fff",
  },
  surface: {
    backgroundColor: orange[500],
    color: "#fff",
  },
  estimatedTime: {
    backgroundColor: red[500],
    color: "#fff",
  },
});

class WorkoutFilterChips extends Component {
  render() {
    const {
      classes,
      filters,
      onDeleteFilter,
      onDeleteSearchPhrase,
      searchPhrase,
      level,
    } = this.props;
    const filterKeys = Object.keys(filters);
    return (
      <div className={classes.container}>
        {searchPhrase ? (
          <Chip
            avatar={
              <Avatar>
                <SearchIcon />
              </Avatar>
            }
            label={searchPhrase}
            className={classes.chip}
            onDelete={onDeleteSearchPhrase}
          />
        ) : null}
        {filterKeys.map((key, keyIndex) => {
          if (filters[key] && filters[key].length)
            return filters[key].map((data, index) => {
              return (
                <Chip
                  avatar={<Avatar>{WorkoutFilterMapper(key)}</Avatar>}
                  key={`${keyIndex}-${index}`}
                  label={
                    key === "estimatedTime"
                      ? EstimatedTimes.Items[
                          Array.isArray(data) ? data[2] : data
                        ]
                      : data
                  }
                  onDelete={onDeleteFilter.bind(this, key, data)}
                  className={classNames(classes.chip, classes[key])}
                />
              );
            });
          return null;
        })}
      </div>
    );
  }
}

WorkoutFilterChips.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object,
  onDeleteFilter: PropTypes.func.isRequired,
  onDeleteSearchPhrase: PropTypes.func.isRequired,
  searchPhrase: PropTypes.string,
};

WorkoutFilterChips.defaultProps = {
  filters: [],
};

export default withStyles(styles)(WorkoutFilterChips);
