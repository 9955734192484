import dayjs from "dayjs";
import gsap from "gsap";
import { Box, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import ChatAvatar from "./ChatAvatar";
import ChatReactions from "./ChatReactions";
import { useTranslation } from "react-i18next";
import { palette } from "../../../theme/palette";
import { dateFormat } from "../../../lib/date-helper";
import { DeleteIcon } from "../../icons";
import { ReplyOutlined } from "@material-ui/icons";
import ChatMessageContent from "./ChatMessageContent";
import useChat from "../../../hooks/useChat";

const ChatMessage = ({
  message = {
    senderName: "user",
    text: "Type a message",
    date: dayjs().format(dateFormat),
  },
  sender = false,
  isLastMessageOfSender = false,
  index = null,
  openReaction = false,
  messageSelectedIndex = null,
  handleReactionSelected,
  handleDeleteMessage,
  handleReply,
  onClickMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("chat");
  const { newMessageRef, getPrettyTextMessage } = useChat();

  useEffect(() => {
    if (newMessageRef.current)
      gsap.fromTo(
        newMessageRef.current,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 0.5 },
      );
  }, [message]);

  return (
    <>
      {sender ? (
        <Box
          id={`message-${index}`}
          key={index}
          className={classes.contentSender}
          ref={isLastMessageOfSender ? newMessageRef : null}
        >
          <ChatAvatar message={message} />
          <ChatMessageContent
            propStyles={{
              marginTop: 20,
            }}
            customClass={classes.sender}
            text={getPrettyTextMessage(message)}
            date={message.date}
            replyTo={message.replyTo}
            workout={message.workout}
            options={[
              {
                icon: <DeleteIcon />,
                title: t("chat.buttons.delete"),
                onClick: () => handleDeleteMessage(message.id),
              },
            ]}
          />
        </Box>
      ) : (
        <Box
          id={`message-${message.id}`}
          className={classes.contentReceived}
          key={index}
        >
          <ChatAvatar message={message} />
          <ChatMessageContent
            propStyles={{
              marginTop: 20,
            }}
            onClick={onClickMessage}
            customClass={classes.received}
            text={getPrettyTextMessage(message)}
            date={message.date}
            replyTo={message.replyTo}
            workout={message.workout}
            options={[
              {
                icon: <ReplyOutlined />,
                title: t("chat.buttons.reply"),
                onClick: () => handleReply(message.id),
              },
            ]}
          />
          <ChatReactions
            isOpen={messageSelectedIndex === index && openReaction}
            reactionTo={message}
            onReactionSelected={handleReactionSelected}
          />
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles({
  contentView: {
    flex: "1 1 auto",
    overflowY: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    //position: "fixed",
    backgroundColor: palette.lightGrayOpacity,
    //zIndex: 200,
  },
  contentSender: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: 10,
    gap: 5,
  },
  contentReceived: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    gap: 5,
    position: "relative",
  },
  messageCardHeader: {
    padding: 0,
  },
  messageCardHeaderAction: {
    margin: 0,
  },
  messageCardHeaderTitle: {
    fontWeight: 400,
  },
  message: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    border: "2px 0px 0px 0px",
    maxWidth: 260,
    padding: "8px 12px",
    color: palette.black,
    marginTop: 20,
    boxShadow: "none",
  },
  messageDate: {
    fontWeight: 400,
    fontSize: 14,
    color: palette.deactivated,
  },
  received: {
    backgroundColor: palette.lightPink,
    borderColor: palette.primary,
    borderRadius: "0px 8px 8px 8px",
  },
  sender: {
    backgroundColor: palette.lightBlueHove,
    borderColor: palette.lightBlueHover,
    borderRadius: "8px 0px 8px 8px",
  },
  box: {
    display: "flex",
    padding: "24px 0px",
    boxSizing: "border-box",
    width: "100%",
  },
});

export default ChatMessage;
