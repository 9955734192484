import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";
import SubscriptionOptionsInApp from "../constants/subscription-options-in-app";
import {
  setErrorMessage,
  setMobileProducts,
  addOwnedMobileProduct,
} from "../actions/subscriptions-action";
import { Capacitor } from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

class InAppPurchasesService {
  static isPurchasing = false;
  static callback = null;
  static onStartPurchase = null;
  static onFinishPurchase = null;

  static async initInAppPurchases(sub, dispatch) {
    const instanceID = await FirebaseAnalytics.getAppInstanceId();
    await Purchases.setFirebaseAppInstanceID(instanceID);
    await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
    console.log("Pre Purchases");
    Purchases.configure({
      apiKey:
        Capacitor.getPlatform() === "ios"
          ? process.env.REACT_APP_APPLE_RC_KEY
          : process.env.REACT_APP_GOOGLE_RC_KEY,
      appUserID: sub,
    });

    try {
      const offerings = await Purchases.getOfferings();

      if (
        offerings.current !== null &&
        offerings.current.availablePackages.length !== 0
      ) {
        const mobileProductsExplore = [
          offerings.current.availablePackages.find(
            (p) => p.identifier === SubscriptionOptionsInApp.EXPLORE_MONTHLY,
          ),
          offerings.current.availablePackages.find(
            (p) => p.identifier === SubscriptionOptionsInApp.EXPLORE_SIX_MONTHS,
          ),
          offerings.current.availablePackages.find(
            (p) => p.identifier === SubscriptionOptionsInApp.EXPLORE_YEARLY,
          ),
        ];
        const mobileProductsPremium = [
          offerings.current.availablePackages.find(
            (p) => p.identifier === SubscriptionOptionsInApp.PREMIUM_MONTHLY,
          ),
        ];
        dispatch(
          setMobileProducts({ mobileProductsExplore, mobileProductsPremium }),
        );
      }
    } catch (e) {
      console.error("error loading in app offerings", e);
    }

    // Specific query for one ID
    try {
      const { customerInfo } = await Purchases.getCustomerInfo();
      if (
        customerInfo &&
        customerInfo.entitlements &&
        customerInfo.entitlements.active &&
        Object.entries(customerInfo.entitlements.active).length
      ) {
        console.log("customerInfo login", customerInfo);
        for (const entitlement of Object.entries(
          customerInfo.entitlements.active,
        )) {
          dispatch(addOwnedMobileProduct(entitlement));
        }
      }
    } catch (e) {
      console.error("error fetching in app customer info", e);
    }
  }

  static purchaseAppSubscription = async (product, dispatch) => {
    console.log("Purchase", product);

    this.isPurchasing = true;

    try {
      const {
        customerInfo,
        productIdentifier,
      } = await Purchases.purchasePackage({ aPackage: product });

      if (this.onStartPurchase) {
        this.onStartPurchase();
      }
      if (Object.entries(customerInfo.entitlements.active).length) {
        console.log("customerInfo purchase", customerInfo);
        for (const entitlement of Object.entries(
          customerInfo.entitlements.active,
        )) {
          console.log("entitlement", entitlement);
          dispatch(addOwnedMobileProduct(entitlement));
        }
        this.isPurchasing = false;
        if (this.onFinishPurchase) {
          this.onFinishPurchase();
        }
        if (this.callback) {
          this.callback();
        }
      }
    } catch (e) {
      this.isPurchasing = false;
      if (this.onFinishPurchase) {
        this.onFinishPurchase();
      }

      /*dispatch(
        setErrorMessage(
          "An error occurred when connecting to the payment service. Please try again in a few minutes",
        ),
      );*/
    }
  };

  static syncTransactions = async () => {
    try {
      const { customerInfo } = await Purchases.getCustomerInfo();
      if (
        !customerInfo ||
        !customerInfo.entitlements ||
        !customerInfo.entitlements.active ||
        !Object.entries(customerInfo.entitlements.active).length
      ) {
        Purchases.syncPurchases();
      }
    } catch (e) {
      console.error("error fetching in app customer info", e);
    }
  };

  static refreshFull = async (dispatch) => {
    try {
      const { customerInfo } = await Purchases.restoreTransactions();
      if (
        customerInfo &&
        customerInfo.entitlements &&
        customerInfo.entitlements.active &&
        Object.entries(customerInfo.entitlements.active).length
      ) {
        console.log("customerInfo restore", customerInfo);
        for (const entitlement of Object.entries(
          customerInfo.entitlements.active,
        )) {
          dispatch(addOwnedMobileProduct(entitlement));
        }
      }
    } catch (e) {
      console.error("error fetching in app customer info", e);
    }
  };
}
export default InAppPurchasesService;
