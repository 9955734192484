import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { palette } from "../../../theme/palette";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles(() => ({
  card: {
    background: palette.white,
    borderRadius: "16px",
    width: "100%",
    height: "280px",
  },
  textContainer: {
    padding: "12px",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

const VideosCardSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Skeleton variant="rounded" width="100%" height="200px" />
      <Box
        style={{
          padding: "12px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box className={classes.textContainer}>
          <Skeleton width="60%" />
          <Skeleton width="80%" />
        </Box>
      </Box>
    </Box>
  );
};

export default VideosCardSkeleton;
