import React, { useState } from "react";
import {
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PlanFieldTransition from "./plan-field-transition";
import { useSelector, useDispatch } from "react-redux";
import { updatePlan } from "../../../actions/user-plans-action";
import { PostContactData } from "../../../actions/subcategory-action";
import { withTranslation } from "react-i18next";
import {
  userPlanMailchimpSubscribe,
  activeCampaignInvited,
} from "../../../actions/user-plans-action";
import { emailRegex } from "../../../constants/regex";
import { useAnalyticsEvent } from "../../../hooks/useAnalyticsEvent";

const styles = {
  main: {
    padding: "16px",
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    margin: "0px 0px 16px 0px",
    fontSize: "24px",
    fontWeight: "bold",
  },
  text: {
    color: "#9E9E9E",
    fontSize: "16px",
  },
  icon: {
    color: "#9E9E9E",
  },
  field: {
    margin: "16px 0px",
  },
  btnsParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "30px",
  },
  btn: {
    borderRadius: "10px",
  },
};

const Invitation = ({ classes, t }) => {
  const dispatch = useDispatch();
  const { userPlans, auth } = useSelector((state) => state);

  const [fields, setFields] = useState([0]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [emails, setEmails] = useState({
    email0: "",
    email1: "",
    email2: "",
    email3: "",
    email4: "",
    email5: "",
  });

  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;

    const splited = name.split("l");
    const numberValue = splited[1];

    setEmails({ ...emails, [name]: value });
    const isValid = emailRegex.test(value);

    if (isValid && fields.length <= 4) {
      const copy = [...fields];
      copy[Number(numberValue) + 1] = Number(numberValue) + 1;
      setFields(copy);
    }
  };

  const deleteEmptyKeysFromObject = (data) => {
    for (const key in data) {
      if (!data[key]) delete data[key];
    }
    return data || {};
  };

  const sendAnalyticsEvent = () => {
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const pathname = window.location.pathname;
    const segments = pathname.split("/").filter(Boolean);
    const lastSegment = segments[segments.length - 1];
  };

  const savePlan = async (skip = false) => {
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { name, sub, email } = attributes;
    const emailsCopy = { ...emails };
    const currentEmails = deleteEmptyKeysFromObject(emailsCopy);
    const emailsArray = Object.values(currentEmails);

    if (userPlans) {
      const invitedList = {};
      emailsArray.forEach((value, index) => {
        invitedList[`friend${index + 1}`] = value;
      });

      if (!skip) {
        await dispatch(
          PostContactData({
            subject: "Invite friends",
            name,
            email: currentUser.username,
            message: `Invitation emails: ${emailsArray.toString()}`,
          }),
        );

        if (Object.keys(invitedList).length) {
          await userPlanMailchimpSubscribe({
            cognito_user_sub: sub,
            cognito_user_email: email,
            ...invitedList,
          });

          for (const property in invitedList) {
            await dispatch(
              activeCampaignInvited(sub, {
                invitedUserEmail: invitedList[property],
                invitedByEmail: email,
                invitedByName: name,
              }),
            );
          }
        }
      }
    }
    sendAnalyticsEvent();
  };

  const isDisabled = () => {
    const emailsArray = Object.values(emails);
    const result = emailsArray.some((email) => emailRegex.test(email));
    return result;
  };

  return (
    <PlanFieldTransition>
      <div className={classes.main}>
        <Typography className={classes.title}>
          {t("Do you have training partners? Invite them to Vert!")}
        </Typography>
        <Typography className={classes.text}>
          {t("Train with friends")}
        </Typography>

        {fields.map((field, index) => (
          <TextField
            key={index}
            id="email"
            name={`email${field}`}
            autoComplete="off"
            label={t("Add your friend's email here")}
            type="email"
            fullWidth
            onChange={handleChange}
            className={classes.field}
            onKeyPress={null}
            variant="outlined"
            value={emails[`email${field}`]}
            onFocus={() => setCurrentIndex(field)}
            onBlur={() => setCurrentIndex(-1)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonAddIcon
                    style={{
                      color: field == currentIndex ? "#E16327" : "#9E9E9E",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        ))}

        <div className={classes.btnsParent}>
          <Button
            variant="contained"
            type="submit"
            className={classes.btn}
            onClick={() => sendAnalyticsEvent()}
          >
            {t("Skip for now")}
          </Button>
          <Button
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
            className={classes.btn}
            onClick={() => savePlan()}
            disabled={!isDisabled()}
          >
            {t("Invite friends")}
          </Button>
        </div>
      </div>
    </PlanFieldTransition>
  );
};

export default withTranslation("constants")(
  withStyles(styles, { withTheme: true })(Invitation),
);
