import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Avatar,
  Fab,
  Button,
  Badge,
  Box,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { CircularProgress } from "@material-ui/core";
import { Storage } from "aws-amplify";
import jimp from "jimp";
import { withTranslation } from "react-i18next";
import DashboardUserCurrentLocation from "./dashboard-user-current-location";
import RouterLink from "../common/router-link";
import UpdateIcon from "@material-ui/icons/Update";
import DashboardNotifications from "../dashboard/dashboard-notifications";
import ActivateTrailheadDialog from "../trail-head/activate-trailhead-dialog";
import { connect } from "react-redux";
import moment from "moment";
import {
  updatePlanImage,
  updatePlanAddImage,
  updatePlanDeleteImage,
  updatePlan,
  getReferralCode,
  toggleOpenMenu,
} from "../../actions/user-plans-action";
import WorkoutPlans from "../../constants/plans";
import { dateFormat } from "../../lib/date-helper";
import { getSubcategoryTitle } from "../../lib/subcategory-helper";
import ReferalDialog from "../referal-dialog";
import RaffleDialog from "../raffle-dialog";
import FinishedChallenge from "../challenges/finished-challenge";
import { getChallenges } from "../../actions/challenges-action";
import history from "../../lib/history-helper";
import { getLevel } from "../../lib/rates-helper";
import { logEvent } from "../../lib/events-helper";
import PaywallModal from "../../components/generals/upgrade-pro-modal";
import MountainIndexChart from "../generals/mountain-index-chart";
import MountainIndexModal from "../generals/mountain-index-modal";
import { checkSubscription } from "../../lib/subscription-plan-helper";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { palette } from "../../theme/palette";
import SearchInput from "../generals/search-input";
import { generalSearch } from "../../actions/search-action";
import { Capacitor } from "@capacitor/core";

const styles = (theme) => ({
  progressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: 109,
  },
  container: {
    borderRadius: 10,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    height: "100vh",
    boxShadow: "4px 0px 12px rgba(33, 81, 209, 0.04)",

    [theme.breakpoints.down("md")]: {
      height: "100%",
      borderRadius: 0,
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "right",
      marginRight: 35,
    },
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit * 2,
    paddingTop: 6,
    paddingBottom: 6,
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
    },
  },
  textContainerPlan: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    paddingTop: 6,
    paddingBottom: 6,
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
    },
  },
  textContainerWeeks: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    paddingTop: 6,
    paddingBottom: 6,
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
    },
  },
  textContainerStrava: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    paddingTop: 6,
    paddingBottom: 6,
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
    },
  },
  freeTrialTextContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 14,
    paddingTop: 6,
    paddingBottom: 6,
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing.unit,
      paddingLeft: 11,
      paddingTop: 3,
    },
  },
  avatar: {
    width: 64,
    height: 64,
    alignSelf: "center",
  },
  fab: {
    width: 64,
    height: 64,
  },
  text: {
    color: "#202020",
    marginTop: "auto",
    paddingLeft: theme.spacing.unit,
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },

  textChallenges: {
    color: "#9E9E9E",
    fontWeight: "normal",
    fontSize: 14,
    marginLeft: 7,
  },

  textPlan: {
    color: "#202020",
    marginTop: "auto",
    paddingLeft: 8,
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  textWeeks: {
    color: "#202020",
    marginTop: "auto",
    paddingLeft: 9,
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  textStrava: {
    color: "#FF6327",
    marginTop: "auto",
    paddingLeft: 10,
    textAlign: "left",
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  textFreeTrial: {
    color: "#202020",
    marginTop: "auto",
    paddingLeft: 7,
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  icon: {
    color: "#9e9e9e",
    height: 20,
    [theme.breakpoints.down("md")]: {
      height: 16,
    },
  },
  link: {
    textDecoration: "none",
  },
  input: {
    display: "none",
  },
  divider: {
    margin: theme.spacing.unit * 1.5,
  },
  topDivider: {
    marginTop: theme.spacing.unit * 1.5,
    marginRight: theme.spacing.unit * 1.5,
    marginLeft: theme.spacing.unit * 1.5,
  },
  dividerMobile: {
    margin: theme.spacing.unit * 1.5,
  },
  dividerReport: {
    margin: "12px 0 20px",
  },
  imgIcon: {
    height: 20,
    [theme.breakpoints.down("md")]: {
      height: 18,
      marginTop: -2,
    },
  },

  imgIconPlanName: {
    height: 20,
  },

  imgIconRay: {
    height: 20,
    marginTop: 2,
  },
  notDisplayedInXs: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  onlyDisplayedInXs: {
    fontSize: 15,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  pictureContainer: {
    position: "relative",
    width: 64,
    height: 64,
    marginTop: theme.spacing.unit * 1.2,
    marginBottom: theme.spacing.unit * 1.2,
    // marginRight: "auto",
    // marginLeft: "auto",
    top: 0,
    left: 0,
    "& svg": {
      position: "absolute",
      top: -14,
      left: -14,
      "& circle": {
        strokeLinecap: "butt !important",
        strokeDasharray: "20, 5",
        stroke: "rgb(255,216,197,0.3) !important",
      },
      "& path": {
        strokeLinecap: "butt !important",
        strokeDasharray: "20, 5",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: 35,
    },
  },
  pictureIcon: {
    position: "absolute",
    bottom: 5,
    right: 5,
    cursor: "pointer",
  },
  edit: {
    top: 10,
    right: 10,
    position: "absolute",
    zIndex: 100,
  },
  notificationButton: {
    top: 0,
    right: 44,
    position: "absolute",
    zIndex: 100,
    cursor: "pointer",
  },
  raceContainer: {
    display: "flex",
    marginTop: 5,
    marginBottom: 5,
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      marginLeft: 10,
    },
  },
  raceIcon: {
    height: 16,
  },
  raceWeeks: {
    fontFamily: "Avenir",
    fontWeight: "normal",
    fontSize: 16,
    color: "#3F3F3F",
    textAlign: "left",
    marginLeft: 5,
  },
  raceTitle: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#202020",
    marginTop: 10,
    textAlign: "left",
  },
  topContainer: {
    paddingTop: 25,
    paddingBottom: 20,
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingTop: 8,
      paddingBottom: 0,
    },
  },
  socialContainer: {
    width: "calc(100% - 5px)",
    position: "relative",
    marginTop: 10,
    marginBottom: 20,
    background: "rgb(255 216 197 / 30%)",
    paddingLeft: 8,
    borderRadius: 10,
  },
  socialContainerActive: {
    width: "calc(100% - 34px)",
    position: "relative",
    marginTop: 10,
    marginBottom: 20,
    background: "rgb(255 216 197 / 30%)",
    padding: 8,
    marginLeft: 17,
    marginRight: 17,
    borderRadius: 10,
  },
  upgradeTitle: {
    fontSize: 14,
    color: "#FF6327",
    textAlign: "left",
    marginBottom: 5,
  },
  upgradeText: {
    fontSize: 12,
    color: "#000000",
    textAlign: "left",
  },
  imgUpgrade: {
    width: 108,
    marginLeft: -8,
  },
  imgLanded: {
    width: 100,
  },
  upgradeBkg: {
    width: "100%",
    height: 100,
    position: "absolute",
    background: "#FFD8C5",
    opacity: 0.3,
  },
  thBkg: {
    width: "100%",
    height: "80%",
    position: "absolute",
    background: "#FFD8C5",
    opacity: 0.3,
  },
  zIndexTop: {
    zIindex: 100,
  },
  iconLink: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  activateTH: {
    cursor: "pointer",
  },
  tabsContainer: {},
  tabsMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  tabSelected: {
    width: 100,
    textAlign: "center",
    padding: 10,
    position: "relative",
    cursor: "pointer",
  },
  tabNotSelected: {
    width: 100,
    textAlign: "center",
    cursor: "pointer",
    padding: 10,
  },
  tabContainer: {
    borderRadius: 20,
  },
  socialContainerScroll: {
    /*overflow: "scroll",
    [theme.breakpoints.down("md")]: {
      overflow: "initial",
    },*/
  },
  actionsContainer: {},
  editIcon: {
    cursor: "pointer",
    float: "right",
  },
  editActions: {
    display: "inline",
    float: "right",
  },
  editText: {
    color: "#3F3F3F",
    cursor: "pointer",
    marginBottom: 5,
    fontWeight: 800,
    fontSize: 12,
  },
  descriptionTitle: {
    fontWeight: 800,
    fontSize: 12,
    color: "#2F2E41",
    textAlign: "left",
  },
  descriptionText: {
    fontWeight: "normal",
    fontSize: 12,
    color: "#2F2E41",
    textAlign: "left",
  },
  mobileTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "left",
    padding: "10px 12px 0",
  },
  titleMobile: {
    fontWeight: 500,
    textAlign: "left",
    fontSize: 20,
  },
  mobileWeeksContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 5,
    marginLeft: 8,
  },
  mobilePlanContainer: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 5,
    marginLeft: 9,
  },
  mobilePlanText: {
    fontSize: 14,
    marginTop: "auto",
    paddingLeft: 4,
  },
  iconMobileWeeks: {
    height: 16,
  },
  mobileWeekstext: {
    fontSize: 14,
    marginTop: "auto",
    paddingLeft: 8,
  },

  textContainerRace: {
    marginLeft: 10,
  },
  textRace: {
    color: "#ffffff",
    textAlign: "left",
    paddingLeft: 0,
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  imgIconRace: {
    height: 18,
  },
  fullProfileContainer: {
    marginBottom: 10,
  },
  fullProfile: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#FF6327",
  },
  topMobileReport: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 15px",
    alignItems: "center",
    marginBottom: 10,
  },
  mobileReportTitle: {
    fontWeight: 500,
    fontSize: 14,
  },
  editBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imgIconPlus: {
    marginLeft: "auto",
    height: 24,
  },
  activate: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  activateText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "#FF6F00",
    fontFamily: "Avenir",
  },
  iconPresent: {
    top: 0,
    right: 78,
    position: "absolute",
    zIndex: 100,
    cursor: "pointer",

    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: 12,
      position: "initial",
    },

    height: 24,
  },
  noFinishedContainer: {
    background: "#FFFFFF",
    borderRadius: 8,
    padding: 10,
    margin: "15px 30px",
  },

  noActive: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#7B8BB2",
  },

  achievementsContainer: {
    overflow: "hidden",
  },

  achievementsBadgesContainer: {
    overflow: "scroll",
    height: "calc(100% - 48px)",
    minHeight: 200,
  },

  achievementsTitle: {
    color: "#202020",
    fontSize: 16,
    fontStyle: "normal",
    textAlign: "center",
    fontFamily: "Avenir",
    fontWeight: 500,
  },

  achievementsTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 10,
  },

  iconChallenges: {
    height: 20,
    marginRight: 5,
  },

  textContainerRaceDesktop: {
    display: "flex",
    alignItems: "center",
    paddingTop: 6,
    paddingLeft: 16,
    paddingBottom: 6,
  },

  imgIconRaceDesktop: {
    marginRight: 10,
  },

  textAddRaceDesktop: {
    fontSize: 14,
  },

  iconToday: {
    height: 22,
    marginLeft: "auto",
    marginRight: 12,
  },

  racePrioritySelected: {
    color: "#212121",
    fontSize: 10,
    background: "rgba(255, 87, 34, 0.24)",
    fontWeight: 500,
    width: "fit-content",
    padding: "0px 4px",
  },

  raceName: {
    fontWeight: 500,
    fontSize: 14,
    color: "#212121",
    textAlign: "left",
  },

  raceDate: {
    fontWeight: 400,
    fontSize: 12,
    color: "#212121",
    textAlign: "left",
  },

  mobileTopIconsContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: 30,
    justifyContent: "flex-end",
  },
  customSeparation: {
    marginRight: 0,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  levelMiniBadge: {
    height: 25,
    bottom: -5,
    right: -10,
    position: "absolute",
  },
  profilePictureParent: {
    alignSelf: "flex-start",
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    justifyContent: "space-between",
  },
  btnParent: {
    display: "block",
    marginLeft: "auto",
  },
  proButton: {
    borderRadius: 50,
    fontFamily: "Oswald",
    textTransform: "uppercase",
  },
  proButtonHide: {
    borderRadius: 50,
    fontFamily: "Oswald",
    animation: "$fadeout 0.5s forwards",
    textTransform: "uppercase",
  },
  "@keyframes fadeout": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
  staticInput: {
    display: "none",
  },
});

class DashboardUser extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    avatarLoading: true,
    showAll: false,
    isOpenActivateTH: false,
    currentTab: 0,
    isEditing: false,
    athleteDescription: null,
    referral: false,
    notificationsScheduled: false,
    raffle: false,
    units: "kms",
    showpaywall: false,
    showMountain: false,
    showSearch: 0,
    searchValue: "",
    timer: null,
    isProfile: false,
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const image = await jimp.read(reader.result);
          await image.resize(150, jimp.AUTO);
          const data = await image.getBase64Async(jimp.MIME_JPEG);
          const fileNameArray = file.name ? file.name.split(".") : undefined;
          resolve({
            image: data,
            extension: fileNameArray[fileNameArray.length - 1],
          });
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleInputChange = async (event) => {
    if (event) {
      const { target } = event;
      const { onError, maxSize, onImageChange, t } = this.props;
      if (target) {
        const { files } = target;
        if (files) {
          const selectedFile = files[0];
          try {
            if (selectedFile) {
              const { size } = selectedFile;
              if (size <= maxSize) {
                const { image: imageString, extension } = await this.getBase64(
                  selectedFile,
                );

                if (onImageChange) {
                  onImageChange(imageString, extension);
                  this.setState({
                    ...this.state,
                    avatarUrl: undefined,
                  });
                }
              } else if (onError) {
                onError(
                  t("Sorry, your image needs to be smaller than maxSize"),
                );
              }
            } else {
              if (onError) {
                onError(t("Invalid File", { ns: "messages" }));
              }
            }
          } catch (exp) {
            if (onError) onError(exp);
          }
        }
      }
    }
  };

  async componentDidUpdate(prevPops) {
    const { challenges, auth, isOnDashboard } = this.props;

    if (!isOnDashboard) {
      this.setSocial();

      if (
        prevPops.challenges.loadingChallenges &&
        !challenges.loadingChallenges
      ) {
        const { currentUser } = auth;
        const { attributes } = currentUser || {};
        const { sub } = attributes || {};
        if (sub) {
          let yourChallenges = challenges.currentChallenges
            ? challenges.currentChallenges.filter(
                (c) => c.participants && c.participants.includes(sub),
              )
            : [];
          this.props.dispatch(
            updatePlan(
              {
                cognito_user_sub: sub,
                currentChallenges: yourChallenges
                  .map((c) => (c && c.id ? c.id : null))
                  .filter((cs) => cs)
                  .join(","),
              },
              false,
              false,
            ),
          );
        }
      }

      if (
        prevPops.challenges.loadingYourChallengeEntries &&
        !challenges.loadingYourChallengeEntries
      ) {
        const { currentUser } = auth;
        const { attributes } = currentUser || {};
        const { sub } = attributes || {};
        if (sub) {
          const { yourChallengeEntries } = challenges || {};
          const yourDoneChallenges = yourChallengeEntries
            ? yourChallengeEntries.filter(
                (e) => e.challengeData.end_date < new Date().getTime(),
              )
            : [];
          this.props.dispatch(
            updatePlan(
              {
                cognito_user_sub: sub,
                pastChallenges: yourDoneChallenges
                  .map((c) =>
                    c && c.challengeData && c.challengeData.id
                      ? (c.challengeData.goal && c.finished) ||
                        (!c.challengeData.goal &&
                          c.activities &&
                          c.activities.length)
                        ? c.challengeData.id + "_Completed"
                        : c.challengeData.id + "_NotCompleted"
                      : null,
                  )
                  .filter((cs) => cs)
                  .join(","),
              },
              false,
              false,
            ),
          );
        }
      }
    }
  }

  setSocial() {
    const { userPlans } = this.props;
    const { athleteDescription, questions } = this.state;
    const { currentPlan } = userPlans;
    const { description, profileQuestions } = currentPlan || {};

    let newState = {};
    let hasChanged = false;
    if (description && athleteDescription === null) {
      newState.athleteDescription = description;
      hasChanged = true;
    }

    if (profileQuestions && !questions) {
      newState.questions = profileQuestions;
      hasChanged = true;
    }

    if (hasChanged) {
      this.setState({
        ...this.state,
        ...newState,
      });
    }
  }

  async componentDidMount() {
    this.getAvatarUrl();
    this.setSocial();

    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes || {};

    if (sub) {
      this.props.dispatch(getChallenges(sub));
    }

    if (typeof window !== "undefined") {
      this.setState({
        ...this.state,
        units: localStorage.getItem("units"),
      });

      window.addEventListener("storage", () => this.localStorageUpdated());
    }
  }

  localStorageUpdated() {
    this.setState({
      ...this.state,
      units: localStorage.getItem("units"),
    });
  }

  async componentWillReceiveProps(nextProps) {
    const { image } = nextProps;
    const { avatarUrl } = this.state;
    if (image && !avatarUrl) {
      this.setState({
        ...this.state,
        avatarLoading: true,
      });
      this.getAvatarUrl();
    }
  }

  getAvatarUrl = async () => {
    const { image, onError, t } = this.props;
    const { avatarUrl } = this.state;

    if (image && !avatarUrl) {
      try {
        const result = await Storage.get(image, { level: "public" });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              avatarUrl: result,
              avatarLoading: false,
            }),
          100,
        );
      } catch (exp) {
        onError(
          new Error(
            t(
              "Oops, we could not load your profile picture. Give it another try",
              { ns: "messages" },
            ),
          ),
        );
        throw exp;
      }
    } else if (!image) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            avatarLoading: false,
          }),
        100,
      );
    }
  };

  handleOpenActivateTrailhead = () => {
    this.setState({
      ...this.state,
      isOpenActivateTH: true,
    });
  };

  handleCloseActivateTrailhead = () => {
    this.setState({
      ...this.state,
      isOpenActivateTH: false,
    });
  };

  handleChangeTab = (newValue) => {
    this.setState({
      ...this.state,
      currentTab: newValue,
    });
  };

  handleEdit = () => {
    this.setState({
      ...this.state,
      isEditing: true,
    });
  };

  closeEdit = () => {
    const { onError, t, userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { description, profileQuestions } = currentPlan || {};
    if (description) {
      this.setState({
        ...this.state,
        isEditing: false,
        athleteDescription: description,
        questions: profileQuestions,
      });
    } else {
      onError(t("Please add a short bio"));
    }
  };

  handleSave = () => {
    const { userPlans, onError, t } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || {};
    const { athleteDescription, questions } = this.state;
    if (athleteDescription) {
      this.props.dispatch(
        updatePlan(
          {
            cognito_user_sub,
            description: athleteDescription,
            profileQuestions: questions,
          },
          false,
          false,
        ),
      );
      this.setState({
        ...this.state,
        isEditing: false,
      });
    } else {
      onError(t("Please add a short bio"));
    }
  };

  handleDescriptionChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          ...this.state,
          athleteDescription:
            value.replace(/(\r\n|\n|\r)/gm, "").slice(0, 140) || "",
        });
      }
    }
  };

  handleQuestionChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { id, value } = target;
        if (id) {
          const questions = {
            ...this.state.questions,
            [id]: value.replace(/(\r\n|\n|\r)/gm, "").slice(0, 140) || "",
          };
          this.setState({
            ...this.state,
            questions,
          });
        }
      }
    }
  };

  handleImageFieldChange = (value, extension) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      updatePlanImage({
        cognito_user_sub: currentPlan.cognito_user_sub,
        image: value,
        imageExtension: extension && extension.toLowerCase(),
      }),
    );
  };

  handleProfileImageFieldChange = (value, extension) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      updatePlanAddImage({
        cognito_user_sub: currentPlan.cognito_user_sub,
        image: value,
        imageExtension: extension && extension.toLowerCase(),
      }),
    );
  };

  handleProfileImageDelete = (value) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      updatePlanDeleteImage({
        cognito_user_sub: currentPlan.cognito_user_sub,
        image: value,
      }),
    );
  };

  handleOpenReferral = () => {
    if (!this.state.referral) {
      const { userPlans } = this.props;
      const { referralCode } = userPlans;
      if (!referralCode) {
        this.props.dispatch(getReferralCode());
      }
      this.setState({
        ...this.state,
        referral: true,
      });
    }
  };

  handleCloseReferral = () => {
    this.setState({
      ...this.state,
      referral: false,
    });
  };

  handleOpenRaffle = () => {
    if (!this.state.raffle) {
      this.setState({
        ...this.state,
        raffle: true,
      });
    }
  };

  handleCloseRaffle = () => {
    this.setState({
      ...this.state,
      raffle: false,
    });
  };

  openWorkoutNearestToToday = () => {
    const { userPlans, subscriptions } = this.props;
    const { currentPlan } = userPlans;
    const { currentTraining } = currentPlan || {};
    const { days } = currentTraining || {};
    const { latestExpired, latest } = subscriptions;

    const today = moment();

    if (days && !latestExpired && latest) {
      let found = false;
      days.forEach((day) => {
        if (day) {
          if (
            !found &&
            moment(day[0].plannedDate, dateFormat).isSameOrAfter(today, "day")
          ) {
            found = true;
            history.push(
              `/dashboard/${day[0].id}/${day[0].plannedDate
                .replace("/", "-")
                .replace("/", "-")}`,
            );
          }
        }
      });
    }
  };

  toogleMenu = () => {
    this.props.dispatch(toggleOpenMenu());
  };

  handleBack = () => history.goBack();

  handleSearch = () => {
    if (this.state.showSearch === 0)
      return this.setState({ ...this.state, showSearch: 1 });
    if (this.state.showSearch === 1)
      return this.setState({ ...this.state, showSearch: 2, searchValue: "" });
    if (this.state.showSearch === 2)
      return this.setState({ ...this.state, showSearch: 1 });
  };

  render() {
    const {
      classes,
      userPlans,
      auth,
      subscriptions,
      subcategories,
      width,
      i18n,
      t,
      xs,
      sm,
      md,
      lg,
      xl,
      onError,
      onFieldChanges,
      onActivateTrailhead,
      isChallenges,
      challenges,
      onShareChallenge,
      isOnDashboard = false,
      isProfile = false,
      subscriptionError,
      handleRetryModal,
      search,
    } = this.props;
    const { loading: searchLoading, searchData } = search;
    const { updatingImage, currentPlan, loading, comments } = userPlans;
    const { currentLocation, currentGeoLocation, currentTraining } =
      currentPlan || "";

    const fullTraining = currentTraining;

    const {
      yourChallengeEntries,
      loadingChallenges,
      loadingYourChallengeEntries,
    } = challenges || {};

    const {
      nextRace,
      nextRaces,
      accumulatedDistance = 0,
      accumulatedElevation = 0,
    } = currentPlan || {};

    const level = loading
      ? 0
      : getLevel(accumulatedDistance, accumulatedElevation);

    let allRaces = [];
    if (nextRace) {
      const today = moment();
      const raceDateMoment = moment(nextRace.raceDate, "DD/MM/YYYY");
      const weeksToRace = raceDateMoment.diff(today, "day");
      if (weeksToRace >= 0) {
        allRaces.push(nextRace);
      }
    }
    if (nextRaces && nextRaces.length > 0) {
      nextRaces.forEach((element) => {
        const today = moment();
        const raceDateMoment = moment(element.raceDate, "DD/MM/YYYY");
        const weeksToRace = raceDateMoment.diff(today, "day");
        if (weeksToRace >= 0) {
          allRaces.push(element);
        }
      });
    }

    const inputSearch = async ({ target: { value } }) => {
      await this.setState({ ...this.state, searchValue: value });
      await clearTimeout(this.state.timer);
      const newTimer = setTimeout(() => {
        this.props.dispatch(generalSearch(value));
      }, 800);

      await this.setState({ ...this.state, timer: newTimer });
    };

    allRaces.sort((a, b) => {
      let aMoment;
      if (a && a.raceDate) {
        aMoment = moment(a.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      let bMoment;
      if (b && b.raceDate) {
        bMoment = moment(b.raceDate, "DD/MM/YYYY").startOf("day");
      } else {
        return -1;
      }

      return aMoment.diff(bMoment, "days");
    });

    let timeToRaceText = "";

    if (allRaces && allRaces.length) {
      const today = moment();
      const raceDateMoment = moment(allRaces[0].raceDate, "DD/MM/YYYY");
      const weeksToRace = raceDateMoment.diff(today, "week");
      timeToRaceText = t("X Weeks", { value: weeksToRace });
      if (weeksToRace === 1) {
        timeToRaceText = t("X Week", { value: weeksToRace });
      }
      if (weeksToRace === 0) {
        const daysToRace = raceDateMoment.diff(today, "day") + 1;
        timeToRaceText = t("X Days", { value: daysToRace });
        if (daysToRace === 1) {
          timeToRaceText = t("X Day", { value: daysToRace });
        }
      }
    }

    const { latestExpired, loadingLatest, latest } = subscriptions;
    const { trial_end } = latest || {};
    const { latestSubscriptionFinalizedTime } = currentPlan || {};

    let goal = 0;
    let runningStreak = 0;
    const { durationInWeeks, startingDate } = fullTraining || {};

    let { selectedPlan } = fullTraining || {};

    if (!selectedPlan && currentPlan && currentPlan.selectedPlan) {
      selectedPlan = currentPlan.selectedPlan;
    }

    const { language } = i18n;
    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const today = moment();
    const startingDateMoment = moment(startingDate, dateFormat);
    const currentDay = today.diff(startingDateMoment, "days") + 1;
    const currentWeek = Math.min(
      Math.ceil((currentDay > 0 ? currentDay : 0) / 7),
      durationInWeeks,
    );

    let trialDaysLeft = 0;
    if (latest) {
      const endDate = moment.unix(trial_end);
      trialDaysLeft = endDate.diff(today, "days") + 1;
    }

    if (
      fullTraining &&
      fullTraining.days &&
      fullTraining.days.filter((day) => day).length > 0
    ) {
      //running streak
      goal = fullTraining.days
        .filter((day) => day)
        .map((day) => day.length)
        .reduce((sum, a) => sum + a);
      runningStreak = fullTraining.days
        .filter((day) => day)
        .map((day) => day.filter((workout) => workout.isDone).length)
        .reduce((sum, a) => sum + a);
    }

    const { currentUser } = auth;
    let currentUserFullName = "";
    if (currentUser) {
      const { attributes } = currentUser;
      if (attributes) {
        const { name, family_name, email } = attributes;
        currentUserFullName =
          name || family_name ? `${name} ${family_name || ""}`.trim() : email;
      }
    }

    let planName;
    if (selectedPlan && selectedPlan !== "premium") {
      const subcategory =
        currentPlan &&
        currentPlan.currentTraining &&
        currentPlan.currentTraining.subcategory
          ? currentPlan.currentTraining.subcategory
          : +currentPlan.subcategory;
      planName =
        subcategories &&
        subcategories.data &&
        subcategories.data.length &&
        currentPlan.subcategory
          ? getSubcategoryTitle(
              subcategories.data,
              subcategory,
              languageDetector,
            )
          : selectedPlan === "free"
          ? "Free"
          : "Explore";
    }
    if (selectedPlan === "premium") {
      planName = "Premium";
    }

    const { alreadyLoadedOnce } = subscriptions || {};

    const isSubscribed = checkSubscription(
      subscriptions,
      latestSubscriptionFinalizedTime,
    );

    const handleChatAction = (hasSubscription) => {
      if (hasSubscription) return history.push("/dashboard/chat");
      this.setState({ ...this.state, showpaywall: true });
    };

    const onClosePaywallModal = () => {
      this.setState({ ...this.state, showpaywall: false });
      history.push("/dashboard/chat");
    };

    const types = {
      subcategories: "chooseplan",
      posts: "explore/post",
    };

    const selectOption = (item) => {
      const { type, id } = item;
      const section = types[type];
      if (type === "subcategories")
        return history.push("chooseplan", { id: item.id });
      history.push(`${section}/${id}`);
    };

    const showStravaButton = currentPlan && !currentPlan.strava_athlete_id;
    const isInMobileView = width === "md" || width === "sm" || width === "xs";
    const isChallengesDesktop = !isInMobileView && isChallenges;

    const { referral, raffle } = this.state;
    const {
      avatarUrl,
      avatarLoading,
      isOpenActivateTH,
      currentTab,
      showMountain,
    } = this.state;

    const yourDoneChallenges = yourChallengeEntries
      ? yourChallengeEntries.filter(
          (e) => e.challengeData.end_date < new Date().getTime(),
        )
      : null;

    const unreadComments = comments
      ? comments.filter((c) => c.type === 0 && !c.isRead)
      : [];

    return (isInMobileView || !isOnDashboard) &&
      !(isChallenges && isInMobileView) ? (
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <div
          className={classes.container}
          style={{
            borderRadius: 0,
            boxShadow: "initial",
          }}
        >
          {!isChallenges ? (
            <Grid
              container
              alignItems="center"
              spacing={16}
              className={classes.topContainer}
              style={{
                paddingBottom: 8,
                paddingLeft: 16,
              }}
            >
              <Grid
                item
                xs={7}
                sm={7}
                md={isChallenges || isProfile ? 3 : 4}
                lg={isChallenges || isProfile ? 3 : 4}
                xl={isChallenges || isProfile ? 3 : 4}
                direction="row"
                justifyContent="flex-start"
              >
                {updatingImage || avatarLoading ? (
                  <div className={classes.progressContainer}>
                    <CircularProgress
                      variant="indeterminate"
                      color="primary"
                      data-testid="LoadingImage"
                    />
                  </div>
                ) : (
                  <div
                    className={classes.profilePictureParent}
                    id="profile_menu"
                  >
                    {!isProfile && (
                      <div
                        className={classes.pictureContainer}
                        style={{
                          width: 44,
                          height: 44,
                          position: "relative",
                        }}
                      >
                        <Fab
                          component="span"
                          className={classes.fab}
                          title="Cambia tu foto"
                          style={{
                            width: 44,
                            height: 44,
                          }}
                          onClick={() => this.toogleMenu()}
                        >
                          <Avatar
                            src={
                              avatarUrl ||
                              `${process.env.PUBLIC_URL}/avatar-empty.png`
                            }
                            className={classes.avatar}
                            style={{
                              width: 44,
                              height: 44,
                            }}
                          />
                        </Fab>

                        {level ? (
                          <img
                            src={`${process.env.PUBLIC_URL}/trl${level}-sm.svg`}
                            className={classes.levelMiniBadge}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    )}

                    {isInMobileView && (
                      <div
                        className={classes.btnParent}
                        style={{
                          margin:
                            !isSubscribed &&
                            ((!loadingLatest &&
                              typeof loadingLatest !== "undefined") ||
                              alreadyLoadedOnce)
                              ? 0
                              : 0,
                        }}
                      >
                        {!isSubscribed &&
                        ((!loadingLatest &&
                          typeof loadingLatest !== "undefined") ||
                          alreadyLoadedOnce) ? (
                          <RouterLink
                            to="/settings"
                            className={classes.menuBarIcon}
                          >
                            <Button
                              color="primary"
                              variant="contained"
                              className={
                                this.state.showSearch === 0 ||
                                this.state.showSearch === 2
                                  ? classes.proButton
                                  : classes.proButtonHide
                              }
                              size="medium"
                              id="home_go_pro_button"
                            >
                              {t("Go pro!")}
                            </Button>
                          </RouterLink>
                        ) : (
                          <img
                            src={`${process.env.PUBLIC_URL}/vertrun-logo-wide.png`}
                            alt="logo"
                            width={100}
                          />
                        )}
                      </div>
                    )}

                    {isInMobileView && subscriptionError && (
                      <InfoIcon
                        style={{ marginLeft: 10 }}
                        onClick={handleRetryModal}
                      />
                    )}
                  </div>
                )}
              </Grid>

              <Grid
                item
                xs={isChallenges ? 9 : 5}
                sm={isChallenges ? 9 : 5}
                md={8}
                lg={8}
                xl={8}
              >
                {!isInMobileView && (
                  <Typography variant="h5" className={classes.title}>
                    {currentUserFullName}
                  </Typography>
                )}

                {isInMobileView && isChallenges && (
                  <Typography variant="h5" className={classes.title}>
                    {t("Challenges")}
                  </Typography>
                )}

                {!isInMobileView &&
                allRaces &&
                allRaces.length &&
                allRaces[0] ? (
                  <Typography variant="h4" className={classes.raceTitle}>
                    {allRaces[0].raceName}
                  </Typography>
                ) : (
                  ""
                )}
                {!isInMobileView &&
                allRaces &&
                allRaces.length &&
                allRaces[0] ? (
                  <div className={classes.raceContainer}>
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-weeks.svg`}
                      alt=""
                      className={classes.raceIcon}
                    />
                    <Typography variant="body1" className={classes.raceWeeks}>
                      {timeToRaceText}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}

                {isInMobileView && !isChallenges ? (
                  <div
                    className={
                      !isOnDashboard
                        ? classes.mobileTopIconsContainer
                        : classes.customSeparation
                    }
                  >
                    {!isOnDashboard && !isProfile && (
                      <Badge
                        color="error"
                        badgeContent={unreadComments.length}
                        onClick={() => handleChatAction(!!latest)}
                      >
                        <img
                          style={{
                            marginLeft: 10,
                          }}
                          src={`${process.env.PUBLIC_URL}/icon-chat.svg`}
                          alt=""
                        />
                      </Badge>
                    )}

                    <Box sx={{ position: "relative" }}>
                      <Box
                        sx={{ position: "absolute", right: "5px", top: "-5px" }}
                      >
                        <SearchInput
                          show={this.state.showSearch}
                          handleInput={this.handleSearch}
                          onChange={inputSearch}
                          loading={searchLoading}
                          value={this.state.searchValue}
                        />
                      </Box>

                      {isOnDashboard && (
                        <img
                          src={`${process.env.PUBLIC_URL}/icon_search.svg`}
                          alt="search-icon"
                          width="24px"
                          id="home_search_icon"
                          style={{ marginRight: "16px" }}
                          onClick={this.handleSearch}
                        />
                      )}
                    </Box>

                    {this.state.searchValue && (
                      <Box
                        sx={{
                          minHeight: "100vh",
                          background: palette.background,
                          position: "absolute",
                          top: "70px",
                          left: "0",
                          width: "100%",
                          zIndex: 999,
                          marginTop:
                            Capacitor.getPlatform() === "ios"
                              ? "env(safe-area-inset-top)"
                              : "0px",
                          paddingBottom: "20px",
                          boxSizing: "border-box",
                        }}
                      >
                        <Box
                          sx={{
                            padding: "16px",
                            width: "100%",
                            boxSizing: "border-box",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{ padding: "4px 0px", color: palette.darkGray }}
                          >
                            <Typography
                              sx={{ textAlign: "left" }}
                              variant="body2"
                            >
                              {searchData.length > 0
                                ? t("Showing results for")
                                : t("We couldn't find any results for")}
                              <b>{` "${this.state.searchValue}"`}</b>
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              height: "2px",
                              width: "100%",
                              padding: "8px 0px",
                            }}
                          >
                            <Divider sx={{ marginTop: "8px" }} />
                          </Box>
                          {!searchLoading
                            ? searchData.map((item, index) => (
                                <Box
                                  sx={{
                                    marginTop: "16px",
                                    color: palette.black,
                                    "& p": {
                                      textAlign: "left",
                                    },
                                  }}
                                  variant="h6"
                                  key={item.id}
                                  onClick={() => selectOption(item)}
                                  id={`home_search_result${index}`}
                                >
                                  <Typography>
                                    {item && item.title
                                      ? item.title[language]
                                        ? item.title[language]
                                        : item.title["en"]
                                      : ""}
                                  </Typography>
                                </Box>
                              ))
                            : null}
                        </Box>
                      </Box>
                    )}

                    <DashboardNotifications
                      currentTraining={currentTraining}
                      isOnDashboard={isOnDashboard}
                      isOnProfile={isProfile}
                    ></DashboardNotifications>

                    {/* <div
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      {isInMobileView && (
                        <MountainIndexChart handleShow={handleShow} />
                      )}
                      {showMountain || true && (
                        <MountainIndexModal
                          open={showMountain}
                          onClose={() => { }}
                        />
                      )}
                    </div> */}
                  </div>
                ) : (
                  ""
                )}

                <PaywallModal
                  open={this.state.showpaywall}
                  onClose={onClosePaywallModal}
                  title="Paywall Title 1"
                  btnText="Try 7 days for free"
                  htmlContent
                  content="Paywall Content 1"
                  price={false}
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {!isInMobileView && !isChallenges ? (
            <>
              {planName ? (
                <div className={classes.textContainerPlan}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-plan.svg`}
                    alt=""
                    className={classes.imgIcon}
                  />
                  <Typography variant="body1" className={classes.textPlan}>
                    {planName}
                  </Typography>
                </div>
              ) : (
                ""
              )}

              {durationInWeeks > 0 ? (
                <>
                  <div className={classes.textContainerWeeks}>
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-weeks.svg`}
                      alt=""
                      className={classes.imgIcon}
                    />
                    <Typography
                      variant={isInMobileView ? "caption" : "body1"}
                      className={classes.textWeeks}
                    >
                      {t("Week of", {
                        currentWeek,
                        durationInWeeks,
                        ns: "dashboard",
                      })}
                    </Typography>
                  </div>
                </>
              ) : (
                ""
              )}

              <DashboardUserCurrentLocation
                currentLocation={currentLocation}
                currentGeoLocation={currentGeoLocation}
                isInMobileView={isInMobileView}
                onError={onError}
                onChanges={onFieldChanges}
              />

              {showStravaButton ? (
                <RouterLink to="/profile">
                  <div className={classes.textContainerStrava}>
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-sync.svg`}
                      alt=""
                      className={classes.imgIcon}
                    />
                    <Typography variant="body1" className={classes.textStrava}>
                      {t("Connect with STRAVA", { ns: "dashboard" })}
                    </Typography>
                  </div>
                </RouterLink>
              ) : (
                ""
              )}

              {trialDaysLeft > 0 ? (
                <>
                  <div className={classes.freeTrialTextContainer}>
                    <UpdateIcon className={classes.icon} />
                    <Typography
                      variant={isInMobileView ? "caption" : "body1"}
                      className={classes.textFreeTrial}
                    >
                      {t("Days left of free trial", {
                        trialDaysLeft,
                        ns: "dashboard",
                      })}
                    </Typography>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {isChallengesDesktop ? (
            loadingChallenges ||
            (loadingYourChallengeEntries && !yourDoneChallenges) ? (
              <div className={classes.progressContainer}>
                <CircularProgress
                  variant="indeterminate"
                  color="primary"
                  className={classes.loading}
                  data-testid="LoadingUser"
                />
              </div>
            ) : (
              <div className={classes.achievementsContainer}>
                <div className={classes.achievementsTitleContainer}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-challenges.svg`}
                    alt=""
                    className={classes.iconChallenges}
                  />
                  <Typography
                    variant={"body1"}
                    className={classes.achievementsTitle}
                  >
                    {t("Your achievements", { ns: "challenges" })}
                  </Typography>
                </div>
                <Grid
                  container
                  spacing={8}
                  className={classes.achievementsBadgesContainer}
                >
                  {yourDoneChallenges && yourDoneChallenges.length ? (
                    yourDoneChallenges.map((c, index) => (
                      <Grid item xs={6} key={index}>
                        <FinishedChallenge
                          yourEntry={c}
                          onShareChallenge={onShareChallenge}
                          units={this.state.units}
                        ></FinishedChallenge>
                      </Grid>
                    ))
                  ) : (
                    <div className={classes.noFinishedContainer}>
                      <Typography
                        variant={"body1"}
                        className={classes.noActive}
                      >
                        {t("no finished challenges", { ns: "challenges" })}
                      </Typography>
                    </div>
                  )}
                </Grid>
              </div>
            )
          ) : (
            ""
          )}
        </div>
        <ActivateTrailheadDialog
          open={isOpenActivateTH}
          onCancel={this.handleCloseActivateTrailhead}
          onConfirm={() => {
            this.setState({
              ...this.state,
              isEditing: true,
              isOpenActivateTH: false,
            });
            onActivateTrailhead();
          }}
        ></ActivateTrailheadDialog>
        <ReferalDialog
          open={referral}
          onCancel={this.handleCloseReferral}
        ></ReferalDialog>

        <RaffleDialog
          open={raffle}
          onCancel={this.handleCloseRaffle}
        ></RaffleDialog>
      </Grid>
    ) : (
      ""
    );
  }
}

DashboardUser.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  planName: PropTypes.string,
};

DashboardUser.defaultProps = {
  maxSize: 5120000,
};

export default connect((store) => {
  return {
    strava: store.strava,
    userPlans: store.userPlans,
    auth: store.auth,
    trailHead: store.trailHead,
    subscriptions: store.subscriptions,
    challenges: store.challenges,
    subcategories: store.subcategories,
    search: store.search,
  };
})(
  withTranslation(
    "dashboard",
    "messages",
    "trailhead",
    "challenges",
  )(withWidth()(withStyles(styles, { withTheme: true })(memo(DashboardUser)))),
);
