import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { currentUserSignOut } from "../actions/auth-action";
import LocalNotificationsService from "../services/local-notifications-service";
import { storageFactory } from "../lib/storage-factory";
import { clearGarminActivities } from "../actions/garmin-action";
import { clearStravaActivities } from "../actions/strava-action";
import { clearSuuntoActivities } from "../actions/suunto-action";
import { clearVertActivities } from "../actions/vert-activity-action";
import { clearHealthkitActivities } from "../actions/healthkit-action";
import { clearCorosActivities } from "../actions/coros-action";

class SignOut extends React.Component {
  componentWillMount() {
    const { auth } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;

    LocalNotificationsService.stopListenToNotifications();
    storageFactory().removeItem("currentGoal");
    this.props.dispatch(currentUserSignOut(Auth.signOut(), sub));
    this.props.dispatch(clearGarminActivities());
    this.props.dispatch(clearStravaActivities());
    this.props.dispatch(clearSuuntoActivities());
    this.props.dispatch(clearVertActivities());
    this.props.dispatch(clearCorosActivities());
    this.props.dispatch(clearHealthkitActivities());
    if (localStorage.getItem("counter"))
      localStorage.removeItem("counter", "1");
  }

  render() {
    const { auth } = this.props;
    const { currentUserSigningOut } = auth;
    if (currentUserSigningOut) return null;
    return (
      <Redirect
        to={{
          pathname: "/auth",
        }}
      />
    );
  }
}

export default connect((store) => {
  return {
    auth: store.auth,
  };
})(SignOut);
