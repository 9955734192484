import React from "react";
import { createContext, useContext } from "react";

export const STEPS_RECOVERY = {
  SEND_CODE: "sendCode",
  CHANGE_PASSWORD: "changePassword",
  SUCCESS: "success",
};

const StepsRecoveryContext = createContext({
  step: STEPS_RECOVERY.SEND_CODE,
  onSetStep: () => {},
  errorMessage: null,
  onSetErrorMessage: () => {},
});

const StepsRecoveryProvider = ({ children }) => {
  const [step, setStep] = React.useState(STEPS_RECOVERY.SEND_CODE);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const onSetStep = (step) => {
    setStep(step);
  };

  const onSetErrorMessage = (message) => {
    setErrorMessage(message);
  };

  return (
    <StepsRecoveryContext.Provider
      value={{ step, onSetStep, errorMessage, onSetErrorMessage }}
    >
      {children}
    </StepsRecoveryContext.Provider>
  );
};

export default StepsRecoveryProvider;

export const useStepsRecovery = () => useContext(StepsRecoveryContext);
