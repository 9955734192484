import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Divider, CircularProgress } from '@material-ui/core';

const styles = theme => ({
    divider: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
    },
    loading: {
        padding: theme.spacing.unit * 0.7
    }
});

class AthleteUserPlanInfo extends Component {
    render() {
        const { classes, title, value, loading } = this.props;
        return <div>
            <Typography variant="body1">
                {title}
            </Typography>
            {loading ?
                <CircularProgress variant="indeterminate" size={20} className={classes.loading} />
                :
                <Typography variant="h5" color="textSecondary">
                    {value}
                </Typography>}
            <Divider className={classes.divider} />
        </div>
    }
}

AthleteUserPlanInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loading: PropTypes.bool
}
AthleteUserPlanInfo.defaultProps = {
    loading: false
}

export default withStyles(styles)(AthleteUserPlanInfo);


