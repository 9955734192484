import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import AppBarMenu from "./app-bar-menu";
import UserInfo from "./user-info";
import UserInfoMobile from "./user-info-mobile";
import history from "../../lib/history-helper";
import { toggleFavorites } from "../../actions/trail-head-action";
import { logEvent } from "../../lib/events-helper";
import { setPlanToSelect } from "../../actions/user-plans-action";

const styles = (theme) => ({
  appBar: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    height: 60,
    [theme.breakpoints.down("sm")]: {
      background: "transparent",
      position: "relative",
      paddingTop: 0,
      paddingBottom: 0,
      height: "auto",
    },
    flexShrink: 0,
  },
  logoLangDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse",
      marginLeft: theme.spacing.unit * 2,
      flexGrow: 1,
    },
    margin: "5px 0",
  },
  langDiv: {
    display: "flex",
  },
  logoDiv: {
    paddingLeft: 30,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      paddingLeft: 0,
      paddingRight: 40,
    },
  },
  logo: {
    width: 100,
    display: "block",
    margin: "auto",
    content: `url("${process.env.PUBLIC_URL}/vertrun-logo-wide.png")`,
    [theme.breakpoints.down("sm")]: {
      width: 80,
      margin: "initial",
    },
  },
  appBarDiv: {
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.down("sm")]: {
      flex: 0,
      display: "none",
    },
  },
  appBarMenu: {
    textAlign: "center",
    marginRight: "auto",
  },
  appBarMenuChoosePlan: {
    width: "70%",
  },
  menuDiv: {
    flex: 1,
    height: "100%",
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.down("sm")]: {
      flex: 0,
      display: "none",
    },
  },
  noDisplayInMobile: {
    [theme.breakpoints.down("sm")]: {
      flex: 0,
      display: "none",
    },
  },
  logoInMobileViewAuth: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 50,
    },
  },
  headerTitle: {
    width: "90%",
  },
  choosePlanAppBar: {
    alignItems: "center",
    justifyContent: "center",
    background: "#F4F4F5",
    zIndex: 999,
    position: "fixed",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },

  dashboardAppBar: {
    background: "#ffffff",
    paddingTop: 0,
    paddingBottom: 0,
    boxShadow: "4px 0px 12px rgb(33 81 209 / 4%)",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },

  choosePlanlogoLang: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "unset",
      marginLeft: 0,
      flexGrow: 0,
    },
  },
  choosePlanUserInfo: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  freeButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  freeButtonRoot: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    fontSize: "0.975rem",
    fontWeight: 600,
    paddingTop: theme.spacing.unit * 1.2,
    paddingBottom: theme.spacing.unit * 1.2,
  },
  freeButtonlabel: {
    textAlign: "center",
  },
  mobileAppBarDividerWrapper: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      boxSizing: "border-box",
      display: "block",
      padding: "0 20px",
      width: "100%",
    },
  },
  mobileAppBarDivider: {
    width: "100%",
    borderTop: "1px solid gray",
  },
  iconBack: {
    marginLeft: 35,
    cursor: "pointer",
  },
  choosePlanVariant: {
    backgroundColor: "#FFF",
    position: "relative",
    zIndex: 999,
  },
});

class AppBar extends Component {
  handleBack = () => {
    const {
      trailHead,
      isOnExplore,
      isOnUtmb,
      isOnSkyrunner,
      isOnXterra,
    } = this.props;
    const { favorites } = trailHead;

    if (isOnUtmb || isOnSkyrunner || isOnXterra)
      return history.push("/chooseplan");
    if (isOnExplore && favorites) {
      this.props.dispatch(toggleFavorites());
    } else {
      history.goBack();
    }
  };

  render() {
    const {
      classes,
      isNotOnChoosingPlanPage,
      isOnDashboard,
      showLanguageMenu,
      showChoosePlanHeader,
      isOnSubscriptionPage,
      isOnTrailhead,
      width,
      isOnSuggesterResults,
      isOnChallenges,
      isOnProfile,
      isOnExplore,
      trailHead,
      isOnTracking,
      isOnSubscription,
      isOnUtmb,
      isOnSkyrunner,
      subscriptionError,
      handleRetryModal,
      choosePlanVariant,
      isOnXterra,
    } = this.props;

    const { favorites } = trailHead;

    const pathname = window.location.pathname;

    const navigate = () => {
      if (pathname.includes("/subscription/")) history.push("/");

      const { planToSelect } = this.props.userPlans || {};
      if (planToSelect) {
        this.props.dispatch(setPlanToSelect({}));
      }
    };

    const isInMobileViewSm = width === "sm" || width === "xs";
    return (
      <Fragment>
        <div
          className={classNames(classes.appBar, {
            [classes.choosePlanAppBar]: showChoosePlanHeader,
            [classes.dashboardAppBar]: isOnDashboard,
            [classes.choosePlanVariant]: choosePlanVariant,
          })}
        >
          {(!isInMobileViewSm &&
            (isOnChallenges ||
              isOnProfile ||
              (isOnExplore && !(pathname === "/explore" && !favorites)))) ||
          (!isInMobileViewSm && (isOnUtmb || isOnSkyrunner || isOnXterra)) ? (
            <img
              src={`${process.env.PUBLIC_URL}/icon-arrow-back-black.svg`}
              alt=""
              className={classes.iconBack}
              onClick={() => this.handleBack()}
            />
          ) : (
            ""
          )}
          {!isInMobileViewSm && (
            <div
              className={classNames(classes.logoLangDiv, {
                [classes.choosePlanlogoLang]: showChoosePlanHeader,
              })}
            >
              <div
                className={classNames(classes.logoDiv, {
                  [classes.logoInMobileViewAuth]: !showLanguageMenu,
                })}
              >
                {!isOnUtmb && (
                  <img
                    onClick={navigate}
                    className={classes.logo}
                    alt="logo"
                    data-testid="logo"
                  />
                )}

                {isOnUtmb && (
                  <img
                    onClick={navigate}
                    alt="logo-blue"
                    width={75}
                    src={`${process.env.PUBLIC_URL}/vert-logo-blue.svg`}
                  />
                )}

                {isOnUtmb && isInMobileViewSm && (
                  <img
                    src={`${process.env.PUBLIC_URL}/utmb-logo.svg`}
                    alt="utmb-logo"
                    style={{ marginLeft: 10, width: 65 }}
                  />
                )}

                {isOnSkyrunner && isInMobileViewSm && (
                  <img
                    alt="skyrunning-logo"
                    width={75}
                    src={`${process.env.PUBLIC_URL}/skyrunning-logo.svg`}
                  />
                )}
              </div>
            </div>
          )}
          {showChoosePlanHeader && (
            <div
              className={classNames(
                classes.appBarDiv,
                classes.appBarMenuChoosePlan,
              )}
            ></div>
          )}
          {isNotOnChoosingPlanPage &&
            !isOnSubscriptionPage &&
            !showLanguageMenu && (
              <div
                className={classNames(classes.appBarDiv, classes.appBarMenu)}
              >
                <AppBarMenu
                  isNotOnChoosingPlanPage={
                    isNotOnChoosingPlanPage && !isOnSubscriptionPage
                  }
                />
              </div>
            )}

          {!isInMobileViewSm && !isOnSubscription && (
            <UserInfo
              isNotOnChoosingPlanPage={
                isNotOnChoosingPlanPage && !isOnSubscriptionPage
              }
              isOnDashboard={isOnDashboard}
              isOnTrailhead={isOnTrailhead}
              isOnExplore={isOnExplore}
              isOnSuggesterResults={isOnSuggesterResults}
              isOnTracking={isOnTracking}
              showLanguageMenu={showLanguageMenu}
              showChoosePlanHeader={showChoosePlanHeader}
              className={classNames(classes.appBarDiv, {
                [classes.choosePlanUserInfo]: showChoosePlanHeader,
              })}
              subscriptionError={subscriptionError}
              handleRetryModal={handleRetryModal}
            />
          )}
          {isInMobileViewSm && (
            <UserInfoMobile
              isNotOnChoosingPlanPage={
                isNotOnChoosingPlanPage && !isOnSubscriptionPage
              }
              isOnTrailhead={isOnTrailhead}
              isOnDashboard={isOnDashboard}
              isOnSuggesterResults={isOnSuggesterResults}
              isOnChallenges={isOnChallenges}
              isOnProfile={isOnProfile}
              isOnExplore={isOnExplore}
              isOnTracking={isOnTracking}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  whiteLogoOnMobileView: PropTypes.bool,
  isNotOnChoosingPlanPage: PropTypes.bool,
  isOnDashboard: PropTypes.bool,
  showLanguageMenu: PropTypes.bool,
  showChoosePlanHeader: PropTypes.bool,
  isOnSubscriptionPage: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

AppBar.defaultValues = {
  whiteLogoOnMobileView: false,
  isNotOnChoosingPlanPage: true,
};

export default connect((store) => {
  return {
    auth: store.auth,
    trailHead: store.trailHead,
    userPlans: store.userPlans,
  };
})(
  withWidth()(
    withTranslation("layout")(withStyles(styles, { withTheme: true })(AppBar)),
  ),
);
