import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Switch,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import EnhancedDeleteIcon from "./enhanced-delete-icon";
import { withTranslation } from "react-i18next";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "row-reverse",
  },
  title: {
    flex: "0 0 auto",
  },
  toggleParent: {
    display: "flex",
    alignItems: "center",
  },
  titleParent: {
    display: "flex",
  },
  titleText: {
    marginRight: "16px",
  },
});

const CustomSwitch = withStyles({
  switchBase: {
    color: "#FFF",
    opacity: 1,
    "&$checked": {
      color: "#FFF",
      opacity: 1,
    },
    "&$checked + $track": {
      backgroundColor: "#000",
      opacity: 1,
    },
    "&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
      backgroundColor: "#FFF",
      opacity: 1,
    },
    "&$disabled + $track": {
      backgroundColor: "#000",
      opacity: 1,
    },
  },
  checked: {},
  track: {
    background: "black",
    opacity: 1,
  },
})(Switch);

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    classes,
    title,
    deleting,
    deleteRoute,
    extraActions,
    i18n,
  } = props;
  const { language } = i18n;

  const handleChangeLanguage = () => {
    const id = language === "en" ? "es" : language === "es" ? "fr" : "en";
    i18n.changeLanguage(id);
  };

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <div className={classes.titleParent}>
            <Typography
              variant="h6"
              id="tableTitle"
              className={classes.titleText}
            >
              {title}
            </Typography>
            <div className={classes.toggleParent}>
              <Typography variant="subtitle1">EN</Typography>
              <CustomSwitch
                defaultChecked
                onChange={handleChangeLanguage}
                checked={language == "es"}
              />
              <Typography variant="subtitle1">ES</Typography>
            </div>
          </div>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <EnhancedDeleteIcon deleting={deleting} linkTo={deleteRoute} />
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
        {extraActions}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  deleting: PropTypes.bool,
  deleteRoute: PropTypes.string,
};

export default withTranslation()(
  withStyles(toolbarStyles)(EnhancedTableToolbar),
);
