import { connect } from "react-redux";

import * as authActions from "../actions/auth-action";

const mapState = (state) => ({
  auth: state.auth,
});

const mapDispatch = {
  ...authActions,
};

const ConnectAuth = connect(mapState, mapDispatch);

export default ConnectAuth;
