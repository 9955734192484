import React from "react";
import { Box } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { palette } from "../../theme/palette";

const wrapperStyles = {
  display: "inline-block",
  marginRight: "16px",
};

const rowBox = {
  display: "flex",
  overflowX: "scroll",
  width: "300vw",
};

function Wrapper({ children }) {
  return <Box sx={wrapperStyles}>{children}</Box>;
}

const skeletonStyles = {
  marginBottom: "16px",
  flex: 1,
};

export const RowListSkeleton = ({ width, height }) => {
  return (
    <>
      <Skeleton
        count={1}
        borderRadius={4}
        width="100%"
        height={15}
        baseColor={palette.skeleton}
        style={skeletonStyles}
      />
      <Box sx={rowBox}>
        <Skeleton
          count={3}
          borderRadius={4}
          width={width}
          height={height}
          baseColor={palette.skeleton}
          wrapper={Wrapper}
        />
      </Box>
    </>
  );
};
