import React from "react";
import { Tabs, Tab, makeStyles } from "@material-ui/core";
import { palette } from "../../theme/palette";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  bigIndicator: {
    backgroundColor: palette.black,
    color: palette.black,
  },
  tab: {
    fontSize: "18px",
    fontFamily: "Oswald",
    "&:not(.Mui-selected)": {
      color: palette.deactivated,
      fontWeight: 400,
    },
    "&.Mui-selected": {
      color: palette.black,
      fontWeight: 500,
    },
  },
  fixed: {
    width: "100%",
  },
});

const tabs = [
  { label: "profile.stats", value: 0, id: "profile_stats" },
  { label: "profile.races", value: 1, id: "profile_races" },
  { label: "profile.activities", value: 2, id: "profile_activities" },
];

export const CustomTabs = ({ onChange, tabIndex }) => {
  const classes = useStyles();
  const { t } = useTranslation("profile");

  return (
    <>
      <Tabs
        onChange={onChange}
        value={tabIndex}
        variant="fullWidth"
        aria-label="secondary tabs example"
        sx={{ color: palette.black }}
        centered
        classes={{ indicator: classes.bigIndicator, fixed: classes.fixed }}
      >
        {tabs.map((item, index) => (
          <Tab
            data-testid={item.id}
            id={item.id}
            key={index}
            index={item.value}
            label={t(item.label)}
            sx={{ fontFamily: "Oswald" }}
            className={classes.tab}
          />
        ))}
      </Tabs>
    </>
  );
};
