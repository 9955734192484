import React from "react";
import { palette } from "../../theme/palette";

const InfoIcon = ({
  width = 16,
  height = 16,
  vWidth = 17 + 1,
  vHeight = 17 + 1,
  activated = false,
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vWidth} ${vHeight}`}
      fill={activated ? palette.primary : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 4.16667V5.83333H9.16667V4.16667H7.5Z"
        fill={activated ? palette.primary : palette.darkGray}
      />
      <path
        d="M7.5 9.16667V12.5H10V10.8333H9.16667V7.5H6.66667V9.16667H7.5Z"
        fill={activated ? palette.primary : palette.darkGray}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33333 0C3.73333 0 0 3.73333 0 8.33333C0 12.9333 3.73333 16.6667 8.33333 16.6667C12.9333 16.6667 16.6667 12.9333 16.6667 8.33333C16.6667 3.73333 12.9333 0 8.33333 0ZM1.66667 8.33333C1.66667 12.0083 4.65833 15 8.33333 15C12.0083 15 15 12.0083 15 8.33333C15 4.65833 12.0083 1.66667 8.33333 1.66667C4.65833 1.66667 1.66667 4.65833 1.66667 8.33333Z"
        fill={activated ? palette.primary : palette.darkGray}
      />
    </svg>
  </>
);
export default InfoIcon;
