const Languages = {
  en: "English",
  fr: "French",
  es: "Español",
};

export default {
  Items: Languages,
  itemsArray: Object.keys(Languages).map((key) => {
    return {
      text: Languages[key],
      value: key,
    };
  }),
  ItemsShortArray: Object.keys(Languages).map((key) => {
    return {
      text: key,
      value: key,
    };
  }),
};
