import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import Collapse from "./Collapse";
import MobilePlayer from "../../generals/mobile-player";
import TimerIcon from "../../icons/TimerIcon";
import { PlayCircleOutlineOutlined } from "@material-ui/icons";
import { palette } from "../../../theme/palette";
import { useTranslation } from "react-i18next";
import useWorkout from "../../../hooks/useWorkout";

const extractTitleFromUrl = (url) => {
  const decodedUrl = decodeURIComponent(url);
  const parts = decodedUrl.split("/");
  const filename = parts[parts.length - 1];
  const title = filename
    .replace(/\.[^/.]+$/, "")
    .replace(/[_\+\-\%]/g, " ")
    .replace(/\[.*?\]\s*/g, "")
    .trim();
  return title;
};

const StrengthCollapse = ({ videoUrl = [] }) => {
  const classes = useStyles();
  const { t } = useTranslation("training");
  const { openVideo, handleOpenVideo } = useWorkout();
  const [selectedVideo, setSelectedVideo] = React.useState(null);

  const handleVideo = (url) => {
    setSelectedVideo(url);
    handleOpenVideo();
  };

  return (
    <Collapse
      title={t("training.strength.title")}
      Icon={TimerIcon}
      subHeading={"15 min"}
      disabled={!videoUrl.length}
    >
      <List>
        {videoUrl.map((url, index) => (
          <ListItem
            key={index}
            className={classes.listItemCore}
            classes={{ gutters: classes.listItemGutters }}
          >
            <ListItemText
              primary={extractTitleFromUrl(url)}
              classes={{ primary: classes.listItemText }}
            />
            <ListItemSecondaryAction className={classes.listItemRight}>
              <IconButton
                size="small"
                edge="end"
                aria-label="play"
                onClick={() => handleVideo(url)}
              >
                <PlayCircleOutlineOutlined style={{ color: palette.black }} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      {videoUrl.length > 0 && (
        <MobilePlayer
          autoplay={true}
          open={openVideo}
          onClose={handleOpenVideo}
          url={selectedVideo}
        />
      )}
    </Collapse>
  );
};

const useStyles = makeStyles({
  marginY: {
    marginTop: 15,
    marginBottom: 15,
  },
  listItemCore: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listItemGutters: {
    display: "flex",
    padding: 0,
  },
  listItemText: {
    marginRight: 50,
  },
  listItemRight: {
    display: "flex",
    justifyContent: "end",
    textAlign: "end",
  },
});

StrengthCollapse.propTypes = {
  videoUrl: PropTypes.arrayOf(PropTypes.string),
};

export default StrengthCollapse;
