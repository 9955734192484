import React, { memo, useState } from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import unitsHook from "../../../../../hooks/unitsHook";
import { convertUnits, round } from "../../../../../lib/units-helper";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ShareBadge from "./share-badge";
import { useSelector } from "react-redux";

const styles = (theme) => ({
  commonCard: {
    background: "white",
    margin: "0px 16px 16px 16px",
    borderRadius: "12px",
    padding: "12px 10px",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  parent: {
    display: "inline-flex",
    width: "100%",
  },
  leftSection: {
    width: "40%",
    display: "flex",
    justifyContent: "center",
  },
  rightSection: {
    width: "60%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
  },
  stats: {
    display: "flex",
    width: "90%",
    justifyContent: "space-between",
  },
  smallText: {
    fontSize: "12px",
  },
  orangeText: {
    textAlign: "right",
    color: "#E16327",
    marginBottom: "4px",
  },
  title: {
    width: "90%",
    fontSize: 14,
    margin: "6px 0",
    lineHeight: 1.2,
    fontWeight: "bold",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
  },
  leftMargin: {
    marginLeft: "8px",
  },
  footerItem: {
    display: "flex",
  },
  icon: {
    marginRight: 4,
  },
  centerText: {
    textAlign: "center",
  },
  orangeBox: {
    backgroundColor: "rgba(255, 87, 34, 0.24)",
    padding: "2px 4px",
    width: "fit-content",
  },
  image: {
    width: 120,
    height: "object-fit",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
});

const BadgeCard = ({ entry, classes, t, i18n }) => {
  const currentUnit = unitsHook();
  const [openShare, setOpenShare] = useState(false);

  const {
    auth: {
      currentUser: { attributes },
    },
    trailHead,
  } = useSelector((state) => state);

  const { totals = {}, challengeData = {} } = entry || {};

  const { selectedAthlete } = trailHead;
  const { cognito_user_sub: userSub } = selectedAthlete || {};

  const { language = "en" } = i18n;

  const buildHours = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const timeStr = `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }`;
    return timeStr || 0;
  };

  const open = () => setOpenShare((openShare) => !openShare);

  return (
    <>
      <ShareBadge
        open={openShare}
        onCloseMethod={open}
        yourEntry={entry}
        totals={totals}
      />
      <div
        className={classes.commonCard}
        onClick={userSub == attributes.sub ? open : null}
      >
        <div className={classes.parent}>
          <div className={classes.leftSection}>
            <img
              src={
                challengeData.badge ||
                `${process.env.PUBLIC_URL}/user-profile/race.png`
              }
              className={classes.image}
            ></img>
          </div>
          <div className={classes.rightSection}>
            {userSub == attributes.sub && (
              <Typography variant="body2" className={classes.orangeText}>
                {t("Share Badge", { ns: "trailhead" })}
              </Typography>
            )}

            <div className={classes.orangeBox}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                {t("Achieved", { ns: "dashboard" })}
              </Typography>
            </div>
            <Typography variant="body2" className={classes.title}>
              {challengeData.name[language]
                ? challengeData.name[language].toUpperCase()
                : challengeData.name["en"].toUpperCase()}
            </Typography>
            <div className={classes.stats}>
              <div>
                <Typography variant="body2" className={classes.centerText}>
                  {currentUnit == "kms"
                    ? round(totals.distance / 1000, 1)
                    : round(
                        convertUnits(
                          totals.distance / 1000,
                          "kms",
                          "miles",
                          "distance",
                        ),
                        1,
                      ) || "0"}
                </Typography>
                <Typography variant="body2" className={classes.smallText}>
                  {t("Distance", { ns: "trailhead" })}
                </Typography>
              </div>
              <div>
                <Typography variant="body2" className={classes.centerText}>
                  {currentUnit == "kms"
                    ? round(totals.elevation, 1)
                    : round(
                        convertUnits(
                          totals.elevation,
                          "kms",
                          "miles",
                          "elevation",
                        ),
                        1,
                      ) || "0"}
                </Typography>
                <Typography variant="body2" className={classes.smallText}>
                  {t("Elevation", { ns: "trailhead" })}
                </Typography>
              </div>
              <div>
                <Typography variant="body2" className={classes.centerText}>
                  {buildHours(totals.time) || ""}
                </Typography>
                <Typography variant="body2" className={classes.smallText}>
                  {t("Time", { ns: "trailhead" })}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation(["trailhead", "challenges", "dashboard"])(memo(BadgeCard)),
);
