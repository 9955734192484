import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid, Button, Typography, Switch } from "@material-ui/core";
import { withTranslation } from "react-i18next";

import PlanFieldTransition from "./plan-field-transition";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { dateFormat } from "../../../lib/date-helper";
import moment from "moment";
import FontAwesomeIcon from "../../common/font-awesome-icon";
import { connect } from "react-redux";
import UserPlansService from "../../../services/user-plans-service";
import { updatePlan } from "../../../actions/user-plans-action";
import classNames from "classnames";

const styles = (theme) => ({
  gridItem: {
    paddingTop: theme.spacing.unit * 5,
    paddingRight: 16,
    paddingLeft: 16,
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    borderRadius: "10px",
  },
  buttonDisabled: {
    "&:disabled": {
      backgroundColor: "#e0e0e0",
    },
  },
  buttonText: {
    background: "rgba(255, 255, 255, 0.3)",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 1.7,
    paddingLeft: theme.spacing.unit * 1.7,
    marginRight: theme.spacing.unit * 2,
    width: 20,
  },
  buttonContainer: {
    paddingBottom: theme.spacing.unit * 5,
  },
  white: {
    color: theme.palette.common.white,
  },

  boldGray: {
    color: "#5F5F5F",
    fontWeight: 600,
    textAlign: "left",
  },

  text: {
    textAlign: "left",
  },

  buttonsContainer: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    marginTop: 40,

    "& button:last-child": {
      marginLeft: "15px",
    },
  },

  disableButtonsContainer: {
    justifyContent: "flex-end",
    display: "flex",
  },

  timePickerContainer: {
    justifyContent: "space-between",
    display: "flex",
    marginTop: 20,
  },

  exampleImg: {
    width: "100%",
    margin: "20px 0 40px",
  },

  switchParent: {
    display: "flex",
    alignItems: "center",
    marginTop: 6,
  },
});

class PlanFieldTimeSelect extends Component {
  state = {
    time: null,
    loading: false,
  };

  handleButtonClick = () => {
    const { onSelect, id } = this.props;
    const { time } = this.state;

    if (onSelect || time) {
      onSelect({
        id,
        value: time
          ? {
              showWorkoutNotifications: true,
              workoutNotificationsTime: time,
            }
          : null,
      });
    }
  };

  handleButtonClickNotNow = () => {
    const { onSelect, disableButton } = this.props;

    if (disableButton) this.handleDisableNotifications();

    if (onSelect) {
      onSelect();
    }
  };

  handleDisableNotifications = async () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans || {};

    await UserPlansService.cancelNotifications(currentPlan.cognito_user_sub);
    await this.props.dispatch(
      updatePlan(
        {
          cognito_user_sub: currentPlan.cognito_user_sub,
          showWorkoutNotifications: false,
        },
        false,
        false,
      ),
    );
  };

  componentDidMount() {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans || {};
    this.setState({
      ...this.state,
      checked: !currentPlan.showWorkoutNotifications,
      time: currentPlan.workoutNotificationsTime
        ? currentPlan.workoutNotificationsTime
        : null,
    });
  }

  handleSwitch = (status) => {
    if (status) {
      this.handleDisableNotifications();
      this.setState({ ...this.state, time: null });
    }

    this.setState({ ...this.state, checked: status });
  };

  handleTimeChange = (momentObject) => {
    const { onError } = this.props;
    if (momentObject) {
      try {
        this.setState({
          ...this.state,
          time: momentObject,
        });
      } catch (exp) {
        if (onError) onError(exp);
      }
    } else {
      this.setState({
        ...this.state,
        time: undefined,
      });
    }
  };

  render() {
    const {
      classes,
      xs,
      sm,
      md,
      lg,
      xl,
      t,
      userPlans = {},
      disableButton,
    } = this.props;
    const { time } = this.state;
    const { currentPlan } = userPlans || {};
    const isInProfile = window.location.pathname.includes("/profile");

    return (
      <PlanFieldTransition>
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          className={classes.gridItem}
        >
          <Typography className={classes.boldGray} variant="body2">
            {t("Example", { ns: "layout" })}
          </Typography>

          <img
            src={`${process.env.PUBLIC_URL}/workout-notification.png`}
            alt=""
            className={classes.exampleImg}
          />

          {(!isInProfile || true) && (
            <>
              <Typography className={classes.text} variant="body2">
                {t("Choose a time to receive your preview", { ns: "layout" })}
              </Typography>

              <div className={classes.timePickerContainer}>
                <Typography className={classes.boldGray} variant="body2">
                  {t("Time", { ns: "layout" })}
                </Typography>

                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <TimePicker
                    autoOk
                    ampm={true}
                    openTo="hours"
                    value={
                      moment(time || null).toISOString() ||
                      currentPlan.workoutNotificationsTime
                    }
                    onChange={this.handleTimeChange}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div className={classes.buttonsContainer}>
                <Button
                  variant="contained"
                  onClick={this.handleButtonClickNotNow}
                  className={classNames(classes.button, classes.buttonDisabled)}
                  disabled={
                    disableButton && !currentPlan.showWorkoutNotifications
                  }
                >
                  {!disableButton
                    ? t("Not Now", { ns: "layout" })
                    : t("Disable notifications", { ns: "layout" })}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleButtonClick}
                  className={classes.button}
                  disabled={!time}
                >
                  {t("Enable notifications", { ns: "layout" })}
                </Button>
              </div>
            </>
          )}
        </Grid>
      </PlanFieldTransition>
    );
  }
}

PlanFieldTimeSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  t: PropTypes.func.isRequired,
  userPlans: PropTypes.any,
  disableButton: PropTypes.bool,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withTranslation("constants, layout")(
    withStyles(styles, { withTheme: true })(PlanFieldTimeSelect),
  ),
);
