import React, { useEffect, useRef, useState } from "react";
import useCreatePost from "../../../hooks/useCreatePost";
import { Box } from "@mui/material";
import DashboardGpsMapView from "../../dashboard/dashboard-gps/dashboard-gps-map-view";
import CommunityChangePhotoButton from "./CommunityChangePhotoButton";
import unitsHook from "../../../hooks/unitsHook";
import { useTranslation } from "react-i18next";
import { round } from "../../../lib/units-helper";
import Skeleton from "react-loading-skeleton";

const PostCanvas = ({
  overlay = false,
  squareFormat = false,
  data = false,
  activityData = false,
  elevationData = false,
  speedData = false,
  setGeneratedImage,
}) => {
  const canvasRef = useRef(null);
  const { selectedImage, activity, handleSetImage } = useCreatePost();
  const { currentUnit } = unitsHook();
  const { t } = useTranslation("community");
  const [samples, setSamples] = useState([]);
  const [elevationSamples, setElevationSamples] = useState([]);
  const [map, setMap] = useState(false);
  const [loadingMap, setLoadingMap] = useState(true);

  const handleScreenshotReady = (image) => {
    handleSetImage(image);
    setLoadingMap(false);
    setMap(false);
  };

  const onFinishDraw = (image) => {
    setGeneratedImage(image);
  };

  const handleOverlay = (ctx, canvas) => {
    if (overlay) {
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
  };

  const handleLogo = (ctx, canvas) => {
    const overlayImg = new Image();
    overlayImg.src = `${process.env.PUBLIC_URL}/share-bkg.png`;
    overlayImg.onload = () => {
      ctx.drawImage(overlayImg, 0, 0, canvas.width, canvas.width);
    };
  };

  const handleDistanceData = (ctx, canvas) => {
    const distance = activity.distance.toFixed(activity.distance > 99 ? 0 : 2);

    ctx.font = `700 ${canvas.width * 0.075}px "Karla"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      distance,
      canvas.width * 0.08,
      canvas.height - canvas.width * 0.11,
    );

    const distanceMeasure = ctx.measureText(distance);

    ctx.font = `400 ${canvas.width * 0.037}px "Karla"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      currentUnit === "miles"
        ? `${t("community.activityGen.overlayDistanceMiles")}`
        : `${t("community.activityGen.overlayDistanceMeters")}`,
      canvas.width * 0.09 + distanceMeasure.width,
      canvas.height - canvas.width * 0.12,
    );

    ctx.font = `400 ${canvas.width * 0.032}px "Karla"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      t("community.activityGen.overlayDistance"),
      canvas.width * 0.08,
      canvas.height - canvas.width * 0.064,
    );
  };

  const handleElevationData = (ctx, canvas) => {
    const elevation = round(
      activity.elevation,
      currentUnit === "miles" || activity.elevation > 9999 ? 0 : 2,
    );

    ctx.font = `400 ${canvas.width * 0.037}px "Karla"`;

    ctx.font = `700 ${canvas.width * 0.075}px "Karla"`;
    ctx.fillStyle = "white";

    const elevationMeasure = ctx.measureText(elevation);

    ctx.fillText(
      elevation,
      canvas.width / 2 - elevationMeasure.width / 2,
      canvas.height - canvas.width * 0.11,
    );

    ctx.font = `400 ${canvas.width * 0.037}px "Karla"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      currentUnit === "miles"
        ? `${t("community.activityGen.overlayElevationMiles")}`
        : `${t("community.activityGen.overlayElevationMeters")}`,
      canvas.width / 2 -
        elevationMeasure.width / 2 +
        elevationMeasure.width +
        canvas.width * 0.01,
      canvas.height - canvas.width * 0.12,
    );

    ctx.font = `400 ${canvas.width * 0.032}px "Karla"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      t("community.activityGen.overlayElevation"),
      canvas.width / 2 - elevationMeasure.width / 2,
      canvas.height - canvas.width * 0.064,
    );
  };

  const handleTimeData = (ctx, canvas) => {
    var h = Math.floor(activity.time / 3600);
    var m = Math.floor((activity.time % 3600) / 60);
    const time = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;

    ctx.font = `700 ${canvas.width * 0.075}px "Karla"`;

    const timeMeasure = ctx.measureText(time);

    ctx.fillStyle = "white";
    ctx.fillText(
      time,
      canvas.width - timeMeasure.width - canvas.width * 0.08,
      canvas.height - canvas.width * 0.11,
    );

    ctx.font = `400 ${canvas.width * 0.032}px "Karla"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      t("community.activityGen.overlayTime"),
      canvas.width - timeMeasure.width - canvas.width * 0.08,
      canvas.height - canvas.width * 0.064,
    );
  };

  const handleElevation = (ctx, canvas) => {
    const justY = elevationSamples.map((e) => e.y);

    const minY = Math.min(...justY);
    const maxY = Math.max(...justY);

    ctx.strokeStyle = "#FFFFFF";
    ctx.lineWidth = 1.5;

    ctx.beginPath();
    ctx.moveTo(-10, canvas.height - canvas.width * 0.186);

    elevationSamples.map((e) => {
      const value = {
        x:
          (e.x * canvas.width) /
          elevationSamples[elevationSamples.length - 1].x,
        y:
          canvas.height -
          canvas.width * 0.186 -
          ((e.y - minY) * (canvas.width * 0.2)) / (maxY - minY),
      };

      ctx.lineTo(value.x, value.y);
    });

    ctx.lineTo(canvas.width + 10, canvas.height - canvas.width * 0.186);

    ctx.lineTo(canvas.width + 10, canvas.height + 10);
    ctx.lineTo(-10, canvas.height + 10);
    ctx.closePath();
    ctx.stroke();
  };

  const handleSpeed = (ctx, canvas) => {
    const uphillPace = round(activity.uphillPace || 0, 0);
    ctx.font = `500 ${canvas.width * 0.04}px "Karla"`;
    ctx.fillStyle = "white";
    ctx.fillText(uphillPace, canvas.width * 0.08, canvas.width * 0.155);

    const uphillPaceMeasure = ctx.measureText(uphillPace);

    ctx.font = `400 ${canvas.width * 0.032}px "Karla"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      currentUnit === "miles"
        ? `${t("community.activityGen.overlayUphillPaceMiles")}`
        : `${t("community.activityGen.overlayUphillPaceMeters")}`,
      canvas.width * 0.09 + uphillPaceMeasure.width,
      canvas.width * 0.155,
    );

    ctx.font = `400 ${canvas.width * 0.032}px "Karla"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      t("community.activityGen.overlayUphillPace"),
      canvas.width * 0.08,
      canvas.width * 0.205,
    );

    const downhillPace = round(activity.downhillPace || 0, 0);
    ctx.font = `500 ${canvas.width * 0.04}px "Karla"`;
    ctx.fillStyle = "white";
    ctx.fillText(downhillPace, canvas.width * 0.08, canvas.width * 0.322);

    const downhillPaceMeasure = ctx.measureText(downhillPace);

    ctx.font = `400 ${canvas.width * 0.032}px "Karla"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      currentUnit === "miles"
        ? `${t("community.activityGen.overlayDownhillPaceMiles")}`
        : `${t("community.activityGen.overlayDownhillPaceMeters")}`,
      canvas.width * 0.09 + downhillPaceMeasure.width,
      canvas.width * 0.322,
    );

    ctx.font = `400 ${canvas.width * 0.032}px "Karla"`;
    ctx.fillStyle = "white";
    ctx.fillText(
      t("community.activityGen.overlayDownhillPace"),
      canvas.width * 0.08,
      canvas.width * 0.372,
    );
  };

  const handleData = (ctx, canvas) => {
    if (data) {
      if (activityData) {
        handleDistanceData(ctx, canvas);
        handleElevationData(ctx, canvas);
        handleTimeData(ctx, canvas);
      }
      if (elevationData) {
        handleElevation(ctx, canvas);
      }
      if (speedData) {
        handleSpeed(ctx, canvas);
      }
    }
  };

  useEffect(() => {
    if (activity != null) {
      const latlngSamples = activity.samples
        ? activity.samples
            .map((s) => ({ latitude: s.latlng[0], longitude: s.latlng[1] }))
            .filter((ll) => ll && ll.latitude && ll.longitude)
        : [];

      const elevationSamples = activity.samples
        ? activity.samples.map((s) => {
            return {
              x: s.distance,
              y: s.elevation,
            };
          })
        : [];
      elevationSamples.sort((a, b) => a.x - b.x);

      setSamples(latlngSamples);
      setElevationSamples(elevationSamples);
      setMap(true);
    }
  }, [activity]);

  useEffect(() => {
    if (selectedImage && !loadingMap) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.src = selectedImage;
      img.onload = () => {
        const canvasRatio = canvas.width / canvas.height;
        const imageRatio = img.width / img.height;

        let scaledWidth, scaledHeight;
        if (imageRatio > canvasRatio) {
          scaledHeight = canvas.height;
          scaledWidth = img.width * (scaledHeight / img.height);
        } else {
          scaledWidth = canvas.width;
          scaledHeight = img.height * (scaledWidth / img.width);
        }

        const x = (canvas.width - scaledWidth) / 2;
        const y = (canvas.height - scaledHeight) / 2;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, x, y, scaledWidth, scaledHeight);

        handleOverlay(ctx, canvas);
        handleLogo(ctx, canvas);
        handleData(ctx, canvas);

        setTimeout(() => {
          onFinishDraw(canvas.toDataURL("image/jpeg"));
        }, 500);
      };

      img.onerror = (error) => {
        console.error("Error loading image:", error);
      };
    }
  }, [
    squareFormat,
    activity,
    overlay,
    data,
    activityData,
    elevationData,
    speedData,
    selectedImage,
    loadingMap,
  ]);
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ position: "relative", overflow: "hidden" }}
      >
        {loadingMap ? (
          <Skeleton
            width={!squareFormat ? 400 : 400}
            height={!squareFormat ? 400 : 800}
          />
        ) : (
          <>
            <canvas
              ref={canvasRef}
              width={!squareFormat ? 400 : 400}
              height={!squareFormat ? 400 : 800}
            />
            <CommunityChangePhotoButton top="10px" left="20px" />
          </>
        )}
      </Box>
      {map && (
        <div
          style={{
            width: 400 * 2,
            height: 800 * 2,
            position: "absolute",
            zIndex: -100,
          }}
        >
          <DashboardGpsMapView
            trackingData={samples}
            onScreenshotReady={handleScreenshotReady}
            withScreenshot={true}
            mapId={`map${new Date().getTime()}`}
          />
        </div>
      )}
    </>
  );
};

export default PostCanvas;
