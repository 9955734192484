import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import {
  LinearProgress,
  FormControl,
  InputLabel,
  NativeSelect,
  Fab,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";

import {
  changeSearchPhrase,
  generalSearchWorkouts,
  cleanSearch,
  changeSearchFilter,
} from "../../actions/workout-action";
import AthleteTrainingWorkout from "./athlete-training-workout-search/athlete-training-workout";
import WorkoutTypes from "../../constants/workout-types";
import EstimatedTimes from "../../constants/estimated-times";
import DifficultyLevels from "../../constants/difficulty-levels";
import Surfaces from "../../constants/surfaces";
import TrainingPeriods from "../../constants/training-periods";
import SearchIcon from "@material-ui/icons/Search";
import UserGroups from "../../constants/user-groups";

const styles = (theme) => ({
  mainContainer: {
    paddingLeft: 16,
  },
  container: {
    flex: 0.25,
    textAlign: "center",
    minHeight: 585,
    maxHeight: 585,
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    minWidth: 200,
    background: "#FFFFFF",
    borderRadius: 20,
  },
  textField: {
    padding: theme.spacing.unit,
  },
  emptyWorkout: {
    padding: theme.spacing.unit * 2,
    opacity: 0.4,
  },
  filterContainer: {
    padding: "0 10px 16px",
    background: "#FFFFFF",
    borderRadius: 20,
    marginBottom: 10,
  },
  searchButtonContainer: {
    textAlign: "right",
    width: "100%",
  },
  searchButton: {
    marginTop: 10,
  },
});

class AthleteTrainingWorkoutSearch extends Component {
  componentDidMount() {
    const { auth } = this.props;
    const { currentUserGroups } = auth;

    // const isExternalCoach = false;
    // currentUserGroups &&
    // currentUserGroups.findIndex(
    //   (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
    // ) > -1;

    if (this.props.isExternalCoach) {
      this.props.dispatch(
        changeSearchFilter({ name: "justMine", values: true }),
      );
    } else {
      this.props.dispatch(
        changeSearchFilter({ name: "justMine", values: false }),
      );
    }
  }

  componentDidUpdate = (prevProps) => {
    const { isExternalCoach } = this.props;
    if (prevProps.isExternalCoach !== isExternalCoach) {
      if (isExternalCoach) {
        this.props.dispatch(
          changeSearchFilter({ name: "justMine", values: true }),
        );
        return;
      }

      this.props.dispatch(
        changeSearchFilter({ name: "justMine", values: false }),
      );
    }
  };

  componentWillUnmount() {
    this.props.dispatch(cleanSearch());
  }

  handleSearchPress = () => {
    const { workout } = this.props;
    const { currentFilter } = workout;
    this.props.dispatch(generalSearchWorkouts(currentFilter));
  };

  handleSearchPhraseChanged = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.props.dispatch(changeSearchPhrase(value));
      }
    }
  };

  handleSearchFilterChanged = (value) => {
    this.props.dispatch(changeSearchFilter(value));
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleSearchPress();
    }
  };

  render() {
    const { classes, workout, onSelectWorkout, auth } = this.props;
    const { currentFilter, searchResult, loading, hasBeenSearched } = workout;
    const {
      searchPhrase,
      type,
      duration,
      level,
      surface,
      period,
      justMine,
    } = currentFilter;
    const { data: dataFull } = searchResult;

    const { currentUserGroups, currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes;

    const isExternalCoach =
      currentUserGroups &&
      currentUserGroups.findIndex(
        (group) => group === UserGroups.USER_GROUP_EXTERNAL_COACH,
      ) > -1;

    let data = dataFull;
    if (justMine) {
      data = dataFull.filter((w) => w.created_by_cognito_user_sub === sub);
    }

    return (
      <div className={classes.mainContainer}>
        <div className={classes.filterContainer}>
          {isExternalCoach ? (
            <FormControlLabel
              control={
                <Switch
                  checked={justMine}
                  onChange={(event) =>
                    this.handleSearchFilterChanged({
                      name: "justMine",
                      values: !justMine,
                    })
                  }
                  name="overlay"
                  color="primary"
                />
              }
              label={"Just show my workouts"}
              classes={{
                label: classes.switchLabel,
              }}
            />
          ) : (
            ""
          )}
          <TextField
            name="searchPhrase"
            label="Search Workouts..."
            value={searchPhrase}
            onChange={this.handleSearchPhraseChanged}
            onKeyPress={this.handleKeyPress}
            margin="none"
            fullWidth
          />
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Type</InputLabel>
            <NativeSelect
              value={type}
              onChange={(event) =>
                this.handleSearchFilterChanged({
                  name: "type",
                  values: event.target.value,
                })
              }
            >
              <option aria-label="None" value="" />
              {WorkoutTypes.itemsArray.map((w, index) => (
                <option value={w.value} key={index}>
                  {w.text}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Duration</InputLabel>
            <NativeSelect
              value={duration}
              onChange={(event) =>
                this.handleSearchFilterChanged({
                  name: "duration",
                  values: event.target.value,
                })
              }
            >
              <option aria-label="None" value="" />
              {EstimatedTimes.itemsArray.map((w, index) => (
                <option value={w.value} key={index}>
                  {w.text}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Level</InputLabel>
            <NativeSelect
              value={level}
              onChange={(event) =>
                this.handleSearchFilterChanged({
                  name: "level",
                  values: event.target.value,
                })
              }
            >
              <option aria-label="None" value="" />
              {DifficultyLevels.itemsArray.map((w, index) => (
                <option value={w.value} key={index}>
                  {w.text}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Surface</InputLabel>
            <NativeSelect
              value={surface}
              onChange={(event) =>
                this.handleSearchFilterChanged({
                  name: "surface",
                  values: event.target.value,
                })
              }
            >
              <option aria-label="None" value="" />
              {Surfaces.itemsArray.map((w, index) => (
                <option value={w.value} key={index}>
                  {w.text}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Training Period</InputLabel>
            <NativeSelect
              value={period}
              onChange={(event) =>
                this.handleSearchFilterChanged({
                  name: "period",
                  values: event.target.value,
                })
              }
            >
              <option aria-label="None" value="" />
              {TrainingPeriods.itemsArray.map((w, index) => (
                <option value={w.value} key={index}>
                  {w.text}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <div className={classes.searchButtonContainer}>
            <Fab
              color="primary"
              aria-label="search"
              size="small"
              onClick={this.handleSearchPress}
              className={classes.searchButton}
            >
              <SearchIcon fontSize="small" />
            </Fab>
          </div>
        </div>
        <div className={classes.container}>
          {loading ? (
            <LinearProgress variant="indeterminate" color="primary" />
          ) : hasBeenSearched ? (
            data.length > 0 ? (
              <AthleteTrainingWorkout
                workouts={data}
                onSelectWorkout={onSelectWorkout}
                level={level}
              />
            ) : (
              <div className={classes.workoutContainer}>
                No workouts found based on your current search criteria
              </div>
            )
          ) : (
            <div className={classes.emptyWorkout}>
              To search workouts, fill the search area with filters and press
              the search button
            </div>
          )}
        </div>
      </div>
    );
  }
}

AthleteTrainingWorkoutSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectWorkout: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    workout: store.workout,
    auth: store.auth,
  };
})(withStyles(styles)(AthleteTrainingWorkoutSearch));
