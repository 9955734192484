import React, { useState } from "react";
import {
  Box,
  Link,
  Slide,
  Dialog,
  Typography,
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  IconButton,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import ToolbarReturnBack from "../../../components/common/v2/ToolbarReturnBack";
import history from "../../../lib/history-helper";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthContext } from "../../../context/AuthProvider";
import {
  IconEye,
  IconEyeClosed,
  IconMail,
  IconUserCircle,
} from "@tabler/icons-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const OnboardingSignUpEmail = () => {
  const [openDialog, setOpenDialog] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const {
    email,
    setEmail,
    signUp,
    name,
    setName,
    password,
    setPassword,
    errorMessage,
    globalErrorMessage,
    resetErrorMessage,
    signUpLoading,
  } = useAuthContext();

  const { t } = useTranslation("onboarding");
  const classes = useStyles();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleTransitionEnd = () => {
    resetErrorMessage();
    setEmail("");
    setPassword("");
    history.goBack();
  };

  const handleLogIn = () => {
    resetErrorMessage();
    history.push("/auth/log-in");
  };

  const handleContinue = () => {
    resetErrorMessage();
    signUp();
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionProps={{ onExited: handleTransitionEnd }}
      TransitionComponent={Transition}
      display={"flex"}
      flexDirection="column"
    >
      <ToolbarReturnBack
        withLogo={true}
        logoWidth={107}
        style={{
          backgroundColor: "#FAFAFA",
          alignItems: "flex-start",
          padding: "10px",
        }}
        goBack={handleCloseDialog}
      />

      <Box className={classes.parentBox}>
        <Box className={classes.contentContainer}>
          <Box className={classes.titleBox}>
            <Typography className={classes.title}>
              {t("onboarding.signup.title")}
            </Typography>
          </Box>
          <FormControl fullWidth error={errorMessage} hiddenLabel size="small">
            <OutlinedInput
              first
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder={t("onboarding.signup.namePlaceholder")}
              color="secondary"
              aria-label="naked"
              className={classes.inputForm}
              startAdornment={
                <InputAdornment
                  position="start"
                  data-testid="edit-icon"
                  id="edit-icon"
                >
                  <IconUserCircle color="#6D6F71" stoke={1} />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl fullWidth error={errorMessage} hiddenLabel size="small">
            <OutlinedInput
              first
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder={t("onboarding.signup.emailPlaceholder")}
              color="secondary"
              aria-label="naked"
              className={classes.inputForm}
              startAdornment={
                <InputAdornment
                  position="start"
                  data-testid="edit-icon"
                  id="edit-icon"
                >
                  <IconMail color="#6D6F71" stoke={1} />
                </InputAdornment>
              }
            />
            <FormHelperText className={classes.errorCardText}>
              {t(errorMessage)}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth error={errorMessage} hiddenLabel size="small">
            <OutlinedInput
              first
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder={t("onboarding.signup.passwordPlaceholder")}
              color="secondary"
              aria-label="naked"
              className={classes.inputForm}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? (
                    <IconEye color="#6D6F71" stroke={1} />
                  ) : (
                    <IconEyeClosed color="#6D6F71" stroke={1} />
                  )}
                </IconButton>
              }
            />
          </FormControl>
        </Box>
        {globalErrorMessage != "" && (
          <Typography variant="caption" color="error">
            {t(globalErrorMessage)}
          </Typography>
        )}
        <Typography className={classes.termsText}>
          {t("onboarding.signup.bySigning")}{" "}
          <Link
            href="https://vert.run/terms-of-use/"
            target="_blank"
            underline="none"
            className={classes.termsButton}
          >
            {t("onboarding.signup.terms")}{" "}
          </Link>
          {t("onboarding.signup.viewOur")}{" "}
          <Link
            href="https://vert.run/eula/"
            target="_blank"
            underline="none"
            className={classes.termsButton}
          >
            {t("onboarding.signup.eula")}
          </Link>
        </Typography>
        <Button
          disabled={signUpLoading}
          variant="contained"
          onClick={handleContinue}
          className={classes.logInButton}
        >
          {signUpLoading ? (
            <CircularProgress />
          ) : (
            t("onboarding.signup.continueButton")
          )}
        </Button>
        <Typography className={classes.signUpText}>
          {t("onboarding.signup.haveAccount")}{" "}
          <Link
            onClick={handleLogIn}
            underline="none"
            className={classes.loginButton}
          >
            {t("onboarding.signup.logIn")}
          </Link>
        </Typography>
      </Box>
    </Dialog>
  );
};

export default OnboardingSignUpEmail;

const useStyles = makeStyles({
  parentBox: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    padding: "16px",
    gap: "12px",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  title: {
    fontFamily: "Oswald",
    fontWeight: 700,
    fontSize: "22px",
    color: "#12151B",
    textTransform: "uppercase",
    lineHeight: "27.5px",
  },

  subtitle: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    color: "#12151B",
    lineHeight: "24px",
  },
  contentContainer: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    marginTop: "40px",
  },
  emailButton: {
    backgroundColor: "#FEFFFF",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  googleButton: {
    backgroundColor: "#FEFFFF",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  facebookButton: {
    backgroundColor: "#3C5A9A",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  appleButton: {
    backgroundColor: "#12151B",
    justifyContent: "flex-start",
    paddingLeft: "100px",
    borderRadius: "4px",
    padding: "16px",
    height: "47px",
    boxShadow: "0px 4px 30px 0px rgba(18, 21, 27, 0.05)",
  },
  darkButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#FEFFFF",
  },
  lightButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#12151B",
  },
  emailButtonText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#0B2B5C",
  },
  logInButton: {
    backgroundColor: "#0B2B5C",
    height: "47px",
    color: "#FFFFFF",
    borderRadius: "4px",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: "Karla",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  dividerText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 700,
  },
  termsText: {
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "21px",
    fontSize: "14px",
    fontWeight: 400,
  },
  signUpText: {
    alignSelf: "center",
    fontFamily: "Karla",
    color: "#616161",
    lineHeight: "22.5px",
    fontSize: "18px",
    fontWeight: 400,
  },
  termsButton: {
    color: "#4780AB",
    fontWeight: 700,
    cursor: "pointer",
  },
  loginButton: {
    color: "#F93C6E",
    fontWeight: 700,
    cursor: "pointer",
  },
  errorCardText: {
    fontFamily: "Karla",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
});
