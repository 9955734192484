import React, { forwardRef } from "react";
import { useSortable, arraySwap } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

function getNewIndex({ id, items, activeIndex, overIndex }) {
  return arraySwap(items, overIndex, activeIndex).indexOf(id);
}

export function SortableGridItem({ children, className, item, styles }) {
  const {
    setNodeRef,
    attributes,
    listeners,
    transition,
    transform,
  } = useSortable({ id: item.sortableId, getNewIndex });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    ...styles,
  };

  return (
    <GridItem
      ref={setNodeRef}
      style={style}
      className={className}
      {...attributes}
      {...listeners}
    >
      {children}
    </GridItem>
  );
}

// eslint-disable-next-line react/display-name
export const GridItem = forwardRef(({ children, ...props }, ref) => {
  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
});
