import React from "react";
import { SvgIcon } from "@material-ui/core";

const CloseIcon = ({ width = 17, height = 17, state = "filled" }) => {
  let fill = "none";
  let stroke = "none";

  if (state === "highlighted") {
    stroke = "#6D6F71";
  } else if (state === "filled") {
    fill = "#6D6F71";
  } else if (state === "outline") {
    stroke = "#6D6F71";
  }

  return (
    <SvgIcon width={width} height={height}>
      <svg
        viewBox={`0 0 ${24} ${24}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.9463 6.06317L17.9467 6.06353C18.1314 6.24827 18.1314 6.57169 17.9467 6.75643L13.0567 11.6464L12.7031 12L13.0567 12.3535L17.9467 17.2435C17.9928 17.2897 18.0294 17.3445 18.0544 17.4048C18.0794 17.4651 18.0922 17.5297 18.0922 17.595C18.0922 17.6602 18.0794 17.7249 18.0544 17.7852C18.0294 17.8455 17.9928 17.9003 17.9467 17.9464C17.9005 17.9926 17.8457 18.0292 17.7854 18.0542C17.7251 18.0791 17.6605 18.092 17.5952 18.092C17.53 18.092 17.4653 18.0791 17.405 18.0542C17.3447 18.0292 17.2899 17.9926 17.2438 17.9464L12.3538 13.0564L12.0002 12.7029L11.6467 13.0564L6.75667 17.9464C6.71052 17.9926 6.65573 18.0292 6.59542 18.0542C6.53512 18.0791 6.47049 18.092 6.40522 18.092C6.33995 18.092 6.27532 18.0791 6.21502 18.0542C6.15472 18.0292 6.09993 17.9926 6.05378 17.9464C6.00762 17.9003 5.97101 17.8455 5.94604 17.7852C5.92106 17.7249 5.9082 17.6602 5.9082 17.595C5.9082 17.5297 5.92106 17.4651 5.94604 17.4048C5.97101 17.3445 6.00763 17.2897 6.05378 17.2435L10.9438 12.3535L11.2973 12L10.9438 11.6464L6.05378 6.75643C6.00762 6.71027 5.97101 6.65548 5.94604 6.59518C5.92106 6.53488 5.9082 6.47025 5.9082 6.40498C5.9082 6.33971 5.92106 6.27508 5.94604 6.21478C5.97101 6.15448 6.00762 6.09969 6.05378 6.05353C6.09993 6.00738 6.15472 5.97077 6.21502 5.94579C6.27532 5.92081 6.33995 5.90796 6.40522 5.90796C6.47049 5.90796 6.53512 5.92081 6.59543 5.94579C6.65573 5.97077 6.71052 6.00738 6.75667 6.05353L11.6467 10.9435L11.9999 11.2967L12.3534 10.9439L17.2434 6.0639L17.2441 6.06317C17.2902 6.017 17.3449 5.98038 17.4052 5.95539C17.4654 5.9304 17.53 5.91754 17.5952 5.91754C17.6604 5.91754 17.725 5.9304 17.7853 5.95539C17.8455 5.98038 17.9002 6.017 17.9463 6.06317Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1"
        />
      </svg>
    </SvgIcon>
  );
};

export default CloseIcon;
