import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PropTypes from "prop-types";

import Languages from "../../../constants/languages";
import { convertToRTFForCDK } from "../../../lib/rtf-helper";
import FilterSelect from "../../common/filter-select";

const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-end",
  },
  title: {
    marginTop: theme.spacing.unit * 1.4,
    marginBottom: theme.spacing.unit,
  },
  description: {
    marginLeft: theme.spacing.unit,
  },
});

class WorkoutEditorMultiLangFields extends Component {
  state = {
    language: "en",
  };

  handleLanguageSelect = ({ name, value }) => {
    this.setState({
      ...this.state,
      language: value,
    });
  };

  handleChange = (event) => {
    const { target } = event;
    if (target) {
      const { name, value } = target;
      const { onChange } = this.props;
      if (name && onChange) {
        const { language } = this.state;
        const { title, description } = this.props;
        const valueObject = name === "title" ? title : description;
        onChange({
          name: name,
          value: {
            ...valueObject,
            [language]: value,
          },
        });
      }
    }
  };

  handleChangeRTEditor = (event, editor) => {
    const value = editor.getData();
    const { onChange } = this.props;
    if (onChange) {
      const { language } = this.state;
      const { description } = this.props;
      const valueObject = description;
      onChange({
        name: "description",
        value: {
          ...valueObject,
          [language]: value,
        },
      });
    }
  };

  render() {
    const { classes, title, description, disabled } = this.props;
    const { language } = this.state;
    return (
      <div>
        <div className={classes.container}>
          <FilterSelect
            autoFocus
            name="language"
            text="lng"
            value={language}
            large={false}
            xsmall
            items={Languages.ItemsShortArray}
            onSelect={this.handleLanguageSelect}
          />
          <TextField
            className={classes.title}
            margin="dense"
            name="title"
            label={`Title ${language}`}
            value={title[language] || ""}
            onChange={this.handleChange}
            fullWidth
            required
            disabled={disabled}
          />
        </div>
        <CKEditor
          editor={ClassicEditor}
          config={{
            link: {
              decorators: {
                openInNewTab: {
                  mode: "manual",
                  label: "Open in a new tab",
                  attributes: {
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                },
              },
            },
            mediaEmbed: {
              previewsInData: true,
              providers: [
                {
                  name: "videofilesvertrun",
                  // A URL regexp or an array of URL regexps:
                  url: /^videofilesvertrun\.s3\.amazonaws\.com\/(.+)/,

                  // To be defined only if the media are previewable:
                  html: (match) => {
                    const url = match[0];
                    return (
                      '<div style="text-align: center; width: 100%">' +
                      '<video width="100%" height="auto" controls>' +
                      `<source src="https://${url}" type="video/mp4" />` +
                      "Your browser does not support the video tag." +
                      "</video>" +
                      "</div>"
                    );
                  },
                },
              ],
            },
            readOnly: disabled,
          }}
          data={convertToRTFForCDK(description[language]) || ""}
          onChange={this.handleChangeRTEditor}
          required
          disabled={disabled}
        />
      </div>
    );
  }
}

WorkoutEditorMultiLangFields.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.object,
  description: PropTypes.object,
};

export default withStyles(styles)(WorkoutEditorMultiLangFields);
