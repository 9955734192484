import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { palette } from "../../../theme/palette";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 8,
    background: palette.white,
    fontFamily: "Oswald",
    overflow: "visible",
    margin: "15px 0",
  },
  cardHeader: {
    padding: "8px 16px",
  },
  cardContentRoot: {
    paddingTop: 0,
    paddingBottom: 5,
  },
  cardFooter: {
    padding: "0 16px 16px 16px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    marginRight: 5,
  },
}));

const CardTemplate = (props) => {
  const classes = useStyles();
  const {
    title,
    titleProps,
    subheader,
    avatar,
    actions,
    children,
    shadow,
    FooterComponent,
    onClick,
  } = props;

  return (
    <Card
      className={classes.root}
      style={{ boxShadow: shadow ? palette.boxShadow : "none" }}
    >
      <CardHeader
        className={classes.cardHeader}
        classes={{
          avatar: classes.avatar,
        }}
        avatar={avatar}
        action={actions}
        title={title}
        titleTypographyProps={titleProps}
        subheader={subheader}
      />
      <CardContent onClick={onClick} className={classes.cardContentRoot}>
        {children}
      </CardContent>

      {FooterComponent && (
        <CardContent className={classes.cardFooter}>
          {FooterComponent}
        </CardContent>
      )}
    </Card>
  );
};

CardTemplate.propTypes = {
  title: PropTypes.string,
  titleProps: PropTypes.object,
  subheader: PropTypes.string,
  avatar: PropTypes.element,
  actions: PropTypes.element,
  children: PropTypes.node.isRequired,
  shadow: PropTypes.bool,
  FooterComponent: PropTypes.element,
  onClick: PropTypes.func,
};

CardTemplate.defaultProps = {
  title: null,
  titleProps: {
    style: { fontWeight: 700, fontSize: 18 },
  },
  subheader: null,
  avatar: null,
  actions: null,
  shadow: true,
  FooterComponent: null,
  onClick: () => console.info("Card clicked"),
};

export default CardTemplate;
