import jimp from "jimp";
import { useEffect, useState } from "react";
import useUserInfo from "./useUserInfo";
import { useDispatch } from "react-redux";
import { Storage } from "aws-amplify";
import { updatePlanImage } from "../actions/user-plans-action";

const useChangeProfilePicture = () => {
  const dispatch = useDispatch();
  const { userPlans } = useUserInfo();
  const { updatingImage, currentPlan, loading } = userPlans;
  const { image } = currentPlan || {};
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const image = await jimp.read(reader.result);
          await image.resize(150, jimp.AUTO);
          const data = await image.getBase64Async(jimp.MIME_JPEG);
          const fileNameArray = file.name ? file.name.split(".") : undefined;
          resolve({
            image: data,
            extension: fileNameArray[fileNameArray.length - 1],
          });
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getAvatarUrl = async () => {
    try {
      const result = await Storage.get(image, { level: "public" });
      console.log("ChangeProfilePicture getAvatarUrl", { result });
      setAvatarUrl(result);
      setAvatarLoading(false);
    } catch (exp) {
      console.error("ChangeProfilePicture getAvatarUrl error:", { exp });
    }
  };

  const handleImageFieldChange = async (value, extension) => {
    console.log("ChangeProfilePicture handleImageFieldChange", {
      value,
      extension,
    });
    await dispatch(
      updatePlanImage({
        cognito_user_sub: currentPlan.cognito_user_sub,
        image: value,
        imageExtension: extension && extension.toLowerCase(),
      }),
    );
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    console.log("ChangeProfilePicture handleInputChange", { event });
    if (event) {
      const { target } = event;
      const maxSize = 5120000;

      if (!target) {
        console.error("ChangeProfilePicture target undefined", { target });
        return;
      }

      if (!target.files) {
        console.error("ChangeProfilePicture target.files undefined", {
          target,
        });
        return;
      }

      const { files } = target;
      const selectedFileTemp = files[0];
      try {
        if (selectedFileTemp) {
          const { size } = selectedFileTemp;
          if (size >= maxSize)
            throw new Error(
              "Sorry, your image needs to be smaller than maxSize",
            );
          setSelectedImage(selectedFileTemp);
        } else {
          throw new Error("Invalid File", selectedFileTemp);
        }
      } catch (exp) {
        console.error("ChangeProfilePicture catch change profile", exp);
      }
    }
  };

  const handleCancel = () => {
    setSelectedImage(null);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const { image: imageString, extension } = await getBase64(selectedImage);
    console.log("ChangeProfilePicture handleSave", {
      selectedImage,
      image,
      extension,
    });
    await handleImageFieldChange(imageString, extension);
  };

  const buttonDisabled = () => {
    const saveDisabled =
      updatingImage || avatarLoading || loading || selectedImage == null;
    return saveDisabled;
  };

  useEffect(() => {
    console.log("ChangeProfilePicture image:", { image });
    if (image) {
      setSelectedImage(null);
      setAvatarLoading(true);
      getAvatarUrl();
    } else {
      setAvatarLoading(false);
    }
  }, [image]);

  useEffect(() => {
    if (selectedImage !== null) {
      getBase64(selectedImage).then((result) => {
        console.log("ChangeProfilePicture getBase64", { result });
        setAvatarUrl(result.image);
      });
    }
  }, [selectedImage]);

  return {
    avatarUrl,
    avatarLoading,
    selectedImage,
    updatingImage,
    loading,
    buttonDisabled,
    handleInputChange,
    handleSave,
    handleCancel,
  };
};

export default useChangeProfilePicture;
