import React, { useRef, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { withStyles, withWidth } from "@material-ui/core";
import "leaflet-simple-map-screenshoter";

const styles = (theme) => ({
  mapview: {
    height: "100%",
  },
});

const DashboardGpsMapView = ({
  classes,
  t,
  trackingData,
  withScreenshot = false,
  onScreenshotReady,
  mapId,
}) => {
  const mapRef = useRef(null);
  const [userMarker, setUserMarker] = React.useState(null);
  const [path, setPath] = React.useState(null);
  const [screenshoter, setScreenshoter] = React.useState(null);

  function getMiddle(prop, markers) {
    let values = markers.map((m) => m[prop]);
    let min = Math.min(...values);
    let max = Math.max(...values);
    if (prop === "lng" && max - min > 180) {
      values = values.map((val) => (val < max - 180 ? val + 360 : val));
      min = Math.min(...values);
      max = Math.max(...values);
    }
    let result = (min + max) / 2;
    if (prop === "lng" && result > 180) {
      result -= 360;
    }
    return result;
  }

  function findCenter(markers) {
    return {
      latitude: getMiddle("latitude", markers),
      longitude: getMiddle("longitude", markers),
    };
  }

  useEffect(() => {
    if (trackingData && !mapRef.current) {
      if ((!trackingData || !trackingData.length) && withScreenshot) {
        onScreenshotReady(`${process.env.PUBLIC_URL}/bkg_gps.png`);
        return;
      }

      const center = findCenter(trackingData);

      if (mapRef.current) {
        mapRef.current.off();
        mapRef.current.remove();
      }

      const mapInstance = window.L.map(mapId, {
        zoomControl: !withScreenshot,
      }).setView(new window.L.LatLng(center.latitude, center.longitude), 16);

      mapRef.current = mapInstance;

      const tileLayer = window.L.tileLayer(
        "https://api.maptiler.com/maps/outdoor/{z}/{x}/{y}.png?key=HYQqXwUGhRkIp3nBx2ra",
      ).addTo(mapInstance);

      const polyline = window.L.polyline(
        [
          trackingData
            .filter((p) => !p.paused)
            .map((point) => [point.latitude, point.longitude]),
        ],
        {
          color: "blue",
          weight: 2,
          opacity: 0.5,
          smoothFactor: 1,
        },
      );
      polyline.addTo(mapInstance);
      setPath(polyline);
      mapInstance.fitBounds(polyline.getBounds());

      if (withScreenshot) {
        mapInstance.dragging.disable();
        mapInstance.touchZoom.disable();
        mapInstance.doubleClickZoom.disable();
        mapInstance.scrollWheelZoom.disable();
        mapInstance.boxZoom.disable();
        mapInstance.keyboard.disable();

        const simpleMapScreenshoter = window.L.simpleMapScreenshoter({
          hidden: true, // hide screen btn on map
        }).addTo(mapInstance);

        setScreenshoter(simpleMapScreenshoter);

        tileLayer.on("load", () => {
          /* Had to add this for iOS, first screenshot doesn't work */
          setTimeout(() => {
            simpleMapScreenshoter
              .takeScreen("image", {
                onPixelDataFail: async function({
                  node,
                  plugin,
                  error,
                  mapPane,
                  domtoimageOptions,
                }) {
                  // Solutions:
                  // decrease size of map
                  // or decrease zoom level
                  // or remove elements with big distanses
                  // and after that return image in Promise - plugin._getPixelDataOfNormalMap
                  console.log(error);
                  return plugin._getPixelDataOfNormalMap(domtoimageOptions);
                },
              })
              .then((image) => {})
              .catch((e) => {
                console.log(e);
              });
          }, 1000);
          /* ***** */

          setTimeout(() => {
            simpleMapScreenshoter
              .takeScreen("image", {
                onPixelDataFail: async function({
                  node,
                  plugin,
                  error,
                  mapPane,
                  domtoimageOptions,
                }) {
                  // Solutions:
                  // decrease size of map
                  // or decrease zoom level
                  // or remove elements with big distanses
                  // and after that return image in Promise - plugin._getPixelDataOfNormalMap
                  console.log(error);
                  return plugin._getPixelDataOfNormalMap(domtoimageOptions);
                },
              })
              .then((image) => {
                onScreenshotReady(image);
              })
              .catch((e) => {
                console.log(e);
                onScreenshotReady(null);
              });
          }, 3000);
        });
      }
    }
  }, [trackingData]);

  return <div id={mapId} className={classes.mapview}></div>;
};

export default withTranslation("dashboard")(
  withWidth()(withStyles(styles, { withTheme: true })(DashboardGpsMapView)),
);
