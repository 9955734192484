import React from "react";
import {
  Box,
  ButtonBase,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { palette } from "../../../theme/palette";
import ModalCustom from "./ModalCustom";
import CustomButton from "./CustomButton";
import HighlightText from "./HighlightText";
import dayjs from "dayjs";
import { updatePlanAddEasyWeek } from "../../../actions/user-plans-action";
import { useDispatch } from "react-redux";
import { dateFormat } from "../../../lib/date-helper";
import PaywallModal from "./PaywallModal";
import bugsnagClient from "../../../lib/bugsnag-client";
import { BatteryIcon } from "../../icons";

const AddEasyWeek = ({
  currentTraining = {},
  currentPlan = {},
  isSubscribed = false,
  disabled = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("training");
  const dispatch = useDispatch();
  const [isEaseWeek, setIsEasyWeek] = React.useState(false);
  const [isOpenPaywall, setIsOpenPaywall] = React.useState(false);
  const { raceCount = 0, trainPerWeek = null } = currentTraining || {};

  const onCloseEaseWeek = () => setIsEasyWeek(false);

  const openEasyWeek = () => {
    if (isSubscribed) {
      setIsEasyWeek(true);
    } else {
      setIsOpenPaywall(true);
    }
  };

  const onAddEasyWeek = () => {
    if (isSubscribed) {
      try {
        dispatch(
          updatePlanAddEasyWeek(
            {
              cognito_user_sub: currentPlan.cognito_user_sub,
              startDate: dayjs()
                .startOf("isoWeek")
                .format(dateFormat),
            },
            currentTraining,
            raceCount,
            trainPerWeek,
            0,
            false,
          ),
        );
        onCloseEaseWeek();
      } catch (error) {
        bugsnagClient.notify(error);
        onCloseEaseWeek();
      }
    } else {
      setIsOpenPaywall(true);
    }
  };

  return (
    <>
      <PaywallModal
        isOpen={isOpenPaywall}
        onClose={() => setIsOpenPaywall(false)}
      />

      <ButtonBase
        className={classes.cardButton}
        onClick={openEasyWeek}
        disabled={disabled}
      >
        <BatteryIcon fill={palette.black} />
        <Typography className={classes.cardTextButton}>
          {t("training.addEasyWeek")}
        </Typography>
      </ButtonBase>

      {/** Easy week modal  */}
      <ModalCustom
        fullScreen={false}
        open={isEaseWeek}
        onClose={onCloseEaseWeek}
        toolbarJustifyContent="end"
        headerStyles={{
          padding: "8px 5px !important",
        }}
        contentStyles={{
          padding: "8px 20px 10px",
          gap: 8,
        }}
      >
        <Grid container styles={{ gap: "6px 0" }}>
          <Grid item xs={12}>
            <Typography className={classes.modalTitle}>
              {t("training.addEasyWeek.modalTitle.p1")}{" "}
              <HighlightText
                color={palette.primary}
                text={t("training.addEasyWeek.modalTitle.easyWeek")}
              />{" "}
              ?
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "15px 0" }}>
            <Typography className={classes.modalDescription}>
              {t("training.addEasyWeek.modalDescription.p1")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box py={2}>
              <CustomButton
                color="default"
                typeStyle="secondary"
                variant="contained"
                size="large"
                onClick={onCloseEaseWeek}
                fullWidth
              >
                {t("training.modalButtons.cancel")}
              </CustomButton>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box py={2} pl={1}>
              <CustomButton
                color="secondary"
                typeStyle="primary"
                variant="contained"
                size="large"
                onClick={onAddEasyWeek}
                fullWidth
              >
                {t("training.modalButtons.add")}
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </ModalCustom>
    </>
  );
};

const useStyles = makeStyles(() => ({
  modalTitle: {
    fontFamily: "Karla",
    fontSize: 18,
    fontWeight: 700,
    textAlign: "left",
    color: palette.black,
  },
  modalDescription: {
    fontFamily: "Karla",
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",
    color: palette.black,
  },
  cardButton: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "120px",
    padding: "16px 12px",
    gap: 12,
    borderRadius: 4,
    boxShadow: palette.boxShadow,
    "&:hover": {
      backgroundColor: "#F1F6FD",
    },
  },
  cardTextButton: {
    fontFamily: "Karla",
    fontSize: 14,
    fontWeight: 400,
  },
  marginY: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

export default AddEasyWeek;
