import ActionTypes from "../constants/action-types";

const initialState = {
  loadingPosts: false,
  loadingPost: false,
  loadingAthlete: false,
  loadingTags: false,
  loadingUserPosts: false,
  loadingPublicGroups: false,
  loadingPublicGroup: false,

  joiningPublicGroup: false,
  currentPublicGroup: null,

  tags: [],
  publicGroups: [],
  currentFilter: { tags: [], searchPhrase: "" },
  posts: {
    data: [],
    moreData: true,
  },
  currentPost: null,
  selectedAthlete: null,
  addingAllezOn: null,
  addingCommentOn: null,
  addingPost: false,
  title: null,
  text: null,
  image: null,

  loadingGroupPosts: false,
  currentGroupPosts: {
    data: [],
    moreData: true,
  },

  loadingPinnedPosts: false,
  currentPinnedPosts: {
    data: [],
    moreData: true,
  },

  explorePosts: { all: [] },
  currentExplorePost: null,
  currentExplorePostRelated: null,
  loadingExplorePosts: { all: false },
  loadingExplorePost: false,
  loadingRelatedExplorePosts: false,
  exploreFeaturedPost: null,
  loadingExploreFeaturedPost: false,
  favorites: false,
  currentUserPosts: [],
  loadingActivities: false,
  currentAverage: 0,
  loadingUpdateAthlete: true,
};

export default function reducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.TRAIL_HEAD_GET_ATHLETE_PENDING:
        return {
          ...state,
          loadingAthlete: true,
        };
      case ActionTypes.TRAIL_HEAD_GET_ATHLETE_FULFILLED:
        return {
          ...state,
          selectedAthlete: action.payload || null,
          loadingAthlete: false,
        };
      case ActionTypes.TRAIL_HEAD_GET_ATHLETE_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingAthlete: false,
        };
      case ActionTypes.TRAIL_HEAD_UPDATE_PROFILE_PENDING:
        return {
          ...state,
          loadingAthlete: true,
        };
      case ActionTypes.TRAIL_HEAD_UPDATE_PROFILE_FULFILLED:
        const selectedAthlete = state.selectedAthlete;
        return {
          ...state,
          selectedAthlete: { ...selectedAthlete, ...action.payload },
          loadingAthlete: true,
        };
      case ActionTypes.TRAIL_HEAD_UPDATE_PROFILE_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingAthlete: true,
        };

      case ActionTypes.TRAIL_HEAD_GET_TAGS_PENDING:
        return {
          ...state,
          loadingTags: true,
        };
      case ActionTypes.TRAIL_HEAD_GET_TAGS_FULFILLED:
        return {
          ...state,
          tags: action.payload || null,
          loadingTags: false,
        };
      case ActionTypes.TRAIL_HEAD_GET_TAGS_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingTags: false,
        };

      case ActionTypes.TRAIL_HEAD_GET_PUBLIC_GROUPS_PENDING:
        return {
          ...state,
          loadingPublicGroups: true,
        };
      case ActionTypes.TRAIL_HEAD_GET_PUBLIC_GROUPS_FULFILLED:
        return {
          ...state,
          publicGroups:
            action.payload.sort((a, b) => a.display_order - b.display_order) ||
            null,
          loadingPublicGroups: false,
        };
      case ActionTypes.TRAIL_HEAD_GET_PUBLIC_GROUPS_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingPublicGroups: false,
        };

      case ActionTypes.TRAIL_HEAD_JOIN_GROUP_PENDING:
      case ActionTypes.TRAIL_HEAD_LEAVE_GROUP_PENDING:
        return {
          ...state,
          joiningPublicGroup: true,
        };
      case ActionTypes.TRAIL_HEAD_JOIN_GROUP_FULFILLED:
      case ActionTypes.TRAIL_HEAD_LEAVE_GROUP_FULFILLED:
        return {
          ...state,
          currentPublicGroup:
            state.currentPublicGroup &&
            state.currentPublicGroup.id === action.payload.id
              ? action.payload
              : state.currentPublicGroup,
          publicGroups: [
            ...state.publicGroups.filter((g) => g.id !== action.payload.id),
            action.payload,
          ].sort((a, b) => a.display_order - b.display_order),
          joiningPublicGroup: false,
          successMessage: action.payload.isPrivate
            ? "Request to join sent"
            : null,
        };
      case ActionTypes.TRAIL_HEAD_JOIN_GROUP_REJECTED:
      case ActionTypes.TRAIL_HEAD_LEAVE_GROUP_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          joiningPublicGroup: false,
        };

      case ActionTypes.TRAIL_HEAD_GET_GROUP_PENDING:
        return {
          ...state,
          currentPublicGroup: null,
          loadingPublicGroup: true,
        };
      case ActionTypes.TRAIL_HEAD_GET_GROUP_FULFILLED:
        return {
          ...state,
          currentPublicGroup: action.payload,
          loadingPublicGroup: false,
        };
      case ActionTypes.TRAIL_HEAD_GET_GROUP_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          currentPublicGroup: null,
          loadingPublicGroup: false,
        };

      case ActionTypes.TRAIL_HEAD_ADD_GROUP_ACTIVITIES_FULFILLED:
        return {
          ...state,
          currentPublicGroup:
            state.currentPublicGroup &&
            state.currentPublicGroup.id === action.payload.id
              ? action.payload
              : state.currentPublicGroup,
          publicGroups: [
            ...state.publicGroups.filter((g) => g.id !== action.payload.id),
            action.payload,
          ].sort((a, b) => a.display_order - b.display_order),
        };

      case ActionTypes.TRAIL_HEAD_CLEAR_GROUP:
        return {
          ...state,
          currentPublicGroup: null,
          loadingPublicGroup: false,
          title: null,
          text: null,
          image: null,
          imageBase64: null,
          badge: null,
          epicRace: null,
          currentGroupPosts: {
            data: [],
            moreData: true,
          },
        };

      case ActionTypes.TRAIL_HEAD_NEW_POST:
        return {
          ...state,
          title: action.payload.title ? action.payload.title : "",
          text: action.payload.text ? action.payload.text : "",
          image: action.payload.image ? action.payload.image : "",
          imageBase64: action.payload.imageBase64
            ? action.payload.imageBase64
            : "",
          badge: action.payload.badge ? action.payload.badge : "",
          epicRace: action.payload.epicRace ? action.payload.epicRace : "",
        };

      case ActionTypes.TRAIL_HEAD_CLEAN_NEW_POST:
        return {
          ...state,
          title: null,
          text: null,
          image: null,
          imageBase64: null,
          epicRace: null,
          badge: null,
        };

      case ActionTypes.TRAIL_HEAD_GET_POSTS_PENDING:
        return {
          ...state,
          loadingPosts: true,
          posts: {
            data: action.payload.reload ? [] : state.posts.data,
          },
        };
      case ActionTypes.TRAIL_HEAD_GET_POSTS_FULFILLED:
        let newData;
        if (action.payload.reload) {
          newData = action.payload.result.Items;
        } else {
          newData = [...state.posts.data, ...action.payload.result.Items];
        }
        return {
          ...state,
          posts: {
            data: newData,
            moreData: action.payload.result.Items.length > 0,
          },
          loadingPosts: false,
        };
      case ActionTypes.TRAIL_HEAD_GET_POSTS_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingPosts: false,
        };

      case ActionTypes.TRAIL_HEAD_GET_GROUP_POSTS_PENDING:
        return {
          ...state,
          loadingGroupPosts: true,
          currentGroupPosts: {
            data: action.payload.reload ? [] : state.currentGroupPosts.data,
          },
        };
      case ActionTypes.TRAIL_HEAD_GET_GROUP_POSTS_FULFILLED:
        let newDataG;
        if (action.payload.reload) {
          newDataG = action.payload.result.Items;
        } else {
          newDataG = [
            ...state.currentGroupPosts.data,
            ...action.payload.result.Items,
          ];
        }
        return {
          ...state,
          currentGroupPosts: {
            data: newDataG,
            moreData: action.payload.result.Items.length > 0,
          },
          loadingGroupPosts: false,
        };
      case ActionTypes.TRAIL_HEAD_GET_GROUP_POSTS_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingGroupPosts: false,
        };

      case ActionTypes.TRAIL_HEAD_GET_PINNED_POSTS_PENDING:
        return {
          ...state,
          loadingPinnedPosts: true,
          currentPinnedPosts: {
            data: [],
            moreData: true,
          },
        };
      case ActionTypes.TRAIL_HEAD_GET_PINNED_POSTS_FULFILLED:
        let newDataP;
        if (action.payload.reload) {
          newDataP = action.payload.result.Items;
        } else {
          newDataP = [
            ...state.currentPinnedPosts.data,
            ...action.payload.result.Items,
          ];
        }
        return {
          ...state,
          currentPinnedPosts: {
            data: newDataP,
            moreData: action.payload.result.Items.length > 0,
          },
          loadingPinnedPosts: false,
        };
      case ActionTypes.TRAIL_HEAD_GET_PINNED_POSTS_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingPinnedPosts: false,
        };

      case ActionTypes.TRAIL_HEAD_GET_POST_PENDING:
        return {
          ...state,
          loadingPost: true,
          currentPost: null,
        };
      case ActionTypes.TRAIL_HEAD_GET_POST_FULFILLED:
        return {
          ...state,
          currentPost: action.payload,
          loadingPost: false,
        };
      case ActionTypes.TRAIL_HEAD_GET_POST_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingPost: false,
        };

      case ActionTypes.TRAIL_HEAD_GET_EXPLORE_POSTS_PENDING:
        let loadingEP = state.loadingExplorePosts;
        if (action.payload.type) {
          loadingEP = {
            ...loadingEP,
            [action.payload.type]: true,
          };
        } else {
          loadingEP = {
            ...loadingEP,
            all: true,
          };
        }
        return {
          ...state,
          loadingExplorePosts: loadingEP,
          explorePosts: action.payload.reload
            ? { all: { data: [] } }
            : state.explorePosts,
        };
      case ActionTypes.TRAIL_HEAD_GET_EXPLORE_POSTS_FULFILLED:
        let newExplorePosts;
        if (action.payload.reload) {
          if (action.payload.type) {
            newExplorePosts = {
              ...state.explorePosts,
              [action.payload.type]: {
                data: action.payload.result.Items,
                moreData: action.payload.result.Items.length > 0,
              },
            };
          } else {
            newExplorePosts = {
              ...state.explorePosts,
              all: {
                data: action.payload.result.Items,
                moreData: action.payload.result.Items.length > 0,
              },
            };
          }
        } else {
          if (action.payload.type) {
            let currentPosts =
              state.explorePosts[action.payload.type] &&
              state.explorePosts[action.payload.type].data
                ? state.explorePosts[action.payload.type].data
                : [];
            newExplorePosts = {
              ...state.explorePosts,
              [action.payload.type]: {
                data: [...currentPosts, ...action.payload.result.Items],
                moreData: action.payload.result.Items.length > 0,
              },
            };
          } else {
            newExplorePosts = {
              ...state.explorePosts,
              all: {
                data: [
                  ...state.explorePosts.all.data,
                  ...action.payload.result.Items,
                ],
                moreData: action.payload.result.Items.length > 0,
              },
            };
          }
        }

        let loadingEPEnd = state.loadingExplorePosts;
        if (action.payload.type) {
          loadingEPEnd = {
            ...loadingEPEnd,
            [action.payload.type]: false,
          };
        } else {
          loadingEPEnd = {
            ...loadingEPEnd,
            all: false,
          };
        }

        return {
          ...state,
          explorePosts: newExplorePosts,
          loadingExplorePosts: loadingEPEnd,
        };
      case ActionTypes.TRAIL_HEAD_GET_EXPLORE_POSTS_REJECTED:
        let loadingEPEndR = state.loadingExplorePosts;
        if (action.payload.type) {
          loadingEPEndR = {
            ...loadingEPEndR,
            [action.payload.type]: false,
          };
        } else {
          if (action.payload.type) {
            loadingEPEndR = {
              ...loadingEPEndR,
              all: false,
            };
          }
        }

        return {
          ...state,
          errorMessage:
            (action.payload &&
              action.payload.err &&
              action.payload.err.message) ||
            "SERVER ERROR",
          loadingExplorePosts: loadingEPEndR,
        };

      case ActionTypes.TRAIL_HEAD_GET_EXPLORE_POST_PENDING:
        return {
          ...state,
          loadingExplorePost: true,
          currentExplorePost: null,
          currentExplorePostRelated: null,
          loadingRelatedExplorePosts: null,
        };
      case ActionTypes.TRAIL_HEAD_GET_EXPLORE_POST_FULFILLED:
        return {
          ...state,
          currentExplorePost: action.payload,
          loadingExplorePost: false,
        };
      case ActionTypes.TRAIL_HEAD_GET_EXPLORE_POST_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingExplorePost: false,
        };

      case ActionTypes.TRAIL_HEAD_GET_EXPLORE_FEATURED_POST_PENDING:
        return {
          ...state,
          loadingExploreFeaturedPost: true,
          exploreFeaturedPost: null,
        };
      case ActionTypes.TRAIL_HEAD_GET_EXPLORE_FEATURED_POST_FULFILLED:
        return {
          ...state,
          exploreFeaturedPost: action.payload,
          loadingExploreFeaturedPost: false,
        };
      case ActionTypes.TRAIL_HEAD_GET_EXPLORE_FEATURED_POST_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingExploreFeaturedPost: false,
        };

      case ActionTypes.TRAIL_HEAD_GET_RELATED_EXPLORE_POST_PENDING:
        return {
          ...state,
          loadingRelatedExplorePosts: true,
          currentExplorePostRelated: null,
        };
      case ActionTypes.TRAIL_HEAD_GET_RELATED_EXPLORE_POST_FULFILLED:
        return {
          ...state,
          currentExplorePostRelated: action.payload,
          loadingRelatedExplorePosts: false,
        };
      case ActionTypes.TRAIL_HEAD_GET_RELATED_EXPLORE_POST_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingRelatedExplorePosts: false,
        };

      case ActionTypes.TRAIL_HEAD_ADD_ALLEZ_PENDING:
        return {
          ...state,
          addingAllezOn: action.payload.replyId
            ? action.payload.replyId
            : action.payload.commentId
            ? action.payload.commentId
            : action.payload.id,
        };

      case ActionTypes.TRAIL_HEAD_ADD_ALLEZ_FULFILLED:
        const dataAfterNewAllez = [
          ...state.posts.data.filter((p) => p.id !== action.payload.id),
          action.payload,
        ];
        const currentPostAfterNewAllez = state.currentPost
          ? action.payload.id === state.currentPost.id
            ? action.payload
            : state.currentPost
          : null;

        const groupDataAfterNewAllez =
          state.currentGroupPosts.data && state.currentGroupPosts.data.length
            ? [
                ...state.currentGroupPosts.data.filter(
                  (p) => p.id !== action.payload.id,
                ),
                action.payload,
              ]
            : [];

        const pinnedDataAfterNewAllez =
          state.currentPinnedPosts.data && state.currentPinnedPosts.data.length
            ? [
                ...state.currentPinnedPosts.data.filter(
                  (p) => p.id !== action.payload.id,
                ),
                action.payload,
              ]
            : [];

        return {
          ...state,
          posts: {
            data: dataAfterNewAllez,
          },
          currentGroupPosts: {
            data: groupDataAfterNewAllez,
          },
          currentPinnedPosts: {
            data: pinnedDataAfterNewAllez,
          },
          currentPost: currentPostAfterNewAllez,
          addingAllezOn: null,
        };
      case ActionTypes.TRAIL_HEAD_ADD_ALLEZ_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          addingAllezOn: null,
        };

      case ActionTypes.TRAIL_HEAD_ADD_COMMENT_PENDING:
        return {
          ...state,
          addingCommentOn: action.payload.reply_to
            ? action.payload.reply_to
            : action.payload.comment_to,
        };

      case ActionTypes.TRAIL_HEAD_ADD_COMMENT_FULFILLED:
        const dataAfterNewComment = [
          ...state.posts.data.filter((p) => p.id !== action.payload.id),
          action.payload,
        ];
        const currentPostAfterNewComment = state.currentPost
          ? action.payload.id === state.currentPost.id
            ? action.payload
            : state.currentPost
          : null;

        const groupDataAfterNewComment =
          state.currentGroupPosts.data && state.currentGroupPosts.data.length
            ? [
                ...state.currentGroupPosts.data.filter(
                  (p) => p.id !== action.payload.id,
                ),
                action.payload,
              ]
            : [];

        const pinnedDataAfterNewComment =
          state.currentPinnedPosts.data && state.currentPinnedPosts.data.length
            ? [
                ...state.currentPinnedPosts.data.filter(
                  (p) => p.id !== action.payload.id,
                ),
                action.payload,
              ]
            : [];

        return {
          ...state,
          posts: {
            data: dataAfterNewComment,
          },
          currentGroupPosts: {
            data: groupDataAfterNewComment,
          },

          currentPinnedPosts: {
            data: pinnedDataAfterNewComment,
          },
          currentPost: currentPostAfterNewComment,
          addingCommentOn: null,
        };
      case ActionTypes.TRAIL_HEAD_ADD_COMMENT_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          addingCommentOn: null,
        };

      case ActionTypes.TRAIL_HEAD_DELETE_COMMENT_FULFILLED:
      case ActionTypes.TRAIL_HEAD_EDIT_POST_FULFILLED:
        const dataAfterChange = [
          ...state.posts.data.filter((p) => p.id !== action.payload.id),
          action.payload,
        ];
        const currentPostAfterChange = state.currentPost
          ? action.payload.id === state.currentPost.id
            ? action.payload
            : state.currentPost
          : null;

        const groupDataAfterChange =
          state.currentGroupPosts.data && state.currentGroupPosts.data.length
            ? [
                ...state.currentGroupPosts.data.filter(
                  (p) => p.id !== action.payload.id,
                ),
                action.payload,
              ]
            : [];

        const pinnedDataAfterNewChange =
          state.currentPinnedPosts.data && state.currentPinnedPosts.data.length
            ? [
                ...state.currentPinnedPosts.data.filter(
                  (p) => p.id !== action.payload.id,
                ),
                action.payload,
              ]
            : [];
        return {
          ...state,
          posts: {
            data: dataAfterChange,
          },
          currentGroupPosts: {
            data: groupDataAfterChange,
          },
          currentPinnedPosts: {
            data: pinnedDataAfterNewChange,
          },
          currentPost: currentPostAfterChange,
        };
      case ActionTypes.TRAIL_HEAD_DELETE_COMMENT_REJECTED:
      case ActionTypes.TRAIL_HEAD_EDIT_POST_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.TRAIL_HEAD_ADD_POST_PENDING:
        return {
          ...state,
          loadingPosts: true,
          addingPost: true,
        };
      case ActionTypes.TRAIL_HEAD_ADD_POST_FULFILLED:
        return {
          ...state,
          addingPost: false,
        };
      case ActionTypes.TRAIL_HEAD_ADD_POST_REJECTED:
        return {
          ...state,
          loadingPosts: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          addingPost: false,
        };

      case ActionTypes.TRAIL_HEAD_DELETE_POST_FULFILLED:
        let dataAfterDeletePost = state.posts.data.filter(
          (p) => p.id !== action.payload.id,
        );

        let groupDataAfterDeletePost = state.currentGroupPosts.data.filter(
          (p) => p.id !== action.payload.id,
        );

        let pinnedDataAfterDeletePost = state.currentPinnedPosts.data.filter(
          (p) => p.id !== action.payload.id,
        );

        return {
          ...state,
          posts: {
            data: dataAfterDeletePost,
          },
          currentGroupPosts: {
            data: groupDataAfterDeletePost,
          },
          currentPinnedPosts: {
            data: pinnedDataAfterDeletePost,
          },
        };
      case ActionTypes.TRAIL_HEAD_DELETE_POST_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.TRAIL_HEAD_TOGGLE_FAVORITES:
        return {
          ...state,
          favorites: !state.favorites,
        };

      case ActionTypes.TRAIL_HEAD_GET_USER_POSTS_PENDING:
        return {
          ...state,
          loadingUserPosts: true,
        };

      case ActionTypes.TRAIL_HEAD_GET_USER_POSTS_FULFILLED:
        return {
          ...state,
          loadingUserPosts: false,
          currentUserPosts: action.payload,
        };

      case ActionTypes.TRAIL_HEAD_GET_USER_POSTS_REJECTED:
        return {
          ...state,
          loadingUserPosts: false,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
        };

      case ActionTypes.TRAIL_HEAD_GET_USER_ACTIVITY_PENDING:
        return {
          ...state,
          loadingAthlete: false,
        };

      case ActionTypes.TRAIL_HEAD_GET_USER_ACTIVITY_FULFILLED:
        return {
          ...state,
          selectedAthlete: {
            ...state.selectedAthlete,
            activities: action.payload || null,
          },
        };

      case ActionTypes.TRAIL_HEAD_GET_USER_ACTIVITY_REJECTED:
        return {
          ...state,
          errorMessage:
            (action.payload && action.payload.message) || "SERVER ERROR",
          loadingAthlete: false,
        };

      case ActionTypes.UPDATE_SELECTED_ATHLETE_PENDING:
        return {
          ...state,
          loadingUpdateAthlete: action.payload,
        };

      case ActionTypes.UPDATE_SELECTED_ATHLETE_FULLFILLED:
        return {
          ...state,
          loadingUpdateAthlete: false,
          selectedAthlete: {
            ...state.selectedAthlete,
            average: action.payload,
          },
        };

      /*case ActionTypes.ATHLETES_LOAD_IMAGE_URL_PENDING:
        return {
          ...state,
          loadingAthleteImageUrl: true,
          errorMessage: undefined,
        };
      case ActionTypes.ATHLETES_LOAD_IMAGE_URL_FULFILLED:
        let updatedUserPlansForImageUrl = [...state.searchResult.data];
        const foundSelectedIndexForImageUrl = updatedUserPlansForImageUrl.findIndex(
          (userPlan) =>
            userPlan.cognito_user_sub === action.payload.cognito_user_sub,
        );
        if (foundSelectedIndexForImageUrl > -1) {
          updatedUserPlansForImageUrl.splice(foundSelectedIndexForImageUrl, 1, {
            ...updatedUserPlansForImageUrl[foundSelectedIndexForImageUrl],
            imageUrl: action.payload.imageUrl,
          });
        }
        let newSelectedAthleteForImageUrl = state.selectedAthlete;
        if (
          newSelectedAthleteForImageUrl &&
          newSelectedAthleteForImageUrl.cognito_user_sub ===
            action.payload.cognito_user_sub
        ) {
          newSelectedAthleteForImageUrl = {
            ...newSelectedAthleteForImageUrl,
            imageUrl: action.payload.imageUrl,
          };
        }
        return {
          ...state,
          loadingAthleteImageUrl: false,
          searchResult: {
            ...state.searchResult,
            data: updatedUserPlansForImageUrl,
          },
          selectedAthlete: newSelectedAthleteForImageUrl,
        };*/

      default:
        return state;
    }
  } else {
    return state;
  }
}
