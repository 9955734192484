import React, { Component, memo } from "react";
import {
  withMobileDialog,
  Typography,
  Dialog,
  DialogContent,
  Button,
  CircularProgress,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Canvas from "../../common/canvas";
import { setErrorMessage } from "../../../actions/user-plans-action";
import { reduceFileSize } from "../../../lib/image-helper";
import jimp from "jimp";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing";
import { Capacitor } from "@capacitor/core";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { newPost } from "../../../actions/trail-head-action";
import history from "../../../lib/history-helper";
import { logEvent } from "../../../lib/events-helper";
import TrailHeadService from "../../../services/trail-head-service";
import { Storage } from "aws-amplify";
import VideoConverter from "convert-video";
import DashboardGpsMapView from "../dashboard-gps/dashboard-gps-map-view";

const styles = (theme) => ({
  noPadding: {
    padding: "0 !important",
  },

  container: {
    backgroundColor: "#F9F9FB",
    minHeight: "100%",
    overflowX: "hidden",
  },

  topContainer: {
    display: "flex",
    marginBottom: 40,
    alignItems: "center",
    padding: "20px 24px",
    backgroundColor: "#FFFFFF",
  },

  progressContainer: {
    width: "calc(100% - 24px)",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  processing: {
    fontSize: 14,
    color: "#FF5722",
    marginTop: 10,
  },

  loading: {
    margin: "auto",
  },

  input: {
    display: "none",
  },

  label: {
    width: "100%",
    display: "flex",
    position: "relative",
    boxSizing: "border-box",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  shareAction: {
    boxShadow: "none",
    borderRadius: 50,
    marginLeft: 20,
  },

  shareTitle: {
    fontWeight: 500,
    fontSize: 18,
    marginLeft: 20,
  },

  iconImages: {
    marginLeft: "auto",
    cursor: "pointer",
  },

  canvasContainer: {
    marginLeft: 24,
    position: "relative",
  },

  optionsContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },

  optionsContainerStats: {
    background: "#fff",
  },

  optionsContainerRow: {
    display: "flex",
  },

  optionsContainerButton: {
    width: "50%",
    margin: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(63, 81, 181, 0.12)",
    borderRadius: 4,
    padding: "16px 0",
    cursor: "pointer",
  },

  optionsContainerButtonSelected: {
    width: "50%",
    margin: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#3F51B5",
    borderRadius: 4,
    padding: "16px 0",
    cursor: "pointer",
    position: "relative",
  },

  iconOptionsCheck: {
    position: "absolute",
  },

  optionsContainerOverlay: {
    padding: "0 10px",
  },

  switchLabel: {
    fontWeight: 500,
    fontSize: 14,
  },

  shareDrawerTitle: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#9E9E9E",
    paddingTop: 20,
    paddingLeft: 20,
  },
  labelsParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "0px 8px 16px 8px",
  },
  actionLabel: {
    display: "flex",
    backgroundColor: "#FFF",
    alignItems: "center",
    padding: "8px",
    borderRadius: 24,
    width: "fit-content",
    cursor: "pointer",
  },
  optionIcon: {
    marginRight: 6,
  },
  optionText: {
    fontWeight: "bold",
  },
  optionBtnContent: {
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
  },
  optionSub: {
    display: "flex",
    alignItems: "center",
    width: "80%",
  },
  labelText: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  mapLoading: {
    width: "100vw",
    height: "100vh",
    zIndex: 400,
    background: "#f9f9fb",
    textAlign: "center",
    marginLeft: -24,
    position: "absolute",
  },
});

class DashboardActivityShare extends Component {
  state = {
    openDrawer: false,
    currentImage: null,
    currentImageExtension: ".png",
    loadingCurrentImage: false,
    type: 0,
    base64Image: null,
    overlay: true,
    muted: false,
    openShareDrawer: false,
    currentVideo: null,
    sharingVideo: false,
    loadingCurrentVideo: false,

    processingVideo: null,
    needsToProcess: false,

    customeImage: false,
    alignment: "h",
  };

  componentDidMount = () => {
    this.setState({
      ...this.state,
      openDrawer: false,
      currentImage: null,
      currentImageExtension: ".png",
      loadingCurrentImage: false,
      type: 0,
      overlay: true,
      muted: false,
      openShareDrawer: false,
      currentVideo: null,
      sharingVideo: false,
      processingVideo: null,
      needsToProcess: false,
      customeImage: false,
      alignment: "h",
    });
  };

  componentWillUnmount = () => {
    this.setState({
      ...this.state,
      openDrawer: false,
      currentImage: null,
      currentImageExtension: ".png",
      loadingCurrentImage: false,
      type: 0,
      overlay: true,
      muted: false,
      openShareDrawer: false,
      currentVideo: null,
      sharingVideo: false,
      processingVideo: null,
      needsToProcess: false,
      customeImage: false,
      alignment: "h",
    });
  };

  handleScreenshotReady = (image) => {
    if (
      !this.state.currentImage ||
      this.state.currentImage === `${process.env.PUBLIC_URL}/bkg_gps.png`
    ) {
      if (image) {
        this.setState({
          ...this.state,
          openDrawer: false,
          currentImage: image,
          currentImageExtension: ".png",
          loadingCurrentImage: false,
          type: 0,
          overlay: true,
          muted: false,
          openShareDrawer: false,
          currentVideo: null,
          sharingVideo: false,
          processingVideo: null,
          needsToProcess: false,
          customeImage: false,
        });
      } else {
        this.setState({
          ...this.state,
          openDrawer: false,
          currentImage: `${process.env.PUBLIC_URL}/bkg_gps.png`,
          currentImageExtension: ".png",
          loadingCurrentImage: false,
          type: 0,
          overlay: false,
          muted: false,
          openShareDrawer: false,
          currentVideo: null,
          sharingVideo: false,
          processingVideo: null,
          needsToProcess: false,
          customeImage: false,
        });
      }
    }
  };

  toggleDrawer = (value) => {
    this.setState({
      ...this.state,
      openDrawer: value,
    });
  };

  toggleShareDrawer = async (value) => {
    if (this.state.currentVideo && this.state.sharingVideo === false) {
      if (this.state.needsToProcess) {
        this.setState({
          ...this.state,
          sharingVideo: true,
        });
      } else {
        setTimeout(
          () => this.checkVideo(this.state.processingVideo, 0, true),
          1000,
        );
      }
    } else {
      this.setState({
        ...this.state,
        openShareDrawer: value,
      });
    }
  };

  handleError = (err) => {
    this.props.dispatch(setErrorMessage(err)); //GOES TO SNACK BAR
  };

  takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
    });

    this.setState({
      ...this.state,
      currentImage: image.dataUrl,
      currentVideo: null,
      sharingVideo: false,
      processingVideo: false,
      loadingCurrentImage: false,
      openDrawer: false,
      customeImage: true,
    });
  };

  handleInputChange = async (event) => {
    if (event) {
      const { target } = event;
      const { maxSize, t } = this.props;
      if (target) {
        const { files } = target;
        if (files) {
          const selectedFile = files[0];

          const { size } = selectedFile;
          if (size > 150000000) {
            this.handleError(
              t("Sorry, your video needs to be smaller than maxSize150", {
                ns: "messages",
              }),
            );
            return;
          }

          if (selectedFile.type && selectedFile.type.includes("video")) {
            const fileNameArray = selectedFile.name
              ? selectedFile.name.split(".")
              : undefined;
            const extension = fileNameArray
              ? fileNameArray[fileNameArray.length - 1]
              : undefined;

            let convertedVideoDataObj = null;

            if (!extension.toLowerCase().includes("mp4")) {
              const converted = await VideoConverter.convert(
                selectedFile,
                "mp4",
              );
              convertedVideoDataObj = converted.data;
            }

            this.setState({
              ...this.state,
              currentVideo: convertedVideoDataObj
                ? convertedVideoDataObj
                : URL.createObjectURL(selectedFile),
              loadingCurrentImage: false,
              loadingCurrentVideo: true,
              currentImage: false,
              openDrawer: false,
              sharingVideo: false,
              processingVideo: null,
              needsToProcess: true,
              customeImage: true,
            });
          } else {
            this.setState({
              ...this.state,
              loadingCurrentImage: true,
              openDrawer: false,
            });
            try {
              if (selectedFile) {
                const { size } = selectedFile;
                if (size <= maxSize) {
                  if (size > 1024000) {
                    reduceFileSize(
                      selectedFile,
                      1024000,
                      4000,
                      Infinity,
                      0.9,
                      async (fixedFile) => {
                        fixedFile.name = selectedFile.name;

                        const {
                          image: imageString,
                          extension,
                        } = await this.getBase64(fixedFile);

                        this.setState({
                          ...this.state,
                          currentImage: imageString,
                          currentImageExtension: extension,
                          loadingCurrentImage: false,
                          currentVideo: null,
                          sharingVideo: false,
                          processingVideo: false,

                          loadingCurrentVideo: false,

                          needsToProcess: false,
                          customeImage: true,
                        });
                      },
                    );
                  } else {
                    const {
                      image: imageString,
                      extension,
                    } = await this.getBase64(selectedFile);

                    this.setState({
                      ...this.state,
                      currentImage: imageString,
                      currentImageExtension: extension,
                      loadingCurrentImage: false,
                      currentVideo: null,
                      sharingVideo: false,
                      processingVideo: false,
                      loadingCurrentVideo: false,
                      needsToProcess: false,
                      customeImage: true,
                    });
                  }
                } else {
                  this.handleError(
                    t("Sorry, your image needs to be smaller than maxSize10", {
                      ns: "messages",
                    }),
                  );
                }
              } else {
                //if (onError) {
                this.handleError(t("Invalid File", { ns: "messages" }));
                //}
              }
            } catch (exp) {
              this.handleError(exp);
            }
          }
        }
      }
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const image = await jimp.read(reader.result);
          //await image.resize(150, jimp.AUTO);
          const fileNameArray = file.name ? file.name.split(".") : undefined;
          const extension = fileNameArray
            ? fileNameArray[fileNameArray.length - 1]
            : undefined;
          const data = await image.getBase64Async(
            extension === "png" ? jimp.MIME_PNG : jimp.MIME_JPEG,
          );
          resolve({
            image: data,
            extension,
          });
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      openDrawer: false,
      currentImage: null,
      currentImageExtension: ".png",
      type: 0,
      loadingCurrentImage: false,
      overlay: true,
      muted: false,
      openShareDrawer: false,
      currentVideo: null,
      sharingVideo: false,
      processingVideo: null,
      loadingCurrentVideo: false,
      needsToProcess: false,
      alignment: "h",
      customeImage: true,
    });
    const { onClose } = this.props;
    onClose();
  };

  handleChangeType = (value) => {
    this.setState({
      ...this.state,
      type: value,
      loadingCurrentVideo: this.state.currentVideo ? true : false,
      needsToProcess: this.state.currentVideo ? true : false,
      processingVideo: null,
    });
  };

  handleChangeOverlay = () => {
    this.setState({
      ...this.state,
      overlay: !this.state.overlay,
      loadingCurrentVideo: this.state.currentVideo ? true : false,
      needsToProcess: this.state.currentVideo ? true : false,
      processingVideo: null,
    });
  };

  handleChangeMuted = () => {
    this.setState({
      ...this.state,
      muted: !this.state.muted,
    });
  };

  handleFinishLoadingVideo = () => {
    this.setState({
      ...this.state,
      loadingCurrentVideo: false,
    });
  };

  shareOnTrailhead = async () => {
    const { isColumn = false, onClose, trailHead } = this.props;
    const { text, currentPublicGroup } = trailHead;
    const { base64Image } = this.state;

    if (base64Image) {
      this.props.dispatch(newPost("", text, null, base64Image));
    }

    if (isColumn) {
      onClose(true);
    } else {
      history.push(
        currentPublicGroup
          ? `/trailhead/group/${currentPublicGroup.id}`
          : `/trailhead`,
      );
    }
  };

  shareOnInstagram = async () => {
    const { base64Image } = this.state;

    if (base64Image) {
      if (Capacitor.isNativePlatform()) {
        if (Capacitor.getPlatform() === "ios") {
          await SocialSharing.saveToPhotoAlbum(base64Image);
          window.open(
            "instagram://library?AssetPath=assets-library",
            "_system",
          );
        } else {
          SocialSharing.shareViaInstagram("", base64Image);
        }
      } else {
        const blob = await (await fetch(base64Image)).blob();
        const file = new File([blob], "fileName.png", { type: blob.type });
        //if (navigator.canShare && navigator.canShare({ files: [file] })) {
        navigator
          .share({
            files: [file],
            title: "",
            text: "",
          })
          .then(() => console.log("Share was successful."))
          .catch((error) => console.log("Sharing failed", error));
        //} else {
        //  console.log(`Your system doesn't support sharing files.`);
        //}
      }
    }
  };

  shareOnFacebook = async () => {
    const { base64Image } = this.state;

    if (base64Image) {
      if (Capacitor.isNativePlatform()) {
        SocialSharing.shareViaFacebook("", base64Image);
      } else {
        const blob = await (await fetch(base64Image)).blob();
        const file = new File([blob], "fileName.png", { type: blob.type });
        //if (navigator.canShare && navigator.canShare({ files: [file] })) {
        navigator
          .share({
            files: [file],
            title: "",
            text: "",
          })
          .then(() => console.log("Share was successful."))
          .catch((error) => console.log("Sharing failed", error));
        //} else {
        //  console.log(`Your system doesn't support sharing files.`);
        //}
      }
    }
  };

  shareOnTwitter = async () => {
    const { base64Image } = this.state;

    if (base64Image) {
      if (Capacitor.isNativePlatform()) {
        SocialSharing.shareViaTwitter("", base64Image);
      } else {
        const blob = await (await fetch(base64Image)).blob();
        const file = new File([blob], "fileName.png", { type: blob.type });
        //if (navigator.canShare && navigator.canShare({ files: [file] })) {
        navigator
          .share({
            files: [file],
            title: "",
            text: "",
          })
          .then(() => console.log("Share was successful."))
          .catch((error) => console.log("Sharing failed", error));
        //} else {
        //  console.log(`Your system doesn't support sharing files.`);
        //}
      }
    }
  };

  handleShare = async () => {
    const { base64Image } = this.state;

    if (base64Image) {
      if (Capacitor.isNativePlatform()) {
        console.log(base64Image);
        SocialSharing.share("", "", base64Image);
      } else {
        const blob = await (await fetch(base64Image)).blob();
        const file = new File([blob], "fileName.png", { type: blob.type });
        //if (navigator.canShare && navigator.canShare({ files: [file] })) {
        navigator
          .share({
            files: [file],
            title: "",
            text: "",
          })
          .then(() => console.log("Share was successful."))
          .catch((error) => console.log("Sharing failed", error));
        //} else {
        //  console.log(`Your system doesn't support sharing files.`);
        //}
      }
    }
  };

  handleFinishDraw = async (base64Image, w = 0, h = 0) => {
    console.log("FINISH DRAW", w, h);
    if (this.state.currentVideo) {
      const { userPlans } = this.props;
      const { currentPlan } = userPlans;
      const { cognito_user_sub } = currentPlan || {};

      const date = new Date().getTime();

      this.setState({
        ...this.state,
        processingVideo: cognito_user_sub + date,
      });

      try {
        const image = await jimp.read(base64Image);
        await image.resize(w, h);
        const data = await image.getBase64Async(jimp.MIME_PNG);
        const blob = await (await fetch(data)).blob();
        await Storage.put(
          `videos/${cognito_user_sub + date}-overlay.png`,
          blob,
        );

        const videoBlob = await (await fetch(this.state.currentVideo)).blob();
        await Storage.put(`videos/${cognito_user_sub + date}.mp4`, videoBlob);

        const result = await TrailHeadService.convertVideo(
          cognito_user_sub + date,
        );

        //console.log(result);

        setTimeout(() => this.checkVideo(cognito_user_sub + date, 0), 5000);
      } catch (e) {
        console.log("ERROR", e);

        const { t } = this.props;
        this.handleError(
          t("There was an error processing your video", {
            ns: "messages",
          }),
        );

        this.setState({
          ...this.state,
          sharingVideo: false,
          processingVideo: null,
        });
      }
    } else {
      this.setState({
        ...this.state,
        base64Image,
      });
    }
  };

  checkVideo = async (id, attempt, direct = false) => {
    if (this.state.sharingVideo || direct) {
      try {
        const downloadedData = await Storage.get(`videos/${id}+1.mp4`, {
          download: true,
        });

        console.log("downloadedData", downloadedData);

        if (downloadedData) {
          try {
            if (Capacitor.isNativePlatform()) {
              if (Capacitor.getPlatform() === "ios") {
                SocialSharing.share(
                  "",
                  "",
                  `https://${
                    process.env.REACT_APP_VIDEOS_BUCKET
                  }/public/videos/${encodeURIComponent(id + "+1")}.mp4`,
                )
                  .then(() => console.log("Share was successful."))
                  .catch((error) => console.log("Sharing failed", error));
              } else {
                var reader = new FileReader();
                reader.readAsDataURL(downloadedData.Body);
                reader.onloadend = function() {
                  var base64data = reader.result;

                  SocialSharing.share("", "", base64data)
                    .then(() => console.log("Share was successful."))
                    .catch((error) => console.log("Sharing failed", error));
                };
              }
            }

            this.setState({
              ...this.state,
              sharingVideo: false,
              needsToProcess: false,
            });
          } catch (e) {
            this.setState({
              ...this.state,
              sharingVideo: false,
              processingVideo: null,
            });
          }
        }
      } catch (e) {
        if (attempt < 50) {
          setTimeout(() => this.checkVideo(id, attempt + 1), 5000);
        } else {
          const { t } = this.props;
          this.handleError(
            t("There was an error processing your video", {
              ns: "messages",
            }),
          );

          this.setState({
            ...this.state,
            sharingVideo: false,
            processingVideo: null,
          });
        }
      }
    }
  };

  handleToggleAligment = () => {
    const { alignment, customeImage } = this.state;
    if (customeImage) {
      this.setState({
        ...this.state,
        alignment: alignment === "h" ? "v" : "h",
      });
    } else {
      this.setState({
        ...this.state,
        openDrawer: false,
        currentImage: null,
        currentImageExtension: ".png",
        loadingCurrentImage: false,
        type: 0,
        overlay: false,
        muted: false,
        openShareDrawer: false,
        currentVideo: null,
        sharingVideo: false,
        processingVideo: null,
        needsToProcess: false,
        customeImage: false,
        alignment: alignment === "h" ? "v" : "h",
      });
    }
  };

  render() {
    const {
      classes,
      t,
      isOpen,
      strava,
      garmin,
      suunto,
      width,
      vertActivities,
      healthKit,
      coros,
    } = this.props;

    const {
      openDrawer,
      currentImage,
      type,
      loadingCurrentImage,
      overlay,
      muted,
      openShareDrawer,
      currentVideo,
      sharingVideo,
      processingVideo,
      loadingCurrentVideo,
      alignment,
    } = this.state;

    const currentDevice =
      isOpen === "vert.run"
        ? vertActivities
        : isOpen === "garmin"
        ? garmin
        : isOpen === "suunto"
        ? suunto
        : isOpen === "coros"
        ? coros
        : isOpen === "strava"
        ? strava
        : isOpen == "healthKit"
        ? healthKit
        : {};

    const { currentActivity, loadingActivity } = currentDevice;
    const units = localStorage.getItem("units");

    const elevationSamples =
      currentActivity && currentActivity.samples
        ? currentActivity.samples.map((s) => {
            return {
              x: s.distance,
              y: s.elevation,
            };
          })
        : [];

    elevationSamples.sort((a, b) => a.x - b.x);

    const latlngSamples =
      currentActivity && currentActivity.samples
        ? currentActivity.samples
            .map((s) => {
              return {
                latitude: s.latlng[0],
                longitude: s.latlng[1],
              };
            })
            .filter((ll) => !!ll && ll.latitude && ll.longitude)
        : [];

    const isInMobileViewSm = width === "sm" || width === "xs";

    const { devicePixelRatio = 1 } = window;
    const canvasSizeX = (isInMobileViewSm ? window.innerWidth : 500) - 48;
    const canvasSizeY =
      currentVideo || alignment === "v"
        ? window.innerHeight - 48
        : (isInMobileViewSm ? window.innerWidth : 500) - 48;

    return (
      <Dialog
        open={!!isOpen}
        onClose={this.handleClose}
        maxWidth={true}
        fullScreen={true}
        style={{
          paddingLeft: "env(safe-area-inset-left)",
          paddingRight: "env(safe-area-inset-right)",
          paddingTop: "env(safe-area-inset-top)",
          paddingBottom: "env(safe-area-inset-bottom)",

          width: isInMobileViewSm ? "initial" : 500,
          left: isInMobileViewSm ? 0 : "initial",
        }}
      >
        <DialogContent className={classes.noPadding}>
          {loadingActivity ? (
            <div className={classes.container}>
              <div className={classes.topContainer}>
                <ArrowBackIosIcon
                  onClick={() => this.handleClose()}
                  className={classes.backIcon}
                  fontSize="small"
                />
              </div>

              <div className={classes.progressContainer}>
                <CircularProgress
                  variant="indeterminate"
                  color="primary"
                  className={classes.loading}
                  data-testid="LoadingActivity"
                />
              </div>
            </div>
          ) : currentActivity ? (
            <div
              className={classes.container}
              style={{
                marginBottom: alignment === "v" ? 270 : 0,
              }}
            >
              <div className={classes.topContainer}>
                <ArrowBackIosIcon
                  onClick={() => this.handleClose()}
                  className={classes.backIcon}
                  fontSize="small"
                />

                <Typography variant="h3" className={classes.shareTitle}>
                  {t("Share")}
                </Typography>

                <img
                  src={`${process.env.PUBLIC_URL}/icon-images-share.svg`}
                  alt=""
                  className={classes.iconImages}
                  onClick={() => {
                    if (!sharingVideo) this.toggleDrawer(!openDrawer);
                  }}
                />

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.shareAction}
                  onClick={() => this.toggleShareDrawer(!openShareDrawer)}
                  disabled={
                    loadingCurrentImage ||
                    (!currentImage && !currentVideo) ||
                    loadingCurrentVideo ||
                    sharingVideo
                  }
                >
                  {t("Share")}
                </Button>
              </div>

              <div className={classes.canvasContainer}>
                {loadingCurrentImage || (sharingVideo && processingVideo) ? (
                  <div className={classes.progressContainer}>
                    <CircularProgress
                      variant="indeterminate"
                      color="primary"
                      className={classes.loading}
                      data-testid="LoadingImage"
                    />

                    {sharingVideo && processingVideo ? (
                      <Typography variant="h3" className={classes.processing}>
                        {t("Processing Video")}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <>
                    {loadingCurrentVideo ? (
                      <div className={classes.progressContainer}>
                        <CircularProgress
                          variant="indeterminate"
                          color="primary"
                          className={classes.loading}
                          data-testid="LoadingImage"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {!currentImage && !currentVideo ? (
                      <div
                        style={{
                          position: "absolute",
                        }}
                      >
                        <div
                          style={{
                            width: canvasSizeX * 2,
                            height: canvasSizeY * 2,
                            position: "absolute",
                            zIndex: -100,
                          }}
                        >
                          {latlngSamples ? (
                            <DashboardGpsMapView
                              trackingData={latlngSamples}
                              onScreenshotReady={this.handleScreenshotReady}
                              withScreenshot={true}
                              mapId={`map${new Date().getTime()}`}
                            ></DashboardGpsMapView>
                          ) : null}
                        </div>

                        <div className={classes.mapLoading}>
                          <CircularProgress
                            variant="indeterminate"
                            color="primary"
                            className={classes.loading}
                            data-testid="LoadingActivity"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {currentImage || currentVideo ? (
                      <Canvas
                        image={currentImage}
                        video={currentVideo}
                        width={canvasSizeX * devicePixelRatio}
                        height={canvasSizeY * devicePixelRatio}
                        style={
                          currentVideo
                            ? {
                                width: "calc(100% - 24px)",
                              }
                            : {
                                width: canvasSizeX,
                                height: canvasSizeY,
                                border: "1px solid #e7eaf6",
                              }
                        }
                        currentActivity={currentActivity}
                        units={units}
                        elevationSamples={elevationSamples}
                        type={type}
                        overlay={overlay}
                        muted={muted}
                        onFinishDraw={this.handleFinishDraw}
                        sharingVideo={sharingVideo}
                        onFinishLoadingVideo={this.handleFinishLoadingVideo}
                        alignment={alignment}
                      ></Canvas>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
              {currentVideo ? <div style={{ height: 200 }}></div> : ""}
              {(currentImage || currentVideo) && !sharingVideo ? (
                <div className={classes.optionsContainer}>
                  <div className={classes.labelsParent}>
                    <div
                      className={classes.actionLabel}
                      onClick={() => this.toggleDrawer(true)}
                    >
                      <Typography className={classes.labelText}>
                        {t("Add image", { ns: "tracking" })}
                      </Typography>
                      <img
                        src={`${process.env.PUBLIC_URL}/upload-icon.svg`}
                        style={{ marginLeft: 4 }}
                      ></img>
                    </div>
                    <div
                      className={classes.actionLabel}
                      onClick={() => this.handleToggleAligment()}
                    >
                      <Typography className={classes.labelText}>
                        {t("Alignment", { ns: "tracking" })}
                      </Typography>
                      <img
                        src={`${process.env.PUBLIC_URL}/alignment-icon.svg`}
                        style={{ marginLeft: 4 }}
                      ></img>
                    </div>
                    <div
                      className={classes.actionLabel}
                      onClick={() => this.handleChangeOverlay()}
                    >
                      <Typography className={classes.labelText}>
                        {t("Overlay", { ns: "tracking" })}
                      </Typography>
                      <img
                        src={`${process.env.PUBLIC_URL}/${
                          overlay ? "active-" : ""
                        }overlay-icon.svg`}
                        style={{ marginLeft: 4 }}
                      ></img>
                    </div>
                  </div>

                  <div className={classes.optionsContainerStats}>
                    <div className={classes.optionsContainerRow}>
                      <div
                        className={
                          type === 0
                            ? classes.optionsContainerButtonSelected
                            : classes.optionsContainerButton
                        }
                        onClick={() => this.handleChangeType(0)}
                      >
                        <div className={classes.optionBtnContent}>
                          <div className={classes.optionSub}>
                            <img
                              src={`${process.env.PUBLIC_URL}/timer-icon.svg`}
                              alt="timer-icon"
                              className={classes.optionIcon}
                            />
                            <Typography
                              variant="body2"
                              className={classes.optionText}
                            >
                              {t("Activity Stats", { ns: "tracking" })}
                            </Typography>
                          </div>
                        </div>

                        {type === 0 ? (
                          <img
                            src={`${process.env.PUBLIC_URL}/icon-check-share.svg`}
                            alt=""
                            className={classes.iconOptionsCheck}
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      <div
                        className={
                          type === 1
                            ? classes.optionsContainerButtonSelected
                            : classes.optionsContainerButton
                        }
                        onClick={() => this.handleChangeType(1)}
                      >
                        <div className={classes.optionBtnContent}>
                          <div className={classes.optionSub}>
                            <img
                              src={`${process.env.PUBLIC_URL}/elevation-icon.svg`}
                              alt="elevation-icon"
                              className={classes.optionIcon}
                            />
                            <Typography
                              variant="body2"
                              className={classes.optionText}
                            >
                              {t("Elevation Profile", { ns: "tracking" })}
                            </Typography>
                          </div>
                        </div>

                        {type === 1 ? (
                          <img
                            src={`${process.env.PUBLIC_URL}/icon-check-share.svg`}
                            alt=""
                            className={classes.iconOptionsCheck}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className={classes.optionsContainerRow}>
                      <div
                        className={
                          type === 2
                            ? classes.optionsContainerButtonSelected
                            : classes.optionsContainerButton
                        }
                        onClick={() => this.handleChangeType(2)}
                      >
                        <div className={classes.optionBtnContent}>
                          <div className={classes.optionSub}>
                            <img
                              src={`${process.env.PUBLIC_URL}/speed-icon.svg`}
                              alt="speed-icon"
                              className={classes.optionIcon}
                            />
                            <Typography
                              variant="body2"
                              className={classes.optionText}
                            >
                              {t("Up/Down Speed", { ns: "tracking" })}
                            </Typography>
                          </div>
                        </div>

                        {type === 2 ? (
                          <img
                            src={`${process.env.PUBLIC_URL}/icon-check-share.svg`}
                            alt=""
                            className={classes.iconOptionsCheck}
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      <div
                        className={
                          type === 3
                            ? classes.optionsContainerButtonSelected
                            : classes.optionsContainerButton
                        }
                        onClick={() => this.handleChangeType(3)}
                      >
                        <div className={classes.optionBtnContent}>
                          <div className={classes.optionSub}>
                            <img
                              src={`${process.env.PUBLIC_URL}/allstats-icon.svg`}
                              alt="allstats-icon"
                              className={classes.optionIcon}
                            />
                            <Typography
                              variant="body2"
                              className={classes.optionText}
                            >
                              {t("All the stats and elevation", {
                                ns: "tracking",
                              })}
                            </Typography>
                          </div>
                        </div>

                        {type === 3 ? (
                          <img
                            src={`${process.env.PUBLIC_URL}/icon-check-share.svg`}
                            alt=""
                            className={classes.iconOptionsCheck}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </DialogContent>

        <Drawer
          anchor={"bottom"}
          open={openDrawer}
          onClose={() => this.toggleDrawer(false)}
        >
          <List>
            {Capacitor.isNativePlatform() ? (
              <ListItem
                button
                key={t("Take a selfie")}
                onClick={this.takePicture}
              >
                <ListItemIcon>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-selfie.svg`}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary={t("Take a selfie")} />
              </ListItem>
            ) : (
              ""
            )}

            <ListItem button key={t("Upload from the gallery")}>
              <input
                accept={
                  Capacitor.isNativePlatform() ? "image/*, video/*" : "image/*"
                }
                className={classes.input}
                id="contained-button-file-2"
                type="file"
                onChange={this.handleInputChange}
              />
              <label
                htmlFor="contained-button-file-2"
                className={classes.label}
              >
                <ListItemIcon>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-upload.svg`}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    Capacitor.isNativePlatform()
                      ? t("Upload from the gallery")
                      : t("Upload image")
                  }
                />
              </label>
            </ListItem>

            {/*<ListItem button key={t("Remove current photo")}>
              <ListItemIcon>
                <img src={`${process.env.PUBLIC_URL}/icon-remove.svg`} alt="" />
              </ListItemIcon>
              <ListItemText primary={t("Remove current photo")} />
          </ListItem>*/}
          </List>
        </Drawer>

        <Drawer
          anchor={"bottom"}
          open={openShareDrawer}
          onClose={() => this.toggleShareDrawer(false)}
        >
          <Typography variant="h3" className={classes.shareDrawerTitle}>
            {t("Share to")}:
          </Typography>
          <List>
            <ListItem
              button
              key={t("Vert Community")}
              onClick={this.shareOnTrailhead}
            >
              <ListItemIcon>
                <img
                  src={`${process.env.PUBLIC_URL}/icon-share-th.svg`}
                  alt=""
                />
              </ListItemIcon>
              <ListItemText primary={t("Vert Community")} />
            </ListItem>

            {Capacitor.isNativePlatform() ? (
              <ListItem
                button
                key={"Instagram"}
                onClick={this.shareOnInstagram}
              >
                <ListItemIcon>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-share-instagram.svg`}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary={"Instagram"} />
              </ListItem>
            ) : (
              ""
            )}

            {Capacitor.isNativePlatform() ? (
              <ListItem button key={"Facebook"} onClick={this.shareOnFacebook}>
                <ListItemIcon>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-share-facebook.svg`}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary={"Facebook"} />
              </ListItem>
            ) : (
              ""
            )}

            {Capacitor.isNativePlatform() ? (
              <ListItem button key={"Twitter"} onClick={this.shareOnTwitter}>
                <ListItemIcon>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-share-twitter.svg`}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary={"Twitter"} />
              </ListItem>
            ) : (
              ""
            )}

            {Capacitor.isNativePlatform() ? (
              <ListItem button key={t("Other")} onClick={this.handleShare}>
                <ListItemIcon>
                  <img
                    src={`${process.env.PUBLIC_URL}/icon-share.svg`}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary={t("Other")} />
              </ListItem>
            ) : (
              ""
            )}
          </List>
        </Drawer>
      </Dialog>
    );
  }
}

DashboardActivityShare.defaultProps = {
  maxSize: 10240000,
};

DashboardActivityShare.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  fullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  userPlans: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect((store) => {
  return {
    strava: store.strava,
    garmin: store.garmin,
    suunto: store.suunto,
    userPlans: store.userPlans,
    trailHead: store.trailHead,
    vertActivities: store.vertActivities,
    healthKit: store.healthKit,
    coros: store.coros,
  };
})(
  withStyles(styles, { withTheme: true })(
    withTranslation(["dashboard", "tracking"])(
      withWidth()(withMobileDialog()(memo(DashboardActivityShare))),
    ),
  ),
);
