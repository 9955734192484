import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Typography,
  Box,
  Dialog,
  makeStyles,
  Grid,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Paper,
} from "@material-ui/core";
import {
  InfoIcon,
  DistanceIcon,
  ElevationIcon,
  TimerIcon,
  LightningIcon,
  ShareIcon,
  RunIcon,
  AvgUphillSpeedIcon,
  AvgDownhillSpeedIcon,
  AvgHeartRateIcon,
  CaloriesIcon,
} from "../../icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { palette } from "../../../theme/palette";
import { ActivityChart } from "./activity-chart";
import { ActivityTable } from "./activity-table";
import dayjs from "dayjs";
import UnitsHook from "../../../hooks/unitsHook";
import { convertUnits, round } from "../../../lib/units-helper";
import CardTemplate from "../../common/v2/CardTemplate";
import useCalculatorMI from "../../../hooks/useCalculatorMI";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUserActivity } from "../../../actions/user-plans-action";
import useUserInfo from "../../../hooks/useUserInfo";
import useActivityDetails from "../../../hooks/useActivityDetails";
import { Fade, Popper } from "@mui/material";
import { logEvent } from "../../../lib/events-helper";

const useStyles = makeStyles({
  activityName: {
    fontWeight: 700,
    lineHeight: "28px",
  },
  activityStats: {
    fontWeight: "bold",
    fontSize: 32,
    color: palette.darkBlue,
    fontFamily: "Oswald",
    lineHeight: "40px",
  },
  textGray: {
    color: palette.darkGray,
    fontSize: "14px",
    lineHeight: "21px",
  },
  boxBordered: {
    borderRight: "1px solid rgba(2, 4, 2, 0.1)",
    paddingRight: "5px",
  },
  activityStatsSecondary: {
    fontWeight: "bold",
    fontSize: 22,
    color: palette.lightBlue,
  },
  titleFont: {
    fontFamily: "Oswald",
  },
  appBar: {
    backgroundColor: palette.white,
    color: palette.black,
    boxShadow: palette.boxShadow,
  },
  layoutContent: {
    backgroundColor: palette.lightGrayOpacity,
    display: "flex",
    flexDirection: "column",
    padding: "30px 16px",
  },
  MI: {
    color: palette.primary,
    fontWeight: 600,
    fontFamily: "Oswald",
  },
  activityDate: {
    fontSize: 16,
    fontWeight: 400,
  },
  paperPopper: {
    boxShadow: "0px 1px 15px 0px #12151B0D !important",
    display: "flex",
    padding: "8px 12px 8px 12px",
    /* gap: 12px; */
    borderRadius: "4px !important",
    backgroundColor: `${palette.lightBlueHover} !important`,
    opacity: 1,
    maxWidth: 277,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const ActivityDetailModal = ({
  type,
  open,
  onClose,
  activity,
  shareActivity,
}) => {
  const { distance, total_elevation_gain: elevation, laps } = activity;
  const { name, icon, time, loadActivityDetails } = useActivityDetails();
  const { t } = useTranslation("profile");
  const classes = useStyles();
  const dispatch = useDispatch();
  const localUnits = UnitsHook();
  const { calculateValue } = useCalculatorMI();
  const { currentPlan } = useUserInfo();
  const [currentLaps, setCurrentLaps] = useState(null);
  const [downhillPace, setDownhillPace] = useState(null);
  const [uphillPace, setUphillPace] = useState(null);
  const [calories, setCalories] = useState(null);
  const [pace, setPace] = useState({ minutes: null, seconds: null });
  const [date, setDate] = useState(null);
  const [hr, setHr] = useState(null);
  const [anchorElElevation, setAnchorElElevation] = useState(null);
  const [openElevation, setOpenElevation] = useState(false);
  const [placementElevation, setPlacementElevation] = useState();
  const [anchorElSplit, setAnchorElSplit] = useState(null);
  const [openSplit, setOpenSplit] = useState(false);
  const [placementSplit, setPlacementSplit] = useState();
  const { strava, garmin, suunto, vertActivities, healthKit } = useSelector(
    (state) => state,
  );
  const activityByType = {
    "vert.run": vertActivities,
    garmin: garmin,
    suunto: suunto,
    strava: strava,
    healthKit: healthKit,
  };
  const currentDevice = activityByType[type] ? activityByType[type] : {};
  const { currentActivity } = currentDevice || {};
  const { minutes, seconds } = pace || {};

  const handlePopperElevation = (newPlacement) => (event) => {
    setAnchorElElevation(event.currentTarget);
    setOpenElevation((prev) => placementElevation !== newPlacement || !prev);
    setPlacementElevation(newPlacement);
  };

  const handlePopperSplit = (newPlacement) => (event) => {
    setAnchorElSplit(event.currentTarget);
    setOpenSplit((prev) => placementSplit !== newPlacement || !prev);
    setPlacementSplit(newPlacement);
  };

  const handleDateFormat = (date) => {
    if (!date) return;
    return dayjs(date).format("ddd, MMM D - hh:mm a");
  };

  const calculateMaxSpeed = () => {
    try {
      const dataCopy = [...currentLaps];
      const speedArray = dataCopy.map((item) => {
        const speed = round(1 / (item.time / 3600), 2);
        return speed;
      });
      const max = Math.max(...speedArray);
      return isFinite(max) ? max : 0;
    } catch (error) {
      return 0;
    }
  };

  const handleShare = (activity) => {
    shareActivity(activity);
  };

  const getActivityDetails = async () => {
    await dispatch(getUserActivity(activity, currentPlan));
  };

  useEffect(() => {
    getActivityDetails();
    loadActivityDetails(activity);
  }, []);

  useEffect(() => {
    if (currentActivity) {
      setDownhillPace(
        currentActivity.downhillPace ? currentActivity.downhillPace : 0,
      );
      setCurrentLaps(currentActivity.laps ? currentActivity.laps : []);
      setUphillPace(
        currentActivity.uphillPace ? currentActivity.uphillPace : 0,
      );
      setCalories(currentActivity.calories ? currentActivity.calories : 0);
      setDate(currentActivity.date ? currentActivity.date : null);
      setPace(currentActivity.pace ? currentActivity.pace : 0);
      setHr(currentActivity.hr ? currentActivity.hr : 0);
    } else {
      setDownhillPace(null);
      setCurrentLaps(null);
      setUphillPace(null);
      setCalories(null);
      setPace({ minutes: null, seconds: null });
      setDate(null);
      setHr(null);
    }
  }, [currentActivity]);

  useEffect(() => {
    logEvent("open_activiy");
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setOpenElevation(false);
        setOpenSplit(false);
      }
    };

    const handleClickOutside = () => {
      setOpenElevation(false);
      setOpenSplit(false);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Dialog
        open={open}
        fullScreen
        aria-labelledby="activity-dialog-title"
        aria-describedby="activity-dialog-description"
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} position="static">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose}>
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, flex: 1, textAlign: "center" }}
            >
              {t("profile.activities.details.title")}
            </Typography>
            <IconButton color="inherit" onClick={() => handleShare(activity)}>
              <ShareIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box className={classes.layoutContent}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            marginBottom={"2.5rem"}
          >
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <img
                src={`${process.env.PUBLIC_URL}/${icon}.svg`}
                alt="run-icon"
                width={22}
              />
              <Box
                display="flex"
                alignItems="flex-start"
                marginLeft={"0.5rem"}
                flexDirection={"column"}
                justifyContent="space-between"
              >
                <Typography variant="h3" className={classes.activityName}>
                  {name}
                </Typography>

                {date !== null ? (
                  <Typography
                    className={classes.activityDate}
                    variant="body2"
                    color="darkgray"
                  >
                    {handleDateFormat(date)}
                  </Typography>
                ) : (
                  <Skeleton height={20} width={120} />
                )}
              </Box>
            </Box>
            <Box
              style={{
                gap: 8,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h2" className={classes.MI}>
                {calculateValue(
                  round(
                    convertUnits(distance, "kms", localUnits, "distance") /
                      1000,
                    1,
                  ),
                  round(
                    convertUnits(elevation, "kms", localUnits, "elevation"),
                    1,
                  ),
                )}
              </Typography>
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"space-between"}
              >
                <InfoIcon width={24} height={24} />
                <Typography variant="body2" className={classes.textGray}>
                  {t("mountainIndex.acro", { ns: "mountainIndex" })}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Grid container>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className={classes.boxBordered}
            >
              <Typography className={classes.activityStats}>{time}</Typography>
              <Box
                display={"flex"}
                justifyContent="flex-start"
                alignItems="center"
              >
                <TimerIcon />
                <Typography variant="caption" className={classes.textGray}>
                  {t("profile.card.duration")}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4} className={classes.boxBordered}>
              <Typography align="center" className={classes.activityStats}>
                {round(
                  convertUnits(distance, "kms", localUnits, "distance") / 1000,
                  1,
                )}{" "}
                {localUnits === "kms" ? "km" : "miles"}
              </Typography>
              <Box display={"flex"} justifyContent="center" alignItems="center">
                <DistanceIcon />
                <Typography variant="caption" className={classes.textGray}>
                  {t("profile.card.distance")}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={4}
              justifyContent="flex-end"
              alignItems="center"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography align="right" className={classes.activityStats}>
                {round(
                  convertUnits(elevation, "kms", localUnits, "elevation"),
                  1,
                )}{" "}
                {localUnits === "kms" ? "m" : "feet"}
              </Typography>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <ElevationIcon />
                <Typography variant="caption" className={classes.textGray}>
                  {t("profile.card.elevation")}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <CardTemplate>
            <Grid
              container
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 10px",
                gap: "20px 0",
              }}
            >
              <Grid item xs={6} justifyContent="flex-start">
                {minutes !== null && seconds !== null ? (
                  <>
                    <Typography className={classes.activityStatsSecondary}>
                      {`${minutes}:${(seconds < 10 ? "0" : "") + seconds}`}{" "}
                      {localUnits === "miles"
                        ? t("profile.unit.milesPerHour")
                        : t("profile.unit.kmPerHour")}
                    </Typography>
                    <Box display={"flex"}>
                      <LightningIcon />
                      <Typography
                        variant="caption"
                        className={classes.textGray}
                      >
                        {t("profile.card.avgPace")}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Skeleton height={20} width={120} />
                    <Box display="flex" alignItems="center">
                      <Skeleton
                        circle
                        width={20}
                        height={20}
                        style={{ marginRight: "8px" }}
                      />
                      <Skeleton height={20} width={80} />
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item xs={6} justifyContent="flex-start">
                {minutes !== null && seconds !== null ? (
                  <>
                    <Typography className={classes.activityStatsSecondary}>
                      {calculateMaxSpeed()}/
                      {localUnits === "kms"
                        ? t("profile.unit.km")
                        : t("profile.unit.miles")}
                    </Typography>
                    <Box display={"flex"} style={{ gap: "6px" }}>
                      <RunIcon width={19} height={20} />
                      <Typography
                        variant="caption"
                        className={classes.textGray}
                      >
                        {t("profile.card.fastest")}{" "}
                        {localUnits === "kms"
                          ? t("profile.unit.km")
                          : t("profile.unit.miles")}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Skeleton height={20} width={120} />
                    <Box display="flex" alignItems="center">
                      <Skeleton
                        circle
                        width={20}
                        height={20}
                        style={{ marginRight: "8px" }}
                      />
                      <Skeleton height={20} width={80} />
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item xs={6} justifyContent="flex-start">
                {uphillPace !== null ? (
                  <>
                    <Typography className={classes.activityStatsSecondary}>
                      {round(uphillPace || 0, 0) || 0}{" "}
                      {localUnits === "miles"
                        ? t("profile.unit.feetPerHour")
                        : t("profile.unit.mPerHour")}
                    </Typography>
                    <Box display={"flex"} style={{ gap: "6px" }}>
                      <AvgUphillSpeedIcon width={13} height={13} />
                      <Typography
                        variant="caption"
                        className={classes.textGray}
                      >
                        {t("profile.card.avgUphillSpeed")}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Skeleton height={20} width={120} />
                    <Box display="flex" alignItems="center">
                      <Skeleton
                        circle
                        width={20}
                        height={20}
                        style={{ marginRight: "8px" }}
                      />
                      <Skeleton height={20} width={80} />
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item xs={6} justifyContent="flex-start">
                {downhillPace !== null ? (
                  <>
                    <Typography className={classes.activityStatsSecondary}>
                      {round(downhillPace || 0, 0) || 0}{" "}
                      {localUnits === "miles"
                        ? t("profile.unit.feetPerHour")
                        : t("profile.unit.mPerHour")}
                    </Typography>
                    <Box display={"flex"} style={{ gap: "6px" }}>
                      <AvgDownhillSpeedIcon width={18} height={18} />
                      <Typography
                        variant="caption"
                        className={classes.textGray}
                      >
                        {t("profile.card.avgDownhillSpeed")}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Skeleton height={20} width={120} />
                    <Box display="flex" alignItems="center">
                      <Skeleton
                        circle
                        width={20}
                        height={20}
                        style={{ marginRight: "8px" }}
                      />
                      <Skeleton height={20} width={80} />
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item xs={6} justifyContent="flex-start">
                {hr !== null ? (
                  <>
                    <Typography className={classes.activityStatsSecondary}>
                      {hr || 0} {t("profile.unit.bpm")}
                    </Typography>
                    <Box display={"flex"} style={{ gap: "6px" }}>
                      <AvgHeartRateIcon width={18} height={18} />
                      <Typography
                        variant="caption"
                        className={classes.textGray}
                      >
                        {t("profile.card.avgHeartRate")}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Skeleton height={20} width={120} />
                    <Box display="flex" alignItems="center">
                      <Skeleton
                        circle
                        width={20}
                        height={20}
                        style={{ marginRight: "8px" }}
                      />
                      <Skeleton height={20} width={80} />
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item xs={6} justifyContent="flex-start">
                {calories !== null ? (
                  <>
                    <Typography className={classes.activityStatsSecondary}>
                      {calories || 0}
                    </Typography>
                    <Box display={"flex"} style={{ gap: "6px" }}>
                      <CaloriesIcon width={18} height={19} />
                      <Typography
                        variant="caption"
                        className={classes.textGray}
                      >
                        {t("profile.card.calories")}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Skeleton height={20} width={120} />
                    <Box display="flex" alignItems="center">
                      <Skeleton
                        circle
                        width={20}
                        height={20}
                        style={{ marginRight: "8px" }}
                      />
                      <Skeleton height={20} width={80} />
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </CardTemplate>

          {currentLaps !== null ? (
            currentLaps.length > 0 && (
              <Box style={{ marginTop: "1.7rem" }}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Box display={"flex"}>
                    <Typography variant={"h4"} className={classes.titleFont}>
                      {t(
                        "profile.activities.details.chartElevation.title",
                      ).toUpperCase()}
                    </Typography>
                    <Popper
                      sx={{ zIndex: 9999, maxWidth: 300 }}
                      open={openElevation}
                      anchorEl={anchorElElevation}
                      placement={placementElevation}
                      transition
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper className={classes.paperPopper}>
                            <Typography
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  "profile.activities.details.chartElevation.tooltip",
                                ),
                              }}
                            />
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                    <Box
                      marginLeft={"0.5rem"}
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      onClick={handlePopperElevation("top")}
                    >
                      <InfoIcon
                        width={15}
                        height={15}
                        vWidth={18}
                        vHeight={18}
                      />
                    </Box>
                  </Box>
                  <ActivityChart
                    activitiesData={currentLaps}
                    currentActivity={currentActivity}
                  />
                </Box>
              </Box>
            )
          ) : (
            <Box
              style={{
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <Skeleton height={80} width={320} />
            </Box>
          )}

          {currentLaps !== null ? (
            currentLaps.length > 0 && (
              <Box style={{ marginTop: "1.7rem" }}>
                <Box display={"flex"} marginBottom={"1.5rem"}>
                  <Typography variant={"h4"} className={classes.titleFont}>
                    {t(
                      "profile.activities.details.chartSplit.title",
                    ).toUpperCase()}
                  </Typography>
                  <Popper
                    sx={{ zIndex: 9999, maxWidth: 300 }}
                    open={openSplit}
                    anchorEl={anchorElSplit}
                    placement={placementSplit}
                    transition
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper className={classes.paperPopper}>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: t(
                                "profile.activities.details.chartSplit.tooltip",
                              ),
                            }}
                          />
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                  <Box
                    marginLeft={"0.5rem"}
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    onClick={handlePopperSplit("top")}
                  >
                    <InfoIcon width={15} height={15} vWidth={18} vHeight={18} />
                  </Box>
                </Box>
                <ActivityTable data={currentLaps} />
              </Box>
            )
          ) : (
            <Box
              style={{
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <Skeleton height={80} width={320} />
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
};
