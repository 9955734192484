import { API } from "aws-amplify";
import bugsnagClient from "../lib/bugsnag-client";
import dayjs from "dayjs";

class AdminService {
  static API_NAME = "apidatasage";

  static async getAdminData(
    status = "",
    start_date = "2023-06-26",
    coachesQuery = false,
    subcategoriesQuery = false,
  ) {
    const today = dayjs().format("YYYY-MM-DD");
    try {
      const response = await API.get(
        this.API_NAME,
        `/search?status=${status}&start_date=${start_date}&end_date=${today}${
          coachesQuery ? "&coachesQuery=true" : ""
        }${subcategoriesQuery ? "&subcategoriesQuery=true" : ""}`,
      );
      const { success, error } = response;
      const { body } = success || {};
      if (error) throw new Error(error);
      if (success) return { status, data: body.success.body };
    } catch (err) {
      bugsnagClient.notify(err);
      throw new Error(err);
    }
  }
}

export default AdminService;
