const WorkoutFilterMapper = (filter) => {
  switch (filter) {
    case "trainingPeriod":
      return "P";
    case "difficultyLevel":
      return "L";
    case "workoutType":
      return "W";
    case "surface":
      return "S";
    case "estimatedTime":
      return "T";
    default:
      return filter ? filter[0].toUpperCase() : "";
  }
};

export { WorkoutFilterMapper };
