import { API } from "aws-amplify";
import bugsnagClient from "../lib/bugsnag-client";

class SubscriptionsService {
  static API_NAME = "apisubscriptions";
  static CHECKOUT_PATH = (PLAN_ID) => `/subscriptions/checkout/${PLAN_ID}`;
  static LISTITEM_PATH = (PLAN_ID) => `/subscriptions/item/${PLAN_ID}`;
  static LATEST_PATH = "/subscriptions/latest";

  static async openCheckout(plan_id, params) {
    if (plan_id) {
      let myInit = {
        // OPTIONAL
        queryStringParameters: params,
      };
      // https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_new_subscription
      // If you want to use paypal, go cardless and plaid, pass embed parameter as false
      const response = await API.get(
        this.API_NAME,
        this.CHECKOUT_PATH(plan_id),
        myInit,
      );
      const { success, result, error } = response;

      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }

      return response;
    } else throw new Error("Invalid Keys");
  }
  static async getItem(plan_id) {
    if (plan_id) {
      const response = await API.get(
        this.API_NAME,
        this.LISTITEM_PATH(plan_id),
      );
      const { success, result, error } = response;

      if (success) return result;
      else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
      return response;
    } else throw new Error("Invalid Keys");
  }

  static async retryRequest(retries = 2, customerId) {
    try {
      const response = await API.get(
        this.API_NAME,
        `${this.LATEST_PATH}/${customerId}`,
      );

      const { error } = response;
      if (error) return error;
      return response;
    } catch (error) {
      if (retries == 0) return { error };
      return this.retryRequest(--retries);
    }
  }

  static async getLatestSubscription(customerId = "") {
    try {
      const response = await this.retryRequest(2, customerId);
      const { success, result } = response;
      const error = "true";
      if (success) return result;
      if (error) {
        return { error: "error" };
      }
      return response;
    } catch (exp) {
      bugsnagClient.notify(exp);
      return { error: "error" };
    }
  }
}

export default SubscriptionsService;
