import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Box,
  TextField,
  Typography,
  Button,
  Tooltip,
  Select,
  FormControl,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { sendTrainingZones } from "../../../actions/lab-action";
import { useSelector } from "react-redux";
import { Storage } from "aws-amplify";
import Genders from "../../../constants/genders";
import SnackBarMessageDisplay from "../../common/snack-bar-message-display";
import withWidth from "@material-ui/core/withWidth";

const useStyles = makeStyles({
  main: {
    padding: "20px 30px",
    backgroundColor: "#21222D",
    boxSizing: "border-box",
    borderRadius: 10,
  },
  whiteText: {
    color: "#FFF",
  },
  notchedOutline: {
    borderColor: "#5E5E5E",
  },
  root: {
    background: "#1C1C1C",
    marginBottom: 20,
    width: "100%",
    "& label.Mui-actived": {
      color: "#5E5E5E",
    },
    "& label.Mui-focused": {
      color: "#5E5E5E",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#5E5E5E",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#5E5E5E",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5E5E5E",
      },
    },
  },
  rootSelect: {
    background: "#1C1C1C",
    marginBottom: 20,
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
  title: {
    marginBottom: 16,
    color: "#fff",
  },
  label: {
    marginBottom: 6,
    color: "#fff",
    fontWeight: "bold",
  },
  submitBtn: {
    background: "#A9DFD8",
    width: "100%",
    marginTop: "10px",

    "&:disabled": {
      backgroundColor: "rgba(0,0,0,0.1)",
      color: "white",
    },
  },
  titleParent: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  customInput: {
    font: "inherit",
    height: "40px !important",
    margin: 0,
    display: "block",
    padding: "10.5px 14px",
    width: "100%",
    background: "none",
    boxSizing: "border-box",
    backgroundColor: "#1C1C1C",
    border: "solid 1px #5E5E5E",
    borderRadius: "4px",
    marginBottom: 20,
    cursor: "pointer",
    color: "#FFF",
    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",

    "&::-webkit-scrollbar": {
      display: "none",
    },

    "&:hover": {
      border: "solid 1px #000",
    },
  },
  inputRoot: {
    color: "green",
  },
  whiteColor: {
    color: "#FFF",
    border: "solid 1px #5E5E5E",
    borderRadius: "4px",
  },
  icon: {
    color: "#5E5E5E",
    border: "none",
  },
});

const fitLevels = [{ text: "fit" }, { text: "unfit" }, { text: "very fit" }];

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: "750px",
    width: "fit-content",
    padding: "30px",
    boxSizing: "border-box",
    borderRadius: 23,
  },
  arrow: {
    color: "white",
    fontSize: 28,
  },
}))(Tooltip);

const TrainingZoneForm = ({ runIfAuthenticated, handleOpenSignIn }) => {
  const { currentUser, isAuthenticated } = useSelector((state) => state.auth);
  const { attributes } = currentUser || {};
  const { sub } = attributes || {};

  const { trainingZoneLoading } = useSelector((state) => state.lab);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState("");
  const [form, setForm] = useState({
    fitFileKey: "",
    age: "",
    gender: "",
    height: "",
    weight: "",
    fitnessLevel: "",
    maxHR: "",
  });

  const handleFile = async (event) => {
    if (!isAuthenticated) return handleOpenSignIn();
    const file = event.target.files[0];
    if (!file) return;
    const fileName = file.name.split(".");
    const compoundName = `${sub}${fileName[0]}.${fileName[1]}`;

    try {
      const result = await Storage.put(compoundName, file, {
        bucket: "fitworkoutfiles",
      });

      setForm({ ...form, fitFileKey: result.key });
    } catch (err) {
      throw new Error(err);
    }
  };

  const handleField = (event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: /\d/.test(value) ? parseFloat(value) : value });
  };

  const handleHide = () => setErrorMessage("");

  const handleSubmit = () => {
    if (!Object.values(form).every((item) => item)) {
      setErrorMessage("All fields are required");

      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    }

    const baseData = { sub };
    const body = { ...baseData, ...form };
    body.fitFileKey = `public/${body.fitFileKey}`;

    dispatch(sendTrainingZones(body)).then(() => {
      setForm({
        fitFileKey: "",
        age: "",
        gender: "",
        height: "",
        weight: "",
        fitnessLevel: "",
        maxHR: "",
      });
    });
  };

  return (
    <Box className={classes.main}>
      {errorMessage && (
        <SnackBarMessageDisplay
          onHideMessage={handleHide}
          errorMessage={errorMessage}
        />
      )}

      <Box className={classes.titleParent}>
        <Typography className={classes.title} variant="h6">
          Training Zones Calculator
        </Typography>
        <>
          <LightTooltip title={<TooltipContent />} arrow>
            <InfoOutlined style={{ color: "#FFF" }} />
          </LightTooltip>
        </>
      </Box>
      <Box>
        <Typography className={classes.label} variant="body2">
          {" "}
          Upload the file of your activity
        </Typography>
      </Box>
      <input
        id="upload-activity"
        onChange={handleFile}
        type="file"
        accept=".fit"
        hidden
      />

      <label className={classes.customInput} htmlFor="upload-activity">
        {form.fitFileKey}
      </label>
      <Box>
        <Typography className={classes.label} variant="body2">
          What’s your age{" "}
        </Typography>
      </Box>
      <TextField
        id="age"
        value={form.age}
        name="age"
        type="number"
        hidden
        fullWidth
        size="small"
        required
        className={classes.root}
        onChange={(event) => runIfAuthenticated(() => handleField(event))}
        variant="outlined"
        InputProps={{
          inputProps: { style: { color: "#fff" } },
          classes: {
            notchedOutline: classes.notchedOutline,
          },
        }}
      />

      <Box>
        <Typography className={classes.label} variant="body2">
          With what gender do you identify?
        </Typography>
      </Box>
      <FormControl
        variant="outlined"
        className={classes.rootSelect}
        size="small"
      >
        <Select
          labelId="gender-label"
          id="gender"
          name="gender"
          value={form.gender}
          onChange={(event) => runIfAuthenticated(() => handleField(event))}
          disableUnderline
          classes={{
            root: classes.whiteColor,
            icon: classes.icon,
          }}
        >
          {Genders.itemsArray.map((gender, index) => (
            <MenuItem value={gender.value} key={index}>
              {gender.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box>
        <Typography className={classes.label} variant="body2">
          What’s your height
        </Typography>
      </Box>
      <TextField
        id="upload"
        value={form.height}
        name="height"
        type="number"
        hidden
        fullWidth
        size="small"
        required
        className={classes.root}
        onChange={(event) => runIfAuthenticated(() => handleField(event))}
        variant="outlined"
        InputProps={{
          inputProps: { style: { color: "#fff" } },
          classes: {
            notchedOutline: classes.notchedOutline,
          },
        }}
      />
      <Box>
        <Typography className={classes.label} variant="body2">
          {" "}
          What’s your weight
        </Typography>
      </Box>
      <TextField
        id="upload"
        value={form.weight}
        name="weight"
        type="number"
        hidden
        fullWidth
        size="small"
        required
        onChange={(event) => runIfAuthenticated(() => handleField(event))}
        className={classes.root}
        variant="outlined"
        InputProps={{
          inputProps: { style: { color: "#fff" } },
          classes: {
            notchedOutline: classes.notchedOutline,
          },
        }}
      />

      <Box>
        <Typography className={classes.label} variant="body2">
          How fit are you?
        </Typography>
      </Box>
      <FormControl
        variant="outlined"
        className={classes.rootSelect}
        size="small"
      >
        <Select
          labelId="fit-label"
          id="fitnessLevel"
          name="fitnessLevel"
          value={form.fitnessLevel}
          onChange={(event) => runIfAuthenticated(() => handleField(event))}
          disableUnderline
          classes={{
            root: classes.whiteColor,
            icon: classes.icon,
          }}
        >
          {fitLevels.map((item) => (
            <MenuItem value={item.text} key={item.text}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box>
        <Typography className={classes.label} variant="body2">
          What’s your max heart rate
        </Typography>
      </Box>
      <TextField
        id="maxHR"
        value={form.maxHR}
        name="maxHR"
        type="number"
        hidden
        fullWidth
        size="small"
        required
        onChange={(event) => runIfAuthenticated(() => handleField(event))}
        className={classes.root}
        variant="outlined"
        InputProps={{
          inputProps: { style: { color: "#fff" } },
          classes: {
            notchedOutline: classes.notchedOutline,
          },
        }}
      />
      <Button
        variant="contained"
        size="large"
        className={classes.submitBtn}
        onClick={() => runIfAuthenticated(handleSubmit)}
        disabled={trainingZoneLoading}
      >
        {trainingZoneLoading ? (
          <CircularProgress variant="indeterminate" size={28} color="#A9DED8" />
        ) : (
          "Submit"
        )}
      </Button>
    </Box>
  );
};

const TooltipContent = () => (
  <>
    <Typography variant="body2">
      In order for our algorithm to calculate your accurate Lactate Threshold,
      you need to do a specific test workout (running or biking). This workout
      consists of:{" "}
    </Typography>
    <ul>
      <li>
        <Typography variant="body2">
          10 to 15-minute easy warm-up at an endurance pace (RPE of 3 - 4).
        </Typography>
      </li>
      <li>
        <Typography variant="body2">
          Followed by a 15 to 20 minutes tempo run, on a flat surface or an
          uphill, wherever you feel more comfortable.
        </Typography>
      </li>
    </ul>
    <Typography variant="body2" style={{ marginTop: 10 }}>
      During the tempo run, should be an RPE of 6 to 7 on the perceived exertion
      scale, you should be able to talk short sentences while maintaining a
      focused effort, without entering the "red-zone" meaning, without getting
      to an 8+ on the RPE scale.
    </Typography>

    <Typography variant="body2" style={{ marginTop: 10 }}>
      You should finish this 15 to 20 minutes tempo run, feeling that you could
      have continued running at the same speed for 15 to 20 more minutes.
    </Typography>

    <Typography variant="body2" style={{ marginTop: 10 }}>
      You should not feel exhausted after the tempo, and you should always feel
      in control of your effort.
    </Typography>
  </>
);

export default withWidth()(TrainingZoneForm);
