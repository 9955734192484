import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import "url-search-params-polyfill";
import { Button, TextField, InputAdornment, Avatar } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import jimp from "jimp";
import LinkPreview from "@ashwamegh/react-link-preview";
import "@ashwamegh/react-link-preview/dist/index.css";
import SnackBarMessageDisplay from "../../common/snack-bar-message-display";
import ReactDOM from "react-dom";
import { reduceFileSize } from "../../../lib/image-helper";
import { getVideoIframe, getYoutubeId } from "../../../lib/youtube-helper";

const styles = (theme) => ({
  topBar: {
    display: "flex",
    alignItems: "center",
    margin: 10,
  },
  avatar: {
    marginRight: 10,
  },
  main: {
    display: "flex",
  },
  container: {
    textAlign: "center",
    width: "100%",
  },
  mediaMenu: {
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
  },
  mediaIcon: {
    margin: 5,
    cursor: "pointer",
  },
  mediaIconLink: {
    margin: 5,
    cursor: "pointer",
    marginTop: 0,
  },
  input: {
    display: "none",
  },
  postImageContainer: {
    position: "relative",
    display: "inline-block",
  },
  postImage: {
    maxWidth: "100%",
    maxHeight: 300,
    marginTop: 15,
  },
  actionIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "5px",
    top: "20px",
    color: "#ffffff",
  },

  linkContainer: {
    marginTop: 15,
    position: "relative",
  },

  addLinkContainer: {
    display: "flex",
    alignItems: "center",
  },
  addLinkInput: {
    marginRight: 15,
  },
  addLinkButton: {
    marginLeft: "auto",
    marginTop: 10,
    borderRadius: 40,
  },

  linkIcon: {
    cursor: "pointer",
    color: "#000000",
  },
  actionIconLink: {
    cursor: "pointer",
    position: "absolute",
    right: "5px",
    top: "5px",
    color: "#000000",
    zIndex: 1,
  },
  textarea: {
    overflow: "hidden",
    marginTop: 0,
  },
  publishButton: {
    borderRadius: 40,
  },
  cancelButton: {
    borderRadius: 40,
    marginRight: 10,
    marginLeft: 10,
  },
  bottomBar: {
    display: "flex",
  },
  round: {
    borderRadius: 50,
  },
  noMargin: {},
});

class AddComment extends Component {
  state = {
    text: null,
    currentImage: null,
    currentImageExtension: null,
    link: null,
    isAddingLink: false,
    errorMessage: null,
    showAllFeatures: false,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.hideAll();
    }
  };

  async componentDidUpdate(prevPops) {}

  handleSave = () => {
    const { userSub, onAddComment, postId, commentId, hasSub } = this.props;
    const { text, link, currentImage, currentImageExtension } = this.state;

    const comment = {
      text,
      link,
      image: currentImage,
      imageExtension: currentImageExtension,
      cognito_user_sub: userSub,
      comment_to: postId,
      reply_to: commentId,
      crown: hasSub,
    };

    onAddComment(comment);

    this.cancel();
  };

  handleTextChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          ...this.state,
          text: value || "",
        });
      }
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const image = await jimp.read(reader.result);
          //await image.resize(150, jimp.AUTO);
          const fileNameArray = file.name ? file.name.split(".") : undefined;
          const extension = fileNameArray[fileNameArray.length - 1];
          const data = await image.getBase64Async(
            extension === "png" ? jimp.MIME_PNG : jimp.MIME_JPEG,
          );
          resolve({
            image: data,
            extension,
          });
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleInputChange = async (event) => {
    if (this.state.currentImage || this.state.link) {
      this.handleShowMessage(
        "Please clear the link or image before adding a new link or image",
      );
    } else {
      if (event) {
        const { target } = event;
        const { onError, maxSize, t } = this.props;
        if (target) {
          const { files } = target;
          if (files) {
            const selectedFile = files[0];
            try {
              if (selectedFile) {
                const { size } = selectedFile;
                if (size <= maxSize) {
                  if (size > 1024000) {
                    reduceFileSize(
                      selectedFile,
                      1024000,
                      1000,
                      Infinity,
                      0.9,
                      async (fixedFile) => {
                        fixedFile.name = selectedFile.name;
                        const {
                          image: imageString,
                          extension,
                        } = await this.getBase64(fixedFile);

                        this.setState({
                          ...this.state,
                          currentImage: imageString,
                          currentImageExtension: extension,
                        });
                      },
                    );
                  } else {
                    const {
                      image: imageString,
                      extension,
                    } = await this.getBase64(selectedFile);

                    this.setState({
                      ...this.state,
                      currentImage: imageString,
                      currentImageExtension: extension,
                    });
                  }
                } else if (onError) {
                  onError(
                    t("Sorry, your image needs to be smaller than maxSize10", {
                      ns: "messages",
                    }),
                  );
                }
              } else {
                if (onError) {
                  onError(t("Invalid File", { ns: "messages" }));
                }
              }
            } catch (exp) {
              if (onError) onError(exp);
            }
          }
        }
      }
    }
  };

  deselectMedia = () => {
    this.setState({
      ...this.state,
      currentImage: null,
      currentImageExtension: null,
      link: null,
    });
  };

  handleStartEditLink = () => {
    if (this.state.currentImage || this.state.link) {
      this.handleShowMessage(
        "Please clear the link or image before adding a new link or image",
      );
    } else {
      this.setState({
        ...this.state,
        isAddingLink: true,
      });
    }
  };

  handleFinishEditLink = (save) => {
    if (save) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i",
      ); // fragment locator
      if (!pattern.test(this.state.link)) {
        this.handleShowMessage("Please paste a valid link");
        return;
      }
    }
    this.setState({
      ...this.state,
      isAddingLink: false,
      link: save ? this.state.link : null,
    });
  };

  handleLinkChange = (event) => {
    if (event) {
      const { target } = event;
      if (target) {
        const { value } = target;
        this.setState({
          ...this.state,
          link: value || "",
        });
      }
    }
  };

  handleHideMessage = () => {
    this.setState({
      ...this.state,
      errorMessage: null,
    });
  };

  handleShowMessage = (message) => {
    this.setState({
      ...this.state,
      errorMessage: message,
    });
  };

  showAll = () => {
    this.setState({
      ...this.state,
      showAllFeatures: true,
    });
  };

  hideAll = () => {
    this.setState({
      ...this.state,
      showAllFeatures: false,
    });
  };

  cancel = () => {
    const { onCancel } = this.props;
    this.setState({
      text: null,
      currentImage: null,
      currentImageExtension: null,
      link: null,
      isAddingLink: false,
      errorMessage: null,
      showAllFeatures: false,
    });
    onCancel();
  };

  render() {
    const { classes, t, image } = this.props;

    const {
      text,
      currentImage,
      link,
      isAddingLink,
      errorMessage,
      showAllFeatures,
    } = this.state;

    return (
      <div className={classes.main}>
        <Avatar
          src={image ? image : `${process.env.PUBLIC_URL}/avatar-empty.png`}
          className={classes.avatar}
        />

        <div className={classes.container}>
          {currentImage && (
            <div className={classes.postImageContainer}>
              <CloseIcon
                onClick={() => this.deselectMedia()}
                className={classes.actionIcon}
                fontSize="small"
              />
              <img src={currentImage} alt="" className={classes.postImage} />
            </div>
          )}

          {!isAddingLink && link ? (
            getYoutubeId(link) ? (
              <div className={classes.linkContainer}>
                <CloseIcon
                  onClick={() => this.deselectMedia()}
                  className={classes.actionIconLink}
                  fontSize="small"
                />
                {getVideoIframe(link)}
              </div>
            ) : (
              <div className={classes.linkContainer}>
                <CloseIcon
                  onClick={() => this.deselectMedia()}
                  className={classes.actionIconLink}
                  fontSize="small"
                />
                <LinkPreview url={link} />
              </div>
            )
          ) : (
            ""
          )}

          <TextField
            value={text ? text : ""}
            margin="normal"
            variant="outlined"
            placeholder={t("Comment placeholder")}
            fullWidth={true}
            multiline={true}
            onChange={this.handleTextChange}
            onFocus={this.showAll}
            inputProps={{ maxLength: 2200 }}
            className={classes.textarea}
            InputProps={{
              classes: {
                root: classes.round,
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  {showAllFeatures ? (
                    <div className={classes.mediaMenu}>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file-2"
                        type="file"
                        onChange={this.handleInputChange}
                      />
                      <label htmlFor="contained-button-file-2">
                        <img
                          src={`${process.env.PUBLIC_URL}/icon-media-image-comment.svg`}
                          alt=""
                          className={classes.mediaIcon}
                        />
                      </label>
                      {/*<img
                        src={`${process.env.PUBLIC_URL}/icon-media-link-comment.svg`}
                        alt=""
                        onClick={() => this.handleStartEditLink()}
                        className={classes.mediaIconLink}
                  />*/}
                    </div>
                  ) : (
                    <></>
                  )}
                </InputAdornment>
              ),
            }}
          />

          {isAddingLink ? (
            <div className={classes.addLinkContainer}>
              <TextField
                value={link ? link : ""}
                margin="normal"
                variant="outlined"
                placeholder={t("Link placeholder")}
                fullWidth={true}
                inputProps={{ maxLength: 2200 }}
                // onChange={this.handleLinkChange}
                className={classes.addLinkInput}
                InputProps={{
                  classes: {
                    root: classes.round,
                  },
                }}
              />
              <CheckIcon
                onClick={() => this.handleFinishEditLink(true)}
                className={classes.linkIcon}
                fontSize="small"
              />{" "}
              <CloseIcon
                onClick={() => this.handleFinishEditLink(false)}
                className={classes.linkIcon}
                fontSize="small"
              />
            </div>
          ) : (
            ""
          )}

          {showAllFeatures && (
            <div className={classes.bottomBar}>
              <Button
                color="primary"
                variant="contained"
                className={classes.publishButton}
                onClick={this.handleSave}
                disabled={!text}
              >
                {t("Comment")}
              </Button>
            </div>
          )}
          <SnackBarMessageDisplay
            onHideMessage={this.handleHideMessage}
            errorMessage={t(errorMessage)}
          />
        </div>
      </div>
    );
  }
}

AddComment.defaultProps = {
  maxSize: 10240000,
};

export default withTranslation("trailhead")(
  withWidth()(withStyles(styles, { withTheme: true })(AddComment)),
);
