import { API } from "aws-amplify";
import { ACTIVITY_TYPES } from "../lib/activities-helper";

class SuuntoService {
  static API_NAME = "apidevice";
  static AUTHORIZATION_INIT_PATH = "/device/suuntoInit";
  static GET_ACTIVITIES_PATH = "/device/suuntoActivities/:cognito_user_sub";
  static GET_ACTIVITY_PATH = "/device/suuntoActivity/:cognito_user_sub";
  static EXPORT_WORKOUT_PATH = "/device/exportSuuntoWorkout";

  static async initAuthorization(code) {
    if (code) {
      const myInit = {
        body: {
          code,
        },
      };
      const response = await API.post(
        this.API_NAME,
        this.AUTHORIZATION_INIT_PATH,
        myInit,
      );
      const { success, result, error } = response;

      if (success) {
        return result;
      } else if (error) {
        if (typeof error === typeof "") throw new Error(error);
        else throw error;
      }
    } else throw new Error("Invalid Keys");
  }

  static async getActivities(params) {
    if (params) {
      const {
        start_date,
        end_date,
        access_token,
        cognito_user_sub,
        expires_at,
        refresh_token,
      } = params;
      if (
        start_date &&
        end_date &&
        access_token &&
        cognito_user_sub &&
        expires_at &&
        refresh_token
      ) {
        let myInit = {
          queryStringParameters: {
            start_date,
            end_date,
            access_token,
            expires_at,
            refresh_token,
          },
        };
        const response = await API.get(
          this.API_NAME,
          this.GET_ACTIVITIES_PATH.replace(
            ":cognito_user_sub",
            cognito_user_sub,
          ),
          myInit,
        );
        const { success, result, error } = response;

        if (success) {
          const data = result.map((activity) => {
            if (!activity.device)
              return { ...activity, device: ACTIVITY_TYPES.SUUNTO };
            return activity;
          });
          return data;
        } else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      }
    }
  }

  static async getActivity(params) {
    if (params) {
      const {
        workoutId,
        access_token,
        cognito_user_sub,
        expires_at,
        refresh_token,
      } = params;
      if (
        workoutId &&
        access_token &&
        cognito_user_sub &&
        expires_at &&
        refresh_token
      ) {
        let myInit = {
          queryStringParameters: {
            workoutId,
            access_token,
            expires_at,
            refresh_token,
          },
        };
        const response = await API.get(
          this.API_NAME,
          this.GET_ACTIVITY_PATH.replace(":cognito_user_sub", cognito_user_sub),
          myInit,
        );
        const { success, result, error } = response;

        if (success) return result;
        else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      }
    }
  }

  static async exportWorkout(params) {
    if (params) {
      const {
        workout_id,
        access_token,
        level,
        cognito_user_sub,
        date,
      } = params;
      if (workout_id && access_token && level && cognito_user_sub && date) {
        let myInit = {
          body: {
            workout_id,
            access_token,
            level,
            cognito_user_sub,
            date,
          },
        };
        const response = await API.post(
          this.API_NAME,
          this.EXPORT_WORKOUT_PATH,
          myInit,
        );
        const { success, result, error } = response;

        if (success) return result;
        else if (error) {
          if (typeof error === typeof "") throw new Error(error);
          else throw error;
        }
      }
    }
  }
}

export default SuuntoService;
