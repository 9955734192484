import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import {
  Grid,
  Typography,
  LinearProgress,
  Tabs,
  Tab,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Weekdays from "../../constants/weekdays";
import EstimatedTimes from "../../constants/estimated-times";
import "moment/min/locales";
import { dateFormat } from "../../lib/date-helper";
import history from "../../lib/history-helper";
import { getPlanLevel, getCustomLevel } from "../../lib/plan-helper";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ArrowForwardIos } from "@material-ui/icons";
import { connect } from "react-redux";

const styles = (theme) => ({
  container: {
    borderRadius: 12,
    margin: 12,

    background: "#fff",
  },

  workoutsGraphContainer: {
    paddingBottom: 2,
  },

  workoutsGraphTitle: {
    fontWeight: 400,
    fontSize: 14,
    margin: "25px 10px 0",
  },

  graphContainer: {
    height: 250,
    [theme.breakpoints.down("md")]: {
      height: 200,
      margin: "4px",
      background: "#FFFFFF",
      border: "solid 1px #F0F0F0",
      borderRadius: "5px",
      padding: 10,
    },
  },
  topBar: {
    alignItems: "center",
    marginBottom: 20,
    justifyContent: "center",
    top: 0,
    zIndex: 100,
    background: "#fff",
  },

  stats: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: 24,
  },

  stat: {
    textAlign: "center",
  },

  statValue: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "Avenir",
  },

  statLabel: {
    fontWeight: "normal",
    fontSize: 14,
    fontFamily: "Avenir",
  },

  doneChart: {
    minWidth: 120,
    height: 144,
    position: "relative",
    margin: 10,
  },

  doneTextCont: {
    top: "50%",
    left: "50%",
    color: "#212121",
    position: "absolute",
    fontSize: 34,
    transform: "translate(-50%, -50%)",
  },

  doneText: {
    color: "#212121",
    fontSize: 24,
    fontWeight: 500,
  },
  donePercentage: {
    color: "#C4C4C4",
    fontSize: 14,
    textAlign: "center",
    fontWeight: 400,
    marginTop: -10,
  },
  doneTip: {
    color: "rgba(158, 158, 158, 0.7)",
    fontSize: 14,
    marginTop: 10,
    textAlign: "left",
    fontWeight: 500,
  },

  title: {
    fontFamily: "Avenir",
    fontWeight: "normal",
    fontSize: 14,
    padding: 12,
    marginRight: "auto",
  },

  titleCont: {
    display: "flex",
    alignItems: "center",
  },

  syncTracker: {
    fontWeight: 400,
    fontSize: 14,
    color: "#FF6327",
  },

  addButtonContainer: {
    marginLeft: "10px",
    marginRight: "10px",
    cursor: "pointer",
  },
  chartsCard: {
    padding: "12px",
    border: "solid 1px #F0F0F0",
    borderRadius: "5px",
    boxSixing: "border-box",
    width: "90%",
    margin: "auto",
  },

  statsParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "16px",
  },

  statTitle: {
    marginLeft: "5px",
  },

  statSubValue: {
    fontWeight: "bold",
    textAlign: "center",
  },
  timesParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  moveParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    margin: "auto",
    marginBottom: "12px",
  },
});

class DashboardWeeklyReportDiagramMobile extends Component {
  state = {
    tab: 0,
    units: "kms",
    openDone: false,
    doneGran: "plan",
    openEnergy: false,
    energyGran: "plan",
    open: false,
    currentDate: moment(),
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setState({
        ...this.state,
        units: localStorage.getItem("units"),
      });

      window.addEventListener("storage", () => this.localStorageUpdated());
    }
  }

  localStorageUpdated() {
    this.setState({
      ...this.state,
      units: localStorage.getItem("units"),
    });
  }

  handleOpenDone() {
    this.setState({
      ...this.state,
      openDone: true,
    });
  }

  handleCloseDone(doneGran = null) {
    this.setState({
      ...this.state,
      openDone: false,
      doneGran: doneGran ? doneGran : this.state.doneGran,
    });
  }

  handleOpenEnergy() {
    this.setState({
      ...this.state,
      openEnergy: true,
    });
  }

  handleCloseEnergy(energyGran = null) {
    this.setState({
      ...this.state,
      openEnergy: false,
      energyGran: energyGran ? energyGran : this.state.energyGran,
    });
  }

  handleToggle = () => {
    this.setState({
      ...this.state,
      open: !this.state.open,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      open: false,
    });
  };

  openSettings = () => {
    history.push("/profile");
  };

  mutateAndNormalizeArrays(planned, done, over) {
    for (let i = 0; i < planned.length; i++) {
      if (planned[i] > 0 && over[i] > 0) {
        const startPlanned = planned[i];
        const startOver = over[i];

        if (startOver > startPlanned) {
          planned[i] = 0;
          over[i] -= startPlanned;
          done[i] += startPlanned;
        } else {
          over[i] = 0;
          planned[i] -= startOver;
          done[i] += startOver;
        }
      }
    }
  }

  render() {
    const {
      classes,
      xs,
      sm,
      md,
      lg,
      xl,
      className,
      currentTraining,
      t,
      i18n,
      activities,
      width,
      noTracker,
      loading,
    } = this.props;

    const isInMobileView = width === "md" || width === "sm" || width === "xs";

    const { tab, units, open } = this.state;
    const { days, currentStartDate, startingDate, durationInWeeks } =
      currentTraining || {};
    const { chainedPlans } = currentTraining || {};

    const { language } = i18n;
    const momentLocale = moment().locale(language);

    const level = getPlanLevel(currentTraining);

    // Overview
    console.time();
    let currentPlannedWeek = [];
    let currentDoneWeek = [];
    let currentOverWeek = [];
    let plannedWeek = 0;

    let labels = [];
    const { currentDate } = this.state;
    const weekStartDate = moment(currentDate).startOf("isoWeek");
    const dates = [];
    for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
      dates.push(moment(weekStartDate).add(dayIndex, "day"));
      if (days) {
        const tempDate = moment(weekStartDate).add(dayIndex, "day");

        let foundActivitiesTime = 0;
        if (activities) {
          const foundActivities = activities.filter(
            (activity) =>
              activity && moment(activity.start_date).isSame(tempDate, "day"),
          );

          if (foundActivities && foundActivities.length > 0) {
            foundActivitiesTime = foundActivities
              .map((item) => Math.round(item.elapsed_time / 60) || 0)
              .reduce((a, sum) => a + sum);
          }
        }

        const foundDay = days.find(
          (day) =>
            day &&
            day.length > 0 &&
            moment(day[0].plannedDate, dateFormat).isSame(tempDate, "day"),
        );

        if (foundDay) {
          const customLevel = getCustomLevel(
            foundDay[0].plannedDate,
            chainedPlans,
          );

          const dayWorkoutsTime = foundDay
            .map((item) =>
              Array.isArray(item.estimatedTime)
                ? +item.estimatedTime[customLevel ? customLevel : level]
                : +item.estimatedTime,
            )
            .reduce((a, sum) => a + sum);

          plannedWeek += dayWorkoutsTime;

          currentPlannedWeek.push(
            Math.max(0, dayWorkoutsTime - foundActivitiesTime),
          );
          currentDoneWeek.push(
            foundActivitiesTime > dayWorkoutsTime
              ? dayWorkoutsTime
              : foundActivitiesTime,
          );
          currentOverWeek.push(
            foundActivitiesTime > dayWorkoutsTime
              ? foundActivitiesTime - dayWorkoutsTime
              : 0,
          );
        } else {
          currentPlannedWeek.push(0);
          currentDoneWeek.push(0);
          currentOverWeek.push(0);
        }
      } else {
        currentPlannedWeek.push(0);
        currentDoneWeek.push(0);
        currentOverWeek.push(0);
      }
    }
    console.timeEnd();

    let currentPlannedTotal = [];
    let currentDoneTotal = [];
    let currentOverTotal = [];
    let plannedTotal = 0;

    for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
      currentPlannedTotal.push(0);
      currentDoneTotal.push(0);
      currentOverTotal.push(0);
    }
    const startingDateMoment = moment(startingDate, dateFormat);

    if (days) {
      days.forEach((day) => {
        if (day) {
          const plannedDay =
            moment(day[0].plannedDate, dateFormat).diff(
              startingDateMoment,
              "days",
            ) + 1;
          const plannedWeek = Math.floor(
            (plannedDay > 1 ? plannedDay - 1 : 0) / 7,
          );

          let foundActivitiesTime = 0;
          if (activities) {
            const foundActivities = activities.filter(
              (activity) =>
                activity &&
                moment(activity.start_date).isSame(
                  moment(day[0].plannedDate, dateFormat),
                  "day",
                ),
            );

            if (foundActivities && foundActivities.length > 0) {
              foundActivitiesTime = foundActivities
                .map((item) => Math.round(item.elapsed_time / 60) || 0)
                .reduce((a, sum) => a + sum);
            }
          }

          const customLevel = getCustomLevel(day[0].plannedDate, chainedPlans);

          if (plannedWeek < durationInWeeks) {
            const dayWorkoutsTime = day
              .map((item) =>
                Array.isArray(item.estimatedTime)
                  ? +item.estimatedTime[customLevel ? customLevel : level]
                  : +item.estimatedTime,
              )
              .reduce((a, sum) => a + sum);

            plannedTotal += dayWorkoutsTime;

            const planned = Math.max(0, dayWorkoutsTime - foundActivitiesTime);

            const done =
              foundActivitiesTime > dayWorkoutsTime
                ? dayWorkoutsTime
                : foundActivitiesTime;

            const over =
              foundActivitiesTime > dayWorkoutsTime
                ? foundActivitiesTime - dayWorkoutsTime
                : 0;

            currentPlannedTotal[plannedWeek] += isNaN(+planned) ? 0 : +planned;
            currentDoneTotal[plannedWeek] += isNaN(+done) ? 0 : +done;

            currentOverTotal[plannedWeek] += isNaN(+over) ? 0 : +over;
          }
        }
      });
    }
    this.mutateAndNormalizeArrays(
      currentPlannedTotal,
      currentDoneTotal,
      currentOverTotal,
    );

    let currentPlannedMonth = [];
    let currentDoneMonth = [];
    let currentOverMonth = [];
    let monthLabels = [];
    let totalWeeks = 0;
    let plannedMonth = 0;

    for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
      currentPlannedMonth.push(0);
      currentDoneMonth.push(0);
      currentOverMonth.push(0);
    }

    const monthStartDate = moment(currentDate)
      .startOf("isoWeek")
      .add(-3, "weeks");
    const monthEndDate = moment(currentDate)
      .endOf("isoWeek")
      .add(1, "weeks");

    for (
      let dateIdx = moment(monthStartDate);
      dateIdx.isBefore(monthEndDate, "day");
      dateIdx.add(7, "days")
    ) {
      const labelDate = moment(dateIdx);

      monthLabels.push(
        `${labelDate.startOf("isoWeek").format("MMM")} ${labelDate
          .startOf("isoWeek")
          .date()} - ${labelDate
          .endOf("isoWeek")
          .format("MMM")} ${labelDate.endOf("isoWeek").date()}`,
      );
    }

    if (days) {
      days.forEach((day) => {
        if (day) {
          if (
            moment(day[0].plannedDate, dateFormat).isBetween(
              monthStartDate,
              monthEndDate,
              "[]",
            )
          ) {
            const plannedDay =
              moment(day[0].plannedDate, dateFormat).diff(
                monthStartDate.startOf("isoWeek"),
                "days",
              ) + 1;

            const plannedWeek = Math.floor(
              (plannedDay > 1 ? plannedDay - 1 : 0) / 7,
            );

            if (plannedWeek + 1 > totalWeeks) {
              totalWeeks = plannedWeek + 1;
            }

            let foundActivitiesTime = 0;
            if (activities) {
              const foundActivities = activities.filter(
                (activity) =>
                  activity &&
                  moment(activity.start_date).isSame(
                    moment(day[0].plannedDate, dateFormat),
                    "day",
                  ),
              );

              if (foundActivities && foundActivities.length > 0) {
                foundActivitiesTime = foundActivities
                  .map((item) => Math.round(item.elapsed_time / 60) || 0)
                  .reduce((a, sum) => a + sum);
              }
            }

            const customLevel = getCustomLevel(
              day[0].plannedDate,
              chainedPlans,
            );

            if (plannedWeek < durationInWeeks) {
              const dayWorkoutsTime = day
                .map((item) =>
                  Array.isArray(item.estimatedTime)
                    ? +item.estimatedTime[customLevel ? customLevel : level]
                    : +item.estimatedTime,
                )
                .reduce((a, sum) => a + sum);

              plannedMonth += dayWorkoutsTime;

              const planned = Math.max(
                0,
                dayWorkoutsTime - foundActivitiesTime,
              );

              const done =
                foundActivitiesTime > dayWorkoutsTime
                  ? dayWorkoutsTime
                  : foundActivitiesTime;

              const over =
                foundActivitiesTime > dayWorkoutsTime
                  ? foundActivitiesTime - dayWorkoutsTime
                  : 0;

              currentPlannedMonth[plannedWeek] += isNaN(+planned)
                ? 0
                : +planned;
              currentDoneMonth[plannedWeek] += isNaN(+done) ? 0 : +done;

              currentOverMonth[plannedWeek] += isNaN(+over) ? 0 : +over;
            }
          }
        }
      });
    }

    currentPlannedMonth = currentPlannedMonth.slice(0, totalWeeks);
    currentDoneMonth = currentDoneMonth.slice(0, totalWeeks);
    currentOverMonth = currentOverMonth.slice(0, totalWeeks);
    this.mutateAndNormalizeArrays(
      currentPlannedMonth,
      currentDoneMonth,
      currentOverMonth,
    );
    monthLabels = monthLabels.slice(0);

    const calculateProgressBar = (achievedTime, plannedTime) => {
      const result = (achievedTime && (achievedTime * 100) / plannedTime) || 0;
      if (!isFinite(result)) return 100;
      return result < 100 ? Math.round(result) : 100;
    };

    const plannedType = {
      "0": currentPlannedWeek,
      "1": currentPlannedMonth,
      "2": currentPlannedTotal,
    };

    const currentDoneType = {
      "0": currentDoneWeek,
      "1": currentDoneMonth,
      "2": currentDoneTotal,
    };

    const currentOverType = {
      "0": currentOverWeek,
      "1": currentOverMonth,
      "2": currentOverTotal,
    };

    const currentPlanned = plannedType[tab];
    const currentDone = currentDoneType[tab];
    const currentOver = currentOverType[tab];

    if (tab === 0) {
      labels = Weekdays.itemsArray.map((item, i) =>
        isInMobileView
          ? [
              `${dates[i].format("D")} ${momentLocale
                .localeData()
                .months(dates[i])
                .substring(0, 3)}`,
            ]
          : [
              t(item.text)
                .substring(0, 3)
                .toUpperCase(),
              `${dates[i].format("D")} ${momentLocale
                .localeData()
                .months(dates[i])
                .substring(0, 3)}`,
            ],
      );
    }

    if (tab === 1) {
      labels = monthLabels;
    }

    if (tab === 2) {
      labels = [];
      for (let weekIndex = 0; weekIndex < durationInWeeks; weekIndex++) {
        labels.push(t("WeekMobile", { week: weekIndex + 1, ns: "dashboard" }));
      }
    }

    const data = {
      labels,
      datasets: [
        {
          label: t("Achieved", { ns: "dashboard" }),
          backgroundColor: "#578fccb3",
          borderWidth: 0,
          data: currentDone,
        },
        {
          label: t("Left to Complete", { ns: "dashboard" }),
          backgroundColor: "#EDEDED",
          data: currentPlanned,
        },
        {
          label: t("Extra", { ns: "dashboard" }),
          backgroundColor: "#feaeaeb3",
          borderWidth: 0,
          data: currentOver,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
            color: "#DDDDDD",
            drawBorder: false,
          },
          ticks: {
            minor: {
              fontFamily: "Avenir",
              fontSize: 12,
              fontColor: "#9E9E9E",
            },
            padding: 10,
          },
          stacked: true,
        },
        y: {
          grid: {
            color: "rgba(0, 0, 0, 0.05)",
            zeroLineColor: "rgba(0, 0, 0, 0.05)",
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: tab === 0 ? 60 : 180,
            callback: function(value) {
              return EstimatedTimes.convertMinutesToLabel(value);
            },
            fontFamily: "Avenir",
            fontSize: 12,
            fontColor: "#9E9E9E",
            padding: 10,
          },
          stacked: true,
        },
      },
      animation: {
        easing: "linear",
        duration: 1000,
      },
      interaction: {
        mode: "index",
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          mode: "single",
          callbacks: {
            label: function(context) {
              let title = t("Achieved", { ns: "dashboard" });
              if (context.datasetIndex === 1)
                title = t("Left to Complete", { ns: "dashboard" });
              if (context.datasetIndex === 2)
                title = t("Extra", { ns: "dashboard" });

              return `${title}: ${EstimatedTimes.convertMinutesToLabel(
                +context.parsed.y,
                true,
              )}`;
            },
            title: function() {
              return null;
            },
          },
        },
      },
    };

    // Report
    let time = [];
    let timeCross = [];
    let distance = [];
    let elevation = [];
    let reportLabels = [];

    let runActivities = [];
    let crossTrainingActivities = [];

    if (activities) {
      runActivities = activities.filter(
        (a) =>
          (a.type && a.type.toLowerCase().includes("run")) ||
          (a.activityType && a.activityType.includes("RUN")),
      );
      crossTrainingActivities = activities.filter(
        (a) =>
          (a.type && a.type.toLowerCase() !== "run") ||
          (a.activityType && !a.activityType.includes("RUN")),
      );
    }

    if (tab === 0) {
      const weekStartDate = moment(currentDate).startOf("isoWeek");
      const dates = [];
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        dates.push(moment(weekStartDate).add(dayIndex, "day"));
        if (runActivities) {
          const tempDate = moment(weekStartDate).add(dayIndex, "day");
          const foundActivities = runActivities.filter(
            (activity) =>
              activity && moment(activity.start_date).isSame(tempDate, "day"),
          );
          if (foundActivities && foundActivities.length > 0) {
            time.push(
              foundActivities
                .map((item) => Math.round(item.elapsed_time / 60) || 0)
                .reduce((a, sum) => a + sum),
            );
            distance.push(
              foundActivities
                .map((item) => Math.round(item.distance / 1000) || 0)
                .reduce((a, sum) => a + sum),
            );
            elevation.push(
              foundActivities
                .map((item) => Math.round(item.total_elevation_gain) || 0)
                .reduce((a, sum) => a + sum),
            );
          } else {
            time.push(0);
            distance.push(0);
            elevation.push(0);
          }
        } else {
          time.push(0);
          distance.push(0);
          elevation.push(0);
        }

        if (crossTrainingActivities) {
          const tempDate = moment(weekStartDate).add(dayIndex, "day");
          const foundActivities = crossTrainingActivities.filter(
            (activity) =>
              activity && moment(activity.start_date).isSame(tempDate, "day"),
          );
          if (foundActivities && foundActivities.length > 0) {
            timeCross.push(
              foundActivities
                .map((item) => Math.round(item.elapsed_time / 60) || 0)
                .reduce((a, sum) => a + sum),
            );
            time.push(
              foundActivities
                .map((item) => Math.round(item.elapsed_time / 60) || 0)
                .reduce((a, sum) => a + sum),
            );
          } else {
            timeCross.push(0);
          }
        } else {
          timeCross.push(0);
        }
      }

      reportLabels = Weekdays.itemsArray.map((item, i) => [
        isInMobileView
          ? ""
          : t(item.text)
              .substring(0, 3)
              .toUpperCase(),
        `${dates[i].format("D")} ${momentLocale
          .localeData()
          .months(dates[i])
          .substring(0, 3)}`,
      ]);
    }

    if (tab === 1) {
      if (runActivities) {
        const filteredActivities = runActivities.filter((item) => {
          if (
            moment(item.start_date).isBetween(
              monthStartDate,
              monthEndDate,
              "[]",
            )
          )
            return item;
        });

        const filteredCrossTraining = activities.filter(
          (a) =>
            ((a.type && a.type.toLowerCase() !== "run") ||
              (a.activityType && !a.activityType.includes("RUN"))) &&
            moment(a.start_date).isBetween(monthStartDate, monthEndDate, "[]"),
        );

        filteredActivities.forEach((activity) => {
          distance.push(Math.round(activity.distance / 1000));
          elevation.push(Math.round(activity.total_elevation_gain));
          time.push(Math.round(activity.elapsed_time / 60));
        });

        filteredCrossTraining.forEach((activity) => {
          timeCross.push(activity.elapsed_time / 60);
          time.push(Math.round(activity.elapsed_time / 60));
        });
      }
    }

    if (tab === 2) {
      const startingDateMoment = startingDate;

      startingDateMoment
        ? moment(startingDate, dateFormat)
        : moment().startOf("month");

      const diffWeeks = moment().diff(moment().startOf("month"), "week") + 1;
      reportLabels = [];
      for (
        let weekIndex = 0;
        weekIndex < (durationInWeeks ? durationInWeeks : diffWeeks);
        weekIndex++
      ) {
        time.push(0);
        distance.push(0);
        elevation.push(0);
        timeCross.push(0);
        reportLabels.push(
          t("WeekMobile", { week: weekIndex + 1, ns: "dashboard" }),
        );
      }
      runActivities.forEach((activity) => {
        if (
          activity &&
          (currentTraining ||
            moment(activity.start_date).isSameOrAfter(startingDateMoment))
        ) {
          const plannedDay =
            moment(activity.start_date).diff(startingDateMoment, "days") + 1;
          const plannedWeek = Math.floor((plannedDay > 0 ? plannedDay : 0) / 7);
          if (plannedWeek < (durationInWeeks ? durationInWeeks : diffWeeks)) {
            time[plannedWeek] += Math.round(activity.elapsed_time / 60) || 0;
            distance[plannedWeek] += Math.round(activity.distance / 1000) || 0;
            elevation[plannedWeek] +=
              Math.round(activity.total_elevation_gain) || 0;
          }
        }
      });
      crossTrainingActivities.forEach((activity) => {
        if (
          activity &&
          (currentTraining ||
            moment(activity.start_date).isAfter(startingDateMoment))
        ) {
          const plannedDay =
            moment(activity.start_date).diff(startingDateMoment, "days") + 1;
          const plannedWeek = Math.floor((plannedDay > 0 ? plannedDay : 0) / 7);
          timeCross[plannedWeek] += Math.round(activity.elapsed_time / 60) || 0;
          time[plannedWeek] += Math.round(activity.elapsed_time / 60) || 0;
        }
      });
    }

    const planedTimeMap = {
      "0": plannedWeek,
      "1": plannedMonth,
      "2": plannedTotal,
    };

    const plannedTime = planedTimeMap[tab];

    const activitiesTime =
      time.length > 0 ? time.reduce((a, sum) => a + sum) : 0;
    const activitiesDistance =
      distance.length > 0 ? distance.reduce((a, sum) => a + sum) : 0;
    const activitiesElevation =
      elevation.length > 0 ? elevation.reduce((a, sum) => a + sum) : 0;
    const activitiesTimeCross =
      timeCross.length > 0 ? timeCross.reduce((a, sum) => a + sum) : 0;

    const BorderLinearProgress = withStyles((theme) => ({
      root: {
        height: 16,
        borderRadius: 2,
      },
      colorPrimary: {
        backgroundColor:
          theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
      },
      bar: {
        borderRadius: 2,
        backgroundColor: "#578FCC",
      },
    }))(LinearProgress);

    const handlePrev = () => {
      const { currentTraining } = this.props;
      const { days } = currentTraining || {};

      let lowerDate = moment().startOf("isoWeek");

      if (days) {
        const daysWithWorkouts = days.filter((d) => !!d);
        if (
          daysWithWorkouts &&
          daysWithWorkouts.length &&
          daysWithWorkouts[0] &&
          daysWithWorkouts[0][0]
        ) {
          lowerDate = moment(
            daysWithWorkouts[0][0].plannedDate,
            dateFormat,
          ).startOf("isoWeek");
        }
      }

      const { currentDate } = this.state;
      const newvalue = moment(currentDate).subtract(7, "days");
      if (lowerDate.isSameOrBefore(newvalue, "day")) {
        this.setState({ ...this.state, currentDate: newvalue });
      }
    };

    const handleNext = () => {
      const { currentTraining } = this.props;
      const { days } = currentTraining || {};

      let upperDate = moment().startOf("isoWeek");

      if (days) {
        const daysWithWorkouts = days.filter((d) => !!d);
        if (
          daysWithWorkouts &&
          daysWithWorkouts.length &&
          daysWithWorkouts[daysWithWorkouts.length - 1] &&
          daysWithWorkouts[daysWithWorkouts.length - 1][0]
        ) {
          upperDate = moment(
            daysWithWorkouts[daysWithWorkouts.length - 1][0].plannedDate,
            dateFormat,
          ).endOf("isoWeek");
        }
      }

      const { currentDate } = this.state;
      const newvalue = moment(currentDate).add(7, "days");
      if (upperDate.isSameOrAfter(newvalue, "day")) {
        this.setState({ ...this.state, currentDate: newvalue });
      }
    };

    return (
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={className}>
        <div>
          <div className={classes.overview}>
            <div className={classes.container}>
              <div className={classes.titleCont}>
                <Typography variant="body1" className={classes.title}>
                  {t("Running load", { ns: "dashboard" })}
                </Typography>

                {noTracker ? (
                  <Typography
                    variant="body1"
                    className={classes.syncTracker}
                    onClick={() => this.openSettings()}
                  >
                    {t("Sync your activity tracker", {
                      ns: "dashboard",
                    })}
                  </Typography>
                ) : (
                  ""
                )}

                {noTracker ? (
                  <div className={classes.addButtonContainer}>
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-add-nr.svg`}
                      alt=""
                      onClick={() => this.openSettings()}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className={classes.topBar}>
                <Tabs
                  value={tab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(ev, index) =>
                    this.setState({
                      tab: index,
                    })
                  }
                  variant="fullWidth"
                >
                  <Tab label={t("Daily", { ns: "dashboard" })} index={0} />
                  <Tab label={t("Weekly", { ns: "dashboard" })} index={1} />

                  <Tab label={t("Full plan", { ns: "dashboard" })} index={2} />
                </Tabs>
              </div>

              {tab !== 2 && (
                <div className={classes.moveParent}>
                  <IconButton onClick={handlePrev}>
                    <ArrowBackIosIcon style={{ width: 18 }} />
                  </IconButton>
                  {tab == 0 ? (
                    <Typography style={{ color: "#B6B6B6" }}>
                      {moment(currentDate)
                        .startOf("isoWeek")
                        .format("MMM")}{" "}
                      {moment(currentDate)
                        .startOf("isoWeek")
                        .date()}{" "}
                      -{" "}
                      {moment(currentDate)
                        .endOf("isoWeek")
                        .format("MMM")}{" "}
                      {moment(currentDate)
                        .endOf("isoWeek")
                        .date()}
                    </Typography>
                  ) : (
                    <Typography style={{ color: "#B6B6B6" }}>
                      {monthStartDate.format("MMM")} {monthStartDate.date()} -{" "}
                      {monthEndDate.format("MMM")} {monthEndDate.date()}{" "}
                    </Typography>
                  )}

                  <IconButton onClick={handleNext}>
                    <ArrowForwardIos style={{ width: 18 }} />
                  </IconButton>
                </div>
              )}

              <div className={classes.chartsCard}>
                <BorderLinearProgress
                  variant="determinate"
                  value={calculateProgressBar(activitiesTime, plannedTime)}
                />

                <div className={classes.timesParent}>
                  <Typography variant="body2" className={classes.statSubValue}>
                    {t("Achieved", { ns: "dashboard" })}:{" "}
                    {EstimatedTimes.convertMinutesToLabel(
                      activitiesTime,
                      true,
                    ) || "0min"}
                  </Typography>
                  <Typography variant="body2" className={classes.statSubValue}>
                    {t("Planned", { ns: "dashboard" })}:{" "}
                    {EstimatedTimes.convertMinutesToLabel(plannedTime, true) ||
                      "0min"}
                  </Typography>
                </div>

                <div className={classes.statsParent}>
                  <div>
                    <div style={{ display: "flex" }}>
                      <img
                        src={`${process.env.PUBLIC_URL}/icon-distance.svg`}
                        width={18}
                      />
                      <Typography className={classes.statTitle}>
                        {t("Distance", { ns: "dashboard" })}
                      </Typography>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <Typography className={classes.statSubValue}>
                        {!units || units === "kms"
                          ? `${activitiesDistance} km`
                          : `${Math.round(
                              activitiesDistance * 0.621371,
                            )} ${t("miles", { ns: "dashboard" })}`}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <img
                        src={`${process.env.PUBLIC_URL}/elevation-icon.svg`}
                        width={20}
                      />
                      <Typography className={classes.statTitle}>
                        {t("Elevation", { ns: "dashboard" })}
                      </Typography>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <Typography className={classes.statSubValue}>
                        {!units || units === "kms"
                          ? `${activitiesElevation} m`
                          : `${Math.round(
                              activitiesElevation * 3.28084,
                            )} ${t("feet", { ns: "dashboard" })}`}
                      </Typography>
                    </div>
                  </div>

                  <div>
                    <div style={{ display: "flex" }}>
                      <img
                        src={`${process.env.PUBLIC_URL}/icon_activity_bike.svg`}
                        width={20}
                      />
                      <Typography className={classes.statTitle}>
                        {t("C. Training", { ns: "dashboard" })}
                      </Typography>
                    </div>

                    <div style={{ alignText: "center" }}>
                      <Typography className={classes.statSubValue}>
                        {EstimatedTimes.convertMinutesToLabel(
                          activitiesTimeCross,
                          true,
                        ) || "0min"}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              {currentTraining ? (
                <div className={classes.workoutsGraphContainer}>
                  <Typography className={classes.workoutsGraphTitle}>
                    {tab === 0
                      ? t("Daily workouts", { ns: "dashboard" })
                      : tab === 1
                      ? t("Weekly workouts", { ns: "dashboard" })
                      : t("Full plan workouts", { ns: "dashboard" })}
                  </Typography>
                  <div className={classes.graphContainer}>
                    <Bar data={data} options={options} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Grid>
    );
  }
}

DashboardWeeklyReportDiagramMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  currentTraining: PropTypes.object,
  distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  elevation: PropTypes.number,
  t: PropTypes.func.isRequired,
  activities: PropTypes.array,
};

export default connect((store) => {
  return {
    athletes: store.athletes,
  };
})(
  withTranslation(["constants", "dashboard"])(
    withWidth()(
      withStyles(styles, { withTheme: true })(
        memo(DashboardWeeklyReportDiagramMobile),
      ),
    ),
  ),
);
