import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Slide,
  Stack,
  Typography,
  CardMedia,
  FormControl,
} from "@mui/material";
import ToolbarReturnBack from "./ToolbarReturnBack";
import CommunityTagsDropdown from "./CommunityTagsDropdown";
import useCreatePost from "../../../hooks/useCreatePost";
import SmallAvatar from "../../generals/small-avatar";
import useUserInfo from "../../../hooks/useUserInfo";
import CommunityChangePhotoButton from "./CommunityChangePhotoButton";
import { RaceCard } from "../../profile/Races/race-card";
import { useTranslation } from "react-i18next";
import { makeStyles, OutlinedInput } from "@material-ui/core";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CommunityPostPreviewDialog = ({
  handleOpenDialog,
  handleCloseDialog,
}) => {
  const { text, selectedImage, goal, handleCreatePost } = useCreatePost();
  const [postText, setPostText] = useState("");
  const { handleTextChange } = useCreatePost();
  const classes = useStyles();

  const { name } = useUserInfo();
  const { t } = useTranslation("community");

  const cardActions = {
    cardClick: false,
    share: false,
    menu: false,
    edit: false,
    delete: false,
    link: false,
  };

  const handlePostClicked = () => {
    if (text || selectedImage || goal) {
      handleCreatePost();
      handleCloseDialog(true);
    }
  };

  return (
    <Dialog
      fullScreen
      open={handleOpenDialog}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
      display="flex"
      flexDirection="column"
    >
      <Box
        sx={{
          flex: "0 0 auto",
        }}
      >
        <ToolbarReturnBack
          goBack={() => handleCloseDialog(false)}
          title={t("community.preview.title")}
        />
      </Box>
      <Box
        p={2}
        sx={{
          flex: "1 1 auto",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          backgroundColor: "#FAFAFA",
        }}
      >
        <Stack
          id="comment_avatar"
          data-testid="comment_avatar"
          direction="row"
          spacing={2}
          mt={2}
          mb={2}
        >
          <SmallAvatar level={false} customSize="32px" onTrailHead={true} />
          <Typography
            component={"span"}
            fontSize={"18px"}
            fontWeight={700}
            fontFamily={"karla"}
            color={"#12151B"}
          >
            {name}
          </Typography>
        </Stack>

        <FormControl fullWidth hiddenLabel data-testid="new_post" id="new_post">
          <OutlinedInput
            data-testid="post_text"
            id="post_text"
            multiline
            first
            onBlur={() => handleTextChange(postText)}
            value={postText}
            onChange={(e) => {
              setPostText(e.target.value);
              if (e.target.value.length === 1) {
                handleTextChange(e.target.value);
              }
              if (e.target.value.length === 0) {
                handleTextChange(e.target.value);
              }
            }}
            placeholder={t("community.newPost.placeholder")}
            color="secondary"
            aria-label="naked"
            className={classes.inputForm}
          />
        </FormControl>
        <CommunityTagsDropdown />
        {selectedImage && (
          <Box sx={{ position: "relative" }}>
            <CardMedia
              component="img"
              image={selectedImage}
              sx={{
                mt: 2,
                mb: 2,
                borderRadius: "4px",
                objectFit: "cover",
                width: "100%",
              }}
            />
            <CommunityChangePhotoButton />
          </Box>
        )}
        {goal && (
          <Box p={2}>
            <RaceCard race={goal} enableActions={cardActions} />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          flex: "0 0 auto",
          p: 2,
        }}
      >
        <Button
          data-testid="post_button"
          id="post_button"
          onClick={handlePostClicked}
          sx={{
            bgcolor: "#0B2B5C",
            color: "#fff",
            height: "47px",
            width: "100%",
            textTransform: "Initial",
          }}
          variant="contained"
        >
          {t("community.preview.postButton")}
        </Button>
      </Box>
    </Dialog>
  );
};

export default CommunityPostPreviewDialog;

const useStyles = makeStyles({
  inputForm: {
    height: "48px",
    marginBottom: "16px",
    backgroundColor: "#fff",
    borderWidth: "2px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EAEAEA",
      borderWidth: "2px",
    },
  },
});
