import AnalyticsService from "../services/analytic-service";
import ActionTypes from "../constants/action-types";

const pushAnalyticData = (data) => {
  return async (dispatch) => {
    await dispatch({ type: ActionTypes.PUSH_ANALYTIC_DATA_PENDING });
    try {
      await AnalyticsService.addAnalyticsData(data);
      dispatch({
        type: ActionTypes.PUSH_ANALYTIC_DATA_FULFILLED,
      });
    } catch (exp) {
      await dispatch({ type: ActionTypes.PUSH_ANALYTIC_DATA_REJECTED });
    }
  };
};

export { pushAnalyticData };
