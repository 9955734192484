import React from "react";
import { palette } from "../../theme/palette";

const AppleIcon = ({ width = 24, height = 24, activated = false }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill={activated ? palette.black : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7729 0.0120105C14.7219 -0.0449895 12.8844 0.0345106 11.2854 1.77001C9.68639 3.50401 9.93239 5.49301 9.96839 5.54401C10.0044 5.59501 12.2484 5.67451 13.6809 3.65701C15.1134 1.63951 14.8239 0.0705105 14.7729 0.0120105ZM19.7439 17.6115C19.6719 17.4675 16.2564 15.7605 16.5744 12.4785C16.8924 9.19501 19.0869 8.29501 19.1214 8.19751C19.1559 8.10001 18.2259 7.01251 17.2404 6.46201C16.5168 6.07389 15.716 5.85152 14.8959 5.81101C14.7339 5.80651 14.1714 5.66851 13.0149 5.98501C12.2529 6.19351 10.5354 6.86851 10.0629 6.89551C9.58889 6.92251 8.17889 6.11251 6.66239 5.89801C5.69189 5.71051 4.66289 6.09451 3.92639 6.39001C3.19139 6.68401 1.79339 7.52101 0.815389 9.74551C-0.162611 11.9685 0.348889 15.4905 0.714889 16.5855C1.08089 17.679 1.65239 19.4715 2.62439 20.7795C3.48839 22.2555 4.63439 23.28 5.11289 23.628C5.59139 23.976 6.94139 24.207 7.87739 23.7285C8.63039 23.2665 9.98939 23.001 10.5264 23.0205C11.0619 23.04 12.1179 23.2515 13.1994 23.829C14.0559 24.1245 14.8659 24.0015 15.6774 23.6715C16.4889 23.34 17.6634 22.083 19.0344 19.5345C19.5549 18.3495 19.7919 17.709 19.7439 17.6115Z"
      fill={activated ? palette.black : palette.white}
    />
  </svg>
);
export default AppleIcon;
