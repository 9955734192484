import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { Dialog, DialogContent, Typography } from "@material-ui/core";
import Card from "../components/card";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import history from "../../../../lib/history-helper";
import { useSelector, useDispatch } from "react-redux";
import { setFormTrackerStatus } from "../../../../actions/workout-action";

const styles = (theme) => ({
  dialog: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)",
    paddingTop: "env(safe-area-inset-top)",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  container: {
    padding: "0px 16px !important",
    background: "#FFF",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      minWidth: "initial",
      background: "#FFF",
    },
  },
  dialogPaper: {
    borderRadius: 0,

    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFF",
    padding: "16px",
    boxSizing: "border-box",
  },
  text: {
    textAlign: "center",
    "& b": {
      color: "#FF4400",
    },
  },
});

const NoWorkouts = ({ classes, t }) => {
  const dispatch = useDispatch();
  const { fromTracker } = useSelector((state) => state.workout);

  const goBack = () => {
    if (fromTracker.fromTracker && fromTracker.id && fromTracker.date) {
      return history.push(
        `/tracking/tracker/${fromTracker.id}/${fromTracker.date}`,
      );
    }

    if (fromTracker.fromTracker && !fromTracker.id && !fromTracker.date) {
      return history.push(`/tracking/tracker`);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setFormTrackerStatus({}));
    };
  }, []);

  return (
    <>
      <Dialog
        fullScreen={true}
        open={true}
        onClose={null}
        aria-labelledby="Workout"
        scroll="body"
        maxWidth="md"
        className={classes.dialog}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogContent className={classes.container}>
          <div className={classes.topContainer}>
            <ArrowBackIosIcon
              onClick={goBack}
              className={classes.backIcon}
              fontSize="small"
            />
          </div>
          <Card>
            <Typography
              variant="body2"
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: t("No Workouts Text"),
              }}
            />
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslation("tracking")(NoWorkouts),
);
