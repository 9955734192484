import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid, Typography, CircularProgress, Button } from "@material-ui/core";
import moment from "moment";
import withWidth from "@material-ui/core/withWidth";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import "moment/min/locales";
import { dateFormat } from "../../lib/date-helper";
import DashboardTrainingOneWeekday from "./dashboard-training/dashboard-training-one-weekday";
import RouterLink from "../common/router-link";
import { getPlanLevel } from "../../lib/plan-helper";
import { currentTrainingCurrentStartDateSet } from "../../actions/user-plans-action";
import { connect } from "react-redux";
import CustomErrorBoundary from "../common/error/CustomErrorBoundary";

const styles = (theme) => ({
  container: {
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
      marginTop: theme.spacing.unit,
    },
  },
  workoutContainer: {
    position: "relative",
    /*background: "#FCFCFC",
    boxShadow: "0 0 12px rgba(33, 81, 209, 0.1)",
    borderRadius: 18,
    margin: "0 15px 20px 0",
    padding: "30px 15px",
    
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
      margin: 5,
      padding: 5,
      background: "#EFF3F5",
    },*/
  },
  emptyContainer: {
    margin: 20,
    display: "flex",
    background: "#FCFCFC",
    boxShadow: "0 0 12px rgba(33, 81, 209, 0.1)",
    borderRadius: 18,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginBottom: 22,
      justifyContent: "center",
    },
  },
  topBarTitle: {
    fontWeight: 500,
    fontSize: 12,
    color: "#202020",
    marginRight: 16,
  },

  loading: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1,
    background: "#fff",
    opacity: 0.5,
    display: "flex",
  },
  progress: {
    margin: "auto",
  },
  activate: {
    background: "#FFD8C5",
    borderRadius: 8,
    padding: 15,
    margin: 40,
  },
  activateText: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#FF6327",
  },
  weeksText: {
    color: "#212121",
    fontSize: 18,
    fontFamily: "Avenir",
    fontWeight: "normal",
    margin: "auto",
  },
  emptyContainerText: {
    fontSize: 18,
    margin: 20,
    fontWeight: "normal",
  },

  buttonArrow: {
    fontWeight: "normal",
    margin: "0 10px",
  },

  minWZero: {
    minWidth: "initial",
  },
});

class DashboardTrainingDaily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentStartDate: moment(),
    };
  }

  handleCurrentWeek = () => {
    const weekNumber = moment().week();
    const date = moment()
      .clone()
      .week(weekNumber)
      .day("Monday")
      .format("YYYY-MM-DD hh:mm:ss");

    this.props.dispatch(currentTrainingCurrentStartDateSet(date));
  };

  componentDidMount() {
    const { onDaysChangeMobile } = this.props;
    this.handleCurrentWeek();
    setTimeout(onDaysChangeMobile(this.state.currentStartDate), 100);
  }

  componentDidUpdate(prevProps) {
    const { updating } = this.props;
    if (updating) {
      if (!this.state.loading) {
        this.setState({
          ...this.state,
          loading: true,
        });
      }
    } else {
      if (this.state.loading) {
        setTimeout(() => {
          this.setState({
            ...this.state,
            loading: false,
          });
        }, 1000);
      }
    }
  }

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  getNextButtonStatus = () => {};

  getPrevButtonStatus = () => {};

  onClickNext = (nextDuration) => {
    const { currentTraining, onDaysChangeMobile } = this.props;

    const { days } = currentTraining || {};

    const notNullDays = days.filter((d) => !!d);

    if (
      this.state.currentStartDate.diff(moment(), "day") < nextDuration &&
      notNullDays &&
      notNullDays.length &&
      notNullDays[notNullDays.length - 1][0] &&
      moment(
        notNullDays[notNullDays.length - 1][0].plannedDate,
        dateFormat,
      ).isAfter(this.state.currentStartDate, "day")
    ) {
      this.setState({
        currentStartDate: this.state.currentStartDate.add(1, "day"),
      });
      setTimeout(onDaysChangeMobile(this.state.currentStartDate), 100);
    }
  };

  onClickPrev = () => {
    const { currentTraining, onDaysChangeMobile } = this.props;

    const { days } = currentTraining || {};

    const notNullDays = days.filter((d) => !!d);

    if (
      notNullDays &&
      notNullDays.length &&
      notNullDays[0][0] &&
      moment(notNullDays[0][0].plannedDate, dateFormat).isBefore(
        this.state.currentStartDate,
        "day",
      )
    ) {
      this.setState({
        currentStartDate: this.state.currentStartDate.subtract(1, "day"),
      });
      setTimeout(onDaysChangeMobile(this.state.currentStartDate), 100);
    }
  };

  render() {
    const {
      classes,
      width,
      xs,
      sm,
      md,
      lg,
      xl,
      className,
      currentTraining,
      updatingWorkoutId,
      selectedPlan,
      t,
      i18n,
      hasSubscription,
      lastVisibleDate,
      loading,
      onError,
    } = this.props;

    const { language } = i18n;
    const momentLocale = moment().locale(language);

    const isInMobileView = width === "md" || width === "sm" || width === "xs";
    const { startingDate, days, durationInWeeks } = currentTraining || {};

    const { currentStartDate = moment() } = this.state;

    const startingDateMoment = moment(startingDate, dateFormat);
    const currentDay =
      moment(currentStartDate)
        .startOf("isoWeek")
        .diff(startingDateMoment.startOf("isoWeek"), "days") + 1;

    const currentWeek = Math.ceil((currentDay > 0 ? currentDay : 0) / 7);

    let nextDuration = durationInWeeks ? parseInt(durationInWeeks) : 1;
    nextDuration = nextDuration * 7;

    const weekday = days
      ? days.find(
          (d) =>
            d &&
            d.length > 0 &&
            moment(d[0].plannedDate, dateFormat).isSame(
              moment(currentStartDate),
              "day",
            ),
        )
      : null;

    const level = getPlanLevel(currentTraining);

    return (
      <CustomErrorBoundary>
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          className={classNames(classes.container, className)}
        >
          {!hasSubscription && selectedPlan !== "free" ? (
            /*<div className={classes.activate}>
            <Typography variant="body1" className={classes.activateText}>
              {t("Activate full text")}
              <RouterLink to="/chooseplan?filter=free">
                <u>{t("Activate full text 1")}</u>
              </RouterLink>
            </Typography>
          </div>*/ ""
          ) : currentTraining &&
            currentTraining.days &&
            currentTraining.days.length ? (
            <Fragment>
              <div className={classes.topBar}>
                <Button
                  className={classes.buttonArrow}
                  classes={{ root: classes.minWZero }}
                  onClick={() => this.onClickPrev()}
                  disabled={this.getPrevButtonStatus()}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/arrow-left.svg`}
                    alt=""
                  />
                </Button>

                <Typography
                  variant={isInMobileView ? "caption" : "body1"}
                  className={classes.weeksText}
                >
                  {moment(currentStartDate).isSame(moment(), "day")
                    ? `${t("Today")}, `
                    : ""}
                  <b>
                    {`${momentLocale
                      .localeData()
                      .months(moment(currentStartDate))
                      .substr(0, 3)} ${moment(currentStartDate).format("D")}`}
                  </b>
                </Typography>

                <Button
                  className={classes.buttonArrow}
                  classes={{ root: classes.minWZero }}
                  onClick={() => this.onClickNext(nextDuration)}
                  disabled={this.getNextButtonStatus()}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/arrow-right.svg`}
                    alt=""
                  />
                </Button>
              </div>

              {currentWeek > durationInWeeks ? (
                <div className={classes.emptyContainer}>
                  <RouterLink to="/chooseplan">
                    <Typography
                      variant="h6"
                      dangerouslySetInnerHTML={{
                        __html: t("You currently dont have a training plan"),
                      }}
                      className={classes.emptyContainerText}
                    ></Typography>
                  </RouterLink>
                </div>
              ) : (
                <div className={classes.workoutContainer}>
                  {this.state.loading ? (
                    <div className={classes.loading}>
                      <CircularProgress
                        variant="indeterminate"
                        color="primary"
                        size={50}
                        className={classes.progress}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {weekday ? (
                    <DashboardTrainingOneWeekday
                      trainingDay={weekday}
                      updatingWorkoutId={updatingWorkoutId}
                      isInMobileView={isInMobileView}
                      lastVisibleDate={lastVisibleDate}
                      hasSubscription={hasSubscription}
                      onError={onError}
                      level={level}
                    />
                  ) : (
                    <div className={classes.emptyContainer}>
                      <Typography
                        variant="body1"
                        className={classes.emptyContainerText}
                      >
                        {t("No workout", { ns: "dashboard" })}
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </Fragment>
          ) : !loading ? (
            <div className={classes.emptyContainer}>
              <Typography variant="h6" className={classes.emptyContainerText}>
                {t("Your coach is working on your training plan")}
              </Typography>
            </div>
          ) : (
            ""
          )}
        </Grid>
      </CustomErrorBoundary>
    );
  }
}

DashboardTrainingDaily.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  className: PropTypes.string,
  currentTraining: PropTypes.object,
  onCurrentDaysChangeRequested: PropTypes.func.isRequired,
  onWorkoutsMoved: PropTypes.func.isRequired,
  onRedoWeek: PropTypes.func.isRequired,
  onAddEasyWeek: PropTypes.func.isRequired,
  onRestartPlan: PropTypes.func,
  onOpenAdd: PropTypes.func.isRequired,
  updatingWorkoutId: PropTypes.string,
  updating: PropTypes.bool,
  selectedPlan: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
  subscriptionEnd: PropTypes.number,
  onError: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
  };
})(
  withTranslation("dashboard")(
    withWidth()(withStyles(styles)(DashboardTrainingDaily)),
  ),
);
