import React, { useEffect } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { palette } from "../../theme/palette";
import TimerOutlined from "@material-ui/icons/TimerOutlined";
import { useSelector, useDispatch } from "react-redux";
import { getExplorePosts } from "../../actions/trail-head-action";
import { useHistory } from "react-router-dom";
import { updatePlan } from "../../actions/user-plans-action";
import { RowListSkeleton } from "./row-list-skeleton";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  card: {
    background: palette.white,
    borderRadius: "8px",
    marginTop: "12px",
    width: "235px",
    "&:not(:last-child)": {
      marginRight: "16px",
    },
  },
  title: {
    fontFamily: "Oswald",
    marginBottom: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: "4px",
    width: "80%",
  },
  content: {
    color: palette.darkGray,
    fontWeight: "400",
  },
  button: {
    fontFamily: "Karla",
    marginTop: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "18px",
    textTransform: "lowercase",
  },
  descriptionContainer: {
    padding: "12px",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  verticalCentered: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  divider: {
    background: palette.lightGray,
    width: "2px",
    height: "40px",
  },
  label: {
    color: palette.darkGray,
    marginTop: "4px",
  },
  labelOnly: {
    color: palette.darkGray,
  },
  margin: {
    marginTop: "12px",
  },
  carousel: {
    display: "-webkit-inline-box",
    width: "100%",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  seeAll: {
    color: palette.lightBlue,
  },
  blur: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: "50%",
    width: 40,
    height: 40,
    padding: "4px",
  },
  blurFavorite: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: "50%",
    width: 24,
    height: 24,
    padding: "4px",
    boxSizing: "border-box",
    position: "absolute",
    top: "8px",
    right: "8px",
  },
  bold: {
    fontWeight: "700",
  },
});

const imageStyles = (url) => ({
  borderRadius: "8px 8px 0 0",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url(${url})`,
  height: "125px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
});

const iconStyle = {
  color: palette.darkGray,
  width: "18px",
  margin: 0,
  padding: 0,
  marginRight: "4px",
};

const PopularVideos = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("home");
  const history = useHistory();
  const dispatch = useDispatch();
  const { explorePosts, loadingExplorePosts } = useSelector(
    (state) => state.trailHead,
  );

  const { currentPlan } = useSelector((state) => state.userPlans);
  const { exploreFavorites } = currentPlan || {};
  const { data: videos } = explorePosts["Workouts [ Video ]"] || {};
  const classes = useStyles();

  useEffect(() => {
    if (!videos || !videos.length)
      dispatch(getExplorePosts(new Date().getTime(), "Workouts [ Video ]"));
  }, [videos]);

  const navigate = () => history.push("/all-videos");

  const navigateVideo = ({ id }) => history.push(`explore/post/${id}`);

  const toggleFavorite = (event, id, type) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const { exploreFavorites, cognito_user_sub } = currentPlan || {};
    if (
      exploreFavorites &&
      exploreFavorites.length &&
      exploreFavorites.find((e) => e.id === id)
    ) {
      const newExploreFavorites = (exploreFavorites || []).filter(
        (f) => f.id !== id,
      );
      dispatch(
        updatePlan(
          {
            cognito_user_sub: cognito_user_sub,
            exploreFavorites: newExploreFavorites,
          },
          false,
          false,
        ),
      );
      return;
    }
    dispatch(
      updatePlan(
        {
          cognito_user_sub: cognito_user_sub,
          exploreFavorites:
            exploreFavorites && exploreFavorites.length
              ? [{ id, type }, ...exploreFavorites]
              : [{ id, type }],
        },
        false,
        false,
      ),
    );
  };

  return (
    <>
      <Box sx={{ marginTop: "40px" }}>
        {!loadingExplorePosts["Workouts [ Video ]"] && (
          <Box className={classes.header}>
            <Typography variant="h3" className={classes.bold}>
              {t("Most popular videos")}
            </Typography>
            <Typography
              variant="body2"
              className={classes.seeAll}
              id="home_see_all_videos"
              onClick={navigate}
            >
              {t("See all")}
            </Typography>
          </Box>
        )}
      </Box>

      {loadingExplorePosts["Workouts [ Video ]"] && (
        <Box sx={{ marginTop: "12px" }}>
          <RowListSkeleton width={210} height={202} />
        </Box>
      )}
      <Box className={classes.carousel}>
        {(videos || []).map((item = {}, index) => {
          const isFavorite = (exploreFavorites || []).find(
            ({ id }) => id === item.id,
          );
          return (
            <Box key={index} className={classes.card}>
              {item && (
                <Box key={item.id} id={`home_video_pos${index + 1}`}>
                  <Box
                    alt="training-cover"
                    sx={imageStyles(item.image_url)}
                    onClick={() => navigateVideo(item)}
                  >
                    <Box className={classes.blur}>
                      <img
                        src={`${process.env.PUBLIC_URL}/icon-play-white.svg`}
                        width="100%"
                        id={`home_favorite_pos${index + 1}`}
                      />
                    </Box>

                    <Box
                      className={classes.blurFavorite}
                      onClick={(event) =>
                        toggleFavorite(event, item.id, item.type)
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/icon-star-outline-${
                          isFavorite ? "filled" : "white"
                        }.svg`}
                        width="100%"
                      />
                    </Box>
                  </Box>
                  <Box></Box>
                  <Box className={classes.descriptionContainer}>
                    <Box display="flex">
                      <TimerOutlined style={iconStyle} />
                      <Typography
                        variant="subtitle2"
                        className={classes.content}
                      >
                        {item.time || item.workout_time}
                      </Typography>
                    </Box>
                    <Typography variant="h4">
                      {item.workout_type && item.workout_type[language]
                        ? item.workout_type[language]
                        : item.workout_type && item.workout_type["en"]}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default PopularVideos;
