import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { palette } from "../../../../theme/palette";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  card: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    maxWidth: 220,
    gap: 6,
    padding: "12px 12px",
    borderRadius: "4px",
    boxShadow: "0px 1px 15px 0px #12151B0D",
    background: palette.white,
    boxSizing: "border-box",
    width: "100%",
    overflowWrap: "anywhere",
  },
  stat: {
    textAlign: "center",
    display: "flex",
    width: "fit-content",
    justifyContent: "center",
    alignSelf: "center",
  },
  title: {
    marginLeft: "8px",
    textWrap: "nowrap",
  },
}));

export const StatCard = ({ color, title, stat, icon }) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <img
          src={`${process.env.PUBLIC_URL}/profile/${icon}-icon.svg`}
          alt="run-icon"
        />
        <Typography variant="body2" className={classes.title}>
          {title}
        </Typography>
      </Box>
      <Typography variant="h5" className={classes.stat} style={{ color }}>
        {stat}
      </Typography>
    </Box>
  );
};
