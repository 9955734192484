import WorkoutPlans from "./workout-plans";

export default {
  Items: WorkoutPlans,
  itemsArray: Object.keys(WorkoutPlans).map((key) => {
    return {
      text: WorkoutPlans[key],
      value: WorkoutPlans[key],
    };
  }),
};
