import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, Typography, Dialog, Button, Tabs, Tab } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import {
  clearSuccessMessage,
  clearErrorMessage,
  setErrorMessage,
  updatePlan,
  updatePlanImage,
  userPlanMailchimpSubscribe,
  setSuccessMessage,
  getUserActivities,
} from "../actions/user-plans-action";
import {
  getChallengeLeaders,
  getChallenges,
  joinChallenge,
  updateJoinedChallenges,
} from "../actions/challenges-action";
import { newPost } from "../actions/trail-head-action";
import SnackBarMessageDisplay from "./common/snack-bar-message-display";
import DashboardUser from "./dashboard/dashboard-user";
import AppBar from "./layout/app-bar";
import Challenge from "./challenges/challenge";
import YourChallenge from "./challenges/your-challenge";
import FinishedChallenge from "./challenges/finished-challenge";
import history from "../lib/history-helper";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing";
import MobileShare from "./common/mobile-share";
import objectToGetParams from "../lib/objectToGetParams";
import { DialogContent } from "@material-ui/core";
import AppAuthenticator from "./app-authenticator";
import { Capacitor } from "@capacitor/core";
import { logEvent } from "../lib/events-helper";
import { Redirect } from "react-router-dom";
import RouterLink from "./common/router-link";
import { convertUnits } from "../lib/units-helper";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Clipboard } from "@capacitor/clipboard";
import withActivities from "../hoc/withActivities";

const styles = (theme) => ({
  progressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FCFCFC",
    [theme.breakpoints.down("md")]: {
      minHeight: "calc(100vh - 50px)",
      backgroundColor: "#EFF3F5",
    },
  },
  loading: {
    margin: "auto",
  },
  weeklyReport: {
    order: 1,
    [theme.breakpoints.down("md")]: {
      order: 2,
    },
  },
  notDisplayedInMobile: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  training: {
    order: 2,
    [theme.breakpoints.down("md")]: {
      order: 1,
    },
  },
  trainingPane: {
    height: "100vh",
    overflow: "scroll",

    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 112px)",
    },
  },

  trainingPaneFinished: {
    background: "#fff",
  },

  topBarMobile: {
    alignItems: "center",
    marginBottom: 20,
    justifyContent: "center",
    position: "sticky",
    top: 0,
    zIndex: 100,
    background: "#fff",
    [theme.breakpoints.up("md")]: {
      marginTop: 10,
      background: "initial",
      position: "initial",
      display: "flex",
    },
  },
  filter: {
    color: "#000000",
    cursor: "pointer",
    padding: "3px 8px",
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10,
    background: "#E7EAEE",
    borderRadius: 3,
    width: "33%",
    textAlign: "center",
  },
  filterSelected: {
    color: "#FFFFFF",
    cursor: "pointer",
    padding: "3px 8px",
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10,
    background: "#2E3A59",
    borderRadius: 3,
    width: "33%",
    textAlign: "center",
  },
  filterDesktop: {
    fontSize: 12,
    background: "#E7F3FC",
    borderRadius: 24,
    padding: "6px 8px",
    marginLeft: 10,
    marginRight: 10,
    color: "#0F4975",
    cursor: "pointer",
  },
  filterSelectedDesktop: {
    fontSize: 12,
    background: "#1A629B",
    borderRadius: 24,
    padding: "6px 8px",
    marginLeft: 10,
    marginRight: 10,
    color: "#FFFFFF",
    cursor: "pointer",
  },
  commentIcon: {
    right: 10,
    bottom: 10,
    padding: 10,
    position: "fixed",
  },

  challengesTitleContainer: {
    display: "flex",
    alignItems: "center",
    margin: 10,
  },

  challengesTitle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 20,

    color: "#2E3A59",
  },

  challengesTitleNoAuth: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 20,
    color: "#2E3A59",
    textAlign: "center",
  },

  yourBadgesContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },

  yourBadges: {
    fontFamily: "Montserrat",
    fontSize: 12,
    color: "#2E3A59",
  },

  logoImgContainer: {
    textAlign: "center",
    width: "calc(100% - 20px)",
    margin: 10,
    cursor: "pointer",
  },

  logoImg: {
    width: 150,
  },

  noAuthText: {
    background: "#FFFFFF",
    borderRadius: 8,
    padding: 10,
    marginTop: 15,
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 14,
    textAlign: "center",
    color: "#2E3A59",
  },

  noActiveContainer: {
    background: "#FFFFFF",
    borderRadius: 8,
    padding: 10,
    margin: 30,
  },

  noFinishedContainer: {
    background: "#FFFFFF",
    borderRadius: 8,
    padding: 10,
    margin: "15px 30px",
  },

  noActive: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    color: "#7B8BB2",
  },

  noAuthSubTitle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 18,
    color: "#0D253C",
  },

  button: {
    borderRadius: 5,
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "20px",
    textAlign: "center",
    color: "#FFFFFF",
    margin: "auto",
    marginTop: 8,
  },

  rightButtons: {
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    marginLeft: "auto",
  },

  stravaText: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 16,
    textAlign: "center",
    color: "#7B8BB2",
  },

  stravaTitle: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 18,
    textAlign: "center",
    color: "#FF6327",
  },

  stravaDialog: {
    textAlign: "center",
  },

  badgesTitle: {
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: 18,
    marginLeft: 12,
  },

  badgesContainer: {
    display: "flex",
    alignItems: "flex-start",
  },

  badgesOpener: {
    cursor: "pointer",
    margin: "30px 10px 0 0px",
  },

  totalsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    position: "fixed",
    bottom: 0,
    width: "100%",
    zIndex: 10000,
  },

  totalsTextSelected: {
    fontFamily: "Avenir",
    fontWeight: 800,
    fontSize: 14,
    textAlign: "center",
    color: "#202020",
    margin: "0 2px",

    [theme.breakpoints.up("md")]: {
      fontSize: 11,
    },
  },

  totalsText: {
    fontFamily: "Avenir",
    fontSize: 14,
    textAlign: "center",
    color: "#202020",
    margin: "0 2px",

    [theme.breakpoints.up("md")]: {
      fontSize: 11,
    },
  },

  dialogPaper: {
    borderRadius: 10,
  },

  searchBar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    background: "#fff",
    padding: "15px 10px",
  },

  title: {
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      fontSize: 18,
      marginRight: "auto",
      marginLeft: 25,
      marginTop: 2,
    },
  },

  favs: {
    marginLeft: "auto",
    marginRight: 15,
  },
});

class Challenges extends Component {
  state = {
    tab: 2,
    openChallenge: null,
    openChallengeUpcoming: null,
    openShare: false,
    units: "kms",
    openSignUp: false,
    openStrava: false,
    openBadges: false,
    favorites: false,
  };

  componentDidMount() {
    const { userPlans, auth, activities } = this.props;
    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub, email } = attributes || {};

    if (sub && email) {
      this.props.dispatch(
        userPlanMailchimpSubscribe(
          {
            cognito_user_sub: sub,
            cognito_user_email: email,
          },
          false,
        ),
      );
    }

    this.props.dispatch(getChallenges(sub ? sub : ""));
    if (userPlans.loadingPlan === false) {
      this.getActivities();
    }

    if (activities.length > 0) {
      this.refreshJoinedChallenges();
    }

    if (typeof window !== "undefined") {
      this.setState({
        ...this.state,
        units: localStorage.getItem("units"),
      });

      window.addEventListener("storage", () => this.localStorageUpdated());
    }
  }

  localStorageUpdated() {
    this.setState({
      ...this.state,
      units: localStorage.getItem("units"),
    });
  }

  componentDidUpdate(prevProps) {
    const { userPlans, challenges, activities } = this.props;

    if (!prevProps.userPlans.currentPlan && userPlans.currentPlan) {
      this.getActivities();
    }

    if (
      prevProps.challenges.loadingChallenges &&
      !challenges.loadingChallenges
    ) {
      this.setStartTab();
      this.refreshJoinedChallenges();
    }

    if (
      activities.length > 0 &&
      (!prevProps.activities || prevProps.activities.length === 0)
    ) {
      if (!challenges.loadingChallenges) {
        this.refreshJoinedChallenges();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.garminTimeout);

    this.setState({
      ...this.state,
      garminTimeout: null,
    });
  }

  setStartTab = () => {
    const { userPlans, challenges, width } = this.props;
    const { currentPlan } = userPlans;
    const { cognito_user_sub } = currentPlan || "";
    const isInMobileView = width === "md" || width === "sm" || width === "xs";

    if (
      !challenges.loadingChallenges &&
      !challenges.loadingChallenges &&
      this.state.tab !== 1
    ) {
      let yourChallenges = challenges.currentChallenges
        ? challenges.currentChallenges.filter(
            (c) => c.participants && c.participants.includes(cognito_user_sub),
          )
        : [];

      const yourDoneChallenges = challenges.yourChallengeEntries
        ? challenges.yourChallengeEntries.filter(
            (e) =>
              e.finished && e.challengeData.end_date < new Date().getTime(),
          )
        : [];

      if (
        (!yourDoneChallenges || yourDoneChallenges.length === 0) &&
        (!yourChallenges || yourChallenges.length === 0)
      ) {
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              tab: 2,
            }),
          100,
        );
      } else if (
        !yourDoneChallenges ||
        yourDoneChallenges.length === 0 ||
        !isInMobileView
      ) {
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              tab: 1,
            }),
          100,
        );
      } else {
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              tab: 0,
            }),
          100,
        );
      }
    }
  };

  getDisplayErrorMessage = (err) => {
    let errorMessage = "";
    const { t } = this.props;

    if (typeof err === typeof string) {
      errorMessage = err;
    } else errorMessage = err.message || err.inputMessage || err.toString();

    switch (errorMessage) {
      case "Invalid Size":
        return t("Sorry, your image needs to be smaller than maxSize");
      case "Invalid File":
        return t("Invalid file, please try again");
      default:
        return errorMessage;
    }
  };

  handleError = (err) => {
    this.props.dispatch(setErrorMessage(this.getDisplayErrorMessage(err))); //GOES TO SNACK BAR
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleFieldChanges = (changes, showMessage) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      updatePlan(
        { cognito_user_sub: currentPlan.cognito_user_sub, ...changes },
        showMessage,
      ),
    );
    if (changes.currentLocation && changes.currentGeoLocation) {
      this.props.dispatch(
        userPlanMailchimpSubscribe(
          {
            cognito_user_sub: currentPlan.cognito_user_sub,
            cognito_user_email: currentPlan.cognito_user_email,
            ...changes,
          },
          showMessage,
        ),
      );
    }
  };

  handleImageFieldChange = (value, extension) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    this.props.dispatch(
      updatePlanImage({
        cognito_user_sub: currentPlan.cognito_user_sub,
        image: value,
        imageExtension: extension && extension.toLowerCase(),
      }),
    );
  };

  handleJoinChallenge = (challenge) => {
    const { userPlans, auth, challenges, activities } = this.props;
    const { currentPlan } = userPlans;
    const { isAuthenticated, currentUser } = auth;
    const { lastLogin } = currentPlan || {};

    const { attributes } = currentUser || {};
    const { sub, email } = attributes || {};

    if (isAuthenticated) {
      this.props.dispatch(
        joinChallenge(sub, challenge, lastLogin, activities ? activities : []),
      );

      this.props.dispatch(getChallengeLeaders(challenge.id, sub));

      this.setState({
        ...this.state,
        tab: 1,
        openChallenge: challenge,
        openChallengeUpcoming: null,
      });

      if (
        !currentPlan ||
        (!currentPlan.strava_athlete_id &&
          !currentPlan.garmin_oauth_token &&
          !currentPlan.suunto_auth &&
          !currentPlan.coros_auth)
      ) {
        this.setState({
          ...this.state,
          openStrava: true,
          tab: 1,
          openChallenge: challenge,
          openChallengeUpcoming: null,
        });
      }

      let yourChallenges = challenges.currentChallenges
        ? challenges.currentChallenges.filter(
            (c) => c.participants && c.participants.includes(sub),
          )
        : [];

      this.props.dispatch(
        userPlanMailchimpSubscribe(
          {
            cognito_user_sub: sub,
            cognito_user_email: email,
            currentChallenges: [...yourChallenges, challenge]
              .map((c) => (c && c.id ? c.id : null))
              .filter((cs) => cs)
              .join(","),
          },
          false,
        ),
      );
    } else {
      this.handleOpenSignUp();
    }
  };

  handleOpenChallenge = (challenge) => {
    const { auth } = this.props;

    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes || {};

    this.props.dispatch(getChallengeLeaders(challenge.id, sub));

    this.setState({
      ...this.state,
      openChallenge: challenge,
    });
  };

  handleCloseChallenge = () => {
    this.setState({
      ...this.state,
      openChallenge: null,
      openChallengeUpcoming: null,
    });
  };

  handleOpenChallengeUpcoming = (challenge) => {
    const { auth } = this.props;

    const { currentUser } = auth;
    const { attributes } = currentUser || {};
    const { sub } = attributes || {};

    this.props.dispatch(getChallengeLeaders(challenge.id, sub));

    this.setState({
      ...this.state,
      openChallengeUpcoming: challenge,
    });
  };

  handleCloseChallengeUpcoming = () => {
    this.setState({
      ...this.state,
      openChallengeUpcoming: null,
    });
  };

  getActivities = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    this.props.dispatch(getUserActivities(currentPlan));
  };

  handleActivateTrailhead = () => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;

    if (currentPlan) {
      this.props.dispatch(
        updatePlan(
          { cognito_user_sub: currentPlan.cognito_user_sub, trailhead: true },
          false,
          false,
        ),
      );
    }
  };

  isMobileOrTablet = () => {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
  };

  handleShareProgress = (challenge) => {
    this.setState({
      ...this.state,
      openShare: true,
      challengeToShare: challenge,
      shareType: "progress",
    });
  };

  handleShareProgressS1 = async (type) => {
    const { i18n, userPlans, t, trailHead } = this.props;
    const { language } = i18n;

    const { currentPublicGroup } = trailHead;

    const { challengeToShare: challenge } = this.state;
    const { yourEntry } = challenge;

    const { currentPlan } = userPlans;
    const { cognito_user_name } = currentPlan || "";

    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const done = `${t("valueUnit", {
      value:
        yourEntry && yourEntry.totals
          ? Math.round(
              convertUnits(
                yourEntry.totals.distance / 1000,
                "kms",
                this.state.units,
                "distance",
              ),
            )
          : 0,
      unit: this.state.units === "miles" ? ` ${t("miles")}` : "km",
    })} - ${t("valueUnit", {
      value:
        yourEntry && yourEntry.totals
          ? new Date(yourEntry.totals.time * 1000).toISOString().substr(11, 5)
          : 0,
      unit: "h",
    })} - ${t("valueUnit", {
      value:
        yourEntry && yourEntry.totals
          ? Math.round(
              convertUnits(
                yourEntry.totals.elevation,
                "kms",
                this.state.units,
                "elevation",
              ),
            )
          : 0,
      unit: this.state.units === "miles" ? ` ${t("feet")}` : "m",
    })}`;

    switch (type) {
      case "instagram":
        this.props.dispatch(
          setSuccessMessage(
            t(
              "Challenge message copied to clipboard. Paste it on Instagram to complete your post",
            ),
          ),
        );
        await Clipboard.write({
          string:
            t("share progress title", {
              name: cognito_user_name,
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }) +
            ": " +
            t("share progress text", {
              description: challenge.description[languageDetector]
                ? challenge.description[languageDetector]
                : challenge.description["en"],
              done,
            }) +
            " - " +
            "https://app.vert.run/challenges",
        });
        setTimeout(() => {
          SocialSharing.shareViaInstagram(
            t("share progress title", {
              name: cognito_user_name,
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }) +
              ": " +
              t("share progress text", {
                description: challenge.description[languageDetector]
                  ? challenge.description[languageDetector]
                  : challenge.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
            [challenge.badge],
          );
        }, 1000);
        this.handleCloseShare();
        break;
      case "email":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaEmail(
            t("share progress text", {
              description: challenge.description[languageDetector]
                ? challenge.description[languageDetector]
                : challenge.description["en"],
              done,
            }),
            t("share progress title", {
              name: cognito_user_name,
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }),
            null,
            null,
            null,
            [challenge.badge],
          );
        } else {
          window.open(
            "mailto:" +
              objectToGetParams({
                subject: t("share progress title", {
                  name: cognito_user_name,
                  title: challenge.name[languageDetector]
                    ? challenge.name[languageDetector]
                    : challenge.name["en"],
                }),
                body:
                  t("share progress text", {
                    description: challenge.description[languageDetector]
                      ? challenge.description[languageDetector]
                      : challenge.description["en"],
                    done,
                  }) +
                  " - " +
                  "https://app.vert.run/challenges",
              }),
          );
        }
        this.handleCloseShare();
        break;

      case "facebook":
        this.props.dispatch(
          setSuccessMessage(
            t(
              "Challenge message copied to clipboard. Paste it on Facebook to complete your post",
            ),
          ),
        );
        //if (!Capacitor.isNativePlatform()) {
        navigator.clipboard.writeText(
          t("share progress title", {
            name: cognito_user_name,
            title: challenge.name[languageDetector]
              ? challenge.name[languageDetector]
              : challenge.name["en"],
          }) +
            ": " +
            t("share progress text", {
              description: challenge.description[languageDetector]
                ? challenge.description[languageDetector]
                : challenge.description["en"],
              done,
            }) +
            " - " +
            "https://app.vert.run/challenges",
        );
        //}
        setTimeout(() => {
          if (Capacitor.isNativePlatform()) {
            SocialSharing.shareViaFacebook(
              t("share progress title", {
                name: cognito_user_name,
                title: challenge.name[languageDetector]
                  ? challenge.name[languageDetector]
                  : challenge.name["en"],
              }) +
                ": " +
                t("share progress text", {
                  description: challenge.description[languageDetector]
                    ? challenge.description[languageDetector]
                    : challenge.description["en"],
                  done,
                }) +
                " - " +
                "https://app.vert.run/challenges",
              [challenge.badge],
            );
          } else {
            window.open(
              "http://www.facebook.com/sharer.php?u=" +
                encodeURIComponent(challenge.badge),
              "sharer",
              "toolbar=0,status=0,width=626,height=436",
            );
          }
        }, 1000);
        this.handleCloseShare();
        break;

      case "whatsapp":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaWhatsApp(
            t("share progress title", {
              name: cognito_user_name,
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }) +
              ": " +
              t("share progress text", {
                description: challenge.description[languageDetector]
                  ? challenge.description[languageDetector]
                  : challenge.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
            Capacitor.getPlatform() === "ios" ? [] : [challenge.badge],
          );
        } else {
          window.open(
            "https://" +
              (this.isMobileOrTablet() ? "api" : "web") +
              ".whatsapp.com/send" +
              objectToGetParams({
                text:
                  t("share progress title", {
                    name: cognito_user_name,
                    title: challenge.name[languageDetector]
                      ? challenge.name[languageDetector]
                      : challenge.name["en"],
                  }) +
                  ": " +
                  t("share progress text", {
                    description: challenge.description[languageDetector]
                      ? challenge.description[languageDetector]
                      : challenge.description["en"],
                    done,
                  }) +
                  " - " +
                  "https://app.vert.run/challenges",
              }),
          );
        }
        this.handleCloseShare();
        break;

      case "twitter":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaTwitter(
            t("share progress title", {
              name: cognito_user_name,
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }) +
              ": " +
              t("share progress text", {
                description: challenge.description[languageDetector]
                  ? challenge.description[languageDetector]
                  : challenge.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
            [challenge.badge],
          );
        } else {
          window.open(
            "http://twitter.com/share?" +
              "text=" +
              encodeURIComponent(
                t("share progress title", {
                  name: cognito_user_name,
                  title: challenge.name[languageDetector]
                    ? challenge.name[languageDetector]
                    : challenge.name["en"],
                }) +
                  ": " +
                  t("share progress text", {
                    description: challenge.description[languageDetector]
                      ? challenge.description[languageDetector]
                      : challenge.description["en"],
                    done,
                  }) +
                  " - " +
                  "https://app.vert.run/challenges",
              ),
          );
        }

        this.handleCloseShare();
        break;

      case "trailhead":
        this.props.dispatch(
          newPost(
            t("share progress title", {
              name: cognito_user_name,
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }),
            t("share progress text", {
              description: challenge.description[languageDetector]
                ? challenge.description[languageDetector]
                : challenge.description["en"],
              done,
            }),
            challenge.badge,
          ),
        );
        history.push(
          currentPublicGroup
            ? `/trailhead/group/${currentPublicGroup.id}`
            : `/trailhead`,
        );
        this.handleCloseShare();
        break;

      default:
        break;
    }
  };

  handleShareFinished = (challenge) => {
    this.setState({
      ...this.state,
      openShare: true,
      challengeToShare: challenge,
      shareType: "finished",
    });
  };

  handleShareFinishedS1 = async (type) => {
    const { i18n, userPlans, t, trailHead } = this.props;
    const { language } = i18n;

    const { currentPublicGroup } = trailHead;

    const { challengeToShare: challenge } = this.state;
    const { yourEntry } = challenge;

    const { currentPlan } = userPlans;
    const { cognito_user_name } = currentPlan || "";

    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    const done = `${t("valueUnit", {
      value:
        yourEntry && yourEntry.totals
          ? Math.round(
              convertUnits(
                yourEntry.totals.distance / 1000,
                "kms",
                this.state.units,
                "distance",
              ),
            )
          : 0,
      unit: this.state.units === "miles" ? ` ${t("miles")}` : "km",
    })} - ${t("valueUnit", {
      value:
        yourEntry && yourEntry.totals
          ? new Date(yourEntry.totals.time * 1000).toISOString().substr(11, 5)
          : 0,
      unit: "h",
    })} - ${t("valueUnit", {
      value:
        yourEntry && yourEntry.totals
          ? Math.round(
              convertUnits(
                yourEntry.totals.elevation,
                "kms",
                this.state.units,
                "elevation",
              ),
            )
          : 0,
      unit: this.state.units === "miles" ? ` ${t("feet")}` : "m",
    })}`;

    switch (type) {
      case "instagram":
        this.props.dispatch(
          setSuccessMessage(
            t(
              "Challenge message copied to clipboard. Paste it on Instagram to complete your post",
            ),
          ),
        );
        setTimeout(() => {
          SocialSharing.shareViaInstagram(
            t("share finished title", {
              name: cognito_user_name,
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }) +
              ": " +
              t("share finished text", {
                description: challenge.description[languageDetector]
                  ? challenge.description[languageDetector]
                  : challenge.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
            [challenge.badge],
          );
        }, 1000);
        this.handleCloseShare();
        break;
      case "email":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaEmail(
            t("share finished text", {
              description: challenge.description[languageDetector]
                ? challenge.description[languageDetector]
                : challenge.description["en"],
              done,
            }),
            t("share finished title", {
              name: cognito_user_name,
              title: challenge.name[languageDetector],
            }),
            null,
            null,
            null,
            [challenge.badge],
          );
        } else {
          window.open(
            "mailto:" +
              objectToGetParams({
                subject: t("share finished title", {
                  name: cognito_user_name,
                  title: challenge.name[languageDetector]
                    ? challenge.name[languageDetector]
                    : challenge.name["en"],
                }),
                body:
                  t("share finished text", {
                    description: challenge.description[languageDetector]
                      ? challenge.description[languageDetector]
                      : challenge.description["en"],
                    done,
                  }) +
                  " - " +
                  "https://app.vert.run/challenges",
              }),
          );
        }
        this.handleCloseShare();
        break;

      case "facebook":
        this.props.dispatch(
          setSuccessMessage(
            t(
              "Challenge message copied to clipboard. Paste it on Facebook to complete your post",
            ),
          ),
        );
        if (!Capacitor.isNativePlatform()) {
          navigator.clipboard.writeText(
            t("share finished title", {
              name: cognito_user_name,
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }) +
              ": " +
              t("share finished text", {
                description: challenge.description[languageDetector]
                  ? challenge.description[languageDetector]
                  : challenge.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
          );
        } else {
          await Clipboard.write({
            string:
              t("share finished title", {
                name: cognito_user_name,
                title: challenge.name[languageDetector]
                  ? challenge.name[languageDetector]
                  : challenge.name["en"],
              }) +
              ": " +
              t("share finished text", {
                description: challenge.description[languageDetector]
                  ? challenge.description[languageDetector]
                  : challenge.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
          });
        }
        setTimeout(() => {
          if (Capacitor.isNativePlatform()) {
            SocialSharing.shareViaFacebook(
              t("share finished title", {
                name: cognito_user_name,
                title: challenge.name[languageDetector]
                  ? challenge.name[languageDetector]
                  : challenge.name["en"],
              }) +
                ": " +
                t("share finished text", {
                  description: challenge.description[languageDetector]
                    ? challenge.description[languageDetector]
                    : challenge.description["en"],
                  done,
                }) +
                " - " +
                "https://app.vert.run/challenges",
              [challenge.badge],
            );
          } else {
            window.open(
              "http://www.facebook.com/sharer.php?u=" +
                encodeURIComponent(challenge.badge),
              "sharer",
              "toolbar=0,status=0,width=626,height=436",
            );
          }
        }, 1000);
        this.handleCloseShare();
        break;

      case "whatsapp":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaWhatsApp(
            t("share finished title", {
              name: cognito_user_name,
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }) +
              ": " +
              t("share finished text", {
                description: challenge.description[languageDetector]
                  ? challenge.description[languageDetector]
                  : challenge.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
            Capacitor.getPlatform() === "ios" ? [] : [challenge.badge],
          );
        } else {
          window.open(
            "https://" +
              (this.isMobileOrTablet() ? "api" : "web") +
              ".whatsapp.com/send" +
              objectToGetParams({
                text:
                  t("share finished title", {
                    name: cognito_user_name,
                    title: challenge.name[languageDetector]
                      ? challenge.name[languageDetector]
                      : challenge.name["en"],
                  }) +
                  ": " +
                  t("share finished text", {
                    description: challenge.description[languageDetector]
                      ? challenge.description[languageDetector]
                      : challenge.description["en"],
                    done,
                  }) +
                  " - " +
                  "https://app.vert.run/challenges",
              }),
          );
        }
        this.handleCloseShare();
        break;

      case "twitter":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaTwitter(
            t("share finished title", {
              name: cognito_user_name,
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }) +
              ": " +
              t("share finished text", {
                description: challenge.description[languageDetector]
                  ? challenge.description[languageDetector]
                  : challenge.description["en"],
                done,
              }) +
              " - " +
              "https://app.vert.run/challenges",
            [challenge.badge],
          );
        } else {
          window.open(
            "http://twitter.com/share?" +
              "text=" +
              encodeURIComponent(
                t("share finished title", {
                  name: cognito_user_name,
                  title: challenge.name[languageDetector]
                    ? challenge.name[languageDetector]
                    : challenge.name["en"],
                }) +
                  ": " +
                  t("share finished text", {
                    description: challenge.description[languageDetector]
                      ? challenge.description[languageDetector]
                      : challenge.description["en"],
                    done,
                  }) +
                  " - " +
                  "https://app.vert.run/challenges",
              ),
          );
        }
        this.handleCloseShare();
        break;

      case "trailhead":
        this.props.dispatch(newPost("", "", null, null, challenge.yourEntry));
        history.push(
          currentPublicGroup
            ? `/trailhead/group/${currentPublicGroup.id}`
            : `/trailhead`,
        );
        this.handleCloseShare();
        break;

      default:
        break;
    }
  };

  handleInvite = (challenge) => {
    this.setState({
      ...this.state,
      openShare: true,
      challengeToShare: challenge,
      shareType: "invite",
    });
  };

  handleInviteS1 = async (type) => {
    const { i18n, t, trailHead } = this.props;
    const { language } = i18n;

    const { currentPublicGroup } = trailHead;

    const { challengeToShare: challenge } = this.state;

    const languageDetector =
      language === "en" || language === "es" || language === "fr"
        ? language
        : "en";

    switch (type) {
      case "instagram":
        this.props.dispatch(
          setSuccessMessage(
            t(
              "Challenge message copied to clipboard. Paste it on Instagram to complete your post",
            ),
          ),
        );
        setTimeout(() => {
          SocialSharing.shareViaInstagram(
            t("share invite title", {
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }) +
              ": " +
              challenge.description[languageDetector]
              ? challenge.description[languageDetector]
              : challenge.description["en"] +
                  " - " +
                  "https://app.vert.run/challenges",
            [challenge.badge],
          );
        }, 1000);
        this.handleCloseShare();
        break;
      case "email":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaEmail(
            challenge.description[languageDetector]
              ? challenge.description[languageDetector]
              : challenge.description["en"],
            t("share invite title", {
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }),
            null,
            null,
            null,
            [challenge.badge],
          );
        } else {
          window.open(
            "mailto:" +
              objectToGetParams({
                subject: t("share invite title", {
                  title: challenge.name[languageDetector]
                    ? challenge.name[languageDetector]
                    : challenge.name["en"],
                }),
                body: challenge.description[languageDetector]
                  ? challenge.description[languageDetector]
                  : challenge.description["en"] +
                    " - " +
                    "https://app.vert.run/challenges",
              }),
          );
        }
        this.handleCloseShare();
        break;

      case "facebook":
        this.props.dispatch(
          setSuccessMessage(
            t(
              "Challenge message copied to clipboard. Paste it on Facebook to complete your post",
            ),
          ),
        );
        if (!Capacitor.isNativePlatform()) {
          navigator.clipboard.writeText(
            t("share invite title", {
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }) +
              ": " +
              challenge.description[languageDetector]
              ? challenge.description[languageDetector]
              : challenge.description["en"] +
                  " - " +
                  "https://app.vert.run/challenges",
          );
        } else {
          await Clipboard.write({
            string:
              t("share invite title", {
                title: challenge.name[languageDetector]
                  ? challenge.name[languageDetector]
                  : challenge.name["en"],
              }) +
              ": " +
              challenge.description[languageDetector]
                ? challenge.description[languageDetector]
                : challenge.description["en"] +
                  " - " +
                  "https://app.vert.run/challenges",
          });
        }
        setTimeout(() => {
          if (Capacitor.isNativePlatform()) {
            SocialSharing.shareViaFacebook(
              t("share invite title", {
                title: challenge.name[languageDetector]
                  ? challenge.name[languageDetector]
                  : challenge.name["en"],
              }) +
                ": " +
                challenge.description[languageDetector]
                ? challenge.description[languageDetector]
                : challenge.description["en"] +
                    " - " +
                    "https://app.vert.run/challenges",
              [challenge.badge],
            );
          } else {
            window.open(
              "http://www.facebook.com/sharer.php?u=" +
                encodeURIComponent(challenge.badge),
              "sharer",
              "toolbar=0,status=0,width=626,height=436",
            );
          }
        }, 1000);
        this.handleCloseShare();
        break;

      case "whatsapp":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaWhatsApp(
            t("share invite title", {
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }) +
              ": " +
              challenge.description[languageDetector]
              ? challenge.description[languageDetector]
              : challenge.description["en"] +
                  " - " +
                  "https://app.vert.run/challenges",
            Capacitor.getPlatform() === "ios" ? [] : [challenge.badge],
          );
        } else {
          window.open(
            "https://" +
              (this.isMobileOrTablet() ? "api" : "web") +
              ".whatsapp.com/send" +
              objectToGetParams({
                text:
                  t("share invite title", {
                    title: challenge.name[languageDetector]
                      ? challenge.name[languageDetector]
                      : challenge.name["en"],
                  }) +
                  ": " +
                  challenge.description[languageDetector]
                    ? challenge.description[languageDetector]
                    : challenge.description["en"] +
                      " - " +
                      "https://app.vert.run/challenges",
              }),
          );
        }
        this.handleCloseShare();
        break;

      case "twitter":
        if (Capacitor.isNativePlatform()) {
          SocialSharing.shareViaTwitter(
            t("share invite title", {
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }) +
              ": " +
              challenge.description[languageDetector]
              ? challenge.description[languageDetector]
              : challenge.description["en"] +
                  " - " +
                  "https://app.vert.run/challenges",
            [challenge.badge],
          );
        } else {
          window.open(
            "http://twitter.com/share?" +
              "text=" +
              encodeURIComponent(
                t("share invite title", {
                  title: challenge.name[languageDetector]
                    ? challenge.name[languageDetector]
                    : challenge.name["en"],
                }) +
                  ": " +
                  challenge.description[languageDetector]
                  ? challenge.description[languageDetector]
                  : challenge.description["en"] +
                      " - " +
                      "https://app.vert.run/challenges",
              ),
          );
        }
        this.handleCloseShare();
        break;

      case "trailhead":
        this.props.dispatch(
          newPost(
            t("share invite title", {
              title: challenge.name[languageDetector]
                ? challenge.name[languageDetector]
                : challenge.name["en"],
            }),
            challenge.description[languageDetector]
              ? challenge.description[languageDetector]
              : challenge.description["en"],
            challenge.badge,
          ),
        );
        history.push(
          currentPublicGroup
            ? `/trailhead/group/${currentPublicGroup.id}`
            : `/trailhead`,
        );
        this.handleCloseShare();
        break;

      default:
        break;
    }
  };

  handleCloseShare = () => {
    this.setState({
      ...this.state,
      openShare: false,
      challengeToShare: null,
    });
  };

  handleShareChallenge = (challenge) => {
    SocialSharing.share(
      "test message",
      "test subject",
      [challenge.badge],
      "https://app.vert.run/challenges",
    );
  };

  refreshJoinedChallenges = () => {
    const { activities, userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { lastLogin } = currentPlan || {};

    if (currentPlan && currentPlan.cognito_user_sub) {
      this.props.dispatch(
        updateJoinedChallenges({
          cognito_user_sub: currentPlan.cognito_user_sub,
          lastLogin,
          activities,
        }),
      );
    }
  };

  handleSelectActivities = (activity, challengeId) => {
    const { userPlans } = this.props;
    const { currentPlan } = userPlans;
    const { lastLogin } = currentPlan || {};

    activity.selectedFor = [challengeId];

    this.props.dispatch(
      updateJoinedChallenges({
        cognito_user_sub: currentPlan.cognito_user_sub,
        lastLogin,
        activities: [activity],
      }),
    );
  };

  handleHideMessage = () => {
    this.props.dispatch(clearSuccessMessage());
    this.props.dispatch(clearErrorMessage());
  };

  handleOpenSignUp = () => {
    try {
      localStorage.setItem("challengesUser", true);
    } catch (e) {
      console.log(e);
    }

    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignUp: true,
    });
  };

  handleCloseSignUp = () => {
    localStorage.removeItem("challengesUser");
    localStorage.removeItem("error");
    this.setState({
      ...this.state,
      openSignUp: false,
    });
  };

  handleCloseStrava = () => {
    this.setState({
      ...this.state,
      openStrava: false,
    });
  };

  toggleFavorite = (event, id, type) => {
    event.preventDefault();
    event.stopPropagation();
    const { auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      const { userPlans } = this.props;
      const { currentPlan } = userPlans;
      const { exploreFavorites, cognito_user_sub } = currentPlan || {};
      if (
        exploreFavorites &&
        exploreFavorites.length &&
        exploreFavorites.find((e) => e.id === id)
      ) {
        const newExploreFavorites = exploreFavorites.filter((f) => f.id !== id);
        this.props.dispatch(
          updatePlan(
            {
              cognito_user_sub: cognito_user_sub,
              exploreFavorites: newExploreFavorites,
            },
            false,
            false,
          ),
        );
      } else {
        this.props.dispatch(
          updatePlan(
            {
              cognito_user_sub: cognito_user_sub,
              exploreFavorites:
                exploreFavorites && exploreFavorites.length
                  ? [{ id, type }, ...exploreFavorites]
                  : [{ id, type }],
            },
            false,
            false,
          ),
        );
      }
    } else {
      this.handleOpenSignUp();
    }
  };

  handleOpenFavorites = () => {
    const { auth } = this.props;
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      this.setState({
        ...this.state,
        favorites: !this.state.favorites,
      });
    } else {
      this.handleOpenSignUp();
    }
  };

  handleBack = () => {
    history.goBack();
  };

  render() {
    const {
      classes,
      userPlans,
      auth,
      width,
      t,
      challenges,
      activities,
    } = this.props;
    const { loading, currentPlan, successMessage, errorMessage } = userPlans;
    const { image, cognito_user_sub, trailhead, exploreFavorites } =
      currentPlan || "";
    const {
      tab,
      openChallenge,
      openChallengeUpcoming,
      openShare,
      shareType,
      openSignUp,
      openStrava,
      openBadges,
      challengeToShare,
      favorites,
    } = this.state;

    const {
      currentChallenges,
      yourChallengeEntries,
      loadingChallenges,
      loadingYourChallengeEntries,
      leaders,
      loadingLeaders,
    } = challenges;

    const { currentUserLoading, isAuthenticated } = auth;

    const goToMain = () => history.push("/");

    const isInMobileView = width === "md" || width === "sm" || width === "xs";
    const isInMobileViewSm = width === "sm" || width === "xs";

    let yourChallenges = currentChallenges
      ? currentChallenges.filter(
          (c) => c.participants && c.participants.includes(cognito_user_sub),
        )
      : [];
    let upcomingChallenges = currentChallenges
      ? currentChallenges.filter(
          (c) => !c.participants || !c.participants.includes(cognito_user_sub),
        )
      : [];

    const yourDoneChallenges = yourChallengeEntries
      ? yourChallengeEntries.filter(
          (e) => e.challengeData.end_date < new Date().getTime(),
        )
      : [];

    yourChallenges = yourChallenges.map((c) => {
      c.yourEntry = yourChallengeEntries
        ? yourChallengeEntries.find((yc) => yc.challenge === c.id)
        : null;
      return c;
    });

    upcomingChallenges.sort((a, b) => {
      return a.start_date - b.start_date;
    });

    yourDoneChallenges.sort((a, b) => {
      return a.start_date - b.start_date;
    });

    yourChallenges.sort((a, b) => {
      return a.start_date - b.start_date;
    });

    let yourPastChallenges = yourDoneChallenges.map((c) => {
      const challenge = c.challengeData;
      challenge.yourEntry = c;
      return challenge;
    });

    if (favorites) {
      yourPastChallenges = exploreFavorites
        ? yourPastChallenges.filter((p) =>
            exploreFavorites.find((e) => e.id === p.id),
          )
        : [];

      upcomingChallenges = exploreFavorites
        ? upcomingChallenges.filter((p) =>
            exploreFavorites.find((e) => e.id === p.id),
          )
        : [];

      yourChallenges = exploreFavorites
        ? yourChallenges.filter((p) =>
            exploreFavorites.find((e) => e.id === p.id),
          )
        : [];
    }

    let openedChallenge = null;
    if (openChallenge) {
      openedChallenge = yourChallenges.find((yc) => yc.id === openChallenge.id);

      if (!openedChallenge) {
        openedChallenge = upcomingChallenges.find(
          (uc) => uc.id === openChallenge.id,
        );
      }

      if (!openedChallenge) {
        openedChallenge = openChallenge;
      }
    }

    return !isAuthenticated && Capacitor.isNativePlatform() ? (
      <Redirect
        to={{
          pathname: "/auth",
        }}
      />
    ) : currentUserLoading || (loading && isAuthenticated) ? (
      <div className={classes.progressContainer}>
        <CircularProgress
          variant="indeterminate"
          color="primary"
          className={classes.loading}
          data-testid="LoadingUser"
        />
      </div>
    ) : (
      <div
        className={classes.container}
        style={{
          minHeight:
            isInMobileView && isAuthenticated
              ? "calc(100vh - 50px)"
              : isInMobileView
              ? "100vh"
              : "initial",
        }}
      >
        {isAuthenticated ? (
          <AppBar
            isOnDashboard={true}
            isNotOnChoosingPlanPage={true}
            showLanguageMenu={false}
            showChoosePlanHeader={false}
            whiteLogoOnMobileView={false}
            isOnSubscriptionPage={false}
            isOnChallenges={true}
          />
        ) : (
          <div className={classes.logoImgContainer}>
            <img
              src={`${process.env.PUBLIC_URL}/vertrun-logo-wide.png`}
              alt=""
              className={classes.logoImg}
              onClick={goToMain}
            />

            <Typography variant="body1" className={classes.noAuthSubTitle}>
              {t("Trail & Ultra running")}
            </Typography>

            <Typography variant="body1" className={classes.noAuthText}>
              {t("Choose your challenge")}
            </Typography>
          </div>
        )}
        <Grid
          container
          spacing={16}
          style={{
            justifyContent: isAuthenticated ? "initial" : "center",
          }}
        >
          {isInMobileViewSm ? (
            <div className={classes.searchBar}>
              <ArrowBackIosIcon
                onClick={() => this.handleBack()}
                fontSize="small"
              />

              <Typography variant="body1" className={classes.title}>
                {t("Challenges")}
              </Typography>

              <img
                src={`${process.env.PUBLIC_URL}/icon-star-${
                  favorites ? "filled" : "outline"
                }.svg`}
                alt=""
                onClick={this.handleOpenFavorites}
                className={classes.favs}
              />
            </div>
          ) : (
            ""
          )}

          {isAuthenticated && !isInMobileView ? (
            <DashboardUser
              xs={12}
              sm={12}
              md={4}
              lg={3}
              onError={this.handleError}
              onImageChange={this.handleImageFieldChange}
              onFieldChanges={this.handleFieldChanges}
              maxSize={2048000}
              onActivateTrailhead={this.handleActivateTrailhead}
              image={image}
              isChallenges={true}
              onShareChallenge={this.handleShareFinished}
            />
          ) : (
            ""
          )}

          {loadingChallenges ? (
            <div className={classes.progressContainer}>
              <CircularProgress
                variant="indeterminate"
                color="primary"
                className={classes.loading}
                data-testid="LoadingUser"
              />
            </div>
          ) : (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={classes.trainingPane}
                style={{
                  padding: isInMobileViewSm ? 0 : "0 10px",
                }}
              >
                {isAuthenticated &&
                isInMobileView &&
                ((yourDoneChallenges && yourDoneChallenges.length) ||
                  loadingChallenges) ? (
                  <div className={classes.trainingPaneFinished}>
                    {!loadingChallenges ? (
                      <Typography
                        variant={"body1"}
                        className={classes.badgesTitle}
                      >
                        {t("Badges")}
                      </Typography>
                    ) : (
                      ""
                    )}

                    <div className={classes.badgesContainer}>
                      <Grid container>
                        {!loadingChallenges ? (
                          yourDoneChallenges.map((c, i) => {
                            if (openBadges || i < 4) {
                              return (
                                <Grid item xs={3} key={c.id}>
                                  <FinishedChallenge
                                    yourEntry={c}
                                    onShareChallenge={() =>
                                      this.handleShareFinished({
                                        ...c.challengeData,
                                        yourEntry: c,
                                      })
                                    }
                                    units={this.state.units}
                                  ></FinishedChallenge>
                                </Grid>
                              );
                            } else {
                              return "";
                            }
                          })
                        ) : (
                          <div className={classes.progressContainer}>
                            <CircularProgress
                              variant="indeterminate"
                              color="primary"
                              className={classes.loading}
                              data-testid="LoadingUser"
                            />
                          </div>
                        )}
                      </Grid>

                      <img
                        src={`${process.env.PUBLIC_URL}/icon-chevron-${
                          openBadges ? "up" : "down"
                        }.svg`}
                        alt=""
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            openBadges: !openBadges,
                          })
                        }
                        className={classes.badgesOpener}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <>
                  {isAuthenticated ? (
                    isInMobileView ? (
                      <div className={classes.topBarMobile}>
                        <Tabs
                          value={tab}
                          indicatorColor="primary"
                          textColor="primary"
                          onChange={(ev, index) =>
                            this.setState({
                              tab: index,
                              openChallenge: null,
                              openChallengeUpcoming: null,
                            })
                          }
                          variant="fullWidth"
                        >
                          <Tab label={t("Past")} index={0} />
                          <Tab label={t("Active")} index={1} />
                          <Tab label={t("Comming up")} index={2} />
                        </Tabs>
                      </div>
                    ) : (
                      <div className={classes.topBarMobile}>
                        <Typography
                          variant="body1"
                          className={
                            tab === 0 ? classes.filterSelected : classes.filter
                          }
                          onClick={() =>
                            this.setState({
                              tab: 0,
                              openChallenge: null,
                              openChallengeUpcoming: null,
                            })
                          }
                        >
                          {t("Past Challenges")}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={
                            tab === 1 ? classes.filterSelected : classes.filter
                          }
                          onClick={() =>
                            this.setState({
                              tab: 1,
                              openChallenge: null,
                              openChallengeUpcoming: null,
                            })
                          }
                        >
                          {t("Your challenges")}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={
                            tab === 2 ? classes.filterSelected : classes.filter
                          }
                          onClick={() => this.setState({ tab: 2 })}
                        >
                          {t("Comming up")}
                        </Typography>
                      </div>
                    )
                  ) : (
                    ""
                  )}

                  {tab === 0 && (
                    <>
                      {openedChallenge ? (
                        <YourChallenge
                          challenge={openedChallenge}
                          onShareChallenge={this.handleShareFinished}
                          onInvite={this.handleInvite}
                          onClose={this.handleCloseChallenge}
                          currentPlan={currentPlan}
                          units={this.state.units}
                          showLeaderboard={isInMobileView ? 0 : 1}
                          activities={activities}
                          onSelectActivity={this.handleSelectActivities}
                          isPast={true}
                          loadingYourChallengeEntries={
                            loadingYourChallengeEntries
                          }
                          leaders={leaders}
                          loadingLeaders={loadingLeaders}
                          onToggleFavorite={this.toggleFavorite}
                          isFav={
                            exploreFavorites &&
                            exploreFavorites.length &&
                            exploreFavorites.find(
                              (e) => e.id === openedChallenge.id,
                            )
                          }
                        ></YourChallenge>
                      ) : yourPastChallenges && yourPastChallenges.length ? (
                        yourPastChallenges.map((c) => (
                          <Challenge
                            key={c.id}
                            challenge={c}
                            onJoinChallenge={this.handleJoinChallenge}
                            onOpenChallenge={this.handleOpenChallenge}
                            isJoined={true}
                            units={this.state.units}
                            isPast={true}
                            onToggleFavorite={this.toggleFavorite}
                            isFav={
                              exploreFavorites &&
                              exploreFavorites.length &&
                              exploreFavorites.find((e) => e.id === c.id)
                            }
                          ></Challenge>
                        ))
                      ) : loadingYourChallengeEntries ? (
                        <div className={classes.progressContainer}>
                          <CircularProgress
                            variant="indeterminate"
                            color="primary"
                            className={classes.loading}
                            data-testid="LoadingUser"
                          />
                        </div>
                      ) : (
                        <div className={classes.noActiveContainer}>
                          <Typography
                            variant={"body1"}
                            className={classes.noActive}
                          >
                            {t("no past challenges")}
                          </Typography>
                        </div>
                      )}
                    </>
                  )}

                  {tab === 1 && (
                    <>
                      {openedChallenge ? (
                        <YourChallenge
                          challenge={openedChallenge}
                          onShareChallenge={this.handleShareProgress}
                          onInvite={this.handleInvite}
                          onClose={this.handleCloseChallenge}
                          currentPlan={currentPlan}
                          units={this.state.units}
                          showLeaderboard={isInMobileView ? 0 : 1}
                          activities={activities}
                          onSelectActivity={this.handleSelectActivities}
                          loadingYourChallengeEntries={
                            loadingYourChallengeEntries
                          }
                          leaders={leaders}
                          loadingLeaders={loadingLeaders}
                          onToggleFavorite={this.toggleFavorite}
                          isFav={
                            exploreFavorites &&
                            exploreFavorites.length &&
                            exploreFavorites.find(
                              (e) => e.id === openedChallenge.id,
                            )
                          }
                        ></YourChallenge>
                      ) : yourChallenges && yourChallenges.length ? (
                        yourChallenges.map((c) => (
                          <Challenge
                            key={c.id}
                            challenge={c}
                            onJoinChallenge={this.handleJoinChallenge}
                            onOpenChallenge={this.handleOpenChallenge}
                            isJoined={true}
                            units={this.state.units}
                            onToggleFavorite={this.toggleFavorite}
                            isFav={
                              exploreFavorites &&
                              exploreFavorites.length &&
                              exploreFavorites.find((e) => e.id === c.id)
                            }
                          ></Challenge>
                        ))
                      ) : loadingChallenges ? (
                        <div className={classes.progressContainer}>
                          <CircularProgress
                            variant="indeterminate"
                            color="primary"
                            className={classes.loading}
                            data-testid="LoadingUser"
                          />
                        </div>
                      ) : (
                        <div className={classes.noActiveContainer}>
                          <Typography
                            variant={"body1"}
                            className={classes.noActive}
                          >
                            {t("no active challenges")}
                          </Typography>
                        </div>
                      )}
                    </>
                  )}

                  {(tab === 2 || !isAuthenticated) && (
                    <>
                      {openChallengeUpcoming ? (
                        <YourChallenge
                          challenge={openChallengeUpcoming}
                          onShareChallenge={this.handleShareProgress}
                          onInvite={this.handleInvite}
                          onClose={this.handleCloseChallenge}
                          currentPlan={currentPlan}
                          units={this.state.units}
                          showLeaderboard={isInMobileView ? 0 : 1}
                          activities={activities}
                          onSelectActivity={this.handleSelectActivities}
                          loadingYourChallengeEntries={
                            loadingYourChallengeEntries
                          }
                          notJoined={true}
                          onJoinChallenge={this.handleJoinChallenge}
                          leaders={leaders}
                          loadingLeaders={loadingLeaders}
                          onToggleFavorite={this.toggleFavorite}
                          isFav={
                            exploreFavorites &&
                            exploreFavorites.length &&
                            exploreFavorites.find(
                              (e) => e.id === openChallengeUpcoming.id,
                            )
                          }
                        ></YourChallenge>
                      ) : upcomingChallenges && upcomingChallenges.length ? (
                        upcomingChallenges.map((c) => (
                          <Challenge
                            key={c.id}
                            challenge={c}
                            onJoinChallenge={this.handleJoinChallenge}
                            onOpenChallenge={this.handleOpenChallengeUpcoming}
                            units={this.state.units}
                            onToggleFavorite={this.toggleFavorite}
                            isFav={
                              exploreFavorites &&
                              exploreFavorites.length &&
                              exploreFavorites.find((e) => e.id === c.id)
                            }
                          ></Challenge>
                        ))
                      ) : loadingChallenges ? (
                        <div className={classes.progressContainer}>
                          <CircularProgress
                            variant="indeterminate"
                            color="primary"
                            className={classes.loading}
                            data-testid="LoadingUser"
                          />
                        </div>
                      ) : (
                        <div className={classes.noActiveContainer}>
                          <Typography
                            variant={"body1"}
                            className={classes.noActive}
                          >
                            {t("no upcoming challenges")}
                          </Typography>
                        </div>
                      )}
                    </>
                  )}
                </>
              </Grid>
              {!isInMobileView &&
              (tab === 0 || tab === 1 || tab === 2) &&
              isAuthenticated ? (
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  className={classes.trainingPane}
                >
                  {(openedChallenge && tab < 2) ||
                  (openChallengeUpcoming && tab === 2) ? (
                    <YourChallenge
                      challenge={
                        openedChallenge && tab < 2
                          ? openedChallenge
                          : openChallengeUpcoming
                      }
                      onShareChallenge={this.handleShareProgress}
                      onInvite={this.handleInvite}
                      onClose={this.handleCloseChallenge}
                      currentPlan={currentPlan}
                      units={this.state.units}
                      showLeaderboard={isInMobileView ? 0 : 2}
                      activities={activities}
                      onSelectActivity={this.handleSelectActivities}
                      loadingYourChallengeEntries={loadingYourChallengeEntries}
                      notJoined={openChallengeUpcoming && tab === 2}
                      leaders={leaders}
                      loadingLeaders={loadingLeaders}
                      onToggleFavorite={this.toggleFavorite}
                      isFav={
                        exploreFavorites &&
                        exploreFavorites.length &&
                        exploreFavorites.find(
                          (e) =>
                            e.id ===
                            (openedChallenge && tab < 2
                              ? openedChallenge.id
                              : openChallengeUpcoming
                              ? openChallengeUpcoming
                              : null),
                        )
                      }
                    ></YourChallenge>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : (
                ""
              )}
            </>
          )}
          <SnackBarMessageDisplay
            onHideMessage={this.handleHideMessage}
            errorMessage={errorMessage}
            successMessage={successMessage}
          />
        </Grid>

        <Dialog
          open={openShare}
          onClose={this.handleCloseShare}
          maxWidth="md"
          // classes={{
          //   paper: classes.dialogPaper,
          // }}
        >
          <DialogContent
            id="signupdialog"
            className={classes.noPadding}
            classes={{
              root: classes.dialogPaper,
            }}
          >
            <MobileShare
              title={
                shareType === "invite"
                  ? t("Invite with")
                  : shareType === "finished"
                  ? t("Share your result")
                  : t("Share on")
              }
              onClose={this.handleCloseShare}
              onShare={(type) =>
                shareType === "invite"
                  ? this.handleInviteS1(type)
                  : shareType === "finished"
                  ? this.handleShareFinishedS1(type)
                  : this.handleShareProgressS1(type)
              }
              trailhead={trailhead}
              challengeToShare={
                shareType === "finished" ? challengeToShare : null
              }
              units={this.state.units}
            ></MobileShare>
          </DialogContent>
        </Dialog>

        <SnackBarMessageDisplay
          onHideMessage={this.handleHideMessage}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />

        <Dialog
          open={openSignUp}
          onClose={this.handleCloseSignUp}
          aria-labelledby="signUp"
          maxWidth={!isInMobileViewSm ? "sm" : false}
          fullScreen={isInMobileViewSm}
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <DialogContent id="signupdialog" className={classes.noPadding}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseSignUp}
              />
            </div>
            <AppAuthenticator initialState="signUp" />
          </DialogContent>
        </Dialog>

        <Dialog
          open={openStrava}
          onClose={this.handleCloseStrava}
          maxWidth={!isInMobileViewSm ? "sm" : false}
        >
          <DialogContent id="stravadialog" className={classes.stravaDialog}>
            <div className={classes.rightButtons}>
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt=""
                onClick={this.handleCloseStrava}
              />
            </div>
            <Typography variant={"body1"} className={classes.stravaTitle}>
              {t("strava title")}
            </Typography>

            <Typography variant={"body1"} className={classes.stravaText}>
              {t("strava text")}
            </Typography>

            <RouterLink to="/profile">
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
              >
                {t("Connect with Strava")}
              </Button>
            </RouterLink>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

Challenges.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  subcategories: PropTypes.object.isRequired,
  userPlans: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  strava: PropTypes.object.isRequired,
  garmin: PropTypes.object.isRequired,
  suunto: PropTypes.object.isRequired,
  subscriptions: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
  getActivities: PropTypes.func,
  clearSuccessMessage: PropTypes.func,
  clearErrorMessage: PropTypes.func,
  setErrorMessage: PropTypes.func,
  updatePlan: PropTypes.func,
  updatePlanImage: PropTypes.func,
  userPlanMailchimpSubscribe: PropTypes.func,
  currentTrainingCurrentStartDateChange: PropTypes.func,
  coros: PropTypes.object.isRequired,
};

export default connect((store) => {
  return {
    userPlans: store.userPlans,
    subcategories: store.subcategories,
    auth: store.auth,
    strava: store.strava,
    garmin: store.garmin,
    suunto: store.suunto,
    subscriptions: store.subscriptions,
    challenges: store.challenges,
    trailHead: store.trailHead,
    coros: store.coros,
  };
})(
  withTranslation(["challenges"])(
    withWidth()(
      withStyles(styles, { withTheme: true })(withActivities(Challenges)),
    ),
  ),
);
