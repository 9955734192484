import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Grid, TextField, InputAdornment } from "@material-ui/core";

import PlanFieldTransition from "./plan-field-transition";

const styles = {
  textField: {
    marginTop: 0,
  },
  emptyLabel: {
    marginTop: 19.33,
  },

  margin: {
    marginLeft: 5,
    marginRight: 5,
  },
};
class PlanFieldTextInput extends Component {
  render() {
    const {
      classes,
      className,
      xs,
      sm,
      md,
      lg,
      xl,
      id,
      value,
      defaultValue,
      onChange,
      onKeyPress,
      type,
      label,
      textFieldPlaceholder,
      endAdornmentLabel,
      fullWidth,
      multiline,
      required,
    } = this.props;
    return (
      <PlanFieldTransition>
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          className={classes.margin}
        >
          <TextField
            className={classNames(
              { [classes.textField]: label && true },
              { [classes.emptyLabel]: !label && true },
              className,
            )}
            id={id}
            value={value}
            type={type}
            defaultValue={defaultValue}
            margin="normal"
            variant="outlined"
            placeholder={textFieldPlaceholder}
            fullWidth={fullWidth}
            multiline={multiline}
            required={required}
            InputProps={{
              endAdornment: endAdornmentLabel ? (
                <InputAdornment position="end">
                  {endAdornmentLabel}
                </InputAdornment>
              ) : null,
            }}
            onChange={onChange}
            onKeyDown={onKeyPress}
            autoFocus
          />
        </Grid>
      </PlanFieldTransition>
    );
  }
}

PlanFieldTextInput.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  type: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  textFieldPlaceholder: PropTypes.string,
  endAdornmentLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
};

PlanFieldTextInput.defaultProps = {
  type: "text",
  value: "",
  required: false,
};

export default withStyles(styles)(PlanFieldTextInput);
