export default {
  AUTH_CURRENT_USER_PENDING: "AUTH_CURRENT_USER_PENDING",
  AUTH_CURRENT_USER_FULFILLED: "AUTH_CURRENT_USER_FULFILLED",
  AUTH_CURRENT_USER_REJECTED: "AUTH_CURRENT_USER_REJECTED",

  AUTH_SHOW_UPDATE_FULFILLED: "AUTH_SHOW_UPDATE_FULFILLED",

  AUTH_UPDATE_ATTRIBUTES: "AUTH_UPDATE_ATTRIBUTES",

  AUTH_CURRENT_USER_SILENT_PENDING: "AUTH_CURRENT_USER_SILENT_PENDING",
  AUTH_CURRENT_USER_SILENT_FULFILLED: "AUTH_CURRENT_USER_SILENT_FULFILLED",
  AUTH_CURRENT_USER_SILENT_REJECTED: "AUTH_CURRENT_USER_SILENT_REJECTED",

  AUTH_CURRENT_USER_SIGNOUT: "AUTH_CURRENT_USER_SIGNOUT",
  AUTH_CURRENT_USER_SIGNOUT_PENDING: "AUTH_CURRENT_USER_SIGNOUT_PENDING",
  AUTH_CURRENT_USER_SIGNOUT_FULFILLED: "AUTH_CURRENT_USER_SIGNOUT_FULFILLED",
  AUTH_CURRENT_USER_SIGNOUT_REJECTED: "AUTH_CURRENT_USER_SIGNOUT_REJECTED",
  AUTH_CURRENT_USER_SESSION_CHANGED: "AUTH_CURRENT_USER_SESSION_CHANGED",
  AUTH_USER_SET_ERROR: "AUTH_USER_SET_ERROR",
  AUTH_USER_CLEAR_ERROR: "AUTH_USER_CLEAR_ERROR",
  AUTH_USER_SET_PAGE: "AUTH_USER_SET_PAGE",
  USER_PLANS_SET_CURRENT_PLAN: "USER_PLANS_SET_CURRENT_PLAN",
  USER_PLANS_SET_PLAN_TO_SELECT: "USER_PLANS_SET_PLAN_TO_SELECT",
  USER_PLANS_CHANGE_FIELD: "USER_PLANS_CHANGE_FIELD",
  USER_PLANS_CLEAN_FIELDS: "USER_PLANS_CLEAN_FIELDS",
  USER_PLANS_SET_ERROR_MESSAGE: "USER_PLANS_SET_ERROR_MESSAGE",
  USER_PLANS_CLEAR_ERROR_MESSAGE: "USER_PLANS_CLEAR_ERROR_MESSAGE",
  USER_PLANS_SET_SUCCESS_MESSAGE: "USER_PLANS_SET_SUCCESS_MESSAGE",
  USER_PLANS_CLEAR_SUCCESS_MESSAGE: "USER_PLANS_CLEAR_SUCCESS_MESSAGE",
  USER_PLANS_SAVE: "USER_PLANS_SAVE",
  CUSTOMER_COACH_RATE_SAVE_PENDING: "CUSTOMER_COACH_RATE_SAVE_PENDING",
  CUSTOMER_COACH_RATE_SAVE: "CUSTOMER_COACH_RATE_SAVE",
  CUSTOMER_COACH_RATE_SAVE_REJECTED: "CUSTOMER_COACH_RATE_SAVE_REJECTED",

  CUSTOMER_PLAN_RATE_SAVE_PENDING: "CUSTOMER_PLAN_RATE_SAVE_PENDING",
  REQUEST_COACH_CHANGE_SAVE: "REQUEST_COACH_CHANGE_SAVE",
  REQUEST_COACH_CHANGE_SAVE_REJECTED: "REQUEST_COACH_CHANGE_SAVE_REJECTED",
  REQUEST_COACH_CHANGE_SAVE_PENDING: "REQUEST_COACH_CHANGE_SAVE_PENDING",
  CUSTOMER_PLAN_RATE_SAVE: "CUSTOMER_PLAN_RATE_SAVE",
  CUSTOMER_PLAN_RATE_SAVE_REJECTED: "CUSTOMER_PLAN_RATE_SAVE_REJECTED",

  USER_PLANS_SAVE_PENDING: "USER_PLANS_SAVE_PENDING",
  USER_PLANS_SAVE_FULFILLED: "USER_PLANS_SAVE_FULFILLED",
  USER_PLANS_SAVE_REJECTED: "USER_PLANS_SAVE_REJECTED",
  USER_PLANS_UPDATE_PENDING: "USER_PLANS_UPDATE_PENDING",
  USER_PLANS_UPDATE_FULFILLED: "USER_PLANS_UPDATE_FULFILLED",
  USER_PLANS_UPDATE_REJECTED: "USER_PLANS_UPDATE_REJECTED",

  USER_PLANS_CT_UPDATE_PENDING: "USER_PLANS_CT_UPDATE_PENDING",
  USER_PLANS_CT_UPDATE_FULFILLED: "USER_PLANS_CT_UPDATE_FULFILLED",
  USER_PLANS_CT_UPDATE_REJECTED: "USER_PLANS_CT_UPDATE_REJECTED",

  USER_PLANS_UPDATE_IMAGE_PENDING: "USER_PLANS_UPDATE_IMAGE_PENDING",
  USER_PLANS_UPDATE_IMAGE_FULFILLED: "USER_PLANS_UPDATE_IMAGE_FULFILLED",
  USER_PLANS_UPDATE_IMAGE_REJECTED: "USER_PLANS_UPDATE_IMAGE_REJECTED",

  USER_PLANS_ADD_IMAGE_PENDING: "USER_PLANS_ADD_IMAGE_PENDING",
  USER_PLANS_ADD_IMAGE_FULFILLED: "USER_PLANS_ADD_IMAGE_FULFILLED",
  USER_PLANS_ADD_IMAGE_REJECTED: "USER_PLANS_ADD_IMAGE_REJECTED",

  USER_PLANS_DELETE_IMAGE_PENDING: "USER_PLANS_DELETE_IMAGE_PENDING",
  USER_PLANS_DELETE_IMAGE_FULFILLED: "USER_PLANS_DELETE_IMAGE_FULFILLED",
  USER_PLANS_DELETE_IMAGE_REJECTED: "USER_PLANS_DELETE_IMAGE_REJECTED",

  USER_PLANS_UPDATE_LAST_LOGIN_PENDING: "USER_PLANS_UPDATE_LAST_LOGIN_PENDING",
  USER_PLANS_UPDATE_LAST_LOGIN_FULFILLED:
    "USER_PLANS_UPDATE_LAST_LOGIN_FULFILLED",
  USER_PLANS_UPDATE_LAST_LOGIN_REJECTED:
    "USER_PLANS_UPDATE_LAST_LOGIN_REJECTED",

  USER_PLANS_UPDATE_LOAD_PENDING: "USER_PLANS_UPDATE_LOAD_PENDING",
  USER_PLANS_UPDATE_LOAD_FULFILLED: "USER_PLANS_UPDATE_LOAD_FULFILLED",
  USER_PLANS_UPDATE_LOAD_REJECTED: "USER_PLANS_UPDATE_LOAD_REJECTED",

  USER_PLANS_REFERRAL_CODE_PENDING: "USER_PLANS_REFERRAL_CODE_PENDING",
  USER_PLANS_REFERRAL_CODE_FULFILLED: "USER_PLANS_REFERRAL_CODE_FULFILLED",
  USER_PLANS_REFERRAL_CODE_REJECTED: "USER_PLANS_REFERRAL_CODE_REJECTED",

  USER_PLANS_RAFFLE_CODE_PENDING: "USER_PLANS_RAFFLE_CODE_PENDING",
  USER_PLANS_RAFFLE_CODE_FULFILLED: "USER_PLANS_RAFFLE_CODE_FULFILLED",
  USER_PLANS_RAFFLE_CODE_REJECTED: "USER_PLANS_RAFFLE_CODE_REJECTED",

  USER_PLANS_CHANGE_CURRENT_STEP: "USER_PLANS_CHANGE_CURRENT_STEP",
  USER_NEXT_PLAN_GET_PENDING: "USER_NEXT_PLAN_GET_PENDING",
  USER_NEXT_PLAN_GET_FULFILLED: "USER_NEXT_PLAN_GET_FULFILLED",
  USER_NEXT_PLAN_GET_REJECTED: "USER_NEXT_PLAN_GET_REJECTED",
  USER_PLANS_LOAD_CURRENT_PLAN: "USER_PLANS_LOAD_CURRENT_PLAN",
  USER_PLANS_LOAD_CURRENT_PLAN_PENDING: "USER_PLANS_LOAD_CURRENT_PLAN_PENDING",
  USER_PLANS_LOAD_CURRENT_PLAN_FULFILLED:
    "USER_PLANS_LOAD_CURRENT_PLAN_FULFILLED",
  USER_PLANS_LOAD_CURRENT_PLAN_REJECTED:
    "USER_PLANS_LOAD_CURRENT_PLAN_REJECTED",
  USER_PLANS_LOAD_CURRENT_TRAINING: "USER_PLANS_LOAD_CURRENT_TRAINING",
  USER_PLANS_LOAD_CURRENT_TRAINING_PENDING:
    "USER_PLANS_LOAD_CURRENT_TRAINING_PENDING",
  USER_PLANS_LOAD_CURRENT_TRAINING_FULFILLED:
    "USER_PLANS_LOAD_CURRENT_TRAINING_FULFILLED",
  USER_PLANS_LOAD_CURRENT_TRAINING_REJECTED:
    "USER_PLANS_LOAD_CURRENT_TRAINING_REJECTED",

  USER_PLANS_LOAD_TRAINING_PENDING: "USER_PLANS_LOAD_TRAINING_PENDING",
  USER_PLANS_LOAD_TRAINING_FULFILLED: "USER_PLANS_LOAD_TRAINING_FULFILLED",
  USER_PLANS_LOAD_TRAINING_REJECTED: "USER_PLANS_LOAD_TRAINING_REJECTED",

  USER_PLANS_PREV_LOAD_TRAINING_PENDING:
    "USER_PLANS_PREV_LOAD_TRAINING_PENDING",
  USER_PLANS_PREV_LOAD_TRAINING_FULFILLED:
    "USER_PLANS_PREV_LOAD_TRAINING_FULFILLED",
  USER_PLANS_PREV_LOAD_TRAINING_REJECTED:
    "USER_PLANS_PREV_LOAD_TRAINING_REJECTED",

  USER_PLANS_CURRENT_TRAINING_CURRENT_START_DATE_CHANGED:
    "USER_PLANS_CURRENT_TRAINING_CURRENT_START_DATE_CHANGED",

  USER_PLANS_CURRENT_TRAINING_CURRENT_START_DATE_SET:
    "USER_PLANS_CURRENT_TRAINING_CURRENT_START_DATE_SET",

  USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD:
    "USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD",
  USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_PENDING:
    "USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_PENDING",
  USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_FULFILLED:
    "USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_FULFILLED",
  USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_REJECTED:
    "USER_PLANS_CURRENT_TRAINING_UPDATE_WORKOUT_FIELD_REJECTED",
  USER_PLANS_CURRENT_TRAINING_SET_SELECTED_TEMP:
    "USER_PLANS_CURRENT_TRAINING_SET_SELECTED_TEMP",
  USER_PLANS_MAILCHIMP_SUBSCRIBE: "USER_PLANS_MAILCHIMP_SUBSCRIBE",
  USER_PLANS_MAILCHIMP_SUBSCRIBE_PENDING:
    "USER_PLANS_MAILCHIMP_SUBSCRIBE_PENDING",
  USER_PLANS_MAILCHIMP_SUBSCRIBE_FULFILLED:
    "USER_PLANS_MAILCHIMP_SUBSCRIBE_FULFILLED",
  USER_PLANS_MAILCHIMP_SUBSCRIBE_REJECTED:
    "USER_PLANS_MAILCHIMP_SUBSCRIBE_REJECTED",

  USER_PLANS_DELETE_ACCOUNT: "USER_PLANS_DELETE_ACCOUNT",

  USER_PLANS_TOOGLE_OPEN_MENU: "USER_PLANS_TOOGLE_OPEN_MENU",

  USER_PLANS_ADD_COMMENT_PENDING: "USER_PLANS_ADD_COMMENT_PENDING",
  USER_PLANS_ADD_COMMENT_FULFILLED: "USER_PLANS_ADD_COMMENT_FULFILLED",
  USER_PLANS_ADD_COMMENT_REJECTED: "USER_PLANS_ADD_COMMENT_REJECTED",

  USER_PLANS_GET_COMMENTS_PENDING: "USER_PLANS_GET_COMMENTS_PENDING",
  USER_PLANS_GET_COMMENTS_FULFILLED: "USER_PLANS_GET_COMMENTS_FULFILLED",
  USER_PLANS_GET_COMMENTS_REJECTED: "USER_PLANS_GET_COMMENTS_REJECTED",

  USER_PLANS_GET_OLD_COMMENTS_PENDING: "USER_PLANS_GET_OLD_COMMENTS_PENDING",
  USER_PLANS_GET_OLD_COMMENTS_FULFILLED:
    "USER_PLANS_GET_OLD_COMMENTS_FULFILLED",
  USER_PLANS_GET_OLD_COMMENTS_REJECTED: "USER_PLANS_GET_OLD_COMMENTS_REJECTED",

  USER_PLANS_COMMENTS_READ_PENDING: "USER_PLANS_COMMENTS_READ_PENDING",
  USER_PLANS_COMMENTS_READ_FULFILLED: "USER_PLANS_COMMENTS_READ_FULFILLED",
  USER_PLANS_COMMENTS_READ_REJECTED: "USER_PLANS_COMMENTS_READ_REJECTED",

  USER_PLANS_ADD_NOTE_PENDING: "USER_PLANS_ADD_NOTE_PENDING",
  USER_PLANS_ADD_NOTE_FULFILLED: "USER_PLANS_ADD_NOTE_FULFILLED",
  USER_PLANS_ADD_NOTE_REJECTED: "USER_PLANS_ADD_NOTE_REJECTED",

  USER_PLANS_DELETE_NOTE_PENDING: "USER_PLANS_DELETE_NOTE_PENDING",
  USER_PLANS_DELETE_NOTE_FULFILLED: "USER_PLANS_DELETE_NOTE_FULFILLED",
  USER_PLANS_DELETE_NOTE_REJECTED: "USER_PLANS_DELETE_NOTE_REJECTED",

  USER_PLANS_EDIT_NOTE_PENDING: "USER_PLANS_EDIT_NOTE_PENDING",
  USER_PLANS_EDIT_NOTE_FULFILLED: "USER_PLANS_EDIT_NOTE_FULFILLED",
  USER_PLANS_EDIT_NOTE_REJECTED: "USER_PLANS_EDIT_NOTE_REJECTED",

  WORKOUT_SEARCH: "WORKOUT_SEARCH",
  WORKOUT_SEARCH_PENDING: "WORKOUT_SEARCH_PENDING",
  WORKOUT_SEARCH_FULFILLED: "WORKOUT_SEARCH_FULFILLED",
  WORKOUT_SEARCH_REJECTED: "WORKOUT_SEARCH_REJECTED",
  WORKOUT_CLEAN_SEARCH: "WORKOUT_CLEAN_SEARCH",
  WORKOUT_SEARCH_PARAM_CHANGED: "WORKOUT_SEARCH_PARAM_CHANGED",
  WORKOUT_SEARCH_PHRASE_CHANGED: "WORKOUT_SEARCH_PHRASE_CHANGED",
  WORKOUT_SEARCH_FILTER_CHANGED: "WORKOUT_SEARCH_FILTER_CHANGE",
  WORKOUT_SAVE: "WORKOUT_SAVE",
  WORKOUT_SAVE_PENDING: "WORKOUT_SAVE_PENDING",
  WORKOUT_SAVE_FULFILLED: "WORKOUT_SAVE_FULFILLED",
  WORKOUT_SAVE_REJECTED: "WORKOUT_SAVE_REJECTED",
  WORKOUT_READ: "WORKOUT_READ",
  WORKOUT_READ_PENDING: "WORKOUT_READ_PENDING",
  WORKOUT_READ_FULFILLED: "WORKOUT_READ_FULFILLED",
  WORKOUT_READ_REJECTED: "WORKOUT_READ_REJECTED",

  WORKOUT_SET: "WORKOUT_SET",
  WORKOUT_SET_PENDING: "WORKOUT_SET_PENDING",
  WORKOUT_SET_FULFILLED: "WORKOUT_SET_FULFILLED",
  WORKOUT_SET_REJECTED: "WORKOUT_SET_REJECTED",

  WORKOUT_CLEAN_FIELDS: "WORKOUT_CLEAN_FIELDS",
  WORKOUT_CHANGE_FIELD: "WORKOUT_CHANGE_FIELD",
  WORKOUT_SET_ERROR_MESSAGE: "WORKOUT_SET_ERROR_MESSAGE",
  WORKOUT_CLEAR_ERROR_MESSAGE: "WORKOUT_CLEAR_ERROR_MESSAGE",
  WORKOUT_SET_SUCCESS_MESSAGE: "WORKOUT_SET_SUCCESS_MESSAGE",
  WORKOUT_CLEAR_SUCCESS_MESSAGE: "WORKOUT_CLEAR_SUCCESS_MESSAGE",
  WORKOUT_DELETE: "WORKOUT_DELETE",
  WORKOUT_DELETE_PENDING: "WORKOUT_DELETE_PENDING",
  WORKOUT_DELETE_FULFILLED: "WORKOUT_DELETE_FULFILLED",
  WORKOUT_DELETE_REJECTED: "WORKOUT_DELETE_REJECTED",
  TRAINING_SEARCH: "TRAINING_SEARCH",
  TRAINING_SEARCH_PENDING: "TRAINING_SEARCH_PENDING",
  TRAINING_SEARCH_FULFILLED: "TRAINING_SEARCH_FULFILLED",
  TRAINING_SEARCH_REJECTED: "TRAINING_SEARCH_REJECTED",
  TRAINING_CLEAN_SEARCH: "TRAINING_CLEAN_SEARCH",
  TRAINING_CLEAN_SWAP: "TRAINING_CLEAN_SWAP",
  TRAINING_SEARCH_PARAM_CHANGED: "TRAINING_SEARCH_PARAM_CHANGED",
  TRAINING_SEARCH_PHRASE_CHANGED: "TRAINING_SEARCH_PHRASE_CHANGED",
  TRAINING_SEARCH_FILTER_CHANGED: "TRAINING_SEARCH_FILTER_CHANGE",
  TRAINING_WEEK_CHANGED: "TRAINING_WEEK_CHANGED",
  TRAINING_SELECT_WORKOUT: "TRAINING_SELECT_WORKOUT",
  TRAINING_ADD_WORKOUT: "TRAINING_ADD_WORKOUT",
  TRAINING_READ: "TRAINING_READ",
  TRAINING_READ_PENDING: "TRAINING_READ_PENDING",
  TRAINING_READ_FULFILLED: "TRAINING_READ_FULFILLED",
  TRAINING_READ_REJECTED: "TRAINING_READ_REJECTED",
  TRAINING_REMOVE_WORKOUT: "TRAINING_REMOVE_WORKOUT",
  TRAINING_CLEAR_CURRENT: "TRAINING_CLEAR_CURRENT",
  TRAINING_SAVE: "TRAINING_SAVE",
  TRAINING_SAVE_PENDING: "TRAINING_SAVE_PENDING",
  TRAINING_SAVE_FULFILLED: "TRAINING_SAVE_FULFILLED",
  TRAINING_SAVE_REJECTED: "TRAINING_SAVE_REJECTED",
  TRAINING_SET_ERROR_MESSAGE: "TRAINING_SET_ERROR_MESSAGE",
  TRAINING_CLEAR_ERROR_MESSAGE: "TRAINING_CLEAR_ERROR_MESSAGE",
  TRAINING_SET_SUCCESS_MESSAGE: "TRAINING_SET_SUCCESS_MESSAGE",
  TRAINING_CLEAR_SUCCESS_MESSAGE: "TRAINING_CLEAR_SUCCESS_MESSAGE",
  TRAINING_CHANGE_FIELD: "TRAINING_CHANGE_FIELD",
  TRAINING_DELETE: "TRAINING_DELETE",
  TRAINING_DELETE_PENDING: "TRAINING_DELETE_PENDING",
  TRAINING_DELETE_FULFILLED: "TRAINING_DELETE_FULFILLED",
  TRAINING_DELETE_REJECTED: "TRAINING_DELETE_REJECTED",
  TRAINING_SWAP: "TRAINING_SWAP",
  TRAINING_SWAP_PENDING: "TRAINING_SWAP_PENDING",
  TRAINING_SWAP_FULFILLED: "TRAINING_SWAP_FULFILLED",
  TRAINING_SWAP_REJECTED: "TRAINING_SWAP_REJECTED",
  TRAINING_INLINE_EDIT_SELECT: "TRAINING_INLINE_EDIT_SELECT",
  TRAINING_SEARCH_ATHLETES: "TRAINING_SEARCH_ATHLETES",
  TRAINING_SEARCH_ATHLETES_PENDING: "TRAINING_SEARCH_ATHLETES_PENDING",
  TRAINING_SEARCH_ATHLETES_FULFILLED: "TRAINING_SEARCH_ATHLETES_FULFILLED",
  TRAINING_SEARCH_ATHLETES_REJECTED: "TRAINING_SEARCH_ATHLETES_REJECTED",
  TRAINING_CLEAR_ATHLETES: "TRAINING_CLEAR_ATHLETES",
  ATHLETES_SEARCH_PHRASE_CHANGED: "ATHLETES_SEARCH_PHRASE_CHANGED",
  ATHLETES_SEARCH_FILTER_CHANGED: "ATHLETES_SEARCH_FILTER_CHANGED",
  ATHLETES_ADD_VISITED_PAGE: "ATHLETES_ADD_VISITED_PAGE",
  ATHLETES_SORT_CHANGED: "ATHLETES_SORT_CHANGED",
  ATHLETES_SEARCH: "ATHLETES_SEARCH",
  ATHLETES_DATA_FETCHING: "ATHLETES_DATA_FETCHING",
  ATHLETES_DATA_FULLFILLED: "ATHLETES_DATA_FULLFILLED",
  ATHLETES_DATA_REJECTED: "ATHLETES_DATA_REJECTED",
  ATHLETES_SEARCH_PENDING: "ATHLETES_SEARCH_PENDING",
  ATHLETES_SEARCH_FULFILLED: "ATHLETES_SEARCH_FULFILLED",
  ATHLETES_SEARCH_REJECTED: "ATHLETES_SEARCH_REJECTED",
  ATHLETES_CLEAN_SEARCH: "ATHLETES_CLEAN_SEARCH",
  ATHLETES_SET_ERROR_MESSAGE: "ATHLETES_SET_ERROR_MESSAGE",
  ATHLETES_CLEAR_ERROR_MESSAGE: "ATHLETES_CLEAR_ERROR_MESSAGE",
  ATHLETES_SET_SUCCESS_MESSAGE: "ATHLETES_SET_SUCCESS_MESSAGE",
  ATHLETES_CLEAR_SUCCESS_MESSAGE: "ATHLETES_CLEAR_SUCCESS_MESSAGE",
  ATHLETES_GET_COUNT: "ATHLETES_GET_COUNT",
  ATHLETES_GET_COUNT_PENDING: "ATHLETES_GET_COUNT_PENDING",
  ATHLETES_GET_COUNT_FULFILLED: "ATHLETES_GET_COUNT_FULFILLED",
  ATHLETES_GET_COUNT_REJECTED: "ATHLETES_GET_COUNT_REJECTED",
  ATHLETES_SELECT_PENDING: "ATHLETES_SELECT_PENDING",
  ATHLETES_SELECT_FULFILLED: "ATHLETES_SELECT_FULFILLED",
  ATHLETES_SELECT_REJECTED: "ATHLETES_SELECT_REJECTED",
  ATHLETES_SELECT_WORKOUT: "ATHLETES_SELECT_WORKOUT",
  ATHLETES_LOAD_COACHES: "ATHLETES_LOAD_COACHES",
  ATHLETES_LOAD_COACHES_PENDING: "ATHLETES_LOAD_COACHES_PENDING",
  ATHLETES_LOAD_COACHES_FULFILLED: "ATHLETES_LOAD_COACHES_FULFILLED",
  ATHLETES_LOAD_COACHES_REJECTED: "ATHLETES_LOAD_COACHES_REJECTED",
  ATHLETES_ASSIGN_COACH: "ATHLETES_ASSIGN_COACH",
  ATHLETES_ASSIGN_COACH_PENDING: "ATHLETES_ASSIGN_COACH_PENDING",
  ATHLETES_ASSIGN_COACH_FULFILLED: "ATHLETES_ASSIGN_COACH_FULFILLED",
  ATHLETES_ASSIGN_COACH_REJECTED: "ATHLETES_ASSIGN_COACH_REJECTED",
  ATHLETES_GET_OVERALL_WORKOUT_COUNT: "ATHLETES_GET_OVERALL_WORKOUT_COUNT",
  ATHLETES_GET_OVERALL_WORKOUT_COUNT_PENDING:
    "ATHLETES_GET_OVERALL_WORKOUT_COUNT_PENDING",
  ATHLETES_GET_OVERALL_WORKOUT_COUNT_FULFILLED:
    "ATHLETES_GET_OVERALL_WORKOUT_COUNT_FULFILLED",
  ATHLETES_GET_OVERALL_WORKOUT_COUNT_REJECTED:
    "ATHLETES_GET_OVERALL_WORKOUT_COUNT_REJECTED",
  ATHLETES_ASSIGN_TRAINING: "ATHLETES_ASSIGN_TRAINING",
  ATHLETES_ASSIGN_TRAINING_PENDING: "ATHLETES_ASSIGN_TRAINING_PENDING",
  ATHLETES_ASSIGN_TRAINING_FULFILLED: "ATHLETES_ASSIGN_TRAINING_FULFILLED",
  ATHLETES_ASSIGN_TRAINING_REJECTED: "ATHLETES_ASSIGN_TRAINING_REJECTED",
  ATHLETES_TRAINING_WEEK_CHANGED: "ATHLETES_TRAINING_WEEK_CHANGED",
  ATHLETES_TRAINING_SELECT_WORKOUT: "ATHLETES_TRAINING_SELECT_WORKOUT",
  ATHLETES_TRAINING_ADD_WORKOUT: "ATHLETES_TRAINING_ADD_WORKOUT",
  ATHLETES_TRAINING_REMOVE_WORKOUT: "ATHLETES_TRAINING_REMOVE_WORKOUT",
  ATHLETES_TRAINING_SAVE_PENDING: "ATHLETES_TRAINING_SAVE_PENDING",
  ATHLETES_TRAINING_SAVE_FULFILLED: "ATHLETES_TRAINING_SAVE_FULFILLED",
  ATHLETES_TRAINING_SAVE_REJECTED: "ATHLETES_TRAINING_SAVE_REJECTED",

  ATHLETES_CHAIN_TRAINING_PENDING: "ATHLETES_CHAIN_TRAINING_PENDING",
  ATHLETES_CHAIN_TRAINING_FULFILLED: "ATHLETES_CHAIN_TRAINING_FULFILLED",
  ATHLETES_CHAIN_TRAINING_REJECTED: "ATHLETES_CHAIN_TRAINING_REJECTED",

  ATHLETES_TRAINING_VIEWED_SAVE_PENDING:
    "ATHLETES_TRAINING_VIEWED_SAVE_PENDING",
  ATHLETES_TRAINING_VIEWED_SAVE_FULFILLED:
    "ATHLETES_TRAINING_VIEWED_SAVE_FULFILLED",
  ATHLETES_TRAINING_VIEWED_SAVE_REJECTED:
    "ATHLETES_TRAINING_VIEWED_SAVE_REJECTED",

  ATHLETES_TRAINING_RACES_SAVE_PENDING: "ATHLETES_TRAINING_RACES_SAVE_PENDING",
  ATHLETES_TRAINING_RACES_SAVE_FULFILLED:
    "ATHLETES_TRAINING_RACES_SAVE_FULFILLED",
  ATHLETES_TRAINING_RACES_SAVE_REJECTED:
    "ATHLETES_TRAINING_RACES_SAVE_REJECTED",

  ATHLETES_TRAINING_WORKOUT_COMMENT_SAVE_PENDING:
    "ATHLETES_TRAINING_WORKOUT_COMMENT_SAVE_PENDING",
  ATHLETES_TRAINING_WORKOUT_COMMENT_SAVE_FULFILLED:
    "ATHLETES_TRAINING_WORKOUT_COMMENT_SAVE_FULFILLED",
  ATHLETES_TRAINING_WORKOUT_COMMENT_SAVE_REJECTED:
    "ATHLETES_TRAINING_WORKOUT_COMMENT_SAVE_REJECTED",

  ATHLETES_TRAINING_BULK_MESSAGE_SAVE_PENDING:
    "ATHLETES_TRAINING_BULK_MESSAGE_SAVE_PENDING",
  ATHLETES_TRAINING_BULK_MESSAGE_SAVE_FULFILLED:
    "ATHLETES_TRAINING_BULK_MESSAGE_SAVE_FULFILLED",
  ATHLETES_TRAINING_BULK_MESSAGE_SAVE_REJECTED:
    "ATHLETES_TRAINING_BULK_MESSAGE_SAVE_REJECTED",

  ATHLETES_TRAINING_COMMENTS_PENDING: "ATHLETES_TRAINING_COMMENTS_PENDING",
  ATHLETES_TRAINING_COMMENTS_FULFILLED: "ATHLETES_TRAINING_COMMENTS_FULFILLED:",
  ATHLETES_TRAINING_COMMENTS_REJECTED: "ATHLETES_TRAINING_COMMENTS_REJECTED",

  ATHLETES_TRAINING_PLAN_PENDING: "ATHLETES_TRAINING_PLAN_PENDING",
  ATHLETES_TRAINING_PLAN_FULFILLED: "ATHLETES_TRAINING_PLAN_FULFILLED",
  ATHLETES_TRAINING_PLAN_REJECTED: "ATHLETES_TRAINING_PLAN_REJECTED",

  ATHLETES_TRAINING_WORKOUT_COMMENT_DELETE_PENDING:
    "ATHLETES_TRAINING_WORKOUT_COMMENT_DELETE_PENDING",
  ATHLETES_TRAINING_WORKOUT_COMMENT_DELETE_FULFILLED:
    "ATHLETES_TRAINING_WORKOUT_COMMENT_DELETE_FULFILLED",
  ATHLETES_TRAINING_WORKOUT_COMMENT_DELETE_REJECTED:
    "ATHLETES_TRAINING_WORKOUT_COMMENT_DELETE_REJECTED",

  ATHLETES_TRAINING_WORKOUT_COMMENT_EDIT_PENDING:
    "ATHLETES_TRAINING_WORKOUT_COMMENT_EDIT_PENDING",
  ATHLETES_TRAINING_WORKOUT_COMMENT_EDIT_FULFILLED:
    "ATHLETES_TRAINING_WORKOUT_COMMENT_EDIT_FULFILLED",
  ATHLETES_TRAINING_WORKOUT_COMMENT_EDIT_REJECTED:
    "ATHLETES_TRAINING_WORKOUT_COMMENT_EDIT_REJECTED",

  ATHLETES_TRAINING_WORKOUT_COMMENTS_READ_SAVE_PENDING:
    "ATHLETES_TRAINING_WORKOUT_COMMENTS_READ_SAVE_PENDING",
  ATHLETES_TRAINING_WORKOUT_COMMENTS_READ_SAVE_FULFILLED:
    "ATHLETES_TRAINING_WORKOUT_COMMENTS_READ_SAVE_FULFILLED",
  ATHLETES_TRAINING_WORKOUT_COMMENTS_READ_SAVE_REJECTED:
    "ATHLETES_TRAINING_WORKOUT_COMMENTS_READ_SAVE_REJECTED",

  ATHLETES_TRAINING_GET_OLD_COMMENTS_PENDING:
    "ATHLETES_TRAINING_GET_OLD_COMMENTS_PENDING",
  ATHLETES_TRAINING_GET_OLD_COMMENTS_FULFILLED:
    "ATHLETES_TRAINING_GET_OLD_COMMENTS_FULFILLED",
  ATHLETES_TRAINING_GET_OLD_COMMENTS_REJECTED:
    "ATHLETES_TRAINING_GET_OLD_COMMENTS_REJECTED",

  ATHLETES_TRAINING_WORKOUT_EDIT_PENDING:
    "ATHLETES_TRAINING_WORKOUT_EDIT_PENDING",
  ATHLETES_TRAINING_WORKOUT_EDIT_FULFILLED:
    "ATHLETES_TRAINING_WORKOUT_EDIT_FULFILLED",
  ATHLETES_TRAINING_WORKOUT_EDIT_REJECTED:
    "ATHLETES_TRAINING_WORKOUT_EDIT_REJECTED",

  ATHLETES_TRAINING_WORKOUT_ADD_PENDING:
    "ATHLETES_TRAINING_WORKOUT_ADD_PENDING",
  ATHLETES_TRAINING_WORKOUT_ADD_FULFILLED:
    "ATHLETES_TRAINING_WORKOUT_ADD_FULFILLED",
  ATHLETES_TRAINING_WORKOUT_ADD_REJECTED:
    "ATHLETES_TRAINING_WORKOUT_ADD_REJECTED",

  ATHLETES_TRAINING_WORKOUT_DELETE_PENDING:
    "ATHLETES_TRAINING_WORKOUT_DELETE_PENDING",
  ATHLETES_TRAINING_WORKOUT_DELETE_FULFILLED:
    "ATHLETES_TRAINING_WORKOUT_DELETE_FULFILLED",
  ATHLETES_TRAINING_WORKOUT_DELETE_REJECTED:
    "ATHLETES_TRAINING_WORKOUT_DELETE_REJECTED",

  ATHLETES_TRAINING_WEEK_ADD_PENDING: "ATHLETES_TRAINING_WEEK_ADD_PENDING",
  ATHLETES_TRAINING_WEEK_ADD_FULFILLED: "ATHLETES_TRAINING_WEEK_ADD_FULFILLED",
  ATHLETES_TRAINING_WEEK_ADD_REJECTED: "ATHLETES_TRAINING_WEEK_ADD_REJECTED",

  ATHLETES_TRAINING_WEEK_EASY_ADD_PENDING:
    "ATHLETES_TRAINING_WEEK_EASY_ADD_PENDING",
  ATHLETES_TRAINING_WEEK_EASY_ADD_FULFILLED:
    "ATHLETES_TRAINING_WEEK_EASY_ADD_FULFILLED",
  ATHLETES_TRAINING_WEEK_EASY_ADD_REJECTED:
    "ATHLETES_TRAINING_WEEK_EASY_ADD_REJECTED",

  ATHLETES_TRAINING_BLOCK_ADD_PENDING: "ATHLETES_TRAINING_BLOCK_ADD_PENDING",
  ATHLETES_TRAINING_BLOCK_ADD_FULFILLED:
    "ATHLETES_TRAINING_BLOCK_ADD_FULFILLED",
  ATHLETES_TRAINING_BLOCK_ADD_REJECTED: "ATHLETES_TRAINING_BLOCK_ADD_REJECTED",

  ATHLETES_TRAINING_WEEK_REMOVE_PENDING:
    "ATHLETES_TRAINING_WEEK_REMOVE_PENDING",
  ATHLETES_TRAINING_WEEK_REMOVE_FULFILLED:
    "ATHLETES_TRAINING_WEEK_REMOVE_FULFILLED",
  ATHLETES_TRAINING_WEEK_REMOVE_REJECTED:
    "ATHLETES_TRAINING_WEEK_REMOVE_REJECTED",

  ATHLETES_TRAINING_CHAINED_REMOVE_PENDING:
    "ATHLETES_TRAINING_CHAINED_REMOVE_PENDING",
  ATHLETES_TRAINING_CHAINED_REMOVE_FULFILLED:
    "ATHLETES_TRAINING_CHAINED_REMOVE_FULFILLED",
  ATHLETES_TRAINING_CHAINED_REMOVE_REJECTED:
    "ATHLETES_TRAINING_CHAINED_REMOVE_REJECTED",

  ATHLETES_TRAINING_SWAP_PENDING: "ATHLETES_TRAINING_SWAP_PENDING",
  ATHLETES_TRAINING_SWAP_FULFILLED: "ATHLETES_TRAINING_SWAP_FULFILLED",
  ATHLETES_TRAINING_SWAP_REJECTED: "ATHLETES_TRAINING_SWAP_REJECTED",

  ATHLETES_TRAINING_WORKOUT_FEEDBACK_SAVE:
    "ATHLETES_TRAINING_WORKOUT_FEEDBACK_SAVE",
  ATHLETES_LOAD_ACTIVITIES: "ATHLETES_LOAD_ACTIVITIES",
  ATHLETES_LOAD_ACTIVITIES_PENDING: "ATHLETES_LOAD_ACTIVITIES_PENDING",
  ATHLETES_LOAD_ACTIVITIES_FULFILLED: "ATHLETES_LOAD_ACTIVITIES_FULFILLED",
  ATHLETES_LOAD_ACTIVITIES_REJECTED: "ATHLETES_LOAD_ACTIVITIES_REJECTED",
  ATHLETES_LOAD_IMAGE_URL: "ATHLETES_LOAD_IMAGE_URL",
  ATHLETES_LOAD_IMAGE_URL_PENDING: "ATHLETES_LOAD_IMAGE_URL_PENDING",
  ATHLETES_LOAD_IMAGE_URL_FULFILLED: "ATHLETES_LOAD_IMAGE_URL_FULFILLED",
  ATHLETES_LOAD_IMAGE_URL_REJECTED: "ATHLETES_LOAD_IMAGE_URL_REJECTED",

  ATHLETES_GET_RECENT_DATA_PENDING: "ATHLETES_GET_RECENT_DATA_PENDING",
  ATHLETES_GET_RECENT_DATA_FULFILLED: "ATHLETES_GET_RECENT_DATA_FULFILLED",
  ATHLETES_GET_RECENT_DATA_REJECTED: "ATHLETES_GET_RECENT_DATA_REJECTED",

  ATHLETES_GET_DATA_PENDING: "ATHLETES_GET_DATA_PENDING",
  ATHLETES_GET_DATA_FULFILLED: "ATHLETES_GET_DATA_FULFILLED",
  ATHLETES_GET_DATA_REJECTED: "ATHLETES_GET_DATA_REJECTED",

  ATHLETES_GET_EVENTS_PENDING: "ATHLETES_GET_EVENTS_PENDING",
  ATHLETES_GET_EVENTS_FULFILLED: "ATHLETES_GET_EVENTS_FULFILLED",
  ATHLETES_GET_EVENTS_REJECTED: "ATHLETES_GET_EVENTS_REJECTED",

  ATHLETES_CLEAR_ATHLETE: "ATHLETES_CLEAR_ATHLETE",
  ATHLETES_UPDATE_LAST_VIEWED: "ATHLETES_UPDATE_LAST_VIEWED",

  STRAVA_INIT: "STRAVA_INIT",
  STRAVA_INIT_PENDING: "STRAVA_INIT_PENDING",
  STRAVA_INIT_FULFILLED: "STRAVA_INIT_FULFILLED",
  STRAVA_INIT_REJECTED: "STRAVA_INIT_REJECTED",
  STRAVA_LOAD_ACTIVITIES: "STRAVA_LOAD_ACTIVITIES",
  STRAVA_LOAD_ACTIVITIES_PENDING: "STRAVA_LOAD_ACTIVITIES_PENDING",
  STRAVA_LOAD_ACTIVITIES_FULFILLED: "STRAVA_LOAD_ACTIVITIES_FULFILLED",
  STRAVA_LOAD_ACTIVITIES_REJECTED: "STRAVA_LOAD_ACTIVITIES_REJECTED",

  STRAVA_GET_ACTIVITY_PENDING: "STRAVA_GET_ACTIVITY_PENDING",
  STRAVA_GET_ACTIVITY_FULFILLED: "STRAVA_GET_ACTIVITY_FULFILLED",
  STRAVA_GET_ACTIVITY_REJECTED: "STRAVA_GET_ACTIVITY_REJECTED",
  STRAVA_CLEAR_ACTIVITY: "STRAVA_CLEAR_ACTIVITY",
  STRAVA_CLEAR_ACTIVITIES: "STRAVA_CLEAR_ACTIVITIES",

  VERT_LOAD_ACTIVITIES: "VERT_LOAD_ACTIVITIES",
  VERT_LOAD_ACTIVITIES_PENDING: "VERT_LOAD_ACTIVITIES_PENDING",
  VERT_LOAD_ACTIVITIES_FULFILLED: "VERT_LOAD_ACTIVITIES_FULFILLED",
  VERT_LOAD_ACTIVITIES_REJECTED: "VERT_LOAD_ACTIVITIES_REJECTED",

  VERT_GET_ACTIVITY_PENDING: "VERT_GET_ACTIVITY_PENDING",
  VERT_GET_ACTIVITY_FULFILLED: "VERT_GET_ACTIVITY_FULFILLED",
  VERT_GET_ACTIVITY_REJECTED: "VERT_GET_ACTIVITY_REJECTED",

  VERT_ADD_ACTIVITY_PENDING: "VERT_ADD_ACTIVITY_PENDING",
  VERT_ADD_ACTIVITY_FULFILLED: "VERT_ADD_ACTIVITY_FULFILLED",
  VERT_ADD_ACTIVITY_REJECTED: "VERT_ADD_ACTIVITY_REJECTED",

  VERT_CLEAR_ACTIVITY: "VERT_CLEAR_ACTIVITY",
  VERT_SET_ACTIVITY: "VERT_SET_ACTIVITY",
  VERT_SET_WATCHER: "VERT_SET_WATCHER",
  VERT_CLEAR_WATCHERS: "VERT_CLEAR_WATCHERS",

  VERT_CLEAR_ACTIVITIES: "VERT_CLEAR_ACTIVITIES",

  SUBSCRIPTIONS_LATEST: "SUBSCRIPTIONS_LATEST",
  SUBSCRIPTIONS_LATEST_PENDING: "SUBSCRIPTIONS_LATEST_PENDING",
  SUBSCRIPTIONS_LATEST_FULFILLED: "SUBSCRIPTIONS_LATEST_FULFILLED",
  SUBSCRIPTIONS_LATEST_REJECTED: "SUBSCRIPTIONS_LATEST_REJECTED",
  SUBSCRIPTIONS_SET_ERROR_MESSAGE: "SUBSCRIPTIONS_SET_ERROR_MESSAGE",
  SUBSCRIPTIONS_CLEAR_ERROR_MESSAGE: "SUBSCRIPTIONS_CLEAR_ERROR_MESSAGE",
  SUBSCRIPTIONS_SET_SUCCESS_MESSAGE: "SUBSCRIPTIONS_SET_SUCCESS_MESSAGE",
  SUBSCRIPTIONS_CLEAR_SUCCESS_MESSAGE: "SUBSCRIPTIONS_CLEAR_SUCCESS_MESSAGE",

  GARMIN_INIT: "GARMIN_INIT",
  GARMIN_INIT_REJECTED: "GARMIN_INIT_REJECTED",
  GARMIN_INIT_PENDING: "GARMIN_INIT_PENDING",
  GARMIN_INIT_FULFILLED: "GARMIN_INIT_FULFILLED",
  GARMIN_CLEAR_ACTIVITIES: "GARMIN_CLEAR_ACTIVITIES",

  GARMIN_LOAD_ACTIVITIES_PENDING: "GARMIN_LOAD_ACTIVITIES_PENDING",
  GARMIN_LOAD_ACTIVITIES_FULFILLED: "GARMIN_LOAD_ACTIVITIES_FULFILLED",
  GARMIN_LOAD_ACTIVITIES_REJECTED: "GARMIN_LOAD_ACTIVITIES_REJECTED",

  GARMIN_GET_ACTIVITY_PENDING: "GARMIN_GET_ACTIVITY_PENDING",
  GARMIN_GET_ACTIVITY_FULFILLED: "GARMIN_GET_ACTIVITY_FULFILLED",
  GARMIN_GET_ACTIVITY_REJECTED: "GARMIN_GET_ACTIVITY_REJECTED",
  GARMIN_CLEAR_ACTIVITY: "GARMIN_CLEAR_ACTIVITY",

  GARMIN_DELETE: "GARMIN_DELETE",
  GARMIN_BACKFILL: "GARMIN_BACKFILL",

  SUUNTO_INIT: "SUUNTO_INIT",
  SUUNTO_INIT_REJECTED: "SUUNTO_INIT_REJECTED",
  SUUNTO_INIT_PENDING: "SUUNTO_INIT_PENDING",
  SUUNTO_INIT_FULFILLED: "SUUNTO_INIT_FULFILLED",

  SUUNTO_LOAD_ACTIVITIES_PENDING: "SUUNTO_LOAD_ACTIVITIES_PENDING",
  SUUNTO_LOAD_ACTIVITIES_FULFILLED: "SUUNTO_LOAD_ACTIVITIES_FULFILLED",
  SUUNTO_LOAD_ACTIVITIES_REJECTED: "SUUNTO_LOAD_ACTIVITIES_REJECTED",

  SUUNTO_GET_ACTIVITY_PENDING: "SUUNTO_GET_ACTIVITY_PENDING",
  SUUNTO_GET_ACTIVITY_FULFILLED: "SUUNTO_GET_ACTIVITY_FULFILLED",
  SUUNTO_GET_ACTIVITY_REJECTED: "SUUNTO_GET_ACTIVITY_REJECTED",
  SUUNTO_CLEAR_ACTIVITY: "SUUNTO_CLEAR_ACTIVITY",
  SUUNTO_CLEAR_ACTIVITIES: "SUUNTO_CLEAR_ACTIVITIES",

  SUUNTO_DELETE: "SUUNTO_DELETE",
  SUUNTO_BACKFILL: "SUUNTO_BACKFILL",

  MOBILE_PRODUCTS: "MOBILE_PRODUCTS",
  OWNED_MOBILE_PRODUCT: "OWNED_MOBILE_PRODUCT",

  PUSHER_CHANNEL_SUBSCRIPTION: "PUSHER_CHANNEL_SUBSCRIPTION",
  PUSHER_CHANNEL_SUBSCRIPTION_PENDING: "PUSHER_CHANNEL_SUBSCRIPTION_PENDING",
  PUSHER_CHANNEL_SUBSCRIPTION_FULFILLED:
    "PUSHER_CHANNEL_SUBSCRIPTION_FULFILLED",
  PUSHER_CHANNEL_SUBSCRIPTION_REJECTED: "PUSHER_CHANNEL_SUBSCRIPTION_REJECTED",
  PUSHER_CHANNEL_UNSUBSCRIPTION: "PUSHER_CHANNEL_UNSUBSCRIPTION",
  PUSHER_CHANNEL_UNSUBSCRIPTION_PENDING:
    "PUSHER_CHANNEL_UNSUBSCRIPTION_PENDING",
  PUSHER_CHANNEL_UNSUBSCRIPTION_FULFILLED:
    "PUSHER_CHANNEL_UNSUBSCRIPTION_FULFILLED",
  PUSHER_CHANNEL_UNSUBSCRIPTION_REJECTED:
    "PUSHER_CHANNEL_UNSUBSCRIPTION_REJECTED",
  NOTIFICATION_UPDATE_CHANGES: "NOTIFICATION_UPDATE_CHANGES",
  NOTIFICATION_CLEAR_CHANGES: "NOTIFICATION_CLEAR_CHANGES",
  NOTIFICATION_LOAD: "NOTIFICATION_LOAD",
  NOTIFICATION_LOAD_PENDING: "NOTIFICATION_LOAD_PENDING",
  NOTIFICATION_LOAD_FULFILLED: "NOTIFICATION_LOAD_FULFILLED",
  NOTIFICATION_LOAD_REJECTED: "NOTIFICATION_LOAD_REJECTED",
  NOTIFICATION_SAVE: "NOTIFICATION_SAVE",
  NOTIFICATION_SAVE_PENDING: "NOTIFICATION_SAVE_PENDING",
  NOTIFICATION_SAVE_FULFILLED: "NOTIFICATION_SAVE_FULFILLED",
  NOTIFICATION_SAVE_REJECTED: "NOTIFICATION_SAVE_REJECTED",
  NOTIFICATION_UPDATE: "NOTIFICATION_UPDATE",
  NOTIFICATION_UPDATE_PENDING: "NOTIFICATION_UPDATE_PENDING",
  NOTIFICATION_UPDATE_FULFILLED: "NOTIFICATION_UPDATE_FULFILLED",
  NOTIFICATION_UPDATE_REJECTED: "NOTIFICATION_UPDATE_REJECTED",
  SUBCATEGORIES_GET: "SUBCATEGORIES_GET",
  SUBCATEGORIES_GET_PENDING: "SUBCATEGORIES_GET_PENDING",
  SUBCATEGORIES_GET_FULFILLED: "SUBCATEGORIES_GET_FULFILLED",
  SUBCATEGORIES_GET_REJECTED: "SUBCATEGORIES_GET_REJECTED",

  SUBCATEGORIES_GET_SAMPLE: "SUBCATEGORIES_GET_SAMPLE",
  SUBCATEGORIES_GET_SAMPLE_PENDING: "SUBCATEGORIES_GET_SAMPLE_PENDING",
  SUBCATEGORIES_GET_SAMPLE_FULFILLED: "SUBCATEGORIES_GET_SAMPLE_FULFILLED",
  SUBCATEGORIES_GET_SAMPLE_REJECTED: "SUBCATEGORIES_GET_SAMPLE_REJECTED",

  SUBCATEGORIES_CLEAR: "SUBCATEGORIES_CLEAR",
  PUSH_ANALYTIC_DATA_PENDING: "PUSH_ANALYTIC_DATA_PENDING",
  PUSH_ANALYTIC_DATA_FULFILLED: "PUSH_ANALYTIC_DATA_FULFILLED",
  PUSH_ANALYTIC_DATA_REJECTED: "PUSH_ANALYTIC_DATA_REJECTED",

  TRAIL_HEAD_GET_ATHLETE_PENDING: "TRAIL_HEAD_GET_ATHLETE_PENDING",
  TRAIL_HEAD_GET_ATHLETE_FULFILLED: "TRAIL_HEAD_GET_ATHLETE_FULFILLED",
  TRAIL_HEAD_GET_ATHLETE_REJECTED: "TRAIL_HEAD_GET_ATHLETE_REJECTED",

  TRAIL_HEAD_UPDATE_PROFILE_PENDING: "TRAIL_HEAD_UPDATE_PROFILE_PENDING",
  TRAIL_HEAD_UPDATE_PROFILE_FULFILLED: "TRAIL_HEAD_UPDATE_PROFILE_FULFILLED",
  TRAIL_HEAD_UPDATE_PROFILE_REJECTED: "TRAIL_HEAD_UPDATE_PROFILE_REJECTED",

  TRAIL_HEAD_LOAD_IMAGE_URL_PENDING: "TRAIL_HEAD_LOAD_IMAGE_URL_PENDING",
  TRAIL_HEAD_LOAD_IMAGE_URL_FULFILLED: "TRAIL_HEAD_LOAD_IMAGE_URL_FULFILLED",
  TRAIL_HEAD_LOAD_IMAGE_URL_REJECTED: "TRAIL_HEAD_LOAD_IMAGE_URL_REJECTED",

  TRAIL_HEAD_GET_TAGS_PENDING: "TRAIL_HEAD_GET_TAGS_PENDING",
  TRAIL_HEAD_GET_TAGS_FULFILLED: "TRAIL_HEAD_GET_TAGS_FULFILLED",
  TRAIL_HEAD_GET_TAGS_REJECTED: "TRAIL_HEAD_GET_TAGS_REJECTED",

  TRAIL_HEAD_GET_PUBLIC_GROUPS_PENDING: "TRAIL_HEAD_GET_PUBLIC_GROUPS_PENDING",
  TRAIL_HEAD_GET_PUBLIC_GROUPS_FULFILLED:
    "TRAIL_HEAD_GET_PUBLIC_GROUPS_FULFILLED",
  TRAIL_HEAD_GET_PUBLIC_GROUPS_REJECTED:
    "TRAIL_HEAD_GET_PUBLIC_GROUPS_REJECTED",

  TRAIL_HEAD_JOIN_GROUP_PENDING: "TRAIL_HEAD_JOIN_GROUP_PENDING",
  TRAIL_HEAD_JOIN_GROUP_FULFILLED: "TRAIL_HEAD_JOIN_GROUP_FULFILLED",
  TRAIL_HEAD_JOIN_GROUP_REJECTED: "TRAIL_HEAD_JOIN_GROUP_REJECTED",

  TRAIL_HEAD_LEAVE_GROUP_PENDING: "TRAIL_HEAD_LEAVE_GROUP_PENDING",
  TRAIL_HEAD_LEAVE_GROUP_FULFILLED: "TRAIL_HEAD_LEAVE_GROUP_FULFILLED",
  TRAIL_HEAD_LEAVE_GROUP_REJECTED: "TRAIL_HEAD_LEAVE_GROUP_REJECTED",

  TRAIL_HEAD_ADD_GROUP_ACTIVITIES_PENDING:
    "TRAIL_HEAD_ADD_GROUP_ACTIVITIES_PENDING",
  TRAIL_HEAD_ADD_GROUP_ACTIVITIES_FULFILLED:
    "TRAIL_HEAD_ADD_GROUP_ACTIVITIES_FULFILLED",
  TRAIL_HEAD_ADD_GROUP_ACTIVITIES_REJECTED:
    "TRAIL_HEAD_ADD_GROUP_ACTIVITIES_REJECTED",

  TRAIL_HEAD_GET_GROUP_PENDING: "TRAIL_HEAD_GET_GROUP_PENDING",
  TRAIL_HEAD_GET_GROUP_FULFILLED: "TRAIL_HEAD_GET_GROUP_FULFILLED",
  TRAIL_HEAD_GET_GROUP_REJECTED: "TRAIL_HEAD_GET_GROUP_REJECTED",

  TRAIL_HEAD_CLEAR_GROUP: "TRAIL_HEAD_CLEAR_GROUP",

  TRAIL_HEAD_NEW_POST: "TRAIL_HEAD_NEW_POST",
  TRAIL_HEAD_CLEAN_NEW_POST: "TRAIL_HEAD_CLEAN_NEW_POST",

  TRAIL_HEAD_ADD_POST_PENDING: "TRAIL_HEAD_ADD_POST_PENDING",
  TRAIL_HEAD_ADD_POST_FULFILLED: "TRAIL_HEAD_ADD_POST_FULFILLED",
  TRAIL_HEAD_ADD_POST_REJECTED: "TRAIL_HEAD_ADD_POST_REJECTED",

  TRAIL_HEAD_EDIT_POST_PENDING: "TRAIL_HEAD_EDIT_POST_PENDING",
  TRAIL_HEAD_EDIT_POST_FULFILLED: "TRAIL_HEAD_EDIT_POST_FULFILLED",
  TRAIL_HEAD_EDIT_POST_REJECTED: "TRAIL_HEAD_EDIT_POST_REJECTED",

  TRAIL_HEAD_DELETE_POST_PENDING: "TRAIL_HEAD_DELETE_POST_PENDING",
  TRAIL_HEAD_DELETE_POST_FULFILLED: "TRAIL_HEAD_DELETE_POST_FULFILLED",
  TRAIL_HEAD_DELETE_POST_REJECTED: "TRAIL_HEAD_DELETE_POST_REJECTED",

  TRAIL_HEAD_GET_POSTS_PENDING: "TRAIL_HEAD_GET_POSTS_PENDING",
  TRAIL_HEAD_GET_POSTS_FULFILLED: "TRAIL_HEAD_GET_POSTS_FULFILLED",
  TRAIL_HEAD_GET_POSTS_REJECTED: "TRAIL_HEAD_GET_POSTS_REJECTED",

  TRAIL_HEAD_GET_GROUP_POSTS_PENDING: "TRAIL_HEAD_GET_GROUP_POSTS_PENDING",
  TRAIL_HEAD_GET_GROUP_POSTS_FULFILLED: "TRAIL_HEAD_GET_GROUP_POSTS_FULFILLED",
  TRAIL_HEAD_GET_GROUP_POSTS_REJECTED: "TRAIL_HEAD_GET_GROUP_POSTS_REJECTED",

  TRAIL_HEAD_GET_PINNED_POSTS_PENDING: "TRAIL_HEAD_GET_PINNED_POSTS_PENDING",
  TRAIL_HEAD_GET_PINNED_POSTS_FULFILLED:
    "TRAIL_HEAD_GET_PINNED_POSTS_FULFILLED",
  TRAIL_HEAD_GET_PINNED_POSTS_REJECTED: "TRAIL_HEAD_GET_PINNED_POSTS_REJECTED",

  TRAIL_HEAD_GET_POST_PENDING: "TRAIL_HEAD_GET_POST_PENDING",
  TRAIL_HEAD_GET_POST_FULFILLED: "TRAIL_HEAD_GET_POST_FULFILLED",
  TRAIL_HEAD_GET_POST_REJECTED: "TRAIL_HEAD_GET_POST_REJECTED",

  TRAIL_HEAD_GET_EXPLORE_POSTS_PENDING: "TRAIL_HEAD_GET_EXPLORE_POSTS_PENDING",
  TRAIL_HEAD_GET_EXPLORE_POSTS_FULFILLED:
    "TRAIL_HEAD_GET_EXPLORE_POSTS_FULFILLED",
  TRAIL_HEAD_GET_EXPLORE_POSTS_REJECTED:
    "TRAIL_HEAD_GET_EXPLORE_POSTS_REJECTED",

  TRAIL_HEAD_GET_EXPLORE_POST_PENDING: "TRAIL_HEAD_GET_EXPLORE_POST_PENDING",
  TRAIL_HEAD_GET_EXPLORE_POST_FULFILLED:
    "TRAIL_HEAD_GET_EXPLORE_POST_FULFILLED",
  TRAIL_HEAD_GET_EXPLORE_POST_REJECTED: "TRAIL_HEAD_GET_EXPLORE_POST_REJECTED",

  TRAIL_HEAD_GET_EXPLORE_FEATURED_POST_PENDING:
    "TRAIL_HEAD_GET_EXPLORE_FEATURED_POST_PENDING",
  TRAIL_HEAD_GET_EXPLORE_FEATURED_POST_FULFILLED:
    "TRAIL_HEAD_GET_EXPLORE_FEATURED_POST_FULFILLED",
  TRAIL_HEAD_GET_EXPLORE_FEATURED_POST_REJECTED:
    "TRAIL_HEAD_GET_EXPLORE_FEATURED_POST_REJECTED",

  TRAIL_HEAD_GET_RELATED_EXPLORE_POST_PENDING:
    "TRAIL_HEAD_GET_RELATED_EXPLORE_POST_PENDING",
  TRAIL_HEAD_GET_RELATED_EXPLORE_POST_FULFILLED:
    "TRAIL_HEAD_GET_RELATED_EXPLORE_POST_FULFILLED",
  TRAIL_HEAD_GET_RELATED_EXPLORE_POST_REJECTED:
    "TRAIL_HEAD_GET_RELATED_EXPLORE_POST_REJECTED",

  TRAIL_HEAD_ADD_ALLEZ_PENDING: "TRAIL_HEAD_ADD_ALLEZ_PENDING",
  TRAIL_HEAD_ADD_ALLEZ_FULFILLED: "TRAIL_HEAD_ADD_ALLEZ_FULFILLED",
  TRAIL_HEAD_ADD_ALLEZ_REJECTED: "TRAIL_HEAD_ADD_ALLEZ_REJECTED",

  TRAIL_HEAD_ADD_REPORT_PENDING: "TRAIL_HEAD_ADD_REPORT_PENDING",
  TRAIL_HEAD_ADD_REPORT_FULFILLED: "TRAIL_HEAD_ADD_REPORT_FULFILLED",
  TRAIL_HEAD_ADD_REPORT_REJECTED: "TRAIL_HEAD_ADD_REPORT_REJECTED",

  TRAIL_HEAD_ADD_COMMENT_PENDING: "TRAIL_HEAD_ADD_COMMENT_PENDING",
  TRAIL_HEAD_ADD_COMMENT_FULFILLED: "TRAIL_HEAD_ADD_COMMENT_FULFILLED",
  TRAIL_HEAD_ADD_COMMENT_REJECTED: "TRAIL_HEAD_ADD_COMMENT_REJECTED",

  TRAIL_HEAD_DELETE_COMMENT_PENDING: "TRAIL_HEAD_DELETE_COMMENT_PENDING",
  TRAIL_HEAD_DELETE_COMMENT_FULFILLED: "TRAIL_HEAD_DELETE_COMMENT_FULFILLED",
  TRAIL_HEAD_DELETE_COMMENT_REJECTED: "TRAIL_HEAD_DELETE_COMMENT_REJECTED",

  TRAIL_HEAD_TOGGLE_FAVORITES: "TRAIL_HEAD_TOGGLE_FAVORITES",

  CHALLENGES_GET_PENDING: "CHALLENGES_GET_PENDING",
  CHALLENGES_GET_FULFILLED: "CHALLENGES_GET_FULFILLED",
  CHALLENGES_GET_REJECTED: "CHALLENGES_GET_REJECTED",

  CHALLENGES_LEADERS_GET_PENDING: "CHALLENGES_LEADERS_GET_PENDING",
  CHALLENGES_LEADERS_GET_FULFILLED: "CHALLENGES_LEADERS_GET_FULFILLED",
  CHALLENGES_LEADERS_GET_REJECTED: "CHALLENGES_LEADERS_GET_REJECTED",
  CHALLENGES_LEADERS_CLEAR: "CHALLENGES_LEADERS_CLEAR",

  CHALLENGES_GET_OLD_PENDING: "CHALLENGES_GET_OLD_PENDING",
  CHALLENGES_GET_OLD_FULFILLED: "CHALLENGES_GET_OLD_FULFILLED",
  CHALLENGES_GET_OLD_REJECTED: "CHALLENGES_GET_OLD_REJECTED",

  CHALLENGE_JOIN_PENDING: "CHALLENGE_JOIN_PENDING",
  CHALLENGE_JOIN_FULFILLED: "CHALLENGE_JOIN_FULFILLED",
  CHALLENGE_JOIN_REJECTED: "CHALLENGE_JOIN_REJECTED",

  UPDATE_JOINED_CHALLENGE_PENDING: "UPDATE_JOINED_CHALLENGE_PENDING",
  UPDATE_JOINED_CHALLENGE_FULFILLED: "UPDATE_JOINED_CHALLENGE_FULFILLED",
  UPDATE_JOINED_CHALLENGE_REJECTED: "UPDATE_JOINED_CHALLENGE_REJECTED",

  ATHLETES_CHANGE_TRAINING_CHECK_PENDING:
    "ATHLETES_CHANGE_TRAINING_CHECK_PENDING",
  ATHLETES_CHANGE_TRAINING_CHECK_FULFILLED:
    "ATHLETES_CHANGE_TRAINING_CHECK_FULFILLED",
  ATHLETES_CHANGE_TRAINING_CHECK_REJECTED:
    "ATHLETES_CHANGE_TRAINING_CHECK_REJECTED",

  ATHLETES_CHANGE_TRAINING_NEEDSTOCHECK_PENDING:
    "ATHLETES_CHANGE_TRAINING_NEEDSTOCHECK_PENDING",
  ATHLETES_CHANGE_TRAINING_NEEDSTOCHECK_FULFILLED:
    "ATHLETES_CHANGE_TRAINING_NEEDSTOCHECK_FULFILLED",
  ATHLETES_CHANGE_TRAINING_NEEDSTOCHECK_REJECTED:
    "ATHLETES_CHANGE_TRAINING_NEEDSTOCHECK_REJECTED",

  ATHLETES_UPDATE_LOCALLY: "ATHLETES_UPDATE_LOCALLY",

  GET_EPIC_RACES_PENDING: "GET_EPIC_RACES_PENDING",
  GET_EPIC_RACES_FULFILLED: "GET_EPIC_RACES_FULFILLED",
  GET_EPIC_RACES_REJECTED: "GET_EPIC_RACES_REJECTED",

  TRAIL_HEAD_GET_USER_POSTS_PENDING: "TRAIL_HEAD_GET_EPIC_RACES_PENDING",
  TRAIL_HEAD_GET_USER_POSTS_FULFILLED: "TRAIL_HEAD_GET_USER_POSTS_FULFILLED",
  TRIAL_HEAD_GET_USER_POSTS_REJECTED: "TRAIL_HEAD_GET_USER_POSTS_REJECTED",

  CHALLENGE_GET_USER_BADGES_PENDING: "CHALLENGE_GET_USER_BADGES_PENDING",
  CHALLENGE_GET_USER_BADGES_FULFILLED: "CHALLENGE_GET_USER_BADGES_FULFILLED",
  CHALLENGE_GET_USER_BADGES_REJECTED: "CHALLENGE_GET_USER_BADGES_REJECTED",

  TRAIL_HEAD_GET_USER_ACTIVITY_PENDING: "TRAIL_HEAD_GET_USER_ACTIVITY_PENDING",
  TRAIL_HEAD_GET_USER_ACTIVITY_FULFILLED:
    "TRAIL_HEAD_GET_USER_ACTIVITY_FULFILLED",
  TRAIL_HEAD_GET_USER_ACTIVITY_REJECTED:
    "TRAIL_HEAD_GET_USER_ACTIVITY_REJECTED",

  UPDATE_SELECTED_ATHLETE_PENDING: "UPDATE_SELECTED_ATHLETE_PENDING",
  UPDATE_SELECTED_ATHLETE_FULLFILLED: "UPDATE_SELECTED_ATHLETE_FULLFILLED",

  UPDATE_VERT_ACTIVITY_PENDING: "UPDATE_VERT_ACTIVITY_PENDING",
  UPDATE_VERT_ACTIVITY_FULLFILLED: "UPDATE_VERT_ACTIVITY_FULLFILLED",
  UPDATE_VERT_ACTIVITY_REJECTED: "UPDATE_VERT_ACTIVITY_REJECTED",
  UPDATE_NAVBAR: "UPDATE_NAVBAR",

  FROM_TRACKER_STATUS: "FROM_TRACKER_STATUS",

  CLEAR_CURRENT_TEMP_TRAINING: "CLEAR_CURRENT_TEMP_TRAINING",

  POST_CONTACT_FORM_PENDING: "POST_CONTACT_FORM_PENDING",
  POST_CONTACT_FORM_FULLFILLED: "POST_CONTACT_FORM_FULLFILLED",
  POST_CONTACT_FORM_REJECTED: "POST_CONTACT_FORM_REJECTED",

  CLEAR_CONTACT_FORM: "CLEAR_CONTACT_FORM",

  GET_ACTIVITIES_PENDING: "GET_ACTIVITIES_PENDING",
  GET_ACTIVITIES_FULLFILLED: "GET_ACTIVITIES_FULLFILLED",
  GET_ACTIVITIES_REJECTED: "GET_ACTIVITIES_REJECTED",

  CLEAR_CURRENT_ACTIVITY_PENDING: "CLEAR_CURRENT_ACTIVITY_PENDING",
  CLEAR_CURRENT_ACTIVITY_FULLFILLED: "CLEAR_CURRENT_ACTIVITY_FULLFILLED",
  CLEAR_CURRENT_ACTIVITY_REJECTED: "CLEAR_CURRENT_ACTIVITY_REJECTED",

  GET_CURRENT_ACTIVITY_PENDING: "GET_CURRENT_ACTIVITY_PENDING",
  GET_CURRENT_ACTIVITY_FULLFILLED: "GET_CURRENT_ACTIVITY_FULLFILLED",
  GET_CURRENT_ACTIVITY_REJECTED: "GET_CURRENT_ACTIVITY_REJECTED",

  POST_RESERVE_SPOT_PENDING: "POST_RESERVE_SPOT_PENDING",
  POST_RESERVE_SPOT_FULLFILLED: "POST_RESERVE_SPOT_FULLFILLED",
  POST_RESERVE_SPOT_REJECTED: "POST_RESERVE_SPOT_REJECTED",
  CLEAR_RESERVE_SPOT: "CLEAR_RESERVE_SPOT",

  ATHLETES_CHANGE_TRAINING_NEEDSHELP_PENDING:
    "ATHLETES_CHANGE_TRAINING_NEEDSHELP_PENDING",
  ATHLETES_CHANGE_TRAINING_NEEDSHELP_FULFILLED:
    "ATHLETES_CHANGE_TRAINING_NEEDSHELP_FULFILLED",
  ATHLETES_CHANGE_TRAINING_NEEDSHELP_REJECTED:
    "ATHLETES_CHANGE_TRAINING_NEEDSHELP_REJECTED",
  ATHLETES_GET_SUBSCRIPTION_METHOD: "ATHLETES_GET_SIGNUP_METHOD",

  ATHLETES_GET_SIGNUP_PENDING: "ATHLETES_GET_SIGNUP_PENDING",
  ATHLETES_GET_SIGNUP_FULLFILLED: "ATHLETES_GET_SIGNUP_FULLFILLED",
  ATHLETES_GET_SIGNUP_REJECTED: "ATHLETES_GET_SIGNUP_REJECTED",

  ACTIVE_CAMPAIGN_INVITED_PENDING: "ACTIVE_CAMPAIGN_INVITED_PENDING",
  ACTIVE_CAMPAIGN_INVITED_FULLFILED: "ACTIVE_CAMPAIGN_INVITED_FULLFILED",
  ACTIVE_CAMPAIGN_INVITED_REJECTED: "ACTIVE_CAMPAIGN_INVITED_REJECTED",

  TRAINING_ZONE_CALCULATOR_PENDING: "TRAINING_ZONE_CALCULATOR_PENDING",
  TRAINING_ZONE_CALCULATOR_FULLFILED: "TRAINING_ZONE_CALCULATOR_FULLFILED",
  TRAINING_ZONE_CALCULATOR_REJECTED: "TRAINING_ZONE_CALCULATOR_REJECTED",

  EXPORT_WORKOUT_FULFILLED: "EXPORT_WORKOUT_FULFILLED",
  EXPORT_WORKOUT_UNFULFILLED: "EXPORT_WORKOUT_UNFULFILLED",

  HEALTHKIT_LOAD_ACTIVITIES_PENDING: "HEALTHKIT_LOAD_ACTIVITIES_PENDING",
  HEALTHKIT_LOAD_ACTIVITIES_FULFILLED: "HEALTHKIT_LOAD_ACTIVITIES_FULFILLED",
  HEALTHKIT_LOAD_ACTIVITIES_REJECTED: "HEALTHKIT_LOAD_ACTIVITIES_REJECTED",

  HEALTHKIT_INIT: "HEALTHKIT_INIT",
  HEALTHKIT_INIT_REJECTED: "HEALTHKIT_INIT_REJECTED",
  HEALTHKIT_INIT_PENDING: "HEALTHKIT_INIT_PENDING",
  HEALTHKIT_INIT_FULFILLED: "HEALTHKIT_INIT_FULFILLED",

  HEALTHKIT_GET_ACTIVITY_PENDING: "HEALTHKIT_GET_ACTIVITY_PENDING",
  HEALTHKIT_GET_ACTIVITY_FULFILLED: "HEALTHKIT_GET_ACTIVITY_FULFILLED",
  HEALTHKIT_GET_ACTIVITY_REJECTED: "HEALTHKIT_GET_ACTIVITY_REJECTED",
  HEALTHKIT_CLEAR_ACTIVITY: "HEALTHKIT_CLEAR_ACTIVITY",
  HEALTHKIT_CLEAR_ACTIVITIES: "HEALTHKIT_CLEAR_ACTIVITIES",

  GET_CUSTOMERS_DASHBOARD_PENDING: "GET_CUSTOMERS_DASHBOARD_PENDING",
  GET_CUSTOMERS_DASHBOARD_FULLFILLED: "GET_CUSTOMERS_DASHBOARD_FULLFILLED",
  GET_CUSTOMERS_DASHBOARD_CUSTOMERS_FULFILLED:
    "GET_CUSTOMERS_DASHBOARD_FULFILLED",
  GET_DASHBOARD_SUBCATEGORIES_FULFILLED:
    "GET_DASHBOARD_SUBCATEGORIES_FULFILLED",
  GET_CUSTOMERS_DASHBOARD_REJECTED: "GET_CUSTOMERS_DASHBOARD_REJECTED",
  CLEAR_ADMIN_DASHBOARD: "CLEAR_ADMIN_DASHBOARD",

  SET_LOGIN_COUNT: "SET_LOGIN_COUNT",
  ATHLETES_LOAD_STRENGTH_COACHES: "ATHLETES_LOAD_STRENGTH_COACHES",

  ATHLETES_ASSIGN_STRENGTH_COACH_PENDING:
    "ATHLETES_ASSIGN_STRENGTH_COACH_PENDING",
  ATHLETES_ASSIGN_STRENGTH_COACH_FULLFILED:
    "ATHLETES_ASSIGN_STRENGTH_COACH_FULLFILED",
  ATHLETES_ASSIGN_STRENGTH_COACH_REJECTED:
    "ATHLETES_ASSIGN_STRENGTH_COACH_REJECTED",

  ATHLETES_SAVE_PLAN_PENDING: "ATHLETES_SAVE_PLAN_PENDING",
  ATHLETES_SAVE_PLAN_FULLFILED: "ATHLETES_SAVE_PLAN_FULLFILED",
  ATHLETES_SAVE_PLAN_REJECTED: "ATHLETES_SAVE_PLAN_REJECTED",

  EDITING_PLAN_PENDING: "EDITING_PLAN_PENDING",
  EDITING_PLAN_FULLFILED: "EDITING_PLAN_FULLFILED",
  EDITING_PLAN_REJECTED: "EDITING_PLAN_REJECTED",
  DELETE_VERT_ACTIVITY_PENDING: "DELETE_VERT_ACTIVITY_PENDING",
  DELETE_VERT_ACTIVITY_FULFILLED: "DELETE_VERT_ACTIVITY_FULFILLED",
  DELETE_VERT_ACTIVITY_REJECTED: "DELETE_VERT_ACTIVITY_REJECTED",

  GENERAL_SEARCH_PENDING: "GENERAL_SEARCH_PENDING",
  GENERAL_SEARCH_FULLFILED: "GENERAL_SEARCH_FULLFILED",
  GENERAL_SEARCH_REJECTED: "GENERAL_SEARCH_REJECTED",

  GET_PAGINATED_PLAN_PENDING: "GET_PAGINATED_PLAN_PENDING",
  GET_PAGINATED_PLAN_FULFILLED: "GET_PAGINATED_PLAN_FULFILLED",
  GET_PAGINATED_PLAN_REJECTED: "GET_PAGINATED_PLAN_REJECTED",

  SET_PROFILE_CURRENT_FILTER: "SET_PROFILE_CURRENT_FILTER",
  SET_PROFILE_CURRENT_DATE: "SET_PROFILE_CURRENT_DATE",
  SET_PROFILE_SCREEN: "SET_PROFILE_SCREEN",
  COROS_INIT: "COROS_INIT",
  COROS_INIT_REJECTED: "COROS_INIT_REJECTED",
  COROS_INIT_PENDING: "COROS_INIT_PENDING",
  COROS_INIT_FULFILLED: "COROS_INIT_FULFILLED",
  COROS_DISCONNECT_FULFILLED: "COROS_DISCONNECT_FULFILLED",

  COROS_LOAD_ACTIVITIES_PENDING: "COROS_LOAD_ACTIVITIES_PENDING",
  COROS_LOAD_ACTIVITIES_FULFILLED: "COROS_LOAD_ACTIVITIES_FULFILLED",
  COROS_LOAD_ACTIVITIES_REJECTED: "COROS_LOAD_ACTIVITIES_REJECTED",

  COROS_GET_ACTIVITY_PENDING: "COROS_GET_ACTIVITY_PENDING",
  COROS_GET_ACTIVITY_FULFILLED: "COROS_GET_ACTIVITY_FULFILLED",
  COROS_GET_ACTIVITY_REJECTED: "COROS_GET_ACTIVITY_REJECTED",
  COROS_CLEAR_ACTIVITY: "COROS_CLEAR_ACTIVITY",
  COROS_CLEAR_ACTIVITIES: "COROS_CLEAR_ACTIVITIES",

  COMMUNITY_SET_REPLY_ID: "COMMUNITY_SET_REPLY_ID",
  COMMUNITY_SET_CURRENT_TEXT: "COMMUNITY_SET_CURRENT_TEXT",
  COMMUNITY_SET_CURRENT_IMAGE: "COMMUNITY_SET_CURRENT_IMAGE",
  COMMUNITY_SET_CURRENT_GOAL: "COMMUNITY_SET_CURRENT_GOAL",
  COMMUNITY_SET_CURRENT_BADGE: "COMMUNITY_SET_CURRENT_BADGE",
  COMMUNITY_SET_CURRENT_EPIC_RACE: "COMMUNITY_SET_CURRENT_EPIC_RACE",
  COMMUNITY_SET_POST_TYPE: "COMMUNITY_SET_POST_TYPE",
  COMMUNITY_ADD_POST_TAG: "COMMUNITY_ADD_POST_TAG",
  COMMUNITY_RESET_POST_CREATION_STATE: "COMMUNITY_RESET_POST_CREATION_STATE",
  COMMUNITY_ADD_POST_ACTIVITY: "COMMUNITY_ADD_POST_ACTIVITY",

  CHECKOUT_SET_ITEMS: "CHECKOUT_SET_ITEMS",

  USER_PLANS_LOADING_HAS_PLAN_DONE: "USER_PLANS_LOADING_HAS_PLAN_DONE",
  USER_PLANS_LOADING_HAS_PLAN: "USER_PLANS_LOADING_HAS_PLAN",
  USER_PLANS_SET_HAS_PLAN: "USER_PLANS_SET_HAS_PLAN",
  USER_PLANS_SET_HAS_PLAN_REJECTED: "USER_PLANS_SET_HAS_PLAN_REJECTED",
  USER_PLANS_UNREAD_MESSAGES: "USER_PLANS_UNREAD_MESSAGES",
  USER_PLANS_NEW_MESSAGES_BY_EVENT: "USER_PLANS_NEW_MESSAGES_BY_EVENT",
};
